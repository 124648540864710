import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import GLPolicyChangeStartPage from './pages/Start/GLPolicyChangeStartPage';

import GLPaySuccessChangePage from './pages/PaySuccess/GLPaySuccessChangePage';

import GLQualificationChangePage from './pages/Qualification/GLQualificationChangePage';
import GLQualificationChangeReadOnlyPage from './pages/Qualification/GLQualificationChangePage.readonly';

import GLPolicyDetailsChangePage from './pages/PolicyDetails/GLPolicyDetailsChangePage';
import GLPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/GLPolicyDetailsChangePage.readonly';

import GLSublineSelectionChangePage from './pages/SublineSelection/GLSublineSelectionChangePage';
import GLSublineSelectionChangeReadOnlyPage from './pages/SublineSelection/GLSublineSelectionChangePage.readonly';

import GLGeneralLiabilityChangePage from './pages/GeneralLiability/GLGeneralLiabilityChangePage';
import GLGeneralLiabilityChangeReadOnlyPage from './pages/GeneralLiability/GLGeneralLiabilityChangePage.readonly';

import GLStateSpecificInformationChangePage from './pages/StateSpecificInformation/GLStateSpecificInformationChangePage';
import GLStateSpecificInformationChangeReadOnlyPage from './pages/StateSpecificInformation/GLStateSpecificInformationChangePage.readonly';

import GLLocationsChangePage from './pages/Locations/GLLocationsChangePage';
import GLLocationsChangeReadOnlyPage from './pages/Locations/GLLocationsChangePage.readonly';

import GLExposuresChangePage from './pages/Exposures/GLExposuresChangePage';
import GLExposuresChangeReadOnlyPage from './pages/Exposures/GLExposuresChangePage.readonly';

import GLUnmannedAircraftChangePage from './pages/UnmannedAircraft/GLUnmannedAircraftChangePage';
import GLUnmannedAircraftChangeReadOnlyPage from './pages/UnmannedAircraft/GLUnmannedAircraftChangePage.readonly';

import GLModifiersChangePage from './pages/Modifiers/GLModifiersChangePage';
import GLModifiersChangeReadOnlyPage from './pages/Modifiers/GLModifiersChangePage.readonly';

import GLUnderwritingQuestionsChangePage from './pages/UnderwritingQuestions/GLUnderwritingQuestionsChangePage';
import GLUnderwritingQuestionsChangeReadOnlyPage from './pages/UnderwritingQuestions/GLUnderwritingQuestionsChangePage.readonly';

import GLRiskAnalysisChangePage from './pages/RiskAnalysis/GLRiskAnalysisChangePage';
import GLRiskAnalysisChangeReadOnlyPage from './pages/RiskAnalysis/GLRiskAnalysisChangePage.readonly';


// WIZARD PAGE IMPORT ANCHOR

import GLQuoteChangePage from './pages/Quote/GLQuoteChangePage';
import GLQuoteChangeReadOnlyPage from './pages/Quote/GLQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        GLPolicyChangeStartPage: { component: 'GLPolicyChangeStartPage' },
        GLPaySuccessChangePage: { component: 'GLPaySuccessChangePage' },

        GLQualificationChangePage: { component: 'GLQualificationChangePage'},
        GLQualificationChangeReadOnlyPage: { component: 'GLQualificationChangeReadOnlyPage'},

        GLPolicyDetailsChangePage: { component: 'GLPolicyDetailsChangePage'},
        GLPolicyDetailsChangeReadOnlyPage: { component: 'GLPolicyDetailsChangeReadOnlyPage'},

        GLSublineSelectionChangePage: { component: 'GLSublineSelectionChangePage'},
        GLSublineSelectionChangeReadOnlyPage: { component: 'GLSublineSelectionChangeReadOnlyPage'},

        GLGeneralLiabilityChangePage: { component: 'GLGeneralLiabilityChangePage'},
        GLGeneralLiabilityChangeReadOnlyPage: { component: 'GLGeneralLiabilityChangeReadOnlyPage'},

        GLStateSpecificInformationChangePage: { component: 'GLStateSpecificInformationChangePage' },
        GLStateSpecificInformationChangeReadOnlyPage: { component: 'GLStateSpecificInformationChangeReadOnlyPage' },

        GLLocationsChangePage: { component: 'GLLocationsChangePage'},
        GLLocationsChangeReadOnlyPage: { component: 'GLLocationsChangeReadOnlyPage'},

        GLExposuresChangePage: { component: 'GLExposuresChangePage'},
        GLExposuresChangeReadOnlyPage: { component: 'GLExposuresChangeReadOnlyPage'},

        GLUnmannedAircraftChangePage: { component: 'GLUnmannedAircraftChangePage'},
        GLUnmannedAircraftChangeReadOnlyPage: { component: 'GLUnmannedAircraftChangeReadOnlyPage'},

        GLModifiersChangePage: { component: 'GLModifiersChangePage'},
        GLModifiersChangeReadOnlyPage: { component: 'GLModifiersChangeReadOnlyPage'},

        GLUnderwritingQuestionsChangePage: { component: 'GLUnderwritingQuestionsChangePage'},
        GLUnderwritingQuestionsChangeReadOnlyPage: { component: 'GLUnderwritingQuestionsChangeReadOnlyPage'},

        GLRiskAnalysisChangePage: { component: 'GLRiskAnalysisChangePage'},
        GLRiskAnalysisChangeReadOnlyPage: { component: 'GLRiskAnalysisChangeReadOnlyPage'},


// WIZARD PAGE COMPONENT NAME ANCHOR
        GLQuoteChangePage: { component: 'GLQuoteChangePage' },
        GLQuoteChangeReadOnlyPage: { component: 'GLQuoteChangeReadOnlyPage' },
    },
    {
        GLPolicyChangeStartPage,
        GLPaySuccessChangePage,

        GLQualificationChangePage,
        GLQualificationChangeReadOnlyPage,

        GLPolicyDetailsChangePage,
        GLPolicyDetailsChangeReadOnlyPage,

        GLSublineSelectionChangePage,
        GLSublineSelectionChangeReadOnlyPage,

        GLGeneralLiabilityChangePage,
        GLGeneralLiabilityChangeReadOnlyPage,

        GLStateSpecificInformationChangePage,
        GLStateSpecificInformationChangeReadOnlyPage,

        GLLocationsChangePage,
        GLLocationsChangeReadOnlyPage,

        GLExposuresChangePage,
        GLExposuresChangeReadOnlyPage,

        GLUnmannedAircraftChangePage,
        GLUnmannedAircraftChangeReadOnlyPage,

        GLModifiersChangePage,
        GLModifiersChangeReadOnlyPage,

        GLUnderwritingQuestionsChangePage,
        GLUnderwritingQuestionsChangeReadOnlyPage,

        GLRiskAnalysisChangePage,
        GLRiskAnalysisChangeReadOnlyPage,


// WIZARD PAGE COMPONENT MAP ANCHOR
        GLQuoteChangePage,
        GLQuoteChangeReadOnlyPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as GLPolicyChangeWizard } from './GLPolicyChangeWizard';

// To be used by CPPGLExposuresChangePage
export { default as GLExposuresChangePage } from './pages/Exposures/GLExposuresChangePage';
