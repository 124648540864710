import React, {
    // useContext,
    useCallback,
    // useEffect,
    // useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
// import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { CAUnderwritingQuestionsService } from 'wni-capability-quoteandbind-ca';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import WizardPage from '../../templates/CAWizardPage';

import styles from './CAUnderwritingQuestionsPage.module.scss';
import metadata from './CAUnderwritingQuestionsPage.metadata.json5';
import caQuestionSetsUtil from './utils/CAQuestionSetsUtil';


function CAUnderwritingQuestionsReadOnlyPage(props) {

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        // saveUnderwritingQuestionsData,
    } = props;

    // const {
    //     jobID: quoteID, 
    //     sessionUUID,
    // } = submissionVM.value;



    const {
        initialValidation,
        onValidate,
        // invalidFields,
        // isComponentValid,
        // registerComponentValidation
    } = useValidation('CAUnderwritingQuestionsPage');

    const questionSetMapper = caQuestionSetsUtil.getQSMapperForCaUnderwritingQuestions()

    
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );


    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            policyUnderwritingQuestionSets: {
                isReadOnly: true,
                contentMapper: questionSetMapper,
            }
        }
    }, []);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // onNext={isComponentValid ? onPageNext : handleValidation}
            // pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                // showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAUnderwritingQuestionsReadOnlyPage.propTypes = {
    ...WizardPage.propTypes,
    saveUnderwritingQuestionsData: PropTypes.func
};

CAUnderwritingQuestionsReadOnlyPage.defaultProps = {
    ...WizardPage.defaultProps,
    saveUnderwritingQuestionsData: CAUnderwritingQuestionsService.saveCAUnderwritingQuestionsData
} 
export default CAUnderwritingQuestionsReadOnlyPage;