import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imAAISCommonFormsService'), method, data, additionalHeaders);
}

export default class IMAAISCommonFormsService {
  
    /**
     * Invokes IMAAISCommonFormsHandler.getAAISCommonFormsWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getAAISCommonFormsWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getAAISCommonFormsWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMAAISCommonFormsHandler.getAAISCommonForms()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getAAISCommonForms(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getAAISCommonForms', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMAAISCommonFormsHandler.updateAAISCommonFormsCoverage()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} aaisCommonFormsDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateAAISCommonFormsCoverage(jobNumber, sessionUUID, aaisCommonFormsDTO, authHeader = {}) {
        return processSubmission('updateAAISCommonFormsCoverage', [jobNumber, sessionUUID, aaisCommonFormsDTO], authHeader);
    }

    /**
     * Invokes IMAAISCommonFormsHandler.onAAISCommonFormsNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onAAISCommonFormsNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onAAISCommonFormsNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMAAISCommonFormsHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMAAISCommonFormsHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}