
import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardConstants } from 'wni-portals-config-js';
import { DPAddIntService } from 'wni-capability-quoteandbind-dp';
// import { HOAdditionalInterestsPage } from 'wni-capability-quoteandbind-ho-react';
import { BaseAdditionalInterestsPageComponent } from 'wni-capability-common-react';

import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

function DPAdditionalInterestsPage(props) {

    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const {
        wizardData: submissionVM,
        updateWizardPageData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus,
        },
    } = submissionVM.value;

    const lineSpecialAdditionalInterestTypeValueChangeFn = useCallback((
        newValue,
        vmService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        writeValue
    ) => {
        if (newValue !== 'AddInsuredDP_Ext') {
            const newAdditionalInterestVM = vmService.clone(getAdditionalInterestVM);
            _.set(newAdditionalInterestVM, 'value.type', newValue);
            _.unset(newAdditionalInterestVM, 'value.dpaddInsuredType_Ext');
            _.unset(newAdditionalInterestVM, 'value.explain_Ext');
            _.unset(newAdditionalInterestVM, 'value.nameOfSchool_Ext');
            updateAdditionalInterestVM(newAdditionalInterestVM);
        } else {
            writeValue(newValue, 'type');
        }
    }, []);

    const lineSpecialAdditionalInterestInsuredTypeValueChangeFn = useCallback((
        newValue,
        vmService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        writeValue
    ) => {
        const newAdditionalInterestVM = vmService.clone(getAdditionalInterestVM);
        _.set(newAdditionalInterestVM, 'value.dpaddInsuredType_Ext', newValue);
        if (newValue !== 'Other') {
            _.unset(newAdditionalInterestVM, 'value.explain_Ext');
        }
        if (newValue !== 'StudentLivingAway') {
            _.unset(newAdditionalInterestVM, 'value.nameOfSchool_Ext');
        }
        updateAdditionalInterestVM(newAdditionalInterestVM);
    }, []);

    const lineSpecialRenderInterestTypeFn = useCallback((
        item,
        index,
        property,
        translator,
        typeListsAvailableValues,
        dpaddInsuredTypeListsAvailableValues
        ) => {
            const typeValue = _.get(item, property.path);
            let value = '';
            if (typeListsAvailableValues !== null) {
                const interestTypeKey = _.find(typeListsAvailableValues, (ttx) => ttx.code === typeValue);
                value = translator({ id: _.get(interestTypeKey, 'name') });
            }
            if (typeValue === 'AddInsuredDP_Ext') {
                const dpInsuredValue = _.get(item, 'dpaddInsuredType_Ext');
                if (dpInsuredValue === 'Other') {
                    const dpInsuredOtherValue = _.get(item, 'explain_Ext');
                    value += ` - ${dpInsuredOtherValue}`;
                } else if(dpInsuredValue===''){
                    value = '-'
                } else {
                    const dpInsuredKey = _.find(dpaddInsuredTypeListsAvailableValues, (ttx) => ttx.code === dpInsuredValue);
                    const dpInsured = translator({ id: _.get(dpInsuredKey, 'name') });
                    value += ` - ${dpInsured}`;
                }
            }
            return value;
    }, []);

    const initProps = {
        ...props,
        lobDataProductKey: 'dwellingProperty',
        dtoVMPath: 'wni.edge.capabilities.quote.lob.dwellingproperty.dto.DPAdditionalInterestDTO',
        WizardPage,
        showAdditionalInterestInsuredType: true,
        showAdditionalInterestPleaseExplain: true,
        showAdditionalInterestSpecificInterest: true,
        showAdditionalInterestNameOfSchool: true,
        addIntService: DPAddIntService,
        lineSpecialAdditionalInterestTypeValueChangeFn,
        lineSpecialAdditionalInterestInsuredTypeValueChangeFn,
        lineSpecialRenderInterestTypeFn
    };

    return (
        <BaseAdditionalInterestsPageComponent {...initProps}/>
    );

}

DPAdditionalInterestsPage.propTypes = BaseAdditionalInterestsPageComponent.propTypes;
DPAdditionalInterestsPage.defaultProps = BaseAdditionalInterestsPageComponent.defaultProps;

export default DPAdditionalInterestsPage;
