import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WniAccountService } from 'wni-capability-gateway';
import { StateUtil, WindowUtil } from 'wni-portals-util-js';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    getVerifyAddressIssues, AddressChangeVerify, getValidationMap, AddressVerifiedUtil
} from '../../components/AddressInputComponent/AddressVerifiedUtil';
import AccountContactInputComponent from '../../components/AccountContactInputComponent/AccountContactInputComponent';
import metadata from './NewPersonComponent.metadata.json5';
import messages from './NewPersonComponent.message';

import { Button } from '@jutro/legacy/components';

function NewPersonComponent(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        authHeader,
        submissionVM,
        primaryPayerAvailableValues,
        requireProductsContainer,
        isTemporaryForPortal,
        useAuthenticationData,
        useDependenciesData,
        viewModelService,
        clSelectedBillingMethodPAYG
    } = props;

    const modalApi = useWniModal();

    const [validationIssues, updateValidationIssues] = useState([]);
    const [showDuplicateExistingMessage, updateShowDuplicateExistingMessage] = useState(false);
    const [acknowledgeUser, updateAcknowledgeUser] = useState(null);
    const {
        onValidate,
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('newPersonComponent');

    let accountContactDTOPath = 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO';
    if (clSelectedBillingMethodPAYG) {
        accountContactDTOPath = 'wni.edge.capabilities.policycommon.accountcontact.dto.PaygAccountContactDTO';
    }

    const accountContactDtoVM = viewModelService.create(
        {
            primaryAddress: {
                city: undefined,
                country: 'US',
                postalCode: undefined,
                state: undefined
            }
        }, 'pc',
        accountContactDTOPath, {
            PhoneRequired: true
        }
    );
    _.set(accountContactDtoVM, 'subtype.value', 'Person');
    _.set(accountContactDtoVM, 'phoneRequired_Ext', true);
    _.set(accountContactDtoVM, 'primaryPhoneType', 'mobile');
    const [vm, updateVM] = useState(accountContactDtoVM);
    const [showErrors, updateShowErrors] = useState(false);
    const [options, updateOptions] = useState([]);
    const [isAddressFlag, updateAddressFlag] = useState(false);

    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);

    const writeValue = useCallback(
        (value, path) => {
            if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
                // address change, the warning message about invaild address set hide
                const verifyMsg = getVerifyAddressIssues(false);
                updateLookupValidation(verifyMsg);
                // set the flag false, and click next button, verify address again
                updateAddressFlag(false);
            }
            const newPaymentDetailVM = viewModelService.clone(vm);
            _.set(newPaymentDetailVM, path, value);
            updateVM(newPaymentDetailVM);
            updateShowDuplicateExistingMessage(false);
        },
        [viewModelService, vm, updateLookupValidation]
    );

    const onAccountContactChange = (value, path, customUpdater = undefined) => {
        if (!customUpdater) {
            writeValue(value, path);
        } else {
            const newPaymentDetailVM = viewModelService.clone(vm);
            customUpdater(newPaymentDetailVM);
            updateVM(newPaymentDetailVM);
        }
    };

    const checkDuplicate = useCallback((firstName, lastName) => {
        const inputUserName = `${firstName} ${lastName}`;
        const result = _.find(primaryPayerAvailableValues, (payer) => {
            return inputUserName === _.get(payer, 'name');
        });
        return result;
    }, [primaryPayerAvailableValues]);


    const isFormValid = useCallback(() => {
        const {
            firstName,
            lastName,
            primaryPhoneType,
            homeNumber,
            cellNumber,
            workNumber,
            primaryAddress: {
                pobox_Ext: pobox,
                addressLine1,
                postalCode,
                city,
                state
            }
        } = vm.value;
        // check the addressLine1 when pobox is empty
        if (_.isEmpty(pobox)) {
            if (
                firstName
                && lastName
                && addressLine1
                && postalCode
                && city
                && state
                && primaryPhoneType
                && (homeNumber || cellNumber || workNumber)
            ) {
                return true;
            }
        }
        // do not check addressLine1 when pobox got value
        if (
            firstName
            && lastName
            && postalCode
            && city
            && state
            && primaryPhoneType
            && (homeNumber || cellNumber || workNumber)
        ) {
            return true;
        }
        return false;
    }, [vm]);

    useEffect(() => {
        registerComponentValidation(isFormValid);
    }, [registerComponentValidation, isFormValid]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const handleSave = useCallback(
        async () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            const {
                firstName,
                lastName
            } = vm.value;
            const addressUtil = AddressVerifiedUtil({
                authHeader,
                addressVM: _.get(vm, 'primaryAddress'),
                addressPath: 'primaryAddress',
                updateValidations: updateLookupValidation,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                doublePopup: true,
                writeValue,
                //
                modalApi,
            });
            const verifiedObj = await addressUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }

            const result = checkDuplicate(firstName, lastName);
            const inputUserName = `${firstName} ${lastName}`;
            if (inputUserName === acknowledgeUser) {
                updateShowDuplicateExistingMessage(false);
            } else if (result) {
                updateShowDuplicateExistingMessage(true);
                updateAcknowledgeUser(`${firstName} ${lastName}`);
                WindowUtil.scrollTo('duplicateExistingMessage');
                return false;
            } else {
                updateShowDuplicateExistingMessage(false);
            }

            const quoteID = _.get(submissionVM, 'value.quoteID');
            const sessionUUID = _.get(submissionVM, 'value.sessionUUID');
            const accountNumber = _.get(submissionVM, 'value.baseData.accountNumber');
            const newAccountContact = _.get(vm, 'value');
            if (isTemporaryForPortal) {
                return onResolve(newAccountContact);    
            }
            const newAccountResponse = await WniAccountService
                .createOrUpdateBillingContact(
                    quoteID, sessionUUID, accountNumber, newAccountContact, authHeader
                );
            return onResolve(newAccountResponse);
        },
        [acknowledgeUser, authHeader, checkDuplicate, handleValidation, isAddressFlag, isComponentValid, isTemporaryForPortal, onResolve, submissionVM, updateLookupValidation, vm, writeValue]
    );

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        accountContactInputContainer: {
            dateOfBirthContainerConfig: {
                required: false
            },
            model: vm,
            updateAccountHolderViewVM: updateVM,
            onAccountContactChange,
            onOrganisationOptionsChange: () => {},
            onProducerCodeOptionsChange: updateOptions,
            onContactTypeChange: () => {},
            updateAddressSubType: () => {},
            showErrors: showErrors,
            isHideContactType: true,
            hideRequiredforQuote: true,
            onValidate,
            products: {
                productSelected: [],
                updateProductSelected: _.noop,
                requireProductsContainer: requireProductsContainer
            },
            viewModelService,
            useAuthenticationData,
            useDependenciesData
        },
        duplicateExistingMessage: {
            visible: showDuplicateExistingMessage,
            message: messages.contactExist,
        }
    };

    const resolvers = {
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            accountcontactinput: AccountContactInputComponent
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="NewPersonComponentPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={vm}
                    onValueChange={writeValue}
                    overrideProps={overrideProps}
                    componentMap={resolvers.resolveComponentMap}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

NewPersonComponent.propTypes = {
    contactsVM: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    submissionVM: PropTypes.shape({}),
    primaryPayerAvailableValues: PropTypes.arrayOf(PropTypes.shape({})),
    requireProductsContainer: PropTypes.bool,
    isTemporaryForPortal: PropTypes.bool
};
NewPersonComponent.defaultProps = {
    size: 'lg',
    submissionVM: {},
    primaryPayerAvailableValues: [],
    requireProductsContainer: false,
    isTemporaryForPortal: false
};
export default NewPersonComponent;
