import { CountryMap } from "wni-common-sources";

const {
    COUNTRY_FORMAT_MAPS
} = CountryMap;

const isPostalCodeValid = (country, postalCode) => {
    const formatByCountry = COUNTRY_FORMAT_MAPS[country];
    if(!formatByCountry) {
        return true;
    }
    const { regex } = formatByCountry;
    return regex.test(postalCode)
};

const postalCodeFormat = (country, postalCode) => {
    const formatByCountry = COUNTRY_FORMAT_MAPS[country];
    if(!formatByCountry) {
        return postalCode;
    }

    const { formatPostalCode } = formatByCountry;
    if(!formatPostalCode) {
        return postalCode;
    }
    return formatPostalCode(postalCode);
}

export default {
    isPostalCodeValid,
    postalCodeFormat
}