

import React from 'react';
import { IMTransitPage } from 'wni-capability-quoteandbind-im-react';


const IMTransitChangePage = (props) => {
    return <IMTransitPage {...props} isPolicyChange />
};
export default IMTransitChangePage

