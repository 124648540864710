// import WALFrontendScheduleItemSyncUtil from './util/WALFrontendScheduleItemSyncUtil'
// import WALOptionsUtil from './util/WALOptionsUtil'
// import WALScheduleOptionValuesSortUtil from './util/WALScheduleOptionValuesSortUtil'

/**
 * @type {{
*  [scheduleConfigOption: String]: Object
* }}
*/
const scheduleConfig = {
    /**
     * Table column visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleColumnIdsOverride: {
    },
    /**
     * Schedule details visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleFieldIdsOverride: {
    },
    /**
     * @type {{
     *  [clauseCode: String]: Array.<String>
     * }}
     */
    fieldsNotNeedSyncWhileChange: {
    },
    /**
     * @type {{
     *  [clauseCode: String]: {
     *      [scheduleItemID: String]: (scheduleItem: Object, allOptions: Array) => Array
     *  }
     * }}
     */
    optionFilters: {
    },
    /**
     * Return a function which return schedule after frontend sync
     * 
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (schedule: Object, localPathToSchedule: String) => Object
     *  }
     * }}
     */
    frontendScheduleItemSync: {
    },
    /**
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (optionA: Object, optionB: Object) => Number
     *  }
     * }}
     */
    optionValueSortFunction: {
    }
}

/**
 * @type {{
 *  [clauseCode: String]: Array.<String>
 * }}
 */
const termConfig = {
    termsNotChangeWithSync: [
        // group by coverage
    ]
}

/**
 * A key(coverage code)-value(term code) set of coverage has limit term
 * And limit term display right of coverage label
 * 
 * @type {{
 *  [clauseCode: String]: String
 * }}
 */
const limitCoverageConfig = {
    // RoadTrail and Equipment - Limit
    'RTEquip': 'RTEquipRTEquipEquipLmt',
    // Personal Property - Limit
    'RTRTPersonalProperty': 'RTRTPersonalPropertyRTPersonalPropertyPPLmt',
    // Liability - Per Occurrence Limit
    'RTLiability': 'RTLiabilityRTLiabilityOccurrenceLmt',
    // Single Liability Limit - Liability Combined Single Limit
    'RTSingleLiabilityLimit_Ext': 'RTSingleLiabilityLimitLCSingleLimit_Ext',
    // Bodily Injury Liability - Bodily Injury Liability
    'RTBodilyInjuryLiability_Ext': 'RTBodilyInjuryLiabilityBILiabilityPackage_Ext',
    // Property Damage Liability - Property Damage Liability
    'RTPropertyDamageLiability_Ext': 'RTPropertyDamageLiabilityPDLiability_Ext',
    // Medical Payments - Medical Limit
    'RTMedicalPayments_Ext': 'RTMedicalPaymentsMedicalLimit_Ext',
    // Uninsured/Underinsured Motorists Coverage - Bodily Injury - Uninsured/Underinsured Motorists - BI Limits
    'RTUnUnderMotCovBodilyInjury_Ext': 'RTUnUnderMotCovBodilyInjuryBILimitsPackage_Ext',
    // Uninsured/Underinsured Motorists Coverage - Property Damage - Uninsured/Underinsured Motorists - PD Limits
    'RTUnUnderMotCovPropertyDamage_Ext': 'RTUnUnderMotCovPropertyDamagePDLimits_Ext',
    // Uninsured/Underinsured Motorists Coverage - Bodily Injury and Property Damage - Uninsured/Underinsured Motorists - BI & PD Single Limit
    'RTUnUnderMotCovBodilyInPropertyDam_Ext': 'RTUnUnderMotCovBodilyInPropertyDamSingleLimit_Ext',
    // Trip Interruption Coverage - Limit
    'RTTripInterruptionCoverage_Ext': 'RTTripInterruptionCoverageLimit_Ext',
    // Uninsured Motorists Coverage Split - Uninsured Motorists - BI Limits - Split
    'RTUninsuredMotoristsCoverageSplit_Ext': 'RTUninsuredMotoristsCoverageSplitBILimitsPackage_Ext',
    // Uninsured Motorists Coverage Single Limit - Uninsured Motorists - BI Limits - Single Limit
    'RTUninsuredMotoristsCovSingleLimit_Ext': 'RTUninsuredMotoristsCovSingleLimitBILimits_Ext',
    // Underinsured Motorists Coverage Split - Underinsured Motorists - BI Limits - Split
    'RTUnderinsuredMotoristsCovSplit_Ext': 'RTUnderinsuredMotoristsCovSplitBILimitsPackage_Ext',
    // Underinsured Motorists Single Limit - Underinsured Motorists - BI Limits - Single Limit
    'RTUnderinsuredMotoristsSingleLimit_Ext': 'RTUnderinsuredMotoristsSingleLimitBILimits_Ext',
    // Uninsured Motorists Coverage - Bodily Injury - Split - Uninsured Motorists - BI Limits - Split
    'RTUninsuredMotoristsCovBISplit_Ext': 'RTUninsuredMotoristsCovBISplitBILimitsPackage_Ext',
    // Underinsured Motorists Coverage - Bodily Injury - Split - Underinsured Motorists - BI Limits - Split
    'RTUnderinsuredMotoristsCovBISplit_Ext': 'RTUnderinsuredMotoristsCovBISplitBILimitsPackage_Ext',
    // Uninsured Motorists Coverage - Bodily Injury - Single Limit - Uninsured Motorists - BI Limits - Single Limit
    'RTUninsuredMotCovBISingleLimit_Ext': 'RTUninsuredMotCovBISingleLimitBILimits_Ext',
    // Underinsured Motorists Coverage - Bodily Injury - Single Limit - Underinsured Motorists - BI Limits - Single Limit
    'RTUnderinsuredMotCovBISingleLimit_Ext': 'RTUnderinsuredMotCovBISingleLimitBILimits_Ext',
    // Auto Death Benefits and Disability Coverage - South Dakota - Principal Sum Per Insured
    'RTAutoDeathBenefitsAndDisCovSD_Ext': 'RTAutoDeathBenefitsAndDisCovSDPSPerInsured_Ext',
    // Uninsured Motorists Coverage - Uninsured Motorists - BI Limits - Split
    'RTUninsuredMotoristsCoverage_Ext': 'RTUninsuredMotoristsCoverageBILimitsPackage_Ext',
    // Single Uninsured Motorists Limit - Uninsured Motorists - BI Limits - Single Limit
    'RTSingleUninsuredMotoristsLimit_Ext': 'RTSingleUninsuredMotoristsLimitBILimits_Ext',
    // Underinsured Motorists Coverage - Underinsured Motorists - BI Limits - Split
    'RTUnderinsuredMotoristsCoverage_Ext': 'RTUnderinsuredMotoristsCoverageBILimitsPackage_Ext',
    // Single Underinsured Motorists Limit - Underinsured Motorists - BI Limits - Single Limit
    'RTSingleUnderinsuredMotoristsLimit_Ext': 'RTSingleUnderinsuredMotoristsLimitBILimits_Ext',
    // Other Than Collision - Other Than Collision Deductible
    'RTOtherThanCollision_Ext': 'RTOtherThanCollisionDeductible_Ext',
    // Collision - Collision Deductible
    'RTCollision_Ext': 'RTCollisionDeductible_Ext',
    // Transportation Expenses Coverage - Limit Per Day
    'RTTransportationExpensesCoverage_Ext': 'RTTransportationExpensesCoverageLimitPackage_Ext',
    // Towing And Labor Costs Coverage - Limit of Liability
    'RTTowingAndLaborCostsCoverage_Ext': 'RTTowingAndLaborCostsCoverageLimit_Ext',
    // Increased Limits Rider Safety Apparel Coverage - Increased Limit
    'RTIncLimitsRiderSafetyAppCov_Ext': 'RTIncLimitsRiderSafetyAppCovLimit_Ext',
    // Electronic Equipment Coverage - Limit Of Liability For Electronic Equipment
    'RTElectronicEquipmentCoverage_Ext': 'RTElectronicEquipmentCoverageLimit_Ext',
    // Other Than Collision Coverage For Damage To A Rental Vehicle - Other Than Collision Deductible
    'RTOTCCovForDamToARentalVehicle_Ext': 'RTOTCCovForDamToARentalVehicleDeductible_Ext',
    // Personal Contents Coverage - Limit
    'RTPersonalContentsCoverage_Ext': 'RTPersonalContentsCoverageLimit_Ext',
    // Tapes, Disks and Other Media Coverage - Tapes, Disks and Other Media Limit
    'RTTapesRecordsDisksDiscsAndOtherMediaCov_Ext': 'RTTapesRecordsDisksDiscsAndOtherMediaCovLimit_Ext',
}

/**
 * @type {{
*  [clauseCode: String]: (...params: any) => Boolean
* }}
*/
const coverageAvailable = {
    'RTAutoDeathBenefits_Ext': () => false,
    'RTTotalDisabilityBenefits_Ext': () => false,
}

/**
 * @type {{
*  [clauseCode: String]: {
    *       [scheduleItemID: String]: (...params: any) => Boolean
    *   }
    * }}
    */
const covTermAvailable = {
}

/**
 * @enum {String}
 */
const ClauseComponentType = {
    Toggle: 'Toggle',
}

/**
 * default as checkbox
 * @type {{
*  [clauseCode: String]: String
* }}
*/
const clauseComponentTypeMap = {
}

export default {
    scheduleConfig,
    termConfig,
    limitCoverageConfig,
    coverageAvailable,
    covTermAvailable,
    ClauseComponentType,
    clauseComponentTypeMap,
}