import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

// import WCCoveargesPageMain from '../../../Coverages/WCCoveragesPage.main';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';

import WCRatingPeriodSummaryComponent from './WCRatingPeriodSummaryComponent';
import WCCoveredEmployeesComponent from './WCCoveredEmployeesComponenty';

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function WCJurisdictionSummaryComponent(props) {

    const {
        jurisdiction, // WCJurisdictionDTO;
    } = props;

    if (_.isEmpty(jurisdiction)) {
        return null;
    }

    const {
        state,
        officialID,
        //
        ratingPeriods,
        //
        noSplitCoverages,
        coverages,
        exclusions,
        conditions,
        //
        coveredEmployees,
    } = jurisdiction;


    const renderRatingPeriods = () => {
        if (_.isEmpty(ratingPeriods)) {
            return null;
        }
        const retval = ratingPeriods.map((period) => {
            return <WCRatingPeriodSummaryComponent ratingPeriod={period} />
        });
        // <h4>Rating Periods</h4>
        return retval;
    };

    // const renderClausesSection = (sectionTitle, sectionClauses) => {
    //     if (_.isEmpty(sectionClauses)) {
    //         return null;
    //     }
    //     return (
    //         <>
    //             <h4>{sectionTitle}</h4>
    //             <CoverageSummaryComponent clauses={sectionClauses} />
    //         </>
    //     );
    // };

    const visibleCoverages = coverages || noSplitCoverages;
    return (
        <div>
            {renderRatingPeriods()}
            <CoverageSummaryComponent title="Coverages" clauses={visibleCoverages} />
            <CoverageSummaryComponent title="Exclusions" clauses={exclusions} />
            <CoverageSummaryComponent title="Conditions" clauses={conditions} />
            <WCCoveredEmployeesComponent coveredEmployees={coveredEmployees} />
        </div>
    );
    
}

WCJurisdictionSummaryComponent.propTypes = {
    
};

WCJurisdictionSummaryComponent.defaultProps = {
    
};


export default WCJurisdictionSummaryComponent;