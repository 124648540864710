import React from 'react';
import WizardPage from '../../templates/IMWizardPage';
import IMBoatCoveragePage from './IMBoatCoveragePage';

const IMBoatCoverageReadOnlyPage = (props) => {
    return <IMBoatCoveragePage {...props} readOnly />
};

IMBoatCoverageReadOnlyPage.propTypes = WizardPage.propTypes;
export default IMBoatCoverageReadOnlyPage;
