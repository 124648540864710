import { defineMessages } from 'react-intl';

export default defineMessages({
    productFilterAllProductsItemName: {
        id: 'gateway.directives.policy-transaction.all products',
        defaultMessage: '--All Products--',
    },
    productFilterAllTransactionsItemName: {
        id: 'gateway.directives.policy-transaction.all transactions',
        defaultMessage: '--All Transactions--',
    },
    prendingTransationsColumnHeaderProduct: {
        id: 'gateway.directives.policy-transaction.Product',
        defaultMessage: 'Product',
    },
    prendingTransationsColumnHeaderEffective: {
        id: 'gateway.directives.policy-transaction.Effective',
        defaultMessage: 'Effective',
    },
    prendingTransationsColumnHeaderTotalPremium: {
        id: 'gateway.directives.policy-transaction.Total Premium',
        defaultMessage: 'Total Premium',
    },
    prendingTransationsColumnHeaderStatus: {
        id: 'gateway.directives.policy-transaction.Status',
        defaultMessage: 'Status',
    },
    prendingTransationsColumnHeaderStatusTootipDraft: {
        id: 'gateway.directives.policy-transaction.StatusTootip.Draft',
        defaultMessage: 'Draft- Proceed to quote or complete edits',
    },
    prendingTransationsColumnHeaderStatusTootipRated: {
        id: 'gateway.directives.policy-transaction.StatusTootip.Rated',
        defaultMessage: 'Rated- Proceed to complete quote',
    },
    prendingTransationsColumnHeaderStatusTootipQuoted: {
        id: 'gateway.directives.policy-transaction.StatusTootip.Quoted',
        defaultMessage: 'Quoted- Proceed to validation or refer to underwriting',
    },
    prendingTransationsColumnHeaderStatusTootipApproved: {
        id: 'gateway.directives.policy-transaction.StatusTootip.Approved',
        defaultMessage: 'Approved- Proceed to issue',
    },
    prendingTransationsColumnHeaderQuoteSummary: {
        id: 'gateway.directives.policy-transaction.Quote Summary',
        defaultMessage: 'Quote Summary',
    },
    prendingTransationsColumnHeaderViewPolicy: {
        id: 'gateway.directives.policy-transaction.View Policy',
        defaultMessage: 'Policy #',
    },
    prendingTransationsColumnHeaderType: {
        id: 'gateway.directives.policy-transaction.Transaction Type',
        defaultMessage: 'Transaction Type',
    },
    prendingTransationsColumnHeaderViewTransaction: {
        id: 'gateway.directives.policy-transaction.View Transaction',
        defaultMessage: 'Transaction #'
    },
    withdrawJob: {
        id: 'gateway.directives.policy-transaction.WithdrawJob',
        defaultMessage: 'Withdraw Quote?'
    },
    sureWithDrawJob: {
        id: 'gateway.directives.policy-transaction.Are you sure you want to withdraw {jobType}:',
        defaultMessage: 'Are you sure you want to withdraw {jobType}: {quoteNumber}? All changes will be lost.'
    },
});
