import React, {
    useEffect,
    useContext,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { TooltipIcon } from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { IntlContext } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { WALTooltipUtil as tooltips } from 'wni-portals-tooltip';
import messages from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemsComponent.messages';
import styles from './WALScheduleItemsComponent.module.scss';
import metadata from './WALScheduleItemsComponent.metadata.json5';
import {
    valueTypeNameMap,
    valueTypeMap,
    getFormattedValue,
    isShceduleItemDataItemDiff,
} from './WALScheduleItemsUtil';
import HOScheduleItemDetailsComponent from './WALScheduleItemDetailsComponent';
import CoveragesConfigContext from '../../context/WALCoveragesConfigContext';

import { Button } from '@jutro/legacy/components';

const ItemNO = "ItemNO"

function WALScheduleItemsComponent(props) {
    const modalApi = useWniModal();
    const intl = useContext(IntlContext);
    // const [isLoading, setLoading] = useState(false);
    const {
        coverageCode,
        value: originScheduleItem,
        labelPosition,
        readOnly,
        onScheduleChangeWithSync,
        onScheduleChangeWithoutSync,
        path,
        showTitle,
        visibleColumnIdsOverride,
        visibleFieldIdsOverride,
        showScheduleDetailsInPanelBelow,
        openedRowItemNumber,
        setOpenedRowItemNumber,
        selectedRowItemIndex,
        setSelectedRowItemIndex,
        showErrors,
        // create loss payee
        additionalInterests = [],
        onSaveAdditionalInterestFn,
        onGettingAdditionalInterestTypeOptions,
        accountContacts,
        isCreateAdditionalInterestPanelVisible,
        setIsCreateAdditionalInterestPanelVisible,
        // end create loss payee
    } = props;

    const coveragesConfig = useContext(CoveragesConfigContext)

    const scheduleItem = useMemo(() => {
        return {
            ...originScheduleItem,
            scheduleItems: _.sortBy(originScheduleItem.scheduleItems, 'itemNumber')
        }
    }, [originScheduleItem]);

    const openScheduleItem = _.get(scheduleItem, 'scheduleItems', [])
        .find((item) => _.get(item, 'itemNumber') === openedRowItemNumber);

    const {
        scheduleConfig: {
            fieldsNotNeedSyncWhileChange,
            optionFilters,
            optionValueSortFunction,
        }
    } = coveragesConfig;

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow, 'publicId_Ext') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'scheduleItemTable');
    };
    const onSort = useCallback((a, b, info) => {
        highlightRowFn(openScheduleItem);
        const columnId = _.get(info, 'id');
        if (columnId === ItemNO) {
            return DatatableUtil.sortNumber(a, b)
        }
        // const valueObject = _.get(items.itemData, columnId);
        const {
            isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
            valueTypeName_Ext: valueTypeName,
            valueType,
        } = info
        const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : valueTypeMap[valueType];
        const getValueFromItemData = (itemData) => {
            return _.get(itemData, `${columnId}.${valuePath}`)
        }
        if (valuePath === 'typeCodeValue') {
            const getChosenOption = (itemData) => {
                const options = _.get(itemData, `${columnId}.options_Ext`, [])
                const value = getValueFromItemData(itemData)
                return options.find((option) => option.code === value)
            }
            const sortFunctionFromConfig = _.get(optionValueSortFunction, `${coverageCode}.${columnId}`)
            if (_.isFunction(sortFunctionFromConfig)){
                return sortFunctionFromConfig(getChosenOption(a), getChosenOption(b))
            }
            // options sort by option value
            const getOptionValue = (itemData) => {
                const chosenOption = getChosenOption(itemData)
                return _.get(chosenOption, 'optionValue')
            }
            // sort by option value then by displayName
            return DatatableUtil.sortNumber(getOptionValue(a), getOptionValue(b))
        }
        if (valuePath === 'bigDecimal' || valuePath === 'integerValue') {
            return DatatableUtil.sortNumber(getValueFromItemData(a), getValueFromItemData(b))
        }
        if (valuePath === 'dateValue') {
            return DatatableUtil.sortDate(moment(getValueFromItemData(a)), moment(getValueFromItemData(b)))
        }
        if (valuePath === 'stringValue') {
            return DatatableUtil.sortString(getValueFromItemData(a), getValueFromItemData(b))
        }
        return DatatableUtil.sortString(a, b);
    }, [coverageCode, openScheduleItem, optionValueSortFunction]);

    useEffect(() => {
        highlightRowFn(openScheduleItem);
    }, [openScheduleItem]);

    const renderCell = useCallback((items, index, property) => {
        const { publicId_Ext: publicId} = items;
        const {
            id: columnId,
            valueType,
            valueTypeName_Ext: valueTypeName,
            isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
        } = property.data;
        if (valueType === 'ICON') {
            const { content } = property.data;
            const iconActionDom = (
                <div className={`align-right rowId${publicId}`}>
                    {
                        content.map((item) => {
                            const { icon, onClick } = item;
                            return (
                                <Button
                                    key={icon}
                                    className="wni-button-icon-border"
                                    icon={icon}
                                    type="outlined"
                                    size="small"
                                    onClick={onClick(items, index)}
                                />
                            );
                        })
                    }
                </div>
            );
            return iconActionDom;
        }

        if (columnId === ItemNO) {
            return _.get(items, 'itemNumber')
        }

        const valueObject = _.get(items.itemData, columnId);
        const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : valueTypeMap[valueType];
        const value = _.get(valueObject, valuePath);
        if (_.isNil(value)) {
            return '-'
        }

        if (valuePath === 'typeCodeValue') {
            const options = _.get(valueObject, 'options_Ext', []);
            const selectedOption = options.find((option) => _.get(option, 'code') === value);
            const optionCode = _.get(selectedOption, 'code');
            const optionHelpText = tooltips[optionCode];
            return (
                <div className="labelWithTooltip">
                    <span>{_.get(selectedOption, 'name')}</span>
                    { optionHelpText && <TooltipIcon
                        id={`option${optionCode}`}
                        text={optionHelpText}
                    />}
                    
                </div>
            )
        }
        if (valueTypeName === 'MONEY_EXT' && valuePath === 'bigDecimal') {
            return intl.formatNumber(
                value,
                {
                    style: 'currency',
                    currency: 'USD',
                    currencyDisplay: 'symbol',
                    maximumFractionDigits: 0
                }
            );
        }

        if (valuePath === 'integerValue' && _.has(property.data, 'currency')) {
            const { currency } = property.data;
            return intl.formatNumber(
                value,
                {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'symbol'
                }
            );
        }
        if (valuePath === 'dateValue') {
            return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
        }
        return getFormattedValue(value, valueType, property.data);
    }, [intl]);

    const generateColumnOverrides = useCallback(
        (columnData) => {
            const overrideProps = columnData.map((info, index) => {
                const columnId = _.get(info, 'id');
                const isNOColumn = columnId === ItemNO;
                return {
                    [`scheduleItemColumn${index}`]: {
                        renderCell: renderCell,
                        header: info.label,
                        textAlign: info.textAlign ? info.textAlign : 'left',
                        sortable: info.valueType !== 'ICON',
                        path: isNOColumn ? 'itemNumber' : 'itemData',
                        onSort: (a, b) => onSort(a, b, info)
                    }
                }
            })
            return Object.assign({}, ...overrideProps);
        },
        [onSort, renderCell]
    );

    const syncScheduleChange = useCallback(async (updatedSchedule, index, forceSync = false) => {
        const clonedSchedule = _.cloneDeep(scheduleItem);
        const localPathToScheduleItem = `scheduleItems[${index}]`;
        const scheduleItemBeforeChange = _.get(clonedSchedule, localPathToScheduleItem)

        const mapLossPayeeToFixedId = (lossPayee) => _.get(lossPayee, 'fixedId')

        const scheduleItemBeforeChangeItemData = _.get(scheduleItemBeforeChange, 'itemData')
        const lossPayeesBeforeChange = _.get(scheduleItemBeforeChange, 'lossPayees_Ext', [])
        const lossPayeeFixedIdsBeforeChange = lossPayeesBeforeChange
            .map(mapLossPayeeToFixedId)
        const updatedScheduleItemData = _.get(updatedSchedule, 'itemData')
        const updatedLossPayees = _.get(updatedSchedule, 'lossPayees_Ext', [])
        const updatedLossPayeeFixedIds = updatedLossPayees
            .map(mapLossPayeeToFixedId)

        const allFieldKeysInSchedule = _.keys(scheduleItemBeforeChangeItemData)
        const changedFields = allFieldKeysInSchedule.filter((key) => {
            const beforeChangeItem = _.get(scheduleItemBeforeChangeItemData, key)
            const updateScheduleItem = _.get(updatedScheduleItemData, key)
            return isShceduleItemDataItemDiff(beforeChangeItem, updateScheduleItem)
        })

        const islossPayeeChanged = 
            _.difference(lossPayeeFixedIdsBeforeChange, updatedLossPayeeFixedIds).length > 0
            || _.difference(updatedLossPayeeFixedIds, lossPayeeFixedIdsBeforeChange).length > 0
            || updatedLossPayees.some((newLossPayee) => {
                return lossPayeesBeforeChange.some((oldLossPayee) => 
                    _.get(oldLossPayee, 'fixedId') === _.get(newLossPayee, 'fixedId')
                    && _.get(oldLossPayee, 'contractNumber') !== _.get(newLossPayee, 'contractNumber'))
            })
            
        
        const isThisChangeNotNeedSync = changedFields.every((key) => {
            return _.get(fieldsNotNeedSyncWhileChange, coverageCode, []).includes(key)
        }) && !islossPayeeChanged;
        _.set(clonedSchedule, localPathToScheduleItem, updatedSchedule);
        let scheduleAfterChange = null
        if (isThisChangeNotNeedSync && forceSync === false) {
            if (onScheduleChangeWithoutSync) {
                scheduleAfterChange = onScheduleChangeWithoutSync(
                    clonedSchedule,
                    path,
                    localPathToScheduleItem,
                    changedFields
                )
            } else {
                return null
            }
        } else if (onScheduleChangeWithSync) {
            scheduleAfterChange = await onScheduleChangeWithSync(clonedSchedule, path)
        } else {
            return null
        }

        const callBackIndex = _.findIndex(scheduleAfterChange.scheduleItems, (item) => {
            return item.publicId_Ext === updatedSchedule.publicId_Ext
        });
        const newLocalPathToSchedule = `scheduleItems[${callBackIndex}]`;
        const scheduleItemAfterSync = _.get(scheduleAfterChange, newLocalPathToSchedule);
        return scheduleItemAfterSync
    }, [scheduleItem, onScheduleChangeWithSync, fieldsNotNeedSyncWhileChange, coverageCode, onScheduleChangeWithoutSync, path]);

    const editSchedule = useCallback(
        (schedule) => () => {
            setOpenedRowItemNumber(_.get(schedule, 'itemNumber'))
            
        },
        [setOpenedRowItemNumber]
    );

    const addSchedule = useCallback(() => {
        const clonedSchedule = _.cloneDeep(scheduleItem);
        const newScheduleItem = {
            '@deserialization-class': scheduleItem.deserializationClass,
            itemData: {
            }
        };
        clonedSchedule.scheduleItems = [...scheduleItem.scheduleItems, newScheduleItem];
        if (onScheduleChangeWithSync) {
            // setLoading(true);
            onScheduleChangeWithSync(clonedSchedule, path).finally(_.noop);
            setOpenedRowItemNumber(clonedSchedule.scheduleItems.length);
        }
    }, [onScheduleChangeWithSync, path, scheduleItem, setOpenedRowItemNumber]);

    const delSelectedSchedule = useCallback(() => {
        modalApi.showConfirm({
            title: messages.scheduleRemoveTitle,
            message: messages.scheduleRemove,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.confirm,
        }).then(async (result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            const clonedSchedule = _.cloneDeep(scheduleItem);
            const scheduleItems = _.get(clonedSchedule, 'scheduleItems');
            const toBeRemovedScheduleItems = _.filter(scheduleItems, (item, index) => _.includes(selectedRowItemIndex, index));
            
            clonedSchedule.scheduleItems = clonedSchedule.scheduleItems.filter(
                (schedule) => !_.includes(toBeRemovedScheduleItems, schedule)
            );
            setSelectedRowItemIndex([])

            if (onScheduleChangeWithSync) {
                // setLoading(true);
                await onScheduleChangeWithSync(clonedSchedule, path);
                // setLoading(false);
                setOpenedRowItemNumber(null);
            }
        }, _.noop);
    }, [
        onScheduleChangeWithSync,
        path,
        scheduleItem,
        selectedRowItemIndex,
        setOpenedRowItemNumber,
        setSelectedRowItemIndex,
    ]);

    const columnData = useMemo(() => {
        const visiblePropertyInfos = visibleColumnIdsOverride ? 
            _.filter(scheduleItem.propertyInfos, (propertyInfo) => 
                visibleColumnIdsOverride.includes(_.get(propertyInfo, 'id'))) : scheduleItem.propertyInfos;
        const orderedPropertyInfo = _.sortBy(visiblePropertyInfos, 'order');
        const itemNO = {
            editable: false,
            id: ItemNO,
            label: "Item No.",
            order: -1,
            required: false,
            valueType: "INTEGER",
        }
        const icons = {
            valueType: 'ICON',
            textAlign: 'right',
            content: [
                // {
                //     icon: 'gw-delete',
                //     onClick: removeSchedule
                // },
                {
                    icon: 'gw-edit',
                    onClick: editSchedule
                }
            ]
        };
        return [itemNO, ...orderedPropertyInfo, icons];
    }, [editSchedule, scheduleItem.propertyInfos, visibleColumnIdsOverride]);

    const onSelectionChange = (ids) => {
        setSelectedRowItemIndex(ids);
    };

    const scheduleItemIndex = _.get(scheduleItem, 'scheduleItems', [])
        .findIndex((item) => _.get(item, 'itemNumber') === openedRowItemNumber)
    const selectedScheduleItem = _.get(scheduleItem, `scheduleItems[${scheduleItemIndex}]`, {})

    const optionFilter = _.get(optionFilters, coverageCode);
    const overrideProps = {
        '@field': {
            isEditable: !readOnly,
        },
        scheduleItemTable: {
            data: columnData,
            title: showTitle ? scheduleItem.displayName : undefined,
            // renderTitleAction: !readOnly ? renderAdd : undefined,
            placeholder: readOnly ? messages.noItems : '',
            selectionType: readOnly ? 'none' : 'multi',
            onSelectionChange,
            selectedRows: selectedRowItemIndex,
            showErrors,
        },
        scheduleItemTitleAction: {
            visible: !readOnly,
        },
        removeSchedule: {
            disabled: selectedRowItemIndex.length <= 0,
            onClick: delSelectedSchedule
        },
        addSchedule: {
            onClick: addSchedule
        },
        scheduleItemDetails: {
            visible: openedRowItemNumber && scheduleItem,
            openedRowItemNumber: openedRowItemNumber,
            scheduleItem: scheduleItem,
            labelPosition: labelPosition,
            syncScheduleChange: syncScheduleChange,
            selectedScheduleItem: selectedScheduleItem,
            showScheduleDetailsInPanelBelow: showScheduleDetailsInPanelBelow,
            path: path,
            visibleFieldIdsOverride: visibleFieldIdsOverride,
            isEditable: !readOnly,
            showErrors,
            // create loss payee
            additionalInterests,
            onSaveAdditionalInterestFn,
            onGettingAdditionalInterestTypeOptions,
            accountContacts,
            optionFilter,
            isCreateAdditionalInterestPanelVisible,
            setIsCreateAdditionalInterestPanelVisible,
        },
        ...generateColumnOverrides(columnData)
    }

    const readValue = useCallback(
        (elementId, schedulePath) => {
            return _.get(scheduleItem, schedulePath);
        },
        [scheduleItem]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
        resolveComponentMap: {
            scheduleItemDetailsComponent: HOScheduleItemDetailsComponent
        },
    };

    // if (isLoading) {
    //     return <Loader loaded={!isLoading} />;
    // }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

WALScheduleItemsComponent.propTypes = {
    value: PropTypes.shape({
        scheduleItems: PropTypes.arrayOf(PropTypes.shape({})),
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onScheduleChangeWithSync: PropTypes.func,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    readOnly: PropTypes.bool,
    showTitle: PropTypes.bool,
    visibleColumnIdsOverride: PropTypes.arrayOf(PropTypes.string),
    visibleFieldIdsOverride: PropTypes.arrayOf(PropTypes.string),
    showErrors: PropTypes.bool,
    openedRowItemNumber: PropTypes.number,
    setOpenedRowItemNumber: PropTypes.func,
    selectedRowItemIndex: PropTypes.arrayOf(PropTypes.number),
    setSelectedRowItemIndex: PropTypes.func,
};

WALScheduleItemsComponent.defaultProps = {
    onScheduleChangeWithSync: undefined,
    readOnly: false,
    labelPosition: undefined,
    showTitle: true,
    visibleColumnIdsOverride: undefined,
    visibleFieldIdsOverride: undefined,
    showErrors: false,
    openedRowItemNumber: undefined,
    setOpenedRowItemNumber: _.noop,
    selectedRowItemIndex: [],
    setSelectedRowItemIndex: _.noop,
};

export default WALScheduleItemsComponent;
