import { Icon } from '@jutro/components';
import { DropdownMenuLink } from '@jutro/router';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm,ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import React,{
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { CRLocationService } from 'wni-capability-quoteandbind-cr';
import { useWniModal } from 'wni-components-platform-react';
import {
    QuoteUtil,
    ValidationIssueUtil,WindowUtil,
    WniProductsUtil,
    ConfigUtil
} from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { PortalConstants } from 'wni-portals-config-js';

import WizardPage from '../../templates/CRWizardPage';
import LocationComponent from './Components/LocationComponent';
import messages from './CRLocationsPage.messages';
import metadata from './CRLocationsPage.metadata.json5';
import LocationsUtil from './Util/CRLocations.util';

const { CPP_PRODUCT_CODE, CR_PRODUCT_CODE, getLobName } = WniProductsUtil;

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

const DEFAULT_SERVICE = CRLocationService;
const DEFAULT_LOB_NAME = getLobName(CR_PRODUCT_CODE);
const CPP_LOB_NAME = getLobName(CPP_PRODUCT_CODE);

function CRLocationsPage(props) {
    const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        resetWizardDataToSnapshot,
        isReadOnly = false,
        isPolicyChange = false,
        LocationService = DEFAULT_SERVICE,
        lobName = DEFAULT_LOB_NAME
    } = props;

    const {
        jobID, sessionUUID,
        baseData: {
            productCode
        } = {},
        lobData: {
            [lobName]: {
                coverables: {
                    unusedLocations = [],
                    locations = []
                }
            }
        }
    } = submissionVM.value;

    const COVERABLES_PATH = `lobData.${lobName}.coverables`;
    const UNUSED_LOCATION_PATH = `${COVERABLES_PATH}.unusedLocations`
    const LOCATION_PATH = `${COVERABLES_PATH}.locations`;
    const CPP_LOCATIONS_PATH = `lobData.${CPP_LOB_NAME}.coverables.locations`;

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CRLocationsPage');

    const [validationIssues, updateValidationIssues] = useState([]);
    const [currentRow, updateCurrentRow] = useState(null);
    const [currentIndex, updateCurrentIndex] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const highlightRowFn = (index) => {
        WniTableRowUtil.setTablePublicIDSelected(`index${index}`, 'locationTable');
    };

    useEffect(() => {
        highlightRowFn(currentIndex);
    }, [currentIndex]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRow(rowData);
            return false;
        }
        updateCurrentRow(rowData);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, `${LOCATION_PATH}[${currentIndex}]`, rowData.value);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardData);
    };
    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshot);
    };

    const updateSubmissionVMForResponse = (res) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${LOCATION_PATH}.value`, res.locations);
        _.set(newSubmissionVM, `${UNUSED_LOCATION_PATH}.value`, res.unusedLocations);
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        if(productCode === CPP_PRODUCT_CODE && res.cppLocations) {
            _.set(newSubmissionVM, CPP_LOCATIONS_PATH, res.cppLocations)
        }
        const stateSimpleInfos =  _.get(res, 'stateSimpleInfos')
        if(!_.isNil(stateSimpleInfos)) {
            _.set(newSubmissionVM.value, `lobData.${lobName}.coverables.stateSimpleInfos`, stateSimpleInfos)
        }
        updateWizardSnapshot(newSubmissionVM);
        return newSubmissionVM;
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${LOCATION_PATH}.children`, []);
        const locIndex = childrenVM.findIndex((vm) => vm.value.rowIdPath === index);
        const itemVM = childrenVM[locIndex];
        let type;
        if(!_.get(itemVM, 'aspects.valid') || !_.get(itemVM, 'aspects.subtreeValid')) {
            type = 'error';
        } else {
            type = 'success';
        }

        const iconDom = <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />   
        
        return  WniTableRowUtil.renderCell(`index${locIndex}`, iconDom);
    };


    const viewOrEditLocation = async (item, index, newVM) => {
        if(!index) {
            return false;
        }
        const vm = newVM || submissionVM;
        const childrenVM = _.get(vm, `${LOCATION_PATH}.children`, []);
        const locationVM = childrenVM.find((itemVM) => itemVM.value.rowIdPath === index);
        const locIndex = childrenVM.findIndex((itemVM) => itemVM.value.rowIdPath === index);
        updateCurrentRow(locationVM);
        updateCurrentIndex(locIndex)
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
    
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const locationService = async(serviceName, serviceData, isAdd) => {
        setLoadingMask(true);
        const locationData = _.get(submissionVM, `${LOCATION_PATH}.value`);
        const oldLocations = _.cloneDeep(locationData) || [];
        const oldLocationIds = oldLocations.map((v) => v.publicID);

        const res = await LocationService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        updateSelection([]);
        updateShowErrors(false);
        generateValidationIssues(res.errorsAndWarnings);
    
        updateCurrentRow(null);
        setLoadingMask(false);
         // for add new vehicle
         if(isAdd) {
            // if find new vehicle, will open the new location detail, so need updateCurrentIndex and updateCurrentRow
            const locationsVM = _.get(newSubmissionVM, `${LOCATION_PATH}.children`, []);
            const locIndex = locationsVM.findIndex((vm) => !oldLocationIds.includes(vm.value.publicID));
            const newLocationVM = locationsVM[locIndex];
            updateCurrentIndex(locIndex);
            updateCurrentRow(newLocationVM)
            return false;
        }
        return newSubmissionVM
    };

    const addLocation = async() => {
        const locationsVM = _.get(submissionVM, `${LOCATION_PATH}`);
        const { _dtoName, _xCenter } = locationsVM;

        const initLocationData = {
            rowIdPath: ConfigUtil.getUuid(),
            isNonSpecific: false,
            address: {country: defaultCountryCode}
        };

        const newLocationVM = viewModelService.create(initLocationData, _xCenter, _dtoName);
        const existingLocations = _.get(locationsVM, `value`, []);
        existingLocations.push(newLocationVM.value);
        _.set(submissionVM, `${LOCATION_PATH}.value`, existingLocations);
        const locIndex = existingLocations.length - 1;
        updateCurrentIndex(locIndex);
        updateCurrentRow(newLocationVM);
        updateWizardData(submissionVM);
        WindowUtil.scrollTo('locationTable')

    };

    const addExistingLocation = async(existingLocations, isAll = false) => {
        const addExistingPublicIds = existingLocations.map((item) => item.publicID);
        await locationService('addExistingLocations', addExistingPublicIds, !isAll);
       
    };

    const addAllExistingLocation = async () => {
        addExistingLocation(unusedLocations, 'isAll')
    };

    
    const removeLocations = async () => {
        modalApi.showConfirm({
            title: messages.removeLocationTitle,
            message: messages.removeLocationDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.Ok,
            cancelButtonText: messages.Cancel,
        }).then(async (result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            syncWizardData(null);
            updateCurrentIndex(null);
            await locationService('removeLocations', selection);
        });
    };

    const cancelLocation = async() => {
        resetWizardDataToSnapshot();
        syncWizardData(null);
        updateCurrentIndex(null);
        updateShowErrors(false);
    }

    const onNextLocation = async (newmVM) => {
        const childrenVM = _.get(newmVM, `${LOCATION_PATH}.children`);
        let index = _.findIndex(childrenVM, (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'rowIdPath.value');
        syncWizardData(null);
        await viewOrEditLocation(null, indexID, newmVM);
        WindowUtil.scrollTo('locationTable')
    };

    const updateLocation = async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        const newSubmissionVM = await locationService('updateLocation', currentRow.value);
        return newSubmissionVM;
    };

    const allLocationValid = () => {
        const allLocationVMs = _.get(submissionVM, `${LOCATION_PATH}.children`);
        return allLocationVMs.every((vm) => vm.aspects.valid && vm.aspects.subtreeValid);
    };

    const onPageNext = useCallback(async () => {
        if(!allLocationValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await LocationService.onPageNext(requestData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, submissionVM]);

    const isDisabledDelete = () => {
        if(_.isEmpty(selection)) {
            return true
        } 
        // find if selection has primary location
        const primaryLocation = _.get(submissionVM, `${LOCATION_PATH}.value`, []).find((item) => item.isPrimary);
        const primaryLocationID = _.get(primaryLocation, 'rowIdPath');
        if(selection.includes(primaryLocationID)) {
            return true
        } 
        return false
    }

    const renderAllUnusedLocationsMenuItem = () => {
        return unusedLocations.map((item) => {
            const { publicID, displayName } = item.address || {};
            return (
                <DropdownMenuLink
                    type="action"
                    onClick={() => addExistingLocation([item])}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        })
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            const newValue = _.clone(value);
            _.set(currentRow.value, path, newValue);
            syncWizardData(currentRow);
        }
    };


    //---------------------
    const overrideProps = {
        '@all': {
            isReadOnly
        },
        '@field': {
           labelPosition: 'left',
        },
        removeLocation: {
            visible: !isReadOnly,
            disabled: isDisabledDelete()
        },
        addLocation: {
            visible: !isReadOnly
        },
        addExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocations.length === 0,
            content: renderAllUnusedLocationsMenuItem(),
        },
        addAllExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocations.length === 0,
        },
        locationTable: {
            path: LOCATION_PATH,
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: isReadOnly ? 'none' : 'multi',
            selectedRows: selection,
        },
        locationDetailContainer: {
            visible: currentRow != null,
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        locationDetails: {
            visible: !_.isEmpty(currentRow),
            currentRow,
            onValueChange: writeValue,
            onValidate,
            showErrors,
            isReadOnly,
            externalData: {
                jobID,
                sessionUUID,
                authHeader
            }
        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: locations.length > 1
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            addLocation,
            addAllExistingLocation,
            removeLocations,
            viewOrEditLocation,
            cancelLocation,
            saveLocation: () => {
                updateLocation().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextLocation: () => {
                updateLocation().then((vm) => {
                    if (vm) {
                        onNextLocation(vm);
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderValidationCell,
            renderPrimaryCell: (item) => _.get(item, `isPrimary`) ? 'X' : '-', 
            renderAddressCell: (item) => LocationsUtil.getPrimaryAddressDisplayName(item.address)
        },
        resolveComponentMap: {
            locationcomponent: LocationComponent
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            showRequiredInfoForFasterQuote
            disableNext={!allLocationValid()}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CRLocationsPage.propTypes = WizardPage.propTypes;
CRLocationsPage.defaultProps = WizardPage.defaultProps;

export default CRLocationsPage;