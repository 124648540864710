module.exports = {
    TRANSACTIONS_MAP: {
        fnol: ['claims', 'fnol'],
        policychange: ['policychange', 'endorsement'],
        quote: ['quoteandbind', 'quote'],
        renewal: ['renewal', 'policyrenewal']
    },
    LOBS_MAP: {
        bop: ['bop', 'BusinessOwners', 'BusinessOwnersLine', 'BOPLine'],
        ca: ['ca', 'CommercialAuto', 'BusinessAuto', 'BusinessAutoLine'],
        cp: ['cp', 'CommercialProperty', 'CommercialPropertyLine', 'CPLine'],
        cpkg: ['cpkg', 'CommercialPackageLine', 'CommercialPackage'],
        gl: ['gl', 'GeneralLiability', 'GeneralLiabilityLine', 'GLLine'],
        ho: ['ho', 'Homeowners', 'HomeownersLine_HOE', 'HOPHomeowners', 'HOPLine', 'HOLine', 'pr'],
        im: ['im', 'InlandMarine', 'InlandMarineLine', 'IMLine'],
        pa: ['pa', 'PersonalAuto', 'PersonalAutoLine', 'auto_per', 'auto'],
        wc7: ['wc7', 'WC7WorkersComp', 'WC7Line', 'WC7WorkersCompLine', 'Workers\' Compensation (v7)'],
        wc: ['wc', 'WorkersComp', 'WorkersCompLine', 'Workers\' compensation'],
        gtp: ['gtp'],
        slq: ['slq']
    }
}
