import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import RTCoverageUtil from '../../util/RTCoverageUtil';
import RTClausesComponentVM from '../../components/RTClausesComponentVM/RTClausesComponentVM';
import RTSingleClauseComponentVM from '../../components/RTSingleClauseComponentVM/RTSingleClauseComponentVM';
import metadata from './RTLineCoverages.metadata.json5';
import styles from './RTLineCoverages.module.scss';
import RTScheduleItemsPopup from '../../components/RTScheduleItemsPopup/RTScheduleItemsPopup';

function RTLineCoverages(props) {
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService,
        loadingLineCoverages,
        setLoadingLineCoverages,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const modalApi = useWniModal();
    const getLineCoverages = useCallback(() => {
        const lineCoverages = _.get(selectedVersion, 'coverages.lineCoverages', []);
        return lineCoverages;
    }, [selectedVersion]);

    const lineCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.lineCoverages`;
    const lineCoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.lineCoverages`;
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const lineCoverages = getLineCoverages();

    const showCoverageDetailsPopup = (coverageToOpen)=>{
        const lineCovVMs = _.get(submissionVM, `${lineCoveragesVMPath}.children`);
        const targetCoverageIndex = lineCovVMs.findIndex((vm)=>vm.value.code_Ext === coverageToOpen.code_Ext);
        const targetCoveragePath = `${lineCoveragesVMPath}.children[${targetCoverageIndex}]`;

        const getCoverageFromLobCoverages = (lobCovs) => {
            const lineCovs = _.get(lobCovs, 'lineCoverages', [])
            return lineCovs.find((cov) => _.get(cov, 'code_Ext') === coverageToOpen.code_Ext)
        }
        const componentProps = {
            isEditable,
            coverageCode: coverageToOpen.code_Ext,
            coveragePath: targetCoveragePath,
            schedule: coverageToOpen.schedule,
            coverage: coverageToOpen,
            coveragesService,
            jobID,
            sessionUUID,
            lobName,
            submissionVM,
            updateSubmissionVM: updateWizardData,
            lobCoveragesPath,
            getCoverageFromLobCoverages,
        };
        return modalApi.showModal(
            <RTScheduleItemsPopup {...componentProps} />
        );
    } 

    const onOpenCoverageDetailsPopup = (clausePatternCode)=>{
        const coverageToOpen = lineCoverages.find((cov)=>cov.code_Ext === clausePatternCode);
        showCoverageDetailsPopup(coverageToOpen)
    }

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = RTCoverageUtil.setClauseValue(lineCoverages, lineCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, lineCoveragePath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            lineCoverages,
            lineCoveragesVMPath,
            lineCoveragePath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = RTCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = RTCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingLineCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingLineCoverages,
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            lineCoverages: {
                loadingClause: loadingLineCoverages,
                path: lineCoveragesVMPath,
                value: lineCoverages,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    RTSingleClauseComponentVM: RTSingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
            }
        };
    }, [
        loadingLineCoverages,
        lineCoveragesVMPath,
        lineCoverages,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
            onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
        },
        resolveComponentMap: {
            RTClausesComponentVM: RTClausesComponentVM,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

RTLineCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
RTLineCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default RTLineCoverages;
