import { defineMessages } from 'react-intl';

export default defineMessages({
    driver: {
        id: 'wni.pe.fnol.pa.directives.contactDetails.Driver',
        defaultMessage: 'Driver'
    },
    person: {
        id: 'wni.pe.fnol.pa.directives.contactDetails.Person',
        defaultMessage: 'Person'
    },
    unknowTips: {
        id: 'wni.pe.fnol.pa.directives.contactDetails.unknowTips',
        defaultMessage: 'If you don\'t know specific {what}, please enter \'Unknown\''
    },
    lastName: {
        id: 'wni.pe.fnol.pa.directives.contactDetails.lastName',
        defaultMessage: 'last name'
    }

});
