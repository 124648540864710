export default {
    personalLinesAdvisor: {
        UIC: 'https://www.wnins.com/r3m0teProXyL0G1n/umialik/guides/TheAdvisor.pdf',
        WNI: 'https://www.wnins.com/r3m0teProXyL0G1n/mutual/guides/TheAdvisor.pdf'
    },
    rtVehicleTypesAdvisor: {
        UIC: 'https://www.wnins.com/r3m0teProXyL0G1n/mutual/guides/RoadAndTrailDescriptionGuide(UIC).pdf',
        WNI: 'https://www.wnins.com/r3m0teProXyL0G1n/mutual/guides/RoadAndTrailDescriptionGuide.pdf'
    },
    walVehicleTypesAdvisor: 'https://www.wnins.com/r3m0teProXyL0G1n/mutual/guides/WatercraftDescriptionGuide.pdf',
}