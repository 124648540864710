import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCommentValidationMessage: {
        id: 'ho.quote.incident.additionalCommentValidationMessage',
        defaultMessage: 'What steps have been taken to prevent a future occurrence? - Eligibility is subject to underwriter review'
    },
    incidentPagecauseOfLossIneligible:{
        id: 'wni.quote.incident.lossCauseIsIneligible.',
        defaultMessage: '{lossCause} is ineligible.'
    }
})