import _ from 'lodash';
import { ConfigUtil, WniAccordionValidationUtil } from 'wni-portals-util-js';
import CAStateInfoConstants from '../config/CAStateSpecificInfoConstants';


function hasIssuanceInvalidFields(dataVM = {}) {
    return false;
}

function setDefaultStateInfoObj(countryCode) {
    // const defaultQuestionSets = [
    //     {
    //         "answers": {
    //             "WCMUndergroundParking": "false",
    //             "WCMMaxEmployees": null,
    //             "WCMLowFloor": "false",
    //             "WCMHighFloor": "false",
    //             "WCMNumShifts": null
    //         },
    //         "code": "WCMStateInfoQuestions"
    //     }
    // ]
    const res = {
        rowIdPath: ConfigUtil.getUuid()
    };
    _.unset(res, 'publicID');
    // _.set(res, 'StateInfoQuestionSets', defaultQuestionSets);

    return res;
}

function isStateInfoInvalid(stateInfo, garageLocationId) {
    const fieldsMap = CAStateInfoConstants.sectionFieldsMap.stateInfoDetail;
    if(fieldsMap.includes(stateInfo.propertyName) && stateInfo.available) {
        if(stateInfo.inputSetMode === 'integer') {
            if(_.isNil(stateInfo.integerValue) || stateInfo.integerValue === "" || Number(stateInfo.integerValue) < 0) {
                return true
            }
        }
        if(stateInfo.inputSetMode === 'dynamicrange') {
            if((stateInfo.rangeValue && _.isEmpty(stateInfo.rangeValue)) || (!_.has(stateInfo, 'rangeValue'))) {
                return true
            }
        }
        if(stateInfo.propertyName === 'GaragingLocation' && _.isNil(garageLocationId)) {
            return true
        }
        if(stateInfo.propertyName === 'ZipCode' && !_.isNil(stateInfo.fieldValue)) {
            if(stateInfo.updated && 
                (stateInfo.fieldValue.length < 5 
                    || (stateInfo.fieldValue.length > 5 && stateInfo.fieldValue.length < 10))) {
                return true
            }
        }
    }
    return false
}

function hasInvalidStateInfo(stateInfoVM) {
    const nonOwedDisplayableDTO = _.get(stateInfoVM.value, 'nonOwedDisplayableDTO');
    const hiredAutoDisplayableDTO = _.get(stateInfoVM.value, 'hiredAutoDisplayableDTO');
    const displayableDTO = nonOwedDisplayableDTO.concat(hiredAutoDisplayableDTO)
    if(displayableDTO) {
        const garageLocationId = _.get(stateInfoVM.value, 'garageLocationId')
        return _.find(displayableDTO, (cov) => {
            return isStateInfoInvalid(cov, garageLocationId)
        });
    }
    // const hiredAutoDisplayableDTO = _.get(stateInfoVM.value, 'hiredAutoDisplayableDTO');
    // if(hiredAutoDisplayableDTO) {
    //     return _.find(hiredAutoDisplayableDTO, (cov) => {
    //         return isStateInfoInvalid(cov)
    //     });
    // }
}

function getAccordionOverridesForStateInfo(StateInfoVM, validationIssues = []) {
    const cardFieldsMap = CAStateInfoConstants.sectionFieldsMap;
    const retval = WniAccordionValidationUtil.getInvalidAccordionCardsOverrides(cardFieldsMap, StateInfoVM);
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(StateInfoVM, 'value.fixedId');
    });
    const errorFields = _.map(errorIssues, (error) => {
        return _.upperFirst(_.get(error, 'relatedEntity.fieldName'));
    });
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        const invalidFields = _.filter(fields, (field) => {
            const idx = errorFields.indexOf(_.upperFirst(field));
            return idx >= 0;
        });
        return invalidFields.length > 0;
    });
    ids.forEach((id) => {
        if (invalidAccordions.indexOf(id) >= 0) {
            _.set(retval, `${id}.errorState`, true);
        }
    });
    return retval;
}

export default {
    hasIssuanceInvalidFields,
    setDefaultStateInfoObj,
    hasInvalidStateInfo,
    getAccordionOverridesForStateInfo
}