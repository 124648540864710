const sectionFieldsMap = {
    locationDetail: [
        'locationNum',
        'locationName',
        'locationCode',
        'address_Ext',
        'numberOfEmployee'
    ],
};

export default {
    sectionFieldsMap,
};
