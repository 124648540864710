import _ from 'lodash';
import moment from 'moment';


/**
 * Get a new ActivityDTO JSON from provided QuoteDataDTO view model object
 * @param {object} quoteDataDtoVM QuoteDataDTO view model object
 * @returns {object} new activity json object with shape of ActivityDTO
 */
function newActivity(quoteDataDtoVM) {
    const retval = {
        accountNumber: _.get(quoteDataDtoVM, 'baseData.accountNumber').value,
        policyNumber: _.get(quoteDataDtoVM, 'quoteID').value,
        subject: 'General reminder (diary)',
        priority: 'normal',
        recurring: false,
        mandatory: false,
        dueDate: new Date(),
        escalationDate: new Date(),
        activityPattern: {
            code: 'general_reminder',
            priority: 'normal'
        }
    };
    return retval;
}

/**
 * Get an ActivityDTO json if the effectiveDat check pases, or null if not.
 * @param {object} quoteDataDtoVM QuoteDataDTO view model object
 * @returns {object} new ActivityDTO json, or null
 */
function newActivityFromEffRateDate(quoteDataDtoVM) {
    let retval = null;
    const rateEffDate = _.get(quoteDataDtoVM, 'baseData.rateBookEffectiveDate_Ext.value');
    const effDate = _.get(quoteDataDtoVM, 'baseData.periodStartDate.value');

    if (rateEffDate) {
        const nowDate = {
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            day: new Date().getDate()
        };

        const rateEffMonment = moment([rateEffDate.year, rateEffDate.month, rateEffDate.day]);
        const effMoment = moment([effDate.year, effDate.month, effDate.day]);
        const nowMoment = moment([nowDate.year, nowDate.month, nowDate.day]);
        const maxDiff = 60;

        if (effMoment.diff(rateEffMonment, 'days') > 0 && effMoment.diff(nowMoment, 'days') >= maxDiff) {
            retval = newActivity(quoteDataDtoVM);
        }
    }

    return retval;
}

function createNewActivityData(activityObj) {
    const newActivityData = {
        policyNumber: activityObj.policyNumber,
        subject: activityObj.subject,
        description: activityObj.description,
        priority: activityObj.priority,
        mandatory: activityObj.mandatory,
        dueDate: new Date(),
        escalationDate: new Date(),
        activityPattern: activityObj.activityPattern
    };

    return newActivityData;
}

/**
 * Get the Account Reservation Activity Data  for the QuotePage
 * @param {string} activityTitle
 * @param {string} activityDesc
 * @param {string} priority
 * @param {string} patternCode
 * @returns {Object} the activity object
 */
function getActivityData(activityTitle, activityDesc, priority, patternCode) {
    const activityObj = {
        // policyNumber: policyNumber,
        subject: activityTitle,
        description: activityDesc,
        priority: priority,
        mandatory: true,
        dueDate: new Date(),
        escalationDate: new Date(),
        activityPattern: {
            code: patternCode,
            priority: priority
        }
    };
    return activityObj;
}


export default {
    newActivity,
    newActivityFromEffRateDate,
    createNewActivityData,
    getActivityData
};
