
import React, {
    useState,
} from 'react';
import _ from 'lodash';
import { HODPCoveragesBaseReadOnlyPage } from 'wni-capability-quoteandbind-ho-react';
import DPCoveragesConfig from './DPCoverages.config';
import DPPrimaryCoverages from './CoveragesSection/DPPrimaryCoverages/DPPrimaryCoverages';

function DPCoveragesReadOnlyPage(props) {

    const {
        // currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        updateWizardData
    } = props;

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            dwellingProperty: {
                offerings
            }
        },
    } = submissionVM.value;

    const [openedAccordions, setOpendedAccordions] = useState(() => {
        const res = [
            'primaryCoverages',
            'commonOptionalCoverages'
        ]
        const selectedVersion = offerings
            .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
        
        const otherOptionalCoverages = _.get(selectedVersion, 'coverages.otherOptionalCoverages_Ext', []);
        const exclusions = _.get(selectedVersion, 'coverages.exclusion_Ext', []);
        if (otherOptionalCoverages.some((coverage) => coverage.selected)
            || exclusions.some((coverage) => coverage.selected)) {
            res.push('otherOptionalCoveragesAndExclusions')
        }
        return res
    });

    const DPOverrideProps = {
        coveragesAccordion: {
            accordionStates: openedAccordions,
            onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
        },
    }

    const componentMapOverride = {
        hoPrimaryCoverages: DPPrimaryCoverages,
    }

    return <HODPCoveragesBaseReadOnlyPage
        wizardData={submissionVM}
        updateWizardData={updateWizardData}
        lobName='dwellingProperty'
        coveragesConfig={DPCoveragesConfig}
        componentMapOverride={componentMapOverride}
        DPOverrideProps={DPOverrideProps}
    />

}

DPCoveragesReadOnlyPage.propTypes = HODPCoveragesBaseReadOnlyPage.propTypes;
DPCoveragesReadOnlyPage.defaultProps = HODPCoveragesBaseReadOnlyPage.defaultProps;

export default DPCoveragesReadOnlyPage;
