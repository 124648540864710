import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { PolicyService } from 'gw-capability-gateway';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import NotesComponent from '../../Components/NotesComponent/NotesComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Notes.metadata.json5';
import messages from './Notes.messages';

class PolicyNotesWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                }),
                getUpdateCountInfo: PropTypes.func.isRequired
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    state = {
        notesData: [],
        loaded: false
    };

    componentDidMount() {
        this.getNotes();
    }

    getNotes = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const translator = this.context;
        const { policyNumber } = policyResponse;
        this.setState({ loaded: false });
        try {
            const notesData = await PolicyService.getNotesForPolicy(policyNumber, authHeader);
            this.setState({
                notesData,
                loaded: true
            });
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: translator(messages.errorLoadingNotes, {
                    policyNumber: policyNumber
                }),
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
    };

    getNotesTileUpdatedCount = () => {
        const {
            fromAccountLanding: {
                policyDetailsData: { getUpdateCountInfo }
            }
        } = this.props;
        getUpdateCountInfo('Notes');
    };

    createNote = async (data) => {
        const {
            authHeader,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const { policyNumber } = policyResponse;
        this.setState({ loaded: false });
        try {
            const policyNoteData = await PolicyService.createNoteForPolicy(
                [policyNumber, data],
                authHeader
            );
            if (!_.isEmpty(policyNoteData)) {
                this.getNotes();
            }
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorCreatingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
    };

    render() {
        const { notesData, loaded } = this.state;
        const translator = this.context;
        const overrides = {
            policyNotes: {
                initialNotesData: notesData,
                createNote: this.createNote,
                noDataMessage: translator(messages.noPolicyNotes),
                visible: loaded,
                openActivitiesNote: false,
                getNotesTileUpdatedCount: this.getNotesTileUpdatedCount
            },
            notesLoader: {
                loaded: loaded
            }
        };
        const resolvers = {
            resolveComponentMap: {
                notescomponent: NotesComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const PolicyNotes = withModalContext(PolicyNotesWithoutModalContext);

export default withAuthenticationContext(PolicyNotes);
