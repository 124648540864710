import React, {
    useContext, useState, useCallback
} from 'react';
import _ from 'lodash';
import {ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import HOCreateBaseAdditionalInterestDetailComponent from './HOCreateBaseAdditionalInterestDetailComponent'

function HOCreateAdditionalInterestPanel(props) {

    const {
        setIsCreateAdditionalInterestPanelVisible,
        onSaveAdditionalInterestFn,
        onGettingAdditionalInterestTypeOptions,
        accountContacts,
    } = props
    const viewModelService = useContext(ViewModelServiceContext);
    const newAdditionalInterestDTOVM = viewModelService.create({
        policyAdditionalInterest: {
            primaryAddress: '',
        }
    }, 'pc', 'wni.edge.capabilities.quote.lob.homeowners.dto.HOAdditionalInterestDTO');
    const [currentRow, updateCurrentRow] = useState(newAdditionalInterestDTOVM);
    const emptyVM = viewModelService.create({}, 'pc', 'wni.edge.capabilities.quote.lob.homeowners.dto.HOAdditionalInterestDTO');
    


    const handleSaveAdditionalInterestFn = async (getAdditionalInterestVM, innerIsComponentValid, handleValidation) => {
        // same logic as BaseAdditionalInterestComponet#handleSave
        if (!innerIsComponentValid) {
            handleValidation();
            return false;
        }
        if (_.get(getAdditionalInterestVM, 'value.policyAdditionalInterest.phoneRequired_Ext')) {
            _.set(getAdditionalInterestVM, 'value.policyAdditionalInterest.phoneRequired_Ext', false);
        }
        // set sendBill_Ext default false value when it is null
        if (_.isNil(_.get(getAdditionalInterestVM, 'value.sendBill_Ext'))) {
            _.set(getAdditionalInterestVM, 'value.sendBill_Ext', false);
        }
        // const latestAddedLossPayeeID = getAdditionalInterestVM.value.policyAdditionalInterest.publicID;
        try {
            await onSaveAdditionalInterestFn(_.get(getAdditionalInterestVM, 'value'));
            // const latestAddedLossPayee = remainAdditionalInterests.find((additionalInterest) => {
            //     return latestAddedLossPayeeID === _.get(additionalInterest, 'policyAdditionalInterest.publicID')
            // })
            // handleLossPayeeChange(latestAddedLossPayee);
            updateCurrentRow(null);
            setIsCreateAdditionalInterestPanelVisible(false);
        } catch (e) {
            _.noop();
        }
    }

    const handleAdditionalInterestTypeValueChangeFn = useCallback((
        newValue,
        vmService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        writeValue
    ) => {
        if (newValue !== 'FIRSTMORTGAGEE') {
            const newAdditionalInterestVM = vmService.clone(getAdditionalInterestVM);
            _.set(newAdditionalInterestVM, 'type', newValue);
            _.set(newAdditionalInterestVM, 'sendBill_Ext', false);
            updateAdditionalInterestVM(newAdditionalInterestVM);
        } else {
            writeValue(newValue, 'type');
        }
    }, []);
   
    const getAdditionalInterestTypeOptions = async({
        publicID, isPerson, isCompany, isBank, isTrust
    }) => {
        let options;
        try {   
            options = await onGettingAdditionalInterestTypeOptions({publicID, isPerson, isCompany, isBank, isTrust});
            // add FIRSTMORTGAGEE to options only when the value is it and currently isBank
            if (_.get(currentRow, 'value.type') === 'FIRSTMORTGAGEE'
                && isBank
                && !options.includes(_.get(currentRow, 'value.type'))
            ) {options.push(_.get(currentRow, 'value.type'))}
        } 
        catch (e) {
            options = [];
        }
        return options;
    };
    const isBank = _.get(currentRow, 'value.policyAdditionalInterest.companyType_Ext', null) === 'Bank';  
    const componentProps = {
        visible: true,
        title: 'title',
        iconClassType: false,
        showCloseBtn: false,
        showCancelBtn: false,
        actionBtnLabel: 'Save',
        newSearchBtnLabel: 'New Search',
        isOpen: true,
        viewModelService,
        additionalInterestVM: newAdditionalInterestDTOVM,
        emptyVM: emptyVM,
        handleSaveAdditionalInterestFn,
        accountContacts,
        handleAdditionalInterestTypeValueChangeFn,
        getAdditionalInterestTypeOptions,
        showLoanNumber: isBank
    }
        
    
    return (<HOCreateBaseAdditionalInterestDetailComponent {...componentProps}/>)
}

export default HOCreateAdditionalInterestPanel;
