import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import BuildingsAndLocationsTemplate from '../../templates/BuildingsAndLocationsTemplate';
import { LocationSummary, EditBuilding } from '../../WizardFileConfig';

function BuildingsPage(props) {
    const { wizardData, jumpTo, updateWizardData } = props;
    const { submissionVM, selectedLocation, selectedBuilding } = wizardData;
    const { authHeader } = useAuthentication();
    const [buildingSubmission, updateBuildingSubmission] = useState({});
    const [propertyCodeList, updatePropertyCodeList] = useState([]);
    const { isComponentValid, onValidate } = useValidation('BuildingsPage');

    const handleNext = useCallback(async () => {
        const locationIdPath = 'lobData.commercialProperty.coverables.locations.children[0].publicID.value';
        const quoteId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
        const sessionId = _.get(submissionVM.value, 'sessionUUID');
        const locationId = _.get(selectedLocation, 'location.publicID') || _.get(submissionVM, locationIdPath);
        const selectedPropertyCodeId = _.get(buildingSubmission.value, 'classCode.code');

        const selectedPropertyCode = propertyCodeList.find((propertyCode) => {
            return propertyCode.code === selectedPropertyCodeId;
        });

        const requestData = {
            ...buildingSubmission.value,
            coverageForm: 'BPP',
            rateType: 'Class',
            classCode: selectedPropertyCode,
            displayName: _.get(buildingSubmission, 'description.value', 'undefined')
        };

        const addOrUpdateBuilding = _.get(buildingSubmission.value, 'publicID')
            ? CPCoverablesService.updateCPBuilding
            : CPCoverablesService.addCPBuilding;

        const response = await addOrUpdateBuilding(
            quoteId,
            locationId,
            requestData,
            sessionId,
            authHeader
        );
        _.set(selectedBuilding, 'building', response);
        return wizardData;
    }, [
        authHeader,
        buildingSubmission,
        propertyCodeList,
        selectedBuilding,
        selectedLocation,
        submissionVM,
        wizardData
    ]);

    const setBuildingSubmission = useCallback(
        (submission, propertyClassCodeList) => {
            updateBuildingSubmission(submission);
            updatePropertyCodeList(propertyClassCodeList);
            updateWizardData(wizardData);
        },
        [updateWizardData, wizardData]
    );

    return (
        <WizardPage
            onNext={handleNext}
            disableNext={!isComponentValid}
            template={BuildingsAndLocationsTemplate}
            showCancel
        >
            <LocationSummary
                submission={_.get(selectedLocation, 'location')}
                onEditClicked={() => jumpTo(0)}
            />
            <hr />
            <EditBuilding
                setBuildingSubmission={setBuildingSubmission}
                onValidate={onValidate}
                {...props}
            />
        </WizardPage>
    );
}

BuildingsPage.propTypes = wizardProps;

export default BuildingsPage;
