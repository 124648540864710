import React, {useState} from "react"
import _ from "lodash";
import { DropdownMenuButton, ToggleField } from "@jutro/components";
import { DisplayColumn, Table } from '@jutro/legacy/datatable';

import { useTranslator } from '@jutro/locale';
import SingleInjuryIncident from "./SingleInjuryIncident/SingleInjuryIncident";
import messages from './InjuryIncidents.messages'
import { getNewContactTempId } from "../util/NewContactUtil";

import { Button } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

const InjuryIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const injuryIncidentInitValue = {
}

const InjuryIncidents = (props) => {
    const {
        injuryIncidents = [],
        updateInjuryIncidents,
        relatedContacts = [],
    } = props;

    const translator = useTranslator();

    const relatedPersonContacts = relatedContacts.filter((contact) => ['UserContact', 'Person'].includes(contact.subtype))

    const [injuryIncidentQuestionValue, setInjuryIncidentQuestion] = useState(() => {
        return injuryIncidents.length > 0 ? InjuryIncidentQuestionValue.Yes : InjuryIncidentQuestionValue.No
    })
    const [singleInjuryIncidentPopupStatus, setSingleInjuryIncidentPopupStatus] = useState({
        isOpen: false,
        originInjuryIncident: injuryIncidentInitValue,
        editingInjuryIncidentIndex: undefined
    })

    const closePopup = () => {
        setSingleInjuryIncidentPopupStatus({
            isOpen: false,
            originInjuryIncident: injuryIncidentInitValue,
            editingInjuryIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = (injuryIncident, editingInjuryIncidentIndex, injured) => {

        const isNewPerson = !relatedPersonContacts.some((contact) => contact.publicID === injured.publicID)

        let updatedInjuryIncident = injuryIncident
        let updatedInjured = injured
        if (isNewPerson) {
            const tempContactID = getNewContactTempId()
            updatedInjuryIncident = {...injuryIncident, injuredContactPublicID: tempContactID}
            updatedInjured = {...injured, publicID: tempContactID}
        }
        let updatedInjuredIncidents
        if (_.isNil(editingInjuryIncidentIndex)) {
            // new added item
            updatedInjuredIncidents = injuryIncidents.concat([updatedInjuryIncident])
        } else {
            // existing item
            updatedInjuredIncidents = injuryIncidents.toSpliced(editingInjuryIncidentIndex, 1, updatedInjuryIncident)
        }
        updateInjuryIncidents(updatedInjuredIncidents, updatedInjured)
        closePopup()
    }

    return <>
        <ToggleField
            id='injuryIncidentQuestion'
            label={translator(messages.InjuryIncidentsQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: InjuryIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: InjuryIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setInjuryIncidentQuestion(value)
            }}
            disabled={injuryIncidents.length > 0}
            value={injuryIncidentQuestionValue}
        />
        {injuryIncidentQuestionValue === InjuryIncidentQuestionValue.Yes &&
            <>
                <Table
                    data={injuryIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                >
                    <DisplayColumn
                        id="injuredSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="injuredLastNameColumn"
                        header={translator(messages.InjuryLastName)}
                        textAlign='center'
                        renderCell={(item) => {
                            const {
                                injuredContactPublicID
                            } = item
                            const injured = relatedPersonContacts.find((contact) => contact.publicID === injuredContactPublicID)
                            return _.get(injured, 'lastName')
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <DropdownMenuButton
                                id={`DropdownMenuBtn_${index}`}
                                icon="gw-expand-more"
                                size="small"
                                iconPosition="right"
                                disabled={singleInjuryIncidentPopupStatus.isOpen}
                            >
                                <DropdownMenuLink
                                    icon="gw-edit"
                                    iconPosition="left"
                                    onClick={() => setSingleInjuryIncidentPopupStatus({
                                        isOpen: true,
                                        originInjuryIncident: item,
                                        editingInjuryIncidentIndex: index
                                    })}
                                >
                                    {translator(messages.Edit)}
                                </DropdownMenuLink>
                                <DropdownMenuLink
                                    icon="gw-delete"
                                    iconPosition="left"
                                    onClick={() => {
                                        const updatedInjuredIncidents = injuryIncidents.toSpliced(index, 1)
                                        updateInjuryIncidents(updatedInjuredIncidents)
                                    }}
                                >
                                    {translator(messages.Delete)}
                                </DropdownMenuLink>
                            </DropdownMenuButton>
                        }}
                    />
                </Table>
                <Button
                    type="outlined"
                    onClick={() => setSingleInjuryIncidentPopupStatus({
                        isOpen: true,
                        originInjuryIncident: injuryIncidentInitValue
                    })}
                    disabled={singleInjuryIncidentPopupStatus.isOpen}
                >
                    {translator(messages.AddInjury)}
                </Button>
                {singleInjuryIncidentPopupStatus.isOpen && <SingleInjuryIncident
                    originInjuryIncident={singleInjuryIncidentPopupStatus.originInjuryIncident}
                    editingInjuryIncidentIndex={singleInjuryIncidentPopupStatus.editingInjuryIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                    relatedPersonContacts={relatedPersonContacts}
                />}
            </>
        }
        
    </>
}

export default InjuryIncidents
