import { EventEmitter2 } from 'eventemitter2';

const events = new EventEmitter2({
    wildcard: true,
    delimiter: '::',
    maxListeners: 0
});

// eslint-disable-next-line import/prefer-default-export
export { events };
