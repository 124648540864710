import React, {
    useCallback, useEffect, useRef, useState
} from 'react';


const LINE_BREAK_CHAR = '\n';
// const LINE_BREAK_TAG = '<br/>';


// workaround to use callback in useState
function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((stateInner, cb) => {
        cbRef.current = cb;
        setState(stateInner);
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
}


/**
 * Format line break chars by replacing them with <br/> tags;
 * @param {string} strValue
 * @param {object} (Optional) className for container and paragaphs
 * @returns {string}
 */
function formatLineBreakChars(strValue, {
    containerClassName = '',
    paragraphClassName = '',
} = {}) {
    if (strValue && strValue.includes(LINE_BREAK_CHAR)) {
        // const formattedVal = strValue.replaceAll(LINE_BREAK_CHAR, LINE_BREAK_TAG);
        const formattedVal = strValue.split(LINE_BREAK_CHAR).map(
            (s) => <p className={paragraphClassName}>{s}</p>
        );
        return (
            <div className={containerClassName}>
                {formattedVal}
            </div>
        );
    }
    return strValue;
}

export default {
    useStateCallback,
    formatLineBreakChars,
};
