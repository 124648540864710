import _ from 'lodash';
import WizardPageJumpUtil from './Wizard/WizardPageJumpUtil';


const BROADRIDGE = 'broadridge';

const SKIP_PATHS = [
    BROADRIDGE
];

/**
 * OOTB skip check
 * @param {string} toPath 
 * @returns {boolean}
 */
function isHeadingToErrorPage(toPath) {
    let retval = false;
    const skipModal = ['knockoutpage', 'logout', 'documenterror']; // 'summary'
    const lastPathName = toPath.replace(/\/?.*\//g, '');
    if (skipModal.includes(lastPathName)) {
        retval =  true;
    }
    return retval;
}

function isHeadingToBroadBridge(toPath) {
    // not using startsWith() to follow design of earlier implementation
    return toPath.includes(BROADRIDGE);
}

/**
 * Checks whether current transaction wizard should skip the Confirm Modal 
 * when Cancel (i.e. Save & Exit) button is clicked
 * @param {string} toPath 
 * @returns {boolean}
 */
function shouldSkipConfirmCancelModalDialog(toPath) {
    if (!toPath) {
        return false;
    }
    // const result = _.find(SKIP_PATHS, (skipPath) => path.includes(skipPath));
    // return !!result;
    const WNICheck = isHeadingToBroadBridge(toPath) 
        || WizardPageJumpUtil.isSkippingWizardLeavePromptURL(toPath)
        || WizardPageJumpUtil.isHeadingToAccountQuotesPage(toPath);
    const OOTBCheck = isHeadingToErrorPage(toPath);
    
    return WNICheck || OOTBCheck;

}

const getURLSearchStr = (params) => {
    const urlParmas = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    return `?${urlParmas}`;
};
const getURLSearchJson = (search) => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params);
};
export default {
    shouldSkipConfirmCancelModalDialog,
    BROADRIDGE,
    getURLSearchStr,
    getURLSearchJson
};
