/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Loader } from '@jutro/components';

import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WniTableRowUtil, DomRenderUtil } from 'wni-portals-util-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';

import {
    ErrorsAndWarningsUtil,
    QuoteUtil,
    VehicleValidationUtil,
    VehicleUtil,
    WniClausesUtil,
    OOSUtil,
    WindowUtil,
    WniAccordionValidationUtil
} from 'wni-portals-util-js';
import { VehicleComponent } from 'gw-capability-policyjob-react';
import {
    CustomFooterV2Component, AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import { WniDriverService } from 'wni-capability-quoteandbind';
import { WizardConstants } from 'wni-portals-config-js';
import styles from './PAVehiclesPage.module.scss';
import metadata from './PAVehiclesPage.metadata.json5';
import customMessages from './PAVehiclesPage.messages';
import PAVehicleUtil from './util/PAVehicleUtil';


function PAVehiclesPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        policyChangeUpdateVehicle,
        policyChangeUnselectUnavailableExtendedCoverage,
        policyChangeGetErrorsAndWarnings,
        policyChangeGetAdditionalInterestTypeOptions,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        policyChangeCheckCanViewAndEdit,
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        updateWizardPageData,
        wizardPageData,
        isSkipping,
        // initialSteps,
        // currentStep,
        // changeNextSteps
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [currentRow, updateCurrentRow] = useState(null);
    const [showErrors, updateShowErrors] = useState(false);
    const [showGaragedAtErrors, updateShowGaragedAtErrors] = useState(false);
    const [showMessages, updateShowMessages] = useState(false);
    const [showLoader, updateShowLoader] = useState(false);
    const [selection, updateSelection] = useState([]);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [validVinInfo, updateValidVinInfo] = useState({}); // cache vin service info
    const [accordionOpendIds, updateAccordionOpendIds] = useState([]);

    const {
        quoteID,
        sessionUUID,
        policyNumber,
        jobID,
        baseData: {
            effectiveDate,
            jobType,
            periodStatus,
            accountContacts_Ext: accountContacts,
            quoteFlow_Ext: quoteFlow,
            submittingQuote_Ext: submittingQuote,
            sourcePolicyType_Ext: sourcePolicyType,
            source_Ext: source
        },
        lobData: {
            personalAuto: {
                isForNamedNonOwner_Ext: isQuoteForNamedNonOwner = false,
                coverables: {
                    availableOwners_Ext: availableOwners = [],
                    garagedAtOptions_Ext: garagedAtOptions = [],
                }
            }
        },
        oossliceDates_Ext: oossliceDates,
    } = submissionVM.value;
    const errorsAndWarningsPath =
    jobType === 'PolicyChange'
        ? 'errorsAndWarnings_Ext.value'
        : 'errorsAndWarnings.value';
    const isQuoteExecuted = !_.isNil(wizardPageData[WizardConstants.policyDiffData]);
    const isRequiredForIssuance = (quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote) || (jobType === 'PolicyChange' && isQuoteExecuted);
    const isDraftOrFirstQuote = quoteFlow === 'draft' || quoteFlow === 'firstquote'
    const hasHardStopMessage = validationIssues.some((issue) => _.endsWith(issue.type, 'error'));
    const baseState = _.get(submissionVM, 'baseData.baseState_Ext.value.code');
    const coverageVMs = WniClausesUtil.getVehicleCoverageVMs(submissionVM);
    const currentVehicleCoverages = VehicleUtil
        .getCurrentVehicleCoverages(coverageVMs, currentRow); // VMListNode

    const fetchFrontendIssues = () => {
        const vehicleVMs = _.get(
            submissionVM,
            'lobData.personalAuto.coverables.vehicles.children'
        );
        const issues = vehicleVMs.flatMap((vm) => {
            return (
                VehicleValidationUtil.getVehicleNotifications({
                    currentRow: vm,
                    translator,
                    messageForIssuance:
                        customMessages.requiredForIssuanceSchema,
                    messageForQuote: customMessages.requiredForQuoteSchema,
                    isQuoteForNamedNonOwner,
                }) || []
            );
        });
        // updateIssuesOnEnter(issues);
        return issues;
    };

    const isVehicleTypeMismatchPolicyType =
        VehicleValidationUtil.eraseVehicleTypeIfNNOPolicyWithNotNNOVehicle(
            _.get(
                submissionVM,
                'lobData.personalAuto.coverables.vehicles.children'
            ),
            isQuoteForNamedNonOwner
        );
    const isAllVehicleValid = VehicleValidationUtil.allVehiclesValid(_.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children'));
    const accountHolderBirthday = _.get(submissionVM.value, 'baseData.accountHolder.dateOfBirth')
        || _.get(submissionVM.value, 'baseData.accountHolderDateOfBirth_Ext');
    const accountNumber = _.get(submissionVM.value, 'baseData.accountNumber');
    const additionalInterestType = _.get(submissionVM, 'lobData.personalAuto.coverables.addInterestTypeCategory');

    const { authHeader } = useAuthentication();
    const [isAddressFlag, updateAddressFlag] = useState(true);
    const [invalidFieldsArr, updateInvalidFieldsArr] = useState([]);
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation,
        invalidFields,
        disregardFieldValidation
    } = useValidation('VehiclePage');

    const onValidationIcon = useCallback(
        (item, index) => {
            const warningsForInsurance = fetchFrontendIssues();
            const paVehicleUtil = PAVehicleUtil({
                vehicleVMList: _.get(
                    submissionVM,
                    'lobData.personalAuto.coverables.vehicles.children'
                ),
                translator,
                coverageVMs,
                isQuoteForNamedNonOwner,
                validVinInfo,
                validationIssues,
                isRequiredForIssuance,
            });
            const result = paVehicleUtil.onVehicleValidationIconCell(warningsForInsurance, index);            
            return result;
        },
        [submissionVM, validationIssues]
    );

    const accordionErrorId = useCallback(() => {
        if (!currentRow) {
            return [];
        }
        const errorStateObj =
            WniAccordionValidationUtil.getAccordionOverridesForVehicles(
                currentRow,
                isRequiredForIssuance,
                currentVehicleCoverages,
                isQuoteForNamedNonOwner,
                invalidFieldsArr
            );
        const errorIds = Object.keys(errorStateObj).filter((key) => errorStateObj[key].errorState);
        return errorIds;
    }, [currentRow, invalidFieldsArr]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            updateInvalidFieldsArr(invalidFields);
            updateAccordionOpendIds(accordionErrorId()); // get the error accordions and expand them
            setTimeout(() => {
                WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
            }, 500);
            return false;
        },
        [accordionErrorId, invalidFields]
    );

    const highlightRowFn = useCallback(
        (activeRow) => {
            const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
            WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'vehicleTable');
        },
        [],
    );

    const onSort = (a, b) => {
        highlightRowFn(currentRow);
        return DatatableUtil.sortString(a, b);
    };

    const checkOwnersFn = () => {
        const vehicleData = _.get(submissionVM.value, 'lobData.personalAuto.coverables.vehicles');
        _.forEach(vehicleData, (vehicle) => {
            if (_.isEmpty(_.get(vehicle, 'owners_Ext'))) {
                _.set(vehicle, 'owners_Ext', undefined);
            }
        });
    };

    const onVINCell = useCallback((items, index, property) => {
        const vin = items[property.id];
        return vin ? vin.toString()
            .substring(vin.length - 4, vin.length) : '';
    }, []);

    const addVehicle = useCallback(
        async () => {
            updateShowLoader(true);
            const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
            // save page temp data
            const oldVehicles = _.cloneDeep(vehicles) || [];
            const oldVehiclesIds = oldVehicles.map((v) => v.publicID);

            switch (jobType) {
                case 'Submission': {
                    const request = {
                        quoteID,
                        sessionUUID,
                        method: 'create'
                    };
                    // update whole submission, page temp data lost
                    submissionVM.value = await WniQuoteVehicleService.updateVehicle(request, authHeader);
                    break;
                }
                case 'PolicyChange': {
                    const request = {
                        quoteID,
                        sessionUUID,
                        policyNumber,
                        jobID,
                        effectiveDate,
                        method: 'create'
                    };
                    submissionVM.value = await policyChangeUpdateVehicle(request, authHeader);
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            const addedVehicle = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children')
                .find((vm) => !oldVehiclesIds.includes(vm.value.publicID));

            updateSelection([]);
            updateWizardSnapshot(submissionVM);

            updateShowLoader(false);
            updateShowErrors(false);
            updateShowMessages(false);
            updateCurrentRow(addedVehicle);
            const pcDisplayIssues = VehicleValidationUtil.getPCDisplayIssues(submissionVM.value);
            updateValidationIssues(pcDisplayIssues);
            if (!_.isEmpty(pcDisplayIssues)) {
                updateShowMessages(true);
            }
            return addedVehicle;
        },
        [authHeader, submissionVM, updateWizardData]
    );
    const updateLookupValidation = (validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    };

    const writeValue = (value, path) => {
        if (AddressChangeVerify(path, 'garagedAt_Ext')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateAddressFlag(false);
        }
        // validation bypass: delete the whole AmountDTO if input is empty
        const amountDTOs = ['statedValue_Ext', 'costNew'];
        if (amountDTOs.includes(path) && value && !value.amount) {
            _.set(currentRow, path, undefined);
        } else {
            _.set(currentRow, path, value);
        }
        const newSubmissionVM = viewModelService.clone(submissionVM);
        updateWizardData(newSubmissionVM);
    };

    const debugInvalidField = useCallback(() => {
        // TODO remove this function when the project is alive
        function findInvalidField(vm) {
            if (!_.isNil(_.get(vm, 'children'))) {
                _.get(vm, 'children').forEach((childrenVM) => {
                    findInvalidField(childrenVM);
                });
            } else {
                Object.keys(vm).filter((k) => !k.startsWith('_')).forEach((k) => {
                    if (!_.isNil(_.get(vm, `[${k}].aspects`)) && (vm[k].aspects.valid === false || vm[k].aspects.subtreeValid === false)) {
                        // console.log(k);
                        if (_.isObject(vm[k])) {
                            findInvalidField(vm[k]);
                        }
                    }
                });
            }
        }
        if (currentRow) {
            findInvalidField(currentRow);
        }
    }, [currentRow]);

    const updateVehicleType = useCallback(
        async () => {
            // if (isQuoteForNamedNonOwner) {
            //     return false;
            // }
            updateShowLoader(true);
            updateShowMessages(false);
            switch (jobType) {
                case 'Submission': {
                    const request = {
                        quoteID,
                        sessionUUID,
                        method: 'updateVehicleType',
                        vehiclePublicID: currentRow.value.publicID,
                        vehicleType_Ext: currentRow.value.vehicleType_Ext
                    };
                    // update whole submission, page temp data lost
                    submissionVM.value = await WniQuoteVehicleService.updateVehicle(request, authHeader);
                    break;
                }
                case 'PolicyChange': {
                    const request = {
                        quoteID,
                        sessionUUID,
                        policyNumber,
                        jobID,
                        effectiveDate,
                        method: 'updateVehicleType',
                        vehiclePublicID: currentRow.value.publicID,
                        vehicleType_Ext: currentRow.value.vehicleType_Ext
                    };
                    submissionVM.value = await policyChangeUpdateVehicle(request, authHeader);
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            VehicleUtil.clearFields(currentRow);
            updateShowLoader(false);
            return true;
        },
        [isComponentValid, currentRow, jobType, updateWizardData, submissionVM,
            handleValidation, quoteID, sessionUUID, authHeader, policyNumber,
            jobID, effectiveDate, policyChangeUpdateVehicle, isAddressFlag, updateAddressFlag]
    );

    const updateVehicle = useCallback(
        async () => {
            if (!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
                handleValidation();
                debugInvalidField();
                return false;
            }
            const isCensusBlockInfoMissing = currentRow.value.isCensusBlockInfoMissing_Ext;
            if ((source === 'Converted' && isCensusBlockInfoMissing) || source !== 'Converted') {
                const addressUtil = AddressVerifiedUtil({
                    authHeader,
                    isAddressFlag,
                    updateAddressFlag: updateAddressFlag,
                    addressVM: _.get(currentRow, 'garagedAt_Ext'),
                    addressPath: 'garagedAt_Ext',
                    updateValidations: updateLookupValidation,
                    writeValue: writeValue,
                    //
                    modalApi,
                });
                const verifiedObj = await addressUtil.onVerified();
                if (!verifiedObj.isVerified && source !== 'Converted') {
                    return false;
                }
            }
            updateShowLoader(true);
            updateShowMessages(false);
            switch (jobType) {
                case 'Submission': {
                    const request = {
                        quoteID,
                        sessionUUID,
                        method: 'update',
                        vehicleToUpdate: currentRow.value
                    };
                    // update whole submission, page temp data lost
                    submissionVM.value = await WniQuoteVehicleService.updateVehicle(request, authHeader);
                    break;
                }
                case 'PolicyChange': {
                    const request = {
                        quoteID,
                        sessionUUID,
                        policyNumber,
                        jobID,
                        effectiveDate,
                        method: 'update',
                        vehicleToUpdate: currentRow.value,
                        isErrorLevel_Ext: isQuoteExecuted
                    };
                    submissionVM.value = await policyChangeUpdateVehicle(request, authHeader);
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            updateSelection([]);
            updateWizardSnapshot(submissionVM);
            updateShowLoader(false);
            // const pcDisplayIssues = VehicleValidationUtil.getPCDisplayIssues(submissionVM.value);

            if (!_.isEmpty(validationIssues) ) {
                let frontendIssues = [];
                if (isDraftOrFirstQuote) {
                    frontendIssues = fetchFrontendIssues();
                }
                const errorsAndWarningsResponse = _.get(submissionVM, errorsAndWarningsPath);
                const allIssues =
                    ErrorsAndWarningsUtil.getValidationIssues(
                        errorsAndWarningsResponse,
                        frontendIssues
                    ) || [];
                const issuesInThisPage =
                    VehicleValidationUtil.getIssuesInVehiclePage(allIssues);
                updateValidationIssues(issuesInThisPage);
                if (!_.isEmpty(issuesInThisPage)) {
                    updateShowMessages(true);
                }
            } 
            return true;
        },
        [isComponentValid, currentRow, jobType, updateWizardData, submissionVM,
            handleValidation, quoteID, sessionUUID, authHeader, policyNumber,
            jobID, effectiveDate, policyChangeUpdateVehicle, isAddressFlag, updateAddressFlag]
    );

    const removeVehicles = useCallback(
        () => {
            modalApi.showConfirm({
                title: customMessages.removeVehicleTitle,
                message: customMessages.removeVehicleDescription,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok,
                cancelButtonText: commonMessages.cancelModel
            })
                .then(async (results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    updateShowLoader(true);
                    updateShowMessages(false);
                    const currentRowID =
                        currentRow && currentRow.value
                            ? currentRow.value.publicID
                            : undefined;
                    updateCurrentRow(null);
                    disregardFieldValidation();
                    const vehicles = _.get(
                        submissionVM,
                        'lobData.personalAuto.coverables.vehicles.value'
                    );
                    const selectedPublicIDs = vehicles
                        .filter((v, index) => selection.includes(index))
                        .map((v) => v.publicID);

                    switch (jobType) {
                        case 'Submission': {
                            const request = {
                                quoteID,
                                sessionUUID,
                                method: 'delete',
                                itemPublicIDs: selectedPublicIDs,
                            };
                            submissionVM.value =
                                await WniQuoteVehicleService.updateVehicle(
                                    request,
                                    authHeader
                                );
                            break;
                        }
                        case 'PolicyChange': {
                            const request = {
                                quoteID,
                                sessionUUID,
                                policyNumber,
                                jobID,
                                effectiveDate,
                                method: 'delete',
                                itemPublicIDs: selectedPublicIDs,
                                IsErrorLevel_Ext: isQuoteExecuted
                            };
                            submissionVM.value =
                                await policyChangeUpdateVehicle(
                                    request,
                                    authHeader
                                );
                            break;
                        }
                        default: {
                            // eslint-disable-next-line no-console
                            console.log(`Unhandled job type: ${jobType}`);
                        }
                    }
                    updateWizardSnapshot(submissionVM);
                    updateSelection([]);
                    if (currentRowID) {
                        const vehicleVMs = _.get(
                            submissionVM,
                            'lobData.personalAuto.coverables.vehicles.children'
                        );
                        const currentVehicle = vehicleVMs.find(
                            (vm) => vm.value.publicID === currentRowID
                        );
                        updateCurrentRow(currentVehicle);
                    }
                    updateShowLoader(false);

                    
                    let frontendIssues = [];
                    if (isDraftOrFirstQuote) {
                        frontendIssues = fetchFrontendIssues();
                    }
                    const errorsAndWarningsResponse = _.get(submissionVM, errorsAndWarningsPath);
                    
                    let allIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarningsResponse, frontendIssues);
                    if (isDraftOrFirstQuote && _.isEmpty(validationIssues)) {
                        allIssues = allIssues.filter((issue) => issue.type !== 'warning');
                    }
                    
                    const issuesInThisPage = VehicleValidationUtil.getIssuesInVehiclePage(allIssues);
                    updateValidationIssues(issuesInThisPage);
                    if (!_.isEmpty(issuesInThisPage)) {
                        updateShowMessages(true);
                    }
                    return true;
                }, () => {
                });
        },
        [authHeader, disregardFieldValidation, selection, currentRow, submissionVM, invalidFields]
    );

    useEffect(() => {
        highlightRowFn(currentRow);
        // check each row owners_Ext isEmpty
        checkOwnersFn();
    }, [currentRow, removeVehicles, submissionVM]);


    const viewOrEditVehicle = useCallback(async (value, index) => {
        const vehicleVM = _.get(submissionVM, `lobData.personalAuto.coverables.vehicles.children.${index}`);
        updateCurrentRow(vehicleVM);
    }, [submissionVM]);

    const renderViewEditDom = () => {
        return (
            <div className="activeRow btn-link">
                {translator(customMessages.viewAndEditLabel)}
            </div>
        );
    };

    const getAdditionalInterestTypeOptions = useCallback(async ({
        publicID, isPerson, isCompany, isBank, isTrust
    }) => {
        let options;
        try {
            switch (jobType) {
                case 'Submission':
                    options = await WniQuoteVehicleService.getAdditionalInterestType(
                        quoteID, sessionUUID, publicID, isPerson,
                        isCompany, isBank, isTrust, authHeader
                    );
                    break;
                case 'PolicyChange':
                    options = await policyChangeGetAdditionalInterestTypeOptions({
                        jobID,
                        sessionUUID,
                        publicID,
                        isPerson,
                        isCompany,
                        isBank,
                        isTrust,
                        authHeader
                    });
                    break;
                default: break;
            }
        } catch (e) {
            options = [];
        }
        return options;
    }, [authHeader, jobID, jobType,
        policyChangeGetAdditionalInterestTypeOptions, quoteID, sessionUUID]);

    const checkCanViewAndEdit = useCallback(async ({
        vehicleType, updateBaseOnNull, baseOnNullExt
    }) => {
        try {
            // policy change
            if (policyChangeCheckCanViewAndEdit) {
                policyChangeCheckCanViewAndEdit({
                    isQuoteForNamedNonOwner, sourcePolicyType, vehicleType, updateBaseOnNull, baseOnNullExt
                });
            } else {
                // new business
                // enable view and edit
                updateBaseOnNull(true);
            }
        } catch (e) {
            _.noop();
        }
    }, [isQuoteForNamedNonOwner, policyChangeCheckCanViewAndEdit, sourcePolicyType]);

    const retrieveBackendValidationIssues = useCallback(async () => {
        switch (jobType) {
            case 'Submission': {
                return WniQuoteVehicleService.getErrorsAndWarnings(quoteID, false, authHeader);
            }
            case 'PolicyChange': {
                // eslint-disable-next-line max-len
                return policyChangeGetErrorsAndWarnings(jobID, false, authHeader, isQuoteExecuted);
            }
            default: {
                // eslint-disable-next-line no-console
                console.log(`Unhandled job type: ${jobType}`);
            }
        }
        return [];
    }, []);

    const initIssues = async () => {
        const errorsAndWarningsResponse = await retrieveBackendValidationIssues();
        const backendIssues =
            ErrorsAndWarningsUtil.getValidationIssues(
                errorsAndWarningsResponse
            ) || [];
        let allValidationIssues = [];
        if (isDraftOrFirstQuote) {
            const frontendIssues = fetchFrontendIssues();
            allValidationIssues = frontendIssues.concat(backendIssues);
        }
        if (_.isEmpty(allValidationIssues)) {
            allValidationIssues = backendIssues;
        }
        const issuesInThisPage =
            VehicleValidationUtil.getIssuesInVehiclePage(allValidationIssues);
        // if(isQuoteExecuted){
        //     issuesInThisPage.forEach((issue)=>{
        //         if(issue.type !== 'error'){
        //             issue.type === 'error'
        //         }
        //     })
        // }
        updateValidationIssues(issuesInThisPage);
        if (!_.isEmpty(issuesInThisPage)) {
            updateShowMessages(true);
        }
    };

    useEffect(() => {
        // auto select first invalid vehicle
        const vehicleVMs = _.get(
            submissionVM,
            'lobData.personalAuto.coverables.vehicles.children'
        );
        const firstInvalidVehicle = vehicleVMs.find((vehicleVM) => {
            // const vehicleCoverages = VehicleUtil.getCurrentVehicleCoverages(
            //     coverageVMs,
            //     vehicleVM
            // );

            const valid = VehicleValidationUtil.isVehicleValid(
                vehicleVM,
                coverageVMs,
                isQuoteForNamedNonOwner,
                isRequiredForIssuance,
                validVinInfo
            );
            return !valid;
        });
        if (!_.isNil(firstInvalidVehicle)) {
            updateCurrentRow(firstInvalidVehicle);
        }
    }, []);

    useEffect(() => {
        if (isSkipping) {
            return;
        }
        if (quoteFlow !== 'draft' && isVehicleTypeMismatchPolicyType
            && (jobType !== 'PolicyChange' || isQuoteExecuted)) {
            // get backend issues after final validation
            initIssues();
            
            // show highlight
            updateShowErrors(true);
        }
    }, [isSkipping]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues
                .concat(
                    OOSUtil.getOOSSliceDatesWarning(
                        OOSUtil.getOOSSliceDatesString(
                            oossliceDates,
                            effectiveDate
                        ),
                        translator
                    )
                )
                .concat(wizardErrorsAndWarnings),
            issueDistinguisher: true,
            visible:
                !_.isEmpty(validationIssues) ||
                wizardErrorsAndWarnings.length > 0 ||
                OOSUtil.getOOSSliceDatesString(oossliceDates, effectiveDate)
                    .length > 0,
            scrollToIssues: true,
            issueRenderFn: DomRenderUtil.issueRenderFn
        },
        addVehicle: {
            onClick: addVehicle,
            disabled:
                currentRow ||
                !isComponentValid ||
                !isAllVehicleValid ||
                VehicleValidationUtil.namedNonOwnerVehicleExist(
                    _.get(
                        submissionVM,
                        'lobData.personalAuto.coverables.vehicles.children'
                    )
                ),
        },
        removeVehicle: {
            onClick: removeVehicles,
            disabled: selection.length <= 0,
        },
        vehicleTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            // selectedRows: selection
        },
        vehicleValidationIcon: {
            renderCell: onValidationIcon,
        },
        vehicleIndex: {
            renderCell: (value) => {
                return WniTableRowUtil.renderCell(
                    value.publicID,
                    value.vehicleNumber ? value.vehicleNumber : '-'
                );
            },
        },
        vin: {
            renderCell: onVINCell,
        },
        type: {
            visible: isQuoteForNamedNonOwner,
            renderCell: (value) => {
                if (value.vehicleType_Ext) {
                    return translator({
                        id: `typekey.VehicleType.${value.vehicleType_Ext}`,
                    });
                }
                return '-';
            },
        },
        year: {
            visible: !isQuoteForNamedNonOwner,
        },
        viewOrEdit: {
            label: renderViewEditDom(),
            onClick: viewOrEditVehicle,
            disabled: currentRow,
        },
        vehicle: {
            getAdditionalInterestTypeOptions,
            visible: currentRow != null,
            vehicleVM: currentRow,
            onValueChange: writeValue,
            availableOwners,
            garagedAtOptions,
            coverages: currentVehicleCoverages,
            isQuoteForNamedNonOwner,
            accountNumber,
            accountContacts,
            accountHolderBirthday,
            additionalInterestType,
            validVinInfo,
            updateValidVinInfo,
            quoteStatus: periodStatus || {
                code: _.get(submissionVM, 'value.status'),
            },
            quoteFlow,
            submittingQuote,
            showErrors,
            showGaragedAtErrors,
            updateShowGaragedAtErrors,
            accordionOpendIds,
            updateAccordionOpendIds,
            onValidate,
            checkCanViewAndEdit,
            updateVehicleType,
            updateCacheOptions: updateWizardPageData,
            getCacheOptions: wizardPageData,
            isRequiredForIssuance,
            shouldSkipInitialization: isSkipping,
            baseState,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onSort
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            vehiclecomponent: VehicleComponent,
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM]
    );

    const hideButtonsProps = {
        showNext: !currentRow,
        showPrevious: !currentRow,
        showCancel: !currentRow
    };

    const onNextVehicle = useCallback(() => {
        updateCurrentRow(null);
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        const index = _.findIndex(vehicleVMs,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === vehicleVMs.length - 1) {
            updateCurrentRow(_.get(vehicleVMs, 0));
            return;
        }
        updateCurrentRow(_.get(vehicleVMs, index + 1));
    }, [currentRow, submissionVM]);

    const getFooterButtons = useCallback((onNext) => {
        if (!currentRow) {
            return (<div />);
        }
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        const nextVisible = vehicleVMs.length > 1;
        return (
            <CustomFooterV2Component
                saveAndNextMessage={translator(customMessages.saveNextLabel)}
                handleCancel={() => {
                    resetWizardDataToSnapshot();
                    updateCurrentRow(null);
                }}
                handleSaveAndClose={() => {
                    updateShowGaragedAtErrors(true);
                    updateVehicle().then((valid) => {
                        if (valid) {
                            updateCurrentRow(null);
                        }
                    });
                }}
                handleSaveAndNext={() => {
                    updateShowGaragedAtErrors(true);
                    updateVehicle().then((valid) => {
                        if (valid) {
                            onNextVehicle();
                        }
                    });
                }}
                nextButtonVisible={nextVisible}
            />
        );
    }, [currentRow, submissionVM, isAddressFlag, updateAddressFlag, invalidFields]);

    const renderPageContent = useCallback(({ onNext }) => {
        return showLoader ? (<Loader showLoader />) : (
            <React.Fragment>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    onModelChange={updateWizardData}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    showErrors={showErrors}
                />
                {getFooterButtons(onNext)}
            </React.Fragment>
        );
    }, [overrideProps, resolvers, showErrors, showLoader, submissionVM, onValidate]);

    const checkVehicleEmpty = useCallback(
        () => {
            const vehicleSize = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
            return !_.isEmpty(vehicleSize);
        },
        [submissionVM]
    );

    const isNNOVehicle = useCallback(() => {
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children', []);
        let frontendIssues = [];
        if (VehicleUtil.isNNO(vehicleVMs, isQuoteForNamedNonOwner)) {
            let message = translator(customMessages.otherVehicleTypeExistsForNNO);
            if (vehicleVMs.length > 1) {
                message = translator(customMessages.otherVehicleTypeExistsForNNOAndMoreThanOne);
            }
            frontendIssues = frontendIssues.concat([{
                reason: message,
                type: 'error'
            }]);
        }
        // const issuesInThisPage = VehicleValidationUtil.getIssuesInVehiclePage(frontendIssues);
        updateValidationIssues(frontendIssues);
        if (!_.isEmpty(frontendIssues) && !showMessages) {
            updateShowMessages(true);
            return false;
        }
        return true;
    }, [submissionVM, isQuoteForNamedNonOwner]);

    const isNNOVehicleExistInSelectType = useCallback(() => {
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children', []);
        let frontendIssues = [];
        if (!isQuoteForNamedNonOwner && VehicleUtil.isSelect(vehicleVMs)) {
            const message = translator(customMessages.nnoVehicleTypeExistsForSelect);
            frontendIssues = frontendIssues.concat([{
                reason: message,
                type: 'error'
            }]);
        }
        // const issuesInThisPage = VehicleValidationUtil.getIssuesInVehiclePage(frontendIssues);
        updateValidationIssues(frontendIssues);
        if (!_.isEmpty(frontendIssues) && !showMessages) {
            updateShowMessages(true);
            return false;
        }
        return true;
    }, [submissionVM, isQuoteForNamedNonOwner, showMessages, translator]);

    useEffect(() => {
        registerInitialComponentValidation(() => checkVehicleEmpty()
            && isAllVehicleValid
            && isNNOVehicle()
            && isNNOVehicleExistInSelectType()
            && isVehicleTypeMismatchPolicyType);
    }, [registerInitialComponentValidation, isAllVehicleValid,
        checkVehicleEmpty, isNNOVehicle, isNNOVehicleExistInSelectType]);

    const unSelectDriverCoverages = useCallback(() => {
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children', []);
        if (vehicleVMs.every((vm) => !VehicleUtil.isPPAOnVehicle(vm))) {
            switch (jobType) {
                case 'Submission': {
                    WniDriverService
                        .unselectUnavailableExtendedCoverage({
                            quoteID,
                            sessionUUID
                        }, authHeader);
                    break;
                }
                case 'PolicyChange': {
                    policyChangeUnselectUnavailableExtendedCoverage({
                        policyNumber, effectiveDate, jobID
                    }, authHeader);
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
        }
    }, [submissionVM]);

    const onPageNext = async () => {
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children', []);
        const errorsAndWarningsResponse = await retrieveBackendValidationIssues();
        // eslint-disable-next-line max-len
        const backendIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarningsResponse) || [];
        let frontendIssues = [];
        if (isDraftOrFirstQuote) {
            frontendIssues = fetchFrontendIssues();
        }
        // This policy has changed to Named Non Owner, please remove all the existing vehicles
        // and add new one
        // eslint-disable-next-line max-len
        if (VehicleUtil.isNNO(vehicleVMs, isQuoteForNamedNonOwner)) {
            let message = translator(customMessages.otherVehicleTypeExistsForNNO);
            if (vehicleVMs.length > 1) {
                message = translator(customMessages.otherVehicleTypeExistsForNNOAndMoreThanOne);
            }
            frontendIssues = frontendIssues.concat([{
                reason: message,
                type: 'error'
            }]);
        }

        // This policy has changed to Select, there should be no NNO vehicles
        if (!isQuoteForNamedNonOwner && VehicleUtil.isSelect(vehicleVMs)) {
            frontendIssues = frontendIssues.concat([{
                reason: translator(customMessages.nnoVehicleTypeExistsForSelect),
                type: 'error'
            }]);
        }

        const allValidationIssues = frontendIssues.concat(backendIssues);
        const issuesInThisPage = VehicleValidationUtil.getIssuesInVehiclePage(allValidationIssues);
        const updatedIssues = _.differenceBy(issuesInThisPage, validationIssues, 'reason');
        updateValidationIssues(issuesInThisPage);
        if (!_.isEmpty(issuesInThisPage)) {
            if (!showMessages || !_.isEmpty(updatedIssues)) {
                updateShowMessages(true);
                return false;
            }
        }

        // To be turned on only whne Vehicle Data has been updated.
        const shouldUpdateVehicleHistory = !_.isFunction(policyChangeUpdateVehicle);
        if (periodStatus === 'Draft' && shouldUpdateVehicleHistory) {
            await WniQuoteVehicleService.updateVehicleHistory(quoteID, sessionUUID, authHeader);
        }
        // unselect unavailable driver extended coverages
        unSelectDriverCoverages();
        // checkNextSteps(vehicleVMs);
        return submissionVM;
    };
    // const getErrorsAndWarnings = async () => {
    //     let needToCallWarnings = true;
    //     let errorsAndWarningsResponse = submissionVM.errorsAndWarnings_Ext.value;
    //     if (jobType === 'PolicyChange' && !_.isNil(errorsAndWarningsResponse)) {
    //         needToCallWarnings = false;
    //     }
    //     if (needToCallWarnings) {
    //         errorsAndWarningsResponse = retrieveBackendValidationIssues();
    //     }
    //     return errorsAndWarningsResponse;
    // };

    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            alwaysCallOnNext
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            disableNext={showLoader || !isAllVehicleValid || hasHardStopMessage}
            {...hideButtonsProps}
        >
            {renderPageContent}
        </WizardPage>
    );
}

PAVehiclesPage.propTypes = wizardProps;
export default PAVehiclesPage;
