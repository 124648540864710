import _ from 'lodash';
import { ProductUtil } from 'wnice-portals-util-react';
import HOClaimExtension from '../models/HOClaimExtension';
import CPClaimExtension from '../models/CPClaimExtension';
import GeneralClaimExtension from '../models/GeneralClaimExtension';
import CAClaimExtension from '../models/CAClaimExtension';
import PAClaimExtension from '../models/PAClaimExtention';
import WCClaimExtension from '../models/WCClaimExtension';
import DPClaimExtension from '../models/DPClaimExtension';
import RTClaimExtension from '../models/RTClaimExtension';
import WTClaimExtension from '../models/WTClaimExtension';
import CRClaimExtension from '../models/CRClaimExtension';
import CPPClaimExtension from '../models/CPPClaimExtension';

const {
    PA_LOB_NAME,
    HO_LOB_NAME,
    DP_LOB_NAME,
    RT_LOB_NAME,
    WT_LOB_NAME,
    CA_LOB_NAME,
    GL_LOB_NAME,
    BOP_LOB_NAME,
    IM_LOB_NAME,
    WC_LOB_NAME,
    CP_LOB_NAME,
    CR_LOB_NAME,
    CPP_LOB_NAME,
    /** for product code */
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    CR_PRODUCT_CODE,
    CPP_PRODUCT_CODE,
    getProductName
} = ProductUtil;

const POLICY_LINE_EXTENSION_MAP = {
    PersonalAuto: {
        lob: PA_LOB_NAME,
        code: PA_PRODUCT_CODE,
        claimExtension: (claim) => new PAClaimExtension(claim, PA_LOB_NAME)
    },
    Homeowners: {
        lob: HO_LOB_NAME,
        code: HO_PRODUCT_CODE,
        claimExtension: (claim) => new HOClaimExtension(claim)
    },
    HOPHomeowners: {
        lob: HO_LOB_NAME,
        code: HOP_PRODUCT_CODE,
        claimExtension: (claim) => new HOClaimExtension(claim)
    },
    BusinessAuto: {
        lob: CA_LOB_NAME,
        code: CA_PRODUCT_CODE,
        claimExtension: (claim) => new CAClaimExtension(claim, CA_LOB_NAME)
    },
    GeneralLiability: {
        lob: GL_LOB_NAME,
        claimExtension: (claim) => new GeneralClaimExtension(claim, GL_LOB_NAME)
    },
    BusinessOwners: {
        lob: BOP_LOB_NAME,
        claimExtension: (claim) => new GeneralClaimExtension(claim, BOP_LOB_NAME)
    },
    InlandMarine: {
        lob: IM_LOB_NAME,
        claimExtension: (claim) => new GeneralClaimExtension(claim, IM_LOB_NAME)
    },
    WorkersComp: {
        lob: WC_LOB_NAME,
        code: WCM_PRODUCT_CODE,
        claimExtension: (claim) => new WCClaimExtension(claim)
    },
    CommercialProperty: {
        lob: CP_LOB_NAME,
        code: CP_PRODUCT_CODE,
        claimExtension: (claim) => new CPClaimExtension(claim)
    },
    DwellingFire_EXT: {
        lob: DP_LOB_NAME,
        code: DP_PRODUCT_CODE, // code: 'Dwelling',
        claimExtension: (claim) => new DPClaimExtension(claim)
    },
    RoadTrail_EXT: {
        lob: RT_LOB_NAME,
        code: RT_PRODUCT_CODE,
        claimExtension: (claim) =>  new RTClaimExtension(claim)
    },
    Watercraft_EXT: {
        lob: WT_LOB_NAME,
        code: WAL_PRODUCT_CODE,
        claimExtension: (claim) =>  new WTClaimExtension(claim)
    },
    Crime: {
        lob: CR_LOB_NAME,
        code: CR_PRODUCT_CODE,
        claimExtension: (claim) => new CRClaimExtension(claim)
    },
    CommercialPackage: {
        lob: CPP_LOB_NAME,
        code: CPP_PRODUCT_CODE,
        claimExtension: (claim) => new CPPClaimExtension(claim)
    },
}

function getLobExtensionByPolicyType(policyType) {
    const lobExtension = POLICY_LINE_EXTENSION_MAP[policyType];
    return lobExtension;
}

function getLobByPolicyType(policyType) {
    const lobExtension = getLobExtensionByPolicyType(policyType);
    return _.get(lobExtension, 'lob', PA_LOB_NAME);
}

function getCodeByPolicyType(policyType) {
    const lobExtension = getLobExtensionByPolicyType(policyType);
    return _.get(lobExtension, 'code', PA_PRODUCT_CODE);
}

function getDisplayNameByPolicyType(policyType) {
    const lobExtension = getLobExtensionByPolicyType(policyType);
    const code = _.get(lobExtension, 'code', PA_PRODUCT_CODE)
    return getProductName(code);
}


function getCancelPath(submission) {
    const accountNumber = _.get(submission, 'policy.accountNumber');
    const policyNumber = _.get(submission, 'policy.policyNumber');

    if (policyNumber) {
        return `/policies/${policyNumber}/claims`;
    }
    if (accountNumber) {
        return `/accounts/${accountNumber}/claims`;
    }
    return '/claims';
}

export default {
    getCancelPath,
    POLICY_LINE_EXTENSION_MAP,
    getLobByPolicyType,
    getLobExtensionByPolicyType,
    getCodeByPolicyType,
    getDisplayNameByPolicyType,
};
