import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';

import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';


import {
    formatDate,
    formatNumber,
    FormattedDate,
    FormattedNumber,
    //
    Chevron,
    AccordionCard,
} from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/legacy/datatable';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
    ScheduleSummaryComponent,
} from 'wni-capability-gateway-react';




import { Button } from '@jutro/legacy/components';


/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CRCoveragePartComponent(props) {

    const {
        covPart, // CRPolicyLineSummaryPartDTO,
    } = props;

    const {
        label: title,
        //
        coverages,
        // scheduledItems,
        // additionalCoverages,
        exclusions,
        conditions,
        // additionalInsureds,

    } = covPart;

    return (
        // <div>
        //     <CoverageSummaryComponent title="Coverages" clauses={coverages} />
        //     {/* <ScheduleSummaryComponent schedules={scheduledItems} /> */}
        //     {/* <CoverageSummaryComponent title="Additional Coverages" clauses={additionalCoverages} /> */}
        //     <CoverageSummaryComponent title="Policy Conditions" clauses={conditions} />
        //     <CoverageSummaryComponent title="Exclusions" clauses={exclusions} />
        //     {/* <CoverageSummaryComponent title="Additional Insured" clauses={additionalInsureds} /> */}
        //     {/* <ScheduleSummaryComponent schedules={additionalInsureds} /> */}
        // </div>

        <AccordionCard id={`accordionCard_${title}`}
            title={title}
            chevron
        >
            <div>
                <CoverageSummaryComponent title="Coverages" clauses={coverages} />
                {/* <ScheduleSummaryComponent schedules={scheduledItems} /> */}
                {/* <CoverageSummaryComponent title="Additional Coverages" clauses={additionalCoverages} /> */}
                <CoverageSummaryComponent title="Policy Conditions" clauses={conditions} />
                <CoverageSummaryComponent title="Exclusions" clauses={exclusions} />
                {/* <CoverageSummaryComponent title="Additional Insured" clauses={additionalInsureds} /> */}
                {/* <ScheduleSummaryComponent schedules={additionalInsureds} /> */}
            </div>
        </AccordionCard>
    );
    
}

CRCoveragePartComponent.propTypes = {

};

CRCoveragePartComponent.defaultProps = {
    
};


export default CRCoveragePartComponent;