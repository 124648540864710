
import React, {
    useCallback, useState
} from 'react';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import PropTypes from 'prop-types';
import { WNIAddressLookupService } from 'wni-capability-address';
import { RecommendInputComponent } from 'wni-components-platform-react';

const LookupAddressComponent = (props) => {
    const {
        id,
        label,
        path: lookupPath,
        value,
        className,
        required,
        placeholder,
        disabled,
        onSelection,
        writeValue,
        readOnly,
        showErrors,
        autoFilled,
        useAuthenticationData
    } = props;
    const useAuth = useAuthentication();
    const authData = useAuthenticationData || useAuth;
    const { authHeader } = authData;
    const [availableValues, updateAvailableValues] = useState([]);
    const [showLoader, updateShowLoader] = useState(false);

    const lookupServiceFn = useCallback(_.debounce((val) => {
        if (!val) {
            updateAvailableValues([]);
            return false;
        }
        updateShowLoader(true);
        WNIAddressLookupService.lookupAddressUsingString(
            val, authHeader
        ).then((addressList) => {
            updateShowLoader(false);
            const recommendList = addressList ? addressList.map((item) => {
                return {
                    recommendCode: `${item.addressLine1}, ${item.city}, ${item.state}, ${item.postalCode}`,
                    ...item
                };
            }) : [];
            updateAvailableValues(recommendList);
        }).catch(() => {
            updateShowLoader(false);
            updateAvailableValues([]);
        });
    }, 800), []);

    const onValueChange = (val, currentpath, fun) => {
        writeValue(val, lookupPath);
        if(fun === 'onBlur') {
            return false;
        }
        if (!autoFilled) {
            return false;
        }
        lookupServiceFn(val);
    };

    const onSelectionFn = (item, index) => {
        updateAvailableValues([]);
        onSelection(item, index);
    };
    //------------------------------------------
    return (
        <RecommendInputComponent
            id={id}
            path={lookupPath}
            value={value}
            className={className}
            required={required}
            disabled={disabled}
            label={label}
            placeholder={placeholder}
            readOnly={readOnly}
            availableValues={availableValues}
            onValueChange={onValueChange}
            onSelectionChange={onSelectionFn}
            showErrors={showErrors}
            isUpperCase
            showLoader={showLoader}
        />
    );
};

LookupAddressComponent.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    path: PropTypes.string.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    onSelection: PropTypes.func.isRequired,
    writeValue: PropTypes.func.isRequired,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool
};
LookupAddressComponent.defaultProps = {
    label: '',
    value: '',
    placeholder: '',
    required: false,
    disabled: false,
    showErrors: false,
    readOnly: false
};
export default LookupAddressComponent;
