
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { PUCoveragesReadOnlyPage } from 'wni-capability-quoteandbind-pu-react';

function PUCoveragesChangeReadOnlyPage(props) {

    return (
        <PUCoveragesReadOnlyPage {...props} />
    );

}

PUCoveragesChangeReadOnlyPage.propTypes = PUCoveragesReadOnlyPage.propTypes;
PUCoveragesChangeReadOnlyPage.defaultProps = PUCoveragesReadOnlyPage.defaultProps;

export default PUCoveragesChangeReadOnlyPage;