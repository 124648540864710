import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { useWniModal } from 'wni-components-platform-react';
import { CAPolicyDetailsChangeService } from 'wni-capability-policychange-ca';
import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    WniDateUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';

import { WizardConstants } from 'wni-portals-config-js';

import { WizardErrorContext } from 'wni-portals-wizard-react';
import { BRCLMessages }from 'wni-platform-translations';

import moment from 'moment';
import {
    CAWizardPage as WizardPage
} from 'wni-capability-quoteandbind-ca-react';

// import styles from './CAPolicyDetailsPage.module.scss';
import metadata from './CAPolicyDetailsChangePage.metadata.json5';
// import messages from './CAPolicyDetailsPage.messages';

function CAPolicyDetailsChangePage(props) {
    const wizardErrorContext = useContext(WizardErrorContext);
    // const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        wizardSnapshot,
        savePolicyDetailsData,

        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        clearVisitedStepsFromQuotePage,
    } = props;

    const {
        updateWizardIssues,
    } = wizardErrorContext;

    const {
        jobID: quoteID, 
        sessionUUID,
        // policyInfoSource_Ext: policyInfoSource,
        baseData,
        // lobData: {
        //     workersComp: {
        //         // policyDetails: {
        //         //     organizationTypeOptions,
        //         // }
        //     }
        // },
        // bindData,
        // quoteData,
        changeData: {
            maximumEffectiveDate
        }
    } = submissionVM.value;

    const {
        // baseState_Ext: baseState,
        // quoteFlow_Ext: quoteFlow,
        effectiveDate_Ext: transactionEffectiveDate
        // productCode
    } = baseData;

    const {
        [WizardConstants.landingPage]: landingPage,
        // [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    // const history = useHistory();
    const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    // const viewModelService = useContext(ViewModelServiceContext);
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    // const policyDetailPath = 'lobData.workersComp.policyDetails';

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CAPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            // if (path === 'baseData.effectiveDate_Ext') {
            //     _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            // }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    
    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
        return maxDate
    }, [submissionVM, maximumEffectiveDate])

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate()
        const maxDate = effectiveMaxDate()
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf()
        
        return ((minDif >= 0) && (maxDif >= 0))
    }, [effectiveMaxDate, submissionVM]);
    

    const CAPolicyDetailsValidation = useCallback(() => {
        return isEffectiveDateValid()
    }, [isEffectiveDateValid]);

    // =======================================================================
    const pageOnInit = useCallback(() => {
        if (landingPage) {
            //gray out all following pages when first enter
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, 'changeData.description', '');
            updateSubmissionVM(newSubmissionVM);
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'CAPolicyDetailsPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            clearVisitedStepsFromQuotePage();
            //
            setLoadingMask(false);
        }
    }, []);
    // =======================================================================

    useEffect(() => {
        // A better solution might be expected in the future
        updateWizardIssues([{
            type: 'info',
            reason: translator(BRCLMessages.BR_PolicyChange_Details),
        }]);
        //
        pageOnInit();
    }, []);

    useEffect(() => {
        registerComponentValidation(CAPolicyDetailsValidation);
    }, [registerComponentValidation, transactionEffectiveDate]);


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );
    const initialEffectiveDate = _.get(wizardSnapshot.value, 'baseData.effectiveDate_Ext')
    const getOOSPeriods = useCallback(() => {
        const oOSSliceDates = _.get(submissionVM.value, 'changeData.oossliceDates_Ext');
            let oOSStartDate = _.get(wizardSnapshot.value, 'baseData.periodStartDate')
            let oOSEndDate = _.get(wizardSnapshot.value, 'baseData.periodEndDate')
            
            const oOSSliceDatesInMoment = oOSSliceDates.map((res) => {
                return moment(res)
            })
            const sortOOSSliceDatesInMoment = oOSSliceDatesInMoment.sort((a, b) => a.diff(b))
            const oOSEndDateIndex = sortOOSSliceDatesInMoment.findIndex((res) => res.isAfter(moment(initialEffectiveDate)))
            if (oOSEndDateIndex !==-1 ) { 
                oOSEndDate = sortOOSSliceDatesInMoment[oOSEndDateIndex] 
                if (oOSEndDateIndex > 0 ) { 
                oOSStartDate = sortOOSSliceDatesInMoment[oOSEndDateIndex-1]
                }
            }
            if (oOSEndDateIndex ===-1 && (sortOOSSliceDatesInMoment.length>0 )) {
                oOSStartDate = sortOOSSliceDatesInMoment[sortOOSSliceDatesInMoment.length-1]
            }
            return {oOSStartDate, oOSEndDate}
    }, [initialEffectiveDate, submissionVM.value, wizardSnapshot.value])

    
    const effectiveMinDate = useCallback(() => {
        const {oOSStartDate} = getOOSPeriods()
        return WniDateUtil.getDateObj(oOSStartDate)
    }, [getOOSPeriods])

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        // const caPolicyDetailsData = _.get(submissionVM.value, 'lobData.workersComp.policyDetails')
        // const questionSetData = _.get(submissionVM.value, 'lobData.workersComp.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        const changeData = _.get(submissionVM.value, 'changeData')
        const res = await savePolicyDetailsData(
            quoteID, 
            sessionUUID,
            // questionSetData,
            quoteBaseData,
            changeData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);
        
        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: effectiveMinDate(),
                maxDate: effectiveMaxDate(),
                showErrors
            },
        
            
        }
    }, [effectiveMaxDate, showErrors, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: {

        },
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAPolicyDetailsChangePage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
};

CAPolicyDetailsChangePage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: CAPolicyDetailsChangeService.saveCAPolicyDetailsData,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
} 
export default CAPolicyDetailsChangePage;