import _ from 'lodash';

function PolicyChangeQuoteUtil(props) {
    const {
        intl, // from IntlContext,
        translator, // from TranslatorContext, or useTranslator(),
        totalCost: {
            amount: totalCostAmount,
            currency: totalCostCurrency,
        } = {},
        transactionCost: {
            amount: transactionCostAmount,
            currency: transactionCostCurrency,
        } = {},
        currentMessages,
    } = props;

    /**
     * Get the PolicyChange notification message on premium change
     * @returns {array} vaidation message
     */
    const getPremiumDiffMessage = () => {
        if (_.isNil(totalCostAmount)) {
            return [];
        }
        const formattedAmount = intl.formatNumber(totalCostAmount, {
            style: 'currency',
            currency: totalCostCurrency,
            currencyDisplay: 'symbol' // 'code'
        });
        const absTransactionCostAmount = transactionCostAmount;
        const formattedAmountDiff = intl.formatNumber(Math.abs(absTransactionCostAmount), {
            style: 'currency',
            currency: transactionCostCurrency,
            currencyDisplay: 'symbol' // 'code'
        });
        const msg = {
            type: 'info',
            reason: translator(currentMessages.infoValidationMessagesDesc, {
                newPremium: formattedAmount,
                status: transactionCostAmount < 0 ? translator(currentMessages.infoDecrease) : translator(currentMessages.infoIncrease),
                changedIncost: formattedAmountDiff,
            })
        };
        return [msg];
    };

    /**
     * Get the PolicyChange notification title on premium change
     * @returns {string} vaidation title
     */
    const getPremiumDiffTitle = () => {
        const retval = transactionCostAmount < 0
            ? translator(currentMessages.infoValidationMessages, { status: 'decrease' })
            : translator(currentMessages.infoValidationMessages, { status: 'increase' });
        return retval;
    };

    return {
        getPremiumDiffMessage,
        getPremiumDiffTitle,
    };
}

export default PolicyChangeQuoteUtil;
