
const notImmediatelyUpdateOnSelectCoverages = [
    // Some custom coverage need update to PC immediately
    'UMUIMAKCSLCustomCov_Ext',
    // 26 Full Safety Glass Coverage
    'PAGlassCov_Ext',
    // 34 New Vehicle Replacement Cost Other than Collision Coverage
    'PANewVehRCAKOTCCov_EXT',
    'PANewVehRCOTCCov_EXT',
    // 35 New Vehicle Replacement Cost Collision Coverage
    'PANewVehRCAKCollCov_Ext',
    'PANewVehRCCollCov_EXT',
    // 42 Key Replacement And Related Services Coverage
    // 'PAKeyRepCov_Ext',
    // 'PAKeyRepAKCov_Ext',
    // 44 Accident Forgiveness
    'PAAcdntForCov_Ext',
    // 45 Custom Equipment Coverage
    'PACustomEquipCov_Ext',
    // 46 Additional Resident of Your Household
    'PAAddlHoResCov_Ext',
    // 47 Optional Limits Transportation Expenses
    // 'PAOptLimitTrans_Ext',
    // 48 SD Auto Death Benefits
    'PADeathDisabilityCov',
    // 49 SD Total Disability Benefits
    'PADeathDisabilityCov_Ext',
];

const notImmediatelyUpdateOnUnSelectCoverages = [
    // 26 Full Safety Glass Coverage
    'PAGlassCov_Ext',
    // 34 New Vehicle Replacement Cost Other than Collision Coverage
    'PANewVehRCAKOTCCov_EXT',
    'PANewVehRCOTCCov_EXT',
    // 35 New Vehicle Replacement Cost Collision Coverage
    'PANewVehRCAKCollCov_Ext',
    'PANewVehRCCollCov_EXT',
    // 42 Key Replacement And Related Services Coverage
    // 'PAKeyRepCov_Ext',
    // 'PAKeyRepAKCov_Ext',
    // 44 Accident Forgiveness
    'PAAcdntForCov_Ext',
    // 45 Custom Equipment Coverage
    'PACustomEquipCov_Ext',
    // 46 Additional Resident of Your Household
    'PAAddlHoResCov_Ext',
    // 47 Optional Limits Transportation Expenses
    // 'PAOptLimitTrans_Ext',
    // 48 SD Auto Death Benefits
    'PADeathDisabilityCov',
    // 49 SD Total Disability Benefits
    'PADeathDisabilityCov_Ext',
];

const coverageCodesOfImmediatelyUpdateCoverageTerms = [
    // Some custom coverage need update to PC immediately
    'UMUIMAKCSLCustomCov_Ext',
    // 1 Liability - Bodily Injury
    'PABILiabilityCov',
    // 1 Liability - Bodily Injury NNO
    'PA_NO_Liab_BI_EXT',
    // 2 Liability - Property Damage
    'PAPDLiabilityCov',
    // 2 Liability - Property Damage NNO
    'PA_NO_Liab_PD_EXT',
    // 3 Liability - Single Limit
    'PALiabilityCSLCov',
    // 3 Liability - Single Limit NNO
    'PA_NO_Liab_CSL_EXT',
    // 4 Personal Injury Protection Stacked
    'PAPIP_Stack_MN',
    // 5 Personal Injury Protection Not Stacked
    'PAPIPNonStack_MN',
    // 6 Personal Injury Protection
    'PAPIPMNME_Ext',
    // 8 Uninsured Motorists Coverage Single Limit
    'PAUMNonStackIACSL_Ext',
    // 8 Uninsured Motorists Coverage Single Limit
    'PAUMWICovCSL_EXT',
    // 8 Uninsured Motorists Coverage Single Limit
    'PA_NO_UMWI_CSL_Ext',
    // 8 Uninsured Motorists Coverage Single Limit
    'PANonOwnedUMIA_CSL_Ext',
    // 8 Uninsured Motorists Coverage - Single Limit
    'PAUMCSLSDCov_EXT',
    // 8 Uninsured Motorists Coverage - Single Limit
    'PA_NO_UMCSLSD_EXT',
    // 9 Uninsured Motorists Coverage Split
    'PAUMNonStackIA_Ext',
    // 9 Uninsured Motorists Coverage Split
    'PAUMWICov_Ext',
    // 9 Uninsured Motorists Coverage Split
    'PA_NO_UMWI_Split_Ext',
    // 9 Uninsured Motorists Coverage Split
    'PANonOwnedUMIA_Ext',
    // 9 Uninsured Motorists Coverage - Split
    'PAUMSplitSDCov_EXT',
    // 9 Uninsured Motorists Coverage - Split
    'PA_NO_UMSplitSD_EXT',
    // 10 Underinsured Motorists Coverage Split
    'PAUIMNonStackIA_Ext',
    // 10 Underinsured Motorists Coverage Split
    'PA_NO_UIMIA_EXT',
    // 10 Underinsured Motorists Coverage Split
    'PAUIMWICov_Ext',
    // 10 Underinsured Motorists Coverage Split
    'PA_NO_UIMWI_EXT',
    // 10 Underinsured Motorists Coverage - Split
    'PA_NO_UIMSD_EXT',
    // 10 Underinsured Motorists Coverage - Split
    'PAUIMSplitSDCov_EXT',
    // 11 Underinsured Motorists Coverage Single Limit
    'PAUIMNonStackCSLIA_Ext',
    // 11 Underinsured Motorists Coverage Single Limit
    'PA_NO_UIMIACSL_EXT',
    // 11 Underinsured Motorists Coverage Single Limit
    'PAUIMWICovCSL_EXT',
    // 11 Underinsured Motorists Coverage Single Limit
    'PA_NO_UIMWICSL_EXT',
    // 11 Underinsured Motorists Coverage - Single Limit
    'PAUIMCSLSDCov_EXT',
    // 11 Underinsured Motorists Coverage - Single Limit
    'PA_NO_UIMCSLSD_EXT',
    // 12 Underinsured Motorists Coverage - Bodily Injury - Split
    'PAUIMMnBISplitCov_Ext',
    // 12 Underinsured Motorists Coverage - Bodily Injury - Split
    'PA_NO_UIMMNSplit_Ext',
    // 13 Underinsured Motorists Coverage - Bodily Injury - Single Limit
    'PAUIMMnBICSLCov_Ext',
    // 13 Underinsured Motorists Coverage - Bodily Injury - Single Limit
    'PA_NO_UIMMNCSL_Ext',
    // 14 Uninsured / Underinsured Motorists Coverage - Bodily Injury
    'PAUMUIMAkBICov_EXT',
    // 14 Uninsured / Underinsured Motorists Coverage - Bodily Injury
    'PA_NO_UMUIMAK_BI_Ext',
    // 15 Uninsured / Underinsured Motorists Coverage Property Damage - $250 Ded
    'PAUMUIMAkPDCov_EXT',
    // 15 Uninsured / Underinsured Motorists Coverage Property Damage - $250 Ded
    'PA_NO_UMUIMAK_PD_Ext',
    // 16 Uninsured / Underinsured Motorists Coverage - Single Limit
    'PAUMUIMAkCSLCov_EXT',
    // 16 Uninsured / Underinsured Motorists Coverage - Single Limit
    'PA_NO_UMUIMAK_CSL_Ext',
    // 24 Stated Amount Other Than Collision
    'PAStatedAmtCov_Ext'
];

// See CoverageUtil.DriverCoveragesPattern
const driverCoveragePatternCodes = [
    // Don't show driver coverages
    // Additional Resident of Your Household
    'PAAddlHoResCov_Ext',
    // Auto Death Benefits
    'PADeathDisabilityCov', // Enable it for PAQuotePage
    // Total Disability Benefits
    'PADeathDisabilityCov_Ext', // Enable it for PAQuotePage
    // Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual
    'PAENORegVehUseIndCov',
    // Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual&Residents
    'PAENORegVehUseResCov',
    'PAENORegVehUseIndMedCov',
    'PAENORegVehUseResMedCov',
    'PAENOPubVehUseCovInd_EXT',
    'PAENOPubVehUseCovRes_EXT',
    'PAENOPubVehUseCovIndMed_EXT',
    'PAENOPubVehUseCovResMed_EXT'
];

/**
 * Checks whether the provided clause is driver coverage
 * @param {object} coverageDTO instance of CoverageDTO
 * @returns {boolean}
 */
function isDriverCoverage(coverageDTO = {}) {
    const {
        code_Ext: clausePatternCode
    } = coverageDTO;
    return clausePatternCode && driverCoveragePatternCodes.includes(clausePatternCode);
}

function isNotDriverCoverage(coverageDTO = {}) {
    return !isDriverCoverage(coverageDTO);
}

function isDriverCoveragePatternCode(patternCode) {
    return driverCoveragePatternCodes.includes(patternCode);
}

export default {
    notImmediatelyUpdateOnSelectCoverages,
    notImmediatelyUpdateOnUnSelectCoverages,
    coverageCodesOfImmediatelyUpdateCoverageTerms,
    //
    isNotDriverCoverage,
    isDriverCoveragePatternCode,
};
