import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import SummaryHeading from '../SummaryHeader/SummaryHeader';
import metadata from './BuildingSummary.metadata.json5';
import styles from './BuildingSummary.module.scss';
import messages from '../../../PECPWizard.messages';

class BuildingSummary extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        submission: PropTypes.shape({}).isRequired,
        onEditClicked: PropTypes.func.isRequired,
        summaryIcon: PropTypes.string
    };

    static defaultProps = {
        summaryIcon: 'mi-check'
    };

    propertyCodeToString = (code) => {
        if (!code || ['code', 'classification'].some((prop) => !code[prop])) {
            return '';
        }
        return `${_.get(code, 'code.value', code.code)}: ${_.get(
            code,
            'classification.value',
            code.classification
        )}`;
    };

    renderAccordionHeader = (type) => {
        const { summaryIcon, submission: building } = this.props;
        const translator = this.context;
        const title = translator(messages.buildingSummary);

        return (isOpen) => (
            <SummaryHeading
                isOpen={isOpen}
                type={type}
                displayName={building.name}
                title={title}
                icon={summaryIcon}
            />
        );
    };

    getPercentageSprinklered = () => {
        const { submission } = this.props;
        return (
            <FormattedNumber
                id="getPercentageSprinklered"
                value={_.get(submission, 'percentageSprinklered') / 100}
                style={['percent']}
            />
        );
    };

    onEditBuildingClicked = () => {
        const { onEditClicked } = this.props;
        if (onEditClicked) {
            onEditClicked();
        }
    };

    render() {
        const { submission: building } = this.props;

        const overrideProps = {
            '@field': {
                readOnly: true
            },
            buildingSummaryCard: {
                renderHeader: this.renderAccordionHeader('location')
            },
            propertyClassCode: {
                value: this.propertyCodeToString(building.classCode)
            },
            percentageSprinklered: {
                value:
                    _.get(building, 'percentageSprinklered') !== undefined
                        ? this.getPercentageSprinklered()
                        : '-'
            }
        };

        if (_.isEmpty(building, true)) {
            return null;
        }

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onEditBuildingClicked: this.onEditBuildingClicked
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, building, id, path, overrideProps);
        };

        return (
            <ViewModelForm
                model={building}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

export default BuildingSummary;
