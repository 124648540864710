import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { EntityUtil } from '@xengage/gw-portals-util-js';
import VehicleIncident from './VehicleIncident';
import Vehicle from './Vehicle';
import './Contact';

function PAClaimExtension(claim) {
    Object.defineProperty(this, 'claim', {
        enumerable: false,
        value: claim
    });
    if (this.claim.lobs.personalAuto) {
        this.vehicles = this.claim.lobs.personalAuto.vehicles || [];
        this.vehicleIncidents = this.claim.lobs.personalAuto.vehicleIncidents || [];
        this.contacts = this.claim.contacts || [];

        this.vehicles = _.map(this.claim.lobs.personalAuto.vehicles, (v) => {
            return new Vehicle(v);
        });

        _.each(this.claim.lobs.personalAuto.vehicleIncidents, _.bind(function (vi) {
            const vehicle = vi;
            vehicle.driver = this.claim.findContact(this.claim, vehicle.driver);
            _.each(vehicle.passengers, _.bind((p, index) => {
                vehicle.passengers[index] = this.claim.findContact(this.claim, p);
            }, this));
            vehicle.vehicle = this.findVehicle(vehicle.vehicle);
        }, this));

        this.fixedPropertyIncident = this.claim.lobs.personalAuto.fixedPropertyIncident
            ? this.claim.lobs.personalAuto.fixedPropertyIncident
            : { propertyDescription: undefined };
        this.vehicleIncidents = _.map(
            this.claim.lobs.personalAuto.vehicleIncidents, _.bind(function (vi) {
                return new VehicleIncident(this.claim, this, vi);
            }, this)
        );

        const { repairOption } = this.claim.lobs.personalAuto;
        if (repairOption) {
            const vehicleIncident = this.findVehicleIncident(repairOption.vehicleIncident);
            const matchKeys = repairOption.repairFacility
                && repairOption.repairFacility.addressBookUID
                ? ['addressBookUID'] : null;
            const repairFacility = this.claim.findContact(
                this.claim, repairOption.repairFacility, matchKeys
            );
            this.repairOption = _.extend({}, repairOption, { vehicleIncident, repairFacility });
        }
        _.extend(this, this.claim.lobs.personalAuto);
    } else {
        this.fixedPropertyIncident = { propertyDescription: undefined };
        this.vehicleIncidents = [];
        this.vehicles = [];
    }
}

PAClaimExtension.prototype.createVehicleIncident = function () {
    const vi = new VehicleIncident(this.claim, this);
    vi.tempID = EntityUtil.nextId();
    this.vehicleIncidents.push(vi);
    return vi;
};

PAClaimExtension.prototype.createVehicle = function () {
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountry = localeService.getDefaultCountryCode();
    return new Vehicle({
        tempID: EntityUtil.nextId(),
        country: defaultCountry
    });
};

PAClaimExtension.prototype.removeVehicleIncident = function (incident) {
    const idx = this.vehicleIncidents.indexOf(incident);
    if (idx >= 0) {
        this.vehicleIncidents.splice(idx, 1);
    }
};

PAClaimExtension.prototype.availableVehicles = function (additional) {
    return _.filter(this.vehicles, function (v) { // eslint-disable-line prefer-arrow-callback
        if (additional && v === additional) {
            return true;
        }
        const used = _.find(this.vehicleIncidents, (vi) => {
            return v === vi.vehicle;
        });
        return used === undefined;
    }.bind(this));
};

PAClaimExtension.prototype.availableDrivers = function (additional) {
    return this.availablePassengers(additional);
};

PAClaimExtension.prototype.availablePassengers = function (additional) {
    return _.filter(this.contacts, function (c) { // eslint-disable-line prefer-arrow-callback
        if (additional && _.isEqual(c, additional)) {
            return true;
        }
        const used = _.find(this.vehicleIncidents, (vi) => {
            return _.isEqual(c, vi.driver)
                || _.some(vi.passengers, (p) => {
                    return _.isEqual(c, p);
                });
        });
        return used === undefined;
    }.bind(this));
};

PAClaimExtension.prototype.findVehicle = function (vehicle) {
    return EntityUtil.findMatchingEntity(this.vehicles, vehicle, {
        Ctor: Vehicle
    });
};

PAClaimExtension.prototype.findVehicleIncident = function (vi) {
    return EntityUtil.findMatchingEntity(this.vehicleIncidents, vi, {
        Ctor: VehicleIncident
    });
};

export default PAClaimExtension;
