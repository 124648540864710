import { defineMessages } from 'react-intl';

// To be refactored
export default defineMessages({
    handlePreemptionMessagePart1: {
        id: 'customer.ho.wizard.step.paysuccess.Handle Preemption Message Part1',
        defaultMessage: 'The changes made on this endorsement will automatically apply to change request #'
    },
    handlePreemptionMessagePart2: {
        id: 'customer.ho.wizard.step.paysuccess.Handle Preemption Message Part2',
        defaultMessage: '. You can '
    },
    handlePreemptionMessageDeleteJobLink: {
        id: 'customer.ho.wizard.step.paysuccess.Handle Preemption Message Delete Job Link',
        defaultMessage: 'delete'
    },
    handlePreemptionMessagePart3: {
        id: 'customer.ho.wizard.step.paysuccess.Handle Preemption Message Part3',
        defaultMessage: ' {jobID} if the change request is no longer needed.'
    },
    deleteQuote: {
        id: 'custom.ho.gateway.directives.JobSummary.Delete Quote?',
        defaultMessage: 'Delete Quote?'
    },
    sureDeletePolicyChange: {
        id: 'custom.ho.gateway.views.endorsement.Are you sure you want to delete this policy transaction? All changes will be lost.',
        defaultMessage: 'Are you sure you want to delete this policy transaction? All changes will be lost.'
    },
    // driverDetails: {
    //     id: 'customer.ho.policychange.views.pa-confirmation.Driver Details',
    //     defaultMessage: 'Driver Details'
    // },
    contactInformationDetails: {
        id: 'customer.ho.policychange.views.ho-confirmation.Contact Information Details',
        defaultMessage: 'Contact Information Details'
    },
    changeSuccess: {
        id: 'customer.ho.policychange.views.confirmation.gateway.This policy has been changed',
        defaultMessage: 'This policy has been changed.'
    },
    cancellationMessage: {
        id: 'customer.pa.wizard.step.policychange.paysuccess.cancellationMessage',
        defaultMessage: 'Please click to continue with the Cancellation Transaction {startCancellation}'
    },
    startCancellation: {
        id: 'customer.pa.wizard.step.policychange.paysuccess.startCancellation',
        defaultMessage: 'Start Policy Cancellation'
    }
});
