import _ from 'lodash';

const DRIVER_PATH = 'driver';
const DRIVER_MAP_PATH = 'driverMap';
const newLocation = {
    code: 'new',
    name: 'New',
    country: 'US',
};


const getFullVehicleDisplayName = (vehicle)=> {
    const vehicleYear = _.get(vehicle, 'year');
    const vehicleMake = _.get(vehicle, 'make');
    const vehicleModel = _.get(vehicle, 'model');
    return `${vehicleYear} ${vehicleMake} ${vehicleModel}`;
}

export {
    
    DRIVER_PATH,
    DRIVER_MAP_PATH,
    newLocation,
    getFullVehicleDisplayName
}