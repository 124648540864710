import React from 'react';
import _ from 'lodash';
import { WNIAddressLookupService } from 'wni-capability-address';
import AddressRecommendPopupComponent from './modal/RecommendedAddress';
import AddressRecommendTable from './modal/RecommendedAddressTable';


/*
    getVerifyIssues: if address invalid, the warning message visible
*/
const getVerifyAddressIssues = (visible) => {
    return {
        type: 'warning',
        visible: visible,
        reason: 'Address entered could not be validated. Please modify the address if needed.'
    };
};
/*
    AddressChangeVerify: check the address filed change or not.
    and if change, click next button, will verify again
*/
const AddressChangeVerify = (path, addressPath) => {
    const addressMap = [`${addressPath}.pobox_Ext`, `${addressPath}.addressLine1`, `${addressPath}.city`, `${addressPath}.county`, `${addressPath}.postalCode`, `${addressPath}.state`];
    if (addressMap.includes(path)) {
        return true;
    }
    return false;
};

/*
    getValidationMap: if the warning message is visible or not, and return all the validations
*/
const getValidationMap = (validation, validationMap) => {
    const { visible } = validation;
    const existValidation = validationMap.find((msg) => msg.reason === validation.reason);
    if (!visible) {
        return validationMap.filter(({ reason }) => reason !== validation.reason);
    }
    if (!existValidation) {
        return [...validationMap, validation];
    }

    return validationMap;
};
/*
    AddressVerifiedUtil: verify address is valid.

    if address verified in popup,
    【doublePopup, updateRecommentAddress, updateRecommentAddressVisible】 is required,
    because jutro can not be showModal multiple times, so this case requires special solution.
*/
function AddressVerifiedUtil(props) {
    const {
        /*  authHeader: rquired,
            verifyaddress service need the authHeader
        */
        authHeader,
        /*  addressVM: required,
            need get the address.value, and compare the value and the verify service callback value.
            when select the suggestedAddress, need write into address DTO
        */
        addressVM: addressDto,
        /*  addressPath: required,
            different component, the addressDTO path is different.
            need to get this path to control the addressDto
        */
        addressPath,
        /*
            updateValidations: rquired,
            if the address is inValid, the warning message need show and update,
            otherwise, need hide
        */
        updateValidations,
        /*  writeValue: required,
            when select the address, need to update the data
        */
        writeValue,
        /*  updateAddressFlag: required,
            isAddressFlag: required, if true, continue the next func, otherwise bolck the page
            when user want want to use the invaild address and contine click next,
            and need not verify again and set 'isAutofilled' true
        */
        updateAddressFlag,
        isAddressFlag,

        /*
            when in popup, this three is required.
            doublePopup: if true, is popup window
            updateRecommentAddress: get suggested address
            updateRecommentAddressVisible: control the address suggested is visible or not
        */
        doublePopup,
        //
        modalApi,
    } = props;

    const getAddressObj = (address) => {
        return {
            pobox_Ext: address.pobox_Ext || '',
            addressLine1: address.addressLine1 || '',
            city: address.city || '',
            county: address.county || '',
            postalCode: address.postalCode || '',
            state: address.state || ''
        };
    };
    const lowerJSONAddress = (address) => {
        if (!address) {
            return address;
        }
        const transactions = Object.entries(address).map(([key, val]) => ({
            [key]: val.toLowerCase()
        }));
        return Object.assign({}, ...transactions);
    };

    const isMatchedSuggested = (address, suggested) => {
        const selectedAddress = getAddressObj(address);
        const newSuggestedMap = suggested.map((item) => {
            return getAddressObj(item);
        });
        let isAutofilled = false;
        newSuggestedMap.map((v) => {
            if (_.isEqual(lowerJSONAddress(selectedAddress), lowerJSONAddress(v))) {
                isAutofilled = true;
                return true;
            }
        });
        return isAutofilled;
    };

    const onVerified = async () => {
        if (isAddressFlag) {
            return {isVerified: true, addressValue: addressDto.value};
        }
        const addressPramas = _.omit(addressDto.value, ['code', 'name', 'displayName', 'recommendCode']);
        const verifiedData = await WNIAddressLookupService.verifyAddress(
            addressPramas, authHeader
        );
        if (!verifiedData) {return false;}
        const { verifiedAddresses } = verifiedData;
        if (!verifiedAddresses || verifiedAddresses.length === 0) {
            const updatedVMObj = writeValue({ ...addressDto.value, isAutofilled: false }, addressPath);
            const verifyMsg = getVerifyAddressIssues(true);
            updateValidations(verifyMsg);
            updateAddressFlag(true);
            return {isVerified: false, addressValue: addressDto.value, updatedVMObj};
        }
    
        // const currentAddress = getAddressObj(addressDto.value);
        // const oneSuggestedAddress = getAddressObj(verifiedAddresses[0]);
        const suggestedAddress = verifiedAddresses[0];
        // if (verifiedAddresses.length > 1 || !_.isEqual(lowerJSONAddress(currentAddress), lowerJSONAddress(oneSuggestedAddress))) {
        //     if (doublePopup) {
        //         MultipleModal.showModal({
        //             title: '',
        //             component: (
        //                 <AddressRecommendTable
        //                     suggestedAddress={verifiedAddresses}
        //                     originalAddress={currentAddress}
        //                 />
        //             ),
        //             ok: (selected) => {
        //                 const isAutofilled = isMatchedSuggested(selected, verifiedAddresses);
        //                 const { censusBlockDerived_Ext: censusBlockDerived } = selected;
        //                 writeValue({
        //                     ...addressDto.value,
        //                     ...selected,
        //                     isAutofilled: isAutofilled,
        //                     censusBlockDerived_Ext: censusBlockDerived
        //                 }, addressPath);
        //                 updateAddressFlag(true);
        //             }
        //         });
        //         return false;
        //     }

        //     // const modalApi = useWniModal(); // hook could only be used in compoennt or other hooks

        //     modalApi.showModal(
        //         <AddressRecommendPopupComponent
        //             suggestedAddress={verifiedAddresses}
        //             originalAddress={currentAddress}
        //         />
        //     ).then((selected) => {
        //         const isAutofilled = isMatchedSuggested(selected, verifiedAddresses);
        //         const { censusBlockDerived_Ext: censusBlockDerived } = selected;
        //         writeValue({
        //             ...addressDto.value,
        //             ...selected,
        //             isAutofilled: isAutofilled,
        //             censusBlockDerived_Ext: censusBlockDerived
        //         }, addressPath);
        //         updateAddressFlag(true);
        //     }, _.noop);
        //     return false;
        // }
        const updatedVMObj = writeValue({
            ...addressDto.value,
            ...suggestedAddress,
            isAutofilled: true
        }, addressPath);
        updateAddressFlag(true);

        return {
            isVerified: true,
            addressValue: {...addressDto.value, ...suggestedAddress},
            updatedVMObj,
        };
    };

    return {
        onVerified
    };
}

export {
    getVerifyAddressIssues,
    AddressChangeVerify,
    getValidationMap,
    AddressVerifiedUtil
};
