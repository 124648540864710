import React, {
    useEffect,
} from 'react';
import {
    useHistory,
    useParams,
} from "react-router-dom";

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';


// const { lobEndorsementURL, endorsementProducts } = appConfig;
const { lobQuoteURL } = appConfig;

const DEFAULT_PAY_STATUS = 'init';

/**
 * See AQAccountSummaryPage.onIssueApprovedQuotesClick()
 * @param {object} props 
 * @returns {Promise}
 */
function PaymentCommonRoutes(props) {

    const history = useHistory();
    const {
        jobNumber,
        payStatus = DEFAULT_PAY_STATUS,
    } = useParams();

    useEffect(() => {
        const targetURL = '/aqpayment';
        const stateObj = {
            jobNumber,
            payStatus,
        }
        history.push(targetURL, stateObj);
    }, []);

    return null;

}

export default PaymentCommonRoutes;
