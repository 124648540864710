import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { BreakpointTrackerContext } from '@jutro/layout';
import { FNOLService } from 'wni-capability-fnol';
import metadata from './SummaryPage.metadata.json5';
import summaryStyles from './SummaryPage.module.scss';
import AdjusterContent from './Component/AdjusterContent';
import AddNotePopup from './AddNotePopup';
import messages from '../../FNOL.messages';

function FNOLSummaryPage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    // const { FNOLService } = useDependencies('FNOLService');
    const { ClaimService } = useDependencies('ClaimService');
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader,
        history,
        history: {
            location: { state }
        }
    } = props;
    const { capabilitiesConfig } = appConfig;
    const [popupNotesVM, updatePopupNotesVM] = useState(null);
    const newNoteViewModelObj = (initialData) => {
        return {
            note: viewModelService.create(
                initialData.note,
                'cc',
                'edge.capabilities.note.dto.NoteDTO'
            )
        };
    };

    const getWhereLocation = useCallback(() => {
        if (
            !_.isEmpty(claimVM.lossLocation.value)
            && claimVM.lossLocation.city.value
            && claimVM.lossLocation.state.value.code
            && claimVM.lossLocation.country.value.code
        ) {
            return `${claimVM.lossLocation.city.value},
                ${claimVM.lossLocation.state.value.code},
                ${claimVM.lossLocation.country.value.code}`;
        }
        return '';
    }, [claimVM]);

    const getContactPerson = useCallback(() => {
        let phoneType;
        switch (claimVM.value.mainContact.primaryPhoneType) {
            case 'home':
                phoneType = claimVM.value.mainContact.homeNumber;
                break;
            case 'work':
                phoneType = claimVM.value.mainContact.workNumber;
                break;
            case 'mobile':
                phoneType = claimVM.value.mainContact.cellNumber;
                break;
            default:
                phoneType = '';
        }
        if (claimVM.value.mainContact.displayName && phoneType) {
            return `${claimVM.value.mainContact.displayName}, ${phoneType}`;
        }
        if (claimVM.value.mainContact.displayName) {
            return claimVM.value.mainContact.displayName;
        }
        return '';
    }, [claimVM]);

    const isVendorRepairOptionEnabled = useMemo(() => {
        const lossCause = _.get(claimVM.value, 'lossCause');
        return !_.isNil(_.get(claimVM.value, 'lobs.personalAuto'))
        && (lossCause !== 'theftentire')
        && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'vendor' });
    }, [capabilitiesConfig, claimVM]);

    const repairFacilityVisible = useCallback(() => {
        return isVendorRepairOptionEnabled && !_.isNil(_.get(claimVM.value, 'lobs.personalAuto.repairOption'));
    }, [claimVM, isVendorRepairOptionEnabled]);

    const getRepairFacility = useCallback(() => {
        const repairOptionChoice = _.get(claimVM.value, 'lobs.personalAuto.repairOption.repairOptionChoice');
        if (!_.isNil(repairOptionChoice) && repairOptionChoice === 'NoRepair') {
            return translator(messages.fnolNoRepairRequested);
        }
        const repairFacility = _.get(claimVM.value, 'lobs.personalAuto.repairOption.repairFacility');
        if (_.isNil(repairFacility)) {
            return null;
        }
        const vendorName = repairFacility.contactName ? repairFacility.contactName : '';
        const vendorAddress = repairFacility.primaryAddress;
        const addressDetails = {
            addressLine1: vendorAddress.addressLine1,
            addressLine2: vendorAddress.addressLine2,
            addressLine3: vendorAddress.addressLine3,
            city: vendorAddress.city,
            state: vendorAddress.state,
            postalCode: vendorAddress.postalCode
        };

        const formattedVendorAddress = Object.values(addressDetails)
            .filter((data) => data)
            .join(', ');
        return (
            <div>
                <p>{vendorName}</p>
                <p>{formattedVendorAddress}</p>
            </div>
        );
    }, [claimVM, translator]);

    const initializeNotesVM = () => {
        if (!_.isEmpty(claimVM.adjusterNote.value)) {
            const getNotesVM = newNoteViewModelObj({
                note: {
                    claimNumber: claimVM.claimNumber.value,
                    subject: claimVM.adjusterNote.subject.value,
                    body: claimVM.adjusterNote.body.value
                }
            });
            return updatePopupNotesVM(getNotesVM.note);
        }

        const getNotesVM = newNoteViewModelObj({
            note: {
                claimNumber: claimVM.claimNumber.value,
                subject: '',
                body: ''
            }
        });
        return updatePopupNotesVM(getNotesVM.note);
    };

    useEffect(() => {
        initializeNotesVM();
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = useCallback(
        async (getNotesVM) => {
            const componentProps = {
                title: translator(messages.dialogAddNoteToAdjuster),
                actionBtnLabel: messages.dialogOk,
                cancelBtnLabel: messages.dialogCancel,
                notesVM: getNotesVM,
                viewModelService: viewModelService
            };

            const results = await modalApi.showModal(
                <AddNotePopup {...componentProps} />
            );

            return results;
        },
        [translator, viewModelService]
    );

    const updateNoteData = useCallback(
        (noteVM) => {
            const claimNumber = _.get(claimVM.value, 'claimNumber');
            return ClaimService.updateClaimNote(claimNumber, noteVM.value, authHeader)
                .then((response) => {
                    _.set(claimVM, 'adjusterNote.value', response);
                    updateWizardData(claimVM);
                    _.set(noteVM, 'value', response);
                    updatePopupNotesVM(noteVM);
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: messages.fnolNoteErrorHead,
                        message: messages.fnolNoteErrorMessage,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
        },
        [claimVM, authHeader, updateWizardData, ClaimService]
    );

    const handleEditNote = useCallback(() => {
        showModal(popupNotesVM).then((updatedVM) => {
            const modifiedVM = viewModelService.clone(updatedVM);
            updateNoteData(modifiedVM);
        }).catch(_.noop);
    }, [popupNotesVM, showModal, updateNoteData, viewModelService]);

    const handleDeleteNote = useCallback(() => {
        const claimNumber = _.get(claimVM.value, 'claimNumber');
        const noteId = _.get(claimVM.value, 'adjusterNote.publicID');
        return ClaimService.deleteClaimNote(claimNumber, noteId, authHeader)
            .then((response) => {
                _.set(claimVM, 'adjusterNote.value', response);
                updateWizardData(claimVM);
                _.set(popupNotesVM, 'value', response);
                updatePopupNotesVM(popupNotesVM);
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.fnolNoteErrorHead,
                    message: messages.fnolNoteErrorMessage,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
    }, [claimVM, authHeader, popupNotesVM, updateWizardData, ClaimService]);

    const saveNoteData = useCallback(
        (noteVM) => {
            const claimNumber = _.get(claimVM.value, 'claimNumber');
            return ClaimService.createClaimNote(claimNumber, noteVM.value, authHeader)
                .then((response) => {
                    _.set(claimVM, 'adjusterNote.value', response);
                    updateWizardData(claimVM);
                    _.set(noteVM, 'value', response);
                    updatePopupNotesVM(noteVM);
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: messages.fnolNoteErrorHead,
                        message: messages.fnolNoteErrorMessage,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
        },
        [authHeader, claimVM, updateWizardData, ClaimService]
    );

    const addNotesToAdjuster = useCallback(() => {
        showModal(popupNotesVM).then((updatedVM) => {
            const modifiedVM = viewModelService.clone(updatedVM);
            saveNoteData(modifiedVM);
        }).catch(_.noop);
    }, [popupNotesVM, saveNoteData, showModal, viewModelService]);

    const getWhereLocationVisibility = useCallback(() => {
        if (!_.isEmpty(claimVM.lossLocation.value)) {
            return true;
        }
        return false;
    }, [claimVM]);

    const getFullAddressDisplayName = (address)=> {
        const AddressLineDisplay = [_.get(address, 'addressLine1'), _.get(address, 'addressLine2'), _.get(address, 'addressLine3')].filter((item) => !_.isNil(item)).join(' ')
        const CityStateZipcodeDisplay = [_.get(address, 'city'), _.get(address, 'state'), _.get(address, 'postalCode')].filter((item) => !_.isNil(item)).join(', ')
        return `${AddressLineDisplay}, ${CityStateZipcodeDisplay}`;
    }

    const onNext = useCallback(() => {
        return FNOLService.submitClaim(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                const claimNumber = _.get(claimVM.value, 'claimNumber');
                const { redirectPath } = state;
                return history.push({
                    pathname: `/confirmation/${claimNumber}`,
                    state: {
                        redirectPath: redirectPath
                    }
                });
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.fnolErrorSubmitClaimTitle,
                    message: messages.fnolErrorSubmitClaimMessage,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
    }, [translator, FNOLService, claimVM, authHeader, state, history]);

    const typeOfIncident = _.get(claimVM, 'lossCause.value.name');

    const overrides = {
        '@field': {
            phoneWide: {
                labelPosition: 'top'
            }
        },
        fnolTypeOfIncident: {
            value: translator({
                id: typeOfIncident,
                defaultMessage: typeOfIncident
            })
        },
        addNotebtnDiv: {
            // visible: _.isEmpty(claimVM.adjusterNote.value)
            visible: false
        },
        adjusterNoteDiv: {
            // visible: !_.isEmpty(claimVM.adjusterNote.value)
            visible: false
        },
        fnolWhere: {
            visible: getWhereLocationVisibility(),
            // value: getWhereLocation()
            value: _.get(claimVM, 'value.lossLocation.displayName') || getFullAddressDisplayName(_.get(claimVM, 'value.lossLocation'))
        },
        fnolWhen: {
            phoneWideTime: {
                labelPosition: 'top'
            },
        },
        fnolContactPerson: {
            value: getContactPerson()
        },
        fnolRepairFacility: {
            visible: repairFacilityVisible(),
            value: repairFacilityVisible() ? getRepairFacility() : ''
        },
        adjusterContent: {
            handleEditNote,
            handleDeleteNote,
            claimVM
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            addNotesToAdjuster: addNotesToAdjuster
        },
        resolveComponentMap: {
            adjustercontent: AdjusterContent
        },
        resolveClassNameMap: summaryStyles
    };

    const readValue = (id, path) => {
        return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
    };

    let nextLabel = messages.fnolSubmitClaim;
    if (breakpoint === 'phoneWide' || breakpoint === 'phone') {
        nextLabel = messages.fnolSubmitClaimShort;
    }
    return (
        <WizardPage
            nextLabel={translator(nextLabel)}
            cancelLabel={translator(messages.fnolSaveandExit)}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

FNOLSummaryPage.propTypes = wizardProps;
FNOLSummaryPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(withViewModelService(FNOLSummaryPage)));
