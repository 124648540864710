import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import HOCoverageUtil from '../../util/HOCoverageUtil';
import metadata from './HOOtherOptionalCoverages.metadata.json5';
import styles from './HOOtherOptionalCoverages.module.scss';
import HOCoverageDetailsPopup from '../../components/HOCoverageDetailsPopup/HOCoverageDetailsPopup';
import HOClausesComponentVM from '../../components/HOClausesComponentVM/HOClausesComponentVM';
import HOSingleClauseComponentVM from '../../components/HOSingleClauseComponentVM/HOSingleClauseComponentVM';

function HOOtherOptionalCoverages (props) {
    const modalApi = useWniModal();
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        isEditing,
        isEditable,
        showErrors,
        lobName,
        coveragesConfig,
        coveragesService,
    } = props;

    const {
        quoteID,
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const [loadingOtherOptionalCoverages, setLoadingOtherOptionalCoverages] = useState(false);

    const getOtherOptionalCoverages = useCallback(() => {
        const otherOptionalCoverages = _.get(selectedVersion, 'coverages.otherOptionalCoverages_Ext', []);
        return otherOptionalCoverages;
    }, [selectedVersion]);

    const otherOptionalCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.otherOptionalCoverages_Ext`;
    const otherOptionalCoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.otherOptionalCoverages_Ext`;
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const otherOptionalCoverages = getOtherOptionalCoverages();

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = HOCoverageUtil.setClauseValue(otherOptionalCoverages, otherOptionalCoveragesVMPath, value, changedPath);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM.value, otherOptionalCoveragePath, newCoverages);
            updateWizardData(newSubmissionVM);
            return newSubmissionVM;
        },
        [otherOptionalCoverages, otherOptionalCoveragesVMPath, submissionVM, otherOptionalCoveragePath, updateWizardData]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM, updateWithLoader = true) => {
        const coverages = _.get(newSubmissionVM.value, lobCoveragesPath);
        const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        if (updateWithLoader) {
            const updatedCoveragePublicIDs = HOCoverageUtil.getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
            setLoadingOtherOptionalCoverages(updatedCoveragePublicIDs);
        }
        const response = await coveragesService.updateCoverages(
            quoteID || jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        const submissionVMClone = _.clone(newSubmissionVM)
        _.set(submissionVMClone.value, lobCoveragesPath, lobCoverages);
        setLoadingOtherOptionalCoverages(false);
        updateWizardData(submissionVMClone);
        return submissionVMClone;
    }, [authHeader, jobID, lobCoveragesPath, quoteID, sessionUUID, updateWizardData, lobName, coveragesService]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const onScheduleChange = useCallback(
        async (value, changedPath, updateWithLoader = true) => {
            const newSubmission = changeSubmission(value, changedPath);
            const newSubmissionVMBySync = await updateSubmissionVMToServer(newSubmission, updateWithLoader);
            const valuePathToSyncedSchedule = HOCoverageUtil.getValuePathByVMPath(changedPath)
            const updatedSchedule = _.get(newSubmissionVMBySync.value, valuePathToSyncedSchedule);
            return updatedSchedule;
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const showCoverageDetailsPopup = useCallback(
        (coverage) => {
            const componentProps = {
                coverage,
                quoteID,
                jobID,
                sessionUUID,
                coverageType: 'otherOptionalCoverages_Ext',
                lobCoveragesPath,
                submissionVM,
                updateSubmissionVM: updateWizardData,
                isEditable,
                lobName,
                coveragesConfig,
                coveragesService,
            };
            return modalApi.showModal(
                <HOCoverageDetailsPopup {...componentProps} />
            );
        }, [
            quoteID,
            jobID,
            sessionUUID,
            lobCoveragesPath,
            submissionVM,
            updateWizardData,
            isEditable,
            lobName,
            coveragesConfig,
            coveragesService,
        ]
    )

    const onOpenCoverageDetailsPopup = (clausePatternCode) => {
        const coverageToOpen = otherOptionalCoverages
            .find((coverage) => _.get(coverage, 'code_Ext') === clausePatternCode)
        showCoverageDetailsPopup(coverageToOpen)
            .then(async (updatedCoverage) => {
                const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO({
                    // eslint-disable-next-line camelcase
                    otherOptionalCoverages_Ext: [updatedCoverage]
                }, lobName);
                const updatedCoveragePublicIDs = HOCoverageUtil.getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
                setLoadingOtherOptionalCoverages(updatedCoveragePublicIDs);
                const response = await coveragesService.updateCoverages(
                    quoteID || jobID,
                    sessionUUID,
                    clausesToUpdate,
                    authHeader
                )
                const lobCoverages = _.get(response, lobName);
                _.set(submissionVM.value, lobCoveragesPath, lobCoverages);
                setLoadingOtherOptionalCoverages(false);
                updateWizardData(submissionVM);
            })
            .catch(() => _.noop)
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable
            },
            otherOptionalCoverages: {
                loadingClause: loadingOtherOptionalCoverages,
                path: otherOptionalCoveragesVMPath,
                value: otherOptionalCoverages,
                hideCheckBoxForRequired: true,
                componentMapOverrides: {
                    HOSingleClauseComponentVM: HOSingleClauseComponentVM,
                },
                showErrors,
                isEditing,
                coveragesConfig,
            },
            // otherOptionalToggleButtonCoverages: {
            //     coverages: coveragesDisplayOnToggleButonCoveragesComponent,
            //     onAddSelectedCoverages: onAddSelectedCoverages
            // }
        };
    }, [
        // coveragesDisplayOnToggleButonCoveragesComponent,
        loadingOtherOptionalCoverages,
        otherOptionalCoveragesVMPath,
        otherOptionalCoverages,
        // onAddSelectedCoverages,
        isEditable,
        isEditing,
        showErrors,
        coveragesConfig,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: onScheduleChange,
            onChangeClause: changeSubmission,
            onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
        },
        resolveComponentMap: {
            HOClausesComponentVM: HOClausesComponentVM,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

HOOtherOptionalCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
};

HOOtherOptionalCoverages.defaultProps = {
    showErrors: false,
    isEditable: true,
};

export default HOOtherOptionalCoverages;
