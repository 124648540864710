import _ from 'lodash';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wcStateInfoService'), method, data, additionalHeaders);
}

export default class WCStateInfoService {
    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} stateInfo
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateStateInfo(quoteID, sessionUUID, stateInfo, authHeader = {}) {
        return processSubmission(
            'updateStateInfo',
            [quoteID, sessionUUID, stateInfo],
            authHeader
        );
    }

    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Array} statePublicIDs
     * @param {date} splitDate
     * @param {string} splitType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addSplitPeriod(quoteID, sessionUUID, statePublicIDs, splitDate, splitType, authHeader = {}) {
        return processSubmission(
            'addSplitPeriod',
            [quoteID, sessionUUID, statePublicIDs, splitDate, splitType],
            authHeader
        );
    }

    /**
     *
     *  @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} statePublic 
     * @param {Array} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeSplitPeriod(quoteID, sessionUUID, statePublic, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeSplitPeriod',
            [quoteID, sessionUUID, statePublic, publicIDs],
            authHeader
        );
    }

    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} statePublicID 
     * @param {object} coveredEmployee
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateCoveredEmployee(quoteID, sessionUUID, statePublicID, coveredEmployee, authHeader = {}) {
        return processSubmission(
            'updateCoveredEmployee',
            [quoteID, sessionUUID, statePublicID, coveredEmployee],
            authHeader
        );
    }

    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} statePublicID 
     * @param {string} coveredEmployeePublicID
     * @param {object} coveredEmployeeDisplayables
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateCoveredEmployeeDisplayables(quoteID, sessionUUID, statePublicID, coveredEmployeePublicID, coveredEmployeeDisplayables, authHeader = {}) {
        return processSubmission(
            'updateCoveredEmployeeDisplayables',
            [quoteID, sessionUUID, statePublicID, coveredEmployeePublicID, coveredEmployeeDisplayables],
            authHeader
        );
    }


    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} statePublicID 
     * @param {Array} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeCoveredEmployee(quoteID, sessionUUID, statePublicID, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeCoveredEmployee',
            [quoteID, sessionUUID, statePublicID, publicIDs],
            authHeader
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static updateOfficials(data, authHeader = {}) {
        return processSubmission(
            'updateOfficials',
            [data.jobID, data.sessionUUID, data.OfficialIDs],
            authHeader
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID, data.stateInfo],
            authHeader
        );
    }

    static retrieveCoveredEmployeeDisplayables(quoteID, sessionUUID, statePublicID, coveredEmployeePublicID, authHeader = {}) {
        return processSubmission(
            'retrieveCoveredEmployeeDisplayables',
            [quoteID, sessionUUID, statePublicID, coveredEmployeePublicID],
            authHeader
        );
    }
}