import React, {
    useContext
} from 'react'
import {  WniToggle } from "wni-common-base-components"
import CoveragesConfigContext from '../../../context/CPCoveragesConfigContext'
import SingleClauseContext from '../../../context/CPSingleClauseContext'
import { ValuePath } from '../../../util/CPScheduleItemsUtil'


const ScheduleItemEditableCellBoolean = (props) => {
    const {
        propertyInfo,
        scheduleItem,
        scheduleItemVMPath,
    } = props

    const {
        id: propertyInfoId,
        required,
    } = propertyInfo

    const {
        itemNumber,
        itemData: {
            [propertyInfoId]: itemDataField
        }
    } = scheduleItem

    const {
        [ValuePath.TypeCodeValue]: value,
        available_Ext: available
    } = itemDataField

    const coveragesConfig = useContext(CoveragesConfigContext);


    const {
        clauseCode,
        showErrors,
        onChangeClause,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)
    const {
        scheduleConfig: {
            fieldsNotNeedSyncWhileChange: {
                [clauseCode]: fieldsNotNeedSync = []
            }
        }
    } = coveragesConfig;

    const notNeedSyncImmediately = fieldsNotNeedSync.includes(propertyInfoId)

    const onBooleanValueChange = (newValue) => {
        const newItemDataField = {
            ...itemDataField,
            [ValuePath.TypeCodeValue]: newValue,
            updated_Ext: true
        };
        const path = `${scheduleItemVMPath}.itemData.${propertyInfoId}`
        if (notNeedSyncImmediately) {
            onChangeClause(newItemDataField, path)
            return
        }
        onChangeSubmissionAndSync(newItemDataField, path)
        // console.log(path)
    }

    return <WniToggle
        id = {`${propertyInfoId}_${itemNumber}`}
        required = {required}
        showRequired = {required}
        // availableValues = {availableValues}
        value = {value}
        onValueChange = {onBooleanValueChange}
        showErrors = {showErrors}
        disabled={!available}
    />
}

export default ScheduleItemEditableCellBoolean