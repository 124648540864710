import { defineMessages } from 'react-intl';

export default defineMessages({
    ok: {
        id: 'custom.wizardsidebar.modal.Ok',
        defaultMessage: 'Ok'
    },
    cancel: {
        id: 'custom.wizardsidebar.modal.Cancel',
        defaultMessage: 'Cancel'
    },
    RCTWebsiteCallbackFail: {
        id: 'custom.wizardsidebar.quick link.RCTCallbackFail',
        defaultMessage: 'Failed to synchronize with RCE. Please click OK button to try again. '
    },
});