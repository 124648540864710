
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';
import { WizardStepUtil } from 'wni-portals-util-js';

import { CPPQualificationPage } from 'wni-capability-quoteandbind-cpp-react';

function CPPQualificationChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const lobData = _.get(submissionVM, 'lobData.value');
    const policyLinePatterns = WizardStepUtil.getPolicyLinePatterns(lobData);
    const isCRQualificationAvailableForCPPChange = WizardStepUtil.isCRQualificationAvailableForCPPChange(policyLinePatterns)
    const isGLQualificationAvailableForCPPChange = WizardStepUtil.isGLQualificationAvailableForCPPChange(policyLinePatterns)

    return (
        <CPPQualificationPage
            {...props}
            isCRQualificationAvailable={isCRQualificationAvailableForCPPChange}
            isGLQualificationAvailable={isGLQualificationAvailableForCPPChange}
        />
    );

}

CPPQualificationChangePage.propTypes = CPPQualificationPage.propTypes;
CPPQualificationChangePage.defaultProps = CPPQualificationPage.defaultProps;

export default CPPQualificationChangePage;