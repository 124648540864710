

import React from 'react';
import { IMMiscellaneousFloatersReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMMiscellaneousFloatersChangeReadOnlyPage = (props) => {
    return <IMMiscellaneousFloatersReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMMiscellaneousFloatersChangeReadOnlyPage
