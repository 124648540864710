import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Chevron } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateUtil, WniPNIUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import styles from './PaySuccessPage.module.scss';
import metadata from './PaySuccessPage.metadata.json5';
import messages from './PaySuccessPage.messages';

const ACCORDION_CARDS = [
    'driverAccordion',
    'vehicleAccordion',
    'coverageAccordion',
    'contactAccordion'
];

function renderCellContent(data, index, props) {
    const { path } = props;
    return _.get(data, path);
}

function renderAccordionHeader(title) {
    return (isOpen) => (
        <div className="wizardTitle d-flex align-items-center">
            <Chevron
                align="left"
                isOpen={isOpen}
                chevronIcon="gw-chevron-right"
            />
            <h2 className="title flex-sm-fill ml-20">{title}</h2>
        </div>
    );
}

function PaySuccessPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPrinting, setPrintingState] = useState(false);
    const {
        // history,
        wizardData: submissionVM,
        turnOffWizardPromptMessage,
    } = props;
    const { interactionModel } = useDependencies('interactionModel');
    const history = useHistory();
    const [legacyVisible, setLegacyVisible] = useState(false);
    const [legacyMessage, setLegacyMessage] = useState(false);

    const primaryNamedInsured = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext');
    const pniPrimaryPhoneNumber = WniPNIUtil.getPrimaryPhoneNumber(primaryNamedInsured);


    useEffect(() => {
        turnOffWizardPromptMessage();
    }, []);

    useEffect(() => {
        // legacy notification msg
        const jobType = _.get(submissionVM.value, 'baseData.jobType');
        const source = _.get(submissionVM.value, 'baseData.legacyAccountNo_Ext');
        const effectiveDateExt = moment(_.get(submissionVM.value, 'baseData.effectiveDate_Ext')).toDate();
        // ****date would be renewal offer date (34 days) before eff date
        const date = WniDateUtil.getDiffDateByDay(effectiveDateExt, -34);
        const effectiveDate = WniDateUtil.formatDateWithPattern(new Date(date));
        setLegacyMessage(translator(messages.convertedInfo, {
            effectiveDate: effectiveDate
        }));
        if (jobType === 'Submission' && !_.isEmpty(source)) {
            setLegacyVisible(true);
        } else {
            setLegacyVisible(false);
        }
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const accountNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.accountNumber.value');
    }, [submissionVM]);

    const policyNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.policyNumber.value');
    }, [submissionVM]);

    const periodStatus = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodStatus.value.code');
    }, [submissionVM]);

    const firstName = useMemo(() => {
        return _.get(submissionVM, 'baseData.accountHolder.firstName');
    }, [submissionVM]);

    const lastName = useMemo(() => {
        return _.get(submissionVM, 'baseData.accountHolder.lastName');
    }, [submissionVM]);

    const accountName = `${firstName.value} ${lastName.value}`;

    const quoteId = _.get(submissionVM, 'quoteID');

    let paymentPlanName = useMemo(() => {
        return _.get(submissionVM, 'value.bindData.paymentPlans[0].name');
    }, [submissionVM]);

    const NamesChange = {
        'PL - 12 months': '',
        'PL - 6 months': '',
        'Full Pay': 'Pay in Full'
    };
    _.forEach(NamesChange, (value, key) => {
        if (paymentPlanName && paymentPlanName.includes(key)) {
            paymentPlanName = paymentPlanName.substring(0, paymentPlanName.indexOf(key));
            paymentPlanName += value;
        }
    });

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const handleDone = useCallback(() => {
        history.push(`/quotes/${quoteId.value}/summary`);
    }, [history, quoteId]);

    const generateCoverageOverrides = useCallback(
        () => {
            return {
                coverageAccordion: {
                    header: renderAccordionHeader(translator(messages.coveragePremiumDetails))
                }
            };
        },
        [translator]
    );

    const generateVehicleOverrides = useCallback(
        () => {
            return {
                vehicleAccordion: {
                    header: renderAccordionHeader(translator(messages.vehicleDetails))
                },
                vehicleTable: {
                    data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
                },
                vehicleMake: { renderCell: renderCellContent },
                vehicleModel: { renderCell: renderCellContent },
                vehicleLicensePlate: { renderCell: renderCellContent },
            };
        },
        [submissionVM, translator]
    );

    const generateLinkOverrides = () => {
        const accountType = _.get(submissionVM, 'baseData.accountHolder.contactType_Ext.value');
        return {
            accountNumberLink: interactionModel.getURLObj(accountType, 'accountSummary', accountNumber),
            policyNumberLink: interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber)
        };
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        legacyNotification: {
            visible: legacyVisible,
            message: legacyMessage,
        },
        driverAccordion: {
            header: renderAccordionHeader(translator(messages.driverDetails)),
        },
        contactAccordion: {
            header: renderAccordionHeader(
                translator(messages.contactInformationDetails)
            ),
            // header: translator(messages.contactInformationDetails)
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined,
            defaultOpenedId: 'contactAccordion',
        },
        paSuccessDetailsPage: {
            content: translator(messages.paymentSubmitted),
        },
        customerSuccessPagePolicyMessage: {
            content: translator(messages.issuedSuccess),
            visible: periodStatus === 'Bound'
        },
        dynamicInlineNotificationContainer: {
            validationIssues: [
                {
                    type: 'warning',
                    reason: translator(messages.paymentFail),
                },
            ],
            visible: periodStatus !== 'Bound',
            issueRenderFn: (
                issue,
                { listKey }
            ) => {
                const { reason } = issue;
                return (
                    reason.split('\n').map((item) => {
                        return <p className={styles.noMargin} key={`${listKey}-${item}`}>{item}</p>;
                    })
                );
            },
            scrollToIssues: true
        },
        // primaryInsuredName: {
        //     value: accountName
        // },
        pniPhone: {
            value: pniPrimaryPhoneNumber,
        },
        paymentPlan: {
            value: paymentPlanName,
        },
        policyEffectiveDate: {
            showRequired: false,
            required: false,
            value: _.get(submissionVM.value, 'baseData.periodStartDate'),
            showErrors: false,
        },
        policyExpirationDate: {
            showRequired: false,
            required: false,
            value: _.get(submissionVM.value, 'baseData.periodEndDate'),
            showErrors: false,
        },
        ...generateCoverageOverrides(translator),
        ...generateVehicleOverrides(translator),
        ...generateLinkOverrides(),
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onDone: handleDone
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PaySuccessPage.propTypes = wizardProps;
export default PaySuccessPage;
