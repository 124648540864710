import React, {
    useCallback,
    useEffect,
    useState,
    useContext,
    useRef,
} from 'react';
import PropTypes from 'prop-types';

import {
    withRouter,
    Redirect,
    Prompt,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import _ from 'lodash';

function getLocationFingerPrint(loc) {
    const retval = _.pick(loc, ['pathname', 'search', 'state']);
    return JSON.stringify(retval);
}

function checkPageChangeStatus(loc1, loc2) {
    const loc1FP = getLocationFingerPrint(loc1);
    const loc2FP = getLocationFingerPrint(loc2);
    const hasPageChanged = loc1FP !== loc2FP;
    return hasPageChanged;
}

function isSameFunction(fn1, fn2) {
    return _.isFunction(fn1) && _.isFunction(fn2) && (
        fn1 === fn2 || fn1.name === fn2.name
    );
}

function isPromise(obj) {
    return _.isObjectLike(obj) && _.isFunction(obj.then);
}

function getDefaultConfirmModal(promptMessage) {
    return () => {
        // eslint-disable-next-line no-alert
        const retval = window.confirm(promptMessage);
        return retval;
    };
}

/**
 * <p>This component intends to repolace react-router-dom/Prompt by enhancing
 * message with support for async func.</p>
 * 
 * <p>It is built upon history.block() -- which only accepts syncrhonous callback too,
 * and is basically a stripped down version of ReactRouterPause, which suites the need of
 * Wizard for now, so I will allow it.</p>
 * 
 * <h4>How it works</h4>
 * <p>Follow these steps:</p>
 * <ol>
 *  <li>history.block(historyTransitionPrompt) is called instantly on component mount/re-render, (extra guards were
 * added to avoid duplicate calls);</li>
 *  <li>historyTransitionPrompt() returns false (to stay on current page) for async message;</li>
 *  <li>unblock() is called when the async message response resolves, along with resumePageJump();</li>
 *  <li>resumePageJump() invokes history.goBack()|push()|replace() to recover the histry transition flow</li>
 * </ol>
 * 
 * <hr/>
 * References:
 * 1, https://github.com/remix-run/history/blob/main/docs/blocking-transitions.md
 * 2, https://medium.com/@kevin.dalman/control-react-router-asynchronously-b5c0e88013ab
 * 3, https://github.com/allpro/react-router-pause/blob/master/src/ReactRouterPauseHooks.js
 * 4, https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
 * 5, https://stackoverflow.com/questions/65526447/react-router-v5-2-blocking-route-change-with-createbrowserhistory-and-history
 * //
 * 6, https://stackoverflow.com/questions/66529690/using-history-block-with-asynchronous-functions-callback-async-await
 * 7, https://github.com/remix-run/history/blob/v4/modules/
 * 
 * @param {object} props 
 * @returns {object} React 
 */
function AsyncPrompt(props) {

    const {
        message: confirmLeavingFunc,
        when: shouldTriggerInterception,
    } = props;

    const history = useHistory();
    const location = useLocation();

    const historyUnblock = useRef(null);
    const leavingFunc = useRef(null);
    const cachedNavigation = useRef(null);
    
    
    function clearCachedNavigation() {
        cachedNavigation.current = null;
    }
    
   // ==================================================
    /**
     * call the unblock() function to remove transitionManager.prompt,
     * and clean leavingFunc.current
     */
    function unblock() {
        const existingUnblockFn = historyUnblock.current;

        historyUnblock.current = null;
        leavingFunc.current = null;

        // console.log('================unblocking invoked==================');
        existingUnblockFn && existingUnblockFn();
    }

    function resumePageJump() {
        if (_.isNil(cachedNavigation.current)) {
            return;
        }

        // console.log('=================resume page jump===============');

        const {
            location: cachedLocation,
            action: cachedAction,
        } = cachedNavigation.current;

        clearCachedNavigation();

        // const hasPageChanged = checkPageChangeStatus(location, cachedLocation);
        // clear existing transitionPrompt to avoid infinite loop 
        // by the following history.goBack()|push()|replace()
        //
        unblock();

        // ref: ReactRouterPause
        // ref2: https://v5.reactrouter.com/core/api/history
        const historyJumpMethodName = cachedAction.toLowerCase();
        if (historyJumpMethodName === 'pop') {
            history.goBack();
        } else { // action: 'PUSH' or 'REPLACE'
            history[historyJumpMethodName](cachedLocation);
        }
    }
    
    function checkLeavingFuncResult(nextLocation, action) {
        let shouldGoToNextLocation = true;

        //
        cachedNavigation.current = { location: nextLocation, action };

        let checkLeavingResult = true;
        try {
            const currentLeavingFunc = leavingFunc.current;
            checkLeavingResult = currentLeavingFunc(nextLocation);
        // eslint-disable-next-line no-empty
        } catch (error) {} // ignore any error thrown by currentLeavingFunc

        // No need to check cachedNavigation here, since it is not exposed to leavingFunc
        // like what's been done in ReactRouterPause
        // if (_.isNil(cachedNavigation.current)) {
        //     retval = false;
        // }

        if (isPromise(checkLeavingResult)) {
            // Stay on current page if the result is still pending
            shouldGoToNextLocation = false;
            checkLeavingResult.then((result) => {
                // only stay on current page with explicit boolean return value
                // ==========================================================
                // WARNING: things could get really ugly when result itself is a Promise.
                // What happens next is resumePageJump() kept getting executed,
                // and the current wizard just keeps refreshing.
                // 
                // Might need something like "resolveAllDeeplyNestedPromises()" to 
                // really clean things up. Should worth a look sometime later.
                // 
                // Solution Proposal:
                // 1) introduce another useRef() to track all Promise results as a list
                // 2) 
                //
                // ==========================================================
                // 2022-11-02(Wed) Update: the culprit is the OOTB setIsLoading()
                // in PA/HOSubmissionWizard.handleOnCancel(), which re-renders the whole
                // wizard, thus throw AsyncPrompt in vain. Call stack:

                // (anonymous) (AsyncPrompt.jsx:218)
                // Promise.then (async)
                // checkLeavingFuncResult (AsyncPrompt.jsx:197)
                // historyTransitionPrompt (AsyncPrompt.jsx:160)
                // confirmTransitionTo (history.js:131)
                // replace (history.js:385)
                // onMount (Redirect.js:43)
                // componentDidMount (Lifecycle.js:5)
                // ...
                // (anonymous) (useBaseWizardSteps.js:176)
                // (anonymous) (WizardPage.jsx:79)
                // Promise.then (async)
                // (anonymous) (WizardPage.jsx:76)
                // ...
                // _callee3$ (PASubmissionWizard.jsx:175)
                //     // line 175:
                //     setIsLoading(false); // <-------------------------
                // tryCatch (PASubmissionWizard.jsx:2)
                // (anonymous) (PASubmissionWizard.jsx:2)
                // (anonymous) (PASubmissionWizard.jsx:2)
                // asyncGeneratorStep (asyncToGenerator.js:3)
                // _next (asyncToGenerator.js:25)
                // Promise.then (async)
                // ...
                // (anonymous) (PASubmissionWizard.jsx:163)
                // _callee$ (CommonTransactionWizard.jsx:43)
                // tryCatch (CommonTransactionWizard.jsx:2)
                // (anonymous) (CommonTransactionWizard.jsx:2)
                // (anonymous) (CommonTransactionWizard.jsx:2)
                // ...
                // (anonymous) (CommonTransactionWizard.jsx:43)
                // _callee$ (BaseWizard.jsx:339)
                // tryCatch (BaseWizard.jsx:2)
                // (anonymous) (BaseWizard.jsx:2)
                // (anonymous) (BaseWizard.jsx:2)
                // ...
                // (anonymous) (BaseWizard.jsx:339)
                // checkLeavingFuncResult (AsyncPrompt.jsx:184)
                // historyTransitionPrompt (AsyncPrompt.jsx:160)
                // confirmTransitionTo (history.js:131)
                // push (history.js:350)
                // navigate (Link.js:105)
                // onClick (Link.js:50)
                // ...
                // 
                // 
                // ==========================================================
                if (result === false) {
                    clearCachedNavigation();
                } else {
                    resumePageJump();
                }
            }).catch(clearCachedNavigation);
        } else {
            // Also supports synchronous leavingFunc result
            shouldGoToNextLocation = checkLeavingFuncResult;
        }

        return shouldGoToNextLocation;
    };

    /**
     * Note that this method is only invoked in history::transitionManager.confirmTransitionTo()
     * 
     * @param {object} nextLocation 
     * @param {string} action 
     * @returns {boolean} true to go to next page, otherwise stay.
     */
    function historyTransitionPrompt(nextLocation, action) {
        // const currentLocation = history.location; // location; //  history.location;
        const currentLocation = location;
        // console.log(`currentLocation.pathname: ${currentLocation.pathname}, nextLocation: ${nextLocation.pathname}`);

        const hasPageChanged = checkPageChangeStatus(currentLocation, nextLocation);

        const hasLocationChanged = currentLocation.hash !== nextLocation.hash;
        
        //
        let shouldGoToNextLocation = true;

        // if (!(hasPageChanged || hasLocationChanged)) {
        //     // still the same page, return true, i.e. should go to next location
        // } else { // if (!_.isNil(historyUnblock.current)) { // blocking in progress
        //      shouldGoToNextLocation = checkLeavingFuncResult(nextLocation, action);
        // }

        if (hasPageChanged || hasLocationChanged) {
            shouldGoToNextLocation = checkLeavingFuncResult(nextLocation, action);
        } else {
            // still the same page, return true, i.e. should go to next location
        }

        // if (shouldGoToNextLocation) {
        //     console.log('================go to next page immediately==================');
        // } else {
        //     // console.log('================stay on current page==================');
        // }

        return shouldGoToNextLocation;

    }

    /**
     * Call history.block() to set transitionManager.prompt,
     * and update leavingFunc.current
     */
    function block() {

        // console.log('================blocking invoked==================');
        unblock();

        if (_.isFunction(confirmLeavingFunc)) {
            leavingFunc.current = confirmLeavingFunc;
        } else { // _.isString(confirmLeavingFunc)
            leavingFunc.current = getDefaultConfirmModal(confirmLeavingFunc);
        }
        

        // Note: history.block() does not support async callback,
        // and it is not much different from addEventListener() in terms of execution-delay.
        historyUnblock.current = history.block(historyTransitionPrompt);
    }


    function updateBlockingStatus() {
        if (!shouldTriggerInterception) {
            unblock();
            return;
        }

        // console.log('=================update blocking status===============');

        const existingLeavingFunc = leavingFunc.current;
        const nextLeavingFunc = _.isFunction(confirmLeavingFunc) ? confirmLeavingFunc : null;

        if (!(existingLeavingFunc || nextLeavingFunc)) {
            // there is no leavingFunc defined, hence nothing happens
        } else if (existingLeavingFunc && !nextLeavingFunc) {
            // the leavingFunc has been removed
            unblock();
        } else if (!existingLeavingFunc && nextLeavingFunc) {
            // embrace the upcoming leavingFunc to block page transtition
            block();

        // Avoid multiple blocks when existing and next leavingFunc are the same,
        // ref: ReactRouterPause
        } else if (!isSameFunction(existingLeavingFunc, nextLeavingFunc)) {
            block();
        }
            
    }
    // ==================================================
    useEffect(() => {
        // return unblock;
        return () => {
            // only invokes unblock() on unmount
            // It will be called when history::transitionManager.confirmTransitionTo()
            // completes and Portal is leaving current wizard page.
            // The next thing happens is updateBlockingStatus() being called on the 
            // new wizard page -- hence results into block() being called again.
            //
            // This line seems to be able to get ignored, since it's called on onPageResume() already.
            // Neverthless, let's keep it here to keep thigs in sync (no pun intended) and show tribute
            // to ReactRouterPause.
            // console.log('=================unmount component===============');
            unblock();
        };
    }, []);

    // --------------------------------------------------
    // Update blocking status each and every time current component gets re-rendered
    updateBlockingStatus();

    return null;
}

AsyncPrompt.propTypes = {
    /**
     * This property retains the name from <Prompt /> provided by react-router-dom.
     * It surely does support value type of func or string, but please use only func here.
     * 
     * Default value: _.stubTrue, meaning always go to next page.
     */
    message: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /**
     * When shall block happen. Default to false.
     */
    when: PropTypes.bool,
};

AsyncPrompt.defaultProps = {
    message: _.stubTrue,
    when: false,
};

export default AsyncPrompt; // 
// export default withRouter(AsyncPrompt);

