export default {
    homePage: () => '/',
    accountsHomePage: () => '/accounts',
    accountSummary: (accountNumber) => `/accounts/${accountNumber}/summary`,
    policySummary: (accountNumber, policyNumber) => `/policies/${policyNumber}/summary`,
    accountBilling: (accountNumber) => `/accounts/${accountNumber}/summary`,
    accountSearch: (accountNumber) => `/accounts/${accountNumber}/summary`,
    newQuote: (accountNumber) => `/new-quote/${accountNumber}`,
    formPersonal: () => '/',
    profileSettings: () => '/profile',
    preferenceSettings: () => '/settings',
    FAQSettings: () => '/help',
    logout: (logout) => logout() || '/',
    claimDetails:  (accountNumber, claimNumber) => `/claims/${claimNumber}`,
};
