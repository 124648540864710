import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { useBreakpoint, BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import {
    WniDateUtil,
    WniPNIUtil,
    WniProductsUtil
} from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { JobService, PolicyChangeService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {  useProductsData } from 'wni-portals-util-react';
import { PolicyChangePaySuccessMessages as policychangeMessages } from 'wni-platform-translations';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { CommonPaySuccessChangePageStyles as styles} from 'wni-capability-gateway-react';
// import styles from './CAPaySuccessChangePage.module.scss';
import metadata from './CAPaySuccessChangePage.metadata.json5';
import { Button } from '@jutro/legacy/components';
import { Link } from '@jutro/router';
// import policychangeMessages from './CAPaySuccessChangePage.messages';

// const TYPE_MOBILE = 'mobile';
// const TYPE_HOME = 'home';
// const TYPE_WORK = 'work';

const ACCORDION_CARDS = [
    'contactAccordion',
];

const ZERO_COST = { amount: 0.00, currency: 'usd' };

function CAPaySuccessChangePage(props) {
    const modalApi = useWniModal();
    // const breakpoint = useContext(BreakpointTrackerContext);
    const { breakpoint } = useBreakpoint({});
    const translator = useTranslator();
    const [isPrinting, setPrintingState] = useState(false);
    const {
        interactionModel,
        loadingMask: { setLoadingMask }
    } = useDependencies(['interactionModel',  'loadingMask']);
    const {
        wizardData: submissionVM,
        turnOffWizardPromptMessage,
        wizardPageData
    } = props;

    const history = useHistory();
    const [handlePreemptionJobIDList, setHandlePreemptionJobIDList] = useState(_.get(submissionVM.value, 'changeData.handlePreemptionJobIDList_Ext', []));
    const { authHeader, authUserData } = useAuthentication();
    const {businessData_Ext: {
            enableCommercialLine
    } = {}} = authUserData;
    const { getProductName } = useProductsData();

    const {
        jobID: transactionNumber,
        baseData: {
            effectiveDate_Ext: effectiveDateObj,
            primaryNamedInsured_Ext: primaryNamedInsured,
            accountNumber,
            periodStartDate: policyPeriodStartDate,
            periodEndDate: policyPeriodEndDate,
            productCode,
            accountHolder: {
                contactType_Ext: accountType
            } = {}
        },
        changeData: {
            policyNumber,
            transactionCost = ZERO_COST,
            totalCost = ZERO_COST,
        },
        
    } = submissionVM.value;

    const policyChangeSource = wizardPageData[WizardConstants.policyChangeSource];


    useEffect(() => {
        turnOffWizardPromptMessage();
    }, []);

    // To be refacotred
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    // const accountNumber = _.get(submissionVM, 'value.baseData.accountNumber');

    // const policyNumber = _.get(submissionVM, 'value.policyNumber');

    // const transactionNumber = _.get(submissionVM, 'value.jobID');

    // const accountName = _.get(submissionVM, 'value.baseData.accountName');

    // const policyPeriodStartDate = _.get(submissionVM, 'value.baseData.periodStartDate');

    // const policyPeriodEndDate = _.get(submissionVM, 'value.baseData.periodEndDate');

    const policyPeriod = (
        <div>
            {`${WniDateUtil.formatDateWithPattern(policyPeriodStartDate)} - ${WniDateUtil.formatDateWithPattern(policyPeriodEndDate)}`}
        </div>
    );

    // let transactionCost = _.get(submissionVM, 'value.transactionCost');
    // if (!transactionCost) {
    //     transactionCost = { amount: 0.00, currency: 'usd' };
    // }

    // let totalCost = _.get(submissionVM, 'value.totalCost');
    // if (!totalCost) {
    //     totalCost = { amount: 0.00, currency: 'usd' };
    // }

    // const effectiveDate = WniDateUtil
    //     .formatDateWithPattern(_.get(submissionVM, 'value.baseData.effectiveDate'));
    const effectiveDate = WniDateUtil.formatDateWithPattern(effectiveDateObj);

    const handlePrint = useCallback(() => {
        setPrintingState(true);
    }, []);

    const handleDone = useCallback(() => {
        history.push(`/Change/${transactionNumber}/summary`);
    }, [history, transactionNumber]);

    const generateLinkOverrides = () => {
        return {
            accountNumberLink: interactionModel.getURLObj(accountType, 'accountSummary', accountNumber),
            policyNumberLink: interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber)
        };
    };

    const onWithdrawTransaction = (jobNumber) => {
        modalApi.showConfirm({
            title: translator(policychangeMessages.deleteQuote),
            message: jobNumber ? translator(policychangeMessages.sureDeletePolicyChange) : `${translator(policychangeMessages.sureDeletePolicyChange)} ${jobNumber}?`,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: translator(commonMessages.ok)
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            JobService.withdrawJobByJobNumber(jobNumber, authHeader).then(
                () => {
                    const newHandlePreemptionJobIDList = handlePreemptionJobIDList
                        .filter((jobID) => jobID !== jobNumber);
                    setHandlePreemptionJobIDList(newHandlePreemptionJobIDList);
                },
                () => {
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: {
                            id: 'custom.gateway.directives.JobSummary.Failed to withdraw submission',
                            defaultMessage: 'Failed to withdraw submission'
                        },
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    const findJobByJobNumber = async(e, jobID) => {
        e.preventDefault();
        setLoadingMask(true);
        const jobValue = await PolicyChangeService.findJobByJobNumber(jobID, authHeader);
        const isOosTransaction = _.get(jobValue, 'oosTransaction_Ext');
        const isPreemptiveTransaction = _.get(jobValue, 'preemptiveTransaction_Ext');
        let blockChangeMessage = <div className='font-Primary-light-16'>{translator(policychangeMessages.oosTransaction)}</div>;
        const shouldBlockChange = isPreemptiveTransaction || isOosTransaction;

        if (!enableCommercialLine) {
            history.push(`/change/${jobID}/summary`);
        } else if (shouldBlockChange && WniProductsUtil.isCLProduct(_.get(jobValue, 'productCode'))) {
            // trigger the block popup when this shouldBlockChange and it is Policy Change and it is and CL product
            if (isPreemptiveTransaction) {
                blockChangeMessage = <div className='mt-10 font-NeutralUltraDark-light-16'>{translator(policychangeMessages.preemptiveTransaction)}</div>;
            }
            modalApi.showAlert({
                title: translator(policychangeMessages.blockChangeTitle),
                message: blockChangeMessage,
                status: 'info',
                icon: 'gw-error-outline',
                size: 'lg',
            }).catch(_.noop)
        } else {
            history.push(`/change/${jobID}/summary`);
        }
        setLoadingMask(false);
    }

    const generateHandlePreemption = () => {
        return handlePreemptionJobIDList.length > 0 ? (
            <>
                {handlePreemptionJobIDList.map((jobID) => (
                    <div key={jobID}>
                        <span>{translator(policychangeMessages.handlePreemptionMessagePart1)}</span>
                        <Link onClick={(e) => findJobByJobNumber(e, jobID)} href="/">{jobID}</Link>
                        <span>{translator(policychangeMessages.handlePreemptionMessagePart2)}</span>
                        <Button className={`btn-link ${styles.deleteBtnLink}`} onClick={() => onWithdrawTransaction(jobID)}>
                            {translator(policychangeMessages.handlePreemptionMessageDeleteJobLink)}
                        </Button>
                        <span>
                            {translator(
                                policychangeMessages.handlePreemptionMessagePart3,
                                { jobID: jobID }
                            )}
                        </span>
                    </div>
                ))}
            </>
        ) : null;
    };

    const startCancellation = () => {
        history.push({
            pathname: `/policies/${policyNumber}/summary`,
            state: {
                isAutoStartCancel: 'true'
            }
        })
    };

    const pniPhoneNumber = WniPNIUtil.getPrimaryPhoneNumber(primaryNamedInsured);
    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        ...generateLinkOverrides,
        cancellationNotification: {
            visible: policyChangeSource === 'cancellation',
            message: translator(policychangeMessages.cancellationMessage, {
                startCancellation: <FieldLinkComponent
                    id='startCancellation'
                    className={styles.cancellationLink}
                    value={translator(policychangeMessages.startCancellation)}
                    onClick={startCancellation}
                />
            })
        },
        handlePreemptionMessages: {
            content: generateHandlePreemption()
        },
        transactionNumberLink: {
            to: `/Change/${transactionNumber}/summary`
        },
        driverAccordion: {
            title: policychangeMessages.driverDetails
        },
        contactAccordion: {
            title: policychangeMessages.contactInformationDetails
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined,
            defaultOpenedId: 'contactAccordion',
            // visible: false,
        },
        customerSuccessPagePolicyMessage: {
            content: translator(policychangeMessages.changeSuccess)
        },
        // primaryInsuredName: {
        //     value: accountName
        // },
        policyPeriod: {
            value: policyPeriod
        },
        changeEffectiveDate: {
            value: effectiveDate
        },
        policyTotalCost: {
            value: totalCost
        },
        changeInCost: {
            value: transactionCost
        },
        phone: {
            // value: getPhoneNumber()
            value: pniPhoneNumber,
        },
        policyType: {
            value: getProductName(productCode)
        },
        ...generateLinkOverrides(),
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onDone: handleDone
        },
        resolveComponentMap: {
            fieldlinkcomponent: FieldLinkComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

CAPaySuccessChangePage.propTypes = wizardProps;
export default CAPaySuccessChangePage;