import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

//
import DPHouseholdInfoPage from './pages/HouseholdInfo/DPHouseholdInfoPage';
import DPHouseholdInfoReadOnlyPage from './pages/HouseholdInfo/DPHouseholdInfoPage.readonly';

import DPResidencePage from './pages/DPResidence/DPResidencePage';
import DPResidenceReadOnlyPage from './pages/DPResidence/DPResidencePage.readonly';

import DPCoveragesPage from './pages/DPCoverages/DPCoveragesPage';
import DPCoveragesReadOnlyPage from './pages/DPCoverages/DPCoveragesPage.readonly';

import DPLossHistoryAndPriorCarrierPage from './pages/LossHistoryAndPriorCarrier/DPLossHistoryAndPriorCarrierPage';
import DPLossHistoryAndPriorCarrierReadOnlyPage from './pages/LossHistoryAndPriorCarrier/DPLossHistoryAndPriorCarrierPage.readonly';

import DPAdditionalInterestsPage from './pages/AdditionalInterests/DPAdditionalInterestsPage';
import DPAdditionalInterestsReadOnlyPage from './pages/AdditionalInterests/DPAdditionalInterestsPage.readonly';

//
import DPQuotePage from './pages/Quote/DPQuotePage';
import DPQuoteReadOnlyPage from './pages/Quote/DPQuotePage.readonly';

setComponentMapOverrides(
    {
        DPHouseholdInfoPage: {component: 'DPHouseholdInfoPage'},
        DPHouseholdInfoReadOnlyPage: { component: 'DPHouseholdInfoReadOnlyPage' },
        
        DPResidencePage: { component: 'DPResidencePage' },
        DPResidenceReadOnlyPage: { component: 'DPResidenceReadOnlyPage' },
        
        DPCoveragesPage: { component: 'DPCoveragesPage' },
        DPCoveragesReadOnlyPage: { component: 'DPCoveragesReadOnlyPage' },

        DPLossHistoryAndPriorCarrierPage: { component: 'DPLossHistoryAndPriorCarrierPage'},
        DPLossHistoryAndPriorCarrierReadOnlyPage: {component: 'DPLossHistoryAndPriorCarrierReadOnlyPage'},

        DPAdditionalInterestsPage: { component: 'DPAdditionalInterestsPage' },
        DPAdditionalInterestsReadOnlyPage: { component: 'DPAdditionalInterestsReadOnlyPage' },
        
        DPQuotePage: { component: 'DPQuotePage' },
        DPQuoteReadOnlyPage: { component: 'DPQuoteReadOnlyPage' },
    },
    {
        DPHouseholdInfoPage,
        DPHouseholdInfoReadOnlyPage,

        DPResidencePage,
        DPResidenceReadOnlyPage,

        DPCoveragesPage,
        DPCoveragesReadOnlyPage,

        DPLossHistoryAndPriorCarrierPage,
        DPLossHistoryAndPriorCarrierReadOnlyPage,

        DPAdditionalInterestsPage,
        DPAdditionalInterestsReadOnlyPage,

        DPQuotePage,
        DPQuoteReadOnlyPage,
    }
);


export {
    DPHouseholdInfoPage,
    DPHouseholdInfoReadOnlyPage,

    DPResidencePage,
    DPResidenceReadOnlyPage,

    DPCoveragesPage,
    DPCoveragesReadOnlyPage,

    DPLossHistoryAndPriorCarrierPage,
    DPLossHistoryAndPriorCarrierReadOnlyPage,

    DPAdditionalInterestsPage,
    DPAdditionalInterestsReadOnlyPage,

};

// eslint-disable-next-line import/prefer-default-export
export { default as DPSubmissionWizard } from './DPSubmissionWizard';
// export { default as DPJobWizard } from './templates/DPJobWizard';
export { default as DPWizardPage } from './templates/DPWizardPage';

export { default as DPQuoteIssueRenderHelper } from './pages/Quote/util/DPQuoteIssueRenderHelper';
