import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    ErrorsAndWarningsUtil,
    WindowUtil,
} from 'wni-portals-util-js';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import WizardPage from '../../templates/WALWizardPage';

import styles from './WALDummyPage.module.scss';
import metadata from './WALDummyPage.metadata.json5';
import messages from './WALDummyPage.messages';

function WALDummyPage(props) {
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        // history,
        // goNext
        // errorsForStep,
        // stepsWithErrors,
        // underwritingIssues,
        // acknowledgeError,
        // reportErrors,
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
    } = props;

    // const {
    //     quoteID, sessionUUID,
    //     baseData: {
    //         termType,
    //         periodStatus,
    //         selectedVersion_Ext: selectedVersion,
    //         accountHolder: {
    //             primaryAddress: {
    //                 postalCode,
    //             }
    //         },
    //         numberOfQuotedOrRatedQuotes_Ext: quoteCount,
    //         showFullPayDiscount_Ext: showFullPayDiscount,
    //         displayStatus_Ext: displayStatus,
    //     },
    //     lobData: {
    //         personalAuto: {
    //             isForNamedNonOwner_Ext: isForNamedNonOwner
    //         }
    //     },
    //     bindData,
    //     quoteData,
    // } = submissionVM.value;

    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('PAQuotePage');
    const [showErrors, updateShowErrors] = useState(false);

    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    // const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    // const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    useEffect(() => {

    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
        };
    }, [breakpoint, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WALDummyPage.propTypes = WizardPage.propTypes;
WALDummyPage.defaultProps = WizardPage.defaultProps;
export default WALDummyPage;
