import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cppMultiQuote'), method, data, additionalHeaders);
}

export default class CPPMultiQuoteService {
    /**
     * Invokes CPPMultiQuoteHandler.processQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('processQuote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CPPMultiQuoteHandler.finishQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static finishQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('finishQuote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes CPPMultiQuoteHandler.createDuplicateQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDuplicateQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDuplicateQuote', [quoteID, sessionUUID], authHeader);
    }


    /**
     * Invokes CPPMultiQuoteHandler.withdrawBranch()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} periodPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static withdrawBranch(quoteID, sessionUUID, periodPublicID, authHeader = {}) {
        return processSubmission('withdrawBranch', [quoteID, sessionUUID, periodPublicID], authHeader);
    }

    /**
     * Invokes CPPMultiQuoteHandler.updateBranchName()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} branchName
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBranchName(quoteID, sessionUUID, branchName, authHeader = {}) {
        return processSubmission('updateBranchName', [quoteID, sessionUUID, branchName], authHeader);
    }

        /**
     * Invokes CPPMultiQuoteHandler.payAsYouGo()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} branchName
     * @param {Object} authHeader 
     * @returns {Promise}
     */
        static payAsYouGo(quoteID, sessionUUID, toUpdate, authHeader = {}) {
            return processSubmission('payAsYouGo', [quoteID, sessionUUID, toUpdate], authHeader);
        }
}