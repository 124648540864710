import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './CALocationComponent.metadata.json5';
import TerritoryField from '../../../Common/TerritoryField';

function CALocationComponent(props) {
    const {
        locationVM,
        baseData,
        id,
        onValidate,
        onValueChange,
        syncWizardData,
        showErrors,
        isReadOnly,
        extendProps = {},
        linePath,
        stateOptions
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    
    const emplyoerVM = _.get(locationVM.value, 'locationNamedInsuredCandidates');
    const emplyoerValue = _.get(locationVM.value, 'singleLocationNamedInsured');
    const originalEmplyoerValue = _.get(locationVM.value, 'originalSingleLocationNamedInsured');
    const [employerOptions, updateEmployerOptions] = useState([]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [locationVM, id, onValidate, isComponentValid]);

    useEffect(() => {
        getAvailableEmployers();
    }, [])

    const getAvailableEmployers = () => {
        if(linePath === 'workersComp' && emplyoerVM && emplyoerVM.length > 0) {
            const employerCandidates = _.clone(emplyoerVM);
            const matchedEmployer = originalEmplyoerValue && emplyoerVM.find(item => item.publicID === originalEmplyoerValue.publicID);
            if(originalEmplyoerValue && !matchedEmployer) {
                employerCandidates.push(originalEmplyoerValue);
            }
            updateEmployerOptions(employerCandidates);
        }
    }

    const getEmployerOptions = () => {
        let options = [];
        if(emplyoerVM && emplyoerVM.length > 0) {
            options = employerOptions
            .map((elt) => {
                return {
                    code: elt.publicID,
                    name: elt.displayName
                }
            });
        } 
        return options;
    }

    const onEmployerValueChange = (value) => {
        const currentRow = _.clone(locationVM);
        const option = employerOptions.find(item => item.publicID === value)
        const optionParam = option ? {
            publicID: option.publicID,
            displayName: option.displayName
        } : null;
        _.set(currentRow.value, 'singleLocationNamedInsured', optionParam);
        syncWizardData(currentRow)
    }

    const getDisplayableFieldValue = useCallback((label) => {
        const displayableDTO = _.get(locationVM.value, 'displayableDTO');
        if(displayableDTO) {
            const displayableValue = displayableDTO
            .find((elt) => elt.label === label);
            return _.get(displayableValue, 'integerValue')
        }
    }, [locationVM]);

    const onDisplayablesValueChange = useCallback(
        (value, label) => {
            const displayableDTO = _.get(locationVM.value, 'displayableDTO');
            const newLocationVM = _.clone(locationVM);
            const index = displayableDTO.findIndex((elt) => elt.label === label);
            const diaplayablePath = `displayableDTO[${index}]`;
            _.set(newLocationVM.value, `${diaplayablePath}.integerValue`, value);
            _.set(newLocationVM.value, `${diaplayablePath}.updated`, true);
            _.set(newLocationVM.value, `numberOfEmployee`, value);
            syncWizardData(newLocationVM);
        },
        [locationVM, syncWizardData]);

    const writeValue = async(value, path) => {
        if(!value) {
            onValueChange(value, path);
            return false;
        }
        switch (path) {
            case "territoryCode":
                _.set(locationVM, 'territoryCode', value);
                syncWizardData(locationVM);
                break;
            default:
                onValueChange(value, path);
                break;
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        addressLookup: {
            model: locationVM,
            dataPath: 'address_Ext',
            onAddressChange: onValueChange,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true
            },
            availableStates: stateOptions,
            showErrors,
            onValidate,
            readOnly: isReadOnly
        },
        // locationQuestionCard: {
            // visible: linePath === 'workersComp',
        // },
        // locationUnderwritingQuestionSets: {
            // visible: linePath === 'workersComp',
            // contentMapper: CALocationsUtil.getQSMapperForWCLocations(),
        // }
        employeeCount: {
            visible: linePath === 'workersComp',
            value: getDisplayableFieldValue('Number Of Employees'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'Number Of Employees')
        },
        employer: {
            visible: linePath === 'workersComp',
            value: _.get(emplyoerValue, 'publicID'),
            availableValues: getEmployerOptions(),
            onValueChange: (value) => onEmployerValueChange(value)
        },
        territoryfield: {
            visible: linePath === 'commercialAuto',
            vm: locationVM,
            addressPath: 'address_Ext',
            onValueChange,
            showErrors,
            onValidate,
            isReadOnly,
            extendProps
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, locationVM, fieldId, fieldPath, overrideProps
        );
    }, [locationVM]);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent,
            territoryfield: TerritoryField
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={locationVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={writeValue}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

CALocationComponent.propTypes = {
};
CALocationComponent.defaultProps = {
};
export default CALocationComponent;
