/* eslint-disable camelcase */
import React from 'react';
import messages from './PUTooltip.message';

const PUTooltips = {
    UMBUnUnderinsuredMotCov_Ext : messages.UMBUnUnderinsuredMotCov_Ext,
    UMBExpandedDefinitionOfInsured_Ext: messages.UMBExpandedDefinitionOfInsured_Ext,
    UMBAutoOutsideOfUSOrCA_Ext: messages.UMBAutoOutsideOfUSOrCA_Ext,
    UMBExcBusiPurCov_Ext: messages.UMBExcBusiPurCov_Ext,
    UMBExcInciFarmPerLiabCov_Ext: messages.UMBExcInciFarmPerLiabCov_Ext,
    UMBExcPerIncOccLiabilityCov_Ext: messages.UMBExcPerIncOccLiabilityCov_Ext
}

export default PUTooltips