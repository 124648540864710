
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { WALOperatorChangeService } from 'wni-capability-policychange-wal';

import { WALOperatorsPage } from 'wni-capability-quoteandbind-wal-react';

function WALOperatorsChangePage(props) {

    return (
        <WALOperatorsPage {...props}
            // onPageNextClicked={WALOperatorChangeService.onPageNext}
        />
    );

}

WALOperatorsChangePage.propTypes = WALOperatorsPage.propTypes;
WALOperatorsChangePage.defaultProps = WALOperatorsPage.defaultProps;

export default WALOperatorsChangePage;