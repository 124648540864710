
import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil, WniProductsUtil } from 'wni-portals-util-js';
import { DPTooltipUtil as tooltips } from 'wni-portals-tooltip';
import QuestionSetsComponent from '../QuestionSetsComponent/QuestionSetsComponent';
import metadata from './OtherInformationComponent.metadata.json5';
import styles from '../../HOResidencePage.module.scss';

const {
    DP_PRODUCT_CODE
} = WniProductsUtil;

const OtherInformationComponent = (props) => {
    const {
        model: constructionVM,
        isReadOnly,
        onValidate,
        showErrors,
        onValueChange,
        dwellingQuestionSetsVM,
        baseState,
        isRCTSuccess,
        RCTSubmission,
        isRequiredForIssuance,
        coverageForm,
        messages,
        componentMapOverride,
        DP2Condo,
        linePath,
    } = props;

    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(metadata.componentContent), []);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1,
            isRequiredForIssuance
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        isResidenceForSale: {
            visible: coverageForm !== 'ho4',
            label: messages.isResidenceForSale
        },
        questionSets: {
            model: dwellingQuestionSetsVM,
            onValueChange,
            isReadOnly,
            onValidate,
            showErrors,
            baseState,
            coverageForm,
            messages,
            DP2Condo,
        },
        roomerBoardersNumber: {
            tooltip: {
                text: linePath === 'dwellingProperty' ? tooltips.DPRoomerBoardersNumberHelp : null
            }
        },
        // isFlatRoofOver: {
        //     visible: coverageForm !== 'ho4' && coverageForm !== 'ho6'
        // },
    };
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const defaultComponentMap = {
        QuestionSetsComponent: QuestionSetsComponent,
    }
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            ...defaultComponentMap,
            ...componentMapOverride,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={constructionVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

OtherInformationComponent.propTypes = {
    model: PropTypes.shape(
        {
        value: PropTypes.shape({})
        }
    ),
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    dwellingQuestionSetsVM: PropTypes.shape({}),
    baseState: PropTypes.string,
    isRCTSuccess: PropTypes.bool,
    RCTSubmission: PropTypes.shape({}),
    coverageForm: PropTypes.string,
    messages: PropTypes.object
};

OtherInformationComponent.defaultProps = {
    model: {},
    showErrors: true,
    isReadOnly: false,
    onValidate: _.noop,
    dwellingQuestionSetsVM: null,
    baseState: '',
    isRCTSuccess: false,
    RCTSubmission: {},
    coverageForm: '',
    messages: {}
};

export default OtherInformationComponent;
