const sectionFieldsMap = {
    memberContactDetail: [
        'person.firstName',
        'person.lastName',
        'dateOfBirth',
        'gender_Ext',
        'relationshipToInsured_Ext',
        'person.maritalStatus',
        'maritalStatus',
        'person.primaryPhoneType',
        'person.workNumber',
        'person.homeNumber',
        'person.cellNumber',
        'person.emailAddress1_Ext',
        'firstName',
        'middleName_Ext',
        'lastName',
        'suffix_Ext',
        'primaryPhoneType',
        'workNumber',
        'homeNumber',
        'cellNumber',
        'additionalInsured_Ext',
        'maritalStatus_Ext',
        'occupation_Ext'
    ],
    memberLicenseInfo: [
        'licensedDriver_Ext',
        'driverCovered_Ext',
        'driverUnLicensedReason_Ext',
        'driverCoveredExplain_Ext',
        'driversLicenseStatus_Ext',
        'licenseNumber',
        'licenseState',
        'ageFirstLicensed_Ext',
        'person.ssnOfficialID_Ext',
        'driverCoveredReason_Ext',
        'driverUnlicensedExplain_Ext',
        'isGoodStudentYN_Ext',
        'isStudentAwayWithoutCarYN_Ext',
        'schoolName_Ext',
        'defensiveDriverDiscount_Ext',
        'defDriverCourseCompDate_Ext',
        'workLossDiscount_Ext'
    ],
    underwriting: [
        'driverQuestionSets_Ext.children[0]'
    ]
};

const messagesInDriverPage = [

];

export default {
    sectionFieldsMap,
    messagesInDriverPage
};
