import React from 'react';
import WizardPage from '../../templates/CPWizardPage';
import CPStateSpecificInformationPage from './CPStateSpecificInformationPage';

function CPStateSpecificInformationReadOnlyPage(props) {

    return (
        <CPStateSpecificInformationPage {...props} isReadOnly/>
    );
}

CPStateSpecificInformationReadOnlyPage.propTypes = WizardPage.propTypes;
export default CPStateSpecificInformationReadOnlyPage;