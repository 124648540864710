import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { PUHouseholdInfoService } from 'wni-capability-quoteandbind-pu';
import {
    ValidationIssueUtil,
    WniDateUtil,
    ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    WniPNIUtil,
    WniUMBDriverUtil,
    WniProductsUtil
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
} from 'wni-capability-gateway-react';
import { useProductsData, WniTableRowUtil } from 'wni-portals-util-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { AOConfig } from 'wni-portals-config-js';
import moment from 'moment';

import WizardPage from '../../templates/PUWizardPage';
import styles from './PUHouseholdInfoPage.module.scss';

import metadata from './PUHouseholdInfoPage.metadata.json5';
import messages from './PUHouseholdInfoPage.messages';
import puQuestionSetsUtil from './utils/PUQuestionSetsUtil'
import PUPrimaryNamedInsuredInputPopup from './components/PUPrimaryNamedInsuredInputPopupComponent/PUPrimaryNamedInsuredInputPopup';
import PUEditResidentsInputPopup from './components/PUEditResidentsPopupComponent/PUEditResidentsInputPopup';
import PUAddResidentsInputPopup from './components/PUAddResidentsPopupComponent/PUAddResidentsInputPopup';
import { IconButton } from '@jutro/legacy/components';

const { aoStates } = AOConfig;

function PUHouseholdInfoPage(props) {

    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        setAgentComponentReadOnly,
        // updateWizardSnapshot,
        // updateWizardReadOnly,
        wizardSnapshot,
        // //
        // wizardStepToFieldMapping,
        // markFollowingWizardStepsUnvisited,
        savePolicyDetailsData,
        isOpenPNIPopupPageInit,
        setEffectiveDateForChangeTransaction = false,
        maximumEffectiveDate,
        // jumpTo: wizardJumpTo,
        // steps: wizardSteps,
        //
        // wizardPageData,
        // updateWizardPageData,
        // readOnlyFields: {
        //     // effectiveDate = false,
        //     agencyOfRecord = false,
        // },
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        policyInfoSource_Ext: policyInfoSource,
        baseData,
        lobData: {
            personalUmbrella: {
                householdInfo
            }
        },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        quoteFlow_Ext: quoteFlow,
        effectiveDate_Ext: transactionEffectiveDate,
        policyAddress,
        productCode
    } = baseData;
    const {
        isPolicyInfoFirstEnter_Ext: isPolicyInfoFirstEnter,
        umbdrivers,
        umbdriverAsPNI,
        uwqapplicantExistPolicy_Ext: uwqApplicantExistPolicy,
        uwqholdNonCompPosi_Ext: uwqHoldNonCompPosi,
        uwqholdNonCompApplies_Ext: uwqholdNonCompApplies
    } = householdInfo;

    // const history = useHistory();
    const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [driverResidentsTableData, updateDriverResidentsTableData] = useState(umbdrivers);
    const viewModelService = useContext(ViewModelServiceContext);
    const { getAvailableEffectiveDate, getAvailablePolicyState, getProductsMinDate } = useProductsData();
    const initialEffectiveDate = _.get(wizardSnapshot.value, 'baseData.effectiveDate_Ext')
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('PUPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);
    const { domainCompany: { domainName} } = useDependencies(['domainCompany']);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);


    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const questionSetMapper = puQuestionSetsUtil.getQSMapperForPUHouseholdInfo()

    const getMinDate = useCallback(() => {
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            return moment(getAvailableEffectiveDate(productCode, baseState)).add(-5, 'd').startOf('day').toDate();
        }
        return getAvailableEffectiveDate(productCode, baseState)
    }, [quoteFlow, policyInfoSource, productCode, baseState]);

    const getOOSPeriods = useCallback(() => {
        const oOSSliceDates = _.get(submissionVM.value, 'changeData.oossliceDates_Ext');
            let oOSStartDate = _.get(wizardSnapshot.value, 'baseData.periodStartDate')
            let oOSEndDate = _.get(wizardSnapshot.value, 'baseData.periodEndDate')
            
            const oOSSliceDatesInMoment = oOSSliceDates.map((res) => {
                return moment(res)
            })
            const sortOOSSliceDatesInMoment = oOSSliceDatesInMoment.sort((a, b) => a.diff(b))
            const oOSEndDateIndex = sortOOSSliceDatesInMoment.findIndex((res) => res.isAfter(moment(initialEffectiveDate)))
            if (oOSEndDateIndex !==-1 ) { 
                oOSEndDate = sortOOSSliceDatesInMoment[oOSEndDateIndex] 
                if (oOSEndDateIndex > 0 ) { 
                oOSStartDate = sortOOSSliceDatesInMoment[oOSEndDateIndex-1]
                }
            }
            if (oOSEndDateIndex ===-1 && (sortOOSSliceDatesInMoment.length>0 )) {
                oOSStartDate = sortOOSSliceDatesInMoment[sortOOSSliceDatesInMoment.length-1]
            }
            return {oOSStartDate, oOSEndDate}
    }, [initialEffectiveDate, submissionVM.value, wizardSnapshot.value])


    const effectiveMinDate = useCallback(() => {
        if (setEffectiveDateForChangeTransaction) {
            // return _.get(submissionVM, 'value.baseData.periodStartDate')
            const {oOSStartDate} = getOOSPeriods()
            return oOSStartDate
        }
        return getMinDate()
    }, [setEffectiveDateForChangeTransaction, getMinDate, getOOSPeriods])

    const effectiveMaxDate = useCallback(() => {
        // const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        if (setEffectiveDateForChangeTransaction) {
            // if there is bound change, use oosEndDate as maxDate
            const {oOSEndDate} = getOOSPeriods()
            // if (_.get(submissionVM.value, 'changeData.oossliceDates_Ext') !== null) {
                const maxDate = ( moment(maximumEffectiveDate).isBefore(oOSEndDate) ? maximumEffectiveDate : WniDateUtil.getDateObj(oOSEndDate) )
                return maxDate
            // }
            // const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
            //     maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
            // return maxDate
        }
        return undefined

    }, [submissionVM, setEffectiveDateForChangeTransaction, maximumEffectiveDate]);

    const getEffectiveDateFieldOverrideProps = () => {
        // const prodDate = getAvailableEffectiveDate(productCode, baseState);
        const prodDate = getProductsMinDate(productCode, baseState);
        const currentDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const isProdDateBeforeSysDate = moment(prodDate).valueOf() - moment(systemDate).valueOf() < 0;
        let shouldShowNewValidation = false;
        if (!isProdDateBeforeSysDate) {
            // current<prod show
            if(moment(currentDate).valueOf() - moment(prodDate).valueOf() < 0) {
                shouldShowNewValidation = true;
            }
        }
        const effectiveDateFieldOverrideProps = {
            minDate: effectiveMinDate(),
            maxDate: effectiveMaxDate(),
            value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            // onValueChange: onStartDateChange,
            showErrors: showErrors || shouldShowNewValidation,
            // readOnly: effectiveDate
        };
        if (shouldShowNewValidation) {
            _.set(effectiveDateFieldOverrideProps, 'validationMessages',
                [translator(messages.effectiveDateValidation, {
                    productEffectiveDate: WniDateUtil.formatDateWithPattern(prodDate, 'MM/DD/YYYY'),
                })]);
        }
        return  effectiveDateFieldOverrideProps;
    };


    
    const isEffecitiveDateValidForOOS = useCallback(() => {
        // return true when in policy change transaction and in slice
        // always return true when in NB transaction
        // return false when in policy change transaction and oos
        if (setEffectiveDateForChangeTransaction) {
            
            
            const {oOSStartDate, oOSEndDate} = getOOSPeriods()
            const isDateSameOrAfterSliceStart =  moment(transactionEffectiveDate).isSameOrAfter(oOSStartDate)
            const isDateBeforeSliceEnd =  moment(transactionEffectiveDate).isBefore(oOSEndDate)
            return isDateSameOrAfterSliceStart && isDateBeforeSliceEnd
        }
        return true
    }, [getOOSPeriods, setEffectiveDateForChangeTransaction, transactionEffectiveDate])

    const isBaseStateInMutualState = useCallback(() => {
        return aoStates.includes(baseState)
    }, [baseState])

    const getBaseStateList = useCallback(() => {
        const allBaseStateList = _.get(submissionVM, 'baseData.baseState_Ext._aspects.availableValues')
        const displaAllBaseStateList = allBaseStateList.map((state) => {
            return {
                code: state.code,
                name: translator({ id: state.name })
            }
        })
        const policyEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const availableState = getAvailablePolicyState(productCode, policyEffectiveDate)
        const filteredBaseStateList = _.filter(displaAllBaseStateList, (state) => availableState.includes(state.code))     
        return filteredBaseStateList
    }, [submissionVM, translator])

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, umbDriverAsPNIVM, umbDriversVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                umbDriverAsPNIVM, 
                umbDriversVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                showEmployeeFlagToggle: false,
                authHeader
            };
            return modalApi.showModal(
                <PUPrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, modalApi]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        _.set(primaryNamedInsuredVM.value, 'isNewAccount_Ext', true)
        // _.set(primaryNamedInsuredVM.value, 'accountOrgType_Ext', 'commonownership')
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );

        const umbDriverAsPNIVM = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdriverAsPNI');
        const umbDriversVM = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdrivers')
        // set occupation, occupationExplain, emailAddress1 from UMBDriver to PNI
        _.set(primaryNamedInsuredVM, 'emailAddress1', _.get(umbDriverAsPNIVM, 'emailAddress1', null))
        _.set(primaryNamedInsuredVM, 'occupation_Ext', _.get(umbDriverAsPNIVM, 'occupation_Ext', null))
        _.set(primaryNamedInsuredVM, 'occupationExplain_Ext', _.get(umbDriverAsPNIVM, 'occupationExplain_Ext', null))
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, umbDriverAsPNIVM, umbDriversVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.PNIVM.value);
                const newAddress = _.get(updatedVM.PNIVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);
                
                // Find updated account contact, update pni data to accountContact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM.PNIVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.PNIVM.value);
                if (accountHolder.publicID === _.get(updatedVM.PNIVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.PNIVM.value);
                }
                // update pni dato to umbDriverAsPNIVM
                _.set(submissionVM.value, 'lobData.personalUmbrella.householdInfo.umbdriverAsPNI', updatedVM.driverVM.value)
                
                // Find updated umbDriver, update pni data to umbDriver list
                const newUmbDriverAsPNIIndex = _.get(umbDriversVM, 'value', []).findIndex(
                    (driversVM) => driversVM.mappingContactPublicID === _.get(updatedVM.PNIVM, 'value.publicID')
                );
                _.set(submissionVM.value, `lobData.personalUmbrella.householdInfo.umbdrivers[${newUmbDriverAsPNIIndex}]`, updatedVM.driverVM.value)

                const newSubmissionVM = _.clone(submissionVM);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    },[showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    useEffect(() => {
        if (isOpenPNIPopupPageInit) {
            openEditPrimaryNamedInsuredPopup();
        }
    }, [isOpenPNIPopupPageInit]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName =   _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.accountContacts_Ext[0].primaryAddress'));
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress=WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM]);

    const isResidentsValid = useCallback(() => {
        const isDriversValid = umbdrivers.every(
            (elt) => !_.isNil(elt.isLicensedDriver) 
                && !_.isNil(elt.firstName)
                && !_.isNil(elt.lastName)
                && !_.isNil(elt.dateOfBirth)
                && !_.isNil(elt.relationshipToInsured_Ext)
                && !_.isNil(elt.additionalNamedInsured)
        )
        return isDriversValid
    }, [umbdrivers])

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate();
        const maxDate = effectiveMaxDate()
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = (maxDate === undefined ? 1 : moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf())
        if (setEffectiveDateForChangeTransaction) {
            return isEffecitiveDateValidForOOS()
        }
        return ((minDif >= 0) && (maxDif >= 0))
    }, [submissionVM.value, effectiveMinDate, effectiveMaxDate, setEffectiveDateForChangeTransaction, isEffecitiveDateValidForOOS]);
    
    const isUWQuestionExplainValid = useCallback(() => {
        const questionAnswers = _.get(submissionVM.value, 'lobData.personalUmbrella.preQualQuestionSets[0].answers',[]);
        if (questionAnswers.doesApplicantOwnAircraft_Ext === 'true') {
            return !_.isEmpty(questionAnswers.doesApplicantOwnAircraftExplain_Ext);
        }
        if (questionAnswers.isApplicantHasForeclosure_Ext === 'true') {
            return !_.isEmpty(questionAnswers.isApplicantHasForeclosureExplain_Ext);
        }
        if (questionAnswers.hasAnyApplicantHadUMBDeclined_Ext === 'true') {
            return !_.isEmpty(questionAnswers.hasAnyApplicantHadUMBDeclinedExplain_Ext);
        }
        return true;
    }, [submissionVM.value]);

    const PUHouseholdInfoValidation = useCallback(() => {
        return isEffectiveDateValid()
            && WniPNIUtil.isPNIValid(primaryNamedInsured)
            && isResidentsValid()
            && isUWQuestionExplainValid();
        // && isAgencyAndAgentInfoValid()
    }, [isEffectiveDateValid, isResidentsValid, primaryNamedInsured, isUWQuestionExplainValid]);

    useEffect(() => {
        registerComponentValidation(PUHouseholdInfoValidation);
    }, [PUHouseholdInfoValidation, registerComponentValidation]);


    const getMultipleSelectOptions = useCallback(() => {
        const options =  _.get(submissionVM.value, 'lobData.personalUmbrella.householdInfo.uwqselectedProductTypeOptions_Ext');
        return options.map((res) => {
            return {
                code: res,
                name: WniProductsUtil.getProductName(res) || translator({ id: res })
            }
        })
    },[submissionVM.value, translator]);

    const getHoldNonCompApplies = () => {
        const options = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.uwqholdNonCompAppliesOptions_Ext.aspects.availableValues', []);
        
        return options.map((res) => {
            return {
                code: res.code,
                name: translator({ id: res.name })
            } 
        })
    }

    const updateErrorsAndWarnings = (res) => {
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        let newValidationIssues = {}
        if(showErrors){
            newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        }else{
            newValidationIssues = ErrorsAndWarningsUtil.getServerIssues(errorsAndWarnings);
        }
        
        updateValidationIssues(newValidationIssues);

        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
    }

    const refreshDriverResidentsTable = (datas) => {
        const initData = [];
        _.each(datas, (contact) => {
                initData.push(contact);
        })
        updateDriverResidentsTableData(initData)
    };


    const editDriverRow = async(event, item) => {
        const driverVMList = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdrivers.children');
        const pniFirstName = _.get(primaryNamedInsured, 'firstName');
        const pniLastName = _.get(primaryNamedInsured, 'lastName');
        const componentProps = {
            title: messages.editResidentsTitle,
            nextBtnLabel: 'Save & Next Household Resident',
            saveBtnLabel: 'Save & Continue',
            cancelBtnLabel: 'Cancel',
            // contactVM: contactVM,
            driverVMList,
            selectedDriverPublicID: item.publicID ,
            viewModelService: viewModelService,
            policyAddress,
            pniFirstName,
            pniLastName,
            quoteFlow
        };
        modalApi.showModal(<PUEditResidentsInputPopup {...componentProps} />)
            .then(async (res) => {
                const newSubmissionVM = _.clone(submissionVM);
                const listToUpdate = res.filter((elt) => _.get(elt, 'relationshipToInsured_Ext') !== 'PRIMARYNAMEDINSURED')
                setLoadingMask(true);
                const rs = await PUHouseholdInfoService.updateResidents(quoteID, sessionUUID, listToUpdate, authHeader);
                // update tableData
                const tableData = _.get(rs, 'umbdrivers', {});
                updateErrorsAndWarnings(rs)
                refreshDriverResidentsTable(tableData);                
                _.set(newSubmissionVM.value, 'lobData.personalUmbrella.householdInfo.umbdrivers', tableData);
                // update resident (role as PNI) data to PNI fields
                const umbDriverAsPNIIndex = tableData.findIndex(
                    (driversVM) => driversVM.firstName === pniFirstName && driversVM.lastName === pniLastName);
                _.set(submissionVM.value, 'lobData.personalUmbrella.householdInfo.umbdriverAsPNI', tableData[umbDriverAsPNIIndex])
                updateSubmissionVM(newSubmissionVM);
                updateDisplayWarnings(false);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    };

    
    const deleteDriverRow = useCallback(async (event, item) => {
        modalApi.showConfirm({
            title: messages.deleteResidentsTitle,
            message: messages.deleteResidentsMessage,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const publicID = _.get(item, 'publicID');
                try {
                    setLoadingMask(true);
                    const newSubmissionVM = _.clone(submissionVM);
                    const rs = await PUHouseholdInfoService.removeResidents(quoteID, sessionUUID, publicID, authHeader);
                    const tableData = _.get(rs, 'umbdrivers', {});
                    refreshDriverResidentsTable(tableData);
                    updateErrorsAndWarnings(rs)
                    _.set(newSubmissionVM.value, 'lobData.personalUmbrella.householdInfo.umbdrivers', rs.umbdrivers);
                    updateSubmissionVM(newSubmissionVM);
                } finally {
                    setLoadingMask(false);
                }
                return true;
            }, _.noop
        );
    }, [modalApi, setLoadingMask, submissionVM, updateSubmissionVM]);
   
    const getInvalidTableRows = (driver) => {
        if (_.isNil(driver)){
            return true
        }
        const isValid = !_.isNil(driver.isLicensedDriver) 
            && !_.isNil(driver.firstName)
            && !_.isNil(driver.lastName)
            && !_.isNil(driver.dateOfBirth)
            && !_.isNil(driver.relationshipToInsured_Ext)
            && !_.isNil(driver.additionalNamedInsured)
        return isValid
    }


    const renderDriverTableAction = (item, index) => {
        const classNames = getInvalidTableRows(item) ? '' : 'highlight'
        if (!isResidentsValid() && showErrors){
            WniTableRowUtil.setTableRowClass('error', 'driverResidentsTable');
        } else if (isResidentsValid() && showErrors){
            WniTableRowUtil.removeTableRowClass('error', 'driverResidentsTable');
        }
        return (
            <div className={classNames}>
                <IconButton
                    id={`edit${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => editDriverRow(e, item)}
                />
                <IconButton
                    id={`delete${index}`}
                    icon="gw-delete"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => deleteDriverRow(e, item)
                    }
                />
            </div>
        );
    };


    const renderCell = (item, index, { path, typeKey }) => {
        return item[path] ? translator({id: `${typeKey}.${item[path]}` }) : '-'
    };

    const licensedDriverRenderCell = (item) => { 
        const val = _.get(item, 'isLicensedDriver');
        if (val === false) {
            return 'No';
        }
        if (val === true) {
            return 'Yes';
        }
        return '-';
    }

    const aniRenderCell = (item) => { 
        const val = _.get(item, 'additionalNamedInsured');
        if (val === false || val === null) {
            return 'No';
        }
        if (val === true) {
            return 'Yes';
        }
        return '-';
    }



    const handleAddDriverResidents = async () => {      
        // const driverVM = viewModelService.create(
        //     {}, 'pc', 'wni.edge.capabilities.quote.lob.personalumbrella.dto.UMBDriverDTO'
        // );
        // _.set(driverVM, 'publicID', 'new');
        const componentProps = {
            quoteID, 
            sessionUUID,
            title: messages.addResidentsTitle,
            // iconClassType: false,
            // showCloseBtn: false,
            // showCancelBtn: false,
            addBtnLabel: 'Save & Add HouseHold Resident',
            saveBtnLabel: 'Save & Continue',
            cancelBtnLabel: 'Cancel',
            viewModelService: viewModelService,
            nextButtonVisible: true,
            policyAddress
        };
        modalApi.showModal(<PUAddResidentsInputPopup {...componentProps} />)
            .then(async (res) => {
                const newSubmissionVM = _.clone(submissionVM);
                const tableData = _.get(res, 'umbdrivers', {});
                setLoadingMask(true);
                refreshDriverResidentsTable(tableData);
                updateErrorsAndWarnings(res)
                _.set(newSubmissionVM.value, 'lobData.personalUmbrella.householdInfo.umbdrivers', tableData);
                updateSubmissionVM(newSubmissionVM);
                updateDisplayWarnings(false);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    };

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        const umbDriverAsPNIVM = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdriverAsPNI');
        // set occupation, occupationExplain, emailAddress1 from UMBDriver to PNI
        _.set(primaryNamedInsuredVM, 'emailAddress1', _.get(umbDriverAsPNIVM, 'emailAddress1', null))
        _.set(primaryNamedInsuredVM, 'occupation_Ext', _.get(umbDriverAsPNIVM, 'occupation_Ext', null))
        _.set(primaryNamedInsuredVM, 'occupationExplain_Ext', _.get(umbDriverAsPNIVM, 'occupationExplain_Ext', null))

        const puHouseholdInfoData = _.get(submissionVM.value, 'lobData.personalUmbrella.householdInfo')
        const questionSetData = _.get(submissionVM.value, 'lobData.personalUmbrella.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData')

        if (!_.get(puHouseholdInfoData, 'umbdriverAsPNI.publicID')) {
            delete puHouseholdInfoData.umbdriverAsPNI
        }

        const res = await savePolicyDetailsData(
            quoteID, 
            sessionUUID,
            questionSetData,
            quoteBaseData,
            puHouseholdInfoData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);
        
        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);


    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            // effectiveDate: {
            //     minDate: effectiveMinDate(),
            //     maxDate: effectiveMaxDate(),
            //     showErrors
            // },
            effectiveDate: {
                ...getEffectiveDateFieldOverrideProps()
            },
            policyState: {
                value: baseState,
                //
                readOnly: !(isPolicyInfoFirstEnter && isBaseStateInMutualState()),
                availableValues: getBaseStateList()
            },
            agencyAndProducerInfo: {
                isReadOnly: !!setAgentComponentReadOnly,
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: false
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            primaryNamedInsured: {
                value: getPrimaryNamedInsuredAndDisplay(),
                containerClassName: (!(WniPNIUtil.isPNIValid(primaryNamedInsured) && WniUMBDriverUtil.isDriverValid(umbdriverAsPNI)) && showErrors) ? 'validationError' : ''
            },
            primaryNamedInsuredEditIcon: {
                onClick: openEditPrimaryNamedInsuredPopup,
            },
            UWQApplicantExistPolicyToggle: {
                label: translator(messages.uWQApplicantExistPolicyToggle, {
                    companyName: domainName
                }),
            },
            UWQSelectedProductTypeMultiSelect: {
                required: true,
                availableValues: getMultipleSelectOptions(),
                visible: !!uwqApplicantExistPolicy,
            },
            UWQHoldNonCompApplies: {
                required: true,
                visible: !!uwqHoldNonCompPosi,
                availableValues: getHoldNonCompApplies(),
            },
            UWQHoldNonCompPosiExplain: {
                required: true,
                visible: !!uwqHoldNonCompPosi && (uwqholdNonCompApplies.includes('other') || uwqholdNonCompApplies.includes('board_member'))
            },
            policyUnderwritingQuestionSets: {
                contentMapper: questionSetMapper,
            },
            addDriverButton: {
                onClick: handleAddDriverResidents,
            },
            driverResidentsTable: {
                data: driverResidentsTableData,
            },
            actionColumn: {
                renderCell: renderDriverTableAction,
            },
        };
    }, [getMinDate, showErrors, isPolicyInfoFirstEnter, isBaseStateInMutualState, getBaseStateList, submissionVM.baseData, handleAgencyChange, onValidate, getPrimaryNamedInsuredAndDisplay, openEditPrimaryNamedInsuredPopup, translator, domainName, getMultipleSelectOptions, uwqApplicantExistPolicy, uwqHoldNonCompPosi, getHoldNonCompApplies, uwqholdNonCompApplies, questionSetMapper, driverResidentsTableData, renderDriverTableAction]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderCell: renderCell,
            licensedDriverRenderCell: licensedDriverRenderCell,
            aniRenderCell: aniRenderCell

        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

PUHouseholdInfoPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
};

PUHouseholdInfoPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: PUHouseholdInfoService.savePUHouseholdInfoData,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
} 
export default PUHouseholdInfoPage;