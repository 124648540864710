import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalHeader, ModalNext, ModalBody, ModalFooter, Button } from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { ConfigUtil } from 'wni-portals-util-js';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { WniDataTable } from 'wni-common-base-components';
import { CPRisksService } from 'wni-capability-quoteandbind-cp';

function BuildingAndOccupanyInfoPopup(props) {
    // note: import not set legacy for Kufri update, save close button
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        setLoadingMask,
        lossCostResponses,
        jobID,
        sessionUUID,
        authHeader,
        locationPublicID,
        buildingPublicID
    } = props;

    const [selectedBuilding, updateSelectedBuilding] = useState(null);
    const [selectedOccupancy, updateSelectedOccupancy] = useState(null);

    const handleSaveClose = async () => {
        setLoadingMask(true);
        await CPRisksService.saveSelectedBuildingOccupancyInfo(jobID, sessionUUID, locationPublicID, buildingPublicID, selectedBuilding, selectedOccupancy, authHeader);
        setLoadingMask(false);
        onResolve();
    }

    useEffect(() => {
        lossCostResponses.forEach((lossCostResponse, lcrIndex) => {
            _.set(lossCostResponse, 'rowIdPath', ConfigUtil.getUuid() + lcrIndex);
            const buildingInfos = _.get(lossCostResponse, 'buildingInfo', []);
            const occupancyInfos = _.get(lossCostResponse, 'occupanciesInfo', []);
            if (!_.isEmpty(buildingInfos)) {
                [lossCostResponse.buildingInfo].forEach((building) => {
                    _.set(building, 'rowIdPath', ConfigUtil.getUuid() + lcrIndex);
                })
            }
            if (!_.isEmpty(occupancyInfos)) {
                lossCostResponse.occupanciesInfo.forEach((occupancy) => {
                    _.set(occupancy, 'rowIdPath', ConfigUtil.getUuid() + lcrIndex);
                })
            }
        });
    }, [lossCostResponses]);

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader
                contentLayout={{ component: 'div' }}
                titleLayout={{ component: 'div' }}
                title={(
                    <Flex justifyContent="between">
                        <h3>Building And Occupancy Description</h3>
                        <Button className="btn-link" label='Return to New Building' onClick={() => onReject()} />
                    </Flex>
                )} />
            <ModalBody id="searchClassCodeDescription" autoFocus={false}>
                {
                    lossCostResponses.map(lossCostResponse => {
                        return <div className='table-mutiple'>
                            {/* Building */}
                            <WniDataTable
                                columnsConfigurable={false}
                                data={[_.get(lossCostResponse, 'buildingInfo')]}
                                id="buildingInfo"
                                showPagination={false}
                                showSearch={false}
                            >
                                <DisplayColumn
                                    id="select"
                                    textAlign="left"
                                    columnProportion="3"
                                    renderCell={(item) => {
                                        const buttonDom = <Button className="btn-link font-bold" label='Select' onClick={() => {
                                            updateSelectedBuilding(item);
                                            const occupanciesInfo = _.get(lossCostResponse, 'occupanciesInfo', []);
                                            if (!_.includes(occupanciesInfo, selectedOccupancy)) {
                                                // reset selected occupancy when building is selected in another block
                                                updateSelectedOccupancy(null);
                                            }
                                        }} disabled={_.isEqual(selectedBuilding, item)} />
                                        return WniTableRowUtil.renderCell(item.descriptionName, buttonDom)
                                    }}
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="Building Description"
                                    id="Name"
                                    path="descriptionName"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="CSP Class Code"
                                    id="CspClass"
                                    path="cspClass"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="BG I Specific"
                                    id="BGILossCost"
                                    path="bgilossCost"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="Construction Type To Use"
                                    id="ConstructionType"
                                    path="constructionType"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                            </WniDataTable>
                            {/* Occupancy */}
                            <WniDataTable
                                columnsConfigurable={false}
                                data={_.get(lossCostResponse, 'occupanciesInfo', [])}

                                id="occupancyInfo"
                                showPagination={false}
                                showSearch={false}
                            >
                                <DisplayColumn
                                    id="emptyPlaceHolder"
                                    textAlign="left"
                                    columnProportion="3"
                                    renderCell={() => { return null }}
                                    sortable={false}
                                />
                                <DisplayColumn
                                    id="select"
                                    textAlign="left"
                                    columnProportion="3"
                                    renderCell={(item) => {
                                        const buttonDom = <Button className="btn-link font-bold" label='Select' onClick={() => {
                                            updateSelectedOccupancy(item);
                                            // selected related building
                                            const buildingInfo = _.get(lossCostResponse, 'buildingInfo');
                                            if (buildingInfo !== selectedBuilding) {
                                                updateSelectedBuilding(buildingInfo);
                                            }
                                        }} disabled={_.isEqual(selectedOccupancy, item) &&
                                            (lossCostResponse.rowIdPath === selectedOccupancy.rowIdPath || lossCostResponse.rowIdPath === selectedBuilding.rowIdPath)} />
                                        return WniTableRowUtil.renderCell(item.descriptionName, buttonDom)
                                    }}
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="Occupancy Description"
                                    id="name"
                                    path="descriptionName"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="CSP Class Code"
                                    id="CspClass"
                                    path="cspClass"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="BG I Specific"
                                    id="BGILossCost"
                                    path="bgilossCost"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable={false}
                                />
                            </WniDataTable>
                        </div>
                    })
                }
            </ModalBody>
            <ModalFooter>
                <Button label='Save & Close' onClick={() => { handleSaveClose() }} disabled={_.isNil(selectedBuilding) || _.isNil(selectedOccupancy)} />
            </ModalFooter>
        </ModalNext>
    );
}
BuildingAndOccupanyInfoPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    lossCostResponses: PropTypes.array
};

BuildingAndOccupanyInfoPopup.defaultProps = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    lossCostResponses: []
};

export default BuildingAndOccupanyInfoPopup;
