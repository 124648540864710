import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';


import { formatDate, formatNumber, FormattedDate, FormattedNumber } from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/legacy/datatable';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';



import WCJurisdictionSummaryComponent from './WCJurisdictionSummaryComponent';


import { Button } from '@jutro/legacy/components';


function extractRowData(jurisdictionDTO) {
    const {
        state,
        officialID,
    } = jurisdictionDTO;
    return {
        state,
        officialID,
    };
}

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function WCJurisdictionsComponent(props) {

    const {
        jurisdictions, // WCJurisdictionDTO[];
    } = props;

    const [currentRow, updateCurrentRow] = useState(undefined);

    if (_.isEmpty(jurisdictions)) {
        return null;
    }

    useEffect(() => {
        if (!_.isEmpty(jurisdictions)) {
            updateCurrentRow(jurisdictions[0]);
        }
    }, []);


    const onRowClick = (rowData, selectedIndex, ...params) => {
        const newCurrentRow = jurisdictions[selectedIndex];
        updateCurrentRow(newCurrentRow);
    };

    const renderCell = (columnField) => {
        return (rowData, ...params) => {
            const retval = rowData[columnField];
            // console.log(params);
            return retval;
        }; 
    } ;
    

    return (
        <div>
            
            <DataTable
                id="WCJurisdictions"
                data={jurisdictions}
                tableLabel="State-Specific Information"
                onRowClick={onRowClick}
                selectOnRowClick
                showSearch={false}
            >
                <DisplayColumn
                    header="State"
                    renderCell={renderCell('state')}
                />
                <DisplayColumn
                    header="Official ID"
                    renderCell={renderCell('officialID')}
                />
            </DataTable>
            <WCJurisdictionSummaryComponent jurisdiction={currentRow} />
        </div>
    );
    
}

WCJurisdictionsComponent.propTypes = {
    
};

WCJurisdictionsComponent.defaultProps = {
    
};


export default WCJurisdictionsComponent;