import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';

import metadata from './YourInfoPage.metadata.json5';
import './YourInfoPage.messages';

function YourInfoPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { onValidate, isComponentValid, initialValidation } = useValidation('YourInfoPage');

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.updateDraftSubmissionAndPrimaryLocation(
            submissionVM.value
        );
        return submissionVM;
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        addresslookup: {
            // Use custom onValueChange handler to avoid state comparison issue
            // in AddressLookup component due to the use of _.set() in Jutro onFieldValueChange,
            onValueChange: writeValue
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourInfoPage.propTypes = wizardProps;
export default YourInfoPage;
