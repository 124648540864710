import React, { useMemo, useEffect } from 'react';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import config from 'app-config';
import metadata from './PersonalAutoDetails.metadata.json5';
import { getAvailableYears, isAutoFieldVisible } from '../../util/Underlying.util';

const { oldestCarYear } = config.personalAutoConfig;
function PersonalAutoDetails(props) {
    const {
        vm,
        onValueChange,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance,
        index,
        coveredItem
    } = props;

    const {
        onValidate: onValidationChange, 
        isComponentValid
    } = useValidation(`${coveredItem}DetailFields${index}`);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, `${coveredItem}DetailFields${index}`);
        }
        return () => {
            if (onValidate) {
                onValidate(true, `${coveredItem}DetailFields${index}`);
            }
        }
    }, [onValidate, isComponentValid])

    const getVisibleField = (fieldPath) => {
        return isAutoFieldVisible(vm, fieldPath);
    };

    const writeValue = (value, path) => {
        onValueChange(value, path, { index, coveredItem })
        //POLI-100687
        if(vm.vehicleType.value.code === 'privatepassengerauto_Ext'){
            //Set BI Symbol to 'KK' for PPA
            _.set(vm, 'value.bodilyInjurySymbol', 'KK')
        }
        else{
            //Set BI Symbol to null for Other vehicle type.
            _.set(vm, 'value.bodilyInjurySymbol', null)
        }
    };
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        vin: {
            visible: getVisibleField('vin')
        },
        year: {
            availableValues: getAvailableYears(oldestCarYear),
            visible: getVisibleField('year')
        },
        make: {
            visible: getVisibleField('make')
        },
        model: {
            visible: getVisibleField('model')
        },
        // hasVehicleBeenModified: {
        //     visible: getVisibleField('hasVehicleBeenModified')
        // },
        vehExclusiveUseToFarmOwned: {
            visible: getVisibleField('vehExclusiveUseToFarmOwned')
        },
        vehicleUsedInBusinessOwned: {
            visible: getVisibleField('vehicleUsedInBusinessOwned')
        },
        vehicleUsedInBusiOwnedExplain: {
            visible: getVisibleField('vehicleUsedInBusiOwnedExplain')
        },
        motorHomeRented: {
            visible: getVisibleField('motorHomeRented')
        },
        milePercentPerYear: {
            visible: getVisibleField('milePercentPerYear')
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);
    _.set(newMetadata, 'id', `${coveredItem}DetailFields${index}`);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
           
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            newMetadata, vm, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={newMetadata}
            model={vm}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidationChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default PersonalAutoDetails;
