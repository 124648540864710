import { defineMessages } from 'react-intl';

export default defineMessages({
    addLocation: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.Add Location',
        defaultMessage: 'Add Location'
    },
    selectState: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.-- Choose State --',
        defaultMessage: '-- Choose State --'
    },
    locationDetails: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.Location Details',
        defaultMessage: 'Location Details'
    },
    locationCode: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.Location Code',
        defaultMessage: 'Location Code'
    },
    phoneNumber: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.Phone Number',
        defaultMessage: 'Phone Number'
    },
    fireProtection: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.Fire Protection',
        defaultMessage: 'Fire Protection'
    },
    selectFireProtection: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.-- Fire Protection --',
        defaultMessage: '-- Fire Protection --'
    },
    back: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.location.location.Back',
        defaultMessage: 'Back'
    }
});
