import { AOConfig } from 'wni-portals-config-js';

const { filterStatesByCode } = AOConfig;

export default [
    {
        domainUrl: 'wnins.com',
        domainDesc: 'wni',
        domainLogoClass: 'wni-logo',
        domainName: 'Western National',
        favicon: './wni-images/branding/wni/favicon.ico',
        name: 'Western National',
        code: 'WNI',
        availableStates: filterStatesByCode([
            {
                code: 'SD',
                name: 'South Dakota'
            },
            {
                code: 'WI',
                name: 'Wisconsin'
            },
            {
                code: 'MN',
                name: 'Minnesota'
            },
            {
                code: 'IA',
                name: 'Iowa'
            }
        ])
    },
    {
        domainUrl: 'umialik.com',
        domainDesc: 'uic',
        domainLogoClass: 'uic-logo',
        domainName: 'Umialik Insurance',
        favicon: './wni-images/branding/uic/UIC_Favicon.svg',
        name: 'Umialik Insurance',
        code: 'UIC',
        availableStates: filterStatesByCode([
            {
                code: 'AK',
                name: 'Alaska'
            }
        ])
    },
    {
        domainUrl: 'mimillers.com',
        domainDesc: 'mm',
        domainLogoClass: 'mm-logo',
        domainName: 'Michigan Millers',
        favicon: './wni-images/branding/wni/favicon.ico',
        name: 'Michigan Millers',
        code: 'MM',
        availableStates: filterStatesByCode([
            {
                code: 'SD',
                name: 'South Dakota'
            },
            {
                code: 'WI',
                name: 'Wisconsin'
            },
            {
                code: 'MN',
                name: 'Minnesota'
            },
            {
                code: 'IA',
                name: 'Iowa'
            }
        ])
    },
    {
        domainUrl: 'wnins.com',
        domainDesc: 'wni',
        domainLogoClass: 'wni-logo',
        domainName: 'Western National',
        favicon: './wni-images/branding/wni/favicon.ico',
        tabTitle: 'AgentsOnline',
        name: 'Pioneer',
        code: 'PSI',
        availableStates: filterStatesByCode([
            {
                code: 'SD',
                name: 'South Dakota'
            },
            {
                code: 'WI',
                name: 'Wisconsin'
            },
            {
                code: 'MN',
                name: 'Minnesota'
            },
            {
                code: 'IA',
                name: 'Iowa'
            }
        ])
    }
];
