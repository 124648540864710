import { defineMessages } from 'react-intl';

export default defineMessages({
    cancelLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-buttons v2.Cancel',
        defaultMessage: 'Cancel',
    },
    saveAndColose: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-buttons v2.Save & Close',
        defaultMessage: 'Save & Close',
    }
});
