import appConfig from 'app-config';
import _ from 'lodash';

const CONFIG_KEY_NUM_OF_RECENTLY_ISSUED_POLICIES = '{{NUM_OF_RECENTLY_ISSUED_POLICIES}}';

const DEFUALT_NUM_OF_RECENTLY_ISSUED_POLICIES = 120;

//
// see config.json5
const {
    PerformanceConfig: {
        numOfRecentlyIssuedPolicies,
    },
} = appConfig;

//
let numOfRecentIP = DEFUALT_NUM_OF_RECENTLY_ISSUED_POLICIES;
if (!_.isEmpty(numOfRecentlyIssuedPolicies)) {
    try {
        numOfRecentIP = parseInt(numOfRecentlyIssuedPolicies);
        if (isNaN(numOfRecentIP)) {
            numOfRecentIP = DEFUALT_NUM_OF_RECENTLY_ISSUED_POLICIES;
        }
    } catch (e) {
        // Ignore any parse error
    }
}



export default {
    get NumOfRecentlyIssuedPolicies() { return numOfRecentIP; }
};
