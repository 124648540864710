import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import styles from './BaseMobileWizardSteps.module.scss';

function renderSteps(steps, currentStepIndex) {
    return steps.map((step, index) => {
        const liClassName = classNames(styles.step, {
            [styles.visited]: index < currentStepIndex,
            [styles.active]: index === currentStepIndex,
            [styles.notVisited]: !step.visited
        });
        return <li className={liClassName} key={step.id} />;
    });
}

function BaseMobileWizardSteps(props) {
    const wizardContext = useContext(WizardContext);
    const { toggleSideBar } = props;
    const { steps, currentStepIndex } = wizardContext;

    return (
        <button type="button" className={styles.navigationButton} onClick={toggleSideBar}>
            <ul className={styles.stepContainer} role="navigation">
                {renderSteps(steps, currentStepIndex)}
            </ul>
        </button>
    );
}

BaseMobileWizardSteps.propTypes = {
    toggleSideBar: PropTypes.func.isRequired
};
export default BaseMobileWizardSteps;
