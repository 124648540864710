
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import { NumberValue } from '@jutro/components';
import metadata from './CPBlanketSummaryComponent.metadata.json5';
import styles from './CPBlanketSummaryComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function CPBlanketSummaryComponent(props) {
    const {
        model: dtoVM = {},
        onValueChange = _.noop,
        showErrors = false,
        //
        blankets,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);


    useEffect(() => {
    }, []);


    const writeValue = (value, path) => {
        onValueChange(value, path);
    };

    const renderNumberValueCell = (item, index, property) => {
        const path = _.get(property, 'path');
        const value = _.get(item, path);
        return (
            <NumberValue
                id={`${path}${index}`}
                value={value}
                readOnly
                hideLabel
            />
        );
    }

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        blanketsTable: {
            data: blankets,
            onRowClick: _.noop,
            onSelectionChange: _.noop, // (rows) => updateSelection(rows),
            selectionType: 'none', // readOnly ? 'none' : 'multi',
            // selectedRows: selection,
        },
        limit: {
            renderCell: renderNumberValueCell
        },
        coinsuranceValue: {
            renderCell: renderNumberValueCell
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            dtoVM,
            id,
            path,
            overrideProps
        );
    };
    //---------
    // return (
    //     <MetadataContent
    //             uiProps={metadata.componentContent}
    //             overrideProps={overrides}
    //             {...resolvers} />
    // );
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dtoVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
}

CPBlanketSummaryComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
};

CPBlanketSummaryComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
};

export default CPBlanketSummaryComponent;
