import {
    useEffect
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';


function ExternalRCTRouting(props) {
    const { match, history } = props;

    useEffect(() => {
        const { lobQuoteURL } = appConfig;
        const { params: { postalCode, productCode, jobID, policyNumber, transaction } } = match;
        if (transaction === PortalConstants.TRANSACTIONTYPE_POLICYCHANGE) {
            const { lobEndorsementURL, endorsementProducts } = appConfig;
            if (endorsementProducts.includes(productCode)) {
                history.push(lobEndorsementURL[productCode], {
                    RCTCallback: 'residence',
                    policyNumber: policyNumber,
                    jobNumber: jobID
                });
            } else {
                JobUtil.openJobInXCenter(jobID);
            }
        } else {
            const state = {
                RCTCallback: 'residence',
                quoteentry: {
                    postalCode: postalCode,
                    quoteID: jobID,
                }
            };
            const toURL = lobQuoteURL[productCode] || '/';
            history.push(toURL, state);
        }
    }, [history, match]);

    return null;
}

ExternalRCTRouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withRouter(ExternalRCTRouting);
