import { DropdownMenuButton, useModal } from '@jutro/components';
import { DropdownMenuLink } from '@jutro/router';
import { PhoneNumberField } from '@jutro/legacy/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm,ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React,{ useContext, useState, useEffect, useCallback } from 'react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { TableRowUtil } from 'wnice-portals-util-react';
import { getFullAddressDisplayName } from '../../utils/AddressUtil';
import { DRIVER_PATH, DRIVER_MAP_PATH, PASSENGER_PATH, PASSENGER_MAP_PATH } from '../../config/vehicles.static';
import metadata from './InjuryContact.metadata.json5';
import ContactDetails from './InjuryContactDetails';
import messages from '../PolicyVehicle/PolicyVehicle.messages';


const CONTACT_DTO = 'wni.edge.capabilities.claim.contact.dto.WniContactDTO';
const xCenter = 'cc';

function getDriverData (driverVM, driverMapList, selectedDriverId){
    let driver = _.get(driverVM, 'value')
    const res = []
    if(selectedDriverId && selectedDriverId.length > 0 && driverMapList.length > 0){
        const selectDriver = driverMapList.find(d => d.rowIdPath === selectedDriverId[0])
        if(selectDriver){
            driver = selectDriver
        }
    }
    if(driver){
        res[0] = {
            ...driver,
            isDriver: true
        }
    }
    return res
}

function Contact(props) {
    const modalApi = useModal();
    const {
        id: pageId,
        model: vehicleModel,
        basePath,
        dataPath,
        contactRole,
        tableData = [],
        title,
        contactTitle,
        buttonText,
        onValidate = _.noop,
        writeValue = _.noop,
        tableConfig = {},
        selection,
        onSelectionChange,
        showPerson,
        incidentType,
        updateCurrentRow,
        selectedDriverId
    } = props;

    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const [contactVM, updateContactVM] = useState(null);
    const passengersVM = _.get(vehicleModel, 'passengers');
    const passengersMapVM = _.get(vehicleModel, 'passengerMap');
    const driverVM = _.get(vehicleModel, 'driver');
    const driverMapVM = _.get(vehicleModel, 'driverMap');
    const passengersList = _.get(passengersVM, 'value', []);
    const passengersMapList = _.get(passengersMapVM, 'value', []);
    const driverMapList = _.get(driverMapVM, 'value', [])
    const driverList = getDriverData(driverVM, driverMapList, selectedDriverId)
    const allData = _.uniqBy(driverList.concat(passengersMapList), 'rowIdPath');
    const allTableData = _.filter(allData, {isInjury: true});

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        TableRowUtil.setTablePublicIDSelected(activePublicID, pageId);
    };

    useEffect(() => {
        highlightRowFn(contactVM);
    }, [contactVM]);

    const handleSelectionChange = useCallback((ids) => {
        onSelectionChange(ids, basePath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const addContact = () => {
        const initData = {
            rowIdPath: `cc:${TableRowUtil.getUuid()}`,
            primaryAddress: { 
                country: 'US'
            },
            subtype: "Person",
            isSave: true
        }
        const initVM = viewModelService.create(initData, xCenter, CONTACT_DTO);
        updateContactVM(initVM);
    };
    const editContact = (item) => {
        const initData = {
            ...item,
            primaryAddress: item.primaryAddress || { country: 'US' },
            isSave: true
        }
        const initVM = viewModelService.create(initData, xCenter, CONTACT_DTO);
        updateContactVM(initVM);
    };

    const generateVM = (vm) => {
        const newVM = viewModelService.clone(vm);
        const contactAddress = _.get(newVM.value, 'primaryAddress', {});
        const newContactAddress = _.omit(contactAddress, 'country'); // country not be checked
        const isAddressEmpty = _.every(newContactAddress, _.isEmpty);
        if(isAddressEmpty) {
            _.set(newVM.value, 'primaryAddress', null);
        }

        return newVM
    };

    const removeContact = (item) => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline',
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return false;
            }
           
            setLoadingMask(true);
            // if(item.publicID) {
            //     const res = await VehicleService.removeContact(
            //         claimNumber,
            //         item.publicID,
            //         contactRole,
            //         authHeader
            //     );
            //     syncVehicleData(res);
            // }
            setLoadingMask(false);

            if(item.isDriver){
                const currentVM = driverVM;
                _.set(currentVM.value, 'isInjury', false);
                const rowIdPath = _.get(currentVM.value, 'rowIdPath');
                const newModel = viewModelService.clone(vehicleModel);
                _.set(newModel.value, DRIVER_PATH, currentVM.value);
                const currentIndex = driverMapList.findIndex((driver) => driver.rowIdPath === rowIdPath)
                _.set(newModel.value, `${DRIVER_MAP_PATH}[${currentIndex}]`, currentVM.value);
                updateCurrentRow(newModel)
                return true
            }
            
            onSelectionChange(selection.filter(rowIdPath => rowIdPath !== item.rowIdPath), PASSENGER_PATH)
            const newData = passengersMapList.map((contact) => {
                if(contact.rowIdPath === item.rowIdPath){
                    return {
                        ...contact,
                        isInjury: false
                    }
                }
                return contact
            })
            writeValue(newData, PASSENGER_MAP_PATH);
            return true;
        }, _.noop);
    };

    const renderPhoneCell = (item) => {
        const phoneNumber = item.workNumber || item.homeNumber || item.cellNumber;
        return <PhoneNumberField value={phoneNumber} readOnly />
    };

    const renderActionCell = (item, index) => {
        const dom = (
            <DropdownMenuButton
                icon="gw-expand-more"
                id="dropdownMenuButton"
                className="dropDownMenuIconbtn"
                menuClassName="dropDownMenuList"
                alignRight
            >
                <DropdownMenuLink icon="gw-edit" onClick={() => editContact(item)}>Edit</DropdownMenuLink>
                <DropdownMenuLink icon="gw-delete" onClick={() => removeContact(item)}>Remove</DropdownMenuLink>
            </DropdownMenuButton>
        );
        return TableRowUtil.renderCell(item.rowIdPath, dom);
    };


    const onValueChange = (value, path) => {
        if(contactVM) {
            const newVM = viewModelService.clone(contactVM);
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(newVM.value, path, undefined);
            } else {
                _.set(newVM.value, path, value);
            }
            updateContactVM(newVM);
        }
    };

    const getPersonAvailablePersons = () => {
        const contacts = _.filter(allData, contact => !selection.includes(contact.rowIdPath) && !contact.isInjury)
        const availablePersons = _.map(contacts, (person) => {
            return {
                code: person.rowIdPath,
                name: person.displayName || `${_.get(person, 'firstName', '')} ${_.get(person, 'lastName', '')}`
            }
        })
        return availablePersons.concat({code: 'newPerson', name: 'New Person'})
    }

    const handlePersonChange = (contactId) => {
        const currentContact = allData.find((item) => item.rowIdPath === contactId);

        const initData = {
            ...currentContact,
            rowIdPath: currentContact?.rowIdPath || `cc:${TableRowUtil.getUuid()}`,
            subtype: currentContact?.subtype || "Person",
            primaryAddress: currentContact?.primaryAddress || { country: 'US' },
            isSave: true,

        }
        const initVM = viewModelService.create(initData, xCenter, CONTACT_DTO);
        updateContactVM(initVM);
    }

    const renderAddressCell = (item) => {
        const primaryAddress = _.get(item, 'primaryAddress');
        if (_.isEmpty(primaryAddress)) {
            return '-';
        }
        return getFullAddressDisplayName(_.get(item, 'primaryAddress'));
    }

    const save = () => {
        const currentVM = generateVM(contactVM);
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const findCurrentContact = allData.find((item) => item.rowIdPath === rowIdPath)
        _.set(currentVM.value, 'isInjury', true);

        if(findCurrentContact) { // edit existing contact
            if(findCurrentContact.isDriver){
                const newModel = viewModelService.clone(vehicleModel);
                _.set(newModel.value, DRIVER_PATH, currentVM.value);
                const driverIndex = driverMapList.findIndex((item) => item.rowIdPath === rowIdPath)
                _.set(newModel.value, `${DRIVER_MAP_PATH}[${driverIndex}]`, currentVM.value);
                updateCurrentRow(newModel)
            }else{
                const newModel = viewModelService.clone(vehicleModel);
                const passengerIndex = passengersList.findIndex((item) => item.rowIdPath === rowIdPath);
                if(passengerIndex !== -1){
                    _.set(newModel.value, `${PASSENGER_PATH}[${passengerIndex}]`, currentVM.value);
                }
                const passengerMapIndex = passengersMapList.findIndex((item) => item.rowIdPath === rowIdPath);
                _.set(newModel.value, `${PASSENGER_MAP_PATH}[${passengerMapIndex}]`, currentVM.value);
                updateCurrentRow(newModel)
                onSelectionChange(selection.concat([rowIdPath]), PASSENGER_PATH);
            }
            updateContactVM(null);
            return false;
        }
        // add new contact
        passengersMapVM.pushElement(currentVM);
        writeValue(passengersMapVM.value, PASSENGER_MAP_PATH);
        onSelectionChange(selection.concat([rowIdPath]), PASSENGER_MAP_PATH);
        
        updateContactVM(null);
    };

    const cancel = () => {
        updateContactVM(null);
    };

    const overrides = {
        contactTitleText:{
            content: translator(title)
        },
        contactTable: {
            id: pageId,
            data: allTableData,
            selectedRows: selection,
            onSelectionChange: handleSelectionChange,
            ...tableConfig
        },
        addButton: {
            content: buttonText,
            disabled: contactVM
        },
        contactDetailsContainer: {
            visible: !!contactVM
        },
        contactDetails: {
            contactVM: contactVM,
            writeValue: onValueChange,
            save,
            cancel,
            showPerson,
            availablePersons: getPersonAvailablePersons(),
            handlePersonChange,
            contactTitle,
            incidentType
        }
    };
    const resolvers = {
        resolveComponentMap: {
            contactdetails: ContactDetails
        },
        resolveCallbackMap: {
            renderPhoneCell,
            renderActionCell,
            addContact,
            renderAddressCell
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            tableData,
            id,
            path,
            overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={tableData}
            overrideProps={overrides}
            onValueChange={onValueChange}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default Contact;
