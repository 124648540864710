import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Parser from 'rss-parser';
import moment from 'moment';

import { getProxiedUrl } from '@xengage/gw-portals-url-js';

import styles from './RssDirective.module.scss';

export default class RssDirective extends Component {
    static propTypes = {
        id: PropTypes.string,
        limit: PropTypes.number,
        feed: PropTypes.string.isRequired
    };

    static defaultProps = {
        limit: Infinity,
        id: undefined
    };

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.state = {
            fetchError: false,
            entries: []
        };
    }

    componentDidMount() {
        const { feed } = this.props;
        const feedUrl = feed.startsWith('http') ? feed : getProxiedUrl(feed);
        fetch(feedUrl)
            .then((resp) => {
                if (!resp.ok) {
                    // eslint-disable-next-line no-console
                    console.error(`${resp.status} ${resp.url}`);
                    return Promise.reject();
                }
                return resp.text();
            })
            .then((text) => this.parser.parseString(text))
            .then((parsedText) => {
                const { items = [] } = parsedText;
                this.setState({ entries: items });
            })
            .catch(() => {
                this.setState({ fetchError: true });
            });
    }

    render() {
        const { limit, id } = this.props;
        const { entries, fetchError } = this.state;

        // a proxy error due to the feed RSS is randomly appearing so we add
        // this check which will prevent rendering the component in case this happen
        if (fetchError) {
            return null;
        }

        return (
            <div id={id}>
                {_.take(entries, limit).map(({ title, link, isoDate }) => {
                    return (
                        <div className={styles.rssStyle} key={title}>
                            <a rel="noopener noreferrer" target="_blank" href={link}>
                                {title}
                            </a>
                            <div className={styles.muted}>
                                {moment(isoDate).format('LL')}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
