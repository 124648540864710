import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    ViewModelForm,
    ViewModelServiceContext
} from '@xengage/gw-portals-viewmodel-react';
import { WniInputText } from 'wni-common-base-components';
import { ConfigUtil } from 'wni-portals-util-js';
import metadata from './BaseActionWithEditTableComponent.metadata.json5';
import styles from './BaseActionWithEditTableComponent.module.scss';

const BaseActionWithEditTableComponent = (props) => {
    const {
        dtoVM,
        infoLabel,
        addBtnLabel,
        removeBtnLabel,
        showErrors,
        initialData,
        updateVM,
        onValidate,
        requiredVM,
        prefix,
        viewModelService
    } = props;

    const [rowsSelected, updateRowsSelected] = useState([]);
    const [initialized, updateInitialized] = useState(false);
    // const viewModelService = useContext(ViewModelServiceContext);
    const [showLoader, updateShowLoader] = useState(false);
    const tableData = _.isEmpty(dtoVM) ? [] : dtoVM;
    const [requiredFields, updateRequiredFields] = useState([]);

    useEffect(() => {
        if (!initialized) {
            updateInitialized(true);
            initialData.forEach((item, index) => {
                _.set(item, 'rowIdPath', ConfigUtil.getUuid()+index);
            });
            updateVM(initialData);
        }
    }, [initialData, initialized, updateVM]);

    const updateColomnData = useCallback((targetID, value, path, index) => {
        const newTableData = _.clone(tableData);
        const changedData = _.find(newTableData, (item) => {
            return _.get(item, 'id') === targetID;
        });
        _.set(changedData, path, value);
        updateVM(tableData);
    }, [tableData, updateVM]);

    const getInputCellRenderFn = useCallback((item, index, property) => {
        const path = _.get(property, 'path');
        const id = prefix + path + _.get(item, 'id');
        const value = _.get(item, `${path}`);

        // set the value to get the validation
        const newVM = viewModelService.clone(requiredVM);
        _.set(newVM, path, value);

        const isFieldRequired = _.get(newVM, `${path}.aspects.required`);
        if (isFieldRequired) {
            if (!requiredFields.includes(path)) {
                requiredFields.push(path);
                updateRequiredFields(requiredFields);
            }
        }
        const inputProps = {
            path,
            value,
            onValueChange: (changedValue, changedPath) => updateColomnData(
                _.get(item, 'id'), changedValue, changedPath, index
            ),
            onValidationChange: (isValid) => onValidate(isValid, id),
            type: 'field',
            labelPosition: 'left',
            required: isFieldRequired,
            showErrors: showErrors,
            validationMessages: _.get(newVM, `${path}.aspects.validationMessages`)
        };
        return <WniInputText id={id} {...inputProps} />;
    }, [onValidate, requiredVM, showErrors, updateColomnData, viewModelService, prefix]);

    const getEmailColomnHeader = useCallback(() => {
        return (
            <div>
                <span>Email Address</span>
                <span className={styles.colorRed}> *</span>
            </div>
        );
    }, []);

    const sortData = useCallback((array, path) => {
        let index = 1;
        _.forEach(array, (item) => {
            _.set(item, `${path}`, `${index}`);
            index += 1;
        });
        return array;
    }, []);

    const handleAdd = useCallback(() => {
        let newData = _.clone(tableData);
        newData.push({ rowIdPath: ConfigUtil.getUuid() });
        // re-sort the data id
        newData = sortData(newData, 'id');
        updateVM(newData);
    }, [sortData, tableData, updateVM]);

    const updateValidation = useCallback((id) => {
        _.each(requiredFields, (field) => {
            const removeValidateField = `${prefix}${field}${id}`;
            // update the outside invalidFields property
            onValidate(true, removeValidateField);
        });
    }, [onValidate, prefix, requiredFields]);

    const handleRemove = useCallback(() => {
        updateShowLoader(true);
        let newData = _.clone(tableData);
        newData = _.filter(newData, (item) => {
            const rs = _.find(rowsSelected, (idx) => idx === item.rowIdPath);
            if (rs) {
                // update validation to true when remove
                updateValidation(_.get(item, 'id'));
                return false;
            }
            // find the rows which are not selected
            return true;
        });
        // re-sort the data id
        newData = sortData(newData, 'id');
        updateVM(newData);
        // reset selected rows
        updateRowsSelected([]);
    }, [tableData, sortData, updateVM, rowsSelected, updateValidation]);

    useEffect(() => {
        updateShowLoader(false);
    }, [tableData]);
    const overrideProps = {
        '@all': {},
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        infoLabel: {
            content: infoLabel
        },
        addBtn: {
            content: addBtnLabel
        },
        removeBtn: {
            content: removeBtnLabel
        },
        tableLoader: {
            loaded: !showLoader
        },
        infoDatatable: {
            data: tableData,
            onSelectionChange: (rows) => updateRowsSelected(rows),
            rowIdPath: 'rowIdPath'
        },
        nameColomn: {
            renderCell: (item, index, property) => {
                return getInputCellRenderFn(item, index, property);
            }
        },
        emailColomn: {
            renderCell: (item, index, property) => {
                return getInputCellRenderFn(item, index, property);
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getEmailColomnHeader,
            handleAdd,
            handleRemove
        },
        resolveComponentMap: {},
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={tableData}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            styles={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
};

BaseActionWithEditTableComponent.propTypes = {
    dtoVM: PropTypes.arrayOf(PropTypes.shape({})),
    infoLabel: PropTypes.string,
    addBtnLabel: PropTypes.string,
    removeBtnLabel: PropTypes.string,
    showErrors: PropTypes.bool,
    initialData: PropTypes.arrayOf(PropTypes.shape({})),
    updateVM: PropTypes.func,
    onValidate: PropTypes.func,
    requiredVM: PropTypes.shape({}),
    prefix: PropTypes.string
};

BaseActionWithEditTableComponent.defaultProps = {
    dtoVM: [],
    infoLabel: '',
    addBtnLabel: 'Add',
    removeBtnLabel: 'Remove',
    showErrors: false,
    initialData: [],
    updateVM: _.noop,
    onValidate: _.noop,
    requiredVM: {},
    prefix: ''
};

export default BaseActionWithEditTableComponent;
