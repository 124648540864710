import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import GLDummyPage from './pages/Dummy/GLDummyPage';
import GLDummyReadOnlyPage from './pages/Dummy/GLDummyPage.readonly';

import GLQualificationPage  from './pages/Qualification/GLQualificationPage';
import GLQualificationReadOnlyPage from './pages/Qualification/GLQualificationPage.readonly';

import GLPolicyDetailsPage  from './pages/PolicyDetails/GLPolicyDetailsPage';
import GLPolicyDetailsReadOnlyPage from './pages/PolicyDetails/GLPolicyDetailsPage.readonly';

import GLSublineSelectionPage from './pages/SublineSelection/GLSublineSelectionPage';
import GLSublineSelectionReadOnlyPage from './pages/SublineSelection/GLSublineSelectionPage.readonly';

import GLGeneralLiabilityPage from './pages/GeneralLiability/GLGeneralLiabilityPage';
import GLGeneralLiabilityReadOnlyPage from './pages/GeneralLiability/GLGeneralLiabilityPageReadOnly';

import GLStateSpecificInformationPage from './pages/StateSpecificInformation/GLStateSpecificInformationPage';
import GLStateSpecificInformationReadOnlyPage from './pages/StateSpecificInformation/GLStateSpecificInformationPage.readonly';

import GLLocationsPage from './pages/Locations/GLLocationsPage';
import GLLocationsReadOnlyPage from './pages/Locations/GLLocationsPage.readonly';

import GLModifiersPage from './pages/Modifiers/GLModifiersPage';
import GLModifiersReadOnlyPage from './pages/Modifiers/GLModifiersPage.readonly';

import GLRiskAnalysisPage from './pages/RiskAnalysis/GLRiskAnalysisPage';
import GLRiskAnalysisReadOnlyPage from './pages/RiskAnalysis/GLRiskAnalysisPage.readonly';

import GLExposuresPage from './pages/Exposure/GLExposuresPage';
import GLExposuresReadOnlyPage from './pages/Exposure/GLExposuresPage.readonly';

import GLUnmannedAircraftPage from './pages/UnmannedAircraft/GLUnmannedAircraftPage';
import GLUnmannedAircraftReadOnlyPage from './pages/UnmannedAircraft/GLUnmannedAircraftPage.readonly';

import GLUnderwritingQuestionsPage from './pages/UnderwritingQuestions/GLUnderwritingQuestionsPage';
import GLUnderwritingQuestionsReadOnlyPage from './pages/UnderwritingQuestions/GLUnderwritingQuestionsPage.readonly';

import GLQuotePage  from './pages/Quote/GLQuotePage';
import GLQuoteReadOnlyPage from './pages/Quote/GLQuotePage.readonly';


setComponentMapOverrides(
    {
        GLDummyPage: {component: 'GLDummyPage'},
        GLDummyReadOnlyPage: { component: 'GLDummyReadOnlyPage' },

        //
        GLQualificationPage: {component: 'GLQualificationPage'},
        GLQualificationReadOnlyPage: {component: 'GLQualificationReadOnlyPage'},

        GLPolicyDetailsPage: {component: 'GLPolicyDetailsPage'},
        GLPolicyDetailsReadOnlyPage: {component: 'GLPolicyDetailsReadOnlyPage'},

        GLSublineSelectionPage: { component: "GLSublineSelectionPage"},
        GLSublineSelectionReadOnlyPage: { component: "GLSublineSelectionReadOnlyPage"},

        GLGeneralLiabilityPage: {component: 'GLGeneralLiabilityPage'},
        GLGeneralLiabilityReadOnlyPage: {component: 'GLGeneralLiabilityReadOnlyPage'},

        GLStateSpecificInformationPage: {component: 'GLStateSpecificInformationPage'},
        GLStateSpecificInformationReadOnlyPage: {component: 'GLStateSpecificInformationReadOnlyPage'},

        GLLocationsPage: { component: "GLLocationsPage"},
        GLLocationsReadOnlyPage: { component: "GLLocationsReadOnlyPage"},

        GLModifiersPage: {component: 'GLModifiersPage'},
        GLModifiersReadOnlyPage: { component: 'GLModifiersReadOnlyPage' },

        GLRiskAnalysisPage: {component: 'GLRiskAnalysisPage'},
        GLRiskAnalysisReadOnlyPage: {component: 'GLRiskAnalysisReadOnlyPage'},

        GLExposuresPage: { component: 'GLExposuresPage'},
        GLExposuresReadOnlyPage: { component: 'GLExposuresReadOnlyPage'},

        GLUnmannedAircraftPage: { component: 'GLUnmannedAircraftPage'},
        GLUnmannedAircraftReadOnlyPage: { component: 'GLUnmannedAircraftReadOnlyPage'},

        GLUnderwritingQuestionsPage: {component: 'GLUnderwritingQuestionsPage'},
        GLUnderwritingQuestionsReadOnlyPage: {component: 'GLUnderwritingQuestionsReadOnlyPage'},

        //
        GLQuotePage: {component: 'GLQuotePage'},
        GLQuoteReadOnlyPage: {component: 'GLQuoteReadOnlyPage'},

    },
    {
        GLDummyPage,
        GLDummyReadOnlyPage,

        GLQualificationPage,
        GLQualificationReadOnlyPage,

        GLPolicyDetailsPage,
        GLPolicyDetailsReadOnlyPage,

        GLSublineSelectionPage,
        GLSublineSelectionReadOnlyPage,

        GLGeneralLiabilityPage,
        GLGeneralLiabilityReadOnlyPage,

        GLStateSpecificInformationPage,
        GLStateSpecificInformationReadOnlyPage,

        GLLocationsPage,
        GLLocationsReadOnlyPage,

        GLModifiersPage,
        GLModifiersReadOnlyPage,

        GLRiskAnalysisPage,
        GLRiskAnalysisReadOnlyPage,

        GLExposuresPage,
        GLExposuresReadOnlyPage,

        GLUnmannedAircraftPage,
        GLUnmannedAircraftReadOnlyPage,

        GLUnderwritingQuestionsPage,
        GLUnderwritingQuestionsReadOnlyPage,
        //
        GLQuotePage,
        GLQuoteReadOnlyPage,
    }
);


export {
    GLDummyPage,
    GLDummyReadOnlyPage,
    GLQualificationPage,
    GLQualificationReadOnlyPage,
    GLPolicyDetailsPage,
    GLPolicyDetailsReadOnlyPage,
    GLSublineSelectionPage,
    GLSublineSelectionReadOnlyPage,
    GLGeneralLiabilityPage,
    GLGeneralLiabilityReadOnlyPage,
    GLStateSpecificInformationPage,
    GLStateSpecificInformationReadOnlyPage,
    GLLocationsPage,
    GLLocationsReadOnlyPage,
    GLModifiersPage,
    GLModifiersReadOnlyPage,
    GLRiskAnalysisPage,
    GLRiskAnalysisReadOnlyPage,
    GLExposuresPage,
    GLExposuresReadOnlyPage,
    GLUnmannedAircraftPage,
    GLUnmannedAircraftReadOnlyPage,
    GLUnderwritingQuestionsPage,
    GLUnderwritingQuestionsReadOnlyPage
    //
};

 
export { default as GLSubmissionWizard } from './GLSubmissionWizard';
export { default as GLWizardPage } from './templates/GLWizardPage';

export { default as GLQuoteIssueRenderHelper } from './pages/Quote/util/GLQuoteIssueRenderHelper';
export { default as GLQuotePageConstants } from './pages/Quote/config/GLQuotePage.config';

export { default as GLPolicyLineSummaryComponent } from './pages/Quote/components/Summary/GLPolicyLineSummaryComponent';