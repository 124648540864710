import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';

import styles from './BopQuoteInfo.module.scss';
import messages from './BopQuoteInfo.messages';

const PATH_TO_MOCK_DATA = 'quote.bop';

function BopQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useTranslator();
    const quoteId = _.get(submissionVM, 'quoteID.value');

    const isContactNameMocked = MockUpUtil.isValueMocked(
        _.get(submissionVM, 'value'),
        PATH_TO_MOCK_DATA,
        'baseData.accountHolder.contactName'
    );

    let companyName = _.get(submissionVM, 'baseData.accountHolder.contactName.value', '-');

    if (isContactNameMocked) {
        submissionVM.value = MockUpUtil.cleanUpMockedProperties(
            _.get(submissionVM, 'value'),
            PATH_TO_MOCK_DATA,
            'baseData.accountHolder.contactName'
        );
        companyName = '-';
    }

    const address = _.get(submissionVM, 'baseData.policyAddress.value');
    const { displayName } = address;

    const isAddressDisplayNameMocked = MockUpUtil.isDisplayNameMocked(
        _.get(submissionVM, 'value'),
        PATH_TO_MOCK_DATA,
        'baseData.policyAddress.displayName',
        'baseData.policyAddress.addressLine1',
        'baseData.policyAddress.city'
    );
    const policyAddressToShowInHeader = isAddressDisplayNameMocked
        ? `${address.state} ${address.postalCode}`
        : displayName;

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.quoteWrapper}>
                <div className={styles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.businessQuote)}
                    </div>
                    <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                        {quoteId}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.company)}
                    </div>
                    <div id="contextCompanyName" className={commonQuoteStyles.infoBlockContent}>
                        {companyName}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    {translator(messages.location)}
                    <div id="contextLocation" className={commonQuoteStyles.infoBlockContent}>
                        <Tooltip
                            id="policyLocation"
                            placement="bottom"
                            trigger="mouseenter"
                            content={policyAddressToShowInHeader}
                        >
                            <div className={commonQuoteStyles.infoOverflow}>{policyAddressToShowInHeader}</div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

BopQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }).isRequired
};

export default BopQuoteInfo;
