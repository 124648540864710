const sectionFieldsMap = {
    operatorMemberContactDetail: [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'maritalStatus',
        'primaryPhoneType',
        'workNumber',
        'homeNumber',
        'cellNumber',
        'suffix',
        'occupation',
        'relationship'
    ],
    operatorLicenseInfo: [
        'isLicensedDriver',
        'unLicensedDriverReason',
        'unlicensedDriverExplain',
        'licenseStatus',
        'licenseNumber',
        'licenseState',
        'ageFirstLicensed'
    ],
    operatorUnderwriting: [
    ]
};

export default {
    sectionFieldsMap,
};
