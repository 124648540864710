import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniVehicleInfoLookupService {
    static lookupMakes(year, vehicleType, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('wniVehicleInfoLookup'),
            'lookupMakes',
            [year, vehicleType],
            additionalHeaders
        );
    }

    static lookupModels(year, make, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('wniVehicleInfoLookup'),
            'lookupModels',
            [year, make],
            additionalHeaders
        );
    }

    static lookupBodyTypes(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('wniVehicleInfoLookup'),
            'lookupBodyTypes',
            [dto],
            additionalHeaders
        );
    }
    
    /**
     * Invokes the OOTB JSON-RPC method VehicleInfoLookupHandler.lookupBasedOnDTO()
     * @param {object} dto 
     * @param {object} additionalHeaders 
     * @returns {Promise}
     */
    static autofillBasedOnDto(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnDTO',
            [dto],
            additionalHeaders
        );
    }
}
