import React, {
    useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ServiceManager } from '@jutro/legacy/services';
import { useHistory } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {WizardContext } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { HORCTService } from 'wni-capability-quoteandbind-ho';
import { UrlUtil, useProductsData } from 'wni-portals-util-react';
import { ServiceErrorUtil, DocumentsUtil, WniProductsUtil } from 'wni-portals-util-js';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { JobUtil } from '@xengage/gw-portals-util-js';
import QuickLinkComponent from '../QuickLink/QuickLinkComponent';
import metadata from './WizardSidebarLink.metadata.json5';
import WizardSidebarExtensionComponent from '../WizardSidebarExtension/WizardSidebarExtensionComponent';
import messages from './WizardSideBarLink.messages';

const WizardSidebarLink = (props) => {
    const modalApi = useWniModal();
    const {
        sideLinkSlotRenderFunc,
        // transactionType,
    } = props;

    const history = useHistory();
    const wizardContext = useContext(WizardContext);
    const { authHeader } = useAuthentication();
    const {  domainCompany, loadingMask: { setLoadingMask } } = useDependencies(['domainCompany', 'loadingMask']);
    const { wizardData } = wizardContext;
    const { jobID,
        sessionUUID,
        baseData: {
            accountNumber,
            productCode,
            accountHolder,
            producerCode_Ext: producerCode,
            producerCodePublicID_Ext: producerCodePublicID,
            baseState_Ext: baseState
        },
        changeData
    } = wizardData.value;
    const { code: domainCode } = domainCompany;
    const quickLinksMap = WniProductsUtil.getQuickLink(productCode);

    const launchRCTURLFailPopup = () => {
        modalApi.showConfirm({
            title: messages.RCTWebsiteCallbackFail,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.ok
        }).then(
            async (res) => {
                if (res === 'confirm') {
                    await launchRCTURL();
                }
            }, _.noop
        );
    };
    const launchRCTURL = async () => {
        let transactionType='submission'
        let policyNumber
        if(!_.isEmpty(changeData)){
            transactionType='policychange'
            policyNumber=changeData.policyNumber
        }
        const postalCode = _.get(accountHolder, 'primaryAddress.postalCode');

        const defaultUrl = `${window.location.origin}/RCT/${transactionType}/${postalCode}/${productCode}/${jobID}`
        const callbackUrl = policyNumber ? `${defaultUrl}/${policyNumber}` : defaultUrl;
        setLoadingMask(true);
        const url = await HORCTService.launchRCTExpress(jobID, sessionUUID, callbackUrl, authHeader);
        if (_.isEmpty(url)) {
            launchRCTURLFailPopup();
        } else {
            window.location.href = url;
        }
        setLoadingMask(false);
    };

    const handleDownload = useCallback(async (value) => {
        if (_.isNil(value)) {
            return;
        }
        const successCallback = () => {
            setLoadingMask(false);
        };
        const errorCallback = () => {
            setLoadingMask(false);
            modalApi.showConfirm({
                title: 'Error',
                message: ServiceErrorUtil.prependWithFriendMessage(),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(() => {
                _.noop();
            }).catch(() => {
                _.noop();
            });
        };
        setLoadingMask(true);
        await DocumentsUtil.tryDownloadDocument(
            value, authHeader, history, WniDocumentRetrievalService, successCallback, errorCallback
        );
    }, [authHeader, history, setLoadingMask]);

    const sideLinkSlotContent = sideLinkSlotRenderFunc && sideLinkSlotRenderFunc({
        wizardData,
        handleDownload,
    });

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        '@action': {
            quickLinkData: quickLinksMap
        },
        '@all': {
            tabIndex: -1
        },
        personalLinesAdvisor: {
            onClick: () => {window.open(UrlUtil.personalLinesAdvisor[domainCode])}
        },
        sideLinkSlotContainer: {
            visible: !_.isEmpty(sideLinkSlotContent),
            content: sideLinkSlotContent,
        },
        replacementCostEstimator: {
            onClick: launchRCTURL
        },
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            wizardsidebarextension: WizardSidebarExtensionComponent,
            quicklinkcomponent: QuickLinkComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

WizardSidebarLink.propTypes = {
    sideLinkSlotRenderFunc: PropTypes.func,
    transactionType: PropTypes.string,
};

WizardSidebarLink.defaultProps = {
    // wizardData: {},
    sideLinkSlotRenderFunc: undefined,
    transactionType: 'submission',
};

// export default withRouter(WizardSidebarLink);
export default WizardSidebarLink;
