import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('crLocationService'), method, data, additionalHeaders);
}

export default class GLLocationService {
    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} location
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateLocation(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'updateLocation',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

    //   /**
    //  *
    //  * @param {string} quoteID
    //  * @param {string} sessionUUID
    //  * @param {Object} authHeader
    //  * @returns {Promise}
    //  */
    //   static fetchLocations(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission(
    //         'fetchLocations',
    //         [quoteID, sessionUUID],
    //         authHeader
    //     );
    // }

    /**
     *
     *  @param {string} quoteID 
     * @param {string} sessionUUID 
     *@param {Array} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeLocations(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeLocations',
            [quoteID, sessionUUID, publicIDs],
            authHeader
        );
    }

  
     static addExistingLocations(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission(
            'addExistingLocations',
            [quoteID, sessionUUID, publicIDs],
            authHeader
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader
        );
    }

}