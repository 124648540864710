import _ from 'lodash';
/**
 * QuestionSet filter for WC UnderwritingQuestions page
 *
 * See QuestionSetsParser.contentFilter
 * @param {String} policyState
 * @returns {Function}
 */
function getQSFilterForWCUWQuetions() {
    return (metadataContent) => {
        const UNDISPLAY_FIELDS_FOR_UWQS_VERSION2 =
        [
            'WCMAgeRange', 
            'WCMExplainForAgeRange_Ext', 
            'WCMAircraftWatercraft',
            'WCMAthleticSponser',
            'WCMEmployeeHeathPlans',
            'WCMGroundTransport',
            'WCMDisabledEmployees',
            'WCMOnOrOverWater',
            'WCMDoesThisClient_Ext',
            'WCMPhysicalsRequired',
            'WCMPriorDeclined',
            'WCMExplainForPriorDeclined_Ext',
            'WCMSafetyProgram',
            'WCMSeasonal',
            'WCMSubcontractors',
            'WCMSubcontractorsUsedYes_Ext',
            'WCMTaxLiens',
            'WCMExplainTaxLien_Ext',
            'WCMWorkAtHome',
            'WCMEmployeeAtHome_Ext',
            'WCMSublet',
            'WCMWorkSubletWithoutCertificatesYes_Ext',
            'WCMExplainForAboveOrBelowGround_Ext'
        ];

        return !UNDISPLAY_FIELDS_FOR_UWQS_VERSION2.includes(metadataContent.id)
            
    };
}

function IsValidField(value) {
    if (value === undefined || value === null || value === '') {
        return false;
    }
    return true;
}

/**
 * QuestionSet Mapper for WCUnderwritingQuestions
 *
 *
 * @returns {Function}
 */
function getQSMapperForWcUnderwritingQuestions(questionsetAnswers = {}, invalidMessages = []) {
    return (metaDataContent) => {
        switch (metaDataContent.id) {
            case 'WCMHazMat':
            case 'WCMAboveOrBelowGround':
            case 'WCMOtherBusinesses':
            case 'WCMVolunteer':
            case 'WCMOutOfStateTravel':
            case 'WCMLaborInterchange':
            case 'WCMUnpaidPremimum':
            case 'WCMLeaseEmployees':
                _.set(metaDataContent, 'component', 'WniToggle')
                _.set(metaDataContent,'componentProps.tooltip', true);
                _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
                _.set(metaDataContent, 'componentProps.tooltip.text', 'Acord 130')
                break;
            default:
                _.set(metaDataContent, 'componentProps.showRequired', true);
                _.set(metaDataContent, 'componentProps.required', true);
                if (!IsValidField(_.get(questionsetAnswers, metaDataContent.componentProps.path))) {
                    _.set(metaDataContent, 'componentProps.validationMessages', invalidMessages);
                } else {
                    _.set(metaDataContent, 'componentProps.validationMessages', []);
                }
                break;
        }    
        return metaDataContent;
    };
}
export default {
    getQSFilterForWCUWQuetions,
    getQSMapperForWcUnderwritingQuestions
};
