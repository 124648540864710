import { defineMessages } from 'react-intl';

export default defineMessages({
    toogleButtonShow: {
        id: 'quoteandbind.views.paymentList.table.button.show',
        defaultMessage: 'Show other pay plans (without Full Pay discount)',
    },
    feePerInstallment: {
        id: 'quoteandbind.views.paymentList.table.feePerInstallment',
        defaultMessage: 'Fee Per Installment',
    },
    schedule: {
        id: 'quoteandbind.views.paymentList.table.Schedule',
        defaultMessage: 'SCHEDULE',
    },
    dialogOk: {
        id: 'quoteandbind.views.paymentList.ViewSchedule.OK',
        defaultMessage: 'OK',
    },
});
