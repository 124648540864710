import { defineMessages } from 'react-intl';

/**
 * ref: common/ClauseComponentVM.messages.js
 */
export default defineMessages({
    areYouSure: {
        id: 'custom.cacoverages.bop.directives.areYouSure',
        defaultMessage: 'Are you sure you want to remove this coverage? Any coverage limits selected for this coverage will be lost.'
    },
});
