import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('puAddiService'), method, data, additionalHeaders);
}

export default class PUAddiService {

    /**
     * get additional interest type options
     * 
     * @param {String} quoteID 
     * @param {String} sessionUUID 
     * @param {String} publicID 
     * @param {Boolean} isPerson 
     * @param {Boolean} isCompany 
     * @param {Boolean} isBank 
     * @param {Boolean} isTrust 
     * @param {String} authHeader 
     * @returns 
     */
    static getAdditionalInterestType(quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader) {
      return processSubmission('getAdditionalInterestType', [quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust], authHeader);
    }

    static getAllowedContactTypesForPolicyContactRoleType(authHeader) {
      return processSubmission('getAllowedContactTypesForPolicyContactRoleType', [], authHeader);
    }

    static createOrUpdateAdditionalInterest(jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO, authHeader) {
      return processSubmission('createOrUpdateAdditionalInterest', [jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO], authHeader);
    }
 
    static removeAdditionalInterest(jobID, sessionUUID, selection, authHeader) {
      return processSubmission('removeAdditionalInterest', [jobID, sessionUUID, selection], authHeader);
    }

    static removeAdditionalInterestTrustee(jobID, sessionUUID, trustId, selection, authHeader) {
      return processSubmission('removeAdditionalInterestTrustee', [jobID, sessionUUID, trustId, selection], authHeader);
    }

}