
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
// import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
// import { MetadataContent } from '@jutro/uiconfig';
// import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
// import { useTranslator, IntlContext } from '@jutro/locale'; 
// import {
//     DropdownMenuHeader,
//     DropdownMenuLink,
// } from '@jutro/router';
// import { WizardContext, wizardStepProps } from '@xengage/gw-portals-wizard-react';

// import { PolicyChangeCommonMessages as messages } from 'wni-platform-translations';
// import { BaseAddNewChangeVersionComponent } from 'wni-components-platform-react';

// import { RTMultiQuoteChangeService } from 'wni-capability-policychange-rt';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { DropdownMenuHeader } from '@jutro/components';

import { PolicyChangeCommonMessages as messages } from 'wni-platform-translations';
import { BaseAddNewChangeVersionComponent } from 'wni-components-platform-react';

import { RTMultiQuoteChangeService } from 'wni-capability-policychange-rt';
import metadata from './RTAddNewChangeVersionComponent.metadata.json5';
import styles from './RTAddNewChangeVersionComponent.module.scss';
import { DropdownMenuLink } from '@jutro/router';
// import messages from './RTAddNewChangeVersionComponent.messages';



// const POLICY_TYPE_CORE = 'CORE';

// function getNormalizedPolicyType(policyType = '') {
//     let retval = policyType;

//     const upperCaseType = policyType.toUpperCase();
//     if (POLICY_TYPE_CORE === upperCaseType) {
//         retval = '';-
//     }
//     return retval;
// }

/**
 * RT Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function RTAddNewChangeVersionComponent(props) {
    
    const translator = useTranslator();

    const {
        sxsDataDTO: rtSxsDataDTO = {},
        ...restProps
    } = props;


    const {
        canAddNewVersion,
        periods = [{}],
    } = rtSxsDataDTO;


    const menuItems = [
        {  
            menuName: translator(messages.createNewVersion),
            createService: RTMultiQuoteChangeService.createDuplicateQuote,
            menuVisible: canAddNewVersion,
        },
    ];

    return <BaseAddNewChangeVersionComponent
        menuItems={menuItems}
        {...restProps}
    />;
}

RTAddNewChangeVersionComponent.propTypes = {
    // model: PropTypes.shape(
    //     {
    //         value: PropTypes.shape({})
    //     }
    // ),
    // onValueChange: PropTypes.func,
    // showErrors: PropTypes.bool,
    sxsDataDTO: PropTypes.shape({}).isRequired,
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
};

RTAddNewChangeVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,
};

export default RTAddNewChangeVersionComponent;
