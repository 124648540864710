import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    unLicensedReasonHelpText: {
        id: 'driver licenseinfo help text.For a Permit or ID only select No',
        defaultMessage: 'For a Permit or ID only select No'
    }
});
