
const FIRST_CHANGE_WIZARD_PAGE = 'DPHouseholdInfoChangePage';

function getChangePageJumpList({ policyChangeSource, getPageJumpAction }) {
    let retval;
    if (policyChangeSource === 'cancellation') {
        retval = [{
            name: 'Mailing Address',
            action: getPageJumpAction('DPHouseholdInfoPage'),
        }];
    } else {
        retval = [{
            name: 'Household Info',
            action: getPageJumpAction('DPHouseholdInfoPage'),
        }, {
            name: 'Residence',
            action: getPageJumpAction('DPResidencePage'),
        }, {
            name: 'Coverages',
            action: getPageJumpAction('DPCoveragesPage'),
        }, {
            name: 'Loss History and Prior Carrier',
            action: getPageJumpAction('DPLossHistoryAndPriorCarrierPage'),
        }, {
            name: 'Additional Interests',
            action: getPageJumpAction('DPAdditionalInterestsPage'),
        }];
    }
    return retval;
}

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};
