import { defineMessages } from 'react-intl';

export default defineMessages({
    makePaymentLabel: {
        id: 'billing.view.invoice-payment.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    noPaymentsDueLabel: {
        id: 'billing.view.invoice-payment.No payments are currently due',
        defaultMessage: 'No payments are currently due.'
    },
    selectInvoicePayLabel: {
        id: 'billing.view.invoice-payment.Please select an invoice to pay.',
        defaultMessage: 'Please select an invoice to pay.'
    },
    invoiceSummaryLabel: {
        id: 'billing.view.invoice-payment.Invoice(s) Summary',
        defaultMessage: 'Invoice(s) Summary'
    },
    totalPaymentSelectedLabel: {
        id: 'billing.view.invoice-payment.Total Payment Selected',
        defaultMessage: 'Total Payment Selected'
    },
    enterPaymentAmountLabel: {
        id: 'billing.view.invoice-payment.Enter Your Payment Amount',
        defaultMessage: 'Enter Your Payment Amount.'
    },
    cancelLabel: {
        id: 'billing.view.invoice-payment.Cancel',
        defaultMessage: 'Cancel'
    },
    paymentMethodLabel: {
        id: 'billing.view.invoice-payment.Payment Method',
        defaultMessage: 'Payment Method'
    },
    changePaymentAmountLabel: {
        id: 'billing.view.invoice-payment.Change Payment Amount',
        defaultMessage: 'Change Payment Amount'
    },
    payNowLabel: {
        id: 'billing.view.invoice-payment.Pay Now',
        defaultMessage: 'Pay Now'
    },
    invoiceLabel: {
        id: 'billing.directives.templates.invoice-select.Invoice #',
        defaultMessage: 'Invoice #:'
    },
    dueDateLabel: {
        id: 'billing.directives.templates.invoice-select.Due Date',
        defaultMessage: 'Due Date:'
    },
    invoiceDateLabel: {
        id: 'billing.directives.templates.invoice-select.Invoice Date',
        defaultMessage: 'Invoice Date:'
    },
    totalInvoiceAmountLabel: {
        id: 'billing.directives.templates.invoice-select.Total Invoice Amount',
        defaultMessage: 'Total Invoice Amount:'
    },
    hideInvoiceDetailsLabel: {
        id: 'billing.directives.templates.invoice-select.Hide Invoice Details',
        defaultMessage: 'Hide Invoice Details'
    },
    viewInvoiceDetailsLabel: {
        id: 'billing.directives.templates.invoice-select.View Invoice Details',
        defaultMessage: 'View Invoice Details'
    },
    lineLabel: {
        id: 'billing.directives.templates.invoice-select.Line',
        defaultMessage: 'Line'
    },
    policyLabel: {
        id: 'billing.directives.templates.invoice-select.Policy',
        defaultMessage: 'Policy'
    },
    chargeTypeLabel: {
        id: 'billing.directives.templates.invoice-select.Charge Type',
        defaultMessage: 'Charge Type'
    },
    itemTypeLabel: {
        id: 'billing.directives.templates.invoice-select.Item Type',
        defaultMessage: 'Item Type'
    },
    amountLabel: {
        id: 'billing.directives.templates.invoice-select.Amount',
        defaultMessage: 'Amount'
    },
    overpayNotSupported: {
        id: 'billing.controllers.InvoicePaymentCtrl.Overpay is not supported',
        defaultMessage: 'Overpay is not supported'
    },
    sorryYourPaymentCould: {
        id: 'billing.controllers.InvoicePaymentCtrl.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    paymentRequestFailed: {
        id: 'billing.controllers.InvoicePaymentCtrl.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
    paymentNumbersOnly: {
        id: 'billing.controllers.InvoicePaymentCtrl.Please enter numbers only',
        defaultMessage: 'Please enter numbers only.'
    },
    paymentPositiveNumber: {
        id: 'billing.controllers.InvoicePaymentCtrl.Payment amount must be positive',
        defaultMessage: 'Payment amount must be positive.'
    }
});
