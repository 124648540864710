
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { HOLossHistoryAndPriorCarrierReadOnlyPage } from 'wni-capability-quoteandbind-ho-react';

function DPLossHistoryAndPriorCarrierReadOnlyPage(props) {

    return (
        <HOLossHistoryAndPriorCarrierReadOnlyPage {...props} 
            productName = 'dwellingProperty'
        />
    );

}

DPLossHistoryAndPriorCarrierReadOnlyPage.propTypes = HOLossHistoryAndPriorCarrierReadOnlyPage.propTypes;
DPLossHistoryAndPriorCarrierReadOnlyPage.defaultProps = HOLossHistoryAndPriorCarrierReadOnlyPage.defaultProps;

export default DPLossHistoryAndPriorCarrierReadOnlyPage;
