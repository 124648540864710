import React, {
    useContext,
    useState,
    useEffect,
} from 'react'
import { WniInputText } from "wni-common-base-components"
import CoveragesConfigContext from '../../../context/IMCoveragesConfigContext'
import SingleClauseContext from '../../../context/IMSingleClauseContext'
import { ValuePath } from '../../../util/IMScheduleItemsUtil'

const ScheduleItemEditableCellText = (props) => {
    const {
        propertyInfo,
        scheduleItem,
        scheduleItemVMPath,
    } = props

    const {
        id: propertyInfoId,
        required,
    } = propertyInfo

    const {
        itemNumber,
        itemData: {
            [propertyInfoId]: itemDataField
        }
    } = scheduleItem

    const {
        [ValuePath.StringValue]: value,
        available_Ext: available
    } = itemDataField

    const [editingValue, setEditingValue] = useState(value)

    useEffect(() => {
        setEditingValue(value)
    }, [value])

    const coveragesConfig = useContext(CoveragesConfigContext);


    const {
        clauseCode,
        showErrors,
        onChangeClause,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)
    const {
        scheduleConfig: {
            fieldsNotNeedSyncWhileChange: {
                [clauseCode]: fieldsNotNeedSync = []
            }
        }
    } = coveragesConfig;

    const notNeedSyncImmediately = fieldsNotNeedSync.includes(propertyInfoId)


    const onBlur = () => {
        const newItemDataField = {
            ...itemDataField,
            [ValuePath.StringValue]: editingValue,
            updated_Ext: true
        };
        const path = `${scheduleItemVMPath}.itemData.${propertyInfoId}`
        if (notNeedSyncImmediately) {
            onChangeClause(newItemDataField, path)
            return
        }
        onChangeSubmissionAndSync(newItemDataField, path)
        // console.log(path)
    }

    const onStringValueChange = (newValue) => {
        setEditingValue(newValue)
    }

    return <WniInputText
        id = {`${propertyInfoId}_${itemNumber}`}
        required = {required}
        showRequired = {required}
        value = {editingValue}
        onValueChange = {onStringValueChange}
        showErrors = {showErrors}
        onBlur={onBlur}
        maxLength={255}
        disabled={!available}
    />
}

export default ScheduleItemEditableCellText