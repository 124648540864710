
import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import stereotype from 'stereotype';
import { ViewModelUtil } from 'wni-portals-viewmodel-js';

const TOOLTIP_MSG_PREFIX = 'portal.components.ClauseComponent.tooltip';

/**
 * Get tooltip string for the checkbox in ClauseComponent based on current pattern code
 *
 * Requirement: the message key defined in the <code>messages</code> object should be
 * Clause PatternCode. Otherwise the tick here will not work.
 *
 * @param {object} messages
 * @param {string} clausePatternCode
 * @param {function} translator
 * @returns {string}
 */
function getClauseCheckboxTooltipByMessageKey(messages, clausePatternCode, translator) {
    const messageObject = _.get(messages, clausePatternCode);
    if (!messageObject) {
        return null;
    }
    return translator(messageObject);
}

/**
 * Get tooltip string for the checkbox in ClauseComponent based on current pattern code
 *
 * Note: This method is replaced by getClauseCheckboxTooltipByMessageKey due to
 * the following reasons:
 * 1) The logic to check empty message is less than ideal, and
 * 2) There is discrepency between the generated en_US.json in local env and  Guidewire Cloud,
 * which makes the translator({id: ''}) way unreliable so far
 * -- at least before this disrepency is resolved.
 *
 * @param {string} clausePatternCode
 * @param {function} translator
 * @returns {string}
 */
function getClauseCheckboxTooltip_Simplified(clausePatternCode, translator) {
    const checkboxTooltip = translator({ id: `${TOOLTIP_MSG_PREFIX}.${clausePatternCode}` });
    if (checkboxTooltip.startsWith(TOOLTIP_MSG_PREFIX)) {
        return null;
    }
    return checkboxTooltip;
}

/**
 * Coverage or term path
 * See ClausesUtil.getLobNameFromPath()
 * @param {string} path
 * @returns {string} 'lineCoverages' or 'vehicleCoverages'
 */
function getClauseTypeFromPath(path) {
    const pathSections = path.split('.');

    const lobDataSectionIndex = pathSections.findIndex((section) => section === 'coverages');
    const lobNameIndex = lobDataSectionIndex + 1;

    return pathSections[lobNameIndex];
}

/**
* Extracted from new ClauseComponent.jsx::getLabelNameWithAmount()
* @param {String} labelName
* @param {String} labelAmount
* @param {object} intlContext
* @param {boolean} showAmount
* @returns {String}
*/
function getLabelNameWithAmount(
    labelName, labelAmount, intlContext,
    showAmount = true,
) {
    if (!_.isUndefined(labelAmount) && showAmount && labelAmount.amount !== 0) {
        // const intl = this.context;
        const formatterCurrency = intlContext.formatNumber(
            labelAmount.amount,
            {
                style: 'currency',
                currency: labelAmount.currency,
                currencyDisplay: 'symbol'
            }
        );
        return `${labelName} ${formatterCurrency}`;
    }
    return labelName;
}

// /**
//  * Extracted from new ClauseComponent.jsx::getLabelNameWithAmount()
//  * @param {String} labelName
//  * @param {String} labelAmount
//  * @param {boolean} showAmount
//  * @returns {String}
//  */
// function getLabelNameWithAmount(labelName, labelAmount, showAmount = true) {
//     if (!_.isUndefined(labelAmount) && showAmount) {
//         return `${labelName} ${formatCurrency(labelAmount.amount, labelAmount.currency)}`;
//     }
//     return labelName;
// }


/**
 * Get clause component ID
 * @param {string} idPrefix
 * @param {string} coveragePublicID
 * @param {string} repeatItemId (Optional) e.g. vehicleID, driverID, etc.
 * @returns {string}
 */
function getClauseContainerId(idPrefix, coveragePublicID, repeatItemId = undefined) {
    let retval = `${idPrefix}_${coveragePublicID}_td_div_container`;
    if (!_.isNil(repeatItemId)) {
        retval = `${retval}_${repeatItemId}`;
    }
    return retval;
}

const getCurrentOfferingVM = (submissionVM) => {
    const selectedVersion = _.get(submissionVM, 'baseData.selectedVersion_Ext.value');
    const offerings = _.get(submissionVM, 'lobData.personalAuto.offerings.children', []);
    return _.find(offerings, (offering) => _.get(offering, 'publicID_Ext.value') === selectedVersion);
};

const getCurrentOfferingIndex = (submissionVM) => {
    const selectedVersion = _.get(submissionVM.value, 'baseData.selectedVersion_Ext');
    const offerings = _.get(submissionVM.value, 'lobData.personalAuto.offerings');
    return _.findIndex(offerings, (offering) => _.get(offering, 'publicID_Ext') === selectedVersion);
};

const getCurrentOfferingValueFromResponse = (response) => {
    const selectedVersion = _.get(response, 'baseData.selectedVersion_Ext');
    const offerings = _.get(response, 'lobData.personalAuto.offerings', []);
    return _.find(offerings, (offering) => _.get(offering, 'publicID_Ext') === selectedVersion);
};

const getVehicleCoverageVMs = (submissionVM) => {
    const offeringVM = getCurrentOfferingVM(submissionVM);
    if (_.isNil(offeringVM)) {
        return [];
    }
    return _.get(offeringVM, 'coverages.vehicleCoverages.children');
};

const getVehicleCoverageValuesFromResponse = (response) => {
    const offering = getCurrentOfferingValueFromResponse(response);
    if (_.isNil(offering)) {
        return [];
    }
    return _.get(offering, 'coverages.vehicleCoverages');
};

const getLineCoverageValuesFromResponse = (response) => {
    const offering = getCurrentOfferingValueFromResponse(response);
    if (_.isNil(offering)) {
        return [];
    }
    return _.get(offering, 'coverages.lineCoverages');
};

const getVehicleCoverageValues = (submissionVM) => {
    if (_.isNil(_.get(submissionVM, 'value'))) {
        return [];
    }
    return getVehicleCoverageValuesFromResponse(submissionVM.value);
};

const setVehicleCoverageValues = (submissionVM, coverageValues) => {
    const currentOfferingIndex = getCurrentOfferingIndex(submissionVM);
    _.set(
        submissionVM.value,
        `lobData.personalAuto.offerings[${currentOfferingIndex}].coverages.vehicleCoverages`,
        coverageValues
    );
    return submissionVM;
};

const setLineCoverageValues = (submissionVM, coverageValues) => {
    const currentOfferingIndex = getCurrentOfferingIndex(submissionVM);
    _.set(
        submissionVM.value,
        `lobData.personalAuto.offerings[${currentOfferingIndex}].coverages.lineCoverages`,
        coverageValues
    );
    return submissionVM;
};

/**
 * Takes an object  and filters out any clauses that have not been updated
 * (or dont have updated terms)
 * @param {Object} clauses - an object consisting of LOBS with clause properties.
 * @returns {Object} the same structure as allCoverages, but with the coverages reduced.
 *                  Empty lists are marked as undefined
 */
function filterUnchangedClauses(clauses) {
    // remove any coverages from the array that have not been updated
    if (_.isArray(clauses)) {
        const updatedClauses = _.filter(clauses, (clause) => {
            // return updated Clauses or Clauses with updated terms.
            // Also return everything that isnt a coverage (i.e. doesnt have coverageCategoryCode)
            return clause.updated === true
                || _.get(clause, 'schedule.updated_Ext') === true
                || _.filter(clause.terms, { updated: true }).length > 0
                || !clause.coverageCategoryCode;
        });
        return (updatedClauses.length > 0) ? updatedClauses : undefined;
    }
    return clauses;
}

function getPathToTerm(changedPath) {
    // onBlur event returns an object instead of path as a String
    const path = _.isString(changedPath) ? changedPath : changedPath.model;
    const matched = /(.*.terms(.children)?\[\d+\])(.*$)/.exec(path);
    if (!matched) {
        return changedPath;
    }
    const [, termPath] = matched;
    return termPath;
}

function setClauseTermUpdated(data, path) {
    const pathToTermVM = getPathToTerm(path);
    const pathToTerm = ViewModelUtil.getNonVMPath(pathToTermVM);
    _.set(data, `${pathToTerm}.updated`, true);
}

function setClauseValue(vm, value, path) {
    const clonedModel = _.cloneDeep(vm.value);
    const basePath = ClausesUtil.getObjectPathFromChangedPath(path);
    const nonVMChangedPath = ViewModelUtil.getNonVMPath(path);
    const nonVMBasePath = ViewModelUtil.getNonVMPath(basePath);
    const realValue = stereotype(value);
    _.set(clonedModel, nonVMChangedPath, value);
    if (path.includes('.terms')) {
        setClauseTermUpdated(clonedModel, path);
    } else if (path.includes('.schedule')) {
        _.set(vm, `${basePath}.schedule.updated_Ext`, true);
    } else {
        // set flag to changed
        _.set(clonedModel, `${nonVMBasePath}.updated`, true);
    }

    // checks the type of the realValue and the value in the base object
    // incase the realValue is an empty string and the correct type can
    // be taken from the base object
    _.set(clonedModel, nonVMChangedPath, realValue);

    // eslint-disable-next-line no-param-reassign
    vm.value = clonedModel;

    return vm;
}

/**
 * Takes an object  and filters out any clauses that have not been updated
 * (or dont have updated terms)
 * @param {Object} clauses - an object consisting of LOBS with clause properties.
 * @returns {Object} the same structure as allCoverages, but with the coverages reduced.
 *                  Empty lists are marked as undefined
 */
function filterUnchangedClausesSchedule(clauses) {
    if (_.isArray(clauses)) {
        const updatedClauses = _.filter(clauses, (clause) => {
            return clause.updated === true
                || _.get(clause, 'schedule.updated_Ext') === true
                || _.filter(clause.terms, { updated: true }).length > 0
                || !clause.coverageCategoryCode;
        });
        return (updatedClauses.length > 0) ? updatedClauses : undefined;
    }
    return clauses;
}

/**
 * Puts the cluses in an object to send to the server containing all the changed clauses
 * @param {Object|Array} coverages - All the objects to send to the server
 * @param {string} lobName - Name of the Lob
 * @param {string} clauseType - type of clause, additionalCoverage, lobCoverage
 * @returns {Object} object to send to the server containing all the changed clauses
 */
function structureClausesScheduleForServer(coverages, lobName, clauseType) {
    let changedCoverages = null;
    coverages.forEach((cov) => {
        const changeClauses = filterUnchangedClausesSchedule(cov.coverages);
        if (changeClauses && !_.isEmpty(changeClauses)) {
            const updatedCoveragesByVehicle = _.clone(cov);
            _.set(updatedCoveragesByVehicle, 'coverages', changeClauses);
            if (_.isNil(changedCoverages)) {
                changedCoverages = [];
            }
            changedCoverages.push(updatedCoveragesByVehicle);
        }
    });
    return {
        [lobName]: { [clauseType]: changedCoverages }
    };
}

/*
 * Get normalized changed value path
 * @param {string} path
 * @param {string} changedValuePath
 * @returns {string}
 */
function getChangedValuePath(path, changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    const normalisePath = pathToNormalise.replace(/\[/g, '.children[');
    const basePath = path.replace(/\.value/, '');
    return `${basePath}${normalisePath}`;
}

/**
 * Puts the cluses in an object to send to the server containing all the changed clauses
 * @param {Object|Array} clauses - All the objects to send to the server
 * @param {string} lobName - Name of the Lob
 * @param {string} clauseType - type of clause, additionalCoverage, lobCoverage
 * @param {boolean} checkScheduleItemExistence - checks whether existence of schedule Item
 *   indicates that coveage has been selected.
 * @returns {Object} object to send to the server containing all the changed clauses
 */
function structureClausesForServerImpl(
    clauses,
    lobName,
    clauseType,
    checkScheduleItemExistence = false,
) {
    // object containing multiple clause types:
    // eg. { lineCoverages: [ ...{clauses} ], vehicle: [ ...{clauses} ] }
    let clausesFromObject = [];
    // single clause type: [ ...{clauses} ]
    let clausesFromArray = {};

    if (_.isArray(clauses)) {
        clausesFromArray = {
            [clauseType]: filterUnchangedClauses(clauses)
        };
    } else {
        clausesFromObject = Object.entries(clauses).map(([clauseTypeKey, clause]) => ({
            [clauseTypeKey]: filterUnchangedClauses(clause)
        }));
    }

    if (checkScheduleItemExistence) {
        _.map(clausesFromArray[clauseType], (clause) => {
            if (!_.isEmpty(clause.schedule)) {
                const scheduleItems = _.get(clause, 'schedule.scheduleItems');
                if (!_.isEmpty(scheduleItems) && scheduleItems.length > 0) {
                    _.set(clause, 'selected', true);
                } else {
                    _.set(clause, 'selected', false);
                }
            }
        });
    }

    return {
        [lobName]: Object.assign({}, clausesFromArray, ...clausesFromObject)
    };
}

/**
 * Puts the cluses in an object to send to the server containing all the changed clauses
 * @param {Object|Array} clauses - All the objects to send to the server
 * @param {string} lobName - Name of the Lob
 * @param {string} clauseType - type of clause, additionalCoverage, lobCoverage
 * @returns {Object} object to send to the server containing all the changed clauses
 */
function structureClausesForServer(clauses, lobName, clauseType) {
    return structureClausesForServerImpl(clauses, lobName, clauseType, true);
}

/**
 * Puts the cluses in an object to send to the server containing all the changed clauses.
 * Note that existence of schedule items is not checked.
 * @param {Object|Array} clauses - All the objects to send to the server
 * @param {string} lobName - Name of the Lob
 * @param {string} clauseType - type of clause, additionalCoverage, lobCoverage
 * @returns {Object} object to send to the server containing all the changed clauses
 */
function structureClausesForServerWithoutScheduleItem(clauses, lobName, clauseType) {
    return structureClausesForServerImpl(clauses, lobName, clauseType, false);
}

export default {
    // getClauseCheckboxTooltip,
    getClauseCheckboxTooltip: getClauseCheckboxTooltipByMessageKey,
    getClauseTypeFromPath,
    getLabelNameWithAmount,
    getClauseContainerId,
    getVehicleCoverageVMs,
    getVehicleCoverageValues,
    getVehicleCoverageValuesFromResponse,
    getLineCoverageValuesFromResponse,
    setVehicleCoverageValues,
    setLineCoverageValues,
    filterUnchangedClauses,
    setClauseValue,
    structureClausesScheduleForServer,
    getChangedValuePath,
    structureClausesForServer,
    structureClausesForServerWithoutScheduleItem,
};
