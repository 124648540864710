import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'InlandMarine.signs.schedules.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'InlandMarine.signs.schedules.table.del',
        defaultMessage: 'Delete Selected'
    },
    scheduleEdit: {
        id: 'InlandMarine.signs.schedules.table.edit',
        defaultMessage: 'Edit'
    },
    scheduleView: {
        id: 'InlandMarine.signs.schedules.table.view',
        defaultMessage: 'View'
    },
    ListOfScheduleItems: {
        id: 'InlandMarine.signs.schedules.table.ListOfScheduleItems',
        defaultMessage: 'List of Scheduled Items'
    },
    itemFormNotClosedMessage: {
        id: 'InlandMarine.signs.schedules.table.itemFormNotClosedMessage',
        defaultMessage: 'Click Cancel or Save to continue.'
    },
    signsScheduleInvalidMessage: {
        id: 'InlandMarine.signs.schedules.table.scheduleInvalidMessage',
        defaultMessage: 'Information for this schedule is missing.'
    }
})