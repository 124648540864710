import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import {
    withViewModelService,
    ViewModelForm,
} from '@xengage/gw-portals-viewmodel-react';
import { ContactService } from 'gw-capability-gateway';
import { WniContactService } from 'wni-capability-gateway';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ServiceManager } from '@jutro/legacy/services';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import messages from '../../gateway.messages';
import metadata from './ContactDetails.metadata.json5';


function ContactDetails(props) {
    const {
        fromAccountLanding: {
            accountDetailsData: {
                accountHolder = {},
                accountHolder: { contactType_Ext: accountType } = {},
                accountNumber,
            } = {},
        } = {},
        history,
        location: {
            state: { accountOrPolicyNumber, contact, contactId, relatedTo },
        },
    } = props;
    const localeService = ServiceManager.getService('locale-service');
    const phoneUtil = PhoneUtil();
    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
        interactionModel,
    } = useDependencies(['loadingMask', 'interactionModel']);
    const { authHeader } = useAuthentication();
    const [contactDetails, updateContactDetails] = useState({});
    const initData = useCallback(async () => {
        let res;
        setLoadingMask(true);
        if (contact !== 'policyContacts') {
            const [
                accountContactRes,
                associatedAccountsRes,
                accountContactRolesRes,
                accountDBAsRes
            ] = await Promise.all([
                await WniContactService.getAccountContact(contactId, authHeader),
                await WniContactService.getAssociatedAccounts(contactId, authHeader),
                await WniContactService.getAccountContactRoles(contactId, authHeader),
                await WniContactService.getAccountDBAs(contactId, authHeader),
            ])
            res = {
                ...accountContactRes,
                associatedAccounts: associatedAccountsRes,
                accountContactRoles: accountContactRolesRes,
                accountDBAs: accountDBAsRes,
            }
        } else {
            res = await ContactService.getPolicyContactDetails(
                contactId,
                accountOrPolicyNumber,
                authHeader
            );
        }

        _.set(res, 'accountHolder', accountHolder);
        updateContactDetails(res);
        setLoadingMask(false);
    }, [accountHolder, accountOrPolicyNumber, authHeader, contact, contactId, setLoadingMask]);

    useEffect(() => {
        initData();
    }, []);
    const renderContactRoles = () => {
        const rolesPath =
            contact === 'policyContacts'
                ? 'policyContactRoles'
                : 'accountContactRoles';
        const rolesMap = _.get(contactDetails, rolesPath, []);
        return rolesMap.map((item) => {
            const code = _.camelCase(item.subtype);
            return {
                code: item.subtype,
                name: translator(messages[code]),
            };
        });
    };

    const generateData = () => {
        const { associatedAccounts = [] } = contactDetails;
        return associatedAccounts.map((item) => {
            return {
                ...item,
                displayName: `${item.accountHolderName} (${item.accountNumber})`,
            };
        });
    };
    const generateAssociatedAccounts = () => {
        const { associatedAccounts = [] } = contactDetails;

        const overrides = associatedAccounts.map((account, index) => {
            return {
                [`associatedLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: `${account.accountHolderName} (${account.accountNumber})`,
                },
                [`associatedIcon${index}`]: {
                    icon: account.subtype === 'Company' ? 'business' : 'person',
                },
            };
        });
        return Object.assign({}, ...overrides);
    };
    const getPolicySummaryLink = (policyNumber) => {
        return interactionModel.getURLObj(accountType, 
            'policySummary',
            accountNumber,
            policyNumber
        );
    };

    const renderPolicyCell = (item, index, { path }) => {
        const associatedPolicies = item[path];
        const policyLinkFields = associatedPolicies.map((policyNumber) => {
            const fieldProps = getPolicySummaryLink(policyNumber);
            return (
                <LinkComponent {...fieldProps}>{policyNumber}</LinkComponent>
            );
        });
        return <Flex justifyContent='left'>{policyLinkFields}</Flex>;
    };
    const overrideProps = {
        contactTypeName: {
            content: messages[`${contact}Title`],
            to: {
                pathname: `/${relatedTo}/${accountOrPolicyNumber}/contacts`,
                state: {
                    selectTab: `${contact}Tab`,
                },
            },
        },
        contactDisplayName: {
            content: contactDetails.displayName,
        },
        accountDetails: {
            title: _.upperFirst(contactDetails.contactType_Ext),
        },
        rolesList: {
            data: renderContactRoles(),
        },
        industry: {
            visible:
                contact !== 'policyContacts' &&
                contactDetails.contactType_Ext !== 'person',
        },
        associatedList: {
            data: generateData(),
        },
        dbasSection: {
            visible: contactDetails.contactType_Ext !== 'person',
        },
        dbaTable: {
            data: _.get(contactDetails, 'accountDBAs', []),
        },
        dateOfBirth: {
            visible: contactDetails.contactType_Ext === 'person',
        },
        maritalStatus: {
            visible: contactDetails.contactType_Ext === 'person',
            value: _.get(contactDetails, 'maritalStatus') ? translator({id: `typekey.MaritalStatus.${_.get(contactDetails, 'maritalStatus')}` }) : '-',
        },
        primaryMobile: {
            visible: contactDetails.contactType_Ext === 'person',
        },
        ...generateAssociatedAccounts(),
    };
    const resolvers = {
        callbackMap: {
            renderPolicyCell,
        },
        resolveComponentMap: {},
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            contactDetails,
            id,
            path,
            overrideProps
        );
    };
    if (_.isEmpty(contactDetails)) {
        return null;
    }
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={contactDetails}
            overrideProps={overrideProps}
            resolveValue={readValue}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.callbackMap}
        />
    );
}

export default withRouter(ContactDetails);
