import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalCoverageTitle: {
        "id": "quoteandbind.gl.wizard.step.location.AdditionalCoverage",
        "defaultMessage": "Additional Coverage"
    },
    AdditionalCoverageActionText: {
        "id": "quoteandbind.gl.wizard.step.location.AdditionalCoverageActionText",
        "defaultMessage": "Add Coverages"
    },
});
