import { defineMessages } from 'react-intl';

export default defineMessages({
    canNotSelectWindscreen: {
        id: 'fnol.pe.wni.ca.directives.vehicle-damage-picker.Cannot select the windscreen for Collision claim',
        defaultMessage: 'Cannot select the windscreen for Collision claim'
    },
    canNotSelectWindscreenMessage: {
        id: 'fnol.pe.wni.ca.directives.vehicle-damage-picker.If you wish to select the windscreen for your claim, please select Glass as your claim type.',
        defaultMessage: 'If you wish to select the windscreen for your claim, please select Glass as your claim type.',
    },
    canNotSelectRoof: {
        id: 'fnol.pe.wni.ca.directives.vehicle-damage-picker.Cannot select the roof for a Collision claim',
        defaultMessage: 'Cannot select the roof for a Collision claim'
    },
    canNotSelectRoofMessage: {
        id: 'fnol.pe.wni.ca.directives.vehicle-damage-picker.If you wish to select the roof for your claim, please select Other as your claim type.',
        defaultMessage: 'If you wish to select the roof for your claim, please select Other as your claim type.',
    },
    partOfTheCarDamaged: {
        id: 'fnol.pe.wni.ca.directives.damage-list.Select which part of the car was damaged.',
        defaultMessage: 'Select which part of the car was damaged.',
    }
});
