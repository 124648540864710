import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalNext,
} from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import metadata from './IMSelectablePartsPopup.metadata.json5';

function IMSelectablePartsPopup(props) {
    const {
        title,
        size,
        isOpen,
        onReject,
        onResolve,
        cancelButtonText,
        confirmButtonText,
        selectableCoverageParts
    } = props;

    const [selection, updateSelection] = useState([]);

    const cancel = () => {
        onReject();
    };

    const save = () => {
        onResolve(selection);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        coveragePartTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            data: selectableCoverageParts,
        },
        saveButton: {
            disabled: _.isEmpty(selection),
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            selectableCoverageParts,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader onClose={onReject} title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    {...resolvers}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="secondary"
                    label={cancelButtonText}
                    onClick={cancel}
                />
                <Button label={confirmButtonText} onClick={save} disabled={_.isEmpty(selection)} />
            </ModalFooter>
        </ModalNext>
    );
}

IMSelectablePartsPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
};
IMSelectablePartsPopup.defaultProps = {
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
};

export default IMSelectablePartsPopup;
