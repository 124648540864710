import { defineMessages } from 'react-intl';

export default defineMessages({
    totalPremium: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Total Premium',
        defaultMessage: 'Total Premium'
    },
    policyDetails: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Policy Details',
        defaultMessage: 'Policy Details'
    },
    renewalEffectiveDate: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Renewal Effective Date',
        defaultMessage: 'Renewal Effective Date'
    },
    paymentPlans: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Payment Plans',
        defaultMessage: 'Payment Plans'
    },
    highPriority: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the job until the underwriter releases it back to you.',
        defaultMessage: 'Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the {jobType} until the underwriter releases it back to you.'
    },
    referJobToUnderWriter: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Are you sure you want to refer this job to the Underwriter?',
        defaultMessage: 'Are you sure you want to refer this {jobType} to the Underwriter?'
    },
    infoExceptAgent: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.The renewal for ProductName policy PolicyNumber will be bound automatically on Date',
        defaultMessage: 'The renewal for {productName} policy {policyNumber} will be bound automatically on {date}.'
    },
    issueNow: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.If it is important to bind earlier than this date, click Issue Now.',
        defaultMessage: 'If it is important to bind earlier than this date, click Issue Now.'
    },
    processing: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.Processing...',
        defaultMessage: 'Processing...'
    },
    processingPayment: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.Processing Payment...',
        defaultMessage: 'Processing Payment...'
    },
    issueThisRenewal: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.You do not have permission to issue this renewal',
        defaultMessage: 'You do not have permission to issue this renewal'
    },
    underwriterConsideration: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.If you would like this renewal to be issued, please refer it to an underwriter for their consideration.',
        defaultMessage: 'If you would like this renewal to be issued, please refer it to an underwriter for their consideration.'
    },
    currentPolicyPeriod: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Current Policy Period',
        defaultMessage: 'Current Policy Period'
    },
    unableToPurchase: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.Unable to purchase renewal',
        defaultMessage: 'Unable to purchase renewal'
    },
    unableToBind: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.An error occurred while attempting to bind your renewal',
        defaultMessage: 'An error occurred while attempting to bind your renewal'
    },
    noPaymentSelected: {
        id: 'quoteandbind.controllers.PaymentDetailsCtrl.Please choose a payment plan',
        defaultMessage: 'No Payment Plan Selected'
    },
    selectPaymentPlan: {
        id: 'quoteandbind.controllers.PaymentDetailsCtrl.You have not chosen a payment plan',
        defaultMessage: 'Please select a payment plan and try again.'
    },
    confirmJob: {
        id: 'policy-renewal.views.job-uwissues.Confirm',
        defaultMessage: 'Confirm'
    },
    cancelJob: {
        id: 'policy-renewal.views.job-uwissues.Cancel',
        defaultMessage: 'Cancel'
    },
    referToUWInfoRenewal: {
        id: 'policy-renewal.views.job-uwissues.Are you sure you want to refer this Renewal to the Underwriter?',
        defaultMessage: 'Are you sure you want to refer this renewal to the Underwriter?'
    },
    referToUnderwriter: {
        id: 'policy-renewal.views.job-uwissues.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    noteForUnderwriter: {
        id: 'policy-renewal.views.job-uwissues.Note for the underwriter',
        defaultMessage: 'Note for Underwriter'
    },
    referralInfoRenewal: {
        id: 'policy-renewal.views.job-uwissues.Referral Info for Renewal',
        defaultMessage: 'Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the renewal until the underwriter releases it back to you.'
    },
});
