import React from 'react';
import WizardPage from '../../templates/WALWizardPage';
import WALWatercraftsPage from './WALWatercraftsPage';

function WALWatercraftsReadOnlyPage(props) {
    return (
        <WALWatercraftsPage {...props} isReadOnly checkRequiredForIssuance={false} />
    );
}

WALWatercraftsReadOnlyPage.propTypes = WizardPage.propTypes;
export default WALWatercraftsReadOnlyPage;
