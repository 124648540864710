

import React from 'react';
import { IMMuseumReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMMuseumChangeReadOnlyPage = (props) => {
    return <IMMuseumReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMMuseumChangeReadOnlyPage
