import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { InternalRouteConstants } from 'wni-portals-config-js';

import SubmissionWizardCommonRoutes from './transactions/SubmissionWizardCommonRoutes';
import PolicyChangeWizardCommonRoutes from './transactions/PolicyChangeWizardCommonRoutes';
import HOAccountSummaryRoutes from './transactions/HOAccountSummaryRoutes';
import PaymentCommonRoutes from './payment/PaymentCommonRoutes';
import SkipWizardLeavePromptComponent from './transactions/SkipWizardLeavePromptComponent';


const { URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT } = InternalRouteConstants;

function InternalCommonRoutes(props) {

    return (
        <Switch>
            <Route exact path="/internal/quote-wizard/:productCode/:postalCode/:quoteID" component={SubmissionWizardCommonRoutes} />
            {/* <Route exact path="/internal/quote-wizard/:productCode/:postalCode/:quoteID/:periodPublicID" component={SubmissionWizardCommonRoutes} /> */}

            <Route exact path="/internal/endorsement-wizard/:productCode/:policyNumber/:jobID/:landingPageID" component={PolicyChangeWizardCommonRoutes} />
            {/* HO Account Summary */}
            <Route exact path="/internal/account-quotes/:accountNumber" component={HOAccountSummaryRoutes} />
            
            
            {/* HO Payment Page */}
            <Route exact path="/internal/account-quotes-payment/:jobNumber" component={PaymentCommonRoutes} />
            <Route exact path="/internal/account-quotes-payment/:jobNumber/:payStatus" component={PaymentCommonRoutes} />
            
            <Route path={URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT} component={SkipWizardLeavePromptComponent} />
            {/* <Route path="/internal/skip-wizard-leave-prompt" component={SkipWizardLeavePromptComponent} /> */}
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}

export default InternalCommonRoutes;
