import { defineMessages } from 'react-intl';

export default defineMessages({
    houseHoldPageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.Household Information',
        defaultMessage: 'Household Information',
    },
    residencePageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.Residence Risk Characteristics',
        defaultMessage: 'Residence Risk Characteristics',
    },
    coveragesPageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.Coverages',
        defaultMessage: 'Coverages',
    },
    lossHistoryPageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.LossHistoryAndPriorCarrierPage',
        defaultMessage: 'Loss History & Prior Carrier',
    },
    additionalInterestsPageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.Additional Interests',
        defaultMessage: 'Additional Interests',
    },
    quotePageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.Quote & Price',
        defaultMessage: 'Quote & Price',
    },
    paymentsPageTitle: {
        id: 'wni.quoteandbind.dp.wizard.step.Payments',
        defaultMessage: 'Payments',
    },
    // anErrorOccurred: {
    //     id: 'quoteandbind.dp.wizard.error.cancel',
    //     defaultMessage: 'Something went wrong during this process.'
    // },
    // anErrorOccurredTitle: {
    //     id: 'quoteandbind.dp.wizard.error.cancel.title',
    //     defaultMessage: 'Error'
    // },
    // home: {
    //     id: 'quoteandbind.wizard.step.Home',
    //     defaultMessage: 'Home'
    // },
    // saveQuoteError: {
    //     id: 'quoteandbind.dp.wizard.Unable to quote',
    //     defaultMessage: 'Unable to quote'
    // },
    // saveQuoteErrorMessage: {
    //     id: 'quoteandbind.dp.wizard.An error occurred while attempting to quote.',
    //     defaultMessage: 'An error occurred while attempting to quote.'
    // }
});
