import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Chevron, withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './BuildingScreen.messages';
import LocationAndBuildingmessages from '../LocationsAndBuildingsPage.messages';
import metadata from './BuildingScreen.metadata.json5';
import styles from './BuildingScreen.module.scss';

function renderAccordionHeader(translator) {
    return (isOpen) => (
        <React.Fragment>
            <Chevron isOpen={isOpen} />
            <h3 className={styles.title}>{translator(messages.additionalCoverages)}</h3>
        </React.Fragment>
    );
}

function formatPropertyCode(classCode) {
    return {
        ...classCode,
        name: `${classCode.code}: ${classCode.classification}`
    };
}

const COVERAGE_PATHS = {
    baseCoverage: 'buildingVM.baseCoverages',
    additionalCoverages: 'buildingVM.additionalCoverages'
};

class BuildingWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func.isRequired,
            clone: PropTypes.func.isRequired
        }).isRequired,
        quoteID: PropTypes.string.isRequired,
        jobID: PropTypes.string.isRequired,
        sessionUUID: PropTypes.string.isRequired,
        labelPosition: PropTypes.string.isRequired,
        addBuilding: PropTypes.func.isRequired,
        location: PropTypes.shape({
            address: PropTypes.string,
            displayName: PropTypes.string,
            publicID: PropTypes.string
        }).isRequired,
        returnToLocationScreen: PropTypes.func.isRequired,
        updateBuilding: PropTypes.func.isRequired,
        building: PropTypes.shape({}),
        authHeader: PropTypes.shape({}).isRequired,
        ...validationPropTypes
    };

    static defaultProps = {
        building: {}
    };

    state = {
        formData: {
            alarmTypeConditions: false,
            isPremiumBasicVisible: false,
            buildingVM: {}
        },
        shouldShowCoverages: false
    };

    componentDidMount() {
        const {
            viewModelService,
            quoteID,
            jobID,
            sessionUUID,
            location,
            building,
            authHeader,
            PropertyCodeLookupService
        } = this.props;

        const { formData } = this.state;

        const buildingVM = viewModelService.create(
            building,
            'pc',
            'edge.capabilities.policyjob.lob.businessowners.coverables.dto.BuildingDTO'
        );

        buildingVM.classCode.value = buildingVM.classCode.value
            ? formatPropertyCode(buildingVM.classCode.value)
            : {};

        formData.buildingVM = buildingVM;
        formData.alarmTypeConditions = !!_.get(buildingVM, 'alarmType.value');
        formData.locationDisplayName = location.displayName;
        this.setState({
            formData,
            isExistingBuilding:
                !_.isEmpty(building)
                && _.has(building, 'baseCoverages')
                && _.has(building, 'additionalCoverages'),
            shouldShowCoverages: !_.isEmpty(building)
        });

        PropertyCodeLookupService.getPropertyCodes(
            'BusinessOwners',
            quoteID || jobID,
            null,
            location.address,
            sessionUUID,
            authHeader
        ).then((propertyCodes) => {
            const formattedPropertyCodes = propertyCodes.map(formatPropertyCode);
            this.setState({ propertyCodes: formattedPropertyCodes });
        });
    }

    onModelChange = (formData) => {
        this.setState({ formData });
        this.shouldShowCoverage();
    };

    handleError = () => {
        this.props.modalContext.showAlert({
            title: messages.buildingNotSaved,
            message: messages.pleaseCheckForErrorsAndTryAgain,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
        this.setState({ loadingClause: undefined });
    };

    onSaveBuilding = () => {
        const {
            location, quoteID, jobID, sessionUUID, authHeader, BopCoverablesService
        } = this.props;
        const {
            formData: { buildingVM },
            formData,
            shouldShowCoverages
        } = this.state;
        let saveBuildingCall;

        if (!shouldShowCoverages) {
            return null;
        }

        if (
            _.has(buildingVM.value, 'baseCoverages')
            && _.has(buildingVM.value, 'additionalCoverages')
        ) {
            saveBuildingCall = BopCoverablesService.updateBOPBuilding(
                quoteID || jobID,
                location.publicID,
                formData.buildingVM.value,
                sessionUUID,
                authHeader
            );
        } else {
            saveBuildingCall = BopCoverablesService.addBOPBuilding(
                quoteID || jobID,
                location.publicID,
                buildingVM.value,
                sessionUUID,
                authHeader
            );
        }

        return saveBuildingCall
            .then((response) => {
                _.defaults(formData.buildingVM.value, response);
                this.setState({ formData });
            })
            .catch(this.handleError);
    };

    cloneAndMapCoverages = (response) => {
        const { viewModelService } = this.props;
        const {
            formData: { buildingVM }
        } = this.state;

        const newBuildingVM = viewModelService.clone(buildingVM);

        // only update coverages
        _.set(newBuildingVM, 'baseCoverages', response.baseCoverages);
        _.set(newBuildingVM, 'additionalCoverages', response.additionalCoverages);

        return newBuildingVM;
    };

    updateBuildingCoverages = (getNewBuildingFromResponse = this.cloneAndMapCoverages) => {
        const {
            location,
            quoteID,
            jobID,
            sessionUUID,
            authHeader,
            disregardFieldValidation,
            BopCoverablesService
        } = this.props;
        const { formData } = this.state;

        return BopCoverablesService.updateBOPBuildingCoverages(
            quoteID || jobID,
            location.publicID,
            formData.buildingVM.value,
            sessionUUID,
            authHeader
        )
            .then((response) => {
                const newBuildingVM = getNewBuildingFromResponse(response);

                const clauseIDsToRemove = Object.values(COVERAGE_PATHS).flatMap((coveragePath) => {
                    const vmPath = coveragePath.replace(/^buildingVM\./, '');
                    return ClausesUtil.getRemovedClausesID(
                        formData.buildingVM,
                        newBuildingVM,
                        vmPath
                    );
                });

                formData.buildingVM = newBuildingVM;
                disregardFieldValidation(clauseIDsToRemove);
                this.setState({
                    formData,
                    loadingClause: undefined
                });
            })
            .catch(this.handleError);
    };

    handlePropertyClassCodeChange = (selectedClassCode, path) => {
        const { formData, propertyCodes } = this.state;
        let selectedPropertyCode = {};

        if (selectedClassCode && selectedClassCode.code) {
            selectedPropertyCode = propertyCodes.find(
                ({ code }) => code === selectedClassCode.code
            );

            selectedPropertyCode.name = selectedClassCode.name;
        }

        _.set(formData, `${path}.value`, selectedPropertyCode);
        this.setState({ formData });
        this.shouldShowCoverage();
    };

    onBackClick = () => {
        const {
            returnToLocationScreen,
            quoteID,
            jobID,
            sessionUUID,
            location,
            building: originalBuilding,
            authHeader,
            BopCoverablesService
        } = this.props;
        const {
            formData: { buildingVM },
            isClausesShown,
            isExistingBuilding
        } = this.state;

        if (isExistingBuilding && !_.isEqual(originalBuilding, buildingVM.value)) {
            // if editing an exisiting building and something has changed
            // reset coverages as thats the only call that can be made
            this.updateBuildingCoverages();
        } else if (isClausesShown) {
            // if the clauses are shown, it means the building has been added to the server
            BopCoverablesService.removeBOPBuilding(
                quoteID || jobID,
                location.publicID,
                buildingVM.publicID.value,
                sessionUUID,
                authHeader
            );
        }

        if (returnToLocationScreen) {
            returnToLocationScreen();
        }
    };

    changeSubmissionAndSync = (value, changedPath) => {
        this.changeClause(value, changedPath);
        this.syncCoverages(value, changedPath);
    };

    inRangeValidation = (terms = []) => {
        if (!terms.length) {
            return true;
        }
        const getAllMinMaxTerms = terms.filter((val) => val.directValueMax || val.directValueMin);
        return getAllMinMaxTerms.every((val) => {
            if (val.directValueMax && val.directValue) {
                return _.inRange(val.directValue, val.directValueMin, val.directValueMax);
            }
            return !(val.directValue < val.directValueMin);
        });
    };

    termsValuesNotInRange = (value) => {
        if (value && value.length) {
            const getAllBaseCoveragesTerms = value.reduce((getAllTerms, coverage) => {
                return [...getAllTerms, ...coverage.terms];
            }, []);
            return this.inRangeValidation(getAllBaseCoveragesTerms);
        }
        return true;
    };

    syncCoverages = (value, changedPath) => {
        const { formData } = this.state;
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        const baseObject = _.get(formData, basePath);

        const path = basePath.includes('baseCoverages')
            ? `${COVERAGE_PATHS.baseCoverage}.value`
            : `${COVERAGE_PATHS.additionalCoverages}.value`;

        const clauses = _.get(formData, path);

        if (basePath.includes('baseCoverages')) {
            const changedBaseCoveragePath = changedPath.replace(/.terms.*/g, '.terms.value');
            const changedBaseCoverageTerms = _.get(formData, changedBaseCoveragePath);
            if (!this.inRangeValidation(changedBaseCoverageTerms)) {
                return;
            }
        }
        if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
            this.setState({ loadingClause: baseObject.coveragePublicID || baseObject.publicID });
            this.updateBuildingCoverages();
        }
    };

    changeClause = (value, changedPath) => {
        const { formData } = this.state;
        const coveragesVMPath = changedPath.replace(/\.children.*/, '');
        const coveragesVM = _.get(formData, coveragesVMPath);
        const clauseVMRelativePath = changedPath.replace(/^.*?\.children/, '');
        const newCoveragesVM = ClausesUtil.setClauseValue(coveragesVM, value, clauseVMRelativePath);

        _.set(formData, coveragesVMPath, newCoveragesVM);
        this.setState({ formData });
    };

    shouldShowCoverage = () => {
        const { formData } = this.state;
        const { buildingVM } = formData;

        const shouldShowCoverages = !_.isEmpty(buildingVM)
            && Object.values(buildingVM)
                .filter((value) => _.get(value, 'aspects.required'))
                .every(({ value, aspects }) => {
                    const isNodeValid = aspects.valid && aspects.subtreeValid;
                    const hasValue = !_.isEmpty(value)
                    || (_.isNumber(value) && !_.isUndefined(value));

                    return isNodeValid && hasValue;
                });
        this.setState({ shouldShowCoverages });
    };

    onAddBuilding = () => {
        const {
            location, building, addBuilding, updateBuilding
        } = this.props;
        const isEditingBuilding = !_.isEmpty(building);

        this.updateBuildingCoverages((response) => {
            const newBuildingVM = this.cloneAndMapCoverages(response);
            const { viewModelService } = this.props;
            return viewModelService.create(
                newBuildingVM.value,
                'pc',
                'edge.capabilities.policyjob.lob.businessowners.coverables.dto.BuildingDTO'
            );
        }).then(() => {
            const {
                formData: { buildingVM }
            } = this.state;
            if (isEditingBuilding) {
                updateBuilding(location.publicID, buildingVM);
            } else {
                addBuilding(location.publicID, buildingVM);
            }
        });
    };

    generateOverrides = () => {
        const flatMetadata = getFlattenedUiPropsContent(metadata.pageContent);
        const { formData } = this.state;
        const overrides = flatMetadata.map((field) => {
            const path = _.get(field, 'componentProps.path');
            const isFieldRequired = _.get(formData, `${path}.aspects.required`);
            if (!isFieldRequired) {
                return {};
            }
            return { [field.id]: { onBlur: this.onSaveBuilding } };
        });
        return Object.assign({}, ...overrides);
    };

    getBuildingLabelContent = () => {
        const { isExistingBuilding } = this.state;
        const translator = this.context;
        if (isExistingBuilding) {
            return translator(LocationAndBuildingmessages.saveBuilding);
        }
        return translator(LocationAndBuildingmessages.addBuilding);
    };

    render() {
        const { labelPosition, setComponentValidation, isComponentValid } = this.props;
        const {
            formData, propertyCodes, loadingClause, shouldShowCoverages
        } = this.state;

        const translator = this.context;
        const overrideProps = {
            '@field': {
                // apply to all fields
                showOptional: true,
                labelPosition
            },
            premiumBasicAmount: {
                visible: _.get(formData, 'buildingVM.basisAmount.aspects.required')
            },
            alarmType: {
                visible: formData.alarmTypeConditions
            },
            infoBox: {
                visible: !shouldShowCoverages
            },
            coverages: {
                visible: shouldShowCoverages
            },
            generalClauses: {
                loadingClause: loadingClause
            },
            additional_clauses_from_VM: {
                loadingClause: loadingClause
            },
            buildingAccordionCard: {
                renderHeader: renderAccordionHeader(translator)
            },
            propertyClassCode: {
                onValueChange: this.handlePropertyClassCodeChange,
                availableValues: propertyCodes
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onAddBuilding: this.onAddBuilding,
                onBackClick: this.onBackClick,
                onChangeClause: this.changeClause,
                onSyncCoverages: this.syncCoverages,
                onChangeSubmissionAndSync: this.changeSubmissionAndSync,
                onValidate: setComponentValidation
            }
        };

        const baseCoverage = _.get(formData, `${COVERAGE_PATHS.baseCoverage}.value`);
        const additionalCoverages = _.get(formData, `${COVERAGE_PATHS.additionalCoverages}.value`);
        const isFormValid = isComponentValid
            && shouldShowCoverages
            && !_.isUndefined(baseCoverage)
            && !_.isUndefined(additionalCoverages);

        const isLoading = !_.isUndefined(loadingClause);
        const shouldNextBeDisabled = !isFormValid || isLoading;

        const {
            buildingVM: { value }
        } = formData;

        const overridesWithButton = {
            addBuilding: {
                disabled:
                    shouldNextBeDisabled
                    || !this.termsValuesNotInRange(value.baseCoverages)
                    || !this.termsValuesNotInRange(value.additionalCoverages),
                content: this.getBuildingLabelContent()
            },
            ...overrideProps
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={formData}
                onModelChange={this.onModelChange}
                onValidationChange={setComponentValidation}
                overrideProps={_.merge({}, overridesWithButton, this.generateOverrides())}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

const Building = withModalContext(BuildingWithoutModalContext);

export default withValidation(
    withViewModelService(
        withAuthenticationContext(withDependencies(['PropertyCodeLookupService','BopCoverablesService'])(Building))
    )
);
