import { defineMessages } from 'react-intl';

export default defineMessages({
    personalAutoQuote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pa.Auto Insurance Quote',
        defaultMessage: 'Auto Insurance Quote'
    },
    location: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pa.Location',
        defaultMessage: 'Location'
    },
    vehicles: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pa.Vehicles',
        defaultMessage: 'Vehicles'
    },
    drivers: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.pa.Drivers',
        defaultMessage: 'Drivers'
    }
});
