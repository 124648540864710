import React, { useContext, useEffect, useState } from 'react';
import { WniInputText } from 'wni-common-base-components';
import SingleClauseContext from '../../../context/IMSingleClauseContext';

const IMScheduleItemEditabnleCovTermCellInputText = (props) => {
    const { showErrors, isEditable, setIsEditing } = useContext(SingleClauseContext);

    const { termID, term, setUpdatedTermToScheduleItem } = props;

    const { directStringValue } = term;

    const [editingValue, setEditingValue] = useState(directStringValue);

    useEffect(() => {
        setEditingValue(directStringValue);
    }, [directStringValue]);

    return (
        <WniInputText
            id={termID}
            showLabel={false}
            value={editingValue}
            onValueChange={(value) => {
                setIsEditing(true)
                setEditingValue(value)
            }}
            onBlur={async () => {
                setIsEditing(false)
                if (editingValue === directStringValue) {
                    return;
                }
                const updatedTerm = {
                    ...term,
                    directStringValue: editingValue,
                    updated: true,
                };
                await setUpdatedTermToScheduleItem(updatedTerm);
            }}
            required={term.required}
            readOnly={!isEditable || term.readOnly_Ext}
            showErrors={showErrors}
        />
    );
};

export default IMScheduleItemEditabnleCovTermCellInputText;
