import { defineMessages } from 'react-intl';

export default defineMessages({
    documentDeletionFailed: {
        id: 'document.Document deletion failed',
        defaultMessage: 'DocumentDeletionFailed'
    },
    documentCannotDeleted: {
        id: 'document.component.document-component.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    }
});
