import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    ActivitiesService, UserService
} from 'gw-capability-gateway';
import { WniRewriteService } from 'wni-capability-gateway';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import metadata from './Rewrite.metadata.json5';
import CommonRoutes from '../Common/CommonRouting';
import pageRouting from '../Common/Rewrite-config.json5';

function Rewrite(props) {
    const {
        match: {
            url,
            params: { jobNumber }
        },
        location: {
            pathname,
            state: locationState
        }
    } = props;

    const { authHeader } = useAuthentication();
    const [currentView, updateCurrentView] = useState('summary');
    const [tilesCountSubmissionData, updateTilesCountSubmissionData] = useState({});
    const [quotes, updateQuotes] = useState({});
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const filterActivityWithAgency = (availableActivities, producerCode) => {
        const tempList = [];
        availableActivities.forEach((activity) => {
            const obj = _.find(producerCode, (item) => {
                if (!_.isEmpty(_.get(activity, 'assignedTo.producerCodes_Ext', []))) {
                    const assignedProducers = _.get(activity, 'assignedTo.producerCodes_Ext', []);
                    const hasMatched = _.find(assignedProducers, (assignedItem) => {
                        return assignedItem.code === item.code;
                    });
                    if (hasMatched) {
                        return true;
                    }
                }
                return false;
            });
            if (obj) {
                tempList.push(activity);
            }
        });
        return tempList;
    };

    const getActivities = useCallback(
        async () => {
            const producerCode = await UserService
                .getAvailableProducerCodesForCurrentUser(authHeader);
            const getAvailableActivities = await ActivitiesService.getActivitiesForJob(
                jobNumber,
                authHeader
            );
            const activities = filterActivityWithAgency(getAvailableActivities, producerCode);

            return activities;
        },
        [authHeader, jobNumber],
    );

    const getQuoteSummaryCount = useCallback(
        async () => {
            const routePath = pathname && (pathname.trim().split('/') || []);
            const selectedPageFromPath = routePath[routePath.length - 1];
            const activeTile = selectedPageFromPath || 'summary';

            const submissionSummary = await WniRewriteService.getRewriteSummary([jobNumber],
                authHeader);
            const attachments = await WniRewriteService
                .getPortalAttachmentsForRewrite([jobNumber], authHeader);
            submissionSummary.numberOfDocuments = attachments.length;
            submissionSummary.numberOfDocuments = attachments.length;
            const openActivities = await getActivities();
            submissionSummary.numberOfOpenActivities = openActivities.length;
            updateCurrentView(activeTile);
            updateTilesCountSubmissionData(submissionSummary);
        },
        [authHeader, jobNumber, pathname, getActivities],
    );

    const updateQuoteSummary = (newQuotes) => {
        updateQuotes(newQuotes);
    };

    const getJobByJobNumber = useCallback(
        async () => {
            // const submission = await JobService.findJobByJobNumber(jobNumber, authHeader);
            // from PAQuotePage::markAsNotTaken
            let submission = _.get(locationState, 'submissionDTO');
            if (!submission) {
                submission = await WniRewriteService.findJobByJobNumber([jobNumber], authHeader);
            }
            updateQuoteSummary(submission);
        }, [authHeader, locationState, jobNumber]
    );
    const fetchData = async() => {
        setLoadingMask(true);
        await getQuoteSummaryCount();
        await getJobByJobNumber();
        setLoadingMask(false);
    };
    useEffect(() => {
        fetchData()
    }, []);

    const handleTilesOnClick = (id) => {
        updateCurrentView(id);
    };

    // ==============================================================
    const renderContent = () => {
        if (_.isEmpty(quotes)) {
            return null;
        }

        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: false
            },
            notes: {
                visible: false
            }
        };
        const quoteVM = { quotes, tilesCountSubmissionData };
        const resolvers = {
            // resolveClassNameMap: styles,
            resolveCallbackMap: {
                handleTilesOnClick: handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };
        const RewriteDetailspage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={quoteVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );

        const rewriteData = {
            jobNumber: jobNumber,
            loadRewriteSummary: quotes,
            updateJobSummary: updateQuoteSummary,
            getQuoteSummaryCount: getQuoteSummaryCount,
            getActivities: getActivities
        };
        return (
            <>
                {RewriteDetailspage}
                <CommonRoutes
                    steps={pageRouting}
                    basePath={url}
                    // keep this name quoteDetailsData
                    // In current version it means transactionDetailsData, and this props used by common components
                    quoteDetailsData={rewriteData}
                />
            </>
        );
    };

    return renderContent();
}

Rewrite.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            jobNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.object,
    }).isRequired
};

export default withRouter(Rewrite);
