import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect } from 'react';
import { CRLineCoveragesService } from 'wni-capability-quoteandbind-cr';
import messages from './AdditionalCoveragesCard.messages';
import CoverageContext from '../../../../context/CRCoverageContext';
import CRClausesComponentVM from '../../../../components/CRClausesComponentVM';
import CRSingleClauseComponentVM from '../../../../components/CRSingleClauseComponentVM';
import CRCoverageUtil from '../../../../util/CRCoverageUtil';
import AccordionCardTitle from '../../../../components/CRClauseAccordionCardTitle';



const AdditionalCoveragesCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        lineCoverages,
        setLineCoverages,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props;


    const translator = useTranslator();

    const validationID = 'additionalCoveragesCard';
    const clauseType = 'additionalCoverages';
    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    const {
        additionalCoverage
    } = lineCoverages

    const changeSubmission = (value, changedPath) => {
            const newClauses = CRCoverageUtil.setClauseValue(additionalCoverage, 'additionalCoverage', value, changedPath);
            const newLineCoverages = _.clone(lineCoverages)
            _.set(newLineCoverages, 'additionalCoverage', newClauses);
            setLineCoverages(newLineCoverages);
            setIsEditing(false);
            return newLineCoverages;
        }

    const updateVMToServer = async (newCoveragePartClauses, setEditingFunc) => {
        const lineCoveragesToUpdate = CRCoverageUtil.generateUpdatedCRLineClausesDTO(newCoveragePartClauses);
        const lineCoveragesFromServer = await withLoadingMask(() => CRLineCoveragesService.updateLineCoverages(
            jobID,
            sessionUUID,
            lineCoveragesToUpdate,
            authHeader
        ))
        setEditingFunc(false)
        setLineCoverages(lineCoveragesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }
    const updateNewCoveragePartClausesToServer = async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }

    const changeClauseAndSync = (value, changedPath) => {
            const newCoveragePartClauses = changeSubmission(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        }

    const onSyncCoverages = () => {
            updateNewCoveragePartClausesToServer(lineCoverages);
        }

    return (
        <AccordionCard
            id="CoveragesCard"
            initialExpanded
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.AdditionalCoverages)}
                    actionText={messages.AddAdditionalCoverages}
                    onClick={() => onClickAddSearchClauses(clauseType)}
                /> : translator(messages.AdditionalCoverages)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: lineCoverages,
                        updateClausesContainer: setLineCoverages,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <CRClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeClauseAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        path="additionalCoverage"
                        value={additionalCoverage}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CRSingleClauseComponentVM: CRSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default AdditionalCoveragesCard;
