import React, {
    useCallback,
    useState,
    useMemo,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { WCCoveragesService } from 'wni-capability-quoteandbind-wc';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WizardPage from '../../templates/WCWizardPage';
import WCCoverageUtil from './util/WCCoverageUtil';

import WCLineDetails from './CoveragesSection/WCLineDetails/WCLineDetails';
import WCLineCoverages from './CoveragesSection/WCLineCoverages/WCLineCoverages';
import WCLineAdditionalCoverages from './CoveragesSection/WCLineAdditionalCoverages/WCLineAdditionalCoverages'
import WCLineExclusions from './CoveragesSection/WCLineExclusions/WCLineExclusions'
import WCLineConditions from './CoveragesSection/WCLineConditions/WCLineConditions'

import styles from './WCCoveragesPage.module.scss';
import metadata from './WCCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/WCCoveragesConfigContext';
import WCCoveragesConfig from './WCCoverages.config';

import WCCoveargesPageMain from './WCCoveragesPage.main';

function WCCoveragesPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const lobName = 'workersComp'

    const {
        jobID,
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
            // baseState_Ext: baseState,
            // policyContactRoles_Ext: policyContactRoles
        },
        lobData: {
            [lobName]: {
                offerings,
                // coverables: {
                //     vehicles,
                // }
            }
        },
    } = submissionVM.value;

    // const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        // registerComponentValidation,
    } = useValidation('WCCoveragesPage');


    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [validationIssues, updateValidationIssues] = useState([])
    const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);


    const pageLevelValidation = useMemo(
        () => validationIssues
            // .concat(errMsgsInvalidRTVehicle)
            ,
        [
            // errMsgsInvalidRTVehicle,
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM;
    }, []);

    const onPageNext = async () => {
        // Call update coverages service to save coverages not sync and check errors and warnings
        const coverages = _.get(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = WCCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = WCCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName);
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await WCCoveragesService.onPageNext(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        setLoadingLineCoverages(false);
        const lobCoverages = _.get(response, `lobCoverages.${lobName}`);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        const officialIDs = _.get(response, 'officialIDs');
        _.set(submissionVM.value, `lobData.${lobName}.coverables.stateSpecificInfo.officialIDs`, officialIDs);
        _.set(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        return newSubmissionVM;
    }

    const handleValidation = () => {
        setShowErrors(true)
        return false
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={isEditing}
            disablePrevious={isEditing}
            disableCancel={isEditing}
            onNext={isComponentValid ? onPageNext : handleValidation}
            alwaysCallOnNext
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            <WCCoveargesPageMain {...props}
                //
                coveragesPageProps = {{
                    onValidate,
                    updateErrorsAndWarningsForCovComponent,
                    //
                    showErrors,
                    //
                    setIsEditing,
                    isEditing,
                    //
                    setLoadingLineCoverages,
                    loadingLineCoverages,
                }}
            />
            
        </WizardPage>
    );
}

WCCoveragesPage.propTypes = WizardPage.propTypes;
export default WCCoveragesPage;
