import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages',
    },
    viewAndEditLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle-coverages.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle-coverages.View',
        defaultMessage: 'View',
    },
    cancel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle-coverages.Cancel',
        defaultMessage: 'Cancel',
    },
    next: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle-coverages.Save & Next Vehicle',
        defaultMessage: 'Save & Next Vehicle',
    },
    close: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle-coverages.Save & Close',
        defaultMessage: 'Save & Close',
    },
});
