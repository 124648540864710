import { wizardProps } from '@xengage/gw-portals-wizard-react';

import { FNOLCriminalDamagePage as HOCriminalDamagePage } from 'wni-capability-fnol-ho-react';

import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLDPCriminalDamagePage(props) {

    return <HOCriminalDamagePage {...props} />;
    
}

FNOLDPCriminalDamagePage.propTypes = wizardProps;
export default withRouter(FNOLDPCriminalDamagePage);
