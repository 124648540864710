import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('policyANI'), method, data, additionalHeaders);
}

export default class WniANIService {
    static saveAddtionalInsured(quoteID, sessionUUID, addlInsured, authHeader) {
        return processSubmission('saveAddtionalInsured', [quoteID, sessionUUID, addlInsured], authHeader);
    }

    static removeAddtionalInsureds(quoteID, sessionUUID, publicIDs, authHeader) {
        return processSubmission('removeAddtionalInsureds', [quoteID, sessionUUID, publicIDs], authHeader);
    }

    static searchAddressBook(quoteID, sessionUUID, searchCriteria, authHeader) {
        return processSubmission('searchAddressBook', [quoteID, sessionUUID, searchCriteria], authHeader);
    }

    static saveExsitingContactForANI(quoteID, sessionUUID, publicIDs, authHeader) {
        return processSubmission('saveExsitingContactForANI', [quoteID, sessionUUID, publicIDs], authHeader);
    }
}
