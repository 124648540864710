import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('walPolicyInfoService'), method, data, additionalHeaders);
}

export default class WALPolicyInfoService {

    static saveWALPolicyInfoData(quoteID, sessionUUID, questionSetData, quoteBaseData, policyInfoData, authHeader) {
        return processSubmission('saveWALPolicyInfoData', [quoteID, sessionUUID, questionSetData, quoteBaseData, policyInfoData], authHeader);
    }
}
