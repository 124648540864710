import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imMuseum'), method, data, additionalHeaders);
}

export default class IMMuseumService {

    /**
     * Invokes IMMuseumHandler.getMuseumClausesWithSync()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getMuseumClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMuseumClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.getMuseumClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getMuseumClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMuseumClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.updateMuseumClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} accountsReceivableToUpdate
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateMuseumClauses(jobNumber, sessionUUID, museumToUpdate, authHeader = {}) {
        return processSubmission('updateMuseumClauses', [jobNumber, sessionUUID, museumToUpdate], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.updateCoveragePartSchedule()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.onMuseumNext()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static onMuseumNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onMuseumNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.searchClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.addSearchedClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} clausesToAddData
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMMuseumHandler.calculateLimit()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static calculateLimit(jobID, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobID, sessionUUID, termCode], authHeader);
    }
}
