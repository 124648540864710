import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caRiskAnalysisService'), method, data, additionalHeaders);
}

export default class CARiskAnalysisService {
  
    /**
     * Invokes CARiskAnalysisHandler.saveNonLossType
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveNonLossType(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('saveNonLossType', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CARiskAnalysisHandler.saveAttachedLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveAttachedLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('saveAttachedLoss', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CARiskAnalysisHandler.saveManualLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveManualLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('saveManualLoss', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CARiskAnalysisHandler.updateManualLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateManualLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('updateManualLoss', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CARiskAnalysisHandler.deleteManualLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static deleteManualLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('deleteManualLoss', [quoteID, sessionUUID, newModel], authHeader);
    }
}