import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUnderwritingIssuesList,
    EligibilityIssuesLinkComponent,
    ValidationIssuesComponent
} from 'wni-components-platform-react';
import {
    ErrorsAndWarningsUtil,
    SxsUtil,
    UWIssueUtil,
} from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import {
    ReferToUnderwriterComponent,
    AccountClearanceLinkComponent,
    // RecommendCoveragesComponent,
    SideBySidePeriodInfoStyles as styles,
} from 'wni-capability-gateway-react';

import { QuoteSideBySidePeriodMessages as messages } from 'wni-platform-translations';
// import messages from './PUSideBySidePeriodInfo.messages';

import QuotePageConstants from '../config/PUQuotePage.config';

import PURecommendCoveragesComponent from './RecommendCov/PURecommendCoveragesComponent';

import metadata from './PUSideBySidePeriodInfo.metadata.json5';
// import styles from './PUSideBySidePeriodInfo.module.scss';

const { LOB_PACKAGE_NAME } = QuotePageConstants;

const INELIGIBLE_UW_ISSUE_CODES = ['PUIneligibleForSignatureAndSelect_Ext'];
const SXS_DTO_NAME = `wni.edge.capabilities.quote.sxs.lob.${LOB_PACKAGE_NAME}.dto.PUSideBySidePeriodInfoDTO`;

function PUSideBySidePeriodInfo(props) {
    const {
        model: sxsPeriodDto,
        // overrideProps,
        // periodStatus,
        // submissionVM,
        // updateSubmissionVM,
        underwritingIssues,
        eligibilityIssues,
        isEditable,
        isClueCompare,
        isMvrCompare,
        isNewAccount,
        currentAccountNumber,
        // quoteID,
        // sessionUUID,
        // isReferUnWriter,
        isServiceCallInProgress: isLoading,
        showVersionNumber,
        // paymentPlanType,
        isMonthlyPayment,
        // coveragesForm,
        // isRunFinalValidation,
        // isContinueWithQuote,
        // isValidation,
        callbackMap: {
            onFinishQuote,
            onSubmitQuote,
            onReferToUnderwriter,
            onContinueToIssue,
            goToIncidentReports,
            onRateAll: onGetUpdatedRate,
            // onRunReservation,
            onAccountClearance,
            postAccountMerge,
            //
            // goToDriverCoveragesPage,
            goToCoveragesPage,
            //
            onCallVirtualInspectionReport,
            goToQuoteDetailsPage,
        },
        showAccountClearanceLink
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const {
        publicID,
        periodStatus,
        policyType_Ext: policyType,
        branchName,
        // branchCode,
        quoteFlow_Ext: quoteFlow,
        totalPremiumRPT,
        // isResolveNeeded,
        validationIssues,
        coverageIssues,
        // nonFullPayAmount_Ext: monthlyPayTotal,
        // fullPayAmount_Ext: fullPayTotal,
        columnIndex,
        totalCostRPT,
    } = sxsPeriodDto;
 
    const sxsPeriodDtoVM = viewModelService.create(sxsPeriodDto, 'pc', SXS_DTO_NAME);
    const translator = useTranslator();

    const [isCompareViolationDiff, setCompareViolationDiff] = useState(false);
    const [isComparePriorLossDiff, setComparePriorLossDiff] = useState(false);
    const [isCompareAllDiff, setCompareAllDiff] = useState(false);
    // const [isLoading, updateIsLoading] = useState(false);

    const hasBlockingUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(
        { underwritingIssues: underwritingIssues }
    );
    const sxsPeriodValidationIssues = ErrorsAndWarningsUtil.getValidationIssues({ validationIssues });
    const sxsPeriodValiationErrors = sxsPeriodValidationIssues.filter((issue) => issue.type === 'error');

    // const isMonthlyPayment = paymentPlanType === 'monthly';
    const isDraftPeriod = periodStatus === 'Draft';
    const isFinishOrSubmitFlow = quoteFlow === 'finishquote' || quoteFlow === 'submitquote';
    // const isReadyForIssue = quoteFlow === 'submitquote' && periodStatus === 'Quoted';
    // another option: check sxsPeriodDto.canIssue instead
    const isReadyForIssue = SxsUtil.isQuotedOrApproved(periodStatus) && isFinishOrSubmitFlow;
    const hasIneligibleUwIssue = UWIssueUtil.hasUnApprovedUWIssueByTypeCode(underwritingIssues, INELIGIBLE_UW_ISSUE_CODES);

    //
    // // let showApprovedVirtualInspectionUWIssue = false;
    // if (isEditable && quoteFlow === 'submitquote' && periodStatus === 'Quoted') {
    //     // const hasUnApprovedVirtualInspectionUWIssue = UWIssueUtil.hasUnApprovedUWIssueByTypeCode(underwritingIssues, VIRTUAL_INSPECTION_UW_ISSUE_CODES);
    //     // showApprovedVirtualInspectionUWIssue = hasUnApprovedVirtualInspectionUWIssue;
    // }

    useEffect(() => {
        if (isClueCompare && isMvrCompare) {
            setCompareAllDiff(true);
            setComparePriorLossDiff(false);
            setCompareViolationDiff(false);
        } else if (isClueCompare) {
            setCompareAllDiff(false);
            setComparePriorLossDiff(true);
        } else if (isMvrCompare) {
            setCompareAllDiff(false);
            setCompareViolationDiff(true);
        }
    }, [isClueCompare, isMvrCompare]);

    const getButtonsVisibility = useCallback(() => {
        if (!isEditable) {
            return {
                showContinueWithQuoteBtn: false,
                showRunFinalValidationBtn: false,
                showReferToUWBtn: false,
                showContinueToIssueBtn: false,
                showGetUpdatedRateBtn: false,
            };
        }
        const showContinueWithQuoteBtn = quoteFlow === 'firstquote' && periodStatus === 'Rated';
        const showRunFinalValidationBtn = isFinishOrSubmitFlow && periodStatus === 'Rated';
        const showReferToUWBtn = hasBlockingUWIssue && // !showApprovedVirtualInspectionUWIssue && 
            !(periodStatus === 'Draft' || showContinueWithQuoteBtn || showRunFinalValidationBtn);
        const showContinueToIssueBtn = !hasBlockingUWIssue && isReadyForIssue;
        const showGetUpdatedRateBtn = isDraftPeriod;
        return {
            showContinueWithQuoteBtn,
            showRunFinalValidationBtn,
            showReferToUWBtn,
            showContinueToIssueBtn,
            showGetUpdatedRateBtn,
        };
    }, [isEditable, quoteFlow, hasBlockingUWIssue, periodStatus]);

    const getPremim = () => {
        if (isDraftPeriod) {
            return null;
        }
        // return isMonthlyPayment ? monthlyPayTotal : fullPayTotal;
        return totalCostRPT;
    };

    const uwNoteProcessor = useCallback((note) => {
        const userNote = note || '';
        // const normalizedBranchName = branchName === 'CUSTOM' ? '' : branchName;
        // 'CUSTOM' branch name will be eliminated in WniSubmissionPlugin
        const normalizedBranchName = branchName;
        // return `${userNote} \n\n ${policyType} ${normalizedBranchName} referred`;
        return `${userNote} \n\n ${normalizedBranchName} referred`;
    }, [branchName]);

    //-------------------------------------
    const {
        showContinueWithQuoteBtn,
        showRunFinalValidationBtn,
        showReferToUWBtn,
        showContinueToIssueBtn,
        showGetUpdatedRateBtn,
    } = getButtonsVisibility();

    const DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES = [
        'UMBEffectiveDateGreaterThan35DaysInTheFuture_Ext'
    ];

    const disableBtnWithIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
        underwritingIssues, DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        periodStatus: {
            content: periodStatus,
        },
        policyType: {
            // value: `${policyType} Watercraft`,
            value: `Personal Umbrella`,
        },
        branchName: {
            visible: showVersionNumber,
        },
        // coverageForm: {
        //     value: coveragesForm.toUpperCase(),
        // },
        // policyPenaltyFree: {
        //     visible: policyType === 'Signature',
        // },
        premium: {
            value: getPremim(),
        },
        // premiumFullDiscount: {
        //     content: isMonthlyPayment ? messages.withoutPayFullDiscount : messages.withPayFullDiscount,
        // },
        // validationIssuesToResolve: {
        //     // visible: isResolveNeeded,
        //     visible: !_.isEmpty(sxsPeriodValiationErrors),
        //     issues: sxsPeriodValiationErrors,
        // },
        // resolveUWIssuesNotification: {
        //     visible: showReferToUWBtn,
        // },
        quoteUWIssues: {
            underwritingIssues,
            headerObject: messages.IssuesRequiringReferral,
            // issueDescFormatter: (uwIssue) => uwIssue.longDescription,
        },
        eligibilityIssuesLink: {
            // visible: false,
            visible: !_.isEmpty(eligibilityIssues), //  && hasIneligibleUwIssue,
            policyType: 'Personal Umbrella', // `${policyType} Home`,
            eligibilityIssues,
            errorMode: hasBlockingUWIssue,
        },
        accountClearanceLink: {
            // visible: (showReferToUWBtn || showContinueToIssueBtn) && isNewAccount,
            visible: !!showAccountClearanceLink,
            onAccountClearance: async () => onAccountClearance(publicID),
            postAccountMerge,
            currentAccountNumber: currentAccountNumber
        },
        recommendedCoveragesContainer: {
            visible: isEditable && !_.isEmpty(coverageIssues),
            // periodPublicID: publicID,
            columnIndex,
            coverageIssues,
            // goToDriverCoveragesPage,
            goToCoveragesPage,
        },
        // runReservationBtn: {
        //     renderContent: renderHelper.getButtonRenderFunc(isLoading),
        // },
        continueWithQuoteBtn: {
            visible: showContinueWithQuoteBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.continueWithQuote), isLoading),
        },
        runFinalValidationBtn: {
            visible: showRunFinalValidationBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.runLossReport), isLoading),
        },
        referToUWBtn: {
            visible: showReferToUWBtn,
            onReferToUnderwriter: async (noteForUW) => onReferToUnderwriter(noteForUW, publicID),
            isServiceCallInProgress: isLoading,
            referToUWButtonEnabled: !disableBtnWithIneligibleUwIssue,
            noteProcessor: uwNoteProcessor,
        },
        continueToIssueBtn: {
            visible: showContinueToIssueBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.continueToIssue), isLoading),
        },
        getUpdatedRateBtn: {
            visible: showGetUpdatedRateBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.getUpdatedRate), isLoading),
        },
        //
        // virtualInspectionNotification: {
        //     onVirtualInspection: onCallVirtualInspectionReport,
        //     onCancelVirtualInspection: goToQuoteDetailsPage,
        //     // isServiceCallInProgress,
        //     visible: showApprovedVirtualInspectionUWIssue,
        // },
        //
        compareReviewViolationLink: {
            // visible: isEditable && quoteFlow === 'submitquote' && isCompareViolationDiff,
            visible: isEditable && isFinishOrSubmitFlow && isCompareViolationDiff,
        },
        compareReviewLossLink: {
            // visible: isEditable && quoteFlow === 'submitquote' && isComparePriorLossDiff,
            visible: isEditable && isFinishOrSubmitFlow && isComparePriorLossDiff,
        },
        compareReviewAllLink: {
            // visible: isEditable && quoteFlow === 'submitquote' && isCompareAllDiff,
            visible: isEditable && isFinishOrSubmitFlow && isCompareAllDiff,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onFinishQuote: async () => onFinishQuote(publicID),
            onSubmitQuote: async () => onSubmitQuote(publicID),
            onContinueToIssue: async () => onContinueToIssue(publicID),
            goToIncidentReports,
            onGetUpdatedRate,
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            refertouw: ReferToUnderwriterComponent,
            eligibilitydetails: EligibilityIssuesLinkComponent,
            accountclearancelink: AccountClearanceLinkComponent,
            recommendedCoverages: PURecommendCoveragesComponent,
            validationissuescomponent: ValidationIssuesComponent,
            // virtualinspection: VirtualInspectionNotificationComponent,
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            sxsPeriodDtoVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sxsPeriodDtoVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            // onValueChange={writeValue}
            // onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PUSideBySidePeriodInfo.propTypes = {
    model: PropTypes.shape({}).isRequired,
    underwritingIssues: PropTypes.arrayOf(PropTypes.object),
    eligibilityIssues: PropTypes.arrayOf(PropTypes.string),
    // overrideProps: PropTypes.shape({}),
    // periodStatus: PropTypes.string
    isEditable: PropTypes.bool,
    isClueCompare: PropTypes.bool,
    isMvrCompare: PropTypes.bool,
    isNewAccount: PropTypes.bool,
    currentAccountNumber: PropTypes.string,
    // quoteID: PropTypes.string,
    // sessionUUID: PropTypes.string,
    // paymentPlanType: PropTypes.string,
    isMonthlyPayment: PropTypes.bool,
    // isReferUnWriter: PropTypes.bool,
    isServiceCallInProgress: PropTypes.bool,
    showVersionNumber: PropTypes.bool,
    // isRunFinalValidation: PropTypes.bool,
    // isContinueWithQuote: PropTypes.bool,
    // isValidation: PropTypes.string,
    callbackMap: PropTypes.shape({
        onFinishQuote: PropTypes.func,
        onSubmitQuote: PropTypes.func,
        onReferToUnderwriter: PropTypes.func,
        onContinueToIssue: PropTypes.func,
        goToIncidentReports: PropTypes.func,
        onRateAll: PropTypes.func,
        // onRunReservation: PropTypes.func
        onAccountClearance: PropTypes.func,
        postAccountMerge: PropTypes.func,
        goToDriverCoveragesPage: PropTypes.func,
    })
};

PUSideBySidePeriodInfo.defaultProps = {
    // overrideProps: {},
    // periodStatus: undefined
    underwritingIssues: [],
    eligibilityIssues: [],
    isEditable: true,
    isClueCompare: false,
    isMvrCompare: false,
    isNewAccount: false,
    currentAccountNumber: '',
    // quoteID: undefined,
    // sessionUUID: undefined,
    // isReferUnWriter: true,
    // paymentPlanType: 'annually',
    isMonthlyPayment: false,
    isServiceCallInProgress: false,
    showVersionNumber: false,
    // isRunFinalValidation: false,
    // isContinueWithQuote: true,
    // isValidation: 'default',
    callbackMap: {},
};

export default PUSideBySidePeriodInfo;