
import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { CACoveragesService } from 'wni-capability-quoteandbind-ca';
import { useTranslator } from '@jutro/locale';
import { PortalConstants } from 'wni-portals-config-js';
import {
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import CAStateSpecificInfoUtil from '../../util/CAStateSpecificInfoUtil'
import CAStateSpecificInfoDetail from '../StateSpecificInfoDetail/CAStateSpecificInfoDetail';

import metadata from './CAStateSpecificInfoComponent.metadata.json5';
import CAStateStandardCoverages from '../CAStateStandardCoverages/CAStateStandardCoverages';
import CAHiredAutoStandardCoverages from '../CAHiredAutoStandardCoverages/CAHiredAutoStandardCoverages';
import CANonOwnedStandardCoverages from '../CANonOwnedStandardCoverages/CANonOwnedStandardCoverages';
import CAStateAdditionalCoverages from '../CAStateAdditionalCoverages/CAStateAdditionalCoverages';
import CAHiredAutoAdditionalCoverages from '../CAHiredAutoAdditionalCoverages/CAHiredAutoAdditionalCoverages';
import CANonOwnedAdditionalCoverages from '../CANonOwnedAdditionalCoverages/CANonOwnedAdditionalCoverages';
import CAStateExclusions from '../CAStateExclusions/CAStateExclusions';
import CAStateConditions from '../CAStateConditions/CAStateConditions';
import CAHiredAutoExclusions from '../CAHiredAutoExclusions/CAHiredAutoExclusions';
import CAHiredAutoConditions from '../CAHiredAutoConditions/CAHiredAutoConditions';
import CANonOwnedExclusions from '../CANonOwnedExclusions/CANonOwnedExclusions';
import CANonOwnedConditions from '../CANonOwnedConditions/CANonOwnedConditions';

function CAStateSpecificInfoComponent(props) {
    const {
        submissionVM,
        updateWizardData,
        stateSpecificVM,
        onValidate,
        onValueChange,
        syncWizardData,
        syncWizardDataSnapshot,
        updateSubmissionVMForResponse,
        showErrors,
        accordionStates,
        isReadOnly,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            commercialAuto: {
                offerings,
            }
        },
    } = submissionVM.value;
    
    const translator = useTranslator();

    const [openCoveragesAccordions, setOpenCoveragesAccordions] = useState([
        'stateSpecificCoverages',
        'stateSpecificAdditionalCoverages',
    ])
    const [validationIssues, updateValidationIssues] = useState([])
    const [loadingClauses, setLoadingClauses] = useState(false);
    const [isEditing, setIsEditing] = useState(false)

    const getAccordionStatesOverrides = useCallback(() => {
        return CAStateSpecificInfoUtil.getAccordionOverridesForStateInfo(stateSpecificVM);
    }, [stateSpecificVM]);

    const defaultOpenedId = useCallback(() => {
        const errorStateObj = CAStateSpecificInfoUtil
            .getAccordionOverridesForStateInfo(stateSpecificVM);
        const entries = Object.entries(errorStateObj);
        const openedArr = entries.find((entry) => entry[1].errorState);
        const openedId = _.get(openedArr, '0') || _.get(entries, '0.0');

        return openedId;
    }, [stateSpecificVM]);

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const lobName = 'commercialAuto'
    
    const selectedState = _.get(stateSpecificVM.value, 'jurisdiction')

    const stateCoveragesIndex = _.get(selectedVersion, 'coverages.stateSpecificCoverages', [])
        .findIndex((stateSpecificCoverages) => stateSpecificCoverages.state === selectedState)

    const stateCoverages = _.get(selectedVersion, `coverages.stateSpecificCoverages[${stateCoveragesIndex}]`)

    const {
        stateExclusions = [],
        stateConditions = [],
        hiredAutoExclusions = [],
        hiredAutoConditions = [],
        nonOwnedAutoExclusions = [],
        nonOwnedAutoConditions = [],
    } = stateCoverages

    const hasExclusionsOrConditions = stateExclusions
        .concat(stateConditions)
        .concat(hiredAutoExclusions)
        .concat(hiredAutoConditions)
        .concat(nonOwnedAutoExclusions)
        .concat(nonOwnedAutoConditions).length > 0

    const commonCoverageComponentProps = {
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        updateWizardData: updateWizardDataToDraft,
        onValidate,
        showErrors,
        updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService: CACoveragesService,
        loadingClauses,
        setLoadingClauses,
        stateCoveragesIndex,
        isEditable: !isReadOnly
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            readOnly: isReadOnly,
            showRequired: true
        },
        stateSpecificAccordion: {
            // defaultOpenedId: 'stateSpecificDetail',
            // accordionStates
        },
        stateSpecificDetailsSection: {
            stateSpecificVM,
            onValidate,
            onValueChange,
            syncWizardData,
            syncWizardDataSnapshot,
            updateSubmissionVMForResponse,
            showErrors,
            isReadOnly,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        },
        stateSpecificCoveragesAccordion: {
            accordionStates: openCoveragesAccordions,
            onUpdateAccordionStates: (ids) => setOpenCoveragesAccordions(ids),
        },
        stateSpecificExclusionsAndConditions: {
            visible: hasExclusionsOrConditions,
        },
        caStateStandardCoverages: {
            ...commonCoverageComponentProps,
        },
        caHiredAutoStandardCoverages: {
            ...commonCoverageComponentProps,
        },
        caNonOwnedAutoStandardCoverages: {
            ...commonCoverageComponentProps,
        },
        caStateAdditionalCoverages: {
            ...commonCoverageComponentProps,
        },
        caHiredAutoAdditionalCoverages: {
            ...commonCoverageComponentProps,
        },
        caNonOwnedAutoAdditionalCoverages: {
            ...commonCoverageComponentProps,
        },
        caStateExclusions: {
            ...commonCoverageComponentProps,
            visible: stateExclusions.length > 0,
        },
        caStateConditions: {
            ...commonCoverageComponentProps,
            visible: stateConditions.length > 0,
        },
        caHiredAutoExclusions: {
            ...commonCoverageComponentProps,
            visible: hiredAutoExclusions.length > 0,
        },
        caHiredAutoConditions: {
            ...commonCoverageComponentProps,
            visible: hiredAutoConditions.length > 0
        },
        caNonOwnedExclusions: {
            ...commonCoverageComponentProps,
            visible: nonOwnedAutoExclusions.length > 0
        },
        caNonOwnedConditions: {
            ...commonCoverageComponentProps,
            visible: nonOwnedAutoConditions.length > 0
        },
        ...getAccordionStatesOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            stateSpecificdetails: CAStateSpecificInfoDetail,
            CAStateStandardCoverages: CAStateStandardCoverages,
            CAHiredAutoStandardCoverages: CAHiredAutoStandardCoverages,
            CANonOwnedStandardCoverages: CANonOwnedStandardCoverages,
            CAStateAdditionalCoverages: CAStateAdditionalCoverages,
            CAHiredAutoAdditionalCoverages: CAHiredAutoAdditionalCoverages,
            CANonOwnedAdditionalCoverages: CANonOwnedAdditionalCoverages,
            CAStateExclusions: CAStateExclusions,
            CAStateConditions: CAStateConditions,
            CAHiredAutoExclusions: CAHiredAutoExclusions,
            CAHiredAutoConditions: CAHiredAutoConditions,
            CANonOwnedExclusions: CANonOwnedExclusions,
            CANonOwnedConditions: CANonOwnedConditions,
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            stateSpecificVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={stateSpecificVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

export default CAStateSpecificInfoComponent;
