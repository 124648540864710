
import React from 'react';


import { CUUnderlyingReadOnlyPage } from 'wni-capability-quoteandbind-cu-react';

function CUUnderlyingChangeReadOnlyPage(props) {

    return (
        <CUUnderlyingReadOnlyPage {...props} />
    );

};

export default CUUnderlyingChangeReadOnlyPage;