import _ from 'lodash';



/**
 * QuestionSet Mapper for HoHouseholdInfo
 *
 * See QuestionSetsParser.contentMapper
 * 
 * @returns {Function}
 */
function getQSMapperForDpHousehold() {
    return (metadataContent) => {
        if (metadataContent.id.startsWith('explainFor')) {
            _.set(metadataContent, 'componentProps.className', 'indentSection');
        }
        if (metadataContent.id === 'hasAnyApplicant_Ext'){
            _.set(metadataContent,  'componentProps.label', 
            {
                defaultMessage: 'Has any applicant had Home or Dwelling coverage declined, cancelled, or non-renewed during the last three (3) years?',
                id: 'wni.quoteandbind.dp-householdinfo.UWQ hasAnyApplicant_Ext',
            })
        }
        else if (metadataContent.id === "hasAnyApplicantForeclosure_Ext") {
            _.set(metadataContent,  'componentProps.label', 
            {
                defaultMessage: 'Has any applicant had a foreclosure, repossession, bankruptcy, judgement, or lien during the last five (5) years?',
                id: 'wni.quoteandbind.dp-householdinfo.UWQ hasAnyApplicantForeclosure_Ext',
            })
        }else if (metadataContent.id === "hasThereBeenLapse_Ext") {
            _.set(metadataContent,  'componentProps.label', 
            {
                defaultMessage: 'Has this dwelling been continuously covered by a property insurance policy for the last five (5) years?',
                id: 'wni.quoteandbind.dp-householdinfo.UWQ hasThereBeenLapse_Ext',
            })
        }

        return metadataContent;
    };
}

function getQSFilterForDpHousehold() {

    return (metadataContent) => {
        const metadataContentId = metadataContent.id
        switch (metadataContentId) {
            case 'isThereExistingDPPolicy_Ext':
                return false
            case 'product_Ext':
                return false
            case 'isThisTransfer_Ext':
                return false
            default:
                return true
        }
    };
}
export default {

    getQSMapperForDpHousehold,
    getQSFilterForDpHousehold

};
