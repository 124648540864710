import { defineMessages } from 'react-intl';

export default defineMessages({
    resolveErrors: {
        id: 'quoteandbind.views.quote-details.Resolve Errors',
        defaultMessage: 'Resolve Errors'
    },
    referralSubmitted: {
        id: 'quoteandbind.views.quote-details.The referral has been submitted. Please wait on the Underwriter&quot;s response.',
        defaultMessage: 'The referral has been submitted. Please wait on the Underwriter\'s response.'
    },
    backToAccountSummary: {
        id: 'wni.quoteandbind.wizard.Quote.quote.Back to Account Summary',
        defaultMessage: 'Back to Account Summary',
    },
    // ========================
    deleteThisQuoteVersion: {
        id: 'wni.quoteandbind.common-wizard.QuotPage.Delete this Quote Version',
        defaultMessage: 'Delete this Quote Version',
    },
    deleteThisQuoteVersionConfirmMessage: {
        id: 'wni.quoteandbind.common-wizard.QuotPage.Are you sure want to delete this Quote Version?',
        defaultMessage: 'Are you sure want to delete this Quote Version?',
    },
    deleteThisQuoteVersionConfirmConfirm: {
        id: 'wni.quoteandbind.common-wizard.QuotPage.Confirm',
        defaultMessage: 'Confirm',
    },
    // =======================
    // from PAQuotePage.messages
    submitTheApplication: {
        id: 'wni.quoteandbind.views.quote-details.submit the application',
        defaultMessage: 'In order to submit the application, the following information must first be resolved:'
    },
    reviewWarningMessages: {
        id: 'wni.quoteandbind.views.quote-details.Review the following item(s) before submitting:',
        defaultMessage: 'Review the following item(s) before submitting:'
    },
    // Same with the corresponding messages from PolicyChange.Common.messages
    addVersions: {
        id: 'custom.component.common-wizard.AddNewQuoteVersionComponent.Add Versions',
        defaultMessage: 'Add Versions',
    },
    // Now it is becoming ridiculous
    addVersion: {
        id: 'custom.component.common-wizard.AddNewQuoteVersionComponent.Add Version',
        defaultMessage: 'Add Version',
    },
    //
    createNewVersionOfThisQuote: {
        id: 'custom.component.common-wizard.AddNewQuoteVersionComponent.Create another version of this quote',
        defaultMessage: 'Create another version of this quote',
    }, 
    addAnotherVersionOfThisQuote: {
        id: 'custom.component.common-wizard.AddNewQuoteVersionComponent.Add another version of this quote',
        defaultMessage: 'Add another version of this quote'
    },
    chooseAddVersions: {
        id: 'custom.component.common-wizard.AddNewQuoteVersionComponent.Choose / Add Versions',
        defaultMessage: 'Choose / Add Versions',
    },
    chooseVersions: {
        id: 'custom.component.common-wizard.AddNewQuoteVersionComponent.Choose Versions',
        defaultMessage: 'Choose Versions',
    },
    //
    commonQuoteAndPrice: {
        id: 'wni.quoteandbind.common.wizard.step.Quote & Prices',
        defaultMessage: 'Quote & Prices',
    },
    policyLevelQuoteDetails: {
        id: 'wni.quoteandbind.common.wizard.QuoteClauseTable.Policy Level Quote Details',
        defaultMessage: 'Policy Level Quote Details',
    },
    commonOptionalCoverages: {
        id: 'wni.quoteandbind.common.wizard.QuoteClauseTable.Common Optional Coverages',
        defaultMessage: 'Common Optional Coverages',
    },
    otherOptionalCoverages: {
        id: 'wni.quoteandbind.common.wizard.QuoteClauseTable.Other Optional Coverages',
        defaultMessage: 'Other Optional Coverages',
    },
    additionalCoverages: {
        id: 'wni.quoteandbind.common.wizard.QuoteClauseTable.Additional Coverages',
        defaultMessage: 'Additional Coverages',
    },
    exclusionCoverages: {
        id: 'wni.quoteandbind.common.wizard.QuoteClauseTable.Exclusion Coverages',
        defaultMessage: 'Exclusion Coverages',
    },
    // ============================================================
    referToUnderwriter: {
        id: 'wni.quoteandbind.components.ReferToUnderwriter.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
});
