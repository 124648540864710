import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import PropTypes from 'prop-types';

import WizardPage from '../../templates/HOWizardPage';

import styles from './HOResidencePage.module.scss';
import metadata from './HOResidencePage.metadata.json5';
import messages from './HOResidencePage.messages';

import ConstructionComponent from './Component/ConstructionComponent/ConstructionComponent';
import ProtectionComponent from './Component/ProtectionComponent/ProtectionComponent';
import LiabilityExposuresComponent from './Component/LiabilityExposuresComponent/LiabilityExposuresComponent';
import OtherInformationComponent from './Component/OtherInformationComponent/OtherInformationComponent';
import BusinessQuestionComponent from './Component/BusinessQuestionComponent/BusinessQuestionComponent';
import QuestionSetsComponent from './Component/QuestionSetsComponent/QuestionSetsComponent';

function HOResidenceReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        linePath = 'homeowners',
        customMessages,
        DPOverrideProps,
        componentMapOverride
    } = props;

    const dwellingPath = `lobData.${linePath}.coverables.dwelling_Ext`;
    const dwellingVM = _.get(submissionVM, dwellingPath);
    const {
        prefillRCTCallFlag,
        fireProtectionFlag,
        isFeb19EntityChangeVer
    } = dwellingVM.value;

    const {
        effectiveDate_Ext: effectiveDate,
        baseState_Ext: baseState
    } = _.get(submissionVM, 'value.baseData')

    const constructionPath = `lobData.${linePath}.coverables.dwelling_Ext.construction`;
    const constructionVM = _.get(submissionVM, constructionPath);

    const protectionPath = `lobData.${linePath}.coverables.dwelling_Ext.protection`;
    const protectionVM = _.get(submissionVM, protectionPath);

    const liabilityExposuresPath = `lobData.${linePath}.coverables.dwelling_Ext.liabilityExposures`;
    const liabilityExposuresVM = _.get(submissionVM, liabilityExposuresPath);

    const businessQuestionsPath = `lobData.${linePath}.coverables.dwelling_Ext.businessQuestions`;
    const businessQuestionsVM = _.get(submissionVM, businessQuestionsPath);

    const animalsPath = `lobData.${linePath}.coverables.dwelling_Ext.dwellingAnimals`;
    const dwellingAnimalsVM = _.get(submissionVM, animalsPath)

    const questionSetPath = `lobData.${linePath}.coverables.dwelling_Ext.dwellingQuestionSets`
    const dwellingQuestionSetsVM = _.get(submissionVM, questionSetPath);

    const ownAnyAnimals = _.get(liabilityExposuresVM, 'value.ownAnyAnimals');
    const coverageForm = _.get(submissionVM, `value.${constructionPath}.coverageForm_Ext`);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            // dynamicInlineNotificationContainer: {
            //     visible: false
            // },
            construction: {
                model: constructionVM,
                isReadOnly: true,
                baseState,
                effectiveDate,
                prefillRCTCallFlag,
                coverageForm,
                isFeb19EntityChangeVer,
                messages: customMessages
            },
            protection: {
                model: protectionVM,
                isReadOnly: true,
                fireProtectionFlag,
                coverageForm,
                messages: customMessages,
                isShowdIstanceToFire: !fireProtectionFlag || linePath === 'dwellingProperty',
            },
            liabilityExposures: {
                model: liabilityExposuresVM,
                isReadOnly: true,
                coverageForm,
                messages: customMessages,
                animalsVM: dwellingAnimalsVM,
            },
            animals: {
                isReadOnly: true,
                model: dwellingAnimalsVM,
                visible: ownAnyAnimals === 'yes',
            },
            otherInformation: {
                model: constructionVM,
                dwellingQuestionSetsVM,
                baseState,
                isReadOnly: true,
                coverageForm,
                messages: customMessages
            },
            businessQuestion: {
                model: businessQuestionsVM,
                isReadOnly: true
            }
        };
    }, []);

    // ===================================================
    const overrideProps = _.mergeWith(generateOverrides(), DPOverrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    const defaultComponentMap = {
        ConstructionComponent: ConstructionComponent,
        ProtectionComponent: ProtectionComponent,
        LiabilityExposuresComponent: LiabilityExposuresComponent,
        OtherInformationComponent: OtherInformationComponent,
        BusinessQuestionComponent: BusinessQuestionComponent,
        QuestionSetsComponent: QuestionSetsComponent,
    }
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            ...defaultComponentMap,
            ...componentMapOverride,
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />

        </WizardPage>
    );
}

HOResidenceReadOnlyPage.propTypes = {
    ...wizardProps,
    customMessages: PropTypes.object
}
HOResidenceReadOnlyPage.defaultProps = {
    customMessages: messages
};
export default HOResidenceReadOnlyPage;
