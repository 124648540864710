import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wcPolicyDetailsService'), method, data, additionalHeaders);
}

export default class WCPolicyDetailsService {

    static saveWCPolicyDetailsData(quoteID, sessionUUID, wcPolicyDetailsData, quoteBaseData, authHeader) {
        return processSubmission('saveWCPolicyDetailsData', [quoteID, sessionUUID, wcPolicyDetailsData, quoteBaseData], authHeader);
    }

}