import _ from 'lodash';
import WniProductsUtil from '../AccountAndProduct/WniProductsUtil';
import WizardStepUtil from '../Wizard/WizardStepUtil';

const SXS_CHANGE_PERIOD_AT_LEAST_NUM = 1;
const SXS_CHANGE_PERIOD_AT_MOST_NUM = 3;

/**
 * Checks whether current quote is in "Rated" status
 * @param {object} policyChangeDataDtoVM PolicyChangeDataDTO view model object
 * @returns {boolean} true if current quote is already rated.
 */
function isStatusQuotedOrRated(policyChangeDataDtoVM) {
    const periodStatus = _.get(policyChangeDataDtoVM, 'value.status');
    return periodStatus === 'Rated' || periodStatus === 'Quoted';
}

/**
 * Checked whether current PolicyChange is in "Draft" status
 * @param {object} policyChangeDataDtoVM
 * @returns {boolean}
 */
function isStatusDraft(policyChangeDataDtoVM) {
    const periodStatus = _.get(policyChangeDataDtoVM, 'value.status');
    return periodStatus === 'Draft';
}
/**
 * Get a skipFn that
 * 1) either returns true when current quote is Rated or Quoted, or
 * 2) returns the result of defaultSkipFn() if it exists
 * @param {Function} defaultSkipFn
 * @returns {Function} a skipFn that could be used by WizardPage
 */
function getSkipRatedQuotedFn(defaultSkipFn) {
    return (quoteData) => {
        let retval = false;
        if (isStatusQuotedOrRated(quoteData)) {
            retval = true;
        } else if (defaultSkipFn) {
            retval = defaultSkipFn(quoteData);
        }
        return retval;
    };
}

/**
 * Get a skipFn that skip current page for "Draft" status
 * @param {function} defaultSkipFn
 * @returns {boolean}
 */
function getSkipDraftFn(defaultSkipFn) {
    return (policyChangeDataDtoVM) => {
        let retval = false;
        if (isStatusDraft(policyChangeDataDtoVM)) {
            retval = true;
        } else if (defaultSkipFn) {
            retval = defaultSkipFn(policyChangeDataDtoVM);
        }
        return retval;
    };
}


/**
 * Checks whether new SideBySide PolicyChange period can be added
 * @param {Array} sxsPeriods
 * @returns {boolean} true if new pair could be added
 */
 function canRemoveSideBySideChangePeriod(sxsPeriods = []) {
    if (_.isEmpty(sxsPeriods)) {
        return false;
    }
    return sxsPeriods.length > SXS_CHANGE_PERIOD_AT_LEAST_NUM;
}


/**
 * Checks whether new SideBySide PolicyChange period can be added
 * @param {Array} sxsPeriods
 * @returns {boolean} true if new pair could be added
 */
function canAddSideBySideChangePeriod(sxsPeriods = []) {
    if (_.isEmpty(sxsPeriods)) {
        return false;
    }
    return sxsPeriods.length < SXS_CHANGE_PERIOD_AT_MOST_NUM;
}

function isDisplaySubmissionComponent(linePattern, submissionVM) {
    if(!linePattern) {
        return false
    };
    const productCode = _.get(submissionVM, 'baseData.productCode.value');
    const lobData = _.get(submissionVM, 'lobData.value', {});
    const policyLinePatterns = WizardStepUtil.getPolicyLinePatterns(lobData);
    const linePatternMatch = _.find(policyLinePatterns, (item) => item.publicId === linePattern);
    const isFollowSubmission = _.get(linePatternMatch, 'editable', false);
    if(productCode === WniProductsUtil.CPP_PRODUCT_CODE && isFollowSubmission) {
        return true;
    }
    return false;
}

export default {
    isStatusQuotedOrRated,
    getSkipRatedQuotedFn,
    getSkipDraftFn,
    canRemoveSideBySideChangePeriod,
    canAddSideBySideChangePeriod,
    isDisplaySubmissionComponent
};
