import { defineMessages } from 'react-intl';

export default defineMessages({
    // coverageEndDate: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Coverage End Date',
    //     defaultMessage: 'Coverage End Date',
    // },
    // totalPremium: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Total Premium(Inclusive of Taxes & Fees)',
    //     defaultMessage: 'Total Premium(Inclusive of Taxes & Fees)',
    // },
    // QuoteDocuments: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Quote Documents',
    //     defaultMessage: 'Quote Documents',
    // },
    // RetaininAgency: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Retain in Agency',
    //     defaultMessage: 'Retain in Agency',
    // },
    // WesternNational: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Submit to Western National',
    //     defaultMessage: 'Submit to Western National',
    // },
    // UmialikInsurance: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Submit to Umialik Insurance',
    //     defaultMessage: 'Submit to Umialik Insurance',
    // },
    // UserAgreement: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.User Agreement',
    //     defaultMessage: 'User Agreement',
    // },
    // UserAggreenmenHeader: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.user Aggreenmen Header',
    //     defaultMessage: 'By clicking the "Finish Quote" button below, you also agree that the following statements are true: '
    // },
    // UserAggreenmenli1: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.user Aggreenmen li1',
    //     defaultMessage: '• Signed Application forms with the Insured’s signature will be retained and made available at the request of Western National. '
    // },
    // UserAggreenmenli1UIC: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.UIC.user Aggreenmen li1',
    //     defaultMessage: '• Signed Application forms with the Insured’s signature will be retained and made available at the request of Umialik Insurance. '
    // },
    // UserAggreenmenli2: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.user Aggreenmen li2',
    //     defaultMessage: '• You certify that the applicant is not aware of any losses/claims/violations, other than those already provided, which will give risk to claim from the Effective Date of the policy.'
    // },
    // CreateHomeownerQuote: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Create a Homeowner Quote',
    //     defaultMessage: 'Create a Homeowner Quote'
    // },
    // FinishQuote: {
    //     id: 'quoteandbind.pa.buttonlabel.pa-policy-info.Finish Quote',
    //     defaultMessage: 'Finish Quote'
    // },
    // Pending: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Pending',
    //     defaultMessage: 'Pending'
    // },
    // PrimaryInsuredContactDetails: {
    //     id: 'quoteandbind.pa.views.pa-policy-info.Primary Insured Contact Details',
    //     defaultMessage: 'Primary Insured Contact Details'
    // }
    // FinishQuote: {
    //     id: 'endorsement.pa.buttonlabel.pa-policy-info.Finish Quote',
    //     defaultMessage: 'Finish Quote'
    // },
    submitChange: {
        id: 'endorsement.pa.buttonlabel.pa-policy-info.Submit Change',
        defaultMessage: 'Submit Change'
    },
});
