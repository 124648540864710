import _ from 'lodash';

const PA_PRODUCT_CODE = 'PersonalAuto';
const HOP_PRODUCT_CODE = 'HOPHomeowners';
const HO_PRODUCT_CODE = 'Homeowners';
const DP_PRODUCT_CODE = 'DwellingProperty';
const WAL_PRODUCT_CODE = 'Watercraft';
const RT_PRODUCT_CODE = 'RoadTrail';
const PU_PRODUCT_CODE = 'PersonalUmbrella';
const CA_PRODUCT_CODE = 'CA7CommAuto';
const WCM_PRODUCT_CODE = 'WCMWorkersComp';
const GL_PRODUCT_CODE = 'GL7GeneralLiability';
const CP_PRODUCT_CODE = 'CP7CommercialProperty';
const CR_PRODUCT_CODE = 'CR7Crime';
const CPP_PRODUCT_CODE = 'CommercialPackage';
const IM_PRODUCT_CODE = 'InlandMarine';
const CU_PRODUCT_CODE = 'CEL';

const PA_LOB_NAME = 'personalAuto';
const HO_LOB_NAME = 'homeowners';
const DP_LOB_NAME = 'dwellingProperty';
const RT_LOB_NAME = 'roadTrail';
const WT_LOB_NAME = 'watercraft';
const CA_LOB_NAME = 'commercialAuto';
const GL_LOB_NAME = 'generalLiability';
const BOP_LOB_NAME = 'businessOwners';
const IM_LOB_NAME = 'inlandMarine';
const WC_LOB_NAME = 'workersComp';
const CP_LOB_NAME = 'commercialProperty';
const CR_LOB_NAME = 'crime';
const CPP_LOB_NAME = 'commercialPackage';
const CU_LOB_NAME = 'commercialUmbrella';

const PERSONAL_ACCOUNT_CODE = 'person';
const COMMERCIAL_ACCOUNT_CODE = 'company';

const FNOL_LOB_GL = 'GeneralLiability';
const FNOL_LOB_CP = 'CommercialProperty';
const FNOL_LOB_IM = 'InlandMarine';
const FNOL_LOB_CR = 'Crime';
const FNOL_LOB_CPP = 'CommercialPackage';

const productsMap = [
    {
        code: PA_PRODUCT_CODE,
        productName: 'Personal Auto',
        productShortName: 'PA',
        icon: 'cust-personalauto',
        type: 'plan',
        sort: 10,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: HOP_PRODUCT_CODE,
        productName: 'Home',
        productShortName: 'HO',
        icon: 'cust-homeowner',
        type: 'plan',
        sort: 20,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: HO_PRODUCT_CODE,
        productName: 'Home',
        productShortName: 'HO',
        icon: 'cust-homeowner',
        sort: 20,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: DP_PRODUCT_CODE, // code: 'Dwelling',
        productName: 'Dwelling',
        productShortName: 'DP',
        icon: 'cust-dwelling',
        sort: 30,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: PU_PRODUCT_CODE,
        productName: 'Personal Umbrella',
        productShortName: 'PU',
        icon: 'cust-personalumbrella',
        sort: 40,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: WAL_PRODUCT_CODE,
        productName: 'Watercraft',
        productShortName: 'WAL',
        icon: 'cust-watercraft',
        sort: 80,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: RT_PRODUCT_CODE,
        productName: 'Road & Trail',
        productShortName: 'RT',
        icon: 'cust-roadtrail',
        type: 'other',
        sort: 90,
        accountType: PERSONAL_ACCOUNT_CODE,
    },
    {
        code: CA_PRODUCT_CODE,
        productName: 'Commercial Auto',
        productShortName: 'CA',
        icon: 'cust-commercialauto',
        sort: 100,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: WCM_PRODUCT_CODE,
        productName: "Workers' Compensation",
        productShortName: 'WCM',
        icon: 'cust-workerscompensation',
        sort: 110,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: GL_PRODUCT_CODE,
        productName: 'General Liability',
        productShortName: 'GL',
        icon: 'cust-generalliability',
        sort: 120,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: CP_PRODUCT_CODE,
        productName: 'Commercial Property',
        productShortName: 'CP',
        icon: 'cust-commercialpackage',
        sort: 130,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: CPP_PRODUCT_CODE,
        productName: 'Commercial Package',
        productShortName: 'CPP',
        icon: 'cust-commercialpackage',
        sort: 140,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: IM_PRODUCT_CODE,
        productName: 'Inland Marine',
        productShortName: 'IM',
        icon: 'cust-inlandmarine',
        sort: 150,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: CU_PRODUCT_CODE,
        productName: 'Commercial Umbrella and Excess Liability',
        productShortName: 'CU',
        icon: 'cust-commercialumbrella',
        sort: 160,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
    {
        code: CR_PRODUCT_CODE,
        productName: 'Crime',
        productShortName: 'CR',
        icon: 'cust-crime',
        sort: 170,
        accountType: COMMERCIAL_ACCOUNT_CODE,
    },
];

const getAllProductsMap = () => {
    return productsMap;
};

/**
 *
 * @param {string} code product code
 * @returns {string} return product icon
 */

const getProductIcon = (code) => {
    if (!code) {
        return '';
    }
    const productItem = _.find(productsMap, (item) => item.code === code);
    return productItem.icon;
};
/**
 *
 * @param {string} code
 * @returns {string|object}
 */

const getProductName = (code) => {
    if (!code) {
        return '';
    }
    const productItem = _.find(productsMap, (item) => item.code === code);
    return _.get(productItem, 'productName');
};

const getProductShortName = (productCode) => {
    const productItem = _.find(
        productsMap,
        (item) => item.code === productCode
    );
    return _.get(productItem, 'productShortName');
};

export default {
    /** for product code */
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    CR_PRODUCT_CODE,
    CPP_PRODUCT_CODE,
    /** for product lob name */
    PA_LOB_NAME,
    HO_LOB_NAME,
    DP_LOB_NAME,
    RT_LOB_NAME,
    WT_LOB_NAME,
    CA_LOB_NAME,
    GL_LOB_NAME,
    BOP_LOB_NAME,
    IM_LOB_NAME,
    WC_LOB_NAME,
    CP_LOB_NAME,
    CR_LOB_NAME,
    CPP_LOB_NAME,
    /** for fnol lob name */
    FNOL_LOB_GL,
    FNOL_LOB_CP,
    FNOL_LOB_IM,
    FNOL_LOB_CR,
    FNOL_LOB_CPP,
    /** for product func */
    getAllProductsMap,
    getProductIcon,
    getProductName,
    getProductShortName,
};
