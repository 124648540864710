
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';

import { WniChangeConflict } from 'wni-capability-gateway';
import { CAMultiQuoteChangeService } from 'wni-capability-policychange-ca';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';

import { CAModifiersPage } from 'wni-capability-quoteandbind-ca-react';

function CAModifiersChangePage(props) {

    // const modalApi = useWniModal();

    // const useAuthenticationData = useAuthentication();
    // const { authHeader } = useAuthenticationData;
    // const {
    //     loadingMask: { setLoadingMask },
    // } = useDependencies(['loadingMask']);
    // const {
    //     wizardData: submissionVM,
    //     updateWizardPageData,
    // } = props;

    // const {
    //     jobID,
    //     sessionUUID,
    //     baseData: {
    //         periodStatus,
    //     },
    // } = submissionVM.value;

    // const showChangeConflictsModal = useCallback(
    //     (modalVM) => {
    //         const baseVM = modalVM.baseData;
    //         const policyNumber = _.get(modalVM.value, 'changeData.policyNumber');
    //         const componentProps = {
    //             title: 'Change Conflicts',
    //             iconClassType: false,
    //             showCloseBtn: false,
    //             showCancelBtn: false,
    //             actionBtnLabel: 'Submit',
    //             cancelBtnLabel: 'Cancel',
    //             size: 'lg',
    //             authHeader: authHeader,
    //             baseVM,
    //             jobID,
    //             policyNumber,
    //         };
    //         return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
    //     },
    //     [authHeader, jobID]
    // );

    // const goToNext = async () => {
    //     if (periodStatus === 'Draft') {
    //         updateWizardPageData({
    //             [WizardConstants.sideBySideData]: undefined,
    //             [WizardConstants.policyDiffData]: undefined,
    //             [WizardConstants.policyChangeDocumentsData]: undefined,
    //         });
    //         setLoadingMask(true)
    //         submissionVM.value = await CAMultiQuoteChangeService.processQuote(
    //             jobID, sessionUUID, authHeader
    //         );
    //     }
    //     return submissionVM;
    // };

    // const handleConflict = async () => {
    //     const conflictRes = await WniChangeConflict.getChangeConflictData(submissionVM.jobID.value, authHeader);
    //     if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
    //         setLoadingMask(false)
    //         const newSubmissionVM = await showChangeConflictsModal(submissionVM)
    //             .then(goToNext)
    //             .catch(() => {
    //                 _.noop();
    //                 return false;
    //             });
    //         return newSubmissionVM;
    //     }
    //     return goToNext();
    // }

    // const onChangePageNext = async () => {
    //     return handleConflict();
    // }

    return (
        <CAModifiersPage {...props}
            // onPreQuotePageNext = {onChangePageNext}
        />
    );

}

CAModifiersChangePage.propTypes = CAModifiersPage.propTypes;
CAModifiersChangePage.defaultProps = CAModifiersPage.defaultProps;

export default CAModifiersChangePage;