import _ from 'lodash';
import { Component } from 'react';
import { WniInputNumberWithAction } from 'wni-common-base-components';
/**
 * QuestionSet filter for CA UnderwritingQuestions page
 *
 * See QuestionSetsParser.contentFilter
 * @param {String} policyState
 * @returns {Function}
 */
function getQSFilterForCaUnderwritingQuestions() {
    return (metadataContent) => {
        const UNDISPLAY_FIELDS_FOR_UWQS =
            [
                'CA7IfYesWhatPercentage',
                'CA7IfYesIsSprayBoothULApproved'
            ];

        return !UNDISPLAY_FIELDS_FOR_UWQS.includes(metadataContent.id)

    };
}


/**
 * QuestionSet Mapper for CAUnderwritingQuestions
 *
 *
 * @returns {Function}
 */
function getQSMapperForCaUnderwritingQuestions() {
    return (metaDataContent) => {
        switch (metaDataContent.id) {
            case 'CA7AreIccPucOrOther':
                _.set(metaDataContent, 'component', 'WniToggle')
                _.set(metaDataContent, 'componentProps.tooltip', true);
                _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
                _.set(metaDataContent, 'componentProps.tooltip.text', 'Acord 127')
                break;
            case 'CA7IsTireRecappingOrRetreading':
            case 'CA7AreVehiclesFurnished':
            case 'CA7DoesApplicantUseTowTrucks':
            case 'CA7DoesApplicantPerformRoadEmergencyServices':
            case 'CA7DoesApplicantSprayPaintingOrWelding':
                _.set(metaDataContent, 'component', 'WniToggle')
                _.set(metaDataContent, 'componentProps.tooltip', true);
                _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
                _.set(metaDataContent, 'componentProps.tooltip.text', 'Acord 128')
                break;
            case 'CA7DoesApplicantHaul':
            case 'CA7DoesApplicantHaulTarget':
                _.set(metaDataContent, 'component', 'WniToggle')
                _.set(metaDataContent, 'componentProps.tooltip', true);
                _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
                _.set(metaDataContent, 'componentProps.tooltip.text', 'Acord 132')
                break;
            case 'CA7AnyWaterborneshipments':
            case 'CA7DoesTheApplicantHireOwnerOperators':
            case 'CA7DoesTheApplicantBackHaul':
                _.set(metaDataContent, 'component', 'WniToggle')
                _.set(metaDataContent, 'componentProps.tooltip', true);
                _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
                _.set(metaDataContent, 'componentProps.tooltip.text', 'Acord 143')
                break;
            case 'CA7IfYesWhatPercentage_Ext':
                const metaDataProps = _.get(metaDataContent, 'componentProps', {});
                const componentProps = {
                    ...metaDataProps,
                    maxValue: 100,
                    minValue: 0,
                    decimalPlaces: 0,
                    className: 'searchFieldWithButton',
                    actionConfig: {
                        id: "naicsDescriptionSearchButton",
                        icon: "cust-percent"
                    }
                }
                _.set(metaDataContent, 'component', 'WniInputNumberWithAction');
                _.set(metaDataContent, 'componentProps', componentProps)
                break;
            default:
                break;
        }
        return metaDataContent;
    };
}

export default {
    getQSMapperForCaUnderwritingQuestions,
    getQSFilterForCaUnderwritingQuestions
};
