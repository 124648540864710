import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Policy Details',
        defaultMessage: 'Policy Details'
    },
    policyChangeDescription: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Renewal Description',
        defaultMessage: 'Renewal Description'
    },
    effectiveDate: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Renewal Effective Date',
        defaultMessage: 'Renewal Effective Date'
    },
    organizationType: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Organization Type',
        defaultMessage: 'Organization Type'
    },
    pleaseChoose: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.-- Please Choose --',
        defaultMessage: '-- Please Choose --'
    }
});
