import React, {
    useContext,
    useMemo,
} from 'react';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { CAVehicleService } from 'wni-capability-quoteandbind-ca';
import GaragingLocation from '../GaragingLocation/GaragingLocation';
import metadata from './CAVehicleDetails.metadata.json5';
import SearchModal from '../SearchModal/SearchModal';
import CAVehicleDetailsFieldMap from './CAVehicleDetailsFieldMap';
import { callPriceDigestTypeMaps } from '../../Config/CAVehicle.static';
import { isOrigionalCostNewValid } from '../../Util/CAVehicle.util';

function VehicleDetails(props) {
    const modalApi = useWniModal();

    const {
        currentRow,
        submissionVM,
        onValueChange,
        syncWizardData,
        syncWizardDataSnapshot,
        showPriceDigestModal = _.noop,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        } = {},

        setVehicleCoveragesVM,

        isChangeFlow,
        filterSizeClassMapsInChange,
        generateValidationIssues = _.noop
    } = props;

    const {
        publicID,
        vehicleType
    } = currentRow.value;

    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const updateService = async(serviceData, value, fieldItem) => {
        const {
            inputSetMode,
            propertyName
        } = fieldItem || {};

        const { callPriceDigestType } = serviceData;
        setLoadingMask(true)
       
        let isCallPriceDigest = callPriceDigestTypeMaps.includes(propertyName);

        if(callPriceDigestType === 'VIN' && propertyName !== 'VIN') {
            isCallPriceDigest = false;
        }
        if(callPriceDigestType && callPriceDigestType !== 'VIN' && propertyName === 'VIN') {
            isCallPriceDigest = false;
        }

        const res = await CAVehicleService.postOnChangeAction(jobID, sessionUUID, serviceData, isCallPriceDigest, authHeader);
        // _.set(selectedVersion, `coverages.vehiclesCoverages[${vehicleCoveragesIndex}]`, res.vehicleCoverages);
        const vehicleCoveragesVM = viewModelService.create(res.vehicleCoverages, 'pc', 'wni.edge.capabilities.policyjob.lob.commercialauto.coverages.dto.CAVehicleCoveragesDTO');
        setVehicleCoveragesVM(vehicleCoveragesVM)
        _.set(currentRow, 'value', res.caVehicle);
        syncWizardDataSnapshot(currentRow);
        generateValidationIssues(res.errorsAndWarnings);
        setLoadingMask(false);
        /**
         * for multiple PriceDegest
         * if get pricedigest form VIN, and get more than one pricedigest, should open popup for user to select which pricedigest to use
         */
        const isValidValue = !_.isNil(value) && value.length !== 0;
        // const checkMultiVINIndicatorVisiable = _.get(res.caVehicle, 'checkMultiVINIndicatorVisiable');
        
        if(isValidValue && inputSetMode === 'ca7_vin_ext' && _.get(res, 'caVehicle.priceDigestDetails', []).length > 1) {
            showPriceDigestModal(res.caVehicle)
        }
    };

    const onBlur = (value, fieldItem) => {
        if(fieldItem.propertyName === "OriginalCostNew" && !isOrigionalCostNewValid(currentRow.value, value)) {
            return false
        }
        updateService(currentRow.value, value, fieldItem)
    };

    const onSearch = async(fieldItem) => {
        setLoadingMask(true);
       
        const paramsData = {
            publicID,
            vehicleType,
            propertyName: fieldItem.propertyName,
            isDefault: true
        };
        const res = await CAVehicleService.getSearchDisplayable(jobID, sessionUUID, paramsData, authHeader);
        setLoadingMask(false);
        const componentProps = {
            title: fieldItem.label,
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            publicID,
            vehicleType,
            initData: res,
            fieldItem,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        }
        modalApi.showModal(<SearchModal {...componentProps} />).then((result) => {
            _.set(currentRow, 'value', result);
            syncWizardDataSnapshot(currentRow)
        }).catch(() => _.noop());
        
    };

    const writeValue = (fieldItem, path, fieldModel = {}) => {
        _.set(currentRow.value, path, fieldItem);
        syncWizardData(currentRow);

        // 
        if(fieldModel.triggerFunc === 'onValueChange') {
            updateService(currentRow.value, fieldItem[fieldModel.value], fieldItem)
        }
    }
    const componentProps = _.omit(props, 'id');

    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        garagingLocationSection: {
            ...componentProps

        },
        fieldSetModel: {
            vm: currentRow,
            dataPath: 'bulkVehicleDetails',
            submissionVM,
            onValueChange: writeValue,
            onSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            filterSizeClassMapsInChange
        }
        
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            garaginglocation: GaragingLocation,
            fieldsetmap: CAVehicleDetailsFieldMap
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrides}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default VehicleDetails;
