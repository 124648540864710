import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const AGENTS_ONLINE_MODE = 'ao';
const BILLING_EXIT_PAGE_INDEX = 3;

function ExternalAgentsOnlineBillingRouting(props) {
    const { match, history } = props;
    const {
        params: { accountNumber }
    } = match;

    useEffect(() => {
        const targetURL = `/account-quotes/${accountNumber}`;
        const stateObj = {
            mode: AGENTS_ONLINE_MODE,
            submissionWizardExitPage: BILLING_EXIT_PAGE_INDEX,
            accountNumber: accountNumber
        };
        history.push(targetURL, stateObj);
    }, []);

    return null;
}

ExternalAgentsOnlineBillingRouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

export default withRouter(ExternalAgentsOnlineBillingRouting);
