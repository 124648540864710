import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('GLStateSpecificInformationService'), method, data, additionalHeaders);
}

export default class GLStateSpecificInformationService {
    /**
    * Invokes GLStateSpecificInformationHandler.onStateSpecificInformationPageNext()
    * 
    * @param {string} jobID 
    * @param {string} sessionUUID 
    * @param {Object} authHeader 
    * @returns {Promise}
    */
    static onStateSpecificInformationPageNext(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('onStateSpecificInformationPageNext', [jobID, sessionUUID], authHeader);
    }
}