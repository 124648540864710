import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useModal } from '@jutro/components';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import messages from '../../../PECPWizard.messages';
import wizardConfig from './config/cp-building-wizard-config.json5';

function PECPBuildingsWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const {
        initialSubmission,
        finishAddBuilding,
        cancelAddBuilding,
        selectedLocation,
        onCancel
    } = props;

    const handleFinish = useCallback(
        (submission) => {
            finishAddBuilding(
                _.get(submission, 'wizardData.selectedLocation.location'),
                _.get(submission, 'wizardData.selectedBuilding.building')
            );
        },
        [finishAddBuilding]
    );

    const handleCancel = useCallback(
        ({ param: nextPath }) => {
            if (nextPath) {
                return onCancel(nextPath);
            }
            return modalApi.showConfirm({
                title: messages.cancelAddingBuilding,
                message: messages.cancelConfirmation,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.yes,
                cancelButtonText: messages.no
            }).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                return cancelAddBuilding();
            }, _.noop);
        },
        [cancelAddBuilding, onCancel]
    );

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={null}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={{
                    submissionVM: initialSubmission,
                    selectedLocation: {
                        location: selectedLocation || {},
                        locationType: ''
                    },
                    selectedBuilding: {
                        building: {}
                    }
                }}
                onFinish={handleFinish}
                onCancel={handleCancel}
            />
        </div>
    );
}

PECPBuildingsWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    initialSubmission: PropTypes.shape({}).isRequired,
    finishAddBuilding: PropTypes.func.isRequired,
    cancelAddBuilding: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedLocation: PropTypes.shape({}).isRequired
};

export default PECPBuildingsWizard;
