import React, {
    useState, useEffect, useCallback, useContext, useRef
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';

import { useModal } from '@jutro/components';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PropertyCodeLookupService, CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import metadata from './EditBuildingSummary.metadata.json5';
import styles from './EditBuildingSummary.module.scss';
import message from '../../../PECPWizard.messages';

const EditBuildingSummary = (props) => {
    const modalApi = useModal();
    const {
        buildingToEdit,
        submissionVM,
        updateWizardData,
        selectedLocation,
        completeEdit
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [buildingSubmission, updateBuildingSubmission] = useState({});
    const [propertyClassCodeList, updatePropertyClassCodeList] = useState([]);
    const [isAlarmEnabled, enableAlarm] = useState(true);

    const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';
    const updatedBuilding = useRef({});

    const getPropertyClassCodeList = useCallback(() => {
        const jobId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
        const classCode = _.get(buildingToEdit, 'classCode');
        const address = _.get(selectedLocation, 'address');
        const sessionId = _.get(submissionVM.value, 'sessionUUID');

        PropertyCodeLookupService.getPropertyCodes(
            'CommercialProperty',
            jobId,
            classCode || null,
            address,
            sessionId,
            authHeader
        ).then((response) => {
            const propertyClassCodesResponse = response
                && response.map((propertyClassCode) => {
                    const propertyClassCodesData = {
                        publicID: propertyClassCode.publicID,
                        code: propertyClassCode.code,
                        name: propertyClassCode.code.concat(': ', propertyClassCode.classification)
                    };
                    return propertyClassCodesData;
                });

            updatePropertyClassCodeList(propertyClassCodesResponse);
        });
    }, [submissionVM.value, buildingToEdit, selectedLocation, authHeader]);

    const saveEditedBuilding = useCallback(
        async (isComplete = false) => {
            const quoteId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
            const locationId = _.get(selectedLocation, 'publicID');
            const sessionId = _.get(submissionVM.value, 'sessionUUID');

            const response = await CPCoverablesService.updateCPBuilding(
                quoteId,
                locationId,
                updatedBuilding.current.submission,
                sessionId,
                authHeader
            );

            const location = _.get(submissionVM.value, LOCATION_PATH).find((loc) => {
                return loc.publicID === selectedLocation.publicID;
            });

            const buildingIndex = _.findIndex(location.buildings, (building) => {
                return building.publicID === response.publicID;
            });
            location.buildings.splice(buildingIndex, 1, response);

            updateWizardData(submissionVM);
            updatedBuilding.current = {
                submission: submissionVM.value,
                isSavedOrCancelled: true
            };
            completeEdit(true, isComplete);
        },
        [submissionVM, selectedLocation, authHeader, updateWizardData, completeEdit]
    );

    const onSaveClicked = useCallback(() => {
        saveEditedBuilding(true);
    }, [saveEditedBuilding]);

    useEffect(() => {
        getPropertyClassCodeList();
        const submission = viewModelService.create(
            buildingToEdit,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.BuildingDTO'
        );
        updateBuildingSubmission(submission);

        updatedBuilding.current = {
            submission: submission.value,
            isSavedOrCancelled: false
        };

        return () => {
            const isBuildingSaved = !updatedBuilding.current.isSavedOrCancelled
                && !_.isEqual(buildingToEdit, updatedBuilding.current.submission);

            if (isBuildingSaved) {
                modalApi.showConfirm({
                    title: message.unsavedChangesTitle,
                    message: message.unsavedChangesMessage,
                    status: 'warning',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.yesModel,
                    cancelButtonText: commonMessages.cancelModel
                }).then((results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    return saveEditedBuilding(false);
                }, _.noop);
            }
        };
    }, [buildingToEdit, getPropertyClassCodeList, saveEditedBuilding, viewModelService]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(buildingSubmission);
            if (path === 'classCode') {
                const selectedClassCode = propertyClassCodeList.find(
                    (classCodeData) => classCodeData.code === value
                );
                newSubmissionVM.classCode = selectedClassCode;
            } else {
                _.set(newSubmissionVM, path, value);
            }
            updatedBuilding.current = {
                submission: newSubmissionVM.value,
                isSavedOrCancelled: false
            };
            updateBuildingSubmission(newSubmissionVM);
        },
        [viewModelService, buildingSubmission, updateBuildingSubmission, propertyClassCodeList]
    );

    const showCancelModal = useCallback(() => {
        return modalApi.showAlert({
            title: message.editBuildingCancelChangesTitle,
            message: message.editBuildingCancelChangesMessage,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: message.editLocationCancelChangesConfirmationYes,
            cancelButtonText: message.editLocationCancelChangesConfirmationNo
        }).catch(_.noop);
    }, []);

    const onCancelClicked = useCallback(() => {
        const isComplete = true;
        const toCollapseBuildingDetails = true;
        const isCancelled = true;

        updatedBuilding.current = {
            ...updatedBuilding.current,
            isSavedOrCancelled: true
        };

        if (!_.isEqual(buildingToEdit, updatedBuilding.current.submission)) {
            showCancelModal().result.then(() => {
                completeEdit(isComplete, toCollapseBuildingDetails, isCancelled);
            });
        } else {
            completeEdit(isComplete, toCollapseBuildingDetails, isCancelled);
        }
    }, [buildingToEdit, showCancelModal, completeEdit]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        propertyClassCode: {
            availableValues: propertyClassCodeList,
            value: _.get(buildingSubmission.value, 'classCode.code')
        },
        alarm: {
            value: isAlarmEnabled,
            onValueChange: enableAlarm
        },
        alarmType: {
            visible: isAlarmEnabled
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                buildingSubmission,
                id,
                path,
                overrideProps
            );
        },
        [buildingSubmission, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCancelClicked: onCancelClicked,
            onSaveClicked: onSaveClicked
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={buildingSubmission}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

EditBuildingSummary.propTypes = {
    buildingToEdit: PropTypes.shape({}).isRequired,
    selectedLocation: PropTypes.shape({
        publicID: PropTypes.shape({})
    }).isRequired,
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    completeEdit: PropTypes.func.isRequired
};

export default EditBuildingSummary;
