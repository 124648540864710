import { defineMessages } from 'react-intl';

export default defineMessages({
    commonYes: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Yes',
        defaultMessage: "Yes"
    },
    commonNo: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.No',
        defaultMessage: "No"
    },
    requiredForIssuance: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.required for issuance',
        defaultMessage: 'required for issuance'
    },
    riskCharacteristicsTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.RESIDENCE - RISK CHARACTERISTICS',
        defaultMessage: 'RESIDENCE - RISK CHARACTERISTICS'
    },
    replacementCostLabel: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Estimated Replacement Cost',
        defaultMessage: 'Estimated Replacement Cost'
    },
    editReplacementCostButton: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.edit Replacement Cost Button',
        defaultMessage: 'View/Edit Replacement Cost Estimator'
    },
    constructionOption: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Construction Grade',
        defaultMessage: 'Construction Grade'
    },
    yearBuilt: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Year Built',
        defaultMessage: 'Year Built'
    },
    numberofStories: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Number of Stories',
        defaultMessage: 'Number of Stories'
    },
    numberofBathrooms: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Number of Bathrooms',
        defaultMessage: 'Number of Bathrooms'
    },
    finishedLivingArea: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Finished Living Area - Above ground',
        defaultMessage: 'Finished Living Area - Above ground'
    },
    numberofUnitinBuilding: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Number of Units in Building',
        defaultMessage: 'Number of Units in Building'
    },
    construction: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Type of Construction',
        defaultMessage: 'Type of Construction'
    },
    constructionType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Type of Construction Description',
        defaultMessage: 'Type of Construction Description'
    },
    logType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Type of Log',
        defaultMessage: 'Type of Log'
    },
    existChinkingBetweenLogs: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.existChinkingBetweenLogs',
        defaultMessage: 'Is there chinking between the logs?'
    },
    chinkingUpdatedType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.chinkingUpdatedType',
        defaultMessage: 'How often the chinking is updated'
    },
    logsJoinedSealedPattern: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.logsJoinedSealedPattern',
        defaultMessage: 'How are the logs joined and sealed together'
    },
    haveLogsBeenSealed: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.haveLogsBeenSealed',
        defaultMessage: 'Have the logs been treated and sealed?'
    },
    routineMaintanceFrequency: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.routineMaintanceFrequency',
        defaultMessage: 'How often is routine maintenance performed on the logs?'
    },
    foundation: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Type of Foundation',
        defaultMessage: 'Type of Foundation'
    },
    basementFinished: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Percentage of Basement Finished',
        defaultMessage: 'Percentage of Basement Finished'
    },
    exteriorWallCovering: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Primary Exterior Wall Covering',
        defaultMessage: 'Primary Exterior Wall Covering'
    },
    roof: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Roof Covering Type',
        defaultMessage: 'Roof Covering Type'
    },
    roofSlope: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Roof Slope',
        defaultMessage: 'Roof Slope'
    },
    roofingUpgradeDate: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Roof - Last Complete Replacement Year',
        defaultMessage: 'Roof - Last Complete Replacement Year'
    },
    hasCompletedMaintenance: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.hasCompletedMaintenance',
        defaultMessage: 'Are you completing the recommended protective maintenance treatments every 3 years?'
    },
    heatingUpgradeDate: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Heating - Year of Complete Replacement',
        defaultMessage: 'Heating - Year of Complete Replacement'
    },
    isResidenceHaveCentralHeat: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isResidenceHaveCentralHeat',
        defaultMessage: 'Does the residence have central heat?'
    },
    primaryHeatingType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.primaryHeatingType',
        defaultMessage: 'How is the central heating system powered?'
    },
    anySolidFuelBurningDevice: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.anySolidFuelBurningDevice',
        defaultMessage: 'Are there any solid fuel burner devices located on the premises?'
    },
    plumbingUpgradeDate: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Plumbing - Year of Complete Replacement',
        defaultMessage: 'Plumbing - Year of Complete Replacement'
    },
    wiringUpgradeDate: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Electrical - Year of Complete Replacement',
        defaultMessage: 'Electrical - Year of Complete Replacement'
    },
    garage: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Garage Type',
        defaultMessage: 'Garage Type'
    },
    isGarageHeated: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isGarageHeated',
        defaultMessage: 'Is the garage heated?'
    },
    numberofStalls: {
        id: 'wni.quoteandbind.ho-wizard.Number of Stalls',
        defaultMessage: 'Number of Stalls'
    },
    protectionTitle: {
        id: 'wni.quoteandbind.ho-wizard.SAFEGUARDS AND SECURITY',
        defaultMessage: 'SAFEGUARDS AND SECURITY'
    },
    distanceToFireHydrant: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.distance To Fire Hydrant',
        defaultMessage: 'Distance to Fire Hydrant or Year Round Water Source (ft)'
    },
    distanceToFireStation: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.distance To FireStation',
        defaultMessage: 'Driving distance to responding Fire Station (mi)'
    },
    fireProtectionClass: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Fire Protection',
        defaultMessage: 'Fire Protection'
    },
    burglarAlarm: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Burglar Alarm',
        defaultMessage: 'Burglar Alarm'
    },
    fireAlarm: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Fire Alarm',
        defaultMessage: 'Fire Alarm'
    },
    homeMonitoringSystems: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Home Monitoring System(s)',
        defaultMessage: 'Home Monitoring System(s)'
    },
    liabilityTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.LIABILITY EXPOSURES',
        defaultMessage: 'LIABILITY EXPOSURES'
    },
    liabilityTitleNote: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.liability title note',
        defaultMessage: '*Please note,responses to the below questions can have premium impact'
    },
    numberOfResidenceEmployees: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Number of Household Employees residing on premise?',
        defaultMessage: 'Number of Household Employees residing on premise?'
    },
    ownAnyAnimals: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Are any animals owned or kept on the premise?',
        defaultMessage: 'Are any animals owned or kept on the premise?'
    },
    swimmingPoolType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Is there a swimming pool on premise?',
        defaultMessage: 'Is there a swimming pool on premise?'
    },
    approvedFence: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Approved Fence',
        defaultMessage: 'Is the swimming pool surrounded by a six foot fence with  a self-latching gate?'
    },
    divingBoard: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Diving Board',
        defaultMessage: 'Is the swimming pool equipped with a diving board or slide?'
    },
    depthOfThePool: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.depthOfThePool',
        defaultMessage: 'What is the maximum depth of the pool?'
    },
    explain: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Please Explain:',
        defaultMessage: 'Please Explain:'
    },
    hotTubType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Is there a hot tub on premise?',
        defaultMessage: 'Is there a hot tub on premise?'
    },
    existATrampoline: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Is there a trampoline on premise?',
        defaultMessage: 'Is there a trampoline on premise?'
    },
    isTrampolineNetted: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isTrampolineNetted',
        defaultMessage: 'Is the trampoline netted?'
    },
    animals: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.ANIMALS',
        defaultMessage: 'ANIMALS'
    },
    addAnimal: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Add',
        defaultMessage: 'Add'
    },
    noAnimalPlaceholder: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.No animal Placeholder',
        defaultMessage: 'There is no animal now. Please add one.'
    },
    numberofPoultry: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Number of Poultry',
        defaultMessage: 'Number of Poultry'
    },
    isChickenCoopHeated: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isChickenCoopHeated',
        defaultMessage: 'Is there a heated coop on premises?'
    },
    howFarIsTheChickenCoop: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.howFarIsTheChickenCoop',
        defaultMessage: 'How far is the coop from another building (ft)?'
    },
    sellOrSharedEggs: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.sellOrSharedEggs',
        defaultMessage: 'Does the insured sell or share eggs? '
    },
    otherInformationTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.other information title',
        defaultMessage: 'OTHER RESIDENCE INFORMATION REQUIRED FOR ISSUANCE'
    },
    isResidenceForSale: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isResidenceForSale',
        defaultMessage: 'Is the residence for sale?'
    },
    roomerBoardersNumber: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Number of Roomers/Boarders',
        defaultMessage: 'Number of Roomers/Boarders'
    },
    structureOriginallyBuilt: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.structureOriginallyBuilt',
        defaultMessage: 'Has this structure ever functioned as anything other than a residence?'
    },
    commercialOrNonresidential: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.commercialOrNonresidential',
        defaultMessage: 'Is this property located within 300 feet of commercial or nonresidential property?'
    },
    relatedPrivateStructure: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.relatedPrivateStructure',
        defaultMessage: 'How many owned related private structures on or off premise exceed 200 square feet?'
    },
    accessibleByVehicle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.accessibleByVehicle',
        defaultMessage: 'Is this residence accessible year round by vehicle?'
    },
    residentHaveCurrentInspection: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.residentHaveCurrentInspection',
        defaultMessage: 'Does the residence to be insured by this policy have a current inspection?'
    },
    dateOfInspection: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.dateOfInspection',
        defaultMessage: 'What is the date of the inspection?'
    },
    primaryHeating: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.What is the primary heating source for this residence?',
        defaultMessage: 'What is the primary heating source for this residence?'
    },
    primaryHeatingFuelTankLocation: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.primaryHeatingFuelTankLocation',
        defaultMessage: 'Where is the fuel storage tank for this heating type located?'
    },
    secondaryHeating: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.secondaryHeating',
        defaultMessage: 'What is the secondary heating source type for this residence?'
    },
    secHeatingFuelTankLocation: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.secHeatingFuelTankLocation',
        defaultMessage: 'Where is the fuel storage tank for this heating type located?'
    },
    devicesNumber: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.devicesNumber',
        defaultMessage: 'How many solid fuel burning devices are located on premise?'
    },
    solidFuelBurningDevice: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.solidFuelBurningDevice',
        defaultMessage: 'What type of solid fuel burning device is located on premise?'
    },
    stoveLocated: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.stoveLocated',
        defaultMessage: 'Where is the solid fuel burning device located?'
    },
    isSolidFuelDeviceABarrel: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isSolidFuelDeviceABarrel',
        defaultMessage: 'Is the solid fuel device a barrel, homemade, or Franklin stove?'
    },
    plumbing: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.What plumbing type(s) are found in this residence?',
        defaultMessage: 'What plumbing type(s) are found in this residence?'
    },
    plumbingTypeDescription: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.plumbingTypeDescription',
        defaultMessage: 'Type of Plumbing Description'
    },
    wiring: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.What type(s) of electrical wiring are found in this residence?',
        defaultMessage: 'What type(s) of electrical wiring are found in this residence?'
    },
    electricalType: {
        id: "wni.quoteandbind.ho-wizard.ho-residence.electricalType",
        defaultMessage: "What type of electrical system is found in this residence?"
    },
    ampService: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Amp Service',
        defaultMessage: 'Amp Service'
    },
    isFlatRoofOver: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isFlatRoofOver',
        defaultMessage: 'Is there a flat roof over living area?'
    },
    businessQuestion: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.BUSINESS QUESTIONS',
        defaultMessage: 'BUSINESS QUESTIONS'
    },
    conductRemoteOfficeWork: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.conductRemoteOfficeWork',
        defaultMessage: 'Does the insured conduct remote office work on premises?'
    },
    additionalBusinessPropCov: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.additionalBusinessPropCov',
        defaultMessage: 'Do you need additional Business Property coverage?'
    },
    anyBusinessConducted: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.anyBusinessConducted',
        defaultMessage: 'Is there any business or farming conducted on premise?'
    },
    additionalBusinessType: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.additionalBusinessType',
        defaultMessage: 'What type of business is it?'
    },
    existBusiPropStoredOutbuilding: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.existBusiPropStoredOutbuilding',
        defaultMessage: 'Is there any business conducted or business property stored in an outbuilding?'
    },
    existCommercialPolicyWithWNI: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.existCommercialPolicyWithWNI',
        defaultMessage: 'Is there a commercial policy in place with Western National?'
    },
    existCommercialPolicyWithUMI: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.existCommercialPolicyWithUMI',
        defaultMessage: 'Is there a commercial policy in place with Umialik Insurance?'
    },
    type: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Type',
        defaultMessage: 'Type'
    },
    breed: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Breed',
        defaultMessage: 'Breed'
    },
    animalBiteHistory: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.animalBiteHistory',
        defaultMessage: 'Is there any history of biting or aggression'
    },
    describe: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Describe',
        defaultMessage: 'Describe'
    },
    exclude: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Exclude',
        defaultMessage: 'Exclude'
    },
    removeAnimalTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.removeAnimalTitle',
        defaultMessage: 'Remove Animal?'
    },
    removeAnimaldescription: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.removeAnimaldescription',
        defaultMessage: 'Are you sure you want to remove this animal from the list of animals?'
    },
    ok: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Ok',
        defaultMessage: 'Ok'
    },
    cancel: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Cancel',
        defaultMessage: 'Cancel'
    },
    RCTWebsiteCallbackFail: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.RCTCallbackFail',
        defaultMessage: 'Failed to synchronize with RCE. Please click OK button to try again. '
    },
    RCTCallbackSuccess: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.RCTCallbackSuccess',
        defaultMessage: 'The RCE has not successfully returned a value in all applicable fields, please review and click “View/Edit Replacement Cost Estimator” to complete.'
    },
    notReturnedFields: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Not Returned Fields',
        defaultMessage: 'Not Returned Fields'
    },
    printThisPage: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Print This Page',
        defaultMessage: 'Print This Page'
    },
    diffTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.diffTitle',
        defaultMessage: 'Review Differences'
    },
    diffNote: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.diffNote',
        defaultMessage: 'The Replacement Cost Estimator has returned the following values for this property which are different from what was entered in the comparative rater. Please click the “View/Edit Replacement Cost Estimator” hyperlink at the bottom to launch and correct any values.'
    },
    diifNote2: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.diffNote2',
        defaultMessage: 'Click the “Override” button to replace the comparative rater entries with the values returned from the Replacement Cost Estimator. If you wish to retain the comparative rater entries, please click the “Retain” button.'
    },
    burglarAlarmError: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.burglarAlarmError',
        defaultMessage: 'Please select one option for Burglar Alarm.'
    },
    fireAlarmError: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.fireAlarmError',
        defaultMessage: 'Please select one option for Fire Alarm.'
    },
    viewOrEditAnimalDetail: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.viewOrEditAnimalDetail',
        defaultMessage: 'View/Edit Details'
    },
    viewAnimalDetail: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.viewAnimalDetail',
        defaultMessage: 'View Details'
    },
    viewOrEditDetail: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.viewOrEditDetail',
        defaultMessage: 'View/Edit Details'
    },
    viewDetail: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.viewDetail',
        defaultMessage: 'View Details'
    },
    viewAndEditLabel: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.viewAndEditLabel',
        defaultMessage: 'View/Edit'
    },
    viewLabel: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.viewLabel',
        defaultMessage: 'View'
    },
    BL0328WarningMessage: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.BL0328WarningMessage',
        defaultMessage: 'The valuation method can only be Functional Replacement Cost when the construction type is Standard.'
    },
    BL0329WarningMessage: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.BL0329WarningMessage',
        defaultMessage: 'The valuation method cannot be Functional Replacement Cost when the construction type is not Standard.'
    },
    override: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.override',
        defaultMessage: 'Override'
    },
    retain: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.retain',
        defaultMessage: 'Retain'
    },
    residenceRequiredNotification: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.requiredNotification',
        defaultMessage: '{fasterQuote} Only fields with red asterisks {asteriskIcon} are required.{RCENote}'
    },
    residenceFasterQuote: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.fasterQuote',
        defaultMessage: 'Get a faster quote!'
    },
    residenceRCENote: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.RCTNote',
        defaultMessage: 'Please note: Any values changed in the Replacement Cost Estimator will update your quote and the Coverage A value. If a value is updated directly within the quote, it will not be reflected in the Replacement Cost Estimator or the Coverage A value.'
    },
    solidFuelTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Solid Fuel Burner Devices',
        defaultMessage: 'Solid Fuel Burner Devices'
    },
    noSolidFuelPlaceholder: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.No solidFuel Placeholder',
        defaultMessage: 'There is no Solid Fuel now. Please add one.'
    },
    solidFuelDetailTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.Solid Fuel Burner Devices Details',
        defaultMessage: 'Solid Fuel Burner Devices Details'
    },
    isAnyKnobAndTubeWiring: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isAnyKnobAndTubeWiring',
        defaultMessage: 'Is there any knob and tube wiring present in this dwelling?'
    },
    isTrampolineSecured: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.isTrampolineSecured',
        defaultMessage: 'Is the trampoline secured to the ground?'
    },
    BR339WarningMessage: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.BR339WarningMessage',
        defaultMessage: 'The RCE has not successfully returned a valuation. Please click {ViewEditRCE} to complete a Replacement Cost Estimator.'
    },
    ViewEditRCE: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.ViewEditRCE',
        defaultMessage: '"View/Edit RCE"'
    },
    BR343WarningMessage: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.BR343WarningMessage',
        defaultMessage: 'Please note that the information returned in the RCE is based off assumptions from our vendor.'
    },
    dogBreedIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.dogBreedIneligible',
        defaultMessage:'{dogBreed} is ineligible.'
    },
    dogBiteHistoryIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.dogBiteHistoryIneligible',
        defaultMessage:'Insured cannot have a dog with a history of biting or aggression.'
    },
    dogCountIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.dogCountIneligible',
        defaultMessage:'Insured cannot have more than 3 dogs.'
    },
    solidFuelTypeIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.solidFuelTypeIneligible',
        defaultMessage:' Insured cannot have a barrel, homemade or Franklin stove.'
    },
    ampServiceIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.ampServiceIneligible',
        defaultMessage:'Insured risk cannot have 60-amp service.'
    },
    constructionTypeIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.constructionTypeIneligible',
        defaultMessage:'{constructionType} is ineligible.'
    },
    exteriorWallCoveringIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.exteriorWallCoveringIneligible',
        defaultMessage:'{exteriorWallCoveringType} is ineligible.'
    },
    roofTypeIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.roofTypeIneligible',
        defaultMessage:'{roofType} is ineligible.'
    },
    roofSlopeTypeIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.roofSlopeTypeIneligible',
        defaultMessage:'Flat roofed homes are not eligible.'
    },
    plumbingTypeIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.plumbingTypeIneligible',
        defaultMessage:'Homes with galvanized plumbing are ineligible.'
    },
    knobAndTubeWiringIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.knobAndTubeWiringIneligible',
        defaultMessage:'Home with knob and tube wiring are not eligible.'
    },
    wiringTypeIneligible:{
        id:'wni.quoteandbind.ho-wizard.ho-residence.wiringTypeIneligible',
        defaultMessage:'Homes with aluminum wiring are ineligible.'
    },
    dogBreedsDescription: {
        id: 'wni.quoteandbind.ho-wizard.ho-residence.dogBreedsDescription',
        defaultMessage: 'Please list the breed(s) of dog.'
    },
});
