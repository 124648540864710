/* eslint-disable max-len */
import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import { withRouter } from 'react-router-dom';
import { ViewModelServiceContext, ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import _ from 'lodash';
import moment from 'moment';
import {
    useTranslator
} from '@jutro/locale';
import { CurrencyValue, Loader, formatDate } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { WniBillingAndPaymentService, WniSubmissionService, WniDocumentRetrievalService } from 'wni-capability-gateway';
import { PaymentComponent, Link as LinkComponent } from 'gw-components-platform-react';
import { WniDateUtil, DocumentsUtil, ServiceErrorUtil } from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from 'gw-capability-gateway-react/Accounts/Accounts.messages';
import metadata from './AutoPaymentPage.metadata.json5';
import customMessages from './AutoPaymentPage.messages';
import accountBillingStyles from './AutoPaymentPage.module.scss';

import { CurrencyField } from '@jutro/legacy/components';

function AutoPaymentPage(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { match: { params: { accountNumber, invoiceNumber } }, history } = props;
    const { authHeader } = useAuthentication();
    const [billingData, updateBillingData] = useState({});
    const [showLoader, updateShowLoader] = useState(true);
    const [xCenter, updateXCenter] = useState('');
    const [isSetupPayment, updateIsSetupPayment] = useState(true);
    const [isDetailsedScheduleOpen, updateIsDetailsedScheduleOpen] = useState(false);
    const [showInnerLoader, updateShowInnerLoader] = useState(false);
    const [isSetupPaymentComplete, updateIsSetupPaymentComplete] = useState(false);
    const [isRemovePaymentComplete, updateIsRemovePaymentComplete] = useState(false);
    const { interactionModel } = useDependencies('interactionModel');
    const [sysLocalDate, updateSysLocalDate] = useState();
    const [periodicity, updatePeriodicity] = useState('');
    const [dataExist, updateDataExist] = useState(false);
    const [hasBalanceDue, updateHasBalanceDue] = useState(false);
    const [unpaidInvoices, updateUnpaidInvoices] = useState([]);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const createVM = (model) => {
        return viewModelService.create(model,
            'bc', 'edge.capabilities.billing.dto.BillingInfoDTO');
    };

    const getPeriodicity = (code) => {
        if (_.isNil(code)) {
            return '';
        }
        const scheduleTermDTO = viewModelService.create({},
            'bc', 'edge.capabilities.billing.dto.ScheduleTermDTO');
        const availableValues = _.get(scheduleTermDTO, 'periodicity.aspects.availableValues');
        const target = _.find(availableValues, (item) => item.code === code);
        const name = _.get(target, 'name');
        if (_.isNil(name)) {
            return '';
        }
        updatePeriodicity(translator({ id: name }));
        return periodicity;
    };

    const getBillingData = async () => {
        const responseDataAccountBillingSummary = await WniBillingAndPaymentService
            .getAccountPaymentDetails(accountNumber, invoiceNumber, authHeader);

        const paymentGroup = _.isEmpty(responseDataAccountBillingSummary.paymentGroups) ? [] : responseDataAccountBillingSummary.paymentGroups[0];
        if (paymentGroup && responseDataAccountBillingSummary.paymentInstrument_Ext && responseDataAccountBillingSummary.paymentInstrument_Ext.paymentMethod === 'autopay_Ext') {
            updateDataExist(true);
        }

        if (paymentGroup && paymentGroup.invoiceSummary) {
            const dueInvoices = _.filter(paymentGroup.invoiceSummary, (invoice) => {
                return invoice.invoiceStatus !== 'planned';
            });
            if (dueInvoices.length > 0) {
                updateHasBalanceDue(true);
                updateUnpaidInvoices(dueInvoices);
            }
        }

        const model = {};
        const billingInfoVM = createVM(model);
        billingInfoVM.paymentGroups = !_.isEmpty(responseDataAccountBillingSummary)
            ? responseDataAccountBillingSummary.paymentGroups
            : [];
        const { _xCenter } = billingInfoVM;
        updateBillingData({
            paymentGroup: paymentGroup,
            paymentInstrument: responseDataAccountBillingSummary.paymentInstrument_Ext,
            relatedPolicyNumbers: responseDataAccountBillingSummary.relatedPolicyNumbers_Ext
        });

        const periodicityCode = paymentGroup.scheduleTerms
            ? paymentGroup.scheduleTerms[0].periodicity
            : '';
        getPeriodicity(periodicityCode);

        updateXCenter(_xCenter);
        updateShowLoader(false);
    };

    const getSystemDate = () => {
        WniSubmissionService.getSystemDate(authHeader)
            .then((res) => {
                updateSysLocalDate(res);
            }).catch(() => {
                _.noop();
            });
    };

    useEffect(() => {
        getBillingData();
        getSystemDate();
    }, []);

    const handleTogglePaymentSchedule = () => {
        updateIsDetailsedScheduleOpen(!isDetailsedScheduleOpen);
    };

    const handleRemoveAutoPayment = async () => {
        updateShowInnerLoader(true);
        updateShowLoader(true);
        await WniBillingAndPaymentService
            .removeAutoPaymentByStreamNumber(accountNumber, invoiceNumber, authHeader)
            .then(() => {
                updateShowInnerLoader(false);
                updateShowLoader(false);
                updateIsSetupPayment(false);
                updateIsRemovePaymentComplete(true);
            }).catch(() => {
                updateShowInnerLoader(false);
                updateShowLoader(false);
                _.noop();
            });
    };

    const handleConfirmSetupPayment = async (paymentData, paymentMethod) => {
        const paymentInstrument = {};

        if (paymentMethod === 'wire') {
            const bankAccountData = {
                bankAccountType_Ext: _.get(paymentData, 'bankAccountType_Ext.value.code'),
                bankAccountNumber: _.get(paymentData, 'bankAccountNumber.value'),
                bankABANumber: _.get(paymentData, 'bankABANumber.value'),
                bankName: _.get(paymentData, 'bankName.value'),
                effectiveDate_Ext: WniDateUtil.getDateObj(_.get(paymentData, 'effectiveDate_Ext.value'))
            };

            paymentInstrument.paymentMethod = paymentMethod;
            paymentInstrument.bankAccountData = bankAccountData;
            paymentInstrument.creditCardData = null;
        } else {
            const creditBankAccountData = {
                creditCardIssuer: _.get(paymentData, 'creditCardIssuer.value.code'),
                creditCardNumber: _.get(paymentData, 'creditCardNumber.value'),
                creditCardExpDate: _.get(paymentData, 'creditCardExpDate.value'),
            };

            paymentInstrument.paymentMethod = 'creditcard';
            paymentInstrument.creditCardData = creditBankAccountData;
            paymentInstrument.bankAccountData = null;
        }

        updateShowInnerLoader(true);
        updateShowLoader(true);

        await WniBillingAndPaymentService.setUpAutoPayByStreamNumber(
            accountNumber, invoiceNumber, paymentInstrument, authHeader
        ).then(() => {
            updateShowInnerLoader(false);
            updateShowLoader(false);
            updateIsSetupPayment(false);
            updateIsSetupPaymentComplete(true);
        }).catch(() => {
            updateShowInnerLoader(false);
            updateShowLoader(false);
            modalApi.showAlert({
                title: messages.setupPaymentFailed,
                message: messages.setupPaymentFailedSorry,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            });
        });
    };


    const getUnpaidStatusData = () => {
        const { paymentGroup: { invoiceSummary } } = billingData;
        const unpaidStat = _.minBy(
            _.filter(invoiceSummary, (invoice) => { return invoice.invoiceStatus === 'planned'; }),
            (invoice) => {
                return new Date(invoice.dueDate).getTime();
            }
        );
        return unpaidStat;
    };
    const getAutomaticPaymentScheduleValue = () => {
        const { paymentGroup: { displayName = '', } } = billingData;
        // const displayValue = displayName.replace(/[\d()]/g, '').trim();
        const unpaidStat = getUnpaidStatusData();
        const formattedDate = WniDateUtil.formatDateWithPattern(_.get(unpaidStat, 'dueDate'));
        return <>
            {
                translator(messages.scheduleTermDescription, { periodicity: displayName, startDate: formattedDate })
            }
        </>
    };

    const getTotalPremium = () => {
        let totalPremium = 0;
        _.each(billingData.paymentGroup.invoiceSummary, (invoice) => {
            totalPremium += invoice.amountDue.amount;
        });
        return totalPremium;
    };

    const generateSetupPaymentOverrides = () => {
        const relatedPolicies = _.get(billingData, 'relatedPolicyNumbers', []);

        const overrides = relatedPolicies.map((relatedPolicy, index) => {
            return {
                [`tabAutomaticPaymentsSetupInvoicePolicy${index}`]: {
                    value: <div>{relatedPolicy}</div>
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const getCell = (item, index, property) => {
        return item[property.id];
    };

    const getFromattedDate = (item, index, property) => {
        return WniDateUtil.formatDateWithPattern(new Date(item[property.id]));
    };

    const renderFormattedCurrencyField = (item) => {
        return (
            <div className={accountBillingStyles.currencyContainer}>
                <CurrencyField
                    id="currency"
                    value={item}
                    readOnly
                    hideLabel
                />
            </div>
        );
    };

    const getFormattedCurrency = (item, index, property) => {
        return renderFormattedCurrencyField(item[property.id]);
    };

    const getBillStatus = (item, index, property) => {
        return translator(messages[item[property.id]]);
    };

    const getFormattedCurrencyPaymentSchedule = (item, index, property) => {
        const { amount, currency } = item.breakdown[property.id];
        return renderFormattedCurrencyField({ amount, currency });
    };

    const getFormattedCurrencyCharges = (item) => {
        const { charges, taxes } = item.breakdown;
        return (
            <CurrencyValue
                amount={charges.amount + taxes.amount}
                currency={charges.currency}
                showFractions
            />
        );
    };

    const onCancelPayment = () => {
        interactionModel.goToPage(null, history, 'accountBilling', accountNumber);
    };

    const getRemoveSuccessWithdrawVisible = (invoiceSummary, paymentInstrument) => {
        // effectiveDate_Ext => nearest billed => billdate & dueDate
        // if (billDate =< currentDate <= dueDate) => visible
        // else invisible
        const bankAccountData = _.get(paymentInstrument, 'bankAccountData');
        const effectiveDateExt = _.get(bankAccountData, 'effectiveDate_Ext');
        const effectiveDate = _.isNil(effectiveDateExt) ? '' : moment(effectiveDateExt);
        const unpaidStat = _.find(invoiceSummary, (invoice) => {
            const billDate = _.isNil(invoice.eventDate) ? '' : moment(invoice.eventDate);
            if (_.isEmpty(effectiveDate) || _.isEmpty(billDate)) {
                return invoice.invoiceStatus === 'billed';
            }
            return invoice.invoiceStatus === 'billed' && billDate.isSameOrAfter(effectiveDate);
        });
        const billDate = unpaidStat ? _.get(unpaidStat, 'eventDate') : '';
        const dueDate = unpaidStat ? _.get(unpaidStat, 'dueDate') : '';
        if (_.isEmpty(billDate) || _.isEmpty(dueDate)) {
            // not show
            return false;
        }
        const today = moment(sysLocalDate);
        if (today.isSameOrAfter(moment(billDate)) && today.isSameOrBefore(moment(dueDate))) {
            // show withdraw message
            return true;
        }
        return false;
    };

    const getSetupSuccessMessage = (paymentGroup) => {
        const formattedDate = paymentGroup.scheduleTerms
            ? WniDateUtil.formatDateWithPattern(paymentGroup.scheduleTerms[0].startDate) : 'Date Unavailable';
        return (
            <div>
                <span>{translator(customMessages.firstAutoDateMessage)}</span>
                <span className="font-Primary-bold-14">{formattedDate}</span>
            </div>
        );
    };

    const getBalanceDueMessage = () => {
        let amount = 0;
        _.forEach(unpaidInvoices, (invoice) => {
            if (invoice.paidStatus === 'unpaid') {
                // use * 100 and / 100 to avoid js float sum precision problem
                amount = amount * 100 + invoice.amountDue.amount * 100;
                amount /= 100;
            }
        });
        return (
            <div>
                <span>{translator(customMessages.accountHasBalanceDue, { balanceDue: amount })}</span>
                <span className="font-underline">{`${translator(customMessages.accountHasBalanceDueMust)}.`}</span>
            </div>
        );
    };

    const downloadAutoPayEnrollmentDocument = async () => {
        setLoadingMask(true);
        const successCallback = () => {
            setLoadingMask(false);
        };
        const errorCallback = () => {
            setLoadingMask(false);
            modalApi.showConfirm({
                title: 'Error',
                message: ServiceErrorUtil.prependWithFriendMessage(),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(() => {
                _.noop();
            }).catch(() => {
                _.noop();
            });
        };
        const doc = await WniGatewayBillingSubmissionService.findAutoPayEnrollment([accountNumber, invoiceNumber], authHeader);
        const targetProxiedServiceUrl = 'wniBCDocumentRetrieval';
        await DocumentsUtil.tryDownloadDocument(
            doc, authHeader, history, WniDocumentRetrievalService, successCallback, errorCallback, targetProxiedServiceUrl
        );
    };

    const getPaymentScheduleTitle = () => {
        if (periodicity) {
            return translator(messages.setupAutomaticPaymentSchedule,
                { displayName: `${invoiceNumber}(${periodicity})` });
        }
        return translator(messages.setupAutomaticPaymentSchedule,
            { displayName: `${invoiceNumber}` });
    };

    if (showLoader) {
        return <Loader loaded={!showLoader} />;
    }

    const overrideProps = {
        setupPaymentLoader: {
            visible: showInnerLoader,
            showLoader: showInnerLoader
        },
        removeAutomaticPaymentsSuccess: {
            visible: isRemovePaymentComplete
        },
        setupAutomaticPaymentsSuccess: {
            visible: isSetupPaymentComplete && !showInnerLoader
        },
        setupAutomaticPaymenetsSetup: {
            visible: !isSetupPaymentComplete && !isRemovePaymentComplete
        },
        paymentPageComponent: {
            xCenter: xCenter,
            isSetupPayment: isSetupPayment,
            paymentGroup: billingData.paymentGroup,
            paymentInstrument: billingData.paymentInstrument,
            handleRemoveAutoPayment: handleRemoveAutoPayment,
            nextLabel: translator(messages.setupPaymentConfirm),
            sysLocalDate: sysLocalDate
        },
        tabAutomaticPaymentsScheduleTitle: {
            content: getPaymentScheduleTitle()
        },
        paymentScheduleMessageLabel: {
            visible: getUnpaidStatusData(),
            value: getAutomaticPaymentScheduleValue()
        },
        paymentSchedulePremium: {
            value: getTotalPremium()
        },
        paymentScheduleDetailToggle: {
            icon: isDetailsedScheduleOpen ? 'gw-arrow-drop-up' : 'gw-arrow-drop-down',
            message: isDetailsedScheduleOpen
                ? translator(messages.hideDetailedSchedule)
                : translator(messages.showDetailedSchedule)
        },
        detailedScheduleContainer: {
            visible: isDetailsedScheduleOpen
        },
        detailedScheduleTable: {
            data: billingData.paymentGroup.invoiceSummary
        },
        // paymentSetupSuccessNextPayment: {
        //     message: getSetupPaymentNextPayment(billingData.paymentGroup.invoiceSummary)
        // },
        // paymentSetupSuccessDue: {
        //     message: getAutomaticPaymentScheduleDueDate(billingData.paymentGroup.invoiceSummary)
        // },
        paymentRemoveSuccessWithdrawMessage: {
            content: translator(customMessages.removeSuccessWithdrawMessage),
            visible: getRemoveSuccessWithdrawVisible(billingData.paymentGroup.invoiceSummary, billingData.paymentInstrument)
        },
        tabAutomaticHeaderTitle: {
            content: dataExist ? translator(customMessages.modifyAutomaticPayments) : translator(messages.setupAutomaticPayment)
        },
        tabAutomaticPaymentsSetupMessage: {
            visible: !dataExist
        },
        paymentSetupSuccessMessage: {
            content: getSetupSuccessMessage(billingData.paymentGroup)
        },
        balanceDueMessage: {
            content: getBalanceDueMessage(),
            visible: hasBalanceDue
        },
        autoPayEnrollmentDocument: {
            onClick: downloadAutoPayEnrollmentDocument,
            visible: !dataExist
        },
        ...generateSetupPaymentOverrides()
    };

    const resolvers = {
        resolveClassNameMap: accountBillingStyles,
        resolveCallbackMap: {
            getCell: getCell,
            getFromattedDate: getFromattedDate,
            getFormattedCurrency: getFormattedCurrency,
            getBillStatus: getBillStatus,
            getFormattedCurrencyPaymentSchedule: getFormattedCurrencyPaymentSchedule,
            getFormattedCurrencyCharges: getFormattedCurrencyCharges,
            handleTogglePaymentSchedule: handleTogglePaymentSchedule,
            handleConfirmSetupPayment: handleConfirmSetupPayment,
            onCancelPayment: onCancelPayment
        },
        resolveComponentMap: {
            loader: Loader,
            paymentpagecomponent: PaymentComponent
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            billingData,
            id,
            path,
            overrideProps
        );
    };

    return (
        <div className={accountBillingStyles.accountbilling}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={billingData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

AutoPaymentPage.propTypes = {
    location: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            accountNumber: PropTypes.string,
            invoiceNumber: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withViewModelService(withRouter(AutoPaymentPage));
