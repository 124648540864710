import React, {useEffect, useCallback, useMemo, useContext} from 'react'
import _ from 'lodash';
import { AccordionCard } from '@jutro/components';
import { WniDropdownSelect, WniInputText } from 'wni-common-base-components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMSignsService } from 'wni-capability-quoteandbind-im';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import messages from './CoveragesAccordionCard.messages'

import IMCoverageUtil from '../../../../util/IMCoverageUtil';
import CoverageContext from '../../../../context/IMCoverageContext';
import CoveragePartSchedule from './CoveragePartSchedule';

const CoveragesAccordionCard = (props) => {

    const {
        coveragePartClauses,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);

    const { signCoinsurance, coveragePartSchedule } = coveragePartClauses;

    const signCoveragesVM = useMemo(
        () =>
            viewModelService.create(
                coveragePartClauses,
                'pc',
                'wni.edge.capabilities.policyjob.lob.inlandmarine.coverages.dto.SignsCoveragesDTO'
            ),
        [coveragePartClauses, viewModelService]
    );

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();

    const validationID = 'SignsCoveragesAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])



    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        const coveragePartClausesToUpdate = IMCoverageUtil.generateUpdatedAccountReceivableClausesDTO(newCoveragePartClauses);
        setLoadingMask(true)
        const coveragePartClausesFromServer = await IMSignsService.updateAccountsReveivableClauses(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCoveragePartClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);


    return (
        <AccordionCard
            id="SignsCoverages"
            initialExpanded
            chevron
            cardHeadingClassName="font-PrimaryDark-bold-16 font-capitalize"
            title={translator(messages.Coverages)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <WniDropdownSelect
                        label={translator(messages.Coinsurance)}
                        id='Coinsurance'
                        readOnly
                        value={signCoinsurance}
                        availableValues={_.get(signCoveragesVM, 'signCoinsurance.aspects.availableValues', []).map(e => ({
                            code: e.code,
                            name: translator({id: e.name})
                        }))}
                    />
                    <CoveragePartSchedule
                        jobID={jobID}
                        sessionUUID={sessionUUID}
                        schedule={coveragePartSchedule}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        isEditable={isEditable}
                        setCoveragePartClauses={setCoveragePartClauses}
                        showErrors={showErrors}
                        onValidate={onValidate}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    )
}

export default CoveragesAccordionCard