import React, { useContext, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, InfoLabel, Tooltip } from '@jutro/components';
import { Grid } from '@jutro/layout';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { IntlContext, useTranslator } from '@jutro/locale';
import { JobUtil } from '@xengage/gw-portals-util-js';
import styles from './ActivityTableComponent.module.scss';
import NotesComponent from '../../NotesComponent/NotesComponent';
import metadata from './ActivityTableComponent.metadata.json5';
import ReassignActivity from '../ReassignActivityComponent/ReassignActivity';
import messages from './ActivityTableComponent.messages';

import { Button } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

const ActivityTableComponent = (props) => {
    const {
        activitiesOverdue,
        activitiesToday,
        activitiesTomorrow,
        activitiesThisWeek,
        activitiesComplete,
        displayNoActivities,
        activitiesFuture,
        createNote,
        onClickComplete,
        publicId
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const dateFormat = useCallback((date) => {
        return intl.formatDate(new Date(date), { year: 'numeric', month: 'short', day: 'numeric' });
    }, [intl]);

    const getPriority = (data,rowID) => {
        let priorityType = '';
        switch (data.priority) {
            case 'high':
                priorityType = 'warning';
                break;
            case 'normal':
                priorityType = 'success';
                break;
            case 'urgent':
                priorityType = 'error';
                break;
            case 'low':
                priorityType = 'neutral';
                break;
            default:
                break;
        }
        return (
            <div>
                <Tooltip placement="bottom" content={data.priorityDesc} id={`PriorityTooltip${rowID}`}>
                    <span>
                        <InfoLabel
                            type={priorityType}
                            size="small"
                            id={`PriorityLabel${rowID}`}
                            message={translator({
                                id: `typekey.Priority.${data.priority}`,
                                defaultMessage: data.priority
                            })}
                        />
                    </span>
                </Tooltip>
            </div>
        );
    };

    const getNotes = (data) => {
        return (
            <div>
                {data.notes.length}
                <Icon tag="span" className={styles.activityIcon} icon="mi-note" />
            </div>
        );
    };

    const getNoOfDueDays = (data) => {
        let dueDaysColumn = '';
        if (data.status === 'complete' || data.dueDate === null || data.dueDate === undefined) {
            dueDaysColumn = '-';
        } else {
            const today = moment();
            const tomorrow = moment().add(1, 'days');
            const dateDifference = today.diff(moment(data.dueDate), 'days');
            const yesterday = moment().subtract(1, 'days');
            if (dateDifference === 1) {
                dueDaysColumn = (
                    <div className={styles.cssNoOfDueDays}>{translator(messages.yesterday)}</div>
                );
            } else if (moment(data.dueDate).isBefore(yesterday, 'd')) {
                dueDaysColumn = (
                    <div className={styles.cssNoOfDueDays}>
                        {translator(messages.daysAgo, { days: Math.floor(dateDifference) })}
                    </div>
                );
            } else if (moment(data.dueDate).isSame(tomorrow, 'd')) {
                dueDaysColumn = translator(messages.tomorrow);
            } else if (moment(data.dueDate).isSame(today, 'd')) {
                dueDaysColumn = translator(messages.today);
            } else if (moment(data.dueDate).isBefore(moment().endOf('isoWeek'), 'd')) {
                switch (moment(data.dueDate).isoWeekday()) {
                    case 1:
                        dueDaysColumn = translator(messages.monday);
                        break;
                    case 2:
                        dueDaysColumn = translator(messages.tuesday);
                        break;
                    case 3:
                        dueDaysColumn = translator(messages.wednesday);
                        break;
                    case 4:
                        dueDaysColumn = translator(messages.thursday);
                        break;
                    case 5:
                        dueDaysColumn = translator(messages.friday);
                        break;
                    case 6:
                        dueDaysColumn = translator(messages.saturday);
                        break;
                    case 7:
                        dueDaysColumn = translator(messages.sunday);
                        break;
                    default:
                        break;
                }
            } else {
                dueDaysColumn = dateFormat(data.dueDate);
            }
        }
        return dueDaysColumn;
    };

    const onJobClicked = (data) => {
        let redirectPath = '';
        if (data.productName && data.policyNumber && !data.jobNumber) {
            const policyUrl = `/policies/${data.policyNumber}/summary`;
            redirectPath = (
                <RouterLink
                    className={styles.removeLinkStyle}
                    title={data.productName}
                    to={policyUrl}
                >
                    {data.productName}
                </RouterLink>
            );
        } else if (data.productName && data.jobNumber && !JobUtil.isSupportJobType(data.jobType)) {
            redirectPath = (
                <Link
                    href="/"
                    className={styles.removeLinkStyle}
                    title={data.productName}
                    onClick={() => JobUtil.openJobInXCenter(data.jobNumber)}
                >
                    {data.productName}
                </Link>
            );
        } else {
            redirectPath = (
                <RouterLink
                    className={styles.removeLinkStyle}
                    title={data.productName}
                    to={JobUtil.getJobDetailURLByJobType(data.jobType, data.jobNumber)}
                >
                    {data.productName}
                </RouterLink>
            );
        }
        return redirectPath;
    };

    const getSubject = (data) => {
        const redirectRoute = 'summary';
        let subjectData = '';
        const text = translator(messages.for);
        if (data.productName !== undefined && data.productName !== null) {
            subjectData = (
                <div style={{ whiteSpace: 'normal' }}>
                    {`${data.subject} ${text} `}
                    {onJobClicked(data)}
                    {` ${text} `}
                    <RouterLink
                        to={`/accounts/${data.accountNumber}/${redirectRoute}`}
                        className={styles.removeLinkStyle}
                        title={data.accountHolderName}
                    >
                        {data.accountHolderName}
                    </RouterLink>
                </div>
            );
        } else {
            subjectData = (
                <div style={{ whiteSpace: 'normal' }}>
                    {`${data.subject} ${text} `}
                    <RouterLink
                        to={`/accounts/${data.accountNumber}/${redirectRoute}`}
                        className={styles.removeLinkStyle}
                        title={data.accountHolderName}
                    >
                        {data.accountHolderName}
                    </RouterLink>
                </div>
            );
        }
        return subjectData;
    };

    const expanderContent = (data) => {
        const description = _.get(data, 'description', '-');
        const getEscalationDate = !_.isUndefined(data.escalationDate)
            ? dateFormat(data.escalationDate)
            : '-';

        return (
            <div className={styles.gwActivityContent}>
                <Grid
                    columns={['0.25fr', '0.75fr', '0.25fr', '1fr']}
                    rows={['1fr', '1fr', '1fr']}
                    gap="large"
                    className={styles.gwActivityTableRowDescription}
                >
                    <div className={styles.field}>{translator(messages.escalationDate)}</div>
                    <div className={styles.fieldValue}>{getEscalationDate}</div>
                    <div className={styles.field}>{translator(messages.createdBy)}</div>
                    <div className={styles.fieldValue}>{data.createdBy}</div>
                    <div className={styles.field}>{translator(messages.aType)}</div>
                    <div className={styles.fieldValue}>{data.subject}</div>
                    <div className={styles.field}>{translator(messages.created)}</div>
                    <div className={styles.fieldValue}>{dateFormat(data.createdDate)}</div>
                    <div className={styles.field}>{translator(messages.description)}</div>
                    <div className={styles.fieldValue}>{description}</div>
                </Grid>
                <div className={styles.gwActivityTableNotesSeparator}>
                    <NotesComponent
                        initialNotesData={data.notes}
                        createNote={createNote}
                        publicID={data.publicID}
                        openActivitiesNote
                        showNoteButton={!data.closeDate}
                    />
                </div>
            </div>
        );
    };

    const getAssignedActivity = (item) => {
        const reAssignActivity = (activity) => {
            _.merge(item, activity);
        };
        return <ReassignActivity activity={item} onUpdateActivity={reAssignActivity} />;
    };

    const completeButton = useCallback(
        (item) => {
            let completedButton;
            if (item.canComplete) {
                completedButton = (
                    <div key={item.publicID}>
                        <Button
                            className={styles.gwHideCompleteButton}
                            onClick={() => onClickComplete(item.publicID)}
                            icon="mi-check"
                        >
                            {translator(messages.complete)}
                        </Button>
                    </div>
                );
            } else if (item.status === 'complete') {
                completedButton = (
                    <div>
                        <Icon tag="span" icon="mi-check" className={styles.activityIcon} />
                        {translator(messages.completed)}
                    </div>
                );
            } else {
                completedButton = null;
            }
            if (item.publicID === publicId) {
                return (
                    <div>
                        <Icon tag="span" icon="mi-check" className={styles.activityIcon} />
                        {translator(messages.completed)}
                    </div>
                );
            }
            return completedButton;
        },
        [onClickComplete, translator, publicId]
    );

    const overrideProps = {
        activitiesFutureTable: {
            data: activitiesFuture,
            renderExpanderContent: expanderContent,
            visible: activitiesFuture.length > 0
        },
        activitiesTodayTable: {
            data: activitiesToday,
            renderExpanderContent: expanderContent,
            visible: activitiesToday.length > 0
        },
        activitiesTomorrowTable: {
            data: activitiesTomorrow,
            renderExpanderContent: expanderContent,
            visible: activitiesTomorrow.length > 0
        },
        activitiesThisWeekTable: {
            data: activitiesThisWeek,
            renderExpanderContent: expanderContent,
            visible: activitiesThisWeek.length > 0
        },
        activitiesOverdueTable: {
            data: activitiesOverdue,
            renderExpanderContent: expanderContent,
            visible: activitiesOverdue.length > 0
        },
        activitiesCompleteTable: {
            data: activitiesComplete,
            visible: activitiesComplete.length > 0,
            renderExpanderContent: expanderContent
        },
        noActivityFound: {
            visible: displayNoActivities !== false
        },
        noActivitySeparator: {
            visible: displayNoActivities !== false
        },
        activitiesDetailGrid: {
            visible: displayNoActivities === false
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getNoOfDueDays: getNoOfDueDays,
            getSubject: getSubject,
            getNotes: getNotes,
            getPriority: getPriority,
            completeButton: completeButton,
            getAssignedActivity: getAssignedActivity,
            onRowClick: (data) => {
                _.set(data, 'expanded', !data.expanded);
            }
        }
    };

    const openActivityPage = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers} />;
    return <div className={classNames(styles.summary)}>{openActivityPage}</div>;
};

ActivityTableComponent.propTypes = {
    activitiesOverdue:  PropTypes.arrayOf(PropTypes.shape({})),
    activitiesFuture: PropTypes.arrayOf(PropTypes.shape({})),
    activitiesTomorrow: PropTypes.arrayOf(PropTypes.shape({})),
    activitiesThisWeek:  PropTypes.arrayOf(PropTypes.shape({})),
    activitiesToday: PropTypes.arrayOf(PropTypes.shape({})),
    activitiesComplete:  PropTypes.arrayOf(PropTypes.shape({})),
    onClickComplete: PropTypes.func.isRequired,
    createNote: PropTypes.func.isRequired,
    displayNoActivities: PropTypes.bool.isRequired,
    publicId: PropTypes.string
};
ActivityTableComponent.defaultProps = {
    activitiesTomorrow: [],
    activitiesFuture:[],
    activitiesOverdue:[],
    activitiesComplete: [],
    activitiesThisWeek: [],


}
export default withAuthenticationContext(ActivityTableComponent);
