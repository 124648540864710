import React, {
    useCallback
}from 'react';
import _ from 'lodash';
import HOPrimaryCoverages from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOPrimaryCoverages/HOPrimaryCoverages';

const DPPrimaryCoverages = (props) => {

    const {
        selectedVersion,
        submissionVM
    } = props;

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            dwellingProperty: {
                offerings
            }
        }
    } = submissionVM.value;

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const primaryCoveragePath = `lobData.dwellingProperty.offerings[${selectedVersionIndex}].coverages.primaryCoverages_Ext`;
    const getPrimaryCoverages = useCallback(() => {
        // const primaryCoverages = _.get(selectedVersion, 'coverages.primaryCoverages_Ext', []);
        const primaryCoverages = _.get(submissionVM.value, primaryCoveragePath, []);
        return primaryCoverages;
    }, [selectedVersion]);
    
    const primaryCoverages = getPrimaryCoverages();
    let isCovBLinkVisible = false;
    primaryCoverages.forEach((coverage) => {
            
        if (_.get(coverage, 'code_Ext') === 'DPCoverageBSchedCov_Ext') {
            const allTerms = _.get(coverage, 'terms', [])
            // show links when has private related structure under coverage B or Click yes for
            //  'Do you want additional infomational now?'
            const addItNowTerm = allTerms.find((term) => {
                return _.get(term, 'code_Ext') === 'PortalCustomCovTerm_IsAddItNow'
            })
            const addItNowTermChosenTerm = _.get(addItNowTerm, 'chosenTerm')
            const hoPrivateStructures = _.get(coverage, 'hoprivateStructures', [])
            if (addItNowTermChosenTerm === true || addItNowTermChosenTerm === 'true' || _.get(hoPrivateStructures, 'length', 0) > 0) {
                isCovBLinkVisible = true;
            } 
        }
    })
    const covBHasStructure = !!primaryCoverages.find((coverage) => {
        if (!_.get(coverage, 'code_Ext') === 'DPCoverageBSchedCov_Ext') {
            return false
        }
        const hoprivateStructures = _.get(coverage, 'hoprivateStructures', []);
        return _.get(hoprivateStructures, 'length', 0) > 0
    });

    const DPOverrideProps = {
        primaryCoverages: {
            sortBySelected: false,
            hideCovBstructureRelatedTerms: covBHasStructure,
            isCovBLinkVisible: isCovBLinkVisible
        }
    }

    return <HOPrimaryCoverages
        {...props}
        DPOverrideProps = {DPOverrideProps}
        isDPcoverages
    />
}

DPPrimaryCoverages.propTypes = HOPrimaryCoverages.propTypes;
DPPrimaryCoverages.defaultProps = HOPrimaryCoverages.defaultProps;

export default DPPrimaryCoverages;