import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import {wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { HOAddIntService, HOMultiQuoteService } from 'wni-capability-quoteandbind-ho';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';
import { QuoteUtil } from 'wni-portals-util-js';
// import WizardPage from '../../templates/HOWizardPage';

import BaseAdditionalInterestDetailComponent from './BaseAddIComponent/BaseAddIDetailComponent/BaseAdditionalInterestDetailComponent'

import styles from './BaseAdditionalInterestsPageComponent.module.scss';
import metadata from './BaseAdditionalInterestsPageComponent.metadata.json5';
import messages from './BaseAdditionalInterestsPageComponent.messages';
import { Button } from '@jutro/legacy/components';

function BaseAdditionalInterestsPageComponent(props) {
    const modalApi = useWniModal();
    // TODO: can not change personA to personB or BankC to BankD
    const {
        currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        updateWizardSnapshot,
        updateWizardPageData,
        //
        processQuote,
        lobDataProductKey: LOB_NAME,
        dtoVMPath: DTOVM_PATH,
        WizardPage,
        showAdditionalInterestInsuredType,
        showAdditionalInterestPleaseExplain,
        showAdditionalInterestSpecificInterest,
        showAdditionalInterestNameOfSchool,
        addIntService,
        lineSpecialAdditionalInterestTypeValueChangeFn,
        lineSpecialAdditionalInterestInsuredTypeValueChangeFn,
        lineSpecialRenderInterestTypeFn
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            jobType,
            accountNumber,
            accountContacts_Ext: accountContacts,
            periodStatus,
        },
        lobData: {
            // homeowners: {
            [LOB_NAME]: {
                coverables: {
                    additionalInterests
                }
            }
        }
    } = submissionVM.value;

    const translator = useTranslator();
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const { workflowType } = useDependencies('workflowType');
    const { loadingMask: { setLoadingMask }, workflowType }= useDependencies(['workflowType', 'loadingMask']);
    const [selection, updateSelection] = useState([]);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        // onValidate,
        // invalidFields,
        // isComponentValid,
    } = useValidation('BaseAdditionalInterestsPageComponent');

    const [validationIssues, updateValidationIssues] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [isInterestTypeResetinTable, updateIsInterestTypeResetinTable] = useState(false);
    const [currentRow, updateCurrentRow] = useState(null);
    const emptyVM = viewModelService.create({}, 'pc', DTOVM_PATH);
    const typeListsAvailableValues = _.get(emptyVM, 'type.aspects.availableValues', []);
    const dpaddInsuredTypeListsAvailableValues = _.get(emptyVM, 'dpaddInsuredType_Ext.aspects.availableValues', []);
    const [viewEditType, updateViewEditType] = useState('');

    const ADD_INT_VALUE_PATH = `value.lobData.${LOB_NAME}.coverables.additionalInterests`;


    const checkIfSomeDataHasStudentInsuredType = useCallback(() => {
        const isAllDataHasStudentInsuredType = additionalInterests.some(item =>{
            return item.dpaddInsuredType_Ext === 'StudentLivingAway'
        })
        return isAllDataHasStudentInsuredType
    }, []); 
    const resetInsuredType = useCallback(() => {
        additionalInterests.forEach(item => {
            if(item.dpaddInsuredType_Ext==="StudentLivingAway"){
                item.dpaddInsuredType_Ext  = ''
            }
        });
    }, []);

    const showInterstsErrorMessage = useCallback((type,message) => {
        updateValidationIssues([{
                type: type,
                reason: message
            }])
    }, []);

    //Watch whether the dependent value Occupancy of the form data allows the value of the form's Interest Type field to be Student
    useEffect(() => {
    // This is a common component, judege dwellingUsage value only when this component use for DPAdditionalInterestsPage
    if(currentPageId==='DPAdditionalInterestsPage'){    
                const dwellingUsage = _.get(submissionVM, 'value.lobData.dwellingProperty.householdInfoDTO_Ext.namedInsuredOccupancy')
                const isOwner = dwellingUsage.includes('owner')
                const isSomeDataHasStudent = checkIfSomeDataHasStudentInsuredType()
        if(isOwner===false && isSomeDataHasStudent ){
                updateIsInterestTypeResetinTable(true)
                resetInsuredType()
                updateShowErrors(true)
                showInterstsErrorMessage('error','Please check the table list,part of the field "Interest Type" in the Additional Interests table has benn reset to empty value,it may be because you have changed the option of the describe occupancy in the Household Information step.')
        }else{
                updateShowErrors(false)
                updateIsInterestTypeResetinTable(false)
        }
    }
    }, []);
    
    const checkAllDataNotEmptyInsuredType = useCallback(() => {
        const isAllDataNotEmpty = additionalInterests.every(item =>{
            return item.dpaddInsuredType_Ext !==""
        })
        return isAllDataNotEmpty
    }, []);

    //if additionalInterests changed,check table
    useEffect(() => {
        if(checkAllDataNotEmptyInsuredType && showErrors){
            //if all data have value of dpaddInsuredType_Ext
            updateShowErrors(false)
            updateValidationIssues([])
            updateIsInterestTypeResetinTable(false)
        }
    }, [additionalInterests]);

    const createEmptyVM = useCallback(() => {
        const newAdditionalInterestDTOVM = viewModelService.create({
            policyAdditionalInterest: {
                primaryAddress: '',
            }
        }, 'pc', DTOVM_PATH);
        return newAdditionalInterestDTOVM;
    }, [DTOVM_PATH, viewModelService]);

    const viewOrEditVehicleFn = useCallback(async (value) => {
        const rowVM = viewModelService.create(value, 'pc', DTOVM_PATH);
        updateCurrentRow(rowVM);
        updateViewEditType('edit');
    }, [DTOVM_PATH, viewModelService]);

    const renderViewEditDom = useCallback(() => {
        return (
            <div className="activeRow btn-link">
                {translator(messages.viewAndEditLabel)}
            </div>
        );
    }, [translator]);

    const addAdditionalInterestFn = useCallback(() => {
        const newAdditionalInterestDTOVM = createEmptyVM();
        updateCurrentRow(newAdditionalInterestDTOVM);
        updateViewEditType('');
    }, [createEmptyVM]);

    const updateCurrentEditingAdditionalInterest = useCallback((value, path) => {
        _.set(currentRow, path, value);
        updateCurrentRow(currentRow);
    }, [currentRow]);

    const handleSaveAdditionalInterestFn = useCallback(async (getAdditionalInterestVM, innerIsComponentValid, handleValidation) => {
        // same logic as BaseAdditionalInterestComponet#handleSave
        if (!innerIsComponentValid) {
            handleValidation();
            return false;
        }
        if (_.get(getAdditionalInterestVM, 'value.policyAdditionalInterest.phoneRequired_Ext')) {
            _.set(getAdditionalInterestVM, 'value.policyAdditionalInterest.phoneRequired_Ext', false);
        }
        // set sendBill_Ext default false value when it is null
        if (_.isNil(_.get(getAdditionalInterestVM, 'value.sendBill_Ext'))) {
            _.set(getAdditionalInterestVM, 'value.sendBill_Ext', false);
        }
        setLoadingMask(true);
        try {
            const remainAdditionalInterests = await addIntService.createOrUpdateAdditionalInterest(jobID, sessionUUID, _.get(getAdditionalInterestVM, 'value'), authHeader);
            updateSelection([]);
            const vm = viewModelService.clone(submissionVM);
            _.set(vm, ADD_INT_VALUE_PATH, remainAdditionalInterests);
            updateCurrentRow(null);

            _.set(vm, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
            updateWizardSnapshot(vm);
        } catch (e) {
            _.noop();
        } finally {
            setLoadingMask(false);
        }
    }, [ADD_INT_VALUE_PATH, authHeader, jobID, sessionUUID, setLoadingMask,
        submissionVM, updateWizardSnapshot, viewModelService])

    const handleCancelFn = useCallback(() => {
        updateCurrentRow(null);
    }, [])

    const removeAdditionalInterestFn = useCallback(() => {
        modalApi.showConfirm({
            title: messages.removeAdditionalInterestTitle,
            message: messages.removeAdditionalInterestDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.deletionModalOk,
            cancelButtonText: messages.deletionModalCancel
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                setLoadingMask(true);
                let remainAdditionalInterests = await addIntService.removeAdditionalInterest(jobID, sessionUUID, selection, authHeader)
                if (_.isEmpty(remainAdditionalInterests)) {
                    remainAdditionalInterests = [];
                }
                updateSelection([]);
                // const vm = _.cloneDeep(submissionVM);
                const vm = viewModelService.clone(submissionVM);
                _.set(vm, ADD_INT_VALUE_PATH, remainAdditionalInterests);
                updateCurrentRow(null);

                _.set(vm, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
                updateWizardSnapshot(vm);
                return true;
            }, _.noop
        ).finally(() => {
            setLoadingMask(false);
        });
    }, [ADD_INT_VALUE_PATH, addIntService, authHeader, jobID, modalApi,
        selection, sessionUUID, setLoadingMask, submissionVM, updateWizardSnapshot]);

    const defaultHandleAdditionalInterestTypeValueChangeFn = useCallback((
        newValue,
        vmService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        writeValue
    ) => {
        if (newValue !== 'FIRSTMORTGAGEE') {
            const newAdditionalInterestVM = vmService.clone(getAdditionalInterestVM);
            _.set(newAdditionalInterestVM, 'type', newValue);
            _.set(newAdditionalInterestVM, 'sendBill_Ext', false);
            updateAdditionalInterestVM(newAdditionalInterestVM);
        } else {
            writeValue(newValue, 'type');
        }
    }, []);

    const handleAdditionalInterestTypeValueChangeFn = lineSpecialAdditionalInterestTypeValueChangeFn === _.noop ? defaultHandleAdditionalInterestTypeValueChangeFn : lineSpecialAdditionalInterestTypeValueChangeFn;

    const handleCustomActionFooterFn = useCallback((
        companyType, showBankDetail, readOnly, showBankSearch, newSearchBtnLabel,
        onReject, cancelBtnLabel, handleSave, actionBtnLabel, selectRow,
        handleSelectBank, getAdditionalInterestVM, innnerIsComponentValid, handleValidation
    ) => {
        const actionLabel = 'Save & Close';
        if (companyType === 'Bank') {
            let btns = null;
            if (showBankDetail) {
                btns = (
                    <Flex justifyContent="between" className="mt-10">
                        <Button onClick={() => handleCancelFn()} type="outlined">{cancelBtnLabel}</Button>
                        <div>
                            <Button disabled={readOnly} onClick={showBankSearch} type="filled">{newSearchBtnLabel}</Button>
                            <Button disabled={_.isEmpty(companyType) || readOnly} onClick={() => handleSaveAdditionalInterestFn(getAdditionalInterestVM, innnerIsComponentValid, handleValidation)} type="filled">{actionLabel}</Button>
                        </div>
                    </Flex>
                );
            } else {
                btns = (
                    <Flex justifyContent="between" className="mt-10">
                        <Button onClick={() => handleCancelFn()} type="outlined">{cancelBtnLabel}</Button>
                        <Button disabled={_.isEmpty(selectRow) || readOnly} onClick={handleSelectBank} type="filled">{actionBtnLabel}</Button>
                    </Flex>
                );
            }
            return btns;
        }
        return (
            <>
                <Flex justifyContent="between" className="mt-10">
                    <Button onClick={() => handleCancelFn()} type="outlined">{cancelBtnLabel}</Button>
                    <Button disabled={_.isEmpty(companyType) || readOnly} onClick={() => handleSaveAdditionalInterestFn(getAdditionalInterestVM, innnerIsComponentValid, handleValidation)} type="filled">{actionLabel}</Button>
                </Flex>
            </>
            
            
        );
    }, [handleCancelFn, handleSaveAdditionalInterestFn]);

    const renderInterestType = useCallback((item, index, property) => {
        const typeValue = _.get(item, property.path);
        let value = '';
        if (typeListsAvailableValues !== null) {
            const interestTypeKey = _.find(typeListsAvailableValues, (ttx) => ttx.code === typeValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [translator, typeListsAvailableValues]);

    const renderYesNo = useCallback((item, index, property) => {
        const typeValue = _.get(item, property.path);
        const value = typeValue ? 'Yes' : 'No';
        return value;
    }, []);
 

    const onDefaultPageNext = async () =>{
        if(isInterestTypeResetinTable){
            // InterestType in table have been reset,show error message,cant go to next page
            return false
        }else{
            if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
                updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
                // submissionVM.value = await HOMultiQuoteService.processQuote(
                submissionVM.value = await processQuote(
                    jobID, sessionUUID, authHeader
                );
                return submissionVM
            }
        }
    }

    const onNext = async () => {
        const {
            onPageNext = onDefaultPageNext
        } = props
        
        const res = await onPageNext();
        return res
    };

    const isBank = _.get(currentRow, 'value.policyAdditionalInterest.companyType_Ext', null) === 'Bank';

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            removeAdditionalInterest: {
                onClick: removeAdditionalInterestFn,
                disabled: selection.length <= 0,
            },
            addAdditionalInterest: {
                onClick: addAdditionalInterestFn,
            },
            additionalInterestTable: {
                noDataText: translator(messages.noDataText),
                data: additionalInterests,
                onSelectionChange: (rows) => updateSelection(rows),
                rowIdPath: 'fixedId',
            },
            interestType: {
                path: 'type',
                renderCell:
                    lineSpecialRenderInterestTypeFn === _.noop
                        ? renderInterestType
                        : (item, index, property) =>
                              lineSpecialRenderInterestTypeFn(
                                  item,
                                  index,
                                  property,
                                  translator,
                                  typeListsAvailableValues,
                                  dpaddInsuredTypeListsAvailableValues
                              ),
            },
            sendBill: {
                path: 'sendBill_Ext',
                renderCell: renderYesNo,
            },
            loanNumber: {
                path: 'contractNumber',
            },
            viewOrEdit: {
                label: renderViewEditDom(),
                onClick: viewOrEditVehicleFn,
                disabled: currentRow,
            },
            currentEditingAddtionalInterest: {
                visible: currentRow != null,
                model: currentRow,
                emptyVM: emptyVM,
                onValueChange: updateCurrentEditingAdditionalInterest,
                showErrors,
                jobID,
                sessionUUID,
                handleSaveAdditionalInterestFn,
                handleCancelFn,
                accountContacts,
                accountNumber,
                jobType,
                viewEditType,
                workflowType,
                handleAdditionalInterestTypeValueChangeFn,
                handleAdditionalInterestInsuredTypeValueChangeFn:
                    lineSpecialAdditionalInterestInsuredTypeValueChangeFn,
                handleCustomActionFooterFn,
                showLoanNumber: isBank,
                productCode: LOB_NAME,
                showAdditionalInterestInsuredType,
                showAdditionalInterestPleaseExplain,
                showAdditionalInterestSpecificInterest,
                showAdditionalInterestNameOfSchool,
            },
        };
    }, [LOB_NAME, accountContacts, accountNumber, addAdditionalInterestFn,
        additionalInterests, currentRow, emptyVM, handleAdditionalInterestTypeValueChangeFn,
        handleCancelFn, handleCustomActionFooterFn, handleSaveAdditionalInterestFn,
        isBank, jobID, jobType, removeAdditionalInterestFn, renderInterestType,
        renderViewEditDom, renderYesNo, selection.length, sessionUUID,
        showAdditionalInterestInsuredType, showAdditionalInterestNameOfSchool,
        showAdditionalInterestPleaseExplain, showAdditionalInterestSpecificInterest,
        showErrors, translator, updateCurrentEditingAdditionalInterest, viewEditType,
        viewOrEditVehicleFn, workflowType, lineSpecialAdditionalInterestInsuredTypeValueChangeFn,
        lineSpecialRenderInterestTypeFn, dpaddInsuredTypeListsAvailableValues,
        typeListsAvailableValues]
    );

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            additionalinterestdetailcomponent: BaseAdditionalInterestDetailComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            // skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            onNext={onNext}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

BaseAdditionalInterestsPageComponent.propTypes = {
    ...wizardProps,
    //
    processQuote: PropTypes.func,
    /**
     * (Optional): the lobData path segment that describes the path of 
     * current product, e.g. 'homeowners' or 'dwellingProperty'.
     * 
     * Refactoring Notice: Could lobName be a better property name?
     */
    lobDataProductKey: PropTypes.string,
    dtoVMPath: PropTypes.string,
    lineSpecialAdditionalInterestTypeValueChangeFn: PropTypes.func,
    lineSpecialAdditionalInterestInsuredTypeValueChangeFn: PropTypes.func,
    lineSpecialRenderInterestTypeFn: PropTypes.func
};

BaseAdditionalInterestsPageComponent.defaultProps = {
    processQuote: HOMultiQuoteService.processQuote,
    lobDataProductKey: 'homeowners',
    dtoVMPath: 'wni.edge.capabilities.quote.lob.homeowners.dto.HOAdditionalInterestDTO',
    lineSpecialAdditionalInterestTypeValueChangeFn: _.noop,
    lineSpecialAdditionalInterestInsuredTypeValueChangeFn: _.noop,
    lineSpecialRenderInterestTypeFn: _.noop
};

export default BaseAdditionalInterestsPageComponent;
