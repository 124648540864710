

import React from 'react';
import { IMWarehouseLegalLiabilityReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMWarehouseLegalLiabilityChangeReadOnlyPage = (props) => {
    return <IMWarehouseLegalLiabilityReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMWarehouseLegalLiabilityChangeReadOnlyPage
