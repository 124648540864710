import _ from 'lodash';
import { requireForIssuance } from "../Config/CAVehicle.static";

const getVehicleTypeMaps = (data = [], translator) => {
    return data.map((item) => {
        return {
            code: item,
            name: translator({id: `typekey.CA7VehicleType.${item}`})
        }
    })
}

const getVehicleFilterSetMaps = (data = []) => {
    return data.map((item) => {
        return {
            code: item,
            name: item
        }
    })
}
const generateIssuanceValid = (itemVM) => {
    const displayabledIssuanceValid = _.get(itemVM.value, 'bulkVehicleDetails', []);
    const invalidIssuanceFields = [];
    requireForIssuance.forEach((item) => {
        const findItem = displayabledIssuanceValid.find((v) => v.propertyName === item);
        const { fieldValue } = findItem || {};
        if (_.isNil(fieldValue) || fieldValue.length === 0) {
            invalidIssuanceFields.push(item);
        }

    });
    return invalidIssuanceFields
};

const isOrigionalCostNewValid = (vehicleData, origionalCostNewValue) => {
    const origionalCostNew = _.get(vehicleData, 'bulkVehicleDetails', []).find((item) => item.propertyName === 'OriginalCostNew');
    if(!origionalCostNew) {
        return true;
    }
    const ocnValue = origionalCostNewValue || origionalCostNew.integerValue;
    const pricedigest = _.get(vehicleData, 'priceDigestDetails');
    if(pricedigest && pricedigest.length ===1 && !pricedigest[0].errorCode) {
        const digestDetail = pricedigest[0];
        let vendorValue = digestDetail.msrp
        if ((digestDetail.msrp && digestDetail.truckBodyHighMSRP > digestDetail.msrp) || !digestDetail.msrp) {
            vendorValue = digestDetail.truckBodyHighMSRP;
        }

        if(ocnValue && vendorValue && ocnValue < vendorValue) {
            return false
        }
        return true
        
    }
    return true
    
};

export {
getVehicleTypeMaps,
getVehicleFilterSetMaps,
generateIssuanceValid,
isOrigionalCostNewValid
};
