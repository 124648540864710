import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cppLineSelectionService'), method, data, additionalHeaders);
}

export default class CPPLineSelectionService {
   
    /**
     * Invokes CPPLineSelection.onPageNext()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Array} lineSelectionData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onPageNext(quoteID, sessionUUID, lineSelectionData, authHeader = {}) {
        return processSubmission('onPageNext', [quoteID, sessionUUID, lineSelectionData], authHeader);
    }


    static onCheckboxChange(quoteID, sessionUUID, lineSelectionData, authHeader = {}) {
        return processSubmission('onPageNext', [quoteID, sessionUUID, lineSelectionData], authHeader);
    }
}