import _ from 'lodash';
import { PortalConstants } from 'wni-portals-config-js';

const {
    PRICE_VIEW_MODES,
    isQuoted,
} = PortalConstants;

const SXS_PAIR_AT_MOST_NUM = 2;
const SXS_PAIR_AT_LEAST_NUM = 1;

const PAYMENT_PLAN_TYPES = _.clone(PRICE_VIEW_MODES);

/**
 * Checks whether current quote is in "Rated" status
 * @param {object} quoteDataDtoVM QuoteDataDTO / PolicyChangeDataDTO view model object
 * @returns {boolean} true if current quote is already rated.
 */
function isStatusQuotedOrRated(quoteDataDtoVM) {
    // const periodStatus = _.get(quoteDataDtoVM, 'baseData.periodStatus.value.code');
    const {
        // ====for QuoteDataDTO=================
        baseData: {
            periodStatus
        } = {},
        // ====for PolicyChangeDataDTO==========
        jobID,
        status: jobStatus,
    } = quoteDataDtoVM.value;
    if (_.isNil(jobID)) { // QuoteDataDTO
        return periodStatus === 'Rated' || periodStatus === 'Quoted';
    }
    return jobStatus === 'Rated' || jobStatus === 'Quoted';
}

/**
 * Checks whether current quote is in "Rated" status
 * 
 * The difference between this function and isStatusQuotedOrRated is 
 * isStatusQuotedOrRated accepts QuoteDataDTO / PolicyChangeDataDTO, while
 * isWizardDataDTOStatusQuotedOrRated accepts SubmissionWizardDataDTO / PolicyChangeWizardDataDTO
 * @param {object} wizardDataDTOVM SubmissionWizardDataDTO/PolicyChangeWizardDataDTO view model object
 * @returns {boolean} true if current quote is already rated.
 */
function isWizardDataDTOQuotedOrRated(wizardDataDTOVM) {
    // const periodStatus = _.get(quoteDataDtoVM, 'baseData.periodStatus.value.code');
    const {
        // ====for SubmissionWizardDataDTO and PolicyChangeWizardDataDTO
        baseData: {
            periodStatus
        } = {},
    } = wizardDataDTOVM.value;
    // return periodStatus === 'Rated' || periodStatus === 'Quoted' || periodStatus === 'Approved';
    return isQuoted(periodStatus);
}

/**
 * Get a skipFn that
 * 1) either returns true when current quote is Rated or Quoted, or
 * 2) returns the result of defaultSkipFn() if it exists
 * @param {Function} defaultSkipFn
 * @returns {Function} a skipFn that could be used by WizardPage
 */
function getSkipRatedQuotedFn(defaultSkipFn) {
    return (quoteData) => {
        let retval = false;
        if (isStatusQuotedOrRated(quoteData)) {
            retval = true;
        } else if (defaultSkipFn) {
            retval = defaultSkipFn(quoteData);
        }
        return retval;
    };
}

/**
 * Get a skipFn that
 * 1) either returns true when current quote is Rated or Quoted, or
 * 2) returns the result of defaultSkipFn() if it exists
 * 
 * //
 * Caution: please do not provide on unused (i.e. the corresponding onValidate method
 * has never been used) initialValidation as defaultSkipFn,
 * since the Promise might never resolve.
 * 
 * 
 * The difference between v1 and v2:
 * getSkipRatedQuotedFn: accepts QuoteDataDTO and PolicyChangeDataDTO
 * getSkipRatedQuotedFnV2: accepts SubmissionWizardDataDTO and PolicyChangeWizardDataDTO
 * @param {Function} defaultSkipFn
 * @returns {Function} a skipFn that could be used by WizardPage
 */
function getSkipRatedQuotedFnV2(defaultSkipFn) {
    return (quoteData) => {
        let retval = false;
        if (isWizardDataDTOQuotedOrRated(quoteData)) {
            retval = true;
        } else if (defaultSkipFn) {
            retval = defaultSkipFn(quoteData);
        }
        return retval;
    };
}

// /**
//  * Checks whether current producer code is licensed and appointed
//  * @param {object} quoteDataDtoVM the submissionVM view model object
//  * @returns {boolean}
//  */
// function isProducerLicensedAndApppointed(quoteDataDtoVM) {
//     const isProducerLicenseValid = _.get(quoteDataDtoVM, 'baseData.isProducerLicenseValid_Ext.value');
//     const isProducerAppointmentValid = _.get(quoteDataDtoVM, 'baseData.isProducerAppointmentValid_Ext.value');
//     return isProducerLicenseValid && isProducerAppointmentValid;
// }

/**
 * Receives a list of BaseSideBySideDataDTO and extract distinct pairs
 * @param {Array} sxsPeriods
 * @returns {Array} distinct SxsPeriod pairs with publicID and pairPeriodPublicId_Ext
 */
function getSideBySidePairs(sxsPeriods = []) {
    if (_.isEmpty(sxsPeriods)) {
        return [];
    }
    //
    const retval = [];
    sxsPeriods.forEach((sxsPeriod) => {
        const periodPublicID = sxsPeriod.publicID;
        if (!periodPublicID) {
            return;
        }
        const existingSxsPeriod = retval.find((sp) => {
            return periodPublicID === sp.publicID
            || periodPublicID === sp.pairPeriodPublicId_Ext;
        });

        if (!existingSxsPeriod) {
            retval.push({
                publicID: sxsPeriod.publicID,
                pairPeriodPublicId_Ext: sxsPeriod.pairPeriodPublicId_Ext,
            });
        }
    });
    return retval;
}

/**
 * Checks whether new SideBySide pair can be added
 * @param {Array} sxsPeriods
 * @returns {boolean} true if new pair could be added
 */
function canAddSideBySidePair(sxsPeriods = []) {
    if (_.isEmpty(sxsPeriods)) {
        return false;
    }
    const sxsPairs = getSideBySidePairs(sxsPeriods);
    return sxsPairs.length < SXS_PAIR_AT_MOST_NUM;
}


// /**
//  * Checks whether existing SideBySide pair can be removed
//  * @param {Array} sxsPeriods
//  * @returns {boolean} true if existing pair could be removed
//  */
// function canRemoveSideBySidePair(sxsPeriods = []) {
//     const sxsPairs = getSideBySidePairs(sxsPeriods);
//     return sxsPairs.length > SXS_PAIR_AT_LEAST_NUM;
// }

/**
 * Checks whether SideBySidePairs are ready for removal
 * @param {aRRAY} sxsPairs
 * @returns {boolean} true if more than one pair exists
 */
function checkReadyForSideBySidePairRemoval(sxsPairs = []) {
    const pairNum = _.get(sxsPairs, 'length', 0);
    return pairNum > SXS_PAIR_AT_LEAST_NUM;
}

/**
 * Get the first instance of OfferedQuoteDTO that is quoted, or undefined if not found
 * @param {array} quotingDto
 * @param {function} dataFormatter format the quotingDto and return the result
 * @returns {object} result of dataformatter(quotingDto) or empty
 */
function getFirstQuotedData(quotingDto = {}, dataFormatter) {
    const { offeredQuotes = [] } = quotingDto;
    const quotedData = offeredQuotes.find((offeredQuote) => offeredQuote.status === 'Quoted');
    const formattedData = dataFormatter(quotedData);
    return formattedData;
}

/**
 * Get teh total cost of the quote first quoted branch
 * @param {object} quotingDto
 * @returns {object} the CurrencyDTO object with property of currency and amount.
 */
function getFirstQuotedPremium(quotingDto = {}) {
    const retval = getFirstQuotedData(quotingDto, (quotedData) => {
        if (_.isEmpty(quotedData)) {
            return '';
        }
        const totalCost = _.get(quotedData, 'premium.totalCost_Ext');
        return totalCost;
    });
    return retval;
}

/**
 * Get a string that represents the first quoted period
 * @param {array} quotingDto an instance of QuotingDTO
 * @returns {string} returns "policyType branchName" of the first quoted period
 */
function getFirstQuotedTypeAndBranch(quotingDto = {}) {
    const retval = getFirstQuotedData(quotingDto, (quotedData) => {
        if (_.isEmpty(quotedData)) {
            return '';
        }
        const { branchName, policyType_Ext: policyType } = quotedData;
        return `${policyType} ${branchName}`;
    });
    return retval;
}

/**
 * Iterate through all SubmissionVM list and return PolicyType and BranchName of the first one.
 * @param  {array} submissionVMLList
 * @returns {string} "PolicyType BranchName" for the first quoted branch, e.g. "Select Version #"
 */
function getQuotedTypeAndBranch(...submissionVMLList) {
    const quoteDataList = submissionVMLList
        .map((submissionVM) => getFirstQuotedTypeAndBranch(_.get(submissionVM.value, 'quoteData')))
        .filter((quoteData) => !_.isEmpty(quoteData));
    const [firstQuoteData] = quoteDataList;
    return firstQuoteData;
}

/**
 * Returns a map of {branchName: [{ publicID_Ext, policyType_Ext }]}
 * @param {array} paOfferingDTOs a list of PaOfferingDTO
 * @returns {object}
 */
function getOfferingPair(paOfferingDTOs = []) {
    const offeringBranchPairs = _.groupBy(paOfferingDTOs, (offeringDTO) => offeringDTO.branchName);
    return offeringBranchPairs;
}
/**
 * Retrieve the type and branch info as string
 * @param {QuoteDataDtoVM} submissionVM
 * @param {string} lob
 * @returns {string} a string consists of PolicyType_Ext and BranchName
 */
function getQuoteSelectedTypeAndBranch(submissionVM, lob = 'personalAuto') {
    const {
        baseData: {
            selectedVersion_Ext: selectedVersion,
            displayStatus_Ext: displayStatus,
            periodStatus,
            quoteFlow_Ext: quoteFlow,
        },
        lobData: {
            [lob]: {
                isForNamedNonOwner_Ext: isNamedNonOwner,
                offerings,
            }
        },
    } = submissionVM.value;
    if (!selectedVersion
    ) {
        return '';
    }

    let retval = '';
    const offeringBranchPairs = _.groupBy(offerings, (offeringDTO) => offeringDTO.branchName);
    const numOfBranches = _.get(Object.keys(offeringBranchPairs), 'length', 0);

    const isCurrentBranchSelected = quoteFlow === 'finishquote' || quoteFlow === 'submitquote';
    const selectedOffering = offerings.find((elt) => elt.publicID_Ext === selectedVersion);

    if (isCurrentBranchSelected && !_.isEmpty(selectedOffering)) {
        const {
            branchName: selectedBranch,
            policyType_Ext: selectedPolicyType,
        } = selectedOffering;

        // Don't show job status on view mode
        if (isNamedNonOwner && periodStatus !== 'Bound') {
            retval = 'Named Non-Owner';
        } else if (offerings.length > 1) {
            retval = `${selectedPolicyType} Auto`;
        }

        if (numOfBranches > 1) {
            retval += ` ${selectedBranch}`;
        }
    }
    return retval;
}

/**
 * Similar with getQuoteSelectedTypeAndBranch, only for PolicyChnage
 * @param {object} policyChangeDataDTOVM
 * @param {string} lob
 * @returns {string} the selected branch info
 */
function getEndorsementSelectedTypeAndBranch(policyChangeDataDTOVM, lob = 'personalAuto') {
    const {
        status: jobStatus,
        baseData: {
            selectedVersion_Ext: selectedVersion,
        },
        lobData: {
            [lob]: {
                offerings = [],
            } = {},
        } = {}, // for PolicyChange Issuance
    } = policyChangeDataDTOVM.value;

    let retval = jobStatus;
    const offeringsNum = offerings.length;
    if (offeringsNum <= 1) {
        return retval;
    }

    const selectedOffering = offerings.find((elt) => elt.publicID_Ext === selectedVersion);
    if (!_.isEmpty(selectedOffering)) {
        const {
            branchName: selectedBranch,
            policyType_Ext: selectedPolicyType,
        } = selectedOffering;
        // retval += ` ${selectedPolicyType} ${selectedBranch}`;
        retval = `${selectedBranch} ${jobStatus}`;
    }
    return retval;
}

/**
 * Get the total premium of the first quoted branch
 * @param {object} submissionVM
 * @returns {object} an instance of CurrencyDTO object, or '';
 */
function getQuotedTotalCost(submissionVM) {
    const retval = getFirstQuotedPremium(_.get(submissionVM.value, 'quoteData'));
    return retval;
}

/**
 * Find the SxsPeriod
 * @param {array} sxsPeriods
 * @param {string} periodPublicID
 * @returns {object} the sxsPeriodDataDTO
 */
function getSideBySidePeriod(sxsPeriods = [], periodPublicID) {
    const sxsPeriod = sxsPeriods.find((period) => periodPublicID === period.publicID);
    return sxsPeriod;
}

/**
 * Get proper Vehicle description text
 * @param {object} vehicleDto
 * @param {string} prefix
 * @returns {string}
 */
function getVehicleDescTitle(vehicleDto = {}, prefix = '') {
    const {
        year = '',
        make = '',
        model = '',
    } = vehicleDto;
    const desc = _.trim(`${year} ${make} ${model}`);
    return _.isEmpty(desc) ? desc : ` ${prefix}${desc}`;
}


// /**
//  * Get the Month Payment total cost
//  * @deprecated To be removed, 2021-04-15
//  * @param {array} installmentPlans list of PaymentPlanDTO[]
//  * @returns {object} instalce of AmountDTO, i.e. with amount and currency
//  */
// function getMonthlyPaymentTotalCost(installmentPlans = []) {
//     const retval = installmentPlans.find((plan) => plan.name.startsWith('Monthly PL'));
//     return retval && retval.total;
// }


/**
 * Get premium currently shown on PAQuotePage
 *
 * Sample INPUT:
 *     selectedVersion: 'pc:5104'
 *     paymentViewType: 'monthly'
 *     sxsDataDTO: {
 *      personalAuto: {
 *          periods: []
 *      }
 *     }
 *
 * Sample OUTPUT: {currency: "usd", amount: 372}
 * @param {string} selectedVersion publicID of selected Period
 * @param {string} paymentViewType
 * @param {string} sxsDataDTO
 * @param {string} lob
 * @returns {object} the premium AmountDTO, or null if selectedVersion could not be found
 */
function getSelectdVersionDisplayedPremium(selectedVersion, paymentViewType, sxsDataDTO, lob = 'personalAuto') {
    const sxsPeriods = _.get(sxsDataDTO, `${lob}.periods`);
    const selectedBranch = sxsPeriods.find((period) => period.publicID === selectedVersion);
    if (!selectedBranch) {
        return null;
    }
    const {
        nonFullPayAmount_Ext: monthlyPayTotal,
        fullPayAmount_Ext: fullPayTotal,
    } = selectedBranch;
    // const isMonthlyPayment = isMonthlyPaymentPlan(paymentViewType);
    const isMonthlyPayment = paymentViewType === PAYMENT_PLAN_TYPES.monthly;
    return isMonthlyPayment ? monthlyPayTotal : fullPayTotal;
}

// /**
//  * Checks whether Finish Quote or Submit Quote has been clicked
//  * @param {object} submissionVM 
//  * @returns {boolean}
//  */
// function isCurrentBranchDecided(submissionVM) {
//     const {
//         baseData: {
//             quoteFlow_Ext: quoteFlow,
//         },
//     } = submissionVM.value;
//     const retval = quoteFlow === 'finishquote' || quoteFlow === 'submitquote';
//     return retval;
// }

/**
 * Get the lob offering for Sxs Pair Period
 * @param {array} lobOfferings
 * @param {string} periodPublicID
 * @returns {array}
 */
function getLobOfferingByPeriodPublicID(lobOfferings, periodPublicID) {
    const retval = lobOfferings.find((lobOffering) => periodPublicID === lobOffering.publicID_Ext);
    return retval || [];
}

/**
 * Get count of Quotes with statu of either Rated, Quoted, or Approved
 * 
 * @param {array} activeQuotes array of AccountActiveJobDTO typically retrieved through wizardPageData
 * @returns {number} number of jobs with status of Rated/Quoted/Approved
 */
function getCountOfRatedQuotedApprovedQuotes(activeQuotes = []) {
    const quotedJobs = activeQuotes.filter((activeQuote) => isQuoted(activeQuote.jobStatus));
    return quotedJobs.length;
}
export default {
    PAYMENT_PLAN_TYPES,
    isStatusQuotedOrRated,
    //
    getSkipRatedQuotedFn,
    getSkipRatedQuotedFnV2,
    // isProducerLicensedAndApppointed,
    getSideBySidePairs,
    canAddSideBySidePair,
    // canRemoveSideBySidePair,
    checkReadyForSideBySidePairRemoval,
    // getFirstQuotedTypeAndBranch,
    // getQuotedTypeAndBranch,
    getQuotedTotalCost,
    getSideBySidePeriod,
    getVehicleDescTitle,
    //
    getQuoteSelectedTypeAndBranch,
    //
    getEndorsementSelectedTypeAndBranch,
    // getPaymentPlanId,
    // getPaymentPlanType,
    // isMonthlyPaymentPlan,
    getSelectdVersionDisplayedPremium,
    // isCurrentBranchDecided,
    getLobOfferingByPeriodPublicID,
    getCountOfRatedQuotedApprovedQuotes,
};
