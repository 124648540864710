import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glPolicyDetailsChangeService'), method, data, additionalHeaders);
}

export default class GLPolicyDetailsChangeService {
    static saveGLPolicyDetailsData(quoteID, sessionUUID, quoteBaseData, authHeader) {
        return processSubmission('saveGLPolicyDetailsData', [quoteID, sessionUUID, quoteBaseData], authHeader);
    }

}