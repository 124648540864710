import React, { useCallback } from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PADriversPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeDriverService } from 'wni-capability-policychange';
import { WizardConstants } from 'wni-portals-config-js';


function PADriversChangePage(props) {
    const {
        wizardData: submissionVM,
        wizardPageData
    } = props;
    const {
        policyNumber,
        jobID,
        baseData: {
            effectiveDate
        }
    } = submissionVM.value;
    const { authHeader } = useAuthentication();

    const addDriver = useCallback(async (driver) => {
        const request = {
            policyNumber,
            effectiveDate,
            jobID,
            driver
        };
        const response = await WniPolicyChangeDriverService.addDriver(
            request,
            authHeader
        );
        return response;
    }, []);

    const delDrivers = useCallback(async (drivers) => {
        const request = {
            policyNumber,
            effectiveDate,
            jobID,
            drivers
        };
        const response = await WniPolicyChangeDriverService.delDrivers(
            request,
            authHeader
        );
        return response;
    }, []);

    const delADPDrivers = useCallback(async (drivers) => {
        const request = {
            jobID,
            effectiveDate,
            drivers
        };
        const response = await WniPolicyChangeDriverService.removeADPDrivers(
            request,
            authHeader
        );
        return response;
    }, []);

    const updateDriver = useCallback(async (driver) => {
        const request = {
            policyNumber,
            effectiveDate,
            jobID,
            driver
        };
        const response = await WniPolicyChangeDriverService.updateDriver(
            request,
            authHeader
        );
        return response;
    }, []);

    const getCoverages = useCallback(async (driver) => {
        const request = {
            jobID,
            driver
        };
        const response = await WniPolicyChangeDriverService.getCoverages(
            request,
            authHeader
        );
        return response;
    }, []);

    const updateCoverages = useCallback(async (driverDTO, cov, scheduleItem) => {
        const request = {
            policyNumber,
            effectiveDate,
            jobID,
            driverDTO,
            cov,
            scheduleItem
        };
        const response = await WniPolicyChangeDriverService.updateCoverages(
            request,
            authHeader
        );
        return response;
    }, []);

    const getErrorsAndWarnings = useCallback(async () => {
        const request = {
            jobID,
            isErrorLevel: !_.isNil(wizardPageData[WizardConstants.policyDiffData])
        };
        const response = await WniPolicyChangeDriverService.getErrorsAndWarnings(
            request,
            authHeader
        );
        return response;
    }, []);

    const overrideProps = {
        ...props,
        policyChangeService: {
            addDriver,
            delDrivers,
            updateDriver,
            getCoverages,
            updateCoverages,
            delADPDrivers,
            getErrorsAndWarnings
        }
    };


    return <PADriversPage {...overrideProps} />;
}

PADriversChangePage.propTypes = wizardProps;
export default PADriversChangePage;
