import { defineMessages } from 'react-intl';

export default defineMessages({
    ViewOrEdit: {
        id: "quoteandbind.gl.wizard.step.state specific information page.View or Edit",
        defaultMessage: "View/Edit"
    },
    ViewLabel: {
        id: 'quoteandbind.gl.wizard.step.state specific information page.View',
        defaultMessage: 'View',
    },
});