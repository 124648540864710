
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { HOCoveragesPage } from 'wni-capability-quoteandbind-ho-react';

function HOCoveragesChangePage(props) {

    return (
        <HOCoveragesPage {...props} />
    );

}

HOCoveragesChangePage.propTypes = HOCoveragesPage.propTypes;
HOCoveragesChangePage.defaultProps = HOCoveragesPage.defaultProps;

export default HOCoveragesChangePage;