import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext';
import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil';

const StandardCoverage = (props) => {
    const {
        unmannedAircraftClauses,
        updateUnmannedAircraftClauses,
        wizardData: submissionVM,
        updateWizardData,
        onValidate: onUnmannedAircarftPageValidate,
        showErrors,
        readOnly,
        extendProps,
        setIsEditing,
        isEditing,
        setClauseValid,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { jobID, sessionUUID } = extendProps;

    const { authHeader } = useAuthentication();

    const validationID = 'glUnmannedAircraftStandardCoverages';

    const { onValidate, isComponentValid } = useValidation(validationID);

    useEffect(() => {
        if (onUnmannedAircarftPageValidate) {
            onUnmannedAircarftPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onUnmannedAircarftPageValidate) {
                onUnmannedAircarftPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onUnmannedAircarftPageValidate]);

    const { standardCoverage } = unmannedAircraftClauses;

    const [loadingClauses, setLoadingClauses] = useState(false);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(
                standardCoverage,
                'standardCoverage',
                value,
                changedPath
            );
            const newUnmannedAircraftClauses = _.clone(unmannedAircraftClauses);
            _.set(newUnmannedAircraftClauses, 'standardCoverage', newClauses);
            updateUnmannedAircraftClauses(newUnmannedAircraftClauses);
            setIsEditing(false);
            return newUnmannedAircraftClauses;
        },
        [
            standardCoverage,
            unmannedAircraftClauses,
            updateUnmannedAircraftClauses,
            setIsEditing,
        ]
    );

    const updateVMToServer = useCallback(
        async (newExposureClauses, setLoadingClausesFunc, setEditingFunc) => {
            const unmannedAircraftClausesToUpdate =
                GLCoverageUtil.generateUpdatedUnmannedAircraftClausesDTO(
                    newExposureClauses
                );
            const updatedUnmannedAircraftClausesPublicIDs =
                GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedUnmannedAircraftClausesDTO(
                    unmannedAircraftClausesToUpdate
                );
            setLoadingMask(true)
            setLoadingClausesFunc(updatedUnmannedAircraftClausesPublicIDs);
            const response =
                await GLCoverageService.updateUnmannedAircraftClauses(
                    jobID,
                    sessionUUID,
                    unmannedAircraftClausesToUpdate,
                    authHeader
                );
            setLoadingClausesFunc(false);
            setLoadingMask(false)
            setEditingFunc(false);
            setClauseValid(response.valid)
            updateUnmannedAircraftClauses(response.singleUnmannedAircraftClauses);
        },
        [setLoadingMask, jobID, sessionUUID, authHeader, setClauseValid, updateUnmannedAircraftClauses]
    );

    const updateNewUnmannedAircraftClausesToServer = useCallback(
        async (newSublineClauses) => {
            updateVMToServer(
                newSublineClauses,
                setLoadingClauses,
                setIsEditing
            );
        },
        [updateVMToServer, setLoadingClauses, setIsEditing]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeSubmission(value, changedPath);

            updateNewUnmannedAircraftClausesToServer(newSublineClauses);
        },
        [changeSubmission, updateNewUnmannedAircraftClausesToServer]
    );

    const onSyncCoverages = useCallback(() => {
        updateNewUnmannedAircraftClausesToServer(unmannedAircraftClauses);
    }, [unmannedAircraftClauses, updateNewUnmannedAircraftClausesToServer]);

    return (
        <div className="formCoveragesContainer">
            <CoverageContext.Provider
                value={{
                    wizardData: submissionVM,
                    updateWizardData: updateWizardData,
                    updateVMToServer: updateVMToServer,
                }}
            >
                <GLClausesComponentVM
                    splitByClauseCategory={false}
                    sortBySelected
                    onSyncCoverages={onSyncCoverages}
                    onChangeSubmissionAndSync={changeSubmissionAndSync}
                    onChangeClause={changeSubmission}
                    onValidate={onValidate}
                    showAmount={false}
                    loadingClause={loadingClauses}
                    path="standardCoverage"
                    value={standardCoverage}
                    showErrors={showErrors}
                    componentMapOverrides={{
                        GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                    }}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    isEditable={!readOnly}
                />
            </CoverageContext.Provider>
        </div>
    );
};

export default StandardCoverage;
