import { defineMessages } from 'react-intl';

export default defineMessages({
    cpViewAndEditLabel: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecific.View/Edit',
        defaultMessage: 'View/Edit',
    },
    cpViewLabel: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecific.View',
        defaultMessage: 'View',
    },
});