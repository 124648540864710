import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glExposureService'), method, data, additionalHeaders);
}

export default class GLExposureService {

    /**
     * Invokes GLExposuresHandler.updateVehicle()
     */
    static addExposure(quoteID, sessionUUID, subline, authHeader = {}) {
        return processSubmission('addExposure', [quoteID, sessionUUID, subline], authHeader);
    }

    /**
     * Invokes GLExposuresHandler.removeExposure()
     *
     */
    static removeExposure(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('removeExposure', [quoteID, sessionUUID, publicIDs], authHeader);
    }

    static postOnChangeAction(quoteID, sessionUUID, dto, authHeader = {}) {
        return processSubmission('postOnChangeAction', [quoteID, sessionUUID, dto], authHeader);
    }

    static viewAndEdit(quoteID, sessionUUID, publicID, authHeader = {}) {
        return processSubmission('viewAndEdit', [quoteID, sessionUUID, publicID], authHeader);
    }

    static saveExposure(quoteID, sessionUUID, dto, authHeader = {}) {
        return processSubmission('saveExposure', [quoteID, sessionUUID, dto], authHeader);
    }

    static onPageNext(requestData, authHeader = {}) {
        return processSubmission('onPageNext', [requestData.jobID, requestData.sessionUUID], authHeader);
    }
    
    static getSearchClassCode(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('getSearchClassCode', [quoteID, sessionUUID, paramsData], authHeader);
    }
   
    static handleSearchClassCode(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('handleSearchClassCode', [quoteID, sessionUUID, paramsData], authHeader);
    }

}
