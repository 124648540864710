
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLModifiersPage } from 'wni-capability-quoteandbind-gl-react';

function GLModifiersChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLModifiersPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLModifiersChangePage.propTypes = GLModifiersPage.propTypes;
GLModifiersChangePage.defaultProps = GLModifiersPage.defaultProps;

export default GLModifiersChangePage;