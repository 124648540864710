import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import React, {
    useState
} from 'react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './ScheduleItemForm.messages'
import IMScheduleDetailsPropertyField from '../../../../../components/IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsPropertyField';
import IMScheduleDetailsCovTermField from '../../../../../components/IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsCovTermField';


const ScheduleItemForm = (props) => {
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false)
    const {
        propertyInfos,
        coveragePropertyInfos,
        scheduleItem: originScheduleItem,
        handleScheduleItemCancel,
        handleSaveScheduleItem,
        isEditable,
        isEditing,
        setIsEditing,
    } = props;


    const validationID = `AccountReceivableScheduleItem_${originScheduleItem.publicId_Ext}`

    const { onValidate, isComponentValid } = useValidation(validationID);


    const [scheduleItem, setScheduleItem] = useState(originScheduleItem)

    const setScheduleItemFunc = (setPropertyFieldFunc) => {
        const newScheduleItem = setPropertyFieldFunc(scheduleItem)
        setScheduleItem(newScheduleItem)
    }

    const propertyFieldComponents = propertyInfos
        .sort((a, b) => a.order - b.order)
        .map(propertyInfo => {
            const propertyInfoID = propertyInfo.id
            const propertyInfoItemData = scheduleItem.itemData[propertyInfoID]
            return <IMScheduleDetailsPropertyField
                id={propertyInfoID}
                key={propertyInfoID}
                setScheduleItemFunc={setScheduleItemFunc}
                propertyInfo={propertyInfo}
                onValidate={onValidate}
                propertyInfoItemData={propertyInfoItemData}
                showErrors={showErrors}
                isEditable={isEditable}
                setIsEditing={setIsEditing}
            />
        })

    const termFieldComponents = coveragePropertyInfos
        .sort((a, b) => a.order - b.order)
        .map(termPropertyInfo => {
            const termCode = termPropertyInfo.id
            const term = scheduleItem.scheduleItemTerms
                .find(t => t.code_Ext === termCode)
            if(!term){
                return null
            }
            return <IMScheduleDetailsCovTermField
                key={termCode}
                term={term}
                setScheduleItemFunc={setScheduleItemFunc}
                onValidate={onValidate}
                showErrors={showErrors}
                isEditable={isEditable}
                setIsEditing={setIsEditing}
            />
        })

    return (
        <>
            <h4>{translator(messages.ReceptacleDetails)}</h4>
            {propertyFieldComponents}
            {termFieldComponents}
            <Flex gap="small" justifyContent="right" className="mb-10">
                <Button
                    variant="secondary"
                    onClick={handleScheduleItemCancel}
                    label={messages.Cancel}
                />
                <Button
                    onClick = {()=> handleSaveScheduleItem(scheduleItem)}
                    disabled = {!isComponentValid}
                    label={messages.SaveAndContinue}
                />
            </Flex>
        </>
        
    );
};

export default ScheduleItemForm;
