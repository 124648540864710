import React from 'react';
import { Grid } from '@jutro/layout';
import { Icon } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import pageFooterStyles from './PageFooterComponent.module.scss';
import message from './PageFooterComponent.messages';

function PageFooterComponent(props) {
    const translator = useTranslator();
    const { domainCompany: { code } } = useDependencies(['domainCompany', 'portallBuildInfo']);
    const domainCode = code === 'UIC' ? 'UIC' : 'WNI';
    const companyName = translator(message[`footerCompanyName${domainCode}`]);
    const companyEmail = translator(message[`footerEmailAddress${domainCode}`]);
    const companyNumber = translator(message[`footerPhoneNumber${domainCode}`]);
    const facebookLink = translator(message[`footerFackbookLink${domainCode}`]);
    const linkedinLink = translator(message[`footerLinkedinLink${domainCode}`]);
    return (
        <>
            <div className={pageFooterStyles.pageFooterPos} />
            <Grid
                className={pageFooterStyles.pageFooter}
                columns={['2fr', '1fr']}
                valignContent="middle"
                valignItems="middle"
            >
                <div className={pageFooterStyles.applicationFooter}>
                    <p>
                        <span>{`${companyName} | `}</span>
                        <a href={`mailto:${companyEmail}`} className="font-white-link">{companyEmail}</a>
                        <span>{` | ${companyNumber}`}</span>
                    </p>
                </div>
                <div className={pageFooterStyles.applicationFooter}>
                    <ul className={pageFooterStyles.logoSpacing}>
                        <a href={facebookLink} tabIndex="-1">
                            <i className={pageFooterStyles.cosmosFacebookLogo} aria-hidden="true" />
                        </a>
                        {/* <a href="https://twitter.com/westernnational" tabIndex="-1"> */}
                            {/* <i className={pageFooterStyles.cosmosTwitterLogo} aria-hidden="true" /> */}
                        {/* </a> */}
                        <a href={linkedinLink} tabIndex="-1">
                            <i className={pageFooterStyles.cosmosLinkedInLogo} aria-hidden="true" />
                        </a>
                    </ul>
                </div>
            </Grid>
        </>
    );
}

export default PageFooterComponent;
