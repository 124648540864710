import { defineMessages } from 'react-intl';

export default defineMessages({
    editLocationAlertTitle: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Alert Title',
        defaultMessage: 'You are editing a location used for {buildings} and the changes you make here will apply to all of them.'
    },
    buildingsCount: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Buildings',
        defaultMessage: '{numberOfBuildings} Buildings'
    },
    editLocationAlertMessage: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.If you want to change the location for individual building, procede as follows:',
        defaultMessage: 'If you want to change the location for individual building, proceed as follows:'
    },
    clickToViewBuildings: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Click',
        defaultMessage: 'Click {link}.'
    },
    viewBuildings: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.View Buildings',
        defaultMessage: 'View Buildings'
    },
    selectTheBuilding: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Select the building',
        defaultMessage: 'Select the building.'
    },
    expandLocation: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Expand "Location" and choose either new or existing location',
        defaultMessage: 'Expand "Location" and choose either new or existing location.'
    }
});
