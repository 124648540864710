
import React from 'react';
import { RTVehiclesPage } from 'wni-capability-quoteandbind-rt-react';

function RTVehiclesChangePage(props) {

    return (
        <RTVehiclesPage {...props} checkRequiredForIssuance={false} />
    );

}

RTVehiclesChangePage.propTypes = RTVehiclesPage.propTypes;
RTVehiclesChangePage.defaultProps = RTVehiclesPage.defaultProps;

export default RTVehiclesChangePage;