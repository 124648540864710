import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUnderwritingIssuesList,
    EligibilityIssuesLinkComponent,
    ValidationIssuesComponent
} from 'wni-components-platform-react';
import {
    ErrorsAndWarningsUtil,
    SxsUtil,
    UWIssueUtil,
} from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import {
    ReferToUnderwriterComponent,
    AccountClearanceLinkComponent,
    // RecommendCoveragesComponent,
} from 'wni-capability-gateway-react';

import { QuoteSideBySidePeriodMessages as messages } from 'wni-platform-translations';
import hoMessages from './HoSideBySidePeriodInfo.messages';

import HORecommendCoveragesComponent from './RecommendCov/HORecommendCoveragesComponent';

import VirtualInspectionNotificationComponentV2 from './VirtualInspection/VirtualInspectionNotificationComponentV2';

import metadata from './HoSideBySidePeriodInfo.metadata.json5';
import styles from './HoSideBySidePeriodInfo.module.scss';

const UW_PL_0536_VIRUTAL_INSPECTION_CODE = 'HOPCheckIssuanceForVirtualInspection_Ext';
const VIRTUAL_INSPECTION_UW_ISSUE_CODES = [UW_PL_0536_VIRUTAL_INSPECTION_CODE];
//
const INELIGIBLE_UW_ISSUE_CODES = ['HOPIneligibleForSignatureAndSelect_Ext'];

const UW_POLICY_HOLD = 'UWPolicyHold';

function HoSideBySidePeriodInfo(props) {
    const {
        model: sxsPeriodDto,
        // overrideProps,
        // periodStatus,
        // submissionVM,
        // updateSubmissionVM,
        underwritingIssues,
        eligibilityIssues,
        isEditable,
        isClueCompare,
        isMvrCompare,
        isNewAccount,
        currentAccountNumber,
        // quoteID,
        // sessionUUID,
        // isReferUnWriter,
        isServiceCallInProgress: isLoading,
        showVersionNumber,
        // paymentPlanType,
        isMonthlyPayment,
        coveragesForm,
        // isRunFinalValidation,
        // isContinueWithQuote,
        // isValidation,
        callbackMap: {
            onFinishQuote,
            onSubmitQuote,
            onDoNotProceed,
            onReferToUnderwriter,
            onContinueToIssue,
            goToIncidentReports,
            onRateAll: onGetUpdatedRate,
            // onRunReservation,
            onAccountClearance,
            postAccountMerge,
            //
            // goToDriverCoveragesPage,
            goToCoveragesPage,
            //
            onCallVirtualInspectionReport,
            goToQuoteDetailsPage,
            baseState,
        },
        showAccountClearanceLink
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const {
        publicID,
        periodStatus,
        policyType_Ext: policyType,
        branchName,
        // branchCode,
        quoteFlow_Ext: quoteFlow,
        totalPremiumRPT,
        // isResolveNeeded,
        validationIssues,
        coverageIssues,
        // nonFullPayAmount_Ext: monthlyPayTotal,
        // fullPayAmount_Ext: fullPayTotal,
        columnIndex,
        totalCostRPT,
        //
        showVirtualInspection,
    } = sxsPeriodDto;

    const sxsPeriodDtoVM = viewModelService.create(sxsPeriodDto, 'pc', 'wni.edge.capabilities.quote.sxs.lob.homeowners.dto.HoSideBySidePeriodInfoDTO');
    const translator = useTranslator();

    const [isCompareViolationDiff, setCompareViolationDiff] = useState(false);
    const [isComparePriorLossDiff, setComparePriorLossDiff] = useState(false);
    const [isCompareAllDiff, setCompareAllDiff] = useState(false);
    // const [isLoading, updateIsLoading] = useState(false);

    const hasBlockingUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(
        { underwritingIssues: underwritingIssues }
    );
    const sxsPeriodValidationIssues = ErrorsAndWarningsUtil.getValidationIssues({ validationIssues });
    const sxsPeriodValiationErrors = sxsPeriodValidationIssues.filter((issue) => issue.type === 'error');

    // const isMonthlyPayment = paymentPlanType === 'monthly';
    const isDraftPeriod = periodStatus === 'Draft';
    const isFinishOrSubmitFlow = quoteFlow === 'finishquote' || quoteFlow === 'submitquote';
    // const isReadyForIssue = quoteFlow === 'submitquote' && periodStatus === 'Quoted';
    // another option: check sxsPeriodDto.canIssue instead
    const isReadyForIssue = SxsUtil.isQuotedOrApproved(periodStatus) && isFinishOrSubmitFlow;
    const hasIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(underwritingIssues, INELIGIBLE_UW_ISSUE_CODES);
    const isReferralBlockedByEligibility = ErrorsAndWarningsUtil.isReferralBlockedByEligibility(eligibilityIssues);
    const meetsWaitAndBindCriteria = UWIssueUtil.hasUnApprovedUWIssueByTypeCode(underwritingIssues,VIRTUAL_INSPECTION_UW_ISSUE_CODES) && !hasBlockingUWIssue && isReadyForIssue;
    const ineligibilityButtonTitle = isReferralBlockedByEligibility ? hoMessages.blockedByIneligibility.defaultMessage : undefined
    const referToUwWaitOrBindBtnTitle = meetsWaitAndBindCriteria ? hoMessages.waitOrBindReferToUWMessage.defaultMessage : undefined
    const continueToIssueWaitOrBindBtnTitle = meetsWaitAndBindCriteria ? hoMessages.waitOrBindContinueToIssueMessage.defaultMessage : undefined
    const disableContinueToQuoteMN = UWIssueUtil.hasUnApprovedUWIssueByTypeCode(underwritingIssues,[UW_POLICY_HOLD]) && baseState === 'MN';


    let showApprovedVirtualInspectionUWIssue = false;
     if (isEditable && quoteFlow === 'submitquote' && periodStatus === 'Quoted') {
         const hasUnApprovedVirtualInspectionUWIssue = UWIssueUtil.hasUnApprovedUWIssueByTypeCode(underwritingIssues, VIRTUAL_INSPECTION_UW_ISSUE_CODES);
         showApprovedVirtualInspectionUWIssue = hasUnApprovedVirtualInspectionUWIssue;
    }

    // let runValidationBtnLabel = messages.runFinalValidation;
    //  if (showVirtualInspection) {
    //      runValidationBtnLabel = hoMessages.runLossReport;
    // }
    const runValidationBtnLabel = hoMessages.proceedToSubmission;
    
    const doNotProceedBtnLabel = hoMessages.doNotProceed;

    useEffect(() => {
        if (isClueCompare && isMvrCompare) {
            setCompareAllDiff(true);
            setComparePriorLossDiff(false);
            setCompareViolationDiff(false);
        } else if (isClueCompare) {
            setCompareAllDiff(false);
            setComparePriorLossDiff(true);
        } else if (isMvrCompare) {
            setCompareAllDiff(false);
            setCompareViolationDiff(true);
        }
    }, [isClueCompare, isMvrCompare]);

    const getButtonsVisibility = useCallback(() => {
        if (!isEditable) {
            return {
                showContinueWithQuoteBtn: false,
                showRunFinalValidationBtn: false,
                showDoNotProceedBtn: false,
                showReferToUWBtn: false,
                showContinueToIssueBtn: false,
                showGetUpdatedRateBtn: false,
            };
        }
        const showContinueWithQuoteBtn = quoteFlow === 'firstquote' && periodStatus === 'Rated';
        const showRunFinalValidationBtn = isFinishOrSubmitFlow && periodStatus === 'Rated';
        const showReferToUWBtn = meetsWaitAndBindCriteria || (hasBlockingUWIssue && !showApprovedVirtualInspectionUWIssue && 
            !(periodStatus === 'Draft' || showContinueWithQuoteBtn || showRunFinalValidationBtn));
        const showDoNotProceedBtn = showRunFinalValidationBtn || showReferToUWBtn;
        const showContinueToIssueBtn = meetsWaitAndBindCriteria || (!hasBlockingUWIssue && isReadyForIssue);
        const showGetUpdatedRateBtn = isDraftPeriod;
        return {
            showContinueWithQuoteBtn,
            showRunFinalValidationBtn,
            showDoNotProceedBtn,
            showReferToUWBtn,
            showContinueToIssueBtn,
            showGetUpdatedRateBtn,
        };
    }, [isEditable, quoteFlow, hasBlockingUWIssue, periodStatus, showApprovedVirtualInspectionUWIssue]);

    const getPremim = () => {
        if (isDraftPeriod) {
            return null;
        }
        // return isMonthlyPayment ? monthlyPayTotal : fullPayTotal;
        return totalCostRPT;
    };

    const uwNoteProcessor = useCallback((note) => {
        const userNote = note || '';
        // return `${userNote} \n\n ${policyType} ${branchName} referred`;

        // for POI-25371, requirement change
        const normalizedBranchName = branchName === 'CUSTOM' ? '' : branchName;
        return `${userNote} \n\n ${policyType} ${normalizedBranchName} referred`;
    }, [branchName, policyType]);

    const getFilteredUnderwritingIssues = () => {
        // filtered UWI.0536 Rule, no need to display in portal
        // return underwritingIssues.filter((issue) => issue.issueTypeCode_Ext !== 'HOPCheckIssuanceForVirtualInspection_Ext') 
        return underwritingIssues;
    }

    //-------------------------------------
    const {
        showContinueWithQuoteBtn,
        showRunFinalValidationBtn,
        showDoNotProceedBtn,
        showReferToUWBtn,
        showContinueToIssueBtn,
        showGetUpdatedRateBtn,
    } = getButtonsVisibility();

    const DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES = [
        'PABothKnockout_Ext',
        'PAEligibleSelectOnly_Ext',
        'PAEffectiveDateAfterPendingRate_Ext',
        'HOPEffectiveDateAfterPendingRate_Ext',
        'RTEffectiveDateGreaterThan35DaysInTheFuture_Ext',
        'WALEffectiveDateGreaterThan35DaysInTheFuture_Ext',
        'UMBEffectiveDateGreaterThan35DaysInTheFuture_Ext'
    ];

    const disableBtnWithIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
        underwritingIssues, DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        periodStatus: {
            content: periodStatus,
        },
        policyType: {
            value: `${policyType} Home`,
        },
        branchName: {
            visible: showVersionNumber,
        },
        coverageForm: {
            value: coveragesForm.toUpperCase(),
        },
        // policyPenaltyFree: {
        //     visible: policyType === 'Signature',
        // },
        premium: {
            value: getPremim(),
        },
        // premiumFullDiscount: {
        //     content: isMonthlyPayment ? messages.withoutPayFullDiscount : messages.withPayFullDiscount,
        // },
        // validationIssuesToResolve: {
        //     // visible: isResolveNeeded,
        //     visible: !_.isEmpty(sxsPeriodValiationErrors),
        //     issues: sxsPeriodValiationErrors,
        // },
        // resolveUWIssuesNotification: {
        //     visible: showReferToUWBtn,
        // },
        quoteUWIssues: {
            underwritingIssues: getFilteredUnderwritingIssues(),
            headerObject: messages.IssuesRequiringReferral,
            // issueDescFormatter: (uwIssue) => uwIssue.longDescription,
        },
        eligibilityIssuesLink: {
            visible: !_.isEmpty(eligibilityIssues) && hasIneligibleUwIssue,
            policyType: `${policyType} Home`,
            eligibilityIssues,
            errorMode: hasBlockingUWIssue,
        },
        accountClearanceLink: {
            // visible: (showReferToUWBtn || showContinueToIssueBtn) && isNewAccount,
            visible: !!showAccountClearanceLink,
            onAccountClearance: async () => onAccountClearance(publicID),
            postAccountMerge,
            currentAccountNumber: currentAccountNumber
        },
        recommendedCoveragesContainer: {
            visible: isEditable && !_.isEmpty(coverageIssues),
            // periodPublicID: publicID,
            columnIndex,
            coverageIssues,
            // goToDriverCoveragesPage,
            goToCoveragesPage,
        },
        // runReservationBtn: {
        //     renderContent: renderHelper.getButtonRenderFunc(isLoading),
        // },
        continueWithQuoteBtn: {
            visible: showContinueWithQuoteBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.continueWithQuote), isLoading),
            disabled: isReferralBlockedByEligibility || disableContinueToQuoteMN,
            title: ineligibilityButtonTitle,
        },
        runFinalValidationBtn: {
            visible: showRunFinalValidationBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(runValidationBtnLabel), isLoading),
            disabled: isReferralBlockedByEligibility || disableContinueToQuoteMN || disableBtnWithIneligibleUwIssue,
            title: ineligibilityButtonTitle,
        },
        doNotProceedBtn: {
            visible: showDoNotProceedBtn,
            content: renderHelper.getButtonContent(translator(doNotProceedBtnLabel), isLoading),
        },
        ineligibilityMessage: {
            visible: isReferralBlockedByEligibility && hasIneligibleUwIssue,
            value: hoMessages.blockedByIneligibility.defaultMessage,
        },
        virtualInspectionNeededMessage: {
            visible: showContinueToIssueBtn && showReferToUWBtn,
            value: hoMessages.requiresVirtualInspectionMessage.defaultMessage,
        },
        waitOrBindInfoRequiresVirtualInspectionMessage: {
            visible: showContinueToIssueBtn && showReferToUWBtn,
            value: translator(hoMessages.waitOrBindInfoRequiresVirtualInspectionMessage.defaultMessage, {
                ContinuetoIssue: <span className="font-PrimaryDark-bold-14">{translator(messages.continueToIssue)}</span>,
            }),
        },
        waitOrBindInfoUnderwritingContactMessage: {
            visible: showContinueToIssueBtn && showReferToUWBtn,
            value: translator(hoMessages.waitOrBindInfoUnderwritingContactMessage.defaultMessage, {
                RefertoUnderwriter: <span className="font-PrimaryDark-bold-14">{translator(messages.referToUnderwriter)}</span>,
            }),
        },
        referToUWBtn: {
            visible: showReferToUWBtn, //&& !isReferralBlockedByEligibility,
            onReferToUnderwriter: async (noteForUW) => onReferToUnderwriter(noteForUW, publicID),
            isServiceCallInProgress: isLoading,
            noteProcessor: uwNoteProcessor,
            referToUWButtonEnabled: !isReferralBlockedByEligibility || !disableBtnWithIneligibleUwIssue,
            referToUWBtnTitle: isReferralBlockedByEligibility ? ineligibilityButtonTitle : referToUwWaitOrBindBtnTitle,
        },
        continueToIssueBtn: {
            visible: showContinueToIssueBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.continueToIssue), isLoading),
            title: continueToIssueWaitOrBindBtnTitle,
        },
        getUpdatedRateBtn: {
            visible: showGetUpdatedRateBtn,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.getUpdatedRate), isLoading),
        },
        //
        virtualInspectionNotification: {
            onVirtualInspection: async (noteForUW) => onReferToUnderwriter(noteForUW, publicID),
            onCancelVirtualInspection: goToQuoteDetailsPage,
            // isServiceCallInProgress,
            visible: showApprovedVirtualInspectionUWIssue,
        },
        //
        compareReviewViolationLink: {
            // visible: isEditable && quoteFlow === 'submitquote' && isCompareViolationDiff,
            visible: isEditable && isFinishOrSubmitFlow && isCompareViolationDiff,
        },
        compareReviewLossLink: {
            // visible: isEditable && quoteFlow === 'submitquote' && isComparePriorLossDiff,
            visible: isEditable && isFinishOrSubmitFlow && isComparePriorLossDiff,
        },
        compareReviewAllLink: {
            // visible: isEditable && quoteFlow === 'submitquote' && isCompareAllDiff,
            visible: isEditable && isFinishOrSubmitFlow && isCompareAllDiff,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onFinishQuote: async () => onFinishQuote(publicID),
            onSubmitQuote: async () => onSubmitQuote(publicID),
            onDoNotProceed: async () => onDoNotProceed(publicID),
            onContinueToIssue: async () => onContinueToIssue(publicID),
            goToIncidentReports,
            onGetUpdatedRate,
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            refertouw: ReferToUnderwriterComponent,
            eligibilitydetails: EligibilityIssuesLinkComponent,
            accountclearancelink: AccountClearanceLinkComponent,
            recommendedCoverages: HORecommendCoveragesComponent,
            validationissuescomponent: ValidationIssuesComponent,
            virtualinspection: VirtualInspectionNotificationComponentV2,
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            sxsPeriodDtoVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sxsPeriodDtoVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            // onValueChange={writeValue}
            // onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

HoSideBySidePeriodInfo.propTypes = {
    model: PropTypes.shape({}).isRequired,
    underwritingIssues: PropTypes.arrayOf(PropTypes.object),
    eligibilityIssues: PropTypes.arrayOf(PropTypes.object),
    // overrideProps: PropTypes.shape({}),
    // periodStatus: PropTypes.string
    isEditable: PropTypes.bool,
    isClueCompare: PropTypes.bool,
    isMvrCompare: PropTypes.bool,
    isNewAccount: PropTypes.bool,
    currentAccountNumber: PropTypes.string,
    // quoteID: PropTypes.string,
    // sessionUUID: PropTypes.string,
    // paymentPlanType: PropTypes.string,
    isMonthlyPayment: PropTypes.bool,
    // isReferUnWriter: PropTypes.bool,
    isServiceCallInProgress: PropTypes.bool,
    showVersionNumber: PropTypes.bool,
    // isRunFinalValidation: PropTypes.bool,
    // isContinueWithQuote: PropTypes.bool,
    // isValidation: PropTypes.string,
    callbackMap: PropTypes.shape({
        onFinishQuote: PropTypes.func,
        onSubmitQuote: PropTypes.func,
        onDoNotProceed: PropTypes.func,
        onReferToUnderwriter: PropTypes.func,
        onContinueToIssue: PropTypes.func,
        goToIncidentReports: PropTypes.func,
        onRateAll: PropTypes.func,
        // onRunReservation: PropTypes.func
        onAccountClearance: PropTypes.func,
        postAccountMerge: PropTypes.func,
        goToDriverCoveragesPage: PropTypes.func,
    })
};

HoSideBySidePeriodInfo.defaultProps = {
    // overrideProps: {},
    // periodStatus: undefined
    underwritingIssues: [],
    eligibilityIssues: [],
    isEditable: true,
    isClueCompare: false,
    isMvrCompare: false,
    isNewAccount: false,
    currentAccountNumber: '',
    // quoteID: undefined,
    // sessionUUID: undefined,
    // isReferUnWriter: true,
    // paymentPlanType: 'annually',
    isMonthlyPayment: false,
    isServiceCallInProgress: false,
    showVersionNumber: false,
    // isRunFinalValidation: false,
    // isContinueWithQuote: true,
    // isValidation: 'default',
    callbackMap: {},
};

export default HoSideBySidePeriodInfo;
