import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import CPBlanketFieldMap from './CPBlanketFieldMap';
import CPGroupedRisksComponent from '../CPGroupedRisksComponent/CPGroupedRisksComponent';
import metadata from './CPBlanketDetailsComponent.metadata.json5';
import messages from '../../CPBlanketsPage.messages';

function CPBlanketDetailsComponent(props) {
    const {
        blanketVM: currentRow,
        readOnly,
        onValueChange,
        updateService,
        updateSubmissionVMByCurrentRow,
        updateWizardData,
        selectedCoverages,
        updateSelectedCoverages,
        riskTreeRows,
        applyRisks,
        removeAllRisks,
        showErrors
    } = props;

    const blanketType = _.get(currentRow, 'value.blanketType');

    const translator = useTranslator();
    // const ViewModelService = useContext(ViewModelServiceContext);

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid
    } = useValidation('CPBlanketDetailsComponent');

    const onBlur = () => {
        updateService(currentRow);
    };

    const onFieldMapChange = (fieldItem, path, fieldModel = {}) => {
        const initCurrentRow = viewModelService.clone(currentRow);
        _.set(initCurrentRow.value, path, fieldItem);

        if (fieldModel.triggerFunc === 'onValueChange') {
            updateService(initCurrentRow);
        } else {
            updateSubmissionVMByCurrentRow(initCurrentRow, updateWizardData);
        }
    };

    const getBlanketCoverages = () => {
        const coverages = _.get(currentRow, 'value.coverages');
        return _.map(coverages, (cov) => {
            return {
                code: cov,
                name: cov
            }
        })
    }

    const getCoveragesTooltip = () => {
        if (blanketType === 'Flood Coverage') {
            return translator(messages.floodCoveragesTooltip)
        }
        if (blanketType === 'Earthquake and Volcanoe Coverage') {
            return translator(messages.EQCoveragesTooltip)
        }
        return '';
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                labelPosition: 'left',
            },
            blanketAccordion: {
                defaultOpenedId: 'blanketDetails'
            },
            shouldShowPeakSeasonWarning: {
                visible: Boolean(_.get(currentRow, 'value.shouldShowPeakSeasonWarning')),
                message: translator(messages.peakSeasonWarning)
            },
            peakSeasonSelectionWarning: {
                visible: Boolean(_.get(currentRow, 'value.shouldShowPeakSeasonSelectionWarning')),
                message: translator(messages.peakSeasonSelectionWarning)
            },
            blanketFieldMap: {
                vm: currentRow,
                dataPath: 'blanketDisplayables',
                onValueChange: onFieldMapChange,
                onValidate,
                showErrors,
                readOnly,
                onBlur
            },
            blanketCoverages: {
                visible: _.get(currentRow, 'value.coverageVisible'),
                availableValues: getBlanketCoverages(),
                labelPosition: 'top',
                value: selectedCoverages,
                tooltip: getCoveragesTooltip(),
                onValueChange: (selections) => { updateSelectedCoverages(selections); },
                readOnly
            },
            blanketRisks: {
                visible: _.get(currentRow, 'value.blanketType') !== 'Peak Season Coverage'
            },
            groupRisks: {
                riskTreeRows,
                blanketVM: currentRow,
                applyRisks,
                removeAllRisks,
                readOnly
            }
        };
    }, [currentRow, selectedCoverages, showErrors, applyRisks, removeAllRisks]);

    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            cpblanketfieldmap: CPBlanketFieldMap,
            cpgroupedriskscomponent: CPGroupedRisksComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            showErrors={showErrors}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default CPBlanketDetailsComponent;