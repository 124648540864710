

const TempContactId = 'TempContactId'

let newContactTempId = 0

const ContactSubType = {
    Person: 'Person',
    Company: 'Company',
}

const commonContactInitProperties = {
    isReportedByTypeOptional_Ext: true
}

export const getNewContactTempId = () => {
    const res = `${TempContactId}:${newContactTempId}`
    newContactTempId += 1
    return res
}

export const createNewPersonWithoutPublicID = () => {
    return {
        ...commonContactInitProperties,
        subtype: ContactSubType.Person, 
        updated_Ext: true,
    }
}

export const createNewCompanyWithoutPublicID = () => {
    return {
        ...commonContactInitProperties,
        subtype: ContactSubType.Company,
        updated_Ext: true,
    }
}