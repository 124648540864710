import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { FNOLService } from 'wni-capability-fnol';
import { ClaimUtil } from 'gwce-capability-fnol-common-react-ext';
import metadata from './FNOLHOThirdPartyLossDetails.metadata.json5';
import PropertyIncidents from './PropertyIncidents/PropertyIncidents';
import InjuryIncidents from './InjuryIncidents/InjuryIncidents';
import VehicleIncidents from './VehicleIncidents/VehicleIncidents';

function FNOLHOThirdPartyLossDetails(props) {
    const {
        wizardData,
        wizardSnapshot,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { authHeader } = useAuthentication();
    const [pageData] = useState(state);
    const [showErrors, setShowErrors] = useState(false)
    const claimVM = !_.isEmpty(wizardData) ? wizardData : wizardSnapshot;
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation(
        'FNOLHOIncidentDetailsPage'
    );

    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', 'homeowners'));
    
    const propertyIncidentsPath = `lobs.${LOB_NAME}.pepropertyIncidents_Ext`
    const injuryIncidentsPath = `lobs.${LOB_NAME}.peinjuryIncidents_Ext`
    const vehicleIncidentsPath = `lobs.${LOB_NAME}.pevehicleIncidents_Ext`

    const propertyIncidents = _.get(claimVM.value, propertyIncidentsPath, [])
    const injuryIncidents = _.get(claimVM.value, injuryIncidentsPath, [])
    const vehicleIncidents = _.get(claimVM.value, vehicleIncidentsPath, [])

    const onNext = useCallback(async () => {
        const updatedClaim = await FNOLService.saveFNOLDetails(claimVM.value, authHeader)
        const updatedClaimVM = _.clone(claimVM)
        updatedClaimVM.value = updatedClaim
        updateWizardData(updatedClaimVM)
        return updatedClaimVM
    }, [authHeader, claimVM, updateWizardData]);

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const updatePropertyIncidents = useCallback((newPropertyIncidents) => {
        _.set(claimVM.value, propertyIncidentsPath, newPropertyIncidents)

        updateWizardData(claimVM)
    }, [claimVM, propertyIncidentsPath, updateWizardData])

    const updateInjuryIncidents = useCallback((newInjuryIncidents) => {
        const newClaimVM = _.clone(claimVM)
        _.set(newClaimVM.value, injuryIncidentsPath, newInjuryIncidents)

        updateWizardData(newClaimVM)
    }, [claimVM, injuryIncidentsPath, updateWizardData])

    const updateVehicleIncidents = useCallback((newVehicleIncidents) => {
        const newClaimVM = _.clone(claimVM)
        _.set(newClaimVM.value, vehicleIncidentsPath, newVehicleIncidents)
        
        updateWizardData(newClaimVM)
    }, [claimVM, updateWizardData, vehicleIncidentsPath])

    const overrideProps = useMemo(() => ({
        propertyIncidents: {
            propertyIncidents: propertyIncidents,
            updatePropertyIncidents: updatePropertyIncidents,
        },
        injuryIncidents: {
            injuryIncidents: injuryIncidents,
            updateInjuryIncidents: updateInjuryIncidents,
        },
        vehicleIncidents: {
            vehicleIncidents: vehicleIncidents,
            updateVehicleIncidents: updateVehicleIncidents,
        },
    }), [
        propertyIncidents, 
        updatePropertyIncidents,
        injuryIncidents,
        updateInjuryIncidents,
        vehicleIncidents,
        updateVehicleIncidents
    ]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            PropertyIncidents: PropertyIncidents,
            InjuryIncidents: InjuryIncidents,
            VehicleIncidents: VehicleIncidents,
        }
    };

    return (
        <WizardPage disableNext={!isComponentValid} onNext={isComponentValid ? onNext : setValidation} template={WizardPageTemplate} skipWhen={initialValidation} >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={resolvers.resolveValue}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLHOThirdPartyLossDetails.propTypes = wizardProps;
FNOLHOThirdPartyLossDetails.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(FNOLHOThirdPartyLossDetails);
 