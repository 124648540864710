import React from "react";
import OtherInformationComponent from "wni-capability-quoteandbind-ho-react/pages/HOResidence/Component/OtherInformationComponent/OtherInformationComponent";
import DPQuestionSetsComponent from "../DPQuestionSetsComponent/DPQuestionSetsComponent";


function DPOtherInformationComponent(props) {

    const componentMapOverride = {
        QuestionSetsComponent: DPQuestionSetsComponent
    }

    return <OtherInformationComponent
        {...props}
        componentMapOverride={componentMapOverride}
    />
}

DPOtherInformationComponent.propTypes = OtherInformationComponent.propTypes;
DPOtherInformationComponent.defaultProps = OtherInformationComponent.defaultProps;

export default DPOtherInformationComponent;