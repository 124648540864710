const marketingMessageOptions = {
    uic: [
        {
            code: 'Umialik Insurance has been providing local insurance coverage for forty years. It’s peace of mind, made in Alaska.',
            name: 'Umialik Insurance has been providing local insurance coverage for forty years. It’s peace of mind, made in Alaska.'
        },
        {
            code: 'With forty years of serving the unique insurance needs of Alaskans, Umialik Insurance is for Alaskans, by Alaskans.',
            name: 'With forty years of serving the unique insurance needs of Alaskans, Umialik Insurance is for Alaskans, by Alaskans.'
        },
    ],
    default: [
        {
            code: 'Western National offers a Paid-in-Full Discount - making it easy for you to save money on your insurance policy.',
            name: 'Western National offers a Paid-in-Full Discount - making it easy for you to save money on your insurance policy.'
        },
        {
            code: 'With their Roadside Assistance Plus option, you’re covered when you need towing, flat tire service, a battery jump start, and more!',
            name: 'With their Roadside Assistance Plus option, you’re covered when you need towing, flat tire service, a battery jump start, and more!'
        },
        {
            code: 'Have a new vehicle? Western National has a New Vehicle Replacement coverage option to ensure you get full value (depreciation included) if that new vehicle is damaged.',
            name: 'Have a new vehicle? Western National has a New Vehicle Replacement coverage option to ensure you get full value (depreciation included) if that new vehicle is damaged.'
        },
        {
            code: 'At Western National their focus is on protecting individuals and families, so that you can focus on the things that matter to you.',
            name: 'At Western National their focus is on protecting individuals and families, so that you can focus on the things that matter to you.'
        },
        {
            code: 'With Western National’s Common Loss Deductible, policyholders can choose to apply a single deductible whenever an occurrence involves more than one eligible policy.',
            name: 'With Western National’s Common Loss Deductible, policyholders can choose to apply a single deductible whenever an occurrence involves more than one eligible policy.'
        }
    ]
};

export default {
    marketingMessageOptions,
};
