import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';

const {
    capabilitiesConfig,
    defaultDebugProfile: {
        isMenuBarVisible,
        landingPage,
        //
        debugModeEnabled,
    }
} = appConfig;


const LANDING_PAGE_DASHBOARD = 'dashboard';
const CAPABILITY_DEBUG = 'debug';


// Enable Debug Mode on Cloud when and only when its value is 'true'
const debugModeEnabledOnCloudEnv = debugModeEnabled === 'true';
const debugModeEnabledThroughCapability = isCapabilityEnabled({ capabilitiesConfig, capabilityName: CAPABILITY_DEBUG });

const DEBUG_ENABLED = debugModeEnabledOnCloudEnv || debugModeEnabledThroughCapability;


function isDebugEnabled() {
    return DEBUG_ENABLED;
}

function getDefaultMenuBarVisibility() {
    return isMenuBarVisible;
}

function getFavicon(domain) {
    const faviconPath = domain.favicon;
    let $favicon = document.querySelector('link[rel="shortcut icon"]');
    // If a <link rel="icon"> element already exists,
    // change its href to the given link.
    if ($favicon !== null) {
        $favicon.href = faviconPath;
    // Otherwise, create a new element and append it to <head>.
    } else {
        $favicon = document.createElement('link');
        $favicon.rel = 'shortcut icon';
        $favicon.href = faviconPath;
        document.head.appendChild($favicon);
    }
}

/**
 * <p>
 * This method intends to get the default domainData based on current, well, domain.
 * </p>
 * <p>
 * DomainData is the list defined in DomainData.config.js
 * </p>
 * <p>
 * Possible Outcomes:
 * wnins.com -> DomainData[0]
 * umialik.com -> DomainData[1]
 * </p>
 * @param {array} DomainData array of {domainUrl}
 * @returns {object} default to DomainData[0]
 */
function getDefaultDomain(DomainData) {
    // domain would be something like quote.wnins.com or quote.umialik.com
    // Refactoring Note: What if hostname looks like "xxx.yyy.zz.umialik.com"?
    const domain = window.location.hostname;
    const domainToAssign = DomainData.find((brandingArray) => {
        return domain.endsWith(brandingArray.domainUrl);
    });

    return domainToAssign || DomainData[0];
}


function getDefaulInteractionMode(InteractionModes) {
    if (DEBUG_ENABLED) {
        return InteractionModes[0];
    }
    return InteractionModes[1];
}

function getCurrentLandingPage(debugLandingPage, productionLandingPage) {
    if (DEBUG_ENABLED && LANDING_PAGE_DASHBOARD === landingPage) {
        return debugLandingPage;
    }
    return productionLandingPage;
}

export default {
    isDebugEnabled,
    getDefaultMenuBarVisibility,
    getDefaultDomain,
    getDefaulInteractionMode,
    getCurrentLandingPage,
    getFavicon
};
