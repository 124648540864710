import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { WniChangeConflict } from 'wni-capability-gateway';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { WniEndorsementService } from 'wni-capability-policychange';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WindowUtil, WniDateUtil } from 'wni-portals-util-js';
import metadata from './ChangeConflictsComponent.metadata.json5';
import messages from './ChangeConflictsComponent.messages';
import styles from './ChangeConflictsComponent.module.scss';

import { Button } from '@jutro/legacy/components';

function ChangeConflictsComponent(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        baseVM,
        jobID,
        policyNumber,
        useAuthenticationData
    } = props;
    const authData = useAuthenticationData || useAuthentication();
    const { authHeader } = authData;
    const [futureConflict, setFutureConflict] = useState(false);
    const [changeConflictData, setChangeConflictData] = useState([]);
    const [isResData, setResData] = useState(false);
    const [showConflictDate, setShowConflictDate] = useState();
    const [showEffectDate, setShowEffectDate] = useState();
    const [notificationOverrideMsg, setnotificationOverrideMsg] = useState(false);

    const translator = useTranslator();
    const {
        effectiveDate: effectiveDateInBaseVM,
        effectiveDate_Ext: effectiveDateExtInBaseVM
    } = baseVM.value

    const effectiveDate = effectiveDateInBaseVM || effectiveDateExtInBaseVM;


    const getEffectiveDate = () => {
        let day = effectiveDate.day;
        let month = effectiveDate.month + 1;
        const year = effectiveDate.year;
        if (day < 9) {
            day = `0${ day}`;
        }
        if (month < 9) {
            month = `0${ month}`;
        }
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        WniChangeConflict.getChangeConflictData(jobID, authHeader)
            .then((res) => {
                setChangeConflictData(res);
                if (res.length > 0) {
                    setResData(true);
                }
            });
        WniEndorsementService.getOOSSliceDates([policyNumber, effectiveDate], authHeader)
            .then((conflictDate) => {
                setShowConflictDate(conflictDate);
            });
        const showEffectiveDate = getEffectiveDate();
        setShowEffectDate(showEffectiveDate);
    }, []);

    useEffect(() => {
        WindowUtil.scrollTo('notificationOverrideMsgContainer');
    }, [notificationOverrideMsg]);


    const handleFutureConflict = (val) => {
        setFutureConflict(val);
    };

    const handleSubmit = useCallback(() => {
        if (isResData) {
            setnotificationOverrideMsg(true);
        } else {
            setnotificationOverrideMsg(false);
            onResolve();
        }
    }, [isResData, onResolve]);

    const onOkBtn = () => {
        WniChangeConflict.overrideConflictData(jobID, futureConflict, authHeader).then(() => {
            onResolve();
        });
    };

    const ononCancelBtn = () => {
        setnotificationOverrideMsg(false);
    };

    const getValidationIssuesMap = () => {
        const validationIssuesMap = [
            {
                type: 'error',
                reason: messages.PCPAErrorMsg.defaultMessage
            },
            {
                type: 'warning',
                reason: `${messages.PCPAWarnMsg1.defaultMessage} ${showConflictDate}`
            },
            {
                type: 'warning',
                reason: messages.PCPAWarnMsg2.defaultMessage
            },
            {
                type: 'warning',
                reason: messages.PCPAWarnMsg3.defaultMessage
            }
        ];
        return validationIssuesMap;
    };


    const resolvers = {
        resolveCallbackMap: {
            onOk: onOkBtn,
            onCancel: ononCancelBtn
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent
        }
    };

    const overrideProps = {
        PCFutureConflict: {
            onValueChange: handleFutureConflict,
            value: futureConflict
        },
        changeConflictTable: {
            data: changeConflictData,
        },
        tableColPCEff: {
            header: `${translator(messages.PolicyChangeEff)} ${showEffectDate}`
        },
        notificationOverrideMsgContainer: {
            visible: notificationOverrideMsg
        },
        validationissuesContainer: {
            validationIssues: getValidationIssuesMap(),
            scrollToIssues: true,
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            baseVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ModalNext isOpen={isOpen} className={size} id="conflictWin">
            <ModalHeader title={ title || translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredMwodal">
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={baseVM}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    resolveValue={readValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel || translator(messages.cancel)}
                </Button>
                <Button onClick={handleSubmit}>
                    {actionBtnLabel || translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

ChangeConflictsComponent.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    // authHeader: PropTypes.shape({}).isRequired,
    baseVM: PropTypes.shape({}).isRequired,
    jobID: PropTypes.string.isRequired,
    policyNumber: PropTypes.string.isRequired
};

ChangeConflictsComponent.defaultProps = {
    size: 'md'
};

export default ChangeConflictsComponent;
