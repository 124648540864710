
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALWatercraftsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function WALWatercraftsChangeReadOnlyPage(props) {

    return (
        <WALWatercraftsReadOnlyPage {...props} checkRequiredForIssuance={false} />
    );

}

WALWatercraftsChangeReadOnlyPage.propTypes = WALWatercraftsReadOnlyPage.propTypes;
WALWatercraftsChangeReadOnlyPage.defaultProps = WALWatercraftsReadOnlyPage.defaultProps;

export default WALWatercraftsChangeReadOnlyPage;