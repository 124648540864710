import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
    useMemo,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { useTranslator, IntlContext } from '@jutro/locale';
import { PortalConstants } from 'wni-portals-config-js';
import { WALCoveragesService } from 'wni-capability-quoteandbind-wal';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WizardPage from '../../templates/WALWizardPage';
import WALCoveragesConfig from './WALCoverages.config';
import WALCoverageUtil from './util/WALCoverageUtil';

import WALLineCoverages from './CoveragesSection/WALLineCoverages/WALLineCoverages';
import WALWaterCraftCoverages from './CoveragesSection/WALWaterCraftCoverages/WALWaterCraftCoverages';

import styles from './WALCoveragesPage.module.scss';
import metadata from './WALCoveragesPage.metadata.json5';
import messages from './WALCoveragesPage.messages';
import CoveragesConfigContext from './context/WALCoveragesConfigContext';

function WALCoveragesPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        currentStep: { id: currentPageId },
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            watercraft: {
                coverables: {
                    watercrafts,
                },
                offerings
            }
        },
    } = submissionVM.value;

    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
    } = useValidation('WALCoveragesPage');
    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false)

    const [validationIssues, updateValidationIssues] = useState([])
    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelCoverages',
        'watercraftCoverages',
    ]);
    const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    const lobName = 'watercraft'

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const {
        coverages: {
            watercraftCoverages
        }
    } = selectedVersion

    const watercraftPublicIDsWithInvalidCoverage = useMemo(
        () => watercraftCoverages.filter((watercraftCovDTO) => {
            const coveragesPerfWatercraft = _.get(watercraftCovDTO, 'coverages', [])
            return coveragesPerfWatercraft.some((cov) => WALCoverageUtil.isCoverageInvalid(cov))
        }).map((watercraftCovDTO) => watercraftCovDTO.owningCoverablePublicID),
        [watercraftCoverages])

    const errMsgsInvalidWatercraft = useMemo(
        () => showErrors ? watercraftPublicIDsWithInvalidCoverage.map((watercraftPublicID) => {
            const watercraft = watercrafts.find((w) => w.publicID === watercraftPublicID)
            const watercraftDesc = WALCoverageUtil.getWatercraftDescription(watercraft)
            return {
                type: 'error',
                reason: translator(messages.watercraftCoverageInvalidField, {
                    watercraftDesc
                })
            }
        }) : [], [translator, watercraftPublicIDsWithInvalidCoverage, watercrafts, showErrors])

    const pageLevelValidation = useMemo(
        () => validationIssues.concat(errMsgsInvalidWatercraft),
        [errMsgsInvalidWatercraft, validationIssues]
    )
    const isWatercraftsCoveragesValid = useCallback(() => {
        return watercraftPublicIDsWithInvalidCoverage.length === 0;
    }, [watercraftPublicIDsWithInvalidCoverage]);

    useEffect(() => {
        registerComponentValidation(isWatercraftsCoveragesValid);
    }, [isWatercraftsCoveragesValid, registerComponentValidation]);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const onPageNext = async () => {
        // Call update coverages service to save coverages not sync and check errors and warnings
        const coverages = _.get(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = WALCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = WALCoverageUtil.getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await WALCoveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        setLoadingLineCoverages(false);
        const lobCoverages = _.get(response, lobName);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        _.set(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        return newSubmissionVM;
    }

    const handleValidation = () => {
        setShowErrors(true)
        return false
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            waLineCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                onValidate,
                showErrors,
                updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                isEditing,
                setIsEditing,
                lobName,
                coveragesService: WALCoveragesService,
                loadingLineCoverages,
                setLoadingLineCoverages,
            },
            waWatercraftCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                showErrors,
                updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                lobName,
                coveragesService: WALCoveragesService,
                currentPageId,
            }
        };
    }, [
        isEditing,
        lobName,
        onValidate,
        selectedVersion,
        selectedVersionIndex,
        showErrors,
        submissionVM,
        updateErrorsAndWarningsForCovComponent,
        updateWizardDataToDraft,
        openedAccordions,
        loadingLineCoverages,
        setLoadingLineCoverages,
        currentPageId
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            waLLineCoverages: WALLineCoverages,
            WALWaterCraftCoverages: WALWaterCraftCoverages,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={isEditing || loadingLineCoverages}
            disablePrevious={isEditing || loadingLineCoverages}
            disableCancel={isEditing || loadingLineCoverages}
            onNext={isComponentValid ? onPageNext : handleValidation}
            alwaysCallOnNext
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            <CoveragesConfigContext.Provider value={WALCoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
}

WALCoveragesPage.propTypes = WizardPage.propTypes;
export default WALCoveragesPage;
