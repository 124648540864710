import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalCoverages: {
        "id": "quoteandbind.im.wizard.step.commonAdditionalCov page.additional additionalCoverages",
        "defaultMessage": "Additional Coverages"
    },
    AddAdditionalCoverages: {
        "id": "quoteandbind.im.wizard.step.commonAdditionalCov page.Add AdditionalCoverages",
        "defaultMessage": "Add Additional Coverages"
    },
    AddedAdditionalCoverages: {
        "id": "quoteandbind.im.wizard.step.commonAdditionalCov page.Added AdditionalCoverages",
        "defaultMessage": "Added Additional Coverages"
    },
});