import {
    useState, useCallback, useRef, useEffect
} from 'react';
import _ from 'lodash';

/**
 * the V2 name is reserved for the OOTB useWizardError
 * @param {array} initialWizardLevelIssues 
 * @returns {object}
 */
function useBaseWizardErrorsV2(initialWizardLevelIssues = []) {

    const [showRequiredInfo, updateShowRequiredInfo] = useState(false);
    const [showEntityNameInIssues, updateShowEntityNameInIssues] = useState(false);
    // Note: it is a map
    // object type: { currentStep.id: String => [{ type: string, reason: string }]}
    const [requiredInfoMessages, updateRequiredInfoMessages] = useState({});
    const [pageLevelIssues, updatePageLevelIssues] = useState({});
    const [pageLevelStickyIssues, updatePageLevelStickyIssues] = useState({});
    
    // Note: it is an array
    // wizardLevelIssues are always sticky, object type: [{ type: string, reason: string }]
    const [wizardLevelIssues, updateWizardLevelIssues] = useState(initialWizardLevelIssues);
    
    const getRequiredInfoMessage = useCallback((pageId) => {
        const retval = _.get(requiredInfoMessages, pageId); // default to undefined
        return retval;
    }, [requiredInfoMessages]);

    const updateRequiredInfoMessage = useCallback((pageId, requiredInfoMessage) => {
        updateRequiredInfoMessages({
            ...requiredInfoMessages,
            [pageId]: requiredInfoMessage,
        });
    }, [requiredInfoMessages, updateRequiredInfoMessages]);

    const getWizardPageIssues = useCallback((pageId) => {
        const retval = _.get(pageLevelIssues, pageId, []);
        return retval;
    }, [pageLevelIssues]);

    const updateWizardPageIssues = useCallback((pageId, validationIssues) => {
        updatePageLevelIssues({
            ...pageLevelIssues,
            [pageId]: validationIssues,
        });
    }, [pageLevelIssues, updatePageLevelIssues]);

    const getWizardPageStickyIssues = useCallback((pageId) => {
        const retval = _.get(pageLevelStickyIssues, pageId, []);
        return retval;
    }, [pageLevelStickyIssues]);

    const updateWizardPageStickyIssues = useCallback((pageId, validationIssues) => {
        updatePageLevelStickyIssues({
            ...pageLevelStickyIssues,
            [pageId]: validationIssues,
        });
    }, [pageLevelStickyIssues, updatePageLevelStickyIssues]);

    const updateAllWizardPageStickyIssues = useCallback((allIssues) => {
        updatePageLevelStickyIssues(allIssues);
    }, [updatePageLevelStickyIssues]);

    //
    const updateWizardIssues = useCallback((validationIssues = []) => {
        updateWizardLevelIssues(validationIssues);
    }, [updateWizardLevelIssues]);

    return {
        //
        showRequiredInfo,
        updateShowRequiredInfo,
        showEntityNameInIssues,
        updateShowEntityNameInIssues,
        // Page Level
        getRequiredInfoMessage,
        updateRequiredInfoMessage,
        //
        getWizardPageIssues,
        updateWizardPageIssues,
        //
        getWizardPageStickyIssues,
        updateWizardPageStickyIssues,
        updateAllWizardPageStickyIssues,
        // Wizard Level
        wizardIssues: wizardLevelIssues,
        updateWizardIssues,
    };
}

export default useBaseWizardErrorsV2;