
import React from 'react';

import { DPLossHistoryAndPriorCarrierPage } from 'wni-capability-quoteandbind-dp-react';

function DPLossHistoryAndPriorCarrierChangePage(props) {
    return (
        <DPLossHistoryAndPriorCarrierPage {...props}
            productName = 'dwellingProperty'
            disableClueAndPriorCarrierEdit
            showPolicyClaims
            disableAddButton
            checkRequiredForIssuance = {false}
        />
    );
}

DPLossHistoryAndPriorCarrierChangePage.propTypes = DPLossHistoryAndPriorCarrierPage.propTypes;
DPLossHistoryAndPriorCarrierChangePage.defaultProps = DPLossHistoryAndPriorCarrierPage.defaultProps;

export default DPLossHistoryAndPriorCarrierChangePage;
