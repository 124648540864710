
const FIRST_CHANGE_WIZARD_PAGE = 'PUHouseholdInfoPage';

function getChangePageJumpList({ policyChangeSource, getPageJumpAction }) {
    // return [{
    //     name: 'PU Page',
    //     action: getPageJumpAction('PUDummyPage'),
    // }];
    return  [{
        name: 'Household Information',
        action: getPageJumpAction('PUHouseholdInfoPage'),
    }, {
        name: 'Underlying Policy Details',
        action: getPageJumpAction('PUUnderlyingPage'),
    }, {
        name: 'Coverages',
        action: getPageJumpAction('PUCoveragesPage'),
    }, {
        name: 'Loss History',
        action: getPageJumpAction('PULossHistoryPage'),
    }];
}

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};