import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('crPolicyDetailsChangeService'), method, data, additionalHeaders);
}

export default class CRPolicyDetailsChangeService {

    /**
     * Invokes CRPolicyDetailsChangeHandler.savePolicyDetailsData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} policyDetailsData
     * @param {Object} quoteBaseData
     * @param {Object} changeData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static savePolicyDetailsData(requestData, authHeader = {}) {
        return processSubmission('savePolicyDetailsData', [
            requestData.jobID,
            requestData.sessionUUID,
            requestData.policyDetailsData,
            requestData.quoteBaseData,
            requestData.changeData], authHeader);
    }

}