import { Button } from '@jutro/components';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { IMAccountsReceivableService } from 'wni-capability-quoteandbind-im';
import { WniCheckboxField, WniDataTable } from 'wni-common-base-components';
import { useValidation } from '@xengage/gw-portals-validation-react';

import IMScheduleItemReadonlyCell from '../../../../../components/IMScheduleTable/IMScheduleItemReadonlyCell';
import ScheduleItemForm from '../ScheduleItemForm';
import messages from './CoveragePartSchedule.messages';
import IMCoverageUtil from '../../../../../util/IMCoverageUtil';

const CoveragePartSchedule = (props) => {
    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { authHeader } = useAuthentication();

    const {
        schedule,
        isEditable,
        setCoveragePartClauses,
        jobID,
        sessionUUID,
        setIsEditing,
        isEditing,
        onValidate: onContainerValidate,
        showErrors,
    } = props;

    const validationID = 'AccountReceivableSchedule'

    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(validationID);

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, validationID);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, validationID);
            }
        };
    }, [isComponentValid, onContainerValidate, validationID]);

    const {
        propertyInfos = [],
        coveragePropertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
    } = schedule;

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] =
        useState([]);
    const [openedScheduleItem, setOpenedScheduleItem] = useState();

    const isScheduleInvalid = IMCoverageUtil.isScheduleInvalid(schedule)

    const checkItemNotEditingOrInvalid = useCallback(() => _.isNil(openedScheduleItem) && !isScheduleInvalid, [isScheduleInvalid, openedScheduleItem])
    
    useEffect(() => {
        registerComponentValidation(checkItemNotEditingOrInvalid);
    }, [checkItemNotEditingOrInvalid, registerComponentValidation]);

    const sortedScheduleItems = scheduleItems.toSorted(
        (scheduleItemA, scheduleItemB) => {
            const getScheduleNumber = (scheduleItem) => {
                return _.get(scheduleItem, 'itemNumber');
            };
            return (
                getScheduleNumber(scheduleItemA) -
                getScheduleNumber(scheduleItemB)
            );
        }
    );

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const saveScheduleItems = async (newScheduleItems) => {
        const scheduleRequest = _.clone(schedule);
        _.set(scheduleRequest, 'updated_Ext', true);
        _.set(scheduleRequest, 'scheduleItems', newScheduleItems);
        const newCoveragePartCoverages = await withLoadingMask(() =>
            IMAccountsReceivableService.updateCoveragePartSchedule(
                jobID,
                sessionUUID,
                scheduleRequest,
                authHeader
            )
        );
        setCoveragePartClauses(newCoveragePartCoverages);
        const {
            coveragePartSchedule: { scheduleItems: newScheduleItemsRes },
        } = newCoveragePartCoverages;
        return newScheduleItemsRes;
    };

    const delSchedule = async () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const { itemNumber } = scheduleItem;
            return !selectedScheduleItemNumbers.includes(itemNumber);
        });
        await saveScheduleItems(newScheduleItems);
        setSelectedScheduleItemNumbers([]);
        setOpenedScheduleItem(undefined);
    };

    const addSchedule = async () => {
        const newScheduleItems = scheduleItems.concat([
            {
                '@deserialization-class': deserializationClass,
                itemData: {},
            },
        ]);
        const oldScheduleItemPublicIds = scheduleItems.map(
            (item) => item.publicId_Ext
        );
        const newScheduleItemsRes = await saveScheduleItems(newScheduleItems);
        const newAddedItem = newScheduleItemsRes.find(
            (item) => !oldScheduleItemPublicIds.includes(item.publicId_Ext)
        );
        setOpenedScheduleItem(newAddedItem);
    };

    const handleScheduleItemCancel = () => setOpenedScheduleItem(undefined);

    const handleSaveScheduleItem = async (newScheduleItem) => {
        const { publicId_Ext: publicId } = newScheduleItem;

        const newScheduleItems = scheduleItems.map((scheduleItem) => {
            if (scheduleItem.publicId_Ext === publicId) {
                return newScheduleItem;
            }
            return scheduleItem;
        });
        await saveScheduleItems(newScheduleItems);
        setOpenedScheduleItem(undefined);
    };

    const scheduleNumberColumn = (
        <DisplayColumn
            key="ScheduleNumber"
            header="Schedule Number"
            sortable={false}
            textAlign="left"
            path="itemNumber"
            renderCell={(scheduleItem) => {
                const { itemNumber } = scheduleItem;
                if (!isEditable || scheduleReadonly) {
                    return itemNumber;
                }
                return (
                    <WniCheckboxField
                        value={selectedScheduleItemNumbers.includes(itemNumber)}
                        label={itemNumber}
                        showInlineLabel
                        onValueChange={(checked) => {
                            let newSelectedScheduleItemNumbers;
                            if (checked) {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.concat([
                                        itemNumber,
                                    ]);
                            } else {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.filter(
                                        (num) => num !== itemNumber
                                    );
                            }
                            setSelectedScheduleItemNumbers(
                                newSelectedScheduleItemNumbers
                            );
                        }}
                    />
                );
            }}
        />
    );

    const propertyInfoColumns = propertyInfos.map((propertyInfo) => {
        const { id, label } = propertyInfo;

        return (
            <DisplayColumn
                key={id}
                header={label}
                renderCell={(scheduleItem) => {
                    return (
                        <IMScheduleItemReadonlyCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                        />
                    );
                }}
                sortable={false}
            />
        );
    });

    const termPropertyColumns = coveragePropertyInfos
        .sort((a, b) => a.order - b.order)
        .map((coveragePropertyInfo) => {
            const { id, label } = coveragePropertyInfo;

            return (
                <DisplayColumn
                    key={id}
                    header={label}
                    renderCell={(scheduleItem) => {
                        const { scheduleItemTerms } = scheduleItem;
                        const term = scheduleItemTerms.find(
                            (t) => t.code_Ext === id
                        );

                        return _.get(term, 'chosenTermValue');
                    }}
                    sortable={false}
                />
            );
        });

    const actionColumn = (
        <DisplayColumn
            key="ActionColumn"
            sortable={false}
            textAlign="left"
            renderCell={(scheduleItem) => {
                return (
                    <Button
                        className="btn-link"
                        onClick={() => setOpenedScheduleItem(scheduleItem)}
                        label={`${translator(messages.scheduleEdit)}/${translator(
                            messages.scheduleView
                        )}`}
                    />
                );
            }}
        />
    );

    const columns = [
        scheduleNumberColumn,
        ...propertyInfoColumns,
        ...termPropertyColumns,
    ];
    const dataTableColumns =
        isEditable && !scheduleReadonly ? [...columns, actionColumn] : columns;

    return (
        <>
            {isEditable && !scheduleReadonly && (
                <div className='mt-10'>
                    <h4>{translator(messages.ListOfScheduleItems)}</h4>
                    <Flex gap="small" justifyContent="right" className="mb-10">
                        <Button
                            className="wni-button-danger"
                            disabled={selectedScheduleItemNumbers.length === 0}
                            onClick={delSchedule}
                            label={messages.scheduleDel}
                        />
                        <Button icon="gw-add" onClick={addSchedule} label={messages.scheduleAdd} />
                    </Flex>
                </div>
            )}
            <div className="table-wrapper mb-10">
                <WniDataTable
                    id="clause_schedule_table_IMAccountReceivableScheduledCov_Ext"
                    data={sortedScheduleItems}
                    showSearch={false}
                >
                    {sortedScheduleItems.length > 0 ? dataTableColumns : []}
                </WniDataTable>
            </div>
            {openedScheduleItem && (
                <>
                    <ScheduleItemForm
                        propertyInfos={propertyInfos}
                        coveragePropertyInfos={coveragePropertyInfos}
                        scheduleItem={openedScheduleItem}
                        handleScheduleItemCancel={handleScheduleItemCancel}
                        handleSaveScheduleItem={handleSaveScheduleItem}
                        isEditable={isEditable}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        onValidate={onValidate}
                        showErrors={showErrors}
                    />
                    {showErrors && <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.itemFormNotClosedMessage)}</div>}
                </>
            )}
            {showErrors && isScheduleInvalid && <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.scheduleInvalidMessage)}</div>}
        </>
    );
};

export default CoveragePartSchedule;
