import { DatatableUtil } from '@xengage/gw-portals-util-js';
import _ from 'lodash'
import {
    HOSSPDeductibleOptionValueMap,
} from './HOSchedulePersonalPropertyUtil'

const sortByOptionCode = (optionA, optionB) => {
    const getCodeFromOption = (option) => _.get(option, 'code')
    return DatatableUtil.sortNumber(getCodeFromOption(optionA), getCodeFromOption(optionB))
}

const sortHOSSPDeductibleByOptionValue = (optionA, optionB) => {
    const getOptionValueFromOption = (option) => _.get(HOSSPDeductibleOptionValueMap, _.get(option, 'code'))
    return DatatableUtil.sortNumber(getOptionValueFromOption(optionA), getOptionValueFromOption(optionB))
}

export default {
    sortByOptionCode,
    sortHOSSPDeductibleByOptionValue,
};