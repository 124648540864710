import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useHistory } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { CADriverService } from 'wni-capability-quoteandbind-ca';
import { CustomFooterV2Component } from 'wni-capability-gateway-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import {
    QuoteUtil,
    WindowUtil,
    ValidationIssueUtil,
    DocumentsUtil,
    ServiceErrorUtil
} from 'wni-portals-util-js';

import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import { messages as commonMessages } from '@xengage/gw-platform-translations';
import moment from 'moment';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import ImportDriverModal from './Components/ImportDriverModal/ImportDriverModal';
import ExportDriverModal from './Components/ExportDriverModal/ExportDriverModal';
import WizardPage from '../../templates/CAWizardPage';
import CADriverComponent from './Components/CADriverComponent/CADriverComponent';
import CADriverUtil from './Util/CADriversUtil';

import metadata from './CADriversPage.metadata.json5';
import messages from './CADriversPage.messages';

function CADriversPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        wizardSnapshot,
        updateWizardData,
        resetWizardDataToSnapshot,
        caDriverService
    } = props;

    // const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CADriversPage');

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [currentRow, updateCurrentRow] = useState(null);
    const [driverData, updateDriverData] = useState([]);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [file, updateFile] = useState(null);
    const [isMaskPrivateFields, updateisMaskPrivateFields] = useState(false);
    const { domainCompany } = useDependencies('domainCompany');

    const driversVMPath = 'lobData.commercialAuto.coverables.drivers';
    const driversVM = _.get(submissionVM, driversVMPath);
    const policyCountryCode = _.get(submissionVM.value, 'baseData.policyAddress.country');
    const licenseStateOptions = _.get(submissionVM.value, 'lobData.commercialAuto.coverables.licenseStateOptions');
    const driverReleaseAuthForm = _.get(submissionVM.value, 'lobData.commercialAuto.coverables.driverReleaseAuthForm');
    const {
        jobID,
        sessionUUID,
        baseData: {
            effectiveDate_Ext: effectiveDate
        }
    } = submissionVM.value;

    const VALIDATION_ICON_MAP = {
        success: 'gw-check-circle',
        warning: 'gw-warning',
        error: 'gw-error',
    };

    const isBeforeToday = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const formatDate = new Date(date);
        formatDate.setHours(0, 0, 0, 0);

        return formatDate.getTime() < today.getTime();
    }

    useEffect(() => {
        const formatEffectiveDate = new Date(`${effectiveDate.year}-${effectiveDate.month + 1}-${effectiveDate.day}`).getTime();
        if (isBeforeToday(formatEffectiveDate)) {
            updateisMaskPrivateFields(true);
        }
    }, []);

    const setRowIdPath = useCallback(() => {
        const drivers = _.get(submissionVM, `${driversVMPath}.value`);
        drivers.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateDriverData(drivers);
    }, [submissionVM]);

    const switchToNextDriver = useCallback(() => {
        const childrenVM = _.get(submissionVM, `${driversVMPath}.children`);
        const index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            updateCurrentRow(_.get(childrenVM, 0));
            return;
        }
        updateCurrentRow(_.get(childrenVM, index + 1));
    }, [currentRow, submissionVM]);

    useEffect(() => {
        setRowIdPath();
        _.each(driversVM.value, (driver) => {
            CADriverUtil.truncatedPhoneNumber(driver);
        })
    }, [submissionVM, switchToNextDriver]);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const requiredInfoMessages = useMemo(() => {
        return translator(messages.requiredNotification, {
            fasterQuote: <span className="font-Primary-bold-16">{translator(messages.fasterQuote)}</span>,
            asteriskIcon: <span className="font-error-bold-16">*</span>
        })
    }, [])

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${driversVMPath}.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        const hasIssuanceInvalidFields = CADriverUtil.hasIssuanceInvalidFields(itemVM);
        let type;
        if(!_.get(itemVM, 'aspects.valid') || !_.get(itemVM, 'aspects.subtreeValid')) {
            type = 'error';
        } else if (hasIssuanceInvalidFields) {
            type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const viewOrEditDriver = useCallback((value, index) => {
        const childrenVM = _.get(submissionVM, `${driversVMPath}.children`);
        const driverVM = childrenVM.find((item) => item.value.rowIdPath === index);
        updateShowErrors(false);
        setRowIdPath();
        updateCurrentRow(driverVM);
    }, []);

    const getValidationIssues = useCallback((resErrorsAndWarnings) => {
        const errorsAndWarnings = !_.isEmpty(resErrorsAndWarnings) ? resErrorsAndWarnings : _.get(submissionVM, 'value.errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(_.uniqBy(newValidationIssues, 'reason'));
        return _.uniqBy(newValidationIssues, 'reason');
    }, [submissionVM]);

    const updateSubmissionVMForResponse = useCallback((res) => {
        const resDrivers = _.get(res, 'cadrivers');
        _.set(submissionVM, driversVMPath, resDrivers);
        _.set(submissionVM, 'errorsAndWarnings', res.errorsAndWarnings);
        _.set(submissionVM.value, 'baseData', res.baseData);

        _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        
        updateWizardSnapshot(submissionVM);
        updateDisplayWarnings(true);
        return submissionVM;
    }, [submissionVM]);

    const addDriver = useCallback(() => {
        const driverObj = CADriverUtil.setDefaultDriverObj(policyCountryCode, driversVM)
        const {
            _dtoName,
            _xCenter,
        } = driversVM;
        const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
        const addedDriver = driversVM
            .pushElement(driverVM);
        setRowIdPath();
        updateCurrentRow(addedDriver);
        updateWizardData(submissionVM);
        updateShowErrors(false);
    }, [submissionVM]);

    const cancel = useCallback(() => {
        resetWizardDataToSnapshot();
        updateCurrentRow(null);
    }, [wizardSnapshot]);

    const removeDrivers = useCallback(() => {
        modalApi.showConfirm({
            title: messages.removeDriverTitle,
            message: messages.removeDriverDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.Ok,
            cancelButtonText: messages.Cancel
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const requestData = {
                    jobID,
                    sessionUUID,
                    publicIDs: selection
                };
                updateServiceCallInProgress(true);
                const res = await caDriverService.removeDrivers(requestData, authHeader);
                updateServiceCallInProgress(false);
                updateCurrentRow(null);
                updateSelection([]);
                updateSubmissionVMForResponse(res);
                getValidationIssues(res.errorsAndWarnings);
                setRowIdPath();
                return true;
            }, _.noop
        );
    }, [selection]);

    const onPageNext = useCallback(async () => {
        //
        return submissionVM;
    }, [authHeader, submissionVM]);

    const hideButtonsProps = {
        showNext: !currentRow,
        showPrevious: !currentRow,
        showCancel: !currentRow
    };

    const handleError = useCallback((messageTitle, message) => {
        return modalApi.showAlert({
            title: messageTitle,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(_.noop, _.noop);
    }, []);

    const handleWarning = useCallback((messageTitle, message) => {
        return modalApi.showAlert({
            title: messageTitle,
            message: message,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(_.noop, _.noop);
    }, []);

    const claimsDocUploadToken = useCallback((async () => {
        try {
            const uploadTokenID = await GatewayDocumentService
                .generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }), [authHeader, handleError]);

    const uploadDocument = useCallback((async (uploadFile) => {
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: 'agency_correspondence_Ext',
            // description: _.get(vm, 'value.description'),
            securityType: 'unrestricted',
            status: 'approved',
            jobNumber: jobID,
            name: uploadFile.name,
            mimeType: uploadFile.type,
            sessionID: await claimsDocUploadToken()
        };
        try {
            await GatewayDocumentService
                .uploadDocument(uploadFile, documentMetaDataTemplate,
                    authHeader);
        } catch (error) {
            // BR.PL.0254
            // if (error
            //     && _.get(error, 'baseError.message') === 'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.'
            //     && _.get(error, 'baseError.code') === -32603
            // ) {
            //     const message = _.get(domainCompany, 'code') === 'UIC' ? messages.uploadFailedMessageUIC : messages.uploadFailedMessage;
            //     handleWarning(
            //         messages.warningUploadTitle,
            //         message
            //     );
            // } else {
            //     handleError(
            //         commonMessages.errorUploadTitle,
            //         commonMessages.uploadFailedMessage
            //     );
            // }
        }
    }), []);

    const displayErrorModal = useCallback(() => {
        modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    const saveDriver = useCallback(async (callback) => {
        if (!isComponentValid || !_.get(currentRow, 'aspects.valid') || !_.get(currentRow, 'aspects.subtreeValid')) {
            handleValidation();
            return false;
        }
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            driver: CADriverUtil.removeEmpty(currentRow.value)
        };
        const res = await caDriverService.updateDriver(requestData, authHeader);
        updateSubmissionVMForResponse(res);
        getValidationIssues(res.errorsAndWarnings);
        setRowIdPath();
        if (file) {
            uploadDocument(file)
        }
        updateServiceCallInProgress(false);
        updateSelection([]);
        if (_.isFunction(callback)) {
            await callback(null);
        }
    }, [currentRow, handleValidation, isComponentValid, updateWizardSnapshot]);

    const download = (isTemplete, type) => {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const serviceUrl = getProxiedServiceUrl('caDownloadService').concat(`/${jobID}?jobID=${jobID}&isTemplete=${isTemplete}&page=driver`);
        const fileName = `${type}_CA_Drivers_${date}`;
        return WniDocumentRetrievalService.generateDocument(serviceUrl, fileName, authHeader, _.noop, displayErrorModal)
    };

    const downloadTemplate = async() => {
        setLoadingMask(true);
        await download(true, 'Templete');
        setLoadingMask(false);
    };

    const exportDrivers = async() => {
        const componentProps = {
            title: messages.exportTitle,
            actionBtnLabel: translator(messages.exportToSpreadsheet),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            download,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        modalApi.showModal(<ExportDriverModal {...componentProps} />).then(() => _.noop).catch(()=> _.noop)
    }

    const downloadDriverReleaseForm = async () => {
        const successCallback = () => {
            updateServiceCallInProgress(false);
        };
        const errorCallback = () => {
            updateServiceCallInProgress(false);
            modalApi.showConfirm({
                title: 'Error',
                message: ServiceErrorUtil.prependWithFriendMessage(),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(_.noop).catch(_.noop);
        };
        if (!driverReleaseAuthForm) {
            modalApi.showConfirm({
                title: 'Error',
                message: translator(messages.noDocMessage),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(_.noop).catch(_.noop);
            return;
        }
        await DocumentsUtil.tryDownloadDocument(
            driverReleaseAuthForm, authHeader, history, WniDocumentRetrievalService, successCallback, errorCallback
        )
    }

    const importDriver = () => {
        const componentProps = {
            title: messages.importTitle,
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        modalApi.showModal(<ImportDriverModal {...componentProps} />).then((result) => {
            _.set(submissionVM, `${driversVMPath}.value`, result);
            updateWizardSnapshot(submissionVM);
            setRowIdPath();
        }).catch(() => {
            // do nothing when close the popup
            _.noop();
        });
    };

    const getFooterButtons = useCallback(() => {
        if (!currentRow) {
            return (<div />);
        }
        const nextButtonVisible = driversVM.value.length > 1;

        return (
            <CustomFooterV2Component
                saveAndNextMessage={translator(messages.saveAndNext)}
                handleCancel={cancel}
                handleSaveAndClose={() => {
                    saveDriver(updateCurrentRow);
                }}
                handleSaveAndNext={() => {
                    saveDriver(switchToNextDriver);
                }}
                nextButtonVisible={nextButtonVisible}
            />
        );
    }, [cancel, currentRow, saveDriver, switchToNextDriver, translator]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            downloadTemplate: {
                onClick: downloadTemplate
            },
            importTemplate: {
                onClick: importDriver
            },
            exportData: {
                onClick: exportDrivers
            },
            delDriver: {
                onClick: removeDrivers,
                disabled: _.isEmpty(selection)
            },
            addDriver: {
                onClick: addDriver,
                disabled: currentRow
            },
            driverTable: {
                data: driverData,
                onSelectionChange: (rows) => {
                    updateSelection(rows)
                },
                rowIdPath: 'rowIdPath'
            },
            licenseState: {
                renderCell: (item) => _.get(item, 'licenseState.code')
            },
            licenseNumber: {
                renderCell: (item) => CADriverUtil.onLicenseNumberCell(item, isMaskPrivateFields)
            },
            dateOfBirth: {
                renderCell: (item) => CADriverUtil.onDateOfBirthCell(item, isMaskPrivateFields)
            },
            driverValidationIcon:{
                renderCell: renderValidationCell
            },
            viewOrEditLink: {
                onClick: viewOrEditDriver,
                disabled: currentRow || selection.length > 1,
            },
            driver: {
                showErrors,
                visible: !_.isEmpty(currentRow),
                driverVM: currentRow,
                downloadDriverReleaseForm,
                file,
                updateFile,
                isMaskPrivateFields,
                updateCurrentRow,
                licenseStateOptions
            }
        };
    }, [removeDrivers, selection, addDriver, currentRow, driverData, viewOrEditDriver, showErrors, file]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            cadrivercomponent: CADriverComponent
        }
    };

    const renderPageContent = useCallback(({ onNext }) => {
        return <React.Fragment>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                // resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors = {showErrors}
                onValidationChange={onValidate}
            />
            {getFooterButtons(onNext)}
        </React.Fragment>;
    }, [overrideProps, resolvers, submissionVM, showErrors]);

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            disableNext={isServiceCallInProgress || CADriverUtil.hasInvalidDriver(driversVM)}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
            pageLevelRequiredInfoMessage={requiredInfoMessages}
            {...hideButtonsProps}>
            {renderPageContent}
        </WizardPage>
    );
}

CADriversPage.propTypes = WizardPage.propTypes;
CADriversPage.defaultProps = WizardPage.defaultProps;

CADriversPage.propTypes = {
    ...wizardProps,
    caDriverService: PropTypes.object
};

CADriversPage.defaultProps = {
    caDriverService: CADriverService
};
export default CADriversPage;