import { Button, ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import _ from 'lodash';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React, { useEffect, useState } from 'react';
import { CUUnderlyingService } from 'wni-capability-quoteandbind-cu';
import { WniProductsUtil } from 'wni-portals-util-js';
import metadata from './FetchExistingPoliciesPopup.metadata.json5';
import messages from '../../CUUnderlyingPage.messages';

const {
    GL_PRODUCT_CODE,
    getProductName,
    getProductNameByLinePattern
} = WniProductsUtil;

function FetchExistingPoliciesPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        accountNumber,
        fetchPolicies = _.noop,
        useDependenciesData,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        } = {},
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;

    const [tableData, updateTableData] = useState([]);
    const [selection, updateSelection] = useState([]);
    const [searchVal, updateSearchVal] = useState(accountNumber);
    
    const initData = async() => {
        const res = await fetchPolicies(accountNumber);
        updateTableData(res);
        const initSelection = res.filter((item) => item.checked).map((v) => v.policyNumber);
        updateSelection(initSelection);
    }
    useEffect(() => {
        initData()
    }, []);

    const renderProduct = (item, index, { path }) => {
        return getProductNameByLinePattern(item[path]);
    };

    const handleConfirm = async () => {
       onResolve(selection);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        accountNumber: {
            value: searchVal,
            onValueChange: (val) => updateSearchVal(val)
        },
        policyTable: {
            data: tableData,
            onSelectionChange: (rows) => updateSelection(rows),
            selectedRows: selection,
        }
    };

    const resolvers = {
        callbackMap: {
            renderProduct
        },
        componentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={messages.searchUnderlyingPolicies} />
            <ModalBody id="packagePolicy">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    {...resolvers}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} variant="secondary" label={commonMessages.cancelModel} />
                <Button onClick={() => handleConfirm(false)} label='Save' />
               
            </ModalFooter>
        </ModalNext>
    );
}

export default FetchExistingPoliciesPopup;
