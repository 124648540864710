import { defineMessages } from 'react-intl';

export default defineMessages({
    Type: {
        id: 'wni.quoteandbind.cp-wizard.cp-risk.Type',
        defaultMessage: 'Type',
    },
    Percentage: {
        id: 'wni.quoteandbind.cp-wizard.cp-risk.Percentage',
        defaultMessage: 'Percentage',
    },
    Delete: {
        id: 'wni.quoteandbind.cp-wizard.cp-risk.Delete',
        defaultMessage: 'Delete',
    },
    Add: {
        id: 'wni.quoteandbind.cp-wizard.cp-risk.Add',
        defaultMessage: 'Add',
    }
});