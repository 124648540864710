import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React from 'react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import { CALocationService } from 'wni-capability-quoteandbind-ca';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './TerritoryField.metadata.json5';
import TerritoryPopup from './TerritoryPopup';

function TerritoryField(props) {
    const modalApi = useWniModal();
    const {
        vm,
        basePath,
        addressPath,
        onValueChange,
        onValidate,
        showErrors,
        isReadOnly,
        className,
        extendProps = {},
    } = props;
    
    const territoryCodePath = basePath ? `${basePath}.territoryCode` : 'territoryCode';

    const {
        city,
        state,
        postalCode
    } = _.get(vm.value, addressPath, {});
    
    const {
        jobID,
        sessionUUID,
        authHeader,
        baseData: {
            baseState_Ext: baseState
        } = {}
    } = extendProps;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const searchTerritoryCode = async (requestData) => {
        setLoadingMask(true);
        const res = await CALocationService.searchTerritoryCode(jobID, sessionUUID, requestData, authHeader);
        setLoadingMask(false);
        return res || []
    };
    const autoFillTerritoryCode = async () => {
        const data = {
            city,
            postalCode,
            state: state || baseState
        }
        const requestData = _.omitBy(data, (val) => !val);
        const territoryData = await searchTerritoryCode(requestData);
        const territoryCode = _.get(territoryData[0], 'code', null);
        onValueChange(territoryCode, territoryCodePath);
    }

    const onSearch = async() => {
        const territoryData = await searchTerritoryCode({});
        const modalProps = {
            title: 'Territory Code Lookup',
            size: 'md',
            model: vm,
            data: territoryData,
            searchTerritoryCode,
            baseState: state || baseState,
            actionBtnLabel: commonMessages.ok,
            cancelBtnLabel: commonMessages.cancelModel,
        };

        modalApi.showModal(<TerritoryPopup {...modalProps} />).then((res) => {
            onValueChange(res.code, territoryCodePath);
        }).catch(() => _.noop);
    };
    
    
    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            readOnly: isReadOnly,
            className
        },
        autoFillTerritoryCode: {
            visible: !isReadOnly
        },
        territoryCode: {
            actionFn: onSearch,
            actionVisible: !isReadOnly
        }
        
    };
    const resolvers = {
        resolveCallbackMap: {
            autoFillTerritoryCode
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, vm, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vm}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default TerritoryField;
