import _ from 'lodash';
import { ViewModelUtil } from 'wni-portals-viewmodel-js';


function mapScheduleToDriver(driverVM, coverages) {
    const driver = ViewModelUtil.isViewModelNode(driverVM) ? driverVM.value : driverVM;
    _.forEach(coverages, (cov) => {
        const scheduleItems = _.get(cov, 'schedule.scheduleItems');
        if (!_.isEmpty(scheduleItems)) {
            const hasCov = _.find(scheduleItems, (item) => {
                const itemPublicId = _.get(item, 'namedInsured_Ext.publicID');
                if (itemPublicId === _.get(driver, 'contactDenormPublicId_Ext')) {
                    _.set(cov, 'scheduleVal', item.option_Ext);
                }
                return itemPublicId === _.get(driver, 'contactDenormPublicId_Ext');
            });
            if (!hasCov) {
                _.set(cov, 'selected', false);
            }
        }
    });
}

function buildUpdateCoveragesReuqest(changedPath, isSelectClause, driverVM) {
    let driver = {};
    if (ViewModelUtil.isViewModelNode(driverVM)) {
        driver = driverVM.value;
        driver.coverages = driverVM.coverages;
    } else {
        driver = driverVM;
    }
    const covPath = changedPath.split('.')[0];

    const scheduleItem = {
        namedInsured_Ext: {
            ...driver
        },
        '@deserialization-class': 'edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO'
    };

    if (_.endsWith(changedPath, 'scheduleVal')) {
        _.set(scheduleItem, 'option_Ext', _.get(driver, changedPath));
    }

    const scheduleItems = _.get(driver, `${covPath}.schedule.scheduleItems`);
    _.forEach(scheduleItems, (item) => {
        if (_.get(item, 'namedInsured_Ext.publicID') === _.get(driver, 'contactDenormPublicId_Ext')) {
            _.set(scheduleItem, 'publicId_Ext', item.publicId_Ext);
        }
    });

    const requestData = {
        driverDTO: driver,
        cov: _.get(driver, `${covPath}`),
        scheduleItem: scheduleItem
    };
    if (changedPath.search('selected') !== -1) {
        _.set(requestData, 'cov.updated', false);
    } else {
        _.set(requestData, 'cov.updated', true);
    }
    if (isSelectClause) {
        _.set(requestData, 'cov.updated', true);
    }
    if (changedPath.search('terms') !== -1) {
        const termPath = changedPath.split('.')[1];
        _.set(requestData, `cov.${termPath}.updated`, true);
    }

    return requestData;
}

function getClauseCheckboxTooltip(code, helpTextStaticData = {}, defaultTooltip) {
    const { helpTextFields, helpTextFieldValue } = helpTextStaticData;
    const tooltipValue = helpTextFields.indexOf(code) > -1
        ? helpTextFieldValue[code] : defaultTooltip;
    let retval = null;
    if (!_.isEmpty(tooltipValue)) {
        retval = {
            text: tooltipValue,
        };
    }
    return retval;
}

function addNewOptionValOfSchedule(clause) {
    if (clause.hasOwnProperty('scheduleOptions_Ext') && _.isArray(clause.scheduleOptions_Ext)) {
        const valInOptions = _.find(clause.scheduleOptions_Ext, (opt) => {
            return opt.id === clause.scheduleVal;
        });
        if (!valInOptions) {
            clause.scheduleOptions_Ext.push({ id: clause.scheduleVal, displayName: clause.scheduleVal });
        }
    }

    return clause;
}

function setScheduleVal(clonedModel, changedPath, value) {
    const basePath = changedPath.replace('.scheduleVal', '');
    const scheduleItems = _.get(clonedModel, `${basePath}.schedule.scheduleItems`);
    if (scheduleItems && scheduleItems.length > 0) {
        _.map(scheduleItems, (item) => {
            if (_.get(item, 'namedInsured_Ext.publicID') === clonedModel.contactDenormPublicId_Ext) {
                _.set(item, 'option_Ext', value);
                _.set('@deserialization-class', 'edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO');
            }
            return item;
        });
    }
    _.set(clonedModel, `${basePath}.schedule.updated_Ext`, true);
}

// for notImmediatelyUpdateCoverages
function setCovVal(changedPath, clonedModel, value) {
    const basePath = changedPath.replace('.selected', '');
    _.set(clonedModel, changedPath, value);
    const scheduleItems = _.get(clonedModel, `${basePath}.schedule.scheduleItems`) || [];
    _.set(clonedModel, `${basePath}.updated`, true);
    // if (_.isEmpty(scheduleItems) || (scheduleItems.length === 1 && !value)) {
    //     _.set(clonedModel, `${basePath}.updated`, true);
    // } else {
    //     _.set(clonedModel, `${basePath}.schedule.updated_Ext`, true);
    // }
    if (!_.isEmpty(scheduleItems) && (scheduleItems.length !== 1 || value)) {
        _.set(clonedModel, `${basePath}.schedule.updated_Ext`, true);
    }
    const updatedScheduleItem = _.filter(scheduleItems, (item) => {
        return item.publicID === clonedModel.contactDenormPublicId_Ext;
    });
    if (value) {
        if (_.isEmpty(updatedScheduleItem)) {
            scheduleItems.push({
                namedInsured_Ext: {
                    publicID: clonedModel.contactDenormPublicId_Ext
                },
                '@deserialization-class': 'edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO'
            });
        }
    } else {
        _.remove(scheduleItems, (item) => {
            const res = _.get(item, 'namedInsured_Ext.publicID') === clonedModel.contactDenormPublicId_Ext;
            return res;
        });
    }
    _.set(clonedModel, `${basePath}.schedule.scheduleItems`, scheduleItems);
}

function setClauseValue(changedPath, vm, value) {
    const clonedModel = _.cloneDeep(vm);

    if (_.endsWith(changedPath, '.selected')) {
        setCovVal(changedPath, clonedModel, value);
    } else if (_.endsWith(changedPath, '.scheduleVal')) {
        setScheduleVal(clonedModel, changedPath, value);
    } else if (_.endsWith(changedPath, '.directBooleanValue')) {
        const basePath = changedPath.replace('.directBooleanValue', '');
        _.set(clonedModel, `${basePath}.chosenTerm`, value);
        _.set(clonedModel, changedPath, value);
        _.set(clonedModel, `${basePath}.updated`, true);
    }

    return clonedModel;
}

function availableStates(state) {
    if (state === 'SD'
        || state === 'MN'
        || state === 'WI'
        || state === 'IA'
        || state === 'AK'
        || state === 'AZ') {
        return true;
    }
    return false;
}

function generateDriverCoverages(submissionVM, coverages) {
    const lineCoverages = _.get(coverages, 'lineCoverages');
    const baseState = _.get(submissionVM, 'value.baseData.baseState_Ext');
    const drivers = _.get(submissionVM, 'value.lobData.personalAuto.coverables.drivers');
    let isPAENORegVehUseIndCovAvailable = false;
    let isPAENORegVehUseResCovAvailable = false;
    let isPAENORegVehUseIndMedCovAvailable = false;
    let isPAENORegVehUseResMedCovAvailable = false;
    let PAENORegVehUseIndCovScheduleItems = {};
    let PAENORegVehUseResCovScheduleItems = {};
    let PAENOPubVehUseCovIndScheduleItems = {};
    let PAENOPubVehUseCovResScheduleItems = {};
    let PAENOPubVehUseCovIndMed = {};
    let PAENOPubVehUseCovResMed = {};
    _.each(lineCoverages, (lineCov) => {
        if (lineCov.code_Ext === 'PAENORegVehUseIndCov') {
            isPAENORegVehUseIndCovAvailable = lineCov.isPAENORegVehUseIndCovAvailable_Ext;
            PAENORegVehUseIndCovScheduleItems = _.get(lineCov, 'schedule.scheduleItems');
        }
        if (lineCov.code_Ext === 'PAENORegVehUseResCov') {
            isPAENORegVehUseResCovAvailable = lineCov.isPAENORegVehUseResCovAvailable_Ext;
            PAENORegVehUseResCovScheduleItems = _.get(lineCov, 'schedule.scheduleItems');
        }
        if (lineCov.code_Ext === 'PAENORegVehUseIndMedCov') {
            isPAENORegVehUseIndMedCovAvailable = lineCov.isPAENORegVehUseIndMedCovAvailable_Ext;
        }
        if (lineCov.code_Ext === 'PAENORegVehUseResMedCov') {
            isPAENORegVehUseResMedCovAvailable = lineCov.isPAENORegVehUseResMedCovAvailable_Ext;
        }
        if (lineCov.code_Ext === 'PAENOPubVehUseCovInd_EXT') {
            PAENOPubVehUseCovIndScheduleItems = _.get(lineCov, 'schedule.scheduleItems');
        }
        if (lineCov.code_Ext === 'PAENOPubVehUseCovRes_EXT') {
            PAENOPubVehUseCovResScheduleItems = _.get(lineCov, 'schedule.scheduleItems');
        }
        if (lineCov.code_Ext === 'PAENOPubVehUseCovIndMed_EXT') {
            PAENOPubVehUseCovIndMed = lineCov;
        }
        if (lineCov.code_Ext === 'PAENOPubVehUseCovResMed_EXT') {
            PAENOPubVehUseCovResMed = lineCov;
        }
    });

    _.map(drivers, (d) => {
        let coveragesCodeMap = {};
        const driverCoverages = [];
        const relationship = _.get(d, 'relationshipToInsured_Ext');
        if (availableStates(baseState)) {
            if (d.driverCovered_Ext
                && !d.additionalInsured_Ext
                && (relationship === 'Partner_Ext'
                || relationship === 'Employee_Ext'
                || relationship === 'Other_Ext')) {
                _.set(coveragesCodeMap, 'PAAddlHoResCov_Ext', true);
            }

            /*
            POI-18638
            Auto Death Benefit and Auto Disability Benefit Coverage
            only show when for State of SD and PNI or ANI or any covered driver which relationship is Spouse or Demostic Partner
            */
            if (baseState === 'SD'
                && (d.additionalInsured_Ext || d.isPNI_Ext
                    || (d.driverCovered_Ext && (relationship === 'Spouse_Ext' || relationship === 'Partner_Ext')))) {
                _.set(coveragesCodeMap, 'PADeathDisabilityCov', true);
                _.set(coveragesCodeMap, 'PADeathDisabilityCov_Ext', true);
            }

            /**
             * Coverage - [Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual[PAENORegVehUseIndCov]] - Availability Script
             * Coverage - [Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Residents[PAENORegVehUseResCov]] - Availability Script
             */
            if (d.additionalInsured_Ext || d.isPNI_Ext || relationship === 'Spouse_Ext') {
                if (isPAENORegVehUseIndCovAvailable) {
                    _.set(coveragesCodeMap, 'PAENORegVehUseIndCov', true);
                }
                if (isPAENORegVehUseResCovAvailable) {
                    _.set(coveragesCodeMap, 'PAENORegVehUseResCov', true);
                }
                if (isPAENORegVehUseIndMedCovAvailable) {
                    const scheduleItem = _.find(PAENORegVehUseIndCovScheduleItems, (item) => {
                        return d.contactDenormPublicId_Ext === item.namedInsured_Ext.publicID;
                    });
                    if (!_.isEmpty(scheduleItem)) {
                        _.set(coveragesCodeMap, 'PAENORegVehUseIndMedCov', true);
                    }
                }
                if (isPAENORegVehUseResMedCovAvailable) {
                    const scheduleItem = _.find(PAENORegVehUseResCovScheduleItems, (item) => {
                        return d.contactDenormPublicId_Ext === item.namedInsured_Ext.publicID;
                    });
                    if (!_.isEmpty(scheduleItem)) {
                        _.set(coveragesCodeMap, 'PAENORegVehUseResMedCov', true);
                    }
                }
            }

            /*
            * Can only be displayed on the portal after the PC is selected
            * Once unselected in the portal, it cannot be selected
            * */
            const PAENOPubVehUseCovIndScheduleItem = _.find(PAENOPubVehUseCovIndScheduleItems, (item) => {
                return d.contactDenormPublicId_Ext === item.namedInsured_Ext.publicID;
            });
            if (!_.isEmpty(PAENOPubVehUseCovIndScheduleItem)) {
                _.set(coveragesCodeMap, 'PAENOPubVehUseCovInd_EXT', true);
            }
            const PAENOPubVehUseCovResScheduleItem = _.find(PAENOPubVehUseCovResScheduleItems, (item) => {
                return d.contactDenormPublicId_Ext === item.namedInsured_Ext.publicID;
            });
            if (!_.isEmpty(PAENOPubVehUseCovResScheduleItem)) {
                _.set(coveragesCodeMap, 'PAENOPubVehUseCovRes_EXT', true);
            }
            if (PAENOPubVehUseCovIndMed.selected && !_.isEmpty(PAENOPubVehUseCovIndScheduleItem)) {
                _.set(coveragesCodeMap, 'PAENOPubVehUseCovIndMed_EXT', true);
            }
            if (PAENOPubVehUseCovResMed.selected && !_.isEmpty(PAENOPubVehUseCovResScheduleItem)) {
                _.set(coveragesCodeMap, 'PAENOPubVehUseCovResMed_EXT', true);
            }

            const policyType = _.get(submissionVM, 'value.lobData.personalAuto.offerings[0].policyType_Ext');
            // IsForNamedNonOwner
            if (policyType === 'NamedNonOwner') {
                coveragesCodeMap = {};
            }
            // POI-13857 Driver coverage should not offer to the excluded driver
            if (!d.driverCovered_Ext || !d.licensedDriver_Ext) {
                coveragesCodeMap = {};
            }
        }
        _.each(lineCoverages, (lineCov) => {
            const cov = _.cloneDeep(lineCov);
            if (_.get(coveragesCodeMap, cov.code_Ext)) {
                const scheduleItems = _.get(cov, 'schedule.scheduleItems');
                if (!_.isEmpty(scheduleItems)) {
                    const scheduleItem = _.find(scheduleItems, (item) => {
                        return d.contactDenormPublicId_Ext === item.namedInsured_Ext.publicID;
                    });
                    if (_.isEmpty(scheduleItem)) {
                        _.set(cov, 'selected', false);
                    } else {
                        _.set(cov, 'selected', true);
                    }
                }
                driverCoverages.push(cov);
            }
        });

        if (d.coverages && d.coverages.length === driverCoverages.length) {
            const hasInequalCov = _.filter(d.coverages, (cov, index) => {
                const newCov = driverCoverages[index];

                const scheduleItems = _.get(cov, 'schedule.scheduleItems') || [];
                const newScheduleItems = _.get(newCov, 'schedule.scheduleItems') || [];
                let hasInequalSchedule = [];
                if (scheduleItems.length === newScheduleItems.length) {
                    hasInequalSchedule = _.filter(scheduleItems, (item, idx) => {
                        return _.get(item, 'option_Ext') !== _.get(newScheduleItems[idx], 'option_Ext')
                            || _.get(item, 'namedInsured_Ext.publicID') !== _.get(newScheduleItems[idx], 'namedInsured_Ext.publicID');
                    });
                } else {
                    _.set(d, 'coverages', driverCoverages);
                }

                const covTerms = _.get(cov, 'terms') || [];
                const hasInequalTerms = _.filter(covTerms, (term, idx) => {
                    return _.get(term, 'chosenTerm') !== _.get(newCov, `terms[${idx}].chosenTerm`);
                });
                return cov.selected !== newCov.selected
                    || hasInequalTerms.length > 0
                    || hasInequalSchedule.length > 0;
            });

            if (hasInequalCov && hasInequalCov.length > 0) {
                _.set(d, 'coverages', driverCoverages);
            }
        } else {
            _.set(d, 'coverages', driverCoverages);
        }

        return d;
    });

    return drivers;
}

export default {
    mapScheduleToDriver,
    buildUpdateCoveragesReuqest,
    getClauseCheckboxTooltip,
    addNewOptionValOfSchedule,
    setClauseValue,
    generateDriverCoverages
};
