import customerConfig from 'customer-config-config';
import parametrizeWithCustomerConfig from './common.mjs';

const config = parametrizeWithCustomerConfig(customerConfig);

export const getNormalizedTransactionName = config.getNormalizedTransactionName;
export const getNormalizedLOBName = config.getNormalizedLOBName;
export const isValidTransaction = config.isValidTransaction;
export const isValidLob = config.isValidLob;
export const isCapabilityEnabled = config.isCapabilityEnabled;
export const filterCapabilityRoutes = config.filterCapabilityRoutes;
