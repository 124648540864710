import { defineMessages } from 'react-intl';

export default defineMessages({
    primaryNamedInsured: {
        id: 'custom.gateway.views.account-clearance.Primary Named Insured',
        defaultMessage: 'Primary Named Insured',
    },
    account: {
        id: 'custom.gateway.views.account-clearance.Account#',
        defaultMessage: 'Account#',
    },
    address: {
        id: 'custom.gateway.views.account-clearance.Address',
        defaultMessage: 'Address',
    },
    driverLicenseID: {
        id: 'custom.gateway.views.account-clearance.Driver LicenseID',
        defaultMessage: 'Driver LicenseID',
    },
    phone: {
        id: 'custom.gateway.views.account-clearance.Phone#',
        defaultMessage: 'Phone#',
    },
    matchingQuote: {
        id: 'custom.gateway.views.account-clearance.Matching Quote',
        defaultMessage: 'Matching Quote',
    },
    matchingPolicy: {
        id: 'custom.gateway.views.account-clearance.Matching Policy',
        defaultMessage: 'Matching Policy',
    },
    startNewQuote: {
        id: 'custom.gateway.views.account-clearance.Start New Quote',
        defaultMessage: 'Add Quote To This Account',
    },
    mergeAccount: {
        id: 'custom.gateway.views.account-clearance.Merge Account',
        defaultMessage: 'Merge Account',
    },
    merge: {
        id: 'custom.gateway.views.account-clearance.Merge',
        defaultMessage: 'Merge',
    },
    product: {
        id: 'custom.gateway.views.account-clearance.Product',
        defaultMessage: 'Product(s)',
    },
    accountHolder: {
        id: 'custom.gateway.views.account-clearance.Account Holder',
        defaultMessage: 'Account Holder'
    },
});
