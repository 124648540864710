/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
    CA7BusinessAutoEnhancement_Ext: {
        id: 'wni.custom.products.CA7.tooltip.For detailed information about the Business Auto Enhancement endorsement, please refer to the coverage forms links in the left navigation bar',
        defaultMessage: 'For detailed information about the Business Auto Enhancement endorsement, please refer to the coverage forms links in the left navigation bar'
    },
    CA7BusinessAutoEnEdgeEn_Ext: {
        id: 'wni.custom.products.CA7.tooltip.For detailed information about the Business Auto Enhancement Edge endorsement, please refer to the coverage forms links in the left navigation bar',
        defaultMessage: 'For detailed information about the Business Auto Enhancement Edge endorsement, please refer to the coverage forms links in the left navigation bar'
    }
})