import React, {
    // useContext,
    useCallback,
    // useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import {
    WCUnderwritingQuestionsService,
    // WCMultiQuoteService,
} from 'wni-capability-quoteandbind-wc';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import WizardPage from '../../templates/WCWizardPage';


import styles from './WCUnderwritingQuestionsPage.module.scss';
import metadata from './WCUnderwritingQuestionsPage.metadata.json5';
import messages from './WCUnderwritingQuestionsPage.messages';
import wcQuestionSetsUtil from './utils/WCQuestionSetsUtil';

const UNDERWRITING_QUESTION_PATH = 'lobData.workersComp.underwritingQuestionSets';

function WCUnderwritingQuestionsPage(props) {

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        saveUnderwritingQuestionsData,
        //
        // updateWizardPageData,
        //
        // onPreQuotePageNext,
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
    } = submissionVM.value;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const translator = useTranslator();

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        // registerComponentValidation
    } = useValidation('WCUnderwritingQuestionsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);

    
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );
    

    const onPageNext = useCallback(async () => {
        
        setLoadingMask(true);
        const wcUWQuestionsData = _.get(submissionVM.value, UNDERWRITING_QUESTION_PATH);
        
        const res = await saveUnderwritingQuestionsData(
            quoteID, 
            sessionUUID,
            wcUWQuestionsData,
            authHeader);
        setLoadingMask(false);

        const newUQVal = _.get(res, 'underwritingQuestionSets');
        _.set(submissionVM.value, UNDERWRITING_QUESTION_PATH, newUQVal);
        //
        _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        // submissionVM.value = res;
        updateSubmissionVM(submissionVM);
        
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
        
        // const retval = await onPreQuotePageNext();
        // return retval;
    }, [setLoadingMask, submissionVM, saveUnderwritingQuestionsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);


    const questionSetFilter = wcQuestionSetsUtil.getQSFilterForWCUWQuetions()
    const questionsetAnswers = _.get(submissionVM, `${UNDERWRITING_QUESTION_PATH}.value[0].answers`) || {};
    const questionSetMapper = wcQuestionSetsUtil.getQSMapperForWcUnderwritingQuestions(questionsetAnswers, [translator(messages.UnderwritingQuestionsValidationMessages)]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            policyUnderwritingQuestionSets: {
                contentFilter: questionSetFilter,
                contentMapper: questionSetMapper,
                onValidate
            }
        }
    }, [onValidate, questionSetFilter, questionSetMapper]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

WCUnderwritingQuestionsPage.propTypes = {
    ...WizardPage.propTypes,
    saveUnderwritingQuestionsData: PropTypes.func
};

WCUnderwritingQuestionsPage.defaultProps = {
    ...WizardPage.defaultProps,
    saveUnderwritingQuestionsData: WCUnderwritingQuestionsService.saveWCUnderwritingQuestionsData
} 
export default WCUnderwritingQuestionsPage;