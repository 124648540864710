import React from 'react';
import WizardPage from '../../templates/WCWizardPage';
import WCStateSpecificInformationPage from './WCStateSpecificInformationPage';

function WCStateSpecificInformationReadOnlyPage(props) {

    return (
        <WCStateSpecificInformationPage {...props} isReadOnly/>
    );
}

WCStateSpecificInformationReadOnlyPage.propTypes = WizardPage.propTypes;
export default WCStateSpecificInformationReadOnlyPage;