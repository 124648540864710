import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

// mode: default / editable
import CLPolicyHolderPage from './pages/PolicyHolder/CLPolicyHolderPage';
// mode: readOnly
import CLPolicyHolderReadOnlyPage from './pages/PolicyHolder/CLPolicyHolderPage.readonly';

import PackagePolicyPopup from './pages/PolicyHolder/PackagePolicyPopup/PackagePolicyPopup';

import ProductsSelectorPopup from './pages/ProductsSelectorPopup/ProductsSelectorPopup';

setComponentMapOverrides(
    {
        CLPolicyHolderPage: { component: 'CLPolicyHolderPage' },
        CLPolicyHolderReadOnlyPage: { component: 'CLPolicyHolderReadOnlyPage' },
        PackagePolicyPopup: { component: 'PackagePolicyPopup'},
        ProductsSelectorPopup: { component: 'ProductsSelectorPopup'} 
    },
    {
        CLPolicyHolderPage,
        CLPolicyHolderReadOnlyPage,
        PackagePolicyPopup,
        ProductsSelectorPopup
    }
);

export { default as CLPolicyHolder } from './pages/PolicyHolder/CLPolicyHolderPage';
export { default as NewSubmissionBlocked } from './pages/NewSummissionBlocked/NewSubmissionBlocked';
export { default as PackagePolicyPopup} from './pages/PolicyHolder/PackagePolicyPopup/PackagePolicyPopup';
export { default as ProductsSelectorPopup } from './pages/ProductsSelectorPopup/ProductsSelectorPopup';