import React, {useState, useCallback, useEffect} from 'react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMAAISCommonFormsService } from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext'
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMAAISCommonFormsPage.metadata.json5';

import WizardPage from '../../templates/IMWizardPage';
import CommonCoveragesCard from './component/CommonCoveragesCard';
import ConditionsAndExclusionsCard from './component/ConditionsAndExclusionsCard';
import AdditionalInsuredsCard from './component/AdditionalInsuredsCard';

const IMAAISCommonFormsReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM,
    } = props;

    const { authHeader } = useAuthentication();
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors] = useState(false)
    const [aaisCommonForms, setAAISCommonForms] = useState()

    const {
        jobID,
        sessionUUID,
        lobData: {
            inlandMarine: {
                coverables: {
                    stateSimpleInfos = {}
                }
            }
        }
    } = submissionVM.value

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');


    const initCommonForms = useCallback(async () => {
        setLoadingMask(true)
        const res = await IMAAISCommonFormsService.getAAISCommonForms(jobID, sessionUUID, authHeader)
        setAAISCommonForms(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCommonForms()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const onPageNext = async () => {
        return submissionVM
    }


    const accordionCommonProps = {
        commonForms: aaisCommonForms,
        setCommonForms: (newCommonForms) => {
            setAAISCommonForms(newCommonForms)
        },
        isEditing,
        setIsEditing,
        isEditable: false,
        showErrors,

        wizardData: submissionVM,
    }

    const overrideProps = {
        '@field': {
            isEditable: false,
        },
        CommonCoveragesCard: {
            ...accordionCommonProps
        },
        ConditionsAndExclusionsCard: {
            ...accordionCommonProps
        },
        AdditionalInsuredsCard: {
            ...accordionCommonProps
        },
        stateTable: {
            data: [stateSimpleInfos]
        },
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            CommonCoveragesCard: CommonCoveragesCard,
            ConditionsAndExclusionsCard: ConditionsAndExclusionsCard,
            AdditionalInsuredsCard: AdditionalInsuredsCard,
        },
    };


    return (
        <WizardPage
            onNext={onPageNext}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {aaisCommonForms && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default IMAAISCommonFormsReadOnlyPage
