import { defineMessages } from 'react-intl';

const WniPlatformMessages = defineMessages({
    OK: {
        id: 'custom.platform.flow.angularhelpers.OK',
        defaultMessage: 'OK'
    },
    wantToSaveAndExit: {
        id: 'custom.platform.flow.angularhelpers.CancelConfirmation.Are you sure you want to Save & Exit?',
        defaultMessage: 'Want to Save & Exit?'
    },
    Cancel: {
        id: 'custom.platform.flow.angularhelpers.Cancel',
        defaultMessage: 'Cancel'
    },
    exit: {
        id: 'custom.platform.flow.angularhelpers.Exit',
        defaultMessage: 'Exit'
    },
    saveAndExit: {
        id: 'custom.platform.flow.angularhelpers.Save & Exit',
        defaultMessage: 'Save & Exit'
    },
    No: {
        id: 'custom.platform.flow.angularhelpers.No',
        defaultMessage: 'No',
    }
});

export default WniPlatformMessages;

export const messages = WniPlatformMessages;
