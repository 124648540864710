import { defineMessages } from 'react-intl';

export default defineMessages({
    paAddAdditionalInterestContact: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Contact',
        defaultMessage: 'Contact',
    },
    paAddAdditionalInterestBankName: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Financial Institution',
        defaultMessage: 'Financial Institution',
    },
    paAddAdditionalInterestTrustName: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Trust Name',
        defaultMessage: 'Trust Name',
    },
    paAddAdditionalInterestCompanyType: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Company Type',
        defaultMessage: 'Company Type',
    },
    paAddAdditionalInterestLoanNumber: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Loan Number',
        defaultMessage: 'Loan Number',
    },
    paAddAdditionalInterestRelationshipToInsured: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Relationship to Insured',
        defaultMessage: 'Relationship to Insured',
    },
    paAddAdditionalInterestExplain: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Explain',
        defaultMessage: 'Explain',
    },
    additionalInterestCount: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.additionalInterestCount',
        defaultMessage: 'Additional Interest: { count }'
    },
    removeAdditionalInterest: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Remove Additional Interest.ext',
        defaultMessage: 'Remove Additional Interest?',
    },
    removeAdditionalInterestDesc: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.AddIntRemovalMsg.ext',
        defaultMessage: 'Are you sure you want to remove this additional interest from the list?',
    },
    addAdditionalInterest: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.addAdditionalInterest',
        defaultMessage: 'Add Additional Interest',
    },
    addAdditionalInterestType: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Type',
        defaultMessage: 'Type'
    },
    paAddAdditionalInterestTrust: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.Trust',
        defaultMessage: 'Trust'
    },
    editAdditionalInterest: {
        id: 'wni.quoteandbind.ho-wizard.HOCoverages.HOScheduleItemsComponent.editAdditionalInterest',
        defaultMessage: 'Edit Additional Interest',
    }
});
