import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import metadata from './GenLiabDetails.metadata.json5';
import {  generateFieldsVisible, isFieldVisible } from '../../../CUUnderlyingPage.util';

function GenLiabDetails(props) {
    const {
        currentRow,
        writeValue,
        onValidate,
        showErrors,
        isReadOnly,
    } = props;

    const translator = useTranslator();

    const getAvailableValues = (path) => {
        const options = _.get(currentRow,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        });
    };

    const isVisible = (path) => {
        return isFieldVisible(currentRow, path);
    };

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        glExpTypes: {
            availableValues: getAvailableValues('glExpType')
        },
        glOccLimit: {
            visible: isVisible('glOccLimit')
        },
        glAggLimit: {
            visible: isVisible('glAggLimit')
        },
        glProdCompOpsAggLim: {
            visible: isVisible('glProdCompOpsAggLim')
        },
        glPersAdvInj: {
            visible: isVisible('glPersAdvInj')
        },
        // owner and contractors
        glOwnAndConOccLimit: {
            visible: isVisible('glOwnAndConOccLimit')
        },
        glOwnAndConAggLimit: {
            visible: isVisible('glOwnAndConAggLimit')
        },
        // product withdrawal
        glProdWithAggLimit: {
            visible: isVisible('glProdWithAggLimit')
        },
        // railroad
        glRailraodOccLimit: {
            visible: isVisible('glRailraodOccLimit')
        },
        glRailroadAggLimit: {
            visible: isVisible('glRailroadAggLimit')
        },
        ...generateFieldsVisible(currentRow)
       
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default GenLiabDetails;
