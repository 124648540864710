import React from 'react';
import WizardPage from '../../templates/IMWizardPage';
import IMBuildingsAndLocationsPage from './IMBuildingsAndLocationsPage';

function IMBuildingsAndLocationsReadOnlyPage(props) {
    return (
        <IMBuildingsAndLocationsPage {...props} isReadOnly/>
    );
}

IMBuildingsAndLocationsReadOnlyPage.propTypes = WizardPage.propTypes;
export default IMBuildingsAndLocationsReadOnlyPage;
