import { defineMessages } from 'react-intl';

export default defineMessages({
    account: {
        id: 'wni.ho.billing.Account',
        defaultMessage: 'Account'
    },
    pageTitle: {
        id: 'wni.ho.billing.Billing',
        defaultMessage: 'Billing'
    },
    billAccount: {
        id: 'wni.ho.billing.Bill Account#',
        defaultMessage: 'Bill Account#'
    },
    policy: {
        id: 'wni.ho.billing.Policy',
        defaultMessage: 'Policy'
    },
    dueDay: {
        id: 'wni.ho.billing.Due Day',
        defaultMessage: 'Due Day '
    },
    paymentPlan: {
        id: 'wni.ho.billing.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    paymentMethod: {
        id: 'wni.ho.billing.Payment Method',
        defaultMessage: 'Payment Method'
    },
    primaryPayer: {
        id: 'wni.ho.billing.Primary Payer',
        defaultMessage: 'Primary Payer'
    },
    effectiveDate: {
        id: 'wni.ho.billing.Effective Date',
        defaultMessage: 'Effective Date'
    },
    editPaymentDetails: {
        id: 'wni.ho.billing.Edit Payment Details',
        defaultMessage: 'Edit Payment Details ({productName} {policyNumber} Eff. Date{effectiveDate})'   
    },
    dialogOk: {
        id: 'wni.ho.billing.OK',
        defaultMessage: 'OK'
    },
    dialogCancel: {
        id: 'wni.ho.billing.Cancel',
        defaultMessage: 'Cancel'
    },
    billingMethod: {
        id: 'wni.ho.billing.Billing Method',
        defaultMessage: 'Billing Method'
    }
});
