import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniPAQuote'), method, data, additionalHeaders);
}

export default class PAQuoteService {
    /**
     * Invokes PersonalAutoQuoteHandler.getDriverQuestionSet()
     * is disabled.
     * @param {string} quoteID current Quote ID
     * @param {string} sessionUUID current session ID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static getDriverQuestionSet(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getDriverQuestionSet', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes PersonalAutoQuotehandler.removeDriver()
     * @param {object} driverToRemoveDto DriverToRemoveDTO
     * @param {object} additionalHeaders the AuthHeader from AuthenticationContext
     * @returns {Promsie} the Promsie from the backend call
     */
    static removeDriver(driverToRemoveDto, additionalHeaders = {}) {
        return processSubmission('removeDriver', [driverToRemoveDto], additionalHeaders);
    }

    /**
     * Updates coverages.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateCoverages(quoteID, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateCoverages',
            [quoteID, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Updates coverages.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} copyFromVehiclePublicID copyFromVehicle publicID
     * @param {Object} copyToVehiclesPublicID copyToVehicle publicID array
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static copyVehicleCoverages(
        quoteID,
        sessionUUID,
        copyFromVehiclePublicID,
        copyToVehiclesPublicID,
        additionalHeaders = {}
    ) {
        return processSubmission(
            'copyVehicleCoverages',
            [quoteID, sessionUUID, copyFromVehiclePublicID, copyToVehiclesPublicID],
            additionalHeaders
        );
    }

    /**
     * Invokes PersonalAutoQuoteHandler.updatePolicyDetails()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} baseData QuoteBaseDataDTO from qdd.BaseData
     * @param {object} preQualQuestionSet QuestionSetAnswersDTO from qdd.LobData.PersonalAuto.PreQualQuestionSets[0]
     * @param {string} flowStepID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static updatePolicyDetails(quoteID, sessionUUID,
        baseData, preQualQuestionSet, flowStepID, authHeader = {}) {
        return processSubmission('updatePolicyDetails',
            [quoteID, sessionUUID, baseData, preQualQuestionSet, flowStepID],
            authHeader);
    }

    /**
     * Updates coverages.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} marketingMessage the marketingMessage to be saved
     * @param {Object} quotePriceViewMode quotePriceViewMode
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise}
     */
    static updateMarketingMessage(quoteID, sessionUUID, marketingMessage,
        quotePriceViewMode, additionalHeaders = {}) {
        return processSubmission('updateMarketingMessage',
            [quoteID, sessionUUID, marketingMessage, quotePriceViewMode],
            additionalHeaders);
    }

    /**
     * Invokes PersonalAutoQuoteHandler.updateDrivers()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {array} drivers DriverDTO[] from qdd.LobData.PersonalAuto.Coverables.Drivers
     * @param {string} flowStepID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static updateDrivers(quoteID, sessionUUID, drivers, flowStepID, authHeader = {}) {
        return processSubmission('updateDrivers',
            [quoteID, sessionUUID, drivers, flowStepID],
            authHeader);
    }

    /**
     * Invokes PersonalAutoQuoteHandler.retriveBindingData()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retriveBindingData(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('retriveBindingData',
            [quoteID, sessionUUID],
            authHeader);
    }

    /**
     * Invokes WniQuoteHandler.changePolicyType()
     * @param {object} data the WniChangePolicyTypeRequestDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static changePolicyType(data, authHeader = {}) {
        return processSubmission('changePolicyType', [data], authHeader);
    }

    /**
     * Invokes PersonalAutoQuoteHandler.updateVehicles()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} vehicles VehicleDTO[] from qdd.LobData.PersonalAuto.Coverables.Vehicles
     * @param {object} authHeader
     * @returns {Promise} the Promise from service call
     */
    static updateVehicles(quoteID, sessionUUID, vehicles, authHeader = {}) {
        return processSubmission('updateVehicles',
            // [quoteID, sessionUUID, vehicles],
            // Enclose VehicleDTO[] into a PaLobDataDTO to provide context "policyLine"
            [quoteID, sessionUUID, { coverables: { vehicles } }],
            authHeader);
    }


    /**
     * Invokes PersonalAutoQuoteHandler.updateVehicleDriverAssignment()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} vehicleDrivers VehicleDriverDTO[] from qdd.LobData.PersonalAuto.Coverables.VehicleDrivers
     * @param {object} authHeader
     * @returns {Promise}
     */
    static updateVehicleDriverAssignment(quoteID, sessionUUID, vehicleDrivers, authHeader = {}) {
        return processSubmission('updateVehicleDriverAssignment',
            [quoteID, sessionUUID, vehicleDrivers],
            authHeader);
    }

    static getPayInstruments(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getPayInstruments',
            [quoteID, sessionUUID],
            authHeader);
    }

    static getDueDate(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getDueDate',
            [quoteID, sessionUUID],
            authHeader);
    }

    static generateAutoPayEnrollment(quoteID, sessionUUID,
        bankABANumber,
        bankAccountNumber,
        bankName,
        bankAccountType,
        notesExt,
        publicID,
        authHeader = {}) {
        return processSubmission('generateAutoPayEnrollment',
            [quoteID, sessionUUID, bankABANumber,
                bankAccountNumber,
                bankName,
                bankAccountType,
                notesExt,
                publicID],
            authHeader);
    }

    static recalculatePaymentData(quoteID, sessionUUID, dayOfMonth, isAutoPay, authHeader = {}) {
        return processSubmission('recalculatePaymentData',
            [quoteID, sessionUUID, dayOfMonth, isAutoPay],
            authHeader);
    }

    static savePayInstrucmentService(quoteID, sessionUUID,
        bankABANumber,
        bankAccountNumber,
        bankName,
        bankAccountType,
        notesExt,
        publicID,
        authHeader = {}) {
        return processSubmission('savePayInstrucmentService',
            [quoteID, sessionUUID, bankABANumber,
                bankAccountNumber,
                bankName,
                bankAccountType,
                notesExt,
                publicID],
            authHeader);
    }
}
