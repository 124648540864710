
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';

import { GLQualificationReadOnlyPage } from 'wni-capability-quoteandbind-gl-react';

function GLQualificationChangeReadOnlyPage(props) {

    return (
        <GLQualificationReadOnlyPage {...props} />
    );

}

GLQualificationChangeReadOnlyPage.propTypes = GLQualificationReadOnlyPage.propTypes;
GLQualificationChangeReadOnlyPage.defaultProps = GLQualificationReadOnlyPage.defaultProps;

export default GLQualificationChangeReadOnlyPage;