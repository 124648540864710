import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCoverages: {
        id: 'gw-portals-bop-components-angular.coverages.additional.additionalCoverages.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    searchCoverages: {
        id: 'gw-portals-bop-components-angular.coverages.additional.additionalCoverages.Search coverages',
        defaultMessage: 'Search coverages'
    }
});
