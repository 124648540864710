import React from 'react';
import  { CALocationsReadOnlyPage } from 'wni-capability-quoteandbind-ca-react';

function WCLocationsReadOnlyPage(props) {

    return (
        <CALocationsReadOnlyPage 
            {...props}
            linePath = 'workersComp'
        />
    );
}

WCLocationsReadOnlyPage.propTypes = CALocationsReadOnlyPage.propTypes;
WCLocationsReadOnlyPage.defaultProps = CALocationsReadOnlyPage.defaultProps;
export default WCLocationsReadOnlyPage;