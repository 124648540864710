import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniPolicyService } from 'wni-capability-gateway';
import appConfig from 'app-config';
import { useWniModal } from 'wni-components-platform-react';
import PolicyAdvancedFilter from '../AdvancedFilter/PolicyAdvancedFilter';
import {advancedDefaultView ,currentViews, parseCase, generateAccountData, generateOpenJobData, generatePolicyData } from '../Policies.util';
import PolicyPageTable from '../PolicyPageTable/PolicyPageTable';
import metadata from './PolicyPageAdvanceFilter.metadata.json5';

const {
    lobEndorsementURL
} = appConfig;


// const isCurrentUserLicensedOrServicingAgent = (jobSummaryDTO) => {
//     const {
//         currentUserAgent_Ext: { isLicensedAgent, isServicingAgent },
//     } = jobSummaryDTO;
//     const isLicensedOrSericingAgent = isLicensedAgent || isServicingAgent;
//     return isLicensedOrSericingAgent;
// };

// const shouldShowPolicy = (policySummaryDTO, producerCodeParam) => {
//     let retval = policySummaryDTO.producerCodeOfService === producerCodeParam;
//     if (producerCodeParam === 'all') {
//         retval = true;
//     } else if (producerCodeParam === 'myTransactions') {
//         retval = isCurrentUserLicensedOrServicingAgent(policySummaryDTO);
//     }
//     return retval;
// };


const PolicyPageAdvanceFilter = (props) => {
    const {
        policyData = [],
        accountData,
        selectedProducerCode,
        isInitLoading,
        history,
        location: { state },
    } = props;

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();    

    const [currentView, updateCurrentView] = useState(advancedDefaultView);
    const [searchValue, updateSearchValue] = useState('');
    const [initCategoryName, updateInitCategoryName] = useState('');
    const [policyInitData, updatePolicyInitData] = useState(policyData);
    const [accountInitData, updateAccountInitData] = useState({});
    const [viewPolicyData, updateViewPolicyData] = useState([]);
    const [openJobData, updateOpenJobData] = useState([]);

    useEffect(() => {
        const newInitData = generateAccountData(accountData);
        updateAccountInitData(newInitData);
    }, [accountData]);

    useEffect(() => {
        if (!_.isEmpty(state)) {
            const { selectedTile, initStatus } = state;
            if (initStatus === 'Quotes') {
                updateInitCategoryName(initStatus);
            }
            const newView = selectedTile || advancedDefaultView;
            updateCurrentView(newView);
        } else {
            updateCurrentView(advancedDefaultView);
            updateSearchValue('');
        }
    }, [state]);

    const getOpenJobResponseData = useCallback((id, producerCodeParam) => {
        const tableDataList = accountInitData[`open${id}`] || [];
        const genNewData = generateOpenJobData(tableDataList)
        updateOpenJobData(genNewData);
    }, [accountInitData]);

    const getPolicyResponseData = useCallback(
        (id, initialData) => {
            const newData = initialData || policyInitData;
            const genNewData = generatePolicyData(newData);
            updateViewPolicyData(genNewData);
        },
        [policyInitData]
    );

    const triggerGetResponse = useCallback((
        id,
        producerCodeParam,
        initialData = undefined
    ) => {
        const checkTableDataType = currentViews.indexOf(parseCase(id)) >= 0;
        if (checkTableDataType) {
            getOpenJobResponseData(id, producerCodeParam);
        } else {
            getPolicyResponseData(id, initialData);
        }
    }, [getOpenJobResponseData, getPolicyResponseData]);

    useEffect(() => {
        triggerGetResponse(currentView, selectedProducerCode);
    }, [currentView, selectedProducerCode, policyInitData, accountInitData, triggerGetResponse]);

    const onChangeAdvanceFilter = (value) => {
        updateCurrentView(value.currentView);
        updateSearchValue('');
        if(currentViews.indexOf(parseCase(value.currentView)) >= 0) {
            const newInitData = {
                ...accountInitData,
                [`open${value.currentView}`]: value.data
            }
            updateAccountInitData(newInitData);
        } else {
            updatePolicyInitData(value.data);
        }
    };

    const searchJobService = useCallback(_.debounce(async (value) => {
        if (_.isEmpty(value)) {
            triggerGetResponse(currentView, 'all');
            return false;
        }
        let resultData;
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(value)) {
            const resultForNumber = await WniPolicyService.getJobSummaryFromJobNum(
                value, authHeader
            );
            resultData = _.isEmpty(resultForNumber) ? [] : [resultForNumber]
        } else {
            resultData = await WniPolicyService.getJobSummariesFromAccountName(
                value, authHeader
            );
        }
        let jobType;
        switch (currentView) {
            case 'Quotes':
                jobType = 'Submission';
                break;
            case 'Change':
                jobType = 'PolicyChange';
                break;
            default:
                jobType = currentView;
        }
        const filterResultData = resultData.filter((item) => item.type === jobType);
        const newInitData = {
            ...accountInitData,
            [`open${currentView}`]: filterResultData
        }
        updateAccountInitData(newInitData);
    }, 600), [currentView]);

    const searchPolicyService = useCallback(_.debounce(async (value) => {
        if (!value) {
            triggerGetResponse(currentView, 'all', policyInitData);
            return false;
        }
        let resultData;
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(value)) {
            const resultForNumber = await WniPolicyService.getPolicySummaryFromPolicyNumber(
                value, authHeader
            );
            resultData = _.isEmpty(resultForNumber) ? [] : [resultForNumber]
        } else {
            resultData = await WniPolicyService.getPolicySummariesFromAccountName(
                value, authHeader
            );
        }
        updatePolicyInitData(resultData || [])
    }, 600), [currentView]);

    const onSearchFn = (value, viewType) => {
        updateSearchValue(value);
        if (viewType === 'policy') {
            searchPolicyService(value);
        } else {
            searchJobService(value);
        }
    };

    if (isInitLoading) {
        return null;
    }

    //-------------------------------------------
    const overrideProps = {
        policyAdvancedFilter: {
            policies: policyData,
            accounts: accountData,
            clickedTile: parseCase(currentView),
            initCategoryName: initCategoryName,
            onValueChange: onChangeAdvanceFilter,
        },
        tableContainer: {
            currentView,
            policyData: viewPolicyData,
            jobData: openJobData,
            search: {
                visible: true,
                searchValue,
                onSearchFn
            },
            history
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            {},
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            // getLink,
            // renderPolicyAction,
            // sortString: DatatableUtil.sortString,
            // sortNumber: DatatableUtil.sortNumber,
            // sortCurrency: DatatableUtil.sortCurrency,
            // sortDate: DatatableUtil.sortDate,
        },
        resolveComponentMap: {
            advancedfilter: PolicyAdvancedFilter,
            tablecomponent: PolicyPageTable
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
        />
    );
};

export default PolicyPageAdvanceFilter;
