// only apply to PE, differnent state have differnt total range for agent
const agentStateTotalRangeMap = {
    'Alaska': {
        minimum: -0.25,
        maximum: 0.25
    }
}

export default {
    agentStateTotalRangeMap
}