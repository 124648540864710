import React from 'react'
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/legacy/components';

const AccordionCardTitle = (props) => {
    const { title, actionText, onClick } = props;
    const translator = useTranslator();
    return (
        <Flex justifyContent="between">
            <div>{translator(title)}</div>
            <Button icon="gw-add" onClick={(e) => {
                e.stopPropagation()
                onClick()
            }}>
                {translator(actionText)}
            </Button>
        </Flex>
    );
};

export default AccordionCardTitle