import { defineMessages } from 'react-intl';

export default defineMessages({
    InjuryTitle: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.Other Injuries',
        defaultMessage: "Other Injuries"
    },
    InjuryTitleWTAndRT: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.Third party Injuries',
        defaultMessage: "Third party Injuries"
    }
})