import React, { Component, Fragment, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import FilterOptions from 'gw-capability-gateway-react/Policy/AdvancedFilter/FilterOptions';
import styles from './PolicyAdvancedFilter.module.scss';
import messages from './PolicyLanding.messages';
import { RadioButtonField } from '@jutro/legacy/components';

function CategoryFilterCard(props) {

    const {
        selectedCategory,
        initCategoryName,
        policies,
        onValueChange,
        customFilter, countInformation
    } = props;


    const translator = useTranslator();

    const [advancedFilterSelectedCategory, updateAdvancedFilterSelectedCategory] = useState(undefined);

    const eventCallBack = useCallback((category) => {
        if (onValueChange) {
            onValueChange(category);
        }
    }, [onValueChange]);

    const onWriteValue = useCallback((value) => {
        updateAdvancedFilterSelectedCategory(value);
        eventCallBack(value);
    }, [updateAdvancedFilterSelectedCategory, eventCallBack]);

    useEffect(() => {
        updateAdvancedFilterSelectedCategory(selectedCategory);
    }, []);

    useEffect(() => {
        /**
         * Loading the Policies data before trigger the 'onWriteValue' function to the Quotes
         * category
         */
        if (initCategoryName === 'Quotes' && selectedCategory === 'Policies' && policies.length > 0) {
            onWriteValue(initCategoryName);
        }
    }, [selectedCategory, initCategoryName, onWriteValue, policies.length]);

    const hasFilterOptions = (policy, category) => {
        const NestedOptions = policy.filters.filter((items) => items.options.length > 0);
        if (policy.filters.length > 0 && (category === policy.displayValue
            && NestedOptions.length > 0)) {
            return true;
        }
        return false;
    };


    const onFilterValueChange = () => {
        eventCallBack(advancedFilterSelectedCategory);
    };

    const render = () => {
        const writePolicyInfo = (displayValue, displayKey) => {
            const displayName = `${translator(displayKey)} (${countInformation[displayValue]})`;
            return [{ code: displayValue, name: displayName }];
        };
        return (
            <div className="gw_filter_section">
                <div className="gw_filter_section_title">
                    {translator(messages.policylandingCategory)}
                </div>
                <div className="gw_filter_section_content">
                    {customFilter.length > 0 && customFilter.map((policy, index) => (
                        <Fragment key={`policyType${index}`}>
                            <RadioButtonField
                                id={`policyType${index}`}
                                value={advancedFilterSelectedCategory === policy.displayValue
                                && translator(policy.displayValue)}
                                availableValues={writePolicyInfo(policy.displayValue,
                                    policy.displayKey)}
                                showOptional={false}
                                onValueChange={onWriteValue}
                            />
                            {hasFilterOptions(policy, advancedFilterSelectedCategory)
                                && policy.filters.map((filter) => (
                                    <div className={styles.gw_filter_sub_option_container} key={`status${filter.displayKey}`}>
                                        <div className={styles.gw_sub_option_title}>
                                            {translator(filter.displayKey)}
                                        </div>
                                        <FilterOptions
                                            filters={filter}
                                            onValueChange={onFilterValueChange}
                                        />
                                    </div>
                                ))
                            }
                        </Fragment>
                    ))}
                </div>
            </div>
        );
    };

    //
    return render();
}

CategoryFilterCard.propTypes = {
    customFilter: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.shape({})]).isRequired,
    onValueChange: PropTypes.func.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    countInformation: PropTypes.shape({}).isRequired,
    initCategoryName: PropTypes.string.isRequired,
    policies: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.shape({})]).isRequired
};

export default CategoryFilterCard;
