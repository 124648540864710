import { defineMessages } from 'react-intl';

export default defineMessages({
    hoYourHome: {
        id: 'quoteandbind.ho.views.ho-your-home.Your Home',
        defaultMessage: 'Your Home',
    },
    replacementCost: {
        id: 'quoteandbind.ho.views.ho-your-home.Estimated value of Your Home',
        defaultMessage: 'Estimated Value of Your Home',
    },
    locationType: {
        id: 'quoteandbind.ho.views.ho-your-home.Location Type',
        defaultMessage: 'Location Type',
    },
    distanceToFireHydrant: {
        id: 'quoteandbind.ho.views.ho-your-home.Distance to Fire Hydrant',
        defaultMessage: 'Distance to Fire Hydrant',
    },
    within500ft: {
        id: 'quoteandbind.ho.views.ho-your-home.Within 500ft',
        defaultMessage: 'Within 500ft',
    },
    within300ftOfCommercialProperty: {
        id: 'quoteandbind.ho.views.ho-your-home.Within 300ft of commercial property',
        defaultMessage: 'Within 300ft of commercial property',
    },
    floodingOrFireHazard: {
        id: 'quoteandbind.ho.views.ho-your-home.Flooding or Fire Hazard',
        defaultMessage: 'Flooding or Fire Hazard',
    },
    residenceType: {
        id: 'quoteandbind.ho.views.ho-your-home.Residence Type',
        defaultMessage: 'Residence Type',
    },
    whoOccupiesYourHome: {
        id: 'quoteandbind.ho.views.ho-your-home.Your home is',
        defaultMessage: 'Specify who occupies the home',
    },
    homeType: {
        id: 'quoteandbind.ho.views.ho-your-home.You use your home as a',
        defaultMessage: 'Home Type',
    },
    over500ft: {
        id: 'quoteandbind.ho.views.ho-your-home.Over 500ft',
        defaultMessage: 'Over 500ft',
    },
    distanceToFireStation: {
        id: 'quoteandbind.ho.views.ho-your-home.Distance to Fire Station',
        defaultMessage: 'Distance to Fire Station'
    },
    within5Miles: {
        id: 'quoteandbind.ho.views.ho-your-home.Within 5 Miles',
        defaultMessage: 'Within 5 Miles'
    },
    over5Miles: {
        id: 'quoteandbind.ho.views.ho-your-home.Over 5 Miles',
        defaultMessage: 'Over 5 Miles',
    },
    additionalInterestForThisHome: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interest for This Home',
        defaultMessage: 'Additional Interest for This Home'
    },
    anotherAdditionalInterest: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Add Another Additional Interest?',
        defaultMessage: 'Add Another Additional Interest?'
    },
    additionalModalYes: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Yes',
        defaultMessage: 'Yes'
    },
    additionalModalNo: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.No',
        defaultMessage: 'No'
    },
    additionalInterestCount: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Additional Interest:',
        defaultMessage: 'Additional Interest: { count }'
    },
});
