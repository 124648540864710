import { defineMessages } from 'react-intl';

export default defineMessages({
    cppViewAndEditLabel: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-locations.View/Edit',
        defaultMessage: 'View/Edit',
    },
    cppViewLabel: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-locations.View',
        defaultMessage: 'View',
    },
    removeLocationTitle: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-locations.Remove location?',
        defaultMessage: 'Remove location?',
    },
    removeLocationDescription: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-locations.removelocationDescription',
        defaultMessage:
            'Are you sure you want to remove this location from the list of locations?',
    },
    Ok: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-locations.OK',
        defaultMessage: 'OK',
    },
    Cancel: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-locations.cppncel',
        defaultMessage: 'Cancel',
    },
});
