// Note: Looks like this package has been removed from common/ in the 11.3 upgrade
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import PaySuccessPage from './pages/Success/PaySuccessPage';


setComponentMapOverrides(
    {
        PaySuccessPage: { component: 'PaySuccessPage' }
    },
    {
        PaySuccessPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PASubmissionWizard } from './PASubmissionWizard';
export { default as PaySuccessPageByBCC } from './pages/Success/PaySuccessPageByBCC';
