import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentAmount: {
        id: 'wni.ho.billing.editBillingPopup.Payment Amount',
        defaultMessage: 'Payment Amount'
    },
    paymentMethod: {
        id: 'wni.ho.billing.editBillingPopup.Payment Method',
        defaultMessage: 'Payment Method'
    },
    dueDayOfMonth: {
        id: 'wni.ho.billing.editBillingPopup.Due Day of Month',
        defaultMessage: 'Due Day of Month'
    },
    paymentPlan: {
        id: 'wni.ho.billing.editBillingPopup.PaymentPlan',
        defaultMessage: 'Payment Plan'
    },
    primaryPayer: {
        id: 'wni.ho.billing.editBillingPopup.Primary Payer',
        defaultMessage: 'Primary Payer'
    },
    applyToOtherQuotes: {
        id: 'wni.ho.billing.editBillingPopup.Apply to other policies',
        defaultMessage: 'Apply to other policies: (Select all that apply)'
    },
    newPersonTitle: {
        id: 'wni.ho.billing.editBillingPopup.New Person Details',
        defaultMessage: 'New Person Details'
    },
    dialogOk: {
        id: 'wni.ho.billing.editBillingPopup.OK',
        defaultMessage: 'OK'
    },
    dialogCancel: {
        id: 'wni.ho.billing.editBillingPopup.Cancel',
        defaultMessage: 'Cancel'
    },
    addPaymentMethodTitle: {
        id: 'wni.ho.billing.editBillingPopup.Add Payment Method',
        defaultMessage: 'Add Payment Method',
    },
    billingMethod: {
        id: 'wni.ho.billing.editBillingPopup.Billing Method',
        defaultMessage: 'Billing Method'
    }
});
