import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { Icon } from '@jutro/components';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WindowUtil, IssuanceValidationUtil, QuoteUtil, ValidationIssueUtil } from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { RTVehicleService } from 'wni-capability-quoteandbind-rt';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    CustomFooterV2Component, AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from 'wni-capability-gateway-react';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import RTVehicleComponent from './VehicleComponent/RTVehicleComponent';
import WizardPage from '../../templates/RTWizardPage';
import metadata from './RTVehiclesPage.metadata.json5';
import messages from './RTVehiclesPage.messages';
import { OTHER } from './config/Vehicle.static';
import { syncCoverageAmountValueAndMsg, generateIssuanceValid } from './util/Vehicle.util';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};
const PATH = 'lobData.roadTrail.coverables.vehicles';
const CHILDREN_PATH = `${PATH}.children`;
// const DATA_PATH = `${PATH}.value`;
function RTVehiclesPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardPageData,
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        isReadOnly,
        currentStep,
        checkRequiredForIssuance
    } = props;
    const {
        jobID,
        sessionUUID,
        baseData,
        lobData: {
            roadTrail: {
                offerings
            }
        },
    } = submissionVM.value;
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        invalidFields
    } = useValidation('vehiclesPage');
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);
    const {
        selectedVersion_Ext: selectedVersionPublicID,
        source_Ext: source
    } = baseData;

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const  [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [isAddressFlag, updateAddressFlag] = useState(true);
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'vehiclesTable');
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [currentRow, invalidFields]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const updateSubmission = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allWatercrafts = _.get(submissionVM.value, PATH);
        const currentIndex = allWatercrafts.findIndex((item) => item.publicID === publicID); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const updateCurrentRow = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        const {
            _dtoName,
            _xCenter,
        } = rowData;
        const initCurrentRow = viewModelService.create(rowData.value, _xCenter, _dtoName);
        syncCoverageAmountValueAndMsg(initCurrentRow.value);
        if(_.get(initCurrentRow.value, 'manufacturer') === OTHER) {
            _.set(initCurrentRow.value, 'model', OTHER);
        }
        updateCurrentRowInteranl(initCurrentRow);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(initCurrentRow);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };
    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardData);
    };
    
    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };
    const vehicleService = useCallback(async(serviceName, serviceData, isAdd) => {
        setLoadingMask(true);
        const vehicleData = _.get(submissionVM, `${PATH}.value`);
        const oldVehicles = _.cloneDeep(vehicleData) || [];
        const oldVehicleIds = oldVehicles.map((v) => v.publicID);

        const res = await RTVehicleService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        _.set(submissionVM, `${PATH}.value`, res.rtvehicles);
        updateSelection([]);
        updateWizardSnapshot(submissionVM);
        updateShowErrors(false);
        // for add new watercraft
        if(isAdd) {
            // if find new vehicle, this is add new data
            const newVehicle = _.get(submissionVM, CHILDREN_PATH).find((vm) => !oldVehicleIds.includes(vm.value.publicID));
            updateCurrentRow(newVehicle);
            setLoadingMask(false);
            return false;
        }
        generateValidationIssues(res.errorsAndWarnings);
    
        updateCurrentRow(null);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM]);


    const addVehicle = async() => {
        vehicleService('updateVehicle', {}, 'add');
    };

    const cancelVehicle = () => {
        resetWizardDataToSnapshot();
        syncWizardData(null)
    }

    const updateLookupValidation = (validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    };

    const writeValue = (value, path) => {
        if (AddressChangeVerify(path, 'garagedAt_Ext')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateAddressFlag(false);
        }
        if(currentRow) {
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(currentRow, path, undefined);
            } else {
                _.set(currentRow, path, value);
            }
            syncWizardData(currentRow);
        }
    };

    const updateVehicle = useCallback(async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        // const isCensusBlockInfoMissing = currentRow.value.isCensusBlockInfoMissing_Ext;
        if (source !== 'Converted') {
            const addressUtil = AddressVerifiedUtil({
                authHeader,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                addressVM: _.get(currentRow, 'garagedAt_Ext'),
                addressPath: 'garagedAt_Ext',
                updateValidations: updateLookupValidation,
                writeValue: writeValue,
                //
                modalApi,
            });
            const verifiedObj = await addressUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }
        }
        await vehicleService('updateVehicle', currentRow.value);
        return true;
    }, [authHeader, currentRow, handleValidation, isAddressFlag, isComponentValid, modalApi, source,
        updateLookupValidation, vehicleService, updateAddressFlag]);

    const removeVehicle = () => {
        modalApi.showConfirm({
            title: messages.removeVehicleTitle,
            message: messages.removeVehicleDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            syncWizardData(null);
            vehicleService('removeVehicle', selection);
        })
    };
    const viewAndEdit = async(row, index) => {
        setLoadingMask(true);
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const vehicleVM = childrenVM.find((item) => item.value.publicID === index);
        const currentPublicID = _.get(vehicleVM.value, 'publicID');
        // if manufacturer and model has options, no need to call service again
        if(!_.get(vehicleVM.value, 'hasIntOptions') && !isReadOnly) {
            const res = await RTVehicleService.viewEditVehicle(currentPublicID, jobID, sessionUUID, authHeader)
            _.set(vehicleVM, 'value', res)
        }
        syncWizardData(vehicleVM)
        setLoadingMask(false);
    };
    const onNextVehicle = useCallback(() => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        let index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.publicID');
        syncWizardData(null);
        viewAndEdit(null, indexID)
        WindowUtil.scrollToTop()
    }, [currentRow, submissionVM]);

    const allVehicleValid = () => {
        const allVehicleVMs = _.get(submissionVM, CHILDREN_PATH);
        return allVehicleVMs.every((vm) => vm.aspects.valid && vm.aspects.subtreeValid);
    };
    const onPageNext = async () => {
        if(!allVehicleValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await RTVehicleService.onPageNext(requestData, authHeader);
        const lobCoveragePath = `lobData.roadTrail.offerings[${selectedVersionIndex}].coverages`;
        const newLobCoverage = _.get(res, `lobCoverages.roadTrail`);
        _.set(submissionVM.value, lobCoveragePath, newLobCoverage);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return submissionVM;
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.publicID === index) || {};
        let type;
        const isItemValid = _.get(itemVM, 'aspects.valid') && _.get(itemVM, 'aspects.subtreeValid');
        if(isItemValid) {
            const issuacneInValidField = generateIssuanceValid(itemVM);
            if(_.isEmpty(issuacneInValidField)) {
                type = 'success';
            } else {
                type = 'warning';
            }
        } else {
            type = 'error';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };
    const renderDropDownCell = (data, index, {path, typeKey}) => {
        const formatValue = data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-';
        return <span className='text-breakAll'>{formatValue}</span>
    };
    const renderVIN = (data, index, { path }) => {
        return data.vin || data[path] || '-'
    };
    const renderCell = (data, index, { path }) => {
        if(path === 'manufacturer' && data[path] === OTHER) {
            return data.otherManufacturer || '-'
        }
        if(path === 'model' && data[path] === OTHER) {
            return data.otherModel || '-'
        }
        return data[path] || '-'
    };
   
    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        addVehicle: {
            disabled: currentRow,
            visible: !isReadOnly
        },
        removeVehicle: {
            disabled: selection.length < 1,
            visible: !isReadOnly
        },
        vehiclesTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: isReadOnly ? 'none' : 'multi'
        },
        validationIcon: {
            renderCell: renderValidationCell
        },
        vehicleDetailContainer: {
            visible: currentRow != null,
        },
        viewAndEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        vehicleDetails: {
            visible: currentRow != null,
            value: currentRow,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            updateValidationIssues,
            generateValidationIssues,
            onValidate,
            showErrors,
            isReadOnly,
            isRequiredForIssuance,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
                baseData
            }
        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: _.get(submissionVM, `${PATH}.value`).length > 1
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            addVehicle,
            removeVehicle,
            viewAndEdit,
            cancelVehicle,
            saveVehicle: () => {
                updateVehicle().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextVehicle: () => {
                updateVehicle().then((valid) => {
                    if (valid) {
                        onNextVehicle();
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderDropDownCell,
            renderVIN,
            renderCell
        },
        resolveComponentMap: {
            vehiclecomponent: RTVehicleComponent
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            disableNext={!allVehicleValid()}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

RTVehiclesPage.propTypes = WizardPage.propTypes;
RTVehiclesPage.defaultProps = {
    checkRequiredForIssuance: true
}
export default RTVehiclesPage;
