import React, { useCallback, useContext, useEffect, useState, } from 'react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Loader } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { VehicleUtil, } from 'wni-portals-util-js';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import metadata from './PADriverAssignmentPage.metadata.json5';
import styles from './PADriverAssignmentPage.module.scss';

function PADriverAssignmentReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        policyChangeAutoAssignAndGetVehicleDriverAssignments,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const [showLoader, updateShowLoader] = useState(false);
    const [assignmentVMs, updateAssignmentVMs] = useState([]);


    const {
        onValidate,
        registerInitialComponentValidation,
    } = useValidation('DriverAssignmentPage');

    const {
        quoteID,
        jobID,
        sessionUUID,
        policyNumber,
        baseData: {
            effectiveDate,
            jobType,
            periodStatus
        },
        lobData: {
            personalAuto: {
                coverables: {
                    allVehiclesHavePrimaryDriver_Ext: isAssignmentValid
                }
            }
        }
    } = submissionVM.value;


    async function fetchData(updatingAssignment) {
        updateShowLoader(true);
        let response = { assignments: [] };
        switch (jobType) {
            case 'Submission': {
                response = await WniQuoteVehicleService.autoAssignAndGetVehicleDriverAssignments(quoteID, 
                    sessionUUID, updatingAssignment, authHeader);
                break;
            }
            case 'PolicyChange': {
                response = await policyChangeAutoAssignAndGetVehicleDriverAssignments(
                    policyNumber, effectiveDate, jobID, updatingAssignment, authHeader
                );
                break;
            }
            default: {
                // eslint-disable-next-line no-console
                console.log(`Unhandled job type: ${jobType}`);
            }
        }
        const vms = response.assignments.map((json) => viewModelService.create(json, 'pc',
            'edge.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleDriverDTO'));

        updateAssignmentVMs(vms);
        updateShowLoader(false);
    }

    useEffect(() => {
        fetchData(false);
    }, []);

    useEffect(() => {
        registerInitialComponentValidation(() => isAssignmentValid);
    }, [registerInitialComponentValidation, isAssignmentValid]);

    const visibleForRatedDriver = useCallback(() => {
        return periodStatus !== 'Draft';
    }, [periodStatus]);

    const getData = useCallback(() => {
        const vds = assignmentVMs ? assignmentVMs.map((vm) => vm.value) : [];
        return VehicleUtil.getAssignmentData(submissionVM, vds);
    }, [assignmentVMs]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            visible: false
        },
        driverAssDatatable: {
            data: getData()
        },
        ratedDriver: {
            visible: visibleForRatedDriver(),
        },
        editButton: {
            visible: false
        },
        paDriverAssignmentGuidelineComponent: {
            visible: false,
        },
        paDriverAssignmentComponent: {
            visible: false,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
        },
    };

    return (
        <WizardPage>
            {showLoader ? (<Loader showLoader />) : (
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            )}
        </WizardPage>
    );
}

PADriverAssignmentReadOnlyPage.propTypes = wizardProps;
export default PADriverAssignmentReadOnlyPage;
