import React, { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    WniPolicyService,
    WniDocumentRetrievalService,
    WniMultiDocumentRetrievalService,
} from 'wni-capability-gateway';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    AttachmentUtil,
    DocumentsUtil,
    EmailUtil,
    ServiceErrorUtil,
    WniProductsUtil,
} from 'wni-portals-util-js';
import documentMessage from 'wni-capability-gateway-react/components/DocumentsComponent/DocumentsComponent.messages';
import { gatewayMessages } from 'gw-capability-gateway-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    BaseDocumentCheckedComponentMessages,
    BaseDocumentSendViaEmailPopup,
} from 'wni-capability-common-react';
import DocumentListComponent from 'wni-capability-gateway-react/components/DocumentListComponent/DocumentListComponent';
import metadata from './PoliciesDocuments.metadata.json5';
import messages from './PoliciesDocuments.messages';

/** 
 * BR.PL.0254
 */ 
const BR_PL_0254_ERROR_MESSAGE = 'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.';
const BR_PL_0254_ERROR_CODE = -32603;

function PolicyDocuments(props) {
    const modalApi = useWniModal();
    const {
        uploadSuccess = false,
        fromAccountLanding: {
            policyDetailsData: {
                policyResponse: { policyNumber, product, account, issued },
                updateCountInfoDriectly,
            },
        },
        history
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        domainCompany: {code: domainCode },
        loadingMask: { setLoadingMask }
    } = useDependencies(['domainCompany', 'loadingMask']);

    const [initAttachments, updateInitAttachments] = useState([]);
    const [initPolicyDocuments, updateInitPolicyDocuments] = useState([]);

    const productCode = _.get(product, 'productCode');
    const isCL = WniProductsUtil.isCLProduct(productCode);
    const enableCommercialLine = _.get(authUserData, 'businessData_Ext.enableCommercialLine')

    const successCallback = useCallback(() => {
        return DocumentsUtil.defaultSuccessCallback(setLoadingMask);
    }, [setLoadingMask]);

    const errorCallback = useCallback(() => {
        return DocumentsUtil.defaultErrorCallback(
            modalApi,
            setLoadingMask,
            ServiceErrorUtil
        );
    }, [modalApi, setLoadingMask]);

    const printAllErrorCallBack = useCallback(
        (exception) => {
            return DocumentsUtil.defaultPrintAllErrorCallBack(
                exception,
                modalApi,
                setLoadingMask,
                ServiceErrorUtil
            );
        },
        [modalApi, setLoadingMask]
    );

    const handlePrintAllFn = useCallback(
        async (docs) => {
            await DocumentsUtil.handlePrintAllFn(
                docs,
                setLoadingMask,
                authHeader,
                history,
                WniDocumentRetrievalService,
                successCallback,
                errorCallback,
                WniMultiDocumentRetrievalService,
                printAllErrorCallBack
            );
        },
        [
            authHeader,
            errorCallback,
            history,
            printAllErrorCallBack,
            setLoadingMask,
            successCallback,
        ]
    );

    const handleSendViaEmailFn = useCallback(
        (documents) => {
            const validDocs = DocumentsUtil.filterValidDocuments(documents);
            const tempSubmission = {};
            _.set(
                tempSubmission,
                'policy.account.accountHolder',
                _.get(account, 'accountHolder')
            );
            _.set(tempSubmission, 'policy.issued', issued);
            _.set(tempSubmission, 'policyNumber_Ext', policyNumber);
            _.set(tempSubmission, 'jobNumber', policyNumber);
            const componentProps = {
                isOpen: true,
                documents: validDocs,
                submission: tempSubmission,
            };
            return EmailUtil.showSendViaEmailPopup(
                modalApi,
                componentProps,
                BaseDocumentSendViaEmailPopup,
                BaseDocumentCheckedComponentMessages
            );
        },
        [account, issued, modalApi, policyNumber]
    );

    const handleError = useCallback(
        (title, message) => {
            return modalApi
                .showAlert({
                    title: title,
                    message: message,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(() => {
                    _.noop();
                });
        },
        [modalApi]
    );

    const handleWarning = useCallback(
        (title, message) => {
            return modalApi
                .showAlert({
                    title: title,
                    message: message,
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(() => {
                    _.noop();
                });
        },
        [modalApi]
    );

    const retrieveInitPolicyDocAndAttachments = useCallback(async () => {
        try {
            setLoadingMask(true);
            const response = await WniPolicyService.getPortalDocAndAttachmentsForPolicy(
                      policyNumber,
                      enableCommercialLine,
                      authHeader
                  );
            const getAvailableDocumentsData = DocumentsUtil.updateDocumentUnselectable(response);
            updateInitPolicyDocuments(getAvailableDocumentsData);
            setLoadingMask(false);
        } catch (e) {
            setLoadingMask(false);
            handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    }, [authHeader, handleError, isCL, policyNumber]);

    const retrieveInitAttachments = useCallback(async () => {
        const attachments = await WniPolicyService.getAttachmentsForPolicy(
            policyNumber,
            isCL,
            authHeader
        );
        updateInitAttachments(attachments);
        return attachments;
    }, [policyNumber, isCL, authHeader]);

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID =
                await GatewayDocumentService.generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }, [authHeader, handleError]);

    useEffect(() => {
        async function retrieveInitDatas() {
            try {
                setLoadingMask(true);
                if (enableCommercialLine) {
                    await retrieveInitPolicyDocAndAttachments();
                } else {
                    await retrieveInitAttachments();
                }
            } finally {
                setLoadingMask(false);
            }
        }
        retrieveInitDatas();
    }, []);

    // useEffect(() => {
    //     updateCountInfoDriectly(
    //         'Documents',
    //         _.get(initPolicyDocuments, 'length')
    //     );
    // }, [initPolicyDocuments]);

    const uploadDocument = useCallback(
        async (file, vm) => {
            const documentMetaDataTemplate = {
                docUID: '001',
                documentType: _.get(vm, 'documentType.value.code'),
                description: _.get(vm, 'description.value'),
                securityType: 'unrestricted',
                status: 'approved',
                policyNumber: policyNumber,
                name: file.name,
                mimeType: file.type,
                sessionID: await claimsDocUploadToken(),
            };
            if (isCL) {
                _.set(documentMetaDataTemplate, 'status', 'final');
            }
            try {
                setLoadingMask(true);
                const res = await GatewayDocumentService.uploadDocument(file, documentMetaDataTemplate, authHeader);
                setLoadingMask(false);
                if (!_.isEmpty(res)) {
                    return isCL ? retrieveInitPolicyDocAndAttachments() : retrieveInitAttachments();
                }
            } catch (error) {
                // // BR.PL.0254
                // if (
                //     error &&
                //     _.get(error, 'baseError.message') ===
                //         'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.' &&
                //     _.get(error, 'baseError.code') === -32603
                // ) {
                //     const message =
                //         _.get(domainCompany, 'code') === 'UIC'
                //             ? messages.uploadFailedMessageUIC
                //             : messages.uploadFailedMessage;
                //     handleWarning(messages.warningUploadTitle, message);
                // } else {
                //     handleError(
                //         commonMessages.errorUploadTitle,
                //         commonMessages.uploadFailedMessage
                //     );
                // }
                setLoadingMask(false);
                if(error && _.get(error, 'baseError.message') ===  BR_PL_0254_ERROR_MESSAGE && _.get(error, 'baseError.code') === BR_PL_0254_ERROR_CODE) {
                    const message = messages[`uploadFailedMessage${domainCode}`] || messages.uploadFailedMessage;
                    return handleWarning(messages.warningUploadTitle, message);
                }
                return handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
            }
        },
        [authHeader, claimsDocUploadToken, domainCode, handleError, handleWarning, isCL, modalApi, policyNumber, retrieveInitAttachments, retrieveInitPolicyDocAndAttachments, setLoadingMask]
    );

    const overrideProps = {
        '@all': {},
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        documentList: {
            visible: isCL,
            initQuoteDocuments: initPolicyDocuments,
            showQuoteDetailsDocumentsCL: true,
            authHeader,
            viewModelService,
            breakpoint,
            setLoadingMask,
            listDocHeader: translator(messages.policyDocuments),
            getDateModifiedFn: isCL ? AttachmentUtil.getDateModifiedFn : _.noop,
            onDemandDocumentDownloadFn: async (item) => {
                await DocumentsUtil.onDemandDocumentDownloadFn(
                    item,
                    authHeader,
                    history,
                    modalApi,
                    setLoadingMask,
                    ServiceErrorUtil,
                    WniDocumentRetrievalService
                );
            },
            downloadPCDocument: (publicID, sessionID) => {
                DocumentsUtil.defaultDownloadDocument(
                    publicID,
                    sessionID,
                    setLoadingMask,
                    history,
                    authHeader
                );
            },
            handlePCPrintAllFn: handlePrintAllFn,
            handlePCSendViaEmailFn: handleSendViaEmailFn,
            overrideTitleStyle: isCL,
            uploadDocument: uploadDocument,
            productCode: product.productCode,
            documentNameColumnPath: 'simpleName_Ext',
            pageSizeConfig: { pageSize: 5 },
            pageSizeOptions: [5, 10, 20]
        },
        plDocumentList: {
            // For PL only
            visible: !isCL && enableCommercialLine,
            initQuoteDocuments: initPolicyDocuments,
            showQuoteDetailsDocumentsCL: true,
            authHeader,
            viewModelService,
            breakpoint,
            setLoadingMask,
            listDocHeader: translator(messages.policyDocuments),
            getDateModifiedFn: AttachmentUtil.getDateModifiedFn,
            onDemandDocumentDownloadFn: async (item) => {
                await DocumentsUtil.onDemandDocumentDownloadFn(
                    item,
                    authHeader,
                    history,
                    modalApi,
                    setLoadingMask,
                    ServiceErrorUtil,
                    WniDocumentRetrievalService
                );
            },
            downloadPCDocument: (publicID, sessionID) => {
                DocumentsUtil.defaultDownloadDocument(
                    publicID,
                    sessionID,
                    setLoadingMask,
                    history,
                    authHeader
                );
            },
            handlePCPrintAllFn: handlePrintAllFn,
            handlePCSendViaEmailFn: handleSendViaEmailFn,
            overrideTitleStyle: isCL,
            uploadDocument: uploadDocument,
            // product: product.productName,
            productCode: product.productCode,
            documentNameColumnPath: 'simpleName_Ext',
            pageSizeConfig: { pageSize: 5 },
            pageSizeOptions: [5, 10, 20]
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            documentListComponent: DocumentListComponent,
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(PolicyDocuments);
