
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import {
    WizardUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';

import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { WizardConstants } from 'wni-portals-config-js';
import { WniCommonChangeService } from 'wni-capability-policychange';
import { WALPolicyInfoChangeService } from 'wni-capability-policychange-wal';

import { WALPolicyDetailsPage } from 'wni-capability-quoteandbind-wal-react';

function WALPolicyDetailsChangePage(props) {

    const {
        authHeader,
        authUserData
    } = useAuthentication();

    const {
        wizardData: submissionVM,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        turnOffWizardPage,
        //
        clearVisitedStepsFromQuotePage,
    }  = props;

    const {
        jobID,
        sessionUUID,
        changeData: {
            isBillingOptionsEditable,
        }
    } = submissionVM.value;

    const {
        [WizardConstants.landingPage]: landingPage,
        // [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const policyChangeSource = wizardPageData[WizardConstants.policyChangeSource];

    const pageOnInit = useCallback(() => {
        if (landingPage) {
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'WALPolicyDetailsPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            clearVisitedStepsFromQuotePage();
        }
    }, []);

    useEffect(() => {
        pageOnInit();

        // if (!isBillingOptionsEditable) {
        //     turnOffWizardPage('WALPaymentPage');
        // }
    }, []);

    const saveWALPolicyInfoData = useCallback(async (...params) => {
        const retval = await WALPolicyInfoChangeService.saveWALPolicyInfoData(...params);
        // Update ActiveQuotes as well, since PolicyPeriod publicID could be updated due to Date change

        const newActiveQuotes = await WniCommonChangeService.getActiveQuotes(jobID, sessionUUID, authHeader);
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: newActiveQuotes,
        });

        return retval;
    }, [authHeader, jobID, sessionUUID, updateWizardPageData]);

    const maximumEffectiveDate = _.get(submissionVM,'value.changeData.maximumEffectiveDate')

    return (
        <WALPolicyDetailsPage {...props}
            savePolicyInfoData = {saveWALPolicyInfoData}
            // saveWALPolicyInfoData = {saveWALPolicyInfoData}
            isOpenPNIPopupPageInit = {policyChangeSource === 'cancellation'}
            maximumEffectiveDate = {maximumEffectiveDate}
            readOnlyFields = {
                {
                    // effectiveDate: true,
                    walTermTypedropdownselect: true,
                    walBaseState: true,
                    agencyOfRecord: true,
                    uwqReadonly: true
                }
            }
            setEffectiveDateForChangeTransaction
        />
    );

}

WALPolicyDetailsChangePage.propTypes = WALPolicyDetailsPage.propTypes;
WALPolicyDetailsChangePage.defaultProps = WALPolicyDetailsPage.defaultProps;

export default WALPolicyDetailsChangePage;