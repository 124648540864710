import _ from 'lodash';

function updateTrustDefaultByProductCode(productCode, newVM) {
  if (productCode === 'homeowner' || productCode === 'dwellingProperty') {
    _.set(newVM, 'value.type', 'TRUST_Ext');
  }
  // else if (productCode === 'dwellingProperty') {
  //   _.set(newVM, 'value.type', 'AddInsuredDP_Ext');
  // }
  return newVM
}

export default {
  updateTrustDefaultByProductCode
};
