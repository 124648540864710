import { defineMessages } from 'react-intl';

export default defineMessages({
    claimInformation: {
        id: 'fnol.common.views.fnol.Claim Information',
        defaultMessage: 'Claim Information'
    },
    selectPolcicy: {
        id: 'fnol.wizard.steps.Select Policy',
        defaultMessage: 'Select Policy'
    },
    additionalInformation: {
        id: 'fnol.wizard.steps.Additional Information',
        defaultMessage: 'Additional Information'
    },
    searchCriteria: {
        id: 'fnol.common.views.policy-select-policyholder.No policies were found that match the search criteria.',
        defaultMessage: 'No policies were found that match the search criteria.',
    },
    selectPolicy: {
        id: 'fnol.common.views.policy-select-policyholder.Select A Policy',
        defaultMessage: 'Select A Policy',
    },
    lossInformation: {
        id: 'fnol.common.views.policy-select-policyholder.Loss Information',
        defaultMessage: 'Loss Information {policyNumber}'
    },
    searchingPolicies: {
        id: 'fnol.common.views.policy-select-policyholder.Searching for your policies...',
        defaultMessage: 'Searching for your policies...'
    },
    policyNotFoundMessage: {
        id: 'fnol.common.views.policy-select-producer.No policies were found that match the search criteria.',
        defaultMessage: 'No policies were found that match the search criteria.'
    },
    whenLossOccur: {
        id: 'fnol.common.directives.loss-date-input.When did loss occur?',
        defaultMessage: 'When did loss occur?'
    },
    fnolSummaryHeader: {
        id: 'fnol.wizard.step.Summary',
        defaultMessage: 'Summary'
    },
    fnolReviewInformation: {
        id: 'fnol.common.views.summary.Please review the following information before submitting:',
        defaultMessage: 'Please review the following information before submitting:'
    },
    fnolPolicyNumber: {
        id: 'fnol.common.views.summary.Policy Number',
        defaultMessage: 'Policy Number'
    },
    fnolTypeOfIncident: {
        id: 'fnol.common.views.summary.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    fnolSummaryWhen: {
        id: 'fnol.common.views.summary.When',
        defaultMessage: 'When'
    },
    fnolSummaryWhere: {
        id: 'fnol.common.views.summary.Where',
        defaultMessage: 'Where'
    },
    fnolSummaryContactPerson: {
        id: 'fnol.common.views.summary.Contact Person',
        defaultMessage: 'Contact Person'
    },
    fnolRepairFacility: {
        id: 'fnol.common.views.summary.Repair Facility',
        defaultMessage: 'Repair Facility'
    },
    fnolNoRepairRequested: {
        id: 'fnol.common.views.summary.No repair requested',
        defaultMessage: 'No repair requested'
    },
    fnolNoteToAdjuter: {
        id: 'fnol.common.views.summary.Note to Adjuster',
        defaultMessage: 'Note to Adjuster'
    },
    fnolEdit: {
        id: 'fnol.common.views.summary.Edit',
        defaultMessage: 'Edit'
    },
    fnolDelete: {
        id: 'fnol.common.views.summary.Delete',
        defaultMessage: 'Delete'
    },
    fnolAddNoteToAdjuster: {
        id: 'fnol.common.views.summary.Add a Note to the Adjuster',
        defaultMessage: 'Add a Note to the Adjuster'
    },
    fnolSummaryPara: {
        id: 'fnol.common.views.summary.This represents where a carrier might put the text surrounding their Fraud statement. This text is illustrative and should be updated accordingly.',
        defaultMessage: 'This represents where a carrier might put the text surrounding their Fraud statement. This text is illustrative and should be updated accordingly.'
    },
    dialogAddNoteToAdjuster: {
        id: 'fnol.common.views.note-dialog.Add a Note to the Adjuster',
        defaultMessage: 'Add a Note to the Adjuster'
    },
    dialogSubject: {
        id: 'fnol.common.views.note-dialog.Subject',
        defaultMessage: 'Subject'
    },
    dialogNote: {
        id: 'fnol.common.views.note-dialog.Note',
        defaultMessage: 'Note'
    },
    dialogCancel: {
        id: 'fnol.common.views.note-dialog.Cancel',
        defaultMessage: 'Cancel'
    },
    dialogOk: {
        id: 'fnol.common.views.note-dialog.OK',
        defaultMessage: 'Ok'
    },
    primaryContactDetails: {
        id: 'fnol.common.views.main-contact.Primary Contact Details',
        defaultMessage: 'Primary Contact Details'
    },
    verifyContactInformation: {
        id: 'fnol.common.views.main-contact.Verify the contact information with the client. They may have changed address recently or would prefer to use another address for claim correspondence.',
        defaultMessage: 'Verify the contact information with the client. They may have changed address recently or would prefer to use another address for claim correspondence.'
    },
    contactDetailsWizardStep: {
        id: 'fnol.wizard.step.Contact Details',
        defaultMessage: 'Contact Details'
    },
    commonContactHeader: {
        id: 'fnol.common.views.main-contact.Contact',
        defaultMessage: 'Contact'
    },
    commonContactEmailHeader: {
        id: 'fnol.common.views.main-contact.Email',
        defaultMessage: 'Email'
    },
    CLPrimaryContactHeader: {
        id: 'fnol.common.views.contact-details.Primary Contact',
        defaultMessage: 'Primary Insured Contact'
    },
    GLCPPrimaryContactHeader: {
        id: 'fnol.common.views.contact-details.Policyholder Contact',
        defaultMessage: 'Policyholder Contact'
    },
    PLPrimaryContactHeader: {
        id: 'fnol.common.views.contact-details.Primary Insured Contact',
        defaultMessage: 'Primary Insured Contact'
    },
    wizardTitleAdditionalInformation: {
        id: 'fnol.wizard.step.Additional Information',
        defaultMessage: 'Additional Information'
    },
    fnolSupportingInformation: {
        id: 'fnol.common.views.more-info.Supporting Information',
        defaultMessage: 'Supporting Information'
    },
    fnolAddMoreInfo: {
        id: 'fnol.common.views.more-info.Add photos or documents that are relevant to the incident and may help with the claim.',
        defaultMessage: 'Add photos or documents that are relevant to the incident and may help with the claim.'
    },
    fnolWitnessesOtherParties: {
        id: 'fnol.common.views.more-info.Witnesses or Other Parties',
        defaultMessage: 'Witnesses or Other Parties'
    },
    fnolAddPerson: {
        id: 'fnol.common.views.more-info.Add Person',
        defaultMessage: 'Add Person'
    },
    fnolAnotherPerson: {
        id: 'fnol.common.views.more-info.Another person',
        defaultMessage: 'Another person'
    },
    fnolMetropolitanReports: {
        id: 'fnol.common.views.more-info.Add Law Enforcement of Responding Agency Officer',
        defaultMessage: 'Add Law Enforcement of Responding Agency Officer'
    },
    fnolPolicyReports: {
        id: 'fnol.common.views.more-info.Add Policy Reports',
        defaultMessage: 'Add Police Reports'
    },
    fnolName: {
        id: 'fnol.common.views.more-info.Name',
        defaultMessage: 'Name'
    },
    fnoReportNo: {
        id: 'fnol.common.views.more-info.Report No',
        defaultMessage: 'Report No'
    },
    fnolFirstName: {
        id: 'fnol.common.views.more-info.First name',
        defaultMessage: 'First Name'
    },
    fnolLastName: {
        id: 'fnol.common.views.more-info.Last name',
        defaultMessage: 'Last Name'
    },
    fnolInvolvement: {
        id: 'fnol.common.views.more-info.Involvement',
        defaultMessage: 'Involvement'
    },
    fnolPhoneNumber: {
        id: 'fnol.common.views.more-info.Phone number',
        defaultMessage: 'Phone number'
    },
    fnolInjured: {
        id: 'fnol.common.views.more-info.Injured',
        defaultMessage: 'Injured'
    },
    fnolRemoveUploadedDocument: {
        id: 'platform.widgets.fileupload.templates.Are you sure you want to remove:',
        defaultMessage: 'Are you sure you want to remove: {documentName}?'
    },
    fnolRemove: {
        id: 'fnol.common.views.more-info.Remove',
        defaultMessage: 'Remove'
    },
    fnolYes: {
        id: 'fnol.common.controllers.MoreInfoCtrl.Yes',
        defaultMessage: 'Yes'
    },
    fnolNo: {
        id: 'fnol.common.controllers.MoreInfoCtrl.No',
        defaultMessage: 'No'
    },
    otherPerson: {
        id: 'fnol.common.controllers.MainContactCtrl.Other Person',
        defaultMessage: 'Other Person'
    },
    newContact: {
        id: 'fnol.common.controllers.MainContactCtrl.New Contact',
        defaultMessage: 'New Contact'
    },
    fnolDetailedPolicyNumber: {
        id: 'fnol.common.directives.policy-search-result-detailed.Policy Number',
        defaultMessage: 'Policy Number'
    },
    fnolDetailedInsured: {
        id: 'fnol.common.directives.policy-search-result-detailed.Insured',
        defaultMessage: 'Insured'
    },
    fnolDetailedAddress: {
        id: 'fnol.common.directives.policy-search-result-detailed.Address',
        defaultMessage: 'Address'
    },
    fnolDetailedType: {
        id: 'fnol.common.directives.policy-search-result-detailed.Type',
        defaultMessage: 'Type'
    },
    fnolDetailedEffective: {
        id: 'fnol.common.directives.policy-search-result-detailed.Effective',
        defaultMessage: 'Effective'
    },
    fnolDetailedExpires: {
        id: 'fnol.common.directives.policy-search-result-detailed.Expires',
        defaultMessage: 'Expires'
    },
    successfullySubmitted: {
        id: 'fnol.common.views.confirmation.Claim was successfully submitted',
        defaultMessage: 'Claim was successfully submitted'
    },
    confirmationThankMessage: {
        id: 'fnol.common.views.confirmation.Thank you for submitting a claim. Our claim team will be in contact shortly.',
        defaultMessage: 'Thank you for submitting a claim. Our claim team will contact you within the next business day.'
    },
    referenceNumber: {
        id: 'fnol.common.views.confirmation.Claim Reference number',
        defaultMessage: 'Claim reference number { num }'
    },
    claimNumber: {
        id: 'fnol.common.views.confirmation.Claim number',
        defaultMessage: 'Claim number { num }'
    },
    foundationInsurance: {
        id: 'fnol.common.views.confirmation.Kind regards, Foundation Insurance',
        defaultMessage: 'Kind regards, Foundation Insurance'
    },
    printConfirmation: {
        id: 'fnol.common.views.confirmation.Print Confirmation',
        defaultMessage: 'Print Confirmation'
    },
    fnolSaveandExit: {
        id: 'fnol.wizard.Save and Exit',
        defaultMessage: 'Save and Exit'
    },
    fnolSubmitClaim: {
        id: 'fnol.common.views.summary.Submit My Claim Now',
        defaultMessage: 'Submit Claim'
    },
    fnolSubmitClaimShort: {
        id: 'fnol.common.views.summary.Submit',
        defaultMessage: 'Submit'
    },
    fnolNoteErrorHead: {
        id: 'fnol.common.controllers.SummaryCtrl.Note Error Head',
        defaultMessage: 'Note submission failed'
    },
    fnolNoteErrorMessage: {
        id: 'fnol.common.controllers.SummaryCtrl.Note Error Message',
        defaultMessage: 'Note cannot be processed at this time. '
    },
    fnolSubmitClaimDetails: {
        id: 'fnol.common.config.FlowConfig.Submitting claim details...',
        defaultMessage: 'Submitting claim details...'
    },
    fnolErrorSubmitClaimTitle: {
        id: 'fnol.common.config.FlowConfig.Error submitting claim',
        defaultMessage: 'Error submitting claim'
    },
    fnolErrorSubmitClaimMessage: {
        id: 'fnol.common.config.FlowConfig.An error occurred attempting to submit the claim.',
        defaultMessage: 'An error occurred attempting to submit the claim.'
    },
    fnolSaveDraftErrorTitle: {
        id: 'fnol.common.config.FlowConfig.Unable to save draft claim information',
        defaultMessage: 'Unable to save draft claim information'
    },
    fnolSaveDraftErrorMessage: {
        id: 'fnol.common.config.FlowConfig.An error occurred while attempting to save the claim submission.',
        defaultMessage: 'An error occurred while attempting to save the claim submission.'
    },
    fnolSaveDraftClaim: {
        id: 'fnol.common.config.FlowConfig.Saving draft claim details...',
        defaultMessage: 'Saving draft claim details...'
    },
    generalCreateDraftErrorTitle: {
        id: 'fnol.general.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    generalCreateDraftErrorMessage: {
        id: 'fnol.general.config.FlowConfig.An error occurred attempting to create a claim.',
        defaultMessage: 'An error occurred attempting to create a claim.'
    },
    generalCreateDraftClaim: {
        id: 'fnol.general.config.FlowConfig.Creating draft claim...',
        defaultMessage: 'Creating draft claim...'
    },
    wizardTypeOfIncident: {
        id: 'fnol.wizard.step.general.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    generalWizardTypeOfIncident: {
        id: 'fnol.wizard.step.general.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    wizardLossType: {
        id: 'fnol.wizard.step.general.Loss Type',
        defaultMessage: 'Loss Type'
    },
    wizardTitleWhere: {
        id: 'fnol.wizard.step.general.Where',
        defaultMessage: 'Where'
    },
    generalWasAnyoneInjured: {
        id: 'fnol.general.views.what.Was anyone injured?',
        defaultMessage: 'Was anyone injured?'
    },
    generalAddAnInjured: {
        id: 'fnol.general.views.what.Add an injured...',
        defaultMessage: 'Add an injured...'
    },
    generalFirstName: {
        id: 'fnol.general.views.what.First Name',
        defaultMessage: 'First Name'
    },
    generalLastName: {
        id: 'fnol.general.views.what.Last Name',
        defaultMessage: 'Last Name'
    },
    generalFirstNameKanji: {
        id: 'fnol.general.views.what.First Name Kanji',
        defaultMessage: 'First Name Kanji'
    },
    generalLastNameKanji: {
        id: 'fnol.general.views.what.Last Name Kanji',
        defaultMessage: 'Last Name Kanji'
    },
    generalInjuryDescription: {
        id: 'fnol.general.views.what.Description',
        defaultMessage: 'Description'
    },
    generalInjured: {
        id: 'fnol.general.views.what.Injured',
        defaultMessage: 'Injured #{injuredIndex}'
    },
    generalRemoveInjuredPopupTitle: {
        id: 'fnol.general.views.what.modal.Remove Injured',
        defaultMessage: 'Remove Injured'
    },
    generalRemoveInjuredPopupMessage: {
        id: 'fnol.general.views.what.modal.Are you sure you want to remove Injured #{injuredIndex}?',
        defaultMessage: 'Are you sure you want to remove Injured #{injuredIndex}?',
    },
    generalRemoveInjuredPopupRemoveButton: {
        id: 'fnol.general.views.what.modal.Remove',
        defaultMessage: 'Remove'
    },
    generalDescribeWhatHappened: {
        id: 'fnol.general.views.what.Describe what happened',
        defaultMessage: 'Describe what happened'
    },
    generalLossCause: {
        id: 'fnol.general.views.what.Loss Cause',
        defaultMessage: 'Loss Cause'
    },
    generalSelectLossCause: {
        id: 'fnol.general.views.what.Select Loss Cause',
        defaultMessage: 'Select Loss Cause'
    },
    generalIndicateWhoOrWhat: {
        id: 'fnol.general.views.what.Indicate who or what caused the incident, and provide other details that would be helpful.',
        defaultMessage: 'Indicate who or what caused the incident, and provide other details that would be helpful.'
    },
    generalDescribeDamage: {
        id: 'fnol.general.views.what.Describe damage',
        defaultMessage: 'Describe damage'
    },
    generalListWhatWasDamaged: {
        id: 'fnol.general.views.what.List what was damaged or lost during the incident, such as structural damage or office equipment.',
        defaultMessage: 'List what was damaged or lost during the incident, such as structural damage or office equipment.'
    },
    generalDescribeInjuries: {
        id: 'fnol.general.views.what.Describe injuries if any',
        defaultMessage: 'Describe injuries if any'
    },
    generalTypeOfIncidentDelete: {
        id: 'fnol.wizard.tooltip.Delete',
        defaultMessage: 'Delete'
    },
    cancelModalMessage: {
        id: 'fnol.common.config.FlowConfig.The information you entered will not be saved.',
        defaultMessage: 'The information you entered will not be saved.'
    },
    saveAndExitModalTitle: {
        id: 'fnol.common.config.FlowConfig.Save and Exit?',
        defaultMessage: 'Save and Exit?'
    },
    saveAndExitModalMessage: {
        id: 'fnol.common.config.FlowConfig.We will save the information you have entered up to this point as a draft claim.',
        defaultMessage: 'We will save the information you have entered up to this point as a draft claim. \n\nThe reference number of your draft claim is not an official claim number. Once you submit the claim at the end of this process, we will provide you with an official claim number. '
    },
    policyChangeModalTitle: {
        id: 'fnol.common.controllers.PolicySelectPolicyholderCtrl.Modal Title',
        defaultMessage: 'Policy change'
    },
    policyChangeModalMessage: {
        id: 'fnol.common.controllers.PolicySelectPolicyholderCtrl.Modal Content',
        defaultMessage: 'You have selected a new policy for this claim. The data you have entered for this claim will be lost.'
    },
    noPolicyFoundMessage: {
        id: 'fnol.common.views.policy-select-policyholder.No policies were found that match the search criteria.',
        defaultMessage: 'No policies were found that match the search criteria.'
    },
    changePolicyButtonlabel: {
        id: 'fnol.common.controllers.PolicySelectPolicyholderCtrl.Modal Option',
        defaultMessage: 'Change Policy'
    },
    fnolMessagePopupYes: {
        id: 'fnol.common.config.FlowConfig.Yes',
        defaultMessage: 'Yes'
    },
    fnolMessagePopupCancel: {
        id: 'fnol.common.config.FlowConfig.Cancel',
        defaultMessage: 'Cancel'
    },
    fnolUnnamed: {
        id: 'fnol.common.model.Unnamed',
        defaultMessage: 'Unnamed'
    },
    fnolBackToHome: {
        id: 'fnol.common.views.confirmation.Back to Homepage',
        defaultMessage: 'Back to Homepage'
    },
    fnolBackToClaims: {
        id: 'fnol.common.views.confirmation.Back to claims',
        defaultMessage: 'Back to claims'
    }
});
