import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('puUnderlying'), method, data, additionalHeaders);
}

export default class PUUnderlyingService {
    
    static updateUnderlying(quoteID, sessionUUID, data, authHeader = {}) {
        return processSubmission('updateUnderlying', [quoteID, sessionUUID, data], authHeader);
    }

    static removeUnderlying(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('removeUnderlying', [quoteID, sessionUUID, publicIDs], authHeader);
    }

    static removeVehicle(quoteID, sessionUUID, publicID, vehicleID, underlyingPolicyType, authHeader = {}) {
        return processSubmission('removeVehicle', [quoteID, sessionUUID, publicID, vehicleID, underlyingPolicyType], authHeader);
    }

    static onPageNext(requestData, authHeader = {}) {
        return processSubmission('onPageNext', [requestData.jobID, requestData.sessionUUID], authHeader);
    }
}