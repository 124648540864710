import React from 'react';
import WizardPage from '../../templates/IMWizardPage';
import IMMotorTruckCargoPage from './IMMotorTruckCargoPage';

const IMMotorTruckCargoReadOnlyPage = (props) => {
    return <IMMotorTruckCargoPage {...props} readOnly />
};

IMMotorTruckCargoReadOnlyPage.propTypes = WizardPage.propTypes;
export default IMMotorTruckCargoReadOnlyPage;
