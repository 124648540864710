import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniPolicyChangeCoverageService {

    static getErrorsAndWarnings(jobID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeCoverage'),
            'getErrorsAndWarnings', [jobID], additionalHeaders);
    }
}
