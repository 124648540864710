import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUnderwritingIssuesList,
    EligibilityIssuesLinkComponent,
    ValidationIssuesComponent
} from 'wni-components-platform-react';
import { ErrorsAndWarningsUtil } from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import metadata from './PolicyChangeSideBySidePeriodInfo.metadata.json5';
import messages from './PolicyChangeSideBySidePeriodInfo.messages';
import styles from './PolicyChangeSideBySidePeriodInfo.module.scss';

function PolicyChangeSideBySidePeriodInfo(props) {
    const {
        model: sxsPeriodDto,
        // overrideProps,
        // periodStatus,
        // submissionVM,
        // updateSubmissionVM,
        underwritingIssues,
        eligibilityIssues,
        isEditable,
        isClueCompare,
        isMvrCompare,
        isNewAccount,
        currentAccountNumber,
        // quoteID,
        // sessionUUID,
        // isReferUnWriter,
        isServiceCallInProgress: isLoading,
        showVersionNumber,
        // paymentPlanType,
        // isMonthlyPayment,
        // isRunFinalValidation,
        // isContinueWithQuote,
        // isValidation,
        callbackMap: {
            // onFinishQuote,
            // onSubmitQuote,
            // onReferToUnderwriter,
            // onContinueToIssue,
            // goToIncidentReports,
            // onRateAll: onGetUpdatedRate,
            // // onRunReservation,
            // onAccountClearance,
            // onRemoveVersion,
            // onSelectAndQuote,
            onSelectAndQuoteVersion,
            onRemoveSideBySideVersion,
        }
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        publicID,
        periodStatus,
        policyType_Ext: policyType,
        branchName,
        quoteFlow_Ext: quoteFlow,
        // isResolveNeeded,
        validationIssues,
        coverageIssues,
        nonFullPayAmount_Ext: monthlyPayTotal,
        fullPayAmount_Ext: fullPayTotal,
        totalPremiumRPT,
        columnIndex,
    } = sxsPeriodDto;
    const sxsPeriodDtoVM = viewModelService.create(sxsPeriodDto, 'pc', 'wni.edge.capabilities.quote.sxs.lob.personalauto.dto.PaSideBySidePeriodInfoDTO');
    // const sxsPeriodDtoVM = sxsPeriodDto;
    const [isCompareViolationDiff, setCompareViolationDiff] = useState(false);
    const [isComparePriorLossDiff, setComparePriorLossDiff] = useState(false);
    const [isCompareAllDiff, setCompareAllDiff] = useState(false);
    // const [isLoading, updateIsLoading] = useState(false);

    const hasBlockingUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(
        { underwritingIssues: underwritingIssues }
    );
    const sxsPeriodValidationIssues = ErrorsAndWarningsUtil.getValidationIssues({ validationIssues });
    const sxsPeriodValiationErrors = sxsPeriodValidationIssues.filter((issue) => issue.type === 'error');

    // const isMonthlyPayment = paymentPlanType === 'monthly';
    const isDraftPeriod = periodStatus === 'Draft';
    const isReadyForIssue = quoteFlow === 'submitquote' && periodStatus === 'Quoted';


    // const getButtonsVisibility = useCallback(() => {
    //     if (!isEditable) {
    //         return {
    //             showContinueWithQuoteBtn: false,
    //             showRunFinalValidationBtn: false,
    //             showReferToUWBtn: false,
    //             showContinueToIssueBtn: false,
    //             showGetUpdatedRateBtn: false,
    //         };
    //     }
    //     const showContinueWithQuoteBtn = quoteFlow === 'firstquote' && periodStatus === 'Rated';
    //     const showRunFinalValidationBtn = (quoteFlow === 'finishquote' || quoteFlow === 'submitquote') && periodStatus === 'Rated';
    //     const showReferToUWBtn = hasBlockingUWIssue && !(periodStatus === 'Draft' || showContinueWithQuoteBtn || showRunFinalValidationBtn);
    //     const showContinueToIssueBtn = !hasBlockingUWIssue && isReadyForIssue;
    //     const showGetUpdatedRateBtn = isDraftPeriod;
    //     return {
    //         showContinueWithQuoteBtn,
    //         showRunFinalValidationBtn,
    //         showReferToUWBtn,
    //         showContinueToIssueBtn,
    //         showGetUpdatedRateBtn,
    //     };
    // }, [isEditable, quoteFlow, hasBlockingUWIssue, periodStatus]);

    const getPremim = () => {
        if (isDraftPeriod) {
            return null;
        }
        // return isMonthlyPayment ? monthlyPayTotal : fullPayTotal;
        return totalPremiumRPT;
    };
    //-------------------------------------
    // const {
    //     showContinueWithQuoteBtn,
    //     showRunFinalValidationBtn,
    //     showReferToUWBtn,
    //     showContinueToIssueBtn,
    //     showGetUpdatedRateBtn,
    // } = getButtonsVisibility();
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        policyType: {
            value: `${policyType} Auto`,
        },
        branchName: {
            visible: showVersionNumber,
        },
        premium: {
            value: getPremim(),
            // value: totalPremiumRPT,
        },
        quoteVersionBtn: {
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: renderHelper.getButtonContent(translator(messages.selectAndQuote), isLoading),
        },
        deleteVersionBtn: {
            disabled: isLoading,
            visible: showVersionNumber,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            // onFinishQuote: async () => onFinishQuote(publicID),
            // onSubmitQuote: async () => onSubmitQuote(publicID),
            // onContinueToIssue,
            // goToIncidentReports,
            // onGetUpdatedRate,
            onSelectAndQuote: async () => onSelectAndQuoteVersion(publicID),
            onRemoveVersion: async () => onRemoveSideBySideVersion(publicID),
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            // quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            // refertouw: ReferToUnderwriterComponent,
            // eligibilitydetails: EligibilityIssuesLinkComponent,
            // accountclearancelink: AccountClearanceLinkComponent,
            // recommendedCoverages: RecommendCoveragesComponent,
            // validationissuescomponent: ValidationIssuesComponent,
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            sxsPeriodDtoVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sxsPeriodDtoVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            // onValueChange={writeValue}
            // onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyChangeSideBySidePeriodInfo.propTypes = {
    model: PropTypes.shape({}).isRequired,
    underwritingIssues: PropTypes.shape([]),
    eligibilityIssues: PropTypes.arrayOf(PropTypes.string),
    // overrideProps: PropTypes.shape({}),
    // periodStatus: PropTypes.string
    isEditable: PropTypes.bool,
    isClueCompare: PropTypes.bool,
    isMvrCompare: PropTypes.bool,
    isNewAccount: PropTypes.bool,
    currentAccountNumber: PropTypes.string,
    // quoteID: PropTypes.string,
    // sessionUUID: PropTypes.string,
    // paymentPlanType: PropTypes.string,
    // isMonthlyPayment: PropTypes.bool,
    // isReferUnWriter: PropTypes.bool,
    isServiceCallInProgress: PropTypes.bool,
    showVersionNumber: PropTypes.bool,
    // isRunFinalValidation: PropTypes.bool,
    // isContinueWithQuote: PropTypes.bool,
    // isValidation: PropTypes.string,
    callbackMap: PropTypes.shape({
        onFinishQuote: PropTypes.func,
        onSubmitQuote: PropTypes.func,
        onReferToUnderwriter: PropTypes.func,
        onContinueToIssue: PropTypes.func,
        goToIncidentReports: PropTypes.func,
        onRateAll: PropTypes.func,
        // onRunReservation: PropTypes.func
        onAccountClearance: PropTypes.func,
    })
};

PolicyChangeSideBySidePeriodInfo.defaultProps = {
    // overrideProps: {},
    // periodStatus: undefined
    underwritingIssues: [],
    eligibilityIssues: [],
    isEditable: true,
    isClueCompare: false,
    isMvrCompare: false,
    isNewAccount: false,
    currentAccountNumber: '',
    // quoteID: undefined,
    // sessionUUID: undefined,
    // isReferUnWriter: true,
    // paymentPlanType: 'annually',
    // isMonthlyPayment: false,
    isServiceCallInProgress: false,
    showVersionNumber: false,
    // isRunFinalValidation: false,
    // isContinueWithQuote: true,
    // isValidation: 'default',
    callbackMap: {},
};

export default PolicyChangeSideBySidePeriodInfo;
