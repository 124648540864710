import { defineMessages } from 'react-intl';

export default defineMessages({
    hoConstruction: {
        id: 'quoteandbind.ho.views.construction.Construction',
        defaultMessage: 'Construction',
    },
    hoConstructionDetails: {
        id: 'quoteandbind.ho.views.construction.Construction Details',
        defaultMessage: 'Construction Details',
    },
    hoConstructionYearBuilt: {
        id: 'quoteandbind.ho.views.construction.Year Built',
        defaultMessage: 'Year Built',
    },
    numberOfStories: {
        id: 'quoteandbind.ho.views.construction.Number of Stories',
        defaultMessage: 'Number of Stories',
    },
    garage: {
        id: 'quoteandbind.ho.views.construction.Garage',
        defaultMessage: 'Garage',
    },
    attachedGarage: {
        id: 'quoteandbind.ho.views.construction.Attached Garage',
        defaultMessage: 'Attached Garage',
    },
    noGarage: {
        id: 'quoteandbind.ho.views.construction.No Garage',
        defaultMessage: 'No Garage',
    },
    constructionType: {
        id: 'quoteandbind.ho.views.construction.Construction Type',
        defaultMessage: 'Construction Type',
    },
    foundationType: {
        id: 'quoteandbind.ho.views.construction.Foundation Type',
        defaultMessage: 'Foundation Type',
    },
    roof: {
        id: 'quoteandbind.ho.views.construction.Roof',
        defaultMessage: 'Roof',
    },
    specifyOther: {
        id: 'widgets.radioselect.components.RadioSelectImage.Specify other:',
        defaultMessage: 'Specify other:',
    },
    roofingUpgraded: {
        id: 'quoteandbind.ho.views.construction.Roofing Upgraded',
        defaultMessage: 'Roofing Upgraded',
    },
    plumbing: {
        id: 'quoteandbind.ho.views.construction.Plumbing',
        defaultMessage: 'Plumbing',
    },
    plumbingUpgraded: {
        id: 'quoteandbind.ho.views.construction.Plumbing Upgraded',
        defaultMessage: 'Plumbing Upgraded',
    },
    heating: {
        id: 'quoteandbind.ho.views.construction.Heating',
        defaultMessage: 'Heating',
    },
    primaryHeating: {
        id: 'quoteandbind.ho.views.construction.Primary Heating',
        defaultMessage: 'Primary Heating',
    },
    primaryHeatingPleaseSpecify: {
        id: 'quoteandbind.ho.views.construction.Specify other:',
        defaultMessage: 'Please specify:',
    },
    secondaryHeating: {
        id: 'quoteandbind.ho.views.construction.Secondary Heating',
        defaultMessage: 'Secondary Heating',
    },
    heatingUpgraded: {
        id: 'quoteandbind.ho.views.construction.Heating Upgraded',
        defaultMessage: 'Heating Upgraded',
    },
    electrical: {
        id: 'quoteandbind.ho.views.construction.Electrical',
        defaultMessage: 'Electrical',
    },
    wiring: {
        id: 'quoteandbind.ho.views.construction.Wiring',
        defaultMessage: 'Wiring',
    },
    electricalSystem: {
        id: 'quoteandbind.ho.views.construction.Electrical System',
        defaultMessage: 'Electrical System',
    },
    wiringUpgraded: {
        id: 'quoteandbind.ho.views.construction.Wiring Upgraded',
        defaultMessage: 'Wiring Upgraded',
    },
    yearPlaceholder: {
        id: 'quoteandbind.ho.views.construction.Year',
        defaultMessage: 'Year',
    },
});
