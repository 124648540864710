import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('puHouseholdInfoChangeService'), method, data, additionalHeaders);
}

export default class PUHouseholdInfoChangeService {


    /**
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static savePUHouseholdInfoData(quoteID, sessionUUID, questionSetData, quoteBaseData, puHouseholdInfoData, authHeader) {
        return processSubmission('savePUHouseholdInfoData', [quoteID, sessionUUID, questionSetData, quoteBaseData, puHouseholdInfoData], authHeader);
    }
}