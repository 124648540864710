import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';

import metadata from './PoliciesVehiclesTable.metadata.json5';
import messages from '../Policies.messages';
import PAVehicleCoveragesPopup from './components/PAVehicleCoveragesPopup/PAVehicleCoveragesPopup';

function PoliciesVehiclesTable(props) {
    const { model } = props;
    const modalApi = useWniModal();
    const translator = useTranslator();

    const vehicles = _.get(model, 'lobs.personalAuto.vehicleDTOs');

    const showCoveragePopup = () => {
        const componentProps = {
            title: 'vehicle coverages'
        };
        return modalApi.showModal(
            <PAVehicleCoveragesPopup {...componentProps} />
        );
    };

    const getCoverageLink = () => {
        return (
            <LinkComponent onClick={() => showCoveragePopup()}>
                {translator(messages.showVehicleCoveragesMessage)}
            </LinkComponent>
        );
    };

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            vehiclesTableContainer: {
                data: vehicles,
            },
        };
    }, []);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            getCoverageLink,
        },
        resolveComponentMap: {},
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={model}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PoliciesVehiclesTable.propTypes = {
    model: PropTypes.shape({}),
};
PoliciesVehiclesTable.defaultProps = {
    model: {},
};
export default PoliciesVehiclesTable;
