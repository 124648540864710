import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

const SN_ADDRESS_LOOKUP_WNI = 'addressLookupWNI';

export default class WNIAddressLookupService {
    static lookupAddressUsingZipCode(zipCode, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'getAddressFromZipCode', [zipCode], additionalHeaders);
    }

    static lookupAddressUsingZipCodeAndCountry(zipCode, aCountry, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'getAddressFromZipCodeAndCountry', [zipCode, aCountry], additionalHeaders);
    }

    static lookupAddressUsingObject(addressLookupDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'lookupAddressUsingObject', [addressLookupDTO], additionalHeaders);
    }

    static lookupAddressUsingString(partialAddress, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'lookupAddressUsingString', [partialAddress], additionalHeaders);
    }

    static lookupBankCompanyName(companyName, companyType, accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'getBankCompanyName', [companyName, companyType, accountNumber], additionalHeaders);
    }

    static searchAccountNumbers(accountQuery, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'searchAccountNumbers', [accountQuery], additionalHeaders);
    }

    static verifyAddress(addressDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'verifyAddress', [addressDTO], additionalHeaders);
    }

    static searchBank(bankSearchCriteriaDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'searchBank', [bankSearchCriteriaDTO], additionalHeaders);
    }

    static searchCLAccountNumbers(accountQuery, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP_WNI), 'searchCLAccountNumbers', [accountQuery], additionalHeaders);
    }
}
