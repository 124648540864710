import { defineMessages } from 'react-intl';

export default defineMessages({
    activePoliciesProductFilterAllItemName: {
        id: 'gateway.directives.active-policies.all products',
        defaultMessage: '--All Products--'
    },
    activePoliciesColumnHeaderProduct: {
        id: 'gateway.directives.active-policies.Product',
        defaultMessage: 'Product'
    },
    activePoliciesColumnHeaderEffectiveDate: {
        id: 'gateway.directives.active-policies.Effective',
        defaultMessage: 'Effective',
    },
    activePoliciesColumnHeaderExpirationDate: {
        id: 'gateway.directives.active-policies.Expiration',
        defaultMessage: 'Expiration',
    },
    activePoliciesColumnHeaderTotalPremium: {
        id: 'gateway.directives.active-policies.Total Premium',
        defaultMessage: 'Total Premium',
    },
    activePoliciesColumnHeaderStatus: {
        id: 'gateway.directives.active-policies.Status',
        defaultMessage: 'Status',
    },
    activePoliciesColumnHeaderViewPolicy: {
        id: 'gateway.directives.active-policies.View Policy',
        defaultMessage: 'View Policy',
    },
    activePoliciesColumnHeaderChangeOrCancel: {
        id: 'gateway.directives.active-policies.Change Or Cancel',
        defaultMessage: 'change/cancel'
    },
    changePolicyButton: {
        id: 'gateway.directives.active-policies.Change Policy Button',
        defaultMessage: 'Change Policy'
    },
    cancelPolicyButton: {
        id: 'gateway.directives.active-policies.Cancel Policy Button',
        defaultMessage: 'Cancel Policy'
    },
    warningMsgNoRights: {
        id: 'gateway.directives.active-policies.warning Policy Message',
        defaultMessage: 'Our records indicate that you do not have servicing rights on this policy. Please contact your Underwriter for further assistance'
    },
});
