import { defineMessages } from 'react-intl';

export default defineMessages({
    payAndIssue: {
        id: 'wni.payment.downPaymentSetup.pay and issue button',
        defaultMessage: 'Pay & Issue'
    },
    forQuotes: {
        id: 'wni.payment.downPaymentSetup.For Quotes',
        defaultMessage: 'For Quotes',
    },
    agencySweep: {
        id: 'wni.payment.downPaymentSetup.Agency Sweep',
        defaultMessage: 'Agency Sweep',
    },
    addNewCreditCard: {
        id: 'wni.payment.downPaymentSetup.Add New Credit Card',
        defaultMessage: 'Add New Credit Card'
    },
    addNewBankAccount: {
        id: 'wni.payment.downPaymentSetup.Add New Bank Account',
        defaultMessage: 'Add New Bank Account'
    },
    agencySweepMessage: {
        id: 'wni.payment.downPaymentSetup.Agency Sweep Message',
        defaultMessage: 'You have selected Agency Sweep as the down payment method.  Please note, you will see a separate down payment for each policy issued.  If you are submitting multiple policies for issue, the down payment amount will not be combined.'
    }
});
