
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import SingleClauseContext from '../../context/IMSingleClauseContext';
import IMScheduleTable from "../IMScheduleTable/IMScheduleTable";
import IMCoverageUtil from '../../util/IMCoverageUtil';

const CommonSingleScheduleTable = (props) => {

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { authHeader } = useAuthentication();

    const {
        wizardData: submissionVM,
        coveragePartClauses,
        coveragePartSchedule,
        coverageWithSchedule,
        setCoveragePartClauses,
        isEditable,
        onValidate: onSingleCoverageValidate,
        setIsEditing,
        updateScheduleService,
        schedulePath,
        showErrors,
        visibleOfTableTitle = false,
        visibleOfDivider = false,
        scheduleTableTitle = '',
        isCoverageForm = true
    } = props

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const validationID = schedulePath;

    const { onValidate, isComponentValid } = useValidation(validationID);

    useEffect(() => {
        if (onSingleCoverageValidate) {
            onSingleCoverageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onSingleCoverageValidate) {
                onSingleCoverageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onSingleCoverageValidate, validationID]);

    const changeClause = useCallback(
        (value, changedPath, originCoverage) => {
            // edit
            let overrideChangedPath = changedPath.replace(`${schedulePath}.schedule.scheduleItems.`, `${schedulePath}.scheduleItems`);
            if (overrideChangedPath === changedPath) {
                // add, delete
                overrideChangedPath = changedPath.replace(`${schedulePath}.schedule.scheduleItems`, `${schedulePath}.scheduleItems`);
            }
            const newSchedule = IMCoverageUtil.setClauseValue(originCoverage, schedulePath, value, overrideChangedPath);
            const newCoverageWithSchedule = _.clone(coverageWithSchedule);
            _.set(newCoverageWithSchedule, schedulePath, newSchedule);
            if(isCoverageForm) {
                setCoveragePartClauses({...coveragePartClauses, [schedulePath]: newCoverageWithSchedule});
            } else {
                setCoveragePartClauses(newCoverageWithSchedule);
            }
            setIsEditing(false);
            return newSchedule;
        },
        [coveragePartClauses, coverageWithSchedule, isCoverageForm, schedulePath, setCoveragePartClauses, setIsEditing]
    );
    
    const changeCoverage = useCallback(
        (value, changedPath) => {
            return changeClause(value, changedPath, coverageWithSchedule);
        },
        [changeClause, coverageWithSchedule]
    );

    const updateVMToServer = useCallback(async (newSchedule, setEditingFunc) => {
        setLoadingMask(true)
        const newSingleCoverageFromServer = await updateScheduleService(
            jobID,
            sessionUUID,
            newSchedule,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false);
        setCoveragePartClauses(newSingleCoverageFromServer);
    }, [setLoadingMask, updateScheduleService, jobID, sessionUUID, authHeader, setCoveragePartClauses]);

    const updateNewScheduleToServer = useCallback(async (scheduleItems) => {
        updateVMToServer(scheduleItems, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeSingleScheduleAndSync = useCallback(
        (value, changedPath) => {
            const newSchedule = changeCoverage(value, changedPath);
            updateNewScheduleToServer(newSchedule);
        },
        [changeCoverage, updateNewScheduleToServer]
    );

    return <SingleClauseContext.Provider value={{
        clausePath: schedulePath,
        isEditable,
        onChangeSubmissionAndSync: changeSingleScheduleAndSync,
        labelPosition: 'left',
        onValidate,
        setIsEditing,
        showErrors,
    }}>
        <>
            {visibleOfTableTitle && <h4>{scheduleTableTitle}</h4>}
            {visibleOfDivider && <hr/>}
            <IMScheduleTable schedule={coveragePartSchedule} />         
        </>
        {!isComponentValid?
                <div className='font-error-light-16 mb-10 mt--10' >Information for this coverage is missing.</div>
                : null}
    </SingleClauseContext.Provider>
}

export default CommonSingleScheduleTable