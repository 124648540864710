import { defineMessages } from 'react-intl';

export default defineMessages({
    startNewQuote: {
        id: 'custom.gateway.views.account-existing.Use this Account',
        defaultMessage: 'Start New Quote'
    },
    addQuoteToThisAccount: {
        id: 'custom.gateway.views.account-existing.Start New Quote',
        defaultMessage: 'Add Quote To This Account',
    },
    merge: {
        id: 'custom.gateway.views.account-existing.Merge',
        defaultMessage: 'Merge',
    },
    AddToThisAccount: {
        id: 'custom.gateway.views.account-clearance.Add to this Account',
        defaultMessage: 'Add to this Account'
    },
    matchingTitle: {
        id: 'gateway.views.new-quote.This may be an existing client for your agency.',
        defaultMessage: 'This may be an existing client for your agency.'
    },
    matchingMsg: {
        id: 'gateway.views.new-quote.Please select one of the following accounts to continue to account summary or click on "Create New" to start with a new account.',
        defaultMessage: 'Please select one of the following accounts to continue to account summary or click on "Create New" to start with a new account.'
    },
    continiueToPayAndIssue: {
        id: 'gateway.views.new-quote.Please select one of the following accounts to continue to pay and issue or refer to your underwriter for review.',
        defaultMessage: 'Please select one of the following accounts to continue to pay and issue or refer to your underwriter for review.'
    },
    potentialTitle: {
        id: 'gateway.views.new-quote.You may also want to take a look at these',
        defaultMessage: 'You may also want to take a look at these...'
    },
    noMatchingTitle: {
        id: 'gateway.views.new-quote.There is no existing client for your agency.',
        defaultMessage: 'There is no existing client for your agency.'
    },
    noMatchingPotentialTitle: {
        id: 'gateway.views.new-quote.You may also want to take a look at these or click on "Create New" to start with a new account.',
        defaultMessage: 'You may also want to take a look at these or click on "Create New" to start with a new account.'
    },
});
