import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { useWniModal } from 'wni-components-platform-react';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import messages from './useAORValidationData.messages';



function useAORValidationData() {
    const {
        authUserData: {
            producerCodes_Ext: activeProducerCodeList
        },
        authUserData
    } = useAuthentication();
    const history = useHistory();
    const modalApi = useWniModal();
    const showWarningMsg = () => {
        modalApi.showAlert({
            status: 'warning',
            icon: 'gw-error-outline',
            message: messages.warningMsg,
        }).then(() => {
            history.push('/home');
        }).catch((result) => {
            console.log(result)
            _.noop();
        });
    };

    const getPolicyAccess = (agencyOfServiceCode) => {
        let result = false;
        if (!_.get(authUserData, 'isExternalUser_Ext')) {
            result = true;
        } else {
            _.forEach(activeProducerCodeList, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                    return false;
                }
                return true;
            });
        }
        return result;
    };
    const validateAORAccess = (producerCode) => {
        const access = getPolicyAccess(producerCode);
        if (!access) {
            showWarningMsg();
        }; 
    }

    return {
        validateAORAccess
    };
}

export default useAORValidationData;
