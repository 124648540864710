
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';

import { CRLineCoveragesReadOnlyPage } from 'wni-capability-quoteandbind-cr-react';

function CRLineCoveragesChangeReadOnlyPage(props) {

    return (
        <CRLineCoveragesReadOnlyPage {...props} />
    );

};

export default CRLineCoveragesChangeReadOnlyPage;