import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('dpRCTChange'), method, data, additionalHeaders);
}

export default class HORCTChangeChangeService {
    static prefillRCTByRiskAddress(jobID, sessionUUID, additionalHeaders = {}) {
        return processSubmission('prefillRCTByRiskAddress', [jobID, sessionUUID], additionalHeaders);
    }

    static assumptionsRCTCall(jobID, sessionUUID, assumptionRequestDTO, additionalHeaders = {}) {
        return processSubmission('assumptionsRCTCall', [jobID, sessionUUID, assumptionRequestDTO], additionalHeaders);
    }

    // No need for re-implementation in PolicyChange
    // static launchRCTExpress(jobID, sessionUUID, callbackUrl, additionalHeaders = {}) {
    //     return processSubmission('launchRCTExpress', [jobID, sessionUUID, callbackUrl], additionalHeaders);
    // }

    static syncRCTInfo(jobID, sessionUUID, additionalHeaders = {}) {
        return processSubmission('syncRCTInfo', [jobID, sessionUUID], additionalHeaders);
    }

    // just call and return RCT data, not save
    static prefillRCTByRiskAddressPt1(quoteID, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('hoRCT'),
            'prefillRCTByRiskAddressPt1', [quoteID, sessionUUID], additionalHeaders);
    }

    // save RCT data
    static prefillRCTByRiskAddressPt2(quoteID, sessionUUID, prefillRCTID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('hoRCT'),
            'prefillRCTByRiskAddressPt2', [quoteID, sessionUUID, prefillRCTID], additionalHeaders);
    }

    // just call and return RCT data, not save
    static assumptionsRCTCallPt1(quoteID, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('hoRCT'),
            'assumptionsRCTCallPt1', [quoteID, sessionUUID], additionalHeaders);
    }

    // save RCT data
    static assumptionsRCTCallPt2(quoteID, sessionUUID, prefillRCTID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('hoRCT'),
            'assumptionsRCTCallPt2', [quoteID, sessionUUID, prefillRCTID], additionalHeaders);
    }
}
