import React from 'react';

function ModalFooter(props) {
    const {
        className = '',
        children
    } = props;

    return <div className={`wni__ModalFooter__modalFooter ${className}`}>
        {children}
    </div>;
}

export default ModalFooter;