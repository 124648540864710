import { WniProductsUtil, WniAccountsUtil } from 'wni-portals-util-js';

const { PERSONAL_ACCOUNT_CODE, COMMERCIAL_ACCOUNT_CODE } = WniAccountsUtil;
const productsMap = WniProductsUtil.getAllProductsMap();

const getImageCheckboxGroupOptions = () => {
    const newData = productsMap.filter((item) => item.accountType === COMMERCIAL_ACCOUNT_CODE);
    return newData.map((item) => {
        return {
            name: item.productName,
            icon: item.icon,
            code: item.code,
            tooltip: item.tooltip
        }
    });
};

export default {
    ImageCheckboxGroupOptions: getImageCheckboxGroupOptions()
}