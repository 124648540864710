import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    otherActiveQuotes: {
        id: "custom.component.common.ActiveQuotesDropdown.Other Active Quotes",
        defaultMessage: "Other Active Quotes",
    }
});
