
import React from 'react';
import { CRLineCoveragesPage } from 'wni-capability-quoteandbind-cr-react';
import { WniProductsUtil, PolicyChangeUtil } from 'wni-portals-util-js';

const {
    CR_PRODUCT_CODE,
    getPolicyLinePatternName
} = WniProductsUtil;

const linePattern = getPolicyLinePatternName(CR_PRODUCT_CODE);

function CRLineCoveragesChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent(linePattern, submissionVM);

    return (
        <CRLineCoveragesPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

};

export default CRLineCoveragesChangePage;