

import React from 'react';
import { IMInstallationFloaterReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMInstallationFloaterChangeReadOnlyPage = (props) => {
    return <IMInstallationFloaterReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMInstallationFloaterChangeReadOnlyPage
