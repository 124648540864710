import React, {
    useContext,
    useMemo,
    useEffect
} from 'react';

import { WniDropdownSelect} from 'wni-common-base-components';
import _ from 'lodash';
import SingleClauseContext from '../../context/WCSingleClauseContext'
import CoverageUtil from '../../util/WCCoverageUtil';
import WCClauseComponent from '../WCClauseComponentVM/WCClauseComponent';
import WCClauseElementComponent from './WCClauseElementComponent';
import WCTermComponent from './WCTermComponent';
import CoveragesConfigContext from '../../context/WCCoveragesConfigContext';

function isClauseLoading(clause, loadingClause) {
    if (_.isArray(loadingClause)) {
        return loadingClause.includes(clause.code_Ext)
    }
    if (_.isString(loadingClause)) {
        return loadingClause === clause.code_Ext;
    }
    if (_.isBoolean(loadingClause)) {
        return loadingClause;
    }
    return false;
}

function getRelativePath(changedValuePath) {
    const [relativePath] = changedValuePath.match(/(selected|terms\.children\[\d+\](\.\w+)+)/);

    return relativePath.replace(/\.children/g, '');
}

const isAllTermsReadOnly = (terms) => {
    return terms.every((term) => {
        const isTermReadOnly = _.get(term, 'readOnly_Ext')
        if (isTermReadOnly) {
            return true
        }
        const chosenTerm = _.get(term, 'chosenTerm')
        const options = _.get(term, 'options', [])
        if (_.get(options, 'length', 0) === 1 && _.get(options, '[0].code') === chosenTerm) {
            return true
        }
        return false
    })
}

function WCSingleClauseComponentVM(props) {
    const {
        //
        value: clause = {},
        path,
        //
        onValidate,
        showErrors,
        //
        idPrefix,
        containerClassName,
        //
        labelPosition,
        labelTop,
        //
        showAmount,
        //
        loadingClause,
        loadingClauseMessage,
        loadingIconType,
        //
        isEditable,
        isDisabled,
        //
        onChangeSubmissionAndSync,
        // This function change submission without sync
        onChangeClause,
        onSyncCoverages,
        // ============================
        hideCheckBoxForRequired,
        isOnlyShowTermsAndScheduleItems,
        onOpenCoverageDetailsPopup,
        setIsEditing
    } = props;

    const coveragesConfig = useContext(CoveragesConfigContext);

    const {
        code_Ext: codeExt,
        name,
        terms,
        schedule,
        publicID,
        selected
    } = clause

    const {
        limitCoverageConfig,
    } = coveragesConfig;

    const clauseID = useMemo(() => `${idPrefix}Clause_[${clause.publicID}]`, [idPrefix, clause.publicID])

    const coverageTermsCount = _.get(clause, 'terms.length', 0);
    const isLimitRequired = _.get(clause, 'terms', []).some((term) => term.required);

    const displayName = clause.name;

    // const showPrivateStructureTable = getShowPrivateStructureTable();
    const isCoverageValid = !CoverageUtil.isCoverageInvalid(clause)

    useEffect(()=>{
        if (onValidate) {
            onValidate(isCoverageValid, codeExt)
        }
        return () => {
            if (onValidate) {
                onValidate(true, codeExt)
            }
        }
    },[clause, codeExt, isCoverageValid, onValidate])

    const linkToDetailsReadOnly = !isEditable || (
        _.isNil(schedule) // If coverage has schedule it's not readonly
        && isAllTermsReadOnly(terms)
    );


    const handleChangeAndSyncClause = (value, changedValuePath) => {
        if (_.get(clause, getRelativePath(changedValuePath)) !== value) {
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    const isLoading = isClauseLoading(clause, loadingClause)
    const ClauseContent = clause.selected && !isLoading ? <WCClauseElementComponent
        terms={terms}
        schedule={schedule}
    /> : null;
    
    const showInvalidCoverageError = showErrors && !isCoverageValid;

    const coverageContainsLimitTerm = Object.keys(limitCoverageConfig).includes(codeExt);

    const ClauseComponent = <WCClauseComponent
        id={clauseID}
        displayName={displayName}
        showAmount={showAmount}
        amount={clause.amount}
        path= {`${path}.selected`}
        readOnly= {clause.required || clause.readOnly_Ext}
        required= {clause.required}
        value= {selected}
        checked= {selected}
        onValueChange= {handleChangeAndSyncClause}
        isLoading= {isLoading}
        loadingMessage= {loadingClauseMessage}
        containerClassName= {`${containerClassName} clause-ootbcoverage-container`}
        coverageTermsCount= {coverageTermsCount}
        isLimitRequired= {isLimitRequired}
        loadingIconType={loadingIconType}
        hideCheckBoxForRequired={hideCheckBoxForRequired}
        isOnlyShowTermsAndScheduleItems = {isOnlyShowTermsAndScheduleItems}
        onOpenCoverageDetailsPopup={onOpenCoverageDetailsPopup}
        isCoverageValid={isCoverageValid}
        linkToDetailsReadOnly={linkToDetailsReadOnly}
        className='flex-1-5'
    />

    const limitTerm = coverageContainsLimitTerm ? terms
        .find((term) => _.get(term, 'code_Ext') === _.get(limitCoverageConfig, codeExt)) : null;
    const limitTermIndex = terms
        .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(limitTerm, 'code_Ext'))

    // If limit term not exist, render a disabled dropdown in edit mode
    const LimitTermComponent = limitTerm ? <WCTermComponent
        term={limitTerm}
        termIndex = {limitTermIndex}
        className='flex-1'
        hideLabel
    /> : isEditable && <WniDropdownSelect
        disabled
        hideLabel
        id="disabledDropdownselect"
        placeholder=""
        size="medium"
        className='flex-1'
    />;

    return (
        <SingleClauseContext.Provider value={{
            idPrefix: idPrefix,
            clauseCode: codeExt,
            clausePublicID: publicID,
            clausePath: path,
            clauseName: name,
            isEditable: isEditable,
            isDisabled,
            onChangeClause: onChangeClause,
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: onChangeSubmissionAndSync,
            labelPosition,
            labelTop,
            onValidate,
            setIsEditing,
            showErrors,
        }}>
            {/* if this coverage do not contains limit term, directly display checkbox in a line */}
            {(!coverageContainsLimitTerm || !isEditable) && ClauseComponent}
            {/* if this coverage do not contains limit term, display checkbox and limit term in a line */}
            {(coverageContainsLimitTerm && isEditable) && <div className='d-flex'>
                {ClauseComponent}
                {LimitTermComponent}
            </div>}
            {/* ClauseContent includes terms(without limit term) and schedules */}
            {ClauseContent}
            {showInvalidCoverageError ?
                <div className='font-error-light-16 mb-10 mt--10' >Information for this coverage is missing.</div>
                : null}
        </SingleClauseContext.Provider>)
}

WCSingleClauseComponentVM.propTypes = {
};

export default WCSingleClauseComponentVM;