import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cuRiskDetails'), method, data, additionalHeaders);
}

export default class CURiskDetailsService {

    /**
     * Invokes CURiskDetailsHandler.getRiskDetailsWithSync()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getRiskDetailsWithSync(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getRiskDetailsWithSync', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CURiskDetailsHandler.getRiskDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getRiskDetails(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getRiskDetails', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CURiskDetailsHandler.updateRiskDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} cuCoveragesDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateRiskDetails(quoteID, sessionUUID, cuCoveragesDTO, authHeader = {}) {
        return processSubmission('updateRiskDetails', [quoteID, sessionUUID, cuCoveragesDTO], authHeader);
    }

    /**
     * Invokes CURiskDetailsHandler.onPageNext()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onPageNext(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('onPageNext', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CURiskDetailsHandler.searchCEICoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchCEICoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, authHeader = {}) {
        return processSubmission('searchCEICoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType], authHeader);
    }

    /**
     * Invokes CURiskDetailsHandler.addSearchedCEICoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedCEICoverages(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedCEICoverages', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

}