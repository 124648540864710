import React, {
    useCallback,
    useContext,
    useState,
} from "react";
import _ from 'lodash'
import { useTranslator } from '@jutro/locale';
import CoverageContext from '../../../../context/CPCoverageContext'
import SingleClauseContext from '../../../../context/CPSingleClauseContext'
import CPClausesComponentVM from '../../../CPClausesComponentVM/CPClausesComponentVM'
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import CPSingleClauseComponentVM from '../../../CPSingleClauseComponentVM/CPSingleClauseComponentVM'
import CoverageUtil from '../../../../util/CPCoverageUtil'
import messages from './CPComplexSchedulePopupCoverages.messages'

const CPComplexSchedulePopupCoverages = (props) => {
    const {
        schedule,
        openedScheduleItemNumber,
        onValidate,
        showErrors,
        isEditing,
        setIsEditing,
    } = props

    const {
        scheduleItems,
    } = schedule

    const translator = useTranslator();

    if (_.isNil(openedScheduleItemNumber)) {
        return null
    }

    const openedScheduleItemIndex = scheduleItems.findIndex((scheduleItem) => scheduleItem.itemNumber === openedScheduleItemNumber)
    const openedScheduleItem = scheduleItems[openedScheduleItemIndex]

    const {
        wizardData: submissionVM,
        updateWizardData,

        updateVMToServer,
    } = useContext(CoverageContext)
    
    const {
        clausePath: clauseVMPath,
        isEditable,
    } = useContext(SingleClauseContext)

    const [loadingClauses, setLoadingClauses] = useState([])

    const getScheduledItemClauses = useCallback(() => {
        const scheduledItemClauses = _.get(openedScheduleItem, 'clauses', []);
        return scheduledItemClauses;
    }, [openedScheduleItem]);

    const scheduledItemClausesVMPath = `${clauseVMPath}.schedule.scheduleItems.children[${openedScheduleItemIndex}].clauses`;
    const scheduledItemClausesPath = `${clauseVMPath.replaceAll('.children', '')}.schedule.scheduleItems[${openedScheduleItemIndex}].clauses`;
    const scheduleItemClauses = getScheduledItemClauses();

    const updateSubmissionVMToServer = useCallback(async (updatedSubVMInPE) => {
        updateVMToServer(updatedSubVMInPE, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = CoverageUtil.setClauseValue(scheduleItemClauses, scheduledItemClausesVMPath, value, changedPath);
            _.set(submissionVM.value,`${clauseVMPath.replaceAll('.children', '')}.schedule.updated_Ext`, true)
            _.set(submissionVM.value, scheduledItemClausesPath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            clauseVMPath,
            submissionVM,
            scheduleItemClauses,
            scheduledItemClausesVMPath,
            scheduledItemClausesPath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubVMUpdatedInPE = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubVMUpdatedInPE);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    if (_.isEmpty(scheduleItemClauses)) {
        return null
    }

    return <>
        <h5>{translator(messages.scheduledItemCoveragesTitle)}</h5>
        <div className="formCoveragesContainer">
            <CPClausesComponentVM
                splitByClauseCategory= {false}
                sortBySelected
                onSyncCoverages= {onSyncCoverages}
                onChangeSubmissionAndSync = {changeSubmissionAndSync}
                onChangeClause = {changeSubmission}
                onValidate = {onValidate}
                showAmount = {false}
                loadingClause = {loadingClauses}
                path = {scheduledItemClausesVMPath}
                value = {scheduleItemClauses}
                showErrors ={showErrors}
                componentMapOverrides = {{
                    CPSingleClauseComponentVM: CPSingleClauseComponentVM,
                }}
                setIsEditing ={setIsEditing}
                isEditing = {isEditing}
                isEditable={isEditable}
            />
        </div>
    </>
}

export default CPComplexSchedulePopupCoverages