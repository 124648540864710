
import React, { useContext } from 'react';
import _ from 'lodash';
import CPTermComponent from './CPTermComponent';
import SingleClauseContext from '../../context/CPSingleClauseContext';
import CoveragesConfigContext from '../../context/CPCoveragesConfigContext';
import CPScheduleTable from '../CPScheduleTable/CPScheduleTable';
import CP7Limit2TermComponent from './CP7Limit2TermComponent';

function CPClauseElementComponent(props) {

    const {
        idPrefix,
        clauseCode: code,
        clausePublicID: publicID,
        isEditable,
    } = useContext(SingleClauseContext)

    const coveragesConfig = useContext(CoveragesConfigContext)

    const {
        terms,
        schedule,
    } = props

    const {
        limitCoverageConfig,
        covTermAvailable,
    } = coveragesConfig;

    const curCovTermAvailableConfig = _.get(covTermAvailable, code, {})

    return (
        <div
            id= {`${idPrefix}ClausetElementContainer_[${publicID}]`}
            className= 'clause_element_container'
        >
            {terms
                .filter((term) => {
                    const termCode = _.get(term, 'code_Ext')
                    const curTermAvailableFunc = _.get(curCovTermAvailableConfig, termCode)
                    if (_.isFunction(curTermAvailableFunc)) {
                        const isCurTermAvailable = curTermAvailableFunc()
                        if (!isCurTermAvailable) {
                            return false
                        }
                    }
                    // filter all limit terms in edit mode
                    if (!isEditable) {
                        return true
                    }
                    return _.get(term, 'code_Ext') !== _.get(limitCoverageConfig, code)
                })
                .map((term) => {
                    const termCode = _.get(term, 'code_Ext')
                    const termIndex = terms
                        .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === termCode)
                    if (termCode === 'CP7Limit2') {
                        return <CP7Limit2TermComponent
                            key={termCode}
                            term={term}
                            termIndex = {termIndex}
                        />
                    }
                    return <CPTermComponent
                        key={termCode}
                        term={term}
                        termIndex = {termIndex}
                    />
                })}
            {schedule ? <CPScheduleTable schedule={schedule} /> : null}
        </div>
        
    )
}

export default CPClauseElementComponent;