import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';

function Address(data) {
    const localeService = ServiceManager.getService('locale-service');
    this.country = localeService.getDefaultCountryCode();
    this.addressLine1 = null;
    this.addressLine2 = null;
    this.addressLine3 = null;
    this.city = null;
    this.state = null;
    this.postalCode = null;
    this.addressType = 'home';

    if (data) {
        _.extend(this, data);
    }
}

export default Address;
