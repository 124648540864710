

import React from 'react';
import { IMMiscFormsPage } from 'wni-capability-quoteandbind-im-react';


const IMMiscFormsChangePage = (props) => {
    return <IMMiscFormsPage {...props} isPolicyChange />
};
export default IMMiscFormsChangePage

