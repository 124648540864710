import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import DummyPolicyChangeStartPage from './pages/Start/DummyPolicyChangeStartPage';
import DummyPaySuccessChangePage from './pages/PaySuccess/DummyPaySuccessChangePage';

// WIZARD PAGE IMPORT ANCHOR

import DummyQuoteChangePage from './pages/Quote/DummyQuoteChangePage';
import DummyQuoteChangeReadOnlyPage from './pages/Quote/DummyQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        DummyPolicyChangeStartPage: { component: 'DummyPolicyChangeStartPage' },
        DummyPaySuccessChangePage: { component: 'DummyPaySuccessChangePage' },

// WIZARD PAGE COMPONENT NAME ANCHOR
        DummyQuoteChangePage: { component: 'DummyQuoteChangePage' },
        DummyQuoteChangeReadOnlyPage: { component: 'DummyQuoteChangeReadOnlyPage' },
    },
    {
        DummyPolicyChangeStartPage,
        DummyPaySuccessChangePage,

// WIZARD PAGE COMPONENT MAP ANCHOR
        DummyQuoteChangePage,
        DummyQuoteChangeReadOnlyPage,
    }
);

export { default as DummyPolicyChangeWizard } from './DummyPolicyChangeWizard';
