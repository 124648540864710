import React, {
    useState,
    useEffect
} from 'react'
import { WniDate } from 'wni-common-base-components'

export const DetailsDateInput = (props) => {
    const {
        sublinePublicID,
        displayable,
        detailsIndex,
        onValidate,
        showErrors,
        isEditable,
        disabled,
        changeDetailsAndSync,
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        dateValue,
    } = displayable


    const [editingValue, setEditingValue] = useState(dateValue)

    useEffect(() => {
        setEditingValue(dateValue)
    }, [dateValue])

    const onDateValueChange = (value) => {
        setEditingValue(value)
    }

    const onBlur = () => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            dateValue: editingValue
        }
        changeDetailsAndSync(newDisplayable, detailsIndex)
    }


    return <WniDate
        id={`${sublinePublicID}_displayable_dateinput_${propertyName}`}
        labelPosition='left'
        label={label}
        value={editingValue}
        onValueChange={onDateValueChange}
        onBlur={onBlur}
        placeholder=""
        size="medium"
        className='flex-1'
        required={required}
        disabled={disabled}
        showRequired
        onValidationChange={onValidate}
        showErrors={showErrors}
        readOnly={!isDisplayableEditable || !isEditable}
    />
}