
import { defineMessages } from 'react-intl';

export default defineMessages({
    ConditionsAndExclusions: {
        "id": "quoteandbind.cu.wizard.step.Risk Details Page.ConditionsAndExclusions",
        "defaultMessage": "Conditions And Exclusions"
    },
    AddConditionsAndExclusions: {
        "id": "quoteandbind.cu.wizard.step.Risk Details Page.Add Conditions and Exclusions",
        "defaultMessage": "Add Conditions and Exclusions"
    },
});