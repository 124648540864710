
import React from 'react';

import { RTCoveragesPage } from 'wni-capability-quoteandbind-rt-react';

function RTCoveragesChangePage(props) {

    return (
        <RTCoveragesPage {...props} />
    );

}

RTCoveragesChangePage.propTypes = RTCoveragesPage.propTypes;
RTCoveragesChangePage.defaultProps = RTCoveragesPage.defaultProps;

export default RTCoveragesChangePage;