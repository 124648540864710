import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { UrlUtil } from 'wni-portals-util-react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import { WALWatercraftService } from 'wni-capability-quoteandbind-wal';
import {
    minYear,
    OTHER,
    itemToBeInsuredConfig,
    OUTBOARD_MAP,
    requiredForIssuanceData,
    basePathMap,
} from "../config/Watercraft.static";
import messages from '../WALWatercraftsPage.messages';

const getAvailableYears = (systemDate) => {
    const currentYear = systemDate? moment(systemDate).year() : (new Date().getFullYear());
    const yearRange = _.range(currentYear + 1, minYear - 1);
    // return yearRange.map((year) => ({
    //     code: year,
    //     name: year
    // }));
    return yearRange.map(_.toString).map((year) => ({
        code: year,
        name: year
    }));
};

const getAvailableList = (options) => {
    return options.map((item) => {
        return {
            code: item,
            name: item
        }
    });
};

const getAvailableFromPath = (vm, path) => {
    const options = _.get(vm.value, path ) || [];
    return getAvailableList(options);
};
const getAvailableFromVM = (vm, path, translator) => {
    const options = _.get(vm,  `${path}.aspects.availableValues`) || []
    return options.map((item) => {
        return {
            code: item.code,
            name: translator({ id: item.name })
        }
    });
};

const postOnChangeAction = async(params, jobID, sessionUUID, authHeader) => {
    const res = await WALWatercraftService.postOnChangeAction(params, jobID, sessionUUID, authHeader);
    return res;
}
const getAvailableManufacturers = async (params, jobID, sessionUUID, authHeader) => {
    const res = await WALWatercraftService.postOnChangeAction(params, jobID, sessionUUID, authHeader);
    return res;
};

const getAvailableModels = async (params, jobID, sessionUUID, authHeader) => {
    const res = await WALWatercraftService.postOnChangeAction(params, jobID, sessionUUID, authHeader);
    return res;
};

const getPriceDigestsData = async (requestParams, jobID, sessionUUID, authHeader) => {
    try {
        const res = await WALWatercraftService.postOnChangeAction(requestParams, jobID, sessionUUID, authHeader)
        return res;
    } catch (ex) {
        return {};
    }
};

const sectionVisible = (itemIsured, walFlowType) => {
    const sectionData = itemToBeInsuredConfig.find((item) => item.value === itemIsured);
    if(!walFlowType) {
        return sectionData
    }
    // judging whether it is 'inboard' or 'outboard'
    const isOutBoard = _.includes(OUTBOARD_MAP, walFlowType)
    
    if (['watercraft_motor_trailer', 'watercraft_motor'].includes(itemIsured) && !isOutBoard) {
        _.set(sectionData, 'sectionConfig.motorDetail', false);
    }
    if (['watercraft_motor_trailer', 'watercraft_motor'].includes(itemIsured) && isOutBoard) {
        _.set(sectionData, 'sectionConfig.motorDetail', true);
    }
    return sectionData
};

const tooltipForcoverageAmount = (translator, sectionType) => {
    return (
        <ul className='tooltipList'>
            <li>{translator(messages.coverageAmountTitle1)}</li>
            <li>{translator(messages.coverageAmountMsg1)}</li>
            <li>{translator(messages.coverageAmountTitle2)}</li>
            <li>{translator(messages.coverageAmountMsg2)}</li>
            {sectionType === 'watercraft' && <li>{translator(messages.coverageAmountTitle3)}</li>}
        </ul>
    )
};

const tooltipForItemInsured = (translator) => {
    return (
            <>
                {translator(messages.itemInsuredMsg, {
                    here: <LinkComponent onClick={() => window.open(UrlUtil.walVehicleTypesAdvisor)} >here</LinkComponent>
                })}
            </>
            )
};

const setDefaultObj = () => {
    return {
        watercraftDetail: {
            selectedWatercraftUsages: undefined,
            selectedWatercraftKepts: undefined,
            selectedWatercraftApplies: undefined,
            watercraftDetailType: 'watercraft',
            isPhysicalDamageCovRequired: true
        },
        motorDetail: {
            watercraftDetailType: 'motor'
        },
        trailerDetail: {
            watercraftDetailType: 'trailer'
        }

    };
};


/**
 * Checks whether field "lossSettlementValuationMethod" should be visible on page
 * @param {Object} walWatercraftDetailDTO 
 * @returns {boolean}
 */
const isLossSettlementValuationMethodVisible = (walWatercraftDetailDTO) => {
    const isVisible = !!_.get(walWatercraftDetailDTO, 'isPhysicalDamageCovRequired')
    return isVisible;
}

const isFieldVisible = (currentRow, fieldPath, basePath, baseData, isReset = true) => {
    const {
        baseState_Ext: baseState
    } = baseData;
    let isVisible = true;
    const vm = currentRow[basePath] || {};
    const itemToBeInsured = _.get(currentRow, 'value.itemToBeInsured_Ext');
    const description = _.get(currentRow, 'value.watercraftDetail.description');
    const categoryName = _.get(currentRow, 'value.watercraftDetail.categoryName');
    const walFlowType = _.get(currentRow, 'value.walCategoryFlowType');
    let isFieldReset = true;
    switch (fieldPath){
        case "otherManufacturer":
            isVisible = _.get(vm.value, 'manufacturer') === OTHER;
            break;
        case "otherModel":
            isVisible = _.get(vm.value, 'model') === OTHER;
            break;
        case "drive":
            isVisible = ['Personal Watercraft', 'Inboard/Outdrive'].includes(description)
            break;
        case "registrationStateExplain":
            isVisible =  !!_.get(vm.value, 'registrationState') && _.get(vm.value, 'registrationState') !== baseState
            break;
        case "motorType":
            if(basePath === 'watercraftDetail') {
                isVisible = ['STERN-IO', 'Sailboats'].includes(categoryName) || ['Inboard/Outdrive', 'Sail Boats'].includes(description) 
                || (categoryName === 'Pontoon Deck and Houseboats' && ['watercraft_motor_trailer_inboard', 'watercraft_motor_inboard'].includes(walFlowType))
            }
            break;
        case "motorIdentificationNumber":
            isVisible = ['watercraft_motor_trailer_inboard', 'watercraft_motor_inboard'].includes(walFlowType) && _.get(vm.value, 'motorType') !== "no_motor"
            break;
        case "purchaseDate":
            if(basePath === 'motorDetail') {
                isVisible = itemToBeInsured === 'motor'
            }
            if(basePath === 'trailerDetail') {
                isVisible = itemToBeInsured === 'trailer';
            }
            break;
        case "registrationState":
            if(basePath === 'motorDetail') {
                isVisible = ['watercraft_motor_outboard', 'motor', 'watercraft_motor_trailer_outboard'].includes(walFlowType);
            } else if(basePath === 'trailerDetail') {
                isVisible = ['trailer', 'watercraft_motor_trailer_outboard', 'watercraft_motor_trailer_inboard'].includes(walFlowType);
            } else{
                isVisible = ['watercraft', 'watercraft_motor', 'watercraft_motor_trailer'].includes(itemToBeInsured);
            }
            break;
        case "registrationStateOtherExplain":
            isVisible = _.get(vm.value, 'registrationStateExplain') === 'other';
            break;
        case "engineHorsepower":
            if(basePath === 'watercraftDetail') {
                isVisible = ['STERN-IO', 'Personal Watercraft', 'Pontoon Deck and Houseboats'].includes(categoryName) || ['Inboard/Outdrive', 'Personal Watercraft'].includes(description)
            }
            if(basePath === 'motorDetail'){
                isVisible = ['watercraft_motor_trailer_outboard', 'watercraft_motor_outboard', 'motor'].includes(walFlowType)
            }
            isFieldReset = false;
            break;
        case "fuelType": 
            if(basePath === 'watercraftDetail') {
                isVisible = ['STERN-IO', 'Personal Watercraft'].includes(categoryName) || ['Inboard/Outdrive', 'Personal Watercraft'].includes(description)
            }
            isFieldReset = false;
            break;
        case "isPhysicalDamageCovRequired": 
            if(basePath === 'motorDetail') {
                isFieldReset = false;
                isVisible = itemToBeInsured === 'motor'
            } 
            if(basePath === 'trailerDetail'){
                isFieldReset = false;
                isVisible = itemToBeInsured === 'trailer';
            }
            break;
        case "currentMarketValue":
            isVisible = !!_.get(vm.value, 'isPhysicalDamageCovRequired');
            // isFieldReset = !_.get(vm.value, 'priceDigestsDetails.currentMarketValue');
            break;
        case "lossSettlementValuationMethod":
            // isVisible = !!_.get(vm.value, 'isPhysicalDamageCovRequired')
            isVisible = isLossSettlementValuationMethodVisible(vm.value);
            break;
        case "coverageAmount":
            const lossMethodValue = _.get(vm.value, 'lossSettlementValuationMethod');
            isVisible = ['replacement_cost', 'agreed_value'].includes(lossMethodValue)
            break;
        // info
        case "watercraftKeptExplain":
            isVisible = _.get(vm.value, 'selectedWatercraftKepts', []).includes(_.lowerCase(OTHER));
            break;
        case "primarilyUsedCounty": 
            const selectedWatercraftKepts = _.get(vm.value, 'selectedWatercraftKepts', []);
            const keptsFilter = selectedWatercraftKepts.filter((item) => item !== 'primary_residence');
            isVisible = !_.isEmpty(keptsFilter)
            break;
        case "hasMotorBeenModified":
            if(basePath === 'watercraftDetail') {
                isVisible = ['watercraft_motor_trailer', 'watercraft_motor'].includes(itemToBeInsured) 
                && _.get(vm.value, 'motorType') !== "no_motor"
            } 
            if(basePath === 'motorDetail') {
                isVisible = itemToBeInsured === 'motor'
            }
            break;
        case "hasMotorBeenModifiedExplain":
            isVisible = !!_.get(vm.value, 'hasMotorBeenModified')
            break;
        case "isThereExistingDamageExplain":
            isVisible = !!_.get(vm.value, 'isThereExistingDamage')
            break;
        case "coOwnerName":
            isVisible = !!_.get(vm.value, 'hasCoOwner')
            break;
        case "hasPhysicalDamageLast12Months": 
            const purchaseDate = _.get(vm.value, 'purchaseDate');
            const editEffectiveData = _.get(vm.value, 'createTime');
            const purchaseDateStr = purchaseDate ? moment(purchaseDate).format('MM/DD/YYYY') : null;
            const editEffectiveDataStr = editEffectiveData ? moment(editEffectiveData).format('MM/DD/YYYY') : null;
            isVisible = !!_.get(vm.value, 'isPhysicalDamageCovRequired') && purchaseDate && purchaseDateStr !== editEffectiveDataStr;
            break;
        case "hasAnyAdditionalInfoExplain":
            isVisible = !!_.get(vm.value, 'hasAnyAdditionalInfo')
            break;
        case "isUsedForBusiPurposesExplain": 
            isVisible = !!_.get(vm.value, 'isUsedForBusinessPurposes')
            break;
        default:
            isVisible = true;
    }
    if(!isVisible && isReset && isFieldReset) {
        _.set(currentRow.value, `${basePath}.${fieldPath}`, undefined);
    }
    return !!isVisible
};

const generateIssuanceValid = (vm, baseData) => {
    const issuanceMaps = [];
    
    const itemToBeInsured = _.get(vm, 'value.itemToBeInsured_Ext');
    const watercraftDescription = _.get(vm, 'value.watercraftDetail.description');
    const walFlowType = _.get(vm, 'value.walCategoryFlowType') || watercraftDescription;
    const { sectionConfig } = sectionVisible(itemToBeInsured, walFlowType);
    const visibleSection = _.keys(_.pickBy(sectionConfig, (item) => item));
    // const issuanceSection = _.intersection(visibleSection, basePathMap);
    _.mapKeys(visibleSection, (key) => {
        const data = requiredForIssuanceData[key] || [];
        data.forEach((fieldPath) => {
            let basePath = key;
            if(key === 'watercraftInfo') {
                basePath = 'watercraftDetail';
            };
            if(key === 'motorInfo') {
                basePath = 'motorDetail';
            };
            if(key === 'trailerInfo') {
                basePath = 'trailerDetail';
            }
            const isVisible = isFieldVisible(vm, fieldPath, basePath, baseData, false);
            issuanceMaps.push({
                path: `${basePath}.${fieldPath}`,
                visible: isVisible
            })
        })
    });
    const newIssuanceMaps = issuanceMaps.filter((item) => _.get(item, 'visible'));

    const invalidIssuanceFields = [];
    newIssuanceMaps.forEach((item) => {
        const { path } = item;
        const fieldValue = _.get(vm, `value.${path}`);
        const isCurrencyField = _.isObject(fieldValue) && _.get(fieldValue, 'currency');
        if (isCurrencyField && (_.isNil(_.get(fieldValue, 'amount')) || _.get(fieldValue, 'amount') === '')) {
            invalidIssuanceFields.push(path);
        }
        if (_.isNil(fieldValue) || fieldValue.length === 0) {
            invalidIssuanceFields.push(path);
        }
    });
    return invalidIssuanceFields;
};


// ===========================================
const isOutboardWatercraft = (walWatercraftDTO) => {
    const {
        walCategoryFlowType,
        watercraftDetail: {
            description,
        } = {}
    } = walWatercraftDTO;
    const watercraftFlowType = walCategoryFlowType || description;
    const isOutboard = _.includes(OUTBOARD_MAP, watercraftFlowType);
    return isOutboard;
}

const getValuationMethodOptions = (walWatercraftDetailDtoVM, effectiveDate, translator, disabled) => {
    if(!walWatercraftDetailDtoVM.value) {
        return []
    };
    const {
        year,
        lossSettlementValuationMethod
    } = walWatercraftDetailDtoVM.value;
    const yearNumber = _.toNumber(year);
    const options = getAvailableFromVM(walWatercraftDetailDtoVM, 'lossSettlementValuationMethod', translator);
    if (disabled) {
        return options;
    }
    const effectiveYear = moment(effectiveDate).get('Y');
    if( effectiveYear - yearNumber <= 2 ) {
        return options;
    }
    const filterOptions = options.filter((item) => item.code !== 'replacement_cost');
    return filterOptions;
};

const syncCoverageAmountValueAndMsg = (walWatercraftDTO) => {
    basePathMap.forEach((section) => {
        const coverageAmount = _.get(walWatercraftDTO, `${section}.coverageAmount`, {});
        const currentMarketValue = _.get(walWatercraftDTO, `${section}.currentMarketValue`, {});
        const lossSettlementValuationMethod = _.get(walWatercraftDTO, `${section}.lossSettlementValuationMethod`);
        const isCompare = lossSettlementValuationMethod === 'replacement_cost' && coverageAmount.amount && currentMarketValue.amount;
        if(isCompare && coverageAmount.amount < currentMarketValue.amount) {
            _.set(walWatercraftDTO, `${section}.coverageAmount`, currentMarketValue);
            _.set(walWatercraftDTO, `${section}.isCoverageAmountWarning`, true);
        }else{
            _.set(walWatercraftDTO, `${section}.isCoverageAmountWarning`, false);
        }
    })
};

const syncPhysicalDamageCovRequired = (walWatercraftDTO, updateMotorOrTrailer) => {
    if(!walWatercraftDTO) {
        return false;
    }
    const itemToBeInsured = _.get(walWatercraftDTO, 'itemToBeInsured_Ext');
    const isPhysicalDamageCovRequiredForWatercraft = _.get(walWatercraftDTO, 'watercraftDetail.isPhysicalDamageCovRequired');
    const lossSettlementValuationMethodForWatercraft = _.get(walWatercraftDTO, 'watercraftDetail.lossSettlementValuationMethod');
    // if(['watercraft_motor_trailer', 'watercraft_motor'].includes(itemToBeInsured)) {
        
    // }
    if(!['watercraft_motor_trailer', 'watercraft_motor'].includes(itemToBeInsured)) {
        return false;
    };
    _.set(walWatercraftDTO, 'motorDetail.isPhysicalDamageCovRequired', isPhysicalDamageCovRequiredForWatercraft)
    _.set(walWatercraftDTO, 'trailerDetail.isPhysicalDamageCovRequired', isPhysicalDamageCovRequiredForWatercraft)
    if(lossSettlementValuationMethodForWatercraft) {
        _.set(walWatercraftDTO, 'motorDetail.lossSettlementValuationMethod', lossSettlementValuationMethodForWatercraft);
        _.set(walWatercraftDTO, 'trailerDetail.lossSettlementValuationMethod', lossSettlementValuationMethodForWatercraft);
    }
    
   
    if(updateMotorOrTrailer) {
        const section = ['motorDetail', 'trailerDetail'];
        section.forEach((itemPath) => {
            const msrp = _.get(walWatercraftDTO, `${itemPath}.priceDigestsDetails.msrp`);
            const currentMarketValue = _.get(walWatercraftDTO, `${itemPath}.priceDigestsDetails.currentMarketValue`);
            const lossSettlementValuationMethod = _.get(walWatercraftDTO, `${itemPath}.lossSettlementValuationMethod`);
            if(lossSettlementValuationMethod === 'replacement_cost' && msrp) {
                _.set(walWatercraftDTO, `${itemPath}.coverageAmount`, msrp);
            }
            if(lossSettlementValuationMethod === 'agreed_value' && currentMarketValue) {
                _.set(walWatercraftDTO, `${itemPath}.coverageAmount`, currentMarketValue);
            }
        });
    }
};

export {
    getAvailableYears,
    getAvailableList,
    getAvailableManufacturers,
    getAvailableModels,
    getPriceDigestsData,
    postOnChangeAction,
    getAvailableFromPath,
    getAvailableFromVM,
    sectionVisible,
    tooltipForcoverageAmount,
    isFieldVisible,
    setDefaultObj,
    generateIssuanceValid,
    //
    isLossSettlementValuationMethodVisible,
    isOutboardWatercraft,
    getValuationMethodOptions,
    //
    syncCoverageAmountValueAndMsg,
    syncPhysicalDamageCovRequired,
    tooltipForItemInsured,
}