import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { HOCoveragesService } from 'wni-capability-quoteandbind-ho';
import HODPCoveragesBasePage from './HODPCoveragesBasePage';
import HOCoveragesConfig from './HOCoverages.config';

function HOCoveragesPage(props) {
    const {
        // currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        updateWizardData
    } = props;

    return <HODPCoveragesBasePage
        wizardData={submissionVM}
        updateWizardData={updateWizardData}
        lobName='homeowners'
        coveragesConfig={HOCoveragesConfig}
        coveragesService={HOCoveragesService}
    />
}

HOCoveragesPage.propTypes = wizardProps;
export default HOCoveragesPage;
