import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpCoverageService'), method, data, additionalHeaders);
}

export default class CPCoverageService {

    /**
     * Invokes CPCoveragesHandler.getLineClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLineClausesWithSync(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getLineClausesWithSync', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.getLineClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLineClauses(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getLineClauses', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.updateLineClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {dto} lineClausesDTOForUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateLineClauses(jobID, sessionUUID, dto, authHeader = {}) {
        return processSubmission('updateLineClauses', [jobID, sessionUUID, dto], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.onCommercialPropertyPageNext()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onCommercialPropertyPageNext(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('onCommercialPropertyPageNext', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchLineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchLineCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, authHeader = {}) {
        return processSubmission('searchLineCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedLineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedLineCoverages(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedLineCoverages', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchStateSpecificCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} jurisdictionCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchStateSpecificCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, jurisdictionCode, authHeader = {}) {
        return processSubmission('searchStateSpecificCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, jurisdictionCode], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedStateSpecificCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clauseSpecData
     * @param {string} jurisdictionCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedStateSpecificCoverages(jobID, sessionUUID, clauseSpecData, jurisdictionCode, authHeader = {}) {
        return processSubmission('addSearchedStateSpecificCoverages', [jobID, sessionUUID, clauseSpecData, jurisdictionCode], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchLocationCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
        static searchLocationCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationID, authHeader = {}) {
            return processSubmission('searchLocationCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationID], authHeader);
        }
    
    /**
     * Invokes CPCoveragesHandler.addSearchedLocationCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clauseSpecData
     * @param {string} locationPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedLocationCoverages(jobID, sessionUUID, clauseSpecData, locationID, authHeader = {}) {
        return processSubmission('addSearchedLocationCoverages', [jobID, sessionUUID, clauseSpecData, locationID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchBuildingCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchBuildingCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID,  buildingPublicID, authHeader = {}) {
        return processSubmission('searchBuildingCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID, buildingPublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedBuildingCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {array} clauseSpecData
     * @param {string} locationPublicID 
     * @param {string} buildingPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedBuildingCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, authHeader = {}) {
        return processSubmission('addSearchedBuildingCoverages', [jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchSpecialClassCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchSpecialClassCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID,  specialClassPublicID, authHeader = {}) {
        return processSubmission('searchSpecialClassCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID, specialClassPublicID], authHeader);
    }
    
    /**
     * Invokes CPCoveragesHandler.addSearchedSpecialClassCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {array} clauseSpecData
     * @param {string} locationPublicID 
     * @param {string} specialClassPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedSpecialClassCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, specialClassPublicID, authHeader = {}) {
        return processSubmission('addSearchedSpecialClassCoverages', [jobID, sessionUUID, clauseSpecData, locationPublicID, specialClassPublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchSpecClsBICoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {string} specialClassBusinessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchSpecClsBICoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID,  specialClassPublicID, specialClassBusinessIncomePublicID, authHeader = {}) {
        return processSubmission('searchSpecClsBICoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedSpecClsBICoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {array} clauseSpecData
     * @param {string} locationPublicID 
     * @param {string} specialClassPublicID
     * @param {string} specialClassBusinessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedSpecClsBICoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID, authHeader = {}) {
        return processSubmission('addSearchedSpecClsBICoverages', [jobID, sessionUUID, clauseSpecData, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchOccupancyCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchOccupancyCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID,  buildingPublicID, occupancyPublicID, authHeader = {}) {
        return processSubmission('searchOccupancyCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID, buildingPublicID, occupancyPublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedOccupancyCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {array} clauseSpecData
     * @param {string} locationPublicID 
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedOccupancyCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, occupancyPublicID, authHeader = {}) {
        return processSubmission('addSearchedOccupancyCoverages', [jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, occupancyPublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchBusinessIncomeCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchBusinessIncomeCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID,  buildingPublicID, businessIncomePublicID, authHeader = {}) {
        return processSubmission('searchBusinessIncomeCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID, buildingPublicID, businessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedBusinessIncomeCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {array} clauseSpecData
     * @param {string} locationPublicID 
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedBusinessIncomeCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader = {}) {
        return processSubmission('addSearchedBusinessIncomeCoverages', [jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, businessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.searchPersonalPropertyCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchPersonalPropertyCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID,  buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader = {}) {
        return processSubmission('searchPersonalPropertyCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.addSearchedPersonalPropertyCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {array} clauseSpecData
     * @param {string} locationPublicID 
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedPersonalPropertyCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader = {}) {
        return processSubmission('addSearchedPersonalPropertyCoverages', [jobID, sessionUUID, clauseSpecData, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID], authHeader);
    }

}