import React, {
    useEffect,
    useContext,
} from "react";
import _ from 'lodash'
import { ValueType } from "../../../../util/WCScheduleItemsUtil";
import CoverageUtil from '../../../../util/WCCoverageUtil'
import SingleClauseContext from '../../../../context/WCSingleClauseContext'
import WCComplexSchedulePopupPropertyInfoDropdown from "./components/WCComplexSchedulePopupPropertyInfoDropdown";
import WCComplexSchedulePopupPropertyInfoTextArea from "./components/WCComplexSchedulePopupPropertyInfoTextArea";
import WCComplexSchedulePopupPropertyInfoInteger from "./components/WCComplexSchedulePopupPropertyInfoInteger";
import WCComplexSchedulePopupPropertyInfoInputText from "./components/WCComplexSchedulePopupPropertyInfoInputText";
import WCComplexSchedulePopupPropertyInfoBigDecimal from "./components/WCComplexSchedulePopupPropertyInfoBigDecimal";


const WCComplexSchedulePopupPropertyInfoField = (props) => {

    const {
        propertyInfo,
        openedScheduleItem,
        openedScheduleItemIndex,
        updateSubmissionVMToServer,
        onValidate,
        showErrors,
        setIsEditing,
    } = props

    const {
        clauseCode,
    } = useContext(SingleClauseContext)

    const {
        id,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo
    const {
        itemData: {
            [id]: scheduleItemDataDTO
        }
    } = openedScheduleItem
    
    useEffect(() => {
        const scheduleItemValidationID = `${clauseCode}_scheduleItem_${openedScheduleItemIndex}_${id}`
        if (onValidate) {
            onValidate(!CoverageUtil.isScheduledItemDataInvalid(scheduleItemDataDTO, propertyInfo), scheduleItemValidationID);
        }
        return () => {
            if (onValidate) {
                onValidate(true, scheduleItemValidationID);
            }
        }
    }, [clauseCode, id, onValidate, openedScheduleItemIndex, propertyInfo, scheduleItemDataDTO])

    if (_.isNil(scheduleItemDataDTO) || _.get(scheduleItemDataDTO, 'visible_Ext') === false) {
        return null
    }

    const commmonProps = {
        propertyInfo,
        openedScheduleItemIndex,
        openedScheduleItem,
        updateSubmissionVMToServer,
        showErrors,
        setIsEditing,
    }

    switch (schedulePropertyInfoType) {
        case ValueType.Option:
        case ValueType.AdditionalInsured:
        case ValueType.AdditionalInterest:
        case ValueType.ForeignKey:
        case ValueType.ForeignKeyWithOptionLabels:
        case ValueType.PolicyContact:
        case ValueType.TypeKey:
            return <WCComplexSchedulePopupPropertyInfoDropdown
                {...commmonProps}
            />
        case ValueType.TextArea:
            return <WCComplexSchedulePopupPropertyInfoTextArea
                {...commmonProps}
            />
        case ValueType.Shorttext:
        case ValueType.String:
            return <WCComplexSchedulePopupPropertyInfoInputText
                {...commmonProps}
            />
        // case ValueType.Direct:
        case ValueType.BigDecimal:
            return <WCComplexSchedulePopupPropertyInfoBigDecimal
                {...commmonProps}
            />
        case ValueType.Integer:
        case ValueType.IntegerRange:
        case ValueType.AutoNumber:
            return <WCComplexSchedulePopupPropertyInfoInteger
                {...commmonProps}
            />
        // case ValueType.MONEY_EXT:
        //     return <ScheduleItemEditableCellCurrency
        //         {...commonEditableCellComponentProps}
        //     />
        // case ValueType.DateTime:
        //     return <ScheduleItemEditableCellDate
        //         {...commonEditableCellComponentProps}
        //     />
        // case ValueType.Date:
        //     return <ScheduleItemEditableCellLocalDateObj
        //         {...commonEditableCellComponentProps}
        //     />
        // case ValueType.Bit:
        // case ValueType.Boolean:
        //     return <ScheduleItemEditableCellBoolean
        //         {...commonEditableCellComponentProps}
        //     />
        default:
            return <div>Unsupport type editable cell</div>
    }
}

export default WCComplexSchedulePopupPropertyInfoField