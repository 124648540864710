import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    contactName: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.Name",
        defaultMessage: "Name"
    },
    firstName: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.First Name",
        defaultMessage: "First Name"
    },
    lastName: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.Last Name",
        defaultMessage: "Last Name"
    },
    relationshipToInsured: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.relationshipToInsured",
        defaultMessage: "Relationship to Insured"
    },
    dateOfBirth: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.dateOfBirth",
        defaultMessage: "Date of Birth"
    },
    additionalNamedInsured: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.additionalNamedInsured",
        defaultMessage: "Additional Named Insured?"
    },
    westernNationalEmployee: {
        id: "wni.quoteandbind.ho-wizard.ho-householdinfo.householdpopup.western National Employee Discount",
        defaultMessage: "Western National Employee?"
    }
});
