import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import HOCoverageUtil from '../../util/HOCoverageUtil';
import metadata from './HOExclusions.metadata.json5';
import styles from './HOExclusions.module.scss';
import HOCoverageDetailsPopup from '../../components/HOCoverageDetailsPopup/HOCoverageDetailsPopup';
import HOClausesComponentVM from '../../components/HOClausesComponentVM/HOClausesComponentVM';
import HOSingleClauseComponentVM from '../../components/HOSingleClauseComponentVM/HOSingleClauseComponentVM';

function HOExclusions (props) {
    const modalApi = useWniModal();
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        isEditable,
        isEditing,
        showErrors,
        lobName,
        coveragesConfig,
        coveragesService,
    } = props;

    const {
        quoteID,
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const [loadingExclusions, setLoadingExclusions] = useState(false);

    const getExclusions = useCallback(() => {
        const exclusions = _.get(selectedVersion, 'coverages.exclusion_Ext', []);
        return exclusions;
    }, [selectedVersion]);

    const exclusionsVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.exclusion_Ext`;
    const exclusionsPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.exclusion_Ext`;
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const exclusions = getExclusions();

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = HOCoverageUtil.setClauseValue(exclusions, exclusionsVMPath, value, changedPath);
            _.set(submissionVM.value, exclusionsPath, newCoverages);
            updateWizardData(submissionVM);
            return submissionVM;
        },
        [exclusions, exclusionsVMPath, submissionVM, exclusionsPath, updateWizardData]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM, updateWithLoader = true) => {
        const coverages = _.get(newSubmissionVM.value, lobCoveragesPath);
        const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        if (updateWithLoader) {
            const updatedCoveragePublicIDs = HOCoverageUtil
                .getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
            setLoadingExclusions(updatedCoveragePublicIDs);
        }
        const response = await coveragesService.updateCoverages(
            quoteID || jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        _.set(newSubmissionVM.value, lobCoveragesPath, lobCoverages);
        setLoadingExclusions(false);
        updateWizardData(newSubmissionVM);
        return newSubmissionVM;
    }, [authHeader, jobID, lobCoveragesPath, quoteID, sessionUUID, updateWizardData, lobName, coveragesService]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const onScheduleChange = useCallback(
        async (value, changedPath, updateWithLoader = true) => {
            const newSubmission = changeSubmission(value, changedPath);
            const newSubmissionVMBySync = await updateSubmissionVMToServer(newSubmission, updateWithLoader);
            const valuePathToSyncedSchedule = HOCoverageUtil.getValuePathByVMPath(changedPath)
            const updatedSchedule = _.get(newSubmissionVMBySync.value, valuePathToSyncedSchedule);
            return updatedSchedule;
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const showCoverageDetailsPopup = useCallback(
        (coverage) => {
            const componentProps = {
                coverage,
                quoteID,
                jobID,
                sessionUUID,
                coverageType: 'exclusion_Ext',
                isEditable,
                lobName,
                coveragesConfig,
                coveragesService,
                // lobCoveragesPath,
                submissionVM,
                // updateSubmissionVM: updateWizardData
            };
            return modalApi.showModal(
                <HOCoverageDetailsPopup {...componentProps} />
            );
        }, [
            quoteID,
            jobID,
            sessionUUID,
            isEditable,
            lobName,
            coveragesConfig,
            coveragesService,
            // lobCoveragesPath,
            submissionVM,
            // updateWizardData
        ]
    )

    const onOpenCoverageDetailsPopup = (clausePatternCode) => {
        const coverageToOpen = exclusions
            .find((coverage) => _.get(coverage, 'code_Ext') === clausePatternCode)
        showCoverageDetailsPopup(coverageToOpen)
            .then(async (updatedCoverage) => {
                const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO({
                    // eslint-disable-next-line camelcase
                    exclusion_Ext: [updatedCoverage]
                }, lobName);
                const updatedCoveragePublicIDs = HOCoverageUtil
                    .getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
                setLoadingExclusions(updatedCoveragePublicIDs);
                const response = await coveragesService.updateCoverages(
                    quoteID || jobID,
                    sessionUUID,
                    clausesToUpdate,
                    authHeader
                )
                const lobCoverages = _.get(response, lobName);
                _.set(submissionVM.value, lobCoveragesPath, lobCoverages);
                setLoadingExclusions(false);
                updateWizardData(submissionVM);
            })
            .catch(() => _.noop)
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable
            },
            exclusions: {
                loadingClause: loadingExclusions,
                path: exclusionsVMPath,
                value: exclusions,
                hideCheckBoxForRequired: true,
                componentMapOverrides: {
                    HOSingleClauseComponentVM: HOSingleClauseComponentVM,
                },
                showErrors,
                isEditing,
                coveragesConfig,
            },
        };
    }, [
        // coveragesDisplayOnToggleButonCoveragesComponent,
        loadingExclusions,
        exclusionsVMPath,
        exclusions,
        showErrors,
        isEditable,
        isEditing,
        coveragesConfig,
        // onAddSelectedCoverages
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: onScheduleChange,
            onChangeClause: changeSubmission,
            onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
        },
        resolveComponentMap: {
            HOClausesComponentVM: HOClausesComponentVM,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

HOExclusions.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
};

HOExclusions.defaultProps = {
    isEditable: true,
    showErrors: false,
};

export default HOExclusions;
