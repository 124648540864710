import { Card } from '@jutro/components';
import React, { useCallback, useEffect } from 'react';
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import CoverageContext from '../context/GLCoverageContext';
import GLCoverageUtil from '../util/GLCoverageUtil';
import GLClausesComponentVM from '../components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';
import messages from './GLSingleSublineDetailsAndStandardCoveragesAccordionCard.messages'
import GLDetails from '../components/GLDetails/GLDetails';

import { AccordionCard } from '@jutro/legacy/components';

const GLSingleSublineDetailsAndStandardCoveragesAccordionCard = (props) => {
    const {
        sublineClauses,
        setSublineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: multiSublinesOnValidate,
        setUnmannedAircraftAvailable,
        loadingClauses,
        setLoadingClauses,
        peReferredUWIssue,
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const translator = useTranslator();

    const {
        publicID,
        sublineName,
        standardCoverages = [],
        details = [],
    } = sublineClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const validationID = `SingleSublineStandardCoverage_${publicID}`

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (multiSublinesOnValidate) {
            multiSublinesOnValidate(isComponentValid, validationID);
        }
        return () => {
            if (multiSublinesOnValidate) {
                multiSublinesOnValidate(true, validationID);
            }
        }
    }, [isComponentValid, multiSublinesOnValidate, validationID])

    const changeClauses = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(standardCoverages, 'standardCoverages', value, changedPath);
            const newSublineClauses = _.clone(sublineClauses)
            _.set(newSublineClauses, 'standardCoverages', newClauses);
            setSublineClauses(newSublineClauses);
            setIsEditing(false);
            return newSublineClauses;
        },
        [standardCoverages, setIsEditing, setSublineClauses, sublineClauses]
    );

    const changeDetails = useCallback((displayable, index) => {
        const newSublineClauses = _.clone(sublineClauses)
        _.set(newSublineClauses, `details[${index}]`, displayable);
        setSublineClauses(newSublineClauses);
        setIsEditing(false);
        return newSublineClauses;
    }, [setIsEditing, setSublineClauses, sublineClauses])

    const updateVMToServer = useCallback(async (newSublineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const subLineClausesToUpdate = GLCoverageUtil.generateUpdatedSingleSublineClausesDTO(newSublineClauses);
        const updatedSublineClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedSublineClausesDTO(subLineClausesToUpdate);
        setLoadingClausesFunc(updatedSublineClausesPublicIDs)
        setLoadingMask(true)
        const response = await GLCoverageService.updateSingleSublineClauses(
            jobID,
            sessionUUID,
            subLineClausesToUpdate,
            authHeader
        )
        const sublineClausesFromServer = _.get(response, 'gl7SingleSublineClauses')
        const isUnmannedAircraftAvailable = _.get(response , 'isUnmannedAircraftAvailable')
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setSublineClauses(sublineClausesFromServer);
        setUnmannedAircraftAvailable(isUnmannedAircraftAvailable)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setSublineClauses, setUnmannedAircraftAvailable]);

    const updateNewSubLineClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);

    const changeDetailsAndSync = useCallback((displayable, index) => {
        const newSublineClauses = changeDetails(displayable, index)
        updateNewSubLineClausesToServer(newSublineClauses);
    }, [changeDetails, updateNewSubLineClausesToServer])

    const changeClausesAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeClauses(value, changedPath);
            
            updateNewSubLineClausesToServer(newSublineClauses);
        },
        [changeClauses, updateNewSubLineClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewSubLineClausesToServer(sublineClauses);
        },
        [sublineClauses, updateNewSubLineClausesToServer]
    )

    return (
        <AccordionCard
            id={`${publicID}_StandardCoverages`}
            chevron
            className="coveragesAccordion"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={sublineName}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <Card
                        title={translator(messages.Details)}
                        className= "wizardSection"
                        headerClassName= "wizardTitle"
                    >
                        <GLDetails
                            entityPublicID={publicID}
                            details={details}
                            loadingClauses={loadingClauses}
                            changeDetailsAndSync={changeDetailsAndSync}
                            onValidate={onValidate}
                            showErrors={showErrors}
                            isEditable={isEditable}
                        />
                    </Card>
                    <Card
                        title={translator(messages.Coverages)}
                        className= "wizardSection"
                        headerClassName= "wizardTitle"
                    >
                        <GLClausesComponentVM
                            splitByClauseCategory={false}
                            sortBySelected
                            onSyncCoverages={onSyncCoverages}
                            onChangeSubmissionAndSync={changeClausesAndSync}
                            onChangeClause={changeClauses}
                            onValidate={onValidate}
                            showAmount={false}
                            loadingClause={loadingClauses}
                            path="standardCoverages"
                            value={standardCoverages}
                            showErrors={showErrors}
                            componentMapOverrides={{
                                GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                            }}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            isEditable={isEditable}
                            peReferredUWIssue={peReferredUWIssue}
                            hideCheckBoxForRequired
                        />
                    </Card>
                    
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default GLSingleSublineDetailsAndStandardCoveragesAccordionCard;
