import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { Icon } from '@jutro/components';
import { 
    useWniModal
} from 'wni-components-platform-react';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WindowUtil, QuoteUtil, IssuanceValidationUtil, ValidationIssueUtil } from 'wni-portals-util-js';

import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WALWatercraftService } from 'wni-capability-quoteandbind-wal';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import WatercraftComponent from './WatercraftComponent/WatercraftComponent';
import WizardPage from '../../templates/WALWizardPage';
import metadata from './WALWatercraftsPage.metadata.json5';
import messages from './WALWatercraftsPage.messages';
import {
    generateIssuanceValid,
    getPriceDigestsData,
    syncCoverageAmountValueAndMsg,
    syncPhysicalDamageCovRequired,
    getValuationMethodOptions
} from './util/Watercraft.util';
import { basePathMap, itemIsuredTypeKey, OTHER } from './config/Watercraft.static';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};
const PATH = 'lobData.watercraft.coverables.watercrafts';
const CHILDREN_PATH = `${PATH}.children`;

function WALWatercraftsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardPageData,
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        checkRequiredForIssuance,
        isReadOnly,
        currentStep
    } = props;

    const {
        jobID, 
        sessionUUID,
        baseData,
        lobData: {
            watercraft: {
                offerings
            }
        },
    } = submissionVM.value;

    const pageId = 'WALWatercraftsPage';
    const {
        selectedVersion_Ext: selectedVersionPublicID
    } = baseData;

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        invalidFields
    } = useValidation(pageId);
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { businessData_Ext: { systemDate } = {} } = {}} = useAuthentication();

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false)
    const [isSplitWarning, setSplitWarning] = useState(false);

    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);
    
    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'watercraftTable');
    };
    const updateSubmission = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allWatercrafts = _.get(submissionVM.value, PATH);
        const currentIndex = allWatercrafts.findIndex((item) => item.publicID === publicID); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const updateCurrentRow = async (rowData, updateMotorOrTrailer, updateSubmissionData = updateWizardData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        const {
            _dtoName,
            _xCenter,
        } = rowData;
        const initCurrentRow = viewModelService.create(rowData.value, _xCenter, _dtoName);
        await syncPhysicalDamageCovRequired(initCurrentRow.value, updateMotorOrTrailer);
        syncCoverageAmountValueAndMsg(initCurrentRow.value);
        updateCurrentRowInteranl(initCurrentRow);
        if(!isReadOnly) {
            const newSubmissionVM = updateSubmission(initCurrentRow);
            updateSubmissionData(newSubmissionVM)
        }
    };
   
    const syncWizardDataSnapshot = (currentVM, updateMotorOrTrailer) => {
        updateCurrentRow(currentVM, updateMotorOrTrailer, updateWizardSnapshot);
    };
    const syncWizardData = (currentVM, updateMotorOrTrailer) => {
        updateCurrentRow(currentVM, updateMotorOrTrailer, updateWizardData);
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        // const {
        //     _dtoName,
        //     _xCenter,
        // } = currentRow;
        // const initCurrentRow = viewModelService.create(currentRow.value, _xCenter, _dtoName);
        // console.log(initCurrentRow)
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [invalidFields]);

    const allWatercraftValid = () => {
        const allWatercraftVMs = _.get(submissionVM, CHILDREN_PATH);
        return allWatercraftVMs.every((vm) => vm.aspects.valid && vm.aspects.subtreeValid);
    };

    
    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const watercraftService = useCallback(async(serviceName, serviceData, isAdd) => {
        setLoadingMask(true);
        const watercraftData = _.get(submissionVM, `${PATH}.value`);
        const oldWatercrafts = _.cloneDeep(watercraftData) || [];
        const oldWatercraftIds = oldWatercrafts.map((v) => v.publicID);

        const res = await WALWatercraftService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        _.set(submissionVM, `${PATH}.value`, res.walWatercrafts);
        updateWizardSnapshot(submissionVM);
        updateSelection([]);
        setLoadingMask(false);
        updateShowErrors(false);
        // for add new watercraft
        if(isAdd) {
            const newWatercraft = _.get(submissionVM, CHILDREN_PATH).find((vm) => !oldWatercraftIds.includes(vm.value.publicID));
            updateCurrentRow(newWatercraft);
            setSplitWarning(true);
            return false;
        }
        generateValidationIssues(res.errorsAndWarnings);
        updateCurrentRow(null);
        setSplitWarning(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM, updateSelection]);

    const addWatercraft = async() => {
        watercraftService('updateWatercraft', {}, 'add');
    };
    useEffect(() => {
        if(_.isEmpty(_.get(submissionVM, `${PATH}.value`))) {
            addWatercraft();
        }
    }, []);
    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const cancelWatercraft = () => {
        resetWizardDataToSnapshot();
        updateCurrentRow(null);
        setSplitWarning(false);
    }

    const updateWatercraft = useCallback(async() => {
        if(!isComponentValid) {
            handleValidation();
            return false;
        };
        const splitWatercraft = await WALWatercraftService.isSplitWatercraft(jobID, sessionUUID, currentRow.value, authHeader)
        if(isSplitWarning && splitWatercraft.isSplit) {
            // to do show warning message
            const newValidationIssues = ValidationIssueUtil.getValidationIssues(splitWatercraft.errorsAndWarnings);
            updateValidationIssues(_.uniqBy(newValidationIssues, 'reason'));
            setSplitWarning(false);
            return false;
        }
        await watercraftService('updateWatercraft', currentRow.value);
        return true;
    }, [currentRow, handleValidation, isComponentValid, watercraftService]);

    const removeWatercraft = () => {
        modalApi.showConfirm({
            title: messages.removeWatercraftTitle,
            message: messages.removeWatercraftDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            updateCurrentRow(null);
            setSplitWarning(false);
            watercraftService('removeWatercraft', selection);
        })
    };
    const viewAndEdit = async(row, index) => {
        setLoadingMask(true);
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const watercraftVM = childrenVM.find((item) => item.value.publicID === index);
        const currentPublicID = _.get(watercraftVM.value, 'publicID');

        // if manufacturer and model has options, no need to call service again
        if(!_.get(watercraftVM.value, 'hasIntOptions') && !isReadOnly) {
            const res = await WALWatercraftService.viewEditWatercraft(currentPublicID, jobID, sessionUUID, authHeader)
            _.set(watercraftVM, 'value', res)
        }
        updateCurrentRow(watercraftVM);
        setSplitWarning(true);
        setLoadingMask(false);
    };
    const onNextWatercraft = useCallback(() => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        let index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        updateCurrentRow(null);
        const indexID = _.get(childrenVM[index], 'value.publicID');
        viewAndEdit(null, indexID)
        WindowUtil.scrollToTop()
    }, [currentRow, submissionVM]);

    const onPageNext = async () => {
        if(!allWatercraftValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await WALWatercraftService.onPageNext(requestData, authHeader);
        const lobCoveragePath = `lobData.watercraft.offerings[${selectedVersionIndex}].coverages`;
        const newLobCoverage = _.get(res, `lobCoverages.watercraft`);
        _.set(submissionVM.value, lobCoveragePath, newLobCoverage);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return submissionVM;
    };
    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.publicID === index) || {};
        const {
            effectiveDate_Ext: effectiveDate
        } = baseData;
        basePathMap.forEach((basePath) => {
            if(itemVM[basePath] && itemVM[basePath].value){
                getValuationMethodOptions(itemVM[basePath], effectiveDate, translator, false)
            }
        });
        const isItemValid = _.get(itemVM, 'aspects.valid') && _.get(itemVM, 'aspects.subtreeValid');
        let type;
        if(isItemValid) {
            const issuacneInValidField = generateIssuanceValid(itemVM, baseData);
            if(_.isEmpty(issuacneInValidField)) {
                type = 'success';
            } else {
                type = 'warning';
            }
        } else {
            type = 'error';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const renderDropDownCell = (data, index, {path, typeKey}) => {
        return data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-'
    };
    const renderCell = (data, index, { path }) => {
        const { 
            itemToBeInsured_Ext: itemToBeInsured
        } = data;
        let detailsPath;
        switch (itemToBeInsured) {
            case 'motor':
                detailsPath = 'motorDetail'
                break;
            case 'trailer':
                detailsPath = 'trailerDetail'
                break;
            default:
                detailsPath = 'watercraftDetail'
                break;
        };
        const detailsData = _.get(data, detailsPath, {});
        if(path === 'manufacturer' && detailsData[path] === OTHER) {
            return detailsData.otherManufacturer || '-'
        }
        if(path === 'model' && detailsData[path] === OTHER) {
            return detailsData.otherModel || '-'
        }
        return detailsData[path] || '-'
    };

    const changeModelAction = async (requestParams) => {
        setLoadingMask(true);
        const { 
            watercraftDetailType,
            walWatercraftDetail: {
                itemToBeInsured_Ext: beforeItemInsuredType
            } = {}
        } = requestParams;
        const res = await getPriceDigestsData(requestParams, jobID, sessionUUID, authHeader);
        const {
            walWatercraftDetail: {
                itemToBeInsured_Ext: newItemInsuredType,
                walCategoryFlowType: newCategoryFlowType
            } = {}
        } = res;
        const newCurrentRow = viewModelService.clone(currentRow);
        if(['watercraft_motor_trailer', 'watercraft_motor', 'watercraft'].includes(beforeItemInsuredType) && watercraftDetailType === 'watercraft') {
            // POI-31456: show warning message if item to be insured update
            const resInsuredType = translator({id: `${itemIsuredTypeKey}.${newItemInsuredType}`});
            const insuredTypeForPriceDigestValidation = {
                type: 'warning',
                insuredTypeBaseField: 'priceDigest',
                reason: translator(messages.BRPL0441, { itemToBeInsured: resInsuredType})
            }
            updateValidationIssues((prev) => {
                const filterPrev = prev.filter((item) => item.insuredTypeBaseField !== 'description' && item.insuredTypeBaseField !== 'priceDigest');
                const validations = [...filterPrev, insuredTypeForPriceDigestValidation];
                if(newItemInsuredType !== beforeItemInsuredType) {
                    return _.uniqBy(validations, 'reason')
                }
                return filterPrev;
            });
            // -------- end for POI-31456
            _.set(newCurrentRow.value, 'itemToBeInsured_Ext', newItemInsuredType);
           
        }
        _.set(newCurrentRow.value, 'walCategoryFlowType', newCategoryFlowType || watercraftDetailType);
        _.set(newCurrentRow.value, `${watercraftDetailType}Detail`, res.walWatercraftDetail);
        syncWizardDataSnapshot(newCurrentRow, 'updateMotorOrTrailer');
        setLoadingMask(false);
    };

    const writeValue = useCallback((value, path) => {
        if(currentRow) {
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(currentRow, path, undefined);
            } else {
                _.set(currentRow, path, value);
            }            
            syncWizardData(currentRow);
        }
    }, [currentRow, submissionVM, viewModelService]);

    const overrideProps = {
        '@all': {
            isRequiredForIssuance: isRequiredForIssuance,
            isReadOnly
        },
        '@field': {
            labelPosition: 'left'
        },
        addWatercraft: {
            disabled: currentRow,
            visible: !isReadOnly
        },
        removeWatercraft: {
            disabled: selection.length < 1,
            visible: !isReadOnly
        },
        watercraftTable: {
            selectedRows: selection,
            onSelectionChange: (rows) => {
                updateSelection(rows)
            },
            selectionType: isReadOnly ? 'none' : 'multi'
        },
        validationIcon: {
            renderCell: renderValidationCell
        },
        viewAndEditLink: {
            disabled: currentRow || selection.length > 1,
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        watercraftDetailContainer: {
            visible: currentRow != null,
        },
        watercraftDetails: {
            currentRow,
            onValueChange: writeValue,
            isComponentValid,
            onValidate: onValidate,
            syncWizardData,
            syncWizardDataSnapshot,
            disregardFieldValidation,
            changeModelAction,
            updateValidationIssues,
            showErrors,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
                baseData,
                systemDate
            }

        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: _.get(submissionVM, `${PATH}.value`).length > 1
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            addWatercraft,
            removeWatercraft,
            viewAndEdit,
            cancelWatercraft,
            saveWatercraft: () => {
                updateWatercraft().then((valid) => {
                    if (valid) {
                        updateCurrentRow(null);
                        setSplitWarning(false);
                    }
                });
            },
            saveAndNextWatercraft: () => {
                updateWatercraft().then((valid) => {
                    if (valid) {
                        onNextWatercraft();
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderDropDownCell,
            renderCell
        },
        resolveComponentMap: {
            watercraftcomponent: WatercraftComponent
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            disableNext={!allWatercraftValid()}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

WALWatercraftsPage.propTypes = WizardPage.propTypes;
WALWatercraftsPage.defaultProps = {
    checkRequiredForIssuance: true
}
export default WALWatercraftsPage;
