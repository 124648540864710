import _ from 'lodash'
import {Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import PropTypes from 'prop-types';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import React, { useState, useCallback } from 'react';
import { GLCoverageService, GLStateSpecificInformationService } from 'wni-capability-quoteandbind-gl';
import { SearchAndAddCoveragesPopup } from 'wni-capability-common-react';
import CoveragesConfigContext from '../GeneralLiability/context/GLCoveragesConfigContext'
import GLCoveragesConfig from '../GeneralLiability/GLCoverages.config';
import MultiLineAdditionalCoveragesAccordionCard from './components/MultiLineAdditionalCoveragesAccordionCard';
import MultiLineExclusionAndConditionAccordionCard from './components/MultiLineExclusionAndConditionAccordionCard';
import messages from './GLStateSpecificInformationPage.messages'
import metadata from './GLStateSpecificInformationPage.metadata.json5';

const stateSimpleInfosPATH = 'lobData.generalLiability.stateSimpleInfos';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

const GLStateSpecificInformationPage = (props) => {
    const {
        wizardData: submissionVM,
        updateWizardData, 
        markFollowingWizardStepsUnvisited,
        //
        authHeader,
        isReadOnly
    } = props;

    const {
        jobID,
        sessionUUID,
        lobData: {
            generalLiability: { stateSimpleInfos = [] },
        },
    } = submissionVM.value;
    const modalApi = useWniModal();
    const translator = useTranslator();

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');
    const [validationIssues, updateValidationIssues] = useState([])
    const [stateClauses, setStateClauses] = useState();
    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loadingClauses, setLoadingClauses] = useState(false);
    const [selectedState, updateSelectedState] = useState('AK');
    const { onValidate, initialValidation, isComponentValid } = useValidation(
        'GLStateSpecificInformationPage'
    );

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            jurisdictionCode: selectedState
        };
        return modalApi.showModal(
            <SearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, selectedState, sessionUUID, setLoadingMask]);

    const onClickAddSearchCoverage = useCallback((covType) => {
        showCoveragesModal(covType).then(
            (data) => {setStateClauses(data)}
        ).catch(_.noop);
    },[showCoveragesModal])

    const viewOrEdit = async (item) => {
        setLoadingMask(true);
        const res = await GLCoverageService.getStateClauses(
            jobID,
            sessionUUID,
            item.state,
            authHeader
        );
        setStateClauses(res);
        setLoadingMask(false);
        updateSelectedState(item.state);
    };

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData);
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const renderValidationCell = (info) => {
        let iconDom
        if (info.isValid) {
            iconDom = <Icon id={`validationIcon${info.state}`} icon={VALIDATION_ICON_MAP.success} className='wni-icon-success' />
        } else {
            iconDom = <Icon id={`validationIcon${info.state}`} icon={VALIDATION_ICON_MAP.error} className='wni-icon-error' />
        }
        
        return WniTableRowUtil.renderCell(info.state, iconDom)
    }

    const onPageNext = async () => {
        setLoadingMask(true)
        const res = await GLStateSpecificInformationService.onStateSpecificInformationPageNext(jobID, sessionUUID, authHeader)
        const {
            stateSimpleInfos: newStateSimpleInfos = [],
            errorsAndWarnings
        } = res
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        _.set(newSubmissionVM.value, stateSimpleInfosPATH, newStateSimpleInfos)
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const hasInvalidState = newStateSimpleInfos.some(info => !info.isValid)
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        setLoadingMask(false)
        if (hasErrorIssues || hasNewErrorsOrWarnings || hasInvalidState) {
            setShowErrors(true)
            return false;
        }
        return newSubmissionVM
    };

    const setStateClausesAndClearPages = useCallback((clausesParam) => {
        setStateClauses(clausesParam);
        // updateWizardDataToDraft(submissionVM);
        // let's make things simpler
        markFollowingWizardStepsUnvisited();
    }, [setStateClauses]);

    const handleValidation = () => {
        setShowErrors(true)
        return false
    }

    const resolvers = {
        resolveCallbackMap: {
            viewOrEdit
        },
        componentMap: {
            MultiLineAdditionalCoveragesAccordionCard:
                MultiLineAdditionalCoveragesAccordionCard,
            MultiLineExclusionAndConditionAccordionCard:
                MultiLineExclusionAndConditionAccordionCard,
        },
    };

    const propsForMultiLineClauses = {
        stateClauses,
        setStateClauses: setStateClausesAndClearPages,
        submissionVM,
        updateWizardData: updateWizardDataToDraft,
        onValidate,
        isEditing,
        setIsEditing,
        loadingClauses,
        setLoadingClauses,
        showErrors,
        isEditable: !isReadOnly,
        onClickAddSearchCoverage
    };

    const overrideProps = {
        '@field': {
            isEditable: !isReadOnly,
        },
        stateSelectionTable: {
            data: stateSimpleInfos,
        },
        stateInformationContent: {
            visible: !!stateClauses,
        },
        stateInfoValidationIcon: {
            renderCell: renderValidationCell
        },
        MultiLineAdditionalCoveragesAccordionCard: {
            ...propsForMultiLineClauses,
        },
        MultiLineExclusionAndConditionAccordionCard: {
            ...propsForMultiLineClauses,
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.ViewLabel : messages.ViewOrEdit
        }
    };

    const disableNavigate = isEditing || loadingClauses || isLoadingMask

    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigate}
            disablePrevious={disableNavigate}
            disableCancel={disableNavigate}
        >
            <CoveragesConfigContext.Provider value={GLCoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardDataToDraft}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.componentMap}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
};

GLStateSpecificInformationPage.propTypes = {
    ...wizardProps,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string,
    }).isRequired,
};

export default withAuthenticationContext(GLStateSpecificInformationPage);
