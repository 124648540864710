import { defineMessages } from 'react-intl';

export default defineMessages({
    solidFuelQuestionnaire: {
        id: 'custom.wizard.sidebar.quick link.Solid Fuel Questionnaire',
        defaultMessage: 'Solid Fuel Questionnaire'
    },
    earthquakeQuestionnaire: {
        id: 'custom.wizard.sidebar.quick link.Earthquake Questionnaire',
        defaultMessage: 'Earthquake Questionnaire'
    },
});