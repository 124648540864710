import React from 'react';
import PoliciesSummaryClauseScheduleTable from './PoliciesSummaryClauseScheduleTable';
import { InputField } from '@jutro/legacy/components';

const PoliciesClausesArray = (props) => {
    const { clauses = [] } = props;
    return (
        <>
            {clauses
                .filter((clause) => clause.selected)
                .map((clause) => (
                    <>
                        <div key={clause.code_Ext} className="d-flex">
                            <h5 className="flex-1">{clause.name}</h5>
                            <div className="flex-1">
                                {clause.terms.map((term) => {
                                    let termValueDisplayName = term.chosenTermValue
                                    if (term.valueType === 'shorttext') {
                                        termValueDisplayName = term.directStringValue
                                    }
                                    return (
                                        <InputField
                                            key={`${clause.code_Ext}.${term.code_Ext}`}
                                            label={term.name}
                                            value={termValueDisplayName}
                                            readOnly
                                            labelPosition='left'
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        {clause.schedule ? <PoliciesSummaryClauseScheduleTable schedule={clause.schedule}/> : null}
                    </>
                    
                ))}
        </>
    );
};

export default PoliciesClausesArray;
