import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { DataTable,DisplayColumn } from '@jutro/legacy/datatable';
import { Flex, FlexItem } from '@jutro/layout';
import { CPCoverageService } from 'wni-capability-quoteandbind-cp';
import messages from './CPSearchAndAddCoveragesPopup.messages';

import { Button, RadioButtonField, InputField, DropdownSelectField } from '@jutro/legacy/components';

function SearchAndAddCoveragesPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        serviceProps,
        setLoadingMask,
        covType,
        isLine,
        jurisdictionCode,
        locationPublicID,
        riskItem
    } = props;

    const {
        jobID,
        sessionUUID,
        authHeader
    } = serviceProps;

    const translator = useTranslator();
    const [coverageCategory, updateCoverageCategory] = useState('all');
    const [searchKeyword, updateSearchKeyword] = useState(null);
    const [coverageType, updateCoverageType] = useState(covType);
    const [searchedCoveragesData, updateSearchedCoveragesData] = useState([]);
    const [selections, updateSelections] = useState([]);
    const stateFullName = translator({
        id: `typekey.Jurisdiction.${jurisdictionCode}`,
        defaultMessage: jurisdictionCode
    })

    const availableTypeValues = [{id: 'conditions', displayName: 'Conditions'}, {id: 'exclusions', displayName: 'Exclusions'}, {id: 'exclusionsAndConditions', displayName: 'Exclusions And Conditions'}];
    
    const availableCategoryValues = useMemo(() => {
        if (covType === 'additionalCoverages') {
            if (isLine) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.LineAdditionalCoverageCategory)}];
            }
            if (!_.isNil(jurisdictionCode)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.StateSpecificAdditionalCoverageCategory)}];
            }
            if (!_.isNil(locationPublicID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.LocationAdditionalCoverageCategory)}];
            }
            if (!_.isNil(riskItem)){
                if (_.get(riskItem, 'coverableType') !== 'Occupancy') {
                    return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.RiskItemAdditionalCoverageCategory, {coverableType: riskItem.coverableType})}];
                } 
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.OccupancyClassAdditionalCoverageCategory)}];
                
            }
        } 
        if (covType === 'additionalInsureds') {
            if (isLine) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.LineAdditionalInsuredsCategory)}];
            }
            if (!_.isNil(jurisdictionCode)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.StateSpecificAdditionalInsuredsCategory)}];
            }
            if (!_.isNil(locationPublicID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.LocationAdditionalInsuredsCategory)}];
            }
            if (!_.isNil(riskItem)) {
                if (_.get(riskItem, 'coverableType') !== 'Occupancy') {
                    return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.RiskItemAdditionalInsuredsCategory, {coverableType: riskItem.coverableType})}];
                }
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.OccupancyClassAdditionalInsuredsCategory)}];
            
            }
        } 
        if (covType === 'exclusionsAndConditions') {
            if (isLine) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.LineConditionsCategory)}, {code: 'exclusions', name: translator(messages.LineExclusionsCategory)}];    
            }
            if (!_.isNil(jurisdictionCode)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.StateSpecificConditionsCategory)}, {code: 'exclusions', name: translator(messages.StateSpecificExclusionsCategory)}];
            }
            if (!_.isNil(locationPublicID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.LocationConditionsCategory)}, {code: 'exclusions', name: translator(messages.LocationExclusionsCategory)}];
            }
            if (!_.isNil(riskItem)) {
                if (_.get(riskItem, 'coverableType') !== 'Occupancy') {
                    return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.RiskItemConditionsCategory, {coverableType: riskItem.coverableType})}, {code: 'exclusions', name: translator(messages.RiskItemExclusionsCategory, {coverableType: riskItem.coverableType})}];
                }
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.OccupancyClassConditionsCategory)}, {code: 'exclusions', name: translator(messages.OccupancyClassExclusionsCategory)}];
        
            }
        }
    }, [covType, isLine, jurisdictionCode, locationPublicID, riskItem, translator])

    const coverageTypeTitle = useMemo(()=>{
        if (covType === 'additionalCoverages') {
            return translator(messages.coveragesPopupTitle);
        }
        if (covType === 'additionalInsureds') {
            return translator(messages.additionalInsuredsCoveragesPopupTitle);
        }
        return translator(messages.exclusionsAndConditionsPopupTitle);
    }, [covType, translator])

    const coverageButtonTypeTitle = useMemo(()=>{
        if (covType === 'additionalCoverages' || covType === 'additionalInsureds') {
            return translator(messages.coveragesPopupTitle);
        }
        return translator(messages.exclusionsAndConditionsPopupTitle);
    }, [covType, translator])

    const serchCoverages = useCallback(async() => {
        setLoadingMask(true);
        let searchResults;
        if (isLine) {
            searchResults = await CPCoverageService.searchLineCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, authHeader);
        } 
        if (!_.isNil(jurisdictionCode)) {
            searchResults = await CPCoverageService.searchStateSpecificCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, jurisdictionCode, authHeader);
        } 
        if (!_.isNil(locationPublicID)) {
            searchResults = await CPCoverageService.searchLocationCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, locationPublicID, authHeader);
        }
        if (!_.isNil(riskItem)) {
            switch (riskItem.coverableType) {
                case "Building": 
                    searchResults = await CPCoverageService.searchBuildingCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, riskItem.locationPublicID, riskItem.publicID, authHeader);
                    break;
                case "Special Class":
                    searchResults = await CPCoverageService.searchSpecialClassCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, riskItem.locationPublicID, riskItem.publicID, authHeader);
                    break;
                case "Occupancy":
                    searchResults = await CPCoverageService.searchOccupancyCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, riskItem.locationPublicID, riskItem.buildingPublicID, riskItem.publicID, authHeader);
                    break;
                case "Business Income":
                    searchResults = await CPCoverageService.searchBusinessIncomeCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, riskItem.locationPublicID, riskItem.buildingPublicID, riskItem.publicID, authHeader);
                    break;
                case "Personal Property":
                    searchResults = await CPCoverageService.searchPersonalPropertyCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, riskItem.locationPublicID, riskItem.buildingPublicID, riskItem.occupancyPublicID, riskItem.publicID, authHeader);
                    break;
                case "Special Class Business Income":
                    searchResults = await CPCoverageService.searchSpecClsBICoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, riskItem.locationPublicID, riskItem.specialClassPublicID, riskItem.publicID, authHeader);
                    break;
                default:
                    break;
            }
        }

        if (!_.isEmpty(searchResults)) {
            updateSearchedCoveragesData(searchResults);
        } else {
            updateSearchedCoveragesData([]);
        }
        setLoadingMask(false);
    }, [authHeader, coverageCategory, coverageType, isLine, jobID, jurisdictionCode, locationPublicID, riskItem, searchKeyword, sessionUUID, setLoadingMask])

    const addSelectedCoverage = useCallback(async()=>{
        setLoadingMask(true);
        let res;
        const clauseSpecData = searchedCoveragesData.filter(cov => selections.includes(searchedCoveragesData.indexOf(cov)));
        if (isLine) {
            res = await CPCoverageService.addSearchedLineCoverages(jobID, sessionUUID, clauseSpecData, authHeader);
        }
        if (!_.isNil(jurisdictionCode)) {
            res = await CPCoverageService.addSearchedStateSpecificCoverages(jobID, sessionUUID, clauseSpecData, jurisdictionCode, authHeader);
        } 
        if (!_.isNil(locationPublicID)) {
            res = await CPCoverageService.addSearchedLocationCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, authHeader);
        }
        if (!_.isNil(riskItem)) {
            switch (riskItem.coverableType) {
                case "Building": 
                    res = await CPCoverageService.addSearchedBuildingCoverages(jobID, sessionUUID, clauseSpecData, riskItem.locationPublicID, riskItem.publicID, authHeader);
                    break;
                case "Special Class":
                    res = await CPCoverageService.addSearchedSpecialClassCoverages(jobID, sessionUUID, clauseSpecData, riskItem.locationPublicID, riskItem.publicID, authHeader);
                    break;
                case "Occupancy":
                    res = await CPCoverageService.addSearchedOccupancyCoverages(jobID, sessionUUID, clauseSpecData, riskItem.locationPublicID, riskItem.buildingPublicID, riskItem.publicID, authHeader);
                    break;
                case "Business Income":
                    res = await CPCoverageService.addSearchedBusinessIncomeCoverages(jobID, sessionUUID, clauseSpecData, riskItem.locationPublicID, riskItem.buildingPublicID, riskItem.publicID, authHeader);
                    break;
                case "Personal Property":
                    res = await CPCoverageService.addSearchedPersonalPropertyCoverages(jobID, sessionUUID, clauseSpecData, riskItem.locationPublicID, riskItem.buildingPublicID, riskItem.occupancyPublicID, riskItem.publicID, authHeader);
                    break;
                case "Special Class Business Income":
                    res = await CPCoverageService.addSearchedSpecClsBICoverages(jobID, sessionUUID, clauseSpecData, riskItem.locationPublicID, riskItem.specialClassPublicID, riskItem.publicID, authHeader);
                    break;
                default:
                    break;
            }
        }

        setLoadingMask(false);
        return res;
    }, [authHeader, isLine, jobID, jurisdictionCode, locationPublicID, riskItem, searchedCoveragesData, selections, sessionUUID, setLoadingMask])

    const resetChoice = () => {
        updateCoverageCategory('all');
        updateSearchKeyword(null);
        updateCoverageType(covType);
        updateSearchedCoveragesData([]);
    }

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="coverageContactSearchComponent" autoFocus={false}>
                {isLine && <h3>{`Search ${coverageTypeTitle} for Commercial Property`}</h3>} 
                {!_.isNil(locationPublicID) && 
                (covType === 'exclusionsAndConditions' ?
                    <h3>Search Exclusions And Conditions for CP7 Location</h3>
                    : <h3>{`Search ${coverageTypeTitle} for CP7 Location`}</h3>)}
                {!_.isNil(riskItem) && 
                (covType === 'exclusionsAndConditions' ? 
                    <h3>{`Search Exclusions And Conditions for ${riskItem.coverableType}`}</h3>
                    : <h3>{`Search Coverages for ${riskItem.coverableType}`}</h3>)}
                {!_.isNil(jurisdictionCode) && 
                (covType === 'exclusionsAndConditions' ? 
                    <h3>{`Search Exclusions And Conditions for ${stateFullName}`}</h3>
                    : <h3>{`Search Coverages for ${stateFullName}`}</h3>)}
                <hr/>
                <DropdownSelectField
                    id="coverage_category"
                    label="Category"
                    labelPosition="left"
                    alwaysShowPlaceholder={false}
                    availableValues={availableCategoryValues}
                    onValueChange={(value) => updateCoverageCategory(value)}
                    value={coverageCategory}
                />
                <InputField
                    id="coverage_keyword"
                    label="Keyword"
                    labelPosition="left"
                    onValueChange={(value) => updateSearchKeyword(value)}
                    value={searchKeyword}
                />
                <RadioButtonField
                    id="coverage_type"
                    label="Type"
                    labelPosition="left"
                    availableValues={availableTypeValues}
                    showRequired
                    required
                    onValueChange={(value) => updateCoverageType(value)}
                    visible={covType === 'exclusionsAndConditions'}
                    value={coverageType}
                />
                <Flex justifyContent="left">
                    <FlexItem><Button onClick={serchCoverages}>Search</Button></FlexItem>
                    <FlexItem><Button onClick={resetChoice} type="outlined">Reset</Button></FlexItem>
                </Flex>
                <DataTable
                    columnsConfigurable={false}
                    data={searchedCoveragesData}
                    defaultConfig={{
                        // filterValue: '',
                        // page: 0,
                        pageSize: 5
                    }}
                    pageSizeOptions={[5]}
                    id="basic"
                    showPagination
                    showSearch={false}
                    selectionType="multi"
                    onSelectionChange={(indexs) => {
                        updateSelections(indexs)
                    }}
                    >
                    <DisplayColumn
                        header="Coverage"
                        id="coverage"
                        path="patternDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
                    <DisplayColumn
                        header="Category"
                        id="category"
                        path="categoryDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
                    <DisplayColumn
                        header="Type"
                        id="type"
                        path="clauseType"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
            </DataTable>
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "right",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject()}} type="outlined">Cancel</Button>
                <Button onClick={()=>{onResolve(addSelectedCoverage())}} disabled={_.isEmpty(selections)}>Add Selected {coverageButtonTypeTitle}</Button>
            </ModalFooter>
        </ModalNext>
    );
}
SearchAndAddCoveragesPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    isLine: PropTypes.bool,
    jurisdictionCode: PropTypes.string,
    locationPublicID: PropTypes.string
};

SearchAndAddCoveragesPopup.defaultProps = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    isLine: false,
    jurisdictionCode: undefined,
    locationPublicID: undefined,
    riskItem: undefined
};

export default SearchAndAddCoveragesPopup;
