import _ from "lodash";
import PropTypes from 'prop-types';
import RTCoverageUtil from "../../../util/RTCoverageUtil";

const useSinglePolicyContactRoleClause = (props) => {

    const {
        clauseCode,
        limitScheduleItemTermCode,
        subScheduleTermsCodes,
        policyContactRole,
        submissionVM,
        updateWizardData,
        lineCoveragePath,
        updateSubmissionVMToServer,
    } = props

    /**
     * @type {Array}
     */
    const lineCoverages = _.get(submissionVM.value, lineCoveragePath, [])

    const coverageInLineCovsIdx = lineCoverages.findIndex((cov) => cov.code_Ext === clauseCode)

    const coverage = lineCoverages[coverageInLineCovsIdx]

    const {
        schedule = {},
    } = coverage

    const {
        publicID_Ext: policyContactRolePublicID
    } = policyContactRole;

    const {
        scheduleItems = [],
    } = schedule

    /**
     * check if scheduleItem is for current operator
     * @param {Object} scheduleItem 
     * @returns {Boolean}
     */
    const checkIsScheduleItemForCurOperator = (scheduleItem) => {
        const itemData = _.get(scheduleItem, 'itemData', {})
        const policyContactRoleItem = _.get(itemData, 'PolicyContactRole', {})
        return _.get(policyContactRoleItem, 'typeCodeValue') === policyContactRolePublicID
    }

    const scheduleItemForCurOperatorIdx = scheduleItems.findIndex(checkIsScheduleItemForCurOperator)
    const scheduleItemForCurOperator = scheduleItems[scheduleItemForCurOperatorIdx]
    const selectedLmtScheduleItemTermTypeCode = _.get(
        scheduleItemForCurOperator,
        `itemData.${limitScheduleItemTermCode}.typeCodeValue`
    )
    const lmtScheduleItemTermOptions = _.get(
        scheduleItemForCurOperator,
        `itemData.${limitScheduleItemTermCode}.options_Ext`
    )

    const lmtScheduleItemTermName = _.get(
        scheduleItemForCurOperator,
        `itemData.${limitScheduleItemTermCode}.name`
    )

    const setOperatorCovSelected = (value) => {
        const updatedCoverage = _.clone(coverage)
        if (!value) {
            const remainScheduleItems = scheduleItems
                .filter((scheduleItem) => !checkIsScheduleItemForCurOperator(scheduleItem))
            RTCoverageUtil.setValueToCoverage(updatedCoverage, remainScheduleItems, 'schedule.scheduleItems')
            // Check how many schedule items remains in this schedule
            // If no items exists after uncheck this one, unselect coverage
            if (remainScheduleItems.length <= 0) {
                RTCoverageUtil.setValueToCoverage(updatedCoverage, value, 'selected')
            }
        } else {
            // select coverage if coverage is not selected
            const isCoverageSelected = _.get(coverage, 'selected')
            if (!isCoverageSelected) {
                RTCoverageUtil.setValueToCoverage(updatedCoverage, value, 'selected')
            }
            const newScheduleItem = {
                '@deserialization-class': "edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO",
                itemData: {
                    PolicyContactRole: {
                        typeCodeValue: policyContactRolePublicID
                    }
                }
            }
            const updatedScheduleItems = scheduleItems.concat([newScheduleItem])
            RTCoverageUtil.setValueToCoverage(updatedCoverage, updatedScheduleItems, 'schedule.scheduleItems')
        }
        _.set(submissionVM.value, `${lineCoveragePath}[${coverageInLineCovsIdx}]`, updatedCoverage)
        updateWizardData(submissionVM)
        updateSubmissionVMToServer(submissionVM)
    }

    const lmtScheduleItemTermInfo = {
        name: lmtScheduleItemTermName,
        selectedTypeCode: selectedLmtScheduleItemTermTypeCode,
        options: lmtScheduleItemTermOptions
    }

    const subTermsInfo = subScheduleTermsCodes.reduce((termsInfo, curTermCode) => {
        return {
            ...termsInfo,
            [curTermCode]: {
                name: _.get(
                    scheduleItemForCurOperator,
                    `itemData.${curTermCode}.name`
                ),
                selectedTypeCode: _.get(
                    scheduleItemForCurOperator,
                    `itemData.${curTermCode}.typeCodeValue`
                ),
                options: _.get(
                    scheduleItemForCurOperator,
                    `itemData.${curTermCode}.options_Ext`
                ),
            }
        }
    }, {})

    const setTermCode = (code, termCode) => {
        const updatedCoverage = _.clone(coverage)

        RTCoverageUtil.setValueToCoverage(
            updatedCoverage,
            code,
            `schedule.scheduleItems[${scheduleItemForCurOperatorIdx}].itemData.${termCode}.typeCodeValue`
        )
        _.set(submissionVM.value, `${lineCoveragePath}[${coverageInLineCovsIdx}]`, updatedCoverage)
        updateWizardData(submissionVM)
        updateSubmissionVMToServer(submissionVM)
    }

    const operatorCovSelected = !_.isNil(scheduleItemForCurOperator)

    return [
        operatorCovSelected,
        setOperatorCovSelected,
        lmtScheduleItemTermInfo,
        subTermsInfo,
        setTermCode,
    ]
}

useSinglePolicyContactRoleClause.propTypes = {
    clauseCode: PropTypes.string.isRequired,
    limitScheduleItemTermCode: PropTypes.string,
    subScheduleTermsCodes: PropTypes.arrayOf(PropTypes.string),
    policyContactRole: PropTypes.shape({}).isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    lineCoveragePath: PropTypes.string.isRequired,
    updateSubmissionVMToServer: PropTypes.func.isRequired,
};
useSinglePolicyContactRoleClause.defaultProps = {
    limitScheduleItemTermCode: undefined,
    subScheduleTermsCodes: [],
}

export default useSinglePolicyContactRoleClause;