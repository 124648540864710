import React, { useCallback, useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { WniCheckboxField } from 'wni-common-base-components';
import { ProductUtil } from 'wni-portals-util-react';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { useTranslator } from '@jutro/locale';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { FNOLService } from 'wni-capability-fnol';
import metadata from './FNOLBusinessLocationPage.metadata.json5';

function FNOLBusinessLocationPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader,
        LOB_NAME,
        isCPPLine
    } = props;
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const { FNOLService } = useDependencies('FNOLService');
    const viewModelService = useContext(ViewModelServiceContext);

    const [totalBuildings, updateTotalBuildings] = useState([]);
    const [policyRiskUnits, setPolicyRiskUnits] = useState([]);
    const [selectedSingleSelectionBuildingID, setSelectedSingleSelectionBuildingID] = useState(undefined);
    
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation
    } = useValidation('FNOLBusinessLocationPage');

    const RISK_UNITS_LOB_NAME = isCPPLine ? ProductUtil.CPP_LOB_NAME : LOB_NAME;
    const PROPERTY_RISK_UNITS_PATH = `policy.lobs.${RISK_UNITS_LOB_NAME}.propertyRiskUnits`;

    const isSingleSelection = [ProductUtil.GL_LOB_NAME, ProductUtil.IM_LOB_NAME].includes(LOB_NAME);

    const isSelectedBuildings = useCallback(() => {
        if (totalBuildings.length === 0) {
            return null;
        }
        const selectedBuildings = _.filter(totalBuildings, (item) => item.documentisCheck);
        if (selectedBuildings.length > 0) {
            return true;
        }
        return false;
    }, [totalBuildings]);

    const getResponseData = useCallback((responseData, oldClaimVM) => {
        const addressData = responseData.lobs[RISK_UNITS_LOB_NAME];
        const contactsInfo = addressData.propertyRiskUnits.map((building) => {
            const oldPropertyRiskUnits = _.get(oldClaimVM, `value.policy.lobs.${RISK_UNITS_LOB_NAME}.propertyRiskUnits`, []);
            const selected = oldPropertyRiskUnits.findIndex(
                    (unit) => unit.policySystemId === building.policySystemId
                ) > -1;
            const addressline2 = building.addressline2 ? `, ${building.addressline2}` : '';
            const buildingAddress = `${building.addressLine1} ${addressline2}`;
            return {
                documentCheckbox: building,
                documentBuilding: building.buildingNumber,
                documentDescription: building.description,
                documentCity: building.city,
                documentState: building.state_Ext,
                documentLocation: buildingAddress,
                documentPropertyNo: building.propertyNumber_Ext,
                documentLocationId: building.policySystemId,
                documentisCheck: selected
            };
        });
        return _.sortBy(contactsInfo, ['documentPropertyNo']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPolicySummaryRiskUnitsFromService = useCallback(() => {
        if (!_.get(claimVM, `value.policy.lobs.${RISK_UNITS_LOB_NAME}`)) {
            _.set(claimVM, `value.policy.lobs.${RISK_UNITS_LOB_NAME}`, { propertyRiskUnits: [] } );
        }
        const oldClaimVM = viewModelService.clone(claimVM);
        const {
            lossDate,
            policy: {
                policyNumber,
                policySubType_EXT: policySubType,
                policyType_Ext: policyType
            }
        } = claimVM.value;
        const policySearchCriteria = {
            policyNumber: policyNumber,
            lossDate: lossDate,
            policySubType_EXT: policySubType,
            policyType: policyType
        };
        setLoadingMask(true);
        FNOLService.getPolicySummaryRiskUnitsBySearchCriteria(policySearchCriteria, authHeader).then(
            (response) => {
                setPolicyRiskUnits(response);
                const contactdatatable = getResponseData(response, oldClaimVM);
                const buildings = [...contactdatatable];
                updateTotalBuildings(buildings);
                if (isSingleSelection) {
                    const selectedGLBuilding = buildings.find((bld) => bld.documentisCheck);
                    setSelectedSingleSelectionBuildingID(selectedGLBuilding ? selectedGLBuilding.documentLocationId : undefined);
                }
            }
        ).finally(
            () => {
                setLoadingMask(false);
            }
        );
    }, [RISK_UNITS_LOB_NAME, authHeader, claimVM, getResponseData, isSingleSelection, setLoadingMask, viewModelService]);

    useEffect(() => {
        registerComponentValidation(isSelectedBuildings);
    }, [isSelectedBuildings, registerComponentValidation]);

    useEffect(() => {
        getPolicySummaryRiskUnitsFromService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeCheckboxColumn = useCallback(
        (value, selectedBuilding) => {
            const selectedIndex = totalBuildings.indexOf(selectedBuilding);
            totalBuildings[selectedIndex].documentisCheck = value;
            updateTotalBuildings([...totalBuildings]);
            const locationList = _.get(
                claimVM,
                `value.${PROPERTY_RISK_UNITS_PATH}`
            );
            const riskUnitLocationList = _.get(
                policyRiskUnits,
                `lobs.${RISK_UNITS_LOB_NAME}.propertyRiskUnits`
            );
            const index = riskUnitLocationList.findIndex((unit) => {
                return unit.policySystemId === selectedBuilding.documentLocationId;
            });
            if (value) {
                locationList.push(riskUnitLocationList[index]);
                _.set(
                    claimVM,
                    `value.${PROPERTY_RISK_UNITS_PATH}`,
                    locationList
                );
            } else {
                const updatedList = locationList.splice(index, 0);
                _.set(
                    claimVM,
                    `value.${PROPERTY_RISK_UNITS_PATH}`,
                    updatedList
                );
            }
            updateWizardData(claimVM);
        },
        [totalBuildings, claimVM, PROPERTY_RISK_UNITS_PATH, policyRiskUnits, RISK_UNITS_LOB_NAME, updateWizardData]
    );

    const onChangeRadioColumn = useCallback(
        (buildingID) => {
            setSelectedSingleSelectionBuildingID(buildingID);
            const selectedIndex =  totalBuildings.findIndex((bld) => bld.documentLocationId === buildingID);
            const selectedBuilding = totalBuildings[selectedIndex];
            // single select for GL
            totalBuildings.forEach((bld) => {
                _.set(bld, 'documentisCheck', false);
            })
            selectedBuilding.documentisCheck = true;
            updateTotalBuildings([...totalBuildings]);
            const riskUnitLocationList = _.get(
                policyRiskUnits,
                `lobs.${RISK_UNITS_LOB_NAME}.propertyRiskUnits`
            );
            const index = riskUnitLocationList.findIndex((unit) => {
                return unit.policySystemId === selectedBuilding.documentLocationId;
            });
            _.set(
                claimVM,
                `value.${PROPERTY_RISK_UNITS_PATH}`,
                [riskUnitLocationList[index]]
            );
            updateWizardData(claimVM);
        },
        [totalBuildings, policyRiskUnits, RISK_UNITS_LOB_NAME, claimVM, PROPERTY_RISK_UNITS_PATH, updateWizardData]
    );

    const getDataCell = useCallback((item, index, { path: property }) => {
        return item[property];
    }, []);

    const getCheckboxDataCell = useCallback(
        (item, index) => {
            return (
                <WniCheckboxField
                    name={item.documentLocationId}
                    id={item.documentLocationId}
                    value={item.documentisCheck}
                    onValueChange={(e) => onChangeCheckboxColumn(e, item, index)}
                />
            );
        },
        [onChangeCheckboxColumn]
    );

    const generateDataForRadioButton = useCallback((row) => {
        return {
            code: row.documentLocationId,
            value: ''
        };
    }, []);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        buildingDataTable: {
            data: totalBuildings
        },
        buildingCheckbox: {
            visible: !isSingleSelection
        },
        buildingRadio: {
            visible: isSingleSelection,
            option: generateDataForRadioButton,
            value: selectedSingleSelectionBuildingID,
            onValueChange: onChangeRadioColumn
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getCheckboxDataCell: getCheckboxDataCell,
            getDataCell: getDataCell
        }
    };

    const onNext = useCallback(() => {
        const modifiedBuildingsArray = _.filter(totalBuildings, (item) => item.documentisCheck);
        if (modifiedBuildingsArray.length === 0) {
            return false;
        }
        const buildings = _.get(claimVM.value, PROPERTY_RISK_UNITS_PATH);
        const selectedBuildings = buildings.filter((building) => {
            return modifiedBuildingsArray.some((modifiedBuilding) => {
                return building.policySystemId === modifiedBuilding.documentCheckbox.policySystemId;
            });
        });
        _.set(claimVM.value, PROPERTY_RISK_UNITS_PATH, selectedBuildings);
        setLoadingMask(true);
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, [totalBuildings, claimVM, PROPERTY_RISK_UNITS_PATH, setLoadingMask, FNOLService, authHeader]);


    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLBusinessLocationPage.propTypes = wizardProps;
FNOLBusinessLocationPage.defaultProps = {
    isCPPLine: false
};
export default withRouter(withAuthenticationContext(FNOLBusinessLocationPage));
