import React, {
    useState,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { WniClausesUtil } from 'wni-portals-util-js';

function PUCoverageSectionHeaderComponent(props) {

    const {
        sectionTitle,
        sectionPremium,
    } = props;

    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const retval = WniClausesUtil.getLabelNameWithAmount(sectionTitle, sectionPremium, intl);

    // To be refactored with styling
    return (<div><h5>{retval}</h5></div>);
}

PUCoverageSectionHeaderComponent.propTypes = {
    sectionTitle: PropTypes.string,
    sectionPremium: PropTypes.shape({
        currenty: PropTypes.string,
        amount: PropTypes.number,
    }),
}

PUCoverageSectionHeaderComponent.defaultProps = {
    sectionTitle: '',
    sectionPremium: undefined,
};

export default PUCoverageSectionHeaderComponent;

