import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { FNOLService } from 'wni-capability-fnol';

import metadata from './FNOLDPTypeOfIncidentPage.metadata.json5';
import messages from '../../FNOLDP.messages';

function FNOLDPTypeOfIncidentPage(props) {
    const modalApi = useModal();
    const {
        wizardData,
        wizardSnapshot,
        updateWizardData,
        history,
        authHeader,
        history: {
            location: { state = {} }
        }
    } = props;
    // const { FNOLService } = useDependencies('FNOLService');
    const [isLoading, setIsLoading] = useState(false);
    const [pageData] = useState(state);
    const translator = useTranslator();
    const claimVM = !_.isEmpty(wizardData) ? wizardData : wizardSnapshot;
    const { onValidate, initialValidation, registerInitialComponentValidation } = useValidation(
        'FNOLDPTypeOfIncidentPage'
    );


    const onNext = useCallback(() => {
        const miscellaneous = claimVM.lossCause.value.code === 'miscellaneous';
        const { redirectPath } = pageData;
        if (miscellaneous) {
            return history.push({
                pathname: '/contact-agent',
                state: {
                    redirectPath: redirectPath
                }
            });
        }
        const policyNumber = _.get(claimVM, 'policy.policyNumber.value');
        const isClaimNumberAvailable = _.get(claimVM, 'claimNumber.value');
        if (isClaimNumberAvailable) {
            return claimVM;
        }
        setIsLoading(true);
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.dpCreateDraftErrorTitle,
                    message: messages.dpCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, history, modalApi, pageData]);

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const overrideProps = useMemo(() => ({
        typeOfIncidentPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(messages.dpCreateDraftClaim)
        },
        claimsDpContainerPage: {
            visible: !isLoading
        }
    }), [isLoading, translator]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
        }
    };

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} template={WizardPageTemplate}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={resolvers.resolveValue}
            />
        </WizardPage>
    );
}

FNOLDPTypeOfIncidentPage.propTypes = wizardProps;
FNOLDPTypeOfIncidentPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withAuthenticationContext(FNOLDPTypeOfIncidentPage));
