import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { DocumentUploadService, DocumentDownloadService } from '@xengage/gw-portals-document-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caVehicleService'), method, data, additionalHeaders);
}

export default class CAVehicleService {

    /**
     * Invokes CAVehiclesHandler.updateVehicle()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} vehicle
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addVehicle(quoteID, sessionUUID, vehicle, authHeader = {}) {
        return processSubmission('addVehicle', [quoteID, sessionUUID, vehicle], authHeader);
    }

    /**
     * Invokes CAVehiclesHandler.updateVehicle()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} vehicle
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateVehicle(quoteID, sessionUUID, vehicle, authHeader = {}) {
        return processSubmission('updateVehicle', [quoteID, sessionUUID, vehicle], authHeader);
    }

    /**
     * Invokes CAVehiclesHandler.removeVehicle()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Array} removeData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeVehicle(quoteID, sessionUUID, removeData, authHeader = {}) {
        return processSubmission('removeVehicle', [quoteID, sessionUUID, removeData], authHeader);
    }

    static postOnChangeAction(quoteID, sessionUUID, vehicle, isCallPriceDigest, authHeader = {}) {
        return processSubmission('postOnChangeAction', [quoteID, sessionUUID, vehicle, isCallPriceDigest], authHeader);
    }

    static fetchVehicles(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('fetchVehicles', [quoteID, sessionUUID], authHeader);
    }

    static copyVehicle(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('copyVehicle', [quoteID, sessionUUID, paramsData], authHeader);
    }
    
    static viewAndEdit(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('viewAndEdit', [quoteID, sessionUUID, paramsData], authHeader);
    }

    static getSearchDisplayable(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('getSearchDisplayable', [quoteID, sessionUUID, paramsData], authHeader);
    }
   
    static handleSearchDisplayable(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('handleSearchDisplayable', [quoteID, sessionUUID, paramsData], authHeader);
    }

    static handleConfigurationIdForPriceDigest(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('handleConfigurationIdForPriceDigest', [quoteID, sessionUUID, paramsData], authHeader);
    }

    static handleApplyFilter(quoteID, sessionUUID, paramsData, authHeader = {}) {
        return processSubmission('handleApplyFilter', [quoteID, sessionUUID, paramsData], authHeader);
    }

    static onPageNext(requestData, authHeader = {}) {
        return processSubmission('onPageNext', [requestData.jobID, requestData.sessionUUID, requestData.renumberingVehicles], authHeader);
    }
    
    static runBulkPrefill(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('runBulkPrefill', [jobID, sessionUUID], authHeader);
    }

    static getVehiclesCanCopyCovereages(jobID, sessionUUID, copyFromVehicleRowIdPath, authHeader = {}) {
        return processSubmission('getVehiclesCanCopyCovereages', [jobID, sessionUUID, copyFromVehicleRowIdPath], authHeader);
    }

    static copyVehicleCoverages(jobID, sessionUUID, copyFormVehicleRowIdPath, selection, authHeader = {}) {
        return processSubmission('copyVehicleCoverages', [jobID, sessionUUID, copyFormVehicleRowIdPath, selection], authHeader);
    }

    /** upload  */
     /**
     * Invokes CAUploadHandler.uploadVehicle()
     * 
     * @param {Object} documentMetaDataTemplate 
     * @param {object} file 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static upload(file, documentMetadata, authHeader = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('caUploadService'), file, documentMetadata, authHeader);
    }

    static download(jobID, isTemplete, additionalHeaders, errorCallback) {
        const url = getProxiedServiceUrl('caDownloadService').concat(`/${jobID}_${isTemplete}`);
        return DocumentDownloadService.getDocument(url, additionalHeaders, errorCallback);
    }
}
