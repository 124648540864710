import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { PAVehiclesReadOnlyPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeVehicleService } from 'wni-capability-policychange';


function PAVehiclesChangeReadOnlyPage(props) {

    const policyChangeGetAdditionalInterestTypeOptions = useCallback(async ({
        jobID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader
    }) => {
        const response = await WniPolicyChangeVehicleService.getAdditionalInterestTypeOptions(
            jobID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader
        );
        return response;
    }, []);

    const overrideProps = {
        ...props,
        policyChangeGetAdditionalInterestTypeOptions
    };

    return <PAVehiclesReadOnlyPage {...overrideProps} />;
}

PAVehiclesChangeReadOnlyPage.propTypes = wizardProps;
export default PAVehiclesChangeReadOnlyPage;
