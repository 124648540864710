import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PACoveragesReadOnlyPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeDriverService } from 'wni-capability-policychange';


function PACoveragesChangeReadOnlyPage(props) {
    const { wizardData: submissionVM } = props;
    const {
        jobID
    } = submissionVM.value;
    const { authHeader } = useAuthentication();

    const getDriversCoverages = useCallback(async () => {
        const request = {
            jobID
        };
        const response = await WniPolicyChangeDriverService.getDriversCoverages(
            request,
            authHeader
        );
        return response;
    }, []);

    const overrideProps = {
        ...props,
        policyChangeService: {
            getDriversCoverages
        }
    };


    return <PACoveragesReadOnlyPage {...overrideProps} />;
}

PACoveragesChangeReadOnlyPage.propTypes = wizardProps;
export default PACoveragesChangeReadOnlyPage;
