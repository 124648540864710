
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { HOResidenceReadOnlyPage } from 'wni-capability-quoteandbind-ho-react';
import DPConstructionComponent from "./Component/DPConstructionComponent/DPConstructionComponent";
import DPLiabilityExposuresComponent from "./Component/DPLiabilityExposuresComponent/DPLiabilityExposuresComponent";
import DPOtherInformationComponent from './Component/DPOtherInformationComponent/DPOtherInformationComponent';
import DPBusinessQuestionComponent from './Component/DPBusinessQuestionComponent/DPBusinessQuestionComponent';
import DPProtectionComponent from './Component/DPProtectionComponent/DPProtectionComponent';

function DPResidenceReadOnlyPage(props) {

    const {
        wizardData: submissionVM
    } = props;

    const {
        lobData: {
            dwellingProperty: {
                householdInfoDTO_Ext: {
                    namedInsuredOccupancy
                }
            }
        }
    } = submissionVM.value

    const DPOverrideProps = {
        businessQuestion: {
            namedInsuredOccupancy
        }
    }

    const componentMapOverride = {
        ConstructionComponent: DPConstructionComponent,
        LiabilityExposuresComponent: DPLiabilityExposuresComponent,
        OtherInformationComponent: DPOtherInformationComponent,
        BusinessQuestionComponent: DPBusinessQuestionComponent,
        ProtectionComponent: DPProtectionComponent
    }

    return (
        <HOResidenceReadOnlyPage
            {...props}
            componentMapOverride = {componentMapOverride}
            DPOverrideProps = {DPOverrideProps}
            linePath = 'dwellingProperty'/>
    );

}

DPResidenceReadOnlyPage.propTypes = HOResidenceReadOnlyPage.propTypes;
DPResidenceReadOnlyPage.defaultProps = HOResidenceReadOnlyPage.defaultProps;

export default DPResidenceReadOnlyPage;
