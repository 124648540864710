import React, {
    useState,
    useContext
}from 'react'
import _ from 'lodash'
import {
    Button,
} from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './SinglePedestrian.metadata.json5'
import messages from './SinglePedestrian.message'
import styles from '../../../../FNOLPAWizard.module.scss';


const SinglePedestrian = (props) => {
    const {
        originPedestrianIncident,
        onPopupCancel,
        onPopupSave
    } = props

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SinglePedestrian')

    const [showErrors, setShowErrors] = useState(false)
    const [pedestrianIncidentVM, setPedestrianIncidentVM] = useState(viewModelService.create(
        originPedestrianIncident,
        'cc',
        'wni.edge.capabilities.claim.lob.impl.personalauto.dto.PedestrianIncidentDTO'
    ));

    const contactVM = _.get(pedestrianIncidentVM, 'injuried');

    const writeValue = (value, path) => {
        const newPedestrianIncidentVM = viewModelService.clone(pedestrianIncidentVM);
        _.set(newPedestrianIncidentVM.value, path, value);
        if (path.startsWith('injuried')) {
            _.set(newPedestrianIncidentVM.value, 'injuried.isSave', true);
        }
        setPedestrianIncidentVM(newPedestrianIncidentVM);
    }

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        
        onPopupSave(pedestrianIncidentVM.value)
    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            showRequired: true,
            labelPosition: isPhone ? 'top' : 'left'
        },
        addressField: {
            model: contactVM,
            basePath: 'primaryAddress',
            onValueChange: writeValue,
            onValidate,
            cityStateRequired: false
        },
        pedestrianLastName: {
            tooltip: translator(messages.unknowTips, {
                what: translator(messages.lastName)
            })
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={pedestrianIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div className={styles.buttonsContainer}>
            <Button
                className="wni-button-link"
                variant="secondary"
                onClick={onPopupCancel}
                label={translator(messages.cancel)}
            />
            <Button
                variant="primary"
                onClick={onSaveClicked}
                label={translator(messages.save)}
            />
        </div>
        
    </>
}

export default SinglePedestrian
