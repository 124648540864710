// import { R1Config } from 'wni-portals-config-js';
import _ from 'lodash';

// const { availableAccountStates } = R1Config;

// function limitStateOptions(stateVM, allowedStateCodes) {
//     return stateVM.aspects.availableValues.filter((state) => {
//         return allowedStateCodes.some(
//             (allowedStateCode) => state.code === allowedStateCode
//         );
//     });
// }

// function getAvailableAcocuntStates() {
//     // return { ...availableAccountStateCodes };
//     return availableAccountStates;
// }

/**
 * Try to set default state in newSubmissionDTO
 * @param {object} newSubmissionDTO view model of NewSubmissionDTO
 * @param {string} state state code
 * @param {object} domainCompany domainCompany from dependency
 */
function tryToUpdateNewQuoteState(newSubmissionDTO, state, domainCompany = {}) {
    const {
        availableStates = []
    } = domainCompany;
    if (_.isEmpty(availableStates)) {
        return;
    }
    const stateOption = availableStates.find((s) => s.code === state);
    if (stateOption) {
        _.set(newSubmissionDTO, 'state', state);
    }
}



export default {
    // limitStateOptions,
    // getAvailableAcocuntStates,
    tryToUpdateNewQuoteState,
};
