import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('dpResidenceChange'), method, data, additionalHeaders);
}

export default class HOResidenceService {
    static editAnimal(data, additionalHeaders = {}) {
        return processSubmission(
            'editAnimal',
            [data.jobID, data.sessionUUID, data.dwellingAnimals],
            additionalHeaders
        );
    }

    static removeAnimal(data, additionalHeaders = {}) {
        return processSubmission(
            'removeAnimal',
            [data.jobID, data.sessionUUID, data.publicID],
            additionalHeaders
        );
    }

    static removeAnimals(data, additionalHeaders = {}) {
        return processSubmission(
            'removeAnimals',
            [data.jobID, data.sessionUUID, data.animals],
            additionalHeaders
        );
    }

    static updateDwelling(data, additionalHeaders = {}) {
        return processSubmission(
            'updateDwelling',
            [data.jobID, data.sessionUUID, data.dwelling],
            additionalHeaders
        );
    }

    static callRCTAndFireProtection(data, additionalHeaders = {}) {
        return processSubmission(
            'callRCTAndFireProtection',
            [data.jobID, data.sessionUUID],
            additionalHeaders
        );
    }

    static editSolidFuel(data, additionalHeaders = {}) {
        return processSubmission(
            'editSolidFuel',
            [data.jobID, data.sessionUUID, data.solidFuel],
            additionalHeaders
        );
    }

    static removeSolidFuels(data, additionalHeaders = {}) {
        return processSubmission(
            'removeSolidFuels',
            [data.jobID, data.sessionUUID, data.solidFuels],
            additionalHeaders
        );
    }
}
