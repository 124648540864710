import _ from 'lodash';

/**
 * Check the requiredness of a ViewModelNode
 * 
 * @param {object} currentVmNode
 * @param {object} question
 * @param {object} answers
 * @returns {object} - an object with the requiredness and visibility of the given ViewModelNode
 */
export default (currentVmNode, question, answers) => {
    return {
        visible: {
            get: () => {
                return _.every(question.filters, (filter) => {
                    if (answers[filter.questionCode] !== undefined) {
                        return answers[filter.questionCode] === filter.answer;
                    }
                    return false;
                });
            }
        },
        required: {
            get: () => {
                return currentVmNode.aspects.visible && question.required;
            }
        }
    };
};
