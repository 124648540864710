import { defineMessages } from 'react-intl';

export default defineMessages({
    language: {
        id: 'gateway.views.preferences.Language',
        defaultMessage: 'Language',
    },
    Preferences: {
        id: 'portal.index.Preferences',
        defaultMessage: 'Preferences',
    },
    accountSettings: {
        id: 'gateway.views.preferences.Account Settings',
        defaultMessage: 'Account Settings'
    }
});
