import { defineMessages } from 'react-intl';

export default defineMessages({
    createDP1Version: {
        id: 'custom.component.dp.DpAddNewVersionComponent.Create DP1 quote',
        defaultMessage: 'Create DP1 quote',
    }, 
    createDP2Version: {
        id: 'custom.component.dp.DpAddNewVersionComponent.Create DP2 quote',
        defaultMessage: 'Create DP2 quote',
    }, 
    createDP3Version: {
        id: 'custom.component.dp.DpAddNewVersionComponent.Create DP3 quote',
        defaultMessage: 'Create DP3 quote',
    },
});
