import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import classNames from 'classnames';

import { QuoteClauseTableUtil } from 'wni-portals-util-js';
import { WizardQuotePageConstants } from 'wni-portals-config-js';


import metadataMobileView from '../QuoteClauseTable.MobileView.metadata.json5';
import styles from '../QuoteClauseTable.module.scss';

const {
    PAYMENT_TYPES, NON_BLOCKING,
} = WizardQuotePageConstants;

function QuoteClauseDesktopTableHelper(props) {
    const {
        tableData, columnData,
        renderCellContent,
        // onChangeSubmission,
    } = props;

    const getTableColumnContent = (columnDataParam, tableInfo) => {
        return columnDataParam.map((colData, index) => {
            // from PAQuoteUIHelper.generateColumnData()
            const {
                data: {
                    isSxsEditable,
                    publicID: periodPublicID,
                    coverageIssues,
                },
                vehicleCoverageSets,
            } = colData.sxsPeriod;
            const {
                vehicleFixedId,
            } = tableInfo;

            return {
                id: `quoteTableColumn${index}`,
                type: 'element',
                component: 'tablecolumn',
                componentProps: {
                    cellClassName: 'gwTableCell',
                    renderCell: renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === colData.code),
                    isSxsEditable,
                    periodPublicID,
                    coverageIssues,
                    //
                    vehicleCoverageSets,
                    vehicleFixedId,
                }
            };
        });
    };

    const generateDesktopTableOverrides = () => {
        // const { tableData, columnData } = props;

        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data,
                title: tableInfo.header,
                content: getTableColumnContent(columnData, tableInfo)
            }
        }));

        return Object.assign({}, ...overrides);
    };


    function generateDesktopOverrides() {
        return {
            // ...generateDesktopHeaderOverrides(),
            ...generateDesktopTableOverrides()
        };
    }

    return {
        generateDesktopOverrides
    };
}

export default QuoteClauseDesktopTableHelper;
