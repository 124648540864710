
const HOCovBCode = 'HOPCovB';
const DPCovBCode = 'DPCoverageBSchedCov_Ext'

const structureFieldsNotNeedImmediatelySync = [
    'structureValue',
    'squareFootage',
    'hasLivingArea',
    'heatingType',
]

function getTermsForNewStructure(clausePatternCode) {
    switch (clausePatternCode) {
        case HOCovBCode:
        case DPCovBCode:
            return [];
        case 'HOPActualCashValueRelatedPrivateStructures_Ext':
            return [];
        case 'HOPRelatedPrivateStructuresRentedToOthers_Ext':
            return [
                {
                    required: true,
                    type: "DirectHOPRelatedPrivateStructuresRentedToOthersItemLimit_ExtType",
                    valueType: "Money",
                    publicID: "zfejs77n5qdipen8vnmmkmlkmqb",
                    patternCode: "zfejs77n5qdipen8vnmmkmlkmqb",
                    updated: false,
                    // eslint-disable-next-line camelcase
                    code_Ext: "HOPRelatedPrivateStructuresRentedToOthersItemLimit_Ext",
                    isAscendingBetter: true,
                    coveragePublicID: "z2khiclnc5etlfrl2k09fusbpi9",
                    name: "Limit",
                    // eslint-disable-next-line camelcase
                    readOnly_Ext: true
                }
            ]
        case 'HOPSchedRelatedPrivateStructuresAwayFromDescripLocat_Ext':
            return [
                {
                    required: true,
                    type: "GenericHOPSchedRelatedPrivateStructAwayFromItemLocatStruct_ExtType",
                    valueType: "shorttext",
                    publicID: "zlhiimmidh5b2c3dlk4nqq0q70a",
                    patternCode: "zlhiimmidh5b2c3dlk4nqq0q70a",
                    // eslint-disable-next-line camelcase
                    code_Ext: "HOPSchedRelatedPrivateStructAwayFromItemLocatStruct_Ext",
                    isAscendingBetter: true,
                    coveragePublicID: "zkpggtsk1f7tt5tcepgbgcn8m9a",
                    name: "Location of Structure"
                },
                {
                    required: true,
                    type: "DirectHOPSchedRelatedPrivateStructAwayFromItemLimit_ExtType",
                    valueType: "Money",
                    publicID: "z3jikekv1e3eb0sd9c4h9htqvn8",
                    patternCode: "z3jikekv1e3eb0sd9c4h9htqvn8",
                    // eslint-disable-next-line camelcase
                    code_Ext: "HOPSchedRelatedPrivateStructAwayFromItemLimit_Ext",
                    isAscendingBetter: true,
                    coveragePublicID: "zkpggtsk1f7tt5tcepgbgcn8m9a",
                    name: "Limit",
                    // eslint-disable-next-line camelcase
                    readOnly_Ext: true
                }
            ]
        default:
            return []
    }
}

export default {
    getTermsForNewStructure,
    structureFieldsNotNeedImmediatelySync,
};