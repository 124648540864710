import _ from 'lodash';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import React, { useState, useEffect, useContext } from 'react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    WniDataTable,
    WniDropdownSelect,
    WniInputNumber,
} from 'wni-common-base-components';
import messages from './FireProtectionSystemTable.messages';
import { Button } from '@jutro/legacy/components';



const FireProtectionSystemTable = (props) => {
    const translator = useTranslator();


    const { 
        vm, 
        onValueChange,
        additionalDetailsVM,
        updateAdditionalDetailsVM, 
        isReadOnly,
        addFireProtection,
        removeFireProtectionFunc
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const [currentRow, updateCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showPercentageError, updateShowPercentageError] = useState(false)
    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'fireProtectionSystemTable');
    };
    const sortedTable = vm.value.sort((a,b) => {
        return Number(a.publicID.substring(3))-Number(b.publicID.substring(3))
    })

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const handleTypeValueChange = (newValue, index) => {

        onValueChange(newValue, `additionalDetails.fireProtectionSystemData[${index}].fireProtectionSystemType`)
    };

    const handlePercentageValueChange = (newValue, index) => {
        onValueChange(newValue, `additionalDetails.fireProtectionSystemData[${index}].fireProtectionSystemPercentage`)
    }
    const removeFireProtection = async() => {

            updateCurrentRow(null);
            updateSelection([])
            setLoadingMask(true);
            const dto = await removeFireProtectionFunc(selection)
            const newVM = viewModelService.clone(additionalDetailsVM);
            _.set(newVM.value, 'additionalDetails.fireProtectionSystemData', dto)
            updateAdditionalDetailsVM(newVM)
            setLoadingMask(false);
    };


    const renderPercentageCell = (item, index) => {
        return <WniInputNumber
            disabled={isReadOnly}
            value={item.fireProtectionSystemPercentage}
            onValueChange = {(value)=>{
                handlePercentageValueChange(value, index)
                }
            }
            minValue={0}
            maxValue={999999999}
            onBlur={
                () => {
                    updateShowPercentageError(true)
                }
            }
            showErrors={showPercentageError}
            showGrouping={false}
    />
    }

    const renderTypeCell = (item, index) => {

        const availableValueCodes = _.get(vm, 'children[0].fireProtectionSystemType.aspects.availableValues', [])
        
        return <WniDropdownSelect
            disabled={isReadOnly}
            value = {item.fireProtectionSystemType}
            availableValues={availableValueCodes.map(elt => ({
                code:elt.code,
                name: translator({id: elt.name})
            }))}
            onValueChange = {(value)=>{
                handleTypeValueChange(value, index)
                }
            }
        />
    }

    return (
        <>
        {!isReadOnly && (
            <Flex gap="small" justifyContent="right" className="mb-10">
                <Button
                    className="wni-button-danger"
                    type="filled"
                    disabled={sortedTable.length === 0}
                    onClick={removeFireProtection}
                >
                    {translator(messages.Delete)}
                </Button>
                <Button 
                    className="gw-add" 
                    type="filled"
                    onClick= {() => addFireProtection(sortedTable)}>
                    {translator(messages.Add)}
                </Button>
            </Flex>
        )}
        <WniDataTable
        id="fireProtectionSystemTable"
        data={sortedTable}
        showSearch={false}
        selectionType="multi"
        selectedRows={selection}
        onSelectionChange= {(rows) => updateSelection(rows)}
    >
        <DisplayColumn
            header={translator(messages.Type)}
            sortable={false}
            renderCell={renderTypeCell}
        />
        <DisplayColumn
            header={translator(messages.Percentage)}
            sortable={false}
            renderCell = {renderPercentageCell}
        />
    </WniDataTable>   
    </>       
    );
};

export default FireProtectionSystemTable;
