
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';

import {
    WizardPageJumpUtil,
} from 'wni-portals-util-js';

import { CAQualificationPage } from 'wni-capability-quoteandbind-ca-react';

function CAQualificationChangePage(props) {

    const {
        wizardData: submissionVM,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        turnOffWizardPage,
        //
        clearVisitedStepsFromQuotePage,
    }  = props;

    const {
        jobID,
        sessionUUID,
        changeData: {
            isBillingOptionsEditable,
        }
    } = submissionVM.value;

    const {
        [WizardConstants.landingPage]: landingPage,
        [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const pageOnInit = useCallback(() => {
        if (landingPage) {
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'CAQualificationPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            clearVisitedStepsFromQuotePage();
        }
    }, []);

    useEffect(() => {
        pageOnInit();
    }, []);

    return (
        <CAQualificationPage {...props} />
    );

}

CAQualificationChangePage.propTypes = CAQualificationPage.propTypes;
CAQualificationChangePage.defaultProps = CAQualificationPage.defaultProps;

export default CAQualificationChangePage;