import React, { useEffect } from 'react';
import _ from 'lodash';

import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import messages from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemTypes/SimpleScheduleItem/SimpleScheduleItem.messages';
import { valueTypeMap, valueTypeDatatypeMap, valueTypePropDatatypeMap } from '../../ScheduleItemsUtil';

export function getComponentDataType(propertyInfo) {
    const { valueType } = propertyInfo;

    if (valueType === 'INTEGER' && _.has(propertyInfo, 'currency')) {
        return 'currency';
    }

    return _.get(valueTypeDatatypeMap, valueType);
}

function formatAvailableValues(availableValues) {
    return _.sortBy(availableValues, 'priority')
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.displayKey
        }));
}

function hasErrors(scheduleItem, value, path, modalData) {
    const isFirstItem = _.isEmpty(scheduleItem.scheduleItems);
    const isValueUnique = scheduleItem.scheduleItems.filter((item) => {
        return item.itemNumber !== modalData.itemNumber;
    })
        .every((item) => {
            const itemValue = _.get(item, path);
            return itemValue !== value;
        });

    return !isValueUnique && !isFirstItem;
}

function getValidationMessage(isUnique, scheduleItem, value, path, modalData, translator) {
    return (isUnique && hasErrors(scheduleItem, value, path, modalData)
        ? [translator(messages.thisFieldMustBeUnique)] : undefined)
        || (value === 0.00 ? [translator(messages.requiredField)] : undefined);
}

export default function SimpleScheduleItem({
    info,
    translator,
    modalData,
    scheduleItem,
    onValueChange,
    onValidate,
    parentOverrides
}) {
    const {
        id,
        valueType,
        required,
        label,
        editable,
        availableTypeListValues = [],
        currency,
        isUnique
    } = info;

    const path = `itemData.${id}.${valueTypeMap[valueType]}`;
    const dataType = getComponentDataType(info);
    const value = _.get(modalData, path);

    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);

    const metadata = {
        id: id,
        content: [{
            id: id,
            type: 'field',
            datatype: dataType,
            componentProps: {
                dataType: valueTypePropDatatypeMap[info.valueType],
                required: required,
                availableValues: formatAvailableValues(availableTypeListValues),
                currency: currency,
                path: path,
                readOnly: !editable,
                label: label,
                value: value,

                validationMessages: getValidationMessage(
                    isUnique, scheduleItem, value, path, modalData, translator
                ),

                ...parentOverrides['@field']
            }
        }]
    };
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [onValidate, id, isComponentValid]);

    return (
        <ViewModelForm
            uiProps={metadata}
            onValidationChange={setComponentValidation}
            onValueChange={onValueChange}
            model={modalData} />
    );
}
