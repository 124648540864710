import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './PoliciesConstructionDetails.metadata.json5';

function PoliciesConstructionDetails(props) {
    const {
        model
    } = props;

    const constructionDetails = _.get(model, 'dwellingDTO.constructionDetails') || {}

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            roofUpgraded: {
                value: _.get(constructionDetails, 'roofUpgraded') ? `Yes (${constructionDetails.roofUpgradeYear})` : 'No'
            },
            plumbingUpgraded: {
                value: _.get(constructionDetails, 'plumbingUpgraded') ? `Yes (${constructionDetails.plumbingUpgradeYear})` : 'No'
            },
            heatingUpgraded: {
                value: _.get(constructionDetails, 'heatingUpgraded') ? `Yes (${constructionDetails.heatingUpgradeYear})` : 'No'
            },
            wiringUpgraded: {
                value: _.get(constructionDetails, 'wiringUpgraded') ? `Yes (${constructionDetails.wiringUpgradeYear})` : 'No'
            }
        };
    }, []);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={constructionDetails}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PoliciesConstructionDetails.propTypes = {
    model: PropTypes.shape({}),
};
PoliciesConstructionDetails.defaultProps = {
    model: {}
};
export default PoliciesConstructionDetails;