import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useTranslator } from '@jutro/locale';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import BuildingsAndLocationsTemplate from '../../templates/BuildingsAndLocationsTemplate';
import { EditCoverages, LocationSummary, BuildingSummary } from '../../WizardFileConfig';
import messages from '../../../../../PECPWizard.messages';

function CoveragePage(props) {
    const { wizardData, jumpTo } = props;
    const { submissionVM, selectedLocation, selectedBuilding } = wizardData;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [coverageSubmission, updateCoverageSubmission] = useState({});
    const { isComponentValid, onValidate } = useValidation('CoveragePage');

    const handleNext = useCallback(async () => {
        const quoteId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
        const sessionId = _.get(submissionVM.value, 'sessionUUID');
        const locationId = _.get(selectedLocation, 'location.publicID');

        const response = await CPCoverablesService.updateCPBuildingCoverages(
            quoteId,
            locationId,
            coverageSubmission.value || _.get(selectedBuilding, 'building'),
            sessionId,
            authHeader
        );
        _.set(selectedBuilding, 'building', response);
        return wizardData;
    }, [
        coverageSubmission,
        authHeader,
        selectedBuilding,
        selectedLocation,
        wizardData,
        submissionVM
    ]);

    return (
        <WizardPage
            onNext={handleNext}
            nextLabel={translator(messages.save)}
            template={BuildingsAndLocationsTemplate}
            showCancel
            disableNext={!isComponentValid}
            finish
        >
            <LocationSummary
                submission={_.get(selectedLocation, 'location')}
                onEditClicked={() => jumpTo(0)}
            />
            <BuildingSummary
                submission={_.get(selectedBuilding, 'building')}
                onEditClicked={() => jumpTo(1)}
            />
            <hr />
            <EditCoverages
                setCoverageSubmission={updateCoverageSubmission}
                onValidate={onValidate}
                {...props}
            />
        </WizardPage>
    );
}

CoveragePage.propTypes = wizardProps;
export default CoveragePage;
