import React, { useEffect, useState, useCallback} from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import appConfig from 'app-config';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniAccountService } from 'wni-capability-gateway';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import messages from 'gw-capability-gateway-react/Accounts/Accounts.messages';
import metadata from './AccountsClaims.metadata.json5';
import gatewayMessages from '../../gateway.messages';
import styles from './AccountsClaims.module.scss';
import claimsMessages from '../../Claims/Claims.messages';
import { CurrencyField } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

const { fnolLobs, fnolLobsConfig } = appConfig;
const getFnolLobs = _.reduce(fnolLobs,
    (namesLiterals, policyType) => namesLiterals.concat(fnolLobsConfig[policyType]), []);

function AccountClaims(props) {
    const translator = useTranslator();
    const { authHeader, authUserData} = useAuthentication();
    const { interactionModel } = useDependencies('interactionModel');
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const {
            history,
            fromAccountLanding: { 
                accountDetailsData: { 
                    accountNumber,
                    accountHolder: {
                        contactType_Ext : contactType
                    }
                }
            }
    } = props;

    const  {
        fromAccountLanding: { 
            accountDetailsData = {}
        }
    } = props;
    const [claimResponse, updateClaimResponse] = useState('');
    const [claimDataTable, updateClaimDataTable] = useState('');
    const [selectedClaimLOB, updateSelectedClaimLOB] = useState('');
    const [claimLOBOptions, updateClaimLOBOptions] = useState([]);
    const [searchFilter, updateSearchFilter] = useState('');
    // const claimCenterURL = _.get(authUserData, 'claimCenterURL');

    const getLOBFilterValues = (claimsArrayResult, filter) => {
        return _.filter(claimsArrayResult, (res) => {
            return res.product === filter.value;
        });
    }

    const getSearchFilterValues = (claimsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(claimsArrayResult, (res) => {
            return Object.keys(res).some((key) => typeof (res[key]) === 'string' && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue));
        });
    }

    const getClaimDataTable = useCallback((claimsData, filter) => {
        let claimsArrayResult = claimsData;
        const combineFilter = {};
        switch (filter.type) {
            case 'LOBFilter':
                claimsArrayResult = getLOBFilterValues(claimsArrayResult, filter);
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    claimsArrayResult = getSearchFilterValues(claimsArrayResult,
                        combineFilter);
                }
                break;
            case 'SearchFilter':
                claimsArrayResult = getSearchFilterValues(claimsArrayResult, filter);
                if ((typeof (selectedClaimLOB) === 'string') && (selectedClaimLOB !== 'Filters')) {
                    combineFilter.value = selectedClaimLOB;
                    claimsArrayResult = getLOBFilterValues(claimsArrayResult, combineFilter);
                }
                break;
            case null:
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    claimsArrayResult = getSearchFilterValues(claimsArrayResult,
                        combineFilter);
                }
                break;
            default:
                claimsArrayResult = claimsData;
                break;
        };
        claimsArrayResult = claimsArrayResult.map((claimInfo) => {
            const claims = {
                product: claimInfo.product,
                claimNumber: claimInfo.claimNumber,
                dateOfLoss: _.isNil(claimInfo.lossDate) ? undefined : moment(claimInfo.lossDate).format('MMMM DD, YYYY'),
                dateOfClose_Ext: _.isNil(claimInfo.dateOfClose_Ext) ? undefined : moment(claimInfo.dateOfClose_Ext).format('MMMM DD, YYYY'),
                status: claimInfo.status,
                paid:  claimInfo.status === 'Closed' || contactType === 'person' ? claimInfo.totalPayments : undefined,
                netIncurred: claimInfo.totalIncurredNet,
                policyNumber: claimInfo.policyNumber,
                source: claimInfo.source_Ext
            };
            return claims;
        });
        updateClaimDataTable(claimsArrayResult);
    }, [contactType, searchFilter, selectedClaimLOB])

    const getClaimLOBOptions = useCallback((response) => {
        let claimLOBOptionsArray;
        claimLOBOptionsArray = [translator(messages.filterPlaceholder)];
        if (_.isEmpty(response)) {
            return claimLOBOptionsArray.map((key) => {
                return ({
                    code: key,
                    name: key
                });
            });
        }
        response.map((claimInfo) => {
            if (!_.isNil(claimInfo.product) && claimLOBOptionsArray.indexOf(claimInfo.product) === -1) {
                claimLOBOptionsArray = [...claimLOBOptionsArray, claimInfo.product];
            }
            return claimLOBOptionsArray;
        });
        return claimLOBOptionsArray.map((key) => {
            return ({
                code: key,
                name: key
            });
        });
    },[translator])

    useEffect(() => {
        if(!_.isNil(accountNumber)){
            setLoadingMask(true);
            WniAccountService.getAccountClaims(accountNumber, authHeader).then(
                (accountClaimsResponse)=>{
                    const filter = {
                        type: null,
                        value: null
                    };
                    getClaimDataTable(accountClaimsResponse, filter);
                    updateClaimResponse(accountClaimsResponse);
                    updateSelectedClaimLOB(translator(messages.filterPlaceholder));
                    updateClaimLOBOptions(getClaimLOBOptions(accountClaimsResponse));
                }
            );
            setLoadingMask(false);
        }
    }, [accountNumber])

    const handleFilterChange = (value, id) => {
        const filter = {
            type: null,
            value: null
        };
        if (id === 'LOBFilter') {
            if (value !== 'Filters') {
                filter.type = 'LOBFilter';
                filter.value = value;
            };
            updateSelectedClaimLOB(value);
            getClaimDataTable(claimResponse, filter);
        }
        if (id === 'searchFilter') {
            filter.type = 'SearchFilter';
            filter.value = value;
            updateSearchFilter(value);
            getClaimDataTable(claimResponse, filter);
        }
    }

    // jump to external cliamcenter, pending confirmation
    // const handleFileClick = () => {
    //     if (!_.isNil(claimCenterURL)){
    //         window.location.assign(claimCenterURL)
    //     } else {
    //         window.location.assign('producer-engage/');
    //     }
    // }
    const handleFileClick = () => {
        const redirectClaimsPath = `/accounts/${accountNumber}/claims`;
        return history.push({
            pathname: '/fnol-select-policy',
            state: {
                accountNumber: accountNumber,
                redirectPath: redirectClaimsPath
            }
        });
    }

    const getCell = (item, index, property) => {
        const toolTipMessage = {
            product: translator(claimsMessages.product),
            dateOfLoss: translator(claimsMessages.dateOfLoss),
            status: translator(claimsMessages.status)
        };
        return (
            <span title={toolTipMessage[property.id]}>
                {item[property.id]}
            </span>
        );
    }

    const getCurrencyCell = (item, index, property) => {
        const toolTipMessage = {
            paid: translator(claimsMessages.paid),
            netIncurred: translator(claimsMessages.netIncurred)
        };
        return (
            <span title={toolTipMessage[property.id]} className={styles.currencyContainer}>
                <CurrencyField
                    id="currency"
                    hideLabel
                    readOnly
                    value={item[property.id]}
                    datatype="object"
                    title={toolTipMessage}
                />
            </span>
        );
    }

    const getClaimDetailsLink = (item) => {
        const {
            claimNumber,
            status,
            source
        } = item
        const toolTipMessage = translator(messages.claimNumber);
        if (status === 'Draft') {
            const redirectpath = {
                pathname: '/fnol-select-policy',
                state: {
                    claimNumber,
                    redirectPath: '/claims',
                    claimStatus: item.status
                }
            };
            if (source === 'PE_EXT') {
                return (
                    <RouterLink
                        to={redirectpath}
                        className={styles.gwClaimsLinkContent}
                        title={toolTipMessage}
                    >
                        {claimNumber}
                    </RouterLink>
                );
            }
            return claimNumber;
        }
        return (
            <Link
                className={styles.gwAccountClaimsLinkContent}
                title={toolTipMessage}
                {...interactionModel.getURLObj(null, 'claimDetails', accountNumber, claimNumber)}
            >
                {claimNumber}
            </Link>
        );
    }

    const getLink = (item, index, property) => {
        // jump to external claimcenter, pending confirmation
        // const redirectPath = claimCenterURL || 'producer-engage/';
        // return (
        //     <a
        //         href={redirectPath}
        //         className={styles.gwAccountClaimsLinkContent}
        //     >
        //         {item[property.id]}
        //     </a>
        // );
        const toolTipMessage = {
            policyNumber: translator(claimsMessages.policyNumber),
            claimNumber: translator(claimsMessages.claimNumber)
        };
        if (property.id === 'claimNumber' && item.status === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'draft' })) {
            const redirectpath = {
                pathname: '/fnol-select-policy',
                state: {
                    claimNumber: item[property.id],
                    redirectPath: `/accounts/${accountNumber}/claims`,
                    claimStatus: item.status
                }
            };
            return (
                <RouterLink
                    to={redirectpath}
                    className={styles.gwAccountClaimsLinkContent}
                    title={toolTipMessage[property.id]}
                >
                    {item[property.id]}
                </RouterLink>
            );
        }
        const redirectRoute = (property.id === 'policyNumber')
            ? `/policies/${item[property.id]}/summary` : `/claims/${item[property.id]}`;
        const redirectPath = {
            pathname: redirectRoute,
            state: {
                claimNumber: item[property.id],
                redirectPath: `/accounts/${accountNumber}/claims`,
                claimStatus: item.status
            }
        };
        return (
            <RouterLink
                to={redirectPath}
                className={styles.gwAccountClaimsLinkContent}
                title={toolTipMessage[property.id]}
            >
                {item[property.id]}
            </RouterLink>
        );
    }

    const accountHasClaimLOBLines = (account) => {
        if (!_.isEmpty(account) && !_.isEmpty(account.policySummaries)
            && account.policySummaries.length > 0) {
            return account.policySummaries.some((policySummary) => {
                return policySummary.policyLines.some((line) => (
                    getFnolLobs.includes(line.lineOfBusinessCode)
                ));
            });
        }
        return false;
    }

    const getFileClaimButtonVisible = () => {
        return accountHasClaimLOBLines(accountDetailsData) && accountNumber;
    }

    const render = () => {
        const overrides = {
            claimDataTable: {
                data: claimDataTable,
                defaultSorted: [
                    { id: 'productColumnTitle', desc: true },
                    { id: 'dateOfLossColumnTitle', desc: false },
                    { id: 'dateOfCloseColumnTitle', desc: false },
                    { id: 'paidColumnTitle', desc: true },
                    { id: 'netIncurredColumnTitle', desc: true },
                ],
                showSearch: false,
                showPagination: false
            },
            LOBFilter: {
                availableValues: claimLOBOptions,
                value: selectedClaimLOB
            },
            fileClaimAction: {
                visible: getFileClaimButtonVisible()
            },
            searchFilter: {
                value: searchFilter
            },
            noDataMessageContainer: {
                visible: _.isEmpty(claimResponse)
            },
            noDataMessage: {
                value: translator(gatewayMessages.noClaimsResult)
            },
            paidColumnTitle: {
                visible: appConfig.claimFinancials
            },
            netIncurredColumnTitle: {
                visible: appConfig.claimFinancials
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell,
                getCurrencyCell,
                // getAccount,
                getClaimDetailsLink,
                getLink,
                handleLOBValueChange: (value) => handleFilterChange(value, 'LOBFilter'),
                handleSearchValueChange: (value) => handleFilterChange(value, 'searchFilter'),
                handleFileClick,
                sortCurrency: DatatableUtil.sortCurrency,
                sortString: DatatableUtil.sortString,
                sortDate: DatatableUtil.sortDate
            }
        };
        const claimPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return (
            <div className={cx(styles.summary)}>
                {claimPage}
            </div>
        );
    };

    return render();
}

AccountClaims.propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string,
                policySummaries: PropTypes.array
            })
        }).isRequired
};

export default withRouter(AccountClaims);
