import React, {
    useCallback,
    useState,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WizardPage from '../../templates/CAWizardPage';

import CALineCoverages from './CoveragesSection/CALineCoverages/CALineCoverages';

import styles from './CACoveragesPage.module.scss';
import metadata from './CACoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/CACoveragesConfigContext';
import CACoveragesConfig from './CACoverages.config';
import CALineAdditionalCoverages from './CoveragesSection/CALineAdditionalCoverages/CALineAdditionalCoverages';
import CALineExclusions from './CoveragesSection/CALineExclusions/CALineExclusions';
import CALineConditions from './CoveragesSection/CALineConditions/CALineConditions';

import CACoveargesPageMain from './CACoveragesPage.main';

function CACoveragesPage(props) {
    return (
        <WizardPage
            // onNext={onPageNext}
            //
            showRequiredInfoForFasterQuote
        >
            <CACoveargesPageMain {...props}
                //
                coveragesPageProps = {{
                    onValidate: _.noop,
                    updateErrorsAndWarningsForCovComponent: _.noop,
                    //
                    showErrors: _.noop,
                    //
                    setIsEditing: _.noop,
                    isEditing: false,
                    //
                    setLoadingLineCoverages: _.noop,
                    loadingLineCoverages: false,
                    //
                    isEditable: false,
                }}
            />
        </WizardPage>
    );
}

CACoveragesPage.propTypes = WizardPage.propTypes;
export default CACoveragesPage;
