import React from 'react'

const PoliciesSummaryClausesDisplayablesArray = (props) => {
    const {
        displayables = []
    } = props
    return <>
        {displayables.map((displayable) => {
            const {
                inputSetMode,
                propertyName,
                label,
                rangeValue,
            } = displayable
            if (inputSetMode === 'dynamicrange') {
                return <div key={propertyName} className='d-flex'>
                    <h5 className='flex-1'>{label}</h5>
                    <h5 className='flex-1'>{rangeValue}</h5>
                </div>
            }
            return null
        })}
    </>
}

export default PoliciesSummaryClausesDisplayablesArray