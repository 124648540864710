import React, {
    useContext,
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { SubmissionService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AgencyAndAgentInfoComponent } from 'wni-capability-gateway-react';
import { WniAccountService, WniSubmissionService } from 'wni-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { R1Config } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WindowUtil, ErrorsAndWarningsUtil } from 'wni-portals-util-js';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import metadata from './NewSubmissionPage.metadata.json5';
import styles from './NewSubmissionPage.module.scss';
import messages from './NewSubmissionPage.messages';

function NewSubmissionPage(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    // const translator = useTranslator();
    const {
        isAnExistingAccount, submissionVM, postalCode,
        updateSubmissionVM, allOrganisationValue, authHeader, sysLocalDate
    } = props;
    const [productValue, updateProductValue] = useState([]);
    const [selectedOrganisationValue, updateOrganisation] = useState('');
    const [showErrors, updateShowErrors] = useState(false);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    // const [showNoProductError, setShowNoProductError] = useState(false);
    const { history } = props;
    const { lobQuoteURL } = appConfig;
    const {
        onValidate,
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('NewSubmissionPage');
    // const { domainCompany } = useDependencies('domainCompany');
    // const { interactionModel } = useDependencies('interactionModel');
    const {
        domainCompany,
        interactionModel,
    } = useDependencies(['domainCompany', 'interactionModel']);

    const availableStatesForR1 = domainCompany.availableStates;

    const startSubmission = useCallback(
        async () => {
            const producerCodeRes = await WniAccountService
                .getAgencyMatchData(submissionVM.producerCode.value, 5, authHeader);
            if (!_.isNil(producerCodeRes) && producerCodeRes.length > 0) {
                updateServiceCallInProgress(true);
                const productCode = _.get(submissionVM, 'productCode.value');
                const submissionResponse = await SubmissionService
                    .createSubmission(submissionVM.value, authHeader);
                const newValidationIssues = ErrorsAndWarningsUtil
                    .getValidationIssues(_.get(submissionResponse, 'errorsAndWarnings_Ext'));
                const hasError = newValidationIssues.some((issue) => issue.type === 'error');
                if (hasError) {
                    updateValidationIssues(newValidationIssues);
                    updateServiceCallInProgress(false);
                    return;
                }
                const newSubmissionResponse = submissionResponse;
                if (newSubmissionResponse.jobNumber > 0) {
                    if (!_.isNil(lobQuoteURL[productCode])) {
                        const nextLocation = {
                            quoteentry: {
                                postalCode: postalCode,
                                quoteID: newSubmissionResponse.jobNumber,
                                // producerCode_Ext: newSubmissionResponse.producerCode_Ext
                            }
                        };
                        await WniSubmissionService.addRecentlyViewedSubmission(
                            newSubmissionResponse.jobNumber,
                            authHeader
                        );
                        history.push(lobQuoteURL[productCode], nextLocation);
                    } else {
                        JobUtil.openJobInXCenter(newSubmissionResponse.jobNumber);
                    }
                }
                updateServiceCallInProgress(false);
            } else {
                modalApi.showConfirm({
                    title: 'Warning',
                    message: messages.noProducerCode,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel'
                });
            }
        },
        [authHeader, history, lobQuoteURL, postalCode, submissionVM]
    );
    const refreshProducts = useCallback(
        async (newSubmissionVM) => {
            try {
                const submission = {
                    ...newSubmissionVM.value
                };
                let productCodeResponse = [];
                if (submission.producerCodePublicID_Ext) {
                    productCodeResponse = await WniAccountService
                        .getAvailableProductsForAccount(
                            submission.accountNumber,
                            submission.state,
                            submission.country,
                            submission.effectiveDate,
                            submission.producerCode,
                            submission.producerCodePublicID_Ext,
                            authHeader
                        );
                    // setShowNoProductError(_.isEmpty(productCodeResponse));
                }

                const productCodeAvailableValue = _.isEmpty(productCodeResponse) ? []
                    : productCodeResponse
                        .filter((value) => !value.isRiskReserved)
                        .map(({ productCode, productName }) => {
                            return {
                                code: productCode, name: productName
                            };
                        });
                updateProductValue(productCodeAvailableValue);
                const productCode = _.get(newSubmissionVM.value, 'productCode');
                if (!productCodeAvailableValue
                    .find((option) => option.code === productCode)
                ) {
                    _.set(newSubmissionVM, 'productCode', undefined);
                }
            } catch {
                updateProductValue([]);
                modalApi.showAlert({
                    title: gatewayMessages.modalError,
                    message: gatewayMessages.gatewayViewsModalErrorData,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                });
            }
            return newSubmissionVM;
        },
        [authHeader]
    );

    const handleValueOrganisation = (value) => {
        updateOrganisation(value);
    };

    const writeValue = useCallback(
        async (value, path) => {
            let newSubmissionVMClone = viewModelService.clone(submissionVM);
            let code;
            if (path === 'producerCode') {
                code = _.get(value, 'code');
                _.set(newSubmissionVMClone, path, code);
                _.set(newSubmissionVMClone, 'producerCodePublicID_Ext', _.get(value, 'publicID'));
                updateSubmissionVM(newSubmissionVMClone);
            } else {
                _.set(newSubmissionVMClone, path, value);
                updateSubmissionVM(newSubmissionVMClone);
            }

            if ((path === 'producerCode'
            || path === 'effectiveDate'
            || path === 'state')
            && !!(_.get(newSubmissionVMClone, 'producerCode.value')
            && _.get(newSubmissionVMClone, 'state.value.code')
            && _.get(newSubmissionVMClone, 'effectiveDate.value'))) {
                // Set default product to Personal Auto for R1;
                _.set(newSubmissionVMClone, 'productCode', 'PersonalAuto');
                newSubmissionVMClone = await refreshProducts(newSubmissionVMClone);

                updateSubmissionVM(newSubmissionVMClone);
            }

            if (path === 'producerCode' && _.get(value, 'code') === '') {
                _.set(newSubmissionVMClone, 'productCode', '');
                updateSubmissionVM(newSubmissionVMClone);
            }
        },
        [refreshProducts, submissionVM, updateSubmissionVM, viewModelService]
    );

    const handleCancel = useCallback(
        () => {
            const accountNumber = _.get(submissionVM, 'accountNumber.value');
            modalApi.showConfirm({
                title: messages.cancelQuote,
                message: messages.cancelMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then((result) => {
                if (result === 'cancel' || result === 'close') {
                    return _.noop();
                }
                if (accountNumber) {
                    interactionModel.goToPage(null, history, 'accountSummary', accountNumber);
                }
                return true;
            }, _.noop);
        }, [history, submissionVM]
    );
    const handleValidation = useCallback(
        () => {
            WindowUtil.scrollToInvalidField(invalidFields);
            updateShowErrors(true);
        },
        [updateShowErrors, invalidFields]
    );
    const isAgencyAndAgentInfoValid = useCallback(() => {
        const { producerCode } = submissionVM;
        if (producerCode.value) {
            return true;
        }
        return false;
    }, [submissionVM]);

    const dateInputChange = (value, path) => {
        writeValue(value, path);
    };

    useEffect(() => {
        registerComponentValidation(isAgencyAndAgentInfoValid);
    }, [registerComponentValidation, isAgencyAndAgentInfoValid]);

    useEffect(() => {
        const productCode = _.get(submissionVM, 'productCode.value');
        if (productCode) {
            refreshProducts(submissionVM);
        }
    }, []);

    const submissionProductCodeOverrideProps = {
        availableValues: productValue,
        disabled: !_.get(submissionVM, 'producerCode.value'),
        showErrors: !_.get(submissionVM, 'productCode.value'),
    };

    /* if (showNoProductError) {
        submissionProductCodeOverrideProps
            .validationMessages = [translator(R1Config.R1ValidationMessages.R1NoProductCode)];
    } */

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        submissionState: {
            disabled: !isAnExistingAccount,
            availableValues: availableStatesForR1,
        },
        submissionDate: {
            // minDate: new Date().setHours(0, 0, 0, 0),
            minDate: sysLocalDate,
            value: _.get(submissionVM.value, 'effectiveDate'),
            onValueChange: dateInputChange,
            showErrors
        },
        agencyAndAgentInfo: {
            model: submissionVM,
            producerCodePath: 'producerCode',
            onAgencyChange: writeValue,
            shouldSetExternalAgencyVal: true,
            showErrors: showErrors
        },
        submissionProductCode: submissionProductCodeOverrideProps,
        submissionOrganisation: {
            visible: false,
            availableValues: allOrganisationValue,
            onValueChange: handleValueOrganisation,
            value: selectedOrganisationValue
        },
        cancel: {
            disabled: !isAnExistingAccount
        },
        next: {
            disabled: !isAnExistingAccount || isServiceCallInProgress || !isComponentValid
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            startSubmission: isComponentValid ? startSubmission : handleValidation,
            onCancel: handleCancel,
            onValidate: onValidate
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            agencyagentinfo: AgencyAndAgentInfoComponent
        }

    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValidationChange={onValidate}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
}
NewSubmissionPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    isAnExistingAccount: PropTypes.bool,
    /**
     * Instance of NewSubmissionDTO
     */
    submissionVM: PropTypes.shape({
        producerCode: PropTypes.shape({
            value: PropTypes.string
        }),
        value: PropTypes.shape({})
    }),
    allOrganisationValue: PropTypes.arrayOf(PropTypes.string),
    postalCode: PropTypes.string,
    updateSubmissionVM: PropTypes.func,
    authHeader: PropTypes.shape({}),
    sysLocalDate: PropTypes.shape({})
};

NewSubmissionPage.defaultProps = {
    isAnExistingAccount: false,
    submissionVM: {},
    allOrganisationValue: [],
    postalCode: '',
    updateSubmissionVM: undefined,
    authHeader: {},
    sysLocalDate: {}
};
export default withRouter(NewSubmissionPage);
