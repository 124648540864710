import _ from 'lodash';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';

export default class WniMultiDocumentRetrievalService {
    static printAllDocument(documents, authHeader,
        callBack = _.noop, errorCallback = _.noop,
        specificName = null, targetProxiedServiceUrl = 'wniMultiDocumentRetrieval') {
        let publicIDString = '';
        _.each(documents, (doc) => {
            publicIDString = publicIDString.concat(_.get(doc, 'publicID')).concat('_');
        });
        publicIDString = publicIDString.substring(0, publicIDString.length - 1);
        const firstDocumentPublicID = documents[0].publicID;
        const serviceUrl = getProxiedServiceUrl(targetProxiedServiceUrl);
        const templateDownloadEndpointURL = serviceUrl.concat(`/${firstDocumentPublicID}?publicID=${publicIDString}`);
        const fileName = specificName || 'combinedDocument';
        return this.generateDocument(templateDownloadEndpointURL, fileName, authHeader,
            callBack, errorCallback);
    }

    static generateDocument(downloadEndpointURL, filename, authHeader,
        callBack = _.noop, errorCallback = _.noop) {
        const params = {
            method: 'GET',
            url: downloadEndpointURL,
            headers: {
                ...authHeader,
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer'
        };
        return fetch(downloadEndpointURL, params).then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const downloadLink = document.createElement('a');
                        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                        downloadLink.setAttribute('download', filename);
                        downloadLink.style.display = 'none';
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        downloadLink.remove();
                    }
                    callBack();
                });
            } else {
                errorCallback(response);
            }
        }).catch((exception) => {
            errorCallback(exception);
        });
    }
}
