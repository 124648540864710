// import WALFrontendScheduleItemSyncUtil from './util/WALFrontendScheduleItemSyncUtil'
// import WALOptionsUtil from './util/WALOptionsUtil'
// import WALScheduleOptionValuesSortUtil from './util/WALScheduleOptionValuesSortUtil'
import WALCoverageAvailableUtil from "./util/WALCoverageAvailableUtil"

/**
 * @type {{
 *  [scheduleConfigOption: String]: Object
 * }}
 */
const scheduleConfig = {
    /**
     * Table column visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
     *  [clauseCode: String]: Array.<String>
     * }}
     */
    visibleColumnIdsOverride: {
    },
    /**
     * Schedule details visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleFieldIdsOverride: {
    },
    /**
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    fieldsNotNeedSyncWhileChange: {
    },
    /**
     * @type {{
    *  [clauseCode: String]: {
    *      [scheduleItemID: String]: (scheduleItem: Object, allOptions: Array) => Array
    *  }
    * }}
    */
    optionFilters: {
    },
    /**
     * Return a function which return schedule after frontend sync
     * 
     * @type {{
    *  [clasueCode: String]: {
    *      [scheduleItemID: String]: (schedule: Object, localPathToSchedule: String) => Object
    *  }
    * }}
    */
    frontendScheduleItemSync: {
        // return a function which return schedule after frontend sync
    },
    /**
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (optionA: Object, optionB: Object) => Number
     *  }
     * }}
     */
    optionValueSortFunction: {
    }
}

/**
 * @type {{
*  [clauseCode: String]: Array.<String>
* }}
*/
const termConfig = {
    termsNotChangeWithSync: [
        // group by coverage
    ]
}

/**
 * A key(coverage code)-value(term code) set of coverage has limit term
 * And limit term display right of coverage label
 * 
 * @type {{
*  [clauseCode: String]: String
* }}
*/
const limitCoverageConfig = {
    
    // Line coverages
    // Liability: Limit of Liability
    'WALWatercraftPolicy_Ext': 'WALWatercraftPolicyLimitOfLiability_Ext',
    // Medical Payments: Medical Limit
    'WALMedicalPayments_Ext': 'WALMedicalPaymentsMedicalLimit_Ext',
    // Uninsured Watercraft Coverage: Limit
    'WALUninsuredWatercraftCoverage_Ext': 'WALUninsuredWatercraftCoverageLimit_Ext',
    // Replacement Cost - Personal Property: Limit
    'WALReplacementCostPersonalProp_Ext': 'WALReplacementCostPersonalPropLimit_Ext',

    // Watercraft coverages
    // Physical Damage: Deductible of Physical Damage
    'WALPhysicalDamage_Ext': 'WALPhysicalDamageDeductibleOfPhysicalDamage_Ext',
    // Trip Interruption Coverage: Limit
    'WALTripInterruptionCoverage_Ext': 'WALTripInterruptionCoverageLimit_Ext'
}

/**
 * @type {{
*  [clauseCode: String]: (...params: any) => Boolean
* }}
*/
const coverageAvailable = {
    // 'WALReplacementCostCoverage_Ext': WALCoverageAvailableUtil.isCurPageCovPage,
    // 'WALWatercraftAgreedValueCoverage_Ext': WALCoverageAvailableUtil.isCurPageCovPage,
}

/**
 * @type {{
*  [clauseCode: String]: {
*       [scheduleItemID: String]: (...params: any) => Boolean
*   }
* }}
*/
const covTermAvailable = {
    'WALPhysicalDamage_Ext': {
        'WALPhysicalDamageWatercraftAmount_Ext': WALCoverageAvailableUtil.isCurPageCovPage,
        'WALPhysicalDamageMotorAmount_Ext': WALCoverageAvailableUtil.isCurPageCovPage,
        'WALPhysicalDamageTrailerAmount_Ext': WALCoverageAvailableUtil.isCurPageCovPage
    }
}

/**
 * @enum {String}
 */
const ClauseComponentType = {
    Toggle: 'Toggle',
}

/**
 * default as checkbox
 * @type {{
*  [clauseCode: String]: String
* }}
*/
const clauseComponentTypeMap = {
    'WALBoatLiftCoverage_Ext': ClauseComponentType.Toggle
}

export default {
    scheduleConfig,
    termConfig,
    limitCoverageConfig,
    coverageAvailable,
    covTermAvailable,
    ClauseComponentType,
    clauseComponentTypeMap,
}