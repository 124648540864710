/**
 * From: gw-portals-wizard-react/Wizard/templates/DigitalWizardPageTemplate.jsx
 * This Component is extracted from gw-portals-wizard-react to avoid the circular
 * dependency problem between gw-portals-wizard-react and gw-capability-gateway-react,
 * since QuoteDetails/Document are currently being used by both.
 */
import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { InlineLoader, Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { messages } from 'wni-platform-translations';
import { pageTemplateProps, WizardContext } from '@xengage/gw-portals-wizard-react';
// import WizardSidebar from '../components/WizardSidebar';
import { WizardSidebar, WizardPageHeader } from 'gw-portals-wizard-components-ui';
import WizardPageErrorComponent from '../components/WizardPageError/WizardPageErrorComponent';
import MobileWizardSteps from '../components/MobileWizardSteps/BaseMobileWizardSteps';
import styles from './BaseDigitalWizardPageTemplate.module.scss';

import { Button } from '@jutro/legacy/components';

function renderLoader(isLoading) {
    return isLoading
        ? (
            <InlineLoader loading={isLoading} className="gw-inline-loader" />
        ) : null;
}

function BaseDigitalWizardPageTemplate(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const wizardContext = useContext(WizardContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';
    const [showSidebar, setShowSidebar] = useState(false);
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        // renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        // ===========================
        customMessageModal,
        wizardSidebarExtension: WizardSidebarExtensionComponent,
        renderWizardFooterComponent,
    } = props;
    useEffect(() => {
        switch (breakpoint) {
            case 'phoneWide':
            case 'phone':
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    
    // const contextComponent = useCallback(() => {
    //     return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    // }, [renderContextComponent, wizardSnapshot]);
    const contextComponent = useCallback(() => {
        // return <WizardPageHeader isSideHeader={isSideHeader} />;
        return <WizardPageHeader />;
    }, [wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isPhone
    });

    const nextButton = useMemo(() => (
        <Button
            id="gw-wizard-Next"
										
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransiton}
            onClick={onNext}
						 
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </Button>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
		  
        <Tooltip placement="bottom" content={nextButtonTooltip}>
					  
            <span>{nextButton}</span>
					   
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isPhone
    });

    const getWizardSidebarExtension = () => {
        if (!_.isFunction(WizardSidebarExtensionComponent)) {
            return (null);
        }
        return (
            <WizardSidebarExtensionComponent />
        );
    };

    const getDefaultWizardFootButtons = () => {
        if(!showCancel && !showPrevious && !showNext) {
            return null
        }
        return (
            <div className="wizard-footer-buttons">
                <div className="buttonCancel">
                    {(showCancel) && (
                        <Button
                            id="gw-wizard-cancel"
                            disabled={disableCancel || isPerformingTransiton}
                            type="text"
                            onClick={onCancel}
                        >
                            {translator(cancelLabel)}
                            {renderLoader(isLoadingCancel)}
                        </Button>
                    )}
                </div>
                <div className="buttonGrounp">
                    {(showPrevious) && (
                        <Button
                            id="gw-wizard-previous"
                            disabled={disablePrevious || isPerformingTransiton}
                            type="outlined"
                            onClick={onPrevious}
                        >
                            {translator(previousLabel)}
                            {renderLoader(isLoadingPrevious)}
                        </Button>
                    )}
                    {(showNext) && renderNextButton}
                </div>
            </div>
        );
    };

    const getWizardContent = () => {
        return (
            <div className="wizard-content">
                {isPhone ? <MobileWizardSteps toggleSideBar={toggleSideBar} /> : null}
                {showWizardHeader && contextComponent()}
                
                <WizardPageErrorComponent />
                <div className='wizard-content-page'>{content}</div>
                <div className="wizard-footer-polaceholder" />
            </div>
        );
    };

    // ====================================================================
    const wizardPageFooterContent = _.isFunction(renderWizardFooterComponent)?
        renderWizardFooterComponent({ 
            isPerformingTransiton,
            renderLoader
        }) : getDefaultWizardFootButtons();
    
    return (
        <div className={`${styles.digitalWizard} wizardPage`}>
            {showContextComponent && contextComponent()}
            <div className={`${wizardClassName} wizardPageContent`}>
                <div className="wizard-sidebar-wrapper">
                    <div className="sidebar-sticky">
                        <div className="wizard-sidebar-content">
                            {showSidebar ? <WizardSidebar customMessageModal={customMessageModal} /> : null}
                            {getWizardSidebarExtension()}
                        </div>
                    </div>
                </div>
                { getWizardContent() }
            </div>
            <div className="wizard-footer-wrapper">
                <div className="wizard-footer-sideBar" />
                <div className="wizard-footer-content">
                    {wizardPageFooterContent}
                </div>
            </div>
        </div>
    );
}

BaseDigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    // renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string,
    renderWizardFooterComponent: PropTypes.func,
};

BaseDigitalWizardPageTemplate.defaultProps = {
    // renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: '',
    cancelLabel: messages.Cancel,
    renderWizardFooterComponent: undefined,
};

export default BaseDigitalWizardPageTemplate;
