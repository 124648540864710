import React, {useState} from "react"
import _ from "lodash";
import { Flex } from "@jutro/layout";
import { Button } from "@jutro/components";
import { WniDataTable, WniToggle } from "wni-common-base-components";
import { DisplayColumn } from '@jutro/legacy/datatable';
import { useTranslator } from '@jutro/locale';
import SingleInjuryIncident from "./SingleInjuryIncident/SingleInjuryIncident";
import messages from './InjuryIncidents.messages'



const InjuryIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const injuryIncidentInitValue = {
    injuredContact: {
        primaryAddress: {
            country: "US"
        }
    }
}

const InjuryIncidents = (props) => {
    const {
        injuryIncidents = [],
        updateInjuryIncidents,
    } = props;

    const translator = useTranslator();


    const [injuryIncidentQuestionValue, setInjuryIncidentQuestion] = useState(() => {
        return injuryIncidents.length > 0 ? InjuryIncidentQuestionValue.Yes : InjuryIncidentQuestionValue.No
    })
    const [singleInjuryIncidentPopupStatus, setSingleInjuryIncidentPopupStatus] = useState({
        isOpen: false,
        originInjuryIncident: injuryIncidentInitValue,
        editingInjuryIncidentIndex: undefined
    })

    const [selectedIncidentNumbers, setSelectedIncidentNumebrs] = useState([])

    const closePopup = () => {
        setSingleInjuryIncidentPopupStatus({
            isOpen: false,
            originInjuryIncident: injuryIncidentInitValue,
            editingInjuryIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = (injuryIncident, editingInjuryIncidentIndex) => {

        let updatedInjuredIncidents
        if (_.isNil(editingInjuryIncidentIndex)) {
            // new added item
            updatedInjuredIncidents = injuryIncidents.concat([injuryIncident])
        } else {
            // existing item
            updatedInjuredIncidents = injuryIncidents.toSpliced(editingInjuryIncidentIndex, 1, injuryIncident)
        }
        updateInjuryIncidents(updatedInjuredIncidents)
        closePopup()
    }

    return <>
        <WniToggle
            id='injuryIncidentQuestion'
            label={translator(messages.InjuryIncidentsQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: InjuryIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: InjuryIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setInjuryIncidentQuestion(value)
            }}
            disabled={injuryIncidents.length > 0}
            value={injuryIncidentQuestionValue}
        />
        {injuryIncidentQuestionValue === InjuryIncidentQuestionValue.Yes &&
            <>
                <Flex gap="small" justifyContent="right" className="mb-10">
                    <Button
                        type="primary"
                        onClick={() => setSingleInjuryIncidentPopupStatus({
                            isOpen: true,
                            originInjuryIncident: injuryIncidentInitValue
                        })}
                        disabled={singleInjuryIncidentPopupStatus.isOpen}
                        label={messages.AddInjuries}
                    />
                    <Button
                        type="primary"
                        className="wni-button-danger"
                        onClick={() => {
                            const newInjuryIncidents = injuryIncidents.filter((_incident, index) => {
                                if (selectedIncidentNumbers.includes(index)) {
                                    return false
                                }
                                return true
                            })
                            updateInjuryIncidents(newInjuryIncidents)
                        }}
                        disabled={selectedIncidentNumbers.length === 0}
                        label={messages.DeleteInjuries}
                    />
                </Flex>
                <WniDataTable
                    id="injuryIncidentsTable"
                    showSearch= {false}
                    showPagination= {false}
                    data={injuryIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                    rowIdpath="number"
                    selectionType="multi"
                    onSelectionChange={(selectedNumebrs) => setSelectedIncidentNumebrs(selectedNumebrs)}
                    selectedRows={selectedIncidentNumbers}
                >
                    <DisplayColumn
                        id="injuredSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="injuredLastNameColumn"
                        header={translator(messages.InjuryLastName)}
                        textAlign='center'
                        path="injuredContact.lastName"
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <Flex gap="small" justifyContent="right" className="mb-10">
                                <Button
                                    className="btn-link"
                                    onClick={() => setSingleInjuryIncidentPopupStatus({
                                        isOpen: true,
                                        originInjuryIncident: item,
                                        editingInjuryIncidentIndex: index
                                    })}
                                    label={messages.ViewOrEdit}
                                />
                            </Flex>
                        }}
                    />
                </WniDataTable>
                {singleInjuryIncidentPopupStatus.isOpen && <SingleInjuryIncident
                    originInjuryIncident={singleInjuryIncidentPopupStatus.originInjuryIncident}
                    editingInjuryIncidentIndex={singleInjuryIncidentPopupStatus.editingInjuryIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                />}
            </>
        }
        
    </>
}

export default InjuryIncidents
