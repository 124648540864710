import { defineMessages } from 'react-intl';

export default defineMessages({
    editHouseholdResident: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.Edit Household Residents',
        defaultMessage: 'Edit Household Residents'
    },
    addHouseholdResident: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.Add Household Residents',
        defaultMessage: 'Add Household Residents'
    },
    deleteHouseholdTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.Delete Household Title',
        defaultMessage: 'Remove Household Resident?'
    },
    deleteHouseholdMessage: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.Delete Household Message',
        defaultMessage: 'Are you sure you want to remove this individual from the list of household residents?'
    },
    editResidenceLocation: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.Edit Residence Location',
        defaultMessage: 'Edit Residence Location'
    },
    editPreviousLocation: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.Edit Previous Address',
        defaultMessage: 'Edit Previous Address'
    },
    residenceQuestionDropdown: {
        id: "wni.quoteandbind.ho.householdinfo.Residence Dropdown",
        defaultMessage: "Is this residence under new construction or major structural renovation?"
    },
    generalContractorToggle: {
        id: "wni.quoteandbind.ho.householdinfo.General Contractor Toggle",
        defaultMessage: "Is the named insured the General Contractor?"
    },
    constructionToggle: {
        id: "wni.quoteandbind.ho.householdinfo.construction Toggle",
        defaultMessage: "Has construction beyond the foundation level occurred?"
    },
    estimatedCompletionDateDropdown: {
        id: "wni.quoteandbind.ho.householdinfo.estimated completion date",
        defaultMessage: "What is the estimated completion date of the project?"
    },
    isThisResidenceOccupiedSeasonallyToggle: {
        id: "wni.quoteandbind.ho.householdinfo.is this residence occupied seasonally",
        defaultMessage: "Is this residence occupied seasonally?"
    },
    totalNumOfHouseholdResidentsTooltip: {
        id: "wni.quoteandbind.ho.householdinfo.Enter the number of residents. Please list all residents of the household residents below, including children.",
        defaultMessage: "Enter the number of residents. Please list all residents of the household residents below, including children."
    },
    effectiveDateValidation: {
        id: 'wni.policychange.ho.frontendValidation.effectiveDate',
        defaultMessage: 'The selected effective date is before the product has become available. Please select an effective date after {productEffectiveDate}'
    },
    effectiveDateOver35Days:{
        id: "wni.quoteandbind.ho.householdinfo.EffectiveDate Over 35 Days in the future.",
        defaultMessage: "Applications cannot be submitted more than 35 days in advance of the effective date. Please resubmit within the allowed timeframe."
    }
});
