import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

// mode: default / editable
import AQPolicyHolderPage from './pages/PolicyHolder/AQPolicyHolderPage';
import AQAccountSummaryPage from './pages/AccountSummary/AQAccountSummaryPage';
import AQPaymentPage from './pages/Payment/AQPaymentPage';
import AQBillingPage from './pages/Billing/AQBillingPage';
// mode: readOnly
import AQPolicyHolderReadOnlyPage from './pages/PolicyHolder/AQPolicyHolderPage.readonly';

setComponentMapOverrides(
    {
        AQPolicyHolderPage: { component: 'AQPolicyHolderPage' },
        AQAccountSummaryPage: { component: 'AQAccountSummaryPage' },
        AQPaymentPage: { component: 'AQPaymentPage' },
        AQPolicyHolderReadOnlyPage: { component: 'AQPolicyHolderReadOnlyPage' },
        AQBillingPage: { component: 'AQBillingPage' }
    },
    {
        AQPolicyHolderPage,
        AQAccountSummaryPage,
        AQPaymentPage,
        AQPolicyHolderReadOnlyPage,
        AQBillingPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as AQAccountQuotesWizard } from './AQAccountQuotesWizard';

export { default as AQPaymentPage } from './pages/Payment/AQPaymentPage';

export { default as IssueApprovedQuotesPopup }  from './pages/AccountSummary/components/IssueApprovedQuotesPopup/IssueApprovedQuotesPopup';

