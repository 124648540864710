

import React from 'react';
import { IMAAISCommonFormsReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMAAISCommonFormsChangeReadOnlyPage = (props) => {
    return <IMAAISCommonFormsReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMAAISCommonFormsChangeReadOnlyPage
