import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMAAISCommonFormsService } from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext'
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMAAISCommonFormsPage.metadata.json5';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import WizardPage from '../../templates/IMWizardPage';
import CommonCoveragesCard from './component/CommonCoveragesCard';
import ConditionsAndExclusionsCard from './component/ConditionsAndExclusionsCard';
import AdditionalInsuredsCard from './component/AdditionalInsuredsCard';

const IMAAISCommonFormsPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        markFollowingWizardStepsUnvisited,
    } = props;

    const { initialValidation, onValidate, isComponentValid } = useValidation('AAISCommonFormsPage');
    const { authHeader } = useAuthentication();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [aaisCommonForms, setAAISCommonForms] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        },
        lobData: {
            inlandMarine: {
                coverables: {
                    stateSimpleInfos = {}
                }
            }
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCommonForms = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await IMAAISCommonFormsService.getAAISCommonFormsWithSync(jobID, sessionUUID, authHeader) 
            : await IMAAISCommonFormsService.getAAISCommonForms(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setAAISCommonForms(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCommonForms()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: IMAAISCommonFormsService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setAAISCommonForms(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await IMAAISCommonFormsService.onAAISCommonFormsNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const accordionCommonProps = {
        commonForms: aaisCommonForms,
        setCommonForms: (newCommonForms) => {
            setAAISCommonForms(newCommonForms)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
            markFollowingWizardStepsUnvisited()
        },
        isEditing,
        setIsEditing,
        isEditable: true,
        showErrors,
        onValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        CommonCoveragesCard: {
            ...accordionCommonProps
        },
        ConditionsAndExclusionsCard: {
            ...accordionCommonProps
        },
        AdditionalInsuredsCard: {
            ...accordionCommonProps
        },
        stateTable: {
            data: [stateSimpleInfos]
        },
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            CommonCoveragesCard: CommonCoveragesCard,
            ConditionsAndExclusionsCard: ConditionsAndExclusionsCard,
            AdditionalInsuredsCard: AdditionalInsuredsCard,
        },
    };

    const disableNavigator = isEditing || isLoadingMask

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {aaisCommonForms && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default IMAAISCommonFormsPage
