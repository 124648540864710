import React from 'react';
import { CPRisksReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPRisksChangeReadOnlyPage(props) {

    return (
        <CPRisksReadOnlyPage {...props} />
    );

}

CPRisksChangeReadOnlyPage.propTypes = CPRisksReadOnlyPage.propTypes;
CPRisksChangeReadOnlyPage.defaultProps = CPRisksReadOnlyPage.defaultProps;

export default CPRisksChangeReadOnlyPage;