import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';

import { WniChangeConflict } from 'wni-capability-gateway';
import { CPPMultiQuoteChangeService } from 'wni-capability-policychange-cpp';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';

import { CPRisksChangePage } from 'wni-capability-policychange-cp-react';

function CPPCPRisksChangePage(props) {

    const {
        steps,
        currentStep,
    } = props;

    
    // =======================PreQuote check BEGIN===============================
    
    
    const getPreQuotePageNext = (onChangePageNext) => {
        let isCurrentPagePreQuote = false;
        const preQuotePageIndex = steps.findLastIndex((step) => step.isPreQuotePage);
        if (preQuotePageIndex > 0) {
            isCurrentPagePreQuote = steps[preQuotePageIndex].id === currentStep.id;
        }

        if (isCurrentPagePreQuote) {
            return onChangePageNext;
        }

        return undefined;
    };


    // ========================PreQuote check END==============================
    
    return (
        <CPRisksChangePage quoteService={CPPMultiQuoteChangeService}
            getPreQuotePageNext={getPreQuotePageNext}
            {...props} />
    );

}

CPPCPRisksChangePage.propTypes = CPRisksChangePage.propTypes;
CPPCPRisksChangePage.defaultProps = CPRisksChangePage.defaultProps;

export default CPPCPRisksChangePage;
