
import React from 'react';
import _ from 'lodash';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLUnmannedAircraftPage } from 'wni-capability-quoteandbind-gl-react';

function GLUnmannedAircraftChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLUnmannedAircraftPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLUnmannedAircraftChangePage.propTypes = GLUnmannedAircraftPage.propTypes;
GLUnmannedAircraftChangePage.defaultProps = GLUnmannedAircraftPage.defaultProps;

export default GLUnmannedAircraftChangePage;