
import React, {
    useCallback, useState, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { ViewModelForm, ViewModelServiceContext, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { HOTooltipUtil as tooltips } from 'wni-portals-tooltip';
import ResidenceUtil from '../../util/ResidenceUtil';
import defaultMetadata from './ConstructionComponent.metadata.json5';
import SolidFuelPopupCompnent from '../SolidFuelPopupCompnent/SolidFuelPopupCompnent';
import { Button } from '@jutro/legacy/components';

const ConstructionComponent = (props) => {
    const modalApi = useWniModal();
    const {
        model: constructionVM,
        isReadOnly,
        onValidate,
        showErrors,
        onValueChange,
        baseState,
        effectiveDate,
        isRCTSuccess,
        prefillRCTCallFlag,
        assumptionsRCTCall,
        RCTSubmission,
        launchRCTURL,
        coverageForm,
        isRequiredForIssuance,
        showBRMessage,
        messages,
        editSolidFuel,
        removeSolidFuels,
        isFeb19EntityChangeVer,
        constructionFieldReadOnlyFlags,
        DPOverrideProps,
        metaDataOverride,
        linePath,
        policySource,
    } = props;

    const {
        isConstructionOptionReadOnly,
        isNumberofBathroomsReadOnly,
        isConstructionTypeDescriptionReadOnly,
        isFoundationReadOnly,
        isExteriorWallCoveringReadOnly,
        isRoofReadOnly,
        isRoofSlopeReadOnly,
        isResidenceHaveCentralHeatReadOnly,
        isCentralHeatingTypeReadOnly,
        isBasementFinishedReadOnly,
        isGarageReadOnly,
        isNumberofStallsReadOnly,
        isPrimaryHeatingReadOnly,
    } = constructionFieldReadOnlyFlags;

    const {
        coveragePartType_Ext: coveragePartType,
        constructionType,
        logType_Ext: logType,
        existChinkingBetweenLogs_Ext: existChinkingBetweenLogs,
        foundationType,
        roofType,
        roofSlopeType_Ext: roofSlopeType,
        roofUpgradeYear,
        garageType_Ext: garageType,
        yearBuilt,
        storiesNumber_Ext: storiesNumber,
        totalLivingArea_Ext: totalLivingArea,
        plumbingUpgradeYear,
        electricalSystemUpgradeYear_Ext: electricalSystemUpgradeYear,
        primaryHeatingType,
        secondaryHeatingType_Ext: secondaryHeatingType,
        solidFuelBurningDevice_Ext: solidFuelBurningDevice,
        plumbingType_Ext: plumbingType,
        ampService_Ext: ampService,
        hopconstructionOption_Ext: hopconstructionOption,
        isAnyKnobAndTubeWiring_Ext: isAnyKnobAndTubeWiring,
        isResidenceHaveCentralHeat_Ext: isResidenceHaveCentralHeat,
        selectedCentralHeats_Ext: selectedCentralHeats,
        anySolidFuelBurningDevice_Ext: anySolidFuelBurningDevice,
        exteriorWallCovering_Ext: exteriorWallCovering,
        wiringType_Ext: wiringType
    } = constructionVM.value;

    const metadata = metaDataOverride || defaultMetadata;

  
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const COALANDWOOD = ['Coal_Ext', 'Wood_Ext'];
    const INELIGIBLECONSTRUCTIONS = ['DomeHome_Ext', 'EarthHome_Ext', 'Manufactured_Ext', 'MobileHome_Ext'];
    const INELIGIBLECONSTRUCTIONSWNM = INELIGIBLECONSTRUCTIONS.concat('Modular_Ext', 'LogHomeHandHewn_Ext');
    const INELIGIBLEROOFTYPES = ['Rolled_Ext', 'targravel', 'Rubber_Ext']

    // useEffect(() => {
    //     if (isFeb19EntityChangeVer && _.includes(wiringType, 'KnobAndTube')) {
    //         _.remove(wiringType, (e) => {
    //             return e === 'KnobAndTube';
    //         })
    //     }
    // }, [isFeb19EntityChangeVer]);

    const showHasCompletedMaintenance = useCallback(() => {
        const effectiveDateYearInt = parseInt(effectiveDate.year);
        const roofUpgradeYearInt = parseInt(roofUpgradeYear);
        if (roofType === 'wood'
            && (effectiveDateYearInt - roofUpgradeYearInt) > 5
            && coverageForm !== 'ho4' && coverageForm !== 'ho6') {
            return true;
        }
        return false;
    },[coverageForm, effectiveDate, roofType, roofUpgradeYear]);

    const handleConstructionType = useCallback((value, path) => {
        onValueChange(value, path);
        if (value === 'log' || value === 'LogHomeHandHewn_Ext') {
            onValueChange('LogSolid', 'exteriorWallCovering_Ext');
        }
        if (value !== 'log') {
            onValueChange(null, 'logType_Ext');
            onValueChange(null, 'existChinkingBetweenLogs_Ext');
            onValueChange(null, 'chinkingUpdatedType_Ext');
            onValueChange(null, 'logsJoinedSealedPattern_Ext');
            onValueChange(null, 'haveLogsBeenSealed_Ext');
            onValueChange(null, 'routineMaintanceFrequency_Ext');
        }
    }, []);

    const handleLogType = useCallback((value, path) => {
        onValueChange(value, path);
        if (value !== 'handhewn' && value !== 'milled') {
            onValueChange(null, 'existChinkingBetweenLogs_Ext');
            onValueChange(null, 'haveLogsBeenSealed_Ext');
        }
    }, []);

    const handleExistChinkingBetweenLogs = useCallback((value, path) => {
        onValueChange(value, path);
        if (value) {
            onValueChange(null, 'logsJoinedSealedPattern_Ext');
        } else {
            onValueChange(null, 'chinkingUpdatedType_Ext');
        }
    }, []);

    const handleIsResidenceHaveCentralHeat = useCallback((value, path) => {
        onValueChange(value, path);
        if (value) {
            onValueChange(null, 'centralHeatExplain_Ext');
        } else {
            onValueChange(null, 'selectedCentralHeats_Ext');
            onValueChange(null, 'primaryHeatingTypeDescription');
            onValueChange(null,'primaryHeatingFuelTankLocation_Ext');
        }
    }, []);

    const handleCentralHeatingType = useCallback((value, path) => {
        onValueChange(value, path)
        if (!_.isEmpty(value)) {
            const isCoalAndWoodChoosed = COALANDWOOD.some((item) => value.includes(item));
            if (isCoalAndWoodChoosed) {
                onValueChange(true, 'anySolidFuelBurningDevice_Ext');
            } else {
                onValueChange(false, 'anySolidFuelBurningDevice_Ext');
            }
        }
    }, [])

    const numberofUnitinBuildingDefaultValue = useCallback(() => {
        // if (coverageForm === 'ho4' || coverageForm === 'ho6') {
        //     return null;
        // }
        if (coveragePartType === 'singlefamilydwelling_Ext') {
            return 'unitsnum1';
        }
        if (coveragePartType === 'twofamilydwelling_Ext') {
            return 'unitsnum2_4';
        }
        // if (coveragePartType === 'townhouse_Ext') {
        //     return 'unitsnum5plus';
        // }
    }, [coveragePartType]);

    const handleBlur = useCallback(async () => {
        if (!prefillRCTCallFlag
            && !_.isNil(yearBuilt)
            && !_.isNil(storiesNumber)
            && !_.isNil(totalLivingArea)) {
            await assumptionsRCTCall();
        }
        showBRMessage();
    }, [prefillRCTCallFlag, storiesNumber, totalLivingArea, yearBuilt, hopconstructionOption]);

    const handleYearBuild = useCallback((value, path) => {
        onValueChange(ResidenceUtil.removeStr(value), path);
        if (_.get(constructionVM, `${path}.aspects.valid`)) {
            const buildingAge = 30;
            const effectiveDateYearInt = parseInt(effectiveDate.year);
            const yearBuiltInt = parseInt(value);
            if (effectiveDateYearInt - yearBuiltInt <= buildingAge) {
                if (_.isEmpty(plumbingUpgradeYear)) {
                    _.set(constructionVM, 'plumbingUpgradeYear', value);
                }
                if (_.isEmpty(electricalSystemUpgradeYear)) {
                    _.set(constructionVM, 'electricalSystemUpgradeYear_Ext', value);
                }
            }
        }
    }, [constructionVM, effectiveDate, onValueChange]);

    const solidFuelBurningDeviceDefaultValue = useCallback((value) => {
        const finalPrimaryVal = _.get(value, 'primaryVal') || primaryHeatingType;
        const finalSecondaryVal = _.get(value, 'secondaryVal') || secondaryHeatingType;
        if (finalPrimaryVal === 'Wood_Ext' || finalSecondaryVal === 'Wood_Ext') {
            return 'Wood';
        }
        if (finalPrimaryVal === 'Coal_Ext' || finalSecondaryVal === 'Coal_Ext') {
            return 'Coal';
        }
        return 'None';
    }, [primaryHeatingType, secondaryHeatingType]);

    // const handlePrimaryHeating = (value ,path) => {
    //     onValueChange(value ,path);
    //     const res = solidFuelBurningDeviceDefaultValue({primaryVal: value});
    //     onValueChange(res, 'solidFuelBurningDevice_Ext');
    // };

    // const handleSecondaryHeating = (value, path) => {
    //     onValueChange(value ,path);
    //     const res = solidFuelBurningDeviceDefaultValue({secondaryVal: value});
    //     onValueChange(res, 'solidFuelBurningDevice_Ext');
    // };

    const showSolidFuelModal = useCallback(() => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            // actionBtnLabel: messages.ok,
            // cancelBtnLabel: messages.cancel,
            size: 'lg',
            isReadOnly,
            solidFuelBurningDevicesVM: _.get(constructionVM, 'solidFuelBurningDevices_Ext'),
            editSolidFuel,
            removeSolidFuels,
            solidFuelTypeOptions: _.get(constructionVM, 'solidFuelTypeOptions_Ext.value'),
            viewModelService,
            linePath
        };
        return modalApi.showModal(
            <SolidFuelPopupCompnent {...componentProps} />
        );
    }, [isReadOnly, constructionVM]);

    const handleEditSolidFuel = useCallback(() => {
        showSolidFuelModal().then((res) => {
        }).catch(() => {
            _.noop();
        });
    }, []);

    const ViewOrEditButtonLink = useCallback(() => {
        if (anySolidFuelBurningDevice) {
            return <Button className="wni-button-link" onClick={handleEditSolidFuel} >
                {translator(isReadOnly ? messages.viewDetail : messages.viewOrEditDetail)}
            </Button>
        }
        return null
    }, [anySolidFuelBurningDevice]);

    const primaryHeatingFuelTankLocationVisible = useCallback(() => {
        if (coverageForm !== 'ho4' && coverageForm !== 'ho6') {
            if (isFeb19EntityChangeVer && _.includes(selectedCentralHeats, 'oil')) {
                return true;
            }
            if (!isFeb19EntityChangeVer && primaryHeatingType === 'oil') {
                return true;
            }
        }
        return false;
    }, [coverageForm, isFeb19EntityChangeVer, primaryHeatingType, selectedCentralHeats]);

    const anySolidFuelBurningDeviceDefaultValue = useCallback(() => {
        if (!_.isEmpty(selectedCentralHeats)) {
            const isCoalAndWoodChoosed = COALANDWOOD.some(item => selectedCentralHeats.includes(item));
            if (isCoalAndWoodChoosed) {
                return true;
            } 
            return false;
        }
    }, [selectedCentralHeats]);

    const ampServiceKnockoutObj = useCallback(() => {
        const isShowAmpServiceKnockout = coverageForm !== 'ho4' && coverageForm !== 'ho6' && ampService === 'AmpService60';
        const ampServiceIneligibleMessage = translator(messages.ampServiceIneligible);
        return {
            isShowKnockout: isShowAmpServiceKnockout,
            ineligibleMessage: ampServiceIneligibleMessage
         };
    },[ampService, coverageForm, messages.ampServiceIneligible, translator]);
    const ampServiceComponentProps = ResidenceUtil.getIneligibleKnockout(ampServiceKnockoutObj(), linePath);

    const exteriorWallCoveringKnockoutObj = useCallback(() => {
        const isShowexteriorWallCoveringKnockout = coverageForm !== 'ho4' && coverageForm !== 'ho6' 
            && (exteriorWallCovering === 'StuccoSynthetic' || exteriorWallCovering === 'Plywood');
        const exteriorWallCoveringName = exteriorWallCovering === 'Plywood' ? exteriorWallCovering : 'Stucco Synthetic';
        const exteriorWallCoveringIneligibleMessage = translator(messages.exteriorWallCoveringIneligible,{exteriorWallCoveringType:exteriorWallCoveringName});
        return {
            isShowKnockout: isShowexteriorWallCoveringKnockout,
            ineligibleMessage: exteriorWallCoveringIneligibleMessage
         };
    },[coverageForm, exteriorWallCovering, messages.exteriorWallCoveringIneligible, translator]);
    const exteriorWallCoveringeComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(exteriorWallCoveringKnockoutObj(), linePath);

    const constructionKnockoutObj = useCallback(() => {
        const isShowconstructionKnockout = baseState === 'AK' ? INELIGIBLECONSTRUCTIONS.includes(constructionType) : INELIGIBLECONSTRUCTIONSWNM.includes(constructionType);
        const constructionIneligibleMessage = translator(messages.constructionTypeIneligible,{constructionType: ResidenceUtil.getConstructionTypeName(constructionType)});
        return {
            isShowKnockout: isShowconstructionKnockout,
            ineligibleMessage: constructionIneligibleMessage
         };
    },[baseState, constructionType, messages.constructionTypeIneligible, translator]);
    const constructionComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(constructionKnockoutObj(), linePath);

    const roofTypeKnockoutObj = useCallback(() => {
        const isShowRoofTypeKnockout = INELIGIBLEROOFTYPES.includes(roofType);
        const roofTypeIneligibleMessage = translator(messages.roofTypeIneligible,{roofType: ResidenceUtil.getRoofTypeName(roofType)});
        return {
            isShowKnockout: isShowRoofTypeKnockout,
            ineligibleMessage: roofTypeIneligibleMessage
         };
    },[messages.roofTypeIneligible, roofType, translator]);
    const roofTypeComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(roofTypeKnockoutObj(), linePath);

    const roofSlopeTypeKnockoutObj = useCallback(() => {
        const isShowRoofSlopeTypeKnockout = !_.isNil(roofSlopeType) && roofSlopeType.includes('flat');
        const roofSlopeTypeIneligibleMessage = translator(messages.roofSlopeTypeIneligible);
        return {
            isShowKnockout: isShowRoofSlopeTypeKnockout,
            ineligibleMessage: roofSlopeTypeIneligibleMessage
         };
    },[messages.roofSlopeTypeIneligible, roofSlopeType, translator]);
    const roofSlopeTypeComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(roofSlopeTypeKnockoutObj(), linePath);

    const plumbingTypeKnockoutObj = useCallback(() => {
        const isShowPlumbingTypeKnockout = coverageForm !== 'ho4' && coverageForm !== 'ho6' && !_.isNil(plumbingType) && plumbingType.includes('galv');
        const plumbingTypeIneligibleMessage = translator(messages.plumbingTypeIneligible);
        return {
            isShowKnockout: isShowPlumbingTypeKnockout,
            ineligibleMessage: plumbingTypeIneligibleMessage
         };
    },[coverageForm, messages.plumbingTypeIneligible, plumbingType, translator]);
    const plumbingTypeComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(plumbingTypeKnockoutObj(), linePath);

    const knobAndTubeWiringKnockoutObj = useCallback(() => {
        const isShowKnobAndTubeWiringKnockout = isFeb19EntityChangeVer && coverageForm !== 'ho4'
            && coverageForm !== 'ho6' && isAnyKnobAndTubeWiring;
        const knobAndTubeWiringIneligibleMessage = translator(messages.knobAndTubeWiringIneligible);
        return {
            isShowKnockout: isShowKnobAndTubeWiringKnockout,
            ineligibleMessage: knobAndTubeWiringIneligibleMessage
         };
    },[coverageForm, isAnyKnobAndTubeWiring, isFeb19EntityChangeVer, messages.knobAndTubeWiringIneligible, translator]);
    const knobAndTubeWiringComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(knobAndTubeWiringKnockoutObj(), linePath);

    const wiringTypeKnockoutObj = useCallback(() => {
        const isShowWiringTypeKnockout = coverageForm !== 'ho4' && coverageForm !== 'ho6' 
            && !_.isNil(wiringType) && wiringType.includes('aluminum');
        const wiringTypeIneligibleMessage = translator(messages.wiringTypeIneligible);
        return {
            isShowKnockout: isShowWiringTypeKnockout,
            ineligibleMessage: wiringTypeIneligibleMessage
         };
    },[coverageForm, messages.wiringTypeIneligible, translator, wiringType]);
    const wiringTypeComponentOverrideProps = ResidenceUtil.getIneligibleKnockout(wiringTypeKnockoutObj(), linePath);
   
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(metadata.componentContent), []);

    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            isRequiredForIssuance
        },
        editReplacementCostButton: {
            visible: !isReadOnly
        },
        replacementCostGrid: {
            // readOnly: isRCTSuccess || !_.isNil(RCTReplacementCost) || isReadOnly,
            readOnly: true,
            visible: coverageForm !== 'ho4'
        },
        constructionOption: {
            // readOnly: isRCTSuccess || !_.isNil(RCTConstructionOption) || isReadOnly,
            onBlur: handleBlur,
            readOnly: isConstructionOptionReadOnly || isReadOnly,
            visible: coverageForm !== 'ho4',
            requiredForIssuance: true,
        },
        yearBuilt: {
            onBlur: handleBlur,
            onValueChange: handleYearBuild
        },
        numberofStories: {
            required: linePath === 'homeowners' && policySource !== 'Converted' && coverageForm !== 'ho4',
            visible: coverageForm !== 'ho4',
            onBlur: handleBlur
        },
        numberofBathrooms: {
            // readOnly: isRCTSuccess || !_.isNil(RCTNumberOfBathrooms) || isReadOnly,
            readOnly: isNumberofBathroomsReadOnly || isReadOnly,
            visible: coverageForm !== 'ho4'
        },
        finishedLivingArea: {
            required: linePath === 'homeowners' && policySource !== 'Converted',
            onBlur: handleBlur
        },
        numberofUnitinBuilding: {
            // visible: coveragePartType === 'townhouse'
            defaultValue: numberofUnitinBuildingDefaultValue()
        },
        construction: {
            // readOnly: isRCTSuccess || !_.isNil(RCTConstructionType) || isReadOnly
            onValueChange: handleConstructionType,
            label: messages.construction,
            availableValues: ResidenceUtil.getConstructionAvailableValues(
                _.get(constructionVM, 'constructionType.aspects.availableValues'), baseState, translator
            ),
            ineligibleTooptip: constructionComponentOverrideProps,
        },
        constructionTypeDescription: {
            visible: constructionType === 'other',
            label: messages.constructionType,
            readOnly: isConstructionTypeDescriptionReadOnly || isReadOnly,
        },
        logType: {
            visible: constructionType === 'log' && baseState === 'AK' && coverageForm !== 'ho4' && coverageForm !== 'ho6',
            label: messages.logType,
            onValueChange: handleLogType
        },
        existChinkingBetweenLogs: {
            visible: (logType === 'handhewn' || logType === 'milled')
                    && baseState === 'AK'
                    && coverageForm !== 'ho4'
                    && coverageForm !== 'ho6',
            onValueChange: handleExistChinkingBetweenLogs
        },
        chinkingUpdatedType: {
            visible: Boolean(existChinkingBetweenLogs && baseState === 'AK') && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        logsJoinedSealedPattern: {
            visible: Boolean(existChinkingBetweenLogs === false && baseState === 'AK') && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        haveLogsBeenSealed: {
            visible: (logType === 'handhewn' || logType === 'milled') && baseState === 'AK' && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        routineMaintanceFrequency: {
            visible: constructionType === 'log' && baseState === 'AK' && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        foundation: {
            // readOnly: isRCTSuccess || !_.isNil(RCTFoundationType) || isReadOnly,
            required: linePath === 'homeowners' && policySource !== 'Converted' && coverageForm !== 'ho4',
            visible: coverageForm !== 'ho4',
            label: messages.foundation,
            readOnly: isFoundationReadOnly || isReadOnly,
        },
        exteriorWallCovering: {
            // readOnly: isRCTSuccess || !_.isNil(ECTExteriorWallCovering) || isReadOnly,
            readOnly: isExteriorWallCoveringReadOnly || isReadOnly,
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            ineligibleTooptip: exteriorWallCoveringeComponentOverrideProps,
        },
        roof: {
            // readOnly: isRCTSuccess || !_.isNil(RCTRoofType) || isReadOnly,
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            label: messages.roof,
            readOnly: isRoofReadOnly || isReadOnly,
            ineligibleTooptip: roofTypeComponentOverrideProps,
        },
        roofSlope: {
            readOnly: isRoofSlopeReadOnly || isReadOnly,
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            availableValues: ResidenceUtil.getAvailableValues(constructionVM, 'sampleRoofSlopeType_Ext'),
            ineligibleTooptip: roofSlopeTypeComponentOverrideProps,
        },
        roofingUpgradeDate: {
            visible: coverageForm !== 'ho4',
            onValueChange: (value, path) => {
                onValueChange(ResidenceUtil.removeStr(value), path);
            }
        },
        // new data start
        isResidenceHaveCentralHeat: {
            label: messages.isResidenceHaveCentralHeat,
            visible:
                coverageForm !== 'ho4' &&
                coverageForm !== 'ho6' &&
                isFeb19EntityChangeVer,
            onValueChange: handleIsResidenceHaveCentralHeat,
            readOnly: isResidenceHaveCentralHeatReadOnly || isReadOnly,
        },
        centralHeatExplain: {
            visible: isResidenceHaveCentralHeat === false
                        && coverageForm !== 'ho4'
                        && coverageForm !== 'ho6'
                        && isFeb19EntityChangeVer,
        },
        centralHeatingType: {
            visible: Boolean(isResidenceHaveCentralHeat
                        && coverageForm !== 'ho4'
                        && coverageForm !== 'ho6'
                        && isFeb19EntityChangeVer),
            readOnly: isCentralHeatingTypeReadOnly || isReadOnly,
            availableValues: _.get(constructionVM, 'value.selectedCentralHeatsOptions_Ext'),
            onValueChange: handleCentralHeatingType,
            // availableValues: ResidenceUtil.getAvailableValues(constructionVM, 'sampleSelectedCentralHeats_Ext')
        },
        centralHeatingExplain: {
            visible: _.includes(selectedCentralHeats, 'other')
                        && coverageForm !== 'ho4'
                        && coverageForm !== 'ho6'
                        && isFeb19EntityChangeVer,
        },
        anySolidFuelBurningDevice: {
            secondaryLabel: <ViewOrEditButtonLink />,
            visible: isFeb19EntityChangeVer,
            defaultValue: anySolidFuelBurningDeviceDefaultValue()
        },
        isAnyKnobAndTubeWiring: {
            visible: isFeb19EntityChangeVer
                        && coverageForm !== 'ho4'
                        && coverageForm !== 'ho6',
            ineligibleTooptip: knobAndTubeWiringComponentOverrideProps,
        },
        // new data end
        heatingUpgradeDate: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            onValueChange: (value, path) => {
                onValueChange(ResidenceUtil.removeStr(value), path);
            }
        },
        plumbingUpgradeDate: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            onValueChange: (value, path) => {
                onValueChange(ResidenceUtil.removeStr(value), path);
            }
        },
        wiringUpgradeDate: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            onValueChange: (value, path) => {
                onValueChange(ResidenceUtil.removeStr(value), path);
            }
        },
        basementFinished: {
            required: linePath === 'homeowners' && policySource !== 'Converted' && coverageForm !== 'ho4' && coverageForm !== 'ho6' && foundationType === 'FullBasement',
            readOnly: isBasementFinishedReadOnly || isReadOnly,
            visible: foundationType === 'FullBasement' && coverageForm !== 'ho4',
            // readOnly: isRCTSuccess || !_.isNil(RCTBasementFinishedPercentage) || isReadOnly
        },
        hasCompletedMaintenance: {
            visible: showHasCompletedMaintenance(),
            tooltip: {
                text: tooltips.hasCompletedMaintenance
            }
        },
        garage: {
            // readOnly: isRCTSuccess || !_.isNil(RCTGarageType) || isReadOnly,
            readOnly: isGarageReadOnly || isReadOnly,
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        garageExplain: {
            visible: garageType === 'Other_Ext' && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        numberofStalls: {
            readOnly: isNumberofStallsReadOnly || isReadOnly,
            visible: garageType !== 'None' && coverageForm !== 'ho4' && coverageForm !== 'ho6',
            // readOnly: isRCTSuccess || !_.isNil(RCTStallsNumber) || isReadOnly,
        },
        isGarageHeated: {
            visible: garageType !== 'None' && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        primaryHeatingFuelTankLocation: {
            // visible: _.includes(selectedCentralHeats, 'oil') && coverageForm !== 'ho4' && coverageForm !== 'ho6'
            visible: primaryHeatingFuelTankLocationVisible()
        },
        // POI-29153 remove, version 0219
        // exsiting data atart
        primaryHeating: {
            // readOnly: isRCTSuccess || !_.isNil(RCTPrimaryHeatingType) || isReadOnly,
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6' && !isFeb19EntityChangeVer,
            showRequired: false,
            readOnly: isPrimaryHeatingReadOnly || isReadOnly,
            // onValueChange: handlePrimaryHeating
            label: messages.primaryHeating
        },
        secondaryHeating: {
            defaultValue: 'None_Ext',
            // readOnly: isRCTSuccess || !_.isNil(RCTSecondaryHeatingType) || isReadOnly,
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6' && !isFeb19EntityChangeVer,
            // onValueChange: handleSecondaryHeating
        },
        secHeatingFuelTankLocation: {
            visible: secondaryHeatingType === 'oil'
                        && coverageForm !== 'ho4'
                        && coverageForm !== 'ho6'
                        && !isFeb19EntityChangeVer
        },
        solidFuelBurningDevice: {
            defaultValue: solidFuelBurningDeviceDefaultValue(),
            availableValues: _.get(constructionVM, 'value.solidFuelTypeOptions_Ext'),
            visible: !isFeb19EntityChangeVer
            // readOnly: isRCTSuccess || !_.isNil(RCTSolidFuelBurningDevice),
        },
        devicesNumber: {
            visible: (solidFuelBurningDevice === 'Coal'
            || solidFuelBurningDevice === 'FireplaceInsert'
            || solidFuelBurningDevice === 'Pellet'
            || solidFuelBurningDevice === 'TraditionalFireplace'
            || solidFuelBurningDevice === 'Wood'
            || solidFuelBurningDevice === 'Other')
            && !isFeb19EntityChangeVer
        },
        stoveLocated: {
            visible: (solidFuelBurningDevice === 'Wood'
            || solidFuelBurningDevice === 'Coal')
            && !isFeb19EntityChangeVer
        },
        isSolidFuelDeviceABarrel: {
            visible: (solidFuelBurningDevice === 'Wood'
            || solidFuelBurningDevice === 'Coal')
            && !isFeb19EntityChangeVer
        },
        // exsiting data end
        plumbing: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            availableValues: ResidenceUtil.getAvailableValues(constructionVM, 'plumbingType'),
            label: messages.plumbing,
            ineligibleTooptip: plumbingTypeComponentOverrideProps,
        },
        plumbingTypeDescription: {
            visible: _.includes(plumbingType, 'other') && coverageForm !== 'ho4' && coverageForm !== 'ho6',
            label: messages.plumbingTypeDescription
        },
        wiring: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            // availableValues: ResidenceUtil.getAvailableValues(constructionVM, 'wiringType')
            availableValues: _.get(constructionVM, 'value.wiringTypeOptions_Ext'),
            label: messages.wiring,
            ineligibleTooptip: wiringTypeComponentOverrideProps,
        },
        electricalType: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            label: messages.electricalType
        },
        ampService: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            ineligibleTooptip: ampServiceComponentProps,
        },
        ampServiceExplain: {
            visible: ampService === 'Other' && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        }
    };

    const overrides = _.mergeWith(overrideProps, DPOverrideProps, overridesForIssuance, (obj, src) => {
        return {...obj, ...src}
    });
    const resolvers = {
        resolveCallbackMap: {
            onLaunchRCTURL: launchRCTURL
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={constructionVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

ConstructionComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    baseState: PropTypes.string,
    effectiveDate: PropTypes.shape({}),
    isRCTSuccess: PropTypes.bool,
    prefillRCTCallFlag: PropTypes.bool,
    assumptionsRCTCall: PropTypes.func,
    RCTSubmission: PropTypes.shape({}),
    launchRCTURL: PropTypes.func,
    coverageForm: PropTypes.string,
    showBRMessage: PropTypes.func,
    messages: PropTypes.object,
    editSolidFuel: PropTypes.func,
    removeSolidFuels: PropTypes.func,
    isFeb19EntityChangeVer: PropTypes.bool,
    policySource: PropTypes.string,
    linePath: PropTypes.string,
    constructionFieldReadOnlyFlags: PropTypes.shape({
        isConstructionOptionReadOnly: PropTypes.bool,
        isNumberofBathroomsReadOnly: PropTypes.bool,
        isConstructionTypeDescriptionReadOnly: PropTypes.bool,
        isFoundationReadOnly: PropTypes.bool,
        isExteriorWallCoveringReadOnly: PropTypes.bool,
        isRoofReadOnly: PropTypes.bool,
        isRoofSlopeReadOnly: PropTypes.bool,
        isResidenceHaveCentralHeatReadOnly: PropTypes.bool,
        isCentralHeatingTypeReadOnly: PropTypes.bool,
        isBasementFinishedReadOnly: PropTypes.bool,
        isGarageReadOnly: PropTypes.bool,
        isNumberofStallsReadOnly: PropTypes.bool,
        isPrimaryHeatingReadOnly: PropTypes.bool,
    }),
    DPOverrideProps: PropTypes.shape({}),
};

ConstructionComponent.defaultProps = {
    model: {},
    showErrors: true,
    isReadOnly: false,
    onValidate: _.noop,
    baseState: '',
    effectiveDate: {},
    isRCTSuccess: false,
    prefillRCTCallFlag: false,
    assumptionsRCTCall: _.noop,
    RCTSubmission: {},
    launchRCTURL: _.noop,
    coverageForm: '',
    showBRMessage: _.noop,
    messages: {},
    editSolidFuel: _.noop,
    removeSolidFuels: _.noop,
    isFeb19EntityChangeVer: true,
    policySource: '',
    constructionFieldReadOnlyFlags: {},
    DPOverrideProps: {},
};

export default ConstructionComponent;
