
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { withIntl, useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { policyCommissionMessages } from 'gw-capability-gateway-commission-react';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniDateUtil } from 'wni-portals-util-js';
import { WniPolicyService } from 'wni-capability-gateway';
import { FieldComponent } from '@jutro/components';
import policyBillingStyles from './PoliciesBilling.module.scss';
import metadata from './PoliciesBilling.metadata.json5';
import messages from './PoliciesBilling.messages';

function BillingComponent(props) {
    const {
        intl,
        history,
        fromAccountLanding,
        fromAccountLanding: {
            policyDetailsData: { policyResponse }
        }
    } = props;

    const translator = useTranslator();

    const [billingData, updateBillingData] = useState([]);
    const [currentBillingData, updateCurrentBillingData] = useState({});
    const [billingInvoiceData, updateBillingInvoiceData] = useState([]);
    const [policyPeriodDropDownOptions, updatePolicyPeriodDropDownOptions] = useState([]);
    const [selectedPeriod, updateSelectedPeriod] = useState('');
    const [isBillingInfoAvailable, updateIsBillingInfoAvailable] = useState(true);
    const [billingMethod, updateBillingMethod] = useState();
    const authHeader = _.get(useAuthentication(), 'authHeader');
    const policyNumber = _.get(policyResponse, 'policyNumber');

    const [bcPaymentScheduleData, updateBCPaymentScheduleData] = useState([]);

    useEffect(() => {
        async function retrieveBCPaymentScheduleData() {
            const data = await WniPolicyService.retrieveBCPaymentScheduleData(
                policyNumber,
                authHeader
            );
            updateBCPaymentScheduleData(data);
            return data;
        }
        retrieveBCPaymentScheduleData();
    }, []);

    const getPolicyPeriodDropDownOptions = (data) => {
        if (_.isEmpty(data)) {
            return null;
        }
        const now = moment();
        let currentPolicyPeriodBillingSummary;
        return data.map((key, index) => {
            let policyPeriodName = `${_.get(key, 'termNumber_Ext')}: ${key.periodName}`;
            if (
                !currentPolicyPeriodBillingSummary
                && key.effectiveDate
                && now.isAfter(key.effectiveDate)
                && key.expirationDate
                && now.isBefore(key.expirationDate)
            ) {
                currentPolicyPeriodBillingSummary = key;
                policyPeriodName = `${policyPeriodName} ${translator(
                    policyCommissionMessages.current
                )}`;
            }
            return {
                code: key.periodName,
                name: policyPeriodName
            };
        });
    };
    
    const getPolicyBillingSummaryInfo = () => {
        const billingInfo = _.get(fromAccountLanding, 'billingData');
        const billingSummary = _.get(billingInfo, 'policyPeriodBillingSummaries');
        // set bcPaymentScheduleData to billingSummary
        if (_.get(bcPaymentScheduleData, 'length') > 0) {
            _.each(billingSummary, (item) => {
                const termNumber = _.get(item, 'termNumber_Ext');
                const billingPaymentSchedule = _.find(bcPaymentScheduleData, (data) => _.get(data, 'termNumber') === termNumber);
                _.set(item, 'billingPaymentSchedule', billingPaymentSchedule);
            });
        }
        
        if (!_.isEmpty(billingInfo) && billingSummary.length > 0) {
            const dropDownOptions = getPolicyPeriodDropDownOptions(billingSummary);
            let selectedBillingSummary = null;
            if (!_.isEmpty(selectedPeriod)) {
                selectedBillingSummary = billingSummary.find((data) => _.get(data, 'periodName') === selectedPeriod);
            } else {
                const currentPeriodOption = _.find(dropDownOptions, (opt) => _.includes(_.get(opt, 'name'), '(Current)'));
                const optSelectedPeriod = _.get(currentPeriodOption, 'code')
                selectedBillingSummary = billingSummary.find((data) => _.get(data, 'periodName') === optSelectedPeriod);
            }
            updateBillingData(billingSummary);
            updateCurrentBillingData(selectedBillingSummary);
            updatePolicyPeriodDropDownOptions(dropDownOptions);
            updateSelectedPeriod(_.get(selectedBillingSummary, 'periodName'));

            const schedulePaymentData = _.get(selectedBillingSummary, 'billingPaymentSchedule.schedulePayments', []);
            updateBillingInvoiceData(schedulePaymentData);
            // const invoiceData = selectedBillingSummary.invoices;
            // const paymentScheduleDataTable = getPaymentScheduleDataTable(invoiceData);
            // updateBillingInvoiceData(paymentScheduleDataTable);

            const policyBillingMethod = _.get(selectedBillingSummary, 'billingMethod');
            updateBillingMethod(policyBillingMethod);
        } else {
            updateIsBillingInfoAvailable(false);
        }
    };


    useEffect(() => {
        getPolicyBillingSummaryInfo();
    }, [currentBillingData, bcPaymentScheduleData]);

    const getBillingStatus = (isDelinquent) => {
        if (isDelinquent) {
            return translator(messages.delinquentLabel);
        }
        return translator(messages.inGoodStandingLabel);
    };

    const getFormattedDate = (date) => {
        return intl.formatDate(new Date(date), { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const getCell = (items, index, property) => {
        const tooltipMessage = {
            dueDate: translator(messages.dueDateLabel),
            paidStatus: translator(messages.paidStatusLabel)
        };
        let showPastDueAlert = false;
        if (
            moment().isAfter(items.dueDate)
            && items.outStanding.amount > 0
            && property.id === 'paidStatus'
        ) {
            // eslint-disable-next-line no-param-reassign
            items.paidStatus = translator(messages.pastDue);
            showPastDueAlert = true;
        }

        const classNameShowPastDueAlert = classNames({
            [policyBillingStyles.negativePastDueAlert]: showPastDueAlert
        });

        return (
            <span title={tooltipMessage[property.id]} className={classNameShowPastDueAlert}>
                {items[property.id]}
            </span>
        );
    };

    const getDueDate = (item, index, property) => {
        return (
            <FieldComponent
                id={`dueDate_${index}`}
                // value={item[property.id]}
                readOnly
                hideLabel
                showOptional={false}
                value={ WniDateUtil.formatDateWithPattern(item[property.id], "MM/DD/YYYY")}
            />
        );
    };

    const getBoldCell = (item, index, property) => {
        return (
            <FieldComponent
                id={`${item.id}_${index}`}
                value={item[property.id]}
                readOnly
                hideLabel
                showOptional={false}
            />
        );
    }

    const getFormattedCurrency = (item, index, property) => {
        const tooltipMessage = {
            paid: translator(messages.PaidLabel),
            outStanding: translator(messages.outstandingLabel),
            statementAmount: translator(messages.statementAmountLabel)
        };

        return (
            <span
                title={tooltipMessage[property.id]}
            >
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            </span>
        );
    };

    const handlePolicyPeriodChange = (policyPeriod) => {
        const policyChangeData = billingData.find((data) => data.periodName === policyPeriod);
        const schedulePaymentData = _.get(policyChangeData, 'billingPaymentSchedule.schedulePayments', []);
        updateBillingInvoiceData(schedulePaymentData);
        // const invoiceData = policyChangeData.invoices;
        // const paymentScheduleDataTable = getPaymentScheduleDataTable(invoiceData);
        // updateBillingInvoiceData(paymentScheduleDataTable);
        updateCurrentBillingData(policyChangeData);
        updateSelectedPeriod(policyPeriod);
    };

    const getPaymentScheduleDataTable = useCallback((invoiceData) => {
        let invoiceArrayResult = [];
        invoiceArrayResult = invoiceData.map((data) => {
            const objData = {
                statementDate: data.invoiceDate,
                dueDate: data.dueDate,
                paidStatus: data.paidStatus,
                status: data.status,
                paid: data.paid,
                net: data.unpaid,
                statementAmount: data.amount,
                payer: currentBillingData.primaryPayer_Ext,
                gross: currentBillingData.totalPremium
            };
            return objData;
        });
        return invoiceArrayResult;
    }, [currentBillingData])

    const handleViewAccountBilling = () => {
        const {
            account: { accountNumber }
        } = policyResponse;
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({ pathname: nextPath, accountDetailsData: { accountNumber: accountNumber } });
    };

    const showPastDueLine = _.get(currentBillingData, 'aggregatedPastDueAmount')
            && _.get(currentBillingData, 'aggregatedPastDueAmount.amount') > 0;

    const showCurrentPaymentLine = _.get(currentBillingData, 'nextDueAmount')
            && _.get(currentBillingData, 'nextDueAmount.amount') > 0;

    const getPolicyBillingStatus = useCallback(() => {
        const billingPaymentSchedule = _.get(currentBillingData, 'billingPaymentSchedule');
        const status = _.get(billingPaymentSchedule, 'policyPeriodStatus');
        return status;
    }, [currentBillingData]);

    const getStatusRenderObject = useCallback((status) => {
        let icon = '';
        let className = '';
        switch (status) {
            case 'In Good Standing':
                icon = 'gw-check';
                className = 'wni-icon-success mt-20';
                break;
            case 'Past Due':
                icon = 'gw-alarm';
                className = 'wni-icon-warning mt-20';
                break;
            case 'Pending Cancellation':
                icon = 'gw-warning';
                className = 'wni-icon-error mt-20';
                break;
            case 'Cancelled':
                icon = 'gw-not-interested';
                className = 'mt-20';
                break;
            case 'Expired':
                icon = 'gw-not-interested';
                className = 'mt-20';
                break;
            default:
        }
        return { icon, className };
    }, []);

    const getStatusIcon = useCallback(() => {
        const billingPaymentSchedule = _.get(currentBillingData, 'billingPaymentSchedule');
        const status = _.get(billingPaymentSchedule, 'policyPeriodStatus');
        const renderObj = getStatusRenderObject(status);
        const icon = _.get(renderObj, 'icon');
        return icon;
    }, [currentBillingData, getStatusRenderObject]);

    const getIconClassName = useCallback(() => {
        const billingPaymentSchedule = _.get(currentBillingData, 'billingPaymentSchedule');
        const status = _.get(billingPaymentSchedule, 'policyPeriodStatus');
        const renderObj = getStatusRenderObject(status);
        const className = _.get(renderObj, 'className');
        return className;
    }, [currentBillingData, getStatusRenderObject]);

    const overrides = {
        billingPagePolicyStatusIcon: {
            icon: getStatusIcon(),
            className: getIconClassName()
        },
        tabBillingSummaryTotalPremium: {
            value: _.get(currentBillingData, 'totalPremium')
        },
        tabBillingSummaryTaxesAndFees: {
            value: _.get(currentBillingData, 'taxesAndFees')
        },
        tabBillingSummaryTotalCosts: {
            value: _.get(currentBillingData, 'totalCost')
        },
        billingPagePolicyBillingStatus: {
            // value: getBillingStatus(_.get(currentBillingData, 'isDelinquent'))
            value: getPolicyBillingStatus()
        },
        tabBillingSummaryPastDueContainer: {
            visible: showPastDueLine
        },
        tabBillingSummaryPastDue: {
            value: _.get(currentBillingData, 'aggregatedPastDueAmount')
        },
        tabBillingSummaryPastDueDate: {
            value: getFormattedDate(_.get(currentBillingData, 'pastDueDate'))
        },
        tabBillingSummaryNextPaymentContainer: {
            visible: showCurrentPaymentLine
        },
        tabBillingSummaryNextPayment: {
            value: _.get(currentBillingData, 'nextDueAmount')
        },
        billingSummaryNextDueDate: {
            value: getFormattedDate(_.get(currentBillingData, 'nextDueDate'))
        },
        tabBillingSummaryTotalDue: {
            value: _.get(currentBillingData, 'totalDueAmount')
        },
        tabBillingSummaryBillingMethod: {
            value: translator({
                id: `typekey.BillingMethod.${_.get(currentBillingData, 'billingMethod')}`,
                defaultMessage: `typekey.BillingMethod.${_.get(currentBillingData, 'billingMethod')}`
            })
        },
        tabBillingSummaryPaymentPlan: {
            value: _.get(currentBillingData, 'paymentPlan')
        },
        paymentScheduledTable: {
            data: billingInvoiceData
        },
        policyPeriodDropDown: {
            availableValues: policyPeriodDropDownOptions,
            value: selectedPeriod
        },
        billingInfoContainer: {
            visible: isBillingInfoAvailable
        },
        billingEmptyContainer: {
            visible: !isBillingInfoAvailable,
            content: messages.noBillingDataLabel
        },
        paymentScheduleCard: {
            visible: billingMethod === 'AgencyBill'
        }
    };

    const resolvers = {
        resolveClassNameMap: policyBillingStyles,
        resolveCallbackMap: {
            getCell: getCell,
            getFormattedCurrency: getFormattedCurrency,
            handlePolicyPeriodChange: handlePolicyPeriodChange,
            handleViewAccountBilling: handleViewAccountBilling,
            sortDate: DatatableUtil.sortDate,
            sortString: DatatableUtil.sortString,
            sortCurrency: DatatableUtil.sortCurrency,
            getDueDate: getDueDate,
            getBoldCell: getBoldCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default withIntl(withAuthenticationContext(BillingComponent));
