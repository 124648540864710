import React from "react";
import _ from 'lodash';
import QuestionSetsComponent from "wni-capability-quoteandbind-ho-react/pages/HOResidence/Component/QuestionSetsComponent/QuestionSetsComponent";
import metadata from './DPQuestionSetsComponent.metadata.json5'

function DPQuestionSetsComponent(props) {
    const {
        model: dwellingQuestionSetsVM,
        DP2Condo,
        baseState
    } = props;

    const stringQuestions = _.get(dwellingQuestionSetsVM, 'value[0].answers');
    const residentHaveCurrentInspection = _.get(stringQuestions, 'ResidentHaveCurrentInspection_Ext');

    const changeToBool = (string) => {
        return (/^true$/i).test(string);
    };

    const DPOverrideProps = {
        residentHaveCurrentInspection: {
            visible: !DP2Condo && baseState === 'AK'
        },
        dateOfInspection: {
            visible: !DP2Condo && changeToBool(residentHaveCurrentInspection) && baseState === 'AK'
        }
    }

    return <QuestionSetsComponent
        {...props}
        DPOverrideProps={DPOverrideProps}
        metadataOverride={metadata}
    />
}

DPQuestionSetsComponent.propTypes = QuestionSetsComponent.propTypes;
DPQuestionSetsComponent.defaultProps = QuestionSetsComponent.defaultProps;

export default DPQuestionSetsComponent;