import React from 'react'
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/components';

const AccordionCardTitle = (props) => {
    const { title, actionText, onClick } = props;
    const translator = useTranslator();
    return (
        <Flex justifyContent="between" className='w-100'>
            <h4 className='font-PrimaryDark-bold-16 d-flex align-items-center'>{translator(title)}</h4>
            <Button
                variant="primary"
                icon="gw-add"
                onClick={(e) => {
                    e.stopPropagation()
                    onClick()
                }}

                label={actionText}
            />
        </Flex>
    );
};

export default AccordionCardTitle