import { defineMessages } from 'react-intl';

export default defineMessages({
    createDraftErrorTitle: {
        id: 'fnol.gl.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    createDraftErrorMessage: {
        id: 'fnol.gl.config.FlowConfig.An error occurred attempting to create claim.',
        defaultMessage: 'An error occurred attempting to create claim.'
    },
});
