import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { DocumentUploadService, DocumentDownloadService } from '@xengage/gw-portals-document-js';

export default class GatewaySpreadsheetService {
    static uploadDocument(file, documentMetadata, additionalHeaders) {
        return DocumentUploadService.send(getProxiedServiceUrl('uploadSpreadsheet'), file, documentMetadata, additionalHeaders);
    }

    static downloadSpreadsheetTemplate(jobID, additionalHeaders, errorCallback) {
        const url = getProxiedServiceUrl('downloadSpreadsheet').concat(`/${jobID}`);
        return DocumentDownloadService.getDocument(url, additionalHeaders, errorCallback);
    }
}
