import React from 'react';
import PropTypes from 'prop-types';
import { BaseAddPaymentMethodPopup } from 'wni-capability-common-react';

function AddPaymentMethodPopup(props) {
    return (
        <BaseAddPaymentMethodPopup
            {...props}
        />
    );
}

AddPaymentMethodPopup.propTypes = {
    paymentDetailVM: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};
AddPaymentMethodPopup.defaultProps = {
    size: 'md'
};
export default AddPaymentMethodPopup;
