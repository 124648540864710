import React, {useContext} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { RTVehicleService } from 'wni-capability-quoteandbind-rt';
import VehicleDetails from '../VehicleDetails/RTVehicleDetails';
import VehicleInformation from '../VehicleInformation/RTVehicleInformation';
import GaragingLocation from '../GaragingLocation/GaragingLocation';
import RTAdditionalInterest from '../RTAdditionalInterest/RTAdditionalInterest';
import metadata from './RTVehicleComponent.metadata.json5';
import { getVehicleOrTrailerLabel, getAvailableFromVM, tooltipForItemInsured } from '../util/Vehicle.util';

function VehicleComponent(props) {
    const {
        value: vehicleVM,
        onValueChange,
        onValidate,
        showErrors,
        syncWizardDataSnapshot,
        isReadOnly,
        isRequiredForIssuance,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;
    const {
        itemToBeInsured,
        itemToBeInsured_options: itemToBeInsuredOptions
    } = vehicleVM.value;
    
    const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    const { loadingMask: { setLoadingMask }, domainCompany } = useDependencies(['loadingMask', 'domainCompany']);
    const { code: domainCode } = domainCompany;
    const getRequestData = (value, path) => {
        return {
            ...vehicleVM.value,
            [path]: value,
            isUpdate: false,
            garagedAt_Ext: null,
            vehicleType: null
        };
    };
    const handleItemInsuredChange = async (value, path) => {
        if(value === itemToBeInsured) {
            return false;
        };
        setLoadingMask(true);
        const requestData = getRequestData(value, path);
        const res = await RTVehicleService.postOnChangeAction(jobID, sessionUUID, requestData, path, authHeader);
        _.set(vehicleVM, 'value', res.rtvehicle);
        syncWizardDataSnapshot(vehicleVM);
        setLoadingMask(false);
    };

    const getItemInsuredAvailables = (path) => {
        const options = getAvailableFromVM(vehicleVM, path, translator);
        const newOptions = options.filter((item) => itemToBeInsuredOptions.includes(item.code));
        return newOptions
    };

    const componentProps = _.omit(props, 'id');
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        iteminsured: {
            availableValues: getItemInsuredAvailables('itemToBeInsured'),
            onValueChange: handleItemInsuredChange,
            value: itemToBeInsured,
            secondaryLabel: tooltipForItemInsured(translator, domainCode),
            disabled: !_.get(vehicleVM.value, 'isEditabled'),
        },
        vehicleContainer: {
            visible: !!itemToBeInsured
        },
        vehicleDetailsSection: {
            ...componentProps
        },
        vehicleInformationSection: {
            ...componentProps
        },
        additionalInterestSection: {
            ...componentProps
        },
        garagingLocationSection: {
            ...componentProps
        },
        additionalInterestComponentId: {
            ...componentProps
        },
        vehicleDetailsCard: {
            title: getVehicleOrTrailerLabel('vehicleDetail', itemToBeInsured)
        },
        vehicleInformationCard: {
            title: getVehicleOrTrailerLabel('vehicleInformation', itemToBeInsured)
        }
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            vehicledetails: VehicleDetails,
            vehicleinformation: VehicleInformation,
            additionalInterest: RTAdditionalInterest,
            garaginglocation: GaragingLocation
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, vehicleVM, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default VehicleComponent;
