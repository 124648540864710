import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imTransitService'), method, data, additionalHeaders);
}

export default class IMTransitService {
  
    /**
     * Invokes IMTransitHandler.getTransitClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getTransitClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getTransitClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMTransitHandler.getTransitClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getTransitClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getTransitClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMTransitHandler.updateTransitClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} TransitToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateTransitClauses(jobNumber, sessionUUID, TransitToUpdate, authHeader = {}) {
        return processSubmission('updateTransitClauses', [jobNumber, sessionUUID, TransitToUpdate], authHeader);
    }

    /**
     * Invokes IMTransitHandler.updateTransitSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateTransitSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateTransitSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMTransitHandler.updateTransitAddiInsuredSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateTransitAddiInsuredSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateTransitAddiInsuredSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMTransitHandler.onTransitNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onTransitNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onTransitNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMTransitHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMTransitHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMTransitHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }

    /**
     * Invokes IMTransitHandler.calculateOwnersCargoLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateOwnersCargoLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
      return processSubmission('calculateOwnersCargoLimit', [jobNumber, sessionUUID, termCode], authHeader);
  }

  /**
     * Invokes IMTransitHandler.calculateTripTransitLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
  static calculateTripTransitLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
    return processSubmission('calculateTripTransitLimit', [jobNumber, sessionUUID, termCode], authHeader);
}
}