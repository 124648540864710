import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayIncidentReport'), method, data, additionalHeaders);
}

export default class WniPriorCarrierService {
    static getPriorCarrierData(type, quoteId, additionalHeaders = {}) {
        return processSubmission('getPriorCarrierData', [type, quoteId], additionalHeaders);
    }

    static updatePriorCarrier(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('updatePriorCarrier', [type, quoteId, newModel], additionalHeaders);
    }

    static deletePriorCarrier(type, quoteId, publicId, additionalHeaders = {}) {
        return processSubmission('deletePriorCarrier', [type, quoteId, publicId], additionalHeaders);
    }

    static savePriorCarrier(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('savePriorCarrier', [type, quoteId, newModel], additionalHeaders);
    }

    static updateReportPriorCarrier(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('updateReportPriorCarrier', [type, quoteId, newModel], additionalHeaders);
    }
}
