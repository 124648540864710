import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { ValidationIssuesComponent} from 'wni-components-platform-react';
import {
    WALMultiQuoteService,
} from "wni-capability-quoteandbind-wal";
import { QuoteUtil, WniDateUtil } from 'wni-portals-util-js';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';
//
import WizardPage from '../../templates/WALWizardPage';

import styles from './WALIncidentsAndReportsPage.module.scss';
import metadata from './WALIncidentsAndReportsPage.metadata.json5';
import messages from './WALIncidentsAndReportsPage.messages';
import LossUtil from './WALIncidentsAndReportsUtil';
import { CurrencyField } from '@jutro/legacy/components';

function WALIncidentsAndReportsPageReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardPageData,
        //
        disableAddButton,
        productName,
        filteredManualCLUE,
        filteredManualMVR,
        showPolicyClaims
    } = props;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        authHeader
    } = useAuthentication();

    // clue
    const [manuallyClue, updateManuallyClue] = useState([]);
    const [vendorClueReports, updateVendorClueReports] = useState([]);   
    // mvr
    const [manuallyMVR, updateManuallyMVR] = useState([]);
    const [vendorMVR, updateVendorMVR] = useState([]);
    // policy claims    
    const [vendorPolicyClaims, updateVendorPolicyClaims] = useState([]);

    const {
        quoteFlow_Ext: quoteFlow,
    } = submissionVM.value.baseData;
    const [accordionStates, updateAccordionStates] = useState([]);
    // filter duplicate manual clue/mvr for policy change, NB show all manual records
    const isFilteredManualClueAvaliable = _.isArray(filteredManualCLUE);
    const isFilteredManualMVRAvaliable = _.isArray(filteredManualMVR);
    const manualClueVisiblity = isFilteredManualClueAvaliable ? filteredManualCLUE.length > 0 : manuallyClue.length > 0;
    const manualMVRVisiblity = isFilteredManualMVRAvaliable ? filteredManualMVR.length > 0 : manuallyMVR.length > 0;
    
    useEffect(()=>{
        if (quoteFlow === 'draft') {
            updateAccordionStates(["userEnteredInfo"])
        }
    },[]);
    useEffect(() => {
        const {
            lobData: {
                [productName]: {
                    incidentsAndReports_Ext: {
                        clueenter = [],
                        cluereport = [],
                        mvrenter = [],
                        mvrreport = [],
                        claimReport = [],
                    },
                },
            },
        } = submissionVM.value;
        const sortedMVRReport = LossUtil.sortReports(mvrreport);
        updateManuallyClue(clueenter);
        updateVendorClueReports(cluereport);
        updateManuallyMVR(mvrenter);
        updateVendorMVR(sortedMVRReport);
        updateVendorPolicyClaims(claimReport);
    }, [productName]) ;


    const onDefaultPageNext = useCallback(async () =>{
        const {
            jobID,
            sessionUUID,
            baseData: {
                periodStatus,
            },
        } = submissionVM.value;

        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
            submissionVM.value = await WALMultiQuoteService.processQuote(
            // submissionVM.value = await processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM
    }, [authHeader, submissionVM, updateWizardPageData]);

    const onPageNext = useCallback(async () => {

        const {
            onIncidentsPageNext = onDefaultPageNext
        } = props;
        
        const res = await onIncidentsPageNext();
        return res
    }, [onDefaultPageNext, props]);

    const onColumnDesCell = (
        (item, index, property) => {
            const { typeKey } = property;
            if (item.source === 'chips') {
                return (
                    <div>{`${item.descriptionForChips}`}</div>
                );
            }
            const val = translator({ id: `${typeKey}.${item.description}` });
            return <div>{`${item.description ? val : ''}`}</div>;
        }
    );

    const onColumnCell = useCallback((item, index, property) => {
        const { path, typeKey } = property;
        if (item[path] && item[path].year) {
            return (
                <div>{WniDateUtil.formatDateWithPattern(item[path])}</div>
            );
        }
        if (item[path] && item[path].currency) {
            return (
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[path]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            );
        }
        const val = typeKey
            ? translator({ id: `${typeKey}.${item[path]}` })
            : item[path];
        return <div>{`${item[path] ? val : ''}`}</div>;
    }, [translator]);

    const getInfoValidationMsg = () => {
        const msg = [{
            type: 'info',
            reason: messages.CreditAuth1.defaultMessage
        },
        {
            type: 'info',
            reason: messages.CreditAuth2.defaultMessage
        }];
        return msg;
    }

    const getSubUserEnterStates = () => {
        const userEnterStates = [];
        if (manuallyClue.length > 0 || (quoteFlow === 'draft')) {
            userEnterStates.push('userEnteredPriorLossesCard');
        }
        if (manuallyMVR.length > 0) {
            userEnterStates.push('userEnteredViolationCard');
        }
        return userEnterStates;
    };
    const getSubVendorStates = () => {
        const vendorStates = [];
        if (vendorClueReports.length > 0) {
            vendorStates.push('vendorPriorLossesCard');
        }
        if (vendorMVR.length > 0) {
            vendorStates.push('vendorViolationCard');
        }
        if (vendorPolicyClaims.length > 0) {
            vendorStates.push('PolicyClaimsCard');
        }
        return vendorStates;
    };
    const sortDate = (a, b) => {
        return DatatableUtil.sortDate(moment(a).format("MM/DD/YYYY"), moment(b).format("MM/DD/YYYY"));
    }
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        '@action': {
            disabled: disableAddButton,
        },
        infoValidationMessages: {
            validationIssues: getInfoValidationMsg()
        },
        // clue add manually
        userAddedPriorLossTable: {
            visible: manualClueVisiblity,
            data: isFilteredManualClueAvaliable? filteredManualCLUE : manuallyClue,
        },
        // vendor clue report
        priorLossCLUETable: {
            visible: vendorClueReports.length > 0,
            data: vendorClueReports,
        },
        // MVR add manually
        userAddedMVRTable: {
            visible: manualMVRVisiblity,
            data: isFilteredManualMVRAvaliable? filteredManualMVR : manuallyMVR,
        },
        // MVR report 
        reportedMVRTable: {
            visible: vendorMVR.length > 0,
            data: vendorMVR,
        },
        // vendor claim report
        PolicyClaimsCard: {
            visible: showPolicyClaims
        },
        reportedPolicyClaimsTable: {
            visible: vendorPolicyClaims.length > 0,
            data: vendorPolicyClaims,
        },
        userEnteredPriorLossesCard: {
            title: LossUtil.getManualClueTitle(isFilteredManualClueAvaliable, filteredManualCLUE, manuallyClue),
        },
        vendorPriorLossesCard: {
            title:
                vendorClueReports.length > 1
                    ? `PRIOR LOSSES ${vendorClueReports.length} RECORDS`
                    : `PRIOR LOSSES ${vendorClueReports.length} RECORD`,
        },
        userEnteredViolationCard: {
            title: LossUtil.getManualMVRTitle(isFilteredManualMVRAvaliable, filteredManualMVR, manuallyMVR),
        },
        vendorViolationCard: {
            title:
                vendorMVR.length > 1
                    ? `VIOLATIONS ${vendorMVR.length} RECORDS`
                    : `VIOLATIONS ${vendorMVR.length} RECORD`,
        },
        claimPriorLossesCard: {
            title:
                vendorPolicyClaims.length > 1
                    ? `PRIOR LOSSES ${vendorPolicyClaims.length} RECORDS`
                    : `PRIOR LOSSES ${vendorPolicyClaims.length} RECORD`,
        },
        accordionInfo: {
            accordionStates: accordionStates
        },
        userEnteredSubInfo: {
            accordionStates: getSubUserEnterStates()
        },
        vendorSubInfo: {
            accordionStates: getSubVendorStates()
        },
        // for read only mode
        addPriorlossBtn: {
            visible: false
        },
        addUserIncidentBtn: {
            visible: false
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onColumnCell: onColumnCell,
            onColumnClueCell: (item, index, property) => onColumnCell(item, index, property, 'clue'),
            onColumnDesCell: (item, index, property) => onColumnDesCell(item, index, property, 'mvr'),
            onColumnMvrCell: (item, index, property) => onColumnCell(item, index, property, 'mvr'),
            onColumnPolicyClaimsCell: (item, index, property) => onColumnCell(item, index, property, 'policyClaims'),
            sortDate: sortDate,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
            sortCurrency: DatatableUtil.sortCurrency
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            onNext={onPageNext}
            // pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

WALIncidentsAndReportsPageReadOnlyPage.propTypes = {
    ...wizardProps,
    disableAddButton: PropTypes.bool,
    productName: PropTypes.string,
    /**
     * for other Transaction Types like PolicyChange
     */
    onIncidentsPageNext: PropTypes.func,
    filteredManualCLUE: PropTypes.array,
    filteredManualMVR: PropTypes.array,
    showPolicyClaims: PropTypes.bool,
};
WALIncidentsAndReportsPageReadOnlyPage.defaultProps = {
    disableAddButton: true,
    productName: 'watercraft',
    //
    onIncidentsPageNext: undefined,
    filteredManualCLUE: undefined,
    filteredManualMVR: undefined,
    showPolicyClaims: false,
};
export default WALIncidentsAndReportsPageReadOnlyPage;
