import { defineMessages } from 'react-intl';

export default defineMessages({
    removeTitle: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeDescription: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this record?',
    },
    EQCoveragesTooltip: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.EQCoveragesTooltip',
        defaultMessage: 'For Earthquake and Volcanoe blankets multiple coverages can be selected and will determine which risks can be grouped together'
    },
    floodCoveragesTooltip: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.floodCoveragesTooltip',
        defaultMessage: 'For flood blankets multiple coverages can be selected and will determine which risks can be grouped together'
    },
    blanketTypeTooltip: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.blanketTypeTooltip',
        defaultMessage: 'Standard Blanket risks can include Building Coverage, BPP Coverage, Building and BPP coverage, & more'
    },
    blanketAppliedtpTooltip: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.BlanketAppliedtpTooltip',
        defaultMessage: 'Peak Season Coverage can only be applied to existing standard Blankets that have specified having a peak season'
    },
    selectRisksTitle: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.selectRisksTitle',
        defaultMessage: 'Select Risks to Group Within Blanket'
    },
    cancel: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.Cancel',
        defaultMessage: 'Cancel'
    },
    apply: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.Apply',
        defaultMessage: 'Apply'
    },
    saveAndNextBlanket: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.SaveAndNextBlanket',
        defaultMessage: 'Save & Next Blanket'
    },
    nextBlanket: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.NextBlanket',
        defaultMessage: 'Next Blanket'
    },
    saveAndClose: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.SaveAndClose',
        defaultMessage: 'Save & Close'
    },
    close: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.Close',
        defaultMessage: 'Close'
    },
    peakSeasonSelectionWarning: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.peakSeasonSelectionWarning',
        defaultMessage: 'Only blankets with the Business Personal Property or Personal Property coverage types can have a peak season coverage applied'
    },
    peakSeasonWarning: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.peakSeasonWarning',
        defaultMessage: 'Only Blankets with coverage type Business Personal Property can be selected for Peak Season'
    },
    removeAllRisksTitle: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.removeAllRisksTitle',
        defaultMessage: 'Want to remove all risks?',
    },
    removeAllRisksDescription: {
        id: 'quoteandbind.cp.directives.templates.cp-edit.removeAllRisksDescription',
        defaultMessage: 'Are you sure you want to remove all risks?',
    },
});