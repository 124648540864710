import { defineMessages } from 'react-intl';

export default defineMessages({
    account: {
        id: 'wni.payment.Account',
        defaultMessage: 'Account'
    },
    policiesInfo: {
        id: 'wni.payment.policies Info',
        defaultMessage: 'Policies can bill on one invoice if the due day of month, payment method, and primary payer are the same.  The quoted policy will be added to an existing bill account when possible.  Please review the billing parameters.  You can make changes by clicking the pencil icon to the right of the quoted policy row.'
    },
    invoiceFeesInfo: {
        id: 'wni.payment.invoice fees Info',
        defaultMessage: 'Invoice fees will be included in all installment bills.'
    },
    exsitingBillAccount: {
        id: 'wni.payment.Existing Bill Account',
        defaultMessage: 'Existing Bill Account'
    },
    editPaymentDetails: {
        id: 'wni.payment.Edit Payment Details',
        defaultMessage: 'Edit Payment Details ({productName} {quoteNumber})'
    },
    editPaymentDetailsWarning: {
        id: 'wni.payment.You have selected an existing account',
        defaultMessage: 'You have selected an existing account. The Due Day of Month has been updated to the same Day as the existing billing account. If you prefer a different due date, please select the appropriate day.'
    },
    dialogOk: {
        id: 'wni.payment.OK',
        defaultMessage: 'OK'
    },
    dialogCancel: {
        id: 'wni.payment.Cancel',
        defaultMessage: 'Cancel'
    },
    conditionallyInfo: {
        id: 'wni.payment.conditionally Info',
        defaultMessage: 'Payment could not be processed.The policy has been conditionally issued,but a payment is still required.Click the One Time Payment button to make a One-Time Payment or contact your Underwriter.'
    },
    paymentIssuanceTitle: {
        id: 'wni.payment.pament Issuance Title',
        defaultMessage: 'Policy Payment & Issuance'
    },
    issueButton: {
        id: 'wni.payment.issue button',
        defaultMessage: 'Issue'
    },
    issueNowButton: {
        id: 'wni.payment.Issue Now',
        defaultMessage: 'Issue Now'
    },
    payAndIssueButton: {
        id: 'wni.payment.pay&issue button',
        defaultMessage: 'Pay & Issue'
    },
    oneTimePaymentButton: {
        id: 'wni.payment.one time payment',
        defaultMessage: 'One Time Payment'
    },
    toAccountDetail: {
        id: 'wni.payment.back to account detail',
        defaultMessage: 'Back to Account Details'
    },
    toAccountSummary: {
        id: 'wni.payment.back to account summary',
        defaultMessage: 'Back to Account Summary'
    },
    toAllQuotes: {
        id: 'wni.payment.back to all quotes',
        defaultMessage: 'Back to All Quotes'
    },
    downPaymentSetupTitle: {
        id: 'wni.payment.pay and issue title',
        defaultMessage: 'Down Payment Setup'
    },
    pageTitle: {
        id: 'wni.payment.Payment Setup & Documents',
        defaultMessage: 'Payment Setup & Documents'
    },
    bccErrorMessage: {
        id: 'wni.payment.System is facing an issue and could not submit payment now. Please try again later.',
        defaultMessage: 'System is facing an issue and could not submit payment now. Please try again later.'
    },
    proceedToIssue: {
        id: 'wni.payment.Proceed to Issue',
        defaultMessage: 'Proceed to Issue'
    },
    previous: {
        id: 'wni.payment.Previous',
        defaultMessage: 'Previous'
    },
    westernNational: {
        id: 'wni.payment.Submit to Western National',
        defaultMessage: 'Submit to { domainName }',
    },
    setUpAutoPay: {
        id: 'wni.payment.Do you want to set up AutoPay?',
        defaultMessage: 'Do you want to set up AutoPay?',
    },
    agencySweepSucessInfoMain: {
        id: 'wni.payment.agencySweepSucessInfoMain',
        defaultMessage: 'Your payment has been successfully submitted.'
    },
    agencySweepSucessInfoLast: {
        id: 'wni.payment.agencySweepSucessInfoLast',
        defaultMessage: '*Balances in our online portals may not update for 24 hours.'
    }
});
