import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BasePolicyChangeQuoteComponent } from 'wni-components-platform-react';

/**
 * See PAPolicyChangeQuoteComponent
 * @param {object} props 
 * @returns {object}
 */
function GLPolicyChangeQuoteComponent(props) {
    // const {
    // } = props;

    // const [accordionHeaders] = useState([]);


    return (
        <BasePolicyChangeQuoteComponent
            {...props}
            // generateQuoteTable={generateQuoteTable}
            // generateAllAccordionHeaders={generateAllAccordionHeaders}
        />
    );
}

GLPolicyChangeQuoteComponent.propTypes = {
    ...BasePolicyChangeQuoteComponent.propTypes,
};

GLPolicyChangeQuoteComponent.defaultProps = {
    ...BasePolicyChangeQuoteComponent.defaultProps,
};

export default GLPolicyChangeQuoteComponent;