
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { Card } from '@jutro/components';
import {  useProductsData } from 'wni-portals-util-react';
import { WizardPageJumpUtil, WniDateUtil, WniProductsUtil } from 'wni-portals-util-js';
import activeQuotesMessages from '../ActiveQuotesDropdown/ActiveQuotesDropdownComponent.messages';

import { Link } from '@jutro/router';

const { lobQuoteURL } = appConfig;
/**
 * AcriveQuotesListComponent
 * @param {object} props
 * @returns {object} React Component
 */
function ActiveQuotesListComponent(props) {
    const {
        accountJobs,
        postalCode,
    } = props;

    const getActiveJobsList = (accountActiveQuotesDTOList) => {
        const retval = [];
        accountActiveQuotesDTOList.forEach((accountActiveJobDTO) => {
            const {
                jobID, 
                // jobStatus,
                // displayStatus,
                productCode,
                product,
                // productName,
                // periods: jobPeriods,
                effectiveDate
            } = accountActiveJobDTO;
            const formatEffectiveDate = WniDateUtil.formatDateWithPattern(effectiveDate);
            const normalizedProductName = WniProductsUtil.getProductNameByLinePattern(product);
            retval.push({
                name: `${normalizedProductName} - ${jobID}-${formatEffectiveDate}`,
                productCode,
                jobID, 
            });
        });
        return retval;
    }

    const activeJobsList = getActiveJobsList(accountJobs); 
    
    if (_.isEmpty(activeJobsList)) {
        return null;
    }

    // =============================
    /**
     * See QuoteDetailsSummary.onContinueTransaction()
     * @param {object} activeJobs
     * @returns {object} list of <Link> comonents for each job number
     */
    const renderActiveJobsList = (activeJobs) => {
        return activeJobs.map((activeJobOption) => {
            const {
                productCode,
                jobID, 
                name,
            } = activeJobOption;

            const jumpLink = WizardPageJumpUtil.getNewQouteWizardJumpLink({
                productCode, postalCode, jobID
            });
            return (
                <li key={jobID}>
                    <Link key={`${jobID}-Link`} to={jumpLink} >
                        {name}
                    </Link>
                </li>
            );
        })
    }

    return (
        <Card
            id="wizardSidebarActiveQuote"
            className="wizardSiderbarActiveQuote hiddenPrintElement"
            headerClassName="activeQuoteHeader"
            title={activeQuotesMessages.otherActiveQuotes}
        >
            <ul className="activeQuoteContent">
                {renderActiveJobsList(activeJobsList)}
            </ul>
        </Card>
    );
}

ActiveQuotesListComponent.propTypes = {
    accountJobs: PropTypes.arrayOf(PropTypes.object),
    postalCode: PropTypes.string,
};

ActiveQuotesListComponent.defaultProps = {
    accountJobs: [],
    postalCode: undefined,
};

export default ActiveQuotesListComponent;
