import { defineMessages } from 'react-intl';

export default defineMessages({
    CoveredPremises: {
        id: 'InlandMarine.miscform.coveredPrmises.schedules.scheduleItem.CoveredPremises',
        defaultMessage: 'Covered Premises',
    },
    Cancel: {
        id: 'InlandMarine.miscforms.coveredPrmises.schedules.scheduleItem.Cancel',
        defaultMessage: 'Cancel',
    },
    SaveAndContinue: {
        id: 'InlandMarine.miscforms.coveredPrmises.schedules.scheduleItem.SaveAndContinue',
        defaultMessage: 'Save & Continue',
    },
})