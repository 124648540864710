import React, {
    useContext,
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniLoadSaveService, WniPAQuoteService } from 'wni-capability-quoteandbind';
import {
    PaymentInputComponent,
    NewPersonComponent
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { WniDateUtil, WniUrlUtil, PaymentUtil } from 'wni-portals-util-js';
import { DateUtil } from '@xengage/gw-portals-util-js';
import { PaymentPlanConfig, WizardConstants } from 'wni-portals-config-js';
import { WniPolicyService } from 'wni-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages2 from './PaymentDetailsPage.messages';
import metadata from './PaymentDetailsPage.metadata.json5';
import styles from './PaymentDetailsPage.module.scss';
import AddPaymentMethodPopup from './AddPaymentMethodPopup';

/* eslint-disable no-param-reassign */
function initialiseVM(submissionVM) {
    submissionVM.bindData.paymentDetails.value = (submissionVM.bindData.paymentDetails
        && submissionVM.bindData.paymentDetails.value)
        ? submissionVM.bindData.paymentDetails.value : {};
}

/* eslint-enable no-param-reassign */
function PaymentDetailsPage(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM, updateWizardData, wizardSnapshot,
        updateWizardPageData, wizardPageData, updateWizardSnapshot
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const { authHeader } = useAuthentication();
    const [hasRetrievedPaymentPlans, updateHasRetrievedPaymentPlans] = useState(false);
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    // for popup to add a new PaymentMethod
    const [popupPaymentMethodVM, updatePopupPaymentMethodVM] = useState(null);
    // PaymentMethods added
    const [paymentMethodsVM, updatePaymentMethodsVM] = useState([]);

    const [showErrors, updateShowErrors] = useState(false);
    const [primaryPayer, updatePrimaryPayer] = useState('');
    const [paymentDetailsForDownPayment, updatePaymentDetailsForDownPayment] = useState(null);
    const [showLoader, updateShowLoader] = useState(false);
    const [showNotification, updateShowNotification] = useState(false);
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);
    const [dueDateExt, updateDueDateExt] = useState(null);
    const [selectPaymentPlanFinished, updateSelectPaymentPlanFinished] = useState(false);
    const [primaryPayerAvailableValues, updatePrimaryPayerAvailableValues] = useState([]);
    const [paymentPlanDatas, updatePaymentPlanDatas] = useState([]);
    const [dueDateExtError, updateDueDateExtError] = useState(false);
    const [paymentPlansDataError, updatePaymentPlansDataError] = useState(false);
    const [requestParam, updateRequestParam] = useState(null);
    const [paymentDetailsForDownPaymentExt, updatePaymentDetailsForDownPaymentExt] = useState(null);
    const [isBCC, updateIsBCC] = useState(false);
    const [oneTimePaymentMethodsVM, updateOneTimePaymentMethodsVM] = useState([]);
    const { routerBaseName } = appConfig;

    const {
        paymentMethodData, payUsing, fullPayStr,
    } = PaymentPlanConfig;

    const {
        quoteID, sessionUUID,
        baseData: {
            termType,
        },
        bindData,
        // bindData could be empty for freshly copied Submission
        bindData: {
            selectedPaymentPlan,
            paymentPlans_Ext: paymentPlansFromBC,
            chosenQuote,
        } = {},
        quoteData: {
            offeredQuotes,
        } = {},
    } = submissionVM.value;

    const resetPopupPaymentMethodVM = useCallback(() => {
        const paymentDetails = {
            paymentMethod: 'autopay_Ext',
            bankAccountData: {
                bankAccountType: 'checking'
            }
        };
        const paymentDetailsVM = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
        updatePopupPaymentMethodVM(paymentDetailsVM);
    }, [viewModelService]);

    const getDownPaymentRaido = useCallback(() => {
        const showAgencySweep = _.get(submissionVM, 'bindData.agencySweep_Ext.value');
        if (showAgencySweep && showAgencySweep === 'Yes') {
            return PaymentUtil.AGENCY_SWEEP_AVAILABLE_OPTIONS;
        }
        return PaymentUtil.AGENCY_SWEEP_UNAVAILABLE_OPTIONS;
    }, [submissionVM]);

    const updatePaymentInputData = useCallback((premium, paymentPlan) => {
        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );
        const totalPremium = _.get(premium, 'totalPremium_Ext');
        const taxAndSurchargesRPT = _.get(premium, 'taxAndSurchargesRPT_Ext');
        const installmentFees = _.get(premium, 'installmentFees_Ext');
        const fullPayDiscount = _.get(premium, 'fullPayDiscount_Ext');
        _.set(chosenQuoteData, 'premium.totalPremium_Ext', totalPremium);
        _.set(chosenQuoteData, 'premium.taxAndSurchargesRPT_Ext', taxAndSurchargesRPT);
        _.set(chosenQuoteData, 'premium.billingFees_Ext', installmentFees);
        _.set(chosenQuoteData, 'premium.fullPayDiscount_Ext', fullPayDiscount);

        // (None full pay) Total Premium =PC(Premium + Taxes and Surcharges + TotalFee)
        // (Full Pay) Total Premium =PC(Premium + Taxes and Surcharges + TotalFee + FullPayDiscount)
        // installmentFees = TotalFee = Taxes and Surcharges + Fees
        // Total Cost = PORTAL(Total Premium - full pay discount)
        let totalPremiumWithTaxAndSurchargesAmount;
        const totalCostAmount = _.get(totalPremium, 'amount') + _.get(installmentFees, 'amount');
        if (_.get(paymentPlan, 'name') === 'Full Pay') {
            totalPremiumWithTaxAndSurchargesAmount = totalCostAmount + _.get(fullPayDiscount, 'amount');
        } else {
            totalPremiumWithTaxAndSurchargesAmount = totalCostAmount;
        }
        const totalPremiumWithTaxAndSurcharges = {
            amount: totalPremiumWithTaxAndSurchargesAmount,
            currency: _.get(totalPremium, 'currency')
        };
        _.set(chosenQuoteData, 'premium.totalPremiumWithTaxAndSurcharges_Ext', totalPremiumWithTaxAndSurcharges);

        const totalCost = {
            amount: totalCostAmount,
            currency: _.get(totalPremium, 'currency')
        };
        _.set(chosenQuoteData, 'premium.totalCost_Ext', totalCost);

        _.set(submissionVM, 'value.bindData.agencySweepValue_Ext', _.get(paymentPlan, 'downPayment_Ext'));
        _.set(submissionVM, 'value.bindData.electronicValue_Ext', _.get(paymentPlan, 'downPayment_Ext'));
        _.set(chosenQuoteData, 'downPayment_Ext', _.get(paymentPlan, 'downPayment_Ext'));
        _.set(chosenQuoteData, 'frequency_Ext', _.get(paymentPlan, 'invoiceFrequency_Ext'));
        _.set(chosenQuoteData, 'installments_Ext', _.get(paymentPlan, 'installment_Ext'));
        _.set(submissionVM, 'value.bindData.selectedPaymentPlan', _.get(paymentPlan, 'billingId'));
        _.set(chosenQuoteData, 'planName', _.get(paymentPlan, 'name'));

        updateWizardSnapshot(submissionVM);
    }, [submissionVM, updateWizardSnapshot]);

    const paymentPlanChange = useCallback(async (paymentPlan) => {
        let premium;
        if (_.get(paymentPlan, 'billingId')) {
            updateSelectPaymentPlanFinished(false);
            updateShowLoader(true);
            premium = await WniLoadSaveService.selectPaymentPlan(quoteID, sessionUUID, _.get(paymentPlan, 'billingId'), authHeader);

            const paymentViewType = wizardPageData[WizardConstants.paymentViewType];
            const selectedPlanType = PaymentUtil
                .getPaymentPlanType(paymentPlan, paymentPlansFromBC);
            if (paymentViewType !== selectedPlanType) {
                updateWizardPageData({ [WizardConstants.paymentViewType]: selectedPlanType });
            }
        }

        updateSelectPaymentPlanFinished(true);
        // update paymentInput datas
        updatePaymentInputData(premium, paymentPlan);
        updateShowLoader(false);
    }, [authHeader, paymentPlansFromBC, quoteID, sessionUUID,
        updatePaymentInputData, updateWizardPageData, wizardPageData]);

    const paymentPlanChangeCombine = useCallback(async (paymentPlan) => {
        const billingId = _.get(paymentPlan, 'billingId');
        if (billingId) {
            updateSelectPaymentPlanFinished(false);
            updateShowLoader(true);
            const quoteDataDTO = await WniLoadSaveService.selectPaymentPlanCombine(quoteID, sessionUUID, _.get(paymentPlan, 'billingId'), authHeader);
            // update paymentPlan with new retrieved data
            const paymentPlansExt = _.get(quoteDataDTO, 'bindData.paymentPlans_Ext', []);
            const newPaymentPlan = _.find(
                paymentPlansExt,
                (elt) => _.get(elt, 'billingId') === billingId
            );
            const paymentViewType = wizardPageData[WizardConstants.paymentViewType];
            const selectedPlanType = PaymentUtil
                .getPaymentPlanType(newPaymentPlan, paymentPlansFromBC);
            if (paymentViewType !== selectedPlanType) {
                updateWizardPageData({ [WizardConstants.paymentViewType]: selectedPlanType });
            }
            // update paymentPlans data
            _.set(submissionVM, 'value.bindData.paymentPlans_Ext', _.get(quoteDataDTO, 'bindData.paymentPlans_Ext'));
            // update paymentInput data
            updatePaymentInputData(_.get(quoteDataDTO, 'bindData.premium_Ext'), newPaymentPlan);
        }
        updateSelectPaymentPlanFinished(true);
        updateShowLoader(false);
    }, [authHeader, paymentPlansFromBC, quoteID, sessionUUID,
        submissionVM, updatePaymentInputData, updateWizardPageData, wizardPageData]);

    const resetPrimaryPayerOptions = useCallback((newPerson) => {
        // set primaryPayerOptions
        let primaryPayerOptions = [];
        const nonBillingContacts = _.get(submissionVM, 'value.bindData.nonBillingContacts_Ext');
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            nonBillingContacts, primaryPayerOptions
        );
        // manually add New Person
        if (newPerson) {
            primaryPayerOptions.push({
                code: JSON.stringify(newPerson),
                name: _.get(newPerson, 'displayName')
            });
        }
        const billingContacts = _.get(submissionVM, 'value.bindData.billingContacts_Ext');
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            billingContacts, primaryPayerOptions
        );
        primaryPayerOptions.push({
            code: 'New Person',
            name: 'New Person'
        });
        updatePrimaryPayerAvailableValues(primaryPayerOptions);
    }, [submissionVM]);

    const refreshPremiums = useCallback(async (firstDayOfMonth, isAutoPay,
        currentPaymentPlan = false) => {
        try {
            updateShowLoader(true);
            // call the service to update the day of month
            const result = await WniPAQuoteService.recalculatePaymentData(
                quoteID, sessionUUID, firstDayOfMonth, isAutoPay, authHeader
            );
            // update paymentPlans data
            _.set(submissionVM, 'value.bindData.paymentPlans_Ext', _.get(result, 'bindData.paymentPlans_Ext'));
            // update paymentInput data
            const paymentPlan = currentPaymentPlan || _.find(_.get(result, 'bindData.paymentPlans_Ext'), (item) => item.billingId === selectedPaymentPlan);
            updatePaymentInputData(_.get(result, 'bindData.premium_Ext'), paymentPlan);
            updateShowLoader(false);
        } catch (ex) {
            updateShowLoader(false);
        }
    }, [authHeader, quoteID, selectedPaymentPlan, sessionUUID,
        submissionVM, updatePaymentInputData]);

    const selectPaymentPlanAndUpdateSchedule = useCallback(async (currentPaymentPlan) => {
        await paymentPlanChangeCombine(currentPaymentPlan);
    }, [paymentPlanChangeCombine]);

    const generateAutoPayEnrollment = useCallback(async (vm) => {
        try {
            updateShowLoader(true);
            const result = await WniPAQuoteService.generateAutoPayEnrollment(
                quoteID,
                sessionUUID,
                _.get(vm, 'value.bankAccountData.bankABANumber'),
                _.get(vm, 'value.bankAccountData.bankAccountNumber'),
                _.get(vm, 'value.bankAccountData.bankName'),
                _.get(vm, 'value.bankAccountData.bankAccountType'),
                null,
                _.get(vm, 'value.bankAccountData.publicID'),
                authHeader
            );
            updateShowLoader(false);
            return result;
        } catch (ex) {
            updateShowLoader(false);
            return null;
        }
    }, [authHeader, quoteID, sessionUUID]);

    useEffect(() => {
        if (!isVMInitialised) {
            updateShowLoader(true);
            initialiseVM(submissionVM);
            updateIsVMInitialised(true);
            // set up default value
            if (!_.get(submissionVM, 'bindData.payUsing_Ext.value')) {
                _.set(submissionVM, 'bindData.payUsing_Ext', payUsing.check);
                updateWizardData(submissionVM);
            }
            // set up default primaryPayer_Ext
            let defaultPrimaryPayer = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.displayName');
            if (!defaultPrimaryPayer) {
                defaultPrimaryPayer = '-';
            }
            _.set(submissionVM, 'bindData.primaryPayer_Ext', _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext'));
            updatePrimaryPayer(defaultPrimaryPayer);
            resetPrimaryPayerOptions();
            // initialise popupPaymentMethodVM
            resetPopupPaymentMethodVM();
            // paymentMethods
            const payInstrucments = _.get(submissionVM, 'value.bindData.payInstrucments_Ext');
            const paymentMethods = PaymentUtil.initPaymentMethods(
                payInstrucments, viewModelService
            );
            updatePaymentMethodsVM(paymentMethods);
            // trigger autoPayEnrollment
            const payUsingValue = _.get(submissionVM, 'bindData.payUsing_Ext.value');
            if (payUsingValue) {
                // set as New InvoiceStream
                // trigger AutoPay generate
                const vm = _.find(paymentMethods, (bank) => {
                    return _.get(bank, 'value.bankAccountData.publicID') === payUsingValue;
                });
                if (!_.isEmpty(vm)) {
                    generateAutoPayEnrollment(vm);
                }
            }
            // dueDate
            const defaultDueDateExt = _.get(submissionVM, 'value.bindData.dueDate_Ext');
            updateDueDateExt(defaultDueDateExt);
            // payment Plans
            _.set(submissionVM, 'value.bindData.downPayment_Ext', 'Electronic');
            // update isBcc flag
            updateIsBCC(true);
            const paymentPlans = _.get(submissionVM, 'value.bindData.paymentPlans_Ext');
            updatePaymentPlanDatas(paymentPlans);
            _.set(submissionVM, 'value.bindData.paymentPlans', paymentPlans);
            updateWizardData(submissionVM);
            updateHasRetrievedPaymentPlans(true);
            if (!_.isNil(paymentPlans)) {
                let currentPaymentPlan = PaymentUtil
                    .getSelectedPaymentPlan(selectedPaymentPlan, paymentPlans);
                const paymentViewType = wizardPageData[WizardConstants.paymentViewType];
                if (paymentViewType) {
                    const currentPaymentlanID = _.get(currentPaymentPlan, 'billingId');
                    const selectedPlanType = PaymentUtil
                        .getPaymentPlanType(currentPaymentlanID, paymentPlans);
                    if (paymentViewType !== selectedPlanType) {
                        currentPaymentPlan = PaymentUtil
                            .getPaymentPlanByTerm(termType, paymentViewType, paymentPlans);
                    }
                }
                // update paymentPlan and refresh payment schedule
                // selectPaymentPlanAndUpdateSchedule(currentPaymentPlan);
                // update paymentInput data
                const paymentPlan = currentPaymentPlan || _.find(_.get(submissionVM, 'value.bindData.paymentPlans_Ext'), (item) => item.billingId === selectedPaymentPlan);
                updatePaymentInputData(_.get(submissionVM, 'value.bindData.premium_Ext'), paymentPlan);
                updateSelectPaymentPlanFinished(true);
            }
        }
    }, [isVMInitialised, submissionVM, updateWizardData,
        resetPopupPaymentMethodVM, authHeader,
        viewModelService, hasRetrievedPaymentPlans,
        payUsing.check, paymentPlanChange, fullPayStr,
        paymentMethodsVM, updateWizardPageData,
        wizardPageData, updateWizardSnapshot,
        resetPrimaryPayerOptions, selectedPaymentPlan,
        termType, refreshPremiums, selectPaymentPlanAndUpdateSchedule,
        updatePaymentInputData]);

    useEffect(() => {
        if (selectPaymentPlanFinished) {
            updateShowLoader(false);
        }
    }, [selectPaymentPlanFinished]);

    useEffect(() => {
        // check if the response is valid
        const errors = [];
        //  check dueDateExt
        if (_.isNil(dueDateExt)) {
            errors.push({
                type: 'error',
                reason: translator(messages2.noDueDateDataMessage)
            });
            updateDueDateExtError(true);
        } else {
            const { days } = PaymentUtil.trnasformDate(dueDateExt, DateUtil);
            if (_.isNil(days) || _.isNaN(days) || days < 0) {
                errors.push({
                    type: 'error',
                    reason: translator(messages2.noDueDateDataMessage)
                });
                updateDueDateExtError(true);
            } else {
                updateDueDateExtError(false);
            }
        }
        // check paymentPlanDatas
        if (_.isNil(paymentPlanDatas) || _.get(paymentPlanDatas, 'length') === 0) {
            errors.push({
                type: 'error',
                reason: translator(messages2.noPaymentPlanDataMessage)
            });
            updatePaymentPlansDataError(true);
        } else {
            updatePaymentPlansDataError(false);
        }
        updateValidationIssuesWarning(errors);
    }, [dueDateExt, paymentPlanDatas, translator]);

    const formatPaymentData = useCallback(
        () => {
            const paymentDetailsPath = 'bindData.paymentDetails';
            const val = _.get(submissionVM, 'bindData.payUsing_Ext');
            if (val.value !== PaymentPlanConfig.payUsing.check) {
                const paymentData = paymentMethodsVM.find(
                    (vm) => _.get(vm, 'value.bankAccountData.publicID') === val.value
                );
                if (paymentData) {
                    if (paymentData.paymentMethod.value === paymentMethodData.bank) {
                        _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', paymentMethodData.bank);
                        _.set(submissionVM, `${paymentDetailsPath}.bankAccountData`, paymentData.bankAccountData.value);
                    } else if (paymentData === paymentMethodData.credit) {
                        _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', paymentMethodData.credit);
                        _.set(submissionVM, `${paymentDetailsPath}.creditCardData`, paymentData.creditCardData.value);
                    }
                }
            }
            _.set(submissionVM, 'bindData.billingMethod_Ext', 'DirectBill');

            const paymentPlans = _.get(submissionVM, 'value.bindData.paymentPlans');
            _.forEach(paymentPlans, (plan) => {
                _.unset(plan, 'treeNode_Ext');
            });

            return submissionVM.value.bindData;
        },
        [submissionVM, paymentMethodsVM, paymentMethodData.bank, paymentMethodData.credit]
    );

    const generateDaysOfMonth = useCallback(() => {
        return PaymentUtil.initDayOfMonth(offeredQuotes, chosenQuote, dueDateExt, DateUtil);
    }, [dueDateExt, chosenQuote, offeredQuotes]);

    const handlePaymentOptionChange = useCallback((value) => {
        let dataToOmit;
        if (value === paymentMethodData.bank) {
            dataToOmit = 'creditCardData';
            submissionVM.bindData.paymentDetails.bankAccountData.value = _.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData', {});
        } else {
            dataToOmit = 'bankAccountData';
            submissionVM.bindData.paymentDetails.creditCardData.value = _.get(submissionVM.value, 'bindData.paymentDetails.creditCardData', {});
        }

        submissionVM.value = _.omit(
            submissionVM.value,
            `bindData.paymentDetails.${dataToOmit}`
        );

        _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', value);
        updateWizardData(submissionVM);
    }, [paymentMethodData.bank, submissionVM, updateWizardData]);

    const handleDueDateChange = useCallback(async (value) => {
        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );
        if (!_.isNil(dueDateExt)) {
            const {
                dueDate, days
            } = PaymentUtil.trnasformDate(dueDateExt, DateUtil);
            if (_.isNil(days) || _.isNaN(days) || days < 0) {
                return;
            }
            dueDate.setDate(value);
            _.set(chosenQuoteData, 'dueDate_Ext', dueDate);
            updateDueDateExt(dueDate);
            _.set(submissionVM, 'value.bindData.firstDayOfMonth_Ext', value);

            // refresh premiums of paymentPlans
            const isAutoPay = _.get(submissionVM, 'value.bindData.payUsing_Ext') !== payUsing.check;
            await refreshPremiums(value, isAutoPay);
            updateWizardData(submissionVM);
        }
    }, [dueDateExt, payUsing.check, refreshPremiums, submissionVM, updateWizardData]);

    const onCell = (items, index, property) => {
        return items[property.path];
    };

    const showModal = useCallback((getPaymentDetailVM) => {
        const componentProps = {
            title: translator(messages2.addPaymentMethodTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages2.dialogOk,
            cancelBtnLabel: messages2.dialogCancel,
            paymentDetailVM: getPaymentDetailVM,
        };
        return modalApi.showModal(<AddPaymentMethodPopup {...componentProps} />);
    }, [translator, viewModelService]);

    const addPaymentMethod = useCallback(() => {
        showModal(popupPaymentMethodVM).then(async (updatedVM) => {
            resetPopupPaymentMethodVM();
            const updateBankAcountNumber = _.get(updatedVM, 'value.bankAccountData.bankAccountNumber');
            if (!_.isEmpty(updateBankAcountNumber)) {
                const result = PaymentUtil.checkPaymentMethodExist(updatedVM, paymentMethodsVM);
                if (!_.isEmpty(result)) {
                    return false;
                }
                updateShowNotification(true);

                // trigger generate autoPay and get new payInstrument publicID
                const publicID = await generateAutoPayEnrollment(updatedVM);
                _.set(updatedVM, 'value.bankAccountData.publicID', publicID);
                const modifiedVM = viewModelService.clone(updatedVM);
                updatePaymentMethodsVM([...paymentMethodsVM, modifiedVM]);
                const oldPayUsing = _.get(submissionVM, 'value.bindData.payUsing_Ext');
                // check -> autopay
                if (oldPayUsing === payUsing.check) {
                    // refresh premiums of paymentPlans
                    const firstDayOfMonthExt = _.get(submissionVM, 'value.bindData.firstDayOfMonth_Ext');
                    await refreshPremiums(firstDayOfMonthExt, true);
                }
                // select option
                const option = _.get(updatedVM, 'value.bankAccountData.publicID');
                _.set(submissionVM, 'bindData.payUsing_Ext', option);
                updateWizardData(submissionVM);
                return true;
            }
            return false;
        }).catch(() => {
            resetPopupPaymentMethodVM();
            updateShowNotification(false);
            updateShowLoader(false);
            // do nothing when close the popup
            _.noop();
        });
    }, [showModal, popupPaymentMethodVM, resetPopupPaymentMethodVM,
        paymentMethodsVM, generateAutoPayEnrollment,
        viewModelService, submissionVM, payUsing.check, updateWizardData,
        refreshPremiums]);

    const checkDownPaymentValid = useCallback((newValue) => {
        if (!offeredQuotes) {
            return false;
        }
        const chosenOfferedQuote = offeredQuotes.find(
            (offeredQuote) => offeredQuote.publicID === chosenQuote
        );
        if (!chosenOfferedQuote) {
            return false;
        }
        const defaultValue = chosenOfferedQuote.downPayment_Ext;
        const retval = newValue && (newValue.amount >= defaultValue.amount);
        return retval;
    }, [chosenQuote, offeredQuotes]);

    const isAgencySweepValueValid = useCallback(() => {
        const newValue = _.get(submissionVM, 'bindData.agencySweepValue_Ext.value');
        return checkDownPaymentValid(newValue);
    }, [checkDownPaymentValid, submissionVM]);

    const isElectronicValueValid = useCallback(() => {
        const newValue = _.get(submissionVM, 'bindData.electronicValue_Ext.value');
        return checkDownPaymentValid(newValue);
    }, [checkDownPaymentValid, submissionVM]);


    const callBindSubmission = useCallback(async (
        quoteIDParam, sessionUUIDParam, bindDataParam, ignoreWarning) => {
        const result = await WniLoadSaveService.bindSubmission(
            quoteIDParam,
            sessionUUIDParam,
            bindDataParam,
            ignoreWarning,
            authHeader
        );
        return result;
    }, [authHeader]);

    const callIssueSubmission = useCallback(async (
        quoteIDParam, sessionUUIDParam) => {
        const bindResult = await callBindSubmission(
            quoteIDParam,
            sessionUUIDParam,
            formatPaymentData(),
            true
        );
        // initial getting issues from resposne
        const {
            errorsAndWarnings: {
                underwritingIssues = [],
                validationIssues: {
                    issues = [],
                    fieldIssues = []
                } = {}
            } = {}
        } = bindResult;
        // concat validationIssues
        const validationIssues = [...issues, ...fieldIssues];
        // filter error level issues
        const errorValidateIssues = _.filter(validationIssues, (issue) => _.get(issue, 'type') === 'error');
        // convert uwIssues
        const convertedUnderwritingIssues = _.map(underwritingIssues,
            ({ issueTypeCode_Ext: issueTypeCodeExt, longDescription }) => {
                return {
                    type: issueTypeCodeExt,
                    reason: _.get(PaymentUtil.UWISSUE_MAP, issueTypeCodeExt) || longDescription
                };
            });
        // combine validationIssues and underwritingIssues
        const errors = [...errorValidateIssues, ...convertedUnderwritingIssues];
        if (!_.isEmpty(errors)) {
            updateValidationIssuesWarning(errors);
            return false;
        }
        // default return
        return bindResult;
    }, [callBindSubmission, formatPaymentData]);

    const checkElectronic = useCallback((downpayment) => {
        if (downpayment === 'Electronic' && !isElectronicValueValid()) {
            updateShowErrors(true);
            return false;
        }
        return true;
    }, [isElectronicValueValid]);

    const checkAgencySweep = useCallback((downpayment) => {
        if (downpayment === 'AgencySweep' && !isAgencySweepValueValid()) {
            updateShowErrors(true);
            return false;
        }
        return true;
    }, [isAgencySweepValueValid]);

    const handleNext = useCallback(
        async () => {
            const downpayment = _.get(submissionVM, 'bindData.downPayment_Ext.value');
            if (checkElectronic(downpayment) && checkAgencySweep(downpayment)) {
                // no errorsAndWarnings then call bind
                const bindResult = await callIssueSubmission(
                    _.get(submissionVM.value, 'quoteID'),
                    _.get(submissionVM.value, 'sessionUUID')
                );
                if (!bindResult) {
                    return false;
                }
                submissionVM.value = bindResult;
                await WniPolicyService.addRecentlyViewedPolicy(quoteID, authHeader);
                return submissionVM;
            }
            return false;
        },
        [submissionVM, checkElectronic, checkAgencySweep, callIssueSubmission, quoteID, authHeader]
    );

    useEffect(() => {
        registerComponentValidation(isAgencySweepValueValid);
    }, [registerComponentValidation, isAgencySweepValueValid]);

    const handlePayUsingRadioChange = useCallback(async (value) => {
        if (value === payUsing.autopayNew) {
            addPaymentMethod();
        } else {
            // reset to default
            if (value === payUsing.check) {
                const vm = {};
                await generateAutoPayEnrollment(vm);
            } else {
                // set as New InvoiceStream
                // trigger AutoPay generate
                const vm = _.find(paymentMethodsVM, (bank) => {
                    return _.get(bank, 'value.bankAccountData.publicID') === value;
                });
                if (!_.isEmpty(vm)) {
                    await generateAutoPayEnrollment(vm);
                }
            }
            const oldPayUsing = _.get(submissionVM, 'value.bindData.payUsing_Ext');
            // check -> autopay or autoPay -> check
            if (
                (oldPayUsing === payUsing.check && value !== payUsing.check)
                || (oldPayUsing !== payUsing.check && value === payUsing.check)
            ) {
                // refresh premiums of paymentPlans
                const firstDayOfMonthExt = _.get(submissionVM, 'value.bindData.firstDayOfMonth_Ext');
                await refreshPremiums(firstDayOfMonthExt, value !== payUsing.check);
            }
            _.set(submissionVM, 'value.bindData.payUsing_Ext', value);
            updateWizardData(submissionVM);
        }
    }, [addPaymentMethod, generateAutoPayEnrollment,
        payUsing.autopayNew, payUsing.check,
        paymentMethodsVM, submissionVM, updateWizardData,
        refreshPremiums]);


    const handlePaymentChange = useCallback((value, path) => {
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);


    const getPaymentDetailsData = useCallback(() => {
        const data = [
            {
                code: payUsing.check,
                description: payUsing.check
            }
        ];
        if (paymentMethodsVM.length > 0) {
            paymentMethodsVM.forEach((vm) => {
                // last 4 digits of bankAccountNumber
                let digits = _.get(vm, 'value.bankAccountData.bankAccountNumber');
                if (digits) {
                    digits = digits.substr(-4);
                }
                let type = _.get(vm, 'value.bankAccountData.bankAccountType');
                type = type === 'checking' ? 'Checking' : 'Saving';
                data.push({
                    code: _.get(vm, 'value.bankAccountData.publicID'),
                    description: `Autopay (${type} - ${digits})`,
                });
            });
        } else {
            data.push({
                code: payUsing.autopayNew,
                description: payUsing.autopayNew,
            });
        }
        return data;
    }, [payUsing.autopayNew, payUsing.check, paymentMethodsVM]);

    const getPaymentMethodDropdownData = useCallback(() => {
        let data = [];
        data = PaymentUtil.addPaymentMethodOptions(data, paymentMethodsVM);
        data = PaymentUtil.addPaymentMethodOptions(data, oneTimePaymentMethodsVM);
        data.push({
            code: 'addNewCreditCard',
            name: translator(messages2.addNewCreditCard)
        });
        data.push({
            code: 'addNewBankAccount',
            name: translator(messages2.addNewBankAccount)
        });
        return data;
    }, [paymentMethodsVM, oneTimePaymentMethodsVM, translator]);

    const issueAndForward = useCallback(async (url, quoteIDParam) => {
        return handleNext().then((res) => {
            updateShowLoader(true);
            if (res) {
                // add browser history
                window.history.pushState({}, '', `${window.location.origin}/${routerBaseName}/${WniUrlUtil.BROADRIDGE}/paySuccessByBCC/${quoteIDParam}/cancel`);
                window.location.href = url;
            } else {
                // hide the loader when error occurs
                updateShowLoader(false);
                // select default
                updatePaymentDetailsForDownPayment(undefined);
            }
        });
    }, [handleNext, routerBaseName]);

    const jumpToBCC = useCallback(async () => {
        const downpayment = _.get(submissionVM, 'bindData.downPayment_Ext.value');
        if (checkElectronic(downpayment) && checkAgencySweep(downpayment)) {
            // edge api
            try {
                const result = await WniGatewayBillingSubmissionService.getUrlgeneration(
                    [JSON.stringify(requestParam)], authHeader
                );
                const bccUrl = _.get(result, 'url');
                if (_.isNil(bccUrl)) {
                    PaymentUtil.showBCCFailPopup(
                        modalApi, commonMessages.genericError,
                        messages2.bccErrorMessage, commonMessages.ok
                    );
                    return false;
                }
                _.set(submissionVM, 'value.bindData.paymentDetailsForDownPayment_Ext', paymentDetailsForDownPaymentExt);
                updateWizardData(submissionVM);
                // handleNext and jumpt link
                await issueAndForward(bccUrl, quoteID);
            } catch (ex) {
                PaymentUtil.showBCCFailPopup(
                    modalApi, commonMessages.genericError,
                    messages2.bccErrorMessage, commonMessages.ok
                );
                return false;
            }
        }
        return false;
    }, [authHeader, checkAgencySweep, checkElectronic,
        paymentDetailsForDownPaymentExt, quoteID, requestParam,
        submissionVM, updateWizardData, issueAndForward]);

    const updateElectronicBank = useCallback((bankVM, basicParam) => {
        const bankValue = _.get(bankVM, 'value');
        const bank = _.get(bankValue, 'bankAccountData.publicID');
        const payDate = WniDateUtil.formatDateWithPattern(new Date());
        updatePaymentDetailsForDownPayment(bank);
        const toUpdateRequestParam = PaymentUtil.getToUpdateRequestParam(
            basicParam, payDate, bankValue
        );
        updateRequestParam(toUpdateRequestParam);
        updatePaymentDetailsForDownPaymentExt(bankValue);
    }, []);

    const handleDownPaymentMethodChange = useCallback(async (value) => {
        if (!isElectronicValueValid()) {
            updateShowErrors(true);
            return false;
        }
        const amt = parseFloat(_.get(submissionVM, 'value.bindData.electronicValue_Ext.amount')).toFixed(2);
        const amtDue = `${amt}`;
        const baseCallBackUrl = `${window.location.origin}/${routerBaseName}/broadridge/paySuccessByBCC/${quoteID}`;
        const basicParam = PaymentUtil.getDefaultBasicRequestParam(
            submissionVM, amtDue, baseCallBackUrl
        );
        // select Add New Credit Card
        if (value === 'addNewCreditCard') {
            updatePaymentDetailsForDownPayment(value);
            updateRequestParam({ ...basicParam, ActionCode: 'EXPAYCC' });
            updatePaymentDetailsForDownPaymentExt({ paymentMethd: 'creditcard' });
            return true;
        }

        // select Add New Bank Acount
        if (value === 'addNewBankAccount') {
            // popup a window to enter
            const paymentDetails = {
                paymentMethod: 'autopay_Ext',
                bankAccountData: {
                    bankAccountType: 'checking'
                }
            };
            const popupOneTimePaymentMethodVM = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
            return showModal(popupOneTimePaymentMethodVM).then(async (updatedVM) => {
                resetPopupPaymentMethodVM();
                // check payment exist in payment methods or one-time payment
                const paymentMethodOptions = paymentMethodsVM.concat(oneTimePaymentMethodsVM);
                const result = PaymentUtil.checkPaymentMethodExist(updatedVM, paymentMethodOptions);
                if (!_.isEmpty(result)) {
                    updateElectronicBank(result, basicParam);
                    return true;
                }
                const payDate = WniDateUtil.formatDateWithPattern(new Date());
                const oneTimePaymentPublicID = PaymentUtil.getOneTimePaymentPublicID(updatedVM);
                const toUpdateRequestParam = PaymentUtil.getToUpdateRequestParam(
                    basicParam, payDate, _.get(updatedVM, 'value')
                );
                updateRequestParam(toUpdateRequestParam);
                const paymentDetailsDTO = {
                    paymentMethod: 'autopay_Ext'
                };
                updatePaymentDetailsForDownPaymentExt(paymentDetailsDTO);
                // add option to dropdown
                _.set(updatedVM, 'value.bankAccountData.publicID', oneTimePaymentPublicID);
                // add this option only to the dropdown list
                const modifiedVM = viewModelService.clone(updatedVM);
                updateOneTimePaymentMethodsVM([...oneTimePaymentMethodsVM, modifiedVM]);
                updatePaymentDetailsForDownPayment(oneTimePaymentPublicID);
                return true;
            }).catch(() => {
                updatePaymentDetailsForDownPayment(undefined);
                _.noop();
                return false;
            });
        }
        // select added bank
        if (value && paymentMethodsVM.length > 0) {
            const options = paymentMethodsVM.concat(oneTimePaymentMethodsVM);
            const selectedVM = PaymentUtil.findSelectedPaymentMethod(value, options);
            updateElectronicBank(selectedVM, basicParam);
            return true;
        }
        // select default
        updatePaymentDetailsForDownPayment(value);
        return false;
    }, [isElectronicValueValid, oneTimePaymentMethodsVM, paymentMethodsVM, quoteID,
        resetPopupPaymentMethodVM, showModal, submissionVM, updateElectronicBank,
        viewModelService, routerBaseName]);

    const downPaymentChange = useCallback((value, path) => {
        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );
        const downPaymentExt = _.get(chosenQuoteData, 'downPayment_Ext');
        if (value === 'AgencySweep') {
            _.set(submissionVM, 'bindData.agencySweepValue_Ext', downPaymentExt);
            updateIsBCC(false);
        } else {
            _.set(submissionVM, 'bindData.electronicValue_Ext', downPaymentExt);
            updateIsBCC(true);
        }
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const isPlanOverrideVisible = useCallback(() => {
        const selectedPaymentPlanId = _.get(submissionVM.value, 'bindData.selectedPaymentPlan');
        const plans = _.get(submissionVM.value, 'bindData.paymentPlans');
        if (!selectedPaymentPlanId || !plans) {
            return false;
        }
        const selectedPlan = plans.find((plan) => plan.billingId === selectedPaymentPlanId);
        return selectedPlan;
    }, [submissionVM.value]);

    const handleCancel = useCallback(
        () => {
            return isComponentValid ? submissionVM : wizardSnapshot;
        }, [isComponentValid, submissionVM, wizardSnapshot]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            return false;
        },
        [updateShowErrors]
    );

    const onNewPersonClick = useCallback(() => {
        const modalProps = {
            title: translator(messages2.newPersonTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages2.dialogOk,
            cancelBtnLabel: messages2.dialogCancel,
            authHeader,
            submissionVM,
            primaryPayerAvailableValues
        };
        return modalApi.showModal(<NewPersonComponent {...modalProps} />)
            .then((res) => {
                updatePrimaryPayer(_.get(res, 'displayName'));
                _.set(submissionVM, 'bindData.primaryPayer_Ext', res);
                resetPrimaryPayerOptions(res);
                _.noop();
            }).catch(() => {
                _.noop();
            });
    }, [authHeader, primaryPayerAvailableValues, resetPrimaryPayerOptions, submissionVM,
        translator]);

    const primaryPayerChange = useCallback((value) => {
        if (value === 'New Person') {
            onNewPersonClick();
        } else {
            const contact = JSON.parse(value);
            updatePrimaryPayer(_.get(contact, 'displayName'));
            _.set(submissionVM, 'bindData.primaryPayer_Ext', contact);
        }
    }, [onNewPersonClick, submissionVM]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        paymentInputContainer: {
            totalModel: submissionVM,
            onPaymentInfoChange: handlePaymentChange,
        },

        planOverrideContainer: {
            visible: isPlanOverrideVisible()
        },
        dueDayOfTheMonth_Ext: {
            availableValues: generateDaysOfMonth(),
            onValueChange: handleDueDateChange
        },
        payUsingTable: {
            data: getPaymentDetailsData()
        },
        payUsingRadioColumn: {
            onValueChange: handlePayUsingRadioChange,
            value: _.get(submissionVM, 'bindData.payUsing_Ext.value')
        },
        paymentOptions: {
            onValueChange: handlePaymentOptionChange
        },
        bankAccountContainer: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === paymentMethodData.bank
        },
        agencySweepValue_Ext: {
            disabled: _.get(submissionVM, 'bindData.downPayment_Ext.value') !== 'AgencySweep',
            showErrors: !isAgencySweepValueValid(),
            validationMessages: isAgencySweepValueValid() ? [] : ['Number value is less than min value'],
            className: _.get(submissionVM, 'bindData.agencySweep_Ext.value') === 'Yes' ? 'form-control-no-label' : styles.displayNone
        },
        electronicValue_Ext: {
            disabled: _.get(submissionVM, 'bindData.downPayment_Ext.value') !== 'Electronic',
            showErrors: !isElectronicValueValid(),
            validationMessages: isElectronicValueValid() ? [] : ['Number value is less than min value'],
            className: 'form-control-no-label'
        },
        choosePaymentMethodDropdown: {
            disabled: _.get(submissionVM, 'bindData.downPayment_Ext.value') !== 'Electronic',
            availableValues: getPaymentMethodDropdownData(),
            onValueChange: handleDownPaymentMethodChange,
            value: paymentDetailsForDownPayment,
            required: _.get(submissionVM, 'bindData.downPayment_Ext.value') === 'Electronic'
        },
        paymentPlansList: {
            submissionVM: submissionVM,
            onValueChange: selectPaymentPlanAndUpdateSchedule,
            authHeader,
            quoteID: _.get(submissionVM, 'value.quoteID'),
            sessionUUID: _.get(submissionVM, 'value.sessionUUID'),
            updateWizardPageData,
            wizardPageData,
            WniLoadSaveService,
            updateShowLoader
        },
        downPaymentRadio: {
            onValueChange: downPaymentChange,
            availableValues: getDownPaymentRaido()
        },
        payUsingDescription: {
            renderCell: onCell
        },
        primaryPayer_Ext: {
            value: primaryPayer
        },
        autoPayNotification: {
            visible: showNotification
        },
        primaryPayerInputContainer: {
            className: styles.primaryPayerInputContainer
        },
        primaryNamedInsuredEditIcon: {
            className: styles.primaryNamedInsuredEditIcon
        },
        primaryPayerContainer: {
            value: primaryPayer,
            placeholder: primaryPayer,
            onValueChange: primaryPayerChange,
            availableValues: primaryPayerAvailableValues
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssuesWarning,
            visible: validationIssuesWarning.length > 0,
            id: 'validationIssuesComponentId',
            scrollToIssues: true
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {
            addPaymentMethod: addPaymentMethod,
            onValidate,
            onNewPersonClick
        },
        resolveComponentMap: {
            paymentinputcomponent: PaymentInputComponent,
            validationissuescomponent: ValidationIssuesComponent
        },
        resolveClassNameMap: styles
    };

    // ==========================
    if (!bindData) { // or _.isEmpty(bindData)
        return null;
    }
    // check whether using jumpTo
    const checkIsBCC = isBCC ? jumpToBCC : handleNext;
    return (
        <WizardPage
            nextLabel={messages2.paymentPayAndIssue}
            onNext={isComponentValid ? checkIsBCC : handleValidation}
            disableNext={showLoader || dueDateExtError || paymentPlansDataError}
            onCancel={handleCancel}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        onModelChange={updateWizardData}
                        overrideProps={overrideProps}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        classNameMap={resolvers.resolveClassNameMap}
                        resolveValue={readValue}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
