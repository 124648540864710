import React, {
    useEffect,
} from 'react';
import {
    useHistory,
    useParams,
} from "react-router-dom";

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';


// const { lobEndorsementURL, endorsementProducts } = appConfig;
const { lobQuoteURL } = appConfig;

function SubmissionWizardCommonRoutes(props) {

    const history = useHistory();
    const {
        productCode,
        postalCode,
        quoteID,
        // periodPublicID,
    } = useParams();

    useEffect(() => {
        const targetURL = lobQuoteURL[productCode];
        const stateObj = {
            quoteentry: {
                postalCode,
                quoteID,
                // periodPublicID,
            }
        }
        history.push(targetURL, stateObj);
    }, []);

    return null;

}

export default SubmissionWizardCommonRoutes;