import React, {} from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { DetailsDropdownSelect } from './component/DetailsDropdownSelect';
import { DetailsIntegerInput } from './component/DetailsIntegerInput';
import { DetailsDateInput } from './component/DetailsDateInput';
import { DetailsStringInput } from './component/DetailsStringInput';
import { DetailsMultiSelect } from './component/DetailsMultiSelect';

const GLSublineDetails = (props) => {

    const {
        details,
        loadingClauses,
        onValidate,
        showErrors,
        isEditable,
        changeDetailsAndSync,
    } = props

    return <>
        {details
            .map((displayable, index) => {
                const {
                    label,
                    inputSetMode,
                } = displayable

                const commonprops = {
                    displayable: displayable,
                    detailsIndex: index,
                    changeDetailsAndSync: changeDetailsAndSync,
                    onValidate: onValidate,
                    showErrors: showErrors,
                    isEditable: isEditable,
                    disabled: loadingClauses,
                }
                switch (inputSetMode) {
                    case 'dynamicrange':
                    case 'range':
                        return <DetailsDropdownSelect
                            {...commonprops}
                        />
                    case 'rangemultiselect_ext':
                        return <DetailsMultiSelect
                            {...commonprops}
                        />
                    case 'integer':
                        return <DetailsIntegerInput
                            {...commonprops}
                        />
                    case 'date':
                    case 'dynamicdate':
                        return <DetailsDateInput
                            {...commonprops}
                        />
                    case 'dynamicdefault':
                        return <DetailsStringInput
                            {...commonprops}
                        />
                    case 'label':
                        return <h4>{label}</h4>
                    default:
                        return <div style={{color: 'red'}}>Unsupport value type</div>
                }
            })}
    </>
}

GLSublineDetails.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    lobName: PropTypes.string.isRequired,
    coveragesService: PropTypes.shape({}),
    loadingLineCoverages: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    setLoadingLineCoverages: PropTypes.func,
    setIsEditing: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
}

GLSublineDetails.defaultProps = {
    updateWizardData: _.noop,
    coveragesService: {},
    loadingLineCoverages: false,
    setLoadingLineCoverages: _.noop,
    setIsEditing: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
}

export default GLSublineDetails
