import _ from 'lodash';
import moment from 'moment';

const TERM_TYPE_ANNUAL = 'Annual';
const TERM_TYPE_HALF_YEAR = 'HalfYear';
const SUFFIX = ['st', 'nd', 'rd', 'th'];

/**
 * Checkes wither dateToCompareis nDaysInTheFuture days in the future.
 * @param {object} dateToCompare with shape of {year, month, day};
 * @param {number} nDaysInTheFuture how many days in the future
 * @returns {boolean} true if dateToCompare > now + nDaysInTheFuture, otherwise false.
 * @see https://momentjs.com/docs/#/displaying/difference/
 */
function isMoreThanXDaysInFuture(dateToCompare, nDaysInTheFuture) {
    const diffInDays = moment(dateToCompare).diff(moment(), 'days');
    return diffInDays > nDaysInTheFuture;
}

/**
 * Try to calculate policyEndDate from existing policyStartDate and termType;
 * @param {object} policyStartDate with shapfe of {year, month, day};
 * @param {string} termTypeCode either "Annual" or "HalfYear";
 * @returns {object} A Date object that can be assigned to policyEndDate, or
 * null if the termType could not be recognized.
 */
function getPolicyEndDate(policyStartDate, termTypeCode) {
    const startDate = moment(policyStartDate);
    let endDate;
    if (TERM_TYPE_ANNUAL === termTypeCode) {
        endDate = startDate.add(1, 'years');
    } else if (TERM_TYPE_HALF_YEAR === termTypeCode) {
        endDate = startDate.add(6, 'months');
    }

    let retval;
    if (endDate) {
        retval = {
            year: endDate.year(),
            month: endDate.month(),
            day: endDate.date(),
        };
    }
    return retval;
}

/**
 * parse date to a patterned string
 * @param {date} date
 * @param {string} pattern date format pattern;
 * @returns {string} A date formatted with given pattern
 */
function formatDateWithPattern(date, pattern) {
    return moment(date).format(pattern || 'MMM D, YYYY');
}

/**
 * back to the early date
 * @returns {date}
 */
function backEarlyDate() {
    const backDate = new Date();
    backDate.setFullYear(1900);
    return backDate;
}


/**
 * get date suffix as sup
 * @param {int} day
 * @returns {string} the date sup
 */
function getSuffix(day) {
    let suffix;
    // refer to date of 11th 12th 13th
    const exceptionDate = [11, 12, 13];
    // for date that use 1st 2nd 3rd
    const specialSuffixNum = [1, 2, 3];
    const dayStr = `${day}`;
    const lastNum = parseInt(dayStr.substr(-1), 0);
    if (exceptionDate.includes(day)) {
        suffix = SUFFIX[SUFFIX.length - 1];
    } else if (specialSuffixNum.includes(lastNum)) {
        suffix = SUFFIX[lastNum - 1];
    } else {
        suffix = SUFFIX[SUFFIX.length - 1];
    }
    return suffix;
}

function isDaysAfter(date, dayNum) {
    const today = moment();
    const chooseDate = moment(date);
    return today.isSameOrBefore(moment(chooseDate).subtract(dayNum, 'days'), 'seconds');
}

function isDaysBefore(date, dayNum) {
    const today = moment();
    const chooseDate = moment(date);
    return today.isSameOrAfter(moment(chooseDate).add(dayNum, 'days'), 'seconds');
}

function getDateObj(timeDate, add) {
    const date = moment(timeDate);
    return {
        year: date.year(),
        month: date.month(),
        day: date.date(),
    };
}

function getDiffDateByDay(date, dayNum) {
    const targetDate = moment(date);
    return moment(targetDate).add(dayNum, 'days');
}


/**
 * Parse effective date from AO, YYYMMDD, 20200820
 * @param {String} dateValueStr
 * @returns {object}
 */
function parseDateExternal(dateValueStr) {
    if (!/^(\d){8}$/.test(dateValueStr)) return "invalid date";
    const y = dateValueStr.substr(0, 4);
    const m = dateValueStr.substr(4, 2) - 1;
    const d = dateValueStr.substr(6, 2);
    const date = new Date(y,m,d);
    date.setHours(0, 0, 0, 0);
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    };
}

function compareWithMinDateByQuoteFlow(periodStart, quoteFlowExt) {
    let minDate;
    // quoteFlow and policyInfoSource are TypeKey, you need to retrieve its code for comparison
    const quoteFlow = _.get(quoteFlowExt, 'code');
    if (quoteFlow === 'draft') {
        minDate = moment().startOf('day').toDate();
    } else {
        minDate = moment().subtract(5, 'd').startOf('day').toDate();
    }

    const dif = moment(minDate).startOf('day').toDate().getTime() - moment(periodStart).startOf('day').toDate().getTime();
    if (dif === 0) {
        return 0;
    }
    return dif > 0 ? 1 : -1;
}

function formatLocalDateBaseUTC(date, timezone = -7) {
    const localDate = date ? new Date(date) : new Date();
    // Deviation between local time and UTC time;
    const utcOffset = localDate.getTimezoneOffset() / 60;
    // Deviation between local time and target time;
    const localOffset = utcOffset + timezone;
    
    const offsetUTC = localDate.getTime() + (60*60*1000*localOffset);
    return new Date(offsetUTC)
}

export default {
    isMoreThanXDaysInFuture,
    getPolicyEndDate,
    formatDateWithPattern,
    backEarlyDate,
    getSuffix,
    isDaysAfter,
    isDaysBefore,
    getDateObj,
    getDiffDateByDay,
    parseDateExternal,
    compareWithMinDateByQuoteFlow,
    formatLocalDateBaseUTC
};
