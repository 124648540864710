import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caDriverService'), method, data, additionalHeaders);
}

export default class CADriverService {
    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static updateDriver(data, additionalHeaders = {}) {
        return processSubmission(
            'updateDriver',
            [data.jobID, data.sessionUUID, data.driver],
            additionalHeaders
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static removeDrivers(data, additionalHeaders = {}) {
        return processSubmission(
            'removeDrivers',
            [data.jobID, data.sessionUUID, data.publicIDs],
            additionalHeaders
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static addDriverForPNI(data, additionalHeaders = {}) {
        return processSubmission(
            'addDriverForPNI',
            [data.jobID, data.sessionUUID],
            additionalHeaders
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, additionalHeaders = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            additionalHeaders
        );
    }

}