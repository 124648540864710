import React from 'react'
import { CRCommonercialCrimeService } from 'wni-capability-quoteandbind-cr'
import CRCoverableReadOnlyPage from '../CRCoverablePage/CRCoverablePage.readonly'


const CRCommercialCrimeReadOnlyPage = (props) => {
    return <CRCoverableReadOnlyPage
        {...props}
        initClausesFunc={CRCommonercialCrimeService.getCommercialCrime}
    />
}

export default CRCommercialCrimeReadOnlyPage