

import React from 'react';
import { IMFineArtsFloaterReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMFineArtsFloaterChangeReadOnlyPage = (props) => {
    return <IMFineArtsFloaterReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMFineArtsFloaterChangeReadOnlyPage
