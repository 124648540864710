
/**
 * Constants used in pa-wizard-config.json
 */
const WizardStepConstants = {
    isQuotePage: 'isQuotePage',
};


export default WizardStepConstants;
