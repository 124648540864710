
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';

import { IMDummyPage } from 'wni-capability-quoteandbind-im-react';

function IMDummyChangePage(props) {

    return (
        <IMDummyPage {...props} />
    );

}

IMDummyChangePage.propTypes = IMDummyPage.propTypes;
IMDummyChangePage.defaultProps = IMDummyPage.defaultProps;

export default IMDummyChangePage;