import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('crCommercialFidelityService'), method, data, additionalHeaders);
}

export default class CRCommercialFidelityService {
  
    /**
     * Invokes CRCommercialFidelityHandler.getCommercialFidelityWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getCommercialFidelityWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getCommercialFidelityWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CRCommercialFidelityHandler.getCommercialFidelity()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getCommercialFidelity(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getCommercialFidelity', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CRCommercialFidelityHandler.updateCommercialFidelity()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} crCoveragesDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCommercialFidelity(jobNumber, sessionUUID, crCoveragesDTO, authHeader = {}) {
        return processSubmission('updateCommercialFidelity', [jobNumber, sessionUUID, crCoveragesDTO], authHeader);
    }

    /**
     * Invokes CRCommercialFidelityHandler.searchCommercialFidelityCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchCommercialFidelityCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, authHeader = {}) {
        return processSubmission('searchCommercialFidelityCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType], authHeader);
    }

    /**
     * Invokes CRCommercialFidelityHandler.addSearchedCommercialFidelityCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedCommercialFidelityCoverages(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedCommercialFidelityCoverages', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes CRCommercialFidelityHandler.onPageNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onPageNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onPageNext', [jobNumber, sessionUUID], authHeader);
    }
}