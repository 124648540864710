import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { WniAccountService } from 'wni-capability-gateway';
import { QuestionSetComponent } from 'gw-components-platform-react';
import {  QuoteUtil, WindowUtil, IssuanceValidationUtil, WniQuestionSetUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { DomRenderUtil } from 'wni-portals-util-react';
import {
    AgencyOfServiceComponent,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
import { useWniModal } from 'wni-components-platform-react';
import { WizardConstants } from 'wni-portals-config-js';
import { HOHouseholdInfoService } from 'wni-capability-quoteandbind-ho';
import WizardPage from '../../templates/HOWizardPage';

import HouseholdResidentsPopup from './component/HouseholdResidentsPopup/HouseholdResidentsPopup';

import styles from './HOHouseholdInfoPage.module.scss';
import metadata from './HOHouseholdInfoPage.metadata.json5';
import messages from './HOHouseholdInfoPage.messages';
import HouseholdLocationPopupComponent from './component/HouseholdLocationPopup/HouseholdLocationPopupComponent';
import { IconButton } from '@jutro/legacy/components';

function HOHouseholdInfoReadOnlyPage(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardPageData,
        wizardPageData,
        //
        saveHoHouseholdInfo,
        householdInfoPageOnInit,
        isOpenPNIPopupPageInit,
        checkRequiredForIssuance
    } = props;

    const {
        [WizardConstants.externalAgencyOptions]: externalAgencyOptions,
        [WizardConstants.licensedAgentOptions]: licensedAgentOptions,
        [WizardConstants.agentStatus]: agentStatus,
        [WizardConstants.servicingAgentOptions]: servicingAgentOptions,
        // [WizardConstants.RCTCallback]: RCTCallback,
        // [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageData;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        lobData: {
            homeowners: {
                isFeb19EntityChangeVerExt,
                isApr16EntityChangeVerExt,
                householdInfoDTO_Ext: {
                    isPropertyRentedToOthers,
                    coveragesForm,
                    underNewConstructionType,
                    isNamedInsuredContractor,
                    totalNumOfHouseholdResidents,
                    householdResidents,
                    namedInsuredOccupancy,
                    hasLivedInLocationLess3Yrs,
                    isHouseholdInfoFirstEnter
                }
            }
        }
    } = submissionVM.value;

    const {
        baseState_Ext: baseState,
        producerCode_Ext: producerCode= '',
        producerCodePublicID_Ext: producerCodePublicID,
        producerDisplay_Ext: producerCodeDisplayName,
        primaryNamedInsured_Ext: primaryNamedInsured,
        producerOrLicensedAgent_Ext: licensedAgentPublicID,
        producerOrLicensedAgentUserDisplayName_Ext: licensedAgentValue,
        servicingAgent_Ext:  servicingAgentPublicID,
        servicingAgentDisplayName_Ext: servicingAgentValue,
    } = baseData;

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('HOHouseholdInfoPage');

    // can refer to the method of PA line, add the validation into VM, if done, this below func can be remove.
    const isEffectiveDateValid = useCallback(() => {
        const effectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = LocalDateUtil.today();
        const dif = moment(effectiveDate).startOf('day').toDate().getTime() - moment(minDate).startOf('day').toDate().getTime();

        return dif >= 0;
    }, [submissionVM]);

    useEffect(() => {
        registerComponentValidation(isEffectiveDateValid);
    }, [registerComponentValidation, isEffectiveDateValid]);
    const emptyHOHouseholdContactDTO = viewModelService.create(
        {}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
    );
    const relationshipToInsuredListsAvailableValues = _.get(emptyHOHouseholdContactDTO, 'relationshipToInsured_Ext.aspects.availableValues', []);

    const isBaseStateInMutualState = useCallback(() => {
        switch (baseState) {
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    }, [baseState])

    const translator = useTranslator();
    // const ViewModelService = useContext(ViewModelServiceContext);
    const {
        authHeader,
        authUserData
    } = useAuthentication();
    // const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const userPublicID = authUserData && authUserData.publicID;
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    // const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);
    const [householdResidentsTableData, updateHouseholdResidentsTableData] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    // require for issuance util
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuance(baseData, wizardPageData);
    const [requiredForIssuanceFields, updateRequiredForIssuanceFields] = useState([]);
    const requiredForIssuranceAllFields = ['residenceQuestionDropdown', 'generalContractorToggle',
        'constructionToggle', 'estimatedCompletionDateDropdown', 'isThisResidenceOccupiedSeasonallyToggle'];

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(primaryNamedInsured.isNil) {
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            const primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        const primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName') 
        return (
            <div>
                <div>{displayName}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [submissionVM]);

    const getResidenceLocaltionAndDisplay = useCallback(() => {
        const dwellingLocation = _.get(submissionVM, 'value.lobData.homeowners.householdInfoDTO_Ext.dwellingLocation');
        let displayName;
        let primaryAddress;
        if (dwellingLocation) {
            displayName = _.get(dwellingLocation, 'address.addressLine1');
            primaryAddress= `${_.get(dwellingLocation, 'address.state')}, ${_.get(dwellingLocation, 'address.postalCode')}`;
        } else {
            // TODO 
            // For account without existing policy, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            // For account with existing policy (PA), we will define requirement in future user story to copy data from PA. As of now, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
            primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{displayName}</div>
                <div>{primaryAddress || '-'}</div>
            </div>
        );
    }, [submissionVM]);

    const getPriorAddressAndDisplay = useCallback(() => {
        const priorAddress = _.get(submissionVM, 'value.lobData.homeowners.householdInfoDTO_Ext.priorAddress');
        let displayName = '';
        let primaryAddress = '';
        if (priorAddress) {
            displayName = _.get(priorAddress, 'addressLine1');
            primaryAddress= `${_.get(priorAddress, 'state')}, ${_.get(priorAddress, 'postalCode')}`;
        }
        return (
            <div>
                <div>{displayName}</div>
                <div>{primaryAddress || '-'}</div>
            </div>
        );
    }, [submissionVM]);

    const getOccupancyValue = useCallback(() => {
        const occupancyValue = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.namedInsuredOccupancy.aspects.availableValues')
        const displayOccupancy = occupancyValue.map((occupancy) => {
            return {
                code: occupancy.code,
                name:  translator({ id: occupancy.name }),
            }
        })
        return displayOccupancy
    },[submissionVM, translator])

    const getCoverageFormValue = useCallback(() => {
        const coverageFormValue = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm._aspects.availableValues')
        const displayCoverageForm = coverageFormValue.map((coverageForm) => {
            return {
                code: coverageForm.code,
                name: translator({ id: coverageForm.name })
            }
        })
        const requiredCoverageForm = ['ho3', 'ho4','ho5','ho6']
        const filteredCoverageForm = _.filter(displayCoverageForm, (item) => requiredCoverageForm.includes(item.code))     
        return filteredCoverageForm
    }, [submissionVM, translator])

    const getResidenceTypeDropdown = useCallback(() => {
        const allResidenceTypeValue = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.residenceType._aspects.availableValues')
        const displayallResidenceType = allResidenceTypeValue.map((residenceType) => {
            return {
                code: residenceType.code,
                name: translator({ id: residenceType.name })
            }
        })
        const ho3ResidenceType = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'townhouse_Ext']
        const ho4ResidenceType = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'hopcondo', 'hopapartment_Ext','townhouse_Ext']
        const ho5ResidenceType = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'townhouse_Ext']
        const ho6ResidenceType = ['hopcondo', 'townhouse_Ext']
        switch (coveragesForm) {
            case 'ho3':
                return _.filter(displayallResidenceType, (item) => ho3ResidenceType.includes(item.code));
            case 'ho4':
                return _.filter(displayallResidenceType, (item) => ho4ResidenceType.includes(item.code));
            case 'ho5':
                return _.filter(displayallResidenceType, (item) => ho5ResidenceType.includes(item.code));
            case 'ho6':
                return _.filter(displayallResidenceType, (item) => ho6ResidenceType.includes(item.code));
            default:
                return []
        }

    }, [coveragesForm, submissionVM, translator])


    const coveragesFormLogic = useCallback(() => {
        switch (coveragesForm) {
            case 'ho3': return true
            case 'ho4': return false
            case 'ho5': return true
            case 'ho6': return false
            default: ''
        }
    },[coveragesForm])

    const baseStateLogic = useCallback(() => {
        switch (baseState) {
            case 'AK': 
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    },[baseState])

    const shouldShowResidenceQuestionDropdown = useCallback(() => {
        return coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic])

    const isNamedInsuredContractorEnteredNo = useCallback(() => {
        if (isNamedInsuredContractor === false) {
            return true;
        } 
        return false;
    }, [isNamedInsuredContractor])
    const shouldShowGeneralContractorToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' ||
                    underNewConstructionType === 'yeswithstructuralrenovation');
        return selection && coveragesFormLogic() && baseStateLogic()
    }, [baseStateLogic, coveragesFormLogic, underNewConstructionType])

    const shouldShowConstructionToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo());
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const shouldShowEstimatedCompletionDateDropdown = useCallback(() => {
        const selection = (underNewConstructionType ===
            'yeswithstructuralrenovation' ||
        (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo()));
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                authHeader
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader]
    );


    const getNamedInsuredOwnThePropertyValue = useCallback(() => {
        switch (coveragesForm) {
            case 'ho3': 
            case 'ho5': 
            case 'ho6': 
                _.set(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.isNamedInsuredOwnTheProperty', true)
                return true
            case 'ho4': 
                _.set(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.isNamedInsuredOwnTheProperty', false)
                return false
            default: ''
        }
    },[coveragesForm])
    const getExternalAgencyOptions = async () => {
        if (isExternalUser) {
            const agencies = await WniAccountService.getAgencyMatchData('', 6, authHeader).then((agencyResponse) => {
                return agencyResponse.map((selectRow) => {
                    const agencyDisplay = `${selectRow.code} - `
                        + `${selectRow.description ? `${selectRow.description}-` : null} `
                        + `${selectRow.city_Ext ? selectRow.city_Ext : null}, `
                        + `${selectRow.state_Ext ? selectRow.state_Ext : null}`;
                    return {
                        code: selectRow.code,
                        name: agencyDisplay,
                        publicID: selectRow.publicID
                    };
                });
            });
            return agencies;
        }
        return null;
    };

    const updateLicensedAgent = (value) => {
        // updateWizardPageData({ [WizardConstants.licensedAgentValue]: value });
        _.set(submissionVM, 'baseData.producerOrLicensedAgent_Ext', value)
        // updateSubmissionVM(submissionVM)
    };

    const updateServicingAgent = (value) => {
        // updateWizardPageData({ [WizardConstants.servicingAgentValue]: value });
        _.set(submissionVM, 'baseData.servicingAgent_Ext', value)
        // updateSubmissionVM(submissionVM)
    };

    const getLicensedAgentOptions = async (newProducerCodePublicID) => {
        const res = await WniAccountService
            .getLicensedAgentData(newProducerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        return options;
    };

    const getAgentStatus = async () => {
        if (producerCodePublicID) {
            const res = await WniAccountService.getAgentStatus(producerCodePublicID, userPublicID, authHeader);
            return res
        }
        return '';
    };

    const getServicingAgentOptions = async (newProducerCodePublicID) => {
        const res = await WniAccountService.getServicingAgentData(newProducerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        return options;
    };

    const updateExternalAgency = async (value) => {
        const changedName = value.name
        const changedCode = value.code
        let changedProducerCodePublicID = ''
        if(isExternalUser) {
            changedProducerCodePublicID = value.code;
        }else{
            changedProducerCodePublicID = value.publicID
        }
        const servicingRes = await getServicingAgentOptions(changedProducerCodePublicID);
        const licensedAgentsRes = await getLicensedAgentOptions(changedProducerCodePublicID);
        const newWizardPageData = {
            [WizardConstants.servicingAgentOptions]: servicingRes,
            [WizardConstants.licensedAgentOptions]: licensedAgentsRes,
        };
        _.set(submissionVM, 'baseData.producerCodePublicID_Ext', changedProducerCodePublicID)
        _.set(submissionVM, 'baseData.producerDisplay_Ext', changedName)
        _.set(submissionVM, 'baseData.producerCode_Ext', changedCode)
        updateWizardPageData(newWizardPageData);
        // updateSubmissionVM(submissionVM)
        // updateExternalAgencyValue(value);
    };


    /**
     * When initializing updateWizardPageData,
     * it needs to be integrated in a method, otherwise data coverage will occur
     */
    const getAgentInfo = async () => {
        let agencyRes;
        let servicingRes;
        let agentStatusRes;
        let licensedAgentsRes;
        const newWizardPageData = {};

        if (!agentStatus) {
            agentStatusRes = await getAgentStatus();
            _.set(newWizardPageData, WizardConstants.agentStatus, agentStatusRes);
        }
        

        if (!externalAgencyOptions && isExternalUser) {
            agencyRes = await getExternalAgencyOptions();
            _.set(newWizardPageData, WizardConstants.externalAgencyOptions, agencyRes);
               
        }
        if (producerCodePublicID) {
            if (!servicingAgentOptions) {
                servicingRes = await getServicingAgentOptions(producerCodePublicID);
                _.set(newWizardPageData, WizardConstants.servicingAgentOptions, servicingRes);
            }
            if (!licensedAgentOptions) {
                licensedAgentsRes = await getLicensedAgentOptions(producerCodePublicID);
                _.set(newWizardPageData, WizardConstants.licensedAgentOptions, licensedAgentsRes);
            }
            
        }


        // updateSubmissionVM(submissionVM)
        updateWizardPageData(newWizardPageData);
    };
    
    const refreshHouseholdResidentsTable = useCallback((datas) => {
        const pni = _.cloneDeep(primaryNamedInsured);
        _.set(pni, 'relationshipToInsured_Ext', 'PRIMARYNAMEDINSURED');
        let initData = [];
        initData.push(pni);
        initData = initData.concat(datas);
        updateHouseholdResidentsTableData(initData)
    }, [primaryNamedInsured]);

    useEffect(() => {
        getAgentInfo();
        refreshHouseholdResidentsTable(householdResidents);
        if (householdInfoPageOnInit) {
            householdInfoPageOnInit();
        }
    }, []);

    const updateLicensedAgentOptions = (value) => {
        updateWizardPageData({ [WizardConstants.licensedAgentOptions]: value });
    };

    const updateServicingAgentOptions = (value) => {
        updateWizardPageData({ [WizardConstants.servicingAgentOptions]: value });

    };


    const renderRelationshipToInsured = useCallback((item, index, property) => {
        const relationshipToInsuredValue = _.get(item, property.path);
        let value = '';
        if (relationshipToInsuredValue === 'PRIMARYNAMEDINSURED') {
            return 'Primary Named Insured';
        }
        if (!_.isNil(relationshipToInsuredValue)) {
            const interestTypeKey = _.find(relationshipToInsuredListsAvailableValues,
                (ttx) => ttx.code === relationshipToInsuredValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [relationshipToInsuredListsAvailableValues, translator]);

    const renderAdditionalNamedInsured = useCallback((item, index, property) => {
        const additionalNamedInsuredValue = _.get(item, property.path);
        let value = 'No';
        if (additionalNamedInsuredValue === 'PolicyAddlNamedInsured') {
            value = 'Yes';
        }
        return value;
    }, []);

    const preQualQuestionSets = _.get(submissionVM.value, 'lobData.homeowners.preQualQuestionSets', [])
    const policyInfoQuestions = preQualQuestionSets
        .find((questionSet) => questionSet.code === 'HOPPolicyInfoUWQuestions_Ext')

    const questionSetFilter = WniQuestionSetUtil.getQSFilterForHoHouseholdInfo(isFeb19EntityChangeVerExt, isApr16EntityChangeVerExt, policyInfoQuestions)
    const questionSetMapper = WniQuestionSetUtil.getQSMapperForHoHousehold(baseState);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            // updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );
    // const getFrontendRequiredValiationErrors = useCallback(() => {
    //     if (!isRequiredForIssuance) {
    //         return [];
    //     } 
    //     const newRequiredForIssuranceFields = invalidFields.filter((item) =>{
    //         return requiredForIssuranceAllFields.includes(item);
    //     });
    //     return newRequiredForIssuranceFields.map((item) => {
    //         return {
    //             type: 'error',
    //             reason: `Below required fields for issuance are missing: "${translator(messages[item])}"`
    //         };
    //     });
    // }, [submissionVM, invalidFields]);

    // const handleValidation = useCallback(
    //     () => {
    //         const frontendRequiredValiationErrors = getFrontendRequiredValiationErrors();
    //         updateRequiredForIssuanceFields(frontendRequiredValiationErrors);
    //         updateShowErrors(true);
    //         WindowUtil.scrollToInvalidField(invalidFields);
    //         return false;
    //     },
    //     [updateShowErrors, invalidFields]
    // );


    // // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    // const onPageNext = useCallback(async () => {
    //     setLoadingMask(true);
    //     const hoHouseholdInfoData = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext')
    //     const questionSetData = _.get(submissionVM.value, 'lobData.homeowners.preQualQuestionSets')
    //     const quoteBaseData = _.get(submissionVM.value, 'baseData')
    //     // const res = await HOHouseholdInfoService
    //     //     .saveHoHouseholdInfoData(
    //     const res = await saveHoHouseholdInfo(
    //         quoteID, 
    //         sessionUUID,
    //         hoHouseholdInfoData,
    //         questionSetData,
    //         quoteBaseData,
    //         authHeader);
    //     const newSubmissionVM = viewModelService.clone(submissionVM);
    //     _.set(newSubmissionVM, 'baseData.value', res.baseData)
    //     _.set(newSubmissionVM, 'lobData.value', res.lobData)
    //     updateSubmissionVM(newSubmissionVM);
    //     setLoadingMask(false);
    //     return newSubmissionVM
    // }, [setLoadingMask,
    //     submissionVM, 
    //     quoteID, 
    //     sessionUUID, 
    //     authHeader, 
    //     viewModelService,
    //     updateSubmissionVM]);

    const overrideProps = {
        '@all': {
            readOnly: true
        },
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            isReadOnly: true,
            labelPosition: 'left',
            showRequired: true
        },
        // dynamicInlineNotificationContainer: {
        //     visible: requiredForIssuanceFields.length > 0,
        //     scrollToIssues: true,
        //     issueRenderFn: DomRenderUtil.issueRenderFn,
        //     validationIssues: IssuanceValidationUtil.getIssuesMap(requiredForIssuanceFields),
        // },
        coverageFormDropdown: {
            availableValues: getCoverageFormValue(),
        },
        agencyServiceContent: {
            externalAgencyOptions,
            licensedAgentOptions,
            servicingAgentOptions,
            showErrors,
            updateExternalAgency,
            externalAgencyValue: {
                name: producerCodeDisplayName,
                code: producerCode,
                publicID: producerCodePublicID
            },
            // externalAgencyValue: producerCodePublicID,
            updateLicensedAgent,
            licensedAgentValue: licensedAgentPublicID,
            servicingAgentValue: servicingAgentPublicID,
            updateServicingAgent,
            updateLicensedAgentOptions,
            updateServicingAgentOptions,
            onValidate
        },
        primaryNamedInsured: {
            value: getPrimaryNamedInsuredAndDisplay(),
        },
        describeOccupancyDropdown: {
            availableValues: getOccupancyValue(),
        },
        residenceTypeDropdown: {
            availableValues: getResidenceTypeDropdown(),
        },
        residenceLocation: {
            value: getResidenceLocaltionAndDisplay(),
        },
        residenceQuestionDropdown: {
            visible: shouldShowResidenceQuestionDropdown(),
            required: isRequiredForIssuance
        },
        generalContractorToggle: {
            visible: shouldShowGeneralContractorToggle(),
            required: isRequiredForIssuance
        },
        constructionToggle: {
            visible: shouldShowConstructionToggle(),
            required: isRequiredForIssuance
        },
        // estimatedCompletionDateDropdown: {
        //     visible: shouldShowEstimatedCompletionDateDropdown(),
        // 
        //     required: isRequiredForIssuance
        // },
        namedInsuredOwnThePropertyToggle: {
            value: getNamedInsuredOwnThePropertyValue(),
        },
        isThisResidenceOccupiedSeasonallyToggle: {
            visible: (namedInsuredOccupancy !== 'fullTimeYearRound'),
            required: isRequiredForIssuance
        },
        householdResidentsTable: {
            data: householdResidentsTableData,
        },
        relationshipToInsuredColumn: {
            path: 'relationshipToInsured_Ext',
            renderCell: renderRelationshipToInsured,
        },
        additionalNamedInsuredColumn: {
            path: 'subType',
            renderCell: renderAdditionalNamedInsured,
        },
        primaryNamedInsuredEditIcon: {
            // onClick: openEditPrimaryNamedInsuredPopup,
            visible: 'false'
        },
        residenceLocationEditIcon: {
            visible: 'false'
        },
        provideThePreviousAddress: {
            visible: (hasLivedInLocationLess3Yrs ? 'true': 'false'),
            value: getPriorAddressAndDisplay(),
        },
        provideThePreviousAddressEditIcon: {
            visible: 'false'
        },
        effectiveDate: {
            // minDate: LocalDateUtil.today(),
            value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            showErrors
        },
        policyStateDropdown: {
            visible: isHouseholdInfoFirstEnter && isBaseStateInMutualState(),
        },
        policyStateInput: {
            visible: !(isHouseholdInfoFirstEnter && isBaseStateInMutualState()),
        },
        isAnyPortionOfThePropertyToggle: {
            defaultValue: false,
            value: isPropertyRentedToOthers,
        },
        totalNumberofHouseholdResidents: {
            required: true,
            minValue: _.get(householdResidentsTableData, 'length', 0)
        },
        addHouseholdResident: {
            disabled: true
        },
        policyUnderwritingQuestionSets: {
            isRequiredForIssuance: isRequiredForIssuance,
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            // onValidate,
            // getInvalidAnswerKeys: questionSetGetInvalidAnswerKeys,
        }
    };
    //---------------------
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            // validationissuescomponent: ValidationIssuesComponent,
            agencyServiceinfo: AgencyOfServiceComponent,
            // policyholderuwquestion: AQPolicyHolderUWQuestionComponent,
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

HOHouseholdInfoReadOnlyPage.propTypes = {
    ...wizardProps,
    saveHoHouseholdInfo: PropTypes.func,
    householdInfoPageOnInit: PropTypes.func,
    isOpenPNIPopupPageInit: PropTypes.bool,
    checkRequiredForIssuance: PropTypes.bool
};

HOHouseholdInfoReadOnlyPage.defaultProps = {
    saveHoHouseholdInfo: HOHouseholdInfoService.saveHoHouseholdInfoData,
    householdInfoPageOnInit: undefined,
    isOpenPNIPopupPageInit: false,
    checkRequiredForIssuance: true
};

export default HOHouseholdInfoReadOnlyPage;
