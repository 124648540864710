import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import _ from 'lodash';
import metadata from './CoverageFormSelection.metadata.json5';

const COVERAGE_FORM_MAPS = ['contractorsEquipPart', 'miscFloatersPart', 'miscFormsPart', 'transitPart'];

function CoverageFormSelection(props) {
    const { vm, writeValue, isReadOnly, syncCoverageForm } = props;

    const isVisible = (path) => {
        return _.get(vm, `${path}Visible.value`);
    };

    const isDisabled = (path) => {
        return !_.get(vm, `${path}.available.value`);
    };

    const findVisibleCoverageForm = _.filter(COVERAGE_FORM_MAPS, (item) => isVisible(item));
    const isVisibleCoverageForm = !_.isEmpty(findVisibleCoverageForm);

    if (!vm || !isVisibleCoverageForm) {
        return null;
    };

    const generateCoverageFormVisible = () => {
        const overrides = COVERAGE_FORM_MAPS.map((item) => {
            return {
                [item]: {
                    visible: isVisible(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        scheduledEquipmentForm: {
            disabled: isDisabled('scheduledEquipmentForm'),
            onValueChange: syncCoverageForm
        },
        blanketEquipmentForm: {
            disabled: isDisabled('blanketEquipmentForm'),
            onValueChange: syncCoverageForm
        },
        smallToolsFloater: {
            disabled: isDisabled('smallToolsFloater'),
            onValueChange: syncCoverageForm
        },
        ...generateCoverageFormVisible()
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vm,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {},
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vm}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValueChange={writeValue}
            {...resolvers}
        />
    );
}

export default CoverageFormSelection;
