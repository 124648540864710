
import React from 'react';


import CUPolicyDetailsChangePage  from './CUPolicyDetailsChangePage';

function CUPolicyDetailsChangeReadOnlyPage(props) {

    return (
        <CUPolicyDetailsChangePage 
        {...props} 
        isReadOnly/>
    );

}

CUPolicyDetailsChangeReadOnlyPage.propTypes = CUPolicyDetailsChangePage.propTypes;
CUPolicyDetailsChangeReadOnlyPage.defaultProps = CUPolicyDetailsChangePage.defaultProps;

export default CUPolicyDetailsChangeReadOnlyPage;