import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniRewrite'), method, data, additionalHeaders);
}
export default class WniSubmissionService {
    static getRewriteSummary(params, additionalHeaders) {
        return processSubmission('getRewriteSummary', params, additionalHeaders);
    }

    static findJobByJobNumber(params, additionalHeaders) {
        return processSubmission('findJobByJobNumber', params, additionalHeaders);
    }

    static getDocumentsForRewriteGroupByType(params, additionalHeaders) {
        return processSubmission('getDocumentsForRewriteGroupByType', params, additionalHeaders);
    }

    static getPortalAttachmentsForRewrite(params, additionalHeaders) {
        return processSubmission('getPortalAttachmentsForRewrite', params, additionalHeaders);
    }

    static addRecentlyViewedRewrite(jobNumber, additionalHeaders) {
        return processSubmission('addRecentlyViewedRewrite', [jobNumber], additionalHeaders);
    }
}
