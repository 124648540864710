import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { useTranslator } from '@jutro/locale';
import { Redirect, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Loade } from '@jutro/components';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/prefer-default-export
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages, PolicyChange } from 'gw-capability-policychange-common-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { useWniModal } from 'wni-components-platform-react';

import {
    QuoteUtil, PolicyChangeUtil, ServiceErrorUtil, WizardUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardPageHeaderExtensionComponent, WizardSidebarLink } from 'wni-capability-gateway-react';
import { MultiModeWizard } from 'wni-portals-wizard-react';
import { WniEndorsementService, WniCommonChangeService } from 'wni-capability-policychange';
import { WniPolicyService } from 'wni-capability-gateway';
import { WniLoadSaveService } from 'wni-capability-quoteandbind';
import {
    PolicyChangeCommonMessages as commonChangeMsg,
    WniPlatformMessages as customMessages,
 } from 'wni-platform-translations';
import messages from './PAPolicyChangeWizard.messages';
import styles from './PAPolicyChangeWizard.module.scss';

import wizardPolicyChangeStartConfig from './config/pa-policychange-wizard-start-policychange-config.json5';
import wizardConfig from './config/pa-policychange-wizard-config.json5';
import wizardReadOnlyConfig from './config/pa-policychange-wizard-readonly-config.json5';
import wizardStepToFieldMapping from './config/pa-policychange-wizard-step-to-field-mapping.json5';

function PAPolicyChangeWizard(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const { steps, title } = wizardConfig;
    const { steps: initialReadOnlySteps } = wizardReadOnlyConfig;
    const { steps: policyChangeStartSteps } = wizardPolicyChangeStartConfig;

    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    const [isClickCancel, setIsClickCancel] = useState(false);
    const [readOnlySteps, updateReadOnlySteps] = useState(initialReadOnlySteps);
    const [wizardErrorsAndWarnings, setWizardErrorsAndWarnings] = useState([]);
    // Is the Submission obtained by copying, updateWizardSnapshot
    // Whether the Submission has been copied, Shows that it has been successfully copied
    const viewModelService = useContext(ViewModelServiceContext);
    const isPageLoading = _.isNil(viewModelService);

    const { location, history } = props;
    const { authHeader, authUserData } = useAuthentication();

    const [accountNumber, updateAccountNumber] = useState('');

    // const { EndorsementService } = useDependencies('EndorsementService');
    const {
        EndorsementService,
        loadingMask: { setLoadingMask },
    } = useDependencies(['EndorsementService', 'loadingMask']);

    const [initialWizardPageData, setInitialWizardPageData] = useState(undefined);

    const isReadOnly = _.get(location.state, 'isReadOnly', false);
    const policyNumber = _.get(location, 'state.policyNumber', null);
    const jobNumber = _.get(location, 'state.jobNumber', null);
    // const landingPageID = _.get(location, 'state.landingPageID');

    const hasCreatePolicyChangePermission = _.get(authUserData, 'permissionMap_Ext.createpolchange');
    const hasEditPolicyChangePermission = _.get(authUserData, 'permissionMap_Ext.editpolchange');

    // const quoteStatus = _.get(initialSubmission, 'value.status');
    const skipCompletedSteps = PolicyChangeUtil.isStatusQuotedOrRated(initialSubmission);

    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const loadPolicyChangePeriod = useCallback(() => {
        if (jobNumber) {
            return WniEndorsementService.loadByJobNumberEndorsement(jobNumber, authHeader);
        }
        return EndorsementService.loadEndorsement(policyNumber, authHeader);
    }, [EndorsementService, authHeader, jobNumber, policyNumber]);

    const getStartPolicyChangeWarningMessage = useCallback(() => {
        if (!jobNumber) {
            return WniEndorsementService
                .getStartPolicyChangeWarningMessage(policyNumber, authHeader);
        }
        return WniEndorsementService
            .getStartPolicyChangeWarningMessageByJobNumber(jobNumber, authHeader);
    }, [policyNumber, jobNumber, authHeader]);

    const errorModalBox = (errorMessage) => {
        modalApi.showAlert(errorMessage).then(() => {
            history.push(`/contactAgent/${location.state.policyNumber}`);
        });
    };

    const onInit = useCallback(async () => {
        setLoadingMask(true);
        try {
            if (!hasCreatePolicyChangePermission && jobNumber === null) {
                throw Error('Insufficient permissions to initiate policy change.');
            }

            const warningMessages = await getStartPolicyChangeWarningMessage();
            if (!_.isEmpty(warningMessages)) {
                setWizardErrorsAndWarnings(warningMessages.map((message) => {
                    return {
                        type: 'warning',
                        reason: message
                    };
                }));
            }
            const policyChangeData = await loadPolicyChangePeriod();
            const policyChangeDataVM = viewModelService.create(
                policyChangeData,
                'pc',
                'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
            );
            setInitialSubmission(policyChangeDataVM);

            const newAccountNumber = _.get(policyChangeData, 'baseData.accountNumber');
            updateAccountNumber(newAccountNumber);
            
            //
            let initPageData = {};
            if (!_.isNil(jobNumber)) {
                const { sessionUUID } = policyChangeData;
                const wizardExitData = await WniCommonChangeService.loadWizardExitData(jobNumber, sessionUUID, authHeader);
                initPageData = WizardUtil.getInitialWizardPageData(wizardExitData);

                // initPageData[WizardConstants.landingPage] = landingPageID,
            }
            setInitialWizardPageData(initPageData);
        } catch (e) {
            const errorMsg = ServiceErrorUtil.getErrorMessage(e, commonMessages.somethingWentWrong);
            errorModalBox({
                title: messages.changingPaymentMethod,
                message: errorMsg,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: platformMessages.ok
            });
        }
        setLoadingMask(false);
        // setIsLoading(false);
    }, [isPageLoading, policyNumber, jobNumber]);

    useEffect(() => {
        if (isPageLoading) { // the whole page gets refreshed, meaning viewModelService is still undefined now
            return;
        }
        if (!location.state || !policyNumber) {
            history.push('/');
            return;
        }
        onInit();
    },
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPageLoading, policyNumber, jobNumber]);

    const onLeaveWizard = useCallback(async ({
        wizardData: submissionVM,
        wizardPageData,
        currentStepIndex,
    }) => {
        // const {
        //     quoteID: quoteIDParam, sessionUUID,
        // } = submissionVM.value;
        // setIsLoading(true);

        // const wizardExitData = WizardUtil.getQuoteWizardExitData(wizardPageData, { currentStepIndex });
        // await WniCommonQuoteService.saveWizardExitData(quoteIDParam, sessionUUID, wizardExitData, authHeader);
        return true;
    }, []);

    const handleOnCancel = useCallback(({ wizardData: submissionVM }) => {
        const submissionJobID = _.get(submissionVM.value, 'jobID');
        WizardPageJumpUtil.goToChangeDetailsSummaryPage(history, {
            jobID: submissionJobID,
        });
    }, [history]);

    const handleReadOnlyCancel = useCallback(({ wizardData: submissionVM }) => {
        const { jobID } = submissionVM.value;

        if (_.isNil(jobID)) {
            WizardPageJumpUtil.goToPoliciesPage(history);
        } else {
            WizardPageJumpUtil.goToChangeDetailsSummaryPage(history, { jobID });
        }
        return true;
    }, [history]);

    const handlePolicyStartCancel = useCallback(({ wizardData: submissionVM }) => {
        WizardPageJumpUtil.goToPoliciesPage(history);
    }, [history]);

    const handleError = useCallback((error) => {
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        let redirectPath = `/contactAgent/${jobIDFromLocation}`;
        if (!_.isEmpty(jobIDFromWizard)) {
            redirectPath = `/change/${jobIDFromWizard}/summary`;
        }
        const state = {
            pathname: redirectPath,
            state: error,
            accountNumber,
        };

        const errorMsg = ServiceErrorUtil.getErrorMessage(error, messages.anErrorOccurred);
        return modalApi.showAlert({
            title: messages.anErrorOccurred,
            message: errorMsg,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: platformMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        });
    }, [location, accountNumber]);

    const getPageHeaderExtensionComponent = () => {
        // const { jobID } = initialSubmission.value;
        return (pageHeaderExtensionProps) => {
            const { jobID } = pageHeaderExtensionProps;
            return (
                <WizardPageHeaderExtensionComponent
                    jobNumber={jobID}
                />
            );
        };
    };

    const handleCopySubmission = () => {
        const copySubmissionID = _.get(initialSubmission.value, 'policyNumber');
        return WniPolicyService.copySubmission([copySubmissionID], authHeader);
    };

    // const getWizardSideBarExtensionProps = useCallback(() => {
    //     const jobType = _.get(initialSubmission.value, 'baseData.jobType');
    //     return {
    //         jobType: jobType,
    //         copySubmission: handleCopySubmission,
    //     };
    // }, [initialSubmission]);

    const getInitialMode = () => {
        if (isReadOnly || !hasEditPolicyChangePermission) {
            return 'readOnly';
        }
        if (!jobNumber && policyNumber) {
            return 'policyChangeStart';
        }
        return 'default';
    };

    const policyChangeStartPageHeaderFormatter = (formatterParams) => {
        const lobWithQuoteNumber = translator(messages.personalAutoPolicyChange, {
            policyNumber: policyNumber
        });
        return {
            productWithQuoteNumber: lobWithQuoteNumber,
            jobStatusInfo: '',
            isDisplayPolicyType: true,
        };
    };

    const policyChangePageHeaderFormatter = ({
        wizardData: wizardSubmission,
    }) => {
        const lobWithQuoteNumber = translator(messages.personalAutoPolicyChange, {
            policyNumber: policyNumber
        });
        const endorsementStatusInfo = QuoteUtil
            .getEndorsementSelectedTypeAndBranch(wizardSubmission);
        return {
            productWithQuoteNumber: lobWithQuoteNumber,
            jobStatusInfo: endorsementStatusInfo,
            isDisplayPolicyType: true,
            isDisplayJobType: true,
        };
    };

    const onWizardModeChange = useCallback(({
        wizardData = {},
        nextWizardMode,
    } = {}) => {
        if (nextWizardMode === 'readOnly') {
            const isForNamedNonOwner = _.get(wizardData.value, 'lobData.personalAuto.isForNamedNonOwner_Ext', false);
            if (isForNamedNonOwner) {
                const newReadOnlySteps = WizardUtil.filterPANamedNonOwnerWizardStepsForReadOnlyMode(
                    initialReadOnlySteps, 'PCPADriverCoveragesPage'
                );
                updateReadOnlySteps(newReadOnlySteps);
            }
        }
    }, [updateReadOnlySteps, initialReadOnlySteps]);

    // ========================================================
    const defaultOnInitialization = useCallback(({
        updateFurthestPageVisited,
        wizardPageData,
        updateWizardReadOnly,
        stopSkipping,
    }) => {
        const isUwLocked = wizardPageData[WizardConstants.isUwLocked];
        if (isUwLocked) {
            updateWizardReadOnly(true);
            return;
        }
        const landingPage = wizardPageData[WizardConstants.landingPage];
        if (landingPage) {
            stopSkipping();
            updateFurthestPageVisited('PCPAIncidentsAndReports');
        }
    }, []);

    const readonlyOnInitialization = useCallback(({ updateFurthestPageVisited }) => {
        updateFurthestPageVisited('PCPAQuotePage');
    }, []);

    // =======================================================
    // if (isLoading) {
    //     return <Loader loaded={!isLoading} />;
    // }

    if (!initialSubmission || !initialWizardPageData) {
        return null;
    }

    return (
        <div className={styles.paPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <MultiModeWizard
                    initialData={initialSubmission}
                    modeToWizardPropsMap={
                        {
                            default: {
                                initialSteps: steps,
                                wizardTitle: title,
                                skipCompletedSteps: true,
                                wizardStepToFieldMapping,
                                onCancel: handleOnCancel,
                                onPreviousModalProps: {
                                    title: commonMessages.wantToJump,
                                    message: commonMessages.wantToJumpMessage,
                                    messageProps: {
                                        ok: platformMessages.yes,
                                        close: platformMessages.no
                                    }
                                },
                                wizardPageHeaderExtension: getPageHeaderExtensionComponent(),
                                wizardSidebarExtension: WizardSidebarLink,
                                // wizardSideBarExtensionProps: getWizardSideBarExtensionProps(),
                                wizardServiceCallbacks: {
                                    retrieveSubmission: WniLoadSaveService.retrieveSubmission,
                                    copySubmission: handleCopySubmission,
                                },
                                wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
                                wizardDataComparator: WizardUtil.isWizardDataEqualForPA,
                                showWizardPromptMessage: true,
                                wizardPageHeaderFormatter: policyChangePageHeaderFormatter,
                                initialWizardPageData,
                                onWizardInitialization: defaultOnInitialization,
                            },
                            readOnly: {
                                initialSteps: readOnlySteps,
                                wizardTitle: title,
                                onCancel: handleReadOnlyCancel,
                                wizardStepToFieldMapping,
                                onWizardInitialization: readonlyOnInitialization,
                                wizardPageHeaderFormatter: policyChangePageHeaderFormatter,
                            },
                            policyChangeStart: {
                                initialSteps: policyChangeStartSteps,
                                wizardTitle: title,
                                onCancel: handlePolicyStartCancel,
                                wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
                                wizardPageHeaderFormatter: policyChangeStartPageHeaderFormatter,
                            },
                        }
                    }
                    initialMode={getInitialMode()}
                    onWizardModeChange={onWizardModeChange}
                />
            </ErrorBoundary>
        </div>
    );
}

PAPolicyChangeWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            jobNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withRouter(PAPolicyChangeWizard);
