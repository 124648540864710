
import React, {
    useEffect,
} from 'react';


import {
    useHistory,
    useParams,
    useLocation,
} from "react-router-dom";

import { InternalRouteConstants } from 'wni-portals-config-js';

const { URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT } = InternalRouteConstants;

const PREFIX_LENGTH = URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT.length;

function SkipWizardLeavePromptComponent() {

    const history = useHistory();
    const location = useLocation();

    const {
        pathname,
    } = location;
    

    useEffect(() => {
        const targetPath = pathname.slice(PREFIX_LENGTH);
        history.push(targetPath);
    }, []);


    return null;

};

export default SkipWizardLeavePromptComponent;
