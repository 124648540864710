import _ from 'lodash';
import { WniProductsUtil, WniAccountsUtil } from 'wni-portals-util-js';

const FNOL_LOB_GL = 'GeneralLiability';
const FNOL_LOB_CP = 'CommercialProperty';
const FNOL_LOB_IM = 'InlandMarine';
const FNOL_LOB_CR = 'Crime';
const RT_LOB_NAME = 'roadTrail';
const PA_LOB_NAME = 'personalAuto';
const WT_LOB_NAME = 'watercraft';
const WC_LOB_NAME = 'workersComp';
const CA_LOB_NAME = 'commercialAuto';
const GL_LOB_NAME = 'generalLiability';
const IM_LOB_NAME = 'inlandMarine';
const CPP_LOB_NAME = 'commercialPackage';

export default {
    ...WniProductsUtil,
    ...WniAccountsUtil,
    FNOL_LOB_GL,
    FNOL_LOB_CP,
    FNOL_LOB_IM,
    FNOL_LOB_CR,
    RT_LOB_NAME,
    PA_LOB_NAME,
    WT_LOB_NAME,
    WC_LOB_NAME,
    CA_LOB_NAME,
    GL_LOB_NAME,
    IM_LOB_NAME,
    CPP_LOB_NAME
};
