import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    OOSUtil,
    QuoteUtil,
    WniClausesUtil,
    WindowUtil,
    PolicyChangeUtil,
    CoveragesUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    DocumentsUtil,
    WniDateUtil,
    DTOUtil
} from 'wni-portals-util-js';
import { WizardConstants} from 'wni-portals-config-js';
import { ComponentRenderHelper as renderHelper, ModalDialogHelper } from 'wni-portals-util-react';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, PAPolicyChangeQuoteComponent, useWniModal } from 'wni-components-platform-react';
import { PAQuoteUIHelper } from 'gw-capability-quoteandbind-pa-react';
import { SuccessNotification } from 'gw-components-platform-react';
import {
    WniSxsChangeService,
    WniPAPolicyChangeService,
    WniCustomEndorsementService,
} from 'wni-capability-policychange';
import { PolicyDiffService } from 'gw-capability-policyjob';
import {
    SideBySidePeriodsComponent,
    ReferToUnderwriterComponent,
    PolicyChangeSideBySidePeriodInfo,
    ChangeSummaryComponent,
    DocumentCheckedComponent,
} from 'wni-capability-gateway-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as currentMessages } from 'wni-platform-translations';
import styles from './PAQuoteChangePage.module.scss';
import metadata from './PAQuoteChangePage.metadata.json5';

const {
    generateColumnData,
    generateTableData,
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = PAQuoteUIHelper;

const SXS_LOB_NAME = 'personalAuto';

function PAQuoteChangePage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings = [],
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const { isLoggedIn, authHeader } = useAuthentication();

    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('PAQuoteChangePage');
    const [showErrors, updateShowErrors] = useState(false);

    const [allDrivers, updateAllDrivers] = useState();
    const [allVehicles, updateAllVehicles] = useState();
    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [isShowMulti, updateShowMulti] = useState(false);
    const [hasBlockingIssue, updateHasBlockingIssue] = useState(false);
    // const [policyDiffData, updatePolicyDiffData] = useState([]);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);

    const {
        jobID,
        totalCost: {
            amount: totalCostAmount,
        } = {},
        transactionCost: {
            amount: transactionCostAmount,
        } = {},
        baseData: {
            effectiveDate,
            periodStartDate,
            periodEndDate,
            isForNamedNonOwner = false, // TODO
            selectedVersion_Ext: selectedVersion,
        },
        lobData: {
            [SXS_LOB_NAME]: {
                coverables: {
                    vehicles = [],
                }
            }
        },
        previousTotalCost,
        transactionCost = {},
        totalCost,
        oossliceDates_Ext: oossliceDates,
        status: jobStatus,
    } = submissionVM.value;

    const {
        [WizardConstants.policyDiffData]: policyDiffData = null,
        [WizardConstants.sideBySideData]: cachedSxsDataDTO,
        [WizardConstants.policyChangeDocumentsData]: policyChangeDocumentsData = [],
    } = wizardPageData;

    const modalHelper = ModalDialogHelper(modalApi);
    const oosErrorsAndWarnings = OOSUtil.getOOSSliceDatesWarning(
        OOSUtil.getOOSSliceDatesString(
            oossliceDates,
            effectiveDate
        ),
        translator
    );

    const updateSideBySideData = useCallback(async ({
        refreshSxsCache = true,
        clearUWIssues = false,
        updatePageData = true,
    } = {}) => {
        const retval = {};
        let sideBySideData = cachedSxsDataDTO;
        if (sideBySideData && !refreshSxsCache) {
            if (!sxsDataDTO && sideBySideData[SXS_LOB_NAME]) {
                updateSxsDataDTO(sideBySideData);
            }
            return retval;
        }
        
        sideBySideData = await WniSxsChangeService.retrieveSxsData(jobID, authHeader);
        // const sideBySideData = await WniSxsChangeService.retrieveSxsData(jobID, authHeader);
        const newErrorsAndWarnings = _.get(sideBySideData, 'errorsAndWarnings', {});
        if (clearUWIssues) {
            _.unset(newErrorsAndWarnings, 'underwritingIssues');
        }
        updateErrorsAndWarnings(newErrorsAndWarnings);
        if (sideBySideData[SXS_LOB_NAME]) {
            updateSxsDataDTO(sideBySideData);
            if (updatePageData) {
                updateWizardPageData({ [WizardConstants.sideBySideData]: sideBySideData });
            }
            retval[[WizardConstants.sideBySideData]] = sideBySideData;
        }
        return retval;
    }, [authHeader, cachedSxsDataDTO, jobID, sxsDataDTO, updateWizardPageData]);

    const updatePolicyDifferences = useCallback(async () => {
        const newPolicyDiffData = await PolicyDiffService.getPolicyDiffWithPrevious(jobID,
            authHeader);
        // updatePolicyDiffData(newPolicyDiffData);
        // updateWizardPageData({ [WizardConstants.policyDiffData]: newPolicyDiffData });
        return { [WizardConstants.policyDiffData]: newPolicyDiffData };
    }, [authHeader, jobID]);

    const updatePolicyChangeDocuments = useCallback(async () => {
        const documents = await WniCustomEndorsementService.getDocumentsForPolicyChangeGroupByType([jobID], authHeader);
        const policyChangeDocs = DocumentsUtil.getDocumentData(documents, DocumentsUtil.POLICY_CHANGE);
        return {
            [WizardConstants.policyChangeDocumentsData]: policyChangeDocs,
        };
    }, []);

    const withServiceInProgressUpdated = async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        } finally{
            updateServiceCallInProgress(false);
            // setLoadingMask(false);
        }
    };

    // const createNewVersion = async () => {
    //     submissionVM.value = await WniSxsChangeService.createNewSideBySideVersion(jobID, authHeader);
    //     updateSideBySideData();
    // };

    const handlePrint = () => {
        window.print();
    };

    const updateWizardData = useCallback((newSubmissionVM) => {
        updateWizardSnapshot(newSubmissionVM);
    }, [updateSubmissionVM]);

    useEffect(() => {
        WindowUtil.scrollToTop();
        // to be updated
        withServiceInProgressUpdated(async () => {
            if (policyDiffData) {
                return;
            }
            const newPolicyDiffData = await updatePolicyDifferences();
            const newPolicyChangeDocData = await updatePolicyChangeDocuments();
            updateWizardPageData({
                ...newPolicyDiffData,
                ...newPolicyChangeDocData,
            });
        });
    }, []);

    useEffect(() => {
        const setDriverNames = () => {
            const drivers = _.get(
                submissionVM.value,
                `lobData.${SXS_LOB_NAME}.coverables.drivers`,
                []
            );
            const driverNames = drivers.map((driver) => driver.displayName);
            updateAllDrivers(driverNames.join());
        };

        const setVehicleNames = () => {
            const vehicleNames = vehicles.map((vehicle) => vehicle.displayName);
            updateAllVehicles(vehicleNames.join());
        };

        if (isLoggedIn) {
            setDriverNames();
            setVehicleNames();
        }
    }, [isLoggedIn, submissionVM, updateWizardData, vehicles]);

    const onReferToUnderwriter = async (noteForUW) => {
        withServiceInProgressUpdated(async () => {
            const jobResponse = await WniPAPolicyChangeService.referToUnderwriter(jobID,
                noteForUW, authHeader);

            // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
            const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
            if (!_.isEmpty(referToUWIssues)) {
                updateExtraBlockingSubmitIssues(referToUWIssues);
                WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
            } else if (!_.isEmpty(jobResponse)) {
                // history.push(`/change/${jobID}/summary`);
                updateWizardReadOnly(true);
            }
        });
    };

    const showMultiVersions = async () => {
        withServiceInProgressUpdated(async () => {
            const canSxs = await WniSxsChangeService.canSideBySide(jobID, authHeader);
            if (canSxs) {
                submissionVM.value = await WniSxsChangeService.initiateSideBySide(
                    jobID, authHeader
                );
                updateWizardData(submissionVM);
            }

            await updateSideBySideData({
                refreshSxsCache: false,
                clearUWIssues: true,
            });
            updateShowMulti(true);
        });
    };

    const closeMultiVersions = () => {
        updateShowMulti(false);
    };

    const onAddSideBySideVersion = async () => {
        withServiceInProgressUpdated(async () => {
            submissionVM.value = await WniSxsChangeService.addSxsVersion(jobID, authHeader);
            await updateSideBySideData();

            updateWizardData(submissionVM);
        });
    };

    const onSelectAndQuoteVersion = useCallback(async (sxsPeriodPublicID) => {
        if (!isComponentValid) {
            updateShowErrors(true);
            WindowUtil.scrollTo(invalidFields);
            return;
        }
        withServiceInProgressUpdated(async () => {
            submissionVM.value = await WniSxsChangeService.selectAndQuoteSxsVersion(jobID,
                sxsPeriodPublicID, authHeader);

            const newSxsData = await updateSideBySideData({ updatePageData: false });
            const newPolicyDiffData = await updatePolicyDifferences();
            updateWizardPageData({
                ...newSxsData,
                ...newPolicyDiffData,
            });

            closeMultiVersions();
            updateWizardData(submissionVM);
        });
    }, [authHeader, invalidFields, isComponentValid, jobID, submissionVM,
        updatePolicyDifferences, updateSideBySideData, updateWizardData,
        updateWizardPageData, withServiceInProgressUpdated]);

    const onRemoveSideBySideVersion = async (sxsPeriodPublicID) => {
        modalApi.showConfirm({
            title: currentMessages.deleteSideBySideVersionTitle,
            message: currentMessages.deleteSideBySideVersionMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: currentMessages.deleteSideBySidePairConfirm,
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            withServiceInProgressUpdated(async () => {
                submissionVM.value = await WniSxsChangeService.removeSxsVersion(jobID,
                    sxsPeriodPublicID, authHeader);
                await updateSideBySideData();
                updateWizardData(submissionVM);
            });
        }, _.noop);
    };

    const onUpdateCoverage = async (value, changedPath,
        lobOfferingPath, lobOfferingIndex) => {
        // LineCoveragesInputComponent::changeSubmission()

        // LineCoveragesInputComponent::syncCoverages()
        // LineCoveragesInputComponent::onClauseUpdate();
        ClausesUtil.setClauseValue(submissionVM, value, changedPath);
        const lobName = ClausesUtil.getLobNameFromPath(lobOfferingPath);
        // const clauseType = WniClausesUtil.getClauseTypeFromPath(changedPath);
        // const coveragesPath = `${lobOfferingPath}.coverages.${clauseType}`;
        // const clauses = _.get(submissionVM, `${coveragesPath}.value`);
        const offeringPeriodPublicID = _.get(submissionVM, `${lobOfferingPath}.publicID_Ext.value`);
        // const clausesToUpdate = ClausesUtil.structureClausesForServer(clauses, lobName, clauseType);
        const clausesToUpdate = CoveragesUtil.generateUpdatedCoveragesDTO({
            submissionVM,
            lobName,
            selectedVersionOfferingsIndex: lobOfferingIndex,
            structureClausesForServer: WniClausesUtil.structureClausesForServerWithoutScheduleItem,
        });

        const sxsPeriod = QuoteUtil.getSideBySidePeriod(_.get(sxsDataDTO, `${SXS_LOB_NAME}.periods`, []), offeringPeriodPublicID);
        const previousPeriodStatus = _.get(sxsPeriod, 'periodStatus');

        // the usage of withServiceInProgressUpdated here breaks isClauseLoading. Hmm.
        updateServiceCallInProgress(true);
        // withServiceInProgressUpdated(async () => {
        const response = await WniSxsChangeService.updateSxsCoverages(
            jobID, offeringPeriodPublicID, clausesToUpdate, authHeader
        );
        submissionVM.value = response;
        updateErrorsAndWarnings(_.get(response, 'errorsAndWarnings_Ext'));
        updateWizardData(submissionVM);

        if (previousPeriodStatus !== 'Draft') {
            await updateSideBySideData();
        }
        // else {
        //     const isRecommendedCovInvolved = QuoteCoveragesUtil.isRecommendedCoveragesInvolved(SXS_LOB_NAME,
        //         clausesToUpdate);
        //     if (isRecommendedCovInvolved) {
        //         await updateSideBySideData({
        //             sxsDataRetriever: WniSxsQuoteService.retrieveBasicSxsData,
        //             // Presever existing errors and warnings
        //             quoteErrorsAndWarnings: sxsDataDTO.errorsAndWarnings,
        //         });
        //     }
        // }
        // });
        updateServiceCallInProgress(false);
        // return newSubmissionVM;
    };

    // ================================================
    // from PAPolicySummaryChangePage
    const onPageNext = useCallback(async () => {
            // submissionVM.value = await WniPAPolicyChangeService.issue(submissionVM.jobID.value, authHeader);
            // return submissionVM;

            // Invokes WniGateWayPersonalAutoPolicyChangeHandler.issue()
            try {
                const issuanceResult = await WniPAPolicyChangeService.issue(submissionVM.jobID.value, true, authHeader);
                submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, issuanceResult);
    
                const newErrorsAndWarnings = _.get(issuanceResult, 'errorsAndWarnings_Ext');
                
                const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);
                if (hasValidationError) {
                    updateWizardSnapshot(submissionVM);
                    // stay on curent page
                    return false;
                }
                // go to the next page
                return submissionVM;
            } catch (e) {
                const errorMessage = ServiceErrorUtil.getErrorMessage(e);
                modalHelper.alert({ errorMessage });
            }
    }, [authHeader, submissionVM]);
    // ================================================
    const generateOverrides = useCallback(() => {
        const effectiveDateFormat = WniDateUtil.formatDateWithPattern(effectiveDate);
        const startPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodStartDate);
        const endPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodEndDate);

        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        const columnData = generateColumnData({
            submissionVM,
            sxsDataDTO,
            isClueCompare,
            isMvrCompare,
            callbackMap: {
                // onFinishQuote,
                // onSubmitQuote,
                // onReferToUnderwriter,
                // onContinueToIssue: async () => onContinueToIssue(onNext),
                // goToIncidentReports
                // onAddSideBySideVersion,
                onSelectAndQuoteVersion,
                onRemoveSideBySideVersion,
                onUpdateCoverage,
            }
        });
        const tableData = generateTableData(submissionVM, columnData, translator);

        const newErrorsAndWarnings = _.get(submissionVM, 'errorsAndWarnings_Ext.value');
        const validationIssues_all = ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings,
            [...oosErrorsAndWarnings, ...wizardErrorsAndWarnings, ...extraBlockingSubmitIssues]);
        // POI-55289: requirement requests hiding of all waring messages
        const validationIssues = validationIssues_all.filter((issue) => issue.type !== 'warning');
    
            
        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);

        // const underwritingIssues = _.get(newErrorsAndWarnings, 'underwritingIssues', []);
        const underwritingIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(newErrorsAndWarnings, 'underwritingIssues', []), selectedVersion
        );
        // const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(newErrorsAndWarnings);
        const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue({ underwritingIssues });

        updateHasBlockingIssue(hasBlockUWIssue || hasValidationError);

        const changeUtil = PolicyChangeQuoteUtil({
            intl, translator, totalCost, transactionCost, currentMessages
        });
        const premiumDiffMsg = changeUtil.getPremiumDiffMessage();
        const premiumDiffTitle = changeUtil.getPremiumDiffTitle();
        const DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES = [
            'PAEffectiveDateAfterPendingRate_Ext'
        ];
    
        const disableBtnWithIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
            underwritingIssues, DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES
        );
        return {
            '@field': {
                labelPosition: 'left'
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssues),
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator, (issueType) => {
                    return issueType === 'error' ? currentMessages.submitTheChange : undefined;
                }),
                typeFooterFormatter: getQuotePageErrorsAndWarningsFooter({
                    wizardSteps,
                    wizardStepToFieldMapping,
                    wizardJumpTo,
                    resolveButtonLabel: translator(currentMessages.resolveErrors),
                    isServiceCallInProgress,
                }),
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                // Refactoring Notes: should retrieve validation result for current slice only
                issueDistinguisher: true,
            },
            quoteUWIssues: {
                underwritingIssues,
                issueFilter: _.stubTrue, // show Non-Blocking UWI as well
                // headerObject: currentMessages.IssuesRequiringReferral,
            },
            referToUWBtn: {
                onReferToUnderwriter,
                isServiceCallInProgress,
                visible: hasBlockUWIssue,
                referToUWButtonEnabled: !disableBtnWithIneligibleUwIssue
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            },
            printBtn: {
                disabled: isServiceCallInProgress,
            },
            printBtnV2: {
                disabled: isServiceCallInProgress,
            },
            drivers: {
                value: allDrivers
            },
            vehicles: {
                value: allVehicles
            },
            // =====================
            policyChangeSummary: {
                validationIssues: premiumDiffMsg,
                typeTitleFormatter: premiumDiffTitle,
                previousTotalCost,
                transactionCost,
                totalCost,
                effectiveDateFormat,
                periodDateRange: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`,
                // transactionCostAmount,
                showPremiumChange: jobStatus === 'Quoted',
            },
            // effectiveDateId: {
            //     value: effectiveDateFormat
            // },
            // peroidDateRangeId: {
            //     value: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`
            // },
            // ==========================
            PAHeadId: {
                visible: !isShowMulti
            },
            policyChangeDetailId: {
                visible: !isShowMulti
            },
            paPolicyDiff: {
                policyDiffData,
                isServiceCallInProgress,
                vehicles,
            },
            // ============================
            documentcheckedList: {
                // borrowed from PAPolicySummaryChangePage, origiginal design by Adrian/Fengjuan
                // history: history,
                submission: submissionVM,
                renderData: [
                    {
                        data: policyChangeDocumentsData,
                    },
                    {
                        data: [],
                    }
                ],
            },
            // ============================
            multiVersionsId: {
                visible: isShowMulti
            },
            multiVersionBtn: {
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
                content: renderHelper.getButtonContent(translator(currentMessages.MultiVersions), isServiceCallInProgress),
            },
            backToDetailBtn: {
                disabled: isServiceCallInProgress,
            },
            addNewBtn: {
                // disabled: true,
                visible: PolicyChangeUtil.canAddSideBySideChangePeriod(_.get(sxsDataDTO, `${SXS_LOB_NAME}.periods`)),
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
                content: renderHelper.getButtonContent(translator(currentMessages.addNewVersion), isServiceCallInProgress),
            },
            sxsPeriodsInfoSection: {
                columnData,
                isServiceCallInProgress,
                showActionButtons: false,
                sxsPeriodInfo: PolicyChangeSideBySidePeriodInfo,
                readyForSxsRemovalCheckFn: PolicyChangeUtil.canRemoveSideBySideChangePeriod,
            },
            quoteTable: {
                columnData,
                tableData,
                // isEditable: false,
                onValidate,
                showErrors,
            },
        };
    },
    [
        submissionVM,
        isServiceCallInProgress,
        errorsAndWarnings, sxsDataDTO,
        translator,
        isShowMulti,
        policyDiffData,
        showErrors,
    ]);

    const renderQuotePage = useCallback(({ onNext }) => {
        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                // onStaleQuoteBranchCode: _.noop,
                onChangeSubmissionAndSync: onUpdateCoverage,
                // onChangeSubmission: updateWizardData,
                // onSyncCoverages: _.noop,
                onShowMultiVersions: showMultiVersions,
                onBackToDetail: closeMultiVersions,
                onCreateNewVersion: onAddSideBySideVersion,
                onPrint: handlePrint,
            },
            resolveComponentMap: {
                sxsquoteperiods: SideBySidePeriodsComponent,
                validationissuescomponent: ValidationIssuesComponent,
                quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
                notificationcomponent: SuccessNotification,
                policychangequotecomponent: PAPolicyChangeQuoteComponent,
                refertouw: ReferToUnderwriterComponent,
                changesummary: ChangeSummaryComponent,
                documentcheckedcomponent: DocumentCheckedComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, submissionVM, updateWizardData]); // sxsDataDTO

    return (
        <WizardPage
            disableNext={hasBlockingIssue}
            nextLabel={currentMessages.submitChange}
            onNext={onPageNext}
            // cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
        >
            {renderQuotePage}
        </WizardPage>
    );
}

PAQuoteChangePage.propTypes = wizardProps;
export default PAQuoteChangePage;
