import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('walWatercraftService'), method, data, additionalHeaders);
}

export default class WALWatercraftService {

    /**
     * Invokes WALWatercraftsHandler.updateWatercraft()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} watercraft
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateWatercraft(quoteID, sessionUUID, watercraft, authHeader = {}) {
        return processSubmission('updateWatercraft', [quoteID, sessionUUID, watercraft], authHeader);
    }

    /**
     * Invokes WALWatercraftsHandler.removeWatercraft()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Array} publicIDs
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeWatercraft(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('removeWatercraft', [quoteID, sessionUUID, publicIDs], authHeader);
    }

    static postOnChangeAction(requestData, quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('postOnChangeAction', [quoteID, sessionUUID, requestData], authHeader);
    }

    static viewEditWatercraft(publicID, jobID, sessionUUID, authHeader = {}) {
        return processSubmission('viewEditWatercraft', [jobID, sessionUUID, publicID], authHeader);
    }

    static isSplitWatercraft(quoteID, sessionUUID, watercraft, authHeader = {}) {
        return processSubmission('isSplitWatercraft', [quoteID, sessionUUID, watercraft], authHeader);
    }

    static onPageNext(requestData, authHeader = {}) {
        return processSubmission('onPageNext', [requestData.jobID, requestData.sessionUUID], authHeader);
    }

    static getAdditionalInterestType(quoteID, sessionUUID, publicID, contactType, authHeader) {
        return processSubmission('getAdditionalInterestType', [quoteID, sessionUUID, publicID, contactType], authHeader);
    }

    //
    /**
     * Invokes WALWatercraftsHandler.updateWatercraftAndRefreshCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} watercraft
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateWatercraftAndRefreshCoverages(quoteID, sessionUUID, watercraft, authHeader = {}) {
        return processSubmission('updateWatercraftAndRefreshCoverages', [quoteID, sessionUUID, watercraft], authHeader);
    }

}
