import React, { createContext, useCallback, useState } from 'react';
import MainModal from './MainModal/MainModal';

export const ModalContext = createContext();

const ModalProvider = ({ children, defaultProps = {} }) => {
    const [modalProps, setModalProps] = useState({ ...defaultProps });
    const [resolveReject, setResolveReject] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [resolve, reject] = resolveReject;

    const show = useCallback((initModalProps = {}) => {
        return new Promise((resolveFn, rejectFn) => {
            setIsOpen(true);
            setModalProps({ ...initModalProps });
            setResolveReject([resolveFn, rejectFn]);
        });
    }, []);

    const handleClose = () => {
        reject();
        setIsOpen(false);
        setResolveReject([]);
    };

    // const handleCancel = () => {
    //     if (reject) {
    //         reject();
    //         handleClose();
    //     }
    // };

    const handleConfirm = (res) => {
        if (resolve) {
            resolve(res);
            handleClose();
        }
    };

    return (
        <ModalContext.Provider value={{ show }}>
            {children}
            <MainModal
                isOpen={isOpen}
                modalProps={modalProps}
                onReject={handleClose}
                onResolve={handleConfirm}
            />
        </ModalContext.Provider>
    );
};

export default ModalProvider;
