import _ from 'lodash';
import { ConfigUtil } from "wni-portals-util-js";

const setANIDefaultObj = (contactCode, industryCode, policyAddress) => {
  const rowIdPath = `${contactCode}:${ConfigUtil.getUuid()}`;
  const res = {
      rowIdPath_Ext: rowIdPath,
      industryCode: {
          code: industryCode.code,
          classification: industryCode.classification
      }
  }
  if (contactCode === 'Company') {
      _.set(res, 'company.primaryAddress', policyAddress);
      _.set(res, 'contactCode_Ext', 'Company');
      _.set(res, 'company.isContactNameRequired_Ext', true);
      _.set(res, 'company.isProducerCodeRequired_Ext', false)
  }
  if (contactCode === 'Person') {
      _.set(res, 'person_Ext.primaryAddress', policyAddress);
      _.set(res, 'person_Ext.country_Ext', policyAddress.country);
      _.set(res, 'person_Ext.isContactNameRequired_Ext', false);
      _.set(res, 'person_Ext.isProducerCodeRequired_Ext', false)
      _.set(res, 'contactCode_Ext', 'Person');
  }
  return res
};

const setDBADefaultObj = (contactCode, currentPcr, pcrID) => {
  let pcrAddress;
  const aniContactCode = _.get(currentPcr, 'value.contactCode_Ext');
  const contactDenormPublicID = _.get(currentPcr, 'value.contactDenormPublicID_Ext') || _.get(currentPcr, 'value.publicID');
  if (aniContactCode === 'Company') {
      pcrAddress = _.get(currentPcr, 'value.company.primaryAddress')
  } else {
      pcrAddress = _.get(currentPcr, 'value.person_Ext.primaryAddress')
  }
  const rowIdPath = `${contactCode}:${ConfigUtil.getUuid()}`;
  const res = {
      rowIdPath: rowIdPath,
      contactDenormPublicID,
      pcrID,
      contact: {
        primaryAddress: pcrAddress || _.get(currentPcr, 'value.primaryAddress'),
        contactType_Ext: contactCode,
        contactNameRequired_Ext: true,
        phoneRequired_Ext: false
      }
  }
  return res;
}


export default {
  setANIDefaultObj,
  setDBADefaultObj
};