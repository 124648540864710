import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';

import { Wizard } from '@xengage/gw-portals-wizard-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { Loader, useModal } from '@jutro/components';
import wizardConfig from './config/cp-wizard-config.json5';
import wizardStepToFieldMapping from './config/cp-wizard-step-to-field-mapping.json5';
import messages from './PECPWizard.messages';
import styles from './PECPWizard.module.scss';

const PATH_TO_MOCK_DATA = 'quote.cp';
const MOCK_DATA_TO_SET = ['bindData.contactPhone'];
const MOCK_DATA_TO_REMOVE = ['bindData.contactPhone'];

function PECPWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { viewModelService, history, location } = props;

    useEffect(
        () => {
            const viewModelContext = {
                AccountEmailRequired: false,
                AccountDOBRequired: false,
                DriverEmailRequired: true
            };

            if (!location.state) {
                history.push('/');
                return;
            }

            const { quoteentry } = location.state;
            const { postalCode, quoteID } = quoteentry;

            const requestData = {
                quoteID: quoteID,
                postalCode: postalCode
            };
            LoadSaveService.retrieveSubmission(requestData, authHeader)
                .then((response) => {
                    return MockUpUtil.cleanUpMockedProperties(
                        response,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_REMOVE
                    );
                })
                .then((retrieveSubmissionResponse) => {
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(retrieveSubmissionResponse, 'quoteID'),
                        authHeader
                    );
                    if (
                        retrieveSubmissionResponse.bindData
                        && !retrieveSubmissionResponse.bindData.contactPhone
                    ) {
                        _.set(retrieveSubmissionResponse, 'bindData.contactPhone', null);
                    }
                    const submission = viewModelService.create(
                        retrieveSubmissionResponse,
                        'pc',
                        'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                        viewModelContext
                    );
                    setInitialSubmission(submission);
                    setIsLoading(false);
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleCancel = useCallback(
        (cancelProps) => {
            const skipModal = ['knockoutpage', 'logout'];
            const {
                wizardSnapshot,
                param: nextPath = '',
                currentStepIndex,
                wizardData
            } = cancelProps;
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const quoteSummaryRegex = /^\/quotes\/[0-9]+\/summary/;

            // Don't display modal service for success page or if
            // next path matches /quotes/<job number>/summary
            if (
                currentStepIndex === steps.length - 1
                || skipModal.includes(lastPathName)
                || nextPath.match(quoteSummaryRegex)
                || hasErrorOccurred
            ) {
                return true;
            }

            return modalApi.showConfirm({
                title: commonMessages.wantToCancel,
                message: commonMessages.infoWillBeSavedAsDraft,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.yes,
                cancelButtonText: commonMessages.no
            }).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                const isStatusQuoted = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === 'Quoted';

                let serverCall = LoadSaveService.updateDraftSubmission;

                if (isStatusQuoted) {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const isValid = wizardData.lobData.aspects.valid
                    && wizardData.lobData.aspects.subtreeValid;
                const payload = isValid
                    ? _.get(wizardData, 'value')
                    : _.get(wizardSnapshot, 'value');

                // remove paymentDetails from bindData if page is in payment page
                const payloadBeforeSave = _.omit(payload, 'bindData.paymentDetails');
                setIsLoading(true);

                const redirectPath = `/quotes/${payload.quoteID}/summary`;

                const dataToSave = MockUpUtil.setMockData(
                    payloadBeforeSave,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_SET
                );
                serverCall(dataToSave, authHeader)
                    .then(() => {
                        history.push(nextPath || redirectPath);
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            title: messages.anErrorOccurredTitle,
                            message: messages.anErrorOccurred,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                        history.push(nextPath || redirectPath);
                    });
                return true;
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, history, steps.length]
    );

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
            const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
            const quoteID = quoteIDFromWizard || quoteIDFromLocation;
            const redirectPath = `/quotes/${quoteID}/summary`;
            const state = {
                pathname: redirectPath,
                state: error
            };

            return modalApi.showAlert({
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [location]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.cpWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <DependencyProvider
                    value={{
                        LoadSaveService
                    }}
                >
                    <Wizard
                        initialSteps={steps}
                        wizardTitle={title}
                        initialData={initialSubmission}
                        onCancel={handleCancel}
                        skipCompletedSteps
                        wizardStepToFieldMapping={wizardStepToFieldMapping}
                        onPreviousModalProps={{
                            title: commonMessages.wantToJump,
                            message: commonMessages.wantToJumpMessage,
                            status: 'warning',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.yesModel,
                            cancelButtonText: commonMessages.cancelModel
                        }}
                    />
                </DependencyProvider>
            </ErrorBoundary>
        </div>
    );
}

PECPWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withRouter(withViewModelService(PECPWizard));
