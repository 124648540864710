import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'quoteandbind.bop.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.bop.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'quoteandbind.bop.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'quoteandbind.bop.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    }
});
