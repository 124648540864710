import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsTitle: {
        id: 'wni.quoteandbind.wc.policydetails.Policy Details Title',
        defaultMessage: 'Policy Details'
    },
    termType: {
        id: 'wni.quoteandbind.wc.policydetails.Term Type',
        defaultMessage: 'Term Type'
    },
    policyState: {
        id: 'wni.quoteandbind.wc.policydetails.Policy State',
        defaultMessage: 'Policy State'
    },
    agencyOfRecordTitle: {
        id: "wni.quoteandbind.wc.policydetails.Agency of Record Title",
        defaultMessage: "Agency of Record"
    },
    organizationTypeDropdown: {
        id: "wni.quoteandbind.wc.policydetails.wni.quoteandbind.wc.policydetails.Organization Type",
        defaultMessage: "Organization Type"
    },
    policyUnderwritingQuestionsTitle: {
        id: "wni.quoteandbind.wc.policydetails.Policy Underwriting Questions Title",
        defaultMessage: "Policy Underwriting Questions"
    },
    pniMessage: {
        id: "wni.quoteandbind.wc.policyDetails.pni notification",
        defaultMessage: "Updating primary insured information will sync to the account and other quotes"
    }
});