
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from 'gw-capability-gateway-react/Components/UnderwritingComponent/UnderwritingComponent.messages';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import metadata from './ReferToUnderwriterComponent.metadata.json5';
import styles from './ReferToUnderwriterComponent.module.scss';

// See the Refer to Underwriter feature in UnderwritingComponent.jsx
// While using this component, either provide jobNumber and jobService, or onReferToUnderwriter.
const ReferToUnderwriterComponent = (props) => {
    const modalApi = useWniModal();
    const {
        referToUnderwriterID,
        jobNumber,
        jobService,
        onReferToUnderwriter,
        isServiceCallInProgress: isLoading,
        noteProcessor,
        //
        referToUWBtnPosition,
        referToUWBtnLabel,
        referToUWButtonEnabled,
        referToUWBtnTitle,
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [dataForm, updateDataForm] = useState({});
    const [showReferToUWPanel, setShowReferToUWPanel] = useState(false);

    const defaultReferToUWBtnLabel = translator(messages.warningUnderwriter)

    const defaultReferQuoteToUnderWriter = useCallback(async (noteForUW) => {
        await jobService.referToUnderwriter(jobNumber, noteForUW, authHeader).then(
            () => {
                // setShowReferToUWPanel(false);
            },
            () => {
                modalApi.showAlert({
                    title: gatewayMessages.modalError,
                    message: messages.errorSendingJobForReview,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
        ).finally(() => {
            setShowReferToUWPanel(false);
        });
    }, [jobService]);

    const handleUnderwrite = async () => {
        const { uwNoteForUnderwriter } = dataForm;
        // const noteForUW = uwNoteForUnderwriter === '' ? null : uwNoteForUnderwriter;
        const noteForUW = noteProcessor(uwNoteForUnderwriter);
        if (_.isFunction(onReferToUnderwriter)) {
            await onReferToUnderwriter(noteForUW);
        } else {
            await defaultReferQuoteToUnderWriter(noteForUW);
        }
    };

    const onReferToUW = useCallback(() => {
        setShowReferToUWPanel(true);
    }, []);
    const onCancelReferUW = useCallback(() => {
        setShowReferToUWPanel(false);
    }, []);

    //-------------------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false
        },
        referToUnderwriterButton: {
            visible: !showReferToUWPanel,
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
            content: referToUWBtnLabel || defaultReferToUWBtnLabel,
            disabled: !referToUWButtonEnabled,
            title: referToUWBtnTitle,
        },
        referToUnderwriterContainer: {
            visible: !showReferToUWPanel,
            className: referToUWBtnPosition
        },
        referToUWPanel: {
            visible: showReferToUWPanel,
        },
        uwReferUnderwriterButtonsCancel: {
            // renderContent: renderHelper.getButtonRenderFunc(isLoading),
        },
        uwReferUnderwriterButtonsConfirm: {
            content: renderHelper.getButtonContent(translator(messages.confirmJob), isLoading, referToUnderwriterID)
        }
    };

    const writeValue = (value, path) => {
        const newFormData = _.cloneDeep(dataForm);
        _.set(newFormData, path, value);
        updateDataForm(newFormData);
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleUnderwrite: handleUnderwrite,
            onCancelReferUW: onCancelReferUW,
            onReferToUW: onReferToUW,
            writeValue: writeValue,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dataForm}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

ReferToUnderwriterComponent.propTypes = {
    jobNumber: PropTypes.string,
    jobService: PropTypes.shape({
        referToUnderwriter: PropTypes.string
    }),
    onReferToUnderwriter: PropTypes.func,
    isServiceCallInProgress: PropTypes.bool,
    noteProcessor: PropTypes.func,
    referToUWBtnPosition: PropTypes.string,
    referToUnderwriterID: PropTypes.string,
    referToUWButtonEnabled: PropTypes.bool,
    referToUWBtnTitle: PropTypes.string
};

ReferToUnderwriterComponent.defaultProps = {
    jobNumber: undefined,
    jobService: undefined,
    onReferToUnderwriter: undefined,
    isServiceCallInProgress: false,
    noteProcessor: (note) => (_.isEmpty(note) ? null : note),
    referToUWBtnPosition: 'align-center',
    referToUnderwriterID: 'ReferToUnderwriterConfirmBtn',
    referToUWButtonEnabled: true,
    referToUWBtnTitle: undefined,
};

export default ReferToUnderwriterComponent;
