import { ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React,{ useCallback,useState } from 'react';
import metadata from './ExportModal.metadata.json5';

import { Button } from '@jutro/legacy/components';

function ExportModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        download,
        exportType,
        cpExportMask,
        isOpen,
        onResolve,
        onReject,
        title,
        useDependenciesData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;
    const [formatName, setFormatName] = useState('All Available');

    const handleExport = async () => {
        setLoadingMask(true);
        await download(formatName, exportType);
        setLoadingMask(false);
        onResolve();
    };

    const generateFormatOptions = useCallback(() => {
        return cpExportMask.map((elt) => {
            return { name: elt, code: elt };
        });
    }, [cpExportMask]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },

        formatField: {
            onValueChange: setFormatName,
            availableValues: generateFormatOptions(),
            value: formatName,
        },
    };
    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={title} />
            <ModalBody id="exportRisk">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'between',
                    },
                }}
            >
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleExport}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

ExportModal.propTypes = {};
ExportModal.defaultProps = {
    size: 'md',
};
export default ExportModal;
