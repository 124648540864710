import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { FNOLService } from 'wni-capability-fnol';

import metadata from './FNOLHOIncidentDetails.metadata.json5';
import SelectAddressComponent from './LossLocation/SelectAddressComponent';
import LossLocationComponent from './LossLocation/LossLocationComponent';

function FNOLHOIncidentDetails(props) {
    const {
        wizardData,
        wizardSnapshot,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    // const { FNOLService } = useDependencies('FNOLService');
    const { authHeader } = useAuthentication();
    const [pageData] = useState(state);
    const [showErrors, setShowErrors] = useState(false)
    const claimVM = !_.isEmpty(wizardData) ? wizardData : wizardSnapshot;
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation(
        'FNOLHOIncidentDetailsPage'
    );

    const lossSubCauseAvailableValues = _.get(claimVM, 'lossSubCause_Ext.aspects.availableValues', [])

    const predefinedLossLocations = _.get(claimVM.value, 'predefinedLossLocations', []);
    const initiallossLocationIndex = predefinedLossLocations.findIndex((location) => {
        return location.publicID === _.get(claimVM, 'value.lossLocation.publicID')
    })
    const [lossLocationIndex, updateLossLocationIndex] = useState(initiallossLocationIndex);
    const [countNewLocation, updateCountNewLocation] = useState(0);
    const primaryLocationID = _.get(claimVM, 'value.primaryLocation.publicID');
    const currentLossLocation = predefinedLossLocations[lossLocationIndex];
    const isLocationReadOnly = primaryLocationID === _.get(currentLossLocation, 'publicID');

    const onNext = useCallback(async () => {
        const updatedClaim = await FNOLService.saveFNOLDetails(claimVM.value, authHeader)
        const updatedClaimVM = _.clone(claimVM)
        updatedClaimVM.value = updatedClaim
        updateWizardData(updatedClaimVM)
        return updatedClaimVM
    }, [FNOLService, authHeader, claimVM, updateWizardData]);

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const onSelectAddressChange = useCallback((value) => {
        const newClaimVM = _.clone(claimVM);
        if (value=== 'new') {
            updateLossLocationIndex(predefinedLossLocations.length);
            updateCountNewLocation(countNewLocation + 1);
        } else {
            const newLossLocationIndex = predefinedLossLocations.findIndex((location)=>location.publicID === value);
            updateLossLocationIndex(newLossLocationIndex);
            _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[newLossLocationIndex]);
        }
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, predefinedLossLocations, updateWizardData])

    const onLocationChange = useCallback((value, path) => {
        const newClaimVM = _.clone(claimVM);
        _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].${path}`, value);
        if (_.isNil(_.get(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`))) {
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`, `tempNew${countNewLocation}`)
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].country`, 'US')
        }
        _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[lossLocationIndex]);
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, lossLocationIndex, predefinedLossLocations, updateWizardData])
    
    const overrideProps = useMemo(() => ({
        lossSubCauseDropdown: {
            visible: lossSubCauseAvailableValues.length > 0
        },
        selectAddress: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onSelectAddressChange: onSelectAddressChange,
            countNewLocation: countNewLocation
        },
        lossLocation: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onLocationChange: onLocationChange,
            isReadOnly: isLocationReadOnly,
            countNewLocation: countNewLocation,
            setComponentValidation: onValidate,
            showErrors: showErrors,
        }
    }), [
        lossSubCauseAvailableValues.length,
        predefinedLossLocations,
        lossLocationIndex,
        onSelectAddressChange,
        countNewLocation,
        onLocationChange,
        isLocationReadOnly,
        onValidate,
        showErrors
    ]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            SelectAddressComponent: SelectAddressComponent,
            LossLocationComponent: LossLocationComponent
        }
    };

    return (
        <WizardPage disableNext={!isComponentValid} onNext={isComponentValid ? onNext : setValidation} template={WizardPageTemplate} skipWhen={initialValidation} >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={resolvers.resolveValue}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLHOIncidentDetails.propTypes = wizardProps;
FNOLHOIncidentDetails.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(FNOLHOIncidentDetails);
 