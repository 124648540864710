import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import PropTypes from 'prop-types';
import { CustomFooterV2Component } from 'wni-capability-gateway-react';

import WizardPage from '../../templates/WALWizardPage';
import OperatorComponent from './components/OperatorComponent/OperatorComponent';
import OperatorUtil from './util/OperatorUtil';

import styles from './WALOperatorsPage.module.scss';
import metadata from './WALOperatorsPage.metadata.json5';
import messages from './WALOperatorsPage.messages';

function WALOperatorsReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        linePath
    } = props;

    const translator = useTranslator();
    const [operatorData, updateOperatorData] = useState([]);
    const [currentRow, updateCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [accordionStates, updateAccordionStates] = useState([]);

    const operatorsPath = `lobData.${linePath}.coverables.operators`;
    const operatorsVM = _.get(submissionVM, operatorsPath);
    const operators = operatorsVM.value;

    const VALIDATION_ICON_MAP = {
        success: 'gw-check-circle',
        warning: 'gw-warning',
        error: 'gw-error',
    };

    const hideButtonsProps = {
        showNext: !currentRow,
        showPrevious: !currentRow,
        showCancel: !currentRow
    };

    const setRowIdPath = useCallback(() => {
        const VM = _.get(submissionVM, operatorsPath);
        const value = VM.value;
        value.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateOperatorData(operators);
    }, [submissionVM]);

    useEffect(() => {
        setRowIdPath();
    }, [submissionVM]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'operatorTable');
    };

    const switchToNextOperator = useCallback(() => {
        setRowIdPath();
        const childrenVM = _.get(submissionVM, `lobData.${linePath}.coverables.operators.children`);
        const index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            updateCurrentRow(_.get(childrenVM, 0));
            return;
        }
        updateCurrentRow(_.get(childrenVM, index + 1));
    }, [currentRow, submissionVM]);
    
    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow, switchToNextOperator, submissionVM]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const viewOrEditOperator = (value, index) => {
        const childrenVM = _.get(submissionVM, `lobData.${linePath}.coverables.operators.children`);
        const operatorVM = childrenVM.find((item) => item.value.publicID === index);
        setRowIdPath();
        updateCurrentRow(operatorVM);
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `lobData.${linePath}.coverables.operators.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        let type;
        if(!_.get(itemVM, 'aspects.valid') || !_.get(itemVM, 'aspects.subtreeValid')) {
            type = 'error';
        } else {
            type = 'success';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const cancel = useCallback(() => {
        updateCurrentRow(null);
    }, []);

    const getAvailableAccountContacts = useCallback(() => {
        const accountContacts = _.get(operatorsVM, 'value[0].accountContacts');
        const hasSavedOperators = _.filter(operators, (operator) => {
            return operator.publicID
        })
        const contactDenormPublicIds = _.map(hasSavedOperators, (operator) => {
            return operator.contactDenormPublicId
        });
        const res = _.filter(accountContacts, (contact) => {
            return !_.includes(contactDenormPublicIds, contact.publicID);
        });
        return _.cloneDeep(res);
    }, [operators, submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
        },delOperator: {
            visible: false
        },
        addOperator: {
            visible: false
        },
        operatorTable: {
            // data: operators,
            data: operatorData,
            onSelectionChange: (rows) => {
                updateSelection(rows)
            },
            rowIdPath: 'rowIdPath'
        },
        operatorValidationIcon:{
            renderCell: renderValidationCell
        },
        name: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.rowIdPath, OperatorUtil.onNameCell(item))
        },
        dateOfBirth: { renderCell: OperatorUtil.onDateOfBirthCell },
        relationship: { renderCell: (item) => OperatorUtil.onRelationshipCell(item, translator) },
        excluded: {
            visible: OperatorUtil.hasExcluded(operators)
        },
        viewOrEdit: {
            renderCell: (row, index) => {
                const componentProps = {
                    onClick: viewOrEditOperator,
                    label: translator(messages.waViewLabel)
                }
                return WniTableRowUtil.renderViewAndEdit(row, index, componentProps)
            }
        },
        operator: {
            visible: !_.isEmpty(currentRow),
            operatorVM: currentRow,
            messages,
            baseState: _.get(submissionVM, 'value.baseData.baseState_Ext'),
            effectiveDate: _.get(submissionVM, 'value.baseData.effectiveDate_Ext'),
            relationshipOptions: _.get(operatorsVM, 'value[0].relationshipOptions'),
            availableAccountContacts: getAvailableAccountContacts(),
            isReadOnly: true,
            accordionStates,
            updateAccordionStates
        },
        operatorDetailContainer: {
            visible: !_.isEmpty(currentRow),
        }
    };

    const getFooterButtons = useCallback(() => {
        if (!currentRow) {
            return (<div />);
        }

        const nextButtonVisible = operators.length > 1;

        return (
            <CustomFooterV2Component
                saveAndNextMessage={translator(messages.next)}
                handleCancel={cancel}
                nextButtonVisible={nextButtonVisible}
                saveButtonVisible={false}
                handleSaveAndNext={switchToNextOperator}
            />
        );
    }, [cancel, currentRow, translator]);

    // ===================================================
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
        },
        resolveComponentMap: {
            operatorcomponent: OperatorComponent,
        }
    };

    const renderPageContent = useCallback(({ onNext }) => {
        return <React.Fragment>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
            {getFooterButtons(onNext)}
        </React.Fragment>;
    }, [overrideProps, resolvers, submissionVM]);

    return (
        <WizardPage
            {...hideButtonsProps}>
            {renderPageContent}
        </WizardPage>
    );
}

WALOperatorsReadOnlyPage.propTypes = {
    ...wizardProps,
    linePath: PropTypes.string
};

WALOperatorsReadOnlyPage.defaultProps = {
    linePath: 'watercraft'
};
export default WALOperatorsReadOnlyPage;
