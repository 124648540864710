import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './GaragingLocation.metadata.json5';
import { newGarageLocation } from '../../Config/CAVehicle.static';
import TerritoryField from '../../../Common/TerritoryField';

function GaragingLocation(props) {
    const {
        currentRow,
        syncWizardData,
        onValueChange,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance,
        isChangeFlow,
        extendProps
    } = props;
    const {
        locationMaps = []
    } = currentRow.value;

    const getLocationOptions = (options) => {
        const generateOptions = options.map((location) => {
            return {
                ...location,
                code: location.publicID,
                name: location.locationName
            };
        });
        // if(isChangeFlow) {
        //     return generateOptions
        // }
        // generateOptions.push(newGarageLocation);
        return generateOptions;
    };

    const writeValue = async(value, path) => {
        if(!value) {
            onValueChange(value, path);
            return false;
        }
        switch(path) {
            case "locationID":
                if (value === 'new') {
                    _.set(currentRow, 'location', {
                        displayName: newGarageLocation.name,
                        country: newGarageLocation.country
                    });
                } else {
                    _.set(currentRow, 'location', locationMaps.find((item) => item.publicID === value));
                }
                _.set(currentRow, path, value);
                syncWizardData(currentRow);
                break;
            default:
                break;
        }
    };
    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        garagingLocation: {
            availableValues: getLocationOptions(locationMaps),
        },
        addressContainer: {
            visible: _.get(currentRow.value, 'locationID') === 'new',
        },
        addressLookup: {
            model: currentRow,
            dataPath: 'location.address_Ext',
            onAddressChange: onValueChange,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true
            },
            showErrors,
            onValidate
        },
        territoryfield: {
            vm: currentRow.location,
            basePath: 'location',
            addressPath: 'address_Ext',
            onValueChange,
            showErrors,
            onValidate,
            isReadOnly,
            extendProps
        }
    };
    const resolvers = {
        resolveCallbackMap: {
    
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent,
            territoryfield: TerritoryField
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default GaragingLocation;
