import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCoveragesPopupTitle: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Additional Coverages",
        "defaultMessage": "Additional Coverages"
    },
    additionalInsuredsCoveragesPopupTitle: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Additional Insureds Coverages",
        "defaultMessage": "Additional Insureds"
    },
    exclusionsAndConditionsPopupTitle: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Exclusions & Conditions",
        "defaultMessage": "Exclusions & Conditions"
    },
    coveragesPopupTitle: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Coverages",
        "defaultMessage": "Coverages"
    },
    LineAdditionalCoverageCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Line Additional Coverages",
        "defaultMessage": "Line Additional Coverages"
    },
    LineAdditionalInsuredsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Line Additional Insureds",
        "defaultMessage": "Line Additional Insureds"
    },
    LineConditionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Line Conditions",
        "defaultMessage": "Line Conditions"
    },
    LineExclusionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Line Exclusions",
        "defaultMessage": "Line Exclusions"
    },
    AllCoveragesCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.All",
        "defaultMessage": "All"
    },
    StateSpecificAdditionalCoverageCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.StateSpecific Additional Coverages",
        "defaultMessage": "Jurisdiction Additional Coverages"
    },
    StateSpecificAdditionalInsuredsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.StateSpecific Additional Insureds",
        "defaultMessage": "Jurisdiction Additional Insureds"
    },
    StateSpecificConditionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.StateSpecific Conditions",
        "defaultMessage": "Jurisdiction Conditions"
    },
    StateSpecificExclusionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.StateSpecific Exclusions",
        "defaultMessage": "Jurisdiction Exclusions"
    },
    LocationAdditionalCoverageCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Location Additional Coverages",
        "defaultMessage": "Location Additional Coverages"
    },
    LocationAdditionalInsuredsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Location Additional Insureds",
        "defaultMessage": "Location Additional Insureds"
    },
    LocationConditionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Location Conditions",
        "defaultMessage": "Location Conditions"
    },
    LocationExclusionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.Location Exclusions",
        "defaultMessage": "Location Exclusions"
    },
    RiskItemAdditionalCoverageCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.RiskItem Additional Coverages",
        "defaultMessage": "{coverableType} Additional Coverages"
    },
    RiskItemAdditionalInsuredsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.RiskItem Additional Insureds",
        "defaultMessage": "{coverableType} Additional Insureds"
    },
    RiskItemConditionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.RiskItem Conditions",
        "defaultMessage": "{coverableType} Conditions"
    },
    RiskItemExclusionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.RiskItem Exclusions",
        "defaultMessage": "{coverableType} Exclusions"
    },
    OccupancyClassAdditionalCoverageCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.OccupancyClass Additional Coverages",
        "defaultMessage": "Occupancy Class Additional Coverages"
    },
    OccupancyClassAdditionalInsuredsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.OccupancyClass Additional Insureds",
        "defaultMessage": "Occupancy Class Additional Insureds"
    },
    OccupancyClassConditionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.OccupancyClass Conditions",
        "defaultMessage": "Occupancy Class Conditions"
    },
    OccupancyClassExclusionsCategory: {
        "id": "wni.cp.wizard.commercialProperty.coveragePopup.OccupancyClass Exclusions",
        "defaultMessage": "Occupancy Class Exclusions"
    }
});