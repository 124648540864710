import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Loader, FieldComponent } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniPolicyService } from 'wni-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { gatewayMessages } from 'gw-capability-gateway-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniDateUtil } from 'wni-portals-util-js';

import appConfig from 'app-config';
import claimMessages from './PolicyClaims.messages';
import metadata from './PolicyClaims.metadata.json5';
import styles from './PolicyClaims.module.scss';

import { Link } from '@jutro/router';

// const { fnolLobs, fnolLobsConfig } = appConfig;
// const getFnolLobs = _.reduce(
//     fnolLobs,
//     (namesLiterals, policyType) => namesLiterals.concat(fnolLobsConfig[policyType]),
//     []
// );

const PolicyClaims = (props) => {

    const {
        fromAccountLanding
    } = props

    const {
        policyDetailsData: { policyResponse },
    } = fromAccountLanding

    const {
        account: {
            accountNumber
        }
    } = policyResponse

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const history = useHistory();
    const { interactionModel } = useDependencies('interactionModel');

    const [claimResponse, setClaimResponse] = useState('')
    const [claimDataTable, setClaimDataTable] = useState('')
    const [searchFilter, setSearchFilter] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const getSearchFilterValues = (claimsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(claimsArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    const getClaimDataTable = useCallback((claimsData, filter) => {
        let claimsArrayResult = claimsData;
        if (filter.type === 'SearchFilter') {
            claimsArrayResult = getSearchFilterValues(claimsArrayResult, filter);
        }
        claimsArrayResult = claimsArrayResult.map((claimInfo) => {
            const claims = {
                claimNumber: claimInfo.claimNumber,
                dateOfLoss: claimInfo.lossDate,
                dateOfClose_Ext: claimInfo.dateOfClose_Ext,
                status: claimInfo.status,
                paid: claimInfo.status === 'Closed' ? claimInfo.totalPayments : undefined,
                netIncurred: claimInfo.totalIncurredNet
            };
            return claims;
        });
        setClaimDataTable(claimsArrayResult)
    }, []);

    useEffect(() => {
        const { policyNumber } = fromAccountLanding.policyDetailsData.policyResponse;
        WniPolicyService.getPolicyClaims(policyNumber, authHeader).then((response) => {
            const filter = {
                type: null,
                value: null
            };
            getClaimDataTable(response, filter);
            setClaimResponse(response)
            setIsLoading(false)
        });
    }, [])

    useEffect(() => {
        const filter = {
            type: 'SearchFilter',
            value: searchFilter
        };
        getClaimDataTable(claimResponse, filter);
    }, [claimResponse, getClaimDataTable, searchFilter])

    
    const handleFilterChange = (value) => {
        setSearchFilter(value)
    };

    const getLossAndCloeseDate = (item, index, property) => {
        return (
            <FieldComponent
                id={`lossDate_${index}`}
                // value={item[property.id]}
                readOnly
                hideLabel
                showOptional={false}
                value={_.isNil(item[property.id]) ? '' : WniDateUtil.formatDateWithPattern(item[property.id], "MM/DD/YYYY")}
            />
        );
    };

    const handleFileClick = () => {
        const { policyNumber } = policyResponse;
        const redirectClaimsPath = `/policies/${policyNumber}/claims`;
        return history.push({
            pathname: '/fnol-select-policy',
            state: {
                policyNumber: policyNumber,
                redirectPath: redirectClaimsPath
            }
        });
    };

    const getCell = (item, index, property) => {
        const toolTipMessage = {
            dateOfLoss: translator(claimMessages.dateOfLoss),
            status: translator(claimMessages.status)
        };
        return <span title={toolTipMessage[property.id]}>{item[property.id]}</span>;
    };

    const getCurrencyCell = (item, index, property) => {
        const toolTipMessage = {
            paid: translator(claimMessages.paid),
            netIncurred: translator(claimMessages.netIncurred)
        };
        return (
            <span title={toolTipMessage[property.id]}>
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    datatype="object"
                    title={toolTipMessage}
                    readOnly
                    hideLabel
                />
            </span>
        );
    };

    const getLink = (item) => {
        const {
            claimNumber
        } = item
        const toolTipMessage = translator(claimMessages.claimNumber);
        return (
            <Link 
                className={styles.gwAccountClaimsLinkContent}
                title={toolTipMessage}
                {...interactionModel.getURLObj(null, 'claimDetails', accountNumber, claimNumber)}
            >
                {claimNumber}
            </Link>
        );
    };

    // const checkIfPolicyIsInForceStatus = (latestPeriod) => {
    //     return (
    //         latestPeriod.status
    //             === translator({ id: 'typekey.PolicyPeriodStatus.Bound', defaultMessage: 'Bound' })
    //         && !moment().isBefore(moment(latestPeriod.effectiveDate))
    //     );
    // };

    // const getFileClaimButtonVisible = () => {
    //     const policyType = _.get(policyResponse, 'product.productCode', '');
    //     const policyNumber = _.get(policyResponse, 'policyNumber', '');
    //     return (
    //         getFnolLobs.includes(policyType)
    //         && policyNumber
    //         && !policyResponse.latestPeriod.canceled
    //         && checkIfPolicyIsInForceStatus(policyResponse.latestPeriod)
    //     );
    // };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const overrides = {
        claimDataTable: {
            data: claimDataTable
        },
        fileClaimButton: {
            // visible: this.getFileClaimButtonVisible()
            visible: false
        },
        searchFilter: {
            value: searchFilter,
            visible: !_.isEmpty(claimResponse)
        },
        noDataMessageContainer: {
            visible: _.isEmpty(claimResponse)
        },
        noDataMessage: {
            value: translator(gatewayMessages.noClaimsResult)
        },
        paidColumnTitle: {
            visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
        },
        netIncurredColumnTitle: {
            visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
         resolveCallbackMap: {
            getCell: getCell,
            getCurrencyCell: getCurrencyCell,
            getLossAndCloeseDate: getLossAndCloeseDate,
            getLink: getLink,
            handleSearchValueChange: handleFilterChange,
            handleFileClick: handleFileClick,
            sortDate: DatatableUtil.sortDate,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
            sortCurrency: DatatableUtil.sortCurrency
        }
    };
    const claimPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    return (
        <div>
            {claimPage}
        </div>
    );
}

PolicyClaims.propTypes = {
    fromAccountLanding: PropTypes.shape({
        policyDetailsData: PropTypes.shape({
            policyResponse: PropTypes.shape({
                policyNumber: PropTypes.string,
                account: PropTypes.shape({
                    policySummaries: PropTypes.array
                }),
                product: PropTypes.shape({
                    productCode: PropTypes.string
                }),
                latestPeriod: PropTypes.shape({
                    canceled: PropTypes.string
                })
            })
        })
    }).isRequired,
}

export const PolicyClaimsComponent = PolicyClaims;
export default PolicyClaims
