import _ from 'lodash'
import {
    getNewDeductibleOptionsByType,
    HOPSPPTypeFineArtsAntiques,
    HOPSPPTypeJewelry,
    HOSSPAgreedValueOptionCodes,
} from './HOSchedulePersonalPropertyUtil'

const filterSPPDeductiblesOptions = (scheduleItem, allOptions) => {
    const classOfPersonalPropertyCode = _.get(scheduleItem, 'itemData.HOPScheduledPersonalPropertyItemType.typeCodeValue')
    return getNewDeductibleOptionsByType(classOfPersonalPropertyCode, allOptions)
}

const filterSPPAgreedValueOptions = (scheduleItem, allOptions) => {
    const classOfPersonalPropertyCode = _.get(scheduleItem, 'itemData.HOPScheduledPersonalPropertyItemType.typeCodeValue')
    return allOptions.filter((option) => {
        if (_.get(option, 'code') === HOSSPAgreedValueOptionCodes.No) {
            return classOfPersonalPropertyCode === HOPSPPTypeJewelry
        }
        if (_.get(option, 'code') === HOSSPAgreedValueOptionCodes.Yes) {
            return classOfPersonalPropertyCode === HOPSPPTypeJewelry
                || classOfPersonalPropertyCode === HOPSPPTypeFineArtsAntiques
        }
        return true
    })
}

export default {
    filterSPPDeductiblesOptions,
    filterSPPAgreedValueOptions,
};