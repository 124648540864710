import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { Grid } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';

import ResidenceUtil from '../../util/ResidenceUtil';
import metadata from './DiffPopupComponent.metadata.json5';
import messages from '../../HOResidencePage.messages';

import { Button } from '@jutro/legacy/components';

function DiffPopupComponent(props) {
    const {
        isOpen,
        onResolve,
        onReject,
        model: diffFields,
        launchRCTURL,
        compRaterDwelling,
        RCTDwelling,
        translator
    } = props;
    const ids = 'diffPopupComponent'

    const handlePrintBtn = () => {
        // window.print();
        WindowUtil.partialPrint(ids);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left'
        },
        diffFieldsTable: {
            data: diffFields
        },
        compRaterVal: {
            cell: (item) => ResidenceUtil.onDiffTableCell(item, compRaterDwelling, translator)
        },
        RCTVal: {
            cell: (item) => ResidenceUtil.onDiffTableCell(item, RCTDwelling, translator)
        }
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
            onLaunchRCTURL: launchRCTURL
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={`md ${ids}`}>
            <ModalHeader title={messages.diffTitle} onClose={onReject} />
            <ModalBody id="agencyDatailsPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    // model={fieldsName}
                    overrideProps={overrideProps}
                    // resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Grid justifyItems="between">
                    <div className="align-right">
                        <Button onClick={handlePrintBtn} type="outlined">{messages.printThisPage}</Button>
                        <Button onClick={onResolve} type="primary">{messages.override}</Button>
                        <Button onClick={onReject} type="outlined">{messages.retain}</Button>
                    </div>
                </Grid>
            </ModalFooter>
        </ModalNext>
    );
}

DiffPopupComponent.propTypes = {
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    model: PropTypes.shape({}),
    compRaterDwelling: PropTypes.shape({}),
    RCTDwelling: PropTypes.shape({})
};
DiffPopupComponent.defaultProps = {
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    compRaterDwelling: {},
    RCTDwelling: {}
};

export default DiffPopupComponent;
