import React, {
    // useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { useWniModal } from 'wni-components-platform-react';
import { WCPolicyDetailsChangeService } from 'wni-capability-policychange-wc';
import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';

import moment from 'moment';
import {
    WCWizardPage as WizardPage
} from 'wni-capability-quoteandbind-wc-react';

// import styles from './WCPolicyDetailsPage.module.scss';
import metadata from './WCPolicyDetailsChangePage.metadata.json5';
// import messages from './WCPolicyDetailsPage.messages';

function WCPolicyDetailsChangePage(props) {

    // const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,

        savePolicyDetailsData,

    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        // policyInfoSource_Ext: policyInfoSource,
        baseData,
        // lobData: {
        //     workersComp: {
        //         // policyDetails: {
        //         //     organizationTypeOptions,
        //         // }
        //     }
        // },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        // baseState_Ext: baseState,
        // quoteFlow_Ext: quoteFlow,
        effectiveDate_Ext: transactionEffectiveDate
        // productCode
    } = baseData;

    // const history = useHistory();
    // const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    // const viewModelService = useContext(ViewModelServiceContext);
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    // const policyDetailPath = 'lobData.workersComp.policyDetails';
    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('WCPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    
    // const writeValue = useCallback(
    //     (value, path) => {
    //         const newSubmissionVM = _.clone(submissionVM);
    //         // When coverages Form is changed, clear residence type value
    //         // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
    //         //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
    //         // }
    //         if (path === 'baseData.effectiveDate_Ext') {
    //             _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
    //         }
    //         _.set(newSubmissionVM, path, value);
    //         updateSubmissionVM(newSubmissionVM);
    //     },
    //     [submissionVM, updateSubmissionVM]
    // );

    const getMinDate = useCallback(() => {
        // for transactions once rated, enable 5 days behind the current date
        // if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
        //     return moment(systemDate).add(-5, 'd').startOf('day').toDate();
        // }
        return systemDate
    }, [systemDate]);

    // const isEffectiveDateValid = useCallback(() => {
    //     const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
    //     const minDate = getMinDate();
    //     const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();

    //     return (minDif >= 0) 
    // }, [submissionVM.value, getMinDate]);
    

    // const WCPolicyDetailsValidation = useCallback(() => {
    //     return isEffectiveDateValid()
    // }, [isEffectiveDateValid]);

    // useEffect(() => {
    //     registerComponentValidation(WCPolicyDetailsValidation);
    // }, [registerComponentValidation, transactionEffectiveDate]);


    // const handleValidation = useCallback(
    //     () => {
    //         updateShowErrors(true);
    //         WindowUtil.scrollToInvalidField(invalidFields);
    //         return false;
    //     },[updateShowErrors, invalidFields]
    // );

    // const onPageNext = useCallback(async () => {
    //     setLoadingMask(true);
    //     const wcPolicyDetailsData = _.get(submissionVM.value, 'lobData.workersComp.policyDetails')
    //     // const questionSetData = _.get(submissionVM.value, 'lobData.workersComp.preQualQuestionSets')
    //     const quoteBaseData = _.get(submissionVM.value, 'baseData')
        
    //     const res = await savePolicyDetailsData(
    //         quoteID, 
    //         sessionUUID,
    //         wcPolicyDetailsData,
    //         // questionSetData,
    //         quoteBaseData,
    //         authHeader);
    //     setLoadingMask(false);
    //     submissionVM.value = res;
    //     updateSubmissionVM(submissionVM);
        
    //     // Next step: move this and the service call into HOWizardPage as a common feature
    //     const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
    //     const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
    //     updateValidationIssues(newValidationIssues);

    //     const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
    //     const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
    //     if(hasValidationWarning && !displayWarnings) {
    //         updateDisplayWarnings(true);
    //         return false;
    //     }

    //     if (hasValidationError) {
    //         WindowUtil.scrollToWizardErrors();
    //         return false;
    //     }
    //     return submissionVM;
    // }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                isReadOnly: true,
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: getMinDate(),
                showErrors
            },
        
            
        }
    }, [getMinDate, showErrors]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: {

        },
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

WCPolicyDetailsChangePage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
};

WCPolicyDetailsChangePage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: WCPolicyDetailsChangeService.saveWCPolicyDetailsData,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
} 
export default WCPolicyDetailsChangePage;