import React, {
    useContext, useCallback, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './AdditionalInformationPage.metadata.json5';
import './AdditionalInformationPage.messages';

const PATH_TO_MOCK_DATA = 'quote.bop';
const MOCK_DATA_TO_SET = ['bindData.contactEmail', 'baseData.accountHolder.emailAddress1'];

function AdditionalInformationPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { onValidate, isComponentValid } = useValidation('AdditionalInformationPage');

    const writeValue = useCallback(
        (newValue, path) => {
            const normalisedPhoneNumber = _.isNil(newValue)
                ? undefined
                : newValue.replace(/-/g, '');

            if (normalisedPhoneNumber !== _.get(submissionVM, path)) {
                _.set(submissionVM, path, newValue);
                updateWizardData(submissionVM);
            }
        },
        [submissionVM, updateWizardData]
    );

    useEffect(() => {
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            PATH_TO_MOCK_DATA,
            ...MOCK_DATA_TO_SET
        );

        updateWizardData(submissionVM);
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            'quote.bop',
            'bindData.contactPhone'
        );
        
        submissionVM.value = await LoadSaveService.updateQuotedSubmissionWithLOBData(
            submissionVM.value,
            authHeader
        );
        
        submissionVM.value = MockUpUtil.cleanUpMockedProperties(
            submissionVM.value,
            'quote.bop',
            'bindData.contactPhone'
        );
        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM]);

    const locationOverrides = useMemo(() => {
        const locationsPath = 'lobData.businessOwners.coverables.locations.value';
        const locations = _.get(submissionVM, locationsPath, []);

        const overrides = locations.map((location = {}, index) => ({
            [`phoneNumberFieldTitle${index}`]: {
                content: location.displayName
            }
        }));

        return Object.assign({}, ...overrides);
    }, []);

    const overrideProps = {
        '@field': {
            // apply to all fields
            onValueChange: writeValue,
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        ...locationOverrides
    };

    return (
        <WizardPage onNext={onNext} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

AdditionalInformationPage.propTypes = wizardProps;
export default AdditionalInformationPage;
