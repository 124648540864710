import React, {
    useCallback,
    useState,
    useMemo,
    useContext,
    useEffect,
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { PUAddiService, PUCoveragesService } from 'wni-capability-quoteandbind-pu';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import WizardPage from '../../templates/PUWizardPage';
import CoverageUtil from './util/PUCoverageUtil';
import styles from './PUCoveragesPage.module.scss';
import metadata from './PUCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/PUCoveragesConfigContext';
import CoveragesConfig from './PUCoverages.config';
import PULineStandardCoverages from './CoveragesSection/PULineStandardCoverages/PULineStandardCoverages';
import PULineOtherOptionalCoverages from './CoveragesSection/PULineOtherOptionalCoverages/PULineOtherOptionalCoverages';
import PULineExclusions from './CoveragesSection/PULineExclusions/PULineExclusions';
import PUAddiComponent from './components/PUAddiComponent/PUAddiComponent';

function PUCoveragesPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const lobName = 'personalUmbrella'

    const {
        jobID,
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
            accountContacts_Ext: accountContacts
        },
        lobData: {
            [lobName]: {
                offerings,
            }
        },
    } = submissionVM.value

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const additionalInterestsVM = _.get(submissionVM, 'lobData.personalUmbrella.coverables.additionalInterests');
    const additionalInterestType = _.get(submissionVM, 'lobData.personalUmbrella.coverables.addInterestTypeCategory');

    const viewModelService = useContext(ViewModelServiceContext);

    const createEmtpyTrusteeVM = useCallback(() => {
        const dtoVMPath = 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO';
        const newAdditionalInterestDTOVM = viewModelService.create(
            {
                primaryAddress: {},
                phoneRequired_Ext: false
            },
            'pc', dtoVMPath);
        return newAdditionalInterestDTOVM;
    }, [viewModelService]);

    // const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
    } = useValidation('PUCoveragesPage');


    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false)

    const [validationIssues, updateValidationIssues] = useState([])
    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelAdditionalCoverages',
        'policyLevelExclusionsAndConditions',
    ]);
    const [loadingClausesCodes, setLoadingLineCoverages] = useState(false);

    

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const {
        coverages: {
            lineStandardCoverages = [],
            lineOtherOptionalCoverages = [],
            lineExclusions = [],
        }
    } = selectedVersion

    const isPULineClausesValid = useCallback(() => {
        return !CoverageUtil.hasInvalidCoverage(
            lineStandardCoverages.concat(lineOtherOptionalCoverages).concat(lineExclusions)
        )
    }, [lineExclusions, lineOtherOptionalCoverages, lineStandardCoverages])

    useEffect(() => {
        registerComponentValidation(isPULineClausesValid)
    }, [registerComponentValidation, isPULineClausesValid])

    const pageLevelValidation = useMemo(
        () => validationIssues
            // .concat(errMsgsInvalidRTVehicle)
            ,
        [
            // errMsgsInvalidRTVehicle,
            validationIssues
        ]
    )

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const onPageNext = async () => {
        // Call update coverages service to save coverages not sync and check errors and warnings
        const coverages = _.get(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = CoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = CoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName);
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await PUCoveragesService.onNext(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        setLoadingLineCoverages(false);
        const lobCoverages = _.get(response, 'coverages');
        const lossHistory = _.get(response, 'lossHistory')
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        
        _.set(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        _.set(submissionVM.value, 'lobData.personalUmbrella.lossHistory_Ext', lossHistory);
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        return newSubmissionVM;
    }

    const handleValidation = () => {
        setShowErrors(true)
        return false
    }

    const getSubTypeOptions = useCallback(async () => {
        // retrivev available condition flag from PC
        // person will only available when baseState is Ak & insured/spouse/DP over 70
        const trdPartyCondition = _.get(submissionVM, 'value.lobData.personalUmbrella.coverables.trdPartyCondition')
        if (trdPartyCondition) {
            return [
                {
                    code: 'Person',
                    name: 'Person'
                },
                {
                    code: 'IndividualOrTrust',
                    name: 'Trust'
                }
            ]
        }
        const options = [
            {
                code: 'IndividualOrTrust',
                name: 'Trust'
            }
        ]
        return options;
    }, [submissionVM]);

    const updateAdditionalInterestVM = useCallback((remainAdditionalInterests) => {
        const newVM = viewModelService.clone(submissionVM);
        _.set(newVM, 'lobData.personalUmbrella.coverables.additionalInterests', remainAdditionalInterests);
        _.set(newVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardData(newVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const removeTruteeFn = useCallback(async (trustId, selection) => {
        setLoadingMask(true);
        try {
            const remainAdditionalInterests = await PUAddiService.removeAdditionalInterestTrustee(jobID, sessionUUID, trustId, selection, authHeader);
            updateAdditionalInterestVM(remainAdditionalInterests);
        } catch (e) {
            _.noop();
        } finally {
            setLoadingMask(false);
        }
    }, [authHeader, jobID, sessionUUID, setLoadingMask, updateAdditionalInterestVM]);

    const removeAdditionalInterestFn = useCallback(async (fixedId) => {
        setLoadingMask(true);
        try {
            const remainAdditionalInterests = await PUAddiService.removeAdditionalInterest(jobID, sessionUUID, [fixedId], authHeader);
            updateAdditionalInterestVM(remainAdditionalInterests);
        } catch (e) {
            _.noop();
        } finally {
            setLoadingMask(false);
        }
    }, [authHeader, jobID, sessionUUID, setLoadingMask, updateAdditionalInterestVM]);

    const handleSaveAdditionalInterestFn = useCallback(async (getAdditionalInterestVM) => {
        const toCreateOrUpdateAdditionalInterestDTO = _.get(getAdditionalInterestVM, 'value');
        // return false;
        setLoadingMask(true);
        try {
            const remainAdditionalInterests = await PUAddiService.createOrUpdateAdditionalInterest(jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO, authHeader);
            updateAdditionalInterestVM(remainAdditionalInterests);
        } catch (e) {
            _.noop();
        } finally {
            setLoadingMask(false);
        }
    }, [authHeader, jobID, sessionUUID, setLoadingMask, updateAdditionalInterestVM])

    const getAdditionalInterestTypeOptions = useCallback(async ({
        publicID, isPerson, isCompany, isBank, isTrust
    }) => {
        let options;
        try {
            options = await PUAddiService.getAdditionalInterestType(
                jobID, sessionUUID, publicID, isPerson,
                isCompany, isBank, isTrust, authHeader
            );
        } catch (e) {
            options = [];
        }
        return options;
    }, [authHeader, jobID, sessionUUID]);

    const generateOverrides = useCallback(() => {

        const commonCoverageComponentProps = {
            selectedVersion,
            selectedVersionIndex,
            submissionVM,
            updateWizardData: updateWizardDataToDraft,
            onValidate,
            showErrors,
            updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
            isEditing,
            setIsEditing,
            lobName,
            coveragesService: PUCoveragesService,
            loadingClausesCodes,
            setLoadingLineCoverages,
        }

        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            puLineStandardCoverages: {
                ...commonCoverageComponentProps
            },
            puLineOtherOptionalCoverages: {
                ...commonCoverageComponentProps
            },
            lineExclusionsTitle: {
                visible: lineExclusions.length > 0
            },
            puLineExclusions: {
                ...commonCoverageComponentProps,
                visible: lineExclusions.length > 0
            },
            puAdditionalInterestSection: {
                value: additionalInterestsVM,
                onValidate,
                onValueChange: handleSaveAdditionalInterestFn,
                // licenseStateCode,
                // accountNumber,
                accountContacts,
                // accountHolderBirthday,
                authHeader,
                initialContext: {
                    AdditionalInterestType: additionalInterestType
                },
                readOnly: false,
                getAdditionalInterestTypeOptions,
                getSubTypeOptions,
                removeAdditionalInterestFn,
                emptyTrusteeVM: createEmtpyTrusteeVM(),
                removeTruteeFn
            }
        };
    }, [selectedVersion, selectedVersionIndex, submissionVM,
        updateWizardDataToDraft, onValidate, showErrors,
        updateErrorsAndWarningsForCovComponent, isEditing, loadingClausesCodes,
        openedAccordions, lineExclusions.length, additionalInterestsVM,
        handleSaveAdditionalInterestFn, accountContacts, authHeader,
        additionalInterestType, getAdditionalInterestTypeOptions,
        getSubTypeOptions, removeAdditionalInterestFn, createEmtpyTrusteeVM,
        removeTruteeFn
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            PULineStandardCoverages: PULineStandardCoverages,
            PULineOtherOptionalCoverages: PULineOtherOptionalCoverages,
            PULineExclusions: PULineExclusions,
            PUAdditionalInterestComponent: PUAddiComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={isEditing}
            disablePrevious={isEditing}
            disableCancel={isEditing}
            onNext={isComponentValid ? onPageNext : handleValidation}
            alwaysCallOnNext
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            <CoveragesConfigContext.Provider value={CoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
}

PUCoveragesPage.propTypes = WizardPage.propTypes;
export default PUCoveragesPage;
