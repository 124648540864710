import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMMiscellaneousFloatersService as  MiscellaneousFloatersService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMMiscellaneousFloatersPage.metadata.json5';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonClausesCard from '../../components/IMCommonClausesCard/CommonClausesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCoverageUtil from '../../util/IMCoverageUtil';
import messages from './IMMiscellaneousFloatersPage.messages';

const SCHEDULED_PROPERTY_FLOATER_ACCORDION_CARD_COMPONENT_MAPS = ['scheduledPropertyFloaterMainCovCard', 'scheduledPropertyFloaterSupplementalCovCard', 'miscFloatersScheduled', 'miscFloatersCovs']
const MiscellaneousFloatersPage = (props) => {
    const {
        wizardData: submissionVM,
        updateWizardData,
        markFollowingWizardStepsUnvisited,
        //
        isPolicyChange,
    } = props;

    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('MiscellaneousFloatersPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [miscellaneousFloatersClauses, setMiscellaneousFloatersClauses] = useState();
    const [miscellaneousFloatersLoadingClauses, setMiscellaneousFloatersLoadingClauses] = useState();

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true);
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await MiscellaneousFloatersService.getMiscellaneousFloatersClausesWithSync(jobID, sessionUUID, authHeader) 
            : await MiscellaneousFloatersService.getMiscellaneousFloatersClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setMiscellaneousFloatersClauses(res);
        setLoadingMask(false);
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    const calculateLimit = useCallback(async (termCode) => {
        setLoadingMask(true);
        const res = await MiscellaneousFloatersService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setMiscellaneousFloatersClauses(res);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 
    useEffect(() => {
        initCoverablePartClauses();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await MiscellaneousFloatersService.onMiscellaneousFloatersNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: miscellaneousFloatersClauses,
            setCoveragePartClauses: (newCoveragePart) => {
                setMiscellaneousFloatersClauses(newCoveragePart)
                updateWizardDataWhileSetPeriodStatus(submissionVM)
                markFollowingWizardStepsUnvisited()
            },
            isEditing,
            setIsEditing,
            isEditable: true,
            showErrors,
            onValidate: onValidate,
            loadingClauses: miscellaneousFloatersLoadingClauses,
            setLoadingClauses: setMiscellaneousFloatersLoadingClauses,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus,
            updateCoverageFormClausesService: MiscellaneousFloatersService.updateMiscellaneousFloatersClauses,
            calculateLimit
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: true,
            },
            scheduledPropertyFloater: {
                visible: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.visible', false),
                errorState: !_.isEmpty(_.intersection(invalidFields, SCHEDULED_PROPERTY_FLOATER_ACCORDION_CARD_COMPONENT_MAPS))
            },
            scheduledPropertyFloaterMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.mainCoverage'),
                coverageFormPath: 'scheduledPropertyFloater.mainCoverage'
            },
            scheduledPropertyFloaterSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.supplementalCoverage'),
                coverageFormPath: 'scheduledPropertyFloater.supplementalCoverage'
            },
            miscFloatersScheduled: {
                ...accordionCommonProps,
                scheduleContainer: miscellaneousFloatersClauses,
                coveragePartSchedule: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.miscFloatersScheduled'),
                updateScheduleService: MiscellaneousFloatersService.updateMiscellaneousFloatersSchedule,
                schedulePath: 'scheduledPropertyFloater.miscFloatersScheduled',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails),
                updateAdditionalIntestSchedule: MiscellaneousFloatersService.updateMiscellaneousFloatersAddiInsuredSchedule
            },
            miscFloatersCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'scheduledPropertyFloater.miscFloatersCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.miscFloatersCovs')
            }
        }
    }, [miscellaneousFloatersClauses, invalidFields, accordionCommonProps, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            ScheduledPropertyFloaterMainCovCard: IMCommonMainCoverage,
            ScheduledPropertyFloaterSupplementalCovCard: IMCommonMainCoverage,
            MiscFloatersScheduled: IMCommonCovPartSchedule,
            MiscFloatersCovs: IMCommonClausesCard
        },
    };

    const disableNavigator = isEditing || isLoadingMask || miscellaneousFloatersLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
            isPolicyChange={isPolicyChange}
        >
            {miscellaneousFloatersClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default MiscellaneousFloatersPage
