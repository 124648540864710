import React, { useCallback } from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { PAVehiclesPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeVehicleService, WniPolicyChangeDriverService } from 'wni-capability-policychange';


function VehiclesPage(props) {
    const policyChangeUpdateVehicle = useCallback(async (request, authHeader) => {
        const response = await WniPolicyChangeVehicleService.updateVehicle(
            request,
            authHeader
        );
        return response;
    }, []);

    const policyChangeUnselectUnavailableExtendedCoverage = useCallback(async (data, authHeader) => {
        WniPolicyChangeDriverService.unselectUnavailableExtendedCoverage(data, authHeader);
    }, []);

    const policyChangeGetErrorsAndWarnings = useCallback(async (jobID, isCoveragePage, authHeader, isErrorLevel = false) => {
        const response = await WniPolicyChangeVehicleService.getErrorsAndWarnings(
            jobID,
            isCoveragePage,
            authHeader,
            isErrorLevel
        );
        return response;
    }, []);

    const policyChangeGetAdditionalInterestTypeOptions = useCallback(async ({
        jobID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader
    }) => {
        const response = await WniPolicyChangeVehicleService.getAdditionalInterestTypeOptions(
            jobID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader
        );
        return response;
    }, []);

    const policyChangeCheckCanViewAndEdit = useCallback(({
        isQuoteForNamedNonOwner, sourcePolicyType, vehicleType, updateBaseOnNull, baseOnNullExt
    }) => {
        let retval = true;
        const disableNamedNoneOwnerViewAndEdit = sourcePolicyType === 'NamedNonOwner' && isQuoteForNamedNonOwner && vehicleType === 'noVehicleNamedNonOwner_Ext';
        const disableSelectViewAndEdit = sourcePolicyType !== 'NamedNonOwner' && !isQuoteForNamedNonOwner && vehicleType !== 'noVehicleNamedNonOwner_Ext' && !_.isNil(baseOnNullExt);
        if (disableNamedNoneOwnerViewAndEdit || disableSelectViewAndEdit) {
            retval = false;
        }
        updateBaseOnNull(retval);
    }, []);

    const overrideProps = {
        ...props,
        policyChangeUpdateVehicle,
        policyChangeUnselectUnavailableExtendedCoverage,
        policyChangeGetErrorsAndWarnings,
        policyChangeGetAdditionalInterestTypeOptions,
        policyChangeCheckCanViewAndEdit
    };


    return <PAVehiclesPage {...overrideProps} />;
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;
