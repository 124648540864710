import React from "react";
import { useTranslator } from '@jutro/locale';
import _ from "lodash";
import { WniInputNumber } from "wni-common-base-components";
import messages from './CRComplexSchedulePopupPropertyInfos.messages'
import CRComplexSchedulePopupPropertyInfoField from "./CRComplexSchedulePopupPropertyInfoField";


const CRComplexSchedulePopupPropertyInfos = (props) => {
    const {
        schedule,
        openedScheduleItemNumber,
        updateSubmissionVMToServer,
        onValidate,
        showErrors,
        setIsEditing,
    } = props

    const {
        propertyInfos,
        scheduleItems,
    } = schedule

    const translator = useTranslator();

    if (_.isNil(openedScheduleItemNumber)) {
        return null
    }

    const openedScheduleItemIndex = scheduleItems.findIndex((scheduleItem) => scheduleItem.itemNumber === openedScheduleItemNumber)
    const openedScheduleItem = scheduleItems[openedScheduleItemIndex]

    return <>
        <h5>{translator(messages.scheduledItemTitle)}</h5>
        <WniInputNumber
            value = {openedScheduleItemNumber}
            label = 'ScheduleNumber'
            readOnly
        />
        {propertyInfos.map((propertyInfo) =>
            <CRComplexSchedulePopupPropertyInfoField
                propertyInfo = {propertyInfo}
                openedScheduleItem = {openedScheduleItem}
                openedScheduleItemIndex = {openedScheduleItemIndex}
                updateSubmissionVMToServer = {updateSubmissionVMToServer}
                onValidate={onValidate}
                showErrors={showErrors}
                setIsEditing={setIsEditing}
            />
        )}
    </>
}

export default CRComplexSchedulePopupPropertyInfos