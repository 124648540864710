
// ==============Dobson BEGIN===================
// import ClauseComponent from 'gw-components-platform-react/Clauses/ClauseComponent';
// import ClausesComponentVM from 'gw-components-platform-react/Clauses/ClausesComponentVM';
// import SingleClauseComponentVM from 'gw-components-platform-react/Clauses/SingleClauseComponentVM';
import 'gw-components-platform-react/platform-styles.scss';
import ImageComponent from 'gw-components-platform-react/ImageComponent/ImageComponent';
import FieldLinkComponent from 'gw-components-platform-react/FieldLinkComponent/FieldLinkComponent';
// import PaymentList from 'gw-components-platform-react/PaymentList/PaymentList';
import MediaSideBar from 'gw-components-platform-react/MediaSidebarComponent/MediaSideBar';
import TypeAheadComponent from 'gw-components-platform-react/TypeAheadComponent/TypeAheadComponent';
import KnockOutPage from 'gw-components-platform-react/KnockOutPageComponent/KnockOutPage';
import CreditCardExpiryDate from 'gw-components-platform-react/CreditCardExpiryDate/CreditCardExpiryDate';
import TileComponent from 'gw-components-platform-react/TileComponent/TileComponent';
import FacebookShare from 'gw-components-platform-react/FacebookShare/FacebookShare';
// import QuoteClauseTable from 'gw-components-platform-react/QuoteClauseTable/QuoteClauseTable';
// import ScheduleItemsComponent from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemsComponent';
import CustomizedHeaderComponent from 'gw-components-platform-react/CustomizedHeaderComponent/CustomizedHeaderComponent';
// import PaymentComponent from 'gw-components-platform-react/PaymentComponent/PaymentComponent';
import UnderwritingIssues from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues';
import 'gw-components-platform-react/CustomHTMLElement/CustomHTMLElement';
// import SuccessNotification from 'gw-components-platform-react/SuccessNotification/SuccessNotification';
import uwIssuesMessages from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues.messages';
import Currency from 'gw-components-platform-react/CurrencyField/CurrencyField';
import HelpIcon from 'gw-components-platform-react/HelpIcon/HelpIcon';
import Captcha from 'gw-components-platform-react/Captcha/Captcha';


// ==============Dobson END=====================


// import HeaderLinkComponent from 'gw-components-platform-react/HeaderLinkComponent/HeaderLinkComponent';
// import 'gw-components-platform-react/platform-styles.scss';
// import ImageComponent from 'gw-components-platform-react/ImageComponent/ImageComponent';
import FaqItemComponent from 'gw-capability-faq-react/components/FaqItemComponent/FaqItemComponent';
import FaqSectionComponent from 'gw-capability-faq-react/components/FaqSectionComponent/FaqSectionComponent';
// import FieldLinkComponent from 'gw-components-platform-react/FieldLinkComponent/FieldLinkComponent';
// import MediaSideBar from 'gw-components-platform-react/MediaSidebarComponent/MediaSideBar';
// import TabSetComponent from 'gw-components-platform-react/TabSetComponent/TabSetComponent';
// import TypeAheadComponent from 'gw-components-platform-react/TypeAheadComponent/TypeAheadComponent';
// import KnockOutPage from 'gw-components-platform-react/KnockOutPageComponent/KnockOutPage';
// import TranslatedText from 'gw-components-platform-react/TranslatedText/TranslatedText';
// import CapabilityRoute from 'gw-components-platform-react/CapabilityRoute';
// import CarouselComponent from 'gw-components-platform-react/CarouselComponent/CarouselComponent';
// import CreditCardNumberComponent from 'gw-components-platform-react/CreditCardNumberComponent/CreditCardNumberComponent';
// import CreditCardExpiryDate from 'gw-components-platform-react/CreditCardExpiryDate/CreditCardExpiryDate';
// import DateRangeComponent from 'gw-components-platform-react/DateRangeComponent/DateRangeComponent';
// import TileComponent from 'gw-components-platform-react/TileComponent/TileComponent';
// import FacebookShare from 'gw-components-platform-react/FacebookShare/FacebookShare';
// import CustomizedHeaderComponent from 'gw-components-platform-react/CustomizedHeaderComponent/CustomizedHeaderComponent';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
// import EntryPageTemplate from 'gw-components-platform-react/EntryPageTemplate/EntryPageTemplate';
// import Currency from 'gw-components-platform-react/CurrencyField/CurrencyField';
// import PhoneNumber from 'gw-components-platform-react/PhoneNumber/PhoneNumber';
// import UWMessages from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues.messages';



import LinkComponent from './LinkComponent/LinkComponent';
import SuccessNotification from './SuccessNotification/SuccessNotification';
import ClauseComponent from './Clauses/ClauseComponent';
import ClausesComponentVM from './Clauses/ClausesComponentVM';
import SingleClauseComponentVM from './Clauses/SingleClauseComponentVM';
import PaymentComponent from './PaymentComponent/PaymentComponent';
import PaymentList from './PaymentList/PaymentList';
import QuestionSetComponent from './QuestionSetComponent/QuestionSetComponent';
import QuoteClauseTable from './QuoteClauseTable/QuoteClauseTable';
import ProducerUnderwritingIssues from './ProducerUnderwritingIssues/ProducerUnderwritingIssues';
import ScheduleItemsComponent from './ScheduleItemsComponent/ScheduleItemsComponent';
import InvoiceCloudPopup from './InvoiceCloudPopup/InvoiceCloudPopup';


const platformComponentMap = {
    // carousel: { component: 'carousel' },
    ClauseComponent: { component: 'ClauseComponent' },
    ClausesComponentVM: { component: 'ClausesComponentVM' },
    CreditCardExpiryDate: { component: 'CreditCardExpiryDate' },
    // CreditCardNumberComponent: { component: 'CreditCardNumberComponent' },
    // DateRangeComponent: { component: 'DateRangeComponent' },
    FacebookShare: { component: 'FacebookShare' },
    faqItem: { component: 'FaqItemComponent' },
    faqSection: { component: 'FaqSectionComponent' },
    fieldLinkComponent: { component: 'FieldLinkComponent' },
    // headerLinkComponent: { component: 'HeaderLinkComponent' },
    ImageComponent: { component: 'ImageComponent' },
    link: { component: 'link' },
    mediaSidebar: { component: 'MediaSideBar' },
    PaymentList: { component: 'PaymentList' },
    questionSet: { component: 'QuestionSetComponent' },
    QuoteClauseTable: { component: 'QuoteClauseTable' },
    ScheduleItemsComponent: { component: 'ScheduleItemsComponent' },
    CustomizedHeaderComponent: { component: 'CustomizedHeaderComponent' },
    SingleClauseComponentVM: { component: 'SingleClauseComponentVM' },
    TileComponent: { component: 'TileComponent' },
    TypeAheadComponent: { component: 'TypeAheadComponent' },
    ErrorBoundary: { component: 'ErrorBoundary' },
    PaymentComponent: { component: 'PaymentComponent' },
    ProducerUnderwritingIssues: { component: 'ProducerUnderwritingIssues' },
    UnderwritingIssues: { component: 'UnderwritingIssues' },
    // EntryPageTemplate: { component: 'EntryPageTemplate' },
    // override both to preserve consistency for metadata usage
    Currency: { component: 'Currency' },
    currency: { component: 'currency' },
    // PhoneNumber: { component: 'Currency' },
    SuccessNotification: { component: 'SuccessNotification' },
    Captcha: { component: 'Captcha' },
    InvoiceCloudPopup: { component: 'InvoiceCloudPopup' }
};

const platformComponents = {
    // carousel: CarouselComponent,
    ClauseComponent: ClauseComponent,
    ClausesComponentVM: ClausesComponentVM,
    CreditCardExpiryDate,
    // CreditCardNumberComponent,
    // DateRangeComponent: DateRangeComponent,
    FacebookShare,
    FaqItemComponent: FaqItemComponent,
    FaqSectionComponent: FaqSectionComponent,
    FieldLinkComponent: FieldLinkComponent,
    // HeaderLinkComponent: HeaderLinkComponent,
    ImageComponent,
    link: LinkComponent,
    MediaSideBar,
    PaymentList,
    QuestionSetComponent,
    QuoteClauseTable,
    ScheduleItemsComponent,
    CustomizedHeaderComponent,
    SingleClauseComponentVM,
    TileComponent,
    TypeAheadComponent,
    ErrorBoundary,
    PaymentComponent,
    ProducerUnderwritingIssues,
    // EntryPageTemplate,
    // PhoneNumber,
    SuccessNotification,
    Captcha,
    // override both to preserve consistency for metadata usage
    Currency,
    currency: Currency,
    InvoiceCloudPopup
};
export {
    // CapabilityRoute,
    // CarouselComponent,
    ClauseComponent,
    ClausesComponentVM,
    CreditCardExpiryDate,
    // CreditCardNumberComponent,
    // DateRangeComponent,
    FacebookShare,
    FaqItemComponent,
    FaqSectionComponent,
    FieldLinkComponent,
    // HeaderLinkComponent,
    ImageComponent,
    LinkComponent as Link,
    MediaSideBar,
    PaymentList,
    QuestionSetComponent,
    SingleClauseComponentVM,
    // TabSetComponent,
    TileComponent,
    TypeAheadComponent,
    // TranslatedText,
    ErrorBoundary,
    CustomizedHeaderComponent,
    PaymentComponent,
    KnockOutPage,
    UnderwritingIssues,
    ProducerUnderwritingIssues,
    // EntryPageTemplate,
    Currency,
    // PhoneNumber,
    SuccessNotification,
    // for Jutro
    platformComponentMap,
    platformComponents,
    uwIssuesMessages,
    HelpIcon,
    InvoiceCloudPopup
};
