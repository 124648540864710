import _ from 'lodash';
import { VehicleUtil, VehicleValidationUtil } from 'wni-portals-util-js';
import { InValidIconUtil } from 'wni-portals-util-react';
import customMessages from '../PAVehiclesPage.messages';

export default function PAVehicleUtil(props) {
    const {
        vehicleVMList,
        translator,
        coverageVMs,
        isQuoteForNamedNonOwner,
        validVinInfo = {},
        validationIssues = [],
        isRequiredForIssuance = false,
    } = props;

    function onVehicleValidationIconCell(warningsForInsurance, index) {
        // Reasons need to be counted (can be extended for more reasons)
        const issueReasonsMap = {
            licenseState: 'Registered State',
            owners_Ext: 'Owner(s)',
            'vehicleQuestionSets_Ext[0].answers.VehModHasSpecialEquipment_Ext': 'Is the vehicle modified or does the vehicle have any special equipment?',
            'vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext': 'Is there any existing damage to the vehicle (including damaged glass)?',
            'vehicleQuestionSets_Ext[0].answers.vehDamagesEplanation_Ext': 'Please explain the damages',
            motorHomeRented_Ext: 'Is this motorhome occasionally rented to others?',
            vehRecreationalTrailer_Ext: 'Is this recreational trailer occasionally rented to others?',
            vehRTParked_Ext: 'Is this recreational trailer parked at a single seasonal site for longer than 120 days?',
            milePercentPerYear_Ext: 'Of the total miles driven per year, what percent occurs when rented to others?',
            // customEquipments_Ext: 'Custom Equipment'
        };
        const vehicleVM = _.get(vehicleVMList, index);
        // const isRequiredForIssuance = quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote;
        const currentVehicleCoverages = VehicleUtil
            .getCurrentVehicleCoverages(coverageVMs, vehicleVM); // VMListNode
        const fieldsValid = VehicleValidationUtil.isVehicleValid(vehicleVM, currentVehicleCoverages,
            isQuoteForNamedNonOwner,
            isRequiredForIssuance, validVinInfo);
        const displayName = _.get(vehicleVM, 'displayName.value');
        // eslint-disable-next-line prefer-const
        let modifiedValidationIssues = _.cloneDeep(validationIssues);
        if (!_.isNil(validationIssues) && !_.isEmpty(validationIssues)) {
            const keys = Object.keys(issueReasonsMap);
            keys.forEach((path) => {
                const displayKey = _.get(issueReasonsMap, path);
                if (validationIssues.some((issue) => issue.type === 'error' && issue.reason.includes(displayName) && issue.reason.includes(displayKey))) {
                    let skip = false;
                    const value = _.get(vehicleVM.value, path);
                    if (path === 'vehicleQuestionSets_Ext[0].answers.vehDamagesEplanation_Ext'
                        && (_.get(vehicleVM.value, 'vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext') === false
                            || _.get(vehicleVM.value, 'vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext') === 'false'
                        )
                    ) {
                        _.remove(modifiedValidationIssues, (issue) => {
                            return issue.type === 'error' && issue.reason.includes(displayName) && issue.reason.includes(displayKey);
                        });
                        skip = true;
                    }
                    // if (path === 'customEquipments_Ext'
                    //     && (_.get(vehicleVM.value, 'vehicleQuestionSets_Ext[0].answers.VehModHasSpecialEquipment_Ext') === false
                    //         || _.get(vehicleVM.value, 'vehicleQuestionSets_Ext[0].answers.VehModHasSpecialEquipment_Ext') === 'false'
                    //     )
                    // ) {
                    //     _.remove(modifiedValidationIssues, (issue) => {
                    //         return issue.type === 'error' && issue.reason.includes(displayName) && issue.reason.includes(displayKey);
                    //     });
                    //     skip = true;
                    // }
                    if (!skip && ((!Array.isArray(value) && !_.isNil(value)) || (Array.isArray(value) && !_.isEmpty(value)))) {
                        _.remove(modifiedValidationIssues, (issue) => {
                            return issue.type === 'error' && issue.reason.includes(displayName) && issue.reason.includes(displayKey);
                        });
                    }
                }
            });
        }
        const vehicleNoError = _.isNil(modifiedValidationIssues
            .filter((issue) => issue.type === 'error')
            .find((issue) => issue.reason.includes(displayName)));
        const isErrorForInsurance = !_.isNil(warningsForInsurance
            .filter((issue) => issue.type === 'warning' && issue.isForInsurance)
            .find((issue) => issue.reason.includes(displayName)));
        let valid = fieldsValid && vehicleNoError;
        // const inValidForInsurance = valid && isErrorForInsurance;
        const vin = _.get(vehicleVM, 'vin.value');
        const vehiclesWithThisVin = vehicleVMList.filter((v) => v.value.vin === vin);
        const isMultipleVehiclesWithSameVin = !_.isNil(vin) && vehiclesWithThisVin.length > 1;

        // Implement invalid icon util
        const iconMessages = {
            validToolTip: translator(customMessages.validToolTip),
            inValidToolTip: translator(customMessages.inValidToolTip),
            inValidForInsuranceToolTip: translator(customMessages.inValidForInsuranceToolTip),
            duplicatedVinToolTip: translator(customMessages.duplicatedVinToolTip)
        };

        if (valid && isErrorForInsurance && isRequiredForIssuance) {
            valid = false;
        }
        const invalidIconUtil = InValidIconUtil({ iconMessages });
        return invalidIconUtil.renderInValidIcon(valid, !isErrorForInsurance, isMultipleVehiclesWithSameVin);

        // Archived
        // const validIcon = (
        //     <Tooltip
        //         id="validTooltip"
        //         trigger="mouseenter"
        //         content={translator(customMessages.validToolTip)}
        //     >
        //         <div>
        //             <Icon
        //                 id="checkIcon"
        //                 icon="gw-check-circle"
        //                 className={cx(styles.green)}
        //             />
        //         </div>
        //     </Tooltip>
        // );
        // const inValidForInsuranceIcon = (
        //     <Tooltip
        //         id="inValidTooltip"
        //         trigger="mouseenter"
        //         content={translator(customMessages.inValidToolTip)}
        //     >
        //         <div>
        //             <Icon
        //                 id="exclamationIcon"
        //                 icon="gw-check-circle"
        //                 className={cx(styles.yellow)}
        //             />
        //         </div>
        //     </Tooltip>
        // );
        // const inValidIcon = (
        //     <Tooltip
        //         id="inValidTooltip"
        //         trigger="mouseenter"
        //         content={translator(customMessages.inValidToolTip)}
        //     >
        //         <div>
        //             <Icon
        //                 id="exclamationIcon"
        //                 icon="gw-exclamation-triangle"
        //                 className={cx(styles.red)}
        //             />
        //         </div>
        //     </Tooltip>
        // );
        // if (isMultipleVehiclesWithSameVin) {
        //     // There are multiple vehicles with same VIN
        //     return (
        //         <Tooltip
        //             id="inValidTooltip"
        //             trigger="mouseenter"
        //             content={translator(customMessages.duplicatedVinToolTip)}
        //         >
        //             <div>
        //                 <Icon
        //                     id="exclamationIcon"
        //                     icon="gw-exclamation-triangle"
        //                     className={cx(styles.red)}
        //                 />
        //             </div>
        //         </Tooltip>
        //     );
        // }
        // if (inValidForInsurance) {
        //     return inValidForInsuranceIcon;
        // }
        // return valid ? validIcon : inValidIcon;
    }

    function isVehicleCoverageExcluded(vehicleCoverage) {
        return vehicleCoverage.coverages.filter((coverage) => coverage.name === 'Exclude Liability'
            && coverage.selected).length > 0;
    }

    // Hide assignment page if no PPA vehicles with Liability and no Named non owned vehicle
    function shouldHideAssignmentPage() {
        if (_.isEmpty(vehicleVMList)) {
            return false;
        }
        if (_.isNil(coverageVMs) || _.isEmpty(coverageVMs.value)) {
            return false;
        }
        const ppaVehicleIds = vehicleVMList
            .filter((v) => VehicleUtil.isPPAOnVehicle(v)).map((v) => v.value.publicID);
        const namedNonOwnerVehicles = vehicleVMList
            .filter((v) => VehicleUtil.isNamedNonOwnerOnVehicle(v));
        const vehicleCoverages = coverageVMs.value
            .filter((vc) => ppaVehicleIds.includes(vc.publicID));
        const ppaWithLiability = vehicleCoverages.filter((vc) => !isVehicleCoverageExcluded(vc));
        return _.isEmpty(ppaWithLiability) && _.isEmpty(namedNonOwnerVehicles);
    }

    return {
        onVehicleValidationIconCell,
        shouldHideAssignmentPage
    };
}
