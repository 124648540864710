
import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardConstants } from 'wni-portals-config-js';
import { HOAddIntService } from 'wni-capability-quoteandbind-ho';
// import { HOAdditionalInterestsPage } from 'wni-capability-quoteandbind-ho-react';
import { BaseAdditionalInterestsPageComponent } from 'wni-capability-common-react';

import WizardPage from '../../templates/HOWizardPage';
// import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

function HOAdditionalInterestsPage(props) {

    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const {
        wizardData: submissionVM,
        updateWizardPageData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus,
        },
    } = submissionVM.value;

    const initProps = {
        ...props,
        WizardPage,
        addIntService: HOAddIntService
    };

    return (
        <BaseAdditionalInterestsPageComponent {...initProps}
        />
    );

}

HOAdditionalInterestsPage.propTypes = BaseAdditionalInterestsPageComponent.propTypes;
HOAdditionalInterestsPage.defaultProps = BaseAdditionalInterestsPageComponent.defaultProps;

export default HOAdditionalInterestsPage;
