import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    WniDateUtil,
    TermTypeUtil,
    WniQuestionSetUtil,
    PolicyChangeUtil,
    WniPNIUtil,
} from 'wni-portals-util-js';
import { Loader } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniEndorsementService, WniPAPolicyChangeService } from 'wni-capability-policychange';

import {
    AgencyAndAgentInfoComponent,
} from 'wni-capability-gateway-react';
// eslint-disable-next-line import/no-unresolved
import questionSetsMetadata from 'question-sets-metadata';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import metadata from './PAPolicyDetailsChangePage.metadata.json5';


function PAPolicyDetailsChangeReadOnlyPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    // const { EndorsementService } = useDependencies('EndorsementService');
    // const { LoadSaveService } = useDependencies('LoadSaveService');
    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const {
        EndorsementService,
        LoadSaveService,
        loadingMask: { setLoadingMask },
    } = useDependencies(['EndorsementService', 'LoadSaveService', 'loadingMask']);
    
    const {
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerComponentValidation,
    } = useValidation('PAPolicyChange');
    const {
        updateWizardData,
        wizardData: submissionVM
    } = props;
    const [UWValues, updateUWValues] = useState({});
    const { authHeader } = useAuthentication();
    const [showErrors, updateShowErrors] = useState(false);
    // const [
    //     initalPolicyChangePageVisible,
    //     updateInitalPolicyChangePageVisible
    // ] = useState(!submissionVM.jobID.value);
    const [warningMessage, updateWarningMessage] = useState();
    const [showDescription, updateShowDescription] = useState('');
    const [showLoader, updateShowLoader] = useState(false);
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);
    const translator = useTranslator();

    const {
        jobID,
        policyNumber,
        baseData: {
            baseState_Ext: policyState,
            selectedVersion_Ext: selectedVersion,
        },
        originPolicyType_Ext: originPolicyType,
    } = submissionVM.value;

    const selectedOffering = _.get(submissionVM.value, 'lobData.personalAuto.offerings', [])
        .find((offering) => offering.publicID_Ext === selectedVersion);

    const isAgencyAndAgentInfoValid = useCallback(() => {
        const {
            baseData: {
                producerCode_Ext: produerCode,
                producerOrLicensedAgent_Ext: producerOrLicensedAgent,
                servicingAgent_Ext: servicingAgent,
            },
        } = submissionVM;
        if (produerCode.value
            && producerOrLicensedAgent.value
            && servicingAgent.value
        ) {
            return true;
        }
        return false;
    }, [submissionVM]);

    useEffect(() => {
        // registerComponentValidation(isAgencyAndAgentInfoValid);
        // if (!jobID) {
        //     WniEndorsementService.getStartPolicyChangeWarningMessage(policyNumber, authHeader)
        //         .then((response) => {
        //             if (response && response.length > 0) {
        //                 updateValidationIssuesWarning(response.map((message) => {
        //                     return {
        //                         type: 'warning',
        //                         reason: message
        //                     };
        //                 }));
        //             }
        //         });
        // }
    }, [
        registerComponentValidation,
        isAgencyAndAgentInfoValid,
        policyNumber,
        jobID,
        authHeader,
    ]);

    const isTermTypeOther = () => {
        // const currentTermType = _.get(submissionVM, 'baseData.termType.value');
        // return TERM_TYPE_OTHER === currentTermType.code;
    };

    const shouldShowEffDateNotice = () => {
        const currentEffectiveDate = _.get(
            submissionVM,
            'baseData.periodStartDate.value'
        );
        return WniDateUtil.isMoreThanXDaysInFuture(currentEffectiveDate, 60);
    };

    const handleAgencyChange = (value, path) => {
        if (path === 'producerCode_Ext' && !value) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
        }
        _.set(submissionVM.baseData, path, value);

        updateWizardData(submissionVM);
    };

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(
            submissionVM,
            'baseData.primaryNamedInsured_Ext.firstName.value'
        );
        const lastName = _.get(
            submissionVM,
            'baseData.primaryNamedInsured_Ext.lastName.value'
        );
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const isShowNotification = useCallback(() => {
        const policyInfoSource = _.get(
            submissionVM,
            'baseData.PolicyInfoSource_Ext'
        );
        if (policyInfoSource && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const loadCovertPolicyInfo = useCallback(() => {
        // const currentEffectiveDate = _.get(submissionVM, 'baseData.periodStartDate.value');
        // const infoDate = WniDateUtil.formatDateWithPattern(currentEffectiveDate).subtract(34, 'days');
        const covertInfo = `messages.covertedPolicyInfoStart.defaultMessage
        + infoDate + messages.covertedPolicyInfoEnd.defaultMessage`;
        return covertInfo;
    }, []);


    // const onNext = useCallback(async () => {
    //     return savePolicyChange();
    // }, [
    //     savePolicyChange
    // ]);

    const questionSetFilter = WniQuestionSetUtil.getQSFilterForPolicyDetails(policyState);
    const questionSetMapper = WniQuestionSetUtil.getQSMapperForPAPolicyChangePolicyDetails(policyState);

    const overrideProps = {
        '@all': {
            // visible: !initalPolicyChangePageVisible,
            readOnly: true
        },
        '@field': {
            labelPosition: 'left',
        },
        PAPolicyChangePage: {
            visible: true
        },
        policyDetails: {
            visible: true
        },
        effectiveDate: {
            visible: true,
            vm: submissionVM,
            minDate: _.get(submissionVM, 'value.baseData.minimumEffectiveDate'),
            maxDate: _.get(submissionVM, 'value.baseData.maximumEffectiveDate_Ext'),
            showErrors
        },
        policyEffectiveDate: {
            visible: false,
            vm: submissionVM,
            showErrors
        },
        policyExpirationDate: {
            visible: false,
            vm: submissionVM,
            showErrors
        },
        dynamicInlineNotificationContainer: {
            // validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssuesWarning),
            // visible: validationIssuesWarning.length > 0,
            // scrollToIssues: true,
            // issueRenderFn: DomRenderUtil.issueRenderFn,
            // Comply with PAPolicyDetailsPage.readonly.jsx
            visible: false,
        },
        coverageStartDate: {
            minDate: new Date(),
        },
        paTermTypedropdownselect: {
            /**
             * in policyChange, 'other' needs to be retained,
             * because the data of the policychange after pc rewrite may be 'other'.
             * so the availableValues don't need to filter
             */
            // availableValues: TermTypeUtil.getPATermTypeAvailableValues(translator)
        },
        coverageExpirationDate: {
            readOnly: !isTermTypeOther(),
        },
        primaryNamedInsuredName: {
            content: getPrimaryNamedInsuredDisplayName(),
        },
        primaryNamedInsuredAddress: {
            content: WniPNIUtil.getPrimaryAddressDisplayName(
                _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress')
            ),
        },
        primaryNamedInsuredEditIcon: {
            visible: false,
        },
        agencyAndAgentInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: true,
                licensedAgent: true,
                servicingAgent: true,
            },
            producerCodePath: 'producerCode_Ext',
            isReadOnly: true,
        },
        isForNamedNonOwner_Ext: {
            // onValueChange: handlePolicyTypeChange
            visible: originPolicyType === 'Select'
        },
        policyUnderwritingQuestionSets: {
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            isReadOnly: true,
        },
        covertNotification: {
            visible: isShowNotification(),
            message: loadCovertPolicyInfo(),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            // handleEffectiveDateChange: handleEffectiveDateChange,
            // handleDescription: handleDescription,
            // handlePolicyTypeChange
        },
        resolveComponentMap: {
            agencyagentinfo: AgencyAndAgentInfoComponent,
            validationissuescomponent: ValidationIssuesComponent,
            // paunderwritingquestionsinput: PAUnderwritingQuestionsInput,
            questionset: QuestionSetComponent,
        },
    };

    return (
        <WizardPage
            showPrevious={false}
            warningMessage={warningMessage}
            disableNext={showLoader}
            skipWhen={PolicyChangeUtil.getSkipRatedQuotedFn()}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PAPolicyDetailsChangeReadOnlyPage.propTypes = {
    ...wizardProps
};
export default PAPolicyDetailsChangeReadOnlyPage;
