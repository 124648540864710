import React from 'react'
import { CRCommercialFidelityService } from 'wni-capability-quoteandbind-cr'
import CRCoverableReadOnlyPage from '../CRCoverablePage/CRCoverablePage.readonly'


const CRCommercialFidelityReadOnlyPage = (props) => {
    return <CRCoverableReadOnlyPage
        {...props}
        initClausesFunc={CRCommercialFidelityService.getCommercialFidelity}
    />
}

export default CRCommercialFidelityReadOnlyPage