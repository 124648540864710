import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniPNIUtil } from 'wni-portals-util-js';
import metadata from './AccountContactInputComponent.person.metadata.json5';


/**
 * Account contact input component is used for personal line account contact in pni field
 * @param {object} props
 * @returns {object} React Component
 */
function PersonalAccountContactInputComponent(props) {
    const {
        model: accountContactVM,
        onValueChange: writeValue,
        showErrors,
        disablePNISwitch,
        // showEmployeeFlagToggle,
        visibleFields: {
            showMiddleName = true,
            showDateOfBirth = false,
            showPrimaryPhoneType = true,
            showPrimaryPhone = true,
            showPrimaryEmail = true,
            showAdditionalNamedInsured = false,
            showRelationshipToInsured = false
        },
        pniFirstName = '',
        pniLastName = ''
    } = props;

    const {
        firstName,
        lastName
    } = accountContactVM.value
    const PU_RELATIONSHIP_TO_INSURED_OPTIONS_PNI = ['Insured_Ext', 'Spouse_Ext', 'Partner_Ext', 'OtherFamilyMember_Ext', 'Other_Ext'];
    const PU_RELATIONSHIP_TO_INSURED_OPTIONS = ['Spouse_Ext', 'Partner_Ext', 'OtherFamilyMember_Ext', 'Other_Ext'];
    const translator = useTranslator();
    // const { authHeader, authUserData } = useAuthentication();
    // const viewModelService = useContext(ViewModelServiceContext);
    // const PNIState = _.get(accountContactVM.value, 'primaryAddress.state');
    const occupation = _.get(accountContactVM.value, 'occupation_Ext')

    // useEffect(() => {
    // }, []);

    const getPrimaryPhone = useCallback(() => {
       return WniPNIUtil.getPrimaryPhone(accountContactVM)
    }, [accountContactVM]);

    const getRelationshipToInsured = useCallback(() => {
        const Options = _.get(accountContactVM, 'relationshipToInsured_Ext.aspects.availableValues', []);
        let filteredOptions = [];
        if (firstName === pniFirstName && lastName === pniLastName) {
            filteredOptions = _.filter(Options, (item) => PU_RELATIONSHIP_TO_INSURED_OPTIONS_PNI.includes(item.code))
        } else {
            filteredOptions = _.filter(Options, (item) => PU_RELATIONSHIP_TO_INSURED_OPTIONS.includes(item.code))
        }

        const optionsToDisplay = filteredOptions.map((options) => {
            return {
                code: options.code,
                name: translator({ id: options.name })
            }
        })
        return optionsToDisplay
    }, [accountContactVM.value, firstName, lastName])
    
    const setPrimaryPhone = useCallback((value) => {
        const primaryPhoneType = _.get(accountContactVM.value, 'primaryPhoneType');
        switch (primaryPhoneType) {
            case 'mobile':
                writeValue(value, 'cellNumber');
                break;
            case 'home':
                writeValue(value, 'homeNumber');
                break;
            case 'work':
                writeValue(value, 'workNumber');
                break;
            default:
                break;
        }
    }, [accountContactVM, writeValue]);


    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        firstName: {
            disabled: disablePNISwitch,
        },
        middleName: {
            disabled: disablePNISwitch,
            visible: showMiddleName
        },
        lastName: {
            disabled: disablePNISwitch,
        },
        suffix: {
            disabled: disablePNISwitch,
        },
        dateOfBirth: {
            visible: showDateOfBirth,
            required: showDateOfBirth
        },
        primaryPhoneType: {
            visible: showPrimaryPhoneType,
            required: showPrimaryPhoneType
        },
        primaryPhone: {
            value: getPrimaryPhone(),
            onValueChange: setPrimaryPhone,
            visible: showPrimaryPhone,
            required: showPrimaryPhone
        },
        primaryEmail: {
            visible: showPrimaryEmail
        },
        // employeeFlagToggle: {
        //     visible: (PNIState!=='AK' && showEmployeeFlagToggle),
        //     label: translator(messages.employeeFlagToggle)
        // },
        occupationExplain: {
            visible: occupation === 'selfEmployed' || occupation === 'other'
        },
        relationshipToInsured: {
            availableValues: getRelationshipToInsured(),
            visible: showRelationshipToInsured,
            required: showRelationshipToInsured
        },
        additionalNamedInsured: {
            visible: showAdditionalNamedInsured,
            required: showAdditionalNamedInsured
        }
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------
    // return (
    //     <MetadataContent
    //             uiProps={metadata.componentContent}
    //             overrideProps={overrides}
    //             {...resolvers} />
    // );
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountContactVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
}

PersonalAccountContactInputComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
};

PersonalAccountContactInputComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
};

export default PersonalAccountContactInputComponent;
