import _ from 'lodash'
import { AccordionCard } from '@jutro/legacy/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect } from 'react';
import messages from './StateExclusionsAndConditionsCard.messages.js';
import CoverageContext from '../../../../context/CRCoverageContext';
import CRClausesComponentVM from '../../../../components/CRClausesComponentVM';
import CRSingleClauseComponentVM from '../../../../components/CRSingleClauseComponentVM';
import CRCoverageUtil from '../../../../util/CRCoverageUtil';
import AccordionCardTitle from '../../../../components/CRClauseAccordionCardTitle';

const StateExclusionsAndConditionsCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        stateClauses,
        setStateClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateClausesFunc,
    } = props;


    const translator = useTranslator();

    const validationID = 'StateExclusionsAndConditionsCard';
    const clauseType = 'exclusionsAndConditions';
    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    const {
        condition,
        exclusion
    } = stateClauses

    const changeExclusion = (value, changedPath) => {
        return changeClauses(value, changedPath, exclusion, 'exclusion');
    }

    const changeCondition = (value, changedPath) => {
        return changeClauses(value, changedPath, condition, 'condition');
    }

    const changeClauses = (value, changedPath, oldClauses, clausesPath) => {
        const newClauses = CRCoverageUtil.setClauseValue(oldClauses, clausesPath, value, changedPath);
        const newCoverableCoverages = _.clone(stateClauses)
        _.set(newCoverableCoverages, clausesPath, newClauses);
        setStateClauses(newCoverableCoverages);
        setIsEditing(false);
        return newCoverableCoverages;
    }

    const updateVMToServer = async (newCoveragePartClauses, setEditingFunc) => {
        const commonFormsToUpdate = CRCoverageUtil.generateUpdatedCRStateClausesDTO(newCoveragePartClauses);
        const commonFormsFromServer = await withLoadingMask(() => updateClausesFunc(
            jobID,
            sessionUUID,
            commonFormsToUpdate,
            authHeader
        ))
        setEditingFunc(false)
        setStateClauses(commonFormsFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }
    const updateNewCoveragePartClausesToServer = async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }

    const changeExclusionAndSync = (value, changedPath) => {
        const newCoveragePartClauses = changeExclusion(value, changedPath);
            
        updateNewCoveragePartClausesToServer(newCoveragePartClauses);
    }
    const changeConditionAndSync = (value, changedPath) => {
        const newCoveragePartClauses = changeCondition(value, changedPath);
        
        updateNewCoveragePartClausesToServer(newCoveragePartClauses);
    }
    const onSyncCoverages = () => {
            updateNewCoveragePartClausesToServer(stateClauses);
        }

    return (
        <AccordionCard
            id="StateExclusionsAndConditionsCard"
            initialExpanded
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.ExclusionsAndConditions)}
                    actionText={messages.AddExclusionsAndConditions}
                    onClick={() => onClickAddSearchClauses(clauseType)}
                /> : translator(messages.ExclusionsAndConditions)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: stateClauses,
                        updateClausesContainer: setStateClauses,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <CRClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeExclusionAndSync}
                        onChangeClause={changeExclusion}
                        onValidate={onValidate}
                        showAmount={false}
                        path="exclusion"
                        value={exclusion}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CRSingleClauseComponentVM: CRSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                    <CRClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeConditionAndSync}
                        onChangeClause={changeCondition}
                        onValidate={onValidate}
                        showAmount={false}
                        path="condition"
                        value={condition}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CRSingleClauseComponentVM: CRSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default StateExclusionsAndConditionsCard;
