import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
// import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class WniAccountService {
    static getCompanyIndustryCodes(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getCompanyIndustryCodes', [], additionalHeaders);
    }

    static getAgencyMatchData(agencySearchWords, limit, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAgencyMatchData', [agencySearchWords, limit], additionalHeaders);
    }

    static searchAgency(agencySearchName, agencySearchCode, limit, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'searchAgency', [agencySearchName, agencySearchCode, limit], additionalHeaders);
    }

    static getAgentStatus(publicID, userPublicId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAgentStatus', [publicID, userPublicId], additionalHeaders);
    }

    static getLicensedAgentData(agencyCode, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getLicensedAgentData', [agencyCode], additionalHeaders);
    }

    static getServicingAgentData(agencyCode, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getServicingAgentData', [agencyCode], additionalHeaders);
    }

    static getDescription(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getDescription', [], additionalHeaders);
    }

    static getReservationAccounts(account, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getReservationAccounts', [account], additionalHeaders);
    }

    static mergeAccount(sourceAccountNum, targetAccountNum, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'mergeAccount', [sourceAccountNum, targetAccountNum], additionalHeaders);
    }

    static createOrUpdateBillingContact(quoteID, sessionUUID,
        accountID, accountContactDto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'createOrUpdateBillingContact',
            [quoteID, sessionUUID, accountID, accountContactDto],
            additionalHeaders);
    }

    static getRecentlyViewedAccountsByAgency(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getRecentlyViewedAccountsByAgency', [], additionalHeaders);
    }

    static getAccountJobsForCurrentUser(noOfDays, pageSize, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAccountJobsForCurrentUser', [noOfDays, pageSize], additionalHeaders);
    }

    static getRecentlyViewedJobsForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getRecentlyViewedJobsForCurrentUser', [], additionalHeaders);
    }

    static getAvailableProducerCodesForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAvailableProducerCodesForCurrentUser', [], additionalHeaders);
    }

    static getAvailableProductsForAccount(
        accountNumber,
        state,
        country,
        effectiveDate,
        producerCode,
        producerCodePublicID_Ext,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAvailableProductsForAccount', [
                {
                    accountNumber,
                    state,
                    country,
                    effectiveDate,
                    producerCode,
                    producerCodePublicID_Ext
                }
            ], additionalHeaders);
    }

    
    static getPortalBillingDocumentsForAccount(accountNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getPortalBillingDocumentsForAccount',
            [accountNumber], additionalHeaders);
    }
    
    static getOrCreateAccount(account, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'getOrCreateAccount', [account], additionalHeaders);
    }
    
    static getAgencyInfoData(agencySearchWords, producerCodePublicID, userPublicId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAgencyInfoData', [agencySearchWords, producerCodePublicID, userPublicId], additionalHeaders);
    }

    static getAttachmentsForAccount(accountNumber, isCL = false, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'getAttachmentsForAccount', [accountNumber, isCL], additionalHeaders);
    }

    static removeAttachment(publicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'removeAttachment', [publicID], additionalHeaders);
    }

    static getDocumentsForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'getDocumentsForAccount', [accountNumber], additionalHeaders);
    }

    static getAccountClaims(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
            'getAccountClaims', [accountNumber], additionalHeaders);
    }

    static searchIndustry(industryCodeDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'searchIndustry', [industryCodeDTO], additionalHeaders);
    }

    static referToUnderwriterForAccount(accountNumber, noteForUnderwriter, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'referToUnderwriterForAccount', [accountNumber, noteForUnderwriter], additionalHeaders);
    }

    /**
     * get documents and attachments for given accountNumber
     * 
     * @param {*} accountNumber 
     * @param {*} isCL 
     * @param {*} additionalHeaders 
     * @returns 
     */
    static getDocAndAttachmentForAccount(accountNumber, isCL, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniAccount'),
        'getDocAndAttachmentForAccount', [accountNumber, isCL], additionalHeaders);
    }
}