

import React from 'react';
import { IMMotorTruckCargoPage } from 'wni-capability-quoteandbind-im-react';


const IMMotorTruckCargoChangePage = (props) => {
    return <IMMotorTruckCargoPage {...props} isPolicyChange />
};
export default IMMotorTruckCargoChangePage
