import React, { useEffect } from 'react';
import _ from 'lodash'
import HOSSPLossPayeeComponent from './HOSSPLossPayeeComponent';

function HOScheduleItemDetailsExtension(props) {

    const {
        scheduleItem,
        labelPosition,
        isEditable,
        // create loss payee
        additionalInterests = [],
        handleLossPayeeChange,
        onSaveAdditionalInterestFn,
        onGettingAdditionalInterestTypeOptions,
        accountContacts,
        isCreateAdditionalInterestPanelVisible,
        setIsCreateAdditionalInterestPanelVisible,
        // end create loss payee
    } = props;
    
    const {
        lossPayees_Ext: lossPayees,
        lossPayeeCandidate_Ext: lossPayeeCandidates,
    } = scheduleItem

    const showSSPLossPayeeComponent =
        _.get(scheduleItem, 'itemData.HOPScheduledPersonalPropertyItemHaveLossPayee_Ext.booleanValue')

    useEffect(() => {
        if (!showSSPLossPayeeComponent) {
            setIsCreateAdditionalInterestPanelVisible(false)
        }
    }, [showSSPLossPayeeComponent, setIsCreateAdditionalInterestPanelVisible])

    return <>
        {showSSPLossPayeeComponent ? <HOSSPLossPayeeComponent
            labelPosition={labelPosition}
            isEditable={isEditable}
            lossPayees={lossPayees}
            lossPayeeCandidates={lossPayeeCandidates}
            additionalInterests={additionalInterests}
            handleLossPayeeChange={handleLossPayeeChange}
            onSaveAdditionalInterestFn={onSaveAdditionalInterestFn}
            onGettingAdditionalInterestTypeOptions={onGettingAdditionalInterestTypeOptions}
            accountContacts={accountContacts}
            isCreateAdditionalInterestPanelVisible={isCreateAdditionalInterestPanelVisible}
            setIsCreateAdditionalInterestPanelVisible={setIsCreateAdditionalInterestPanelVisible}
        /> : null}
    </>
}

export default HOScheduleItemDetailsExtension;
