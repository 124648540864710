
import React from 'react';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { GLPolicyDetailsPage } from 'wni-capability-quoteandbind-gl-react';
import { CPPolicyDetailsService } from 'wni-capability-quoteandbind-cp';
// import messages from './CPRiskAnalysisPage.messages';

function CPPolicyDetailsPage(props) {

    const {
        // wizardData: submissionVM,
        policyDetailsService
    } = props;

    const CPOverrideProps = {
    }

    return (
        <GLPolicyDetailsPage
            {...props}
            policyDetailsService = {policyDetailsService}
            savePolicyDetailsData = {policyDetailsService.savePolicyDetailsData}
            CPOverrideProps = {CPOverrideProps}
            lobName = 'commercialProperty'
        />
    );

}

CPPolicyDetailsPage.propTypes = {
    ...wizardProps,
    policyDetailsService: PropTypes.object
};

CPPolicyDetailsPage.defaultProps = {
    policyDetailsService: CPPolicyDetailsService
};

export default CPPolicyDetailsPage;
