
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, withViewModelService, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DriverUtil, WniFormat } from 'wni-portals-util-js';
import metadata from './DriverLicenseInfoComponent.metadata.json5';
import message from './DriverLicenseInfoComponent.messages';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const DriverLicenseInfoComponent = (props) => {
    const {
        model: driverVM,
        onValueChange,
        showErrors,
        isReadOnly,
        baseState,
        baseData,
        onValidate,
        wizardPageData
    } = props;

    const {
        driverCovered_Ext: driverCovered,
        licensedDriver_Ext: licensedDriver,
        defensiveDriverDiscount_Ext: defensiveDriverDiscount,
        driversLicenseStatus_Ext: licenseStatus = {},
        driverUnLicensedReason_Ext: driverUnLicensedReason = {},
        driverCoveredReason_Ext: driverCoveredReason = {},
        relationshipToInsured_Ext: relationshipToInsured = {},
        additionalInsured_Ext: additionalInsured,
        isStudentAwayWithoutCarYN_Ext: isStudentAwayWithoutCarYN,
        isPNI_Ext: isPNI
        // licenseState = {}
    } = driverVM.value || {};
    const isRequiredForIssuance = DriverUtil.isRequiredForIssuance(baseData, wizardPageData);

    const translator = useTranslator();
    const [encryptSsn, updateEncryptSsn] = useState(true);

    // #210
    // useEffect(() => {
    //     if (!_.isEmpty(_.get(driverVM, 'dateOfBirth.value'))
    //          && _.get(driverVM, 'licensedDriver_Ext.value') === undefined) {
    //         _.set(driverVM, 'licensedDriver_Ext', !DriverUtil.isChild(driverVM, baseData));
    //     }
    // }, []);

    useEffect(() => {
        if (isPNI || additionalInsured) {
            _.set(driverVM, 'driverCovered_Ext', true);
        }
    }, [additionalInsured, driverVM, isPNI]);

    const insurancePolicyVisible = useCallback(() => {
        if (licenseStatus === 'valid' && licensedDriver) {
            return true;
        }
        return false;
    }, [licenseStatus, licensedDriver]);

    const GPAVisible = useCallback(() => {
        if (!DriverUtil.isChild(driverVM, baseData)
        && DriverUtil.getAge(driverVM, baseData) < 23 && driverCovered && licensedDriver) {
            return true;
        }
        return false;
    }, [baseData, driverCovered, driverVM, licensedDriver]);

    const licenseStatusVisible = useCallback(() => {
        const res = licensedDriver || (driverUnLicensedReason !== 'other'
                                        && driverUnLicensedReason !== 'neverLicensed'
                                        && driverUnLicensedReason !== 'willNeverLicensed'
                                        && !_.isEmpty(driverUnLicensedReason));
        return res;
    }, [driverUnLicensedReason, licensedDriver]);

    const handleLicensedDriver = useCallback((val) => {
        onValueChange(val, 'licensedDriver_Ext');
        if (val === false) {
            _.set(driverVM, 'driversLicenseStatus_Ext.value', null);
            // _.set(driverVM, 'driverCovered_Ext.value', null);
            _.set(driverVM, 'driverCoveredReason_Ext', null);
            if (DriverUtil.isChild(driverVM, baseData)) {
                _.set(driverVM, 'driverUnLicensedReason_Ext', 'neverLicensed');
            }
        } else {
            _.set(driverVM, 'driverUnLicensedReason_Ext', null);
        }
    }, [driverVM, onValueChange]);

    const handleDriverCovered = useCallback((val) => {
        onValueChange(val, 'driverCovered_Ext');
        if (val) {
            _.set(driverVM, 'driverCoveredReason_Ext', null);
        }
    }, [driverVM, onValueChange]);

    const handleLicenseStatus = useCallback((val) => {
        onValueChange(val, 'driversLicenseStatus_Ext');
        if (licenseStatus !== 'valid' && driverVM.value) {
            // _.set(driverVM, 'driverCovered_Ext', null);
        }
    }, [driverVM, onValueChange]);

    const getDropDownOptions = (dropPath) => {
        const options = _.get(driverVM, `${dropPath}.aspects.availableValues`);
        if (options) {
            return options.map((item) => {
                const availableValuesList = {
                    code: item.code,
                    name: translator({ id: item.name })
                };
                return availableValuesList;
            });
        }
    };

    const getDropDownValue = useCallback((dropPath) => {
        const values = _.get(driverVM, `${dropPath}.value`);
        return values ? {
            code: values.code,
            name: translator({
                id: values.name
            })
        } : values;
    }, [translator, driverVM]);

    const isGoodStudentYNVisible = useCallback(() => {
        if (DriverUtil.getAge(driverVM, baseData) === false) {
            return false;
        }
        if (baseData.baseState_Ext === 'IA' || baseData.baseState_Ext === 'SD') {
            if (DriverUtil.getAge(driverVM, baseData) > 13 && DriverUtil.getAge(driverVM, baseData) < 25) {
                return true;
            }
        }
        return !DriverUtil.isChild(driverVM, baseData)
            && DriverUtil.getAge(driverVM, baseData) < 25;
    }, [baseData, driverVM]);

    const isStudentAwayWithoutCarYNVisible = useCallback(() => {
        if (DriverUtil.getAge(driverVM, baseData) === false) {
            return false;
        }
        return !DriverUtil.isChild(driverVM, baseData) && DriverUtil.getAge(driverVM, baseData) < 23 && licenseStatus === 'valid';
    }, [baseData, driverVM, licenseStatus]);

    // POI-13979
    const isDefensiveDriverAvailable = useCallback(() => {
        let retval = true;
        // if (DriverUtil.getAge(driverVM, baseData) < 55 || driverCovered === false) {
        if (DriverUtil.getAge(driverVM, baseData) < 55 || licensedDriver === false || driverCovered === false) {
            retval = false;
        }
        return retval;
    }, [baseData, driverCovered, driverVM, licensedDriver]);

    const isDefensiveDriverDiscountAvaliableVal = isDefensiveDriverAvailable();

    useEffect(() => {
        if (!isDefensiveDriverDiscountAvaliableVal && _.get(driverVM, 'publicID')) {
            _.set(driverVM, 'defensiveDriverDiscount_Ext', false);
            _.set(driverVM, 'defDriverCourseCompDate_Ext', null);
        }
    }, [driverVM, isDefensiveDriverDiscountAvaliableVal]);

    // POI-13979 && POI-14355 && POI-18627
    const isWorkLossDiscountAvailable = useCallback(() => {
        let retval = true;
        if (DriverUtil.getAge(driverVM, baseData) < 60
        || licensedDriver === false
        || driverCovered === false
        || ((
            relationshipToInsured === 'Partner_Ext'
            || relationshipToInsured === 'Other_Ext'
            || relationshipToInsured === 'Employee_Ext'
        ) && !_.get(driverVM, 'value.additionalInsured_Ext'))) {
            retval = false;
        }
        if (_.get(baseData, 'baseState_Ext') !== 'MN') {
            retval = false;
        }
        return retval;
    }, [baseData, driverCovered, driverVM, licensedDriver, relationshipToInsured]);

    const isWorkLossDiscountAvailableVal = isWorkLossDiscountAvailable();

    useEffect(() => {
        if (!isWorkLossDiscountAvailableVal && _.get(driverVM, 'publicID')) {
            _.set(driverVM, 'workLossDiscount_Ext', false);
        }
    }, [driverVM, isWorkLossDiscountAvailableVal]);

    const encryptSSN = useCallback(() => {
        let resSSN = '';
        let ssnNum = '';
        if (_.get(driverVM, 'value.person.ssnOfficialID_Ext')) {
            ssnNum = _.get(driverVM, 'value.person.ssnOfficialID_Ext').replace(/[^0-9]/ig, '');
        }
        const len = ssnNum.length;
        for (let i = 0; i < len; i++) {
            resSSN += '*';
        }
        return resSSN;
    }, [driverVM]);

    const handleLicenseNumber = useCallback((val) => {
        onValueChange(val, 'licenseNumber');
        _.set(driverVM, 'licenseNumberChanged_Ext', true);
    });

    const handleAgeFirstLicensed = useCallback((val) => {
        onValueChange(val, 'ageFirstLicensed_Ext');
        const ageVal = DriverUtil.getAge(driverVM, baseData);
        DriverUtil.setInexperiencedDriver(ageVal, driverVM);
    }, [baseData, driverVM, onValueChange]);

    const getPlaceholder = (path) => {
        return WniFormat.placeholderFormat(driverVM, path);
    };

    const licenseStatusDefaultValue = useCallback(() => {
        if (!_.get(driverVM, 'value.isAutoPrefillData_Ext')) {
            return 'valid';
        }
    }, []);

    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        driverUnLicensedReason: {
            visible: licensedDriver === false,
            tooltip: (driverUnLicensedReason === 'permitonly' || driverUnLicensedReason === 'idOnly')
                ? translator(message.unLicensedReasonHelpText) : '',
            ...getPlaceholder('driverUnLicensedReason_Ext')
        },
        driverCoveredReason: {
            visible: driverCovered === false && licensedDriver,
            availableValues: DriverUtil.getAvailableCoveredReason(
                _.get(driverVM, 'driverCoveredReason_Ext.aspects.availableValues'), baseData.baseState_Ext, translator
            ),
            ...getPlaceholder('driverCoveredReason_Ext')
        },
        driverUnlicensedExplain: {
            visible: driverUnLicensedReason === 'other',
            ...getPlaceholder('driverUnlicensedExplain_Ext')
        },
        ageFirstLicensed_Ext: {
            ...getPlaceholder('driverUnlicensedExplain_Ext')
        },
        driverCoveredExplain: {
            visible: driverCoveredReason === 'other' && licensedDriver,
            ...getPlaceholder('driverCoveredExplain_Ext')
        },
        driverSSN: {
            visible: relationshipToInsured === 'Insured_Ext' || additionalInsured === true,
            mask: isReadOnly ? '***-**-****' : '999-99-9999',
            onFocus: () => updateEncryptSsn(false),
            onBlur: () => updateEncryptSsn(true)
        },
        isGoodStudentYN: {
            visible: isGoodStudentYNVisible() && licensedDriver === true,
            disabled: driverCovered === false,
            defaultValue: false
        },
        isStudentAwayWithoutCarYN: {
            visible: isStudentAwayWithoutCarYNVisible(),
            disabled: driverCovered === false,
            defaultValue: false
        },
        nameOfSchool: {
            visible: GPAVisible() && isStudentAwayWithoutCarYN,
            ...getPlaceholder('schoolName_Ext')
        },
        driverDefensiveDriverDiscount: {
            visible: isDefensiveDriverAvailable(),
            defaultValue: false
        },
        driverCourseCompletionDate: {
            visible: Boolean(isDefensiveDriverAvailable() && defensiveDriverDiscount),
            value: _.get(driverVM.value, 'defDriverCourseCompDate_Ext'),
            onValueChange,
            showErrors
        },
        driverWorkLossDiscount: {
            visible: isWorkLossDiscountAvailableVal,
            defaultValue: false
        },
        driverDriverLicenseStatus: {
            visible: licensedDriver === true,
            onValueChange: handleLicenseStatus,
            defaultValue: licenseStatusDefaultValue(),
            required: isRequiredForIssuance && licensedDriver === true,
            ...getPlaceholder('driversLicenseStatus_Ext')
        },
        driverLicenseState: {
            // POI-12418
            visible: licenseStatusVisible(),
            availableValues: getDropDownOptions('licenseState'),
            // value: getDropDownValue('licenseState'),
            required: isRequiredForIssuance && licensedDriver === true,
            defaultValue: baseState,
            ...getPlaceholder('licenseState')
        },
        driverDriverLicenseNumber: {
            visible: licenseStatusVisible(),
            required: isRequiredForIssuance && licensedDriver === true,
            onValueChange: handleLicenseNumber,
            ...getPlaceholder('licenseNumber')
        },
        driverAgeFirstLicensed: {
            visible: licensedDriver
                    || driverUnLicensedReason === 'expired'
                    || driverUnLicensedReason === 'cancelled'
                    || driverUnLicensedReason === 'surrendered'
                    || driverUnLicensedReason === 'revokedsuspended',
            ...getPlaceholder('ageFirstLicensed_Ext'),
            onValueChange: handleAgeFirstLicensed
        },
        driverCovered: {
            visible: insurancePolicyVisible(),
            onValueChange: handleDriverCovered,
            disabled: _.get(driverVM, 'value.isPNI_Ext') || _.get(driverVM, 'value.additionalInsured_Ext')
        },
        licensedDriver: {
            onValueChange: handleLicensedDriver,
        },
        exploreInsIndicator: {
            readOnly: true,
            visible: !licensedDriver && driverUnLicensedReason !== 'other'
        },
        exploreMonitoringProducts: {
            readOnly: true,
            visible: !licensedDriver && driverUnLicensedReason !== 'other'
        }
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        if (path === 'person.ssnOfficialID_Ext' && encryptSsn) {
            return encryptSSN();
        }
        return readViewModelValue(
            metadata.componentContent,
            driverVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

DriverLicenseInfoComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    baseState: PropTypes.string,
    baseData: PropTypes.shape({}),
    wizardPageData: PropTypes.shape({})
};

DriverLicenseInfoComponent.defaultProps = {
    model: {},
    onValueChange: undefined,
    showErrors: false,
    isReadOnly: false,
    baseState: undefined,
    baseData: {},
    wizardPageData: {}
};

export default DriverLicenseInfoComponent;
