import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imPhysiciansAndSurgeons'), method, data, additionalHeaders);
}

export default class IMPhysiciansAndSurgeonsService {

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.getPhysiciansAndSurgeonsClausesWithSync()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getPhysiciansAndSurgeonsClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getPhysiciansAndSurgeonsClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.getPhysiciansAndSurgeonsClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getPhysiciansAndSurgeonsClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getPhysiciansAndSurgeonsClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.updatePhysiciansAndSurgeonsClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} accountsReceivableToUpdate
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updatePhysiciansAndSurgeonsClauses(jobNumber, sessionUUID, physiciansAndSurgeonsToUpdate, authHeader = {}) {
        return processSubmission('updatePhysiciansAndSurgeonsClauses', [jobNumber, sessionUUID, physiciansAndSurgeonsToUpdate], authHeader);
    }

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.updateCoveragePartSchedule()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.onPhysiciansAndSurgeonsNext()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static onPhysiciansAndSurgeonsNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onPhysiciansAndSurgeonsNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.searchClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMPhysiciansAndSurgeonsHandler.addSearchedClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} clausesToAddData
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}