
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLQualificationPage } from 'wni-capability-quoteandbind-gl-react';

function GLQualificationChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLQualificationPage {...props} />
    );

}

GLQualificationChangePage.propTypes = GLQualificationPage.propTypes;
GLQualificationChangePage.defaultProps = GLQualificationPage.defaultProps;

export default GLQualificationChangePage;