import React, {useState} from "react"
import _ from "lodash";
import { DropdownMenuButton, ToggleField } from "@jutro/components";
import { DisplayColumn, Table } from '@jutro/legacy/datatable';

import { useTranslator } from '@jutro/locale';
import SinglePedestrian from "./SinglePedestrian/SinglePedestrian";
import messages from './Pedestrians.messages'
import { getNewContactTempId } from "../util/NewContactUtil";

import { Button } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

const PedestrianQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const pedestrianInitValue = {
}

const Pedestrians = (props) => {
    const {
        pedestrians = [],
        updatePedestrians,
        relatedContacts = [],
        filteredRelatedContacts = [],
        updateRelatedContact,
    } = props;

    const translator = useTranslator();

    const relatedPersonContacts = relatedContacts.filter((contact) => ['UserContact', 'Person'].includes(contact.subtype))

    const [pedestrianQuestionValue, setPedestrianQuestion] = useState(() => {
        return pedestrians.length > 0 ? PedestrianQuestionValue.Yes : PedestrianQuestionValue.No
    })
    const [singlePedestrianPopupStatus, setSinglePedestrianPopupStatus] = useState({
        isOpen: false,
        originPedestrian: pedestrianInitValue,
        editingPedestrianIndex: undefined
    })

    const closePopup = () => {
        setSinglePedestrianPopupStatus({
            isOpen: false,
            originPedestrian: pedestrianInitValue,
            editingPedestrianIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = async (pedestrian, editingPedestrianIndex, person) => {

        const isNewPerson = !relatedPersonContacts.some((contact) => contact.publicID === person.publicID)

        let updatedPedestrian = pedestrian
        let updatedPerson = person
        if (isNewPerson) {
            const tempContactID = getNewContactTempId()
            updatedPedestrian = {...pedestrian, personPublicID: tempContactID}
            updatedPerson = {...person, publicID: tempContactID}
        }
        let updatedPedestrains
        if (_.isNil(editingPedestrianIndex)) {
            // new added item
            updatedPedestrains = pedestrians.concat([updatedPedestrian])
        } else {
            // existing item
            updatedPedestrains = pedestrians.toSpliced(editingPedestrianIndex, 1, updatedPedestrian)
        }
        await updateRelatedContact(updatedPerson)
        updatePedestrians(updatedPedestrains, updatedPerson)
        closePopup()
    }

    return <>
        <ToggleField
            id='pedestrianQuestion'
            label={translator(messages.PedestrianQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: PedestrianQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: PedestrianQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setPedestrianQuestion(value)
            }}
            disabled={pedestrians.length > 0}
            value={pedestrianQuestionValue}
        />
        {pedestrianQuestionValue === PedestrianQuestionValue.Yes &&
            <>
                <Table
                    data={pedestrians.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                >
                    <DisplayColumn
                        id="pedestrianSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="pedestrianLastNameColumn"
                        header={translator(messages.PedestrianLastName)}
                        textAlign='center'
                        renderCell={(item) => {
                            const {
                                personPublicID
                            } = item
                            const pedestrian = relatedPersonContacts.find((contact) => contact.publicID === personPublicID)
                            return _.get(pedestrian, 'lastName')
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <DropdownMenuButton
                                id={`DropdownMenuBtn_${index}`}
                                icon="gw-expand-more"
                                size="small"
                                iconPosition="right"
                                disabled={singlePedestrianPopupStatus.isOpen}
                            >
                                <DropdownMenuLink
                                    icon="gw-edit"
                                    iconPosition="left"
                                    onClick={() => setSinglePedestrianPopupStatus({
                                        isOpen: true,
                                        originPedestrian: item,
                                        editingPedestrianIndex: index
                                    })}
                                >
                                    {translator(messages.Edit)}
                                </DropdownMenuLink>
                                <DropdownMenuLink
                                    icon="gw-delete"
                                    iconPosition="left"
                                    onClick={() => {
                                        const updatedPedestrains = pedestrians.toSpliced(index, 1)
                                        updatePedestrians(updatedPedestrains)
                                    }}
                                >
                                    {translator(messages.Delete)}
                                </DropdownMenuLink>
                            </DropdownMenuButton>
                        }}
                    />
                </Table>
                <Button
                    type="outlined"
                    onClick={() => setSinglePedestrianPopupStatus({
                        isOpen: true,
                        originPedestrian: pedestrianInitValue
                    })}
                    disabled={singlePedestrianPopupStatus.isOpen}
                >
                    {translator(messages.AddPedestrian)}
                </Button>
                {singlePedestrianPopupStatus.isOpen && <SinglePedestrian
                    originPedestrian={singlePedestrianPopupStatus.originPedestrian}
                    editingPedestrianIndex={singlePedestrianPopupStatus.editingPedestrianIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                    relatedPersonContacts={relatedPersonContacts}
                    filteredRelatedContacts={filteredRelatedContacts}
                />}
            </>
        }
        
    </>
}

export default Pedestrians
