import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniCommonQuote'), method, data, additionalHeaders);
}

export default class WniCommonQuoteService {
    /**
     * Invokes CommonQuoteHandler.isUwLocked()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static isUwLocked(quoteID, sessionID, authHeader = {}) {
        return processSubmission('isUwLocked', [quoteID, sessionID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.getErrorsAndWarnings()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {string} flowStepID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static getErrorsAndWarnings(quoteID, sessionID, flowStepID, authHeader = {}) {
        return processSubmission('getErrorsAndWarnings', [quoteID, sessionID, flowStepID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.updateSelectedVersion()
     * 
     * This method is the same with updateCurrentBranch() in terms of updating selectedVersion,
     * but is heavyweight by responding with QuoteDataDTO
     * 
     * @deprecated reserved for backward compatibility of PA
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {string} selectedBranch
     * @param {object} authHeader
     * @returns {object} the new QuoteDataDTO
     */
    static updateSelectedVersion(quoteID, sessionID, selectedBranch, authHeader = {}) {
        return processSubmission('updateSelectedVersion', [quoteID, sessionID, selectedBranch], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.updateCurrentBranch()
     * 
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {string} periodPublicID
     * @param {object} authHeader
     * @returns {string} 'success' or 'failure'
     */
    static updateCurrentBranch(quoteID, sessionID, periodPublicID, authHeader = {}) {
        return processSubmission('updateCurrentBranch', [quoteID, sessionID, periodPublicID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.updateCurrentBranchAndRefreshWizardData()
     * 
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {string} periodPublicID
     * @param {object} authHeader
     * @returns {object} the new WizardDataDTO
     */
    static updateCurrentBranchAndRefreshWizardData(quoteID, sessionID, periodPublicID, authHeader = {}) {
        return processSubmission('updateCurrentBranchAndRefreshWizardData', [quoteID, sessionID, periodPublicID], authHeader);
    }
    

    // /**
    //  * Invokes CommonQuoteHandler.getQuotePriceViewMode()
    //  * @param {string} quoteID
    //  * @param {string} sessionID
    //  * @param {object} authHeader
    //  * @returns {Promise}
    //  */
    // static getQuotePriceViewMode(quoteID, sessionID, authHeader = {}) {
    //     return processSubmission('getQuotePriceViewMode', [quoteID, sessionID], authHeader);
    // }

    // /**
    //  * Invokes CommonQuoteHandler.updateQuotePriceViewMode()
    //  * @param {string} quoteID
    //  * @param {string} sessionID
    //  * @param {string} priceViewMode
    //  * @param {object} authHeader
    //  * @returns {Promise}
    //  */
    // static updateQuotePriceViewMode(quoteID, sessionID, priceViewMode, authHeader = {}) {
    //     return processSubmission('updateQuotePriceViewMode', [quoteID, sessionID, priceViewMode], authHeader);
    // }


    /**
     * Invokes CommonQuoteHandler.loadWizardExitData()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static loadWizardExitData(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('loadWizardExitData', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.loadWizardExitDataWithActiveQuotes()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static loadWizardExitDataWithActiveQuotes(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('loadWizardExitDataWithActiveQuotes', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.loadWizardExitDataR3()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static loadWizardExitDataR3(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('loadWizardExitDataR3', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.getActiveQuotes()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static getActiveQuotes(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getActiveQuotes', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.saveWizardExitData()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} wizardExitData instance of EdgeJobDataDTO
     * @param {object} authHeader
     * @returns {Promise}
     */
    static saveWizardExitData(quoteID, sessionUUID, wizardExitData, authHeader = {}) {
        return processSubmission('saveWizardExitData', [quoteID, sessionUUID, wizardExitData], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.retrieveSubmissionWizardData()
     * @param {string} quoteRetrievalDTO
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveSubmissionWizardData(quoteRetrievalDTO, authHeader) {
        return processSubmission('retrieveSubmissionWizardData', [quoteRetrievalDTO], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.retrieveSubmissionWizardDataAndWarmUp()
     * @param {string} quoteRetrievalDTO
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveSubmissionWizardDataAndWarmUp(quoteRetrievalDTO, authHeader) {
        return processSubmission('retrieveSubmissionWizardDataAndWarmUp', [quoteRetrievalDTO], authHeader);
    }

    /**
     * Invokes CommonQuoteHandler.refreshSubmissionWizardData()
     * 
     * This method differs from retrieveSubmissionWizardData() by requiring sessionID in param
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static refreshSubmissionWizardData(jobID, sessionUUID, authHeader) {
        return processSubmission('refreshSubmissionWizardData', [jobID, sessionUUID], authHeader);
    }


    /**
     * Invokes CommonQuoteHandler.generateNewWizardSessionID()
     * @param {string} quoteRetrievalDTO
     * @param {object} authHeader
     * @returns {Promise}
     */
    static generateNewWizardSessionID(quoteRetrievalDTO, authHeader) {
        return processSubmission('generateNewWizardSessionID', [quoteRetrievalDTO], authHeader);
    }
}
