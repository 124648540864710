import _ from 'lodash';
import { WniProductsUtil } from 'wni-portals-util-js';

function formatLocalDate(localDate) {
    const year = _.get(localDate, 'year');
    const month = _.get(localDate, 'month') + 1;
    const day = _.get(localDate, 'day');
    return new Date(`${year}-${month}-${day}`);
}

function filterBoundPeriods(periods, jobType) {
    return _.filter(periods, (period) => {
        return period.jobType_Ext === jobType && period.status === 'Bound';
    });
}

// function getCLEarliestEffDate(policyData) {
//     const periodEffDate = formatLocalDate(_.get(policyData, 'latestPeriod.effectiveDate_Ext'));
//     const today = new Date()
//     const backDate7Days = new Date(today.setDate(today.getDate() - 7));
//     let earliestCancellationDate;
//     earliestCancellationDate = periodEffDate.setDate(periodEffDate.getDate() + 1);
//     if (periodEffDate.getTime() < backDate7Days.getTime()) {
//         earliestCancellationDate = backDate7Days.setDate(backDate7Days.getDate());
//     }
//     return earliestCancellationDate;
// }
function getCLEarliestEffDate(policyData, sysLocalDate) {
    const periodEffDate = formatLocalDate(_.get(policyData, 'latestPeriod.effectiveDate_Ext')).getTime();
    const today = formatLocalDate(sysLocalDate)
    const backDate7Days = new Date(today.setDate(today.getDate() - 7)).getTime();
    if (periodEffDate < backDate7Days) {
        return new Date(backDate7Days);
    }
    return new Date(periodEffDate);
}

function updateCancellationDate(policyData, cancellationSubmissionVM, sysLocalDate) {
    const productCode = _.get(policyData, 'product.productCode');
    const isCL = WniProductsUtil.isCLProduct(productCode);
    const periods = _.get(policyData, 'periods');
    const PNIAddress = _.get(policyData, 'account.accountHolder.primaryAddress.displayName');
    let earliestCancellationDate;
    if (filterBoundPeriods(periods, 'Renewal').length > 0) {
        const submission = filterBoundPeriods(periods, 'Submission')[0]
        earliestCancellationDate = formatLocalDate(_.get(submission, 'effectiveDate_Ext'));
    } else {
        earliestCancellationDate = formatLocalDate(_.get(policyData, 'latestPeriod.effectiveDate_Ext'));
    }
    // earliestCancellationDate.setDate(earliestCancellationDate.getDate() + 1);
    earliestCancellationDate.setDate(earliestCancellationDate.getDate());
    earliestCancellationDate.setHours(0, 0, 0, 0);
    const expirationDate = formatLocalDate(_.get(policyData, 'latestPeriod.expirationDate_Ext'));
    expirationDate.setDate(expirationDate.getDate() - 1);
    expirationDate.setHours(0, 0, 0, 0);
    _.set(cancellationSubmissionVM, 'earliestCancellationDate', earliestCancellationDate);
    _.set(cancellationSubmissionVM, 'actualExpirationDate', expirationDate);
    _.set(cancellationSubmissionVM, 'mailingAddress', PNIAddress);
    _.set(cancellationSubmissionVM, 'isChangeMailingAddress', false);

    if (isCL) {
        _.set(cancellationSubmissionVM, 'earliestCancellationDate', getCLEarliestEffDate(policyData, sysLocalDate));
    }
    return cancellationSubmissionVM;
}


export default {
    updateCancellationDate,
    formatLocalDate
};
