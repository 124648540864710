import React, {
    useContext, useEffect,
} from "react";
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator, IntlContext } from '@jutro/locale';
import { WniCheckboxField, WniDropdownSelect } from "wni-common-base-components";
import useSinglePolicyContactRoleClause from "../hooks/useSinglePolicyContactRoleClause";
import styles from './SingleOperatorClause.module.scss';

const SingleOperatorTerm = ({
    termCode,
    termInfo,
    setTermCode,
    isEditable = true,
    className,
    onValidate,
    showErrors,
    hideLabel = false
}) => {

    useEffect(() => {
        if (onValidate) {
            onValidate(!_.isNil(termInfo.selectedTypeCode), termCode);
        }
        return () => {
            if (onValidate) {
                onValidate(true, termCode);
            }
        }
    }, [onValidate, termCode, termInfo.selectedTypeCode])

    return <WniDropdownSelect
        key={termCode}
        id={`${termCode}_DropdownSelect`}
        label = {termInfo.name}
        placeholder=""
        size="medium"
        className={className}
        value={termInfo.selectedTypeCode}
        availableValues={termInfo.options}
        onValueChange={(code) => setTermCode(code, termCode)}
        readOnly={!isEditable}
        required
        showErrors={showErrors}
        hideLabel={hideLabel}
    />
}

function SingleOperatorClause(props) {

    const {
        clauseCode,
        limitScheduleItemTermCode,
        subScheduleTermsCodes,
        label,
        isEditable,
        policyContactRole,
        submissionVM,
        updateWizardData,
        lineCoveragePath,
        updateSubmissionVMToServer,
        onValidate,
        showErrors,
    } = props

    const translator = useTranslator();

    const [
        operatorCovSelected,
        setOperatorCovSelected,
        lmtScheduleItemTermInfo,
        subTermsInfo,
        setTermCode,
    ] = useSinglePolicyContactRoleClause({
        clauseCode: clauseCode,
        limitScheduleItemTermCode,
        subScheduleTermsCodes,
        policyContactRole,
        submissionVM,
        updateWizardData,
        lineCoveragePath,
        updateSubmissionVMToServer,
    })

    const subTerms = subScheduleTermsCodes.length > 0
        && (operatorCovSelected ? <div className="clause_element_container">
            {Object.entries(subTermsInfo)
                .map(([termCode, termInfo]) => <SingleOperatorTerm
                termCode={termCode}
                termInfo={termInfo}
                setTermCode={setTermCode}
                isEditable={isEditable}
                onValidate={onValidate}
                showErrors={showErrors}
            />)}
        </div> : null)

    if (!isEditable) {
        if (!operatorCovSelected) {
            return null
        }
        return <>
            <div className={`${styles.readOnlyContainer} flex-1 d-flex`}>
                <span className='flex-1'>
                    {_.isString(label) ? label : translator(label)}
                </span>
                {limitScheduleItemTermCode && <WniDropdownSelect
                    hideLabel
                    id={`${limitScheduleItemTermCode}_DropdownSelect`}
                    placeholder=""
                    size="medium"
                    className='flex-1'
                    value={lmtScheduleItemTermInfo.selectedTypeCode}
                    availableValues={lmtScheduleItemTermInfo.options}
                    readOnly
                />}
            </div>
            {subTerms}
        </>
    }

    return <>
        <div className='d-flex wni-gap-8px'>
            <WniCheckboxField
                id= {`${clauseCode}_Checkbox`}
                label= {label}
                value= {operatorCovSelected}
                onValueChange= {setOperatorCovSelected}
                className= 'flex-1'
                checked ={operatorCovSelected}
                showInlineLabel
                layout= "full-width"
            />
            {limitScheduleItemTermCode && (operatorCovSelected ? <SingleOperatorTerm
                termCode={limitScheduleItemTermCode}
                termInfo={lmtScheduleItemTermInfo}
                setTermCode={setTermCode}
                onValidate={onValidate}
                showErrors={showErrors}
                className='flex-1'
                isEditable
                hideLabel
            /> : <WniDropdownSelect
                disabled
                hideLabel
                id={`${clauseCode}_disabledDropdownselect`}
                placeholder=""
                size="medium"
                className='flex-1'
            />)}
        </div>
        {subTerms}
    </>
}

SingleOperatorClause.propTypes = {
    clauseCode: PropTypes.string.isRequired,
    limitScheduleItemTermCode: PropTypes.string,
    subScheduleTermsCodes: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string,
        }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    operator: PropTypes.shape({
        publicID: PropTypes.string.isRequired,
    }).isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    lineCoveragePath: PropTypes.string.isRequired,
    updateSubmissionVMToServer: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
};
SingleOperatorClause.defaultProps = {
    limitScheduleItemTermCode: undefined,
    subScheduleTermsCodes: [],
    isEditable: true,
    onValidate: _.noop,
    showErrors: false
}

export default SingleOperatorClause;