import SRELandingPage from 'gw-capability-gateway-react/SRELandingPage/SRELandingPage';
import { ClaimsLanding } from 'gw-capability-gateway-claim-react';
import Analytics from 'gw-capability-gateway-react/Analytics/Analytics';
import { ClaimDetails } from 'gw-capability-claim-react';
import SearchResults from 'gw-capability-gateway-react/SearchResults/SearchResults';
import Preferences from 'gw-pages-platform-react/pages/Preferences/Preferences';
import MakePayment from 'gw-capability-gateway-react/Accounts/BillingAndPayment/MakePayment/MakePayment';

import PaymentConfirmation from 'gw-capability-gateway-react/Accounts/BillingAndPayment/PaymentConfirmation/PaymentConfirmation';
import SuccessNotification from 'gw-components-platform-react/SuccessNotification/SuccessNotification';
import ContactRoles from 'gw-capability-gateway-react/Components/ContactRoles/ContactRoles';

import Activities from './Activities/Activities';
import Accounts from './Accounts/Accounts';
import Commission from './Accounts/Commission/AccountsCommission';
import Cancellation from './Cancellation/Cancellation';
import LandingPage from './LandingPage/LandingPage';
import Endorsement from './Endorsement/Endorsement';
import Policies from './Policies/Policies';
import PolicyDetails from './Policies/PolicyDetails/PolicyDetails';
import QuoteDetails from './QuoteDetails/QuoteDetails';
import Rewrite from './Rewrite/Rewrite';
import Renewal from './Renewal/Renewal';
import Statements from './Statements/Statements';
import NewQuoteAccountSearch from './NewQuote/NewQuoteAccountSearch'
import NewQuotePage from './NewQuote/NewQuote/NewQuotePage';
import PageHeaderComponent from './Components/PageHeader/PageHeaderComponent';
import DocumentsComponent from './Components/DocumentsComponent/DocumentsComponent';
import MenuBarComponent from './Components/MenuBar/MenuBarComponent';
import { FormattedHeaderComponent, PageHeaderComponent as FormatPageHeaderComponent } from './Components/FormattedHeaderComponent/FormattedHeaderComponent';

import AccountBillingAndPayment from './Accounts/BillingAndPayment/BillingAndPayment';
import AccountDetails from './Accounts/AccountDetails/AccountDetails';

import BackLinkComponent from './Components/BackLinkComponent/BackLinkComponent';
import ContactUnderwriter from './ContactUnderwriter/ContactUnderwriter';

const producerEngageComponentMap = {
    ContactRoles: { component: 'ContactRoles' },
};
// 'gw-capability-gateway-react
const producerEngageAdditionalComponents = {
    ContactRoles,
};

export { default as claimsMessages } from './Claims/Claims.messages';
export { default as summaryMessages } from 'gw-capability-gateway-react/Endorsement/Summary/Summary.messages';
export { default as accountMessages } from 'gw-capability-gateway-react/Accounts/Accounts.messages';
export { default as gatewayMessages } from 'gw-capability-gateway-react/gateway.messages';
export * from 'gw-gateway-common-react/components/SelectProducerCode/SelectProducerCode';

export {
    LandingPage as default,
    SRELandingPage,
    Accounts,
    Policies,
    ClaimsLanding,
    Activities,
    Analytics,
    Commission,
    AccountDetails,
    PolicyDetails,
    ClaimDetails,
    NewQuoteAccountSearch,
    NewQuotePage,
    SearchResults,
    Preferences,
    QuoteDetails,
    Rewrite,
    Renewal,
    Endorsement,
    Cancellation,
    AccountBillingAndPayment,
    MakePayment,
    ContactUnderwriter,
    PaymentConfirmation,
    DocumentsComponent,
    FormattedHeaderComponent,
    FormatPageHeaderComponent,
    SuccessNotification,
    MenuBarComponent,
    PageHeaderComponent,
    ContactRoles,
    producerEngageComponentMap,
    producerEngageAdditionalComponents,
    BackLinkComponent,
    Statements
};

export { default as AutomaticPaymentPolicyAffectedValueIterableComponent } from 'gw-capability-gateway-react/Accounts/BillingAndPayment/AutomaticPaymentPolicyAffectedValueIterableComponent';
export { default as UWUtil } from './Components/UnderwritingComponent/UWUtil';
