
import { defineMessages } from 'react-intl';

export default defineMessages({
    Coverages: {
        "id": "quoteandbind.cr.wizard.step.CommercialCrime Page.Coverages",
        "defaultMessage": "Coverages"
    },
    AddCoverages: {
        "id": "quoteandbind.cr.wizard.step.CommercialCrime page.Add Coverages",
        "defaultMessage": "Add Coverages"
    },
});