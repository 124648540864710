import { root } from 'cheerio';
import _ from 'lodash';

/**
 * Scroll window view to the specific element
 *
 * //
 * Note: 2021-04-27 (Tue)
 * Strange, Looks like .scrollIntoView() works on <div /> but
 * not on <input type="checkbox" />.
 * @param {string} elementId
 * @param {object} optConfig
 */
function scrollTo(elementId, optConfig = {}) {
    const {
        focus = false,
    } = optConfig;
    const ele = document.getElementById(elementId);
    if (ele) {
        ele.scrollIntoView();
        if (focus && ele.focus) {
            ele.focus();
        }
    }
}

/**
 * Scroll to top of window
 * ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
 */
function scrollToTop() {
    window.scrollTo({
        top: 0,
    });
}
function getFieldDomWithParent(parentsId, id) {
    const firstEle = document.querySelector(`#${parentsId} #${id}`) || document.querySelector(`#${parentsId} input[id^="${id}"]`);
    const firstEleLabel = document.querySelector(`#${parentsId} label[for="${id}"]`);
    const element = firstEle || firstEleLabel;
    const closestParent = element && element.closest('.jut__FieldComponent__fieldComponent');
    return closestParent || element;
};

function getFieldDom(id) {
    const firstEle = document.querySelector(`#${id}`) || document.querySelector(`input[id^="${id}"]`);
    const firstEleLabel = document.querySelector(`label[for="${id}"]`);
    const element = firstEle || firstEleLabel;
    const closestParent = element && element.closest('.jut__FieldComponent__fieldComponent');
    return closestParent || element;
}
function getObjPos(target) {
    const pos = {
        x: target.offsetLeft,
        y: target.offsetTop
    };

    let ATarget = target.offsetParent;
    while (ATarget) { // when target is body, it's parent is null
        pos.x += ATarget.offsetLeft;
        pos.y += ATarget.offsetTop;

        ATarget = ATarget.offsetParent;
    }
    return pos;
}
function scrollToInvalidField(invalidFields, doubleColumn) {
    if (_.isEmpty(invalidFields)) {
        return false;
    }
    const invalidFieldsPos = invalidFields.map((v) => {
        const fieldEle = getFieldDom(v);
        const fieldPos = fieldEle ? getObjPos(fieldEle) : {};
        return {
            invalidId: v,
            x: fieldPos.x,
            y: fieldPos.y
        };
    });
    const invalidFieldsData = !doubleColumn ? _.sortBy(invalidFieldsPos, 'y') : _.sortBy(invalidFieldsPos, ['x', 'y']);
    const firstDom = getFieldDom(invalidFieldsData[0].invalidId);

    if (firstDom) {
        firstDom.scrollIntoView();
    }
    return false;
}

function scrollToInvalidMapField(invalidMapFields = {}) {
    const invalidFields = _.omitBy(invalidMapFields, (item) => _.isEmpty(item));
    const invalidFieldsPos = [];
    _.mapKeys(invalidFields, (value, key) => {
        _.forEach(value, (v) => {
            const fieldEle = getFieldDomWithParent(key, v);
            const fieldPos = fieldEle ? getObjPos(fieldEle) : {};
            invalidFieldsPos.push({
                invalidId: v,
                x: fieldPos.x,
                y: fieldPos.y,
                parentsId: key
            })
        });
    });
    const invalidFieldsData = _.sortBy(invalidFieldsPos, 'y');
    const firstDom = getFieldDom(invalidFieldsData[0].parentsId, invalidFieldsData[0].invalidId);

    if (firstDom) {
        firstDom.scrollIntoView();
    }
    return false;
}

/**
 * A shorthand for WindowUtil.scrollTo('wizardPageErrorsAndWarnings');
 * See WizardPageErrorComponent
 */
function scrollToWizardErrors() {
    scrollTo('wizardPageErrorsAndWarnings');
}

function partialPrint(ids){
    const partialPrintDom = document.getElementById('printRoot');
    const rootDom = document.getElementsByTagName('body')[0];
    const idsDom = document.getElementsByClassName(ids);
    
    if (idsDom && !_.isEmpty(idsDom)) {
        rootDom.classList.add('partialPrinting');
        partialPrintDom.innerHTML = idsDom[0].innerHTML// Put the content to be printed in a new div
    }
    window.print();
    rootDom.classList.remove('partialPrinting')
    partialPrintDom.innerHTML = ''
}
export default {
    scrollTo,
    scrollToTop,
    scrollToInvalidField,
    scrollToInvalidMapField,
    scrollToWizardErrors,
    partialPrint
};
