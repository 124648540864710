import { defineMessages } from 'react-intl';

export default defineMessages({
    CAUnfavorablyAnswerWarning: {
        id: 'wni.quoteandbind.ca-wizard.ca-qualification.CAUnfavorablyAnswerWarning',
        defaultMessage: 'This application has been flagged as containing risks that do not meet criteria to quote via portal. You may continue to fill out relevant information, however, an underwriter will need to review the application before a quote can be provided.'
    },
    CAValidationMessages: {
        id: 'wni.quoteandbind.ca-wizard.ca-qualification.CAValidationMessages',
        defaultMessage: 'This field is required.'
    }
});