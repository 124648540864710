import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { ProducerContext } from './SelectProducerCodeContext';
import metadata from './SelectProducerCode.metadata.json5';
import styles from './SelectProducerCode.module.scss';

const SelectProducerCode = (props) => {
    const { hideMyWork, onValueChange } = props;
    const [availableProducerCode, setProducerCodeList] = useState([]);
    const { producerCodeList, producerCode, setProducerCode } = useContext(ProducerContext);

    const getSelectedProducerCode = useCallback(
        (codeList) => {
            if (!_.isEmpty(codeList)) {
                return _.some(codeList, { code: producerCode }) ? producerCode : 'all';
            }
            return null;
        },
        [producerCode]
    );

    useEffect(() => {
        if (hideMyWork && !_.isEmpty(producerCodeList)) {
            const codeList = _.filter(producerCodeList, (list) => list.code !== 'myWork');
            const code = getSelectedProducerCode(codeList);
            setProducerCode(code);
            if (onValueChange) {
                onValueChange(code);
            }
            setProducerCodeList(codeList);
        } else {
            setProducerCodeList(producerCodeList);
        }
        // execute once producerCodeList is populated
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [producerCodeList]);

    const handleValueChange = useCallback(
        (code) => {
            if (onValueChange) {
                onValueChange(code);
            }
            setProducerCode(code);
        },
        [onValueChange, setProducerCode]
    );

    const overrideProps = {
        availableProducerCode: {
            availableValues: availableProducerCode,
            value: getSelectedProducerCode(availableProducerCode)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange: handleValueChange
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

SelectProducerCode.propTypes = {
    hideMyWork: PropTypes.bool,
    onValueChange: PropTypes.func
};
SelectProducerCode.defaultProps = {
    hideMyWork: true,
    onValueChange: undefined
};

export default SelectProducerCode;
