import React, {useState, useCallback, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMFineArtsFloaterService as  FineArtsFloaterService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMFineArtsFloaterPage.metadata.json5';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import messages from './IMFineArtsFloaterPage.messages';

const FineArtsFloaterReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM,
    } = props;

    const { initialValidation, onValidate, } = useValidation('FineArtsFloaterReadOnlyPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [isEditing, setIsEditing] = useState(false);
    const [finArtsFloaterClauses, setFinArtsFloaterClausesClauses] = useState();
    const [finArtsFloaterLoadingClauses, setFinArtsFloaterLoadingClauses] = useState();

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');
    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await FineArtsFloaterService.getFineArtsFloaterClausesWithSync(jobID, sessionUUID, authHeader) 
            : await FineArtsFloaterService.getFineArtsFloaterClauses(jobID, sessionUUID, authHeader)
        setFinArtsFloaterClausesClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onPageNext = () => {
        return submissionVM
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: finArtsFloaterClauses,
            setCoveragePartClauses: setFinArtsFloaterClausesClauses,
            isEditing: false,
            isEditable: false,
            onValidate: onValidate,
            loadingClauses: finArtsFloaterLoadingClauses,
            setLoadingClauses: setFinArtsFloaterLoadingClauses,
            wizardData: submissionVM,
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: false,
            },
            // accor
            fineArtsFloater: {
                visible: _.get(finArtsFloaterClauses, 'fineArtsFloaterCoverage.visible'),
            },
            fineArtsFloaterMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(finArtsFloaterClauses, 'fineArtsFloaterCoverage.mainCoverage'),
                coverageFormName: 'FineArtsFloaterMain',
                coverageFormPath: 'fineArtsFloaterCoverage.mainCoverage'
            },
            fineArtsFloaterSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(finArtsFloaterClauses, 'fineArtsFloaterCoverage.supplementalCoverage'),
                coverageFormName: 'FineArtsFloaterSupplemental',
                coverageFormPath: 'fineArtsFloaterCoverage.supplementalCoverage'
            },
            fineArtsFloaterScheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(finArtsFloaterClauses, 'fineArtsFloaterCoverage.scheduledCoverage'),
                updateScheduleService: FineArtsFloaterService.updateFineArtsFloaterSchedule,
                schedulePath: 'fineArtsFloaterCoverage.scheduledCoverage',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails)
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'FineArtsFloater'
            },
            exclusionAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'FineArtsFloater'
            }
        }
    }, [accordionCommonProps, finArtsFloaterClauses, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            FineArtsFloaterMainCovCard: IMCommonMainCoverage,
            FineArtsFloaterSupplementalCovCard: IMCommonMainCoverage,
            AdditionalAccordionCard: IMCommonAdditionalCovCard,
            ExclusionAccordionCard: IMExclusionAccordionCard,
            FineArtsFloaterScheduleCovCard: IMCommonCovPartSchedule,
        },
    };

    const disableNavigator = isEditing || isLoadingMask || finArtsFloaterLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={onPageNext}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {finArtsFloaterClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default FineArtsFloaterReadOnlyPage
