import React, {
    useEffect, useState, useContext, useCallback, Fragment
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { Loader } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ClaimService } from 'gw-capability-claim';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { getNormalizedLOBName } from '@xengage/gw-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import { PAVehicleCoverages } from 'gw-capability-claim-pa-react';
import { HOLocationCoverages } from 'gw-capability-claim-ho-react';
import metadata from './PolicyLevelCoverages.metadata.json5';
import claimDetailsStyles from './PolicyLevelCoverages.module.scss';
import messages from '../../Claims.messages';

function PolicyLevelCoverages(props) {
    const { match, history, authHeader } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [isLoading, setLoading] = useState(true);
    const [policyCoverages, setPolicyCoverages] = useState([]);
    const claimNumber = _.get(match, 'params.claimNumber');

    useEffect(() => {
        if (_.isNil(claimNumber)) {
            history.push('/');
        }
        ClaimService.getClaimPolicyDetail(claimNumber, authHeader)
            .then((coverageResponse) => {
                setPolicyCoverages(coverageResponse);
            })
            .finally(() => {
                setLoading(false);
            });
        // It should call once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBackToPreviousState = useCallback(() => {
        history.goBack();
    }, [history]);

    const getNameCell = useCallback(
        (item, index, property) => {
            return <span title={translator(messages.coverage)}>{item[property.id]}</span>;
        },
        [translator]
    );

    const getLimitCell = useCallback(
        (item) => {
            return (
                <div title={translator(messages.limit)}>
                    {!_.isNil(item.incidentLimit) && (
                        <div>{translator(messages.coveragePerAccident)}</div>
                    )}
                    {!_.isNil(item.exposureLimit) && (
                        <div>{translator(messages.coveragePerPerson)}</div>
                    )}
                    {_.isNil(item.incidentLimit) && _.isNil(item.exposureLimit) && <div>-</div>}
                </div>
            );
        },
        [translator]
    );

    const getValueCell = useCallback((item) => {
        return (
            <Fragment>
                {!_.isNil(item.incidentLimit) && (
                    <div>
                        {
                            intl.formatNumber(
                                item.incidentLimit.amount,
                                {
                                    style: 'currency',
                                    currency: item.incidentLimit.currency,
                                    currencyDisplay: 'code'
                                }
                            )
                        }
                    </div>
                )}
                {!_.isNil(item.exposureLimit) && (
                    <div>
                        {
                            intl.formatNumber(
                                item.exposureLimit.amount,
                                {
                                    style: 'currency',
                                    currency: item.exposureLimit.currency,
                                    currencyDisplay: 'code'
                                }
                            )
                        }
                    </div>
                )}
            </Fragment>
        );
    }, [intl]);

    if (isLoading) {
        return <Loader />;
    }

    const lobName = getNormalizedLOBName(_.get(policyCoverages, 'policyType'));

    const overrides = {
        claimPolicyNumber: {
            content: translator(messages.claimPolicyDetails, {
                policyNumber: _.get(policyCoverages, 'policyNumber')
            })
        },
        policySectionTitle: {
            visible: !_.isEmpty(_.get(policyCoverages, 'coverages'))
        },
        policyCoverageTable: {
            visible: !_.isEmpty(_.get(policyCoverages, 'coverages')),
            data: _.get(policyCoverages, 'coverages')
        },
        paVehicleCoverage: {
            visible: lobName === 'pa',
            value: _.get(policyCoverages, 'lobs.personalAuto', [])
        },
        hoLocationCoverages: {
            visible: lobName === 'ho',
            value: _.get(policyCoverages, 'lobs.homeowner', [])
        }
    };

    const resolvers = {
        resolveClassNameMap: claimDetailsStyles,
        resolveComponentMap: {
            PAVehicleCoverages: PAVehicleCoverages,
            HOLocationCoverages: HOLocationCoverages
        },
        resolveCallbackMap: {
            getNameCell,
            getLimitCell,
            getValueCell,
            getBackToPreviousState
        }
    };
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
}

PolicyLevelCoverages.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string,
        params: PropTypes.shape({
            claimNumber: PropTypes.string
        })
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withRouter(withAuthenticationContext(PolicyLevelCoverages));
