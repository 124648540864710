import { defineMessages } from 'react-intl';

export default defineMessages({
    footerCompanyNameWNI: {
        id: 'cosmos.footer.wni.companyname',
        defaultMessage: 'Western National Insurance',
    },
    footerCompanyNameUIC: {
        id: 'cosmos.footer.uic.companyname',
        defaultMessage: 'Umialik Insurance Company',
    },
    footerEmailAddressWNI: {
        id: 'cosmos.footer.wni.emailAddress',
        defaultMessage: 'info@wnins.com',
    },
    footerEmailAddressUIC: {
        id: 'cosmos.footer.uic.emailAddress',
        defaultMessage: 'umialikinfo@umialik.com',
    },
    footerPhoneNumberWNI: {
        id: 'cosmos.footer.wni.PhoneNumber',
        defaultMessage: '(800) 862-6070'
    },
    footerPhoneNumberUIC: {
        id: 'cosmos.footer.uic.PhoneNumber',
        defaultMessage: '(800) 251-3563'
    },
    footerFackbookLinkWNI: {
        id: 'cosmos.footer.wni.FackbookLink',
        defaultMessage: 'https://www.facebook.com/WesternNational/'
    },
    footerFackbookLinkUIC: {
        id: 'cosmos.footer.uic.FackbookLink',
        defaultMessage: 'https://www.facebook.com/umialik/'
    },
    footerLinkedinLinkWNI: {
        id: 'cosmos.footer.wni.LinkedinLink',
        defaultMessage: 'https://www.linkedin.com/company/western-national-insurance'
    },
    footerLinkedinLinkUIC: {
        id: 'cosmos.footer.uic.LinkedinLink',
        defaultMessage: 'https://www.linkedin.com/company/umialik-insurance-company/'
    }
});
