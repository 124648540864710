import messages from './WALIncidentsAndReportsPage.messages'

export default {
    userClue: {
        title: 'Prior Loss Entry',
        labelMap: {
            incidentDate: { label: messages.walIncidentDate },
            category: { label: messages.walCategory}, 
            policyDriverId: { label: messages.walDriver, driverOptionPath: 'drivers' },
            status: { label: messages.walStatus, initialFiled: true, initialFilterValues: [{ code: 'Open', name: 'Open' }, { code: 'Closed', name: 'Closed' }, { code: 'Subrogated', name: 'Subrogated' }] },
            amountPaid: { label: messages.walAmountPaid },
            chargeable: { label: messages.walSurchargeable, checkRatedStatus: true, disabled: true },
            surChargeApply: { label: messages.walSurChargeApplied, checkRatedStatus: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.watercraft.dto.WALCLUEManuallyDTO'
    },
    vendorClue: {
        title: 'Prior Loss Reports',
        labelMap: {
            driverName: { unEditable: true, label: messages.walClueDriver },
            policyDriverName: { unEditable: false, label: messages.walPolicyDriver, driverOptionPath: 'policydrivers' },
            category: { unEditable: true, label: messages.walCategory },
            incidentDate: { unEditable: true, label: messages.walIncidentDate },
            status: { unEditable: true, label: messages.walStatus },
            amountPaid: { unEditable: true, label: messages.walAmountPaid },
            chargeable: { unEditable: true, label: messages.walSurchargeable },
            surChargeApply: { unEditable: true, label: messages.walSurChargeApplied },
            dispute: {
                unEditable: false,
                label: messages.walDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'disputeother' },
                tooltip: { text: messages.walMVRDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.walExplanation, unVisible: true },
            underwritingResponse: { unEditable: true, label: messages.walUnderWritingResponse },
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.watercraft.dto.WALCLUEReportDTO'
    },
    userMvr: {
        title: 'MVR Entry',
        labelMap: {
            driverId: { label: messages.walMVRDriverName, driverOptionPath: 'drivers' },
            incidentDate: { label: messages.walIncidentDate },
            description: { label: messages.walDescription, filterVal: 'AccidentFromMVR', },
            descriptionForChips: {label: messages.walDescription, disabled: true },
            source: { label: messages.walSource, hidden: true },
            chargeable: { label: messages.walSurchargeable, checkRatedStatus: true, disabled: true },
            chargeableApply: { label: messages.walSurChargeApplied, checkRatedStatus: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.watercraft.dto.WALMVRManuallyDTO'
    },
    vendorMvr: {
        title: 'MVR Reports',
        labelMap: {
            driverName: { unEditable: true, label: messages.walMVRReportDriverName },
            incidentDate: { unEditable: true, label: messages.walIncidentDate },
            description: { unEditable: true, label: messages.walDescription },
            chargeable: { unEditable: true, label: messages.walSurchargeable },
            surChargeApply: { unEditable: true, label: messages.walSurChargeApplied },
            dispute: {
                unEditable: false,
                label: messages.walDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'Other' },
                tooltip: { placement: 'left', text: messages.walMVRDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.walExplanation, unVisible: true },
            underwritingResponse_Ext: { unEditable: true, label: messages.walUnderWritingResponse }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.watercraft.dto.WALMVRReportDTO'
    }
}