
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import messages from './ClauseCommonMessages.messages';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const AccidentForgivenessClauseComponent = (props) => {
    const {
        model: dtoVM,
        onValueChange,
        showErrors,
    } = props;

    const translator = useTranslator();

    const penaltyFreeMsg = translator(messages.penaltyFreeIncluded);

    //---------

    return (
        <div className="ph-10 pv-10">
            <span>{penaltyFreeMsg}</span>
        </div>
    );
};

AccidentForgivenessClauseComponent.propTypes = {
    // model: PropTypes.shape(
    //     {
    //         value: PropTypes.shape({})
    //     }
    // ),
    // onValueChange: PropTypes.func,
    // showErrors: PropTypes.bool,
};

AccidentForgivenessClauseComponent.defaultProps = {
    // model: {},
    // onValueChange: undefined,
    // showErrors: false,
};

export default AccidentForgivenessClauseComponent;
