import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { WCQualificationService } from 'wni-capability-quoteandbind-wc';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/WCWizardPage';

import metadata from './WCQualificationPage.metadata.json5';
import messages from './WCQualificationPage.messages';

function WCQualificationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        wcQualificationService
    } = props;

    // const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('WCQualificationPage');

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const questionsetAnswers = _.get(submissionVM, 'lobData.workersComp.preQualQuestionSets.value[0].answers');
    const requiredQuestions = [
        // 'ThreeYearsWCCoverage',   // Have you had three (3) or more consecutive years...
        // 'TotalNumberEmployees',   // What are the total number of employees per location and per shift?*
        'OwnOperateLeaseCraft',   // Does the applicant own, operate or lease Aircraft/Watercraft?
        'BargesVesselsDocksBridges',    // Any work performed on Barges...
        // 'WCMSafetyMeetingFrequency_Ext',   // Are safety meetings held regularly...
        // 'WCMExplainForSafetyMeetingFrequency_Ext',   // If yes, how often?
        // 'WCLossesPastFiveYears',   // Have there been three (3) or more WC losses in the past five (5) years?
    ];

    const displayQuestions = [
        'OwnOperateLeaseCraft',
        'BargesVesselsDocksBridges'
    ];

    const IsValidField = (value) => {
        if (value === undefined || value === null || value === '') {
            return false;
        }
        return true;
    }

    const IsValidFields = useCallback(() => {
        const invalidFields = _.filter(requiredQuestions, (code) => {
            return IsValidField(questionsetAnswers[code]) === false
        })
        // if ((questionsetAnswers.WCMSafetyMeetingFrequency_Ext === true
        //     || questionsetAnswers.WCMSafetyMeetingFrequency_Ext === 'true')
        //     && !IsValidField(questionsetAnswers.WCMExplainForSafetyMeetingFrequency_Ext)) {
        //     return false;
        // }
        if (invalidFields.length <= 0) {
            return true;
        }
        return false;
    }, [questionsetAnswers]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const getQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            _.set(metaDataContent, 'componentProps.showRequired', true);
            _.set(metaDataContent, 'componentProps.required', true);
            if (!IsValidField(_.get(questionsetAnswers, metaDataContent.componentProps.path))) {
                _.set(metaDataContent, 'componentProps.validationMessages', [translator(messages.WCValidationMessages)]);
            } else {
                _.set(metaDataContent, 'componentProps.validationMessages', []);
            }
            // if(metaDataContent.id === 'WCMSafetyMeetingFrequency_Ext') {
            //    const safetyMeetingFrequencyAnswers = _.get(questionsetAnswers, metaDataContent.componentProps.path);
            //    if(safetyMeetingFrequencyAnswers === true) {
            //     _.set(metaDataContent.id, 'WCMExplainForSafetyMeetingFrequency_Ext', null);
            //    }
            // }
            return metaDataContent;
        }
    }, [questionsetAnswers]);

    const getValidationIssues = useCallback((issues) => {
        const WCUnfavorablyAnswerWarning = [{
            type: 'warning',
            reason: translator(messages.WCUnfavorablyAnswerWarning)
        }]
        if (!_.isEmpty(questionsetAnswers)) {
            if (
                // questionsetAnswers.ThreeYearsWCCoverage === 'false' || questionsetAnswers.ThreeYearsWCCoverage === false
                // || questionsetAnswers.TotalNumberEmployees === 'FiftyOneHundred'
                // || questionsetAnswers.TotalNumberEmployees === 'OverOneHundred'
                questionsetAnswers.OwnOperateLeaseCraft === 'true' || questionsetAnswers.OwnOperateLeaseCraft === true
                || questionsetAnswers.BargesVesselsDocksBridges === 'true' || questionsetAnswers.BargesVesselsDocksBridges === true
                // || questionsetAnswers.WCLossesPastFiveYears === 'true' || questionsetAnswers.WCLossesPastFiveYears === true
            ) {
                return _.concat(issues, WCUnfavorablyAnswerWarning);
            }
        }
        return issues;
    }, []);

    const filterQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                contentMapper: getQuestionMapper()
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, 'lobData.workersComp.preQualQuestionSets.value')
        };
        const WCMTotalAnnualPayroll = _.get(requestData, 'dto[0].answers.WCMTotalAnnualPayroll');
        if (WCMTotalAnnualPayroll === '') {
            _.set(requestData, 'dto[0].answers.WCMTotalAnnualPayroll', 0);
        }
        const res = await wcQualificationService.updateQualification(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.workersComp.preQualQuestionSets.value', res.preQualQuestionSets);
        }
        setLoadingMask(false);
        updateWizardData(newSubmissionVM);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        const resValidationIssues = getValidationIssues(newValidationIssues);
        updateValidationIssues(resValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(resValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(resValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, wcQualificationService, jobID, sessionUUID, submissionVM, displayWarnings]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WCQualificationPage.propTypes = WizardPage.propTypes;
WCQualificationPage.defaultProps = WizardPage.defaultProps;

WCQualificationPage.propTypes = {
    ...wizardProps,
    wcQualificationService: PropTypes.object
};

WCQualificationPage.defaultProps = {
    wcQualificationService: WCQualificationService
};
export default WCQualificationPage;