import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { CheckboxField } from '@jutro/legacy/components';
import {
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { FNOLBusinessLocationPage } from 'gwce-capability-fnol-common-react-ext';
import { ProductUtil } from 'wnice-portals-util-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './FNOLCPBusinessLocationPage.metadata.json5';
import messages from '../../FNOLCP.messages';

function FNOLCPBusinessLocationPage(props) {
    return <FNOLBusinessLocationPage 
                {...props}
                LOB_NAME={ProductUtil.CP_LOB_NAME}
            />;
}

FNOLCPBusinessLocationPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLCPBusinessLocationPage));
