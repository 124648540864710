import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { InfoLabel, DropdownMenu } from '@jutro/components';
import metadata from './BuildingsListPage.metadata.json5';
import styles from './BuildingsListPage.module.scss';
import messages from '../../../PECPWizard.messages';

import { IconButton, Button } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

const BuildingsListPage = (props) => {
    const {
        locationsList,
        buildingsList,
        viewBuildingAndLocation,
        deleteBuilding,
        addBuildingToSpecificLocation,
        newBuildings
    } = props;
    const translator = useTranslator();

    const renderBuildingClassCode = useCallback(
        (item, index, { path: property }) => {
            const toolTipMessage = translator(messages.buildingClassCode);
            return <span title={toolTipMessage}>{item[property]}</span>;
        },
        [translator]
    );

    const renderNewBuildingTag = useCallback(
        (item, index, { path: property }) => {
            const newBuilding = _.includes(newBuildings, item[property]) ? (
                <InfoLabel type="info" message={translator(messages.newLabel)} />
            ) : null;
            return newBuilding;
        },
        [translator, newBuildings]
    );

    const renderBuildingAndLocation = useCallback(
        (item, index, { path: property }) => {
            const toolTipMessage = property === 'name'
                ? translator(messages.buildingDescription)
                : translator(messages.location);
            const selectedCategory = property === 'name' ? 'building' : 'location';
            return (
                <Button
                    type="text"
                    className={styles.gwBuildingAndLocationNameLink}
                    onClick={() => viewBuildingAndLocation(item, selectedCategory)}
                    title={toolTipMessage}
                >
                    {item[property]}
                </Button>
            );
        },
        [viewBuildingAndLocation, translator]
    );

    const removeBuilding = useCallback(
        (selectedBuilding) => {
            const buildingLocation = locationsList.find((loc) => {
                return loc.buildings.find((building) => {
                    return building.publicID === selectedBuilding.buildingId;
                });
            });
            deleteBuilding(buildingLocation.publicID, selectedBuilding.buildingId);
        },
        [locationsList, deleteBuilding]
    );

    const addBuildingToThisLocation = useCallback(
        (selectedBuilding) => {
            const buildingLocation = locationsList.find((loc) => {
                return loc.buildings.find((building) => {
                    return building.publicID === selectedBuilding.buildingId;
                });
            });
            addBuildingToSpecificLocation(buildingLocation);
        },
        [locationsList, addBuildingToSpecificLocation]
    );

    const renderTrigger = useCallback((dropdownProps, toggleMenu) => {
        const {
            id, menuId, isOpen, className
        } = dropdownProps;
        const handleButtonClick = () => {
            toggleMenu(!isOpen);
        };

        return (
            <div className={styles.gwIconButton}>
                <IconButton
                    id={id}
                    className={className}
                    onClick={handleButtonClick}
                    role="menuitem"
                    aria-controls={menuId}
                    icon="mi-more_horiz"
                />
            </div>
        );
    }, []);

    const renderActionColumn = useCallback(
        (item) => {
            const buildingsActionDropdown = cx(
                styles.gwBuildingsAction,
                styles.gwBuildingsList,
                styles.gwAddNewBuilding,
                styles.gwAddBuildingToLocation,
                styles.gwAddBuilding,
                styles.gwIconButton
            );

            return (
                <DropdownMenu
                    id="builidngsListAction"
                    isOpen={false}
                    alignRight
                    dropUp={false}
                    showArrow={false}
                    className={buildingsActionDropdown}
                    renderTrigger={renderTrigger}
                >
                    <DropdownMenuLink
                        onClick={(e) => {
                            e.preventDefault();
                            removeBuilding(item);
                        }}
                    >
                        <Button
                            id="deleteBuilding"
                            icon="mi-add"
                            type="text"
                            className={styles.gwBuildingsTableButton}
                        >
                            {translator(messages.deleteBuilding)}
                        </Button>
                    </DropdownMenuLink>
                    <DropdownMenuLink
                        onClick={(e) => {
                            e.preventDefault();
                            addBuildingToThisLocation(item);
                        }}
                    >
                        <Button
                            id="addBuilding"
                            icon="mi-add"
                            type="text"
                            className={styles.gwBuildingsTableButton}
                        >
                            {translator(messages.addBuildingToThisLocation)}
                        </Button>
                    </DropdownMenuLink>
                </DropdownMenu>
            );
        },
        [addBuildingToThisLocation, renderTrigger, translator, removeBuilding]
    );

    const overrideProps = {
        buildingsTableContainer: {
            data: buildingsList
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderNewBuildingTag: renderNewBuildingTag,
            renderBuildingDescription: renderBuildingAndLocation,
            renderBuildingLocation: renderBuildingAndLocation,
            renderActionColumn: renderActionColumn,
            renderBuildingClassCode: renderBuildingClassCode
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

BuildingsListPage.propTypes = {
    locationsList: PropTypes.shape([]).isRequired,
    buildingsList: PropTypes.shape([]).isRequired,
    deleteBuilding: PropTypes.func.isRequired,
    addBuildingToSpecificLocation: PropTypes.func.isRequired,
    viewBuildingAndLocation: PropTypes.func.isRequired,
    newBuildings: PropTypes.shape([]).isRequired
};

export default BuildingsListPage;
