import _ from 'lodash'
import {
    HOPSPPTypeFineArtsAntiques,
    HOPSPPTypeJewelry,
    getNewDeductibleOptionsByType,
    HOSSPAgreedValueOptionCodes,
    HOSSPWithBreakageOptionCodes,
} from './HOSchedulePersonalPropertyUtil'

const HOPScheduledPersonalPropertyItemType = (schedule, localPathToSchedule) => {
    const scheduleAfterSync = _.clone(schedule)
    const scheduleItem = _.get(scheduleAfterSync, localPathToSchedule)
    const classOfPersonalPropertyCode = _.get(scheduleItem, 'itemData.HOPScheduledPersonalPropertyItemType.typeCodeValue')
    
    const allDeductiblesOptions = _.get(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemDeductible.options_Ext`)
    const availableDeductiblesOptions = getNewDeductibleOptionsByType(classOfPersonalPropertyCode, allDeductiblesOptions)
    const defuctibleOptionCode = _.get(scheduleItem, 'itemData.HOPScheduledPersonalPropertyItemDeductible.typeCodeValue')
    if (!availableDeductiblesOptions.some((option) => option.code === defuctibleOptionCode)) {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemDeductible.typeCodeValue`, undefined)
    }
    // HOPScheduledPersonalPropertyItemAgreedValue_Ext: 
    // [S26] 1. This field will only be available when "Class of Personal Property" is "Fine Arts & Antiques" or "Jewelry".
    if (classOfPersonalPropertyCode === HOPSPPTypeFineArtsAntiques
        || classOfPersonalPropertyCode === HOPSPPTypeJewelry) {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.visible_Ext`, true)
        // set default value
        const agreedValue = _.get(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.typeCodeValue`)
        if (_.isNil(agreedValue)) {
            if (classOfPersonalPropertyCode === HOPSPPTypeJewelry) {
                // Default to NO if NO is available
                _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.typeCodeValue`, HOSSPAgreedValueOptionCodes.No)
            } else {
                // Default to Yes if only Yes is available
                _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.typeCodeValue`, HOSSPAgreedValueOptionCodes.Yes)
            }
            _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.updated_Ext`, true)
        } else if (classOfPersonalPropertyCode === HOPSPPTypeFineArtsAntiques) {
             _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.typeCodeValue`, HOSSPAgreedValueOptionCodes.Yes)
             _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.updated_Ext`, true)
            }

    } else {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.visible_Ext`, false)
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.typeCodeValue`, undefined)
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemAgreedValue_Ext.updated_Ext`, true)
    }

    // HOPScheduledPersonalPropertyItemWithBreakage_Ext
    // [S26] 1. This field will only be available when "Class of Personal Property" is "Fine Arts & Antiques".
    if (classOfPersonalPropertyCode === HOPSPPTypeFineArtsAntiques) {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.visible_Ext`, true)
        const withBreakageValue = _.get(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.typeCodeValue`)
        if (_.isNil(withBreakageValue)) {
            // Default to NO if NO is available
            _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.typeCodeValue`, HOSSPWithBreakageOptionCodes.No)
            _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.updated_Ext`, true)
        }
    } else {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.visible_Ext`, false)
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.typeCodeValue`, undefined)
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemWithBreakage_Ext.updated_Ext`, true)
    }

    // HOPScheduledPersonalPropertyItemLocation_Ext
    // [S26] 3. This field is only available when the "Class of Property" = "Fine Arts & Antiques".
    if (classOfPersonalPropertyCode === HOPSPPTypeFineArtsAntiques) {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemLocation_Ext.visible_Ext`, true)
    } else {
        _.set(scheduleItem, `itemData.HOPScheduledPersonalPropertyItemLocation_Ext.visible_Ext`, false)
    }

    _.set(scheduleAfterSync, localPathToSchedule, scheduleItem)
    return scheduleAfterSync
}

export default {
    HOPScheduledPersonalPropertyItemType
};