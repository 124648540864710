import { defineMessages } from 'react-intl';

export default defineMessages({
    // This is common throughout the HO flow so adding it here
    hoCommonPleaseSelect: {
        id: 'quoteandbind.ho.views.common.Please Select',
        defaultMessage: 'Please Select',
    },
    addressLabel: {
        id: 'quoteandbind.ho.views.contact-details.Enter the address of the home that you want to insure.',
        defaultMessage: 'Enter the address of the home that you want to insure.',
    }
});
