import React, {
    useContext,
} from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil, ClauseMetadataUtil } from 'wni-portals-util-js';
import messages from 'gw-components-platform-react/Clauses/ClauseComponentVM.messages';
import SideBySideClauseComponent from './SideBySideClauseComponent';
import SingleClauseComponentVM from '../SingleClauseComponentVM';


const {
    QuotePageConfig: {
        displayAmountTermPatterns,
    }
} = R1Config;

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function SideBySideSingleClauseComponentVM(props) {
    const {
        value: clause = {},
        isEditable,
        path,
        idPrefix,
        isDisabled,
        labelPosition,
        labelTop,
        showAmount,
    } = props;

    const translator = useTranslator();
    const intl = useContext(IntlContext);

    /**
     * Generate clauses term metadata from clause
     * @param {object} clauseTerms the clause terms
     * @param {string} clausePublicID the clause publicID
     * @returns {object | null} the metadata to render
     */
    const getTermMetaData = (clauseTerms, clausePublicID) => {
        const coverageAmount = clause.amount;
        const updatedClauseTerms = clauseTerms.map((term) => {
            const { name } = term;
            let nameWithAmount = name;
            if (displayAmountTermPatterns.includes(term.patternCode)) {
                nameWithAmount = WniClausesUtil.getLabelNameWithAmount(name, coverageAmount, intl, showAmount);
            }
            return {
                ...term,
                name: nameWithAmount
            };
        });
        return ClauseMetadataUtil.getTermMetadata(
            {
                clauseTerms: updatedClauseTerms,
                clausePublicID,
                isEditable,
                path,
                idPrefix,
                isDisabled,
                labelPosition,
                labelTop,
                translator,
                messages
            }
        );
    };

    const componentMapOverrides = {
        ClauseComponent: SideBySideClauseComponent
    };

    // const overrideFunctionMap = {
    //     getTermMetaData
    // };

    return (
        <SingleClauseComponentVM
            {...props}
            getTermMetaData={getTermMetaData}
            componentMapOverrides={componentMapOverrides}
            // overrideFunctionMap={overrideFunctionMap}
        />
    );
}

SideBySideSingleClauseComponentVM.propTypes = SingleClauseComponentVM.propTypes;

SideBySideSingleClauseComponentVM.defaultProps = SingleClauseComponentVM.defaultProps;


export const SingleClauseVM = SideBySideSingleClauseComponentVM;
// export default withValidation(SXSSingleClauseComponentVM);
export default SideBySideSingleClauseComponentVM;
