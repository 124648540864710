import React from 'react'
import { CRCommonercialCrimeService } from 'wni-capability-quoteandbind-cr'
import CRCoverablePage from '../CRCoverablePage/CRCoverablePage'

const CRCommercialCrimePage = (props) => {
    return <CRCoverablePage
        {...props}
        initClausesWithSyncFunc={CRCommonercialCrimeService.getCommercialCrimeWithSync}
        initClausesFunc={CRCommonercialCrimeService.getCommercialCrime}
        updateClausesFunc={CRCommonercialCrimeService.updateCommercialCrime}
        searchClausesFunc={CRCommonercialCrimeService.searchCommercialCrimeCoverages}
        addSearchedClausesFunc={CRCommonercialCrimeService.addSearchedCommercialCrimeCoverages}
        onPageNextService={async () => null}
        coverableName='Commercial Crime'
        coverableCategory='CommlCrime'
    />
}

export default CRCommercialCrimePage