import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    recommendedUpgrades: {
        id: 'custom.quoteandbind.views.quote-details.Recommended Upgrades',
        defaultMessage: 'Recommended Upgrades',
    },
    // recommendedItem: {
    //     id: 'custom.quoteandbind.views.quote-details.Recommended Coverage',
    //     defaultMessage: 'coverage',
    // }
});
