import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Chevron } from '@jutro/components';
import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import {
    WniDateUtil,
    WniPNIUtil,
} from 'wni-portals-util-js';
import { JobService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {  WniProductsUtil } from 'wni-portals-util-react';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';

// import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

import { HOPaySuccessChangePage } from 'wni-capability-policychange-ho-react';

import { Button } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

// const ACCORDION_CARDS = [
//     'driverAccordion',
//     'vehicleAccordion',
//     'coverageAccordion',
//     'contactAccordion'
// ];

// const TYPE_MOBILE = 'mobile';
// const TYPE_HOME = 'home';
// const TYPE_WORK = 'work';

const ZERO_COST = { amount: 0.00, currency: 'usd' };

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



function DPPaySuccessChangePage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPrinting, setPrintingState] = useState(false);
    const { interactionModel } = useDependencies('interactionModel');
    const {
        wizardData: submissionVM,
        turnOffWizardPromptMessage,
        wizardPageData
    } = props;

    return (
        <HOPaySuccessChangePage {...props} showContactInfo />
    );
}

DPPaySuccessChangePage.propTypes = HOPaySuccessChangePage.propTypes;
DPPaySuccessChangePage.defaultProps = HOPaySuccessChangePage.defaultProps;

export default DPPaySuccessChangePage;
