import React, { useCallback, useEffect, useState } from 'react';
import { ToastProvider } from '@jutro/components'
import { withRouter, useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AccountService } from 'gw-capability-gateway-policy';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniSubmissionService, WniAccountQuoteService } from 'wni-capability-gateway';
import AccountContactsPage from '../../Contacts/AccountContacts/AccountContactsPage';
import AssociatedContactsPage from '../../Contacts/AssociatedContacts/AssociatedContactsPage';

import metadata from './AccountContacts.metadata.json5';

function AccountContacts(props) {
    const modalApi = useWniModal();
    const {
        fromAccountLanding: { 
            accountDetailsData = {},
            accountDetailsData: {
                accountNumber,
                displayName,
                accountHolder,
                accountHolder: {
                    contactType_Ext: accountType,
                } = {},
                producerCodes,
                availableProducerCodesForCurrentUser_Ext: availableProducerCodesForCurrentUser

            }
        } = {},
    } = props;
    const history = useHistory();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    

    const overrides = {
        accountContactsSection: {
            accountNumber: accountNumber,
            relatedTo: 'accounts',
            history,
            availableProducerCodesForCurrentUser,
            accountType
        },
        associatedContactsSection: {
            accountName: accountHolder.displayName,
            accountNumber: accountNumber,
            relatedTo: 'accounts',
            history
        },
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            accountcontacts: AccountContactsPage,
            associatedcontacts: AssociatedContactsPage
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(AccountContacts);
