import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './AllAgencyPopupComponentForCL.metadata.json5';

import { Button } from '@jutro/legacy/components';

function AllAgencyPopupComponentForCL(props) {
    const {
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        size,
        updateExternalAgency,
        agencySearchName,
        agencySearchCode,
        updateInternalAgencyName,
        useAuthenticationData,
        useDependenciesData,
        viewModelService
        // searchValue
    } = props;

    const { authHeader } = useAuthenticationData || useAuthentication();
    const [pageConfig, updatePageConfig] = useState(WniTableRowUtil.defaultPageConfig);
    // const [agencyResponseValue, updateAgencyResponseValue] = useState([]);
    const [agencyAfterSearchValue, updateAgencyAfterSearchValue] = useState([]);
    const [selectRow, updateSelectRow] = useState({});
    const [searchByAgencyNameInputValue, updateSearchByAgencyNameInputValue] = useState('');
    const [searchByAgencyCodeInputValue, updateSearchByAgencyCodeInputValue] = useState('');

    const searchAgency = async (searchName, searchCode, num) => {
        const agencies = await WniAccountService.searchAgency(searchName, searchCode, num, authHeader).then((agencyResponse) => {
            if (_.isArray(agencyResponse)) {
                _.each(agencyResponse, (item) => {
                    const name = `${item.code} - `
                        + `${item.description ? `${item.description}-` : null} `
                        + `${item.city_Ext ? item.city_Ext : null}, `
                        + `${item.state_Ext ? item.state_Ext : null}`;
                    _.set(item, 'name', name);
                });
                return agencyResponse;
            }
            return [];
        });
        return agencies;
    };



    const handleAgencyNameSearchInputChange = (agencyNameSearchInputValue) => {
        updateSearchByAgencyNameInputValue(agencyNameSearchInputValue);
    };

    const handleAgencyCodeSearchInputChange = (agencyCodeSearchInputValue) => {
        updateSearchByAgencyCodeInputValue(agencyCodeSearchInputValue);
    };

    const handleSearch = async () => {
        /**
         * keep the logic for future if it is changed again.
         */
        // let searchVal = '';
        let agencySearchName = '';
        let agencySearchCode = '';
        if (!_.isNull(searchByAgencyNameInputValue) && !_.isEmpty(searchByAgencyNameInputValue)) {
            agencySearchName = searchByAgencyNameInputValue;
        } else if (!_.isNull(searchByAgencyCodeInputValue) && !_.isEmpty(searchByAgencyCodeInputValue)) {
            agencySearchCode = searchByAgencyCodeInputValue;
        }
        // eslint-disable-next-line max-len
        const agencyRes = await searchAgency(agencySearchName, agencySearchCode, 100);
        updateAgencyAfterSearchValue(agencyRes);
        updatePageConfig(DatatableUtil.defaultPageConfig);
    };

    const handleReset = () => {
        updateSearchByAgencyCodeInputValue('');
        updateSearchByAgencyNameInputValue('');
        updateAgencyAfterSearchValue([]);
        updatePageConfig(DatatableUtil.defaultPageConfig);
    };

    const handleSave = useCallback(() => {
        if (selectRow.code) {

            onResolve(selectRow);
            updateExternalAgency(selectRow);
            updateInternalAgencyName(_.get(selectRow, 'name'));
        }
    }, [onResolve, selectRow, updateExternalAgency]);
    const hightLight = (activeRow) => {
        const selectedPublicID = _.get(activeRow, 'publicID');
        WniTableRowUtil.setTablePublicIDSelected(selectedPublicID, 'exisitingAgencyMatchTable');
    };
    const getSelectedFn = (item) => {
        if (item.code === selectRow.code) {
            updateSelectRow({});
        } else {
            updateSelectRow(item);
        }
        hightLight(item);
    };
    const onTableConfigPage = useCallback((obj) => {
        updatePageConfig(obj);
    }, []);
    const onSort = useCallback((a, b) => {
        hightLight(selectRow);
        return DatatableUtil.sortString(a, b);
    }, [selectRow]);

    useEffect(() => {
        hightLight(selectRow);
    }, [selectRow, onTableConfigPage, pageConfig]);

    const overrideProps = {
        agencyNameSearchInput: {
            value: searchByAgencyNameInputValue
        },
        agencyCodeSearchInput: {
            value: searchByAgencyCodeInputValue
        },
        exisitingAgencyMatchTable: {
            data: agencyAfterSearchValue,
            onConfigChange: onTableConfigPage,
            config: pageConfig
        },
        agencyNamecolumn: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.publicID, item.description)
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            getSelectedFn: getSelectedFn,
            updateAgencyNameSearchInput: handleAgencyNameSearchInputChange,
            updateAgencyCodeSearchInput: handleAgencyCodeSearchInputChange,
            onSearchBtnClick: handleSearch,
            onResetBtnClick: handleReset,
            onSort
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title="Agency Search Data" onClose={onReject} />
            <ModalBody id="agencyDatailsPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    // model={agencyVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="tertiary">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="primary" disabled={!selectRow.code}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

AllAgencyPopupComponentForCL.propTypes = {
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    updateExternalAgency: PropTypes.func,
    searchValue: PropTypes.string
};
AllAgencyPopupComponentForCL.defaultProps = {
    updateExternalAgency: undefined,
    searchValue: ''
};
export default AllAgencyPopupComponentForCL;
