import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

const URL_SLASH = '/';

const AO_URL_KEYS = ['{{PRODUCER_ENGAGE_AO_URL}}', '{{PRODUCER_ENGAGE_UMIALIK_AO_URL}}'];

const {
    lobQuoteURL,
    routerBaseName,
    wniExtendedConfig = {},
    AgentsOnlineConfig: {
        productionURL,
    },
} = appConfig;

/**
 * Get lobQuoteURL from app-config
 * @param {string} productCode
 * @returns {string}
 */
function getLobQuoteURL(productCode) {
    return lobQuoteURL[productCode];
}
/**
 * Generate random numbers
 * @returns {string}
 */
function getUuid() {
    // eslint-disable-next-line no-restricted-globals
    return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
}

/**
 * Get App BaseRouterName in URL format, e.g. '/producer-engage'
 * @returns {string}
 */
function getAppBaseRouterURL() {
    if (routerBaseName.startsWith(URL_SLASH)) {
        return routerBaseName;
    }
    return `${URL_SLASH}${routerBaseName}`;
}


// ===============================================================
// Try to extract wniExtendedConfig from config.json5
// When the URL is *.wnins.com, then themeName "ProducerEngage" will be returned.
// When the URL is *.umialik.com, then themeName "ProducerEngageUmialik" will be returned. 
// ====================================================
function getThemeName() {
    // Determine theme/branch name by URL, match-pattern specified in the config.json5 file
    const urlHref = window.location.href;
    let themeName;

    for (const [key, value] of Object.entries(wniExtendedConfig)) {
        if ('urlMatchPattern' in value && urlHref.search(value.urlMatchPattern) !== -1) {
            themeName = value.themeName;
            break;
        }
    }
    
    return themeName;
};

/**
 * Retrieve wniExtendedConfig value based on current theme. 
 * 
 * Sample Usage when current URL is *.wnins.com:
 * Input: 'okta'
 * Output: {"JUTRO_AUTH_LOGIN_QUERY_EXTRAS": "{\"idp\": \"{{PRODUCER_ENGAGE_UMIALIK_IDP}}\"}"}
 * 
 * Input: 'aoUrl'
 * Output: "{{PRODUCER_ENGAGE_AO_URL}}"
 * @param {string} propertyNameOrPath 
 * @returns {object}
 */
function getBrandedConfig(propertyNameOrPath) {
    const themeName = getThemeName();
    const brandConfigValue =_.get(wniExtendedConfig, `${themeName}.${propertyNameOrPath}`);

    return brandConfigValue;
}

function getBrandedOktaConfig() {
    return getBrandedConfig('okta');
}
function getBrandedAOUrlConfig() {
    let retval = getBrandedConfig('aoUrl');
    if (_.isEmpty(retval) || AO_URL_KEYS.includes(retval)) {
        retval = productionURL;
    }
    return retval;
}

function getBrandedDefaultStates() {
    return getBrandedConfig('defaultQuoteStates');
}

export default {
    getLobQuoteURL,
    getUuid,
    getAppBaseRouterURL,
    getBrandedOktaConfig,
    getBrandedAOUrlConfig,
    getBrandedDefaultStates
};
