import React, { useEffect } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './CPPLocationComponent.metadata.json5';

function CPPLocationComponent(props) {
    const {
        currentRow,
        viewModelService,
        onValidate,
        onValueChange,
        showErrors,
        isReadOnly,
        stateOptions
    } = props;

    const locationVM = viewModelService.clone(currentRow);
    const writeValue = async(value, path) => {
        onValueChange(value, path);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        addressLookup: {
            model: locationVM,
            dataPath: 'address',
            onAddressChange: onValueChange,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true
            },
            availableStates: stateOptions,
            showErrors,
            onValidate,
            readOnly: isReadOnly,
            defaultCountryRequired: false
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, locationVM, fieldId, fieldPath, overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={locationVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={writeValue}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default CPPLocationComponent;
