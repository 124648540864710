import React, { Component, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { CancellationService } from 'gw-capability-gateway';
import { useProductsData } from 'wni-portals-util-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DocumentsUtil, WniCancellationUtil } from 'wni-portals-util-js';
import { WniCustomCancellationService, WniSubmissionService } from 'wni-capability-gateway';
import { PageHeaderComponent as FormatPageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../Common/CommonRouting';
import metadata from './Cancellation.metadata.json5';
import styles from './Cancellation.module.scss';
import pageRouting from '../Common/Cancellation-config.json5';


function Cancellation(props) {
    const {
        authHeader,
        match: { 
            url,
            params: {
                jobNumber 
            } 
        },
        location: { pathname }
        
    } = props;


    const routePath = pathname && (pathname.trim().split('/') || []);
    const selectedPageFromPath = routePath[routePath.length - 1];
    const activeTile = selectedPageFromPath || 'summary';


    const [currentView, updateCurrentView] = useState('summary');
    const [tilesCountCancellationData, updateTilesCountCancellationData] = useState({});
    const [cancellation, updateCancellation] = useState({});

    const cancellationVM = { cancellation, tilesCountCancellationData };

    const [isCLLine, updateIsCLLine] = useState(false);



    const handleTilesOnClick = (id) => {
        updateCurrentView(id)
        
    }

    const getCancellationSummaryCount = async() => {
        const tilesData = await CancellationService.getCancellationSummary(jobNumber, authHeader)
        const attachments = await WniCustomCancellationService.getPortalAttachmentsForCancellation([jobNumber], authHeader)
        _.set(tilesData, 'numberOfDocuments', attachments.length);
        updateCurrentView(activeTile);
        updateTilesCountCancellationData(tilesData);
    }


    const updateCancellationSummary = async (cancellationData) => {
        const policyData = _.get(cancellationData, 'policy');
        getCancellationSummaryCount();
        const sysLocalDate = await WniSubmissionService.getSystemDate(authHeader);
        const res = WniCancellationUtil.updateCancellationDate(policyData, cancellationData, sysLocalDate);
        updateCancellation(res)
        const isCL = _.get(res, 'policy.account.accountHolder.contactType_Ext') === 'company';
        updateIsCLLine(isCL);

    }

    const getJobByJobNumber = useCallback( async() => {

        const res = await CancellationService.findJobByJobNumber(jobNumber, authHeader);
        updateCancellationSummary(res);
    }, [authHeader, jobNumber])
    
    // const render = () => {
    

    
    useEffect(() => {
        getCancellationSummaryCount();
        getJobByJobNumber();
    }, [])

    const quoteData = {
        jobNumber: jobNumber,
        loadCancellationSummary: cancellation,
        updateJobSummary: updateCancellationSummary,
        getCancellationSummaryCount: getCancellationSummaryCount
    };

    const overrides = {
        [currentView]: {
            active: true
        },
        activities: {
            visible: false
        },
        notes: {
            visible: false
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleTilesOnClick: handleTilesOnClick
        },
        resolveComponentMap: {
            pageheadercomponent: FormatPageHeaderComponent
        }
    };

    
    if (_.isEmpty(cancellation)) {
        return null;
    }

    return (
            <><ViewModelForm
            uiProps={metadata.pageContent}
            model={cancellationVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap} />
            <CommonRoutes
                steps={pageRouting}
                basePath={url}
                quoteDetailsData={quoteData} />
            </>
    );
        // };
        // return render();
    }

Cancellation.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            jobNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired
};

export default withRouter(withAuthenticationContext(Cancellation));
