import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('puHouseholdInfoService'), method, data, additionalHeaders);
}

export default class PUHouseholdInfoService {


    /**
     * save info page data
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static savePUHouseholdInfoData(quoteID, sessionUUID, questionSetData, quoteBaseData, puHouseholdInfoData, authHeader) {
        return processSubmission('savePUHouseholdInfoData', [quoteID, sessionUUID, questionSetData, quoteBaseData, puHouseholdInfoData], authHeader);
    }

    /**
     * remove resident after click on trash button
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} toRemovePublicID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
        static removeResidents(quoteID, sessionUUID, toRemovePublicID, authHeader) {
            return processSubmission('removeResidents', [quoteID, sessionUUID, toRemovePublicID], authHeader);
        }

    /**
     * add new resident
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
        static createResidents(quoteID, sessionUUID, toAddUMBDriverDTO, authHeader) {
            return processSubmission('createResidents', [quoteID, sessionUUID, toAddUMBDriverDTO], authHeader);
        }



        /**
     * add new resident
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
        static updateResidents(quoteID, sessionUUID, listToUpdate, authHeader) {
            return processSubmission('updateResidents', [quoteID, sessionUUID, listToUpdate], authHeader);
        }
}