import {
wizardProps
} from '@xengage/gw-portals-wizard-react';
import { FNOLHOIncidentDetailsPage as HOIncidentDetailsPage } from 'wni-capability-fnol-ho-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';


function FNOLDPIncidentDetails(props) {
    
    return <HOIncidentDetailsPage {...props} />;

}

FNOLDPIncidentDetails.propTypes = wizardProps;
FNOLDPIncidentDetails.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(FNOLDPIncidentDetails);
 