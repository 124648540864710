import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { FNOLPATypeOfRepairPage } from 'wni-capability-fnol-pa-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import messages from '../../FNOLCA.messages';
import FNOLCARepairFacilityPage from '../RepairFacility/FNOLCARepairFacilityPage.pe';
import FNOLCARepairFacilityMapPage from '../RepairFacilityMap/FNOLCARepairFacilityMapPage.pe';

const repairFacility = {
    path: '/repair',
    component: FNOLCARepairFacilityPage,
    title: messages.wizardPARepairFacility,
    stepProps: {
        template: WizardPageTemplateWithTitle,
    },
};

const repairFacilityMap = {
    path: '/repair',
    component: FNOLCARepairFacilityMapPage,
    title: messages.wizardPARepairFacility,
    stepProps: {
        template: WizardPageTemplateWithTitle,
    },
};

function FNOLCATypeOfRepairPage(props) {
    const extraProps = {
        repairFacility,
        repairFacilityMap,
        ...props
    }
    return <FNOLPATypeOfRepairPage {...extraProps} />;
}

FNOLCATypeOfRepairPage.propTypes = wizardProps;
export default withRouter(FNOLCATypeOfRepairPage);
