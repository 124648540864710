import _ from 'lodash';
import moment from 'moment';
import messages from './WALIncidentsAndReportsPage.messages';

const AddViewEditManualClueTitle = [
    messages.AddPriorLossesEntry.defaultMessage,
    messages.EditPriorLossesEntry.defaultMessage,
    messages.ViewPriorLossesEntry.defaultMessage
]
const ViewEditVendorClueTitle = [
    messages.ViewPriorLossesVendor.defaultMessage,
    messages.EditPriorLossesVendor.defaultMessage
]
const DuplicateClueSurchargeApplied = [
    'nousecluerecord', 'No_Duplicate' // No - Use CLUE Record, No - Duplicate Accident Charged
]
const DuplicateMVRSurchargeApplied = [
    'No_Charge', 'nodupviolationcharge' // No - Charge MVR incident, No - Duplicate Violation Charged
]

// For Clue/MVR records, if manual records are duplicate to the vendor records, then after policy issued, only display the vendor records in the policy changes
const filterCLUEManuallyData = (data) => {
    // from clue-path-'surChargeApply' and mvr-path-'chargeableApply', we can identify which record is duplicate
    const filteredCLUEManuallyData = data.filter((item) => !DuplicateClueSurchargeApplied.includes(_.get(item, 'surChargeApply')));
    return filteredCLUEManuallyData
};
const filterMVRManuallyData = (data) => {
    const filteredMVRManuallyData = data.filter((item) => !DuplicateMVRSurchargeApplied.includes(_.get(item, 'chargeableApply')));
    return filteredMVRManuallyData
};
// Non-duplicate loss title for policy change
const getManualClueTitle = (isFilteredManualClueAvaliable, filteredManualCLUE, manuallyClue) => {
    if (isFilteredManualClueAvaliable) {
        if (filteredManualCLUE.length > 1) {
            return `PRIOR LOSSES ${filteredManualCLUE.length} RECORDS`
        } 
        return `PRIOR LOSSES ${filteredManualCLUE.length} RECORD`
    } 
    if (manuallyClue.length > 1) {
        return `PRIOR LOSSES ${manuallyClue.length} RECORDS`
    }
    return`PRIOR LOSSES ${manuallyClue.length} RECORD`
    
}
const getManualMVRTitle = (isFilteredManualMVRAvaliable, filteredManualMVR, manuallyMVR) => {
    if (isFilteredManualMVRAvaliable) {
        if (filteredManualMVR.length > 1) {
            return `VIOLATIONS ${filteredManualMVR.length} RECORDS`
        } 
        return `VIOLATIONS ${filteredManualMVR.length} RECORD`
    } 
    if (manuallyMVR.length > 1) {
        return `VIOLATIONS ${manuallyMVR.length} RECORDS`
    }
    return `VIOLATIONS ${manuallyMVR.length} RECORD`
}
/**
 * add the regular drivers into Clue enter driver list
 * @param {object} drivers
 * @param {object} messages
 * @returns {object}
 */
function addRegularDrivers(drivers) {
    drivers.push(
        {
            code: messages.DriverHousehold.defaultMessage,
            name: messages.DriverHousehold.defaultMessage
        }
    );
    drivers.push(
        {
            code: messages.NotApplicable.defaultMessage,
            name: messages.NotApplicable.defaultMessage
        }
    );
    drivers.push(
        {
            code: messages.PermissiveDriver.defaultMessage,
            name: messages.PermissiveDriver.defaultMessage
        }
    );
    drivers.push(
        {
            code: messages.Unoccupied.defaultMessage,
            name: messages.Unoccupied.defaultMessage
        }
    );
    return drivers;
}

function sortReports(reports) {
    const newReports = reports.map((report)=> {
        return {...report, incidentDateTemp: moment(report.incidentDate).format()};
    });
    const sortedMVRReport = _.sortBy(newReports, ['driverName', 'incidentDateTemp', 'description']);
    return sortedMVRReport;
}

export default {
    AddViewEditManualClueTitle,
    ViewEditVendorClueTitle,
    addRegularDrivers,
    sortReports,
    filterCLUEManuallyData,
    filterMVRManuallyData,
    getManualClueTitle,
    getManualMVRTitle
};