import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import CoveragesTermsIterableComponent from '../CoveragesTermsIterableComponent/CoveragesTermsIterableComponent';
import metadata from './CoveragesIterableComponent.metadata.json5';
import styles from '../../Policies/Summary/Summary.module.scss';

function CoveragesIterableComponent(props) {
    const { data: coveragesData } = props;

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            coveragestermsiterablecomponent: CoveragesTermsIterableComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveragesData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

CoveragesIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired
};

export default CoveragesIterableComponent;
