import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@jutro/components';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import styles from '../SpreadsheetProcessorResult.module.scss';

function SectionTemplate({
    icon, message, value, type, content
}) {
    const translator = useTranslator();
    const iconClass = classnames({
        [styles.error]: type === 'error' || type === 'warning',
        [styles.success]: type !== 'error'
    });
    return (
        <>
            <div className={styles.section}>
                <div className={styles.icon}>
                    {icon && <Icon icon={icon} className={iconClass} />}
                </div>
                <div className={styles.report}>
                    <FormattedMessage {...message} values={{ count: <strong>{value}</strong> }} />
                </div>
            </div>
            {content && <p className={styles.gwResultContents}>{translator(content)}</p>}
        </>
    );
}

SectionTemplate.propTypes = {
    icon: PropTypes.string,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    content: PropTypes.string
};
SectionTemplate.defaultProps = {
    icon: undefined,
    content: undefined
};
export default SectionTemplate;
