


import React from 'react';
import { IMBaileeCustomersFloaterPage } from 'wni-capability-quoteandbind-im-react';


const IMBaileeCustomersFloaterChangePage = (props) => {
    return <IMBaileeCustomersFloaterPage {...props} isPolicyChange />
};
export default IMBaileeCustomersFloaterChangePage
