import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import WCDummyPage from './pages/Dummy/WCDummyPage';
import WCDummyReadOnlyPage from './pages/Dummy/WCDummyPage.readonly';

import WCQualificationPage from './pages/Qualification/WCQualificationPage';
import WCQualificationReadOnlyPage from './pages/Qualification/WCQualificationPage.readonly';

import WCPolicyDetailsPage  from './pages/PolicyDetails/WCPolicyDetailsPage';
import WCPolicyDetailsReadOnlyPage from './pages/PolicyDetails/WCPolicyDetailsPage.readonly';

import WCCoveragesPage from './pages/Coverages/WCCoveragesPage';
import WCCoveragesReadOnlyPage from './pages/Coverages/WCCoveragesPage.readonly';

import WCLocationsPage from './pages/Locations/WCLocationsPage';
import WCLocationsReadOnlyPage from './pages/Locations/WCLocationsPage.readonly';

import WCStateSpecificInformationPage from './pages/StateSpecificInformation/WCStateSpecificInformationPage';
import WCStateSpecificInformationReadOnlyPage from './pages/StateSpecificInformation/WCStateSpecificInformationPage.readonly';

import WCQuotePage  from './pages/Quote/WCQuotePage';
import WCQuoteReadOnlyPage from './pages/Quote/WCQuotePage.readonly';

import WCUnderwritingQuestionsPage  from './pages/UnderwritingQuestions/WCUnderwritingQuestionsPage';
import WCUnderwritingQuestionsReadOnlyPage from './pages/UnderwritingQuestions/WCUnderwritingQuestionsPage.readonly';

import WCRiskAnalysisPage from './pages/RiskAnalysis/WCRiskAnalysisPage';
import WCRiskAnalysisReadOnlyPage from './pages/RiskAnalysis/WCRiskAnalysisPage.readonly';

import WCPayAsYouGoComponent from './pages/Quote/components/PayAsYouGo/WCPayAsYouGoComponent';
import WCPAYGPopup from './pages/Quote/components/PayAsYouGo/WCPAYGPopup/WCPAYGPopup';

setComponentMapOverrides(
    {
        WCDummyPage: {component: 'WCDummyPage'},
        WCDummyReadOnlyPage: { component: 'WCDummyReadOnlyPage' },

        WCQualificationPage: {component: 'WCQualificationPage'},
        WCQualificationReadOnlyPage: { component: 'WCQualificationReadOnlyPage' },

        WCPolicyDetailsPage: {component: 'WCPolicyDetailsPage'},
        WCPolicyDetailsReadOnlyPage: { component: 'WCPolicyDetailsReadOnlyPage' },

        WCCoveragesPage: {component: 'WCCoveragesPage'},
        WCCoveragesReadOnlyPage: { component: 'WCCoveragesReadOnlyPage' },

        WCLocationsPage: {component: 'WCLocationsPage'},
        WCLocationsReadOnlyPage: { component: 'WCLocationsReadOnlyPage' },

        WCStateSpecificInformationPage: {component: 'WCStateSpecificInformationPage'},
        WCStateSpecificInformationReadOnlyPage: {component: 'WCStateSpecificInformationReadOnlyPage'},

        //
        WCQuotePage: {component: 'WCQuotePage'},
        WCQuoteReadOnlyPage: {component: 'WCQuoteReadOnlyPage'},

        WCUnderwritingQuestionsPage: {component: 'WCUnderwritingQuestionsPage'},
        WCUnderwritingQuestionsReadOnlyPage: { component: 'WCUnderwritingQuestionsReadOnlyPage' },
        
        WCRiskAnalysisPage: {component: 'WCRiskAnalysisPage'},
        WCRiskAnalysisReadOnlyPage: { component: 'WCRiskAnalysisReadOnlyPage' },
    },
    {
        WCDummyPage,
        WCDummyReadOnlyPage,

        WCQualificationPage,
        WCQualificationReadOnlyPage,

        WCPolicyDetailsPage,
        WCPolicyDetailsReadOnlyPage,

        WCCoveragesPage,
        WCCoveragesReadOnlyPage,

        WCLocationsPage,
        WCLocationsReadOnlyPage,

        WCStateSpecificInformationPage,
        WCStateSpecificInformationReadOnlyPage,
        //
        WCQuotePage,
        WCQuoteReadOnlyPage,

        WCUnderwritingQuestionsPage,
        WCUnderwritingQuestionsReadOnlyPage,

        WCRiskAnalysisPage,
        WCRiskAnalysisReadOnlyPage,
    }
);


export {
    WCDummyPage,
    WCDummyReadOnlyPage,

    WCQualificationPage,
    WCQualificationReadOnlyPage,

    WCPolicyDetailsPage,
    WCPolicyDetailsReadOnlyPage,

    WCCoveragesPage,
    WCCoveragesReadOnlyPage,
    
    WCLocationsPage,
    WCLocationsReadOnlyPage,

    WCStateSpecificInformationPage,
    WCStateSpecificInformationReadOnlyPage,

    WCUnderwritingQuestionsPage,
    WCUnderwritingQuestionsReadOnlyPage,

    WCRiskAnalysisPage,
    WCRiskAnalysisReadOnlyPage,

    WCPayAsYouGoComponent,
    WCPAYGPopup
};

export { default as WCSubmissionWizard } from './WCSubmissionWizard';
export { default as WCWizardPage } from './templates/WCWizardPage';

export { default as WCQuoteIssueRenderHelper } from './pages/Quote/util/WCQuoteIssueRenderHelper';
export { default as WCQuotePageConstants } from './pages/Quote/config/WCQuotePage.config';
