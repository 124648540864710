import _ from 'lodash';
import IssuanceValidationUtil from './IssuanceValidationUtil/IssuanceValidationUtil';
import VehicleUtil from './VehicleUtil';

function isCoverageSelected(coverages, name) {
    if (!coverages || !coverages.value) {
        return false;
    }
    return coverages.value.filter((coverage) => coverage.name === name
        && coverage.selected).length > 0;
}

/*
    POI-1207:
    Named Non Owner vehicle doesn't have stated value
    POI-7934 01.0010 11:
    Stated Value is required for motorhome and trailers
    when Stated amount other than collision or stated amount collision is selected for PPA.
 */
function checkStatedValueRequired(vehicleType, coverages, isRequiredForIssuance) {
    let vehicleTypeCode;
    if (vehicleType instanceof Object) {
        vehicleTypeCode = _.get(vehicleType, 'code');
    } else {
        vehicleTypeCode = vehicleType;
    }
    if (vehicleTypeCode === 'privatepassengerauto_Ext') {
        return (isCoverageSelected(coverages, 'Stated Amount Collision') || isCoverageSelected(coverages, 'Stated Amount Other Than Collision')) && isRequiredForIssuance;
    }
    // Motor Home, Utility Trailer, Recreational Trailer
    const types = ['motorhome_Ext', 'utilitytrailer_Ext', 'rectrailer_Ext'];
    return types.includes(vehicleTypeCode);
}

function validateFieldsBlockingIssuance(
    {
        currentRow,
        translator,
        message,
        isQuoteForNamedNonOwner = false
    }
) {
    if (!currentRow) {
        return [];
    }
    const fieldMessageMap = {};
    if (!isQuoteForNamedNonOwner) {
        fieldMessageMap.licenseState = 'Registered State';
        if (currentRow.value.otherOwnersAssignedFlag_Ext) {
            fieldMessageMap.otherOwners_Ext = 'Other Owner(s)';
        } else {
            fieldMessageMap.owners_Ext = 'Owner(s)';
        }
    }
    return IssuanceValidationUtil
        .getWarningMessages(currentRow, fieldMessageMap, translator,
            message);
}

function validateQuestionsBlockingIssuance(
    {
        currentRow,
        translator,
        message,
        isQuoteForNamedNonOwner = false
    }
) {
    if (!currentRow) {
        return [];
    }
    const { vehicleType_Ext: vehicleType } = currentRow.value;
    // questions implemented as field
    const fieldMessageMap = {
    };
    // questions implemented as questionSet
    const questionMessageMap = {
    };
    if (!isQuoteForNamedNonOwner) {
        questionMessageMap['vehicleQuestionSets_Ext[0].answers.VehModHasSpecialEquipment_Ext'] = 'Is the vehicle modified or does the vehicle have any special equipment?';
        questionMessageMap['vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext'] = 'Is there any existing damage to the vehicle (including damaged glass)?';

        const isGlassDamages = _.get(currentRow.value, 'vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext');
        if (!_.isNil(isGlassDamages) && isGlassDamages !== 'false' && isGlassDamages !== false) {
            questionMessageMap['vehicleQuestionSets_Ext[0].answers.vehDamagesEplanation_Ext'] = 'Please explain the damages';
        }

        if (vehicleType === 'motorhome_Ext') {
            questionMessageMap.motorHomeRented_Ext = 'Is this motorhome occasionally rented to others?';
        }

        if (vehicleType === 'rectrailer_Ext') {
            questionMessageMap.vehRecreationalTrailer_Ext = 'Is this recreational trailer occasionally rented to others?';
            questionMessageMap.vehRTParked_Ext = 'Is this recreational trailer parked at a single seasonal site for longer than 120 days?';
        }

        if (currentRow.value.motorHomeRented_Ext || currentRow.value.vehRecreationalTrailer_Ext) {
            questionMessageMap.milePercentPerYear_Ext = 'Of the total miles driven per year, what percent occurs when rented to others?';
        }
    }

    // const defaultMessage = {
    //     id: 'quoteandbind.vehicle.question.required.issuance.default',
    //     defaultMessage: 'Below required fileds for issuance are missing: '
    // };
    const warningsForField = IssuanceValidationUtil
        .getWarningMessages(currentRow, fieldMessageMap, translator, message);
    const warningsForQuestion = IssuanceValidationUtil
        .getWarningMessages(currentRow, questionMessageMap, translator, message);
    return _.uniq(warningsForField.concat(warningsForQuestion));
}

function validateFieldsBlockingQuote(
    {
        currentRow,
        translator,
        message,
        isQuoteForNamedNonOwner = false
    }
) {
    if (!currentRow) {
        return [];
    }
    const fieldMessageMap = {};
    return IssuanceValidationUtil
        .getErrorMessages(currentRow, fieldMessageMap, translator,
            message);
}

function getVehicleNotifications(
    {
        currentRow,
        translator,
        messageForIssuance,
        messageForQuote,
        isQuoteForNamedNonOwner
    }
) {
    const warningMessagesForField = validateFieldsBlockingIssuance({
        currentRow,
        translator,
        message: messageForIssuance,
        isQuoteForNamedNonOwner
    });
    const warningMessagesForQuestion = validateQuestionsBlockingIssuance({
        currentRow,
        translator,
        message: messageForIssuance,
        isQuoteForNamedNonOwner
    });
    const errorMessages = validateFieldsBlockingQuote({
        currentRow,
        translator,
        message: messageForQuote,
        isQuoteForNamedNonOwner
    });
    const allMessage = [
        ...warningMessagesForField.map((text) => {
            return {
                type: 'warning',
                reason: text,
                isForInsurance: true
            };
        }),
        ...warningMessagesForQuestion.map((text) => {
            return {
                type: 'warning',
                reason: text,
                isForInsurance: true
            };
        }),
        ...errorMessages.map((text) => {
            return {
                type: 'error',
                reason: text
            };
        })
    ];
    return allMessage;
}

function allVehiclesValid(vehicleVMs) {
    return vehicleVMs.every((vm) => vm.aspects.valid && vm.aspects.subtreeValid);
}

function isVINComponentValid(
    {
        isQuoteForNamedNonOwner = false,
        quoteStatus,
        vehicleVM,
        foundVehicle,
        isNewVehicleReplacementSelected,
        isRequiredForIssuance,
        vinReg,
        baseOnNull
    }
) {
    if(VehicleUtil.isPPAOnVehicle(vehicleVM) && !vinReg.test(_.get(vehicleVM.value, 'vin')) && baseOnNull){
        return false;
    }
    if (isQuoteForNamedNonOwner || quoteStatus.code === 'Rated' || quoteStatus.code === 'Draft') {
        // Named Non Owner Vehicle doesn't need VIN and related info.
        return true;
    }

    const costNew = _.get(vehicleVM.value, 'costNew');
    if (isRequiredForIssuance && isNewVehicleReplacementSelected && _.isEmpty(costNew)) {
        return false;
    }
    return vehicleVM.aspects.subtreeValid && vehicleVM.aspects.valid;
}

const isCostNewVisible = (isQuoteForNamedNonOwner, vehicleTypeCode) => {
    // Named Non Owner vehicle doesn't have cost new
    if (isQuoteForNamedNonOwner) {
        return false;
    }
    const types = ['motorhome_Ext', 'utilitytrailer_Ext', 'rectrailer_Ext'];
    return !types.includes(vehicleTypeCode);
};

const isCostNewRequired = (
    {
        isQuoteForNamedNonOwner,
        year,
        vin = '',
        isNewVehicleReplacementSelected,
        foundVehicle,
        isRequiredForIssuance
    }
) => {
    // POI-1207
    // Named Non Owner vehicle doesn't have cost new
    if (isQuoteForNamedNonOwner) {
        return false;
    }
    return isRequiredForIssuance && isNewVehicleReplacementSelected;
    // // POI-7750
    // // If a valid VIN is entered then Cost new is not required.
    // // This value is populated from ADP Integration VIN response in this case
    // // If VIN entered is not valid and user has selected Other than Collision Coverage
    // // then cost new is a required field
    // const vinLength = vin.length;
    // const invalid = VehicleUtil.isVinInvalid(year, vinLength, foundVehicle);
    // return invalid && isOtherThanCollisionSelected;
};

const getPCDisplayIssues = (submission) => {
    const warningMessage1 = _.get(submission, 'errorsAndWarnings.pcDisplayMessage_Ext.warnings', []);
    // policy change
    const warningMessage2 = _.get(submission, 'errorsAndWarnings_Ext.pcDisplayMessage_Ext.warnings', []);
    const allWarningMessages = warningMessage1.concat(warningMessage2).map((warning) => {
        return {
            reason: warning,
            type: 'warning'
        };
    });
    const errorMessage1 = _.get(submission, 'errorsAndWarnings.pcDisplayMessage_Ext.errors', []);
    // policy change
    const errorMessage2 = _.get(submission, 'errorsAndWarnings_Ext.pcDisplayMessage_Ext.errors', []);
    const allErrorMessages = errorMessage1.concat(errorMessage2).map((error) => {
        return {
            reason: error,
            type: 'error'
        };
    });
    return allWarningMessages.concat(allErrorMessages);
};

const getIssuesInVehiclePage = (validationIssues) => {
    const issuesInDriverPage = validationIssues.filter((issue) => _.isNil(issue.flowStepId) || !issue.flowStepId.includes('PADriverAssignmentPage'));
    const filterIssuesType = IssuanceValidationUtil.getIssuesMap(issuesInDriverPage);
    return filterIssuesType;
};

const getIssuesInAssignmentPage = (validationIssues,) => {
    return validationIssues.filter((issue) => !_.isNil(issue.flowStepId) && issue.flowStepId.includes('PADriverAssignmentPage'));
};


function isNewVehicleReplacementCoverageSelected(coverages) {
    const newVehicleReplacementCostCollisionCoverageSelected = isCoverageSelected(coverages, 'New Vehicle Replacement Cost Collision Coverage');
    const newVehicleReplacementCostOtherthanCollisionCoverageSelected = isCoverageSelected(coverages, 'New Vehicle Replacement Cost Other than Collision Coverage');
    return newVehicleReplacementCostCollisionCoverageSelected
        || newVehicleReplacementCostOtherthanCollisionCoverageSelected;
}

const isVehicleValid = (vehicleVM, coverages, isQuoteForNamedNonOwner, isRequiredForIssuance, validVinInfo) => {
    const {
        vin,
        year,
        statedValue_Ext: statedValue,
        costNew,
        vehicleType_Ext: vehicleType = {},
        licenseState,
        owners_Ext: owners = [],
        otherOwners_Ext: otherOwners,
        otherOwnersAssignedFlag_Ext: isOtherOwnerSelected = false,
        additionalInterests = [],
        isStatedValueRequired_Ext: isStatedValueRequired
    } = vehicleVM.value;

    const dtoValid = vehicleVM.aspects.valid && vehicleVM.aspects.subtreeValid;
    const jsValidArray = [];

    if (isStatedValueRequired) {
        jsValidArray
            .push(!_.isNil(statedValue) && !_.isNil(statedValue.amount));
    } else {
        jsValidArray.push(true);
    }

    // const isOtherThanCollisionSelected = isCoverageSelected(coverages, 'Other Than Collision');
    const isNewVehicleReplacementSelected = isNewVehicleReplacementCoverageSelected(coverages);
    const errorCode = _.get(validVinInfo, `${vin}.errorCode`);
    const foundVehicle = _.isEmpty(errorCode);
    const isCostNewFieldRequired = isCostNewVisible(isQuoteForNamedNonOwner, vehicleType)
        && isCostNewRequired({
            isQuoteForNamedNonOwner,
            year,
            vin,
            isNewVehicleReplacementSelected,
            foundVehicle,
            isRequiredForIssuance
        });
    if (isCostNewFieldRequired) {
        jsValidArray
            .push(!_.isNil(costNew) && !_.isNil(costNew.amount));
    } else {
        jsValidArray.push(true);
    }

    // if (isRequiredForIssuance) {
    //     // valid if fields that are Required For Issuance
    //     if (vehicleType !== 'noVehicleNamedNonOwner_Ext' && (_.isNil(licenseState)
    //         || (!isOtherOwnerSelected && _.isEmpty(owners))
    //         || (isOtherOwnerSelected && _.isNil(otherOwners)))) {
    //         jsValidArray.push(false);
    //     }
    // }
    if (!_.isEmpty(additionalInterests)
        && !(vehicleVM.additionalInterests.aspects.valid
            && vehicleVM.additionalInterests.aspects.subtreeValid)) {
        jsValidArray.push(false);
    }

    return dtoValid && jsValidArray.every((bool) => bool);
};

function eraseVehicleTypeIfNNOPolicyWithNotNNOVehicle(vehicleVMs, isQuoteForNamedNonOwner) {
    let result = true;
    _.forEach(vehicleVMs, (vehicle) => {
        if ((isQuoteForNamedNonOwner && vehicle.value.vehicleType_Ext !== 'noVehicleNamedNonOwner_Ext') || (!isQuoteForNamedNonOwner && vehicle.value.vehicleType_Ext === 'noVehicleNamedNonOwner_Ext')) {
            // set vehicle type to null if vehicle doesn't match policy type
            _.unset(vehicle.value, 'vehicleType_Ext');
            VehicleUtil.clearFields(vehicle);
            if (result) {
                result = false;
            }
        }
    });
    return result;
}

function namedNonOwnerVehicleExist(vehicleVMList) {
    return vehicleVMList
        .filter((v) => VehicleUtil.isNamedNonOwner(v.value.vehicleType_Ext)).length > 0;
}

export default {
    isCoverageSelected,
    checkStatedValueRequired,
    getVehicleNotifications,
    allVehiclesValid,
    isVINComponentValid,
    isCostNewVisible,
    isCostNewRequired,
    getPCDisplayIssues,
    getIssuesInVehiclePage,
    getIssuesInAssignmentPage,
    isVehicleValid,
    eraseVehicleTypeIfNNOPolicyWithNotNNOVehicle,
    namedNonOwnerVehicleExist,
    isNewVehicleReplacementCoverageSelected
};
