import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { R1Config } from 'wni-portals-config-js';
import WniClausesUtil from './Clause/WniClausesUtil';
import VehicleUtil from './VehicleUtil';

const {
    PACoverageConfig: {
        notImmediatelyUpdateOnSelectCoverages,
        notImmediatelyUpdateOnUnSelectCoverages,
        coverageCodesOfImmediatelyUpdateCoverageTerms,
    }
} = R1Config;

function defaultSelectedLineCoverage() {
    return [
        'PABILiabilityCov',
        'PAPDLiabilityCov',
        'PALiabilityCSLCov',
        'PA_NO_Liab_BI_EXT',
        'PA_NO_Liab_PD_EXT',
        'PA_NO_Liab_CSL_EXT',
        'PAMedPayCov',
        'PA_NO_MedPay_EXT',
        'PAUMNonStackIA_Ext',
        'PAUMNonStackIACSL_Ext',
        'PANonOwnedUMIA_Ext',
        'PANonOwnedUMIA_CSL_Ext',
        'PAUIMNonStackIA_Ext',
        'PAUIMNonStackCSLIA_Ext',
        'PA_NO_UIMIA_EXT',
        'PA_NO_UIMIACSL_EXT',
        'PAUIMWICovSplit_EXT',
        'PAUIMWICovCSL_EXT',
        'PA_NO_UIMWISplit_EXT',
        'PA_NO_UIMWICSL_EXT',
        'PAUMUIMAkBICov_EXT',
        'PAUMUIMAkPDCov_EXT',
        'PAUMUIMAkCSLCov_EXT',
        'PA_NO_UMUIMAK_BI_EXT',
        'PA_NO_UMUIMAK_PD_EXT',
        'PA_NO_UMUIMAK_CSL_EXT',
        'PAPetInjuryCov_EXT'
    ];
}

function defaultSelectedVehicleCoverage() {
    return ['PALiabilityCovExc_EXT'];
}

function defaultAccordionStates(otherCoverages, driverCoverages, updateIfUpdateaccordionStates, ifUpdateaccordionStates) {
    if (!ifUpdateaccordionStates) {
        return false;
    }
    const lineCoverages = _.get(otherCoverages, 'lineCoverages');
    const vehicleCoverages = _.get(otherCoverages, 'vehicleCoverages');

    const res = [];
    const isEditedLineCoveragesValue = _.find(lineCoverages, c => {
        return _.find(_.get(c, 'terms'), term => {
            return _.get(term, 'chosenTermValue') && c.code_Ext != 'PAPetInjuryCov_EXT';
        });
    });
    const isEditedLine = _.find(lineCoverages, cov => {
        return (cov.selected
            && !_.find(defaultSelectedLineCoverage(), c => { return c === cov.code_Ext; }))
            || isEditedLineCoveragesValue;
    });

    const isEditedVehicle = _.find(vehicleCoverages, vehicle => {
        return _.find(vehicle.coverages, cov => {
            return cov.selected && !_.find(defaultSelectedVehicleCoverage(), c => { return c === cov.code_Ext; });
        });
    });

    const isEditedDriver = _.find(driverCoverages, driver => {
        return _.find(driver.coverages, cov => {
            return cov.selected;
        });
    });

    if (!isEditedLine) {
        res.push('lineCoverages');
    }
    if (!isEditedVehicle) {
        res.push('vehicleCoverages');
    }
    if (!isEditedDriver) {
        res.push('driverCoverages');
    }
    updateIfUpdateaccordionStates(false);
    // return res;
    return ['lineCoverages', 'vehicleCoverages', 'driverCoverages'];
}

function isCoverageInvalid(coverage) {
    if (coverage.required && !coverage.selected) {
        return true;
    }
    if (coverage.hasTerms) {
        const invalidTerm = _.find(coverage.terms, (term) => {
            // Use chosenTerm instead of chosenTermValue.
            // chosenTermValue only update after vm is sent to PC
            return term.required && (term.chosenTerm !== false && !term.chosenTerm);
        });
        if (!_.isEmpty(invalidTerm)) {
            return true;
        }
    }
    return false;
}

function hasInvalidCoverage(coverages) {
    return _.find(coverages, (cov) => {
        return isCoverageInvalid(cov)
    });
}

function getAccordionStatesOverrides(otherCoverages, driverCoverages) {
    const lineCoverages = _.get(otherCoverages, 'lineCoverages');
    const vehicleCoverages = _.get(otherCoverages, 'vehicleCoverages');

    const hasInvalidLineCoverage = hasInvalidCoverage(lineCoverages);

    const hasInvalidVehicleCoverage = _.find(vehicleCoverages, (vehicle) => {
        return hasInvalidCoverage(vehicle.coverages);
    });

    const hasInvalidDriverCoverage = _.find(driverCoverages, (driver) => {
        return hasInvalidCoverage(driver.coverages);
    });

    return {
        lineCoverages: { errorState: hasInvalidLineCoverage },
        vehicleCoverages: { errorState: hasInvalidVehicleCoverage },
        driverCoverages: { errorState: hasInvalidDriverCoverage }
    };
}

const generateUpdatedCoveragesDTO = ({
    submissionVM,
    lobName,
    selectedVersionOfferingsIndex,
    // provide customization for QuotePage
    structureClausesForServer = WniClausesUtil.structureClausesForServer,
}) => {
    const lineCoveragePath = `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages.lineCoverages`;
    const vehicleCoveragePath = `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages.vehicleCoverages`;

    const lineCoverageClauses = _.get(submissionVM, `${lineCoveragePath}.value`);
    const vehicleCoverageClauses = _.get(submissionVM, `${vehicleCoveragePath}.value`);

    const lineCoverageClausesToUpdate = structureClausesForServer(lineCoverageClauses,
        lobName, 'lineCoverages');
    const vehicleCoverageClausesToUpdate = WniClausesUtil.structureClausesScheduleForServer(vehicleCoverageClauses,
        lobName, 'vehicleCoverages');

    const coveragesDTO = {};
    const lineCoveragesDTO = _.get(lineCoverageClausesToUpdate, `${lobName}.lineCoverages`);
    const vehicleCoveragesDTO = _.get(vehicleCoverageClausesToUpdate, `${lobName}.vehicleCoverages`);

    if (!_.isNil(lineCoveragesDTO)) {
        _.set(coveragesDTO, 'lineCoverages', lineCoveragesDTO);
    }
    if (!_.isNil(vehicleCoveragesDTO)) {
        _.set(coveragesDTO, 'vehicleCoverages', vehicleCoveragesDTO);
    }

    return {
        [lobName]: coveragesDTO,
    };
};

const checkExistenceOfUpdatedCoverage = (updatedCoveragesDTO) => {
    return _.values(updatedCoveragesDTO)
        .some((coveragesByLob) => _.values(coveragesByLob)
            .some((coveragesByClausesType) => !_.isNil(coveragesByClausesType)));
};

const isCoverageSelectOrUnSelectMustUpdateImmediately = (clause) => {
    if (clause.updated) {
        // 1. when coverage selected or unselected
        // return !_.includes(notImmediatelyUpdateCoverages, item.code_Ext);
        if (clause.selected) {
            return !_.includes(notImmediatelyUpdateOnSelectCoverages, clause.code_Ext);
        }
        return !_.includes(notImmediatelyUpdateOnUnSelectCoverages, clause.code_Ext);
    }
    return false;
};

const isCoverageTermMustUpdateImmediately = (clause) => {
    const coveragesTerms = _.get(clause, 'terms', []);
    const hasTermUpdated = coveragesTerms.some((term) => term.updated);
    if (hasTermUpdated) {
        // 2. when coverage term updated
        return _.includes(coverageCodesOfImmediatelyUpdateCoverageTerms, clause.code_Ext);
    }
    return false;
};

const isCoverageScheduleMustUpdateImmediately = (clause) => {
    const isScheduleUpdated = _.get(clause, 'schedule.updated_Ext');
    if (isScheduleUpdated) {
        // 3. when schedult item updated
        // nothing need sync to PC immediately yet in POI-18080
        return false;
    }
    return false;
};

const hasCoverageMustUpdateImmediately = (clausesToUpdate) => {
    if (_.isNil(clausesToUpdate)) {
        return false;
    }
    if (_.isArray(clausesToUpdate)) {
        return clausesToUpdate
            .some((item) => {
                if (item.code_Ext) {
                    const mustUpdateDueToCoverageSelectOrUnSelect = isCoverageSelectOrUnSelectMustUpdateImmediately(item);
                    const mustUpdateDueToTermChanged = isCoverageTermMustUpdateImmediately(item);
                    const mustUpdateDueToScheduleChanged = isCoverageScheduleMustUpdateImmediately(item);
                    // if (item.updated) {
                    //     // 1. when coverage selected or unselected
                    //     // return !_.includes(notImmediatelyUpdateCoverages, item.code_Ext);
                    //     if (item.selected) {
                    //         return !_.includes(notImmediatelyUpdateOnSelectCoverages, item.code_Ext);
                    //     }
                    //     return !_.includes(notImmediatelyUpdateOnUnSelectCoverages, item.code_Ext);
                    // }
                    // const coveragesTerms = _.get(item, 'terms', []);
                    // const hasTermUpdated = coveragesTerms.some((term) => term.updated);
                    // if (hasTermUpdated) {
                    //     // 2. when coverage term updated
                    //     return _.includes(coverageCodesOfImmediatelyUpdateCoverageTerms, item.code_Ext);
                    // }
                    // const isScheduleUpdated = _.get(item, 'schedule.updated_Ext');
                    // if (isScheduleUpdated) {
                    //     // 3. when schedult item updated
                    //     // nothing need sync to PC immediately yet in POI-18080
                    //     return false;
                    // }
                    return mustUpdateDueToCoverageSelectOrUnSelect
                        || mustUpdateDueToTermChanged
                        || mustUpdateDueToScheduleChanged;
                }
                return hasCoverageMustUpdateImmediately(item.coverages);
            });
    }
    return _.values(clausesToUpdate).some((lobValue) => hasCoverageMustUpdateImmediately(lobValue));
};

const updateCoveragesToServer = async ({
    submissionVM,
    updateCoveragesDTOToServerMethod,
    clausesToUpdate,
    authHeader,
    lobName,
    selectedVersionOfferingsIndex,
}) => {
    let updatedCoverages = [];
    let clauseTypeList = [];
    const jobType = _.get(submissionVM.value, 'baseData.jobType');
    const { quoteID, jobID, sessionUUID } = submissionVM.value;

    if (lobName === 'personalAuto') {
        clauseTypeList = ['lineCoverages', 'vehicleCoverages', 'driverCoverages'];
    }

    const clausesPathMap = {};
    clauseTypeList.forEach((clauseType) => {
        clausesPathMap[clauseType] = {
            path: `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages.${clauseType}`,
            valuePath: `lobData.${lobName}.offerings[${selectedVersionOfferingsIndex}].coverages.${clauseType}`,
        };
    });

    const response = await updateCoveragesDTOToServerMethod(quoteID || jobID, sessionUUID,
        clausesToUpdate,
        authHeader);

    switch (jobType) {
        case 'Submission': {
            updatedCoverages = clauseTypeList.map((clauseType) => {
                return {
                    path: clausesPathMap[clauseType].path,
                    updatedClauses: _.get(response, `${lobName}.${clauseType}`)
                        || _.get(response.lobData, `${lobName}.coverages.${clauseType}`),
                };
            });
            break;
        }
        case 'PolicyChange': {
            updatedCoverages = clauseTypeList.map((clauseType) => {
                return {
                    path: clausesPathMap[clauseType].path,
                    updatedClauses: _.get(response, clausesPathMap[clauseType].valuePath),
                };
            });
            break;
        }
        default: {
            // eslint-disable-next-line no-console
            console.log(`Unhandled job type: ${jobType}`);
        }
    }

    return {
        updatedCoverages,
        errorsAndWarning: _.get(response, 'errorsAndWarnings.pcDisplayMessage_Ext')
    };
};

const setAdditionalFieldsAfterCoverageUpdate = (
    submissionVM
) => {
    // Once coverages are changed, driver assignment flag need to be changed.
    const vehicleCoverages = _.get(submissionVM, 'value.lobData.personalAuto.offerings[0].coverages.vehicleCoverages');
    if (!_.isNil(vehicleCoverages) && !_.isEmpty(vehicleCoverages)) {
        vehicleCoverages.forEach((vehicleCoverage) => {
            const {
                fixedId,
                isDriverAssignmentAvailable_Ext: isDriverAssignmentAvailable
            } = vehicleCoverage;
            const vehicle = VehicleUtil.getVehicleDataById(submissionVM, fixedId);
            if (!_.isNil(vehicle)) {
                _.set(vehicle, 'isDriverAssignmentAvailable_Ext', isDriverAssignmentAvailable);
            }
        });
    }
    _.set(submissionVM, 'value.baseData.periodStatus', 'Draft');
    _.set(submissionVM, 'value.status', 'Draft');
};

/**
 * Sync all coverages to PC
 * @returns {submissionVM} updatedSubmissionVM
 */
const forceUpdateCoveragesWhenHasCoverageNotSync = async ({
    submissionVM,
    lobName,
    selectedVersionOfferingsIndex,
    updateCoveragesFunction,
    authHeader,
    updateCoveragesErrorsAndWarnings
}) => {
    const clausesToUpdate = generateUpdatedCoveragesDTO({
        submissionVM,
        lobName: lobName,
        selectedVersionOfferingsIndex,
    });
    const hasUpdatedCoverage = checkExistenceOfUpdatedCoverage(clausesToUpdate);
    if (hasUpdatedCoverage) {
        const { updatedCoverages, errorsAndWarning } = await updateCoveragesToServer({
            submissionVM,
            updateCoveragesDTOToServerMethod: updateCoveragesFunction,
            clausesToUpdate,
            authHeader,
            lobName,
            selectedVersionOfferingsIndex,
        });
        updatedCoverages.forEach((updateCoverage) => {
            _.set(submissionVM, updateCoverage.path, updateCoverage.updatedClauses);
        });
        setAdditionalFieldsAfterCoverageUpdate(submissionVM);
        if (updateCoveragesErrorsAndWarnings) {
            updateCoveragesErrorsAndWarnings(errorsAndWarning);
        }
    }
    return [submissionVM, hasUpdatedCoverage];
};

const getRemovedClausesIDByUpdatedCoverages = (oldSubmissionVM, newSubmissionVM, path) => {
    let clausesIDToBeRemoved = [];
    const oldCoverageVM = _.get(oldSubmissionVM, path);
    const newCoverageVM = _.get(newSubmissionVM, path);
    const clausesType = _.keys(oldCoverageVM.value);
    clausesType.forEach((clauseType) => {
        const oldCoverageVMByClauseType = _.get(oldCoverageVM, clauseType);
        const newCoverageVMByClauseType = _.get(newCoverageVM, clauseType);
        if (clauseType === 'lineCoverages') {
            const oldClauses = _.get(oldCoverageVMByClauseType, 'value');
            const newClauses = _.get(newCoverageVMByClauseType, 'value');
            const oldPublicIDs = oldClauses.map((clause) => clause.publicID);
            const newPublicIDs = newClauses.map((clause) => clause.publicID);
            clausesIDToBeRemoved = clausesIDToBeRemoved.concat(oldPublicIDs
                .filter((clauseID) => !_.includes(newPublicIDs, clauseID)));
        } else {
            const oldCoverageCoverableVMs = _.get(oldCoverageVMByClauseType, 'children');
            const newCoverageCoverableVMs = _.get(newCoverageVMByClauseType, 'children');
            oldCoverageCoverableVMs.forEach((oldCoverableVM) => {
                const coverablePublicID = _.get(oldCoverableVM, 'publicID.value');
                const newCoverableVM = newCoverageCoverableVMs
                    .find((coverable) => _.get(coverable, 'publicID.value') === coverablePublicID);
                const oldClauses = _.get(oldCoverableVM, 'coverages.value');
                const newClauses = _.get(newCoverableVM, 'coverages.value');
                const oldPublicIDs = oldClauses.map((clause) => clause.publicID);
                const newPublicIDs = newClauses.map((clause) => clause.publicID);
                clausesIDToBeRemoved = clausesIDToBeRemoved.concat(oldPublicIDs
                    .filter((clauseID) => !_.includes(newPublicIDs, clauseID)));
            });
        }
    });
    return clausesIDToBeRemoved;
};

export default {
    isCoverageInvalid,
    hasInvalidCoverage,
    defaultAccordionStates,
    getAccordionStatesOverrides,
    generateUpdatedCoveragesDTO,
    hasCoverageMustUpdateImmediately,
    updateCoveragesToServer,
    getRemovedClausesIDByUpdatedCoverages,
    //
    forceUpdateCoveragesWhenHasCoverageNotSync,
    setAdditionalFieldsAfterCoverageUpdate,
};
