import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import styles from '../SpreadsheetProcessorResult.module.scss';
import messages from '../CommercialPropertyResult.messages';
import { Link } from '@jutro/router';

function BreadCrumbTemplate({ showLessErrorsLink, callBackShowData }) {
    const showErrorLink = useCallback((event) => {
        event.preventDefault();
        callBackShowData();
    }, [callBackShowData]);

    const getShowLessErrorLinkElement = useCallback((messageContent) => {
        return (
            <Link href="/" id="showLessErrorsLink" onClick={showErrorLink}>
                {messageContent}
            </Link>
        );
    }, [showErrorLink]);
    const translator = useTranslator();
    const messageContent = !showLessErrorsLink ? messages.showLess : messages.showAll;
    const reportStyle = cx(styles.report, styles.iconSpacing);
    return (
        <div className={styles.section}>
            <div className={reportStyle}>
                <div className={styles.gwResultBreadcrumbs}>
                    {getShowLessErrorLinkElement(translator(messageContent))}
                </div>
            </div>
        </div>
    );
}
BreadCrumbTemplate.propTypes = {
    showLessErrorsLink: PropTypes.bool.isRequired,
    callBackShowData: PropTypes.func.isRequired
};
export default BreadCrumbTemplate;
