import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('rtIncidentsService'), method, data, additionalHeaders);
}

export default class WALCLUEService {
    static updateClue(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('updateClue', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static deleteClue(jobId, sessionUUID, publicId, additionalHeaders = {}) {
        return processSubmission('deleteClue', [jobId, sessionUUID, publicId], additionalHeaders);
    }

    static saveClue(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('saveClue', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static updateClueReport(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('updateClueReport', [jobId, sessionUUID, newModel], additionalHeaders);
    }
}