
/**
 * Default table rows to display
 */
const DEFAULT_SXS_TABLE_ROWS = [
    { rowId: 'policyType' },
    { rowId: 'branchName' },
    { rowId: 'withThePenaltyFreePromise' },
    { rowId: 'payTotal' },
    { rowId: 'premiumFullDiscount' },
    { rowId: 'underwritingIssues' },
    { rowId: 'eligibilityIssuesLink' },
    { rowId: 'accountClearanceLink' },
    { rowId: 'recommendedCoverages' },
    { rowId: 'submitAndDeleteActions' },
    { rowId: 'compareReviewViolationLink' },
    { rowId: 'compareReviewLossLink' },
    { rowId: 'compareReviewAllLink' },
    { rowId: 'clearMvrClueLink' },
];

const HO_SXS_TABLE_ROWS = [
    { rowId: 'periodStatus' },
    { rowId: 'policyType' },
    // { rowId: 'branchName' },
    { rowId: 'withThePenaltyFreePromise' },
    { rowId: 'payTotal' },
    { rowId: 'premiumFullDiscount' },
    { rowId: 'underwritingIssues' },
    { rowId: 'eligibilityIssuesLink' },
    { rowId: 'accountClearanceLink' },
    { rowId: 'recommendedCoverages' },
    { rowId: 'submitAndDeleteActions' },
    { rowId: 'compareReviewViolationLink' },
    { rowId: 'compareReviewLossLink' },
    { rowId: 'compareReviewAllLink' },
    { rowId: 'clearMvrClueLink' },
];

// eslint-disable-next-line import/prefer-default-export
export const SxsTableRowProfiles = {
    get DefaultRows() { // for PA
        // return [...DEFAULT_SXS_TABLE_ROWS]; // might prefer this one
        return DEFAULT_SXS_TABLE_ROWS;
    },
    get HoRows() { // for HO
        // return [...HO_SXS_TABLE_ROWS];
        return HO_SXS_TABLE_ROWS;
    }
};


// export default {
// };
