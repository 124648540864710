import { defineMessages } from 'react-intl';

/**
 * From PAQuoteChangePage.messages.js
 */
export default defineMessages({
    papolicychangePrintBtn: {
        id: 'pa.policychange.quote.views.Print',
        defaultMessage: 'Print',
    },
    papolicychangeQuotePremiumSummary: {
        id: 'pa.policychange.quote.views.Premium Summary',
        defaultMessage: 'Premium Summary',
    },
    papolicychangeQuoteSubPremiumSummary: {
        id: 'pa.policychange.quote.views.Includes taxes, Surcharges, & fees',
        defaultMessage: 'Includes taxes, Surcharges, & fees',
    },
    papolicychangeQuoteCurrentPremium: {
        id: 'pa.policychange.quote.views.Current Premium',
        defaultMessage: 'Current Premium',
    },
    papolicychangeQuoteChangeIncost: {
        id: 'pa.policychange.quote.views.Change in Cost',
        defaultMessage: 'Change in Cost',
    },
    papolicychangeQuoteNetPremium: {
        id: 'pa.policychange.quote.views.New Premium',
        defaultMessage: 'New Premium',
    },
    newPremiumCaculationDesc: {
        id: 'pa.policychange.quote.views.New Premium Caculation Desc',
        defaultMessage: '{totalCost} + {taxes}(Tax)',
    },
    increasNotificationTitle: {
        id: 'pa.policychange.quote.views.Increased Premium Title',
        defaultMessage: 'There is an increase in the premium',
    },
    decreasNotificationTitle: {
        id: 'pa.policychange.quote.views.Decreased Premium Title',
        defaultMessage: 'There is a decrease in the premium',
    },
    increasNotificationDesc: {
        id: 'pa.policychange.quote.views.Increased Premium Descripition',
        defaultMessage: 'The new premium is now {newPremium}.This is an increase of {changedIncost} on current premium and will be reflected in the upcoming invoices.'
    },
    decreasNotificationDesc: {
        id: 'pa.policychange.quote.views.Decreased Premium Descripition',
        defaultMessage: 'The new premium is now {newPremium}.This is a decrease of {changedIncost} on current premium and will be reflected in the upcoming invoices.'
    },
    // new messages added
    policySummaryHeader: {
        id: 'pa.policychange.quote.views.Policy Summary',
        defaultMessage: 'Policy Summary'
    },
    incluesTaxesHeader: {
        id: 'pa.policychange.quote.views.Includes taxes, surcharges & fees',
        defaultMessage: 'Includes taxes, surcharges & fees'
    },
    papolicychangeQuotePriorPremium: {
        id: 'pa.policychange.quote.views.Prior Premium',
        defaultMessage: 'Prior Premium',
    },
    papolicychangeQuoteChangeInPremium: {
        id: 'pa.policychange.quote.views.Change in Premium',
        defaultMessage: 'Change in Premium',
    },
    papolicychangeQuoteFullTerm: {
        id: 'pa.policychange.quote.views.Full-Term',
        defaultMessage: 'Full-Term',
    },
    papolicychangeQuoteTotalPremium: {
        id: 'pa.policychange.quote.views.Total Premium',
        defaultMessage: 'Total Premium',
    },
    papolicychangePolicyPeriod: {
        id: 'pa.policychange.quote.views.Policy Period',
        defaultMessage: 'Policy Period',
    },
    infoIncrease: {
        id: 'custom.pa.policychange.quote.views.an increase',
        defaultMessage: 'an increase',
    },
    infoDecrease: {
        id: 'custom.pa.policychange.quote.views.an decrease',
        defaultMessage: 'a decrease',
    },
    infoValidationMessages: {
        id: 'custom.pa.policychange.quote.views.Increased Premium Title',
        defaultMessage: 'This change will result in a premium {status}',
    },
    infoValidationMessagesDesc: {
        id: 'custom.pa.policychange.quote.views.Increased Premium Descripition',
        defaultMessage: 'The new premium will be {newPremium}, which is {status} of {changedIncost}. This change will be reflected in upcoming invoices.'
    },
    MultiVersions: {
        id: 'custom.pa.policychange.quote.views.Multi-Versions',
        defaultMessage: 'Multi-Versions',
    },
    back: {
        id: 'custom.pa.policychange.quote.views.Back',
        defaultMessage: 'Back',
    },
    addNewVersion: {
        id: 'custom.pa.policychange.quote.views.Add New Version',
        defaultMessage: 'Add New Version',
    },
    IssuesRequiringReferral: {
        id: 'custom.pa.policychange.quote.views.issues Requiring Referral:',
        defaultMessage: 'Issues Requiring Referral:',
    },
    deleteSideBySideVersionTitle: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deleteVersion.Confirm Delete this Quote Version',
        defaultMessage: 'Confirm Delete this Quote Version'
    },
    deleteSideBySideVersionMessage: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deleteVersion.Deleting will delete the version you selected. To delete it, select Delete. To proceed one of the quotes click Cancel and then select appropriate quote.',
        defaultMessage: 'Deleting will delete the version you selected. To delete it, select Delete. To proceed one of the quotes click Cancel and then select appropriate quote.'
    },
    deleteSideBySidePairConfirm: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deleteVersion.Delete',
        defaultMessage: 'Delete'
    },
    resolveErrors: {
        id: 'custom.pa.policychange.quote.views.Resolve Errors',
        defaultMessage: 'Resolve Errors'
    },
    submitTheChange: {
        // id: 'quoteandbind.views.quote-details.submit the Change',
        id: 'custom.pa.policychange.quote.views.submit the Change',
        defaultMessage: 'In order to submit the Change, the following information must first be resolved:'
    },
    // ==============================
    deletePolicyChange: {
        id: 'custom.portal.components.transactionWizardTitle.transactionWizardTitle.Delete',
        defaultMessage: 'Delete',
    },
    sureDeletePolicyChange: {
        id: 'gw-portals-policytransaction-pc-pa.gateway.views.endorsement.Are you sure you want to delete this policy change?',
        defaultMessage: 'Are you sure you want to delete this policy transaction? All changes will be lost.'
    },
    submitChange: {
        id: 'wni.policychange.common.QuotePage.Submit Change',
        defaultMessage: 'Submit Change'
    },
    //
    deleteQuoteVerion: {
        id: 'wni.policychange.common-wizard.common-quote.Delete this Version',
        defaultMessage: 'Delete this Version',
    },
    //
    addVersions: {
        id: 'custom.component.common-wizard.AddNewChangeVersionComponent.Add Versions',
        defaultMessage: 'Add Versions',
    }, 
    createNewVersion: {
        id: 'custom.component.common-wizard.AddNewChangeVersionComponent.Add New Version',
        defaultMessage: 'Add New Version',
    }, 
    // ===============================================
    chooseAddVersions: {
        id: 'custom.component.common-wizard.AddNewChangeVersionComponent.Choose / Add Versions',
        defaultMessage: 'Choose / Add Versions',
    },
    chooseVersions: {
        id: 'custom.component.common-wizard.AddNewChangeVersionComponent.Choose Versions',
        defaultMessage: 'Choose Versions',
    },
    // ===============================================
    anErrorOccurred: {
        id: 'custom.component.common-wizard.PolicyChangeWizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'custom.component.common-wizard.PolicyChangeWizard.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'custom.component.common-wizard.PolicyChangeWizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'custom.component.common-wizard.PolicyChangeWizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
});
