
import React from 'react';
import { CRLocationsPage } from 'wni-capability-quoteandbind-cr-react';
import { WniProductsUtil, PolicyChangeUtil } from 'wni-portals-util-js';

const {
    CR_PRODUCT_CODE,
    getPolicyLinePatternName
} = WniProductsUtil;

const linePattern = getPolicyLinePatternName(CR_PRODUCT_CODE);

function CRLocationsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent(linePattern, submissionVM);
    return (
        <CRLocationsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

};

export default CRLocationsChangePage;