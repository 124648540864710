/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
    DPAdditionalLivingExpense_Ext: {
        id: 'wni.custom.products.DP.tooltip.Provides coverage for the increased living expense incurred to maintain normal standard of living as a result of a covered loss.',
        defaultMessage: 'Provides coverage for the increased living expense incurred to maintain normal standard of living as a result of a covered loss.'
    },
    DPPersonalPropUsedForBuss_Ext: {
        id: 'wni.custom.products.DP.tooltip.Provides coverage for an incidental office, private school, studio, or small service businesses such as barber or beauty shop on premises.',
        defaultMessage: 'Provides coverage for an incidental office, private school, studio, or small service businesses such as barber or beauty shop on premises.'
    },
    DPUnitOwnersCoverage_Ext: {
        id: 'wni.custom.products.DP.tooltip.Provides coverage for unit- owners building items, including alterations, appliances, fixtures, and improvements; real property which pertains to the unit; and structures the policyholder solely owns on the premises other than the described location.',
        defaultMessage: 'Provides coverage for unit- owners building items, including alterations, appliances, fixtures, and improvements; real property which pertains to the unit; and structures the policyholder solely owns on the premises other than the described location.'
    },
    DPActualCashValueLossSett_Ext: {
        id: 'wni.custom.products.DP.tooltip.Covered property losses shall be settled at actual cash value at the time of loss but shall not be settled at more than the amount required to repair or replace.',
        defaultMessage: 'Covered property losses shall be settled at actual cash value at the time of loss but shall not be settled at more than the amount required to repair or replace.'
    },
    DPActualCashValLossSettleWindOrHailLossToRoofSurf_Ext: {
        id: 'wni.custom.products.DP.tooltip.Covered loss for roof surfacing caused by the peril of windstorm or hail shall be subject to actual cash value settlement.',
        defaultMessage: 'Covered loss for roof surfacing caused by the peril of windstorm or hail shall be subject to actual cash value settlement.'
    },
    DPFunctionalReplacementCostLossSett_Ext: {
        id: 'wni.custom.products.DP.tooltip.Functional replacement cost is the cost to repair or replace with commonly used and less costly materials. Coverage A must be at 80% of its functional replacement cost.',
        defaultMessage: 'Functional replacement cost is the cost to repair or replace with commonly used and less costly materials. Coverage A must be at 80% of its functional replacement cost.'
    },
    DPPersonalPropertyRepCostLossSett_Ext: {
        id: 'wni.custom.products.DP.tooltip.Expands loss settlement for personal property to a replacement cost basis.',
        defaultMessage: 'Expands loss settlement for personal property to a replacement cost basis.'
    },
    DPEarthquake_Ext: {
        id: 'wni.custom.products.DP.tooltip.Provides coverage against a loss resulting from the peril of earthquake.',
        defaultMessage: 'Provides coverage against a loss resulting from the peril of earthquake.'
    },
    DPPersonalLiability_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides Coverage L-personal liability and Coverage M-medical payments to others. ',
        defaultMessage: 'Provides Coverage L-personal liability and Coverage M-medical payments to others. '
    },
    DPLossAssessmentPropertyCoverage_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides property coverage for your share of loss assessment charged against you by an association.',
        defaultMessage: 'Provides property coverage for your share of loss assessment charged against you by an association.'
    },
    DPLossAssessmentLiability_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides liability coverage for your share of loss assessment charged against you by an association.',
        defaultMessage: 'Provides liability coverage for your share of loss assessment charged against you by an association.'
    },
    DPLossAssessmentCovForEarthquakes_Ext : {
        id: 'wni.custom.products.DP.tooltip.Coverage for loss assessments caused by earthquake can be added. Deductible percentages available 5%, 10%, 15%, 20%, and 25%.  Maximum limit of $50,000.',
        defaultMessage: 'Coverage for loss assessments caused by earthquake can be added. Deductible percentages available 5%, 10%, 15%, 20%, and 25%.  Maximum limit of $50,000.'
    },
    DPSinkholeCollapse_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides physical damage coverage caused by Sinkhole Collapse.',
        defaultMessage: 'Provides physical damage coverage caused by Sinkhole Collapse.'
    },
    DPSpecialCoverage_Ext : {
        id: 'wni.custom.products.DP.tooltip.Amends the policy from Named Perils coverage to Special coverage for Improvements, Alterations, and Additions or Unit- owners Coverage.',
        defaultMessage: 'Amends the policy from Named Perils coverage to Special coverage for Improvements, Alterations, and Additions or Unit- owners Coverage.'
    },
    DPBroadTheftCoverage_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides theft, vandalism and malicious mischief coverage on and off premises for owner occupied dwellings.  Off premises is only available when on premises is purchased.',
        defaultMessage: 'Provides theft, vandalism and malicious mischief coverage on and off premises for owner occupied dwellings.  Off premises is only available when on premises is purchased.'
    },
    DPLimitedTheftCoverage_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides theft, vandalism and malicious mischief coverage on Premises only for non-owner occupied dwellings.',
        defaultMessage: 'Provides theft, vandalism and malicious mischief coverage on Premises only for non-owner occupied dwellings.'
    },
    DPTheftEndNewDwelUnderCons_Ext : {
        id: 'wni.custom.products.DP.tooltip.Extends coverage for theft to building materials, supplies, fixtures and equipment that is part of the insured’s dwelling for described location on the policy. Coverage is provided until the dwelling is completed or the expiration date of the policy, whichever occurs first. ',
        defaultMessage: 'Extends coverage for theft to building materials, supplies, fixtures and equipment that is part of the insured’s dwelling for described location on the policy. Coverage is provided until the dwelling is completed or the expiration date of the policy, whichever occurs first. '
    },
    DPLimitWaterBackUpAndSumpDis_Ext : {
        id: 'wni.custom.products.DP.tooltip.Covers loss caused by water that backs up through sewers or drains, or overflows from a sump pump.',
        defaultMessage: 'Covers loss caused by water that backs up through sewers or drains, or overflows from a sump pump.'
    },
    DPIncLowPowerRecMotorVehicleLiabCovEndor_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides liability coverage with respect to certain types of recreational motor vehicles that are low speed.',
        defaultMessage: 'Provides liability coverage with respect to certain types of recreational motor vehicles that are low speed.'
    },
    DPPersonalInjury_Ext : {
        id: 'wni.custom.products.DP.tooltip.Liability coverage for personal injury arising out of specificed offenses, such as false arrest, malicious presecution, wrongfule eviction, slander or libel.',
        defaultMessage: 'Liability coverage for personal injury arising out of specificed offenses, such as false arrest, malicious presecution, wrongfule eviction, slander or libel.'
    },
    DPModifiedLossSettlementFormDP0001ONLY_Ext : {
        id: 'wni.custom.products.DP.tooltip.Amends the loss settlement provision for Coverages A and B under DP 00 01 from actual cash value to replacement cost.',
        defaultMessage: 'Amends the loss settlement provision for Coverages A and B under DP 00 01 from actual cash value to replacement cost.'
    },
    DPOwnMotorGolfCartPhyLossSchedCov_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides broad physical damage coverage for owned motorized golf carts with an option to exclude or include collision coverage.',
        defaultMessage: 'Provides broad physical damage coverage for owned motorized golf carts with an option to exclude or include collision coverage.'
    },
    DPTreesShrubsAndOtherPlants_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides specified perils coverage for tress, shrubs and other plants, limited to a $500 per item maximum.',
        defaultMessage: 'Provides specified perils coverage for tress, shrubs and other plants, limited to a $500 per item maximum.'
    },
    DPNonOwnedBusPurSchedCov_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides liability coverage for specified business pursuits if insured does not own their own business.',
        defaultMessage: 'Provides liability coverage for specified business pursuits if insured does not own their own business.'
    },
    DPOrdinanceOrLawIncreasedAmountOfCov_Ext : {
        id: 'wni.custom.products.DP.tooltip.Provides coverage for the additional costs incurred to repair or rebuild a dwelling as a result of an ordinance or law. ',
        defaultMessage: 'Provides coverage for the additional costs incurred to repair or rebuild a dwelling as a result of an ordinance or law. '
    },
    DPPremLiabNonOwnerOccDwelSchedCov_Ext: {
        id: 'wni.custom.products.DP.tooltip.Allows premises liability - Coverage L & M for non-owner occupied dwelling. ',
        defaultMessage: 'Allows premises liability - Coverage L & M for non-owner occupied dwelling. '
    },
    DPDeductibles_Ext: {
        id: 'wni.custom.products.DP.tooltip.Provides for higher windstorm or hail percentage deductibles.',
        defaultMessage: 'Provides for higher windstorm or hail percentage deductibles.'
    },
    DPCoverageBSchedCov_Ext1: {
        id: 'wni.custom.products.DP.tooltip. Coverage for other structures is automatically provided on a blanket basis for up to 10% of the Coverage A limit. The DP-1 form reduces the Coverage A limit for the same loss.',
        defaultMessage: ' Coverage for other structures is automatically provided on a blanket basis for up to 10% of the Coverage A limit. The DP-1 form reduces the Coverage A limit for the same loss.'
    },
    DPCoverageBSchedCov_Ext2: {
        id: 'wni.custom.products.DP.tooltip.For example, if Coverage A is $100,000, the Coverage B limit provided is $10,000. In the event of a total loss to the dwelling and other structures, the most paid for the claim will be $100,000.',
        defaultMessage: 'For example, if Coverage A is $100,000, the Coverage B limit provided is $10,000. In the event of a total loss to the dwelling and other structures, the most paid for the claim will be $100,000.'
    },
    DPCoverageDFairRentVal_Ext_DP1: {
        id: 'wni.custom.products.DP.tooltip.Coverage for Fair Rental Value is available up to 20% of the Coverage A limit. The DP-1 form reduces the Coverage A limit for the same loss. For example, if Coverage A is $100,000, the Coverage D limit provided is $20,000. In the event of a total loss to the dwelling, the most paid for the claim will be $100,000.',
        defaultMessage: 'Coverage for Fair Rental Value is available up to 20% of the Coverage A limit. The DP-1 form reduces the Coverage A limit for the same loss. For example, if Coverage A is $100,000, the Coverage D limit provided is $20,000. In the event of a total loss to the dwelling, the most paid for the claim will be $100,000.'
    },
    DPCoverageDFairRentVal_Ext_DP2: {
        id: 'wni.custom.products.DP.tooltip.20% of the Coverage A limit is automatically included for Fair Rental Value and Additional Living Expenses as a combined limit of additional insurance.',
        defaultMessage: '20% of the Coverage A limit is automatically included for Fair Rental Value and Additional Living Expenses as a combined limit of additional insurance.'
    },
    DPCoverageEAddLivExp_Ext_DP1: {
        id: 'wni.custom.products.DP.tooltip.Coverage not automatically included but can be purchased separately.',
        defaultMessage: 'Coverage not automatically included but can be purchased separately.'
    },
    DPAssLivCareEndorSchedCov_Ext1: {
        id: 'wni.custom.products.DP.tooltip.Coverage for personal property and additional living costs for a person related to the policyholder and resides in an assisted living facility.',
        defaultMessage: 'Coverage for personal property and additional living costs for a person related to the policyholder and resides in an assisted living facility.'
    },
    DPAssLivCareEndorSchedCov_Ext2: {
        id: 'wni.custom.products.DP.tooltip.Coverage for persosal liability for a person related to the policyholder and resides in an assisted living facility.',
        defaultMessage: 'Coverage for persosal liability for a person related to the policyholder and resides in an assisted living facility.'
    },
    DPRoomerBoardersNumberHelp:{
        id: 'wni.custom.products.DP.tooltip.Enter the number of Roomers/Boarders in the dwelling. Please include all non-household members that pay to live in the Dwelling.',
        defaultMessage: 'Enter the number of Roomers/Boarders in the dwelling. Please include all non-household members that pay to live in the Dwelling.'
    }
})