import { defineMessages } from 'react-intl';

export default defineMessages({
    SupplementalCoverages: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.SupplementalCoverages",
        "defaultMessage": "Supplemental Coverages"
    },
    ContinuingRentalorLeasePayments: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.ContinuingRentalorLeasePayments",
        "defaultMessage": "Continuing Rental or Lease Payments"
    },
    PollutantCleanupAndRemoval: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.PollutantCleanupAndRemoval",
        "defaultMessage": "Pollutant Cleanup And Removal"
    },
    RentalReimbursement: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.RentalReimbursement",
        "defaultMessage": "Rental Reimbursement"
    },
    SparePartsAndTools: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.SparePartsAndTools",
        "defaultMessage": "Spare Parts And Tools"
    },
    Limit: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.Limit",
        "defaultMessage": "Limit"
    },
    WaitingPeriod: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.WaitingPeriod",
        "defaultMessage": "Waiting Period"
    },
    hours: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.hours",
        "defaultMessage": "hours"
    },
    ElectricalDisturbance: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.ElectricalDisturbance",
        "defaultMessage": "Electrical Disturbance"
    },
    AdditionalInformation: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.AdditionalInformation",
        "defaultMessage": "Additional Information"
    }
});