
import React, {} from 'react'; 

import { DPCoveragesReadOnlyPage } from 'wni-capability-quoteandbind-dp-react';


function DPCoveragesChangeReadOnlyPage(props) {
    return (
        <DPCoveragesReadOnlyPage
            {...props}
        />
    );

}

DPCoveragesChangeReadOnlyPage.propTypes = DPCoveragesReadOnlyPage.propTypes;
DPCoveragesChangeReadOnlyPage.defaultProps = DPCoveragesReadOnlyPage.defaultProps;

export default DPCoveragesChangeReadOnlyPage;
