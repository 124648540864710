import React from 'react';
import { CPRiskAnalysisReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPRiskAnalysisChangeReadOnlyPage(props) {

    return (
        <CPRiskAnalysisReadOnlyPage {...props} />
    );

}

CPRiskAnalysisChangeReadOnlyPage.propTypes = CPRiskAnalysisReadOnlyPage.propTypes;
CPRiskAnalysisChangeReadOnlyPage.defaultProps = CPRiskAnalysisReadOnlyPage.defaultProps;

export default CPRiskAnalysisChangeReadOnlyPage;