import React, { useState, useContext }from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { Button } from '@jutro/components';
import metadata from './SinglePropertyIncident.metadata.json5'
import messages from './SinglePropertyIncident.message'


const SinglePropertyIncident = (props) => {
    const {
        originPropertyIncident,
        editingPropertyIncidentIndex,
        onPopupCancel,
        onPopupSave,
    } = props
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SinglePropertyIncident')

    const [showErrors, setShowErrors] = useState(false);
    const [propertyIncidentVM, setPropertyIncidentVM] = useState(() => viewModelService.create(
            originPropertyIncident,
            'cc',
            'wni.edge.capabilities.claim.lob.shared.incidents.dto.PEPropertyIncidentDTO'
        ))

    const writeValue = (value, path) => {
        const newPropertyIncidentVM = _.clone(propertyIncidentVM);
        _.set(newPropertyIncidentVM.value, path, value);
        if (path.includes('ownerContact_Ext')) {
            _.set(newPropertyIncidentVM.value, 'ownerContact_Ext.updated_Ext', true);
        }
        setPropertyIncidentVM(newPropertyIncidentVM);
    }


    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(propertyIncidentVM.value, editingPropertyIncidentIndex)
    }



    const overrideProps = {
        PersonaTypeContactContainer1: {
            visible: _.get(propertyIncidentVM.value, 'ownerContact_Ext.subtype') === 'Person'
        },
        PersonaTypeContactContainer2: {
            visible: _.get(propertyIncidentVM.value, 'ownerContact_Ext.subtype') === 'Person'
        },
        CompanyTypeContactContainer: {
            visible: _.get(propertyIncidentVM.value, 'ownerContact_Ext.subtype') === 'Company'
        },
        primaryAddressContainer: {
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={propertyIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div style={{
                display: 'flex',
                'justify-content': 'right',
                gap: '10px'
            }}>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
                label={messages.cancel}
            />
            <Button
                type="filled"
                onClick={onSaveClicked}
                label={messages.save}
            />
        </div>
    </>
}

export default SinglePropertyIncident
