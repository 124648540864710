import React, { useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { fnolCommonMessages, saveFNOLDetails } from 'gw-capability-fnol-common-react';

import WCFnolLossLocation from '../../components/LossLocation/WCLossLocation';
import metadata from './WherePage.metadata.json5';

function WCFnolWherePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLWherePage');

    const handleValueChange = useCallback(
        (value, path) => {
            if (value) {
                _.set(claimVM, path, value);
                updateWizardData(claimVM);
            }
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const getAvailablePolicyLocations = useMemo(
        () => {
            const dropDownContacts = claimVM.value.policyProperties.map((location) => {
                return {
                    code: location.publicID,
                    name: location.displayName
                };
            });
            return dropDownContacts;
        },
        [claimVM.value.policyProperties]
    );

    const overrideProps = {
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        },
        selectAddress: {
            availableValues: getAvailablePolicyLocations,
            onValueChange: handleValueChange
        },
    };

    const resolvers = {
        resolveComponentMap: {
            losslocationcomponent: WCFnolLossLocation
        },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const validateClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        return !_.isNil(claimStatus);
    }, [state]);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [validateClaimStatus, registerInitialComponentValidation]);

    const onNext = useCallback(async () => {
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        return claimVM
    }, [authHeader, claimVM]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}
WCFnolWherePage.propTypes = wizardProps;
export default withRouter(WCFnolWherePage);
