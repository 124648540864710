import { defineMessages } from 'react-intl';

export default defineMessages({
    noAccountDocuments: {
        id: 'wni.gateway.directives.gateway-documents.There are no documents associated with this account',
        defaultMessage: 'There are no documents associated with this account'
    },
    attachmentColumnName: {
        id: 'wni.gateway.directives.gateway-documents.Name',
        defaultMessage: 'Name'
    },
    attachmentDeletionFailed: {
        id: 'custom.gateway.directives.GatewayDocuments.Attachment deletion failed',
        defaultMessage: 'Attachment Deletion Failed'
    },
    attachmentCannotDeleted: {
        id: 'custom.gateway.directives.GatewayDocuments.Sorry your attachment could not be deleted at this time',
        defaultMessage: 'Sorry your attachment could not be deleted at this time'
    },
    printSelectedAttachments: {
        id: 'wni.gateway.directives.gateway-documents.Print Selected Attachments',
        defaultMessage: 'Print Selected Attachments'
    }
});
