import React, {
    useCallback,
    useState,
    useContext
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import WizardPage from '../../templates/PUWizardPage';
import styles from './PUCoveragesPage.module.scss';
import metadata from './PUCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/PUCoveragesConfigContext';
import CoveragesConfig from './PUCoverages.config';
import PULineStandardCoverages from './CoveragesSection/PULineStandardCoverages/PULineStandardCoverages';
import PULineOtherOptionalCoverages from './CoveragesSection/PULineOtherOptionalCoverages/PULineOtherOptionalCoverages';
import PULineExclusions from './CoveragesSection/PULineExclusions/PULineExclusions';
import PUAddiComponent from './components/PUAddiComponent/PUAddiComponent';

function PUCoveragesReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;

    const lobName = 'personalUmbrella'

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            [lobName]: {
                offerings,
            }
        },
    } = submissionVM.value;

    const additionalInterestsVM = _.get(submissionVM, 'lobData.personalUmbrella.coverables.additionalInterests');
    const viewModelService = useContext(ViewModelServiceContext);

    const createEmtpyTrusteeVM = useCallback(() => {
        const dtoVMPath = 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO';
        const newAdditionalInterestDTOVM = viewModelService.create(
            {
                primaryAddress: {},
                phoneRequired_Ext: false
            },
            'pc', dtoVMPath);
        return newAdditionalInterestDTOVM;
    }, [viewModelService]);

    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelAdditionalCoverages',
        'policyLevelExclusionsAndConditions',
    ]);

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const {
        coverages: {
            lineExclusions = [],
        }
    } = selectedVersion


    const onPageNext = async () => {
        return submissionVM;
    }

    const generateOverrides = useCallback(() => {

        const commonCoverageComponentProps = {
            selectedVersion,
            selectedVersionIndex,
            submissionVM,
            lobName,
        }

        return {
            '@field': {
                labelPosition: 'left',
                isEditable: false,
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            puLineStandardCoverages: {
                ...commonCoverageComponentProps
            },
            puLineOtherOptionalCoverages: {
                ...commonCoverageComponentProps
            },
            lineExclusionsTitle: {
                visible: lineExclusions.length > 0
            },
            puLineExclusions: {
                ...commonCoverageComponentProps,
                visible: lineExclusions.length > 0
            },
            puAdditionalInterestSection: {
                value: additionalInterestsVM,
                readOnly: true,
                getSubTypeOptions: _.noop,
                emptyTrusteeVM: createEmtpyTrusteeVM(),
            }
        };
    }, [
        lobName,
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        openedAccordions,
        lineExclusions,
        additionalInterestsVM,
        createEmtpyTrusteeVM
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            PULineStandardCoverages: PULineStandardCoverages,
            PULineOtherOptionalCoverages: PULineOtherOptionalCoverages,
            PULineExclusions: PULineExclusions,
            PUAdditionalInterestComponent: PUAddiComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            onNext={onPageNext}
            //
            showRequiredInfoForFasterQuote
        >
            <CoveragesConfigContext.Provider value={CoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
}

PUCoveragesReadOnlyPage.propTypes = WizardPage.propTypes;
export default PUCoveragesReadOnlyPage;
