import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniDateUtil } from 'wni-portals-util-js';
import metadata from './PaymentInputComponent.metadata.json5';
import styles from './PaymentInputComponent.module.scss';


const PaymentInputComponent = (props) => {
    const {
        totalModel: paymentTotalVM,
        onPaymentInfoChange: onPaymentChange,
    } = props;

    const [installmentsExtValueIsZero, updateInstallmentsExtValueIsZero] = useState(null);

    useEffect(() => {
    }, []);


    const isFullPayDiscountApplicable = useCallback(() => {
        const bindData = _.get(paymentTotalVM, 'bindData.chosenQuote');
        const chosenQuote = paymentTotalVM.value.quoteData.offeredQuotes
            .find((quote) => bindData.value === quote.publicID);
        const fullPayDiscountExt = _.get(chosenQuote, 'premium.fullPayDiscount_Ext');
        const isFullPay = _.get(chosenQuote, 'planName') === 'Full Pay';
        return isFullPay && !!(fullPayDiscountExt && fullPayDiscountExt.amount > 0);
    }, [paymentTotalVM]);


    const fullPayStyle = useCallback(() => {
        if (paymentTotalVM.quoteData.offeredQuotes) {
            const choseQuote = paymentTotalVM.quoteData.offeredQuotes.value
                .find(({ publicID }) => publicID === paymentTotalVM.bindData.chosenQuote.value);
            const fullPayDiscountExt = _.get(choseQuote, 'premium.fullPayDiscount_Ext');

            const fullPayClass = (fullPayDiscountExt != null && fullPayDiscountExt.amount > 0) ? 'text-green' : '';
            return fullPayClass;
        }
        return '';
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        addressType: {
            required: true,
        },
        fullPayDiscount_Ext: {
            visible: isFullPayDiscountApplicable(),
            className: fullPayStyle(),
        },
        installments_Ext: {
            visible: !installmentsExtValueIsZero,
        },
        payUsing_Ext: {
            value: _.get(paymentTotalVM, 'bindData.payUsing_Ext.value') === 'Check' ? 'Check' : 'Bank Account',
        },
        totalPremiumWithTaxAndSurcharges: {
            visible: isFullPayDiscountApplicable()
        },
        totalPremium: {
            visible: !isFullPayDiscountApplicable()
        },
        billingFees: {
            visible: !isFullPayDiscountApplicable()
        }
    };

    const readValue = (id, path) => {
        let model = paymentTotalVM;
        if (!paymentTotalVM.quoteData.offeredQuotes) {
            return '';
        }
        const choseQuote = paymentTotalVM.quoteData.offeredQuotes.value
            .find(({ publicID }) => publicID === paymentTotalVM.bindData.chosenQuote.value);
        if (id === 'firstDayOfMonth') {
            const firstDayOfMonthExt = _.get(paymentTotalVM, `value.${path}`);
            if (!_.isNil(firstDayOfMonthExt)) {
                return (
                    <div>
                        {`${firstDayOfMonthExt}`}
                        <sup>
                            {`${WniDateUtil.getSuffix(firstDayOfMonthExt)}`}
                        </sup>
                    </div>
                );
            }
            return '';
        }
        if (path.startsWith('bindData')) {
            model = paymentTotalVM;
        } else {
            model = choseQuote;
        }

        if (_.get(choseQuote, 'installments_Ext.amount') === 0) {
            updateInstallmentsExtValueIsZero(true);
        } else {
            updateInstallmentsExtValueIsZero(false);
        }

        return readViewModelValue(
            metadata.componentContent,
            model,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={paymentTotalVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValueChange={onPaymentChange}
        />
    );
};


PaymentInputComponent.propTypes = {
    totalModel: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onPaymentInfoChange: PropTypes.func,
};

PaymentInputComponent.defaultProps = {
    totalModel: {},
    onPaymentInfoChange: undefined,
};

export default PaymentInputComponent;
