import React, { useState, useEffect, useCallback } from 'react';
import _, { findLastIndex } from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { Loader } from '@jutro/components';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import styles from 'gw-capability-vehicleinfo-react/VehicleInfoLookupComponent/VehicleInfoLookupComponent.module.scss';
import 'gw-capability-vehicleinfo-react/VehicleInfoLookupComponent/VehicleInfoLookupComponent.messages';
import { VehicleUtil, VehicleValidationUtil, WniFormat } from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { WniVehicleInfoLookupService } from 'wni-capability-vehicleinfo';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './VehicleInfoLookupComponent.messages';
import metadata from './VehicleInfoLookupComponent.metadata.json5';

function VehicleInfoLookupComponent(props) {
    const modalApi = useWniModal();
    const {
        //TODO
        checkExistenceOfVIN,

        id,
        onValidate,
        onValueChange,
        // displayAllFields,
        isComponentValid,
        registerComponentValidation,
        // hasValidationChanged,
        value: vehicleVM = {},
        // _.get(submissionVM, 'lobData.personalAuto.isForNamedNonOwner_Ext.value')
        isQuoteForNamedNonOwner,
        // _.get(submissionVM, 'baseData.periodStatus.value')
        quoteStatus,
        isOtherThanCollisionSelected,
        showVinInfoText,
        showErrors,
        readOnly,
        layout,
        labelPosition,
        validVinInfo,
        updateValidVinInfo,
        checkCanViewAndEdit,
        updateVehicleType,
        updateCacheOptions,
        getCacheOptions,
        isNewVehicleReplacementSelected,
        isRequiredForIssuance,
        shouldSkipInitialization,
    } = props;

    const {
        vin, year, make, model,
        isCostNewVINPrefilled_Ext: isCostNewVINPrefilled,
        makeNotFound_Ext: makeNotFound,
        modelNotFound_Ext: modelNotFound,
        vehicleType_Ext: vehicleType = {},
        baseOnNull_Ext: baseOnNullExt = true,
        bodyType_Ext: bodyTypeExt
    } = vehicleVM.value;

    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const [showLoader, updateShowLoader] = useState(false);
    const [foundVehicle, updateFoundVehicle] = useState(true);
    const [bodyTypeCodeRange, updateBodyTypeCodeRange] = useState([]);
    const [vinInvalid, updateVinInvalid] = useState(false);
    const [baseOnNull, updateBaseOnNull] = useState();
    const [makeOptions, updateMakeOptions] = useState([]);
    const [modelOptions, updateModelOptions] = useState([]);
    const [isCostNewDisabled, updateIsCostNewDisabled] = useState(isCostNewVINPrefilled);

    const vinReg = /^[\s\S]{1,17}$/;

    const vinLength = vin ? vin.length : 0;
    // const vinInvalid = VehicleUtil.isVinInvalid(year, vinLength, foundVehicle);
    const isPPA = VehicleUtil.isPPAOnVehicle(vehicleVM);
    const handleChange = (value, changedPath) => {
        if (changedPath === 'vin' && !_.isEmpty(value)) {
            // BR.PL.0038 replace similar letter with number
            const newVin = value.replace(/O/g, '0').replace(/I/g, '1').replace(/q/g, '9');
            onValueChange(newVin, changedPath);
            return;
        }
        onValueChange(value, changedPath);
    };

    const handleBodyTypeChange = (vehicleInfo, newBodyTypeCodeRange) => {
        if (newBodyTypeCodeRange.length === 1) {
            handleChange(newBodyTypeCodeRange[0], 'bodyType_Ext');
        } else {
            const BodyTypeSelected = _.get(vehicleInfo, 'bodyTypeSelected_Ext', null);
            if (!_.isNil(BodyTypeSelected)) {
                handleChange(BodyTypeSelected, 'bodyType_Ext');
            }
        }
    };

    const reverseVinLookup = (vehicleInfo) => {
        const {
            errorCode
        } = vehicleInfo;
        const newFoundVehicle = _.isEmpty(errorCode);
        updateFoundVehicle(newFoundVehicle);
        if (newFoundVehicle) {
            updateVinInvalid(false);
        } else {
            updateVinInvalid(true);
        }
        // updateVinInvalid(VehicleUtil.isVinInvalid(vehicleYear, vinLength, newFoundVehicle));

        // reset Make not found and Model not found to false
        // and set Not found checkbox to invisible
        handleChange(undefined, 'makeNotFound_Ext');
        handleChange(undefined, 'modelNotFound_Ext');

        handleChange(vehicleInfo.vin, 'vin');

        // update the symbolAndModifiers_Ext from RAPA service
        const symbolAndModifiers = _.get(vehicleInfo, 'symbolAndModifiers_Ext', null);
        if (symbolAndModifiers) {
            handleChange(symbolAndModifiers, 'symbolAndModifiers_Ext');
        }

        // update cost new
        if (_.get(vehicleInfo, 'costNew_Ext.amount')) {
            handleChange(_.get(vehicleInfo, 'costNew_Ext'), 'costNew');
            _.set(vehicleVM, 'value.isCostNewVINPrefilled_Ext', true);
            updateIsCostNewDisabled(true);
        }

        // set body type options
        const newBodyTypeCodeRange = _.get(vehicleInfo, 'bodyTypeCodeRange_Ext', []);

        updateBodyTypeCodeRange(newBodyTypeCodeRange);
        handleBodyTypeChange(vehicleInfo, newBodyTypeCodeRange);
    };

    const handleDetailBlur = async (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject);
        if (!isValueChanged) {
            return;
        }
        const isVehicleVMVinEmpty = _.isNil(_.get(vehicleVM, 'value.vin')) || _.isEmpty(_.get(vehicleVM, 'value.vin'));
        if (!(year && make && model) || !isPPA || readOnly) {
            // any of year make model doesn't have value
            // vehicleType is not PPA
            // is on readOnly model
            // not call the service
            return;
        }
        updateShowLoader(true);
        const vehicleInfo = await WniVehicleInfoLookupService.autofillBasedOnDto(
            vehicleVM.value,
            authHeader
        );

        if (vehicleInfo.vin) {
            // if vin is empty and set the value directly
            if (isVehicleVMVinEmpty) {
                reverseVinLookup(vehicleInfo);
            } else if (!vinInvalid) {
                // override the vin automaticly when current is an validVin
                reverseVinLookup(vehicleInfo);
            } else {
                modalApi.showConfirm({
                    title: '',
                    message: translator(messages.vinOverrideConfirmationMessage,
                        { vin: vehicleInfo.vin }),
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: messages.vinOverrideYes,
                    cancelButtonText: messages.vinOverrideNo
                }).then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    reverseVinLookup(vehicleInfo);
                    return true;
                }, _.noop).finally(() => updateShowLoader(false));
            }
        } else if (_.get(vehicleInfo, 'errorCode') === '2'
            && _.get(vehicleInfo, 'errorDescription') === 'Invoke integration service failed!') {
            // vin not found show warning popup
            modalApi.showAlert({
                // title: title,
                message: messages.vinServiceNotFound,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(() => {
                _.noop();
            });
        }

        updateShowLoader(false);
    };

    //
    useEffect(() => {
        registerComponentValidation(() => {
            return VehicleValidationUtil.isVINComponentValid({
                isQuoteForNamedNonOwner,
                quoteStatus,
                vehicleVM,
                foundVehicle,
                isNewVehicleReplacementSelected,
                isRequiredForIssuance,
                vinReg,
                baseOnNull
            });
        });

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        //
    }, [foundVehicle, id, isComponentValid, isNewVehicleReplacementSelected,
        isQuoteForNamedNonOwner, isRequiredForIssuance, onValidate,
        quoteStatus, registerComponentValidation, vehicleVM]);

    const handleYearBlur = useCallback(async (target, valueObject) => {
        if (valueObject && _.isNumber(valueObject.beforeValue)) {
            _.set(valueObject, 'beforeValue', valueObject.beforeValue.toString());
        }
        const isValueChanged = WniFormat.isValueChanged(valueObject);
        if (!isValueChanged) {
            if (makeOptions) {
                const codes = _.map(makeOptions, (item) => {
                    return _.get(item, 'code');
                });
                return codes;
            }
            return [];
        }
        const value = _.get(valueObject, 'value') || year;
        if (_.isNil(value) || !isPPA) {
            return [];
        }
        updateShowLoader(true);
        // try fetch data from cache
        let yearForMakeOptions = getCacheOptions[WizardConstants.yearForMakeOptions];
        let newMakes = [];
        // get the value from cache
        const cacheMakeOptions = _.get(yearForMakeOptions, `${value}.makeOptions`);
        if (!_.isEmpty(cacheMakeOptions)) {
            newMakes = cacheMakeOptions;
        } else {
            if (_.isNil(yearForMakeOptions)) {
                yearForMakeOptions = {};
            }
            // fetch from server
            const vehicleInfo = await WniVehicleInfoLookupService
                .lookupMakes(value, vehicleType, authHeader);
            newMakes = _.get(vehicleInfo, 'makes', []);
            // update make options to cache
            yearForMakeOptions[`${value}.makeOptions`] = newMakes;
            updateCacheOptions({ [WizardConstants.yearForMakeOptions]: yearForMakeOptions });
        }
        const newMakeOptions = _.map(newMakes, (item) => {
            return { code: item, name: item };
        });
        updateMakeOptions(newMakeOptions);
        _.set(vehicleVM, 'value.make', undefined);
        _.set(vehicleVM, 'value.model', undefined);
        _.set(vehicleVM, 'value.costNew', undefined);
        _.set(vehicleVM, 'value.bodyType_Ext', undefined);
        updateBodyTypeCodeRange([]);
        updateShowLoader(false);
        return newMakes;
    }, [authHeader, isPPA, makeOptions, updateCacheOptions,
        vehicleType, vehicleVM, getCacheOptions, year]);

    const handleMakeBlur = useCallback(async (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject);
        if (!isValueChanged) {
            if (modelOptions) {
                const codes = _.map(modelOptions, (item) => {
                    return _.get(item, 'code');
                });
                return codes;
            }
            return [];
        }
        const value = _.get(valueObject, 'value') || make;
        if (_.isNil(value) || !isPPA) {
            return [];
        }
        updateShowLoader(true);
        const currentYear = _.get(vehicleVM, 'value.year');
        // try fetch data from cache
        let yearMakeForModelOptions = getCacheOptions[WizardConstants.yearMakeForModelOptions];
        let newModels = [];
        // get the value from cache
        const cacheModelOptions = _.get(yearMakeForModelOptions, `${currentYear}.${value}.modelOptions`);
        if (!_.isEmpty(cacheModelOptions)) {
            newModels = cacheModelOptions;
        } else {
            // update make options
            if (_.isNil(yearMakeForModelOptions)) {
                yearMakeForModelOptions = {};
            }
            // fetch from server
            const vehicleInfo = await WniVehicleInfoLookupService
                .lookupModels(currentYear, value, authHeader);
            newModels = _.get(vehicleInfo, 'models', []);
            // update model options to cache
            yearMakeForModelOptions[`${currentYear}.${value}.modelOptions`] = newModels;
            const yearForMakeOptions = getCacheOptions[WizardConstants.yearForMakeOptions];
            // get the value from cache
            const cachedMakeOptions = _.get(yearForMakeOptions, `${currentYear}.makeOptions`);
            if (cachedMakeOptions) {
                updateCacheOptions({
                    [WizardConstants.yearForMakeOptions]: yearForMakeOptions,
                    [WizardConstants.yearMakeForModelOptions]: yearMakeForModelOptions
                });
            } else {
                updateCacheOptions({
                    [WizardConstants.yearMakeForModelOptions]: yearMakeForModelOptions
                });
            }
        }
        const newModelOptions = _.map(newModels, (item) => {
            return { code: item, name: item };
        });
        updateModelOptions(newModelOptions);
        // clear model after make changed
        _.set(vehicleVM, 'value.model', undefined);
        _.set(vehicleVM, 'value.costNew', undefined);
        _.set(vehicleVM, 'value.bodyType_Ext', undefined);
        updateBodyTypeCodeRange([]);
        updateShowLoader(false);
        return newModels;
    }, [authHeader, isPPA, make, modelOptions, updateCacheOptions, vehicleVM, getCacheOptions]);

    const clearVinLookupInfo = () => {
        // add recordValues for firstTime reset
        const recordYear = _.get(vehicleVM, 'value.year');
        const recordMake = _.get(vehicleVM, 'value.make');
        const recordModel = _.get(vehicleVM, 'value.model');
        const recordMakeNotFound = _.get(vehicleVM, 'value.makeNotFound_Ext');
        const recordModelNotFound = _.get(vehicleVM, 'value.modelNotFound_Ext');
        const recordCostNew = _.get(vehicleVM, 'value.costNew');
        const recordBodyType = _.get(vehicleVM, 'value.bodyType_Ext');
        const recordSymbolAndModifiers = _.get(vehicleVM, 'value.symbolAndModifiers_Ext');

        handleChange(undefined, 'year');
        handleChange(undefined, 'make');
        handleChange(undefined, 'model');
        handleChange(undefined, 'makeNotFound_Ext');
        handleChange(undefined, 'modelNotFound_Ext');
        handleChange(undefined, 'costNew');
        handleChange(undefined, 'bodyType_Ext');
        handleChange(undefined, 'symbolAndModifiers_Ext');
        return {
            recordYear,
            recordMake,
            recordModel,
            recordMakeNotFound,
            recordModelNotFound,
            recordCostNew,
            recordBodyType,
            recordSymbolAndModifiers
        };
    };

    const setRecordValueOnFirstView = (recordValue) => {
        // set year value
        handleChange(_.get(recordValue, 'recordYear'), 'year');
        // set make value
        handleChange(_.get(recordValue, 'recordMake'), 'make');
        // set model value
        handleChange(_.get(recordValue, 'recordModel'), 'model');
        // set make value
        handleChange(_.get(recordValue, 'recordMakeNotFound'), 'makeNotFound_Ext');
        // set model value
        handleChange(_.get(recordValue, 'recordModelNotFound'), 'modelNotFound_Ext');
        // set cost new
        handleChange(_.get(recordValue, 'recordCostNew'), 'costNew');
        // set body type
        handleChange(_.get(recordValue, 'recordBodyType'), 'bodyType_Ext');
        // set symbols
        handleChange(_.get(recordValue, 'symbolAndModifiers'), 'symbolAndModifiers_Ext');
    };

    const handleVinBlur = async (target, valueObject, isFirstEditAndView) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject); // after onBlur, if vin value change or not.
        if (!isValueChanged) {
            return;
        }
        updateIsCostNewDisabled(false);
        _.set(vehicleVM, 'value.isCostNewVINPrefilled_Ext', false);
        if (_.isEmpty(vin) || !isPPA || readOnly) {
            return;
        }
        if (valueObject) {
            const { beforeValue, value } = valueObject;
            if (value === beforeValue) {
                return;
            }
            if(isPPA && !vinReg.test(value) && baseOnNull) {
                return;
            }
        }
        if (checkExistenceOfVIN(vin)) {
            // There is multiple vehicles has the same vin
            alert('There shouldn\'t be vehicles with same VIN, please enter again.');
            return;
        }

        updateShowLoader(true);

        // reuse cached vehicle info
        let vehicleInfo = _.get(validVinInfo, vin);
        if (_.isNil(vehicleInfo)) {
            vehicleInfo = await VehicleInfoLookupService.lookupVehicleInfoBasedOnVin(
                vin,
                authHeader
            );
            const newValidVinInfo = _.cloneDeep(validVinInfo);
            _.set(newValidVinInfo, vin, vehicleInfo);
            updateValidVinInfo(newValidVinInfo);
        }

        const {
            errorCode,
            make: vehicleMake,
            model: vehicleModel,
            year: vehicleYear,
            costNew_Ext: costNew,
            bodyTypeCodeRange_Ext: newBodyTypeCodeRange = [],
            symbolAndModifiers_Ext: symbolAndModifiers
        } = vehicleInfo;
        const newFoundVehicle = _.isEmpty(errorCode);
        updateFoundVehicle(newFoundVehicle);
        if (newFoundVehicle) {
            updateVinInvalid(false);
        } else {
            updateVinInvalid(true);
        }
        // updateVinInvalid(VehicleUtil.isVinInvalid(vehicleYear, vin.length, newFoundVehicle));

        // reset values to null before update
        const recordValue = clearVinLookupInfo();

        if (!newFoundVehicle) {
            updateShowLoader(false);
            // set make options to empty
            updateMakeOptions([]);
            // set model options to emtpy
            updateModelOptions([]);
            // set the record value in first view/edit
            if (isFirstEditAndView) {
                // setRecordValueOnFirstView(recordValue);
                const makeCodes = await handleYearBlur(null, { value: _.get(recordValue, 'recordYear'), beforeValue: undefined });
                const makeValue = _.get(recordValue, 'recordMake');
                const makeOptionsWithValue = VehicleUtil.getMakeOptions(makeValue, makeCodes);
                updateMakeOptions(makeOptionsWithValue);
                // reset record value
                setRecordValueOnFirstView(recordValue);

                const modelCodes = await handleMakeBlur(null, { value: _.get(recordValue, 'recordMake'), beforeValue: undefined });
                const modelValue = _.get(recordValue, 'recordModel');
                const modelOptionsWithValue = VehicleUtil.getModelOptions(modelValue, modelCodes);
                updateModelOptions(modelOptionsWithValue);
                // reset record value
                setRecordValueOnFirstView(recordValue);
            }
            return;
        }

        // set the record value in first view/edit
        if (isFirstEditAndView) {
            setRecordValueOnFirstView(recordValue);
        } else {
            // set year value
            handleChange(vehicleYear, 'year');
            // trigger year onBlur event to fetch make options
            await handleYearBlur(null, { value: vehicleYear, beforeValue: undefined });
            // set make value
            handleChange(vehicleMake, 'make');
            // trigger make onBlur event to fetch model options
            await handleMakeBlur(null, { value: vehicleMake, beforeValue: undefined });
            // set model value
            handleChange(vehicleModel, 'model');
        }
        // set the RAPA symbols
        handleChange(symbolAndModifiers, 'symbolAndModifiers_Ext');
        // set body type options
        updateBodyTypeCodeRange(newBodyTypeCodeRange);
        // set body type
        handleBodyTypeChange(vehicleInfo, newBodyTypeCodeRange);
        // set cost new
        if (_.get(vehicleInfo, 'costNew_Ext.amount')) {
            handleChange(costNew, 'costNew');
            _.set(vehicleVM, 'value.isCostNewVINPrefilled_Ext', true);
            updateIsCostNewDisabled(true);
        }
        updateShowLoader(false);
    };

    useEffect(() => {
        async function searchForMakeOptions(currentYear, currentMake) {
            let yearForMakeOptions = getCacheOptions[WizardConstants.yearForMakeOptions];
            let newMakes = _.get(yearForMakeOptions, `${currentYear}.makeOptions`);
            if (_.isNil(newMakes)) {
                const vInfoForMakes = await WniVehicleInfoLookupService
                    .lookupMakes(currentYear, vehicleType, authHeader);
                newMakes = _.get(vInfoForMakes, 'makes', []);
                // update make options
                if (_.isNil(yearForMakeOptions)) {
                    yearForMakeOptions = {};
                }
                yearForMakeOptions[`${currentYear}.makeOptions`] = newMakes;
            }
            let makeOptionsWithValue;
            if (currentMake) {
                makeOptionsWithValue = VehicleUtil.getMakeOptions(currentMake, newMakes);
            } else {
                makeOptionsWithValue = _.map(newMakes, (item) => {
                    return { code: item, name: item };
                });
            }
            updateMakeOptions(makeOptionsWithValue);
            // return cache value for update
            return { [WizardConstants.yearForMakeOptions]: yearForMakeOptions };
        }
        async function searchForModelOptions(currentYear, currentMake, currentModel) {
            let yearMakeForModelOptions = getCacheOptions[WizardConstants.yearMakeForModelOptions];
            let newModels = _.get(yearMakeForModelOptions, `${currentYear}.${currentMake}.modelOptions`);
            if (_.isNil(newModels)) {
                const vInfoForModels = await WniVehicleInfoLookupService
                    .lookupModels(currentYear, currentMake, authHeader);
                newModels = _.get(vInfoForModels, 'models', []);
                // update make options
                if (_.isNil(yearMakeForModelOptions)) {
                    yearMakeForModelOptions = {};
                }
                yearMakeForModelOptions[`${currentYear}.${currentMake}.modelOptions`] = newModels;
            }
            let modelOptionsWithValue;
            if (currentModel) {
                modelOptionsWithValue = VehicleUtil.getModelOptions(currentModel, newModels);
            } else {
                modelOptionsWithValue = _.map(newModels, (item) => {
                    return { code: item, name: item };
                });
            }
            updateModelOptions(modelOptionsWithValue);
            // return cache value for update
            return { [WizardConstants.yearMakeForModelOptions]: yearMakeForModelOptions };
        }
        async function searchForBodyTypeOptions(
            cacheOptionsToUpdate, currentYear,
            currentMake, currentModel
        ) {
            let yearMakeModelForBodyTypeOptions = getCacheOptions[
                WizardConstants.yearMakeModelForBodyTypeOptions
            ];
            let newBodyTypes = _.get(yearMakeModelForBodyTypeOptions, `${currentYear}.${currentMake}.${currentModel}.bodyTypeOptions`);
            if (_.isNil(newBodyTypes)) {
                const vInfoForBodyTypes = await WniVehicleInfoLookupService
                    .lookupBodyTypes(
                        { year: currentYear, make: currentMake, model: currentModel },
                        authHeader
                    );
                newBodyTypes = _.get(vInfoForBodyTypes, 'bodyTypes_Ext', []);
                // update bodyType options
                if (_.isNil(yearMakeModelForBodyTypeOptions)) {
                    yearMakeModelForBodyTypeOptions = {};
                }
                yearMakeModelForBodyTypeOptions[`${currentYear}.${currentMake}.${currentModel}.bodyTypeOptions`] = newBodyTypes;
            }
            updateBodyTypeCodeRange(newBodyTypes);
            const toUpdate = Object.assign(
                {
                    [WizardConstants.yearMakeModelForBodyTypeOptions]:
                    yearMakeModelForBodyTypeOptions
                },
                cacheOptionsToUpdate
            );
            updateCacheOptions(toUpdate);
        }
        async function searchOptions(searchYear, searchMake, searchModel) {
            if (_.isEmpty(vehicleType)) {
                return;
            }
            updateShowLoader(true);
            const cacheMakeOptionsToUpdate = await searchForMakeOptions(searchYear, searchMake);
            const cacheModelOptionsToUpdate = await searchForModelOptions(
                searchYear, searchMake, searchModel
            );
            // combine the caches to one
            const cacheOptionsToUpdate = Object.assign(
                cacheMakeOptionsToUpdate, cacheModelOptionsToUpdate
            );
            await searchForBodyTypeOptions(
                cacheOptionsToUpdate, searchYear, searchMake, searchModel
            );
            updateShowLoader(false);
        }
        // useEffect main code start
        // do not call any service if is readonly
        if (readOnly || shouldSkipInitialization) {
            return;
        }
        // NB / Change
        // baseOnNullExt == null -> new Vehicle -> can edit
        if (makeNotFound || modelNotFound) {
            updateVinInvalid(true);
        }

        if (baseOnNullExt) {
            // initial dropdown options and call VIN service
            updateBaseOnNull(true);
            const vehicleInfo = _.get(validVinInfo, vin);
            const errorCode = _.get(vehicleInfo, 'errorCode');
            const newFoundVehicle = _.isEmpty(errorCode);
            if (!newFoundVehicle) {
                updateVinInvalid(true);
            }
            searchOptions(year, make, model);
        } else {
            checkCanViewAndEdit({ vehicleType, updateBaseOnNull, baseOnNullExt });
            searchOptions(year, make, model);
        }
    }, [baseOnNullExt, shouldSkipInitialization, readOnly]);

    // =============================================
    const yearOptions = VehicleUtil.getYearOptions();
    // const makeOptions = VehicleUtil.getMakeOptions(make, makes);
    // const modelOptions = VehicleUtil.getModelOptions(model, models);
    const isCostNewVisible = VehicleValidationUtil
        .isCostNewVisible(isQuoteForNamedNonOwner, vehicleType);
    const isVinAmpersandNotificationVisible = !_.isEmpty(vin) && vin.includes('&');
    const availableBodyTypes = VehicleUtil.getAvailableBodyTypes(
        vehicleVM,
        bodyTypeCodeRange,
        translator
    );
    const availableVehicleTypes = VehicleUtil.getAvailableVehicleTypes(
        _.get(vehicleVM, 'vehicleType_Ext.aspects.availableValues'),
        isQuoteForNamedNonOwner,
        translator
    );
    const getPlaceholder = (path, isRequired) => {
        return WniFormat.placeholderFormat(vehicleVM, path, isRequired);
    };
    const getVehicleVinValidator = () => {
        return isPPA && baseOnNull ? {validator: {
            pattern: vinReg,
            message: "There are more than 17 digits entered, please correct the VIN."
        }} : null;
    }

    const clearModel = async (value, path) => {
        // set checkbox value
        handleChange(value, path);
        // reset Model to empty
        handleChange(undefined, 'model');
        // reset costNew to emtpy
        handleChange(undefined, 'costNew');
        // reset bodyType to empty
        handleChange(undefined, 'bodyType_Ext');
        updateBodyTypeCodeRange([]);
    };

    const clearMakeAndModel = (value, path) => {
        // set checkbox value
        handleChange(value, path);
        // reset Make to empty
        handleChange(undefined, 'make');
        // reset Model not found checkbox to empty
        handleChange(undefined, 'modelNotFound_Ext');
        // reset Model to empty
        clearModel(undefined, 'model');
    };


    const overrideProps = {
        '@field': {
            showOptional: false,
            showRequired: true,
            layout: layout,
            labelPosition: labelPosition,
            readOnly,
        },
        inlineIntegrationNotification: {
            visible: showErrors && !isQuoteForNamedNonOwner && vinInvalid,
        },
        vinAmpersandNotification: {
            visible: isVinAmpersandNotificationVisible,
        },
        vinRelatedFieldsContainer: {
            visible: !isQuoteForNamedNonOwner,
        },
        vehicleType: {
            required: true,
            disabled: !baseOnNull,
            availableValues: availableVehicleTypes,
            onValueChange: (value, path) => {
                if (value === vehicleVM.value.vehicleType_Ext) {
                    return;
                }
                onValueChange(value, path);
                updateVehicleType();
            },
            ...getPlaceholder('vehicleType_Ext', true),
        },
        vinInfoContainer: {
            visible: showVinInfoText,
        },
        vehicleVin: {
            disabled: !baseOnNull,
            onBlur: handleVinBlur,
            ...getPlaceholder('vin'),
            ...getVehicleVinValidator(),
        },
        vehicleYear: {
            readOnly: readOnly,
            disabled: !baseOnNull,
            onBlur: handleYearBlur,
            availableValues: yearOptions,
            ...getPlaceholder('year'),
        },
        makeNotFound: {
            visible: vinInvalid && isPPA,
            onValueChange: clearMakeAndModel,
            ...getPlaceholder('makeNotFound_Ext'),
        },
        vehicleMake: {
            readOnly: readOnly,
            disabled: !baseOnNull,
            visible: !makeNotFound && isPPA,
            availableValues: makeOptions,
            onBlur: handleMakeBlur,
            ...getPlaceholder('make'),
        },
        vehicleMakeNew: {
            visible: (vinInvalid && makeNotFound) || !isPPA,
            label: isPPA ? messages.newMake : messages.make,
            ...getPlaceholder('make'),
        },
        modelNotFound: {
            visible: vinInvalid && !makeNotFound && isPPA,
            onValueChange: clearModel,
            ...getPlaceholder('modelNotFound_Ext'),
        },
        vehicleModel: {
            readOnly: readOnly,
            disabled: !baseOnNull,
            visible: !makeNotFound && !modelNotFound && isPPA,
            availableValues: modelOptions,
            onBlur: handleDetailBlur,
            ...getPlaceholder('model'),
        },
        vehicleModelNew: {
            visible: (vinInvalid && (makeNotFound || modelNotFound)) || !isPPA,
            label: isPPA ? messages.newModel : messages.model,
            ...getPlaceholder('model'),
        },
        vehicleCostNew: {
            required: isRequiredForIssuance && isNewVehicleReplacementSelected,
            visible: isCostNewVisible,
            disabled: isCostNewDisabled,
            ...getPlaceholder('costNew'),
        },
        vehicleBodyType: {
            availableValues: availableBodyTypes,
            ...getPlaceholder('bodyType_Ext'),
        },
    };
    const resolvers = {
        // resolveValue: readValue,
        resolveClassNameMap: styles,
    };
    return showLoader ? (<Loader loaded={!showLoader} />) : (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onValueChange={handleChange}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
}

VehicleInfoLookupComponent.propTypes = {
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func,
    // displayAllFields: PropTypes.bool,
    value: PropTypes.shape({ value: PropTypes.shape({}) }),
    quoteStatus: PropTypes.shape({ code: PropTypes.string }).isRequired,
    onValueChange: PropTypes.func,
    checkExistenceOfVIN: PropTypes.func,
    isOtherThanCollisionSelected: PropTypes.bool,
    isQuoteForNamedNonOwner: PropTypes.bool,
    showVinInfoText: PropTypes.bool,
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
    showErrors: PropTypes.bool,
    layout: PropTypes.oneOf(['normal', 'reversed', 'full', 'indent']),
    labelPosition: PropTypes.oneOf(['left', 'right', 'top']),
    readOnly: PropTypes.bool,
    validVinInfo: PropTypes.shape({}).isRequired,
    updateValidVinInfo: PropTypes.func.isRequired,
    checkCanViewAndEdit: PropTypes.func,
    updateVehicleType: PropTypes.func,
    updateCacheOptions: PropTypes.func,
    getCacheOptions: PropTypes.shape({}),
    isNewVehicleReplacementSelected: PropTypes.bool,
    shouldSkipInitialization: PropTypes.bool,
};

VehicleInfoLookupComponent.defaultProps = {
    onValidate: undefined,
    value: undefined,
    onValueChange: undefined,
    // displayImage: true,
    // displayAllFields: true,
    labelPosition: 'left',
    layout: 'normal',
    showVinInfoText: false,
    isOtherThanCollisionSelected: false,
    showErrors: true,
    checkExistenceOfVIN: _.noop,
    isQuoteForNamedNonOwner: false,
    readOnly: false,
    checkCanViewAndEdit: _.noop,
    updateVehicleType: _.noop,
    updateCacheOptions: _.noop,
    getCacheOptions: {},
    isNewVehicleReplacementSelected: false,
    shouldSkipInitialization: false,
};

export default withViewModelService(withAuthenticationContext(
    withValidation(VehicleInfoLookupComponent)
));
