import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import _, { update } from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';

// Conside to move/copy this component into wni-components-platform-react
import { CheckboxElement } from 'wnice-components-platform-react';

import { useModal } from '@jutro/components';

import { TableRowUtil, ProductUtil } from 'wni-portals-util-react';
import { WniCheckboxField } from 'wni-common-base-components';

import { dtoName, xCenter, wtDtoName } from '../../config/vehicles.static';
import VehicleDetails from '../VehicleDetails/VehicleDetails';
import WatercraftDetails from '../VehicleDetails/WatercarftDetails';
import RTVehicleDetails from '../VehicleDetails/RTVehicleDetails';
import metadata from './PolicyVehicle.metadata.json5';
import messages from '../../FNOLPAVehiclesPage.messages';

const {
    PA_LOB_NAME,
    RT_LOB_NAME,
    WT_LOB_NAME,
    CA_LOB_NAME
} = ProductUtil;

function PolicyVehicle(props) {
    const modalApi = useModal();
    const {
        claimVM,
        claimNumber,
        basePath,
        authHeader,
        tableData = [],
        onValidate = _.noop,
        saveIncident,
        LOB_NAME,
        PAGE_TYPE_NAME,
        VehicleService,
        syncWizardData,
        syncWizardDataSnapshot,
        resetWizardDataToSnapshot,
        initIncidentData = {},
        updateIsEditing,
        isEditing,
        handleContactSelectionChange,
        availableDrivers,
        updateAvailableDrivers,
        availablePassengers,
        updateAvailablePassengers
    } = props;

    const dtoPath = LOB_NAME === WT_LOB_NAME ? wtDtoName : dtoName;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const [currentRow, updateCurrentRow] = useState(null);
    const [vehicleTableData, updateVehicleTableData] = useState([]);
    const [searchValue, updateSearchValue] = useState(null);
    const [filterMapData, updateFilterMapData] = useState(null);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, `${PAGE_TYPE_NAME}.publicID`) : null;
        TableRowUtil.setTablePublicIDSelected(activePublicID, 'vehicleTable');
    };

    useEffect(() => {
        if (!_.isEmpty(tableData)) {
            updateVehicleTableData(_.cloneDeep(tableData));
        }
        const dataInfo = {};
        if (!_.isEmpty(tableData)) {
            tableData.forEach((item) => {
                const make = _.get(item, 'make');
                if (!dataInfo[make]) {
                    dataInfo[make] = []
                }
                dataInfo[make].push(item);
            });
        }
        updateFilterMapData(dataInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        highlightRowFn(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRow]);

    const filterBySearchValue = () => {
        const initTableData = _.cloneDeep(tableData);
        if (!searchValue || searchValue === '') {
            updateVehicleTableData(initTableData);
            return false;
        }
        const searchData = initTableData.filter((item) => item.vin && item.vin.slice(-4) === searchValue);
        updateVehicleTableData(searchData);
    };

    const handleFilterChange = (value, key, vehicle) => {
        const newData = _.cloneDeep(filterMapData);
        const currentIndex = newData[key].findIndex((v) => v.publicID === vehicle.publicID);
        _.set(newData[key], currentIndex, {...vehicle, checked: value} );
        updateFilterMapData(newData);
        const checkedVehicles = _.flatten(_.values(newData)).filter((item) => item.checked);
        // const checkedVehiclesData = checkedVehicles.map((item) => {return { make: item.make, model: item.model}});
        // updateFilterValue(checkedVehiclesData);
        if(_.isEmpty(checkedVehicles)) {
            updateVehicleTableData(_.cloneDeep(tableData));
        } else {
            updateVehicleTableData(checkedVehicles);
        }
    };

    const renderModelFilter = () => {
        if(_.isNil(filterMapData) || _.isEmpty(filterMapData)) {
            return null;
        }
        const keysMap = _.keys(filterMapData) || [];
        return _.map(keysMap, (key) => {
            const keyValue = filterMapData[key];
            return (
                <ul key={`make${key}`} className="dropdownMenuItemWrapper">
                    <div className="dropdownMenuItemTitle">{key}</div>
                    {keyValue.map((v, i) => {
                        return (
                            <CheckboxElement
                                className="dropdownMenuItem"
                                key={`model${i}`}
                                label={v?.model}
                                value={!!v?.checked}
                                onValueChange={(val) => handleFilterChange(val, key, v)}
                            />
                        );
                    })}
                </ul>
            );
        });
    };

    const onRowClick = async (item) => {
        if (!item) {
            updateCurrentRow(null);
            updateIsEditing(false);
            return null;
        }
        if (isEditing) {
            return false;
        }
        if(currentRow && _.get(currentRow.value, `${PAGE_TYPE_NAME}.publicID`) === item.publicID) {
            return false
        }
        let res = {}
        setLoadingMask(true);
        if(item.hasIncident) {
            res = await VehicleService.getOrCreateVehicleIncident(
                claimNumber,
                item.incidentPublicId,
                authHeader
            );
        } else {
            res = initIncidentData;
        }
        
        setLoadingMask(false);
        const initRowData = {
            ...res,
            lossPartyType: 'insured',
            [PAGE_TYPE_NAME]: {...item},
            pageTypeName: PAGE_TYPE_NAME,
            isSave: true,
            watercraftType: res.watercraftType || 'listed',
            location: res.location || { country: 'US'}
        };
        const initCurrentRow = viewModelService.create(initRowData, xCenter, dtoPath);
        updateCurrentRow(initCurrentRow);
        updateIsEditing(true);
    };

    const removeVehicleIncident = async (item) => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline',
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return false;
            }
            setLoadingMask(true);
            const res = await VehicleService.removeVehicleIncident(
                claimNumber,
                item.incidentPublicId,
                authHeader
            );
            setLoadingMask(false);
            syncWizardDataSnapshot(res, basePath);
            return true;
        }, _.noop);
    };

    const handleCheckboxChange = async(val, item) => {
        if(val) {
            await onRowClick(item);
            return false;
        }
        await removeVehicleIncident(item)
    }

    const renderIncidentIcon = (item) => {
        const dom = <WniCheckboxField value={item.hasIncident} onValueChange={(val) => handleCheckboxChange(val, item)} />
        return TableRowUtil.renderCell(item.publicID, dom);
    };
    const saveAndClose = async(rowValue) => {
        await saveIncident(basePath, rowValue);
        updateCurrentRow(null);
        updateIsEditing(false);
        updateAvailableDrivers(null);
        updateAvailablePassengers(null);
    };

    const handleCancel = () => {
        // resetWizardDataToSnapshot();
        updateCurrentRow(null);
        updateIsEditing(false);
        updateAvailableDrivers(null);
        updateAvailablePassengers(null);
    };
    const writeValue = (value, path) => {
        if(currentRow) {
            const initCurrentRow = viewModelService.clone(currentRow);
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(initCurrentRow.value, path, undefined);
            } else {
                _.set(initCurrentRow.value, path, value);
            }
            updateCurrentRow(initCurrentRow);
            updateIsEditing(true);
        }
    };

    const generageOverrides = useMemo(() => ({
            '@field': {
                labelPosition: 'left',
            },
            '@element': {
                claimNumber,
                currentRow,
                updateCurrentRow,
                isPolicyVehicle: true,
                writeValue,
                onValidate,
                authHeader,
                claimVM,
                cancel: handleCancel,
                save: (rowValue) => saveAndClose(rowValue),
                VehicleService,
                availableDrivers: availableDrivers,
                availablePassengers: availablePassengers,
                handleContactSelectionChange
            },
            vehicleSection: {
                title: LOB_NAME === WT_LOB_NAME ? 'Select Involved Unit' : 'Involved Vehicle(s)'
            },
            vehicleTableHeader: {
                // visible: LOB_NAME === PA_LOB_NAME
            },
            vehicleSearch: {
                value: searchValue,
                onValueChange: (val) => updateSearchValue(val),
                actionFn: filterBySearchValue,
                onEnter: filterBySearchValue
            },
            filterByModel: {
                content: renderModelFilter(),
            },
            vehicleTable: {
                data: vehicleTableData,
                onRowClick: onRowClick
            },
            hasIncident: {
                renderCell: renderIncidentIcon
            },
            itemToBeInsured: {
                visible: LOB_NAME === WT_LOB_NAME
            },
            insuredItem: {
                visible: LOB_NAME === RT_LOB_NAME
            },
            vinNumber: {
                visible: LOB_NAME !== WT_LOB_NAME && LOB_NAME !== RT_LOB_NAME
            },
            vehicleDetailCard: {
                visible: !!currentRow,
            },
            vehicleDetailField: {
                visible: LOB_NAME === PA_LOB_NAME || LOB_NAME === CA_LOB_NAME
            },
            rtVehicletDetailField: {
                visible: LOB_NAME === RT_LOB_NAME
            },
            watercraftDetailField: {
                visible: LOB_NAME === WT_LOB_NAME
            }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [claimNumber, currentRow, writeValue, onValidate, authHeader, claimVM, handleCancel, VehicleService, availableDrivers, availablePassengers, handleContactSelectionChange, LOB_NAME, searchValue, renderModelFilter, vehicleTableData, onRowClick, renderIncidentIcon, saveAndClose]);

    const resolvers = {
        resolveComponentMap: {
            vehicledetails: VehicleDetails,
            watercraftdetails: WatercraftDetails,
            rtVehicledetails: RTVehicleDetails
        },
        resolveCallbackMap: {
        },
    };

    const readValue = useCallback((id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            currentRow,
            id,
            path,
            generageOverrides
        );
    }, [currentRow, generageOverrides]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={currentRow}
            overrideProps={generageOverrides}
            onModelChange={updateCurrentRow}
            onValueChange={writeValue}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default PolicyVehicle;
