import { defineMessages } from 'react-intl';

export default defineMessages({
    exclusionAndConditionTitle: {
        "id": "quoteandbind.gl.wizard.step.exposures page.Exclusion and Condition.title",
        "defaultMessage": "Exclusions & Conditions"
    },
    exclusionAndConditionActionText: {
        "id": "quoteandbind.gl.wizard.step.exposures page.Exclusion and Condition.add exclusion and conditions",
        "defaultMessage": "Add Exclusion & Conditions"
    }
})