import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicyChange'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class WniEndorsementService {
    static getOOSSliceDates(data, additionalHeaders = {}) {
        return processSubmission('getOOSSliceDates', data, additionalHeaders);
    }

    /**
     * Invokes WniGatewayPolicyChangeHandler.changePolicyType()
     * @param {object} data the WniChangeVehicleTypeRequestDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static changePolicyType(data, authHeader = {}) {
        return processSubmission('changePolicyType', [data], authHeader);
    }

    static loadByJobNumberEndorsement(jobNumber, additionalHeaders = {}) {
        return processSubmission('loadWithJobNumber', [jobNumber], additionalHeaders);
    }

    static loadEndorsementWithjobIDAndEffectiveDate(jobID, effectiveDate, additionalHeaders = {}) {
        return processSubmission('loadWithjobIDAndEffectiveDate', [jobID, effectiveDate], additionalHeaders);
    }

    static saveEndorsement(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    static getStartPolicyChangeWarningMessage(policyNumber, additionalHeaders = {}) {
        return processSubmission('getStartPolicyChangeWarningMessage', [policyNumber], additionalHeaders);
    }

    static getStartPolicyChangeWarningMessageByJobNumber(jobNumber, additionalHeaders = {}) {
        return processSubmission('getStartPolicyChangeWarningMessageByJobNumber', [jobNumber], additionalHeaders);
    }
}
