import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PortalConstants } from 'wni-portals-config-js';
import { IMUnderwritingQuestionsService } from 'wni-capability-quoteandbind-im';
import { QuoteUtil } from 'wni-portals-util-js';
import WizardPage from '../../templates/IMWizardPage';
import metadata from './IMUnderwritingQuestionsPage.metadata.json5';

const PATH = 'lobData.inlandMarine.underwritingQuestionSets';

function IMUnderwritingQuestionsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        imUnderwritingQuestionsService,
        readOnly,
    } = props;

    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation,
    } = useValidation('IMUnderwritingQuestionsPage');

    const [showErrors, updateShowErrors] = useState(false);


    const { jobID, sessionUUID } = submissionVM.value;

    const isExplainBox = (key) => {
        return key.endsWith('Explain_Ext') || key.endsWith('Explain');
    };

    const validRegisterComponent = useCallback(() => {
        const { code: questionSetCode, answers: questionSetAnswer } = _.get(submissionVM.value,`${PATH}[0]`,{});

        const explainFieldsValid = {}; // check explain field is valid
        const explainKeyMap = _.keys(questionSetAnswer); // get questions field keys

        _.mapKeys(questionSetAnswer, (value, key) => {
            const keyWithExplainPath = `${key}Explain_Ext`;

            if (!isExplainBox(key) && _.includes(explainKeyMap, keyWithExplainPath)) {
                const isExplainValid = value === 'true' ? !_.isNil(questionSetAnswer[keyWithExplainPath]) : true;
                _.set(explainFieldsValid, keyWithExplainPath, isExplainValid);
            }
        });
        return _.every(explainFieldsValid, (val)=> val);
    }, [submissionVM.value]);

    useEffect(() => {
        registerComponentValidation(validRegisterComponent);
    }, [registerComponentValidation, validRegisterComponent]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, `${PATH}.value`),
        };
        const res =
            await imUnderwritingQuestionsService.updateUnderwritingQuestions(
                requestData,
                authHeader
            );
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, `${PATH}.value`, res.underwritingQuestionSets);
            _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        }
        setLoadingMask(false);
        updateWizardData(newSubmissionVM);
        return newSubmissionVM;

    }, [imUnderwritingQuestionsService, submissionVM, updateWizardData]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    //---------------------
    const overrideProps = {
        '@field': {
                // apply to all fields
                labelPosition: 'left',
                showRequired: true,
            },
            questionSetsContainer: {
                isRequiredForExplain: true,
                isReadOnly: readOnly,
            }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            questionset: QuestionSetComponent,
        },
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

IMUnderwritingQuestionsPage.propTypes = WizardPage.propTypes;
IMUnderwritingQuestionsPage.defaultProps = WizardPage.defaultProps;

IMUnderwritingQuestionsPage.propTypes = {
    ...wizardProps,
    imUnderwritingQuestionsService: PropTypes.object,
};

IMUnderwritingQuestionsPage.defaultProps = {
    imUnderwritingQuestionsService: IMUnderwritingQuestionsService,
};
export default IMUnderwritingQuestionsPage;
