import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLGeneralWizard from './FNOLGeneralWizard';
import FNOLGLLossTypePage from './pages/LossType/FNOLGLLossTypePage';
import FNOLGLBusnessLocationPage from './pages/BusinessLocation/FNOLGLBusnessLocationPage';
import FNOLGLIncidentDetailsPage from './pages/IncidentDetails/FNOLGLIncidentDetailsPage';

setComponentMapOverrides(
    {
        FNOLGeneralWizard: { component: 'FNOLGeneralWizard' },
        FNOLGLLossTypePage: { component: 'FNOLGLLossTypePage' },
        FNOLGLBusnessLocationPage: { component: 'FNOLGLBusnessLocationPage' },
        FNOLGLIncidentDetailsPage: { component: 'FNOLGLIncidentDetailsPage' },
    },
    {
        FNOLGeneralWizard,
        FNOLGLLossTypePage,
        FNOLGLBusnessLocationPage,
        FNOLGLIncidentDetailsPage,
    }
);

export {
    FNOLGeneralWizard as default,
    FNOLGLLossTypePage,
    FNOLGLBusnessLocationPage,
    FNOLGLIncidentDetailsPage
};
