import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { BaseMobileWizardSteps } from 'wni-portals-wizard-components-ui';
// import styles from './MobileWizardSteps.module.scss';

function MobileWizardSteps(props) {
    return <BaseMobileWizardSteps {...props} />;
}

MobileWizardSteps.propTypes = BaseMobileWizardSteps.propTypes;

export default MobileWizardSteps;
