import React, {
    useContext, useCallback, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { WniDateUtil } from 'wni-portals-util-js';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator, IntlContext } from '@jutro/locale';
import { Chevron, Loader } from '@jutro/components';
import { Grid } from '@jutro/layout';
// import { PolicyDiffService } from 'gw-capability-policyjob';
import messages from './BasePolicyChangeQuoteComponent.messages';
import style from './BasePolicyChangeQuoteComponent.module.scss';

function BasePolicyChangeQuoteComponent(props) {
    const {
        // jobNumber,
        changeDiffId,
        policyDiffData,
        isServiceCallInProgress: isLoading,
    } = props;
    const [accordionHeaders] = useState([]);
    const COLUMN_CONFIGURATION = ['auto', '12rem', '12rem'];
    const sanitizeText = useCallback((dirtyString) => {
        return dirtyString.replace(/[^A-Za-z0-9]/gi, '');
    }, []);

    const defaultGenerateAllAccordionHeaders = useCallback(() => {
        const overrides = accordionHeaders.map((accordionHeader) => {
            return {
                [`quoteCard${sanitizeText(accordionHeader.label)}`]: {
                    title: accordionHeader.label,
                    chevron: true
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [accordionHeaders, sanitizeText]);

    const isDateValid = (value) => {
        return moment(value, 'MM/DD/YYYY', true).isValid();
    };
    const generateQuoteGrid = useCallback((currentNode) => {
        const { value1, value2 } = currentNode;

        // change PC Homeowners label to Home in Portal
        let { label: nodeLabel } = currentNode;
        if (nodeLabel === 'Homeowners') {
            nodeLabel = 'Home';
        }

        // Try to format value in the frontend is dangerous. e.g. "PO Box 9187"
        // could be formatted to "01/01/9187". It might be more reasonable to
        // do the Date format in PC through config/resources/diff/PADiffTree.xml.
        const formattedValue1 = isDateValid(value1) ? WniDateUtil.formatDateWithPattern(value1) : value1;
        const formattedValue2 = isDateValid(value2) ? WniDateUtil.formatDateWithPattern(value2) : value2;
        return {
            id: `quoteRow${sanitizeText(currentNode.label)}`,
            type: 'container',
            component: 'Grid',
            componentProps: {
                columns: COLUMN_CONFIGURATION,
                gap: 'none',
                className: 'changeRowItem'
            },
            content: [
                {
                    id: `quoteRowLabel${sanitizeText(currentNode.label)}`,
                    type: 'element',
                    component: 'span',
                    content: nodeLabel,
                },
                {
                    id: `quoteRowValue1${sanitizeText(currentNode.label)}`,
                    type: 'element',
                    component: 'span',
                    componentProps: {
                        className: 'gridColumn'
                    },
                    content: formattedValue1,
                },
                {
                    id: `quoteRowValue2${sanitizeText(currentNode.label)}`,
                    type: 'element',
                    component: 'span',
                    componentProps: {
                        className: 'gridColumn'
                    },
                    content: formattedValue2,
                }
            ]
        };
    }, [COLUMN_CONFIGURATION, sanitizeText]);

    const defaultGenerateQuoteTable = useCallback(
        (currentNodes) => {
            return currentNodes.map((currentNode) => {
                if (currentNode.isLeaf) {
                    return generateQuoteGrid(currentNode);
                }

                accordionHeaders.push(currentNode);
                return {
                    id: `quote${sanitizeText(currentNode.label)}`,
                    type: 'container',
                    component: 'accordion',
                    componentProps: {
                        closeOthers: false,
                        showFrame: false,
                        boldFont: false,
                        chevronAlignment: 'left',
                        defaultOpenedId: `quoteCard${sanitizeText(currentNode.label)}`
                    },
                    content: [
                        {
                            id: `quoteCard${sanitizeText(currentNode.label)}`,
                            type: 'element',
                            component: 'accordioncard',
                            componentProps: {
                                cardBodyClassName: 'cardBodyMargins',
                            },
                            content: defaultGenerateQuoteTable(currentNode.children)
                        }
                    ]
                };
            });
        },
        [accordionHeaders, generateQuoteGrid, sanitizeText]
    );

    const {
        generateQuoteTable = defaultGenerateQuoteTable,
        generateAllAccordionHeaders = defaultGenerateAllAccordionHeaders,
    } = props;


    const renderQuoteTable = useCallback(() => {
        // const { quote, id } = props;

        const quoteChangeTitle = {
            id: 'quoteTitle',
            type: 'container',
            component: 'Grid',
            componentProps: {
                columns: COLUMN_CONFIGURATION,
                gap: 'none',
                className: 'mb-10'
            },
            content: [
                {
                    id: 'quoteTitleItem',
                    type: 'element',
                    component: 'div',
                    componentProps: {
                        className: 'gridTitle'
                    },
                    content: messages.quoteChangeItem
                },
                {
                    id: 'quoteTitleExisting',
                    type: 'element',
                    component: 'div',
                    componentProps: {
                        className: 'gridTitle'
                    },
                    content: messages.quoteChangeExisting
                },
                {
                    id: 'quoteTitleNew',
                    type: 'element',
                    component: 'div',
                    componentProps: {
                        className: 'gridTitle'
                    },
                    content: messages.quoteChangeNew
                }
            ]
        };

        // const metadata = generateQuoteTable(quote);
        const metadata = generateQuoteTable(
            policyDiffData,
            generateQuoteGrid,
            sanitizeText,
        );
        metadata.unshift(quoteChangeTitle);

        return {
            content: [
                {
                    id: `quote${changeDiffId}`,
                    type: 'container',
                    component: 'accordion',
                    componentProps: {
                        closeOthers: false,
                        showFrame: false,
                        chevronAlignment: 'right',
                        className: 'noPad-cardTitle',
                        defaultOpenedId: `quoteCard${changeDiffId}`
                    },
                    content: [
                        {
                            id: `quoteCard${changeDiffId}`,
                            type: 'container',
                            component: 'accordioncard',
                            componentProps: {
                                cardBodyClassName: 'cardBodyMainMargins'
                            },
                            content: metadata
                        }
                    ]
                }
            ]
        };
    }, [
        COLUMN_CONFIGURATION,
        changeDiffId,
        generateQuoteGrid,
        generateQuoteTable,
        policyDiffData,
        sanitizeText
    ]);

    if (_.isEmpty(policyDiffData)) {
        return null;
    }
    // ===================================================
    const resolvers = {
        resolveClassNameMap: style
    };
    const generatedMetadata = renderQuoteTable();
    const overrides = {
        [`quoteCard${changeDiffId}`]: {
            title: messages.quoteChangeTitle,
            chevron: true
        },
        ...generateAllAccordionHeaders(
            sanitizeText,
        )
    };

    return (
        <Loader loaded={!isLoading}>
            <MetadataContent uiProps={generatedMetadata} overrideProps={overrides} {...resolvers} />
        </Loader>
    );
}

BasePolicyChangeQuoteComponent.propTypes = {
    changeDiffId: PropTypes.string,
    policyDiffData: PropTypes.arrayOf(PropTypes.shape({})),
    isServiceCallInProgress: PropTypes.bool,
    generateQuoteTable: PropTypes.func,
    generateAllAccordionHeaders: PropTypes.func,
};
BasePolicyChangeQuoteComponent.defaultProps = {
    changeDiffId: 'policyChangeDiff',
    policyDiffData: [],
    isServiceCallInProgress: false,
    generateQuoteTable: undefined,
    generateAllAccordionHeaders: undefined,
};
export default BasePolicyChangeQuoteComponent;
