import _ from 'lodash';
import { WNIAddressLookupService } from 'wni-capability-address';

/*
    getVerifyIssues: if address invalid, the warning message visible
*/
const getVerifyAddressIssues = (visible) => {
    return {
        type: 'warning',
        visible: visible,
        reason: 'Address entered could not be validated. Please modify the address if needed.'
    };
};

/*
    AddressChangeVerify: check the address filed change or not.
    and if change, click next button, will verify again
*/
const AddressChangeVerify = (path, addressPath) => {
    const addressMap = [addressPath, `${addressPath}.pobox_Ext`, `${addressPath}.addressLine1`, `${addressPath}.city`, `${addressPath}.county`, `${addressPath}.postalCode`, `${addressPath}.state`];

    if (addressMap.includes(path)) {
        return true;
    }
    return false;
};

/*
    getValidationMap: if the warning message is visible or not, and return all the validations
*/
const getValidationMap = (validation, validationMap) => {
    const { visible = true } = validation;
    const existValidation = validationMap.find((msg) => msg.reason === validation.reason);
    if (!visible) {
        return validationMap.filter(({ reason }) => reason !== validation.reason);
    }
    if (!existValidation) {
        return [...validationMap, validation];
    }

    return validationMap;
};

const onVerified = async (addressValue, authHeader) => {
    try{
        const verifiedData = await WNIAddressLookupService.verifyAddress(addressValue, authHeader);
        const { verifiedAddresses, addressValidationStatus, errorsAndWarnings: {displayMssages = []} = {} } = verifiedData;
        
        if (!verifiedAddresses || verifiedAddresses.length === 0) {
            let validationIssues = displayMssages;
            if(_.isEmpty(displayMssages)) {
                const verifiedAddressMsg = getVerifyAddressIssues(true)
                validationIssues = [verifiedAddressMsg]
            }
            return {
                isVerified: false,
                validationIssues,
                addressValue: {
                    ...addressValue,
                    isAutofilled: false,
                    addressValidationStatus_Ext: addressValidationStatus,
                }
            };
        };

        const suggestedAddress = verifiedAddresses[0];
        return {
            isVerified: true,
            addressValue: {
                ...addressValue,
                ...suggestedAddress,
                isAutofilled: true,
                addressValidationStatus_Ext: addressValidationStatus
            }
        }
    } catch(e) {
        return {
            isVerified: true,
            addressValue: {
                ...addressValue,
                isAutofilled: false,
                addressValidationStatus: 'technicalerror',
            },
        };
    }
};

export default {
    getVerifyAddressIssues,
    AddressChangeVerify,
    getValidationMap,
    onVerified
};