import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect } from 'react';
import { CURiskDetailsService } from 'wni-capability-quoteandbind-cu';
import messages from './UmbrellaCoveragesCard.messages';
import CoverageContext from '../../../../context/CUCoverageContext';
import CUClausesComponentVM from '../../../../components/CUClausesComponentVM';
import CUSingleClauseComponentVM from '../../../../components/CUSingleClauseComponentVM';
import CUCoverageUtil from '../../../../util/CUCoverageUtil';


const UmbrellaCoveragesCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        riskDetails,
        setRiskDetails,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props;


    const translator = useTranslator();

    const validationID = 'UmbrellacoveragesCard';
    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    const {
        standardCoverage
    } = riskDetails

    const changeSubmission = (value, changedPath) => {
            const newClauses = CUCoverageUtil.setClauseValue(standardCoverage, 'standardCoverage', value, changedPath);
            const newCoverableCoverages = _.clone(riskDetails)
            _.set(newCoverableCoverages, 'standardCoverage', newClauses);
            setRiskDetails(newCoverableCoverages);
            setIsEditing(false);
            return newCoverableCoverages;
        }

    const updateVMToServer = async (newRiskDetails, setEditingFunc) => {
        const lineCoveragesToUpdate = CUCoverageUtil.generateUpdatedRiskDetailsDTO(newRiskDetails);
        const lineCoveragessFromServer = await withLoadingMask(() => CURiskDetailsService.updateRiskDetails(
            jobID,
            sessionUUID,
            lineCoveragesToUpdate,
            authHeader
        ))
        setEditingFunc(false)
        setRiskDetails(lineCoveragessFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }
    const updateNewCoveragePartClausesToServer = async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }

    const changeClauseAndSync = (value, changedPath) => {
            const newCoveragePartClauses = changeSubmission(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        }

    const onSyncCoverages = () => {
            updateNewCoveragePartClausesToServer(riskDetails);
        }

    return (
        <AccordionCard
            id="UmbrellaCoveragesCard"
            initialExpanded
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={translator(messages.UmbrellaCoverages)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: riskDetails,
                        updateClausesContainer: setRiskDetails,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <CUClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeClauseAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        path="standardCoverage"
                        value={standardCoverage}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CUSingleClauseComponentVM: CUSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default UmbrellaCoveragesCard;
