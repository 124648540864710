import React, {useEffect, useCallback, useState, useContext} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useWniModal } from 'wni-components-platform-react';
import { WniDateUtil, WniAccountContactUtil } from 'wni-portals-util-js';
import PropTypes from 'prop-types';
import { WniDBAService } from 'wni-capability-quoteandbind';
import { DropdownMenuHeader, Loader } from '@jutro/components';
import DBADetailComponent from '../DBADetailComponent/DBADetailComponent'
import metadata from './DBAComponent.metadata.json5';

import { DropdownMenuLink } from '@jutro/router';

function DBAComponent(props) {
    const {
        model,
        onValueChange,
        onValidate,
        isReadOnly,
        isRequiredForIssuance,
        authHeader,
        sessionUUID,
        jobID,
        accountContacts,
        policyDetailPath,
        currentPcr,
        viewModelService,
        source
    } = props;

    const modalApi = useWniModal();

    const translator = useTranslator();
    const [currentRow, updateCurrentRow] = useState();
    const [showErrors, updateShowErrors] = useState(false);
    const [originCurrentRow, updateOriginCurrentRow] = useState();
    const [selection, updateSelection] = useState([]);
    const [loading, updateLoading] = useState(false);
    const [DBAsVM, updateDBAsVM] = useState(model);
    const [pcrID, updatePcrID] = useState();

    useEffect(() => {
        if (source === 'PNI') {
            updatePcrID(currentPcr.value.pnipublicID_Ext);
        } else {
            updatePcrID(currentPcr.value.publicID);
        }
    }, []);

    const buildAddItemList = () => {
        return [
            { code: 'company', name: 'New Company'},
            { code: 'person', name: 'New Person'}
        ];
    };

    const updateSubmissionByResponse = (response) => {
        onValueChange(response.additionalInsureds, `${policyDetailPath}.additionalInsureds`);
        // onValueChange(response.unassignedAdditionalNamedInsureds, `${policyDetailPath}.unassignedAdditionalNamedInsureds`);
        onValueChange(response.pnidbalist, 'baseData.pnidbalist_Ext');
        onValueChange(response.policyDBAList, `${policyDetailPath}.policyDBAList`);
        if (source === 'PNI') {
            _.set(DBAsVM, 'value', response.pnidbalist);
            updateDBAsVM(DBAsVM);
        }
    };

    const viewOrEdit = useCallback((value, index) => {
        const childrenVM = _.get(DBAsVM, 'children');
        const itemVM = childrenVM.find((item) => item.value.rowIdPath === index);
        updateShowErrors(false);
        const contactType = _.get(itemVM, 'value.contact.contactType_Ext')
        if (contactType === 'company') {
            _.set(itemVM, 'contact.contactNameRequired_Ext', true);
            _.set(itemVM, 'contact.phoneRequired_Ext', false);
        }
        updateCurrentRow(itemVM);
        updateOriginCurrentRow(viewModelService.clone(itemVM));
    }, [DBAsVM, viewModelService])

    const addItemFn = useCallback((item) => {
        const DTO_PATH = 'wni.edge.capabilities.gateway.contact.dto.WniDBADTO';
        const xCenter = 'pc';
        const addItem = WniAccountContactUtil.setDBADefaultObj(item.code, currentPcr, pcrID)
        const newVM = viewModelService.create(addItem, xCenter, DTO_PATH);
        DBAsVM.pushElement(newVM);
        updateShowErrors(false);
        updateCurrentRow(newVM)
        updateOriginCurrentRow(newVM);
    }, [DBAsVM, viewModelService]);

    const removeFn = useCallback(async () => {
        updateLoading(true);
        const res = await WniDBAService.removeDBAs(jobID, sessionUUID, selection, pcrID, authHeader)
        if (res.removeErrorMessage) {
            modalApi.showConfirm({
                message: res.removeErrorMessage,
                status: 'error',
                icon: 'gw-error-outline'
            });
        } else {
            updateSubmissionByResponse(res);
        }
        updateLoading(false);
        updateCurrentRow(null);
        updateSelection([]);
    }, [selection])

    const isAddBtnDisabled = useCallback(() => {
        const items = _.get(DBAsVM, 'value');
        const newlyItem = _.filter(items, (item) => {
            return !item.publicID
        })
        if (_.isEmpty(newlyItem)) {
            return false;
        }
        return true;
    }, [DBAsVM]);

    const renderAddItemContent = () => {
        const addItemList = buildAddItemList();
        return _.map(addItemList, (item, index) => {
            return (
                <DropdownMenuLink key={item.code} onClick={(e) => { addItemFn(item) }}>
                    {item.name}
                </DropdownMenuLink>
            )
        })
    }

    const renderDBATitle = () => {
        let pcrName;
        if (currentPcr.contactCode_Ext === 'Company') {
            pcrName = currentPcr.company.contactName || ''
        }
        if (currentPcr.contactCode_Ext === 'Person') {
            pcrName = `${currentPcr.person_Ext.firstName || ''} ${currentPcr.person_Ext.lastName || ''}`
        }

        return pcrName ? translator(`${pcrName} DBAs`) : ''
    }

    const getFormattedDate = (items, index, property) => {
        if (typeof items[property.id] === 'undefined') {
            return '';
        }
        return WniDateUtil.formatDateWithPattern(items[property.id], 'MM/DD/YYYY')
    };

    const saveDBA = async () => {
        const dba = currentRow.value
        updateLoading(true);
        const res = await WniDBAService.saveDBA(jobID, sessionUUID, dba, authHeader);
        updateLoading(false);
        updateSubmissionByResponse(res);
        updateCurrentRow(null);
    }

    const writeValue = (value, path) => {
        if(currentRow) {
            _.set(currentRow, path, value);
            updateCurrentRow(currentRow);
        }
        if (source === 'PNI') {
            onValueChange(value, path);
        } else {
            onValueChange();
        }
    }

    //---------------------
    const overrides = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        DBATitle: {
            content: renderDBATitle()
        },
        removeItem: {
            disabled: _.isEmpty(selection),
            visible: !isReadOnly
        },
        addItem: {
            disabled: isAddBtnDisabled(),
            content: renderAddItemContent(),
            visible: !isReadOnly
        },
        DBAsTable: {
            data: _.get(DBAsVM, 'value'),
            onSelectionChange: (rows) => updateSelection(rows),
            rowIdPath: 'rowIdPath'
        },
        DBADetailContainer: {
            visible: Boolean(currentRow)
        },
        viewOrEditLink: {
            label: isReadOnly ? 'View' : 'View/Edit',
            disabled: Boolean(currentRow)
        },
        DBADetailComponent: {
            currentRow,
            updateDBAsVM,
            updateCurrentRow,
            onValueChange: writeValue,
            originCurrentRow,
            currentPcr,
            accountContacts,
            policyDetailPath,
            isReadOnly,
            DBAsVM,
            saveDBA,
            viewModelService,
            source
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            viewOrEditDBA: viewOrEdit,
            removeFn,
            getFormattedDate
        },
        resolveComponentMap: {
            dbadetailcomponent: DBADetailComponent
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, DBAsVM, fieldId, fieldPath, overrides
        );
    };

    if (loading) {
        return <Loader loaded={!loading} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={DBAsVM}
            overrideProps={overrides}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

DBAComponent.propTypes = {
    onValueChange: PropTypes.func
};
DBAComponent.defaultProps = {
    onValueChange: _.noop
};

export default DBAComponent;
