import React, {
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    BreakpointTrackerContext
} from '@jutro/layout';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './UploadFieldComponent.metadata.json5';

function UploadFieldComponent(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        labelPosition,
        singleUpload,
        showErrors,
        label,
        uploadConfig: {
            uploadFiles,
            updateUploadFiles,
            required
        }
    } = props

    const componentProps = _.omit(props, ['uploadConfig', 'singleUpload']);
    const [tableFiles, setTableFiles] = useState([]);

    useEffect(() => {
        if (singleUpload) {
            setTableFiles(uploadFiles ? [uploadFiles] : []);
        } else {
            setTableFiles(uploadFiles ? [...uploadFiles] : []);
        }
    }, [singleUpload, uploadFiles])

    const uploadFn = (file) => {
        let newFiles;
        if (singleUpload){
            newFiles = file;
        }else{
            newFiles = uploadFiles ? _.concat(uploadFiles, [file]) : [file]
        }
        updateUploadFiles(newFiles)
    }

    const deleteRowFn = (row) => {
        let leftFiles;
        if(singleUpload){
            leftFiles = null
        } else {
            leftFiles = uploadFiles.filter((item) => item.lastModified !== row.lastModified)
        }
        updateUploadFiles(leftFiles)
    }

    const renderSize = (item, index, { path }) => {
        const num = 1000;
        return `${(item[path]/num).toFixed(2)}KB`;
    }
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        fileUploaderMask: {
            required: required,
            value: uploadFiles,
            label,
            showErrors
        },
        fileUploader: {
            ...componentProps,
            onUpload: uploadFn
        },
        fileUploadTableContainer: {
            className: `fileUploadTable ${labelPosition}`
        },
        fileUploadTable: {
            visible: !_.isEmpty(tableFiles),
            data: tableFiles
        }
    };
    

    const resolvers = {
        resolveCallbackMap: {
            deleteRowFn,
            renderSize
        }
    };
    return (
        <ViewModelForm
            model={uploadFiles}
            overrideProps={overrideProps}
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

UploadFieldComponent.propTypes = {
    singleUpload: PropTypes.bool,
    uploadConfig: PropTypes.shape({}).isRequired
};
UploadFieldComponent.defaultProps = {
    singleUpload: true
};

export default UploadFieldComponent;
