import { PRICE_VIEW_MODES } from './constants/PortalConstants';

const paymentPlanData = {
    'Full Pay': 'Every Year',
    'Semiannual': 'Semiannual',
    'Quarterly PL - 12 months': 'Quarterly',
    'Quarterly PL - 6 months': 'Quarterly',
    'Monthly PL - 12 months': 'Monthly',
    'Monthly PL - 6 months': 'Monthly',
};

const fullPayStr = 'Full Pay';

const paymentMethodData = {
    bank: 'wire',
    credit: 'creditcard',
};

const payUsing = {
    check: 'Check',
    autopay: 'Autopay',
    autopayNew: 'Autopay',
};

// const PAYMENT_PLAN_TYPE_MAP = {
//     [PRICE_VIEW_MODES.annually]: ['Full Pay'],
//     [PRICE_VIEW_MODES.monthly]: ['Semiannual',
//         'Quarterly PL - 12 months', 'Quarterly PL - 6 months',
//         'Monthly PL - 12 months', 'Monthly PL - 6 months',
//     ],
// };

const PAYMENT_PLANS_BASIC = {
    FULL_PAY: 'Full Pay',
    SIX_MONTHS: 'Monthly PL - 6 months',
    TWELVE_MONTHS: 'Monthly PL - 12 months',
};

// See PAQuotePage.metadata.json::paymentPlanToggle
const TERM_TYPE_TO_PAYMENT_PLAN_MAP = {
    Annual: {
        annually: PAYMENT_PLANS_BASIC.FULL_PAY,
        monthly: PAYMENT_PLANS_BASIC.TWELVE_MONTHS,
    },
    HalfYear: {
        annually: PAYMENT_PLANS_BASIC.FULL_PAY,
        monthly: PAYMENT_PLANS_BASIC.SIX_MONTHS,
    }
};

export default {
    paymentPlanData,
    paymentMethodData,
    fullPayStr,
    payUsing,
    // PAYMENT_PLAN_TYPE_MAP,
    PAYMENT_PLANS_BASIC,
    TERM_TYPE_TO_PAYMENT_PLAN_MAP,
};
