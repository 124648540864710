import React, {
    useCallback,
    useContext,
    useState,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { 
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import WALCoverageUtil from '../../util/WALCoverageUtil';
import WALClausesComponentVM from '../WALClausesComponentVM/WALClausesComponentVM';
import WALSingleClauseComponentVM from '../WALSingleClauseComponentVM/WALSingleClauseComponentVM';
import metadata from './WatercraftCoveragesModal.metadata.json5';
import messages from './WatercraftCoveragesModal.messages';

import { Button } from '@jutro/legacy/components';

function WatercraftCoveragesModal(props) {
    const {
        isEditable,
        title,
        size,
        isOpen,
        onResolve,
        submissionVM,
        updateWizardData,
        watercraftPublicID,
        openCopyCoveragesModal,
        // updateErrorsAndWarnings,
        // loadingVehicleCoverages,
        lobName,
        selectedVersionIndex,
        coveragesService,
        currentPageId,
    } = props;


    const {
        onValidate,
        isComponentValid,
    } = useValidation('WatercraftCoveragesModal');

    const {
        jobID,
        sessionUUID,
        lobData: {
            [lobName]: {
                coverables: {
                    watercrafts = []
                } = {}
            } ={}
        } = {}
    } = submissionVM.value

    const allWatercraftCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.watercraftCoverages`;
    const allWatercraftCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.watercraftCoverages.children`;
    
    const allWatercraftCoverages = _.get(submissionVM.value, allWatercraftCoveragesPath);
    const allWatercraftCoverageVMs = _.get(submissionVM, allWatercraftCoveragesVMPath);

    const walCoverageIndex = allWatercraftCoverages
        .findIndex((walCoverageDTO) => _.get(walCoverageDTO, 'owningCoverablePublicID') === watercraftPublicID);
    const walCoverageVMIndex = allWatercraftCoverageVMs
        .findIndex((walCoverageVM) => _.get(walCoverageVM.value, 'owningCoverablePublicID') === watercraftPublicID);

    const watercraftCoveragePath = `${allWatercraftCoveragesPath}[${walCoverageIndex}].coverages`;
    const watercraftCoveragesVMPath = `${allWatercraftCoveragesVMPath}.[${walCoverageVMIndex}].coverages`;

    const watercraftCoverages = _.get(
        submissionVM.value,
        watercraftCoveragePath
    )

    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [loadingWaterCraftCoverages, setLoadingWaterCraftCoverages] = useState(false);
    const [popupWarningIssue, setPopupWarningIssue] = useState([]);


    const handleSaveAndClose = () => {
        if (isEditing || loadingWaterCraftCoverages) {
            return;
        }
        if (!isComponentValid && isEditable) {
            setShowErrors(true);
        } else {
            onResolve();
        }
    };

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = WALCoverageUtil.setClauseValue(watercraftCoverages, watercraftCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, watercraftCoveragePath, newCoverages);
            updateWizardData(submissionVM);
            return submissionVM;
        },
        [
            watercraftCoverages,
            watercraftCoveragesVMPath,
            submissionVM,
            watercraftCoveragePath,
            updateWizardData,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = WALCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = WALCoverageUtil.getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingWaterCraftCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        // Only show warnings on field change here
        const newErrorsAndWarnings = ErrorsAndWarningsUtil.getServerIssues(errorsAndWarnings);
        const newWarnings = newErrorsAndWarnings.filter((issue) => _.get(issue, 'type') === 'warning');
        setPopupWarningIssue(newWarnings)
        // updateErrorsAndWarnings(errorsAndWarnings);
        setLoadingWaterCraftCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        lobName,
        coveragesService
    ]);

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const handleCopyCoverages = () => {
        openCopyCoveragesModal(watercraftPublicID);
    }

    const multipleWatercrafts = watercrafts.filter((watercraft) => WALCoverageUtil.getIsPhysicalDamageCovRequired(watercraft)).length > 1;

    const overrideProps = {
        dynamicInlineNotificationContainer: {
            visible: popupWarningIssue.length > 0,
            validationIssues: popupWarningIssue
        },
        watercraftCoverageModalCopyCoverageButton: {
            visible: isEditable && multipleWatercrafts,
        },
        watercraftCoveragesComponent: {
            loadingClause: loadingWaterCraftCoverages,
            path: watercraftCoveragesVMPath,
            value: watercraftCoverages,
            onValidate,
            showErrors,
            componentMapOverrides: {
                WALSingleClauseComponentVM: WALSingleClauseComponentVM,
            },
            isEditable,
            setIsEditing,
            isEditing,
            currentPageId,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onCopyCoverageButtonClick: handleCopyCoverages,
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
        },
        resolveComponentMap: {
            WALClausesComponentVM: WALClausesComponentVM,
            ValidationIssuesComponent: ValidationIssuesComponent,
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            className={size}
        >
            <ModalHeader
                title={title || 'title'}
                onClose={handleSaveAndClose}
            />
            <ModalBody
                id="vehicleCoverageModal"
            >
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    // model={selectedVehicleVM.current}
                    model={{}}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button disabled={isEditing || loadingWaterCraftCoverages} onClick={handleSaveAndClose}>
                    {translator(messages.saveAndClose)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

WatercraftCoveragesModal.propTypes = {
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({
                lobData: PropTypes.shape({
                    personalAuto: PropTypes.shape({
                        coverables: PropTypes.shape({
                            vehicles: PropTypes.arrayOf()
                        })
                    }),
                })
            })
        }
    ).isRequired,
    watercraftPublicID: PropTypes.string.isRequired,
    openCopyCoveragesModal: PropTypes.func.isRequired,
    updateSubmissionVMOnClauseUpdate: PropTypes.func,
    loadingVehicleCoverages: PropTypes.bool.isRequired,
    lobName: PropTypes.string.isRequired,
    selectedVersionOfferingsIndex: PropTypes.number.isRequired,
};

WatercraftCoveragesModal.defaultProps = {
    isReadOnly: false,
    title: null,
    size: 'md',
    updateSubmissionVMOnClauseUpdate: _.noop,
};

export default WatercraftCoveragesModal;
