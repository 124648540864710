import React, {useState, useCallback, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMMiscellaneousFloatersService as  MiscellaneousFloatersService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMMiscellaneousFloatersPage.metadata.json5';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonClausesCard from '../../components/IMCommonClausesCard/CommonClausesCard';
import IMCommonSingleScheduleTable from '../../components/IMComonCovPartSchedule/CommonSingleScheduleTable';
import messages from './IMMiscellaneousFloatersPage.messages';

const SCHEDULED_PROPERTY_FLOATER_ACCORDION_CARD_COMPONENT_MAPS = ['scheduledPropertyFloaterMainCovCard', 'scheduledPropertyFloaterSupplementalCovCard', 'miscFloatersScheduled', 'miscFloatersCovs']
const MiscellaneousFloatersReadOnlyPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('MiscellaneousFloatersPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [isEditing, setIsEditing] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [miscellaneousFloatersClauses, setMiscellaneousFloatersClauses] = useState();
    const [miscellaneousFloatersLoadingClauses, setMiscellaneousFloatersLoadingClauses] = useState();

    const {
        jobID,
        sessionUUID
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true);
        const res = await MiscellaneousFloatersService.getMiscellaneousFloatersClauses(jobID, sessionUUID, authHeader);
        setMiscellaneousFloatersClauses(res);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    const calculateLimit = useCallback(async (termCode) => {
        setLoadingMask(true);
        const res = await MiscellaneousFloatersService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setMiscellaneousFloatersClauses(res);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: MiscellaneousFloatersService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setMiscellaneousFloatersClauses(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 

    const onPageNext = () => {
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: miscellaneousFloatersClauses,
            setCoveragePartClauses: setMiscellaneousFloatersClauses,
            isEditing,
            setIsEditing,
            isEditable: false,
            showErrors,
            onValidate: onValidate,
            loadingClauses: miscellaneousFloatersLoadingClauses,
            setLoadingClauses: setMiscellaneousFloatersLoadingClauses,
            onClickAddSearchClauses,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus,
            updateCoverageFormClausesService: MiscellaneousFloatersService.updateMiscellaneousFloatersClauses,
            calculateLimit
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: false,
            },
            scheduledPropertyFloater: {
                visible: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.visible'),
                errorState: !_.isEmpty(_.intersection(invalidFields, SCHEDULED_PROPERTY_FLOATER_ACCORDION_CARD_COMPONENT_MAPS))
            },
            scheduledPropertyFloaterMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.mainCoverage'),
                coverageFormPath: 'scheduledPropertyFloater.mainCoverage'
            },
            scheduledPropertyFloaterSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.supplementalCoverage'),
                coverageFormPath: 'scheduledPropertyFloater.supplementalCoverage'
            },
            miscFloatersScheduled: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.miscFloatersScheduled'),
                coverageWithSchedule: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater'),
                updateScheduleService: MiscellaneousFloatersService.updateMiscellaneousFloatersSchedule,
                schedulePath: 'miscFloatersScheduled',
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            miscFloatersCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'scheduledPropertyFloater.miscFloatersCovs',
                // generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedScheduledPropertyFloaterDTO,
                clauses: _.get(miscellaneousFloatersClauses, 'scheduledPropertyFloater.miscFloatersCovs')
            }
        }
    }, [miscellaneousFloatersClauses, invalidFields, accordionCommonProps, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            ScheduledPropertyFloaterMainCovCard: IMCommonMainCoverage,
            ScheduledPropertyFloaterSupplementalCovCard: IMCommonMainCoverage,
            MiscFloatersScheduled: IMCommonSingleScheduleTable,
            MiscFloatersCovs: IMCommonClausesCard
        },
    };

    const disableNavigator = isEditing || isLoadingMask || miscellaneousFloatersLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {miscellaneousFloatersClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default MiscellaneousFloatersReadOnlyPage
