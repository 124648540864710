// import WALFrontendScheduleItemSyncUtil from './util/WALFrontendScheduleItemSyncUtil'
// import WALOptionsUtil from './util/WALOptionsUtil'
// import WALScheduleOptionValuesSortUtil from './util/WALScheduleOptionValuesSortUtil'
import _ from 'lodash';

/**
 * @type {{
*  [scheduleConfigOption: String]: Object
* }}
*/
const scheduleConfig = {
    /**
     * Table column visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleColumnIdsOverride: {
        IMMotorTruckCargoPropertyExcludedExclusion_Ext: [
            'IMPropertyExcludedPropertyType_Ext',
            'IMPropertyExcludedPropertyDescription_Ext'
        ],
        IMMotorTruckCargoTheftExclusion_Ext: [
            'IMTheftExclPropertyType_Ext',
            'IMTheftExclPropertyDescription_Ext'
        ],
        IMCommonAdditionalInsuredA632SchCov: [
            'TypeKeyCol1',
            'StringCol1',
            'StringCol2',
            'StringCol3',
            'IMCommAddInsZipCode',
            'IMCommAddInsDescriptionOfProperty'
        ]
    },
    /**
     * Schedule details visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleFieldIdsOverride: {
    },
    /**
     * Table column visible override
     * key<clauseCode|scheduleId> - value<ScheduleItemId>
     * @type {{
     *  [clauseCodeOrScheduleCode: String]: {
     *      [columnId: String]: Number
     *  }
     * }}
     */
    columnOrderOverride: {
        IMCommNoticeCanEnd_Ext: {
            TypeKeyCol1: 301
        },
        IMAAISCommNoticeCanEnd_Ext: {
            TypeKeyCol1: 301
        },
        IMBoatSchCovAddInsuredSch_Ext: {
            TypeKeyCol1: 301
        },
        IMCEScheduleCovAddInsuredSch_Ext: {
            TypeKeyCol1: 301
        },
        IMFAFSchCovAddInsuredSch_Ext: {
            TypeKeyCol1: 301
        },
        IMMFOSchCovAddInsuredSch_Ext: {
            TypeKeyCol1: 301
        },
        IMMFASchCovAddInsuredSch_Ext: {
            TypeKeyCol1: 301
        }
    },
    /**
     * @type {{
     *  [clauseCode: String]: Array.<String>
     * }}
     */
    fieldsNotNeedSyncWhileChange: {
    },
    /**
     * @type {{
     *  [clauseCode: String]: {
     *      [scheduleItemID: String]: (scheduleItem: Object, allOptions: Array) => Array
     *  }
     * }}
     */
    optionFilters: {
    },
    /**
     * Return a function which return schedule after frontend sync
     * 
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (schedule: Object, localPathToSchedule: String) => Object
     *  }
     * }}
     */
    frontendScheduleItemSync: {
    },
    /**
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (optionA: Object, optionB: Object) => Number
     *  }
     * }}
     */
    optionValueSortFunction: {
    },
    
    // store clause code for it's scheduleTable Not Visible, refer to PC isSchTableVisible_Ext
    getIsScheduleTableVisibleFunction: (code, coveragePartClauses) => {
        if (code === 'IMAgreedAmountEndorsement_Ext' && _.get(coveragePartClauses, 'scheduledEquipmentFormCoverage.visible', false)) {
            return false
        }
        if (code === 'IM7027IncomeCoverage_Ext') {
            return _.get(coveragePartClauses, 'blanketEquipmentFormCoverage.visible', false)
        }
        if(code === "IMInstFloaterReportCondition_Ext") {
            const installationFloaterCoverageType = _.get(coveragePartClauses, 'installationCoverage.installationFloaterScheduleCoverageTypeTerm.terms', []).find(elt => elt.code_Ext === "IMInstallationFloaterCoverageType");
            return !(installationFloaterCoverageType.chosenTerm === "ScheduledLocationsCoverage")
          }
        return true
    }
}

/**
 * @type {{
 *  [clauseCode: String]: Array.<String>
 * }}
 */
const termConfig = {
    termsNotChangeWithSync: [
        // group by coverage
    ],
    // store term code of PC pcf hard code for some special coverages
    termShowAsTextAreaConfig: [
        'IMEquipmentBreakdownandTestingCoverageIM7083DEDUCTIBLESOtherConditions'
    ]
}

/**
 * A key(coverage code)-value(term code) set of coverage has limit term
 * And limit term display right of coverage label
 * 
 * @type {{
 *  [clauseCode: String]: String
 * }}
 */
const limitCoverageConfig = {
}

/**
 * @type {{
*  [clauseCode: String]: (...params: any) => Boolean
* }}
*/
const coverageAvailable = {
}

/**
 * @type {{
*  [clauseCode: String]: {
    *       [scheduleItemID: String]: (...params: any) => Boolean
    *   }
    * }}
    */
const covTermAvailable = {
}

/**
 * @enum {String}
 */
const ClauseComponentType = {
    Toggle: 'Toggle',
}

/**
 * default as checkbox
 * @type {{
*  [clauseCode: String]: String
* }}
*/
const clauseComponentTypeMap = {
}

export default {
    scheduleConfig,
    termConfig,
    limitCoverageConfig,
    coverageAvailable,
    covTermAvailable,
    ClauseComponentType,
    clauseComponentTypeMap,
}