import React, {useEffect, useCallback, useState} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import { Loader, DropdownMenuHeader, DropdownMenuSeparator } from '@jutro/components';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './DBADetailComponent.metadata.json5';

import { DropdownMenuLink } from '@jutro/router';

function DBADetailComponent(props) {
    const {
        currentRow,
        accountContacts,
        updateCurrentRow,
        isReadOnly,
        isRequiredForIssuance,
        onValueChange,
        originCurrentRow,
        currentPcr,
        // updateCurrentPcr,
        updateDBAsVM,
        saveDBA,
        DBAsVM,
        viewModelService,
        source
    } = props;

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('addlInsuredForm');

    const contactType = _.get(currentRow, 'value.contact.contactType_Ext')

    const [showErrors, updateShowErrors] = useState(false);

    const IsValidFields = useCallback(() => {
        if (!currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            return false;
        }
        return true;
    }, [currentRow]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, currentRow]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const buildAddressList = () => {
        const addressList = _.map(accountContacts, (accountContact) => {
            return {
                id: `contact-${accountContact.publicID}`,
                name: accountContact.displayName,
                subMenuList: [
                    {
                        id: `address-${_.get(accountContact, 'primaryAddress.publicID')}`,
                        name: _.get(accountContact, 'primaryAddress.addressLine1'),
                        address: _.get(accountContact, 'primaryAddress')
                    }
                ]
            }
        })
        return addressList;
    };

    const selectAddress = (address) => {
        _.set(currentRow, 'contact.primaryAddress', address)
        updateCurrentRow(currentRow);
        onValueChange()
    }

    const renderContent = () => {
        return buildAddressList().map((item, index) => {
            const subMenuList = item.subMenuList || []
            return (
                <>
                    <DropdownMenuHeader title={item.name} key={item.id}>
                        {!_.isEmpty(subMenuList) &&
                            subMenuList.map((v, i) => {
                                return (
                                    <DropdownMenuLink key={item.id} onClick={(e) => { selectAddress(v.address) }}>
                                        {v.name}
                                    </DropdownMenuLink>
                                );
                            })}
                    </DropdownMenuHeader>
                    <DropdownMenuSeparator />
                </>
            );
        });
    }

    const cancel = () => {
        let originDBAs
        if (_.get(originCurrentRow, 'value.publicID')) {
            updateCurrentRow(originCurrentRow);
            originDBAs = _.map(DBAsVM.value || [], (DBA) => {
                if (DBA.publicID === originCurrentRow.value.publicID) {
                    return originCurrentRow.value
                }
                return DBA;
            })
        } else {
            originDBAs = _.filter(DBAsVM.value, (item) => {
                return item.publicID
            })
        }
        _.set(DBAsVM, 'value', originDBAs);
        updateDBAsVM(DBAsVM);
        onValueChange();
        updateCurrentRow(null);
    };

    const writeValue = useCallback((value, path) => {
        if(currentRow) {
            _.set(currentRow, path, value);
            updateCurrentRow(currentRow);
        }
        if (source === 'PNI') {
            onValueChange(DBAsVM.value, 'baseData.pnidbalist_Ext')
        } else {
            onValueChange();
        }
    }, [currentRow]);

    //---------------------
    const overrides = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        companyDetail: {
            visible: contactType === 'company'
        },
        personDetail: {
            visible: contactType === 'person'
        },
        addressDropdownButton: {
            readOnly: true
        },
        dropdownMenuButton: {
            content: renderContent(),
            visible: !isReadOnly
        },
        addressContainer: {
            model: currentRow,
            dataPath: 'contact.primaryAddress',
            onAddressChange: writeValue,
            showErrors,
            unAutoFilled: {
                addressLine1: true,
                postalCode: false
            },
            readOnly: isReadOnly,
            unRequiredField: {
                county: true
            },
            onValidate,
            hideFieldType: {
                addressType: true
            },
            defaultCountryRequired: false,
            isCLine: true
        },
        save: {
            visible: !isReadOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            cancel,
            save: isComponentValid ? saveDBA : handleValidation
        },
        resolveComponentMap: {
            addressInputComponent: AddressInputComponent
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

DBADetailComponent.propTypes = {
    onValueChange: PropTypes.func
};
DBADetailComponent.defaultProps = {
    onValueChange: _.noop
};

export default DBADetailComponent;
