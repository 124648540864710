import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { withRouter } from 'react-router-dom';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteInfoComponentContext } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import BopQuoteInfo from './components/BopQuoteInfo/BopQuoteInfo';
import wizardConfig from './config/bop-wizard-config.json5';
import wizardStepToFieldMapping from './config/bop-wizard-step-to-field-mapping.json5';
import './BOPLocationStyles.scss';
import messages from './BOPWizard.messages';

const PATH_TO_MOCK_DATA = 'quote.bop';
const MOCK_DATA_TO_SET = [
    'baseData.accountHolder.contactName',
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.accountHolder',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city',
    'baseData.productCode',
    'baseData.accountHolder.subtype',
    'lobData.businessOwners.accountOrgType',
    'lobData.businessOwners.smallBusinessType'
];
const MOCK_DATA_TO_REMOVE = [
    'baseData.accountHolder.contactName',
    'baseData.accountHolder.emailAddress1',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city'
];

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: commonMessages.infoWillBeSavedAsDraft,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function setCoverageStartDate() {
    const currentDate = new Date();
    const coverageStartDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return coverageStartDate;
}

function setPrepData(address) {
    const realData = {
        baseData: {
            policyAddress: address,
            periodStartDate: setCoverageStartDate()
        }
    };

    return MockUpUtil.setMockData(realData, PATH_TO_MOCK_DATA, ...MOCK_DATA_TO_SET);
}

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        ...MOCK_DATA_TO_REMOVE
    );
}

function createSubmissionOnPartialAddress(address) {
    const data = setPrepData(address);
    return LoadSaveService.createSubmission(data).then((response) => {
        return MockUpUtil.cleanUpMockedProperties(
            response,
            PATH_TO_MOCK_DATA,
            'baseData.accountHolder.firstName',
            'baseData.accountHolder.lastName',
            'baseData.accountHolder.contactName',
            'baseData.accountHolder.displayName',
            'baseData.accountHolder.emailAddress1',
            'baseData.policyAddress.addressLine1',
            'baseData.policyAddress.city',
            'baseData.policyAddress.displayName',
            'lobData.businessOwners.accountOrgType',
            'lobData.businessOwners.smallBusinessType'
        );
    });
}

function BOPWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;

    useEffect(() => {
        const viewModelContext = {
            AccountEmailRequired: true,
            AccountDOBRequired: true
        };

        if (!location.state) {
            return;
        }
        if (!viewModelService) {
            history.push('/');
            return;
        }

        let submissionPromise;
        if (_.has(location, 'state.address')) {
            const { address } = location.state;
            submissionPromise = createSubmissionOnPartialAddress(address);
        } else if (_.has(location, 'state.submission')) {
            const { submission } = location.state;
            setShouldSkipValidSteps(true);
            submissionPromise = Promise.resolve(submission);
        }

        submissionPromise.then((response) => {
            ClausesUtil.getDependentClausesFromServer(ClauseService, _.get(response, 'quoteID'));
            const submissionVM = viewModelService.create(
                removeMockData(response),
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                viewModelContext
            );

            setInitialSubmission(submissionVM);
            setIsLoading(false);
        });
        // only execute this once per component lifecycle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = useCallback(
        (wizardProps) => {
            const { wizardSnapshot, wizardData } = wizardProps;
            const latestSnapshot = wizardData.aspects.subtreeValid && wizardData.aspects.valid
                ? wizardData
                : wizardSnapshot;

            const periodStatus = _.get(latestSnapshot, 'baseData.periodStatus.value.code');
            if (periodStatus === 'Bound') {
                // allow transition once the submission is bound
                return true;
            }
            return modalApi.showConfirm(ACTION_MSG.cancelAction).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                let serverCall = LoadSaveService.updateDraftSubmission;

                if (periodStatus === 'Quoted') {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const wizardDataFromSnapshot = _.get(latestSnapshot, 'value');
                const dataToSave = MockUpUtil.setMockData(
                    wizardDataFromSnapshot,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_SET
                );
                setIsLoading(true);

                serverCall(dataToSave)
                    .then(() => {
                        history.push('/');
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            title: messages.anErrorOccurredTitle,
                            message: messages.anErrorOccurred,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                        history.push('/');
                    });
                return true;
            }, _.noop);
        },
        [history]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <QuoteInfoComponentContext.Provider value={BopQuoteInfo}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                skipCompletedSteps={shouldSkipValidSteps}
                wizardStepToFieldMapping={wizardStepToFieldMapping}
            />
        </QuoteInfoComponentContext.Provider>
    );
}

BOPWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({})
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(BOPWizard);
