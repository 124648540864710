import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { CPLocationsPage } from 'wni-capability-quoteandbind-cp-react';

function CPLocationsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('CP7Line', submissionVM);
    return (
        <CPLocationsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

CPLocationsChangePage.propTypes = CPLocationsPage.propTypes;
CPLocationsChangePage.defaultProps = CPLocationsPage.defaultProps;

export default CPLocationsChangePage;