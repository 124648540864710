import { defineMessages } from 'react-intl';

export default defineMessages({
    StandardCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Building Coverages',
        defaultMessage: 'Building Coverages',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Building Additional Coverages',
        defaultMessage: 'Building Additional Coverages',
    },
    AddAdditionalCoverage: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Add Additional Coverages',
        defaultMessage: 'Add Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Building Exclusions and Conditions',
        defaultMessage: 'Building Exclusions and Conditions',
    },
    AddExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Add Exclusions and Conditions',
        defaultMessage: 'Add Exclusions and Conditions',
    },
    AdditionalInsured: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Building Additional Insured',
        defaultMessage: 'Building Additional Insured',
    },
    AddAdditionalInsured: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Building.Add Additional Insured',
        defaultMessage: 'Add Additional Insured',
    },
    
});