import _ from 'lodash';
import { R1Config } from 'wni-portals-config-js';
import VehicleValidationUtil from './VehicleValidationUtil';
import DriverUtil from './DriverUtil';
import DriverValidation from './DriverValidationUtil';

const { DriversPageConfig, VehiclesPageConfig } = R1Config;

/**
 * Get the list of invalid cards
 * @param {Object} cardFieldsMap
 * @param {Object} viewModel
 * @returns {[String]} list of invalid card ids
 */
function getInvalidAccordionCardsOverrides(cardFieldsMap, viewModel) {
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        return _.some(fields, (field) => {
            const invalidRequiredField = _.get(viewModel, `${field}.aspects.required`) && (!_.get(viewModel, `${field}.aspects.valid`) || !_.get(viewModel, `${field}.aspects.subtreeValid`));
            return invalidRequiredField;
        });
    });
    const res = {};
    ids.forEach((accordion) => {
        res[accordion] = {
            errorState: _.includes(invalidAccordions, accordion)
        };
    });
    return res;
}

function getInvalidVehicleAccordionCardsOverrides(cardFieldsMap, viewModel) {
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        return _.some(fields, (field) => {
            const invalidField = !_.get(viewModel, `${field}.aspects.valid`) && !_.isEmpty(viewModel, `${field}.aspects.validationMessage`);
            const invalidRequiredField = _.get(viewModel, `${field}.aspects.required`) && (!_.get(viewModel, `${field}.aspects.valid`) || !_.get(viewModel, `${field}.aspects.subtreeValid`));
            return invalidField || invalidRequiredField;
        });
    });
    const res = {};
    ids.forEach((accordion) => {
        res[accordion] = {
            errorState: _.includes(invalidAccordions, accordion)
        };
    });
    return res;
}

function getAccordionOverridesForDrivers(driverDtoVM, validationIssues, baseData, wizardPageData) {
    const cardFieldsMap = DriversPageConfig.sectionFieldsMap;
    const retval = getInvalidAccordionCardsOverrides(cardFieldsMap, driverDtoVM);
    const isRequiredForIssuance = DriverUtil.isRequiredForIssuance(baseData, wizardPageData);
    const {
        licenseState,
        driversLicenseStatus_Ext: driversLicenseStatus,
        licenseNumber,
        licensedDriver_Ext: licensedDriver
    } = driverDtoVM.value || {};
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(driverDtoVM, 'value.fixedId');
    });
    const errorFields = _.map(errorIssues, (error) => {
        // return _.camelCase(_.get(error, 'relatedEntity.fieldName'));
        return _.upperFirst(_.get(error, 'relatedEntity.fieldName'));
    });
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        const invalidFields = _.filter(fields, (field) => {
            const idx = errorFields.indexOf(_.upperFirst(field));
            return idx >= 0;
        });
        return invalidFields.length > 0;
    });
    ids.forEach((id) => {
        if (invalidAccordions.indexOf(id) >= 0) {
            _.set(retval, `${id}.errorState`, true);
        }
    });

    if (!DriverValidation.getQuestionValid(driverDtoVM)) {
        _.set(retval, 'underwriting.errorState', true);
    } else {
        _.set(retval, 'underwriting.errorState', false);
    }

    if (isRequiredForIssuance) {
        if ((licensedDriver
            && (_.isEmpty(licenseState) || _.isEmpty(driversLicenseStatus) || _.isEmpty(licenseNumber)))
            || _.get(getInvalidAccordionCardsOverrides(cardFieldsMap, driverDtoVM), 'memberLicenseInfo.errorState')) {
            _.set(retval, 'memberLicenseInfo.errorState', true);
        } else {
            _.set(retval, 'memberLicenseInfo.errorState', false);
        }
        if (_.isNil(_.get(driverDtoVM, 'value.driverQuestionSets_Ext.[0].answers.DriverAnyCurFinResFilingYN_Ext'))
        || !DriverValidation.getQuestionValid(driverDtoVM)) {
            _.set(retval, 'underwriting.errorState', true);
        } else {
            _.set(retval, 'underwriting.errorState', false);
        }
    }
    return retval;
}

function isAmountEmpty(possibleAmountDTO) {
    return _.isNil(possibleAmountDTO) || (_.has(possibleAmountDTO, 'amount') && _.isNil(possibleAmountDTO.amount));
}

function getAccordionOverridesForVehicles(
    vehicleDtoVM, isRequiredForIssuance, coverages, isQuoteForNamedNonOwner, invalidFieldsArr
) {
    const retval = getInvalidVehicleAccordionCardsOverrides(
        VehiclesPageConfig.sectionFieldsMap, vehicleDtoVM
    );
    const {
        licenseState,
        owners_Ext: owners = [],
        otherOwners_Ext: otherOwners,
        otherOwnersAssignedFlag_Ext: isOtherOwnerSelected = false,
        additionalInterests = [],
        costNew,
        vehicleType_Ext: vehicleType,
        motorHomeRented_Ext: motorHomeRented,
        vehRecreationalTrailer_Ext: vehRecreationalTrailer,
        vehRTParked_Ext: vehRTParked,
        vehRTParkedExplain_Ext: vehRTParkedExplain,
        isStatedValueRequired_Ext: isStatedValueRequired,
        statedValue_Ext: statedValue,
        milePercentPerYear_Ext: milePercentPerYear
    } = vehicleDtoVM.value;
    if ((isStatedValueRequired && _.isNil(statedValue)) || (!_.isEmpty(invalidFieldsArr) && invalidFieldsArr.includes('vinLookup'))) {
        _.set(retval, 'vehicleDetailsAccordion.errorState', true);
    }
    // vehicle question vehRTPParked = Yes and vehRTParkedExplain = null
    if (vehRTParked && _.isEmpty(vehRTParkedExplain)) {
        _.set(retval, 'vehicleQuestionsAccordion.errorState', true);
    }
    if (isRequiredForIssuance) {
        // valid if fields that are Required For Issuance
        // const getCostNewRequired = () => {
        //     return VehicleValidationUtil.isCoverageSelected(coverages, 'New Vehicle Replacement Cost Collision Coverage')
        //         || VehicleValidationUtil.isCoverageSelected(coverages, 'New Vehicle Replacement Cost Other than Collision Coverage');
        // };
        const isNewVehicleReplacementCoverageSelected = VehicleValidationUtil.isNewVehicleReplacementCoverageSelected(coverages);
        const isCostNewRequired = VehicleValidationUtil.isCostNewRequired(
            {
                isQuoteForNamedNonOwner,
                isNewVehicleReplacementSelected: isNewVehicleReplacementCoverageSelected,
                isRequiredForIssuance
            }
        );
        const getVehicleQuestionsRequired = () => {
            let questionNotAnswered = false;
            if (vehicleType === 'motorhome_Ext') {
                questionNotAnswered = _.isNil(motorHomeRented);
            } else if (vehicleType === 'rectrailer_Ext') {
                // questionNotAnswered = _.isNil(vehRecreationalTrailer);
                questionNotAnswered = _.isNil(vehRecreationalTrailer) || _.isNil(vehRTParked);
            }
            if (motorHomeRented || vehRecreationalTrailer) {
                questionNotAnswered = questionNotAnswered || _.isNil(milePercentPerYear);
            }
            questionNotAnswered = questionNotAnswered
                || _.isNil(_.get(vehicleDtoVM, 'value.vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext'))
                || _.isNil(_.get(vehicleDtoVM, 'value.vehicleQuestionSets_Ext[0].answers.VehModHasSpecialEquipment_Ext'));
            const isGlassDamages = _.get(vehicleDtoVM, 'value.vehicleQuestionSets_Ext[0].answers.hasExistDamagesInclGlass_Ext');
            if (!_.isNil(isGlassDamages) && isGlassDamages !== 'false' && isGlassDamages !== false) {
                questionNotAnswered = questionNotAnswered || _.isNil(_.get(vehicleDtoVM, 'value.vehicleQuestionSets_Ext[0].answers.vehDamagesEplanation_Ext'));
            }
            // const isCustomerEquipmentsSelect = _.get(vehicleDtoVM, 'value.vehicleQuestionSets_Ext[0].answers.VehModHasSpecialEquipment_Ext');
            // if (!_.isNil(isCustomerEquipmentsSelect) && isCustomerEquipmentsSelect !== 'false' && isCustomerEquipmentsSelect !== false) {
            //     questionNotAnswered = questionNotAnswered || _.isEmpty(_.get(vehicleDtoVM, 'value.customEquipments_Ext'));
            // }

            return questionNotAnswered && vehicleType !== 'noVehicleNamedNonOwner_Ext';
        };
        if (vehicleType !== 'noVehicleNamedNonOwner_Ext' && (_.isNil(licenseState)
            || (!isOtherOwnerSelected && _.isEmpty(owners))
            || (isOtherOwnerSelected && _.isNil(otherOwners))
            || (isCostNewRequired && isAmountEmpty(costNew)))) {
            // || (getCostNewRequired() && isAmountEmpty(costNew)))) {
            _.set(retval, 'vehicleDetailsAccordion.errorState', true);
        }
        if (getVehicleQuestionsRequired()) {
            _.set(retval, 'vehicleQuestionsAccordion.errorState', true);
        }
    }
    if (!_.isEmpty(additionalInterests)
        && !(vehicleDtoVM.additionalInterests.aspects.valid
            && vehicleDtoVM.additionalInterests.aspects.subtreeValid)) {
        _.set(retval, 'vehicleAdditionalInterestAccordion.errorState', true);
    }
    return retval;
}

export default {
    getInvalidAccordionCardsOverrides,
    getAccordionOverridesForDrivers,
    getAccordionOverridesForVehicles,
};
