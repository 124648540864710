import React, { Component } from 'react';
import _ from 'lodash';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { validationPropTypes, withValidation } from '@xengage/gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { withModalContext } from '@jutro/components';
import metadata from './SubmitPaymentComponent.metadata.json5';
import customMessages from './SubmitPaymentComponent.messages';
import styles from './SubmitPaymentComponent.module.scss';

class SubmitPaymentComponentWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        ...validationPropTypes
    };

    static defaultProps = {};

    state = {
        showValidationErrors: false,
        paymentVM: {}
    };

    componentDidMount = () => {
        const { match, viewModelService } = this.props;
        const { params: { invoiceStreamPublicId } } = match;
        const paymentVM = viewModelService.create({ invoiceStreamPublicId }, 'bc', 'wni.edge.capabilities.billing.demo.WniDirectBillPaymentDTO');
        this.setState({
            paymentVM
        });
    };

    submitClicked = () => {
        const { paymentVM } = this.state;
        const { isComponentValid, match, authHeader } = this.props;
        const { params: { accountNumber } } = match;

        if (!isComponentValid) {
            this.setState({
                showValidationErrors: true
            });
        }

        GatewayBillingService.makeDirectBillPayment(
            accountNumber, paymentVM.value, authHeader
        ).then(() => {
            // eslint-disable-next-line no-alert
            alert('Payment Done, Integration to be done.');
        }).catch(() => {
            this.props.modalContext.showAlert({
                title: customMessages.paymentRequestFailed,
                message: customMessages.sorryYourPaymentCould,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: customMessages.ok
            });
        });
    };

    handleAmountChange = (value) => {
        const { paymentVM } = this.state;
        _.set(paymentVM, 'amountPaid.value', value);
        this.setState({ paymentVM });
    };

    render() {
        const translator = this.context;
        const { setComponentValidation, isComponentValid } = this.props;
        const { showValidationErrors, paymentVM } = this.state;
        const amountPaidNull = _.isNull(_.get(paymentVM, 'amountPaid.amount.value'));
        const overrides = {
            paymentSource: {
                value: 'Agency Sweep'
            },
            submitPayment: {
                onClick: this.submitClicked,
                content: translator(customMessages.submitPayment),
                disabled: !isComponentValid || amountPaidNull
            },
            paymentOptionsTitle: {
                content: translator({
                    id: 'quoteandbind.views.payment-details.Payment Method',
                    defaultMessage: 'Payment Method'
                })
            },
            cancel: {
                content: translator(customMessages.cancel)
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {}
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.componentContent, paymentVM, id, path, overrides);
        };

        const writeValue = (value, path) => {
            _.set(paymentVM, path, value);
            this.setState({ paymentVM });
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={paymentVM}
                showErrors={showValidationErrors}
                overrideProps={overrides}
                resolveValue={readValue}
                onValueChange={writeValue}
                onValidationChange={setComponentValidation}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

const SubmitPaymentComponent = withModalContext(SubmitPaymentComponentWithoutModalContext);

export default withValidation(withViewModelService(withAuthenticationContext(SubmitPaymentComponent)));
