import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.title',
        defaultMessage: 'Driver Assignment guidelines:',
    },
    staticLine1: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.staticLine1',
        defaultMessage: 'All drivers must be assigned to a vehicle.'
    },
    staticLine2: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.staticLine2',
        defaultMessage: 'Vehicles driven to work or used in business must have a primary operator.'
    },
    staticLine3: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.staticLine3',
        defaultMessage: 'Each driver can only be assigned once regardless of the number of vehicles.'
    },
    staticLine4: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.staticLine4',
        defaultMessage: 'Additional vehicles will be rated as excess vehicles.'
    },
    youthful: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.youthful',
        defaultMessage: 'Youthful operators cannot be assigned to the same vehicle unless all vehicles have a youthful operator.'
    },
    furnished: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.guideline.furnished',
        defaultMessage: 'Drivers with vehicles furnished and available for their regular use will not be assigned to a vehicle.'
    },
});
