
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { useProductsData } from 'wni-portals-util-react';
import AccountClearanceDetailsPopup from './modal/AccountClearanceDetailsPopup';
import messages from './AccountClearanceLinkComponent.messages';
import { Link } from '@jutro/router';

const AccountClearanceLinkComponent = (props) => {
    const modalApi = useWniModal();
    const productsData = useProductsData();

    const {
        onAccountClearance,
        postAccountMerge,
        currentAccountNumber,
    } = props;

    const translator = useTranslator();

    const showAccountClearanceDialog = async () => {
        const popupTitle = translator(messages.accountClearanceDialogTitle);
        return modalApi.showModal(
            <AccountClearanceDetailsPopup
                title={popupTitle}
                okBtnLabel={translator(messages.continueReferUnderwriting)}
                getAccountClearanceData={onAccountClearance}
                postAccountMerge={postAccountMerge}
                currentAccountNumber={currentAccountNumber}
                //
                productsData={productsData}
            />
        );
    };

    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link onClick={showAccountClearanceDialog} className="font-link-16">
            {messages.RunReservation}
        </Link>
    );
};

AccountClearanceLinkComponent.propTypes = {
    onAccountClearance: PropTypes.func.isRequired,
    postAccountMerge: PropTypes.func.isRequired,
    currentAccountNumber: PropTypes.string.isRequired
};

AccountClearanceLinkComponent.defaultProps = {
};

export default AccountClearanceLinkComponent;
