import { defineMessages } from 'react-intl';

export default defineMessages({
    city: {
        id: 'fnol.common.component.addressInputField.City',
        defaultMessage: 'City'
    },
    cityOrWater: {
        id: 'fnol.common.component.addressInputField.City or Body of Water',
        defaultMessage: 'City or Body of Water'
    },
    unknowTips: {
        id: 'fnol.common.component.addressInputField.unknowTips',
        defaultMessage: 'If you don\'t know specific {what}, please enter \'Unknown\''
    },

});
