import { defineMessages } from 'react-intl';

export default defineMessages({
    changeBoundMessage: {
        id: 'endorsement.common.views.change-bound.Your change was bound successfully.',
        defaultMessage: 'Your change was bound successfully.',
    },
    contactAgentMessage: {
        id: 'endorsement.common.views.change-bound.Changes automatically applied.',
        defaultMessage: 'Changes have also been automatically applied to your pending renewed policy. Please contact an Agent with any questions.'
    },
    contactUnderwriterMessage: {
        id: 'endorsement.common.views.change-bound.Changes automatically applied contact underwriter',
        defaultMessage: 'Changes have also been automatically applied to your pending renewed policy. Please contact an Underwriter with any questions.'
    },
    backToPolicy: {
        id: 'endorsement.common.views.contact-agent.Back to Policy',
        defaultMessage: 'Back to Policy'
    },
    backToHome: {
        id: 'endorsement.common.views.contact-agent.Back to Homepage',
        defaultMessage: 'Back to Homepage'
    },
    changeBound: {
        id: 'endorsement.common.views.change-bound.Change is Bound',
        defaultMessage: 'Change is Bound'
    }
});
