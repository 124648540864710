import { defineMessages } from 'react-intl';

export default defineMessages({
    buildingDetails: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Building Details',
        defaultMessage: 'Building Details'
    },
    buildingLocation: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Location',
        defaultMessage: 'Location'
    },
    buildingPropertyClassCode: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Property Class Code',
        defaultMessage: 'Property Class Code'
    },
    buildingPremiumBasicAmount: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Premium Basis Amount',
        defaultMessage: 'Premium Basis Amount'
    },
    buildingDescription: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Building Description',
        defaultMessage: 'Building Description'
    },
    buildingYearBuilt: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Year Built',
        defaultMessage: 'Year Built'
    },
    buildingConstructionType: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Construction Type',
        defaultMessage: 'Construction Type'
    },
    buildingNumberOfStories: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Number of Stories',
        defaultMessage: 'Number of Stories'
    },
    buildingTotalAreaExcludingBasement: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Total Area Excluding Basement',
        defaultMessage: 'Total Area Excluding Basement'
    },
    buildingPercentageSprinklered: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Percentage Sprinklered',
        defaultMessage: 'Percentage Sprinklered'
    },
    buildingExposure: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Exposure',
        defaultMessage: 'Exposure'
    },
    buildingAlarm: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Alarm',
        defaultMessage: 'Alarm'
    },
    buildingAlarmYes: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Alarm.Yes',
        defaultMessage: 'Yes'
    },
    buildingAlarmNo: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Alarm.No',
        defaultMessage: 'No'
    },
    buildingAlarmType: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Alarm Type',
        defaultMessage: 'Alarm Type'
    },
    generalCoverages: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.General Coverages',
        defaultMessage: 'General Coverages'
    },
    additionalCoverages: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    infoBox: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Please provide building details to see the coverages.',
        defaultMessage: 'Please provide building details to see the coverages.'
    },
    buildingNotSaved: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.buildingCtrl.Building not saved',
        defaultMessage: 'Building not saved'
    },
    pleaseCheckForErrorsAndTryAgain: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.buildingCtrl.Please check for errors and try again',
        defaultMessage: 'Please check for errors and try again'
    },
    backButton: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Back',
        defaultMessage: 'Back'
    }
});
