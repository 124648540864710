import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { InlineLoader } from '@jutro/components';
import { ErrorsAndWarningsUtil, QuoteUtil } from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
// import NoteUWInputComponent from '../components/NoteUWInputComponent/NoteUWInputComponent';

import { QuoteSideBySidePeriodMessages as messages } from 'wni-platform-translations';

// =================To be refactored BEGIN============================
// import messages from 'wni-capability-gateway-react/QuotePage/SideBySidePeriodsComponent.messages';
// import styles from 'wni-capability-gateway-react/QuotePage/SideBySidePeriodsComponent.module.scss';
// -----------------To be refactored END------------------------------

import SideBySidePeriodInfo from './HoSideBySidePeriodInfo';
import metadata from './HoSideBySidePeriodsComponent.metadata.json5';
import styles from './HoSideBySidePeriodsComponent.module.scss';

function generateQuoteTableHeaderDesktopOverrides({ columnData, isLoading, showVersionNumber }) {
    // const scheduleStaleCode = getStaleCode();
    // if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
    //     updateStaleQuoteBranchCodes(scheduleStaleCode);
    // }
    const overrides = columnData.map(({ code, sxsPeriod }, index) => {
        // const columnPath = `columnData[${index}]`;
        // if (sxsPeriod.data) {
        //     const { data } = sxsPeriod;
        // }

        const {
            path,
            data: model,
            ...extraColumnData
        } = sxsPeriod;

        return {
            [`tableHeaderSection${index}`]: {
                model,
                isServiceCallInProgress: isLoading,
                showVersionNumber,
                ...extraColumnData,
            },
        };
    });

    return Object.assign({}, ...overrides);
}

function getSideBySidePairActionOverrides(sxsPairs = [],
    {
        onRemoveSideBySidePair, isReadyForRemoval = false, isLoading = false,
        translator,
    }) {
    const getRemoveSxsPairFn = (sxsPeriodPublicID) => {
        return () => onRemoveSideBySidePair(sxsPeriodPublicID);
    };

    const overrides = sxsPairs.map((sxsPair, index) => {
        const sxsPeriodPublicID = sxsPair.publicID;
        return {
            [`removeSxsPairBtn${index}`]: {
                visible: isReadyForRemoval,
                onClick: getRemoveSxsPairFn(sxsPeriodPublicID),
                // renderContent: renderHelper.getButtonRenderFunc(isLoading),
                content: renderHelper.getButtonContent(translator(messages.deleteSideBySidePairBtn), isLoading),
            }
        };
    });
    return Object.assign({}, ...overrides);
}

function defaultSxsPairRemovalCheckFn(sxsPeriods, sideBySidePairs) {
    // const sideBySidePairs = QuoteUtil.getSideBySidePairs(sxsPeriods);
    const retval = QuoteUtil.checkReadyForSideBySidePairRemoval(sideBySidePairs);
    return retval;
}

function HoSideBySidePeriodsComponent(props) {
    const {
        quoteID,
        columnData,
        // quoteClauseTableHeaderOverrides,
        onRemoveSideBySidePair,
        isServiceCallInProgress: isLoading,
        isReadOnly,
        showActionButtons,
        sxsPeriodInfo: SxsPeriodInfoComponent,
        readyForSxsRemovalCheckFn,
    } = props;

    const translator = useTranslator();

    const sxsPeriods = columnData.map(({ sxsPeriod }) => sxsPeriod.data);
    const sideBySidePairs = QuoteUtil.getSideBySidePairs(sxsPeriods);
    // const isReadyForRemoval = !isReadOnly && QuoteUtil.checkReadyForSideBySidePairRemoval(sideBySidePairs);
    const hasMoreThanOneSxsPair = readyForSxsRemovalCheckFn(sxsPeriods, sideBySidePairs);

    const quoteClauseTableHeaderOverrides = generateQuoteTableHeaderDesktopOverrides({
        columnData, isLoading, showVersionNumber: hasMoreThanOneSxsPair,
    });
    const sideBySidePairActionOverrides = getSideBySidePairActionOverrides(sideBySidePairs,
        {
            onRemoveSideBySidePair,
            isReadyForRemoval: !isReadOnly && hasMoreThanOneSxsPair,
            isLoading,
            translator
        });

    const dataForComponent = {
        columnData,
        sideBySidePairs,
    };
    const overrideProps = {
        ...quoteClauseTableHeaderOverrides,
        ...sideBySidePairActionOverrides,
        sideBySidePairActionsContainer: {
            visible: showActionButtons,
        },
        // noteForUWInputInfo: {
        //     // canUWIssueStatus: canUWIssue,
        //     quoteID,
        //     // WniQuoteNoteService,
        // },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            sidebysideperiodinfo: SxsPeriodInfoComponent,
            // noteuwinputinfo: NoteUWInputComponent,
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dataForComponent}
            overrideProps={overrideProps}
            // resolveValue={readValue}
            // onValueChange={writeValue}
            // onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

HoSideBySidePeriodsComponent.propTypes = {
    quoteID: PropTypes.string.isRequired,
    columnData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.number,
        // quote: PropTypes.shape({
        //     path: PropTypes.string,
        //     premium: PropTypes.shape({
        //         monthlyPremium: moneyPropsTypes,
        //         total: moneyPropsTypes,
        //     })
        // }),
        quote: PropTypes.shape({
            path: PropTypes.string,
            data: PropTypes.shape({
                publicID: PropTypes.string,
                pairPeriodPublicId_Ext: PropTypes.string
            })
        })
    })).isRequired,
    onRemoveSideBySidePair: PropTypes.func,
    isServiceCallInProgress: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    showActionButtons: PropTypes.bool,
    sxsPeriodInfo: PropTypes.func,
    readyForSxsRemovalCheckFn: PropTypes.func,
};

HoSideBySidePeriodsComponent.defaultProps = {
    onRemoveSideBySidePair: _.noop,
    isServiceCallInProgress: false,
    isReadOnly: false,
    showActionButtons: true,
    sxsPeriodInfo: SideBySidePeriodInfo,
    readyForSxsRemovalCheckFn: defaultSxsPairRemovalCheckFn,
};

export default HoSideBySidePeriodsComponent;
