import React, {
    useState,
    useEffect,
    useContext
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { RTTooltipUtil as tooltips } from 'wni-portals-tooltip';
import { WniCurrency, WniDate, WniDropdownSelect, WniInputNumber, WniTextArea, WniToggle } from 'wni-common-base-components';
import RTCoverageUtil from '../../util/RTCoverageUtil';
import messages from './RTSingleClauseComponent.messages';
import clausesMessages from '../RTClausesComponentVM/RTClausesComponentVM.messages';
import SingleClauseContext from '../../context/RTSingleClauseContext';
import CoveragesConfigContext from '../../context/RTCoveragesConfigContext';

function getPath(changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    return pathToNormalise;
}

const originEditingValueFromTerm = (term) => {
    const valueType = _.get(term, 'valueType')
    if (valueType === 'Money') {
        return term.directValue
    }
    if (valueType === 'shorttext') {
        return term.directStringValue
    }
    if (valueType === 'datetime') {
        return term.directDateValue
    }
    return term.directValue
}

function RTTermComponent(props) {

    const {
        idPrefix,
        clauseCode: coverageCode,
        clausePublicID,
        clausePath,
        isEditable,
        isDisabled,
        onChangeClause,
        // onSyncCoverages,
        onChangeSubmissionAndSync,
        labelPosition,
        labelTop,
        onValidate,
        setIsEditing,
        showErrors,
    } = useContext(SingleClauseContext);

    const coveragesConfig = useContext(CoveragesConfigContext);

    const {
        term = {},
        termIndex,
        hideLabel = false,
        className,
    } = props

    const {
        termConfig,
    } = coveragesConfig;

    const {
        name,
        isEditable: isTermEditable = true,
        options = [],
        code_Ext: code,
    } = term

    const translator = useTranslator();

    const originEditingValue = originEditingValueFromTerm(term);
    const [editingValue, setEditingValue] = useState(originEditingValue)

    useEffect(() => {
        setEditingValue(originEditingValue)
    }, [originEditingValue])

    useEffect(() => {
        if (onValidate) {
            onValidate(!RTCoverageUtil.isTermInvalid(term), term.code_Ext);
        }
        return () => {
            if (onValidate) {
                onValidate(true, term.code_Ext);
            }
        }
    }, [onValidate, term])

    const handleChangeClause = (value) => {
        if (editingValue !== value) {
            setIsEditing(true)
            setEditingValue(value)
        }
        
    };

    const handleSyncCoverages = (evt, changedValues) => {
        setIsEditing(false)
        const { beforeValue, value: newValue, model } = changedValues;
        const actualChangedPath = getPath(model);

        const isValueChange = (beforeValue !== newValue)

        if (isValueChange) {
            // Use changeWithSync here instead of sync, because editing value is not saved to submission
            // Use changeWithSync to save value to VM and sync to PC
            const fieldNotChangeWithSync = _.get(termConfig.termsNotChangeWithSync, coverageCode, [])
            if (fieldNotChangeWithSync.includes(code)) {
                if (onChangeClause) {
                    return Promise.resolve(onChangeClause(newValue, actualChangedPath));
                }
            }
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(newValue, actualChangedPath));
            }
        }
        return Promise.resolve();
    };

    const handleChangeWithSync = (value, changedValuePath) => {
        if (_.get(term, 'chosenTerm') !== value) {
            const fieldNotChangeWithSync = _.get(termConfig.termsNotChangeWithSync, coverageCode, [])
            if (fieldNotChangeWithSync.includes(code)) {
                if (onChangeClause) {
                    return Promise.resolve(onChangeClause(value, changedValuePath));
                }
            }
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    const termPathPrefix = `${clausePath}.terms.children[${termIndex}]`;

    const commmonTermProps = {
        id: `${idPrefix}ClauseTerm_[${clausePublicID}]_[${termIndex}]`,
        label: name,

        path: `${termPathPrefix}.chosenTerm`,
        readOnly: !isTermEditable || !isEditable || term.readOnly_Ext,
        disabled: isDisabled,
        value: term.chosenTerm,
        labelPosition: labelPosition,
        layout: labelTop ? 'full-width' : null,
        onValueChange: handleChangeWithSync,
        required: isEditable && term.required,
        className: `clause_term ${className}`,
        tooltip: {
            text: tooltips[term.code_Ext]
        },
        showErrors,
        hideLabel,
    }

    const hasOption = !_.isEmpty(options)

    const availableValues = hasOption ? options.filter((option) => {
        // Filtering out the None Selected value as the backend sets the code value
        // for None Selected to be an empty string. This results in the option not
        // showing in the dropdown select component. We are handling this by using
        // None Selected as a placeholder which will always be shown to the user
        return option.code;
    }).map((option) => {
        return {
            code: '',
            id: '',
            ...option
        };
    }) : [];

    const hasAvailableValues = _.has(term, 'options') && !_.isEmpty(availableValues);
    const valueType = _.has(term, 'valueType') && term.valueType;

    const onlyOneAvailableValue = hasAvailableValues && availableValues.length === 1;

    const readOnlyCausedByOnlyOneAvailableValue = onlyOneAvailableValue &&
        _.get(term, 'chosenTerm') === _.get(availableValues, '[0].code')

        

    if (!hasAvailableValues && valueType === 'Money') {
        return <WniCurrency
            {...commmonTermProps}
            dataType= 'number'
            value= {editingValue}
            onValueChange= {handleChangeClause}
            onBlur= {handleSyncCoverages}
            maxValue={term.directValueMax}
            minValue= {term.directValueMin}
            path= {`${termPathPrefix}.directValue`}
            required= {term.required}
            showFractions= {false}
        />
    }

    if (hasAvailableValues && valueType === 'bit') {
        // chosenTerm for bit terms with more than 2 choices?
        return <WniToggle
            {...commmonTermProps}
            value= {term.chosenTerm}
            availableValues= {term.options.filter((option) => option.code)}
            required= {term.required}
        />
    }

    if (!hasAvailableValues && valueType === 'datetime') {
        return <WniDate
            {...commmonTermProps}
            dataType= 'string'
            onValueChange={handleChangeClause}
            onBlur= {handleSyncCoverages}
            value= {editingValue}
            path= {`${termPathPrefix}.directDateValue`}
            required= {term.required}
            showTime= {false}
        />
    }

    if (valueType === 'shorttext') {
        return <WniTextArea
            {...commmonTermProps}
            onValueChange= {handleChangeClause}
            onBlur= {handleSyncCoverages}
            path= {`${termPathPrefix}.directStringValue`}
            value= {editingValue}
            required= {term.required}
            // placeholder: term.required ? '-- Required for Quote --' : '',
            placeholder= ''
        />
    }

    if (!valueType) {
        return <WniDropdownSelect
            {...commmonTermProps}
            availableValues={availableValues}
            path= {`${termPathPrefix}.chosenTerm`}
            value= {term.chosenTerm}
            readOnly= {!isEditable || readOnlyCausedByOnlyOneAvailableValue || term.readOnly_Ext}
            required= {term.required}
            // placeholder: term.required ? '-- Required for Quote --' : '',
            placeholder= {
                _.some(term.options, ['name', translator(messages.noneSelected)])
                    ? translator(messages.noneSelected) : ''}
        />

    }

    const getMessageByMinMax = () => {
        let key = 'maxValue';
        if (term.directValueMax && term.directValueMin) {
            key = 'selectedValue';
        } else if (term.directValueMin) {
            key = 'minValue';
        }
        return clausesMessages[key];
    };

    return <WniInputNumber
        {...commmonTermProps}
        onValueChange= {handleChangeClause}
        onBlur= {handleSyncCoverages}
        value= {editingValue}
        maxValue= {term.directValueMax}
        minValue= {term.directValueMin}
        path= {`${termPathPrefix}.directValue`}
        required= {term.required}
        // placeholder: term.required ? '-- Required for Quote --' : '',
        placeholder= ''
        messageProps= {{
            validationMaxValue: translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            }),
            validationMinValue: translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            }),
        }}
    />
}
export default RTTermComponent;