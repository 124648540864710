import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { CACoveredVehicleSymbolsService } from 'wni-capability-quoteandbind-ca';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniCheckboxField } from 'wni-common-base-components';

import WizardPage from '../../templates/CAWizardPage';

import metadata from './CACoveredVehicleSymbolsPage.metadata.json5';

function CACoveredVehicleSymbolsReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        caCoveredVehicleSymbolsService
    } = props;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const coveredVehicleSymbols = _.get(submissionVM, 'value.lobData.commercialAuto.coverables.coveredVehicleSymbols') || {};

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        invalidFields,
        isComponentValid,
    } = useValidation('CACoveredVehicleSymbolsReadOnlyPage');

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [coverageSymbolGroup, updateCoverageSymbolGroup] = useState(coveredVehicleSymbols.coverageSymbolGroup)

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const onPageNext = useCallback(async () => {
        return submissionVM;
    }, [submissionVM]);

    const renderTableContent = () => {
        return (
            <DataTable
                data={coverageSymbolGroup}
                id="coveredVehicleSymbols"
                expandable={false}
                showSearch={false}
                showPagination={false}
                defaultConfig={{
                    sorted: [
                        {
                            desc: false,
                            id: 'symbolHeader-priority'
                        }
                    ]
                }}
            >
                <DisplayColumn
                    path='priority'
                    header='Priority'
                    id='symbolHeader-priority'
                    textAlign="left"
                    onSort={DatatableUtil.sortNumber}
                    visible={false}
                />
                <DisplayColumn
                    path='displayName'
                    header='Coverage'
                    id='symbolHeader-coverage'
                    textAlign="left"
                    onSort={DatatableUtil.sortString}
                />
                {
                    _.map(coveredVehicleSymbols.coverageSymbolsColumn, (colum) => {
                        return <DisplayColumn
                            renderCell={(row) =>
                                <WniCheckboxField
                                    readOnly
                                    visible={_.find(row.coverageSymbols, (item) => item.code === colum.code).isAvailable}
                                    value={_.find(row.coverageSymbols, (item) => item.code === colum.code).isSelected} />}
                            header={colum.displayName}
                            id={`symbolHeader-${colum.code}`}
                            textAlign="left"
                            onSort={DatatableUtil.sortString}
                        />
                    })
                }
            </DataTable>
        )
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            symbolsTableContainer: {
                content: renderTableContent()
            },
            vehicleGroupReferenceTable: {
                data: coveredVehicleSymbols.vehicleGroupReference
            }
        };
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}
CACoveredVehicleSymbolsReadOnlyPage.propTypes = {
    ...wizardProps,
    caCoveredVehicleSymbolsService: PropTypes.object
};

CACoveredVehicleSymbolsReadOnlyPage.defaultProps = {
    caCoveredVehicleSymbolsService: CACoveredVehicleSymbolsService
};

export default CACoveredVehicleSymbolsReadOnlyPage;
