import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { DateUtil, LocalDateUtil } from '@xengage/gw-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ActivitiesService } from 'gw-capability-gateway';
import {
    useDataRefresh,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import metadata from './AddActivityPopUp.metadata.json5';
import styles from './OpenActivitiesComponent.module.scss';

import { Button } from '@jutro/legacy/components';

const AddActivityPopUp = (props) => {
    const {
        authHeader,
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        activityEntity,
        activityEntityId,
        onResolve,
        onReject,
        viewModelService,
        size = 'md'
    } = props;
    const [activityVM, updateActivityVM] = useState({});
    const [isAddActivityLoading, setIsAddActivityLoading] = useState(false);
    const [typeAvailableValues, updateTypeAvailableValues] = useState([]);
    const [priorityAvailableValues, updatePriorityAvailableValues] = useState(
        []
    );
    const [userAvailableValues, updateUserAvailableValues] = useState([]);
    const [selectedUser, updateSelectedUser] = useState([]);
    const [assignableUserData, updateAssignableUserData] = useState([]);
    const [activityPatternData, updateActivityPatternData] = useState([]);
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        'AddActivityPopUp'
    );
    const { refreshData } = useDataRefresh();

    const createVM = useCallback(
        (model) => {
            return viewModelService.create(
                model,
                'pc',
                'edge.capabilities.gateway.activity.dto.ActivityDTO'
            );
        },
        [viewModelService]
    );

    const getAssignableUserForActivity = useCallback(
        (activityPatterns) => {
            ActivitiesService.getAssignableUserForActivity(
                activityEntity,
                activityEntityId,
                activityPatterns,
                authHeader
            ).then((activity) => {
                updateAssignableUserData(activity.assignableUsers);
            });
        },
        [activityEntity, activityEntityId, authHeader]
    );

    const getActivityPatternsFor = useCallback(
        () => {
            ActivitiesService.getActivityPatternsFor(activityEntity, authHeader).then(
                (activityPatterns) => {
                    getAssignableUserForActivity(activityPatterns[0]);
                    updateActivityPatternData([...activityPatterns]);
                    setIsAddActivityLoading(false);
                }
            );
        },
        [activityEntity, authHeader, getAssignableUserForActivity]
    );

    const handleChange = useCallback(
        (value, path) => {
            const newActivitySubmissionVM = viewModelService.clone(activityVM);
            let newActivityResult = [];
            _.set(newActivitySubmissionVM.value, path, value !== '' ? value : undefined);
            switch (path) {
                case 'jobType':
                    newActivityResult = activityPatternData.find((activity) => {
                        return activity.subject === value;
                    });
                    _.set(newActivityResult, 'jobType', value);
                    delete newActivityResult.publicID;
                    delete newActivityResult.category;
                    _.set(newActivitySubmissionVM, 'value', newActivityResult);
                    _.set(newActivitySubmissionVM, 'assignedTo', assignableUserData[0]);
                    break;
                case 'assignedTo':
                    updateSelectedUser(value);
                    break;
                default:
                    break;
            }
            updateActivityVM(newActivitySubmissionVM);
            refreshData();
        },
        [activityPatternData, activityVM, assignableUserData, viewModelService]
    );

    const handleSave = useCallback(() => {
        onResolve(activityVM);
    }, [activityVM, onResolve]);

    const isPageValid = useCallback(() => {
        const dueDate = _.get(activityVM.value, 'dueDate');
        const escalationDate = _.get(activityVM.value, 'escalationDate');
        const today = new Date();
        const isFutureDueDate = DateUtil.compareIgnoreTime(dueDate, today);
        const isFutureEscalationDate = DateUtil.compareIgnoreTime(escalationDate, today);
        return isFutureDueDate !== -1 && isFutureEscalationDate !== -1;
    }, [activityVM]);

    useEffect(() => {
        updateActivityVM(createVM({}));
        setIsAddActivityLoading(true);
        getActivityPatternsFor();
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    useEffect(() => {
        const subjectDataArray = activityPatternData.map((key) => {
            return {
                name: key.subject,
                code: key.subject
            };
        });

        const uniqueActivityPriority = _.uniqBy(activityPatternData, 'priority');
        const priorityDataArray = uniqueActivityPriority.map((key) => {
            return {
                name: _.startCase(_.toLower(key.priority)),
                code: key.priority
            };
        });

        const userDataObject = assignableUserData.map((key) => {
            return {
                name: key,
                code: key
            };
        });
        const defaultSelectedUser = userDataObject[0];

        updateTypeAvailableValues(subjectDataArray);
        updatePriorityAvailableValues(priorityDataArray);
        updateUserAvailableValues(userDataObject);
        updateSelectedUser(defaultSelectedUser);
    }, [activityPatternData, assignableUserData]);

    const tagType = !_.get(activityVM.value, 'jobType');

    const overrideProps = {
        addAnActivityGrid: {
            visible: !isAddActivityLoading
        },
        type: {
            availableValues: typeAvailableValues,
            value: _.get(activityVM.value, 'jobType')
        },
        priority: {
            availableValues: priorityAvailableValues
        },
        assignedTo: {
            availableValues: userAvailableValues,
            disabled: tagType,
            value: selectedUser,
            showOptional: true,
        },
        dueDate: {
            disabled: tagType,
            minDate: LocalDateUtil.today()
        },
        subject: {
            disabled: tagType
        },
        escalationDate: {
            disabled: tagType,
            minDate: LocalDateUtil.today()
        },
        description: {
            disabled: tagType,
            showOptional: true,
        },
        loader: {
            loaded: !isAddActivityLoading
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader
                title={title}
                onClose={onReject}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    model={activityVM}
                    classNameMap={styles}
                    onValidationChange={onValidate}
                    onValueChange={handleChange}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave} disabled={!isComponentValid || isAddActivityLoading}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

AddActivityPopUp.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.shape({}).isRequired,
    cancelBtnLabel: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    activityEntityId: PropTypes.number.isRequired,
    activityEntity: PropTypes.string.isRequired,
};

export default AddActivityPopUp;
