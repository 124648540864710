import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/rtcoverages'), method, data, additionalHeaders);
}

export default class RTCoveragesService {

    /**
     * Updates coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateCoverages(jobNumber, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateCoverages',
            [jobNumber, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Copy coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {String} copyFromRoadTrailPublicID the road & trail coverage copy from
     * @param {String} copyToRoadTrailPublicID the road & trail coverage copy to
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static copyRoadTrailCoverages(jobNumber, sessionUUID, lobCoverages, copyFromRoadTrailPublicID, copyToRoadTrailPublicID, additionalHeaders = {}) {
        return processSubmission(
            'copyRoadTrailCoverages',
            [jobNumber, sessionUUID, lobCoverages, copyFromRoadTrailPublicID, copyToRoadTrailPublicID],
            additionalHeaders
        );
    }
}