
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALPolicyDetailsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function WALPolicyDetailsChangeReadOnlyPage(props) {

    return (
        <WALPolicyDetailsReadOnlyPage {...props} />
    );

}

WALPolicyDetailsChangeReadOnlyPage.propTypes = WALPolicyDetailsReadOnlyPage.propTypes;
WALPolicyDetailsChangeReadOnlyPage.defaultProps = WALPolicyDetailsReadOnlyPage.defaultProps;

export default WALPolicyDetailsChangeReadOnlyPage;