import { defineMessages } from 'react-intl';

export default defineMessages({
    premium: {
        id: 'gateway.directives.PolicySummary.Premium',
        defaultMessage: 'Premium'
    },
    openQuoteLabel: {
        id: 'gateway.directives.job-summary.Quote',
        defaultMessage: 'Quote'
    },
    openQuotesSearchQuotesLabel: {
        id: 'gateway.directives.job-summary.Search quotes',
        defaultMessage: 'Search quotes'
    },
    openQuotesLastDaysLabel: {
        id: 'gateway.views.quote-detail.last30Days',
        defaultMessage: 'Issued in Last 30 Days'
    },
    openQuotesOpenNotBoundLabel: {
        id: 'gateway.views.quote-detail.openNotBound',
        defaultMessage: 'Open - Not Bound'
    },
    openQuotesOpenBoundLabel: {
        id: 'gateway.views.quote-detail.openBound',
        defaultMessage: 'Open - Bound'
    },
    openQuotesCreatedLabel: {
        id: 'gateway.directives.job-summary.Created',
        defaultMessage: 'Created'
    },
    openQuotesProductLabel: {
        id: 'gateway.directives.job-summary.Line',
        defaultMessage: 'Product'
    },
    openQuotesPremiumLabel: {
        id: 'gateway.directives.job-summary.Cost',
        defaultMessage: 'Premium'
    },
    openQuoteStatusLabel: {
        id: 'gateway.directives.job-summary.Status',
        defaultMessage: 'Status'
    },
    noQuotesLabel: {
        id: 'gateway.directives.job-summary.There are no quotes meeting these filters',
        defaultMessage: 'There are no quotes meeting these filters'
    },
    noQuoteForAccount: {
        id: 'gateway.directives.job-summary.There are no open quotes for this account.',
        defaultMessage: 'There are no open quotes for this account.'
    },
});
