import _ from 'lodash';

// transform typelist to dropdownlist
function getDropDownValues(typelist, translator){
    return typelist.map((item) => {
        const availableValuesList = {
            code: item.code,
            name: translator({ id: item.name })
        };
        return availableValuesList;
    });
};
// common: some fields visibility controller
function getVisibilityOfConditionalFields(title, key, newModalVM, labelMap) {
    if (title === 'Edit MVR Reports' || title === 'Edit Prior Loss Reports' || title === 'Edit Prior Carrier Reports' ||
    title === 'View MVR Reports' || title === 'View Prior Loss Reports' || title === 'View Prior Carrier Reports'
    ) {
    /**
     * when load the data from backend and check the dispute is 'Other'
     * then the explanationBox should be visible
     */
        if (key === 'explanationBox' && !_.isNil(newModalVM.dispute.value) && newModalVM.dispute.value.code === labelMap.dispute.linkage.linkCode) {
            labelMap[key].unVisible = false;
        }
        /**
     * when load the data from backend and check the dispute is not 'Other'
     * then the explanationBox should not be visible
     */
        if (key === 'explanationBox' && !_.isNil(newModalVM.dispute.value) && newModalVM.dispute.value.code !== labelMap.dispute.linkage.linkCode) {
            labelMap[key].unVisible = true;
        }
        /**
     * when load the data from backend and check the dispute is undefined
     * then the explanationBox should not be visible
     */
        if (key === 'explanationBox' && _.isNil(newModalVM.dispute.value)) {
            labelMap[key].unVisible = true;
        }
        // when venor MVR is EARS data, then hide dispute field
        if (key === 'dispute' && newModalVM.earsFlag) {
            if (newModalVM.earsFlag.value) {
                labelMap[key].unVisible = true;
            } else {
                labelMap[key].unVisible = false;
            }
        }
    }

    /**
 * when Carrier is empty then hidden the Other Carrier Name field
 * when Carrier is 'Other' then display the Other Carrier Name field
 * when Carrier is 'No Prior' then display the reason field
 */
    if (title === 'Add Prior Carrier Entry' || title === 'Edit Prior Carrier Entry' || title === 'View Prior Carrier Entry') {
        if (_.isUndefined(newModalVM.carrier_Ext.value)) {
            if (key === 'otherCarrierName_Ext' || key === 'reasonForNoInsurance_Ext') {
                labelMap[key].unVisible = true;
            } else {
                labelMap[key].unVisible = false;
            }
        }
        if (key === 'otherCarrierName_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code !== 'Other') {
            labelMap[key].unVisible = true;
        } else if (key === 'otherCarrierName_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code === 'Other') {
            labelMap[key].unVisible = false;
        } else if (key === 'reasonForNoInsurance_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code !== 'NoPrior') {
            labelMap[key].unVisible = true;
        } else if (key === 'reasonForNoInsurance_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code === 'NoPrior') {
            labelMap[key].unVisible = false;
        } else if (key !== 'otherCarrierName_Ext' && key !== 'reasonForNoInsurance_Ext' && key !== 'carrier_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code !== 'NoPrior') {
            labelMap[key].unVisible = false;
        } else if (key !== 'reasonForNoInsurance_Ext' && key !== 'carrier_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code === 'NoPrior') {
            labelMap[key].unVisible = true;
        }
    }
}

// eslint-disable-next-line valid-jsdoc
/**
 * Deal with the scenario is when the source is chips then hidden the description field but set one value into description field because of its required
 * Deal with the scenario is when the source is manual then hidden the descriptionForChips field but set one value into descriptionForChips field because of its required
 * Deal with the scenario is when the action is adding a new mvr user enter then hidden the descriptionForChips field but set one value into descriptionForChips field because of its required
 */
function handleVisibilityOfDescriptionField(key, title, rendorProps, newModalVM, getNewModalVM) {
    if (!_.isNil(newModalVM.value.source) && newModalVM.value.source === 'chips' && key === 'description') {
        rendorProps.hidden = true;
        if (_.isEmpty(newModalVM.description.value)) {
            const descriptionValues = newModalVM[key].aspects.availableValues;
            if (!_.isNil(descriptionValues) && descriptionValues.length > 0) {
                _.set(getNewModalVM, key, descriptionValues[0].code);
            }
        }
    } else if (!_.isNil(newModalVM.value.source) && newModalVM.value.source === 'chips' && key === 'descriptionForChips') {
        rendorProps.hidden = false;
    } else if (!_.isNil(newModalVM.value.source) && newModalVM.value.source !== 'chips' && key === 'description') {
        rendorProps.hidden = false;
    } else if ((!_.isNil(newModalVM.value.source) && newModalVM.value.source !== 'chips' && key === 'descriptionForChips') || (title === 'Add MVR Entry' && key === 'descriptionForChips')) {
        rendorProps.hidden = true;
        if (_.isEmpty(newModalVM.descriptionForChips.value)) {
            const descriptionValues = newModalVM.description.aspects.availableValues;
            if (!_.isNil(descriptionValues) && descriptionValues.length > 0) {
                _.set(getNewModalVM, key, descriptionValues[0].code);
            }
        }
    }
}
// description only have one, remove unnecessary field
function removeUnnecessaryDescription(clonedNewModalVM) {
    if (clonedNewModalVM.value.source === 'chips')
    {
        delete clonedNewModalVM.value.description
    } else { 
        delete clonedNewModalVM.value.descriptionForChips
    }
}

export default {
    getDropDownValues,
    getVisibilityOfConditionalFields,
    handleVisibilityOfDescriptionField,
    removeUnnecessaryDescription
};
