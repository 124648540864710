import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniQuote'), method, data, additionalHeaders);
}

export default class WniLoadSaveService {
    static retrievePaymentPlans(quoteID, sessionUUID, additionalHeaders = {}) {
        return processSubmission(
            'retrievePaymentPlans',
            [quoteID, sessionUUID],
            additionalHeaders
        );
    }

    static bindSubmission(quoteID, sessionUUID, bindData, ignoreWarning, additionalHeaders = {}) {
        return processSubmission('wniBind',
            [quoteID, sessionUUID, bindData, ignoreWarning],
            additionalHeaders);
    }

    static retrieveSubmission(data, additionalHeaders = {}) {
        return processSubmission('retrieve', [data], additionalHeaders);
    }

    // static validateFinishQuoteData(data, authHeader = {}) {
    //     return processSubmission('validateFinishQuoteData', [data], authHeader);
    // }

    static validateFinishQuoteData(quoteID, sessionUUID, flowStep, authHeader = {}) {
        return processSubmission('validateFinishQuoteData', [quoteID, sessionUUID, flowStep], authHeader);
    }

    static retrievePaymentSchedulePlans(quoteID, sessionUUID, billingId, authHeader = {}) {
        return processSubmission('retrievePaymentSchedulePlans', [quoteID, sessionUUID, billingId], authHeader);
    }

    static approveUnderwritingIssueByCodes(quoteID, sessionUUID,
        issueCodes, authHeader = {}) {
        return processSubmission(
            'approveUnderwritingIssueByCodes',
            [quoteID, sessionUUID, issueCodes], authHeader
        );
    }

    static selectPaymentPlan(quoteID, sessionUUID, billingId, authHeader = {}) {
        return processSubmission('selectPaymentPlan',
            [quoteID, sessionUUID, billingId],
            authHeader);
    }

    static selectPaymentPlanCombine(quoteID, sessionUUID, billingId, authHeader = {}) {
        return processSubmission('selectPaymentPlanCombine',
            [quoteID, sessionUUID, billingId],
            authHeader);
    }

    /**
     * Invokes WniQuoteHandler.retrieveByQuoteId()
     * 
     * This method is deprecated and should <strong>not</strong> be used anymore
     * 
     * @deprecated use WniCommonQuoteService.refreshSubmissionWizardData() instead
     * @param {string} quoteID 
     * @param {object} additionalHeaders 
     * @returns {QuoteDataDTO}
     */
    static retrieveSubmissionByQuoteId(quoteID, additionalHeaders = {}) {
        return processSubmission('retrieveByQuoteId', [quoteID], additionalHeaders);
    }

    static retrieveQuoteAndBindData(quoteID, sessionUUID, additionalHeaders = {}) {
        return processSubmission('getQuoteAndBindData', [quoteID, sessionUUID], additionalHeaders);
    }

    static updateUIFrontPaymentByQuoteId(quoteID, additionalHeaders = {}) {
        return processSubmission('updateUIFrontPaymentByQuoteId', [quoteID], additionalHeaders);
    }

    static retrieveQuotePaymentData(publicIDs, additionalHeaders) {
        return processSubmission('retrieveQuotePaymentData', [publicIDs], additionalHeaders);
    }

    static retrieveQuotePaymentDetailsData(publicID, additionalHeaders) {
        return processSubmission('retrieveQuotePaymentDetailsData', [publicID], additionalHeaders);
    }

    static saveBillingParameterToPeriod(hoPaymentRetrieveDetailDTO, additionalHeaders) {
        return processSubmission('saveBillingParameterToPeriod', [hoPaymentRetrieveDetailDTO], additionalHeaders);
    }

    static wniMultiQuotesBind(hoPaymentRetrieveDetailDTOs, wniBindData,
        ignoreWarning, additionalHeaders = {}) {
        return processSubmission('wniMultiQuotesBind',
            [hoPaymentRetrieveDetailDTOs, wniBindData, ignoreWarning],
            additionalHeaders);
    }

    static calculateInvoiceStreamCode(hoPaymentRetrieveDetailDTOs, additionalHeaders = {}) {
        return processSubmission('calculateInvoiceStreamCode',
            [hoPaymentRetrieveDetailDTOs],
            additionalHeaders);
    }

    static wniMultiQuotesDirectBind(hoPaymentRetrieveDetailDTOs,
        ignoreWarning, additionalHeaders = {}) {
        return processSubmission('wniMultiQuotesDirectBind',
            [hoPaymentRetrieveDetailDTOs, ignoreWarning],
            additionalHeaders);
    }

    static fetchAccountInfoWithJobNumber(quoteIDs, additionalHeaders = {}) {
        return processSubmission('fetchAccountInfoWithJobNumber',
            [quoteIDs],
            additionalHeaders);
    }

    static fetchAccountInfoWithAccountNumber(accountNumber, additionalHeaders = {}) {
        return processSubmission('fetchAccountInfoWithAccountNumber',
            [accountNumber],
            additionalHeaders);
    }
    
}
