import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CRDummyPage from './pages/Dummy/CRDummyPage';
import CRDummyReadOnlyPage from './pages/Dummy/CRDummyPage.readonly';

import CRQuotePage  from './pages/Quote/CRQuotePage';
import CRQuoteReadOnlyPage from './pages/Quote/CRQuotePage.readonly';

import CRModifiersPage from './pages/Modifiers/CRModifiersPage';
import CRModifiersReadOnlyPage from './pages/Modifiers/CRModifiersPage.readonly';

import CRQualificationPage from './pages/Qualification/CRQualificationPage';
import CRQualificationReadOnlyPage from './pages/Qualification/CRQualificationPage.readonly';

import CRLocationsPage from './pages/Locations/CRLocationsPage';
import CRLocationsReadOnlyPage from './pages/Locations/CRLocationsPage.readonly';

import CRLineCoveragesPage from './pages/CRLineCoveragesPage/CRLineCoveragesPage';
import CRLineCoveragesReadOnlyPage from './pages/CRLineCoveragesPage/CRLineCoveragesPage.readonly';

import CRCommercialCrimePage from './pages/CommercialCrime/CRCommercialCrimePage';
import CRCommercialCrimeReadOnlyPage from './pages/CommercialCrime/CRCommercialCrimePage.readonly';

import CRCommercialFidelityPage from './pages/CommercialFidelity/CRCommercialFidelityPage';
import CRCommercialFidelityReadOnlyPage from './pages/CommercialFidelity/CRCommercialFidelityPage.readonly';

import CRRiskAnalysisPage from './pages/RiskAnalysis/CRRiskAnalysisPage';
import CRRiskAnalysisReadOnlyPage from './pages/RiskAnalysis/CRRiskAnalysisPage.readonly';

import CRPolicyDetailsPage from './pages/PolicyDetails/CRPolicyDetailsPage';
import CRPolicyDetailsReadOnlyPage from './pages/PolicyDetails/CRPolicyDetailsPage.readonly';

import CRStateSpecificInformationPage from './pages/StateSpecificInformation/CRStateSpecificInformationPage';
import CRStateSpecificInformationReadOnlyPage from './pages/StateSpecificInformation/CRStateSpecificInformationPage.readonly';

setComponentMapOverrides(
    {
        CRDummyPage: {component: 'CRDummyPage'},
        CRDummyReadOnlyPage: { component: 'CRDummyReadOnlyPage' },

        //
        CRQuotePage: {component: 'CRQuotePage'},
        CRQuoteReadOnlyPage: {component: 'CRQuoteReadOnlyPage'},

        CRModifiersPage: {component: 'CRModifiersPage'},
        CRModifiersReadOnlyPage: {component: 'CRModifiersReadOnlyPage'},

        CRQualificationPage: {component: 'CRQualificationPage'},
        CRQualificationReadOnlyPage: {component: 'CRQualificationReadOnlyPage'},

        CRLocationsPage: {component: 'CRLocationsPage'},
        CRLocationsReadOnlyPage: { component: 'CRLocationsReadOnlyPage' },

        CRLineCoveragesPage: {component: 'CRLineCoveragesPage'},
        CRLineCoveragesReadOnlyPage: {component: 'CRLineCoveragesReadOnlyPage'},

        CRCommercialCrimePage: {component: 'CRCommercialCrimePage'},
        CRCommercialCrimeReadOnlyPage: {component: 'CRCommercialCrimeReadOnlyPage'},

        CRStateSpecificInformationPage: {component: 'CRStateSpecificInformationPage'},
        CRStateSpecificInformationReadOnlyPage: {component: 'CRStateSpecificInformationReadOnlyPage'},

        CRCommercialFidelityPage: {component: 'CRCommercialFidelityPage'},
        CRCommercialFidelityReadOnlyPage: {component: 'CRCommercialFidelityReadOnlyPage'},

        CRRiskAnalysisPage: {component: 'CRRiskAnalysisPage'},
        CRRiskAnalysisReadOnlyPage: { component: 'CRRiskAnalysisReadOnlyPage' },

        CRPolicyDetailsPage: {component: 'CRPolicyDetailsPage'},
        CRPolicyDetailsReadOnlyPage: {component: 'CRPolicyDetailsReadOnlyPage'}
    },
    {
        CRDummyPage,
        CRDummyReadOnlyPage,

        //
        CRQuotePage,
        CRQuoteReadOnlyPage,

        CRModifiersPage,
        CRModifiersReadOnlyPage,

        CRQualificationPage,
        CRQualificationReadOnlyPage,

        CRLocationsPage,
        CRLocationsReadOnlyPage,

        CRLineCoveragesPage,
        CRLineCoveragesReadOnlyPage,

        CRCommercialCrimePage,
        CRCommercialCrimeReadOnlyPage,

        CRCommercialFidelityPage,
        CRCommercialFidelityReadOnlyPage,

        CRRiskAnalysisPage,
        CRRiskAnalysisReadOnlyPage,

        CRPolicyDetailsPage,
        CRPolicyDetailsReadOnlyPage,

        CRStateSpecificInformationPage,
        CRStateSpecificInformationReadOnlyPage,
    }
);


export {
    CRDummyPage,
    CRDummyReadOnlyPage,
    //
    CRModifiersPage,
    CRModifiersReadOnlyPage,

    CRQualificationPage,
    CRQualificationReadOnlyPage,

    CRLocationsPage,
    CRLocationsReadOnlyPage,

    CRLineCoveragesPage,
    CRLineCoveragesReadOnlyPage,

    CRCommercialCrimePage,
    CRCommercialCrimeReadOnlyPage,

    CRCommercialFidelityPage,
    CRCommercialFidelityReadOnlyPage,

    CRRiskAnalysisPage,
    CRRiskAnalysisReadOnlyPage,

    CRPolicyDetailsPage,
    CRPolicyDetailsReadOnlyPage,

    CRStateSpecificInformationPage,
    CRStateSpecificInformationReadOnlyPage,
};

// eslint-disable-next-line import/prefer-default-export
export { default as CRSubmissionWizard } from './CRSubmissionWizard';
export { default as CRWizardPage } from './templates/CRWizardPage';

export { default as CRQuoteIssueRenderHelper } from './pages/Quote/util/CRQuoteIssueRenderHelper';
export { default as CRQuotePageConstants } from './pages/Quote/config/CRQuotePage.config';
export { default as CRPolicyLineSummaryComponent } from './pages/Quote/components/Summary/CRPolicyLineSummaryComponent';
