import React, { useState, useContext }from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './SingleVehicleIncident.metadata.json5'
import messages from './SingleVehicleIncident.message'
import { createNewCompanyWithoutPublicID, createNewPersonWithoutPublicID } from '../../util/NewContactUtil';
import { getContactDisplayName } from '../../util/ContactUtil';
import { createNewLocationWithoutPublicID } from '../../util/NewAddressUtil';
import { getFullAddressDisplayName } from "../../util/AddressUtil";
import { Button } from '@jutro/legacy/components';

const AddNewPerson = 'AddNewPerson'
const AddNewCompany = 'AddNewCompany'
const AddNewLocation = 'AddNewLocation'

const SingleVehicleIncident = (props) => {
    const {
        originVehicleIncident,
        editingVehicleIncidentIndex,
        onPopupCancel,
        onPopupSave,
        relatedContacts,
        predefinedLossLocations,
        incidentsAddresses,
    } = props

    const translator = useTranslator()

    const {
        isComponentValid,
        onValidate
    } = useValidation('SingleVehicleIncident')

    const [showErrors, setShowErrors] = useState(false);

    const viewModelService = useContext(ViewModelServiceContext);

    const [vehicleOwner, setVehicleOwner] = useState(() => {
        return relatedContacts.find((contact) => contact.publicID === originVehicleIncident.vehicleOwnerContactPublicID)
    })

    const allExistingLocations = predefinedLossLocations.concat(incidentsAddresses)

    const [vehicleLocation, setVehicleLocation] = useState(() => {
        return allExistingLocations.find((address) => address.publicID === originVehicleIncident.vehicleLocationPublicID)
    })

    const vehicleLocationVM = viewModelService.create(
        vehicleLocation,
        'cc',
        'edge.capabilities.address.dto.AddressDTO'
    )

    // handle vehicle is undefined
    if (_.isEmpty(_.get(originVehicleIncident, 'vehicle'))) {
        _.set(originVehicleIncident, 'vehicle', {});
    }

    const [vehicleIncidentVM, setVehicleIncidentVM] = useState(() => viewModelService.create(
            originVehicleIncident,
            'cc',
            'wni.edge.capabilities.claim.lob.impl.commonauto.dto.WniVehicleIncidentDTO'
        ))

    const vehicleOwnerContactPublicID = _.get(vehicleIncidentVM.value, 'vehicleOwnerContactPublicID')
    const vehicleLocationPublicID = _.get(vehicleIncidentVM.value, 'vehicleLocationPublicID')

    const writeValue = (value, path) => {
        const newVehicleIncidentVM = _.clone(vehicleIncidentVM);
        _.set(newVehicleIncidentVM.value, path, value);
        setVehicleIncidentVM(newVehicleIncidentVM);
    }

    const vehicleOwnerSelectorAvailableValues = relatedContacts.map((contact) => {
        return {
            code: _.get(contact, 'publicID'),
            name: getContactDisplayName(contact)
        }
    }).concat([{
        code: AddNewPerson,
        name: translator(messages.addNewPerson)
    },{
        code: AddNewCompany,
        name: translator(messages.addNewCompany)
    }])

    const vehicleLocationSelectorAvailableValues = allExistingLocations.map((address) => {
        return {
            code: _.get(address, 'publicID'),
            name: getFullAddressDisplayName(address)
        }
    }).concat([
        {
            code: AddNewLocation,
            name: translator(messages.addNewLocation)
        }
    ])

    const onVehicleOwnerSelectorChange = (value) => {
        if (value === vehicleOwnerContactPublicID) {
            return
        }
        const newVehicleIncidentVM = _.clone(vehicleIncidentVM);
        if (value === AddNewPerson) {
            _.set(newVehicleIncidentVM.value, 'vehicleOwnerContactPublicID', AddNewPerson);
            setVehicleOwner(createNewPersonWithoutPublicID())
        } else if (value === AddNewCompany) {
            _.set(newVehicleIncidentVM.value, 'vehicleOwnerContactPublicID', AddNewCompany);
            setVehicleOwner(createNewCompanyWithoutPublicID())
        } else {
            _.set(newVehicleIncidentVM.value, 'vehicleOwnerContactPublicID', value);
            setVehicleOwner(relatedContacts.find((contact) => contact.publicID === value))
        }
        setVehicleIncidentVM(newVehicleIncidentVM);
    }

    const onVehicleLocationSelectorChange = (value) => {
        if (value === vehicleLocationPublicID) {
            return
        }
        const newVehicleIncidentVM = _.clone(vehicleIncidentVM);
        if (value === AddNewLocation) {
            _.set(newVehicleIncidentVM.value, 'vehicleLocationPublicID', AddNewLocation);
            setVehicleLocation(createNewLocationWithoutPublicID())
        } else {
            _.set(newVehicleIncidentVM.value, 'vehicleLocationPublicID', value);
            setVehicleLocation(allExistingLocations.find((address) => address.publicID === value))
        }
        setVehicleIncidentVM(newVehicleIncidentVM);
    }

    const onVehicleOwnerChange = (value, path) => {
        const updatedVehicleOwner = {
            ...vehicleOwner,
            [path]: value,
            updated_Ext: true,
        }
        setVehicleOwner(updatedVehicleOwner)
    }

    const onVehicleLocationChange = (value, path) => {
        const updatedVehicleLocation = {
            ...vehicleLocation,
            [path]: value
        }
        setVehicleLocation(updatedVehicleLocation)
    }

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(vehicleIncidentVM.value, editingVehicleIncidentIndex, vehicleOwner, vehicleLocation)
    }

    let stateAvailableValues = _.get(vehicleLocationVM, 'state.aspects.availableValues', [])
    stateAvailableValues = _.map(stateAvailableValues, (stateCode) => {
        const opt = {
            code: _.get(stateCode, 'code'),
            name: translator({
                id: _.get(stateCode, 'name'),
                defaultMessage: _.get(stateCode, 'name')
            })
        }
        return opt;
    });

    const isClaimAddress = predefinedLossLocations.some((address) => address.publicID === vehicleLocationPublicID)

    const overrideProps = {
        vinNumber: {
            showOptional: true
        },
        make: {
            showOptional: true
        },
        model: {
            showOptional: true
        },
        vehicleOwnerSelector: {
            value: vehicleOwnerContactPublicID,
            availableValues: vehicleOwnerSelectorAvailableValues,
            onValueChange: onVehicleOwnerSelectorChange
        },
        vehicleOwnerPersonContainer: {
            visible: ['Person', 'UserContact'].includes(_.get(vehicleOwner, 'subtype'))
        },
        vehicleOwnerFirstName: {
            value: _.get(vehicleOwner, 'firstName'),
            onValueChange: (value) => onVehicleOwnerChange(value, 'firstName'),
            showOptional: true
        },
        vehicleOwnerLastName: {
            value: _.get(vehicleOwner, 'lastName'),
            onValueChange: (value) => onVehicleOwnerChange(value, 'lastName')
        },
        vehicleOwnerCompanyContainer: {
            visible: ['Company'].includes(_.get(vehicleOwner, 'subtype'))
        },
        vehicleCompanyName: {
            value: _.get(vehicleOwner, 'contactName'),
            onValueChange: (value) => onVehicleOwnerChange(value , 'contactName')
        },
        vehicleLocationSelector: {
            value: vehicleLocationPublicID,
            availableValues: vehicleLocationSelectorAvailableValues,
            onValueChange: onVehicleLocationSelectorChange
        },
        addressDetailsContainer: {
            visible: !!vehicleLocation
        },
        addressLine1: {
            value: _.get(vehicleLocation, 'addressLine1'),
            onValueChange: (value) => onVehicleLocationChange(value, 'addressLine1'),
            disabled: isClaimAddress,
            showOptional: true
        },
        addressLine2: {
            value: _.get(vehicleLocation, 'addressLine2'),
            onValueChange: (value) => onVehicleLocationChange(value, 'addressLine2'),
            disabled: isClaimAddress,
            showOptional: true
        },
        addressLine3: {
            value: _.get(vehicleLocation, 'addressLine3'),
            onValueChange: (value) => onVehicleLocationChange(value, 'addressLine3'),
            disabled: isClaimAddress,
            showOptional: true
        },
        city: {
            value: _.get(vehicleLocation, 'city'),
            onValueChange: (value) => onVehicleLocationChange(value, 'city'),
            disabled: isClaimAddress,
        },
        state: {
            value: _.get(vehicleLocation, 'state'),
            onValueChange: (value) => onVehicleLocationChange(value, 'state'),
            availableValues: stateAvailableValues,
            disabled: isClaimAddress,
        },
        zipCode: {
            value: _.get(vehicleLocation, 'postalCode'),
            onValueChange: (value) => onVehicleLocationChange(value, 'postalCode'),
            disabled: isClaimAddress,
            showOptional: true
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={vehicleIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div style={{
                display: 'flex',
                'justify-content': 'right',
                gap: '10px'
            }}>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
            >{translator(messages.cancel)}</Button>
            <Button
                type="filled"
                onClick={onSaveClicked}
            >{translator(messages.save)}</Button>
        </div>
    </>
}

export default SingleVehicleIncident
