
import React from 'react';

import { CPPLineSelectionPage } from 'wni-capability-quoteandbind-cpp-react';

function CPPLineSelectionChangePage(props) {

    return (
        <CPPLineSelectionPage {...props} isPolicyChange />
    );

}

CPPLineSelectionChangePage.propTypes = CPPLineSelectionPage.propTypes;
CPPLineSelectionChangePage.defaultProps = CPPLineSelectionPage.defaultProps;

export default CPPLineSelectionChangePage;