import { defineMessages } from 'react-intl';

export default defineMessages({
    hoDiscount: {
        id: 'quoteandbind.views.discount.Homeowners Discount',
        defaultMessage: 'Homeowners Discount',
    },
    hoPeDiscount: {
        id: 'quoteandbind.ho.views.rating.Discounts',
        defaultMessage: 'Discounts',
    },
    fireExtinguishers: {
        id: 'quoteandbind.ho.views.rating.Fire Extinguishers in Your Home',
        defaultMessage: 'Fire Extinguishers in the Home',
    },
    burglarAlarm: {
        id: 'quoteandbind.ho.views.rating.Burglar Alarm',
        defaultMessage: 'Burglar Alarm',
    },
    burglarAlarmType: {
        id: 'quoteandbind.ho.views.rating.Specify type:',
        defaultMessage: 'Specify type:',
    },
    fireAlarm: {
        id: 'quoteandbind.ho.views.rating.Fire Alarm Reporting To Monitoring Center',
        defaultMessage: 'Fire Alarm Reporting To Monitoring Center',
    },
    smokeAlarms: {
        id: 'quoteandbind.ho.views.rating.Smoke Alarms',
        defaultMessage: 'Smoke Alarms',
    },
    smokeAlarmsOnAllFloors: {
        id: 'quoteandbind.ho.views.rating.Alarms are on all floors',
        defaultMessage: 'Are there alarms on all floors?',
    },
    deadbolts: {
        id: 'quoteandbind.ho.views.rating.Deadbolts',
        defaultMessage: 'Deadbolts',
    },
    numberOfDeadbolts: {
        id: 'quoteandbind.ho.views.rating.Number of Deadbolts',
        defaultMessage: 'Specify the number of deadbolts:',
    },
    residenceVisibleToNeighbours: {
        id: 'quoteandbind.ho.views.rating.Residence Visible to Neighbours',
        defaultMessage: 'Residence Visible to Neighbours',
    },
    sprinklerSystemType: {
        id: 'quoteandbind.ho.views.rating.Sprinkler System Type',
        defaultMessage: 'Sprinkler System Type',
    },
    roomersOrBoarders: {
        id: 'quoteandbind.ho.views.rating.Roomers or Boarders',
        defaultMessage: 'Roomers or Boarders',
    },
    roomersOrBoardersNumber: {
        id: 'quoteandbind.ho.views.rating.Specify the number of boarders:',
        defaultMessage: 'Specify the number of boarders:',
    },
    fireplaceWoodStove: {
        id: 'quoteandbind.ho.views.rating.Fireplace or Wood Stove',
        defaultMessage: 'Fireplace or Wood Stove',
    },
    fireplaceWoodStoveNumber: {
        id: 'quoteandbind.ho.views.rating.Specify the number of fireplaces or wood stoves:',
        defaultMessage: 'Specify the number of fireplaces or wood stoves:',
    },
    swimmingPool: {
        id: 'quoteandbind.ho.views.rating.Swimming Pool',
        defaultMessage: 'Swimming Pool',
    },
    swimmingPoolType: {
        id: 'quoteandbind.ho.views.rating.Swimming Pool Type',
        defaultMessage: 'Swimming Pool Type',
    },
    swimmingPoolDivingBoard: {
        id: 'quoteandbind.ho.views.rating.Is there a diving board?',
        defaultMessage: 'Is there a diving board?',
    },
    swimmingPoolFencing: {
        id: 'quoteandbind.ho.views.rating.Is the property fenced?',
        defaultMessage: 'Is the property fenced?',
    },
    swimmingPoolSlide: {
        id: 'quoteandbind.ho.views.rating.Is there a slide?',
        defaultMessage: 'Is there a slide?',
    },
    trampoline: {
        id: 'quoteandbind.ho.views.rating.Trampoline',
        defaultMessage: 'Trampoline',
    },
    trampolineSafetyNet: {
        id: 'quoteandbind.ho.views.rating.Does the trampoline have a safety net?',
        defaultMessage: 'Does the trampoline have a safety net?',
    },
    waterLeakageInfo: {
        id: 'quoteandbind.ho.views.rating.Water leakage or flooding in the past 5 years?',
        defaultMessage: 'Water leakage or flooding in the past 5 years?',
    },
    waterLeakageDetails: {
        id: 'quoteandbind.ho.views.rating.Provide details:',
        defaultMessage: 'Provide details:',
    },
    numberOfUnits: {
        id: 'quoteandbind.ho.views.rating.Number of Units',
        defaultMessage: 'Number of Units',
    }
});
