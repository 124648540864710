import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import PECPBuildingsWizard from './PECPBuildingsWizard';
import BuildingsPage from './pages/Buildings/BuildingsPage';
import CoveragePage from './pages/Coverages/CoveragePage';
import LocationsPage from './pages/Locations/LocationsPage';

setComponentMapOverrides(
    {
        BuildingsPage: { component: 'BuildingsPage' },
        CoveragePage: { component: 'CoveragePage' },
        LocationsPage: { component: 'LocationsPage' }
    },
    {
        BuildingsPage,
        CoveragePage,
        LocationsPage
    }
);

export default PECPBuildingsWizard;
