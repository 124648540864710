import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('walMultiQuoteChange'), method, data, additionalHeaders);
}

export default class WALMultiQuoteService {
    /**
     * Invokes WALMultiQuoteChangeHandler.processQuote()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processQuote(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('processQuote', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes WALMultiQuoteChangeHandler.processIssuance()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processIssuance(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('processIssuance', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes WALMultiQuoteChangeHandler.processIssuanceWithoutWarning()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processIssuanceWithoutWarning(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('processIssuanceWithoutWarning', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes WALMultiQuoteChangeHandler.getValidationAndUWIssues()
     * 
     * @deprecated replaced by retrieveSideBySideData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getValidationAndUWIssues(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getValidationAndUWIssues', [jobID, sessionUUID], authHeader);
    }

    // /**
    //  * Invokes WALMultiQuoteHandler.finishQuote()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static finishQuote(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('finishQuote', [quoteID, sessionUUID], authHeader);
    // }

    /**
     * Invokes WALMultiQuoteChangeHandler.createDuplicateQuote()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDuplicateQuote(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('createDuplicateQuote', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes WALMultiQuoteChangeHandler.withdrawBranch()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} policPeriodPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static withdrawBranch(jobID, sessionUUID, policPeriodPublicID, authHeader = {}) {
        return processSubmission('withdrawBranch', [jobID, sessionUUID, policPeriodPublicID], authHeader);
    }

    /**
     * Invokes WALMultiQuoteChangeHandler.retrieveSideBySideData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static retrieveSxsData(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('retrieveSideBySideData', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes WALMultiQuoteChangeHandler.retrieveSideBySideDataWithActiveQuotes()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static retrieveSxsDataWithActiveQuotes(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('retrieveSideBySideDataWithActiveQuotes', [jobID, sessionUUID], authHeader);
    }
}
