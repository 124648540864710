import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class FNOLService {
    static submitClaim(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'submitClaim', [data], additionalHeaders);
    }

    static getFNOLDetails(policyNumber, data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'createClaim', [policyNumber, data], additionalHeaders);
    }

    static getClaim(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'getClaim', [claimNumber], additionalHeaders);
    }

    static saveFNOLDetails(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'saveClaim', [data], additionalHeaders);
    }

    static cancelClaim(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'cancelClaim', [claimNumber], additionalHeaders);
    }

    static searchPolicies(policySearchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnolPolicy'), 'searchPolicies', [policySearchCriteria], additionalHeaders);
    }

    static getPolicySummaryRiskUnitsBySearchCriteria(searchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnolPolicy'), 'getPolicySummaryRiskUnitsBySearchCriteria', [searchCriteria], additionalHeaders);
    }

    static getPolicySummaryRiskUnits(policyNumber, lossDate, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnolPolicy'), 'getPolicySummaryRiskUnits', [policyNumber, lossDate], additionalHeaders);
    }

    static getOrCreateFixedPropertyIncident(claimNumber, incidentPublicId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'), 'getOrCreateFixedPropertyIncident', [claimNumber, incidentPublicId], additionalHeaders);
    }


    static savePedestrianIncident(claimNumber, incidentDTO, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),'savePedestrianIncident', [claimNumber, incidentDTO], authHeader);
    }

    static removePedestrianIncident(claimNumber, incidentPublicID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),'removePedestrianIncident', [claimNumber, incidentPublicID], authHeader);
    }

    static savePropertyIncident(claimNumber, incidentDTO, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),'savePropertyIncident', [claimNumber, incidentDTO], authHeader);
    }

    static removePropertyIncident(claimNumber, incidentPublicID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),'removePropertyIncident', [claimNumber, incidentPublicID], authHeader);
    }
}
