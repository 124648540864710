import _ from 'lodash';

export default function parametrizeWithCustomerConfig({ lobConfig }) {
    const TRANSACTIONS_MAP = lobConfig.TRANSACTIONS_MAP;

    const COMMON_LOB = 'common';
    const ALL_LOBS_PLACEHOLDER = '__ALL_LOBS__';

    const LOBS_MAP = lobConfig.LOBS_MAP;

    const keyAndValuesToEntries = ([key, arr]) => arr.map((el) => [_.toLower(el), key]);

    function reverseLookupMap(obj) {
        const lookupMap = _(obj)
            .toPairs()
            .flatMap(keyAndValuesToEntries)
            .fromPairs()
            .value();
        return lookupMap;
    }


    // this contains the lookup from transaction name in the package to the normalized transaction name
    /*
    * e.g.
    * {
    *   quoteandbind: "quote",
    *   policyrenewal: "renewal",
    *   endorsement: "policychange"
    * }
    */
    const transactionLookupMap = reverseLookupMap(TRANSACTIONS_MAP);

    function getNormalizedTransactionName(transactionName) {
        const transactionNameLwr = _.toLower(transactionName);
        return transactionLookupMap[transactionNameLwr];
    }

    const lobLookupMap = reverseLookupMap(LOBS_MAP);

    function getNormalizedLOBName(productName) {
        const productNameLwr = _.toLower(productName);
        return lobLookupMap[productNameLwr];
    }

    function isValidTransaction(transactionName) {
        return !!getNormalizedTransactionName(transactionName);
    }

    function isValidLob(lobName) {
        const lobArray = Object.keys(LOBS_MAP);
        const lobNameLower = _.toLower(lobName);
        const isLob = lobArray.includes(lobNameLower);
        if (isLob) {
            return true;
        }
        const normalisedLob = getNormalizedLOBName(lobNameLower);
        return lobArray.includes(normalisedLob);
    }

    function getEnabledLobsForCapability(capabilityName, allowedCapabilities) {
        // enabled can be undefined, true, false, an array [empty] of strings
        const enabled = allowedCapabilities[capabilityName];
        if (!enabled) {
            return [];
        }
        if (_.isArray(enabled)) {
            return _.uniq([...enabled, COMMON_LOB]);
        }
        // if enabled is true return only the COMMON_LOB
        return [ALL_LOBS_PLACEHOLDER];
    }

    function isCapabilityEnabled({
        capabilitiesConfig,
        capabilityName,
        lob: inputLob = COMMON_LOB
    }) {
        const enabledLobs = getEnabledLobsForCapability(capabilityName, capabilitiesConfig);
        if (_.includes(enabledLobs, ALL_LOBS_PLACEHOLDER)) {
            return true;
        }
        const lob = getNormalizedLOBName(inputLob) || inputLob;
        return _.includes(enabledLobs, lob);
    }

    function filterCapabilityRoutes(capabilitiesConfig, routes = []) {
        return routes.filter(({ componentProps: routeProps = {} }) => {
            const { capability = {} } = routeProps;
            const { name: capabilityName, lob } = capability;

            if (capabilityName === undefined) {
                return true;
            }

            return isCapabilityEnabled({ capabilitiesConfig, capabilityName, lob });
        }).map((route) => {
            const { componentProps = {} } = route;
            // remove capability from the rendered page props
            const { capability, ...rest } = componentProps;
            return {
                ...route,
                componentProps: { ...rest }
            };
        });
    }

    return {
        getNormalizedTransactionName,
        getNormalizedLOBName,
        isValidTransaction,
        isValidLob,
        isCapabilityEnabled,
        filterCapabilityRoutes
    };
}
