import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('rtPolicyInfoService'), method, data, additionalHeaders);
}

export default class RTPolicyInfoService {

    static saveRTPolicyInfoData(quoteID, sessionUUID, questionSetData, quoteBaseData, policyInfoData, authHeader) {
        return processSubmission('saveRTPolicyInfoData', [quoteID, sessionUUID, questionSetData, quoteBaseData, policyInfoData], authHeader);
    }

}
