import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import {
    Redirect,
    useLocation,
    useParams,
    withRouter,
    useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Wizard } from '@xengage/gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    QuoteUtil,
    OOSUtil,
    WizardUtil,
    WizardStepUtil,
    ValidationIssueUtil,
    WizardPageJumpUtil,
    ActiveQuoteUtil,
    WniProductsUtil,
    WizardPageHeaderUtil,
} from 'wni-portals-util-js';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';
import { CUUnderlyingService } from 'wni-capability-quoteandbind-cu';
import { WizardPageHeaderExtensionComponent, WizardSidebarLink } from 'wni-capability-gateway-react';
import { MultiModeWizard, MultiModeWizardWithErrorContext } from 'wni-portals-wizard-react';
import { WniEndorsementService, WniCommonChangeService } from 'wni-capability-policychange';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as commonChangeMsg } from 'wni-platform-translations';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import wizardConfig from './config/cu-policychange-wizard-config.json5';
import wizardReadOnlyConfig from './config/cu-policychange-wizard-readonly-config.json5';
import wizardStartConfig  from './config/cu-policychange-wizard-start-config.json5';
import wizardStepToFieldMapping from './config/cu-policychange-wizard-step-to-field-mapping.json5';

const { steps, title } = wizardConfig;
const { steps: readOnlySteps } = wizardReadOnlyConfig;
const { steps: policyChangeStartSteps } = wizardStartConfig;

const { 
    CU_PRODUCT_CODE,
    getProductName
} = WniProductsUtil;
/**
 * Entry Points:
 * 1) PoliciesSummary::onChangePolicy()
 * 2) EndorsementSummary.onContinueTransaction()
 * 
 * Related Components:
 * 1) QuoteDetailsSummary::onContinueTransaction()
 * 
 * 
 * @param {object} props 
 * @returns {object}
 */
function CUPolicyChangeWizard(props) {
    const modalApi = useWniModal();
    const location = useLocation();
    const history = useHistory();
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const { authHeader, authUserData } = useAuthentication();
    const hasEditPolicyChangePermission = _.get(authUserData, 'permissionMap_Ext.editpolchange');
    const translator = useTranslator();

    const [initialWizardPageData, setInitialWizardPageData] = useState(undefined);
    const [initialPolicyChange, setInitialPolicyChange] = useState(null);
    const [accountNumber, updateAccountNumber] = useState('');
    //
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);

    const [initialWizardLevelIssues, updateInitialWizardLevelIssues] = useState(undefined);

    const {
        state: {
            // from EndorsementSummary.onContinueTransaction()
            isReadOnly,
            policyNumber,
            jobNumber,
            //
            // policyChangeSource,
        } = {}
    } = location;

    const errorModalBox = (errorMessage) => {
        modalApi.showConfirm(errorMessage).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            // setIsLoading(false);
            return history.push(`/contactAgent/${policyNumber}`);
        }, _.noop);
    };

    const getStartPolicyChangeWarningMessage = useCallback(async () => {
        let startMessages = [];
        if (!jobNumber) {
            startMessages = await WniEndorsementService.getStartPolicyChangeWarningMessage(policyNumber, authHeader);
        } else {
            startMessages = await WniEndorsementService.getStartPolicyChangeWarningMessageByJobNumber(jobNumber, authHeader);
        }
        // return startMessages;
        const retval = ValidationIssueUtil.convertStrArrayToWarnings(startMessages);
        return retval;
    }, [policyNumber, jobNumber, authHeader]);

    const retrievePolicyChange = useCallback(async (policyChangeRetrievalParam) => {
        const {
            jobID,
            policyNumber: policynumberParam,
        } = policyChangeRetrievalParam;
        setLoadingMask(true);

        let startPolicyChangeMessages = await getStartPolicyChangeWarningMessage();
        
        let response = null;
        let exitPageData = {};
        
        if (_.isNil(jobNumber)) {
            response = await WniCommonChangeService.retrievePolicyChangeWizardDataStub({ policyNumber: policynumberParam }, authHeader);
        } else {

            response = await WniCommonChangeService.retrievePolicyChangeWizardData({ jobID }, authHeader);
            const {
                sessionUUID,
                baseData: { effectiveDate_Ext: effectiveDate, periodStatus },
                changeData: { oossliceDates_Ext: oossliceDates },
            } = response
            const OOSSliceDatesStringArray = OOSUtil.getOOSSliceDatesString(oossliceDates, effectiveDate);
            const oosSliceDateWarnings = OOSUtil.getOOSSliceDatesWarning(OOSSliceDatesStringArray, translator);
            startPolicyChangeMessages = [
                ...startPolicyChangeMessages,
                ...oosSliceDateWarnings,
            ]

            let wizardExitData;
            if (periodStatus === 'Bound') {
                wizardExitData = await WniCommonChangeService.loadWizardExitData(jobID, sessionUUID, authHeader);
            } else {
                wizardExitData = await WniCommonChangeService.loadWizardExitDataWithActiveQuotes(jobID, sessionUUID, authHeader);
            }
            // const wizardExitData = await WniCommonChangeService.loadWizardExitData(jobID, sessionUUID, authHeader);
            // const wizardExitData = await WniCommonChangeService.loadWizardExitDataWithActiveQuotes(jobID, sessionUUID, authHeader);
            exitPageData = WizardUtil.getInitialWizardPageData(wizardExitData);
        }
        updateInitialWizardLevelIssues(startPolicyChangeMessages);
        //

        const viewModelContext = {};
        const policyChange = viewModelService.create(
            response,
            'pc',
            'wni.edge.capabilities.policychange.dto.PolicyChangeWizardDataDTO',
            viewModelContext,
        );

        
        const initPageData = {
            ...exitPageData,
            // [WizardConstants.policyChangeSource]: policyChangeSource,
            // [WizardConstants.landingPage]: landingPageID,
        };

        // else if (landingPageID) {
        //     const landingPageIndex = WizardStepUtil.getWizardPageIndex(landingPageID, steps);
        //     initPageData[WizardConstants.lastExitWizardPage] = landingPageIndex;
        // }

        setInitialWizardPageData(initPageData);
        setInitialPolicyChange(policyChange);

        const newAccountNumber = _.get(response, 'baseData.accountNumber');
        updateAccountNumber(newAccountNumber);

        setLoadingMask(false);
    }, [authHeader, getStartPolicyChangeWarningMessage, jobNumber, setLoadingMask, translator, viewModelService]);

    useEffect(() => {
        if (!viewModelService) {
            return;
        }
        if (!location.state) {
            history.push('/');
            return;
        }
        // const { state: { policyNumber, policyDetails, requestType } } = location;
        if (!policyNumber) {
            return;
        }
        retrievePolicyChange({
            jobID: jobNumber,
            policyNumber
        });
        
    },
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // []);
    [viewModelService]);


    const getInitialMode = () => {
        if (isReadOnly || !hasEditPolicyChangePermission) {
            return 'readOnly';
        }
        if (_.isNil(jobNumber)) {
            return 'policyChangeStart';
        }
        return 'default';
    };

    const policyChangeStartPageHeaderFormatter = (formatterParams) => {
        const lobWithQuoteNumber = `${getProductName(CU_PRODUCT_CODE)}(${policyNumber})`
        return {
            productWithQuoteNumber: lobWithQuoteNumber,
            // offeringPolicyType: '',
            // coveragesForm: '',
            jobStatusInfo: '',
            isDisplayPolicyType: true,
        };
    };

    const policyChangePageHeaderFormatter = ({
        wizardData: wizardSubmission,
        wizardPageData: wizardPageDataParam,
    }) => {
        const {
            changeData: {
                policyNumber: policyNumberParam,
            },
            baseData: {
                productCode
            } = {}
        } = wizardSubmission.value;
        const lobWithQuoteNumber = `${getProductName(productCode)}(${policyNumberParam})`
        const headerObj = WizardPageHeaderUtil.getPolicyChangeHeader({
            wizardData: wizardSubmission,
            wizardPageData: wizardPageDataParam,
            //
            lobWithQuoteNumber,
        });
        return headerObj;
    };


    const onLeaveWizard = useCallback(async ({
        wizardData: submissionVM,
        wizardPageData,
        currentStepIndex,
    }) => {
        // const {
        //     quoteID: quoteIDParam, sessionUUID,
        // } = submissionVM.value;
        // setIsLoading(true);

        // const wizardExitData = WizardUtil.getQuoteWizardExitData(wizardPageData, { currentStepIndex });
        // await WniCommonQuoteService.saveWizardExitData(quoteIDParam, sessionUUID, wizardExitData, authHeader);
        return true;
    }, []);

    const handleOnCancel = useCallback(({ wizardData: submissionVM }) => {
        
        const submissionJobID = _.get(submissionVM.value, 'jobID');
        WizardPageJumpUtil.goToChangeDetailsSummaryPage(history, {
            jobID: submissionJobID,
        });
    }, [history]);

    const handleReadOnlyCancel = useCallback(({ wizardData: submissionVM }) => {
        const { jobID } = submissionVM.value;

        if (_.isNil(jobID)) {
            WizardPageJumpUtil.goToPoliciesPage(history);
        } else {
            WizardPageJumpUtil.goToChangeDetailsSummaryPage(history, { jobID });
        }
        return true;
    }, [history]);

    const handlePolicyStartCancel = useCallback(({ wizardData: submissionVM }) => {
        WizardPageJumpUtil.goToPoliciesPage(history);
    }, [history]);

    const getPageHeaderExtensionComponent = () => {
        // const { jobID } = initialSubmission.value;
        return (pageHeaderExtensionProps) => {
            const { jobID } = pageHeaderExtensionProps;
            return (
                <WizardPageHeaderExtensionComponent
                    jobNumber={jobID}
                />
            );
        };
    };


    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        let redirectPath = `/contactAgent/${jobIDFromLocation}`;
        if (!_.isEmpty(jobIDFromWizard)) {
            redirectPath = `/change/${jobIDFromWizard}/summary`;
        }
        const state = {
            pathname: redirectPath,
            state: {
                redirectPath: '/home',
                accountNumber,
            }
        };

        return modalApi.showAlert({
            title: isQuotePage ? commonChangeMsg.saveQuoteError : commonChangeMsg.anErrorOccurredTitle,
            message: isQuotePage ? commonChangeMsg.saveQuoteErrorMessage : commonChangeMsg.anErrorOccurred,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: platformMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        }, _.noop);
    }, [location, accountNumber]);

    // ========================================================
    const defaultOnInitialization = useCallback(({
        updateFurthestPageVisited,
        wizardPageData,
        updateWizardReadOnly,
        stopSkipping,
    }) => {
        const isUwLocked = wizardPageData[WizardConstants.isUwLocked];
        if (isUwLocked) {
            updateWizardReadOnly(true);
            return;
        }
        const landingPage = wizardPageData[WizardConstants.landingPage];
        if (landingPage) {
            stopSkipping();
            // This will result into Quote page being marked as visisted
            // while switching from 'policyChangeStart' mode to 'default' mode
            // updateFurthestPageVisited('HOAdditionalInterestsPage');
            // const quotePageIndex = steps.findIndex((wizardStep) => wizardStep.isQuotePage);
            const quotePageIndex = WizardStepUtil.getQuotePageIndex(steps);
            updateFurthestPageVisited(quotePageIndex - 1);
        }
    }, []);

    const readonlyOnInitialization = useCallback(({ updateFurthestPageVisited }) => {
        // updateFurthestPageVisited('HOQuotePage');
        const quotePageIndex = WizardStepUtil.getQuotePageIndex(readOnlySteps);
        updateFurthestPageVisited(quotePageIndex);

        // Clear loading mask after Refer to Underwriter
        setLoadingMask(false);
    }, []);


    // ============================================

    // if (isLoading) {
    //     return (
    //         <Loader loaded={!isLoading} />
    //     );
    // }

    if (!initialPolicyChange || !initialWizardPageData) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <MultiModeWizardWithErrorContext
                initialData={initialPolicyChange}
                modeToWizardPropsMap={
                    {
                        default: {
                            initialSteps: steps,
                            wizardTitle: title,
                            skipCompletedSteps: true,
                            wizardStepToFieldMapping,
                            onCancel: handleOnCancel,
                            onPreviousModalProps: {
                                title: commonMessages.wantToJump,
                                message: commonMessages.wantToJumpMessage,
                                messageProps: {
                                    ok: platformMessages.yes,
                                    close: platformMessages.no
                                }
                            },
                            wizardPageHeaderExtension: getPageHeaderExtensionComponent(),
                            // wizardSidebarExtension: WizardSidebarLink,
                            // wizardServiceCallbacks: {
                            //     retrieveSubmission: WniLoadSaveService.retrieveSubmission,
                            //     copySubmission: handleCopySubmission,
                            // },
                            // wizardDataComparator: WizardUtil.isWizardDataEqualForPA,
                            showWizardPromptMessage: true,
                            wizardPageHeaderFormatter: policyChangePageHeaderFormatter,
                            initialWizardPageData,
                            onWizardInitialization: defaultOnInitialization,
                            //
                            wizardTransactionType: PortalConstants.TRANSACTIONTYPE_POLICYCHANGE,
                        },
                        readOnly: {
                            initialSteps: readOnlySteps,
                            wizardTitle: title,
                            onCancel: handleReadOnlyCancel,
                            wizardStepToFieldMapping,
                            initialWizardPageData,
                            onWizardInitialization: readonlyOnInitialization,
                            wizardPageHeaderFormatter: policyChangePageHeaderFormatter,
                        },
                        policyChangeStart: {
                            initialSteps: policyChangeStartSteps,
                            wizardTitle: title,
                            onCancel: handlePolicyStartCancel,
                            initialWizardPageData,
                            wizardPageHeaderFormatter: policyChangeStartPageHeaderFormatter,
                        },
                    }
                }
                initialMode={getInitialMode()}
                // onWizardModeChange={onWizardModeChange}
                initialWizardLevelIssues={initialWizardLevelIssues}
            />
        </ErrorBoundary>
    );
}

CUPolicyChangeWizard.propTypes = {
};

export default CUPolicyChangeWizard;