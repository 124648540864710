/* eslint-disable camelcase */
import _ from 'lodash';
// import messages from '../HOResidencePage.messages';

const residenceConstants = {
    burglarAlarmPrefix: 'Burglar Alarm - ',
    fireAlarmPrefix: 'Fire Alarm - '
};

function RCTFieldsMap(translator, isFeb19EntityChangeVer, messages) {
    const res = {
        'construction.replacementCost_Ext': translator(messages.replacementCostLabel),
        'construction.yearBuilt': translator(messages.yearBuilt),
        'construction.storiesNumber_Ext': translator(messages.numberofStories),
        'construction.totalLivingArea_Ext': translator(messages.finishedLivingArea),
        'construction.constructionType': translator(messages.construction),
        'construction.foundationType': translator(messages.foundation),
        'construction.basementFinishedPercentage_Ext': translator(messages.basementFinished),
        'construction.exteriorWallCovering_Ext': translator(messages.exteriorWallCovering),
        'construction.roofType': translator(messages.roof),
        'construction.garageType_Ext': translator(messages.garage),
        'construction.primaryHeatingType': translator(messages.primaryHeating),
        'construction.secondaryHeatingType_Ext': translator(messages.secondaryHeating),
        'construction.solidFuelBurningDevice_Ext': translator(messages.solidFuelBurningDevice),
        'construction.ampService_Ext': translator(messages.ampService),
        'construction.numberOfBathrooms_Ext': translator(messages.numberofBathrooms),
        'construction.stallsNumber_Ext': translator(messages.numberofStalls),
        'liabilityExposures.swimmingPoolType': translator(messages.swimmingPoolType),
        'protection.burglarAlarmType': translator(messages.burglarAlarm),
        'protection.fireAlarmType': translator(messages.fireAlarm),
        'protection.homeMonitoringSystems': translator(messages.homeMonitoringSystems)
    };
    if (isFeb19EntityChangeVer) {
        delete res['construction.primaryHeatingType'];
        delete res['construction.secondaryHeatingType_Ext'];
        delete res['construction.solidFuelBurningDevice_Ext'];

        res['construction.isResidenceHaveCentralHeat_Ext'] = translator(messages.isResidenceHaveCentralHeat);
        res['construction.selectedCentralHeats_Ext'] = translator(messages.primaryHeatingType);
        res['construction.anySolidFuelBurningDevice_Ext'] = translator(messages.anySolidFuelBurningDevice);
        res['construction.isAnyKnobAndTubeWiring_Ext'] = translator(messages.isAnyKnobAndTubeWiring);
    }

    return res;
}

function ShouldReportEmptyRCTFieldsMap(translator, isFeb19EntityChangeVer, messages) {
    const res = {
        'construction.replacementCost_Ext': translator(messages.replacementCostLabel),
        'construction.yearBuilt': translator(messages.yearBuilt), //
        'construction.storiesNumber_Ext': translator(messages.numberofStories), //
        'construction.totalLivingArea_Ext': translator(messages.finishedLivingArea), //
        'construction.constructionType': translator(messages.construction), //
        'construction.foundationType': translator(messages.foundation), //
        'construction.basementFinishedPercentage_Ext': translator(messages.basementFinished), //
        'construction.exteriorWallCovering_Ext': translator(messages.exteriorWallCovering), //
        'construction.roofType': translator(messages.roof), //
        'construction.garageType_Ext': translator(messages.garage), //
        'construction.primaryHeatingType': translator(messages.primaryHeating),
        // POI-24911
        // 'construction.secondaryHeatingType_Ext': translator(messages.secondaryHeating),
        // 'liabilityExposures.swimmingPoolType': translator(messages.swimmingPoolType),
        // 'construction.solidFuelBurningDevice_Ext': translator(messages.solidFuelBurningDevice),
        'construction.ampService_Ext': translator(messages.ampService),
        'construction.numberOfBathrooms_Ext': translator(messages.numberofBathrooms), //
        'construction.stallsNumber_Ext': translator(messages.numberofStalls),
        // 'protection.burglarAlarmType': translator(messages.burglarAlarm),
        // 'protection.fireAlarmType': translator(messages.fireAlarm),
        // 'protection.homeMonitoringSystems': translator(messages.homeMonitoringSystems)
    };

    if (isFeb19EntityChangeVer) {
        delete res['construction.primaryHeatingType'];

        res['construction.selectedCentralHeats_Ext'] = translator(messages.primaryHeatingType)
    }

    return res;
}

function typekeyMap() {
    return {
        storiesNumber_Ext: 'HOPStoriesNumber_Ext',
        constructionType: 'HOPConstructionType',
        foundationType: 'FoundationType',
        exteriorWallCovering_Ext: 'HOPEWCovering_Ext',
        roofType: 'HOPRoofType',
        garageType_Ext: 'GarageType',
        primaryHeatingType: 'HeatingType',
        secondaryHeatingType_Ext: 'HeatingType',
        solidFuelBurningDevice_Ext: 'HOPSolidFuleType_Ext',
        ampService_Ext: 'HOPAmpService_Ext',
        swimmingPoolType: 'HOPSwimmingPoolType',
        burglarAlarmType: 'BurglarAlarmType',
        fireAlarmType: 'FireAlarmType',
        homeMonitoringSystems: 'HOPSafetyFeaturesType_Ext',
        selectedCentralHeats_Ext: 'HeatingType'
    };
}

function removeEmpty(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        // need to consider: null/[]/{}/true/false
        if ((_.isNil(val) || _.isEmpty(val)) && val !== true && val !== false ) {
            delete res[key];
        }
    });
    return res;
}

function getDiffFields(initalDwelling, RCTDwelling, translator, isFeb19EntityChangeVer, messages) {
    const res = [];
    // filter burglarAlarm and fireAlarm form homeMonitoringSystems
    const { burglarAlarmPrefix } = residenceConstants;
    const { fireAlarmPrefix } = residenceConstants;
    const homeMonitoringSystems = _.get(initalDwelling, 'homeMonitoringSystems');
    const realHomeMonitoringSystems = _.filter(homeMonitoringSystems, (item) => {
        return !_.startsWith(item, fireAlarmPrefix) && !_.startsWith(item, burglarAlarmPrefix)
    })
    _.set(initalDwelling, 'homeMonitoringSystems', realHomeMonitoringSystems);

    const initalDwellingWithoutEmpty = removeEmpty(initalDwelling);
    const RCTDwellingWithoutEmpty = removeEmpty(RCTDwelling);

    _.forEach(RCTFieldsMap(translator, isFeb19EntityChangeVer, messages), (val, key) => {
        const reskey = key.split('.')[1];
        if (!_.isEqual(_.get(RCTDwellingWithoutEmpty, reskey), _.get(initalDwellingWithoutEmpty, reskey))) {
            res.push({
                fieldName: val,
                RCTVal: _.get(RCTDwellingWithoutEmpty, reskey),
                compRaterVal: _.get(initalDwellingWithoutEmpty, reskey),
                path: reskey
            });
        }
    });

    return res;
}

function getEmptyRCTFields(dwelling, translator, isFeb19EntityChangeVer, messages) {
    const res = [];
    _.forEach(ShouldReportEmptyRCTFieldsMap(translator, isFeb19EntityChangeVer, messages), (val, key) => {
        const callbackVal = _.get(dwelling, `${key}`);
        if (_.isNil(callbackVal)) {
            res.push(val);
        }
    });
    return res;
}

function onDiffTableCell(item, dwelling, translator) {
    const val = _.get(dwelling, item.path);
    if (val === true) {
        return 'Yes';
    }
    if (val === false) {
        return 'No';
    }
    if (!_.isNil(val)) {
        if (_.includes(item.path, 'replacementCost')) {
            return `$${val.amount}`;
        }
        let key = item.path;
        if (_.includes(item.path, '.')) {
            key = item.path.split('.')[1];
        }
        const typekey = _.get(typekeyMap(), key);
        if (typekey && !_.isEmpty(val)) {
            if (_.isArray(val)) {
                const arrayRes = _.map(val, (valItem) => {
                    return `${translator({ id: `typekey.${typekey}.${valItem}` })}  ` || '';
                });
                return arrayRes;
            }
            const typeKeyVal = translator({ id: `typekey.${typekey}.${val}` }) || '';
            return typeKeyVal;
        }
        return val;
    }
    return '';
}

function getAvailableValues(VM, id) {
    const availableValues = _.get(VM, `${id}.aspects.availableValues`);

    return availableValues ? availableValues.map((option) => {
        return {
            code: option.code,
            name: {
                id: option.name,
            },
        };
    }) : [];
};

// build rct fields
function buildDwellingStructure(dwelling) {
    const res = {
        construction: {
            yearBuilt: dwelling.yearBuilt
        },
        businessQuestions: {},
        liabilityExposures: {},
        protection: {},
        prefillRCTCallFlag: dwelling.prefillRCTCallFlag
    };

    return res;
}

function getValuationTypeCoverage(submissionVM, linePath) {
    const version = _.get(submissionVM, 'value.baseData.selectedVersion_Ext');
    const offerings = _.get(submissionVM, `value.lobData.${linePath}.offerings`) || [];
    const selectedOffering = _.find(offerings, (item) => {
        return item.publicID_Ext === version
    });
    const primaryCoverages = _.get(selectedOffering, 'coverages.primaryCoverages_Ext') || [];
    const coverageA = _.find(primaryCoverages, (coverage) => {
        return coverage.code_Ext === 'HOPCovA';
    });
    const terms = _.get(coverageA, 'terms') || [];
    const coverageATerm = _.find(terms, (term) => {
        return term.name === 'Valuation Type';
    });

    return _.get(coverageATerm, 'chosenTermValue');
}

// BR.PL.0328 BR.PL.0329
function updateBRValidationIssues(submissionVM, validationIssues, translator, linePath, messages) {
    const {
        yearBuilt,
        hopconstructionOption_Ext: hopconstructionOption
    } = _.get(submissionVM, `value.lobData.${linePath}.coverables.dwelling_Ext.construction`)
    let newValidationIssues = _.cloneDeep(validationIssues);
    const valuationTypeCoverage = getValuationTypeCoverage(submissionVM, linePath);
    const BL0328Message = _.filter(newValidationIssues, (issue) => {
        return issue.reason === translator(messages.BL0328WarningMessage);
    });
    const BL0329Message = _.filter(newValidationIssues, (issue) => {
        return issue.reason === translator(messages.BL0329WarningMessage);
    });
    // BR.PL.0328
    if (yearBuilt >= 1900
        && yearBuilt < 1940
        && hopconstructionOption === 'Standard'
        && !_.isEmpty(valuationTypeCoverage)
        && valuationTypeCoverage !== 'Functional Replacement Cost'
        && _.isEmpty(BL0328Message)) {
        newValidationIssues.push({
            type: 'warning',
            reason: translator(messages.BL0328WarningMessage)
        });
    } else {
        newValidationIssues = _.filter(newValidationIssues, (issue) => issue.reason !== translator(messages.BL0328WarningMessage))
    }

    // BR.PL.0329
    if (yearBuilt >= 1900
        && yearBuilt < 1940
        && hopconstructionOption !== 'Standard'
        && valuationTypeCoverage === 'Functional Replacement Cost'
        && _.isEmpty(BL0329Message)) {
        newValidationIssues.push({
            type: 'warning',
            reason: translator(messages.BL0329WarningMessage)
        });
    } else {
        newValidationIssues = _.filter(newValidationIssues, (issue) => issue.reason !== translator(messages.BL0329WarningMessage))
    }
    return newValidationIssues;
}

function removeStr(value) {
    let res = value;
    _.each(res, (item) => {
        const numberItem = parseInt(item);
        if (_.isNaN(numberItem)) {
            res = _.replace(value, item, '');
        }
    })
    const intRes = parseInt(res);
    if (_.isNaN(intRes)) {
        return '';
    }
    return intRes;
}

function buildDwellingValue(dwellingVM) {
    const burglarAlarmPrefix = residenceConstants.burglarAlarmPrefix;
    const fireAlarmPrefix = residenceConstants.fireAlarmPrefix;
    const homeMonitoringSystemsValues = _.get(dwellingVM, 'value.protection.homeMonitoringSystems');
    const homeMonitoringSystemsValue = _.filter(homeMonitoringSystemsValues, (val) => {
        return !_.startsWith(val, burglarAlarmPrefix) && !_.startsWith(val, fireAlarmPrefix);
    });
    _.set(dwellingVM, 'protection.homeMonitoringSystems', homeMonitoringSystemsValue);

    const burglarAlarmValues = _.filter(homeMonitoringSystemsValues, (val) => {
        return _.startsWith(val, burglarAlarmPrefix);
    });
    const burglarAlarmValue = _.replace(burglarAlarmValues[0], burglarAlarmPrefix, '');
    if (!_.isEmpty(burglarAlarmValue)) {
        _.set(dwellingVM, 'protection.burglarAlarmType', burglarAlarmValue);
    } else {
        _.set(dwellingVM, 'protection.burglarAlarmType', 'none');
    }

    const fireAlarmValues = _.filter(homeMonitoringSystemsValues, (val) => {
        return _.startsWith(val, fireAlarmPrefix);
    });
    const fireAlarmValue = _.replace(fireAlarmValues[0], fireAlarmPrefix, '');
    if (!_.isEmpty(fireAlarmValue)) {
        _.set(dwellingVM, 'protection.fireAlarmType', fireAlarmValue);
    } else {
        _.set(dwellingVM, 'protection.fireAlarmType', 'none');
    }

    const swimmingPoolType = _.get(dwellingVM, 'value.liabilityExposures.swimmingPoolType');
    if (swimmingPoolType === 'none_Ext') {
        _.set(dwellingVM, 'liabilityExposures.approvedFence', null);
        _.set(dwellingVM, 'liabilityExposures.divingBoard', null);
    }
    return dwellingVM.value;
}

function updateSubmissionVMWithoutEmpty(submission, newSubmission, linePath) {
    const dwellingPath = `lobData.${linePath}.coverables.dwelling_Ext`;
    const resSubmission = _.clone(newSubmission);
    const dwelling = _.get(submission, dwellingPath);

    _.forEach(dwelling, (value, key) => {
        const path = `${dwellingPath}.${key}`;
        const newValue = _.get(newSubmission, path);

        if (_.isObjectLike(value)) {
            _.forEach(value, (subVal, subKey) => {
                const subPath = `${dwellingPath}.${key}.${subKey}`;
                const subNewVal = _.get(newSubmission, subPath);

                if (!_.isEmpty(subVal) && _.isEmpty(subNewVal)) {
                    _.set(resSubmission, subPath, _.get(submission, subPath));
                }
            });
        } else if (!_.isEmpty(value) && _.isEmpty(newValue)) {
            _.set(resSubmission, path, _.get(submission, path));
        }
    });

    return resSubmission;
}

function onSolidFuelTypeCell(item, translator) {
    const solidFuelType = _.get(item, 'solidFuelType');
    if (solidFuelType) {
        return translator({ id: `typekey.HOPSolidFuleType_Ext.${solidFuelType}` }) || '';
    }
    return '';
}

function onStoveLocatedCell(item, translator) {
    const stoveLocated = _.get(item, 'stoveLocated');
    if (stoveLocated) {
        return translator({ id: `typekey.StoveLocatedType_Ext.${stoveLocated}` }) || '';
    }
    return '';
}

function getConstructionAvailableValues(availableValues, baseState, translator) {
    const options = availableValues.map((option) => {
        return {
            code: option.code,
            name: translator({
                id: option.name
            })
        };
    });
    let res = [];
    if (baseState === 'AK') {
        res = options.filter((option) => {
            return option.code !== 'LogHomeHandHewn_Ext';
        });
    } else {
        res = _.cloneDeep(options);
    }
    return res;
}

function getIneligibleKnockout(knockoutObj, linePath) {
    const {
        isShowKnockout,
        ineligibleMessage
    } = knockoutObj;
    const fieldOverrideProps = {};
    if (isShowKnockout && linePath === 'homeowners') {
        _.set(fieldOverrideProps, 'tooltip', isShowKnockout)
        _.set(fieldOverrideProps, 'title', 'Ineligible')
        _.set(fieldOverrideProps, 'icon', 'gw-error')
        _.set(fieldOverrideProps, 'showOnInit', isShowKnockout)
        _.set(fieldOverrideProps, 'text', ineligibleMessage)
        // _.set(fieldOverrideProps, 'className', 'wniKnockoutToolTip')
    };
    return fieldOverrideProps;
}

function getConstructionTypeName(constructionType) {
    let constructionTypeName;
    switch (constructionType) {
        case 'DomeHome_Ext':
            constructionTypeName = 'Dome Home';
            break;
        case 'EarthHome_Ext':
            constructionTypeName = 'Earth Home';
            break;
        case 'Manufactured_Ext':
            constructionTypeName = 'Manufactured';
            break;
        case 'MobileHome_Ext':
            constructionTypeName = 'Mobile Home';
            break;
        case 'Modular_Ext':
            constructionTypeName = 'Modular';
            break;
        case 'LogHomeHandHewn_Ext':
            constructionTypeName = 'Log Home - Hand Hewn';
            break;
        default:
            break;
    }
    return constructionTypeName;
}

function getRoofTypeName(roofType) {
    let roofTypeName;
    switch (roofType) {
        case 'Rolled_Ext': 
            roofTypeName = 'Rolled';
            break;
        case 'targravel':
            roofTypeName = 'Tar & Gravel';
            break;
        case 'Rubber_Ext':
            roofTypeName = 'Rubber';
            break;
        default:
            break;
    }
    return roofTypeName;
}

export default {
    residenceConstants,
    RCTFieldsMap,
    getDiffFields,
    getEmptyRCTFields,
    onDiffTableCell,
    getAvailableValues,
    buildDwellingStructure,
    updateBRValidationIssues,
    removeStr,
    buildDwellingValue,
    updateSubmissionVMWithoutEmpty,
    onSolidFuelTypeCell,
    onStoveLocatedCell,
    getConstructionAvailableValues,
    getIneligibleKnockout,
    getConstructionTypeName,
    getRoofTypeName
}
