import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import messages from './DriverAssignmentComponent.messages';
import metadata from './DriverAssignmentComponent.metadata.json5';
import styles from './DriverAssignmentComponent.module.scss';

/**
 * Component used to edit one assignment row
 * @param {object} props
 * @returns {object} React Component
 */
const DriverAssignmentComponent = (props) => {
    const {
        model: assignmentVMs,
        quoteID,
        jobID,
        jobType,
        policyChangeGetAvailableDrivers,
        vehicleNameMap,
        driverNameMap,
        showStudentAway,
        showRatedDriver,
        cancelCallback,
        saveCallback,
        showErrors,
        isRequiredForIssuance
    } = props;

    const ASSIGNMENT_P = 'Primary';
    const ASSIGNMENT_O = 'Occasional';
    const ASSIGNMENT_S = 'StudentAway';

    const { authHeader } = useAuthentication();
    const [showLoader, updateShowLoader] = useState(false);

    const [data, updateData] = useState({});
    const [initOptionData, updateInitOptionData] = useState({});
    const [availableDrivers, updateAvailableDrivers] = useState([]);
    const [excessVehicle, updateExcessVehicle] = useState();
    const [pageIssues, updatePageIssues] = useState([]);
    const translator = useTranslator();

    const combineData = useCallback((assignmentValues) => {
        const { vehicleID, ratedDriverId_Ext: ratedDriverID } = assignmentValues[0];
        const newData = {};
        newData.vehicleID = vehicleID;
        newData.vehicleName = vehicleNameMap[vehicleID];
        newData.ratedDriverName = driverNameMap[ratedDriverID];
        const primaryAssignment = assignmentValues.find((vd) => vd.assignment_Ext === ASSIGNMENT_P);
        newData.primaryDriverID = primaryAssignment && primaryAssignment.driverID
            ? primaryAssignment.driverID.toString() : undefined;
        const occasionalAssignments = assignmentValues
            .filter((vd) => vd.driverID && vd.assignment_Ext === ASSIGNMENT_O);
        newData.occasionalDriverIDs = occasionalAssignments.map((vd) => vd.driverID.toString());

        const studentAwayAssignments = assignmentValues
            .filter((vd) => vd.driverID && vd.assignment_Ext === ASSIGNMENT_S);
        newData.studentAwayDriverIDs = studentAwayAssignments.map((vd) => vd.driverID.toString());

        const primaryOperator = assignmentValues
            .find((vd) => _.isNil(vd.assignment_Ext) && !_.isNil(vd.vehicleID));
        newData.primaryOperatorID = primaryOperator && primaryOperator.driverID
            ? primaryOperator.driverID.toString() : undefined;

        updateData(newData);

        // save selected option, don't update again
        updateInitOptionData({
            primaryDriverID: newData.primaryDriverID,
            occasionalDriverIDs: newData.occasionalDriverIDs,
            studentAwayDriverIDs: newData.studentAwayDriverIDs,
            primaryOperatorID: newData.primaryOperatorID
        });
    }, []);

     // generate array of VehicleDriverDTO to call service
    const divideData = useCallback((newData) => {
        const result = [];
        if (!_.isEmpty(newData.primaryDriverID)) {
            const vd = {};
            vd.vehicleID = newData.vehicleID;
            vd.driverID = newData.primaryDriverID;
            vd.Assignment_Ext = ASSIGNMENT_P;
            result.push(vd);
        }
        if (!_.isEmpty(newData.occasionalDriverIDs)) {
            newData.occasionalDriverIDs.forEach((id) => {
                const vd = {};
                vd.vehicleID = newData.vehicleID;
                vd.driverID = id;
                vd.Assignment_Ext = ASSIGNMENT_O;
                result.push(vd);
            });
        }
        if (!_.isEmpty(newData.studentAwayDriverIDs)) {
            newData.studentAwayDriverIDs.forEach((id) => {
                const vd = {};
                vd.vehicleID = newData.vehicleID;
                vd.driverID = id;
                vd.Assignment_Ext = ASSIGNMENT_S;
                result.push(vd);
            });
        }
        if (!_.isEmpty(newData.primaryOperatorID) && excessVehicle) {
            const vd = {};
            vd.vehicleID = newData.vehicleID;
            vd.driverID = newData.primaryOperatorID;
            result.push(vd);
        }
        return result;
    }, [excessVehicle]);

    useEffect(() => {
        async function fetchAvailableDrivers(assignmentValue) {
            updateShowLoader(true);
            let drivers;
            switch (jobType) {
                case 'Submission': {
                    drivers = await WniQuoteVehicleService.getAvailableDrivers(
                        quoteID,
                        assignmentValue.vehicleID,
                        authHeader
                    );
                    break;
                }
                case 'PolicyChange': {
                    drivers = await policyChangeGetAvailableDrivers(
                        jobID,
                        assignmentValue.vehicleID,
                        authHeader
                    );
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            const isExcessVehicle = assignmentValue.excessVehicle_Ext;
            if (isExcessVehicle) {
                updateExcessVehicle(true);
                const excessDriver = drivers.find(
                    (driver) => driver.fixedId === assignmentValue.driverID
                );
                _.pull(drivers, excessDriver);
            } else {
                updateExcessVehicle(false);
            }
            updateAvailableDrivers(drivers);
            updateShowLoader(false);
        }

        const assignmentValues = assignmentVMs ? assignmentVMs.map((vm) => vm.value) : [];
        if (assignmentValues.length > 0) {
            combineData(assignmentValues);
            fetchAvailableDrivers(assignmentValues[0]);
        }
        const defaultWarnings = [{
            reason: translator(messages.excessVehilce),
            type: 'warning',
        }];
        updatePageIssues(defaultWarnings);
    }, [assignmentVMs, divideData]);

    const onPrimaryDriverChange = useCallback(async (value) => {
        if (data.primaryDriverID === value) {
            return;
        }
        updateShowLoader(true);
        const newData = _.cloneDeep(data);
        newData.primaryDriverID = value;
        updateData(newData);
        await saveCallback(divideData(newData), newData.vehicleID);
        updateShowLoader(false);
    }, [data]);

    const onOccasionalDriversChange = useCallback(async (value) => {
        updateShowLoader(true);
        const newData = _.cloneDeep(data);
        newData.occasionalDriverIDs = value ? value.map((v) => v.code) : [];
        updateData(newData);
        await saveCallback(divideData(newData), newData.vehicleID);
        updateShowLoader(false);
    }, [data]);

    const onStudentAwayDriversChange = useCallback(async (value) => {
        updateShowLoader(true);
        const newData = _.cloneDeep(data);
        newData.studentAwayDriverIDs = value ? value.map((v) => v.code) : [];
        updateData(newData);
        await saveCallback(divideData(newData), newData.vehicleID);
        updateShowLoader(false);
    }, [data]);

    const onPrimaryOperatorChange = useCallback(async (value) => {
        if (data.primaryOperatorID === value) {
            return;
        }
        updateShowLoader(true);
        const newData = _.cloneDeep(data);
        newData.primaryOperatorID = value;
        updateData(newData);
        await saveCallback(divideData(newData), newData.vehicleID);
        updateShowLoader(false);
    }, [data]);

    // const onSave = useCallback(async () => {
    //     updateShowLoader(true);
    //     const newData = _.cloneDeep(data);
    //     if (excessVehicle && isRequiredForIssuance && _.isEmpty(newData.primaryOperatorID)) {
    //         const defaultWarnings = [{
    //             reason: translator(messages.excessVehilce),
    //             type: 'error',
    //         }];
    //         updatePageIssues(defaultWarnings);
    //         updateShowLoader(false);
    //         return;
    //     }
    //     await saveCallback(divideData(newData), newData.vehicleID);
    //     updateShowLoader(false);
    //     cancelCallback();
    // }, [cancelCallback, data, divideData, excessVehicle, isRequiredForIssuance, saveCallback, translator]);

    const getDriverOptions = useCallback(
        (selectedDriverIDs = [], isStudentAway = false) => {
            let availableDriverCopy = _.cloneDeep(availableDrivers);
            if (!excessVehicle) {
                availableDriverCopy = availableDrivers.filter(
                    (driver) => driver.isStudentAway === isStudentAway
                );
            }
            const unassigned = availableDriverCopy.map((driver) => {
                return {
                    code: driver.fixedId.toString(),
                    name: driverNameMap[driver.fixedId],
                };
            });

            if (selectedDriverIDs.length > 0) {
                return selectedDriverIDs
                    .filter((id) => !_.isEmpty(id))
                    .map((id) => {
                        return {
                            code: id.toString(),
                            name: driverNameMap[id],
                        };
                    })
                    .concat(unassigned);
            }
            return unassigned;
        },
        [availableDrivers, driverNameMap]
    );

    const getOccasionalDriversValue = useCallback(() => {
        if (_.isEmpty(data.occasionalDriverIDs)) {
            return [];
        }
        return data.occasionalDriverIDs.map((id) => {
            return {
                code: id,
                name: driverNameMap[id],
            };
        });
    }, [data]);

    const getStudentAwayDriversValue = useCallback(() => {
        if (_.isEmpty(data.studentAwayDriverIDs)) {
            return [];
        }
        return data.studentAwayDriverIDs.map((id) => {
            return {
                code: id,
                name: driverNameMap[id],
            };
        });
    }, [data]);

    const primaryDriverOptions = getDriverOptions([initOptionData.primaryDriverID], false);
    const occasionalDriversOptions = getDriverOptions(initOptionData.occasionalDriverIDs, false);
    const studentAwayOptions = getDriverOptions([initOptionData.studentAwayDriverIDs], true);
    const primaryOperatorOptions = getDriverOptions([initOptionData.primaryOperatorID], false);

    //----------------------------------
    const overrideProps = {
        '@all': {},
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: pageIssues,
            visible: excessVehicle,
            scrollToIssues: true,
        },
        vehicleName: {
            value: data.vehicleName
        },
        primaryDriver: {
            availableValues: primaryDriverOptions,
            value: data.primaryDriverID,
            onValueChange: onPrimaryDriverChange,
            visible: !excessVehicle,
        },
        occasionalDrivers: {
            availableValues: occasionalDriversOptions,
            value: getOccasionalDriversValue(),
            onValueChange: onOccasionalDriversChange,
            visible: !excessVehicle,
        },
        studentAwayDrivers: {
            availableValues: studentAwayOptions,
            value: getStudentAwayDriversValue(),
            onValueChange: onStudentAwayDriversChange,
            visible: showStudentAway && !excessVehicle,
        },
        primaryOperator: {
            availableValues: primaryOperatorOptions,
            value: data.primaryOperatorID,
            onValueChange: onPrimaryOperatorChange,
            visible: excessVehicle,
            required: excessVehicle && isRequiredForIssuance
        },
        ratedDriverName: {
            value: data.ratedDriverName,
            visible: showRatedDriver && !excessVehicle,
        },
        cancelButton: {
            onClick: cancelCallback
        },
        saveButton: {
            onClick: cancelCallback
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent
        },
    };
    //---------
    if (showLoader) {
        return <Loader showLoader />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={data}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
};

DriverAssignmentComponent.propTypes = {
    model: PropTypes.shape([]),
    showErrors: PropTypes.bool,
    quoteID: PropTypes.string,
    jobID: PropTypes.string,
    jobType: PropTypes.string.isRequired,
    vehicleNameMap: PropTypes.shape({}).isRequired,
    driverNameMap: PropTypes.shape({}).isRequired,
    cancelCallback: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
    policyChangeGetAvailableDrivers: PropTypes.func.isRequired,
    showStudentAway: PropTypes.bool.isRequired,
    showRatedDriver: PropTypes.bool.isRequired,
    isRequiredForIssuance: PropTypes.bool.isRequired
};

DriverAssignmentComponent.defaultProps = {
    model: {},
    quoteID: '',
    jobID: '',
    showErrors: false,
};

export default DriverAssignmentComponent;
