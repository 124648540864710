import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, ToggleField } from '@jutro/components';
import classNames from 'classnames';
import { DataTable, DisplayColumn, ActionColumn } from '@jutro/legacy/datatable';
import { WindowUtil } from 'wni-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateField, WniDropdownSelect } from 'wni-common-base-components';
// import { WniDropdownWithIneligibleTooltip } from '../FieldWithIneligibleTooltip/FieldWithIneligibleTooltip'
import CovPaidComponent from './CovPaidComponent/CovPaidComponent';
import metadata from './IncidentPopupComponent.metadata.json5';
import messages from './IncidentPopupComponent.messages';

import { Button, InputField, CurrencyField, DropdownSelectField, InputNumberField } from '@jutro/legacy/components';

function IncidentPopupComponent(props) {
    const {
        title,
        actionType,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        modalVM,
        viewModelService,
        modelProps,
        drivers,
        policyDrivers,
        displayStatus,
        quotedId,
        comingType,
        wniClueService,
        vendorDataFlag,
        isRequiredForIssuance,
        effectiveDate,
        productName,
        afterWriteValue,
        filterDisputeDropList,
        useAuthenticationData,
        useDependenciesData,
        translator,
        handleClauseOfLossChange,
    } = props;

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation,
        invalidFields
    } = useValidation('IncidentPopupComponent');
    const { type, labelMap: labelMapList } = modelProps;
    const [newModalVM, updateNewModalVM] = useState(modalVM);
    const [showErrors, updateShowErrors] = useState(false);
    const [labelMap, updateLabelMap] = useState(labelMapList);
    const [secondWinVisible, updateSecondWinVisible] = useState(false);
    const [paidCovModalVM, updatepaidCovModalVM] = useState({});
    const [newPaidCovRecords, updateNewPaidCovRecords] = useState(null);
    const [priorCarrierMessage, updatePriorCarrierMessage] = useState(false);
    const bodyAutoFocus = false
    const specifiedOptions = {
        drivers: drivers,
        policydrivers: policyDrivers
    };

        /**
     * Checks if the PriorCarrierDispute Feature is enabled 
     * TODO, Remove function and references when the PriorCarrierDispute feature is retired
     * @returns {Boolean} if the PriorCarrierDispute feature is enabled
     */
    function checkPriorCarrierDisputeisEnabled() {
        const featureList =  _.get(useAuthenticationData, 'authUserData.businessData_Ext.featureToggles', [])
        let priorCarrierDisputeEnabled = false;
        // check if PriorCarrierDispute is enabled by checking if it exists within featureList
        if(featureList.some((elt) => elt.code === 'PriorCarrierDispute')){
            priorCarrierDisputeEnabled = true;
        }
        return priorCarrierDisputeEnabled;
    }
    const priorCarrierfeatureEnabled = checkPriorCarrierDisputeisEnabled();
    const causeOfLossWarningStyle = classNames('wniKnockoutToolTip');

    const setYearsBack = (numOfYears) => {
        const backDate = new Date();
        backDate.setFullYear(backDate.getFullYear() - numOfYears);
        return backDate;
    };

    const setYearsForward = (numOfYears) => {
        const forwardDate = new Date();
        forwardDate.setFullYear(forwardDate.getFullYear() + numOfYears);
        return forwardDate;
    };

    useEffect(() => {
        if(priorCarrierfeatureEnabled && vendorDataFlag && title === 'Add Prior Carrier Entry' ){
            updatePriorCarrierMessage(true);
        }
    }, [ title, priorCarrierfeatureEnabled, vendorDataFlag]);

    const getReadOnlyStatus = () => {
        if (type.toLowerCase() === 'view') {
            return true;
        }
        if (type.toLowerCase() === 'edit') {
            return false;
        }
    }

    const validValue = (val) => {
        /**
         * check the amount currency value
         */
        if (!_.isNil(val.currency) && (_.isNil(val.value.amount) || (val.value.amount < 0))) {
            return false;
        }
        // for chooseing boolean field, fix user chooseing 'false' button but blocked by validation
        if (!_.isNil(val.value)) {
            return true;
        }
        return false;
    };
    const getLabelKey = useCallback(() => {
        return Object.keys(labelMap);
    }, [labelMap]);

    const validFileds = useCallback(() => {
        const labelKey = getLabelKey();
        const validList = [];
        const validKey = [];
        labelKey.map((key) => {
            const eachDto = newModalVM[key];
            const { driverOptionPath } = labelMap[key];
            if (!_.isNil(driverOptionPath)) {
                let defaultDriverValue = '';
                if (driverOptionPath === 'drivers') {
                    defaultDriverValue = drivers.find((item) => item.code === eachDto.value);
                    if (_.isEmpty(defaultDriverValue)) {
                        eachDto.value = '';
                    }
                    validList.push(eachDto);
                    validKey.push(key);
                }
            }
            // for user enter clue status filed
            if (eachDto && isRequiredForIssuance && key === 'status' && (title === 'View Prior Loss Entry' || title === 'Edit Prior Loss Entry')) {
                validList.push(eachDto);
                validKey.push(key);
            }

            if (eachDto && eachDto.aspects.required) {
                validList.push(eachDto);
                validKey.push(key);
            }
            return {
                validList,
                validKey
            };
        });
        return {
            validList,
            validKey
        };
    }, [drivers, getLabelKey, isRequiredForIssuance, labelMap, newModalVM, title]);
    const isModalValid = useCallback(() => {
        const { validList } = validFileds();
        if (validList.every(validValue)) {
            return true;
        }
        return false;
    }, [validFileds]);

    useEffect(() => {
        registerComponentValidation(isModalValid);
    }, [registerComponentValidation, isModalValid]);

    const writeValue = useCallback(
        async (value, path) => {
            if (labelMap[path].noPriorLinkages) {
                const { noPriorLinkages } = labelMap[path];
                if (_.isArray(noPriorLinkages)) {
                    _.forEach(noPriorLinkages, (linkageData) => {
                        const matchedFlag = value === linkageData.linkCode;
                        if (matchedFlag) {
                            labelMap[linkageData.linkPath].unVisible = true;
                        } else {
                            labelMap[linkageData.linkPath].unVisible = false;
                        }
                    });
                    updateLabelMap(labelMap);
                }
            }
            if (labelMap[path].linkage) {
                const { linkage } = labelMap[path];
                if (_.isArray(linkage)) {
                    _.forEach(linkage, (linkageData) => {
                        // replaced old condition since applying to when one field have more than one dependencies
                        const matchedFlag = linkageData.linkCode.includes(value);
                        if (matchedFlag) {
                            labelMap[linkageData.linkPath].unVisible = false;
                        } else {
                            labelMap[linkageData.linkPath].unVisible = true;
                        }
                    });
                    updateLabelMap(labelMap);
                } else {
                    if (value === labelMap[path].linkage.linkCode) {
                        labelMap[labelMap[path].linkage.linkPath].unVisible = false;
                    } else {
                        labelMap[labelMap[path].linkage.linkPath].unVisible = true;
                    }
                    updateLabelMap(labelMap);
                }
            }
            const getNewModalVM = viewModelService.clone(newModalVM);
            if (_.isObject(value) && !_.isNil(value.code)) {
                _.set(getNewModalVM, path, value.code);
            } else {
                _.set(getNewModalVM, path, value);
            }
            /**
             * CLUE enter category switch a new value then description field set empty initially.
             */
            if (labelMap[path].refreshOptionPath) {
                if (!_.isUndefined(newModalVM[path].value) && value !== newModalVM[path].value.code) {
                    _.set(getNewModalVM, labelMap[path].refreshOptionPath, '');
                }
            }
            handleClauseOfLossChange({getNewModalVM, labelMap, path});
            await afterWriteValue({getNewModalVM, labelMap, path});
            updateNewModalVM(getNewModalVM);
        },
        [afterWriteValue, labelMap, newModalVM, viewModelService, handleClauseOfLossChange]
    );

    useEffect(() => {
        const { validKey } = validFileds();
        const disregardField = getLabelKey().filter((item) => !validKey.includes(item));
        disregardFieldValidation(disregardField);
    }, [disregardFieldValidation, getLabelKey, newModalVM, validFileds]);

    const getDropDownValues = (typelist) => {
        return typelist.map((item) => {
            const availableValuesList = {
                code: item.code,
                name: translator({ id: item.name })
            };
            return availableValuesList;
        });
    };

    const getCategoryCell = (row, index, prop) => {
        if (!_.isNil(row.covCategory)) {
            // eslint-disable-next-line react/prop-types
            return translator({ id: `${prop.typekey}.${row.covCategory}` });
        }
        return '';
    };

    const getDescriptionCell = (row, index, prop) => {
        if (!_.isNil(row.covDescription)) {
            // eslint-disable-next-line react/prop-types
            return translator({ id: `${prop.typekey}.${row.covDescription}` });
        }
        return '';
    };

    const getOtherColDetailsCell = (row, index, prop) => {
        if (!_.isNil(row.covOtherThanCollisionDetails)) {
            // eslint-disable-next-line react/prop-types
            return translator({ id: `${prop.typekey}.${row.covOtherThanCollisionDetails}` });
        }
        return '';
    };

    const getPaidAmountCell = (row, rowIndex) => {
        if (!_.isNil(row.covPaidAmount)) {
            const covPaid = row.covPaidAmount;
            if (!_.isNil(covPaid)) {
                return (
                    <CurrencyField
                        id={`paidAmount_${rowIndex}`}
                        value={covPaid}
                        dataType="object"
                        readOnly
                        hideLabel
                    />
                );
            }
        }
        return '';
    };

    const openOtherThanColPopup = (row) => {
        if (!_.isNil(row.covDescription)) {
            // eslint-disable-next-line react/prop-types
            const rowModalVM = viewModelService.create(row, 'pc', 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniClueReportCovDto_Ext');
            updatepaidCovModalVM(rowModalVM);
            updateSecondWinVisible(true);
        }
    };

    const renderActionCell = (row) => {
        if (modelProps.type !== 'View') {
            if (!_.isNil(row.covDescription) && row.covDescription.toLowerCase() === 'comprehensive') {
                return (
                    <Button
                        id="edit"
                        type="text"
                        className="p-0"
                        onClick={() => openOtherThanColPopup(row)}
                    >
                        Edit
                    </Button>
                );
            }
        }
        return null;
    };

    const renderControl = (rendorProps, v) => {
        const {
            path, label, aspects: { inputCtrlType, availableValues, required },
            index, unEditable, visible, disabled, tooltip, radioValues, isTypeAlphabet,
            initialFiled, triggerFiled, initialFilterValues, checkvisible, filterVal, checkRatedStatus, hidden, defineCtrlType, dynamicRequired
        } = rendorProps;

        if (hidden) {
            return;
        }
        /**
         * ears needn't to display dispute column value in MVR popup form.
         */
        if (!_.isNil(newModalVM.value.earsFlag) && newModalVM.value.earsFlag && path === 'dispute') {
            return;
        }
        let rendorDom; let availableList;
        let value; let showCauseOfLossKnockout;
        if (!_.isNil(v) && !_.isNil(v.code)) {
            value = availableValues.find((item) => item.code === v.code);
        } else {
            value = v;
        }

        const causeOfLossComponentProps = {};
        if (path === 'causeOfLoss' && !_.isNil(effectiveDate) && !_.isNil(newModalVM.value.claimDate)) {
            const isClaimDateWithinThreeYears = moment(effectiveDate).diff(newModalVM.value.claimDate, 'years', true) <= 3;
            showCauseOfLossKnockout = productName === 'homeowners' && isClaimDateWithinThreeYears && !_.isNil(v) && !_.isNil(v.code) 
                && (value.code === 'LiabilityAllOther' || value.code === 'Fire');
        };
        if(showCauseOfLossKnockout){
            const lossCause = value.code === 'LiabilityAllOther' ? 'Liability (All Other)' : value.code;
            _.set(causeOfLossComponentProps, 'tooltip', showCauseOfLossKnockout)
            _.set(causeOfLossComponentProps, 'title', 'Ineligible')
            _.set(causeOfLossComponentProps, 'icon', 'gw-info')
            _.set(causeOfLossComponentProps, 'showOnInit', showCauseOfLossKnockout)
            _.set(causeOfLossComponentProps, 'text',translator(messages.incidentPagecauseOfLossIneligible,{lossCause: lossCause}))
        };
        const defaultRadioValues = [
            {
                code: 'true',
                name: {
                    id: 'quote.clur.userAdd.Accept',
                    defaultMessage: 'Accept'
                },
            },
            {
                code: 'false',
                name: {
                    id: 'quote.clur.userAdd.Decline',
                    defaultMessage: 'Decline'
                }
            }
        ];
        /**
         *  Converted Data like 500000/500000 to 500,000/500,000
         */
        if (path === 'bipriorCarreierLimits_Ext') {
            if (!_.isUndefined(value) && value.indexOf(',') < 0 && value.indexOf('/') > 0) {
                const firstValue = value.substring(0, value.indexOf('/'));
                const lastValue = value.substring(value.indexOf('/') + 1, value.length);
                if (!_.isUndefined(firstValue) && !_.isUndefined(lastValue)) {
                    // eslint-disable-next-line radix
                    value = `${parseInt(firstValue).toLocaleString()}/${parseInt(lastValue).toLocaleString()}`;
                }
            }
            // To check if there is a new addionial pl-rater source value then add it into option list to display.
            let isNewValue = true;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < initialFilterValues.length; i++) {
                if (value === initialFilterValues[i].code) {
                    isNewValue = false;
                    break;
                }
            }
            if (isNewValue) {
                initialFilterValues.push({ code: value, name: value });
            }
        }
        filterDisputeDropList(path, availableValues);
        if (!_.isNil(defineCtrlType)) {
            if (!_.isNil(initialFilterValues)) {
                availableList = initialFilterValues;
            } else {
                availableList = getDropDownValues(initialFilterValues);
            }
            rendorDom = (
                <DropdownSelectField
                    id={`multiSelect${index}`}
                    dataType="object"
                    type="field"
                    showRequired
                    singleSelect
                    required={required}
                    onValueChange={writeValue}
                    labelPosition="left"
                    label={label}
                    path={path}
                    visible={visible}
                    placeholder={required ? '-- Required for Quote --' : ''}
                    availableValues={availableList}
                    value={value}
                    readOnly={getReadOnlyStatus() || unEditable}
                    disabled={disabled}
                    showErrors={showErrors}
                    tooltip={tooltip}
                    onValidationChange={onValidate}
                />
            );
        } else {
            switch (inputCtrlType) {
                case 'date':
                    rendorDom = (
                        <WniDateField
                            id={`date${index}`}
                            required={required}
                            type="field"
                            showRequired
                            labelPosition="left"
                            path={path}
                            label={label}
                            showOptional={false}
                            visible={visible}
                            value={value}
                            readOnly={getReadOnlyStatus() || unEditable}
                            disabled={disabled}
                            showErrors={showErrors}
                            onValidationChange={onValidate}
                        />
                    );
                    break;
                case 'typelist':
                    availableList = getDropDownValues(availableValues);
                    if (isTypeAlphabet) {
                        // const Typevalue = value ? {
                        //     code: value.code,
                        //     name: translator({ id: value.name })
                        // } : value;
                        rendorDom = (
                            <DropdownSelectField
                                id={`multiSelect${index}`}
                                type="field"
                                showRequired
                                searchable
                                required={required}
                                onValueChange={writeValue}
                                labelPosition="left"
                                label={label}
                                path={path}
                                visible={visible}
                                placeholder={required ? '-- Required for Quote --' : ''}
                                availableValues={availableList}
                                value={value}
                                readOnly={getReadOnlyStatus() || unEditable}
                                disabled={disabled}
                                showErrors={showErrors}
                                tooltip={tooltip}
                                onValidationChange={onValidate}
                            />
                        );
                    } else {
                        const dropdownProps = {
                            id: `typelist${index}`,
                            type: 'field',
                            showRequired: path !== "whatCauseOfLoss",
                            required: required,
                            labelPosition: 'left',
                            label: label,
                            path: path,
                            visible: visible,
                            searchable: true,
                            placeholder: required ? '-- Required for Quote --' : '',
                            onValueChange: writeValue,
                            availableValues: availableList,
                            value: value,
                            readOnly: getReadOnlyStatus() || unEditable,
                            disabled: disabled,
                            showErrors: showErrors,
                            tooltip: tooltip,
                            onValidationChange: onValidate,
                            ineligibleTooptip:causeOfLossComponentProps,
                        };
                        if (!_.isNil(dynamicRequired) && dynamicRequired) {
                            dropdownProps.required = isRequiredForIssuance;
                        }
                        if (!_.isNil(initialFiled) && initialFiled) {
                            dropdownProps.availableValues = initialFilterValues;
                            const linkField = _.get(newModalVM, triggerFiled);
                            if (!_.isNil(triggerFiled) && !_.isNil(linkField.value)) {
                                const afterTriggerValues = getDropDownValues(availableValues);
                                dropdownProps.availableValues = afterTriggerValues;
                            }
                        }
                        if (!_.isNil(checkvisible) && checkvisible) {
                            if (_.isNil(value)) {
                                return;
                            }
                        }
                        if (!_.isNil(checkRatedStatus) && checkRatedStatus && displayStatus === 'Draft') {
                            return;
                        }
                        if (!_.isNil(filterVal)) {
                            const newAvailableValues = availableValues.filter((item) => {
                                return item.code !== filterVal;
                            }).map((item) => {
                                return {
                                    code: item.code,
                                    name: translator({ id: item.name })
                                };
                            });
                            dropdownProps.availableValues = newAvailableValues;
                        }
                        // HO For Fire/Water category loss cause, mapping different 'whatCauseOfLoss' select dropdown list 
                        if (path === 'whatCauseOfLoss' && !_.isNil(newModalVM.value)) {
                            const whatCauseOfLossListForFire = _.get(newModalVM, 'whatCauseOfLoss.aspects.availableValues[0].typelist.filters[0].codes', []);
                            const whatCauseOfLossListForWater = _.get(newModalVM, 'whatCauseOfLoss.aspects.availableValues[0].typelist.filters[1].codes', []);
                            if (newModalVM.value.causeOfLoss === 'Fire') {
                                dropdownProps.availableValues = getDropDownValues(whatCauseOfLossListForFire);
                            } else if (['WaterDamage', 'WeatherRelatedWater', 'AccidentalDischargeLeakage',
                                'ApplianceRelatedWater', 'FreezingWater', 'WeatherRelatedWater'].includes(newModalVM.value.causeOfLoss)) {
                                dropdownProps.availableValues = getDropDownValues(whatCauseOfLossListForWater);
                            };
                        }
                        rendorDom = <WniDropdownSelect {...dropdownProps}/>;

                    }
                    break;
                case 'text':
                    rendorDom = (
                        <InputField
                            id={`text${index}`}
                            required={required}
                            type="field"
                            showRequired={path === "mitigationSteps" ? false : required}
                            onValueChange={writeValue}
                            labelPosition="left"
                            path={path}
                            label={label}
                            visible={visible}
                            value={value}
                            readOnly={getReadOnlyStatus() || unEditable}
                            disabled={disabled}
                            showErrors={showErrors || path === "mitigationSteps" && required}
                            tooltip={tooltip}
                            placeholder={required ? '-- Required for Quote --' : ''}
                            validationMessages={(value === ''|| _.isNil(value)) && path === "mitigationSteps" && required ? [messages.additionalCommentValidationMessage]: null}
                            onValidationChange={onValidate}
                        />
                    );
                    break;
                case 'boolean':
                    rendorDom = (
                        <ToggleField
                            availableValues={radioValues || defaultRadioValues}
                            id={`radiobutton${index}`}
                            className="radioBtn-horizontal"
                            type="field"
                            showRequired
                            required={required}
                            label={label}
                            path={path}
                            visible={visible}
                            labelPosition="left"
                            readOnly={getReadOnlyStatus() || unEditable}
                            disabled={disabled}
                            value={value}
                            onValueChange={writeValue}
                            showErrors={showErrors}
                            onValidationChange={onValidate}
                        />
                    );
                    break;
                case 'number':
                    rendorDom = (
                        <InputNumberField
                            id={`inputNumberFiled${index}`}
                            type="field"
                            showRequired
                            required={required}
                            label={label}
                            path={path}
                            visible={visible}
                            labelPosition="left"
                            onValueChange={writeValue}
                            readOnly={getReadOnlyStatus() || unEditable}
                            disabled={disabled}
                            value={value}
                            showErrors={showErrors}
                            tooltip={tooltip}
                            placeholder={required ? '-- Required for Quote --' : ''}
                            onValidationChange={onValidate}
                        />
                    );
                    break;
                case 'collection':
                    // eslint-disable-next-line no-case-declarations
                    let collectionValues = null;
                    if (!_.isEmpty(newPaidCovRecords)) {
                        collectionValues = newPaidCovRecords;
                        _.set(newModalVM, 'covPaid', collectionValues);
                    } else {
                        collectionValues = v;
                    }
                    rendorDom = (
                        <div className="table-wrapper">
                            <DataTable
                                data={collectionValues}
                                id="covCategoryPaidTable"
                                expandable={false}
                                showSearch={false}
                                showPagination
                                defaultPageSize={10}
                            >
                                <DisplayColumn
                                    header="Category"
                                    columnProportion={1.2}
                                    id="covCategory"
                                    path="covCategory"
                                    typekey="typekey.Category_Ext"
                                    renderCell={getCategoryCell}
                                    textAlign="left"
                                />
                                <DisplayColumn
                                    header="Description"
                                    columnProportion={1.6}
                                    id="covDescription"
                                    path="covDescription"
                                    typekey="typekey.ClueDescription_Ext"
                                    renderCell={getDescriptionCell}
                                    textAlign="left"
                                />
                                <DisplayColumn
                                    header="Other Than Collision Details"
                                    columnProportion={2}
                                    id="covOtherThanCollisionDetails"
                                    path="covOtherThanCollisionDetails"
                                    typekey="typekey.OtherThanCollisionDetails"
                                    renderCell={getOtherColDetailsCell}
                                    textAlign="left"
                                />
                                <DisplayColumn
                                    header="Coverages Paid Amount"
                                    columnProportion={2}
                                    id="covPaidAmount"
                                    path="covPaidAmount"
                                    renderCell={getPaidAmountCell}
                                    textAlign="left"
                                />
                                <ActionColumn
                                    id="actions"
                                    textAlign="right"
                                    width={55}
                                    renderCell={renderActionCell}
                                />
                            </DataTable>
                        </div>
                    );
                    break;
                default:
                    rendorDom = (
                        <InputField
                            id={`text${index}`}
                            required={required}
                            type="field"
                            showRequired
                            onValueChange={writeValue}
                            labelPosition="left"
                            autoComplete
                            path={path}
                            label={label}
                            value={value}
                            visible={visible}
                            readOnly={getReadOnlyStatus() || unEditable}
                            disabled={disabled}
                            showErrors={showErrors}
                            tooltip={tooltip}
                            onValidationChange={onValidate}
                            placeholder={required ? '-- Required for Quote --' : ''}
                        />
                    );
                    break;
            }
        }
        // eslint-disable-next-line consistent-return
        return rendorDom;
    };

    const rendorDateCtrl = (rendorProps, value, maxDate, minDate) => {
        const {
            path, label, index, aspects, unEditable, visible, disabled,
        } = rendorProps;
        return (
            <WniDateField
                id={`date${index}`}
                type="field"
                showRequired
                required={aspects.required}
                onValueChange={writeValue}
                labelPosition="left"
                path={path}
                label={label}
                showOptional={false}
                value={value}
                visible={visible}
                readOnly={getReadOnlyStatus() || unEditable}
                disabled={disabled}
                showErrors={showErrors}
                maxDate={maxDate}
                minDate={minDate}
                onValidationChange={onValidate}
            />
        );
    };
    const rendorCurrencyCtrl = (rendorProps, value) => {
        const {
            path, label, index, aspects, unEditable, visible, disabled, tooltip
        } = rendorProps;
        return (
            <CurrencyField
                defaultCurrency="USD"
                labelPosition="left"
                type="field"
                showRequired
                id={`currency${index}`}
                required={aspects.required}
                path={path}
                label={label}
                onValueChange={writeValue}
                visible={visible}
                readOnly={getReadOnlyStatus() || unEditable}
                value={value}
                disabled={disabled}
                showErrors={showErrors}
                tooltip={tooltip}
                onValidationChange={onValidate}
                validationMessages={aspects.validationMessage? [aspects.validationMessage]: null}
                placeholder={aspects.required ? '-- Required for Quote --' : ''}
            />
        );
    };
    const rendorSpecifiedDropdownCtrl = (rendorProps, value) => {
        const {
            path, options, label, aspects, unEditable, visible, disabled, tooltip
        } = rendorProps;
        let defaultValue = value;
        if (value) {
            defaultValue = options.find((item) => item.code === value);
        }
        return (
            <DropdownSelectField
                id={`typelist${path}`}
                type="field"
                showRequired
                onValueChange={writeValue}
                required={aspects.required}
                labelPosition="left"
                label={label}
                path={path}
                visible={visible}
                searchable
                placeholder={aspects.required ? '-- Required for Quote --' : ''}
                availableValues={options}
                value={defaultValue}
                readOnly={getReadOnlyStatus() || unEditable}
                disabled={disabled}
                showErrors={showErrors}
                tooltip={tooltip}
                onValidationChange={onValidate}
            />
        );
    };

    const onRenderContentFn = () => {
        const labelKey = getLabelKey();
        return (
            <ul>
                {
                    labelKey.map((key, index) => {
                        const eachDto = newModalVM[key];
                        const eachValue = _.get(newModalVM, `${key}.value`);
                        const labelMsg = labelMap[key].label.defaultMessage
                            ? labelMap[key].label.defaultMessage : labelMap[key].label;
                        const { driverOptionPath } = labelMap[key];
                        if (!_.isNil(driverOptionPath)) {
                            const rendorProps = {
                                path: key,
                                options: specifiedOptions[`${driverOptionPath}`],
                                label: labelMsg,
                                aspects: eachDto.aspects,
                                unEditable: labelMap[key].unEditable,
                                visible: !labelMap[key].unVisible,
                                disabled: labelMap[key].disabled,
                                tooltip: labelMap[key].tooltip,
                                radioValues: labelMap[key].radioValues,
                                isTypeAlphabet: labelMap[key].isTypeAlphabet
                            };
                            return (
                                <li id={key}>
                                    {rendorSpecifiedDropdownCtrl(rendorProps, eachValue)}
                                </li>
                            );
                        }
                        if (eachDto) {
                            if (title === 'Edit MVR Reports' || title === 'Edit Prior Loss Reports' || title === 'Edit Prior Carrier Reports' ||
                                title === 'View MVR Reports' || title === 'View Prior Loss Reports' || title === 'View Prior Carrier Reports'
                            ) {
                                /**
                                 * when load the data from backend and check the dispute is 'Other'
                                 * then the explanationBox should be visible
                                 */
                                if (key === 'explanationBox' && !_.isNil(newModalVM.dispute.value) && newModalVM.dispute.value.code === labelMap.dispute.linkage.linkCode) {
                                    labelMap[key].unVisible = false;
                                }
                                /**
                                 * when load the data from backend and check the dispute is not 'Other'
                                 * then the explanationBox should not be visible
                                 */
                                if (key === 'explanationBox' && !_.isNil(newModalVM.dispute.value) && newModalVM.dispute.value.code !== labelMap.dispute.linkage.linkCode) {
                                    labelMap[key].unVisible = true;
                                }
                                /**
                                 * when load the data from backend and check the dispute is undefined
                                 * then the explanationBox should not be visible
                                 */
                                if (key === 'explanationBox' && _.isNil(newModalVM.dispute.value)) {
                                    labelMap[key].unVisible = true;
                                }
                            }

                            /**
                             * when Carrier is empty then hidden the Other Carrier Name field
                             * when Carrier is 'Other' then display the Other Carrier Name field
                             * when Carrier is 'No Prior' then display the reason field
                             */
                            if (title === 'Add Prior Carrier Entry' || title === 'Edit Prior Carrier Entry' || title === 'View Prior Carrier Entry') {
                                if (_.isUndefined(newModalVM.carrier_Ext.value)) {
                                    if (key === 'otherCarrierName_Ext' || key === 'reasonForNoInsurance_Ext') {
                                        labelMap[key].unVisible = true;
                                    } else {
                                        labelMap[key].unVisible = false;
                                    }
                                }
                                if (key === 'otherCarrierName_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code !== 'Other') {
                                    labelMap[key].unVisible = true;
                                } else if (key === 'otherCarrierName_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code === 'Other') {
                                    labelMap[key].unVisible = false;
                                } else if (key === 'reasonForNoInsurance_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code !== 'NoPrior') {
                                    labelMap[key].unVisible = true;
                                } else if (key === 'reasonForNoInsurance_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code === 'NoPrior') {
                                    labelMap[key].unVisible = false;
                                } else if (key !== 'otherCarrierName_Ext' && key !== 'reasonForNoInsurance_Ext' && key !== 'carrier_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code !== 'NoPrior') {
                                    labelMap[key].unVisible = false;
                                } else if (key !== 'reasonForNoInsurance_Ext' && key !== 'carrier_Ext' && !_.isEmpty(newModalVM.carrier_Ext.value) && newModalVM.carrier_Ext.value.code === 'NoPrior') {
                                    labelMap[key].unVisible = true;
                                }
                            }
                            /**
                             * HO when CauseOfLoss is empty then hidden the whatCauseOfLoss field
                             * HO when CauseOfLoss is 'Fire'/'Water Demage' then display the whatCauseOfLoss field
                             */
                            //  if (!_.isNil(newModalVM.causeOfLoss) && (title === 'Add Prior Loss Entry' || title === 'Edit Prior Loss Entry' || title === 'View Prior Loss Entry' || title === 'Edit Prior Loss Reports' || title === 'View Prior Loss Reports')) {
                            //     if (_.isUndefined(newModalVM.causeOfLoss.value)) {
                            //         if (key === 'whatCauseOfLoss') {
                            //             labelMap[key].unVisible = true;
                            //         } else {
                            //             labelMap[key].unVisible = false;
                            //         }
                            //     }
                            //     if ((key === 'whatCauseOfLoss') && !_.isEmpty(newModalVM.causeOfLoss.value) && newModalVM.causeOfLoss.value.code !== 'Fire' &&  newModalVM.causeOfLoss.value.code !=='WaterDamage') {
                            //         labelMap[key].unVisible = true;
                            //     } else if ((key === 'whatCauseOfLoss') && !_.isEmpty(newModalVM.causeOfLoss.value) && (newModalVM.causeOfLoss.value.code === 'Fire' || newModalVM.causeOfLoss.value.code ==='WaterDamage')) {
                            //         labelMap[key].unVisible = false;
                            //     }
                            // }                            
                            /**
                             * if the visible is false then needn't rendor the component.
                             */
                            if (labelMap[key].unVisible === true) {
                                return false;
                            }
                            const rendorProps = {
                                path: key,
                                label: labelMsg,
                                aspects: eachDto.aspects,
                                index: index,
                                unEditable: labelMap[key].unEditable,
                                visible: !labelMap[key].unVisible,
                                default: labelMap[key].default,
                                disabled: labelMap[key].disabled,
                                tooltip: labelMap[key].tooltip,
                                radioValues: labelMap[key].radioValues,
                                isTypeAlphabet: labelMap[key].isTypeAlphabet,
                                initialFiled: labelMap[key].initialFiled,
                                triggerFiled: labelMap[key].triggerFiled,
                                initialFilterValues: labelMap[key].initialFilterValues,
                                checkvisible: labelMap[key].checkvisible,
                                checkRatedStatus: labelMap[key].checkRatedStatus,
                                filterVal: labelMap[key].filterVal,
                                hidden: labelMap[key].hidden,
                                defineCtrlType: labelMap[key].defineCtrlType,
                                dynamicRequired: labelMap[key].dynamicRequired
                            };
                            /**
                             * Deal with the scenario is when the source is chips then hidden the description filed but set one value into description filed because of its required
                             * Deal with the scenario is when the source is manual then hidden the descriptionForChips filed but set one value into descriptionForChips filed because of its required
                             * Deal with the scenario is when the action is adding a new mvr user enter then hidden the descriptionForChips filed but set one value into descriptionForChips filed because of its required
                             */
                            if (!_.isNil(newModalVM.value.source) && newModalVM.value.source === 'chips' && key === 'description') {
                                rendorProps.hidden = true;
                                if (_.isEmpty(newModalVM.description.value)) {
                                    const getNewModalVM = viewModelService.clone(newModalVM);
                                    const descriptionValues = newModalVM[key].aspects.availableValues;
                                    if (!_.isNil(descriptionValues) && descriptionValues.length > 0) {
                                        _.set(getNewModalVM, key, descriptionValues[0].code);
                                    }
                                    updateNewModalVM(getNewModalVM);
                                }
                            } else if (!_.isNil(newModalVM.value.source) && newModalVM.value.source === 'chips' && key === 'descriptionForChips') {
                                rendorProps.hidden = false;
                            } else if (!_.isNil(newModalVM.value.source) && newModalVM.value.source !== 'chips' && key === 'description') {
                                rendorProps.hidden = false;
                            } else if ((!_.isNil(newModalVM.value.source) && newModalVM.value.source !== 'chips' && key === 'descriptionForChips') ||
                                (title === 'Add MVR Entry' && key === 'descriptionForChips')) {
                                rendorProps.hidden = true;
                                if (_.isEmpty(newModalVM.descriptionForChips.value)) {
                                    const getNewModalVM = viewModelService.clone(newModalVM);
                                    const descriptionValues = newModalVM.description.aspects.availableValues;
                                    if (!_.isNil(descriptionValues) && descriptionValues.length > 0) {
                                        _.set(getNewModalVM, key, descriptionValues[0].code);
                                    }
                                    updateNewModalVM(getNewModalVM);
                                }
                            }

                            if ('year' in eachDto) {
                                if (rendorProps.path === 'incidentDate' || rendorProps.path === 'claimDate' && (title === 'Add MVR Entry' || title === 'Edit MVR Entry' || title === 'Edit CLUE Entry' || title === 'Add CLUE Entry' || title === 'Edit Prior Loss Entry' || title === 'Add Prior Loss Entry')) {
                                    return (
                                        <li id={key}>
                                            {rendorDateCtrl(rendorProps, eachValue, new Date(), setYearsBack(5))}
                                        </li>
                                    );
                                }
                                if (rendorProps.path === 'expirationDate' && (title === 'Add Prior Carrier Entry' || title === 'Edit Prior Carrier Entry')) {
                                    return (
                                        <li id={key}>
                                            {rendorDateCtrl(rendorProps, eachValue, setYearsForward(10), setYearsBack(5))}
                                        </li>
                                    );
                                }
                                return (
                                    <li id={key}>
                                        {rendorDateCtrl(rendorProps, eachValue)}
                                    </li>
                                );
                            }
                            if ('currency' in eachDto) {
                                return (
                                    <li id={key}>
                                        {rendorCurrencyCtrl(rendorProps, eachValue)}
                                    </li>
                                );
                            }
                            return (
                                <li id={key}>
                                    {renderControl(rendorProps, eachValue)}
                                </li>
                            );
                        }
                        return null;
                    })
                }
            </ul>
        );
    };

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
            return false;
        },
        [updateShowErrors, invalidFields]
    );
    const handleSave = useCallback(
        () => {
            if (actionType === 'View') {
                onReject();
                return false;
            }
            if (!isComponentValid) {
                handleValidation();
            } else {
                onResolve(newModalVM.value);
            }
        },
        [onResolve, handleValidation, isComponentValid, newModalVM]
    );
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
            showErrors: showErrors
        },
        doublePopupContainer: {
            visible: secondWinVisible
        },
        covpaidContainer: {
            visible: secondWinVisible,
            publicId: newModalVM.publicId.value,
            quotedId: quotedId,
            comingType: comingType,
            modalVM: paidCovModalVM,
            updatePaidCovModel: (value, path) => {
                const newPaidCovModalVM = viewModelService.clone(paidCovModalVM);
                _.set(newPaidCovModalVM, path, value);
                updatepaidCovModalVM(newPaidCovModalVM);
            },
            updateSecondWinVisible,
            updateNewPaidCovRecords,
            wniClueService,
            useAuthenticationData,
            useDependenciesData,
        },
        tableDetailsList: {
            content: onRenderContentFn()
        },
        IncidentsPopupAlertMessage : {
            visible: priorCarrierMessage
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            newModalVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveComponentMap: {
            covpaidtable: CovPaidComponent,
        },
        resolveCallbackMap: {
        }
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="tablePopupModal" autoFocus={bodyAutoFocus}>
                <ViewModelForm
                    model={newModalVM}
                    overrideProps={overrideProps}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

IncidentPopupComponent.propTypes = {
    title: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    modalVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    modelProps: PropTypes.shape({
        type: PropTypes.string.isRequired,
        labelMap: PropTypes.arrayOf.isRequired
    }).isRequired,
    drivers: PropTypes.arrayOf({}),
    policyDrivers: PropTypes.arrayOf({}),
    displayStatus: PropTypes.string.isRequired,
    quotedId: PropTypes.string.isRequired,
    comingType: PropTypes.string.isRequired,
    wniClueService: PropTypes.func.isRequired,
    vendorDataFlag: PropTypes.string.isRequired,
    isRequiredForIssuance: PropTypes.bool.isRequired,
    effectiveDate: PropTypes.shape({}),
    productName: PropTypes.string,
    validationMessages: PropTypes.arrayOf(PropTypes.string || PropTypes.shape({})),
    afterWriteValue: PropTypes.func,
    filterDisputeDropList: PropTypes.func,
    handleClauseOfLossChange: PropTypes.func,
};
IncidentPopupComponent.defaultProps = {
    size: 'md',
    drivers: [],
    policyDrivers: [],
    afterWriteValue: _.noop,
    filterDisputeDropList: _.noop,
    handleClauseOfLossChange: _.noop,
};

export default IncidentPopupComponent;
