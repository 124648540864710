import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caPolicyDetailsChangeService'), method, data, additionalHeaders);
}

export default class CAPolicyDetailsChangeService {
    static saveCAPolicyDetailsData(quoteID, sessionUUID, quoteBaseData, changeData, authHeader) {
        return processSubmission('saveCAPolicyDetailsData', [quoteID, sessionUUID, quoteBaseData, changeData], authHeader);
    }

}