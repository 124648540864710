import { defineMessages } from 'react-intl';

export default defineMessages({
    StandardCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Occupancy.Occupancy Coverages',
        defaultMessage: 'Occupancy Coverages',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Occupancy.Occupancy Additional Coverages',
        defaultMessage: 'Occupancy Additional Coverages',
    },
    AddAdditionalCoverage: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Occupancy.Add Additional Coverages',
        defaultMessage: 'Add Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Occupancy.Occupancy Exclusions and Conditions',
        defaultMessage: 'Occupancy Exclusions and Conditions',
    },
    AddExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Occupancy.Add Exclusions and Conditions',
        defaultMessage: 'Add Exclusions and Conditions',
    }
});