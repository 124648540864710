import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';

const requiredForIssuanceTitle = 'Below required fields for issuance are missing:';

/**
 * Get warning messages for fields that is required for issuance
 *
 * @param {Object} vm view model
 * @param {Object} map {'fieldPath1': 'fieldName1', 'fieldPath2': 'fieldName2', }
 * @param {Object} translator
 * @param {Object} messageObj
 * @returns {Array} ['displayName - Field 'fieldName1' is require for issuance.']
 */
function getWarningMessages(vm, map, translator, messageObj) {
    const warningMessages = [];
    const keys = Object.keys(map);
    keys.forEach((path) => {
        const displayName = _.get(vm, 'value.displayName');
        const value = _.get(vm, `value.${path}`);
        if (_.isNil(value) || value.length === 0) {
            const message = translator(messageObj, {
                fieldName: map[path],
                displayName
            });
            const newMessage = message.replace('{fieldName}', map[path]);
            warningMessages.push(newMessage);
        }
    });
    return warningMessages;
}

/**
 * Get error messages for fields that is required for quote
 *
 * @param {Object} vm view model
 * @param {Object} map {'fieldPath1': 'fieldName1', 'fieldPath2': 'fieldName2', }
 * @param {Object} translator
 * @param {Object} messageObj
 * @returns {Array} ['displayName - Field 'fieldName1' is require for quote.']
 */
function getErrorMessages(vm, map, translator, messageObj) {
    const errorMessages = [];
    const keys = Object.keys(map);
    keys.forEach((path) => {
        const displayName = _.get(vm, 'value.displayName');
        const value = _.get(vm, `value.${path}`);
        if (_.isNil(value) || value.length === 0) {
            const message = translator(messageObj, {
                fieldName: map[path],
                displayName
            });
            const newMessage = message.replace('{fieldName}', map[path]);
            errorMessages.push(newMessage);
        }
    });
    return errorMessages;
}

/**
 * Combines multiple issues into one.
 * The original issues are storerd in new property "children",
 * Lidewise, the origina reason is stored property "originalReason"
 * @param {array} issues [{type: string, reason: string}]
 * @param {Boolen} showEntityNameInPageLevelIssues
 * @returns {array} [{
 *  type: string,
 *  reason: string,
 *  children: [{ type: string, reason: string, originalReason: string}]
 * }}]
 */
function specialIssuesFilter(issues, showEntityNameInPageLevelIssues) {
    const newIssues = [];
    issues.forEach((item) => {
        const { type, relatedEntity: { displayName } = {} } = item;
        if (!newIssues[type]) {
            newIssues[type] = {
                type,
                reason: requiredForIssuanceTitle,
                children: []
            };
        }
        const reason = _.replace(item.reason, requiredForIssuanceTitle, '');
        const showEntityName = showEntityNameInPageLevelIssues && displayName;
        newIssues[type].children.push({
            ...item,
            originalReason: item.reason,
            reason: showEntityName ? `${displayName}: ${reason}` : reason
        });
    });
    return Object.values(newIssues);
}

/**
 * This method will combine multipe "Required for Issuance" issues into one.
 * The original issue list could be retrieved by ValidationIssueUtil.flattenChldrenInIssue();
 *
 * @See ValidationIssueUtil.flattenChldrenInIssue()
 * @param {array} issues
 * @param {Boolen} showEntityNameInPageLevelIssues
 * @returns {array}
 */
function getIssuesMap(issues, showEntityNameInPageLevelIssues) {
    const specialData = issues.filter((item) => _.startsWith(item.reason, requiredForIssuanceTitle));
    const otherData = issues.filter((item) => !_.startsWith(item.reason, requiredForIssuanceTitle));
    return _.concat(otherData, specialIssuesFilter(specialData, showEntityNameInPageLevelIssues));
}


function isRequiredForIssuance(baseData = {}, wizardPageData) {
    const {
        quoteFlow_Ext: quoteFlow,
        submittingQuote_Ext: submittingQuote,
        jobType
    } = baseData;
    const isQuoteExecuted = !_.isNil(wizardPageData[WizardConstants.policyDiffData]);
    const isRequiredForIssuanceFlag = (quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote) || (jobType === 'PolicyChange' && isQuoteExecuted);
    return isRequiredForIssuanceFlag;
}


function isRequiredForIssuanceR2(baseData = {}, wizardPageData) {
    const {
        quoteFlow_Ext: quoteFlow,
        jobType
    } = baseData;
    const isQuoteExecuted = !_.isNil(wizardPageData[WizardConstants.policyDiffData]);
    const isRequiredForIssuanceFlag = (quoteFlow !== 'draft') || (jobType === 'PolicyChange' && isQuoteExecuted);
    return isRequiredForIssuanceFlag;
}
function generateIssuanceOverrides(flatMetadata, isRequired) {
    const overridesForIssuance = flatMetadata.map((field) => {
        const { id: fieldId, componentProps } = field;
        const isFieldRequired = _.get(componentProps, 'requiredForIssuance') && isRequired;
        if (!isFieldRequired) {
            return {};
        }
        return { [fieldId]: { required: isFieldRequired, showRequired: false } };
    });
    return Object.assign({}, ...overridesForIssuance);
}
export default {
    getWarningMessages,
    getErrorMessages,
    getIssuesMap,
    isRequiredForIssuance,
    isRequiredForIssuanceR2,
    generateIssuanceOverrides
};
