import { ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React,{
useCallback,useEffect,useState
} from 'react';
import { BaseAddPaymentMethodPopup } from 'wni-capability-common-react';
import { WniCLBillingSubmissionService } from 'wni-capability-gateway-billing';
import { NewPersonComponent } from 'wni-capability-gateway-react';
import { WniLoadSaveService,WniPAQuoteService } from 'wni-capability-quoteandbind';
import { CAPaymentService } from 'wni-capability-quoteandbind-ca';
import { useWniModal } from 'wni-components-platform-react';
import { PaymentUtil, WniProductsUtil } from 'wni-portals-util-js';
import { Button } from '@jutro/legacy/components';
import AQPaymentPageUtil from '../../../Payment/utils/AQPaymentPageUtil';
import messages from './EditBillingPopup.messages';
import metadata from './EditBillingPopup.metadata.json5';
import styles from './EditBillingPopup.module.scss';

const {
    PA_PRODUCT_CODE,
    getProductNameByLinePattern
} = WniProductsUtil;


function EditBillingPopup(props) {
    const modalApi = useWniModal();
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        rowData,
        rePopupEditQuoteRow,
        initApplyToOtherQuotes,
        editedData,
        useDependenciesData,
        useAuthenticationData,
        viewModelService,
        breakpoint,
        isCL: isCLProduct
    } = props;

    const { loadingMask: { setLoadingMask } } = useDependenciesData || useDependencies(['loadingMask']);

    const { authHeader } = useAuthenticationData || useAuthentication();
    const translator = useTranslator();
    const [model, updateModel] = useState(_.isEmpty(editedData) ? _.cloneDeep(rowData) : _.cloneDeep(editedData));
    const [hasSelectAutoPay, updateHasSelectAutoPay] = useState(false);
    const [primaryPayerAvailableValues, updatePrimaryPayerAvailableValues] = useState([]);
    const [primaryPayer, updatePrimaryPayer] = useState('');
    const [selectedItem, updateSelectedItem] = useState({});
    const [paymentMethodsOptions, updatePaymentMethodsOptions] = useState([]);
    const [paymentMethodValue, updatePaymentMethodValue] = useState('');
    const [paymentPlanOptions, updatePaymentPlanOptions] = useState([]);
    const [applyToOtherQuotesAvailableValues, updateapplyToOtherQuotesAvailableValues] = useState([]);
    const [applyToOtherQuotesValue, updateApplyToOtherQuotesValue] = useState(_.get(model, 'applyToOtherQuotes', []));
    const [hasFullPayPolicyUnderInvoiceStream, updateHasFullPayPolicyUnderInvoiceStream] = useState(false);

    const [clBillingMethodsOptions, updateCLBillingMethodsOptions] = useState([]);
    const [clBillingMethodValue, updateCLBillingMethodValue] = useState('');
    const [clPaymentMehodEditable, updateCLPaymentMehodEditable] = useState(true);
    const [clDueDayOfMonthEditable, updateCLDueDayOfMonthEditable] = useState(true);
    const [clSelectedPaymentPlan, updateCLSelectedPaymentPlan] = useState('');
    const [clDueDayOfMonth, updateCLDueDayOfMonth] = useState('');
    const [clPrimaryPayerEditable, updateCLPrimaryPayerEditable] = useState(true);
    
    
    
    const {
        quoteNumber: quoteID,
        sessionUUID,
        accountNumber
    } = rowData;

    const checkPersonExist = useCallback((person, primaryPayerOptions) => {
        const personExist = _.find(primaryPayerOptions, (options) => {
            return JSON.stringify(person) === _.get(options, 'code');
        });
        if (person && !personExist) {
            primaryPayerOptions.push({
                code: JSON.stringify(person),
                name: _.get(person, 'displayName')
            });
        }
        return primaryPayerOptions;
    }, []);

    const resetPrimaryPayerOptions = useCallback((newPerson, initPerson) => {
        // set primaryPayerOptions
        let primaryPayerOptions = [];
        const nonBillingContacts = _.get(model, 'bindData.nonBillingContacts_Ext');
        _.each(nonBillingContacts , (contact) => {
            _.set(contact, 'phoneRequired_Ext', false);
        })
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            nonBillingContacts, primaryPayerOptions
        );
        const billingContacts = _.get(model, 'bindData.billingContacts_Ext');
        _.each(billingContacts , (contact) => {
            _.set(contact, 'phoneRequired_Ext', false);
        })
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            billingContacts, primaryPayerOptions
        );
        // add portal temporaryPrimaryPayers
        const temporaryContacts = _.get(model, 'primaryPayerTemporaryOptions');
        _.each(temporaryContacts , (contact) => {
            _.set(contact, 'phoneRequired_Ext', false);
        })
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            temporaryContacts, primaryPayerOptions
        );
        // initPerson
        primaryPayerOptions = checkPersonExist(initPerson, primaryPayerOptions)

        // manually add New Person
        const newPersonExist = _.find(primaryPayerOptions, (options) => {
            const code = _.get(options, 'code');
            const addressBookUIDExt = _.get(JSON.parse(code), 'addressBookUID_Ext');
            return _.get(newPerson, 'addressBookUID') === addressBookUIDExt
                || JSON.stringify(newPerson) === _.get(options, 'code');
        });
        if (newPerson && !newPersonExist) {
            const isBCContact = _.get(newPerson, 'publicID', '').includes('bc');
            const addressBookUID = _.get(newPerson, 'addressBookUID', false);
            if (isBCContact && addressBookUID) {
                primaryPayerOptions.push({
                    code: _.get(newPerson, 'addressBookUID'),
                    name: _.get(newPerson, 'displayName')
                });
            } else {
                primaryPayerOptions.push({
                    code: JSON.stringify(newPerson),
                    name: _.get(newPerson, 'displayName')
                });
            }
        }
        primaryPayerOptions.push({
            code: 'Add New Person',
            name: 'Add New Person'
        });
        updatePrimaryPayerAvailableValues(primaryPayerOptions);
    }, [checkPersonExist, model]);

    const enableDirectBillFieldsEditable = useCallback(() => {
        updateCLPaymentMehodEditable(true);
        updateCLDueDayOfMonthEditable(true);
        updateCLPrimaryPayerEditable(true);
    }, []);

    const updateCLAgencyBillRelatedChanges = useCallback(() => {
        updatePaymentMethodValue('Check');
        updateCLPaymentMehodEditable(false);
        updateCLDueDayOfMonthEditable(false);
        updateCLPrimaryPayerEditable(false);
        _.set(model, 'isAutoPay', false);
        _.set(model, 'payUsing', 'Check');
    }, [model]);

    const updateCLPayAsYouGoRelatedChanges = useCallback(() => {
        updatePaymentMethodValue('Check');
        updateCLPaymentMehodEditable(false);
        updateCLDueDayOfMonthEditable(false);
        updateCLPrimaryPayerEditable(true);
        _.set(model, 'isAutoPay', false);
        _.set(model, 'payUsing', 'Check');
    }, [model]);

    useEffect(() => {
        // loading data options from PC
        async function retrieveQuotePaymentDetailsData() {
            const rs = isCLProduct ? 
                await CAPaymentService
                    .retrieveCLQuotePaymentDetailsData(quoteID, sessionUUID, authHeader)
                :await WniLoadSaveService
                    .retrieveQuotePaymentDetailsData(quoteID, authHeader);
            return rs;
        }
        setLoadingMask(true);
        retrieveQuotePaymentDetailsData().then((res) => {
            if (isCLProduct) {
                // CL Billing Method
                const clBillingMethods = _.get(res, 'bindData.billingMethodRange_Ext');
                const clBillingMethodOptions = PaymentUtil.generatePaymentBillingMethods(clBillingMethods);
                updateCLBillingMethodsOptions(clBillingMethodOptions);

                // CL Selected Billing Method
                const clSelectedBillingMethod = _.get(res, 'bindData.selectedBillingMethod_Ext');
                updateCLBillingMethodValue(clSelectedBillingMethod);

                // CL AGENCY BILL Special disable logic
                if (clSelectedBillingMethod === 'AGENCY_BILL') {
                    updateCLAgencyBillRelatedChanges();
                } else if (clSelectedBillingMethod === 'PAY_AS_YOU_GO'){
                    updateCLPayAsYouGoRelatedChanges();
                } else {
                    enableDirectBillFieldsEditable();
                }
                // CL Due day of Month
                updateCLDueDayOfMonth(_.get(model, 'invoiceStreamDay'))
                
                // CL Selected Payment Plan
                updateCLSelectedPaymentPlan(_.get(model, 'selectedPaymentPlan'));

                // CL Apply To Other Options
                updateCLApplyToOtherQuotesOptions(clSelectedBillingMethod);
            }
            

            // paymentMethods
            const payInstrucments = _.get(res, 'bindData.payInstrucments_Ext');
            let newPaymentMethods = PaymentUtil.initPaymentMethods(
                payInstrucments, viewModelService
            );
            // payUsingTemporaryOptions
            const payUsingTemporaryOptions = _.get(model, 'payUsingTemporaryOptions', []);
            if (!_.isEmpty(payUsingTemporaryOptions)) {
                newPaymentMethods = newPaymentMethods.concat(payUsingTemporaryOptions);
            }
            updatePaymentMethodsOptions(newPaymentMethods);

            // paymentPlans
            const newPaymentPlans = _.get(model, 'availablePayPlans');
            const newPaymentPlanOptions = _.map(newPaymentPlans, (row) => {
                return PaymentUtil.forMatterPaymentPlan(row);
            });
            updatePaymentPlanOptions(newPaymentPlanOptions);

            const billingContacts = _.get(res, 'bindData.billingContacts_Ext');
            const nonBillingContacts = _.get(res, 'bindData.nonBillingContacts_Ext');
            const temporaryContacts = _.get(model, 'primaryPayerTemporaryOptions');

            _.set(model, 'bindData.billingContacts_Ext', billingContacts);
            _.set(model, 'bindData.nonBillingContacts_Ext', nonBillingContacts);

            // set up init primaryPayer_Ext
            const rowDataInitPrimaryPayer = _.get(rowData, 'primaryPayer');
            if (rowDataInitPrimaryPayer) {
                // set phoneRequired_Ext flag to false to avoid dto error
                _.set(rowDataInitPrimaryPayer, 'phoneRequired_Ext', false);
            }


            // set default dueDate to invoiceStreamDay when invoiceStreamCode exists
            const invoiceStreamCode = _.get(rowData, 'invoiceStreamCode', '');
            if (invoiceStreamCode && !isCLProduct) {
                // set dueDayOfTheMonth value with invoiceStreamDay
                _.set(model, 'invoiceStreamDay', _.get(rowData, 'invoiceStreamDay', ''));
            }

            // set up default primaryPayer_Ext
            let defaultPrimaryPayer = _.get(model, 'primaryPayer');
            if (defaultPrimaryPayer) {
                // set phoneRequired_Ext flag to false to avoid dto error
                _.set(model, 'primaryPayer.phoneRequired_Ext', false);
                const newAddedPrimaryPayer = _.get(model, 'newAddedPrimaryPayer');
                if (newAddedPrimaryPayer) {
                    // set phoneRequired_Ext flag to false to avoid dto error
                    _.set(model, 'newAddedPrimaryPayer.phoneRequired_Ext', false);
                    const firstName = _.get(newAddedPrimaryPayer, 'firstName');
                    const lastName = _.get(newAddedPrimaryPayer, 'lastName');
                    const inputUserName = `${firstName} ${lastName}`;
                    const contacts = billingContacts.concat(temporaryContacts);
                    const newAddedContact = _.find(contacts, (payer) => {
                        return inputUserName === _.get(payer, 'displayName');
                    });
                    _.set(newAddedContact, 'phoneRequired_Ext', false);
                    updatePrimaryPayer(JSON.stringify(newAddedContact));
                } else {
                    const isBCContact = _.get(defaultPrimaryPayer, 'publicID', '').includes('bc');
                    if (isBCContact) {
                        const addressBookUID = _.get(defaultPrimaryPayer, 'addressBookUID');
                        let existingPerson = _.find(billingContacts, (payer) => {
                            const addressBookUIDExt = _.get(payer, 'addressBookUID_Ext');
                            return addressBookUID === addressBookUIDExt;
                        });
                        // add account holder
                        const defaultPrimaryPayerPublicID = _.get(defaultPrimaryPayer, 'publicID');
                        const accountHolder = _.get(res, 'baseData.accountHolder');
                        if (defaultPrimaryPayerPublicID !== _.get(accountHolder, 'publicID') && !existingPerson) {
                            billingContacts.push(accountHolder);
                            // update exitingPerson if he is the accountHolder
                            if (addressBookUID === _.get(accountHolder, 'addressBookUID_Ext')) {
                                existingPerson = accountHolder;
                            }
                        }
                        
                        if (existingPerson) {
                            _.set(existingPerson, 'phoneRequired_Ext', false);
                            // for the pc payer
                            updatePrimaryPayer(JSON.stringify(existingPerson));
                        } else if (addressBookUID) {
                            // for the bc payer
                            updatePrimaryPayer(addressBookUID);
                        } else {
                            // for the payer only added in BC
                            updatePrimaryPayer(JSON.stringify(defaultPrimaryPayer));
                        }
                        
                    } else {
                        updatePrimaryPayer(JSON.stringify(defaultPrimaryPayer));
                    }
                    
                }
                resetPrimaryPayerOptions(defaultPrimaryPayer);
            } else {
                defaultPrimaryPayer = '-';
                resetPrimaryPayerOptions();
            }

            const isAutoPay = _.get(model, 'isAutoPay');
            if (isAutoPay) {
                const payUsing = _.get(model, 'payUsing');
                updatePaymentMethodValue(payUsing);
            } else {
                updatePaymentMethodValue('Check');
            }

            if (!isCLProduct) {
                // update applyToOtherQuotesAvailableValues
                const nonMortgageBills = _.filter(initApplyToOtherQuotes, (data) => {
                    // exclude HO mortgage bill quote
                    const isMortgageBill = _.get(data, 'mortgageBill');
                    return !isMortgageBill && _.get(data, 'quoteNumber') !== quoteID;
                });
                const newapplyToOtherQuotesAvailableValues = _.map(nonMortgageBills, (option) => {
                    const isPAWithFullpay = _.get(option, 'productCode') === PA_PRODUCT_CODE && _.get(option, 'isFullPay', false) === true;
                    return {
                        code: JSON.stringify({ quoteID: _.get(option, 'quoteNumber'), periodPublicID: _.get(option, 'periodPublicID'), isPAWithFullpay: isPAWithFullpay }),
                        name: `${getProductNameByLinePattern(_.get(option, 'product'))}-${_.get(option, 'quoteNumber')}`
                    };
                });
                updateapplyToOtherQuotesAvailableValues(newapplyToOtherQuotesAvailableValues || []);
            }

            const item = {
                title: title,
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: true,
                actionBtnLabel: messages.dialogOk,
                cancelBtnLabel: messages.dialogCancel,
                authHeader,
                rowData: model,
                warningMessage: translator(messages.editPaymentDetailsWarning),
                rePopupEditQuoteRow: rePopupEditQuoteRow,
                initApplyToOtherQuotes: initApplyToOtherQuotes,
                useDependenciesData,
                useAuthenticationData,
                viewModelService,
                breakpoint,
                isCL: isCLProduct
            };
            updateSelectedItem(item);
        }).finally(() => {
            setLoadingMask(false);
        });
    }, []);

    const innerNewPersonPopup = useCallback(() => {
        const vmObject = {
            value: {
                quoteID: quoteID,
                sessionUUID: sessionUUID,
                baseData: {
                    accountNumber: accountNumber
                }
            }
        };
        const modalProps = {
            title: translator(messages.newPersonTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            authHeader,
            submissionVM: vmObject,
            primaryPayerAvailableValues,
            requireProductsContainer: false,
            isTemporaryForPortal: true,
            viewModelService,
            useAuthenticationData,
            useDependenciesData
        };

        return Promise.resolve(onReject()).then(() => {
            let newAddPrimaryPayer = null;
            const temporaryOptions = _.get(model, 'primaryPayerTemporaryOptions', []);
            modalApi.showModal(<NewPersonComponent {...modalProps} />)
                .then((res) => {
                    _.set(res, 'displayName', `${_.get(res, 'firstName')} ${_.get(res, 'lastName')}`);
                    temporaryOptions.push(res);
                    newAddPrimaryPayer = res;
                    return res;
                }).catch(() => {
                    return false;
                }).finally(() => {
                    // keep the edtied data
                    _.set(model, 'newAddedPrimaryPayer', newAddPrimaryPayer);
                    _.set(model, 'primaryPayerTemporaryOptions', temporaryOptions);
                    _.set(selectedItem, 'editedData', model);
                    // update option and value
                    // _.set(selectedItem, 'rowData.newAddedPrimaryPayer', newAddPrimaryPayer);
                    // update temporary options for portal
                    // _.set(selectedItem, 'rowData.primaryPayerTemporaryOptions', temporaryOptions);
                    rePopupEditQuoteRow(selectedItem);
                    _.noop();
                });
        });
    }, [accountNumber, authHeader, modalApi, model, onReject, primaryPayerAvailableValues,
        quoteID, rePopupEditQuoteRow, selectedItem, sessionUUID, translator]);

    const onNewPersonClick = useCallback(() => {
        return innerNewPersonPopup();
    }, [innerNewPersonPopup]);

    const primaryPayerChange = useCallback((value) => {
        if (value === 'Add New Person') {
            onNewPersonClick();
        } else {
            updatePrimaryPayer(value);
            try {
                const contact = JSON.parse(value);
                // set phoneRequired_Ext flag to false to avoid dto error
                _.set(contact, 'phoneRequired_Ext', false);
                _.set(model, 'primaryPayer', contact);
                _.set(model, 'newAddedPrimaryPayer', false);
            } catch (ex) {
                _.set(model, 'primaryPayer', { addressBookUID_Ext: value});
                _.set(model, 'newAddedPrimaryPayer', false);
                _.noop();
            }
        }
    }, [model, onNewPersonClick]);


    const writeValue = useCallback((value, path) => {
        const newModel = _.cloneDeep(model);
        _.set(newModel, `${path}`, value);
        updateModel(newModel);
    }, [model]);

    const getDueDayOfMonthOptions = useCallback(() => {
        const options = [...Array(31).keys()].map((i) => ({
            code: i + 1,
            name: i + 1,
        }));
        return options;
    }, []);

    const showAddpaymentMethodPopup = useCallback((getPaymentDetailVM) => {
        const componentProps = {
            title: translator(messages.addPaymentMethodTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            paymentDetailVM: getPaymentDetailVM,
            useAuthenticationData,
            useDependenciesData,
            viewModelService,
            breakpoint
        };
        return modalApi.showModal(<BaseAddPaymentMethodPopup {...componentProps} />);
    }, [breakpoint, modalApi, translator, useAuthenticationData, useDependenciesData, viewModelService]);

    const paymentPlanChange = useCallback((value) => {
        updateCLSelectedPaymentPlan(value);
        writeValue(value, 'selectedPaymentPlan');
    }, [writeValue]);

    const clDueDayOfMonthChange = useCallback((value) => {
        updateCLDueDayOfMonth(value);
        writeValue(value, 'invoiceStreamDay');
    }, [writeValue]);

    const updatePaymentMethod = useCallback((value) => {
        // addNewBankAccount
        if (value === 'Add New AutoPay Account') {
            const isTemporaryForPortal = true;
            // popup new Bank account
            AQPaymentPageUtil.popupNewBankAccount(viewModelService, onReject, model,
                showAddpaymentMethodPopup, PaymentUtil, paymentMethodsOptions,
                setLoadingMask, WniPAQuoteService, quoteID, sessionUUID,
                authHeader, rePopupEditQuoteRow, selectedItem, isTemporaryForPortal);
        } else if (value === 'Check') {
            updateHasSelectAutoPay(false);
        } else {
            updateHasSelectAutoPay(true);
        }
        writeValue(value, 'payUsing');
        updatePaymentMethodValue(value);
    }, [authHeader, model, onReject,
        paymentMethodsOptions, quoteID, rePopupEditQuoteRow,
        selectedItem, sessionUUID, setLoadingMask,
        showAddpaymentMethodPopup, viewModelService, writeValue]);

    const parseApplyToOtherQuotes = useCallback(() => {
        // parse applyToOtherQuotes from string to object
        const applyToOtherQuotes = _.get(model, 'applyToOtherQuotes');
        const applyToOtherQuotesObjects = [];
        if (!_.isEmpty(applyToOtherQuotes)) {
            _.each(applyToOtherQuotes, (str) => {
                applyToOtherQuotesObjects.push(JSON.parse(str));
            });
            _.set(model, 'applyToOtherQuotes', applyToOtherQuotesObjects);
        }
    }, [model]);

    const handleSave = useCallback(async () => {
        parseApplyToOtherQuotes();
        // select the PrimaryPayer value
        if (primaryPayer) {
            primaryPayerChange(primaryPayer);
        }
        _.set(model, 'isCL', isCLProduct);
        const payUsing = _.get(model, 'payUsing');
        const paymentMethodOption = _.find(paymentMethodsOptions, (option) =>
            _.get(option, 'value.bankAccountData.publicID') === payUsing);
        const paymentMethodOptionValue = _.get(paymentMethodOption, 'value.bankAccountData');
        if (payUsing !== 'Check') {
            _.set(model, 'bankAccountData', _.isEmpty(paymentMethodOptionValue) ? JSON.parse(payUsing) : paymentMethodOptionValue);
        }
        return onResolve(model);
    }, [isCLProduct, model, onResolve, parseApplyToOtherQuotes, paymentMethodsOptions, primaryPayer, primaryPayerChange]);

    const handleApplyToOthersChange = useCallback((value) => {
        updateApplyToOtherQuotesValue(value);
        const hasSelectPAWithFullpay = _.find(value, (option) => {
            const opt = JSON.parse(option);
            return _.get(opt, 'isPAWithFullpay') === true;
        });
        updateHasFullPayPolicyUnderInvoiceStream(!!hasSelectPAWithFullpay);
        writeValue(value, 'applyToOtherQuotes');
    }, [writeValue])

    const getPaymentPlanFullPayMessage = useCallback(() => {
        return (
            <div>
                <span className={styles.alertIcon}>*</span>
                <span className={styles.fontWeightNormal}>
                    Payment Plan changes will not apply to an Auto Policy on Full Pay Plan
                </span>
            </div>
        )
    }, []);
    const updateCLApplyToOtherQuotesOptions = useCallback((value) => {
        // update applyToOtherQuotesAvailableValues
        const nonMortgageBills = _.filter(initApplyToOtherQuotes, (data) => {
            // exclude HO mortgage bill quote
            const isMortgageBill = _.get(data, 'mortgageBill');
            return !isMortgageBill && _.get(data, 'quoteNumber') !== quoteID;
        });
        let options = nonMortgageBills;
        if (value === 'PAY_AS_YOU_GO') {
            options = _.filter(nonMortgageBills, (option) => {
                const billingMethodOptions = _.get(option, 'billingMethodOptions') ;
                return _.includes(billingMethodOptions, 'PAY_AS_YOU_GO');
            });
        }
        const newapplyToOtherQuotesAvailableValues = _.map(options, (option) => {
            const product = _.get(option, 'product');
            return {
                code: JSON.stringify({ quoteID: _.get(option, 'quoteNumber'), sessionUUID: _.get(option, 'sessionUUID'), periodPublicID: _.get(option, 'periodPublicID') }),
                name: `${getProductNameByLinePattern(product)}-${_.get(option, 'quoteNumber')}`
            };
        });
        updateapplyToOtherQuotesAvailableValues(newapplyToOtherQuotesAvailableValues || []);
    }, [quoteID, initApplyToOtherQuotes]);

    const updateCLBillingMethod = useCallback(
        async (value) => {
            if (value === _.get(model, 'selectedBillingMethod')) {
                return;
            }
            if (value === 'AGENCY_BILL') {
                updateCLAgencyBillRelatedChanges();
            } else if (value === 'PAY_AS_YOU_GO') {
                updateCLPayAsYouGoRelatedChanges();
            } else {
                enableDirectBillFieldsEditable();
            }
            updateCLBillingMethodValue(value);
            // writeValue(value, 'selectedBillingMethod');
            _.set(model, 'selectedBillingMethod', value);
            // _.unset(model, 'selectedPaymentPlan');

            try {
                setLoadingMask(true);
                const toUpdate = {
                    invoiceStreamCode: _.get(model, 'invoiceStreamCode'),
                    invoiceStreamDay: _.get(model, 'invoiceStreamDay'),
                    applyToOtherQuotes: _.get(model, 'applyToOtherQuotes'),
                    payUsing: _.get(model, 'payUsing'),
                    primaryPayer: _.get(model, 'primaryPayer'),
                    selectedPaymentPlan: _.get(model, 'selectedPaymentPlan'),
                    policyNumber: _.get(model, 'policyNumber'),
                    accountNumber: _.get(model, 'accountNumber'),
                    periodPublicID: _.get(model, 'periodPublicID'),
                    policyPCPublicID: _.get(model, 'policyPCPublicID'),
                    bankAccountData: _.get(model, 'bankAccountData'),
                };
                const res =
                    await WniCLBillingSubmissionService.updateBillingMethodAndRetrievePaymentData(
                        [toUpdate],
                        authHeader
                    );
                // paymentPlans
                const newPaymentPlans = _.get(res, 'availablePayPlans');
                const newPaymentPlanOptions = _.map(newPaymentPlans, (row) => {
                    return PaymentUtil.forMatterPaymentPlan(row);
                });
                updatePaymentPlanOptions(newPaymentPlanOptions);
                // selectedPaymentPlan
                const newSelectedPaymentPlan = _.get(
                    res,
                    'selectedPaymentPlan'
                );
                updateCLSelectedPaymentPlan(newSelectedPaymentPlan);
                _.set(model, 'selectedPaymentPlan', newSelectedPaymentPlan);
                // dueDate
                const newDueDayOfMonth = _.get(res, 'invoiceStreamDay');
                updateCLDueDayOfMonth(newDueDayOfMonth);
                _.set(model, 'invoiceStreamDay', newDueDayOfMonth);
                // // paymentMethod options
                // const payInstrucments = _.get(res, 'payInstrucments_Ext');
                // const newPaymentMethods = PaymentUtil.initPaymentMethods(
                //     payInstrucments, viewModelService
                // );
                // updatePaymentMethodsOptions(newPaymentMethods);
                // _.set(model, 'bindData.payInstrucments_Ext', payInstrucments);
                // update ApplyToOtherQuotesOption when this is CL
                if (isCLProduct) {
                    updateCLApplyToOtherQuotesOptions(value);
                }
                // update value
                const newModel = _.cloneDeep(model);
                updateModel(newModel);
            } catch (ex) {
                updatePaymentPlanOptions([]);
            } finally {
                setLoadingMask(false);
            }
        },
        [
            authHeader,
            enableDirectBillFieldsEditable,
            isCLProduct,
            model,
            setLoadingMask,
            updateCLAgencyBillRelatedChanges,
            updateCLApplyToOtherQuotesOptions,
            updateCLPayAsYouGoRelatedChanges,
        ]
    );

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        notificationSection: {
            className: styles.marginBottom,
        },
        // warningMessage: {
        //     visible: hasSelectAutoPay,
        //     content: warningMessage
        // },
        paymentMethod: {
            label: translator(messages.paymentMethod),
            availableValues: AQPaymentPageUtil.getPaymentDetailsData(
                paymentMethodsOptions
            ),
            onValueChange: updatePaymentMethod,
            value: paymentMethodValue,
        },
        dueDayOfMonth: {
            path: 'invoiceStreamDay',
            label: translator(messages.dueDayOfMonth),
            availableValues: getDueDayOfMonthOptions(),
        },
        paymentPlan: {
            path: 'selectedPaymentPlan',
            label: translator(messages.paymentPlan),
            availableValues: paymentPlanOptions,
            disabled: _.get(model, 'paymentPlanEditable') !== true,
        },
        paymentPlanMessage: {
            label: ' ',
            value: getPaymentPlanFullPayMessage(),
            readOnly: true,
            // visible: _.get(_.find(paymentPlanOptions, (opt) => opt.code === _.get(model, 'selectedPaymentPlan')), 'name') === 'Pay in Full'
            visible: hasFullPayPolicyUnderInvoiceStream,
        },
        primaryPayer: {
            disabled: true,
            label: translator(messages.primaryPayer),
            availableValues: primaryPayerAvailableValues,
            value: primaryPayer,
            onValueChange: primaryPayerChange,
        },
        applyToOtherQuotes: {
            id: 'applyToOtherQuotes',
            path: 'applyToOtherQuotes',
            label: translator(messages.applyToOtherQuotes),
            availableValues: applyToOtherQuotesAvailableValues,
            value: applyToOtherQuotesValue,
            onValueChange: handleApplyToOthersChange,
        },
        clInputSection: {
            visible: isCLProduct,
        },
        inputSection: {
            visible: !isCLProduct,
        },
        clBillingMethod: {
            disabled: true,
            label: translator(messages.billingMethod),
            availableValues: clBillingMethodsOptions,
            onValueChange: updateCLBillingMethod,
            value: clBillingMethodValue,
        },
        clPaymentMethod: {
            label: translator(messages.paymentMethod),
            availableValues: AQPaymentPageUtil.getPaymentDetailsData(
                paymentMethodsOptions
            ),
            onValueChange: updatePaymentMethod,
            value: paymentMethodValue,
            disabled: !clPaymentMehodEditable,
        },
        clDueDayOfMonth: {
            path: 'invoiceStreamDay',
            label: translator(messages.dueDayOfMonth),
            availableValues: getDueDayOfMonthOptions(),
            disabled: !clDueDayOfMonthEditable,
            value: clDueDayOfMonth,
            onValueChange: clDueDayOfMonthChange,
        },
        clPaymentPlan: {
            path: 'selectedPaymentPlan',
            label: translator(messages.paymentPlan),
            availableValues: paymentPlanOptions,
            value: clSelectedPaymentPlan,
            onValueChange: paymentPlanChange,
        },
        clPaymentPlanMessage: {
            label: ' ',
            value: getPaymentPlanFullPayMessage(),
            readOnly: true,
            // visible: _.get(_.find(paymentPlanOptions, (opt) => opt.code === _.get(model, 'selectedPaymentPlan')), 'name') === 'Pay in Full'
            visible: hasFullPayPolicyUnderInvoiceStream,
        },
        clPrimaryPayer: {
            label: translator(messages.primaryPayer),
            availableValues: primaryPayerAvailableValues,
            disabled: !clPrimaryPayerEditable,
            value: primaryPayer,
            onValueChange: primaryPayerChange,
        },
        clApplyToOtherQuotes: {
            id: 'applyToOtherQuotes',
            path: 'applyToOtherQuotes',
            label: translator(messages.applyToOtherQuotes),
            availableValues: applyToOtherQuotesAvailableValues,
            value: applyToOtherQuotesValue,
            onValueChange: handleApplyToOthersChange,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="paymentDetailPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={model}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

EditBillingPopup.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    warningMessage: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
        quoteNumber: PropTypes.string,
        sessionUUID: PropTypes.string,
        accountNumber: PropTypes.string
    }).isRequired,
    rePopupEditQuoteRow: PropTypes.func,
    initApplyToOtherQuotes: PropTypes.arrayOf(PropTypes.shape({}))
};
EditBillingPopup.defaultProps = {
    size: 'md',
    rePopupEditQuoteRow: _.noop,
    initApplyToOtherQuotes: []
};
export default EditBillingPopup;
