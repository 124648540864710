import React, {
    useState,
    useEffect,
    useContext,
    useCallback
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { CPTooltipUtil as tooltips } from 'wni-portals-tooltip';
import { WniInputNumber } from 'wni-common-base-components';
import CPCoverageUtil from '../../../util/CPCoverageUtil';
import messages from './CP7Limit2TermComponent.messages';
import SingleClauseContext from '../../../context/CPSingleClauseContext';
import CoveragesConfigContext from '../../../context/CPCoveragesConfigContext';


function getPath(changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    return pathToNormalise;
}

const originEditingValueFromTerm = (term) => {
    return term.directValue
}

function CP7Limit2TermComponent(props) {

    const {
        idPrefix,
        clauseCode: coverageCode,
        clausePublicID,
        clausePath,
        isEditable,
        isDisabled,
        onChangeClause,
        // onSyncCoverages,
        onChangeSubmissionAndSync,
        labelPosition,
        labelTop,
        onValidate: onContainerValidate,
        setIsEditing,
        showErrors,
    } = useContext(SingleClauseContext);

    const coveragesConfig = useContext(CoveragesConfigContext);

    const {
        term = {},
        termIndex,
        hideLabel = false,
        className,
    } = props

    const {
        termConfig,
    } = coveragesConfig;

    const {
        name,
        isEditable: isTermEditable = true,
        options = [],
        code_Ext: code,
        chosenTerm,
        chosenTermValue,
    } = term  

    const translator = useTranslator();

    const originEditingValue = originEditingValueFromTerm(term);
    const [editingValue, setEditingValue] = useState(originEditingValue)
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation('CP7Limit2Term');

    const valueLowerNotGreaterThan1000 = term.directValue <= 1000

    const checkTermValid = useCallback(() => {
        return !CPCoverageUtil.isTermInvalid(term) && !valueLowerNotGreaterThan1000
    }, [term, valueLowerNotGreaterThan1000])

    useEffect(() => {
        registerComponentValidation(checkTermValid)
    }, [checkTermValid, registerComponentValidation])

    useEffect (() => {
        const targetOption = options.find(elt => elt.code === chosenTerm);
        if ( !_.isNil(targetOption) && targetOption.name !== chosenTermValue) {
            targetOption.name = chosenTermValue;
        }
    }) 

    useEffect(() => {
        setEditingValue(originEditingValue)
    }, [originEditingValue])

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, term.code_Ext);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, term.code_Ext);
            }
        }
    }, [isComponentValid, onContainerValidate, term])

    const handleChangeClause = (value) => {
        if (editingValue !== value) {
            setIsEditing(true)
            setEditingValue(value)
        }
        
    };

    const handleSyncCoverages = (evt, changedValues) => {
        setIsEditing(false)
        const { beforeValue, value: newValue, model } = changedValues;
        const actualChangedPath = getPath(model);

        const isValueChange = (beforeValue !== newValue)

        if (isValueChange) {
            // Use changeWithSync here instead of sync, because editing value is not saved to submission
            // Use changeWithSync to save value to VM and sync to PC
            const fieldNotChangeWithSync = _.get(termConfig.termsNotChangeWithSync, coverageCode, [])
            if (fieldNotChangeWithSync.includes(code)) {
                if (onChangeClause) {
                    return Promise.resolve(onChangeClause(newValue, actualChangedPath));
                }
            }
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(newValue, actualChangedPath));
            }
        }
        return Promise.resolve();
    };

    const handleChangeWithSync = (value, changedValuePath) => {
        if (_.get(term, 'chosenTerm') !== value) {
            const fieldNotChangeWithSync = _.get(termConfig.termsNotChangeWithSync, coverageCode, [])
            if (fieldNotChangeWithSync.includes(code)) {
                if (onChangeClause) {
                    return Promise.resolve(onChangeClause(value, changedValuePath));
                }
            }
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    const termPathPrefix = `${clausePath}.terms.children[${termIndex}]`;

    const commmonTermProps = {
        id: `${idPrefix}ClauseTerm_[${clausePublicID}]_[${termIndex}]`,
        label: name,

        path: `${termPathPrefix}.chosenTerm`,
        readOnly: !isTermEditable || !isEditable || term.readOnly_Ext,
        disabled: isDisabled,
        value: term.chosenTerm,
        labelPosition: labelPosition,
        layout: labelTop ? 'full-width' : null,
        onValueChange: handleChangeWithSync,
        required: isEditable && term.required,
        className: `clause_term ${className}`,
        tooltip: {
            text: tooltips[term.code_Ext]
        },
        showErrors,
        hideLabel,
    }

    return <WniInputNumber
        {...commmonTermProps}
        onValueChange= {handleChangeClause}
        onBlur= {handleSyncCoverages}
        value= {editingValue}
        maxValue= {term.directValueMax}
        path= {`${termPathPrefix}.directValue`}
        required= {term.required}
        onValidationChange={onValidate}
        decimalPlaces={4}
        // placeholder: term.required ? '-- Required for Quote --' : '',
        placeholder= ''
        validationMessages={valueLowerNotGreaterThan1000 ? [translator(messages.FireDepartmentIncreaseLimitLessThan1000)] : undefined}

    />
}
export default CP7Limit2TermComponent;