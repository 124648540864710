import { defineMessages } from 'react-intl';

export default defineMessages({
    runLossReport: {
        id: 'custom.quoteandbind.ho.quote-clause-table.Finalize Eligibility / Run Loss Report',
        defaultMessage: 'Finalize Eligibility / Run Loss Report',
    },
    doNotProceed: {
        id: 'custom.quoteandbind.ho.quote-clause-table.Do Not Proceed',
        defaultMessage: 'Do Not Proceed - Save and Exit',
    },
    proceedToSubmission: {
        id: 'custom.quoteandbind.ho.quote-clause-table.Proceed to Submission',
        defaultMessage: 'Proceed to Submission',
    },
    blockedByIneligibility: {
        id: 'custom.quoteandbind.ho.quote-clause-table.blockedByIneligibility',
        defaultMessage: 'This policy is ineligible. You will need to contact underwriting should you wish to continue with this policy.',
    },
    requiresVirtualInspectionMessage: {
        id: 'custom.quoteandbind.ho.quote-clause-table.requiresVirtualInspectionMessage',
        defaultMessage: 'This property requires a virtual inspection.',
    },
    waitOrBindInfoRequiresVirtualInspectionMessage: {
        id: 'custom.quoteandbind.ho.quote-clause-table.waitOrBindInfoRequiresVirtualInspectionMessage',
        defaultMessage: 'If you {ContinuetoIssue}, you will bind now and the inspection will be reviewed by underwriting post-issuance. If the inspection exposes issues that impact pricing or eligibility, the policy may be changed or cancelled after issuance. In this case, appropriate notification will be sent to you and the policyholder.',
    },
    waitOrBindInfoUnderwritingContactMessage: {
        id: 'custom.quoteandbind.ho.quote-clause-table.waitOrBindInfoUnderwritingContactMessage',
        defaultMessage: 'If you {RefertoUnderwriter}, underwriting will contact you within 24 hours to discuss any concerns that the virtual inspection has identified or to confirm the acceptability of this policy quote.',
    },
    waitOrBindContinueToIssueMessage: {
        id: 'custom.quoteandbind.ho.quote-clause-table.waitOrBindContinueToIssueMessage',
        defaultMessage: 'You will enter the payment information and the application will be issued. The virtual inspection will be reviewed post-issuance.'
    },
    waitOrBindReferToUWMessage: {
        id: 'custom.quoteandbind.ho.quote-clause-table.waitOrBindReferToUWMessage',
        defaultMessage: 'The application and virtual inspection will be reviewed by underwriting. Underwriting will communicate a decision within 24 hours.'
    },
});
