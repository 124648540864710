import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
// import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class WniChangeConflict {
    static getChangeConflictData(jobID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangeConflict'),
            'getChangeConflictData', [jobID], additionalHeaders);
    }

    static overrideConflictData(jobID, action, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangeConflict'),
            'overrideConflictData', [jobID, action], additionalHeaders);
    }
}