import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleTableTitle: {
        id: 'CommercialProperty.directives.schedules.complexSchedulePopup.Scheduled Table',
        defaultMessage: 'Scheduled Table',
    },
    scheduleAdd: {
        id: 'CommercialProperty.directives.schedules.complexSchedulePopup.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'CommercialProperty.directives.schedules.complexSchedulePopup.table.del',
        defaultMessage: 'Delete Selected'
    },
    viewOrEdit: {
        id: 'CommercialProperty.directives.schedules.complexSchedulePopup.table.viewOrEdit',
        defaultMessage: 'View/Edit'
    },
});