import React, {
    useCallback,
} from 'react';
import {
    RTMVRService,
    RTCLUEService,
    RTMultiQuoteService,
} from 'wni-capability-quoteandbind-rt';
import { WALIncidentsAndReportsPage,} from 'wni-capability-quoteandbind-wal-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import RTStaticMessage from './RTIncidentsAndReportsPage.static';

function RTIncidentsAndReportsPage(props) {

    const {
        currentStep: { id: currentPageId },
        wizardData: submissionVM,
        updateWizardPageData,
        disableAddButton,
        newlyAddedOperators,
        hideOrDisableIcons,
        filteredManualCLUE,
        filteredManualMVR,
    } = props;

    const {
        authHeader
    } = useAuthentication();

    const onDefaultPageNext = useCallback(async () =>{
        const {
            jobID,
            sessionUUID,
            baseData: {
                periodStatus,
            },
        } = submissionVM.value;

        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
            submissionVM.value = await RTMultiQuoteService.processQuote(
            // submissionVM.value = await processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM
    }, [authHeader, submissionVM, updateWizardPageData]);
    
    return (
        <WALIncidentsAndReportsPage
            productName = 'roadTrail'
            currentStepPageId = {currentPageId}
            wtMVRService = {RTMVRService}
            wtCLUEService = {RTCLUEService}
            wtStaticMessage = {RTStaticMessage}
            disableAddButton={disableAddButton}
            newlyAddedOperators={newlyAddedOperators}
            hideOrDisableIcons={hideOrDisableIcons}
            filteredManualCLUE={filteredManualCLUE}
            filteredManualMVR={filteredManualMVR}
            onIncidentsPageNext = {onDefaultPageNext}
            {...props}
        />
    );

}

RTIncidentsAndReportsPage.propTypes = WALIncidentsAndReportsPage.propTypes;
// RTIncidentsAndReportsPage.defaultProps = WALIncidentsAndReportsPage.defaultProps;

export default RTIncidentsAndReportsPage;