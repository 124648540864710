import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import FNOLLossLocation from '../../components/LossLocation/LossLocation';
import metadata from './WherePage.metadata.json5';
import messages from '../../FNOL.messages';

function FNOLWherePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const translator = useTranslator();
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLWherePage');

    const overrideProps = {
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        }
    };

    const resolvers = {
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation
        },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const validateClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        return !_.isNil(claimStatus);
    }, [state]);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [validateClaimStatus, registerInitialComponentValidation]);

    return (
        <WizardPage
            cancelLabel={translator(messages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}
FNOLWherePage.propTypes = wizardProps;
export default withRouter(FNOLWherePage);
