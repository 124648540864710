import React, {
    useState,
    useEffect
} from "react";
import _ from 'lodash'
import {
    WniCurrency,
    WniDropdownSelect,
    WniInputNumber,
    WniInputText
} from 'wni-common-base-components';

const originEditingValueFromTerm = (term) => {
    const valueType = _.get(term, 'valueType')
    if (valueType === 'Money') {
        return term.directValue
    }
    if (valueType === 'shorttext') {
        return term.directStringValue
    }
    if (valueType === 'datetime') {
        return term.directDateValue
    }
    return term.directValue
}

const ScheduleItemCovTerm = (props) => {
    const {
        coveragePropertyInfo,
        openedScheduleItemData,
        syncScheduleChange,
        setIsLoading,
        scheduleItemIndex,
        setOpenedScheduleItemData,
        showErrors,
        isEditable,
    } = props

    

    const scheduleItemTerms = _.get(openedScheduleItemData, 'scheduleItemTerms', [])
    const {
        id: covTermPatternCode
    } = coveragePropertyInfo
    const termIndex = scheduleItemTerms.findIndex((t) => t.code_Ext === covTermPatternCode)
    const term = scheduleItemTerms[termIndex]
    if (_.isNil(term)) {
        return null
    }

    const originEditingValue = originEditingValueFromTerm(term);
    const [editingValue, setEditingValue] = useState(originEditingValue)

    useEffect(() => {
        setEditingValue(originEditingValue)
    }, [originEditingValue])

    const {
        valueType,
        chosenTerm,
        options = [],
        name,
    } = term
    const termPath = `scheduleItemTerms[${termIndex}]`

    const setUpdatedTermToScheduleItem = async (updatedTerm) => {
        let clonedData = _.cloneDeep(openedScheduleItemData);
        _.set(clonedData, termPath, updatedTerm)
        if (syncScheduleChange) {
            setIsLoading(true)
            clonedData = await syncScheduleChange(clonedData, scheduleItemIndex);
            setIsLoading(false)
        }
        setOpenedScheduleItemData(clonedData);
    }

    if (_.isNil(valueType)) {
        const readOnlyCausedByOnlyOneAvailableValue = options.length === 1 && options[0].code === chosenTerm
        return <WniDropdownSelect
            id = {covTermPatternCode}
            key = {covTermPatternCode}
            label = {name}
            onValueChange={async (newChosenTerm) => {
                const updatedTerm = {
                    ...term,
                    chosenTerm: newChosenTerm,
                    updated: true,
                }
                await setUpdatedTermToScheduleItem(updatedTerm)
            }}
            showErrors={showErrors}
            availableValues={options}
            value= {term.chosenTerm}
            readOnly= {!isEditable || readOnlyCausedByOnlyOneAvailableValue || term.readOnly_Ext}
            required= {term.required}
        />
    }
    if (valueType === 'Money') {
        return <WniCurrency
            id = {covTermPatternCode}
            key = {covTermPatternCode}
            label = {name}
            dataType= 'number'
            value= {editingValue}
            onValueChange= {(value) => setEditingValue(value)}
            onBlur= {async () => {
                if (editingValue === originEditingValue) {
                    return
                }
                const updatedTerm = {
                    ...term,
                    directValue: editingValue,
                    updated: true,
                }
                await setUpdatedTermToScheduleItem(updatedTerm)
            }}
            maxValue={term.directValueMax}
            minValue= {term.directValueMin}
            required= {term.required}
            showFractions= {false}
            showErrors={showErrors}
        />
    }
    if (name === 'Year' && valueType === 'shorttext') {
        return <WniInputNumber
        id = {covTermPatternCode}
        key = {covTermPatternCode}
        label = {name}
        value= {editingValue}
        onValueChange= {(value) => setEditingValue(value)}
        onBlur= {async () => {
            if (editingValue === originEditingValue) {
                return
            }
            if (editingValue < 1000 || editingValue > 2999) {
                setEditingValue(originEditingValue)
                return
            }
            const updatedTerm = {
                ...term,
                directStringValue: editingValue,
                updated: true,
            }
            await setUpdatedTermToScheduleItem(updatedTerm)
        }}
        maxValue={2999}
        minValue= {1000}
        required= {term.required}
        showFractions= {false}
        showErrors={showErrors}
    />
    }
    if (valueType === 'shorttext') {
        return <WniInputText
            id = {covTermPatternCode}
            key = {covTermPatternCode}
            label = {name}
            value= {editingValue}
            onValueChange= {(value) => setEditingValue(value)}
            onBlur= {async () => {
                if (editingValue === originEditingValue) {
                    return
                }
                const updatedTerm = {
                    ...term,
                    directStringValue: editingValue,
                    updated: true,
                }
                await setUpdatedTermToScheduleItem(updatedTerm)
            }}
            required= {term.required}
            readOnly= {!isEditable || term.readOnly_Ext}
            showErrors={showErrors}
        />
    }
    return <div>{covTermPatternCode}</div>
}

export default ScheduleItemCovTerm
