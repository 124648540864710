import { defineMessages } from 'react-intl';

export default defineMessages({
    clNewMessage: {
        id: 'gateway.views.quote-detail.cl.renewal.clNewMessage',
        defaultMessage: 'This policy is being reviewed by underwriting to confirm renewal eligibility.'
    },
    clDraftMessage: {
        id: 'gateway.views.quote-detail.cl.renewal.clDraftMessage',
        defaultMessage: 'This policy is being reviewed by underwriting to confirm renewal eligibility.'
    },
    clQuotedTitle: {
        id: 'gateway.views.job-uwissues.cl.renewal.clQuotedTitle',
        defaultMessage: 'This Renewal is currently Pending Review'
    },
    clQuotedMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clQuotedMessage',
        defaultMessage: 'An underwriter is currently reviewing this policy to confirm if it is eligible for renewal.'
    },
    clApproved_ExtMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clApproved_ExtMessage',
        defaultMessage: 'This renewal has been approved by underwriting.'
    },
    clRenewingMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clRenewingMessage',
        defaultMessage: 'This policy/account is available to renew on or before the effective date of the policy renewal term.'
    },
    clBoundMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clBoundMessage',
        defaultMessage: 'This policy/account is available to renew on or before the effective date of the policy renewal term.'
    },
    clNotTakenMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clNotTakenMessage',
        defaultMessage: 'This Renewal has not been taken by request of the insured or agent.'
    },
    clNotTakingMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clNotTakingMessage',
        defaultMessage: 'This Renewal has not been taken by request of the insured or agent.'
    },
    clNonRenewedMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clNonRenewedMessage',
        defaultMessage: 'An Underwriter has reviewed this policy and determined it will not be Renewed.'
    },
    clNonRenewingMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clNonRenewingMessage',
        defaultMessage: 'An Underwriter has reviewed this policy and determined it will not be Renewed.'
    },
    clLockedTitle: {
        id: 'gateway.views.job-uwissues.cl.renewal.clLockedTitle',
        defaultMessage: 'This renewal is locked.'
    },
    clLockedMessage: {
        id: 'gateway.views.job-uwissues.cl.renewal.clLockedMessage',
        defaultMessage: 'This renewal is under review by underwriting and is not available to be viewed or edited right now.'
    }
});