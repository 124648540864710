import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { Icon, useModal } from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { WniInputText } from 'wni-common-base-components';

import CPGroupedRisksPopup from '../CPGroupedRisksPopup/CPGroupedRisksPopup';
import metadata from './CPGroupedRisksComponent.metadata.json5';

import { IconButton } from '@jutro/legacy/components';

function CPGroupedRisksComponent(props) {
    const {
        riskTreeRows,
        blanketVM,
        applyRisks,
        removeAllRisks,
        readOnly
    } = props;

    const translator = useTranslator();
    const modalApi = useModal();

    const viewModelService = useContext(ViewModelServiceContext);

    // const [isShowEditRisks, updateIsShowEditRisks] = useState([]);

    const getTableData = () => {
        if (_.isEmpty(riskTreeRows)) {
            return [];
        }
        const riskTreeData = _.clone(riskTreeRows);
        const finalTableData = [];
        riskTreeData.forEach((location) => {
            let buildingData;
            let specialClassData;
            let occupancyData;
            let businessIncomeData;
            let specialClassBusinessIncomeData;
            const locationCollapsed = !_.isNil(location.collapsed) ? location.collapsed : !location.hasChildren;
            Object.assign(location, { 'collapsed': locationCollapsed });
            finalTableData.push({
                ...location,
                buildingTreeRows: undefined,
                specialCLassTreeRows: undefined
            })
            if (!_.isEmpty(location.buildingTreeRows)) {
                buildingData = location.buildingTreeRows;
                for (const building of buildingData) {
                    const buildingCollapsed = !_.isNil(building.coverableColumnBasicInfo.collapsed) ? building.coverableColumnBasicInfo.collapsed : building.coverableColumnBasicInfo.hasChildren;
                    Object.assign(building.coverableColumnBasicInfo, { 'locationPublicID': building.locationPublicID, 'collapsed': buildingCollapsed });
                    finalTableData.push({
                        ...building.coverableColumnBasicInfo,
                        selected: building.selected,
                        checkboxVisible: building.checkboxVisible
                    })
                    if (!_.isEmpty(building.occupancyTreeRows)) {
                        occupancyData = building.occupancyTreeRows;
                        for (const occupancy of occupancyData) {
                            const occupancyCollapsed = !_.isNil(occupancy.coverableColumnBasicInfo.collapsed) ? occupancy.coverableColumnBasicInfo.collapsed : occupancy.coverableColumnBasicInfo.hasChildren;
                            Object.assign(occupancy.coverableColumnBasicInfo, { 'locationPublicID': building.locationPublicID, 'buildingPublicID': occupancy.buildingPublicID, 'collapsed': occupancyCollapsed });
                            finalTableData.push({
                                ...occupancy.coverableColumnBasicInfo,
                                selected: occupancy.selected,
                                checkboxVisible: occupancy.checkboxVisible
                            })
                            if (!_.isEmpty(occupancy.personalPropertyTreeRows)) {
                                const personalPropertyData = occupancy.personalPropertyTreeRows;
                                for (const personalProperty of personalPropertyData) {
                                    Object.assign(personalProperty.coverableColumnBasicInfo, { 'locationPublicID': building.locationPublicID, 'buildingPublicID': occupancy.buildingPublicID, 'occupancyPublicID': personalProperty.occupancyPublicID });
                                    finalTableData.push({
                                        ...personalProperty.coverableColumnBasicInfo,
                                        selected: personalProperty.selected,
                                        checkboxVisible: personalProperty.checkboxVisible
                                    })
                                }
                            }
                        }
                    }
                    if (!_.isEmpty(building.businessIncomeRows)) {
                        businessIncomeData = building.businessIncomeRows;
                        for (const businessIncome of businessIncomeData) {
                            Object.assign(businessIncome.coverableColumnBasicInfo, { 'locationPublicID': building.locationPublicID, 'buildingPublicID': businessIncome.buildingPublicID });
                            finalTableData.push({
                                ...businessIncome.coverableColumnBasicInfo,
                                selected: businessIncome.selected,
                                checkboxVisible: businessIncome.checkboxVisible
                            })
                        }
                    }
                }
            }
            if (!_.isEmpty(location.specialCLassTreeRows)) {
                specialClassData = location.specialCLassTreeRows;
                for (const specialClass of specialClassData) {
                    const specialClassCollapsed = !_.isNil(specialClass.coverableColumnBasicInfo.collapsed) ? specialClass.coverableColumnBasicInfo.collapsed : specialClass.coverableColumnBasicInfo.hasChildren;
                    Object.assign(specialClass.coverableColumnBasicInfo, { 'locationPublicID': specialClass.locationPublicID, 'collapsed': specialClassCollapsed });
                    finalTableData.push({
                        ...specialClass.coverableColumnBasicInfo,
                        selected: specialClass.selected,
                        checkboxVisible: specialClass.checkboxVisible
                    })
                    if (!_.isEmpty(specialClass.specialClassBusinessIncomeTreeRows)) {
                        specialClassBusinessIncomeData = specialClass.specialClassBusinessIncomeTreeRows;
                        for (const specialClassBusinessIncome of specialClassBusinessIncomeData) {
                            Object.assign(specialClassBusinessIncome.coverableColumnBasicInfo, { 'locationPublicID': specialClass.locationPublicID, 'specialClassPublicID': specialClassBusinessIncome.specialClassPublicID });
                            finalTableData.push({
                                ...specialClassBusinessIncome.coverableColumnBasicInfo,
                                selected: specialClassBusinessIncome.selected,
                                checkboxVisible: specialClassBusinessIncome.checkboxVisible
                            })
                        }
                    }
                }
            }
        })
        return finalTableData.filter(elt => !_.isNil(elt));
    }

    const renderDescriptionCell = (item, isShowSelectedIcon) => {
        const itemSelected = item.selected
        const isVisibleArrow = item.hasChildren;

        let indentClassNames;
        switch (item.coverableType) {
            case 'Building':
            case 'Special Class':
                indentClassNames = 'pdl-10'
                break;
            case 'Occupancy':
            case 'Business Income':
            case 'Special Class Business Income':
                indentClassNames = 'pdl-20'
                break;
            case 'Personal Property':
                indentClassNames = 'pdl-30'
                break;
            default:
                break;
        }
        const dom = <Flex justifyContent="left" alignContent="middle" wrap="false" className={indentClassNames}>
            {itemSelected && isShowSelectedIcon && <Icon
                id={`validationIcon${item.publicID}`}
                icon="gw-check-circle"
                className="wni-icon-success"
            />}
            <WniInputText readOnly value={item.description} />
            {isVisibleArrow && <IconButton
                id={`expand${item.publicID}`}
                icon="gw-expand-more"
                iconColor="dark"
                size="medium"
            />}
        </Flex>
        return WniTableRowUtil.renderCell(item.publicID, dom)
    }

    const renderLimitCell = (item) => {
        let limitPath;
        switch (item.coverableType) {
            case 'Building':
            case 'Special Class':
                limitPath = 'buildingLimit'
                break;
            case 'Occupancy':
            case 'Business Income':
            case 'Special Class Business Income':
                limitPath = 'businessIncomeLimit'
                break;
            case 'Personal Property':
                limitPath = 'personalPropertyLimit'
                break;
            default:
                break;
        }

        const removeCommaValue = _.replace(item[limitPath], ',', '');

        return (
            <CurrencyField
                id={`currency_${item.publicID}`}
                value={{
                    amount: parseFloat(removeCommaValue),
                    currency: 'USD'
                }}
                readOnly
                hideLabel
            />
        );
    }

    const editRisks = () => {
        modalApi.showModal(
            <CPGroupedRisksPopup
                applyRisks={applyRisks}
                tableData={getTableData()}
                renderDescriptionCell={renderDescriptionCell}
            />
        )
            .then()
            .catch(_.noop);
    }

    const isEditableRisks = () => {
        const tableData = getTableData();

        const checkboxVisible = _.find(tableData, (item) => item.checkboxVisible)

        if (_.isEmpty(checkboxVisible)) {
            return false;
        }
        return true;
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                labelPosition: 'left',
            },
            editRisks: {
                onClick: editRisks,
                visible: !readOnly,
                disabled: !isEditableRisks()
            },
            removeAllRisks: {
                onClick: removeAllRisks,
                visible: !readOnly,
            },
            risksTable: {
                data: getTableData()
            },
            description: {
                renderCell: (item) => { return renderDescriptionCell(item, true); }
            },
            limit: {
                renderCell: renderLimitCell
            }
        };
    }, [riskTreeRows]);

    //---------------------
    const overrideProps = generateOverrides();

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskTreeRows}
            overrideProps={overrideProps}
        />
    );
}

export default CPGroupedRisksComponent;