import { defineMessages } from 'react-intl';

export default defineMessages({
    saveAnd: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-buttons.Save and:',
        defaultMessage: 'Save and:',
    },
    cancelLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-buttons.Cancel',
        defaultMessage: 'Cancel',
    },
    saveNextLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-buttons.Next',
        defaultMessage: 'Next',
    },
    continueToCoveragesLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-buttons.Continue to Coverages',
        defaultMessage: 'Continue to Coverages',
    }
});
