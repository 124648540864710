
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { PUUnderlyingPage } from 'wni-capability-quoteandbind-pu-react';

function PUUnderlyingChangePage(props) {

    return (
        <PUUnderlyingPage {...props} checkRequiredForIssuance={false} />
    );

}

PUUnderlyingChangePage.propTypes = PUUnderlyingPage.propTypes;
PUUnderlyingChangePage.defaultProps = PUUnderlyingPage.defaultProps;

export default PUUnderlyingChangePage;