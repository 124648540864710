import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import { generateFieldsVisible } from '../../../CUUnderlyingPage.util';
import metadata from './WatercraftDetails.metadata.json5';

function WatercraftDetails(props) {
    const { currentRow, writeValue, onValidate, showErrors, isReadOnly } =
        props;

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false,
        },
        ...generateFieldsVisible(currentRow),
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {},
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default WatercraftDetails;
