import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('policyDBA'), method, data, additionalHeaders);
}

export default class WniDBAService {
    static removeDBAs(quoteID, sessionUUID, publicIDs, ANIId, authHeader) {
        return processSubmission('removeDBAs', [quoteID, sessionUUID, publicIDs, ANIId], authHeader);
    }

    static saveDBA(quoteID, sessionUUID, dba, authHeader) {
        return processSubmission('saveDBA', [quoteID, sessionUUID, dba], authHeader);
    }

    static setAsPrimary(quoteID, sessionUUID, id, authHeader) {
        return processSubmission('setAsPrimary', [quoteID, sessionUUID, id], authHeader);
    }
}