/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
    UMBUnUnderinsuredMotCov_Ext: {
        id: 'wni.custom.products.PU.tooltip.Uninsured and underinsured maximum limit available is $1,000,000.',
        defaultMessage: 'Uninsured and underinsured maximum limit available is $1,000,000.'
    },
    UMBExpandedDefinitionOfInsured_Ext: {
        id: 'wni.custom.products.PU.tooltip.Add this coverage for other residents of the household that are not family members. For example a Domestic Partner. This endorsement must also be present on the underlying homeowners policy to be eligible.',
        defaultMessage: 'Add this coverage for other residents of the household that are not family members. For example a Domestic Partner. This endorsement must also be present on the underlying homeowners policy to be eligible.'
    },
    UMBAutoOutsideOfUSOrCA_Ext: {
        id: 'wni.custom.products.PU.tooltip.Add this coverage for other residents of the household that are not family members. For example a Domestic Partner. This endorsement must also be present on the underlying homeowners policy to be eligible.',
        defaultMessage: 'Add this coverage for other residents of the household that are not family members. For example a Domestic Partner. This endorsement must also be present on the underlying homeowners policy to be eligible.'
    },
    UMBExcBusiPurCov_Ext: {
        id: 'wni.custom.products.PU.tooltip.Add this coverage for liability arising out of a business not owned by the policyholder; available for clerical, sales, or teachers. This endorsement must also be present on the underlying homeowners policy to be eligible.',
        defaultMessage: 'Add this coverage for liability arising out of a business not owned by the policyholder; available for clerical, sales, or teachers. This endorsement must also be present on the underlying homeowners policy to be eligible.'
    },
    UMBExcInciFarmPerLiabCov_Ext: {
        id: `custom.products.PU.tooltip.Add this coverage for liability arising out of a  policyholder's incidental farming activities on or away from premises for garden plots only. This endorsement must also be present on the underlying homeowners policy to be eligible.`,
        defaultMessage: `Add this coverage for liability arising out of a  policyholder's incidental farming activities on or away from premises for garden plots only. This endorsement must also be present on the underlying homeowners policy to be eligible.`
    },
    UMBExcPerIncOccLiabilityCov_Ext: {
        id: 'wni.custom.products.PU.tooltip.Add this coverage for a related private structure on premises with an office, professional, private school, or studio occupancy including incidental liability. This endorsement must also be present on the underlying homeowners policy to be eligible.',
        defaultMessage: 'Add this coverage for a related private structure on premises with an office, professional, private school, or studio occupancy including incidental liability. This endorsement must also be present on the underlying homeowners policy to be eligible.'
    },
})