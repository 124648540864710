import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import metadata from './AgencyDetailsPopup.metadata.json5';
import styles from './AgencyDetailsPopup.module.scss';
import AgencyDetailsMatchPopup from './AgencyDetailsMatchPopup';

import { Button } from '@jutro/legacy/components';

function AgencyDetailsPopup(props) {
    const modalApi = useWniModal();
    const {
        actionBtnLabel,
        cancelBtnLabel,
        title,
        size,
        isOpen,
        onResolve,
        onReject,
        producerCodePath,
        // authHeader,
        onAgencyChange,
        updateAgencyDisplayName,
        updateSearchValue,
        searchValue,
        agencyVM, // : initialAgencyVM,
        updateProducerCodePublicID,
        updateLicensedAndServicingProducerOptions,
        useAuthenticationData,
        useDependenciesData,
        viewModelService: vmService
    } = props;

    const { authHeader, authUserData } = useAuthenticationData || useAuthentication();
    const [pageConfig, updatePageConfig] = useState(WniTableRowUtil.defaultPageConfig);
    const [agencyResponseValue, updateAgencyResponseValue] = useState([]);
    const [selectRow, updateSelectRow] = useState({});

    useEffect(() => {
        WniAccountService.getAgencyMatchData(searchValue, 5, authHeader).then((agencyResponse) => {
            if (!_.isNil(agencyResponse.length) && agencyResponse.length > 0) {
                const fiveAgency = agencyResponse.slice(0, 5);
                updateAgencyResponseValue(fiveAgency);
            }
        });
    }, [searchValue, authHeader]);

    const viewModelService = vmService || useContext(ViewModelServiceContext);

    const handleSave = useCallback(() => {
        if (selectRow.code) {
            const agencyDisplay = `${selectRow.code} - `
                // + `${selectRow.orgName_Ext ? `${selectRow.orgName_Ext}-` : null} `
                + `${selectRow.description ? `${selectRow.description}-` : null} `
                + `${selectRow.city_Ext ? selectRow.city_Ext : null}, `
                + `${selectRow.state_Ext ? selectRow.state_Ext : null}`;
            updateAgencyDisplayName(agencyDisplay);
            updateSearchValue(selectRow.code);
            updateProducerCodePublicID(selectRow.publicID);
            /* if (producerCodePath === 'producerCode_Ext') {
                _.set(agencyVM, 'agencyDisplay_Ext', agencyDisplay);
            } */

            onResolve(selectRow);
        }
    }, [onResolve, selectRow]);

    const showModal = useCallback(
        () => {
            /* const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
            let searchVal = '';
            if (!isExternalUser) {
                searchVal = searchValue;
            } */
            const componentProps = {
                title: 'Agency Search Data',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'OK',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                // authHeader: authHeader,
                agencyVM,
                updateAgencyDisplayName,
                updateSearchValue,
                // searchValue: searchVal,
                viewModelService,
                producerCodePath,
                useAuthenticationData,
                useDependenciesData,
                // updateProducerCodePublicID
            };
            return modalApi.showModal(
                <AgencyDetailsMatchPopup {...componentProps} />
            );
        },
        // eslint-disable-next-line max-len
        [agencyVM, searchValue, authHeader, updateAgencyDisplayName, viewModelService, producerCodePath]
    );

    const openAgencyMatchPopup = useCallback(() => {
        Promise.resolve(onReject()).then(() => {
            showModal().then((res) => {
                let producerCode = '';
                const code = _.get(res, 'code');
                const publicID = _.get(res, 'publicID');
                if (!_.isUndefined(code)) {
                    updateSearchValue(code);
                    updateProducerCodePublicID(_.get(res, 'publicID'));
                    producerCode = res;
                }
                if (onAgencyChange) {
                    onAgencyChange(producerCode, producerCodePath);
                }
                updateLicensedAndServicingProducerOptions(publicID)
            }).catch(() => {
                _.noop();
            });
        });
    }, [showModal, onAgencyChange, producerCodePath]);

    const hightLight = (activeRow) => {
        const selectedPublicID = _.get(activeRow, 'publicID');
        WniTableRowUtil.setTablePublicIDSelected(selectedPublicID, 'exisitingAgencyMatchTable');
    };
    const onTableConfigPage = useCallback((obj) => {
        updatePageConfig(obj);
    }, []);
    const onSort = useCallback((a, b) => {
        hightLight(selectRow);
        return DatatableUtil.sortString(a, b);
    }, [selectRow]);

    useEffect(() => {
        hightLight(selectRow);
    }, [selectRow, onTableConfigPage, pageConfig]);

    const getSelectedFn = (item) => {
        if (item.code === selectRow.code) {
            updateSelectRow({});
        } else {
            updateSelectRow(item);
        }
        hightLight(item);
    };
    const filterAgencyCodeTable = (val) => {
        return (row) => row.code.indexOf(val) !== -1;
    };

    const filterAgencyNameTable = (val) => {
        return (row) => row.orgName_Ext.indexOf(val) !== -1;
    };

    const overrideProps = {
        exisitingAgencyMatchTable: {
            data: agencyResponseValue,
            onConfigChange: onTableConfigPage,
            config: pageConfig
        },
        agencyCodeColumn: {
            filter: filterAgencyCodeTable
        },
        agencyNamecolumn: {
            filter: filterAgencyNameTable,
            renderCell: (item) => WniTableRowUtil.renderCell(item.publicID, item.description)
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            agencyVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getSelectedFn: (item, index, e) => getSelectedFn(item, index, e),
            onSort
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} onClose={onReject} />
            <ModalBody id="agencyDatailsPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={agencyVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Grid columns={['1fr', '2fr']} justifyItems="between">
                    <Button onClick={openAgencyMatchPopup} className="btn-link pv-0 ml-0" type="text">See All Records</Button>
                    <div className="align-right">
                        <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                        <Button onClick={handleSave} type="filled" disabled={!selectRow.code}>{actionBtnLabel}</Button>
                    </div>
                </Grid>
            </ModalFooter>
        </ModalNext>
    );
}

AgencyDetailsPopup.propTypes = {
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    onAgencyChange: PropTypes.func.isRequired,
    updateAgencyDisplayName: PropTypes.func,
    updateSearchValue: PropTypes.func,
    // authHeader: PropTypes.shape({}).isRequired,
    agencyVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    producerCodePath: PropTypes.string.isRequired,
    searchValue: PropTypes.string,
    updateProducerCodePublicID: PropTypes.func,
    updateLicensedAndServicingProducerOptions: PropTypes.func
};
AgencyDetailsPopup.defaultProps = {
    searchValue: '',
    size: 'md',
    updateAgencyDisplayName: _.noop,
    updateSearchValue: _.noop,
    updateProducerCodePublicID: _.noop,
    updateLicensedAndServicingProducerOptions: _.noop,
};

export default AgencyDetailsPopup;
