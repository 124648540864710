import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class WniActivitiesService {
    static getActivitiesForUser(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayWniActivity'),
            'getActivitiesForUser',
            [],
            additionalHeaders
        );
    }

    static getActivitiesForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniActivity'),
            'getActivitiesForPolicy', [policyNumber], additionalHeaders);
    }

    static getActivitiesForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniActivity'),
            'getActivitiesForAccount', [accountNumber], additionalHeaders);
    }

    static getActivitiesForJob(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniActivity'),
            'getActivitiesForJob', [jobNumber], additionalHeaders);
    }
    static createNewActivityAssignToUW(activity, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniActivity'),
            'createNewActivityAssignToUW', [activity], additionalHeaders);
    }

    static createNewActivityAssignToPLCancellationQueue(activity, cancellationDate, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayWniActivity'),
            'createNewActivityAssignToPLCancellationQueue', [activity, cancellationDate], additionalHeaders);
    }
}
