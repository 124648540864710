import _ from 'lodash';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caLocationService'), method, data, additionalHeaders);
}

export default class CALocationService {
    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} location
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateLocation(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'updateLocation',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

    /**
     *
     *  @param {string} quoteID 
     * @param {string} sessionUUID 
     *@param {Array} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeLocations(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeLocations',
            [quoteID, sessionUUID, publicIDs],
            authHeader
        );
    }

    /**
     *
     *  @param {string} quoteID 
     * @param {string} sessionUUID 
     *@param {Array} CATerritoryDTO
     * @param {Object} authHeader
     * @returns {Promise}
     */
     static searchTerritoryCode(quoteID, sessionUUID, CATerritoryDTO, authHeader = {}) {
        return processSubmission(
            'searchTerritoryCode',
            [quoteID, sessionUUID, CATerritoryDTO],
            authHeader
        );
    }

    static addExistingLocations(quoteID, existingLocationPublicIds, sessionUUID, authHeader = {}) {
        return processSubmission('addExistingLocations',[quoteID, existingLocationPublicIds, sessionUUID], authHeader);
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader
        );
    }

}