import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import WALPolicyChangeStartPage from './pages/Start/WALPolicyChangeStartPage';

import WALPaySuccessChangePage from './pages/PaySuccess/WALPaySuccessChangePage';

//
import WALWatercraftsChangePage from './pages/Watercrafts/WALWatercraftsChangePage';
import WALWatercraftsChangeReadOnlyPage from './pages/Watercrafts/WALWatercraftsChangePage.readonly';

import WALOperatorsChangePage from './pages/Operators/WALOperatorsChangePage';
import WALOperatorsChangeReadOnlyPage from './pages/Operators/WALOperatorsChangePage.readonly';

import WALCoveragesChangePage from './pages/Coverages/WALCoveragesChangePage';
import WALCoveragesChangeReadOnlyPage from './pages/Coverages/WALCoveragesChangePage.readonly';

import WALPolicyDetailsChangePage from './pages/PolicyDetails/WALPolicyDetailsChangePage';
import WALPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/WALPolicyDetailsChangePage.readonly';

import WALIncidentsAndReportsChangePage from './pages/IncidentsAndReports/WALIncidentsAndReportsChangePage';
import WALIncidentsAndReportsChangeReadOnlyPage from './pages/IncidentsAndReports/WALIncidentsAndReportsChangePage.readonly';

import WALQuoteChangePage  from './pages/Quote/WALQuoteChangePage';
import WALQuoteChangeReadOnlyPage from './pages/Quote/WALQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        WALPolicyChangeStartPage: {component: 'WALPolicyChangeStartPage'},
        WALPaySuccessChangePage: { component: 'WALPaySuccessChangePage' },

        WALWatercraftsChangePage: {component: 'WALWatercraftsChangePage'},
        WALWatercraftsChangeReadOnlyPage: { component: 'WALWatercraftsChangeReadOnlyPage' },

        WALOperatorsChangePage: {component: 'WALOperatorsChangePage'},
        WALOperatorsChangeReadOnlyPage: {component: 'WALOperatorsChangeReadOnlyPage'},

        WALCoveragesChangePage: {component: 'WALCoveragesChangePage'},
        WALCoveragesChangeReadOnlyPage: {component: 'WALCoveragesChangeReadOnlyPage'},

        WALPolicyDetailsChangePage: {component: 'WALPolicyDetailsChangePage'},
        WALPolicyDetailsChangeReadOnlyPage: {component: 'WALPolicyDetailsChangeReadOnlyPage'},
        
        WALIncidentsAndReportsChangePage: {component: 'WALIncidentsAndReportsChangePage'},
        WALIncidentsAndReportsChangeReadOnlyPage: {component: 'WALIncidentsAndReportsChangeReadOnlyPage'},

        WALQuoteChangePage: {component: 'WALQuoteChangePage'},
        WALQuoteChangeReadOnlyPage: {component: 'WALQuoteChangeReadOnlyPage'},

    },
    {
        WALPolicyChangeStartPage,
        WALPaySuccessChangePage,

        WALWatercraftsChangePage,
        WALWatercraftsChangeReadOnlyPage,

        WALOperatorsChangePage,
        WALOperatorsChangeReadOnlyPage,
        
        WALCoveragesChangePage,
        WALCoveragesChangeReadOnlyPage,
        
        WALPolicyDetailsChangePage,
        WALPolicyDetailsChangeReadOnlyPage,

        WALIncidentsAndReportsChangePage,
        WALIncidentsAndReportsChangeReadOnlyPage,
        //
        WALQuoteChangePage,
        WALQuoteChangeReadOnlyPage,

    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as WALPolicyChangeWizard } from './WALPolicyChangeWizard';
