/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
    namedInsuredOccupancyFullTime: {
        id: 'wni.custom.products.HO.tooltip.Full time: This is their primary residence.',
        defaultMessage: 'Full time: This is their primary residence.'
    },
    namedInsuredOccupancyAtLeastHalfOfYear: {
        id: 'wni.custom.products.HO.tooltip.At least half of each year: This is their primary residence, but the insureds occupy a residence elsewhere for less than 50% of the year.',
        defaultMessage: 'At least half of each year: This is their primary residence, but the insureds occupy a residence elsewhere for less than 50% of the year.'
    },
    namedInsuredOccupancyLessThanHalfTheYear: {
        id: 'wni.custom.products.HO.tooltip.Less than half the year: Insureds occupy this property less than half of the year. Ex: Seasonal Residence, Cabin etc…',
        defaultMessage: 'Less than half the year: Insureds occupy this property less than half of the year. Ex: Seasonal Residence, Cabin etc…'
    },
    namedInsuredOccupancyVacant: {
        id: 'wni.custom.products.HO.tooltip.Vacant: No residents or contents',
        defaultMessage: 'Vacant: No residents or contents'
    },
    namedInsuredOccupancyUnoccupied: {
        id: 'wni.custom.products.HO.tooltip.Unoccupied: No current residents but not devoid of furniture/belongings',
        defaultMessage: 'Unoccupied: No current residents but not devoid of furniture/belongings'
    },
    hasCompletedMaintenance: {
        id: 'wni.custom.products.HO.tooltip.Recommended Maintenance for Wood Roofs:',
        defaultMessage: 'Recommended Maintenance for Wood Roofs:'
    },
    hasCompletedMaintenanceRegularly: {
        id: 'wni.custom.products.HO.tooltip.Regularly clean roof of leaf litter and debris by sweeping or washing',
        defaultMessage: 'Regularly clean roof of leaf litter and debris by sweeping or washing'
    },
    hasCompletedMaintenancePowerWashing: {
        id: 'wni.custom.products.HO.tooltip.Power washing',
        defaultMessage: 'Power washing'
    },
    hasCompletedMaintenanceCleanGutters: {
        id: 'wni.custom.products.HO.tooltip.Clean gutters',
        defaultMessage: 'Clean gutters'
    },
    hasCompletedMaintenanceBranches: {
        id: 'wni.custom.products.HO.tooltip.Branches overhanging roof are trimmed back',
        defaultMessage: 'Branches overhanging roof are trimmed back'
    },
    hasCompletedMaintenanceSurface: {
        id: 'wni.custom.products.HO.tooltip.Surface treatments applied such as water repellent, UV protection, fungicides.',
        defaultMessage: 'Surface treatments applied such as water repellent, UV protection, fungicides.'
    },
    homeMonitoringSystemsAutomaticWater: {
        id: 'wni.custom.products.HO.tooltip.Automatic Water Shut-Off System: A system that detects water by monitoring flows in the pipe or elsewhere and automatically turns off the water when a leak is suspected. This system requires installation by a plumber and requires documentation of the installation.',
        defaultMessage: 'Automatic Water Shut-Off System: A system that detects water by monitoring flows in the pipe or elsewhere and automatically turns off the water when a leak is suspected. This system requires installation by a plumber and requires documentation of the installation.'
    },
    homeMonitoringSystemsWaterMonitoringSystem: {
        id: 'wni.custom.products.HO.tooltip.Water Monitoring System:  A system that notifies the insured when they are away from the premise if a leak or broken pipe/hose is detected. This discount requires documentation that a system is installed.',
        defaultMessage: 'Water Monitoring System:  A system that notifies the insured when they are away from the premise if a leak or broken pipe/hose is detected. This discount requires documentation that a system is installed.'
    },
    homeMonitoringSystemsSmartThermostat: {
        id: 'wni.custom.products.HO.tooltip.Smart Thermostat:  A system that communicates with the insured when they are away from the premise if the temperature in the dwelling has dropped. This discount requires documentation that a system is installed.',
        defaultMessage: 'Smart Thermostat:  A system that communicates with the insured when they are away from the premise if the temperature in the dwelling has dropped. This discount requires documentation that a system is installed.'
    },
    HOPCovAValuationStandard: {
        id: 'wni.custom.products.HO.tooltip.Standard: This option calculates replacement cost using modern materials and construction. Available for all homes, but those built prior to 1940 require the Functional Replacement Cost endorsement.',
        defaultMessage: 'Standard: This option calculates replacement cost using modern materials and construction. Available for all homes, but those built prior to 1940 require the Functional Replacement Cost endorsement.'
    },
    HOPCovAValuationVintage: {
        id: 'wni.custom.products.HO.tooltip.Vintage: This option calculates the cost using the type of building options for homes built before 1940. Available for homes built before 1940.',
        defaultMessage: 'Vintage: This option calculates the cost using the type of building options for homes built before 1940. Available for homes built before 1940.'
    },
    HOPCovAValuationStdVintage: {
        id: 'wni.custom.products.HO.tooltip.Std/Vintage: This option calculates the cost using modern construction techniques with vintage interior finish materials. Available for homes built before 1940.',
        defaultMessage: 'Std/Vintage: This option calculates the cost using modern construction techniques with vintage interior finish materials. Available for homes built before 1940.'
    },
    HOPCovARoofTypeValuation_Ext: {
        id: 'wni.custom.products.HO.tooltip.This coverage provides replacement cost value for a roof damaged by the windstorm or hail peril. If this coverage is not purchased then in the event of a claim for windstorm or hail, the roof will be valued using the ACV model and depreciation will be applied to the total claim payout.',
        defaultMessage: 'This coverage provides replacement cost value for a roof damaged by the windstorm or hail peril. If this coverage is not purchased then in the event of a claim for windstorm or hail, the roof will be valued using the ACV model and depreciation will be applied to the total claim payout.'
    },
    HOPOtherResidentsOfHousehold_Ext: {
        id: 'wni.custom.products.HO.tooltip.Extends the definition of insured to a person who resides with the insured.',
        defaultMessage: 'Extends the definition of insured to a person who resides with the insured.'
    },
    // HOPAssistedLivingCare: {
    //     id: 'wni.custom.products.HO.tooltip.Coverage for personal property,additional living costs, and personal liability for a person related to the policyholder and resides in an assisted living facility.',
    //     defaultMessage: 'Coverage for personal property,additional living costs, and personal liability for a person related to the policyholder and resides in an assisted living facility.'
    // },
    HOPCovForResidentOfAssisLivingFacility_Ext: {
        id: 'wni.custom.products.HO.tooltip.HOPCovForResidentOfAssisLivingFacility_Ext.Coverage for personal property,additional living costs, and personal liability for a person related to the policyholder and resides in an assisted living facility.',
        defaultMessage: 'Coverage for personal property,additional living costs, and personal liability for a person related to the policyholder and resides in an assisted living facility.'
    },
    HOPDwellingUnderConstruction: {
        id: 'wni.custom.products.HO.tooltip.Covers theft of building materials and supplies during construction.',
        defaultMessage: 'Covers theft of building materials and supplies during construction.'
    },
    HOPCollapse: {
        id: 'wni.custom.products.HO.tooltip.Covers loss caused by collapse to a dwelling under construction.',
        defaultMessage: 'Covers loss caused by collapse to a dwelling under construction.'
    },
    HOPOfficeProfessionalSchoolStudioOccupancyLocation_Ext: {
        id: 'wni.custom.products.HO.tooltip.Coverage for a related private structure on premises with an office, professional, private school, or studio occupancy including incidental liability.',
        defaultMessage: 'Coverage for a related private structure on premises with an office, professional, private school, or studio occupancy including incidental liability.'
    },
    HOPHiLmtOnPersonalPropUsedForBuzPurpose_Ext: {
        id: 'wni.custom.products.HO.tooltip.Increases the special limit that applies to business personal property.',
        defaultMessage: 'Increases the special limit that applies to business personal property.'
    },
    HOPNonOwnedBusinessActivities_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides coverage for liability arising out of a business not owned by the policyholder; available for clerical, sales, or teachers.',
        defaultMessage: 'Provides coverage for liability arising out of a business not owned by the policyholder; available for clerical, sales, or teachers.'
    },
    HOPSpecialComputerCoverage: {
        id: 'wni.custom.products.HO.tooltip.Computer coverage for computer hardware & software option not permitted on a policy that included an endorsement providing special coverage for Coverage C Deductibles; Laptop - $100, Home data processing equipment - $50',
        defaultMessage: 'Computer coverage for computer hardware & software option not permitted on a policy that included an endorsement providing special coverage for Coverage C Deductibles; Laptop - $100, Home data processing equipment - $50'
    },
    HOPSpecifiedAddlAmountOfInsurance_Ext: {
        id: 'wni.custom.products.HO.tooltip.An additional amount of insurance can be provided for the residence when the loss exceeds the scheduled Coverage A limit.',
        defaultMessage: 'An additional amount of insurance can be provided for the residence when the loss exceeds the scheduled Coverage A limit.'
    },
    HOPAdditionalLimitsCoveragesABCD_Ext: {
        id: 'wni.custom.products.HO.tooltip.An additional amount of insurance can be provided for the residence when the loss exceeds the coverage A limit-Guaranteed Replacement Cost.',
        defaultMessage: 'An additional amount of insurance can be provided for the residence when the loss exceeds the coverage A limit-Guaranteed Replacement Cost.'
    },
    HOPFuncReplaceCostLoss_Ext_text1: {
        id: 'wni.custom.products.HO.tooltip.Changes loss settlement provisions to allow for the repair or replacement of a building with commonly used and less costly construction materials and methods that are functionally equivalent to obsolete, antique, or custom construction materials and methods used in the original construction of the building; Coverage A must be at 100% of its functional replacement cost.',
        defaultMessage: 'Changes loss settlement provisions to allow for the repair or replacement of a building with commonly used and less costly construction materials and methods that are functionally equivalent to obsolete, antique, or custom construction materials and methods used in the original construction of the building; Coverage A must be at 100% of its functional replacement cost.'
    },
    HOPFuncReplaceCostLoss_Ext_text2: {
        id: 'wni.custom.products.HO.tooltip.10% or 25% additional amounts of insurance coverage can be added when the loss to residence exceeds Coverage A limit. Coverage A must be at 100% of residence\'s functional replacement cost.',
        defaultMessage: '10% or 25% additional amounts of insurance coverage can be added when the loss to residence exceeds Coverage A limit. Coverage A must be at 100% of residence\'s functional replacement cost.'
    },
    HOPModifiedReplaceCostTerms_Ext: {
        id: 'wni.custom.products.HO.tooltip.Coverage for partial losses can be provided on a replacement cost basis when Coverage A is less than 80% of its replacement value; the Coverage A limit as a percent of replacement value must be established at the time this coverage is added.',
        defaultMessage: 'Coverage for partial losses can be provided on a replacement cost basis when Coverage A is less than 80% of its replacement value; the Coverage A limit as a percent of replacement value must be established at the time this coverage is added.'
    },
    HOPIncidentalPropHiLmt_Ext: {
        id: 'wni.custom.products.HO.tooltip.Increases limits available for incidental property.',
        defaultMessage: 'Increases limits available for incidental property.'
    },
    HOPCyberProtectionInsurance_Ext: {
        id: 'wni.custom.products.HO.tooltip.Aggregate limit options for First Party loss (Data Recovery and System Restoration, Cyber Crime, Cyber Extortion, Cyber Bullying, Breach Notification Costs) and Third Party Claims.',
        defaultMessage: 'Aggregate limit options for First Party loss (Data Recovery and System Restoration, Cyber Crime, Cyber Extortion, Cyber Bullying, Breach Notification Costs) and Third Party Claims.'
    },
    HOPEarthquake: {
        id: 'wni.custom.products.HO.tooltip.Covers direct physical loss caused by earthquake - 2% deductible.',
        defaultMessage: 'Covers direct physical loss caused by earthquake - 2% deductible.'
    },
    HOPEquipBreakdownEnhanceEndorse_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides coverage for breakdown of appliances and other equipment at the insured dwelling.',
        defaultMessage: 'Provides coverage for breakdown of appliances and other equipment at the insured dwelling.'
    },
    HOPIncidentalFarming_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides liability arising out of a policyholder\'s incidental farming activities on or away from premises for garden plots only.',
        defaultMessage: 'Provides liability arising out of a policyholder\'s incidental farming activities on or away from premises for garden plots only.'
    },
    HOPSchedMotorGolfCartCov_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides all risks of direct physical loss coverage, with certain exceptions; collision coverage is included;  $500 deductible applies.',
        defaultMessage: 'Provides all risks of direct physical loss coverage, with certain exceptions; collision coverage is included;  $500 deductible applies.'
    },
    HOPGolfCartLiabCov_Ext: {
        id: 'wni.custom.products.HO.tooltip.Covers liability for owned golf carts used for other than golfing.',
        defaultMessage: 'Covers liability for owned golf carts used for other than golfing.'
    },
    HOPBlanketJewelryCoverage_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides coverage against all risk of direct physical loss with certain exceptions for unspecified jewelry.',
        defaultMessage: 'Provides coverage against all risk of direct physical loss with certain exceptions for unspecified jewelry.'
    },
    HOPIdentityFraudExpense_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides coverage for expenses incurred as the result of identity fraud.',
        defaultMessage: 'Provides coverage for expenses incurred as the result of identity fraud.'
    },
    HOPLossAssessmentSchedCov_Ext: {
        id: 'wni.custom.products.HO.tooltip.Coverage assessed by a  homeowners association including deductible  for damage resuting from a covered loss.',
        defaultMessage: 'Coverage assessed by a  homeowners association including deductible  for damage resuting from a covered loss.'
    },
    HOPEarthquakeLossAssessment_Ext: {
        id: 'wni.custom.products.HO.tooltip.Coverage assessed by a  homeowners association including deductible  for damage resuting from earthquake.',
        defaultMessage: 'Coverage assessed by a  homeowners association including deductible  for damage resuting from earthquake.'
    },
    HOPIncidentalCoverageForMotorizedVehiclesLP_Ext: {
        id: 'wni.custom.products.HO.tooltip.Covers liability arising out of the off-premises use of certain motorized vehicles that are low-speed and battery powered.',
        defaultMessage: 'Covers liability arising out of the off-premises use of certain motorized vehicles that are low-speed and battery powered.'
    },
    HOPOrdinanceOrLaw: {
        id: 'wni.custom.products.HO.tooltip.Increases the limit provided to cover additional costs incurred to repair or rebuild a dwelling as a result of an ordinance or law.',
        defaultMessage: 'Increases the limit provided to cover additional costs incurred to repair or rebuild a dwelling as a result of an ordinance or law.'
    },
    HOPActualCashValueRelatedPrivateStructures_Ext: {
        id: 'wni.custom.products.HO.tooltip.Applies Actual Cash Value settlement for specific description of structure described.',
        defaultMessage: 'Applies Actual Cash Value settlement for specific description of structure described.'
    },
    HOPRelatedPrivateStructuresRentedToOthers_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides property coverage for a related private structure at the described location that is rented.',
        defaultMessage: 'Provides property coverage for a related private structure at the described location that is rented.'
    },
    HOPRelatedPrivateStructuresDescribedLocation_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides liability coverage for a related private structure at the described location.',
        defaultMessage: 'Provides liability coverage for a related private structure at the described location.'
    },
    HOPSchedRelatedPrivateStructuresAwayFromDescripLocat_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides property coverage for a specific private structure located away from the described location-ACV.',
        defaultMessage: 'Provides property coverage for a specific private structure located away from the described location-ACV.'
    },
    HOPPersonalInjury_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides coverage for personal injury for which the policyholder is liable.',
        defaultMessage: 'Provides coverage for personal injury for which the policyholder is liable.'
    },
    HOPCoverageForNonResidentRelative_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides personal property and liability coverage for a named non-resident relative related to insured by blood, marriage or adoption.',
        defaultMessage: 'Provides personal property and liability coverage for a named non-resident relative related to insured by blood, marriage or adoption.'
    },
    HOPPersonalPropertyCoverageForDesignatedRelative_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides personal property coverage for specified person named related to insured by blood, marriage or adoption.',
        defaultMessage: 'Provides personal property coverage for specified person named related to insured by blood, marriage or adoption.'
    },
    HOPReplacementCostLossSettlementTermsPersonalProperty_Ext: {
        id: 'wni.custom.products.HO.tooltip.Converts loss settlement terms for personal property and various miscellaneous items from actual cash value to replacement cost.',
        defaultMessage: 'Converts loss settlement terms for personal property and various miscellaneous items from actual cash value to replacement cost.'
    },
    HOPSpclPersonalPropCov_Ext: {
        id: 'wni.custom.products.HO.tooltip.Modifies coverage for personal property from named peril to all risk of direct physical loss with certain exceptions.',
        defaultMessage: 'Modifies coverage for personal property from named peril to all risk of direct physical loss with certain exceptions.'
    },
    HOPScheduledPersonalProperty: {
        id: 'wni.custom.products.HO.tooltip.Modifies coverage for scheduled personal property from named peril to all risk of direct physical loss with certain exceptions.',
        defaultMessage: 'Modifies coverage for scheduled personal property from named peril to all risk of direct physical loss with certain exceptions.'
    },
    HOPCovCHigherLmtOnCertainProp_Ext: {
        id: 'wni.custom.products.HO.tooltip.Increases special limits under Coverage C for electronics, guns, jewelry, furs, money, securities, silverware, valuable papers, etc.',
        defaultMessage: 'Increases special limits under Coverage C for electronics, guns, jewelry, furs, money, securities, silverware, valuable papers, etc.'
    },
    HOPPropertyInRentalUnitsIncreasedLimit_Ext: {
        id: 'wni.custom.products.HO.tooltip.Increases the policy limit for coverage of property in a rental unit.',
        defaultMessage: 'Increases the policy limit for coverage of property in a rental unit.'
    },
    HOPExtendedResidenceTheft: {
        id: 'wni.custom.products.HO.tooltip.Modifies the exclusion of theft for all personal property from the rented part of the premises to exclude certain kinds of property (money, securities, jewelry, etc).',
        defaultMessage: 'Modifies the exclusion of theft for all personal property from the rented part of the premises to exclude certain kinds of property (money, securities, jewelry, etc).'
    },
    HOPAdditionalResidenceRentedToOthersLBCovOnly_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides coverage for liability arising out of a rented one or two family dwelling owned by the policyholder.',
        defaultMessage: 'Provides coverage for liability arising out of a rented one or two family dwelling owned by the policyholder.'
    },
    HOPRoofSurfacingActualCash_Ext: {
        id: 'wni.custom.products.HO.tooltip.Converts the loss settlement terms from replacement cost to actual cash value for loss caused by windstorm or hail.',
        defaultMessage: 'Converts the loss settlement terms from replacement cost to actual cash value for loss caused by windstorm or hail.'
    },
    HOPRoofSurfacingActualCashSched_Ext: {
        id: 'wni.custom.products.HO.tooltip.Allows for scheduling of structures to which actual cash value roof surfacing loss settlement terms will apply.',
        defaultMessage: 'Allows for scheduling of structures to which actual cash value roof surfacing loss settlement terms will apply.'
    },
    HOPMactchingOfUndamagedSidingOrRoofingCov_Ext: {
        id: 'wni.custom.products.HO.tooltip.Allows coverage for undamaged roof surfacing or siding for residence or related private structure to be replaced when similar siding or roof surfacing is no longer available to repair/replace the damaged portion; subject to eligibility.',
        defaultMessage: 'Allows coverage for undamaged roof surfacing or siding for residence or related private structure to be replaced when similar siding or roof surfacing is no longer available to repair/replace the damaged portion; subject to eligibility.'
    },
    HOPAdditionalResidencePremisesOccupied_Ext: {
        id: 'wni.custom.products.HO.tooltip.Extends liability coverage from the primary residence to a seasonal or secondary residence owned by the insured.',
        defaultMessage: 'Extends liability coverage from the primary residence to a seasonal or secondary residence owned by the insured.'
    },
    HOPUndergroundServiceLineCoverage_Ext: {
        id: 'wni.custom.products.HO.tooltip.A $10,000 limit is applied for service lines that are less than 50 years old. A $2,500 limit is applied for service lines 50 years old and above.  The age of the service line is determined by the age of the home or supporting replacement documentation. Contact your underwriter with any specific questions regarding the limit application.',
        defaultMessage: 'A $10,000 limit is applied for service lines that are less than 50 years old. A $2,500 limit is applied for service lines 50 years old and above.  The age of the service line is determined by the age of the home or supporting replacement documentation. Contact your underwriter with any specific questions regarding the limit application.'
    },
    HOPBackupSewersDrainsPump: {
        id: 'wni.custom.products.HO.tooltip.Coverage for damage due to water back-up and sump pump failure.',
        defaultMessage: 'Coverage for damage due to water back-up and sump pump failure.'
    },
    HOPIncidentalCovForMotorVehicleUnder20MPH_Ext: {
        id: 'wni.custom.products.HO.tooltip.Covers liability arising out of the off-premises use of certain motorized vehicles that are low-speed and battery powered.',
        defaultMessage: 'Covers liability arising out of the off-premises use of certain motorized vehicles that are low-speed and battery powered.'
    },
    HOPScheduledPersonalPropertyItemAgreedValue_Ext: {
        id: 'wni.custom.products.HO.tooltip.Provides agreed value loss settlement terms for scheduled jewelry items.',
        defaultMessage: 'Provides agreed value loss settlement terms for scheduled jewelry items.'
    },
    z11giqu03qblnb06i3176iu4mf9: {
        id: 'wni.custom.products.HO.tooltip.Increases limits available for incidental property.',
        defaultMessage: 'Increases limits available for incidental property.'
    },
    zkqgedm1v5ogi0vpubjdm77rvr8: {
        id: 'wni.custom.products.HO.tooltip.Increases the limit that applies to fire department service charges; No deductible applies.',
        defaultMessage: 'Increases the limit that applies to fire department service charges; No deductible applies.'
    },
    z6kggvgf69bhnchbalhksikg4e8: {
        id: 'wni.custom.products.HO.tooltip.Increases the limits that apply to coverage for refrigerated foods.',
        defaultMessage: 'Increases the limits that apply to coverage for refrigerated foods.'
    },
    zffismojuk7q3c0c8d5v3eu0ks8: {
        id: 'wni.custom.products.HO.tooltip.Increases limits that apply to building improvements made by the insured\'s tenant.',
        defaultMessage: 'Increases limits that apply to building improvements made by the insured\'s tenant.'
    },
});
