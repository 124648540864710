import React, { useEffect } from "react";
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import SingleLineExclusionAndConditionAccordionCard from "./SingleLineExclusionAndConditionAccordionCard";
import messages from './MultiLineExclusionAndConditionAccordionCard.messages'
import AccordionCardTitle from '../../../GeneralLiability/components/GLClauseAccordionCardTitle'
import { Accordion, AccordionCard } from '@jutro/legacy/components';

const MultiLineExclusionAndConditionAccordionCard = (props) => {
    const {
        stateClauses,
        setStateClauses,
        submissionVM,
        updateWizardData,
        onValidate: onPageValidate,
        isEditing,
        setIsEditing,
        loadingClauses,
        setLoadingClauses,
        showErrors,
        isEditable,
        onClickAddSearchCoverage
    } = props

    const {
        state,
        stateSublinesClauses = []
    } = stateClauses

    const translator = useTranslator();

    const validationID = `multilineExclusionAndConditions`;
    const covType = 'exclusionsAndConditions';
    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)


    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        }
    }, [isComponentValid, onPageValidate, validationID])

    return (
        <AccordionCard
            id='stateSpecificInformation_multilines_ExclusionAndCondition'
            chevron
            className="coveragesAccordion"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={
                isEditable ? 
                <AccordionCardTitle
                    title={messages.exclusionAndConditionTitle}
                    actionText={messages.addExclusionAndConditionActionText}
                    onClick={() => {onClickAddSearchCoverage(covType)}}
                /> : translator(messages.exclusionAndConditionTitle)}
            errorState={showErrors && !isComponentValid}
        >
            <Accordion
                id='stateSpecificInformation_singleline_ExclusionAndCondition'
                className= "accordion-wizardTitle"
                closeOthers= {false}
                showFrame= {false}
            >
                {stateSublinesClauses.map((singleSublineClauses, index) => {
                    const setSingleLineClauses = (newSingleLineClauses) => {
                        const newStateSublinesClauses = _.clone(stateSublinesClauses)
                        newStateSublinesClauses[index] = newSingleLineClauses
                        setStateClauses({
                            ...stateClauses,
                            stateSublinesClauses: newStateSublinesClauses
                        })
                    }
                    return <SingleLineExclusionAndConditionAccordionCard
                        state={state}
                        singleSublineClauses={singleSublineClauses}
                        setSingleLineClauses={setSingleLineClauses}
                        submissionVM={submissionVM}
                        updateWizardData={updateWizardData}
                        onValidate={onValidate}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        loadingClauses={loadingClauses}
                        setLoadingClauses={setLoadingClauses}
                        showErrors={showErrors}
                        isEditable={isEditable}
                    />
                })}
            </Accordion>
        </AccordionCard>
    );
}

export default MultiLineExclusionAndConditionAccordionCard
