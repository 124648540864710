import { defineMessages } from 'react-intl';

export default defineMessages({
    locationDetailModalTitle: {
        id: 'wni.quoteandbind.im-wizard.im-locations.New/Edit Location Details',
        defaultMessage: '{operationType} Location Details',
    },
    buildingDetailModalTitle: {
        id: 'wni.quoteandbind.im-wizard.im-locations.New/Edit Building Details',
        defaultMessage: '{operationType} Building Details',
    },
    removeLocationTitle: {
        id: 'wni.quoteandbind.im-wizard.imlocations.Remove Location?',
        defaultMessage: "Remove Location?"
    },
    removeLocationDescription: {
        id: 'wni.quoteandbind.im-wizard.im-locations.removeLocationDescription',
        defaultMessage: 'Are you sure you want to remove this location from the list of locations?',
    },
    removeBuildingsTitle: {
        id: 'wni.quoteandbind.im-wizard.imlocations.Remove Building?',
        defaultMessage: "Remove Building?"
    },
    removeBuildingsDescription: {
        id: 'wni.quoteandbind.im-wizard.im-locations.removeBuildingsDescription',
        defaultMessage: 'Are you sure you want to remove this building from the list of locations?',
    },
});