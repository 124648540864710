import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/wnicoverage'), method, data, additionalHeaders);
}

export default class WniCoverageService {
    static getErrorsAndWarnings(quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnicoverage'),
            'getErrorsAndWarnings', [quoteID], additionalHeaders);
    }

    /**
     * Invokes WniCoveragesHandler.syncCoverages()
     * @param {String} quoteID
     * @param {String} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static syncCoverages(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('syncCoverages', [quoteID, sessionUUID], authHeader);
    }
}
