import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useModal } from '@jutro/components';

import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Contact, Claim, saveFNOLDetails, Address } from 'gw-capability-fnol-common-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { FNOLService } from 'wni-capability-fnol';

import metadata from './WhatPage.metadata.json5';
import messages from '../../FNOLWC.messages';

function FNOLWCWhatPage(props) {
    const modalApi = useModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader,
        history: {
            location: { state }
        }
    } = props;

    const translator = useTranslator();
    // const { FNOLService } = useDependencies('FNOLService');
    const [isLoading, setIsLoading] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLWCWhatPage');
    const { claimStatus } = state || {};
    const viewModelService = useContext(ViewModelServiceContext);
    const [policyAddressDropdown, updatePolicyAddressDropdown] = useState(claimVM.value.lossLocation?.publicID);
    const addressViewModel = viewModelService.create(
        _.get(claimVM.value, 'lossLocation') || new Address(),
        'cc',
        'edge.capabilities.address.dto.AddressDTO'
    );
    const [addressVM] = useState(addressViewModel);

    useEffect(
        () => {
            const lobExtension = claimVM.lobs.workersComp;
            const { injuryIncident } = lobExtension.value;

            if (!injuryIncident.injured) {
                injuryIncident.injured = new Contact({
                    displayName: 'New person',
                    gender: null,
                    dateOfBirth: null
                });
            }

            if (!injuryIncident.injured.primaryAddress && injuryIncident.injured.initializePrimaryAddress) {
                injuryIncident.injured.initializePrimaryAddress();
            }
            updateWizardData(claimVM);
        },
        // Call once when page is mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const validateClaimStatus = useCallback(() => {
        return (
            !_.isNil(claimStatus)
            && claimStatus === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'Draft' })
        );
    }, [claimStatus, translator]);

    useEffect(() => {
    }, [registerInitialComponentValidation, validateClaimStatus]);

    const onNext = useCallback(async () => {
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (!isClaimNumberAvailable) {
            setIsLoading(true);
            return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
                .then((response) => {
                    claimVM.value = new Claim(response);
                    return claimVM;
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: messages.wcCreateDraftErrorTitle,
                        message: messages.wcCreateDraftErrorMessage,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        return claimVM;
    }, [authHeader, claimVM, modalApi]);

    const handleAddressDropDownValueChange = useCallback(
        (addressPublicId) => {
            const updatedLocations = claimVM.predefinedLossLocations.value.find((loss) => {
                return loss.publicID === parseInt(addressPublicId, 10);
            });
            _.set(claimVM.value, 'optionalLossLocation.predefinedAddress', updatedLocations);
            updatePolicyAddressDropdown(addressPublicId);
            addressVM.value = _.omit(updatedLocations, 'publicID');
            _.set(claimVM, 'lossLocation.value', updatedLocations);
            updateWizardData(claimVM);
        },
        [addressVM, claimVM, updateWizardData]
    );

    const getPreferredAddressDropDownValues = useCallback(
        () => {
            if (claimVM && claimVM.value.predefinedLossLocations) {
                const addressData = claimVM.value.predefinedLossLocations;
                return addressData.map((address) => {
                    const addressJoin = [
                        address.addressLine1,
                        address.addressLine2,
                        address.addressLine3,
                        address.city,
                        address.postalCode,
                        address.state
                    ];

                    const addressArrayJoin = _.values(addressJoin)
                        .filter((value) => !!value)
                        .join(', ');
                    const addressArrayJoinKey = {
                        code: address.publicID,
                        name: addressArrayJoin
                    };
                    return addressArrayJoinKey;
                });
            }

            return [];
        }, [claimVM]
    );

    const overrides = {
        '@field': {
            showRequired: true,
            labelPosition: breakpoint === 'tablet' ? 'top' : 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        whatPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(messages.wcCreateDraftClaim)
        },
        whatPageContainer: {
            visible: !isLoading
        },
        selectAddress: {
            availableValues: getPreferredAddressDropDownValues(),
            value: policyAddressDropdown,
        },
        whatPhoneHomeNumber: {
            visible: _.get(claimVM, "value.lobs.workersComp.injuryIncident.injured.primaryPhoneType") === 'home'
        },
        whatPhoneWorkNumber: {
            // confirmed by BA, default as work
            visible: _.get(claimVM, "value.lobs.workersComp.injuryIncident.injured.primaryPhoneType", 'work') === 'work'
        },
        whatPhoneCellNumber: {
            visible: _.get(claimVM, "value.lobs.workersComp.injuryIncident.injured.primaryPhoneType") === 'mobile'
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onHandleAddressChange: handleAddressDropDownValueChange,
        }
    };

    return (
        <WizardPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLWCWhatPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLWCWhatPage));
