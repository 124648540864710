import { ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { WniLoadSaveService } from 'wni-capability-quoteandbind';
import { CAPaymentService } from 'wni-capability-quoteandbind-ca';
import { PaymentUtil } from 'wni-portals-util-js';
import messages from './ICDownPaymentSetup.messages';
import metadata from './ICDownPaymentSetup.metadata.json5';
import styles from './ICDownPaymentSetup.module.scss';

import { Button } from '@jutro/legacy/components';

function ICDownPaymentSetup(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        rowData,
        quoteID,
        totalDownPaymentAmount,
        isMulti,
        setLoadingMask,
        getProductName,
        useAuthenticationData,
        viewModelService,
        domainCompany
    } = props;

    const { authHeader } = useAuthenticationData || useAuthentication();
    const translator = useTranslator();
    const [model, updateModel] = useState(rowData);
    const [paymentMethodValue, updatePaymentMethodValue] = useState('');
    const [paymentMethodsVM, updatePaymentMethodsVM] = useState([]);
    const [paymentAmount, updatePaymentAmount] = useState(
        totalDownPaymentAmount
    );
    const [paymentAmountOther, updatePaymentAmountOther] = useState();
    const [paymentAmountOtherCheckbox, updatePaymentAmountOtherCheckbox] =
        useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [requestParam, updateRequestParam] = useState(null);
    const [isMultiAndNew, updateIsMultiAndNew] = useState(false);
    const [displayAgencySweep, updateDisplayAgencySweep] = useState(false);

    useEffect(() => {
        // loading data options from PC
        async function retrieveQuotePaymentDetailsData() {
            const rs = await WniLoadSaveService.retrieveQuotePaymentDetailsData(
                quoteID,
                authHeader
            );
            return rs;
        }
        setLoadingMask(true);
        retrieveQuotePaymentDetailsData()
            .then((res) => {
                PaymentUtil.initInvoiceCloudData(
                    res,
                    viewModelService,
                    model,
                    isMulti,
                    quoteID,
                    updateDisplayAgencySweep,
                    updatePaymentMethodsVM,
                    updateIsMultiAndNew,
                    updatePaymentMethodValue,
                    updateRequestParam
                );
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, []);

    const getPaymentMethodDropdownData = useCallback(() => {
        let data = [];
        if (displayAgencySweep && displayAgencySweep === 'Yes') {
            data.push({
                code: 'AgencySweep',
                name: translator(messages.agencySweep),
            });
        }
        data = PaymentUtil.addPaymentMethodOptions(data, paymentMethodsVM);
        data.push({
            code: 'addNewCreditCard',
            name: translator(messages.addNewCreditCard),
        });
        data.push({
            code: 'addNewBankAccount',
            name: translator(messages.addNewBankAccount),
        });
        return data;
    }, [displayAgencySweep, paymentMethodsVM, translator]);

    const writeValue = useCallback(
        (value, path) => {
            const newModel = _.cloneDeep(model);
            _.set(newModel, `${path}`, value);
            updateModel(newModel);
        },
        [model]
    );

    const isValueValid = useCallback(() => {
        return (
            _.get(paymentAmount, 'amount') >=
            _.get(totalDownPaymentAmount, 'amount')
        );
    }, [paymentAmount, totalDownPaymentAmount]);

    const isOtherValueValid = useCallback(() => {
        // Other amount can be anything above min due when the min due is less than or equal $50,000
        // If min due is greater than $50,000 then the Other amount can be equal to any amount
        const minDueAmount = _.get(totalDownPaymentAmount, 'amount');
        const minDueAmountOther = _.get(paymentAmountOther, 'amount');
        if (minDueAmount <= 50000) {
            return minDueAmountOther > minDueAmount;
        }
        return minDueAmountOther >= 0;
    }, [totalDownPaymentAmount, paymentAmountOther]);

    const isPaymentMethodValid = useCallback(() => {
        return paymentMethodValue;
    }, [paymentMethodValue]);

    const handleSave = useCallback(async () => {
        if (!isValueValid() || !isPaymentMethodValid()) {
            updateShowErrors(true);
            return false;
        }

        const amtValue = paymentAmountOtherCheckbox ? paymentAmountOther : paymentAmount;

        const res = {};
        _.set(res, 'requestParam', requestParam);
        if (paymentMethodValue === 'AgencySweep') {
            // agencySweep
            _.set(res, 'downPayment', 'AgencySweep');
            _.set(res, 'agencySweepValue', amtValue);
            _.set(res, 'paymentDetailsForDownPayment', {
                paymentMethd: 'creditcard',
            });
            _.set(res, 'isInvoiceCloud', false);
        } else {
            _.set(res, 'isInvoiceCloud', true);

            const basicParam =
                await PaymentUtil.getDefaultInvoiceCloudBasicRequestParam(
                    model,
                    isMultiAndNew,
                    CAPaymentService,
                    authHeader
                );
            
            _.set(basicParam, 'amtValue', amtValue);
            
            if (paymentMethodValue === 'addNewCreditCard') {
                _.set(basicParam, 'paymentType', 'cc');
            } else {
                _.set(basicParam, 'paymentType', 'ach');
            }
            
            // special for new bill account

            // use bill accountNumber as referenceID
            const billAccount = _.get(model, 'data[0].invoiceStreamDescription');
            _.set(basicParam, 'invoiceStreamDescription', billAccount);
            if (billAccount === 'New') {
                _.set(basicParam, 'invoiceStreamDescription', `4${quoteID}000000`);
            }

            updateRequestParam(basicParam);
            _.set(res, 'requestParam', basicParam);

            _.set(res, 'downPayment', 'Electronic');
            _.set(res, 'electronicValue', amtValue);
            _.set(res, 'paymentMethodValue', paymentMethodValue);
        }
        
        return onResolve(res);
    }, [authHeader, isMultiAndNew, isPaymentMethodValid, isValueValid, model, onResolve, paymentAmount, paymentAmountOther, paymentAmountOtherCheckbox, paymentMethodValue, quoteID, requestParam]);

    const getAccountInfo = useCallback(() => {
        const quoteIDs = [];
        const data = _.get(rowData, 'data');
        if (_.isEmpty(data)) {
            quoteIDs.push(quoteID);
        } else {
            _.each(data, (submission) => {
                const submissioNumber = _.get(submission, 'quoteNumber');
                // const productName = _.get(submission, 'product.productName');
                const productCode = _.get(submission, 'product.productCode');
                quoteIDs.push({
                    submissioNumber,
                    productCode,
                });
            });
        }
        const STYLE = {
            'margin-bottom': '20px',
        };
        return (
            <div style={STYLE}>
                {quoteIDs.map((value) => {
                    return (
                        <div>
                            {getProductName(
                                _.get(value, 'productCode'),
                                translator
                            )}{' '}
                            {_.get(value, 'submissioNumber')}
                        </div>
                    );
                })}
            </div>
        );
    }, [getProductName, quoteID, rowData, translator]);

    const handleDownPaymentMethodChange = useCallback(
        async (value) => {
            const basicParam =
                PaymentUtil.getDefaultInvoiceCloudBasicRequestParam(
                    model,
                    isMultiAndNew,
                    CAPaymentService,
                    authHeader
                );
            if (value === 'AgencySweep' || value === 'Check') {
                updatePaymentMethodValue(value);
                return true;
            }
            // select Add New Credit Card
            if (value === 'addNewCreditCard') {
                updatePaymentMethodValue(value);
                updateRequestParam({ ...basicParam, ActionCode: 'EXPAYCC' });
                return true;
            }

            // select Add New Bank Acount
            if (value === 'addNewBankAccount') {
                updatePaymentMethodValue(value);
                updateRequestParam({ ...basicParam, ActionCode: 'EXPAYCC' });
                return true;
            }
            // select default
            updatePaymentMethodValue(value);
            return false;
        },
        [model, isMultiAndNew, authHeader]
    );

    const agencySweepMessage = useCallback(() => {
        return (
            <div>
                <span className={styles.fontWeightNormal}>
                    You have selected Agency Sweep as the down payment method.
                    Please note, you will see a separate down payment for each
                    policy issued. If you are submitting multiple policies for
                    issue, the down payment amount will not be combined.
                </span>
            </div>
        );
    }, []);

    const renderMinimunDueWarning = useCallback(() => {
        const basicMessage =
            'Credit card maximum is $50,000 per transaction.\nBank account (ACH) maximum is $125,000 per transaction.\nMaximum number of payments per day is two (2).\nMaximum number of payments per invoice is four (4).\n\nIf you have questions, please contact our Customer Relationship Center at ';
        const UICNumber = 'UIC 800-251-3563. \n\n';
        const WNINumber = 'WN 800-352-2772. \n\n';
        let message = `${basicMessage}${WNINumber}`;
        if (_.get(domainCompany, 'code') === 'UIC') {
            message = `${basicMessage}${UICNumber}`;
        }
        return (
            <div>
                <span className={styles.fontWeightNormal}>{message}</span>
            </div>
        );
    }, [domainCompany]);

    const showPaymentAmountOtherMessage = useCallback(() => {
        if (!paymentAmountOtherCheckbox) {
            return [];
        }
        return isOtherValueValid()
            ? []
            : [
                  translator(messages.minimumAmount, {
                      amount: _.get(totalDownPaymentAmount, 'amount'),
                  }),
              ];
    }, [
        isOtherValueValid,
        paymentAmountOtherCheckbox,
        totalDownPaymentAmount,
        translator,
    ]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        accountLabelTitle: {
            content: `${translator(messages.forQuotes)}: `,
        },
        accountNumberTitle: {
            content: getAccountInfo(),
        },
        paymentAmount: {
            disabled: true,
            value: paymentAmount,
            label: translator(messages.minimumDue),
            showErrors: !isValueValid(),
            validationMessages: isValueValid()
                ? []
                : [
                      translator(messages.minimumAmount, {
                          amount: _.get(totalDownPaymentAmount, 'amount'),
                      }),
                  ],
            className: 'form-control-no-label',
            onValueChange: updatePaymentAmount,
        },
        paymentAmountOtherCheckBox: {
            value: paymentAmountOtherCheckbox,
            onValueChange: updatePaymentAmountOtherCheckbox,
        },
        paymentAmountOther: {
            disabled: !paymentAmountOtherCheckbox,
            value: paymentAmountOther,
            label: translator(messages.minimumDueOther),
            showErrors: !isOtherValueValid() && !paymentAmountOtherCheckbox,
            validationMessages: showPaymentAmountOtherMessage(),
            className: `form-control-no-label ${styles.flexAuto}`,
            onValueChange: updatePaymentAmountOther,
        },
        minimunDueWarning: {
            visible: _.get(totalDownPaymentAmount, 'amount') > 50000,
            content: renderMinimunDueWarning(),
        },
        paymentMethod: {
            label: translator(messages.paymentMethod),
            availableValues: getPaymentMethodDropdownData(),
            onValueChange: handleDownPaymentMethodChange,
            value: paymentMethodValue,
            required: true,
        },
        agencySweepMessage: {
            label: ' ',
            value: agencySweepMessage(),
            readOnly: true,
            visible: paymentMethodValue === 'AgencySweep',
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="paymentDetailPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={model}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

ICDownPaymentSetup.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    rowData: PropTypes.shape({}).isRequired,
    rePopupICDownPaymentSetup: PropTypes.func,
    quoteID: PropTypes.string.isRequired,
    sessionUUID: PropTypes.string.isRequired,
    payUsing: PropTypes.string.isRequired,
    totalDownPaymentAmount: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.PropTypes.shape({}),
};
ICDownPaymentSetup.defaultProps = {
    size: 'md',
    rePopupICDownPaymentSetup: null,
    viewModelService: undefined,
};
export default ICDownPaymentSetup;
