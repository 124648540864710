
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
// import { HOAdditionalInterestsReadOnlyPage } from 'wni-capability-quoteandbind-ho-react';
import { BaseAdditionalInterestsReadOnlyPageComponent } from 'wni-capability-common-react';

import WizardPage from '../../templates/HOWizardPage';


function HOAdditionalInterestsReadOnlyPage(props) {

    const initProps = {
        ...props,
        WizardPage
    };

    return (
        <BaseAdditionalInterestsReadOnlyPageComponent {...initProps} />
    );

}

HOAdditionalInterestsReadOnlyPage.propTypes = BaseAdditionalInterestsReadOnlyPageComponent.propTypes;
HOAdditionalInterestsReadOnlyPage.defaultProps = BaseAdditionalInterestsReadOnlyPageComponent.defaultProps;

export default HOAdditionalInterestsReadOnlyPage;
