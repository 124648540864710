import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';


import FNOLCAWizard from './FNOLCAWizard.pe';
import FNOLCAVehiclesPage from './pages/Vehicles/FNOLCAVehiclesPage.pe';
import FNOLCATypeOfIncidentPage from './pages/TypeOfIncident/FNOLCATypeOfIncidentPage.pe';
import FNOLCATypeOfRepairPage from './pages/TypeOfRepair/FNOLCATypeOfRepairPage.pe';
import FNOLCARepairFacilityPage from './pages/RepairFacility/FNOLCARepairFacilityPage.pe';
import FNOLCACollisionDetailsPage from './pages/Details/Collision/FNOLCACollisionDetailsPage.pe';
import FNOLCAGlassDetailsPage from './pages/Details/Glass/FNOLCAGlassDetailsPage.pe';
import FNOLCATheftDetailsPage from './pages/Details/Theft/FNOLCATheftDetailsPage.pe';
import FNOLCARepairFacilityMapPage from './pages/RepairFacilityMap/FNOLCARepairFacilityMapPage.pe';
import FNOLCALossTypePage from './pages/LossType/FNOLCALossTypePage.pe';
import FNOLCAIncidentsPage from './pages/Incidents/FNOLCAIncidentsPage.pe';

setComponentMapOverrides(
    {
        FNOLCAWizard: { component: 'FNOLCAWizard' },
        FNOLCATypeOfIncidentPage: { component: 'FNOLCATypeOfIncidentPage' },
        FNOLCAVehiclesPage: { component: 'FNOLCAVehiclesPage' },
        FNOLCATypeOfRepairPage: { component: 'FNOLCATypeOfRepairPage' },
        FNOLCARepairFacilityPage: { component: 'FNOLCARepairFacilityPage' },
        FNOLCACollisionDetailsPage: { component: 'FNOLCACollisionDetailsPage' },
        FNOLCAGlassDetailsPage: { component: 'FNOLCAGlassDetailsPage' },
        FNOLCATheftDetailsPage: { component: 'FNOLCATheftDetailsPage' },
        FNOLCARepairFacilityMapPage: { component: 'FNOLCARepairFacilityMapPage' },
        FNOLCALossTypePage: { component: 'FNOLCALossTypePage' },
        FNOLCAIncidentsPage: { component: 'FNOLCAIncidentsPage' }
    },
    {
        FNOLCAWizard,
        FNOLCATypeOfIncidentPage,
        FNOLCAVehiclesPage,
        FNOLCATypeOfRepairPage,
        FNOLCARepairFacilityPage,
        FNOLCACollisionDetailsPage,
        FNOLCAGlassDetailsPage,
        FNOLCATheftDetailsPage,
        FNOLCARepairFacilityMapPage,
        FNOLCALossTypePage,
        FNOLCAIncidentsPage
    }
);

export {
    FNOLCAWizard as default
};
