import React from "react";
import _ from 'lodash';
import LiabilityExposuresComponent from "wni-capability-quoteandbind-ho-react/pages/HOResidence/Component/LiabilityExposuresComponent/LiabilityExposuresComponent";


function DPLiabilityExposuresComponent(props) {
    const {
        model: liabilityExposuresVM,
        launchRCTExpressCallFlag,
        originalLiabilityExposuresVM,
        DP2Condo
    } = props;

    const {
        swimmingPoolType,
        approvedFence,
        divingBoard
    } = liabilityExposuresVM.value;

    const isNoValue = (val) => {
        return (_.isNil(val) || _.isEmpty(val)) && val !== true && val !== false
    }

    const DPOverrideProps = {
        isTrampolineSecured: {
            requiredForIssuance: true
        },
        isTrampolineNetted: {
            requiredForIssuance: true
        },
        swimmingPoolType: {
            visible: !DP2Condo
            // readOnly: launchRCTExpressCallFlag && !isNoValue(originSwimmingPoolType)
        },
        approvedFence: {
            visible: !DP2Condo && (swimmingPoolType === 'AboveGround' || swimmingPoolType === 'InGround' || swimmingPoolType === 'Indoor_Ext')
        },
        approvedFenceExplain: {
            visible: !DP2Condo &&  (swimmingPoolType === 'AboveGround' || swimmingPoolType === 'InGround' || swimmingPoolType === 'Indoor_Ext')
            && approvedFence === false
        },
        divingBoard: {
            visible: !DP2Condo && (swimmingPoolType === 'AboveGround'
            || swimmingPoolType === 'InGround'
            || swimmingPoolType === 'Indoor_Ext')
        },
        depthOfThePool: {
            visible: (swimmingPoolType === 'AboveGround'
            || swimmingPoolType === 'InGround'
            || swimmingPoolType === 'Indoor_Ext')
            && !DP2Condo && divingBoard === true
        }
    }

    return <LiabilityExposuresComponent
        {...props}
        DPOverrideProps={DPOverrideProps}
    />
}

DPLiabilityExposuresComponent.propTypes = LiabilityExposuresComponent.propTypes;
DPLiabilityExposuresComponent.defaultProps = LiabilityExposuresComponent.defaultProps;

export default DPLiabilityExposuresComponent;