import React, {
    useState,
    useEffect
} from 'react'
import { WniInputText } from 'wni-common-base-components';

export const DetailsStringInput = (props) => {

    const {
        sublinePublicID,
        displayable,
        detailsIndex,
        onValidate,
        showErrors,
        disabled,
        isEditable,
        changeDetailsAndSync,
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        fieldValue,
    } = displayable

    const [editingValue, setEditingValue] = useState(fieldValue)

    useEffect(() => {
        setEditingValue(fieldValue)
    }, [fieldValue])

    const onFieldValueChange = (newValue) => {
        setEditingValue(newValue)
    }

    const onBlur = () => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            fieldValue: editingValue
        }
        changeDetailsAndSync(newDisplayable, detailsIndex)
    }

    return <WniInputText
        id={`${sublinePublicID}_displayable_fieldInput_${propertyName}`}
        labelPosition='left'
        label={label}
        value={editingValue}
        onValueChange={onFieldValueChange}
        onBlur={onBlur}
        placeholder=""
        disabled={disabled}
        size="medium"
        className='flex-1'
        required={required}
        showRequired
        onValidationChange={onValidate}
        showErrors={showErrors}
        readOnly={!isDisplayableEditable || !isEditable}
        allowNegative={false}
        decimalPlaces={0}
    />
}