import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { wizardProps } from '@xengage/gw-portals-wizard-react';import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    ValidationIssueUtil,
    UWIssueUtil,
    OOSUtil,
    QuoteUtil,
    WniClausesUtil,
    WindowUtil,
    PolicyChangeUtil,
    CoveragesUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants} from 'wni-portals-config-js';
import {
    ComponentRenderHelper as renderHelper,
    ModalDialogHelper,
    ValidationIssueRenderUtil,
} from 'wni-portals-util-react';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, useWniModal } from 'wni-components-platform-react';

import { SuccessNotification } from 'gw-components-platform-react';
import { 
    WniSxsChangeService,
    WniCommonChangeService,
} from 'wni-capability-policychange';


import { PolicyDiffService } from 'gw-capability-policyjob';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import {
    SideBySidePeriodsComponent,
    ReferToUnderwriterComponent,
    PolicyChangeSideBySidePeriodInfo,
    ChangeSummaryComponent,
} from 'wni-capability-gateway-react';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import {
    PolicyChangeCommonMessages as currentMessages,
    QuoteCommonMessages,
} from 'wni-platform-translations';

import { WALMultiQuoteChangeService } from 'wni-capability-policychange-wal';
import {
    WALWizardPage as WizardPage,
    WALQuoteIssueRenderHelper,
} from 'wni-capability-quoteandbind-wal-react';

import WALPolicyChangeQuoteComponent from './components/WALPolicyChangeQuoteComponent';
import WALAddNewChangeVersionComponent from './components/AddNewVersion/WALAddNewChangeVersionComponent';

import styles from './WALQuoteChangePage.module.scss';
import metadata from './WALQuoteChangePage.metadata.json5';

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = WALQuoteIssueRenderHelper; // ValidationIssueRenderUtil;

const LOB_NAME = 'watercraft'; // 'perosnalAuto'; SXS_LOB_NAME;
// const BRANCH_NAME_PATH = `lobData.${SXS_LOB_NAME}.offerings[0].branchName`;

function WALQuoteChangeReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const { isLoggedIn, authHeader } = useAuthentication();

    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('WALQuoteChangePage');
    const [showErrors, updateShowErrors] = useState(false);
    const modalApi = useWniModal();
    const [allDrivers, updateAllDrivers] = useState();
    const [allVehicles, updateAllVehicles] = useState();
    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [isShowMulti, updateShowMulti] = useState(false);
    const [hasBlockingIssue, updateHasBlockingIssue] = useState(false);
    const [policyDiffData, updatePolicyDiffData] = useState([]);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);

    const {
        jobID,
        sessionUUID,
        baseData: {
            effectiveDate_Ext: effectiveDate,
            periodStartDate,
            periodEndDate,
            selectedVersion_Ext: selectedVersion,
            periodStatus: jobStatus,
        },
        // lobData: {
        // },
        changeData: {
            totalCost,
            totalCost: {
                amount: totalCostAmount,
            } = {},
            transactionCost = {},
            transactionCost: {
                amount: transactionCostAmount,
            } = {},
            previousTotalCost,
            isBillingOptionsEditable,
        }
    } = submissionVM.value;
    const modalHelper = ModalDialogHelper(modalApi);
    // const oosErrorsAndWarnings = OOSUtil.getOOSSliceDatesWarning(
    //     OOSUtil.getOOSSliceDatesString(
    //         oossliceDates,
    //         effectiveDate
    //     ),
    //     translator
    // );

    // const updateSideBySideData = async ({
    //     refreshSxsCache = true,
    //     clearUWIssues = false,
    // } = {}) => {
    //     let sideBySideData = wizardPageData[WizardConstants.sideBySideData];
    //     if (sideBySideData && !refreshSxsCache) {
    //         if (!sxsDataDTO && sideBySideData.personalAuto) {
    //             updateSxsDataDTO(sideBySideData);
    //         }
    //         return;
    //     }
    //     sideBySideData = await WniSxsChangeService.retrieveSxsData(jobID, authHeader);
    //     // const sideBySideData = await WniSxsChangeService.retrieveSxsData(jobID, authHeader);
    //     const newErrorsAndWarnings = _.get(sideBySideData, 'errorsAndWarnings', {});
    //     if (clearUWIssues) {
    //         _.unset(newErrorsAndWarnings, 'underwritingIssues');
    //     }
    //     updateErrorsAndWarnings(newErrorsAndWarnings);
    //     if (sideBySideData.personalAuto) {
    //         updateSxsDataDTO(sideBySideData);
    //         updateWizardPageData({ [WizardConstants.sideBySideData]: sideBySideData });
    //     }
    // };

    const updatePolicyDifferences = async () => {
        const newPolicyDiffData = await PolicyDiffService.getPolicyDiffWithPrevious(jobID,
            authHeader);
        updatePolicyDiffData(newPolicyDiffData);
        updateWizardPageData({ [WizardConstants.policyDiffData]: newPolicyDiffData });
    };

    const withServiceInProgressUpdated = async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        }
        updateServiceCallInProgress(false);
    };

    // // const createNewVersion = async () => {
    // //     submissionVM.value = await WniSxsChangeService.createNewSideBySideVersion(jobID, authHeader);
    // //     updateSideBySideData();
    // // };

    const handlePrint = () => {
        window.print();
    };

    const updateWizardData = useCallback((newSubmissionVM) => {
        updateWizardSnapshot(newSubmissionVM);
    }, [updateSubmissionVM]);

    useEffect(() => {
        WindowUtil.scrollToTop();
        // to be updated
        withServiceInProgressUpdated(async () => {
            updatePolicyDifferences();
        });
    }, []);

    // useEffect(() => {
    //     const setDriverNames = () => {
    //         const drivers = _.get(
    //             submissionVM.value,
    //             'lobData.personalAuto.coverables.drivers',
    //             []
    //         );
    //         const driverNames = drivers.map((driver) => driver.displayName);
    //         updateAllDrivers(driverNames.join());
    //     };

    //     const setVehicleNames = () => {
    //         const vehicleNames = vehicles.map((vehicle) => vehicle.displayName);
    //         updateAllVehicles(vehicleNames.join());
    //     };

    //     if (isLoggedIn) {
    //         setDriverNames();
    //         setVehicleNames();
    //     }
    // }, [isLoggedIn, submissionVM, updateWizardData, vehicles]);

    const onReferToUnderwriter = async (noteForUW) => {
        withServiceInProgressUpdated(async () => {
            const jobResponse = await WniCommonChangeService.referToUnderwriter(jobID,
                noteForUW, authHeader);

            // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
            const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse);
            if (!_.isEmpty(referToUWIssues)) {
                updateExtraBlockingSubmitIssues(referToUWIssues);
                WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
            } else if (!_.isEmpty(jobResponse)) {
                // history.push(`/change/${jobID}/summary`);
                updateWizardReadOnly(true);
            }
        });
    };

    // const showMultiVersions = async () => {
    //     withServiceInProgressUpdated(async () => {
    //         const canSxs = await WniSxsChangeService.canSideBySide(jobID, authHeader);
    //         if (canSxs) {
    //             submissionVM.value = await WniSxsChangeService.initiateSideBySide(
    //                 jobID, authHeader
    //             );
    //             updateWizardData(submissionVM);
    //         }

    //         await updateSideBySideData({
    //             refreshSxsCache: false,
    //             clearUWIssues: true,
    //         });
    //         updateShowMulti(true);
    //     });
    // };

    // const closeMultiVersions = () => {
    //     updateShowMulti(false);
    // };

    // const onAddSideBySideVersion = async () => {
    //     withServiceInProgressUpdated(async () => {
    //         submissionVM.value = await WniSxsChangeService.addSxsVersion(jobID, authHeader);
    //         await updateSideBySideData();

    //         updateWizardData(submissionVM);
    //     });
    // };

    // const onSelectAndQuoteVersion = async (sxsPeriodPublicID) => {
    //     if (!isComponentValid) {
    //         updateShowErrors(true);
    //         WindowUtil.scrollTo(invalidFields);
    //         return;
    //     }
    //     withServiceInProgressUpdated(async () => {
    //         submissionVM.value = await WniSxsChangeService.selectAndQuoteSxsVersion(jobID,
    //             sxsPeriodPublicID, authHeader);

    //         await updateSideBySideData();
    //         await updatePolicyDifferences();

    //         closeMultiVersions();
    //         updateWizardData(submissionVM);
    //     });
    // };

    // const onRemoveSideBySideVersion = async (sxsPeriodPublicID) => {
    //     modalApi.showConfirm({
    //         title: currentMessages.deleteSideBySideVersionTitle,
    //         message: currentMessages.deleteSideBySideVersionMessage,
    //         status: 'warning',
    //         icon: 'gw-error-outline',
    //         confirmButtonText: currentMessages.deleteSideBySidePairConfirm,
    //     }).then((result) => {
    //         if (result === 'cancel' || result === 'close') {
    //             return;
    //         }
    //         withServiceInProgressUpdated(async () => {
    //             submissionVM.value = await WniSxsChangeService.removeSxsVersion(jobID,
    //                 sxsPeriodPublicID, authHeader);
    //             await updateSideBySideData();
    //             updateWizardData(submissionVM);
    //         });
    //     }, _.noop);
    // };

    // const onUpdateCoverage = async (value, changedPath,
    //     lobOfferingPath, lobOfferingIndex) => {
    //     // LineCoveragesInputComponent::changeSubmission()

    //     // LineCoveragesInputComponent::syncCoverages()
    //     // LineCoveragesInputComponent::onClauseUpdate();
    //     ClausesUtil.setClauseValue(submissionVM, value, changedPath);
    //     const lobName = ClausesUtil.getLobNameFromPath(lobOfferingPath);
    //     // const clauseType = WniClausesUtil.getClauseTypeFromPath(changedPath);
    //     // const coveragesPath = `${lobOfferingPath}.coverages.${clauseType}`;
    //     // const clauses = _.get(submissionVM, `${coveragesPath}.value`);
    //     const offeringPeriodPublicID = _.get(submissionVM, `${lobOfferingPath}.publicID_Ext.value`);
    //     // const clausesToUpdate = ClausesUtil.structureClausesForServer(clauses, lobName, clauseType);
    //     const clausesToUpdate = CoveragesUtil.generateUpdatedCoveragesDTO({
    //         submissionVM,
    //         lobName,
    //         selectedVersionOfferingsIndex: lobOfferingIndex,
    //         structureClausesForServer: WniClausesUtil.structureClausesForServerWithoutScheduleItem,
    //     });

    //     const sxsPeriod = QuoteUtil.getSideBySidePeriod(_.get(sxsDataDTO, 'personalAuto.periods', []), offeringPeriodPublicID);
    //     const previousPeriodStatus = _.get(sxsPeriod, 'periodStatus');

    //     // the usage of withServiceInProgressUpdated here breaks isClauseLoading. Hmm.
    //     updateServiceCallInProgress(true);
    //     // withServiceInProgressUpdated(async () => {
    //     const response = await WniSxsChangeService.updateSxsCoverages(
    //         jobID, offeringPeriodPublicID, clausesToUpdate, authHeader
    //     );
    //     submissionVM.value = response;
    //     updateErrorsAndWarnings(_.get(response, 'errorsAndWarnings_Ext'));
    //     updateWizardData(submissionVM);

    //     if (previousPeriodStatus !== 'Draft') {
    //         await updateSideBySideData();
    //     }
    //     // else {
    //     //     const isRecommendedCovInvolved = QuoteCoveragesUtil.isRecommendedCoveragesInvolved('personalAuto',
    //     //         clausesToUpdate);
    //     //     if (isRecommendedCovInvolved) {
    //     //         await updateSideBySideData({
    //     //             sxsDataRetriever: WniSxsQuoteService.retrieveBasicSxsData,
    //     //             // Presever existing errors and warnings
    //     //             quoteErrorsAndWarnings: sxsDataDTO.errorsAndWarnings,
    //     //         });
    //     //     }
    //     // }
    //     // });
    //     updateServiceCallInProgress(false);
    //     // return newSubmissionVM;
    // };
    // ================================================
    const generateOverrides = useCallback(() => {
        const effectiveDateFormat = WniDateUtil.formatDateWithPattern(effectiveDate);
        const startPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodStartDate);
        const endPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodEndDate);

        // const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        // const columnData = generateColumnData({
        //     submissionVM,
        //     sxsDataDTO,
        //     isClueCompare,
        //     isMvrCompare,
        //     callbackMap: {
        //         // onFinishQuote,
        //         // onSubmitQuote,
        //         // onReferToUnderwriter,
        //         // onContinueToIssue: async () => onContinueToIssue(onNext),
        //         // goToIncidentReports
        //         // onAddSideBySideVersion,
        //         onSelectAndQuoteVersion,
        //         onRemoveSideBySideVersion,
        //         onUpdateCoverage,
        //     }
        // });
        // const tableData = generateTableData(submissionVM, columnData, translator);

        const newErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        const validationIssues_all = ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings,
            [
                // ...oosErrorsAndWarnings,
                ...extraBlockingSubmitIssues
            ]);
        // POI-55289: requirement requests hiding of all waring messages
        const validationIssues = validationIssues_all.filter((issue) => issue.type !== 'warning');
            
        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);

        // const underwritingIssues = _.get(newErrorsAndWarnings, 'underwritingIssues', []);
        const underwritingIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(newErrorsAndWarnings, 'underwritingIssues', []), selectedVersion
        );
        // const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(newErrorsAndWarnings);
        const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue({ underwritingIssues });

        updateHasBlockingIssue(hasBlockUWIssue || hasValidationError);

        const changeUtil = PolicyChangeQuoteUtil({
            intl, translator, totalCost, transactionCost, currentMessages
        });
        const premiumDiffMsg = changeUtil.getPremiumDiffMessage();
        const premiumDiffTitle = changeUtil.getPremiumDiffTitle();
        return {
            '@field': {
                labelPosition: 'left'
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssues),
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator, (issueType) => {
                    return issueType === 'error' ? currentMessages.submitTheChange : undefined;
                }),
                typeFooterFormatter: getQuotePageErrorsAndWarningsFooter({
                    wizardSteps,
                    wizardStepToFieldMapping,
                    wizardJumpTo,
                    resolveButtonLabel: translator(currentMessages.resolveErrors),
                    isServiceCallInProgress,
                }),
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                // Refactoring Notes: should retrieve validation result for current slice only
                issueDistinguisher: true,
            },
            quoteUWIssues: {
                underwritingIssues,
                // headerObject: currentMessages.IssuesRequiringReferral,
            },
            addNewVersionBtn: {
                visible: false,
            },
            deleteQuoteVersionButton: {
                visible: false,
            },
            referToUWBtn: {
                onReferToUnderwriter,
                isServiceCallInProgress,
                visible: false, // hasBlockUWIssue,
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            },
            printBtn: {
                disabled: isServiceCallInProgress,
            },
            // printBtnV2: {
            //     disabled: isServiceCallInProgress,
            // },
            // =====================
            policyChangeSummary: {
                validationIssues: premiumDiffMsg,
                typeTitleFormatter: premiumDiffTitle,
                previousTotalCost,
                transactionCost,
                totalCost,
                effectiveDateFormat,
                periodDateRange: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`,
                // transactionCostAmount,
                showPremiumChange: jobStatus === 'Quoted' || jobStatus === 'Bound',
            },
            // // ==========================
            PAHeadId: {
                visible: !isShowMulti
            },
            policyChangeDetailId: {
                visible: !isShowMulti
            },
            paPolicyDiff: {
                policyDiffData,
                isServiceCallInProgress,
                // vehicles,
            },
            // multiVersionsId: {
            //     visible: isShowMulti
            // },
            // multiVersionBtn: {
            //     // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            //     visible: false,
            // },
            // backToDetailBtn: {
            //     disabled: isServiceCallInProgress,
            // },
            // addNewBtn: {
            //     // disabled: true,
            //     visible: PolicyChangeUtil.canAddSideBySideChangePeriod(_.get(sxsDataDTO, 'personalAuto.periods')),
            //     // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            //     content: renderHelper.getButtonContent(translator(currentMessages.addNewVersion), isServiceCallInProgress),
            // },
            // sxsPeriodsInfoSection: {
            //     columnData,
            //     isServiceCallInProgress,
            //     showActionButtons: false,
            //     sxsPeriodInfo: PolicyChangeSideBySidePeriodInfo,
            //     readyForSxsRemovalCheckFn: PolicyChangeUtil.canRemoveSideBySideChangePeriod,
            // },
            // quoteTable: {
            //     columnData,
            //     tableData,
            //     // isEditable: false,
            //     onValidate,
            //     showErrors,
            // },
        };
    },
    [
        submissionVM,
        isServiceCallInProgress,
        errorsAndWarnings, sxsDataDTO,
        translator,
        isShowMulti,
        policyDiffData,
        showErrors,
    ]);

    const renderQuotePage = useCallback(({ onNext }) => {
        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                // // onStaleQuoteBranchCode: _.noop,
                // onChangeSubmissionAndSync: onUpdateCoverage,
                // // onChangeSubmission: updateWizardData,
                // // onSyncCoverages: _.noop,
                // onShowMultiVersions: showMultiVersions,
                // onBackToDetail: closeMultiVersions,
                // onCreateNewVersion: onAddSideBySideVersion,
                onPrint: handlePrint
            },
            resolveComponentMap: {
                sxsquoteperiods: SideBySidePeriodsComponent,
                validationissuescomponent: ValidationIssuesComponent,
                quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
                // notificationcomponent: SuccessNotification,
                policychangequotecomponent: WALPolicyChangeQuoteComponent,
                refertouw: ReferToUnderwriterComponent,
                changesummary: ChangeSummaryComponent,
                addnewversioncomponent: WALAddNewChangeVersionComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateSubmissionVM}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, submissionVM, updateSubmissionVM]); // sxsDataDTO

    return (
        <WizardPage
            showNext={false}
            // cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            nextLabel={ isBillingOptionsEditable ? undefined : currentMessages.submitChange}
        >
            {renderQuotePage}
        </WizardPage>
    );
}

WALQuoteChangeReadOnlyPage.propTypes = wizardProps;
export default WALQuoteChangeReadOnlyPage;
