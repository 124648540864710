import { defineMessages } from 'react-intl';

export default defineMessages({
    considerTitle: {
        id: 'platform.accountsummary.issueapprovedquotes.modal.Did you consider the following from Western National ?',
        defaultMessage: 'Did you consider the following from {domainName} ?'
    },
    userAgreement: {
        id: 'platform.accountsummary.issueapprovedquotes.modal.User Agreement',
        defaultMessage: 'User Agreement'
    },
    nextButton: {
        id: 'platform.accountsummary.issueapprovedquotes.modal.next',
        defaultMessage: 'Next'
    },
    UserAggreenmenli2: {
        id: 'custom.accountsummary.issueapprovedquotes.modal.user Aggreenmen li2',
        defaultMessage: 'Signed Application forms with the Insured\'s signature will be retained and made available at the request of {domainName}.'
    }
});
