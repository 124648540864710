import React, {
    useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { UWBlockingPoint } from '@xengage/gw-portals-edge-validation-js';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './CPQuotePage.metadata.json5';

import styles from './CPQuotePage.module.scss';
import './CPQuotePage.messages';

const BLOCKS_QUOTES = [UWBlockingPoint.BLOCKS_QUOTE, UWBlockingPoint.BLOCKS_QUOTE_RELEASE];

function CPQuotePage(props) {
    const {
        wizardData: submissionVM, steps, jumpTo, underwritingIssues
    } = props;
    const [quoteBlocked, isQuoteBlocked] = useState(false);
    const [offeredQuotes, setOfferedQuotes] = useState();

    useEffect(() => {
        setOfferedQuotes(_.get(submissionVM.value, 'quoteData.offeredQuotes'));

        const isBlocked = underwritingIssues.some(
            (uwIssue) => uwIssue.currentBlockingPoint !== UWBlockingPoint.NON_BLOCKING
        );

        isQuoteBlocked(isBlocked || offeredQuotes === undefined);
    }, [offeredQuotes, submissionVM, underwritingIssues]);

    const handleNext = useCallback(() => {
        _.set(submissionVM, 'bindData.chosenQuote', offeredQuotes[0].publicID);
        return submissionVM;
    }, [submissionVM, offeredQuotes]);

    const handlePrint = () => {
        window.print();
    };

    const findBlockingPoint = useCallback(() => {
        return underwritingIssues.some((offeringIssue) => {
            return _.includes(BLOCKS_QUOTES, offeringIssue.currentBlockingPoint);
        });
    }, [underwritingIssues]);

    const navigateToCoverages = useCallback(() => {
        const indexOfGeneralCoverages = _.findIndex(
            steps,
            ({ path }) => path === '/buildings-and-locations'
        );
        jumpTo(indexOfGeneralCoverages);
    }, [jumpTo, steps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            navigateToCoverages: navigateToCoverages,
            onPrint: handlePrint
        }
    };

    const overrides = {
        '@field': {
            labelPosition: 'left'
        },
        quoteBox: {
            visible: offeredQuotes !== undefined
        },
        underWritingIssues: {
            underwritingIssues: underwritingIssues,
            blockQuote: findBlockingPoint(),
            quoteID: _.get(submissionVM, 'quoteID.value'),
            filterUWIssuesInCustomOffering: false
        },
        policyPeriodDateRange: {
            value: {
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value'),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value'),
            }
        },
    };

    return (
        <WizardPage
            onNext={handleNext}
            disableNext={quoteBlocked}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

CPQuotePage.propTypes = wizardProps;
export default CPQuotePage;
