import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { CAQualificationService } from 'wni-capability-quoteandbind-ca';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/CAWizardPage';

import metadata from './CAQualificationPage.metadata.json5';
import messages from './CAQualificationPage.messages';

function CAQualificationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        caQualificationService
    } = props;

    // const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CAQualificationPage');

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const questionsetAnswers = _.get(submissionVM, 'lobData.commercialAuto.preQualQuestionSets.value[0].answers') || {};
    const displayQuestions = [
        'CA7DriverRecruiting', // Does the applicant have a specific driver recruiting method?
        'CA7EmployeeAutoUsage', // Do over 50% of the employees use their own autos in the business?
        'CA7HazMat', // Do operations involve the transportation of hazardous materials?
        'CA7VehicleMaintProgram', // Is there a vehicle maintenance program in operation?
        // 'CA7RadiusOver300_Ext', // Does your radius operations exceed 300 miles?
        // 'CA7Over3AutoLossInPast5Year_Ext', // Have there been three (3) or more auto losses in past 5 years?
        // 'CA7IfHiredAndNonOwned_Ext' // If hired and non-owned auto coverage is provided, does the ...
    ];

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const IsValidField = (value) => {
        if (value === undefined || value === null || value === '') {
            return false;
        }
        return true;
    }

    const IsValidFields = useCallback(() => {
        const invalidFields = _.filter(displayQuestions, (code) => {
            return IsValidField(questionsetAnswers[code]) === false
        })
        if (invalidFields.length <= 0) {
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const filterQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const getQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            if (metaDataContent.id === 'CA7HazMat') {
                _.set(metaDataContent, 'component', "WniToggle");
                _.set(metaDataContent, 'componentProps.tooltip', {
                    "text": {
                        "id": "wni.quoteandbind.ca.qualification.CA7HazMat tooltip",
                        "defaultMessage": "FMCSA definition of hazardous material: Class 1 - Explosives, Class 2 - Gases, Class 3 - Flammable liquids, Class 4 - Flammable solids or substances"
                    }
                });
            }
            _.set(metaDataContent, 'componentProps.showRequired', true);
            _.set(metaDataContent, 'componentProps.required', true);
            if (!IsValidField(_.get(questionsetAnswers, metaDataContent.componentProps.path))) {
                _.set(metaDataContent, 'componentProps.validationMessages', [translator(messages.CAValidationMessages)]);
            } else {
                _.set(metaDataContent, 'componentProps.validationMessages', []);
            }
            return metaDataContent;
        }
    }, []);

    const getValidationIssues = useCallback((issues) => {
        const CAUnfavorablyAnswerWarning = [{
            type: 'warning',
            reason: translator(messages.CAUnfavorablyAnswerWarning)
        }]
        if (!_.isEmpty(questionsetAnswers)) {
            if (
                questionsetAnswers.CA7EmployeeAutoUsage === 'true' || questionsetAnswers.CA7EmployeeAutoUsage === true
                || questionsetAnswers.CA7VehicleMaintProgram === 'false' || questionsetAnswers.CA7VehicleMaintProgram === false
                || questionsetAnswers.CA7HazMat === 'true' || questionsetAnswers.CA7HazMat === true
                || questionsetAnswers.CA7DriverRecruiting === 'false' || questionsetAnswers.CA7DriverRecruiting === false
                // || questionsetAnswers.CA7RadiusOver300_Ext === 'true' || questionsetAnswers.CA7RadiusOver300_Ext === true
                // || questionsetAnswers.CA7Over3AutoLossInPast5Year_Ext === 'true' || questionsetAnswers.CA7Over3AutoLossInPast5Year_Ext === true
                // || questionsetAnswers.CA7IfHiredAndNonOwned_Ext === 'false' || questionsetAnswers.CA7IfHiredAndNonOwned_Ext === false
            ) {
                return _.concat(issues, CAUnfavorablyAnswerWarning);
            }
        }
        return issues;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left'
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                contentMapper: getQuestionMapper()
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, 'lobData.commercialAuto.preQualQuestionSets.value')
        };
        const res = await caQualificationService.updateQualification(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.commercialAuto.preQualQuestionSets.value', res.preQualQuestionSets);
        }
        updateServiceCallInProgress(false);
        updateWizardData(newSubmissionVM);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        const resValidationIssues = getValidationIssues(newValidationIssues);
        updateValidationIssues(resValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(resValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(resValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, caQualificationService, jobID, sessionUUID, submissionVM, displayWarnings]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CAQualificationPage.propTypes = WizardPage.propTypes;
CAQualificationPage.defaultProps = WizardPage.defaultProps;

CAQualificationPage.propTypes = {
    ...wizardProps,
    caQualificationService: PropTypes.object
};

CAQualificationPage.defaultProps = {
    caQualificationService: CAQualificationService
};
export default CAQualificationPage;