

import React from 'react';
import { R1Config } from 'wni-portals-config-js';

const { pageConfig: defaultPageConfig } = R1Config.WniTableConfig;

function setTablePublicIDSelected(publicID, tableID) {
    const tableDom = document.getElementById(tableID);
    if (!tableDom) {return false};
    const AllRowDom = tableDom.getElementsByClassName('rt-tr-group');
    Array.from(AllRowDom).forEach((v) => {
        v.classList.remove('selected');
    });
    if (!publicID) {return false};
    const currentDom = tableDom.getElementsByClassName(`rowId${publicID}`)[0];
    if (!currentDom) {
        return false;
    }
    const rowDOM = currentDom.closest('.rt-tr-group');
    rowDOM.classList.add('selected');
    return true;
}

function renderCell(id, displayName) {
    const rowId = `rowId${id}`;
    return <span className={rowId}>{displayName}</span>;
}
/**
 * Generate random numbers
 * @returns {string}
 */
function getUuid() {
    // eslint-disable-next-line no-restricted-globals
    return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
}

export default {
    setTablePublicIDSelected,
    renderCell,
    defaultPageConfig,
    getUuid
};
