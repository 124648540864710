import React from 'react';
import { CRStateSpecificInformationReadOnlyPage } from 'wni-capability-quoteandbind-cr-react';

function CRStateSpecificInformationChangeReadOnlyPage(props) {

    return (
        <CRStateSpecificInformationReadOnlyPage {...props} isReadOnly />
    );
}

export default CRStateSpecificInformationChangeReadOnlyPage;