import { defineMessages } from 'react-intl';

export default defineMessages({
    hoIncidentAndReports: {
        id: 'wni.quoteandbind.ho.wizard.step.LossHistoryAndPriorCarrierhoge',
        // defaultMessage: 'Incidents & Report Validation'
        defaultMessage: 'Loss History & Prior Carrier',
    },
    hoAmountpaid: {
        id: 'ho.quote.views.prior loss.userAdd.Amount paid',
        defaultMessage: 'Amount paid'
    },
    hoCoveragespaid: {
        id: 'ho.quote.views.clue.Coverages paid',
        defaultMessage: 'Coverages paid'
    },
    hoCarrierName: {
        id: 'ho.quote.views.clue.Carrier Name',
        defaultMessage: 'Carrier Name'
    },
    hoPolicyholderName: {
        id: 'ho.quote.views.clue.Policy Holder Name',
        defaultMessage: 'policy Holder Name'
    },
    hoCLUEPolicyholderName: {
        id: 'ho.quote.views.clue.CLUE Policy Holder Name',
        defaultMessage: 'Policyholder Name'
    },
    hoPolicyTypeCompany: {
        id: 'ho.quote.views.clue.CLUE Policy Type Company',
        defaultMessage: 'Policy Type Company'
    },
    hoLocationOfLoss: {
        id: 'ho.quote.views.clue.CLUE location of loss',
        defaultMessage: 'Location Of Loss'
    },
    hoExplaination: {
        id: 'ho.quote.views.clue.CLUE explaination',
        defaultMessage: 'Please Explain'
    },
    hoDispute: {
        id: 'ho.quote.views.clue.CLUE dispute',
        defaultMessage: 'Dispute'
    },
    hoCategory: {
        id: 'ho.quote.views.clue.category',
        defaultMessage: 'Category'
    },
    hoSource: {
        id: 'ho.quote.views.clue.source',
        defaultMessage: 'Source'
    },
    hoClaimDate: {
        id: 'ho.quote.views.clue.Claim Date',
        defaultMessage: 'Claim Date'
    },
    hoCauseOfLoss: {
        id: 'ho.quote.views.clue.Cause Of Loss',
        defaultMessage: 'Cause Of Loss'
    },
    hoCatastropheRelated: {
        id: 'ho.quote.views.clue.Catastrophe Related',
        defaultMessage: 'Catastrophe Related'
    },
    hoRiskAddress: {
        id: 'ho.quote.views.clue.Risk Address',
        defaultMessage: 'Risk Address'
    },
    hoAddress: {
        id: 'ho.quote.views.clue.Address',
        defaultMessage: 'Address'
    },
    hoWhatCauseOfLoss: {
        id: 'ho.quote.views.clue.what Cause Of Loss',
        defaultMessage: 'What is the cause of loss?'
    },
    hoMitigationSteps: {
        id: 'ho.quote.views.clue.Mitigation Steps',
        defaultMessage: 'Additional Comment'
    },
    hoDescription: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Description',
        defaultMessage: 'Description'
    },
    hoSurchargeable: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Surchargeable',
        defaultMessage: 'Surchargeable'
    },
    hoSurChargeApplied: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.SurchargeApplied',
        defaultMessage: 'Surcharge Applied'
    },
    hoIncidentDate: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Incident Date',
        defaultMessage: 'Incident Date'
    },
    hoCarrier: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Carrier',
        defaultMessage: 'Carrier'
    },
    hoCancelledExpirationDate: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Cancelled/Expiration Date',
        defaultMessage: 'Cancelled/Expiration Date'
    },
    hoReasonNoinsurance: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Reason for No insurance',
        defaultMessage: 'Reason for No insurance'
    },
    hoTimewithPriorCarrier: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Time With Prior Carrier',
        defaultMessage: 'Time With Prior Carrier'
    },
    hoUnderwritingResponse: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Underwriting Response',
        defaultMessage: 'Underwriting Response'
    },
    hoDateofBirth: {
        id: 'ho.quote.views.clue.date of birth',
        defaultMessage: 'Date of Birth'
    },
    hoVehicle: {
        id: 'ho.quote.views.clue.Vehicle',
        defaultMessage: 'Vehicle'
    },
    hoStatus: {
        id: 'ho.quote.views.clue.Status',
        defaultMessage: 'Status'
    },
    hoUserEnteredInformation: {
        id: "hoUserEnteredInformation",
        defaultMessage: "USER ENTERED INFORMATION"
    },
    hoPriorCarrier: {
        id: "hoPriorCarrier",
        defaultMessage: "Prior Carrier"
    },
    hoSecondUserEnteredInformation: {
        id: "hoSecondUserEnteredInformation",
        defaultMessage: "USER ENTERED INFORMATION"
    },
    hoPriorLosses: {
        id: "hoPriorLosses",
        defaultMessage: "Prior Losses"
    },
    hoVendorInformation: {
        id: "hoVendorInformation",
        defaultMessage: "VENDOR INFORMATION"
    },
    hoSecondPriorLosses: {
        id: "hoSecondPriorLosses",
        defaultMessage: "Prior Losses"
    },
    userPriorCarrierStatus: {
        id: 'ho.quote.views.priorcarrier.userPriorCarrierStatus',
        defaultMessage: 'Status'
    },
    userCLUEStatus: {
        id: 'ho.quote.views.clue.userCLUEStatus',
        defaultMessage: 'Status'
    },
    hoReason: {
        id: 'ho.quote.views.clue.Reason',
        defaultMessage: 'Reason for No Insurance'
    },
    ModalOk: {
        id: 'ho.quote.views.modal.OK',
        defaultMessage: 'OK',
    },
    ModalCancel: {
        id: 'ho.quote.views.modal.Cancel',
        defaultMessage: 'Cancel',
    },
    ModaldeleteMsg: {
        id: 'ho.quote.views.modal.Are you confirm to delete this item ?',
        defaultMessage: 'Are you confirm to delete this item ?'
    },
    hoUserEnteredInformation: {
        id: 'ho.quote.views.accordion.user entered information',
        defaultMessage: 'user entered information',
    },
    hoCLUEReportedLoss: {
        id: 'ho.quote.views.prior loss.CLUE.userAdd.User Enter',
        defaultMessage: 'Prior Loss Reported',
    },
    hoAdd: {
        id: 'ho.quote.views.prior loss.userAdd.+ Add',
        defaultMessage: '+ Add',
    },
    hoCurrentCarrierReported: {
        id: 'ho.quote.views.incident.reported.Current Carrier Reported',
        defaultMessage: 'Current Carrier Reported',
    },
    hoCurrenClaimsReported: {
        id: 'ho.quote.views.incident.reported.Current Claims Reported',
        defaultMessage: 'Current Policy Claims Reported',
    },
    hoLapse: {
        id: 'ho.quote.views.prior.carrier.Lapse',
        defaultMessage: 'Lapse'
    },
    hoDisputeRequest: {
        id: 'ho.quote.views.prior.carrier.Dispute Request',
        defaultMessage: 'Dispute Request'
    },
    hoDisputeExplanation: {
        id: 'ho.quote.views.prior.carrier.Explanation',
        defaultMessage: 'Explanation'
    },
    hoCarrierUnderwritingResponse: {
        id: 'ho.quote.views.prior.carrier.Underwriting Response',
        defaultMessage: 'Underwriting Response'
    },
    hoUnderwritingReason: {
        id: 'ho.quote.views.prior.carrier.Underwriting Reason',
        defaultMessage: 'Underwriting Reason'
    },
    hoEditPriorCarrier: {
        id: 'ho.quote.views.prior.carrier.Edit Prior Carrier',
        defaultMessage: 'Edit Prior Carrier',
    },
    hoAddPriorCarrier: {
        id: 'ho.quote.views.prior.carrier.Add Prior Carrier',
        defaultMessage: 'Add Prior Carrier',
    },
    hoEffectiveDate: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Effective Date',
        defaultMessage: 'Effective Date'
    },
    hoReasonForNoInsurance: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.Reason For No Insurance',
        defaultMessage: 'Reason For No Insurance'
    },
    hoDisputeTooltip: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.dispute tooltip',
        defaultMessage: 'If this incident does not apply, please check the box and add an explanation for the underwriter to review.'
    },
    priorCarrierTooltip: {
        id: 'ho.quote.views.priorCarrierAndLossHistory.prior carrier.dispute tooltip',
        defaultMessage: 'If any prior carrier information is incorrect, please select the field in question for an underwriter to review'
    },
    hoOtherCarrierName: {
        id: 'ho.quote.views.prior.carrier.Other Carrier Name',
        defaultMessage: 'Other Carrier Name'
    },
    hoLossDate: {
        id: 'ho.quote.views.policyclaims.Loss Date',
        defaultMessage: 'Loss Date'
    },
    hoLossCause: {
        id: 'ho.quote.views.policyclaims.Loss Cause',
        defaultMessage: 'Loss Cause'
    },
    hoTotalpaid: {
        id: 'ho.quote.views.policyclaims.Incurred Amount',
        defaultMessage: 'Incurred Amount'
    },
    hoClaimsSurchargeable: {
        id: 'ho.quote.views.policyclaims.Surchargeable',
        defaultMessage: 'Surchargeable'
    },
    hoClaimsSurchargeApplied: {
        id: 'ho.quote.views.policyclaims.Surcharge Applied',
        defaultMessage: 'Surcharge Applied'
    },
    AddPriorLossesEntry: {
        id: 'ho.quote.views.prior.losses entry.Add Prior Losses Entry',
        defaultMessage: 'Add Prior Loss Entry'
    },
    EditPriorLossesEntry: {
        id: 'ho.quote.views.prior.losses entry.Edit Prior Losses Entry',
        defaultMessage: 'Edit Prior Loss Entry'
    },
    ViewPriorLossesEntry: {
        id: 'ho.quote.views.prior.losses entry.View Prior Losses Entry',
        defaultMessage: 'View Prior Loss Entry'
    },
    ViewPriorLossesVendor: {
        id: 'ho.quote.views.prior.losses entry.View Prior Losses Vendor',
        defaultMessage: 'View Prior Loss Reports'
    },
    EditPriorLossesVendor: {
        id: 'ho.quote.views.prior.losses entry.Edit Prior Losses Vendor',
        defaultMessage: 'Edit Prior Loss Reports'
    },
    NotApplicable: {
        id: 'ho.quote.incident.views.Not applicable',
        defaultMessage: 'Not applicable'
    },
    Unoccupied: {
        id: 'ho.quoteincident.views.Unoccupied',
        defaultMessage: 'Unoccupied'
    },
    CreditAuth1: {
        id: 'ho.quote.title.CreditAuth1',
        defaultMessage: 'Policy premium is calculated in part based on information obtained from consumer reports. Agents are required to inform customers that they will be requesting information from consumer reports as hort of the quoting process.'
    },
    CreditAuth2: {
        id: 'ho.quote.title.CreditAuth2',
        defaultMessage: 'Please note that reports will be run during the Run Validation step.  To ensure accuracy of the quote, please enter losses and violations manually. For a more accurate quote, please enter all incident information from the last 5 years below:'
    },
    otherThanCollisionDetails: {
        id: 'ho.quote.views.coverage.paid.Other Than Collision Details',
        defaultMessage: 'Other Than Collision Details'
    },
    manuallyClueHeader: {
        id: 'ho.quote.incident.Prior Losses Records',
        defaultMessage: 'Prior Losses { length } Record'
    },
    priorCarrierMissingForIssuance: {
        id: 'ho.quote.incident.Prior Carrier Missing',
        defaultMessage: 'Below required fields for issuance are missing: Prior Carrier'
    },
    whatIsTheCauseOfLossOnNext:{
        id: 'ho.quote.incident.What is the cause of loss? Is required for fire or water losses.  Update CLUE or manual loss.',
        defaultMessage: 'What is the cause of loss? Is required for fire or water losses.  Update CLUE or manual loss.'
    },
    causeOfLossIneligible:{
        id: 'ho.quote.incident.lossCauseIsIneligible.',
        defaultMessage: '{lossCause} is ineligible.'
    },
    lossHistory:{
        id: 'ho.quote.incident.lossHistory.',
        defaultMessage: 'Loss History'
    }
});
