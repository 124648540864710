import React, {
    useState,
    useContext,
}from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Button } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './SingleInjuryIncident.metadata.json5'
import messages from './SingleInjuryIncident.message'


const SingleInjuryIncident = (props) => {
    const {
        originInjuryIncident,
        editingInjuryIncidentIndex,
        onPopupCancel,
        onPopupSave,
    } = props


    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SingleInjuryIncidentPopup')

    const [showErrors, setShowErrors] = useState(false)
    const [injuryIncidentVM, setInjuryIncidentVM] = useState(() => viewModelService.create(
            originInjuryIncident,
            'cc',
            'wni.edge.capabilities.claim.lob.shared.incidents.dto.WniPEInjuryIncidentDTO'
        ))

    const writeValue = (value, path) => {
        const newInjuryIncidentVM = _.clone(injuryIncidentVM);

        _.set(newInjuryIncidentVM.value, path, value);
        if (path.includes('ownerContact_Ext')) {
            _.set(newInjuryIncidentVM.value, 'injuredContact.updated_Ext', true);
        }
        setInjuryIncidentVM(newInjuryIncidentVM);
    }


    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(injuryIncidentVM.value, editingInjuryIncidentIndex)
    }

    const overrideProps = {
        workPhone: {
            visible: _.get(injuryIncidentVM.value, 'injuredContact.primaryPhoneType') === 'work'
        },
        homePhone: {
            visible: _.get(injuryIncidentVM.value, 'injuredContact.primaryPhoneType') === 'home'
        },
        cellPhone: {
            visible: _.get(injuryIncidentVM.value, 'injuredContact.primaryPhoneType') === 'mobile'
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={injuryIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div style={{
                display: 'flex',
                'justify-content': 'right',
                gap: '10px'
            }}>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
                label={messages.cancel}
            />
            <Button
                type="filled"
                onClick={onSaveClicked}
                label={messages.save}
            />
        </div>
        
    </>
}

export default SingleInjuryIncident
