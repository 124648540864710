import { DocumentUploadService } from '@xengage/gw-portals-document-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpLocationService'), method, data, additionalHeaders);
}

export default class GLLocationService {
    /**
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} location
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateLocation(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'updateLocation',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

    /**
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static fetchLocations(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission(
            'fetchLocations',
            [quoteID, sessionUUID],
            authHeader
        );
    }

    /**
     *
     *  @param {string} quoteID
     * @param {string} sessionUUID
     *@param {Array} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeLocations(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeLocations',
            [quoteID, sessionUUID, publicIDs],
            authHeader
        );
    }

    static postOnChangeAction(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'postOnChangeAction',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

    static addExistingLocations(
        quoteID,
        existingLocationPublicIds,
        sessionUUID,
        authHeader = {}
    ) {
        return processSubmission(
            'addExistingLocations',
            [quoteID, existingLocationPublicIds, sessionUUID],
            authHeader
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader
        );
    }

    /**
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {string} locationpublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getLocationClauses(
        jobID,
        sessionUUID,
        locationpublicID,
        authHeader = {}
    ) {
        return processSubmission(
            'getLocationClauses',
            [jobID, sessionUUID, locationpublicID],
            authHeader
        );
    }

    /**
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {string} locationpublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateLocationClauses(
        jobID,
        sessionUUID,
        locationpublicID,
        dto,
        authHeader = {}
    ) {
        return processSubmission(
            'updateLocationClauses',
            [jobID, sessionUUID, locationpublicID, dto],
            authHeader
        );
    }

    /**
     *
     * @param {Object} documentMetaDataTemplate
     * @param {object} file
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     * @returns
     */
    static upload(file, documentMetadata, authHeader = {}) {
        return DocumentUploadService.send(
            getProxiedServiceUrl('cpUploadService'),
            file,
            documentMetadata,
            authHeader
        );
    }
}