import React, {
    useCallback
} from 'react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import {
    WCMultiQuoteService,
    WCRiskAnalysisService
} from 'wni-capability-quoteandbind-wc';
import CARiskAnalysisPage from "wni-capability-quoteandbind-ca-react/pages/RiskAnalysis/CARiskAnalysisPage";
import WizardPage from '../../templates/WCWizardPage';

function WCRiskAnalysisPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardPageData,
        //
        // onPreQuotePageNext,
    } = props;
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    // =================================================================
    const onDefaultPageNext = useCallback(async () =>{
        const {
            jobID,
            sessionUUID,
            baseData: {
                periodStatus,
            },
        } = submissionVM.value;

        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
            setLoadingMask(true);
            submissionVM.value = await WCMultiQuoteService.processQuote(
            // submissionVM.value = await processQuote(
                jobID, sessionUUID, authHeader
            );
            setLoadingMask(false);
        }
        return submissionVM;
    }, [authHeader, submissionVM, updateWizardPageData]);
    // =================================================================

    const onPageNext = useCallback(async () => {
        
        const {
            onPreQuotePageNext = onDefaultPageNext,
        } = props;
        //
        // return submissionVM;

        const retval = await onPreQuotePageNext();
        return retval;
    }, [authHeader, submissionVM, onDefaultPageNext]);

    return (
        <CARiskAnalysisPage {...props}
            onPreQuotePageNext={onPageNext}
            riskAnalysisService={WCRiskAnalysisService}
            lobName='workersComp'
        />
    );
}

WCRiskAnalysisPage.propTypes = WizardPage.propTypes;
WCRiskAnalysisPage.defaultProps = WizardPage.defaultProps;
export default WCRiskAnalysisPage;