import React, { useEffect, useState } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import metadata from './PersonalProducts.metadata.json5';

function PersonalProducts(props) {
    const { availableValues = [], value, onValueChange } = props;
    const [planValue, updatePlanValue] = useState([]);
    const [otherValue, updateOtherValue] = useState([]);
    const planProductsMap = availableValues.filter((item) => item.type === 'plan');
    const otherProductsMap = availableValues.filter((item) => item.type === 'other');

    useEffect(() => {
        const initPlanValue = value.filter((item) => planProductsMap.some((v) => v.code === item));
        updatePlanValue(initPlanValue);
        const initOtherValue = value.filter((item) => otherProductsMap.some((v) => v.code === item));
        updateOtherValue(initOtherValue);
    }, [availableValues]);

    useEffect(() => {
        const newValue = planValue.concat(otherValue);
        onValueChange(newValue);
    }, [planValue, otherValue]);

    const overrideProps = {
        planProductsField: {
            availableValues: planProductsMap,
            value: planValue,
            onValueChange: updatePlanValue
        },
        otherProductsField: {
            availableValues: otherProductsMap,
            value: otherValue,
            onValueChange: updateOtherValue
        }
    };

    const resolvers = {};
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default PersonalProducts;
