import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imMotorTruckCargoService'), method, data, additionalHeaders);
}

export default class IMMotorTruckCargoService {
  
    /**
     * Invokes IMMotorTruckCargoCoverageHandler.getMotorTruckCargoCoverageClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getMotorTruckCargoCoverageClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMotorTruckCargoCoverageClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.getMotorTruckCargoCoverageClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getMotorTruckCargoCoverageClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMotorTruckCargoCoverageClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.updateMotorTruckCargoCoverageClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} coverageToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateMotorTruckCargoCoverageClauses(jobNumber, sessionUUID, coverageToUpdate, authHeader = {}) {
        return processSubmission('updateMotorTruckCargoCoverageClauses', [jobNumber, sessionUUID, coverageToUpdate], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.updateCoveragePartSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }

    /**
     * Invokes IMMotorTruckCargoCoverageHandler.onMotorTruckCargoNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onMotorTruckCargoNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onMotorTruckCargoNext', [jobNumber, sessionUUID], authHeader);
    }
}