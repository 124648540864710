import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { WniCustomEndorsementService } from 'wni-capability-policychange';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { DocumentsUtil } from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { DocumentsComponent } from 'wni-capability-gateway-react';
import documentMessage from 'wni-capability-gateway-react/components/DocumentsComponent/DocumentsComponent.messages';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import messages from './EndorsementDocuments.messages';
import metadata from './EndorsementDocuments.metadata.json5';

class EndorsementDocumentsWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadPolicyChangeSummaryCount: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            }),
            basePath: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        domainCompany: PropTypes.shape({
            availableStates: PropTypes.arrayOf(PropTypes.shape({}))
        }).isRequired
    }

    state = {
        documentsData: []
    }

    componentDidMount() {
        this.getDocuments();
    }

    handleError = (title, message) => {
        return this.props.modalContext.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(() => {
            _.noop();
        });
    }

    handleWarning = (title, message) => {
        return this.props.modalContext.showAlert({
            title: title,
            message: message,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(() => {
            _.noop();
        });
    }

    getDocuments = (async () => {
        const {
            authHeader, fromAccountLanding:
            { quoteDetailsData: { jobNumber, loadPolicyChangeSummaryCount } }
        } = this.props;
        try {
            const attachments = await WniCustomEndorsementService
                .getPortalAttachmentsForPolicyChange([jobNumber], authHeader);
            this.setState({
                documentsData: attachments
            });
            loadPolicyChangeSummaryCount();
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    })

    getDocumentsTileUpdatedCount = () => {
        const {
            fromAccountLanding:
            { quoteDetailsData: { loadPolicyChangeSummaryCount } },
        } = this.props;
        loadPolicyChangeSummaryCount('Documents');
    }

    claimsDocUploadToken = (async () => {
        const { authHeader } = this.props;
        try {
            const uploadTokenID = await GatewayDocumentService
                .generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return this.handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    });

    uploadDocument = (async (file, vm) => {
        const {
            authHeader, fromAccountLanding:
            { quoteDetailsData: { jobNumber } },
            domainCompany
        } = this.props;
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: _.get(vm, 'value.documentType'),
            description: _.get(vm, 'value.description'),
            securityType: 'unrestricted',
            status: 'approved',
            // policyNumber for policy change is the jobNumber
            jobNumber: jobNumber,
            name: file.name,
            mimeType: file.type,
            sessionID: await this.claimsDocUploadToken()
        };
        this.setState({ uploadSuccess: false });
        try {
            const documentUploadData = await GatewayDocumentService
                .uploadDocument(file, documentMetaDataTemplate,
                    authHeader);
            if (!_.isEmpty(documentUploadData)) {
                this.setState({ uploadSuccess: true });
                this.getDocuments();
                this.getDocumentsTileUpdatedCount();
            }
        } catch (error) {
            // BR.PL.0254
            if (error
                && _.get(error, 'baseError.message') === 'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.'
                && _.get(error, 'baseError.code') === -32603
            ) {
                const message = _.get(domainCompany, 'code') === 'UIC' ? messages.uploadFailedMessageUIC : messages.uploadFailedMessage;
                this.handleWarning(
                    messages.warningUploadTitle,
                    message
                );
            } else {
                this.handleError(
                    commonMessages.errorUploadTitle,
                    commonMessages.uploadFailedMessage
                );
            }
        }
    })

    deleteDocument = (async (publicID) => {
        const {
            authHeader
        } = this.props;
        try {
            const isDeleteItem = await GatewayDocumentService
                .removeDocument(publicID, authHeader);
            if (isDeleteItem) {
                this.getDocuments();
                this.getDocumentsTileUpdatedCount();
            }
        } catch (documentDeletionError) {
            this.handleError(
                documentMessage.documentDeletionFailed,
                documentMessage.documentCannotDeleted
            );
        }
    })

    downloadDocument = (async (publicID, sessionID) => {
        const { authHeader, history } = this.props;
        const serviceUrl = getProxiedServiceUrl('downloadDocument');
        const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?token=${sessionID}`);

        const errorCallback = () => {
            history.push('/documenterror');
        };

        DocumentDownloadService.getDocument(
            templateDownloadEndpointURL,
            authHeader,
            errorCallback
        );
    })

    getMetadataImplementation = () => {
        const { documentsData, uploadSuccess } = this.state;
        const {
            fromAccountLanding: { quoteDetailsData: { loadPolicyChangeSummary } }
        } = this.props;
        const translator = this.context;
        const overrides = {
            policyDocuments: {
                initialDocumentsData: documentsData,
                uploadDocument: this.uploadDocument,
                deleteDocument: this.deleteDocument,
                downloadDocument: this.downloadDocument,
                noDataMessage: translator(messages.noPolicyDocuments),
                showUploadModal: !uploadSuccess,
                product: loadPolicyChangeSummary.policy.product.productName
            }
        };
        const resolvers = {
            resolveComponentMap: {
                documentscomponent: DocumentsComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }

    render() {
        const {
            fromAccountLanding: { quoteDetailsData: { loadPolicyChangeSummary } }
        } = this.props;
        const { documentsData } = this.state;
        const translator = this.context;
        const overrides = {
            endorsementDocuments: {
                initialDocumentsData: documentsData,
                uploadDocument: this.uploadDocument,
                deleteDocument: this.deleteDocument,
                downloadDocument: this.downloadDocument,
                noDataMessage: translator(messages.noEndorsementDocuments),
                product: loadPolicyChangeSummary.policy.product.productName
            }
        };
        const resolvers = {
            resolveComponentMap: {
                documentscomponent: DocumentsComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const EndorsementDocuments = withModalContext(EndorsementDocumentsWithoutModalContext);

export default withAuthenticationContext(withDependencies(['domainCompany'])(EndorsementDocuments));
