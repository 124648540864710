import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CAPolicyChangeStartPage from './pages/Start/CAPolicyChangeStartPage';

import CAPaySuccessChangePage from './pages/PaySuccess/CAPaySuccessChangePage';

import CAQualificationChangePage from './pages/Qualification/CAQualificationChangePage';
import CAQualificationChangeReadOnlyPage from './pages/Qualification/CAQualificationChangePage.readonly';

import CAPolicyDetailsChangePage from './pages/PolicyDetails/CAPolicyDetailsChangePage';
import CAPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/CAPolicyDetailsChangePage.readonly';

import CALocationsChangePage from './pages/Locations/CALocationsChangePage';
import CALocationsChangeReadOnlyPage from './pages/Locations/CALocationsChangePage.readonly';

import CAStateSpecificInformationChangePage from './pages/StateSpecificInformation/CAStateSpecificInformationChangePage';
import CAStateSpecificInformationChangeReadOnlyPage from './pages/StateSpecificInformation/CAStateSpecificInformationChangePage.readonly';

import CAVehiclesChangePage from './pages/Vehicles/CAVehiclesChangePage';
import CAVehiclesChangeReadOnlyPage from './pages/Vehicles/CAVehiclesChangePage.readonly';

import CADriversChangePage from './pages/Drivers/CADriversChangePage';
import CADriversChangeReadOnlyPage from './pages/Drivers/CADriversChangePage.readonly';

import CACoveragesChangePage from './pages/Coverages/CACoveragesChangePage';
import CACoveragesChangeReadOnlyPage from './pages/Coverages/CACoveragesChangePage.readonly';

import CACoveredVehicleSymbolsChangePage from './pages/CoveredVehicleSymbols/CACoveredVehicleSymbolsChangePage';
import CACoveredVehicleSymbolsChangeReadOnlyPage from './pages/CoveredVehicleSymbols/CACoveredVehicleSymbolsChangePage.readonly';

import CAModifiersChangePage from './pages/Modifiers/CAModifiersChangePage';
import CAModifiersChangeReadOnlyPage from './pages/Modifiers/CAModifiersChangePage.readonly';

import CAUnderwritingQuestionsChangePage from './pages/UnderwritingQuestions/CAUnderwritingQuestionsChangePage';
import CAUnderwritingQuestionsChangeReadOnlyPage from './pages/UnderwritingQuestions/CAUnderwritingQuestionsChangePage.readonly';

// WIZARD PAGE IMPORT ANCHOR

import CAQuoteChangePage from './pages/Quote/CAQuoteChangePage';
import CAQuoteChangeReadOnlyPage from './pages/Quote/CAQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        CAPolicyChangeStartPage: { component: 'CAPolicyChangeStartPage' },
        CAPaySuccessChangePage: { component: 'CAPaySuccessChangePage' },

        CAQualificationChangePage: { component: 'CAQualificationChangePage'},
        CAQualificationChangeReadOnlyPage: { component: 'CAQualificationChangeReadOnlyPage'},

        CAPolicyDetailsChangePage: { component: 'CAPolicyDetailsChangePage'},
        CAPolicyDetailsChangeReadOnlyPage: { component: 'CAPolicyDetailsChangeReadOnlyPage'},

        CALocationsChangePage: { component: 'CALocationsChangePage'},
        CALocationsChangeReadOnlyPage: { component: 'CALocationsChangeReadOnlyPage'},

        CAStateSpecificInformationChangePage: { component: 'CAStateSpecificInformationChangePage'},
        CAStateSpecificInformationChangeReadOnlyPage: { component: 'CAStateSpecificInformationChangeReadOnlyPage'},

        CAVehiclesChangePage: { component: 'CAVehiclesChangePage'},
        CAVehiclesChangeReadOnlyPage: { component: 'CAVehiclesChangeReadOnlyPage'},

        CADriversChangePage: { component: 'CADriversChangePage'},
        CADriversChangeReadOnlyPage: { component: 'CADriversChangeReadOnlyPage'},

        CACoveragesChangePage: { component: 'CACoveragesChangePage'},
        CACoveragesChangeReadOnlyPage: { component: 'CACoveragesChangeReadOnlyPage'},

        CACoveredVehicleSymbolsChangePage: { component: 'CACoveredVehicleSymbolsChangePage'},
        CACoveredVehicleSymbolsChangeReadOnlyPage: { component: 'CACoveredVehicleSymbolsChangeReadOnlyPage'},

        CAModifiersChangePage: { component: 'CAModifiersChangePage'},
        CAModifiersChangeReadOnlyPage: { component: 'CAModifiersChangeReadOnlyPage'},

        CAUnderwritingQuestionsChangePage: { component: 'CAUnderwritingQuestionsChangePage'},
        CAUnderwritingQuestionsChangeReadOnlyPage: { component: 'CAUnderwritingQuestionsChangeReadOnlyPage'},
		
// WIZARD PAGE COMPONENT NAME ANCHOR
        CAQuoteChangePage: { component: 'CAQuoteChangePage' },
        CAQuoteChangeReadOnlyPage: { component: 'CAQuoteChangeReadOnlyPage' },
    },
    {
        CAPolicyChangeStartPage,
        CAPaySuccessChangePage,

        CAQualificationChangePage,
        CAQualificationChangeReadOnlyPage,

        CAPolicyDetailsChangePage,
        CAPolicyDetailsChangeReadOnlyPage,

        CALocationsChangePage,
        CALocationsChangeReadOnlyPage,

        CAStateSpecificInformationChangePage,
        CAStateSpecificInformationChangeReadOnlyPage,

        CAVehiclesChangePage,
        CAVehiclesChangeReadOnlyPage,

        CADriversChangePage,
        CADriversChangeReadOnlyPage,

        CACoveragesChangePage,
        CACoveragesChangeReadOnlyPage,

        CACoveredVehicleSymbolsChangePage,
        CACoveredVehicleSymbolsChangeReadOnlyPage,

        CAModifiersChangePage,
        CAModifiersChangeReadOnlyPage,

        CAUnderwritingQuestionsChangePage,
        CAUnderwritingQuestionsChangeReadOnlyPage,

// WIZARD PAGE COMPONENT MAP ANCHOR
        CAQuoteChangePage,
        CAQuoteChangeReadOnlyPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as CAPolicyChangeWizard } from './CAPolicyChangeWizard';