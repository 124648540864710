import React, { useState, useEffect, useCallback } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import CoveragesConfigContext from './context/GLCoveragesConfigContext'
import GLCoveragesConfig from './GLCoverages.config';
import WizardPage from '../../templates/GLWizardPage';
import metadata from './GLGeneralLiabilityPage.metadata.json5';
import GLMultiSublinesClausesAdditionalInsured from './GLMultiSublinesClausesAdditionalInsured/GLMultiSublinesClausesAdditionalInsured';
import GLMultiSublinesClausesExclusionsAndConditions from './GLMultiSublinesClausesExclusionsAndConditions/GLMultiSublinesClausesExclusionsAndConditions'
import GLMultiSublinesClausesAdditionalCoverages from './GLMultiSublinesClausesAdditionalCoverages/GLMultiSublinesClausesAdditionalCoverages';
import GLMultiSublinesClausesDetailsAndStandardCoverages from './GLMultiSublinesClausesDetailsAndStandardCoverages/GLMultiSublinesClausesDetailsAndStandardCoverages';



function GLGeneralLiabilityPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            jobType
        }
    } = submissionVM.value

    const isPolicyChange = jobType === 'PolicyChange'
    
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const { onValidate } = useValidation('GLGeneralLiabilityPage');
    const { authHeader } = useAuthentication();
    const [isEditing, setIsEditing] = useState(false)
    const [allSublinesClauses, setAllSubLinesClauses] = useState()

    const initAllSublinesClauses = useCallback(async () => {
        setLoadingMask(true)
        const res = await GLCoverageService.getSublineCoverages(jobID, sessionUUID, authHeader)
        const { sublineClauses } = res
        setAllSubLinesClauses(sublineClauses)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 


    useEffect(() => {
        initAllSublinesClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mutiSublineClausesProps = {
        allSublinesClauses: allSublinesClauses,
        submissionVM: submissionVM,
        updateWizardData: updateWizardData,
        setAllSubLinesClauses: setAllSubLinesClauses,
        isEditing,
        setIsEditing,
        onValidate,
        showErrors: false,
        isEditable: false,
    }

    const overrideProps = {
        '@field': {
            isEditable: false,
        },
        GLMultiSublinesClausesDetailsAndStandardCoverages: {
            ...mutiSublineClausesProps,
            visible: !isPolicyChange,
        },
        GLMultiSublinesClausesAdditionalCoverages: {
            ...mutiSublineClausesProps,
        },
        GLMultiSublinesClausesExclusionsAndConditions: {
            ...mutiSublineClausesProps,
        },
        GLMultiSublinesClausesAdditionalInsured: {
            ...mutiSublineClausesProps,
        },
    };

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            GLMultiSublinesClausesDetailsAndStandardCoverages: GLMultiSublinesClausesDetailsAndStandardCoverages,
            GLMultiSublinesClausesAdditionalCoverages: GLMultiSublinesClausesAdditionalCoverages,
            GLMultiSublinesClausesExclusionsAndConditions: GLMultiSublinesClausesExclusionsAndConditions,
            GLMultiSublinesClausesAdditionalInsured: GLMultiSublinesClausesAdditionalInsured,
        },
    };

    return (
        <WizardPage
            showRequiredInfoForFasterQuote
        >
            {allSublinesClauses && 
                <CoveragesConfigContext.Provider value={GLCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={false}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
                
            }
        </WizardPage>
    );
}

export default GLGeneralLiabilityPage;