import React, {
    useState,
    useContext,
}from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
// import { NewContactUtil } from 'gwce-capability-fnol-ho-react-ext'
import metadata from './SinglePedestrian.metadata.json5'
import messages from './SinglePedestrian.message'
import { createNewPersonWithoutPublicID } from '../../util/NewContactUtil';

import { Button } from '@jutro/legacy/components';

const AddNewPerson = 'AddNewPerson'

const SinglePedestrian = (props) => {
    const {
        originPedestrian,
        editingPedestrianIndex,
        onPopupCancel,
        onPopupSave,
        relatedPersonContacts,
        filteredRelatedContacts
    } = props

    const translator = useTranslator()

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SinglePedestrianPopup')

    const [showErrors, setShowErrors] = useState(false)
    const [person, setPerson] = useState(() => {
        return relatedPersonContacts.find((contact) => contact.publicID === originPedestrian.personPublicID)
    })


    const [pedestrianVM, setPedestrianVM] = useState(viewModelService.create(
        originPedestrian,
        'cc',
        'wni.edge.capabilities.claim.lob.impl.personalauto.dto.WniPedestrianDTO'
    ));

    const pedestrianPersonPublicID = _.get(pedestrianVM.value, 'personPublicID')

    const writeValue = (value, path) => {
        const newPedestrianVM = _.clone(pedestrianVM);
        _.set(newPedestrianVM.value, path, value);
        setPedestrianVM(newPedestrianVM);
    }

    const contactPersonSelectorAvailableValues = () => {
        const contactPersonAvailableValues = filteredRelatedContacts.map((contact) => {
            return {
                code: _.get(contact, 'publicID'),
                name: `${_.get(contact, 'firstName', '-')} ${_.get(contact, 'lastName', '-')}`
            }
        }).concat([{code: AddNewPerson, name: translator(messages.addNewPerson)}]);
        if(editingPedestrianIndex !== undefined) {
            const editingPedestrian = relatedPersonContacts.find((contact) => contact.publicID === originPedestrian.personPublicID)
            contactPersonAvailableValues.unshift({
                code: _.get(editingPedestrian, 'publicID'),
                name: `${_.get(editingPedestrian, 'firstName', '-')} ${_.get(editingPedestrian, 'lastName', '-')}`
            })
        }

        return contactPersonAvailableValues;
    }

    const onPedestrianSelectorChange = (value) => {
        if (value === pedestrianPersonPublicID) {
            return
        }
        const newPedestrianVM = _.clone(pedestrianVM);
        
        if (value === AddNewPerson) {
            _.set(newPedestrianVM.value, 'personPublicID', AddNewPerson);
            setPerson(createNewPersonWithoutPublicID())
        } else {
            _.set(newPedestrianVM.value, 'personPublicID', value);
            setPerson(relatedPersonContacts.find((contact) => contact.publicID === value))
            
        }
        
        setPedestrianVM(newPedestrianVM);
    }

    
    const onPedestrianPersonChange = (value, path) => {
        const updatedPerson = {
            ...person,
            [path]: value,
            updated_Ext: true,
        }
        setPerson(updatedPerson)
    }

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        
        onPopupSave(pedestrianVM.value, editingPedestrianIndex, person)
    }

    const overrideProps = {
        contactPerson: {
            availableValues: contactPersonSelectorAvailableValues(),
            value: pedestrianPersonPublicID,
            onValueChange: onPedestrianSelectorChange
        },
        pedestrianFirstName: {
            value: _.get(person, 'firstName'),
            onValueChange: (value) => onPedestrianPersonChange(value, 'firstName'),
            showRequired: true,
            visible: !!pedestrianPersonPublicID
        },
        pedestrianLastName: {
            value: _.get(person, 'lastName'),
            onValueChange: (value) => onPedestrianPersonChange(value, 'lastName'),
            visible: !!pedestrianPersonPublicID
        },
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={pedestrianVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div className='d-flex flex-direction-row-reverse'>
            <Button
                type="filled"
                onClick={onSaveClicked}
            >{translator(messages.save)}</Button>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
            >{translator(messages.cancel)}</Button>
        </div>
        
    </>
}

export default SinglePedestrian
