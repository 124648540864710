import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SearchService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { withRouter } from 'react-router-dom';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './SearchResults.metadata.json5';

import styles from './SearchResults.module.scss';
import messages from './SearchResults.messages';

function SearchResults(props) {
    const {
        match: {
            params: { searchText }
        },
        param,
        title,
        noResultsMessage
    } = props;
    const translator = useTranslator();
    const [searchResult, setSearchResult] = useState({
        accounts: [],
        policies: []
    });
    const [prevParam, setPrevParam] = useState('');
    const [loading, setLoading] = useState(true);

    const { authHeader } = useAuthentication();

    const populateAccountResults = useCallback((data) => {
        const accounts = [];
        if (data.accounts.length) {
            data.accounts.forEach((account) => {
                if (account !== null) {
                    const accountInfo = {};
                    accountInfo.accountNumber = account.accountNumber;
                    accountInfo.accountName = account.accountHolder;
                    const {
                        addressLine1, city, state, postalCode
                    } = account.accountHolderAddress;
                    const addressLine2Data = account.accountHolderAddress.addressLine2
                        ? account.accountHolderAddress.addressLine2
                        : '';

                    accountInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
                    accountInfo.addressOthers = `${city}, ${state}${postalCode}`;
                    accounts.push(accountInfo);
                }
            });
        }
        return accounts;
    }, []);

    const populatePolicyResults = useCallback((data) => {
        const policies = [];
        if (data.policies.length) {
            data.policies.forEach((policy) => {
                if (policy.policyNumber) {
                    const policyInfo = {};

                    policyInfo.policyNumber = policy.policyNumber;
                    policyInfo.accountNumber = policy.accountNumber;
                    if (policy.accountHolder.subtype === 'Company') {
                        policyInfo.accountName = policy.accountHolder.contactName;
                    } else if (policy.accountHolder.subtype === 'Person') {
                        policyInfo.accountName = `${policy.accountHolder.firstName} ${policy.accountHolder.lastName}`;
                    }
                    const {
                        addressLine1,
                        city,
                        state,
                        postalCode
                    } = policy.accountHolder.primaryAddress;
                    const addressLine2Data = policy.accountHolder.primaryAddress.addressLine2
                        ? policy.accountHolder.primaryAddress.addressLine2
                        : '';
                    policyInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
                    policyInfo.addressOthers = `${city}, ${state}${postalCode}`;
                    policies.push(policyInfo);
                }
            });
        }
        return policies;
    }, []);

    useEffect(() => {
        const searchParam = param || searchText;
        const fetchData = async () => {
            setLoading(true);
            setPrevParam(searchParam);
            const searchObj = {
                previousSearchParam: '',
                searchParam
            };
            const data = await SearchService.search(searchObj, authHeader);
            setLoading(false);
            setSearchResult(() => {
                return {
                    accounts: populateAccountResults(data),
                    policies: populatePolicyResults(data)
                };
            });
        };
        if (prevParam !== searchParam) {
            fetchData();
        }
    }, [searchText, populateAccountResults, populatePolicyResults, authHeader, param, prevParam]);

    const accountDetailsOverrides = useMemo(() => {
        const overrides = searchResult.accounts.map((account, index) => {
            return {
                [`accountAccountNameLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: `${account.accountName}`
                },
                [`accountNumberLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: `# ${account.accountNumber}`
                },
                [`accountAddressLine${index}`]: {
                    content: `${account.addressLine}`
                },
                [`accountAddressOthers${index}`]: {
                    content: `${account.addressOthers}`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [searchResult.accounts]);

    const policyDetailsOverrides = useMemo(() => {
        const overrides = searchResult.policies.map((policy, index) => {
            return {
                [`policyNumberLink${index}`]: {
                    to: `/policies/${policy.policyNumber}/summary`
                },
                [`policyNumber${index}`]: {
                    content: `# ${policy.policyNumber}`
                },
                [`accountName${index}`]: {
                    content: `${policy.accountName}`
                },
                [`policyAccountNameLink${index}`]: {
                    to: `/accounts/${policy.accountNumber}/summary`
                },
                [`policyAddressLine${index}`]: {
                    content: `${policy.addressLine}`
                },
                [`policyAddressOthers${index}`]: {
                    content: `${policy.addressOthers}`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [searchResult.policies]);

    const overrideProps = {
        accountResults: {
            visible: searchResult.accounts.length > 0 && !loading
        },
        searchResultsTitle: {
            content: title || translator(messages.searchResults)
        },
        policyResults: {
            visible: searchResult.policies.length > 0 && !loading
        },
        searchingText: {
            visible: loading
        },
        loadingIndicator: {
            loaded: !loading
        },
        noResultsMessage: {
            visible:
                (!!searchText || param)
                && !loading
                && !searchResult.accounts.length
                && !searchResult.policies.length,
            content:
                noResultsMessage
                || translator(messages.noResultsFoundFor, {
                    query: searchText || param
                })
        },
        accountHeader: {
            content: translator(
                messages.accountResults,
                { accountResultsNumber: searchResult.accounts.length }
            )
        },
        policyHeader: {
            content: translator(
                messages.policyResults,
                { policyResultsNumber: searchResult.policies.length }
            )
        },
        ...accountDetailsOverrides,
        ...policyDetailsOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, searchResult, id, path, overrideProps);
        },
        [overrideProps, searchResult]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={searchResult}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

SearchResults.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            searchText: PropTypes.string
        })
    }).isRequired,
    param: PropTypes.string,
    title: PropTypes.string,
    noResultsMessage: PropTypes.string
};

SearchResults.defaultProps = {
    param: '',
    title: '',
    noResultsMessage: ''
};

export default withRouter(SearchResults);
