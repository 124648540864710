import React, { useCallback, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CAVehicleService } from 'wni-capability-quoteandbind-ca';
import metadata from './PriceDigestModal.metadata.json5';

import { Button } from '@jutro/legacy/components';

function PriceDigestModal(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        title,
        data,
        publicID,
        vehicleType,
        intl,
        renderDropDownCell = _.noop,
        extendProps: { jobID, sessionUUID, authHeader },
        useDependenciesData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;
    const isShowErrorColumn = data.find((item) => item.errorCode);

    const onSelect = async (item, index) => {
        setLoadingMask(true);
        const paramsData = {
            publicID,
            vehicleType,
            configurationId: item.configurationId,
        };
        const res = await CAVehicleService.handleConfigurationIdForPriceDigest(
            jobID,
            sessionUUID,
            paramsData,
            authHeader
        );
        setLoadingMask(false);
        onResolve(res);
    };

    const renderSortNumber = (item, index) => {
        return `${index + 1}.`;
    };
    const renderSelectColumn = (item, index) => {
        if (item.errorCode) {
            return null;
        }
        return (
            <Button className="btn-link" onClick={() => onSelect(item, index)}>
                Select
            </Button>
        );
    };
    const renderOrigionalCell = (item, index, { path }) => {
        if (!item[path]) {
            return '-';
        }
        return intl.formatNumber(item[path], {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol',
        });
    };
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        priceDigestTable: {
            data: data,
        },
        errorMessageColumn: {
            visible: !!isShowErrorColumn,
        },
    };
    const resolvers = {
        resolveCallbackMap: {
            renderSortNumber,
            renderSelectColumn,
            renderOrigionalCell,
            renderDropDownCell,
        },
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="priceDigest">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            {!!isShowErrorColumn && <ModalFooter><Button onClick={onReject}>Close</Button></ModalFooter>}
        </ModalNext>
    );
}

PriceDigestModal.propTypes = {};
PriceDigestModal.defaultProps = {
    size: 'lg',
};
export default PriceDigestModal;
