import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChangeStarted: {
        id: 'gateway.views.endorsement-detail.This policy change has been successfully started.',
        defaultMessage: 'This policy change has been successfully started.'
    },
    withdrawPolicyChange: {
        id: 'gateway.views.endorsement-detail.Withdraw Policy Change',
        defaultMessage: 'Withdraw Policy Change'
    },
    continuePolicyChange: {
        id: 'gateway.views.endorsement-detail.Continue Policy Change',
        defaultMessage: 'Continue Policy Change'
    },
    policyChangePremiusCalculated: {
        id: 'gateway.views.endorsement-detail.The premium for this policy change has been calculated',
        defaultMessage: 'The premium for this policy change has been calculated'
    },
    mayWithDrawPolicyChange: {
        id: 'gateway.views.endorsement-detail.You may withdraw the policy change',
        defaultMessage: 'You may withdraw the policy change'
    },
    policyWithdrawn: {
        id: 'gateway.views.endorsement-detail.This policy change has been withdrawn',
        defaultMessage: 'This policy change has been withdrawn'
    },
    policyChangeBound: {
        id: 'gateway.views.endorsement-detail.This policy change has been bound',
        defaultMessage: 'This policy change has been bound'
    },
    mayWithdrawOrContinuePolicyChangeWizard: {
        id: 'gateway.views.endorsement-detail.You may either withdraw or continue to the policy change wizard',
        defaultMessage: 'You may either withdraw or continue to the policy change wizard'
    },
    mayWithdrawOrContinue: {
        id: 'gateway.views.endorsement-detail.You may either withdraw this change or continue to the policy change wizard.',
        defaultMessage: 'You may either withdraw this change or continue to the policy change wizard.'
    },
    mayWithdrawPolicyChange: {
        id: 'gateway.views.endorsement-detail.You withdraw the policy change',
        defaultMessage: 'You withdraw the policy change'
    },
    lastDays: {
        id: 'gateway.views.endorsement-detail.last30Days',
        defaultMessage: 'Issued in Last 30 Days'
    },
    openBound: {
        id: 'gateway.views.endorsement-detail.Open - Bound',
        defaultMessage: 'Open - Bound'
    },
    openNotBound: {
        id: 'gateway.views.endorsement-detail.Open - Not Bound',
        defaultMessage: 'Open - Not Bound'
    },
    errorLoadingDocuments: {
        id: 'gateway.views.endorsement-documents.Error loading documents.',
        defaultMessage: 'Error loading documents.'
    },
    status: {
        id: 'gateway.views.endorsement-documents.Status',
        defaultMessage: 'Status'
    },
    underwritingIssues: {
        id: 'gateway.views.endorsement-documents.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    errorLoadingNotes: {
        id: 'gateway.views.endorsement-notes.Error loading notes.',
        defaultMessage: 'Error loading notes.'
    },
    sureWithdrawPolicyChange: {
        id: 'gateway.views.endorsement.Are you sure you want to withdraw this policy change?',
        defaultMessage: 'Are you sure you want to withdraw this policy change?'
    },
    withDrawPolicyChange: {
        id: 'gateway.views.endorsement.Withdraw Policy Change??',
        defaultMessage: 'Withdraw Policy Change?'
    },
    cancel: {
        id: 'gateway.views.endorsement.Cancel',
        defaultMessage: 'Cancel'
    },
    failedtoSavePolicychange: {
        id: 'gateway.views.endorsement.Failed to Save Policy Change',
        defaultMessage: 'Failed to Save Policy Change'
    },
    saveAndExit: {
        id: 'gateway.views.endorsement.Save and Exit',
        defaultMessage: 'Save and Exit'
    },
    quote: {
        id: 'gateway.views.endorsement.Quote',
        defaultMessage: 'Quote'
    },
    savingPolicyChange: {
        id: 'gateway.views.endorsement.Saving Policy Change',
        defaultMessage: 'Saving Policy Change'
    },
    unableWithdrawPolicyChange: {
        id: 'gateway.views.endorsement.Unable to withdraw this policy change',
        defaultMessage: 'Unable to withdraw this policy change'
    },
    withdraw: {
        id: 'gateway.views.endorsement.Withdraw',
        defaultMessage: 'Withdraw'
    },
    withdrawingPolicyChange: {
        id: 'gateway.views.endorsement.Withdrawing Policy Change',
        defaultMessage: 'Withdrawing Policy Change'
    },
    failedToWithdrawPolicyChange: {
        id: 'gateway.views.endorsement-detail.Failed to withdraw policy change',
        defaultMessage: 'Failed to withdraw policy change'
    },
    error: {
        id: 'gateway.directives.JobSummary.Error',
        defaultMessage: 'Error'
    },
    details: {
        id: 'gateway.directives.JobSummary.Error.Details',
        defaultMessage: 'Error loading quote information.'
    }
});
