import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'modal.button.pu.label.editResidents.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'modal.button.pu.label.editResidents.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'modal.button.pu.label.editResidents.Save',
        defaultMessage: 'Save & Continue'
    },
    next: {
        id: 'modal.button.pu.label.editResidents.Add',
        defaultMessage: 'Save & Next HouseHold Resident'
    }

});
