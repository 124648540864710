
import { defineMessages } from 'react-intl';

export default defineMessages({
    phoneNumber: {
        id: 'quoteandbind.bop.views.additional-information.Phone Number',
        defaultMessage: 'Phone Number',
    },
    infoText: {
        id: 'quoteandbind.bop.views.additional-information.You may provide additional information on this page before you complete the quote.',
        defaultMessage: 'You may provide additional information on this page before you complete the quote.',
    }
});
