import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CovPaidComponent.metadata.json5';
import { Button } from '@jutro/legacy/components';

function CovPaidComponent(props) {
    const {
        title,
        publicId,
        quotedId,
        comingType,
        modalVM,
        updatePaidCovModel,
        updateSecondWinVisible,
        updateNewPaidCovRecords,
        wniClueService,
        useAuthenticationData,
        useDependenciesData,
    } = props;

    const { authHeader } = useAuthenticationData;

    const { loadingMask: { setLoadingMask } } = useDependenciesData;

    const handleOtherThanColValue = useCallback((value, path) => {
        updatePaidCovModel(value, path);
    }, []);

    const onCancel = () => {
        updateSecondWinVisible(false);
    };

    const handleSave = async () => {
        setLoadingMask(true);
        const response = await wniClueService.updateOtherThanCollisionById(comingType, quotedId, publicId, modalVM.value, authHeader);
        if (response) {
            updateNewPaidCovRecords(response);
            updateSecondWinVisible(false);
        }
        setLoadingMask(false);
    };

    const overrideProps = {
        otherThanCol: {
            // eslint-disable-next-line react/prop-types
            data: modalVM.covOtherThanCollisionDetails.aspects.availableValues,
            onValueChange: handleOtherThanColValue
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            modalVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        }
    };
    return (
        <div>
            <ViewModelForm
                model={modalVM}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
            />
            <div className="align-right mt-20">
                <Button onClick={onCancel} type="outlined">Cancel</Button>
                <Button onClick={handleSave} type="filled">Save</Button>
            </div>
        </div>
    );
}

CovPaidComponent.propTypes = {
    title: PropTypes.string,
    publicId: PropTypes.string,
    quotedId: PropTypes.string,
    comingType: PropTypes.string,
    modalVM: PropTypes.shape({
        value: PropTypes.shape({})
    }).isRequired,
    updatePaidCovModel: PropTypes.func.isRequired,
    updateSecondWinVisible: PropTypes.func.isRequired,
    updateNewPaidCovRecords: PropTypes.func.isRequired,
    wniClueService: PropTypes.func.isRequired
};
CovPaidComponent.defaultProps = {
    title: 'Coverage Paid Table',
    publicId: '',
    quotedId: '',
    comingType: ''
};

export default CovPaidComponent;
