import { defineMessages } from 'react-intl';

export default defineMessages({
    editAdditionalNamedInsured: {
        id: 'wni.quoteandbind.dp-wizard.dp-householdinfo.Edit Additional Named Insured',
        defaultMessage: 'Edit Additional Named Insured'
    },
    addAdditionalNamedInsured: {
        id: 'wni.quoteandbind.dp-wizard.dp-householdinfo.Add Additional Named Insured',
        defaultMessage: 'Add Additional Named Insured'
    },
    deleteAdditionalNamedInsuredTitle: {
        id: 'wni.quoteandbind.dp-wizard.dp-householdinfo.Delete Additional Named Insured Title',
        defaultMessage: 'Remove Additional Named Insured?'
    },
    deleteAdditionalNamedInsuredMessage: {
        id: 'wni.quoteandbind.dp-wizard.dp-householdinfo.Delete Additional Named Insured Message',
        defaultMessage: 'Are you sure you want to remove this Additional Named Insured?'
    },
    editResidenceLocation: {
        id: 'wni.quoteandbind.dp-wizard.dp-householdinfo.Edit Residence Location',
        defaultMessage: 'Edit Residence Location'
    },
    residenceQuestionDropdown: {
        id: "wni.quoteandbind.dp-householdinfo.Residence Dropdown",
        defaultMessage: "Is this dwelling under new construction or major structural renovation?"
    },
    generalContractorToggle: {
        id: "wni.quoteandbind.dp-householdinfo.General Contractor Toggle",
        defaultMessage: "Is the named insured the General Contractor?"
    },
    constructionToggle: {
        id: "wni.quoteandbind.dp-householdinfo.construction Toggle",
        defaultMessage: "Has construction beyond the foundation level occurred?"
    },
    estimatedCompletionDateDropdown: {
        id: "wni.quoteandbind.dp-householdinfo.estimated completion date",
        defaultMessage: "What is the estimated completion date of the project?"
    },
    isThisResidenceOccupiedSeasonallyToggle: {
        id: "wni.quoteandbind.dp-householdinfo.is this residence occupied seasonally",
        defaultMessage: "Is this residence occupied seasonally?"
    },
    totalNumOfHouseholdResidentsTooltip: {
        id: "wni.quoteandbind.dp-householdinfo.Enter the number of residents. Please list all residents of the household residents below, including children.",
        defaultMessage: "Enter the number of residents. Please list all residents of the household residents below, including children."
    },
    uWQApplicantExistPolicyToggle: {
        id: "wni.quoteandbind.dp.policyDetails.UWQ Select All Applies MultiSelect ",
        defaultMessage: "Does the applicant(s) have an existing Western National policy or will a Western National policy be issued with an effective date within the next 60 days?"
    },
    uWQSelectAllAppliesMultiSelect: {
        id: "wni.quoteandbind.dp.policyDetails.UWQ Prodoct",
        defaultMessage: "Product"
    },
    isDpThereExistingPolicy: {
        id: 'wni.questions.isDpThereExistingPolicy_Ext.',
        defaultMessage: 'Does the applicant(s) have an existing {companyName} Home or Auto policy or will a {companyName} policy be issued effective within 60 days of the Dwelling policy?'
    },
    explain: {
        id: 'wni.quoteandbind.dp-householdinfo.please explain',
        defaultMessage: 'Please Explain'
    },
    uWQIsThisAccountPartOfABookRoll: {
        id: "wni.quoteandbind.dp-householdinfo.UWQ Is this account part of a book roll?",
        defaultMessage: "Is this account part of a book roll?"
    },
    insuredLivedLocationToggle: {
        id: "wni.quoteandbind.dp-householdinfo.insured lived location",
        defaultMessage: "Has the insured lived at this location less than 3 years?"
    },
    effectiveDateValidation: {
        id: 'wni.quoteandbind.dp.householdinfo.golive.effectiveDate',
        defaultMessage: 'The selected effective date is before the product has become available. Please select an effective date after {productEffectiveDate}'
    },
});
