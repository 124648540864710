import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'quoteandbind.ho.views.hoCoverageDetailsPopup.button.Save',
        defaultMessage: 'Save'
    },
    cancel: {
        id: 'quoteandbind.ho.views.hoCoverageDetailsPopup.button.Cancel',
        defaultMessage: 'Cancel'
    },
    close: {
        id: 'quoteandbind.ho.views.hoCoverageDetailsPopup.button.Close',
        defaultMessage: 'Close'
    }
});