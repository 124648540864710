import React, { useContext } from 'react';
// ref: https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
import hoistNonReactStatic from 'hoist-non-react-statics';

const wizardErrorContext = React.createContext(null);

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
export function withWizardErrorContext(WrappedComponent) {
    function WithWizardErrorContext(props) {
        const currentContext = useContext(wizardErrorContext);
        return <WrappedComponent {...currentContext} {...props} />;
    }
    hoistNonReactStatic(WithWizardErrorContext, WrappedComponent);
    WithWizardErrorContext.displayName = `WithWizardErrorContext(${getDisplayName(WrappedComponent)})`;
    return WithWizardErrorContext;
};

export const WizardErrorContext = wizardErrorContext;
