import React, {useState, useCallback, useEffect} from 'react'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CPCoverageService } from 'wni-capability-quoteandbind-cp';
import CoveragesConfigContext from './context/CPCoveragesConfigContext'
import CPCoveragesConfig from './CPCoverages.config';
import metadata from './CommercialPropertyPage.metadata.json5';

import WizardPage from '../../templates/CPWizardPage';
import CPDetailsAccordionCard from './CPDetailsAccordionCard/CPDetailsAccordionCard';
import CPCoveragesAccordionCard from './CPCoveragesAccordionCard/CPCoveragesAccordionCard';
import CPCoveragesAdditionalCoveragesAccordionCard from './CPAdditionalCoveragesAccordionCard/CPAdditionalCoveragesAccordionCard'
import CPExclusionsAndConditionsAccordionCard from './CPExclusionsAndConditionsAccordion/CPExclusionsAndConditionsAccordionCard'

const CommercialPropertyPage = (props) => {

    const {
        wizardData: submissionVM,
    } = props;

    const { initialValidation } = useValidation('CommercialPropertyPage');
    const { authHeader } = useAuthentication();
    const [lineClauses, setLineClauses] = useState()
    const [loadingClauses, setLoadingClauses] = useState(false);

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');


    const initLineClauses = useCallback(async () => {
        setLoadingMask(true)
        const res = await CPCoverageService.getLineClauses(jobID, sessionUUID, authHeader)
        setLineClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initLineClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onPageNext = async () => {
        return submissionVM
    }

    const accordionCommonProps = {
        lineClauses,
        setLineClauses,
        isEditing: false,
        isEditable: false,
        showErrors: false,
        loadingClauses,
        setLoadingClauses,

        wizardData: submissionVM,
    }

    const overrideProps = {
        '@field': {
            isEditable: false,
        },
        CPDetailsAccordionCard: {
            ...accordionCommonProps
        },
        CPCoveragesAccordionCard: {
            ...accordionCommonProps
        },
        CPCoveragesAdditionalCoveragesAccordionCard: {
            ...accordionCommonProps
        },
        CPExclusionsAndConditionsAccordionCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            CPDetailsAccordionCard: CPDetailsAccordionCard,
            CPCoveragesAccordionCard: CPCoveragesAccordionCard,
            CPCoveragesAdditionalCoveragesAccordionCard: CPCoveragesAdditionalCoveragesAccordionCard,
            CPExclusionsAndConditionsAccordionCard: CPExclusionsAndConditionsAccordionCard
        },
    };


    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            showRequiredInfoForFasterQuote
        >
            {lineClauses && 
                <CoveragesConfigContext.Provider value={CPCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default CommercialPropertyPage
