import React, {
    useCallback,
    useContext,
    useState,
} from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    useTranslator,
    IntlContext,
} from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import RTCoverageUtil from '../../util/RTCoverageUtil';
import RTVehicleCoveragesModal from '../../components/RTVehicleCoveragesModal/RTVehicleCoveragesModal'
import CopyRTVehicleCoverageModal from "../../components/CopyRTVehicleCoverageModal/CopyRTVehicleCoverageModal";
import metadata from './RTVehiclesCoverages.metadata.json5';
import styles from './RTVehiclesCoverages.module.scss';
import messages from './RTVehiclesCoverages.messages';
import CoveragesConfigContext from "../../context/RTCoveragesConfigContext";
import { Button } from '@jutro/legacy/components';

const OtherThanCollisionCoverageCode = 'RTOtherThanCollision_Ext'
const CollisionCoverageCode = 'RTCollision_Ext'
const TowingCoverageCode = 'RTTowingAndLaborCostsCoverage_Ext'

const DeductibleOfOtherThanCollisionTermCode = 'RTOtherThanCollisionDeductible_Ext'
const DeductibleOfCollisionTermCode = 'RTCollisionDeductible_Ext'
const LimitOfTowingTermCode = 'RTTowingAndLaborCostsCoverageLimit_Ext'


function RTVehiclesCoverages(props) {

    const {
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        updateWizardData,
        updateErrorsAndWarnings,
        lobName,
        coveragesService,
        isEditable
    } = props;

    const translator = useTranslator();
    const {coverageAvailable} = useContext(CoveragesConfigContext);
    const intl = useContext(IntlContext);
    const { authHeader } = useAuthentication();

    const {
        jobID,
        sessionUUID,
        lobData: {
            [lobName]: {
                coverables: {
                    vehicles = []
                } = {}
            } ={}
        } = {}
    } = submissionVM.value;

    const [vehicleCoveragesDetailsModalstatus, setVehicleCoveragesDetailsModalStatus] = useState({
        isOpen: false,
        vehicleID: undefined,
        title: undefined,
    })


    const [copyRoadTrailCoverageModalStatus, setCopyRoadTrailCoverageModalStatus] = useState({
        isOpen: false,
        triggerCopyCoverageRoadTrailPublicID: undefined
    });

    const rtVehiclescoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.vehiclesCoverages`;

    const rtVehiclesCoverages = _.get(submissionVM.value, rtVehiclescoveragePath, [])

    const getRTVehicleCoverages = (vehPublicID) => {
        const rtVehCoverage = rtVehiclesCoverages
            .find((rtVehCoverageDTO) => _.get(rtVehCoverageDTO, 'owningCoverablePublicID') === vehPublicID);
        return _.get(rtVehCoverage, 'coverages', []);
    }

    const renderVehicleDescriptionCell = (vehicle) => {
        return RTCoverageUtil.getVehicleDescription(vehicle)
    }

    const renderCellByRTVehicleAndCovAndTerm = (vehicle, covCode, termCode) => {
        const vehPublicID = _.get(vehicle, 'publicID');
        const vehCoverages = getRTVehicleCoverages(vehPublicID)
        if (_.isEmpty(vehCoverages)) {
            return translator(messages.RTRoadAndTailCoveragesTableNO)
        }

        const coverage = _.find(vehCoverages, (cov) => _.get(cov, 'code_Ext') === covCode)
        if (_.isEmpty(coverage)){
            return translator(messages.RTRoadAndTailCoveragesTableNO)
        }
        const terms = _.get(coverage, 'terms', [])
        const term = _.find(terms, (t) => _.get(t, 'code_Ext') === termCode)
        if (_.isEmpty(term)){
            return translator(messages.RTRoadAndTailCoveragesTableNO)
        }
        const chosenTerm = _.get(term, 'chosenTerm')
        const options = _.get(term, 'options', [])
        const selectedOption = _.find(options, (option) => _.get(option, 'code') === chosenTerm)
        if (_.isEmpty(selectedOption)){
            return translator(messages.RTRoadAndTailCoveragesTableNO)
        }
        return _.get(selectedOption, 'name');
    }

    const renderVehicleOtherThanCollision = (vehicle) => {
        if (!vehicle.isPhysicalDamageRequired) {
            return null;
        }
        return renderCellByRTVehicleAndCovAndTerm(
            vehicle,
            OtherThanCollisionCoverageCode,
            DeductibleOfOtherThanCollisionTermCode
        );
    }

    const renderVehicleCollision = (vehicle) => {
        if (!vehicle.isPhysicalDamageRequired) {
            return null;
        }
        return renderCellByRTVehicleAndCovAndTerm(
            vehicle,
            CollisionCoverageCode,
            DeductibleOfCollisionTermCode
        );
    }

    const renderRoadsideTowing = (vehicle) => {
        if (!vehicle.isPhysicalDamageRequired) {
            return null;
        }
        return renderCellByRTVehicleAndCovAndTerm(
            vehicle,
            TowingCoverageCode,
            LimitOfTowingTermCode
        );
    }

    const renderVehicleOther = (vehicle) => {
        const vehPublicID = _.get(vehicle, 'publicID');
        const vehCoverages = getRTVehicleCoverages(vehPublicID)
        if (!vehicle.isPhysicalDamageRequired) {
            return null;
        }
        if (_.isEmpty(vehCoverages)) {
            return translator(messages.RTRoadAndTailCoveragesTableNO)
        }
        const hasOtherSelectedCoverages = vehCoverages
            .filter((clause) => {
                const clauseCode = clause.code_Ext;
                if (!coverageAvailable.hasOwnProperty(clauseCode)) {
                    return true
                }
                return coverageAvailable[clauseCode]()
            })
            .some((cov) => {
                const covCode = _.get(cov, 'code_Ext')
                const selected = _.get(cov, 'selected')
                if (!selected) {
                    return false
                }
                if ([
                    OtherThanCollisionCoverageCode,
                    CollisionCoverageCode,
                    TowingCoverageCode
                ].includes(covCode)) {
                    return false
                }
                return true
            })
        if (!hasOtherSelectedCoverages) {
            return translator(messages.RTRoadAndTailCoveragesTableNO)
        }
        return translator(messages.RTRoadAndTailCoveragesTableYES)
    }

    const onOpenWatercraftCoveragesDetailsModal = (vehicle) => {
        const rtVehiclePublicID = _.get(vehicle, 'publicID');
        setVehicleCoveragesDetailsModalStatus({
            isOpen: true,
            vehicleID: rtVehiclePublicID,
            title: RTCoverageUtil.getVehicleDescription(vehicle),
        })
    }

    const renderVehicleAction = (vehicle) => {
        if (vehicle.isPhysicalDamageRequired) {
            return <Button className="wni-button-link" onClick={() => {onOpenWatercraftCoveragesDetailsModal(vehicle)}}>
                    {translator(messages.RTRoadAndTailCoveragesTableViewEditAddCoverage)}
            </Button>;
        }
        return translator(messages.RTRoadAndTailCoveragesLiabilityOnly);
    }

    const onResolveRTVehCoveragesDetailsModal = () => {
        setVehicleCoveragesDetailsModalStatus({
            isOpen: false
        })
    }

    const onResolveCopyRoadTrailCoverageModal = (backToRoadTrailPublicID) => {
        setCopyRoadTrailCoverageModalStatus({
            ...copyRoadTrailCoverageModalStatus,
            isOpen: false
        });
        if (backToRoadTrailPublicID) {
            setVehicleCoveragesDetailsModalStatus({
                ...vehicleCoveragesDetailsModalstatus,
                isOpen: true,
                vehicleID: backToRoadTrailPublicID,
            });
        }
    };
    const onRejectCopyRoadTrailCoverageModal = () => {
        setCopyRoadTrailCoverageModalStatus({
            ...copyRoadTrailCoverageModalStatus,
            isOpen: false
        });
    };
    const openCopyCoveragesModal = (roadTrailPublicID = null) => {
        setVehicleCoveragesDetailsModalStatus({
            ...vehicleCoveragesDetailsModalstatus,
            isOpen: false,
        });
        setCopyRoadTrailCoverageModalStatus({
            ...copyRoadTrailCoverageModalStatus,
            isOpen: true,
            triggerCopyCoverageRoadTrailPublicID: roadTrailPublicID,
        });
    };
    const onCopyCoverageButtonClick = () => {
        openCopyCoveragesModal();
    }
    const updateSubmissionVMOnCopyCoverage = useCallback(
        async (copyFromPublicID, copyToPublicIDs) => {
            const coverages = _.get(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
            const clausesToUpdate = RTCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
            const response = await coveragesService.copyRoadTrailCoverages(
                jobID,
                sessionUUID,
                clausesToUpdate,
                copyFromPublicID,
                copyToPublicIDs,
                authHeader,
            )
            const lobCoverages = _.get(response, lobName);
            const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
            _.set(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
            updateErrorsAndWarnings(errorsAndWarnings)
            updateWizardData(submissionVM)
            
        },
        [
            submissionVM,
            lobName,
            selectedVersionIndex,
            coveragesService,
            jobID,
            sessionUUID,
            authHeader,
            updateErrorsAndWarnings,
            updateWizardData
        ],
    );


    const hasMultiVehicless = vehicles.filter((vehicle) => vehicle.isPhysicalDamageRequired).length > 1;

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            RTVehiclesCoveragesCopyCoverageButton: {
                visible: isEditable && hasMultiVehicless,
            }
        };
    }, [
        isEditable,
        hasMultiVehicless,
    ]);

    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCopyCoverageButtonClick: onCopyCoverageButtonClick,
            renderVehicleDescriptionCell: renderVehicleDescriptionCell,
            renderVehicleOtherThanCollision: renderVehicleOtherThanCollision,
            renderVehicleCollision: renderVehicleCollision,
            renderRoadsideTowing: renderRoadsideTowing,
            renderVehicleOther: renderVehicleOther,
            renderVehicleAction: renderVehicleAction,
        },
        resolveComponentMap: {
        }
    };

    return (
        <>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
            {
                vehicleCoveragesDetailsModalstatus.isOpen ? <RTVehicleCoveragesModal
                    title={vehicleCoveragesDetailsModalstatus.title}
                    size="lg"
                    rtVehiclePublicID={vehicleCoveragesDetailsModalstatus.vehicleID}
                    submissionVM={submissionVM}
                    updateWizardData={updateWizardData}
                    isOpen={vehicleCoveragesDetailsModalstatus.isOpen}
                    onResolve={onResolveRTVehCoveragesDetailsModal}
                    openCopyCoveragesModal={openCopyCoveragesModal}
                    isEditable={isEditable}
                    selectedVersionIndex={selectedVersionIndex}
                    updateErrorsAndWarnings={updateErrorsAndWarnings}
                    coveragesService={coveragesService}
                    lobName="roadTrail"
                /> : null
            }
            {
                copyRoadTrailCoverageModalStatus.isOpen ? <CopyRTVehicleCoverageModal
                    isOpen={copyRoadTrailCoverageModalStatus.isOpen}
                    size="lg"
                    onResolve={onResolveCopyRoadTrailCoverageModal}
                    onReject={onRejectCopyRoadTrailCoverageModal}
                    triggerCopyCoverageRoadTrailPublicID={
                        copyRoadTrailCoverageModalStatus.triggerCopyCoverageRoadTrailPublicID
                    }
                    submissionVM={submissionVM}
                    updateSubmissionVMOnCopyCoverage={updateSubmissionVMOnCopyCoverage}
                /> : null
            }
        </>
    );

}

RTVehiclesCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
RTVehiclesCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default RTVehiclesCoverages;