import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glPolicyDetailsService'), method, data, additionalHeaders);
}

export default class GLPolicyDetailsService {

    static savePolicyDetailsData(quoteID, sessionUUID, glPolicyDetailsData, quoteBaseData, authHeader) {
        return processSubmission('saveGLPolicyDetailsData', [quoteID, sessionUUID, glPolicyDetailsData, quoteBaseData], authHeader);
    }

}