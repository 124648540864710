
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './ContactInformation.metadata.json5';
import messages  from '../NewAccountNewQuote.messages'

const ContactInformation = (props) => {
    const {
        model: accountContactDtoVM,
        writeValue,
        showErrors,
        onValidate
    } = props;
    const accountType = _.get(accountContactDtoVM.value, 'contactType_Ext')

    const isFieldVisible = (basePath) => {
        return _.get(accountContactDtoVM, 'value.primaryPhoneType') === basePath;
    };
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false
        },
        primaryPhoneType: {
            visible: accountType === 'person'
        },
        homePhone:{
            visible: isFieldVisible('home')
        },
        mobilePhone:{
            visible: isFieldVisible('mobile')
        },
        workPhone:{
            visible: isFieldVisible('work'),
            label: messages[`${accountType}WorkNumber`]
        },
        email: {
            label: messages[`${accountType}Email`]
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            accountContactDtoVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountContactDtoVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

export default ContactInformation;
