import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { CRQualificationService } from 'wni-capability-quoteandbind-cr';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/CRWizardPage';

import metadata from './CRQualificationPage.metadata.json5';
import messages from './CRQualificationPage.messages';

const lobDataPath = 'lobData.crime';
function CRQualificationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        crQualificationService,
    } = props;

    // const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CRQualificationPage');

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const questionsetAnswers = _.get(submissionVM, `${lobDataPath}.preQualQuestionSets.value[0].answers`);

    const displayQuestions = [
        'CR7PreQualificationVolunteersUsed',
        'CR7PreQualificationVolunteerNumberAndDescription',
        'CR7PreQualificationEmployeesLeasedToOthers',
        'CR7PreQualificationEmployeesLeasedToOthersNumberAndExplanation',
        'CR7PreQualificationEmployeesLeasedFromOthers',
        'CR7PreQualificationEmployeesLeasedFromOthersNumberAndExplanation',
        'CR7PreQualificationEmployeesCancelledForCrimeCoverage'
    ];

    const questionIsTrue = (value) => {
        const isTrue = value === 'true' || value === true
        return Boolean(isTrue);
    }

    const IsValidField = (value, code) => {
        switch (code) {
            case 'CR7PreQualificationVolunteerNumberAndDescription':
                if (questionIsTrue(questionsetAnswers.CR7PreQualificationVolunteersUsed)) {
                    if (value === undefined || value === null || value === '') {
                        return false;
                    }
                }
                break;
            case 'CR7PreQualificationEmployeesLeasedToOthersNumberAndExplanation':
                if (questionIsTrue(questionsetAnswers.CR7PreQualificationEmployeesLeasedToOthers)) {
                    if (value === undefined || value === null || value === '') {
                        return false;
                    }
                }
                break;
            case 'CR7PreQualificationEmployeesLeasedFromOthersNumberAndExplanation':
                if (questionIsTrue(questionsetAnswers.CR7PreQualificationEmployeesLeasedFromOthers)) {
                    if (value === undefined || value === null || value === '') {
                        return false;
                    }
                }
                break;
            default:
                if (value === undefined || value === null || value === '') {
                    return false;
                }
        }
        return true;
    }

    const IsValidFields = useCallback(() => {
        const invalidFields = _.filter(displayQuestions, (code) => {
            return IsValidField(questionsetAnswers[code], code) === false
        })
        if (invalidFields.length <= 0) {
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation]);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const getValidationIssues = useCallback((issues) => {
        let newIssues = _.cloneDeep(issues);
        const CRAnswerYesWarning = [{
            type: 'warning',
            reason: translator(messages.CRAnswerYesWarning)
        }]
        const CRDisqualificationError = [{
            type: 'error',
            reason: translator(messages.CRDisqualificationError)
        }]
        if (!_.isEmpty(questionsetAnswers)) {
            if (
                questionIsTrue(questionsetAnswers.CR7PreQualificationVolunteersUsed)
                || questionIsTrue(questionsetAnswers.CR7PreQualificationEmployeesLeasedToOthers)
                || questionIsTrue(questionsetAnswers.CR7PreQualificationEmployeesLeasedFromOthers)
            ) {
                newIssues = _.concat(newIssues, CRAnswerYesWarning);
            } else {
                _.remove(newIssues, (issue) => issue.reason === translator(messages.CRAnswerYesWarning))
            }
            if (questionIsTrue(questionsetAnswers.CR7PreQualificationEmployeesCancelledForCrimeCoverage)) {
                newIssues = _.concat(newIssues, CRDisqualificationError);
            } else {
                _.remove(newIssues, (issue) => issue.reason === translator(messages.CRDisqualificationError))
            }
        }
        return newIssues;
    }, [questionsetAnswers]);

    const filterQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const getQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            _.set(metaDataContent, 'componentProps.showRequired', true);
            _.set(metaDataContent, 'componentProps.required', true);
            if (!IsValidField(_.get(questionsetAnswers, metaDataContent.id), metaDataContent.id)) {
                _.set(metaDataContent, 'componentProps.validationMessages', [translator(messages.CRValidationMessages)]);
            } else {
                _.set(metaDataContent, 'componentProps.validationMessages', []);
            }
            if (metaDataContent.id === 'CR7PreQualificationEmployeesLeasedToOthersNumberAndExplanation'
                || metaDataContent.id === 'CR7PreQualificationEmployeesLeasedFromOthersNumberAndExplanation') {
                    _.set(metaDataContent, 'componentProps.label.defaultMessage', '');
            }
            return metaDataContent;
        }
    }, [questionsetAnswers]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                contentMapper: getQuestionMapper()
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, `${lobDataPath}.preQualQuestionSets.value`)
        };
        const res = await crQualificationService.updateQualification(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.crime.preQualQuestionSets.value', res.preQualQuestionSets);
        }
        updateServiceCallInProgress(false);
        updateWizardData(newSubmissionVM);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        const resValidationIssues = getValidationIssues(newValidationIssues);
        updateValidationIssues(resValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(resValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(resValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }

        return newSubmissionVM;
    }, [getValidationIssues, crQualificationService, submissionVM, updateWizardData]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CRQualificationPage.propTypes = WizardPage.propTypes;
CRQualificationPage.defaultProps = WizardPage.defaultProps;

CRQualificationPage.propTypes = {
    ...wizardProps,
    crQualificationService: PropTypes.object
};

CRQualificationPage.defaultProps = {
    crQualificationService: CRQualificationService
};
export default CRQualificationPage;