import React, {
    useContext,
    useState,
    useCallback,
    useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { WniDataTable, WniCheckboxField } from 'wni-common-base-components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AddContactPopup, CoverageContactSearchPopup} from 'wni-capability-common-react';
import SingleClauseContext from '../../context/GLSingleClauseContext';
import CoverageContext from '../../context/GLCoverageContext';
import GLScheduleItemReadonlyCell from './GLScheduleItemReadonlyCell';
import GLScheduleItemEditableCell from './GLScheduleItemEditableCell';
import messages from './GLScheduleTable.messages';
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import GLComplexSchedulePopup from '../GLComplexSchedulePopup/GLComplexSchedulePopup';
import { Button } from '@jutro/legacy/components';

const GLScheduleTable = (props) => {
    const modalApi = useWniModal();
    const {
        schedule,
    } = props
    const {
        propertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
        isSimpleSchedule_Ext: isSimpleSchedule,
        isComplexSchedule_Ext: isComplexSchedule,
        isAdditionalInsuredSchedule_Ext: isAdditionalInsuredSchedule,
    } = schedule

    const {
        wizardData: submissionVM,
        updateWizardData,
    } = useContext(CoverageContext)

    const {
        clauseCode: code,
        isEditable,
        clausePath: clauseVMPath,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)

    const {
        baseData: {
            additionalInsures_Ext: additionalInsures
        },
        jobID,
        sessionUUID,
    } = submissionVM.value
    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([]);
    const [isComplexSchedulePopupOpen, setIsComplexSchedulePopupOpen] = useState(false);
    const additionalInsured = propertyInfos.find(propertyInfo => propertyInfo.id === 'AdditionalInsured');
    const additionalInsuredPublicIDs = additionalInsures.map(item => _.get(item, 'publicID'));
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const existingContactcandidates = useMemo(() => {
        if (isAdditionalInsuredSchedule) {
            return additionalInsures
        }
        return []
    }, [isAdditionalInsuredSchedule, additionalInsures])

    const scheduleNumberColumn = <DisplayColumn
        key='ScheduleNumber'
        header= 'Schedule Number'
        sortable = {false}
        textAlign = 'left'
        path = 'itemNumber'
        renderCell = {(scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            if (!isEditable || scheduleReadonly) {
                return itemNumber
            }
            return <WniCheckboxField
                value = {selectedScheduleItemNumbers.includes(itemNumber)}
                label = {itemNumber}
                showInlineLabel
                onValueChange = {(checked) => {
                    let newSelectedScheduleItemNumbers
                    if (checked) {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.concat([itemNumber])
                    } else {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.filter(num => num !== itemNumber)
                    }
                    setSelectedScheduleItemNumbers(newSelectedScheduleItemNumbers)
                }}
            />
        }}
    />

    const contactColumn = <DisplayColumn
        key='ScheduleNumber'
        header = {additionalInsured? additionalInsured.label : ''}
        sortable = {false}
        textAlign = 'left'
        path = 'contactDisplayName_Ext'
    /> 

    const propertyInfoColumns = propertyInfos
        .filter((propertyInfo) => propertyInfo.id !== 'AdditionalInsured')
        .map((propertyInfo) => {
            const {
                id,
                label,
            } = propertyInfo
            
            return <DisplayColumn
                key = {id}
                header = {label}
                renderCell = {(scheduleItem) => {
                    const {
                        itemData: {
                            [id]: {
                                editable_Ext: isScheduleItemFieldEditable,
                                available_Ext: isScheduleItemFieldAvailable,
                            } = {}
                        },
                        itemNumber,
                    } = scheduleItem;
                    const isCellEditable = isEditable && !scheduleReadonly && isScheduleItemFieldEditable && isScheduleItemFieldAvailable
                    if (isCellEditable && !isComplexSchedule) {
                        const scheduleItemIndex = scheduleItems.findIndex((item) => {
                            return item.itemNumber === itemNumber
                        })
                        const scheduleItemVMPath = `${clauseVMPath}.schedule.scheduleItems.children[${scheduleItemIndex}]`
                        return <GLScheduleItemEditableCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                            scheduleItemIndex={scheduleItemIndex}
                            scheduleItemVMPath={scheduleItemVMPath}
                        />
                    }
                    return <GLScheduleItemReadonlyCell
                        propertyInfo={propertyInfo}
                        scheduleItem={scheduleItem}
                    />
                }}
                sortable = {false}
            />
        })
    
    
    const sortedScheduleItems = scheduleItems.toSorted((scheduleItemA, scheduleItemB) => {
        const getScheduleNumber = (scheduleItem) => {
            return _.get(scheduleItem, 'itemNumber')
        }
        return getScheduleNumber(scheduleItemA) - getScheduleNumber(scheduleItemB)
    })

    const delSchedule = () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            return !selectedScheduleItemNumbers.includes(itemNumber)
        })
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
        setSelectedScheduleItemNumbers([])
    }

    const addSchedule = () => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            itemData: {
            }
        }])
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
    }

    const addContactScheduledItem = useCallback((item) => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            'contactDisplayName_Ext': item.displayName,
            'contactPublicID_Ext': item.publicID,
            itemData: {
                "ScheduleNumber": {
                  "integerValue": scheduleItems.length
                },
                "AdditionalInsured": {
                  "visible_Ext": true,
                  "editable_Ext": false
                }
            }
        }])
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
    }, [clauseVMPath, deserializationClass, onChangeSubmissionAndSync, scheduleItems])

    const addNewContact = useCallback((newContactType) => {
        const primaryAddress = _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress')
        const contactVM = viewModelService.create(
            {
                contactCode: newContactType,
                primaryAddress,
                contactRoleType: 'AdditionalInsured'
            },
            'pc',
            'wni.edge.capabilities.policycommon.accountcontact.dto.WniAccountContactDTO'
        )
        const componentProps = {
            contactVM,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            size: 'lg',
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        return modalApi.showModal(
            <AddContactPopup {...componentProps} />
        ).then((res) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            const newlyAddedContact = res.additionalInsures.find((item) => !additionalInsuredPublicIDs.includes(item.publicID));
            addContactScheduledItem(newlyAddedContact);
            _.set(newSubmissionVM, 'baseData.additionalInsures_Ext', res.additionalInsures);
            updateWizardData(newSubmissionVM);
        }).catch(() => {
            _.noop();
        });;
    }, [addContactScheduledItem, additionalInsuredPublicIDs, authHeader, jobID, modalApi, sessionUUID, submissionVM, updateWizardData, viewModelService])

    const showContactSearchModal = useCallback(() => {
        const componentProps = {
            size: 'lg',
            existingContactcandidates
        };
        return modalApi.showModal(
            <CoverageContactSearchPopup {...componentProps} />
        );
    }, [existingContactcandidates, modalApi]);

    const onClickAddContact = useCallback(() => {
        showContactSearchModal().then(
            (selectedContact) => {
                addContactScheduledItem(selectedContact);
            }
        ).catch((reason) => {
            if (reason === 'person') {
                addNewContact('person')
            } else if (reason === 'company') {
                addNewContact('company')
            } else {
                _.noop();
            }
        });
    },[addContactScheduledItem, addNewContact, showContactSearchModal])

    return <>
        {
            isEditable && !scheduleReadonly ? <Flex
                gap = "small"
                justifyContent = "right"
                className = "mb-10"
            >
                <Button
                        className = "wni-button-danger"
                        type = "filled"
                        disabled = {selectedScheduleItemNumbers.length === 0}
                        onClick = {delSchedule}
                    >
                        {translator(messages.scheduleDel)}
                </Button>
                {isSimpleSchedule && 
                    <Button
                        icon = "gw-add"
                        onClick = {addSchedule}
                    >{translator(messages.scheduleAdd)}
                    </Button>
                }
                {isAdditionalInsuredSchedule && 
                <Button
                    icon = "gw-add"
                    onClick = {() => onClickAddContact()}
                >
                    {translator(messages.scheduleAdd)}
                </Button>
                }
                {isComplexSchedule && 
                <Button
                    onClick = {() => {setIsComplexSchedulePopupOpen(true)}}
                >
                    {translator(messages.scheduleEdit)}
                </Button>
                }
            </Flex> : (isComplexSchedule && <Flex
                gap = "small"
                justifyContent = "right"
                className = "mb-10"
            >
                <Button
                    onClick = {() => {setIsComplexSchedulePopupOpen(true)}}
                >
                    {translator(messages.scheduleView)}
                </Button>
            </Flex>)
        }
        <div className='table-wrapper mb-10'>
            <WniDataTable
                id = {`clause_schedule_table_${code}`}
                data={sortedScheduleItems}
                showSearch={false}
            >
                {isAdditionalInsuredSchedule? [scheduleNumberColumn, contactColumn, ...propertyInfoColumns] : [scheduleNumberColumn, ...propertyInfoColumns]}
            </WniDataTable>
        </div>
        {isComplexSchedulePopupOpen && <GLComplexSchedulePopup
            schedule={schedule}
            handleSave={() => {setIsComplexSchedulePopupOpen(false)}}
        />}
    </>
}

GLScheduleTable.propTypes = {
    schedule: PropTypes.shape({
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    showCovTermPropertyInfo: PropTypes.bool
}

GLScheduleTable.defaultProps = {
    schedule: {
        propertyInfos: []
    },
    showCovTermPropertyInfo: false
}

export default GLScheduleTable