import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useState } from 'react';
import {
    WniDropdownSelect,
    WniInputNumber,
    WniInputText,
    WniTextArea,
    WniToggle,
} from 'wni-common-base-components';

import { Flex } from '@jutro/layout';
import { Button } from '@jutro/components';

import messages from './ValuablePaperForm.messages';
import ValuablePaperSchedule from '../ValuablePaperSchedule';

const ValuablePaperForm = (props) => {
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);

    const {
        valuablePaper: originValuablePaper,
        availableBuildings,
        handleValuablePaperCancel,
        handleSaveValuablePaper,
        isEditable,
    } = props;

    const validationID = `valuablePaper_${originValuablePaper.publicID}`;

    const { onValidate, isComponentValid } = useValidation(validationID);

    const [valuablePaper, setValuablePaper] = useState(originValuablePaper);

    const setScheduleItems = (newScheduleItems) => setValuablePaper({...valuablePaper, scheduledItems: newScheduleItems})

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        handleSaveValuablePaper(valuablePaper)
    }

    return (
        <>
            <h4>{translator(messages.ReceptacleDetails)}</h4>
            <WniDropdownSelect
                id="locationBuilding"
                label={translator(messages.locationBuilding)}
                dataType="string"
                required
                showRequired
                availableValues={availableBuildings.map((building) => ({
                    code: building.publicID,
                    name: `${_.get(building, 'locationDisplayName')}/${_.get(
                        building,
                        'displayName'
                    )}`,
                }))}
                value={valuablePaper.locationBuildingPublicID}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        locationBuildingPublicID: value,
                    })
                }
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />
            <WniToggle
                id="isLocationALibrary"
                label={translator(messages.isLocationALibrary)}
                required
                showRequired
                availableValues={[
                    {
                        code: 'Yes',
                        name: translator(messages.Yes),
                    },
                    {
                        code: 'No',
                        name: translator(messages.No),
                    },
                ]}
                value={valuablePaper.isLocation ? 'Yes' : 'No'}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        isLocation: value === 'Yes',
                    })
                }
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />
            <WniTextArea
                id="receptacleManufacturer"
                label={translator(messages.receptacleManufacturer)}
                value={valuablePaper.receptacleManufacturer}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        receptacleManufacturer: value,
                    })
                }
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />
            <WniTextArea
                id="receptacleLabel"
                label={translator(messages.receptacleLabel)}
                value={valuablePaper.receptacleLabel}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        receptacleLabel: value,
                    })
                }
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />
            <WniDropdownSelect
                id="RecaptacleClass"
                label={translator(messages.RecaptacleClass)}
                dataType="string"
                availableValues={valuablePaper.receptacleClassRange}
                value={valuablePaper.receptacleClassOptionCode}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        receptacleClassOptionCode: value,
                    })
                }
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />
            <WniTextArea
                id="recptacleIssue"
                label={translator(messages.recptacleIssue)}
                value={valuablePaper.recptacleIssue}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        recptacleIssue: value,
                    })
                }
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />
            <WniInputNumber
                id="unscheduledLimit"
                label={translator(messages.unscheduledLimit)}
                value={valuablePaper.blanketLimit}
                onValueChange={(value) =>
                    setValuablePaper({
                        ...valuablePaper,
                        blanketLimit: value,
                    })
                }
                showFractions={false}
                showErrors={showErrors}
                onValidationChange={onValidate}
                readOnly={!isEditable}
            />

            <ValuablePaperSchedule
                scheduleItems={valuablePaper.scheduledItems}
                setScheduleItems={setScheduleItems}
                isEditable={isEditable}
                onValidate={onValidate}
                showErrors={showErrors}
            />

            <Flex gap="small" justifyContent="right" className="mb-10">
                {isEditable ? (
                    <>
                        <Button
                            variant="secondary"
                            onClick={handleValuablePaperCancel}
                            label={messages.Cancel}
                        />
                        <Button
                            onClick = {onSaveClicked}
                            label={messages.SaveAndContinue}
                        />
                    </>
                ): <Button
                    variant="secondary"
                    onClick={handleValuablePaperCancel}
                    label={messages.Cancel}
                />}
                
            </Flex>
        </>
    );
};

export default ValuablePaperForm;
