import { defineMessages } from 'react-intl';

export default defineMessages({
    PedestrianQuestion: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.question',
        defaultMessage: "Were there any other injuries involved in incident?"
    },
    PedestrianQuestionWTAndRT: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.question wt',
        defaultMessage: "Were there any third party injuries?"
    },
    Yes: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.No',
        defaultMessage: "No"
    },
    Number: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.No.',
        defaultMessage: "No."
    },
    PedestrianLastName: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.InjuryLastName',
        defaultMessage: "Injury Last Name"
    },
    PedestrianDescription: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.PedestrianDescription',
        defaultMessage: "Description"
    },
    PedestrianName: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.PedestrianName',
        defaultMessage: "Name"
    },
    Edit: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.Edit',
        defaultMessage: "Edit"
    },
    Delete: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.Delete',
        defaultMessage: "Delete"
    },
    AddPedestrian: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.AddInjuries',
        defaultMessage: "Add Injuries"
    },
    DeletePedestrian: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.DeletePedestrian',
        defaultMessage: "Delete Injuries"
    },
    viewAndEditLabel: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.pedestrians.viewAndEditLabel',
        defaultMessage: "View/Edit"
    }
})