
import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WniChangeConflict } from 'wni-capability-gateway';
import { DPMultiQuoteChangeService } from 'wni-capability-policychange-dp';
import { DPAdditionalInterestsPage } from 'wni-capability-quoteandbind-dp-react';

function DPAdditionalInterestsChangePage(props) {

    const modalApi = useWniModal();

    const useAuthenticationData = useAuthentication();
    const { authHeader } = useAuthenticationData;
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const {
        wizardData: submissionVM,
        updateWizardPageData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus,
        },
    } = submissionVM.value;

    const showChangeConflictsModal = useCallback(
        (modalVM) => {
            const baseVM = modalVM.baseData;
            const policyNumber = _.get(modalVM.value, 'changeData.policyNumber');
            const componentProps = {
                title: 'Change Conflicts',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'Submit',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                baseVM,
                jobID,
                policyNumber,
            };
            return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
        },
        [jobID, modalApi]
    );

    const goToNext = async () => {
        if (periodStatus === 'Draft') {
            updateWizardPageData({
                [WizardConstants.sideBySideData]: undefined,
                [WizardConstants.policyDiffData]: undefined,
                [WizardConstants.policyChangeDocumentsData]: undefined,
            });
            // submissionVM.value = await HOMultiQuoteService.processQuote(
            setLoadingMask(true)
            submissionVM.value = await DPMultiQuoteChangeService.processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM;
    };

    const handleConflict = async () => {
        const conflictRes = await WniChangeConflict.getChangeConflictData(submissionVM.jobID.value, authHeader);
        if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
            setLoadingMask(false)
            const newSubmissionVM = await showChangeConflictsModal(submissionVM)
                .then(goToNext)
                .catch(() => {
                    _.noop();
                    return false;
                });
            return newSubmissionVM;
        }
        return goToNext();
    }

    const onChangePageNext = async () => {
        return handleConflict()
    }

    return (
        <DPAdditionalInterestsPage {...props}
            processQuote={DPMultiQuoteChangeService.processQuote}
            onPageNext={onChangePageNext}
        />
    );

}

DPAdditionalInterestsChangePage.propTypes = DPAdditionalInterestsPage.propTypes;
DPAdditionalInterestsChangePage.defaultProps = DPAdditionalInterestsPage.defaultProps;

export default DPAdditionalInterestsChangePage;
