import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
// import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { ViewModelUtil } from 'wni-portals-viewmodel-js';


// extracted from QuoteClauseCommonHelper, documentation TBD
function getSelectedCoverages(data) {
    // only return the selected base coverages names
    const coveragesObject = _.get(data, 'lob.data.coverages');
    const coverageNames = Object.keys(coveragesObject).filter((name) => {
        return _.includes(name.toLowerCase(), 'coverages');
    });
    const baseCoverageName = _.first(coverageNames);
    const coverages = _.get(coveragesObject, baseCoverageName);
    return coverages.filter((cov) => cov.selected);
}

// extracted from QuoteClauseCommonHelper, documentation TBD
function getChangedDataPath(changedPath, fullChangedPath, pathToChangeTo) {
    const localisedVMPath = changedPath.replace(fullChangedPath, pathToChangeTo);
    return ViewModelUtil.getNonVMPath(localisedVMPath);
}

/**
 * ColumnData is generated in PAQuoteUIHelper.generateColumnData()
 * @param {object | string} changedPathOfModel
 * @param {object} columnData
 * @returns {object}
 */
function getChangedColumnData(changedPathOfModel, columnData) {
    // onBlur is called from Jutro with a object instead of a path
    const path = _.isString(changedPathOfModel) ? changedPathOfModel : changedPathOfModel.model;
    return columnData.find(({ lobOffering }) => _.includes(path, lobOffering.path));
}

// extracted from QuoteClauseCommonHelper, documentation TBD
function getChangedClause(chosenTermPath, columnData) {
    const changedObjectPath = ClausesUtil.getObjectPathFromChangedPath(chosenTermPath);
    const changedColumn = getChangedColumnData(chosenTermPath, columnData);
    // Convert lobData.personalAuto.coverages to lobOffering.data.coverages
    const changedPath = getChangedDataPath(changedObjectPath, changedColumn.lobOffering.path, 'lobOffering.data');
    return _.get(changedColumn, changedPath);
}


export default {
    getSelectedCoverages,
    //
    getChangedColumnData,
    getChangedClause,

};
