import _ from 'lodash'

const LOBS_MAP = {
    bop: ['bop', 'BusinessOwners', 'BusinessOwnersLine', 'BOPLine'],
    ca: ['ca', 'CommercialAuto', 'BusinessAuto', 'BusinessAutoLine'],
    cp: ['cp', 'CommercialProperty', 'CommercialPropertyLine', 'CPLine'],
    cpkg: ['cpkg', 'CommercialPackageLine', 'CommercialPackage'],
    gl: ['gl', 'GeneralLiability', 'GeneralLiabilityLine', 'GLLine'],
    ho: ['ho', 'Homeowners', 'HomeownersLine_HOE', 'HOPHomeowners', 'HOPLine', 'HOLine', 'pr'],
    dp: ['DwellingFire_EXT'],
    im: ['im', 'InlandMarine', 'InlandMarineLine', 'IMLine'],
    pa: ['pa', 'PersonalAuto', 'PersonalAutoLine', 'auto_per', 'auto'],
    wc7: ['wc7', 'WC7WorkersComp', 'WC7Line', 'WC7WorkersCompLine', 'Workers\' Compensation (v7)'],
    wc: ['wc', 'WorkersComp', 'WorkersCompLine', 'Workers\' compensation', 'WCMWorkersComp', "WCMLine"],
    gtp: ['gtp'],
    slq: ['slq'],
    wt: ['Watercraft_EXT'],
    rt: ['rt', 'RoadTrail_EXT', 'RoadTrail'],
};

const keyAndValuesToEntries = ([key, arr]) => arr.map((el) => [_.toLower(el), key]);

function reverseLookupMap(obj) {
    const lookupMap = _(obj)
        .toPairs()
        .flatMap(keyAndValuesToEntries)
        .fromPairs()
        .value();
    return lookupMap;
}

const lobLookupMap = reverseLookupMap(LOBS_MAP);

function getNormalizedLOBName(productName) {
    const productNameLwr = _.toLower(productName);
    return lobLookupMap[productNameLwr];
}

export default getNormalizedLOBName
