import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import styles from './PolicyInformationPage.module.scss';
import metadata from './PolicyInformationPage.metadata.json5';
import './PolicyInformationPage.messages';

function removeInitialData(submissionVM) {
    // eslint-disable-next-line no-param-reassign
    submissionVM.value = MockUpUtil.cleanUpMockedProperties(
        _.get(submissionVM, 'value'),
        'quote.ho',
        'baseData.accountHolder.firstName',
        'baseData.accountHolder.lastName'
    );
    return submissionVM;
}

function initialiseVM(submissionVM) {
    const vm = removeInitialData(submissionVM);
    vm.bindData.value = vm.bindData.value || {};
    vm.bindData.billingAddress.value = vm.bindData.billingAddress.value
    || vm.baseData.policyAddress.value;
    vm.bindData.contactEmail.value = vm.bindData.contactEmail.value
    || vm.baseData.accountHolder.emailAddress1.value;

    return vm;
}

function PolicyInformationPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSameBillingAddress, updateIsSameBillingAddress] = useState(true);
    const [isVmInitialized, updateIsVmInitialized] = useState(false);
    const localeService = ServiceManager.getService('locale-service');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PolicyInformationPage');

    useEffect(() => {
        if (!isVmInitialized) {
            let nextSubmissionVM = viewModelService.clone(submissionVM);
            nextSubmissionVM = initialiseVM(nextSubmissionVM);

            if (!_.isEqual(submissionVM.value, nextSubmissionVM.value)) {
                updateWizardData(nextSubmissionVM);
            }
            updateIsVmInitialized(true);
        }
    }, [isVmInitialized, submissionVM, updateWizardData, viewModelService]);

    const handleNext = useCallback(async () => {
        if (isSameBillingAddress) {
            const policyAddress = _.get(submissionVM, 'baseData.policyAddress.value');
            _.set(submissionVM, 'bindData.billingAddress.value', policyAddress);
        }
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value,
            authHeader
        ).then(() => LoadSaveService.updateQuotedSubmission(submissionVM.value, authHeader));
        return submissionVM;
    }, [LoadSaveService, authHeader, isSameBillingAddress, submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        sameBillingAddress: {
            value: isSameBillingAddress,
            onValueChange: updateIsSameBillingAddress
        },
        billingAddressFields: {
            visible: !isSameBillingAddress
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry()
        },
        primaryInsuredForProducer: {
            visible: config.persona !== 'anonymous'
        },
        firstName: {
            visible: config.persona === 'anonymous'
        },
        lastName: {
            visible: config.persona === 'anonymous'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            updateIsSameBillingAddress
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage onNext={handleNext} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PolicyInformationPage.propTypes = wizardProps;
export default PolicyInformationPage;
