import { defineMessages } from 'react-intl';

export default defineMessages({
    policyPeriod: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Policy Period',
        defaultMessage: 'Policy Period'
    },
    viewAccountCommission: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.View Account Commission',
        defaultMessage: 'View Account Commission'
    },
    viewOverallCommission: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.View Overall Commission',
        defaultMessage: 'View Overall Commission'
    },
    commissionSummary: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Commission Summary',
        defaultMessage: 'Commission Summary'
    },
    theWholePolicyPeriod: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Shown below is the commission you will earn for this policy for the whole policy period.',
        defaultMessage: 'Shown below is the commission you will earn for this policy for the whole policy period.'
    },
    notEarningAnyCommissionPolicyPeriod: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.You are not earning any commission for this policy in this policy period.',
        defaultMessage: 'You are not earning any commission for this policy in this policy period.'
    },
    notPaid: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Not Paid',
        defaultMessage: 'Not Paid'
    },
    paid: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Paid',
        defaultMessage: 'Paid'
    },
    totalCommission: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Total Commission',
        defaultMessage: 'Total Commission'
    },
    paymentsAreIssued: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.The amounts for paid and unpaid might not be fully accurate if direct bill statement payments are suppressed due to low balance, commission payments are reversed or standard commission payments are issued.',
        defaultMessage: 'The amounts for paid and unpaid might not be fully accurate if direct bill statement payments are suppressed due to low balance, commission payments are reversed or standard commission payments are issued.'
    },
    policyInfo: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Policy Info',
        defaultMessage: 'Policy Info'
    },
    totalPremium: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Total Premium + Tax',
        defaultMessage: 'Total Premium + Tax'
    },
    associatedPolicyTransaction: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Associated Policy Transaction',
        defaultMessage: 'Associated Policy Transaction'
    },
    primaryProducer: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Primary Producer',
        defaultMessage: 'Primary Producer'
    },
    secondaryProducer: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Secondary Producer',
        defaultMessage: 'Secondary Producer'
    },
    referrerInfo: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Referrer Info',
        defaultMessage: 'Referrer Info'
    },
    producerCode: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Producer Code',
        defaultMessage: 'Producer Code'
    },
    commissionPlan: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Commission Plan',
        defaultMessage: 'Commission Plan'
    },
    standardCommissionRate: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Standard Commission Rate',
        defaultMessage: 'Standard Commission Rate'
    },
    transactions: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Transactions',
        defaultMessage: 'Transactions'
    },
    date: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Date',
        defaultMessage: 'Date'
    },
    type: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Type',
        defaultMessage: 'Type'
    },
    charge: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Charge',
        defaultMessage: 'Charge'
    },
    basis: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Basis',
        defaultMessage: 'Basis'
    },
    rate: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Rate',
        defaultMessage: 'Rate'
    },
    amount: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Amount',
        defaultMessage: 'Amount'
    },
    total: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Total',
        defaultMessage: 'Total'
    },
    separator: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.—',
        defaultMessage: '-'
    },
    noTransactionData: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.There is no transaction data.',
        defaultMessage: 'There is no transaction data.'
    },
    problemAccessingcommission: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.There was a problem accessing commission data for this policy.',
        defaultMessage: 'There was a problem accessing commission data for this policy.'
    },
    failedToRetrieveTheData: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective.Failed to retrieve the data',
        defaultMessage: 'Failed to retrieve the data'
    },
    current: {
        id: 'gateway.directives.PolicyCommissionsSummaryDirective. (Current)',
        defaultMessage: '(Current)'
    }
});
