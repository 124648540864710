
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { PUUnderlyingReadOnlyPage } from 'wni-capability-quoteandbind-pu-react';

function PUUnderlyingChangeReadOnlyPage(props) {

    return (
        <PUUnderlyingReadOnlyPage {...props} />
    );

}

PUUnderlyingChangeReadOnlyPage.propTypes = PUUnderlyingReadOnlyPage.propTypes;
PUUnderlyingChangeReadOnlyPage.defaultProps = PUUnderlyingReadOnlyPage.defaultProps;

export default PUUnderlyingChangeReadOnlyPage;