import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { CAStateSpecificInfoService } from 'wni-capability-quoteandbind-ca';
import {
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    ValidationIssueUtil
} from 'wni-portals-util-js';

import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import CAStateSpecificInfoUtil from './util/CAStateSpecificInfoUtil'
import WizardPage from '../../templates/CAWizardPage';
import CAStateSpecificInfocomponent from './Components/StateSpecificInfoComponent/CAStateSpecificInfoComponent'

import metadata from './CAStateSpecificInformationPage.metadata.json5';
import messages from './CAStateSpecificInformationPage.messages';
import CACoverageUtil from '../Coverages/util/CACoverageUtil';

function CAStateSpecificInformationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        resetWizardDataToSnapshot,
        isReadOnly
    } = props;

    const {
        jobID, 
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            commercialAuto: {
                offerings,
            }
        },
    } = submissionVM.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CAStateSpecificInformationPage');
    const [validationIssues, updateValidationIssues] = useState(undefined);

    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [stateInfoData, updateStateInfoData] = useState([]);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [accordionStates, updateAccordionStates] = useState([]);

    const stateInfosVMPath = `lobData.commercialAuto.coverables.stateInfos`;
    const stateInfosVM = _.get(submissionVM, stateInfosVMPath);

    const VALIDATION_ICON_MAP = {
        success: 'gw-check-circle',
        warning: 'gw-warning',
        error: 'gw-error',
    };

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const lobName = 'commercialAuto'

    const caCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`

    const caStatesCoveragesPath = `${caCoveragesPath}.stateSpecificCoverages`;
    
    const setRowIdPath = useCallback(() => {
        const states = _.get(submissionVM, `${stateInfosVMPath}.value`);
        states.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateStateInfoData(states);
    }, [submissionVM]);

    useEffect(() => {
        setRowIdPath();
    }, [submissionVM.value]);

    const accordionErrorId = useCallback(() => {
        if (!currentRow) {
            return [];
        }
        const errorStateObj = CAStateSpecificInfoUtil
            .getAccordionOverridesForStateInfo(currentRow);
        const errorIds = Object.keys(errorStateObj).filter((key) => errorStateObj[key].errorState);
        return errorIds;
    }, [currentRow]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        updateAccordionStates(accordionErrorId());
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'stateSpecificTable');
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const renderDropDownCell = (data, index, {path, typeKey}) => {
        const formatValue = data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-';
        return <span className='text-breakAll'>{formatValue}</span>
    };

    const updateSubmission = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allStates = _.get(submissionVM.value, stateInfosVMPath);
        const currentIndex = allStates.findIndex((item) => item.publicID === publicID); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${stateInfosVMPath}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };
    const updateCurrentRow = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        updateCurrentRowInteranl(rowData);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const isStateCoveragesInvalid = (state) => {
        const statesCoverages = _.get(submissionVM.value, caStatesCoveragesPath, [])
        const stateCoverages = statesCoverages.find((s) => s.state === state)
        const {
            hiredAutoAdditionalCoverages = [],
            hiredAutoConditions = [],
            hiredAutoExclusions = [],
            hiredAutoStandardCoverages = [],
            stateAdditionalCoverages = [],
            stateConditions = [],
            stateExclusions = [],
            stateStandardCoverages = [],
        } = stateCoverages
        const stateCoveragesInvalid = CACoverageUtil.hasInvalidCoverage(
            hiredAutoAdditionalCoverages.concat(hiredAutoConditions).concat(hiredAutoExclusions).concat(hiredAutoStandardCoverages)
                .concat(stateAdditionalCoverages).concat(stateConditions).concat(stateExclusions).concat(stateStandardCoverages)
        )
        return stateCoveragesInvalid
    }

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${stateInfosVMPath}.children`);
        const itemVM = childrenVM.find((vm) => vm.value.publicID === index);
        const hasIssuanceInvalidFields = CAStateSpecificInfoUtil.hasIssuanceInvalidFields(itemVM);
        const jurisdiction = _.get(item, 'jurisdiction')
        const stateCoveragesInvalid = isStateCoveragesInvalid(jurisdiction)
        const stateInfoInvalid = CAStateSpecificInfoUtil.hasInvalidStateInfo(itemVM);
        let type;
        if(!_.get(itemVM, 'aspects.valid') || !_.get(itemVM, 'aspects.subtreeValid') || stateCoveragesInvalid || stateInfoInvalid) {
            type = 'error';
        } else if (hasIssuanceInvalidFields) {
            type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };
    
    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardData);
    };

    const viewOrEditStateInfo = (value, index) => {
        setLoadingMask(true);
        const childrenVM = _.get(submissionVM, `${stateInfosVMPath}.children`);
        const stateVM = childrenVM.find((item) => item.value.rowIdPath === index);
        syncWizardData(stateVM);
        setLoadingMask(false);
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        // updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };
    
    const getNewSubmissionVMFromResponse = (res) => {
        const resStateInfoPath = _.get(res, 'castateInfos');
        const coverages = _.get(res, 'coverages')
        const newSubmissionVM = _.clone(submissionVM)
        _.set(newSubmissionVM, stateInfosVMPath, resStateInfoPath);
        _.set(newSubmissionVM.value, caCoveragesPath, coverages);

        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);

        return newSubmissionVM;
    }
    
    const updateSubmissionVMForResponse = (res) => {
        const newSubmissionVM = getNewSubmissionVMFromResponse(res)
        updateWizardData(newSubmissionVM)
    };

    const stateInfoService = useCallback(async(serviceName, serviceData) => {
        setLoadingMask(true);
        const res = await CAStateSpecificInfoService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        updateSubmissionVMForResponse(res);
        setRowIdPath();
        updateSelection([]);
        updateWizardSnapshot(submissionVM);
        updateShowErrors(false);
        generateValidationIssues(res.errorsAndWarnings);
        updateCurrentRow(null);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM]);


    const writeValue = (value, path) => {
        if(currentRow) {
            _.set(currentRow, path, value);
            syncWizardData(currentRow);
        }
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const cancelState = () => {
        resetWizardDataToSnapshot();
        syncWizardData(null)
    }

    const updateStateInfo = useCallback(async() => {
        const stateInfoInvalid = CAStateSpecificInfoUtil.hasInvalidStateInfo(currentRow);
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid || stateInfoInvalid) {
            handleValidation();
            return false;
        };

        await stateInfoService('updateStateInfo', currentRow.value);
        return true;
    }, [currentRow, handleValidation, isComponentValid, stateInfoService]);

    const onNextStateInfo = useCallback(() => {
        const childrenVM = _.get(submissionVM, `${stateInfosVMPath}.children`);
        let index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.publicID');
        syncWizardData(null);
        viewOrEditStateInfo(null, indexID)
        WindowUtil.scrollToTop()
    }, [currentRow, submissionVM]);

    const allStateInfoValid = () => {
        const allStateInfoVMs = _.get(submissionVM, `${stateInfosVMPath}.children`);
        return allStateInfoVMs.every((vm) => {
            const state = _.get(vm.value, 'jurisdiction')
            const stateCoveragesInvalid = isStateCoveragesInvalid(state)
            const stateInfoInvalid = CAStateSpecificInfoUtil.hasInvalidStateInfo(vm);
            return vm.aspects.valid && vm.aspects.subtreeValid && !stateCoveragesInvalid && !stateInfoInvalid
        });
    };

    const onPageNext = useCallback(async () => {
        if(!allStateInfoValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await CAStateSpecificInfoService.onPageNext(requestData, authHeader);
        const newSubmissionVM = getNewSubmissionVMFromResponse(res);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        setRowIdPath();
        if(!isPageValid) {
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, submissionVM]);

    //---------------------
    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
            readOnly: isReadOnly
        },
        stateInfoTable: {
            data: stateInfoData,
            onSelectionChange: (rows) => {
                updateSelection(rows)
            },
            rowIdPath: 'rowIdPath'
        },
        stateInfoValidationIcon: {
            renderCell: renderValidationCell
        },
        viewOrEditLink: {
            disabled: currentRow || selection.length > 1,
            label: isReadOnly ? messages.caViewLabel : messages.caViewAndEditLabel
        },
        StateSpecificInfoContainer: {
            visible: currentRow != null,
        },
        stateSpecificInfo: {
            visible: !_.isEmpty(currentRow),
            stateSpecificVM: currentRow,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            updateSubmissionVMForResponse,
            updateValidationIssues,
            generateValidationIssues,
            accordionStates,
            onValidate,
            showErrors,
            isReadOnly,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            submissionVM,
            updateWizardData,
        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: stateInfosVM.value.length > 1
        }
    };

    const resolvers = {
        resolveClassNameMap: {},
        resolveCallbackMap: {
            viewOrEditStateInfo,
            cancelState,
            saveState: () => {
                updateStateInfo().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextState: () => {
                updateStateInfo().then((valid) => {
                    if (valid) {
                        onNextStateInfo();
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderDropDownCell,
        },
        resolveComponentMap: {
            stateSpecificInfocomponent: CAStateSpecificInfocomponent
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            showEntityNameInPageLevelIssues
            disableNext={!allStateInfoValid()}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAStateSpecificInformationPage.propTypes = WizardPage.propTypes;
CAStateSpecificInformationPage.defaultProps = WizardPage.defaultProps;
export default CAStateSpecificInformationPage;