import _ from 'lodash';
import { WniAccordionValidationUtil, WniDateUtil, ConfigUtil } from 'wni-portals-util-js';
import WCStateSpecificConstants from '../config/WCStateSpecificConstants';

function hasIssuanceInvalidFields(dataVM = {}) {
    const data = dataVM.value || {};
    const annualPayroll = _.get(data, 'annualPayroll');
    if(annualPayroll && Number(annualPayroll) < 0) {
        return true;
    }
    return false;
}


function setDefaultCoveredEmployeeObj() {
    const res = {
        rowIdPath: ConfigUtil.getUuid()
    };
    _.unset(res, 'publicID');
    return res;
}

function getAccordionOverridesForStateSpecific(operatorVM, validationIssues = []) {
    const cardFieldsMap = WCStateSpecificConstants.sectionFieldsMap;
    const retval = WniAccordionValidationUtil.getInvalidAccordionCardsOverrides(cardFieldsMap, operatorVM);
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(operatorVM, 'value.fixedId');
    });
    const errorFields = _.map(errorIssues, (error) => {
        return _.upperFirst(_.get(error, 'relatedEntity.fieldName'));
    });
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        const invalidFields = _.filter(fields, (field) => {
            const idx = errorFields.indexOf(_.upperFirst(field));
            return idx >= 0;
        });
        return invalidFields.length > 0;
    });
    ids.forEach((id) => {
        if (invalidAccordions.indexOf(id) >= 0) {
            _.set(retval, `${id}.errorState`, true);
        }
    });
    return retval;
}

function getPrimaryAddressDisplayName(primaryAddress) {
    const addressLine = _.get(primaryAddress, 'pobox_Ext.value', null) || _.get(primaryAddress, 'addressLine1.value', null) || '-';
    const addressLine2 = _.get(primaryAddress, 'addressLine2.value');
    const city = _.get(primaryAddress, 'city.value') || '-';
    const stateCode = _.get(primaryAddress, 'state.value.code') || '-';
    const zipCode = _.get(primaryAddress, 'postalCode.value') || '-';
    return `${addressLine}, ${addressLine2 ? `${addressLine2},` : ''} ${city}, \n ${stateCode}, ${zipCode}`;
}

function renderEffectiveDateCell(item) {
    if (!item.effectiveDate) {
        return '';
    }
    if(item.effectiveDate) {
        return WniDateUtil.formatDateWithPattern(item.effectiveDate, 'MM/DD/YYYY');
    }
}

function renderExpirationDateCell(item) {
    if (!item.expirationDate) {
        return '';
    }
    if(item.expirationDate) {
        return WniDateUtil.formatDateWithPattern(item.expirationDate, 'MM/DD/YYYY');
    }  
}

export default {
    hasIssuanceInvalidFields,
    setDefaultCoveredEmployeeObj,
    getAccordionOverridesForStateSpecific,
    getPrimaryAddressDisplayName,
    renderEffectiveDateCell,
    renderExpirationDateCell
}