import _ from 'lodash';

export const valueTypeNameMap = {
    Option: 'typeCodeValue',
    Direct: 'bigDecimal',
    MONEY_EXT: 'bigDecimal',
    shorttext: 'stringValue',
    datetime: 'dateValue',
    bit: 'booleanValue'
}

export const valueTypeMap = {
    TYPEKEY: 'typeCodeValue',
    STRING: 'stringValue',
    INTEGER: 'integerValue',
    LOCATION: 'locationValue',
    DATE: 'dateValue',
    POLICYCONTACT: 'policyContactValue'
};

export const valueTypeNameDatatypeMap = {
    Option: {
        dataType: 'string',
        component: 'WniDropdownSelect'
    },
    Direct: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    MONEY_EXT: {
        dataType: 'number',
        component: 'WniCurrency'
    },
    shorttext: {
        dataType: 'string',
        component: 'WniInputText'
    },
    datetime: {
        dataType: 'date',
        component: 'WniDate'
    },
    bit: {
        dataType: 'boolean',
        component: 'WniToggle'
    }
};

export const valueTypeDatatypeMap = {
    TYPEKEY: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    STRING: {
        dataType: 'string',
        component: 'WniInputText'
    },
    INTEGER: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    DATE: {
        dataType: 'date',
        component: 'WniDate'
    },
};

export const valueTypePropDatatypeMap = {
    TYPEKEY: 'string',
    INTEGER: 'number',
    DATE: 'string'
};

export function formatAddress(address) {
    const addressValues = [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.city,
        address.postalCode,
        address.state
    ].filter((addressValue) => !_.isUndefined(addressValue));

    return address ? addressValues.join(', ') : undefined;
}

export function getFormattedValue(value, valueType, propertyInfo) {
    if (valueType === 'TYPEKEY') {
        const availableValue = _.get(propertyInfo, 'availableTypeListValues', []).find(
            (av) => av.code === value || av.displayKey === value
        );
        return _.get(availableValue, 'displayKey');
    }
    return value;
}

export function getComponentDataType(propertyInfo) {
    const { valueType } = propertyInfo;

    if (valueType === 'INTEGER' && _.has(propertyInfo, 'currency')) {
        return 'currency';
    }

    return _.get(valueTypeDatatypeMap, valueType);
}

export function getPropDataType({ valueType }) {
    return _.has(valueTypePropDatatypeMap, valueType)
        ? _.get(valueTypePropDatatypeMap, valueType) : undefined;
}

export function isShceduleItemDataItemDiff(item1, item2) {
    if (_.isNil(item1) || _.isNil(item2)) {
        return true
    }
    const {
        typeCodeValue: typeCodeValueItem1,
        bigDecimal: bigDecimalItem1,
        stringValue: stringValueItem1,
        dateValue: dateValueItem1,
        booleanValue: booleanValueItem1,
    } = item1
    const {
        typeCodeValue: typeCodeValueItem2,
        bigDecimal: bigDecimalItem2,
        stringValue: stringValueItem2,
        dateValue: dateValueItem2,
        booleanValue: booleanValueItem2,
    } = item2
    if (typeCodeValueItem1 !== typeCodeValueItem2
        || bigDecimalItem1 !== bigDecimalItem2
        || stringValueItem1 !== stringValueItem2
        || dateValueItem1 !== dateValueItem2
        || booleanValueItem1 !== booleanValueItem2
    ) {
        return true
    }

    return false
}


