import { defineMessages } from 'react-intl';

export default defineMessages({
    policyLevelQuoteDetails: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Policy Level Quote Details',
        defaultMessage: 'Policy Level Quote Details',
    },
    commonOptionalCoverages: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Common Optional Coverages',
        defaultMessage: 'Common Optional Coverages',
    },
    otherOptionalCoverages: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Other Optional Coverages',
        defaultMessage: 'Other Optional Coverages',
    },
    additionalCoverages: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Additional Coverages',
        defaultMessage: 'Additional Coverages',
    },
    exclusionCoverages: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Exclusion Coverages',
        defaultMessage: 'Exclusion Coverages',
    },
    //
    sectionICovSectionTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Property Coverages Premium',
        defaultMessage: 'Property Coverages Premium',
    },
    sectionIICovSectionTitle: {
        id: 'wni.quoteandbind.ho-wizard.ho-quote.Liability Coverages Premium',
        defaultMessage: 'Liability Coverages Premium',
    },
    //
    printQuoteSummary: {
        id: 'quoteandbind.views.ho-wizard.ho-quote.Print Quote Summary',
        defaultMessage: 'Print Quote Summary'
    }
});
