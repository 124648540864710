import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CPCoverageService, CPModifierService } from 'wni-capability-quoteandbind-cp';
import CoveragesConfigContext from './context/CPCoveragesConfigContext'
import CPCoveragesConfig from './CPCoverages.config';
import metadata from './CommercialPropertyPage.metadata.json5';

import WizardPage from '../../templates/CPWizardPage';
import CPDetailsAccordionCard from './CPDetailsAccordionCard/CPDetailsAccordionCard';
import CPCoveragesAccordionCard from './CPCoveragesAccordionCard/CPCoveragesAccordionCard';
import CPCoveragesAdditionalCoveragesAccordionCard from './CPAdditionalCoveragesAccordionCard/CPAdditionalCoveragesAccordionCard';
import CPExclusionsAndConditionsAccordionCard from './CPExclusionsAndConditionsAccordion/CPExclusionsAndConditionsAccordionCard';
import CPSearchAndAddCoveragesPopup from '../../components/CPSearchAndAddCoveragesPopup/CPSearchAndAddCoveragesPopup';

const CommercialPropertyPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const { initialValidation, onValidate, isComponentValid } = useValidation('CommercialPropertyPage');
    const { authHeader } = useAuthentication();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [lineClauses, setLineClauses] = useState()
    const [loadingClauses, setLoadingClauses] = useState(false);

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initLineClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await CPCoverageService.getLineClausesWithSync(jobID, sessionUUID, authHeader) 
            : await CPCoverageService.getLineClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setLineClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initLineClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            isLine: true
        };
        return modalApi.showModal(
            <CPSearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchCoverage = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType)
        setLineClauses(data)
    },[showCoveragesModal])

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardData(newSubmissionVM)
    } 

    

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const onPageNext = async () => {
        setLoadingMask(true)
        const res = await CPCoverageService.onCommercialPropertyPageNext(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
            stateSimpleInfos,
        } = res        
        const modifiers = await CPModifierService.getModifiers(jobID, sessionUUID, authHeader)
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        _.set(newSubmissionVM, 'value.lobData.commercialProperty.modifiers_Ext', modifiers)
        _.set(newSubmissionVM.value, 'lobData.commercialProperty.coverables.stateSimpleInfos', stateSimpleInfos)
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return newSubmissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const accordionCommonProps = {
        lineClauses,
        setLineClauses,
        isEditing,
        setIsEditing,
        isEditable: true,
        showErrors,
        onValidate: onValidate,
        loadingClauses,
        setLoadingClauses,
        onClickAddSearchCoverage,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        CPDetailsAccordionCard: {
            ...accordionCommonProps
        },
        CPCoveragesAccordionCard: {
            ...accordionCommonProps
        },
        CPCoveragesAdditionalCoveragesAccordionCard: {
            ...accordionCommonProps
        },
        CPExclusionsAndConditionsAccordionCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            CPDetailsAccordionCard: CPDetailsAccordionCard,
            CPCoveragesAccordionCard: CPCoveragesAccordionCard,
            CPCoveragesAdditionalCoveragesAccordionCard: CPCoveragesAdditionalCoveragesAccordionCard,
            CPExclusionsAndConditionsAccordionCard: CPExclusionsAndConditionsAccordionCard
        },
    };

    const disableNavigator = isEditing || isLoadingMask || loadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {lineClauses && 
                <CoveragesConfigContext.Provider value={CPCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default CommercialPropertyPage
