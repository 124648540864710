import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardContext } from '@xengage/gw-portals-wizard-react';

import { WizardConstants } from 'wni-portals-config-js';

import styles from './WizardSidebar.module.scss';

/**
 * @typedef {import('gw-portals-edge-validation-js/errors').GenericUIIssue} GenericUIIssue
 */

/**
 * Renders an icon for steps with errors
 * @param {Array<GenericUIIssue>} errorsAndWarnings array
 * @returns {Object}
 */
function renderErrors(errorsAndWarnings = []) {
    const highestLevel = errorsAndWarnings
        .map((err) => err.level)
        .reduce((currentHighest, currentError) => {
            if (!currentHighest) {
                return currentError;
            }
            if (currentHighest === ErrorLevel.LEVEL_WARN) {
                // currentError can only Greater or Equal than currentHighest
                return currentError;
            }
            return currentHighest;
        }, null);
    switch (highestLevel) {
        case ErrorLevel.LEVEL_ERROR:
            return <Icon icon="gw-block" className={styles.errorIssue} />;
        case ErrorLevel.LEVEL_WARN:
            return <Icon icon="gw-warning" className={styles.warningIssue} />;
        default:
            return null;
    }
}

function jumpRenderModal(index, wizardContext, customMessageModal) {
    const modalMessages = {
        title: !_.isEmpty(customMessageModal)
            ? customMessageModal.title
            : commonMessages.wantToJump,
        message: !_.isEmpty(customMessageModal)
            ? customMessageModal.message
            : commonMessages.wantToJumpMessage,
        messageProps: {
            ok: commonMessages.yes,
            close: commonMessages.close
        }
    };
    const { wizardData, wizardSnapshot, onPageJump } = wizardContext;

    onPageJump({
        wizardData,
        wizardSnapshot,
        modalMessages,
        index
    });
}

const generateSteps = (steps) => {
    return steps.reduce((allSteps, currentStep, index) => {
        _.set(currentStep, 'currentIndex', index);
        if(currentStep[WizardConstants.parentId]) {
            const hasParentStep = allSteps.find((v) => v.id === currentStep[WizardConstants.parentId]);
            if(hasParentStep) {
                return [...allSteps, currentStep]
            };
            const currentStepParent = {
                id: currentStep[WizardConstants.parentId],
                title: currentStep[WizardConstants.parentTitle],
                isParentStep: true,
            }
            return [...allSteps, currentStepParent, currentStep]
        };

        return [...allSteps, currentStep]
    }, []);
};

function WizardSidebar(props) {
    const wizardContext = useContext(WizardContext);
    const translator = useTranslator();
    const {
        steps,
        currentStepIndex,
        jumpTo,
        // stepsWithErrors,
        wizardTitle,
    } = wizardContext;
    const { customMessageModal } = props;

    // stepOpenStatus: {'stepId': true|false}, e.g. {'CPPQuotePage': false, 'CPPPolicyDetailsPage': true}
    const [stepOpenStatus, updateStepOpenStatus] = useState({});

  
    const initSteps = generateSteps(steps);
    
    useEffect(() => {
        const normalizedSteps = generateSteps(steps);
        const parentSteps = normalizedSteps.filter((step) => step.isParentStep);
        const parentStepOpenStatus = {};
        parentSteps.forEach((step) => parentStepOpenStatus[step.id] = true );

        updateStepOpenStatus(parentStepOpenStatus);
    }, [steps]); // do not use initSteps here, otherwise infinite loop awaits

    const handleSteps = (step) => {
        if(step.isParentStep) {
            const prevStepOpen = stepOpenStatus[step.id];
            updateStepOpenStatus({...stepOpenStatus, [step.id]: !prevStepOpen});
            return false;
        }
        jumpRenderModal(step.currentIndex, wizardContext, customMessageModal)
    }

    const renderSteps = () => {
        return initSteps.map((step) => {
            let isOpen = true;
            if(step[WizardConstants.parentId]) {
                const parentStep = initSteps.find((item) => item.id === step[WizardConstants.parentId]);
                isOpen = stepOpenStatus[parentStep.id];
            }

            const liClassName = classNames(styles.step, {
                [styles.active]: step.currentIndex === currentStepIndex,
                [styles.notVisited]: !step.visited,
                [styles.hide]: !isOpen,
                [styles.indent]: !!step[WizardConstants.parentId]
            });
            return (
                <li className={step.isParentStep ? styles.stepParentTitle : liClassName} key={step.id}>
                    <button
                        className={styles.navigationButton}
                        onClick={() => handleSteps(step)}
                        disabled={!step.visited && !step.isParentStep}
                        type="button"
                    >
                        {translator(step.title)}
                    </button>
                    {step.isParentStep && <Icon icon={stepOpenStatus[step.id] ? 'gw-keyboard-arrow-down' : 'gw-keyboard-arrow-right'} />}
                </li>
            );
        });
    };

    return (
        <div className={styles.wizardSidebarWrapper}>
            <h3 className={styles.sideBarTitle}>{translator(wizardTitle)}</h3>
            <div role="navigation" aria-label={translator(wizardTitle)}>
                <ul className={styles.stepsNav}>
                    {renderSteps()}
                </ul>
            </div>
        </div>
    );
}

WizardSidebar.propTypes = {
    customMessageModal: PropTypes.shape({})
};
WizardSidebar.defaultProps = {
    customMessageModal: {}
};
export default WizardSidebar;
