


import React from 'react';
import { IMRiggersCoveragePage } from 'wni-capability-quoteandbind-im-react';


const IMRiggersCoverageChangePage = (props) => {
    return <IMRiggersCoveragePage {...props} isPolicyChange />
};
export default IMRiggersCoverageChangePage

