import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import moment from 'moment';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniVendorComparsionService } from 'wni-capability-quote';
import { WniClueService, WniMVRService, WniPriorCarrierService, WniChangeConflict } from 'wni-capability-gateway';
import { WniSxsChangeService } from 'wni-capability-policychange';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';
import { IncidentPopupComponent, ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import {
    PolicyChangeUtil,
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    OOSUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    PAIncidentsAndReportsMessage as messages,
    PAIncidentsAndReportsStatic as staticMessage,
    PAIncidentsAndReportsMetadata as metadata
} from 'gw-capability-quoteandbind-pa-react';

import { IconButton, CurrencyField } from '@jutro/legacy/components';

function PAIncidentsAndReportsChangePage(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        markFollowingWizardStepsUnvisited,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const useAuthenticationData = useAuthentication();
    const { authHeader } = useAuthenticationData;
    const { onValidate, isComponentValid } = useValidation(
        'IncidentsAndReportsPage'
    );
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // policy claims
    const [vendorPolicyClaims, updateVendorPolicyClaims] = useState([]);
    const [vendorClaimsMsg, updateVendorClaimsMsg] = useState('');
    // carrier
    const [manuallyPriorCarrier, updateManuallyPriorCarrier] = useState([]);
    const [vendorPriorCarrier, updateVendorPriorCarrier] = useState([]);
    // clue
    const [manuallyClue, updateManuallyClue] = useState([]);
    const [vendorClueReports, updateVendorClueReports] = useState([]);
    const [clueCompare, setClueCompare] = useState(false);
    const [vendorClueMsg, updateVendorClueMsg] = useState('');
    // mvr
    const [manuallyMVR, updateManuallyMVR] = useState([]);
    const [vendorMVR, updateVendorMVR] = useState([]);
    const [mvrCompare, setMvrCompare] = useState(false);
    const [vendorMVRMsg, updateVendorMVRMsg] = useState('');
    // warning msg
    const [validationIssues, updateValidationIssues] = useState([]);

    // click the next button to trigger the behavior
    const [showMsg, updateShowMsg] = useState(false);

    // error msg
    const [hasErrorMsg, updateHasErrorIssuesMsg] = useState(false);

    const [covPaidNewSet, updateCovPaidNewSet] = useState('');

    const [clueStatus, updateClueStatus] = useState('');

    const [addBtnStatus, updateAddBtnStatus] = useState(true);

    const setWizardDataToDraft = useCallback((policyChangeDataDTOVM) => {
        _.set(policyChangeDataDTOVM, 'status', 'Draft');
        markFollowingWizardStepsUnvisited();
    }, [markFollowingWizardStepsUnvisited]);

    useEffect(() => {
        const {
            lobData: {
                personalAuto: {
                    clueEnter_Ext: clueEnter,
                    mvrEnter_Ext: mvrEnter,
                    priorCarrierEnter_Ext: priorCarrierEnter,
                    clueReport_Ext: clueReport,
                    mvrReport_Ext: mvrReport,
                    priorCarrierReport_Ext: priorCarrierReport,
                    policyClaimsReport_Ext: policyClaimsReport,
                    // isMvrCompare_Ext: isMvrCompare,
                    // isClueCompare_Ext: isClueCompare，
                    coverables: {
                        drivers: driversReport
                    }
                }
            }
        } = submissionVM.value;
        // To be replaced by
        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        updateManuallyClue(clueEnter);
        updateVendorClueReports(clueReport);
        updateManuallyMVR(mvrEnter);
        updateVendorMVR(mvrReport);
        updateManuallyPriorCarrier(priorCarrierEnter);
        if (!_.isUndefined(priorCarrierReport)) {
            updateVendorPriorCarrier(priorCarrierReport);
        } else {
            updateVendorPriorCarrier([]);
        }
        updateVendorPolicyClaims(policyClaimsReport);
        setClueCompare(isClueCompare);
        setMvrCompare(isMvrCompare);
        if (isClueCompare) {
            if (clueReport.length > 1) {
                updateVendorClueMsg(`Prior Losses ${clueReport.length} Records`);
            } else {
                updateVendorClueMsg(`Prior Losses ${clueReport.length} Record`);
            }
        } else {
            updateVendorClueMsg('Prior Losses 0 Record');
        }

        if (isMvrCompare) {
            if (mvrReport.length > 1) {
                updateVendorMVRMsg(`Violation ${mvrReport.length} Records`);
            } else {
                updateVendorMVRMsg(`Violation ${mvrReport.length} Record`);
            }
        } else {
            updateVendorMVRMsg('Violation 0 Record');
        }

        if (policyClaimsReport.length > 1) {
            updateVendorClaimsMsg(`Policy Claims ${policyClaimsReport.length} Records`);
        } else {
            updateVendorClaimsMsg(`Policy Claims ${policyClaimsReport.length} Record`);
        }

        // eslint-disable-next-line consistent-return
        // if we have new driver data in the policy change then the manual enter add btn is enable.
        _.forEach(driversReport, (reportData) => {
            const basedOnFlag = reportData.basedOnFlag_Ext;
            if (!basedOnFlag) {
                updateAddBtnStatus(false);
                return false;
            }
            updateAddBtnStatus(true);
        });
    }, [authHeader, submissionVM]);

    useEffect(() => {
        // const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        // if ((isClueCompare || isMvrCompare) && !_.isEmpty(covPaidNewSet)) {
        if (!_.isEmpty(covPaidNewSet) || !_.isEmpty(clueStatus)) {
            WniVendorComparsionService.getPolicyChangeIncidentErrorsAndWarnings(submissionVM.jobID.value, authHeader)
                .then((response) => {
                    const backendIssues = ErrorsAndWarningsUtil.getValidationIssues(response) || [];
                    const allValidationIssues = _.uniqBy(backendIssues, 'reason');
                    updateValidationIssues(allValidationIssues);
                    if (allValidationIssues.length > 0) {
                        allValidationIssues.forEach((issue) => {
                            if (issue.type === 'error') {
                                updateHasErrorIssuesMsg(true);
                                return false;
                            }
                            updateHasErrorIssuesMsg(false);
                        });
                        updateShowMsg(true);
                    } else {
                        updateHasErrorIssuesMsg(false);
                    }
                });
            updateClueStatus('');
            updateCovPaidNewSet('');
        }
    }, [authHeader, submissionVM, covPaidNewSet, clueStatus]);

    const showModalFn = async (modalVM, modelProps) => {
        /**
         * policy change only display new driver in the list
         */
        const manualDrivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value');
        let drivers = [];
        if (modalVM.value.basedOnFlag_Ext) {
            drivers = manualDrivers.map((driver) => {
                return {
                    code: driver.publicID,
                    name: driver.displayName,
                };
            });
            if (modelProps.title === messages.AddPriorLossesEntry.defaultMessage || modelProps.title === messages.EditPriorLossesEntry.defaultMessage || modelProps.title === messages.ViewPriorLossesEntry.defaultMessage) {
                drivers = IncidentsUtil.addRegularDrivers(drivers, messages);
            }
        } else {
            _.forEach(manualDrivers, (driver) => {
                if (!driver.basedOnFlag_Ext) {
                    drivers.push({
                        code: driver.publicID,
                        name: driver.displayName,
                    });
                }
            });
        }

        let policyDrivers;
        if (modelProps.title === messages.ViewPriorLossesVendor.defaultMessage || modelProps.title === messages.EditPriorLossesVendor.defaultMessage) {
            policyDrivers = await WniClueService.getPolicyDriverNameList('PC', submissionVM.jobID.value, authHeader)
                .then((driverNames) => {
                    const names = [];
                    _.forEach(driverNames, (policyDriverName) => {
                        names.push({
                            code: policyDriverName,
                            name: policyDriverName
                        });
                    });
                    return names;
                });
        }
        const isRequiredForIssuance = false;
        const componentProps = {
            title: modelProps.title,
            actionType: modelProps.type,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            authHeader: authHeader,
            actionBtnLabel: translator(messages.ModalOk),
            cancelBtnLabel: translator(messages.ModalCancel),
            modalVM,
            modelProps: modelProps,
            drivers: drivers,
            policyDrivers: policyDrivers,
            displayStatus: submissionVM.value.status,
            quotedId: submissionVM.jobID.value,
            comingType: 'PC',
            wniClueService: WniClueService,
            isRequiredForIssuance: isRequiredForIssuance
        };

        return modalApi.showModal(<IncidentPopupComponent {...componentProps} />);
    };
    const handleAddEditFn = (type, handleType, data, dtoService) => {
        const { saveService, updateService, updateTableData } = dtoService;
        let submitService = updateService;
        if (type === 'View') {
            return false;
        }
        if (type === 'Add') {
            submitService = saveService;
        }
        if (type === 'Edit') {
            submitService = updateService;
        }
        if (handleType === 'userClue') {
            submitService('PC', submissionVM.jobID.value, data, authHeader).then(
                (result) => {
                    setWizardDataToDraft(submissionVM);
                    if (type === 'Edit') {
                        updateClueStatus('checkMsg');
                    }
                    updateTableData(result);
                    _.set(submissionVM, 'lobData.personalAuto.clueEnter_Ext', result);
                }
            );
        }
        if (handleType === 'userMVR') {
            submitService(
                'PC',
                submissionVM.jobID.value,
                data.driverId,
                data,
                authHeader
            ).then((result) => {
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.mvrEnter_Ext', result);
            });
        }
        if (handleType === 'vendorClue') {
            submitService(
                'PC',
                submissionVM.jobID.value,
                data,
                authHeader
            ).then((result) => {
                setWizardDataToDraft(submissionVM);
                if (type === 'Edit') {
                    updateCovPaidNewSet('checkMsg');
                }
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.clueReport_Ext', result);
            });
        }
        if (handleType === 'vendorMVR') {
            submitService(
                'PC',
                submissionVM.jobID.value,
                data,
                authHeader
            ).then((result) => {
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.mvrReport_Ext', result);
            });
        }
        if (handleType === 'userPriorCarrier') {
            submitService('PC', submissionVM.jobID.value, data, authHeader).then((result) => {
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.priorCarrierEnter_Ext', result);
            });
        }
        if (handleType === 'vendorPriorCarrier') {
            submitService('PC', submissionVM.jobID.value, data, authHeader).then((result) => {
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.priorCarrierReport_Ext', result);
            });
        }
        return false;
    };
    const handleUserClue = () => {
        return {
            ...staticMessage.userClue,
            dtoService: {
                saveService: WniClueService.saveClue,
                updateService: WniClueService.updateClue,
                deleteService: WniClueService.deleteClue,
                updateTableData: updateManuallyClue,
            },
        };
    };
    const handleVendorClue = () => {
        return {
            ...staticMessage.vendorClue,
            dtoService: {
                updateService: WniClueService.updateClueReport,
                updateTableData: updateVendorClueReports,
            },
        };
    };
    const handleUseMVR = () => {
        return {
            ...staticMessage.userMvr,
            dtoService: {
                saveService: WniMVRService.saveMvr,
                updateService: WniMVRService.updateMvr,
                deleteService: WniMVRService.deleteMvr,
                updateTableData: updateManuallyMVR,
            },
        };
    };
    const handleVendorMVR = () => {
        return {
            ...staticMessage.vendorMvr,
            dtoService: {
                updateService: WniMVRService.updateMVRReport,
                updateTableData: updateVendorMVR,
            },
        };
    };

    const handleUserPriorCarrier = () => {
        return {
            ...staticMessage.userPriorCarrier,
            dtoService: {
                saveService: WniPriorCarrierService.savePriorCarrier,
                updateService: WniPriorCarrierService.updatePriorCarrier,
                deleteService: WniPriorCarrierService.deletePriorCarrier,
                updateTableData: updateManuallyPriorCarrier
            }
        };
    };

    const handleVendorPriorCarrier = () => {
        return {
            ...staticMessage.vendorPriorCarrier,
            dtoService: {
                updateService: WniPriorCarrierService.updateReportPriorCarrier,
                updateTableData: updateVendorPriorCarrier
            }
        };
    };

    const handleRowItem = (e, rowProps, type, initialModal = {}) => {
        e.preventDefault();
        const { item, handleType } = rowProps;

        let modalProps;
        switch (handleType) {
            case 'userClue':
                modalProps = handleUserClue();
                break;
            case 'vendorClue':
                modalProps = handleVendorClue();
                break;
            case 'userMVR':
                modalProps = handleUseMVR();
                break;
            case 'vendorMVR':
                modalProps = handleVendorMVR();
                break;
            case 'userPriorCarrier':
                modalProps = handleUserPriorCarrier();
                break;
            case 'vendorPriorCarrier':
                modalProps = handleVendorPriorCarrier();
                break;
            default:
                modalProps = {};
                break;
        }

        const {
            labelMap, title, dtoPath, dtoService
        } = modalProps;
        let modalVM = {};
        if (item) {
            modalVM = viewModelService.create(item, 'pc', dtoPath);
        } else {
            modalVM = viewModelService.create(initialModal, 'pc', dtoPath);
        }
        const modelInfo = {
            labelMap: labelMap,
            type: type,
            title: `${type} ${title}`,
        };
        showModalFn(modalVM, modelInfo)
            .then((data) => {
                handleAddEditFn(type, handleType, data, dtoService);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    };

    const onColumnDesCell = useCallback(
        (item, index, property, type) => {
            const { typeKey } = property;
            let flag;
            if (type === 'mvr') {
                flag = mvrCompare;
            }
            const classNames = flag && item.flag ? 'highlight' : '';
            if (item.source === 'chips') {
                return (
                    <div className={classNames}>{`${item.descriptionForChips}`}</div>
                );
            }
            const val = translator({ id: `${typeKey}.${item.description}` });
            return <div className={classNames}>{`${item.description ? val : ''}`}</div>;
        },
        [mvrCompare]
    );

    const onColumnCell = useCallback(
        (item, index, property, type) => {
            const { path, typeKey } = property;
            let flag;
            if (type === 'clue') {
                flag = clueCompare;
            }
            if (type === 'mvr') {
                flag = mvrCompare;
            }
            if (type === 'priorCarrier') {
                flag = false;
            }
            if (type === 'policyClaims') {
                flag = false;
            }
            const classNames = flag && item.flag ? 'highlight' : '';
            if (item[path] && item[path].year) {
                return (
                    <div className={classNames}>{WniDateUtil.formatDateWithPattern(item[path])}</div>
                );
            }
            if (item[path] && item[path].currency) {
                return (
                    <CurrencyField
                        id={`currency_${index}`}
                        value={item[path]}
                        readOnly
                        hideLabel
                        showOptional={false}
                    />
                );
            }
            const val = typeKey
                ? translator({ id: `${typeKey}.${item[path]}` })
                : item[path];
            return (
                <div className={classNames}>{`${item[path] ? val : ''}`}</div>
            );
        },
        [clueCompare, mvrCompare]
    );

    useEffect(() => {
        WniTableRowUtil.setTableRowClass('error');
    }, [onColumnCell]);

    const handleDeleteItem = (e, rowProps, handleType) => {
        e.preventDefault();
        const { item } = rowProps;
        modalApi.showConfirm({
            status: 'info',
            title: messages.ModaldeleteMsg,
            icon: 'gw-error-outline',
            message: '',
            confirmButtonText: messages.ModalOk,
            cancelButtonText: messages.ModalCancel,
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            if (handleType === 'userClue') {
                WniClueService.deleteClue('PC', submissionVM.jobID.value, item.publicId, authHeader)
                    .then((res) => {
                        setWizardDataToDraft(submissionVM);
                        updateClueStatus('checkMsg');
                        updateManuallyClue(res);
                        _.set(submissionVM, 'lobData.personalAuto.clueEnter_Ext', res);
                    });
            }
            if (handleType === 'userMVR') {
                WniMVRService.deleteMvr('PC', submissionVM.jobID.value, item.driverId, item.publicId, authHeader)
                    .then((res) => {
                        setWizardDataToDraft(submissionVM);
                        updateManuallyMVR(res);
                        _.set(submissionVM, 'lobData.personalAuto.mvrEnter_Ext', res);
                    });
            }
            if (handleType === 'userPriorCarrier') {
                WniPriorCarrierService
                    .deletePriorCarrier('PC', submissionVM.jobID.value, item.publicId, authHeader)
                    .then((res) => {
                        setWizardDataToDraft(submissionVM);
                        updateManuallyPriorCarrier(res);
                        _.set(submissionVM, 'lobData.personalAuto.priorCarrierEnter_Ext', res);
                    });
            }
        }).catch(() => {
            // do nothing when close the popup
            _.noop();
        });
    };
    const getActioncolumnFn = (item, index, handleType, readOnly) => {
        const rowProps = {
            item: item,
            index: index,
            handleType,
        };
        if (handleType === 'vendorMVR') {
            if (item.earsFlag) {
                return (
                    <>
                        <IconButton
                            id={`view${handleType}${index}`}
                            icon="gw-visibility"
                            iconColor="dark"
                            size="medium"
                            onClick={(e) => handleRowItem(e, rowProps, 'View')}
                        />
                        {!readOnly && (
                            <IconButton
                                id={`delete${handleType}${index}`}
                                icon="gw-delete"
                                iconColor="dark"
                                size="medium"
                                onClick={(e) => handleDeleteItem(e, rowProps, handleType)
                                }
                            />
                        )}
                    </>
                );
            }
        }
        let classNames = '';
        if (handleType === 'vendorClue') {
            classNames = item.isCompLoss && 'warningIcon';
        }
        let disabled = false;
        if (handleType === 'userClue' || handleType === 'userMVR' || handleType === 'userPriorCarrier') {
            if (item.basedOnFlag_Ext) {
                disabled = true;
            } else {
                disabled = false;
            }
        }
        if (handleType === 'vendorClue' || handleType === 'vendorMVR' || handleType === 'vendorPriorCarrier') {
            if (item.basedOnFlag_Ext) {
                disabled = true;
            } else {
                disabled = false;
            }
        }
        return (
            <>
                <IconButton
                    id={`view${handleType}${index}`}
                    icon="gw-visibility"
                    iconColor="dark"
                    className={classNames}
                    size="medium"
                    onClick={(e) => handleRowItem(e, rowProps, 'View')}
                />
                <IconButton
                    id={`edit${handleType}${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    className={classNames}
                    disabled={disabled}
                    size="medium"
                    onClick={(e) => handleRowItem(e, rowProps, 'Edit')}
                />
                {!readOnly && (
                    <IconButton
                        id={`delete${handleType}${index}`}
                        icon="gw-delete"
                        iconColor="dark"
                        disabled={disabled}
                        size="medium"
                        onClick={(e) => handleDeleteItem(e, rowProps, handleType)
                        }
                    />
                )}
            </>
        );
    };

    const showChangeConflictsModal = useCallback(
        (modalVM) => {
            const baseVM = modalVM.baseData;
            const jobID = modalVM.jobID.value;
            const policyNumber = modalVM.policyNumber.value;
            const componentProps = {
                title: 'Change Conflicts',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'Submit',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                baseVM,
                jobID,
                policyNumber,
            };
            return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
        },
        [authHeader]
    );

    const getInfoValidationMsg = () => {
        const msg = [{
            type: 'info',
            reason: messages.CreditAuth1.defaultMessage
        },
        {
            type: 'info',
            reason: messages.CreditAuth2.defaultMessage
        }];
        return msg;
    };

    /* const openTab = () => {
        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        if (isClueCompare || isMvrCompare) {
            return 'vendorInfo';
        }
        return 'userEnteredInfo';
    }; */

    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const goToNext = async () => {
        setLoadingMask(true);
        if (submissionVM.value.status === 'Draft') {
            /**
             * this service is only for Draft to invoke.
             */
            submissionVM.value = await WniSxsChangeService.quote(submissionVM.jobID.value, authHeader);
        }
        setLoadingMask(false);
        return submissionVM;
    };

    const handleConflict = useCallback(async () => {
        const conflictRes = await WniChangeConflict.getChangeConflictData(submissionVM.jobID.value, authHeader);
        if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
            setLoadingMask(false);
            const newSubmissionVM = await showChangeConflictsModal(submissionVM)
                .then(goToNext)
                .catch(() => {
                    _.noop();
                    return false;
                });
            return newSubmissionVM;
        }
        return goToNext();
    }, [submissionVM]);

    const onNext = useCallback(async () => {
        // const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        if (!showMsg) {
            setLoadingMask(true);
            const response = await WniVendorComparsionService.getPolicyChangeIncidentErrorsAndWarnings(submissionVM.jobID.value, authHeader);
            const backendIssues = ErrorsAndWarningsUtil.getValidationIssues(response) || [];
            const allValidationIssues = _.uniqBy(backendIssues, 'reason');
            updateValidationIssues(allValidationIssues);
            allValidationIssues.forEach((issue) => {
                if (issue.type === 'error') {
                    updateHasErrorIssuesMsg(true);
                    return false;
                }
                updateHasErrorIssuesMsg(false);
            });
            if (allValidationIssues.length > 0) {
                if (!showMsg) {
                    updateShowMsg(true);
                    setLoadingMask(false);
                    return false;
                }
                return handleConflict();
            }
        }
        return handleConflict();
    }, [submissionVM, showMsg]);

    const getSubUserEnterStates = () => {
        const userEnterStates = [];
        if (manuallyClue.length > 0) {
            userEnterStates.push('userEnteredPriorLossesCard');
        }
        if (manuallyMVR.length > 0) {
            userEnterStates.push('userEnteredViolationCard');
        }
        if (manuallyPriorCarrier.length > 0) {
            userEnterStates.push('userEnteredPriorCarrierCard');
        }
        return userEnterStates;
    };

    const getSubVendorStates = () => {
        const vendorStates = [];
        if (vendorClueReports.length > 0) {
            vendorStates.push('vendorPriorLossesCard');
        }
        if (vendorMVR.length > 0) {
            vendorStates.push('vendorViolationCard');
        }
        if (vendorPriorCarrier.length > 0) {
            vendorStates.push('PriorCarrierCard');
        }
        if (vendorPolicyClaims.length > 0) {
            vendorStates.push('PolicyClaimsCard');
        }
        return vendorStates;
    };

    const filterManuallyData = (data, type) => {
        const path = type === 'Clue' ? 'surChargeApply' : 'chargeableApply';
        const manuallyHeader = _.get(messages, `manually${type}Header`);

        return IncidentsUtil.filterManuallyData({
            data,
            type,
            path,
            policyType: _.get(submissionVM, 'value.originPolicyType_Ext'),
            translator,
            headerMessage: manuallyHeader

        });
    };
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        // MVR
        userAddedMVRTable: {
            // visible: manuallyMVR.length > 0,
            // data: manuallyMVR,
            ...filterManuallyData(manuallyMVR, 'MVR').manuallyData
        },
        reportedMVRTable: {
            visible: mvrCompare,
            data: vendorMVR,
        },
        // clue
        priorLossCLUETable: {
            visible: clueCompare,
            data: vendorClueReports,
        },
        userAddedPriorLossTable: {
            // visible: manuallyClue.length > 0,
            // data: manuallyClue,
            ...filterManuallyData(manuallyClue, 'Clue').manuallyData
        },
        // carrier
        reportedPriorCarrierTable: {
            visible: vendorPriorCarrier.length > 0,
            data: vendorPriorCarrier,
        },
        // policy claims
        reportedPolicyClaimsTable: {
            visible: vendorPolicyClaims.length > 0,
            data: vendorPolicyClaims
        },
        userAddedPriorCarrierTable: {
            visible: manuallyPriorCarrier.length > 0,
            data: manuallyPriorCarrier
        },
        /* accordionInfo: {
            defaultOpenedId: openTab()
        }, */
        vendorPriorLossesCard: {
            header: vendorClueMsg
        },
        userEnteredPriorLossesCard: {
            header: filterManuallyData(manuallyClue, 'Clue').header,
            // header: manuallyClue.length > 1 ? `Prior Losses ${manuallyClue.length} Records` : `Prior Losses ${manuallyClue.length} Record`
        },
        userEnteredViolationCard: {
            header: filterManuallyData(manuallyMVR, 'MVR').header,
            // header: manuallyMVR.length > 1 ? `Violation ${manuallyMVR.length} Records` : `Violation ${manuallyMVR.length} Record`
        },
        vendorViolationCard: {
            header: vendorMVRMsg
        },
        PriorCarrierCard: {
            header: vendorPriorCarrier.length > 1 ? `Prior Carrier ${vendorPriorCarrier.length} Records` : `Prior Carrier ${vendorPriorCarrier.length} Record`
        },
        userEnteredPriorCarrierCard: {
            header: manuallyPriorCarrier.length > 1 ? `Prior Carrier ${manuallyPriorCarrier.length} Records` : `Prior Carrier ${manuallyPriorCarrier.length} Record`
        },
        PolicyClaimsCard: {
            header: vendorClaimsMsg
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues
                .concat(wizardErrorsAndWarnings)
                .concat(
                    OOSUtil.getOOSSliceDatesWarning(
                        OOSUtil.getOOSSliceDatesString(
                            _.get(submissionVM.value, 'oossliceDates_Ext', []),
                            _.get(submissionVM.value, 'baseData.effectiveDate')
                        ),
                        translator
                    )
                ),
            visible: !_.isEmpty(validationIssues)
            || wizardErrorsAndWarnings.length > 0
            || OOSUtil.getOOSSliceDatesString(
                _.get(submissionVM.value, 'oossliceDates_Ext', []),
                _.get(submissionVM.value, 'baseData.effectiveDate')
            ).length > 0,
            scrollToIssues: true,
        },
        infoValidationMessages: {
            validationIssues: getInfoValidationMsg()
        },
        userEnteredSubInfo: {
            accordionStates: getSubUserEnterStates()
        },
        vendorSubInfo: {
            accordionStates: getSubVendorStates()
        },
        addPriorlossBtn: {
            disabled: addBtnStatus
        },
        addUserIncidentBtn: {
            disabled: addBtnStatus
        },
        addCarrierBtn: {
            disabled: true
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            // clue
            getVendorClueActionFn: (item, index) => getActioncolumnFn(item, index, 'vendorClue', 'readOnly'), // vendor clue: view
            handleClueAddItem: (e) => handleRowItem(e, { handleType: 'userClue' }, 'Add', { source: 'userentered' }), // user clue: add
            getUserClueActionFn: (item, index) => getActioncolumnFn(item, index, 'userClue'), // user clue: edit/view
            // mvr
            getVendorMVRActionFn: (item, index) => getActioncolumnFn(item, index, 'vendorMVR', 'readOnly'), // vendor mvr: view
            handleMvrAddItem: (e) => handleRowItem(e, { handleType: 'userMVR' }, 'Add'), // user mvr: add
            getUserMVRActionFn: (item, index) => getActioncolumnFn(item, index, 'userMVR'), // user Mvr: edit/view
            // carrier
            getVendorPriorCarrierFn: (item, index) => getActioncolumnFn(item, index, 'vendorPriorCarrier', 'readOnly'), // vendor carrier: view
            handlePriorCarrierAddItem: (e) => handleRowItem(e, { handleType: 'userPriorCarrier' }, 'Add'), // user carrier: add
            getUserPriorCarrierFn: (item, index) => getActioncolumnFn(item, index, 'userPriorCarrier'), // vender carrier: edit/vuew
            // common
            handleDeleteItem: handleDeleteItem, // user clue/mvr/carrier: delete
            handleRowItem: handleRowItem,
            onColumnCell: onColumnCell,
            onColumnDesCell: (item, index, property) => onColumnDesCell(item, index, property, 'mvr'),
            onColumnClueCell: (item, index, property) => onColumnCell(item, index, property, 'clue'),
            onColumnMvrCell: (item, index, property) => onColumnCell(item, index, property, 'mvr'),
            onColumnPriorCarrierCell: (item, index, property) => onColumnCell(item, index, property, 'priorCarrier'),
            onColumnPolicyClaimsCell: (item, index, property) => onColumnCell(item, index, property, 'policyClaims')
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
        },
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid || hasErrorMsg}
            skipWhen={PolicyChangeUtil.getSkipRatedQuotedFn()}
            alwaysCallOnNext
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PAIncidentsAndReportsChangePage.propTypes = wizardProps;
export default PAIncidentsAndReportsChangePage;
