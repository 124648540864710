import React, {
    useEffect,
    useContext,
} from "react";
import _ from 'lodash'
import { ValueType } from "../../../../util/CAScheduleItemsUtil";
import CoverageUtil from '../../../../util/CACoverageUtil'
import SingleClauseContext from '../../../../context/CASingleClauseContext'
import CAComplexSchedulePopupPropertyInfoDropdown from "./components/CAComplexSchedulePopupPropertyInfoDropdown";
import CAComplexSchedulePopupPropertyInfoTextArea from "./components/CAComplexSchedulePopupPropertyInfoTextArea";
import CAComplexSchedulePopupPropertyInfoInteger from "./components/CAComplexSchedulePopupPropertyInfoInteger";
import CAComplexSchedulePopupPropertyInfoInputText from "./components/CAComplexSchedulePopupPropertyInfoInputText";


const CAComplexSchedulePopupPropertyInfoField = (props) => {

    const {
        propertyInfo,
        openedScheduleItem,
        openedScheduleItemIndex,
        updateSubmissionVMToServer,
        onValidate,
        showErrors,
        setIsEditing,
    } = props

    const {
        clauseCode,
    } = useContext(SingleClauseContext)

    const {
        id,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo
    const {
        itemData: {
            [id]: scheduleItemDataDTO
        }
    } = openedScheduleItem
    
    useEffect(() => {
        const scheduleItemValidationID = `${clauseCode}_scheduleItem_${openedScheduleItemIndex}_${id}`
        if (onValidate) {
            onValidate(!CoverageUtil.isScheduledItemDataInvalid(scheduleItemDataDTO, propertyInfo), scheduleItemValidationID);
        }
        return () => {
            if (onValidate) {
                onValidate(true, scheduleItemValidationID);
            }
        }
    }, [clauseCode, id, onValidate, openedScheduleItemIndex, propertyInfo, scheduleItemDataDTO])

    if (_.isNil(scheduleItemDataDTO) || _.get(scheduleItemDataDTO, 'visible_Ext') === false) {
        return null
    }

    const commmonProps = {
        propertyInfo,
        openedScheduleItemIndex,
        openedScheduleItem,
        updateSubmissionVMToServer,
        showErrors,
        setIsEditing,
    }

    switch (schedulePropertyInfoType) {
        case ValueType.Option:
        case ValueType.AdditionalInsured:
        case ValueType.AdditionalInterest:
        case ValueType.ForeignKey:
        case ValueType.ForeignKeyWithOptionLabels:
        case ValueType.PolicyContact:
        case ValueType.TypeKey:
            return <CAComplexSchedulePopupPropertyInfoDropdown
                {...commmonProps}
            />
        case ValueType.TextArea:
            return <CAComplexSchedulePopupPropertyInfoTextArea
                {...commmonProps}
            />
        case ValueType.Shorttext:
        case ValueType.String:
            return <CAComplexSchedulePopupPropertyInfoInputText
                {...commmonProps}
            />
        // case ValueType.Direct:
        // case ValueType.BigDecimal:
        //     return <ScheduleItemEditableCellBigDecimal
        //         {...commonEditableCellComponentProps}
        //     />
        case ValueType.Integer:
        case ValueType.IntegerRange:
        case ValueType.AutoNumber:
            return <CAComplexSchedulePopupPropertyInfoInteger
                {...commmonProps}
            />
        // case ValueType.MONEY_EXT:
        //     return <ScheduleItemEditableCellCurrency
        //         {...commonEditableCellComponentProps}
        //     />
        // case ValueType.DateTime:
        //     return <ScheduleItemEditableCellDate
        //         {...commonEditableCellComponentProps}
        //     />
        // case ValueType.Date:
        //     return <ScheduleItemEditableCellLocalDateObj
        //         {...commonEditableCellComponentProps}
        //     />
        // case ValueType.Bit:
        // case ValueType.Boolean:
        //     return <ScheduleItemEditableCellBoolean
        //         {...commonEditableCellComponentProps}
        //     />
        default:
            return <div>Unsupport type editable cell</div>
    }
}

export default CAComplexSchedulePopupPropertyInfoField