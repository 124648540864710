
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLUnderwritingQuestionsPage } from 'wni-capability-quoteandbind-gl-react';

function GLUnderwritingQuestionsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLUnderwritingQuestionsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLUnderwritingQuestionsChangePage.propTypes = GLUnderwritingQuestionsPage.propTypes;
GLUnderwritingQuestionsChangePage.defaultProps = GLUnderwritingQuestionsPage.defaultProps;

export default GLUnderwritingQuestionsChangePage;