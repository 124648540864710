import React from 'react';
// import _ from 'lodash';
// import PropTypes from 'prop-types';
// import NewQuotePageClassImpl from './NewQuotePage.class';
import NewQuotePageFunctionImpl from './NewQuotePage.function';

/**
 * Refactoring Notice: to be removed and replaced by NewQuotePage.function.jsx in R2.
 * @param {object} props
 * @returns {object}
 */
function NewQuotePageWrapper(props) {
    // return <NewQuotePageClassImpl {...props} />;
    return <NewQuotePageFunctionImpl {...props} />;
}

NewQuotePageWrapper.propTypes = {};
NewQuotePageWrapper.defaultPropTypes = {};

export default NewQuotePageWrapper;
