import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _, { update } from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { BreakpointTrackerContext, Flex } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { RTVehicleService } from 'wni-capability-quoteandbind-rt';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ConfigUtil, WindowUtil } from 'wni-portals-util-js';
import metadata from './RTCustomEquipment.metadata.json5';

import { Button, CurrencyField } from '@jutro/legacy/components';

// const basePath = 'customEquipment';

function RTCustomEquipment(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        basePath,
        vm,
        viewModelService,
        translator
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const intl = useContext(IntlContext);
    const [initVM, updateInitVM] = useState(viewModelService.clone(vm));
    const [currentRow, updateCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const {
        onValidate,
        isComponentValid,
        invalidFields
    } = useValidation('RTCustomEquipment');

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [invalidFields]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'uid') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'customEquipmentTable');
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow, initVM]);

    const updateVM = (currentVM) => {
        const uid = _.get(currentVM.value, 'uid');
        const allData = _.get(initVM.value, basePath, []);
        const currentIndex = allData.findIndex((item) => item.uid === uid);
        const newVehicleVM = viewModelService.clone(initVM);
        _.set(newVehicleVM.value, `${basePath}[${currentIndex}]`, currentVM.value);
        return newVehicleVM;
    };

    const syncVM = (currentVM) => {
        updateCurrentRow(currentVM);
        if(!currentVM) {
            return false;
        }
        const newVM = updateVM(currentVM);
        updateInitVM(newVM);
    };

    const viewAndEdit = (row) => {
        const childrenVM = _.get(initVM, `${basePath}.children`);
        const currentVM = childrenVM.find((item) => item.value.uid === row.uid);
        updateCurrentRow(currentVM);
    };

    const removeDataRow = () => {
        const tableData = _.get(initVM.value, basePath, []);
        selection.forEach((id) => {
            _.remove(tableData, (item) => item.uid === id)
        });
        _.set(initVM.value, basePath, tableData);
        updateInitVM(initVM);
        updateSelection([]);
        updateCurrentRow(null);
    };

    const addDataRow = () => {
        const { _dtoName, _xCenter } = initVM[basePath];
        const newCurrentRow = viewModelService.create({
            uid: ConfigUtil.getUuid()
        }, _xCenter, _dtoName);
        const newRow = initVM[basePath].pushElement(newCurrentRow);
        syncVM(newRow)
    };

    const renderCell = (row, index, {path, typeKey}) => {
        const typekeyValue = row[path] ? translator({id: `${typeKey}.${row[path]}` }) : '-';
        return (
            <span className={`rowId${row.uid}`} >{typekeyValue}</span>
        )
    };
    const cancelRow = () => {
        const { _dtoName, _xCenter } = initVM[basePath];
        const newCurrentRow = viewModelService.create({
            uid: _.get(currentRow, 'value.uid')
        }, _xCenter, _dtoName);
        const newVM = updateVM(newCurrentRow);
        updateInitVM(newVM);
        syncVM(null);
    };

    const saveRow = () => {
        if(!isComponentValid) {
            handleValidation();
            return false;
        }
        syncVM(null)
    };

    const isEveryValid = () => {
        const allVMs = _.get(initVM, `${basePath}.children`);
        return allVMs.every((itemVM) => itemVM.aspects.valid && itemVM.aspects.subtreeValid);
    };
    const handleSave = () => {
        if(!isEveryValid()) {
            return false;
        }
        onResolve(_.get(initVM.value, basePath, []));
    };
    const writeValue = (value, path) => {
        if(currentRow){
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if( isCurrencyField && _.get(value, 'amount') === ''){ 
                _.set(currentRow, path, undefined);
            } else {
                _.set(currentRow, path, value);
            }
            syncVM(currentRow);
        }  
    };
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left'
        },
        removeButton: {
            disabled: selection.length < 1
        },
        addButton: {
            disabled: currentRow
        },
        customEquipmentTable: {
            data: _.get(initVM.value, basePath),
            onSelectionChange: (rows) => updateSelection(rows),
            rowIdPath: 'uid',
            selectedRows: selection
        },
        equipmentDetailContainer: {
            visible: !!currentRow
        },
        additionalInfo: {
            visible: _.get(currentRow, 'value.equipmentType') === 'other'
        }
        
    };
    const resolvers = {
        resolveCallbackMap: {
            removeDataRow,
            addDataRow,
            renderCell,
            viewAndEdit
        },
        resolveComponentMap: {
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrideProps
        );
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="customEquipment">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={currentRow}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                {
                    !currentRow ? (
                        <>
                            <Button onClick={onReject} type="outlined">
                                {cancelBtnLabel || translator(commonMessages.cancelModel)}
                            </Button>
                            <Button onClick={handleSave} disabled={!isEveryValid()}>
                                {actionBtnLabel || translator(commonMessages.ok)}
                            </Button>
                        </>
                    ) : (
                        <Button onClick={saveRow} disabled={!isEveryValid()}>
                            Save & Close
                        </Button>
                    )
                }
            </ModalFooter>
        </ModalNext>
        
    );
}
RTCustomEquipment.defaultProps = {
    size: 'md'
};
export default RTCustomEquipment;
