
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './OperatorLicenseInfo.metadata.json5';
import messages from '../../WALOperatorsPage.messages';
// import styles from './OperatorLicenseInfo.module.scss';

/**
 * @param {object} props
 * @returns {object} React Component
 */
function OperatorLicenseInfo(props) {
    const {
        operatorVM,
        onValueChange,
        showErrors,
        baseState,
        isReadOnly
    } = props;

    const {
        isLicensedDriver,
        unLicensedDriverReason
    } = operatorVM.value;

    const translator = useTranslator();

    useEffect(() => {
    }, []);

    const isShowLicensedStatus = useCallback(() => {
        if (isLicensedDriver) {
            return true;
        }
        if (!isLicensedDriver
            && (unLicensedDriverReason === 'permitonly'
                || unLicensedDriverReason === 'idOnly')) {
            return true;
        }
        return false;
    }, [isLicensedDriver, unLicensedDriverReason]);

    const licenseStatusClassName = useCallback(() => {
        if (!isLicensedDriver
            && (unLicensedDriverReason === 'permitonly'
                || unLicensedDriverReason === 'idOnly')) {
            return 'subIndentSection';
        }
        return 'indentSection'
    }, [isLicensedDriver, unLicensedDriverReason]);

    const licenseStatusLabel = useCallback(() => {
        if (!isLicensedDriver && unLicensedDriverReason === 'permitonly') {
            return messages.permitStatus;
        }
        if (!isLicensedDriver && unLicensedDriverReason === 'idOnly') {
            return messages.IDStatus;
        }

        return messages.licensedStatus
    }, [isLicensedDriver, unLicensedDriverReason]);

    const licenseNumberLabel = useCallback(() => {
        if (!isLicensedDriver && unLicensedDriverReason === 'permitonly') {
            return messages.permitNumber;
        }
        if (!isLicensedDriver && unLicensedDriverReason === 'idOnly') {
            return messages.IDNumber;
        }

        return messages.licensedNumber
    }, [isLicensedDriver, unLicensedDriverReason]);

    const licenseStateLabel = useCallback(() => {
        if (!isLicensedDriver && unLicensedDriverReason === 'permitonly') {
            return messages.permitState;
        }
        if (!isLicensedDriver && unLicensedDriverReason === 'idOnly') {
            return messages.IDState;
        }

        return messages.licensedState
    }, [isLicensedDriver, unLicensedDriverReason]);

    const isShowAgeFirstLicensed = useCallback(() => {
        if (isLicensedDriver) {
            return true;
        }
        if (!isLicensedDriver
            && (unLicensedDriverReason === 'expired'
                || unLicensedDriverReason === 'cancelled'
                || unLicensedDriverReason === 'surrendered'
                || unLicensedDriverReason === 'revokedsuspended')) {
            return true;
        }
        return false;
    }, [isLicensedDriver, unLicensedDriverReason]);

    const handleIsLicensedDriver = useCallback((val, path) => {
        onValueChange(val, path);
        if (val) {
            onValueChange(null, 'unLicensedDriverReason');
            onValueChange(null, 'unlicensedDriverExplain');
        }
        if (!val) {
            onValueChange(null, 'licenseStatus');
            onValueChange(null, 'licenseNumber');
            onValueChange(null, 'licenseState');
            onValueChange(null, 'ageFirstLicensed');
        }
    }, [onValueChange]);

    const handleUnLicensedDriverReason = useCallback((val, path) => {
        onValueChange(val, path);
        if (val !== 'permitonly' && val !== 'idOnly') {
            onValueChange(null, 'licenseStatus');
            onValueChange(null, 'licenseNumber');
            onValueChange(null, 'licenseState');
        }
        if (val !== 'expired'
            && val !== 'cancelled'
            && val !== 'surrendered'
            && val !== 'revokedsuspended') {
            onValueChange(null, 'ageFirstLicensed');
        }
        if (val !== 'willNeverLicensed' && val !== 'other') {
            onValueChange(null, 'unlicensedDriverExplain');
        }
    }, [onValueChange]);
    
    const getDropDownOptions = (dropPath) => {
        const options = _.get(operatorVM, `${dropPath}.aspects.availableValues`);
        if (options) {
            return options.map((item) => {
                const availableValuesList = {
                    code: item.code,
                    name: translator({ id: item.name })
                };
                return availableValuesList;
            });
        }
    };

    const getDropDownValue = useCallback((dropPath) => {
        const values = _.get(operatorVM, `${dropPath}.value`);
        return values ? {
            code: values.code,
            name: translator({
                id: values.name
            })
        } : values;
    }, [translator, operatorVM]);
    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            layout: 'reversed',
            showRequired: true,
            readOnly: isReadOnly
        },
        isLicensedDriver: {
            onValueChange: handleIsLicensedDriver
        },
        unLicensedDriverReason: {
            visible: isLicensedDriver === false,
            onValueChange: handleUnLicensedDriverReason
        },
        unlicensedDriverExplain: {
            visible: isLicensedDriver === false
                && (unLicensedDriverReason === 'willNeverLicensed'
                || unLicensedDriverReason === 'other')
        },
        licenseStatus: {
            visible: isShowLicensedStatus(),
            label: licenseStatusLabel(),
            className: licenseStatusClassName()
        },
        licenseNumber: {
            visible: isShowLicensedStatus(),
            label: licenseNumberLabel(),
            className: licenseStatusClassName()
        },
        licenseState: {
            visible: isShowLicensedStatus(),
            className: licenseStatusClassName(),
            label: licenseStateLabel(),
            availableValues: getDropDownOptions('licenseState'),
            value: getDropDownValue('licenseState'),
            defaultValue: ((_.filter(getDropDownOptions('licenseState'), (v) => v.code === baseState))[0] || {}).code,
        },
        ageFirstLicensed: {
            visible: isShowAgeFirstLicensed()
        }
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            operatorVM,
            id,
            path,
            overrideProps
        );
    };
    //---------
    // return MetadataContent(metadata.componentContent, override, resolvers);
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={operatorVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
}

OperatorLicenseInfo.propTypes = {
    operatorVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    baseState: PropTypes.string,
    isReadOnly: PropTypes.bool
};

OperatorLicenseInfo.defaultProps = {
    operatorVM: null,
    onValueChange: _.noop,
    showErrors: false,
    baseState: undefined,
    isReadOnly: false,
};

export default OperatorLicenseInfo;
