import React, {useEffect, useCallback} from 'react';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import IMCoverageUtil from '../../util/IMCoverageUtil';
import CoverageContext from '../../context/IMCoverageContext';
import IMSingleClauseComponentVM from "../IMSingleClauseComponentVM";
import IMClausesComponentVM from "../IMClausesComponentVM";

const CommonClausesCard = (props) => {

    const {
        id: validationID,
        coveragePartClauses,
        setCoveragePartClauses,
        clauses,
        coverageFormPath,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateCoverageFormClausesService,
        generateUpdatedClausesDTO,
        visibleOfTableTitle,
        visibleOfDivider,
        scheduleTableTitle
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate, validationID])

    const changeClauses = useCallback(
        (value, changedPath, clausesPath, originClauses) => {
            const newClauses = IMCoverageUtil.setClauseValue(originClauses, clausesPath, value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, clausesPath, newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, setCoveragePartClauses, setIsEditing]
    );

    const changeCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, coverageFormPath, clauses);
        },
        [changeClauses, coverageFormPath, clauses]
    );


    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        const coveragePartClausesToUpdate = generateUpdatedClausesDTO(coverageFormPath, newCoveragePartClauses);
        setLoadingMask(true)
        const coveragePartClausesFromServer = await updateCoverageFormClausesService(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [generateUpdatedClausesDTO, coverageFormPath, setLoadingMask, updateCoverageFormClausesService, jobID, sessionUUID, authHeader, setCoveragePartClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);


    const changeClausesAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeCoverages, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    return (
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer,
                        visibleOfTableTitle: visibleOfTableTitle,
                        visibleOfDivider: visibleOfDivider,
                        scheduleTableTitle: scheduleTableTitle
                    }}
                >
                    <IMClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeClausesAndSync}
                        onChangeClause={changeCoverages}
                        onValidate={onValidate}
                        showAmount={false}
                        path={coverageFormPath}
                        value={clauses}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            IMSingleClauseComponentVM: IMSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                </CoverageContext.Provider>
            </div>
    )
}

export default CommonClausesCard