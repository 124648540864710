import { defineMessages } from 'react-intl';

export default defineMessages({
    hoPayments: {
        id: 'endorsement.ho.directives.templates.ho-payment.Payments',
        defaultMessage: 'Payments'
    },
    hoPaymentPayInFull: {
        id: 'endorsement.ho.directives.templates.ho-payment.Pay In Full',
        defaultMessage: 'Pay In Full',
    },
    hoPaymentRedistribute: {
        id: 'endorsement.ho.directives.templates.ho-payment.Redistribute',
        defaultMessage: 'Redistribute',
    },
    hoNone: {
        id: 'endorsement.ho.directives.templates.ho-payment.None',
        defaultMessage: 'None'
    },
    policychangePaymentPaymentMessage: {
        id: 'endorsement.ho.directives.templates.ho-payment.The premium will be redistributed on upcoming invoices',
        defaultMessage: 'The premium will be redistributed on upcoming invoices'
    },
    policychangePAPaymentPaymentMessage: {
        id: 'endorsement.pa.directives.templates.pa-payment.The premium will be redistributed on upcoming invoices',
        defaultMessage: 'The premium will be redistributed on upcoming invoices'
    },
    policyChangePaymentPremiumDecreases: {
        id: 'endorsement.ho.directives.templates.ho-payment.No payment needed. The premium will decrease and the change will be reflected in the upcoming invoices.',
        defaultMessage: 'No payment needed. The premium will decrease and the change will be reflected in the upcoming invoices'
    },
    policyChangePAPaymentPremiumDecreases: {
        id: 'endorsement.pa.directives.templates.pa-payment.No payment needed. The premium will decrease and the change will be reflected in the upcoming invoices.',
        defaultMessage: 'No payment needed. The premium will decrease and the change will be reflected in the upcoming invoices'
    },
    policyChangePaymentNoChangeInPremium: {
        id: 'endorsement.ho.directives.templates.ho-payment.No payment needed. There is no change in the premium.',
        defaultMessage: 'No payment needed. There is no change in the premium.'
    },
    paPayments: {
        id: 'endorsement.pa.directives.templates.pa-payment.Payments',
        defaultMessage: 'Payments'
    },
    paBuy: {
        id: 'endorsement.common.views.actions.Buy',
        defaultMessage: 'Buy'
    },
    confirm: {
        id: 'endorsement.common.views.payment.Confirm',
        defaultMessage: 'Confirm'
    },
    paymentTypeChecking: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Checking',
        defaultMessage: 'Checking'
    },
    paymentTypeSavings: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Savings',
        defaultMessage: 'Savings'
    },
    checking: {
        id: 'quoteandbind.wc7.views.qualification.Checking',
        defaultMessage: 'Checking'
    },
    savings: {
        id: 'quoteandbind.wc7.views.qualification.Savings',
        defaultMessage: 'Savings'
    },
    errorBindingTransaction: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.paymentCtrl.Error binding transaction',
        defaultMessage: 'Error binding transaction'
    },
    checkError: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.paymentCtrl.Please check for errors and try again',
        defaultMessage: 'Please check for errors and try again'
    },
    creditCardNumber: {
        id: 'platform.paymentdetails.credit-card-auto-details.Credit card number',
        defaultMessage: 'Credit Card Number',
    }
});
