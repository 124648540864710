import React, { useCallback, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { CAVehicleService } from 'wni-capability-quoteandbind-ca';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './ApplyFilterModal.metadata.json5';
import { getVehicleFilterSetMaps } from '../../Util/CAVehicle.util'

import { Button } from '@jutro/legacy/components';

function ApplyFilterModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        vehicleTypeMaps,
        vehicleFilterSetMaps,
        vehicleLocationMaps,
        vehicleSuspendMaps,
        filterData,
        updateFilterData,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        } = {},
        useDependenciesData,
    } = props;
    const [modelVM, updateModelVM] = useState(_.cloneDeep(filterData));

    const vehicleLocationsOptions = vehicleLocationMaps.map((location) => {
        return {
            code: location.publicID,
            name: location.locationName
        }
    });

    const vehicleFilterSetOptions = getVehicleFilterSetMaps(vehicleFilterSetMaps);
    const vehicleSuspendOptions = getVehicleFilterSetMaps(vehicleSuspendMaps);

    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;

    const handleSave = async() => {
        setLoadingMask(true);
        const serviceData = {
            ...modelVM
        };
        const res = await CAVehicleService.handleApplyFilter(jobID, sessionUUID, serviceData, authHeader);
        setLoadingMask(false);
        updateFilterData(modelVM);
        onResolve(res);
    };

    const handleCancel = () => {
        updateFilterData(filterData);
        onReject()
    };

    const writeValue = (value, path) => {
        const newData = _.cloneDeep(modelVM);
        _.set(newData, path, value);
        if(path === 'typeFilterChoice') {
            _.set(newData, 'filterValue', null);
        }
        updateModelVM(newData);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        viewFilter: {
            availableValues: vehicleTypeMaps
        },
        filterBy: {
            availableValues: vehicleFilterSetOptions
        },
        make: {
            visible: _.get(modelVM, 'typeFilterChoice') === 'Make',
        },
        model: {
            visible: _.get(modelVM, 'typeFilterChoice') === 'Model',
        },
        suspended: {
            visible: _.get(modelVM, 'typeFilterChoice') === 'Suspended',
            availableValues: vehicleSuspendOptions
        },
        location: {
            visible: _.get(modelVM, 'typeFilterChoice') === 'Location',
            availableValues: vehicleLocationsOptions
        },
    };
    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            modelVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={title} onClose={handleCancel} />
            <ModalBody id="applyFilterModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={modelVM}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'between',
                    },
                }}
            >
                <Button onClick={handleCancel} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

ApplyFilterModal.propTypes = {};
ApplyFilterModal.defaultProps = {
    size: 'md',
};
export default ApplyFilterModal;
