import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpPolicyDetailsService'), method, data, additionalHeaders);
}

export default class CPPolicyDetailsService {

    static savePolicyDetailsData(quoteID, sessionUUID, cpPolicyDetailsData, quoteBaseData, authHeader) {
        return processSubmission('saveCPPolicyDetailsData', [quoteID, sessionUUID, cpPolicyDetailsData, quoteBaseData], authHeader);
    }

}