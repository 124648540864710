import React, {
    useContext,
    useMemo,
    useState
} from 'react';
import _ from 'lodash';
// import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import messages from '../../WALWatercraftsPage.messages';
import watercraftMetadata from './WatercraftInfoMetadata/WatercraftInformation.metadata.json5';
import motorMetadata from './WatercraftInfoMetadata/MotorInformation.metadata.json5';
import trailerMetadata from './WatercraftInfoMetadata/TrailerInformation.metadata.json5';
import { isFieldVisible, getAvailableList } from '../../util/Watercraft.util';

const metadataMap = {
    watercraft: watercraftMetadata,
    motor: motorMetadata,
    trailer: trailerMetadata
}

function WatercraftInformation(props) {
    const {
        id,
        title,
        sectionType,
        basePath,
        currentRow,
        onValueChange,
        onValidate,
        showErrors,
        isRequiredForIssuance,
        itemToBeInsured,
        isReadOnly,
        extendProps: {
            baseData
        }
    } = props;

    const vm = currentRow[basePath];
 
    const {
        primarilyUsedCounty_option: primarilyUsedCountyOptions = []
    } = vm.value;
    const translator = useTranslator();
    
    const getAvailableValues = (path) => {
        const options = _.get(vm,  `${path}_options.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };

    const renderAppliesLabel = () => {
        return (
            <>
                <div>{translator(messages.selectedWatercraftApplies1)}</div>
                <div>{translator(messages.selectedWatercraftApplies2)}</div>
            </>
        )
    };

    const renderAppliesSecondaryLabel = () => {
        const applies = _.get(vm, 'value.selectedWatercraftApplies', []);
        if (applies.includes('loaned_to_others')) {
            return messages.selectedWatercraftAppliesWarningMsg
        };
        return null;
    }
    const getVisibleField = (fieldPath) => {
        return isFieldVisible(currentRow, fieldPath, basePath, baseData);
    };

    const writeValue = (value, path) => {
        onValueChange(value, `${basePath}.${path}`)
    }
    
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            layout: 'reversed',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        informationCard: {
            title
        },
        watercraftInformation: {
            visible: sectionType === 'watercraft'
        },
        motorInformation: {
            visible: sectionType === 'motor'
        },
        trailerInformation: {
            visible: sectionType === 'trailer'
        },
        selectedWatercraftUsages: {
            availableValues: getAvailableValues('selectedWatercraftUsages')
        },
        selectedWatercraftKepts: {
            availableValues: getAvailableValues('selectedWatercraftKepts')
        },
        selectedWatercraftApplies: {
            availableValues: getAvailableValues('selectedWatercraftApplies'),
            label: renderAppliesLabel(),
            secondaryLabel: renderAppliesSecondaryLabel()
        },
        watercraftUsedMonths: {
        },
        primarilyUsedCounty: {
            visible: getVisibleField('primarilyUsedCounty'),
            availableValues: getAvailableList(primarilyUsedCountyOptions),
            required: true
        },
        isThereExistingDamage: {
            // label: translator(messages.isThereExistingDamage, { sectionType })
        },
        watercraftKeptExplain: {
            visible: getVisibleField('watercraftKeptExplain')
        },
        hasMotorBeenModified: {
            visible: getVisibleField('hasMotorBeenModified')
        },
        hasMotorBeenModifiedExplain: {
            visible: getVisibleField('hasMotorBeenModifiedExplain')
        },
        isThereExistingDamageExplain: {
            visible: getVisibleField('isThereExistingDamageExplain')
        },
        coOwnerName: {
            visible: getVisibleField('coOwnerName')
        },
        hasPhysicalDamageLast12Months: {
            visible: getVisibleField('hasPhysicalDamageLast12Months')
        },
        hasAnyAdditionalInfoExplain: {
            visible: getVisibleField('hasAnyAdditionalInfoExplain')
        },
        isUsedForBusiPurposesExplain: {
            visible: getVisibleField('isUsedForBusiPurposesExplain')
        },
        hasAnyAdditionalInfo: {
            defaultValue: false,
        }
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };
    const newMetadata = metadataMap[sectionType].componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);

    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            newMetadata, vm, fieldId, fieldPath, overrides
        );
    };
    return (
        <div id={id}>
            <ViewModelForm
                uiProps={newMetadata}
                model={vm}
                overrideProps={overrides}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </div>
        
    );
}

export default WatercraftInformation;
