import React from 'react';
import { CPLocationsReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPLocationsChangeReadOnlyPage(props) {

    return (
        <CPLocationsReadOnlyPage {...props} />
    );

}

CPLocationsChangeReadOnlyPage.propTypes = CPLocationsReadOnlyPage.propTypes;
CPLocationsChangeReadOnlyPage.defaultProps = CPLocationsReadOnlyPage.defaultProps;

export default CPLocationsChangeReadOnlyPage;