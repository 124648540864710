import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useMemo, useCallback } from 'react';
import { useTranslator } from '@jutro/locale';

import messages from './AddressInputField.messages';
import metadata from './AddressInputField.metadata.json5';

function AddressInputField(props) {
    const translator = useTranslator();

    const {
        model,
        basePath,
        onValueChange: onAddressChange,
        onValidate,
        className,
        labelPosition,
        showRequired,
        showOptional,
        readOnly = false,
        disabled = false,
        showErrors,
        cityStateRequired = true,
        isWatercraft = false
    } = props;
    const addressVM = _.get(model, basePath);

    const writeValue = (value, path) => {
        onAddressChange(value, `${basePath}.${path}`);
    };

    const exactCityLable = useCallback(() => {
        const msg = isWatercraft ? messages.cityOrWater : messages.city
        return msg.defaultMessage
    },[isWatercraft])

    const cityProps = useMemo(() => {
        if(cityStateRequired){
            return {
                tooltip: translator(messages.unknowTips, {
                    what: exactCityLable().toLowerCase()
                }),
            }
        }
        return {}
    }, [cityStateRequired, exactCityLable, translator])

    const overrideProps = {
        '@field': {
            className: className,
            labelPosition: labelPosition,
            showRequired,
            showOptional,
            readOnly,
            disabled
        },
        city: {
            label: exactCityLable(),
            required: cityStateRequired,
            ...cityProps
        },
        state: {
            required: cityStateRequired
        }
    };

    const resolvers = {
        // resolveClassNameMap:
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            addressVM,
            id,
            path,
            overrideProps
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={addressVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default AddressInputField;
