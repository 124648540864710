import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class PolicyService {
    static getAccountPolicySummaries(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicySummaries', [], authHeader);
    }

    static getAccountPolicyDetails(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicyDetails', [policyNumber], authHeader);
    }

    static removeDocument(publicID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('removePolicyDocument'), 'removeDocument', [publicID], authHeader);
    }

    static uploadDocument(document, documentMetadata, authHeader = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('uploadPolicyDocument'), document, documentMetadata, authHeader);
    }

    static getDocumentUploadToken(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('generateUploadToken'), 'generateUploadToken', params, additionalHeaders);
    }
}
