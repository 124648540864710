import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import HOCommonOptionalCoverages from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOCommonOptionalCoverages/HOCommonOptionalCoverages';
import HOOtherOptionalCoverages from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOOtherOptionalCoverages/HOOtherOptionalCoverages';
import HOExclusions from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOExclusions/HOExclusions';
import DPCoveragesConfig from "wni-capability-quoteandbind-dp-react/pages/DPCoverages/DPCoverages.config";
import DPPrimaryCoverages from "wni-capability-quoteandbind-dp-react/pages/DPCoverages/CoveragesSection/DPPrimaryCoverages/DPPrimaryCoverages";
import { Accordion, AccordionCard } from '@jutro/legacy/components';

const DwellingPropertyCoverages = (props) => {

    const {
        coverageSubmissionVM
    } = props

    const selectedVersion = _.first(_.get(coverageSubmissionVM, 'value.lobData.dwellingProperty.offerings'));
    const selectedVersionIndex = 0;

    const [accordionStates, setAccordionStates] = useState([
            'primaryCoverages',
            'commonOptionalCoverages'
    ]);

    useEffect(() => {
        const otherOptionalCoverages = _.get(selectedVersion, 'coverages.otherOptionalCoverages_Ext', []);
        const exclusions = _.get(selectedVersion, 'coverages.exclusion_Ext', []);
        if (otherOptionalCoverages.some((coverage) => coverage.selected)
            || exclusions.some((coverage) => coverage.selected)) {
                setAccordionStates([
                    'primaryCoverages',
                    'commonOptionalCoverages', 
                    'otherOptionalAndExclusionsCondations'
                ])
        }
    }, [selectedVersion])
    const commonAccordionCardProps = {
        chevron: true,
        className: 'coveragesAccordion',
        cardBodyClassName: 'accordionCardBody',
        cardHeadingClassName: 'coveragesAccordionHeader'
    }

    const commonCoverageProps = {
        selectedVersion,
        selectedVersionIndex,
        submissionVM: coverageSubmissionVM,
        lobName: 'dwellingProperty',
        coveragesConfig: DPCoveragesConfig,
        labelPosition: 'left',
        isEditable: false
    }
    return <Accordion
        accordionStates={accordionStates}
        onUpdateAccordionStates= {(ids) => setAccordionStates(ids)}
        closeOthers={false}
        className= "accordion-wizardTitle"
    >
       <AccordionCard
            id='primaryCoverages'
            title='Primary Coverages'
            {...commonAccordionCardProps}
        >
            <DPPrimaryCoverages 
                {...commonCoverageProps}
            />
        </AccordionCard>
        <AccordionCard
            id='commonOptionalCoverages'
            title='Common Optional Coverages'
            {...commonAccordionCardProps}
        >
            <HOCommonOptionalCoverages
                {...commonCoverageProps}
            />
        </AccordionCard>
        <AccordionCard
            id='otherOptionalAndExclusionsCondations'
            title = 'Other Optional Coverages & Exclusions'
            {...commonAccordionCardProps}
        >
            <HOOtherOptionalCoverages
                {...commonCoverageProps}
            />
            <HOExclusions
                {...commonCoverageProps}
            />
        </AccordionCard>
    </Accordion>
}

export default DwellingPropertyCoverages