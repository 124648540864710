
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';

import { CPPModifiersReadOnlyPage } from 'wni-capability-quoteandbind-cpp-react';

function CPPModifiersChangeReadOnlyPage(props) {

    return (
        <CPPModifiersReadOnlyPage {...props} />
    );

}

CPPModifiersChangeReadOnlyPage.propTypes = CPPModifiersReadOnlyPage.propTypes;
CPPModifiersChangeReadOnlyPage.defaultProps = CPPModifiersReadOnlyPage.defaultProps;

export default CPPModifiersChangeReadOnlyPage;