/******************************************************
 *                                                    *
 *   EXAMPLE: Adding a new Expression Language Type   *
 *   Declare a new Expression Language Type           *
 *                                                    *
 ******************************************************/

const utils = require('wni-portals-viewmodel-js')

const { WniValidationUtil } = utils;
const validationFunctions = {
    type: WniValidationUtil
}



// Export Expression Types and Validation Functions
module.exports = {
    // add different types of aspects with or without expression language
    // NOTE you can override existing aspects by providing its name as the key for the object entry
    aspectFactory: {
        // myELAwareAspect: myELAwareAspect.create,
        // myaspect: () => myaspect
    },
    // Types
    // Key should match the backend package name for your Expression Object
    // Value should be your new Expression Language Type
    types: {
        // 'gw.api.example.ExampleExpressionLanguageType': exampleExpressionLanguageType,
        'wni.edge.aspects.validation.annotations.WniValidationUtil': validationFunctions
    },
    // Validations
    // Function name should match backend validation function
    validations: {
        // exampleArrLength,
    },
    validationMessagesToIgnore: [
        // validation messages that the view model surfaces that Jutro might duplicate

        // This field is required
        'displaykey.Edge.Web.Api.Model.NotNull',
        // Value entered must be a valid phone number
        'displaykey.Edge.Web.Api.Model.Phone'
    ]
}