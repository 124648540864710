import React from 'react';
import { CPPolicyDetailsReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPPolicyDetailsChangeReadOnlyPage(props) {

    return (
        <CPPolicyDetailsReadOnlyPage {...props} />
    );

}

CPPolicyDetailsChangeReadOnlyPage.propTypes = CPPolicyDetailsReadOnlyPage.propTypes;
CPPolicyDetailsChangeReadOnlyPage.defaultProps = CPPolicyDetailsReadOnlyPage.defaultProps;

export default CPPolicyDetailsChangeReadOnlyPage;