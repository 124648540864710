import { defineMessages } from 'react-intl';

export default defineMessages({
    ColdStorageLockerCoverage: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.ColdStorageLockerCoverage.Cold Storage Locker Coverage",
        "defaultMessage": "Cold Storage Locker Coverage"
    },
    OptionalCoverage: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.ColdStorageLockerCoverage.Optional Coverage",
        "defaultMessage": "Optional Coverage"
    },
    ChangeinTemperatureOrHumidityCoverage: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.ColdStorageLockerCoverage.Change in Temperature Or Humidity Coverage",
        "defaultMessage": "Change in Temperature Or Humidity Coverage"
    },
    CoverageExtensions: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.ColdStorageLockerCoverage.Coverage Extensions",
        "defaultMessage": "Coverage Extensions"
    },
    CoveredPremises: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.ColdStorageLockerCoverage.Covered Premises",
        "defaultMessage": "Covered Premises"
    }
});