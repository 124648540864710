
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { CAStateSpecificInfoService } from 'wni-capability-quoteandbind-ca';
import CAStateSpecificInfoUtil from '../../util/CAStateSpecificInfoUtil'
import metadata from './CAStateSpecificInfoDetail.metadata.json5';

function CAStateSpecificInfoDetail(props) {
    const {
        stateSpecificVM,
        onValidate,
        onValueChange,
        syncWizardData,
        syncWizardDataSnapshot,
        updateSubmissionVMForResponse,
        showErrors,
        isReadOnly,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;
    const {
        locationOptions = []
    } = stateSpecificVM.value;

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const detailsDisplayableDTO = _.get(stateSpecificVM.value, 'detailsDisplayableDTO')
    const hiredAutoDisplayableDTO = _.get(stateSpecificVM.value, 'hiredAutoDisplayableDTO')
    const nonOwedDisplayableDTO = _.get(stateSpecificVM.value, 'nonOwedDisplayableDTO')
    const noNeedUpdateImmediateField = ['NumberOfEmployees', 'NumberOfVolunteers', 'NumberOfPartners',  'ZipCode']

    const defaultMask = '#####-####';
    const [zipcodeMask, setZipcodeMask] = useState(defaultMask);
    const [zipcodeInValid, updateZipcodeInValid] = useState(false);

    const getRegValue = (value) => {
        if (!value) {
            return '';
        }
        let zipcode = _.split(value, /#/)[0];
        if(zipcode.length <= 6) {
            zipcode = zipcode.replace(/-/g, '');
        }
        return zipcode;
    };

    const getInputMaskUpdate = (reg) => {
        updateZipcodeInValid(true);
        setZipcodeMask(reg);
    };

    useEffect(() => {
        const zipcode = getDisplayableFieldValue('ZipCode', hiredAutoDisplayableDTO);
        if(isReadOnly && zipcode) {
            const zipcodeNum = getRegValue(zipcode);
            getInputMaskUpdate(zipcodeNum.replace(/[0-9]/g, '#'));
        }
    }, []);

    const writeValue = async(value, path) => {
        if(!value) {
            onValueChange(value, path);
            return false;
        }
        switch(path) {
            case "garageLocationId": 
                _.set(stateSpecificVM, path, value)
                _.set(stateSpecificVM.value, path, value)
                syncWizardData(stateSpecificVM);
                break;
            default:
                break;
        }
    }

    const getDisplayableFieldValue = useCallback((propertyName, displayable, isIntegerValue) => {
        if(displayable.length > 0) {
            const displayableValue = displayable
            .find((elt) => elt.propertyName === propertyName && elt.available);
            if(propertyName === 'ZipCode') {
                return _.get(displayableValue, 'fieldValue')
            }
            if(isIntegerValue) {
                return _.get(displayableValue, 'integerValue')
            }
            return _.get(displayableValue, 'rangeValue')
        }
    }, [detailsDisplayableDTO])

    const showNumberOfPartners = useCallback((propertyName, displayable) => {
        if(displayable.length > 0) {
            const displayableValue = displayable
            .find((elt) => elt.propertyName === propertyName);
            return _.get(displayableValue, 'available');
        }
    }, [detailsDisplayableDTO])

    const getDisplayableDropdownOptions = useCallback((propertyName, displayable) => {
        const displayableValue = displayable.find((elt) => elt.propertyName === propertyName && elt.available);
        if(displayableValue) {
            const options = displayableValue.rangeValueOptions
                .map((elt) => {
                    return {
                        code: elt,
                        name: elt
                    }
                });
            return options;
        }
        return [];
    }, [detailsDisplayableDTO])

    const onDisplayablesValueChange = useCallback(
        async (value, propertyName, displayablePath, isIntegerValue) => {
            const displayable = _.get(stateSpecificVM.value, displayablePath)
            setLoadingMask(true);
            const newStateSpecificVM = _.clone(stateSpecificVM);
            const index = displayable.findIndex((elt) => elt.propertyName === propertyName);
            const path = `${displayablePath}[${index}]`
            if(isIntegerValue) {
                _.set(newStateSpecificVM.value, `${path}.integerValue`, value);
            } else if(propertyName === 'ZipCode') {
                _.set(newStateSpecificVM.value, `${path}.fieldValue`, value);
            } else {
                _.set(newStateSpecificVM.value, `${path}.rangeValue`, value);
            }
            _.set(newStateSpecificVM.value, `${path}.updated`, true);
            // update to server
            if(!noNeedUpdateImmediateField.includes(propertyName)) {
                const res = await CAStateSpecificInfoService.updateStateInfo(jobID, sessionUUID, newStateSpecificVM.value, authHeader);
                updateSubmissionVMForResponse(res);
                const resStateInfo = _.get(res, 'castateInfos');
                const currentStateInfo = resStateInfo.find(elt => elt.publicID === newStateSpecificVM.value.publicID);
                _.set(newStateSpecificVM, 'value', currentStateInfo);
            }
            syncWizardData(newStateSpecificVM);
            setLoadingMask(false);
        },
        [detailsDisplayableDTO]);

    const zipcodeChangeFn = (value) => {
        if (value === null && zipcodeInValid) {
            return false;
        }
        const zipcodeNum = getRegValue(value);
        updateZipcodeInValid(false);
        onDisplayablesValueChange(zipcodeNum, 'ZipCode', 'hiredAutoDisplayableDTO')
    }

    const getLocationDropdownOptions = () => {
        if(locationOptions) {
            const generateOptions = locationOptions.map((location) => {
                return {
                    code: location.publicID,
                    name: location.displayName
                };
            });
            return generateOptions;
        } 
        return [];
    }


    //----------------------------------
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        hiredAutoCoverage: {
            value: getDisplayableFieldValue('HiredAuto', detailsDisplayableDTO),
            availableValues: getDisplayableDropdownOptions('HiredAuto', detailsDisplayableDTO),
            onValueChange: (value) => onDisplayablesValueChange(value, 'HiredAuto', 'detailsDisplayableDTO')
        },
        hiredAutoCoverageZipcode: {
            mask: zipcodeMask,
            visible: getDisplayableFieldValue('HiredAuto', detailsDisplayableDTO) === 'Yes',
            value: getDisplayableFieldValue('ZipCode', hiredAutoDisplayableDTO),
            onValueChange: zipcodeChangeFn,
            onFocus: () => {
                getInputMaskUpdate(defaultMask);
            },
            onBlur: () => {
                updateZipcodeInValid(true);
                const zipcode = getDisplayableFieldValue('ZipCode', hiredAutoDisplayableDTO)
                const zipcodeNum = getRegValue(zipcode);
                if (!zipcodeNum) {
                    getInputMaskUpdate('');
                    return false;
                }
                if(zipcodeNum.length === 5 || zipcodeNum.length === 10) {
                    getInputMaskUpdate(zipcodeNum.replace(/[0-9]/g, '#'));
                }
                return true;
            }
        },
        nonOwnedAutoCoverage: {
            value: getDisplayableFieldValue('NonOwnedAutoWanted', detailsDisplayableDTO),
            availableValues: getDisplayableDropdownOptions('NonOwnedAutoWanted', detailsDisplayableDTO),
            onValueChange: (value) => onDisplayablesValueChange(value, 'NonOwnedAutoWanted', 'detailsDisplayableDTO')
        },
        nonOwnedAutoLocation: {
            visible: getDisplayableFieldValue('NonOwnedAutoWanted', detailsDisplayableDTO) === 'Yes',
            availableValues: getLocationDropdownOptions()
        },
        nonOwnedAutoRiskType: {
            visible: getDisplayableFieldValue('NonOwnedAutoWanted', detailsDisplayableDTO) === 'Yes',
            value: getDisplayableFieldValue('NonOwnershipRiskType', nonOwedDisplayableDTO),
            availableValues: getDisplayableDropdownOptions('NonOwnershipRiskType', nonOwedDisplayableDTO),
            onValueChange: (value) => onDisplayablesValueChange(value, 'NonOwnershipRiskType', 'nonOwedDisplayableDTO')
        },
        nonOwnedAutoNumberOfEmployees: {
            visible: getDisplayableFieldValue('NonOwnedAutoWanted', detailsDisplayableDTO) === 'Yes',
            value: getDisplayableFieldValue('NumberOfEmployees', nonOwedDisplayableDTO, true),
            onValueChange: (value) => onDisplayablesValueChange(value, 'NumberOfEmployees', 'nonOwedDisplayableDTO', true)
        },
        nonOwnedAutoNumberOfVolunteers: {
            visible: getDisplayableFieldValue('NonOwnedAutoWanted', detailsDisplayableDTO) === 'Yes',
            value: getDisplayableFieldValue('NumberOfVolunteers', nonOwedDisplayableDTO, true),
            onValueChange: (value) => onDisplayablesValueChange(value, 'NumberOfVolunteers', 'nonOwedDisplayableDTO', true)
        },
        nonOwnedAutoNumberOfPartners: {
            visible: getDisplayableFieldValue('NonOwnedAutoWanted', detailsDisplayableDTO) === 'Yes' && showNumberOfPartners('NumberOfPartners', nonOwedDisplayableDTO),
            value: getDisplayableFieldValue('NumberOfPartners', nonOwedDisplayableDTO, true),
            onValueChange: (value) => onDisplayablesValueChange(value, 'NumberOfPartners', 'nonOwedDisplayableDTO', true)
        }
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            stateSpecificVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={stateSpecificVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

CAStateSpecificInfoDetail.propTypes = {

};
CAStateSpecificInfoDetail.defaultProps = {

};

export default CAStateSpecificInfoDetail;
