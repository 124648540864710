import React, { useEffect, useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import {
    ServiceErrorUtil,
    ValidationIssueUtil,
    UWIssueUtil,
    WindowUtil,
    DTOUtil,
    DocumentsUtil,
} from 'wni-portals-util-js';
import { WizardConstants} from 'wni-portals-config-js';
import {
    HOMultiQuoteChangeService,
    HOPaymentChangeService,
} from 'wni-capability-policychange-ho';
import {
    ModalDialogHelper,
    useProductsData,
    useLoadingMask,
} from 'wni-portals-util-react';
import { QuoteUnderwritingIssuesList, useWniModal } from 'wni-components-platform-react';
import {
    DocumentCheckedComponent,
    PaymentDetailEditComponent,
} from 'wni-capability-gateway-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as commonMessages } from 'wni-platform-translations';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { HOWizardPage as WizardPage } from 'wni-capability-quoteandbind-ho-react';
import styles from './HOPaymentChangePage.module.scss';
import metadata from './HOPaymentChangePage.metadata.json5';

/**
 * Hard lessons learned:
 * 1) Sibling properties are different objects, and
 * 2) DropdownSelect likely checks whether availableValues contains value by Object-Is.
 * @param {object} props 
 * @returns {function}
 */
function HOPaymentChangePage(props) {
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        // wizardStepToFieldMapping,
        // jumpTo: wizardJumpTo,
        // steps: wizardSteps,
        // updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    // const intl = useContext(IntlContext);
    // const viewModelService = useContext(ViewModelServiceContext);
    const modalApi = useWniModal();
    const { authHeader } = useAuthentication();
    const { getProductName } = useProductsData()

    const modalHelper = ModalDialogHelper(modalApi);

    const breakpoint = useContext(BreakpointTrackerContext);
    // const [showErrors, updateShowErrors] = useState(false);
    const {
        domainCompany,
    } = useDependencies(['loadingMask', 'domainCompany']);

    const {
        withServiceCallInProgress: withServiceInProgressUpdated,
    } = useLoadingMask();

    const [validationIssues, updateValidationIssues] = useState(undefined);

    // instance type: HOPaymentRetrieveDetailDTO
    const [paymentDetailsDTO, updatePaymentDetailsDTO] = useState(undefined);
    const [jobPaymentData, updateJobPaymentData] = useState(undefined);
    const [bindingDataDTO, updateBindingDataDTO] = useState(undefined);

    const [accountDocuments, updateAccountDocuments] = useState(undefined);
    const [docSubmission, updateDocSubmission] = useState(undefined);

    const {
        jobID,
        sessionUUID,
        //
        baseData: {
            accountHolder,
            primaryNamedInsured_Ext: primaryNamedInsured,
        },
        errorsAndWarnings: {
            underwritingIssues = [],
        } = {},
    } = submissionVM.value;

    
    const {
        [WizardConstants.policyChangePaymentData]: cachedPaymentData,
    } = wizardPageData;
    


    // const updatePaymentData = useCallback((newPaymentData) => {
    //     // updatePaymentDataInternal(newPaymentData);
    //     updateWizardPageData({
    //         [WizardConstants.policyChangePaymentData]: newPaymentData,
    //     });
    // }, []);

    // const withServiceInProgressUpdated = async (cb,
    //     serviceCallInvoker = true) => {
    //     updateServiceCallInProgress(serviceCallInvoker);
    //     let retval = null
    //     try {
    //         retval = await cb();
    //     } catch (e) {
    //         const errorMessage = ServiceErrorUtil.getErrorMessage(e);
    //         modalHelper.alert({ errorMessage });
    //     }
    //     updateServiceCallInProgress(false);
    //     return retval;
    // };

    // ref: AQPaymentPage
    const initDocSubmission = useCallback(() => {
        const tempSubmission = {};
        _.set(tempSubmission, 'policy.account.accountHolder', accountHolder);
        _.set(tempSubmission, 'value.baseData.primaryNamedInsured_Ext', primaryNamedInsured);
        updateDocSubmission(tempSubmission);
    }, []);

    // ref: AQPaymentPage
    const initDocumentsData = useCallback((documentWithProductDTOs) => {
        const newDocumentWithProductDTOs = [];
        _.each(documentWithProductDTOs, (documentWithProductDTO) => {
            const productCode = _.get(documentWithProductDTO, 'productCode');
            const newDocumentData = {
                productName: productCode ? getProductName(productCode) : _.get(documentWithProductDTO, 'productName'),
                jobNumber: _.get(documentWithProductDTO, 'jobNumber'),
                selectedVersion: _.get(documentWithProductDTO, 'selectedVersion_Ext'),
                errorsAndWarnings: {
                    underwritingIssues: _.get(documentWithProductDTO, 'underwritingIssues')
                }
            };
            const documents = _.get(documentWithProductDTO, 'documents');
            if (documents) {
                const retainInAgencyDocs = DocumentsUtil.getDocumentDataByVersion(documents,
                    DocumentsUtil.RETAIN_IN_AGENCY, _.get(documentWithProductDTO, 'selectedVersion_Ext'));
                if (retainInAgencyDocs) {
                    newDocumentData.retainInAgencyDocs = retainInAgencyDocs;
                    newDocumentData.retainInAgencySubTitle = {
                        id: 'quoteandbind.pa.views.pa-policy-info.Retain in Agency',
                        defaultMessage: 'Retain in Agency',
                    };
                }
                const submitToWesternNationalDocs = DocumentsUtil.getDocumentData(
                    documents,
                    DocumentsUtil.SUBMIT_TO_WESTERNNATIONAL
                );
                if (submitToWesternNationalDocs) {
                    newDocumentData.submitToWesternNationalDocs = submitToWesternNationalDocs;
                    newDocumentData.submitToWesternNationalSubTitle = translator(messages.westernNational, { domainName: `${domainCompany.domainName}` });
                }
            }
            newDocumentData.documents = [
                { data: newDocumentData.retainInAgencyDocs },
                { data: newDocumentData.submitToWesternNationalDocs }
            ];
            newDocumentWithProductDTOs.push(newDocumentData);
        });
        
        // updateAccountDocuments(newDocumentWithProductDTOs);
        updateAccountDocuments(newDocumentWithProductDTOs[1].documents);
    }, []);

    const defaultPaymentDataRetriever = useCallback(async () => {
        return HOPaymentChangeService.getPolicyChangePaymentData(jobID, sessionUUID, authHeader);
    }, [authHeader, jobID, sessionUUID]);

    const updatePaymentData = useCallback(async({
        // paymentDataRetriever = HOPaymentChangeService.getPolicyChangePaymentData
        paymentDataRetriever = defaultPaymentDataRetriever,
        //
        paymentDetailUpdater = _.identity,
    } = {}) => {

        // const newJobPaymentData = await paymentDataRetriever(jobID, sessionUUID, authHeader);
        const newJobPaymentData = await paymentDataRetriever();

        updateWizardPageData({
            [WizardConstants.policyChangePaymentData]: newJobPaymentData,
        });

        
        // =============================
        newJobPaymentData.paymentDetail = paymentDetailUpdater(newJobPaymentData.paymentDetail);

        // =============================
        const {
            documents, // WniDocumentWithProductDTO
            paymentDetail,
            bindData,
        } = newJobPaymentData;

        
        updateJobPaymentData(newJobPaymentData);
        //
        initDocumentsData(documents);
        updatePaymentDetailsDTO(paymentDetail);
        updateBindingDataDTO(bindData);
    }, [defaultPaymentDataRetriever, initDocumentsData, updateWizardPageData]);

    
    // useEffect(() => {
    //     setLoadingMask(isServiceCallInProgress);
    // }, [isServiceCallInProgress, setLoadingMask]);

    useEffect(() => {
        initDocSubmission();

        withServiceInProgressUpdated(async () => {
            // const cachedPaymentData = wizardPageData[WizardConstants.policyChangePaymentData];
            const retrievalParams = {};
            if (cachedPaymentData) {
                retrievalParams.paymentDataRetriever = () => cachedPaymentData;
            }
            await updatePaymentData(retrievalParams);
            // await updatePaymentData();
        });
    }, []);

    const onNext = useCallback(async () => {
        try {
            // const updateStatus = await HOPaymentChangeService.updatePaymentData_del(jobID, sessionUUID, paymentFormData, authHeader);

            // ========================================================
            // Set PhoneRequired_Ext on InvoiceStream PrimaryPayer to false
            // Somehow this field is missing value from server response.
            // A better solution might be needed later.
            _.set(paymentDetailsDTO, 'invoiceStreamPrimaryPayer.phoneRequired_Ext', false);
            // ========================================================
            await HOPaymentChangeService.updatePaymentData(jobID, sessionUUID, 
                paymentDetailsDTO, authHeader);
            
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
            return false;
        }
        // return submissionVM;

        // submissionVM.value = await HOMultiQuoteService.processQuote(
        // submissionVM.value = await HOMultiQuoteChangeService.processIssuance(
        const issuanceResponse = await HOMultiQuoteChangeService.processIssuanceWithoutWarning(
            jobID, sessionUUID, authHeader
        );
        submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, issuanceResponse);

        const {
            errorsAndWarnings: issuanceErrorsAndWarnings,
        } = submissionVM.value;

        //
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issuanceErrorsAndWarnings);
        updateValidationIssues(newValidationIssues);


        const hasValidationError = ValidationIssueUtil.hasValidationError(issuanceErrorsAndWarnings);
        const hasBlockingUWIssue = UWIssueUtil.hasBlockingOrRejectedUWIssue(issuanceErrorsAndWarnings);
        if (hasValidationError || hasBlockingUWIssue) {
            WindowUtil.scrollToWizardErrors();
            
            updateWizardSnapshot(submissionVM);
            return false;
        }

        return submissionVM;
    }, [jobID, sessionUUID, authHeader, submissionVM, paymentDetailsDTO, modalHelper, updateWizardSnapshot]);

    // const onInitBindData = useCallback(async () => {
    //     return withServiceInProgressUpdated(() => {
    //         return HOPaymentChangeService.getBindData(jobID, sessionUUID, authHeader)
    //     });
    // }, []);

    // const updateAutoPayEnrollmentForm = useCallback(async () => {
    //     return HOPaymentChangeService.refreshAutoPayEnrollmentFormAndGetPaymentData(jobID, sessionUUID, paymentDetailsDTO, authHeader);
    // }, [authHeader, jobID, paymentDetailsDTO, sessionUUID]);


    const onUpdatePaymentInstrument = useCallback(async (bankDetailsDTO) => {
        
        return withServiceInProgressUpdated(async () => {
            const newBankPublicID =  await HOPaymentChangeService.updatePaymentInstrument(jobID, sessionUUID, bankDetailsDTO, authHeader);

            // refresh bindData
            await updatePaymentData({
                // paymentDetailUpdater: (newPaymentDetails) => {
                //     const retval = {
                //         ...newPaymentDetails,
                //         //
                //         primaryPayer: paymentDetailsDTO.primaryPayer,
                //         payUsing: newBankPublicID,
                //     };
                //     return retval;
                // }
            });
            // await updatePaymentData({
            //     paymentDataRetriever: updateAutoPayEnrollmentForm,
            // });

            return newBankPublicID;
        });
    }, []);

    const onCreateOrUpdateBillingContact = useCallback(async(accountContactDTO) => {
        return withServiceInProgressUpdated(async () => {
            const newPrimaryPayer = await HOPaymentChangeService.createOrUpdateBillingContact(jobID, sessionUUID, accountContactDTO, authHeader);

            // refresh bindData
            await updatePaymentData({
                paymentDetailUpdater: (newPaymentDetails) => {
                    const retval = {
                        ...newPaymentDetails,
                        //
                        primaryPayer: newPrimaryPayer,
                        // payUsing: paymentDetailsDTO.payUsing,
                    };
                    return retval;
                },
            });

            return newPrimaryPayer;
            ;
        });
    }, []);

    const updatePaymentDetailDataModel = useCallback((newPaymentDetailsDTO) => {
        updatePaymentDetailsDTO(newPaymentDetailsDTO);
    }, []);

    // ================================================
    const generateOverrides = useCallback(() => {

        return {
            '@field': {
                labelPosition: 'left'
            },
            quoteUWIssues: {
                underwritingIssues,
                // issueFilter: _.stubTrue, // show Info Level UWI along with Blocking Level
            },
            paymentEditSection: {
                paymentDetailDataModel: paymentDetailsDTO,
                updatePaymentDetailDataModel,
                //
                //
                // rowData: {}, // to be removed
                //
                // fetchedAccountTableData: [], // to be removed
                // quoteTableData: [], // to be removed
                //
                // onInitBindData,
                // onInitBindData: () => bindData,
                bindData: bindingDataDTO,
                onUpdatePaymentInstrument,
                onCreateOrUpdateBillingContact,
                //
                // beforeAddNewPerson,
                // beforeAddNewBankAccount,
                // beforeAddNewPaymentMethod,
                //
                // afterAddNewPerson: _.noop,
                // afterAddNewBankAccount: _.noop,
                // afterAddNewPaymentMethod: _.noop,
                // afterAddMethodInitParams: {},
                breakpoint
            },
            documentSection: {
                visible: !_.isEmpty(accountDocuments),
                // history: history,
                submission: docSubmission,
                renderData: accountDocuments,
                showSendViaEmail: false,
                useJobNumber: true
            }
        };
    }, [bindingDataDTO, underwritingIssues, paymentDetailsDTO, updatePaymentDetailDataModel, 
        onUpdatePaymentInstrument, onCreateOrUpdateBillingContact, accountDocuments, docSubmission, breakpoint]);

    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            documentcheckedcomponent: DocumentCheckedComponent,
            paymentdetaileditcomponent: PaymentDetailEditComponent,
        }
    };

    // ================================
    if (!(jobPaymentData && paymentDetailsDTO)) {
        return null;
    }
    const overrideProps = generateOverrides();
    return (
        <WizardPage
            onNext={onNext}
            nextLabel={commonMessages.submitChange}
            //
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                // onModelChange={updatePaymentData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValueChange={writeValue}
                // resolveValue={readValue}
            />
        </WizardPage>
    );
}

HOPaymentChangePage.propTypes = wizardProps;
export default HOPaymentChangePage;
