import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLIMWizard from './FNOLIMWizard.pe';
import IMLossTypePage from './pages/LossType/FNOLIMLossTypePage.pe';
import IMIncidentsPage from './pages/IncidentDetail/FNOLIMIncidentsPage.pe';
import FNOLIMBusnessLocationPage from './pages/BusinessLocation/FNOLIMBusnessLocationPage.pe';

setComponentMapOverrides(
    {
        FNOLWCWizard: { component: 'FNOLIMWizard' },
        IMLossTypePage: { component: 'IMLossTypePage' },
        IMIncidentsPage: { component: 'IMIncidentsPage' },
        FNOLIMBusnessLocationPage: { component: 'FNOLIMBusnessLocationPage' },
    },
    {
        FNOLIMWizard,
        IMLossTypePage,
        IMIncidentsPage,
        FNOLIMBusnessLocationPage,
    }
);

export {
    FNOLIMWizard as default,
    IMLossTypePage as FNOLIMLossTypePage,
    IMIncidentsPage as FNOLIMIncidentDetailsPage,
    FNOLIMBusnessLocationPage
};
