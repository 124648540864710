import { defineMessages } from 'react-intl';

export default defineMessages({
    coveragesTitle: {
        "id": "quoteandbind.cp.wizard.step.commercialProperty page.ExclusionAndConditions",
        "defaultMessage": "Exclusions & Conditions"
    },
    AddExclusionsAndConditions: {
        "id": "quoteandbind.cp.wizard.step.commercialProperty page.AddExclusionAndConditions",
        "defaultMessage": "Add Exclusions and Conditions"
    },
});