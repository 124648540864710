import { defineMessages } from 'react-intl';

export default defineMessages({
    setupPaymentConfirmMessage: {
        id: 'billing.view.setup-autopay-confirmation.Payment will automatically be taken on the due date.',
        defaultMessage: 'Payment will automatically be taken on the due date.'
    },
    setupPaymentConfirmDue: {
        id: 'amp.bc3.directives.templates.autopay-summary.Next Draft Date',
        defaultMessage: 'Next Draft Date:'
    },
    setupPaymentConfirmForm: {
        id: 'billing.view.setup-autopay-confirmation.Please retain an Approval form signed by the Bank Account holder',
        defaultMessage: 'Please retain an Approval form signed by the Bank Account holder.'
    },
    autoPayRemoved: {
        id: 'billing.view.remove-autopay-message.Auto Payment setup removed',
        defaultMessage: 'Auto Payment setup removed'
    },
    removePaymentConfirmMessage: {
        id: 'billing.view.setup-autopay-confirmation.AutoPay setup removed.',
        defaultMessage: 'AutoPay setup removed.'
    },
    removeSuccessWithdrawMessage: {
        id: 'billing.view.setup-autopay-confirmation.Auto Payment setup removed withdraw.',
        defaultMessage: 'A pending withdrawal has already been sent to the policyholders bank and cannot be stopped for this scheduled withdrawal.'
    },
    setupAutomaticPaymentMessage: {
        id: 'billing.view.setup-autopay-confirmation.Enabling Automatic Payments',
        defaultMessage: 'Enabling Automatic Payments (AutoPay) will mean that, starting with the next payment, the current balance due on the customer’s account will be paid on the due date specified on the schedule, using the specific payment method. If an email address has been provided for this customer, they will receive a confirmation email when the AutoPay enrollment has been processed.'
    },
    returnToAccountSumamry: {
        id: 'billing.view.setup-autopay-confirmation.Return to Account Summary',
        defaultMessage: 'Return to Account Summary'
    },
    modifyAutomaticPayments: {
        id: 'billing.view.setup-autopay-confirmation.Modify Automatic Payments',
        defaultMessage: 'Modify Automatic Payments'
    },
    firstAutoDateMessage: {
        id: 'billing.view.setup-autopay-confirmation.The first automatic payment will be automatically taken on',
        defaultMessage: 'The first automatic payment will be automatically taken on '
    },
    accountHasBalanceDue: {
        id: 'billing.view.setup-autopay-confirmation.accountHasBalanceDue',
        defaultMessage: 'Please note, this account has a current balance due of ${balanceDue}, which will not be automatically deducted and '
    },
    accountHasBalanceDueMust: {
        id: 'billing.view.setup-autopay-confirmation.must be paid by another method',
        defaultMessage: 'must be paid by another method'
    },
    agencyShouldRetain: {
        id: 'billing.view.setup-autopay-confirmation.The agency must retain an approval form signed by the bank account holder',
        defaultMessage: 'The agency must retain an approval form signed by the bank account holder.'
    }
});
