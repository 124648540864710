import { defineMessages } from 'react-intl';

// To be refactored
export default defineMessages({
    handlePreemptionMessagePart1: {
        id: 'customer.common.wizard.step.paysuccess.Handle Preemption Message Part1',
        defaultMessage: 'The changes made on this endorsement will automatically apply to change request #'
    },
    handlePreemptionMessagePart2: {
        id: 'customer.common.wizard.step.paysuccess.Handle Preemption Message Part2',
        defaultMessage: '. You can '
    },
    handlePreemptionMessageDeleteJobLink: {
        id: 'customer.common.wizard.step.paysuccess.Handle Preemption Message Delete Job Link',
        defaultMessage: 'delete'
    },
    handlePreemptionMessagePart3: {
        id: 'customer.common.wizard.step.paysuccess.Handle Preemption Message Part3',
        defaultMessage: ' {jobID} if the change request is no longer needed.'
    },
    deleteQuote: {
        id: 'custom.common.gateway.directives.JobSummary.Delete Quote?',
        defaultMessage: 'Delete Quote?'
    },
    sureDeletePolicyChange: {
        id: 'custom.common.gateway.views.endorsement.Are you sure you want to delete this policy transaction? All changes will be lost.',
        defaultMessage: 'Are you sure you want to delete this policy transaction? All changes will be lost.'
    },
    // driverDetails: {
    //     id: 'customer.common.policychange.views.pa-confirmation.Driver Details',
    //     defaultMessage: 'Driver Details'
    // },
    contactInformationDetails: {
        id: 'customer.common.policychange.views.ho-confirmation.Contact Information Details',
        defaultMessage: 'Contact Information Details'
    },
    changeSuccess: {
        id: 'customer.common.policychange.views.confirmation.gateway.This policy has been changed',
        defaultMessage: 'This policy has been changed.'
    },
    cancellationMessage: {
        id: 'customer.common.wizard.step.policychange.paysuccess.cancellationMessage',
        defaultMessage: 'Please click to continue with the Cancellation Transaction {startCancellation}'
    },
    startCancellation: {
        id: 'customer.common.wizard.step.policychange.paysuccess.startCancellation',
        defaultMessage: 'Start Policy Cancellation'
    },
    primaryInsuredName: {
        id: 'customer.common.views.confirmation.Primary Insured Name',
        defaultMessage: 'Primary Insured Name'
    },
    phoneNumber: {
        id: 'customer.common.views.confirmation.Phone Number',
        defaultMessage: 'Phone Number'
    },
    emailAddress: {
        id: 'customer.common.views.confirmation.Email Address',
        defaultMessage: 'Email Address'
    },
    transactionNumber: {
        id: 'customer.common.views.confirmation.Transaction Number',
        defaultMessage: 'Transaction Number'
    },
    blockChangeTitle: {
        id:'customer.common.wizard.step.paysuccess.blockChangeTitle',
        defaultMessage: 'Transaction Conflict'
    },
    oosTransaction: {
        id: 'customer.common.wizard.step.paysuccess.oosTransaction',
        defaultMessage: 'Due to additional policy changes in progress, this transaction is identified as out of sequence. Please contact your underwriter or start a new policy change in the most recently updated policy.'
    },
    preemptiveTransaction: {
        id: 'customer.common.wizard.step.paysuccess.preemptiveTransaction',
        defaultMessage: 'Please contact your underwriter to continue this transaction or start a new policy change on the most up to date transaction'
    }
});
