import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/hoPriorCarrier'), method, data, additionalHeaders);
}

export default class HOClueService {
    static getClueData(jobId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('getClueData', [jobId, sessionUUID], additionalHeaders);
    }

    static updateClue(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('updateClue', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static deleteClue(jobId, sessionUUID, publicId, additionalHeaders = {}) {
        return processSubmission('deleteClue', [jobId, sessionUUID, publicId], additionalHeaders);
    }

    static saveClue(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('saveClue', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static getClueReportData(jobId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('getClueReportData', [jobId, sessionUUID], additionalHeaders);
    }
    static updateClueReport(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('updateClueReport', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static getCategoryByCauseOfLoss(jobId, sessionUUID, causeOfLoss, additionalHeaders = {}) {
        return processSubmission('getCategoryByCauseOfLoss', [jobId, sessionUUID, causeOfLoss], additionalHeaders);
    }
}
