import PolicyChangeDetailsComponent from 'gw-capability-policyjob-react/components/PolicyChangeDetailsComponent/PolicyChangeDetailsComponent';
// import PolicyDiffComponent from 'gw-capability-policyjob-react/components/PolicyDiffComponent/PolicyDiffComponent';
import PolicyDiffComponent from 'gw-capability-policyjob-react/components/PolicyDiffComponent/PolicyDiffComponent';
import PolicyChangeQuoteComponent from './components/PolicyChangeQuoteComponent/PolicyChangeQuoteComponent';
import DriverComponent from './components/DriverComponent/DriverComponent';
import VehicleComponent from './components/VehicleComponent/VehicleComponent';

const policyJobComponentMap = {
    drivercomponent: { component: 'DriverComponent' },
    vehiclecomponent: { component: 'VehicleComponent' },
    PolicyChangeQuoteComponent: { component: 'PolicyChangeQuoteComponent' },
    PolicyDiffComponent: { component: 'PolicyDiffComponent' },
};

const policyJobComponents = {
    DriverComponent,
    VehicleComponent,
    PolicyChangeQuoteComponent,
    PolicyDiffComponent
};

export {
    DriverComponent,
    VehicleComponent,
    PolicyChangeQuoteComponent,
    PolicyChangeDetailsComponent,
    policyJobComponentMap,
    policyJobComponents,
    PolicyDiffComponent
};
