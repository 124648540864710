import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { WizardConstants, PortalConstants } from 'wni-portals-config-js';
import {
    WindowUtil,
    ConfigUtil,
    QuoteUtil,
    WniDateUtil,
    DocumentsUtil,
    ServiceErrorUtil,
    WniProductsUtil
} from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    WniSubmissionService,
    WniDocumentRetrievalService
} from 'wni-capability-gateway';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { GLRiskAnalysisService, GLMultiQuoteService } from 'wni-capability-quoteandbind-gl';
import ManualLossComponent from "wni-capability-gateway-react/components/ManualLossComponent/ManualLossComponent";
import { Button } from '@jutro/components';
import { Link } from '@jutro/router';
import { FileUploadField } from '@jutro/legacy/components';

import WizardPage from '../../templates/GLWizardPage';

import styles from './GLRiskAnalysisPage.module.scss';
import metadata from './GLRiskAnalysisPage.metadata.json5';
import messages from './GLRiskAnalysisPage.messages';

const {
    GL_PRODUCT_CODE,
    getLobName
} = WniProductsUtil

function GLRiskAnalysisPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData, 
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        updateWizardPageData,
        riskAnalysisService,
        history,
        isReadOnly,
        lobName = getLobName(GL_PRODUCT_CODE),        
        //
        // onPreQuotePageNext,
        isPolicyChange,
    } = props;

    const translator = useTranslator();
    const { authHeader, authUserData: {displayName: currentUser} } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { domainCompany } = useDependencies('domainCompany');
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('GLRiskAnalysisPage');

    const {
        jobID,
        sessionUUID,
        lobData: {
            [lobName]: {
                riskAnalysis_Ext: {
                    lossHistoryType,
                    attachedLoss
                }
            }
        }
    } = submissionVM.value;

    const defaultValidationInfo = useMemo(() => {
        return [{
            type: 'info',
            reason: translator(messages.priorLossInfo)
        }];
    }, [translator])
    const manualLossVMPath = `lobData.${lobName}.riskAnalysis_Ext.manualLoss`;
    const manualLossVM = _.get(submissionVM, manualLossVMPath);
    const manualLoss = _.get(manualLossVM, 'value', []); 
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(defaultValidationInfo);
    const [selection, updateSelection] = useState([]);
    const [initDocuments, updateInitDocuments] = useState([]);
    const [manuallyLoss, updateManuallyLoss] = useState(manualLoss);
    const [currentRow, updateCurrentRow] = useState(null);
    const [originalCurrentRow, updateOriginalCurrentRow] = useState(null);

    const updateSubmissionVM = useCallback((newSubmissionVM) => {

        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        
        updateWizardData(newSubmissionVM);
    }, [updateWizardData]);

    useEffect(() => {
        manualLoss.forEach((item)=> {
            _.set(item, 'rowIdPath', item.publicId || item.rowIdPath);
        });
        updateManuallyLoss(manualLoss);
    }, [manualLoss])
    
    const switchToNextLoss = useCallback(() => {
        const childrenVM = _.get(submissionVM, `${manualLossVMPath}.children`);
        const index = _.findIndex(childrenVM,
            (vm) => vm.value.publicId === currentRow.value.publicId);
        if (index === childrenVM.length - 1) {
            updateCurrentRow(_.get(childrenVM, 0));
            return;
        }
        updateCurrentRow(_.get(childrenVM, index + 1));
    }, [currentRow, submissionVM]);

    const addLoss = useCallback(() => {
        const {
            _dtoName,
            _xCenter,
        } = manualLossVM;
        const lossVM = viewModelService.create({rowIdPath: ConfigUtil.getUuid()}, _xCenter, _dtoName);
        const addedLoss = manualLossVM.pushElement(lossVM);
        updateCurrentRow(addedLoss);
        updateShowErrors(false);
    }, [manualLossVM, viewModelService]);

    const cancelLoss = useCallback(() => {
        resetWizardDataToSnapshot();
        updateCurrentRow(null);
    }, [resetWizardDataToSnapshot]);
    
    const isManualLossValid = async() =>{
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            // handleValidation();
            return false;
        };
        if(_.isNull(_.get(currentRow, 'value.amountPaid.amount'))) {
            _.unset(currentRow, 'value.amountPaid')
        }
        if(_.isNull(_.get(currentRow, 'value.amountReserved.amount'))) {
            _.unset(currentRow, 'value.amountReserved')
        }
        if(_.isNull(_.get(currentRow, 'value.manualTotalIncurred.amount'))) {
            _.unset(currentRow, 'value.manualTotalIncurred')
        }
        if (_.isUndefined(currentRow.value.publicId)) {
            // for newly added, click 'save&close'
            setLoadingMask(true);
            const res = await riskAnalysisService.saveManualLoss(jobID, sessionUUID, currentRow.value, authHeader);
            setLoadingMask(false);
            _.set(submissionVM, manualLossVMPath, res);
        } else if (!_.isEqual(currentRow?.value, originalCurrentRow?.value)){
            setLoadingMask(true);
            const res = await riskAnalysisService.updateManualLoss(jobID, sessionUUID, currentRow.value, authHeader);
            setLoadingMask(false);
            _.set(submissionVM, manualLossVMPath, res);
        }
        
        return true;
    }
    const saveLoss = useCallback(async(callback) => {
        updateCurrentRow(null);
        updateSubmissionVM(submissionVM);
        updateWizardSnapshot(submissionVM);
        updateSelection([]);
        if (_.isFunction(callback)) {
            await callback();
        }
    }, [submissionVM, updateSubmissionVM, updateWizardSnapshot]);

    const deleteLosses = useCallback(() => {
        modalApi.showConfirm({
            title: messages.removeLossTitle,
            message: messages.removeLossDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.Ok,
            cancelButtonText: messages.Cancel
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const removeLosses = manuallyLoss.filter(item => selection.includes(item.publicId));
                setLoadingMask(true);
                const res = await riskAnalysisService.deleteManualLoss(jobID, sessionUUID, removeLosses, authHeader);
                setLoadingMask(false);
                updateManuallyLoss(res);
                updateCurrentRow(null);
                updateSelection([]);
                _.set(submissionVM, manualLossVMPath, res);
                updateSubmissionVM(submissionVM);
                updateWizardSnapshot(submissionVM);
                return true;
            }, _.noop
        );
    }, [manuallyLoss, selection, submissionVM, updateWizardSnapshot]);

    
    const writeValue = (value, path) => {
        _.set(submissionVM.value, path, value);
        updateSubmissionVM(submissionVM);
    };

    const onValueChange = useCallback((value, path) => {
        _.set(currentRow.value, path, value);
        updateCurrentRow(currentRow);
        updateSubmissionVM(submissionVM);
    },[currentRow, submissionVM, updateSubmissionVM]);

    const viewOrEditLoss = useCallback((value, index) => {
        const childrenVM = _.get(submissionVM, `${manualLossVMPath}.children`);
        const lossVM = childrenVM.find((item) => item.value.publicId === index);
        const clonedLossVM = viewModelService.clone(lossVM);
        updateOriginalCurrentRow(clonedLossVM);
        updateCurrentRow(lossVM);
    }, [submissionVM]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'manualLossTable');
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const handleError = useCallback((messageTitle, message) => {
        return modalApi.showAlert({
            title: messageTitle,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(_.noop, _.noop);
    }, []);

    const retrieveInitSubmissionDocAndAttachments = useCallback(async () => {
        try {
            setLoadingMask(true);
            const response =
                await WniSubmissionService.getDocsAndAttachsForJob(
                    [jobID, true],
                    authHeader
                );
            setLoadingMask(false);    
            const documents = DocumentsUtil.updateDocumentUnselectable(response);
            const lossTypeDocuments = documents.filter(doc => doc.securityType === "unrestricted" && doc.documentType === "loss_history" && doc.author === currentUser);
            updateInitDocuments(lossTypeDocuments);
        } catch (e) {
            handleError(messages.modalError, messages.errorLoadingDocument);
        }
    }, [authHeader, currentUser, handleError, jobID, setLoadingMask]);

    useEffect(() => {
        if (lossHistoryType === 'att') {
            retrieveInitSubmissionDocAndAttachments()
        }
    }, [lossHistoryType])
    
    const handleWarning = useCallback((messageTitle, message) => {
        return modalApi.showAlert({
            title: messageTitle,
            message: message,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(_.noop, _.noop);
    }, []);
    const claimsDocUploadToken = useCallback((async () => {
        try {
            setLoadingMask(true);
            const uploadTokenID = await GatewayDocumentService
                .generateUploadToken(authHeader);
            setLoadingMask(false);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }), [authHeader, handleError]);

    const uploadDocument = useCallback((async (uploadFile) => {
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: 'loss_history',
            // description: _.get(vm, 'value.description'),
            securityType: 'unrestricted',
            status: 'approved',
            jobNumber: jobID,
            name: uploadFile.name,
            mimeType: uploadFile.type,
            sessionID: await claimsDocUploadToken()
        };
        setLoadingMask(true);
        try {
            await GatewayDocumentService
                .uploadDocument(uploadFile, documentMetaDataTemplate,
                    authHeader);
            retrieveInitSubmissionDocAndAttachments();
        } catch (error) {
            // BR.PL.0254
            if (error
                && _.get(error, 'baseError.message') === 'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.'
                && _.get(error, 'baseError.code') === -32603
            ) {
                const message = _.get(domainCompany, 'code') === 'UIC' ? messages.uploadFailedMessageUIC : messages.uploadFailedMessage;
                handleWarning(
                    messages.warningUploadTitle,
                    message
                );
            } else {
                handleError(
                    commonMessages.errorUploadTitle,
                    commonMessages.uploadFailedMessage
                );
            }
        } 
        setLoadingMask(false);
    }), []);

    const downloadDocument = (publicID, sessionID) => {
        DocumentsUtil.defaultDownloadDocument(
            publicID,
            sessionID,
            setLoadingMask,
            history,
            authHeader
        );
    }

    const onDemandDocumentDownloadFn = async (item) => {
        await DocumentsUtil.onDemandDocumentDownloadFn(
            item,
            authHeader,
            history,
            modalApi,
            setLoadingMask,
            ServiceErrorUtil,
            WniDocumentRetrievalService
        );
    }

    const handleDocumentDownload = (event, item) => {
        event.preventDefault();
        if (item.docUID) {
            const { publicID, sessionID } = item;
            downloadDocument(publicID, sessionID);
        } else if (onDemandDocumentDownloadFn !== _.noop) {
            onDemandDocumentDownloadFn(item);
        } else {
            _.noop();
        }
    };
    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); 
        return false;
    },
    [updateShowErrors, invalidFields]);

    // =================================================================
    const onDefaultPageNext = useCallback(async () =>{
        // const {
        //     baseData: {
        //         periodStatus,
        //     },
        // } = submissionVM.value;

        // if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
        //     updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
        //     setLoadingMask(true);
        //     submissionVM.value = await GLMultiQuoteService.processQuote(
        //     // submissionVM.value = await processQuote(
        //         jobID, sessionUUID, authHeader
        //     );
        //     setLoadingMask(false);
        // }
        return submissionVM
    }, [authHeader, submissionVM, updateWizardPageData]);
    // =================================================================

    const onPageNext = useCallback(async () => {
        if(isReadOnly) {
            return submissionVM
        };

        const {
            onPreQuotePageNext = onDefaultPageNext,
        } = props;
        //
        if (lossHistoryType === 'nol') {
            setLoadingMask(true);
            await riskAnalysisService.saveNonLossType(jobID, sessionUUID, authHeader);
            _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
            
            setLoadingMask(false);
        }
        if ( lossHistoryType === 'pu_ext') {
            setLoadingMask(true);
            await riskAnalysisService.savePrevUploadType(jobID, sessionUUID, authHeader);
            _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
            setLoadingMask(false);
        }
        if (lossHistoryType === 'man' && _.isEmpty(manuallyLoss)) {
            setLoadingMask(true);
            await riskAnalysisService.saveManualLoss(jobID, sessionUUID, null, authHeader);
            setLoadingMask(false);
        }
        if (lossHistoryType === 'att') {
            const attachedTotalIncurredAmount = _.get(attachedLoss, 'attachedTotalIncurred.amount');
            if (_.isNull(attachedTotalIncurredAmount)) {
                return false;
            }
            if (_.isEmpty(initDocuments)) {
                const newValidationIssues = [{
                    type: 'warning',
                    reason: translator(messages.priorLossInfo)
                }]
                updateValidationIssues(newValidationIssues);
                return false;
            }
            setLoadingMask(true);
            const res = await riskAnalysisService.saveAttachedLoss(jobID, sessionUUID, attachedLoss ,authHeader);
            setLoadingMask(false);
            _.set(submissionVM, `lobData.${lobName}.riskAnalysis_Ext.attachedLoss`, res);

            updateSubmissionVM(submissionVM);
        }

        //
        const retval = await onPreQuotePageNext();
        return retval;
    }, [lossHistoryType, manuallyLoss, setLoadingMask, riskAnalysisService, jobID, sessionUUID, authHeader, submissionVM, attachedLoss, initDocuments, lobName, updateSubmissionVM, translator]);

    const getNameLink = (item, index, property) => {
        const { path } = property;
        if (item.docUID || onDemandDocumentDownloadFn !== _.noop) {
            return (
                <Link
                    href={item[path]}
                    className={styles.documentName}
                    onClick={(e) => handleDocumentDownload(e, item)}
                >
                    {item[path]}
                </Link>
            );
        }
        return (
            <div className={styles.documentName}>
                {item[path]}
            </div>
        );
    };

    const renderUploadDocBtn = () => {
        return (
            <div className={`${styles.height35} uploadWrapper` }>   
                <FileUploadField
                    disableDragAndDrop
                    hideLabel
                    maxNumberOfFiles={1}
                    onUpload={uploadDocument}
                    buttonType="outlined"
                    className="uploadRealBtn"
                />
                <Button
                    variant="secondary"
                    className="uploadMaskBtn"
                    label={messages.uploadFile}
                    />
            </div>
        )
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
                readOnly: isReadOnly
            },
            lossHistoryTypeDropdown: {
                path: `lobData.${lobName}.riskAnalysis_Ext.lossHistoryType`
            },
            manualLossDetailBtns: {
                visible: !_.isEmpty(currentRow)
            },
            saveNextButton: {
                visible: _.get(submissionVM, `${manualLossVMPath}.value`, []).length > 1 && !isReadOnly
            },
            saveButton: {
                visible: !isReadOnly
            },
            LossHistoryAttachedSection: {
                visible: lossHistoryType === 'att'
            },
            AttachedLossRunsSection: {
                visible: lossHistoryType === 'att'
            },
            attachedLossRunsTable: {
                data: initDocuments
            },
            uploadAction: {
                header: renderUploadDocBtn(),
                visible: !isReadOnly
            },
            manualLossTableHeader: {
                visible: lossHistoryType === 'man'
            },
            numberOfLossesInput: {
                required: lossHistoryType === 'att',
                showRequired: lossHistoryType === 'att',
                showErrors,
                path: `lobData.${lobName}.riskAnalysis_Ext.attachedLoss.numberOfLosses`
            },
            totalIncurredInput: {
                required: lossHistoryType === 'att',
                showRequired: lossHistoryType === 'att',
                showErrors,
                path: `lobData.${lobName}.riskAnalysis_Ext.attachedLoss.attachedTotalIncurred`
            },
            addLoss: {
                disabled: !!currentRow,
                visible: !isReadOnly
            },
            delLoss: {
                disabled: _.isEmpty(selection),
                visible: !isReadOnly
            },
            viewOrEditLink: {
                onClick: viewOrEditLoss,
                disabled: currentRow,
                label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
            },
            manualLossTable: {
                data: manuallyLoss,
                visible: lossHistoryType === 'man',
                onSelectionChange: (rows) => {
                    updateSelection(rows)
                },
                rowIdPath: 'rowIdPath'
            },
            detailedLossPanel: {
                visible: !_.isEmpty(currentRow),
                lossVM: currentRow,
                onValidate,
                onValueChange,
                isReadOnly,
                updateCurrentRow,
                hideCategory: true
            }
        };
    }, [currentRow, initDocuments, lobName, lossHistoryType, manuallyLoss, onValidate, onValueChange, selection, showErrors, viewOrEditLoss]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            uploadDocument,
            addLoss,
            cancelLoss,
            deleteLosses,
            saveAndNextLoss: () => {
                isManualLossValid().then((valid) => {
                    if (valid) {
                        saveLoss(switchToNextLoss);
                    }
                });
            },
            saveLoss: () => {
                isManualLossValid().then((valid) => {
                    if (valid) {
                        saveLoss();
                    }
                });
            },
            getNameLink
        },
        resolveComponentMap: {
            manualLossComponent : ManualLossComponent
        }
    };

    return (
        <WizardPage
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            isPolicyChange={isPolicyChange}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

GLRiskAnalysisPage.propTypes = {
    ...wizardProps,
    onPreQuotePageNext: PropTypes.func,
    riskAnalysisService: PropTypes.object,
    lobName: PropTypes.string
};
GLRiskAnalysisPage.defaultProps = {
    riskAnalysisService: GLRiskAnalysisService,
    onPreQuotePageNext: undefined,
}
export default GLRiskAnalysisPage;