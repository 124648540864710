import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPAVehiclesPage } from 'wni-capability-fnol-pa-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLCAVehiclesPage(props) {
    return <FNOLPAVehiclesPage {...props} />;
}

FNOLCAVehiclesPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
};

FNOLCAVehiclesPage.propTypes = wizardProps;
export default withRouter(FNOLCAVehiclesPage);
