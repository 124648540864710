
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLStateSpecificInformationPage } from 'wni-capability-quoteandbind-gl-react';

function GLStateSpecificInformationChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLStateSpecificInformationPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLStateSpecificInformationChangePage.propTypes = GLStateSpecificInformationPage.propTypes;
GLStateSpecificInformationChangePage.defaultProps = GLStateSpecificInformationPage.defaultProps;

export default GLStateSpecificInformationChangePage;