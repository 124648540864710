import React, {useState} from "react"
import _ from "lodash";
import { Button } from "@jutro/components";
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from "@jutro/layout";
import { WniDataTable, WniToggle } from "wni-common-base-components";
import { useTranslator } from '@jutro/locale';
import SingleVehicleIncident from "./SingleVehicleIncident/SingleVehicleIncident";
import messages from './VehicleIncidents.messages'
import { getContactDisplayName } from "../util/ContactUtil";
import { getFullAddressDisplayName } from "../util/AddressUtil";


const VehicleIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const vehicleIncidentInitValue = {
    vehicle: {
    },
    ownerContact: {
        primaryAddress: {
            country: "US"
        }
    }
}

const VehicleIncidents = (props) => {
    const {
        vehicleIncidents = [],
        updateVehicleIncidents,
    } = props;

    const translator = useTranslator();

    const [vehicleIncidentQuestionValue, setVehicleIncidentQuestion] = useState(() => {
        return vehicleIncidents.length > 0 ? VehicleIncidentQuestionValue.Yes : VehicleIncidentQuestionValue.No
    })
    const [selectedIncidentNumbers, setSelectedIncidentNumebrs] = useState([])
    const [singleVehicleIncidentPopupStatus, setSingleVehicleIncidentPopupStatus] = useState({
        isOpen: false,
        originVehicleIncident: vehicleIncidentInitValue,
        editingVehicleIncidentIndex: undefined
    })

    const closePopup = () => {
        setSingleVehicleIncidentPopupStatus({
            isOpen: false,
            originVehicleIncident: vehicleIncidentInitValue,
            editingVehicleIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = (vehicleIncident, editingVehicleIncidentIndex) => {

        let updatedVehicleIncidents
        if (_.isNil(editingVehicleIncidentIndex)) {
            // new added item
            updatedVehicleIncidents = vehicleIncidents.concat([vehicleIncident])
        } else {
            // existing item
            updatedVehicleIncidents = vehicleIncidents.toSpliced(editingVehicleIncidentIndex, 1, vehicleIncident)
        }
        updateVehicleIncidents(updatedVehicleIncidents)
        closePopup()
    }

    return <>
        <WniToggle
            id='vehicleIncidentQuestion'
            label={translator(messages.VehicleIncidentsQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: VehicleIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: VehicleIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setVehicleIncidentQuestion(value)
            }}
            disabled={vehicleIncidents.length > 0}
            value={vehicleIncidentQuestionValue}
        />
        {vehicleIncidentQuestionValue === VehicleIncidentQuestionValue.Yes &&
            <>
                <Flex gap="small" justifyContent="right" className="mb-10">
                    <Button
                        type="primary"
                        onClick={() => setSingleVehicleIncidentPopupStatus({
                            isOpen: true,
                            originVehicleIncident: vehicleIncidentInitValue
                        })}
                        disabled={singleVehicleIncidentPopupStatus.isOpen}
                        label={messages.AddVehicle}
                    />
                    <Button
                        type="primary"
                        className="wni-button-danger"
                        onClick={() => {
                            const newVehicleIncidents = vehicleIncidents.filter((_incident, index) => {
                                if (selectedIncidentNumbers.includes(index)) {
                                    return false
                                }
                                return true
                            })
                            updateVehicleIncidents(newVehicleIncidents)
                        }}
                        disabled={selectedIncidentNumbers.length === 0}
                        label={messages.DeleteVehicle}
                    />
                </Flex>
                <WniDataTable
                    id="vehicleIncidentTable"
                    showSearch= {false}
                    showPagination= {false}
                    data={vehicleIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                    rowIdpath="number"
                    selectionType="multi"
                    onSelectionChange={(selectedNumebrs) => setSelectedIncidentNumebrs(selectedNumebrs)}
                    selectedRows={selectedIncidentNumbers}
                >
                    <DisplayColumn
                        id="vehicleSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="vehicleOwnerColumn"
                        header={translator(messages.VehicleOwner)}
                        textAlign='center'
                        renderCell={(item) => {
                            const vehicleOwner = _.get(item, 'ownerContact')
                            return getContactDisplayName(vehicleOwner)
                        }}
                    />
                    <DisplayColumn
                        id="vehicleLocationColumn"
                        header={translator(messages.VehicleLocation)}
                        textAlign='center'
                        renderCell = {(item) => {
                            const matchedAddress = _.get(item, 'ownerContact.primaryAddress')
                            return getFullAddressDisplayName(matchedAddress)
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <Flex gap="small" justifyContent="right" className="mb-10">
                                <Button
                                    className="btn-link"
                                    onClick={() => setSingleVehicleIncidentPopupStatus({
                                        isOpen: true,
                                        originVehicleIncident: item,
                                        editingVehicleIncidentIndex: index
                                    })}
                                    label={messages.ViewOrEdit}
                                />
                            </Flex>
                        }}
                    />
                </WniDataTable>
                {singleVehicleIncidentPopupStatus.isOpen && <SingleVehicleIncident
                    originVehicleIncident={singleVehicleIncidentPopupStatus.originVehicleIncident}
                    editingVehicleIncidentIndex={singleVehicleIncidentPopupStatus.editingVehicleIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                />}
            </>
        }
        
    </>
}

export default VehicleIncidents
