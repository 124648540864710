import React, {
    useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/legacy/components';

/**
 * Extracted from WizardSidebarLink. Consider to put it along with WniProductsUtil
 * @param {object} props 
 * @returns 
 */
const QuickLinkComponent = (props) => {
    const translator = useTranslator();
    const { id, title, className, onClick, quickLinkData } = props;

    if(!_.get(quickLinkData, id)){
        return null;
    }
    return (
        <li id={`${id}Item`}>
            <Button type="text" id={id} className={className} onClick={onClick} >{translator(title)}</Button>
        </li>
    );
}


export default QuickLinkComponent;
