
import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from "wni-components-platform-react";
import { useTranslator } from '@jutro/locale';
import BaseDocumentSendViaEmailPopup from "wni-capability-common-react/components/BaseDocumentSendViaEmailPopup/BaseDocumentSendViaEmailPopup";
import messages from "wni-capability-common-react/components/BaseDocumentSendViaEmailPopup/BaseDocumentSendViaEmailPopup.messages";
import DocumentsComponent from '../DocumentsComponent/DocumentsComponent';
import documentMessages from '../DocumentsComponent/DocumentsComponent.messages';
import UploadAttachmentModal from '../DocumentsComponent/modal/UploadAttachmentModal';
import metadata from './DocumentListComponent.metadata.json5';
import defaultMessages from './DocumentListComponent.messages';
import styles from './DocumentListComponent.module.scss';

const DOCUMENT_DTO_NAME = 'edge.capabilities.gateway.document.dto.DocumentDTO';
const DOCUMENT_X_Center = 'pc'
function DocumentListComponent(props) {
    const {
        getDateModifiedFn,
        initAccountDocuments,
        initBillingAccountDocuments,
        initQuoteDocuments,
        createBillingDcoumentVM,
        onDemandDocumentDownloadFn,
        showQuoteDetailsDocumentsCL,
        showAccountDocumentsDetailAccordion,
        showBillingDocumentsDetailAccordion,
        downloadPCDocument,
        downloadBCDocument,
        listDocHeader,
        handlePCPrintAllFn,
        handleBCPrintAllFn,
        handlePCSendViaEmailFn,
        handleBCSendViaEmailFn,
        overrideTitleStyle,
        repopupDocumentsPage,
        onReject,
        uploadDocument,
        productCode,
        submissionVM,
        viewModelService = useContext(ViewModelServiceContext),
        translator = useTranslator(),
        documentNameColumnPath,
        pageSizeConfig,
        pageSizeOptions
    } = props;

    const modalApi = useWniModal();

    const [attachmentVM, updateAttachmentVM] = useState(submissionVM);
    // for popup
    const [popupUploadAttachmentVM, updatepopupUploadAttachmentVM] = useState();

    
    useEffect(() => {
        const model = {};
        const newAttachmentVM = viewModelService.create(model, DOCUMENT_X_Center, DOCUMENT_DTO_NAME);
        updateAttachmentVM(newAttachmentVM);
        updatepopupUploadAttachmentVM(newAttachmentVM);
    }, []);

    const onUploadDocument = useCallback(
        (file, vm) => {
            if (file) {
                uploadDocument(file, vm);
            }
        },
        [uploadDocument]
    );

    const showUploadAttachmentModal = () => {
        const componentProps = {
            title: translator(documentMessages.UploadAttachments),
            size: 'md',
            actionBtnLabel: translator(documentMessages.uploadModalOk),
            cancelBtnLabel: translator(documentMessages.uploadModalCancel),
            uploadAttachmentVM: popupUploadAttachmentVM,
            productCode
        };
        return modalApi.showModal(<UploadAttachmentModal {...componentProps} />)
    };

    const handleUploadAttachmentModal = () => {
        if (onReject) {
            showUploadAttachmentModal().then((res) => {
                onUploadDocument(res.file, res.vm);
                repopupDocumentsPage();
            }).catch(_.noop)
        } else {
            showUploadAttachmentModal().then(async(res) => {
                await onUploadDocument(res.file, attachmentVM);
                repopupDocumentsPage();
            }).catch(_.noop)
        }
    };

    const onEmailSendFn = useCallback(() => {
        const componentProps = {
            isOpen: true,
        };
        modalApi.showModal(<BaseDocumentSendViaEmailPopup {...componentProps} />)
            .then((res) => {
                if (res) {
                    modalApi.showAlert({
                        title: messages.success,
                        message: messages.successContent,
                        status: 'info',
                        icon: 'gw-info-outline',
                        confirmButtonText: messages.close
                    }).then(() => {
                        _.noop();
                    }, _.noop);
                } else {
                    modalApi.showAlert({
                        title: messages.notSend,
                        message: messages.notSendContent,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: messages.close
                    }).then(() => {
                        _.noop();
                    }, _.noop);
                }
            }).catch(() => {
                _.noop();
            });
    }, [modalApi]);

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        listDocHeader: {
            content: listDocHeader || translator(messages.Documents)
        },
        accountDocumentsDetailAccordion: {
            visible: showAccountDocumentsDetailAccordion,
            chevron: true,
            chevronAlignment: 'left',
            errorState: false,
            isCollapsible: true,
            title: translator(defaultMessages.accountDocuments)
        },
        accountDocuments: {
            showTitle: false,
            showFilterBar: false,
            showRelatedColumn: false,
            showAuthorColumn: true,
            showActionColumn: false,
            initialDocumentsData: initAccountDocuments,
            getDateModifiedFn,
            onDemandDocumentDownloadFn,
            printAllActionContent: translator(defaultMessages.printSelectedAccountDocuments),
            handlePrintAllFn: handlePCPrintAllFn,
            handleSendViaEmailFn: handlePCSendViaEmailFn,
            downloadDocument: downloadPCDocument,
            productCode,
            documentNameColumnPath: documentNameColumnPath,
            pageSizeConfig: pageSizeConfig,
            pageSizeOptions: pageSizeOptions
        },
        billingDocumentsDetailAccordion: {
            visible: showBillingDocumentsDetailAccordion,
            chevron: true,
            chevronAlignment: 'left',
            errorState: false,
            isCollapsible: true,
            title: translator(defaultMessages.billingDocuments)
        },
        billingDocuments: {
            showTitle: false,
            showFilterBar: false,
            showRelatedColumn: false,
            showAuthorColumn: true,
            showActionColumn: false,
            initialDocumentsData: initBillingAccountDocuments,
            getDateModifiedFn,
            getDocumentTypeVMFn: createBillingDcoumentVM,
            onDemandDocumentDownloadFn,
            printAllActionContent: translator(defaultMessages.printSelectedBillingDocuments),
            handlePrintAllFn: handleBCPrintAllFn,
            handleSendViaEmailFn: handleBCSendViaEmailFn,
            downloadDocument: downloadBCDocument,
            productCode,
            documentNameColumnPath: documentNameColumnPath,
            pageSizeConfig: pageSizeConfig,
            pageSizeOptions: pageSizeOptions
        },
        quoteDetailsDocumentsCL: {
            visible: showQuoteDetailsDocumentsCL,
            showTitle: false,
            showFilterBar: false,
            showRelatedColumn: false,
            showAuthorColumn: true,
            showActionColumn: false,
            initialDocumentsData: initQuoteDocuments,
            getDateModifiedFn,
            onDemandDocumentDownloadFn,
            printAllActionContent: translator(defaultMessages.printSelectedQuoteDocuments),
            handlePrintAllFn: handlePCPrintAllFn,
            handleSendViaEmailFn: handlePCSendViaEmailFn,
            downloadDocument: downloadPCDocument,
            overrideTitleStyle,
            productCode,
            documentNameColumnPath: documentNameColumnPath,
            pageSizeConfig: pageSizeConfig,
            pageSizeOptions: pageSizeOptions
        },
        // claimsDocumentsDetailAccordion: {
        //     chevron: true,
        //     chevronAlignment: 'left',
        //     errorState: false,
        //     isCollapsible: true,
        //     title: translator(defaultMessages.claimsDocuments)
        // },
        // claimsDocuments: {
        //     showTitle: false,
        //     showFilterBar: false,
        //     showRelatedColumn: false,
        //     showAuthorColumn: true,
        //     showActionColumn: false,
        //     initialDocumentsData: []
        // }
    };

    const resolvers = {
        resolveCallbackMap: {
            onEmailSendFn,
            handleUploadAttachmentModal: handleUploadAttachmentModal,
        },
        resolveComponentMap: {
            documentscomponent: DocumentsComponent,
        },
        resolveClassNameMap: styles,
    };
    
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{}}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            // onValueChange={writeValue}
            // showErrors={showErrors}
        />
    );
}

DocumentListComponent.propTypes = {
    showQuoteDetailsDocumentsCL: PropTypes.bool,
    showAccountDocumentsDetailAccordion: PropTypes.bool,
    showBillingDocumentsDetailAccordion: PropTypes.bool,
    downloadPCDocument: PropTypes.func,
    downloadBCDocument: PropTypes.func,
    listDocHeader: PropTypes.shape({}),
    handlePCPrintAllFn: PropTypes.func,
    handleBCPrintAllFn: PropTypes.func,
    handlePCSendViaEmailFn: PropTypes.func,
    handleBCSendViaEmailFn: PropTypes.func,
    overrideTitleStyle: PropTypes.bool,
    onReject: PropTypes.func,
    submissionVM: PropTypes.shape({}),
    repopupDocumentsPage: PropTypes.func
};

DocumentListComponent.defaultProps = {
    showQuoteDetailsDocumentsCL: false,
    showAccountDocumentsDetailAccordion: false,
    showBillingDocumentsDetailAccordion: false,
    downloadPCDocument: _.noop,
    downloadBCDocument: _.noop,
    listDocHeader: {
        id: 'quoteandbind.common.views.list.Documents',
        defaultMessage: 'Documents'
    },
    handlePCPrintAllFn: _.noop,
    handleBCPrintAllFn: _.noop,
    handlePCSendViaEmailFn: _.noop,
    handleBCSendViaEmailFn: _.noop,
    overrideTitleStyle: false,
    onReject: _.noop,
    submissionVM: {},
    repopupDocumentsPage: _.noop
};

export default DocumentListComponent;
