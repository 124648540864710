import { defineMessages } from 'react-intl';

export default defineMessages({
    resolveUWIssuesMsgTooltipTitle: {
        id: 'custom.quoteandbind.common.uwissues.tooltip.content.title',
        defaultMessage: 'The following issue type categories are listed below:',
    },
    resolveUWIssuesMsgTooltipWarning: {
        id: 'custom.quoteandbind.common.uwissues.tooltip.content.warning',
        defaultMessage: 'Issues that will be reviewed by underwriting for final approval after submission.',
    },
    resolveUWIssuesMsgTooltipError: {
        id: 'custom.quoteandbind.common.uwissues.tooltip.content.error',
        defaultMessage: 'Issues that require underwriting referral prior to submission.',
    },
    // requiringPostIssueReview: {
    //     id: 'custom.quoteandbind.common.uwissues.tooltip.content.Requiring Post Issue Review',
    //     defaultMessage: 'Requiring Post Issue Review',
    // },
    // requiringPostIssueReview: {
    //     id: 'custom.quoteandbind.common.uwissues.tooltip.content.for Underwriting Post-Issue Review',
    //     defaultMessage: 'for Underwriting Post-Issue Review',
    // },
    requiringUnderwriterReferral: {
        id: 'custom.quoteandbind.common.uwissues.tooltip.content.Requiring Underwriter Referral',
        defaultMessage: 'Requiring Underwriter Referral',
    },
    requiringPostIssuanceReview: {
        id: 'custom.quoteandbind.common.uwissues.tooltip.content.Requiring Post Issuance Review',
        defaultMessage: 'Requiring Post Issuance Review',
    }
});
