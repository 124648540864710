
import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { useProductsData } from 'wni-portals-util-react';
import metadata from './NewSubmissionBlocked.metadata.json5';
import messages from './NewSubmissionBlocked.messages';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const NewSubmissionBlocked = (props) => {
    const history = useHistory();
    const {
        location: {
            state: {
                blockProducts = []
            }
        } = {}
    } = history;
    const { getProductName } = useProductsData();
    const [blockProductNameMaps, updateBlockProductNameMaps] = useState([]);
    useEffect(() => {
        const productNames = blockProducts.map((item)=> getProductName(item)).join(', ');
        updateBlockProductNameMaps(productNames);
    }, [blockProducts]);

    const translator = useTranslator();

    const returnToHomeScreen = () => {
        return history.push('/')
    }
    //---------
    const overrideProps = {
        blockTitle: {
            content: translator(messages.blockTitle, {Lob: blockProductNameMaps})
        }
       
    };
    const resolvers = {
        resolveCallbackMap: {
            returnToHomeScreen
        },
        resolveComponentMap: {

        }
    }
    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} {...resolvers} />
};

export default NewSubmissionBlocked;
