import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved


function MetroReport(data) {
    if (data) {
        _.extend(this, data);
    }
}

MetroReport.prototype.getDisplayName = function (defaultName) {
    return format(this, ' ') || defaultName;
};

export default MetroReport;
