import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _, { isLength } from 'lodash';
import { UWIssueUtil } from 'wni-portals-util-js';
import { BaseDocumentCheckedComponent } from 'wni-capability-common-react'
import { useTranslator } from '@jutro/locale';
import metadata from './DocumentSectionComponent.metadata.json5';
import styles from './DocumentSectionComponent.module.scss';

function DocumentSectionComponent(props) {
    const translator = useTranslator();
    const {
        renderData,
        documentNameColumnPath
    } = props;

    const generateOverrides = useCallback(() => {
        const AUTOPAY_AUTH_FORM = ['AutoPay Auth Form', 'AutoPay Forms'];
        const overrides = renderData.map((item, index) => {
            const productName = _.get(item, 'productName');
            const jobNumber = _.get(item, 'jobNumber');
            const tableLeftSubTitle = _.get(item, 'retainInAgencySubTitle');
            const tableLeftData = _.get(item, 'retainInAgencyDocs');
            const tableRightSubTitle = _.get(item, 'submitToWesternNationalSubTitle');
            const tableRightData = _.get(item, 'submitToWesternNationalDocs');
            const title = AUTOPAY_AUTH_FORM.includes(productName) ? productName : `${productName} Quote (${jobNumber})`;
            const sideBySideData = item;
            const selectedVersion = _.get(item, 'selectedVersion');
            const infoUWIssues = UWIssueUtil.getPendingInfoIssuesFromSxsData(sideBySideData, selectedVersion);
            return {
                [`accordionTab${index}`]: {
                    title: title,
                    className: styles.flex
                },
                [`accordionDocFormTableLeftSubTtile${index}`]: {
                    content: AUTOPAY_AUTH_FORM.includes(productName) ? '' : translator(tableLeftSubTitle)
                },
                [`accordionDocFormTableLeft${index}`]: {
                    data: tableLeftData,
                },
                [`accordionDocFormTableRightSubTtile${index}`]: {
                    content: AUTOPAY_AUTH_FORM.includes(productName) ? '' : translator(tableRightSubTitle)
                },
                [`accordionDocFormTableRight${index}`]: {
                    data: tableRightData,
                },
                [`accordionTabcontainer${index}`]: {
                    className: `table-document ${styles.flex2}`
                },
                [`uwIssuePendingList${index}`]: {
                    visible: !_.isEmpty(infoUWIssues),
                    uwIssues: infoUWIssues
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [renderData, translator]);

    const accordionStates = [];
    _.each(renderData, (data, index) => {
        accordionStates.push(`accordionTab${index}`);
    });

    const forceDocumentChecked = useCallback(() => {
        const updatedValues = [];
        _.each(renderData, (data, index) => {
            updatedValues[index] = [];
            const documents = _.get(data, 'documents');
            _.each(documents, (documentArray, idx) => {
                updatedValues[index][idx] = null;
            });
        });
        return updatedValues;
    }, [renderData]);

    const forceOnDocSelectedAllFn = useCallback(() => {
        const updatedValues = [];
        _.each(renderData, (data, index) => {
            updatedValues[index] = [];
            const documents = _.get(data, 'documents');
            _.each(documents, (documentArray, idx) => {
                const docList = _.get(documentArray, 'data');
                const docSelectedAll = _.map(docList, (v) => {
                    return v.publicID;
                });
                updatedValues[index][idx] = docSelectedAll;
            });
        });
        return updatedValues;
    }, [renderData]);

    const forceGetCheckBoxFn = useCallback((documentChecked, item) => {
        let leftMatch = null;
        let rightMatch = null;
        _.forEach(documentChecked, (data) => {
            if (data && data[0]) {
                leftMatch = leftMatch || data[0].find((checked) => checked === item.publicID);
            }
            if (data && data[1]) {
                rightMatch = rightMatch || data[1].find((checked) => checked === item.publicID);
            }
        });
        return { leftMatch, rightMatch };
    }, []);

    const forcehandleValueChangeFn = useCallback((documentChecked, value, publicID) => {
        let findIndex = null;
        let findIdx = null;
        const finded = _.find(documentChecked, (checkedSectionData, index) => {
            if (checkedSectionData) {
                const sectionFind = _.find(checkedSectionData, (checkedListData, idx) => {
                    const detailFind = _.find(checkedListData, (checkedData) => {
                        const target = checkedData === publicID;
                        if (target) {
                            findIdx = idx;
                            findIndex = index;
                            return true;
                        }
                        return false;
                    });
                    return detailFind;
                });
                return sectionFind;
            }
            return false;
        });
        // exist in documentChecked
        if (finded) {
            const list = documentChecked[findIndex][findIdx];
            const newList = [];
            _.each(list, (data) => {
                if (data !== publicID) {
                    newList.push(data);
                }
            });
            _.set(documentChecked, `[${findIndex}][${findIdx}]`, newList);
        } else {
            // not exist in documentChecked
            _.find(renderData, (data, index) => {
                const documents = _.get(data, 'documents');
                const findSection = _.find(documents, (section, idx) => {
                    const list = _.get(section, 'data');
                    const findDoc = _.find(list, (doc) => {
                        if (doc.publicID === publicID) {
                            findIdx = idx;
                            findIndex = index;
                            return true;
                        }
                        return false;
                    });
                    return findDoc;
                });
                return findSection;
            });
            const sectionData = documentChecked[findIndex];
            const selectedList = _.isEmpty(sectionData) ? [] : sectionData[findIdx];
            if (selectedList) {
                selectedList.push(publicID);
                _.set(documentChecked, `[${findIndex}][${findIdx}]`, selectedList);
            } else {
                _.set(documentChecked, `[${findIndex}][${findIdx}]`, [publicID]);
            }
        }
        return documentChecked;
    }, [renderData]);

    const forceHasDocSelected = useCallback((checkedDocuments) => {
        const hasDocSelected = _.some(checkedDocuments, (sectionData) => {
            return _.some(sectionData, (listData) => {
                return !_.isEmpty(listData);
            });
        });
        return hasDocSelected;
    }, []);

    const forceDocuments = useCallback(() => {
        let updatedValues = [];
        _.each(renderData, (data, index) => {
            const documents = _.get(data, 'documents');
            _.each(documents, (documentArray, idx) => {
                const docs = _.get(documentArray, 'data');
                if (idx === 0 && !_.isEmpty(docs)) {
                    updatedValues = updatedValues.concat(docs);
                } else if (idx === 1 && !_.isEmpty(docs)) {
                    const validDocs = _.filter(docs, (doc) => !_.get(doc, 'publicID', '').includes('gnore'));
                    updatedValues = updatedValues.concat(validDocs);
                }
            });
        });
        return updatedValues;
    }, [renderData]);

    const forceOnDocPrintAllFn = async (pageRenderData, documentChecked,
        setLoadingMask, DocumentsUtil, authHeader, history,
        WniDocumentRetrievalService, successCallback, errorCallback,
        WniMultiDocumentRetrievalService, printAllErrorCallBack) => {
        const docsToCombine = [];
        let checkedDocs = [];
        _.each(documentChecked, (checkedList) => {
            if (checkedList) {
                _.each(checkedList, (checked) => {
                    checkedDocs = checkedDocs.concat(checked);
                });
            }
        });
        _.each(pageRenderData, (rowData) => {
            // left part
            const datasLeft = rowData.documents[0].data || [];
            const docsLeft = datasLeft.filter((data) => {
                return checkedDocs.find((item) => item === data.publicID);
            });
            if (!_.isEmpty(docsLeft)) {
                _.forEach(docsLeft, (doc) => {
                    docsToCombine.push(doc);
                });
            }

            // right part
            const datasRight = rowData.documents[1].data || [];
            const docsRight = datasRight.filter((data) => {
                return checkedDocs.find((item) => item === data.publicID && !item.includes('ignore'));
            });
            if (!_.isEmpty(docsRight)) {
                _.forEach(docsRight, (doc) => {
                    docsToCombine.push(doc);
                });
            }
        });
        // length === 1 and docUID exist call OOTB download
        if (docsToCombine.length === 1) {
            // only one document
            const doc = docsToCombine[0];
            setLoadingMask(true);
            await DocumentsUtil.tryDownloadDocument(
                doc, authHeader, history, WniDocumentRetrievalService,
                successCallback, errorCallback
            );
        } else {
            setLoadingMask(true);
            await DocumentsUtil.printAllDocuments(
                docsToCombine, authHeader, WniMultiDocumentRetrievalService,
                successCallback, printAllErrorCallBack
            );
        }
    };

    const overrideProps = {
        repeataccordion: {
            data: renderData,
            accordionStates: accordionStates,
            closeOthers: false
        },
        quoteDocActionTitle: {
            className: `${styles.flexEnd} ${styles.bottomSpace}`
        },
        accountDocAccordion: {
            className: styles.docAccordion
        },
        ...generateOverrides()
    };
    const initProps = {
        ...props,
        forceUI: metadata.pageContent,
        forceOverrideProps: overrideProps,
        forceOnDocSelectedAllFn: forceOnDocSelectedAllFn,
        forceGetCheckBoxFn: forceGetCheckBoxFn,
        forcehandleValueChangeFn: forcehandleValueChangeFn,
        forceDocumentChecked: forceDocumentChecked(),
        forceHasDocSelected: forceHasDocSelected,
        forceDocuments: forceDocuments(),
        forceOnDocPrintAllFn: forceOnDocPrintAllFn,
        forceDocumentColumnName: documentNameColumnPath
    };

    return (
        <BaseDocumentCheckedComponent
            {...initProps}
        />
    );
}

DocumentSectionComponent.propTypes = {
    renderData: PropTypes.arrayOf({}).isRequired,
    wizardTitleClass: PropTypes.string,
    showSendViaEmail: PropTypes.bool,
    history: PropTypes.shape({}),
    submission: PropTypes.shape({}),
    useJobNumber: PropTypes.bool,
    showQuoteDocTitleAction: PropTypes.bool,
    showCheckboxIcon: PropTypes.bool
};
DocumentSectionComponent.defaultProps = {
    wizardTitleClass: '',
    showSendViaEmail: true,
    history: {},
    submission: {},
    useJobNumber: false,
    showQuoteDocTitleAction: true,
    showCheckboxIcon: true
};
export default DocumentSectionComponent;
