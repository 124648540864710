import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './FNOLContactAgentPage.metadata.json5';
import styles from './FNOLContactAgentPage.module.scss';

function FNOLContactAgentPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history
    } = props;

    const backToClaim = () => {
        history.push('/claims');
    };

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        backClaim: {
            onClick: backToClaim
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={claimVM}
            resolveValue={readValue}
            classNameMap={styles}
            overrideProps={overrides}
            onModelChange={updateWizardData}
        />
    );
}

FNOLContactAgentPage.propTypes = wizardProps;
FNOLContactAgentPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLContactAgentPage));
