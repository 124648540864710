import { defineMessages } from 'react-intl';

export default defineMessages({
    waViewAndEditLabel: {
        id: 'wni.quoteandbind.wa-wizard.wa-residence.View/Edit',
        defaultMessage: 'View/Edit',
    },
    waViewLabel: {
        id: 'wni.quoteandbind.wa-wizard.wa-residence.View',
        defaultMessage: 'View',
    },
    next: {
        id: 'wni.quoteandbind.wa-wizard.wa-residence.Next',
        defaultMessage: 'Next',
    },
    removeOperatorTitle: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Remove Operator?',
        defaultMessage: "Remove Operator?"
    },
    removeOperatorDescription: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.removeOperatorDescription',
        defaultMessage: 'Are you sure you want to remove this operator from the list of operators?',
    },
    removeIncludePNIOperatorDescription: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.removeIncludePNIOperatorDescription',
        defaultMessage:
            'PNI operator cannot be deleted, are you sure to delete other operators?',
    },
    Ok: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.OK',
        defaultMessage: 'OK'
    },
    Cancel: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Cancel',
        defaultMessage: 'Cancel'
    },
    licensedStatus: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Driver License Status',
        defaultMessage: 'Driver License Status'
    },
    permitStatus: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Permit Status',
        defaultMessage: 'Permit Status'
    },
    IDStatus: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.ID Status',
        defaultMessage: 'ID Status'
    },
    licensedNumber: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Driver License Number',
        defaultMessage: 'Driver License Number'
    },
    permitNumber: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Permit Number',
        defaultMessage: 'Permit Number'
    },
    IDNumber: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.ID Number',
        defaultMessage: 'ID Number'
    },
    licensedState: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Driver License State',
        defaultMessage: 'Driver License State'
    },
    permitState: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.Permit State',
        defaultMessage: 'Permit State'
    },
    IDState: {
        id: 'wni.quoteandbind.wa-wizard.wa-operator.ID State',
        defaultMessage: 'ID State'
    },
    hasLicenseSuspended: {
        id: 'wni.quoteandbind.wal.operator.Has the driver’s license been suspended/revoked within the last 5 years?',
        defaultMessage: 'Has the driver’s license been suspended/revoked within the last 5 years?'
    },
    completedSafetyCoursesInfo: {
        id: 'wni.quoteandbind.wal.operator.safety',
        defaultMessage: 'Has this operator/household member completed any safety course for operating the watercraft?'
    },
});