import { defineMessages } from 'react-intl';

export default defineMessages({
    caTypeOfIncident: {
        id: 'pe.fnol.ca.views.ca-claim-type.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    caCollision: {
        id: 'pe.fnol.ca.views.ca-claim-type.Collision',
        defaultMessage: 'Collision'
    },
    caTheft: {
        id: 'pe.fnol.ca.views.ca-claim-type.Theft',
        defaultMessage: 'Theft'
    },
    caGlass: {
        id: 'pe.fnol.ca.views.ca-claim-type.Glass',
        defaultMessage: 'Glass'
    },
    caOther: {
        id: 'pe.fnol.ca.views.ca-claim-type.Other',
        defaultMessage: 'Other'
    },
    caNewVehicle: {
        id: 'pe.fnol.ca.directives.vehicle-incident.New Vehicle',
        defaultMessage: 'New Vehicle'
    },
    caWhoDriving: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Who was driving?',
        defaultMessage: 'Who was driving?'
    },
    caChooseDriver: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Choose Driver',
        defaultMessage: 'Choose Driver'
    },
    caVehicleSafe: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Is this vehicle safe to drive?',
        defaultMessage: 'Is this vehicle safe to drive?'
    },
    caResponseYes: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Yes',
        defaultMessage: 'Yes'
    },
    caResponseNo: {
        id: 'pe.fnol.ca.directives.vehicle-incident.No',
        defaultMessage: 'No'
    },
    caDidAirbagDeploy: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Did the airbag deploy?',
        defaultMessage: 'Did the airbag deploy?'
    },
    caEquipmentFailure: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Was there equipment failure?',
        defaultMessage: 'Was there equipment failure?'
    },
    caWasVehicleTowed: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Was the vehicle towed?',
        defaultMessage: 'Was the vehicle towed?'
    },
    caWasRentalNeeded: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Is a rental car needed?',
        defaultMessage: 'Is a rental car needed?'
    },
    caPointOfImpact: {
        id: 'pe.fnol.ca.directives.vehicle-collision.What was the initial point of impact?',
        defaultMessage: 'What was the initial point of impact?'
    },
    caPassengers: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Passengers',
        defaultMessage: 'Passengers'
    },
    caAddPassenger: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Add Passenger',
        defaultMessage: 'Add Passenger'
    },
    caAnotherPerson: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Another person',
        defaultMessage: 'Another person'
    },
    caOtherDriver: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Other Driver',
        defaultMessage: 'Other Driver'
    },
    caNewDriver: {
        id: 'pe.fnol.ca.directives.vehicle-incident.New Driver',
        defaultMessage: 'New Driver'
    },
    caNewDriverDetails: {
        id: 'pe.fnol.ca.directives.add-new-driver.New driver details',
        defaultMessage: 'New driver details'
    },
    caFirstName: {
        id: 'pe.fnol.ca.directives.vehicle-incident.First name',
        defaultMessage: 'First name'
    },
    caLastName: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Last name',
        defaultMessage: 'Last name'
    },
    caHomePhone: {
        id: 'pe.fnol.ca.directives.add-new-driver.Home Phone',
        defaultMessage: 'Home Phone'
    },
    caMobilePhone: {
        id: 'pe.fnol.ca.directives.add-new-driver.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    caPhoneNumber: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Phone number',
        defaultMessage: 'Phone number'
    },
    caRemove: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Remove',
        defaultMessage: 'Remove'
    },
    caDelete: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Delete',
        defaultMessage: 'Delete'
    },
    caAddAnotherVehicle: {
        id: 'pe.fnol.ca.views.ca-collision-vehicles.Add Another Vehicle',
        defaultMessage: 'Add Another Vehicle'
    },
    caCollisionDetails: {
        id: 'pe.fnol.wizard.steps.ca.CollisionDetails',
        defaultMessage: 'Details'
    },
    caGlassDetails: {
        id: 'pe.fnol.wizard.steps.ca.GlassDetails',
        defaultMessage: 'Damage'
    },
    caTheftDetails: {
        id: 'pe.fnol.wizard.steps.ca.TheftDetails',
        defaultMessage: 'Details'
    },
    caFleet: {
        id: 'pe.fnol.wizard.steps.ca.Fleet',
        defaultMessage: 'Fleet'
    },
    caWhat: {
        id: 'pe.fnol.wizard.steps.ca.What',
        defaultMessage: 'What'
    },
    caClaimChooseVehicle: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Choose Vehicle',
        defaultMessage: 'Choose Vehicle'
    },
    caClaimWhichVehicle: {
        id: 'pe.fnol.ca.directives.vehicle-incident.Which vehicle was damaged?',
        defaultMessage: 'Which vehicle was damaged?'
    },
    caFleetFilter: {
        id: 'pe.fnol.ca.views.ca-fleet.Filter',
        defaultMessage: 'Filter'
    },
    caVehicles: {
        id: 'pe.fnol.wizard.steps.ca.Vehicles',
        defaultMessage: 'Vehicles'
    },
    caVehicleColor: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Vehicle Color',
        defaultMessage: 'Vehicle Color'
    },
    caVehiclePlate: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.License Plate',
        defaultMessage: 'License Plate Number'
    },
    caVehicleMake: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Make',
        defaultMessage: 'Make'
    },
    caVehicleManufacturer: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Choose Vehicle Manufacturer',
        defaultMessage: 'Choose Vehicle Manufacturer'
    },
    caVehicleModel: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Model',
        defaultMessage: 'Model'
    },
    caVehicleState: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.State',
        defaultMessage: 'State'
    },
    caVehicleChooseState: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Choose Vehicle State',
        defaultMessage: 'Choose Vehicle State'
    },
    caVehicleVIN: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.VIN',
        defaultMessage: 'VIN'
    },
    caVehicleYear: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Year',
        defaultMessage: 'Year'
    },
    caVehicleChooseYear: {
        id: 'pe.fnol.ca.directives.add-new-vehicle.Choose Vehicle Year',
        defaultMessage: 'Choose Vehicle Year'
    },
    caDeleteVehicleMessage: {
        id: 'pe.fnol.ca.controllers.CACollisionVehicleCtrl.Are you sure you want to delete this third party vehicle?',
        defaultMessage: 'Are you sure you want to delete this third party vehicle?'
    },
    caDeleteVehicleTitle: {
        id: 'pe.fnol.ca.controllers.CACollisionVehicleCtrl.Delete Third Party Vehicle?',
        defaultMessage: 'Delete Third Party Vehicle?'
    },
    CAFleetAffeected: {
        id: 'pe.fnol.ca.views.ca-fleet.Select affected vehicles',
        defaultMessage: 'Select affected vehicle(s)'
    },
    CAFleetSearch: {
        id: 'pe.fnol.ca.views.ca-fleet.Search for License No. or VIN',
        defaultMessage: 'Search for License No. or VIN'
    },
    CAFleetDetails: {
        id: 'pe.fnol.ca.views.ca-fleet.License No.',
        defaultMessage: 'License Number'
    },
    CAFleetNo: {
        id: 'pe.fnol.ca.views.ca-fleet.VIN',
        defaultMessage: 'VIN'
    },
    CAFleetLocation: {
        id: 'pe.fnol.ca.views.ca-fleet.State',
        defaultMessage: 'State'
    },
    CAFleetLocationNo: {
        id: 'pe.fnol.ca.views.ca-fleet.Make',
        defaultMessage: 'Make'
    },
    CAFleetMake: {
        id: 'pe.fnol.ca.views.ca-fleet.Model',
        defaultMessage: 'Model'
    },
    CAFleetYear: {
        id: 'pe.fnol.ca.views.ca-fleet.Year',
        defaultMessage: 'Year'
    },
    CAVehiclesSelected: {
        id: 'pe.fnol.ca.components.summary.Vehicle(s) Selected',
        defaultMessage: 'Vehicle(s) Selected'
    },
    CAAllModels: {
        id: 'pe.fnol.ca.views.ca-fleet.All Models',
        defaultMessage: 'All Models'
    },
    caCreateDraftClaim: {
        id: 'pe.fnol.ca.config.FlowConfig.Creating commercial auto draft claim...',
        defaultMessage: 'Creating commercial auto draft claim...'
    },
    caCreateDraftErrorTitle: {
        id: 'pe.fnol.ca.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    caCreateDraftErrorMessage: {
        id: 'pe.fnol.ca.config.FlowConfig.An error occurred attempting to create your commercial auto claim.',
        defaultMessage: 'An error occurred attempting to create your commercial auto claim.'
    },
    caSaveDraftClaim: {
        id: 'pe.fnol.ca.config.FlowConfig.Saving commercial auto draft claim...',
        defaultMessage: 'Saving commercial auto draft claim...'
    },
    caSaveDraftErrorTitle: {
        id: 'pe.fnol.ca.config.FlowConfig.Error saving claim',
        defaultMessage: 'Error saving claim'
    },
    caSaveDraftErrorMessage: {
        id: 'pe.fnol.ca.config.FlowConfig.An error occurred attempting to save your commercial auto claim.',
        defaultMessage: 'An error occurred attempting to save your commercial auto claim.'
    }
});
