import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentAccountType: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Bank Account Type',
        defaultMessage: 'Bank Account Type'
    },
    paymentEffectiveDate: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Effective Date',
        defaultMessage: 'Effective Date'
    },
    removeAutoPayment: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Remove AutoPay',
        defaultMessage: 'Remove AutoPay'
    },
    setupAutoPayment: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Save & Set as My AutoPay Method',
        defaultMessage: 'Save & Set as My AutoPay Method'
    },
    removeConfirmMessage: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Are you sure you want to remove the AutoPay',
        defaultMessage: 'Are you sure you want to remove the AutoPay?'
    }
});