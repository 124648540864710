import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import RTVehiclesPage from './RTVehiclesPage';

function RTVehiclesReadOnlyPage(props) {

    return (
        <RTVehiclesPage {...props} isReadOnly checkRequiredForIssuance={false} />
    );
}

export default RTVehiclesReadOnlyPage;
