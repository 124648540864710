
import React, {
    useCallback, useContext
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WizardPageJumpUtil, WniClausesUtil, ActiveQuoteUtil, WniProductsUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DropdownMenuHeader } from '@jutro/components';
import { DropdownMenuLink } from '@jutro/router';
import metadata from './ActiveQuotesDropdownComponent.metadata.json5';


/**
 * ActiveQuotes Dropdown components that renders Other Active Quotes dropdown list
 * @param {object} props
 * @returns {object} React Component
 */
function ActiveQuotesDropdownComponent(props) {
    const {
        accountJobs,
        postalCode,
        // currentJobID,
        // currentSelectedVersion,
        //
        onClickQuotePeriod,
    } = props;

    const history = useHistory();
    const translator = useTranslator();  
    const intl = useContext(IntlContext);
    const defaultOnClickQuotePeriod = useCallback(async ({
        targetJobID: jobID,
        targetProductCode: productCode,
        // targetPostalCode,
        // isSelectedBranch,
        // targetPeriodPublicID: periodPublicID,
        //
        hasBeenRatedBefore,
    }) => {
        if (hasBeenRatedBefore) {
            WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, {
                jobID
            });
        } else { // if (isSelectedBranch) {
            WizardPageJumpUtil.goToNewQuoteWizard(history, {
                productCode, postalCode, jobID
            });
        }
        // else {
        //     // Switch to new branch before retrieving wizardDataDTO
        //     WizardPageJumpUtil.goToNewQuoteWizardAndSwitchToBranch(history, {
        //         productCode, postalCode, jobID, periodPublicID
        //     });
        // }
    }, [history ,postalCode]);
    /**
     * Render accountJobPeriodDTO to DorpdownMenuLink
     * @param {object} accountJobPeriodDTO instance of AccountJobPeriodDTO
     * @returns {object}
     */
    const renderJobPeriod = useCallback((accountJobPeriodDTO, {
        jobID, 
        productCode,
    }) => {
        const {
            publicID: periodPublicID,
            policyType_Ext: initPolicyType = '',
            branchName,
            // branchCode,
            periodStatus,
            //
            isCurrentBranch,
            hasBeenRatedBefore,
            //
            hoCoverageForm,
            totalPremium
        } = accountJobPeriodDTO;
        
        // ============================================
        let versionName = '';
        const hasMultiplePeriodsFor = ActiveQuoteUtil.hasMultiplePeriodsInAccountJob(accountJobs, jobID, productCode);
        if (hasMultiplePeriodsFor) {
            versionName = branchName;
        }
        // ============================================

        let policyType = initPolicyType;
        if (_.isEmpty(policyType) && productCode === 'HOPHomeowners') {
            policyType = 'Signature';
        }

        let name = `${policyType} ${versionName} ${periodStatus}`;

        // To be refactored. e.g. extra lob specific actions into a map;
        if (productCode === 'HOPHomeowners' && !_.isNil(hoCoverageForm)) {
            name = `${policyType} ${hoCoverageForm.toUpperCase()} ${versionName} ${periodStatus}`;
        }

        if(totalPremium) {
            const nameWithAmount = WniClausesUtil.getLabelNameWithAmount('', totalPremium, intl)
            name = `${name} ${nameWithAmount}`
        }

        const openQuoteWizard = async () => {
            const quoteJumpParams = {
                targetJobID: jobID,
                targetProductCode: productCode,
                targetPeriodPublicID: periodPublicID,
                isSelectedBranch: isCurrentBranch,
                //
                hasBeenRatedBefore,
            };
            if (onClickQuotePeriod) {
                await onClickQuotePeriod(quoteJumpParams, defaultOnClickQuotePeriod);
            } else {
                await defaultOnClickQuotePeriod(quoteJumpParams);
            }
        };

        return (
            <DropdownMenuLink
                type="action"
                onClick={openQuoteWizard}
                key={`${name}-MenuLink`}
            >
                {name}
            </DropdownMenuLink>
        );
    }, [onClickQuotePeriod, defaultOnClickQuotePeriod]);

    /**
     * Render AccountActiveJobDTO as a DropdownMenuHeader
     * @param {object} accountActiveJobDTO an instance of AccountActiveJobDTO
     * @returns {object}
     */
    const renderAccountJobDTO = useCallback((accountActiveJobDTO) => {

        const {
            jobID,
            product,
            productCode,
            periods: jobPeriods = [],
        } = accountActiveJobDTO;

        const normalizedProductName = WniProductsUtil.getProductNameByLinePattern(product);
        const title = `${normalizedProductName} - ${jobID}`;
        const content = jobPeriods.map((jobPeriod) => renderJobPeriod(jobPeriod, {
            jobID, 
            productCode,
        }));

        return (
            <DropdownMenuHeader
                title={title}
                key={`${jobID}-MenuHeader`}
            >
                {content}
            </DropdownMenuHeader>
        );
    }, [renderJobPeriod, translator]);

    /**
     * A List off AccountActiveJobDTO instances
     * @param {object} accountActiveJobDTOList 
     * @returns {object}
     */
    const renderActiveJobs = useCallback((accountActiveJobDTOList) => {
        return accountActiveJobDTOList.map(renderAccountJobDTO);
    }, [renderAccountJobDTO]);

    //----------------------------------
    const dropdownMenuContent = renderActiveJobs(accountJobs);
    const overrideProps = {
        activeQuotesDropdown: {
            content: dropdownMenuContent,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {

        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ActiveQuotesDropdownComponent.propTypes = {
    // currentJobID: PropTypes.string.isRequired,
    // currentSelectedVersion: PropTypes.string.isRequired,
    /**
     * A list of AccountActiveJobDTO
     */
    accountJobs: PropTypes.arrayOf(PropTypes.object),
    postalCode: PropTypes.string,
    onClickQuotePeriod: PropTypes.func,
};

ActiveQuotesDropdownComponent.defaultProps = {
    accountJobs: [],
    postalCode: undefined,
    // currentJobID: undefined,
    // currentSelectedVersion: undefined,
    onClickQuotePeriod: undefined,
};

export default ActiveQuotesDropdownComponent;
