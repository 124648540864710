import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniBillingAndPaymentService {
    static removeAutoPayment(accountNumber, streamInternalId,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'removeAutoPayment', [accountNumber, streamInternalId], additionalHeaders);
    }

    static setUpAutoPayByStreamNumber(accountNumber, streamNumber, paymentMethod,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'setUpAutoPayByStreamNumber', [accountNumber, streamNumber, paymentMethod], additionalHeaders);
    }

    static getAccountPaymentDetails(accountNumber, streamNumber,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getAccountPaymentDetails', [accountNumber, streamNumber], additionalHeaders);
    }

    static removeAutoPaymentByStreamNumber(accountNumber, streamNumber,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'removeAutoPaymentByStreamNumber', [accountNumber, streamNumber], additionalHeaders);
    }

    static retriveInvoiceStreamDetail(accountNumber, streamNumber,
        additionalHeaders = {}) {
            return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
                'retriveInvoiceStreamDetail', [accountNumber, streamNumber], additionalHeaders);
        }

    static getAccountPaymentList(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getAccountPaymentList', [], additionalHeaders);
    }
}
