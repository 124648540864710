import { defineMessages } from 'react-intl';

export default defineMessages({
    companyEmail: {
        id: 'custom.gateway.views.new-quote.Commercial.Primary Email',
        defaultMessage: 'Primary Email'
    },
    personEmail: {
        id: 'custom.gateway.views.new-quote.Personal.Email Address',
        defaultMessage: 'Email Address'
    },
    personWorkNumber: {
        id: 'custom.gateway.views.new-quote.Personal.Work Number',
        defaultMessage: 'Work Number'
    },
    companyWorkNumber: {
        id: 'custom.gateway.views.new-quote.Personal.Office Phone Number',
        defaultMessage: 'Office Phone Number'
    }
});
