import { defineMessages } from 'react-intl';

export default defineMessages({
    NCCIRiskID: {
        id: "wni.quoteandbind.wc.stateSpecificInfo.NCCI Risk ID",
        defaultMessage: "{clientName} - NCCI Risk ID "
    },
    wcViewAndEditLabel: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.View/Edit',
        defaultMessage: 'View/Edit',
    },
    wcViewLabel: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.View',
        defaultMessage: 'View',
    },
    removeSplitPeriodTitle: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.Remove Split Speriod?',
        defaultMessage: "Remove Split Speriod?"
    },
    removeSplitPeriodDescription: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.removeSplitPeriodDescription',
        defaultMessage: 'Are you sure you want to remove this location from the list of locations?',
    },
    Ok: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.OK',
        defaultMessage: 'OK'
    },
    splitPopupOK: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.Save',
        defaultMessage: 'Save'
    },
    removeSplitPopupOK: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.Remove period',
        defaultMessage: 'Remove period'
    },
    Cancel: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.Cancel',
        defaultMessage: 'Cancel'
    },
    Exit: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.Exit',
        defaultMessage: 'Exit'
    },
    removeCoveredEmployeeTitle: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.Remove Covered Employees',
        defaultMessage: 'Remove Covered Employees?'
    },
    removeCoveredEmployeeDescription: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.removeCoveredEmployeeDescription',
        defaultMessage: 'Are you sure you want to remove this covered employee from the list of employees?',
    },
    splitDateValidation: {
        id: 'wni.quoteandbind.wc-wizard.wc-statespecific.SplitDateValidation',
        defaultMessage: 'State already had split date {date}.'
    },
    modifierRangeInfo: {
        id : 'wni.quoteandbind.wc-wizard.wc-statespecific.modifierRangeInfo',
        defaultMessage: 'The system allows a total request of {max} credit or debit. For requests outside of that range, please contact an underwriter.'
    },
    creditDebitOutOfRange: {
        id : 'wni.quoteandbind.wc-wizard.wc-statespecific.creditDebitOutOfRange',
        defaultMessage: 'The selected modifier amounts are out of acceptable ranges for each category, please correct this before proceeding. To be approved for modifiers beyond the acceptable range please contact your underwriter.'
    },
    justificationsRequired: {
        id : 'wni.quoteandbind.wc-wizard.wc-statespecific.justificationsRequired',
        defaultMessage: 'Please enter a justification for any entered credits or debits before proceeding'
    },
    invalidRateFactorError: {
        id : 'wni.quoteandbind.wc-wizard.wc-statespecific.invalidRateFactorError',
        defaultMessage: 'Please enter a valid percentage'
    },
    scheduleRatingFactor: {
        id : 'wni.quoteandbind.wc-wizard.wc-statespecific.scheduleRatingFactor',
        defaultMessage: 'Schedule Rating Factor'
    },
    stateSpecificInfoforAK:{
        id:'wni.quoteandbind.wc-wizard.wc-statespecific.stateSpecificInfoforAK',
        defaultMessage:'AK statute mandates that Officers of Corporations, Municipal Corporations and Nonprofit Corporations and members of LLC must be added as an employee if less than 10% ownership.'
    }
});