import _ from 'lodash';


/**
 * Generate field metadata for terms
 * @param {object} term the clause terms
 * @param {string} termPathPrefix prefix for the term's path
 * @param {object} translator to translate the strings/objects
 * @param {object} isClauseEditable to get the isEditable
 * @param {object} messages to get messages
 * @returns {Object} the field metadata
 */
 const getMetadataForField = (
    {
        term,
        termPathPrefix,
        translator,
        isClauseEditable,
        messages
    }
) => {
    const hasAvailableValues = _.has(term, 'options') && !_.isEmpty(term.options);
    const valueType = _.has(term, 'valueType') && term.valueType;

    const availableValues = hasAvailableValues && term.options.filter((option) => {
        // Filtering out the None Selected value as the backend sets the code value
        // for None Selected to be an empty string. This results in the option not
        // showing in the dropdown select component. We are handling this by using
        // None Selected as a placeholder which will always be shown to the user
        return option.name !== translator(messages.noneSelected);
    }).map((option) => {
        return {
            code: '',
            id: '',
            ...option
        };
    });

    if (!hasAvailableValues && valueType === 'Money') {
        return {
            component: "Currency",
            componentProps: {
                value: {
                    amount: term.directValue,
                    currency: "USD"
                },
                onValueChange: 'onChangeClause',
                onBlur: 'onSyncCoverages',
                maxValue: term.directValueMax,
                minValue: term.directValueMin,
                path: `${termPathPrefix}.directValue`,
                required: term.required,
            }
        }
    }

    if (hasAvailableValues && valueType === 'bit') {
        // chosenTerm for bit terms with more than 2 choices?
        return {
            component: 'toggle',
            componentProps: {
                value: term.chosenTerm,
                availableValues: term.options.filter((option) => option.code),
                required: term.required
            }
        };
    }

    if (!hasAvailableValues && valueType === 'datetime') {
        return {
            component: 'WniDateField',
            componentProps: {
                showTime: false,
                dataType: 'string',
                placeholder: 'MM/DD/YYYY',
                onValueChange: 'onChangeClause',
                onBlur: 'onSyncCoverages',
                value: term.directDateValue,
                path: `${termPathPrefix}.directDateValue`,
                required: term.required
            }
        };
    }

    if (valueType === 'shorttext') {
        return {
            component: 'textarea',
            componentProps: {
                onValueChange: 'onChangeClause',
                onBlur: 'onSyncCoverages',
                path: `${termPathPrefix}.directStringValue`,
                value: term.directStringValue,
                required: term.required,
                placeholder: '',
            }
        };
    }

    if (hasAvailableValues && !valueType) {
        const componentDetails = {
            component: 'dropdownselect',
            componentProps: {
                availableValues,
                path: `${termPathPrefix}.chosenTerm`,
                value: term.chosenTerm,
                readOnly: !isClauseEditable,
                required: term.required,
                // placeholder: term.required ? '-- Required for Quote --' : '',
                placeholder: '',
            }
        };

        if (_.some(term.options, ['name', translator(messages.noneSelected)])) {
            _.set(componentDetails, 'componentProps.placeholder', translator(messages.noneSelected));
        }
        return componentDetails;
    }
    const getMessageByMinMax = () => {
        let key = 'maxValue';
        if (term.directValueMax && term.directValueMin) {
            key = 'selectedValue';
        } else if (term.directValueMin) {
            key = 'minValue';
        }
        return messages[key];
    };
    return {
        component: 'inputnumber',
        componentProps: {
            onValueChange: 'onChangeClause',
            onBlur: 'onSyncCoverages',
            value: term.directValue,
            maxValue: term.directValueMax,
            minValue: term.directValueMin,
            path: `${termPathPrefix}.directValue`,
            required: term.required,
            // placeholder: term.required ? '-- Required for Quote --' : '',
            placeholder: '',
            messageProps: {
                validationMaxValue: translator(getMessageByMinMax(), {
                    maxValue: term.directValueMax,
                    minValue: term.directValueMin
                }),
                validationMinValue: translator(getMessageByMinMax(), {
                    maxValue: term.directValueMax,
                    minValue: term.directValueMin
                }),
            }
        }
    };
};

/**
* Generate clauses term metadata from clause
* @param {object} clauseTerms the clause terms
* @param {string} clausePublicID the clause publicID
* @param {boolean} isEditable the clause is editable
* @param {path} path path of this coverage
* @param {idPrefix} idPrefix idPrefix of this clause term
* @param {isDisabled} isDisabled wether this coverage term is disabled
* @param {labelPosition} labelPosition display method of label
* @param {labelTop} labelTop labTop style
* @param {object} translator to translate the strings/objects
* @param {object} messages to get messages
* @returns {object | null} the metadata to render
*/
const getTermMetadata = (
    {
        clauseTerms,
        clausePublicID,
        isEditable,
        path,
        idPrefix,
        isDisabled,
        labelPosition,
        labelTop,
        translator,
        messages
    }
) => {
    const isClauseEditable = isEditable;
    const retval = clauseTerms.map((term, index) => {
        const { isEditable: isTermEditable = true } = term;
        const termPathPrefix = `${path}.terms.children[${index}]`;
        const termComponent = {
            id: `${idPrefix}ClauseTerm_[${clausePublicID}]_[${index}]`,
            type: 'field',
            componentProps: {
                label: term.name,
                path: `${termPathPrefix}.chosenTerm`,
                readOnly: !isTermEditable || !isClauseEditable || term.readOnly_Ext,
                disabled: isDisabled,
                value: term.chosenTerm,
                labelPosition: labelPosition,
                layout: labelTop ? 'full-width' : null,
                onValueChange: 'onChangeAndSyncClause',
                required: isClauseEditable && term.required,
                className: 'clause_term'
            }
        };

        return _.merge(
            {},
            termComponent,
            getMetadataForField(
                {
                    term,
                    termPathPrefix,
                    translator,
                    isClauseEditable,
                    messages
                }
            )
        );
    });
    return retval;
};

/**
     * Generate clauses schedule metadata from clause
     * @param {object} clauseSchedule the clause schedule
     * @param {string} clausePublicID the clause publicID
     * @returns {object | null} the metadata to render
     */
const getScheduleMetadata = ({
    clauseSchedule, clausePublicID,
    idPrefix, path, showSchedule, labelPosition, isEditable
}) => {
    const schedulePath = `${path}.schedule`;

    if (_.isEmpty(clauseSchedule) || !showSchedule) {
        return [];
    }

    return [{
        id: `${idPrefix}ClauseSchedule_[${clausePublicID}]`,
        type: 'field',
        component: 'ScheduleItemsComponent',
        componentProps: {
            path: schedulePath,
            onScheduleChange: 'onScheduleChange',
            value: clauseSchedule,
            labelPosition,
            showTitle: false,
            readOnly: !isEditable
        }
    }];
};

export default {
    // getMetadataForField,
    getTermMetadata,
    getScheduleMetadata,
};
