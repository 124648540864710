import { defineMessages } from 'react-intl';

export default defineMessages({
    StandardCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Personal Property.Personal Property Coverages',
        defaultMessage: 'Personal Property Coverages',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Personal Property.Personal Property Additional Coverages',
        defaultMessage: 'Personal Property Additional Coverages',
    },
    AddAdditionalCoverage: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Personal Property.Add Additional Coverages',
        defaultMessage: 'Add Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Personal Property.Personal Property Exclusions and Conditions',
        defaultMessage: 'Personal Property Exclusions and Conditions',
    },
    AddExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Personal Property.Add Exclusions and Conditions',
        defaultMessage: 'Add Exclusions and Conditions',
    }
});