import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { CPModifiersPage } from 'wni-capability-quoteandbind-cp-react';

function CPModifiersChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('CP7Line', submissionVM);
    return (
        <CPModifiersPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

CPModifiersChangePage.propTypes = CPModifiersPage.propTypes;
CPModifiersChangePage.defaultProps = CPModifiersPage.defaultProps;

export default CPModifiersChangePage;