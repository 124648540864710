import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import PUCoverageUtil from '../../util/PUCoverageUtil';
import PUClausesComponentVM from '../../components/PUClausesComponentVM/PUClausesComponentVM';
import PUSingleClauseComponentVM from '../../components/PUSingleClauseComponentVM/PUSingleClauseComponentVM';
import metadata from './PULineStandardCoverages.metadata.json5';
import styles from './PULineStandardCoverages.module.scss';
import PUTermsPopup from '../../components/PUTermsPopup/PUTermsPopup';

/**
* @typedef {{
    *  isOpen: Boolean
    *  clauseCode: String
    *  clauseVMPath: String
    *  coverages: Array.<String>
    * }} TermPopopStatusType
    */

function PULineStandardCoverages(props) {
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService,
        loadingClausesCodes,
        setLoadingLineCoverages,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    /**
     * @type {[TermPopopStatusType, React.Dispatch<TermPopopStatusType>]}
     */
    const [termsPopupStatus, setTermsPopupStatus] = useState({
        isOpen: false,
        clauseCode: undefined,
        clauseVMPath: undefined,
    })

    const getLineStandardCoverages = useCallback(() => {
        const lineStandardCoverages = _.get(selectedVersion, 'coverages.lineStandardCoverages', []);
        return lineStandardCoverages;
    }, [selectedVersion]);

    const lineCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.lineStandardCoverages`;
    const lineCoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.lineStandardCoverages`;
    const lineStandardCoverages = getLineStandardCoverages();

    const showTermsPopup = (coverageToOpen) => {
        const coverageIndex = lineStandardCoverages
            .findIndex((coverageDTO) => _.get(coverageDTO, 'code_Ext') === _.get(coverageToOpen, 'code_Ext'))

        const clauseVMPath = `${lineCoveragesVMPath}.children[${coverageIndex}]`

        setTermsPopupStatus({
            isOpen: true,
            clauseCode: _.get(coverageToOpen, 'code_Ext'),
            clauseVMPath: clauseVMPath,
        })

    }

    const onOpenCoverageDetailsPopup = (clausePatternCode)=>{
        const coverageToOpen = lineStandardCoverages.find((cov)=>cov.code_Ext === clausePatternCode);
        const hasTerms = _.get(coverageToOpen, 'terms', []).length > 0
        if (hasTerms) {
            showTermsPopup(coverageToOpen)
        }
    }

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = PUCoverageUtil.setClauseValue(lineStandardCoverages, lineCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, lineCoveragePath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            lineStandardCoverages,
            lineCoveragesVMPath,
            lineCoveragePath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = PUCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = PUCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingLineCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingLineCoverages,
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            lineStandardCoverages: {
                loadingClause: loadingClausesCodes,
                path: lineCoveragesVMPath,
                value: lineStandardCoverages,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    PUSingleClauseComponentVM: PUSingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
            }
        };
    }, [
        loadingClausesCodes,
        lineCoveragesVMPath,
        lineStandardCoverages,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
            onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
        },
        resolveComponentMap: {
            PUClausesComponentVM: PUClausesComponentVM,
        }
    };
    return (
        <>
            <PUTermsPopup
                isOpen={termsPopupStatus.isOpen}
                closePopup = {() => setTermsPopupStatus({
                    ...termsPopupStatus,
                    isOpen: false
                })}
                clauseCode={termsPopupStatus.clauseCode}
                clauseVMPath={termsPopupStatus.clauseVMPath}
                coverages={lineStandardCoverages}
                changeSubmission={changeSubmission}
                changeSubmissionAndSync={changeSubmissionAndSync}
                onSyncCoverages={onSyncCoverages}
                loadingClausesCodes={loadingClausesCodes}
                isEditable={isEditable}
            />
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={{}}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </>
        
    );
}

PULineStandardCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
PULineStandardCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default PULineStandardCoverages;
