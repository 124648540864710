

import React from 'react';
import { IMMuseumPage } from 'wni-capability-quoteandbind-im-react';


const IMMuseumChangePage = (props) => {
    return <IMMuseumPage {...props} isPolicyChange />
};
export default IMMuseumChangePage

