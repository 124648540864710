import _ from 'lodash';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caStateSpecificInfoService'), method, data, additionalHeaders);
}

export default class CAStateSpecificInfoService {
    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} stateInfo
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateStateInfo(quoteID, sessionUUID, stateInfo, authHeader = {}) {
        return processSubmission(
            'updateStateInfo',
            [quoteID, sessionUUID, stateInfo],
            authHeader
        );
    }


    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader
        );
    }

}