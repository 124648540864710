import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('walIncidentsService'), method, data, additionalHeaders);
}

export default class WALMVRService {
    static getMVRManuallyData(jobId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('getMVRManuallyData', [jobId, sessionUUID], additionalHeaders);
    }

    static getMVRReportData(jobId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('getMVRReportData', [jobId, sessionUUID], additionalHeaders);
    }

    static saveMvr(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('saveMvr', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static updateMvr(jobId, sessionUUID,newModel, additionalHeaders = {}) {
        return processSubmission('updateMvr', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static updateMVRReport(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('updateMVRReport', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static deleteMvr(jobId, sessionUUID, driverId, publicId, additionalHeaders = {}) {
        return processSubmission('deleteMvr', [jobId, sessionUUID, driverId, publicId], additionalHeaders);
    }

}
