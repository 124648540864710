
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALOperatorsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function WALOperatorsChangeReadOnlyPage(props) {

    return (
        <WALOperatorsReadOnlyPage {...props} />
    );

}

WALOperatorsChangeReadOnlyPage.propTypes = WALOperatorsReadOnlyPage.propTypes;
WALOperatorsChangeReadOnlyPage.defaultProps = WALOperatorsReadOnlyPage.defaultProps;

export default WALOperatorsChangeReadOnlyPage;