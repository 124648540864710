import React, {
    useCallback,
    useContext,
} from "react";
import _ from 'lodash'
import { useTranslator } from '@jutro/locale';
import CoverageContext from '../../../../context/CRCoverageContext'
import SingleClauseContext from '../../../../context/CRSingleClauseContext'
import CRClausesComponentVM from '../../../CRClausesComponentVM/CRClausesComponentVM'
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import CRSingleClauseComponentVM from '../../../CRSingleClauseComponentVM/CRSingleClauseComponentVM'
import CoverageUtil from '../../../../util/CRCoverageUtil'
import messages from './CRComplexSchedulePopupCoverages.messages'

const CRComplexSchedulePopupCoverages = (props) => {
    const {
        schedule,
        openedScheduleItemNumber,
        onValidate,
        showErrors,
        isEditing,
        setIsEditing,
    } = props

    const {
        scheduleItems,
    } = schedule

    const translator = useTranslator();

    if (_.isNil(openedScheduleItemNumber)) {
        return null
    }

    const openedScheduleItemIndex = scheduleItems.findIndex((scheduleItem) => scheduleItem.itemNumber === openedScheduleItemNumber)
    const openedScheduleItem = scheduleItems[openedScheduleItemIndex]

    const {
        clausesContainer,
        updateClausesContainer,
        updateVMToServer,
    } = useContext(CoverageContext)
    
    const {
        clausePath: clauseVMPath,
        isEditable,
    } = useContext(SingleClauseContext)

    const clausePath = clauseVMPath.replaceAll('.children', '')

    const getScheduledItemClauses = useCallback(() => {
        const scheduledItemClauses = _.get(openedScheduleItem, 'clauses', []);
        return scheduledItemClauses;
    }, [openedScheduleItem]);

    const scheduledItemClausesPath = `${clausePath}.schedule.scheduleItems[${openedScheduleItemIndex}].clauses`;
    const scheduleItemClauses = getScheduledItemClauses();

    const updateClausesContainerToServer = useCallback(async (newClausesContainer) => {
        updateVMToServer(newClausesContainer, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = CoverageUtil.setClauseValue(scheduleItemClauses, scheduledItemClausesPath, value, changedPath);
            const newClausesContainer = _.clone(clausesContainer)
            _.set(newClausesContainer,`${clausePath}.schedule.updated_Ext`, true)
            _.set(newClausesContainer, scheduledItemClausesPath, newCoverages);
            updateClausesContainer(newClausesContainer);
            setIsEditing(false);
            return newClausesContainer;
        },
        [
            scheduleItemClauses,
            scheduledItemClausesPath,
            clausesContainer,
            clausePath,
            updateClausesContainer,
            setIsEditing
        ]
    );

    const changeClausesContainerAndSync = useCallback(
        (value, changedPath) => {
            const newClausesContainer = changeSubmission(value, changedPath);
            
            updateClausesContainerToServer(newClausesContainer);
        },
        [changeSubmission, updateClausesContainerToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateClausesContainerToServer(clausesContainer);
        },
        [clausesContainer, updateClausesContainerToServer]
    )

    if (_.isEmpty(scheduleItemClauses)) {
        return null
    }

    return <>
        <h5>{translator(messages.scheduledItemCoveragesTitle)}</h5>
        <div className="formCoveragesContainer">
            <CRClausesComponentVM
                splitByClauseCategory= {false}
                sortBySelected
                onSyncCoverages= {onSyncCoverages}
                onChangeSubmissionAndSync = {changeClausesContainerAndSync}
                onChangeClause = {changeSubmission}
                onValidate = {onValidate}
                showAmount = {false}
                path = {scheduledItemClausesPath}
                value = {scheduleItemClauses}
                showErrors ={showErrors}
                componentMapOverrides = {{
                    CRSingleClauseComponentVM: CRSingleClauseComponentVM,
                }}
                setIsEditing ={setIsEditing}
                isEditing = {isEditing}
                isEditable={isEditable}
            />
        </div>
    </>
}

export default CRComplexSchedulePopupCoverages