import { defineMessages } from 'react-intl';

export default defineMessages({
    confirm: {
        id: 'copyVehicleCoverageModal.button.label.Confirm',
        defaultMessage: 'Confirm'
    },
    cancel: {
        id: 'copyVehicleCoverageModal.button.label.Cancel',
        defaultMessage: 'Cancel'
    },
    title: {
        id: 'copyVehicleCoverageModal.title.label',
        defaultMessage: 'Copy Vehicle Coverages'
    },
    copyFromLabel: {
        id: 'copyVehicleCoverageModal.copyFrom.label',
        defaultMessage: 'Copy From:'
    },
    copyToLabel: {
        id: 'copyVehicleCoverageModal.Copy To:',
        defaultMessage: 'Copy To:'
    },
    selectAll: {
        id: 'copyVehicleCoverageModal.Select All',
        defaultMessage: 'Select All'
    }
});
