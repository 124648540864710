import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { DocumentUploadService, DocumentDownloadService } from '@xengage/gw-portals-document-js';

export default class GatewayDocumentService {
    static generateUploadToken(additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'generateUploadToken', [], additionalHeaders);
    }

    static removeDocument(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'removeDocument', [params], additionalHeaders);
    }

    static uploadDocument(file, documentMetadata, additionalHeaders) {
        return DocumentUploadService.send(getProxiedServiceUrl('gatewayUpload'), file, documentMetadata, additionalHeaders);
    }

    static downloadDocument(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('downloadDocument'), documentID, token);
    }
}
