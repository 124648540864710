import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WniPolicyService } from 'wni-capability-gateway';
import appConfig from 'app-config';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { UWUtil } from 'gw-capability-gateway-react-ext';
import { useTranslator } from '@jutro/locale';
import { useBusinessData, useProductsData } from 'wni-portals-util-react';
import { PolicyChangeService, UserService } from 'gw-capability-gateway';
import { WniCustomEndorsementService } from 'wni-capability-policychange';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { ErrorsAndWarningsUtil, SummaryUtil, DocumentsUtil, WniProductsUtil } from 'wni-portals-util-js';
import { SuccessNotification, Link as LinkComponent } from 'gw-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import messages from 'gw-capability-gateway-react/Endorsement/Summary/Summary.messages';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { UnderIssuesTableComponent, DocumentCheckedComponent } from 'wni-capability-gateway-react';
import { Link } from '@jutro/router';
import BackLinkComponent from '../../Components/BackLinkComponent/BackLinkComponent';
import styles from './EndorsementSummary.module.scss';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import clMessages from '../../Components/UnderwritingComponent/UnderwritingComponent.cl.messages';
import metadata from './EndorsementSummary.metadata.json5';
import customMessages from './EndorsementSummary.messages';


function EndorsementSummary(props) {
    const modalApi = useWniModal();
    const {
        fromAccountLanding: {
            quoteDetailsData: {
                jobNumber,
                loadPolicyChangeSummary: policyChange,
                updateJobSummary: customUpdateJobSummary,
            }
        },
        history
    } = props;
    const {
        policy: {
            policyState_Ext: policyState,
            account: {
                accountNumber,
                accountHolder: {
                    contactType_Ext: accountType
                } = {}
            },
            policyNumber,
        },
        productCode,
        producerCodeOfService_Ext: {
            description: producerCodeOfServiceDescription,
            publicID: producerCodeOfServicePublicID
        },
        producerCodeOfRecord_Ext: {
            description: producerCodeOfRecordDescription,
            publicID: producerCodeOfRecordPublicID
        }
    } = policyChange;
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();

    const [showContinuePolicyChangeButton, updateShowContinuePolicyChangeButton] = useState(false);
    const [isLoading, updateIsLoading] = useState(true);
    const [referredToUnderWriter, updateReferredToUnderWriter] = useState(false);
    const [showReferToUW, updateShowReferToUW] = useState(false);
    const [retainInAgency, updateRetainInAgency] = useState([]);
    const { interactionModel } = useDependencies('interactionModel');
    const hasEditPolicyChangePermission = _.get(authUserData, 'permissionMap_Ext.editpolchange');
    const currentUserProducerCodes = _.get(authUserData, 'producerCodes_Ext');

    const selectedVersionPublicID = _.get(policyChange, 'selectedVersion_Ext');
    const selectedPolicyPeriod = _.get(policyChange, 'policy.periods', [])
        .find((policyPeriod) => policyPeriod.publicID === selectedVersionPublicID);

    const isCLProduct = WniProductsUtil.isCLProduct(productCode);

    const { getFeatureCodeValue } = useBusinessData();
    const enableProducerEngageWithAgentsOnline = getFeatureCodeValue('EnableProducerEngageWithAgentsOnline');
    const { isChangeCancelEnable } = useProductsData();

    const setShowReferToUW = (newShowReferToUW) => {
        updateShowReferToUW(newShowReferToUW);
    };

    const handleError = (title, message) => {
        return modalApi.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(() => {
            _.noop();
        });
    };

    const getDocuments = async () => {
        try {
            const documents = await WniCustomEndorsementService
                .getDocumentsForPolicyChangeGroupByType([jobNumber], authHeader);
            const policyChangeDocs = DocumentsUtil.getDocumentData(documents,
                DocumentsUtil.POLICY_CHANGE);
            updateRetainInAgency(policyChangeDocs);
        } catch (e) {
            handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    };

    const checkReferredToUnderWriter = async () => {
        const { jobNumber: changeJobNumber } = policyChange;
        const newReferStatus = await WniPolicyService.referredToUnderWriter(
            [changeJobNumber],
            authHeader
        );
        updateReferredToUnderWriter(newReferStatus);
    };

    const getShowContinuePolicyChange = async () => {
        const permissionDTO = {
            permission: 'createpolchange'
        };
        const newShowBtnStatus = await UserService.hasUserSystemPermission(permissionDTO, authHeader);
        updateShowContinuePolicyChangeButton(newShowBtnStatus);
    };

    const addToRecentlyView = async () => {
        const { jobNumber: changeJobNumber } = policyChange;
        await WniCustomEndorsementService.addRecentlyViewedPolicyChange(changeJobNumber, authHeader);
    };

    useEffect(() => {
        Promise.all([
            getShowContinuePolicyChange(),
            checkReferredToUnderWriter(),
            getDocuments(),
            addToRecentlyView()
        ]).then(() => {
            updateIsLoading(false)
        })
        
    }, []);

    const updateWithDrawPolicyChange = (jobStatusCode, policyChangeParam) => {
        const status = translator({ id: `typekey.PolicyPeriodStatus.${jobStatusCode}`, defaultMessage: jobStatusCode });

        const newPolicyChange = _.clone(policyChangeParam);
        _.set(newPolicyChange, 'status', status);
        _.set(newPolicyChange, 'statusCode', jobStatusCode);
        customUpdateJobSummary(newPolicyChange);
    };

    const onContinueTransaction = async ({ isReadOnly = false }) => {
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        const readOnly = (!hasEditPolicyChangePermission || isReadOnly);

        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], {
                isReadOnly: readOnly,
                policyNumber: policyNumber,
                jobNumber: policyChange.jobNumber,
            });
        } else {
            JobUtil.openJobInXCenter(policyChange.jobNumber);
        }
    };

    const onViewPolicyChange = () => {
        onContinueTransaction({ isReadOnly: true });
    };

    const onWithdrawTransaction = () => {
        modalApi.showConfirm({
            title: customMessages.deletePolicyChange,
            message: translator(customMessages.sureDeletePolicyChange, { policyChangeNumber: policyChange.jobNumber }),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            PolicyChangeService.withdrawPolicyChangeByPolicyChangeNumber(
                policyChange.jobNumber,
                authHeader
            ).then(
                () => {
                    updateWithDrawPolicyChange('Withdrawn', policyChange);
                },
                () => {
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedToWithdrawPolicyChange,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    const hasUwIssues = (uwIssues) => {
        if (!_.isEmpty(uwIssues)) {
            return uwIssues.length > 0;
        }
        return false;
    };

    const getUWIssuesApprovedNotificationContent = () => {
        // const job = policyChange;
        // const transactionVisibleActions = getPolicyChangeToProceed();
        const uwutil = UWUtil(policyChange, authUserData.userType);
        return {
            infoMessageTitle: translator(customMessages[`approvedForThis${uwutil.jobType}`]),
        };
    };

    const getNotificationContent = (warningMessagesAndSuccessNotificationVisibility) => {
        let infoMessages;
        let infoMessagesContent;
        let withDrawContent;
        let continueContent;
        let viewContent;
        const policyChangeInDraft = policyChange && policyChange.statusCode === 'Draft';
        if (isCLProduct && policyChangeInDraft) {
            infoMessages = {
                infoMessageTitle: translator(clMessages.clPolicyChangeInProgress),
                infoMessageContinueDescription: translator(clMessages.clEditPolicyChangeToContinue),
                infoMessageWithdrawDescription: translator(clMessages.clWithdrawPolicyChangeIfNeeded)
            }
            continueContent = translator(clMessages.clEditPolicyChangeBtn);
            withDrawContent = translator(clMessages.clWithdrawPolicyChange);
            viewContent = translator(customMessages.viewPolicyChange);
        } else {
            const {
                successNotification: {
                    showUWIssuesApprovedMsg
                }
            } = warningMessagesAndSuccessNotificationVisibility;
            const content = {};
            if (policyChange && !['Bound', 'Quoted', 'Withdrawn'].includes(policyChange.statusCode)
            && !hasUwIssues(policyChange.underwritingIssues)) {
                content.infoMessageTitle = translator(messages.policyChangeStarted);
            }
            if (policyChange && policyChange.statusCode === 'Quoted'
            && !hasUwIssues(policyChange.underwritingIssues)) {
                content.infoMessageTitle = translator(messages.policyChangePremiusCalculated);
            }
            if (policyChange && policyChange.statusCode === 'Bound') {
                content.infoMessageTitle = translator(messages.policyChangeBound);
            }
            if (policyChange && policyChange.statusCode === 'Withdrawn') {
                content.infoMessageTitle = translator(customMessages.policyDeleted);
            }
            if (policyChange && policyChange.statusCode === 'Draft') {
                content.infoMessageTitle = translator(messages.continuePolicyChange);
            }
    
            infoMessages = [{
                infoMessageTitle: content.infoMessageTitle,
            }];
    
            if (showUWIssuesApprovedMsg) {
                infoMessages = [getUWIssuesApprovedNotificationContent()].concat([{infoMessageDescription:translator(customMessages.mayDeleteOrContinuePolicyChangeWizard)}]);
            }
            
            if (policyChange.preempted_Ext && policyChange.statusCode !== 'Withdrawn') {
                infoMessagesContent = (
                    <div>
                        <div className="gwAlertContentSubHeading">
                            <span>{translator(customMessages.preemptedMessageTitlePart1)}</span>
                            {
                                policyChange.preemptionsOfThisJob_Ext.map((jobID) => (
                                    <Link
                                        key={jobID}
                                        to={`/change/${jobID}/summary`}
                                        className={styles.removeLinkStyle}
                                    >
                                        {`#${jobID}`}
                                    </Link>
                                ))
                            }
                            <span>{translator(customMessages.preemptedMessageTitlePart2)}</span>
                        </div>
                        <div className="gwAlertContentInnerHeading">
                            {translator(customMessages.preemptedMessageDescriptionPart1)}
                        </div>
                        <div className="gwAlertContentInnerHeading">
                            {translator(customMessages.preemptedMessageDescriptionPart2)}
                        </div>
                    </div>
                );
            }
            withDrawContent = isCLProduct ? translator(clMessages.clWithdrawPolicyChange) : translator(customMessages.deletePolicyChange);
            continueContent = isCLProduct ? translator(clMessages.clEditPolicyChangeBtn) : translator(messages.continuePolicyChange);
            viewContent = translator(customMessages.viewPolicyChange);
        }
        return {
            infoMessages,
            infoMessagesContent,
            withDrawContent,
            continueContent,
            viewContent,
        };

    };

    const getIsViewTransaction = () => {
        return _.get(authUserData, 'permissionMap_Ext.viewsubmission');
    };

    const getPolicyChangeToProceed = () => {
        if (!isChangeCancelEnable(productCode, policyState)) {
            return {
                isViewTransaction: false,
                isWithdrawTransaction: false,
                isContinueTransaction: false,
                isCopySubmission: false
            };
        }
        const hasEditSubmissionPermission = _.get(authUserData, 'permissionMap_Ext.editsubmission');
        const hasWithDrawPermission = _.get(authUserData, 'permissionMap_Ext.withdraw');
        // const hasReferToUnderWriterPermission = _.get(authUserData, 'permissionMap_Ext.ReferToUnderwriter_Ext');

        if (policyChange && (policyChange.statusCode === 'Withdrawn'
        || policyChange.statusCode === 'Bound')) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: getIsViewTransaction() && !_.get(authUserData, 'isExternalUser_Ext')
            };
        }
        let canWithdraw = false;
        if (!_.isEmpty(policyChange)) {
            if ((policyChange.status !== translator({ id: 'typekey.PolicyPeriodStatus.Bound', defaultMessage: 'Bound' })) && !policyChange.closeDate) {
                canWithdraw = true;
            }
        }
        return {
            isCopySubmission: false,
            isContinueTransaction:
                hasEditSubmissionPermission && canWithdraw
                && (!policyChange.currentVersionLocked_Ext || policyChange.preempted_Ext),
            isWithdrawTransaction: canWithdraw
                && (!policyChange.currentVersionLocked_Ext || policyChange.preempted_Ext)
                && hasWithDrawPermission,
            isViewTransaction: getIsViewTransaction() && !_.get(authUserData, 'isExternalUser_Ext'),
            // isReferToUnderWriterTransaction: hasReferToUnderWriterPermission || _.get(authUserData, 'isExternalUser_Ext')
            isReferToUnderWriterTransaction: false
        };
    };

    const getPolicyLinkVisible = () => {
        const policyNumberAssigned = _.get(policyChange, 'policyNumberAssigned_Ext', false);
        return policyNumberAssigned;
    };

    const redirectToPolicy = () => {
        const url = interactionModel.getURLObj(null, 'policySummary', accountNumber, policyNumber);
        return (
            <LinkComponent
                {...url}
                className={styles.removeLinkStyle}
            >
                {policyNumber}
            </LinkComponent>
        );
    };

    const updateJobSummary = (policyChangeParam) => {
        if (customUpdateJobSummary) {
            customUpdateJobSummary(policyChangeParam);
        }
    };

    const showQuotedBoundContainer = isCLProduct ? policyChange.statusCode !== 'Draft' : true
    const getTotalCostValue = () => {
        if (isCLProduct && policyChange.currentVersionLocked_Ext) {
            return '-'
        }
        return _.get(selectedPolicyPeriod, 'totalCost')
    }

    // ==============================================================
    const render = () => {
        if (_.isEmpty(policyChange)) {
            return (
                <Loader showLoader={isLoading} />
            );
        }
        const uwutil = UWUtil(policyChange, authUserData.userType);
        const underwritingIssues = _.get(policyChange, 'underwritingIssues');
        const newselectedVersionPublicID = _.get(policyChange, 'selectedVersion_Ext');
        const hasBlockingUWIssue = ErrorsAndWarningsUtil.quoteDetailsSummaryPageHasBlockingUWIssue(
            underwritingIssues, newselectedVersionPublicID
        );
        
        // const agencyDescription = _.get(policyChange, 'producerCodeOfRecordDescription_Ext');
        const documentEmpty = (retainInAgency && _.get(retainInAgency, 'length') > 0);

        const warningMessagesAndSuccessNotificationVisibility = SummaryUtil
            .getWarningMessagesAndSuccessNotificationVisibility(
                policyChange,
                authUserData,
                referredToUnderWriter,
                showReferToUW,
                UWUtil,
                getPolicyChangeToProceed()
            );

        const overrides = {
            '@field': {
                readOnly: true
            },
            totalCostId: {
                value: getTotalCostValue(),
            },
            backToPolicyCetailsLink: {
                content: enableProducerEngageWithAgentsOnline ? customMessages.backTOPolicyDetails : customMessages.backTOPolicySummary,
                ...interactionModel.getURLObj(null, 'policySummary', accountNumber, policyNumber)
            },
            quoteNotification: {
                notificationContent: getNotificationContent(warningMessagesAndSuccessNotificationVisibility),
                transactionVisibleActions: getPolicyChangeToProceed(),
                visible: policyChange.preempted_Ext || !warningMessagesAndSuccessNotificationVisibility.warningExist
            },
            underwritingTable: {
                job: policyChange,
                continueJob: getPolicyChangeToProceed(),
                jobService: WniCustomEndorsementService,
                onUpdateJobSummary: updateJobSummary,
                ransactionVisibleActions: getPolicyChangeToProceed(),
                referredToUnderWriter: referredToUnderWriter,
                checkReferredToUnderWriter: checkReferredToUnderWriter,
                hasBlockingUWIssue: hasBlockingUWIssue,
                showReferToUW: showReferToUW,
                setShowReferToUW: setShowReferToUW,
                warningMessagesAndSuccessNotificationVisibility:
                    warningMessagesAndSuccessNotificationVisibility,
                visible: (!policyChange.preempted_Ext && warningMessagesAndSuccessNotificationVisibility.warningExist)
            },
            summaryStatusdDataId: {
                value: policyChange.statusCode === 'Withdrawn' ? 'Deleted' : policyChange.statusCode
            },
            policyInfoLink: {
                visible: getPolicyLinkVisible(),
                value: redirectToPolicy(),
            },
            quotedBoundContainer: {
                visible: (policyChange.statusCode === 'Quoted' || policyChange.statusCode === 'Bound')
            },
            summaryDetailGridRow: {
                columns: showQuotedBoundContainer ? ["1fr", "1fr", "1fr", "1fr"]
                    : ["1fr", "1fr", "1fr"]
            },
            agencyOfRecordOrgId: {
                value: `${producerCodeOfRecordDescription}`,
                visible: currentUserProducerCodes.some(elt => elt.publicID === producerCodeOfRecordPublicID)
            },
            agencyOfServiceId: {
                value: `${producerCodeOfServiceDescription}`,
                visible: currentUserProducerCodes.some(elt => elt.publicID === producerCodeOfServicePublicID)
            },
            underwritingIssues: {
                uwIssues: policyChange.underwritingIssues,
                uwUtil: uwutil,
                noRecordsMessage: translator(messages[`noOpenUnderwriting${uwutil.jobType}`]),
                visible: !['Bound', 'Withdrawn'].includes(policyChange.statusCode) && hasUwIssues(policyChange.underwritingIssues) && !isCLProduct,
            },
            documentcheckedList: {
                visible: documentEmpty,
                history: history,
                submission: policyChange,
                renderData: [
                    {
                        data: retainInAgency
                    },
                    {
                        data: []
                    }
                ],
                showSendViaEmail: true,
                useJobNumber: true
            },
            backLinkContainer: {
                accountNumber: accountNumber,
                accountType: accountType,
                history: history,
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                notificationcomponent: SuccessNotification,
                underwritingcomponent: UnderwritingComponent,
                uwissuestablecomponent: UnderIssuesTableComponent,
                documentcheckedcomponent: DocumentCheckedComponent,
                backlinkcomponent: BackLinkComponent,
            },
            resolveCallbackMap: {
                onContinueTransaction: onContinueTransaction,
                onWithdrawTransaction: onWithdrawTransaction,
                onViewTransaction: onViewPolicyChange,
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, policyChange, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChange}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    };

    return render();
}


EndorsementSummary.propTypes = {
    fromAccountLanding: PropTypes.shape({
        quoteDetailsData: PropTypes.shape({
            jobNumber: PropTypes.string.isRequired,
            loadPolicyChangeSummary: PropTypes.shape({}).isRequired,
            updateJobSummary: PropTypes.func.isRequired
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

// export default withAuthenticationContext(EndorsementSummary);
export default EndorsementSummary;
