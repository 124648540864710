import React, {
    useCallback,
    useState,
    useMemo,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { WCCoveragesService } from 'wni-capability-quoteandbind-wc';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WizardPage from '../../templates/WCWizardPage';
import WCCoverageUtil from './util/WCCoverageUtil';

import WCLineDetails from './CoveragesSection/WCLineDetails/WCLineDetails';
import WCLineCoverages from './CoveragesSection/WCLineCoverages/WCLineCoverages';
import WCLineAdditionalCoverages from './CoveragesSection/WCLineAdditionalCoverages/WCLineAdditionalCoverages'
import WCLineExclusions from './CoveragesSection/WCLineExclusions/WCLineExclusions'
import WCLineConditions from './CoveragesSection/WCLineConditions/WCLineConditions'

import styles from './WCCoveragesPage.module.scss';
import metadata from './WCCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/WCCoveragesConfigContext';
import WCCoveragesConfig from './WCCoverages.config';

function WCCoveragesPageMain(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        //
        // ====================================
        // 
        coveragesPageProps : {
            onValidate,
            updateErrorsAndWarningsForCovComponent,
            //
            showErrors,
            //
            setIsEditing,
            isEditing,
            //
            setLoadingLineCoverages,
            loadingLineCoverages,
            //
            isEditable = true,
        },
    } = props;

    const lobName = 'workersComp';

    const {
        jobID,
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
            // baseState_Ext: baseState,
            // policyContactRoles_Ext: policyContactRoles
        },
        lobData: {
            [lobName]: {
                offerings,
                // coverables: {
                //     vehicles,
                // }
            }
        },
    } = submissionVM.value;

    // const translator = useTranslator();
    const { authHeader } = useAuthentication();

    // const {
    //     initialValidation,
    //     isComponentValid,
    //     onValidate,
    //     // registerComponentValidation,
    // } = useValidation('WCCoveragesPage');


    // const [showErrors, setShowErrors] = useState(false);
    // const [isEditing, setIsEditing] = useState(false)

    // const [validationIssues, updateValidationIssues] = useState([])
    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelCoverages',
        'policyLevelAdditionalCoverages',
        'policyLevelExclusionsAndConditions',
    ]);
    // const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const {
        coverages: {
            lineExclusions = [],
            lineConditions = []
        }
    } = selectedVersion;

    const hasExclusionsOrConditions = lineExclusions.concat(lineConditions).length > 0;

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM;
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        // Note: updateWizardData() could be an async func
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // // Middle layer introduced for the convenience of debugging
    // const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
    //     const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
    //     updateValidationIssues(newValidationIssues);
    // }, [updateValidationIssues]);

    const generateOverrides = useCallback(() => {

        const commonCoverageComponentProps = {
            selectedVersion,
            selectedVersionIndex,
            submissionVM,
            updateWizardData: updateWizardDataToDraft,
            onValidate,
            showErrors,
            updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
            isEditing,
            setIsEditing,
            lobName,
            coveragesService: WCCoveragesService,
            loadingLineCoverages,
            setLoadingLineCoverages,
            //
            isEditable,
        }

        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            wcLineCoverages: {
                ...commonCoverageComponentProps
            },
            wcLineDetails: {
                ...commonCoverageComponentProps
            },
            wcLineAdditionalCoverages: {
                ...commonCoverageComponentProps
            },
            policyLevelExclusionsAndConditions: {
                visible: hasExclusionsOrConditions
            },
            wcLineExclusions: {
                ...commonCoverageComponentProps,
                visible: lineExclusions.length > 0,
            },
            wcLineConditions: {
                ...commonCoverageComponentProps,
                visible: lineConditions.length > 0,
            },
        };
    }, [isEditing,
        lobName,
        onValidate,
        selectedVersion,
        selectedVersionIndex,
        showErrors,
        submissionVM,
        updateErrorsAndWarningsForCovComponent,
        updateWizardDataToDraft,
        openedAccordions,
        loadingLineCoverages,
        setLoadingLineCoverages,
        hasExclusionsOrConditions,
        lineExclusions,
        lineConditions,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WCLineDetails: WCLineDetails,
            WCLineCoverages: WCLineCoverages,
            WCLineAdditionalCoverages: WCLineAdditionalCoverages,
            WCLineExclusions: WCLineExclusions,
            WCLineConditions: WCLineConditions,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <CoveragesConfigContext.Provider value={WCCoveragesConfig}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </CoveragesConfigContext.Provider>
    );
}

WCCoveragesPageMain.propTypes = WizardPage.propTypes;
export default WCCoveragesPageMain;
