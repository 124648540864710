import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniSxsChange'), method, data, additionalHeaders);
}

export default class WniSxsChangeService { /**
     * Invokes WniSideBySidePolicyChangeHandler.retrieveSideBySideData()
     * @param {String} jobNumber policyChange job number
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static retrieveSxsData(jobNumber, authHeader = {}) {
        return processSubmission('retrieveSideBySideData', [jobNumber], authHeader);
    }

    // /**
    //  * Invokes WniSideBySidePolicyChangeHandler.createNewSideBySideVersion()
    //  * 
    //  * @param {String} jobNumber policyChange job number
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static createNewSideBySideVersion(jobNumber, authHeader = {}) {
    //     return processSubmission('createNewSideBySideVersion', [jobNumber], authHeader);
    // }

    /**
     * Note: Consider moving this method to some place like WniPolicyChangeService
     * Invokes WniSideBySidePolicyChangeHandler.quote()
     * @param {String} jobNumber policyChange job number
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static quote(jobNumber, authHeader = {}) {
        return processSubmission('quote', [jobNumber], authHeader);
    }

    /**
     * Invokes WniSideBySidePolicyChangeHandler.initiateSideBySide()
     * @param {String} jobNumber policyChange job number
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static initiateSideBySide(jobNumber, authHeader = {}) {
        return processSubmission('initiateSideBySide', [jobNumber], authHeader);
    }

    /**
     * Invokes WniSideBySidePolicyChangeHandler.canSideBySide()
     * @param {String} jobNumber policyChange job number
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static canSideBySide(jobNumber, authHeader = {}) {
        return processSubmission('canSideBySide', [jobNumber], authHeader);
    }

    /**
     * Invokes WniSideBySidePolicyChangeHandler.addSideBySideVersion()
     * @param {String} jobNumber policyChange job number
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static addSxsVersion(jobNumber, authHeader = {}) {
        return processSubmission('addSideBySideVersion', [jobNumber], authHeader);
    }

    /**
     * Invokes WniSideBySidePolicyChangeHandler.removeSelectedVersion()
     * @param {String} jobNumber policyChange job number
     * @param {String} deletePeriodPublicID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static removeSxsVersion(jobNumber, deletePeriodPublicID, authHeader = {}) {
        return processSubmission('removeSelectedVersion', [jobNumber, deletePeriodPublicID], authHeader);
    }

    /**
     * Invokes WniSideBySidePolicyChangeHandler.selectAndQuoteSideBySideVersion()
     * @param {String} jobNumber policyChange job number
     * @param {String} selectedPeriodPublicID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static selectAndQuoteSxsVersion(jobNumber, selectedPeriodPublicID, authHeader = {}) {
        return processSubmission('selectAndQuoteSideBySideVersion', [jobNumber, selectedPeriodPublicID], authHeader);
    }

    /**
     * Invokes WniSideBySidePolicyChangeHandler.updateSideBySideCoverages()
     * @param {String} jobNumber policyChange job number
     * @param {String} periopdPublicID
     * @param {object} lobCoveragesDTO
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static updateSxsCoverages(jobNumber, periopdPublicID, lobCoveragesDTO, authHeader = {}) {
        return processSubmission('updateSideBySideCoverages', [jobNumber, periopdPublicID, lobCoveragesDTO], authHeader);
    }

}
