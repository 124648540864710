import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredNotification: {
        id: 'customer.pa.wizard.step.policydetails.requiredNotification',
        defaultMessage: '{fasterQuote} Only fields with red asterisks {asteriskIcon} are required.'
    },
    fasterQuote: {
        id: 'customer.pa.wizard.step.policydetails.fasterQuote',
        defaultMessage: 'Get a faster quote!'
    },
});
