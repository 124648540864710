import React, {
    useContext,
    useState,
    useMemo,
    useCallback
} from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { DisplayColumn } from '@jutro/legacy/datatable'
import { Flex } from '@jutro/layout'
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import { WniDataTable, WniCheckboxField } from 'wni-common-base-components';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AddContactPopup, CoverageContactSearchPopup } from 'wni-capability-common-react';
import SingleClauseContext from '../../context/WCSingleClauseContext'
import CoverageContext from '../../context/WCCoverageContext';
import WCScheduleItemReadonlyCell from './WCScheduleItemReadonlyCell'
import WCScheduleItemEditableCell from './WCScheduleItemEditableCell'
import messages from './WCScheduleTable.messages'
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import WCComplexSchedulePopup from '../WCComplexSchedulePopup/WCComplexSchedulePopup';
import { Button } from '@jutro/legacy/components';

const NewContactType = {
    Company: 'company',
    Person: 'person',
}

const WCScheduleTable = (props) => {
    const {
        schedule
    } = props
    const {
        propertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
        isPolicyContactSchedule_Ext : isPolicyContactSchedule,
        isSimpleSchedule_Ext: isSimpleSchedule,
        isComplexSchedule_Ext: isComplexSchedule,
    } = schedule

    const {
        clauseCode: code,
        isEditable,
        clausePath: clauseVMPath,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)

    const {
        wizardData: submissionVM,
        updateWizardData,
    } = useContext(CoverageContext)

    const viewModelService = useContext(ViewModelServiceContext);

    const modalApi = useWniModal();

    const {
        lobData: {
            workersComp: {
                policyContacts_Ext : policyContactRoles
            }
        },
        jobID,
        sessionUUID,
    } = submissionVM.value;

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([]);
    const [isComplexSchedulePopupOpen, setIsComplexSchedulePopupOpen] = useState(false);
    const policyContacts = _.uniqBy(policyContactRoles, 'publicID');
    const policyContact = propertyInfos.find(propertyInfo => propertyInfo.id === 'PolicyContact');
    const policyContactPublicIDs = policyContactRoles.map(item => _.get(item, 'publicID'));
    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const existingContactcandidates = useMemo(() => {
        if (isPolicyContactSchedule) {
            return policyContacts
        }
        return []
    }, [isPolicyContactSchedule, policyContacts])

    const addContactScheduledItem = useCallback((item) => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            'contactDisplayName_Ext': item.displayName,
            'contactPublicID_Ext': item.publicID,
            itemData: {
                "ScheduleNumber": {
                  "integerValue": scheduleItems.length
                },
            }
        }])
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
    }, [clauseVMPath, deserializationClass, onChangeSubmissionAndSync, scheduleItems])

    const addNewContact = useCallback((newContactType) => {
        const primaryAddress = _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress')
        const contactVM = viewModelService.create(
            {
                contactCode: newContactType,
                primaryAddress,
                contactRoleType: 'PolicyContactRoles'
            },
            'pc',
            'wni.edge.capabilities.policycommon.accountcontact.dto.WniAccountContactDTO'
        )
        const componentProps = {
            contactVM,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            size: 'lg',
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        return modalApi.showModal(
            <AddContactPopup {...componentProps} />
        ).then((res) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            const newlyAddedContact = res.policyContacts.find(item => !policyContactPublicIDs.includes(item.publicID));
            addContactScheduledItem(newlyAddedContact);
            _.set(newSubmissionVM, 'lobData.commercialAuto.policyContacts_Ext', res.policyContacts);
            updateWizardData(newSubmissionVM);
        }).catch(() => {
            _.noop();
        });;
    }, [addContactScheduledItem, authHeader, jobID, modalApi, policyContactPublicIDs, sessionUUID, submissionVM, updateWizardData, viewModelService])

    const showContactSearchModal = useCallback(() => {
        const componentProps = {
            size: 'lg',
            existingContactcandidates
        };
        return modalApi.showModal(
            <CoverageContactSearchPopup {...componentProps} />
        );
    }, [existingContactcandidates, modalApi]);

    const onClickAddContact = useCallback(() => {
        showContactSearchModal().then(
            (selectedContact) => {
                addContactScheduledItem(selectedContact);
            }
        ).catch((reason) => {
            if (reason === NewContactType.Person) {
                addNewContact(NewContactType.Person)
            } else if (reason === NewContactType.Company) {
                addNewContact(NewContactType.Company)
            } else {
                _.noop();
            }
        });
    },[addContactScheduledItem, addNewContact, showContactSearchModal])

    const scheduleNumberColumn = <DisplayColumn
        key='ScheduleNumber'
        header= 'Schedule Number'
        sortable = {false}
        textAlign = 'left'
        path = 'itemNumber'
        renderCell = {(scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            if (!isEditable || scheduleReadonly) {
                return itemNumber
            }
            return <WniCheckboxField
                value = {selectedScheduleItemNumbers.includes(itemNumber)}
                label = {itemNumber}
                showInlineLabel
                onValueChange = {(checked) => {
                    let newSelectedScheduleItemNumbers
                    if (checked) {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.concat([itemNumber])
                    } else {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.filter(num => num !== itemNumber)
                    }
                    setSelectedScheduleItemNumbers(newSelectedScheduleItemNumbers)
                }}
            />
        }}
    />

    const contactColumn = <DisplayColumn
        key='ScheduleNumber'
        header = {policyContact? policyContact.label : ''}
        sortable = {false}
        textAlign = 'left'
        path = 'contactDisplayName_Ext'
    /> 

    const propertyInfoColumns = propertyInfos
        .filter((propertyInfo) => propertyInfo.id !== 'PolicyContact')
        .map((propertyInfo) => {
            const {
                id,
                label,
            } = propertyInfo
            
            return <DisplayColumn
                key = {id}
                header = {label}
                renderCell = {(scheduleItem) => {
                    const {
                        itemData: {
                            [id]: {
                                editable_Ext: isScheduleItemFieldEditable,
                            } = {}
                        },
                        itemNumber,
                    } = scheduleItem;
                    const isCellEditable = isEditable && !scheduleReadonly && isScheduleItemFieldEditable
                    if (isCellEditable && !isComplexSchedule) {
                        const scheduleItemIndex = scheduleItems.findIndex((item) => {
                            return item.itemNumber === itemNumber
                        })
                        const scheduleItemVMPath = `${clauseVMPath}.schedule.scheduleItems.children[${scheduleItemIndex}]`
                        return <WCScheduleItemEditableCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                            scheduleItemIndex={scheduleItemIndex}
                            scheduleItemVMPath={scheduleItemVMPath}
                        />
                    }
                    return <WCScheduleItemReadonlyCell
                        propertyInfo={propertyInfo}
                        scheduleItem={scheduleItem}
                    />
                }}
                sortable = {false}
            />
    })
    
    
    const sortedScheduleItems = scheduleItems.toSorted((scheduleItemA, scheduleItemB) => {
        const getScheduleNumber = (scheduleItem) => {
            return _.get(scheduleItem, 'itemNumber')
        }
        return getScheduleNumber(scheduleItemA) - getScheduleNumber(scheduleItemB)
    })

    const delSchedule = () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            return !selectedScheduleItemNumbers.includes(itemNumber)
        })
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
        setSelectedScheduleItemNumbers([])
    }

    const addSchedule = () => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            itemData: {
            }
        }])
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
    }

    const onAddScheduleItemClick = () => {
        addSchedule()
    }


    return <>
        {
            isEditable && !scheduleReadonly ? <Flex
                gap = "small"
                justifyContent = "right"
                className = "mb-10"
            >
                <Button
                    className = "wni-button-danger"
                    type = "filled"
                    disabled = {selectedScheduleItemNumbers.length === 0}
                    onClick = {delSchedule}
                >
                    {translator(messages.scheduleDel)}
                </Button>
                {isSimpleSchedule && 
                <Button
                    icon = "gw-add"
                    onClick = {() => onAddScheduleItemClick()}
                >
                    {translator(messages.scheduleAdd)}
                </Button>
                }
                {isPolicyContactSchedule &&
                <Button
                    icon = "gw-add"
                    onClick = {() => onClickAddContact()}
                >
                    {translator(messages.scheduleAdd)}
                </Button>
                }
                {isComplexSchedule && 
                <Button
                    onClick = {() => {setIsComplexSchedulePopupOpen(true)}}
                >
                    {translator(messages.scheduleEdit)}
                </Button>
                }
            </Flex> : (isComplexSchedule && <Flex
                gap = "small"
                justifyContent = "right"
                className = "mb-10"
            >
                <Button
                    onClick = {() => {setIsComplexSchedulePopupOpen(true)}}
                >
                    {translator(messages.scheduleView)}
                </Button>
            </Flex>)
        }
        <div className='table-wrapper mb-10'>
            <WniDataTable
                id = {`clause_schedule_table_${code}`}
                data={sortedScheduleItems}
                showSearch={false}
            >
                {isPolicyContactSchedule ? [scheduleNumberColumn, contactColumn, ...propertyInfoColumns] : [scheduleNumberColumn, ...propertyInfoColumns]}
            </WniDataTable>
        </div>
        {isComplexSchedulePopupOpen && <WCComplexSchedulePopup
            schedule={schedule}
            handleSave={() => {setIsComplexSchedulePopupOpen(false)}}
        />}
    </>
}

WCScheduleTable.propTypes = {
    schedule: PropTypes.shape({
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    })
}

export default WCScheduleTable