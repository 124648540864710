import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Loader } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    ErrorsAndWarningsUtil,
    QuoteUtil,
    VehicleUtil,
    VehicleValidationUtil,
    OOSUtil,
} from 'wni-portals-util-js';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import {
    DriverAssignmentComponent,
    DriverAssignmentGuidelineComponent
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import metadata from './PADriverAssignmentPage.metadata.json5';
import messages from './PADriverAssignmentPage.messages';
import styles from './PADriverAssignmentPage.module.scss';

function PADriverAssignmentPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        policyChangeGetAvailableDrivers,
        policyChangeAutoAssignAndGetVehicleDriverAssignments,
        policyChangeUpdateVehicleDriverAssignments,
        policyChangeGetErrorsAndWarnings,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        isSkipping,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [showLoader, updateShowLoader] = useState(false);
    const [assignmentVMs, updateAssignmentVMs] = useState([]);
    // array of assignmentVMs
    const [currentRow, updateCurrentRow] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [hasError, updateHasError] = useState(false);
    const [isAssignmentValid, updateIsAssignmentValid] = useState(true);
    const [validationIssues, updateValidationIssues] = useState([]);

    const vehicleNameMap = VehicleUtil.getVehicleNameMap(submissionVM);
    const driverNameMap = VehicleUtil.getDriverNameMap(submissionVM);
    const driverAgeMap = VehicleUtil.getDriverAgeMap(submissionVM);
    const showFurnishedGuideline = VehicleUtil.showFurnishedAssignmentGuideline(submissionVM);
    const showStudentAway = VehicleUtil.showStudentAway(submissionVM);

    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation,
    } = useValidation('DriverAssignmentPage');

    const {
        quoteID,
        jobID,
        sessionUUID,
        policyNumber,
        baseData: {
            effectiveDate,
            jobType,
            periodStatus,
            quoteFlow_Ext: quoteFlow,
            submittingQuote_Ext: submittingQuote,
        },
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles
                },
                vehicleMessagesWithoutAction_Ext: messagesWithoutAction = {},
                isForNamedNonOwner_Ext: isQuoteForNamedNonOwner,
                isExcessVehicleMoreThanDriver_Ext: isExcessVehicleMoreThanDriver
            }
        },
        oossliceDates_Ext: oossliceDates,
    } = submissionVM.value;
    const showRatedDriver = periodStatus !== 'Draft';
    const isRequiredForIssuance = quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote;

    const invalidAssignment = useCallback((vms) => {
        // Find vehicles with primary driver or excess vehicles
        const excessVehicleOrVehicleWithPrimaryDriver = vehicles.map((vehicle) => {
            return vms.some(
                (assignmentVM) => assignmentVM.value.vehicleID === vehicle.fixedId && (assignmentVM.value.assignment_Ext === 'Primary' || assignmentVM.value.excessVehicle_Ext)
            );
        });
        // If there is a vehicle which is not an excess vehcile and has no primary driver
        return excessVehicleOrVehicleWithPrimaryDriver.some((elt) => !elt);
    });

    const fetchData = useCallback(async (updatingAssignment) => {
        if (updatingAssignment) {
            updateShowLoader(true);
        } else {
            onValidate(undefined, 'PaDriverAssignmentPage');
        }
        let response = { assignments: [] };
        switch (jobType) {
            case 'Submission': {
                response = await WniQuoteVehicleService.autoAssignAndGetVehicleDriverAssignments(quoteID, sessionUUID,
                    updatingAssignment, authHeader);
                break;
            }
            case 'PolicyChange': {
                response = await policyChangeAutoAssignAndGetVehicleDriverAssignments(
                    policyNumber, effectiveDate, jobID, updatingAssignment, authHeader
                );
                break;
            }
            default: {
                // eslint-disable-next-line no-console
                console.log(`Unhandled job type: ${jobType}`);
            }
        }
        const vms = response.assignments.map((json) => viewModelService.create(json, 'pc',
            'edge.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleDriverDTO'));

        const pcDisplayIssues = VehicleValidationUtil.getPCDisplayIssues(response);
        updateValidationIssues(pcDisplayIssues);
        updateAssignmentVMs(vms);
        if (invalidAssignment(vms)) {
            updateIsAssignmentValid(false);
        }
        if (updatingAssignment) {
            updateShowLoader(false);
        } else {
            onValidate(true, 'PaDriverAssignmentPage');
        }
        return vms;
    }, []);

    const saveAssignmentData = useCallback(
        async (requestData, vehicleID) => {
            switch (jobType) {
                case 'Submission': {
                    submissionVM.value = await WniQuoteVehicleService.updateVehicleDriverAssignments(
                        quoteID, sessionUUID, requestData, vehicleID, authHeader
                    );
                    break;
                }
                case 'PolicyChange': {
                    await policyChangeUpdateVehicleDriverAssignments(
                        policyNumber, effectiveDate, jobID, requestData, vehicleID, authHeader
                    );
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            const updatedAssignement = await fetchData(true);
            const assignmentVM = updatedAssignement.filter((vm) => VehicleUtil.isIdEqual(_.get(vm, 'value.vehicleID'), vehicleID));
            updateCurrentRow(assignmentVM);
            updateHasError(false);
            updateWizardData(submissionVM);
        },
        [],
    );

    useEffect(() => {
        if (!isSkipping) {
            fetchData(false);
        }
    }, [isSkipping]);

    useEffect(() => {
        registerInitialComponentValidation(() => isAssignmentValid);
    }, [isAssignmentValid, registerInitialComponentValidation]);

    const getIssuesWithoutAction = useCallback(() => {
        const warnings = messagesWithoutAction.warnings ? messagesWithoutAction
            .warnings.map((m) => {
                return {
                    reason: m,
                    type: 'warning'
                };
            }) : [];
        const errors = messagesWithoutAction.errors ? messagesWithoutAction
            .errors.map((m) => {
                return {
                    reason: m,
                    type: 'error'
                };
            }) : [];
        return warnings.concat(errors);
    }, [messagesWithoutAction.errors, messagesWithoutAction.warnings]);

    const issuesWithoutAction = getIssuesWithoutAction();

    const retrieveBackendValidationIssues = useCallback(async () => {
        switch (jobType) {
            case 'Submission': {
                return WniQuoteVehicleService.getErrorsAndWarnings(quoteID, false, authHeader);
            }
            case 'PolicyChange': {
                // eslint-disable-next-line max-len
                return policyChangeGetErrorsAndWarnings(jobID, false, authHeader);
            }
            default: {
                // eslint-disable-next-line no-console
                console.log(`Unhandled job type: ${jobType}`);
            }
        }
        return [];
    }, []);

    const onNext = useCallback(
        async () => {
            const errorsAndWarningsResponse = await retrieveBackendValidationIssues();
            const backendIssues = ErrorsAndWarningsUtil
                .getValidationIssues(errorsAndWarningsResponse) || [];
            const allValidationIssues = _.uniqBy(backendIssues, 'reason');
            const issuesInThisPage = VehicleValidationUtil
                .getIssuesInAssignmentPage(allValidationIssues);
            const updatedIssues = _.differenceBy(issuesInThisPage, validationIssues, 'reason');
            updateValidationIssues(issuesInThisPage);
            if (!_.isEmpty(issuesInThisPage.filter((n) => n.type === 'error'))) {
                updateShowErrors(true);
                updateHasError(true);
                return false;
            }
            updateHasError(false);
            // only warnings
            if (!_.isEmpty(issuesInThisPage.filter((n) => n.type === 'warning'))) {
                if (!showErrors || !_.isEmpty(updatedIssues)) {
                    updateShowErrors(true);
                    return false;
                }
            }
            return submissionVM;
        },
        [showErrors, submissionVM, validationIssues]
    );

    const onEdit = useCallback((value) => {
        const vms = assignmentVMs.filter((vm) => VehicleUtil.isIdEqual(_.get(vm, 'value.vehicleID'), value.vehicleID));
        updateCurrentRow(vms);
    }, [assignmentVMs]);

    const getData = useCallback(() => {
        const vds = assignmentVMs ? assignmentVMs.map((vm) => vm.value) : [];
        return VehicleUtil.getAssignmentData(submissionVM, vds);
    }, [assignmentVMs]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues
                .concat(issuesWithoutAction)
                .concat(wizardErrorsAndWarnings)
                .concat(
                    OOSUtil.getOOSSliceDatesWarning(
                        OOSUtil.getOOSSliceDatesString(
                            oossliceDates,
                            effectiveDate
                        ),
                        translator
                    )
                ),
            visible: !_.isEmpty(validationIssues)
            || wizardErrorsAndWarnings.length > 0
            || !_.isEmpty(issuesWithoutAction)
            || OOSUtil.getOOSSliceDatesString(
                oossliceDates,
                effectiveDate
            ).length > 0,
            scrollToIssues: true,
        },
        driverAssDatatable: {
            data: getData()
        },
        ratedDriver: {
            visible: showRatedDriver,
        },
        studentAwayDrivers: {
            visible: showStudentAway,
        },
        editButton: {
            label: translator(messages.paDriverAssignmentEdit),
            onClick: onEdit,
            disabled: !_.isEmpty(currentRow),
            visible: !isQuoteForNamedNonOwner
        },
        paDriverAssignmentGuidelineComponent: {
            visible: !isQuoteForNamedNonOwner,
            assignmentVMs,
            driverAgeMap,
            showFurnishedGuideline
        },
        primaryOperator: {
            visible: isExcessVehicleMoreThanDriver
        },
        paDriverAssignmentComponent: {
            visible: !_.isEmpty(currentRow),
            model: currentRow,
            quoteID: quoteID ? quoteID.toString() : undefined,
            jobType,
            jobID,
            policyChangeGetAvailableDrivers,
            vehicleNameMap,
            driverNameMap,
            showStudentAway,
            showRatedDriver,
            isRequiredForIssuance,
            cancelCallback: () => updateCurrentRow([]),
            saveCallback: saveAssignmentData
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            driverassignmentcomponent: DriverAssignmentComponent,
            driverassignmentguidelinecomponent: DriverAssignmentGuidelineComponent,
            validationissuescomponent: ValidationIssuesComponent,
        },
    };

    const hideButtonsProps = {
        showNext: _.isEmpty(currentRow),
        showPrevious: _.isEmpty(currentRow),
        showCancel: _.isEmpty(currentRow)
    };

    return (
        <WizardPage
            onNext={onNext}
            alwaysCallOnNext
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            disableNext={!isComponentValid || hasError}
            {...hideButtonsProps}
        >
            {showLoader ? (<Loader showLoader />) : (
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            )}
        </WizardPage>
    );
}

PADriverAssignmentPage.propTypes = wizardProps;
export default PADriverAssignmentPage;
