import { defineMessages } from 'react-intl';

export default defineMessages({
    removeTitle: {
        id: 'quoteandbind.im.directives.templates.im-edit.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeDescription: {
        id: 'quoteandbind.im.directives.templates.im-edit.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this record?',
    },
    addCoveragePartSelectionTitle: {
        id: 'quoteandbind.im.directives.templates.im-edit.Add Coverage Part for Inland Marine',
        defaultMessage: 'Add Coverage Part for Inland Marine'
    },
    addAllSelectedCoverageParts: {
        id: 'quoteandbind.im.directives.templates.im-edit.Add All Selected Coverage Parts',
        defaultMessage: 'Add All Selected Coverage Parts'
    }
});