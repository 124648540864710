
import React from 'react';
import _ from 'lodash';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLLocationsPage } from 'wni-capability-quoteandbind-gl-react';

function GLLocationsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLLocationsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLLocationsChangePage.propTypes = GLLocationsPage.propTypes;
GLLocationsChangePage.defaultProps = GLLocationsPage.defaultProps;

export default GLLocationsChangePage;