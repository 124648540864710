
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';

import { GLModifiersReadOnlyPage } from 'wni-capability-quoteandbind-gl-react';

function GLModifiersChangeReadOnlyPage(props) {

    return (
        <GLModifiersReadOnlyPage {...props} />
    );

}

GLModifiersChangeReadOnlyPage.propTypes = GLModifiersReadOnlyPage.propTypes;
GLModifiersChangeReadOnlyPage.defaultProps = GLModifiersReadOnlyPage.defaultProps;

export default GLModifiersChangeReadOnlyPage;