import React, { useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import { Avatar } from '@jutro/components';
import { ServiceManager } from '@jutro/legacy/services';

import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { ContactService } from 'gw-capability-gateway';
import { Link as LinkComponent } from 'gw-components-platform-react';
import { useWniModal } from 'wni-components-platform-react';

import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import messages from 'gw-capability-gateway-react/Contacts/Contacts.messages';
import { Flex } from '@jutro/layout';
import metadata from './AccountContactsPage.metadata.json5';
import tabStyles from '../Contacts.module.scss';
import gatewayMessages from '../../gateway.messages';
import accountMessage from '../../Accounts/Summary/AccountSummary.messages';


import { Button } from '@jutro/legacy/components';


const PRIMARY_PHONE_TYPE_MAP = {
    home: 'homeNumber',
    mobile: 'cellNumber',
    work: 'workNumber'
};

const servicePageConfig = {
    page: 0,
    pageSize: 100
};

const pageConfig = {
    page: 0,
    pageSize: 5
};

function AccountContactsPage(props) {
    const {
        history,
        accountNumber,
        relatedTo,
        availableProducerCodesForCurrentUser,
        accountType
    } = props;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const modalApi = useWniModal();

    const localeService = ServiceManager.getService('locale-service');
    const phoneUtil = PhoneUtil();
    const [accountContactRoleVM, updateAccountContactRoleVM] = useState({});
    const [accountContactRole, updateAccountContactRole] = useState('allRoles');
    const [accountContactRoleOptions, updateAccountContactRoleOptions] = useState([]);
    const [accountContactTableData, updateAccountContactTableData] = useState([]);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);
    const {
        interactionModel
    } = useDependencies(['interactionModel']);

    const {
        authUserData: {
            isExternalUser_Ext: isExternalUser
        } = {}
    } = useAuthentication();

    const writeValue = (value) => {
        updateAccountContactRole(value);
    };

    const getContactRoles = useCallback(() => {
        const allRolesOption = {
            code: 'allRoles',
            name: translator(messages.allRoles)
        };

        const contactRoles = _.get(accountContactRoleVM, 'subtype.aspects.availableValues', []);

        const roleOptions = contactRoles
            .filter((roles) => {
                return roles.code !== 'AccountContactRole';
            })
            .map((contacts) => {
                return {
                    code: contacts.code,
                    name: translator({
                        id: `gateway.contacts.common.views.roles.${contacts.code}`,
                        defaultMessage: `${contacts.code}`
                    })
                };
            });

        roleOptions.unshift(allRolesOption);

        return roleOptions;
    }, [accountContactRoleVM, translator]);

    const generateTableData = (data) => {
        return data.map((item) => {
            const itemPhoneType = item.primaryPhoneType;
            const telNumber = item[PRIMARY_PHONE_TYPE_MAP[itemPhoneType]];
            const prettyTelNumber = telNumber && phoneUtil.prettyPrint(telNumber, localeService.getDefaultCountryCode())

            return {
                ...item,
                telNumber: prettyTelNumber
            }
        })
    };

    const getAccountContacts = async () => {
        const role = accountContactRole !== 'allRoles' ? [accountContactRole] : [];
        const response = await ContactService.getAccountContacts(
            accountNumber,
            authHeader,
            role,
            servicePageConfig.fromPage,
            servicePageConfig.pageSize
        )

        const accountContacts = response.accountContacts || [];
        const sortedAccountContactTableData = _.sortBy(accountContacts, (contact) => {
            return !_.includes(contact.accountContactRoles, 'AccountHolder')
        });
        const filtedAccountContactTableData = _.filter(sortedAccountContactTableData, (contact) => {
            if(!_.includes(contact.accountContactRoles, 'AccountHolder') && !_.includes(contact.accountContactRoles, 'NamedInsured') && _.includes(contact.accountContactRoles, 'Driver')) {
                return false
            } 
            return true
        })
        const generateData = generateTableData(filtedAccountContactTableData)
        updateAccountContactTableData(generateData);
    };

    useEffect(() => {
        const newAccountContactRoleVM = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.contact.dto.roles.account.AccountContactRoleDTO'
        );
        updateAccountContactRoleVM(newAccountContactRoleVM);

        const newAccountContactRoleOptions = getContactRoles();
        updateAccountContactRoleOptions(newAccountContactRoleOptions)

        getAccountContacts();
    }, []);

    const redirectToContactDetails = (item) => {
        history.push({
            pathname: `/${relatedTo}/${accountNumber}/contactDetails`,
            state: {
                contact: 'accountContacts',
                accountOrPolicyNumber: accountNumber,
                relatedTo: relatedTo,
                contactId: item.accountContactPublicID
            }
        });
    };
    const renderContact = (item, index, { path }) => {
        return (
            <Flex
                justifyContent='left'
                alignItems='center'
                gap="small"
                onClick={() => redirectToContactDetails(item)}
            >
                <Avatar
                    username={item[path]}
                />
                <span className="btn-link" >{item[path]}</span>
            </Flex>
        )
    };

    const renderRole = (item, index, { path: property }) => {
        const roles = item[property];
        const roleOptions = accountContactRoleVM.subtype.aspects.availableValues;
        const getAllRoles = roles.map((data) => {
            const matchingRole = roleOptions.find((opt) => opt.code === data);
            const lowerCode = _.lowerFirst(matchingRole.code)
            return matchingRole ? (
                translator(gatewayMessages[lowerCode])
            ) : null;
        }).join(', ');

        return (
            <div title={getAllRoles}>
                { getAllRoles }
            </div>
        );
    };

    const getPolicyAccess = (agencyOfServiceCode) => {
        let result = false;
        if (!isExternalUser) {
            result = true;
        } else {
            _.forEach(availableProducerCodesForCurrentUser, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                }
            });
        }
        return result;
    };

    const showWarningMsg = () => {
        modalApi.showAlert({
            title: 'Warning',
            status: 'warning',
            icon: 'gw-error-outline',
            message: accountMessage.warningMsgNoRights,
        }).catch(() => {
            _.noop();
        });
    };

    const renderPoliciesInvolved = (item, index, { path }) => {
        const isActive = item.active;
        return isActive ? <Flex gap='small' direction='column'>
            {item?.associatedPolicies?.map(policy => {
                let linkFieldProps = {}
                const access = getPolicyAccess(policy?.agencyOfServiceCode_Ext);
                if (!access) {
                    linkFieldProps = {
                        onClick: showWarningMsg
                    }
                } else {
                    linkFieldProps = {
                        to: `/policies/${policy?.policyId}/summary`
                    }
                }
                return <LinkComponent
                    id={`policy${policy?.policyId}`}
                    {...linkFieldProps}
                >
                    {policy?.policyId}
                </LinkComponent>
            })}</Flex>
            : translator(gatewayMessages.inactive);
    };

    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };

    const overrideProps = {
        accountContactsDropdown: {
            availableValues: accountContactRoleOptions,
            value: accountContactRole
        },
        accountContactsDataTable: {
            key: accountContactRole,
            data: accountContactTableData,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [5, 10, 15, 20]
        }
    };

    const resolvers = {
        resolveClassNameMap: tabStyles,
        resolveCallbackMap: {
            renderContact,
            renderRole,
            renderPoliciesInvolved,
            onRowClick: redirectToContactDetails
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            accountContactRole,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            onValueChange={writeValue}
            resolveValue={readValue}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

AccountContactsPage.propTypes = {};

export const AccountContactsComponent = AccountContactsPage;
export default AccountContactsPage;
