import React, { useCallback } from 'react';
import { InlineLoader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { SwipeNavigation } from '@xengage/gw-portals-navigation-js';
import classNames from 'classnames';

import styles from './QuickQuoteWizardPageTemplate.module.scss';
import QuickQuoteNavigation from './Wizard/QuickQuoteNavigation';

import { Button } from '@jutro/legacy/components';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function QuickQuoteWizardPageTemplate(props) {
    const translator = useTranslator();
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel
    } = props;

    const isPerformingTransiton = isLoadingPrevious || isLoadingNext;

    const isNextDisabled = disableNext || isPerformingTransiton;
    const isPreviousDisabled = disablePrevious || isPerformingTransiton;

    const onEndSwipe = useCallback(
        (event) => {
            const onNextCallback = isNextDisabled ? undefined : onNext;
            const onPreviousCallback = isPreviousDisabled ? undefined : onPrevious;
            SwipeNavigation.onEndSwipe(event, onPreviousCallback, onNextCallback);
        },
        [isNextDisabled, isPreviousDisabled, onNext, onPrevious]
    );

    const quickQuoteButtonStyles = classNames({
        [styles.wizardButtons]: showPrevious === true || showNext === true
    });

    return (
        <div
            className={styles.digitalSLQWizard}
            onTouchStartCapture={SwipeNavigation.onStartSwipe}
            onTouchEndCapture={onEndSwipe}
            onTouchCancel={SwipeNavigation.onCancelSwipe}
        >
            <div className={styles.wizardNavigation}>
                <QuickQuoteNavigation />
            </div>
            <div className={styles.wizardContent}>{content}</div>
            <div className={quickQuoteButtonStyles}>
                {showPrevious && (
                    <Button
                        id="gw-wizard-previous"
                        disabled={isPreviousDisabled}
                        type="outlined"
                        onClick={onPrevious}
                        className={styles.wizardPreviousButton}
                    >
                        {translator(previousLabel)}
                        {renderLoader(isLoadingPrevious)}
                    </Button>
                )}
                {showNext && (
                    <Button
                        id="gw-wizard-Next"
                        disabled={isNextDisabled}
                        onClick={onNext}
                        className={styles.wizardNextButton}
                    >
                        {translator(nextLabel)}
                        {renderLoader(isLoadingNext)}
                    </Button>
                )}
            </div>
        </div>
    );
}

QuickQuoteWizardPageTemplate.propTypes = {
    ...pageTemplateProps
};

export default QuickQuoteWizardPageTemplate;
