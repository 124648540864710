import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { WindowUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './RCTCallbackPopupComponent.metadata.json5';
import messages from '../../HOResidencePage.messages';

import { Button } from '@jutro/legacy/components';

function RCTCallbackPopupComponent(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        model: RCTEmptyFieldsName,
        launchRCTURL,
    } = props;
    const ids = 'RCTCallbackPopupComponent'
    const [fieldsName, updateFieldsName] = useState([]);

    useEffect(() => {
        const res = _.map(RCTEmptyFieldsName, (name) => {
            return { fieldName: name };
        });
        updateFieldsName({ emptyFields: res });
    }, [RCTEmptyFieldsName]);

    const handleActionBtn = () => {
        WindowUtil.partialPrint(ids);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
        },
        emptyFields: {
            // data: fieldsName
        },
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
            onLaunchRCTURL: launchRCTURL,
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} ${ids}`}>
            <ModalHeader title={messages.RCTCallbackSuccess} onClose={onReject} />
            <ModalBody id="agencyDatailsPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={fieldsName}
                    overrideProps={overrideProps}
                    // resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Grid justifyItems="between">
                    <div className="align-right hiddenPrintElement">
                        <Button onClick={handleActionBtn} type="primary">
                            {messages.printThisPage}
                        </Button>
                    </div>
                </Grid>
            </ModalFooter>
        </ModalNext>
    );
}

RCTCallbackPopupComponent.propTypes = {
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    model: PropTypes.shape({}),
    size: PropTypes.string
};
RCTCallbackPopupComponent.defaultProps = {
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    size: 'md'
};

export default RCTCallbackPopupComponent;
