import { defineMessages } from 'react-intl';

export default defineMessages({
    crViewAndEditLabel: {
        id: 'wni.quoteandbind.cr-wizard.cr-statespecific.View/Edit',
        defaultMessage: 'View/Edit',
    },
    crViewLabel: {
        id: 'wni.quoteandbind.cr-wizard.cr-statespecific.View',
        defaultMessage: 'View',
    },
    crClose: {
        id: 'wni.quoteandbind.cr-wizard.cr-statespecific.Close',
        defaultMessage: 'Close',
    },
});