import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import PUCoverageUtil from '../../util/PUCoverageUtil';
import PUClausesComponentVM from '../../components/PUClausesComponentVM/PUClausesComponentVM';
import PUSingleClauseComponentVM from '../../components/PUSingleClauseComponentVM/PUSingleClauseComponentVM';
import metadata from './PULineExclusions.metadata.json5';
import styles from './PULineExclusions.module.scss';
import PUScheduleItemsPopup from '../../components/PUScheduleItemsPopup/PUScheduleItemsPopup';

function PULineExclusions(props) {
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService,
        loadingClausesCodes,
        setLoadingLineCoverages,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const modalApi = useWniModal();
    const getLineExclusions = useCallback(() => {
        const lineExclusions = _.get(selectedVersion, 'coverages.lineExclusions', []);
        return lineExclusions;
    }, [selectedVersion]);

    const lineCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.lineExclusions`;
    const lineCoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.lineExclusions`;
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const lineExclusions = getLineExclusions();

    const showCoverageDetailsPopup = (coverageToOpen)=>{
        const lineCovVMs = _.get(submissionVM, `${lineCoveragesVMPath}.children`);
        const targetCoverageIndex = lineCovVMs.findIndex((vm)=>vm.value.code_Ext === coverageToOpen.code_Ext);
        const targetCoveragePath = `${lineCoveragesVMPath}.children[${targetCoverageIndex}]`;

        const getCoverageFromLobCoverages = (lobCovs) => {
            const lineCovs = _.get(lobCovs, 'lineExclusions', [])
            return lineCovs.find((cov) => _.get(cov, 'code_Ext') === coverageToOpen.code_Ext)
        }
        const componentProps = {
            isEditable: isEditable,
            coverageCode: coverageToOpen.code_Ext,
            coveragePath: targetCoveragePath,
            schedule: coverageToOpen.schedule,
            coverage: coverageToOpen,
            coveragesService,
            jobID,
            sessionUUID,
            lobName,
            submissionVM,
            updateSubmissionVM: updateWizardData,
            lobCoveragesPath,
            getCoverageFromLobCoverages,
            size: 'lg',
        };
        return modalApi.showModal(
            <PUScheduleItemsPopup {...componentProps} />
        );
    } 

    const onOpenCoverageDetailsPopup = (clausePatternCode)=>{
        const coverageToOpen = lineExclusions.find((cov)=>cov.code_Ext === clausePatternCode);
        showCoverageDetailsPopup(coverageToOpen)
    }

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = PUCoverageUtil.setClauseValue(lineExclusions, lineCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, lineCoveragePath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            lineExclusions,
            lineCoveragesVMPath,
            lineCoveragePath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = PUCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = PUCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingLineCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingLineCoverages,
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            lineExclusions: {
                loadingClause: loadingClausesCodes,
                path: lineCoveragesVMPath,
                value: lineExclusions,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    PUSingleClauseComponentVM: PUSingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
            }
        };
    }, [
        loadingClausesCodes,
        lineCoveragesVMPath,
        lineExclusions,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
            onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
        },
        resolveComponentMap: {
            PUClausesComponentVM: PUClausesComponentVM,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PULineExclusions.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
PULineExclusions.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default PULineExclusions;
