import React, {
    useCallback, useMemo, useState, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { TooltipIcon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import {
    VehicleValidationUtil,
    WniClausesUtil,
    CoveragesUtil,
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import metadata from './PAVehicleCoveragesSection.metadata.json5';
import VehicleCoverageModal from '../VehicleCoverageModal/VehicleCoverageModal';
import CopyVehicleCoverageModal from '../CopyVehicleCoverageModal/CopyVehicleCoverageModal';
import messages from './PAVehicleCoveragesSection.messages';

import { Button } from '@jutro/legacy/components';

const CoveragePattern = {
    OtherThanCollisionCoverage: 'PAComprehensiveCov',
    OtherThanCollisionNNOCoverage: 'PA_NO_COMP_EXT',
    CollisionCoverage: 'PACollisionCov',
    CollisionNNOCoverage: 'PA_NO_COLL_Ext',
    FullSafetyGlassCoverage: 'PAGlassCov_Ext',
    RoadSideAssistCoverage: 'PARoadsideAssistCov_Ext',
    RoadSideAssistAKCoverage: 'PARdsidAsstAKCov_EXT',
    RoadSideAssistPlusCoverage: 'PARdsidAsstPlusCov_Ext',
    RoadSideAssistPlusAKCoverage: 'PARdsidAsstPlusAKCov_EXT',
    TowingCoverage: 'PATowingLaborCov',
    RATowingCoverage: 'PARdSdAsstTwngLab_EXT',
    RAPlusTowingCoverage: 'PARdSdAsstPlTwngLab_EXT',
    ExcludeLiability: 'PALiabilityCovExc_Ext'
};

const CoverageTermPattern = {
    OtherThanCollisionCoverageDeductible: 'PACompDeductible',
    OtherThanCollisionNNOCoverageDeductible: 'zb7j0o9onvel98e0ie1vrtgifva',
    OtherThanCollisionRTCoverageDeductible: 'ztggimnmn70goebruhlmis4otr8',
    OtherThanCollisionUTCoverageDeductible: 'zc1imbec9p26sf4vs3hc96q0bna',
    CollisionCoverageDeductible: 'PACollDeductible',
    CollisionNNOCoverageDeductible: 'zhdhia17ep6b6esfin86c7f2i7b',
    CollisionRTCoverageDeductible: 'z9hh0rnn1j4p63aqk1idp78o4u9',
    CollisionUTCoverageDeductible: 'ztfjqhf3vronj4i7nf8v5k8e7la',
};

function PAVehicleCoveragesSection(props) {
    const {
        submissionVM,
        updateWizardSnapshot,
        updateVehicleValidationIssues,
        updateCoveragesErrorsAndWarnings,
        isReadOnly,
        updateCoveragesFunction,
        copyVehicleCoverages,
        // getDriversCoverages,
        disregardFieldValidation: disregardLineCoveragesFieldValidation,
        setInvalidVehicleCoverageFieldIssues,
        selectedVersionOfferingsIndex,
    } = props;

    const {
        quoteID,
        jobID,
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionId,
            jobType,
        },
        lobData: {
            personalAuto: {
                offerings,
                coverables: {
                    vehicles = []
                }
            }
        }
    } = submissionVM.value;
    const translator = useTranslator();
    const selectedVersion = useMemo(() => offerings
        .find((offering) => offering.publicID_Ext === selectedVersionId),
    [offerings, selectedVersionId]);
    const {
        coverages: {
            vehicleCoverages
        }
    } = selectedVersion;

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();

    const [vehicleCoverageModalStatus, updateVehicleCoverageModalStatus] = useState({
        isOpen: false,
        vehiclePublicID: null,
        title: null
    });
    const [copyVehicleCoverageModalStatus, updateCopyVehicleCoverageModalStatus] = useState({
        isOpen: false,
        triggerCopyCoverageVehiclePublicID: null
    });
    const [loadingVehicleCoverages, setLoadingVehicleCoverages] = useState(false);

    useEffect(() => {
        const vehicleCoveragesErrorMessage = [];
        vehicleCoverages.forEach((vehicleCoverage) => {
            const hasInvalidCoverage = CoveragesUtil.hasInvalidCoverage(vehicleCoverage.coverages);
            if (hasInvalidCoverage) {
                const vehicleOfSelectedCoverage = vehicles.find((vehicle) => _.get(vehicle, 'publicID') === _.get(vehicleCoverage, 'publicID'));
                const vehModelYear = _.get(vehicleOfSelectedCoverage, 'year');
                const vehManufact = _.get(vehicleOfSelectedCoverage, 'make');
                const vehModel = _.get(vehicleOfSelectedCoverage, 'model');
                vehicleCoveragesErrorMessage.push({
                    type: 'error',
                    reason: translator(messages.vehicleCoverageInvalidField, {
                        vehModelYear,
                        vehManufact,
                        vehModel
                    })
                });
            }
        });
        setInvalidVehicleCoverageFieldIssues(vehicleCoveragesErrorMessage);
    }, [
        // submissionVM in necessary in this dependency array list
        // otherwise useEffect will not exec after chchange vehicle coverage
        submissionVM,
        vehicleCoverages,
        setInvalidVehicleCoverageFieldIssues,
        translator,
        vehicles
    ]);


    const onResolveVehicleCoverageModal = () => {
        updateVehicleCoverageModalStatus({
            ...vehicleCoverageModalStatus,
            isOpen: false
        });
    };

    const onResolveCopyVehicleCoverageModal = (backToVehiclePublicID) => {
        updateCopyVehicleCoverageModalStatus({
            ...copyVehicleCoverageModalStatus,
            isOpen: false
        });
        if (backToVehiclePublicID) {
            updateVehicleCoverageModalStatus({
                ...vehicleCoverageModalStatus,
                isOpen: true,
                vehiclePublicID: backToVehiclePublicID,
            });
        }
    };

    const onRejectCopyVehicleCoverageModal = () => {
        updateCopyVehicleCoverageModalStatus({
            ...copyVehicleCoverageModalStatus,
            isOpen: false
        });
    };

    const getVehicleCoverage = useCallback((vehiclePublicID) => {
        return selectedVersion.coverages.vehicleCoverages
            .find((vehicleCoverage) => vehicleCoverage.publicID === vehiclePublicID);
    }, [selectedVersion.coverages.vehicleCoverages]);

    const getDeductibleValue = useCallback(
        (coverage, termPatternCode) => {
            if (_.get(coverage, 'selected')) {
                const coverageTerms = _.get(coverage, 'terms', []);
                const deductibleTerms = coverageTerms
                    .find((term) => term.patternCode === termPatternCode);
                const chosenTerm = _.get(deductibleTerms, 'chosenTerm');
                const chosenTermOption = _.get(deductibleTerms, 'options', [])
                    .find((option) => _.get(option, 'code') === chosenTerm);
                const chosenTermValue = _.get(chosenTermOption, 'name');
                return chosenTermValue;
            }
            return null;
        }, []
    );

    const getVehicleDescription = (value) => {
        if (value.vehicleType_Ext === 'noVehicleNamedNonOwner_Ext') {
            return 'Named Non-Owner';
        }
        return `${value.year} ${value.make} ${value.model} (${value.vin.slice(value.vin.length - 4)})`;
    };

    const openVehicleCoverageModal = (vehicle) => {
        updateVehicleCoverageModalStatus({
            isOpen: true,
            vehiclePublicID: vehicle.publicID,
            title: `Coverage Details: ${getVehicleDescription(vehicle)}`,
        });
    };

    const openCopyCoveragesModal = (vehiclePublicID = null) => {
        updateVehicleCoverageModalStatus({
            ...vehicleCoverageModalStatus,
            isOpen: false,
        });
        updateCopyVehicleCoverageModalStatus({
            ...copyVehicleCoverageModalStatus,
            isOpen: true,
            triggerCopyCoverageVehiclePublicID: vehiclePublicID,
        });
    };

    const onCopyCoverageButtonClick = () => {
        openCopyCoveragesModal();
    };

    const renderVehicleOtherThanCollision = (value) => {
        const currentVehicleCoverage = getVehicleCoverage(value.publicID);
        const otherThanCollisionCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.OtherThanCollisionCoverage);
        const otherThanCollisionNNOCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern
                .OtherThanCollisionNNOCoverage);
        const otherThanCollisionDeductibleValue = getDeductibleValue(
            otherThanCollisionCoverage,
            CoverageTermPattern.OtherThanCollisionCoverageDeductible
        );
        const otherThanCollisionRTCoverageDeductible = getDeductibleValue(
            otherThanCollisionCoverage,
            CoverageTermPattern.OtherThanCollisionRTCoverageDeductible
        );
        const otherThanCollisionUTCoverageDeductible = getDeductibleValue(
            otherThanCollisionCoverage,
            CoverageTermPattern.OtherThanCollisionUTCoverageDeductible
        );
        const otherThanCollisionNNODeductibleValue = getDeductibleValue(
            otherThanCollisionNNOCoverage,
            CoverageTermPattern.OtherThanCollisionNNOCoverageDeductible
        );
        if (!_.get(otherThanCollisionCoverage, 'selected') && !_.get(otherThanCollisionNNOCoverage, 'selected')) {
            return 'No';
        }
        const deductibleValue = otherThanCollisionDeductibleValue
            || otherThanCollisionRTCoverageDeductible
            || otherThanCollisionUTCoverageDeductible
            || otherThanCollisionNNODeductibleValue;
        if (_.isNil(deductibleValue)) {
            return 'Yes';
        }
        return deductibleValue;
    };

    const renderVehicleCollision = (value) => {
        const currentVehicleCoverage = getVehicleCoverage(value.publicID);
        const collisionCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.CollisionCoverage);
        const collisionNNOCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.CollisionNNOCoverage);
        const collisionCoverageDeductibleValue = getDeductibleValue(
            collisionCoverage,
            CoverageTermPattern.CollisionCoverageDeductible
        );
        const collisionRTCoverageDeductible = getDeductibleValue(
            collisionCoverage,
            CoverageTermPattern.CollisionRTCoverageDeductible
        );
        const collisionUTCoverageDeductible = getDeductibleValue(
            collisionCoverage,
            CoverageTermPattern.CollisionUTCoverageDeductible
        );
        const collisionNNOCoverageDeductibleValue = getDeductibleValue(
            collisionNNOCoverage,
            CoverageTermPattern.CollisionNNOCoverageDeductible
        );

        if (!_.get(collisionCoverage, 'selected') && !_.get(collisionNNOCoverage, 'selected')) {
            return 'No';
        }
        const deductibleValue = collisionCoverageDeductibleValue
            || collisionRTCoverageDeductible
            || collisionUTCoverageDeductible
            || collisionNNOCoverageDeductibleValue;
        if (_.isNil(deductibleValue)) {
            return 'Yes';
        }
        return deductibleValue;
    };

    const renderVehicleFullSafetyGlass = (value) => {
        const currentVehicleCoverage = getVehicleCoverage(value.publicID);
        const fullSafetyGlassCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.FullSafetyGlassCoverage);
        return _.get(fullSafetyGlassCoverage, 'selected') ? 'Yes' : 'No';
    };

    const renderVehicleRoadSideOrTowing = (value) => {
        const currentVehicleCoverage = getVehicleCoverage(value.publicID);
        const roadSideCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.RoadSideAssistCoverage);
        const roadSideAKCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.RoadSideAssistAKCoverage);
        const roadSidePlusCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.RoadSideAssistPlusCoverage);
        const roadSidePlusAKCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.RoadSideAssistPlusAKCoverage);
        const towingCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.TowingCoverage);
        const RATowingCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.RATowingCoverage);
        const RAPlusTowingCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.RAPlusTowingCoverage);

        if (_.get(roadSideCoverage, 'selected')
            || _.get(roadSideAKCoverage, 'selected')
            || _.get(roadSidePlusCoverage, 'selected')
            || _.get(roadSidePlusAKCoverage, 'selected')) {
            return 'Roadside';
        }
        if (_.get(towingCoverage, 'selected')
            || _.get(RATowingCoverage, 'selected')
            || _.get(RAPlusTowingCoverage, 'selected')) {
            return 'Towing';
        }
        return 'No';
    };

    const renderVehicleOther = (value) => {
        const currentVehicleCoverage = getVehicleCoverage(value.publicID);
        const excludeLiabilityCoverage = currentVehicleCoverage.coverages
            .find((coverage) => coverage.code_Ext === CoveragePattern.ExcludeLiability);
        if (_.get(excludeLiabilityCoverage, 'selected')) {
            return 'Excluded Liability';
        }
        if (currentVehicleCoverage.coverages
            .some((coverage) => _.get(coverage, 'selected') && !Object.values(CoveragePattern).includes(coverage.code_Ext))
        ) {
            return 'Yes';
        }
        return 'No';
    };

    const renderVehicleAction = (value) => {
        return <Button className="wni-button-link" onClick={() => { openVehicleCoverageModal(value); }}>{isReadOnly ? 'View' : 'Edit'}</Button>;
    };

    const updateSubmissionVMOnClauseUpdate = useCallback(
        async (postUpdate, newSubmissionVM, disregardVehicleCoveragesFieldValidation) => {
            const lobName = 'personalAuto';

            const clausesToUpdate = CoveragesUtil.generateUpdatedCoveragesDTO({
                submissionVM: newSubmissionVM,
                lobName: lobName,
                selectedVersionOfferingsIndex
            });
            const hasClauseMustUpdateImmediately = CoveragesUtil
                .hasCoverageMustUpdateImmediately(clausesToUpdate);
            if (hasClauseMustUpdateImmediately) {
                setLoadingVehicleCoverages(true);

                const { updatedCoverages, errorsAndWarning } = await CoveragesUtil
                    .updateCoveragesToServer({
                        submissionVM,
                        updateCoveragesDTOToServerMethod: updateCoveragesFunction,
                        clausesToUpdate,
                        authHeader,
                        lobName,
                        selectedVersionOfferingsIndex,
                    });
                updatedCoverages.forEach((updateCoverage) => {
                    _.set(newSubmissionVM, updateCoverage.path, updateCoverage.updatedClauses);
                });
                if (updateCoveragesErrorsAndWarnings) {
                    updateCoveragesErrorsAndWarnings(errorsAndWarning);
                }
                const fieldsToRemoveFromValidation = CoveragesUtil
                    .getRemovedClausesIDByUpdatedCoverages(
                        submissionVM,
                        newSubmissionVM,
                        `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages`
                    );
                if (!_.isEmpty(fieldsToRemoveFromValidation)) {
                    disregardLineCoveragesFieldValidation(fieldsToRemoveFromValidation);
                }
                disregardVehicleCoveragesFieldValidation(fieldsToRemoveFromValidation);
                setLoadingVehicleCoverages(false);
                if (postUpdate) {
                    postUpdate();
                }
            }
            // if (hasClauseMustUpdateImmediately) {
            //     getDriversCoverages(false);
            // }
            CoveragesUtil.setAdditionalFieldsAfterCoverageUpdate(newSubmissionVM);
            updateWizardSnapshot(newSubmissionVM);
        },
        [
            selectedVersionOfferingsIndex,
            submissionVM,
            updateWizardSnapshot,
            updateCoveragesFunction,
            authHeader,
            updateCoveragesErrorsAndWarnings,
            disregardLineCoveragesFieldValidation
        ]
    );

    const updateSubmissionVMOnCopyCoverage = useCallback(
        async (copyFromPublicID, copyToPublicIDs) => {
            const lobName = 'personalAuto';
            let newSubmissionVM = viewModelService.clone(submissionVM);
            const [updateCovResponse] = await CoveragesUtil.forceUpdateCoveragesWhenHasCoverageNotSync({
                submissionVM: newSubmissionVM,
                lobName,
                selectedVersionOfferingsIndex,
                updateCoveragesFunction,
                authHeader,
                updateCoveragesErrorsAndWarnings
            });
            newSubmissionVM = updateCovResponse;

            let response;
            switch (jobType) {
                case 'Submission': {
                    response = await copyVehicleCoverages(quoteID || jobID, sessionUUID,
                        copyFromPublicID,
                        copyToPublicIDs,
                        authHeader);
                    const pcDisplayIssues = VehicleValidationUtil.getPCDisplayIssues(response);
                    updateVehicleValidationIssues(pcDisplayIssues);
                    WniClausesUtil.setVehicleCoverageValues(newSubmissionVM,
                        response.personalAuto.vehicleCoverages);
                    WniClausesUtil.setLineCoverageValues(newSubmissionVM,
                        response.personalAuto.lineCoverages);
                    break;
                }
                case 'PolicyChange': {
                    response = await copyVehicleCoverages(quoteID || jobID, sessionUUID,
                        copyFromPublicID,
                        copyToPublicIDs,
                        authHeader);
                    const newVehicleCoverages = WniClausesUtil
                        .getVehicleCoverageValuesFromResponse(response);
                    const newLineCoverages = WniClausesUtil
                        .getLineCoverageValuesFromResponse(response);
                    WniClausesUtil.setVehicleCoverageValues(newSubmissionVM, newVehicleCoverages);
                    WniClausesUtil.setLineCoverageValues(newSubmissionVM, newLineCoverages);
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            CoveragesUtil.setAdditionalFieldsAfterCoverageUpdate(newSubmissionVM);
            // getDriversCoverages(false);
            const fieldsToRemoveFromValidation = CoveragesUtil
                .getRemovedClausesIDByUpdatedCoverages(
                    submissionVM,
                    newSubmissionVM,
                    `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages`
                );
            if (!_.isEmpty(fieldsToRemoveFromValidation)) {
                disregardLineCoveragesFieldValidation(fieldsToRemoveFromValidation);
            }
            updateWizardSnapshot(newSubmissionVM);
        },
        [
            viewModelService,
            submissionVM,
            selectedVersionOfferingsIndex,
            updateCoveragesFunction,
            authHeader,
            updateCoveragesErrorsAndWarnings,
            jobType,
            disregardLineCoveragesFieldValidation,
            updateWizardSnapshot,
            copyVehicleCoverages,
            quoteID,
            jobID,
            sessionUUID,
            updateVehicleValidationIssues
        ],
    );

    const hasCompVehicleCoverage = useCallback(
        (vehiclePublicID) => {
            const currentVehicleCoverage = getVehicleCoverage(vehiclePublicID);
            return _.get(currentVehicleCoverage, 'coverages', []).some((coverage) => {
                if (CoveragePattern.OtherThanCollisionCoverage === coverage.code_Ext
                    && coverage.selected) {
                    const otherThanCollisionDeductibleValue = getDeductibleValue(
                        coverage,
                        CoverageTermPattern.OtherThanCollisionCoverageDeductible
                    );
                    const otherThanCollisionRTCoverageDeductible = getDeductibleValue(
                        coverage,
                        CoverageTermPattern.OtherThanCollisionRTCoverageDeductible
                    );
                    const otherThanCollisionUTCoverageDeductible = getDeductibleValue(
                        coverage,
                        CoverageTermPattern.OtherThanCollisionUTCoverageDeductible
                    );
                    return otherThanCollisionDeductibleValue
                        || otherThanCollisionRTCoverageDeductible
                        || otherThanCollisionUTCoverageDeductible;
                }
                if (CoveragePattern.OtherThanCollisionNNOCoverage === coverage.code_Ext
                    && coverage.selected) {
                    return getDeductibleValue(
                        coverage,
                        CoverageTermPattern.OtherThanCollisionNNOCoverageDeductible
                    );
                }
                return false;
            });
        },
        [getVehicleCoverage, getDeductibleValue],
    );

    const hasVehicleCanCopyFrom = vehicles
        .some((vehicle) => hasCompVehicleCoverage(vehicle.publicID));

    const renderHeaderDom = (item) => {
        return (
            <Flex justifyContent="left" gap="none" alignContent="middle" className="wni-tooltip">
                <span className="wni-tooltip-label">{item}</span>
                <TooltipIcon
                    id="wniTooltipContent"
                    className="wni-tooltip-icon"
                    text={translator(messages.otherTooltip)}
                />
            </Flex>
        );
    };
    const multipleVehicle = vehicles.length > 1;

    const overrideProps = {
        PAVehicleCoveragesSectionCopyCoverageButton: {
            visible: !isReadOnly && multipleVehicle,
            disabled: !hasVehicleCanCopyFrom,
        },
        vehicleOther: {
            renderHeader: renderHeaderDom
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            renderVehicleDescription: getVehicleDescription,
            renderVehicleOtherThanCollision,
            renderVehicleCollision,
            renderVehicleFullSafetyGlass,
            renderVehicleRoadSideOrTowing,
            renderVehicleOther,
            renderVehicleAction,
            onCopyCoverageButtonClick: onCopyCoverageButtonClick
        },
        resolveComponentMap: {
        }
    };

    return (
        <>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={submissionVM}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrideProps}
            />
            {vehicleCoverageModalStatus.isOpen && (
                <VehicleCoverageModal
                    title={vehicleCoverageModalStatus.title}
                    size="lg"
                    vehiclePublicID={vehicleCoverageModalStatus.vehiclePublicID}
                    updateVehicleValidationIssues={updateVehicleValidationIssues}
                    submissionVM={submissionVM}
                    updateWizardSnapshot={updateWizardSnapshot}
                    isOpen={vehicleCoverageModalStatus.isOpen}
                    onResolve={onResolveVehicleCoverageModal}
                    openCopyCoveragesModal={openCopyCoveragesModal}
                    isReadOnly={isReadOnly}
                    updateSubmissionVMOnClauseUpdate={updateSubmissionVMOnClauseUpdate}
                    loadingVehicleCoverages={loadingVehicleCoverages}
                    selectedVersionOfferingsIndex={selectedVersionOfferingsIndex}
                    lobName="personalAuto"
                />
            )}
            <CopyVehicleCoverageModal
                isOpen={copyVehicleCoverageModalStatus.isOpen}
                size="lg"
                onResolve={onResolveCopyVehicleCoverageModal}
                onReject={onRejectCopyVehicleCoverageModal}
                triggerCopyCoverageVehiclePublicID={
                    copyVehicleCoverageModalStatus.triggerCopyCoverageVehiclePublicID
                }
                submissionVM={submissionVM}
                updateWizardSnapshot={updateWizardSnapshot}
                updateVehicleValidationIssues={updateVehicleValidationIssues}
                updateSubmissionVMOnCopyCoverage={updateSubmissionVMOnCopyCoverage}
                hasCompVehicleCoverage={hasCompVehicleCoverage}
            />
        </>
    );
}


PAVehicleCoveragesSection.propTypes = {
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({
                quoteID: PropTypes.string,
                jobID: PropTypes.string,
                sessionUUID: PropTypes.string,
                baseData: PropTypes.shape({
                    
                }),
                lobData: PropTypes.shape({
                    personalAuto: PropTypes.shape({
                        
                    }),
                })
            })
        }
    ),
    updateWizardSnapshot: PropTypes.func.isRequired,
    updateVehicleValidationIssues: PropTypes.func.isRequired,
    updateCoveragesErrorsAndWarnings: PropTypes.func,
    isReadOnly: PropTypes.bool,
    updateCoveragesFunction: PropTypes.func.isRequired,
    copyVehicleCoverages: PropTypes.func.isRequired,
    // getDriversCoverages: PropTypes.func.isRequired,
    disregardFieldValidation: PropTypes.func.isRequired,
    setInvalidVehicleCoverageFieldIssues: PropTypes.func,
    selectedVersionOfferingsIndex: PropTypes.number.isRequired,
};
PAVehicleCoveragesSection.defaultProps = {
    submissionVM: {},
    isReadOnly: false,
    setInvalidVehicleCoverageFieldIssues: _.noop,
    updateCoveragesErrorsAndWarnings: _.noop
};
export default PAVehicleCoveragesSection;
