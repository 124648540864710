// import _ from 'lodash';
import { PaymentDetailsDataUtil } from 'wni-portals-util-js';

const {
    groupDataByInvoiceStreamCode,
    getPaymentDetailsData,
    popupNewBankAccount,
    formatIssueReuslt,
} = PaymentDetailsDataUtil;


// for backward compatibility
export default {
    // ===================
    groupDataByInvoiceStreamCode,
    getPaymentDetailsData,
    popupNewBankAccount,
    formatIssueReuslt
};
