import { defineMessages } from 'react-intl';

export default defineMessages({
    gotAQuestion: {
        id: 'media_sidebar.text.sections.media_sidebar_example.title',
        defaultMessage: 'Got a Question?',
    },
    gotAQuestionContent: {
        id: 'media_sidebar.text.sections.media_sidebar_example.content',
        defaultMessage: 'Our FAQ page contains answers to the questions we get asked the most. Find out if your question is included there.'
    },
    faq: {
        id: 'media_sidebar.text.sections.media_sidebar_example.link_name',
        defaultMessage: 'FAQ'
    }
});
