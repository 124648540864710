import messages from './PAIncidentsAndReportsPage.messages';

export default {
    vendorClue: {
        title: 'Prior Loss Reports',
        labelMap: {
            driverName: { unEditable: true, label: messages.paClueDriver },
            policyDriverName: { unEditable: false, label: messages.paPolicyDriver, driverOptionPath: 'policydrivers' },
            category: { unEditable: true, label: messages.paCategory },
            incidentDate: { unEditable: true, label: messages.paIncidentDate },
            description: { unEditable: true, label: messages.paDescription },
            status: { unEditable: true, label: messages.paStatus },
            amountPaid: { unEditable: true, label: messages.paAmountPaid },
            chargeable: { unEditable: true, label: messages.paSurchargeable },
            surChargeApply: { unEditable: true, label: messages.paSurChargeApplied },
            dispute: {
                unEditable: false,
                label: messages.paDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'disputeother' },
                tooltip: { text: messages.paMVRDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.paExplanation, unVisible: true },
            underwritingResponse_Ext: { unEditable: true, label: messages.paUnderwritingResponse },
            /**
            * toDo
            */
            covPaid: { unEditable: true, label: messages.paCoveragesPaid }
        },
        dtoPath: 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniCLUEReportDTO_Ext'
    },
    userClue: {
        title: 'Prior Loss Entry',
        labelMap: {
            policyDriverId: { label: messages.paDriver, driverOptionPath: 'drivers' },
            category: { label: messages.paCategory, refreshOptionPath: 'description' },
            description: { label: messages.paDescription, initialFiled: true, triggerFiled: 'category', initialFilterValues: [{ code: '', name: '' }] },
            incidentDate: { label: messages.paIncidentDate },
            status: { label: messages.userPriorCarrierStatus, initialFiled: true, initialFilterValues: [{ code: 'Open', name: 'Open' }, { code: 'Closed', name: 'Closed' }, { code: 'Subrogated', name: 'Subrogated' }] },
            otherThanCollisionDetails:{ label: messages.otherThanCollisionDetails, checkvisible: true, disabled: true },
            amountPaid: { label: messages.paAmountPaid },
            chargeable: { label: messages.paSurchargeable, checkvisible: true, disabled: true },
            surChargeApply: { label: messages.paSurChargeApplied, checkvisible: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniCLUEManuallyDTO_Ext'
    },
    vendorMvr: {
        title: 'MVR Reports',
        labelMap: {
            driverName: { unEditable: true, label: messages.paMVRDriverName },
            incidentDate: { unEditable: true, label: messages.paIncidentDate },
            description: { unEditable: true, label: messages.paDescription },
            chargeable: { unEditable: true, label: messages.paSurchargeable },
            surChargeApply: { unEditable: true, label: messages.paSurChargeApplied },
            dispute: {
                unEditable: false,
                label: messages.paDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'Other' },
                tooltip: { placement: 'left', text: messages.paMVRDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.paExplanation, unVisible: true },
            underwritingResponse_Ext: { unEditable: true, label: messages.paUnderwritingResponse }
        },
        dtoPath: 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniMVRReportDTO_Ext'
    },
    userMvr: {
        title: 'MVR Entry',
        labelMap: {
            driverId: { label: messages.paMVRDriverName, driverOptionPath: 'drivers' },
            incidentDate: { label: messages.paIncidentDate },
            description: { label: messages.paDescription, filterVal: 'AccidentFromMVR', },
            descriptionForChips: {label: messages.paDescription, disabled: true },
            source: { label: messages.paSource, hidden: true },
            chargeable: { label: messages.paSurchargeable, checkRatedStatus: true, disabled: true },
            chargeableApply: { label: messages.paSurChargeApplied, checkRatedStatus: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniMVRManuallyDTO_Ext'
    },
    vendorPriorCarrier: {
        title: 'Prior Carrier Reports',
        labelMap: {
            carrier: { unEditable: true, label: messages.paCarrier },
            /* cancelledExpirationDate_Ext: {
                unEditable: true,
                label: messages.paCancelledExpirationDate
            }, */
            status: { unEditable: true, label: messages.userPriorCarrierStatus },
            timeWithPriorCarrier: { unEditable: true, label: messages.paTimewithPriorCarrier },
            bipriorCarreierLimits: { unEditable: true, label: messages.paBIPriorCarrierLimits },
            lapse: { unEditable: true, label: messages.paLapse },
            dispute: {
                unEditable: false,
                label: messages.paDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'Other' },
                tooltip: { placement: 'left', text: messages.priorCarrierTooltip }
            },
            explanationBox: { unEditable: false, label: messages.paExplanation, unVisible: true },
            underwritingResponse: { unEditable: true, label: messages.paUnderwritingResponse }
        },
        dtoPath: 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniPriorCarrierReportDTO_Ext'
    },
    userPriorCarrier: {
        title: 'Prior Carrier Entry',
        labelMap: {
            carrier_Ext: {
                label: messages.paCarrier,
                isTypeAlphabet: true,
                linkage: [
                    { linkPath: 'otherCarrierName_Ext', linkCode: 'Other' },
                    { linkPath: 'reasonForNoInsurance_Ext', linkCode: 'NoPrior' },
                ],
                noPriorLinkages: [
                    { linkPath: 'status_Ext', linkCode: 'NoPrior' },
                    { linkPath: 'expirationDate', linkCode: 'NoPrior' },
                    { linkPath: 'timeWithPriorCarrier_Ext', linkCode: 'NoPrior' },
                    { linkPath: 'bipriorCarreierLimits_Ext', linkCode: 'NoPrior' },
                    { linkPath: 'lapse_Ext', linkCode: 'NoPrior' },
                ]
            },
            otherCarrierName_Ext: {
                unEditable: false,
                label: messages.paOtherCarrierName,
                unVisible: true,
            },
            status_Ext: { label: messages.userPriorCarrierStatus },
            expirationDate: { label: messages.paCancelledExpirationDate },
            timeWithPriorCarrier_Ext: { label: messages.paTimewithPriorCarrier },
            bipriorCarreierLimits_Ext: { label: messages.paBIPriorCarrierLimits, defineCtrlType: 'typelist', initialFilterValues: [
                { code: '20,000/40,000', name: '20,000/40,000' },
                { code: '25,000/25,000', name: '25,000/25,000' },
                { code: '25,000/50,000', name: '25,000/50,000' },
                { code: '30,000/60,000', name: '30,000/60,000' },
                { code: '50,000/50,000', name: '50,000/50,000' },
                { code: '50,000/100,000', name: '50,000/100,000' },
                { code: '75,000/150,000', name: '75,000/150,000' },
                { code: '100,000/100,000', name: '100,000/100,000' },
                { code: '100,000/200,000', name: '100,000/200,000' },
                { code: '100,000/300,000', name: '100,000/300,000' },
                { code: '200,000/200,000', name: '200,000/200,000' },
                { code: '200,000/300,000', name: '200,000/300,000' },
                { code: '200,000/500,000', name: '200,000/500,000' },
                { code: '250,000/500,000', name: '250,000/500,000' },
                { code: '300,000/500,000', name: '300,000/500,000' },
                { code: '500,000/500,000', name: '500,000/500,000' },
                { code: '500,000/1,000,000', name: '500,000/1,000,000' },
                { code: '1,000,000/1,000,000', name: '1,000,000/1,000,000' },
                { code: '5,000,000/5,000,000', name: '5,000,000/5,000,000' },
                { code: '55,000 CSL', name: '55,000 CSL' },
                { code: '60,000 CSL', name: '60,000 CSL' },
                { code: '65,000 CSL', name: '65,000 CSL' },
                { code: '75,000 CSL', name: '75,000 CSL' },
                { code: '100,000 CSL', name: '100,000 CSL' },
                { code: '125,000 CSL', name: '125,000 CSL' },
                { code: '200,000 CSL', name: '200,000 CSL' },
                { code: '300,000 CSL', name: '300,000 CSL' },
                { code: '500,000 CSL', name: '500,000 CSL' },
                { code: '1,000,000 CSL', name: '1,000,000 CSL' },
                { code: '2,000,000 CSL', name: '2,000,000 CSL' },
                { code: '3,000,000 CSL', name: '3,000,000 CSL' },
                { code: '5,000,000 CSL', name: '5,000,000 CSL' },
                { code: '10,000,000 CSL', name: '10,000,000 CSL' }
            ] },
            reasonForNoInsurance_Ext: { label: messages.paReason, unVisible: true },
            lapse_Ext: {
                unEditable: false,
                disabled: true,
                label: messages.paLapse,
                radioValues: [
                    {
                        code: 'true',
                        displayName: {
                            id: 'quote.clur.userAdd.Yes',
                            defaultMessage: 'Yes'
                        },
                    },
                    {
                        code: 'false',
                        displayName: {
                            id: 'quote.clur.userAdd.No',
                            defaultMessage: 'No'
                        }
                    }
                ]
            }
        },
        dtoPath: 'wni.edge.capabilities.policyjob.lob.personalauto.coverages.dto.WniPriorCarrierManuallyDTO_Ext'
    }
};
