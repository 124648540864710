import React from 'react';
import _ from 'lodash';
import  { WALPolicyDetailsPage } from 'wni-capability-quoteandbind-wal-react';
import { RTPolicyInfoService } from 'wni-capability-quoteandbind-rt';
import WizardPage from '../../templates/RTWizardPage';

function RTPolicyDetailsPage(props) {

    return (
        <WALPolicyDetailsPage 
            savePolicyInfoData={RTPolicyInfoService.saveRTPolicyInfoData}
            linePath = 'roadTrail'
            {...props}
        />
    );
}

RTPolicyDetailsPage.propTypes =  WizardPage.propTypes;
RTPolicyDetailsPage.defaultProps = WizardPage.defaultProps;

export default RTPolicyDetailsPage;
