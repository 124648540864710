
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import { GwUWSubmissionService } from 'wni-capability-gateway';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './MarkNotTakenComponent.metadata.json5';
import styles from './MarkNotTakenComponent.module.scss';

const MarkNotTakenComponent = (props) => {
    const {
        jobNumber,
        // onMarkNotTaken: onMarkNotTakenServiceCall,
        isServiceCallInProgress: isLoading,
        preNotTaken,
        postNotTaken,
    } = props;

    const {
        isComponentValid,
        onValidate
    } = useValidation('MarkNotTakenComponent');
    const { authHeader, authUserData } = useAuthentication();
    // const [dataForm, updateDataForm] = useState({});
    const viewModelService = useContext(ViewModelServiceContext);
    const [showNotTakenPanel, setShowNotTakenPanel] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const initialModel = viewModelService.create(
        { jobNumber },
        'pc',
        'edge.capabilities.gateway.job.submission.dto.MarkSubmissionNotTakenRequestDTO'
    );
    _.set(initialModel, 'letterType_Ext', 'NotTakenAck');
    const [dataForm, updateDataForm] = useState(initialModel);

    const handleMarkNotTaken = async () => {
        if (!isComponentValid) {
            updateShowErrors(true);
            return;
        }

        updateShowErrors(false);

        preNotTaken();
        const submissionDTO = await GwUWSubmissionService.markSubmissionNotTaken(dataForm.value,
            authHeader);
        const statusCode = _.get(submissionDTO, 'NotTaken'); // 'NotTaken'
        // if (submissionDTO) {
        postNotTaken(submissionDTO);
        // }
        // await onMarkNotTakenServiceCall(dataForm.value);
    };

    const onMarkNotTaken = useCallback(() => {
        setShowNotTakenPanel(true);
    }, []);
    const onCancelNotTaken = useCallback(() => {
        setShowNotTakenPanel(false);
    }, []);

    //-------------------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showRequired: true,
        },
        markNotTakenButton: {
            visible: !showNotTakenPanel,
            renderContent: renderHelper.getButtonRenderFunc(isLoading),
        },
        markNotTakenPanel: {
            visible: showNotTakenPanel,
        },
        notTakenBtnCancel: {
            renderContent: renderHelper.getButtonRenderFunc(isLoading),
        },
        notTakenBtnConfirm: {
            renderContent: renderHelper.getButtonRenderFunc(isLoading),
            // disabled: !isComponentValid,
        }
    };

    const writeValue = (value, path) => {
        // const newFormData = _.cloneDeep(dataForm);
        const newFormData = viewModelService.clone(dataForm);
        _.set(newFormData, path, value);
        updateDataForm(newFormData);
    };

    // onModelChange doesn't work correclty. Hmm.
    // const onModelChange = (newModel) => {
    //     updateModel(newModel);
    // };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onMarkNotTaken,
            onCancelNotTaken,
            handleMarkNotTaken,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dataForm}
            // onModelChange={udpateMarkNotTakenDto}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            // resolveValue={readValue}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
            // showErrors
        />
    );
};

MarkNotTakenComponent.propTypes = {
    jobNumber: PropTypes.string.isRequired,
    isServiceCallInProgress: PropTypes.bool,
    preNotTaken: PropTypes.func,
    postNotTaken: PropTypes.func,
};

MarkNotTakenComponent.defaultProps = {
    isServiceCallInProgress: false,
    preNotTaken: _.noop,
    postNotTaken: _.noop,
};

export default MarkNotTakenComponent;
