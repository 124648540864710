
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DropdownMenuHeader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardContext, wizardStepProps } from '@xengage/gw-portals-wizard-react';

import { WALMultiQuoteService } from 'wni-capability-quoteandbind-wal';

import { BaseAddNewVersionComponent } from 'wni-components-platform-react';

import { QuoteCommonMessages } from 'wni-platform-translations';

// import metadata from './WALAddNewVersionComponent.metadata.json5';
// import styles from './WALAddNewVersionComponent.module.scss';
import messages from './WALAddNewVersionComponent.messages';



import { DropdownMenuLink } from '@jutro/router';



/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function WALAddNewVersionComponent(props) {
    

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const {
        // model: dtoVM,
        // onValueChange,
        // showErrors,
        sxsDataDTO = {},
        ...restProps
    } = props;


    const {
        // canAddNewHO3Version,
        // canAddNewHO5Version,
        // canAddNewVersion,
        // canAddNewSelectVersion,
        periods = [{}],
    } = sxsDataDTO;

    const [{
        branchCode,
        policyType_Ext: policyType,
    }] = periods;


    useEffect(() => {
    }, []);

    
    const getWALMenuItems = useCallback((sxsDataDTOParam) => {
        const {
            // canAddNewHO3Version,
            // canAddNewHO5Version,
            canAddNewVersion,
            // canAddNewSelectVersion,
            // periods = [{}],
        } = sxsDataDTOParam;

        const menuItems = [
            {  
                // menuName: translator(QuoteCommonMessages.addVersions),
                menuName: translator(QuoteCommonMessages.addAnotherVersionOfThisQuote),
                createService: WALMultiQuoteService.createDuplicateQuote,
                menuVisible: canAddNewVersion,
            },
            // {  
            //     menuName: translator(messages.createHO3Version),
            //     createService: WALMultiQuoteService.createHO3Quote,
            //     menuVisible: canAddNewHO3Version,
            // },
            // {  
            //     menuName: translator(messages.createHO5Verseion),
            //     createService: WALMultiQuoteService.createHO5Quote, // Hmm.
            //     menuVisible: canAddNewHO5Version,
            // },
            // {  
            //     // menuName: translator(messages.createSelectVersionParam, { policyType: normalizedPolicyType }),
            //     menuName: translator(messages.createSelectVersionParam),
            //     createService: WALMultiQuoteService.createSelectQuote,
            //     menuVisible: canAddNewSelectVersion,
            // },
        ];
        
        return menuItems;

    }, []);

    const walMenuItems = getWALMenuItems(sxsDataDTO);

    return (
        <BaseAddNewVersionComponent {...restProps}
            menuItems={walMenuItems}
        />
    );


    // // =====================================
    // const renderSwitchToOtherBranchMenuItems = useCallback((accountJobPeriodDTOListParam) => {
    //     const retval =  accountJobPeriodDTOListParam.map((accountJobPeriodDTO) => {
    //         const {
    //             publicID: periodPublicID,
    //             branchName,
    //             // hoCoverageForm,
    //         } = accountJobPeriodDTO;

    //         // const normalizedCoverageForm = hoCoverageForm.toUpperCase();

    //         // const menuName = `${branchName} ${normalizedCoverageForm}`;
    //         const menuName = branchName;
    //         const onMenuClick = async() => onSwitchToBranch(periodPublicID);

    //         return (
    //             <DropdownMenuLink
    //                 type="action"
    //                 onClick={onMenuClick}
    //                 key={`${menuName}-MenuLink`}
    //             >
    //                 {menuName}
    //             </DropdownMenuLink>
    //         );
    //     });

    //     return retval;
    // }, [onSwitchToBranch]);


    // // const writeValue = (value, path) => {
    // //     onValueChange(value, path);
    // // };
    // const renderCreateVersionMenuItems = (menuItemOptions) => {
    //     const retval =  menuItemOptions.map((menuItem) => {
    //         const {
    //             nameMsgKey,
    //             createService
    //         } = menuItem;

    //         const menuName = translator(messages[nameMsgKey]);
    //         const onMenuClick = async() => onCreateNewVersion(createService);
            
    //         return (
    //             <DropdownMenuLink
    //                 type="action"
    //                 onClick={onMenuClick}
    //                 key={`${menuName}-MenuLink`}
    //             >
    //                 {menuName}
    //             </DropdownMenuLink>
    //         );
    //     });

    //     return retval;
    // };

    // const renderCreateVersionMenuItemsV2 = useCallback(() => {
    //     const menuItemsGenerarator = getMenuItems || getWALMenuItems;
    //     const menuItems = menuItemsGenerarator(sxsDataDTO);

    //     // =======================
    //     const visibleMenuItems =  menuItems.filter((item) => item.menuVisible);

    //     const retval =  visibleMenuItems.map((menuItem) => {
    //         const {
    //             menuName,
    //             createService,
    //             menuVisible,
    //         } = menuItem;

    //         const onMenuClick = async() => onCreateNewVersion(createService);
            
    //         return (
    //             <DropdownMenuLink
    //                 type="action"
    //                 onClick={onMenuClick}
    //                 key={`${menuName}-MenuLink`}
    //                 visible={menuVisible}
    //             >
    //                 {menuName}
    //             </DropdownMenuLink>
    //         );
    //     });

    //     return retval;
    // }, [sxsDataDTO, onCreateNewVersion]);

    // const getMenuButtonText = useCallback((chooseOptions, addOptions) => {
    //     let retval = QuoteCommonMessages.addVersions;
    //     if (!_.isEmpty(chooseOptions)) {
    //         if (_.isEmpty(addOptions)) {
    //             retval = QuoteCommonMessages.chooseVersions;
    //         } else {
    //             retval = QuoteCommonMessages.chooseAddVersions;
    //         }
    //     }

    //     return retval;
    // }, []);

    // //----------------------------------
    // const createVersionMenuItems = renderCreateVersionMenuItemsV2();
    // //
    // const switchVersionMenuItems = renderSwitchToOtherBranchMenuItems(accountJobPeriodDTOList);
    // const allMenuItems = [...switchVersionMenuItems, ...createVersionMenuItems];
    // const menuButtonText = getMenuButtonText(switchVersionMenuItems, createVersionMenuItems);
    

    // const overrideProps = {
    //     '@all': {
    //     },
    //     '@field': {
    //         labelPosition: 'left',
    //         showOptional: false,
    //         showRequired: true
    //     },
    //     chooseAddNewVersionDropdown: {
    //         buttonText: menuButtonText,
    //         disabled: _.isEmpty(allMenuItems),
    //         content: allMenuItems,
    //     }
    // };

    // const resolvers = {
    //     resolveClassNameMap: styles,
    //     resolveCallbackMap: {

    //     },
    //     resolveComponentMap: {

    //     },
    // };

    // return (
    //     <ViewModelForm
    //         uiProps={metadata.componentContent}
    //         model={{}}
    //         overrideProps={overrideProps}
    //         classNameMap={resolvers.resolveClassNameMap}
    //         callbackMap={resolvers.resolveCallbackMap}
    //         componentMap={resolvers.resolveComponentMap}
    //         // onModelChange={updateModelValue}
    //         // resolveValue={readValue}
    //         // onValueChange={writeValue}
    //         // showErrors={showErrors}
    //     />
    // );
}

WALAddNewVersionComponent.propTypes = {
    sxsDataDTO: PropTypes.shape({}).isRequired,
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
    // /**
    //  * (Optinal) get menu items
    //  */
    // getMenuItems: PropTypes.func,
};

WALAddNewVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,
    // getMenuItems: undefined,
};

export default WALAddNewVersionComponent;
