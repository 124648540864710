import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('crLineCoveragesService'), method, data, additionalHeaders);
}

export default class CRLineCoveragesService {
  
    /**
     * Invokes CRLineCoveragesHandler.getLineCoveragesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLineCoveragesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getLineCoveragesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CRLineCoveragesHandler.getLineCoverages()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLineCoverages(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getLineCoverages', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CRLineCoveragesHandler.updateLineCoverages()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} crCoveragesDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateLineCoverages(jobNumber, sessionUUID, crCoveragesDTO, authHeader = {}) {
        return processSubmission('updateLineCoverages', [jobNumber, sessionUUID, crCoveragesDTO], authHeader);
    }

    /**
     * Invokes CRLineCoveragesHandler.onCrimeLineCoveragesNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onCrimeLineCoveragesNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onCrimeLineCoveragesNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} displayablePropertyName
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static fetchCrimeDetailsClassCodeTable(jobID , sessionUUID, displayablePropertyName, authHeader = {}) {
        return processSubmission(
            'fetchCrimeDetailsClassCodeTable',
            [jobID, sessionUUID, displayablePropertyName],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} displayablePropertyName
     * @param {Object} row
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getUpdatedCrimeDetails(jobID , sessionUUID, displayablePropertyName, row, authHeader = {}) {
        return processSubmission(
            'getUpdatedCrimeDetails',
            [jobID, sessionUUID, displayablePropertyName, row],
            authHeader
        );
    }

    /**
     * Invokes CRLineCoveragesHandler.searchCrimeLineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchCrimeLineCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, authHeader = {}) {
        return processSubmission('searchCrimeLineCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType], authHeader);
    }

    /**
     * Invokes CRLineCoveragesHandler.addSearchedCrimeLineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedCrimeLineCoverages(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedCrimeLineCoverages', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}