import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _, { remove, update } from 'lodash';
import { ModalHeader, ModalNext, ModalBody, Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import { WniAccountContactService } from 'wni-capability-quoteandbind';
import metadata from './AddContactPopup.metadata.json5';
// import messages from '../CAPolicyDetailsPage.messages';

function AddContactPopup(props) {

    const defaultAddContactService = WniAccountContactService.saveContact
    const {
        contactVM: originContactVM,
        size,
        isOpen,
        onResolve,
        onReject,
        onValueChange,
        viewModelService,
        serviceProps,
        addContactService = defaultAddContactService,
    } = props;

    const translator = useTranslator();
    const {
        jobID,
        sessionUUID,
        authHeader
    } = serviceProps;

    const [showErrors, updateShowErrors] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [contactVM, updateContactVM] = useState(originContactVM);

    const { contactCode } = contactVM.value

    const {
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('AddContactPopup');

    const IsValidFields = useCallback(() => {
        if (!contactVM.aspects.valid || !contactVM.aspects.subtreeValid) {
            return false;
        }
        return true;
    }, [contactVM]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, contactVM]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const saveContact = async () => {
        const requestData = {
            jobID,
            sessionUUID,
            contact: contactVM.value
        }
        updateIsLoading(true);
        const res = await addContactService(requestData, authHeader);
        updateIsLoading(false);
        onResolve(res)
    }

    const writeValue = useCallback((value, path) => {
        const newContactVM = viewModelService.clone(contactVM);
        _.set(newContactVM, path, value);
        updateContactVM(newContactVM);
    }, [contactVM, viewModelService])

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                showOptional: false,
                labelPosition: 'left',
                showRequired: true,
                showErrors: showErrors
            },
            companyDetail: {
                visible: contactCode === 'company'
            },
            feinId: {
                visible: contactCode === 'company'
            },
            personDetail: {
                visible: contactCode === 'person'
            },
            lexId: {
                visible: contactCode === 'person'
            },
            addressContainer: {
                model: contactVM,
                dataPath: 'primaryAddress',
                onAddressChange: writeValue,
                showErrors,
                unRequiredField: {
                    county: false
                },
                hideFieldType: {
                    addressLine3: false,
                    description: false
                },
                defaultCountryRequired: false
            },
            cancel: {
                onClick: () => { onReject(); }
            },
            save: {
                onClick: isComponentValid ? saveContact : handleValidation
            }
        }
    }, [showErrors, contactVM, isComponentValid]);

    const overrideProps = generateOverrides();

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            contactVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressInputComponent: AddressInputComponent
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title='New Contact' onClose={onReject}/>
            <ModalBody id="addressBookPage">
                {isLoading ? <Loader loaded={!isLoading} /> : <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={contactVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                    onValueChange={writeValue}
                />}
            </ModalBody>
        </ModalNext>
    );
}

AddContactPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
};
AddContactPopup.defaultProps = {
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
};

export default AddContactPopup;

