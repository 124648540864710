import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniPolicyService, WniSubmissionService } from 'wni-capability-gateway';
import {
    QuoteUtil, DocumentsUtil, UWIssueUtil,
    WniPNIUtil,
} from 'wni-portals-util-js';
import { DocumentCheckedComponent } from 'wni-capability-gateway-react';
import { WniPAQuoteService } from 'wni-capability-quoteandbind';
import { QuoteUWIssuesPendingList } from 'wni-components-platform-react';
import { R1Config, WizardConstants } from 'wni-portals-config-js';
import metadata from './PAPolicySummaryPage.metadata.json5';
import messages from './PAPolicySummaryPage.messages';
// import marketingMessageDropdownItems from './PAPolicyInformationPage.static';
import styles from './PAPolicySummaryPage.module.scss';


const {
    PolicyInformationPageConfig: {
        marketingMessageOptions,
    }
} = R1Config;

function PAPolicySummaryReadOnlyPage(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        wizardData: submissionVM, updateWizardData, history,
        wizardPageData,
    } = props;
    const { domainCompany } = useDependencies('domainCompany');
    const localeService = ServiceManager.getService('locale-service');
    const { authHeader } = useAuthentication();
    const { onValidate } = useValidation('PolicyInformationPage');
    const [retainInAgencyData, updateRetainInAgencyData] = useState([]);
    const [submitToWesternNationalData, updateSubmitToWesternNationalData] = useState([]);
    const [initialized, updateInitialized] = useState(false);

    const {
        quoteID,
        sessionUUID,
        baseData: {
            PolicyInfoSource_Ext: policyInfoSource,
            selectedVersion_Ext: selectedVersion,
            primaryNamedInsured_Ext: {
                displayName: pniDisplayName,
                emailAddress1: pniEmailAddress,
            },
            primaryNamedInsured_Ext: primaryNamedInsured,
            periodStatus
        },
        bindData: {
            policyNumber
        }
    } = submissionVM.value;
    const chosenQuote = _.get(submissionVM, 'value.bindData.chosenQuote');
    const offeredQuotes = _.get(submissionVM, 'value.quoteData.offeredQuotes') || [];
    const selectedQuote = _.find(offeredQuotes, (quote) => quote.publicID === chosenQuote);
    const totalCost = _.get(selectedQuote, 'premium.totalBeforeTaxes');

    const {
        [WizardConstants.paymentViewType]: paymentViewType,
        [WizardConstants.sideBySideData]: sideBySideData,
    } = wizardPageData;

    

    const pniPrimaryPhoneNumber = WniPNIUtil.getPrimaryPhoneNumber(primaryNamedInsured);
    const infoUWIssueBaseTitle = translator(messages.requiringPostIssueReview);

    useEffect(() => {
        const getAvailableDocumentsData = (async () => {
            let documents;
            if (periodStatus === 'Bound') {
                documents = await WniPolicyService
                    .getPortalDocumentsForPolicyGroupByType(policyNumber, authHeader);
            } else{
                documents = await WniSubmissionService.getPortalDocumentsForSubmissionGroupByType(
                    [quoteID], authHeader
                );
            }
            const retainInAgency = DocumentsUtil.getDocumentDataByVersion(documents,
                DocumentsUtil.RETAIN_IN_AGENCY, selectedVersion);
            const submitToWesternNational = DocumentsUtil.getDocumentData(documents,
                DocumentsUtil.SUBMIT_TO_WESTERNNATIONAL);
            return { retainInAgency, submitToWesternNational };
        });
        if (!initialized) {
            getAvailableDocumentsData().then((res) => {
                const { retainInAgency, submitToWesternNational } = res;
                updateRetainInAgencyData(retainInAgency);
                updateSubmitToWesternNationalData(submitToWesternNational);
            });
            updateInitialized(true);
        }
    }, [authHeader, quoteID, updateRetainInAgencyData, retainInAgencyData, initialized, selectedVersion, submissionVM.value]);

    const onNext = useCallback(() => {
        return submissionVM;
    });

    const renderCellContent = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        if (path === 'licenseState') {
            return translator(
                {
                    id: `typekey.State.${data.licenseState}`,
                    defaultMessage: data.licenseState
                }
            );
        }
        return _.get(data, path);
    },
    [translator]);

    const isShowNotification = useCallback(() => {
        if ((policyInfoSource) && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const getMarketingMessageDropDownItems = useCallback(() => {
        const { domainDesc } = domainCompany;
        return marketingMessageOptions[domainDesc]
            ? marketingMessageOptions[domainDesc]
            : marketingMessageOptions.default;
    }, [domainCompany]);

    const getMarketingDefaultValue = useCallback(() => {
        const { domainDesc } = domainCompany;
        const vals = marketingMessageOptions[domainDesc]
            ? marketingMessageOptions[domainDesc][0]
            : marketingMessageOptions.default[3];
        return vals.code;
    }, [domainCompany]);

    // =================================
    const infoUWIssues = UWIssueUtil.getPendingInfoIssuesFromSxsData(sideBySideData, selectedVersion);
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        totalPremium: {
            value: totalCost,
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry(),
            // value: bindData && bindData.contactPhone,
            value: pniPrimaryPhoneNumber,
        },
        emailAddress: {
            // value: bindData && bindData.contactEmail
            value: pniEmailAddress,
        },
        vehicleList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
        },
        driverList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
        },
        userAggreenmenLi1: {
            content: messages.UserAggreenmenli1
        },
        userAggreenmenLi2: {
            content: translator(messages.UserAggreenmenli2, { domainName: `${domainCompany.domainName}` })
        },
        lossPolicyReleaseForm: {
            visible: isShowNotification()
        },
        lossPolicyReleaseFormDiv: {
            visible: isShowNotification()
        },
        marketingMessageHeading: {
            visible: false
        },
        marketingMessage: {
            availableValues: getMarketingMessageDropDownItems(),
            defaultValue: getMarketingDefaultValue(),
            visible: false
        },
        documentcheckedList: {
            // Note: might need to provide a readonly mode for this component as well
            history: history,
            submission: submissionVM,
            renderData: [
                {
                    subTitle: messages.RetaininAgency,
                    data: retainInAgencyData,
                },
                {
                    subTitle: translator(messages.WesternNational, { domainName: `${domainCompany.domainName}` }),
                    data: submitToWesternNationalData,
                }
            ],
        },
        uwIssuePendingList: {
            visible: !_.isEmpty(infoUWIssues),
            uwIssues: infoUWIssues,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onRenderCell: renderCellContent
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            documentcheckedcomponent: DocumentCheckedComponent,
            uwissuependinglist: QuoteUWIssuesPendingList,
        }

    };
    return (
        <WizardPage
            // onNext={onNext}
            showNext={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PAPolicySummaryReadOnlyPage.propTypes = wizardProps;
export default PAPolicySummaryReadOnlyPage;
