import { defineMessages } from 'react-intl';

export default defineMessages({
    useExistingContact: {
        id: 'policycommon.directives.schedules.contact.Use existing contact?',
        defaultMessage: 'Use existing contact?',
    },
    existingContact: {
        id: 'policycommon.directives.schedules.contact.Existing Contact',
        defaultMessage: 'Existing Contact',
    },
    newContact: {
        id: 'policycommon.directives.schedules.contact.New Contact',
        defaultMessage: 'New Contact',
    }
});
