import React, {
    useContext,
    useCallback
} from 'react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useTranslator } from '@jutro/locale';
import { InlineNotification, Icon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useHistory } from 'react-router-dom';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { WniDateUtil, WizardPageJumpUtil, WniProductsUtil } from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {  useProductsData } from 'wni-portals-util-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import styles from './BaseWizardPageHeader.module.scss';
import customMessages from './BaseWizardPageHeader.messages';
import { Button } from '@jutro/legacy/components';
/**
 * @prop {string} propTypes.wizardSubmission - submission view model
 * @prop {string} propTypes.isSideHeader - flag to specify side header
 */

const {
    // To be refactored
    allowedPolicyTypes: QUOTE_TRANSACTION,
} = appConfig;

const BaseWizardPageHeader = (props) => {
    const translator = useTranslator();

    const {
        interactionModel,
    } = useDependencies(['interactionModel', 'loadingMask']);

    const history = useHistory();

    const {
        // wizardSubmission,
        // isSideHeader, // Only used in CPBuildingsAndLocationsPage, to be removed.
        sideHeaderConfigs: {
            titleContainerClass = cx({
                [styles.gwWizardPageTitle]: true,
                [styles.gwWizardSidePageTitle]: false,
            }),
            categoryContainerClass = cx({
                [styles.gwWizardPageCategory]: true,
                [styles.gwWizardSidePageCategory]: false,
            }),
            associatedPageClass = cx({
                [styles.gwWizardPageAssociated]: true,
                [styles.gwWizardSidePageAssociated]: false,
            }),
            wizardSidePageTitleClass = cx({
                [styles.gwWizardSidepageTitle]: false
            }),
            titleSeparator = undefined, // false,
        } = {},
        displayWizardHeader,
    } = props;

    
    const {
        wizardData: wizardSubmission,
        // wizardSnapshot: wizardSubmission,
        wizardPageHeaderExtension: WizardPageHeaderExtension,
        //
        wizardPageData,
        updateWizardPageData,
        wizardPageHeaderFormatter,
        // clearVisitedStepsFromQuotePage,
    } = useContext(WizardContext);

    const {
        quoteID,
        policyNumber,
        jobID,
        baseData: {
            jobType,
            accountNumber,
            productCode,
            product_Ext: product,
            accountHolder: {
                firstName: getFirstName = '',
                lastName: getLastName = '',
                displayName: getDisplayName = '',
                primaryAddress: {
                    postalCode,
                } = {},
            } = {},
            selectedVersion_Ext: selectedVersionPublicID,
        } = {},
        // This should be split into another layer of component, since AQWizardPage has
        // a difference kind of data structure for wizardData
    } = wizardSubmission.value;
    
    
    const {
        [WizardConstants.copySubmission]: copiedSubmission,
    } = wizardPageData;

    const offerings = _.get(wizardSubmission.value, `lobData.${_.lowerFirst(productCode)}.offerings`, []);

    const selectedVersion = offerings.find((offering) => _.get(offering, 'publicID_Ext') === selectedVersionPublicID);


    // const QUOTE_TRANSACTION = [
    //     'CommercialProperty', 'WC7WorkersComp',
    //     'Homeowners', 'BusinessOwners',
    //     'PersonalAuto', 'HOPHomeowners'
    // ];
    const getAccountDisplayName = !getDisplayName ? `${getFirstName} ${getLastName}` : getDisplayName;
    const accountName = _.trim(getAccountDisplayName)
        || _.get(wizardSubmission.value, 'baseData.accountHolder.contactName')
        || _.get(wizardSubmission.value, 'baseData.accountName');

    let wizardHeaderTemplate;
    if (productCode && QUOTE_TRANSACTION.includes(productCode)) {
        wizardHeaderTemplate = 'transaction';
    }
    /* eslint no-underscore-dangle: 0 */
    if (wizardSubmission._dtoName.indexOf('fnol') !== -1) {
        wizardHeaderTemplate = 'claim';
    }
    const deprecatedPolicyChangeJobID = _.get(wizardSubmission.value, 'jobID', '');
    
    let productWithQuoteNumber = WniProductsUtil.getProductNameByLinePattern(product) + translator(customMessages.quoteNumber,
        {
            quoteNumber: quoteID || policyNumber || jobID,
            jobId: deprecatedPolicyChangeJobID
        });

    let jobStatusInfo = '';
    let isDisplayPolicyType = false;
    let policyTypeDisplayName = _.get(selectedVersion, 'policyType_Ext');
    let isDisplayJobType = false;

    const defaultPageHeaderProfile = {
        productWithQuoteNumber,
        jobStatusInfo,
        isDisplayPolicyType,
        isDisplayJobType,
        policyTypeDisplayName,
    };
    let formatterResult = {};
    if (wizardPageHeaderFormatter) {
        formatterResult = wizardPageHeaderFormatter({
            productWithQuoteNumber,
            wizardData: wizardSubmission,
            wizardPageData,
            jobStatusInfo,
            policyTypeDisplayName,
        });
    }
    ({
        productWithQuoteNumber,
        jobStatusInfo,
        isDisplayPolicyType = false,
        isDisplayJobType = false,
        policyTypeDisplayName
    } = {
        ...defaultPageHeaderProfile,
        ...formatterResult,
    })

    const jobStatusInfoClass = cx({
        [styles.quotedInfo]: true,
    });  

    const policyTypeClass = cx({
        [styles.policyType]: true
    });


    const getWizardHeader = () => {
        const poductIconPath = WniProductsUtil.getProductIcon(productCode);
        return (
            <>
                <div className={`${wizardSidePageTitleClass} font-Primary-bold-24 d-flex`}>
                    <Flex
                        className="flex-sm-fill"
                        justifyContent="left"
                        alignContent="center"
                        gap="small"
                    >
                        {/* To be refactoried */}
                        {productCode!=='PersonalAuto' ? <span><Icon icon={poductIconPath} style={{ width: "34px", fontSize: "36px" }}/></span> : null }
                        
                        <span>{productWithQuoteNumber}</span>
                        {
                            isDisplayPolicyType  && policyTypeDisplayName ? (
                                <span className={policyTypeClass}>
                                    {policyTypeDisplayName}
                                </span>
                            ) : null
                        }
                        {
                            isDisplayJobType ? (
                                <span>
                                    {` - ${jobType.match(/[A-Z][^(A-Z)]*/g).join(' ')} ${jobID}`}
                                </span>
                            ) : null
                        }
                        <span className={jobStatusInfoClass}>{jobStatusInfo}</span>
                    </Flex>
                    <div>
                        { WizardPageHeaderExtension ? <WizardPageHeaderExtension jobID={jobID} /> : null }
                    </div>
                </div>
                {/* {warningMessage.warningExist && warnMessageControls} */}
            </>
        );
    };

    const retrieveCopySubmission = useCallback(async (copiedSubmissionParam) => {
        if (_.isEmpty(copiedSubmissionParam)) {
            return;
        }
        const copiedQuoteID =  _.get(copiedSubmissionParam, 'jobNumber');
        WizardPageJumpUtil.goToNewQuoteWizard(history, {
            productCode,
            postalCode,
            jobID: copiedQuoteID
        });
    }, [history, postalCode, productCode]);

    const toNewSubmission = useCallback(async () => {
        await retrieveCopySubmission(copiedSubmission);
        updateWizardPageData({ [WizardConstants.copySubmission]: undefined }); // Clear cached copySubmission
    }, [retrieveCopySubmission, updateWizardPageData, copiedSubmission]);

    const copySubmissionLink = useCallback(() => {
        if (!_.isEmpty(copiedSubmission)) {
            return (
                <InlineNotification
                    id="wizardPageHeaderCopiedSubmissionSection"
                    message={translator(customMessages.copyMessage, {
                        jobNumber: <Button className="wni-button-link" onClick={toNewSubmission}>{copiedSubmission.jobNumber}</Button>
                    })}
                    messageProps= {
                        {info: ' '}
                    }
                    type="info"
                    isDismissable={false}
                />
                
            );
        }
        return '';
    }, [toNewSubmission, translator, copiedSubmission]);

    const url = interactionModel.getURLObj(null, 'accountSummary', accountNumber);

    // To be refactored
    const submissionQuote = wizardHeaderTemplate === 'transaction' && (
        <div className={`${titleContainerClass} normal-color`}>
            <div className={categoryContainerClass}>
                {translator(customMessages.accountTitle)}
                <LinkComponent
                    className={associatedPageClass}
                    tabIndex="-1"
                    {...url}
                >
                    {accountName}
                </LinkComponent>
            </div>
            {titleSeparator}
            {displayWizardHeader ? getWizardHeader() : '' }
            {copySubmissionLink()}
        </div>
    );

    // To be refactored
    const claimHeader = useCallback(() => {
        // const claimNumber = _.get(wizardSubmission.value, 'claimNumber', '');
        // POI-57879 PE.COMM - Claims - Reference number wording and format Update
        const peclaimNumber = _.get(wizardSubmission.value, 'peclaimNumber', '');
        return wizardHeaderTemplate === 'claim' ? (
            <div className={styles.gwPageTitle}>
                {translator({
                    id: 'fnol.common.views.fnol.New Claim',
                    defaultMessage: ''
                })}
                <span className={styles.gwClaimsWizardSpanNumber}>{peclaimNumber}</span>
            </div>
        ) : null;
    }, [wizardHeaderTemplate, translator, wizardSubmission.value]);

    // To be refactored
    const getConvertedInfo = () => {
        let warnMessageControls = '';
        // const jobType = _.get(wizardSubmission.value, 'baseData.jobType');
        const source = _.get(wizardSubmission.value, 'baseData.legacyAccountNo_Ext');
        const effectiveDateExt = moment(_.get(wizardSubmission.value, 'baseData.effectiveDate_Ext')).toDate();
        // ****date would be renewal offer date (34 days) before eff date
        const date = WniDateUtil.getDiffDateByDay(effectiveDateExt, -34);
        const effectiveDate = WniDateUtil.formatDateWithPattern(new Date(date));
        const message = translator(customMessages.convertedInfo, {
            effectiveDate: effectiveDate
        });
        if (jobType === 'Submission' && !_.isEmpty(source)) {
            warnMessageControls = (
                <InlineNotification
                    className="mt-10"
                    isDismissable={false}
                    isEmbeddedNotification={false}
                    message={message}
                    type="info"
                />
            );
        }
        return (
            <>
                {warnMessageControls}
            </>
        );
    };

    return (
        <>
            {submissionQuote}
            {claimHeader()}
            {/* {getConvertedInfo()} */}
        </>
    );
};

BaseWizardPageHeader.propTypes = {
    // Only used in CPBuildingsAndLocationsPage, to be moved
    // isSideHeader: PropTypes.bool,
    displayWizardHeader: PropTypes.bool,
    //
    sideHeaderConfigs: PropTypes.shape({
        titleContainerClass: PropTypes.string,
        categoryContainerClass: PropTypes.string,
        associatedPageClass: PropTypes.string,
        wizardSidePageTitleClass: PropTypes.string,
        titleSeparator: PropTypes.shape({}),
    }),
};

BaseWizardPageHeader.defaultProps = {
    // To be removed
    // isSideHeader: false,
    displayWizardHeader: true,
    sideHeaderConfigs: undefined,
};

export default BaseWizardPageHeader;
