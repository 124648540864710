import _ from 'lodash';


function hasIssuanceInvalidFields(dataVM) {
    if(!dataVM)  {
        return false;
    }
    return false;
}
function renderDisplayName(str) {
    const newStr = !!str
    return newStr ? `${str},` : ''
}

function getPrimaryAddressDisplayName(primaryAddress = {}) {
    const {
        pobox_Ext: pobox,
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode
    } = primaryAddress;
    const addressLine = pobox || addressLine1 || '-';
    return `${addressLine}, ${renderDisplayName(addressLine2)} ${renderDisplayName(city)} \n ${renderDisplayName(state)} ${postalCode || '-'}`
}

function hasInvalidLocation(driversVM) {
    const retval = driversVM.children.some((item) => {
        return !item.aspects.valid || !item.aspects.subtreeValid;
    });
    return retval;
}

function removeEmpty(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        if (val === '' || val.length === 0 || val === null) {
            delete res[key];
        }
    });
    return res;
}

function transferAnswerstoNull(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        if (val === '') {
            res[key] = null
        }
    });
    return res;
}


function getQSMapperForWCLocations() {
    return (metaDataContent) => {

        return metaDataContent;
    };
}

export default {
    hasIssuanceInvalidFields,
    transferAnswerstoNull,
    hasInvalidLocation,
    removeEmpty,
    getPrimaryAddressDisplayName,
    getQSMapperForWCLocations
}