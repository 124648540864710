import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniRenewal'), method, data, additionalHeaders);
}
export default class WniRenewalService {
    static getDocumentsForRenewalGroupByType(params, additionalHeaders) {
        return processSubmission('getDocumentsForRenewalGroupByType', params, additionalHeaders);
    }

    static getPortalAttachmentsForRenewal(params, additionalHeaders) {
        return processSubmission('getPortalAttachmentsForRenewal', params, additionalHeaders);
    }

    static getRenewalsForCurrentUser(params, additionalHeaders) {
        return processSubmission('getRenewalsForCurrentUser', params, additionalHeaders);
    }

    static addRecentlyViewedRenewal(jobNumber, additionalHeaders) {
        return processSubmission('addRecentlyViewedRenewal', [jobNumber], additionalHeaders);
    }
}
