import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { CPBlanketsPage } from 'wni-capability-quoteandbind-cp-react';

function CPBlanketsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('CP7Line', submissionVM);
    return (
        <CPBlanketsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

CPBlanketsChangePage.propTypes = CPBlanketsPage.propTypes;
CPBlanketsChangePage.defaultProps = CPBlanketsPage.defaultProps;

export default CPBlanketsChangePage;