import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/hoaddint'), method, data, additionalHeaders);
}

export default class HOAddIntService {
    // /**
    //  * Invokes HOAdditionalInterestsHandler.methodName()
    //  * is disabled.
    //  * @param {object} data the QuoteDataDTO json obj
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static methodName(data, authHeader = {}) {
    //     return processSubmission('methodName', [data], authHeader);
    // }

    static createOrUpdateAdditionalInterest(jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO, authHeader = {}) {
        return processSubmission('createOrUpdateAdditionalInterest', [jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO], authHeader);
    }

    static getAdditionalInterestType(quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader) {
        return processSubmission('getAdditionalInterestType', [quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust], authHeader);
    }

    static removeAdditionalInterest(jobID, sessionUUID, selection, authHeader) {
        return processSubmission('removeAdditionalInterest', [jobID, sessionUUID, selection], authHeader);
    }
    
    static getLatestLossPayee(jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO, authHeader = {}) {
        return processSubmission('getLatestLossPayee', [jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO], authHeader);
    }
}
