

function getChangePageJumpList(getPageJumpAction) {
    // return [{
    //     name: 'Dummy Page',
    //     action: getPageJumpAction('CommonDummyPage'),
    // }];
    return  [{
        name: 'Policy Details',
        action: getPageJumpAction('RTPolicyDetailsPage'),
    }, {
        name: 'Vehicles',
        action: getPageJumpAction('RTVehiclesPage'),
    }, {
        name: 'Operator',
        action: getPageJumpAction('RTOperatorsPage'),
    }, {
        name: 'Coverages',
        action: getPageJumpAction('RTCoveragesPage'),
    }, {
        name: 'Incidents & Reports',
        action: getPageJumpAction('RTIncidentsAndReportsPage'),
    }];
}

export default {
    getChangePageJumpList,
};
