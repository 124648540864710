import _ from 'lodash';

const vmQualifyingProperties = ['modelValue', 'question', 'answer', 'aspects', 'label', 'value'];

/**
 * Checks if maybenode is view model node.
 * @alias isViewModelNode
 * @memberof module:gw-portals-questionsets-js.QuestionSetsViewModelUtil
 * @param {*} maybeNode
 *
 * @returns {Boolean}
 */
function isVMNode(maybeNode) {
    if (!maybeNode || !_.isObject(maybeNode)) {
        return false;
    }

    return vmQualifyingProperties.every((qp) => {
        return qp in maybeNode;
    });
}

/**
 * @namespace QuestionSetsViewModelUtil
 * @memberof module:gw-portals-questionsets-js
 */
export default {
    isViewModelNode: isVMNode,
};
