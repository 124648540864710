import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wcModifierService'), method, data, additionalHeaders);
}

export default class WCModifierService {
    /**
     * Invokes WCModifierHandler.getModifiers()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getModifiers(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getModifiers', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes WCModifierHandler.updateScheduleRateModifiers()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateScheduleRateModifiers(quoteID, sessionUUID, newModel, state, authHeader = {}) {
        return processSubmission('updateScheduleRateModifiers', [quoteID, sessionUUID, newModel, state], authHeader);
    }

    /**
     * Invokes WCModifierHandler.updateUnScheduleRateModifiers()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateUnScheduleRateModifiers(quoteID, sessionUUID, newModel, state, authHeader = {}) {
        return processSubmission('updateUnScheduleRateModifiers', [quoteID, sessionUUID, newModel, state], authHeader);
    }
}