import React, { Component, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ReactUtil } from 'wni-portals-util-react';
import { BaseClauseComponent } from 'wni-components-platform-react';
import messages from './ClauseComponent.messages';
import styles from './ClauseComponent.module.scss';

/**
 * Clause component hierarchy diagram:
 * ClausesComponentVM -> SingleClauseComponentVM -> ClauseComponent
 * @param {object} props
 * @returns {object}
 */
function ClauseComponent(props) {
    const translator = useTranslator();

    function getCheckboxTooltip(clausePatternCodeParam) {
        const checkboxTooltip = WniClausesUtil.getClauseCheckboxTooltip(messages, clausePatternCodeParam, translator);
        const formattedTooltip = checkboxTooltip && ReactUtil.formatLineBreakChars(checkboxTooltip, {
            containerClassName: 'clauseComponentTooltipContainer',
            paragraphClassName: 'clauseComponentTooltipParagraph',
        });

        return formattedTooltip;
    }
    return (
        <BaseClauseComponent
            {...props}
            getCheckboxTooltip={getCheckboxTooltip}
        />
    );
}

ClauseComponent.propTypes = BaseClauseComponent.propTypes;
ClauseComponent.defaultProps = BaseClauseComponent.defaultProps;

export default ClauseComponent;
