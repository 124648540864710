import React, { useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ConfirmationQuoteDetailsPage.metadata.json5';
import messages from '../../PolicyChangeCommon.messages';
import styles from './ConfirmationQuoteDetailsPage.module.scss';

function ConfirmationQuoteDetailsPage(props) {
    const translator = useTranslator();
    const { wizardData: submissionVM, history } = props;

    const goToPolicy = () => {
        const policyNumber = _.get(submissionVM.value, 'policyChange.policyNumber');
        return history.push({ pathname: `/policies/${policyNumber}/summary` });
    };

    const goToTransaction = () => {
        const jobID = _.get(submissionVM.value, 'policyChange.jobID');
        return history.push({ pathname: `/change/${jobID}/summary` });
    };

    const getBillingLink = useCallback(() => {
        const redirectRoute = {
            pathname: `/policies/${_.get(submissionVM.value, 'policyChange.policyNumber')}/billing`,
            state: 'billingTile'
        };
        return (
            <FormattedMessage
                {...messages.paymentSchedule}
                values={{
                    link: <Link to={redirectRoute}>{translator(messages.policyBillingPage)}</Link>
                }}
            />
        );
    }, [submissionVM, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            goToTransaction: goToTransaction,
            goToPolicy: goToPolicy
        }
    };

    const overrideProps = {
        lobDetails: {
            content: translator(messages.lobPolicy, {
                lob: _.get(submissionVM.value, 'policyChange.baseData.productName'),
                policyNumber: _.get(submissionVM.value, 'policyChange.policyNumber')
            })
        },
        referenceNumber: {
            content: translator(messages.referenceNumber, {
                refNumber: _.get(submissionVM.value, 'policyChange.jobID')
            })
        },
        paymentScheduleAndInvoices: {
            content: getBillingLink()
        },
        transactionButton: {
            content: translator(messages.viewJobDetails, {
                jobType: _.get(submissionVM.value, 'policyChange.baseData.jobType')
            })
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ConfirmationQuoteDetailsPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                transactionSummary: PropTypes.shape({})
            }).isRequired
        }).isRequired,
        push: PropTypes.func.isRequired
    }).isRequired
};

ConfirmationQuoteDetailsPage.propTypes = wizardProps;
export default withRouter(ConfirmationQuoteDetailsPage);
