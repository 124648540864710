import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('hoMultiQuote'), method, data, additionalHeaders);
}

export default class HOMultiQuoteService {
    // /**
    //  * Invokes HOAdditionalInterestsHandler.methodName()
    //  * is disabled.
    //  * @param {object} data the QuoteDataDTO json obj
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static methodName(data, authHeader = {}) {
    //     return processSubmission('methodName', [data], authHeader);
    // }

    /**
     * Invokes HOMultiQuoteHandler.processQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('processQuote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes HOMultiQuoteHandler.finishQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static finishQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('finishQuote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes HOMultiQuoteHandler.createDuplicateQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDuplicateQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDuplicateQuote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes HOMultiQuoteHandler.createHO3Quote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createHO3Quote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createHO3Quote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes HOMultiQuoteHandler.createHO5Quote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createHO5Quote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createHO5Quote', [quoteID, sessionUUID], authHeader);
    }


    /**
     * Invokes HOMultiQuoteHandler.createSelectQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createSelectQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createSelectQuote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes HOMultiQuoteHandler.withdrawBranch()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} periodPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static withdrawBranch(quoteID, sessionUUID, periodPublicID, authHeader = {}) {
        return processSubmission('withdrawBranch', [quoteID, sessionUUID, periodPublicID], authHeader);
    }

    /**
     * Invokes HOMultiQuoteHandler.updateBranchName()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} branchName
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBranchName(quoteID, sessionUUID, branchName, authHeader = {}) {
        return processSubmission('updateBranchName', [quoteID, sessionUUID, branchName], authHeader);
    }


    /**
     * Invokes HOMultiQuoteHandler.callVirtualInspectionReport()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {JobDTO}
     */
    static callVirtualInspectionReport(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('callVirtualInspectionReport', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes HOMultiQuoteHandler.callVirtualInspectionReportAndCreateNote()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} note
     * @param {Object} authHeader 
     * @returns {JobDTO}
     */
    static callVirtualInspectionReportAndCreateNote(jobID, sessionUUID, note, authHeader = {}) {
        return processSubmission('callVirtualInspectionReportAndCreateNote', [jobID, sessionUUID, note], authHeader);
    }

    // ======================================

    /**
     * Invokes HOMultiQuoteHandler.retrieveQuotePeriodData()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static retrieveQuotePeriodData(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('retrieveQuotePeriodData', [quoteID, sessionUUID], authHeader);
    }
}
