
import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { WniBillingActivityService } from 'wni-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { Link } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { DocumentsUtil, WniDateUtil } from 'wni-portals-util-js';

import metadata from './BillingActivity.metadata.json5';
import styles from './BillingActivity.module.scss';
import messages from './BillingActivity.messages';

const pageConfig = {
    page: 0,
    pageSize: 5
};

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function BillingActivity(props) {
    const {
        history,
        fromAccountLanding: {
            accountDetailsData: {
                accountNumber,
                accountHolder: {
                    contactType_Ext: accountType,
                } = {},
                producerCodes
            }
        }
    } = props;

    const translator = useTranslator();
    const modalApi = useWniModal();
    const {
        authHeader,
        authUserData: {
            isExternalUser_Ext: isExternalUser
        } = {}
    } = useAuthentication();
    const {
        interactionModel,
        loadingMask: { setLoadingMask }
    } = useDependencies(['interactionModel', 'loadingMask']);

    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);
    const [balanceSummaryData, updateBalanceSummaryData] = useState([]);
    const [invoiceStream, updateInvoiceStream] =useState([]);
    const [selectedInvoiceStream, updateSelectedInvoiceStream] = useState();

    const getInvoiceStream = async () => {
        let invoiceStreamRes = [];
        if (accountNumber) {
            invoiceStreamRes = await WniBillingActivityService.getInvoiceStream(accountNumber, authHeader);
        }

        const options = _.map(invoiceStreamRes, (item) => {
            return {
                code: item.invoiceStreamNumber,
                name: `Bill Account(${item.displayName})`
            }
        });
        updateInvoiceStream(options);
        if (!_.isEmpty(options)) {
            updateSelectedInvoiceStream(options[0].code);
        }
        return invoiceStreamRes;
    }

    const getBalanceSummary = async (invoiceStreamNumber) => {
        setLoadingMask(true);
        const balanceSummary = await WniBillingActivityService.getBalanceSummary(accountNumber, invoiceStreamNumber, authHeader);
        updateBalanceSummaryData(balanceSummary);
        // reset table search config
        updateTablePageConfig(pageConfig);
        setLoadingMask(false);
        return balanceSummary;
    }

    useEffect(() => {
        getInvoiceStream();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(selectedInvoiceStream)) {
            getBalanceSummary(selectedInvoiceStream);
        }
    }, [selectedInvoiceStream]);

    const handleInvoiceStreamFilter = (value) => {
        updateSelectedInvoiceStream(value);
    };

    const getCurrency = (item, index, property) => {
        return (
            <CurrencyField
                id={`currency_${index}`}
                value={item[property.id]}
                readOnly
                hideLabel
                showOptional={false}
                className={styles.currencyColumn}
            />
        );
    };

    const getPolicySummaryLink = (policyNumber) => {
        return interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber);
    };

    const showWarningMsg = () => {
        modalApi.showAlert({
            title: 'Warning',
            status: 'warning',
            icon: 'gw-error-outline',
            message: messages.warningMsgNoRights,
        }).catch(() => {
            _.noop();
        });
    };

    const getPolicyAccess = (agencyOfServiceCode) => {
        let result = false;
        if (!isExternalUser) {
            result = true;
        } else {
            _.forEach(producerCodes, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                    return false;
                }
                return true;
            });
        }
        return result;
    };

    const getLink = (item, index, property) => {
        const agencyOfServiceCode = item.producerCodeOfService;
        const access = getPolicyAccess(agencyOfServiceCode);
        const transactionNumber = _.get(item, 'transactionNumber');
        let linkFieldProps = {}

        if (!transactionNumber || !accountNumber) {
            return '-';
        }
        if (!access) {
            linkFieldProps= {
                onClick: showWarningMsg
            }
        } else {
            linkFieldProps = getPolicySummaryLink(transactionNumber);
        }
        return (
            <Link
                id={`policy${item.transactionNumber}`}
                {...linkFieldProps}
            >
                {item[property.id]}
            </Link>
        );
    };
    
    const showChangeSummary = (item) => {
        modalApi.showAlert({
            title: 'Change Summary',
            status: 'success',
            icon: 'gw-info-outline',
            message: item.changeSummary,
        }).catch(() => {
            _.noop();
        });
    };

    const getDescriptionCell = (item, index, property) => {
        if (!_.isEmpty(item.changeSummary)) {
            const changeSummaryProps= {
                onClick: () => showChangeSummary(item)
            }
            return (
                <Link
                    id={`policy${item.transactionNumber}`}
                    {...changeSummaryProps}
                >
                    {item[property.id]}
                </Link>
            );
        }
        if (!_.isEmpty(item.documentId)) {
            const documentProps = {
                onClick: async () => {
                    await DocumentsUtil.previewDocument(
                        item.documentId, authHeader, history, 'preview', 'wniBCDocumentRetrieval'
                    )
                }
            }
            return (
                <Link
                    id={`policy${item.transactionNumber}`}
                    {...documentProps}
                >
                    {item[property.id]}
                </Link>
            );
        }
        return item[property.id];
    }

    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };

    const getFromattedDate = (item, index, property) => {
        return WniDateUtil.formatDateWithPattern(item[property.path], "MM/DD/YYYY");
    };

    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        invoiceStreamFilter: {
            availableValues: invoiceStream,
            value: selectedInvoiceStream,
            onValueChange: handleInvoiceStreamFilter
        },
        balanceSummaryDataTable: {
            data: balanceSummaryData,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [5, 10, 15, 20]
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCurrency,
            getLink,
            getDescriptionCell,
            getFromattedDate
        },
        resolveComponentMap: {},
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            // VM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            // model={VM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
        />
    );
}

BillingActivity.propTypes = {

};

BillingActivity.defaultProps = {

};

export default BillingActivity;
