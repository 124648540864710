import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalInsuredsTitle: {
        "id": "quoteandbind.gl.wizard.step.generalLiability page.Additional Insureds",
        "defaultMessage": "Additional Insureds"
    },
    addAdditionalInsuredsActionText: {
        "id": "quoteandbind.gl.wizard.step.generalLiability page.Additional Insureds.Add Additional Insureds",
        "defaultMessage": "Add Additional Insureds"
    },
});
