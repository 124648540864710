import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { VehicleCoveragesComponent } from 'wni-capability-gateway-react';
import {
    WniClausesUtil,
    VehicleUtil,
} from 'wni-portals-util-js';

import metadata from './VehicleCoverageModal.metadata.json5';
import messages from './VehicleCoverageModal.messages';

import { Button } from '@jutro/legacy/components';

function VehicleCoverageModal(props) {
    const {
        isReadOnly,
        title,
        size,
        isOpen,
        onResolve,
        submissionVM,
        vehiclePublicID,
        openCopyCoveragesModal,
        updateSubmissionVMOnClauseUpdate,
        loadingVehicleCoverages,
        lobName,
        selectedVersionOfferingsIndex,
    } = props;

    const {
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles = []
                }
            }
        }
    } = submissionVM.value;

    const {
        onValidate,
        isComponentValid,
        disregardFieldValidation,
    } = useValidation('VehicleCoveragesModal');

    const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children', []);
    // const selectedVehicleVM = useRef(vehicleVMs
    //     .find((vehicleVM) => vehicleVM.value.publicID === vehiclePublicID));
    const selectedVehicleVM = vehicleVMs
        .find((vehicleVM) => vehicleVM.value.publicID === vehiclePublicID);
    const coverageVMs = WniClausesUtil.getVehicleCoverageVMs(submissionVM);
    const currentVehicleCoverages = VehicleUtil
        .getCurrentVehicleCoverages(coverageVMs, selectedVehicleVM);
    const selectedVehicleCoverageVMIndex = coverageVMs
        .findIndex((coverageVM) => _.get(coverageVM, 'publicID.value') === vehiclePublicID);
    const pathToVehicleCoverageVM = `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages.vehicleCoverages.children[${selectedVehicleCoverageVMIndex}]`;
    const [showErrors, setShowErrors] = useState(false);

    const translator = useTranslator();

    useEffect(() => {
    //     selectedVehicleVM.current = vehicleVMs
    //         .find((vehicleVM) => vehicleVM.value.publicID === vehiclePublicID);
        setShowErrors(false);
    }, [vehiclePublicID, vehicleVMs]);

    const onClauseUpdate = useCallback(
        async (postUpdate, newSubmissionVM) => {
            await updateSubmissionVMOnClauseUpdate(
                postUpdate,
                newSubmissionVM,
                disregardFieldValidation
            );
        },
        [
            updateSubmissionVMOnClauseUpdate,
            disregardFieldValidation,
        ]
    );

    const handleCopyCoverages = () => {
        openCopyCoveragesModal(vehiclePublicID);
    };

    const handleSaveAndClose = () => {
        if (!isComponentValid && !isReadOnly) {
            setShowErrors(true);
        } else {
            onResolve();
        }
    };

    const multipleVehicle = vehicles.length > 1;

    const overrideProps = {
        VehicleCoverageModalCopyCoverageButton: {
            visible: !isReadOnly && multipleVehicle,
            disabled: loadingVehicleCoverages,
        },
        vehicleCoveragesComponent: {
            visible: true,
            submissionVM: submissionVM,
            vehicleVM: selectedVehicleVM,
            pathToVehicleCoverageVM: pathToVehicleCoverageVM,
            onValidate,
            onClauseUpdate,
            coverages: currentVehicleCoverages,
            readOnly: isReadOnly,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onCopyCoverageButtonClick: handleCopyCoverages,
        },
        resolveComponentMap: {
            vehiclecoveragescomponent: VehicleCoveragesComponent,
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            className={size}
        >
            <ModalHeader
                title={title || 'title'}
                onClose={!loadingVehicleCoverages && handleSaveAndClose}
            />
            <ModalBody
                id="vehicleCoverageModal"
            >
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    // model={selectedVehicleVM.current}
                    model={selectedVehicleVM}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleSaveAndClose} disabled={loadingVehicleCoverages}>
                    {translator(messages.saveAndClose)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

VehicleCoverageModal.propTypes = {
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({
                lobData: PropTypes.shape({
                    personalAuto: PropTypes.shape({
                        coverables: PropTypes.shape({
                            vehicles: PropTypes.arrayOf()
                        })
                    }),
                })
            })
        }
    ).isRequired,
    vehiclePublicID: PropTypes.string.isRequired,
    openCopyCoveragesModal: PropTypes.func.isRequired,
    updateSubmissionVMOnClauseUpdate: PropTypes.func.isRequired,
    loadingVehicleCoverages: PropTypes.bool.isRequired,
    lobName: PropTypes.string.isRequired,
    selectedVersionOfferingsIndex: PropTypes.number.isRequired,
};

VehicleCoverageModal.defaultProps = {
    isReadOnly: false,
    title: null,
    size: 'md'
};

export default VehicleCoverageModal;
