
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALCoveragesPage } from 'wni-capability-quoteandbind-wal-react';

function WALCoveragesChangePage(props) {

    return (
        <WALCoveragesPage {...props} />
    );

}

WALCoveragesChangePage.propTypes = WALCoveragesPage.propTypes;
WALCoveragesChangePage.defaultProps = WALCoveragesPage.defaultProps;

export default WALCoveragesChangePage;