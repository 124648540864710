

import React from 'react';
import { R1Config } from 'wni-portals-config-js';
import  WniTableRowUtil from '../WniTableRowUtil';

const { pageConfig: defaultPageConfig } = R1Config.WniTableConfig;


/**
 * Generate random numbers
 * @returns {string}
 */
function getUuid() {
    // eslint-disable-next-line no-restricted-globals
    return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
}

export default {
    ...WniTableRowUtil,
    defaultPageConfig,
    getUuid
};
