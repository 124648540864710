import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ErrorsAndWarningsUtil } from 'wni-portals-util-js';

import ValidationIssuesComponent from '../ValidationIssuesComponent/ValidationIssuesComponent';
import styles from './ErrorsAndWarningsDisplayComponent.module.scss';
import metadata from './ErrorsAndWarningsDisplayComponent.metadata.json5';


/*
 * Show content from a JobValidationUnderwritingIssuesDTO instance
 */
function ErrorsAndWarningsDisplayComponent(props) {
    const {
        errorsAndWarnings
    } = props;

    const translator = useTranslator();

    const issues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings);


    const overrideProps = {
        '@field': {
        },
        validationIssuesSection: {
            validationIssues: issues
        },
    };

    const readValue = (id, path) => {

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            // model={{}}
            overrideProps={overrideProps}
            // callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // resolveValue={readValue}
        />
    );
}


ErrorsAndWarningsDisplayComponent.propTypes = {
    errorsAndWarnings: PropTypes.shape({

    }),
};

ErrorsAndWarningsDisplayComponent.defaultProps = {
    // Instance of JobValidationUnderwritingIssuesDTO
    errorsAndWarnings: undefined
};

export default ErrorsAndWarningsDisplayComponent;
