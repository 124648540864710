import { defineMessages } from 'react-intl';

export default defineMessages({
    yourHome: {
        id: 'quoteandbind.ho.wizard.step.Your Home',
        defaultMessage: 'Home'
    },
    construction: {
        id: 'quoteandbind.ho.wizard.step.Construction',
        defaultMessage: 'Construction'
    },
    discount: {
        id: 'quoteandbind.ho.wizard.step.Discount',
        defaultMessage: 'Discount'
    },
    additionalOptional: {
        id: 'quoteandbind.ho.views.rating.Additional (optional)',
        defaultMessage: 'Additional (optional)'
    },
    anErrorOccurred: {
        id: 'quoteandbind.ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    safetyNet: {
        id: 'quoteandbind.ho.views.rating.Trampoline has a safety net',
        defaultMessage: 'Does the trampoline have a safety net?'
    },
});
