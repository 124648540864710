import React, {
    useContext, useCallback, useEffect, useState, useMemo, useRef
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { WniPAQuoteService, WniCoverageService } from 'wni-capability-quoteandbind';
import { AllDriversCoveragesComponent, LineCoveragesInputComponent } from 'wni-capability-gateway-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
    OOSUtil,
    CoveragesUtil,
    WindowUtil,
    VehicleUtil,
    IssuanceValidationUtil,
    DriverCoveragesUtil,
} from 'wni-portals-util-js';
import { R1Config } from 'wni-portals-config-js';
import { DomRenderUtil } from 'wni-portals-util-react';
import PAVehicleCoveragesSection from './components/PAVehicleCoveragesSection/PAVehicleCoveragesSection';

import metadata from './PACoveragesPage.metadata.json5';

const { PACoverageConfig } = R1Config;

function CoveragesPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        policyChangeService = {},
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        initialSteps,
        currentStep,
        changeNextSteps,
        isSkipping,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const {
        LoadSaveService,
        loadingMask: { setLoadingMask },
    } = useDependencies(['LoadSaveService', 'loadingMask']);
    const [isPageInitialized, setPageInitialized] = useState(false);
    // const [driverCoverages, updateDriverCoverages] = useState();
    const [vehicleValidationIssuesOnchange, updateVehicleValidationIssues] = useState([]);
    const [ifUpdateaccordionStates, updateIfUpdateaccordionStates] = useState(true);
    const [accrodionStates, updateAccrodionStates] = useState();
    const [showDriversLoader, updateShowDriversLoader] = useState(false);
    const [coveragesErrorsAndWarningsOnChange, updateCoveragesErrorsAndWarnings] = useState({
        errors: [],
        warnings: []
    });
    const [invalidVehicleCoverageFieldIssues, setInvalidVehicleCoverageFieldIssues] = useState([]);
    const [showLoader, updateShowLoader] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [loadingClause, updateLoadingClause] = useState();
    const [lineCoverageLoading, setLineCoverageLoading] = useState(false);

    const quoteFlow = _.get(submissionVM, 'value.baseData.quoteFlow_Ext');

    const isLoading = useMemo(() => {
        return showLoader || loadingClause || lineCoverageLoading;
    }, [showLoader, loadingClause, lineCoverageLoading]);

    const validationIssuesOnChange = useMemo(() => {
        return ErrorsAndWarningsUtil
            .convertPcDisplayMessageToIssues(coveragesErrorsAndWarningsOnChange)
            .concat(vehicleValidationIssuesOnchange)
            .concat(showErrors ? invalidVehicleCoverageFieldIssues : []);
    }, [
        coveragesErrorsAndWarningsOnChange,
        invalidVehicleCoverageFieldIssues,
        showErrors,
        vehicleValidationIssuesOnchange
    ]);

    const { authHeader } = useAuthentication();
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        invalidFields,
        registerComponentValidation,
    } = useValidation('CoveragesPage');
    const {
        quoteID,
        jobID,
        sessionUUID,
        baseData: {
            jobType,
            periodStatus,
            effectiveDate,
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            personalAuto: {
                offerings
            }
        },
        oossliceDates_Ext: oossliceDates,
    } = submissionVM.value;

    /**
     * Create a reference of submission which always same as submissionVM.
     * UseEffect always use initial data of props and state,
     * use this ref to get latest submissionVM
     */
    const submissionVMRef = useRef(submissionVM);

    const selectedVersionOfferingsIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const selectedVersion = useMemo(() => offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID),
    [offerings, selectedVersionPublicID]);
    const {
        coverages
    } = selectedVersion;

    const {
        vehicleCoverages
    } = coverages;

    const allValidationIssues = useMemo(() => {
        const OOSValidationIssues = OOSUtil.getOOSSliceDatesWarning(
            OOSUtil.getOOSSliceDatesString(
                oossliceDates,
                effectiveDate
            ),
            translator
        );
        const newAllValidationIssues = []
            .concat(showErrors ? validationIssues : [])
            .concat(validationIssuesOnChange)
            .concat(OOSValidationIssues);
        return newAllValidationIssues;
    }, [
        effectiveDate,
        oossliceDates,
        showErrors,
        translator,
        validationIssues,
        validationIssuesOnChange
    ]);

    const updateCoveragesFunction = _.get(policyChangeService, 'updateCoveragesFunction', WniPAQuoteService.updateCoverages);
    const copyVehicleCoverages = _.get(policyChangeService, 'copyVehicleCoverages', WniPAQuoteService.copyVehicleCoverages);

    const updateDriverCoverages = useCallback(async (selectedCov) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const lobName = 'personalAuto';
        const clausesToUpdate = CoveragesUtil
            .generateUpdatedCoveragesDTO({ submissionVM: newSubmissionVM, lobName, selectedVersionOfferingsIndex });
        const hasClauseMustUpdateImmediately = CoveragesUtil
            .hasCoverageMustUpdateImmediately(clausesToUpdate);

        if (hasClauseMustUpdateImmediately) {
            updateLoadingClause(_.get(selectedCov, 'publicID'));
            const { updatedCoverages, errorsAndWarning } = await CoveragesUtil
                .updateCoveragesToServer({
                    submissionVM,
                    updateCoveragesDTOToServerMethod: updateCoveragesFunction,
                    clausesToUpdate,
                    authHeader,
                    lobName,
                    selectedVersionOfferingsIndex,
                });
            updatedCoverages.forEach((updateCoverage) => {
                _.set(newSubmissionVM, updateCoverage.path, updateCoverage.updatedClauses);
            });

            if (updateCoveragesErrorsAndWarnings) {
                updateCoveragesErrorsAndWarnings(errorsAndWarning);
            }
            CoveragesUtil.setAdditionalFieldsAfterCoverageUpdate(newSubmissionVM);
            const fieldsToRemoveFromValidation = CoveragesUtil
                .getRemovedClausesIDByUpdatedCoverages(
                    submissionVM,
                    newSubmissionVM,
                    `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages`
                );
            if (!_.isEmpty(fieldsToRemoveFromValidation)) {
                disregardFieldValidation(fieldsToRemoveFromValidation);
            }
            updateLoadingClause(false);
            updateWizardSnapshot(newSubmissionVM);
        }
    }, [selectedVersionOfferingsIndex, submissionVM]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );


    const getErrorsAndWarnings = useCallback(async () => {
        let res;
        if (_.isFunction(policyChangeService.getErrorsAndWarnings)) {
            res = await policyChangeService.getErrorsAndWarnings();
        } else {
            res = await WniCoverageService.getErrorsAndWarnings(quoteID, authHeader);
        }
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(res), 'reason');
        updateValidationIssues(newValidationIssues);
        if(!_.isEmpty(newValidationIssues)){
            updateShowErrors(true);
        }
    }, [authHeader, quoteID, policyChangeService]);

    const initErrorsAndWarnings = useCallback(() => {
        if (quoteFlow !== 'draft') {
            getErrorsAndWarnings();
        }
    }, [getErrorsAndWarnings, quoteFlow]);

    const isVehicleCoveragesValid = useCallback(() => {
        const hasInvalidVehicleCoverage = _.find(vehicleCoverages, (vehicle) => {
            return CoveragesUtil.hasInvalidCoverage(vehicle.coverages);
        });

        return !hasInvalidVehicleCoverage;
    }, [vehicleCoverages]);

    const isDriverCoveragesValid = useCallback(() => {

        const driverCoverages = DriverCoveragesUtil.generateDriverCoverages(submissionVM, coverages);
        const hasInvalidDriverCoverage = _.find(driverCoverages, (driver) => {
            return CoveragesUtil.hasInvalidCoverage(driver.coverages);
        });

        return !hasInvalidDriverCoverage;
    }, [coverages, submissionVM]);

    const isAllVehicleAndDriverCoveragesValid = useCallback(() => {
        return isVehicleCoveragesValid() && isDriverCoveragesValid()
    }, [isDriverCoveragesValid, isVehicleCoveragesValid])

    // Check is all vehicle and driver coverages valid
    useEffect(() => {
        registerComponentValidation(isAllVehicleAndDriverCoveragesValid)
    }, [registerComponentValidation, isAllVehicleAndDriverCoveragesValid]);

    // Keep value of submissionVMRef same as submissionVM
    useEffect(() => {
        submissionVMRef.current = submissionVM;
    }, [submissionVM]);

    useEffect(() => {
        setPageInitialized(true);
        // updateAccrodionStates(CoveragesUtil
        //     .defaultAccordionStates(otherCoverages, driverCoverages, updateIfUpdateaccordionStates, ifUpdateaccordionStates));

        return async () => {
            // POI-18302 save coverages not synced to PC before leave page
            const lobName = 'personalAuto';
            const [updatedSubmissionVM, coverageUpdated] = await CoveragesUtil
                .forceUpdateCoveragesWhenHasCoverageNotSync({
                    // Since submissionVM in useEffect is always same as initial value,
                    // use ref to get latest submissionVM
                    submissionVM: submissionVMRef.current,
                    lobName,
                    selectedVersionOfferingsIndex,
                    updateCoveragesFunction,
                    authHeader,
                    updateCoveragesErrorsAndWarnings
                });
            if (coverageUpdated) {
                updateWizardSnapshot(updatedSubmissionVM);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isSkipping) {
            initErrorsAndWarnings();
        }
    }, [isSkipping]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            validationIssues: IssuanceValidationUtil.getIssuesMap(wizardErrorsAndWarnings
                .concat(allValidationIssues)),
            visible: wizardErrorsAndWarnings.length > 0
                || allValidationIssues.length > 0,
            scrollToIssues: true,
            issueRenderFn: DomRenderUtil.issueRenderFn
        },
        coveragesAccordion: {
            // accordionStates: accrodionStates
            accordionStates: ['lineCoverages', 'vehicleCoverages', 'driverCoverages']
        },
        lineCoveragesInputSection: {
            submissionVM,
            updateSubmissionVM: updateWizardSnapshot,
            onValidate,
            disregardFieldValidation,
            LoadSaveService: jobType === 'Submission' ? WniPAQuoteService : LoadSaveService,
            authHeader,
            viewModelService,
            showErrors,
            updateCoveragesErrorsAndWarnings,
            selectedVersionOfferingsIndex: selectedVersionOfferingsIndex,
            setLineCoverageLoading,
            lobName: 'personalAuto',
            lineCoverageFilter: PACoverageConfig.isNotDriverCoverage,
        },
        vehicleCoveragesSection: {
            submissionVM,
            updateWizardSnapshot,
            updateVehicleValidationIssues,
            disregardFieldValidation,
            updateCoveragesFunction,
            copyVehicleCoverages,
            setInvalidVehicleCoverageFieldIssues,
            selectedVersionOfferingsIndex,
            updateCoveragesErrorsAndWarnings,
        },
        driverCoveragesSection: {
            onValidate,
            submissionVM,
            updateWizardSnapshot,
            updateCoverages: updateDriverCoverages,
            loadingClause,
            updateLoadingClause,
            showLoader: showDriversLoader,
            showErrors,
            updateCoveragesErrorsAndWarnings,
            selectedVersionOfferingsIndex,
            lobName: 'personalAuto',
        },
        // ...getAccordionStatesOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const lobName = 'personalAuto';

        const [updatedSubmissionVM] = await CoveragesUtil
            .forceUpdateCoveragesWhenHasCoverageNotSync({
                submissionVM,
                lobName,
                selectedVersionOfferingsIndex,
                updateCoveragesFunction,
                authHeader,
                updateCoveragesErrorsAndWarnings
            });

        let res = {};
        if (_.isFunction(policyChangeService.getErrorsAndWarnings)) {
            res = await policyChangeService.getErrorsAndWarnings();
        } else {
            res = await WniCoverageService.getErrorsAndWarnings(quoteID, authHeader);
        }
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(res), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewValidationIssue = ErrorsAndWarningsUtil
            .hasNewValidationIssue(allValidationIssues, newValidationIssues);
        updateValidationIssues(newValidationIssues);
        if (hasErrorIssues || hasNewValidationIssue) {
            setLoadingMask(false);
            updateShowErrors(true);
            return false;
        }
        // Currently only sync coverages for Submission. But this is likely to be applied to
        // PolicyChange in the future as well
        const shouldSyncCoverages = !_.isFunction(policyChangeService.getErrorsAndWarnings);
        if (periodStatus === 'Draft' && shouldSyncCoverages) {
            await WniCoverageService.syncCoverages(quoteID, sessionUUID, authHeader);
        }
        setLoadingMask(false);
        VehicleUtil.checkNextSteps(changeNextSteps, currentStep, initialSteps, submissionVM);
        return updatedSubmissionVM;
    }, [
        setLoadingMask,
        policyChangeService,
        allValidationIssues,
        submissionVM,
        quoteID,
        authHeader,
        periodStatus,
        sessionUUID,
        updateCoveragesFunction,
        selectedVersionOfferingsIndex
    ]);


    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveComponentMap: {
            ValidationIssuesComponent: ValidationIssuesComponent,
            linecoveragesinputcomponent: LineCoveragesInputComponent,
            driverCoverages: AllDriversCoveragesComponent,
            PAVehicleCoveragesSection: PAVehicleCoveragesSection
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            alwaysCallOnNext
            disableNext={isLoading}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        resolveValue={readValue}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardSnapshot}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

CoveragesPage.propTypes = wizardProps;
export default CoveragesPage;
