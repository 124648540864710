import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLCPWizard from './FNOLCPWizard';
import FNOLCPLossTypePage from './pages/LossType/FNOLCPLossTypePage';
import FNOLCPBusinessLocationPage from './pages/BusinessLocation/FNOLCPBusinessLocationPage';
import FNOLCPIncidentDetailsPage from './pages/IncidentDetails/FNOLCPIncidentDetailsPage';
import {
    createNewPersonWithoutPublicID, 
    createNewCompanyWithoutPublicID
} from './pages/IncidentDetails/utils/NewContactUtil';
import CPIncidentsUtil from './pages/IncidentDetails/utils/CPIncidentsUtil';

setComponentMapOverrides(
    {
        FNOLCPWizard: { component: 'FNOLCPWizard' },
        FNOLCPLossTypePage: { component: 'FNOLCPLossTypePage' },
        FNOLCPBusinessLocationPage: { component: 'FNOLCPBusinessLocationPage' },
        FNOLCPIncidentDetailsPage: { component: 'FNOLCPIncidentDetailsPage' }
    },
    {
        FNOLCPWizard,
        FNOLCPLossTypePage,
        FNOLCPBusinessLocationPage,
        FNOLCPIncidentDetailsPage
    }
);

export {
    FNOLCPWizard as default,
    FNOLCPLossTypePage,
    FNOLCPBusinessLocationPage,
    FNOLCPIncidentDetailsPage,
    CPIncidentsUtil,
    createNewPersonWithoutPublicID, 
    createNewCompanyWithoutPublicID
};
