import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalInterestButtonTitle: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.Additional Interests",
        defaultMessage: "Additional Interests"
    },
    removeSelected: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.Delete Selected",
        defaultMessage: "Delete Selected"
    },
    addAdditionalInterest: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.Add",
        defaultMessage: "Add"
    },
    noDataText: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.no data text",
        defaultMessage: "There is no additional interest now. Please add one."
    },
    viewAndEditLabel: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.View/Edit",
        defaultMessage: "View/Edit"
    },
    viewLabel: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.View",
        defaultMessage: "View"
    },
    removeAdditionalInterestTitle: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.Remove Additional Interest",
        defaultMessage: "Remove Additional Interest?"
    },
    removeAdditionalInterestDescription: {
        id: "wni.quoteandbind.mortgageeAdditionalInterestsComponent.removeAdditionalInterestDescription",
        defaultMessage: "Are you sure you want to remove this additional interest from the list?"
    },
    deletionModalOk: {
        id: 'wni.quoteandbind.mortgageeAdditionalInterestsComponent.modal.Ok',
        defaultMessage: 'Ok',
    },
    deletionModalCancel: {
        id: 'wni.quoteandbind.mortgageeAdditionalInterestsComponent.modal.Cancel',
        defaultMessage: 'cancel',
    },
    saveAndClose: {
        id: 'wni.quoteandbind.mortgageeAdditionalInterestsComponent.save&close',
        defaultMessage: 'Save & Close',
    },
    cancel: {
        id: 'wni.quoteandbind.mortgageeAdditionalInterestsComponent.Cancel',
        defaultMessage: 'Cancel',
    },
    mortgageeSizeLimitationWarning: {
        id: 'wni.quoteandbind.mortgageeAdditionalInterestsComponent.mortgageeSizeLimitationWarning',
        defaultMessage: 'A maximum of 3 mortgagees can be added on a submission',
    }
});
