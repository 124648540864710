import { defineMessages } from 'react-intl';

export default defineMessages({
    totalPremium: {
        id: 'quoteandbind.directives.QuoteDetailsBox.TotalPremium',
        defaultMessage: 'Total Premium'
    },
    taxes: {
        id: 'quoteandbind.directives.QuoteDetailsBox.Taxes',
        defaultMessage: 'Taxes'
    },
    totalCost: {
        id: 'quoteandbind.directives.QuoteDetailsBox.TotalCost',
        defaultMessage: 'TOTAL COST'
    },
    editCoverages: {
        id: 'quoteandbind.directives.QuoteDetailsBox.EditCoveragesButtonLabel',
        defaultMessage: 'Edit Coverages'
    },
    policyStartDate: {
        id: 'quoteandbind.directives.cp.QuoteDetailsBox.Policy Start Date',
        defaultMessage: 'Policy Start Date'
    },
    policyEndDate: {
        id: 'quoteandbind.directives.cp.QuoteDetailsBox.Policy End Date',
        defaultMessage: 'Policy End Date'
    }
});
