import React, {useContext} from 'react'
import _ from 'lodash'
import {
    TooltipIcon
} from '@jutro/components';
import moment from 'moment';
import { IntlContext } from '@jutro/locale';
import { GLTooltipUtil as tooltips } from 'wni-portals-tooltip';
import {
    valueTypeNameMap,
    schedulePropertyInfoTypeMap,
    ValuePath,
} from '../../util/GLScheduleItemsUtil'

const GLScheduleItemReadonlyCell = (props) => {
    const {
        propertyInfo,
        scheduleItem
    } = props;
    const {
        id,
        valueTypeName_Ext: valueTypeName,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo;

    const {
        itemData: {
            [id]: itemDataForProperty
        }
    } = scheduleItem

    const intl = useContext(IntlContext);

    const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : schedulePropertyInfoTypeMap[schedulePropertyInfoType];
    const value = _.get(itemDataForProperty, valuePath);
    if (_.isNil(value)) {
        return '-'
    }

    if (valuePath === 'typeCodeValue') {
        const options = _.get(itemDataForProperty, 'options_Ext', []);
        const selectedOption = options.find((option) => _.get(option, 'code') === value);
        const optionCode = _.get(selectedOption, 'code');
        const optionHelpText = tooltips[optionCode];
        return (
            <div className="labelWithTooltip">
                <span>{_.get(selectedOption, 'name')}</span>
                { optionHelpText && <TooltipIcon
                    id={`option${optionCode}`}
                    text={optionHelpText}
                />}
                
            </div>
        )
    }
    if (valueTypeName === 'MONEY_EXT' && valuePath === 'bigDecimal') {
        return intl.formatNumber(
            value,
            {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol',
                maximumFractionDigits: 0
            }
        );
    }

    if (valuePath === 'integerValue' && _.has(itemDataForProperty, 'currency')) {
        const { currency } = itemDataForProperty;
        return intl.formatNumber(
            value,
            {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol'
            }
        );
    }
    if (valuePath === 'dateValue') {
        return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
    }
    if (valuePath === 'localDate_Ext') {
        return intl.formatDate(moment(value), { year: 'numeric', month: 'short', day: 'numeric' });
    }
    if (valuePath === ValuePath.IntegerValue) {
        return intl.formatNumber(value)
    }
    return value;
}

export default GLScheduleItemReadonlyCell
