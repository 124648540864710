import { defineMessages } from 'react-intl';

export default defineMessages({
    PedestrianQuestion: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.question',
        defaultMessage: "Pedestrian involved in collision?"
    },
    Yes: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.No',
        defaultMessage: "No"
    },
    Number: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.No.',
        defaultMessage: "No."
    },
    PedestrianLastName: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.PedestrianLastName',
        defaultMessage: "Pedestrian Last Name"
    },
    Edit: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.Edit',
        defaultMessage: "Edit"
    },
    Delete: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.Delete',
        defaultMessage: "Delete"
    },
    AddPedestrian: {
        id: 'wni.pe.incident.fnol.pa.views.incidentdetails.pedestrians.AddPedestrian',
        defaultMessage: "Add Pedestrian"
    }
})