// extracted from QuoteClauseTableUIHelper, i.e. QuoteClauseCommonHelper
const PAYMENT_TYPES = {
    monthly: 'monthly',
    annually: 'annually'
};

const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
const BLOCKS_BIND = 'BlocksBind';
const NON_BLOCKING = 'NonBlocking';

export default {
    PAYMENT_TYPES,
    BLOCKS_QUOTES,
    BLOCKS_BIND,
    NON_BLOCKING,
};

