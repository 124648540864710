import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
// import { DocumentUploadService } from '@xengage/gw-portals-document-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayIncidentReport'), method, data, additionalHeaders);
}

export default class WniClueService {
    static getClueData(type, quoteId, additionalHeaders = {}) {
        return processSubmission('getClueData', [type, quoteId], additionalHeaders);
    }

    static updateClue(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('updateClue', [type, quoteId, newModel], additionalHeaders);
    }

    static deleteClue(type, quoteId, publicId, additionalHeaders = {}) {
        return processSubmission('deleteClue', [type, quoteId, publicId], additionalHeaders);
    }

    static saveClue(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('saveClue', [type, quoteId, newModel], additionalHeaders);
    }

    static updateClueReport(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('updateClueReport', [type, quoteId, newModel], additionalHeaders);
    }

    static getPolicyDriverNameList(type, quoteId, additionalHeaders = {}) {
        return processSubmission('getPolicyDriverNameList', [type, quoteId], additionalHeaders);
    }

    static updateOtherThanCollisionById(type, quoteId, publicId, newModel, additionalHeaders = {}) {
        return processSubmission('updateOtherThanCollisionById', [type, quoteId, publicId, newModel], additionalHeaders);
    }
}
