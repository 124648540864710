import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import moment from 'moment';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { WniClueService, WniMVRService, WniPriorCarrierService } from 'wni-capability-gateway';
import { IncidentPopupComponent, ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { IncidentsUtil, WniDateUtil } from 'wni-portals-util-js';
// import { WniSxsQuoteService } from 'wni-capability-quoteandbind';
import metadata from './PAIncidentsAndReportsPage.metadata.json5';
import messages from './PAIncidentsAndReportsPage.messages';
import staticMessage from './PAIncidentsAndReportsPage.static';
import styles from './PAIncidentsAndReportsPage.module.scss';

import { IconButton, CurrencyField } from '@jutro/legacy/components';

function PAIncidentsAndReportsReadOnlyPage(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    // const { LoadSaveService } = useDependencies('LoadSaveService');
    const { onValidate } = useValidation(
        'IncidentsAndReportsPage'
    );
    // carrier
    const [manuallyPriorCarrier, updateManuallyPriorCarrier] = useState([]);
    const [vendorPriorCarrier, updateVendorPriorCarrier] = useState([]);
    // clue
    const [manuallyClue, updateManuallyClue] = useState([]);
    const [vendorClueReports, updateVendorClueReports] = useState([]);
    const [clueCompare, setClueCompare] = useState(false);
    const [vendorClueMsg, updateVendorClueMsg] = useState('');
    // mvr
    const [manuallyMVR, updateManuallyMVR] = useState([]);
    const [vendorMVR, updateVendorMVR] = useState([]);
    const [mvrCompare, setMvrCompare] = useState(false);
    const [vendorMVRMsg, updateVendorMVRMsg] = useState('');
    // policy claims
    const [vendorPolicyClaims, updateVendorPolicyClaims] = useState([]);
    const [vendorClaimsMsg, updateVendorClaimsMsg] = useState('');
    // policy info source
    const [policyInfoSource, updatePolicyInfoSource] = useState('');

    const setCompareFlag = (reports, manually, updateData) => {
        _.forEach(reports, (reportData) => {
            const reportItem = reportData;
            _.forEach(manually, (enterData) => {
                const a = moment(reportItem.incidentDate);
                const b = moment(enterData.incidentDate);
                if ((reportItem.incidentDate && (a.diff(b, 'days') < -30 || a.diff(b, 'days') > 30)) || _.isEmpty((reportItem.incidentDate))) {
                    reportItem.flag = true;
                } else {
                    reportItem.flag = false;
                }
            });
        });
        updateData(reports);
    };

    useEffect(() => {
        const {
            lobData: {
                personalAuto: {
                    clueEnter_Ext: clueEnter,
                    mvrEnter_Ext: mvrEnter,
                    priorCarrierEnter_Ext: priorCarrierEnter,
                    clueReport_Ext: clueReport,
                    mvrReport_Ext: mvrReport,
                    priorCarrierReport_Ext: priorCarrierReport,
                    policyClaimsReport_Ext: policyClaimsReport,
                    // isMvrCompare_Ext: isMvrCompare,
                    // isClueCompare_Ext: isClueCompare
                }
            },
            baseData: {
                policyInfoSource_Ext: policySource
            }
        } = submissionVM.value;
        // To be replaced by:
        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        updateManuallyClue(clueEnter);
        updateVendorClueReports(clueReport);
        updateManuallyMVR(mvrEnter);
        updateVendorMVR(mvrReport);
        updateManuallyPriorCarrier(priorCarrierEnter);
        if (!_.isUndefined(priorCarrierReport)) {
            updateVendorPriorCarrier(priorCarrierReport);
        } else {
            updateVendorPriorCarrier([]);
        }
        updateVendorPolicyClaims(policyClaimsReport);
        setClueCompare(isClueCompare);
        setMvrCompare(isMvrCompare);
        updatePolicyInfoSource(policySource);

        if (isClueCompare) {
            setCompareFlag(clueReport, clueEnter, updateVendorClueReports);
            if (clueReport.length > 1) {
                updateVendorClueMsg(`Prior Losses ${clueReport.length} Records`);
            } else {
                updateVendorClueMsg(`Prior Losses ${clueReport.length} Record`);
            }
        } else {
            updateVendorClueMsg('Prior Losses 0 Record');
        }

        if (isMvrCompare) {
            setCompareFlag(mvrReport, mvrEnter, updateVendorMVR);
            if (mvrReport.length > 1) {
                updateVendorMVRMsg(`Violation ${mvrReport.length} Records`);
            } else {
                updateVendorMVRMsg(`Violation ${mvrReport.length} Record`);
            }
        } else {
            updateVendorMVRMsg('Violation 0 Record');
        }

        if (policyClaimsReport.length > 1) {
            updateVendorClaimsMsg(`Policy Claims ${policyClaimsReport.length} Records`);
        } else {
            updateVendorClaimsMsg(`Policy Claims ${policyClaimsReport.length} Record`);
        }
    }, [authHeader, submissionVM]);

    const showModalFn = async (modalVM, modelProps) => {
        let drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
            .map((driver) => {
                return {
                    code: driver.publicID,
                    name: driver.person.displayName,
                };
            });

        let policyDrivers;
        if (modelProps.title === messages.ViewPriorLossesVendor.defaultMessage || modelProps.title === messages.EditPriorLossesVendor.defaultMessage) {
            policyDrivers = await WniClueService.getPolicyDriverNameList('Submission', submissionVM.quoteID.value, authHeader)
                .then((driverNames) => {
                    const names = [];
                    _.forEach(driverNames, (policyDriverName) => {
                        names.push({
                            code: policyDriverName,
                            name: policyDriverName
                        });
                    });
                    return names;
                });
        }

        if (modelProps.title === messages.AddPriorLossesEntry.defaultMessage || modelProps.title === messages.EditPriorLossesEntry.defaultMessage || modelProps.title === messages.ViewPriorLossesEntry.defaultMessage) {
            drivers = IncidentsUtil.addRegularDrivers(drivers, messages);
        }

        const isRequiredForIssuance = false;

        const componentProps = {
            title: modelProps.title,
            actionType: modelProps.type,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            authHeader: authHeader,
            actionBtnLabel: translator(messages.ModalOk),
            cancelBtnLabel: translator(messages.ModalCancel),
            modalVM,
            modelProps: modelProps,
            drivers: drivers,
            policyDrivers: policyDrivers,
            displayStatus: submissionVM.value.baseData.displayStatus_Ext,
            quotedId: submissionVM.quoteID.value,
            comingType: 'Submission',
            wniClueService: WniClueService,
            isRequiredForIssuance: isRequiredForIssuance
        };

        return modalApi.showModal(<IncidentPopupComponent {...componentProps} />);
    };
    const handleAddEditFn = (type, handleType, data, dtoService) => {
        const { saveService, updateService, updateTableData } = dtoService;
        let submitService = updateService;
        if (type === 'View') {
            return false;
        }
        if (type === 'Add') {
            submitService = saveService;
        }
        if (type === 'Edit') {
            submitService = updateService;
        }
        if (handleType === 'userClue') {
            submitService(submissionVM.quoteID.value, data, authHeader).then(
                (result) => {
                    updateTableData(result);
                    _.set(submissionVM, 'lobData.personalAuto.clueEnter_Ext', result);
                }
            );
        }
        if (handleType === 'userMVR') {
            submitService(
                submissionVM.quoteID.value,
                data.driverId,
                data,
                authHeader
            ).then((result) => {
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.mvrEnter_Ext', result);
            });
        }
        if (handleType === 'vendorClue') {
            submitService(
                submissionVM.quoteID.value,
                data,
                authHeader
            ).then((result) => {
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.clueReport_Ext', result);
            });
        }
        if (handleType === 'vendorMVR') {
            submitService(
                submissionVM.quoteID.value,
                data,
                authHeader
            ).then((result) => {
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.mvrReport_Ext', result);
            });
        }
        if (handleType === 'userPriorCarrier') {
            submitService(submissionVM.quoteID.value, data, authHeader).then((result) => {
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.priorCarrierEnter_Ext', result);
            });
        }
        if (handleType === 'vendorPriorCarrier') {
            submitService(submissionVM.quoteID.value, data, authHeader).then((result) => {
                updateTableData(result);
                _.set(submissionVM, 'lobData.personalAuto.priorCarrierReport_Ext', result);
            });
        }
        return false;
    };
    const handleUserClue = () => {
        return {
            ...staticMessage.userClue,
            dtoService: {
                saveService: WniClueService.saveClue,
                updateService: WniClueService.updateClue,
                deleteService: WniClueService.deleteClue,
                updateTableData: updateManuallyClue,
            },
        };
    };
    const handleVendorClue = () => {
        return {
            ...staticMessage.vendorClue,
            dtoService: {
                updateService: WniClueService.updateClueReport,
                updateTableData: updateVendorClueReports,
            },
        };
    };
    const handleUseMVR = () => {
        return {
            ...staticMessage.userMvr,
            dtoService: {
                saveService: WniMVRService.saveMvr,
                updateService: WniMVRService.updateMvr,
                deleteService: WniMVRService.deleteMvr,
                updateTableData: updateManuallyMVR,
            },
        };
    };
    const handleVendorMVR = () => {
        return {
            ...staticMessage.vendorMvr,
            dtoService: {
                updateService: WniMVRService.updateMVRReport,
                updateTableData: updateVendorMVR,
            },
        };
    };

    const handleUserPriorCarrier = () => {
        return {
            ...staticMessage.userPriorCarrier,
            dtoService: {
                saveService: WniPriorCarrierService.savePriorCarrier,
                updateService: WniPriorCarrierService.updatePriorCarrier,
                deleteService: WniPriorCarrierService.deletePriorCarrier,
                updateTableData: updateManuallyPriorCarrier
            }
        };
    };

    const handleVendorPriorCarrier = () => {
        return {
            ...staticMessage.vendorPriorCarrier,
            dtoService: {
                updateService: WniPriorCarrierService.updateReportPriorCarrier,
                updateTableData: updateVendorPriorCarrier
            }
        };
    };

    const handleRowItem = (e, rowProps, type) => {
        e.preventDefault();
        const { item, handleType } = rowProps;

        let modalProps;
        switch (handleType) {
            case 'userClue':
                modalProps = handleUserClue();
                break;
            case 'vendorClue':
                modalProps = handleVendorClue();
                break;
            case 'userMVR':
                modalProps = handleUseMVR();
                break;
            case 'vendorMVR':
                modalProps = handleVendorMVR();
                break;
            case 'userPriorCarrier':
                modalProps = handleUserPriorCarrier();
                break;
            case 'vendorPriorCarrier':
                modalProps = handleVendorPriorCarrier();
                break;
            default:
                modalProps = {};
                break;
        }

        const {
            labelMap, title, dtoPath, dtoService
        } = modalProps;
        let modalVM = {};
        if (item) {
            modalVM = viewModelService.create(item, 'pc', dtoPath);
        } else {
            modalVM = viewModelService.create({}, 'pc', dtoPath);
        }
        const modelInfo = {
            labelMap: labelMap,
            type: type,
            title: `${type} ${title}`,
        };
        showModalFn(modalVM, modelInfo)
            .then((data) => {
                handleAddEditFn(type, handleType, data, dtoService);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    };

    const onColumnDesCell = useCallback(
        (item, index, property, type) => {
            const { typeKey } = property;
            let flag;
            if (type === 'mvr') {
                flag = mvrCompare;
            }
            const classNames = flag && item.flag ? 'highlight' : '';
            if (item.source === 'chips') {
                return (
                    <div className={classNames}>{`${item.descriptionForChips}`}</div>
                );
            }
            const val = translator({ id: `${typeKey}.${item.description}` });
            return <div className={classNames}>{`${item.description ? val : ''}`}</div>;
        },
        [mvrCompare]
    );

    const onColumnCell = useCallback(
        (item, index, property, type) => {
            const { path, typeKey } = property;
            let flag;
            if (type === 'clue') {
                flag = clueCompare;
            }
            if (type === 'mvr') {
                flag = mvrCompare;
            }
            if (type === 'priorCarrier') {
                flag = false;
            }
            const classNames = flag && item.flag ? 'highlight' : '';
            if (item[path] && item[path].year) {
                return (
                    <div className={classNames}>{WniDateUtil.formatDateWithPattern(item[path])}</div>
                );
            }
            if (item[path] && item[path].currency) {
                return (
                    <CurrencyField
                        id={`currency_${index}`}
                        value={item[path]}
                        readOnly
                        hideLabel
                        showOptional={false}
                    />
                );
            }
            const val = typeKey
                ? translator({ id: `${typeKey}.${item[path]}` })
                : item[path];
            return (
                <div className={classNames}>{`${item[path] ? val : ''}`}</div>
            );
        },
        [clueCompare, mvrCompare]
    );

    /* const openTab = () => {
        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        if (isClueCompare || isMvrCompare) {
            return 'vendorInfo';
        }
        return 'userEnteredInfo';
    }; */

    useEffect(() => {
        WniTableRowUtil.setTableRowClass('error');
    }, [onColumnCell]);

    const handleDeleteItem = (e, rowProps, handleType) => {
        e.preventDefault();
        const { item } = rowProps;
        modalApi.showConfirm({
            title: messages.ModaldeleteMsg,
            message: '',
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.ModalOk,
            cancelButtonText: messages.ModalCancel
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            if (handleType === 'userClue') {
                WniClueService.deleteClue(submissionVM.quoteID.value, item.publicId, authHeader)
                    .then((res) => {
                        updateManuallyClue(res);
                    });
            }
            if (handleType === 'userMVR') {
                WniMVRService.deleteMvr(submissionVM.quoteID.value, item.driverId, item.publicId, authHeader)
                    .then((res) => {
                        updateManuallyMVR(res);
                    });
            }
            if (handleType === 'userPriorCarrier') {
                WniPriorCarrierService
                    .deletePriorCarrier(submissionVM.quoteID.value, item.publicId, authHeader)
                    .then((res) => {
                        updateManuallyPriorCarrier(res);
                    });
            }
            return true;
        }, _.noop);
    };

    const getInfoValidationMsg = () => {
        const msg = [{
            type: 'info',
            reason: messages.CreditAuth1.defaultMessage
        },
        {
            type: 'info',
            reason: messages.CreditAuth2.defaultMessage
        }];
        return msg;
    };

    const getActioncolumnFn = (item, index, handleType) => {
        const rowProps = {
            item: item,
            index: index,
            handleType,
        };
        return (
            <IconButton
                id={`view${handleType}${index}`}
                icon="gw-visibility"
                iconColor="dark"
                size="medium"
                onClick={(e) => handleRowItem(e, rowProps, 'View')}
            />
        );
    };

    const getSubUserEnterStates = () => {
        const userEnterStates = [];
        if (manuallyClue.length > 0) {
            userEnterStates.push('userEnteredPriorLossesCard');
        }
        if (manuallyMVR.length > 0) {
            userEnterStates.push('userEnteredViolationCard');
        }
        if (manuallyPriorCarrier.length > 0) {
            userEnterStates.push('userEnteredPriorCarrierCard');
        }
        return userEnterStates;
    };

    const getSubVendorStates = () => {
        const vendorStates = [];
        if (vendorClueReports.length > 0) {
            vendorStates.push('vendorPriorLossesCard');
        }
        if (vendorMVR.length > 0) {
            vendorStates.push('vendorViolationCard');
        }
        if (vendorPriorCarrier.length > 0) {
            vendorStates.push('PriorCarrierCard');
        }
        return vendorStates;
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        // MVR
        userAddedMVRTable: {
            visible: manuallyMVR.length > 0,
            data: manuallyMVR,
        },
        reportedMVRTable: {
            visible: mvrCompare,
            data: vendorMVR,
        },
        // clue
        priorLossCLUETable: {
            visible: clueCompare,
            data: vendorClueReports,
        },
        userAddedPriorLossTable: {
            visible: manuallyClue.length > 0,
            data: manuallyClue,
        },
        // policy claims
        reportedPolicyClaimsTable: {
            visible: vendorPolicyClaims.length > 0 && policyInfoSource === 'Converted',
            data: vendorPolicyClaims
        },
        PolicyClaimsCard: {
            visible: policyInfoSource === 'Converted',
            header: vendorClaimsMsg
        },
        // carrier
        reportedPriorCarrierTable: {
            visible: vendorPriorCarrier.length > 0,
            data: vendorPriorCarrier,
        },
        userAddedPriorCarrierTable: {
            visible: manuallyPriorCarrier.length > 0,
            data: manuallyPriorCarrier
        },
        /* accordionInfo: {
            defaultOpenedId: openTab()
        }, */
        vendorPriorLossesCard: {
            header: vendorClueMsg
        },
        userEnteredPriorLossesCard: {
            header: manuallyClue.length > 1 ? `Prior Losses ${manuallyClue.length} Records` : `Prior Losses ${manuallyClue.length} Record`
        },
        userEnteredViolationCard: {
            header: manuallyMVR.length > 1 ? `Violation ${manuallyMVR.length} Records` : `Violation ${manuallyMVR.length} Record`
        },
        vendorViolationCard: {
            header: vendorMVRMsg
        },
        PriorCarrierCard: {
            header: vendorPriorCarrier.length > 1 ? `Prior Carrier ${vendorPriorCarrier.length} Records` : `Prior Carrier ${vendorPriorCarrier.length} Record`
        },
        userEnteredPriorCarrierCard: {
            header: manuallyPriorCarrier.length > 1 ? `Prior Carrier ${manuallyPriorCarrier.length} Records` : `Prior Carrier ${manuallyPriorCarrier.length} Record`
        },
        addPriorlossBtn: {
            visible: false
        },
        addUserIncidentBtn: {
            visible: false
        },
        addCarrierBtn: {
            visible: false
        },
        infoValidationMessages: {
            validationIssues: getInfoValidationMsg()
        },
        userEnteredSubInfo: {
            accordionStates: getSubUserEnterStates()
        },
        vendorSubInfo: {
            accordionStates: getSubVendorStates()
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            // clue
            getVendorClueActionFn: (item, index) => getActioncolumnFn(item, index, 'vendorClue', 'readOnly'), // vendor clue: view
            handleClueAddItem: (e) => handleRowItem(e, { handleType: 'userClue' }, 'Add'), // user clue: add
            getUserClueActionFn: (item, index) => getActioncolumnFn(item, index, 'userClue'), // user clue: edit/view
            // mvr
            getVendorMVRActionFn: (item, index) => getActioncolumnFn(item, index, 'vendorMVR', 'readOnly'), // vendor mvr: view
            handleMvrAddItem: (e) => handleRowItem(e, { handleType: 'userMVR' }, 'Add'), // user mvr: add
            getUserMVRActionFn: (item, index) => getActioncolumnFn(item, index, 'userMVR'), // user Mvr: edit/view
            // carrier
            getVendorPriorCarrierFn: (item, index) => getActioncolumnFn(item, index, 'vendorPriorCarrier', 'readOnly'), // vendor carrier: view
            handlePriorCarrierAddItem: (e) => handleRowItem(e, { handleType: 'userPriorCarrier' }, 'Add'), // user carrier: add
            getUserPriorCarrierFn: (item, index) => getActioncolumnFn(item, index, 'userPriorCarrier'), // vender carrier: edit/vuew
            // common
            handleDeleteItem: handleDeleteItem, // user clue/mvr/carrier: delete
            handleRowItem: handleRowItem,
            onColumnCell: onColumnCell,
            onColumnDesCell: (item, index, property) => onColumnDesCell(item, index, property, 'mvr'),
            onColumnClueCell: (item, index, property) => onColumnCell(item, index, property, 'clue'),
            onColumnMvrCell: (item, index, property) => onColumnCell(item, index, property, 'mvr'),
            onColumnPriorCarrierCell: (item, index, property) => onColumnCell(item, index, property, 'priorCarrier'),
            onColumnPolicyClaimsCell: (item, index, property) => onColumnCell(item, index, property, 'policyClaims')
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
        },
        resolveClassNameMap: styles,
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PAIncidentsAndReportsReadOnlyPage.propTypes = wizardProps;
export default PAIncidentsAndReportsReadOnlyPage;
