import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CUDummyPage from './pages/Dummy/CUDummyPage';
import CUDummyReadOnlyPage from './pages/Dummy/CUDummyPage.readonly';

import CUUnderlyingPage from './pages/Underlying/CUUnderlyingPage';
import CUUnderlyingReadOnlyPage from './pages/Underlying/CUUnderlyingPage.readonly';

import CURiskDetailsPage from './pages/RiskDetails/CURiskDetails';
import CURiskDetailsReadonlyPage from './pages/RiskDetails/CURiskDetails.readonly';

import CUQuotePage  from './pages/Quote/CUQuotePage';
import CUQuoteReadOnlyPage from './pages/Quote/CUQuotePage.readonly';

import CURiskAnalysisPage from './pages/RiskAnalysis/CURiskAnalysisPage';
import CURiskAnalysisReadOnlyPage from './pages/RiskAnalysis/CURiskAnalysisPage.readonly';

import CUPolicyDetailsPage from './pages/PolicyDetails/CUPolicyDetailsPage';
import CUPolicyDetailsReadOnlyPage from './pages/PolicyDetails/CUPolicyDetailsPage.readonly';

setComponentMapOverrides(
    {
        CUDummyPage: {component: 'CUDummyPage'},
        CUDummyReadOnlyPage: { component: 'CUDummyReadOnlyPage' },

        CUUnderlyingPage: {component: 'CUUnderlyingPage'},
        CUUnderlyingReadOnlyPage: { component: 'CUUnderlyingReadOnlyPage' },

        CURiskAnalysisPage: {component: 'CURiskAnalysisPage'},
        CURiskAnalysisReadOnlyPage: {component: 'CURiskAnalysisReadOnlyPage'},

        CURiskDetailsPage: {component: 'CURiskDetailsPage'},
        CURiskDetailsReadonlyPage: {component: 'CURiskDetailsReadonlyPage'},

        //
        CUQuotePage: {component: 'CUQuotePage'},
        CUQuoteReadOnlyPage: {component: 'CUQuoteReadOnlyPage'},

        CUPolicyDetailsPage: {component: 'CUPolicyDetailsPage'},
        CUPolicyDetailsReadOnlyPage: {component: 'CUPolicyDetailsReadOnlyPage'},

    },
    {
        CUDummyPage,
        CUDummyReadOnlyPage,

        CUUnderlyingPage,
        CUUnderlyingReadOnlyPage,
        
        CURiskAnalysisPage,
        CURiskAnalysisReadOnlyPage,
        
        CURiskDetailsPage,
        CURiskDetailsReadonlyPage,

        //
        CUQuotePage,
        CUQuoteReadOnlyPage,

        CUPolicyDetailsPage,
        CUPolicyDetailsReadOnlyPage,
    }
);


export {
    CUDummyPage,
    CUDummyReadOnlyPage,

    CUUnderlyingPage,
    CUUnderlyingReadOnlyPage,
    
    CURiskAnalysisPage,
    CURiskAnalysisReadOnlyPage,

    CUPolicyDetailsPage,
    CUPolicyDetailsReadOnlyPage,
    //
    CURiskDetailsPage,
    CURiskDetailsReadonlyPage,
};

// eslint-disable-next-line import/prefer-default-export
export { default as CUSubmissionWizard } from './CUSubmissionWizard';
export { default as CUWizardPage } from './templates/CUWizardPage';
export { default as CUPolicyLineSummaryComponent } from './pages/Quote/components/Summary/CUPolicyLineSummaryComponent';

export { default as CUQuoteIssueRenderHelper } from './pages/Quote/util/CUQuoteIssueRenderHelper';
export { default as CUQuotePageConstants } from './pages/Quote/config/CUQuotePage.config';
