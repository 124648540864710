import { defineMessages } from 'react-intl';

export default defineMessages({
    addVersions: {
        id: 'custom.component.ho.HoAddNewChangeVersionComponent.Add Versions',
        defaultMessage: 'Add Versions',
    }, 
    createNewVersion: {
        id: 'custom.component.ho.HoAddNewChangeVersionComponent.Add New Version',
        defaultMessage: 'Add New Version',
    }, 
    // ===============================================
    chooseAddVersions: {
        id: 'custom.component.ho.HoAddNewChangeVersionComponent.Choose / Add Versions',
        defaultMessage: 'Choose / Add Versions',
    },
    chooseVersions: {
        id: 'custom.component.ho.HoAddNewChangeVersionComponent.Choose Versions',
        defaultMessage: 'Choose Versions',
    },
});
