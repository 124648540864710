import { wizardProps } from '@xengage/gw-portals-wizard-react';
import React from 'react';
import { withRouter } from 'react-router-dom';


function FNOLDPWaterDamagePage(props) {

    return <FNOLDPWaterDamagePage {...props} />;

}

FNOLDPWaterDamagePage.propTypes = wizardProps;
export default withRouter(FNOLDPWaterDamagePage);
