import _ from 'lodash';

const FIELD_BASE_OTHER_MAPS = {
    Aircraft: [
        'acbiEachOccLiabLimit',
        'acBodInjEachOccLiabLimit',
        'acpdLiabLimit',
        'acLiabAggLim',
    ],
    UnmannedAircraft_Ext: ['acLiabAggLim'],
    BOP: [
        'bopGenAggOthThaProWork',
        'bopProComOpeAgg',
        'bopPerAndAdvInjBIPDLia',
        'bopBIPDLiaPerOcc',
        'bopLiaAndMedExp',
    ],
    CommAuto: [
        'adEachAccAutoOnly',
        'adEachAccOtherAuto',
        'adAggLimOtherAuto',
        'caOthGenLiaAggType',
        'caProAndWorYouPerAggType',
        'cabiEachAcc',
        'cabiEachPerson',
        'capdEachAcc',
        'caCombSingLimiType'

    ],
    CommercialFarm: [
        'cfFarmLiability',
        'cfFarmChemicals',
        'cfLimitedFungi',
        'cfPersonalAdvertising'
    ],
    Cyber: [],
    EmpBenLiab: [
        'eblLimitEachEmp',
        'eblAggLim'
    ],
    EmpLiab: [
        'wcbiAccLim',
        'wcbiDisEmpLim',
        'wcBodInjDisPolicyLim'
    ],
    GenLiab: [
        // popco
        'glOccLimit',
        'glAggLimit',
        'glProdCompOpsAggLim',
        'glPersAdvInj',
        // owner and contractors
        'glOwnAndConOccLimit',
        'glOwnAndConAggLimit',
        // product withdrawal
        'glProdWithAggLimit',
        // railroad
        'glRailraodOccLimit',
        'glRailroadAggLimit',

    ],
    Liquor: [
        'liqCommonCauseLimit',
        'liqAggLim'
    ],
    Watercraft: [
        'wcLiabLimit'
    ],
    WorkComp: [
        'wcbiAccLim',
        'wcbiDisEmpLim',
        'wcBodInjDisPolicyLim'
    ],
    MotorCarrierLiability_Ext: [
        'adEachAccAutoOnly',
        'adEachAccOtherAuto',
        'adAggLimOtherAuto',
        'caOthGenLiaAggType',
        'caProAndWorYouPerAggType',
        'cabiEachAcc',
        'cabiEachPerson',
        'capdEachAcc',
        'caCombSingLimiType'
    ],
    ProfessionalLiability_Ext: [
        'glProLiaOccLimit',
        'glProLiaAggLimit'
    ],
    Other_Ext: [],
};

const CA_NUMBER_RULES = [
    'caNumPPV',
    'caNumLightTrucks',
    'caNumMedTrucks',
    'caNumHeavyTrucks',
    'caNumHeavyTruckTractors',
    'caNumExHeavyTrucks',
    'caNumExHeavyTruckTractors',
    'caNumZoneRatedVeh',
    'caNumTrailers',
    'caNumPublicTransVeh'
]

const generateFieldsVisible = (currentVM) => {
    const policyType = _.get(currentVM.value, 'policyType');
    const fieldBaseOtherMaps = FIELD_BASE_OTHER_MAPS[policyType] || [];

    const overrides = fieldBaseOtherMaps.map((item) => {
        const isVisible =
            _.get(currentVM.value, item) === 'Other_Ext' ||
            _.get(currentVM.value, item) === 'Other';
        if (!isVisible) {
            _.set(currentVM.value, `${item}O`, undefined);
        }
        return {
            [`${item}O`]: {
                visible: isVisible,
            },
        };
    });
    return Object.assign({}, ...overrides);
};

const generateCommAutoFieldsVisible = (currentRow) => {
    const {
        policyType,
        caUnderlyingPolType
    } = currentRow.value;
    const overrides = CA_NUMBER_RULES.map((item) => {
        let isItemVisible = true;
        if(policyType === 'CommAuto') {
            isItemVisible = caUnderlyingPolType === 'BusAuto';
        }
        if(!isItemVisible) {
            _.set(currentRow.value, `${item}`, undefined);
        }
        const isVisible = _.get(currentRow.value, item) > 0 ;
        if (!isVisible) {
            _.set(currentRow.value, `${item}UL`, undefined);
            _.set(currentRow.value, `${item}UO`, undefined);
        }
        return {
            [item]: {
                visible: isItemVisible
            },
            [`${item}UL`]: {
                visible: isVisible
            },
            [`${item}UO`]: {
                visible: isVisible
            }
        }
    })
    return Object.assign({}, ...overrides);
};


const isFieldVisible = (currentRow, path) => {
    let isVisible = false;
    const {
        policyType,
        caUnderlyingPolType,
        caLiabilityCoverageType,
        glExpTypes
    } = currentRow.value;
    switch(path) {
        // CA or Motor
        case 'caUnderlyingPolType':
            isVisible = policyType === 'CommAuto'
            break;
        case 'caLiabilityCoverageType':
            isVisible = true
            if(policyType === 'CommAuto') {
                isVisible = !!caUnderlyingPolType
            }
           
            break
        case 'adEachAccAutoOnly':
        case 'adEachAccOtherAuto':
        case 'adAggLimOtherAuto':
        // case 'caOthGenLiaAggType':
        // case 'caProAndWorYouPerAggType':
            isVisible = caUnderlyingPolType === 'AutoDealer' && caLiabilityCoverageType === 'SplitLimit';
            break;
        case 'cabiEachAcc':
        case 'cabiEachPerson':
        case 'capdEachAcc':
            if(policyType === 'CommAuto') {
                isVisible = caUnderlyingPolType === 'BusAuto' && caLiabilityCoverageType === 'SplitLimit'
            }else {
                isVisible = caLiabilityCoverageType === 'SplitLimit'
            }
            break;
        case 'caCombSingLimiType':
            isVisible = caLiabilityCoverageType === 'CombinedSingleLimit';
            break;
        case 'caOthGenLiaAggType':
        case 'caProAndWorYouPerAggType':
            isVisible = caUnderlyingPolType === 'AutoDealer';
            break;
        // GL
        // popco
        case 'glOccLimit':
        case 'glAggLimit':
        case 'glProdCompOpsAggLim':
        case 'glPersAdvInj':
            isVisible = _.includes(glExpTypes, 'PremOpsProdCompOps');
            break;
        // owner and contractors
        case 'glOwnAndConOccLimit':
        case 'glOwnAndConAggLimit':
            isVisible = _.includes(glExpTypes, 'OwnContractor');
            break;
        // product withdrawal
        case 'glProdWithAggLimit':
            isVisible = _.includes(glExpTypes, 'ProdWith');
            break;
        // railroad
        case 'glRailraodOccLimit':
        case 'glRailroadAggLimit':
            isVisible = _.includes(glExpTypes, 'Railroad');
            break;
        default:
            isVisible = false;
            break;
    }
    if(!isVisible) {
        _.set(currentRow.value, path, undefined)
    }
    return !!isVisible
}

export { generateFieldsVisible, generateCommAutoFieldsVisible, isFieldVisible };