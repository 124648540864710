import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import _ from 'lodash';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
// import { useAuthentication } from '@xengage/gw-digital-auth-react';
// import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { useTranslator } from '@jutro/locale';
// import {
//     ErrorsAndWarningsUtil,
//     OOSUtil,
//     WizardPageJumpUtil,
// } from 'wni-portals-util-js';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// import { JobUtil, LocalDateUtil } from '@xengage/gw-portals-util-js';
// import { useAORValidationData } from 'wni-portals-util-react';
// import moment from 'moment';
// import { useValidation } from '@xengage/gw-portals-validation-react';
// import {
//     WniEndorsementService,
//     WniPAPolicyChangeService,
//     WniCustomEndorsementService,
//     WniCommonChangeService,
// } from 'wni-capability-policychange';
// // import {
// //     AgencyAndAgentInfoComponent,
// // } from 'wni-capability-gateway-react';
// import { ValidationIssuesComponent, ActionListComponent, useWniModal } from 'wni-components-platform-react';
// import { WizardErrorContext } from 'wni-portals-wizard-react';
// import { PolicyChangeStartMessages as policyChangePageMessages } from 'wni-platform-translations';

import { BasePolicyChangeStartPage } from 'wni-capability-gateway-react';


import { CPPWizardPage as WizardPage } from 'wni-capability-quoteandbind-cpp-react';

import {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
} from './CPPPolicyChangeStartPage.config';

/**
 * Original design from PAPolicyChangeStartPage
 * @param {object} props 
 * @returns {object}
 */
function CPPPolicyChangeStartPage(props) {
    return (
        <BasePolicyChangeStartPage
            {...props}
            //
            startPageConfig={{
                FIRST_CHANGE_WIZARD_PAGE,
                getChangePageJumpList,
            }}
            startPageName="CPPPolicyChangeStartPage"
            startPageTemplate={WizardPage}
            shouldShowJumpList="false"
            shouldHardStopByOos
        />
    );
}

CPPPolicyChangeStartPage.propTypes = {
    ...wizardProps,
};

CPPPolicyChangeStartPage.defaultProps = {

}
export default CPPPolicyChangeStartPage;