import { defineMessages } from 'react-intl';

export default defineMessages({
    ReceptacleDetails: {
        id: 'InlandMarine.valuablePapers.form.Receptacle Details',
        defaultMessage: 'Receptacle Details',
    },
    locationBuilding: {
        id: 'InlandMarine.valuablePapers.form.locationBuilding',
        defaultMessage: 'Location/Building'
    },
    isLocationALibrary: {
        id: 'InlandMarine.valuablePapers.form.isLocationALibrary',
        defaultMessage: 'Is Location a Library?'
    },
    Yes: {
        id: 'InlandMarine.valuablePapers.form.Yes',
        defaultMessage: 'Yes'
    },
    No: {
        id: 'InlandMarine.valuablePapers.form.No',
        defaultMessage: 'No'
    },
    receptacleManufacturer: {
        id: 'InlandMarine.valuablePapers.form.receptacleManufacturer',
        defaultMessage: 'Recaptacle Manufacturer'
    },
    receptacleLabel: {
        id: 'InlandMarine.valuablePapers.form.receptacleLabel',
        defaultMessage: 'Recaptacle Label'
    },
    RecaptacleClass: {
        id: 'InlandMarine.valuablePapers.form.RecaptacleClass',
        defaultMessage: 'Recaptacle Class'
    },
    recptacleIssue: {
        id: 'InlandMarine.valuablePapers.form.recptacleIssue',
        defaultMessage: 'Recaptacle Issuer'
    },
    unscheduledLimit: {
        id: 'InlandMarine.valuablePapers.form.unscheduled Limit',
        defaultMessage: 'Unscheduled Limit'
    },
    scheduleTitle: {
        id: 'InlandMarine.valuablePapers.form.scheduleTitle',
        defaultMessage: 'List of Schedule Items'
    },
    scheduleAdd: {
        id: 'InlandMarine.valuablePapers.form.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'InlandMarine.valuablePapers.form.table.del',
        defaultMessage: 'Delete Selected'
    },
    Cancel: {
        id: 'InlandMarine.valuablePapers.form.Cancel',
        defaultMessage: 'Cancel',
    },
    Close: {
        id: 'InlandMarine.valuablePapers.form.Close',
        defaultMessage: 'Close',
    },
    SaveAndContinue: {
        id: 'InlandMarine.valuablePapers.form.SaveAndContinue',
        defaultMessage: 'Save & Continue',
    }
})