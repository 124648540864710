import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'quoteandbind.views.paymentList.table.header.Name',
        defaultMessage: 'NAME',
    },
    downPayment: {
        id: 'quoteandbind.views.paymentList.table.header.Down payment',
        defaultMessage: 'DOWN PAYMENT'
    },
    installment: {
        id: 'quoteandbind.views.paymentList.table.header.Installment',
        defaultMessage: 'INSTALLMENT'
    },
    total: {
        id: 'quoteandbind.views.paymentList.table.header.Total',
        defaultMessage: 'TOTAL'
    },
    paymentPlans: {
        id: 'quoteandbind.views.payment-details.Payment Plans',
        defaultMessage: 'Payment Plans'
    },
    emptyPaymentPlans: {
        id: 'quoteandbind.views.payment-details.The table is empty...',
        defaultMessage: 'The table is empty...'
    }
});
