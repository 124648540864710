import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate, WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { Claim, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ClaimUtil, FNOLLossLocation } from 'gw-capability-fnol-common-react-ext';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ProductUtil, TableRowUtil } from 'wni-portals-util-react';
import { FNOLService } from 'wni-capability-fnol';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './FNOLPALossDetailPage.metadata.json5';
import messages from '../../FNOLPA.messages';

const {
    WT_LOB_NAME,
    PA_LOB_NAME
} = ProductUtil;
function FNOLPALossDetailPage(props) {
    const modalApi = useModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        },
        authHeader,
        isReadOnly = false
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, updateIsEditing] = useState(false);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    // const { FNOLService } = useDependencies('FNOLService');
    const [pageData] = useState(state);
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLPAIncidentsPage');

    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', 'personalAuto'));
    const PAGE_TYPE_NAME = LOB_NAME === WT_LOB_NAME ? 'watercraft' : 'vehicle';
    const isPALine = LOB_NAME === PA_LOB_NAME;

    const displayPolicyType = ClaimUtil.getDisplayNameByPolicyType(_.get(claimVM, 'value.policy.policyType', 'personalAuto'));

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const {
        claimNumber,
        lobs: {
            [LOB_NAME]: {
                vehicles_Ext: vehicles = [],
                filteredRelatedContacts_Ext: filteredRelatedContacts = [],
                vehicleIncidents_Ext: vehicleIncidents = []
            } = {}
        },
    } = claimVM.value

    const policyAndInsureVehicle = useMemo(
        () => {
            if (!_.isNil(vehicles)) {
                return _.concat(vehicles, vehicleIncidents.map(incident => {
                    const veh = incident[PAGE_TYPE_NAME]
                    // if incident vehicle hasn't been created
                    if(!veh.publicID){
                        veh.publicID = `temp:${TableRowUtil.getUuid()}`
                    }
                    return veh
                }))
            }
            
            return vehicleIncidents?.map(incident => {
                const veh = incident[PAGE_TYPE_NAME]
                // if incident vehicle hasn't been created
                if(!veh.publicID){
                    veh.publicID = `temp:${TableRowUtil.getUuid()}`
                }
                return veh
            })
            
        },[PAGE_TYPE_NAME, vehicleIncidents, vehicles]
    ) 

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    const lossSubCauseAvailableValues = _.get(claimVM, 'lossSubCause_Ext.aspects.availableValues', [])

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const syncWizardData = useCallback(
        (data, basePath) => {
            const newClaimVM = viewModelService.clone(claimVM);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.${basePath}_Ext`, data);
            if (!isReadOnly) {
                updateWizardData(newClaimVM);
            }
        },[LOB_NAME, claimVM, isReadOnly, updateWizardData, viewModelService]
    ) 

    const overrides = useMemo(() => ({
        '@field': {
            labelPosition: 'left',
            showRequired: true
        },
        lossLocation: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData,
            isShowWhereDidHappenRadioButton: false,
            showErrors: showErrors,
            setComponentValidation: onValidate,
        },
        lossDetail: {
            visible: lossSubCauseAvailableValues.length > 0
        },
        vehicleContainer: {
            ...props,
            claimVM,
            LOB_NAME,
            syncWizardData,
            updateIsEditing,
            isEditing,
            basePath: `vehicles`,
            tableData: policyAndInsureVehicle
        },
        vehicleContainerTitle: {
            content: isPALine ? translator(messages.vehiclePAContainerTitle) : translator(messages.vehicleContainerTitle)
        }
    }), [claimVM, updateWizardData, showErrors, onValidate, lossSubCauseAvailableValues.length, props, LOB_NAME, syncWizardData, isEditing, policyAndInsureVehicle, isPALine, translator]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.paCreateDraftErrorTitle,
                    message: translator(messages.createDraftErrorMessage, {policytype:displayPolicyType}),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, displayPolicyType, modalApi, translator]);

    const onNext = useCallback(async () => {
        setIsLoading(true);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            return handleSaveClaimData();
        }
        // eslint-disable-next-line no-return-await
        return await FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                updateWizardData(claimVM)
                return claimVM;
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.paCreateDraftErrorTitle,
                    message: translator(messages.paCreateDraftErrorMessage, displayPolicyType),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, displayPolicyType, handleSaveClaimData, modalApi, translator, updateWizardData]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const resolvers = {
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation,
            // PECommonFNOLPolicyVehicle: PEFNOLPolicyVehicle
        }
    };

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={isComponentValid ? onNext : setValidation }
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate} 
            alwaysCallOnNext>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLPALossDetailPage.propTypes = wizardProps;
FNOLPALossDetailPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLPALossDetailPage));
