
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALWatercraftsPage } from 'wni-capability-quoteandbind-wal-react';

function WALWatercraftsChangePage(props) {

    return (
        <WALWatercraftsPage {...props} checkRequiredForIssuance={false} />
    );

}

WALWatercraftsChangePage.propTypes = WALWatercraftsPage.propTypes;
WALWatercraftsChangePage.defaultProps = WALWatercraftsPage.defaultProps;

export default WALWatercraftsChangePage;