import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
    // ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
// import { PortalConstants } from 'wni-portals-config-js';
// import { WALCoveragesService } from 'wni-capability-quoteandbind-wal';
// import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WizardPage from '../../templates/WALWizardPage';
import WALCoveragesConfig from './WALCoverages.config';
// import WALCoverageUtil from './util/WALCoverageUtil';

import WALLineCoverages from './CoveragesSection/WALLineCoverages/WALLineCoverages';
import WALWaterCraftCoverages from './CoveragesSection/WALWaterCraftCoverages/WALWaterCraftCoverages';

import styles from './WALCoveragesPage.module.scss';
import metadata from './WALCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/WALCoveragesConfigContext';

function WALCoveragesReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;

    const {
        // jobID,
        // sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            watercraft: {
                offerings
            }
        },
    } = submissionVM.value;

    // const { authHeader } = useAuthentication();

    const {
        initialValidation,
    } = useValidation('WALCoveragesPage');
    // const [showErrors, setShowErrors] = useState(false);
    // const [isEditing, setIsEditing] = useState(false)

    // const [validationIssues, updateValidationIssues] = useState([])
    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelCoverages',
    ]);
    // const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    const lobName = 'watercraft'

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    useEffect(() => {

    }, []);

    // const updateWizardDataToDraft = useCallback((newSubmissionVM) => {
    //     // Refactoring Notice: Consider to extract this into a common method
    //     _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
    //     updateWizardData(newSubmissionVM);
    // }, [updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    // const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
    //     const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
    //     updateValidationIssues(newValidationIssues);
    // }, [updateValidationIssues]);

    const onPageNext = async () => {
        return submissionVM;
    }

    // const handleValidation = () => {
    //     setShowErrors(true)
    //     return false
    // }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                isEditable: false,
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            waLineCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
            },
            waWatercraftCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
                isEditable: false,
            }
        };
    }, [
        lobName,
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        openedAccordions,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            waLLineCoverages: WALLineCoverages,
            WALWaterCraftCoverages: WALWaterCraftCoverages,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // disableNext={isEditing}
            // disablePrevious={isEditing}
            // disableCancel={isEditing}
            onNext={onPageNext}
            // alwaysCallOnNext
            //
            showRequiredInfoForFasterQuote
        >
            <CoveragesConfigContext.Provider value={WALCoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
}

WALCoveragesReadOnlyPage.propTypes = WizardPage.propTypes;
export default WALCoveragesReadOnlyPage;
