

import React from 'react';
import { IMRiggersCoverageReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMRiggersCoverageChangeReadOnlyPage = (props) => {
    return <IMRiggersCoverageReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMRiggersCoverageChangeReadOnlyPage
