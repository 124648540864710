import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { Chevron, Icon } from '@jutro/components';

import styles from './SummaryHeader.module.scss';

const SummaryHeader = memo(({
    isOpen, type, displayName, title, icon, showIconOnly
}) => {
    return (
        <Fragment>
            {!showIconOnly && <Chevron isOpen={isOpen} />}
            <div className={styles.sumaryAccordionHeaderContent}>
                <Icon
                    className={styles.summaryInfoLabelAccordionHeader}
                    id={type}
                    icon={icon}
                />
                {!showIconOnly && (
                    <>
                        <div className={styles.summaryInfoHeading}>{title}</div>
                        <div className={styles.summaryInfoHeading}>{displayName}</div>
                    </>
                )}
            </div>
        </Fragment>
    );
});

SummaryHeader.propTypes = {
    isOpen: PropTypes.bool,
    type: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    showIconOnly: PropTypes.bool
};

SummaryHeader.defaultProps = {
    isOpen: false,
    showIconOnly: false
};

export default SummaryHeader;
