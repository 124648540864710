import { defineMessages } from 'react-intl';

export default defineMessages({
    ExclusionsAndConditions: {
        "id": "quoteandbind.cr.wizard.step.StateInfo Page.Exclusions and Conditions",
        "defaultMessage": "Exclusions and Conditions"
    },
    AddExclusionsAndConditions: {
        "id": "quoteandbind.cr.wizard.step.StateInfo page.Add Exclusions and Conditions",
        "defaultMessage": "Add Exclusions and Conditions"
    },
});