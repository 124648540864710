
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { HOLossHistoryAndPriorCarrierPage } from 'wni-capability-quoteandbind-ho-react';
import {DPClueService, DPPriorCarrierService} from 'wni-capability-quoteandbind-dp';


function DPLossHistoryAndPriorCarrierPage(props) {
    return (
        <HOLossHistoryAndPriorCarrierPage {...props}
            productName = 'dwellingProperty'
            hoPriorCarrierService = {DPPriorCarrierService}
            hoClueService = {DPClueService}
        />
    );

}

DPLossHistoryAndPriorCarrierPage.propTypes = HOLossHistoryAndPriorCarrierPage.propTypes;
DPLossHistoryAndPriorCarrierPage.defaultProps = HOLossHistoryAndPriorCarrierPage.defaultProps;

export default DPLossHistoryAndPriorCarrierPage;
