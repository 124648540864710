import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Image } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import defaultImage from './images/contact-avatar.png';

import styles from './ContactComponent.module.scss';

import { Link } from '@jutro/router';

const PUBLIC_ID_FIELD = 'publicID';

const defaultConfig = {
    image: defaultImage
};

function ContactComponent(props) {
    const { id, config } = props;
    const [aSummary, setSummary] = useState({});
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        displayName, userType, city, phoneNumber, email, image
    } = aSummary;

    const { ContactService } = useDependencies('ContactService');

    const renderCityAndPhoneNumberSection = useCallback(() => {
        const dataFormat = city && phoneNumber ? `${city}, ${phoneNumber}` : city || phoneNumber;
        return <div>{dataFormat}</div>;
    }, [city, phoneNumber]);

    const mailToTrigger = useCallback((event) => {
        event.preventDefault();
        window.location = `mailto:${email}`;
    }, [email]);

    useEffect(() => {
        const fetchData = async () => {
            let mergeData;

            if (_.isFunction(ContactService.getContactSummaries)) {
                const summaries = await ContactService.getContactSummaries(authHeader);
                const allSummariesAreEqual = summaries.every((summary) => {
                    return summary[PUBLIC_ID_FIELD] === summaries[0][PUBLIC_ID_FIELD];
                });
                if (summaries.length > 1 && !allSummariesAreEqual) {
                    return [];
                }
                mergeData = _.merge(defaultConfig, config, summaries[0]);
            } else {
                mergeData = _.merge(defaultConfig, config);
            }
            setSummary(mergeData);
        };
        fetchData();
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (_.isEmpty(aSummary)) {
        return null;
    }

    return (
        <div className={styles.gwContactModule} id={id}>
            <div className={styles.gwContactInformationContainer}>
                {displayName && <h3>{translator(displayName)}</h3>}
                {userType && <span>{translator(userType)}</span>}
                {renderCityAndPhoneNumberSection()}
                {email && (
                    <Link href="/" onClick={mailToTrigger}>
                        {email}
                    </Link>
                )}
            </div>
            <div className={styles.gwImageContainer}>
                <Image src={image} alt={displayName} />
            </div>
        </div>
    );
}

ContactComponent.propTypes = {
    id: PropTypes.string,
    config: PropTypes.shape({
        displayName: PropTypes.string,
        userType: PropTypes.string,
        city: PropTypes.string,
        phoneNumber: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string
    })
};

ContactComponent.defaultProps = {
    id: undefined,
    config: undefined
};

export default ContactComponent;
