import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { BaseClausesComponentVM } from 'wni-components-platform-react';
// import SingleClauseComponentVM from './SingleClauseComponentVM';

/**
 * Clause component hierarchy diagram:
 * ClausesComponentVM -> SingleClauseComponentVM -> ClauseComponent
 * @param {object} props
 * @returns {object}
 */
function ClausesComponentVM(props) {
    const {
        value: clauses,
        path,
        labelPosition,
        loadingClauseMessage,
        showErrors,
        sortBySelected,
        showAmount,
        splitByClauseCategory,
        isEditable,
        //
        clauseFilter,
    } = props;

    const translator = useTranslator();

    const toMetadata = (clausesCategory) => {
        // override priority
        const { coveragePriorityOverride } = R1Config;
        const keys = Object.keys(coveragePriorityOverride);
        clausesCategory.filter((elt) => keys.includes(_.get(elt, 'code_Ext')))
            .forEach((elt) => {
                const key = _.get(elt, 'code_Ext');
                _.set(elt, 'priority_Ext', coveragePriorityOverride[key]);
            });

        // sort
        let sortedClausesCategory;
        if (sortBySelected) {
            sortedClausesCategory = _.orderBy(clausesCategory, ['selected', 'priority_Ext'], ['desc', 'asc']);
        } else {
            sortedClausesCategory = _.sortBy(clausesCategory, ['priority_Ext']);
        }

        // filter out some clauses, e.g. filter out driverCoverages from LineCovInput
        if (_.isFunction(clauseFilter)) {
            sortedClausesCategory = sortedClausesCategory.filter(clauseFilter);
        }

        return sortedClausesCategory.map((clause) => {
            const index = clauses.findIndex((element) => element.publicID === clause.publicID);
            const clausePath = `[${index}]`;

            const changedValuePath = WniClausesUtil.getChangedValuePath(path, clausePath);
            return {
                id: `clause_${clause.publicID}_[${index}]`,
                type: 'field',
                component: 'SingleClauseComponentVM',
                componentProps: {
                    ...props,
                    labelPosition,
                    loadingClauseMessage: translator(loadingClauseMessage),
                    value: clause,
                    path: changedValuePath,
                    containerClassName: 'clauseContainer',
                    showErrors: showErrors,
                    showAmount: showAmount,
                }
            };
        });
    };

    return (
        <BaseClausesComponentVM
            {...props}
            getClausesCategoryMetadata={toMetadata}
        />
    );
}

ClausesComponentVM.propTypes = {
    ...BaseClausesComponentVM.propTypes,
    /**
    * (Optional) (clauseDataDTO) => boolean
     * Filter function to decide which clauses should be handled
     * by this component.
     */
    clauseFilter: PropTypes.func,
};
ClausesComponentVM.defaultProps = {
    ...BaseClausesComponentVM.defaultProps,
    clauseFilter: undefined,
};

export default ClausesComponentVM;
