import _ from 'lodash';

// ======================================
// Original Design by Adrian
// ======================================

function groupDataByInvoiceStreamCode(data) {
    const useNewInvoiceStreamDatas = [];
    const groupData = {};
    _.each(data, (accountData) => {
        const invoiceStreamCode = _.get(accountData, 'invoiceStreamCode');
        if (_.isEmpty(invoiceStreamCode)) {
            useNewInvoiceStreamDatas.push(accountData);
        } else {
            const invoiceStreamDescription = _.get(
                accountData,
                'invoiceStreamDescription'
            );
            if (_.isEmpty(groupData[invoiceStreamCode])) {
                groupData[invoiceStreamCode] = {
                    invoiceStreamCode: invoiceStreamCode,
                    invoiceStreamDescription: invoiceStreamDescription,
                    data: [],
                };
            }
            groupData[invoiceStreamCode].data.push(accountData);
        }
    });
    const accountTableDataGroupByInvoiceStreamCode = [];
    _.each(groupData, (value) => {
        accountTableDataGroupByInvoiceStreamCode.push(value);
    });

    // group data with same bill account
    const newInvoiceGroupData = {};
    const mortGageUseNewInvoiceStreamDatas = [];
    _.each(useNewInvoiceStreamDatas, (value) => {
        const isMortgage = _.get(value, 'mortgageBill', false);
        if (isMortgage) {
            mortGageUseNewInvoiceStreamDatas.push(value);
        } else {
            _.set(value, 'primaryPayer.phoneRequired_Ext', true)
            const code = `${_.get(value, 'dueDayOfTheMonth')} ${_.get(value, 'payUsing')} ${JSON.stringify(_.get(value, 'primaryPayer', {}))}`;
            if (_.isEmpty(newInvoiceGroupData[code])) {
                newInvoiceGroupData[code] = {
                    invoiceStreamCode: 'New',
                    invoiceStreamDescription: 'New',
                    data: [],
                };
            }
            newInvoiceGroupData[code].data.push(value);
        }
        
    });
    // new invoiceStream with same billing parameter
    _.each(newInvoiceGroupData, (value) => {
        accountTableDataGroupByInvoiceStreamCode.push(value);
    });
    // mortgage bill
    _.each(mortGageUseNewInvoiceStreamDatas, (value) => {
        accountTableDataGroupByInvoiceStreamCode.push(value);
    })
    return accountTableDataGroupByInvoiceStreamCode;
}

function getPaymentDetailsData(paymentMethodsOptions) {
    const data = [
        {
            code: 'Check',
            name: 'Bill'
        }
    ];
    if (paymentMethodsOptions.length > 0) {
        paymentMethodsOptions.forEach((vm) => {
            if (vm.code) {
                data.push(vm);
            } else {
                // last 4 digits of bankAccountNumber
                let digits = _.get(vm, 'value.bankAccountData.bankAccountNumber');
                if (digits) {
                    digits = digits.substr(-4);
                }
                let type = _.get(vm, 'value.bankAccountData.bankAccountType');
                type = type === 'checking' ? 'Checking' : 'Saving';
                data.push({
                    code: _.get(vm, 'value.bankAccountData.publicID') || JSON.stringify(_.get(vm, 'value.bankAccountData')),
                    name: `AutoPay (${type} - ${digits})`,
                });
            }
        });
    }
    data.push({
        code: 'Add New AutoPay Account',
        name: 'Add New AutoPay Account',
    });
    return data;
}

function popupNewBankAccount(viewModelService, onReject, model,
    showAddpaymentMethodPopup, PaymentUtil, paymentMethodsOptions,
    setLoadingMask, WniPAQuoteService, quoteID, sessionUUID,
    authHeader, rePopupEditQuoteRow, selectedItem, isTemporaryForPortal = false) {
    // popup a window to enter
    const paymentDetails = {
        paymentMethod: 'autopay_Ext',
        bankAccountData: {
            bankAccountType: 'checking'
        }
    };
    const popupOneTimePaymentMethodVM = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
    return Promise.resolve(onReject()).then(() => {
        let code = _.get(model, 'payUsing', '');
        let payUsingName = _.get(model, 'payUsingName', '');
        const temporaryOptions = _.get(model, 'payUsingTemporaryOptions', []);
        showAddpaymentMethodPopup(popupOneTimePaymentMethodVM).then(async (updatedVM) => {
            // check payment exist in payment methods
            const result = PaymentUtil.checkPaymentMethodExist(
                updatedVM, paymentMethodsOptions
            );
            if (_.isEmpty(result)) {
                // is only for portal frontend cache
                if (isTemporaryForPortal) {
                    const bankAccountData = _.get(updatedVM, 'value.bankAccountData');
                    const temporaryBankData = {
                        paymentMethod: 'autopay_Ext',
                        bankAccountData: {
                            bankABANumber: _.get(bankAccountData, 'bankABANumber'),
                            bankAccountNumber: _.get(bankAccountData, 'bankAccountNumber'),
                            bankAccountType: _.get(bankAccountData, 'bankAccountType'),
                            bankName: _.get(bankAccountData, 'bankName')
                        }
                    };
                    const temporaryOpt = viewModelService.create(temporaryBankData, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
                    code = JSON.stringify(_.get(temporaryOpt, 'value.bankAccountData'));
                    temporaryOptions.push(temporaryOpt);
                    return true;
                }
                // get new payInstrument publicID
                const publicID = await PaymentUtil.savePayInstrucment(updatedVM,
                    setLoadingMask, WniPAQuoteService.savePayInstrucmentService,
                    quoteID, sessionUUID, authHeader);
                _.set(updatedVM, 'value.bankAccountData.publicID', publicID);
                const bankAccountNumber = _.get(updatedVM, 'value.bankAccountData.bankAccountNumber');
                payUsingName = `AutoPay(BankAccount-${bankAccountNumber.substring(bankAccountNumber.length - 4)})`;
                code = publicID;
                return true;
            }
            code = _.get(result, 'value.bankAccountData.publicID') || JSON.stringify(_.get(result, 'value.bankAccountData'));
            // duplicate
            return false;
        }).catch(() => {
            _.noop();
            return false;
        }).finally(() => {
            // update options and value
            if (!_.isEmpty(code)) {
                _.set(model, 'isAutoPay', true);
                // _.set(selectedItem, 'rowData.isAutoPay', true);
                const payInstrucments = _.get(model, 'bindData.payInstrucments_Ext', []);
                const payInstrument = _.find(payInstrucments, (instrucment) => _.get(instrucment, 'publicID_Ext') === code);
                if (payInstrument) {
                    const bankAccountNumber = _.get(payInstrument, 'accountNumber_Ext');
                    _.set(model, 'payUsingName', `AutoPay(BankAccount-${bankAccountNumber.substring(bankAccountNumber.length - 4)})`, 'payUsingName');
                    // _.set(selectedItem, 'rowData.payUsingName', `AutoPay(BankAccount-${bankAccountNumber.substring(bankAccountNumber.length - 4)})`, 'payUsingName');
                } else {
                    _.set(model, 'payUsingName', payUsingName);
                    // _.set(selectedItem, 'rowData.payUsingName', payUsingName);
                }
            }
            _.set(model, 'payUsing', code);
            // _.set(selectedItem, 'rowData.payUsing', code);
            if (isTemporaryForPortal) {
                _.set(model, 'payUsingTemporaryOptions', temporaryOptions);
                // _.set(selectedItem, 'rowData.payUsingTemporaryOptions', temporaryOptions);
            }
            _.set(selectedItem, 'editedData', model);
            rePopupEditQuoteRow(selectedItem);
            _.noop();
        });
    });
}

function formatIssueReuslt(bindResult, PaymentUtil, updateValidationIssuesWarning) {
    // initial getting issues from resposne
    const {
        errorsAndWarnings: {
            underwritingIssues = [],
            validationIssues: {
                issues = [],
                fieldIssues = []
            } = {}
        } = {}
    } = bindResult;
    // concat validationIssues
    const validationIssues = [...issues, ...fieldIssues];
    // filter error level issues
    const errorValidateIssues = _.filter(validationIssues, (issue) => _.get(issue, 'type') === 'error');
    // convert uwIssues
    const convertedUnderwritingIssues = _.map(underwritingIssues,
        ({ issueTypeCode_Ext: issueTypeCodeExt, longDescription }) => {
            return {
                type: issueTypeCodeExt,
                reason: _.get(PaymentUtil.UWISSUE_MAP, issueTypeCodeExt) || longDescription
            };
        });
    // combine validationIssues and underwritingIssues
    const errors = [...errorValidateIssues, ...convertedUnderwritingIssues];
    if (!_.isEmpty(errors)) {
        updateValidationIssuesWarning(errors);
        return false;
    }
    // default return
    return bindResult;
}

export default {
    groupDataByInvoiceStreamCode,
    getPaymentDetailsData,
    popupNewBankAccount,
    formatIssueReuslt
};
