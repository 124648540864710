
import React, {
    useState,
} from 'react';
import _ from 'lodash';
import { HODPCoveragesBasePage } from 'wni-capability-quoteandbind-ho-react';
import { DPCoveragesService } from 'wni-capability-quoteandbind-dp'

import DPCoveragesConfig from './DPCoverages.config';
import DPPrimaryCoverages from './CoveragesSection/DPPrimaryCoverages/DPPrimaryCoverages';

function DPCoveragesPage(props) {

    const {
        wizardData: submissionVM,
    } = props;

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            dwellingProperty: {
                offerings
            }
        },
    } = submissionVM.value;

    const [openedAccordions, setOpendedAccordions] = useState(() => {
        const res = [
            'primaryCoverages',
            'commonOptionalCoverages'
        ]
        const selectedVersion = offerings
            .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
        
        const otherOptionalCoverages = _.get(selectedVersion, 'coverages.otherOptionalCoverages_Ext', []);
        const exclusions = _.get(selectedVersion, 'coverages.exclusion_Ext', []);
        if (otherOptionalCoverages.some((coverage) => coverage.selected)
            || exclusions.some((coverage) => coverage.selected)) {
            res.push('otherOptionalCoveragesAndExclusions')
        }
        return res
    });

    const DPOverrideProps = {
        coveragesAccordion: {
            accordionStates: openedAccordions,
            onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
        },
    }

    const componentMapOverride = {
        hoPrimaryCoverages: DPPrimaryCoverages,
    }

    return <HODPCoveragesBasePage
        {...props}
        lobName='dwellingProperty'
        coveragesConfig={DPCoveragesConfig}
        coveragesService={DPCoveragesService}
        componentMapOverride={componentMapOverride}
        DPOverrideProps={DPOverrideProps}
    />

}

DPCoveragesPage.propTypes = HODPCoveragesBasePage.propTypes;
DPCoveragesPage.defaultProps = HODPCoveragesBasePage.defaultProps;

export default DPCoveragesPage;
