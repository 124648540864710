import cpMessages from './CommercialPropertyResult.messages';
import wc7Messages from './WC7WorkersCompResult.messages';

export default () => {
    return {
        get CommercialProperty() {
            return {
                'fail-processing': {
                    heading: cpMessages.somethingDataWasNotImported,
                    subheading: cpMessages.buildingsWereNotImported,
                    buttonContent: cpMessages.uploadTheSpreadsheetAgain
                },
                'fail-empty': {
                    heading: cpMessages.noDataImported,
                    subheading: cpMessages.spreadSheetFailEmpty,
                    contents: cpMessages.spreadSheetNoInformation,
                    buttonContent: cpMessages.uploadSpreadsheet
                },
                'fail-unchanged': {
                    heading: cpMessages.noDataImported,
                    subheading: cpMessages.noChangeInSpreadsheet,
                    contents: cpMessages.updatedSpreadsheetInformation,
                    buttonContent: cpMessages.uploadSpreadsheet
                },
                'fail-full': {
                    heading: cpMessages.somethingDataWasNotImported,
                    buttonContent: cpMessages.uploadSpreadsheet
                },
                partial: {
                    heading: cpMessages.wentWrongPartialUpload,
                    buttonContent: cpMessages.continueUpdatingSpreadsheet
                },
                success: {
                    heading: cpMessages.spreadsheetDataSuccessfullyImported,
                    buttonContent: cpMessages.continueUpdatingSpreadsheet
                }
            };
        },

        get WC7WorkersComp() {
            return {
                'fail-processing': {
                    heading: wc7Messages.somethingDataWasNotImported,
                    subheading: wc7Messages.buildingsWereNotImported,
                    buttonContent: wc7Messages.uploadTheSpreadsheetAgain
                },
                'fail-empty': {
                    heading: wc7Messages.noDataImported,
                    subheading: wc7Messages.yourSpreadsheetWasEmpty,
                    contents: wc7Messages.updatedSpreadsheetInformation,
                    buttonContent: wc7Messages.uploadSpreadsheet
                },
                'fail-unchanged': {
                    heading: wc7Messages.noDataImported,
                    subheading: wc7Messages.noChangeInSpreadsheet,
                    contents: wc7Messages.updatedSpreadsheetInformation,
                    buttonContent: wc7Messages.uploadSpreadsheet
                },
                'fail-full': {
                    heading: wc7Messages.somethingDataWasNotImported,
                    buttonContent: wc7Messages.somethingDataWasNotImported
                },
                partial: {
                    heading: wc7Messages.wentWrongPartialUpload,
                    buttonContent: wc7Messages.continueUpdatingtheSpreadsheet
                },
                success: {
                    heading: wc7Messages.spreadsheetDataSuccessfullyImported,
                    buttonContent: wc7Messages.continueUpdatingtheSpreadsheet
                }
            };
        }
    };
};
