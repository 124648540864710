import React, { useCallback } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPageHeader } from 'gw-portals-wizard-components-ui'
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { withRouter } from 'react-router-dom';

import messages from 'gw-capability-policychange-common-react/pages/Confirmation/ConfirmationPage.messages';
import styles from 'gw-capability-policychange-common-react/pages/Confirmation/ConfirmationPage.module.scss';

import metadata from './ConfirmationPage.metadata.json5';
import customMessages from './ConfirmationPage.messages';

function ConfirmationPage(props) {
    const { wizardData } = props;
    const { submissionVM } = wizardData;
    const changesAppliedForward = _.get(wizardData, 'changesAppliedForward');
    const isGateway = appConfig.persona === 'producer' || appConfig.persona === 'csr';
    const changesAppliedForwardText = isGateway ? messages.contactUnderwriterMessage
        : messages.contactAgentMessage;
    const overrideProps = {
        wizardPageHeader: { wizardSubmission: submissionVM },
        pageHeaderTitle: {
            className: styles.confirmationLabel
        },
        policyChangeConfirmationMessage: {
            className: styles.inlineNotificationStyle,
            message: changesAppliedForward && changesAppliedForwardText
        },
        backToPolicyButtonId: {
            content: isGateway ? messages.backToPolicy : messages.backToHome
        }
    };

    const goToPolicy = useCallback(
        () => {
            const { history } = props;
            const policyNumber = _.get(submissionVM.value, 'policyNumber');
            const redirectPath = isGateway ? `/policies/${policyNumber}/summary` : '/';
            history.push(redirectPath);
        }, [isGateway, props, submissionVM.value]
    );

    const resolvers = {
        resolveCallbackMap: {
            goToPolicy: goToPolicy
        },
        resolveComponentMap: {
            wizardpageheader: WizardPageHeader
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ConfirmationPage.propTypes = wizardProps;
export default withRouter(ConfirmationPage);
