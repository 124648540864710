import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniDateUtil, WniFormat } from 'wni-portals-util-js';
import metadata from './VehicleDetailsComponent.metadata.json5';
import styles from './VehicleDetailsComponent.module.scss';

/**
 * Component contains main fields of vehicle
 * @param {object} props
 * @returns {object} React Component
 */
const VehicleDetailsComponent = (props) => {
    const {
        id: componentId,
        model: vehicleVM,
        onValueChange,
        showErrors,
        readOnly,
        isOtherThanCollisionSelected,
        availableOwners,
        isQuoteForNamedNonOwner,
        quoteStatus,
        validVinInfo,
        updateValidVinInfo,
        onValidate,
        quoteFlow,
        submittingQuote,
        checkCanViewAndEdit,
        updateVehicleType,
        updateCacheOptions,
        getCacheOptions,
        isRequiredForIssuance,
        isNewVehicleReplacementSelected,
        shouldSkipInitialization,
    } = props;


    const {
        otherOwnersAssignedFlag_Ext: isOtherOwnerSelected = false,
        isStatedValueRequired_Ext: isStatedValueRequired
    } = vehicleVM.value;

    const isNamedNonOwner = isQuoteForNamedNonOwner;

    // const isRequiredForIssuance = quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote;

    // the 'Other' option in field Owner(s)
    const availableOwnersOther = {
        displayName: 'Other',
        fixedId: 0,
        publicID: 'Other-owner-portal'
    };
    const ownerOptions = [...availableOwners, availableOwnersOther];

    const writeValue = (value, path) => {
        onValueChange(value, path);
    };

    const {
        onValidate: setComponentValidation
    } = useValidation(componentId);

    const getOwnersValue = useCallback(() => {
        let DataValues = vehicleVM.value.owners_Ext ? vehicleVM.value.owners_Ext : [];
        const idOther = DataValues.find((v) => {
            return v.publicID === 'Other-owner-portal';
        });
        if (isOtherOwnerSelected && !idOther) {
            DataValues = [...DataValues, availableOwnersOther];
        }
        return DataValues.map((owner) => {
            return owner.publicID;
        });
    }, [availableOwnersOther, isOtherOwnerSelected, vehicleVM]);

    const handleOwnersChange = useCallback((value) => {
        const indexOther = value ? value.indexOf('Other-owner-portal') : -1;
        if (value && indexOther > -1) {
            writeValue(true, 'otherOwnersAssignedFlag_Ext');
            value.splice(indexOther, 1);
        } else {
            writeValue(false, 'otherOwnersAssignedFlag_Ext');
        }
        writeValue(value ? ownerOptions.filter((owner) => {
            return value.indexOf(owner.publicID) > -1;
        }) : undefined, 'owners_Ext');
    }, [ownerOptions, writeValue]);

    const getAvailableOwners = useCallback(() => {
        return ownerOptions.map((owner) => {
            return {
                code: owner.publicID,
                name: owner.displayName
            };
        });
    }, [ownerOptions]);

    const getAvailableSafetyFeatures = () => {
        const safetyFeatureOptions = _.get(vehicleVM, 'sampleSafetyFeature_Ext.aspects.availableValues');
        return safetyFeatureOptions ? safetyFeatureOptions.map((option) => {
            return {
                code: option.code,
                name: {
                    id: option.name,
                },
            };
        }) : [];
    };
    const getPlaceholder = (path, isRequired, requiredForIssuance) => {
        return WniFormat.placeholderFormat(vehicleVM, path, isRequired, requiredForIssuance);
    };
    //----------------------------------
    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        vinLookup: {
            readOnly,
            value: vehicleVM,
            onValueChange,
            isOtherThanCollisionSelected,
            quoteStatus,
            showErrors,
            isQuoteForNamedNonOwner,
            validVinInfo,
            updateValidVinInfo,
            onValidate,
            checkCanViewAndEdit,
            updateVehicleType,
            updateCacheOptions,
            getCacheOptions,
            isNewVehicleReplacementSelected,
            isRequiredForIssuance,
            shouldSkipInitialization,
        },
        driverLicenceState: {
            readOnly: readOnly,
            visible: !isNamedNonOwner,
            required: isRequiredForIssuance,
            ...getPlaceholder('licenseState', isRequiredForIssuance, true)
        },
        annualMileage: {
            ...getPlaceholder('annualMileage')
        },
        purchaseDate: {
            visible: !isNamedNonOwner,
            maxDate: new Date(),
            minDate: WniDateUtil.backEarlyDate(),
            value: _.get(vehicleVM.value, 'purchaseDate_Ext'),
            onValueChange,
            showErrors
        },
        statedValue: {
            visible: !isNamedNonOwner,
            required: isStatedValueRequired,
            ...getPlaceholder('statedValue_Ext', isStatedValueRequired, isRequiredForIssuance)
        },
        leased: {
            visible: !isNamedNonOwner,
        },
        noVehicleNamedNonOwned_Ext: {
            visible: isNamedNonOwner,
        },
        vehicleOwners: {
            onValueChange: readOnly ? _.noop : handleOwnersChange,
            availableValues: getAvailableOwners(),
            value: getOwnersValue(),
            visible: !isNamedNonOwner,
            required: isRequiredForIssuance && !isOtherOwnerSelected,
            ...getPlaceholder('owners_Ext', isRequiredForIssuance && !isOtherOwnerSelected, true)
        },
        otherOwnerNames: {
            visible: !isNamedNonOwner && isOtherOwnerSelected,
            required: isRequiredForIssuance && isOtherOwnerSelected,
            ...getPlaceholder('otherOwners_Ext', isRequiredForIssuance && isOtherOwnerSelected, true)
        },
        safetyFeature: {
            availableValues: getAvailableSafetyFeatures(),
            onValueChange: readOnly ? _.noop : writeValue,
            value: _.get(vehicleVM.value, 'safetyFeatures_Ext'),
            visible: !isNamedNonOwner,
            ...getPlaceholder('safetyFeatures_Ext')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vehicleVM,
            id,
            path,
            overrideProps
        );
    };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
};

VehicleDetailsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool,
    isQuoteForNamedNonOwner: PropTypes.bool,
    isOtherThanCollisionSelected: PropTypes.bool,
    quoteStatus: PropTypes.shape({ code: PropTypes.string }).isRequired,
    quoteFlow: PropTypes.string.isRequired,
    availableOwners: PropTypes.arrayOf(PropTypes.shape({})),
    accordionStates: PropTypes.arrayOf(PropTypes.string),
    validVinInfo: PropTypes.shape({}).isRequired,
    updateValidVinInfo: PropTypes.func.isRequired,
    submittingQuote: PropTypes.bool,
    checkCanViewAndEdit: PropTypes.func,
    updateVehicleType: PropTypes.func,
    updateCacheOptions: PropTypes.func,
    getCacheOptions: PropTypes.shape({}),
    isRequiredForIssuance: PropTypes.bool.isRequired,
    isNewVehicleReplacementSelected: PropTypes.bool,
    shouldSkipInitialization: PropTypes.bool,
};

VehicleDetailsComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
    readOnly: false,
    isOtherThanCollisionSelected: false,
    isQuoteForNamedNonOwner: false,
    availableOwners: [],
    accordionStates: [],
    submittingQuote: false,
    checkCanViewAndEdit: _.noop,
    updateVehicleType: _.noop,
    updateCacheOptions: _.noop,
    getCacheOptions: {},
    isNewVehicleReplacementSelected: false,
    shouldSkipInitialization: false,
};

export default VehicleDetailsComponent;
