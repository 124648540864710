import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';

function StructureCoverageTermsComponent(props) {

    const {
        terms,
        handleTermsChange,
        handleTermsOnBlur,
        registerComponentValidation,
        showErrors,
        readOnly,
        coveragesConfig
    } = props;

    const {
        privateRelatedStructureConfig
    } = coveragesConfig;

    const onTermsChange = (newTerms, path) => {
        const re = /\[[0-9]+\]/;
        const pathToTerm = _.get(path.match(re), '[0]');
        const updatedTerm = _.get(newTerms, pathToTerm)
        const updatedTermValueType = _.get(updatedTerm, 'valueType')
        handleTermsChange(newTerms, updatedTermValueType);
    }

    const onValueChange = (value, path) => {
        const clonedTerms = _.clone(terms);
        _.set(clonedTerms, path, value);
        onTermsChange(clonedTerms, path)
    }

    const onCurrencyChange = (value, path) => {
        const clonedTerms = _.clone(terms);
        _.set(clonedTerms, path, _.get(value, 'amount'));
        onTermsChange(clonedTerms, path)
    }

    const onValueBlur = (value, path) => {
        const clonedTerms = _.clone(terms);
        _.set(clonedTerms, path, value);
        if (!_.isEqual(path.beforeValue, path.value)) {
            handleTermsOnBlur(clonedTerms)
        }
    }

    const onCurrencyBlur = (value, path) => {
        const clonedTerms = _.clone(terms);
        _.set(clonedTerms, path, _.get(value, 'amount'));
        if (!_.isEqual(path.beforeValue.amount, path.value.amount)) {
            handleTermsOnBlur(clonedTerms)
        }
    }


    const termUIProps = terms
        .filter((term) => !privateRelatedStructureConfig.hiddenScheduleItemCodes.includes(term.code_Ext))
        .map((term, index) => {
            let termComponetUImetadata = {}
            const hasAvailableValues = _.has(term, 'options') && !_.isEmpty(term.options);
            const valueType = _.has(term, 'valueType') && term.valueType;

            const availableValues = hasAvailableValues && term.options.filter((option) => {
                // Filtering out the None Selected value as the backend sets the code value
                // for None Selected to be an empty string. This results in the option not
                // showing in the dropdown select component. We are handling this by using
                // None Selected as a placeholder which will always be shown to the user
                return option.code;
            }).map((option) => {
                return {
                    code: '',
                    id: '',
                    ...option
                };
            });
            const onlyOneAvailableValue = hasAvailableValues && availableValues.length === 1;

            if (!hasAvailableValues && valueType === 'Money') {
                termComponetUImetadata = {
                    component: "Currency",
                    componentProps: {
                        label: term.name,
                        labelPosition: "left",
                        value: {
                            amount: term.directValue,
                            currency: "USD"
                        },
                        onValueChange: onCurrencyChange,
                        onBlur: onCurrencyBlur,
                        maxValue: term.directValueMax,
                        minValue: term.directValueMin,
                        path: `[${index}].directValue`,
                        required: term.required,
                    }
                }
            }
            if (valueType === 'shorttext') {
                termComponetUImetadata = {
                    component: 'textarea',
                    componentProps: {
                        label: term.name,
                        labelPosition: "left",
                        onValueChange: onValueChange,
                        onBlur: onValueBlur,
                        path: `[${index}].directStringValue`,
                        value: term.directStringValue,
                        required: term.required,
                        // placeholder: term.required ? '-- Required for Quote --' : '',
                        placeholder: '',
                    }
                };
            }
            if (hasAvailableValues && !valueType) {
                termComponetUImetadata = {
                    component: 'dropdownselect',
                    componentProps: {
                        label: term.name,
                        labelPosition: "left",
                        availableValues,
                        path: `[${index}].chosenTerm`,
                        value: term.chosenTerm,
                        onValueChange: onValueChange,
                        readOnly: onlyOneAvailableValue || term.readOnly_Ext || readOnly,
                        required: term.required,
                        // placeholder: term.required ? '-- Required for Quote --' : '',
                        placeholder: '',
                    }
                };

            }
            const commonComponentProps = {
                label: term.name,
                labelPosition: "left",
                required: true,
                showRequired: true,
                readOnly: readOnly || term.readOnly_Ext,
                showErrors,
            }
            const componentProps = _.get(termComponetUImetadata, 'componentProps')
            _.set(termComponetUImetadata, 'componentProps', {...commonComponentProps, ...componentProps})
            return termComponetUImetadata;
        })

    const uiProps = useMemo(() => {
        return {
            id: "OtherStructureDetailsComponent",
            type: "container",
            component: "fragment",
            content: termUIProps
        }
    }, [termUIProps]);

    // const isMetadataValid = useCallback(() => {
    //     return validateContentFromMetadata(uiProps);
    // }, [uiProps]);

    // useEffect(() => {
    //     registerComponentValidation(isMetadataValid);
    // }, [isMetadataValid, registerComponentValidation]);

    const overrideProps = {
        '@field': {
            showErrors
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        }
    }

    return <MetadataContent uiProps={uiProps} overrideProps={overrideProps} {...resolvers} />;
}

StructureCoverageTermsComponent.propTypes = {
    terms: PropTypes.array,
    readOnly: PropTypes.bool,
};

StructureCoverageTermsComponent.defaultProps = {
    terms: [],
    readOnly: false,
};

export default StructureCoverageTermsComponent;