import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPATheftDetailsPage } from 'wni-capability-fnol-pa-react';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLCATheftDetailsPage(props) {
    return <FNOLPATheftDetailsPage {...props} />;

}

FNOLCATheftDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLCATheftDetailsPage);
