import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { IMMsicFormsService } from 'wni-capability-quoteandbind-im';
import { WniCheckboxField, WniDataTable } from 'wni-common-base-components';
import IMScheduleItemReadonlyCell from '../../../../../components/IMScheduleTable/IMScheduleItemReadonlyCell';
import CoveredPremisesScheduleItemForm from '../CoveredPremisesScheduleItemForm';
// import ScheduleItemForm from '../CoveredPremisesScheduleItemForm';
import messages from './CoveredPremisesSchedule.messages';
import IMCoverageUtil from '../../../../../util/IMCoverageUtil';
import { DirectCoverageFieldIds, LegalLiabilityFieldIds } from '../config';


const CoveredPremisesSchedule = (props) => {
    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { authHeader } = useAuthentication();

    const {
        clause,
        isEditable,
        setCoveragePartClauses,
        jobID,
        sessionUUID,
        setIsEditing,
        isEditing,
        onValidate: onParentValidate,
        showErrors,
    } = props;

    const { schedule } = clause;

    const {
        propertyInfos = [],
        coveragePropertyInfos = [],
        scheduleItems = [],
        deserializationClass,
    } = schedule;

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] =
        useState([]);
    const [openedScheduleItem, setOpenedScheduleItem] = useState();

    const validationID = 'coverdPremisedSchedule'

    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(validationID);

    const isCoveredPremisesScheduleValid = useMemo(() => {
        const invalidScheduleItem = _.find(scheduleItems, (scheduleItem) => {
            const terms = _.get(scheduleItem, 'scheduleItemTerms', [])
            if (IMCoverageUtil.isScheduleItemInvalid(scheduleItem, propertyInfos)) {
                return true
            }

            const coverageType = terms.find(
                (t) =>
                    t.code_Ext === 'IMColdStorageLockerCoverageScheduleCoverage'
            );
            const coverageTypeCode = _.get(coverageType, 'chosenTerm');

            if (_.isNil(coverageTypeCode)) {
                return false
            }

            const termsToValid = terms.filter(term => {
                if (LegalLiabilityFieldIds.includes(term.code_Ext)) {
                    return coverageTypeCode === 'LegalLiabilityLimit';
                }
                if (DirectCoverageFieldIds.includes(term.code_Ext)) {
                    return coverageTypeCode === 'DirectCoverageLimit';
                }
                return true
            })

            return termsToValid.some(term => IMCoverageUtil.isTermInvalid(term))
        })
        if (_.isNil(invalidScheduleItem)) {
            return true
        }
        return false
    }, [propertyInfos, scheduleItems])

    const isScheduleValid = () => {
        return isCoveredPremisesScheduleValid && _.isNil(openedScheduleItem)
    }

    useEffect(() => registerComponentValidation(isScheduleValid))

    useEffect(() => {
        if (onParentValidate) {
            onParentValidate(isComponentValid, validationID);
        }
        return () => {
            if (onParentValidate) {
                onParentValidate(true, validationID);
            }
        };
    })

    const sortedScheduleItems = scheduleItems.toSorted(
        (scheduleItemA, scheduleItemB) => {
            const getScheduleNumber = (scheduleItem) => {
                return _.get(scheduleItem, 'itemNumber');
            };
            return (
                getScheduleNumber(scheduleItemA) -
                getScheduleNumber(scheduleItemB)
            );
        }
    );

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const saveScheduleItems = async (newScheduleItems) => {
        const scheduleRequest = _.clone(schedule);
        _.set(scheduleRequest, 'updated_Ext', true);
        _.set(scheduleRequest, 'scheduleItems', newScheduleItems);
        const newColdStorageLockerCoverage = await withLoadingMask(() =>
            IMMsicFormsService.updateColdStorageLockerCoverage(
                jobID,
                sessionUUID,
                { coveredPremises: { ...clause, schedule: scheduleRequest } },
                authHeader
            )
        );
        setCoveragePartClauses(newColdStorageLockerCoverage);
        const {
            coveredPremises: {
                schedule: { scheduleItems: newScheduleItemsRes },
            },
        } = newColdStorageLockerCoverage;
        return newScheduleItemsRes;
    };

    const delSchedule = async () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const { itemNumber } = scheduleItem;
            return !selectedScheduleItemNumbers.includes(itemNumber);
        });
        await saveScheduleItems(newScheduleItems);
        setSelectedScheduleItemNumbers([]);
        setOpenedScheduleItem(undefined);
    };

    const addSchedule = async () => {
        const newScheduleItems = scheduleItems.concat([
            {
                '@deserialization-class': deserializationClass,
                itemData: {},
            },
        ]);
        const oldScheduleItemPublicIds = scheduleItems.map(
            (item) => item.publicId_Ext
        );
        const newScheduleItemsRes = await saveScheduleItems(newScheduleItems);
        const newAddedItem = newScheduleItemsRes.find(
            (item) => !oldScheduleItemPublicIds.includes(item.publicId_Ext)
        );
        setOpenedScheduleItem(newAddedItem);
    };

    const handleScheduleItemCancel = () => setOpenedScheduleItem(undefined);

    const handleSaveScheduleItem = async (newScheduleItem) => {
        const { publicId_Ext: publicId } = newScheduleItem;

        const newScheduleItems = scheduleItems.map((scheduleItem) => {
            if (scheduleItem.publicId_Ext === publicId) {
                return newScheduleItem;
            }
            return scheduleItem;
        });
        await saveScheduleItems(newScheduleItems);
        setOpenedScheduleItem(undefined);
    };

    const scheduleNumberColumn = (
        <DisplayColumn
            key="ScheduleNumber"
            header="Schedule Number"
            sortable={false}
            textAlign="left"
            path="itemNumber"
            renderCell={(scheduleItem) => {
                const { itemNumber } = scheduleItem;
                if (!isEditable) {
                    return itemNumber;
                }
                return (
                    <WniCheckboxField
                        value={selectedScheduleItemNumbers.includes(itemNumber)}
                        label={itemNumber}
                        showInlineLabel
                        onValueChange={(checked) => {
                            let newSelectedScheduleItemNumbers;
                            if (checked) {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.concat([
                                        itemNumber,
                                    ]);
                            } else {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.filter(
                                        (num) => num !== itemNumber
                                    );
                            }
                            setSelectedScheduleItemNumbers(
                                newSelectedScheduleItemNumbers
                            );
                        }}
                    />
                );
            }}
        />
    );

    const propertyInfoColumns = propertyInfos.map((propertyInfo) => {
        const { id, label } = propertyInfo;

        return (
            <DisplayColumn
                key={id}
                header={label}
                renderCell={(scheduleItem) => {
                    return (
                        <IMScheduleItemReadonlyCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                        />
                    );
                }}
                sortable={false}
            />
        );
    });

    const termPropertyColumns = coveragePropertyInfos
        .filter(
            (termPropertyInfo) =>
                termPropertyInfo.id !==
                'IMColdStorageLockerCoverageScheduleLocation'
        )
        .sort((a, b) => a.order - b.order)
        .map((coveragePropertyInfo) => {
            const { id, label } = coveragePropertyInfo;

            return (
                <DisplayColumn
                    key={id}
                    header={label}
                    renderCell={(scheduleItem) => {
                        const coverageType =
                            scheduleItem.scheduleItemTerms.find(
                                (t) =>
                                    t.code_Ext ===
                                    'IMColdStorageLockerCoverageScheduleCoverage'
                            );
                        const coverageTypeCode = _.get(
                            coverageType,
                            'chosenTerm'
                        );

                        if (
                            LegalLiabilityFieldIds.includes(
                                coveragePropertyInfo.id
                            )
                        ) {
                            if (coverageTypeCode !== 'LegalLiabilityLimit') {
                                return null;
                            }
                        }
                        if (
                            DirectCoverageFieldIds.includes(
                                coveragePropertyInfo.id
                            )
                        ) {
                            if (coverageTypeCode !== 'DirectCoverageLimit') {
                                return null;
                            }
                        }

                        const { scheduleItemTerms } = scheduleItem;
                        const term = scheduleItemTerms.find(
                            (t) => t.code_Ext === id
                        );

                        return _.get(term, 'chosenTermValue');
                    }}
                    sortable={false}
                />
            );
        });

    const actionColumn = (
        <DisplayColumn
            key="ActionColumn"
            sortable={false}
            textAlign="left"
            renderCell={(scheduleItem) => {
                return (
                    <Button
                        className="btn-link"
                        onClick={() => setOpenedScheduleItem(scheduleItem)}
                        label={`${translator(
                            messages.scheduleEdit
                        )}/${translator(messages.scheduleView)}`}
                    />
                );
            }}
        />
    );

    const columns = [
        scheduleNumberColumn,
        ...propertyInfoColumns,
        ...termPropertyColumns,
    ];
    const dataTableColumns = isEditable ? [...columns, actionColumn] : columns;

    return (
        <>
            {isEditable && (
                <Flex gap="small" justifyContent="right" className="mb-10">
                    <Button
                        className="wni-button-danger"
                        disabled={selectedScheduleItemNumbers.length === 0}
                        onClick={delSchedule}
                        label={translator(messages.scheduleDel)}
                    />

                    <Button
                        icon="gw-add"
                        onClick={addSchedule}
                        label={translator(messages.scheduleAdd)}
                    />
                </Flex>
            )}
            <div className="table-wrapper mb-10">
                <WniDataTable
                    id="clause_schedule_table_IMMiscFormsCoveredPremisesScheduledCov_Ext"
                    data={sortedScheduleItems}
                    showSearch={false}
                >
                    {sortedScheduleItems.length > 0 ? dataTableColumns : []}
                </WniDataTable>
            </div>
            {openedScheduleItem && (
                <>
                    <CoveredPremisesScheduleItemForm
                        propertyInfos={propertyInfos}
                        coveragePropertyInfos={coveragePropertyInfos}
                        scheduleItem={openedScheduleItem}
                        handleScheduleItemCancel={handleScheduleItemCancel}
                        handleSaveScheduleItem={handleSaveScheduleItem}
                        isEditable={isEditable}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        onValidate={onValidate}
                    />
                    {showErrors && <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.itemFormNotClosedMessage)}</div>}
                </>
            )}
            {(!isCoveredPremisesScheduleValid && _.isNil(openedScheduleItem))?
                <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.scheduleItemInvalidMessage)}</div>
                : null}
        </>
    );
};

export default CoveredPremisesSchedule;
