import { defineMessages } from 'react-intl';

export default defineMessages({
    searchUnderlyingPolicies: {
        id: 'quoteandbind.cu.directives.templates.cu-edit.Search Underlying Policies',
        defaultMessage: 'Search Underlying Policies',
    },
    internalPolicies: {
        id: 'wni.quoteandbind.cu-wizard.cu-dlocationsrivers.Internal Policies',
        defaultMessage: 'Internal Policies',
    },
    externalPolicies: {
        id: 'wni.quoteandbind.cu-wizard.cu-dlocationsrivers.External Policies',
        defaultMessage: 'External Policies',
    },
    viewAndEditLabel: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.View',
        defaultMessage: 'View',
    },
    saveAndClose: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.Save & Close',
        defaultMessage: 'Save & Close',
    },
    close: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.Close',
        defaultMessage: 'Close',
    },
    uploadSuccess: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.Success',
        defaultMessage: 'Success',
    },
    uploadSuccessInfo: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.The declaration file has been uploaded successfully!',
        defaultMessage: 'The declaration file has been uploaded successfully!',
    },
    uploadError: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.Error',
        defaultMessage: 'Error',
    },
    uploadErrorInfo: {
        id: 'wni.quoteandbind.cu-wizard.cu-locations.The attempt to upload the declaration file was unsuccessful, Please try to upload again.',
        defaultMessage: 'The attempt to upload the declaration file was unsuccessful, Please try to upload again.',
    },
});
