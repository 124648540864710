import { defineMessages } from 'react-intl';

export default defineMessages({
    PCPAChangeConflicts: {
        id: 'pa.policychange.quote.Change Conflicts',
        defaultMessage: 'Change Conflicts'
    },
    PCPAErrorMsg: {
        id: 'pa.policychange.quote.PCPAErrorMsg',
        defaultMessage: 'There are out-of-sequence conflicts that must be resolved prior to quoting.'
    },
    PCPAWarnMsg1: {
        id: 'pa.policychange.quote.PCPAWarnMsg1',
        defaultMessage: 'Your policy change is an out-of-sequence transaction. There are future transaction(s) effective'
    },
    PCPAWarnMsg2: {
        id: 'pa.policychange.quote.PCPAWarnMsg2',
        defaultMessage: 'Same values specified in this policy transaction conflict with changes made with future effective date. Please indicate if changes should override future transaction(s) and then click submit.'
    },
    PCPAWarnMsg3: {
        id: 'pa.policychange.quote.PCPAWarnMsg3',
        defaultMessage: 'Warning: Submitted changes cannot be undone.'
    },
    PCPAWarnMsg4: {
        id: 'pa.policychange.quote.PCPAWarnMsg4',
        defaultMessage: 'Warning: Submitted changes cannot be undone. Are you sure you want to continue?'
    },
    PCPAOverrideFutureConflict: {
        id: 'pa.policychange.quote.Override Future Conflict(s)?',
        defaultMessage: 'Override Future Conflict(s)?'
    },
    PCPABtnYes: {
        id: 'pa.policychange.quote.PCPA.btn.Yes',
        defaultMessage: 'Yes'
    },
    PCPABtnNo: {
        id: 'pa.policychange.quote.PCPA.btn.No',
        defaultMessage: 'No'
    },
    ConflictSubmit: {
        id: 'pa.policychange.quote.PCPA.btn.Submit',
        defaultMessage: 'Submit'
    },
    ConflictTableItem: {
        id: 'pa.policychange.quote.PCPA.changeConflict.table.Item',
        defaultMessage: 'Item'
    },
    PolicyChangeEff: {
        id: 'pa.policychange.quote.PCPA.changeConflict.table.Policy Change Eff',
        defaultMessage: 'Policy Change Eff'
    },
    ConflictTableConflict: {
        id: 'pa.policychange.quote.PCPA.changeConflict.table.Conflict',
        defaultMessage: 'Conflict'
    },
    ConflictEffDate: {
        id: 'pa.policychange.quote.PCPA.changeConflict.table.Conflict Eff. Date',
        defaultMessage: 'Conflict Eff. Date'
    },
    PCPABtnCancel: {
        id: 'pa.policychange.quote.PCPA.btn.Cancel',
        defaultMessage: 'Cancel'
    },
    ConflictOk: {
        id: 'pa.policychange.quote.PCPA.btn.Ok',
        defaultMessage: 'Ok'
    },
    PCPAWarnMsg5: {
        id: 'pa.policychange.quote.PCPAWarnMsg5',
        defaultMessage: 'Warning: Submitted changes cannot be undone.'
    },
    PCPAWarnMsg6: {
        id: 'pa.policychange.quote.PCPAWarnMsg6',
        defaultMessage: 'Are you sure you want to continue?'
    },
});