import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PACoveragesPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeDriverService, WniPAPolicyChangeService, WniPolicyChangeCoverageService } from 'wni-capability-policychange';

const VehicleCoveragesService = {
    updateCoverages: WniPAPolicyChangeService.updateCoverages,
    copyCoverages: WniPAPolicyChangeService.copyVehicleCoverages,
};

function PACoveragesChangePage(props) {
    const { wizardData: submissionVM } = props;
    const {
        policyNumber,
        jobID,
        baseData: {
            effectiveDate
        }
    } = submissionVM.value;
    const { authHeader } = useAuthentication();

    // const getDriversCoverages = useCallback(async () => {
    //     const request = {
    //         jobID
    //     };
    //     const response = await WniPolicyChangeDriverService.getDriversCoverages(
    //         request,
    //         authHeader
    //     );
    //     return response;
    // }, []);

    // const updateDriverCoverages = useCallback(async (driverDTO, cov, scheduleItem) => {
    //     const request = {
    //         policyNumber,
    //         effectiveDate,
    //         jobID,
    //         driverDTO,
    //         cov,
    //         scheduleItem
    //     };
    //     const response = await WniPolicyChangeDriverService.updateAllCoverages(
    //         request,
    //         authHeader
    //     );
    //     return response;
    // }, []);

    const getErrorsAndWarnings = useCallback(async () => {
        const errorsAndWarnings = WniPolicyChangeCoverageService
            .getErrorsAndWarnings(jobID, authHeader);
        return errorsAndWarnings;
    }, [authHeader, jobID]);

    const updateCoveragesFunction = WniPAPolicyChangeService.updateCoverages;
    const { copyVehicleCoverages } = WniPAPolicyChangeService;

    const overrideProps = {
        ...props,
        policyChangeService: {
            getErrorsAndWarnings,
            updateCoveragesFunction,
            copyVehicleCoverages,
        },
    };


    return <PACoveragesPage {...overrideProps} />;
}

PACoveragesChangePage.propTypes = wizardProps;
export default PACoveragesChangePage;
