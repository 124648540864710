import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter, ModalHeader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { DataTable,DisplayColumn } from '@jutro/legacy/datatable';
import { Flex, FlexItem } from '@jutro/layout';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import { WniInputText, WniDropdownSelect, WniRadio, WniDataTable } from 'wni-common-base-components';
import messages from './SearchAndAddCoveragesPopup.messages';

import { Button } from '@jutro/legacy/components';

function SearchAndAddCoveragesPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        serviceProps,
        setLoadingMask,
        covType,
        isSubline,
        exposureID,
        exposureClassDescription,
        locationPublicID,
        jurisdictionCode,
        editableFieldsForPolicyChange
    } = props;

    const {
        jobID,
        sessionUUID,
        authHeader
    } = serviceProps;

    const translator = useTranslator();
    const [coverageCategory, updateCoverageCategory] = useState('all');
    const [searchKeyword, updateSearchKeyword] = useState(null);
    const [coverageType, updateCoverageType] = useState(covType);
    const [searchedCoveragesData, updateSearchedCoveragesData] = useState([]);
    const [selections, updateSelections] = useState([]);

    const availableTypeValues = [{id: 'conditions', displayName: 'Conditions'}, {id: 'exclusions', displayName: 'Exclusions'}, {id: 'exclusionsAndConditions', displayName: 'Exclusions And Conditions'}];
    
    const availableCategoryValues = useMemo(() => {
        if (covType === 'additionalCoverages') {
            if (isSubline) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.SublineAdditionalCoverageCategory)}];
            } 
            if (!_.isNil(exposureID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.ExposureAdditionalCoverageCategory)}];
            }
            if (!_.isNil(locationPublicID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.LocationAdditionalCoverageCategory)}];
            }
            if (!_.isNil(jurisdictionCode)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.StateSpecificAdditionalCoverageCategory)}];
            }
        } 
        if (covType === 'additionalInsureds') {
            if (isSubline) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.SublineAdditionalInsuredsCategory)}];
            }
            if (!_.isNil(exposureID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.ExposureAdditionalInsuredsCategory)}];
            }
            if (!_.isNil(locationPublicID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.LocationAdditionalInsuredsCategory)}];
            }
            if (!_.isNil(jurisdictionCode)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalInsureds', name: translator(messages.StateSpecificAdditionalInsuredsCategory)}];
            }
        } 
        if (covType === 'exclusionsAndConditions') {
            if (isSubline) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.SublineConditionsCategory)}, {code: 'exclusions', name: translator(messages.SublineExclusionsCategory)}];    
            }
            if (!_.isNil(exposureID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.ExposureConditionsCategory)}, {code: 'exclusions', name: translator(messages.ExposureExclusionsCategory)}]; 
            }
            if (!_.isNil(locationPublicID)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.LocationConditionsCategory)}, {code: 'exclusions', name: translator(messages.LocationExclusionsCategory)}];
            }
            if (!_.isNil(jurisdictionCode)) {
                return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.StateSpecificConditionsCategory)}, {code: 'exclusions', name: translator(messages.StateSpecificExclusionsCategory)}];
            }
        }
    }, [covType, exposureID, isSubline, jurisdictionCode, locationPublicID, translator])

    const coverageTypeTitle = useMemo(()=>{
        if (covType === 'additionalCoverages') {
            return translator(messages.coveragesPopupTitle);
        }
        if (covType === 'additionalInsureds') {
            return translator(messages.additionalInsuredsCoveragesPopupTitle);
        }
        return translator(messages.exclusionsAndConditionsPopupTitle);
    }, [covType, translator])

    const coverageButtonTypeTitle = useMemo(()=>{
        if (covType === 'additionalCoverages' || covType === 'additionalInsureds') {
            return translator(messages.coveragesPopupTitle);
        }
        return translator(messages.exclusionsAndConditionsPopupTitle);
    }, [covType, translator])

    const serchCoverages = useCallback(async() => {
        setLoadingMask(true);
        let searchResults;
        if (!_.isNil(exposureID)) {
            searchResults = await GLCoverageService.searchExposureCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, exposureID, authHeader);
        } else if (!_.isNil(locationPublicID)) {
            searchResults = await GLCoverageService.searchLocationCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, locationPublicID, authHeader);
        } else if (!_.isNil(jurisdictionCode)) {
            searchResults = await GLCoverageService.searchStateSpecificCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, jurisdictionCode, authHeader);
        } else {
            const results = await GLCoverageService.searchSublineCoverages(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, authHeader);
            if (!_.isEmpty(editableFieldsForPolicyChange) && (covType === 'exclusionsAndConditions')) {
                searchResults = results?.filter(elt => editableFieldsForPolicyChange.includes(elt.patterncode));
            } else {
                searchResults = results;
            }
            
        }
        updateSearchedCoveragesData(searchResults);
        setLoadingMask(false);
    }, [authHeader, covType, coverageCategory, coverageType, editableFieldsForPolicyChange, exposureID, jobID, jurisdictionCode, locationPublicID, searchKeyword, sessionUUID, setLoadingMask])

    const addSelectedCoverage = useCallback(async()=>{
        setLoadingMask(true);
        let res;
        const clauseSpecData = searchedCoveragesData.filter(cov => selections.includes(searchedCoveragesData.indexOf(cov)));
        if (!_.isNil(exposureID)) {
            res = await GLCoverageService.addSearchedExposureCoverages(jobID, sessionUUID, clauseSpecData, exposureID, authHeader);
        } else if (!_.isNil(locationPublicID)) {
            res = await GLCoverageService.addSearchedLocationCoverages(jobID, sessionUUID, clauseSpecData, locationPublicID, authHeader);
        } else if (!_.isNil(jurisdictionCode)) {
            res = await GLCoverageService.addSearchedStateSpecificCoverages(jobID, sessionUUID, clauseSpecData, jurisdictionCode, authHeader);
        } else {
            res = await GLCoverageService.addSearchedSublineCoverages(jobID, sessionUUID, clauseSpecData, authHeader);
        }
        setLoadingMask(false);
        return res;
    }, [authHeader, exposureID, jobID, jurisdictionCode, locationPublicID, searchedCoveragesData, selections, sessionUUID, setLoadingMask])

    const resetChoice = () => {
        updateCoverageCategory('all');
        updateSearchKeyword(null);
        updateCoverageType(covType);
        updateSearchedCoveragesData([]);
    }

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader
                title={(
                        <div>
                            {(isSubline || !_.isNil(locationPublicID) || !_.isNil(jurisdictionCode)) && <h3>{`Search ${coverageTypeTitle} for General Liability`}</h3>}  
                            {!_.isNil(exposureID) && <h3>{`Search ${coverageTypeTitle} for ${exposureClassDescription}`}</h3>}
                        </div>
                    )}
            />
                        
            <ModalBody id="coverageContactSearchComponent" autoFocus={false}>
                <WniDropdownSelect
                    id="coverage_category"
                    label="Category"
                    labelPosition="left"
                    alwaysShowPlaceholder={false}
                    availableValues={availableCategoryValues}
                    onValueChange={(value) => updateCoverageCategory(value)}
                    value={coverageCategory}
                />
                <WniInputText
                    id="coverage_keyword"
                    label="Keyword"
                    labelPosition="left"
                    onValueChange={(value) => updateSearchKeyword(value)}
                    value={searchKeyword}
                />
                <WniRadio
                    id="coverage_type"
                    label="Type"
                    labelPosition="left"
                    availableValues={availableTypeValues}
                    showRequired
                    required
                    onValueChange={(value) => updateCoverageType(value)}
                    visible={covType === 'exclusionsAndConditions'}
                    value={coverageType}
                />
                <Flex justifyContent="left" className="mb-15">
                    <FlexItem><Button onClick={serchCoverages}>Search</Button></FlexItem>
                    <FlexItem><Button onClick={resetChoice} type="outlined">Reset</Button></FlexItem>
                </Flex>
                <WniDataTable
                    columnsConfigurable={false}
                    data={searchedCoveragesData}
                    defaultConfig={{
                        // filterValue: '',
                        // page: 0,
                        pageSize: 5
                    }}
                    pageSizeOptions={[5]}
                    id="basic"
                    showPagination
                    showSearch={false}
                    selectionType="multi"
                    onSelectionChange={(indexs) => {
                        updateSelections(indexs)
                    }}
                    >
                    <DisplayColumn
                        header="Coverage"
                        id="coverage"
                        path="patternDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
                    <DisplayColumn
                        header="Subline"
                        id="subline"
                        path="sublineDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={isSubline || !_.isNil(jurisdictionCode) || !_.isNil(locationPublicID)}
                    />
                    <DisplayColumn
                        header="Category"
                        id="category"
                        path="categoryDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
                    <DisplayColumn
                        header="Type"
                        id="type"
                        path="clauseType"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
            </WniDataTable>
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "right",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject()}} type="outlined">Cancel</Button>
                <Button onClick={()=>{onResolve(addSelectedCoverage())}} disabled={_.isEmpty(selections)}>Add Selected {coverageButtonTypeTitle}</Button>
            </ModalFooter>
        </ModalNext>
    );
}
SearchAndAddCoveragesPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    isSubline: PropTypes.bool,
    exposureID: PropTypes.string,
    exposureClassDescription: PropTypes.string,
    locationPublicID: PropTypes.string,
    jurisdictionCode: PropTypes.string
};

SearchAndAddCoveragesPopup.defaultProps = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    isSubline: false,
    exposureClassDescription: 'Exposure'
};

export default SearchAndAddCoveragesPopup;
