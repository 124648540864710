import _ from 'lodash';
import {
    WniClausesUtil,
} from 'wni-portals-util-js';

function getPathToTerm(changedPath) {
    // onBlur event returns an object instead of path as a String
    const path = _.isString(changedPath) ? changedPath : changedPath.model;
    const matched = /(.*.terms(.children)?\[\d+\])(.*$)/.exec(path);
    if (!matched) {
        return changedPath;
    }
    const [, termPath] = matched;
    return termPath;
}

// This is the last part of path, such as dateValue stringValue
function getScheduleItemDataRelativePath(scheduleValuePath) {
    const regex = /([^.]\w+)$/g
    // There should be only one path in array
    const matchedPaths =  scheduleValuePath.match(regex)
    if (_.get(matchedPaths, 'length') === 1) {
        return matchedPaths[0]
    }
    return null
}

function getScheduleItemDataBasePath(scheduleValuePath) {
    const regex = /(\.\w+)$/g
    return scheduleValuePath.replace(regex, '')
}

function getValuePathByVMPath(VMPath) {
    return VMPath.replaceAll('.children', '')
}

function setValue(coverages, pathToCoverages, value) {
    // Currency field
    if (_.get(value, 'currency')) {
        _.set(coverages, pathToCoverages, _.get(value, 'amount'));
    } else {
        _.set(coverages, pathToCoverages, value);
    }
    return coverages
}

function setValueToCoverages(coverages, value, path) {
    const pathToCoverages = getValuePathByVMPath(path)
    const pathToChangedCoverage = pathToCoverages.substring(0, 3);
    coverages = setValue(coverages, pathToCoverages, value);
    if (path.includes('.terms')) {
        const pathToTerm = getPathToTerm(pathToCoverages);
        _.set(coverages, `${pathToTerm}.updated`, true);
    } else if (path.includes('.schedule')) {
        _.set(coverages, `${pathToChangedCoverage}.schedule.updated_Ext`, true);
    } else {
        _.set(coverages, `${pathToChangedCoverage}.updated`, true);
    }
    return coverages.concat([]);
}

function setValueToCoverage(coverage, value, path) {
    const pathToCoverage = getValuePathByVMPath(path)
    coverage = setValue(coverage, pathToCoverage, value);
    if (path.startsWith('terms')) {
        const pathToTerm = pathToCoverage.substring(0, 8);
        _.set(coverage, `${pathToTerm}.updated`, true);
    } else if (path.startsWith('schedule')) {
        _.set(coverage, `schedule.updated_Ext`, true);
    } else {
        _.set(coverage, `updated`, true);
    }
    return coverage;
}

function setClauseValue(coverages, coveragesPath, value, path) {
    const changedValuePathToCoverages = path.replace(coveragesPath, '');
    return setValueToCoverages(coverages, value, changedValuePathToCoverages);
}

function selectCoveragesBySelectedCoverageCodes(coverages, selectedCoverageCodes) {
    const res = selectedCoverageCodes.reduce((updatedCoverages, selectedCoverageCode) => {
        return updatedCoverages.map((updatedCoverage) => {
            if (_.get(updatedCoverage, 'code_Ext') === selectedCoverageCode) {
                _.set(updatedCoverage, 'selected', true)
                _.set(updatedCoverage, 'updated', true)
            }
            return updatedCoverage
        })
    }, coverages)
    return res
}

function generateUpdatedCoveragesDTO(coverages, lobName) {
    const lineCoverages = _.get(coverages, 'lineCoverages', []);
    const lineCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(lineCoverages)

    const lineDetails = _.get(coverages, 'lineDetails', [])
    const lineDetailsToUpdate = lineDetails.filter((displayable) => displayable.updated)

    const lineAdditionalCoverages = _.get(coverages, 'lineAdditionalCoverages', []);
    const lineAdditionalCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(lineAdditionalCoverages)

    const lineExclusions = _.get(coverages, 'lineExclusions', []);
    const lineExclusionsToUpdate = WniClausesUtil.filterUnchangedClauses(lineExclusions)

    const lineConditions = _.get(coverages, 'lineConditions', []);
    const lineConditionsToUpdate = WniClausesUtil.filterUnchangedClauses(lineConditions)

    const allCAVehiclesCoverages = _.get(coverages, 'vehiclesCoverages', [])
    const allCAVehiclesCoveragesToUpdate = []
    allCAVehiclesCoverages.forEach((caVehCoverageDTO) => {
        const caVehCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(_.get(caVehCoverageDTO, 'coverages', []))
        if (!_.isEmpty(caVehCoveragesToUpdate)) {
            allCAVehiclesCoveragesToUpdate.push({
                ...caVehCoverageDTO,
                coverages: caVehCoveragesToUpdate,
            })
        }
    })

    const allStateSpecificCoverages = _.get(coverages, 'stateSpecificCoverages', [])
    const allStateSpecificCoveragesToUpdate = allStateSpecificCoverages.reduce((accUpdatedStateSpecificCoverages, stateSpecificCoverages) => {
        const stateDetails = _.get(stateSpecificCoverages, 'stateDetails', [])
        
        
        const stateStandardCovsPart1 = _.get(stateSpecificCoverages, 'stateStandardCovsPart1', [])
        const stateStandardCovsPart2 = _.get(stateSpecificCoverages, 'stateStandardCovsPart2', [])
        const stateAdditionalCovs = _.get(stateSpecificCoverages, 'stateAdditionalCovs', [])
        

        const stateDetailsToUpdate = stateDetails.filter((displayable) => displayable.updated)
        const stateRatingPeriodsCovsToUpdate =  _.get(stateSpecificCoverages, 'ratingPeriodsCovs', []).reduce((allRatingPeriodsCoverages, ratingPeriod)=>{
            const ratingPeriodCovs = _.get(ratingPeriod, 'coverages', [])
            const ratingPeriodCovsToUpdate = WniClausesUtil.filterUnchangedClauses(ratingPeriodCovs)
            const updateRatingCoverageDTO = {}
            if (!_.isEmpty(ratingPeriodCovsToUpdate)) {
                _.set(updateRatingCoverageDTO, 'coverages', ratingPeriodCovsToUpdate)
            }
            if (!_.isEmpty(updateRatingCoverageDTO) && !_.isNil(ratingPeriod.number)) {
                const ratingPeriodNumber = ratingPeriod.number
                _.set(updateRatingCoverageDTO, 'number', ratingPeriodNumber)
                allRatingPeriodsCoverages.push(updateRatingCoverageDTO)
            }
            return allRatingPeriodsCoverages
        },[])
        const stateStandardCovsPart1ToUpdate = WniClausesUtil.filterUnchangedClauses(stateStandardCovsPart1)
        const stateStandardCovsPart2ToUpdate = WniClausesUtil.filterUnchangedClauses(stateStandardCovsPart2)
        const stateAdditionalCovsToUpdate = WniClausesUtil.filterUnchangedClauses(stateAdditionalCovs)
        const employeeCoveragesToUpdate = _.get(stateSpecificCoverages, 'stateEmployees', []).reduce((allUpdatedEmployeeCoverages, employee)=>{
            const employeeSplittableCoverages = _.get(employee, 'employeeSplittableCoverages', [])
            const employeeStandardCoverages = _.get(employee, 'employeeStandardCoverages', [])
            const employeeSplittableCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(employeeSplittableCoverages)
            const employeeStandardCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(employeeStandardCoverages)
            const updateEmployeeCoverageDTO = {}
            if (!_.isEmpty(employeeSplittableCoveragesToUpdate)) {
                _.set(updateEmployeeCoverageDTO, 'employeeSplittableCoverages', employeeSplittableCoveragesToUpdate)
            }
            if (!_.isEmpty(employeeStandardCoveragesToUpdate)) {
                _.set(updateEmployeeCoverageDTO, 'employeeStandardCoverages', employeeStandardCoveragesToUpdate)
            }
            if (!_.isEmpty(updateEmployeeCoverageDTO) && !_.isNil(employee.publicID)) {
                const employeePublicID = employee.publicID
                _.set(updateEmployeeCoverageDTO, 'publicID', employeePublicID)
                allUpdatedEmployeeCoverages.push(updateEmployeeCoverageDTO)
            }
            return allUpdatedEmployeeCoverages
        },[])
        const updatedStateSpecificCoverageDTO = {}
        if (!_.isEmpty(stateDetailsToUpdate)) {
            _.set(updatedStateSpecificCoverageDTO, 'stateDetails', stateDetailsToUpdate)
        }
        if (!_.isEmpty(stateRatingPeriodsCovsToUpdate)) {
            _.set(updatedStateSpecificCoverageDTO, 'ratingPeriodsCovs', stateRatingPeriodsCovsToUpdate)
        }
        if (!_.isEmpty(stateStandardCovsPart1ToUpdate)) {
            _.set(updatedStateSpecificCoverageDTO, 'stateStandardCovsPart1', stateStandardCovsPart1ToUpdate)
        }
        if (!_.isEmpty(stateStandardCovsPart2ToUpdate)) {
            _.set(updatedStateSpecificCoverageDTO, 'stateStandardCovsPart2', stateStandardCovsPart2ToUpdate)
        }
        if (!_.isEmpty(stateAdditionalCovsToUpdate)) {
            _.set(updatedStateSpecificCoverageDTO, 'stateAdditionalCovs', stateAdditionalCovsToUpdate)
        }
        if (!_.isEmpty(employeeCoveragesToUpdate)) {
            _.set(updatedStateSpecificCoverageDTO, 'stateEmployees', employeeCoveragesToUpdate)
        }

        if (!_.isEmpty(updatedStateSpecificCoverageDTO)) {
            const state = _.get(stateSpecificCoverages, 'state')
            _.set(updatedStateSpecificCoverageDTO, 'state', state)
            accUpdatedStateSpecificCoverages.push(updatedStateSpecificCoverageDTO)
        }

        return accUpdatedStateSpecificCoverages
    }, [])

    const coveragesDTO = {};
    _.set(coveragesDTO, 'lineCoverages', lineCoveragesToUpdate);
    if (lineDetailsToUpdate.length > 0) {
        _.set(coveragesDTO, 'lineDetails', lineDetailsToUpdate);
    }
    _.set(coveragesDTO, 'lineAdditionalCoverages', lineAdditionalCoveragesToUpdate);
    _.set(coveragesDTO, 'lineExclusions', lineExclusionsToUpdate);
    _.set(coveragesDTO, 'lineConditions', lineConditionsToUpdate);
    if (!_.isEmpty(allCAVehiclesCoveragesToUpdate)) {
        _.set(coveragesDTO, 'vehiclesCoverages', allCAVehiclesCoveragesToUpdate);
    }
    if (!_.isEmpty(allStateSpecificCoveragesToUpdate)) {
        _.set(coveragesDTO, 'stateSpecificCoverages', allStateSpecificCoveragesToUpdate)
    }
    
    return {
        [lobName]: coveragesDTO
    }
}

function isClauseToUpdateEmpty(clausesToUpdate, lobName) {
    const lobCoverages = _.get(clausesToUpdate, lobName);
    return _.isEmpty(lobCoverages)
}

const CoverableType = {
    CA7StatePeriodClauses: 'ca7StatePeriodClauses'
}

function getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName) {
    const lobCoverages = _.get(clausesToUpdate, lobName);
    const lineCoveragesCodes = _.get(lobCoverages, 'lineCoverages', []).map((coverageDTO) => coverageDTO.code_Ext);
    const lineDetailsPropertyNames = _.get(lobCoverages, 'lineDetails', []).map((displayable) => displayable.propertyName)
    const lineAdditionalCoveragesCodes = _.get(lobCoverages, 'lineAdditionalCoverages', []).map((coverageDTO) => coverageDTO.code_Ext);
    const lineExclusionsCodes = _.get(lobCoverages, 'lineExclusions', []).map((coverageDTO) => coverageDTO.code_Ext);
    const lineConditionsCodes = _.get(lobCoverages, 'lineConditions', []).map((coverageDTO) => coverageDTO.code_Ext);
    const stateCoveragesCodes = _.get(lobCoverages, 'stateSpecificCoverages', [])
        .reduce((acc, stateSpecificCoverageDTO) => {
            return _.uniq(
                Object.keys(stateSpecificCoverageDTO)
                    .filter(key => key !== 'state' && key !== 'stateEmployees')
                    .reduce((allUpdatedCovCodesInDTO, key) => {
                        if (key === 'stateDetails') {
                            const stateDetailsPropertyNames = _.get(stateSpecificCoverageDTO, key, []).map((displayable) => displayable.propertyName)
                            return allUpdatedCovCodesInDTO.concat(stateDetailsPropertyNames)
                        }
                        if (key === 'ratingPeriodsCovs') {
                            const ratingPeriodsCovs = _.get(stateSpecificCoverageDTO, key, [])
                                .reduce((sum, perRatingPeriodDTO) => {
                                    const perRatingPeriodUpdateCodes = _.get(perRatingPeriodDTO, 'coverages', []).map((covDTO) => covDTO.code_Ext)
                                    if (_.isEmpty(perRatingPeriodUpdateCodes)) {
                                        return sum
                                    }
                                    return {
                                        type: CoverableType.CA7StatePeriodClauses,
                                        number: _.get(perRatingPeriodDTO, 'number'),
                                        clauseCodes: perRatingPeriodUpdateCodes
                                    }
                                }, [])
                            return allUpdatedCovCodesInDTO.concat(ratingPeriodsCovs)

                        }
                        const coveragesCodes = _.get(stateSpecificCoverageDTO, key, []).map((covDTO) => covDTO.code_Ext)
                        return allUpdatedCovCodesInDTO.concat(coveragesCodes)
                    }, acc)
            )
        }, [])
    const employeeCoveragesCodes = _.get(lobCoverages, 'stateSpecificCoverages', []).reduce((acc, stateSpecificCoverageDTO) => {
        return _.uniq(
            Object.keys(stateSpecificCoverageDTO)
                .filter(key => key === 'stateEmployees')
                .reduce((allUpdatedCovCodesInDTO, key) => {
                    const employees = _.get(stateSpecificCoverageDTO, key, [])
                    const allemployeesCodes = employees.reduce((overall, employeeDTO)=>{
                        return Object.keys(employeeDTO).filter(employeeDTOkey => employeeDTOkey !==  'publicID' && employeeDTOkey !== 'employeeDisplayName')
                        .reduce((allCovCodes, currentKey)=>{
                            const covCodes = _.get(employeeDTO, currentKey, []).map(cov => cov.code_Ext)
                            return covCodes.concat(allCovCodes)                      
                        },[])
                    },[])
                    return allUpdatedCovCodesInDTO.concat(allemployeesCodes)
                }, acc)
        )
    }, [])
    return []
        .concat(lineCoveragesCodes)
        .concat(lineDetailsPropertyNames)
        .concat(lineAdditionalCoveragesCodes)
        .concat(lineExclusionsCodes)
        .concat(lineConditionsCodes)
        .concat(stateCoveragesCodes)
        .concat(employeeCoveragesCodes)
}

const isOptionTerm = (term) => {
    return _.isNil(_.get(term, 'valueType'))
}

const isTermHasOnlyOneOption = (term) => {
    const options = _.get(term, 'options', [])
    return isOptionTerm(term) && options.length === 1
}

const getOneOptionTermsInCoverage = (coverage) => {
    const terms = _.get(coverage, 'terms', []);
    const oneOptionTerms = terms.filter((term) => isTermHasOnlyOneOption(term));
    return oneOptionTerms;
}

const isChosenTermNull = (term) => {
    const chosenTerm = _.get(term, 'chosenTerm')
    if (_.isNil(chosenTerm)) {
        return true
    }
    const options = _.get(term, 'options', [])
    const optionCodes = options.map((option) => _.get(option, 'code'))
    if (optionCodes.includes(chosenTerm)) {
        return false
    }
    return true
}

const isCoverageHasOneOptionAndNullTerm = (coverage) => {
    const oneOptionTerms = getOneOptionTermsInCoverage(coverage);
    const oneOptionAndNullTerms = oneOptionTerms.filter((term) => isChosenTermNull(term))
    return _.get(oneOptionAndNullTerms, 'length') > 0;
}

const hasOneOptionAndNullTermInCoverages = (coverages) => {
    const coverageHasOneOptionAndNullTerm = coverages.filter((coverage) => {
        return isCoverageHasOneOptionAndNullTerm(coverage)
    })
    return _.get(coverageHasOneOptionAndNullTerm, 'length') > 0
}

const setValueToOneOptionAndNullTermToCoverages = (coverages) => {
    const updatedCoverages = coverages.map((coverage) => {
        if (!isCoverageHasOneOptionAndNullTerm(coverage)) {
            return coverage;
        }
        let updatedCoverage = _.clone(coverage);
        const terms = _.get(coverage, 'terms', []);
        const oneOptionAndNullTermCodes = terms
            .filter((term) => {
                const termHasOnlyOneOption = isTermHasOnlyOneOption(term);
                const isTermNull = isChosenTermNull(term)
                return termHasOnlyOneOption && isTermNull
            })
            .map((term) => {
                return _.get(term, 'code_Ext')
            });
        oneOptionAndNullTermCodes.forEach((termCode) => {
            const termIndex = terms.findIndex((term) => _.get(term, 'code_Ext') === termCode);
            const termToUpdate = terms[termIndex];
            const chosenTermValue = _.get(termToUpdate, 'options[0].code')
            updatedCoverage = setValueToCoverage(updatedCoverage, chosenTermValue, `terms[${termIndex}].chosenTerm`)

        })
        return updatedCoverage
    })
    return updatedCoverages
}

function isTermInvalid(term) {
    // Use chosenTerm instead of chosenTermValue.
    // chosenTermValue only update after vm is sent to PC
    // value field of date is directDateValue

    if (!_.isNil(term.directValue)) {
        const {
            directValueMax,
            directValueMin
        } = term
        if (!_.isNil(directValueMax) && term.directValue > directValueMax) {
            return true
        }
        if (!_.isNil(directValueMin) && term.directValue < directValueMin) {
            return true
        }
    }

    return term.required
        && !term.chosenTerm
        && !term.directDateValue
        && _.isNil(term.directValue)
        && _.isNil(term.directStringValue)
        && _.isNil(term.directBooleanValue);
}

function isScheduledItemDataInvalid(itemData, propertyInfo) {
    if (!_.get(propertyInfo, 'required')) {
        return false
    }
    if (!_.get(itemData, 'visible_Ext')) {
        return false
    }
    if (!_.get(itemData, 'editable_Ext')) {
        return false
    }
    const {
        dateValue,
        typeCodeValue,
        stringValue,
        booleanValue,
        bigDecimal,
        integerValue,
        localDate_Ext: localDateObj,
    } = itemData
    if (_.isNil(dateValue)
        && _.isNil(typeCodeValue)
        && _.isEmpty(stringValue)
        && _.isNil(booleanValue)
        && _.isNil(bigDecimal)
        && _.isNil(integerValue)
        && _.isNil(localDateObj)
    ) {
        return true
    }
    // if type is option, check if chosenTerm is still in options
    if (_.get(propertyInfo, 'valueType') === 'TYPEKEY') {
        const options = _.get(itemData, 'options_Ext', [])
        const optionCodes = options.map((option) => _.get(option, 'code'))
        if (!optionCodes.includes(typeCodeValue)) {
            return true
        }
    }
    return false
}

function isScheduleItemInvalid(scheduleItem, propertyInfos = []) {
    if (_.isNil(scheduleItem)) {
        return false
    }
    const itemData = _.get(scheduleItem, 'itemData');
    const invalidItem = Object.keys(itemData).find((key) => {
        const scheduleItemData = _.get(itemData, key)
        const propertyInfo = propertyInfos.find((item) => _.get(item, 'id') === key)
        return isScheduledItemDataInvalid(scheduleItemData, propertyInfo)
    })
    if (_.isNil(invalidItem)) {
        return false
    }
    return true
}

function isScheduleInvalid(schedule) {
    const propertyInfos = _.get(schedule, 'propertyInfos');
    const scheduleItems = _.get(schedule, 'scheduleItems');
    const invalidScheduleItem = _.find(scheduleItems, (scheduleItem) => {
        return isScheduleItemInvalid(scheduleItem, propertyInfos)
    })
    if (!_.isNil(invalidScheduleItem)) {
        return true
    }
    return false
}

function isCoverageInvalid(coverage) {
    if (coverage.required && !coverage.selected) {
        return true;
    }
    if (coverage.hasTerms) {
        const invalidTerm = _.find(coverage.terms, (term) => {
            return isTermInvalid(term)
        });
        if (!_.isEmpty(invalidTerm)) {
            return true;
        }
    }

    if (!_.isNil(coverage.schedule)) {
        if (isScheduleInvalid(coverage.schedule)) {
            return true
        }
    }
    return false;
}

function isOpenedScheduleOrStructureInValid(openedItemNumber, coverage) {
    if(_.isNil(openedItemNumber)) {
        return true
    }
    const opendedSchedule = _.get(coverage, 'schedule.scheduleItems', [])
        .find((item) => _.get(item, 'itemNumber') === openedItemNumber);
    
    const propertyInfos = _.get(coverage, 'schedule.propertyInfos');
    return isScheduleItemInvalid(opendedSchedule, propertyInfos)
}

function hasInvalidCoverage(coverages) {
    return _.find(coverages, (cov) => {
        return isCoverageInvalid(cov)
    });
}

const isStateEmployeeCoveragesValid = (stateEmployeeCoveragesDTO) => {
    const {
        employeeStandardCoverages = [],
        employeeSplittableCoverages = [],
    } = stateEmployeeCoveragesDTO


    return !hasInvalidCoverage(employeeStandardCoverages) && !hasInvalidCoverage(employeeSplittableCoverages)
}

const isStateRatingPeriodCoveragesValid = (stateRatingPeriodCoveragesDTO) => {
    const {
        coverages = [],
    } = stateRatingPeriodCoveragesDTO


    return !hasInvalidCoverage(coverages)
}

const isStateCoveragesValid = (stateCoveragesDTO) => {
    const {
        stateEmployees = [],
        ratingPeriodsCovs = [],
        stateStandardCovsPart1 = [],
        stateStandardCovsPart2 = [],
        stateAdditionalCovs = [],
        stateDetails = [],
    } = stateCoveragesDTO

    return stateEmployees.every(stateEmployeeCovsDTO => isStateEmployeeCoveragesValid(stateEmployeeCovsDTO))
        && ratingPeriodsCovs.every(ratingPeriodCovsDTO => isStateRatingPeriodCoveragesValid(ratingPeriodCovsDTO))
        && !hasInvalidCoverage(stateStandardCovsPart1)
        && !hasInvalidCoverage(stateStandardCovsPart2)
        && !hasInvalidCoverage(stateAdditionalCovs)
        && stateDetails.every(displayableDTO => displayableDTO.isValid)

}

const getVehicleDescription = (vehicle) => {

    const model = _.get(vehicle, 'model', '-')
    const otherModel = _.get(vehicle, 'otherModel', '-')

    const manufacturer = _.get(vehicle, 'manufacturer', '-')
    const otherManufacturer = _.get(vehicle, 'otherManufacturer', '-')
    // YEAR MAKE MODEL
    return `${_.get(vehicle, 'year', '-')} ${manufacturer === 'Other' ? otherManufacturer : manufacturer} ${model === 'Other' ? otherModel : model}`
}

export default {
    setValueToCoverages,
    setValueToCoverage,
    setClauseValue,
    generateUpdatedCoveragesDTO,
    isClauseToUpdateEmpty,
    selectCoveragesBySelectedCoverageCodes,
    getValuePathByVMPath,
    // shouldImmediatelySyncAfterValueChange,
    hasOneOptionAndNullTermInCoverages,
    setValueToOneOptionAndNullTermToCoverages,
    getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO,
    getScheduleItemDataBasePath,
    getScheduleItemDataRelativePath,
    getVehicleDescription,
    CoverableType,

    // ---- Functions of Validate Coverages ----
    
    isScheduleInvalid,
    isScheduledItemDataInvalid,
    isOpenedScheduleOrStructureInValid,
    isTermInvalid,
    isCoverageInvalid,
    hasInvalidCoverage,
    isStateEmployeeCoveragesValid,
    isStateCoveragesValid,
};