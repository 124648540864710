
import React from "react";
import { IMBuildingsAndLocationsReadOnlyPage } from "wni-capability-quoteandbind-im-react";
import { PolicyChangeUtil } from 'wni-portals-util-js';

function IMBuildingsAndLocationsChangeReadOnlyPage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('IMLine', submissionVM);
    return <IMBuildingsAndLocationsReadOnlyPage {...props} isPolicyChange={!isDisplayAsSubmission} isReadOnly />
};

export default IMBuildingsAndLocationsChangeReadOnlyPage;
