import { defineMessages } from 'react-intl';

export default defineMessages({
    StandardCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Special Class Business Income Coverages',
        defaultMessage: 'Special Class Business Income Coverages',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Special Class Business Income Additional Coverages',
        defaultMessage: 'Special Class Business Income Additional Coverages',
    },
    AddAdditionalCoverage: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Add Additional Coverages',
        defaultMessage: 'Add Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Special Class Business Income Exclusions and Conditions',
        defaultMessage: 'Special Class Business Income Exclusions and Conditions',
    },
    AddExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Add Exclusions and Conditions',
        defaultMessage: 'Add Exclusions and Conditions',
    },
    AdditionalInsured: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Special Class Business Income Additional Insured',
        defaultMessage: 'Special Class Business Income Additional Insured',
    },
    AddAdditionalInsured: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class Business Income.Add Additional Insured',
        defaultMessage: 'Add Additional Insured',
    }
});