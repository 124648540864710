import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { ClauseService } from 'gw-capability-policycommon';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { messages as changeMessages } from 'gw-capability-policychange-common-react';
import messages from './BOPPolicyChangeWizard.messages';
import wizardConfig from './config/bop-wizard-config.json5';
import styles from './BOPPolicyChangeWizard.module.scss';

function BOPPolicyChangeWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;
    const [toSkip, updateSkip] = useState(true);

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');
                return;
            }
            const {
                state: { policyNumber }
            } = location;
            let endorsementResponse = {};
            if (!policyNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                modalApi.showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push(`/contactAgent/${policyNumber}`);
                }, _.noop);
            };
            GatewayEndorsementService.loadEndorsement(policyNumber, authHeader)
                .then(async (response) => {
                    let submission = {};
                    endorsementResponse = response;
                    const resume = response.jobID != null;
                    if (!resume) {
                        endorsementResponse = await GatewayEndorsementService.saveEndorsement(
                            [response],
                            authHeader
                        );
                        updateSkip(false);
                    }
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(endorsementResponse, 'jobID'),
                        authHeader
                    );
                    submission = viewModelService.create(
                        endorsementResponse,
                        'pc',
                        'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                    );
                    _.set(submission, 'policyNumber', policyNumber);
                    setInitialSubmission(submission);
                    setIsLoading(false);
                })
                .catch(() => {
                    errorModalBox({
                        title: changeMessages.unableToLoadDraftPolicy,
                        message: changeMessages.somethingWentWrong,
                        status: 'warning',
                        icon: 'mi-error-outline',
                    });
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback(
        ({ wizardData, wizardSnapshot, param: nextPath = '' }) => {
            const status = _.get(wizardData.value, 'policyChange.status');
            if (status === 'Bound' || nextPath.startsWith('/change') || hasErrorOccurred) {
                return true;
            }
            return modalApi.showConfirm({
                title: commonMessages.wantToCancel,
                message: commonMessages.infoWillBeSavedAsDraft,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.yes,
                cancelButtonText: commonMessages.no
            }).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                setIsLoading(true);
                const { jobID } = wizardData.value;
                const notQuoted = _.get(wizardData.value, 'status') !== 'Quoted';
                const redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;
                if (notQuoted) {
                    GatewayEndorsementService.saveEndorsement([wizardSnapshot.value], authHeader)
                        .then(() => {
                            history.push(redirectLobPath);
                        })
                        .catch(() => {
                            setIsLoading(false);
                        });
                    return true;
                }
                return history.push(redirectLobPath);
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, history]
    );

    const handleError = useCallback(
        (error) => {
            const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
            const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const jobIDFromLocation = _.get(location, 'state.policyNumber');
            let redirectPath = `/contactAgent/${jobIDFromLocation}`;
            if (!_.isEmpty(jobIDFromWizard)) {
                redirectPath = `/change/${jobIDFromWizard}/summary`;
            }
            const state = {
                pathname: redirectPath,
                state: error
            };

            return modalApi.showAlert({
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [location]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.bopPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <DependencyProvider
                    value={{
                        LoadSaveService: GatewayEndorsementService
                    }}
                >
                    <Wizard
                        initialSteps={steps}
                        wizardTitle={title}
                        initialData={initialSubmission}
                        onCancel={handleOnCancel}
                        skipCompletedSteps={toSkip}
                        onPreviousModalProps={{
                            title: commonMessages.wantToJump,
                            message: commonMessages.wantToJumpMessage,
                            status: 'warning',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.yesModel,
                            cancelButtonText: commonMessages.cancelModel
                        }}
                    />
                </DependencyProvider>
            </ErrorBoundary>
        </div>
    );
}

BOPPolicyChangeWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withRouter(withAuthenticationContext(BOPPolicyChangeWizard));
