
import React from 'react';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { GLPolicyDetailsPage } from 'wni-capability-quoteandbind-gl-react';
import { CPPPolicyDetailsService } from 'wni-capability-quoteandbind-cpp'


function CPPPolicyDetailsPage(props) {

    const {
        // wizardData: submissionVM,
        policyDetailsService
    } = props;

    const CPPOverrideProps = {
    }

    return (
        <GLPolicyDetailsPage
            {...props}
            policyDetailsService = {policyDetailsService}
            savePolicyDetailsData = {policyDetailsService.savePolicyDetailsData}
            CPPOverrideProps = {CPPOverrideProps}
            lobName = 'commercialPackage'
        />
    );

}

CPPPolicyDetailsPage.propTypes = {
    ...wizardProps,
    policyDetailsService: PropTypes.object
};

CPPPolicyDetailsPage.defaultProps = {
    policyDetailsService: CPPPolicyDetailsService
};

export default CPPPolicyDetailsPage;
