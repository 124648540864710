import { Button } from '@jutro/components';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { WniCheckboxField, WniDataTable } from 'wni-common-base-components';
import CoverageConfigContext from '../../../context/IMCoveragesConfigContext';
import IMCoverageUtil from '../../../util/IMCoverageUtil';
import IMScheduleItemReadonlyCell from "../../IMScheduleTable/IMScheduleItemReadonlyCell";
// eslint-disable-next-line import/no-cycle
import ScheduleItemForm from '../CommonScheduleItemForm/CommonScheduleItemForm';
import messages from './CommonCoveragePartSchedule.messages';

const ColumnType = {
    PropertyInfo: 'PropertyInfo',
    CoveragePropertyInfo: 'CoveragePropertyInfo'
}

const CommonCoveragePartSchedule = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { authHeader } = useAuthentication();
    const translator = useTranslator();

    const {
        schedule,
        isEditable,
        setCoveragePartClauses,
        onValidate: onPageValidate,
        jobID,
        sessionUUID,
        setIsEditing,
        isEditing,
        updateScheduleService,
        updateAdditionalIntestSchedule,
        scheduleId,
        schedulePath,
        scheduleLable,
        scheduleFormHeader,
        visibleOfDivider = false,
        onChangeScheduleItem,
        stackOfOpenScheduleID,
        setStackOfOpenedScheduleID,
        scheduleContainer,
        saveButtonLabel,
        showErrors,
    } = props;

    const {
        propertyInfos = [],
        coveragePropertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
    } = schedule;

    const {
        scheduleConfig
    } = useContext(CoverageConfigContext)

    const visibleColumnIdsOverride = _.get(scheduleConfig, `visibleColumnIdsOverride.${scheduleId}`);
    const columnOrderOverride = _.get(scheduleConfig, `columnOrderOverride.${scheduleId}`)

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([]);
    const [openedScheduleItem, setOpenedScheduleItem] = useState();

    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(scheduleId);

    const isScheduleValid = useCallback(() => {
        return !IMCoverageUtil.isScheduleInvalid(schedule) && _.isNil(openedScheduleItem)
    }, [openedScheduleItem, schedule])

    useEffect(() => registerComponentValidation(isScheduleValid), [isScheduleValid, registerComponentValidation])

    useEffect(() => {
        if (!_.isNil(openedScheduleItem) && !_.isEmpty(scheduleItems)) {
            const newOpenScheduleItem = scheduleItems.find(elt => elt.publicId_Ext === openedScheduleItem.publicId_Ext);
            const clonedNewOpenScheduleItem = _.cloneDeep(newOpenScheduleItem);
            setOpenedScheduleItem(clonedNewOpenScheduleItem);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleItems])

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, scheduleId);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, scheduleId);
            }
        }
    }, [onPageValidate, isComponentValid, scheduleId])

    const sortedScheduleItems = scheduleItems.toSorted(
        (scheduleItemA, scheduleItemB) => {
            const getScheduleNumber = (scheduleItem) => {
                return _.get(scheduleItem, 'itemNumber');
            };
            return (
                getScheduleNumber(scheduleItemA) -
                getScheduleNumber(scheduleItemB)
            );
        }
    );

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const saveScheduleItems = async (newScheduleItems) => {
        const scheduleRequest = _.clone(schedule);
        _.set(scheduleRequest, 'updated_Ext', true);
        _.set(scheduleRequest, 'scheduleItems', newScheduleItems);
        const newCoveragePartCoverages = await withLoadingMask(() =>
            updateScheduleService(
                jobID,
                sessionUUID,
                scheduleRequest,
                authHeader
            )
        );
        setCoveragePartClauses(newCoveragePartCoverages);
        const newScheduleItemsRes = _.get(newCoveragePartCoverages, `${schedulePath}.scheduleItems`);
        
        return newScheduleItemsRes;
    };

    const delSchedule = async () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const { itemNumber } = scheduleItem;
            return !selectedScheduleItemNumbers.includes(itemNumber);
        });
        await saveScheduleItems(newScheduleItems);
        setSelectedScheduleItemNumbers([]);
        if (!_.isNil(openedScheduleItem)) {
            setStackOfOpenedScheduleID(stackOfOpenScheduleID.slice(0, stackOfOpenScheduleID.length - 1))
            setOpenedScheduleItem(undefined);
        }
        
        
    };

    const addSchedule = async () => {
        const newScheduleItems = scheduleItems.concat([
            {
                '@deserialization-class': deserializationClass,
                itemData: {},
            },
        ]);
        const oldScheduleItemPublicIds = scheduleItems.map(
            (item) => item.publicId_Ext
        );
        const newScheduleItemsRes = await saveScheduleItems(newScheduleItems);
        const newAddedItem = newScheduleItemsRes.find(
            (item) => !oldScheduleItemPublicIds.includes(item.publicId_Ext)
        );
        setOpenedScheduleItem(newAddedItem);
        if (_.isNil(openedScheduleItem)) {
            setStackOfOpenedScheduleID([...stackOfOpenScheduleID, scheduleId])
        }
        
    };

    const handleScheduleItemCancel = () => {
        setOpenedScheduleItem(undefined);
        setStackOfOpenedScheduleID(stackOfOpenScheduleID.slice(0, stackOfOpenScheduleID.length - 1))
    }

    const handleSaveScheduleItem = async (newScheduleItem) => {
        const { publicId_Ext: publicId } = newScheduleItem;

        const newScheduleItems = scheduleItems.map((scheduleItem) => {
            if (scheduleItem.publicId_Ext === publicId) {
                return newScheduleItem;
            }
            return scheduleItem;
        });
        await saveScheduleItems(newScheduleItems);
        setOpenedScheduleItem(undefined);
        setStackOfOpenedScheduleID(stackOfOpenScheduleID.slice(0, stackOfOpenScheduleID.length - 1))
    };

    const handleSaveAdditionalInterest = async (newScheduleItem) => {
        const { publicId_Ext: publicId } = newScheduleItem;

        const newScheduleItems = scheduleItems.map((scheduleItem) => {
            if (scheduleItem.publicId_Ext === publicId) {
                return {
                    ...scheduleItem,
                    scheduledAdditionalInterest: newScheduleItem.scheduledAdditionalInterest
                };
            }
            return scheduleItem;
        });

        const newScheduleContainer = {...scheduleContainer}
        _.set(newScheduleContainer, `${schedulePath}.scheduleItems`, newScheduleItems)
        setCoveragePartClauses(newScheduleContainer)
        // await saveScheduleItems(newScheduleItems);
    }

    const scheduleNumberColumn = (
        <DisplayColumn
            key="ScheduleNumber"
            header="Schedule Number"
            sortable={false}
            textAlign="left"
            path="itemNumber"
            renderCell={(scheduleItem) => {
                const { itemNumber } = scheduleItem;
                if (!isEditable || scheduleReadonly) {
                    return itemNumber;
                }
                return (
                    <WniCheckboxField
                        value={selectedScheduleItemNumbers.includes(itemNumber)}
                        label={itemNumber}
                        showInlineLabel
                        onValueChange={(checked) => {
                            let newSelectedScheduleItemNumbers;
                            if (checked) {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.concat([
                                        itemNumber,
                                    ]);
                            } else {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.filter(
                                        (num) => num !== itemNumber
                                    );
                            }
                            setSelectedScheduleItemNumbers(
                                newSelectedScheduleItemNumbers
                            );
                        }}
                    />
                );
            }}
        />
    );

    const propertyInfoWithWrappers = propertyInfos
        .filter((propertyInfo) => {
            if (propertyInfo.id === 'AdditionalInsured') {
                return false
            }
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(propertyInfo.id)
            }
            return true
        })
        .map(propertyInfo => ({
            id: propertyInfo.id,
            columnType: ColumnType.PropertyInfo,
            columnInfo: propertyInfo,
            order: propertyInfo.order,
        }))
    
    const coveragePropertyInfoWithWrappers = coveragePropertyInfos
        .filter((coveragePropertyInfo) => {
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(coveragePropertyInfo.id)
            }
            return true
        })
        .map(coveragePropertyInfo => ({
            id: coveragePropertyInfo.id,
            columnType: ColumnType.CoveragePropertyInfo,
            columnInfo: coveragePropertyInfo,
            order: coveragePropertyInfo.order,
        }))

    const sortedColumn = propertyInfoWithWrappers
        .concat(coveragePropertyInfoWithWrappers)
        .sort((a, b) => {
            if (columnOrderOverride) {
                const aOrder = _.get(columnOrderOverride, `${a.id}`, a.order)
                const bOrder = _.get(columnOrderOverride, `${b.id}`, b.order)
                return aOrder - bOrder
            }
            // By default, all propertyInfo should display before coverageProeprtyInfo
            if (a.columnType !== b.columnType) {
                return a.columnType === ColumnType.PropertyInfo ? -1 : 1
            }
            return a.order - b.order
        })
        .map((infoWithWrapper => {
            if (infoWithWrapper.columnType === ColumnType.PropertyInfo) {
                const {columnInfo: propertyInfo} = infoWithWrapper
                const {
                    id,
                    label,
                } = propertyInfo
                
                return <DisplayColumn
                    key={id}
                    header={label}
                    renderCell={(scheduleItem) => {
                        return (
                            <IMScheduleItemReadonlyCell
                                propertyInfo={propertyInfo}
                                scheduleItem={scheduleItem}
                            />
                        );
                    }}
                    sortable={false}
                />
            } 
            if (infoWithWrapper.columnType === ColumnType.CoveragePropertyInfo) {

                const {columnInfo: coveragePropertyInfo} = infoWithWrapper

                const {
                    id,
                    label,
                } = coveragePropertyInfo
                
                return <DisplayColumn
                    key={id}
                    header={label}
                    renderCell={(scheduleItem) => {
                        const { scheduleItemTerms } = scheduleItem;
                        const term = scheduleItemTerms.find(
                            (t) => t.code_Ext === id
                        );

                        return _.get(term, 'chosenTermValue');
                    }}
                    sortable={false}
            />
            }

            return null
        }))
        

    const actionColumn = (
        <DisplayColumn
            key="ActionColumn"
            sortable={false}
            textAlign="left"
            renderCell={(scheduleItem) => {
                return (
                    <Button
                        className="btn-link"
                        onClick={() => {
                            setOpenedScheduleItem(scheduleItem)
                            if (_.isNil(openedScheduleItem)) {
                                setStackOfOpenedScheduleID([...stackOfOpenScheduleID, scheduleId])
                            }
                        }}
                        label={messages.editOrViewschedule}
                    />
                       
                );
            }}
        />
    );

    const columns = [
        scheduleNumberColumn,
        ...sortedColumn,
    ];
    const dataTableColumns =
        isEditable && !scheduleReadonly ? [...columns, actionColumn] : columns;

    return (
        <>
            {isEditable && !scheduleReadonly && (
                <>
                    {scheduleLable && <h4>{scheduleLable}</h4>}
                    {visibleOfDivider && <hr/>}
                    <Flex gap="small" justifyContent="right" className="mb-10">
                        <div>
                            <Button
                                className="wni-button-danger"
                                disabled={selectedScheduleItemNumbers.length === 0}
                                onClick={delSchedule}
                                label={messages.scheduleDel}
                            />
                            <Button
                                icon="gw-add"
                                onClick={addSchedule}
                                label={messages.scheduleAdd}
                            />
                        </div>
                    </Flex>
                </>
                
            )}
            <div className="table-wrapper mb-10">
                <WniDataTable
                    id={`clause_schedule_table_IM${scheduleId}ScheduledCov_Ext`}
                    data={sortedScheduleItems}
                    showSearch={false}
                >
                    {sortedScheduleItems.length > 0 ? dataTableColumns: []}
                </WniDataTable>
            </div>
            {openedScheduleItem && (
                <>
                    <ScheduleItemForm
                        jobID={jobID}
                        sessionUUID={sessionUUID}
                        propertyInfos={propertyInfos}
                        coveragePropertyInfos={coveragePropertyInfos}
                        scheduleItem={openedScheduleItem}
                        handleScheduleItemCancel={handleScheduleItemCancel}
                        handleSaveScheduleItem={handleSaveScheduleItem}
                        handleSaveAdditionalInterest={handleSaveAdditionalInterest}
                        isEditable={isEditable}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        scheduleFormHeader={scheduleFormHeader}
                        onValidate={onValidate}
                        scheduleId={scheduleId}
                        onChangeScheduleItem={onChangeScheduleItem}
                        stackOfOpenScheduleID={stackOfOpenScheduleID}
                        setStackOfOpenedScheduleID={setStackOfOpenedScheduleID}
                        updateAdditionalIntestSchedule={updateAdditionalIntestSchedule}
                        saveButtonLabel={saveButtonLabel}
                    />
                    {showErrors && <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.itemFormNotClosedMessage)}</div>}
                </>
            )}
            {(IMCoverageUtil.isScheduleInvalid(schedule) && _.isNil(openedScheduleItem))?
                <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.scheduleItemInvalidMessage)}</div>
                : null}
        </>
    );
};

export default CommonCoveragePartSchedule;
