import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glLocationService'), method, data, additionalHeaders);
}

export default class GLLocationService {
    /**
     *
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} location
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateLocation(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'updateLocation',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

      /**
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
      static fetchLocations(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission(
            'fetchLocations',
            [quoteID, sessionUUID],
            authHeader
        );
    }

    /**
     *
     *  @param {string} quoteID 
     * @param {string} sessionUUID 
     *@param {Array} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeLocations(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeLocations',
            [quoteID, sessionUUID, publicIDs],
            authHeader
        );
    }

  
     static postOnChangeAction(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'postOnChangeAction',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

    static addExistingLocations(quoteID, existingLocationPublicIds, sessionUUID, authHeader = {}) {
        return processSubmission('addExistingLocations',[quoteID, existingLocationPublicIds, sessionUUID], authHeader);
    }

    static findLocationAssociatedExposures(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('findLocationAssociatedExposures',[quoteID, sessionUUID, publicIDs], authHeader);
    }

    static removeLocationsAndExposures(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('removeLocationsAndExposures',[quoteID, sessionUUID, publicIDs], authHeader);
    }

    static transferExposureLocation(quoteID, sessionUUID, removeLocationIds, transferLocationId,  authHeader = {}) {
        return processSubmission('transferExposureLocation',[quoteID, sessionUUID, removeLocationIds, transferLocationId], authHeader);
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader
        );
    }

}