import { defineMessages } from 'react-intl';

export default defineMessages({
    // contactNumber: {
    //     id: 'platform.contacts.templates.contact-template.Contact Number',
    //     defaultMessage: 'Contact Number'
    // },
    // notEligibleFor: {
    //     id: 'quoteandbind.common.directives.CommonOfferingSelection.Not Eligible For',
    //     defaultMessage: 'Not Eligible For',
    // },
    viewwIneligibilityReasons: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.View Ineligibility Reasons',
        defaultMessage: 'View Ineligibility Reasons',
    },
    popupTitle: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Factors Exceeding Eligibility Guidelines for',
        defaultMessage: 'Factors Exceeding Eligibility Guidelines for',
    },
    okBtnLabel: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.OK',
        defaultMessage: 'OK',
    },
    factor: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Factor',
        defaultMessage: 'FACTOR'
    }
});
