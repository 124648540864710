import React from "react";
import { IMValuablePapersPage } from "wni-capability-quoteandbind-im-react";
import { PolicyChangeUtil } from 'wni-portals-util-js';

function IMValuablePapersChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('IMLine', submissionVM);
    return <IMValuablePapersPage {...props} isPolicyChange={!isDisplayAsSubmission} />
};

export default IMValuablePapersChangePage;
