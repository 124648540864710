import React from 'react';
import PropTypes from 'prop-types';
import { BaseDocumentCheckedComponent } from 'wni-capability-common-react'

function DocumentCheckedComponent(props) {
    return (
        <BaseDocumentCheckedComponent
            {...props}
        />
    );
}

DocumentCheckedComponent.propTypes = {
    renderData: PropTypes.arrayOf({}).isRequired,
    wizardTitleClass: PropTypes.string,
    showSendViaEmail: PropTypes.bool,
    history: PropTypes.shape({}),
    submission: PropTypes.shape({}),
    useJobNumber: PropTypes.bool
};
DocumentCheckedComponent.defaultProps = {
    wizardTitleClass: '',
    showSendViaEmail: true,
    history: {},
    submission: {},
    useJobNumber: false
};
export default DocumentCheckedComponent;
