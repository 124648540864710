import { defineMessages } from 'react-intl';

export default defineMessages({
    tooltipDummy: {
        id: 'custom.products.GL.tooltip.Dummy',
        defaultMessage: 'Dummy'
    },
    GL7CyberSuiteCov_Ext: {
        id: 'custom.products.GL.tooltip.Please specify the annual aggregate limit, all other coverage fields for Cyber Coverage will auto-populate based on your selection.',
        defaultMessage: 'Please specify the annual aggregate limit, all other coverage fields for Cyber Coverage will auto-populate based on your selection.'
    }
})