

import React from 'react';
import { IMCPPCommonFormsReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMCPPCommonFormsChangeReadOnlyPage = (props) => {
    return <IMCPPCommonFormsReadOnlyPage {...props} isPolicyChange isReadOnly/>
};
export default IMCPPCommonFormsChangeReadOnlyPage
