import { defineMessages } from 'react-intl';

export default defineMessages({
    policyRenewal: {
        id: 'gw-portals-policytransaction-common-angular.baseTransaction.baseTransactionWizard.Renewal',
        defaultMessage: 'Renewal'
    },
    buildingsAndLocations: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.config.FlowConfig.Buildings and Locations',
        defaultMessage: 'Buildings and Locations'
    },
    cpPolicyRenewalPolicyDetails: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.config.FlowConfig.Policy Details',
        defaultMessage: 'Policy Details'
    },
    cpPolicyChangeQuote: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.config.FlowConfig.Quote',
        defaultMessage: 'Quote'
    },
    cpPolicyChangePaymentDetails: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.config.FlowConfig.Payment Details',
        defaultMessage: 'Payment Details'
    }
});
