import { defineMessages } from 'react-intl';

export default defineMessages({

    
    vehicleSpecificCoverage: {
        id: 'custom.quoteandbind.wal.directives.MultiQuoteView.Watercraft Specific Coverages - {vehicleDetails}',
        defaultMessage: 'Watercraft Specific Coverages - {vehicleDetails}',
    },
    watercraftDetails: {
        id: 'custom.quoteandbind.wal.directives.MultiQuoteView.Watercraft details ({watercraftDetailDesc})',
        defaultMessage: 'Watercraft details ({watercraftDetailDesc})',
    },
    motorDetails: {
        id: 'custom.quoteandbind.wal.directives.MultiQuoteView.Motor details ({motorDetailDesc})',
        defaultMessage: 'Motor details ({motorDetailDesc})',

    },
    trailerDetails: {
        id: 'custom.quoteandbind.wal.directives.MultiQuoteView.Trailer details ({trailerDetailDesc})',
        defaultMessage: 'Trailer details ({trailerDetailDesc})',
        
    }
});
