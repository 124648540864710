import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './AgencyDetailsMatchPopup.metadata.json5';
import styles from './AgencyDetailsMatchPopup.module.scss';

import { Button } from '@jutro/legacy/components';

function AgencyDetailsMathPopup(props) {
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        size,
        // authHeader,
        // searchValue,
        updateAgencyDisplayName,
        updateAgencyCode,
        agencyVM, // : initialAgencyVM,
        producerCodePath,
        useAuthenticationData,
        useDependenciesData,
    } = props;

    const { authHeader } = useAuthenticationData || useAuthentication();
    const [pageConfig, updatePageConfig] = useState(WniTableRowUtil.defaultPageConfig);
    // const [agencyResponseValue, updateAgencyResponseValue] = useState([]);
    const [agencyAfterSearchValue, updateAgencyAfterSearchValue] = useState([]);
    const [selectRow, updateSelectRow] = useState({});
    const [searchByAgencyNameInputValue, updateSearchByAgencyNameInputValue] = useState('');
    const [searchByAgencyCodeInputValue, updateSearchByAgencyCodeInputValue] = useState('');

    useEffect(() => {
        // if (size)
        // eslint-disable-next-line max-len
        WniAccountService.getAgencyMatchData('', 100, authHeader).then((agencyResponse) => {
            if (!_.isUndefined(agencyResponse.length) && agencyResponse.length > 0) {
                // updateAgencyResponseValue(agencyResponse);
                updateAgencyAfterSearchValue(agencyResponse);
            }
        });
    }, [authHeader]);

    const handleAgencyNameSearchInputChange = (agencyNameSearchInputValue) => {
        updateSearchByAgencyNameInputValue(agencyNameSearchInputValue);
    };

    const handleAgencyCodeSearchInputChange = (agencyCodeSearchInputValue) => {
        updateSearchByAgencyCodeInputValue(agencyCodeSearchInputValue);
    };

    const handleSearch = () => {
        /**
         * keep the logic for future if it is changed again.
         */
        /* let filterBySearchResult = agencyResponseValue.concat();
        if (searchByAgencyNameInputValue !== null && searchByAgencyNameInputValue !== '') {
            filterBySearchResult = filterBySearchResult.filter((agency) => {
                return agency.orgName_Ext
                    && agency.orgName_Ext.toLowerCase().includes(searchByAgencyNameInputValue.toLowerCase());
            });
        }
        if (searchByAgencyCodeInputValue !== null && searchByAgencyCodeInputValue !== '') {
            filterBySearchResult = filterBySearchResult.filter((agency) => {
                return agency.code && agency.code.toLowerCase().includes(searchByAgencyCodeInputValue.toLowerCase());
            });
        }
        updateAgencyAfterSearchValue(filterBySearchResult);
        */

        WniAccountService.searchAgency(searchByAgencyNameInputValue, searchByAgencyCodeInputValue, 100, authHeader).then((agencyResponse) => {
            if (!_.isUndefined(agencyResponse.length) && agencyResponse.length > 0) {
                // updateAgencyResponseValue(agencyResponse);
                updateAgencyAfterSearchValue(agencyResponse);
            } else {
                updateAgencyAfterSearchValue([]);
            }
        });
        updatePageConfig(DatatableUtil.defaultPageConfig);
    };

    const handleReset = () => {
        // updateAgencyAfterSearchValue(agencyResponseValue);
        updateSearchByAgencyCodeInputValue('');
        updateSearchByAgencyNameInputValue('');
        updateAgencyAfterSearchValue([]);
        updatePageConfig(DatatableUtil.defaultPageConfig);
    };

    const handleSave = useCallback(() => {
        if (selectRow.code) {
            const agencyDisplay = `${selectRow.code} - `
                // + `${selectRow.orgName_Ext ? selectRow.orgName_Ext : 'null'} - `
                + `${selectRow.description ? selectRow.description : 'null'} - `
                + `${selectRow.city_Ext ? selectRow.city_Ext : 'null'}, `
                + `${selectRow.state_Ext ? selectRow.state_Ext : 'null'}`;
            updateAgencyDisplayName(agencyDisplay);
            updateAgencyCode(selectRow.code);
            /* if (producerCodePath === 'producerCode_Ext') {
                _.set(agencyVM, 'agencyDisplay_Ext', agencyDisplay);
            } */

            onResolve(selectRow);
        }
    }, [onResolve, selectRow]);
    const hightLight = (activeRow) => {
        const selectedPublicID = _.get(activeRow, 'publicID');
        WniTableRowUtil.setTablePublicIDSelected(selectedPublicID, 'exisitingAgencyMatchTable');
    };
    const getSelectedFn = (item) => {
        if (item.code === selectRow.code) {
            updateSelectRow({});
        } else {
            updateSelectRow(item);
        }
        hightLight(item);
    };
    const onTableConfigPage = useCallback((obj) => {
        updatePageConfig(obj);
    }, []);
    const onSort = useCallback((a, b) => {
        hightLight(selectRow);
        return DatatableUtil.sortString(a, b);
    }, [selectRow]);

    useEffect(() => {
        hightLight(selectRow);
    }, [selectRow, onTableConfigPage, pageConfig]);

    /* const filterAgencyCodeTable = (val) => {
        return (row) => row.code.indexOf(val) !== -1;
    };

    const filterAgencyNameTable = (val) => {
        return (row) => row.orgName_Ext.indexOf(val) !== -1;
    }; */

    const overrideProps = {
        agencyNameSearchInput: {
            value: searchByAgencyNameInputValue
        },
        agencyCodeSearchInput: {
            value: searchByAgencyCodeInputValue
        },
        exisitingAgencyMatchTable: {
            data: agencyAfterSearchValue,
            onConfigChange: onTableConfigPage,
            config: pageConfig
        },
        /* agencyCodeColumn: {
            filter: filterAgencyCodeTable
        },
        : {
            filter: filterAgencyNameTable
        } */
        agencyNamecolumn: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.publicID, item.description)
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            agencyVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getSelectedFn: getSelectedFn,
            updateAgencyNameSearchInput: handleAgencyNameSearchInputChange,
            updateAgencyCodeSearchInput: handleAgencyCodeSearchInputChange,
            onSearchBtnClick: handleSearch,
            onResetBtnClick: handleReset,
            onSort
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <Button className="modal-close" onClick={onReject}>
                <i className="fa fa-times"> </i>
            </Button>
            <ModalBody id="agencyDatailsPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={agencyVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled" disabled={!selectRow.code}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

AgencyDetailsMathPopup.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    // authHeader: PropTypes.shape({}).isRequired,
    agencyVM: PropTypes.shape({}).isRequired,
    updateAgencyDisplayName: PropTypes.func,
    updateAgencyCode: PropTypes.func,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    // searchValue: PropTypes.string,
    producerCodePath: PropTypes.string.isRequired
};
AgencyDetailsMathPopup.defaultProps = {
    updateAgencyDisplayName: () => {},
    updateAgencyCode: () => {},
    // searchValue: ''
};
export default AgencyDetailsMathPopup;
