import React, {
} from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import HODPCoveragesBaseReadOnlyPage from './HODPCoveragesBasePage.readonly';
import HOCoveragesConfig from './HOCoverages.config';
// import messages from './HOCoveragesPage.messages';

function HOCoveragesPage(props) {
    const {
        wizardData: submissionVM,
    } = props;

    return <HODPCoveragesBaseReadOnlyPage 
        wizardData={submissionVM}
        lobName='homeowners'
        coveragesConfig={HOCoveragesConfig}
    />
}

HOCoveragesPage.propTypes = wizardProps;
export default HOCoveragesPage;
