import { defineMessages } from 'react-intl';

export default defineMessages({
    generalCoverage: {
        id: 'quoteandbind.bop.wizard.step.General Coverages',
        defaultMessage: 'General Coverages'
    },
    additionalCoverage: {
        id: 'quoteandbind.bop.wizard.step.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    locationsAndBuildings: {
        id: 'quoteandbind.bop.wizard.step.Locations and Buildings',
        defaultMessage: 'Locations and Buildings'
    },
    businessownersPropertyQuote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.Businessowners Property Quote',
        defaultMessage: 'Businessowners Property Quote'
    },
    company: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.Company',
        defaultMessage: 'Company'
    },
    anErrorOccurred: {
        id: 'quoteandbind.bop.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.bop.wizard.error.cancel.title',
        defaultMessage: 'Error'
    }
});
