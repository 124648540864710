import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPACollisionDetailsPage } from 'wni-capability-fnol-pa-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';


function FNOLCACollisionDetailsPage(props) {
    return <FNOLPACollisionDetailsPage {...props} />;

}

FNOLCACollisionDetailsPage.propTypes = wizardProps;
FNOLCACollisionDetailsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(FNOLCACollisionDetailsPage);
