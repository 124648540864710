import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import IMPolicyChangeStartPage from './pages/Start/IMPolicyChangeStartPage';
import IMPaySuccessChangePage from './pages/PaySuccess/IMPaySuccessChangePage';

import IMPolicyDetailsChangePage from './pages/PolicyDetails/IMPolicyDetailsChangePage';
import IMPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/IMPolicyDetailsChangePage.readonly';

import IMCoveragePartSelectionChangePage from './pages/CoveragePartSelection/IMCoveragePartSelectionChangePage';
import IMCoveragePartSelectionChangeReadOnlyPage from './pages/CoveragePartSelection/IMCoveragePartSelectionChangePage.readonly';

import IMBuildingsAndLocationsChangePage from './pages/BuildingsAndLocations/IMBuildingsAndLocationsChangePage';
import IMBuildingsAndLocationsChangeReadOnlyPage from './pages/BuildingsAndLocations/IMBuildingsAndLocationsChangePage.readonly';

import IMModifiersChangePage from './pages/Modifiers/IMModifiersChangePage';
import IMModifiersChangeReadOnlyPage from './pages/Modifiers/IMModifiersChangePage.readonly';

import IMAAISCommonFormsChangePage from './pages/AAISCommonForms/IMAAISCommonFormsChangePage';
import IMAAISCommonFormsChangeReadOnlyPage from './pages/AAISCommonForms/IMAAISCommonFormsChangePage.readonly';

import IMDummyChangePage from './pages/Dummy/IMDummyChangePage';
import IMDummyChangeReadOnlyPage from './pages/Dummy/IMDummyChangePage.readonly';

import IMBoatCoverageChangePage from './pages/BoatCoverage/IMBoatCoverageChangePage';
import IMBoatCoverageChangeReadOnlyPage from './pages/BoatCoverage/IMBoatCoverageChangePage.readonly';

import IMBuildersRiskChangePage from './pages/BuildersRisk/IMBuildersRiskChangePage';
import IMBuildersRiskChangeReadOnlyPage from './pages/BuildersRisk/IMBuildersRiskChangePage.readonly';

import IMContractorsEquipmentChangePage from './pages/ContractorsEquipment/IMContractorsEquipmentChangePage';
import IMContractorsEquipmentChangeReadOnlyPage from './pages/ContractorsEquipment/IMContractorsEquipmentChangePage.readonly';

import IMElectronicDataProcessingChangePage from './pages/ElectronicDataProcessing/IMElectronicDataProcessingChangePage';
import IMElectronicDataProcessingChangeReadOnlyPage from './pages/ElectronicDataProcessing/IMElectronicDataProcessingChangePage.readonly';

import IMFineArtsFloaterChangePage from './pages/FineArtsFloater/IMFineArtsFloaterChangePage';
import IMFineArtsFloaterChangeReadOnlyPage from './pages/FineArtsFloater/IMFineArtsFloaterChangePage.readonly';

import IMInstallationFloaterChangePage from './pages/InstallationFloater/IMInstallationFloaterChangePage';
import IMInstallationFloaterChangeReadOnlyPage from './pages/InstallationFloater/IMInstallationFloaterChangePage.readonly';

import IMMiscFormsChangePage from './pages/MiscForms/IMMiscFormsChangePage';
import IMMiscFormsChangeReadOnlyPage from './pages/MiscForms/IMMiscFormsChangePage.readonly';

import IMMiscellaneousFloatersChangePage from './pages/MiscellaneousFloaters/IMMiscellaneousFloatersChangePage';
import IMMiscellaneousFloatersChangeReadOnlyPage from './pages/MiscellaneousFloaters/IMMiscellaneousFloatersChangePage.readonly';

import IMMotorTruckCargoChangePage from './pages/MotorTruckCargo/IMMotorTruckCargoChangePage';
import IMMotorTruckCargoChangeReadOnlyPage from './pages/MotorTruckCargo/IMMotorTruckCargoChangePage.readonly';

import IMMuseumChangePage from './pages/Museum/IMMuseumChangePage';
import IMMuseumChangeReadOnlyPage from './pages/Museum/IMMuseumChangePage.readonly';

import IMRiggersCoverageChangePage from './pages/RiggersCoverage/IMRiggersCoverageChangePage';
import IMRiggersCoverageChangeReadOnlyPage from './pages/RiggersCoverage/IMRiggersCoverageChangePage.readonly';

import IMTransitChangePage from './pages/Transit/IMTransitChangePage';
import IMTransitChangeReadOnlyPage from './pages/Transit/IMTransitChangePage.readonly';

import IMWarehouseLegalLiabilityChangePage from './pages/WarehouseLegalLiability/IMWarehouseLegalLiabilityChangePage';
import IMWarehouseLegalLiabilityChangeReadOnlyPage from './pages/WarehouseLegalLiability/IMWarehouseLegalLiabilityChangePage.readonly';

import IMRiskAnalysisChangePage from './pages/RiskAnalysis/IMRiskAnalysisChangePage';
import IMRiskAnalysisChangeReadOnlyPage from './pages/RiskAnalysis/IMRiskAnalysisChangePage.readonly';

import IMUnderwritingQuestionsChangePage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsChangePage';
import IMUnderwritingQuestionsChangeReadOnlyPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsChangePage.readonly';

import IMCPPCommonFormsChangePage from './pages/CPPCommonForms/IMCPPCommonFormsChangePage';
import IMCPPCommonFormsChangeReadOnlyPage from './pages/CPPCommonForms/IMCPPCommonFormsChangePage.readonly';

import IMAccountsReceivableChangePage from './pages/AccountsReceivable/IMAccountsReceivableChangePage';
import IMAccountsReceivableChangeReadOnlyPage from './pages/AccountsReceivable/IMAccountsReceivableChangePage.readonly';

import IMPhysiciansAndSurgeonsChangePage from './pages/PhysiciansAndSurgeons/IMPhysiciansAndSurgeonsChangePage';
import IMPhysiciansAndSurgeonsChangeReadOnlyPage from './pages/PhysiciansAndSurgeons/IMPhysiciansAndSurgeonsChangePage.readonly';

import IMSignsChangePage from './pages/Signs/IMSignsChangePage';
import IMSignsChangeReadOnlyPage from './pages/Signs/IMSignsChangePage.readonly';

import IMValuablePapersChangePage from './pages/ValuablePapers/IMValuablePapersChangePage';
import IMValuablePapersChangeReadOnlyPage from './pages/ValuablePapers/IMValuablePapersChangePage.readonly';

import IMBaileeCustomersFloaterChangePage from './pages/BaileeCustomersFloater/IMBaileeCustomersFloaterChangePage';
import IMBaileeCustomersFloaterChangeReadOnlyPage from './pages/BaileeCustomersFloater/IMBaileeCustomersFloaterChangePage.readonly';


// WIZARD PAGE IMPORT ANCHOR

import IMQuoteChangePage from './pages/Quote/IMQuoteChangePage';
import IMQuoteChangeReadOnlyPage from './pages/Quote/IMQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        IMPolicyChangeStartPage: { component: 'IMPolicyChangeStartPage' },
        IMPaySuccessChangePage: { component: 'IMPaySuccessChangePage' },

        IMPolicyDetailsChangePage: { component: 'IMPolicyDetailsChangePage'},
        IMPolicyDetailsChangeReadOnlyPage: { component: 'IMPolicyDetailsChangeReadOnlyPage'},

        IMCoveragePartSelectionChangePage: { component: 'IMCoveragePartSelectionChangePage'},
        IMCoveragePartSelectionChangeReadOnlyPage: { component: 'IMCoveragePartSelectionChangeReadOnlyPage'},

        IMBuildingsAndLocationsChangePage: { component: 'IMBuildingsAndLocationsChangePage'},
        IMBuildingsAndLocationsChangeReadOnlyPage: { component: 'IMBuildingsAndLocationsChangeReadOnlyPage'},

        IMModifiersChangePage: { component: 'IMModifiersChangePage'},
        IMModifiersChangeReadOnlyPage: { component: 'IMModifiersChangeReadOnlyPage'},

        IMAAISCommonFormsChangePage: { component: 'IMAAISCommonFormsChangePage'},
        IMAAISCommonFormsChangeReadOnlyPage: { component: 'IMAAISCommonFormsChangeReadOnlyPage'},

        IMDummyChangePage: { component: 'IMDummyChangePage'},
        IMDummyChangeReadOnlyPage: { component: 'IMDummyChangeReadOnlyPage'},

        IMBoatCoverageChangePage: { component: 'IMBoatCoverageChangePage'},
        IMBoatCoverageChangeReadOnlyPage: { component: 'IMBoatCoverageChangeReadOnlyPage'},

        IMBuildersRiskChangePage: { component: 'IMBuildersRiskChangePage'},
        IMBuildersRiskChangeReadOnlyPage: { component: 'IMBuildersRiskChangeReadOnlyPage'},

        IMContractorsEquipmentChangePage: { component: 'IMContractorsEquipmentChangePage'},
        IMContractorsEquipmentChangeReadOnlyPage: { component: 'IMContractorsEquipmentChangeReadOnlyPage'},

        IMElectronicDataProcessingChangePage: { component: 'IMElectronicDataProcessingChangePage'},
        IMElectronicDataProcessingChangeReadOnlyPage: { component: 'IMElectronicDataProcessingChangeReadOnlyPage'},

        IMFineArtsFloaterChangePage: { component: 'IMFineArtsFloaterChangePage'},
        IMFineArtsFloaterChangeReadOnlyPage: { component: 'IMFineArtsFloaterChangeReadOnlyPage'},

        IMInstallationFloaterChangePage: { component: 'IMInstallationFloaterChangePage'},
        IMInstallationFloaterChangeReadOnlyPage: { component: 'IMInstallationFloaterChangeReadOnlyPage'},

        IMMiscFormsChangePage: { component: 'IMMiscFormsChangePage'},
        IMMiscFormsChangeReadOnlyPage: { component: 'IMMiscFormsChangeReadOnlyPage'},

        IMMiscellaneousFloatersChangePage: { component: 'IMMiscellaneousFloatersChangePage'},
        IMMiscellaneousFloatersChangeReadOnlyPage: { component: 'IMMiscellaneousFloatersChangeReadOnlyPage'},

        IMMotorTruckCargoChangePage: { component: 'IMMotorTruckCargoChangePage'},
        IMMotorTruckCargoChangeReadOnlyPage: { component: 'IMMotorTruckCargoChangeReadOnlyPage'},

        IMMuseumChangePage: { component: 'IMMuseumChangePage'},
        IMMuseumChangeReadOnlyPage: { component: 'IMMuseumChangeReadOnlyPage'},

        IMRiggersCoverageChangePage: { component: 'IMRiggersCoverageChangePage'},
        IMRiggersCoverageChangeReadOnlyPage: { component: 'IMRiggersCoverageChangeReadOnlyPage'},

        IMTransitChangePage: { component: 'IMTransitChangePage'},
        IMTransitChangeReadOnlyPage: { component: 'IMTransitChangeReadOnlyPage'},

        IMWarehouseLegalLiabilityChangePage: { component: 'IMWarehouseLegalLiabilityChangePage'},
        IMWarehouseLegalLiabilityChangeReadOnlyPage: { component: 'IMWarehouseLegalLiabilityChangeReadOnlyPage'},

        IMRiskAnalysisChangePage: { component: 'IMRiskAnalysisChangePage'},
        IMRiskAnalysisChangeReadOnlyPage: { component: 'IMRiskAnalysisChangeReadOnlyPage'},

        IMUnderwritingQuestionsChangePage: { component: 'IMUnderwritingQuestionsChangePage'},
        IMUnderwritingQuestionsChangeReadOnlyPage: { component: 'IMUnderwritingQuestionsChangeReadOnlyPage'},

        IMCPPCommonFormsChangePage: { component: 'IMCPPCommonFormsChangePage' },
        IMCPPCommonFormsChangeReadOnlyPage: { component: 'IMCPPCommonFormsChangeReadOnlyPage' },

        IMAccountsReceivableChangePage: { component: 'IMAccountsReceivableChangePage'},
        IMAccountsReceivableChangeReadOnlyPage: { component: 'IMAccountsReceivableChangeReadOnlyPage'},

        IMPhysiciansAndSurgeonsChangePage: { component: 'IMPhysiciansAndSurgeonsChangePage'},
        IMPhysiciansAndSurgeonsChangeReadOnlyPage: { component: 'IMPhysiciansAndSurgeonsChangeReadOnlyPage'},

        IMSignsChangePage: { component: 'IMSignsChangePage'},
        IMSignsChangeReadOnlyPage: { component: 'IMSignsChangeReadOnlyPage'},

        IMValuablePapersChangePage: { component: 'IMValuablePapersChangePage'},
        IMValuablePapersChangeReadOnlyPage: { component: 'IMValuablePapersChangeReadOnlyPage'},

        IMBaileeCustomersFloaterChangePage: { component: 'IMBaileeCustomersFloaterChangePage'},
        IMBaileeCustomersFloaterChangeReadOnlyPage: { component: 'IMBaileeCustomersFloaterChangeReadOnlyPage'},

// WIZARD PAGE COMPONENT NAME ANCHOR
        IMQuoteChangePage: { component: 'IMQuoteChangePage' },
        IMQuoteChangeReadOnlyPage: { component: 'IMQuoteChangeReadOnlyPage' },
    },
    {
        IMPolicyChangeStartPage,
        IMPaySuccessChangePage,

        IMPolicyDetailsChangePage,
        IMPolicyDetailsChangeReadOnlyPage,

        IMCoveragePartSelectionChangePage,
        IMCoveragePartSelectionChangeReadOnlyPage,

        IMBuildingsAndLocationsChangePage,
        IMBuildingsAndLocationsChangeReadOnlyPage,

        IMModifiersChangePage,
        IMModifiersChangeReadOnlyPage,

        IMAAISCommonFormsChangePage,
        IMAAISCommonFormsChangeReadOnlyPage,

        IMDummyChangePage,
        IMDummyChangeReadOnlyPage,

        IMBoatCoverageChangePage,
        IMBoatCoverageChangeReadOnlyPage,

        IMBuildersRiskChangePage,
        IMBuildersRiskChangeReadOnlyPage,

        IMContractorsEquipmentChangePage,
        IMContractorsEquipmentChangeReadOnlyPage,

        IMElectronicDataProcessingChangePage,
        IMElectronicDataProcessingChangeReadOnlyPage,

        IMFineArtsFloaterChangePage,
        IMFineArtsFloaterChangeReadOnlyPage,

        IMInstallationFloaterChangePage,
        IMInstallationFloaterChangeReadOnlyPage,

        IMMiscFormsChangePage,
        IMMiscFormsChangeReadOnlyPage,

        IMMiscellaneousFloatersChangePage,
        IMMiscellaneousFloatersChangeReadOnlyPage,

        IMMotorTruckCargoChangePage,
        IMMotorTruckCargoChangeReadOnlyPage,

        IMMuseumChangePage,
        IMMuseumChangeReadOnlyPage,

        IMRiggersCoverageChangePage,
        IMRiggersCoverageChangeReadOnlyPage,

        IMTransitChangePage,
        IMTransitChangeReadOnlyPage,

        IMWarehouseLegalLiabilityChangePage,
        IMWarehouseLegalLiabilityChangeReadOnlyPage,

        IMRiskAnalysisChangePage,
        IMRiskAnalysisChangeReadOnlyPage,

        IMUnderwritingQuestionsChangePage,
        IMUnderwritingQuestionsChangeReadOnlyPage,

        IMCPPCommonFormsChangePage,
        IMCPPCommonFormsChangeReadOnlyPage,

        IMAccountsReceivableChangePage,
        IMAccountsReceivableChangeReadOnlyPage,

        IMPhysiciansAndSurgeonsChangePage,
        IMPhysiciansAndSurgeonsChangeReadOnlyPage,

        IMSignsChangePage,
        IMSignsChangeReadOnlyPage,

        IMValuablePapersChangePage,
        IMValuablePapersChangeReadOnlyPage,

        IMBaileeCustomersFloaterChangePage,
        IMBaileeCustomersFloaterChangeReadOnlyPage,

// WIZARD PAGE COMPONENT MAP ANCHOR
        IMQuoteChangePage,
        IMQuoteChangeReadOnlyPage,
    }
);

export { default as IMPolicyChangeWizard } from './IMPolicyChangeWizard';