import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import {
    ErrorsAndWarningsUtil,
    WindowUtil,
} from 'wni-portals-util-js';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import AdditionalInterestComponent from './AdditionalInterest/WALAdditionalInterestComponent';
import WizardPage from '../../templates/WALWizardPage';

import styles from './WalAddInstDemoPage.module.scss';
import metadata from './WalAddInstDemoPage.metadata.json5';
import messages from './WalAddInstDemoPage.messages';
import { paData } from './AdditionalInterest/mockupData';

function WALDummyPage(props) {
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        // history,
        // goNext
        // errorsForStep,
        // stepsWithErrors,
        // underwritingIssues,
        // acknowledgeError,
        // reportErrors,
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
    } = props;

    // const {
    //     quoteID, sessionUUID,
    //     baseData: {
    //         termType,
    //         periodStatus,
    //         selectedVersion_Ext: selectedVersion,
    //         accountHolder: {
    //             primaryAddress: {
    //                 postalCode,
    //             }
    //         },
    //         numberOfQuotedOrRatedQuotes_Ext: quoteCount,
    //         showFullPayDiscount_Ext: showFullPayDiscount,
    //         displayStatus_Ext: displayStatus,
    //     },
    //     lobData: {
    //         personalAuto: {
    //             isForNamedNonOwner_Ext: isForNamedNonOwner
    //         }
    //     },
    //     bindData,
    //     quoteData,
    // } = submissionVM.value;

    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('PAQuotePage');
    const [showErrors, updateShowErrors] = useState(false);

    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    // const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    // const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    useEffect(() => {

    }, []);

    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            additionalinterestcomponent: AdditionalInterestComponent,
        }
    };
    
    const submission = viewModelService.create(
        paData,
        'pc',
        'edge.capabilities.quote.submission.dto.QuoteDataDTO'
    );
    const vehicleVMs = _.get(submission, 'lobData.personalAuto.coverables.vehicles.children');
    const waterctaftVMs = _.get(submissionVM, 'lobData.watercraft.coverables.waterctafts.children', []);
    const vehicleVM = vehicleVMs[0]
    let waterctaftVM = []
    if(waterctaftVMs.length === 0){
        waterctaftVM = vehicleVM
    }else{
        waterctaftVM = waterctaftVMs[0]
    }
    
    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    const overrideProps = {
        '@field': {
            showOptional: false,
            showRequired: true,
            labelPosition: 'left'
        },
        additionalInterestComponentId: {
            value: _.get(waterctaftVM, 'additionalInterests'),
            accountHolderBirthday: {
                "year": 1986,
                "month": 0,
                "day": 1
            },
            accountNumber: "A000001001",
            accountContacts: submissionVM.value.baseData.accountContacts_Ext,
            authHeader: authHeader,
            // initialContext: {
            //     AdditionalInterestType: additionalInterestType
            // },
            // licenseStateCode: licenseState ? licenseState.code : '',
            readOnly: false,
            // getAdditionalInterestTypeOptions
        },
    };

    const readValue = (innerId, innerPath) => {
        return readViewModelValue(metadata.pageContent, waterctaftVM, innerId, innerPath, overrideProps);
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={waterctaftVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WALDummyPage.propTypes = WizardPage.propTypes;
export default WALDummyPage;
