import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('walPolicyInfoChange'), method, data, additionalHeaders);
}

export default class WALPolicyInfoService {

    static saveWALPolicyInfoData(jobID, sessionUUID, questionSetData, quoteBaseData, policyInfoDTO, authHeader) {
        return processSubmission('saveWALPolicyInfoData', [jobID, sessionUUID, questionSetData, quoteBaseData, policyInfoDTO], authHeader);
    }

}
