import React, {
    useCallback,
    useContext,
    useState,
} from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    IntlContext,
    useTranslator,
} from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import WALCoverageUtil from '../../util/WALCoverageUtil';
import WatercraftCoveragesModal from '../../components/WatercraftCoveragesModal/WatercraftCoveragesModal'
import CopyWatercraftCoverageModal from "../../components/CopyWatercraftCoverageModal/CopyWatercraftCoverageModal";
import metadata from './WALWaterCraftCoverages.metadata.json5';
import styles from './WALWaterCraftCoverages.module.scss';
import messages from './WALWaterCraftCoverages.messages';
import CoveragesConfigContext from "../../context/WALCoveragesConfigContext";
import { Button } from '@jutro/legacy/components';

const PyhsicalDamageCoverageCode = 'WALPhysicalDamage_Ext'
const IncLimitsTowCoverageCode = 'WALIncLimitsTowAndAssistExpCov_Ext'

const DeductibleOfPhysicalDamageTermCode = 'WALPhysicalDamageDeductibleOfPhysicalDamage_Ext'
const IncLimitsTowPerDisLimitTermCode = 'WALIncLimitsTowAndAssistExpCovLimitPerDis_Ext'


function WALWaterCraftCoverages(props) {

    const {
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        updateWizardData,
        updateErrorsAndWarnings,
        lobName,
        coveragesService,
        isEditable,
        currentPageId,
    } = props;

    const translator = useTranslator();
    const {coverageAvailable} = useContext(CoveragesConfigContext);
    const intl = useContext(IntlContext);
    const { authHeader } = useAuthentication();

    const {
        jobID,
        sessionUUID,
        lobData: {
            [lobName]: {
                coverables: {
                    watercrafts = []
                } = {}
            } ={}
        } = {}
    } = submissionVM.value;

    const [watercraftCoveragesDetailsModalstatus, setWatercraftCoveragesDetailsModalStatus] = useState({
        isOpen: false,
        watercraftID: undefined,
        title: undefined,
    })

    const [copyWatercraftCoverageModalStatus, setCopyWatercraftCoverageModalStatus] = useState({
        isOpen: false,
        triggerCopyCoverageWatercraftPublicID: undefined
    });

    const waterCraftcoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.watercraftCoverages`;

    const waterCraftCoverages = _.get(submissionVM.value, waterCraftcoveragePath)

    const getWalCoverages = (wcPublicID) => {
        const walCoverage = waterCraftCoverages
            .find((walCoverageDTO) => _.get(walCoverageDTO, 'owningCoverablePublicID') === wcPublicID);
        return _.get(walCoverage, 'coverages', []);
    }

    const renderWatercraftDescriptionCell = (watercraft) => {
        return WALCoverageUtil.getWatercraftDescription(watercraft)
    }

    const renderCellByWaterCraftAndCovAndTerm = (waterCraft, covCode, termCode) => {
        const wcPublicID = _.get(waterCraft, 'publicID');
        const wcCoverages = getWalCoverages(wcPublicID)
        if (_.isEmpty(wcCoverages)) {
            return translator(messages.WALWatercraftCoveragesTableNO)
        }

        const coverage = _.find(wcCoverages, (cov) => _.get(cov, 'code_Ext') === covCode)
        if (_.isEmpty(coverage)){
            return translator(messages.WALWatercraftCoveragesTableNO)
        }
        const terms = _.get(coverage, 'terms', [])
        const term = _.find(terms, (t) => _.get(t, 'code_Ext') === termCode)
        if (_.isEmpty(term)){
            return translator(messages.WALWatercraftCoveragesTableNO)
        }
        const chosenTerm = _.get(term, 'chosenTerm')
        const options = _.get(term, 'options', [])
        const selectedOption = _.find(options, (option) => _.get(option, 'code') === chosenTerm)
        if (_.isEmpty(selectedOption)){
            return translator(messages.WALWatercraftCoveragesTableNO)
        }
        return _.get(selectedOption, 'name');
    }
   
    const renderWaterCraftPhysicalDamage = (waterCraft) => {
        if (!WALCoverageUtil.getIsPhysicalDamageCovRequired(waterCraft)) {
            return null
        }
        return renderCellByWaterCraftAndCovAndTerm(
            waterCraft,
            PyhsicalDamageCoverageCode,
            DeductibleOfPhysicalDamageTermCode
        );
    }

    const renderWatercraftIncreaseLimitsTowing = (waterCraft) => {
        if (!WALCoverageUtil.getIsPhysicalDamageCovRequired(waterCraft)) {
            return null
        }
        return renderCellByWaterCraftAndCovAndTerm(
            waterCraft,
            IncLimitsTowCoverageCode,
            IncLimitsTowPerDisLimitTermCode
        );
    }

    const renderWatercraftOther = (watercraft) => {
        const wcPublicID = _.get(watercraft, 'publicID');
        const wcCoverages = getWalCoverages(wcPublicID)
        if (!WALCoverageUtil.getIsPhysicalDamageCovRequired(watercraft)) {
            return null
        }
        if (_.isEmpty(wcCoverages)) {
            return translator(messages.WALWatercraftCoveragesTableNO)
        }
        const hasOtherSelectedCoverages = wcCoverages
            .filter((clause) => {
                const clauseCode = clause.code_Ext;
                if (!coverageAvailable.hasOwnProperty(clauseCode)) {
                    return true
                }
                return coverageAvailable[clauseCode](currentPageId)
            })
            .some((cov) => {
                const covCode = _.get(cov, 'code_Ext')
                const selected = _.get(cov, 'selected')
                if (!selected) {
                    return false
                }
                if ([
                    PyhsicalDamageCoverageCode,
                    IncLimitsTowCoverageCode
                ].includes(covCode)) {
                    return false
                }
                return true
            })
        if (!hasOtherSelectedCoverages) {
            return translator(messages.WALWatercraftCoveragesTableNO)
        }
        return translator(messages.WALWatercraftCoveragesTableYES)
    }

    const onOpenWatercraftCoveragesDetailsModal = (watercraft) => {
        const wcPublicID = _.get(watercraft, 'publicID');
        setWatercraftCoveragesDetailsModalStatus({
            isOpen: true,
            watercraftID: wcPublicID,
            title: WALCoverageUtil.getWatercraftDescription(watercraft),
        })
    }

    const renderWatercraftAction = (watercraft) => {
        if (WALCoverageUtil.getIsPhysicalDamageCovRequired(watercraft)) {
            return <Button className="wni-button-link" onClick={() => {onOpenWatercraftCoveragesDetailsModal(watercraft)}}>
                    {translator(messages.WALWatercraftCoveragesTableViewEditAddCoverage)}
                </Button>;
        }
        return translator(messages.WALWatercraftLiabilityOnly);
    }

    const onResolveWatercraftCoveragesDetailsModal = () => {
        setWatercraftCoveragesDetailsModalStatus({
            isOpen: false
        })
    }

    const onResolveCopyWatercraftCoverageModal = (backToWatercraftPublicID) => {
        setCopyWatercraftCoverageModalStatus({
            ...copyWatercraftCoverageModalStatus,
            isOpen: false
        });
        if (backToWatercraftPublicID) {
            setWatercraftCoveragesDetailsModalStatus({
                ...watercraftCoveragesDetailsModalstatus,
                isOpen: true,
                watercraftID: backToWatercraftPublicID,
            });
        }
    };

    const onRejectCopyWatercraftCoverageModal = () => {
        setCopyWatercraftCoverageModalStatus({
            ...copyWatercraftCoverageModalStatus,
            isOpen: false
        });
    };

    const openCopyCoveragesModal = (watercraftPublicID = null) => {
        setWatercraftCoveragesDetailsModalStatus({
            ...watercraftCoveragesDetailsModalstatus,
            isOpen: false,
        });
        setCopyWatercraftCoverageModalStatus({
            ...copyWatercraftCoverageModalStatus,
            isOpen: true,
            triggerCopyCoverageWatercraftPublicID: watercraftPublicID,
        });
    };

    const onCopyCoverageButtonClick = () => {
        openCopyCoveragesModal();
    }

    const updateSubmissionVMOnCopyCoverage = useCallback(
        async (copyFromPublicID, copyToPublicIDs) => {
            const coverages = _.get(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
            const clausesToUpdate = WALCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);

            const response = await coveragesService.copyWatercraftCoverages(
                jobID,
                sessionUUID,
                clausesToUpdate,
                copyFromPublicID,
                copyToPublicIDs,
                authHeader,
            )
            const lobCoverages = _.get(response, lobName);
            const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
            _.set(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
            updateErrorsAndWarnings(errorsAndWarnings)
            updateWizardData(submissionVM)
            
        },
        [
            submissionVM,
            lobName,
            selectedVersionIndex,
            coveragesService,
            jobID,
            sessionUUID,
            authHeader,
            updateErrorsAndWarnings,
            updateWizardData
        ],
    );

    const hasMumultiWatercrafts = watercrafts.filter((watercraft) => WALCoverageUtil.getIsPhysicalDamageCovRequired(watercraft)).length > 1;
    
    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            WALWatercraftCoveragesCopyCoverageButton: {
                visible: isEditable && hasMumultiWatercrafts,
            }
        };
    }, [
        isEditable,
        hasMumultiWatercrafts,
    ]);

    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCopyCoverageButtonClick: onCopyCoverageButtonClick,
            renderWatercraftDescriptionCell: renderWatercraftDescriptionCell,
            renderWaterCraftPhysicalDamage: renderWaterCraftPhysicalDamage,
            renderWatercraftIncreaseLimitsTowing: renderWatercraftIncreaseLimitsTowing,
            renderWatercraftOther: renderWatercraftOther,
            renderWatercraftAction: renderWatercraftAction,
        },
        resolveComponentMap: {
        }
    };

    return (
        <>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
            {
                watercraftCoveragesDetailsModalstatus.isOpen ? <WatercraftCoveragesModal
                    title={watercraftCoveragesDetailsModalstatus.title}
                    size="lg"
                    watercraftPublicID={watercraftCoveragesDetailsModalstatus.watercraftID}
                    submissionVM={submissionVM}
                    updateWizardData={updateWizardData}
                    isOpen={watercraftCoveragesDetailsModalstatus.isOpen}
                    onResolve={onResolveWatercraftCoveragesDetailsModal}
                    openCopyCoveragesModal={openCopyCoveragesModal}
                    isEditable={isEditable}
                    // loadingVehicleCoverages={loadingVehicleCoverages}
                    selectedVersionIndex={selectedVersionIndex}
                    updateErrorsAndWarnings={updateErrorsAndWarnings}
                    coveragesService={coveragesService}
                    lobName="watercraft"
                    currentPageId={currentPageId}
                /> : null
            }
            {
                copyWatercraftCoverageModalStatus.isOpen ? <CopyWatercraftCoverageModal
                    isOpen={copyWatercraftCoverageModalStatus.isOpen}
                    size="lg"
                    onResolve={onResolveCopyWatercraftCoverageModal}
                    onReject={onRejectCopyWatercraftCoverageModal}
                    triggerCopyCoverageWatercraftPublicID={
                        copyWatercraftCoverageModalStatus.triggerCopyCoverageWatercraftPublicID
                    }
                    submissionVM={submissionVM}
                    updateSubmissionVMOnCopyCoverage={updateSubmissionVMOnCopyCoverage}
                /> : null
            }
        </>
    );

}

WALWaterCraftCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
WALWaterCraftCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default WALWaterCraftCoverages;