
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { HOTooltipUtil as tooltips } from 'wni-portals-tooltip';
import ResidenceUtil from '../../util/ResidenceUtil';
import metadata from './ProtectionComponent.metadata.json5';
import messages from '../../HOResidencePage.messages';

const ProtectionComponent = (props) => {
    const {
        model: protectionVM,
        isReadOnly,
        onValidate,
        showErrors,
        onValueChange,
        isRCTSuccess,
        RCTSubmission,
        updateValidationIssues,
        validationIssues,
        isRequiredForIssuance,
        isReadonlyFireProtectionClass,
        isShowdIstanceToFire,
        linePath,
        DPOverrideProps
    } = props;

    const {
        distanceToFireHydrant,
        distanceToFireStation
    } = protectionVM.value;
    const translator = useTranslator();
    const [homeMonitoringSystemsValue, updateHomeMonitoringSystemsValue] = useState([]);
    const { burglarAlarmPrefix } = ResidenceUtil.residenceConstants;
    const { fireAlarmPrefix } = ResidenceUtil.residenceConstants;

    const getHomeMonitoringSystemsValue = useCallback(() => {
        const homeMonitoringSystems = _.get(protectionVM.value, 'homeMonitoringSystems');
        const burglarAlarm = _.get(protectionVM.value, 'burglarAlarmType');
        const fireAlarm = _.get(protectionVM.value, 'fireAlarmType');
        const burglarAlarmId = `${burglarAlarmPrefix}${burglarAlarm}`;
        const fireAlarmId = `${fireAlarmPrefix}${fireAlarm}`;

        if (!_.includes(homeMonitoringSystems, burglarAlarmId) && !_.isEmpty(burglarAlarm) && burglarAlarm !== 'none') {
            homeMonitoringSystems.push(burglarAlarmId);
        }
        if (!_.includes(homeMonitoringSystems, fireAlarmId) && !_.isEmpty(fireAlarm) && fireAlarm !== 'none') {
            homeMonitoringSystems.push(fireAlarmId);
        }

        return homeMonitoringSystems;
    }, [protectionVM]);

    useEffect(() => {
        updateHomeMonitoringSystemsValue(getHomeMonitoringSystemsValue());
    }, []);

    const getAvailableHOSafetyFeatures = () => {
        const HOSafetyFeatureValues = _.get(protectionVM, 'sampleHOSafetyFeature.aspects.availableValues');
        const burglarAlarmValues = _.get(protectionVM, 'burglarAlarmType.aspects.availableValues');
        const fireAlarmValues = _.get(protectionVM, 'fireAlarmType.aspects.availableValues');

        const HOSafetyFeatureOptions = HOSafetyFeatureValues ? HOSafetyFeatureValues.map((option) => {
            return {
                code: option.code,
                name: {
                    id: option.name,
                },
            };
        }) : [];

        const burglarAlarmValuesWithoutNone = burglarAlarmValues ? _.filter(burglarAlarmValues, (value) => value.code !== 'none') : [];
        const burglarAlarmOptions = burglarAlarmValuesWithoutNone.map((option) => {
            return {
                code: `${burglarAlarmPrefix}${option.code}`,
                name: `${burglarAlarmPrefix}${translator({
                    id: option.name
                })}`,
            };
        });
        const fireAlarmValuesWithoutNone = fireAlarmValues ? _.filter(fireAlarmValues, (value) => value.code !== 'none') : [];
        const fireAlarmOptions = fireAlarmValuesWithoutNone.map((option) => {
            return {
                code: `${fireAlarmPrefix}${option.code}`,
                name: `${fireAlarmPrefix}${translator({
                    id: option.name
                })}`
            };
        });
        return _.concat(HOSafetyFeatureOptions, burglarAlarmOptions, fireAlarmOptions);
    };

    const handleHomeMonitoringSystems = useCallback((value) => {
        const burglarAlarmValues = _.filter(value, (item) => _.startsWith(item, burglarAlarmPrefix));
        let newValidationIssues = _.cloneDeep(validationIssues);
        if (burglarAlarmValues.length > 1) {
            // _.remove(value, (item) => item === burglarAlarmValues[0]);
            newValidationIssues.push({
                type: 'error',
                reason: translator(messages.burglarAlarmError)
            });
        } else {
            newValidationIssues = _.filter(newValidationIssues, (issue) => issue.reason !== translator(messages.burglarAlarmError))
        }
        const fireAlarmValues = _.filter(value, (item) => _.startsWith(item, fireAlarmPrefix));
        if (fireAlarmValues.length > 1) {
            // _.remove(value, (item) => item === fireAlarmValues[0]);
            newValidationIssues.push({
                type: 'error',
                reason: translator(messages.fireAlarmError)
            });
        } else {
            newValidationIssues = _.filter(newValidationIssues, (issue) => issue.reason !== translator(messages.fireAlarmError))
        }
        updateValidationIssues(newValidationIssues);
        onValueChange(value, 'homeMonitoringSystems');
        updateHomeMonitoringSystemsValue(value);
    }, [protectionVM]);

    const handleChangeFire = useCallback((value ,path) => {
        onValueChange(value ,path);
        if (linePath !== 'homeowners') {
            return;
        }
        let distanceToFireHydrantVal = '';
        let distanceToFireStationVal = '';
        let protectionClassRes = '';
        if (path === 'distanceToFireHydrant') {
            distanceToFireHydrantVal = value;
            distanceToFireStationVal = distanceToFireStation;
        }
        if (path === 'distanceToFireStation') {
            distanceToFireHydrantVal = distanceToFireHydrant;
            distanceToFireStationVal = value;
        }
        if (!_.isEmpty(distanceToFireHydrantVal) && _.isNumber(distanceToFireStationVal)) {
            if (distanceToFireHydrantVal === 'lessthan1000feet' && distanceToFireStationVal <= 5) {
                protectionClassRes = 'FullyProtected_Ext';
            }
            if (distanceToFireHydrantVal === 'greaterthan1000feet' && distanceToFireStationVal <= 5) {
                protectionClassRes = 'PartiallyProtected_Ext';
            }
            if (distanceToFireStationVal > 5) {
                protectionClassRes = 'Unprotected_Ext';
            }
        }
        onValueChange(protectionClassRes ,'protectionClass');
    }, [distanceToFireHydrant, distanceToFireStation, protectionVM]);

    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            isRequiredForIssuance
        },
        fireProtectionClass: {
            visible: linePath !== 'homeowners',
            readOnly:  isReadonlyFireProtectionClass
        },
        distanceToFireHydrant: {
            visible: isShowdIstanceToFire,
            onValueChange: handleChangeFire
        },
        distanceToFireStation: {
            visible: isShowdIstanceToFire,
            onValueChange: handleChangeFire
        },
        burglarAlarm: {
            defaultValue: 'none',
            // readOnly: isRCTSuccess || !_.isNil(RCTBurglarAlarmType)
        },
        fireAlarm: {
            defaultValue: 'none',
            // readOnly: isRCTSuccess || !_.isNil(RCTFireAlarmType)
        },
        homeMonitoringSystems: {
            // readOnly: isRCTSuccess || (!_.isNil(RCTHomeMonitoringSystems) && !_.isEmpty(RCTHomeMonitoringSystems)),
            availableValues: getAvailableHOSafetyFeatures(),
            value: homeMonitoringSystemsValue,
            onValueChange:handleHomeMonitoringSystems,
            tooltip: {
                text: tooltips.homeMonitoringSystems
            }
        }
    };

    const overrides = _.mergeWith(overrideProps, DPOverrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={protectionVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

ProtectionComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    isRCTSuccess: PropTypes.bool,
    RCTSubmission: PropTypes.shape({}),
    isReadonlyFireProtectionClass: PropTypes.bool,
    isShowdIstanceToFire: PropTypes.bool
};

ProtectionComponent.defaultProps = {
    model: {},
    showErrors: true,
    isReadOnly: false,
    onValidate: _.noop,
    isRCTSuccess: false,
    RCTSubmission: {},
    isReadonlyFireProtectionClass: true,
    isShowdIstanceToFire: false
};

export default ProtectionComponent;
