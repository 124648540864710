import _ from 'lodash';

const FILTER_SUBMISSION_STATUS = ["In Force", "Scheduled", "Approved", "Rated", "Quoted"];
/**
 * 
 * @param {string} currentProductCode 
 * @param {array} policySummaries 
 * @param {array} accountActiveQuotes 
 * @returns {array}
 */
function getAlreadyOwnedProducts(
    currentProductCode,
    policySummaries = [],
    accountActiveQuotes = []
) {
    const alreadyOwnedProducts = [currentProductCode];

    const activePolicies = policySummaries.filter((policy) => {
        const policyDisplayStatus = _.get(policy, 'displayStatus');
        return FILTER_SUBMISSION_STATUS.includes(policyDisplayStatus);
    });

    const activeQuotes = accountActiveQuotes.filter((quote) => {
        const quoteDisplayStatus = _.get(quote, 'jobStatus');
        return FILTER_SUBMISSION_STATUS.includes(quoteDisplayStatus);
    });

    activePolicies.forEach((policy) => {
        const policyProductCode = _.get(policy, 'product.productCode');
        if (!alreadyOwnedProducts
            .some((item) => item === policyProductCode)
        ) {
            alreadyOwnedProducts.push(policyProductCode);
        }
    });

    activeQuotes.forEach((quote) => {
        const quoteProductCode = _.get(quote, 'productCode');
        if (!alreadyOwnedProducts
            .some((item) => item === quoteProductCode)
        ) {
            alreadyOwnedProducts.push(quoteProductCode);
        }
    });
    return alreadyOwnedProducts
};

/**
 * Filter out current JobID from accountActiveJobDTOList
 *
 * INPUT:
 * A List of AccountActiveJobDTO list, along with current JobID
 *
 * OUTPUT:
 * A List of AccountActiveJobDTO list, without current JobID
 *
 *
 * @param {array} accountActiveJobDTOList
 * @param {string} currentJobID
 * @returns {array} an Array of AccountActiveJobDTO
 */
function filterActiveJobDTOsByJobID(
    accountActiveJobDTOList = [],
    currentJobID
) {
    const retval = accountActiveJobDTOList.filter((accountActiveJobDTO) => {
        return accountActiveJobDTO.jobID !== currentJobID;
    });
    return retval;
}

/**
 * Filter currentPeriodID from accountActiveJobDTO list
 *
 * @param {array} accountActiveJobDTOList
 * @param {string} currentJobID
 * @param {string} currentPeriodID
 * @returns {array}
 */
function filterActiveJobDTOsByPeriodID(
    accountActiveJobDTOList = [],
    currentJobID,
    currentPeriodID
) {
    const retval = [];
    accountActiveJobDTOList.forEach((accountActiveJobDTO) => {
        const { jobID, periods = [] } = accountActiveJobDTO;
        if (jobID !== currentJobID) {
            retval.push(accountActiveJobDTO);
            return;
        }

        const filteredPeriods = periods.filter(
            (period) => period.publicID !== currentPeriodID
        );
        if (!_.isEmpty(filteredPeriods)) {
            retval.push({
                ...accountActiveJobDTO,
                periods: filteredPeriods,
            });
        }
    });
    return retval;
}

function hasMultiplePeriodsForJob(accountActiveJobDTOList = [], currentJobID) {
    const activeJobDTO = accountActiveJobDTOList.find(
        (dto) => dto.jobID === currentJobID
    );

    if (!activeJobDTO) {
        return false;
    }
    //
    const { periods: jobPeriods = [] } = activeJobDTO;

    if (_.isEmpty(jobPeriods)) {
        return false;
    }
    return jobPeriods.length > 1;
}

function hasMultiplePeriodsForPAJob(
    accountActiveJobDTOList = [],
    currentJobID
) {
    const activeJobDTO = accountActiveJobDTOList.find(
        (dto) => dto.jobID === currentJobID
    );

    if (!activeJobDTO) {
        return false;
    }
    //
    const { periods: jobPeriods = [] } = activeJobDTO;

    if (_.isEmpty(jobPeriods)) {
        return false;
    }
    const firstPeriodBranchName = jobPeriods[0].branchName;
    return jobPeriods.some((item) => item.branchName !== firstPeriodBranchName);
}

function hasOnlyOneActiveJob(accountActiveJobDTOList = []) {
    return accountActiveJobDTOList.length === 1;
}

// ====================================
/**
 * Try to find specified AccountActiveJobDTO insrtance by jobID.
 * undefined is returned if it could not be found
 *
 * @param {array} accountActiveJobDTOList
 * @param {string} currentJobID
 * @returns {object} the AccountJobDTO instance, or undefined
 */
function findActiveJobDTOByJobID(accountActiveJobDTOList = [], currentJobID) {
    const retval = accountActiveJobDTOList.find(
        (dto) => dto.jobID === currentJobID
    );
    return retval;
}

/**
 * Filter out AccontJobPeriodDTO array with currentJobID but not selectVersion
 * @param {array} accountActiveJobDTOList
 * @param {string} currentJobID
 * @param {string} currentPeriodID
 * @returns {array}
 */
function filterAccountJobPeriodDTOList(
    accountActiveJobDTOList,
    currentJobID,
    currentPeriodID
) {
    const accountActiveJobDTO = findActiveJobDTOByJobID(
        accountActiveJobDTOList,
        currentJobID
    );
    if (!accountActiveJobDTO) {
        return [];
    }

    const { periods = [] } = accountActiveJobDTO;
    const filteredPeriods = periods.filter(
        (period) => period.publicID !== currentPeriodID
    );

    return filteredPeriods;
}

/**
 * @param {array} accountActiveJobDTOList
 * @param {string} currentJobID
 * @param {string} currentPeriodID
 * @returns {array}
 */
function findAccountJobPeriodDTO(
    accountActiveJobDTOList,
    currentJobID,
    currentPeriodID
) {
    const accountActiveJobDTO = findActiveJobDTOByJobID(
        accountActiveJobDTOList,
        currentJobID
    );

    let retval;
    if (accountActiveJobDTO) {
        const { periods = [] } = accountActiveJobDTO;
        const findResult = periods.find(
            (period) => period.publicID === currentPeriodID
        );

        retval = findResult;
    }
    return retval;
}

function hasMultiplePeriodsInAccountJob(accountJobs, jobID, 
    productCode) {
    const retval = productCode === 'PersonalAuto' ? 
        hasMultiplePeriodsForPAJob(accountJobs, jobID)
        : hasMultiplePeriodsForJob(accountJobs, jobID);
    return retval;
}

export default {
    getAlreadyOwnedProducts,
    filterActiveJobDTOsByJobID,
    filterActiveJobDTOsByPeriodID,
    //
    hasMultiplePeriodsForJob,
    hasMultiplePeriodsForPAJob,
    hasMultiplePeriodsInAccountJob,
    //
    hasOnlyOneActiveJob,
    // =================================
    filterAccountJobPeriodDTOList,
    findAccountJobPeriodDTO,
    // =================================
};
