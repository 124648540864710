import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wnigatewaypachange'), method, data, additionalHeaders);
}

export default class WniPAPolicyChangeService {
    static savePolicyDetails(
        jobID,
        policyNumber,
        description,
        baseData,
        preQualQuestionSet,
        flowStepID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('wnigatewaypachange'),
            'savePolicyDetails', [jobID, policyNumber, description, baseData, preQualQuestionSet, flowStepID], additionalHeaders);
    }

    /**
     * Note: Consider moving this method to some place like WniPolicyChangeService
     * Invokes WniSideBySidePolicyChangeHandler.issue()
     * @param {String} jobNumber policyChange job number
     * @param {boolean} ignoreWarning
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static issue(jobNumber, ignoreWarning, authHeader = {}) {
        return processSubmission('issue', [jobNumber, ignoreWarning], authHeader);
    }

    static updateCoverages(quoteID, sessionUUID, clausesToUpdate, additionalHeaders = {}) {
        return processSubmission('updateCoverages', [quoteID, clausesToUpdate], additionalHeaders);
    }

    /**
     * Updates coverages.
     *
     * @param {Object} jobID jobID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} copyFromVehiclePublicID copyFromVehicle publicID
     * @param {Object} copyToVehiclesPublicID copyToVehicle publicID array
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static copyVehicleCoverages(
        jobID,
        sessionUUID,
        copyFromVehiclePublicID,
        copyToVehiclesPublicID,
        additionalHeaders = {}
    ) {
        return processSubmission(
            'copyVehicleCoverages',
            [jobID, sessionUUID, copyFromVehiclePublicID, copyToVehiclesPublicID],
            additionalHeaders
        );
    }

    /**
     * Invokes WniGateWayPersonalAutoPolicyChange.referToUnderwriter()
     * ref: SubmissionService.referTloUnderwriter()
     * @param {String} jobNumber
     * @param {String} noteBody
     * @param {object} additionalHeaders
     * @returns {object} tjhe Promise from backend call
     */
    static referToUnderwriter(jobNumber, noteBody, additionalHeaders = {}) {
        return processSubmission('referToUnderwriter', [jobNumber, noteBody], additionalHeaders);
    }
}
