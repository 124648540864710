const HOPSPPTypeAntennaSaucers = "AntennaSaucers_Ext"
const HOPSPPTypeBaseballCards = "BaseballCards_Ext"
const HOPSPPTypeBicycles = "Bicycles_Ext"
const HOPSPPTypeCamerasNonProfession = "CamerasNonProfession_Ext"
const HOPSPPTypeDocksEtc = "DocksEtc_Ext"
const HOPSPPTypeElectronics = "Electronics_Ext"
export const HOPSPPTypeFineArtsAntiques = "FineArtsInclAntique_Ext"
const HOPSPPTypeFishHouse = "FishHouse_Ext"
const HOPSPPTypeFurs = "Furs_Ext"
const HOPSPPTypeGroundsCareTractorOnPrem = "GroundsCareTractoOnPrem_Ext"
const HOPSPPTypeGuns = "Guns_Ext"
const HOPSPPTypeHearingAids = "HearingAids_Ext"
export const HOPSPPTypeJewelry = "Jewelry_Ext"
const HOPSPPTypeLawnmower = "Lawnmower_Ext"
const HOPSPPTypeMedicalEquipment = "MedicalEquipment_Ext"
const HOPSPPTypeMusicalInstNonProf = "MusicalInstNonProf_Ext"
const HOPSPPTypeMusicalInstSchool = "MusicalInstSchool_Ext"
const HOPSPPTypeSilverware = "Silverware_Ext"
const HOPSPPTypeSportsEquipment = "SportsEquipment_Ext"
const HOPSPPTypeToolsNonProfessional = "ToolsNonProfessional_Ext"

const HOPSPPDeductibleOptionCode0 = "HOPScheduledPersonalPropertyItemDeductible0"
const HOPSPPDeductibleOptionCode50 = "HOPScheduledPersonalPropertyItemDeductible50_Ext"
const HOPSPPDeductibleOptionCode100 = "HOPScheduledPersonalPropertyItemDeductible100_Ext"
const HOPSPPDeductibleOptionCode250 = "HOPScheduledPersonalPropertyItemDeductible250_Ext"
const HOPSPPDeductibleOptionCode500 = "HOPScheduledPersonalPropertyItemDeductible500"
const HOPSPPDeductibleOptionCode1000 = "HOPScheduledPersonalPropertyItemDeductible1000"
const HOPSPPDeductibleOptionCode2500 = "HOPScheduledPersonalPropertyItemDeductible2500"

export const HOSSPDeductibleOptionValueMap = {
    [HOPSPPDeductibleOptionCode0]: 0,
    [HOPSPPDeductibleOptionCode50]: 50,
    [HOPSPPDeductibleOptionCode100]: 100,
    [HOPSPPDeductibleOptionCode250]: 250,
    [HOPSPPDeductibleOptionCode500]: 500,
    [HOPSPPDeductibleOptionCode1000]: 1000,
    [HOPSPPDeductibleOptionCode2500]: 2500,
}

export const HOSSPAgreedValueOptionCodes = {
    Yes: 'Yes',
    No: 'No',
}
export const HOSSPWithBreakageOptionCodes = {
    Yes: 'Yes',
    No: 'No',
}

export const getNewDeductibleOptionsByType = (type, alloptions) => {
    // Copy from PC: HOPLineUIHelper.gs filterOfSchedPersonalProperty_Ext
    const newOptions = alloptions.filter((option) => {
        switch (type) {
            case HOPSPPTypeAntennaSaucers :
                return option.code === HOPSPPDeductibleOptionCode50
    
            case HOPSPPTypeBaseballCards :
                return option.code === HOPSPPDeductibleOptionCode100
    
            case HOPSPPTypeBicycles :
                return option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeCamerasNonProfession :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeDocksEtc :
                return option.code === HOPSPPDeductibleOptionCode1000
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeElectronics :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode50
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeFineArtsAntiques :
                return option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
                  || option.code === HOPSPPDeductibleOptionCode1000
                  || option.code === HOPSPPDeductibleOptionCode2500
    
            case HOPSPPTypeFishHouse :
                return option.code === HOPSPPDeductibleOptionCode0
    
            case HOPSPPTypeFurs :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode100
    
            case HOPSPPTypeGroundsCareTractorOnPrem :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode50
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeGuns :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode50
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeHearingAids :
                return option.code === HOPSPPDeductibleOptionCode50
    
            case HOPSPPTypeJewelry :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
                  || option.code === HOPSPPDeductibleOptionCode1000
                  || option.code === HOPSPPDeductibleOptionCode2500
    
            case HOPSPPTypeLawnmower :
                return option.code === HOPSPPDeductibleOptionCode0
    
            case HOPSPPTypeMedicalEquipment :
                return option.code === HOPSPPDeductibleOptionCode50
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeMusicalInstNonProf :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode50
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
                  || option.code === HOPSPPDeductibleOptionCode1000
    
            case HOPSPPTypeMusicalInstSchool :
                return option.code === HOPSPPDeductibleOptionCode0
    
            case HOPSPPTypeSilverware :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode100
    
            case HOPSPPTypeSportsEquipment :
                return option.code === HOPSPPDeductibleOptionCode0
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            case HOPSPPTypeToolsNonProfessional :
                return option.code === HOPSPPDeductibleOptionCode50
                  || option.code === HOPSPPDeductibleOptionCode100
                  || option.code === HOPSPPDeductibleOptionCode250
                  || option.code === HOPSPPDeductibleOptionCode500
    
            default :
                return false
        }
    })
    return newOptions
}

