
const explainBoxUWQuestionCode = [
    // preQual UW questions
    'anyCoverageDeclinedExplain_Ext',
    'hasLienLast5YrsExplain_Ext',
    // driver UW questions
    'DriverLicSuspLastFiveYearsYNExplain_Ext',
    'DriverAnyCurFinResFilingYNExplain_Ext',
];

export default {
    explainBoxUWQuestionCode
};
