import React, {useMemo, useCallback, useContext, useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, getFlattenedUiPropsContent, ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useWniModal } from 'wni-components-platform-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import PUAnimalsPopup from '../PUAnimalsPopup/PUAnimalsPopup';
import metadata from './DwellingDetails.metadata.json5';
import messages from '../../PUUnderlyingPage.messages';
import styles from '../../PUUnderlyingPage.module.scss'
import { isPropertyFieldVisible } from '../../util/Underlying.util';
import { Button } from '@jutro/legacy/components';

function DwellingDetails(props) {
    const {
        currentRow,
        coveredItem,
        onValueChange,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance,
        propertyLocations,
        syncWizardData,
        unUsedPropertyLocations
    } = props;

    const translator = useTranslator();
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);

    const homeownerVM = _.get(currentRow, coveredItem) || {};
    const animalsVM = _.get(currentRow, `${coveredItem}.puDwellingAnimals`);
    const {
        propertyLocation,
        ownAnyAnimals
    } = homeownerVM.value || {};
    const propertyLocationVM = _.get(homeownerVM, 'propertyLocation') || {};
    const [locationDtoVM, updateLocationDtoVM] = useState(viewModelService.clone(propertyLocationVM));
    const [propertyLocationValue, updatePropertyLocationValue] = useState(propertyLocation);
    const propertyLocationArr = propertyLocations.concat(unUsedPropertyLocations);

    const showAnimalModal = useCallback(() => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            size: 'lg',
            isReadOnly,
            animalsVM,
            currentRow,
            onValueChange,
        };
        return modalApi.showModal(
            <PUAnimalsPopup {...componentProps} />
        );
    }, [isReadOnly, currentRow]);

    const onDetailsLinkClick = useCallback(() => {
        showAnimalModal().then((res) => {
            // editAnimal(res);
        }).catch(() => {
            _.noop();
        });
    }, []);

    const createEmptyVM = useCallback(() => {
        const emptyVM = viewModelService.create({ 
            address: {
                country: 'US'
            }
        },
        'pc', 'edge.capabilities.policylocation.lob.workerscomp.dto.PolicyLocationDTO');
        return emptyVM;
    }, [viewModelService]);
    const newLocationVM = createEmptyVM()

    const ViewOrEditButtonLink = useCallback(() => {
        if (ownAnyAnimals === true) {
            return <Button className="wni-button-link" onClick={onDetailsLinkClick} >
                &nbsp;&nbsp;{translator(isReadOnly ? messages.viewAnimalDetail : messages.viewOrEditAnimalDetail)}
            </Button>
        }
        return null
    }, [ownAnyAnimals]);

    const getPropertyLocationOptions = () => {

        const options = _.map(propertyLocationArr, (option) => {
            return {
                code: _.get(option, 'publicID'),
                name: _.get(option, 'address.displayName')
            }
        });
        options.push({
            code: 'new',
            name: translator(messages.new),
            country: 'US'
        });
        return options;
    };

    const getPropertyLocationValue = () => {
        if (propertyLocation) {
            // return {
            //     code: _.get(propertyLocationValue, 'publicID'),
            //     name: _.get(propertyLocationValue, 'address.displayName')
            // };
            return _.get(propertyLocation, 'publicID')
        }
        return null;
    };

    const handlePropertyLocation = (value, path) => {
        if(value === 'new') {
            onValueChange({
                publicID: 'new',
                isEditable: true,
            }, 'property.propertyLocation');
        } else {
            onValueChange(_.find(propertyLocationArr, (location => location.publicID === value)), 'property.propertyLocation');
        }
    };

    const getVisibleField = (fieldPath) => {
        return isPropertyFieldVisible(homeownerVM, fieldPath);
    };

    const onAddressChange = useCallback((value, path) => {
        onValueChange(value, `property.propertyLocation.${path}`);
    }, [onValueChange]);

    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        ownAnyAnimals: {
            secondaryLabel: <ViewOrEditButtonLink />
        },
        propertyLocation: {
            value: getPropertyLocationValue(),
            availableValues: getPropertyLocationOptions(),
            onValueChange: handlePropertyLocation
        },
        propertyLocationSection:{
            className: styles.propertyAddressContainer,
        },
        addressContainer: {
            model: propertyLocationVM,
            visible: !!_.get(propertyLocationVM, 'value.isEditable'),
            dataPath: 'address',
            onAddressChange: onAddressChange,
            hideFieldType: {
                country: true,
                addressType: true,
                pobox: true
            },
            showErrors: showErrors,
            readOnly: isReadOnly,
            onValidate
        },
        approvedFence: {
            visible: getVisibleField('approvedFence')
        },
        approvedFenceExplain: {
            visible: getVisibleField('approvedFenceExplain')
        },
        divingBoard: {
            visible: getVisibleField('divingBoard')
        },
        depthOfThePool: {
            visible: getVisibleField('depthOfThePool')
        },
        isTrampolineNetted: {
            visible: getVisibleField('isTrampolineNetted')
        },
        isTrampolineSecured: {
            visible: getVisibleField('isTrampolineSecured')
        },
        businessTypeExplain: {
            visible: getVisibleField('businessTypeExplain')
        },
        isPropertyRentedToOthers: {
            visible: getVisibleField('isPropertyRentedToOthers')
        },
        additionalBusinessType: {
            visible: getVisibleField('additionalBusinessType')
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, homeownerVM, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={homeownerVM}
            overrideProps={overrides}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

DwellingDetails.propTypes = {
    propertyLocations: PropTypes.arrayOf(PropTypes.shape({})),
    unUsedPropertyLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

DwellingDetails.defaultProps = {
    propertyLocations: [],
    unUsedPropertyLocations: []
};

export default DwellingDetails;
