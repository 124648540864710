import React, {
} from "react";
import _ from 'lodash';
import PropTypes from 'prop-types';
import messages from './AutoDeathBenefitsCoverage.messages'
import RTOperatorCoverageConfig from "../../RTOperatorCoverage.config";
import SingleOperatorClause from "../SingleOperatorClause";

const {
    OperatorCoverageCode,
    OperatorCoverageLimitScheduleItemTermCode,
} = RTOperatorCoverageConfig

function AutoDeathBenefitsCoverage(props) {

    return <SingleOperatorClause
        clauseCode = {OperatorCoverageCode.AutoDeathBenefits}
        limitScheduleItemTermCode = {OperatorCoverageLimitScheduleItemTermCode.AutoDeathBenefitsScheduleItemSum}
        label = {messages.RTAutoDeathBenefits}
        {...props}
    />
}

AutoDeathBenefitsCoverage.propTypes = {
    policyContactRole: PropTypes.shape({}).isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    lineCoveragePath: PropTypes.string.isRequired,
    updateSubmissionVMToServer: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
};
AutoDeathBenefitsCoverage.defaultProps = {
    onValidate: _.noop,
    showErrors: false,
}

export default AutoDeathBenefitsCoverage;