import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMBoatCoverageService } from 'wni-capability-quoteandbind-im';
import { WniCheckboxField } from 'wni-common-base-components';
import messages from './CoveragesAccordionCard.messages'

import IMCoverageUtil from '../../../../util/IMCoverageUtil';
import CoverageContext from '../../../../context/IMCoverageContext';
import SingleClauseContext from '../../../../context/IMSingleClauseContext';
import IMTermComponent from '../../../../components/IMSingleClauseComponentVM/IMTermComponent';

const CoveragesAccordionCard = (props) => {

    const {
        coveragePartClauses,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { mainCoverages, isMiscBoatingEquipment, isRentedBoats } = coveragePartClauses;

    const [imboatCoverageCov] = mainCoverages

    const {
        code_Ext: imboatCoverageCovCode,
        terms: imboatCoverageCovTerms
    } = imboatCoverageCov

    const [
        imBoatCoverageDeductible, 
        imBoatPropertyDamageLiability,
        imBoatLimit,
    ] = imboatCoverageCovTerms

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();

    const validationID = 'BoatCoveragesAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])

    const changeClauses = useCallback(
        (value, changedPath, clausesPath, originClauses) => {
            const newClauses = IMCoverageUtil.setClauseValue(originClauses, clausesPath, value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, clausesPath, newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, setCoveragePartClauses, setIsEditing]
    );

    const changeMainCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, 'mainCoverages', mainCoverages);
        },
        [changeClauses, mainCoverages]
    );


    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        const coveragePartClausesToUpdate = IMCoverageUtil.generateUpdatedBoatCoverageClausesDTO(newCoveragePartClauses);
        setLoadingMask(true)
        const coveragePartClausesFromServer = await IMBoatCoverageService.updateBoatCoverageClauses(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCoveragePartClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeMainCoveragesAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeMainCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeMainCoverages, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    const valueChangeTerm = async (termName, checked) => {
        setLoadingMask(true)
        const serviceData = {
            [termName]: checked
        }
        const coveragePartClausesFromServer = await IMBoatCoverageService.postOnChangeCoverageTerm(
            jobID,
            sessionUUID,
            serviceData,
            authHeader
        )
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }

    const mainCoverageTermComponents = () => {
        const imBoatRentedBoatMotorLimit = imboatCoverageCovTerms[imboatCoverageCovTerms.length - 1];

        return <>
            <IMTermComponent
                key={_.get(imBoatCoverageDeductible, 'code_Ext')}
                term={imBoatCoverageDeductible}
                termIndex = {0}
            />
            <div className='wizardTitle font-normal'>
                <h4>{translator(messages.coveragesExtensions)}</h4>
            </div>
            <IMTermComponent
                key={_.get(imBoatPropertyDamageLiability, 'code_Ext')}
                term={imBoatPropertyDamageLiability}
                termIndex = {1}
            />
            <WniCheckboxField 
                value={isMiscBoatingEquipment}
                label={translator(messages.miscBoatingEquipment)}
                onValueChange={(checked) => valueChangeTerm('isMiscBoatingEquipment', checked)}
                showInlineLabel
                readOnly={!isEditable}
            />
            {
                isMiscBoatingEquipment && <div className='subIndentSection'>
                    <IMTermComponent
                        key={_.get(imBoatLimit, 'code_Ext')}
                        term={imBoatLimit}
                        termIndex = {2}
                    />
                </div>
            }
            <div className='wizardTitle font-normal'>
                <h4>{translator(messages.optionalCoverages)}</h4>
            </div>
            <WniCheckboxField 
                value={isRentedBoats}
                label={translator(messages.rentedBoats)}
                onValueChange={(checked) => valueChangeTerm('isRentedBoats', checked)}
                showInlineLabel
                readOnly={!isEditable}
            />
            {
                isRentedBoats &&  <div className='subIndentSection'>
                    <IMTermComponent
                        key={_.get(imBoatRentedBoatMotorLimit, 'code_Ext')}
                        term={imBoatRentedBoatMotorLimit}
                        termIndex = {imboatCoverageCovTerms.length - 1}
                    />  
                </div>
                
            }
        </>
            
    }

    return (
        <div className="formCoveragesContainer">
            <CoverageContext.Provider
                value={{
                    wizardData: submissionVM,
                    updateWizardData: updateWizardDataWhileSetPeriodStatus,
                    clausesContainer: coveragePartClauses,
                    updateClausesContainer: setCoveragePartClauses,
                    updateVMToServer: updateVMToServer
                }}
            >
                <SingleClauseContext.Provider value={{
                    clauseCode: imboatCoverageCovCode,
                    clausePath: 'mainCoverages[0]',
                    isEditable,
                    // isDisabled,
                    onChangeClause: changeMainCoverages,
                    onSyncCoverages: onSyncCoverages,
                    onChangeSubmissionAndSync: changeMainCoveragesAndSync,
                    labelPosition: 'left',
                    onValidate,
                    setIsEditing,
                    showErrors,
                }}>
                    { mainCoverageTermComponents() }
                    {/* {imboatCoverageCovTerms.map(term => 
                        mainCoverageTermComponents(term)
                    )} */}
                </SingleClauseContext.Provider>
            </CoverageContext.Provider>
            
        </div>
    )
}

export default CoveragesAccordionCard