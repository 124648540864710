import { defineMessages } from 'react-intl';

export default defineMessages({
    PropertyIncidentsQuestion: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.question',
        defaultMessage: "Was there any property damaged other than a vehicle?"
    },
    PropertyIncidentsQuestionWT: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.question wt',
        defaultMessage: "Was there any property damaged other than our watercraft?"
    },
    PropertyIncidentsQuestionRT: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.question rt',
        defaultMessage: "Was there any property damaged other than your unit?"
    },
    PropertyIncidentsQuestionGL: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.question gl',
        defaultMessage: "Was there any third party property damaged?"
    },
    Yes: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.No',
        defaultMessage: "No"
    },
    PropertySection: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.PropertySection',
        defaultMessage: "Property Section"
    },
    Number: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.No.',
        defaultMessage: "No."
    },
    PropertyLocation: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.PropertyLocation',
        defaultMessage: "Property Location"
    },
    PropertyDescription: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.PropertyDescription',
        defaultMessage: "Description"
    },
    PropertyAddress: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.PropertyAddress',
        defaultMessage: "Address"
    },
    PropertyCity: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.PropertyCity',
        defaultMessage: "City"
    },
    PropertyState: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.PropertyState',
        defaultMessage: "State"
    },
    Edit: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.Edit',
        defaultMessage: "Edit"
    },
    Delete: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.Delete',
        defaultMessage: "Delete"
    },
    DeleteProperty: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.DeleteProperty',
        defaultMessage: "Delete Property"
    },
    AddProperty: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.AddProperty',
        defaultMessage: "Add Property"
    },
    viewAndEditLabel: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.propertyIncidents.viewAndEditLabel',
        defaultMessage: "View/Edit"
    }
})