import { defineMessages } from 'react-intl';

export default defineMessages({
    description: {
        id: 'gateway.views.endorsement-documents.Description',
        defaultMessage: 'Description'
    },
    uwReferral: {
        id: 'quoteandbind.directives.MultiQuoteView.uwReferral',
        defaultMessage: 'UW Referral'
    },
    postIssueReview: {
        id: 'quoteandbind.directives.MultiQuoteView.postIssueReview',
        defaultMessage: 'Post-Issue Review',
    }
});
