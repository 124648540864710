import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

//
import DPPolicyChangeStartPage from './pages/Start/DPPolicyChangeStartPage';

import DPHouseholdInfoChangePage from './pages/HouseholdInfo/DPHouseholdInfoChangePage';
import DPHouseholdInfoChangeReadOnlyPage from './pages/HouseholdInfo/DPHouseholdInfoChangePage.readonly';

import DPResidenceChangePage from './pages/DPResidence/DPResidenceChangePage';
import DPResidenceChangeReadOnlyPage from './pages/DPResidence/DPResidenceChangePage.readonly';

import DPCoveragesChangePage from './pages/DPCoverages/DPCoveragesChangePage';
import DPCoveragesChangeReadOnlyPage from './pages/DPCoverages/DPCoveragesChangePage.readonly';

import DPLossHistoryAndPriorCarrierChangePage from './pages/LossHistoryAndPriorCarrier/DPLossHistoryAndPriorCarrierChangePage';
import DPLossHistoryAndPriorCarrierChangeReadOnlyPage from './pages/LossHistoryAndPriorCarrier/DPLossHistoryAndPriorCarrierChangePage.readonly';

import DPAdditionalInterestsChangePage from './pages/AdditionalInterests/DPAdditionalInterestsChangePage';
import DPAdditionalInterestsChangeReadOnlyPage from './pages/AdditionalInterests/DPAdditionalInterestsChangePage.readonly';

//
import DPQuoteChangePage from './pages/Quote/DPQuoteChangePage';
import DPQuoteChangeReadOnlyPage from './pages/Quote/DPQuoteChangePage.readonly';

import DPPaymentChangePage from './pages/Payment/DPPaymentChangePage';

import DPPaySuccessChangePage from './pages/PaySuccess/DPPaySuccessChangePage';


setComponentMapOverrides(
    {
        DPPolicyChangeStartPage: { component: 'DPPolicyChangeStartPage' },

        DPHouseholdInfoChangePage: { component: 'DPHouseholdInfoChangePage' },
        DPHouseholdInfoChangeReadOnlyPage: { component: 'DPHouseholdInfoChangeReadOnlyPage' },
        
        DPResidenceChangePage: { component: 'DPResidenceChangePage' },
        DPResidenceChangeReadOnlyPage: { component: 'DPResidenceChangeReadOnlyPage' },
        
        DPCoveragesChangePage: { component: 'DPCoveragesChangePage' },
        DPCoveragesChangeReadOnlyPage: { component: 'DPCoveragesChangeReadOnlyPage' },

        DPLossHistoryAndPriorCarrierChangePage: { component: 'DPLossHistoryAndPriorCarrierChangePage'},
        DPLossHistoryAndPriorCarrierChangeReadOnlyPage: {component: 'DPLossHistoryAndPriorCarrierChangeReadOnlyPage'},

        DPAdditionalInterestsChangePage: { component: 'DPAdditionalInterestsChangePage' },
        DPAdditionalInterestsChangeReadOnlyPage: { component: 'DPAdditionalInterestsChangeReadOnlyPage' },
        
        DPQuoteChangePage: { component: 'DPQuoteChangePage' },
        DPQuoteChangeReadOnlyPage: { component: 'DPQuoteChangeReadOnlyPage' },

        DPPaymentChangePage: { component: 'DPPaymentChangePage' },

        DPPaySuccessChangePage: { component: 'DPPaySuccessChangePage' },
    },
    {
        DPPolicyChangeStartPage,
        
        DPHouseholdInfoChangePage,
        DPHouseholdInfoChangeReadOnlyPage,

        DPResidenceChangePage,
        DPResidenceChangeReadOnlyPage,

        DPCoveragesChangePage,
        DPCoveragesChangeReadOnlyPage,

        DPLossHistoryAndPriorCarrierChangePage,
        DPLossHistoryAndPriorCarrierChangeReadOnlyPage,

        DPAdditionalInterestsChangePage,
        DPAdditionalInterestsChangeReadOnlyPage,

        DPQuoteChangePage,
        DPQuoteChangeReadOnlyPage,

        DPPaymentChangePage,

        DPPaySuccessChangePage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as DPPolicyChangeWizard } from './DPPolicyChangeWizard';
