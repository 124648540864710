import React from 'react'
import _ from 'lodash'
import TermFieldDropdown from './TermFieldComponents/TermFieldDropdown'
import TermFieldTextArea from './TermFieldComponents/TermFieldTextArea'
import TermFieldCurrency from './TermFieldComponents/TermFieldCurrency'
import TermFieldInputText from './TermFieldComponents/TermFieldInputText'
import TermFieldInputNumber from './TermFieldComponents/TermFieldInputNumber'
import TermFieldInputTextOther from './TermFieldComponents/TermFieldInputTextOther'
import TermFieldDirectInputNumber from './TermFieldComponents/TermFieldDirectInputNumber'
import TermFieldInputTextWithValidator from './TermFieldComponents/TermFieldInputTextWithValidator'

const IMScheduleDetailsCovTermField = (props) => {

    const {
        term,
        setScheduleItemFunc,
        onValidate,
        showErrors,
        isEditable,
        setIsEditing,
        onChangeScheduleItem
    } = props

    const {
        type,
        valueType,
        name: termName
    } = term

    const commonProps = {
        term,
        setScheduleItemFunc,
        onValidate,
        showErrors,
        isEditable,
        onChangeScheduleItem
    }
    if (_.isNil(valueType)) {
        return <TermFieldDropdown
            {...commonProps}
        />
    }
    if (valueType === 'Money') {
        return <TermFieldCurrency
            {...commonProps}
            setIsEditing={setIsEditing}
        />
    }
    // if (name === 'Year' && valueType === 'shorttext') {
    //     return <IMScheduleItemEditableCovTermCellInputYear
    //         termID={termID}
    //         term={term}
    //         setUpdatedTermToScheduleItem={setUpdatedTermToScheduleItem}
    //     />
    // }
    // if (valueType === 'shorttext') {
    //     return <IMScheduleItemEditableCovTermCellInputText
    //         termID={termID}
    //         term={term}
    //         setUpdatedTermToScheduleItem={setUpdatedTermToScheduleItem}
    //     />
    // }
    if (termName === 'Year' && valueType === 'shorttext') {
        return <TermFieldInputNumber
            {...commonProps}
            setIsEditing={setIsEditing}
        />
    }
    if (valueType === 'shorttext') {
        if (type === 'GenericIMSchCERateSeNum_ExtType') {
            return <TermFieldInputTextWithValidator
                {...commonProps}
                setIsEditing={setIsEditing} />
        }
        return <TermFieldInputText
            {...commonProps}
            setIsEditing={setIsEditing}
        />
    }
    if (valueType === 'longtext') {
        return <TermFieldTextArea
            {...commonProps}
            setIsEditing={setIsEditing}
        />
    }
    if (valueType === 'Other') {
        if (type.startsWith('Direct')) {
            return <TermFieldDirectInputNumber
                {...commonProps}
                setIsEditing={setIsEditing}
            />
        }
        return <TermFieldInputTextOther
            {...commonProps}
            setIsEditing={setIsEditing}
        />
    }
    return <div>Term field type not supported</div>
}

export default IMScheduleDetailsCovTermField