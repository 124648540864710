import { wizardProps } from '@xengage/gw-portals-wizard-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { PEFNOLPAOtherLossDetailPage } from 'wni-capability-fnol-pa-react';

function FNOLWTOtherLossDetailPage(props) {
    return <PEFNOLPAOtherLossDetailPage {...props}/>;
} 

FNOLWTOtherLossDetailPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
};

FNOLWTOtherLossDetailPage.propTypes = wizardProps;
export default withRouter(FNOLWTOtherLossDetailPage);