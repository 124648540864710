import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Vehicles',
        defaultMessage: 'Vehicles',
    },
    addVehicle: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Add Vehicle',
        defaultMessage: 'Add Vehicle',
    },
    deleteLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Delete Selected',
        defaultMessage: 'Delete Selected',
    },
    paVehicleType: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.allVehicleLiabilityExcluded',
        defaultMessage: 'All of the Vehicles on this policy exclude liability.',
    },
    validToolTip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.tooltip.valid',
        defaultMessage: 'All required fields have been completed for this Vehicle.',
    },
    inValidForInsuranceToolTip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.tooltip.invalid for insurance',
        defaultMessage: 'Not all required fields for issuance have been completed for this Vehicle.',
    },
    inValidToolTip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.tooltip.inValid',
        defaultMessage: 'Not all required fields for rating have been completed for this Vehicle.',
    },
    duplicatedVinToolTip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.tooltip.duplicatedVin',
        defaultMessage: 'Other vehicles have the same VIN.',
    },
    viewAndEditLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.View',
        defaultMessage: 'View',
    },
    removeVehicleTitle: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeVehicleDescription: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this vehicle record?',
    },
    cancelLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Cancel',
        defaultMessage: 'Cancel',
    },
    closeLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Save & Close',
        defaultMessage: 'Save & Close',
    },
    saveNextLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Save & Next Vehicle',
        defaultMessage: 'Save & Next Vehicle',
    },
    addAnotherVehicleLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Add Another Vehicle',
        defaultMessage: 'Add Another Vehicle',
    },
    continueToCoveragesLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Continue to Coverages',
        defaultMessage: 'Continue to Coverages',
    },
    addEditVehicleCoveragesLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Add/Edit Vehicle Coverages',
        defaultMessage: 'Add/Edit Vehicle Coverages',
    },
    continueToDriverAssignmentLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Continue to Driver Assignment',
        defaultMessage: 'Continue to Driver Assignment',
    },
    requiredForIssuanceSchema: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.warnings.required.issuance.vehicle',
        defaultMessage: 'Below required fields for issuance are missing: {displayName} - {fieldName}'
    },
    requiredForQuoteSchema: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.required.quote.vehicles',
        defaultMessage: 'Below required fields for quote are missing: {displayName} - {fieldName}'
    },
    otherVehicleTypeExistsForNNO: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.otherVehicleTypeExistsForNNO',
        defaultMessage: 'This policy has been changed to Named Non Owner, please remove all the existing vehicles and add new one.'
    },
    otherVehicleTypeExistsForSelect: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.otherVehicleTypeExistsForSelect',
        defaultMessage: 'This policy has been changed to No Named Non Owner, please remove/edit the existing vehicles.'
    },
    otherVehicleTypeExistsForNNOAndMoreThanOne: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.otherVehicleTypeExistsForNNOAndMoreThanOne',
        defaultMessage: 'This policy has been changed to Named Non Owner, please remove all the existing vehicles and add only one new Named Non Owner vehicle.'
    },
    nnoVehicleTypeExistsForSelect: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.nnoVehicleTypeExistsForSelect',
        defaultMessage: 'This policy has been changed to None Named Non Owner, please remove/edit the existing vehicles.'
    }
});
