import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import metadata from './CPPolicyInformationPage.metadata.json5';
import './CPPolicyInformationPage.messages';
import styles from './CPPolicyInformationPage.module.scss';

function removeInitialData(submissionVM) {
    // eslint-disable-next-line no-param-reassign
    submissionVM.value = MockUpUtil.cleanUpMockedProperties(
        _.get(submissionVM, 'value'),
        'bindData.contactPhone',
        'bindData.contactEmail'
    );
    return submissionVM;
}

function initialiseVM(submissionVM) {
    const vm = removeInitialData(submissionVM);

    vm.bindData.value = vm.bindData.value || {};
    vm.bindData.billingAddress.value = vm.bindData.billingAddress.value
    || vm.baseData.policyAddress.value;
    vm.bindData.contactEmail.value = vm.bindData.contactEmail.value
    || vm.baseData.accountHolder.emailAddress1.value;

    return vm;
}

function CPPolicyInformationPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, initialValidation, onValidate } = useValidation(
        'CPPolicyInformationPage'
    );
    const [isSameBillingAddress, updateIsSameBillingAddress] = useState(true);
    const { wizardData: submissionVM, updateWizardData } = props;

    const locationFullAddress = useMemo(() => {
        const displayName = _.get(submissionVM, 'baseData.policyAddress.displayName.value');

        return displayName.split(',').map((address) => {
            return <div>{address}</div>;
        });
    }, [submissionVM]);

    useEffect(
        () => {
            let nextSubmissionVM = viewModelService.clone(submissionVM);
            nextSubmissionVM = initialiseVM(nextSubmissionVM);

            if (!_.isEqual(submissionVM.value, nextSubmissionVM.value)) {
                updateWizardData(nextSubmissionVM);
            }
        },
        // It should call once, when page is rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleNext = useCallback(() => {
        if (isSameBillingAddress) {
            const policyAddress = _.get(submissionVM, 'baseData.policyAddress.value');
            _.set(submissionVM, 'bindData.billingAddress.value', policyAddress);
        }
        return submissionVM;
    }, [isSameBillingAddress, submissionVM]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        sameBillingAddress: {
            value: isSameBillingAddress,
            onValueChange: updateIsSameBillingAddress
        },
        billingAddressFields: {
            visible: !isSameBillingAddress
        },
        address: {
            value: locationFullAddress
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage
            onNext={handleNext}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                model={submissionVM}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

CPPolicyInformationPage.propTypes = wizardProps;
export default CPPolicyInformationPage;
