import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    RunReservation: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Review Matching Accounts',
        defaultMessage: 'Review Matching Accounts',
    },
    accountClearanceDialogTitle: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Risk Reservation',
        defaultMessage: 'Risk Reservation'
    },
    backToSideBySide: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Back to Side by Side',
        defaultMessage: 'Back to Side by Side'
    },
    continueReferUnderwriting: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Continue to Refer to Underwriting',
        defaultMessage: 'Continue to Refer to Underwriting'
    }
});
