import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { Claim } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { SelectAddressComponent, LossLocationComponent } from 'gwce-capability-fnol-ho-react-ext';
import metadata from './FNOLGLIncidentDetailsPage.metadata.json5';
import messages from './FNOLGLIncidentDetailsPage.messages';
import GLIncidentsUtil from "./utils/GLIncidentsUtil";
import GLPropertyIncidents from './PropertyIncidents/GLPropertyIncidents';
import GLInjuryIncidents from './InjuryIncidents/GLInjuryIncidents';
// import GLVehicleIncidents from './VehicleIncidents/GLVehicleIncidents';

const LOB_NAME = 'generalLiability';

function FNOLGLIncidentDetailsPage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        updateWizardSnapshot,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { FNOLService } = useDependencies('FNOLService');
    const viewModelService = useContext(ViewModelServiceContext);
    const [pageData] = useState(state);
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLGLIncidentDetailsPage');
    
    const relatedContactsPath = `lobs.${LOB_NAME}.relatedContacts_Ext`;
    const relatedContacts = GLIncidentsUtil.removeContactsPrimaryAddress(_.get(claimVM.value, relatedContactsPath));
    const lossSubCauseAvailableValues = _.get(claimVM, 'lossSubCause_Ext.aspects.availableValues', [])
    const [initWizardData, updateInitWizardData] = useState();

    const predefinedLossLocations = _.get(claimVM.value, 'predefinedLossLocations');
    
    const initiallossLocationIndex = predefinedLossLocations.findIndex((location) => {
        return location.publicID === _.get(claimVM, 'value.lossLocation.publicID')
    })
    const [lossLocationIndex, updateLossLocationIndex] = useState(initiallossLocationIndex);
    const [countNewLocation, updateCountNewLocation] = useState(0);
    const primaryLocationID = _.get(claimVM, 'value.primaryLocation.publicID');
    const currentLossLocation = predefinedLossLocations[lossLocationIndex];
    const isLocationReadOnly = primaryLocationID === _.get(currentLossLocation, 'publicID');

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        // keep initial wizard data
        updateInitWizardData(viewModelService.clone(claimVM));
    }, []);

    const resetWizardDataToSnapshot = () => {
        const newClaimVM = viewModelService.clone(initWizardData);
        updateWizardSnapshot(newClaimVM);
    };

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                return claimVM;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM]);

    const onNext = useCallback(async () => {
        setIsLoading(true);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            return handleSaveClaimData();
        }
        // eslint-disable-next-line no-return-await
        return await FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                const updatedClaimVM = _.clone(claimVM)
                updatedClaimVM.value = response
                updateWizardData(updatedClaimVM)
                return updatedClaimVM
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [claimVM]);

    const onLocationChange = useCallback((value, path) => {
        const newClaimVM = _.clone(claimVM);
        _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].${path}`, value);
        if (_.isNil(_.get(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`))) {
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`, `tempNew${countNewLocation}`)
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].country`, 'US')
        }
        _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[lossLocationIndex]);
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, lossLocationIndex, predefinedLossLocations, updateWizardData])
    
    const onSelectAddressChange = useCallback((value) => {
        const newClaimVM = _.clone(claimVM);
        if (value=== 'new') {
            updateLossLocationIndex(predefinedLossLocations.length);
            updateCountNewLocation(countNewLocation + 1);
        } else {
            const newLossLocationIndex = predefinedLossLocations.findIndex((location)=>location.publicID === value);
            updateLossLocationIndex(newLossLocationIndex);
            _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[newLossLocationIndex]);
        }
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, predefinedLossLocations, updateWizardData])


    const commonIncidentsComponentProps = {
        claimVM,
        predefinedLossLocations,
        relatedContactsPath,
        relatedContacts,
        lobName: LOB_NAME,
        updateWizardData,
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        onValidate,
        showErrors,
    }

    const overrides = useMemo(() => ({
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        propertyIncidents: {
            ...commonIncidentsComponentProps
        },
        injuryIncidents: {
            ...commonIncidentsComponentProps
        },
        // vehicleIncidents: {
        //     ...commonIncidentsComponentProps
        // },
        selectAddress: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onSelectAddressChange: onSelectAddressChange,
            countNewLocation: countNewLocation
        },
        lossLocation: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onLocationChange: onLocationChange,
            isReadOnly: isLocationReadOnly,
            countNewLocation: countNewLocation,
            setComponentValidation: onValidate,
            showErrors: showErrors,
        },
        lossDetail: {
            visible: lossSubCauseAvailableValues.length > 0
        }
    }), [claimVM.value,lossLocationIndex, predefinedLossLocations,lossSubCauseAvailableValues, commonIncidentsComponentProps, showErrors]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM]
    );

    const resolvers = {
        resolveComponentMap: {
            SelectAddressComponent: SelectAddressComponent,
            losslocationcomponent: LossLocationComponent,
            PropertyIncidents: GLPropertyIncidents,
            injuryIncidents: GLInjuryIncidents,
            // vehicleIncidents: GLVehicleIncidents
        }
    };

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={isComponentValid ? onNext : setValidation }
            template={WizardPageTemplate} 
            alwaysCallOnNext>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLGLIncidentDetailsPage.propTypes = wizardProps;
FNOLGLIncidentDetailsPage.defaultProps = wizardProps.defaultProps;

FNOLGLIncidentDetailsPage.propTypes = {
    lobName: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
FNOLGLIncidentDetailsPage.defaultProps = {
}
export default withRouter(withAuthenticationContext(FNOLGLIncidentDetailsPage));
