// only apply to PE, differnent state have differnt total range for agent
const agentStateTotalRangeMap = {
    'Alaska': {
        minimum: -0.15,
        maximum: 0.15
    }
}

export default {
    agentStateTotalRangeMap
}