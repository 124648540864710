
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALCoveragesReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function WALCoveragesChangeReadOnlyPage(props) {

    return (
        <WALCoveragesReadOnlyPage {...props} />
    );

}

WALCoveragesChangeReadOnlyPage.propTypes = WALCoveragesReadOnlyPage.propTypes;
WALCoveragesChangeReadOnlyPage.defaultProps = WALCoveragesReadOnlyPage.defaultProps;

export default WALCoveragesChangeReadOnlyPage;