import { defineMessages } from 'react-intl';

export default defineMessages({
    ListOfScheduleItems: {
        id: 'wni.quoteandbind.im-wizard.im-WarehouseLegalLiability.LiatOfScheduleItems',
        defaultMessage: 'List Of Scheduled Items',
    },
    ScheduleDetails: {
        id: 'wni.quoteandbind.im-wizard.im-WarehouseLegalLiability.ScheduleDetails',
        defaultMessage: 'Schedule Details',
    },
    AdditionalInsuredDetail: {
        id: 'wni.quoteandbind.im-wizard.im-WarehouseLegalLiability.AdditionalInsuredDetail',
        defaultMessage: 'Additional Interest/ Additional Insured Detail',
    },
});