import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalInsuredTitle: {
        "id": "quoteandbind.gl.wizard.step.location.AdditionalInsured",
        "defaultMessage": "Additional Insured"
    },
    AdditionalInsuredActionText: {
        "id": "quoteandbind.gl.wizard.step.location.AdditionalInsured.ActionText",
        "defaultMessage": "Add Additional Insureds"
    },
});
