import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { usePromise, PROMISE_STATES } from '@xengage/gw-portals-promise-react';

function defaultRegisterInvalidFieldFn(prevFields, currentFields) {
    return _.uniq([...prevFields, ...currentFields]);
}

function defaultUnregisterInvalidFieldFn(prevFields, currentFields) {
    return prevFields.filter((id) => !currentFields.includes(id));
}

// Same with _.identity
function defaultValidationNameGetter(fieldID) {
    return fieldID;
}

/**
 * New Data Structure for InvalidFields
 * [
 *  name: viewModelFormName, pageName, or componentName,
 *  invalidFields: []
 * ]
 */

// ============================================

function useValidation(componentName = 'Component') {
    const validationPromise = usePromise();
    const [invalidFieldsInternal, updateInvalidFieldsInternal] = useState({});
    // const [invalidFields, updateInvalidFields] = useState([]);
    const [itemsPendingValidation, setItemsPendingValidation] = useState([]);
    const [validationInitialized, updateValidationInitialized] = useState(false);
    const [validationCallback, setValidationCallback] = useState(() => _.stubTrue);
    const [initialValidationCallback, setInitialValidationCallback] = useState(() => _.stubTrue);
    const [isDirty, setDirty] = useState(false);

    const invalidFields = _.flatten(_.values(invalidFieldsInternal));
    // console.log(`${componentName} - ${JSON.stringify(invalidFields)}`);
    const updateInvalidFields = (validationName, currentFields, newInvalidFieldsGetter) => {
        updateInvalidFieldsInternal((prevInvalidFieldsMap) => {
            const prevInvalidFields = _.get(prevInvalidFieldsMap, validationName, []);
            const newInvalidFields = _.isFunction(newInvalidFieldsGetter) ? newInvalidFieldsGetter(prevInvalidFields, currentFields) : newInvalidFieldsGetter;
            return {
                ...prevInvalidFieldsMap,
                [validationName]: newInvalidFields,
            };
        });
    };

    const isInvalidFieldsEmpty = _.isEmpty(invalidFields);
    const areItemsPendingValidation = !_.isEmpty(itemsPendingValidation);
    const isSpecificValidationValid = validationCallback();

    const isValidationInProgress = !validationInitialized
        || areItemsPendingValidation || isSpecificValidationValid === null;

    const isComponentValid = isValidationInProgress
        ? null
        : isInvalidFieldsEmpty && !areItemsPendingValidation && isSpecificValidationValid;

    const registerComponentForValidation = useCallback((fieldID) => {
        setItemsPendingValidation((previousItems) => _.uniq([...previousItems, fieldID]));
    }, []);

    const unregisterItemToBeValidated = useCallback((fieldID) => {
        setItemsPendingValidation((previousItems) => previousItems.filter((id) => id !== fieldID));
    }, []);

    const registerInvalidField = useCallback((fieldIDs, {
        validationName,
        newInvalidFields = defaultRegisterInvalidFieldFn,
    } = {}) => {
        const fieldIDArray = !_.isArray(fieldIDs) ? [fieldIDs] : fieldIDs;

        updateInvalidFields(validationName, fieldIDArray, newInvalidFields);
    }, []);

    const unregisterInvalidField = useCallback((fieldIDs, {
        validationName,
        newInvalidFields = defaultUnregisterInvalidFieldFn,
    } = {}) => {
        const fieldIDArray = !_.isArray(fieldIDs) ? [fieldIDs] : fieldIDs;

        updateInvalidFields(validationName, fieldIDArray, newInvalidFields);
    }, []);

    const disregardFieldValidation = useCallback((validationNames) => {
        const validationNamesArray = !_.isArray(validationNames) ? [validationNames] : validationNames;

        updateInvalidFieldsInternal((prevInvalidFieldsMap) => {
            if (_.isEmpty(validationNames)) {
                return {};
            }
            validationNamesArray.forEach((validationName) => _.unset(prevInvalidFieldsMap, validationName));
            return { ...prevInvalidFieldsMap };
        });
    }, []);

    const registerComponentValidationCallback = useCallback((callback) => {
        setValidationCallback(() => callback);
    }, []);

    const registerInitialComponentValidationCallback = useCallback((callback) => {
        setInitialValidationCallback(() => callback);
    }, []);

    /**
     * Check to see if the current field should be added or removed from the invalid array
     * @param {boolean} isValid - is the current clause valid
     * @param {string} fieldID - the field ID of the current field
     * @param {object} validationName: string or function, newInvalidFields: string, array or function
     */
    const onValidate = useCallback(
        (isValid, fieldID = componentName, {
            validationName: validationNameGetter = _.identity,
            newInvalidFields,
        } = {}) => {
            setDirty(true);

            const validationName = _.isFunction(validationNameGetter) ? validationNameGetter(fieldID) : validationNameGetter;
            if (_.isNil(isValid)) {
                registerComponentForValidation(validationName);
            } else {
                if (isValid) {
                    unregisterInvalidField(fieldID, { validationName, newInvalidFields });
                } else {
                    registerInvalidField(fieldID, { validationName, newInvalidFields });
                }
                unregisterItemToBeValidated(validationName);
            }
        },
        [
            componentName,
            registerComponentForValidation,
            registerInvalidField,
            unregisterInvalidField,
            unregisterItemToBeValidated
        ]
    );

    const initialValidation = useCallback(() => {
        return validationPromise.promise;
    }, [validationPromise]);

    useEffect(() => {
        updateValidationInitialized(true);
    }, []);

    const resolvePromise = useCallback(() => {
        if (validationInitialized) {
            const isValidPromise = Promise.resolve(isComponentValid).then((isValid) => {
                return isValid ? initialValidationCallback() : false;
            });
            validationPromise.resolve(isValidPromise);
        }
    }, [initialValidationCallback, isComponentValid, validationInitialized, validationPromise]);

    useEffect(() => {
        if (
            isDirty
            && validationPromise.state === PROMISE_STATES.pending
            && !isValidationInProgress
        ) {
            resolvePromise();
        }
    }, [
        isDirty,
        isValidationInProgress,
        itemsPendingValidation,
        resolvePromise,
        validationPromise
    ]);

    return {
        // disregardFieldValidation: unregisterInvalidField,
        disregardFieldValidation,
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation: registerComponentValidationCallback,
        registerInitialComponentValidation: registerInitialComponentValidationCallback,
        isValidationInProgress,
        invalidFields
    };
}

export default useValidation;
