import _ from 'lodash';
import { ConfigUtil, WniDateUtil, WniAccordionValidationUtil } from 'wni-portals-util-js';
import CPPLocationConstants from '../Config/CPPLocationConstants'


function hasIssuanceInvalidFields(dataVM) {
    if(!dataVM)  {
        return false;
    }
    const data = dataVM.value || {};
    const numberOfEmployee = _.get(data, 'numberOfEmployee');
    if(numberOfEmployee && Number(numberOfEmployee) < 0) {
        return true;
    }
    return false;
}

function setDefaultLocationObj(item) {
    const defaultDisplayables =[
        {
            "label": "Number Of Employees",
            "propertyName": "NumberOfEmployees",
            "available": true,
            "required": true,
            "editable": true,
            "updated": true,
            "rangeValueOptions": [],
            "inputSetMode": "integer",
            "isValid": false,
            "entityPublicId": "pc:501",
            "integerValue": null
        }
    ]

    if(item) {
        return {
            ...item,
            isUpdate: true,
            displayableDTO: defaultDisplayables
        };
    };

    return {
        rowIdPath: ConfigUtil.getUuid(),
        isNonSpecific: false,
        address_Ext: {
            country: "US"
        },
        isUpdate: true,
        displayableDTO: defaultDisplayables
    };
}

function getPrimaryAddressDisplayName(primaryAddress) {
    const addressLine = _.get(primaryAddress, 'pobox_Ext.value', null) || _.get(primaryAddress, 'addressLine1.value', null) || '-';
    const addressLine2 = _.get(primaryAddress, 'addressLine2.value');
    const city = _.get(primaryAddress, 'city.value') || '-';
    const stateCode = _.get(primaryAddress, 'state.value.code') || '-';
    const zipCode = _.get(primaryAddress, 'postalCode.value') || '-';
    return `${addressLine}, ${addressLine2 ? `${addressLine2},` : ''} ${city}, \n ${stateCode}, ${zipCode}`;
}

function hasInvalidLocation(driversVM) {
    const retval = driversVM.children.some((item) => {
        return !item.aspects.valid || !item.aspects.subtreeValid;
    });
    return retval;
}

function removeEmpty(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        if (val === '' || val.length === 0 || val === null) {
            delete res[key];
        }
    });
    return res;
}

function transferAnswerstoNull(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        if (val === '') {
            res[key] = null
        }
    });
    return res;
}

function getAccordionOverridesForLocation(locationVM, validationIssues = []) {
    const cardFieldsMap = CPPLocationConstants.sectionFieldsMap;
    const retval = WniAccordionValidationUtil.getInvalidAccordionCardsOverrides(cardFieldsMap, locationVM);
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(locationVM, 'value.fixedId');
    });
    const errorFields = _.map(errorIssues, (error) => {
        return _.upperFirst(_.get(error, 'relatedEntity.fieldName'));
    });
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        const invalidFields = _.filter(fields, (field) => {
            const idx = errorFields.indexOf(_.upperFirst(field));
            return idx >= 0;
        });
        return invalidFields.length > 0;
    });
    ids.forEach((id) => {
        if (invalidAccordions.indexOf(id) >= 0) {
            _.set(retval, `${id}.errorState`, true);
        }
    });
    return retval;
}

function getQSMapperForWCLocations() {
    return (metaDataContent) => {

        return metaDataContent;
    };
}

export default {
    hasIssuanceInvalidFields,
    setDefaultLocationObj,
    transferAnswerstoNull,
    hasInvalidLocation,
    removeEmpty,
    getAccordionOverridesForLocation,
    getPrimaryAddressDisplayName,
    getQSMapperForWCLocations
}