import { defineMessages } from 'react-intl';

export default defineMessages({
  documents: {
    id: "gateway.directives.gateway-documents.quote-details.Documents",
    defaultMessage: "Documents"
  },
  attachments: {
    id: "gateway.directives.gateway-documents.quote-details.Attachments",
    defaultMessage: "Attachments"
  }
});
