
/**
 * @constant {{
*  [covCode: String]: String
* }}
*/
const OperatorCoverageCode = {
    AutoDeathBenefits: 'RTAutoDeathBenefits_Ext',
    TotalDisabilityBenefits: 'RTTotalDisabilityBenefits_Ext',
}

/**
 * @constant {{
*  [covCode: String]: String
* }}
*/
const OperatorCoverageLimitScheduleItemTermCode = {
    AutoDeathBenefitsScheduleItemSum: 'RTAutoDeathBenefitsScheduleItemSum_Ext',
    RTTotalDisabilityBenefitsSchItemGE: 'RTTotalDisabilityBenefitsSchItemGE_Ext',
    RTTotalDisabilityBenefitsSchItemNotGE: 'RTTotalDisabilityBenefitsSchItemNotGE_Ext',
}

export default {
    OperatorCoverageCode,
    OperatorCoverageLimitScheduleItemTermCode,
}