import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniCancellation'), method, data, additionalHeaders);
}

export default class WniCustomCancellationService {
    static getDocumentsForCancellation(params, additionalHeaders = {}) {
        return processSubmission('getDocumentsForCancellation', [params], additionalHeaders);
    }

    static getDocumentsForCancellationGroupByType(params, additionalHeaders = {}) {
        return processSubmission('getDocumentsForCancellationGroupByType', [params], additionalHeaders);
    }

    static getPortalAttachmentsForCancellation(params, additionalHeaders) {
        return processSubmission('getPortalAttachmentsForCancellation', params, additionalHeaders);
    }
}
