

const WorkflowTypes = [
    {
        name: 'Classical',
        code: 'Classical',
        get ClassicalWorkflow() { return true; },
    },
    {
        name: 'R2',
        code: 'R2',
        get ClassicalWorkflow() { return false; },
    }
];

// For R1, it's 'Classical'
// For R2, it is, well, 'R2'
export const DEFAULT_WORKFLOW_TYPE = WorkflowTypes[1];

export default WorkflowTypes;
