
import React, { useContext } from 'react';
import _ from 'lodash';
import WCTermComponent from './WCTermComponent';
import SingleClauseContext from '../../context/WCSingleClauseContext';
import CoveragesConfigContext from '../../context/WCCoveragesConfigContext';
import WCScheduleTable from '../WCScheduleTable/WCScheduleTable';

function WCClauseElementComponent(props) {

    const {
        idPrefix,
        clauseCode: code,
        clausePublicID: publicID,
        isEditable,
    } = useContext(SingleClauseContext)

    const coveragesConfig = useContext(CoveragesConfigContext)

    const {
        terms,
        schedule
    } = props

    const {
        limitCoverageConfig,
        covTermAvailable,
    } = coveragesConfig;

    const curCovTermAvailableConfig = _.get(covTermAvailable, code, {})

    return (
        <div
            id= {`${idPrefix}ClausetElementContainer_[${publicID}]`}
            className= 'clause_element_container'
        >
            {terms
                .filter((term) => {
                    const termCode = _.get(term, 'code_Ext')
                    const curTermAvailableFunc = _.get(curCovTermAvailableConfig, termCode)
                    if (_.isFunction(curTermAvailableFunc)) {
                        const isCurTermAvailable = curTermAvailableFunc()
                        if (!isCurTermAvailable) {
                            return false
                        }
                    }
                    // filter all limit terms in edit mode
                    if (!isEditable) {
                        return true
                    }
                    return _.get(term, 'code_Ext') !== _.get(limitCoverageConfig, code)
                })
                .map((term) => {
                    const termIndex = terms
                        .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                    return <WCTermComponent
                        key={_.get(term, 'code_Ext')}
                        term={term}
                        termIndex = {termIndex}
                    />
                })}
            {schedule ? <WCScheduleTable schedule={schedule} /> : null}
        </div>
        
    )
}

export default WCClauseElementComponent;