import React, {
    useContext,
} from 'react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil, ClauseMetadataUtil } from 'wni-portals-util-js';
import messages from 'gw-components-platform-react/Clauses/ClauseComponentVM.messages';

import { SingleClauseComponentVM } from 'gw-components-platform-react';

import SideBySideClauseComponent from './CASideBySideClauseComponent';

// =========================
// import CASingleClauseComponentVM from '../../../Coverages/components/CASingleClauseComponentVM/CASingleClauseComponentVM';


/**
 * Clause component hierarchy diagram:
 * SideBySideSingleClauseComponentVM -> SideBySideClauseComponent
 * @param {object} props
 * @returns {object}
 */
function CASideBySideSingleClauseComponentVM(props) {
    const {
        value: clause = {},
        isEditable,
        path,
        idPrefix,
        isDisabled,
        labelPosition,
        labelTop,
        showAmount,
    } = props;

    const translator = useTranslator();
    const intl = useContext(IntlContext);

    /**
     * Generate clauses term metadata from clause
     * @param {object} clauseTerms the clause terms
     * @param {string} clausePublicID the clause publicID
     * @returns {object | null} the metadata to render
     */
    const getTermMetaData = (clauseTerms, clausePublicID) => {
        // const coverageAmount = clause.amount;
        const { amount: coverageAmount } = clause;
        const normalizedClauseTerms = clauseTerms.map((term) => {
            const { name } = term;
            // let nameWithAmount = name;
            // if (displayAmountTermPatterns.includes(term.patternCode)) {
            const nameWithAmount = WniClausesUtil.getLabelNameWithAmount(name, coverageAmount, intl, showAmount);
            // }
            return {
                ...term,
                name: nameWithAmount
            };
        });

        // Only provide the first term on HOQuotePage for now
        const [ firstClauseTerm ] = normalizedClauseTerms;

        const retval =  ClauseMetadataUtil.getTermMetadata(
            {
                clauseTerms: [firstClauseTerm], // updatedClauseTerms,
                clausePublicID,
                isEditable,
                path,
                idPrefix,
                // isDisabled: true, // Disable Clause Term edit on HOQuotePage, since there's no point to do so
                labelPosition,
                labelTop,
                translator,
                messages
            }
        );
        return retval;
    };

    const componentMapOverrides = {
        ClauseComponent: SideBySideClauseComponent
    };

    // const overrideFunctionMap = {
    //     getTermMetaData
    // };

    return (
        <SingleClauseComponentVM
            {...props}
            getTermMetaData={getTermMetaData}
            componentMapOverrides={componentMapOverrides}
            // overrideFunctionMap={overrideFunctionMap}
        />
    );

    // return (
    //     <CASingleClauseComponentVM {...props} />
    // );
}

CASideBySideSingleClauseComponentVM.propTypes = SingleClauseComponentVM.propTypes;

CASideBySideSingleClauseComponentVM.defaultProps = SingleClauseComponentVM.defaultProps;

// export default withValidation(SXSSingleClauseComponentVM);
export default CASideBySideSingleClauseComponentVM;