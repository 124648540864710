
// Defined in QuoteUtil
// const PAYMENT_PLAN_TYPES = {
//     annually: 'annually',
//     monthly: 'monthly',
// };

// See PALineCoveragesValidator
// See PaCoverageValidationIssuePlugin
const recommendCovLinkTexts = [
    'Roadside Assistance Plus',
    'New Vehicle Replacement',
    'Loan/Lease',
    'Extended Non-Owned',
];


// const messages = {
//     BR_PL_0211_MSG: 'Add Roadside Assistance Plus protection, with an average cost of just $5 a month per vehicle.',
//     BR_PL_0212_MSG: 'Customers with newer vehicles commonly add New Vehicle Replacement Coverage to protect against depreciated value',
//     BR_PL_0213_MSG: 'Add Loan/Lease Coverage to help cover a gap if the balance of the loan or lease is greater than the vehicle’s value',
//     BR_PL_0214_MSG: 'Add Extended Non-Owned Coverage to provide liability coverage for the customer and their family if they drive their corporate car for personal use',
// };

const recommendCoverages = {
    PARdsidAsstPlusAKCov_EXT: 'Roadside Assistance Plus - Alaska',
    PARdsidAsstPlusCov_Ext: 'Roadside Assistance Plus',

    PANewVehRCAKOTCCov_EXT: 'New Vehicle Replacement Cost Other than Collision Coverage',
    PANewVehRCAKCollCov_Ext: 'New Vehicle Replacement Cost Collision Coverage',
    PANewVehRCOTCCov_EXT: 'New Vehicle Replacement Cost Other than Collision Coverage',
    PANewVehRCCollCov_EXT: 'New Vehicle Replacement Cost Collision Coverage',

    PALoanLeaseAKOTCCov_EXT: 'Auto Loan/Lease Other Than Collision',
    PALoanLeaseCollAKCov_EXT: 'Auto Loan/Lease Collision',
    PALoanLeaseOTCCOV_EXT: 'Auto Loan/Lease Other Than Collision',
    PALoanLeaseCollCov_EXT: 'Auto Loan/Lease Collision',

    PAENORegVehUseIndCov: 'Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual',
    PAENORegVehUseResCov: 'Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual&Residents',
    PAENOPubVehUseCovInd_EXT: 'Extended Non-Owned Coverage-Vehicles Furnished or Available For Use As Public or Livery Conveyances Individual',
    PAENOPubVehUseCovRes_EXT: 'Extended Non-Owned Coverage-Vehicles Furnished or Available For Use As Public or Livery Conveyances Individual&Residents',

};

const hiddenAmountCoveragePatterns = [
    'PAPIPMNME_Ext'
];

const displayAmountTermPatterns = [
    'PIPMNStackedNotStacked_Ext'
];


export default {
    recommendCovLinkTexts,
    recommendCoverages,
    hiddenAmountCoveragePatterns,
    displayAmountTermPatterns,
};
