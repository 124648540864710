
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WniAccountService } from 'wni-capability-gateway';
import { PortalConstants } from 'wni-portals-config-js';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
// import { BRPLMessages } from 'wni-platform-translations';
import { useProductsData } from 'wni-portals-util-react';
import AccountClearanceComponent from '../../../components/AccountClearanceComponent/AccountClearanceComponent';
import ExistingAccountMatchPage from '../../../components/ExistingAccountMatch/ExistingAccountMatchPage';
import message from './AccountClearanceDetailsPopup.messages';
import metadata from './AccountClearanceDetailsPopup.metadata.json5';
import styles from './AccountClearanceDetailsPopup.module.scss';

import { Button } from '@jutro/legacy/components';

const AccountClearanceDetailsPopup = (props) => {
    const modalApi = useWniModal();
    const {
        title,
        size,
        okBtnLabel,
        isOpen,
        onResolve,
        getAccountClearanceData,
        postAccountMerge,
        currentAccountNumber,
        //
        useAuthenticationData,
        useDependenciesData,
        viewModelService,
        productsData: propProductsData,
    } = props;

    const translator = useTranslator();
    const productsData = propProductsData|| useProductsData();
    const { authHeader } = useAuthenticationData || useAuthentication();
    const [accountClearanceData, updateAccountClearanceData] = useState(undefined);
    const [showWarning, updateShowWarning] = useState(false);
    const [warningMessage, updateWarningMessage] = useState('');

    const retrieveAcountClearanceData = async () => {
        const newData = await getAccountClearanceData();
        updateAccountClearanceData(newData);
    };

    useEffect(() => {
        retrieveAcountClearanceData();
    }, []);

    const onConfirmMerge = (accountNumber) => {
        updateShowWarning(false);
        //
        const modalMessage = translator(message.warningMsg, { sourceAccount: `${currentAccountNumber}`, targetAccount: `${accountNumber}` });
        modalApi.showConfirm({
            title: message.mergeAccount,
            message: modalMessage,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(async(results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            const res = await WniAccountService.mergeAccount(currentAccountNumber,
                accountNumber, authHeader);
            if (res === PortalConstants.OPERATION_SUCCESS) {
                await postAccountMerge();
                onResolve();
            }
            updateWarningMessage(res);
            updateShowWarning(true);
        }, _.noop);
    };


    // ======================================
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        matchedExstingPage: {
            matchData: accountClearanceData,
            onConfirmMerge: onConfirmMerge,
            isSXS: true,
            //
            useDependenciesData,
            productsData,
        },
        warningMessagesContainer: {
            visible: showWarning,
            validationIssues: [{ type: 'warning', reason: warningMessage }],
            scrollToIssues: true,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            accoungclearance: AccountClearanceComponent,
            existingaccountmatchpage: ExistingAccountMatchPage,
            validationissuescomponent: ValidationIssuesComponent
        },
    };


    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} onClose={onResolve} />
            <ModalBody id="accountClearanceContainer">
                <MetadataContent
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button onClick={onResolve} type="filled">{okBtnLabel}</Button>
                </div>
            </ModalFooter>
        </ModalNext>
    );
};

AccountClearanceDetailsPopup.propTypes = {
    title: PropTypes.string.isRequired,
    okBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    getAccountClearanceData: PropTypes.func.isRequired,
    postAccountMerge: PropTypes.func.isRequired,
    currentAccountNumber: PropTypes.string.isRequired
};

AccountClearanceDetailsPopup.defaultProps = {
    // xs, md, lg, xl
    size: 'lg',
};

export default AccountClearanceDetailsPopup;
