import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsTitle: {
        id: 'wni.quoteandbind.ca.policydetails.Policy Details Title',
        defaultMessage: 'Policy Details'
    },
    termType: {
        id: 'wni.quoteandbind.ca.policydetails.Term Type',
        defaultMessage: 'Term Type'
    },
    policyState: {
        id: 'wni.quoteandbind.ca.policydetails.Policy State',
        defaultMessage: 'Policy State'
    },
    policyType: {
        id: "wni.quoteandbind.ca.policydetails.Policy Type",
        defaultMessage: "Policy Type"
    },
    individualNamedInsured: {
        id: "wni.quoteandbind.ca.policydetails.Individual Named Insured",
        defaultMessage: "Individual Named Insured"
    },
    legalEntity: {
        id: "wni.quoteandbind.ca.policydetails.Legal Entity",
        defaultMessage: "Legal Entity"
    },
    grossReceiptsBasisOrMileageBasis: {
        id: "wni.quoteandbind.ca.policydetails.Gross Receipts Basis or Mileage Basis",
        defaultMessage: "Gross Receipts Basis or Mileage Basis"
    },
    classCode: {
        id: "wni.quoteandbind.ca.policydetails.Class Code",
        defaultMessage: "Class Code"
    },
    vehicleType: {
        id: "wni.quoteandbind.ca.policydetails.Vehicle Type",
        defaultMessage: "Vehicle Type"
    },
    agencyOfRecordTitle: {
        id: "wni.quoteandbind.ca.policydetails.Agency of Record Title",
        defaultMessage: "Agency of Record"
    },
    policyUnderwritingQuestionsTitle: {
        id: "wni.quoteandbind.ca.policydetails.Policy Underwriting Questions Title",
        defaultMessage: "Policy Underwriting Questions"
    },
    addExistingContactWarning: {
        id: 'wni.quoteandbind.ca.policydetails.addExistingContactWarning',
        defaultMessage: '{name} is already a Additional Named Insured.'
    },
    pniMessage: {
        id: "wni.quoteandbind.ca.policyDetails.pni notification",
        defaultMessage: "Updating primary insured information will sync to the account and other quotes"
    },
    acceptCertifiedActs: {
        id: "wni.quoteandbind.ca.policydetails.Accept Certified Acts Of Terrorism Coverage",
        defaultMessage: "Accept Certified Acts Of Terrorism Coverage"
    },
    rideSharing: {
        id: "wni.quoteandbind.ca.policydetails.Ride Sharing Arrangements Endorsement Indicator",
        defaultMessage: "Ride Sharing Arrangements Endorsement Indicator"
    }
});