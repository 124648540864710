import { defineMessages } from 'react-intl';

export default defineMessages({
    whatHappened: {
        id: 'pe.fnol.cp.views.what.Describe what happened',
        defaultMessage: 'Describe what happened'
    },
    cpLossCause: {
        id: 'pe.fnol.cp.views.what.Loss Cause',
        defaultMessage: 'Loss Cause'
    },
    selectLossCause: {
        id: 'pe.fnol.cp.views.what.Select Loss Cause',
        defaultMessage: '--Select Loss Cause--'
    },
    incidentIndication: {
        id: 'pe.fnol.cp.views.what.Indicate who or what caused the incident, and provide other details that would be helpful.',
        defaultMessage: 'Indicate who or what caused the incident, and provide other details that would be helpful.'
    },
    dateOfNotice: {
        id: 'pe.fnol.cp.views.what.Date of Notice',
        defaultMessage: 'Date of Notice'
    },
    submitReport: {
        id: 'pe.fnol.cp.views.what.Submit as report only?',
        defaultMessage: 'Submit as report only?'
    },
    platformYes: {
        id: 'pe.platform.inputs.Yes',
        defaultMessage: 'Yes'
    },
    platformNo: {
        id: 'pe.platform.inputs.No',
        defaultMessage: 'No'
    },
    detailsTitle: {
        id: 'pe.fnol.wizard.step.cp.Details',
        defaultMessage: 'Details'
    },
    buildingTitle: {
        id: 'pe.fnol.wizard.step.cp.Buildings',
        defaultMessage: 'Buildings'
    },
    cpWhereTitle: {
        id: 'pe.fnol.wizard.step.cp.Where',
        defaultMessage: 'Where'
    },
    cpClaimSpecifyDetailsTitle: {
        id: 'pe.fnol.cp.views.details.Specify the details of the selected buildings',
        defaultMessage: 'Specify the details of the selected buildings'
    },
    cpClaimDescriptionTitle: {
        id: 'pe.fnol.cp.views.details.Description',
        defaultMessage: 'Description'
    },
    cpClaimDamageDescriptionTitle: {
        id: 'pe.fnol.cp.views.details.Damage Description',
        defaultMessage: 'Damage Description'
    },
    cpClaimRepairEstimateTitle: {
        id: 'pe.fnol.cp.views.details.Do you have a repair estimate?',
        defaultMessage: 'Do you have a repair estimate?'
    },
    cpClaimRepairCostTitle: {
        id: 'pe.fnol.cp.views.details.Estimated Repair Cost',
        defaultMessage: 'Estimated Repair Cost'
    },
    cpClaimRepairTimeTitle: {
        id: 'pe.fnol.cp.views.details.Estimated Repair Time',
        defaultMessage: 'Estimated Repair Time'
    },
    cpClaimEstimateDamageTitle: {
        id: 'pe.fnol.cp.views.details.Estimate of Damage',
        defaultMessage: 'Estimate of Damage'
    },
    cpClaimEstimateLossTitle: {
        id: 'pe.fnol.cp.views.details.Estimate of Loss',
        defaultMessage: 'Estimate of Loss'
    },
    cpClaimAlreadyRepairedTitle: {
        id: 'pe.fnol.cp.views.details.Has the building already been repaired?',
        defaultMessage: 'Has the building already been repaired?'
    },
    cpClaimClassCodeTitle: {
        id: 'pe.fnol.cp.views.details.Class Code',
        defaultMessage: 'Class Code'
    },
    cpClaimNumberofStoriesTitle: {
        id: 'pe.fnol.cp.views.details.Number of Stories',
        defaultMessage: 'Number of Stories'
    },
    cpClaimRoofMaterialsTitle: {
        id: 'pe.fnol.cp.views.details.Roof Covering Materials',
        defaultMessage: 'Roof Covering Materials'
    },
    cpClaimExteriorMaterialTitle: {
        id: 'pe.fnol.cp.views.details.Exterior Wall Material',
        defaultMessage: 'Exterior Wall Material'
    },
    cpClaimOccupancyTitle: {
        id: 'pe.fnol.cp.views.details.Type of Occupancy',
        defaultMessage: 'Type of Occupancy'
    },
    cpClaimLossAreaTitle: {
        id: 'pe.fnol.cp.views.details.Loss Area',
        defaultMessage: 'Loss Area'
    },
    cpWizardWhat: {
        id: 'pe.fnol.wizard.step.cp.What',
        defaultMessage: 'What'
    },
    cpBuildingAffeected: {
        id: 'pe.fnol.cp.views.properties.Select affected buildings',
        defaultMessage: 'Select Affected Buildings'
    },
    cpBuildingSearch: {
        id: 'pe.fnol.cp.views.properties.Search for buildings or locations',
        defaultMessage: 'Search for buildings or locations'
    },
    cpBuildingDetails: {
        id: 'pe.fnol.cp.views.properties.Building Details',
        defaultMessage: 'Building Details'
    },
    cpBuildingNo: {
        id: 'pe.fnol.cp.views.properties.BUILDING No.',
        defaultMessage: 'Building No.'
    },
    cpBuildingLocation: {
        id: 'pe.fnol.cp.views.properties.Location',
        defaultMessage: 'Location'
    },
    cpBuildingLocationNo: {
        id: 'pe.fnol.cp.views.properties.LOCATION No.',
        defaultMessage: 'Location No.'
    },
    cpSelectedBuildings: {
        id: 'pe.fnol.cp.views.properties.Building(s) Selected',
        defaultMessage: 'Building(s) Selected'
    },
    cpCreateDraftClaim: {
        id: 'pe.fnol.cp.config.FlowConfig.Creating draft claim...',
        defaultMessage: 'Creating draft claim...'
    },
    cpCreateDraftErrorTitle: {
        id: 'pe.fnol.cp.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    cpCreateDraftErrorMessage: {
        id: 'pe.fnol.cp.config.FlowConfig.An error occurred attempting to create a claim.',
        defaultMessage: 'An error occurred attempting to create a claim.'
    }
});
