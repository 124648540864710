
import React from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import  IMPolicyDetailsPage from './IMPolicyDetailsPage';

function IMPolicyDetailsReadOnlyPage(props) {

    // const {
    //     wizardData: submissionVM,
    // } = props;

    const IMOverrideProps = {
    }

    return (
        <IMPolicyDetailsPage
            {...props}
            IMOverrideProps = {IMOverrideProps}
            isReadOnly
        />
    );

}

IMPolicyDetailsReadOnlyPage.propTypes = {
    ...wizardProps,
};

IMPolicyDetailsReadOnlyPage.defaultProps = {
};

export default IMPolicyDetailsReadOnlyPage;