const dtoName = "wni.edge.capabilities.claim.lob.impl.personalauto.dto.PAVehicleIncidentDTO";
const wtDtoName = "wni.edge.capabilities.claim.lob.impl.watercraft.dto.WatercraftIncidentDTO";
const xCenter = "cc";

const DRIVER_PATH = 'driver';
const DRIVER_MAP_PATH = 'driverMap';
const PASSENGER_PATH = 'passengers';
const PASSENGER_MAP_PATH = 'passengerMap';
const newLocation = {
    code: 'new',
    name: 'New',
    country: 'US',
};

const generateContactOptions = (data = []) => {
    return data.map((item) => {
        return {
            ...item,
            rowIdPath: item.publicID || item.rowIdPath
        }
    })
};


export {
    dtoName,
    xCenter,
    wtDtoName,
    DRIVER_PATH,
    DRIVER_MAP_PATH,
    PASSENGER_PATH,
    PASSENGER_MAP_PATH,
    generateContactOptions,
    newLocation
}