const AU_REGEX = /^[0-9]{4}$/
const CA_REGEX = /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/ ;
const DE_REGEX = /^[0-9]{5}$/
const FR_REGEX = /^[0-9]{5}$/
const JP_REGEX = /^[0-9]{3}-[0-9]{4}$/
const US_REGEX = /^[0-9]{5}(-[0-9]{4})?$/


const COUNTRY_FORMAT_MAPS = {
    AU: {
        regex: AU_REGEX,
        formatPostalCode: (code)=> code
    },
    CA: {
        regex: CA_REGEX,
        formatPostalCode: (code) => code ? code.replace(/^([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][a-zA-Z])([0-9][a-zA-Z][0-9])$/i, '$1 $2') : ''
    },
    DE: {
        regex: DE_REGEX,
        formatPostalCode: (code)=> code
    },
    FR: {
        regex: FR_REGEX,
        formatPostalCode: (code)=> code
    },
    JP: {
        regex: JP_REGEX,
        formatPostalCode: (code) => code ? code.replace(/(\d{3})(\d{4})/, '$1-$2') : ''
    },
    US: {
        regex: US_REGEX,
        formatPostalCode: (code) => code ? code.replace(/(\d{5})(\d{4})/, '$1-$2') : ''
    },

};

export default { COUNTRY_FORMAT_MAPS }