import React from 'react';
import PropTypes from 'prop-types';


import { 
    BaseWizardPageHeader,
    BaseWizardPageSideHeader,
} from 'wni-portals-wizard-components-ui';

/**
 * @prop {string} propTypes.wizardSubmission - submission view model
 * @prop {string} propTypes.isSideHeader - flag to specify side header
 */

const WizardPageHeader = (props) => {
    const {
        isSideHeader, // Only used in CPBuildingsAndLocationsPage, to be removed.
        ...otherProps
    } = props;
    
    if (isSideHeader) {
        return (<BaseWizardPageSideHeader {...otherProps} />);
    }
    return (
        <BaseWizardPageHeader {...otherProps} />
    );

};

WizardPageHeader.propTypes = {
    ...BaseWizardPageHeader.propTypes,  
    isSideHeader: PropTypes.bool,
};

WizardPageHeader.defaultProps = {
    ...BaseWizardPageHeader.defaultProps,
    // To be removed
    isSideHeader: false,
};

export default WizardPageHeader;
