import React, { useEffect, useState, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { WniAccountService, WniDocumentRetrievalService, WniMultiDocumentRetrievalService } from 'wni-capability-gateway';
import { WniCLBillingSubmissionService, WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { AttachmentUtil, DocumentsUtil, EmailUtil, ServiceErrorUtil } from 'wni-portals-util-js';
import { BaseDocumentSendViaEmailPopup, BaseDocumentCheckedComponentMessages } from 'wni-capability-common-react';
import { useWniModal } from 'wni-components-platform-react';
import DocumentListComponent from 'wni-capability-gateway-react/components/DocumentListComponent/DocumentListComponent';
import metadata from './AccountDocuments.metadata.json5';
import styles from './AccountDocuments.module.scss';

const attachmentPageService = {
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadAttachment: GatewayDocumentService.uploadDocument,
    // removeAttachment: GatewayDocumentService.removeDocument,
    removeAttachment: WniAccountService.removeAttachment,
    getAttachmentLink: GatewayDocumentService.downloadDocument,
    downloadAttachment: DocumentDownloadService.getDocument
};

function AccountDocuments(props) {
    const modalApi = useWniModal();
    const {
        fromAccountLanding: {
            accountDetailsData: { accountNumber, accountHolder },
            accountActivitiesData: {
                updateCountInfoDirectly
            }
        },
        history
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const authHeader = _.get(useAuthentication(), 'authHeader');
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const [initAccountDocuments, updateInitAccountDocuments] = useState([]);
    const [initBillingAccountDocuments, updateInitBillingAccountDocuments] = useState([]);

    const isCL = _.get(accountHolder, 'contactType_Ext')  === 'company';

    const createBillingDcoumentVM = useCallback(
        (model) => {
            return viewModelService.create(
                model,
                'bc',
                'wni.edge.capabilities.gateway.dto.DocumentDTO'
            );
        },
        [viewModelService]
    );

    const retrieveInitAccountDocAndAttachments = useCallback(async () => {
        const response =
            await WniAccountService.getDocAndAttachmentForAccount(
                accountNumber,
                isCL,
                authHeader
            );
        const accountDocAndAttachments = DocumentsUtil.updateDocumentUnselectable(response);
        updateInitAccountDocuments(accountDocAndAttachments);
        return accountDocAndAttachments;
    }, [accountNumber, isCL, authHeader]);

    const retrieveInitBillingAccountDocuments = useCallback(async () => {
        const response =
            // await WniCLBillingSubmissionService.getPortalDocumentsForAccount(
            await WniGatewayBillingSubmissionService.getDocumentsForAccount(
                [accountNumber],
                authHeader
            );
        const billingAccountDocuments = DocumentsUtil.updateDocumentUnselectable(response);
        updateInitBillingAccountDocuments(billingAccountDocuments);
        return billingAccountDocuments;
    }, [accountNumber, authHeader]);

    useEffect(() => {
        async function retrieveInitDatas() {
            try {
                setLoadingMask(true);
                // await retrieveInitAttachments();
                // await retrieveInitAccountDocuments();
                await retrieveInitAccountDocAndAttachments();
                await retrieveInitBillingAccountDocuments();
            } finally {
                setLoadingMask(false);
            }
        }
        retrieveInitDatas();
    }, []);

    // useEffect(() => {
        // const totalCount = _.get(initAccountDocuments, 'length') + _.get(initBillingAccountDocuments, 'length');
        // updateCountInfoDirectly('Documents', totalCount);
    // }, [initAccountDocuments, initBillingAccountDocuments])


    const uploadAttachment = useCallback(
        async (file, vm) => {
            const jobNumber = '';
            const policyNumber = '';
            const description = _.get(vm, 'value.description');
            const documentType = _.get(vm, 'value.documentType');
            const documentMetaDataTemplate = {
                jobNumber: jobNumber,
                accountNumber: accountNumber,
                policyNumber: policyNumber,
                name: file.name,
                sessionID: await GatewayDocumentService.generateUploadToken(authHeader),
                documentType: documentType,
                description: description,
                securityType: 'unrestricted',
                status: 'approved',
                mimeType: file.type,
            };
            if (isCL) {
                // Agency and Internal
                // _.set(documentMetaDataTemplate, 'securityType', 'agency_Ext');
                // Final
                _.set(documentMetaDataTemplate, 'status', 'final');
            }

            try {
                setLoadingMask(true);
                const res = await GatewayDocumentService.uploadDocument(file, documentMetaDataTemplate, authHeader);
                setLoadingMask(false);
                if (!_.isEmpty(res)) {
                    return retrieveInitAccountDocAndAttachments();
                }
            } catch (error) {
                setLoadingMask(false);
                return modalApi.showAlert({
                    title: commonMessages.errorUploadTitle,
                    message: commonMessages.uploadFailedMessage,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(_.noop);
            }
        },
        [accountNumber, authHeader, isCL, modalApi, retrieveInitAccountDocAndAttachments, setLoadingMask]
    );

    const successCallback = useCallback(() => {
        return DocumentsUtil.defaultSuccessCallback(setLoadingMask);
    }, [setLoadingMask]);

    const errorCallback = useCallback(() => {
        return DocumentsUtil.defaultErrorCallback(modalApi, setLoadingMask, ServiceErrorUtil);
    }, [modalApi, setLoadingMask]);

    const printAllErrorCallBack = useCallback((exception) => {
        return DocumentsUtil.defaultPrintAllErrorCallBack(exception, modalApi, setLoadingMask, ServiceErrorUtil)
    }, [modalApi, setLoadingMask]);

    const handleBCPrintAllFn = useCallback(
        async (docs) => {
            const targetProxiedServiceUrl = 'wniBCDocumentRetrieval';
            const multiTargetProxiedServiceUrl = 'wniBCMultiDocumentRetrieval';
            await DocumentsUtil.handlePrintAllFn(
                docs,
                setLoadingMask,
                authHeader,
                history,
                WniDocumentRetrievalService,
                successCallback,
                errorCallback,
                WniMultiDocumentRetrievalService,
                printAllErrorCallBack,
                targetProxiedServiceUrl,
                multiTargetProxiedServiceUrl
            );
        },
        [
            authHeader,
            errorCallback,
            history,
            printAllErrorCallBack,
            setLoadingMask,
            successCallback,
        ]
    );

    const handlePCPrintAllFn = useCallback(
        async (docs) => {
            await DocumentsUtil.handlePrintAllFn(
                docs,
                setLoadingMask,
                authHeader,
                history,
                WniDocumentRetrievalService,
                successCallback,
                errorCallback,
                WniMultiDocumentRetrievalService,
                printAllErrorCallBack
            );
        },
        [
            authHeader,
            errorCallback,
            history,
            printAllErrorCallBack,
            setLoadingMask,
            successCallback,
        ]
    );

    const handleBCAccountSendViaEmailFn = useCallback((documents)=> {
        const validDocs = DocumentsUtil.filterValidDocuments(documents);
        const emailAddress = _.get(accountHolder, 'emailAddress1') || _.get(accountHolder, 'emailAddress2_Ext');
        const componentProps = {
            isOpen: true,
            documents: validDocs,
            useAccountNumber: true,
            accountNumber,
            accountName: _.get(accountHolder, 'displayName'),
            isBC: true,
            emailAddress: emailAddress || ''
        };
        return EmailUtil.showSendViaEmailPopup(
            modalApi,
            componentProps,
            BaseDocumentSendViaEmailPopup,
            BaseDocumentCheckedComponentMessages
        );
    }, [accountHolder, accountNumber, modalApi]);

    const handlePCAccountSendViaEmailFn = useCallback((documents)=> {
        const validDocs = DocumentsUtil.filterValidDocuments(documents);
        const emailAddress = _.get(accountHolder, 'emailAddress1') || _.get(accountHolder, 'emailAddress2_Ext');
        const componentProps = {
            isOpen: true,
            documents: validDocs,
            useAccountNumber: true,
            accountNumber,
            accountName: _.get(accountHolder, 'displayName'),
            emailAddress: emailAddress || ''
        };
        return EmailUtil.showSendViaEmailPopup(
            modalApi,
            componentProps,
            BaseDocumentSendViaEmailPopup,
            BaseDocumentCheckedComponentMessages
        );
    }, [accountHolder, accountNumber, modalApi]);

    const overrideProps = {
        '@all': {},
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        documentList: {
            accountNumber,
            authHeader,
            viewModelService,
            breakpoint,
            setLoadingMask,
            getDateModifiedFn: AttachmentUtil.getDateModifiedFn,
            initAccountDocuments: initAccountDocuments,
            initBillingAccountDocuments: initBillingAccountDocuments,
            createBillingDcoumentVM,
            onDemandDocumentDownloadFn: async (item) => {
                await DocumentsUtil.onDemandDocumentDownloadFn(
                    item, authHeader, history, modalApi, setLoadingMask,
                    ServiceErrorUtil, WniDocumentRetrievalService
                )
            },
            downloadPCDocument: async (publicID, sessionID, item) => {
                try {
                    setLoadingMask(true);
                    await DocumentsUtil.tryDownloadDocument(
                        item, authHeader, history, WniDocumentRetrievalService,
                        successCallback, errorCallback
                    );
                } finally {
                    setLoadingMask(false);
                }
            },
            downloadBCDocument: async (publicID, sessionID, item) => {
                try {
                    setLoadingMask(true);
                    const targetProxiedServiceUrl = 'wniBCDocumentRetrieval';
                    await DocumentsUtil.tryDownloadDocument(
                        item, authHeader, history, WniDocumentRetrievalService,
                        successCallback, errorCallback, targetProxiedServiceUrl
                    );
                } finally {
                    setLoadingMask(false);
                }
            },
            handlePCPrintAllFn: handlePCPrintAllFn,
            handleBCPrintAllFn: handleBCPrintAllFn,
            handlePCSendViaEmailFn: handlePCAccountSendViaEmailFn,
            handleBCSendViaEmailFn: handleBCAccountSendViaEmailFn,
            showAccountDocumentsDetailAccordion: true,
            showBillingDocumentsDetailAccordion: true,
            overrideTitleStyle: isCL,
            uploadDocument: uploadAttachment,
            documentNameColumnPath: 'simpleName_Ext',
            pageSizeConfig: { pageSize: 5 },
            pageSizeOptions: [5, 10, 20]
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            documentListComponent: DocumentListComponent
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(AccountDocuments);
