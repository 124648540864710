import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CRCoverablePage.metadata.json5';
import WizardPage from '../../templates/CRWizardPage';
import CoveragesConfigContext from '../../context/CRCoveragesConfigContext';
import CRCoveragesConfig from '../../config/CRCoverages.config';
import CoveragesCard from './component/CoveragesCard';
import AdditionalCoveragesCard from './component/AdditionalCoveragesCard';
import ExclusionsAndConditionsCard from './component/ExclusionsAndConditionsCard/ExclusionsAndConditionsCard';
import CRSearchAndAddClausesPopup from '../../components/CRSearchAndAddClausesPopup/CRSearchAndAddClausesPopup.jsx';

const CRCoverablePage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        initClausesWithSyncFunc,
        initClausesFunc,
        updateClausesFunc,
        onPageNextService,
        searchClausesFunc,
        addSearchedClausesFunc,
        coverableName,
        coverableCategory,
        markFollowingWizardStepsUnvisited,
    } = props;

    const { initialValidation, onValidate, isComponentValid } = useValidation('CRCoverablePage');
    const { authHeader } = useAuthentication();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [coverableCoverages, setCoverableCoverages] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverableCoverages = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await initClausesWithSyncFunc(jobID, sessionUUID, authHeader) 
            : await initClausesFunc(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setCoverableCoverages(res)
        setLoadingMask(false)
    }, [authHeader, initClausesFunc, initClausesWithSyncFunc, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverableCoverages()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            searchClausesFunc,
            addSearchedClausesFunc,
            coverableName,
            coverableCategory
        };
        return modalApi.showModal(
            <CRSearchAndAddClausesPopup {...componentProps} />
        );
    }, [addSearchedClausesFunc, authHeader, coverableCategory, coverableName, jobID, modalApi, searchClausesFunc, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType)
        setCoverableCoverages(data)
    },[showCoveragesModal])

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        markFollowingWizardStepsUnvisited();
        updateWizardData(newSubmissionVM)
    } 
    

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await onPageNextService(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const accordionCommonProps = {
        coverableCoverages: coverableCoverages,
        setCoverableCoverages: (newCoverableCoverages) => {
            setCoverableCoverages(newCoverableCoverages)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
        },
        isEditing,
        setIsEditing,
        isEditable: true,
        showErrors,
        onValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateClausesFunc: updateClausesFunc,
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        CoveragesCard: {
            ...accordionCommonProps
        },
        AdditionalCoveragesCard: {
            ...accordionCommonProps
        },
        ConditionsAndExclusionsCard: {
            ...accordionCommonProps
        },
        AdditionalInsuredsCard: {
            ...accordionCommonProps
        },
        ExclusionsAndConditionsCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            CoveragesCard: CoveragesCard,
            AdditionalCoveragesCard: AdditionalCoveragesCard,
            ExclusionsAndConditionsCard: ExclusionsAndConditionsCard,
        },
    };

    const disableNavigator = isEditing || isLoadingMask

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {coverableCoverages && 
                <CoveragesConfigContext.Provider value={CRCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default CRCoverablePage;