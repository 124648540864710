

import React from 'react';
import { IMContractorsEquipmentReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMContractorsEquipmentChangeReadOnlyPage = (props) => {
    return <IMContractorsEquipmentReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMContractorsEquipmentChangeReadOnlyPage
