
import React from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { IMSignsPage } from 'wni-capability-quoteandbind-im-react';


function IMSignsReadOnlyPage(props) {

    // const {
    //     wizardData: submissionVM,
    // } = props;

    const IMOverrideProps = {
    }

    return (
        <IMSignsPage
            {...props}
            // customMessages = {messages}
            IMOverrideProps = {IMOverrideProps}
            isEditable = {false}
        />
    );

}

IMSignsReadOnlyPage.propTypes = {
    ...wizardProps,
};

IMSignsReadOnlyPage.defaultProps = {
};

export default IMSignsReadOnlyPage;
