import _ from 'lodash'

export const getContactDisplayName = (contact) => {
    let optionName = '-'
        switch (_.get(contact, 'subtype')) {
            case 'Person':
            case 'UserContact':
                optionName = `${_.get(contact, 'firstName', '-')} ${_.get(contact, 'lastName', '-')}`
                break
            case 'Company':
                optionName = `${_.get(contact, 'contactName', '-')}`
                break
            default:
                break;
        }

    return optionName
}