import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    // WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import WizardPage from '../../templates/IMWizardPage';

import styles from './IMDummyPage.module.scss';
import metadata from './IMDummyPage.metadata.json5';
import messages from './IMDummyPage.messages';

function IMDummyPage(props) {
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        //
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
    } = props;

    // const {
    //     jobID, sessionUUID,
    //     baseData: {
    //         termType,
    //         periodStatus,
    //         selectedVersion_Ext: selectedVersion,
    //         accountHolder: {
    //             primaryAddress: {
    //                 postalCode,
    //             }
    //         },
    //         displayStatus_Ext: displayStatus,
    //     },
    //     lobData: {
    //         'inlandMarine': {
    //             //
    //         }
    //     },
    // } = submissionVM.value;

    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMDummyPage');
    
    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    useEffect(() => {

    }, []);

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    const onPageNext = useCallback(async () => {
        //
        return submissionVM;
    }, [authHeader, submissionVM]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
        };
    }, [breakpoint, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

IMDummyPage.propTypes = WizardPage.propTypes;
IMDummyPage.defaultProps = WizardPage.defaultProps;
export default IMDummyPage;