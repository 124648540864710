import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { DriverCoveragesSection, DriverCoveragesComponent } from 'wni-capability-gateway-react';
import { WniDriverService } from 'wni-capability-quoteandbind';
import {
    DriverUtil,
    QuoteUtil,
    DriverValidationUtil,
    DriverCoveragesUtil,
    ErrorsAndWarningsUtil,
    OOSUtil,
} from 'wni-portals-util-js';

import metadata from './PADriverCoveragesPage.metadata.json5';
import messages from './PADriverCoveragesPage.messages';

function DriverCoveragesPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        policyChangeService = {},
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const { authHeader } = useAuthentication();
    const {
        initialValidation,
        onValidate,
        isComponentValid
    } = useValidation('DriverCoveragesPage');
    const {
        quoteID,
        sessionUUID,
        baseData: {
            effectiveDate
        },
        oossliceDates_Ext: oossliceDates,
    } = submissionVM.value;

    const [showLoader, updateShowLoader] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [drivers, updateDrivers] = useState([]);
    const [loadingClause, updateLoadingClause] = useState();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const getDriversCoverages = useCallback(async () => {
        updateShowLoader(true);
        let res = {};
        if (_.isFunction(policyChangeService.getDriversCoverages)) {
            res = await policyChangeService.getDriversCoverages();
        } else {
            res = await WniDriverService
                .getDriversCoverages({ quoteID, sessionUUID }, authHeader);
        }
        updateDrivers(res);
        updateShowLoader(false);
    }, [authHeader, policyChangeService, quoteID, sessionUUID]);

    useEffect(() => {
        setPageInitialized(true);
        getDriversCoverages();
    }, []);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            return false;
        },
        [updateShowErrors]
    );

    const updateCoverages = useCallback(async (changedPath, isSelectClause, driver) => {
        const covPath = changedPath.split('.')[0];
        const requestData = _.merge(
            {},
            { quoteID },
            { sessionUUID },
            DriverCoveragesUtil.buildUpdateCoveragesReuqest(changedPath, isSelectClause, driver)
        );
        updateLoadingClause(_.get(driver, `${covPath}.publicID`));
        let res = {};
        if (_.isFunction(policyChangeService.updateCoverages)) {
            res = await policyChangeService.updateCoverages(
                requestData.driverDTO, requestData.cov, requestData.scheduleItem
            );
        } else {
            res = await WniDriverService.updateAllCoverages(requestData, authHeader);
        }
        _.forEach(res, (d) => {
            DriverCoveragesUtil.mapScheduleToDriver(d, d.coverages);
        });
        _.set(submissionVM, 'baseData.periodStatus', 'Draft');
        updateWizardSnapshot(submissionVM);
        updateDrivers(res);
        updateLoadingClause(false);
    }, [authHeader, policyChangeService, quoteID, sessionUUID]);

    const generateOverrides = useCallback(() => {
        const overrides = drivers.map((driver, index) => {
            DriverCoveragesUtil.mapScheduleToDriver(driver, driver.coverages);
            return {
                [`coverageSection${index}`]: {
                    visible: driver.coverages.length >= 1,
                    driver,
                    index,
                    showErrors,
                    onValidate,
                    updateCoverages,
                    loadingClause
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [drivers, showErrors, onValidate, updateCoverages, loadingClause]);

    const hasCovDrivers = drivers.filter((d) => d.coverages.length > 0);
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        driverCoveragesIterable: {
            data: drivers
        },
        emptyContent: {
            visible: hasCovDrivers.length <= 0
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues.concat(
                OOSUtil.getOOSSliceDatesWarning(
                    OOSUtil.getOOSSliceDatesString(
                        oossliceDates,
                        effectiveDate
                    ),
                    translator
                )
            ).concat(wizardErrorsAndWarnings),
            issueDistinguisher: true,
            visible: !_.isEmpty(validationIssues)
                || wizardErrorsAndWarnings.length > 0
                || OOSUtil.getOOSSliceDatesString(
                    oossliceDates,
                    effectiveDate
                ).length > 0,
            scrollToIssues: true,
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        if (_.isFunction(policyChangeService.addDriver)) {
            // pending
        } else if (quoteID) { // hotfix for PolicyChange, which doesn't have quoteID
            const res = await WniDriverService.getCoveragesErrorsAndWarnings(quoteID, authHeader);
            const backendIssues = ErrorsAndWarningsUtil.getValidationIssues(res) || [];
            const errorIssues = _.filter(backendIssues, (issue) => {
                return issue.type === 'error';
            });
            updateValidationIssues(backendIssues);
            if (errorIssues.length > 0) {
                updateShowErrors(false);
                return false;
            }
            if (backendIssues.length > 0) {
                if (!showErrors) {
                    updateShowErrors(true);
                    return false;
                }
            }
        }
        setLoadingMask(false);
        return submissionVM;
    }, [submissionVM, showErrors]);

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveComponentMap: {
            ValidationIssuesComponent: ValidationIssuesComponent,
            driverCoveragesSection: DriverCoveragesSection,
            driverCoverages: DriverCoveragesComponent
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            alwaysCallOnNext
            disableNext={showLoader}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={drivers}
                        resolveValue={readValue}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardSnapshot}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

DriverCoveragesPage.propTypes = wizardProps;
export default DriverCoveragesPage;
