import _ from 'lodash';

export const ValueType = {
    Option: 'Option',
    Direct: 'Direct',
    MONEY_EXT: 'MONEY_EXT',
    Shorttext: 'shorttext',
    DateTime: 'datetime',
    Bit: 'bit',
    AdditionalInsured: 'AdditionalInsured',
    AdditionalInterest: 'AdditionalInterest',
    AutoNumber: 'AutoNumber',
    BigDecimal: 'BigDecimal',
    Boolean: 'Boolean',
    Date: 'Date',
    ForeignKey: 'ForeignKey',
    ForeignKeyWithOptionLabels: 'ForeignKeyWithOptionLabels',
    PolicyContact: 'PolicyContact',
    TypeKey: 'TypeKey',
    Integer: 'Integer',
    IntegerRange: 'IntegerRange',
    String: 'String',
    TextArea: 'TextArea',
}

export const ValuePath = {
    TypeCodeValue: 'typeCodeValue',
    BigDecimal: 'bigDecimal',
    StringValue: 'stringValue',
    DateValue: 'dateValue',
    BooleanValue: 'booleanValue',
    IntegerValue: 'integerValue',
    LocalDate: 'localDate_Ext',
}

export const valueTypeNameMap = {
    [ValueType.Option]: ValuePath.TypeCodeValue,
    [ValueType.Direct]: ValuePath.BigDecimal,
    [ValueType.MONEY_EXT]: ValuePath.BigDecimal,
    [ValueType.Shorttext]: ValuePath.StringValue,
    [ValueType.DateTime]: ValuePath.DateValue,
    [ValueType.Bit]: ValuePath.BooleanValue
}

export const schedulePropertyInfoTypeMap = {
    [ValueType.AdditionalInsured]: ValuePath.TypeCodeValue,
    [ValueType.AdditionalInterest]: ValuePath.TypeCodeValue,
    [ValueType.ForeignKey]: ValuePath.TypeCodeValue,
    [ValueType.ForeignKeyWithOptionLabels]: ValuePath.TypeCodeValue,
    [ValueType.Option]: ValuePath.TypeCodeValue,
    [ValueType.PolicyContact]: ValuePath.TypeCodeValue,
    [ValueType.TypeKey]: ValuePath.TypeCodeValue,
    [ValueType.AutoNumber]: ValuePath.IntegerValue,
    [ValueType.Integer]: ValuePath.IntegerValue,
    [ValueType.IntegerRange]: ValuePath.IntegerValue,
    [ValueType.BigDecimal]: ValuePath.BigDecimal,
    [ValueType.Boolean]: ValuePath.BooleanValue,
    [ValueType.Date]: ValuePath.LocalDate,
    [ValueType.String]: ValuePath.StringValue,
    [ValueType.TextArea]: ValuePath.StringValue,
    
};

export const valueTypeNameDatatypeMap = {
    Option: {
        dataType: 'string',
        component: 'WniDropdownSelect'
    },
    Direct: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    MONEY_EXT: {
        dataType: 'number',
        component: 'WniCurrency'
    },
    shorttext: {
        dataType: 'string',
        component: 'WniInputText'
    },
    datetime: {
        dataType: 'date',
        component: 'WniDate'
    },
    bit: {
        dataType: 'boolean',
        component: 'WniToggle'
    }
};

export const schedulePropertyInfoTypeDataTypeMap = {
    AdditionalInsured: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    AdditionalInterest: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    AutoNumber: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    BigDecimal: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    Boolean: {
        dataType: 'boolean',
        component: 'WniToggle'
    },
    Date: {
        dataType: 'object',
        component: 'WniDate'
    },
    ForeignKey: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    ForeignKeyWithOptionLabels: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    Integer: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    IntegerRange: {
        dataType: 'number',
        component: 'WniInputNumber'
    },
    Option: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    PolicyContact: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
    String: {
        dataType: 'string',
        component: 'WniInputText'
    },
    TextArea: {
        dataType: 'string',
        component: 'WniInputText'
    },
    TypeKey: {
        dataType: 'select',
        component: 'WniDropdownSelect'
    },
};

export const valueTypePropDatatypeMap = {
    TYPEKEY: 'string',
    INTEGER: 'number',
    DATE: 'string'
};

export function formatAddress(address) {
    const addressValues = [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.city,
        address.postalCode,
        address.state
    ].filter((addressValue) => !_.isUndefined(addressValue));

    return address ? addressValues.join(', ') : undefined;
}

export function getFormattedValue(value, propertyInfo) {
    const {
        valueTypeName_Ext: valueTypeName,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo;
    const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : schedulePropertyInfoTypeMap[schedulePropertyInfoType];

    if (valuePath === 'typeCodeValue') {
        const availableValue = _.get(propertyInfo, 'availableTypeListValues', []).find(
            (av) => av.code === value || av.displayKey === value
        );
        return _.get(availableValue, 'displayKey');
    }
    return value;
}

export function getPropDataType({ valueType }) {
    return _.has(valueTypePropDatatypeMap, valueType)
        ? _.get(valueTypePropDatatypeMap, valueType) : undefined;
}

export function isShceduleItemDataItemDiff(item1, item2) {
    if (_.isNil(item1) || _.isNil(item2)) {
        return true
    }
    const {
        typeCodeValue: typeCodeValueItem1,
        bigDecimal: bigDecimalItem1,
        stringValue: stringValueItem1,
        dateValue: dateValueItem1,
        booleanValue: booleanValueItem1,
        localDate_Ext: localDate1,
    } = item1
    const {
        typeCodeValue: typeCodeValueItem2,
        bigDecimal: bigDecimalItem2,
        stringValue: stringValueItem2,
        dateValue: dateValueItem2,
        booleanValue: booleanValueItem2,
        localDate_Ext: localDate2,
    } = item2
    if (typeCodeValueItem1 !== typeCodeValueItem2
        || bigDecimalItem1 !== bigDecimalItem2
        || stringValueItem1 !== stringValueItem2
        || dateValueItem1 !== dateValueItem2
        || booleanValueItem1 !== booleanValueItem2
        || !_.isEqual(localDate1, localDate2)
    ) {
        return true
    }

    return false
}


