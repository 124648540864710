import { defineMessages } from 'react-intl';

export default defineMessages({
    Quote: {
        id: 'gw-portals-bop-components-angular.quote.Quote',
        defaultMessage: 'Quote'
    },
    startDate: {
        id: 'quoteandbind.bop.quote.Start Date',
        defaultMessage: 'Start Date'
    },
    endDate: {
        id: 'quoteandbind.bop.quote.End Date',
        defaultMessage: 'End Date'
    },
    totalPremium: {
        id: 'quoteandbind.bop.quote.Total Premium',
        defaultMessage: 'Total Premium'
    },
    taxes: {
        id: 'quoteandbind.bop.quote.Taxes',
        defaultMessage: 'Taxes'
    },
    totalCost: {
        id: 'quoteandbind.bop.quote.TOTAL COST',
        defaultMessage: 'TOTAL COST'
    },
    policyPeriod: {
        id: 'quoteandbind.bop.quote.Policy Period',
        defaultMessage: 'Policy Period'
    },
    editCoverages: {
        id: 'quoteandbind.bop.quote.Edit Coverages',
        defaultMessage: 'Edit Coverages'
    },
    policyStartDate: {
        id: 'quoteandbind.directives.bop.QuoteDetailsBox.Policy Start Date',
        defaultMessage: 'Policy Start Date'
    },
    policyEndDate: {
        id: 'quoteandbind.directives.bop.QuoteDetailsBox.Policy End Date',
        defaultMessage: 'Policy End Date'
    }
});
