import React, {
    useContext,
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniLoadSaveService, WniPAQuoteService } from 'wni-capability-quoteandbind';
import {
    PaymentInputComponent,
    NewPersonComponent
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { WniDateUtil } from 'wni-portals-util-js';
import { DateUtil } from '@xengage/gw-portals-util-js';
import { PaymentPlanConfig, WizardConstants } from 'wni-portals-config-js';
import messages2 from './PaymentDetailsPage.messages';
import metadata from './PaymentDetailsPage.metadata.json5';
import styles from './PaymentDetailsPage.module.scss';
import AddPaymentMethodPopup from './AddPaymentMethodPopup';


const AGENCY_SWEEP_AVAILABLE_OPTIONS = [
    {
        code: 'AgencySweep',
        name: {
            id: 'quoteandbind.views.payment-details.Down Payment.Agency Sweep',
            defaultMessage: 'Agency Sweep',
        },
    },
    {
        code: 'Electronic',
        name: {
            id: 'quoteandbind.views.payment-details.Down Payment.Electronic',
            defaultMessage: 'Electronic',
        },
    },
];

const AGENCY_SWEEP_UNAVAILABLE_OPTIONS = [
    {
        code: 'Electronic',
        name: {
            id: 'quoteandbind.views.payment-details.Down Payment.Electronic',
            defaultMessage: 'Electronic',
        },
    },
];

/* eslint-disable no-param-reassign */
function initialiseVM(submissionVM) {
    submissionVM.bindData.paymentDetails.value = (submissionVM.bindData.paymentDetails
        && submissionVM.bindData.paymentDetails.value)
        ? submissionVM.bindData.paymentDetails.value : {};
}

/* eslint-enable no-param-reassign */
function PaymentDetailsPage(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM, updateWizardData, wizardSnapshot,
        updateWizardPageData, wizardPageData, goNext
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const { authHeader } = useAuthentication();
    const [hasRetrievedPaymentPlans, updateHasRetrievedPaymentPlans] = useState(false);
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    // for popup to add a new PaymentMethod
    const [popupPaymentMethodVM, updatePopupPaymentMethodVM] = useState(null);
    // PaymentMethods added
    const [paymentMethodsVM, updatePaymentMethodsVM] = useState([]);

    const [showErrors, updateShowErrors] = useState(false);
    const [primaryPayer, updatePrimaryPayer] = useState('');
    const [paymentDetailsForDownPayment, updatePaymentDetailsForDownPayment] = useState(null);
    const [showLoader, updateShowLoader] = useState(false);
    const [fullPayTotalCost, updateFullPayTotalCost] = useState(null);
    const [showNotification, updateShowNotification] = useState(false);
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);
    const [dueDateExt, updateDueDateExt] = useState(null);
    const [getPayInstrumentsFinished, updateGetPayInstrumentsFinished] = useState(false);
    const [getDueDateFinished, updateGetDueDateFinished] = useState(false);
    const [retrievePaymentPlansFinished, updateRetrievePaymentPlansFinished] = useState(false);
    const [selectPaymentPlanFinished, updateSelectPaymentPlanFinished] = useState(false);
    const [primaryPayerAvailableValues, updatePrimaryPayerAvailableValues] = useState([]);
    // const [bccUrl, updateBccUrl] = useState('');

    const {
        paymentPlanData, paymentMethodData, payUsing, fullPayStr,
    } = PaymentPlanConfig;

    const resetPopupPaymentMethodVM = useCallback(() => {
        const paymentDetails = {
            paymentMethod: 'autopay_Ext',
            bankAccountData: {
                bankAccountType: 'checking'
            }
        };
        const paymentDetailsVM = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
        updatePopupPaymentMethodVM(paymentDetailsVM);
    }, [viewModelService]);

    const getDownPaymentRaido = useCallback(() => {
        const showAgencySweep = _.get(submissionVM, 'bindData.agencySweep_Ext.value');
        if (showAgencySweep && showAgencySweep === 'Yes') {
            return AGENCY_SWEEP_AVAILABLE_OPTIONS;
        }
        return AGENCY_SWEEP_UNAVAILABLE_OPTIONS;
    }, [submissionVM]);

    const paymentPlanChange = useCallback(async (paymentPlan) => {
        const { quoteID, sessionUUID } = submissionVM.value;
        let premium;
        if (_.get(paymentPlan, 'billingId')) {
            updateSelectPaymentPlanFinished(false);
            updateShowLoader(true);
            premium = await WniLoadSaveService.selectPaymentPlan(quoteID, sessionUUID, _.get(paymentPlan, 'billingId'), authHeader);
        }
        updateSelectPaymentPlanFinished(true);
        updateShowLoader(false);

        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );
        const totalPremium = _.get(premium, 'totalPremium_Ext');
        const taxAndSurchargesRPT = _.get(premium, 'taxAndSurchargesRPT_Ext');
        const installmentFees = _.get(premium, 'installmentFees_Ext');
        const fullPayDiscount = _.get(premium, 'fullPayDiscount_Ext');
        _.set(chosenQuoteData, 'premium.totalPremium_Ext', totalPremium);
        _.set(chosenQuoteData, 'premium.taxAndSurchargesRPT_Ext', taxAndSurchargesRPT);
        _.set(chosenQuoteData, 'premium.billingFees_Ext',installmentFees);
        _.set(chosenQuoteData, 'premium.fullPayDiscount_Ext', fullPayDiscount);

        // (None full pay) Total Premium =PC(Premium + Taxes and Surcharges + TotalFee)
        // (Full Pay) Total Premium =PC(Premium + Taxes and Surcharges + TotalFee + FullPayDiscount)
        let totalPremiumWithTaxAndSurchargesAmount;
        // Total Cost = PORTAL(Total Premium - full pay discount)
        let totalCostAmount;
        if (_.get(paymentPlan, 'name') === 'Full Pay') {
            totalCostAmount = _.get(totalPremium, 'amount') + _.get(taxAndSurchargesRPT, 'amount')
                + _.get(installmentFees, 'amount');
            totalPremiumWithTaxAndSurchargesAmount = totalCostAmount + _.get(fullPayDiscount, 'amount');
        } else {
            totalCostAmount = _.get(totalPremium, 'amount') + _.get(taxAndSurchargesRPT, 'amount')
                + _.get(installmentFees, 'amount');
            totalPremiumWithTaxAndSurchargesAmount = totalCostAmount;
        }
        const totalPremiumWithTaxAndSurcharges = {
            amount: totalPremiumWithTaxAndSurchargesAmount,
            currency: _.get(totalPremium, 'currency')
        };
        _.set(chosenQuoteData, 'premium.totalPremiumWithTaxAndSurcharges_Ext', totalPremiumWithTaxAndSurcharges);

        const totalCost = {
            amount: totalCostAmount,
            currency: _.get(totalPremium, 'currency')
        };
        _.set(chosenQuoteData, 'premium.totalCost_Ext', totalCost);

        _.set(submissionVM, 'bindData.agencySweepValue_Ext', _.get(paymentPlan, 'downPayment_Ext'));
        _.set(submissionVM, 'bindData.electronicValue_Ext', _.get(paymentPlan, 'downPayment_Ext'));
        _.set(chosenQuoteData, 'downPayment_Ext', _.get(paymentPlan, 'downPayment_Ext'));
        _.set(chosenQuoteData, 'frequency_Ext', _.get(paymentPlan, 'invoiceFrequency_Ext'));
        _.set(chosenQuoteData, 'installments_Ext', _.get(paymentPlan, 'installment_Ext'));
        _.set(submissionVM, 'bindData.selectedPaymentPlan', _.get(paymentPlan, 'billingId'));
        _.set(chosenQuoteData, 'planName', _.get(paymentPlan, 'name'));

        updateWizardData(submissionVM);
        updateWizardSnapshot(submissionVM);
    }, [authHeader, submissionVM, updateWizardData, updateWizardSnapshot]);

    useEffect(() => {
        function getPayInstruments(quoteID, sessionUUID) {
            WniPAQuoteService.getPayInstruments(quoteID, sessionUUID, authHeader)
                .then((res) => {
                    const paymentMethods = [];
                    _.forEach(res, (bank) => {
                        const bankAccountType = _.get(bank, 'bankAccountType_Ext') === 'checking_Ext'
                            ? 'checking' : 'savings';
                        const paymentDetails = {
                            paymentMethod: 'autopay_Ext',
                            bankAccountData: {
                                bankABANumber: _.get(bank, 'routingNumber_Ext'),
                                bankAccountNumber: _.get(bank, 'accountNumber_Ext'),
                                bankAccountType: bankAccountType,
                                bankName: _.get(bank, 'bankName_Ext'),
                                notes_Ext: _.get(bank, 'Note_Ext')
                            }
                        };
                        const node = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
                        paymentMethods.push(node);
                    });
                    updatePaymentMethodsVM(paymentMethods);
                    // stash for previous
                    updateWizardPageData({ [WizardConstants.paymentMethods]: paymentMethods });
                    // loading visible
                    updateGetPayInstrumentsFinished(true);
                }).catch(() => {
                    // loading visible
                    updateGetPayInstrumentsFinished(true);
                });
        }

        function getDueDate(quoteID, sessionUUID) {
            WniPAQuoteService.getDueDate(quoteID, sessionUUID, authHeader)
                .then((res) => {
                    updateDueDateExt(res);
                    _.set(submissionVM, 'dueDate_Ext', res);
                    if (!_.isNil(res)) {
                        _.set(submissionVM, 'bindData.dueDayOfTheMonth_Ext', new Date(res).getDate());
                    }
                    // stash for previous
                    updateWizardPageData({ [WizardConstants.dueDateExt]: res });
                    // loading visible
                    updateGetDueDateFinished(true);
                }).catch(() => {
                    // loading visible
                    updateGetDueDateFinished(true);
                });
        }

        if (!isVMInitialised) {
            updateShowLoader(true);
            initialiseVM(submissionVM);
            updateIsVMInitialised(true);
            // set up default value
            if (!_.get(submissionVM, 'bindData.payUsing_Ext.value')) {
                _.set(submissionVM, 'bindData.payUsing_Ext', payUsing.check);
                updateWizardData(submissionVM);
            }
            // set up default primaryPayer_Ext
            let defaultPrimaryPayer = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.displayName');
            if (!defaultPrimaryPayer) {
                defaultPrimaryPayer = '-';
            }
            _.set(submissionVM, 'bindData.primaryPayer_Ext', _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext'));
            updatePrimaryPayer(defaultPrimaryPayer);
            // set primaryPayerOptions
            const primaryPayerOptions = [];
            const nonBillingContacts = _.get(submissionVM, 'value.bindData.nonBillingContacts_Ext');
            _.forEach(nonBillingContacts, (contact) => {
                primaryPayerOptions.push({
                    code: JSON.stringify(contact),
                    name: _.get(contact, 'displayName')
                });
            });
            const billingContacts = _.get(submissionVM, 'value.bindData.billingContacts_Ext');
            _.forEach(billingContacts, (contact) => {
                primaryPayerOptions.push({
                    code: JSON.stringify(contact),
                    name: _.get(contact, 'displayName')
                });
            });
            primaryPayerOptions.push({
                code: 'New Person',
                name: 'New Person'
            });
            updatePrimaryPayerAvailableValues(primaryPayerOptions);

            // initialise popupPaymentMethodVM
            resetPopupPaymentMethodVM();

            const { quoteID, sessionUUID } = submissionVM.value;
            const paymentMethodsVMCache = wizardPageData[WizardConstants.paymentMethods];
            if (_.isEmpty(paymentMethodsVMCache)) {
                getPayInstruments(quoteID, sessionUUID);
            } else {
                updatePaymentMethodsVM(paymentMethodsVMCache);
                // loading visible
                updateGetPayInstrumentsFinished(true);
            }
            const dueDateExtCache = wizardPageData[WizardConstants.dueDateExt];
            if (_.isNil(dueDateExtCache)) {
                getDueDate(quoteID, sessionUUID);
            } else {
                updateDueDateExt(dueDateExtCache);
                _.set(submissionVM, 'dueDate_Ext', dueDateExtCache);
                // loading visible
                updateGetDueDateFinished(true);
            }

            _.set(submissionVM, 'bindData.downPayment_Ext', 'Electronic');
            if (!hasRetrievedPaymentPlans) {
                const paymentPlansCache = wizardPageData[WizardConstants.paymentPlans];
                if (_.isEmpty(paymentPlansCache)) {
                    WniLoadSaveService
                        .retrievePaymentPlans(quoteID, sessionUUID, authHeader)
                        .then((paymentPlans) => {
                            _.set(submissionVM, 'bindData.paymentPlans', paymentPlans);
                            // stash for previous
                            updateWizardPageData({ [WizardConstants.paymentPlans]: paymentPlans });

                            updateWizardData(submissionVM);
                            updateHasRetrievedPaymentPlans(true);
                            if (paymentPlans !== undefined) {
                                const selectedPlanObj = _.get(submissionVM, 'bindData.selectedPaymentPlan');
                                const selectPlan = (selectedPlanObj !== undefined
                                    && selectedPlanObj.value !== undefined)
                                    ? paymentPlans.find((plan) => plan.billingId
                                        === selectedPlanObj.value)
                                    : paymentPlans.find((plan) => plan.name === fullPayStr);
                                paymentPlanChange(selectPlan);
                            }
                            // loading visible
                            updateRetrievePaymentPlansFinished(true);
                        }).catch(() => {
                            // loading visible
                            updateRetrievePaymentPlansFinished(true);
                        });
                } else {
                    _.set(submissionVM, 'bindData.paymentPlans', paymentPlansCache);
                    updateWizardData(submissionVM);
                    updateHasRetrievedPaymentPlans(true);
                    if (paymentPlansCache !== undefined) {
                        const selectedPlanObj = _.get(submissionVM, 'bindData.selectedPaymentPlan');
                        const selectPlan = (selectedPlanObj !== undefined
                            && selectedPlanObj.value !== undefined)
                            ? paymentPlansCache.find((plan) => plan.billingId
                                === selectedPlanObj.value)
                            : paymentPlansCache.find((plan) => plan.name === fullPayStr);
                        paymentPlanChange(selectPlan);
                    }
                    // loading visible
                    updateRetrievePaymentPlansFinished(true);
                }
            }
        }
    }, [isVMInitialised, submissionVM, updateWizardData,
        resetPopupPaymentMethodVM, authHeader,
        viewModelService, hasRetrievedPaymentPlans,
        payUsing.check, paymentPlanChange, fullPayStr,
        paymentMethodsVM, updateWizardPageData,
        wizardPageData]);

    useEffect(() => {
        if (getDueDateFinished
            && retrievePaymentPlansFinished
            && getPayInstrumentsFinished
            && selectPaymentPlanFinished) {
            updateShowLoader(false);
        }
    }, [getDueDateFinished, retrievePaymentPlansFinished,
        getPayInstrumentsFinished, selectPaymentPlanFinished]);

    const formatPaymentData = useCallback(
        () => {
            const paymentDetailsPath = 'bindData.paymentDetails';
            const val = _.get(submissionVM, 'bindData.payUsing_Ext');
            if (val.value !== PaymentPlanConfig.payUsing.check) {
                const paymentData = paymentMethodsVM.find(
                    (vm) => vm.bankAccountData.bankAccountNumber.value === val.value
                );
                if (paymentData) {
                    if (paymentData.paymentMethod.value === paymentMethodData.bank) {
                        _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', paymentMethodData.bank);
                        _.set(submissionVM, `${paymentDetailsPath}.bankAccountData`, paymentData.bankAccountData.value);
                    } else if (paymentData === paymentMethodData.credit) {
                        _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', paymentMethodData.credit);
                        _.set(submissionVM, `${paymentDetailsPath}.creditCardData`, paymentData.creditCardData.value);
                    }
                }
            }
            _.set(submissionVM, 'bindData.billingMethod_Ext', 'DirectBill');

            const paymentPlans = _.get(submissionVM, 'value.bindData.paymentPlans');
            _.forEach(paymentPlans, (plan) => {
                _.unset(plan, 'treeNode_Ext');
            });

            return submissionVM.value.bindData;
        },
        [submissionVM, paymentMethodsVM, paymentMethodData.bank, paymentMethodData.credit]
    );

    const generateDaysOfMonth = useCallback(() => {
        let days = 31;
        if (submissionVM.quoteData.offeredQuotes && submissionVM.bindData.chosenQuote) {
            if (!_.isNil(dueDateExt)) {
                const dueDate = new Date(dueDateExt);
                const year = DateUtil.getYear(dueDate);
                const month = DateUtil.getMonth(dueDate);
                days = new Date(year, month + 1, 0).getDate();
            }
        }

        return [...Array(days).keys()].map((i) => ({
            code: i + 1,
            name: i + 1,
        }));
    }, [dueDateExt, submissionVM.bindData.chosenQuote,
        submissionVM.quoteData.offeredQuotes]);

    const handlePaymentOptionChange = useCallback((value) => {
        let dataToOmit;
        if (value === paymentMethodData.bank) {
            dataToOmit = 'creditCardData';
            submissionVM.bindData.paymentDetails.bankAccountData.value = _.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData', {});
        } else {
            dataToOmit = 'bankAccountData';
            submissionVM.bindData.paymentDetails.creditCardData.value = _.get(submissionVM.value, 'bindData.paymentDetails.creditCardData', {});
        }

        submissionVM.value = _.omit(
            submissionVM.value,
            `bindData.paymentDetails.${dataToOmit}`
        );

        _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', value);
        updateWizardData(submissionVM);
    }, [paymentMethodData.bank, submissionVM, updateWizardData]);

    const handleDueDateChange = useCallback((value) => {
        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );
        if (!_.isNil(dueDateExt)) {
            const dueDate = new Date(dueDateExt);
            dueDate.setDate(value);
            _.set(chosenQuoteData, 'dueDate_Ext', dueDate);
            _.set(submissionVM, 'bindData.dueDayOfTheMonth_Ext', value);
            // update dueDate_Ext
            _.set(submissionVM, 'dueDate_Ext', dueDate);
            updateDueDateExt(dueDate);
            updateWizardData(submissionVM);
        }
    }, [dueDateExt, submissionVM, updateWizardData]);

    const onCell = (items, index, property) => {
        return items[property.path];
    };

    const showModal = useCallback((getPaymentDetailVM) => {
        const componentProps = {
            title: translator(messages2.addPaymentMethodTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages2.dialogOk,
            cancelBtnLabel: messages2.dialogCancel,
            paymentDetailVM: getPaymentDetailVM,
        };
        return modalApi.showModal(<AddPaymentMethodPopup {...componentProps} />);
    }, [translator]);

    const generateAutoPayEnrollment = useCallback(async (vm) => {
        const { quoteID, sessionUUID } = submissionVM.value;
        WniPAQuoteService.generateAutoPayEnrollment(
            quoteID,
            sessionUUID,
            _.get(vm, 'value.bankAccountData.bankABANumber'),
            _.get(vm, 'value.bankAccountData.bankAccountNumber'),
            _.get(vm, 'value.bankAccountData.bankName'),
            _.get(vm, 'value.bankAccountData.bankAccountType'),
            null,
            authHeader
        );
    }, [authHeader, submissionVM]);

    const addPaymentMethod = useCallback(() => {
        showModal(popupPaymentMethodVM).then((updatedVM) => {
            resetPopupPaymentMethodVM();
            const updateBankAcountNumber = _.get(updatedVM, 'value.bankAccountData.bankAccountNumber');
            if (!_.isEmpty(updateBankAcountNumber)) {
                const result = _.find(paymentMethodsVM, (value) => {
                    return _.get(value, 'value.bankAccountData.bankAccountNumber')
                        === _.get(updatedVM, 'value.bankAccountData.bankAccountNumber')
                        && _.get(value, 'value.bankAccountData.bankABANumber')
                        === _.get(updatedVM, 'value.bankAccountData.bankABANumber');
                });
                if (!_.isEmpty(result)) {
                    return false;
                }
                updateShowNotification(true);
                const modifiedVM = viewModelService.clone(updatedVM);
                updatePaymentMethodsVM([...paymentMethodsVM, modifiedVM]);
                // select option
                _.set(submissionVM, 'bindData.payUsing_Ext', _.get(updatedVM, 'value.bankAccountData.bankAccountNumber'));
                updateWizardData(submissionVM);
                // trigger generate autoPay
                generateAutoPayEnrollment(updatedVM);
                return true;
            }
            return false;
        }).catch(() => {
            resetPopupPaymentMethodVM();
            updateShowNotification(false);
            // do nothing when close the popup
            _.noop();
        });
    }, [showModal, popupPaymentMethodVM, paymentMethodsVM,
        viewModelService, resetPopupPaymentMethodVM,
        submissionVM, updateWizardData, generateAutoPayEnrollment]);

    const isAgencySweepValueValid = useCallback(() => {
        const newValue = _.get(submissionVM, 'bindData.agencySweepValue_Ext.value');
        const defaultValue = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        ).downPayment_Ext;
        if (newValue && (newValue.amount >= defaultValue.amount)) {
            return true;
        }
        return false;
    }, [submissionVM]);

    const callBindSubmission = useCallback(async (
        quoteID, sessionUUID, bindData, ignoreWarning) => {
        const result = await WniLoadSaveService.bindSubmission(
            quoteID,
            sessionUUID,
            bindData,
            ignoreWarning,
            authHeader
        );
        return result;
    }, [authHeader]);

    const callIssueSubmission = useCallback(async (
        quoteID, sessionUUID) => {
        const bindResult = await callBindSubmission(
            quoteID,
            sessionUUID,
            formatPaymentData(),
            true
        );
        const bindValidationIssues = _.get(bindResult, 'errorsAndWarnings.validationIssues') || {};
        const bindIssues = _.get(bindValidationIssues, 'issues') || [];
        const bindFieldIssues = _.get(bindValidationIssues, 'fieldIssues') || [];
        if (!_.isEmpty(bindIssues) || !_.isEmpty(bindFieldIssues)) {
            const allIssues = bindFieldIssues.concat(bindIssues);
            if (!_.isEmpty(allIssues.find((issue) => issue.type === 'error'))) {
                // set errors
                const errors = allIssues
                    .map((issue) => {
                        return {
                            type: issue.type,
                            reason: issue.reason
                        };
                    });
                updateValidationIssuesWarning(errors);
                return false;
            }
        }
        return bindResult;
    }, [callBindSubmission, formatPaymentData]);

    const handleNext = useCallback(
        async () => {
            if (!isAgencySweepValueValid()) {
                updateShowErrors(true);
                return false;
            }
            // no errorsAndWarnings then call bind
            const bindResult = await callIssueSubmission(
                _.get(submissionVM.value, 'quoteID'),
                _.get(submissionVM.value, 'sessionUUID')
            );
            if (!bindResult) {
                return false;
            }
            submissionVM.value = bindResult;
            // window.location.href = bccUrl;
            return submissionVM;
        },
        [isAgencySweepValueValid, submissionVM, callIssueSubmission]
    );

    useEffect(() => {
        registerComponentValidation(isAgencySweepValueValid);
    }, [registerComponentValidation, isAgencySweepValueValid]);

    const handlePayUsingRadioChange = useCallback((value) => {
        if (value === payUsing.autopayNew) {
            addPaymentMethod();
        } else {
            if (value !== payUsing.check) {
                // trigger AutoPay generate
                const vm = _.find(paymentMethodsVM, (bank) => {
                    return _.get(bank, 'value.bankAccountData.bankAccountNumber')
                        + _.get(bank, 'value.bankAccountData.bankABANumber')
                        === value;
                });
                if (!_.isEmpty(vm)) {
                    generateAutoPayEnrollment(vm);
                }
            }
            _.set(submissionVM, 'bindData.payUsing_Ext', value);
            updateWizardData(submissionVM);
        }
    }, [addPaymentMethod, generateAutoPayEnrollment,
        payUsing.autopayNew, payUsing.check,
        paymentMethodsVM, submissionVM, updateWizardData]);


    const handlePaymentChange = useCallback((value, path) => {
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);


    const getPaymentDetailsData = useCallback(() => {
        const data = [
            {
                code: payUsing.check,
                description: payUsing.check
            }
        ];
        if (paymentMethodsVM.length > 0) {
            paymentMethodsVM.forEach((vm) => {
                // last 4 digits of bankAccountNumber
                let digits = _.get(vm, 'value.bankAccountData.bankAccountNumber');
                digits = digits || digits.substr(-4);
                let type = _.get(vm, 'value.bankAccountData.bankAccountType');
                type = type === 'checking' ? 'Checking' : 'Saving';
                data.push({
                    // code: vm.value.bankAccountData.bankAccountNumber,
                    code: _.get(vm, 'value.bankAccountData.bankAccountNumber') + _.get(vm, 'value.bankAccountData.bankABANumber'),
                    description: `Autopay (${type} - ${digits})`,
                });
            });
        } else {
            data.push({
                code: payUsing.autopayNew,
                description: payUsing.autopayNew,
            });
        }
        return data;
    }, [payUsing.autopayNew, payUsing.check, paymentMethodsVM]);


    const getPaymentMethodDropdownData = useCallback(() => {
        const data = [];
        if (paymentMethodsVM.length > 0) {
            paymentMethodsVM.forEach((vm) => {
                // last 4 digits of bankAccountNumber
                const digits = vm.value.bankAccountData.bankAccountNumber.substr(-4);
                data.push({
                    code: vm.value.bankAccountData.bankAccountNumber,
                    name: `Bank Account - ${digits}`,
                });
            });
        }
        data.push({
            code: 'addNewCreditCard',
            name: translator(messages2.addNewCreditCard)
        });
        data.push({
            code: 'addNewBankAccount',
            name: translator(messages2.addNewBankAccount)
        });
        return data;
    }, [paymentMethodsVM, translator]);

    const handleDownPaymentMethodChange = useCallback(async (value) => {
        const amt = parseFloat(_.get(submissionVM, 'value.bindData.electronicValue_Ext.amount')).toFixed(2);
        const amtDue = `${amt}`;
        const basicParam = {
            FromSystem: 'bcc-ecc',
            ActionCode: 'EXPAYCC',
            brand: 'WNI',
            AccountNumber: _.get(submissionVM, 'value.baseData.accountNumber'),
            RemoteUserId: _.get(submissionVM, 'value.baseData.accountHolder.displayName'),
            firstName: _.get(submissionVM, 'value.baseData.accountHolder.firstName'),
            lastName: _.get(submissionVM, 'value.baseData.accountHolder.lastName'),
            address1: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.addressLine1'),
            city: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.city'),
            state: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.state'),
            postal: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.postalCode'),
            amtDue: amtDue,
            reference: `4${_.get(submissionVM, 'value.quoteID')}000000`
        };
        // select Add New Credit Card
        if (value === 'addNewCreditCard') {
            updatePaymentDetailsForDownPayment(value);
            const requestParam = { ...basicParam, ActionCode: 'EXPAYCC' };
            // edge api
            const result = await WniGatewayBillingSubmissionService.getUrlgeneration(
                [JSON.stringify(requestParam)], authHeader
            );
            if (!result) {
                return false;
            }
            const paymentDetailsDTO = {
                paymentMethod: 'creditcard'
            };
            _.set(submissionVM.value, 'bindData.paymentDetailsForDownPayment_Ext', paymentDetailsDTO);
            updateWizardData(submissionVM);
            // updateBccUrl(result.url);
            window.open(result.url);
            updateShowLoader(true);
            handleNext().then((res) => {
                if (res) {
                    goNext();
                }
                updateShowLoader(false);
            });
            return true;
        }

        // select Add New Bank Acount
        if (value === 'addNewBankAccount') {
            updatePaymentDetailsForDownPayment(value);
            const payDate = WniDateUtil.formatDateWithPattern(new Date());
            const requestParam = {
                ...basicParam,
                ActionCode: 'EXPAYACH',
                payDate: payDate,
                bankingAccountNumber: '12345615980',
                bankingAccountType: 'Chkng',
                routingNum: '091000019',
                nameOnAccount: 'Test'
            };
            // edge api
            const result = await WniGatewayBillingSubmissionService.getUrlgeneration(
                [JSON.stringify(requestParam)], authHeader
            );
            if (!result) {
                return false;
            }

            const paymentDetailsDTO = {
                paymentMethod: 'autopay_Ext'
            };
            _.set(submissionVM.value, 'bindData.paymentDetailsForDownPayment_Ext', paymentDetailsDTO);
            updateWizardData(submissionVM);
            // updateBccUrl(result.url);
            window.open(result.url);
            updateShowLoader(true);
            handleNext().then((res) => {
                if (res) {
                    goNext();
                }
                updateShowLoader(false);
            });
            return true;
        }

        // select added bank
        if (value && paymentMethodsVM.length > 0) {
            const selectedVM = paymentMethodsVM.find(
                (vm) => value === vm.value.bankAccountData.bankAccountNumber
            );
            const bankValue = _.get(selectedVM, 'value');
            const bank = _.get(bankValue, 'bankAccountData.bankAccountNumber') + _.get(bankValue, 'bankAccountData.bankABANumber');
            updatePaymentDetailsForDownPayment(bank);

            const payDate = WniDateUtil.formatDateWithPattern(new Date());
            const requestParam = {
                ...basicParam,
                ActionCode: 'EXPAYACH',
                payDate: payDate,
                bankingAccountNumber: _.get(bankValue, 'bankAccountData.bankAccountNumber'),
                bankingAccountType: _.get(bankValue, 'bankAccountData.bankAccountType') === 'checking' ? 'Chkng' : 'Svgs',
                routingNum: _.get(bankValue, 'bankAccountData.bankABANumber'),
                nameOnAccount: _.get(bankValue, 'bankAccountData.bankName')
            };

            // edge api
            const result = await WniGatewayBillingSubmissionService.getUrlgeneration(
                [JSON.stringify(requestParam)], authHeader
            );
            if (!result) {
                return false;
            }

            _.set(submissionVM, 'value.bindData.paymentDetailsForDownPayment_Ext', bankValue);
            updateWizardData(submissionVM);
            // updateBccUrl(result.url);
            window.open(result.url);
            updateShowLoader(true);
            handleNext().then((res) => {
                if (res) {
                    goNext();
                }
                updateShowLoader(false);
            });
            return true;
        }

        // select default
        updatePaymentDetailsForDownPayment(value);
        return false;
    }, [submissionVM, paymentMethodsVM, authHeader,
        updateWizardData, handleNext, goNext]);

    const downPaymentChange = useCallback((value, path) => {
        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );
        const downPaymentExt = _.get(chosenQuoteData, 'downPayment_Ext');
        if (value === 'AgencySweep') {
            _.set(submissionVM, 'bindData.agencySweepValue_Ext', downPaymentExt);
        } else {
            _.set(submissionVM, 'bindData.electronicValue_Ext', downPaymentExt);
        }
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const isPlanOverrideVisible = useCallback(() => {
        const selectedPaymentPlanId = _.get(submissionVM.value, 'bindData.selectedPaymentPlan');
        const plans = _.get(submissionVM.value, 'bindData.paymentPlans');
        if (!selectedPaymentPlanId || !plans) {
            return false;
        }
        const selectedPlan = plans.find((plan) => plan.billingId === selectedPaymentPlanId);
        return selectedPlan && selectedPlan.name !== fullPayStr;
    }, [fullPayStr, submissionVM.value]);

    const handleCancel = useCallback(
        () => {
            return isComponentValid ? submissionVM : wizardSnapshot;
        }, [isComponentValid, submissionVM, wizardSnapshot]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            return false;
        },
        [updateShowErrors]
    );

    const onNewPersonClick = useCallback(() => {
        const modalProps = {
            title: translator(messages2.newPersonTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages2.dialogOk,
            cancelBtnLabel: messages2.dialogCancel,
            authHeader
        };
        return modalApi.showModal(<NewPersonComponent {...modalProps} />)
            .then((res) => {
                updatePrimaryPayer(_.get(res, 'accountHolder.displayName'));
                _.set(submissionVM, 'bindData.primaryPayer_Ext', _.get(res, 'accountHolder'));
                _.noop();
            }).catch(() => {
                _.noop();
            });
    }, [authHeader, submissionVM, translator]);

    const primaryPayerChange = useCallback((value) => {
        if (value === 'New Person') {
            onNewPersonClick();
        } else {
            const contact = JSON.parse(value);
            updatePrimaryPayer(_.get(contact, 'displayName'));
            _.set(submissionVM, 'bindData.primaryPayer_Ext', contact);
        }
    }, [onNewPersonClick, submissionVM]);

    const overrideProps = {
        '@field': {
            disabled: true,
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        paymentInputContainer: {
            totalModel: submissionVM,
            onPaymentInfoChange: handlePaymentChange,
        },

        planOverrideContainer: {
            visible: isPlanOverrideVisible()
        },
        dueDayOfTheMonth_Ext: {
            availableValues: generateDaysOfMonth(),
            onValueChange: handleDueDateChange
        },
        payUsingTable: {
            data: getPaymentDetailsData()
        },
        payUsingRadioColumn: {
            onValueChange: handlePayUsingRadioChange,
            value: _.get(submissionVM, 'bindData.payUsing_Ext.value'),
            disabled: true
        },
        paymentOptions: {
            onValueChange: handlePaymentOptionChange
        },
        bankAccountContainer: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === paymentMethodData.bank
        },
        agencySweepValue_Ext: {
            // disabled: _.get(submissionVM, 'bindData.downPayment_Ext.value') !== 'AgencySweep',
            disabled: true,
            showErrors: !isAgencySweepValueValid(),
            validationMessages: isAgencySweepValueValid() ? [] : ['Number value is less than min value'],
            className: _.get(submissionVM, 'bindData.agencySweep_Ext.value') === 'Yes' ? 'form-control-no-label' : styles.displayNone
        },
        electronicValue_Ext: {
            // disabled: _.get(submissionVM, 'bindData.downPayment_Ext.value') !== 'Electronic'
            disabled: true,
        },
        choosePaymentMethodDropdown: {
            // disabled: _.get(submissionVM, 'bindData.downPayment_Ext.value') !== 'Electronic',
            disabled: true,
            availableValues: getPaymentMethodDropdownData(),
            onValueChange: handleDownPaymentMethodChange,
            value: paymentDetailsForDownPayment,
            required: _.get(submissionVM, 'bindData.downPayment_Ext.value') === 'Electronic'
        },
        paymentPlansList: {
            submissionVM: submissionVM,
            onValueChange: paymentPlanChange,
            authHeader,
            quoteID: _.get(submissionVM, 'value.quoteID'),
            sessionUUID: _.get(submissionVM, 'value.sessionUUID'),
            updateWizardPageData,
            wizardPageData,
            WniLoadSaveService,
            disabled: true
        },
        downPaymentRadio: {
            onValueChange: downPaymentChange,
            availableValues: getDownPaymentRaido()
        },
        payUsingDescription: {
            renderCell: onCell
        },
        primaryPayer_Ext: {
            value: primaryPayer
        },
        autoPayNotification: {
            visible: showNotification
        },
        primaryPayerInputContainer: {
            className: styles.primaryPayerInputContainer
        },
        primaryNamedInsuredEditIcon: {
            className: styles.primaryNamedInsuredEditIcon
        },
        primaryPayerContainer: {
            value: primaryPayer,
            placeholder: primaryPayer,
            onValueChange: primaryPayerChange,
            availableValues: primaryPayerAvailableValues
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssuesWarning,
            visible: validationIssuesWarning.length > 0,
            id: 'validationIssuesComponentId',
            scrollToIssues: true
        },
        addPayUsingButton: {
            disabled: true
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {
            addPaymentMethod: addPaymentMethod,
            onValidate,
            onNewPersonClick
        },
        resolveComponentMap: {
            paymentinputcomponent: PaymentInputComponent,
            validationissuescomponent: ValidationIssuesComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <WizardPage
            nextLabel={messages2.paymentPayAndIssue}
            // onNext={isComponentValid ? handleNext : handleValidation}
            // disableNext={showLoader}
            showNext={false}
            onCancel={handleCancel}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        onModelChange={updateWizardData}
                        overrideProps={overrideProps}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        classNameMap={resolvers.resolveClassNameMap}
                        resolveValue={readValue}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
