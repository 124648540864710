import React, {
    useContext
} from 'react'
import _ from 'lodash'
import {
    TooltipIcon
} from '@jutro/components';
import moment from 'moment';
import { IntlContext } from '@jutro/locale';
import { TooltipContentUtil } from 'wni-portals-tooltip';

const ValueType = {
    Option: 'Option',
    Direct: 'Direct',
    MONEY_EXT: 'MONEY_EXT',
    Shorttext: 'shorttext',
    DateTime: 'datetime',
    Bit: 'bit',
    AdditionalInsured: 'AdditionalInsured',
    AdditionalInterest: 'AdditionalInterest',
    AutoNumber: 'AutoNumber',
    BigDecimal: 'BigDecimal',
    Boolean: 'Boolean',
    Date: 'Date',
    ForeignKey: 'ForeignKey',
    ForeignKeyWithOptionLabels: 'ForeignKeyWithOptionLabels',
    PolicyContact: 'PolicyContact',
    TypeKey: 'TypeKey',
    Integer: 'Integer',
    IntegerRange: 'IntegerRange',
    String: 'String',
    TextArea: 'TextArea',
}

const ValuePath = {
    TypeCodeValue: 'typeCodeValue',
    BigDecimal: 'bigDecimal',
    StringValue: 'stringValue',
    DateValue: 'dateValue',
    BooleanValue: 'booleanValue',
    IntegerValue: 'integerValue',
    LocalDate: 'localDate_Ext',
}

const valueTypeNameMap = {
    [ValueType.Option]: ValuePath.TypeCodeValue,
    [ValueType.Direct]: ValuePath.BigDecimal,
    [ValueType.MONEY_EXT]: ValuePath.BigDecimal,
    [ValueType.Shorttext]: ValuePath.StringValue,
    [ValueType.DateTime]: ValuePath.DateValue,
    [ValueType.Bit]: ValuePath.BooleanValue
}

const schedulePropertyInfoTypeMap = {
    [ValueType.AdditionalInsured]: ValuePath.TypeCodeValue,
    [ValueType.AdditionalInterest]: ValuePath.TypeCodeValue,
    [ValueType.ForeignKey]: ValuePath.TypeCodeValue,
    [ValueType.ForeignKeyWithOptionLabels]: ValuePath.TypeCodeValue,
    [ValueType.Option]: ValuePath.TypeCodeValue,
    [ValueType.PolicyContact]: ValuePath.TypeCodeValue,
    [ValueType.TypeKey]: ValuePath.TypeCodeValue,
    [ValueType.AutoNumber]: ValuePath.IntegerValue,
    [ValueType.Integer]: ValuePath.IntegerValue,
    [ValueType.IntegerRange]: ValuePath.IntegerValue,
    [ValueType.BigDecimal]: ValuePath.BigDecimal,
    [ValueType.Boolean]: ValuePath.BooleanValue,
    [ValueType.Date]: ValuePath.LocalDate,
    [ValueType.String]: ValuePath.StringValue,
    [ValueType.TextArea]: ValuePath.StringValue,
    
};

function getFormattedValue(value, propertyInfo) {
    const {
        valueTypeName_Ext: valueTypeName,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo;
    const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : schedulePropertyInfoTypeMap[schedulePropertyInfoType];

    if (valuePath === 'typeCodeValue') {
        const availableValue = _.get(propertyInfo, 'availableTypeListValues', []).find(
            (av) => av.code === value || av.displayKey === value
        );
        return _.get(availableValue, 'displayKey');
    }
    return value;
}

const PoliciesSummarySclauseScheduleTableCell = (props) => {
    const {
        propertyInfo,
        scheduleItem
    } = props;
    const {
        id,
        valueTypeName_Ext: valueTypeName,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo;

    const {
        itemData: {
            [id]: itemDataForProperty
        }
    } = scheduleItem

    const intl = useContext(IntlContext);

    const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : schedulePropertyInfoTypeMap[schedulePropertyInfoType];
    const value = _.get(itemDataForProperty, valuePath);
    if (_.isNil(value)) {
        return '-'
    }

    if (valuePath === 'typeCodeValue') {
        const options = _.get(itemDataForProperty, 'options_Ext', []);
        const selectedOption = options.find((option) => _.get(option, 'code') === value);
        const optionCode = _.get(selectedOption, 'code');
        const optionHelpText = TooltipContentUtil[optionCode];
        return (
            <div className="labelWithTooltip">
                <span>{_.get(selectedOption, 'name')}</span>
                { optionHelpText && <TooltipIcon
                    id={`option${optionCode}`}
                    text={optionHelpText}
                />}
                
            </div>
        )
    }
    if (valueTypeName === 'MONEY_EXT' && valuePath === 'bigDecimal') {
        return intl.formatNumber(
            value,
            {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol',
                maximumFractionDigits: 0
            }
        );
    }

    if (valuePath === 'integerValue' && _.has(itemDataForProperty, 'currency')) {
        const { currency } = itemDataForProperty;
        return intl.formatNumber(
            value,
            {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol'
            }
        );
    }
    if (valuePath === 'dateValue') {
        return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
    }
    if (valuePath === 'localDate_Ext') {
        return intl.formatDate(moment(value), { year: 'numeric', month: 'short', day: 'numeric' });
    }
    return getFormattedValue(value, itemDataForProperty);
}

export default PoliciesSummarySclauseScheduleTableCell