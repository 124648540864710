import React,{ useState } from 'react';

import { ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import _ from 'lodash';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CAVehicleService } from 'wni-capability-quoteandbind-ca';
import messages from '../../CAVehiclesPage.messages';
import metadata from './CopyVehicleCoverage.metadata.json5';

import { Button } from '@jutro/legacy/components';

function CopyVehicleCoverage(props) {
    const modalApi = useWniModal();
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        title,
        availableVehicles,
        extendProps: { jobID, sessionUUID, authHeader } = {},
        useDependenciesData,
    } = props;
    const [copyFormVehicleRowIdPath, updateCopyFormVehicleRowIdPath] = useState();
    const [copyToVehicles, updateCopyToVehicles] = useState();
    const [selection, updateSelection] = useState([]);
    const [showCopyCoverage, updateShowCopyCoverage] = useState(false);

    const {
        loadingMask: { setLoadingMask }
    } = useDependenciesData;

    const handleCancel = () => {
        if(showCopyCoverage) {
            updateShowCopyCoverage(false);
            return false;
        }
        onReject()
    };

    const handleNext = async() => {
        setLoadingMask(true);
        const res = await CAVehicleService.getVehiclesCanCopyCovereages(jobID, sessionUUID, copyFormVehicleRowIdPath, authHeader);
        setLoadingMask(false);
        updateCopyToVehicles(res);
        updateShowCopyCoverage(true);
    };

    const handleApply = async() => {
        setLoadingMask(true);
        const res = await CAVehicleService.copyVehicleCoverages(jobID, sessionUUID, copyFormVehicleRowIdPath, selection, authHeader);
        setLoadingMask(false);
        modalApi.showAlert({
            title: res ? messages.copyCoverageSuccess : messages.copyCoverageFailed,
            status: res ? 'success' : 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(() => {
            onResolve(res || []);
        }, _.noop);
    };
    
    const modalTitle = showCopyCoverage ? messages.applyCoverages : messages.copyVehicleCoverage;
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        selectVehicle: {
            visible: !showCopyCoverage,
            onValueChange: (val) => updateCopyFormVehicleRowIdPath(val),
            availableValues: availableVehicles,
            value: copyFormVehicleRowIdPath
        },
        copyCoverage: {
            visible: showCopyCoverage
        },
        copyCoverageTable: {
            data: copyToVehicles,
            onSelectionChange: (rows) => updateSelection(rows),
            selectedRows: selection
        }
       
    };
    const resolvers = {
        resolveCallbackMap: {
           
        },
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={modalTitle} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'between',
                    },
                }}
            >
                <Button onClick={handleCancel} type="outlined">Cancel</Button>
                {
                    !showCopyCoverage ? <Button onClick={handleNext} disabled={!copyFormVehicleRowIdPath}>Next</Button> : <Button onClick={handleApply}  disabled={_.isEmpty(selection)}>Apply</Button>
                }
                
            </ModalFooter>
        </ModalNext>
    );
}

CopyVehicleCoverage.propTypes = {};
CopyVehicleCoverage.defaultProps = {
    size: 'md',
};
export default CopyVehicleCoverage;
