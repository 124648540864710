const paData = {
  "sessionUUID": "6a7d65cb-3e19-40d2-8db3-ceb75f227488",
  "baseData": {
      "periodStatus": "Draft",
      "accountContacts_Ext": [
          {
              "displayName": "Yang Xiao",
              "dateOfBirth": {
                  "year": 1986,
                  "month": 0,
                  "day": 1
              },
              "firstName": "Yang",
              "gender": "M",
              "lastName": "Xiao",
              "licenseState": "IA",
              "maritalStatus": "S",
              "licenseNumber": "1231233453453",
              "primaryAddress": {
                  "state": "IA",
                  "country": "US",
                  "displayName": "916 Ashworth Rd, West Des Moines, IA 50265-3675",
                  "publicID": "pc:629",
                  "city": "West Des Moines",
                  "county": "Polk",
                  "spatialPoint": {
                      "longitude": -93.7411,
                      "latitude": 41.5723
                  },
                  "addressLine1": "916 Ashworth Rd",
                  "postalCode": "50265-3675",
                  "addressType": "home"
              },
              "publicID": "pc:620",
              "subtype": "Person",
              "contactName": "Yang Xiao",
              "primaryPhoneType": "mobile",
              "cellNumber": "2015550123",
              "phoneRequired_Ext": true,
              "additionalAddresses": []
          },
          {
              "displayName": "F02 L02",
              "dateOfBirth": {
                  "year": 1999,
                  "month": 0,
                  "day": 1
              },
              "firstName": "F02",
              "lastName": "L02",
              "primaryAddress": {
                  "state": "IA",
                  "country": "US",
                  "displayName": "702 E 10th St S,, Newton, IA 50208",
                  "publicID": "pc:631",
                  "city": "Newton",
                  "addressLine1": "702 E 10th St S,",
                  "postalCode": "50208",
                  "addressType": "home"
              },
              "publicID": "pc:621",
              "subtype": "Person",
              "phoneRequired_Ext": false,
              "additionalAddresses": []
          }
      ],
      "effectiveDate_Ext": {
          "year": 2023,
          "month": 1,
          "day": 6
      },
      "primaryNamedInsured_Ext": {
          "displayName": "Yang Xiao",
          "dateOfBirth": {
              "year": 1986,
              "month": 0,
              "day": 1
          },
          "firstName": "Yang",
          "gender": "M",
          "lastName": "Xiao",
          "licenseState": "IA",
          "maritalStatus": "S",
          "licenseNumber": "1231233453453",
          "primaryAddress": {
              "state": "IA",
              "country": "US",
              "displayName": "916 Ashworth Rd, West Des Moines, IA 50265-3675",
              "publicID": "pc:629",
              "city": "West Des Moines",
              "county": "Polk",
              "spatialPoint": {
                  "longitude": -93.7411,
                  "latitude": 41.5723
              },
              "addressLine1": "916 Ashworth Rd",
              "postalCode": "50265-3675",
              "addressType": "home"
          },
          "publicID": "pc:620",
          "relationshipToInsured_Ext": "Insured_Ext",
          "hasWNIEmployeeDiscount_Ext": false,
          "subtype": "Person",
          "contactName": "Yang Xiao",
          "primaryPhoneType": "mobile",
          "cellNumber": "2015550123",
          "phoneRequired_Ext": true,
          "additionalAddresses": []
      },
      "submittingQuote_Ext": false,
      "quoteFlow_Ext": "firstquote",
      "termType": "HalfYear",
      "accountHolder": {
          "displayName": "Yang Xiao",
          "dateOfBirth": {
              "year": 1986,
              "month": 0,
              "day": 1
          },
          "firstName": "Yang",
          "gender": "M",
          "lastName": "Xiao",
          "licenseState": "IA",
          "maritalStatus": "S",
          "licenseNumber": "1231233453453",
          "primaryAddress": {
              "state": "IA",
              "country": "US",
              "displayName": "916 Ashworth Rd, West Des Moines, IA 50265-3675",
              "publicID": "pc:629",
              "city": "West Des Moines",
              "county": "Polk",
              "spatialPoint": {
                  "longitude": -93.7411,
                  "latitude": 41.5723
              },
              "addressLine1": "916 Ashworth Rd",
              "postalCode": "50265-3675",
              "addressType": "home"
          },
          "publicID": "pc:620",
          "subtype": "Person",
          "contactName": "Yang Xiao",
          "primaryPhoneType": "mobile",
          "cellNumber": "2015550123",
          "phoneRequired_Ext": true,
          "additionalAddresses": []
      },
      "source_Ext": "Portal",
      "isAPISource_Ext": false,
      "policyInfoSource_Ext": "Portal",
      "showFullPayDiscount_Ext": true,
      "displayStatus_Ext": "Draft",
      "producerCodePublicID_Ext": "pc:1",
      "baseState_Ext": "IA",
      "productCode": "PersonalAuto",
      "productName": "Personal Auto",
      "jobType": "Submission",
      "accountNumber": "A000001001",
      "servicingAgent_Ext": "pc:103",
      "producerOrLicensedAgent_Ext": "pc:103",
      "policyAddress": {
          "state": "IA",
          "country": "US",
          "displayName": "916 Ashworth Rd, West Des Moines, IA 50265-3675",
          "publicID": "pc:629",
          "city": "West Des Moines",
          "county": "Polk",
          "spatialPoint": {
              "longitude": -93.7411,
              "latitude": 41.5723
          },
          "addressLine1": "916 Ashworth Rd",
          "postalCode": "50265-3675",
          "addressType": "home"
      },
      "periodEndDate": {
          "year": 2023,
          "month": 7,
          "day": 6
      },
      "policyNamedInsuredCandidates_Ext": [
          {
              "displayName": "Yang Xiao",
              "dateOfBirth": {
                  "year": 1986,
                  "month": 0,
                  "day": 1
              },
              "firstName": "Yang",
              "gender": "M",
              "lastName": "Xiao",
              "licenseState": "IA",
              "maritalStatus": "S",
              "licenseNumber": "1231233453453",
              "primaryAddress": {
                  "state": "IA",
                  "country": "US",
                  "displayName": "916 Ashworth Rd, West Des Moines, IA 50265-3675",
                  "publicID": "pc:629",
                  "city": "West Des Moines",
                  "county": "Polk",
                  "spatialPoint": {
                      "longitude": -93.7411,
                      "latitude": 41.5723
                  },
                  "addressLine1": "916 Ashworth Rd",
                  "postalCode": "50265-3675",
                  "addressType": "home"
              },
              "publicID": "pc:620",
              "subtype": "Person",
              "contactName": "Yang Xiao",
              "primaryPhoneType": "mobile",
              "cellNumber": "2015550123",
              "phoneRequired_Ext": true,
              "additionalAddresses": []
          }
      ],
      "producerOrLicensedAgentUserDisplayName_Ext": "Michael Xiao",
      "latestPeriod_Ext": "pc:1",
      "producerCode_Ext": "100",
      "producerDisplay_Ext": "100 - null - null, null",
      "servicingAgentDisplayName_Ext": "Michael Xiao",
      "selectedVersion_Ext": "pc:1",
      "periodStartDate": {
          "year": 2023,
          "month": 1,
          "day": 6
      }
  },
  "lobData": {
      "personalAuto": {
          "coverables": {
              "drivers": [
                  {
                      "displayName": "Yang Xiao",
                      "dateOfBirth": {
                          "year": 1986,
                          "month": 0,
                          "day": 1
                      },
                      "firstName": "Yang",
                      "gender": "M",
                      "lastName": "Xiao",
                      "licenseState": "IA",
                      "licenseNumber": "1231233453453",
                      "fixedId": 3,
                      "publicID": "pc:3",
                      "relationshipToInsured_Ext": "Insured_Ext",
                      "primaryNamedInsured_Ext": "Yang Xiao",
                      "exploreInsIndicator_Ext": "R",
                      "dateFirstLicensed_Ext": {
                          "year": 2004,
                          "month": 0,
                          "day": 1
                      },
                      "ageFirstLicensed_Ext": 18,
                      "exploreMonitoringProducts_Ext": "All",
                      "licenseNumberChanged_Ext": true,
                      "isStudentAwayWithoutCarYN_Ext": false,
                      "inexperiencedDriver_Ext": false,
                      "isGoodStudentYN_Ext": false,
                      "licensedDriver_Ext": true,
                      "defensiveDriverDiscount_Ext": false,
                      "driverCovered_Ext": true,
                      "workLossDiscount_Ext": false,
                      "person": {
                          "displayName": "Yang Xiao",
                          "dateOfBirth": {
                              "year": 1986,
                              "month": 0,
                              "day": 1
                          },
                          "firstName": "Yang",
                          "lastName": "Xiao",
                          "maritalStatus": "S",
                          "publicID": "pc:620",
                          "primaryPhoneType": "mobile",
                          "cellNumber": "2015550123"
                      },
                      "driversLicenseStatus_Ext": "valid",
                      "policyCountry_Ext": "US",
                      "contactDenormPublicId_Ext": "pc:620",
                      "maritalStatus_Ext": "S",
                      "driverQuestionSets_Ext": [
                          {
                              "code": "PADriverQuestionSet",
                              "answers": {
                                  "DriverWNEmployeeDiscountYN_Ext": "false",
                                  "DriverLicSuspLastFiveYearsYN_Ext": "false",
                                  "DriverAnyCurFinResFilingYNExplain_Ext": null,
                                  "DriverAnyCurFinResFilingYN_Ext": "false",
                                  "DriverLicSuspLastFiveYearsYNExplain_Ext": null,
                                  "DriverIsCompanyVehFurnishedYN_Ext": "false"
                              }
                          }
                      ],
                      "basedOnFlag_Ext": false,
                      "additionalInsured_Ext": false,
                      "gender_Ext": "M",
                      "isPolicyHolder": true,
                      "age_Ext": 37,
                      "isPNI_Ext": true,
                      "ratedAge_Ext": 37
                  }
              ],
              "vehicles": [
                  {
                      "year": 2012,
                      "displayName": "2012 BMW 328I",
                      "licenseState": "IA",
                      "fixedId": 1,
                      "model": "328I",
                      "publicID": "pc:1",
                      "costNew": {
                          "currency": "usd",
                          "amount": 34900
                      },
                      "vin": "WBA3A5C54CF227432",
                      "owners_Ext": [
                          {
                              "displayName": "Yang Xiao",
                              "fixedId": 3,
                              "publicID": "pc:3"
                          }
                      ],
                      "annualMileage": 10000,
                      "safetyFeatures_Ext": [],
                      "isCostNewVINPrefilled_Ext": true,
                      "otherOwnersAssignedFlag_Ext": false,
                      "vehExclusiveUseToFarmOwned_Ext": false,
                      "vehUsedInBusOwned_Ext": false,
                      "vehDrivenToWorkSchool_Ext": false,
                      "vehicleNumber": 1,
                      "leaseOrRent": false,
                      "make": "BMW",
                      "additionalInterests": [
                          {
                              "type": "ADDITIONALINSURED_Ext",
                              "fixedId": 3,
                              "relationshipToInsured_Ext": "Child_Ext",
                              "policyAdditionalInterest": {
                                  "displayName": "F02 L02",
                                  "dateOfBirth": {
                                      "year": 1999,
                                      "month": 0,
                                      "day": 1
                                  },
                                  "firstName": "F02",
                                  "lastName": "L02",
                                  "primaryAddress": {
                                      "state": "IA",
                                      "country": "US",
                                      "displayName": "702 E 10th St S,, Newton, IA 50208",
                                      "publicID": "pc:631",
                                      "city": "Newton",
                                      "addressLine1": "702 E 10th St S,",
                                      "postalCode": "50208",
                                      "addressType": "home"
                                  },
                                  "publicID": "pc:621",
                                  "subtype": "Person",
                                  "phoneRequired_Ext": false,
                                  "additionalAddresses": []
                              },
                              "certificateRequired": false
                          }
                      ],
                      "vehicleType_Ext": "privatepassengerauto_Ext",
                      "garagedAt_Ext": {
                          "state": "IA",
                          "country": "US",
                          "displayName": "1: 916 Ashworth Rd, West Des Moines, IA",
                          "publicID": "pc:630",
                          "city": "West Des Moines",
                          "county": "Polk",
                          "spatialPoint": {
                              "longitude": -93.7411,
                              "latitude": 41.5723
                          },
                          "addressLine1": "916 Ashworth Rd",
                          "postalCode": "50265-3675",
                          "addressType": "home"
                      },
                      "policyCountry_Ext": "US",
                      "symbolAndModifiers_Ext": {
                          "costNew": {
                              "currency": "usd",
                              "amount": 34900
                          },
                          "phyDmg_Ext": "EF",
                          "med_Ext": "JK",
                          "rapaColl_Ext": "0P",
                          "rapaComp_Ext": "0X",
                          "sl_Ext": "EH",
                          "per_Ext": "GE",
                          "bodInj_Ext": "EJ",
                          "oriBodyStyle_Ext": "SEDAN 4D",
                          "pavehicleModifiers_Ext": {
                              "PAPassiveRestraint": "R",
                              "PAAntiTheft": "passive_Ext",
                              "PAPerformance": "intermediate_Ext",
                              "PAAntiLockBrakes": "Standard"
                          }
                      },
                      "policyLine_Ext": "PersonalAutoLine",
                      "baseOnNull_Ext": true,
                      "isDriverAssignmentAvailable_Ext": true,
                      "vehicleQuestionSets_Ext": [
                          {
                              "code": "PAVehicleQuestionSet",
                              "answers": {
                                  "hasExistDamagesInclGlass_Ext": "false",
                                  "vehDamagesEplanation_Ext": null,
                                  "VehModHasSpecialEquipment_Ext": "false"
                              }
                          }
                      ],
                      "isStatedValueRequired_Ext": false,
                      "customEquipments_Ext": [],
                      "isCensusBlockInfoMissing_Ext": false,
                      "leaseOrRent_Ext": false,
                      "bodyType_Ext": "SEDAN4D_Ext"
                  }
              ],
              "allVehiclesHavePrimaryDriver_Ext": false,
              "addInterestTypeCategory": "PAVhcleAddlInterest",
              "garagedAtOptions_Ext": [
                  {
                      "state": "IA",
                      "country": "US",
                      "displayName": "1: 916 Ashworth Rd, West Des Moines, IA",
                      "publicID": "pc:630",
                      "city": "West Des Moines",
                      "county": "Polk",
                      "spatialPoint": {
                          "longitude": -93.7411,
                          "latitude": 41.5723
                      },
                      "addressLine1": "916 Ashworth Rd",
                      "postalCode": "50265-3675",
                      "addressType": "home"
                  }
              ],
              "availableOwners_Ext": [
                  {
                      "displayName": "Yang Xiao",
                      "fixedId": 3,
                      "publicID": "pc:3"
                  }
              ]
          },
          "offerings": [
              {
                  "branchName": "Version 1",
                  "coverages": {
                      "vehicleCoverages": [
                          {
                              "fixedId": 1,
                              "publicID": "pc:1",
                              "coverages": [
                                  {
                                      "name": "Other Than Collision",
                                      "description": "Other Than Collision",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "PAComprehensiveCov",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAComprehensiveCov",
                                      "coverageCategoryCode": "PAPPhysDamGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Physical Damage Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 3500
                                  },
                                  {
                                      "name": "Exclude Liability",
                                      "description": "Exclude Liability",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "z98isu2q3e61sf6i9agqpmfndc9",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PALiabilityCovExc_Ext",
                                      "coverageCategoryCode": "PAPPhysDamGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Physical Damage Group",
                                      "hasTerms": false,
                                      "priority_Ext": 600
                                  },
                                  {
                                      "name": "Stated Amount Other Than Collision",
                                      "description": "Stated Amount Other Than Collision",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "za1g22iffajsaedmjgufcbu8eja",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAStatedAmtCov_Ext",
                                      "coverageCategoryCode": "PAPPhysDamGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Physical Damage Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 3700
                                  },
                                  {
                                      "name": "Key Replacement And Related Services Coverage",
                                      "description": "Key Replacement And Related Services Coverage",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "zrhiqkp0mpdak9pro8vbp0ispn8",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAKeyRepCov_Ext",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 4700
                                  },
                                  {
                                      "name": "Limited Transportation Network Driver Coverage (No Passenger)",
                                      "description": "Limited Transportation Network Driver Coverage (No Passenger)",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "z08ji73hvg6mq5o16vekmtc7sc8",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PALtdTNDCov_Ext",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 9300
                                  },
                                  {
                                      "name": "Transportation Network Driver Coverage (No Passenger)",
                                      "description": "Transportation Network Driver Coverage (No Passenger)",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "zg1i4vrgtteub8uuun4pqmmccdb",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PATNDCov_Ext",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 9000
                                  }
                              ],
                              "vehicleName": "2012 BMW 328I ",
                              "isDriverAssignmentAvailable_Ext": true
                          }
                      ],
                      "driverCoverages_Ext": [
                          {
                              "firstName": "Yang",
                              "lastName": "Xiao",
                              "publicID": "pc:3",
                              "relationshipToInsured_Ext": "Insured_Ext",
                              "coverages": [
                                  {
                                      "name": "Extended Non-Owned Individual Coverage",
                                      "description": "Extended Non-Owned Individual Coverage",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "zgthqa0bgm9iednm5ptt86esuo9",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAENORegVehUseIndCov",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "isPAENORegVehUseIndCovAvailable_Ext": true,
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 4410
                                  },
                                  {
                                      "name": "Extended Non-Owned Individual & Resident Relatives Coverage",
                                      "description": "Extended Non-Owned Individual & Resident Relatives Coverage",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "znihsme2ac04tdf20cctd4pca88",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAENORegVehUseResCov",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "isPAENORegVehUseResCovAvailable_Ext": true,
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 4420
                                  }
                              ],
                              "contactDenormPublicId_Ext": "pc:620"
                          }
                      ],
                      "lineCoverages": [
                          {
                              "name": "Liability - Bodily Injury",
                              "description": "Liability - Bodily Injury",
                              "selected": true,
                              "required": false,
                              "publicID": "zakg8rb7atum0bv4r74ngi7cltb",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Bodily Injury Liability",
                                      "type": "PackageBodily_Injury_ExtType",
                                      "options": [
                                          {
                                              "name": "$20,000/$40,000",
                                              "maxValue": 5,
                                              "code": "20000/40000",
                                              "optionValue": 1
                                          },
                                          {
                                              "name": "$25,000/$50,000",
                                              "maxValue": 5,
                                              "code": "25000/50000",
                                              "optionValue": 1
                                          },
                                          {
                                              "name": "$50,000/$100,000",
                                              "maxValue": 5,
                                              "code": "50000/100000",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$100,000/$200,000",
                                              "maxValue": 5,
                                              "code": "100000/200000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$100,000/$300,000",
                                              "maxValue": 5,
                                              "code": "100000/300000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$250,000/$500,000",
                                              "maxValue": 5,
                                              "code": "250000/500000",
                                              "optionValue": 4
                                          },
                                          {
                                              "name": "$300,000/$300,000",
                                              "maxValue": 5,
                                              "code": "300000/300000",
                                              "optionValue": 4
                                          },
                                          {
                                              "name": "$500,000/$500,000",
                                              "maxValue": 5,
                                              "code": "500000/500000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "ztghicvs4es0eefda5kqv3o02ua",
                                      "updated": false,
                                      "patternCode": "ztghicvs4es0eefda5kqv3o02ua",
                                      "code_Ext": "Bodily_Injury_Ext",
                                      "coveragePublicID": "zakg8rb7atum0bv4r74ngi7cltb",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "20000/40000",
                                      "chosenTermValue": "$20,000/$40,000"
                                  }
                              ],
                              "code_Ext": "PABILiabilityCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 520
                          },
                          {
                              "name": "Liability - Property Damage",
                              "description": "Liability - Property Damage",
                              "selected": true,
                              "required": false,
                              "publicID": "zu3jolstaooakbrqdtd1onhh1t9",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Property Damage Liability",
                                      "type": "PackageProperty_damageType",
                                      "options": [
                                          {
                                              "name": "$15,000",
                                              "maxValue": 5,
                                              "code": "15000",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$20,000",
                                              "maxValue": 5,
                                              "code": "20000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$25,000",
                                              "maxValue": 5,
                                              "code": "25000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zvqishvc7p88u8ss5rma6ghu3ia",
                                      "updated": false,
                                      "patternCode": "zvqishvc7p88u8ss5rma6ghu3ia",
                                      "code_Ext": "Property_damage",
                                      "coveragePublicID": "zu3jolstaooakbrqdtd1onhh1t9",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "15000",
                                      "chosenTermValue": "$15,000"
                                  }
                              ],
                              "code_Ext": "PAPDLiabilityCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 540
                          },
                          {
                              "name": "Liability - Single Limit",
                              "description": "Liability - Single Limit",
                              "selected": false,
                              "required": false,
                              "publicID": "zkkgiqdro6cb1fehk3501vl5fpa",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PALiabilityCSLCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 560
                          },
                          {
                              "name": "Medical Payments",
                              "description": "Medical Payments",
                              "selected": true,
                              "required": false,
                              "publicID": "PAMedPayCov",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Medical Limit",
                                      "type": "OptionPAMedLimitType",
                                      "options": [
                                          {
                                              "name": "$1,000",
                                              "maxValue": 5,
                                              "code": "1000",
                                              "optionValue": 1
                                          },
                                          {
                                              "name": "$2,000",
                                              "maxValue": 5,
                                              "code": "2000",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$5,000",
                                              "maxValue": 5,
                                              "code": "5000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$10,000",
                                              "maxValue": 5,
                                              "code": "10000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$25,000",
                                              "maxValue": 5,
                                              "code": "25000",
                                              "optionValue": 4
                                          },
                                          {
                                              "name": "$50,000",
                                              "maxValue": 5,
                                              "code": "50000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "PAMedLimit",
                                      "updated": false,
                                      "patternCode": "PAMedLimit",
                                      "code_Ext": "PAMedLimit",
                                      "coveragePublicID": "PAMedPayCov",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "1000",
                                      "chosenTermValue": "$1,000"
                                  }
                              ],
                              "code_Ext": "PAMedPayCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 1700
                          },
                          {
                              "name": "Uninsured Motorists Coverage Split",
                              "description": "Uninsured Motorists Coverage Split",
                              "selected": true,
                              "required": false,
                              "publicID": "zfeiu6si8d2r45nrsfp5p33gcr8",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Uninsured Motorists - BI Limits - Split",
                                      "type": "PackagePAIANSUMBILimit_ExtType",
                                      "options": [
                                          {
                                              "name": "$20,000/$40,000",
                                              "maxValue": 5,
                                              "code": "20000/40000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zlgiqlvr2av938u3j6p0qubdt1b",
                                      "updated": false,
                                      "patternCode": "zlgiqlvr2av938u3j6p0qubdt1b",
                                      "code_Ext": "PAIANSUMBILimit_Ext",
                                      "coveragePublicID": "zfeiu6si8d2r45nrsfp5p33gcr8",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "20000/40000",
                                      "chosenTermValue": "$20,000/$40,000"
                                  }
                              ],
                              "code_Ext": "PAUMNonStackIA_Ext",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 1930
                          },
                          {
                              "name": "Underinsured Motorists Coverage Split",
                              "description": "Underinsured Motorists Coverage Split",
                              "selected": true,
                              "required": false,
                              "publicID": "zoah81dj8ih1c5vhl3am0pabes8",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Underinsured Motorists BI Limits Split",
                                      "type": "PackagePAIANSUIMBILimit_ExtType",
                                      "options": [
                                          {
                                              "name": "$20,000/$40,000",
                                              "maxValue": 5,
                                              "code": "20000/40000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zeshuv3nogflm2os6j1kpf6paea",
                                      "updated": false,
                                      "patternCode": "zeshuv3nogflm2os6j1kpf6paea",
                                      "code_Ext": "PAIANSUIMBILimit_Ext",
                                      "coveragePublicID": "zoah81dj8ih1c5vhl3am0pabes8",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "20000/40000",
                                      "chosenTermValue": "$20,000/$40,000"
                                  }
                              ],
                              "code_Ext": "PAUIMNonStackIA_Ext",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 3750
                          },
                          {
                              "name": "Extended Non-Owned Individual Coverage",
                              "description": "Extended Non-Owned Individual Coverage",
                              "selected": false,
                              "required": false,
                              "publicID": "zgthqa0bgm9iednm5ptt86esuo9",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAENORegVehUseIndCov",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "isPAENORegVehUseIndCovAvailable_Ext": true,
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 4410
                          },
                          {
                              "name": "Extended Non-Owned Individual & Resident Relatives Coverage",
                              "description": "Extended Non-Owned Individual & Resident Relatives Coverage",
                              "selected": false,
                              "required": false,
                              "publicID": "znihsme2ac04tdf20cctd4pca88",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAENORegVehUseResCov",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "isPAENORegVehUseResCovAvailable_Ext": true,
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 4420
                          },
                          {
                              "name": "Personal Property Coverage",
                              "description": "Personal Property Coverage",
                              "selected": false,
                              "required": false,
                              "publicID": "zvsh02qeaof2b0lqnfa9qtaa949",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAPerPropCov_Ext",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 9800
                          },
                          {
                              "name": "Accident Forgiveness",
                              "description": "Accident Forgiveness",
                              "selected": false,
                              "required": false,
                              "publicID": "z85gacse4nsqiao5tbbkaj05s6a",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAAcdntForCov_Ext",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "hasTerms": false,
                              "readOnly_Ext": false,
                              "priority_Ext": 10300
                          },
                          {
                              "name": "Additional Resident of Your Household",
                              "description": "Additional Resident of Your Household",
                              "selected": false,
                              "required": false,
                              "publicID": "ztihg04q4jpkp4ijpmsc1b83fub",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAAddlHoResCov_Ext",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "hasTerms": false,
                              "priority_Ext": 11200
                          },
                          {
                              "name": "Pet Injury Coverage",
                              "description": "Pet Injury Coverage",
                              "selected": true,
                              "required": true,
                              "publicID": "z5cha9hrfj3gu096pgm3d91lfnb",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Liability Limit",
                                      "type": "OptionPAPetInj_Limit_EXTType",
                                      "options": [
                                          {
                                              "name": "$500",
                                              "maxValue": 5,
                                              "code": "500",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$1,000",
                                              "maxValue": 5,
                                              "code": "1000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$2,000",
                                              "maxValue": 5,
                                              "code": "2000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zcaiac4jeucaneoaj84k8i0fdg9",
                                      "updated": false,
                                      "patternCode": "zcaiac4jeucaneoaj84k8i0fdg9",
                                      "code_Ext": "PAPetInj_Limit_EXT",
                                      "coveragePublicID": "z5cha9hrfj3gu096pgm3d91lfnb",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "500",
                                      "chosenTermValue": "$500"
                                  }
                              ],
                              "code_Ext": "PAPetInjuryCov_EXT",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 12200
                          }
                      ]
                  },
                  "policyType_Ext": "Select",
                  "publicID_Ext": "pc:1"
              },
              {
                  "branchName": "Version 1",
                  "coverages": {
                      "vehicleCoverages": [
                          {
                              "fixedId": 1,
                              "publicID": "pc:3",
                              "coverages": [
                                  {
                                      "name": "Other Than Collision",
                                      "description": "Other Than Collision",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "PAComprehensiveCov",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAComprehensiveCov",
                                      "coverageCategoryCode": "PAPPhysDamGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Physical Damage Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 3500
                                  },
                                  {
                                      "name": "Exclude Liability",
                                      "description": "Exclude Liability",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "z98isu2q3e61sf6i9agqpmfndc9",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PALiabilityCovExc_Ext",
                                      "coverageCategoryCode": "PAPPhysDamGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Physical Damage Group",
                                      "hasTerms": false,
                                      "priority_Ext": 600
                                  },
                                  {
                                      "name": "Stated Amount Other Than Collision",
                                      "description": "Stated Amount Other Than Collision",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "za1g22iffajsaedmjgufcbu8eja",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAStatedAmtCov_Ext",
                                      "coverageCategoryCode": "PAPPhysDamGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Physical Damage Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 3700
                                  },
                                  {
                                      "name": "Key Replacement And Related Services Coverage",
                                      "description": "Key Replacement And Related Services Coverage",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "zrhiqkp0mpdak9pro8vbp0ispn8",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAKeyRepCov_Ext",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 4700
                                  }
                              ],
                              "vehicleName": "2012 BMW 328I ",
                              "isDriverAssignmentAvailable_Ext": true
                          }
                      ],
                      "driverCoverages_Ext": [
                          {
                              "firstName": "Yang",
                              "lastName": "Xiao",
                              "publicID": "pc:7",
                              "relationshipToInsured_Ext": "Insured_Ext",
                              "coverages": [
                                  {
                                      "name": "Extended Non-Owned Individual Coverage",
                                      "description": "Extended Non-Owned Individual Coverage",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "zgthqa0bgm9iednm5ptt86esuo9",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAENORegVehUseIndCov",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "isPAENORegVehUseIndCovAvailable_Ext": true,
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 4410
                                  },
                                  {
                                      "name": "Extended Non-Owned Individual & Resident Relatives Coverage",
                                      "description": "Extended Non-Owned Individual & Resident Relatives Coverage",
                                      "selected": false,
                                      "required": false,
                                      "publicID": "znihsme2ac04tdf20cctd4pca88",
                                      "updated": false,
                                      "isValid": true,
                                      "terms": [],
                                      "code_Ext": "PAENORegVehUseResCov",
                                      "coverageCategoryCode": "PAMiscGrp",
                                      "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                                      "isPAENORegVehUseResCovAvailable_Ext": true,
                                      "expectCoverageTermComponentType_Ext": "DropDownList",
                                      "hasTerms": true,
                                      "priority_Ext": 4420
                                  }
                              ],
                              "contactDenormPublicId_Ext": "pc:620"
                          }
                      ],
                      "lineCoverages": [
                          {
                              "name": "Liability - Bodily Injury",
                              "description": "Liability - Bodily Injury",
                              "selected": true,
                              "required": false,
                              "publicID": "zakg8rb7atum0bv4r74ngi7cltb",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Bodily Injury Liability",
                                      "type": "PackageBodily_Injury_ExtType",
                                      "options": [
                                          {
                                              "name": "$20,000/$40,000",
                                              "maxValue": 5,
                                              "code": "20000/40000",
                                              "optionValue": 1
                                          },
                                          {
                                              "name": "$25,000/$50,000",
                                              "maxValue": 5,
                                              "code": "25000/50000",
                                              "optionValue": 1
                                          },
                                          {
                                              "name": "$50,000/$100,000",
                                              "maxValue": 5,
                                              "code": "50000/100000",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$100,000/$200,000",
                                              "maxValue": 5,
                                              "code": "100000/200000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$100,000/$300,000",
                                              "maxValue": 5,
                                              "code": "100000/300000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$250,000/$500,000",
                                              "maxValue": 5,
                                              "code": "250000/500000",
                                              "optionValue": 4
                                          },
                                          {
                                              "name": "$300,000/$300,000",
                                              "maxValue": 5,
                                              "code": "300000/300000",
                                              "optionValue": 4
                                          },
                                          {
                                              "name": "$500,000/$500,000",
                                              "maxValue": 5,
                                              "code": "500000/500000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "ztghicvs4es0eefda5kqv3o02ua",
                                      "updated": false,
                                      "patternCode": "ztghicvs4es0eefda5kqv3o02ua",
                                      "code_Ext": "Bodily_Injury_Ext",
                                      "coveragePublicID": "zakg8rb7atum0bv4r74ngi7cltb",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "20000/40000",
                                      "chosenTermValue": "$20,000/$40,000"
                                  }
                              ],
                              "code_Ext": "PABILiabilityCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 520
                          },
                          {
                              "name": "Liability - Property Damage",
                              "description": "Liability - Property Damage",
                              "selected": true,
                              "required": false,
                              "publicID": "zu3jolstaooakbrqdtd1onhh1t9",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Property Damage Liability",
                                      "type": "PackageProperty_damageType",
                                      "options": [
                                          {
                                              "name": "$15,000",
                                              "maxValue": 5,
                                              "code": "15000",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$20,000",
                                              "maxValue": 5,
                                              "code": "20000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$25,000",
                                              "maxValue": 5,
                                              "code": "25000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zvqishvc7p88u8ss5rma6ghu3ia",
                                      "updated": false,
                                      "patternCode": "zvqishvc7p88u8ss5rma6ghu3ia",
                                      "code_Ext": "Property_damage",
                                      "coveragePublicID": "zu3jolstaooakbrqdtd1onhh1t9",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "15000",
                                      "chosenTermValue": "$15,000"
                                  }
                              ],
                              "code_Ext": "PAPDLiabilityCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 540
                          },
                          {
                              "name": "Liability - Single Limit",
                              "description": "Liability - Single Limit",
                              "selected": false,
                              "required": false,
                              "publicID": "zkkgiqdro6cb1fehk3501vl5fpa",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PALiabilityCSLCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 560
                          },
                          {
                              "name": "Medical Payments",
                              "description": "Medical Payments",
                              "selected": true,
                              "required": false,
                              "publicID": "PAMedPayCov",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Medical Limit",
                                      "type": "OptionPAMedLimitType",
                                      "options": [
                                          {
                                              "name": "$1,000",
                                              "maxValue": 5,
                                              "code": "1000",
                                              "optionValue": 1
                                          },
                                          {
                                              "name": "$2,000",
                                              "maxValue": 5,
                                              "code": "2000",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$5,000",
                                              "maxValue": 5,
                                              "code": "5000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$10,000",
                                              "maxValue": 5,
                                              "code": "10000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$25,000",
                                              "maxValue": 5,
                                              "code": "25000",
                                              "optionValue": 4
                                          },
                                          {
                                              "name": "$50,000",
                                              "maxValue": 5,
                                              "code": "50000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "PAMedLimit",
                                      "updated": false,
                                      "patternCode": "PAMedLimit",
                                      "code_Ext": "PAMedLimit",
                                      "coveragePublicID": "PAMedPayCov",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "1000",
                                      "chosenTermValue": "$1,000"
                                  }
                              ],
                              "code_Ext": "PAMedPayCov",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 1700
                          },
                          {
                              "name": "Uninsured Motorists Coverage Split",
                              "description": "Uninsured Motorists Coverage Split",
                              "selected": true,
                              "required": false,
                              "publicID": "zfeiu6si8d2r45nrsfp5p33gcr8",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Uninsured Motorists - BI Limits - Split",
                                      "type": "PackagePAIANSUMBILimit_ExtType",
                                      "options": [
                                          {
                                              "name": "$20,000/$40,000",
                                              "maxValue": 5,
                                              "code": "20000/40000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zlgiqlvr2av938u3j6p0qubdt1b",
                                      "updated": false,
                                      "patternCode": "zlgiqlvr2av938u3j6p0qubdt1b",
                                      "code_Ext": "PAIANSUMBILimit_Ext",
                                      "coveragePublicID": "zfeiu6si8d2r45nrsfp5p33gcr8",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "20000/40000",
                                      "chosenTermValue": "$20,000/$40,000"
                                  }
                              ],
                              "code_Ext": "PAUMNonStackIA_Ext",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 1930
                          },
                          {
                              "name": "Underinsured Motorists Coverage Split",
                              "description": "Underinsured Motorists Coverage Split",
                              "selected": true,
                              "required": false,
                              "publicID": "zoah81dj8ih1c5vhl3am0pabes8",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Underinsured Motorists BI Limits Split",
                                      "type": "PackagePAIANSUIMBILimit_ExtType",
                                      "options": [
                                          {
                                              "name": "$20,000/$40,000",
                                              "maxValue": 5,
                                              "code": "20000/40000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zeshuv3nogflm2os6j1kpf6paea",
                                      "updated": false,
                                      "patternCode": "zeshuv3nogflm2os6j1kpf6paea",
                                      "code_Ext": "PAIANSUIMBILimit_Ext",
                                      "coveragePublicID": "zoah81dj8ih1c5vhl3am0pabes8",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "20000/40000",
                                      "chosenTermValue": "$20,000/$40,000"
                                  }
                              ],
                              "code_Ext": "PAUIMNonStackIA_Ext",
                              "coverageCategoryCode": "PAPLiabGrp",
                              "coverageCategoryDisplayName": "Personal Auto Liability Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 3750
                          },
                          {
                              "name": "Extended Non-Owned Individual Coverage",
                              "description": "Extended Non-Owned Individual Coverage",
                              "selected": false,
                              "required": false,
                              "publicID": "zgthqa0bgm9iednm5ptt86esuo9",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAENORegVehUseIndCov",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "isPAENORegVehUseIndCovAvailable_Ext": true,
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 4410
                          },
                          {
                              "name": "Extended Non-Owned Individual & Resident Relatives Coverage",
                              "description": "Extended Non-Owned Individual & Resident Relatives Coverage",
                              "selected": false,
                              "required": false,
                              "publicID": "znihsme2ac04tdf20cctd4pca88",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAENORegVehUseResCov",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "isPAENORegVehUseResCovAvailable_Ext": true,
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 4420
                          },
                          {
                              "name": "Personal Property Coverage",
                              "description": "Personal Property Coverage",
                              "selected": false,
                              "required": false,
                              "publicID": "zvsh02qeaof2b0lqnfa9qtaa949",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAPerPropCov_Ext",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 9800
                          },
                          {
                              "name": "Additional Resident of Your Household",
                              "description": "Additional Resident of Your Household",
                              "selected": false,
                              "required": false,
                              "publicID": "ztihg04q4jpkp4ijpmsc1b83fub",
                              "updated": false,
                              "isValid": true,
                              "terms": [],
                              "code_Ext": "PAAddlHoResCov_Ext",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "hasTerms": false,
                              "priority_Ext": 11200
                          },
                          {
                              "name": "Pet Injury Coverage",
                              "description": "Pet Injury Coverage",
                              "selected": true,
                              "required": true,
                              "publicID": "z5cha9hrfj3gu096pgm3d91lfnb",
                              "updated": false,
                              "isValid": true,
                              "terms": [
                                  {
                                      "name": "Liability Limit",
                                      "type": "OptionPAPetInj_Limit_EXTType",
                                      "options": [
                                          {
                                              "name": "$500",
                                              "maxValue": 5,
                                              "code": "500",
                                              "optionValue": 2
                                          },
                                          {
                                              "name": "$1,000",
                                              "maxValue": 5,
                                              "code": "1000",
                                              "optionValue": 3
                                          },
                                          {
                                              "name": "$2,000",
                                              "maxValue": 5,
                                              "code": "2000",
                                              "optionValue": 5
                                          }
                                      ],
                                      "required": true,
                                      "publicID": "zcaiac4jeucaneoaj84k8i0fdg9",
                                      "updated": false,
                                      "patternCode": "zcaiac4jeucaneoaj84k8i0fdg9",
                                      "code_Ext": "PAPetInj_Limit_EXT",
                                      "coveragePublicID": "z5cha9hrfj3gu096pgm3d91lfnb",
                                      "isAscendingBetter": true,
                                      "chosenTerm": "500",
                                      "chosenTermValue": "$500"
                                  }
                              ],
                              "code_Ext": "PAPetInjuryCov_EXT",
                              "coverageCategoryCode": "PAMiscGrp",
                              "coverageCategoryDisplayName": "Personal Auto Miscellaneous Coverages Group",
                              "expectCoverageTermComponentType_Ext": "DropDownList",
                              "hasTerms": true,
                              "priority_Ext": 12200
                          }
                      ]
                  },
                  "policyType_Ext": "Signature",
                  "publicID_Ext": "pc:2"
              }
          ],
          "isForNamedNonOwner_Ext": false,
          "quotePageData_Ext": {
              "policySummaries": [],
              "availableProducts": [
                  {
                      "status": "Available",
                      "productCode": "Watercraft",
                      "productName": "Watercraft",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "RoadTrail",
                      "productName": "Road & Trail",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "BP7BusinessOwners",
                      "productName": "Businessowners (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CA7CommAuto",
                      "productName": "Commercial Auto (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CommercialPackage",
                      "productName": "Commercial Package",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "GL7GeneralLiability",
                      "productName": "General Liability (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "WCMWorkersComp",
                      "productName": "Workers' Compensation (wcm)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CR7Crime",
                      "productName": "Crime",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "HOPHomeowners",
                      "productName": "Homeowners",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CP7CommercialProperty",
                      "productName": "Commercial Property (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "InlandMarine",
                      "productName": "Inland Marine",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "PersonalAuto",
                      "productName": "Personal Auto",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "DwellingProperty",
                      "productName": "Dwelling Property",
                      "isRiskReserved": false
                  }
              ],
              "serverCurrentDate": {
                  "year": 2023,
                  "month": 1,
                  "day": 8
              }
          },
          "priorCarrierEnter_Ext": [],
          "policyClaimsReport_Ext": [],
          "clueReport_Ext": [],
          "mvrEnter_Ext": [],
          "mvrReport_Ext": [],
          "clueEnter_Ext": [],
          "risks_Ext": {
              "mvrServiceCalled": false,
              "clueServiceCalled": false
          },
          "preQualQuestionSets": [
              {
                  "code": "PAPolicyInfoUWQuestions",
                  "answers": {
                      "anyCoverageDeclinedExplain_Ext": null,
                      "hasLienLast5Yrs_Ext": "false",
                      "policyInfoDoYou_Ext": null,
                      "anyCoverageDeclined_Ext": "false",
                      "hasFamilyMemberWithSeparateAuto_Ext": "false",
                      "hasLienLast5YrsExplain_Ext": null,
                      "isPartOfBookroll_Ext": "false",
                      "hasExistingHO_Ext": "false"
                  }
              }
          ],
          "isExcessVehicleMoreThanDriver_Ext": false
      },
      "watercraft": {
          "quotePageData_Ext": {
              "policySummaries": [],
              "availableProducts": [
                  {
                      "status": "Available",
                      "productCode": "Watercraft",
                      "productName": "Watercraft",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "RoadTrail",
                      "productName": "Road & Trail",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "BP7BusinessOwners",
                      "productName": "Businessowners (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CA7CommAuto",
                      "productName": "Commercial Auto (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CommercialPackage",
                      "productName": "Commercial Package",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "GL7GeneralLiability",
                      "productName": "General Liability (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "WCMWorkersComp",
                      "productName": "Workers' Compensation (wcm)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CR7Crime",
                      "productName": "Crime",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "HOPHomeowners",
                      "productName": "Homeowners",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "CP7CommercialProperty",
                      "productName": "Commercial Property (v7)",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "InlandMarine",
                      "productName": "Inland Marine",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "PersonalAuto",
                      "productName": "Personal Auto",
                      "isRiskReserved": false
                  },
                  {
                      "status": "Available",
                      "productCode": "DwellingProperty",
                      "productName": "Dwelling Property",
                      "isRiskReserved": false
                  }
              ],
              "serverCurrentDate": {
                  "year": 2023,
                  "month": 1,
                  "day": 8
              }
          }
      }
  },
  "quoteID": "0000001001",
  "quoteData": {
      "offeredQuotes": [
          {
              "branchCode": "Version 1",
              "status": "Draft",
              "publicID": "pc:1",
              "branchName": "Version 1",
              "premium": {
                  "termMonths": 6
              },
              "isCustom": true
          },
          {
              "branchCode": "Version 1",
              "status": "Draft",
              "publicID": "pc:2",
              "branchName": "Version 1",
              "premium": {
                  "termMonths": 6
              },
              "isCustom": false
          }
      ]
  },
  "persons": [
      {
          "displayName": "Yang Xiao",
          "dateOfBirth": {
              "year": 1986,
              "month": 0,
              "day": 1
          },
          "firstName": "Yang",
          "gender": "M",
          "lastName": "Xiao",
          "licenseState": "IA",
          "maritalStatus": "S",
          "licenseNumber": "1231233453453",
          "primaryAddress": {
              "state": "IA",
              "country": "US",
              "displayName": "916 Ashworth Rd, West Des Moines, IA 50265-3675",
              "publicID": "pc:629",
              "city": "West Des Moines",
              "county": "Polk",
              "spatialPoint": {
                  "longitude": -93.7411,
                  "latitude": 41.5723
              },
              "addressLine1": "916 Ashworth Rd",
              "postalCode": "50265-3675",
              "addressType": "home"
          },
          "publicID": "pc:620",
          "subtype": "Person",
          "contactName": "Yang Xiao",
          "primaryPhoneType": "mobile",
          "cellNumber": "2015550123",
          "phoneRequired_Ext": true,
          "additionalAddresses": []
      }
  ]
}

export {
  paData,
}