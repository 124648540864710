import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CPPDummyChangePage from './pages/Dummy/CPPDummyChangePage';
import CPPDummyChangeReadOnlyPage from './pages/Dummy/CPPDummyChangePage.readonly';

import CPPPolicyChangeStartPage from './pages/Start/CPPPolicyChangeStartPage';
import CPPPaySuccessChangePage from './pages/PaySuccess/CPPPaySuccessChangePage';

import CPPLineSelectionChangePage from './pages/LineSelection/CPPLineSelectionChangePage';
import CPPLineSelectionChangeReadOnlyPage from './pages/LineSelection/CPPLineSelectionChangePage.readonly';

import CPPLocationsChangePage from './pages/Locations/CPPLocationsChangePage';
import CPPLocationsChangeReadOnlyPage from './pages/Locations/CPPLocationsChangePage.readonly';

import CPPPolicyDetailsChangePage from './pages/PolicyDetails/CPPPolicyDetailsChangePage';
import CPPPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/CPPPolicyDetailsChangePage.readonly';

import CPPQualificationChangePage from './pages/Qualification/CPPQualificationChangePage';
import CPPQualificationChangeReadOnlyPage from './pages/Qualification/CPPQualificationChangePage.readonly';

import CPPModifiersChangePage from './pages/Modifiers/CPPModifiersChangePage';
import CPPModifiersChangeReadOnlyPage from './pages/Modifiers/CPPModifiersChangePage.readonly';

import CPPGLExposuresChangePage from './pages/GLExposures/CPPGLExposuresChangePage';
import CPPCPRisksChangePage from './pages/CPRisks/CPPCPRisksChangePage';
import CPPCPBlanketsChangePage from './pages/CPBlankets/CPPCPBlanketsChangePage';
import CPPGLUnderwritingQuestionsChangePage from './pages/GLUnderwritingQuestions/CPPGLUnderwritingQuestionsChangePage';
import CPPGLUnmannedAircraftChangePage from './pages/GLUnmannedAircraft/CPPGLUnmannedAircraftChangePage';

// WIZARD PAGE IMPORT ANCHOR
import CPPQuoteChangePage from './pages/Quote/CPPQuoteChangePage';
import CPPQuoteChangeReadOnlyPage from './pages/Quote/CPPQuoteChangePage.readonly';


setComponentMapOverrides(
    {
        CPPDummyChangePage: { component: 'CPPDummyChangePage' },
        CPPDummyChangeReadOnlyPage: { component: 'CPPDummyChangeReadOnlyPage' },

        CPPPolicyChangeStartPage: { component: 'CPPPolicyChangeStartPage' },
        CPPPaySuccessChangePage: { component: 'CPPPaySuccessChangePage' },

        CPPQualificationChangePage: { component: 'CPPQualificationChangePage' },
        CPPQualificationChangeReadOnlyPage: { component: 'CPPQualificationChangeReadOnlyPage' },

        CPPPolicyDetailsChangePage: { component: 'CPPPolicyDetailsChangePage' },
        CPPPolicyDetailsChangeReadOnlyPage: { component: 'CPPPolicyDetailsChangeReadOnlyPage' },

        CPPLineSelectionChangePage: { component: 'CPPLineSelectionChangePage' },
        CPPLineSelectionChangeReadOnlyPage: { component: 'CPPLineSelectionChangeReadOnlyPage' },

        CPPLocationsChangePage: { component: 'CPPLocationsChangePage' },
        CPPLocationsChangeReadOnlyPage: { component: 'CPPLocationsChangeReadOnlyPage' },

        CPPModifiersChangePage: { component: 'CPPModifiersChangePage' },
        CPPModifiersChangeReadOnlyPage: { component: 'CPPModifiersChangeReadOnlyPage' },

        CPPGLExposuresChangePage: { component: 'CPPGLExposuresChangePage' },
        CPPCPRisksChangePage: { component: 'CPPCPRisksChangePage' },
        CPPCPBlanketsChangePage: { component: 'CPPCPBlanketsChangePage' },
        CPPGLUnderwritingQuestionsChangePage: { component: 'CPPGLUnderwritingQuestionsChangePage' },
        CPPGLUnmannedAircraftChangePage: { component: 'CPPGLUnmannedAircraftChangePage' },

        // WIZARD PAGE COMPONENT NAME ANCHOR
        CPPQuoteChangePage: { component: 'CPPQuoteChangePage' },
        CPPQuoteChangeReadOnlyPage: { component: 'CPPQuoteChangeReadOnlyPage' },
    },
    {
        CPPDummyChangePage,
        CPPDummyChangeReadOnlyPage,

        CPPPolicyChangeStartPage,
        CPPPaySuccessChangePage,

        CPPQualificationChangePage,
        CPPQualificationChangeReadOnlyPage,

        CPPPolicyDetailsChangePage,
        CPPPolicyDetailsChangeReadOnlyPage,

        CPPLineSelectionChangePage,
        CPPLineSelectionChangeReadOnlyPage,

        CPPLocationsChangePage,
        CPPLocationsChangeReadOnlyPage,

        CPPModifiersChangePage,
        CPPModifiersChangeReadOnlyPage,

        CPPGLExposuresChangePage,
        CPPCPRisksChangePage,
        CPPCPBlanketsChangePage,
        CPPGLUnderwritingQuestionsChangePage,
        CPPGLUnmannedAircraftChangePage,

        // WIZARD PAGE COMPONENT MAP ANCHOR
        CPPQuoteChangePage,
        CPPQuoteChangeReadOnlyPage,
    }
);

 
export { default as CPPPolicyChangeWizard } from './CPPPolicyChangeWizard';
