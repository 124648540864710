import _ from 'lodash';
import {React} from 'react'
import { ViewModelForm} from '@xengage/gw-portals-viewmodel-react';
import metadata from './AdditionalBuildingDetails.metadata.json5';
import DetailsFieldMap from '../Common/DetailsFieldMap'
import FireProtectionSystemTable from '../FireProtectionSystemTable/FireProtectionSystemTable';





const AdditionalBuildingDetails = (props) => {

    const {
        // riskItem,
        // jobID,
        vm,
        writeAdditionalDetailsValue, // update AdditionalDetails - Entity
        isReadOnly,
        onBlur,
        fireProtectionSystemDataVM,
        writeFireProtectionValue,
        addFireProtection,
        removeFireProtectionFunc,
        updateAdditionalDetailsVM,
        additionalDetailsVM
        // sessionUUID,
        // submissionVM,
        // updateSubmissionVM,
        // showErrors,
        // isEditable,
        // onValidate,
    } = props


    const getRoofTypeValue = () => {
        const roofTypeValueList = ['Asphalt Shingles','Built up smooth','Built up Tar / Gravel','Metal','Other','Rubber Membrane']
        const roofTypeValue = roofTypeValueList.map((type) => {
            return {
                code: type,
                name: type 
            }
        })

       return roofTypeValue
    }
    
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly
        },
        '@field': {
            readOnly: isReadOnly
        },
        roofTypeDropdown: {
            value:_.get(vm, 'additionalDetails.buildingRoofType.value', ''),
            availableValues: getRoofTypeValue()
        },
        otherRoofTypeInput:{
            visible: _.get(vm?.value, 'additionalDetails.buildingRoofType', '') === 'other'
        },
        YearOfLastUpdateHeating: {
            visible: _.get(vm?.value, 'additionalDetails.buildingHeatingImprovements', false),
            showGrouping: false
        },
        YearOfLastUpdatePlumbing: {
            visible: _.get(vm?.value, 'additionalDetails.buildingPlumbingImprovements', false),
            showGrouping: false
        },
        yearOfLastUpdateRoofing: {
            visible: _.get(vm?.value, 'additionalDetails.buildingRoofingImprovements', false),
            showGrouping: false
        },
        yearOfLastUpdateWiring: {
            visible: _.get(vm?.value, 'additionalDetails.buildingWiringImprovements', false),
            showGrouping: false
        },
        yearOfLastUpdateOther: {
            visible: _.get(vm?.value, 'additionalDetails.buildingOtherImprovements', false),
            showGrouping: false
        },
        buldingPrimaryHeatOther: {
            visible: _.get(vm?.value, 'additionalDetails.buldingPrimaryHeatOther', '') === 'other'
        },
        fieldSetModel: {
            vm,
            dataPath: 'buildingDisplayables',
            // submissionVM,
            onValueChange: writeAdditionalDetailsValue,
            // onSearch,
            // onValidate,
            onBlur,
            // showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        rightExposureDistance: {
            showGrouping: false
        },
        leftExposureDistance: {
            showGrouping: false
        },
        frontExposureDistance: {
            showGrouping: false
        },
        rearExposureDistance: {
            showGrouping: false
        },
        fireProtectionSystemTable: {
            vm: fireProtectionSystemDataVM,
            onValueChange: writeFireProtectionValue,
            addFireProtection,
            removeFireProtectionFunc,
            additionalDetailsVM,
            updateAdditionalDetailsVM,
            isReadOnly
        }
    }
    
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap,
            fireProtectionSystemTable: FireProtectionSystemTable
        }
    };

    return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                model={vm}
                onValueChange={writeAdditionalDetailsValue}
                // onValidationChange={onValidate}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // showErrors={showErrors}
            />
    );
}

export default AdditionalBuildingDetails