import { defineMessages } from 'react-intl';

export default defineMessages({
    paAddAdditionalInterestContact: {
        id: 'custom.pucoverages.additional-interest.Contact',
        defaultMessage: 'Contact',
    },
    paAddAdditionalInterestBankName: {
        id: 'custom.pucoverages.additional-interest.Bank Name',
        defaultMessage: 'Bank Name',
    },
    paAddAdditionalInterestTrustName: {
        id: 'custom.pucoverages.additional-interest.Trust Name',
        defaultMessage: 'Trust Name',
    },
    paAddAdditionalInterestCompanyType: {
        id: 'custom.pucoverages.additional-interest.Company Type',
        defaultMessage: 'Company Type',
    },
    paAddAdditionalInterestLoanNumber: {
        id: 'custom.pucoverages.additional-interest.Loan Number',
        defaultMessage: 'Loan Number',
    },
    paAddAdditionalInterestRelationshipToInsured: {
        id: 'custom.pucoverages.additional-interest.Relationship to Insured',
        defaultMessage: 'Relationship to Insured',
    },
    paAddAdditionalInterestExplain: {
        id: 'custom.pucoverages.additional-interest.Explain',
        defaultMessage: 'Explain',
    },
    additionalInterestCount: {
        id: 'custom.pucoverages.additional-interest.additionalInterestCount',
        defaultMessage: 'Additional Interest: { count }'
    },
    removeAdditionalInterest: {
        id: 'custom.pucoverages.additional-interest.Remove Additional Interest.ext',
        defaultMessage: 'Remove Additional Interest?',
    },
    removeAdditionalInterestDesc: {
        id: 'custom.pucoverages.additional-interest.AddIntRemovalMsg.ext',
        defaultMessage: 'Are you sure you want to remove this additional interest from the list?',
    },
    addAdditionalInterest: {
        id: 'custom.pucoverages.additional-interest.addAdditionalInterest',
        defaultMessage: 'Add Additional Interest',
    },
    addAdditionalInterestType: {
        id: 'custom.pucoverages.additional-interest.Type',
        defaultMessage: 'Type'
    },
    paAddAdditionalInterestTrust: {
        id: 'custom.pucoverages.additional-interest.Trust',
        defaultMessage: 'Trust'
    },
    editAdditionalInterest: {
        id: 'custom.pucoverages.additional-interest.editAdditionalInterest',
        defaultMessage: 'Edit Additional Interest',
    },
    interestDetails: {
        id: 'custom.pucoverages.additional-interest.interestDetails',
        defaultMessage: 'Interest Details',
    },
    trustees: {
        id: 'custom.pucoverages.additional-interest.trustees',
        defaultMessage: 'Trustees',
    },
    deleteSelected: {
        id: 'custom.pucoverages.additional-interest.Delete Selected',
        defaultMessage: 'Delete Selected'
    },
    addTrustee: {
        id: 'custom.pucoverages.additional-interest.Add Trustee',
        defaultMessage: 'Add Trustee'
    },
    saveTrustee: {
        id: 'custom.pucoverages.additional-interest.Save',
        defaultMessage: 'Save'
    },
    viewAndEditLabel: {
        id: 'custom.pucoverages.additional-interest.View/Edit',
        defaultMessage: 'View/Edit'
    },
    confirmationToRemoveTitle: {
        id: 'custom.pucoverages.additional-interest.Remove Trustee',
        defaultMessage: 'Remove Trustee'
    },
    confirmationToRemoveMessage: {
        id: 'custom.pucoverages.additional-interest.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove?'
    },
    viewLabel: {
        id: 'custom.pucoverages.additional-interest.View',
        defaultMessage: 'View'
    }
});
