import { defineMessages } from 'react-intl';

export default defineMessages({
    ListOfScheduleItems: {
        id: 'wni.quoteandbind.im-wizard.im-ElectronicDataProcessing.LiatOfScheduleItems',
        defaultMessage: 'List Of Scheduled Items',
    },
    AdditionalInsuredSchedule: {
        id: 'wni.quoteandbind.im-wizard.im-ElectronicDataProcessing.AdditionalInsuredSchedule',
        defaultMessage: 'Additional Interest/ Additional Insureds',
    },
    ScheduleDetails: {
        id: 'wni.quoteandbind.im-wizard.im-ElectronicDataProcessing.ScheduleDetails',
        defaultMessage: 'Schedule Details',
    },
    AdditionalInsuredDetail: {
        id: 'wni.quoteandbind.im-wizard.im-ElectronicDataProcessing.AdditionalInsuredDetail',
        defaultMessage: 'Additional Interest/ Additional Insured Detail',
    },
});