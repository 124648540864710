import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('dpMultiQuoteChange'), method, data, additionalHeaders);
}

export default class DPMultiQuoteChangeService {
    // /**
    //  * Invokes HOAdditionalInterestsHandler.methodName()
    //  * is disabled.
    //  * @param {object} data the QuoteDataDTO json obj
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static methodName(data, authHeader = {}) {
    //     return processSubmission('methodName', [data], authHeader);
    // }

    /**
     * Invokes DPMultiQuoteChangeHandler.processQuote()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processQuote(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('processQuote', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteChangeHandler.processIssuance()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processIssuance(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('processIssuance', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteChangeHandler.processIssuanceWithoutWarning()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processIssuanceWithoutWarning(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('processIssuanceWithoutWarning', [jobID, sessionUUID], authHeader);
    }

    // /**
    //  * Invokes DPMultiQuoteChangeHandler.getValidationAndUWIssues()
    //  * 
    //  * @deprecated replaced by retrieveSideBySideData()
    //  * 
    //  * @param {string} jobID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static getValidationAndUWIssues(jobID, sessionUUID, authHeader = {}) {
    //     return processSubmission('getValidationAndUWIssues', [jobID, sessionUUID], authHeader);
    // }

    // /**
    //  * Invokes HOMultiQuoteHandler.finishQuote()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static finishQuote(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('finishQuote', [quoteID, sessionUUID], authHeader);
    // }

    /**
     * Invokes DPMultiQuoteChangeHandler.createDuplicateQuote()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDuplicateQuote(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('createDuplicateQuote', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteChangeHandler.withdrawBranch()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} policPeriodPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static withdrawBranch(jobID, sessionUUID, policPeriodPublicID, authHeader = {}) {
        return processSubmission('withdrawBranch', [jobID, sessionUUID, policPeriodPublicID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteChangeHandler.retrieveSideBySideData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static retrieveSxsData(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('retrieveSideBySideData', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteChangeHandler.retrieveSideBySideDataWithActiveQuotes()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static retrieveSxsDataWithActiveQuotes(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('retrieveSideBySideDataWithActiveQuotes', [jobID, sessionUUID], authHeader);
    }


    // /**
    //  * Invokes HOMultiQuoteHandler.createHO3Quote()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static createHO3Quote(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('createHO3Quote', [quoteID, sessionUUID], authHeader);
    // }



    // /**
    //  * Invokes HOMultiQuoteHandler.createHO5Quote()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static createHO5Quote(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('createHO5Quote', [quoteID, sessionUUID], authHeader);
    // }


    // /**
    //  * Invokes HOMultiQuoteHandler.createSelectQuote()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static createSelectQuote(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('createSelectQuote', [quoteID, sessionUUID], authHeader);
    // }

    // /**
    //  * Invokes HOMultiQuoteHandler.retrieveQuotePeriodData()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static retrieveQuotePeriodData(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('retrieveQuotePeriodData', [quoteID, sessionUUID], authHeader);
    // }
}
