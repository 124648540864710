import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'PersonalUmbrella.directives.schedules.table.Add',
        defaultMessage: 'Add',
    },
    existingAuto: {
        id: 'PersonalUmbrella.directives.schedules.table.Add.MenuHeader.ExistingAuto',
        defaultMessage: 'Existing Auto',
    },
    existingExcludedDriver: {
        id: 'PersonalUmbrella.directives.schedules.table.Add.MenuHeader.ExistingExcludedDriver',
        defaultMessage: 'Existing Excluded Driver',
    },
    existingPremise: {
        id: 'PersonalUmbrella.directives.schedules.table.Add.MenuHeader.ExistingPremise',
        defaultMessage: 'Existing Premise',
    }
})
