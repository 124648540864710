
import React, {
    useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DriverCoveragesUtil } from 'wni-portals-util-js';
import DriverCoveragesComponent from '../DriverCoverages/DriverCoveragesComponent';
import metadata from './DriverCoveragesSection.metadata.json5';
import styles from './DriverCoveragesSection.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const DriverCoveragesSection = (props) => {
    const {
        submissionVM,
        updateWizardSnapshot,
        driver,
        index,
        showErrors,
        onValidate,
        isReadOnly,
        onValueChange,
        fromReadOnlyPage,
        updateCoverages,
        loadingClause,
        selectedVersionOfferingsIndex,
        lobName
    } = props;

    const {
        firstName,
        lastName
    } = driver || {};

    useEffect(() => {
    }, []);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = _.clone(submissionVM);
            const coveragesPath = `value.lobData.${lobName}.offerings[${selectedVersionOfferingsIndex}].coverages`;
            const coverages = _.get(newSubmissionVM, `${coveragesPath}`);
            const driverCoverages = DriverCoveragesUtil.generateDriverCoverages(submissionVM, coverages);

            const driverIndex = _.findIndex(driverCoverages, (cov) => {
                return cov.publicID === driver.publicID;
            });

            const updateCoverage = DriverCoveragesUtil.setClauseValue(changedPath, driverCoverages[driverIndex], value);

            const lineCoverages = _.get(newSubmissionVM, `${coveragesPath}.lineCoverages`);
            const updateDriverCovsMap = {};
            _.each(updateCoverage.coverages, (cov) => {
                _.set(updateDriverCovsMap, cov.code_Ext, cov);
            });
            const updateLineCoverages = _.map(lineCoverages, (cov) => {
                if (_.hasIn(updateDriverCovsMap, cov.code_Ext)) {
                    return _.get(updateDriverCovsMap, cov.code_Ext);
                }
                return cov;
            });
            _.set(newSubmissionVM, `${coveragesPath}.lineCoverages`, updateLineCoverages);
            updateWizardSnapshot(newSubmissionVM);

            const covPath = changedPath.split('.')[0];
            updateCoverages(_.get(updateCoverage, covPath));
        },
        [submissionVM, lobName, selectedVersionOfferingsIndex, updateCoverages, driver.publicID]
    );

    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            // showRequired: true,
            readOnly: isReadOnly
        },
        driverName: {
            content: `Coverages for Driver ${index + 1}: ${firstName} ${lastName}`
        },
        coverages: {
            driver,
            onChangeClause: changeSubmission,
            loadingClause,
            showErrors,
            readOnly: fromReadOnlyPage
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {
            driverCoverages: DriverCoveragesComponent
        },
    };

    const readValue = (id, readPath) => {
        return readViewModelValue(
            metadata.componentContent,
            driver,
            id,
            readPath,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driver}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            // onModelChange={handleValueChange}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
};

DriverCoveragesSection.propTypes = {
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    updateWizardSnapshot: PropTypes.func,
    driver: PropTypes.shape(
        {
            value: PropTypes.shape({}),
            publicID: PropTypes.string
        }
    ),
    showErrors: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    index: PropTypes.number,
    fromReadOnlyPage: PropTypes.bool,
    updateCoverages: PropTypes.func,
    loadingClause: PropTypes.bool,
    selectedVersionOfferingsIndex: PropTypes.number,
    lobName: PropTypes.string.isRequired
};

DriverCoveragesSection.defaultProps = {
    submissionVM: {},
    updateWizardSnapshot: undefined,
    driver: {},
    showErrors: false,
    isReadOnly: false,
    index: 1,
    fromReadOnlyPage: false,
    updateCoverages: undefined,
    loadingClause: false,
    selectedVersionOfferingsIndex: 0
};

export default DriverCoveragesSection;
