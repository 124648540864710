import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';

// const PhoneNumberUtil = {

/**
 * Remove mask char from phone number
 * @param {string} phoneNumber 
 * @param {string} maskChar Optional
 * @returns {string}
 */
function removeMaskCharFromPhoneNumber(phoneNumber, maskChar = '-') {
    // The phoneNumber.replaceAll existence check here aims to
    // eradicate the random JS error on Drivers page. This is a
    // tmeporary hack instead of actual solution.
    //
    // Current guess: phoneNumber is set to something other than String
    //
    // Refactoring Notice: Remove the phonNumber.replaceAll check here,
    // And go to Drivers related component to dig out the culprit.
    if (!(phoneNumber && phoneNumber.replaceAll)) {
        return phoneNumber;
    }
    const retval = phoneNumber.replaceAll(maskChar, '');
    return retval;
}

/**
 * Check whether phone number has been udpated
 * @param {string} phoneNumber
 * @param {function} onPhoneNumberUpdated
 */
function checkAndUpdatePhoneNumber(phoneNumber, onPhoneNumberUpdated) {
    const formattedPhoneNumber = removeMaskCharFromPhoneNumber(phoneNumber);
    if (formattedPhoneNumber !== phoneNumber) {
        onPhoneNumberUpdated(formattedPhoneNumber);
    }
}

//     return {
//         removeMaskCharFromPhoneNumber
//     };
// };


function sortSubmissionData(submissionVM) {
    const driverPath = 'lobData.personalAuto.coverables.drivers';
    const drivers = _.get(submissionVM, `value.${driverPath}`);

    const sortedDrivers = _.sortBy(drivers, 'fixedId');

    _.set(submissionVM, `value.${driverPath}`, sortedDrivers);
}

/**
 * Extracted from PAPolicyDetailsPage
 * @param {object} param0
 */
function updateNamedNonOwnerPageStep({
    isForNamedNonOwner,
    initialSteps, // initial wizard steps
    steps: currentWizardSteps, // current wizard steps
    changeNextSteps, // changeNextSteps from WizardContext
    driversPageId,
    driverCoveragesPageId
}) {
    // WARNING: DO NOT MODIFY existing wiard steps array
    const driversPageStep = currentWizardSteps.find((step) => step.id === driversPageId);

    const initialDriverCoveragesPageStep = initialSteps.find((step) => step.id === driverCoveragesPageId);
    const currentDriverCoveragesPageStep = currentWizardSteps.find((step) => step.id === driverCoveragesPageId);

    const driversPageIndex = currentWizardSteps.findIndex((step) => step.id === driversPageId);
    const driverCoveragesPageIndex = currentWizardSteps.findIndex((step) => step.id === driverCoveragesPageId);

    // const newWizardSteps = _.clone(currentWizardSteps);
    let nextWizardSteps = [];
    if (isForNamedNonOwner) {
        if (driverCoveragesPageIndex >= 0) {
            const stepsFollowingDriverCovsPage = currentWizardSteps.slice(driverCoveragesPageIndex + 1);
            nextWizardSteps = [driversPageStep, ...stepsFollowingDriverCovsPage];
        }
    } else if (driverCoveragesPageIndex < 0) {
        const stepsFollowingDriversPage = currentWizardSteps.slice(driversPageIndex + 1);
        nextWizardSteps = [driversPageStep, initialDriverCoveragesPageStep, ...stepsFollowingDriversPage];
    }
    if (!_.isEmpty(nextWizardSteps)) {
        changeNextSteps(nextWizardSteps);
    }
}

function filterPANamedNonOwnerWizardStepsForReadOnlyMode(initialReadOnlySteps, filterPageId) {
    const retval = initialReadOnlySteps.filter((step) => step.id !== filterPageId);
    return retval;
}

/**
 * Cleanse auxiliary client side help data
 * @param {object} quoteDataDTOJson
 * @returns {array} a cloned instance along with the update flag
 */
function cleanseAuxiliaryData(quoteDataDTOJson) {
    const clonedCurrentJson = _.cloneDeep(quoteDataDTOJson);
    // ============from PADriversPage.setRowId() BEGINS ================================
    const driverData = _.get(clonedCurrentJson, 'lobData.personalAuto.coverables.drivers', []);
    let isDataJsonUpdated = false;
    driverData.forEach((driver) => {
        const {
            person,
            person: {
                homeNumber,
                cellNumber,
                workNumber,
            } = {},
            dateFirstLicensed_Ext: dateFirstLicensed,
        } = driver || { person: {} };
        // ============from PADriversPage.setRowId() ENDS ================================
        // ============from DriverLicenseInfoComponent BEGINS ============================
        if (dateFirstLicensed) {
            _.unset(driver, 'dateFirstLicensed_Ext');
            isDataJsonUpdated = true;
        }
        // ============from DriverLicenseInfoComponent ENDS ============================
        // ============from DriverContactDetailsComponent BEGINS==========================
        // home number could be formatted from 7157562750 to 715-756-2750
        if (homeNumber) {
            // const formattedNumber = removeMaskCharFromPhoneNumber(homeNumber);
            // if (formattedNumber !== homeNumber) {
            //     _.set(person, 'homeNumber', formattedNumber);
            //     rowIdCleansed = true;
            // }
            checkAndUpdatePhoneNumber(homeNumber, (formattedNumber) => {
                _.set(person, 'homeNumber', formattedNumber);
                isDataJsonUpdated = true;
            });
        }
        if (cellNumber) {
            checkAndUpdatePhoneNumber(cellNumber, (formattedNumber) => {
                _.set(person, 'cellNumber', formattedNumber);
                isDataJsonUpdated = true;
            });
        }
        if (workNumber) {
            checkAndUpdatePhoneNumber(workNumber, (formattedNumber) => {
                _.set(person, 'workNumber', formattedNumber);
                isDataJsonUpdated = true;
            });
        }
        // ============from DriverContactDetailsComponent ENDS==========================
    });
    // ============from PAVehiclesPage BEGINS ==========================================
    const vehiclesData = _.get(clonedCurrentJson, 'lobData.personalAuto.coverables.vehicles', []);
    vehiclesData.forEach((vehicle) => {
        const {
            owners_Ext: owners,
        } = vehicle;
        // treat empty owners and undefined owners the same
        if (!owners) {
            _.set(vehicle, 'owners_Ext', []);
            isDataJsonUpdated = true;
        }
    });
    // ============from PAVehiclesPage ENDS ==========================================
    // ============from VehicleGaragesComponent BEIGNS==============================
    const garageAtOptions = _.get(clonedCurrentJson, 'lobData.personalAuto.coverables.garagedAtOptions_Ext', []);
    garageAtOptions.forEach((garageAtOption) => {
        const {
            tempCode
        } = garageAtOption;
        if (tempCode) {
            _.unset(garageAtOption, 'tempCode');
            isDataJsonUpdated = true;
        }
    });
    // ============from VehicleGaragesComponent ENDS==============================
    return [clonedCurrentJson, isDataJsonUpdated];
}

/**
 * ref: https://stackoverflow.com/questions/31683075/how-to-do-a-deep-comparison-between-2-objects-with-lodash
 *
 * Get the different properties between two objects
 * @param {object} obj1
 * @param {object} obj2
 * @returns {array}
 */
function getDiffProps(obj1, obj2) {
    const retval = _.reduce(obj1, (result, value, key) => {
        if (_.isEqual(value, obj2[key])) {
            return result;
        }
        return result.concat(key);
    }, []);
    return retval;
}

/**
 * This method exists because of PADriversPage.setRowId().
 *
 * Refactoring Notice: cleanse driver.rowIdPath and garagedAtOptions_Ext.tempCode first
 * @param {object} wizardData
 * @param {object} wizardSnapshot
 * @returns {boolean}
 */
function isWizardDataEqualForPA(wizardData = {}, wizardSnapshot = {}) {
    const {
        value: currentJson,
    } = wizardData;
    const {
        value: snapshotJson
    } = wizardSnapshot;
    if (!currentJson || !snapshotJson) {
        return false;
    }

    let retval = false;
    if (_.isEqual(currentJson, snapshotJson)) {
        retval = true;
    } else {
        const [clonedCurrentJson, isCurrentJsonCleansed] = cleanseAuxiliaryData(currentJson);
        const [clonedSnapshotJson, isSnapshotJsonCleansed] = cleanseAuxiliaryData(snapshotJson);

        if (isCurrentJsonCleansed || isSnapshotJsonCleansed) {
            retval = _.isEqual(clonedCurrentJson, clonedSnapshotJson);

            // POI-17229: _.isEqual() is being naughty by returning false for two JSON objects with
            // equal stringified result
            //
            // Snippet from online article:
            // "
            // Well that depends. For JSON.stringify(), the order matters.
            // So if the key-value pair are ordered differently in the two objects but are the same,
            // it will return false. Whereas it doesn't matter in Lodash isEqual,
            // it will return true as along as the key-value pair exists.
            // "
            // ref: https://www.samanthaming.com/tidbits/33-how-to-compare-2-objects/
            //
            // Refactoring Note: This looks bad. A better way is needed for this comparison
            // retval = JSON.stringify(clonedCurrentJson) === JSON.stringify(clonedSnapshotJson);
            // if (!retval) {
            //     retval = _.isEqual(clonedCurrentJson, clonedSnapshotJson);
            // }
            // if (!retval) {
            //     const diffProps = getDiffProps(clonedCurrentJson, clonedSnapshotJson);
            //     const logDiffProps = diffProps;
            // }
        }
    }
    return retval;
}

/**
 * A simple comparator for wizardData and wizardSnapshot
 * @param {object} wizardData submisionVM view model object
 * @param {object} wizardSnapshot submisionVM view model object
 * @returns {boolean}
 */
function wizardDataSimpleComparator(wizardData ={}, wizardSnapshot = {}) {
    const {
        value: currentJson,
    } = wizardData;
    const {
        value: snapshotJson
    } = wizardSnapshot;
    if (!currentJson || !snapshotJson) {
        return false;
    }
    return _.isEqual(currentJson, snapshotJson);
}

/**
 * Checks whether wizard data has been changed
 * @param {object} wizardData
 * @param {object} wizardSnapshot
 * @param {function} wizardDataComparator
 * @returns {boolean}
 */
function isWizardDataChanged(wizardData, wizardSnapshot, wizardDataComparator) {
    // return !isWizardDataEqual(wizardData, wizardSnapshot);
    return !wizardDataComparator(wizardData, wizardSnapshot);
}

/**
 * Checks whether copySubmission is in progress
 * @param {object} wizardPageData
 * @returns {boolean}
 */
function isCopySubmissionInProgress(wizardPageData) {
    const copySubmission = wizardPageData[WizardConstants.copySubmission];
    return !_.isNil(copySubmission);
}

/**
 * Extract EdgeJobDataDTO from wizardPageData
 * @param {object} wizardPageData
 * @param {object} extra info to save
 * @returns {object} an instance of EdgeJobDataDTO
 */
function getQuoteWizardExitData(wizardPageData, {
    currentStepIndex,
}) {
    const retval = {
        quotePriceViewMode: wizardPageData[WizardConstants.paymentViewType],
        // submissionWizardExitPage: wizardPageData[WizardConstants.lastExitWizardPage],
        submissionWizardExitPage: currentStepIndex,
    };
    return retval;
}

/**
 * Provide wizardPageData populated with info from EdgeJobDataDTO
 * @param {object} edgeJobDataDTO
 * @returns {object} the initial wizardPageData instance
 */
function getInitialWizardPageData(edgeJobDataDTO) {
    const {
        quotePriceViewMode,
        submissionWizardExitPage,
        accountJobs,
        accountCompletedJobs,
        locked,
        policySummaries,
    } = edgeJobDataDTO;
    const res = {
        [WizardConstants.paymentViewType]: quotePriceViewMode,
        [WizardConstants.lastExitWizardPage]: submissionWizardExitPage,
        //
        [WizardConstants.accountActiveQuotes]: accountJobs,
        [WizardConstants.accountCompletedQuotes]: accountCompletedJobs,
        //
        [WizardConstants.isUwLocked]: locked,
        //
        [WizardConstants.policySummaries]: policySummaries,
    };
    return res;
}

/**
 * Get CoveragesForm
 * @param {object} wizardData 
 * @returns {string}
 */
function getCoveragesForm(wizardData){
    const {
        lobData: {
            homeowners: {
                householdInfoDTO_Ext: {
                    coveragesForm
                }
            }
        },
    } = wizardData;
    return coveragesForm
}

export default {
    sortSubmissionData,
    updateNamedNonOwnerPageStep,
    isWizardDataChanged,
    wizardDataSimpleComparator,
    isWizardDataEqualForPA,
    filterPANamedNonOwnerWizardStepsForReadOnlyMode,
    isCopySubmissionInProgress,
    getQuoteWizardExitData,
    getInitialWizardPageData,
    getCoveragesForm,
};
