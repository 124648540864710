import { MetadataContent } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import React from 'react';

const ExposureFieldMap = (props) => {
    const {
        vm,
        dataPath,
        onValueChange,
        onValidate,
        onBlur,
        onSearch,
        showErrors,
        readOnly
    } = props;

    const fieldModels = _.get(vm.value, dataPath, []);
    const handleValueChange = (newValue, index, fieldModel) => {
        onValueChange(newValue, `${dataPath}[${index}]`, fieldModel);
    };

    const handleBlur = (value, fieldItem) => {
        onBlur(value, fieldItem);
    };

    const metadata = fieldModels.map((fieldItem, index) => {
        switch(fieldItem.propertyName) {
            case '':
                return;
            default:
        return {
            id: `${fieldItem.propertyName}Field`,
            type: 'field',
            component: 'WniFieldSetModel',
            componentProps: {
                showRequired: true,
                showErrors: showErrors,
                readOnly: readOnly || fieldItem.readonly || !fieldItem.editable,
                onValueChange: (value, fieldModel) => handleValueChange(value, index, fieldModel),
                onBlur: (value) => handleBlur(value, fieldItem),
                fieldItem,
                onValidate,
                onSearch,
                actionVisible: fieldItem.inputSetMode === "searchablerange" ? !readOnly : true
            },
        };
        }
        
    });

    const overrides = {};
    const resolvers = {
        resolveComponentMap: {},
    };

    return (
        <MetadataContent
            uiProps={metadata}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default ExposureFieldMap;
