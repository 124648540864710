import { defineMessages } from 'react-intl';

export default defineMessages({
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ'
    },
    error: {
        id: 'portal.index.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'portal.index.Sorry, an error occurred. You can try again later or contact us.',
        defaultMessage: 'Sorry, an error occurred. You can try again later or contact us.'
    }
});
