const sectionFieldsMap = {
    stateInfoDetail: [
        // 'hiredAutoCoverage',
        // 'nonOwnedAutoCoverage',
        'ZipCode',
        'GaragingLocation',
        'NonOwnershipRiskType',
        'NumberOfEmployees',
        'NumberOfVolunteers',
        'NumberOfPartners'
    ],
};

export default {
    sectionFieldsMap,
};
