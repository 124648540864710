import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalInsuredTitle: {
        "id": "quoteandbind.gl.wizard.step.exposures page.Additional Insured.title",
        "defaultMessage": "Additional Insured"
    },
    addAdditionalInsuredActionText: {
        "id": "quoteandbind.gl.wizard.step.exposures page.Additional Insured.add coverages",
        "defaultMessage": "Add Coverages"
    }
})