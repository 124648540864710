import React, {
    // useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { useWniModal } from 'wni-components-platform-react';
import { CAPolicyDetailsChangeService } from 'wni-capability-policychange-ca';
import {
    CAWizardPage as WizardPage
} from 'wni-capability-quoteandbind-ca-react';

// import styles from './CAPolicyDetailsPage.module.scss';
import metadata from './CAPolicyDetailsChangePage.metadata.json5';
// import messages from './CAPolicyDetailsPage.messages';

function CAPolicyDetailsChangePage(props) {

    // const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,

        // savePolicyDetailsData,

    } = props;



    // const history = useHistory();
    // const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        onValidate,
    } = useValidation('CAPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);


    // );

    const getMinDate = useCallback(() => {
        return systemDate
    }, [systemDate]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                isReadOnly: true,
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: getMinDate(),
                showErrors
            },
        
            
        }
    }, [getMinDate, showErrors]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: {

        },
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAPolicyDetailsChangePage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
};

CAPolicyDetailsChangePage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: CAPolicyDetailsChangeService.saveCAPolicyDetailsData,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
} 
export default CAPolicyDetailsChangePage;