import { WniProductsUtil } from 'wni-portals-util-js';
// PL
import HOTooltipUtil from './HOTooltip/HOTooltipUtil';
import PUTooltipUtil from './PUTooltip/PUTooltipUtil';
import RTTooltipUtil from './RTTooltip/RTTooltipUtil';
import WALTooltipUtil from './WALTooltip/WALTooltipUtil';
// CL
import CA7TooltipUtil from './CA7Tooltip/CA7TooltipUtil';
import WCMTooltipUtil from './WCMTooltip/WCMTooltipUtil';
import CPPTooltipUtil from './CPPTooltip/CPPTooltipUtil';
import CPTooltipUtil from './CPTooltip/CPTooltipUtil';
import DPTooltipUtil from './DPTooltip/DPTooltipUtil';
import GLTooltipUtil from './GLTooltip/GLTooltipUtil';


const {
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
} = WniProductsUtil;

const getLineTooltips = (productCode) => {
    switch (productCode) {
        case HOP_PRODUCT_CODE:
        case HO_PRODUCT_CODE:
            return HOTooltipUtil;
        case DP_PRODUCT_CODE:
            return DPTooltipUtil;
        case WAL_PRODUCT_CODE:
            return WALTooltipUtil;
        case CA_PRODUCT_CODE:
            return CA7TooltipUtil;
        case RT_PRODUCT_CODE:
            return RTTooltipUtil;
        case PU_PRODUCT_CODE:
            return PUTooltipUtil;
        case WCM_PRODUCT_CODE:
            return WCMTooltipUtil;
        case GL_PRODUCT_CODE:
            return GLTooltipUtil;
        case CP_PRODUCT_CODE:
            return CPTooltipUtil;
        default:
            return {};
    }
};

const HODPTooltipUtil = {
    ...HOTooltipUtil,
    ...DPTooltipUtil
}

const TooltipContentUtil =  {
    ...HOTooltipUtil,
    ...WALTooltipUtil,
    ...RTTooltipUtil,
    ...DPTooltipUtil,
    ...PUTooltipUtil,
    ...CA7TooltipUtil,
}

export {
    getLineTooltips,
    HODPTooltipUtil,
    TooltipContentUtil
};
