
const HO_SECTION_I_CATEGORY = 'HOPSectionICovCat';
const HO_SECTION_II_CATEGORY = 'HOPSectionIICovCat';

// const LOB_NAME = 'homeowners';

// const LOB_OFFERING_PATH = `lobData.${LOB_NAME}.offerings`;
// const SXS_PERIODS_PATH = `${LOB_NAME}.periods`;
// const SXS_COVERAGES_PATH = `${LOB_NAME}.coverages`;

// const BRANCH_NAME_PATH = `lobData.${LOB_NAME}.offerings[0].branchName`;

export function generateConfigFromLobName(lobName) {
    const LOB_NAME = lobName;

    const LOB_OFFERING_PATH = `lobData.${LOB_NAME}.offerings`;
    const SXS_PERIODS_PATH = `${LOB_NAME}.periods`;
    const SXS_COVERAGES_PATH = `${LOB_NAME}.coverages`;

    const BRANCH_NAME_PATH = `lobData.${LOB_NAME}.offerings[0].branchName`;

    return {
        //
        LOB_NAME,
        LOB_OFFERING_PATH,
        SXS_PERIODS_PATH,
        SXS_COVERAGES_PATH,
        //
        BRANCH_NAME_PATH,
    };
}

// ==================================
// const hoConfigs = generateConfigFromLobName('homeowners');


// export {
//     generateConfigFromLobName,
// };

export default {
    SECTION_I_CATEGORY: HO_SECTION_I_CATEGORY,
    SECTION_II_CATEGORY: HO_SECTION_II_CATEGORY,
    //
    // generateConfigFromLobName,
};
