import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { WindowUtil, ConfigUtil } from 'wni-portals-util-js';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { WNIAddressLookupService } from 'wni-capability-address';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import messages from './WALBankSearchComponent.message';
import metadata from './WALBankSearchComponent.metadata.json5';
import styles from './WALBankSearchComponent.module.scss';

function WALBankSearchComponent(props) {
    const {
        viewModelService,
        selectRow,
        updateSelectRow,
        addNewBankFn,
        useAuthenticationData
    } = props;

    const { authHeader } = useAuthenticationData || useAuthentication();
    const translator = useTranslator();
    const [isSearched, updateIsSearched] = useState(false);
    const [showBankNameRequiredMsg, updateShowBankNameRequiredMsg] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [bankPageConfig, updateBankPageConfig] = useState(WniTableRowUtil.defaultPageConfig);
    const createEmptyVM = useCallback(() => {
        const emptyVM = viewModelService.create({ primaryAddress: {} },
            'pc', 'wni.edge.capabilities.address.dto.BankSearchCriteriaDTO');
        return emptyVM;
    }, [viewModelService]);

    const [bankSearchCritieraVM, updateBankSearchCritieraVM] = useState(createEmptyVM());
    const [showTooManySearchResults, updateShowTooManySearchResults] = useState(false);
    const [bankSearchValue, updateBankSearchValue] = useState([]);
    const TOO_MANY_RESULTS = 'ContactManager returned too many results, please make your search criteria more specific.';
    const PROVIDE_MORE_SPECIFIC_SEARCH_CRITERIA = 'Please provide more specific search criteria';

    useEffect(() => {
        bankSearchValue.forEach((item, index) => {
            _.set(item, 'rowIdPath', ConfigUtil.getUuid()+index);
            
        });
    }, [bankSearchValue]);
    const handleSearch = () => {
        const bankName = _.get(bankSearchCritieraVM, 'value.bankName');
        if (_.isEmpty(bankName) || _.isNil(bankName) || bankName.length < 5) {
            updateShowBankNameRequiredMsg(true);
            WindowUtil.scrollTo('newQuoteHeader');
            return false;
        }
        const zipCode = _.get(bankSearchCritieraVM, 'value.primaryAddress.postalCode')
        if (_.isEmpty(zipCode) || _.isNil(zipCode) || zipCode.length < 5) {
            updateShowBankNameRequiredMsg(true);
            WindowUtil.scrollTo('newQuoteHeader');
            return false;
        }
        // enable loading
        updateIsLoading(true);
        // update search flag
        updateIsSearched(true);
        // reset select row
        updateSelectRow({});
        // reset search table value
        updateBankSearchValue([]);
        updateBankPageConfig(WniTableRowUtil.defaultPageConfig);
        return WNIAddressLookupService.searchBank(
            _.get(bankSearchCritieraVM, 'value'),
            authHeader,
        ).then((res) => {
            const banks = _.sortBy(_.get(res, 'accountContactDTOs'), (bank) => { return _.get(bank, bankName); });
            updateBankSearchValue(banks);
            updateBankPageConfig(WniTableRowUtil.defaultPageConfig);

            const warningMessage = _.get(res, 'warningMessage');
            if (warningMessage && (warningMessage === TOO_MANY_RESULTS || warningMessage.includes(PROVIDE_MORE_SPECIFIC_SEARCH_CRITERIA))) {
                updateShowTooManySearchResults(true);
                updateIsSearched(false);
                WindowUtil.scrollTo('newQuoteHeader');
            }
            // disable loading
            updateIsLoading(false);
        }).catch((e) => {
            // handle too many results
            // error code = -32603
            // error msg contains Java.Search.TooManyResults
            // = More than {0} results found. Please provide more specific search criteria.
            updateIsSearched(false);
            const errorCode = _.get(e, 'baseError.code');
            const errorMsg = _.get(e, 'baseError.message');
            if (errorCode === -32603 && _.includes(errorMsg, 'More than')) {
                updateShowTooManySearchResults(true);
                WindowUtil.scrollTo('newQuoteHeader');
            }
            _.noop();
            // enable loading
            updateIsLoading(false);
        });
    };

    const handleReset = useCallback(() => {
        // reset searchVM value
        const emptyVM = createEmptyVM();
        updateBankSearchCritieraVM(emptyVM);
        // reset search table value
        updateBankSearchValue([]);
        updateBankPageConfig(WniTableRowUtil.defaultPageConfig);
        // reset select row
        updateSelectRow({});
    }, [createEmptyVM, updateSelectRow]);

    const handleAddNewBank = useCallback(() => {
        if (addNewBankFn) {
            // update pobox_ext value with current pobox
            _.set(bankSearchCritieraVM, 'value.pobox_Ext', _.get(bankSearchCritieraVM, 'value.pobox'));
            // set addressType default business
            _.set(bankSearchCritieraVM, 'value.addressType', 'business');
            addNewBankFn(bankSearchCritieraVM);
        }
    }, [addNewBankFn, bankSearchCritieraVM]);

    const hightLight = (activeRow) => {
        const selectedPublicID = _.get(activeRow, 'publicID') || _.get(activeRow, 'rowIdPath');
        WniTableRowUtil.setTablePublicIDSelected(selectedPublicID, 'exisitingAgencyMatchTable');
    };

    const getSelectedFn = (item) => {
        updateSelectRow(item);
        hightLight(item);
    };

    const writeValue = useCallback(
        (value, path) => {
            const newBankSearchCritieraVM = viewModelService.clone(bankSearchCritieraVM);
            _.set(newBankSearchCritieraVM, path, value);
            updateBankSearchCritieraVM(newBankSearchCritieraVM);
            updateShowBankNameRequiredMsg(false);
        },
        [bankSearchCritieraVM, viewModelService]
    );

    const updateBankName = useCallback(
        (value, path) => {
            updateShowBankNameRequiredMsg(false);
            writeValue(value, path);
        },
        [writeValue]
    );
    const onSort = (a, b) => {
        hightLight(selectRow);
        return DatatableUtil.sortString(a, b);
    };

    const onTableConfigPage = useCallback((obj) => {
        updateBankPageConfig(obj);
    }, [updateBankPageConfig]);

    useEffect(() => {
        updateShowTooManySearchResults(false);
    }, [bankSearchCritieraVM]);

    useEffect(() => {
        hightLight(selectRow);
    }, [selectRow, onTableConfigPage, bankPageConfig]);
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        exisitingAgencyMatchTable: {
            data: bankSearchValue,
            visible: !isLoading,
            onConfigChange: onTableConfigPage,
            config: bankPageConfig
        },
        bankName: {
            required: true,
            label: translator(messages.financialInstitutionName),
            onValueChange: updateBankName,
        },
        primaryAddressContainer: {
            useAuthenticationData,
            model: bankSearchCritieraVM,
            dataPath: 'primaryAddress',
            onAddressChange: writeValue,
            hideRequiredforQuote: true,
            unAutoFilled: {
                addressLine1: true,
                postalCode: false
            },
            hideFieldType: {
                addressType: true,
                pobox: false,
                addressLine1: true,
                addressLine2: true
            },
            unRequiredField: {
                country: true,
                county: true,
                city: true,
                state: true
            }
        },
        newQuoteHeader: {
            content: translator(messages.searchFinancialInstitution)
        },
        addNewBankButton: {
            visible: isSearched
        },
        agencyDiv: {
            visible: isSearched
        },
        bankNameRequiredNotification: {
            visible: showBankNameRequiredMsg,
            validationIssues: [{
                type: 'error',
                reason: translator(messages.financialInstitutionNameZipCodeRequired)
            }],
            scrollToIssues: false
        },
        searchTooManyNotification: {
            visible: showTooManySearchResults,
            validationIssues: [{
                type: 'error',
                reason: translator(messages.tooManyResult)
            }],
            scrollToIssues: false
        },
        tabelLoading: {
            visible: isLoading
        },
        bankColumn: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.publicID || item.rowIdPath, item.contactName)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getSelectedFn: getSelectedFn,
            onSearchBtnClick: handleSearch,
            onResetBtnClick: handleReset,
            onAddNewBankBtnClick: handleAddNewBank,
            onSort
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            addressinputcomponent: AddressInputComponent,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={bankSearchCritieraVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WALBankSearchComponent.propTypes = {
    bankSearchCritieraVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    updateShowBankDetail: PropTypes.shape({}).isRequired,
    selectRow: PropTypes.shape({}).isRequired,
    updateSelectRow: PropTypes.shape({}).isRequired,
    addNewBankFn: PropTypes.shape({}).isRequired
};
WALBankSearchComponent.defaultProps = {

};
export default WALBankSearchComponent;
