import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { WniDropdownSelect } from 'wni-common-base-components';
import { getFullAddressDisplayName } from "../util/AddressUtil";

function SelectAddressComponent(props) {
    const {
        lossLocationIndex,
        predefinedLossLocations, 
        onSelectAddressChange,
        countNewLocation
    } = props;

    const allLocations = predefinedLossLocations.map((location) => {
        const displayName = _.get(location, 'displayName');
        const publicId = _.get(location, 'publicID');
        if (_.isNil(displayName) && _.includes(publicId, 'Temp')) {
            return {
                code: location.publicID,
                name: getFullAddressDisplayName(location)
            }
        }
        return {
            code: location.publicID || `tempNew${countNewLocation}`,
            name: location.displayName || 'New Location'
        };
    });

    const availableLocations = useMemo(() => {
        if (allLocations.map(location => location.name).includes('New Location')) {
            return allLocations
        }
        return allLocations.concat([{ code: 'new', name: 'New Location' }]);
    }, [allLocations])
        

    const selectedAddress = availableLocations[lossLocationIndex];

    return (
        <WniDropdownSelect
            label="Select Address"
            availableValues={availableLocations}
            value={selectedAddress}
            labelPosition="left"
            onValueChange={onSelectAddressChange}
            required
            showRequired
        />
    );
}
SelectAddressComponent.propTypes = {
    isReadOnly: PropTypes.bool,
};

SelectAddressComponent.defaultTypes = {
    isReadOnly: false,
};

export default SelectAddressComponent;
