
import React from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import CPPModifiersPage from './CPPModifiersPage';

function CPPModifiersReadOnlyPage(props) {

    return (
        <CPPModifiersPage {...props} isReadOnly />
    );

}

CPPModifiersReadOnlyPage.propTypes = {
    ...wizardProps,
};

CPPModifiersReadOnlyPage.defaultProps = {
};

export default CPPModifiersReadOnlyPage;
