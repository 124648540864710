import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import {
    AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from '../AddressInputComponent/AddressVerifiedUtil';

import AddressInputComponent from '../AddressInputComponent/AddressInputComponent';
import metadata from './AccountHolderInputPopup.metadata.json5';
import messages from './AccountHolderInputPopup.messages';
import styles from './AccountHolderInputPopup.module.scss';

import { Button } from '@jutro/legacy/components';

function AccountHolderInputPopup(props) {
    const {
        // title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        accountHolder,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        accountHolderDataVM,
        useAuthenticationData
    } = props;
    const{
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation('AccountHolderInputPopup');
    const { authHeader } = useAuthenticationData || useAuthentication();
    const translator = useTranslator();
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isAddressFlag, updateAddressFlag] = useState(false);
    const [saveAccountHolder, updatesaveAccountHolder] = useState(accountHolder);

    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);


    const writeValue = useCallback((value, path) => {
        if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateAddressFlag(false);
        } else {
            const newSaveAccountHolder = _.clone(saveAccountHolder);
            _.set(newSaveAccountHolder, path, value);
            updatesaveAccountHolder(newSaveAccountHolder);
        }
        _.set(accountHolderDataVM, path, value);
    }, [accountHolderDataVM, saveAccountHolder, updateLookupValidation]);


    const handleSave = useCallback(
        async () => {
            onResolve(accountHolderDataVM.value);
        },
        [accountHolderDataVM, onResolve]
    );

    const getPrimaryPhoneType = useCallback(() => {
        const primaryPhoneType = _.get(accountHolderDataVM, 'primaryPhoneType.aspects.availableValues', []);
        return primaryPhoneType.map((primaryPhoneTypeList) => {
            return {
                name: translator({ id: primaryPhoneTypeList.name }),
                code: primaryPhoneTypeList.code
            };
        });
    }, [accountHolderDataVM, translator]);

    const overridePath = useCallback(() => {
        const primaryPhoneType = _.get(saveAccountHolder, 'primaryPhoneType');
        switch (primaryPhoneType) {
            case 'mobile':
                return 'cellNumber'
            case 'home':
                return 'homeNumber'
            case 'work':
                return 'workNumber'
            default:
                break;
        }
    }, [saveAccountHolder])

    const getSuffix = useCallback(() => {
        const suffixCode = _.get(accountHolderDataVM, 'suffix.aspects.availableValues', []);
        return suffixCode.map((availableSuffixs) => {
            return {
                // translator: ({ code: availableSuffixs.code }),
                name: translator({ id: availableSuffixs.name }),
                code: availableSuffixs.code
            };
        });
    }, [accountHolderDataVM, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        contactDetailsCard: {
            title: translator(messages.contactDetails)
        },
        primaryAddressCard: {
            title: translator(messages.primaryAddress)
        },
        primaryAddressContainer: {
            model: accountHolderDataVM,
            dataPath: 'primaryAddress',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true
            },
            useAuthenticationData,
            onValidate: setComponentValidation
        },
        primaryPhone: {
            path: overridePath(),
        },
        primaryPhoneType: {
            availableValues: getPrimaryPhoneType()
        },
        suffix: {
            availableValues: getSuffix()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            validationissuescomponent: ValidationIssuesComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }

    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={accountHolderDataVM}
                    onValidationChange={setComponentValidation}
                    onValueChange={writeValue}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={styles.cancelBtnMargin}>
                    {cancelBtnLabel || translator(messages.cancel)}
                </Button>
                <Button onClick={handleSave} disabled={!isComponentValid}>
                    {actionBtnLabel || translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

AccountHolderInputPopup.propTypes = {
    accountHolderDataVM: PropTypes.shape({}).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};

AccountHolderInputPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md'
};

export default AccountHolderInputPopup;
