
import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes, { object } from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DriverCoveragesUtil } from 'wni-portals-util-js';
import DriverSingleCoverage from './DriverSingleCoverage/DriverSingleCoverageComponent';
import message from './DriverCoveragesComponent.messages';

const DriverCoveragesComponent = (props) => {
    const {
        driver,
        onChangeClause,
        path,
        loadingClause,
        showErrors,
        onValidate,
        isReadOnly: readOnly
    } = props;

    const translator = useTranslator();
    const coverages = _.get(driver, 'coverages');

    useEffect(() => {

    }, []);

    const getPath = useCallback((p, changedValuePath) => {
        const pathToNormalise = _.isObject(changedValuePath)
            ? changedValuePath.model : changedValuePath;
        const normalisePath = pathToNormalise.replace(/\[/g, '[');
        const basePath = p.replace(/\.value/, '');
        return `${basePath}${normalisePath}`;
    }, []);

    const toMetadata = () => {
        if (_.isEmpty(coverages)) {
            return {
                id: 'noCoverage',
                type: 'element',
                component: 'p',
                content: translator(message.noCoverage)
            };
        }
        return coverages.map((clause, index) => {
            const clausePath = `[${index}]`;
            return {
                id: clause.publicID,
                type: 'field',
                component: 'DriverSingleCoverage',
                componentProps: {
                    clause: DriverCoveragesUtil.addNewOptionValOfSchedule(clause),
                    className: 'wniClauseContainer',
                    onChangeClause: onChangeClause,
                    path: getPath(path, clausePath),
                    loadingClause,
                    onValidate,
                    readOnly
                }
            };
        });
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        }
    };

    const readValue = (id, readValuePath) => {
        return readViewModelValue(
            toMetadata(),
            id,
            readValuePath,
            overrideProps
        );
    };

    const resolvers = {
        resolveComponentMap: {
            DriverSingleCoverage: DriverSingleCoverage
        }
    };

    return (
        <ViewModelForm
            uiProps={toMetadata()}
            model={coverages}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
};

DriverCoveragesComponent.propTypes = {
    driver: PropTypes.shape({}).isRequired,
    onChangeClause: PropTypes.func,
    path: PropTypes.string.isRequired,
    loadingClause: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isReadOnly: PropTypes.bool,
    ...DriverSingleCoverage.propTypes,
};

DriverCoveragesComponent.defaultProps = {
    onChangeClause: undefined,
    loadingClause: undefined,
    isReadOnly: false
};

export default DriverCoveragesComponent;
