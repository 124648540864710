import { defineMessages } from 'react-intl';

export default defineMessages({
    searchResults: {
        id: 'gateway.views.search.Search Results',
        defaultMessage: 'Search Results',
    },
    searching: {
        id: 'gateway.views.search.Searching...',
        defaultMessage: 'Searching...',
    },
    noResultsFoundFor: {
        id: 'ggateway.views.search.No results found for',
        defaultMessage: 'No results found for "{query}". Note: You can only search on valid policy and account ID\'s',
    },
    accountResults: {
        id: 'gateway.views.search.Account Results',
        defaultMessage: 'Accounts ({accountResultsNumber})',
    },
    policyResults: {
        id: 'gateway.views.search.Policy Results',
        defaultMessage: 'Policies ({policyResultsNumber})',
    }
});
