import { defineMessages } from 'react-intl';

export default defineMessages({
    person: {
        id: 'custom.gateway.views.new-quote.Person',
        defaultMessage: 'Person',
    },
    company: {
        id: 'custom.gateway.views.new-quote.Company',
        defaultMessage: 'Company',
    },
    contactTypePersonal: {
        id: 'custom.gateway.views.new-quote-account-search.ContactType.Personal',
        defaultMessage: 'Personal'
    },
    contactTypeCommercial: {
        id: 'custom.gateway.views.new-quote-account-search.ContactType.Commercial',
        defaultMessage: 'Commercial'
    },
});
