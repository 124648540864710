import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './PoliciesHomeDetails.metadata.json5';

function PoliciesHomeDetails(props) {
    const {
        model
    } = props;

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            }
        };
    }, []);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={model}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PoliciesHomeDetails.propTypes = {
    model: PropTypes.shape({}),
};
PoliciesHomeDetails.defaultProps = {
    model: {}
};
export default PoliciesHomeDetails;