import { defineMessages } from 'react-intl';

export default defineMessages({
    BackTOAgentsOnline: {
        id: 'gateway.directives.summary.Back to Account Details',
        defaultMessage: 'Back to Account Details'
    },
    BackTOAllQuotes: {
        id: 'gateway.directives.summary.Back To All Quotes',
        defaultMessage: 'Back to All Quotes'
    },
    BackToAccountSummary: {
        id: 'gateway.directives.JobSummary.Back to Account Summary',
        defaultMessage: 'Back to Account Summary'
    },
    backToAccountBillingAndPayment: {
        id: 'gateway.directives.JobSummary.Back To Account Billing And Payment',
        defaultMessage: 'Make a Payment'
    }
});
