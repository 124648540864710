import React from 'react'
import { FNOLHOThirdPartyLossDetailsPage } from 'wni-capability-fnol-ho-react'


const FNOLDPThirdPartyLossDetailsPage = (props) => {
    return <FNOLHOThirdPartyLossDetailsPage
        {...props}
    />
}

export default FNOLDPThirdPartyLossDetailsPage