import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glUnderwritingQuestionsService'), method, data, additionalHeaders);
}

export default class GLUnderwritingQuestionsService {

    static updateUnderwritingQuestions(data, authHeader) {
        return processSubmission('updateUnderwritingQuestions', [data.jobID, data.sessionUUID, data.dto], authHeader);
    }

}