import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLGLBusnessLocationPage } from 'wni-capability-fnol-gl-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ProductUtil } from 'wni-portals-util-react';

function FNOLIMBusnessLocationPage(props) {
    return <FNOLGLBusnessLocationPage {...props} LOB_NAME={ProductUtil.IM_LOB_NAME} />;
}

FNOLIMBusnessLocationPage.propTypes = wizardProps;
FNOLIMBusnessLocationPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLIMBusnessLocationPage));
