

const TempAddressId = 'TempAddressId'

let newAddressTempId = 0


export const getNewAddressTempId = () => {
    const res = `${TempAddressId}:${newAddressTempId}`
    newAddressTempId += 1
    return res
}

export const createNewLocationWithoutPublicID = () => {
    return {
        country: 'US' 
    }
}