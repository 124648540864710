import { defineMessages } from 'react-intl';

export default defineMessages({
    coveragesTitle: {
        "id": "quoteandbind.cp.wizard.step.commercialProperty page.Additional Coverages",
        "defaultMessage": "Additional Coverages"
    },
    addAdditionalCoveragesText: {
        "id": "quoteandbind.cp.wizard.step.commercialProperty page.Add Additional Coverage",
        "defaultMessage": "Add Additional Coverage"
    }
});