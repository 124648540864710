
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';

import messages from './TextEditConfirmComponent.messages';
import metadata from './TextEditConfirmComponent.metadata.json5';
import styles from './TextEditConfirmComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function TextEditConfirmComponent(props) {
    const {
        // model: dtoVM,
        // onValueChange,
        // showErrors,
        text,
        onTextChange,
        //
        editButtonLabel,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const [textContent, updateTextContent] = useState('');
    const [editMode, updateEditMode] = useState(false);


    useEffect(() => {
        updateTextContent(text);
    }, [text]);

    const onEdit = useCallback(async () => {
        updateEditMode(true);
    }, []);

    const onCancel = useCallback(async () => {
        updateTextContent(text);
        updateEditMode(false);
    }, [text]);

    const onConfirm = useCallback(async () => {
        if (textContent !== text) {
            await onTextChange(textContent);
        }
        updateEditMode(false);
    }, [text, textContent, onTextChange]);

    // const writeValue = (value, path) => {
    //     onValueChange(value, path);
    // };

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        currentTextField: {
            value: textContent,
            onValueChange: (val) => updateTextContent(val),
            readOnly: !editMode,
        },
        editButton: {
            visible: !editMode,
            // button label
            content: editButtonLabel,
        },
        confirmButton: {
            visible: editMode,
        },
        cancelButton: {
            visible: editMode,
        }

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onEdit,
            onCancel,
            onConfirm,
        },
        resolveComponentMap: {

        },
    };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
    // return (
    //     <ViewModelForm
    //         uiProps={metadata.componentContent}
    //         model={dtoVM}
    //         overrideProps={overrideProps}
    //         classNameMap={resolvers.resolveClassNameMap}
    //         callbackMap={resolvers.resolveCallbackMap}
    //         componentMap={resolvers.resolveComponentMap}
    //         // onModelChange={updateModelValue}
    //         resolveValue={readValue}
    //         onValueChange={writeValue}
    //         showErrors={showErrors}
    //     />
    // );
}

TextEditConfirmComponent.propTypes = {
    text: PropTypes.string,
    onTextChange: PropTypes.func,
    //
    editButtonLabel: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
    }),
    // showErrors: PropTypes.bool,
};

TextEditConfirmComponent.defaultProps = {
    text: '',
    onTextChange: _.noop,
    // showErrors: false,
    defaultMessage: messages.editButton,
};

export default TextEditConfirmComponent;
