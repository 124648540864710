import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniAccountContact'), method, data, additionalHeaders);
}

export default class WniAccountContactService {
    static saveContact(data, additionalHeaders) {
        return processSubmission(
            'saveContact',
            [data.jobID, data.sessionUUID, data.contact],
            additionalHeaders
        );
    }
}
