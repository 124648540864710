/* eslint-disable */
import _ from "lodash";

/**
 * Flattening UI Props Content
 * 
 * @function getFlattenedUiPropsContent
 * @memberof module:gw-portals-viewmodel-react
 * 
 * @param {Object} uiProps Content metadata or array of metadata
 * @returns {Object} Flattened uiProps
 */
export function getFlattenedUiPropsContent(uiProps) {
  if (uiProps.contentRepeat) {
    return uiProps;
  }

  if (!_.isArray(uiProps.content)) {
    return uiProps;
  }

  return uiProps.content.flatMap((item) => getFlattenedUiPropsContent(item));
}
