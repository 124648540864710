import React, {
    useContext, useCallback, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import LocationHeaderComponent from '../../components/LocationHeader/LocationHeaderComponent';

import LocationBuildingsComponent from '../../components/LocationBuildingsComponent/LocationBuildingsComponent';
import styles from './SuccessPage.module.scss';

import metadata from './SuccessPage.metadata.json5';
import './SuccessPage.messages';

const PATH_TO_LOCATIONS = 'lobData.businessOwners.coverables.locations.value';

const ACCORDION_CARDS = ['coverageAccordion', 'locationAccordionCard', 'contactAccordion'];

function sortLocation(submissionVM) {
    const locations = _.get(submissionVM, PATH_TO_LOCATIONS);
    const locationsWithSortedBuildings = locations.map((location) => {
        const loc = location;
        loc.buildings = _.sortBy(location.buildings, (building) => building.buildingNumber);
        return loc;
    });
    const sortedLocation = _.sortBy(
        locationsWithSortedBuildings,
        (location) => !location.isPrimary
    );

    _.set(submissionVM, PATH_TO_LOCATIONS, sortedLocation);
}

function renderCellContent(data, index, props) {
    const { path } = props;
    return _.get(data, path);
}

function renderCoverageAmount(row) {
    return _.isEmpty(row.amount) ? null : (
        <CurrencyValue amount={row.amount.amount} currency={row.amount.currency} showFractions />
    );
}

function renderLocationAccordionHeader(location, pathOfLocationToRender, locationIndex) {
    return (isOpen) => (
        <LocationHeaderComponent
            location={location}
            path={pathOfLocationToRender}
            locationIndex={locationIndex}
            isAccordionOpen={isOpen}
            isEditable={false}
        />
    );
}

const coverageOverrides = {
    coverageName: { renderCell: renderCellContent },
    coverageAmount: { renderCell: renderCoverageAmount }
};

function SuccessPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isPrinting, setPrintingState] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const { wizardData: submissionVM } = props;
    if (!isPageInitialized) {
        sortLocation(submissionVM);
    }

    useEffect(() => {
        setIsPageInitialized(true);
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const accountNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.accountNumber.value');
    }, [submissionVM]);

    const policyNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.policyNumber.value');
    }, [submissionVM]);

    const periodStartDate = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodStartDate.value');
    }, [submissionVM]);

    const periodEndDate = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodEndDate.value');
    }, [submissionVM]);

    const handlePrint = useCallback(() => {
        setPrintingState(true);
    }, []);

    const generateLocationOverrides = useCallback(() => {
        const locationsPath = 'lobData.businessOwners.coverables.locations';
        const locations = _.get(submissionVM, `${locationsPath}.value`, []);
        const overrides = locations.map((location, index) => {
            const overridePath = `${locationsPath}.children[${index}]`;
            return {
                [`locationAccordionCard${index}`]: {
                    renderHeader: renderLocationAccordionHeader(location, `${overridePath}.value`, index)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [submissionVM]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint
        },
        resolveComponentMap: {
            locationbuildingscomponent: LocationBuildingsComponent
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        accountNumberLink: {
            to: `/accounts/${accountNumber}/summary`
        },
        policyNumberLink: {
            to: `/policies/${policyNumber}/summary`
        },
        effectiveDate: {
            value: {
                startDate: periodStartDate,
                endDate: periodEndDate
            }
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined
        },
        ...coverageOverrides,
        ...generateLocationOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

SuccessPage.propTypes = wizardProps;
export default SuccessPage;
