import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import _ from 'lodash';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    ErrorsAndWarningsUtil,
    OOSUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { JobUtil, LocalDateUtil } from '@xengage/gw-portals-util-js';
import { useAORValidationData } from 'wni-portals-util-react';
import moment from 'moment';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    WniEndorsementService,
    WniPAPolicyChangeService,
    WniCustomEndorsementService,
    WniCommonChangeService,
} from 'wni-capability-policychange';
// import {
//     AgencyAndAgentInfoComponent,
// } from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, ActionListComponent, useWniModal } from 'wni-components-platform-react';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import { PolicyChangeStartMessages as policyChangePageMessages } from 'wni-platform-translations';


import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

import {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
} from './DPPolicyChangeStartPage.config';
import metadata from './DPPolicyChangeStartPage.metadata.json5';

/**
 * Original design from PAPolicyChangeStartPage
 * @param {object} props 
 * @returns {object}
 */
function DPPolicyChangeStartPage(props) {
    const modalApi = useWniModal();
    // const { EndorsementService } = useDependencies('EndorsementService');
    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        wizardIssues: wizardLevelValidationIssues = [],
        updateWizardIssues: updateWizardLevelValidationIssues,
    } = useContext(WizardErrorContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);
    const {
        isComponentValid,
        onValidate,
    } = useValidation('DPPolicyChangeStartPage');
    const {
        updateWizardData,
        updateWizardSnapshot,
        wizardData: submissionVM,
        updateWizardReadOnly,
        // history,
        updateWizardPageData,
        wizardPageData,
        // =======================
    } = props;
    const { authHeader } = useAuthentication();
    const [showErrors, updateShowErrors] = useState(false);
    // const [warningMessage, updateWarningMessage] = useState();
    // const [showDescription, updateShowDescription] = useState('');
    const [showLoader, updateShowLoader] = useState(false);
    const [pageLevelValidationIssues, updatePageLevelValidationIssues] = useState([]);
    // const [frontendValidationIssuesWarning, updateFrontendValidationIssuesWarning] = useState([]);
    // const [backendValidationIssuesWarning, updateBackendValidationIssuesWarning] = useState([]);
    // const [setEffectiveDateOfChange, updateEffectiveDateofChange] = useState(true);
    const [policyChangeStartDate, updatePolicyChangeStartDate] =useState('')
    const translator = useTranslator();

    const history = useHistory();
    const location = useLocation();
    const { validateAORAccess } = useAORValidationData();

    const policyChangeSource = wizardPageData[WizardConstants.policyChangeSource];

    const {
        // jobID, 
        // sessionUUID,
        baseData: {
            effectiveDate_Ext: effectiveDate,
            productCode,
            producerCode_Ext: producerCode,
        },
        changeData: {
            policyNumber,
            oossliceDates_Ext: oossliceDates,
            minimumEffectiveDate,
            maximumEffectiveDate,
            coverageTerms,
        }
    } = submissionVM.value;

    // const [policyChangeStartDate, updatePolicyChangeStartDate] = useState(LocalDateUtil.today);

    useEffect(() => {
        validateAORAccess(producerCode)
    }, []);

    useEffect(() => {
        if (policyChangeSource === 'cancellation') {
            const defaultEffectiveDate = moment(LocalDateUtil.today()).toDate();
            const termPolicyChangeStartDate = moment(defaultEffectiveDate).toDate();
            const termMinimumEffectiveDate = moment(minimumEffectiveDate).toDate();
            const termMaximumEffectiveDate = moment(maximumEffectiveDate).toDate()
            const isNotSetDefaultEffectiveDate =  termPolicyChangeStartDate < termMinimumEffectiveDate || termPolicyChangeStartDate > termMaximumEffectiveDate
            if(policyChangeStartDate === '' && !isNotSetDefaultEffectiveDate) {
                updatePolicyChangeStartDate(LocalDateUtil.today())
            }
        }
    }, [maximumEffectiveDate, minimumEffectiveDate, policyChangeSource, policyChangeStartDate]);

    const handleEffectiveDateChange = (newEffectiveDate) => {
        _.set(submissionVM, 'baseData.effectiveDate_Ext', newEffectiveDate);
        updateWizardData(submissionVM);
        updatePolicyChangeStartDate(newEffectiveDate);
    };

    const getCurrentCoverageTerm = useCallback(() => {
        const termOfSelectedEffectiveDate = coverageTerms.find((coverageTerm) => {
            const selectedEffectiveDate = moment(effectiveDate).toDate();
            const termStartDate = moment(coverageTerm.termStartDate).toDate();
            const termEndDate = moment(coverageTerm.termEndDate).toDate();
            const effectiveDateIsValid = moment(selectedEffectiveDate, 'YYYY-MM-DD').isValid();
            if (effectiveDateIsValid === false
                    || (selectedEffectiveDate >= termStartDate
                        && selectedEffectiveDate < termEndDate)
            ) {
                return true;
            }
            return false;
        });
        return termOfSelectedEffectiveDate;
    }, [effectiveDate, coverageTerms]);

    const refreshOOSWarningInWizardPageData = (OOSSliceDatesStringArray) => {

        const errorsAndWarningsWithoutOOS = wizardLevelValidationIssues.filter((issue) => !OOSUtil.isOOSSliceDateWarning(issue))
        const ossSliceDateWarnings = OOSUtil.getOOSSliceDatesWarning(OOSSliceDatesStringArray, translator);

        const newWizardErrorsAndWarnings = [
            ...errorsAndWarningsWithoutOOS,
            ...ossSliceDateWarnings,
        ]
        
        updateWizardLevelValidationIssues(newWizardErrorsAndWarnings);
    }

    const createPolicyChange = async (landingPageID) => {
        setLoadingMask(false)
        const OOSSliceDatesStringArray = OOSUtil.getOOSSliceDatesString(oossliceDates, policyChangeStartDate);
        refreshOOSWarningInWizardPageData(OOSSliceDatesStringArray);
        if (!_.isEmpty(OOSSliceDatesStringArray)) {
            const modalResponse = await modalApi.showConfirm({
                title: translator(
                    policyChangePageMessages.OOSSliceDatesPopupMessage,
                    { OOSSliceDates: OOSSliceDatesStringArray.join(', ') }
                ),
                message: '',
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel'
            }).catch(_.noop);   // for 'close' operation
            if (modalResponse !== PortalConstants.MODAL_DIALOG_RESULT_CONFIRM) {
                return;
            }
        }
        setLoadingMask(true)
        
        const policyChangeWizardDataDTO = await WniCommonChangeService.createPolicyChange({
            policyNumber,
            effectiveDate: policyChangeStartDate,
        }, authHeader);
        const {
            jobID: policyChangeJobID,
            sessionUUID,
            errorsAndWarnings,
        } = policyChangeWizardDataDTO;

        const hasError = ErrorsAndWarningsUtil.hasValidationError(errorsAndWarnings)
        updatePageLevelValidationIssues(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings))
        if (hasError) {
            setLoadingMask(false);
            return
        }
        // Replace with the following service for better performance
        // const policyChangeJobID = await WniCommonChangeService.createPolicyChangeAndGetJobID({
        //     policyNumber,
        //     effectiveDate: policyChangeStartDate,
        // }, authHeader);
        
        if (_.isNil(policyChangeJobID)) {
            return;    
        }

        await WniCustomEndorsementService.addRecentlyViewedPolicyChange(policyChangeJobID, authHeader);

        // 20221-11-02: Seems like this solution only introduces other issue  -- especially for Page Jump.
        // Let's put it on hold for now, and try to come up with some other options. 
        // e.g.: 1) tweak location state with window.history.replaceState(), 2) utilize location storage, etc.
        // WizardPageJumpUtil.goToPolicyChangeWizard(history, {
        //     productCode, 
        //     policyNumber,
        //     jobID: policyChangeJobID,
        //     landingPageID,
        // });
        

        const newActiveQuotes = await WniCommonChangeService.getActiveQuotes(policyChangeJobID, sessionUUID, authHeader);
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: newActiveQuotes,
            [WizardConstants.landingPage]: landingPageID,
        });

        submissionVM.value = policyChangeWizardDataDTO;
        // updateWizardData(submissionVM);
        updateWizardSnapshot(submissionVM);

        //
        // history.replace(location, {
        //     ...location.state,
        //     jobID: policyChangeWizardDataDTO.jobID,
        // });

        // ref: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
        // window.history.replaceState({
        //     ...location.state,
        //     jobNumber: policyChangeWizardDataDTO.jobID,
        // }, '');
        

        updateWizardReadOnly(false);
        setLoadingMask(false);
    };

    // ======================

    const pageOnNext = async () => {
        // ==================to be removed BEGIN ====================
        // updateWizardPageData({
        //     [WizardConstants.landingPage]: 'HOHouseholdInfoPage',
        // });
        // ==================to be removed END ====================
        await createPolicyChange(FIRST_CHANGE_WIZARD_PAGE);
        return false;
    };
    const getPageJumpAction = (wizardPageName) => {
        if (notBetweenEffectAndExpire()) {
            return null;
        };
        return async () => {
            // ==================to be removed BEGIN ====================
            // updateWizardPageData({
            //     [WizardConstants.landingPage]: wizardPageName
            // })
            // ==================to be removed END ====================
            await createPolicyChange(wizardPageName);
        };
    };

    // To be refactored: extract page names into *.messages.js
    const getDefaultChangePageJumpList = ({ policyChangeSource, getPageJumpAction }) => {
        // let retval;
        // if (policyChangeSource === 'cancellation') {
        //     retval = [{
        //         name: 'Mailing Address',
        //         action: getPageJumpAction('HOHouseholdInfoPage'),
        //     }];
        // } else {
        const retval = getChangePageJumpList({ policyChangeSource, getPageJumpAction });
        // }
        return retval;
    };

    // ==================================
    const selectedCoverageTerm = getCurrentCoverageTerm();

    const notBetweenEffectAndExpire = () => {
        if (policyChangeStartDate) {
            const termPolicyChangeStartDate = moment(policyChangeStartDate).toDate();
            const termMinimumEffectiveDate = moment(minimumEffectiveDate).toDate();
            const termMaximumEffectiveDate = moment(maximumEffectiveDate).toDate();
            if (termPolicyChangeStartDate < termMinimumEffectiveDate 
                || termPolicyChangeStartDate > termMaximumEffectiveDate) {
                return true;
            }
            return false;
        }
        return true;  
    };


    // ====================================================
    const pageJumpActions = getDefaultChangePageJumpList({ policyChangeSource, getPageJumpAction });
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        cancellationMessage: {
            visible: policyChangeSource === 'cancellation'
        },
        effectiveDate: {
            // value: loadCurrentEffectiveDateAsBlank(),
            value: policyChangeStartDate,
            onValueChange: handleEffectiveDateChange,
            // onValueChange: updatePolicyChangeStartDate,
            minDate: minimumEffectiveDate, // _.get(submissionVM, 'value.baseData.minimumEffectiveDate'),
            maxDate: maximumEffectiveDate, // _.get(submissionVM, 'value.baseData.maximumEffectiveDate_Ext'),
            showErrors,
        },
        policyEffectiveDate: {
            value: _.get(selectedCoverageTerm, 'termStartDate', null),
            showErrors
        },
        policyExpirationDate: {
            value: _.get(selectedCoverageTerm, 'termEndDate', null),
            showErrors
        },
        dynamicInlineNotificationContainer: {
            visible: false,
        },
        pageJumpList: {
            actions: pageJumpActions,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleEffectiveDateChange: handleEffectiveDateChange,
            // handleDescription: handleDescription,
        },
        resolveComponentMap: {
            // agencyagentinfo: AgencyAndAgentInfoComponent,
            validationissuescomponent: ValidationIssuesComponent,
            // paunderwritingquestionsinput: PAUnderwritingQuestionsInput,
            // questionset: QuestionSetComponent,
            actionlist: ActionListComponent,
        },
    };

    return (
        <WizardPage
            onNext={pageOnNext}
            // disableNext={!isComponentValid}
            showPrevious={false}
            disableNext={showLoader || !isComponentValid || notBetweenEffectAndExpire()}
            pageLevelValidationIssues={pageLevelValidationIssues}
        >
            {
                !showLoader && (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

DPPolicyChangeStartPage.propTypes = {
    ...wizardProps,
};

DPPolicyChangeStartPage.defaultProps = {
}
export default DPPolicyChangeStartPage;
