import _ from 'lodash';

function VehicleIncident(claim, ClaimExtension, vehicleIncident) {
    Object.defineProperty(this, 'claim', {
        enumerable: false,
        value: claim
    });
    Object.defineProperty(this, 'ClaimExtension', {
        enumerable: false,
        value: ClaimExtension
    });
    if (vehicleIncident) {
        _.extend(this, vehicleIncident);
    } else {
        this.driver = null;
        this.vehicle = null;
        this.passengers = [];
    }
}
VehicleIncident.prototype.availableVehicles = function (ClaimExtension, vehicle) {
    return ClaimExtension.availableVehicles(vehicle);
};
VehicleIncident.prototype.availableDrivers = function (ClaimExtension, driver) {
    return ClaimExtension.availableDrivers(driver);
};
VehicleIncident.prototype.addPassenger = function (passenger, claim) {
    let newPassenger;
    if (!passenger) {
        newPassenger = claim.createContact();
    } else {
        newPassenger = passenger;
    }
    this.passengers.unshift(newPassenger);
};
VehicleIncident.prototype.setNewDriver = function (claim) {
    this.driver = claim.createContact();
};
VehicleIncident.prototype.setNewVehicle = function (claim) {
    this.vehicle = claim.lobs.personalAuto.createVehicle();
};
VehicleIncident.prototype.removePassenger = function (p) {
    const idx = this.passengers.indexOf(p);
    this.passengers.splice(idx, 1);
};
VehicleIncident.prototype.getAvailablePassengers = function (ClaimExtension) {
    return ClaimExtension.availablePassengers();
};

export default VehicleIncident;
