import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    Redirect,
    Prompt,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import _ from 'lodash';

import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WniUrlUtil } from 'wni-portals-util-js';
import { useWizardPageData } from 'wni-portals-util-react';
import { messages as gwCommonMessages } from '@xengage/gw-platform-translations';

import { PortalConstants, WizardConstants } from 'wni-portals-config-js';

import { WniPlatformMessages as wniCommonMessages } from 'wni-platform-translations';
import { CommonTransactionWizard } from 'wni-portals-wizard-react';

import { WizardContext } from './WizardContext';

import { wizardStepProps } from './prop-types/wizardPropTypes';
import useWizardErrors from './hooks/useWizardErrors';
import WizardRoutes from './WizardRoutes';
import useWizardData from './hooks/useWizardData';
import useWizardSteps from './hooks/useWizardSteps';
import { PromptService } from '@jutro/legacy/router';

/**
 * This component is here to serve following purposes:
 * 1, as an alias to CommonTransactionWizard
 * 2, fpor backward compatibility 
 * 
 * Note: Do not introduce an changes into this component.
 * Update CommonTransactionWizard directly if there is need.
 * @param {object} props 
 * @returns {object}
 */
function Wizard(props) {

    return <CommonTransactionWizard {...props} />;
}

Wizard.propTypes = CommonTransactionWizard.propTypes;

Wizard.defaultProps = CommonTransactionWizard.defaultProps;

// export default withRouter(Wizard);
export default Wizard;