import {
    useEffect
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved


function AgentsOnlineNewAccountRouting(props) {
    const { match, history } = props;
    const { params: { AOEffectiveDate, productSelected, baseState } } = match;

    useEffect(() => {
        const targetURL = `/new-quote`;
        const productSelectedList = productSelected.split('+')
        const state = {
            isNewQuote: true,
            AOEffectiveDate,
            productSelectedList,
            baseState
        };

        history.push(targetURL, state);
    }, [baseState, AOEffectiveDate, history, match, productSelected]);

    return (
        null
    );
}

AgentsOnlineNewAccountRouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withRouter(AgentsOnlineNewAccountRouting);
