import React from "react";
import _ from 'lodash';
import ProtectionComponent from "wni-capability-quoteandbind-ho-react/pages/HOResidence/Component/ProtectionComponent/ProtectionComponent";
import ResidenceUtil from 'wni-capability-quoteandbind-ho-react/pages/HOResidence/util/ResidenceUtil';

function DPProtectionComponent(props) {
    const {
        launchRCTExpressCallFlag,
        originalProtectionVM
    } = props;

    const { burglarAlarmPrefix } = ResidenceUtil.residenceConstants;
    const { fireAlarmPrefix } = ResidenceUtil.residenceConstants;

    const isNoValue = (val) => {
        return (_.isNil(val) || _.isEmpty(val)) && val !== true && val !== false
    }

    const getHomeMonitoringSystemsValue = () => {
        const homeMonitoringSystems = _.get(originalProtectionVM.value, 'homeMonitoringSystems');
        const burglarAlarm = _.get(originalProtectionVM.value, 'burglarAlarmType');
        const fireAlarm = _.get(originalProtectionVM.value, 'fireAlarmType');
        const burglarAlarmId = `${burglarAlarmPrefix}${burglarAlarm}`;
        const fireAlarmId = `${fireAlarmPrefix}${fireAlarm}`;

        if (!_.includes(homeMonitoringSystems, burglarAlarmId) && !_.isEmpty(burglarAlarm) && burglarAlarm !== 'none') {
            homeMonitoringSystems.push(burglarAlarmId);
        }
        if (!_.includes(homeMonitoringSystems, fireAlarmId) && !_.isEmpty(fireAlarm) && fireAlarm !== 'none') {
            homeMonitoringSystems.push(fireAlarmId);
        }

        return homeMonitoringSystems;
    };

    const DPOverrideProps = {
        homeMonitoringSystems: {
            // readOnly: launchRCTExpressCallFlag && !isNoValue(getHomeMonitoringSystemsValue())
        }
    }

    return <ProtectionComponent
        {...props}
        DPOverrideProps={DPOverrideProps}
    />
}

DPProtectionComponent.propTypes = ProtectionComponent.propTypes;
DPProtectionComponent.defaultProps = ProtectionComponent.defaultProps;

export default DPProtectionComponent;