
import React from 'react';


import CPPPolicyDetailsChangePage  from './CPPPolicyDetailsChangePage';

function CPPPolicyDetailsChangeReadOnlyPage(props) {

    return (
        <CPPPolicyDetailsChangePage 
        {...props} 
        isReadOnly/>
    );

}

CPPPolicyDetailsChangeReadOnlyPage.propTypes = CPPPolicyDetailsChangePage.propTypes;
CPPPolicyDetailsChangeReadOnlyPage.defaultProps = CPPPolicyDetailsChangePage.defaultProps;

export default CPPPolicyDetailsChangeReadOnlyPage;