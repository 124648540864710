import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody } from '@jutro/components';
import { GLExposureService } from 'wni-capability-quoteandbind-gl';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './SearchModal.metadata.json5';

import { Button } from '@jutro/legacy/components';

const pageConfig = {
    page: 0,
    pageSize: 20
};
function SearchModal(props) {
    const modalApi = useWniModal();
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        title,
        translator,
        publicID,
        initData = {},
        fieldItem : {
            propertyName
        } = {},
        useDependenciesData,
        handleSearch = _.noop,
        handleSelect = _.noop
    } = props;
    const { tableColumnNames = [], tableColumnLabels, classCodeTable} = initData;
    const { loadingMask: { setLoadingMask } } = useDependenciesData;

    const [searchData, updateSearchData] = useState({});
    const [tableData, updateTableData] = useState(classCodeTable);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);

    useEffect(() => {
        const newSearchData = {};
        tableColumnNames.forEach((item, index) => {
            _.set(newSearchData, `criteria${index + 1}`, initData[`criteria${index + 1}`])
        });
        updateSearchData(newSearchData)
    }, []);

    const onSearch = async() => {
        setLoadingMask(true);
        const res = await handleSearch(propertyName, searchData, false);
        updateTablePageConfig(pageConfig);
        updateTableData(res.classCodeTable);
        setLoadingMask(false);
    };

    const onReset = () => {
        updateTablePageConfig(pageConfig);
        updateTableData([]);
    };

    const onSelect = async(item, index) => {
        setLoadingMask(true);
        const res = await handleSelect(propertyName, item);
        setLoadingMask(false);
        onResolve(res);
    };
    
    const renderSearchFields = () => {
        return tableColumnNames.map((item, index) => {
            return {
                id: item,
                type: 'field',
                component: 'WniInputText',
                componentProps: {
                    labelPosition: 'left',
                    label: tableColumnLabels[item],
                    path: `criteria${index + 1}`
                }
            }
        })
    };

    const renderSelectColumn = (item, index) => {
        return <Button onClick={() => onSelect(item, index)} >Select</Button>
    };

    const renderTableContent = () => {
        const selectedColumn = [
            {
                id: `selectedColumn`,
                type: 'container',
                component: 'displaycolumn',
                componentProps: {
                    sortable: false,
                    renderCell: renderSelectColumn,
                    width: '85'
                },
            },
        ];
        const displayColumns = tableColumnNames.map((item, index) => {
            return {
                id: `${item}Column`,
                type: 'element',
                component: 'displaycolumn',
                componentProps: {
                    header: tableColumnLabels[item],
                    path: item,
                    sortable: false,
                    columnProportion: 1
                }
            }
        });
        return selectedColumn.concat(displayColumns)
    };
    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };
    const writeValue = (value, path) => {
        const newSearchData = _.cloneDeep(searchData);
        _.set(newSearchData, path, value);
        updateSearchData(newSearchData)
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        searchBar: {
            content: renderSearchFields()
        },
        resultTable: {
            data: tableData,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [5, 10, 15, 20],
            content: renderTableContent()
        }
        
    };
    const resolvers = {
        resolveCallbackMap: {
            onSearch,
            onReset
        },
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={title} onClose={onReject} />
            <ModalBody id="searchModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={searchData}
                    onValueChange={writeValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
        </ModalNext>
    );
}

SearchModal.propTypes = {};
SearchModal.defaultProps = {
    size: 'lg',
};
export default SearchModal;
