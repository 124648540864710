import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { CUUnderlyingService } from 'wni-capability-quoteandbind-cu';
import { useWniModal } from 'wni-components-platform-react';
import {
    WniProductsUtil,
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import WizardPage from '../../templates/CUWizardPage';

import metadata from './CUUnderlyingPage.metadata.json5';
import messages from './CUUnderlyingPage.messages';
import UnderlyingPolicy from './UnderlyingPolicy/UnderlyingPolicy';

const {
    CU_PRODUCT_CODE,
    getLobName
} = WniProductsUtil;

const LOB_NAME = getLobName(CU_PRODUCT_CODE);

const UNDERLYING_PATH = `lobData.${LOB_NAME}.coverables.cuunderlying`;

function CUUnderlyingPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        resetWizardDataToSnapshot,
        markFollowingWizardStepsUnvisited,
        currentStep,
        isReadOnly = false,
        isPolicyChange
    } = props;

    const {
        jobID, 
        sessionUUID,
        baseData: {
            accountNumber
        }
    } = submissionVM.value;

    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);

    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CUUnderlyingPage');
    
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [showDetails, updateShowDetails] = useState({
        internalPolicies: false,
        externalPolicies: false
    });

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [invalidFields]);

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const findItemVMValid = (vm) => {
        const newVm = viewModelService.clone(vm);
        _.set(newVm, 'isUpdate', true);
        return _.get(newVm, 'aspects.valid') && _.get(newVm, 'aspects.subtreeValid')
    };

    const allDataValid = () => {
        const allDataVMs = _.get(submissionVM, `${UNDERLYING_PATH}.externalPolicies.children`);
        return allDataVMs.every((vm) => findItemVMValid(vm));
    };

    const onPageNext = async () => {
        if(isReadOnly) {
            return submissionVM;
        };

        if(!isComponentValid) {
            handleValidation();
            return false
        };
        setLoadingMask(true);
        const res = await CUUnderlyingService.onPageNext(jobID, sessionUUID, authHeader);
        setLoadingMask(false);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        
        return submissionVM;
    };

    const showUploadAlert = (title, message, status) => {
        modalApi.showAlert({
            title: title,
            message: message,
            status: status,
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(() => {
            _.noop();
        });
    };

    const uploadfile = async(file) => {
        setLoadingMask(true);
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: "PD_Ext",
            securityType: 'unrestricted',
            status: 'approved',
            jobNumber: jobID,
            name: file.name,
            mimeType: file.type,
            sessionID: await GatewayDocumentService.generateUploadToken(authHeader),
        };
        try{
            await GatewayDocumentService.uploadDocument(file, documentMetaDataTemplate, authHeader);
            setLoadingMask(false);
            showUploadAlert(messages.uploadSuccess, messages.uploadSuccessInfo, 'success');
        }catch(error){
            setLoadingMask(false);
            showUploadAlert(messages.uploadError, messages.uploadErrorInfo, 'error');
        }
    }

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardData(newSubmissionVM);
    };

    const updateWizardSnapshotWhileSetPeriodStatus = (newSubmissionVM) => {
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardSnapshot(newSubmissionVM);
    };

    const showPageFooter = _.every(showDetails, ((item) => !item));
    const overrideProps = {
        '@field': {
            labelPosition: "left",
        },
        '@action': {
            isReadOnly,
            submissionVM,
            UNDERLYING_PATH,
            extendProps: { accountNumber, jobID, sessionUUID, authHeader },
            showErrors,
            updateShowErrors,
            findItemVMValid,
            onValidate,
            handleValidation,
            updateWizardData: updateWizardDataWhileSetPeriodStatus,
            updateWizardSnapshot: updateWizardSnapshotWhileSetPeriodStatus,
            resetWizardDataToSnapshot,
            generateValidationIssues,
            updateShowDetails
        },
        uploadDoc: {
            // disabled: isReadOnly,
            readOnly: isReadOnly
        }
       
    };

    const resolvers = {
        resolveCallbackMap: {
            uploadfile
        },
        resolveComponentMap: {
            underlyingpolicy: UnderlyingPolicy
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            disableNext={!allDataValid()}
            showNext={showPageFooter}
            showPrevious={showPageFooter}
            showCancel={showPageFooter}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CUUnderlyingPage.propTypes = WizardPage.propTypes;
CUUnderlyingPage.defaultProps = WizardPage.defaultProps;
export default CUUnderlyingPage;