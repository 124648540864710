import React, { useCallback, useState, useEffect } from 'react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import messages from './DriverAssignmentGuidelineComponent.messages';
import styles from './DriverAssignmentGuidelineComponent.module.scss';

/**
 * Component used to show guidelines for assignment
 * @param {object} props
 * @returns {object} React Component
 */
const DriverAssignmentGuidelineComponent = (props) => {
    const {
        assignmentVMs,
        driverAgeMap,
        showFurnishedGuideline
    } = props;

    const translator = useTranslator();

    const [showStatic, updateShowStatic] = useState(false);
    const [showYouth, updateShowYouth] = useState(false);

    const excessVehicleWithNoPrimaryOperator = useCallback(
        (vms) => {
            return vms.some(
                (elt) => elt.value.excessVehicle_Ext && _.isNil(elt.value.driverID)
            );
        },
        []
    );

    useEffect(() => {
        if (!_.isEmpty(assignmentVMs)) {
            const driverCount = Object.keys(driverAgeMap).length;
            const driverSet = new Set();
            assignmentVMs.filter((vm) => !_.isEmpty(vm.value.assignment_Ext)).forEach((vm) => {
                driverSet.add(vm.value.driverID);
            });
            if (
                driverCount > driverSet.size ||
                excessVehicleWithNoPrimaryOperator(assignmentVMs)
            ) {
                // not all driver assigned
                updateShowStatic(true);
            }

            const youthCount = Object.values(driverAgeMap).filter((age) => age < 25).length;
            if (youthCount > 1) {
                updateShowYouth(true);
            }
        }
    }, [assignmentVMs, driverAgeMap]);

    const getStaticMessages = useCallback(() => {
        if (showStatic) {
            const line1 = translator(messages.staticLine1);
            const line2 = translator(messages.staticLine2);
            const line3 = translator(messages.staticLine3);
            const line4 = translator(messages.staticLine4);
            const array = [line1, line2, line3, line4];
            return (
                <ul className={styles.staticGuideline}>
                    {array.map((line) => <li>{line}</li>)}
                </ul>
            );
        }
        return '';
    }, [showStatic, translator]);

    const getDynamicMessages = useCallback(() => {
        return (
            <ul>
                {showYouth ? <li>{translator(messages.youthful)}</li> : null}
                {showFurnishedGuideline ? <li>{translator(messages.furnished)}</li> : null}
            </ul>
        );
    }, [showFurnishedGuideline, showYouth, translator]);


    const getGuidelines = useCallback(() => {
        if (showStatic || showYouth || showFurnishedGuideline) {
            return (
                <div>
                    <h4>{translator(messages.title)}</h4>
                    {getStaticMessages()}
                    {getDynamicMessages()}
                </div>
            );
        }
        return null;
    }, [showFurnishedGuideline, showStatic, showYouth, translator]);

    return (
        <div>
            {getGuidelines()}
        </div>
    );
};

DriverAssignmentGuidelineComponent.propTypes = {
    assignmentVMs: PropTypes.shape([]).isRequired,
    driverAgeMap: PropTypes.shape({}).isRequired,
    showFurnishedGuideline: PropTypes.bool.isRequired
};

DriverAssignmentGuidelineComponent.defaultProps = {
};

export default DriverAssignmentGuidelineComponent;
