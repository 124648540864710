import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCoveragesPopupTitle: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Additional Coverages",
        "defaultMessage": "Additional Coverages"
    },
    additionalInsuredsCoveragesPopupTitle: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Additional Insureds Coverages",
        "defaultMessage": "Additional Insureds"
    },
    exclusionsAndConditionsPopupTitle: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Exclusions & Conditions",
        "defaultMessage": "Exclusions & Conditions"
    },
    coveragesPopupTitle: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Coverages",
        "defaultMessage": "Coverages"
    },
    SublineAdditionalCoverageCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Subline Additional Coverages",
        "defaultMessage": "Subline Additional Coverages"
    },
    SublineAdditionalInsuredsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Subline Additional Insureds",
        "defaultMessage": "Subline Additional Insureds"
    },
    SublineConditionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Subline Conditions",
        "defaultMessage": "Subline Conditions"
    },
    SublineExclusionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Subline Exclusions",
        "defaultMessage": "Subline Exclusions"
    },
    ExposureAdditionalCoverageCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Exposure Additional Coverages",
        "defaultMessage": "Exposure Additional Coverages"
    },
    ExposureAdditionalInsuredsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Exposure Additional Insureds",
        "defaultMessage": "Exposure Additional Insureds"
    },
    ExposureConditionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Exposure Conditions",
        "defaultMessage": "Exposure Conditions"
    },
    ExposureExclusionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Exposure Exclusions",
        "defaultMessage": "Exposure Exclusions"
    },
    LocationAdditionalCoverageCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Location Additional Coverages",
        "defaultMessage": "Location Additional Coverages"
    },
    LocationAdditionalInsuredsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Location Additional Insureds",
        "defaultMessage": "Location Additional Insureds"
    },
    LocationConditionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Location Conditions",
        "defaultMessage": "Location Conditions"
    },
    LocationExclusionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.Location Exclusions",
        "defaultMessage": "Location Exclusions"
    },
    StateSpecificAdditionalCoverageCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.StateSpecific Additional Coverages",
        "defaultMessage": "State-Specific Additional Coverages"
    },
    StateSpecificAdditionalInsuredsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.StateSpecific Additional Insureds",
        "defaultMessage": "State-Specific Additional Insureds"
    },
    StateSpecificConditionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.StateSpecific Conditions",
        "defaultMessage": "State-Specific Conditions"
    },
    StateSpecificExclusionsCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.StateSpecific Exclusions",
        "defaultMessage": "State-Specific Exclusions"
    },
    AllCoveragesCategory: {
        "id": "wni.common.wizard.generalLiability.coveragePopup.All",
        "defaultMessage": "All"
    }
});