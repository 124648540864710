import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCoveragesTitle: {
        "id": "quoteandbind.gl.wizard.step.exposures page.Additional Coverages.title",
        "defaultMessage": "Additional Coverages"
    },
    addAdditionalCoveragesActionText: {
        "id": "quoteandbind.gl.wizard.step.exposures page.Additional Coverages.add coverages",
        "defaultMessage": "Add Coverages"
    }
})