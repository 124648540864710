import React, {
    useState,
    useContext,
}from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './SingleInjuryIncident.metadata.json5'
import messages from './SingleInjuryIncident.message'
import { createNewPersonWithoutPublicID } from '../../util/NewContactUtil';
import { Button } from '@jutro/legacy/components';

const AddNewPerson = 'AddNewPerson'

const SingleInjuryIncident = (props) => {
    const {
        originInjuryIncident,
        editingInjuryIncidentIndex,
        onPopupCancel,
        onPopupSave,
        relatedPersonContacts
    } = props

    const translator = useTranslator()

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SingleInjuryIncidentPopup')

    const [showErrors, setShowErrors] = useState(false)
    const [injured, setInjured] = useState(() => {
        return relatedPersonContacts.find((contact) => contact.publicID === originInjuryIncident.injuredContactPublicID)
    })
    const [injuryIncidentVM, setInjuryIncidentVM] = useState(() => viewModelService.create(
            originInjuryIncident,
            'cc',
            'wni.edge.capabilities.claim.lob.shared.incidents.dto.WniInjuryIncidentDTO'
        ))

    const injuredContactPublicID = _.get(injuryIncidentVM.value, 'injuredContactPublicID')

    const writeValue = (value, path) => {
        const newInjuryIncidentVM = _.clone(injuryIncidentVM);
        _.set(newInjuryIncidentVM.value, path, value);
        setInjuryIncidentVM(newInjuryIncidentVM);
    }

    const injuredPersonSelectorAvailableValues = relatedPersonContacts.map((contact) => {
        return {
            code: _.get(contact, 'publicID'),
            name: `${_.get(contact, 'firstName', '-')} ${_.get(contact, 'lastName', '-')}`
        }
    }).concat([{code: AddNewPerson, name: translator(messages.addNewPerson)}])

    const onInjuredSelectorChange = (value) => {
        if (value === injuredContactPublicID) {
            return
        }
        const newInjuryIncidentVM = _.clone(injuryIncidentVM);
        
        if (value === AddNewPerson) {
            _.set(newInjuryIncidentVM.value, 'injuredContactPublicID', AddNewPerson);
            setInjured(createNewPersonWithoutPublicID())
        } else {
            _.set(newInjuryIncidentVM.value, 'injuredContactPublicID', value);
            setInjured(relatedPersonContacts.find((contact) => contact.publicID === value))
        }
        setInjuryIncidentVM(newInjuryIncidentVM);
    }

    
    const onInjuredPersonChange = (value, path) => {
        const updatedInjured = {
            ...injured,
            [path]: value,
            updated_Ext: true,
        }
        setInjured(updatedInjured)
    }

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(injuryIncidentVM.value, editingInjuryIncidentIndex, injured)
    }

    const overrideProps = {
        injuredPerson: {
            availableValues: injuredPersonSelectorAvailableValues,
            value: injuredContactPublicID,
            onValueChange: onInjuredSelectorChange
        },
        injuredPersonFirstName: {
            value: _.get(injured, 'firstName'),
            onValueChange: (value) => onInjuredPersonChange(value, 'firstName'),
            showOptional: true,
            visible: !!injuredContactPublicID
        },
        injuredPersonLastName: {
            value: _.get(injured, 'lastName'),
            onValueChange: (value) => onInjuredPersonChange(value, 'lastName'),
            visible: !!injuredContactPublicID
        },
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={injuryIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div style={{
                display: 'flex',
                'justify-content': 'right',
                gap: '10px'
            }}>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
            >{translator(messages.cancel)}</Button>
            <Button
                type="filled"
                onClick={onSaveClicked}
            >{translator(messages.save)}</Button>=
        </div>
        
    </>
}

export default SingleInjuryIncident
