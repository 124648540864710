import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class WniBillingActivityService {

    static getInvoiceStream(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingActivity'),
            'getInvoiceStream', [accountNumber], additionalHeaders);
    }

    static getBalanceSummary(accountNumber, invoiceStreamNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingActivity'),
            'getBalanceSummary', [accountNumber, invoiceStreamNumber], additionalHeaders);
    }

}
