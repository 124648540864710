import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect, useState, useCallback } from 'react';
import { WniInputText } from 'wni-common-base-components';

const SERNUM_REGEX = /^[A-Za-z0-9]+$/;

const TermFieldInputTextWithVilidator = (props) => {

    const {
        term,
        setScheduleItemFunc,
        onValidate: onContainerValidate,
        showErrors,
        isEditable,
        setIsEditing,
    } = props;

    const { code_Ext: termCode, name } = term;

    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(termCode);
    const directStringValue = term.directStringValue ? term.directStringValue : term.directValue;
    const [editingValue, setEditingValue] = useState(directStringValue);

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, termCode);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, termCode);
            }
        };
    }, [isComponentValid, onContainerValidate, termCode]);

    const registerFieldValidation = useCallback(() => {
        const isValid = SERNUM_REGEX.test(editingValue)
        return isValid;
    }, [editingValue])

    useEffect(() => {
        registerComponentValidation(registerFieldValidation)
    }, [registerFieldValidation, registerComponentValidation]);

    useEffect(() => {
        setEditingValue(directStringValue);
    }, [directStringValue]);

    const handleOnBlur = async () => {
        setIsEditing(false);
        const updatedTerm = {
            ...term,
            directStringValue: editingValue,
            updated: true,
        };
        setScheduleItemFunc((oldScheduleItem) => {
            const newScheduleItem = _.clone(oldScheduleItem);
            const newTerms = _.get(
                oldScheduleItem,
                'scheduleItemTerms',
                []
            ).map((t) => {
                if (t.code_Ext === termCode) {
                    return updatedTerm;
                }
                return t;
            });
            _.set(newScheduleItem, 'scheduleItemTerms', newTerms);
            return newScheduleItem;
        });
    };

    return (
        <WniInputText
            id={termCode}
            label={name}
            validator={{
                pattern: SERNUM_REGEX,
                message: 'This field can only consist of letters and numbers'
            }}
            onValueChange={(value) => {
                setIsEditing(true);
                setEditingValue(value);
            }}
            onBlur={handleOnBlur}
            showErrors={showErrors}
            value={editingValue}
            readOnly={!isEditable || term.readOnly_Ext}
            required={term.required}
            showRequired={term.required}
            onValidationChange={onValidate}
        />
    );
}

export default TermFieldInputTextWithVilidator;