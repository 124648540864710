import { Button, ModalBody, ModalFooter, ModalNext } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React, { useEffect, useState } from 'react';
import { WniProductsUtil } from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import metadata from './PackagePolicyPopup.metadata.json5';
import messages from '../CLPolicyHolderPage.messages';

const {
    GL_PRODUCT_CODE,
    getProductName,
    getProductNameByLinePattern
} = WniProductsUtil;

function PackagePolicyPopup(props) {
    const {
        size,
        isOpen,
        model,
        onResolve,
        onReject,
        translator,
        triggerSpecialtyAccountError,
        productSelected
    } = props;

    const [selectPolicy, updateSelectPolicy] = useState();
    const [showErrors, updateShowErrors ] = useState(false);
    // POI-55216: trigger an error message if user attempts to add a general liability line to an account that has policies with specialty NAICS codes
    const [showSpecialtyAccountError, updateShowspecialtyAccountError] = useState(false);

    const highlightRowFn = (id) => {
        WniTableRowUtil.setTablePublicIDSelected(id, 'policyTable');
    };

    useEffect(() => {
        updateShowErrors(false);
        highlightRowFn(selectPolicy);
    }, [selectPolicy]);

    const onRowClick = (item) => {
        updateSelectPolicy(item.policyNumber);
    };

    const renderCell = (item, index, { path }) => {
        let itemValue;
        switch (path) {
            case 'product':
                itemValue = getProductNameByLinePattern(item[path]);
                break;
            default:
                itemValue = item[path];
                break;
        }
        return WniTableRowUtil.renderCell(index, itemValue);
    };

    const handleConfirm = async (isPolicyChange) => {
        if(!selectPolicy && isPolicyChange) {
            updateShowErrors(true);
            updateShowspecialtyAccountError(false);
            return false;
        };
         /**
         * POI-55216: trigger an error message if user attempts to add a general liability line to an account that has policies with specialty NAICS codes
         */
         const isTriggerSpecialtyAccountError = triggerSpecialtyAccountError(_.get(model, 'value'), productSelected);
        if(!isPolicyChange && isTriggerSpecialtyAccountError) {
            updateShowErrors(false);
            updateShowspecialtyAccountError(true);
            return false;
        };
        _.set(model, 'isAddedToExistingPackage', isPolicyChange);
        _.set(model, 'selectPolicy', selectPolicy);
        _.set(model, 'createdAsMonoline', !isPolicyChange)
        onResolve(model.value);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        validationNotification: {
            visible: showErrors
        },
        specialtyAccountError: {
            message: translator(messages.specialtyAccountErrorMessage, {productName: getProductName(GL_PRODUCT_CODE)}),
            visible: showSpecialtyAccountError
        },
        policyTable: {

        },
    };

    const resolvers = {
        callbackMap: {
            onRowClick,
            renderCell,
        },
        componentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalBody id="packagePolicy">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={model}
                    {...resolvers}
                />
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'right',
                        gap: 'large',
                    },
                }}
            >
                <Button onClick={onReject} variant="secondary" label={commonMessages.cancelModel} />
                <Flex justifyContent="right" gap="none">
                    <Button variant="primary" onClick={() => handleConfirm(true)} label={commonMessages.yesModel} />
                    <Button variant="secondary" onClick={() => handleConfirm(false)} label={commonMessages.noModel} />
                </Flex>
            </ModalFooter>
        </ModalNext>
    );
}

export default PackagePolicyPopup;
