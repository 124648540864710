import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import metadata from './PolicyPageQuickFilter.metadata.json5';
import messages from '../Policies.messages';
import {
    jobsCreatedInLastXDays,
    quickDefaultView,
    currentViews,
    parseCase,
    generateAccountData,
    generateOpenJobData,
    generatePolicyData,
    filterJobByProducerCode
} from '../Policies.util';
import PolicyPageTable from '../PolicyPageTable/PolicyPageTable';

const PolicyPageQuickFilter = (props) => {
    const {
        policyData = {},
        accountData = {},
        selectedProducerCode,
        history,
    } = props;
    const translator = useTranslator();

    const [currentView, updateCurrentView] = useState(quickDefaultView);
    const [currentViewId, updateCurrentViewId] = useState();
    const [policyInitData, updatePolicyInitData] = useState({});
    const [accountInitData, updateAccountInitData] = useState({});
    const [viewPolicyData, updateViewPolicyData] = useState([]);
    const [openJobData, updateOpenJobData] = useState([]);

    useEffect(() => {
        const newInitData = generateAccountData(accountData);
        updateAccountInitData(newInitData);
        updatePolicyInitData(policyData);
    }, [accountData, policyData]);

    useEffect(() => {
        const viewid = _.lowerFirst(currentView);
        updateCurrentViewId(viewid);
    }, [currentView]);

    const handleOnClick = (id) => {
        const currentId = parseCase(id);
        updateCurrentView(currentId);
    };

    const getOpenJobResponseData = useCallback((id, producerCodeParam) => {
            const tableDataList = accountInitData[`open${id}`] || [];
            const filterTableData = tableDataList.filter((job) => filterJobByProducerCode(job, producerCodeParam));
            const genNewData = generateOpenJobData(filterTableData);
            updateOpenJobData(genNewData);
        }, [accountInitData]);

    const getPolicyResponseData = useCallback((id, producerCodeParam) => {
        const lowerFirstId = _.lowerFirst(id);
        const newData = policyInitData[lowerFirstId] || [];
        const filterTableData = newData.filter((policy) => filterJobByProducerCode(policy, producerCodeParam));
        const genNewData = generatePolicyData(filterTableData);
        updateViewPolicyData(genNewData);
    }, [policyInitData]);

    const triggerGetResponse = (
        id,
        producerCodeParam
    ) => {
        const checkTableDataType = currentViews.indexOf(parseCase(id)) >= 0;
        if (checkTableDataType) {
            getOpenJobResponseData(id, producerCodeParam);
        } else {
            getPolicyResponseData(id, producerCodeParam);
        }
    };

    useEffect(() => {
        triggerGetResponse(currentView, selectedProducerCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView, selectedProducerCode, policyInitData, accountInitData]);

    const getActiveTileValue = (tileId) => {
        const tileData = accountInitData[`open${tileId}`] || [];
        const filterTableData = tileData.filter((job) => filterJobByProducerCode(job, selectedProducerCode));
        return filterTableData.length;
    };

    //-------------------------------------------
    const overrideProps = {
        quotes: {
            value: getActiveTileValue('Quotes'),
        },
        renewal: {
            value: getActiveTileValue('Renewal'),
        },
        change: {
            value: getActiveTileValue('Change'),
        },
        cancellation: {
            value: getActiveTileValue('Cancellation'),
        },
        [currentViewId]: {
            active: true,
            value: getActiveTileValue(currentView),
        },
        tableCard: {
            title: translator(messages[`titleFor${currentView}`], {
                day: jobsCreatedInLastXDays,
            }),
        },
        tableContainer: {
            currentView,
            policyData: viewPolicyData,
            jobData: openJobData,
            history,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleOnClick,
        },
        resolveComponentMap: {
            tablecomponent: PolicyPageTable,
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

export default PolicyPageQuickFilter;
