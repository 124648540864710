import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    Redirect,
    Prompt,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import _ from 'lodash';

// import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    WizardUtil,
    WizardStepUtil,
 } from 'wni-portals-util-js';
import { useWizardPageData } from 'wni-portals-util-react';
// import { WizardContext, wizardStepProps } from '@xengage/gw-portals-wizard-react';
import { WizardContext } from '@xengage/gw-portals-wizard-react';

const wizardStepProps = {
    title: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]),
    path: PropTypes.string,
    component: PropTypes.elementType,
};


// import { WizardContext } from './WizardContext';
// import { wizardStepProps } from './prop-types/wizardPropTypes';
// import useWizardErrors from './hooks/useWizardErrors';
import WizardRoutes from './BaseWizardRoutes';
import useWizardData from './hooks/useBaseWizardData';
import useWizardSteps from './hooks/useBaseWizardSteps';
import AsyncPrompt from '../components/Prompt/AsyncPrompt';

import { PromptService } from '@jutro/legacy/router';

const checkIsDataValid = (data) => {
    return (!ViewModelUtil.isViewModelNode(data)
    || (data.aspects.subtreeValid && data.aspects.valid));
};

const checkDataValidityDuringTransition = (wizardData, wizardSnapshot, modalMessageProps) => {
    return checkIsDataValid(wizardData)
        ? wizardData
        : wizardSnapshot;
};

const checkContinue = (result) => {
    if (result === false) {
        return Promise.reject(new Error(false));
    }
    return Promise.resolve(result);
};

const handleOnPrevious = (wizardProps) => {
    const { wizardData, wizardSnapshot, onPreviousModalProps } = wizardProps;
    return checkDataValidityDuringTransition(wizardData, wizardSnapshot, onPreviousModalProps);
};

/**
 * This customized wizard intends to resolve the following OOTB error:
 * Cannot ready properties of undefined (reading 'map')
 * TypeError: Cannot read properties of undefined (reading 'map')
 * 	at d (https://localhost:3000/static/js/main.chunk.js:19368:28)
 * 	at arrayMap (https://localhost:3000/static/js/1130.chunk.js:220304:23)
 * 
 * Related Component:
 * 	return e.onOfferings.map(function (r) {
 * 		in
 * 	gw-portals-edge-validation-js/index.js
 * 
 * 	{parseErrors} , used in gw-portals-weizard-react-ext/Wizard/hooks/useWizardErrors.js
 *
 * //===========================
 * Another solution is to simply nullify the interfaces provided by  useWizardErrors(), e.g.:
 * // const {
 * //     acknowledgeError,
 * //     reportErrors,
 * //     stepsWithErrors,
 * //     hasNewErrors,
 * //     underwritingIssues,
 * //     knockOutErrors
 * // } = useWizardErrors(wizardStepToFieldMapping, submittedStepIds);
 * const acknowledgeError = _.noop;
 * const reportErrors = _.noop;
 * const stepsWithErrors = [];
 * const hasNewErrors = false;
 * const underwritingIssues = [];
 * const knockOutErrors = [];
 *
 *
 * @param {object} props 
 * @returns {function}
 */
function BaseWizard(props) {
    const {
        wizardTitle,
        // history,
        initialSteps,
        initialData,
        skipCompletedSteps,
        onFinish,
        onCancel,
        onPrevious,
        onPreviousModalProps,
        //
        wizardStepToFieldMapping,
        // location,
        // match,
        // basePath = match.url,
        wizardSidebarExtension,
        wizardPageHeaderExtension,
        // wizardSideBarExtensionProps,
        updateWizardMode,
        //
        wizardPageHeaderFormatter,
        wizardServiceCallbacks,
        onWizardInitialization,
        wizardDataComparator,
        showWizardPromptMessage,
        onLeaveWizard,
        initialWizardPageData,
        // ===========================
        wizardTransactionType,
        // ===========================
        //
        // wizardNextButtonRef,
        onKnockOut_DEPRECATED: onKnockOut,
        wizardErrorsAndWarnings_DEPRECATED,
    } = props;

    const location = useLocation();
    const match = useRouteMatch();
    const basePath = match.url;

    const {
        steps,
        currentStepIndex,
        currentStep,
        goNext,
        goPrevious,
        jumpTo,
        markStepSubmitted,
        changeNextSteps,
        isSkipping,
        stopSkipping,
        clearVisitedStepsAfterCurrent,
        updateFurthestPageVisited,
        clearVisitedStepsAfterPage,
        //
        toggleStepDisplay_Deprecated: toggleStepDisplay,
        toggleStepDisplay: toggleStepDisplayR6,
    } = useWizardSteps(initialSteps, skipCompletedSteps);

    // const toggleWizardPageDisplay = (...stepConfigs) => toggleStepDisplayR6(stepConfigs);
    const toggleWizardPageDisplay = (stepConfigsArray, optConfig) => toggleStepDisplayR6(stepConfigsArray, optConfig);

    /**
     * Mark the pages unvisisted, starting from QuotePage
     *
     * USAGE EXAMPLE:
     * clearVisitedStepsFromQuotePage();
     */
    const clearVisitedStepsFromQuotePage = useCallback(() => {
        const quotePageIndex = WizardStepUtil.getQuotePageIndex(steps);
        clearVisitedStepsAfterPage(quotePageIndex - 1);
    }, [clearVisitedStepsAfterPage, steps]);

    const {
        data,
        updateData,
        snapshot,
        updateSnapshot,
        cloneData
    } = useWizardData(initialData);
    // const submittedStepIds = _.filter(steps, 'submitted').map((step) => step.id);

    // const { authHeader } = useAuthentication();

    const [toggle, flip] = useState(false);
    // const [data, updateSubmissionData] = useState(initialData);

    // const [isUwIssuesInitialized, updateIsUwIssuesInitialized] = useState(false);

    const { wizardPageData, updateWizardPageData } = useWizardPageData(initialWizardPageData);
    const [showWizardPrompt, updateShowWizardPrompt] = useState(showWizardPromptMessage);

    const updateWizardReadOnly = useCallback((readOnly, optArgs = undefined) => {
        updateWizardMode(readOnly ? 'readOnly' : 'default', optArgs);
        updateShowWizardPrompt(false);
    }, [updateWizardMode, updateShowWizardPrompt]);


    useEffect(() => {
        updateShowWizardPrompt(showWizardPromptMessage);
    }, [showWizardPromptMessage]);

    useEffect(() => {
        onWizardInitialization({
            updateFurthestPageVisited,
            wizardPageData,
            stopSkipping,
            updateWizardReadOnly,
            //
            toggleWizardPageDisplay,
            wizardData: data,
            wizardSteps: steps
        });
    }, [onWizardInitialization]);

    const viewModelService = useContext(ViewModelServiceContext);

    const updateWizardData = useCallback(
        (newData, {
            shouldUpdateFollowingStepsVisitedStatus = true,
        } = {}) => {
            // clearVisitedStepsAfterCurrent();
            // Clear the visisted status for following steps only when
            // the wizard data has been changed
            // if (data.value && snapshot.value && !_.isEqual(data.value, snapshot.value)) {
            if (shouldUpdateFollowingStepsVisitedStatus) {
                if (WizardUtil.isWizardDataChanged(newData, snapshot, wizardDataComparator)) {
                    clearVisitedStepsAfterCurrent();
                }
            }
            const errorsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'errorsAndWarnings.value'
                : 'errorsAndWarnings';
            const errorsAndWarnings = _.get(newData, errorsPath);
            flip(!toggle);
            // WizardUtil.sortSubmissionData(newData);
            updateData(newData);
            // return reportErrors(errorsAndWarnings);
            return false; // reportErrors default to _.stubFalse()
        },
        [toggle, updateData, clearVisitedStepsAfterCurrent]
    );

    const updateWizardSnapshot = useCallback(
        (newData) => {
            updateSnapshot(cloneData(newData, viewModelService));
            return updateWizardData(newData);
        },
        [updateSnapshot, updateWizardData, viewModelService]
    );

    /**
     * Reset current wizardData to snapshot
     */
    const resetWizardDataToSnapshot = useCallback(() => {
        return updateWizardData(cloneData(snapshot, viewModelService), {
            shouldUpdateFollowingStepsVisitedStatus: false,
        });
    }, [updateWizardData, snapshot, viewModelService]);

    const finishCallback = useCallback(
        (params) => onFinish({
            steps,
            currentStepIndex,
            wizardData: data,
            params
        }),
        [steps, currentStepIndex, data, onFinish]
    );

    const previousCallback = useCallback(
        (param) => {
            // Nah, seems like little sense is made to update snpashot here
            // It also results into infinite loop in BaseWizardPage::onPrevious()
            // due to wizardGoPrevious constantly being updated
            // return Promise.resolve(onPrevious({
            //     wizardSnapshot: snapshot,
            //     wizardData: data,
            //     onPreviousModalProps,
            //     param
            // }))
            //     .then(checkContinue)
            //     .then((result) => cloneData(result, viewModelService))
            //     // .then((result) => updateWizardData(result))
            //     .then(updateWizardSnapshot(data))
            //     .then(goPrevious)
            //     .catch((error) => {
            //         if (_.get(error, 'message') === 'false') {
            //             // do nothing as we don't want to proceed
            //             return;
            //         }
            //         throw error;
            //     });
            return goPrevious();
        }, [
            onPrevious,
            onPreviousModalProps,
            snapshot,
            data,
            goPrevious,
            updateWizardData,
            updateSnapshot,
            viewModelService
        ]
    );

    const onPageJump = useCallback(
        ({
            wizardData,
            wizardSnapshot,
            modalMessages,
            index
        }) => {
            const pageTransitionPromise = Promise.resolve(checkDataValidityDuringTransition(
                wizardData, wizardSnapshot, modalMessages
            ));

            pageTransitionPromise.then((result) => {
                if (result !== false) {
                    // updateWizardData(cloneData(wizardData, viewModelService));
                    // updateWizardData(cloneData(result, viewModelService));
                    jumpTo(index);
                }
            }).catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }
                throw error;
            });
        },
        [jumpTo, updateWizardData, viewModelService]
    );

    const cancelCallback = useCallback(() => {
        onCancel({
            wizardData: data,
        });
    }, [onCancel, data]);
    
    /**
     * Ditch the OOTB version by removing Jutro PromptService for good,
     * which is still unstable at Dobson.
     *  2022-10-28: the Jutro (Dobson) PromptService intends to crash,
     *  which boosts Jutro's already good reputation to a new level.
     *  
     *  index.js:1 ERROR: TypeError: Cannot read properties of undefined (reading 'props')
     *  at _callee$ (routeConfirmationModal.js:1:1)
     *  at tryCatch (routeConfirmationModal.js:2:1)
     *  at Generator._invoke (routeConfirmationModal.js:2:1)
     *  at Generator.next (routeConfirmationModal.js:2:1)
     *  at asyncGeneratorStep (asyncToGenerator.js:3:1)
     *  at _next (asyncToGenerator.js:25:1)
     */
    const handlePromptMessage = useCallback(async ({ pathname }, actionName) => {
        const isWizardPath = pathname.startsWith(basePath);

        // if triggered by the wizard, we ignore it
        if (isWizardPath) {
            return true; // go to the following page;
        }
        // const retval = false; // stay on current page
        // Refactoring Notice: pathname is currently used by handleCancel()
        // to check whether cancelModal could be ignored
        // const retval = await cancelCallback(pathname); // true to jump away, false to stay
        let retval = true; // go to next page by default
        // if (onLeaveWizard) { // onLeaveWizard default to _.stubTrue
        retval = await onLeaveWizard({
            wizardData: data,
            wizardPageData,
            currentStepIndex,
            //
            nextPath: pathname,
        });
        // }

        return retval;
    }, [basePath, currentStepIndex, data, onLeaveWizard, wizardPageData]);

    /*
     * current step has authority over the URL.
     *
     * If current step is not matching the URL,
     * we adjust by sending a redirect to the step URL
     */
    const expectedPath = `${basePath}${currentStep.path}`;
    if (!location.pathname.startsWith(expectedPath)) {
        const nextLocation = {
            pathname: expectedPath,
            state: location.state
        };
        return (<Redirect to={nextLocation} />);
    }

    const wizardProps = {
        // wizard details
        wizardTitle,
        // steps props
        steps,
        initialSteps,
        changeNextSteps,
        currentStepIndex,
        currentStep,
        // transitions props
        isSkipping,
        stopSkipping,
        goNext,
        goPrevious: previousCallback,
        markStepSubmitted,
        jumpTo,
        // history,
        finish: finishCallback,
        cancel: cancelCallback,
        onPageJump,
        // wizard data props
        wizardData: data,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot: snapshot,
        // errors props
        // hasNewErrors,
        // errorsForStep,
        // stepsWithErrors,
        // underwritingIssues,
        // acknowledgeError,
        // reportErrors,
        wizardStepToFieldMapping,
        // =============================
        // Expose OOTB Props, to be enhanced with params, e.g. currentStep
        markFollowingWizardStepsUnvisited: () => clearVisitedStepsAfterCurrent(),
        toggleWizardPageDisplay,
        // =============deprecated by turnOn/OffWizardSteps() BEGIN=====================
        turnOnWizardPage: (pageIdOrIndex) => toggleStepDisplay(pageIdOrIndex, _.stubTrue),
        turnOffWizardPage: (pageIdOrIndex) => toggleStepDisplay(pageIdOrIndex, _.stubFalse),
        // =============deprecated by turnOn/OffWizardSteps() END=====================
        // =============================
        // WNI Enhanced Props
        wizardSidebarExtension,
        // wizardSideBarExtensionProps,
        wizardPageHeaderExtension,
        updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
        //
        wizardPageHeaderFormatter,
        wizardServiceCallbacks,
        resetWizardDataToSnapshot,
        clearVisitedStepsFromQuotePage,
        // POI-18283: This method is introduced to to get rid of Next jup on PaySuccessPage
        // Refactoring Notice: Remove this mehtod as soon as there is another solution
        turnOffWizardPromptMessage: () => updateShowWizardPrompt(false),
        //
        wizardTransactionType,
        // wizardNextButtonRef,
        wizardErrorsAndWarnings_DEPRECATED,
    };

    // we pass the props to both the component
    // and the context to allow nested components to reuse them
    // without prop-drilling
    return (
        <WizardContext.Provider value={wizardProps}>
            {/* <Prompt message={handlePromptMessage} when={showWizardPrompt} /> */}
            <AsyncPrompt message={handlePromptMessage} when={showWizardPrompt} />
            <WizardRoutes basePath={basePath} {...wizardProps} />
        </WizardContext.Provider>
    );
}
BaseWizard.propTypes = {
    /** the title for this wizard */
    wizardTitle: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]),
    /**
     * the steps that will compose this wizard
     * (once initialized, they cannot be changed from the props)
     */
    initialSteps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired,
    /**
     * the data to pass to the wizard
     * (once initialized, it cannot be changed from props)
     */
    initialData: PropTypes.shape({}),
    // /**
    //  * the React Router location
    //  */
    // location: PropTypes.shape({
    //     pathname: PropTypes.string,
    //     state: PropTypes.shape({})
    // }).isRequired,
    // /**
    //  * the React Router history
    //  */
    // history: PropTypes.shape({}).isRequired,
    // /**
    //  * The React Router match
    //  */
    // match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    /**
     * Steps to match errors to the specific wizard step (e.g. backend validation)
     */
    wizardStepToFieldMapping: PropTypes.shape({}),
    /**
     * whether the wizard should begin while skipping the completed steps
     * (once initialized, this cannot be changed from the props)
     */
    skipCompletedSteps: PropTypes.bool,
    /**
     * Callback that will be called when the wizard is finished.
     * This will be called with one parameter containing all the wizard data
     */
    onFinish: PropTypes.func,
    /**
     * Callback that will be called when the wizard is canceled.
     * This will be called with one parameter containing all the wizard data
     */
    onCancel: PropTypes.func,
    onPrevious: PropTypes.func,
    onPreviousModalProps: PropTypes.shape({
        title: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        message: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        status: PropTypes.string,
        icon: PropTypes.string,
        confirmButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    }),
    /**
     * Callback that will be called when the wizard is interrupted because of
     * a knock-out error.
     * This will be called with one parameter containing all the wizard data
     * 
     * @deprecated when WizardErrorContext was introduced
     */
    onKnockOut_DEPRECATED: PropTypes.func,
    /**
     * Where this wizard is based
     * If not passed the current path of the page will be used.
     * This is particularly useful when nesting one wizard inside another
     */
    basePath: PropTypes.string,
    /**
    * The wizardSidebarExtension component that will display at the bottom of the Wizard sidebar
    */
    wizardSidebarExtension: PropTypes.func,
    /**
     * The wizardPageHeaderExtension compoennt that will display in WizardPageHeader
     */
    wizardPageHeaderExtension: PropTypes.func,
    /**
     * Set WizardReadOnly status
     */
    updateWizardMode: PropTypes.func,
    // /**
    //  * Event listener that is calle when wizard data is updated.
    //  */
    // onWizardDataUpdated: PropTypes.func,
    /**
     * Customize wizard page header, used in WizardPageHeader
     * @param {
     *   productWithQuoteNumber, {String} optional
     *   wizardData, {Object}  required
     *   jobStatusInfo, {String} optional
     *   policyTypeDisplayName, {String} optional
     * }
     * @returns {
     *   ...allInputParamTypes,
     *   isDisplayPolicyType, {Boolean}
     *   isDisplayJobType,{Boolean}
     *   policyTypeDisplayName, {String} optional
     * }
     * example:
     * params: {
     *      productWithQuoteNumber,
     *      wizardData: wizardSubmission,
     *      jobStatusInfo,
     *  }
     * returns {
     *     productWithQuoteNumber,
     *     wizardData
     *     jobStatusInfo,
     *     isDisplayPolicyType: true
     *     isDisplayJobType :true,
     * }
     */
    wizardPageHeaderFormatter: PropTypes.func,
    /**
     * Initialization func for Wizard, for Internal use only.
     * Params provided: { updateFurthestPageVisited }
     */
    onWizardInitialization: PropTypes.func,
    /**
     * Checks whether wizardData is different from wizardSnapshot
     */
    wizardDataComparator: PropTypes.func,
    // /**
    //  * (Optional) wiard sidebar extensions that will be passed to WizardSidebarExtensionComponent
    //  */
    // wizardSideBarExtensionProps: PropTypes.shape({}).isRequired,
    /**
     * (Optional) Whether show the prompt message when jump out of the Wizard
     * ref: https://reactrouter.com/web/api/history
     * ref2: https://github.com/ReactTraining/history/blob/master/docs/blocking-transitions.md
     */
    showWizardPromptMessage: PropTypes.bool,
    /**
     * (Optional): the Prompt handler to be passed to AsyncPrompt. 
     * 
     * Note that this property is used along with showWizardPromptMessage
     */
    onLeaveWizard: PropTypes.func,
    /**
     * (Optional) Service callbacks that expose capability-* services across module-* components
     */
    wizardServiceCallbacks: PropTypes.shape({}),
    /**
     * (Optional) Initial wizardPageData, e.g. that includes
     * the EdgeJobDataDTO when "Save & Next" was clicked last time
     */
    initialWizardPageData: PropTypes.shape({}),

    /**
     * (Optional) TransactionType, such as Submission, PolicyChange, etc. Currently only used in CommercialWizardPage
     */
    wizardTransactionType: PropTypes.string,
    // ============================================
    // Below properties are deprecated, they are only preserved for backward compatability
    // ============================================
    // wizardNextButtonRef: PropTypes.shape({}),
    // copySubmission: PropTypes.func.isRequired,
    /**
     * 
     * (Optional): provide initial wizard level errors and warnings. 
     * This property has been deprecated.
     * 
     * 
     * Deprecated. Please use WizardErrorContext for Wizard level ErrorsAndWarnings.
     * See useBaseWizardV2 for more info.
     * 
     * 
     * Note: I personally prefer to add prefix DEPRECATED_ instead of suffix _DEPRECATED.
     * But ESLint complains "Identifier 'DEPRECATED_wizardErrorsAndWarnings' is not in camel case",
     * which is why we are here.
     * 
     */
    wizardErrorsAndWarnings_DEPRECATED: PropTypes.arrayOf(PropTypes.shape({})),
};

BaseWizard.defaultProps = {
    wizardTitle: null,
    initialData: null,
    skipCompletedSteps: false,
    onCancel: _.noop,
    onPrevious: handleOnPrevious,
    onPreviousModalProps: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'gw-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    onFinish: _.noop,
    wizardStepToFieldMapping: {},
    basePath: undefined,
    //
    wizardSidebarExtension: null,
    wizardPageHeaderExtension: null,
    updateWizardMode: _.noop,
    wizardPageHeaderFormatter: undefined,
    onWizardInitialization: _.noop,
    wizardDataComparator: WizardUtil.wizardDataSimpleComparator,
    showWizardPromptMessage: false,
    onLeaveWizard: _.stubTrue,
    //
    wizardServiceCallbacks: {},
    initialWizardPageData: undefined,
    //
    // wizardNextButtonRef: { current: null },
    onKnockOut_DEPRECATED: _.noop,
    wizardErrorsAndWarnings_DEPRECATED: [], // Deprecated
};

// export default withRouter(Wizard);
export default BaseWizard;
