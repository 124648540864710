
import React from 'react';
import _ from 'lodash';
import { PolicyChangeUtil } from 'wni-portals-util-js';

import { GLSublineSelectionPage } from 'wni-capability-quoteandbind-gl-react';

function GLSublineSelectionChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);

    return (
        <GLSublineSelectionPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLSublineSelectionChangePage.propTypes = GLSublineSelectionPage.propTypes;
GLSublineSelectionChangePage.defaultProps = GLSublineSelectionPage.defaultProps;

export default GLSublineSelectionChangePage;