
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React from 'react';
import CoveragesConfigContext from '../../GeneralLiability/context/GLCoveragesConfigContext'
import GLCoveragesConfig from '../../GeneralLiability/GLCoverages.config'
import UnmannedAircraftCoverages from './Coverages/UnmannedAircraftCoverages';
import UnmannedAircraftDetails from './Details/UnmannedAircraftDetails';
import metadata from './UnmannedAircraftComponent.metadata.json5';
import StandardCoverage from './StandardCoverage/StandardCoverage';

function UnmannedAircraftComponent(props) {
    const {
        currentRow = {},
        unmannedAircraftClauses,
        updateUnmannedAircraftClauses,
        wizardData: submissionVM,
        updateWizardData,
        onValueChange,
        onValidate,
        showErrors,
        readOnly,
        isEditing,
        setIsEditing,
        extendProps
    } = props;

    const setClauseValid = (valid) => {
        onValueChange(valid, 'unmannedAircraftClausesValid')
    }

    const newComponentProps = _.omit(props, 'id');
    const overrideProps = {
        '@field': {
            ...newComponentProps,
        },
        standardCoverage: {
            unmannedAircraftClauses,
            updateUnmannedAircraftClauses,
            wizardData: submissionVM,
            updateWizardData,
            onValidate: onValidate,
            showErrors,
            readOnly,
            extendProps,
            setIsEditing,
            isEditing,
            setClauseValid,
        }
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            detailsfield: UnmannedAircraftDetails,
            coveragesfield: UnmannedAircraftCoverages,
            StandardCoverage: StandardCoverage,
        },
    };
    return (
        <CoveragesConfigContext.Provider value={GLCoveragesConfig}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={currentRow}
                overrideProps={overrideProps}
                onValueChange={onValueChange}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </CoveragesConfigContext.Provider>
        
    );
}

export default UnmannedAircraftComponent;
