import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const DEFAULT_MODE = 'internal'; // Consider another name like "agentView"
const ACCOUNT_SUMMARY_EXIT_PAGE_INDEX = 1; // 2

function HOAccountSummaryRoutes(props) {
    const history = useHistory();
    
    const {
        accountNumber,
        // mode = DEFAULT_MODE,
    } = useParams();

    useEffect(() => {
        const targetURL = `/account-quotes/${accountNumber}`;
        const stateObj = {
            mode: DEFAULT_MODE, // 'internal',
            submissionWizardExitPage: ACCOUNT_SUMMARY_EXIT_PAGE_INDEX, // 2
        };
        history.push(targetURL, stateObj);
    }, []);

    return null;
}

export default HOAccountSummaryRoutes;
