import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 * WniPolicyChangeVehicleHandler
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeVehicle'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Vehicle for quote backend operations
 *
 * @export
 * @class WniPolicyChangeVehicleService
 */
export default class WniPolicyChangeVehicleService {
    /**
     * Invokes WniPolicyChangeVehicleHandler.updateVehicle()
     * @param {object} data the WniUpdateVehicleRequestDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains PolicyChangeDataDTO
     */
    static updateVehicle(data, authHeader = {}) {
        return processSubmission('updateVehicle', [data], authHeader);
    }

    /**
     * Invokes WniPolicyChangeVehicleHandler.getAvailableDrivers()
     * @param {object} jobID String
     * @param {object} vehicleID Long vehicle fixedId
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains SimpleDriverDTO[]
     */
    static getAvailableDrivers(jobID, vehicleID, authHeader = {}) {
        return processSubmission('getAvailableDrivers', [jobID, vehicleID], authHeader);
    }

    /**
     * Invokes WniPolicyChangeVehicleHandler.autoAssignAndGetVehicleDriverAssignments()
     * @param {object} policyNumber String
     * @param {object} effectiveDate LocalDateDTO
     * @param {object} jobID String
     * @param {object} updatingAssignment Boolean
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains VehicleDriverDTO[]
     */
    static autoAssignAndGetVehicleDriverAssignments(
        policyNumber, effectiveDate, jobID, updatingAssignment, authHeader = {}
    ) {
        return processSubmission('autoAssignAndGetVehicleDriverAssignments', [policyNumber, effectiveDate, jobID, updatingAssignment], authHeader);
    }

    /**
     * Invokes WniPolicyChangeVehicleHandler.updateVehicleDriverAssignments()
     * @param {object} policyNumber String
     * @param {object} effectiveDate LocalDateDTO
     * @param {object} jobID String
     * @param {object} assignments String
     * @param {object} vehicleID Vehicle's fixedId
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains PolicyChangeDataDTO
     */
    static updateVehicleDriverAssignments(
        policyNumber, effectiveDate, jobID, assignments, vehicleID, authHeader = {}
    ) {
        return processSubmission('updateVehicleDriverAssignments', [policyNumber, effectiveDate, jobID, assignments, vehicleID], authHeader);
    }

    /**
     * Invokes WniPolicyChangeVehicleHandler.getErrorsAndWarnings()
     * @param {object} jobID String
     * @param {object} isCoveragePage boolean
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @param {object} isErrorLevel the flag to escalate validation level
     * @returns {Promise} the Promise from the backend call that contains
     * JobValidationUnderwritingIssuesDTO
     */
    static getErrorsAndWarnings(jobID, isCoveragePage, authHeader = {}, isErrorLevel = false) {
        return processSubmission('getErrorsAndWarnings', [jobID, isCoveragePage, isErrorLevel], authHeader);
    }


    /**
     * 
     * @param {object} data the WniUpdateVehicleRequestDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains PolicyChangeDataDTO
     */
    /**
     * Invokes WniPolicyChangeVehicleHandler.getAdditionalInterestType()
     * @param {object} jobID String
     * @param {object} sessionUUID String
     * @param {object} publicID String
     * @param {object} isPerson Boolean
     * @param {object} isCompany Boolean
     * @param {object} isBank Boolean
     * @param {object} isTrust Boolean
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains AdditionalInterestType
     */
    static getAdditionalInterestTypeOptions(jobID, sessionUUID, publicID, isPerson,
        isCompany, isBank, isTrust, authHeader = {}) {
        return processSubmission('getAdditionalInterestType', [jobID, sessionUUID, publicID, isPerson,
            isCompany, isBank, isTrust], authHeader);
    }
}
