import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { UserService } from 'gw-capability-gateway';
import { useBusinessData } from 'wni-portals-util-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { routes } from './MenuBarComponent.json5';
import styles from './MenuBarComponent.module.scss';


const { gatewayAnalytics, capabilitiesConfig, persona } = appConfig;

const MenuBar = (props) => {
    const { getFeatureCodeValue } = useBusinessData();
    const translator = useTranslator();
    const isAgent = persona === 'producer';
    const { authHeader, authUserData } = useAuthentication();
    const [userAccessConfiguration, updateUserAccessConfiguration] = useState({});
    const enableClaimsForPE = getFeatureCodeValue("EnableClaimsForPE");

    const updateConfiguation = useCallback((accessProperty) => {
        updateUserAccessConfiguration((previousState) => ({
            ...previousState,
            [accessProperty]: true
        }));
    }, []);

    const checkViewStatementsPermission = useCallback(() => {
        const canViewStatements = _.get(authUserData, 'canViewStatementScreen_Ext');
        if (canViewStatements) {
            updateConfiguation('canViewStatements');
        }
    }, [authUserData, updateConfiguation]);

    const checkViewReportsPermission = useCallback(() => {
        const permissionDTO = {
            permission: 'gpaviewreports'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then((isPermission) => {
            if (isPermission && gatewayAnalytics) {
                updateConfiguation('canViewReports');
            }
        });
    }, [authHeader, updateConfiguation]);

    const checkViewCommissionsPermission = useCallback(() => {
        const permissionDTO = {
            permission: 'viewcommissions'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then((isPermission) => {
            if (
                isPermission
                && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
            ) {
                updateConfiguation('canViewCommission');
            }
        });
    }, [authHeader, updateConfiguation]);

    const checkViewClaimsPermission = useCallback(() => {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' }) && enableClaimsForPE) {
            updateConfiguation('canViewClaims');
        }
    }, [enableClaimsForPE, updateConfiguation]);

    useEffect(() => {
        if (isAgent) {
            const executePermissionConfiguration = () => {
                checkViewClaimsPermission();
                checkViewReportsPermission();
                checkViewCommissionsPermission();
                checkViewStatementsPermission();
            };
            executePermissionConfiguration();
        }
        // it should call once when page is rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const routeWithUserAccess = useCallback(
        (route) => {
            if (route.visibleWhen) {
                return userAccessConfiguration[route.visibleWhen] && route;
            }
            return route;
        },
        [userAccessConfiguration]
    );

    const NavItems = useCallback(() => {
        const otherUsersMenu = ['/activities', '/'];
        const menuListByAgent = routes.filter((menu) => {
            return isAgent ? routeWithUserAccess(menu) : otherUsersMenu.indexOf(menu.path) !== -1;
        });

        return menuListByAgent.map(({
            title, path, icon, activeClassName
        }, index) => {
            const activeClass = styles[activeClassName];
            return (
                <NavLink key={index} exact={path === '/'} to={path} activeClassName={activeClass} tabIndex="-1">
                    {icon && <Icon icon={icon} className={styles.navIcon} />}
                    {translator(title)}
                </NavLink>
            );
        });
    }, [isAgent, routeWithUserAccess, translator]);

    return (
        <div className={styles.menuBar}>
            <NavItems tabIndex="-1" />
        </div>
    );
};


MenuBar.propTypes = {
   
};

MenuBar.defaultProps = {
};

export default MenuBar;
