import {
    useState,
    useCallback,
    useEffect,
} from 'react';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ServiceErrorUtil } from 'wni-portals-util-js';

import ModalDialogHelper from '../ModalDialogHelper';



function useLoadingMask(props) {
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const modalApi = useWniModal();
    const modalHelper = ModalDialogHelper(modalApi);
    

    //
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);


    const withServiceCallInProgress = useCallback(async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        }
        updateServiceCallInProgress(false);
    }, [modalHelper]);


    return {
        isServiceCallInProgress,
        //
        withServiceCallInProgress,
    };
}


export default useLoadingMask;
