import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

// import WCCoveargesPageMain from '../../../Coverages/WCCoveragesPage.main';


import { formatDate, formatNumber, FormattedDate, FormattedNumber, SimpleDateField } from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/legacy/datatable';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';

import WCCoveredEmployeeSummaryComponent from './WCCoveredEmployeeSummaryComponent';

import { Button } from '@jutro/legacy/components';

const DATE_FIELDS = ['effectiveDate', 'expirationDate'];

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function WCCoveredEmployeeComponent(props) {

    const {
        coveredEmployees, // [];
    } = props;

    
    
    const [currentRow, updateCurrentRow] = useState(undefined);


    if (_.isEmpty(coveredEmployees)) {
        return null;
    }

    useEffect(() => {
        if (!_.isEmpty(coveredEmployees)) {
            updateCurrentRow(coveredEmployees[0]);
        }
    }, []);

    const renderCell = (columnField) => {
        return (rowData, ...params) => {
            const retval = rowData[columnField];

            if (DATE_FIELDS.includes(columnField)) {
                return <SimpleDateField readOnly value={retval} />;
            }
            // console.log(params);
            return retval;
        };
    };

    const onRowClick = (rowData, selectedIndex, ...params) => {
        const newCurrentRow = coveredEmployees[selectedIndex];
        updateCurrentRow(newCurrentRow);
    };
    

    return (
        <div>
            <h4>Covered Employees</h4>
            <DataTable
                id="WCCoveredEmployeesTable"
                data={coveredEmployees}
                tableLabel="Covered Employee"
                onRowClick={onRowClick}
                selectOnRowClick
                showSearch={false}
            >
                <DisplayColumn
                    header="Effective Date"
                    renderCell={renderCell('effectiveDate')}
                />
                <DisplayColumn
                    header="Expiration Date"
                    renderCell={renderCell('expirationDate')}
                />
                <DisplayColumn
                    header="Location"
                    renderCell={renderCell('location')}
                />
                <DisplayColumn
                    header="Class Code"
                    renderCell={renderCell('classCode')}
                />
                <DisplayColumn
                    header="Class Description"
                    renderCell={renderCell('classDescription')}
                />
                <DisplayColumn
                    header="If Any"
                    renderCell={renderCell('ifAny')}
                />
                <DisplayColumn
                    header="Payroll"
                    renderCell={renderCell('payRoll')}
                />
                <DisplayColumn
                    header="Basis"
                    renderCell={renderCell('basis')}
                />
                <DisplayColumn
                    header="Basis Type"
                    renderCell={renderCell('basisType')}
                />
            </DataTable>
            <WCCoveredEmployeeSummaryComponent coveredEmployee={currentRow} />
        </div>
    );
    
}

WCCoveredEmployeeComponent.propTypes = {
    
};

WCCoveredEmployeeComponent.defaultProps = {
    
};


export default WCCoveredEmployeeComponent;