import _ from 'lodash';

const isDriverValid = (driver) => {
    if (_.isNil(driver)){
        return true
    }
    const {
        isLicensedDriver,
        // occupation_Ext: occupation
        // licenseNumber,
        // licenseState,

    } = driver;

    const isValid = !_.isNil(isLicensedDriver)
            // && (isLicensedDriver ? (!_.isNil(licenseNumber) && !_.isNil(licenseState)) : true)
    return isValid;
};


export default {
    isDriverValid
};
