import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { PADriverAssignmentPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeVehicleService } from 'wni-capability-policychange';

function PADriverAssignmentChangePage(props) {
    const policyChangeGetAvailableDrivers = useCallback(
        async (jobID, vehicleID, authHeader) => {
            const response = await WniPolicyChangeVehicleService.getAvailableDrivers(
                jobID, vehicleID, authHeader
            );
            return response;
        }, []
    );

    const policyChangeAutoAssignAndGetVehicleDriverAssignments = useCallback(
        async (policyNumber, effectiveDate, jobID, updatingAssignment, authHeader) => {
            const response = await WniPolicyChangeVehicleService
                .autoAssignAndGetVehicleDriverAssignments(
                    policyNumber, effectiveDate, jobID, updatingAssignment, authHeader
                );
            return response;
        }, []
    );
    const policyChangeUpdateVehicleDriverAssignments = useCallback(
        async (policyNumber, effectiveDate, jobID, assignments, vehicleID, authHeader) => {
            const response = await WniPolicyChangeVehicleService
                .updateVehicleDriverAssignments(
                    policyNumber, effectiveDate, jobID, assignments, vehicleID, authHeader
                );
            return response;
        }, []
    );

    const policyChangeGetErrorsAndWarnings = useCallback(async (jobID, isCoveragePage, authHeader) => {
        const response = await WniPolicyChangeVehicleService.getErrorsAndWarnings(
            jobID,
            isCoveragePage,
            authHeader
        );
        return response;
    }, []);

    const overrideProps = {
        ...props,
        policyChangeGetAvailableDrivers,
        policyChangeAutoAssignAndGetVehicleDriverAssignments,
        policyChangeUpdateVehicleDriverAssignments,
        policyChangeGetErrorsAndWarnings
    };

    return <PADriverAssignmentPage {...overrideProps} />;
}

PADriverAssignmentChangePage.propTypes = wizardProps;
export default PADriverAssignmentChangePage;
