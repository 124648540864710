import { wizardProps } from '@xengage/gw-portals-wizard-react';

import { FNOLFireDamagePage as HOFireDamagePage } from 'wni-capability-fnol-ho-react';

import React from 'react';
import { withRouter } from 'react-router-dom';


function FNOLDPFireDamagePage(props) {
    
    return <HOFireDamagePage {...props} />;
    
}

FNOLDPFireDamagePage.propTypes = wizardProps;
export default withRouter(FNOLDPFireDamagePage);
