


import React from 'react';
import { IMBoatCoveragePage } from 'wni-capability-quoteandbind-im-react';


const IMBoatCoverageChangePage = (props) => {
    return <IMBoatCoveragePage {...props} isPolicyChange />
};
export default IMBoatCoverageChangePage
