import { defineMessages } from 'react-intl';

export default defineMessages({
    exclusionsAndConditionsTitle: {
        "id": "quoteandbind.gl.wizard.step.generalLiability page.Exclusions & Conditions",
        "defaultMessage": "Exclusions & Conditions"
    },
    addExclusionsAndConditionsText: {
        "id": "quoteandbind.gl.wizard.step.generalLiability page.Add Exclusions & Conditions",
        "defaultMessage": "Add Exclusions & Conditions"
    },
});
