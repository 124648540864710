import React, {
    // useContext,
    useCallback,
    // useEffect,
    // useState
} from 'react';
// import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
// import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { QuestionSetComponent } from 'gw-components-platform-react';

import {
    // ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    // WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import WizardPage from '../../templates/WCWizardPage';
import styles from './WCUnderwritingQuestionsPage.module.scss';
import metadata from './WCUnderwritingQuestionsPage.metadata.json5';
import wcQuestionSetsUtil from './utils/WCQuestionSetsUtil';
import messages from './WCUnderwritingQuestionsPage.messages';

const UNDERWRITING_QUESTION_PATH = 'lobData.workersComp.underwritingQuestionSets';
function WCUnderwritingQuestionsReadOnlyPage(props) {

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
    } = props;

    // const {
    //     jobID: quoteID, 
    //     sessionUUID,
    // } = submissionVM.value;

    const translator = useTranslator();

    const {
        initialValidation,
        onValidate,
        // invalidFields,
        // isComponentValid,
        // registerComponentValidation
    } = useValidation('WCUnderwritingQuestionsPage');

    
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );


    const questionSetFilter = wcQuestionSetsUtil.getQSFilterForWCUWQuetions()
    const questionsetAnswers = _.get(submissionVM, `${UNDERWRITING_QUESTION_PATH}.value[0].answers`) || {};
    const questionSetMapper = wcQuestionSetsUtil.getQSMapperForWcUnderwritingQuestions(questionsetAnswers, [translator(messages.UnderwritingQuestionsValidationMessages)]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            policyUnderwritingQuestionSets: {
                contentFilter: questionSetFilter,
                contentMapper: questionSetMapper,
                isReadOnly: true,
            }
        }
    }, [questionSetFilter, questionSetMapper]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // onNext={isComponentValid ? onPageNext : handleValidation}
            // pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                // showErrors={showErrors}
            />
        </WizardPage>
    );
}

WCUnderwritingQuestionsReadOnlyPage.propTypes = {
    ...WizardPage.propTypes,
};

WCUnderwritingQuestionsReadOnlyPage.defaultProps = {
    ...WizardPage.defaultProps,
}
export default WCUnderwritingQuestionsReadOnlyPage;