import { 
    SCHEDULE_ITEM_TYPES as CUSTOMER_SCHEDULE_ITEM_TYPES
} from 'customer-scheduleitems-config';
import LocationScheduleItem from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemTypes/LocationScheduleItem/LocationScheduleItem';
import ContactScheduleItem from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemTypes/ContactScheduleItem/ContactScheduleItem';
import SimpleScheduleItem from './SimpleScheduleItem/SimpleScheduleItem';

const SCHEDULE_ITEM_TYPES = {
    LOCATION: LocationScheduleItem,
    POLICYCONTACT: ContactScheduleItem,
    ...CUSTOMER_SCHEDULE_ITEM_TYPES,
}

export default function generateSubComponents(props) {
    const { valueType } = props.info;
    const scheduleItemType = SCHEDULE_ITEM_TYPES[valueType] || SimpleScheduleItem;

    return scheduleItemType(props);
}
