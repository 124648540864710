import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayRenewals'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the renewal backend operations
 *
 * @export
 * @class GatewayRenewalService
 */
export default class GatewayRenewalService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof RenewalService
     */
    static loadRenewal(data, additionalHeaders = {}) {
        return processSubmission('retrieve', [data], additionalHeaders);
    }

    static saveRenewal(data, additionalHeaders = {}) {
        return processSubmission('updateDraftRenewal', data, additionalHeaders);
    }

    static setQuoteToDraft(data, additionalHeaders = {}) {
        return processSubmission('setQuoteToDraft', data, additionalHeaders);
    }

    static updateCoverages(quoteID, sessionUUID, clausesToUpdate, additionalHeaders = {}) {
        return processSubmission('updateCoverages', [quoteID, clausesToUpdate, sessionUUID], additionalHeaders);
    }

    static generateQuote(data, additionalHeaders = {}) {
        return processSubmission('quote', data, additionalHeaders);
    }

    static isQuoted(data, additionalHeaders = {}) {
        return processSubmission('isQuoted', data, additionalHeaders);
    }

    static retrievePaymentPlans(data, additionalHeaders = {}) {
        return processSubmission('retrievePaymentPlans', data, additionalHeaders);
    }

    static bind(data, additionalHeaders = {}) {
        return processSubmission('bind', data, additionalHeaders);
    }

    static updatePaymentPlan(data, additionalHeaders = {}) {
        return processSubmission('updatePaymentPlan', data, additionalHeaders);
    }

    static getPolicyDiffWithPrevious(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policydiff'), 'getPolicyDiffWithPrevious', data, additionalHeaders);
    }

    static referToUnderwriter(renewalNumber, noteBody, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayRenewal'), 'referToUnderwriter', [renewalNumber, noteBody], additionalHeaders);
    }
}
