import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
    Tooltip, Icon, Loader
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { DriverComponent } from 'gw-capability-policyjob-react';

import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { InValidIconUtil, WniTableRowUtil } from 'wni-portals-util-react';

import { DriverUtil, DriverValidationUtil } from 'wni-portals-util-js';

// import styles from '../../PAWizard.module.scss'
import styles from './PADriversPage.module.scss';
import metadata from './PADriversPage.metadata.json5';
import messages from './PADriversPage.messages';

function DriversReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardPageData,
        jumpTo,
        steps
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [selectedRow, updateSelectedRow] = useState(null);
    const [showLoader, updateShowLoader] = useState(false);
    const [driverData, updateDriverData] = useState([]);
    const [accordionOpendIds, updateAccordionOpendIds] = useState([]);

    const {
        onValidate,
        isComponentValid,
    } = useValidation('DriverPage');
    const periodStatus = _.get(submissionVM, 'value.baseData.periodStatus');
    const baseData = _.get(submissionVM, 'value.baseData');
    const driversPath = 'lobData.personalAuto.coverables.drivers';
    const driversVM = _.get(submissionVM, driversPath);
    const policyCountryCode = _.get(submissionVM.value, 'baseData.policyAddress.country');

    const setRowIdPath = useCallback(() => {
        const drivers = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        drivers.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateDriverData(drivers);
    }, [submissionVM]);

    useEffect(() => {
        setRowIdPath();
        _.forEach(driversVM.children, (d) => {
            DriverUtil.updatePolicyCountryForDriver(d, policyCountryCode);
        });
    }, []);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'driverTable');
    };

    useEffect(() => {
        highlightRowFn(selectedRow);
    }, [selectedRow]);

    const titleRender = () => {
        return (
            <>
                {translator(messages.paDriversHouseholdMembers)}
                <span className="wizard-title-sub">{translator(messages.paDriversHouseholdMembersSub)}</span>
            </>
        );
    };
    const titleAction = useCallback(() => {
        return (
            null
        );
    }, []);

    const generateOverrides = useCallback(() => {
        const drivers = _.get(
            submissionVM,
            'lobData.personalAuto.coverables.drivers.value',
            []
        );
        const overrideProps = {};
        let readOnlyFields = [
            'driverFirstName',
            'driverLastName',
            'driverDateOfBirth',
        ];

        if (config.persona === 'producer' || config.persona === 'csr') {
            readOnlyFields = ['driverFirstName', 'driverLastName'];
        }

        drivers.forEach((driver, index) => {
            const isAccountHolder = driver.isPolicyHolder;

            let driverTitle = translator(messages.paDriverTitle);

            if (drivers.length > 1 && index !== 0) {
                driverTitle = `${translator(messages.paDriverTitle)} ${
                    index + 1
                }`;
            }

            overrideProps[`driver${index}`] = {
                readOnlyFields: isAccountHolder ? readOnlyFields : null,
            };
            overrideProps[`scanDriverLicenseBarcodeContainer${index}`] = {
                visible:
                    !isAccountHolder && (breakpoint === 'phone' || breakpoint === 'tablet'),
            };
            overrideProps[`paDriverTitle${index}`] = {
                value: driverTitle,
            };
            overrideProps[`scanDriverLicenseBarcode${index}`] = {
                onValueChange: undefined,
            };
            overrideProps[`paDeleteDriver${index}`] = {
                visible: !isAccountHolder,
            };
        });

        return overrideProps;
    }, [breakpoint, submissionVM, translator]);

    const onDriverValidationIconCell = useCallback((items, index) => {
        const driversVMColl = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
        const driverVM = driversVMColl.find((item) => item.value.rowIdPath === index);
        const valid = DriverValidationUtil.getDriverValid(driverVM, [], baseData, wizardPageData);
        const validForIssuance = DriverValidationUtil.getDriverValidForIssuance(driverVM);
        const iconMessages = {
            validToolTip: translator(messages.validToolTip),
            inValidToolTip: translator(messages.inValidToolTip),
            inValidForInsuranceToolTip: translator(messages.inValidForInsuranceToolTip)
        };
        const invalidIcounUtil = InValidIconUtil({ iconMessages });
        return invalidIcounUtil.renderInValidIcon(valid, validForIssuance);
    }, [baseData, submissionVM, translator]);

    const viewOrEditDriver = useCallback((value, index) => {
        const driversVMColl = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
        const driverVM = driversVMColl.find((item) => item.value.rowIdPath === index);
        updateSelectedRow(driverVM);
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        name: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.rowIdPath, DriverUtil.onNameCell(item))
        },
        dynamicInlineNotificationContainer: {
            visible: false
        },
        delDriver: {
            visible: false
        },
        addDriver: {
            visible: false
        },
        driverValidationIcon: {
            renderCell: onDriverValidationIconCell
        },
        driverIndex: {
            renderCell: (value, index) => {
                const driversData = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
                return driversData.findIndex((item) => item.rowIdPath === index) + 1;
            }
        },
        driverTable: {
            title: titleRender,
            renderTitleAction: titleAction,
            data: driverData,
            rowIdPath: 'rowIdPath'
        },
        driverAddInsured: { renderCell: (value) => { return value.additionalInsured_Ext ? 'Yes' : 'No'; } },
        licensed: { renderCell: DriverUtil.onLicensedCell },
        gender: { renderCell: (item) => DriverUtil.onGenderCell(item, translator) },
        // maritalStatus: { renderCell: DriverUtil.onMaritalStatusCell },
        relationship: { renderCell: (item) => DriverUtil.onRelationshipCell(item, translator) },
        ratedDriver: {
            renderCell: (item) => DriverUtil.onRatedDriverCell(item, periodStatus)
        },
        dateOfBirth: { renderCell: DriverUtil.onDateOfBirthCell },
        driver: {
            visible: selectedRow != null,
            path: selectedRow ? selectedRow.baseVMNode.pathToItem.asString : '',
            value: selectedRow,
            updateWizardData: updateWizardData,
            submissionVM: submissionVM,
            fromReadOnlyPage: true,
            showCoverages: true,
            baseData,
            wizardPageData,
            baseState: _.get(submissionVM, 'value.baseData.baseState_Ext'),
            primaryNamedInsured: _.get(submissionVM, 'value.baseData.accountHolder.displayName'),
            accordionOpendIds,
            updateAccordionOpendIds
        },
        viewOrEdit: {
            label: translator(messages.viewLabel),
            disabled: !isComponentValid
        },
        delete: {
            visible: false
        },
        ...generateOverrides(),
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    // const handleValidation = useCallback(
    //     () => {
    //         updateShowErrors(true);
    //         return false;
    //     },
    //     [updateShowErrors]
    // );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRemoveDriver: _.noop,
            onViewOrEditDriver: viewOrEditDriver,
            onValidate,
        },
        resolveComponentMap: {
            drivercomponent: DriverComponent
        }
    };

    // if (!isPageInitialized) {
    //     return null;
    // }

    return showLoader ? (<Loader showLoader />) : (
        <React.Fragment>
            <WizardPage>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={_.noop}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </WizardPage>
        </React.Fragment>
    );
}

DriversReadOnlyPage.propTypes = wizardProps;
export default DriversReadOnlyPage;
