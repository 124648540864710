import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WCCoverageUtil from '../../../Coverages/util/WCCoverageUtil';
import CoverageContext from '../../../Coverages/context/WCCoverageContext';
import WCClausesComponentVM from '../../../Coverages/components/WCClausesComponentVM/WCClausesComponentVM';
import WCSingleClauseComponentVM from '../../../Coverages/components/WCSingleClauseComponentVM/WCSingleClauseComponentVM';
import metadata from './WCStateStandardPartOneCoverages.metadata.json5';

function WCStateStandardPartOneCoverages(props) {
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService,
        loadingClauses,
        setLoadingClauses,
        stateCoveragesIndex,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const getStateStandardPart1Coverages = useCallback(() => {
        const stateStandardPart1Coverages = _.get(selectedVersion, `coverages.stateSpecificCoverages[${stateCoveragesIndex}].stateStandardCovsPart1`, []);
        return stateStandardPart1Coverages;
    }, [selectedVersion, stateCoveragesIndex]);

    const stateStandardPart1CoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.stateSpecificCoverages.children[${stateCoveragesIndex}].stateStandardCovsPart1`;
    const stateStandardPart1CoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.stateSpecificCoverages[${stateCoveragesIndex}].stateStandardCovsPart1`;

    const stateStandardPart1Coverages = getStateStandardPart1Coverages();

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = WCCoverageUtil.setClauseValue(stateStandardPart1Coverages, stateStandardPart1CoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, stateStandardPart1CoveragesPath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            stateStandardPart1Coverages,
            stateStandardPart1CoveragesVMPath,
            stateStandardPart1CoveragesPath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = WCCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = WCCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingClauses(updatedCoveragePublicIDs);
        const response = await coveragesService.updateStateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingClauses(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingClauses,
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            stateStandardPart1Coverages: {
                loadingClause: loadingClauses,
                path: stateStandardPart1CoveragesVMPath,
                value: stateStandardPart1Coverages,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    WCSingleClauseComponentVM: WCSingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
            }
        };
    }, [
        loadingClauses,
        stateStandardPart1CoveragesVMPath,
        stateStandardPart1Coverages,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
        },
        resolveComponentMap: {
            WCClausesComponentVM: WCClausesComponentVM,
        }
    };
    return (
        <CoverageContext.Provider
            value={{
                wizardData: submissionVM,
                updateWizardData: updateWizardData,
                lobName: lobName,
                selectedVersionIndex: selectedVersionIndex,
                coveragesService: coveragesService,
            }}
        >
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={{}}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </CoverageContext.Provider>
        
    );
}

WCStateStandardPartOneCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
WCStateStandardPartOneCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default WCStateStandardPartOneCoverages;
