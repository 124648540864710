
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLRiskAnalysisPage } from 'wni-capability-quoteandbind-gl-react';

function GLRiskAnalysisChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    return (
        <GLRiskAnalysisPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

GLRiskAnalysisChangePage.propTypes = GLRiskAnalysisPage.propTypes;
GLRiskAnalysisChangePage.defaultProps = GLRiskAnalysisPage.defaultProps;

export default GLRiskAnalysisChangePage;