import { MetadataContent } from '@jutro/legacy/uiconfig';
import { ServiceManager } from '@jutro/legacy/services';
import metadata from './Preferences.metadata.json5';
import styles from './Preferences.module.scss';
import './Preferences.messages';

import React from 'react';

function Preferences() {
    const localeService = ServiceManager.getService('locale-service');

    const overrideProps = {
        globalizationChooserField: {
            onLanguageValueChange: localeService.saveLanguage,
            languageValue: localeService.getStoredLanguage(),
            availableLanguageValues: localeService.getAvailableLanguages(),
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default Preferences;
