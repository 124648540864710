
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';

import { CRLocationsReadOnlyPage } from 'wni-capability-quoteandbind-cr-react';

function CRLocationsChangeReadOnlyPage(props) {

    return (
        <CRLocationsReadOnlyPage {...props} />
    );

};

export default CRLocationsChangeReadOnlyPage;