import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import FNOLDPWizard from './FNOLDPWizard.pe';
import FNOLDPTypeOfIncidentPage from './pages/TypeOfIncident/FNOLDPTypeOfIncidentPage.pe';
import FNOLDPIncidentDetailsPage from './pages/FNOLDPIncidentDetails/FNOLDPIncidentDetails.pe';
import FNOLDPThirdPartyLossDetailsPage from './pages/ThirdPartyLossDetails/FNOLDPThirdParyLossDetails.pe';
import FNOLDPFireDamagePage from './pages/FireDamage/FNOLDPFireDamagePage.pe';
import FNOLDPWaterDamagePage from './pages/WaterDamage/FNOLDPWaterDamagePage.pe';
import FNOLDPCriminalDamagePage from './pages/CriminalDamage/FNOLDPCriminalDamagePage.pe';

setComponentMapOverrides(
    {
        FNOLDPWizard: { component: 'FNOLDPWizard' },
        FNOLDPTypeOfIncidentPage: { component: 'FNOLDPTypeOfIncidentPage' },
        FNOLDPIncidentDetailsPage: { component: 'FNOLDPIncidentDetailsPage'},
        FNOLDPThirdPartyLossDetailsPage: { component : 'FNOLDPThirdPartyLossDetailsPage'},
        FNOLDPFireDamagePage: { component: 'FNOLDPFireDamagePage' },
        FNOLDPWaterDamagePage: { component: 'FNOLDPWaterDamagePage' },
        FNOLDPCriminalDamagePage: { component: 'FNOLDPCriminalDamagePage' }
    },
    {
        FNOLDPWizard,
        FNOLDPTypeOfIncidentPage,
        FNOLDPIncidentDetailsPage,
        FNOLDPThirdPartyLossDetailsPage,
        FNOLDPFireDamagePage,
        FNOLDPWaterDamagePage,
        FNOLDPCriminalDamagePage
    }
);

export {
    FNOLDPWizard as default
};
