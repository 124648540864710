import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './BaseWizardPageHeader.module.scss';
import BaseWizardPageHeader from './BaseWizardPageHeader';
/**
 * @prop {string} propTypes.wizardSubmission - submission view model
 * @prop {string} propTypes.isSideHeader - flag to specify side header
 */

const BaseWizardPageSideHeader = (props) => {

    return (
        <BaseWizardPageHeader {...props}
            sideHeaderConfigs = {{
                titleContainerClass: cx({
                    [styles.gwWizardPageTitle]: false,
                    [styles.gwWizardSidePageTitle]: true,
                }),
                categoryContainerClass: cx({
                    [styles.gwWizardPageCategory]: false,
                    [styles.gwWizardSidePageCategory]: true,
                }),
                associatedPageClass: cx({
                    [styles.gwWizardPageAssociated]: false,
                    [styles.gwWizardSidePageAssociated]: true,
                }),
                wizardSidePageTitleClass: cx({
                    [styles.gwWizardSidepageTitle]: true
                }),
                titleSeparator: (<div className={styles.gwWizardSidePageTitleSeparator} />)
            }}
        />
    );
};

BaseWizardPageSideHeader.propTypes = BaseWizardPageHeader.propTypes;

BaseWizardPageSideHeader.defaultProps = BaseWizardPageHeader.defaultProps;

export default BaseWizardPageSideHeader;
