

import React from 'react';
import { IMContractorsEquipmentPage } from 'wni-capability-quoteandbind-im-react';


const IMContractorsEquipmentChangePage = (props) => {
    return <IMContractorsEquipmentPage {...props} isPolicyChange />
};
export default IMContractorsEquipmentChangePage

