

import React from 'react';
import { IMBoatCoverageReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMBoatCoverageChangeReadOnlyPage = (props) => {
    return <IMBoatCoverageReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMBoatCoverageChangeReadOnlyPage
