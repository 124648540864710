import React, { useCallback } from 'react';

import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageHeader } from 'gw-portals-wizard-components-ui'

import { BaseDigitalWizardPageWithTitleTemplate } from 'wni-portals-wizard-components-ui';
// import WizardSingleErrorComponent from '../../../WizardSingleErrorComponent/WizardSingleErrorComponent'
// import DigitalWizardPageTemplate from '../DigitalWizardPageTemplate';

// import styles from './DigitalWizardPageWithTitleTemplate.module.scss';

function DigitalWizardPageWithTitleTemplate(props) {
    return <BaseDigitalWizardPageWithTitleTemplate {...props} />;
}

DigitalWizardPageWithTitleTemplate.propTypes = BaseDigitalWizardPageWithTitleTemplate.propTypes;
export default DigitalWizardPageWithTitleTemplate;
