import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { VehicleUtil } from 'wni-portals-util-js';
import VehicleCustomEquipmentTableComponent from '../VehicleCustomEquipmentTableComponent/VehicleCustomEquipmentTableComponent';
import metadata from './VehicleQuestionsComponent.metadata.json5';
import styles from './VehicleQuestionsComponent.module.scss';

/**
 * Component contains vehicle questions, including fields and question sets
 * @param {object} props
 * @returns {object} React Component
 */
const VehicleQuestionsComponent = (props) => {
    const {
        model: vehicleVM,
        onValueChange,
        showErrors,
        readOnly,
        isQuoteForNamedNonOwner,
        onValidate,
        baseState
    } = props;


    const {
        vehicleType_Ext: vehicleType,
        vehicleExplain_Ext: vehicleExplain,
        whatDelivered_Ext: whatDelivered,
        vehUsedInBusOwned_Ext: vehicleUsedInBusOwned,
        vehDrivenToWorkSchool_Ext: vehDrivenToWorkSchool,
        motorHomeRented_Ext: motorHomeRented,
        vehRecreationalTrailer_Ext: vehRecreationalTrailer,
        vehRTParked_Ext: vehRTParked,
    } = vehicleVM.value;
    
    const isNamedNonOwner = isQuoteForNamedNonOwner;


    const writeValue = (value, path) => {
        onValueChange(value, path);
    };

    const toggleAvailableValues = [
        {
            code: 'true',
            name: {
                id: 'quoteandbind.ca.directives.vehicle-incident.Yes',
                defaultMessage: 'Yes'
            }
        },
        {
            code: 'false',
            name: {
                id: 'quoteandbind.ca.directives.vehicle-incident.No',
                defaultMessage: 'No'
            }
        }
    ];

    const initUnavailableVehicleQuestions = [
        'HowmanyHoursperweek_Ext',
        'Whatisbeingdelivered_Ext',
        'Other_Ext',
        'NoVehicleNamedNonOwned_Ext'
    ];

    const getUnavailableVehicleQuestions = useCallback(() => {
        if (isQuoteForNamedNonOwner) {
            return [...initUnavailableVehicleQuestions, 'VehModHasSpecialEquipment_Ext'];
        }
        return initUnavailableVehicleQuestions;
    }, [initUnavailableVehicleQuestions, isQuoteForNamedNonOwner]);

    const isAnswerYes = useCallback((answer) => {
        return !_.isNil(answer) && answer;
    }, []);

    const isAnswerMatched = (answer, toBeMatched) => {
        return answer === toBeMatched;
    };

    const isPPAorNNO = VehicleUtil.isPPA(vehicleType) || VehicleUtil.isNamedNonOwner(vehicleType);

    //----------------------------------
    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        vehDrivenToWorkSchool_Ext: {
            visible: isPPAorNNO
        },
        milesToWorkSchoolOneWay_Ext: {
            visible: isAnswerYes(vehDrivenToWorkSchool) && isPPAorNNO
        },
        vehUsedInBusOwned_Ext: {
            visible: isPPAorNNO
        },
        vehicleExplain_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned) && isPPAorNNO
        },
        vehUsedInBizExplainOther_Ext: {
            visible: isAnswerMatched(vehicleExplain, 'Other') && isAnswerYes(vehicleUsedInBusOwned) && isPPAorNNO
        },
        howManyHours_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned)
                && isPPAorNNO
                && isAnswerMatched(vehicleExplain, 'DeliveryOfFood') // vehicleExplain === 'DeliveryOfFood'
        },
        whatDelivered_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned)
                && isPPAorNNO
                && isAnswerMatched(vehicleExplain, 'DeliveryOfFood') // vehicleExplain === 'DeliveryOfFood'
        },
        questionOfOther_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned)
                && isPPAorNNO
                && isAnswerMatched(vehicleExplain, 'DeliveryOfFood') // vehicleExplain === 'DeliveryOfFood'
                && isAnswerMatched(whatDelivered, 'Other_Ext') // whatDelivered === 'Other_Ext'
        },
        vehExclusiveUseToFarmOwned_Ext: {
            visible: isPPAorNNO
        },
        vehDrivenInMexico_Ext: {
            required: baseState==='AZ',
            visible: baseState==='AZ'
        },
        motorHomeRented_Ext: {
            visible: isAnswerMatched(vehicleType, 'motorhome_Ext'), // vehicleType === 'motorhome_Ext',
            availableValues: toggleAvailableValues
        },
        vehRecreationalTrailer_Ext: {
            visible: isAnswerMatched(vehicleType, 'rectrailer_Ext'), // vehicleType === 'rectrailer_Ext',
            availableValues: toggleAvailableValues
        },
        milePercentPerYear_Ext: {
            visible: (isAnswerMatched(vehicleType, 'motorhome_Ext') && isAnswerYes(motorHomeRented))
                || (isAnswerMatched(vehicleType, 'rectrailer_Ext') && isAnswerYes(vehRecreationalTrailer))
        },
        vehRTParked_Ext: {
            visible: isAnswerMatched(vehicleType, 'rectrailer_Ext'), // vehicleType === 'rectrailer_Ext',
            availableValues: toggleAvailableValues
        },
        vehRTParkedExplain_Ext: {
            visible: (isAnswerMatched(vehicleType, 'rectrailer_Ext') && isAnswerYes(vehRTParked))
        },
        vehicleUnderwritingQuestionSets: {
            isReadOnly: readOnly,
            visible: !isNamedNonOwner,
            contentFilter: (metadataContent) => !getUnavailableVehicleQuestions()
                .includes(metadataContent.id)
        },
        vehicleCustomEquipmentTableComponentId: {
            vehicleVM,
            visible: !isNamedNonOwner,
            readOnly,
            onValueChange
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            vehiclecustomequipmenttablecomponent: VehicleCustomEquipmentTableComponent,
            questionset: QuestionSetComponent,
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vehicleVM,
            id,
            path,
            overrideProps
        );
    };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
};

VehicleQuestionsComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool,
    isQuoteForNamedNonOwner: PropTypes.bool,
};

VehicleQuestionsComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
    readOnly: false,
    isQuoteForNamedNonOwner: false,

};

export default VehicleQuestionsComponent;
