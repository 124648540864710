import _ from 'lodash';
// import moment from 'moment';
const removeDataForSurchargeApplied = {
    Clue: {
        Select: 'nousecluerecord',
        Signature: 'noduplicatecluerecord'
    },
    MVR: {
        Select: 'No_Charge',
        Signature: 'nomvrduplicatesignature'
    }
};

/**
 * This method intends to replace the isClueCompare() and isMvrCompare() listed above.
 * @param {object} quoteDataDto
 * @returns {array} of boolean flags
 */
function getClueMVRCompareFlags(quoteDataDto) {
    const clueReport = _.get(quoteDataDto, 'lobData.personalAuto.clueReport_Ext', []);
    const mvrReport = _.get(quoteDataDto, 'lobData.personalAuto.mvrReport_Ext', []);

    const clueCompareFlag = clueReport.length > 0;
    const mvrCompareFlag = mvrReport.length > 0;
    return [clueCompareFlag, mvrCompareFlag];
}

/**
 * This method checks whether MVR and CLUE servies have been called.
 * @param {object} quoteDataDto
 * @returns {boolean}
 */
function checkMvrClueServiceCallStatus(quoteDataDto) {
    const mvrServiceCalled = _.get(quoteDataDto, 'lobData.personalAuto.risks_Ext.mvrServiceCalled');
    const clueServiceCalled = _.get(quoteDataDto, 'lobData.personalAuto.risks_Ext.clueServiceCalled');

    return mvrServiceCalled && clueServiceCalled;
}


/**
 * See WALIncidentsAndReportsPage
 * @param {object} quoteDataDto 
 * @param {string} lobDataPath 
 * @returns {array}
 */
function getClueMVRCompareFlagsR3(quoteDataDto, lobDataPath) {
    const clueReport = _.get(quoteDataDto, `lobData.${lobDataPath}.incidentsAndReports_Ext.cluereport`, []);
    const mvrReport = _.get(quoteDataDto, `lobData.${lobDataPath}.incidentsAndReports_Ext.mvrreport`, []);

    const clueCompareFlag = clueReport.length > 0;
    const mvrCompareFlag = mvrReport.length > 0;
    return [clueCompareFlag, mvrCompareFlag];
}

/**
 * add the regular drivers into Clue enter driver list
 * @param {object} drivers
 * @param {object} messages
 * @returns {object}
 */
function addRegularDrivers(drivers, messages) {
    drivers.push(
        {
            code: messages.DriverHousehold.defaultMessage,
            name: messages.DriverHousehold.defaultMessage
        }
    );
    drivers.push(
        {
            code: messages.NotApplicable.defaultMessage,
            name: messages.NotApplicable.defaultMessage
        }
    );
    drivers.push(
        {
            code: messages.PermissiveDriver.defaultMessage,
            name: messages.PermissiveDriver.defaultMessage
        }
    );
    drivers.push(
        {
            code: messages.Unoccupied.defaultMessage,
            name: messages.Unoccupied.defaultMessage
        }
    );
    return drivers;
}


/** For Clue records, if manual records are duplicate to the vendor records ,
 *  then after policy issued, only display the vendor records in the policy changes
 *  For MVR records, if manual records are duplicate to the vendor records ,
 *  then after policy issued, only display the vendor records in the  policy changes
 * @param {object} props
 * @returns {object} Clue or MVR filter data and table header message
 */
const filterManuallyData = (props) => {
    const {
        data,
        type,
        path,
        policyType,
        translator,
        headerMessage
    } = props;

    const filterData = data.filter((item) => {
        return item[path] !== removeDataForSurchargeApplied[type][policyType];
    });
    const str = filterData.length > 1 ? 's' : '';
    return {
        manuallyData: {
            visible: filterData.length > 0,
            data: filterData
        },
        header: translator(headerMessage, { length: filterData.length }) + str
    };
};
export default {
    addRegularDrivers,
    getClueMVRCompareFlags,
    filterManuallyData,
    checkMvrClueServiceCallStatus,
    //
    getClueMVRCompareFlagsR3,
};
