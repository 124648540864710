import React, {
    useState,
    useEffect,
} from 'react';
import { withRouter } from 'react-router-dom'
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WniUrlUtil } from 'wni-portals-util-js';
import CommonRoutes from '../Common/CommonRouting';
import pageRouting from '../Common/AccountSearch-config.json5';

function NewQuoteAccountSearch(props) {
    const {
        history,
        location: {
            search
        },
        match: {
            params,
            url
        }
    } = props;

    useEffect(() => {
        let parmasData = '';
        if (!_.isEmpty(params)) {
            parmasData = WniUrlUtil.getURLSearchStr(params);
        }
        history.push(`/new-quote-account-search/search${parmasData || search}`);
    }, []);

    return (
        <CommonRoutes
            steps={pageRouting}
            basePath={url}
        />
    );
}

NewQuoteAccountSearch.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            jobNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired
};

export default withRouter(NewQuoteAccountSearch);
