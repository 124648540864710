import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import {  useProductsData } from 'wni-portals-util-react';
import messages from './IssueApprovedQuotesPopup.messages';
import metadata from './IssueApprovedQuotesPopup.metadata.json5';

import { Button } from '@jutro/legacy/components';

function IssueApprovedQuotesPopup(props) {
    const {
        // title,
        accountType,
        size,
        actionBtnLabel,
        isOpen,
        onResolve,
        activePolicies,
        approvedQuotes,
        onNextBtnClick = _.noop,
        isPUSuggested,
        handleNewSubmission = _.noop,
        productsMap,
        useDependenciesData,
    } = props;
    const translator = useTranslator();
    const { domainCompany } = useDependenciesData;

    const handleNext = () => {
        onNextBtnClick();
        onResolve();
    };

    const getAlreadyOwnedProducts = () => {
        const alreadyOwnedProducts = [];
        activePolicies.forEach((policy) => {
            const productCode = _.get(policy, 'product.productCode');
            if (!alreadyOwnedProducts
                .some((alreadyOwnedProductCode) => alreadyOwnedProductCode === productCode)
            ) {
                alreadyOwnedProducts.push(productCode);
            }
        });
        approvedQuotes.forEach((job) => {
            const productCode = _.get(job, 'product.productCode');
            if (!alreadyOwnedProducts
                .some((alreadyOwnedProductCode) => alreadyOwnedProductCode === productCode)
            ) {
                alreadyOwnedProducts.push(productCode);
            }
        });
        return alreadyOwnedProducts;
    };

    const alreadyOwnedProducts = getAlreadyOwnedProducts();

    const getProductsDisplay = () => {
        return productsMap.map((item) => {
            if(item.code === 'PersonalUmbrella') {
                _.set(item, 'visible', isPUSuggested)
            } else {
                _.set(item, 'visible', !_.includes(alreadyOwnedProducts, item.code))
            }
            return item;
        });
    };
    const newSubmissionFn = (item) => {
        onResolve();
        handleNewSubmission(item);
    };
    const generateOverrides = () => {
        const productList = getProductsDisplay();
        const overrides = productList.map((item, index) => {
            return {
                [`quoteLine${index}`]: {
                    onClick: () => newSubmissionFn(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };
   
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        considerSection: {
            data: getProductsDisplay()
        },
        userAggreenmenLi2: {
            content: translator(messages.UserAggreenmenli2, { domainName: `${domainCompany.domainName}` }) 
        },
        ...generateOverrides(getProductsDisplay())
    };
    const resolvers = {
        resolveComponentMap: {
            
        },
        resolveCallbackMap: {
            // QuoteNewProductFn
        }
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader onClose={onResolve} title={translator(messages.considerTitle, { domainName: `${domainCompany.domainName}` })} />
            <ModalBody id="issueApprovedQuotes">
                <MetadataContent
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleNext}>
                    {actionBtnLabel || translator(messages.nextButton)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

IssueApprovedQuotesPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    // onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    activePolicies: PropTypes.arrayOf(PropTypes.shape({})),
    approvedQuotes: PropTypes.arrayOf(PropTypes.shape({})),
    onNextBtnClick: PropTypes.func.isRequired,
    isPUSuggested: PropTypes.bool.isRequired,
};

IssueApprovedQuotesPopup.defaultProps = {
    actionBtnLabel: undefined,
    size: 'md',
    activePolicies: [],
    approvedQuotes: [],
};

export default IssueApprovedQuotesPopup;
