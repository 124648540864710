import React from 'react';
import BusinessQuestionComponent from 'wni-capability-quoteandbind-ho-react/pages/HOResidence/Component/BusinessQuestionComponent/BusinessQuestionComponent';


function DPBusinessQuestionComponent(props) {

    const {
        namedInsuredOccupancy,
    } = props;

    const DPOverrideProps = {
        conductRemoteOfficeWork: {
            visible: namedInsuredOccupancy === 'owneroccupiedprimary_Ext'
                || namedInsuredOccupancy === 'owneroccupiedseasonal_Ext'
        },
    }

    return <BusinessQuestionComponent
        {...props}
        DPOverrideProps={DPOverrideProps}
    />
}

DPBusinessQuestionComponent.propTypes = BusinessQuestionComponent.propTypes;
DPBusinessQuestionComponent.defaultProps = BusinessQuestionComponent.defaultProps;

export default DPBusinessQuestionComponent;
