import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('crCommercialCrimeService'), method, data, additionalHeaders);
}

export default class CRCommonercialCrimeService {
  
    /**
     * Invokes CRCommercialCrimeHandler.getAccountsReveivableClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getCommercialCrimeWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getCommercialCrimeWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CRCommercialCrimeHandler.getCommercialCrime()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getCommercialCrime(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getCommercialCrime', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CRCommercialCrimeHandler.updateCommercialCrime()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} crCoveragesDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCommercialCrime(jobNumber, sessionUUID, crCoveragesDTO, authHeader = {}) {
        return processSubmission('updateCommercialCrime', [jobNumber, sessionUUID, crCoveragesDTO], authHeader);
    }

    /**
     * Invokes CRCommercialCrimeHandler.searchCommercialCrimeCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchCommercialCrimeCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, authHeader = {}) {
        return processSubmission('searchCommercialCrimeCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType], authHeader);
    }

    /**
     * Invokes CRCommercialCrimeHandler.addSearchedCommercialCrimeCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedCommercialCrimeCoverages(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedCommercialCrimeCoverages', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}