
import React from 'react';
import { RTVehiclesReadOnlyPage } from 'wni-capability-quoteandbind-rt-react';

function RTVehiclesChangeReadOnlyPage(props) {

    return (
        <RTVehiclesReadOnlyPage {...props} />
    );

}

RTVehiclesChangeReadOnlyPage.propTypes = RTVehiclesReadOnlyPage.propTypes;
RTVehiclesChangeReadOnlyPage.defaultProps = RTVehiclesReadOnlyPage.defaultProps;

export default RTVehiclesChangeReadOnlyPage;
