
import React from 'react';


import GLPolicyDetailsChangePage  from './GLPolicyDetailsChangePage';

function GLPolicyDetailsChangeReadOnlyPage(props) {

    return (
        <GLPolicyDetailsChangePage 
        {...props} 
        isReadOnly/>
    );

}

GLPolicyDetailsChangeReadOnlyPage.propTypes = GLPolicyDetailsChangePage.propTypes;
GLPolicyDetailsChangeReadOnlyPage.defaultProps = GLPolicyDetailsChangePage.defaultProps;

export default GLPolicyDetailsChangeReadOnlyPage;