import React from 'react';
import CARiskAnalysisReadOnlyPage from 'wni-capability-quoteandbind-ca-react/pages/RiskAnalysis/CARiskAnalysisPage.readonly'
import WizardPage from '../../templates/WCWizardPage';


function WCRiskAnalysisReadOnlyPage(props) {
    return (
        <CARiskAnalysisReadOnlyPage
            {...props}
            lobName='workersComp'
        />
    );
}

WCRiskAnalysisReadOnlyPage.propTypes = WizardPage.propTypes;
export default WCRiskAnalysisReadOnlyPage;