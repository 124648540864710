import React, { useMemo, useCallback, useContext } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Tooltip, useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './WizardSidebarButtons.messages';
import styles from './WizardSidebarButtons.module.scss';

import { Button } from '@jutro/legacy/components';

function printQuote() {
    window.print();
}

function WizardSidebarButtons() {
    const modalApi = useModal();
    const translator = useTranslator();
    const wizardContext = useContext(WizardContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { currentStep, wizardSnapshot: submissionVM } = wizardContext;

    const showEmailAddress = useCallback(() => {
        return !!_.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');
    }, [submissionVM]);

    const showPrintButton = useCallback(() => {
        return _.includes(currentStep.path, 'quote');
    }, [currentStep.path]);

    const emailQuote = useCallback(() => {
        const quoteID = _.get(submissionVM, 'quoteID.value');
        const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
        const emailAddress = _.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');

        const emailInfo = {
            quoteID,
            sessionID: sessionUUID
        };

        EmailQuoteService.emailQuote(emailInfo)
            .then(() => {
                modalApi.showAlert({
                    title: messages.saveForLater,
                    message: translator(messages.emailQuoteDescription, { quoteID, emailAddress }),
                    status: 'info',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.error,
                    message: messages.cantSendQuoteDetailsAtThisTime,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            });
    }, [submissionVM, translator]);

    const shouldShowEmailButton = showEmailAddress();
    const shouldShowPrintButton = showPrintButton();

    const emailButton = useMemo(() => {
        return (
            <Button
                icon="mi-email"
                type="outlined"
                className={styles.actionButtons}
                onClick={emailQuote}
            >
                {translator(messages.emailQuote)}
            </Button>
        );
    }, [emailQuote, translator]);

    const printButton = useMemo(() => {
        return (
            <Button
                icon="mi-print"
                type="outlined"
                onClick={printQuote}
                className={styles.actionButtons}
            >
                {translator(messages.printQuote)}
            </Button>
        );
    }, [translator]);

    if (!shouldShowEmailButton && !shouldShowPrintButton) {
        return null;
    }

    return (
        <div className={cx({ [styles.buttonContainer]: breakpoint === 'desktop' })}>
            {shouldShowEmailButton ? (
                <Tooltip
                    id="emailButton"
                    placement="bottom"
                    trigger="mouseenter"
                    content={translator(messages.emailQuote)}
                >
                    {emailButton}
                </Tooltip>
            ) : null}
            {shouldShowPrintButton ? (
                <Tooltip
                    id="printButton"
                    placement="bottom"
                    trigger="mouseenter"
                    content={translator(messages.printQuote)}
                >
                    {printButton}
                </Tooltip>
            ) : null}
        </div>
    );
}

export default WizardSidebarButtons;
