import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { CPStateSpecificInformationPage } from 'wni-capability-quoteandbind-cp-react';

function CPStateSpecificInformationChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('CP7Line', submissionVM);
    return (
        <CPStateSpecificInformationPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

CPStateSpecificInformationChangePage.propTypes = CPStateSpecificInformationPage.propTypes;
CPStateSpecificInformationChangePage.defaultProps = CPStateSpecificInformationPage.defaultProps;

export default CPStateSpecificInformationChangePage;

