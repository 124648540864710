import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, ToggleField } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateField } from 'wni-common-base-components';
import metadata from './FormPopupComponent.metadata.json5';

import { Button, InputField, CurrencyField, DropdownSelectField, InputNumberField } from '@jutro/legacy/components';

function FormPopupComponent(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        modalVM,
        viewModelService,
        modelProps,
    } = props;

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('FormPopupComponent');
    const translator = useTranslator();
    const { type, labelMap: labelMapList } = modelProps;
    const [newModalVM, updateNewModalVM] = useState(modalVM);
    const [readOnly, updateReadOnly] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [labelMap, updateLabelMap] = useState(labelMapList);
    useEffect(() => {
        if (type.toLowerCase() === 'view') {
            updateReadOnly(true);
        }
        if (type.toLowerCase() === 'edit') {
            updateReadOnly(false);
        }
    }, [modelProps, type]);

    const validValue = (val) => {
        /**
         * check the amount currency value
         */
        if (!_.isNil(val.currency) && _.isNil(val.value.amount)) {
            return false;
        }
        if (val.value) {
            return true;
        }
        return false;
    };
    const getLabelKey = useCallback(() => {
        return Object.keys(labelMap);
    }, [labelMap]);

    const isModalValid = useCallback(() => {
        const labelKey = getLabelKey();
        const validList = [];
        labelKey.map((key) => {
            const eachDto = newModalVM[key];
            if (eachDto && eachDto.aspects.required) {
                validList.push(eachDto);
            }
            return validList;
        });
        if (validList.every(validValue)) {
            return true;
        }
        return false;
    }, [getLabelKey, labelMap, newModalVM]);

    useEffect(() => {
        registerComponentValidation(isModalValid);
    }, [registerComponentValidation, isModalValid]);

    const writeValue = useCallback(
        (value, path) => {
            const getNewModalVM = viewModelService.clone(newModalVM);
            _.set(getNewModalVM, path, value);
            updateNewModalVM(getNewModalVM);
        },
        [labelMap, newModalVM, viewModelService]
    );
    const getDropDownValues = (typelist) => {
        return typelist.map((item) => {
            const availableValuesList = {
                code: item.code,
                name: translator({ id: item.name })
            };
            return availableValuesList;
        });
    };
    const renderControl = (rendorProps, index) => {
        const {
            path, label, inputCtrlType, availableValues, required,
            visible, disabled, tooltip, radioValues, value
        } = rendorProps;
        let rendorDom;

        const inputProps = {
            path,
            label,
            required,
            visible,
            disabled,
            tooltip,
            showRequired: true,
            value,
            readOnly: readOnly,
            showErrors: showErrors,
            onValueChange: writeValue,
            type: 'field',
            labelPosition: 'left',
            placeholder: required ? '-- Required for Quote --' : ''
        };
        const availableList = availableValues ? getDropDownValues(availableValues) : [];
        switch (inputCtrlType) {
            case 'date':
                rendorDom = (
                    <WniDateField
                        id={`date${index}`}
                        {...inputProps}
                    />
                );
                break;
            case 'text':
                rendorDom = (
                    <InputField
                        id={`text${index}`}
                        {...inputProps}
                    />
                );
                break;
            case 'currency':
                if (!_.isObject(value)) {
                    rendorDom = (
                        <CurrencyField
                            defaultCurrency="USD"
                            id={`currency${index}`}
                            {...inputProps}
                            value={{
                                amount: value,
                                currency: 'USD'
                            }}
                        />
                    );
                } else {
                    rendorDom = (
                        <CurrencyField
                            defaultCurrency="USD"
                            id={`currency${index}`}
                            {...inputProps}
                        />
                    );
                }
                break;
            case 'typelist':
                rendorDom = (
                    <DropdownSelectField
                        id={`typelist${index}`}
                        dataType="object"
                        availableValues={availableList}
                        {...inputProps}
                    />
                );
                break;
            case 'boolean':
                rendorDom = (
                    <ToggleField
                        availableValues={radioValues}
                        id={`radiobutton${index}`}
                        className="radioBtn-horizontal"
                        {...inputProps}
                    />
                );
                break;
            case 'number':
                rendorDom = (
                    <InputNumberField
                        id={`inputNumberFiled${index}`}
                        {...inputProps}
                    />
                );
                break;
            default:
                rendorDom = (
                    <InputField
                        id={`text${index}`}
                        {...inputProps}
                    />
                );
                break;
        }
        return rendorDom;
    };
    const onRenderContentFn = () => {
        const labelKey = getLabelKey();
        return (
            <ul>
                {
                    labelKey.map((key, index) => {
                        const eachDto = newModalVM[key];
                        const eachValue = _.get(newModalVM, `${key}.value`);
                        const labelMsg = labelMap[key].label.id
                            ? translator(labelMap[key].label) : labelMap[key].label;
                        const { aspects: { inputCtrlType, availableValues, required } } = eachDto;
                        const rendorProps = {
                            path: key,
                            label: labelMsg,
                            disabled: labelMap[key].disabled,
                            tooltip: labelMap[key].tooltip,
                            radioValues: labelMap[key].radioValues,
                            searchable: labelMap[key].searchable,
                            inputCtrlType: labelMap[key].inputCtrlType || inputCtrlType,
                            availableValues,
                            required,
                            value: eachValue,
                        };
                        if (eachDto) {
                            if ('currency' in eachDto) {
                                rendorProps.inputCtrlType = 'currency';
                            }
                            return (
                                <li>
                                    {renderControl(rendorProps, index)}
                                </li>
                            );
                        }
                        return null;
                    })
                }
            </ul>
        );
    };

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
        },
        [updateShowErrors]
    );
    const handleSave = useCallback(
        () => {
            if (!isComponentValid) {
                handleValidation();
            } else {
                onResolve(newModalVM.value);
            }
        },
        [onResolve, handleValidation, isComponentValid, newModalVM]
    );
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
            showErrors: showErrors
        },
        tableDetailsList: {
            content: onRenderContentFn()
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            newModalVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        }
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="tablePopupModal">
                <ViewModelForm
                    model={newModalVM}
                    overrideProps={overrideProps}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

FormPopupComponent.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    modalVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    modelProps: PropTypes.shape({
        type: PropTypes.string.isRequired,
        labelMap: PropTypes.arrayOf.isRequired
    }).isRequired,
};
FormPopupComponent.defaultProps = {
    size: 'md'
};

export default FormPopupComponent;
