import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Chevron } from '@jutro/components';

import { Accordion, AccordionCard } from '@jutro/legacy/components';

function CPPPolicyLineSummarySublineContainer(props) {

    const {
        sublines,
    } = props;

    if (_.isEmpty(sublines)) {
        return null;
    }

    const availableSublines = sublines.filter((line) => {
        const lineSummary = _.get(line, 'componentProps.policyLineSummary');
        return !_.isEmpty(lineSummary);
    });
    
    if (_.isEmpty(availableSublines)) {
        return null;
    }

    const renderAccordionCards = () => {
        return availableSublines.map((line, index) => {
            const {
                title,
                component: Component,
                componentProps,
            } = line;

            return (
                <AccordionCard id={`accordionCard_${title}`}
                    title={title}
                    initialExpanded // initialExpanded not working? LOL. // {index === 0} 
                    chevron
                    >
                    <Component {...componentProps} />
                </AccordionCard>
            );
        });
    };

    return (
        <Accordion chevronPosition="left">
            {renderAccordionCards()}
        </Accordion>
    );
    
}


CPPPolicyLineSummarySublineContainer.propTypes = {
    
};

CPPPolicyLineSummarySublineContainer.defaultProps = {
    
};


export default CPPPolicyLineSummarySublineContainer;