import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageHeader } from 'gw-portals-wizard-components-ui'
// import WizardSingleErrorComponent from '../../../WizardSingleErrorComponent/WizardSingleErrorComponent'
import DigitalWizardPageTemplate from '../BaseDigitalWizardPageTemplate';

import styles from './BaseDigitalWizardPageWithTitleTemplate.module.scss';

function BaseDigitalWizardPageWithTitleTemplate(props) {
    const {
        children,
        // acknowledgeError, errorsForStep,

        // Only used in CPBuildingsAndLocationsPage;
        isSideHeader,
        ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        (wizardData) => {
            // const getSubmissionVM = wizardData.submissionVM || wizardData;
            // return (
            //     <WizardPageHeader wizardSubmission={getSubmissionVM} isSideHeader={isSideHeader} />
            // );
            return <WizardPageHeader isSideHeader={isSideHeader} />;
        },
        [isSideHeader]
    );

    return (
        <div className={styles.wizardContainer}>
            <DigitalWizardPageTemplate
                // renderContextComponent={renderWizardHeader}
                showContextComponent={false}
                showWizardHeader
                {...otherProps}
            >
                {/* <WizardSingleErrorComponent
                    acknowledgeError={acknowledgeError}
                    issuesList={errorsForStep}
                /> */}
                {children}
            </DigitalWizardPageTemplate>
        </div>
    );
}

BaseDigitalWizardPageWithTitleTemplate.pageTemplateProps;

export default BaseDigitalWizardPageWithTitleTemplate;
