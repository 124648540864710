import { defineMessages } from 'react-intl';

export default defineMessages({
    wantToChangeLine: {
        id: 'pe.fnol.cpp.views.select-line.wantToChangeLine',
        defaultMessage: 'Change Line of Business?'
    },
    claimDataEnteredCleared: {
        id: 'pe.fnol.cpp.views.select-line.claimDataEnteredCleared',
        defaultMessage: 'We will not save the information you have entered, do you want to continue?'
    },
    cpLine: {
        id: 'pe.fnol.cpp.views.select-line.Commercial Property',
        defaultMessage: 'Commercial Property'
    },
    crLine: {
        id: 'pe.fnol.cpp.views.select-line.Crime',
        defaultMessage: 'Crime'
    },
    glLine: {
        id: 'pe.fnol.cpp.views.select-line.General Liability',
        defaultMessage: 'General Liability'
    },
    imLine: {
        id: 'pe.fnol.cpp.views.select-line.Inland Marine',
        defaultMessage: 'Inland Marine'
    },
});
