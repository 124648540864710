import { defineMessages } from 'react-intl';

export default defineMessages({
    coverages: {
        id: 'quoteandbind.bop.views.bop-confirmation.section.Coverages',
        defaultMessage: 'Coverages',
    },
    contactInformationDetails: {
        id: 'quoteandbind.bop.views.bop-confirmation.Contact Information Details',
        defaultMessage: 'Contact Information Details',
    },
    email: {
        id: 'quoteandbind.bop.views.bop-confirmation.Email',
        defaultMessage: 'Email',
    },
    address: {
        id: 'quoteandbind.bop.views.bop-confirmation.Address',
        defaultMessage: 'Address',
    }
});
