import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalCoverages: {
        "id": "quoteandbind.cr.wizard.step.StateInfo Page.Additional Coverages",
        "defaultMessage": "Additional Coverages"
    },
    AddAdditionalCoverages: {
        "id": "quoteandbind.cr.wizard.step.StateInfo page.Add Additional Coverages",
        "defaultMessage": "Add Coverages"
    },
});