import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { VehicleClausesUtil } from 'wni-portals-util-js';
import metadata from './VehicleCoveragesComponent.metadata.json5';
import styles from './VehicleCoveragesComponent.module.scss';


/**
 * Component contains vehicle coverages
 * @param {object} props
 * @returns {object} React Component
 */
const VehicleCoveragesComponent = (props) => {
    const {
        id,
        vehicleVM,
        submissionVM,
        pathToVehicleCoverageVM,
        showErrors,
        readOnly,
        coverages,
        onClauseUpdate,
        onValidate
    } = props;

    const [loadingClause, updateLoadingClause] = useState(false);


    const initCoverages = useCallback(() => {
        _.set(vehicleVM, 'coverages', coverages);
    }, [coverages, vehicleVM]);

    useEffect(() => {
        // load coverages into vehicleVM
        initCoverages();
    }, [id, initCoverages]);

    const syncCoverages = useCallback(async (value, changedPath, newSubmissionVM) => {
        updateLoadingClause(true);

        await onClauseUpdate(() => {
            initCoverages();
        }, newSubmissionVM);
        updateLoadingClause(false);
    }, [initCoverages, onClauseUpdate]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = _.clone(submissionVM);
            const selectedVehicleCoverageVM = _.get(submissionVM, pathToVehicleCoverageVM);
            VehicleClausesUtil.setClauseValue(selectedVehicleCoverageVM, value, changedPath);
            _.set(newSubmissionVM, pathToVehicleCoverageVM, selectedVehicleCoverageVM);
            return newSubmissionVM;
        },
        [submissionVM, pathToVehicleCoverageVM]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            syncCoverages(value, changedPath, newSubmissionVM);
        },
        [changeSubmission, syncCoverages]
    );

    //----------------------------------
    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        clauseLoader: {
            visible: loadingClause
        },
        clauses_from_VM: {
            visible: !loadingClause,
            loadingClause: false,
            value: coverages ? coverages.value : [],
            isEditable: !readOnly
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate,
            onSyncCoverages: _.noop,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: changeSubmissionAndSync,
            onChangeClause: _.noop,
        },
        resolveComponentMap: {
        },
    };

    const readValue = (innerId, innerPath) => {
        return readViewModelValue(
            metadata.componentContent,
            vehicleVM,
            innerId,
            innerPath,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            resolveValue={readValue}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            showErrors={showErrors}
        />
    );
};

VehicleCoveragesComponent.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    vehicleVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    pathToVehicleCoverageVM: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool,
    coverages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClauseUpdate: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

VehicleCoveragesComponent.defaultProps = {
    submissionVM: {},
    vehicleVM: {},
    showErrors: false,
    readOnly: false,

};

export default VehicleCoveragesComponent;
