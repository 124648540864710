import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';


import CPPRiskAnalysisPage from './CPPRiskAnalysisPage';

function CPPRiskAnalysisReadOnlyPage(props) {
    return <CPPRiskAnalysisPage {...props} isReadOnly />
}


export default CPPRiskAnalysisReadOnlyPage;