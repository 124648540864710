import React, {useEffect} from 'react';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import MainCoveragesCard from '../../../components/IMCommonMainCoverage/MainCoveragesCard';

const LineMainCoveragesComponent = (props) => {

    const {
        id: validationID,
        coveragePartClauses,
        setCoveragePartClauses,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        wizardData,
        updateWizardDataWhileSetPeriodStatus,
        coverageFormPath,
        updateCoverageFormClausesService,
        calculateLimit,
        coveragePatterns
    } = props


    const { onValidate, isComponentValid } = useValidation(validationID);

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate, validationID])

    return (
            <div className="formCoveragesContainer">
                
                {!_.isEmpty(coveragePatterns) && coveragePatterns.map((mainCoverage, index) => {
                    const coverageLabel = _.get(mainCoverage, 'visibleOfCoverageLabel_Ext');
                    return <>
                    {coverageLabel && <h4>{coverageLabel}</h4>}
                    <MainCoveragesCard
                        coveragePartClauses = {coveragePartClauses}
                        setCoveragePartClauses = {setCoveragePartClauses}
                        setIsEditing = {setIsEditing}
                        isEditable = {isEditable}
                        showErrors = {showErrors}
                        onValidate = {onValidate}
                        wizardData = {wizardData}
                        updateWizardDataWhileSetPeriodStatus = {updateWizardDataWhileSetPeriodStatus}
                        mainCoverage = {mainCoverage}
                        coverageFormPath = {`${coverageFormPath}.[${index}]`}
                        updateCoverageFormClausesService = {updateCoverageFormClausesService}
                        calculateLimit = {calculateLimit}
                        />
                    </>
                })}
            </div>
    )
}

export default LineMainCoveragesComponent