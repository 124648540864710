import { defineMessages } from 'react-intl';

export default defineMessages({
    policyInformation: {
        id: 'quoteandbind.bop.views.policy-info.Policy Info',
        defaultMessage: 'Policy Information'
    },
    coverageStartDate: {
        id: 'quoteandbind.bop.views.policy-info.Coverage Start Date',
        defaultMessage: 'Coverage Start Date'
    },
    primaryInsured: {
        id: 'quoteandbind.bop.views.policy-info.Primary Insured',
        defaultMessage: 'Primary Insured'
    },
    address: {
        id: 'quoteandbind.bop.views.policy-info.Address',
        defaultMessage: 'Address'
    },
    sameBillingAddress: {
        id: 'quoteandbind.bop.views.policy-info.Is the Billing Address the same as the above address?',
        defaultMessage: 'Is the Billing Address the same as the above address?'
    },
    email: {
        id: 'quoteandbind.bop.views.policy-info.Email',
        defaultMessage: 'Email'
    },
    phone: {
        id: 'quoteandbind.bop.views.policy-info.Phone',
        defaultMessage: 'Phone'
    }
});
