import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniDateUtil } from 'wni-portals-util-js';
import WizardPage from '../../templates/HOWizardPage';
import staticMessage from './HOLossHistoryAndPriorCarrierPage.static'

import styles from './HOLossHistoryAndPriorCarrierPage.module.scss';
import metadata from './HOLossHistoryAndPriorCarrierPage.metadata.json5';
import { CurrencyField } from '@jutro/legacy/components';

function HOLossHistoryAndPriorCarrierReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        showPolicyClaims,
        productName,
    } = props;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        initialValidation,
    } = useValidation('HOLossHistoryAndPriorCarrierPage');
    const policySource = _.get(submissionVM, 'value.baseData.policyInfoSource_Ext');     
    const [vendorClueReports, updateVendorClueReports] = useState([]);

    const {
        lobData: {
            [productName]: {
                priorCarrierAndLossHistory_Ext:{
                    priorCarrierEnter = [],
                    clueEnter = [],
                    claimReport = [],
                }
            } 
        },
    } = submissionVM.value;

    const mapDisputeValue = (reportList) => {
        reportList.map((report) => {
            if (report.dispute === "disputeweatherclaim") {
                _.set(report, 'dispute', "disputeother");
                _.set(report, 'explanationBox', "Weather Claim");
            }
            return report;
        });
        return reportList;
    };

    useEffect(() => {
        const {
            lobData: {
                [productName]: {
                    priorCarrierAndLossHistory_Ext:{
                        clueReport = [],
                    }
                } 
            },
        } = submissionVM.value;
        const newClueReport = mapDisputeValue(clueReport);
        updateVendorClueReports(newClueReport);
    }, []);

    const onColumnCell = useCallback(
        (item, index, property, type) => {
            const { path, typeKey } = property;
            let flag;
            if (type === 'clue') {
                flag = false;
            }
            if (type === 'priorCarrier') {
                flag = false;
            }
            const classNames = flag && item.flag ? 'highlight' : '';
            if (item[path] && item[path].year) {
                return (
                    <div className={classNames}>{WniDateUtil.formatDateWithPattern(item[path])}</div>
                );
            }
            if (item[path] && item[path].currency) {
                return (
                    <CurrencyField
                        id={`currency_${index}`}
                        value={item[path]}
                        readOnly
                        hideLabel
                        showOptional={false}
                    />
                );
            }
            if (path === 'clueLossCategory' && item[path]=== 'NonWeather'){
                return(
                    <div className={classNames}>Non-Weather</div>
                ) 
            }
            const val = typeKey
                ? translator({ id: `${typeKey}.${item[path]}` })
                : item[path];
            return (
                <div className={classNames}>{`${item[path] ? val : ''}`}</div>
            );
        },
        []
    );

    
    const getSubUserEnterStates = () => {
        const userEnterStates = [];
        if (priorCarrierEnter.length > 0) {
            userEnterStates.push('userEnteredPriorCarrierCard');
        }
        if (clueEnter.length > 0) {
            userEnterStates.push('userEnteredPriorLossesCard');
        }
        return userEnterStates;
    };
    const getSubVendorStates = () => {
        const vendorStates = [];
        if (vendorClueReports.length > 0) {
            vendorStates.push('vendorPriorLossesCard');
        }
        return vendorStates;
    };

    const getAccordionCardTitle = useCallback(
        (recordLength, titlePrefix = 'Prior Losses') => {
            const isPlural = recordLength > 1;
            const suffix = isPlural ? ' Records' : ' Record';
            const prefix =
                productName === 'dwellingProperty'
                    ? `${titlePrefix} - `
                    : `${titlePrefix} `;
            return `${prefix}${recordLength}${suffix}`;
        },
        [productName]
    );

    // converted policy NB show claim card and policychange always show claim card
    const showVendorPolicyClaims = (policySource === 'Converted' || showPolicyClaims);
    //---------------------
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        // dynamicInlineNotificationContainer: {
        //     visible: false
        // },
        // prior carrier add manually
        userAddedPriorCarrierTable: {
            visible: priorCarrierEnter.length > 0,
            data: priorCarrierEnter
        },
        // add button only display when no record showing up
        userProiorHeader: {
            visible: _.isEmpty(priorCarrierEnter),
        },
        // clue add manually
        userAddedPriorLossTable: {
            visible: clueEnter.length > 0,
            data: clueEnter,
        },
        // vendor clue report
        priorLossCLUETable: {
            visible: vendorClueReports.length > 0,
            data: vendorClueReports,
        },
        // vendor claim report 
        claimInfo: {
            visible:showVendorPolicyClaims
        },
        reportedPolicyClaimsTable: {
            visible: claimReport.length > 0,
            data: claimReport
        },
        // length of item showing on U
        userEnteredPriorCarrierCard: {
            title: getAccordionCardTitle(
                priorCarrierEnter.length,
                'Prior Carrier'
            ),
        },
        userEnteredPriorLossesCard: {
            title: getAccordionCardTitle(clueEnter.length),
        },
        vendorPriorLossesCard: {
            title: getAccordionCardTitle(vendorClueReports.length),
        },
        claimPriorLossesCard: {
            title: getAccordionCardTitle(claimReport.length),
        },
        userEnteredSubInfo: {
            accordionStates: getSubUserEnterStates()
        },
        vendorSubInfo: {
            accordionStates: getSubVendorStates()
        },
        // for read only mode
        addCarrierBtn: {
            visible: false
        },
        addPriorlossBtn: {
            visible: false
        },
        clueCauseOfLossCol: {
            visible: false
        }
    }
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onColumnCell: onColumnCell,
            onColumnPriorCarrierCell: (item, index, property) => onColumnCell(item, index, property, 'priorCarrier'),
            onColumnClueCell: (item, index, property) => onColumnCell(item, index, property, 'clue'),
            onColumnPolicyClaimsCell: (item, index, property) => onColumnCell(item, index, property, 'policyClaims')
        },
        resolveComponentMap: {
            // validationissuescomponent: ValidationIssuesComponent,
        }
    };

    return (
        <WizardPage
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}
HOLossHistoryAndPriorCarrierReadOnlyPage.propTypes = {
    ...wizardProps,
    showPolicyClaims: PropTypes.bool,
    productName: PropTypes.string,
};
HOLossHistoryAndPriorCarrierReadOnlyPage.defaultProps = {
    showPolicyClaims: false,
    productName: 'homeowners',
};
export default HOLossHistoryAndPriorCarrierReadOnlyPage;