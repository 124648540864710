import appConfig from 'app-config';
import _ from 'lodash';
import ConfigUtil from '../../wni-portals-util-js/ConfigUtil';




const QUOTE_STATE_DELIMITER = '|';


/**
 * Get available states from AOConfig
 *
 * Examples:
 * parseCodeList(['IA', 'MN']); //['IA', 'MN']
 * parseCodeList('IA| MN'); //['IA', 'MN']
 *
 * @param {string | array} quoteStatesList
 * @returns {array}
 */
function parseCodeList(quoteStatesList) {
    let retval = [];
    if (_.isArray(quoteStatesList)) {
        retval = quoteStatesList;
    } else if (_.isString(quoteStatesList)) {
        retval = quoteStatesList.split(QUOTE_STATE_DELIMITER)
            .map((code) => code.trim())
            .filter((code) => code);
    }
    return retval;
}

// see config.json5
const {
    AgentsOnlineConfig: {
        availableQuoteStates // An array of State Code, or a String of state codes separated by ","
        
    }
} = appConfig;


const quoteStatesList = ConfigUtil.getBrandedDefaultStates();
const aoStates = parseCodeList(quoteStatesList);

/**
 * Filter states by codes supported by AgentsOnline
 * 
 * <p>
 * INPUT: [{name: 'Iowa', code: 'IA'}, {name: 'New York', code: 'NY'}]
 * </p>
 * <p>
 * OUTPUT: [{name: 'Iowa', code: IA'}]
 * </p>
 * @param {array} codeNamePairList [{name, code}]
 * @returns {array}
 */
function filterStatesByCode(codeNamePairList) {
    const retval = codeNamePairList.filter((codeNamePair) => aoStates.includes(codeNamePair.code));
    return retval;
}


export default { 
    filterStatesByCode,
    aoStates
};
