import { defineMessages } from 'react-intl';

export default defineMessages({
    claimDetailNumber: {
        id: 'claim.views.claim-detail.Claim Details',
        defaultMessage: 'Claim Details ({ num })'
    },
    summaryTab: {
        id: 'claim.views.claim-detail.Summary',
        defaultMessage: 'Summary'
    },
    notesTab: {
        id: 'claim.views.claim-detail.Notes',
        defaultMessage: 'Notes'
    },
    documentsTab: {
        id: 'claim.views.claim-detail.Documents',
        defaultMessage: 'Documents'
    },
    paymentsTab: {
        id: 'claim.views.claim-detail.Payments',
        defaultMessage: 'Payments'
    },
    servicesTab: {
        id: 'claim.views.claim-detail.Services',
        defaultMessage: 'Services'
    },
    policyNumberClaimDetail: {
        id: 'claim.views.claim-detail.Policy',
        defaultMessage: 'Policy'
    },
    policyStatus: {
        id: 'claim.views.claim-detail.Status',
        defaultMessage: 'Status'
    },
    reportedBy: {
        id: 'claim.views.claim-detail.Reported By',
        defaultMessage: 'Reported By'
    },
    accountNumber: {
        id: 'claim.views.claim-detail.Account',
        defaultMessage: 'Account'
    },
    dateOfLossClaimDetail: {
        id: 'claim.views.claim-detail.Date of Loss',
        defaultMessage: 'Date of Loss'
    },
    totalPayments: {
        id: 'claim.views.claim-detail.Paid',
        defaultMessage: 'Paid'
    },
    totalIncurrednet: {
        id: 'claim.views.claim-detail.Net Incurred',
        defaultMessage: 'Net Incurred'
    },
    adjusterTitle: {
        id: 'claim.views.claim-detail.Adjuster',
        defaultMessage: 'Adjuster'
    },
    adjusterName: {
        id: 'claim.views.claim-detail.Name',
        defaultMessage: 'Name'
    },
    adjusterPhone: {
        id: 'claim.views.claim-detail.Phone',
        defaultMessage: 'Phone'
    },
    adjusterAddnoteButton: {
        id: 'claim.views.claim-detail.Add Note',
        defaultMessage: 'Add Note'
    },
    addnoteButton: {
        id: 'claim.views.claim-detail.Note',
        defaultMessage: 'Note'
    },
    partiesInvolved: {
        id: 'claim.views.claim-detail.Parties Involved',
        defaultMessage: 'Parties Involved'
    },
    vendorsInvolved: {
        id: 'claim.views.claim-detail.Vendors involved',
        defaultMessage: 'Vendors Involved'
    },
    involvement: {
        id: 'claim.views.claim-detail.Involvement',
        defaultMessage: 'Involvement'
    },
    vehicle: {
        id: 'claim.views.claim-detail.Vehicle(s)',
        defaultMessage: 'Vehicle(s)'
    },
    make: {
        id: 'claim.directives.pa-vehicle-coverages.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'claim.directives.pa-vehicle-coverages.Model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'claim.directives.pa-vehicle-coverages.Year',
        defaultMessage: 'Year'
    },
    license: {
        id: 'claim.directives.pa-vehicle-coverages.License #',
        defaultMessage: 'License #'
    },
    coveragesFor: {
        id: 'claim.directives.pa-vehicle-coverages.Coverages for',
        defaultMessage: 'Coverages for {vehicleMake} {vehicleModel}'
    },
    type: {
        id: 'claim.directives.pa-vehicle-coverages.Type',
        defaultMessage: 'Type'
    },
    deductible: {
        id: 'claim.directives.pa-vehicle-coverages.Deductible',
        defaultMessage: 'Deductible'
    },
    limittype: {
        id: 'claim.directives.pa-vehicle-coverages.Limit Type',
        defaultMessage: 'Limit Type'
    },
    limitvalue: {
        id: 'claim.directives.pa-vehicle-coverages.Limit Value',
        defaultMessage: 'Limit Value'
    },
    perAccident: {
        id: 'claim.directives.pa-vehicle-coverages.Per Accident',
        defaultMessage: 'Per Accident'
    },
    perPerson: {
        id: 'claim.directives.pa-vehicle-coverages.Per Person',
        defaultMessage: 'Per Person'
    },
    lossLocation: {
        id: 'claim.views.claim-detail.Loss Location',
        defaultMessage: 'Loss Location'
    },
    employerNotified: {
        id: 'claim.views.claim-detail.Date Employer Notified',
        defaultMessage: 'Date Employer Notified'
    },
    daysInHospital: {
        id: 'claim.views.claim-detail.Hospital',
        defaultMessage: 'Days spent in the hospital'
    },
    noNotes: {
        id: 'claim.views.claim-detail.No notes associated with this claim',
        defaultMessage: 'There are no notes associated with this claim.'
    },
    notesAuthor: {
        id: 'claim.directives.claim-notes.Author',
        defaultMessage: 'Author'
    },
    notesDate: {
        id: 'claim.directives.claim-notes.Date / Time',
        defaultMessage: 'Date / Time'
    },
    notesSubject: {
        id: 'claim.directives.claim-notes.Subject',
        defaultMessage: 'Subject'
    },
    notesContent: {
        id: 'claim.directives.claim-notes.Note',
        defaultMessage: 'Note'
    },
    searchPlaceholder: {
        id: 'claim.directives.claim-notes.Search',
        defaultMessage: 'Search'
    },
    createNoteSubject: {
        id: 'claim.views.create-note.Subject',
        defaultMessage: 'Subject'
    },
    createNoteCancel: {
        id: 'claim.views.create-note.Cancel',
        defaultMessage: 'Cancel'
    },
    createNoteSave: {
        id: 'claim.views.create-note.Save',
        defaultMessage: 'Save'
    },
    documentsName: {
        id: 'claim.directives.claim-documents.Name',
        defaultMessage: 'Name'
    },
    documentsDateModified: {
        id: 'claim.directives.claim-documents.Date Modified',
        defaultMessage: 'Date Modified'
    },
    documentsViewandDownload: {
        id: 'claim.directives.claim-documents.View/Download',
        defaultMessage: 'View/Download'
    },
    documentsRemove: {
        id: 'claim.directives.claim-documents.Remove',
        defaultMessage: 'Remove'
    },
    uploadDocuments: {
        id: 'platform.documentupload.templates.document-upload.Upload Documents',
        defaultMessage: 'Upload Documents'
    },
    modalAddress: {
        id: 'platform.contacts.templates.contact-template.Address',
        defaultMessage: 'Address'
    },
    modalPrimary: {
        id: 'platform.contacts.templates.contact-template.primary',
        defaultMessage: 'Primary'
    },
    modalPrimaryContact: {
        id: 'platform.contacts.templates.contact-template.Primary Contact',
        defaultMessage: 'Primary Contact'
    },
    modalPhoneNumber: {
        id: 'platform.contacts.templates.contact-template.Phone Number',
        defaultMessage: 'Phone Number'
    },
    modalFaxNumber: {
        id: 'platform.contacts.templates.contact-template.Fax Number',
        defaultMessage: 'Fax Number'
    },
    modalClose: {
        id: 'platform.contacts.templates.contact-template.Close',
        defaultMessage: 'Close'
    },
    modalUnknown: {
        id: 'platform.contacts.Unknown',
        defaultMessage: 'Unknown'
    },
    claimServicesMessage: {
        id: 'claim.views.claim-detail.This tab contains claim-related services such as repairs, replacements or medical treatments.',
        defaultMessage: 'This tab contains claim-related services such as repairs, replacements or medical treatments.',
    },
    statusInProgress: {
        id: 'claim.views.claim-detail.In Progress',
        defaultMessage: 'In Progress',
    },
    statusCompleted: {
        id: 'claim.views.claim-detail.Completed',
        defaultMessage: 'Completed',
    },
    statusOffTrack: {
        id: 'claim.views.claim-detail.Off Track',
        defaultMessage: 'Off Track',
    },
    statusUnknown: {
        id: 'claim.controllers.ClaimDetailsCtrl.Unknown',
        defaultMessage: 'Unknown',
    },
    serviceNumber: {
        id: 'claim.views.claim-detail.Service Number',
        defaultMessage: 'Service Number',
    },
    nextAction: {
        id: 'claim.views.claim-detail.Next Action',
        defaultMessage: 'Next Action',
    },
    service: {
        id: 'claim.views.claim-detail.Service',
        defaultMessage: 'Service',
    },
    vendor: {
        id: 'claim.views.claim-detail.Vendor',
        defaultMessage: 'Vendor',
    },
    expectedCompletionDate: {
        id: 'claim.views.claim-detail.Expected Completion Date',
        defaultMessage: 'Expected Completion Date',
    },
    primaryContact: {
        id: 'claim.views.claim-detail.Primary Contact',
        defaultMessage: 'Primary Contact',
    },
    noClaimServices: {
        id: 'claim.views.claim-detail.There are no service requests associated with this claim.',
        defaultMessage: 'There are no service requests associated with this claim.',
    },
    paymentServicesMessage: {
        id: 'claim.directives.claim-payment-details.This tab lists payment transactions made based on the claim.',
        defaultMessage: 'This tab lists payment transactions made based on the claim.',
    },
    paymentStatus: {
        id: 'claim.directives.claim-payment-details.Status',
        defaultMessage: 'Status',
    },
    scheduledDate: {
        id: 'claim.directives.claim-payment-details.Scheduled Date',
        defaultMessage: 'Scheduled Date',
    },
    issueDate: {
        id: 'claim.directives.claim-payment-details.Issue Date',
        defaultMessage: 'Issue Date',
    },
    grossAmount: {
        id: 'claim.directives.claim-payment-details.Gross Amount',
        defaultMessage: 'Gross Amount',
    },
    checkNumber: {
        id: 'claim.directives.claim-payment-details.Check Number',
        defaultMessage: 'Check Number',
    },
    payee: {
        id: 'claim.directives.claim-payment-details.Payee',
        defaultMessage: 'Payee',
    },
    noClaimPayments: {
        id: 'claim.directives.claim-payment-details.There are no payments associated with this claim.',
        defaultMessage: 'There are no payments associated with this claim.',
    },
    confirmationToRemoveTitle: {
        id: 'claim.directives.ClaimDocuments.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmationToRemoveMessage: {
        id: 'claim.directives.ClaimDocuments.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove {documentname}?'
    },
    removeConfirmationYes: {
        id: 'claim.directives.ClaimDocuments.Yes',
        defaultMessage: 'Yes'
    },
    removeConfirmationNo: {
        id: 'claim.directives.ClaimDocuments.No',
        defaultMessage: 'No'
    },
    deletionFailedTitle: {
        id: 'claim.directives.ClaimDocuments.Document deletion may have failed',
        defaultMessage: 'Document deletion may have failed'
    },
    deletionFailedMessage: {
        id: 'claim.directives.ClaimDocuments.Something may have gone wrong trying to delete your document.',
        defaultMessage: 'Something may have gone wrong trying to delete your document.'
    },
    removeServiceFailedTitle: {
        id: 'claim.directives.ClaimDocuments.Document deletion failed',
        defaultMessage: 'Document deletion failed'
    },
    removeServiceFailedMessage: {
        id: 'claim.directives.ClaimDocuments.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    },
    noDocumentsMessage: {
        id: 'claim.directives.claim-documents.There are no documents associated with this claim.',
        defaultMessage: 'There are no documents associated with this claim.'
    },
    claimsTitle: {
        id: 'claim.views.claims.Claims',
        defaultMessage: 'Claims'
    },
    line: {
        id: 'claim.views.claims-list.Line',
        defaultMessage: 'Line'
    },
    claimNumber: {
        id: 'claim.views.claims-list.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimVendors: {
        id: 'claim.views.claims-list.Vendors',
        defaultMessage: 'Vendor(s)'
    },
    claimStatus: {
        id: 'claim.views.claims-list.Status',
        defaultMessage: 'Status'
    },
    closedClaims: {
        id: 'claim.views.claims-list.Closed Claims',
        defaultMessage: 'Closed Claims'
    },
    includeClosedClaims: {
        id: 'claim.views.claims-list.Include Closed Claims',
        defaultMessage: 'Include Closed Claims'
    },
    allClaims: {
        id: 'claim.views.claims-list.All Claims',
        defaultMessage: 'All Claims'
    },
    filters: {
        id: 'claim.views.claims-list.Filters',
        defaultMessage: 'Filters'
    },
    filterBy: {
        id: 'claim.views.claims-list.Filter By',
        defaultMessage: 'Filter By'
    },
    noClaimsResult: {
        id: 'claim.views.claims-list.No Claims',
        defaultMessage: 'There are no claims currently active'
    },
    fileclaim: {
        id: 'claim.views.claims-list.File a Claim',
        defaultMessage: 'File a Claim'
    },
    unableToDeleteClaims: {
        id: 'claim.directives.ClaimDocuments.Unable to delete that document',
        defaultMessage: 'Unable to delete {documentName}'
    },
    loadingPolicyDetails: {
        id: 'claim.views.claim-policy-details.Loading policy details..',
        defaultMessage: 'Loading policy details..'
    },
    errorLoadingPolicyDetails: {
        id: 'claim.views.claim-policy-details.Error loading policy details...',
        defaultMessage: 'Error loading policy details...'
    },
    claimPolicyDetails: {
        id: 'claim.views.claim-policy-details.Policy Details',
        defaultMessage: 'Policy Details : {policyNumber}'
    },
    claimVehicleCoverage: {
        id: 'claim.views.claim-policy-details.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages'
    },

    policyLevelCoverages: {
        id: 'claim.directives.policy-level-coverages.Policy-Level Coverages',
        defaultMessage: 'Policy-Level Coverages'
    },
    limitType: {
        id: 'claim.directives.policy-level-coverages.Limit Type',
        defaultMessage: 'Limit Type'
    },
    limitValue: {
        id: 'claim.directives.policy-level-coverages.Limit Value',
        defaultMessage: 'Limit Value'
    },
    coverage: {
        id: 'claim.directives.policy-level-coverages.Coverage',
        defaultMessage: 'Coverage'
    },
    coveragePerAccident: {
        id: 'claim.directives.policy-level-coverages.Per Accident',
        defaultMessage: 'Per Accident'
    },
    coveragePerPerson: {
        id: 'claim.directives.policy-level-coverages.Per Person',
        defaultMessage: 'Per Person'
    },
    limit: {
        id: 'claim.directives.policy-level-coverages.Limit',
        defaultMessage: 'Limit'
    },
    insured: {
        id: 'claim.views.claim-detail.Insured',
        defaultMessage: 'Insured'
    },
    createNoteErrorTitle: {
        id: 'claim.controllers.CreateNoteCtrl.Claim update failed',
        defaultMessage: 'Claim update failed'
    },
    createNoteErrorMessage: {
        id: 'claim.controllers.CreateNoteCtrl.Sorry, we were not able to add a new note to your claim.',
        defaultMessage: 'Sorry, we were not able to add a new note to your claim.'
    },
    uploadConfirmationTitle: {
        id: 'claim.directives.ClaimDocuments.Are you sure you want to upload?',
        defaultMessage: 'Are you sure you want to upload?'
    },
    uploadConfirmationMessage: {
        id: 'claim.directives.ClaimDocuments.Please note that once uploaded, the document cannot be deleted. Would you like to continue?',
        defaultMessage: 'Please note that once uploaded, the document cannot be deleted. Would you like to continue?'
    },
    uploadConfirmationYes: {
        id: 'claim.directives.ClaimDocuments.upload.Yes',
        defaultMessage: 'Yes'
    },
    uploadConfirmationCancel: {
        id: 'claim.directives.ClaimDocuments.upload.Cancel',
        defaultMessage: 'Cancel'
    },
    claimRepresentative:{
        id: "claim.views.claim-detail.Claim Representative",
        defaultMessage: "Adjuster"
    },
    documentNameHeader: {
        id: "claim.directives.claim-documents.File Name",
        defaultMessage: "File Name"
    },
    documentsDateUploaded: {
        id: "claim.directives.claim-documents.Date Uploaded",
        defaultMessage: "Date Uploaded"
    },
    phnoeNumberWNI: {
        id: 'claim.views.claim-detail.claim.views.claim-detail.phnoeNumber WNI',
        defaultMessage: '(855) 921-3164'
    },
    phoneNumberUIC: {
        id: 'claim.views.claim-detail.claim.views.claim-detail.phoneNumber UIC',
        defaultMessage: '(800) 251-3562'
    },
    emailWNI: {
        id: 'claim.views.claim-detail.claim.views.claim-detail.email WNI',
        defaultMessage: 'pc.claims@wnins.com'
    },
    emailUIC: {
        id: 'claim.views.claim-detail.claim.views.claim-detail.email UIC',
        defaultMessage: 'newloss@umialik.com'
    }
});
