import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMBaileeCustomersFloaterService } from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext'
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMBaileeCustomersFloaterPage.metadata.json5';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import messages from './IMBaileeCustomersFloaterPage.messages';

const SCHEDULED_PROPERTY_FLOATER_ACCORDION_CARD_COMPONENT_MAPS = ['baileeCustomersFloaterMainCovCard', 'baileeCustomersFloaterScheduled'];
function IMBaileeCustomersFloaterPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        markFollowingWizardStepsUnvisited,
        readOnly
    } = props;
    
    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('IMBaileeCustomersFloaterPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [coveragePartClauses, setCoveragePartClauses] = useState()
    const [baileeLoadingClauses, setBaileeLoadingClauses] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );
    
    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await IMBaileeCustomersFloaterService.getBaileeCustomersFloaterClausesWithSync(jobID, sessionUUID, authHeader) 
            : await IMBaileeCustomersFloaterService.getBaileeCustomersFloaterClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setCoveragePartClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    const calculateLimit = useCallback(async (termCode) => {
        setLoadingMask(true)
        const res = await IMBaileeCustomersFloaterService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setCoveragePartClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: IMBaileeCustomersFloaterService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setCoveragePartClauses(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 
    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await IMBaileeCustomersFloaterService.onBaileeCustomersFloaterNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const accordionCommonProps = {
        coveragePartClauses,
        setCoveragePartClauses: (newCoveragePart) => {
            setCoveragePartClauses(newCoveragePart)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
            markFollowingWizardStepsUnvisited()
        },
        isEditing,
        setIsEditing,
        isEditable: !readOnly,
        showErrors,
        onValidate: onValidate,
        loadingClauses: baileeLoadingClauses,
        setLoadingClauses: setBaileeLoadingClauses,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateCoverageFormClausesService: IMBaileeCustomersFloaterService.updateBaileeCustomersFloaterClauses,
        calculateLimit
    }    

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        mainCoverageCard: {
            errorState: !_.isEmpty(_.intersection(invalidFields, SCHEDULED_PROPERTY_FLOATER_ACCORDION_CARD_COMPONENT_MAPS))
        },
        baileeCustomersFloaterMainCovCard: {
            ...accordionCommonProps,
            mainCoverage: _.get(coveragePartClauses, 'mainCoverages[0]'),
            coverageFormPath: 'mainCoverages[0]'
        },
        baileeCustomersFloaterScheduled: {
            ...accordionCommonProps,
            coveragePartSchedule: _.get(coveragePartClauses, 'coveragePartSchedule'),
            updateScheduleService: IMBaileeCustomersFloaterService.updateCoveragePartSchedule,
            schedulePath: 'coveragePartSchedule',
            scheduleLable: translator(messages.ListOfScheduleItems),
            scheduleFormHeader: translator(messages.ScheduleItem),
            visibleOfDivider: true
        },
        baileePartAdditionalAccordionCard: {
            ...accordionCommonProps,
            coveragePartName: 'BaileeCustomersFloater'
        },
    };

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            BaileeCustomersFloaterMainCovCard: IMCommonMainCoverage,
            BaileeCustomersFloaterScheduled: IMCommonCovPartSchedule,
            BaileePartAdditionalAccordionCard: IMCommonAdditionalCovCard,
        },
    };

    const disableNavigator = isEditing || isLoadingMask

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {coveragePartClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

IMBaileeCustomersFloaterPage.propTypes = WizardPage.propTypes;
IMBaileeCustomersFloaterPage.defaultProps = WizardPage.defaultProps;
export default IMBaileeCustomersFloaterPage;