import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';


import { formatDate, formatNumber, FormattedDate, FormattedNumber } from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/legacy/datatable';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';



import CUJurisdictionSummaryComponent from './CUJurisdictionSummaryComponent';


import { Button } from '@jutro/legacy/components';


/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CUJurisdictionsComponent(props) {

    const {
        jurisdictions, // CUJurisdictionDTO[];
    } = props;

    // const [currentRow, updateCurrentRow] = useState(undefined);

    if (_.isEmpty(jurisdictions)) {
        return null;
    }

    const renderContent = () => {
        const retval = jurisdictions.map((jurisdiction) => {
            return <CUJurisdictionSummaryComponent jurisdiction={jurisdiction} />;
        });
        return retval;
    };

    return (
        <div>
            {renderContent()}
        </div>
    );
    
}

CUJurisdictionsComponent.propTypes = {
    jurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CUJurisdictionsComponent.defaultProps = {
    
};


export default CUJurisdictionsComponent;