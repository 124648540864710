

import React from 'react';
import { IMTransitReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMTransitChangeReadOnlyPage = (props) => {
    return <IMTransitReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMTransitChangeReadOnlyPage
