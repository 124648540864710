import { defineMessages } from 'react-intl';

export default defineMessages({
    caViewAndEditLabel: {
        id: 'wni.quoteandbind.ca-wizard.ca-locations.View/Edit',
        defaultMessage: 'View/Edit',
    },
    caViewLabel: {
        id: 'wni.quoteandbind.ca-wizard.ca-locations.View',
        defaultMessage: 'View',
    },
    removeLocationTitle: {
        id: 'wni.quoteandbind.ca-wizard.ca-locations.Remove Location?',
        defaultMessage: "Remove Location?"
    },
    removeLocationDescription: {
        id: 'wni.quoteandbind.ca-wizard.ca-locations.removeLocationDescription',
        defaultMessage: 'Are you sure you want to remove this location from the list of locations?',
    },
    Ok: {
        id: 'wni.quoteandbind.ca-wizard.ca-locations.OK',
        defaultMessage: 'OK'
    },
    Cancel: {
        id: 'wni.quoteandbind.ca-wizard.ca-locations.Cancel',
        defaultMessage: 'Cancel'
    },
});