import React, {
} from 'react'
import { WniTypeaheadMultiSelect } from 'wni-common-base-components';

export const DetailsMultiSelect = (props) => {

    const {
        sublinePublicID,
        displayable,
        detailsIndex,
        onValidate,
        disabled,
        showErrors,
        isEditable,
        changeDetailsAndSync,
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        multiSelectValue,
        rangeValueOptions = [],
    } = displayable

    const onMultiSelectValueChange = (newValue) => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            multiSelectValue: newValue
        }
        changeDetailsAndSync(newDisplayable, detailsIndex)
    }

    return <WniTypeaheadMultiSelect
        id={`${sublinePublicID}_displayable_multiSelect_${propertyName}`}
        labelPosition='left'
        label={label}
        value={multiSelectValue}
        availableValues={rangeValueOptions.map((v) => {
            return {code: v,name: v}
        })}
        onValueChange={onMultiSelectValueChange}
        placeholder=""
        disabled={disabled}
        size="medium"
        className='flex-1'
        required={required}
        showRequired
        onValidationChange={onValidate}
        showErrors={showErrors}
        readOnly={!isDisplayableEditable || !isEditable}
    />
}