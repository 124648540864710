import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { PolicyChangeDetailsComponent } from 'gw-capability-policyjob-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PRCPQuotePage.metadata.json5';
import styles from './PRCPQuotePage.module.scss';
import './PRCPQuotePage.messages';

function PRCPQuotePage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const breakpoint = useContext(BreakpointTrackerContext);

    const setPolicyData = useMemo(() => {
        const quoteData = submissionVM.quoteData.offeredQuotes.children[0].premium;
        return {
            effectiveDate: submissionVM.baseData.effectiveDate,
            policyPeriod: {
                startDate: _.get(submissionVM.value, 'baseData.periodStartDate'),
                endDate: _.get(submissionVM.value, 'baseData.periodEndDate')
            },
            previousPremium: quoteData.previousPolicyTotalCost.value,
            changeInCost: {
                amount:
                    quoteData.total.amount.value - quoteData.previousPolicyTotalCost.amount.value,
                currency: quoteData.total.currency.value.code
            },
            newPremium: quoteData.total.value,
            taxes: quoteData.taxes.value,
            newPremiumBeforeTaxes: quoteData.totalBeforeTaxes.value
        };
    }, [submissionVM]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            policychangedetailscomponent: PolicyChangeDetailsComponent
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            required: true
        },
        cpPolicyRenewalDetails: {
            policyDetails: setPolicyData,
            visible: !_.isEmpty(submissionVM),
            showPolicyPeriodDateRange: true
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}
PRCPQuotePage.propTypes = wizardProps;
export default PRCPQuotePage;
