import { defineMessages } from 'react-intl';

export default defineMessages({
    // POI-15098: Change coverage title name
    paLineCoverageTitle: {
        id: 'quoteandbind.pa.views.line coverage.title.POLICY-LEVEL COVERAGES',
        defaultMessage: 'POLICY-LEVEL COVERAGES'
    },
    paTermType: {
        id: 'customer.pa.wizard.step.policydetails.termtype.paTermType',
        defaultMessage: 'Term Type'
    },
    paTermTypePleaseSelect: {
        id: 'customer.pa.wizard.step.policydetails.termtype.placeholder.Please Select',
        defaultMessage: 'Please Select'
    },
    paTermsixMonths: {
        id: 'customer.pa.wizard.step.policydetails.termtype.6Months',
        defaultMessage: '6 Months'
    },
    paTermAnnual: {
        id: 'customer.pa.wizard.step.policydetails.termtype.Annual',
        defaultMessage: 'Annual'
    },
    periodEffectiveDate: {
        id: 'customer.pa.wizard.step.policydetails.Effective Date',
        defaultMessage: 'Effective Date'
    },
    periodPolicyState: {
        id: 'customer.pa.wizard.step.policydetails.Policy State',
        defaultMessage: 'Policy State'
    },
    periodEndDate: {
        id: 'customer.pa.wizard.step.policydetails.Coverage Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    primaryNamedInsured: {
        id: 'quoteandbind.pa.views.pa-policy-info.Primary Named Insured',
        defaultMessage: 'Primary Named Insured'
    },
    message: {
        id: 'quoteandbind.Future Effective Date - Quoted premium may be inaccurate.',
        defaultMessage: 'Future Effective Date - Quoted premium may be inaccurate.'
    },
    agencyOfRecord: {
        id: 'quoteandbind.wizard.step.Agency Of Record',
        defaultMessage: 'Agency Of Record'
    },
    agency: {
        id: 'quoteandbind.wizard.step.Agency',
        defaultMessage: 'Agency'
    },
    licensedProducer: {
        id: 'quoteandbind.wizard.step.Licensed Producer',
        defaultMessage: 'Licensed Producer'
    },
    licensedAgentDefault: {
        id: 'quoteandbind.wizard.step.Choose Licensed Agent',
        defaultMessage: 'Please Select'
    },
    servicingProducer: {
        id: 'quoteandbind.wizard.step.Servicing Producer',
        defaultMessage: 'Servicing Producer'
    },
    servicingAgentDefault: {
        id: 'quoteandbind.wizard.step.Choose Servicing Agent',
        defaultMessage: 'Please Select'
    },
    isQuoteForNamedNonOwner: {
        id: 'customer.pa.wizard.step.policydetails.isQuoteForNamedNonOwner',
        defaultMessage: 'Is this policy for a Named Non Owner (No Vehicle)?'
    },
    covertedPolicyInfoStart: {
        id: 'customer.pa.wizard.step.policydetails.CovertedPolicyInfoStart',
        defaultMessage: 'This quote was created based on information from the insured’s current Personal Auto policy as of '
    },
    covertedPolicyInfoEnd: {
        id: 'customer.pa.wizard.step.policydetails.CovertedPolicyInfoEnd',
        defaultMessage: '. Please review it carefully to ensure that any recent changes have been applied.'
    },
    copyMessage: {
        id: 'customer.pa.wizard.step.policydetails.The application has been copied to ',
        defaultMessage: 'The application has been copied to '
    },
    paLineUWTitle: {
        id: 'quoteandbind.pa.views.line coverage.title.Underwriting Questions',
        defaultMessage: 'Underwriting Questions'
    },
});
