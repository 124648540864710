import { defineMessages } from 'react-intl';

export default defineMessages({
    newAccountSearchHeader: {
        id: 'gateway.views.account search.new-quote-account-search.Search for Existing Account',
        defaultMessage: 'Search for Existing Account'
    },
    accountNumber: {
        id: 'gateway.views.account search.new-quote-account-search.Account Number',
        defaultMessage: 'Account Number'
    },
    orYouCanEnterInfo: {
        id: 'gateway.views.account search.new-quote-account-search.Or you can enter...',
        defaultMessage: 'Or you can enter...'
    },
    dba: {
        id: 'gateway.views.account search.new-quote.DBA',
        defaultMessage: 'DBA'
    },
    fein: {
        id: 'gateway.views.account search.new-quote.FEIN',
        defaultMessage: 'FEIN'
    },
    cancelQuote: {
        id: 'gateway.controllers.account search.Cancel Quote',
        defaultMessage: 'Cancel Quote?'
    },
    cancelMessage: {
        id: 'gateway.controllers.account search.Are you sure you want to cancel the new quote?',
        defaultMessage: 'Are you sure you want to cancel the new quote?'
    }
});
