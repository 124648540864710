import { defineMessages } from 'react-intl';

export default defineMessages({
    AddNote: {
        id: 'custom.quoteandbind.uwnote.Add a note for UW',
        defaultMessage: 'Add a note for UW'
    },
    AddReferralNote: {
        id: 'custom.quoteandbind.uwnote.Add a referral note for UW',
        defaultMessage: 'Add a referral note for UW'
    },
    Content: {
        id: 'custom.quoteandbind.uwnote.Content',
        defaultMessage: 'Content'
    },
    Save: {
        id: 'custom.quoteandbind.uwnote.Save',
        defaultMessage: 'Save'
    },
    Cancel: {
        id: 'custom.quoteandbind.uwnote.Cancel',
        defaultMessage: 'Cancel'
    },
    deleteLabel: {
        id: 'custom.quoteandbind.uwnote.Delete',
        defaultMessage: 'Delete',
    }
});
