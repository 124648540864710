import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import CACoverageUtil from '../../../Coverages/util/CACoverageUtil';
import CoverageContext from '../../../Coverages/context/CACoverageContext';
import CAClausesComponentVM from '../../../Coverages/components/CAClausesComponentVM/CAClausesComponentVM';
import CASingleClauseComponentVM from '../../../Coverages/components/CASingleClauseComponentVM/CASingleClauseComponentVM';
import metadata from './CAHiredAutoStandardCoverages.metadata.json5';
import styles from './CAHiredAutoStandardCoverages.module.scss';

function CAHiredAutoStandardCoverages(props) {
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService,
        loadingClauses,
        setLoadingClauses,
        stateCoveragesIndex,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const getHiredAutoStandardCoverages = useCallback(() => {
        const hiredAutoStandardCoverages = _.get(selectedVersion, `coverages.stateSpecificCoverages[${stateCoveragesIndex}].hiredAutoStandardCoverages`, []);
        return hiredAutoStandardCoverages;
    }, [selectedVersion, stateCoveragesIndex]);

    const hiredAutoStandardCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.stateSpecificCoverages.children[${stateCoveragesIndex}].hiredAutoStandardCoverages`;
    const hiredAutoStandardCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.stateSpecificCoverages[${stateCoveragesIndex}].hiredAutoStandardCoverages`;

    const hiredAutoStandardCoverages = getHiredAutoStandardCoverages();

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = CACoverageUtil.setClauseValue(hiredAutoStandardCoverages, hiredAutoStandardCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, hiredAutoStandardCoveragesPath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            hiredAutoStandardCoverages,
            hiredAutoStandardCoveragesVMPath,
            hiredAutoStandardCoveragesPath,
            updateWizardData,
            setIsEditing,
        ]
    );
    const updateVMToServer = useCallback(async (vmUpdatedInPE, setLoadingClausesFunc, setEditingFunc) => {
        const coverages = _.get(vmUpdatedInPE.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = CACoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = CACoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingClausesFunc(updatedCoveragePublicIDs)
        const response = await coveragesService.updateLineCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setLoadingClausesFunc(false)
        setEditingFunc(false)
        const subVMUpdatedByRes = _.clone(vmUpdatedInPE)
        _.set(subVMUpdatedByRes.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        updateWizardData(subVMUpdatedByRes);
    }, [
        lobName,
        selectedVersionIndex,
        coveragesService,
        jobID,
        sessionUUID,
        authHeader,
        updateWizardData
    ]);

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        updateVMToServer(newSubmissionVM, setLoadingClauses, setIsEditing)
    }, [
        updateVMToServer,
        setLoadingClauses,
        setIsEditing
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            hiredAutoStandardCoverages: {
                loadingClause: loadingClauses,
                path: hiredAutoStandardCoveragesVMPath,
                value: hiredAutoStandardCoverages,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    CASingleClauseComponentVM: CASingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
            }
        };
    }, [
        loadingClauses,
        hiredAutoStandardCoveragesVMPath,
        hiredAutoStandardCoverages,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
        },
        resolveComponentMap: {
            CAClausesComponentVM: CAClausesComponentVM,
        }
    };
    return (
        <CoverageContext.Provider
            value={{
                wizardData: submissionVM,
                updateWizardData: updateWizardData,
                updateVMToServer,
            }}
        >
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={{}}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </CoverageContext.Provider>
        
    );
}

CAHiredAutoStandardCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
CAHiredAutoStandardCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default CAHiredAutoStandardCoverages;
