import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniProductsUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useProductsData } from 'wni-portals-util-react';
import { useWniModal } from 'wni-components-platform-react'
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import defaultMessage from 'gw-capability-gateway-react/Components/UnderwritingComponent/UnderwritingComponent.messages';
import UWUtil from './UWUtil';
import wniMessages from './UnderwritingComponent.messages';
import clMessages from './UnderwritingComponent.cl.messages';
import metadata from './UnderwritingComponent.metadata.json5';
import styles from './UnderwritingComponent.module.scss';

const UnderwritingComponent = (props) => {
    const modalApi = useWniModal();
    const {
        job,
        onWithdrawJob,
        onEditJob,
        jobService,
        authUserData,
        authHeader,
        onUpdateJobSummary,
        onViewTransaction,
        onCopySubmission,
        checkReferredToUnderWriter,
        setShowReferToUW,
        warningMessagesAndSuccessNotificationVisibility: {
            warningExist,
            warningMessagesVisibility: {
                showRewriteInProgressWarning,
                showRewriteComfirmedWarning,
                showRewriteDeterminedWarning,
                showUWIssueRaisedWarning,
                showReferReviewWarning,
                showRejectUWIssueWarning,
                showLockedWarning,
                showDeclinedWarning,
                showMessageBox,
                warningButtonVisibility: {
                    showViewQuote,
                    showReferToUnderWriterButton,
                    showWithDrawButton,
                    showEditQuoteButton,
                    showCopySubmissionButton,
                }
            }
        },
        transactionVisibleActions,
        onCopyToHOSubmission,
        onCopyToDPSubmission,
        baseState
    } = props;

    const {
        productCode,
    } = job
    const { getProductEnabled, getProductVisibleForState } = useProductsData();
    const translator = useTranslator();
    const [formData, updateFormData] = useState({});
    const messages = { ...defaultMessage, ...wniMessages, ...clMessages};
    const uwutil = useMemo(() => UWUtil(job, authUserData.userType), []);

    const getCell = useCallback((items, index, property) => {
        return items[property.id];
    }, []);

    const getWithDrawInfo = useCallback(
        (isWithDraw) => {
            const { link } = uwutil.translateWithdrawn;
            return isWithDraw ? translator(link) : translator(messages[`reasonContinue${uwutil.jobType}`]);
        },
        [translator, uwutil.translateWithdrawn,uwutil.jobType]
    );

    const getWithDrawButtonContent = useCallback(() => {
        if (WniProductsUtil.isCLProduct(productCode)) {
            return translator(clMessages[`clWithdraw${uwutil.jobType}`])
        }
        return translator(messages[`withdraw${uwutil.jobType}`]);
    },[messages, productCode, translator, uwutil.jobType]);

    const getRaisedEditButtonContent = useCallback(() => {
        if (WniProductsUtil.isCLProduct(productCode) && uwutil.jobType === "PolicyChange") {
            return translator(clMessages.clEditPolicyChangeBtn)
        }
        return translator(messages[`warningEdit${uwutil.jobType}`])
    }, [messages, productCode, translator, uwutil.jobType]);

    const getRejectedIssuesHeader = useCallback(() => {
        if (WniProductsUtil.isCLProduct(productCode)) {
            return translator(clMessages[`clRejectedByUW${uwutil.jobType}`])
        }
        return !uwutil.offerings
            ? translator(messages.rejectedByUW)
            : translator(wniMessages.wniOfferUnresolvedIssue)
    }, [messages.rejectedByUW, productCode, translator, uwutil.jobType, uwutil.offerings]);

    const getProceedOfferingContent = useCallback(() => {
        if (WniProductsUtil.isCLProduct(productCode)) {
            if (showRejectUWIssueWarning) {
                return translator(clMessages[`clDeniedInfo${uwutil.jobType}`]);
            }
            if (showDeclinedWarning) {
                return (
                    <>
                        <span>{translator(clMessages[`clDeclined${uwutil.jobType}InfoMessage1`])}</span>
                        <br/>
                        <span>{translator(clMessages[`clDeclined${uwutil.jobType}InfoMessage2`])}</span>
                    </>
                )
            }
            if(showRewriteInProgressWarning){
                return translator(clMessages[`clInProgress${uwutil.jobType}InfoMessage`]);
            }
            if(showRewriteComfirmedWarning){
                return translator(clMessages[`clComfirmed${uwutil.jobType}InfoMessage`]);
            }
            if(showRewriteDeterminedWarning){
                return translator(clMessages[`clDetermined${uwutil.jobType}InfoMessage`]);
            }
            return translator(clMessages[`clLocked${uwutil.jobType}InfoMessage`]);
        }
        return translator(wniMessages.jobLockedSubHeadingMessage);
    }, [productCode, showRejectUWIssueWarning, translator, uwutil.jobType]);

    const getEditOrReferUW = useCallback(() => {
        if (WniProductsUtil.isCLProduct(productCode) && showRejectUWIssueWarning) {
            return translator(clMessages.clDeniedReviewInfo);
        }
        return translator(messages[`wniEditOrRefer${uwutil.jobType}`]);
    }, [messages, productCode, showRejectUWIssueWarning, translator, uwutil.jobType]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const referQuoteToUnderWriter = useCallback(async () => {
        const { uwNoteForUnderwriter } = formData;
        const noteForUW = uwNoteForUnderwriter === '' ? null : uwNoteForUnderwriter;
        try {
            const jobResponse = await jobService
                .referToUnderwriter(job.jobNumber, noteForUW, authHeader);
            setShowReferToUW(false);
            onUpdateJobSummary(jobResponse);
        } catch (error) {
            modalApi.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorSendingJobForReview,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
        await checkReferredToUnderWriter();
    }, [
        authHeader,
        formData,
        job.jobNumber,
        jobService,
        onUpdateJobSummary,
        checkReferredToUnderWriter,
        messages.errorSendingJobForReview,
        setShowReferToUW
    ]);

    const onCancelReferUW = useCallback(() => {
        setShowReferToUW(false);
    }, [setShowReferToUW]);

    const onReferToUW = useCallback(() => {
        setShowReferToUW(true);
    }, [setShowReferToUW]);
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: getCell,
            handleEditJob: onEditJob,
            handleUnderwrite: referQuoteToUnderWriter,
            handleWithDraw: onWithdrawJob,
            onCancelReferUW: onCancelReferUW,
            onReferToUW: onReferToUW,
            writeValue: writeValue,
            onViewTransaction: onViewTransaction,
            onCopySubmission: onCopySubmission,
            onCopyToHOSubmission: onCopyToHOSubmission,
            onCopyToDPSubmission: onCopyToDPSubmission,
        },
        resolveComponentMap: {
        }
    };
    const overrideProps = {
        warningMessagesContainer: {
            visible: warningExist
        },
        uwIssuesWarningNotification: {
            job: job
        },
        proceedOffering: {
            content: getProceedOfferingContent()
            // content: WniProductsUtil.isCLProduct(productCode) ?
            //     translator(clMessages.clLockedInfoMessage) : 
            //     translator(wniMessages.jobLockedSubHeadingMessage),
        },
        uwIssueRaisedContentInfo: {
            visible: showUWIssueRaisedWarning
        },
        referReviewWarningContentInfo: {
            visible: showReferReviewWarning
        },
        viewQuote: {
            visible: showViewQuote,
            content: translator(messages[`view${uwutil.jobType}`])
        },
        referToUnderwriterButton: {
            visible: showReferToUnderWriterButton
        },
        rejectReferToUnderwriterButton: {
            visible: showReferToUnderWriterButton
        },
        rejectWarningContentInfo: {
            visible: showRejectUWIssueWarning
        },
        lockedWarningContentInfo: {
            visible: showLockedWarning
        },
        RewriteInProgressWarningContentInfo: {
            visible: showRewriteInProgressWarning
        },
        RewriteComfirmedWarningContentInfo: {
            visible: showRewriteComfirmedWarning
        },
        RewriteDeterminedWarningContentInfo: {
            visible: showRewriteDeterminedWarning
        },
        declinedWarningContentInfo: {
            visible: showDeclinedWarning
        },
        referToUWPanel: {
            visible: showMessageBox
        },
        showIssuesHeader: {
            title: WniProductsUtil.isCLProduct(productCode) ?
            translator(clMessages[`clUnderwriter${uwutil.jobType}`]) :
            translator(messages[`underwriter${uwutil.jobType}`])
        },
        uwIssueRaisedSubHeading: {
            content: WniProductsUtil.isCLProduct(productCode) ?
            translator(clMessages[`clToCompletePointersFor${uwutil.jobType}`]):
            translator(messages[`toCompletePointersFor${uwutil.jobType}`])
        },
        editJobForAccept: {
            content: WniProductsUtil.isCLProduct(productCode) ?
                translator(clMessages[`clEdit${uwutil.jobType}`]) :
                translator(messages[`edit${uwutil.jobType}`])
        },
        reviewForUnderWritter: {
            content: WniProductsUtil.isCLProduct(productCode) ?
                translator(clMessages.clReviewJob) :
                translator(messages[`review${uwutil.jobType}`])
        },
        referReviewForUW: {
            title: translator(messages[`underwriterReviewing${uwutil.jobType}`])
        },
        referredToUnderwriterMessage: {
            title: translator(uwutil.getReferredToUnderwriterMessage)
        },
        rejectedIssuesHeader: {
            title: getRejectedIssuesHeader()
            // title: !uwutil.offerings
            //     ? translator(messages.rejectedByUW)
            //     : translator(wniMessages.wniOfferUnresolvedIssue)
        },
        editOrReferUW: {
            content: getEditOrReferUW()
            // content: translator(messages[`wniEditOrRefer${uwutil.jobType}`])
        },
        withDrawWishList: {
            visible: !WniProductsUtil.isCLProduct(productCode)
        },
        withdrawLink: {
            content: getWithDrawInfo(true)
        },
        wishToContinue: {
            content: getWithDrawInfo()
        },
        referReviewWithDrawJob: {
            visible: showWithDrawButton,
            content: getWithDrawButtonContent()
        },
        // rejectReviewWithDrawJob: {
        //     visible: showWithDrawButton,
        //     content: translator(messages[`withdraw${uwutil.jobType}`])
        // },
        rejectEditJob: {
            visible: showEditQuoteButton,
            content: translator(messages[`warningEdit${uwutil.jobType}`])
        },
        raisedEditJob: {
            visible: showEditQuoteButton,
            content: getRaisedEditButtonContent()
        },
        lockedIssuesHeader: {
            content: WniProductsUtil.isCLProduct(productCode) ? 
                translator(messages[`lockedCL${uwutil.jobType}`]) :
                translator(messages[`locked${uwutil.jobType}`])
        },
        declinedIssuesHeader: {
            content: WniProductsUtil.isCLProduct(productCode) ? 
                translator(messages[`declinedCL${uwutil.jobType}`]) :
                translator(messages[`declined${uwutil.jobType}`])
        },
        referralInfo: {
            content: translator(messages[`referralInfo${uwutil.jobType}`])
        },
        wantToReferInfo: {
            content: translator(messages[`referToUW${uwutil.jobType}`])
        },
        copySubmission: {
            visible: showCopySubmissionButton
        },
        copyToHOSubmission: {
            visible: !!_.get(transactionVisibleActions, 'isCopyToHOSubmission') && getProductEnabled('HOPHomeowners'),
            className: styles.noCapitalize
        },
        copyToDPSubmission: {
            visible: !!_.get(transactionVisibleActions, 'isCopyToDPSubmission') && getProductVisibleForState('DwellingProperty', baseState),
            className: styles.noCapitalize
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={formData}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

UnderwritingComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    job: PropTypes.shape({
        statusCode: PropTypes.string,
        isEditLocked: PropTypes.bool,
        createdByMe: PropTypes.bool,
        jobNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        underwritingIssues: PropTypes.shape({})
    }),
    onWithdrawJob: PropTypes.func.isRequired,
    onUpdateJobSummary: PropTypes.func.isRequired,
    onEditJob: PropTypes.func.isRequired,
    jobService: PropTypes.shape({
        referToUnderwriter: PropTypes.string
    }).isRequired,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }).isRequired,
    onViewTransaction: PropTypes.func,
    onCopySubmission: PropTypes.func,
    transactionVisibleActions: PropTypes.shape({
        isCopySubmission: PropTypes.bool,
        isContinueTransaction: PropTypes.bool.isRequired,
        isWithdrawTransaction: PropTypes.bool.isRequired,
        isSubmitTransaction: PropTypes.bool,
        isEditTransaction: PropTypes.bool,
        isRescindTransaction: PropTypes.bool,
        isViewTransaction: PropTypes.bool,
        isBoundTransaction: PropTypes.bool,
        isReferToUnderWriterTransaction: PropTypes.bool
    }).isRequired,
    checkReferredToUnderWriter: PropTypes.func.isRequired,
    setShowReferToUW: PropTypes.func.isRequired,
    warningMessagesAndSuccessNotificationVisibility: PropTypes.shape({
        warningExist: PropTypes.bool,
        warningMessagesVisibility: {
            showRewriteInProgressWarning: PropTypes.bool,
            showRewriteComfirmedWarning: PropTypes.bool,
            showRewriteDeterminedWarning: PropTypes.bool,
            showUWIssuesApprovedMsg: PropTypes.bool,
            showUWIssueRaisedWarning: PropTypes.bool,
            showReferReviewWarning: PropTypes.bool,
            showRejectUWIssueWarning: PropTypes.bool,
            showLockedWarning: PropTypes.bool,
            showMessageBox: PropTypes.bool,
            warningButtonVisibility: {
                showViewQuote: PropTypes.bool,
                showReferToUnderWriterButton: PropTypes.bool,
                showWithDrawButton: PropTypes.bool,
                showEditQuoteButton: PropTypes.bool,
                showCopySubmissionButton: PropTypes.bool,
            }
        },
        successNotification: {
            showUWIssuesApprovedMsg: PropTypes.bool
        }
    }).isRequired,
};
UnderwritingComponent.defaultProps = {
    job: {},
    onViewTransaction: _.noop,
    onCopySubmission: _.noop,
};

export default withAuthenticationContext(UnderwritingComponent);
