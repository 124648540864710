import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentSource: {
        id: 'quoteandbind.directives.templates.make-payment.Source',
        defaultMessage: 'Source'
    },
    paymentAmount: {
        id: 'quoteandbind.directives.templates.make-payment.Amount',
        defaultMessage: 'Amount'
    },
    ok: {
        id: 'quoteandbind.directives.templates.make-payment.OK',
        defaultMessage: 'OK'
    },
    cancel: {
        id: 'quoteandbind.directives.templates.make-payment.Cancel',
        defaultMessage: 'Cancel'
    },
    submitPayment: {
        id: 'quoteandbind.directives.templates.make-payment.Submit Payment',
        defaultMessage: 'Submit Payment'
    },
    sorryYourPaymentCould: {
        id: 'custom.billing.controllers.InvoicePaymentCtrl.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    paymentRequestFailed: {
        id: 'custom.billing.controllers.InvoicePaymentCtrl.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
});
