import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('crStateSpecificInfoService'), method, data, additionalHeaders);
}

export default class CRStateSpecificInfoService {
  
    /**
     * Invokes CRStateSpecificInfoHandler.getStateClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @param {string} state
     * @returns {Promise}
     */
    static getStateClauses(jobNumber, sessionUUID, state, authHeader = {}) {
        return processSubmission('getStateClauses', [jobNumber, sessionUUID, state], authHeader);
    }

    /**
     * Invokes CRStateSpecificInfoHandler.updateStateClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} crCoveragesDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateStateClauses(jobNumber, sessionUUID, crCoveragesDTO, authHeader = {}) {
        return processSubmission('updateStateClauses', [jobNumber, sessionUUID, crCoveragesDTO], authHeader);
    }

    /**
     * Invokes CRStateSpecificInfoHandler.searchStateSpecificCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} state
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchStateSpecificCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, state, authHeader = {}) {
        return processSubmission('searchStateSpecificCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, state], authHeader);
    }

    /**
     * Invokes CRStateSpecificInfoHandler.addSearchedStateSpecificCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {string} state
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedStateSpecificCoverages(jobID, sessionUUID, clausesToAddData, state, authHeader = {}) {
        return processSubmission('addSearchedStateSpecificCoverages', [jobID, sessionUUID, clausesToAddData, state], authHeader);
    }

        /**
     * Invokes CRStateSpecificInfoHandler.onStateInfoPageNext()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
        static onStateInfoPageNext(jobID, sessionUUID, authHeader = {}) {
            return processSubmission('onStateInfoPageNext', [jobID, sessionUUID], authHeader);
        }
}