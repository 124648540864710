import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'modal.button.pu.label.residents.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'modal.button.pu.label.residents.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'modal.button.pu.label.residents.Save',
        defaultMessage: 'Save & Continue'
    },
    add: {
        id: 'modal.button.pu.label.residents.Add',
        defaultMessage: 'Save & Add HouseHold Resident'
    }

});
