import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import {
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './FNOLContactAdminPage.metadata.json5';
import styles from './FNOLContactAdminPage.module.scss';
import messages from './FNOLContactAdminPage.messages';

function FNOLContactAdminPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history,
        location
    } = props;

    const translator = useTranslator();
    const { lossCause, claimNumber } = location.state;

    const backToClaim = () => {
        history.push({
            pathname: '/policies',
            state: {
                claimNumber: claimNumber,
                redirectPath: '/claims'
            }
        });
    }

    const getContactAdminTitle = () => {
        if (lossCause === 'towingLaborOnly_EXT') {
            return translator(messages.towingLaborOnlyTitle)
        }
        return translator(messages.contactGlassTitle)
    }

    const getContactAdminCall = () => {
        if (lossCause === 'towingLaborOnly_EXT') {
            return translator(messages.towingLaborOnlyCall)
        }
        return translator(messages.contactGlassCall)
    }

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showRequired: true
        },
        contactAdminTitle: {
            content: getContactAdminTitle()
        },
        contactAdminCall: {
            content: getContactAdminCall()
        },
        backClaim: {
            onClick: backToClaim
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={claimVM}
            resolveValue={readValue}
            classNameMap={styles}
            overrideProps={overrides}
            onModelChange={updateWizardData}
        />
    );
}

FNOLContactAdminPage.propTypes = wizardProps;
FNOLContactAdminPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLContactAdminPage));
