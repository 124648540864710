import _ from 'lodash';
import React from 'react';
import { WniDriverService } from 'wni-capability-quoteandbind';
import { WniPolicyChangeDriverService } from 'wni-capability-policychange';
import DeletionModal from '../modal/DeletionModal';
import messages from '../PADriversPage.messages';

function checkTargetValid(target, minValue) {
    if (!_.isNumber(target) || target < 0) {
        return minValue;
    }
    return target;
}

function showModalForDelADPDriver({ isntPNIDrivers, PNIDriver, translator, modalApi }) {
    let description = messages.removeIncludePNIDriverDescription;
    if (_.isEmpty(PNIDriver)) {
        description = messages.removeDriverDescription;
    }
    const componentProps = {
        // title: translator(messages.paDriverRemoveTitle),
        title: translator(description),
        iconClassType: false,
        showCloseBtn: false,
        showCancelBtn: false,
        size: 'lg',
        actionBtnLabel: translator(messages.deletionModalOk),
        cancelBtnLabel: translator(messages.deletionModalCancel),
        drivers: isntPNIDrivers
    };
    if (_.isEmpty(isntPNIDrivers) && PNIDriver.length === 1) {
        description = messages.removePNIDriverDescription;
        return modalApi.showConfirm({
            title: messages.removeDriverTitle,
            message: description,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.deletionModalOk,
            cancelButtonText: messages.deletionModalCancel
        }).then(
            () => {
                return false;
            }, _.noop
        );
    }
    return modalApi.showModal(<DeletionModal {...componentProps} />);
}

async function delADPDrivers({
    autoPrefillDataDrivers, submissionVM, policyChangeService, authHeader, translator, delADPCallback, modalApi
}) {
    const isntPNIDrivers = autoPrefillDataDrivers.filter((d) => {
        return !_.get(d, 'value.isPNI_Ext');
    });
    const PNIDriver = autoPrefillDataDrivers.filter((d) => {
        return _.get(d, 'value.isPNI_Ext');
    });
    return showModalForDelADPDriver({ isntPNIDrivers, PNIDriver, translator, modalApi}).then(async (res) => {
        const driversToRemove = [];
        const {
            quoteID,
            jobID,
            sessionUUID
        } = submissionVM.value;
        _.forEach(res, (driver) => {
            let year = _.get(driver, 'dateOfBirth.value.year');
            let month = _.get(driver, 'dateOfBirth.value.month');
            let day = _.get(driver, 'dateOfBirth.value.day');
            year = checkTargetValid(year, 0);
            month = checkTargetValid(month, 0);
            day = checkTargetValid(day, 1);
            const dateOfBirth = {
                year,
                month,
                day
            };
            const model = {
                quoteId: quoteID || jobID,
                publicID: driver.publicID.value,
                dateOfBirth: dateOfBirth,
                removePrefillReason_Ext: driver.value.person.removePrefillReason_Ext,
                explaination_Ext: driver.value.person.explaination_Ext
            };
            driversToRemove.push(model);
        });
        if (!_.isEmpty(isntPNIDrivers)) {
            delADPCallback(true);
            let result = {};
            if (_.isFunction(policyChangeService.delADPDrivers)) {
                result = await policyChangeService.delADPDrivers(driversToRemove);
            } else {
                result = await WniDriverService
                    .removeADPDrivers({ quoteID, sessionUUID, drivers: driversToRemove }, authHeader);
            }
            _.set(submissionVM, 'value', result);
            delADPCallback(false);
        }
        return true;
    }).catch(() => {
        _.noop();
        return false;
    });
}

export default {
    delADPDrivers
};
