import React from 'react';
import messages from './PersonalPropertyClauses.messages'
import DefaultRiskItemClauses from '../DefaultRiskItemClauses/DefaultRiskItemClauses';

const PersonalPropertyClauses = (props) => {
    return <DefaultRiskItemClauses
        {...props}
        messages={messages}
    />
};

export default PersonalPropertyClauses;
