import React, { useContext } from "react";
import { ModalContext } from "./WniModalProvider";

const useWniBaseModal = () => {
    const modalContext = useContext(ModalContext);
    if(!modalContext) {
        throw new Error('useModal must be used');
    }

    const { show }  = modalContext;

    return { 
        showAlert: (props) => show({
            ...props,
            showCancelButton: false,
            modalType: 'alert'
        }),
        showConfirm: (props) => show({
            ...props,
            modalType: 'confirm'
        }),
        showModal: (props) => show({
            element: props,
            modalType: 'element'
        })
     };
};

export default useWniBaseModal;
