
import React, {
    useCallback, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { useTranslator } from '@jutro/locale';

import OperatorUtil from '../../util/OperatorUtil'

import OperatorContactDetails from '../OperatorContactDetails/OperatorContactDetails';
import OperatorLicenseInfo from '../OperatorLicenseInfo/OperatorLicenseInfo';
import OperatorQuestions from '../OperatorQuestions/OperatorQuestions';

import metadata from './OperatorComponent.metadata.json5';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function OperatorComponent(props) {
    const {
        operatorVM,
        onValueChange,
        showErrors,
        accordionStates,
        updateAccordionStates,
        baseState,
        messages,
        effectiveDate,
        isReadOnly,
        relationshipOptions,
        availableAccountContacts,
        isShowCompletedSafetyCoursesInfo,
        isShowCompletedSafetyCoursesInfoWT,
        isShowOperatorhaveMotorcycleEndorsement,
        isShowOperatorMotorcycleEndorsementDuration,
        isShowPersonOperateMotorcycle,
        isShowWillDriverExclusion,
        isShowMaritalStatus,
        isShowGender
    } = props;

    const {
        isLicensedDriver,
        unLicensedDriverReason,
        dateOfBirth
    } = operatorVM.value;

    const questionset = _.get(operatorVM, 'value.driverQuestionSets[0].answers') || {};

    const translator = useTranslator();

    const classNameMap = [
        { explainForIsDriverRequiredSubmit_Ext: 'indentSection' },
        { explainForhasLicenseSuspended_Ext: 'indentSection' },
        { explainForIsDriverRequiredSubmitRT_Ext: 'indentSection' },
        { explainForhasLicenseSuspendedRT_Ext: 'indentSection' },
    ];

    useEffect(() => {
        if (OperatorUtil.getAge(operatorVM, effectiveDate) <= 12 || !isShowPersonOperateMotorcycle) {
            _.set(questionset, 'personOperateMotorcycleNoDependent_Ext', null);
        }
        if (!questionset.personOperateMotorcycleNoDependent_Ext) {
            _.set(questionset, 'personOperateMotorcycleNoDependent_Ext', 'false');
        }
    }, [dateOfBirth]);

    const getAccordionStatesOverrides = useCallback(() => {
        return OperatorUtil.getAccordionOverridesForOperator(operatorVM);
    }, [operatorVM]);

    const defaultOpenedId = useCallback(() => {
        const errorStateObj = OperatorUtil
            .getAccordionOverridesForOperator(operatorVM);
        const filterErrorStateObj = _.pickBy(errorStateObj, (v) => { return v.errorState});
        const openedArr = _.keys(filterErrorStateObj);
        
        if(!_.isEmpty(openedArr)) {
            return _.take(openedArr)
        }
        const accordionCardIds = _.keys(errorStateObj);
        return _.take(accordionCardIds);
    }, [operatorVM]);

    useEffect(() => {
        updateAccordionStates(defaultOpenedId());
    }, []);

    const filterQuestionSets = useCallback((metadataContent) => {
        /**
         * WAL
         * HasCompletedSafetyCoursesNoDependent_Ext
         * explainForIsDriverRequiredSubmit_Ext
         * explainForhasLicenseSuspended_Ext
         * hasLicenseSuspended_Ext
         * isDriverRequiredSubmit_Ext
         * willDriverExclusion_Ext
         */
        /**
         * RT
         * HasCompletedSafetyCoursesRTNoDependent_Ext
         * explainForIsDriverRequiredSubmitRT_Ext
         * explainForhasLicenseSuspendedRT_Ext
         * hasLicenseSuspendedRT_Ext
         * isDriverRequiredSubmitRT_Ext
         * operatorMotorcycleEndorsementDurationNoDependent_Ext
         * operatorhaveMotorcycleEndorsementNoDependent_Ext
         * personOperateMotorcycleNoDependent_Ext
         */
        const hideQuestions = [];
        const isChild = OperatorUtil.isChild(operatorVM, baseState, effectiveDate);
        if ((!isLicensedDriver && unLicensedDriverReason === 'revokedsuspended') || isChild) {
            hideQuestions.push('hasLicenseSuspended_Ext', 'explainForhasLicenseSuspended_Ext', 'hasLicenseSuspendedRT_Ext', 'explainForhasLicenseSuspendedRT_Ext');
        }

        if (!isLicensedDriver &&
            (!unLicensedDriverReason
            || unLicensedDriverReason === 'permitonly'
            || unLicensedDriverReason === 'underage'
            || unLicensedDriverReason === 'willNeverLicensed'
            || unLicensedDriverReason === 'other')) {
            hideQuestions.push('isDriverRequiredSubmit_Ext', 'explainForIsDriverRequiredSubmit_Ext', 'isDriverRequiredSubmitRT_Ext', 'explainForIsDriverRequiredSubmitRT_Ext')
        }

        if (!isShowWillDriverExclusion) {
            hideQuestions.push('willDriverExclusion_Ext', 'willDriverExclusionRT_Ext');
        }
        if (!isShowCompletedSafetyCoursesInfoWT) {
            hideQuestions.push('HasCompletedSafetyCoursesNoDependent_Ext');
        }
        if (!isShowCompletedSafetyCoursesInfo) {
            hideQuestions.push('HasCompletedSafetyCoursesRTNoDependent_Ext');
        }
        if (!isShowOperatorhaveMotorcycleEndorsement) {
            hideQuestions.push('operatorhaveMotorcycleEndorsementNoDependent_Ext');
        }
        if (!isShowOperatorMotorcycleEndorsementDuration) {
            hideQuestions.push('operatorMotorcycleEndorsementDurationNoDependent_Ext');
        }
        if (!isShowPersonOperateMotorcycle) {
            hideQuestions.push('personOperateMotorcycleNoDependent_Ext');
        }
        hideQuestions.push('employeeDiscountRT_Ext',
                        'HasCompletedSafetyCoursesRT_Ext',
                        'HasCompletedSafetyCourses_Ext',
                        'operatorhaveMotorcycleEndorsement_Ext',
                        'personOperateMotorcycle_Ext',
                        'operatorMotorcycleEndorsementDuration_Ext');
        return _.indexOf(hideQuestions, metadataContent.id) < 0;
    }, [operatorVM, baseState, effectiveDate, isLicensedDriver, unLicensedDriverReason, isShowWillDriverExclusion, isShowCompletedSafetyCoursesInfo, isShowCompletedSafetyCoursesInfoWT, isShowOperatorhaveMotorcycleEndorsement, isShowOperatorMotorcycleEndorsementDuration, isShowPersonOperateMotorcycle]);

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        operatorAccordion: {
            accordionStates
        },
        operatorContactDetailsSection: {
            operatorVM,
            relationshipOptions,
            availableAccountContacts,
            onValueChange,
            showErrors,
            isReadOnly,
            isShowMaritalStatus,
            isShowGender
        },
        operatorLicenseInfoSection: {
            operatorVM,
            onValueChange,
            showErrors,
            baseState,
            isReadOnly
        },
        // operatorUnderwritingQuestionSets1: {
        //     operatorVM,
        //     onValueChange,
        //     showErrors,
        //     baseState,
        //     messages,
        //     effectiveDate,
        //     isReadOnly
        // },
        operatorUnderwritingQuestionSets: {
            contentFilter: filterQuestionSets,
            contentMapper: OperatorUtil.getQuestionMapper(translator, messages, classNameMap),
            isReadOnly
        },
        ...getAccordionStatesOverrides()

    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            operatorcontactdetails: OperatorContactDetails,
            operatorlicense: OperatorLicenseInfo,
            operatorquestions: OperatorQuestions,
            questionset: QuestionSetComponent,
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            operatorVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={operatorVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
}

OperatorComponent.propTypes = {
    operatorVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    baseState: PropTypes.string,
    messages: PropTypes.object,
    effectiveDate: PropTypes.object,
    isReadOnly: PropTypes.bool,
    relationshipOptions: PropTypes.array,
    availableAccountContacts: PropTypes.array,
    isShowCompletedSafetyCoursesInfo: PropTypes.bool,
    isShowOperatorhaveMotorcycleEndorsement: PropTypes.bool,
    isShowOperatorMotorcycleEndorsementDuration: PropTypes.bool,
    isShowPersonOperateMotorcycle: PropTypes.bool,
    isShowWillDriverExclusion: PropTypes.bool
};

OperatorComponent.defaultProps = {
    operatorVM: null,
    onValueChange: _.noop,
    showErrors: false,
    baseState: '',
    messages: {},
    effectiveDate: {},
    isReadOnly: false,
    relationshipOptions: [],
    availableAccountContacts: [],
    isShowCompletedSafetyCoursesInfo: false,
    isShowOperatorhaveMotorcycleEndorsement: false,
    isShowOperatorMotorcycleEndorsementDuration: false,
    isShowPersonOperateMotorcycle: false,
    isShowWillDriverExclusion: false
};

export default OperatorComponent;
