import { defineMessages } from 'react-intl';

export default defineMessages({
    payAndIssue: {
        id: 'wni.payment.ICdownPaymentSetup.pay and issue button',
        defaultMessage: 'Pay & Issue'
    },
    forQuotes: {
        id: 'wni.payment.ICdownPaymentSetup.For Quotes',
        defaultMessage: 'For Quotes',
    },
    agencySweep: {
        id: 'wni.payment.ICdownPaymentSetup.Agency Sweep',
        defaultMessage: 'Agency Sweep',
    },
    addNewCreditCard: {
        id: 'wni.payment.ICdownPaymentSetup.Add New Credit Card',
        defaultMessage: 'Pay By Credit Card'
    },
    addNewBankAccount: {
        id: 'wni.payment.ICdownPaymentSetup.Add New Bank Account',
        defaultMessage: 'Pay By Bank Account'
    },
    agencySweepMessage: {
        id: 'wni.payment.ICdownPaymentSetup.Agency Sweep Message',
        defaultMessage: 'You have selected Agency Sweep as the down payment method.  Please note, you will see a separate down payment for each policy issued.  If you are submitting multiple policies for issue, the down payment amount will not be combined.'
    },
    minimumDue: {
      id: 'wni.payment.ICdownPaymentSetup.Minimum Due',
      defaultMessage: 'Minimum Due'
    },
    paymentMethod: {
      id: 'wni.payment.ICdownPaymentSetup.Payment Method',
      defaultMessage: 'Payment Method'
    },
    minimumAmount: {
      id: 'wni.payment.ICdownPaymentSetup.Minimum Amount',
      defaultMessage: 'The minimum down payment amount due is ${amount}'
    },
    minimumDueOther: {
      id: 'wni.payment.ICdownPaymentSetup.Minimum Amount Other',
      defaultMessage: 'Other'
    }
});
