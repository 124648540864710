import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imBuildingsAndLocationsService'), method, data, additionalHeaders);
}

export default class IMBuildingsAndLocationsService {

    /**
     * Invokes IMBuildingsAndLocationsService.updateLocation()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} location
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateLocation(quoteID, sessionUUID, location, authHeader = {}) {
        return processSubmission(
            'updateLocation',
            [quoteID, sessionUUID, location],
            authHeader
        );
    }

    /**
     * Invokes IMBuildingsAndLocationsService.removeLocations()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} publicID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeLocation(quoteID, sessionUUID, publicID, authHeader = {}) {
        return processSubmission(
            'removeLocation',
            [quoteID, sessionUUID, publicID],
            authHeader
        );
    }

    /**
     * Invokes IMBuildingsAndLocationsService.updateBuilding()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} building
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBuilding(quoteID, sessionUUID, building, authHeader = {}) {
        return processSubmission(
            'updateBuilding',
            [quoteID, sessionUUID, building],
            authHeader
        );
    }

    /**
     * Invokes IMBuildingsAndLocationsService.removeBuildings()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Array} publicIDs
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeBuildings(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission(
            'removeBuildings',
            [quoteID, sessionUUID, publicIDs],
            authHeader
        );
    }

    /**
     * Invokes IMBuildingsAndLocationsService.getPrefillBuildingFields()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} building
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getPrefillBuildingFields(quoteID, sessionUUID, building, authHeader = {}) {
        return processSubmission(
            'getPrefillBuildingFields',
            [quoteID, sessionUUID, building],
            authHeader
        );
    }

    /**
     * Invokes IMBuildingsAndLocationsService.onPageNext()
     *   
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader
        );
    }

}