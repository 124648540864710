import { defineMessages } from 'react-intl';

export default defineMessages({
    blockTitle: {
        id: "gateway.views.policy-holder.new-quote.New Submission Blocked for {Lob}",
        defaultMessage: "New Submission Blocked for {Lob}"
    },
    blockMessage: {
        id: "gateway.views.policy-holder.new-quote.We are sorry to say we cannot accept this account based on some of the information provided. Please reach out to an underwriter to continue this process outside of the portal.",
        defaultMessage: "We are sorry to say we cannot accept this account based on some of the information provided. Please reach out to an underwriter to continue this process outside of the portal."
    }
});
