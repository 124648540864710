import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    editButton: {
        id: 'custom.component.common.TextEditConfirmComponent.Edit',
        defaultMessage: 'Edit'
    },
    confirmButton: {
        id: 'custom.component.common.TextEditConfirmComponent.Confirm',
        defaultMessage: 'Confirm'
    },
    cancelButton: {
        id: 'custom.component.common.TextEditConfirmComponent.Cancel',
        defaultMessage: 'Cancel'
    }
});
