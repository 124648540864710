import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverFirstName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.First Name',
        defaultMessage: 'First Name',
    },
    paDriverMiddleName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Middle Name',
        defaultMessage: 'Middle Name',
    },
    paDriverLastName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Last Name',
        defaultMessage: 'Last Name',
    },
    paDriverSuffix: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Suffix',
        defaultMessage: 'Suffix',
    },
    paDriverDateOfBirth: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Date of Birth',
        defaultMessage: 'Date of Birth',
    },
    paDriverGender: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Gender',
        defaultMessage: 'Gender',
    },
    paDriverRelInsured: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Relationship to Insured',
        defaultMessage: 'Relationship to Insured',
    },
    paDriverGenderPlaceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Driver Gender --',
        defaultMessage: '-- Choose Driver Gender --',
    },
    paDriversLicenseNumber: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Drivers License Number',
        defaultMessage: 'Drivers License Number',
    },
    paDriverLicenceState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.License State',
        defaultMessage: 'License State',
    },
    paDriverYearFirstLicensed: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Year First Licensed',
        defaultMessage: 'Year First Licensed',
    },
    paDriverNumberOfAccidents: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Accidents',
        defaultMessage: 'Number Of Accidents',
    },
    paDriverNumberOfViolations: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Violations',
        defaultMessage: 'Number Of Violations',
    },
    paDriverLicenseYear: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License Year --',
        defaultMessage: '-- Choose License Year --',
    },
    paDriverLicenseState: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License State --',
        defaultMessage: '-- Choose License State --',
    },
    paDriverSafeToDrive: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Is this vehicle safe to drive?',
        defaultMessage: 'Is this vehicle safe to drive?',
    },
    paDriverMaritalStatus: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Marital Status',
        defaultMessage: 'Marital Status',
    },
    paDriverMaritalStatusPlaceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Marital Status --',
        defaultMessage: '-- Choose Marital Status --',
    },
    paDriverOccupation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.occupation',
        defaultMessage: 'occupation',
    },
    paDriverOccupationPlaceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose occupation --',
        defaultMessage: '-- Choose occupation --',
    },
    paDriverPrimaryPhone: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Primary Phone',
        defaultMessage: 'Primary Phone',
    },
    paDriverPrimaryPhonePalceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Primary Phone --',
        defaultMessage: '-- Choose Primary Phone --',
    },
    paDriverPhoneNumber: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Phone Number',
        defaultMessage: 'Phone Number',
    },
    paDriverPrimaryEmail: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Primary Email',
        defaultMessage: 'Primary Email',
    },
    paDriverDetailInformation: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Driver / Household Member Information',
        defaultMessage: 'Driver / Household Member Information',
    },
    paDriverIsthisPerson: {
        id:
            'fnol.ca.directives.vehicle-incident.Is this person a licensed Driver?',
        defaultMessage: 'Is this person a licensed Driver?',
    },
    paDriverInsurancePolicy: {
        id:
            'fnol.ca.directives.vehicle-incident.Will this driver be covered by this insurance policy? ',
        defaultMessage:
            'Will this driver be covered by this insurance policy? ',
    },
    paDriverReason: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Reason',
        defaultMessage: 'Reason',
    },
    paDriverReasonPlaceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Reason --',
        defaultMessage: '-- Choose Reason --',
    },
    paDriverExplain: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Explain',
        defaultMessage: 'Explain',
    },
    paDriverLicenseStatus: {
        id:
            "quoteandbind.pa.directives.templates.pa-edit-driver.Driver's License Status",
        defaultMessage: "Driver's License Status",
    },
    paDriverLicenseStatusPlaceholder: {
        id:
            "quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Driver's License Status --",
        defaultMessage: "-- Choose Driver's License Status --",
    },
    paDriverLicenseNumber: {
        id:
            "quoteandbind.pa.directives.templates.pa-edit-driver.Driver's License Number",
        defaultMessage: "Driver's License Number",
    },
    paLicenseStatus: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.License Status',
        defaultMessage: 'License Status',
    },
    paLicenseStatusPlaceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License Status --',
        defaultMessage: '-- Choose License Status --',
    },
    paDriverAgeFirstLicensed: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Age First Licensed',
        defaultMessage: 'Age First Licensed',
    },
    paDriverAgeFirstLicensedPlaceholder: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Age First Licensed --',
        defaultMessage: '-- Choose Age First Licensed --',
    },
    paDriverSSN: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.SSN',
        defaultMessage: 'SSN',
    },
    paDriverCumulativeGPA: {
        id:
            'quoteandbind.ca.directives.vehicle-incident.Is this driver a full time student or college graduate with a cumulative GPA of 3.0 of higher?',
        defaultMessage:
            'Is this driver a full time student or college graduate with a cumulative GPA of 3.0 of higher?',
    },
    paDriverWithoutACar: {
        id:
            'quoteandbind.ca.directives.vehicle-incident.Is this driver a full time student away at school more than 100 miles without a car?',
        defaultMessage:
            'Is this driver a full time student away at school more than 100 miles without a car?',
    },
    paDriverDefensiveDriverDiscount: {
        id:
            'quoteandbind.ca.directives.vehicle-incident.Defensive Driver Discount:',
        defaultMessage: 'Defensive Driver Discount:',
    },
    paDriverCourseCompletionDate: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Course Completion Date',
        defaultMessage: 'Course Completion Date',
    },
    paDriverWorkLossDiscount: {
        id: 'quoteandbind.ca.directives.vehicle-incident.Work Loss Discount:',
        defaultMessage: 'Exclusion of work loss benefit',
    },
    paDriverYes: {
        id: 'quoteandbind.ca.directives.vehicle-incident.Yes',
        defaultMessage: 'Yes',
    },
    paDriverNo: {
        id: 'quoteandbind.ca.directives.vehicle-incident.No',
        defaultMessage: 'No',
    },
    chooseRalInsured: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Relationship to Insured --',
        defaultMessage: '-- Choose Relationship to Insured --',
    },
    additionalNamedInsure: {
        id:
            'quoteandbind.pa.directives.vehicle-incident.Additional Named Insured',
        defaultMessage: 'Additional Named Insured',
    },
    InexperiencedDriver: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Inexperienced Driver?',
        defaultMessage: 'Inexperienced Driver?',
    },
    placeholderSuffix: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Suffix --',
        defaultMessage: '-- Choose Suffix --',
    },
    workNumber: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Work Number',
        defaultMessage: 'Work Number',
    },
    homeNumber: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Home Number',
        defaultMessage: 'Home Number',
    },
    cellNumber: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Phone Number',
        defaultMessage: 'Phone Number',
    },
    schoolName: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.Name of school',
        defaultMessage: 'Name of school',
    },
    licenseNumberValidate: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.verify driver license number',
        defaultMessage: 'Please verify drivers license number. Length of license number does not match the state'
    },
    greaterAgeNotification: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Driver is greater than 69 years old',
        defaultMessage: 'Driver is greater than 69 years old',
    },
    lessAgeNotification: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Primary named insured is less than 16 years old',
        defaultMessage: 'Primary named insured is less than 16 years old',
    },
    driverDefenseTooltip: {
        id: 'quoteandbind.pa.directives.driver-defense.tooltip',
        defaultMessage: 'Drivers age 55 and over who have successfully completed a defensive driving course may be eligible for this discount'
    },
    driverWorkLossTooltip: {
        id: 'quoteandbind.pa.directives.driver-workloss.tooltip',
        defaultMessage: 'Available to residents/operators who have attained age 65, or are 60-64 and retired and receiving a pension'
    },
    updateCoverages: {
        id: 'quoteandbind.pa.directives.Update Optional Coverages to Driver',
        defaultMessage: 'Do you want to Add/Update Optional Coverages to Driver?'
    },
    requiredForQuote: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Required for Quote',
        defaultMessage: '-- Required for Quote --'
    },
    requiredForIssuance: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Required for Issuance',
        defaultMessage: '-- Required for Issuance --'
    },
    driverAnyCurFinResFilingYNMsg: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.override.AnyCurFinResFilingYN',
        defaultMessage: 'Is this driver required to submit a financial responsibility filing?'
    }
});
