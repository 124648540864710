
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import ResidenceUtil from '../../util/ResidenceUtil';

import metadata from './SolidFuelFormCompnent.metadata.json5';
import messages from '../../HOResidencePage.messages';

const SolidFuelFormCompnent = (props) => {
    const {
        solidFuelVM,
        solidFuelsVM,
        isReadOnly,
        updateSolidFuelVM,
        editSolidFuel,
        updateSolidFuelsVM,
        solidFuelTypeOptions,
        viewModelService,
        originSolidFuelVM,
        linePath,
    } = props;

    // const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [loading, updateLoading] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        isSolidFuelDeviceABarrel,
        solidFuelType,
        stoveLocated
    } = solidFuelVM.value;

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('solidFuelForm');

    const IsValidFields = useCallback(() => {
        return true;
    }, []);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const cancel = () => {
        const solidFuelsValue = _.get(solidFuelsVM, 'value');
        const removeNullSolidFuels = _.filter(solidFuelsValue, (solidFuel) => {
            return solidFuel.publicID
        })
        _.set(solidFuelsVM, 'value', removeNullSolidFuels);

        if (_.get(originSolidFuelVM, 'value.publicID')) {
            const index = _.findIndex(solidFuelsVM.value, (solidFuel) => { return solidFuel.publicID === _.get(originSolidFuelVM, 'value.publicID')})
            _.set(solidFuelsVM, `value.${index}`, originSolidFuelVM.value);
        }

        updateSolidFuelsVM(solidFuelsVM);
        updateSolidFuelVM(null);
    };

    const handleSolidFuelChange = useCallback((value, path, newSolidFuelVMParam) => {
        const newSolidFuelVM = newSolidFuelVMParam || viewModelService.clone(solidFuelVM);
        _.set(newSolidFuelVM, path, value);
        updateSolidFuelVM(newSolidFuelVM);
        const index = _.findIndex(solidFuelsVM.value, (solidFuel) => { return solidFuel.publicID ===  newSolidFuelVM.value.publicID})
        _.set(solidFuelsVM, `value.${index}`, newSolidFuelVM.value);
        updateSolidFuelsVM(solidFuelsVM);
    }, [solidFuelVM, solidFuelsVM, updateSolidFuelVM, updateSolidFuelsVM, viewModelService]);

    const save = useCallback(async () => {
        const requestData = {
            solidFuel: _.get(solidFuelVM, 'value')
        }
        updateLoading(true);
        const res = await editSolidFuel(requestData);

        updateSolidFuelVM(null);
        updateLoading(false);
    }, [solidFuelVM]);

    const handleSolidFuelType = useCallback((value, path) => {
        const newSolidFuelVM = viewModelService.clone(solidFuelVM);
        _.set(newSolidFuelVM, path, value);
        handleSolidFuelChange(value, path, newSolidFuelVM);
        if (value === 'OutdoorWoodBoiler') {
            _.set(newSolidFuelVM, 'stoveLocated', 'outdoorwoodboiler');
            handleSolidFuelChange('outdoorwoodboiler', 'stoveLocated', newSolidFuelVM);
        }
    }, [handleSolidFuelChange, solidFuelVM, stoveLocated, viewModelService]);

    const solidFuelDeviceKnockoutObj = useCallback(() => {
        const isShowSolidFuelDeviceKnockout = isSolidFuelDeviceABarrel
        const solidFuelDeviceIneligibleMessage = translator(messages.solidFuelTypeIneligible)
        return {
            isShowKnockout: isShowSolidFuelDeviceKnockout,
            ineligibleMessage: solidFuelDeviceIneligibleMessage
         };
    },[isSolidFuelDeviceABarrel, translator])
    const solidFuelDeviceComponentProps = ResidenceUtil.getIneligibleKnockout(solidFuelDeviceKnockoutObj(), linePath);

    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        isSolidFuelDeviceABarrel: {
            ineligibleTooptip: solidFuelDeviceComponentProps,
        },
        type: {
            availableValues: solidFuelTypeOptions,
            onValueChange: handleSolidFuelType
        },
        explain: {
            visible: solidFuelType === 'Other'
        },
        cancel: {
            onClick: cancel
        },
        save: {
            onClick: isComponentValid ? save : handleValidation
        }
    };

    const resolvers = {
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            solidFuelVM,
            id,
            path,
            overrideProps
        );
    };

    if (loading) {
        return <Loader loaded={!loading} />;
    }

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={solidFuelVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValueChange={handleSolidFuelChange}
                showErrors={showErrors}
            />
        </div>
    );
};

SolidFuelFormCompnent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    solidFuelVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    solidFuelsVM: PropTypes.PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    originSolidFuelsVM: PropTypes.PropTypes.shape({}),
    isReadOnly: PropTypes.bool,
    updateSolidFuelVM: PropTypes.func,
    editSolidFuel: PropTypes.func,
    removeSolidFuels: PropTypes.func,
    updateSolidFuelsVM: PropTypes.func,
    solidFuelTypeOptions: PropTypes.array,
    linePath: PropTypes.string,
    // viewModelService:  PropTypes.shape({})
    // originAnimalsVM: PropTypes.shape({})
};

SolidFuelFormCompnent.defaultProps = {
    model: {},
    solidFuelVM: {},
    solidFuelsVM: {},
    originSolidFuelsVM: {},
    isReadOnly: false,
    updateSolidFuelVM: _.noop,
    editSolidFuel: _.noop,
    removeSolidFuels: _.noop,
    updateSolidFuelsVM: _.noop,
    solidFuelTypeOptions: []
    // viewModelService: {}
    // originAnimalsVM: null
};

export default SolidFuelFormCompnent;
