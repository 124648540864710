
const FIRST_CHANGE_WIZARD_PAGE = 'CAPolicyDetailsPage';

function getChangePageJumpList({ policyChangeSource, getPageJumpAction }) {
    let retval = [];
    if (policyChangeSource === 'cancellation') {
        retval = [{
            name: 'Mailing Address',
            action: getPageJumpAction('CAPolicyDetailsPage'),
        }];
    } else {
        retval = [
            // {
            //     name: 'CA Page',
            //     action: getPageJumpAction('CADummyPage'),
            // },
            // {
            //     name: 'Qualification',
            //     action: getPageJumpAction('CAQualificationPage'),
            // },
            {
                name: 'Policy Details',
                action: getPageJumpAction('CAPolicyDetailsPage'),
            },
            // {
            //     name: 'Locations',
            //     action: getPageJumpAction('CALocationsPage'),
            // },
            // {
            //     name: 'State Specific Information',
            //     action: getPageJumpAction('CAStateSpecificInformationPage'),
            // },
            {
                name: 'Vehicles',
                action: getPageJumpAction('CAVehiclesPage'),
            },
            // {
            //     name: 'Drivers',
            //     action: getPageJumpAction('CADriversPage'),
            // },
            // {
            //     name: 'Coverages',
            //     action: getPageJumpAction('CACoveragesPage'),
            // },
            // {
            //     name: 'Covered Vehicle Symbols',
            //     action: getPageJumpAction('CACoveredVehicleSymbolsPage'),
            // },
            // {
            //     name: 'Modifiers',
            //     action: getPageJumpAction('CAModifiersPage'),
            // },
            // {
            //     name: 'Underwriting Questions',
            //     action: getPageJumpAction('CAUnderwritingQuestionsPage'),
            // },
        ];
    }
    return retval;
}

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};