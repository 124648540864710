import { defineMessages } from 'react-intl';

export default defineMessages({
    thisPropertyBelongsToYou: {
        id: "wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.thisPropertyBelongsToYou",
        defaultMessage: "This property belongs to you?"
    },
    damageDescription: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.damageDescription',
        defaultMessage: 'Damage Description'
    },
    Yes: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.Yes',
        defaultMessage: 'Yes'
    },
    No: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.No',
        defaultMessage: 'No'
    },
    addNewLocation: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.addNewLocation',
        defaultMessage: 'Add new location'
    },
    cancel: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singlePropertyIncidentPopup.save',
        defaultMessage: 'Save'
    }
})