
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { JobService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import messages from './WithdrawTransactionComponent.messages';
import metadata from './WithdrawTransactionComponent.metadata.json5';
import styles from './WithdrawTransactionComponent.module.scss';

/**
 * This simple component encapsulates the Withdraw feature
 *
 * See QuoteDetails/Summary/Summary.jsx::onWithdrawTransaction()
 * @param {object} props
 * @returns {object} a React component
 */
const WithdrawTransactionComponent = (props) => {
    const modalApi = useWniModal();
    const {
        jobNumber,
        beforeWithdraw,
        onWithdrawn,
        afterWithdraw,
        btnContent,
        messageWithJobNumber,
        confirmMessage,
    } = props;

    const { authHeader } = useAuthentication();
    const translator = useTranslator();

    useEffect(() => {
    }, []);

    const onWithdrawTransaction = () => {
        modalApi.showConfirm({
            title: messages.withdrawJob,
            message: messageWithJobNumber ? translator(confirmMessage) : `${translator(confirmMessage)} ${jobNumber}?`,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            beforeWithdraw();
            JobService.withdrawJobByJobNumber(jobNumber, authHeader).then(
                () => {
                    onWithdrawn();
                },
                () => {
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            ).finally(afterWithdraw);
        }, _.noop);
    };

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        withdrawTransactionBtn: {
            content: btnContent,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onWithdrawTransaction,
        },
        resolveComponentMap: {

        },
    };

    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

WithdrawTransactionComponent.propTypes = {
    jobNumber: PropTypes.string.isRequired,
    beforeWithdraw: PropTypes.func,
    onWithdrawn: PropTypes.func,
    afterWithdraw: PropTypes.func,
    btnContent: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
    }),
    messageWithJobNumber: PropTypes.bool,
    confirmMessage: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
    }),
};

WithdrawTransactionComponent.defaultProps = {
    beforeWithdraw: _.noop,
    onWithdrawn: _.noop,
    afterWithdraw: _.noop,
    btnContent: messages.withdraw,
    messageWithJobNumber: false,
    confirmMessage: messages.sureWithDrawQuote,
};

export default WithdrawTransactionComponent;
