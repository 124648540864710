
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

import { DPHouseholdInfoReadOnlyPage } from 'wni-capability-quoteandbind-dp-react';

function DPHouseholdInfoChangeReadOnlyPage(props) {

    return (
        <DPHouseholdInfoReadOnlyPage {...props} />
    );

}

DPHouseholdInfoChangeReadOnlyPage.propTypes = DPHouseholdInfoReadOnlyPage.propTypes;
DPHouseholdInfoChangeReadOnlyPage.defaultProps = DPHouseholdInfoReadOnlyPage.defaultProps;

export default DPHouseholdInfoChangeReadOnlyPage;
