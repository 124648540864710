

import React from 'react';
import { IMBuildersRiskPage } from 'wni-capability-quoteandbind-im-react';


const IMBuildersRiskChangePage = (props) => {
    return <IMBuildersRiskPage {...props} isPolicyChange />
};
export default IMBuildersRiskChangePage

