
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

import { DPResidenceReadOnlyPage } from 'wni-capability-quoteandbind-dp-react';

function DPResidenceChangeReadOnlyPage(props) {

    return (
        <DPResidenceReadOnlyPage {...props} />
    );

}

DPResidenceChangeReadOnlyPage.propTypes = DPResidenceChangeReadOnlyPage.propTypes;
DPResidenceChangeReadOnlyPage.defaultProps = DPResidenceChangeReadOnlyPage.defaultProps;

export default DPResidenceChangeReadOnlyPage;