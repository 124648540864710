import React, {useEffect, useState, useContext, useCallback} from 'react';
import _, { property } from 'lodash';
import { Icon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ConfigUtil } from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { PUUnderlyingService } from 'wni-capability-quoteandbind-pu';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PersonalAutoDetails from '../components/PersonalAutoDetails/PersonalAutoDetails';
import DwellingDetails from '../components/DwellingDetails/DwellingDetails';
import MotorcycleDetails from '../components/MotorcycleDetails/MotorcycleDetails';
import WatercraftDetails from '../components/WatercraftDetails/WatercraftDetails';
import RecreationalDetails from '../components/RecreationalDetails/RecreationalDetails';
import UnderlyingCoverageInfo from '../components/UnderlyingCoverageInfo/UnderlyingCoverageInfo';
import metadata from './PUUnderlyingComponent.metadata.json5';
import messages from '../PUUnderlyingPage.messages';
import { setDefaultObj } from '../util/Underlying.util';

import { Button } from '@jutro/legacy/components';

function PUUnderlyingComponent(props) {
    const modalApi = useWniModal();

    const {
        currentRow,
        syncWizardData,
        syncWizardDataSnapshot,
        onValueChange,
        onValidate,
        showErrors,
        updateShowErrors,
        isReadOnly,
        isRequiredForIssuance,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader,
            policyLine
        } = {}
    } = props;
    
    const {
        coveredItem,
        publicID,
        underlyingPolicyType
    } = currentRow.value;

    const componentProps = _.omit(props, 'id');
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const [openAccordionIds, updateOpenAccordionIds] = useState([]);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    useEffect(() => {
        if(coveredItem !== 'property') {
            const indexs = []
            const coveredDataVM = _.get(currentRow, coveredItem, []);
            coveredDataVM.forEach((itemVM, index) => {
                if (!itemVM.aspects.valid || !itemVM.aspects.subtreeValid) {
                    indexs.push(index);
                }
            });
            const accordionIds= indexs.map((i) => `${coveredItem}Card${i}`);
            updateOpenAccordionIds(accordionIds)
        }
        
    }, []);

    const addVehicle = async(e) => {
        e.stopPropagation()
        const {
            _dtoName,
            _xCenter
        } = _.get(currentRow, coveredItem, {});
        const newData = setDefaultObj(coveredItem, policyLine)
        const newVM = await viewModelService.create(newData, _xCenter, _dtoName);
        _.get(currentRow, coveredItem, []).pushElement(newVM);
        const coveredData = _.get(currentRow.value, coveredItem, []);
        if(coveredData.length > 1) {
            _.set(currentRow.value, 'areVehiclesUnderSamePol', true);
        }
        syncWizardData(currentRow);
        updateShowErrors(false);

        const accordionIndex = _.findLastIndex(coveredData, (item) => item.rowIdPath === newData.rowIdPath);
        updateOpenAccordionIds([`${coveredItem}Card${accordionIndex}`])
    };
    const removeVehicle = async(e, item) => {
        e.stopPropagation();
        const coveredData = _.get(currentRow.value, coveredItem, []);
        if(coveredData.length ===1) {
            modalApi.showAlert({
                title: 'Error',
                message: translator(messages.vehicleDeleteTips),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return false;
        }
        modalApi.showConfirm({
            title: messages.removeTitle,
            message: messages.removeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            setLoadingMask(true);
            const { publicId: vehicleId, rowIdPath } = item;
            if(!vehicleId) { // the new vehicle has not been added to the database 
                const allVehicles = _.get(currentRow.value, coveredItem, []);
                const filterVehicles = allVehicles.filter((v) => v.rowIdPath !== rowIdPath);
                _.set(currentRow.value, coveredItem, filterVehicles);
                if(filterVehicles.length === 1) {
                    _.set(currentRow.value, 'areVehiclesUnderSamePol', undefined);
                }
                
                syncWizardData(currentRow);
                setLoadingMask(false);
                return false;
            }
            const res = await PUUnderlyingService.removeVehicle(jobID, sessionUUID, publicID, vehicleId, underlyingPolicyType, authHeader);
            _.set(currentRow, 'value', res);
            syncWizardData(currentRow);
            setLoadingMask(false); 
        })
    };

    // eslint-disable-next-line default-param-last
    const renderSectionTitle = (item = {}, index) => {
        return (
            <Flex justifyContent="between">
                <span>
                    {translator(messages[`${coveredItem}Details`], {indexNumber: index + 1})}
                </span>
                {!isReadOnly && (
                    <Flex justifyContent="right" gap="medium" alignItems="center">
                        {index === 0 && (
                            <Button onClick={(e) => addVehicle(e)}>
                                {translator(messages[`add${_.upperFirst(coveredItem)}`])}
                            </Button>
                        )}
                        <Icon icon="gw-delete" onClick={(e) => {removeVehicle(e, item, index)}} className="wni-icon-error"/>
                    </Flex>
                )}
            </Flex>
        );
    };

    const renderContent = (coveredVMMap, index) => {
        return [{
            id: `${coveredItem}Details${index}`,
            type: "element",
            component: `${coveredItem}details`,
            componentProps: {
                index: index,
                coveredItem,
                vm: coveredVMMap[index],
                ...componentProps
            }
        }]
    }

    const renderCoveredSection = () => {
        const coveredVMMap = _.get(currentRow, `${coveredItem}.children`, []);
        return coveredVMMap.map((item, index) => {
            return {
                id: `${coveredItem}Card${index}`,
                type: 'container',
                component: 'accordioncard',
                componentProps: {
                    allowOverflowContent: true,
                    chevron: true,
                    chevronAlignment: 'left',
                    title: renderSectionTitle(item.value, index),
                    errorState: !item.aspects.valid || !item.aspects.subtreeValid
                },
                content: renderContent(coveredVMMap, index)

            };
        })
    };
    //---------------------

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        '@action': {
            visible: false
        },
        propertyDetails: {
            visible: coveredItem === 'property',
            coveredItem,
            ...componentProps
        },
        [`${coveredItem}Section`]: {
            visible: true,
            content: renderCoveredSection(),
            accordionStates: openAccordionIds
        },
    
        underlyingPolicySection: {
            ...componentProps
        },
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            autodetails: PersonalAutoDetails,
            dwellingdetails: DwellingDetails,
            motorcycledetails: MotorcycleDetails,
            watercraftdetails: WatercraftDetails,
            recreationaldetails: RecreationalDetails,
            underlyingcoverageinfo: UnderlyingCoverageInfo
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default PUUnderlyingComponent;
