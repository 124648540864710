import { defineMessages } from 'react-intl';

export default defineMessages({
    noVehicle: {
        id: 'wni.claim.views.claim-detail.No vehicles associated with this policy',
        defaultMessage: 'No vehicles associated with this policy'
    },
    createNoteContent: {
        id: 'wni.claim.views.create-note.Note',
        defaultMessage: 'Note'
    },
    updateFailedError: {
        id: 'wni.claim.controllers.CreateNoteCtrl.Claim update failed',
        defaultMessage: 'Claim update failed'
    },
    notAbletoAddError: {
        id: 'wni.claim.controllers.CreateNoteCtrl.Sorry, we were not able to add a new note to your claim.',
        defaultMessage: 'Sorry, we were not able to add a new note to your claim.'
    },
    emptyNotes: {
        id: 'wni.claim.views.claim-detail.msg.No notes associated with this claim',
        defaultMessage: 'There are no notes associated with this claim.'
    }
});
