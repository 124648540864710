import _ from 'lodash';

function onTypeCell(item, translator) {
    const animalType = _.get(item, 'animalType');
    if (animalType) {
        return translator({ id: `typekey.AnimalType.${animalType}` }) || '';
    }
    return '';
}

function onBreedTypeCell(item, translator) {
    const animalBreed = _.get(item, 'animalBreed');
    if (animalBreed) {
        return translator({ id: `typekey.AnimalBreed.${animalBreed}` }) || '';
    }
    return 'NA';
}

function onBiteHistoryCell(item) {
    const biteHistory = _.get(item, 'animalBiteHistory');
    if (biteHistory === true) {
        return 'Yes';
    }
    if (biteHistory === false) {
        return 'No';
    }
    return 'NA';
}

function onDescribeCell(item) {
    const describe = _.get(item, 'animalDescribe');
    if (_.isNil(describe)) {
        return 'NA';
    }
    return describe;
}

function onExcludedCell(item) {
    const excluded = _.get(item, 'excluded');
    return excluded ? 'Excluded' : '';
}

function getAvailableBreed(availableValues = [], animalType, translator) {
    const options = availableValues.map((option) => {
        return {
            code: option.code,
            name: translator({
                id: option.name
            })
        };
    });
    let res = [];
    switch (animalType) {
        case 'Poultry_Ext':
        case 'Cat_Ext':
        case 'Livestock_Ext':
            res = options.filter((option) => {
                return option.code === 'none_Ext';
            });
            break;
        case 'Other':
            res = options.filter((option) => {
                return option.code === 'Other' || option.code === 'none_Ext';
            });
            break;
        default:
            res = options.filter((option) => {
                return option.code !== 'none_Ext';
            });
            break;
    }
    return res;
}

export default {
    onTypeCell,
    onBreedTypeCell,
    onBiteHistoryCell,
    onDescribeCell,
    onExcludedCell,
    getAvailableBreed
}
