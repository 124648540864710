import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { QuestionSetComponent } from 'gw-components-platform-react';

import WizardPage from '../../templates/WCWizardPage';

// import PAQuoteUIHelper from './util/PAQuoteUIHelper';
import metadata from './WCQualificationPage.metadata.json5';
import messages from './WCQualificationPage.messages';

function WCQualificationReadOnlyPage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const displayQuestions = [
        'OwnOperateLeaseCraft',
        'BargesVesselsDocksBridges'
    ];

    const filterQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                isReadOnly: true
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM]);

    // ===================================================
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />

        </WizardPage>
    );
}

WCQualificationReadOnlyPage.propTypes = WizardPage.propTypes;
export default WCQualificationReadOnlyPage;