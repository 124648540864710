import React, { useState, useContext, useMemo }from 'react'
import _ from 'lodash'
import {
    Button,
} from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { createNewPersonWithoutPublicID,  createNewCompanyWithoutPublicID } from 'gwce-capability-fnol-cp-react-ext';
import { Address } from 'gw-capability-fnol-common-react';
import metadata from './SinglePropertyIncident.metadata.json5'
import messages from './SinglePropertyIncident.message'
import styles from '../../../../FNOLPAWizard.module.scss';

const AddNewPerson = 'AddNewPerson';
const AddNewCompany = 'AddNewCompany';

const SinglePropertyIncident = (props) => {
    const {
        originPropertyIncident,
        onPopupCancel,
        onPopupSave,
        propertyOwnerContacts
    } = props

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SinglePropertyIncident')

    const [showErrors, setShowErrors] = useState(false);
    const [propertyIncidentVM, setPropertyIncidentVM] = useState(() => viewModelService.create(
            originPropertyIncident,
            'cc',
            'edge.capabilities.claim.lob.shared.incidents.dto.FixedPropertyIncidentDTO'
        ))

    const ownerPublicID = _.get(propertyIncidentVM.value, 'ownerContact_Ext.publicID');
    const ownerContact = _.get(propertyIncidentVM.value, 'ownerContact_Ext');

    const writeValue = (value, path) => {
        const newPropertyIncidentVM = _.clone(propertyIncidentVM);
        _.set(newPropertyIncidentVM.value, path, value);
        setPropertyIncidentVM(newPropertyIncidentVM);
    }

    const ownerSelectorAvailableValues = propertyOwnerContacts.map((contact) => {
        return {
            code: _.get(contact, 'publicID'),
            name: _.get(contact, 'displayName', '-')
        }
    }).concat(
        [
            {
                code: AddNewPerson, 
                name: translator(messages.addNewPerson)
            },
            {
                code: AddNewCompany, 
                name: translator(messages.addNewCompany)
            }
        ]
    )

    const onOwnerSelectorChange = (value) => {
        let currentOwner = null;
        if(value === AddNewPerson) {
            currentOwner = {
                publicID: AddNewPerson,
                ...createNewPersonWithoutPublicID()
            };
        } else if (value === AddNewCompany) {
            currentOwner = {
                publicID: AddNewCompany,
                ...createNewCompanyWithoutPublicID()
            };
        } else {
            currentOwner = propertyOwnerContacts.find(item => item.publicID === value);
        }
        writeValue(currentOwner, 'ownerContact_Ext');
    }

    const onPropertyOwnerChange = (value, path) => {
        const newPropertyIncidentVM = viewModelService.clone(propertyIncidentVM);
        _.set(newPropertyIncidentVM.value, path, value);
        const newPropertyOwner = _.get(newPropertyIncidentVM.value, 'ownerContact_Ext');
        const updatedPropertyOwner = {
            ...newPropertyOwner,
            updated_Ext: true
        };
        _.set(newPropertyIncidentVM.value, 'ownerContact_Ext', updatedPropertyOwner);
        setPropertyIncidentVM(newPropertyIncidentVM);
    }

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(propertyIncidentVM.value)
    }

    const stateAvailableValues = useMemo(() => {
        const newLocationVM = viewModelService.create(
            new Address(),
            'cc',
            'edge.capabilities.address.dto.AddressDTO'
        );
        const availableValues = _.get(newLocationVM, 'state.aspects.availableValues', []);
        const options = _.map(availableValues, (option) => {
            return {
                code: option.code,
                name: translator({
                    id: option.name,
                    defaultMessage: option.name
                })
            };
        });
        return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        addressDetailsContainer: {
            visible: !_.isEmpty(ownerPublicID)
        },
        ownerName: {
            availableValues: ownerSelectorAvailableValues,
            onValueChange: onOwnerSelectorChange
        },
        propertyOwnerContainer: {
            visible: !_.isEmpty(ownerPublicID)
        },
        personContainer: {
            visible: _.get(ownerContact, 'subtype') === 'Person' || _.get(ownerContact, 'subtype') === 'UserContact'
        },
        companyContainer: {
            visible: _.get(ownerContact, 'subtype') === 'Company'
        },
        lastName: {
            tooltip: translator(messages.unknowTips, {
                what: translator(messages.lastName)
            }),
            labelPosition: isPhone ? 'top' : 'left'
        },
        city: {
            tooltip: translator(messages.unknowTips, {
                what: translator(messages.city)
            }),
            labelPosition: isPhone ? 'top' : 'left'
        },
        state: {
            availableValues: stateAvailableValues
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            onPropertyOwnerChange
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={propertyIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div className={styles.buttonsContainer}>
            <Button
                className="wni-button-link"
                variant="secondary"
                onClick={() => {onPopupCancel()}}
                label={translator(messages.cancel)}
            />
            <Button
                variant="primary"
                onClick={onSaveClicked}
                label={translator(messages.save)}
            />
        </div>
    </>
}

export default SinglePropertyIncident
