import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { UrlUtil } from 'wni-portals-util-react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import {
    minYear,
    OTHER,
    requiredForIssuanceData,
} from '../config/Vehicle.static';
import messages from '../RTVehiclesPage.messages';

const getVehicleOrTrailerLabel = (id, itemToBeInsured) => {
    if (itemToBeInsured === 'trailer') {
        return messages[`${id}ForTrailer`];
    }
    return messages[id];
};
const getAvailableList = (options) => {
    return options.map((item) => {
        return {
            code: item,
            name: item
        };
    });
};
const getAvailableYears = () => {
    const currentYear = new Date().getFullYear();
    const yearRange = _.range(currentYear + 1, minYear - 1);
    return getAvailableList(yearRange);
};
const getAvailableFromPath = (vm, path) => {
    const options = _.get(vm.value, path) || [];
    return getAvailableList(options);
};
const getAvailableFromVM = (vm, path, translator) => {
    const options = _.get(vm, `${path}.aspects.availableValues`) || [];
    return options.map((item) => {
        return {
            code: item.code,
            name: translator({ id: item.name })
        };
    });
};

const tooltipForcoverageAmount = (translator) => {
    return (
        <ul className='tooltipList'>
            <li>{translator(messages.coverageAmountTitle1)}</li>
            <li>{translator(messages.coverageAmountMsg1)}</li>
            <li>{translator(messages.coverageAmountTitle2)}</li>
            <li>{translator(messages.coverageAmountMsg2)}</li>
        </ul>
    )
};

const tooltipForVehicalType = (translator, itemToBeInsured) => {
    if (itemToBeInsured === 'trailer') {
        return (
            <ul className='tooltipList'>
                <li>{translator(messages.trailerTypeMsg1)}</li>
                <li>{translator(messages.trailerTypeMsg2)}</li>
            </ul>
        );
    }
    return null;
}

const tooltipForItemInsured = (translator, domainCode) => {
    return (
            <>
                {translator(messages.itemInsuredMsg, {
                    here: <LinkComponent onClick={() => window.open(UrlUtil.rtVehicleTypesAdvisor[domainCode])}>here</LinkComponent>
                })}
            </>
            )
}

const isFieldVisible = (currentRow, fieldPath, isReset = true) => {
    let isVisible = true;
    const vm = currentRow || {};
    const { 
        baseState,
        itemToBeInsured
    } = vm.value;
    let isFieldReset = true;
    switch (fieldPath) {
        case 'vin':
            isVisible = itemToBeInsured === 'motorcycle';
            break;
        case 'otherManufacturer':
            isVisible = _.get(vm.value, 'manufacturer') === OTHER;
            break;
        case 'model':
            isVisible = _.get(vm.value, 'manufacturer') !== OTHER;
            isFieldReset = false;
            break;
        case 'otherModel':
            isVisible =
                _.get(vm.value, 'model') === OTHER ||
                _.get(vm.value, 'manufacturer') === OTHER;
            break;
        case 'identificationNumber':
            isVisible = itemToBeInsured !== 'motorcycle'
            break;
        case 'registrationStateExplain':
            isVisible = !!_.get(vm.value, 'registrationState') && (_.get(vm.value, 'registrationState') !== baseState);
            break;
        case 'engineCC':
            isVisible = itemToBeInsured !== 'trailer'
            break;
        case 'lossSettlementValMethod': 
            isVisible = _.get(vm.value, 'isPhysicalDamageRequired')
            break;
        case 'currentMarketValue': 
            isVisible = _.get(vm.value, 'isPhysicalDamageRequired')
            break;
        case 'coverageAmount':
            const lossMethodValue = _.get(vm.value, 'lossSettlementValMethod');
            isVisible = ['replacement_cost', 'agreed_value'].includes(lossMethodValue)
            break;
        case 'statedValue':
            isVisible = _.get(vm.value, 'lossSettlementValMethod') === 'stated_value' && _.get(vm.value, 'statedValue')
            break;

        // information
        case 'existingDamageExplain':
            isVisible = _.get(vm.value, 'isThereExistingDamage')
            break;
        case 'hasCustomizedEquip':
            isVisible = _.get(vm.value, 'isPhysicalDamageRequired')
            break;
        case 'hasAntiTheftProtectDevice':
            isVisible = baseState === 'MN' && ['motorcycle', 'recreational_vehicle'].includes(itemToBeInsured)
            break;
        case 'isLicensedForRoadUse':
        case 'whereVehicleStored':
        case 'vehicleUsedMonths':
        case 'selectVehicleApplies':
        case 'hasVehicleBeenModified':
        case 'hasCoOwner':
            isVisible = itemToBeInsured !== 'trailer';
            break;
        case 'isUsedForBusiness':
        case 'hasLivingQuarters':
            isVisible = itemToBeInsured === 'trailer';
            break;
        case 'isUsedForBusinessExplain':
            isVisible = _.get(vm.value, 'isUsedForBusiness');
            break;
        case 'hasVehBeenModifiedExplain':
            isVisible = _.get(vm.value, 'hasVehicleBeenModified');
            break;
        case 'coOwnerName':
            isVisible = _.get(vm.value, 'hasCoOwner');
            break;
        case 'maxNumberOfOccupants':
            isVisible = ['utility_task_vehicle_side-by-side', 'golf_cart', 'snowmobile_snowmachine'].includes(_.get(vm.value, 'vehicleType'))
            break;
        case 'haveLessThanFourWheels':
            isVisible = itemToBeInsured === 'recreational_vehicle' && ['all_terrain_vehicle', 'off-Road_vehicle', 'utility_task_vehicle_side-by-side'].includes(_.get(vm.value, 'vehicleType'))
            break;
        case 'hasPhysDamageLast12Months':
            const purchaseDate = _.get(vm.value, 'purchaseDate');
            const editEffectiveData = _.get(vm.value, 'editEffectiveDate');
            const purchaseDateStr = purchaseDate ? moment(purchaseDate).format('MM/DD/YYYY') : null;
            const editEffectiveDataStr = editEffectiveData ? moment(editEffectiveData).format('MM/DD/YYYY') : null;
            isVisible = _.get(vm.value, 'isPhysicalDamageRequired') && purchaseDate && purchaseDateStr !== editEffectiveDataStr;
            break;
        // case 'hasHazardWarnLightingSys':
        //     isVisible = itemToBeInsured === 'snowmobile_snowmachine';
        //     break;
        case 'customEquipment':
            isVisible = _.get(vm.value, 'hasCustomizedEquip');
            break;
        default:
            isVisible = true;
    }
    if (!isVisible && isReset && isFieldReset) {
        _.set(currentRow.value, fieldPath, undefined);
    }
    return !!isVisible;
};

const generateIssuanceValid = (vm) => {
    const itemToBeInsured = _.get(vm, 'value.itemToBeInsured');
    const requiredForIssuanceDataList = itemToBeInsured === 'trailer' ? requiredForIssuanceData.filter((data) => data !== 'otherModel')
            : requiredForIssuanceData;
    const issuanceMaps = [];
    requiredForIssuanceDataList.forEach((fieldPath) => {
        const isVisible = isFieldVisible(vm, fieldPath, false);
        issuanceMaps.push({
            path: fieldPath,
            visible: isVisible,
        });
    });
    const newIssuanceMaps = issuanceMaps.filter((item) =>
        _.get(item, 'visible')
    );

    const invalidIssuanceFields = [];
    newIssuanceMaps.forEach((item) => {
        const { path } = item;
        const fieldValue = _.get(vm, `value.${path}`);
        const isCurrencyField =
            _.isObject(fieldValue) && _.get(fieldValue, 'currency');
        if (isCurrencyField && (_.isNil(_.get(fieldValue, 'amount')) || _.get(fieldValue, 'amount') === '')) {
            invalidIssuanceFields.push(path);
        }
        if (_.isNil(fieldValue) || fieldValue.length === 0) {
            invalidIssuanceFields.push(path);
        }
    });
    return invalidIssuanceFields;
};

const getValuationMethodOptions = (vehicleVM, translator, disabled) => {
    if(!vehicleVM.value) {
        return []
    };
    const {
        year,
        editEffectiveDate
    } = vehicleVM.value;
    const options = getAvailableFromVM(vehicleVM, 'lossSettlementValMethod', translator);
    if (disabled) {
        return options;
    }
    const effectiveYear = moment(editEffectiveDate).get('Y');
    const portalOptions = options.filter((item) => item.code !== 'stated_value')

    if( effectiveYear - year <= 2 ) {
        return portalOptions;
    }
    const filterOptions = portalOptions.filter((item) => item.code !== 'replacement_cost');
    return filterOptions;
};

const syncCoverageAmountValueAndMsg = (vehicleDTO) => {
    const coverageAmount = _.get(vehicleDTO, 'coverageAmount', {});
    const currentMarketValue = _.get(vehicleDTO, 'currentMarketValue', {});
    const lossSettlementValMethod = _.get(vehicleDTO, 'lossSettlementValMethod');
    const isCompare = lossSettlementValMethod === 'replacement_cost' && coverageAmount.amount && currentMarketValue.amount;
    if(isCompare && coverageAmount.amount < currentMarketValue.amount) {
        _.set(vehicleDTO, 'coverageAmount', currentMarketValue);
        _.set(vehicleDTO, 'isCoverageAmountWarning', true);
    }else{
        _.set(vehicleDTO, 'isCoverageAmountWarning', false);
    }
};

export {
    getVehicleOrTrailerLabel,
    getAvailableYears,
    getAvailableList,
    getAvailableFromPath,
    getAvailableFromVM,
    isFieldVisible,
    generateIssuanceValid,
    getValuationMethodOptions,
    syncCoverageAmountValueAndMsg,
    tooltipForcoverageAmount,
    tooltipForVehicalType,
    tooltipForItemInsured
};
