import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Button } from '@jutro/components';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CreateNotePopUp.metadata.json5';

function CreateNotePopUp(props) {
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        viewModelService
    } = props;
    const { refreshData } = useDataRefresh();
    const [noteVM, updateNoteVM] = useState();
    const { isComponentValid, onValidate } = useValidation('CreateNotePopUp');
    useEffect(() => {
        const initialNoteVM = viewModelService.create(
            {
                subject: '',
                body: ''
            },
            'cc', 'edge.capabilities.note.dto.NoteDTO'
        );
        updateNoteVM(initialNoteVM);
        // This will call only once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const writeValue = useCallback(
        (value, path) => {
            _.set(noteVM, path, value);
            updateNoteVM(noteVM);
            refreshData();
        },
        [refreshData, noteVM]
    );

    const handleSave = useCallback(() => {
        onResolve(noteVM);
    }, [onResolve, noteVM]);

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={noteVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} variant="secondary" label={cancelBtnLabel} />
                <Button onClick={handleSave} disabled={!isComponentValid}  variant="primary" label={actionBtnLabel} />
            </ModalFooter>
        </ModalNext>
    );
}

CreateNotePopUp.propTypes = {
    initialNoteObj: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.shape({}).isRequired,
    cancelBtnLabel: PropTypes.shape({}).isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default CreateNotePopUp;
