import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imriggerscoverage'), method, data, additionalHeaders);
}

export default class IMRiggersCoverageService {

    /**
     * Invokes IMRiggersCoverageHandler.getRiggersCoverageClausesWithSync()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getRiggersCoverageClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getRiggersCoverageClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMRiggersCoverageHandler.getRiggersCoverageClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getRiggersCoverageClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getRiggersCoverageClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMRiggersCoverageHandler.updateRiggersCoverageClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} riggersCoverageToUpdate
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateRiggersCoverageClauses(jobNumber, sessionUUID, riggersCoverageToUpdate, authHeader = {}) {
        return processSubmission('updateRiggersCoverageClauses', [jobNumber, sessionUUID, riggersCoverageToUpdate], authHeader);
    }

    /**
     * Invokes IMRiggersCoverageHandler.onRiggersCoverageNext()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static onRiggersCoverageNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onRiggersCoverageNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMRiggersCoverageHandler.searchClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMRiggersCoverageHandler.addSearchedClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} clausesToAddData
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}
