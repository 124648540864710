import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { CPCoverageService } from 'wni-capability-quoteandbind-cp';
import messages from './CPDetailsAccordionCard.messages'
import CPDetails from '../components/CPDetailsComponent/CPDetails'
import CPCoverageUtil from '../util/CPCoverageUtil';

import { AccordionCard } from '@jutro/legacy/components';

const CPDetailsAccordionCard = (props) => {

    const {
        lineClauses,
        setLineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        loadingClauses,
        setLoadingClauses,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();

    const {details} = lineClauses

    const validationID = 'CPDetailsAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
        }
    }, [isComponentValid, onPageValidate])

    const changeDetails = useCallback((displayable, index) => {
        const newLineClauses = _.clone(lineClauses)
        _.set(newLineClauses, `details[${index}]`, displayable);
        setLineClauses(newLineClauses);
        setIsEditing(false);
        return newLineClauses;
    }, [lineClauses, setIsEditing, setLineClauses])

    const updateVMToServer = useCallback(async (newLineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const lineClausesToUpdate = CPCoverageUtil.generateUpdatedlineClausesDTO(newLineClauses);
        const updatedLineClausesPublicIDs = CPCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLineClausesDTO(lineClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedLineClausesPublicIDs)
        const lineClausesFromServer = await CPCoverageService.updateLineClauses(
            jobID,
            sessionUUID,
            lineClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setLineClauses(lineClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setLineClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewLineClausesToServer = useCallback(async (newLineClauses) => {
        updateVMToServer(newLineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);

    const changeDetailsAndSync = useCallback((displayable, index) => {
        const newSublineClauses = changeDetails(displayable, index)
        updateNewLineClausesToServer(newSublineClauses);
    }, [changeDetails, updateNewLineClausesToServer])

    return (
        <AccordionCard
            id="commercialPropertyDetails"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={translator(messages.detailsTitle)}
            errorState={showErrors && !isComponentValid}
        >
            <CPDetails
                details={details}
                loadingClauses={loadingClauses}
                changeDetailsAndSync={changeDetailsAndSync}
                onValidate={onValidate}
                showErrors={showErrors}
                isEditable={isEditable}
                isEditing={isEditing}
            />
        </AccordionCard>
    )
}

export default CPDetailsAccordionCard
