import React from 'react';
import WizardPage from '../../templates/CAWizardPage';
import CAVehiclesPage from './CAVehiclesPage';

function CAVehiclesReadOnlyPage(props) {
    return (
        <CAVehiclesPage {...props} isReadOnly checkRequiredForIssuance={false} />
    );
}

CAVehiclesReadOnlyPage.propTypes = WizardPage.propTypes;
export default CAVehiclesReadOnlyPage;
