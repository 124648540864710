import {
    useState,
    useCallback,
} from 'react';
// import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';

// import { resolveComponentFromName } from '@jutro/uiconfig';

/**
 * Filter only valid wizardPageData keys as defined in WizardConstants.WizardPageDataConstants
 * @param {object} wizardPageData
 * @returns {object}
 */
function filterValidPageDataKeys(wizardPageData = {}) {
    const retval = {};
    Object.keys(wizardPageData)
        .filter(WizardConstants.isWizardPageDataKey)
        .forEach((dataKey) => {
            retval[dataKey] = wizardPageData[dataKey];
        });
    return retval;
}

/**
 * This hook intends to store data that is not tracked by wizardData and
 * updateWizardData, i.e. data that's not in QuoteDataDTO. e.g. SideBySideDTO
 * on PAQuotePage.
 *
 * Note: Do not use string as cahe key. Instead, define the cache key in WizardConstants,
 * and reference the key value through WizardConstants.cacheKey or WPDK_cacheKey.
 *
 * USAGE EXAMPLE:
 * Retrieve Cached Value:
 * const cachedValue = wizardPageData[WizardConstants.cacheKey];
 * const cachedValue = wizardPageData.WPDK_cacheKey
 *
 * Update Cached Value:
 * updateWizardPageData({ [WizardConstants.cacheKey]: cachedValue });
 * updateWizardPageData({ WPDK_cacheKey: cachedValue});
 *
 * Remove Cached Value:
 * updateWizardPageData({ [WizardConstants.cacheKey]: undefined });
 * updateWizardPageData({ WPDK_cacheKey: undefined }});
 *
 * @param {object} initialWizardPageData
 * @returns {object}
 */
function useWizardPageData(initialWizardPageData = {}) {
    const actualInitialData = filterValidPageDataKeys(initialWizardPageData);
    const [wizardPageData, updateWizardPageData] = useState(actualInitialData);
    // const wizardPageData = _.clone(initialWizardPageData);

    const assimilateWizardPageData = useCallback((rawNewData) => {
        const newData = filterValidPageDataKeys(rawNewData);
        const newPageData = {
            ...wizardPageData,
            ...newData
        };
        // Object.keys(newData)
        //     .filter(WizardConstants.isWizardPageDataKey)
        //     .forEach((newDataKey) => { newPageData[newDataKey] = newData[newDataKey]; });
        updateWizardPageData(newPageData);
    }, [wizardPageData]);

    // const setWizardPageData = useCallback((newWizardPageData) => {
    //     updateWizardPageData();
    // }, []);


    // const getWizardPageData = useCallback((key) => {
    //     if (_.isNil(key)) {
    //         return undefined;
    //     }
    //     return _.get(wizardPageData, key);
    // }, [wizardPageData]);

    return {
        wizardPageData,
        // getWizardPageData,
        updateWizardPageData: assimilateWizardPageData,
    };
}

export default useWizardPageData;
