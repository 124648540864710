import { defineMessages } from 'react-intl';

export default defineMessages({
    copyCoveragesButtonLabel: {
        id: 'paVehicleCoveragesSection.button.label.copy coverages',
        defaultMessage: 'Copy Coverages'
    },
    PAVehicleCoveragesTip: {
        id: 'pa.vehicle.coverages.section.Summary coverages are shown below. For the full view of available vehicle-level coverages, click “Edit”.',
        defaultMessage: 'Summary coverages are shown below. For the full view of available vehicle-level coverages, click “Edit”.'
    },
    other: {
        id: 'pa.vehicle.coverages.section.Other',
        defaultMessage: 'Other'
    },
    otherTooltip: {
        id: 'pa.vehicle.coverages.section.Indicates other optional coverages besides, Collision, Other than Collision, Full Safety Glass or Roadside Assistance have been selected for this vehicle.  To view all coverages and/or modify them, click the "Edit" button.',
        defaultMessage: 'Indicates other optional coverages besides, Collision, Other than Collision, Full Safety Glass or Roadside Assistance have been selected for this vehicle.  To view all coverages and/or modify them, click the "Edit" button.'
    },
    vehicleCoverageInvalidField: {
        id: 'pa.vehicle.coverages.section.Limits/deductibles are needed for the selected coverages',
        defaultMessage: '{ vehModelYear } { vehManufact } { vehModel }:Limits/deductibles are needed for the selected coverages.'
    }
});
