import React, {
    useCallback,
    useState,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WCCoveragesService } from 'wni-capability-quoteandbind-wc';
import WizardPage from '../../templates/WCWizardPage';

import WCLineDetails from './CoveragesSection/WCLineDetails/WCLineDetails';
import WCLineCoverages from './CoveragesSection/WCLineCoverages/WCLineCoverages';
import WCLineAdditionalCoverages from './CoveragesSection/WCLineAdditionalCoverages/WCLineAdditionalCoverages'
import WCLineExclusions from './CoveragesSection/WCLineExclusions/WCLineExclusions'
import WCLineConditions from './CoveragesSection/WCLineConditions/WCLineConditions'

import styles from './WCCoveragesPage.module.scss';
import metadata from './WCCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/WCCoveragesConfigContext';
import WCCoveragesConfig from './WCCoverages.config';

import WCCoveargesPageMain from './WCCoveragesPage.main';

function WCCoveragesReadonlyPage(props) {

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            // onNext={onPageNext}
            //
            showRequiredInfoForFasterQuote
        >
            <WCCoveargesPageMain {...props}
                //
                coveragesPageProps = {{
                    onValidate: _.noop,
                    updateErrorsAndWarningsForCovComponent: _.noop,
                    //
                    showErrors: _.noop,
                    //
                    setIsEditing: _.noop,
                    isEditing: false,
                    //
                    setLoadingLineCoverages: _.noop,
                    loadingLineCoverages: false,
                    //
                    isEditable: false,
                }}
            />
        </WizardPage>
    );
}

WCCoveragesReadonlyPage.propTypes = WizardPage.propTypes;
export default WCCoveragesReadonlyPage;
