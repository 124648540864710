import { defineMessages } from 'react-intl';

export default defineMessages({
    cancelQuote: {
        id: 'gateway.views.new-quote.Want to Cancel',
        defaultMessage: 'Want to Cancel?'
    },
    cancelMessage: {
        id: 'gateway.views.new-quote.The information previously entered will be stored as a draft',
        defaultMessage: 'The information previously entered will be stored as a draft'
    },
    myProfile: {
        id: 'custom.auth.components.AuthStatus.My Profile',
        defaultMessage: 'My Profile',
    },
    settings: {
        id: 'custom.auth.components.AuthStatus.Settings',
        defaultMessage: 'Settings',
    },
    helpAndContact: {
        id: 'custom.portal.index.Help / Contact Us',
        defaultMessage: 'Help / Contact Us',
    },
    logout: {
        id: 'custom.auth.components.AuthStatus.Logout',
        defaultMessage: 'Logout',
    },
    searchPlaceholder: {
        id: 'custom.auth.components.Search by name, address, or number',
        defaultMessage: 'Search by name, address, or number'
    },
    searchPlaceholderForCL: {
        id: 'custom.auth.components.Search by Name, Address, DBA, Account or Policy Number',
        defaultMessage: 'Search by Name, Address, DBA, Account or Policy Number'
    },
});
