import React, {
    useCallback, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './RecommendedAddress.metadata.json5';

import { Button } from '@jutro/legacy/components';

function RecommendedAddressModal(props) {
    const {
        // title,
        size,
        suggestedAddress,
        originalAddress,
        isOpen,
        onResolve,
        onReject
    } = props;

    const [selectedValue, updateSelectedValue] = useState(undefined);
    const [originalValue, updateoriginalValue] = useState(undefined);

    const handleSave = useCallback(
        () => {
            const selectData = selectedValue || originalAddress;
            onResolve(selectData);
        }, [onResolve, originalAddress, selectedValue]
    );

    const generateAvailableValues = (addressList) => {
        const availableValues = addressList ? addressList.map((item) => {
            return {
                code: `${item.addressLine1 ? item.addressLine1 : item.pobox_Ext}, ${item.city}, ${item.county}, ${item.state}, ${item.postalCode}`,
                name: `${item.addressLine1 ? item.addressLine1 : item.pobox_Ext}, ${item.city}, ${item.county}, ${item.state}, ${item.postalCode}`,
                ...item
            };
        }) : [];
        return availableValues;
    };

    useEffect(() => {
        const defaultValue = generateAvailableValues([originalAddress])[0];
        updateoriginalValue(defaultValue);
    }, []);
    const onValueChange = (value, path) => {
        if (path === 'suggested') {
            updateSelectedValue(value);
            updateoriginalValue(undefined);
            return false;
        }
        updateSelectedValue(undefined);
        updateoriginalValue(value);
        return true;
    };
    const overrideProps = {
        originalAddress: {
            availableValues: generateAvailableValues([originalAddress]),
            value: originalValue,
            onValueChange: onValueChange
        },
        suggestedAddress: {
            availableValues: generateAvailableValues(suggestedAddress),
            value: selectedValue,
            onValueChange: onValueChange
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            suggestedAddress,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            {/* <ModalHeader title={title} /> */}
            <ModalBody id="recommendedAddress">
                <ViewModelForm
                    model={suggestedAddress}
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">Cancel</Button>
                <Button onClick={handleSave} type="filled">Ok</Button>
            </ModalFooter>
        </ModalNext>
    );
}

RecommendedAddressModal.propTypes = {
    // title: PropTypes.string,
    suggestedAddress: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    originalAddress: PropTypes.PropTypes.shape({}).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired
};
RecommendedAddressModal.defaultProps = {
    // title: 'Recommend Address',
    size: 'md'
};

export default RecommendedAddressModal;
