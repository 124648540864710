
import React from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { GLPolicyDetailsReadOnlyPage } from 'wni-capability-quoteandbind-gl-react';
// import messages from './CPPolicyDetailsReadOnlyPage.messages';

function CPPolicyDetailsReadOnlyPage(props) {

    // const {
    //     wizardData: submissionVM,
    // } = props;

    const CPOverrideProps = {
    }

    return (
        <GLPolicyDetailsReadOnlyPage
            {...props}
            // customMessages = {messages}
            CPOverrideProps = {CPOverrideProps}
            lobName = 'commercialPackage'
        />
    );

}

CPPolicyDetailsReadOnlyPage.propTypes = {
    ...wizardProps,
};

CPPolicyDetailsReadOnlyPage.defaultProps = {
};

export default CPPolicyDetailsReadOnlyPage;
