import React, {
    useEffect
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WALWatercraftService } from 'wni-capability-quoteandbind-wal';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import AdditionalInterest from '../WALAdditionInterest/WALAdditionalInterest';
import WatercraftDetails from '../components/WatercraftDetails/WatercraftDetails';
import MotorDetails from '../components/MotorDetails/MotorDetails';
import TrailerDetails from '../components/TrailerDetails/TrailerDetails';
import WatercraftInfoSection from '../components/WatercraftInformation/WatercraftInformation';
import metadata from './WatercraftComponent.metadata.json5';
import messages from '../WALWatercraftsPage.messages';
import { tooltipForItemInsured } from '../util/Watercraft.util'

const WARNING_VALIDATION_CONFIG = [
    {
        itemToBeIsured: ['watercraft_motor_trailer', 'watercraft_motor', 'watercraft'],
        message: messages.runPrefill
    },
    {
        itemToBeIsured: ['trailer'],
        message: messages.onlyTrailers
    }
];

function WatercraftComponent(props) {
    const {
        currentRow,
        onValueChange,
        syncWizardDataSnapshot,
        disregardFieldValidation,
        onValidate,
        showErrors,
        isReadOnly,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;

    const {
        watercraftDetail,
        motorDetail,
        trailerDetail,
        walCategoryFlowType,
        itemToBeInsured_Ext: itemToBeInsured
    } = currentRow.value;
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const waterCraftPriceDigestsDetails = _.get(watercraftDetail, 'priceDigestsDetails');

    const handleItemInsured = async(value, path) => {
        onValueChange(value, path);
        setLoadingMask(true);
        const requestData = {
            publicID: _.get(currentRow.value, 'publicID'),
            fieldName: path,
            walWatercraft: {
                ...currentRow.value,
                [path]: value,
                isSave: false
            }
        }
        const res = await WALWatercraftService.postOnChangeAction(requestData, jobID, sessionUUID, authHeader);
        _.set(currentRow, 'value', res.walWatercraft);
        syncWizardDataSnapshot(currentRow);
        setLoadingMask(false);
    };

    const isSectionVisible = (sectionType) => {
        switch (sectionType) {
            case 'watercraft':
                return watercraftDetail && !['motor', 'trailer'].includes(walCategoryFlowType)
            case 'motor':
                return motorDetail && ['watercraft_motor_trailer_outboard', 'watercraft_motor_outboard', 'motor'].includes(walCategoryFlowType)
            case 'trailer':
                return trailerDetail && ['watercraft_motor_trailer_outboard', 'watercraft_motor_trailer_inboard', 'trailer'].includes(walCategoryFlowType)
            default:
                break;
        }
    };

    const disRegardAndCleanCurrentRow = () => {
        const sectionMaps = ['watercraft', 'motor', 'trailer'];
        const hideSection = [];
        sectionMaps.forEach((item) => {
            const isVisible = isSectionVisible(item);
            if(!isVisible){
                hideSection.push(`${item}DetailSection`)
                hideSection.push(`${item}InfoSection`)
            }
        });

        if(!_.isEmpty(hideSection)) {
            // disregard the required field which in the section is false
            disregardFieldValidation(hideSection);
        }
    };

    useEffect(() => {
        disRegardAndCleanCurrentRow();
    }, [walCategoryFlowType, itemToBeInsured]);

    const getNotificationMsg = () => {
        let isValidationHidden = true;
        if (['watercraft', 'watercraft_motor', 'watercraft_motor_trailer'].includes(itemToBeInsured)) {
            isValidationHidden = !_.get(watercraftDetail, 'year');
        }
        const itemToBeIsuredData = WARNING_VALIDATION_CONFIG.find((item) => item.itemToBeIsured.includes(itemToBeInsured));
        return {
            visible: !!itemToBeIsuredData && isValidationHidden,
            content: itemToBeIsuredData ? itemToBeIsuredData.message : null
        }
    };

    const componentProps = _.omit(props, 'id');
    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            readOnly: isReadOnly
        },
        '@action': {
            ...componentProps
        },
        itemInsured: {
            disabled: _.get(currentRow.value, 'watercraftDetail.description') || !_.get(currentRow.value, 'isEditabled'),
            onValueChange: handleItemInsured,
            secondaryLabel: tooltipForItemInsured(translator),
        },
        notificationMsg:{
            ...getNotificationMsg()
        },
        watercraftDetailsCard: {
            visible: isSectionVisible('watercraft')
        },
        motorDetailsCard: {
            visible: isSectionVisible('motor')
        },
        trailerDetailsCard: {
            visible: isSectionVisible('trailer')
        },
        WatercraftInfoCard: {
            visible: watercraftDetail && !['motor', 'trailer'].includes(itemToBeInsured)
        },
        motorInfoCard: {
            visible: itemToBeInsured === 'motor'
        },
        trailerInfoCard: {
            visible: itemToBeInsured === 'trailer'
        },
        additionalInterestSection: {
            readOnly: isReadOnly,
            ...componentProps
        },
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            watercraftdetails: WatercraftDetails,
            motordetails: MotorDetails,
            trailerdetails: TrailerDetails,
            infosection: WatercraftInfoSection,
            questionset: QuestionSetComponent,
            additionalinterest: AdditionalInterest,
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrideProps
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default WatercraftComponent;
