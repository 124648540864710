import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/hohouseholdinfo'), method, data, additionalHeaders);
}

export default class HOHouseholdInfoService {

    static getExistingContacts(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getExistingContacts', [jobID, sessionUUID], authHeader);
    }

    static getUnassignedContacts(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getUnassignedContacts', [jobID, sessionUUID], authHeader);
    }

    static addNewPersonContact(quoteID, sessionUUID, contact, authHeader) {
        return processSubmission('addNewPersonContact', [quoteID, sessionUUID, contact], authHeader);
    }

    static removeContacts(jobID, sessionUUID, publicID, authHeader) {
        return processSubmission('removeContacts', [jobID, sessionUUID, publicID], authHeader);
    }
    
    static updateNewPersonContact(quoteID, sessionUUID, contact, authHeader) {
        return processSubmission('updateNewPersonContact', [quoteID, sessionUUID, contact], authHeader);
    }

    static saveOrUpdateResidenceLocation(quoteID, sessionUUID, isResidenceLocChanged, location, authHeader) {
        return processSubmission('saveOrUpdateResidenceLocation', [quoteID, sessionUUID, isResidenceLocChanged, location], authHeader);
    }

    static saveOrUpdatePreviousAddressLocation(quoteID, sessionUUID, location, authHeader) {
        return processSubmission('saveOrUpdatePreviousAddressLocation', [quoteID, sessionUUID, location], authHeader);
    }
    
    static saveHoHouseholdInfoData(quoteID, sessionUUID, hoHouseholdInfoData, questionSetData, quoteBaseData, authHeader) {
        return processSubmission('saveHoHouseholdInfoData', [quoteID, sessionUUID, hoHouseholdInfoData, questionSetData, quoteBaseData], authHeader);
    }

    static retrieveDwellinglocationList(quoteID, sessionUUID, authHeader) {
        return processSubmission('retrieveDwellinglocationList', [quoteID, sessionUUID], authHeader);
    }

    static updatePersonContactList(quoteID, sessionUUID, contactList, authHeader) {
        return processSubmission('updatePersonContactList', [quoteID, sessionUUID, contactList], authHeader);
    }
}