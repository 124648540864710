
import { defineMessages } from 'react-intl';

export default defineMessages({
    UmbrellaAdditionalCoverages: {
        "id": "quoteandbind.cu.wizard.step.Risk Details Page.Umbrella Additional Coverages",
        "defaultMessage": "Umbrella Additional Coverages"
    },
    AddAdditionalCoverages: {
        "id": "quoteandbind.cu.wizard.step.Risk Details Page.Add Additional Coverages",
        "defaultMessage": "Add Additional Coverages"
    },
});