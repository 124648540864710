const RiskComponentType = {
    Location: 'CP7 Location',
    Building: 'Building',
    SpecialClass: 'Special Class',
    SpecialClassBusinessIncome: 'Special Class Business Income',
    Occupancy: 'Occupancy',
    BusinessIncome: 'Business Income',
    PersonalProperty: 'Personal Property'
}

export default RiskComponentType