import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';

import LocationSummary from '../LocationSummary/LocationSummary';
import BuildingSummary from '../BuildingSummary/BuildingSummary';
import CoverageSummary from '../CoverageSummary/CoverageSummary';

import metadata from './OverallSummary.metadata.json5';
import styles from './OverallSummary.module.scss';

import React from 'react';

const OverallSummary = (props) => {
    const {
        onAddNewBuilding, onComplete, summaryInfo, onEditClicked
    } = props;

    const overrideProps = {
        locationSummary: {
            submission: summaryInfo.location,
            onEditClicked: () => onEditClicked(summaryInfo.building, 'location')
        },
        buildingSummary: {
            submission: summaryInfo.building,
            onEditClicked: () => onEditClicked(summaryInfo.building, 'building')
        },
        coverageSummary: {
            coverageDetails: summaryInfo.building,
            onEditClicked: () => onEditClicked(summaryInfo.building, 'coverages')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            locationsummary: LocationSummary,
            buildingsummary: BuildingSummary,
            coveragesummary: CoverageSummary
        },
        resolveCallbackMap: {
            onAddAnotherBuildingClicked: onAddNewBuilding,
            onDoneClicked: onComplete
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

OverallSummary.propTypes = {
    summaryInfo: PropTypes.shape({
        location: PropTypes.shape({}),
        building: PropTypes.shape({})
    }).isRequired,
    onAddNewBuilding: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onEditClicked: PropTypes.func.isRequired
};

export default OverallSummary;
