import React, {
    useContext, useState, useEffect, useCallback, useMemo
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './SuccessPage.module.scss';
import metadata from './SuccessPage.metadata.json5';
import './SuccessPage.messages';

const ACCORDION_CARDS = ['yourInfoAccordion', 'yourHouseAccordion', 'yourCoveragesAccordion'];

function SuccessPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isPrinting, setPrintingState] = useState(false);
    const { wizardData: submissionVM } = props;

    useEffect(() => {
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const accountNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.accountNumber.value');
    }, [submissionVM]);

    const policyNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.policyNumber.value');
    }, [submissionVM]);

    const periodStartDate = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodStartDate.value');
    }, [submissionVM]);

    const periodEndDate = useMemo(() => {
        return _.get(submissionVM, 'baseData.periodEndDate.value');
    }, [submissionVM]);

    const handlePrint = useCallback(() => {
        setPrintingState(true);
    }, []);

    const shouldShow = (path) => {
        const value = _.get(submissionVM.value, path);

        return !_.isEmpty(value);
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined
        },
        accountNumberLink: {
            to: `/accounts/${accountNumber}/summary`
        },
        policyNumberLink: {
            to: `/policies/${policyNumber}/summary`
        },
        policyPeriod: {
            value: {
                startDate: periodStartDate,
                endDate: periodEndDate,
            }
        },
        addressLine2: {
            visible: shouldShow('baseData.policyAddress.addressLine2')
        },
        addressLine3: {
            visible: shouldShow('baseData.policyAddress.addressLine3')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

SuccessPage.propTypes = wizardProps;
export default SuccessPage;
