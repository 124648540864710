import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardConstants, AOConfig } from 'wni-portals-config-js';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';
import { useWniModal } from 'wni-components-platform-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { 
    // WizardUtil,
    // WizardPageJumpUtil,
    QuoteUtil, 
    WindowUtil, 
    WniDateUtil,
    IssuanceValidationUtil,
   //  WniQuestionSetUtil,
    ValidationIssueUtil,
    WniPNIUtil,
    WniProductsUtil
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
import { DPHouseholdInfoService } from 'wni-capability-quoteandbind-dp';
import { useProductsData } from 'wni-portals-util-react';
import {HouseholdResidentsPopup, HouseholdLocationPopupComponent} from 'wni-capability-quoteandbind-ho-react';
import styles from './DPHouseholdInfoPage.module.scss';
import metadata from './DPHouseholdInfoPage.metadata.json5';
import messages from './DPHouseholdInfoPage.messages';
import dpQuestionSetsUtil from './utils/DPQuestionSetsUtil';
import { IconButton } from '@jutro/legacy/components';

const { aoStates } = AOConfig;

function DPHouseholdInfoPage(props) {
    const modalApi = useWniModal();


    const {
        authHeader,
        authUserData,
        authUserData: {
            businessData_Ext: {
                featureToggles = []
            } = {}
        } = {},
    } = useAuthentication();


    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        // currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardData: updateSubmissionVM,
        updateWizardPageData,
        wizardPageData,
        //
        saveDpHouseholdInfo,
        // householdInfoPageOnInit,
        isOpenPNIPopupPageInit,
        checkRequiredForIssuance,
        setAgentComponentReadOnly,
        setEffectiveDateForChangeTransaction = false,
        maximumEffectiveDate,
        setCoverageFormReadOnly,
        readOnlyFields: {
            // effectiveDate = false,
            // walTermTypedropdownselect = false,
            // walBaseState = false,
            agencyOfRecord = false,
            // uwqReadonly = false
        },
    } = props;
    const [showErrors, updateShowErrors] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const { getAvailablePolicyState, getAvailableEffectiveDate, getProductsMinDate } = useProductsData();

    const {
        // [WizardConstants.RCTCallback]: RCTCallback,
        // [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
        [WizardConstants.accountCompletedQuotes]: accountCompletedQuotes = []
    } = wizardPageData;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        policyInfoSource_Ext: policyInfoSource,
        lobData: {
            dwellingProperty: {
                householdInfoDTO_Ext: {
                    // isPropertyRentedToOthers,
                    coveragesForm,
                    underNewConstructionType,
                    isNamedInsuredContractor,
                    householdResidents,
                    namedInsuredOccupancy,
                    isHouseholdInfoFirstEnter,
                    dpleaseAgreement_Ext: dpleaseAgreement,
                    isThisAccount_Ext: isThisAccount
                }
            }
        }
    } = submissionVM.value;

    const {
        baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        productCode,
        quoteFlow_Ext: quoteFlow,
    } = baseData;

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('DPHouseholdInfoPage');
    const translator = useTranslator();

    const getMinDate = useCallback(() => {
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            return moment(getAvailableEffectiveDate(productCode, baseState)).add(-5, 'd').startOf('day').toDate();
        }
        return getAvailableEffectiveDate(productCode, baseState)
    }, [quoteFlow, policyInfoSource, productCode, baseState])

    const effectiveMinDate = useCallback(() => {
        if (setEffectiveDateForChangeTransaction) {
            return _.get(submissionVM, 'value.baseData.periodStartDate')
        }
        return getMinDate()
    }, [setEffectiveDateForChangeTransaction, getMinDate, submissionVM])

    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        if (setEffectiveDateForChangeTransaction) {
            const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
            return maxDate
        }
        return undefined

    }, [submissionVM, setEffectiveDateForChangeTransaction, maximumEffectiveDate]);

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate();
        const maxDate = effectiveMaxDate()
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = (maxDate === undefined ? 1 : moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf())
        return ((minDif >= 0) && (maxDif >= 0))
    }, [submissionVM.value, effectiveMinDate, effectiveMaxDate]);

    const getEffectiveDateFieldOverrideProps = () => {
        const prodDate = getProductsMinDate(productCode, baseState);
        const currentDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const isProdDateBeforeSysDate = moment(prodDate).valueOf() - moment(systemDate).valueOf() < 0
        let shouldShowNewValidation = false
        if (!isProdDateBeforeSysDate) {
            // current<prod show
            if(moment(currentDate).valueOf() - moment(prodDate).valueOf() < 0) {
                shouldShowNewValidation = true
            }
        }
        const effectiveDateFieldOverrideProps = {
            minDate: effectiveMinDate(),
            maxDate: effectiveMaxDate(),
            value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            showErrors: showErrors || shouldShowNewValidation,
        };
        if (shouldShowNewValidation) {
            _.set(effectiveDateFieldOverrideProps, 'validationMessages',
                [translator(messages.effectiveDateValidation, {
                    productEffectiveDate: WniDateUtil.formatDateWithPattern(prodDate, 'MM/DD/YYYY'),
                })]);
        }
        return  effectiveDateFieldOverrideProps;
    };

    const isResidenceLocationValid = useCallback(() => {
        const dwellingAddressLine1 = _.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation.address.addressLine1')
        const dwellingZipCode = _.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation.address.postalCode')
        const dwellingState = _.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation.address.state')
        const dwellingCountry = _.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation.address.country')
        const dwellingCity = _.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation.address.city')
        return (dwellingAddressLine1 && dwellingZipCode && dwellingState && dwellingCountry && dwellingCity)
    }, [submissionVM.value])


    const isHOHouseholdInfoValid = useCallback(() => {
        return isEffectiveDateValid() && isResidenceLocationValid();
    }, [isEffectiveDateValid, isResidenceLocationValid]);

    useEffect(() => {
        registerComponentValidation(isHOHouseholdInfoValid);
    }, [registerComponentValidation, isHOHouseholdInfoValid]);

    const emptyHOHouseholdContactDTO = viewModelService.create(
        {}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
    );
    const relationshipToInsuredListsAvailableValues = _.get(emptyHOHouseholdContactDTO, 'relationshipToInsured_Ext.aspects.availableValues', []);

    const isBaseStateInMutualState = useCallback(() => {
        return aoStates.includes(baseState)
    }, [baseState])
    
    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                authHeader
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader]
    );


    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                updateSubmissionVM(submissionVM);
            }).catch(() => _.noop());
    },[showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    useEffect(() => {
        if (isOpenPNIPopupPageInit) {
            openEditPrimaryNamedInsuredPopup();
        }
    }, [isOpenPNIPopupPageInit]);

    const getBaseStateList = useCallback(() => {
        const allBaseStateList = _.get(submissionVM, 'baseData.baseState_Ext._aspects.availableValues')
        const displaAllBaseStateList = allBaseStateList.map((state) => {
            return {
                code: state.code,
                name: translator({ id: state.name })
            }
        })
        const policyEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const availableState = getAvailablePolicyState(productCode, policyEffectiveDate)
        const filteredBaseStateList = _.filter(displaAllBaseStateList, (state) => availableState.includes(state.code))     
        return filteredBaseStateList
    }, [submissionVM, translator, productCode])

  
    const { domainCompany: { domainName}, loadingMask: { setLoadingMask } } = useDependencies(['domainCompany', 'loadingMask']);
    // const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    // const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);
    const [householdResidentsTableData, updateHouseholdResidentsTableData] = useState([]);
    // require for issuance util
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);
    // const [requiredForIssuanceFields, updateRequiredForIssuanceFields] = useState([]);
    const requiredForIssuranceAllFields = ['residenceQuestionDropdown', 'generalContractorToggle',
        'constructionToggle', 'estimatedCompletionDateDropdown', 'isThisResidenceOccupiedSeasonallyToggle'];

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.accountContacts_Ext[0].primaryAddress'));
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress=WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [submissionVM]);
    
    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const getResidenceLocaltionAndDisplay = useCallback(() => {
        const dwellingLocation = _.get(submissionVM, 'value.lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation');
        let displayAddress;
        let displaystate;
        if (dwellingLocation) {
            if(_.isNil(_.get(dwellingLocation, 'address.addressLine1'))){
                displayAddress = ''
                displaystate = ''
            }else{
                displayAddress = `${_.get(dwellingLocation, 'address.addressLine1')}, ${_.get(dwellingLocation, 'address.city')}`;
                displaystate = `${_.get(dwellingLocation, 'address.state')}, ${_.get(dwellingLocation, 'address.postalCode')}`;
            }
        } else {
            // TODO 
            // For account without existing policy, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            // For account with existing policy (PA), we will define requirement in future user story to copy data from PA. As of now, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            displayAddress = ''
            displaystate = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{displayAddress}</div>
                <div>{displaystate || '-'}</div>
            </div>
        );
    }, [submissionVM]);


    const getOccupancyValue = useCallback(() => {
        const occupancyValue = _.get(submissionVM, 'lobData.dwellingProperty.householdInfoDTO_Ext.namedInsuredOccupancy.aspects.availableValues')
        const occupancyOptionList= ['owneroccupiedprimary_Ext', 'owneroccupiedseasonal_Ext', 'tenantoccupiedprimary_Ext', 'tenantoccupiedseasonal_Ext', 'unoccupied_Ext', 'vacant_Ext']
        const displayOccupancy = occupancyValue.map((occupancy) => {
            return {
                code: occupancy.code,
                name:  translator({ id: occupancy.name }),
            }
        })
        const filteredOccupancyValue = _.filter(displayOccupancy, (item) => occupancyOptionList.includes(item.code))     
        return filteredOccupancyValue
    },[submissionVM, translator])

    const getCoverageFormValue = useCallback(() => {
        const coverageFormValue = _.get(submissionVM, 'lobData.dwellingProperty.householdInfoDTO_Ext.coveragesForm._aspects.availableValues')
        const displayCoverageForm = coverageFormValue.map((coverageForm) => {
            return {
                code: coverageForm.code,
                name: translator({ id: coverageForm.name })
            }
        })
        const requiredCoverageForm = ['dp1_Ext', 'dp2_Ext', 'dp3_Ext']
        const filteredCoverageForm = _.filter(displayCoverageForm, (item) => requiredCoverageForm.includes(item.code))     
        return filteredCoverageForm
    }, [submissionVM, translator])

    const getTypeOfDwellingDropdown = useCallback(() => {
        const allResidenceTypeValue = _.get(submissionVM, 'lobData.dwellingProperty.householdInfoDTO_Ext.residenceType._aspects.availableValues')
        const displayallResidenceType = allResidenceTypeValue.map((residenceType) => {
            return {
                code: residenceType.code,
                name: translator({ id: residenceType.name })
            }
        }).filter((item) => item.code !== 'threeormorefamily_Ext')
        // const TypeofDwellingValue = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'townhouse_Ext']
        if (coveragesForm !== 'dp2_Ext') {
            return displayallResidenceType.filter((item) => item.code !== 'hopcondo')
        }
        return displayallResidenceType

    }, [coveragesForm, submissionVM, translator])


    const getMultipleSelectOptions = () => {
        const options =  _.get(submissionVM.value, `lobData.dwellingProperty.householdInfoDTO_Ext.selectAllAppliesOptions_Ext`)
        return options.map((res) => {
            const displayName = res ==='PersonalAuto' ? 'Auto': WniProductsUtil.getProductName(res)
            if (displayName) {
                return {
                    code: res,
                    name: displayName
                }
            } 
            return {
                code: res,
                name: translator({ id: res })
            } 
        })
    }


    // const getResidenceQuestionDropdown = useCallback(() => {
    //     const residenceQuestionSelections = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.underNewConstructionType._aspects.availableValues')
    //     return residenceQuestionSelections.map((selection) => {
    //         return {
    //             code: selection.code,
    //             name: translator({ id: selection.name })
    //         }
    //     })
    // }, [submissionVM, translator])


    const coveragesFormLogic = useCallback(() => {
        switch (coveragesForm) {
            case 'ho3': return true
            case 'ho4': return false
            case 'ho5': return true
            case 'ho6': return false
            default: ''
        }
    },[coveragesForm])

    const baseStateLogic = useCallback(() => {
        switch (baseState) {
            case 'AK': 
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    },[baseState])

    const shouldShowResidenceQuestionDropdown = useCallback(() => {
        return coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic])

    const isNamedInsuredContractorEnteredNo = useCallback(() => {
        if (isNamedInsuredContractor === false) {
            return true;
        } 
        return false;
    }, [isNamedInsuredContractor])
    const shouldShowGeneralContractorToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' ||
                    underNewConstructionType === 'yeswithstructuralrenovation');
        return selection && coveragesFormLogic() && baseStateLogic()
    }, [baseStateLogic, coveragesFormLogic, underNewConstructionType])

    const shouldShowConstructionToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo());
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const shouldShowEstimatedCompletionDateDropdown = useCallback(() => {
        const selection = (underNewConstructionType ===
            'yeswithstructuralrenovation' ||
        (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo()));
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const createEmptyVM = useCallback(() => {
        const emptyVM = viewModelService.create({ 
            address: {
                country: 'US'
            }
        },
        'pc', 'edge.capabilities.policylocation.lob.workerscomp.dto.PolicyLocationDTO');
        return emptyVM;
    }, [viewModelService]);

    const openDwellingLocationPopup = useCallback(async() => {
        const dwellingLocation = _.get(
            submissionVM, 
            'lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation'
        );     
        const dwellingLocationList = await DPHouseholdInfoService.retrieveDwellinglocationList(quoteID, sessionUUID, authHeader);
        
        const refactDwellingLocationList = ((_.get(dwellingLocationList,'length', 0) > 0) ? dwellingLocationList : [])
            .map((locationDTO) => {
                return {
                    index: parseInt(locationDTO.dwellingLocation.displayName, 10),
                    publicID: locationDTO.publicID || locationDTO.accountLocPublicID,
                    address: locationDTO.dwellingLocation
                }
            })
        const orderedDwellingLocationList = _.orderBy(refactDwellingLocationList, ['index'], ['asc'])
        
        const title = messages.editResidenceLocation
        const componentProps = {
            iconClassType: false,
            title: title,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            showEmployeeFlagToggle: true,
            addressDataVM: dwellingLocation,
            addressOptions: orderedDwellingLocationList,
            viewModelService,
            emptyVM: createEmptyVM(),
            linePath: 'dwellingProperty',
        };
        modalApi.showModal(
            <HouseholdLocationPopupComponent {...componentProps}/>
        ).then(async (res) => {
            setLoadingMask(true);
            const isResidenceLocChanged = !_.isEqual(res, dwellingLocation.value)
            const rs = await DPHouseholdInfoService.saveOrUpdateResidenceLocation(quoteID, sessionUUID, isResidenceLocChanged, res, authHeader);
            updateWizardPageData({ [WizardConstants.calledRCTFlag]: false });
            const newSubmissionVM = _.clone(submissionVM);
            // update dwellingLocation filed
            const newDwellingLocation = _.get(rs, 'dwellingLocation');
            _.set(newSubmissionVM, 'value.lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation', newDwellingLocation);
            updateSubmissionVM(newSubmissionVM);
        }).catch(() => {
            _.noop();
        }).finally(() => {
            setLoadingMask(false);
        });
    }, [submissionVM, quoteID, sessionUUID, authHeader, viewModelService, createEmptyVM, modalApi, setLoadingMask, updateWizardPageData, updateSubmissionVM])


    const getEstimatedCompletionDataDropdown = useCallback(() => {
        const estimatedCompletionData = _.get(submissionVM, 'lobData.dwellingProperty.householdInfoDTO_Ext.projectCompletionDateType._aspects.availableValues')
        return estimatedCompletionData.map((selection) => {
            return {
                code: selection.code,
                name: translator({ id: selection.name })
            }
        })
    }, [submissionVM, translator])


    // const preQualQuestionSets = _.get(submissionVM.value, 'lobData.dwellingProperty.preQualQuestionSets', [])
    // const policyInfoQuestions = preQualQuestionSets
    //     .find((questionSet) => questionSet.code === 'DPPolicyInfoUWQuestions_Ext')

    const questionSetFilter = dpQuestionSetsUtil.getQSFilterForDpHousehold()
    const questionSetMapper = dpQuestionSetsUtil.getQSMapperForDpHousehold();

    const updateExistFinishedPolicyAnswers = () => {
        let isExistFinishedPA = null
        accountCompletedQuotes.forEach((job) => {
            if(job.productCode === 'PersonalAuto' && (job.jobStatus === 'Bound' || job.jobStatus === 'Scheduled')) {
                isExistFinishedPA = true
            }
        });
        const isThereExistingPolicyExt = 'lobData.dwellingProperty.preQualQuestionSets.children[0].answers.value.isThereExistingPolicy_Ext';
        if(isExistFinishedPA && !_.get(submissionVM, isThereExistingPolicyExt)) {
            _.set(submissionVM, isThereExistingPolicyExt, isExistFinishedPA);
        }
    } 

    const refreshHouseholdResidentsTable = useCallback((datas) => {
        const pni = _.cloneDeep(primaryNamedInsured);
        _.set(pni, 'relationshipToInsured_Ext', 'PRIMARYNAMEDINSURED');
        const initData = [];
        initData.push(pni);
        _.each(datas, (contact) => {
            if (_.get(contact, 'contactDenormPublicID') !== _.get(pni, 'publicID')) {
                initData.push(contact);
            }
        })
        updateHouseholdResidentsTableData(initData)
    }, [primaryNamedInsured]);

    useEffect(() => {
        // getAgentInfo();
        refreshHouseholdResidentsTable(householdResidents);
        updateExistFinishedPolicyAnswers();
    }, []);

    const handleAddHouseholdResident = async (event, editItem = null, editMode = false) => {      
        // fetch options
        const exsitingContacts = await DPHouseholdInfoService.getUnassignedContacts(quoteID, sessionUUID, authHeader);
        const exsitingContactsOptions = [];
        _.each(exsitingContacts, (contact) => {
            const opt = {
                code: JSON.stringify(contact),
                name: _.get(contact, 'displayName')
            };
            exsitingContactsOptions.push(opt);
        })
        exsitingContactsOptions.push({
            code: 'New',
            name: 'New'
        });
        // create Contact and using PNI address as default
        let initialValue = {
            westernNationalEmployee: false
        };
        if (editItem) {
            initialValue = {
                ...editItem
            }
        }
        const contactVM = viewModelService.create(
            initialValue, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
        );
        let contactVMList = householdResidentsTableData.map((res) => viewModelService.create(
            res, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
        ))

        if(!editItem) {
            const newVM = viewModelService.create(
                {publicID: ''}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
            )
            contactVMList = contactVMList.concat(newVM)
        }

        const title = editMode ? translator(messages.editAdditionalNamedInsured) : translator(messages.addAdditionalNamedInsured);
        const componentProps = {
            title: title,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            contactVM: contactVM,
            defaultList: contactVMList,
            selectedContactPublicID: editItem ? editItem.publicID : '',
            viewModelService: viewModelService,
            exsitingContactsOptions,
            editMode,
            baseState,
            nextButtonVisible: true,
            saveAndNextMessageLabel: 'Save & Next Person',
            productCode,
            isDPLine: true,
            displayFields: {
                additionalNamedInsured: false,
            },
        };
        modalApi.showModal(<HouseholdResidentsPopup {...componentProps} />)
            .then(async (res) => {
                const newSubmissionVM = _.clone(submissionVM);
                const listToUpdate = res.filter((elt) => _.get(elt, 'relationshipToInsured_Ext') !== 'PRIMARYNAMEDINSURED')
                const newListToUpdate = _.map(listToUpdate, (item) => {
                    if (item.relationshipToInsured_Ext === 'Spouse_Ext') {
                        _.set(item, 'maritalStatus', 'M');
                    }
                    return item;
                })
                setLoadingMask(true);
                const rs = await DPHouseholdInfoService.updatePersonContactList(quoteID, sessionUUID, newListToUpdate, authHeader);
                // update tableData
                refreshHouseholdResidentsTable(rs.contacts);
                const enableHODPAccountSync = _.find(featureToggles, ({ code }) => code === 'EnableHODPAccountSync');
                if (enableHODPAccountSync) {   
                    // get error and warning messages
                    const errorsAndWarnings = _.get(rs, 'errorsAndWarnings');
                    const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                    updateValidationIssues(newValidationIssues);
                    updateShowErrors(true);
                }
                _.set(newSubmissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.householdResidents', rs.contacts);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    };

    const renderRelationshipToInsured = useCallback((item, index, property) => {
        const relationshipToInsuredValue = _.get(item, property.path);
        let value = '';
        if (relationshipToInsuredValue === 'PRIMARYNAMEDINSURED') {
            return 'Primary Named Insured';
        }
        if (!_.isNil(relationshipToInsuredValue)) {
            const interestTypeKey = _.find(relationshipToInsuredListsAvailableValues,
                (ttx) => ttx.code === relationshipToInsuredValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [relationshipToInsuredListsAvailableValues, translator]);

    const renderAdditionalNamedInsured = useCallback((item, index, property) => {
        const additionalNamedInsuredValue = _.get(item, property.path);
        let value = 'No';
        // if (additionalNamedInsuredValue === 'PolicyAddlNamedInsured') {
        //     value = 'Yes';
        // }
        if (additionalNamedInsuredValue) {
            value = 'Yes';
        }
        return value;
    }, []);
    
    const editHouseholdRow = (event, item) => {
        return handleAddHouseholdResident(event, item, true);
    };

    const deleteHouseholdRow = useCallback(async (event, item) => {
        modalApi.showConfirm({
            title: messages.deleteAdditionalNamedInsuredTitle,
            message: messages.deleteAdditionalNamedInsuredMessage,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const publicID = _.get(item, 'publicID');
                try {
                    setLoadingMask(true);
                    const newSubmissionVM = _.clone(submissionVM);
                    const rs = await DPHouseholdInfoService.removeContacts(quoteID, sessionUUID, publicID, authHeader);
                    // update tableData
                    refreshHouseholdResidentsTable(rs.contacts);
                    const enableHODPAccountSync = _.find(featureToggles, ({ code }) => code === 'EnableHODPAccountSync');
                    if (enableHODPAccountSync) {
                        // get error and warning messages
                        const errorsAndWarnings = _.get(rs, 'errorsAndWarnings');
                        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                        updateValidationIssues(newValidationIssues);
                        updateShowErrors(true);
                    }
                    _.set(newSubmissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.householdResidents', rs.contacts);
                    updateSubmissionVM(newSubmissionVM);
                } finally {
                    setLoadingMask(false);
                }
                return true;
            }, _.noop
        );
    }, [authHeader, quoteID, refreshHouseholdResidentsTable, sessionUUID, setLoadingMask]);

    const renderHouseholdTableAction = (item, index) => {
        const isPNI = _.get(item, 'relationshipToInsured_Ext') === 'PRIMARYNAMEDINSURED';
        if (isPNI) {
            return '';
        }
        return (
            <>
                <IconButton
                    id={`edit${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => editHouseholdRow(e, item)}
                />
                <IconButton
                    id={`delete${index}`}
                    icon="gw-delete"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => deleteHouseholdRow(e, item)
                    }
                />
            </>
        );
    };

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );
    const getFrontendRequiredValiationErrors = useCallback(() => {
        if (!isRequiredForIssuance) {
            return [];
        } 
        const newRequiredForIssuranceFields = invalidFields.filter((item) =>{
            return requiredForIssuranceAllFields.includes(item);
        });
        return newRequiredForIssuranceFields.map((item) => {
            return {
                type: 'error',
                reason: `Below required fields for issuance are missing: "${translator(messages[item])}"`
            };
        });
    }, [submissionVM, invalidFields]);

    const getResidenceLocationValidationErrors = useCallback(() => {
        const isResidenceLocationDataValid = isResidenceLocationValid()
        if (!isResidenceLocationDataValid) {
            return [{
                type: 'error',
                reason: `Residence Location is required.`
            }]
        }

    },[isResidenceLocationValid])

    const handleValidation = useCallback(
        () => {
            const frontendRequiredValiationErrors = getFrontendRequiredValiationErrors();
            const residenceLocationValidationErrors = getResidenceLocationValidationErrors();
            updateValidationIssues(frontendRequiredValiationErrors);
            updateValidationIssues(residenceLocationValidationErrors)
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );


    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const dpHouseholdInfoData = _.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext')
        const questionSetData = _.get(submissionVM.value, 'lobData.dwellingProperty.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        // TODO: Call Service on next
        const res = await saveDpHouseholdInfo(
            quoteID, 
            sessionUUID,
            dpHouseholdInfoData,
            questionSetData,
            quoteBaseData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);
        
        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask,
        submissionVM, 
        quoteID, 
        sessionUUID, 
        authHeader, 
        viewModelService,
        updateSubmissionVM,
        displayWarnings]);


    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
            showRequired: true,
            isRequiredForIssuance
        },
        // dynamicInlineNotificationContainer: {
        //     visible: requiredForIssuanceFields.length > 0,
        //     scrollToIssues: true,
        //     issueRenderFn: DomRenderUtil.issueRenderFn,
        //     validationIssues: IssuanceValidationUtil.getIssuesMap(requiredForIssuanceFields),
        // },
        coverageFormDropdown: {
            readOnly: setCoverageFormReadOnly,
            availableValues: getCoverageFormValue(),
        },

        agencyAndProducerInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: false,
                licensedAgent: true,
                servicingAgent: true
            },
            readOnlyFields: {
                agencyOfRecordReadOnly: agencyOfRecord
            },
            producerCodePath: 'producerCode_Ext',
            // shouldSetExternalAgencyVal: true,
            // shouldUpdateAgentOptions: !isSkipping,
            onValidate
        },
        UWQIsThisAccountPartOfABookRoll: {
            layout: 'reversed'
        },
        primaryNamedInsured: {
            value: getPrimaryNamedInsuredAndDisplay(),
        },
        describeOccupancyDropdown: {
            availableValues: getOccupancyValue(),
        },
        insuredLivedLocationToggle: {
            visible: namedInsuredOccupancy === 'owneroccupiedprimary_Ext' || namedInsuredOccupancy === 'owneroccupiedseasonal_Ext',
        },
        vacationRentalsType: {
            visible: namedInsuredOccupancy === 'tenantoccupiedprimary_Ext' || namedInsuredOccupancy === 'tenantoccupiedseasonal_Ext',
        },
        dPLeaseAgreement: {
            visible: namedInsuredOccupancy === 'tenantoccupiedprimary_Ext' ||  namedInsuredOccupancy ===  'tenantoccupiedseasonal_Ext',
        },
        dPLeaseAgreementExplain: {
            required: true,
            visible: (dpleaseAgreement === 'lessthansixmonths' || dpleaseAgreement === 'morethanthreetenants') && (namedInsuredOccupancy === 'tenantoccupiedprimary_Ext' ||  namedInsuredOccupancy ===  'tenantoccupiedseasonal_Ext'),
        },
        // isAnyPortionOfThePropertyToggle: {
        //     defaultValue: false,
        //     value: isPropertyRentedToOthers,
        // },
        typeOfDwellingDropdown: {
            availableValues: getTypeOfDwellingDropdown(),
        },
        dwellingLocation: {
            value: getResidenceLocaltionAndDisplay(),
        },
        residenceQuestionDropdown: {
            // availableValues: getResidenceQuestionDropdown(),
            visible: shouldShowResidenceQuestionDropdown(),
        },
        generalContractorToggle: {
            visible: shouldShowGeneralContractorToggle(),
        },
        constructionToggle: {
            visible: shouldShowConstructionToggle(),
        },
        estimatedCompletionDateDropdown: {
            visible: shouldShowEstimatedCompletionDateDropdown(),
            availableValues: getEstimatedCompletionDataDropdown()
        },
        householdResidentsTable: {
            data: householdResidentsTableData,
        },
        relationshipToInsuredColumn: {
            path: 'relationshipToInsured_Ext',
            renderCell: renderRelationshipToInsured,
        },
        additionalNamedInsuredColumn: {
            path: 'additionalNamedInsured',
            renderCell: renderAdditionalNamedInsured,
        },
        actionColumn: {
            renderCell: renderHouseholdTableAction,
        },
        primaryNamedInsuredEditIcon: {
            onClick: openEditPrimaryNamedInsuredPopup,
        },
        dwellingLocationEditIcon: {
            onClick: openDwellingLocationPopup,
        },
        // effectiveDate: {
        //     // minDate: LocalDateUtil.today(),
        //     minDate: effectiveMinDate(),
        //     maxDate: effectiveMaxDate(),
        //     value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
        //     showErrors
        // },
        effectiveDate: {
            ...getEffectiveDateFieldOverrideProps()
        },
        policyStateDropdown: {
            value: baseState,
            //
            availableValues: getBaseStateList(),
            readOnly: !(isHouseholdInfoFirstEnter && isBaseStateInMutualState())
        },
        totalNumberofHouseholdResidents: {
            required: true,
            minValue: _.get(householdResidentsTableData, 'length', 0)
        },
        addHouseholdResident: {
            onClick: handleAddHouseholdResident,
        },
        UWQSelectAllAppliesMultiSelect: {
            availableValues: getMultipleSelectOptions(),
            visible: !!_.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.applicantExistPolicy_Ext'),
        },
        UWQApplicantExistPolicyToggle: {
            label: translator(messages.isDpThereExistingPolicy, {
                companyName: domainName
            }),
        },
        policyUnderwritingQuestionSets: {
            isRequiredForIssuance: isRequiredForIssuance,
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            // onValidate,
            // getInvalidAnswerKeys: questionSetGetInvalidAnswerKeys,
        }
    };
    //---------------------
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            // validationissuescomponent: ValidationIssuesComponent,
            // agencyServiceinfo: AgencyOfServiceComponent,
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            // policyholderuwquestion: AQPolicyHolderUWQuestionComponent,
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

DPHouseholdInfoPage.propTypes = {
    ...wizardProps,
    saveDpHouseholdInfo: PropTypes.func,
    // householdInfoPageOnInit: PropTypes.func,
    isOpenPNIPopupPageInit: PropTypes.bool,
    checkRequiredForIssuance: PropTypes.bool,
        /**
     * Controls the visibility of fields on the page.
     *
     * Default to 'false' to see all fields
     */
        readOnlyFields: PropTypes.shape({
            effectiveDate: PropTypes.bool,
            walTermTypedropdownselect: PropTypes.bool,
            walBaseState: PropTypes.bool,
            agencyOfRecord: PropTypes.bool,
            uwqReadonly: PropTypes.bool
        })
};

DPHouseholdInfoPage.defaultProps = {
    saveDpHouseholdInfo: DPHouseholdInfoService.saveDpHouseholdInfoData,
    // householdInfoPageOnInit: undefined,
    isOpenPNIPopupPageInit: false,
    checkRequiredForIssuance: true,
    readOnlyFields: {
        effectiveDate: false,
        walTermTypedropdownselect: false,
        walBaseState: false,
        agencyOfRecord: false,
        uwqReadonly: false
    }
};

export default DPHouseholdInfoPage;
