import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import PUDummyPage from './pages/Dummy/PUDummyPage';
import PUDummyReadOnlyPage from './pages/Dummy/PUDummyPage.readonly';

import PUUnderlyingPage from './pages/Underlying/PUUnderlyingPage';
import PUUnderlyingReadOnlyPage from './pages/Underlying/PUUnderlyingPage.readonly';

import PUCoveragesPage from './pages/Coverages/PUCoveragesPage';
import PUCoveragesReadOnlyPage from './pages/Coverages/PUCoveragesPage.readonly';

import PULossHistoryPage from './pages/LossHistory/PULossHistoryPage';
import PULossHistoryReadOnlyPage from './pages/LossHistory/PULossHistoryPage.readonly';

import PUQuotePage  from './pages/Quote/PUQuotePage';
import PUQuoteReadOnlyPage from './pages/Quote/PUQuotePage.readonly';

import PUHouseholdInfoPage from './pages/HouseholdInfomation/PUHouseholdInfoPage';
import PUHouseholdInfoReadOnlyPage from './pages/HouseholdInfomation/PUHouseholdInfoPage.readonly';

setComponentMapOverrides(
    {
        PUDummyPage: {component: 'PUDummyPage'},
        PUDummyReadOnlyPage: { component: 'PUDummyReadOnlyPage' },

        PUHouseholdInfoPage: {component: 'PUHouseholdInfoPage'},
        PUHouseholdInfoReadOnlyPage: { component: 'PUHouseholdInfoReadOnlyPage' },

        PUUnderlyingPage: {component: 'PUUnderlyingPage'},
        PUUnderlyingReadOnlyPage: { component: 'PUUnderlyingReadOnlyPage' },

        PUCoveragesPage: {component: 'PUCoveragesPage'},
        PUCoveragesReadOnlyPage: {component: 'PUCoveragesReadOnlyPage'},

        PULossHistoryPage: {component: 'PULossHistoryPage'},
        PULossHistoryReadOnlyPage: {component: 'PULossHistoryReadOnlyPage'},
        //
        PUQuotePage: {component: 'PUQuotePage'},
        PUQuoteReadOnlyPage: {component: 'PUQuoteReadOnlyPage'},

    },
    {
        PUDummyPage,
        PUDummyReadOnlyPage,

        PUHouseholdInfoPage,
        PUHouseholdInfoReadOnlyPage,

        PUUnderlyingPage,
        PUUnderlyingReadOnlyPage,

        PUCoveragesPage,
        PUCoveragesReadOnlyPage,

        PULossHistoryPage,
        PULossHistoryReadOnlyPage,
        //
        PUQuotePage,
        PUQuoteReadOnlyPage,
    }
);


export {
    PUDummyPage,
    PUDummyReadOnlyPage,

    PUHouseholdInfoPage,
    PUHouseholdInfoReadOnlyPage,

    PUUnderlyingPage,
    PUUnderlyingReadOnlyPage,

    PUCoveragesPage,
    PUCoveragesReadOnlyPage,

    PULossHistoryPage,
    PULossHistoryReadOnlyPage,

    //
};

// eslint-disable-next-line import/prefer-default-export
export { default as PUSubmissionWizard } from './PUSubmissionWizard';
export { default as PUWizardPage } from './templates/PUWizardPage';

export { default as PUQuoteIssueRenderHelper } from './pages/Quote/util/PUQuoteIssueRenderHelper';
export { default as PULossHistoryUtil} from './pages/LossHistory/PULossHistoryUtil';
