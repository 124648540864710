import React, {
    useEffect,
} from 'react';
import _ from 'lodash';

import { InlineLoader } from '@jutro/components';

/**
 * Get a renderFunc that renders loading in progress if <code>isLoading</code> is true.
 *
 * Usage example:
 * renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress)
 * @param {boolean} isLoading
 * @returns {Function} a render function that can be fed to the "rednerContent" property
 */
function getButtonRenderFunc(isLoading = false) {
    return isLoading ? () => (<InlineLoader loading className="gw-inline-loader" />) : null;
}

/**
 * Get a render loader
 * @param {boolean | string} isLoading true, false, or the loading buttonID
 * @param {string} buttonID Optional Button ID
 * @returns {object} a loading component
 */
function getButtonRenderLoader(isLoading = false, buttonID = undefined) {
    let loadingInProgress = isLoading;
    if (!_.isNil(buttonID)) {
        loadingInProgress = isLoading === buttonID;
    }
    return loadingInProgress ? (<InlineLoader loading className="gw-inline-loader" />) : null;
}

/**
 * Result of this could be used as "content" of a jutro Button component
 * Usage Example:
 * content: renderHelper.getButtonContent(translator(messages.btnLbel), isLoading)
 * @param {string} buttonLabel
 * @param {boolean | string} isLoading
 * @param {string} buttonID Optional button ID
 * @returns {object} react content what could be used as button content
 */
function getButtonContent(buttonLabel, isLoading = false, buttonID = undefined) {
    return (
        <>
            {buttonLabel}
            {getButtonRenderLoader(isLoading, buttonID)}
        </>
    );
}
/**
 * NOTE: this is a temp solutio and should be  removed
 * 
 * Mec on 2024-02-28 (Wed): This is bad. Please remove this method ASAP.
 * If the same useEffect() needs to be shared among different components, 
 * the canonical way is introducing a custom hook to get the job done. e.g.
 * useClickOutside, and invoke useEffect() inside useClickOutside.
 * 
 * @param {Object} ref
 * @param {object} callback
 */
function useOnClickOutside(ref, callback) {
    useEffect(() => {
        function handler(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, [callback, ref]);
}


/**
 * Render UW Issue list as unordered list.
 * Refactoring Note: Might need to convert this into a component
 * @param {array} pendingInfoIssues
 * @returns {object}
 */
function getIssuesRenderContent(pendingInfoIssues) {
    if (_.isEmpty(pendingInfoIssues)) {
        return null;
    }
    const retval = pendingInfoIssues.map((uwIssue) => {
        const { longDescription } = uwIssue;
        return <li className="mb-10 listdisc" key={longDescription}>{longDescription}</li>;
    });
    return (
        <ul className="listContainer">{retval}</ul>
    );
}

export default {
    getButtonRenderFunc,
    // getButtonRenderLoader,
    getButtonContent,
    useOnClickOutside,
    getIssuesRenderContent,
};
