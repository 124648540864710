import React, {
    useState
} from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import domainData from '../config/DomainData.config';
import interactionModelData from '../config/InteractionModes.config';
import WorkflowTypes from '../config/WorkflowTypes.config';
import ProductionWorkflow from '../config/ProductionWorkflow.config';
import CommonUITypes from '../config/CommonUITypes.config';

import metadata from './DebugPanel.metadata.json5';


function DebugPanel(props) {
    const {
        // data: { domain, interaction, menuBarEnabled },
        domainDataConfig,
        interactionModeConfig,
        menuBarConfig,
        workflowConfig,
        productionConfig,
        commonUITypeConfig,
    } = props;
    const [settingFlag, setSettingFlag] = useState(false);
    const handleToggleFn = () => {
        setSettingFlag(!settingFlag);
    };
    const resolvers = {
        resolveCallbackMap: {
            handleToggleFn,
        },
        resolveComponentMap: {

        }
    };
    const overrideProps = {
        '@all': {
            tabIndex: -1,
        },
        debugPanelSection: {
            visible: settingFlag
        },
        domainCompanyContainer: {
            availableValues: domainData,
            ...domainDataConfig,
        },
        interactionModalContainer: {
            availableValues: interactionModelData,
            ...interactionModeConfig,
        },
        menuBarEnabled: {
            ...menuBarConfig,
        },
        workflowTypeToggle: {
            availableValues: WorkflowTypes,
            ...workflowConfig,
        },
        productionWorkFlow: {
            availableValues: ProductionWorkflow,
            ...productionConfig
        },
        commonUITypeToggle: {
            availableValues: CommonUITypes,
            ...commonUITypeConfig,
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

DebugPanel.propTypes = {
    domainDataConfig: PropTypes.shape({}),
    interactionModeConfig: PropTypes.shape({}),
    menuBarConfig: PropTypes.shape({}),
    workflowConfig: PropTypes.shape({}),
    productEnabledConfig: PropTypes.shape({})
};

DebugPanel.defaultProps = {
};

export default DebugPanel;
