import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useModal } from '@jutro/components';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import metadata from './EditCoverages.metadata.json5';
import messages from '../../PECPWizard.messages';

const EditCoverages = (props) => {
    const modalApi = useModal();
    const {
        id,
        onValidate,
        setCoverageSubmission,
        wizardData: { selectedBuilding, submissionVM }
    } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [buildingCoverageVM, updateBuildingCoverageVM] = useState(null);
    const [loadingClause, updateLoadingClause] = useState();
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation(id);

    useEffect(() => {
        registerComponentValidation(() => _.isUndefined(loadingClause));
        onValidate(isComponentValid, id);
    }, [id, isComponentValid, loadingClause, onValidate, registerComponentValidation]);

    useEffect(() => {
        const submission = viewModelService.create(
            selectedBuilding.building,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverages.dto.BuildingCoverageDTO'
        );
        updateBuildingCoverageVM(submission);
        setCoverageSubmission(submission);
    }, [selectedBuilding, setCoverageSubmission, viewModelService]);

    const onClauseChange = useCallback(
        async (value, changedPath) => {
            const quoteID = _.get(submissionVM, 'quoteID.value') || _.get(submissionVM, 'jobID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
            const locationId = _.get(selectedBuilding, 'building.locationId');

            ClausesUtil.setClauseValue(buildingCoverageVM, value, changedPath);

            if (!value) {
                return false;
            }

            const newBuildingCoverageVM = viewModelService.clone(buildingCoverageVM);

            try {
                const updatedCoverageResponse = await CPCoverablesService.updateCPBuildingCoverages(
                    quoteID,
                    locationId,
                    newBuildingCoverageVM.value,
                    sessionUUID,
                    authHeader
                );

                _.set(newBuildingCoverageVM, 'value', updatedCoverageResponse);

                const fieldsToRemoveFromValidation = ClausesUtil.getRemovedClausesID(
                    buildingCoverageVM,
                    newBuildingCoverageVM,
                    'coverages'
                );

                disregardFieldValidation(fieldsToRemoveFromValidation);
                setCoverageSubmission(newBuildingCoverageVM);
                updateBuildingCoverageVM(newBuildingCoverageVM);
            } catch {
                modalApi.showAlert({
                    title: messages.coveragesNotSavedErrorTitle,
                    message: messages.coveragesNotSavedErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
            updateLoadingClause(undefined);
            return newBuildingCoverageVM;
        },
        [
            submissionVM,
            selectedBuilding,
            buildingCoverageVM,
            viewModelService,
            authHeader,
            disregardFieldValidation,
            setCoverageSubmission
        ]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = ClausesUtil.setClauseValue(
                viewModelService.clone(buildingCoverageVM),
                value,
                changedPath
            );
            updateBuildingCoverageVM(newSubmissionVM);
            setCoverageSubmission(newSubmissionVM);
            return newSubmissionVM;
        },
        [viewModelService, buildingCoverageVM, setCoverageSubmission]
    );

    const syncClauses = useCallback(
        (value, changedPath, latestSubmission = buildingCoverageVM) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(latestSubmission, basePath);

            const clauses = _.get(latestSubmission, 'coverages.value');

            if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
                if (value) {
                    updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
                }
                onClauseChange(value, changedPath);
            }
        },
        [onClauseChange, buildingCoverageVM]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const latestSubmissionVM = changeSubmission(value, changedPath);
            syncClauses(value, changedPath, latestSubmissionVM);
        },
        [changeSubmission, syncClauses]
    );

    const overrideProps = {
        coverageClauses: {
            loadingClause: loadingClause
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate: setComponentValidation
        }
    };

    const readValue = useCallback(
        (elementID, path) => {
            return readViewModelValue(
                metadata.pageContent,
                buildingCoverageVM,
                elementID,
                path,
                overrideProps
            );
        },
        [buildingCoverageVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={buildingCoverageVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
        />
    );
};

EditCoverages.propTypes = {
    id: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    setCoverageSubmission: PropTypes.func.isRequired,
    wizardData: PropTypes.shape({
        selectedBuilding: PropTypes.shape({
            building: PropTypes.shape({})
        }),
        submissionVM: PropTypes.shape({ submissionVM: PropTypes.shape({}) })
    }).isRequired
};

EditCoverages.defaultProps = {
    id: 'EditCoverages'
};

export default EditCoverages;
