import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import metadata from './LossLocationComponent.metadata.json5';

function LossLocationComponent(props) {
    const {
        onLocationChange,
        lossLocationIndex,
        predefinedLossLocations, 
        isReadOnly,
        setComponentValidation,
        showErrors
    } = props;
    
    const viewModelService = useContext(ViewModelServiceContext);

    const [currentLocationVM, updateCurrentLocationVM] = useState(viewModelService.create(
        {country: 'US'},
        'cc',
        'edge.capabilities.address.dto.AddressDTO'
    ));

    const {
        isComponentValid,
        invalidFields,
        registerComponentValidation
    } = useValidation('LossLocationComponent');

    const isCityEmptyCheck = useCallback(() => {
        if (_.get(currentLocationVM, 'value.city') === '' || _.get(currentLocationVM, 'value.state') === '') {
            return false
        }
        return true
    }, [currentLocationVM])

    useEffect(() => {
        let newCurrentLocationVM;
        if (lossLocationIndex !== predefinedLossLocations.length) {
            newCurrentLocationVM = viewModelService.clone(currentLocationVM);
            _.set(newCurrentLocationVM, 'value', predefinedLossLocations[lossLocationIndex]);
        } else {
            newCurrentLocationVM = viewModelService.create(
                {country: 'US', city: '', state: ''},
                'cc',
                'edge.capabilities.address.dto.AddressDTO'
            );
        }
        updateCurrentLocationVM(newCurrentLocationVM)
    }, [lossLocationIndex, predefinedLossLocations])
    
    useEffect(() => {
        registerComponentValidation(isCityEmptyCheck);
    }, [isCityEmptyCheck, registerComponentValidation]);

    useEffect(() => {
        if (setComponentValidation) {
            setComponentValidation(isComponentValid, 'LossLocationComponent');
        }
        return () => {
            if (setComponentValidation) {
                setComponentValidation(true, 'LossLocationComponent');
            }
        }
    }, [setComponentValidation, isComponentValid])

    const overrideProps = useMemo(() => {
        return {
            '@field': {
                showRequired: true,
                labelPosition: "left",
                readOnly: isReadOnly
            }
        }
    }, [isReadOnly])

    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, currentLocationVM, fieldId, fieldPath, overrideProps
        );
    }, [currentLocationVM, overrideProps]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={currentLocationVM}
            overrideProps={overrideProps}
            onValueChange={onLocationChange}
            resolveValue={readValue}
            showErrors={showErrors}
        />
    );
}
LossLocationComponent.propTypes = {
    isReadOnly: PropTypes.bool
};

LossLocationComponent.defaultTypes = {
    isReadOnly: false
};

export default LossLocationComponent;
