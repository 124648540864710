import React, {
    useContext,
    useEffect,
} from 'react';
import _ from 'lodash';
import { WizardUtil } from 'wni-portals-util-js';
import { BaseWizardPage } from 'wni-portals-wizard-react';
import { WizardContext } from '@xengage/gw-portals-wizard-react';

function AQWizardPage(props) {
    const wizardContext = useContext(WizardContext);
    const {
        isSkipping,
        stopSkipping,
        goNext: wizardGoNext,
    } = wizardContext;

    const {
        skipWhen,
    } = props;

    useEffect(() => {
        if (isSkipping) {
            Promise.resolve(skipWhen())
                .then((shouldSkip) => {
                    if (shouldSkip) {
                        wizardGoNext();
                    } else {
                        stopSkipping();
                    }
                });
        }
    });

    return (
        <BaseWizardPage {...props} />
    );
}

AQWizardPage.propTypes = BaseWizardPage.propTypes;

AQWizardPage.defaultProps = BaseWizardPage.defaultProps;


export default AQWizardPage;
