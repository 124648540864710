import React, {
    useEffect, useCallback, useContext, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { FormPopupComponent, useWniModal } from 'wni-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './VehicleCustomEquipmentTableComponent.metadata.json5';
import messages from './VehicleCustomEquipmentTableComponent.message';

function VehicleCustomEquipmentTableComponent(props) {
    const modalApi = useWniModal();
    const {
        id,
        vehicleVM,
        onValueChange,
        onValidate,
        readOnly
    } = props;
    const {
        isComponentValid,
    } = useValidation(id);
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const viewModelService = useContext(ViewModelServiceContext);
    const customEquipmentPath = 'customEquipments_Ext.value';

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, vehicleVM, onValidate, isComponentValid, viewModelService]);

    const deleteDataRow = useCallback((row, index) => {
        const rows = _.get(vehicleVM, customEquipmentPath);
        if (rows) {
            rows.splice(index, 1);
        }
        onValueChange(rows, customEquipmentPath);
    }, [vehicleVM]);
    const showModalFn = (modalVM, modelProps) => {
        const componentProps = {
            title: modelProps.title,
            actionBtnLabel: translator(messages.popOK),
            cancelBtnLabel: translator(messages.popCancel),
            modalVM,
            modelProps: modelProps
        };
        return modalApi.showModal(<FormPopupComponent {...componentProps} />);
    };
    const addNewRow = useCallback(() => {
        const modalVM = viewModelService.create({}, 'pc', 'wni.edge.capabilities.quote.submission.dto.WniVehicleCustomEquipmentDTO');
        const modelInfo = {
            labelMap: {
                customEquipment: { label: messages.equipment },
                equipmentValue: { label: messages.value, inputCtrlType: 'currency' }
            },
            type: translator(messages.Add),
            title: `${translator(messages.Add)} ${translator(messages.customEquipment)}`
        };
        showModalFn(modalVM, modelInfo)
            .then((result) => {
                const { customEquipment, equipmentValue } = result;
                const data = {
                    customEquipment,
                    equipmentValue: equipmentValue.amount || equipmentValue
                };
                // Add default variable type in case get func returns undefined object
                const rows = _.get(vehicleVM, customEquipmentPath, []);
                rows.unshift(data);
                onValueChange(rows, customEquipmentPath);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    }, [vehicleVM]);

    const editDataRow = useCallback((rowValue, index) => {
        const modalVM = viewModelService.create(rowValue, 'pc', 'wni.edge.capabilities.quote.submission.dto.WniVehicleCustomEquipmentDTO');
        const modelInfo = {
            labelMap: {
                customEquipment: { label: messages.equipment },
                equipmentValue: { label: messages.value, inputCtrlType: 'currency' }
            },
            type: translator(messages.Save),
            title: `${translator(messages.Edit)} ${translator(messages.customEquipment)}`
        };
        showModalFn(modalVM, modelInfo)
            .then((result) => {
                const { customEquipment, equipmentValue } = result;
                const data = {
                    customEquipment,
                    equipmentValue: equipmentValue.amount || equipmentValue
                };
                const rows = _.get(vehicleVM, customEquipmentPath);
                rows[index] = data;
                onValueChange(rows, customEquipmentPath);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    }, [vehicleVM]);

    const getCell = (item, index, property) => {
        const { path, currency } = property;
        let formattedContent = item[path];
        if (currency && formattedContent) {
            formattedContent = intl.formatNumber(formattedContent, {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol' // 'code'
            });
        }
        return (
            <span>{formattedContent}</span>

        );
    };

    const overrideProps = {
        editButton: {
            onClick: editDataRow,
            visible: !readOnly
        },
        deleteButton: {
            onClick: deleteDataRow,
            visible: !readOnly
        },
        addButton: {
            onClick: addNewRow,
            visible: !readOnly
        },
        customEquipment: {
            renderCell: (item, index) => {
                const vm = _.get(vehicleVM, `customEquipments_Ext.children.${index}.customEquipment.value`);
                return translator({
                    id: vm.name
                });
            }
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getCell: getCell
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

VehicleCustomEquipmentTableComponent.propTypes = {
    vehicleVM: PropTypes.shape({ value: {} }).isRequired,
    onValidate: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    id: PropTypes.string.isRequired,
};

VehicleCustomEquipmentTableComponent.defaultProps = {
    readOnly: false,
};

export default VehicleCustomEquipmentTableComponent;
