import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniBillingSubmission'), method, data, additionalHeaders);
}

export default class WniGatewayBillingSubmissionService {
    static getUrlgeneration(params, additionalHeaders) {
        return processSubmission('getUrlGeneration', params, additionalHeaders);
    }

    static findAutoPayEnrollment(params, additionalHeaders) {
        return processSubmission('findAutoPayEnrollment', params, additionalHeaders);
    }

    static getBillingPoliciesByAccountNumber(params, additionalHeaders) {
        return processSubmission('getBillingPoliciesByAccountNumber', params, additionalHeaders);
    }

    static saveBillingParameterToPolicies(params, additionalHeaders) {
        return processSubmission('saveBillingParameterToPolicies', params, additionalHeaders);
    }

    static getPortalDocumentsForAccountGroupByType(params, additionalHeaders) {
        return processSubmission('getPortalDocumentsForAccountGroupByType', params, additionalHeaders);
    }

    static getDocumentsForAccount(params, additionalHeaders = {}) {
        return processSubmission('getPortalDocumentsForAccount', params, additionalHeaders);
    }

    static getDocumentDocUIDs(publicIDs, additionalHeaders) {
        return processSubmission('getDocumentDocUIDs', [publicIDs], additionalHeaders);
    }

    static makeAgencySweepPayment(params, additionalHeaders) {
        return processSubmission('makeAgencySweepPayment', params, additionalHeaders);
    }
}
