import React from "react";
import _ from 'lodash';
import { WniDropdownSelect } from "../FieldWithTooltip/FieldWithTooltip";

const NON_GROUP_LABEL = 'others';
const generateOptions = (data = []) => {
    const map = new Map();
    _.forEach(data, (item, index, arr) => {
        if(!map.has(item.groupLabel)){
            if(item.groupLabel) {
                map.set(
                    item.groupLabel,
                    arr.filter((a) => a.groupLabel === item.groupLabel)
                )
            } else {
                map.set(
                    NON_GROUP_LABEL,
                    arr.filter((a) => !a.groupLabel)
                )
            }
            
        }
    });
    const newList = Array.from(map).map((item) => {
        if(item[0] !== NON_GROUP_LABEL) {
            const groupLabelOption = {
                code: item[0],
                name: item[0],
                isDisabled: true
            }
            const itemValue = _.orderBy(item[1], ['name'], ['asc']);
            return [groupLabelOption, ...itemValue]
        }
        return [...item[1]]
    })
    return _.flatten(newList)

};

function WniDropdownGroup(props) {
    const { availableValues } = props;

    const options = generateOptions(availableValues);
    const newComponentProps = {
        ...props,
        availableValues: options
    }
    return <WniDropdownSelect {...newComponentProps} />

};

export default WniDropdownGroup