import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class BOPCoverablesService {
    static addBOPLocation(quoteID, bopLocation, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'addLocation', [quoteID, bopLocation, sessionUUID], additionalHeaders);
    }

    static updateBOPLocation(quoteID, bopLocation, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'updateLocation', [quoteID, bopLocation, sessionUUID], additionalHeaders);
    }

    static removeBOPLocation(quoteID, bopLocation, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'removeLocation', [quoteID, bopLocation, sessionUUID], additionalHeaders);
    }

    static addBOPBuilding(
        quoteID,
        bopLocationId,
        bopBuilding,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'addBuilding', [quoteID, bopLocationId, bopBuilding, sessionUUID], additionalHeaders);
    }

    static updateBOPBuilding(
        quoteID,
        bopLocationId,
        bopBuilding,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'updateBuilding', [quoteID, bopLocationId, bopBuilding, sessionUUID], additionalHeaders);
    }

    static removeBOPBuilding(
        quoteID,
        bopLocationId,
        bopBuilding,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'removeBuilding', [quoteID, bopLocationId, bopBuilding, sessionUUID], additionalHeaders);
    }

    static updateBOPBuildingCoverages(
        quoteID,
        bopLocationId,
        bopBuilding,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaybopcoverables'), 'updateBuildingCoverages', [quoteID, bopLocationId, bopBuilding, sessionUUID], additionalHeaders);
    }
}
