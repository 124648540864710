import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverAssignmentTitle: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Assign Drivers to Vehicles',
        defaultMessage: 'Assign Drivers to Vehicles',
    },
    paDriverAssignmentVehicle: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Vehicle',
        defaultMessage: 'Vehicle',
    },
    paDriverAssignmentPrimaryDriver: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Primary Driver',
        defaultMessage: 'Primary Driver',
    },
    paDriverAssignmentOccasionalDrivers: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Occasional Driver',
        defaultMessage: 'Occasional Driver',
    },
    paDriverAssignmentStudentAwayDrivers: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Student Away',
        defaultMessage: 'Student Away',
    },
    paDriverAssignmentRatedDriver: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Rated Driver',
        defaultMessage: 'Rated Driver',
    },
    paDriverAssignmentPrimaryOperator: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Primary Operator',
        defaultMessage: 'Primary Operator (Excess Vehicle)',
    },
    // quoteandbind.pa.views.pa-driver-assignment.Primary Operator
    paDriverAssignmentEdit: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Edit',
        defaultMessage: 'Edit',
    },
});
