
import React from 'react';

import { WALIncidentsAndReportsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function RTIncidentsAndReportsReadOnlyPage(props) {
    return (
        <WALIncidentsAndReportsReadOnlyPage 
            {...props}
            productName = 'roadTrail'
        />
    );
}

RTIncidentsAndReportsReadOnlyPage.propTypes = WALIncidentsAndReportsReadOnlyPage.propTypes;
RTIncidentsAndReportsReadOnlyPage.defaultProps = WALIncidentsAndReportsReadOnlyPage.defaultProps;

export default RTIncidentsAndReportsReadOnlyPage;






