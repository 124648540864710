const COVERABLES_PATH = 'lobData.commercialAuto.coverables';
const PATH = `${COVERABLES_PATH}.vehicles`;
const CHILDREN_PATH = `${PATH}.children`;

const requireForIssuance = ['VIN'];

const newGarageLocation = {
    code: 'new',
    name: 'New',
    country: 'US',
};

const callPriceDigestTypeMaps = ['VIN', 'Year', 'Make', 'Model'];

const heavyTruck = [
    'Heavy Truck',
    'Heavy Truck-Tractor',
    'Extra-Heavy Truck',
    'Extra-Heavy Truck-Tractor',
];

export {
    COVERABLES_PATH,
    PATH,
    CHILDREN_PATH,
    newGarageLocation,
    requireForIssuance,
    callPriceDigestTypeMaps,
    heavyTruck
};
