import { defineMessages } from 'react-intl';

export default defineMessages({
    Coverages: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.BoatCoverage.Coverages",
        "defaultMessage": "Coverages"
    },
    coveragesExtensions: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.BoatCoverage.Coverages Extensions",
        "defaultMessage": "Coverages Extensions"
    },
    optionalCoverages: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.BoatCoverage.Optional Coverages",
        "defaultMessage": "Optional Coverages"
    },
    miscBoatingEquipment: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.BoatCoverage.Miscellaneous Boating Equipment",
        "defaultMessage": "Miscellaneous Boating Equipment"
    },
    rentedBoats: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.BoatCoverage.Rented Boats And Motors",
        "defaultMessage": "Rented Boats And Motors"
    },
});