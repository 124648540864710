import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
// import { wizardStepProps } from 'gw-portals-wizard-react/Wizard/prop-types/wizardPropTypes';
// import { wizardStepProps } from '@xengage/gw-portals-wizard-react';


const wizardStepProps = {
    title: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]),
    path: PropTypes.string,
    component: PropTypes.elementType,
};

function renderRoutes({ basePath, steps, propsToPass }) {
    return steps.map((step) => {
        const { component: Component, path } = step;
        const composedPath = [basePath, path].join('');
        return (
            <Route key={path} path={composedPath} render={() => <Component {...propsToPass} />} />
        );
    });
}

const BaseWizardRoutes = (props) => {
    const { steps, basePath } = props;
    return <Switch>{renderRoutes({ steps, basePath, propsToPass: props })}</Switch>;
};

BaseWizardRoutes.propTypes = {
    /**
     * where the parent wizard is based
     */
    basePath: PropTypes.string.isRequired,
    /**
     * steps composing the routes
     */
    steps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired
};

export default BaseWizardRoutes;
