import React from 'react';
import { InlineNotification } from '@jutro/components';

function WniInlineNotification(props) {
    const messageProps = props.messageProps || {};
    const componentProps = {
        ...props,
        messageProps
    }
    return <InlineNotification {...componentProps}/>
}

export default WniInlineNotification;