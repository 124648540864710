import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 * WniQuoteVehicleHandler
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniQuoteVehicle'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Vehicle for quote backend operations
 *
 * @export
 * @class WniQuoteVehicleService
 */
export default class WniQuoteVehicleService {
    /**
     * Invokes WniQuoteVehicleHandler.updateVehicle()
     * @param {object} data the WniUpdateVehicleRequestDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains QuoteDataDTO
     */
    static updateVehicle(data, authHeader = {}) {
        return processSubmission('updateVehicle', [data], authHeader);
    }

    /**
     * Invokes WniQuoteVehicleHandler.autoAssignAndGetVehicleDriverAssignments()
     * @param {object} quoteID quoteID
     * @param {object} sessionUUID sessionUUID
     * @param {object} updatingAssignment Boolean
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains VehicleDriverDTO[]
     */
    static autoAssignAndGetVehicleDriverAssignments(quoteID, sessionUUID, updatingAssignment, authHeader = {}) {
        return processSubmission('autoAssignAndGetVehicleDriverAssignments', [quoteID, sessionUUID, updatingAssignment], authHeader);
    }

    /**
     * Invokes WniQuoteVehicleHandler.getAvailableDrivers()
     * @param {object} quoteID quoteID
     * @param {object} vehicleID vehicleID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains SimpleDriverDTO[]
     */
    static getAvailableDrivers(quoteID, vehicleID, authHeader = {}) {
        return processSubmission('getAvailableDrivers', [quoteID, vehicleID], authHeader);
    }

    /**
     * Invokes WniQuoteVehicleHandler.updateVehicleDriverAssignments()
     * @param {object} quoteID quoteID
     * @param {object} sessionUUID sessionUUID
     * @param {object} assignments VehicleDriverDTO[]
     * @param {object} vehicleID Vehicle's fixedId
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains QuoteDataDTO
     */
    static updateVehicleDriverAssignments(quoteID, sessionUUID, assignments, vehicleID, authHeader = {}) {
        return processSubmission('updateVehicleDriverAssignments', [quoteID, sessionUUID, assignments, vehicleID], authHeader);
    }


    /**
     * Invokes WniQuoteVehicleHandler.getErrorsAndWarnings()
     * @param {object} quoteID quoteID
     * @param {object} isCoveragePage boolean
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains
     * JobValidationUnderwritingIssuesDTO
     */
    static getErrorsAndWarnings(quoteID, isCoveragePage, authHeader = {}) {
        return processSubmission('getErrorsAndWarnings', [quoteID, isCoveragePage], authHeader);
    }

    static getAdditionalInterestType(quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader) {
        return processSubmission('getAdditionalInterestType', [quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust], authHeader);
    }

    /**
     * Invokes WniQuoteVehicleHandler.updateVehicleHistory()
     * @param {String} quoteID
     * @param {String} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateVehicleHistory(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('updateVehicleHistory', [quoteID, sessionUUID], authHeader);
    }
}
