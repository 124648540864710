import React, {useState, useCallback, useEffect} from 'react'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CRLineCoveragesService } from 'wni-capability-quoteandbind-cr'
import metadata from './CRLineCoveragesPage.metadata.json5';
import WizardPage from '../../templates/CRWizardPage';
import CoveragesConfigContext from '../../context/CRCoveragesConfigContext';
import CRCoveragesConfig from '../../config/CRCoverages.config';
import CoveragesCard from './component/CoveragesCard/CoveragesCard';
import AdditionalCoveragesCard from './component/AdditionalCoveragesCard/AdditionalCoveragesCard';
import ExclusionsAndConditionsCard from './component/ExclusionsAndConditionsCard/ExclusionsAndConditionsCard';
import DetailsCard from './component/DetailsCard';

const CRLineCoveragesReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM,
    } = props;

    const { initialValidation } = useValidation('CRLineCoveragesPage');
    const { authHeader } = useAuthentication();
    const [lineCoverages, setLineCoverages] = useState()

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');



    const initLineCoverages = useCallback(async () => {
        setLoadingMask(true)
        const res = await CRLineCoveragesService.getLineCoverages(jobID, sessionUUID, authHeader)
        setLineCoverages(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initLineCoverages()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    

    const onPageNext = async () => {
        return submissionVM
    }


    const accordionCommonProps = {
        lineCoverages: lineCoverages,
        isEditing: false,
        isEditable: false,
        showErrors: false,
        wizardData: submissionVM,
    }

    const overrideProps = {
        '@field': {
            isEditable: false,
        },
        DetailsCard: {
            ...accordionCommonProps
        },
        CoveragesCard: {
            ...accordionCommonProps
        },
        AdditionalCoveragesCard: {
            ...accordionCommonProps
        },
        ExclusionsAndConditionsCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            DetailsCard: DetailsCard,
            CoveragesCard: CoveragesCard,
            AdditionalCoveragesCard: AdditionalCoveragesCard,
            ExclusionsAndConditionsCard: ExclusionsAndConditionsCard,
        },
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            showRequiredInfoForFasterQuote
        >
            {lineCoverages && 
                <CoveragesConfigContext.Provider value={CRCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={false}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default CRLineCoveragesReadOnlyPage;