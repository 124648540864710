import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imCoveragePartsService'), method, data, additionalHeaders);
}

export default class IMCoveragePartsService {

    static addCoverageParts(data, authHeader) {
        return processSubmission('addCoverageParts', [data.jobID, data.sessionUUID, data.codes], authHeader);
    }

    static removeCoverageParts(data, authHeader) {
        return processSubmission('removeCoverageParts', [data.jobID, data.sessionUUID, data.codes], authHeader);
    }

    static syncCoverageForm(data, authHeader) {
        return processSubmission('onPageNext', [data.jobID, data.sessionUUID, data.dto], authHeader);
    }

    static onPageNext(data, authHeader) {
        return processSubmission('onPageNext', [data.jobID, data.sessionUUID, data.dto], authHeader);
    }

    static getCoveragePartsSelection(data, authHeader) {
        return processSubmission('getCoveragePartsSelection', [data.policyNumber], authHeader);
    }

}