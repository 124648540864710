import { defineMessages } from 'react-intl';

export default defineMessages({
    equipment: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Equipment',
        defaultMessage: 'Equipment'
    },
    customEquipment: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Custom Equipment',
        defaultMessage: 'Custom Equipment'
    },
    value: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Value',
        defaultMessage: 'Value'
    },
    Add: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Add',
        defaultMessage: 'Add'
    },
    Save: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Save',
        defaultMessage: 'Save'
    },
    Edit: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Edit',
        defaultMessage: 'Edit'
    },
    popOK: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.ok',
        defaultMessage: 'Ok'
    },
    popCancel: {
        id: 'quoteandbind.views.pa-vehicles.custom-equipment.Cancel',
        defaultMessage: 'Cancel'
    }
});
