import { Icon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import React from 'react';

function WniHeader(props) {
    const { errorState = false, children } = props;

    const newComponentProps = _.omit(props, ['errorState']);
    const classNameForErrorState = errorState ? 'errorState' : '';

    return (
        <Flex
            alignItems="center"
            className={`wniHeader ${classNameForErrorState}`}
        >
            <Flex {...newComponentProps}>{children}</Flex>
            {errorState && <Icon className="headerIcon" icon="gw-error-outline" /> }
        </Flex>
    );
}

export default WniHeader;
