import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader, formatDate, withModalContext } from '@jutro/components';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import UWUtil from 'gw-capability-gateway-react/Components/UnderwritingComponent/UWUtil';
import { TranslatorContext } from '@jutro/locale';
import { JobService, SubmissionService } from 'gw-capability-gateway';
import { WniPolicyService, WniSubmissionService } from 'wni-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import styles from 'gw-capability-gateway-react/QuoteDetails/Summary/Summary.module.scss';
import messages from 'gw-capability-gateway-react/QuoteDetails/Summary/Summary.messages';
import cx from 'classnames';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { SuccessNotification } from 'gw-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ErrorsAndWarningsUtil, DocumentsUtil, SummaryUtil } from 'wni-portals-util-js';
import { UnderIssuesTableComponent, DocumentCheckedComponent } from 'wni-capability-gateway-react';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import metadata from './RewriteSummary.metadata.json5';
import localMessages from './RewriteSummary.messages';
import interalStyles from './RewriteSummary.module.scss';

import { Link as GWLink } from '@jutro/router';

function DownloadDocumentComponent(props) {
    const { documents, docType } = props;

    const downloadDocument = (sessionID, publicID) => {
        // const { authHeader, history } = props;
        const serviceUrl = getProxiedServiceUrl('downloadDocument');
        const templateDownloadEndpointURL = serviceUrl.concat(
            `/${publicID}?token=${sessionID}`
        );
        const params = {
            method: 'GET',
            url: templateDownloadEndpointURL,
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        };

        const errorCallback = () => {
            // history.push('/documenterror');
        };

        DocumentDownloadService.getDocument(
            templateDownloadEndpointURL,
            params,
            errorCallback
        );
    };
    if (documents == null) {
        return null;
    }
    return documents.map((doc) => {
        if (docType === doc.docType_Ext) {
            const { name, publicID, sessionID } = doc;
            return (
                <div className={cx(interalStyles['new-line'])}>
                    <GWLink
                        className={cx(interalStyles['download-link'])}
                        onClick={() => {
                            downloadDocument(sessionID, publicID);
                        }}
                        id={publicID}
                    >
                        {name}
                    </GWLink>
                </div>
            );
        }
        return null;
    });
}

class RewriteSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadRewriteSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired,
            }),
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        domainCompany: PropTypes.shape({ domainName: PropTypes.string }).isRequired
    };

    state = {
        canWithdraw: false,
        isLoading: true,
        isCopying: false,
        documentsData: [],
        isShowNotification: false,
        referredToUnderWriter: false,
        showReferToUW: false,
        newSubmissionObj: {}
    };

    setShowReferToUW = (showReferToUW) => {
        this.setState({ showReferToUW: showReferToUW });
    }

    componentDidMount = () => {
        this.canWithdraw();
        this.getDocuments();
        this.checkReferredToUnderWriter();
    };

    checkReferredToUnderWriter = async () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
            authHeader,
        } = this.props;
        const { jobNumber } = submission;
        this.setState({
            referredToUnderWriter: await WniPolicyService.referredToUnderWriter(
                [jobNumber],
                authHeader
            )
        });
    }

    handleError = (title, message) => {
        return this.props.modalContext.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        });
    };

    getDocuments = async () => {
        try {
            const {
                fromAccountLanding: {
                    quoteDetailsData: { loadRewriteSummary: submission },
                },
                authHeader,
            } = this.props;

            let getAvailableDocumentsData = null;
            if (submission.policy !== null && submission.policy.issued) {
                const policyNumber = _.get(
                    submission,
                    'policyNumber_Ext'
                );
                getAvailableDocumentsData = await WniPolicyService
                    .getPortalDocumentsForPolicyGroupByType(policyNumber, authHeader);
            } else {
                const { jobNumber } = submission;
                getAvailableDocumentsData = await WniSubmissionService
                    .getPortalDocumentsForSubmissionGroupByType([jobNumber], authHeader);
            }

            const retainInAgencyDocs = DocumentsUtil.getDocumentData(getAvailableDocumentsData,
                DocumentsUtil.RETAIN_IN_AGENCY);
            const submitToWesternNationalDocs = DocumentsUtil.getDocumentData(
                getAvailableDocumentsData,
                DocumentsUtil.SUBMIT_TO_WESTERNNATIONAL
            );
            this.setState({
                documentsData: getAvailableDocumentsData,
                retainInAgency: retainInAgencyDocs,
                submitToWesternNational: submitToWesternNationalDocs
            });
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
        } = this.props;
        if (!_.isEmpty(submission)) {
            // const jobNumber = submission.jobNumber
            const { status: submissionStatus } = submission;
            // const translator = this.context;
            // usage: translator({ id: 'typekey.PolicyPeriodStatus.Bound' })

            if (submissionStatus === 'Not-taken') {
                this.setState({ canWithdraw: false });
            } else if (
                submissionStatus !== 'Bound'
                && !submission.policy.issued
                && !submission.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    onContinueTransaction = ({ isReadOnly = false }) => {
        const { lobQuoteURL } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
            history,
        } = this.props;
        const productCode = _.get(submission, 'productCode');
        const postalCode = _.get(
            submission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (submission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    isReadOnly,
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: submission.jobNumber,
                        // producerCode_Ext: submission.producerCode_Ext
                    },
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(submission.jobNumber);
            }
        }
    };

    onCopySubmission = async () => {
        const { lobQuoteURL } = appConfig;
        const { authHeader } = this.props;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            }
        } = this.props;
        this.setState({ isCopying: true });
        const newSubmission = await WniSubmissionService.copySubmission(
            submission.jobNumber,
            authHeader
        );
        const productCode = _.get(newSubmission, 'productCode');
        if (newSubmission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                this.setState({ isShowNotification: true });
                this.setState({ newSubmissionObj: newSubmission });
            }
        }
        this.setState({ isCopying: false });
    };

    goToNextSubmission = (history, lobQuoteURL, productCode, nextLocation) => {
        history.push(lobQuoteURL[productCode], nextLocation);
    }

    getCopyMsg = (newSubmissionObj, isShowNotification) => {
        if (!_.isEmpty(newSubmissionObj) && isShowNotification) {
            const { lobQuoteURL } = appConfig;
            const {
                history
            } = this.props;
            const productCode = _.get(newSubmissionObj, 'productCode');
            const postalCode = _.get(
                newSubmissionObj,
                'policy.account.accountHolder.primaryAddress.postalCode'
            );
            const nextJobNum = newSubmissionObj.jobNumber;
            const nextLocation = {
                quoteentry: {
                    postalCode: postalCode,
                    quoteID: nextJobNum,
                },
            };
            return (
                <>
                    The application has been copied to &nbsp;
                    <GWLink
                        onClick={() => this.goToNextSubmission(history, lobQuoteURL, productCode, nextLocation)}
                        className="font-bold"
                    >
                        {nextJobNum}
                    </GWLink>
                </>
            );
        }
    }

    onWithdrawTransaction = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
            authHeader,
        } = this.props;
        this.props.modalContext.showConfirm({
            title: messages.withdrawJob,
            message: translator(localMessages.sureWithDrawQuote,
                { quoteNumber: submission.jobNumber }),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            JobService.withdrawJobByJobNumber(
                submission.jobNumber,
                authHeader
            ).then(
                () => {
                    this.updateWithDrawQuote('Withdrawn', submission);
                },
                () => {
                    this.props.modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    });
                }
            );
        }).catch(_.noop);
    };

    onViewQuote = () => {
        this.onContinueTransaction({ isReadOnly: true });
    };

    hasUwIssues = (uwIssues) => {
        return uwIssues && uwIssues.length > 0;
    };

    getUWIssuesApprovedNotificationContent = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: job },
            },
            authUserData,
        } = this.props;
        const transactionVisibleActions = this.getSubmissionToProceed();
        const translator = this.context;
        const uwutil = UWUtil(job, authUserData.userType);
        return {
            infoMessageTitle: translator(localMessages[`approvedForThis${uwutil.jobType}`]),
        };
    };

    getNotificationContent = (warningMessagesAndSuccessNotificationVisibility) => {
        const {
            successNotification: {
                showUWIssuesApprovedMsg
            }
        } = warningMessagesAndSuccessNotificationVisibility;
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
            authUserData
        } = this.props;
        const selectedVersionPublicID = _.get(submission, 'selectedVersion_Ext');
        const underwritingIssues = _.get(submission, 'underwritingIssues');
        const callingFromAPI = _.get(submission, 'callingFromAPI_Ext');
        const hasBlockingUWIssue = ErrorsAndWarningsUtil
            .quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues, selectedVersionPublicID);
        const content = {};
        if (
            submission
                && !['Bound', 'Quoted', 'Withdrawn'].includes(submission.statusCode)
                && ((['Draft'].includes(submission.statusCode) && !submission.currentVersionLocked_Ext) || !this.hasUwIssues(submission.underwritingIssues))
        ) {
            content.infoMessageTitle = translator(
                messages.quoteSuccessfullyStarted
            );
        }
        if (
            submission
            && (submission.statusCode === 'Quoted' || submission.statusCode === 'Rated')
            && (!hasBlockingUWIssue || (hasBlockingUWIssue && callingFromAPI))
        ) {
            content.infoMessageTitle = translator(
                messages.premiumQuoteCalculated
            );
        }
        if (submission && submission.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(
                messages.thisQuoteHasBeenWithDrawn
            );
        }
        if (submission && submission.statusCode === 'Bound') {
            content.infoMessageTitle = translator(localMessages.thePolicyIsRewritten);
        }
        let infoMessages = [{
            infoMessageTitle: content.infoMessageTitle,
        }];

        if (showUWIssuesApprovedMsg) {
            infoMessages = infoMessages.concat(this.getUWIssuesApprovedNotificationContent());
        }

        return {
            infoMessages: infoMessages,
            withDrawContent: translator(messages.withdrawQuote),
            continueContent: translator(messages.continueQuote),
            viewContent: translator(localMessages.viewQuote),
            copyContent: translator(localMessages.copySubmission),
        };
    };

    getIsViewTransaction = () => {
        const { fromAccountLanding, authUserData } = this.props;
        const statusCode = _.get(fromAccountLanding, 'quoteDetailsData.loadRewriteSummary.statusCode');
        if (statusCode) {
            switch (statusCode) {
                case 'Withdrawn':
                    return false;
                default:
                    return _.get(authUserData, 'permissionMap_Ext.viewsubmission');
            }
        } else {
            return _.get(authUserData, 'permissionMap_Ext.viewsubmission');
        }
    };

    getSubmissionToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
            authUserData
        } = this.props;
        const hasCreateSubmissionPermission = _.get(authUserData, 'permissionMap_Ext.createsubmission');
        const hasEditSubmissionPermission = _.get(authUserData, 'permissionMap_Ext.editsubmission');
        const hasWithDrawPermission = _.get(authUserData, 'permissionMap_Ext.withdraw');
        const hasReferToUnderWriterPermission = _.get(authUserData, 'permissionMap_Ext.ReferToUnderwriter_Ext');
        const { canWithdraw } = this.state;
        if (
            submission && (submission.statusCode === 'Withdrawn')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: false
            };
        }
        return {
            isCopySubmission: hasCreateSubmissionPermission,
            isContinueTransaction:
                hasEditSubmissionPermission && canWithdraw
                && !submission.currentVersionLocked_Ext,
            isWithdrawTransaction: canWithdraw && hasWithDrawPermission,
            // isViewTransaction: this.getIsViewTransaction() && !_.get(authUserData, 'isExternalUser_Ext'),
            isViewTransaction: false,
            // isReferToUnderWriterTransaction: hasReferToUnderWriterPermission || _.get(authUserData, 'isExternalUser_Ext')
            isReferToUnderWriterTransaction: false
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
        } = this.props;
        const policyNumberAssigned = _.get(
            submission,
            'policyNumberAssigned_Ext'
        );
        return policyNumberAssigned;
    };

    redirectToPolicy = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
        } = this.props;
        const policyNumber = _.get(
            submission,
            'policyNumber_Ext'
        );
        return (
            <Link
                to={`/policies/${policyNumber}/summary`}
                className={styles.removeLinkStyle}
            >
                {policyNumber}
            </Link>
        );
    };

    getJobStatus = (job) => {
        const translator = this.context;
        if (job.policy) {
            if (job.policy.issued) {
                return translator(gatewayMessages.issued);
            }
            if (job.status === 'Not-taken') {
                return translator(gatewayMessages.notTaken);
            }
        }
        return job.status;
    };

    goToAllQuotes = () => {
        const { history } = this.props;
        history.push({
            pathname: '/policies',
            state: {
                initStatus: 'Quotes',
            },
        });
    };

    downloadDocumentAll = async () => {
        const { history } = this.props;

        const { documentsData } = this.state;
        documentsData.map((doc) => {
            const { publicID, sessionID } = doc;

            const serviceUrl = getProxiedServiceUrl('downloadDocument');
            const templateDownloadEndpointURL = serviceUrl.concat(
                `/${publicID}?token=${sessionID}`
            );
            const params = {
                method: 'GET',
                url: templateDownloadEndpointURL,
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'arraybuffer',
            };

            const errorCallback = () => {
                history.push('/documenterror');
            };

            DocumentDownloadService.getDocument(
                templateDownloadEndpointURL,
                params,
                errorCallback
            );
        });
    };

    getStatusInfo = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
        } = this.props;
        const translator = this.context;
        const submissionStatus = this.getJobStatus(submission);
        if (
            submissionStatus.toLowerCase() === 'bound'
                && submission.policy.issued
        ) {
            return translator('gateway.directives.JobSummary.Issued');
        }
        return submissionStatus;
    };

    updateWithDrawQuote = (jobStatusCode, submission) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode,
        });
        _.set(submission, 'status', status);
        _.set(submission, 'statusCode', jobStatusCode);
        this.updateJobSummary(submission);
    };

    updateJobSummary = (submission) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary },
            },
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(submission);
        }
    };

    render() {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: submission },
            },
            authUserData,
            history,
            domainCompany
        } = this.props;

        const underwritingIssues = _.get(submission, 'underwritingIssues');
        const selectedVersionPublicID = _.get(submission, 'selectedVersion_Ext');
        const selectedPolicyPeriod = _.get(submission, 'policy.periods', [])
            .find((policyPeriod) => policyPeriod.publicID === selectedVersionPublicID);
        const hasBlockingUWIssue = ErrorsAndWarningsUtil
            .quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues, selectedVersionPublicID);

        const {
            isLoading,
            isCopying,
            documentsData,
            isShowNotification,
            referredToUnderWriter,
            retainInAgency,
            submitToWesternNational,
            showReferToUW,
            newSubmissionObj
        } = this.state;
        if (_.isEmpty(submission)) {
            return <Loader loaded={isLoading} />;
        }
        const uwutil = UWUtil(submission, authUserData.userType);

        const warningMessagesAndSuccessNotificationVisibility = SummaryUtil
            .getWarningMessagesAndSuccessNotificationVisibility(
                submission,
                authUserData,
                referredToUnderWriter,
                showReferToUW,
                UWUtil,
                this.getSubmissionToProceed()
            );

        const overrides = {
            notification: {
                message: this.getCopyMsg(newSubmissionObj, isShowNotification),
                visible: isShowNotification
            },
            quoteNotification: {
                notificationContent: this
                    .getNotificationContent(warningMessagesAndSuccessNotificationVisibility),
                transactionVisibleActions: this.getSubmissionToProceed(),
                visible: !warningMessagesAndSuccessNotificationVisibility.warningExist,
                isCopying
            },
            underwritingTable: {
                job: submission,
                jobService: WniSubmissionService,
                onUpdateJobSummary: this.updateJobSummary,
                transactionVisibleActions: this.getSubmissionToProceed(),
                referredToUnderWriter: referredToUnderWriter,
                checkReferredToUnderWriter: this.checkReferredToUnderWriter,
                hasBlockingUWIssue: hasBlockingUWIssue,
                showReferToUW: showReferToUW,
                setShowReferToUW: this.setShowReferToUW,
                warningMessagesAndSuccessNotificationVisibility:
                    warningMessagesAndSuccessNotificationVisibility
            },
            underwritingIssues: {
                uwIssues: submission.underwritingIssues,
                uwUtil: uwutil,
                noRecordsMessage: translator(messages[`noOpenUnderwriting${uwutil.jobType}`]),
                visible: !['Bound', 'Withdrawn'].includes(submission.statusCode),
            },
            summaryDetailGridRow: {
                columns: submission.statusCode === 'Quoted' || submission.statusCode === 'Bound'
                    ? ['1fr', '1fr', '1fr', '1fr'] : ['1fr', '1fr', '1fr'],
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible(),
                value: this.redirectToPolicy(),
            },
            detailGridTotalColumn: {
                visible: submission.statusCode === 'Quoted' || submission.statusCode === 'Bound',
            },
            totalPremiumId: {
                value: _.get(selectedPolicyPeriod, 'totalPremiumWithoutFullPayDiscount_Ext')
            },
            fullPayDiscountId: {
                visible: _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay',
                value: _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext')
            },
            totalCostId: {
                value: _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay'
                    ? _.get(selectedPolicyPeriod, 'totalPremiumWithFullPayDiscount_Ext')
                    : _.get(selectedPolicyPeriod, 'totalCost'),
                visible: submission.statusCode !== 'Rated' && _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay'
            },
            agencyOfRecordOrgId: {
                value: `${_.get(
                    selectedPolicyPeriod,
                    'producerCodeOfRecordOrg'
                )} (${_.get(
                    selectedPolicyPeriod,
                    'producerCodeOfRecord'
                )})`,
            },
            agencyOfServiceId: {
                value: `${_.get(
                    selectedPolicyPeriod,
                    'producerCodeOfServiceOrg'
                )} (${_.get(
                    selectedPolicyPeriod,
                    'producerCodeOfService'
                )})`,
            },
            summaryCreatedDataId: {
                value: _.get(submission, 'createTime')
            },
            summaryPolicyInceptionId: {
                value: _.get(submission, 'effectiveDate_Ext')
            },
            summaryPolicyExpirationDateId: {
                value: _.get(submission, 'policyExpirationDate_Ext')
            },
            summaryStatusdDataId: {
                value: this.getStatusInfo(),
            },
            documentcheckedList: {
                history: history,
                submission,
                renderData: [
                    {
                        subTitle: {
                            id: 'quoteandbind.pa.views.pa-policy-info.Retain in Agency',
                            defaultMessage: 'Retain in Agency',
                        },
                        data: retainInAgency
                    },
                    {
                        subTitle: translator(localMessages.WesternNational, { domainName: `${domainCompany.domainName}` }),
                        data: submitToWesternNational
                    }
                ]
            }
        };
        const resolvers = {
            resolveClassNameMap: interalStyles,
            resolveComponentMap: {
                notificationcomponent: SuccessNotification,
                underwritingcomponent: UnderwritingComponent,
                uwissuestablecomponent: UnderIssuesTableComponent,
                downloaddocumentcomponent: DownloadDocumentComponent,
                documentcheckedcomponent: DocumentCheckedComponent
            },
            resolveCallbackMap: {
                onCopySubmission: this.onCopySubmission,
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction,
                onViewTransaction: this.onViewQuote,
                redirectToPolicy: this.redirectToPolicy,
                downloadDocumentAll: this.downloadDocumentAll,
                goToAllQuotes: this.goToAllQuotes,
            },
        };
        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submission,
                id,
                path,
                overrides
            );
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submission}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

const RewriteSummary = withModalContext(RewriteSummaryWithoutModalContext);

export default withAuthenticationContext(withDependencies(['domainCompany'])(RewriteSummary));
