import { 
    SCHEDULE_ITEM_TYPES as CUSTOMER_SCHEDULE_ITEM_TYPES
} from 'customer-scheduleitems-config';
import HOSimpleScheduleItem from './HOSimpleScheduleItem/HOSimpleScheduleItem';

const SCHEDULE_ITEM_TYPES = {
    ...CUSTOMER_SCHEDULE_ITEM_TYPES,
}

export default function generateSubComponents(props) {
    const { valueType } = props.info;
    const scheduleItemType = SCHEDULE_ITEM_TYPES[valueType] || HOSimpleScheduleItem;

    return scheduleItemType(props);
}
