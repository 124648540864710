import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class SearchService {
    static search(searchRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('search'),
            'search',
            [searchRequestDTO],
            additionalHeaders
        );
    }

    static searchPartial(searchRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('search'),
            'searchPartial',
            [searchRequestDTO],
            additionalHeaders
        );
    }
}
