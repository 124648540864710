import { Icon } from '@jutro/components';
import { DropdownMenuLink } from '@jutro/router';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useWniModal } from 'wni-components-platform-react';
import { PortalConstants } from 'wni-portals-config-js';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil,
    WniProductsUtil,
} from 'wni-portals-util-js';
import { CPPLocationsService } from 'wni-capability-quoteandbind-cpp';
import { WniTableRowUtil } from 'wni-portals-util-react';
import AssociatedExposuresPopup from 'wni-capability-quoteandbind-gl-react/pages/Locations/Components/AssociatedExposures/AssociatedExposuresPopup';
import WizardPage from '../../templates/CPPWizardPage';
import CPPLocationComponent from './Components/LocationComponent/CPPLocationComponent';
import messages from './CPPLocationsPage.messages';
import metadata from './CPPLocationsPage.metadata.json5';
import styles from './CPPLocationsPage.module.scss';
import CPPLocationsUtil from './Util/CPPLocationsUtil';

const {
    CPP_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    CR_PRODUCT_CODE,
    getLobName
} = WniProductsUtil;

const LOB_NAME = getLobName(CPP_PRODUCT_CODE);

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

const GLCOVERABLES_PATH = 'lobData.generalLiability.coverables';
const CPCOVERABLES_PATH = 'lobData.commercialProperty.coverables';

function CPPLocationsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        isReadOnly,
        resetWizardDataToSnapshot,
        cppLocationService,
        isPolicyChange
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData,
        lobData: {
            [LOB_NAME]: { offerings },
        },
    } = submissionVM.value;

    const { selectedVersion_Ext: selectedVersionPublicID } = baseData;

    const selectedVersionIndex = offerings.findIndex(
        (offering) => offering.publicID_Ext === selectedVersionPublicID
    );
    const coveragesPath = `lobData.${LOB_NAME}.offerings[${selectedVersionIndex}].coverages`;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { initialValidation, onValidate, invalidFields, isComponentValid } =
        useValidation('CPPLocationsPage');
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const locationsVMPath = `lobData.${LOB_NAME}.coverables.locations`;
    const locationsVM = _.get(submissionVM, locationsVMPath);

    const unusedLocationsVMPath = `lobData.${LOB_NAME}.coverables.unusedCPPLocations`;
    const unusedLocationValues =
        _.get(submissionVM, `value.${unusedLocationsVMPath}`) || [];

    const statesVMPath = `lobData.${LOB_NAME}.coverables.stateOptions`;
    const statesValues = _.get(submissionVM, `value.${statesVMPath}`, []);
    const stateOptions = statesValues.map((item) => {
        return {
            name: translator({ id: `typekey.State.${item}` }),
            code: item,
        };
    });

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow
            ? _.get(activeRow, 'value.rowIdPath')
            : null;
        WniTableRowUtil.setTablePublicIDSelected(
            activePublicID,
            'locationTable'
        );
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const updateSubmission = useCallback((currentVM) => {
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const allLoations = _.get(submissionVM.value, locationsVMPath);
        const currentIndex = allLoations.findIndex(
            (item) => item.rowIdPath === rowIdPath
        );
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(
            newSubmissionVM.value,
            `${locationsVMPath}[${currentIndex}]`,
            currentVM.value
        );
        return newSubmissionVM;
    }, [locationsVMPath, submissionVM, viewModelService]);

    const updateCurrentRow = useCallback((rowData, updateSubmissionData) => {
        if (!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        // const {
        //     _dtoName,
        //     _xCenter,
        // } = rowData;
        // const initCurrentRow = viewModelService.create(rowData.value, _xCenter, _dtoName);
        updateCurrentRowInteranl(rowData);
        if (updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM);
        }
    }, [isReadOnly, updateSubmission]);

    const findItemVMValid = useCallback((vm) => {
        if (!vm) {
            return true;
        }
        const newVM = viewModelService.clone(vm);
        _.set(newVM, 'isUpdate', true);
        return (
            _.get(newVM, 'aspects.valid') &&
            _.get(newVM, 'aspects.subtreeValid')
        );
    }, [viewModelService]);

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        const hasIssuanceInvalidFields =
            CPPLocationsUtil.hasIssuanceInvalidFields(itemVM);
        let type;
        if (!findItemVMValid(itemVM)) {
            type = 'error';
        } else if (hasIssuanceInvalidFields) {
            type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = (
            <Icon
                id={`validationIcon${item.rowIdPath}`}
                icon={VALIDATION_ICON_MAP[type]}
                className={`wni-icon-${type}`}
            />
        );
        return WniTableRowUtil.renderCell(item.rowIdPath, iconDom);
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const renderAddressCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        return CPPLocationsUtil.getPrimaryAddressDisplayName(
            _.get(itemVM, `address`)
        );
    };

    const syncWizardData = useCallback(
        (currentVM) => {
            updateCurrentRow(currentVM, updateWizardData);
        },
        [updateCurrentRow, updateWizardData]
    );

    const viewOrEditLocation = useCallback(
        (value, index, newVM) => {
            const vm = newVM || submissionVM;
            setLoadingMask(true);
            const childrenVM = _.get(
                vm,
                `${locationsVMPath}.children`
            );
            const locationVM = childrenVM.find(
                (item) => item.value.rowIdPath === index
            );
            _.set(locationVM.value, 'isUpdate', true);
            updateCurrentRowInteranl(locationVM);
            setLoadingMask(false);
        },
        [locationsVMPath, setLoadingMask, submissionVM]
    );

    const generateValidationIssues = useCallback(
        (issues) => {
            const newValidationIssues =
                ValidationIssueUtil.getValidationIssues(issues);
            const filteredNewValidationIssues = newValidationIssues.filter(
                (issue) =>
                    issue.flowStepId !== 'Portal:WCStateSpecificInformationPage'
            );
            // updateWizardPageStickyIssues(currentStep.id, []);
            updateValidationIssues(filteredNewValidationIssues);

            const hasValidationError =
                ValidationIssueUtil.hasErrorInValidationIssueList(
                    filteredNewValidationIssues
                );
            const hasValidationWarning =
                ValidationIssueUtil.hasWarningInValidationIssueList(
                    filteredNewValidationIssues
                );
            if (hasValidationWarning && !displayWarnings) {
                updateDisplayWarnings(true);
                return false;
            }
            if (hasValidationError) {
                WindowUtil.scrollToWizardErrors();
                updateShowErrors(true);
                return false;
            }
            return true;
        },
        [displayWarnings]
    );

    const syncGLLocations = useCallback((res, newSubmissionVM) => {
        // sync locations to GL locations
        const GLLocations = _.get(res, 'gllocations');
        const GLUnusedLocations = _.get(res, 'glunusedLocations');
        if (!_.isNil(GLLocations) || !_.isNil(GLUnusedLocations)) {
            const GLUnusedLocationsVMPath = `${GLCOVERABLES_PATH}.unusedLocations`
            const GLLocationsVMPath = `${GLCOVERABLES_PATH}.locations`;
            _.set(newSubmissionVM, `value.${GLLocationsVMPath}`, GLLocations);
            _.set(newSubmissionVM, `value.${GLUnusedLocationsVMPath}`, GLUnusedLocations);
        }
    }, [])

    const syncCPLocations = useCallback((res, newSubmissionVM) => {
        // sync locations to GL locations
        const cpLocations = _.get(res, 'cplocations');
        if (!_.isNil(cpLocations)) {
            const CPLocationsVMPath = `${CPCOVERABLES_PATH}.locations`;
            _.set(newSubmissionVM, `value.${CPLocationsVMPath}`, cpLocations);
        }
    }, [])

    const updateSubmissionVMForResponse = useCallback(
        (res) => {
            const resLocationPath = 'cpplocations';
            const resUnusedLocationPath = 'unusedCPPLocations';
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(
                newSubmissionVM,
                `${locationsVMPath}.value`,
                res[resLocationPath]
            );
            _.set(
                newSubmissionVM,
                `${unusedLocationsVMPath}.value`,
                res[resUnusedLocationPath]
            );

            // sync locations to GL locations
            syncGLLocations(res, newSubmissionVM);
            // sync locations to CP locations
            syncCPLocations(res, newSubmissionVM);

            const coverages = _.get(res, 'coverages');
            if (!_.isNil(coverages)) {
                _.set(newSubmissionVM.value, coveragesPath, coverages);
            }
            const stateSpecificInfo = _.get(res, 'stateSpecificInfo');
            if (!_.isNil(stateSpecificInfo)) {
                _.set(
                    newSubmissionVM.value,
                    `lobData.${LOB_NAME}.coverables.stateSpecificInfo`,
                    stateSpecificInfo
                );
            }

            const stateSimpleInfos = _.get(res, 'stateSimpleInfos');
            if (!_.isNil(stateSimpleInfos)) {
                const CR_LOB_NAME = getLobName(CR_PRODUCT_CODE);
                _.set(
                    newSubmissionVM,
                    `value.lobData.${CR_LOB_NAME}.coverables.stateSimpleInfos`,
                    stateSimpleInfos
                );
            }


            const modifier = _.get(res, 'modifier');
            if (!_.isNil(modifier)) {
                _.set(
                    newSubmissionVM.value,
                    `lobData.${LOB_NAME}.modifiers_Ext`,
                    modifier
                );
            }
            //
            _.set(
                newSubmissionVM,
                'baseData.periodStatus',
                PortalConstants.QUOTE_STATUS_DRAFT
            );

            updateWizardSnapshot(newSubmissionVM);
            return newSubmissionVM;
        },
        [
            coveragesPath,
            locationsVMPath,
            submissionVM,
            unusedLocationsVMPath,
            updateWizardSnapshot,
            viewModelService,
        ]
    );

    const locationService = useCallback(
        async (serviceName, serviceData) => {
            setLoadingMask(true);

            const res = await cppLocationService[serviceName](
                jobID,
                sessionUUID,
                serviceData,
                authHeader
            );
            const newSubmissionVM = updateSubmissionVMForResponse(res);
            updateSelection([]);
            updateShowErrors(false);
            generateValidationIssues(res.errorsAndWarnings);

            updateCurrentRow(null);
            setLoadingMask(false);
            return newSubmissionVM;
        },
        [
            authHeader,
            cppLocationService,
            generateValidationIssues,
            jobID,
            sessionUUID,
            setLoadingMask,
            updateCurrentRow,
            updateSubmissionVMForResponse,
        ]
    );

    const addLocation = () => {
        const locationObj = CPPLocationsUtil.setDefaultLocationObj();
        const { _dtoName, _xCenter } = locationsVM;
        const locationVM = viewModelService.create(
            locationObj,
            _xCenter,
            _dtoName
        );
        const addedLocation = locationsVM.pushElement(locationVM);
        updateCurrentRow(addedLocation);
        updateWizardData(submissionVM);
        updateShowErrors(false);
    };

    const writeValue = (value, path) => {
        if (currentRow && _.get(currentRow, `${path}.value`) !== value) {
            let newValue = _.clone(value);
            const newCurrentRow = viewModelService.clone(currentRow);
            if (path === 'address') {
                const state = _.get(value, 'state');
                if (!_.includes(statesValues, state)) {
                    newValue = {
                        ...value,
                        state: null
                    }
                }
            }
            _.set(newCurrentRow.value, path, newValue);
            syncWizardData(newCurrentRow);
        }
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const cancelLocation = () => {
        resetWizardDataToSnapshot();
        syncWizardData(null);
    };

    const updateLocation = useCallback(async () => {
        if (
            !isComponentValid ||
            !currentRow.aspects.valid ||
            !currentRow.aspects.subtreeValid ||
            CPPLocationsUtil.hasIssuanceInvalidFields(currentRow)
        ) {
            handleValidation();
            return false;
        }

        // const questionSetsAnswers = _.get(currentRow.value,'locationQuestionSets[0].answers');
        // const locationQuestionSetsAnswers = CPPLocationsUtil.transferAnswerstoNull(questionSetsAnswers)
        // _.set(currentRow.value,'locationQuestionSets[0].answers', locationQuestionSetsAnswers)
        const newSubmissionVm = await locationService('updateLocation', currentRow.value);
        return newSubmissionVm;
    }, [currentRow, handleValidation, isComponentValid, locationService]);

    const removeData = async() => {
        modalApi.showConfirm({
                title: messages.removeLocationTitle,
                message: messages.removeLocationDescription,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.Ok,
                cancelButtonText: messages.Cancel,
            }).then(async (result) => {
                if (result === 'cancel' || result === 'close') {
                    return _.noop();
                }
                syncWizardData(null);
                await locationService('removeLocations', selection);
            });
    };

    const removeLocations = async () => {
        setLoadingMask(true);
        // find whether this location was used in GL
        // if yes, then open 'AssociatedExposuresPopup' 
        // else, open warning popup
        const res = await CPPLocationsService.findLocationAssociatedExposures(jobID, sessionUUID, selection, authHeader);
        setLoadingMask(false);

        if(_.get(res, 'exposures.length', 0) === 0) {
            await removeData();
            return false;
        };
        
        const componentProps = {
            removeSelection: selection,
            transferLocations: res.gllocations,
            exposures: res.exposures,
            locationService: CPPLocationsService,
            size: 'lg',
            externalData: {
                jobID,
                sessionUUID,
                authHeader,
            }
        };
        modalApi.showModal(<AssociatedExposuresPopup {...componentProps} />).then((result) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM,`${locationsVMPath}.value`,result.cpplocations);
            _.set(newSubmissionVM,`${unusedLocationsVMPath}.value`,result.unusedCPPLocations);
            _.set(newSubmissionVM,`${GLCOVERABLES_PATH}.exposures`,result.exposures);

            syncGLLocations(result, newSubmissionVM);
            updateSelection([]);
            updateShowErrors(false);
            generateValidationIssues(result.errorsAndWarnings);

            updateCurrentRow(null);
            setLoadingMask(false);
            updateWizardSnapshot(newSubmissionVM);
        }).catch(() => _.noop);
    };

    const addExistingLocation = async (existingLocations, isAll = false) => {
        setLoadingMask(true);
        const location = _.get(submissionVM, `${locationsVMPath}.value`);
        const oldLocations = _.cloneDeep(location) || [];
        const oldLocationIds = oldLocations.map((v) => v.publicID); // get old locations publicIds

        const existingLocationPublicIds = existingLocations.map(
            (item) => item.publicID
        );
        const res = await cppLocationService.addExistingLocations(
            jobID,
            existingLocationPublicIds,
            sessionUUID,
            authHeader
        );
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        setLoadingMask(false);
        if (!isAll) {
            // if add one existing location, will open this added location details
            const newLocation = _.get(
                newSubmissionVM,
                `${locationsVMPath}.children`
            ).find((vm) => !oldLocationIds.includes(vm.value.publicID));
            updateCurrentRow(newLocation);
        }
    };

    const addAllExistingLocation = async () => {
        addExistingLocation(unusedLocationValues, 'isAll');
    };

    const onNextLocation = useCallback((newmVM) => {
        const childrenVM = _.get(newmVM, `${locationsVMPath}.children`);
        let index = _.findIndex(
            childrenVM,
            (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath
        );
        if (index === childrenVM.length - 1) {
            index = 0;
        } else {
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.rowIdPath');
        syncWizardData(null);
        viewOrEditLocation(null, indexID, newmVM);
        WindowUtil.scrollToTop();
    }, [
        currentRow,
        locationsVMPath,
        syncWizardData,
        viewOrEditLocation,
    ]);

    const allLocationValid = useCallback(() => {
        const allLocationVMs = _.get(
            submissionVM,
            `${locationsVMPath}.children`
        );
        return allLocationVMs.every((vm) => findItemVMValid(vm));
    }, [findItemVMValid, locationsVMPath, submissionVM]);

    const onPageNext = useCallback(async () => {
        if (!allLocationValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        };
        const res = await cppLocationService.onPageNext(requestData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if (!isPageValid) {
            return false;
        }
        return newSubmissionVM;
    }, [
        allLocationValid,
        authHeader,
        cppLocationService,
        generateValidationIssues,
        jobID,
        sessionUUID,
        updateSubmissionVMForResponse,
    ]);

    const disableDeleteBtn = () => {
        if (_.isEmpty(selection)) {
            return true;
        }

        // find if selection has primary location
        const primaryLocation = _.get(
            submissionVM,
            `${locationsVMPath}.value`,
            []
        ).find((item) => item.isPrimary);
        const primaryLocationID = _.get(primaryLocation, 'rowIdPath');
        if (selection.includes(primaryLocationID)) {
            return true;
        }
        return false;
    };

    const renderAllUnusedLocationsMenuItem = () => {
        const retval = unusedLocationValues.map((item) => {
            const address = _.get(item, 'address', {});
            const { publicID, displayName } = address;

            return (
                <DropdownMenuLink
                    type="action"
                    onClick={() => addExistingLocation([item])}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        });

        return retval;
    };


    //---------------------
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
        },
        '@field': {
            labelPosition: 'left',
        },
        removeLocation: {
            visible: !isReadOnly,
            disabled: disableDeleteBtn(),
        },
        addLocation: {
            visible: !isReadOnly,
        },
        addExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocationValues.length === 0,
            content: renderAllUnusedLocationsMenuItem(),
        },
        addAllExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocationValues.length === 0,
        },
        locationTable: {
            path: `${locationsVMPath}.value`,
            onSelectionChange: (rows) => updateSelection(rows),
            selectedRows: selection
        },
        address: {
            renderCell: renderAddressCell,
        },
        primary: {
            renderCell: (item) => {
                return _.get(item, `isPrimary`) ? 'X' : '-';
            },
        },
        locationValidationIcon: {
            renderCell: renderValidationCell,
        },
        locationDetailContainer: {
            visible: currentRow != null,
        },
        viewOrEditLink: {
            disabled: currentRow || selection.length > 1,
            label: isReadOnly
                ? messages.cppViewLabel
                : messages.cppViewAndEditLabel,
        },
        locationDetails: {
            visible: !_.isEmpty(currentRow),
            currentRow,
            viewModelService,
            baseData,
            stateOptions,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            updateValidationIssues,
            generateValidationIssues,
            onValidate,
            showErrors,
            isReadOnly,
            extendProps: {
                jobID,
                authHeader,
                sessionUUID,
                baseData,
            },
        },
        saveButtons: {
            visible: !isReadOnly,
        },
        saveNextButton: {
            visible:
                _.get(submissionVM, `${locationsVMPath}.children`, []).length >
                1,
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addLocation,
            addAllExistingLocation,
            removeLocations,
            viewOrEditLocation,
            cancelLocation,
            saveLocation: () => {
                updateLocation().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextLocation: () => {
                updateLocation().then((vm) => {
                    if (vm) {
                        onNextLocation(vm);
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
        },
        resolveComponentMap: {
            locationcomponent: CPPLocationComponent,
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            showRequiredInfoForFasterQuote
            disableNext={!allLocationValid()}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CPPLocationsPage.propTypes = WizardPage.propTypes;
CPPLocationsPage.defaultProps = WizardPage.defaultProps;

CPPLocationsPage.propTypes = {
    ...wizardProps,
    cppLocationService: PropTypes.func,
};

CPPLocationsPage.defaultProps = {
    cppLocationService: CPPLocationsService
};
export default CPPLocationsPage;
