
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WniFormat } from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import metadata from './RecommendInputComponent.metadata.json5';

const RecommendInputComponent = (props) => {
    const {
        id,
        tabIndex,
        label,
        path: recommendPath,
        value = '',
        className,
        required,
        disabled,
        hideLabel,
        placeholder,
        availableValues,
        onValueChange: onInputValueChange,
        onSelectionChange,
        allowNew,
        showErrors,
        readOnly,
        validationMessages,
        isUpperCase,
        showLoader,
        isDropDown,
        onValueEnter
    } = props;
    const translator = useTranslator();
    const [optionsShow, updateOptionsShow] = useState(false);
    const [availableOptions, updateAvailableOptions] = useState([]);
    const [clearable, setClearable] = useState(false);
    const elementRef = useRef(null);
    renderHelper.useOnClickOutside(elementRef, () => {
        updateOptionsShow(false);
        if (isDropDown && clearable) {
            onInputValueChange('', recommendPath);
            setClearable(false);
        }
    });

    const onValueChange = (val, path) => {
        setClearable(true);
        onInputValueChange(val, path);
    };
    useEffect(() => {
        const parentDom = document.getElementById(id);
        const inputDom = parentDom && parentDom.getElementsByTagName('input')[0];
        if (inputDom) {
            inputDom.tabIndex = tabIndex;
        }
    }, []);
    useEffect(() => {
        const newOptions = { recommendCode: `Add: ${value}`, allowNew: true, value: value };
        if (allowNew && value) {
            updateAvailableOptions([...availableValues, newOptions]);
        } else {
            updateAvailableOptions([...availableValues]);
        }
    }, [allowNew, availableValues, value]);

    const onSelectionFn = useCallback((item, index) => {
        updateOptionsShow(false);
        setClearable(false);
        onSelectionChange(item, index);
    }, [onSelectionChange]);
    const generateRecommendOverrides = useCallback(() => {
        const overrides = availableOptions.map((item, index) => {
            return {
                [`lookupDataRepeat${index}`]: {
                    onClick: () => onSelectionFn(item, index)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [availableOptions, onSelectionFn]);

    const onInputFocus = () => {
        updateOptionsShow(true);
    };

    const onBlur = (e, changeValue) => {
        const { beforeValue, value: newValue } = changeValue;
        if (newValue === beforeValue) {
            return false;
        }
        if (!value || !isUpperCase) {
            return false;
        }
        const formatValue = WniFormat.directionStrFormat(value);
        onInputValueChange(formatValue, recommendPath, 'onBlur');
    };
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false,
            readOnly,
            className
        },
        '@all': {
            tabIndex: -1
        },
        formFiledConatiner: {
            ref: elementRef,
            className: `${className} lookup-recommend-container`
        },
        formFiledInput: {
            label: label.id ? translator(label) : label,
            path: recommendPath,
            value: value,
            onValueChange: onValueChange,
            required: required,
            hideLabel: hideLabel,
            placeholder: placeholder,
            disabled: disabled,
            readOnly: readOnly,
            showErrors,
            validationMessages,
            tabIndex,
            autoComplete: false
        },
        formInputButton: {
            visible: isDropDown
        },
        lookupListContent: {
            visible: optionsShow,
            className: hideLabel ? 'lookup-recommend-content left' : 'lookup-recommend-content'
        },
        lookupList: {
            visible: !showLoader,
            data: availableOptions,
        },
        syncIcon: {
            visible: showLoader
        },
        ...generateRecommendOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onEnter: onValueEnter,
            onFocus: onInputFocus,
            onBlur
        }
    };

    return (
        <div id={id}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={{ value: value }}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
        </div>

    );
};

RecommendInputComponent.propTypes = {
    id: PropTypes.string,
    tabIndex: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    path: PropTypes.string.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    hideLabel: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    availableValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    showErrors: PropTypes.bool,
    allowNew: PropTypes.bool,
    readOnly: PropTypes.bool,
    validationMessages: PropTypes.arrayOf(PropTypes.string || PropTypes.shape({})),
    isUpperCase: PropTypes.bool,
    showLoader: PropTypes.bool,
    isDropDown: PropTypes.bool,
    onValueEnter: PropTypes.func
};
RecommendInputComponent.defaultProps = {
    id: 'recommendInput',
    tabIndex: 0,
    label: '',
    value: '',
    hideLabel: false,
    placeholder: '',
    required: false,
    disabled: false,
    showErrors: false,
    allowNew: false,
    readOnly: false,
    validationMessages: [],
    isUpperCase: false,
    showLoader: false,
    isDropDown: false,
    onValueEnter: _.noop
};
export default RecommendInputComponent;
