import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChange'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class WniCustomEndorsementService {
    static getDocumentsForPolicyChange(params, additionalHeaders) {
        return processSubmission('getDocumentsForPolicyChange', params, additionalHeaders);
    }

    static getDocumentsForPolicyChangeGroupByType(params, additionalHeaders) {
        return processSubmission('getDocumentsForPolicyChangeGroupByType', params, additionalHeaders);
    }

    static getPortalAttachmentsForPolicyChange(params, additionalHeaders) {
        return processSubmission('getPortalAttachmentsForPolicyChange', params, additionalHeaders);
    }

    static addRecentlyViewedPolicyChange(jobNumber, additionalHeaders) {
        return processSubmission('addRecentlyViewedPolicyChange', [jobNumber], additionalHeaders);
    }

    static referToUnderwriter(policyChangeNumber, noteBody, additionalHeaders) {
        return processSubmission('referToUnderwriter', [policyChangeNumber, noteBody], additionalHeaders);
    }
}
