import React, { useState, useEffect, useContext, useCallback  } from "react"
import _ from "lodash";
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { TableRowUtil } from 'wnice-portals-util-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { GLIncidentsDetailService } from 'wnice-capability-claims-gl';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './GLPropertyIncidents.metadata.json5';
import messages from '../FNOLGLIncidentDetailsPage.messages';
import GLPropertyDetail from "./PropertyDetail/GLPropertyDetail";
import { getNewContactTempId } from "../utils/NewContactUtil";
import { getNewAddressTempId } from "../utils/AddressUtil";
import styles from '../FNOLGLIncidentDetailsPage.module.scss';
import { Link } from '@jutro/router';


const GLPropertyIncidents = (props) => {
    const {
        claimVM,
        relatedContactsPath,
        relatedContacts,
        lobName,
        updateWizardData,
        updateWizardSnapshot
    } = props;

    const {
        claimNumber,
    } = claimVM.value;

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { FNOLService } = useDependencies('FNOLService');
    const viewModelService = useContext(ViewModelServiceContext);
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [propertyDamagedValue, updatePropertyDamagedValue] = useState(false);
    const [currentRowIndex, updateCurrentRowIndex] = useState(-1);
    const [bakSelectedIncident, setBakSelectedIncident] = useState({});
    const propertyIncidentsPath = `lobs.${lobName}.fixedPropertyIncidents_Ext`;
    const propertyIncidentsVM = _.get(claimVM, propertyIncidentsPath);
    const propertyIncidents = _.get(claimVM.value, propertyIncidentsPath, []);
    const [propertyIncidentsData, updatePropertyIncidentsData] = useState(propertyIncidents);

    const {
        onValidate,
    } = useValidation('GLPropertyIncidents');
    const [showErrors, setShowErrors] = useState(false);
   
    useEffect(() => {
        updatePropertyDamagedValue(!_.isEmpty(propertyIncidentsData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderStateCell = (data, index, {path, typeKey}) => {
        const stateValue = _.get(data, path);
        const formatValue = stateValue ? translator({id: `${typeKey}.${stateValue}` }) : '-';
        const dom = <span className='text-breakAll'>{formatValue}</span>
        return TableRowUtil.renderCell(data.publicID, dom)
    };

    const updateClaim = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allProperties = _.get(claimVM.value, propertyIncidentsPath);
        const currentIndex = allProperties.findIndex((item) => item.publicID === publicID); 
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `${propertyIncidentsPath}[${currentIndex}]`, currentVM.value);
        return newClaimVM;
    };

    const updateCurrentRow = (rowData, updateClaimData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        updateCurrentRowInteranl(rowData);
        if(updateClaimData) {
            const newClaimVM = updateClaim(rowData);
            updateClaimData(newClaimVM)
        }
    };

    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardData);
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const updateClaimVMForResponse = (res) => {
        _.set(claimVM.value, propertyIncidentsPath, res.fixedPropertyIncidents);
        _.set(claimVM, `value.${relatedContactsPath}`, res.relatedContacts);
        updateWizardData(claimVM);
        updateSelection([]);
        updatePropertyIncidentsData(res.fixedPropertyIncidents);
        updateCurrentRowIndex(-1);
        return claimVM;
    };

    const handleAddProperty = async () => {
        const {
            _dtoName,
            _xCenter,
        } = propertyIncidentsVM;
        setLoadingMask(true);
        const newFixedPropertyIncident = await FNOLService.getOrCreateFixedPropertyIncident(claimVM.value.claimNumber, null, authHeader);
        setLoadingMask(false);
        const propertyVM = viewModelService.create(newFixedPropertyIncident, _xCenter, _dtoName);
        propertyIncidentsVM.pushElement(propertyVM);
        updateCurrentRow(propertyVM);
        updateWizardData(claimVM);
        updatePropertyIncidentsData(propertyIncidentsVM.value);
        updateCurrentRowIndex(propertyIncidentsVM.length - 1);
        setBakSelectedIncident(_.cloneDeep(propertyVM.value));
    }

    const deleteProperty = async (propertyIncidentPublicIDs) => {
        syncWizardData(null);
        setLoadingMask(true);
        const res = await GLIncidentsDetailService.removePropertyIncidents(
            claimNumber,
            propertyIncidentPublicIDs,
            authHeader
        );
        setLoadingMask(false);
        updateClaimVMForResponse(res);
    };

    const handleDeleteProperty = () => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            syncWizardData(null);
            const selectedPropertyIncidents = [];
            selection.forEach((rowIndex) => {
                selectedPropertyIncidents.push(propertyIncidentsData[rowIndex]);
            })
            const propertyIncidentPublicIDs = selectedPropertyIncidents.map((item) => item.publicID);
 
            await deleteProperty(propertyIncidentPublicIDs)
            return true;
        }, _.noop);
    };

    const cancelProperty = () => {
        const incidentPublicID = _.get(currentRow, 'publicID.value');
        if (_.isEmpty(bakSelectedIncident.location_Ext)) {
            // new injury
            deleteProperty([incidentPublicID])
        } else {
            // edit injury
            propertyIncidentsVM.value.splice(currentRowIndex, 1, bakSelectedIncident);
            updatePropertyIncidentsData(propertyIncidentsVM.value);
        }
        updateCurrentRowInteranl(null);
        updateCurrentRowIndex(-1);
    };

    const editInjury = (item) => {
        const currentIndex = propertyIncidents.findIndex((incident) => incident.publicID === item.publicID);
        const childrenVM = _.get(claimVM, `${propertyIncidentsPath}.children`);
        const injuryVM = childrenVM.find((child) => child.value.publicID === item.publicID);       
        syncWizardData(injuryVM);
        updateCurrentRowIndex(currentIndex);
        setBakSelectedIncident(_.cloneDeep(injuryVM.value));
    };

    const saveAndContinue = () => {
        const descriptionEXT = _.get(currentRow.value, 'description_EXT');
        _.set(currentRow.value, 'description', descriptionEXT);
        _.set(currentRow.value, 'publicID', currentRow.value.rowIdPath);
        const ownerContactExt = _.get(currentRow.value, 'ownerContact_Ext');
        const isNewContact = !relatedContacts.some((contact) => contact.publicID === ownerContactExt.publicID);

        const ownerContactIndex = relatedContacts.findIndex((contact) => contact.publicID === ownerContactExt.publicID);
        let newRelatedContacts
        if(isNewContact) {
            const tempContactID = getNewContactTempId();
            const newRelatedContact = {
                ...ownerContactExt,
                updated_Ext: true,
                onwerPublicID_Ext: tempContactID
            }
            newRelatedContacts = relatedContacts.concat([newRelatedContact]);
        } else {
            const newOwnerContactExt = {
                ...ownerContactExt,
                updated_Ext: true,
            }
            newRelatedContacts = relatedContacts.toSpliced(ownerContactIndex, 1, newOwnerContactExt);
        }
        _.set(claimVM.value, relatedContactsPath, newRelatedContacts);


        // update incidentAddress
        const locationExt = _.get(currentRow.value, 'location_Ext');
        const availableAddress = _.get(currentRow.value, 'availableAddress_Ext');
        const isNewIncidentAddress = !availableAddress.some((address) => address.publicID === locationExt.publicID);
        const incidentAddressIndex = availableAddress.findIndex((address) => address.publicID === locationExt.publicID);
        let incidentAddress;
        let locationPublicID;
        if (isNewIncidentAddress) {
            // New  incidentAddres
            const tempAddressID = getNewAddressTempId();
            incidentAddress = {
                ...locationExt,
                publicID: tempAddressID
            }
            locationPublicID = tempAddressID;
        } else {
            incidentAddress = availableAddress[incidentAddressIndex];
            locationPublicID = locationExt.publicID;
        }
        _.set(currentRow.value, 'locationPublicID_Ext', locationPublicID)
        _.set(currentRow.value, 'location_Ext', incidentAddress);
        updateWizardData(claimVM);
        setLoadingMask(true);
        GLIncidentsDetailService.updatePropertyIncident(
            claimNumber,
            currentRow.value,
            newRelatedContacts,
            authHeader
        )
        .then((res) => {
            updateClaimVMForResponse(res);
            setShowErrors(false);
            updateCurrentRow(null);
        })
        .catch(() => {
            modalApi.showAlert({
                title: messages.createDraftErrorTitle,
                message: translator(messages.createDraftErrorMessage),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return false;
        })
        .finally(() => {
            setLoadingMask(false);
        });
    };

    const onPropertyIncidentToggleChange = useCallback((newValue) => {
        if (newValue || propertyIncidentsData.length === 0) {
            updatePropertyDamagedValue(newValue);
        }
    }, [propertyIncidentsData]);

    const renderEditCell =  (item) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <Link className={styles.editCell} onClick={() => editInjury(item)}>
            {translator(messages.edit)}</Link>
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            const newValue = _.clone(value);
            _.set(currentRow.value, path, newValue);
            syncWizardData(currentRow);
        }
    };

    const overrides = {
        '@field': {
            labelPosition: 'left',
        },
        glPropertyIncidentQuestion: {
            value: propertyDamagedValue,
            onValueChange: onPropertyIncidentToggleChange
        },
        glPropertyIncidentsTableContainer: {
            visible: propertyDamagedValue
        },
        glPropertyIncidentsTable: {
            data: propertyIncidentsData,
            selectedRows: selection,
            onSelectionChange: (rows) => updateSelection(rows)
        },
        deleteProperties: {
            disabled: selection.length === 0
        },
        addProperty: {
            disabled: !_.isEmpty(currentRow)
        },
        propertyDetailContainer: {
            visible: !_.isEmpty(currentRow)
        },
        propertyDetails: {
            propertyVM: currentRow,
            relatedContacts,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            onDetailSave: saveAndContinue,
            onDetailCancel: cancelProperty
        }
    };
   
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderStateCell,
            renderEditCell,
            handleDeleteProperty,
            handleAddProperty
        },
        resolveComponentMap: {
            propertyDetailComponent: GLPropertyDetail
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, claimVM, fieldId, fieldPath, overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimVM}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default GLPropertyIncidents
