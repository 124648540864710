import { defineMessages } from 'react-intl';

export default defineMessages({
    referToUnderwriter: {
        id: 'wni.wc.payAsYouGo.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    dialogOk: {
        id: 'wni.wc.payAsYouGo.OK',
        defaultMessage: 'OK'
    },
    dialogCancel: {
        id: 'wni.wc.payAsYouGo.Cancel',
        defaultMessage: 'Cancel'
    },
    paygTitle: {
        id: "wni.wc.payAsYouGo.Email Address Required",
        defaultMessage: "Email Address Required"
    },
    content: {
        id: "wni.wc.payAsYouGo.content",
        defaultMessage: "Prior to submitting to underwriting, please note that a verified customer email address for Pay As You Go reporting is REQUIRED. Please enter an email address below and Refer to Underwriter for review."
    }
});
