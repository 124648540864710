import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CriminalDamagePage.metadata.json5';

function FNOLCriminalDamagePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { claimStatus } = state;
    const translator = useTranslator();

    const initialiseVM = useCallback(() => {
        const crimeTypes = {
            burglary: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'burglary'
            }),
            vandalism: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'vandalism'
            }),
            riotandcivil: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'riotandcivil'
            })
        };

        claimVM.lossCause.value = !_.isEmpty(claimVM.lossCause.value)
            ? claimVM.lossCause.value
            : crimeTypes.burglary;
        return claimVM;
    }, [claimVM]);

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('FNOLHOCriminalDamagePage');

    useEffect(
        () => {
            const nextclaimVM = initialiseVM(claimVM);
            updateWizardData(nextclaimVM);
        },
        // Call once when page gets rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getTypeOfCrime = useCallback(
        (value) => {
            _.set(claimVM, 'lossCause', value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const isClaimStatus = useCallback(() => {
        return _.get(claimVM.value, 'lossCause') === 'burglary' && !_.isEmpty(claimStatus);
    }, [claimStatus, claimVM]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);
    const overrides = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        typeOfCrimeID: {
            value: _.get(claimVM.value, 'lossCause'),
            onValueChange: getTypeOfCrime,
            showOptional: true
        }
    };
    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLCriminalDamagePage.propTypes = wizardProps;
export default withRouter(FNOLCriminalDamagePage);
