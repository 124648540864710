import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentAmount: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Payment Amount',
        defaultMessage: 'Payment Amount'
    },
    paymentMethod: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Payment Method',
        defaultMessage: 'Payment Method'
    },
    dueDayOfMonth: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Due Day of Month',
        defaultMessage: 'Due Day of Month'
    },
    paymentPlan: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    primaryPayer: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Primary Payer',
        defaultMessage: 'Primary Payer'
    },
    applyToOtherQuotes: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Apply to other quotes',
        defaultMessage: 'Apply to other quotes: (Select all that apply)'
    },
    newPersonTitle: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.New Person Details',
        defaultMessage: 'New Person Details'
    },
    dialogOk: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.OK',
        defaultMessage: 'OK'
    },
    dialogCancel: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Cancel',
        defaultMessage: 'Cancel'
    },
    addPaymentMethodTitle: {
        id: 'wni.gateway.PaymentPage.PaymentDetails.Add Payment Method',
        defaultMessage: 'Add Payment Method',
    },
    //
    autoPayWarningMsg: {
        id: 'wni.gateway.PaymentPage.You have selected an existing account. The Due Day of Month has been updated to the same Day as the existing billing account. If you prefer a different due date, please select the appropriate day.',
        defaultMessage: 'You have selected an existing account. The Due Day of Month has been updated to the same Day as the existing billing account. If you prefer a different due date, please select the appropriate day.'
    },
});
