import { defineMessages } from 'react-intl';

export default defineMessages({
    rtDummyPage: {
        id: 'wni.quoteandbind.rt.wizard.step.Dummy Page',
        defaultMessage: 'Dummy Page',
    },
    rtPolicyDetialsPage: {
        id: "wni.quoteandbind.rt.wizard.step.Policy Details Page",
        defaultMessage: 'Policy Details Page'
    },
    operatorPage: {
        id: 'wni.quoteandbind.rt.wizard.step.Operator',
        defaultMessage: 'Operator',
    },
    // rtQuotePage: {
    //     id: 'wni.quoteandbind.rt.wizard.step.Quote & Prices',
    //     defaultMessage: 'Quote & Prices',
    // }
});
