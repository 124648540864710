import { defineMessages } from 'react-intl';

export default defineMessages({
    ConditionsandExclusions: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Conditions and Exclusions",
        "defaultMessage": "Conditions and Exclusions"
    },
    AddConditionsandExclusions: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Add Conditions and Exclusions",
        "defaultMessage": "Add Conditions & Exclusions"
    },
});