const APP_CACHE_DATA_KEY_PREFIX = 'ACDK_';


/**
 * A place to track keys used in updateAppCacheData
 */
const AppCacheDataConstants = {
    documentForDocUID: `${APP_CACHE_DATA_KEY_PREFIX}documentForDocUID`,
};

/**
 * Checks whether the <em>cacheKey</em> is valid for updateAppCacheData
 * @param {string} cacheKey
 * @returns {boolean}
 */
function isAppCacheDataKey(cacheKey) {
    if (!cacheKey) {
        return false;
    }
    return Object.values(AppCacheDataConstants).includes(cacheKey);
}

const AppCacheConstants = {
    ...AppCacheDataConstants,
    isAppCacheDataKey,
};

export default AppCacheConstants;
