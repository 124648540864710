

import React from 'react';
import { R1Config } from 'wni-portals-config-js';
import { Button } from '@jutro/legacy/components';

const { pageConfig: defaultPageConfig } = R1Config.WniTableConfig;

function setTableRowClass(type, tableID) {
    let currentDom
    if(tableID) {
        const tableDom = document.getElementById(tableID);
        currentDom = tableDom.getElementsByClassName('highlight');
    } else {
        currentDom = document.querySelectorAll('.highlight');
    }
    if (currentDom.length > 0) {
        Array.from(currentDom).forEach((v) => {
            const parentsDom = v.closest('.rt-tr-group');
            parentsDom.classList.add(type);
        });
    }
}

function removeTableRowClass(type, tableID) {
    let currentDom
    if(tableID) {
        const tableDom = document.getElementById(tableID);
        currentDom = tableDom.getElementsByClassName('highlight');
    } else {
        currentDom = document.querySelectorAll('.highlight');
    }
    if (currentDom.length > 0) {
        Array.from(currentDom).forEach((v) => {
            const parentsDom = v.closest('.rt-tr-group');
            parentsDom.classList.remove(type);
        });
    }
}

function selectedTableRow(ev, type) {
    const currentDom = ev.currentTarget;
    const parentDom = currentDom.offsetParent;
    const hastypeClassDom = parentDom.getElementsByClassName(type);

    if (Array.from(currentDom.classList).includes(type)) {
        currentDom.classList.remove(type);
        return false;
    }
    if (hastypeClassDom.length > 0) {
        Array.from(hastypeClassDom).forEach((v) => {
            v.classList.remove(type);
        });
    }
    currentDom.classList.add(type);
    return false;
}

function selectedTableRowByRowDOM(publicID, data, tableID) {
    const tableDom = document.getElementById(tableID);
    if (!tableDom) return false;
    const index = data.findIndex(
        (item) => item.publicID === publicID
    );
    const AllRowDom = tableDom.getElementsByClassName('rt-tr-group');
    const currentDom = tableDom.getElementsByClassName('activeRow')[index];
    if (!currentDom) {
        return false;
    }
    const rowDOM = currentDom.closest('.rt-tr-group');
    Array.from(AllRowDom).forEach((v) => {
        v.classList.remove('selected');
    });
    rowDOM.classList.add('selected');
    return true;
}

function setTablePublicIDSelected(publicID, tableID) {
    const tableDom = document.getElementById(tableID);
    if (!tableDom) return false;
    const AllRowDom = tableDom.getElementsByClassName('rt-tr-group');
    Array.from(AllRowDom).forEach((v) => {
        v.classList.remove('selected');
    });
    if (!publicID) return false;
    const currentDom = tableDom.getElementsByClassName(`rowId${publicID}`)[0];
    if (!currentDom) {
        return false;
    }
    const rowDOM = currentDom.closest('.rt-tr-group');
    rowDOM.classList.add('selected');
    return true;
}

function renderCell(id, displayName) {
    const rowId = `rowId${id}`;
    return <span className={rowId}>{displayName}</span>;
}

function renderViewAndEdit(row, index, componentProps) {
    const { disabled, onClick, label} = componentProps;
    return (
        <Button
            className="wni-button-link"
            disabled={disabled}
            onClick={() => onClick(row, index)}
        >
            {label}
        </Button>
    );
}

export default {
    setTableRowClass,
    removeTableRowClass,
    selectedTableRow,
    selectedTableRowByRowDOM,
    setTablePublicIDSelected,
    renderCell,
    renderViewAndEdit,
    defaultPageConfig
};
