import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
    WindowUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import WizardPage from '../../templates/HOWizardPage';

import styles from './HOCoveragesPage.module.scss';
import metadata from './HOCoveragesPage.metadata.json5';
// import messages from './HOCoveragesPage.messages';

import HOPrimaryCoverages from './CoveragesSection/HOPrimaryCoverages/HOPrimaryCoverages';
import HOCommonOptionalCoverages from './CoveragesSection/HOCommonOptionalCoverages/HOCommonOptionalCoverages';
import HOOtherOptionalCoverages from './CoveragesSection/HOOtherOptionalCoverages/HOOtherOptionalCoverages';
import HOExclusions from './CoveragesSection/HOExclusions/HOExclusions';
import HOCoverageUtil from './util/HOCoverageUtil';
import HOCovCoverageFormContext from './context/HOCovCoveragesFormContext';

function HODPCoveragesBasePage(props) {
    const {
        // currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        updateWizardData,
        lobName,
        coveragesConfig,
        coveragesService,
        componentMapOverride,
        DPOverrideProps,
    } = props;

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [showErrors, setShowErrors] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const {
        initialValidation,
        isComponentValid,
        // disregardFieldValidation,
        onValidate,
        registerComponentValidation,
    } = useValidation('HOCoveragesPage');
    const { authHeader } = useAuthentication();

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            [lobName]: {
                offerings,
                householdInfoDTO_Ext: {
                    coveragesForm
                }
            }
        },
        jobID,
        quoteID,
        sessionUUID,
        // errorsAndWarnings
    } = submissionVM.value;

    // const [errorsAndWarnings, updateErrorsAndWarnings] = useState([]);
    const [validationIssues, updateValdationIssues] = useState([]);

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const coverages = _.get(submissionVM.value, lobCoveragesPath);

    const [openedAccordions, setOpendedAccordions] = useState([
        'primaryCoverages',
        'commonOptionalCoverages'
    ]);
    const {
        coverages: {
            primaryCoverages_Ext: primaryCoverages = [],
            commonOptionalCoverages_Ext: commonOptionalCoverages = [],
            otherOptionalCoverages_Ext: otherOptionalCoverages = []
        } = {}
    } = selectedVersion;
    const hasCoverageInvalid = useCallback(() => {
        const hasPrimaryInvalidCoverage = HOCoverageUtil.hasInvalidCoverage(primaryCoverages, coveragesConfig);
        const hasCommonOptionalInvalidCoverage = HOCoverageUtil.hasInvalidCoverage(commonOptionalCoverages, coveragesConfig);
        const hasOtherOptionalInvalidCoverage = HOCoverageUtil.hasInvalidCoverage(otherOptionalCoverages, coveragesConfig);
        const hasInvalidCoverage = hasPrimaryInvalidCoverage
            || hasCommonOptionalInvalidCoverage
            || hasOtherOptionalInvalidCoverage;
        return !hasInvalidCoverage
    }, [primaryCoverages, commonOptionalCoverages, otherOptionalCoverages, coveragesConfig]);

    useEffect(() => {
        registerComponentValidation(hasCoverageInvalid)
    }, [hasCoverageInvalid, registerComponentValidation]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        //  Before click next button, only display servicing issue
        const newValidationIssues = showErrors ? 
            _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason') :
            _.uniqBy(ErrorsAndWarningsUtil.getServerIssues(errorsAndWarnings), 'reason');
        updateValdationIssues(newValidationIssues);
    }, [updateValdationIssues, showErrors]);

    const updateWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardData(newSubmissionVM);
    }, [updateWizardData]);

    const onPageNext = async () => {
        setLoadingMask(true)
        const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const response = await coveragesService.updateCoverages(
            quoteID || jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const newErrorsAndWarnings = _.get(response, 'errorsAndWarnings');
        const lobCoverages = _.get(response, lobName);
        setLoadingMask(false)
        _.set(submissionVM.value, lobCoveragesPath, lobCoverages)
        
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings), 'reason');
        updateValdationIssues(newValidationIssues);

        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewValidationIssue = ErrorsAndWarningsUtil
            .hasNewValidationIssue(validationIssues, newValidationIssues);
        
        if (hasErrorIssues || hasNewValidationIssue) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }

    const handleValidation = () => {
        setShowErrors(true)
        return false
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            hoPrimaryCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                onValidate,
                showErrors,
                updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                isEditing,
                setIsEditing,
                lobName,
                coveragesConfig,
                coveragesService,
            },
            hoCommonOptionalCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                showErrors,
                isEditing,
                lobName,
                coveragesConfig,
                coveragesService,
                // updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
            },
            hoOtherOptionalCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                // updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                showErrors,
                isEditing,
                lobName,
                coveragesConfig,
                coveragesService,
            },
            hoExclusions: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                showErrors,
                isEditing,
                lobName,
                coveragesConfig,
                coveragesService,
                // updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
            },
        };
    }, [
        openedAccordions,
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        onValidate,
        showErrors,
        updateWizardDataToDraft,
        updateErrorsAndWarningsForCovComponent,
        isEditing,
        lobName,
        coveragesConfig,
        coveragesService,
    ]);

    //---------------------
    const overrideProps = generateOverrides();

    const overrides = _.mergeWith(overrideProps, DPOverrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            hoPrimaryCoverages: HOPrimaryCoverages,
            hoCommonOptionalCoverages: HOCommonOptionalCoverages,
            hoOtherOptionalCoverages: HOOtherOptionalCoverages,
            hoExclusions: HOExclusions,
            ...componentMapOverride
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={isEditing}
            disablePrevious={isEditing}
            disableCancel={isEditing}
            onNext={isComponentValid ? onPageNext : handleValidation}
            alwaysCallOnNext
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={validationIssues}
        >
            <HOCovCoverageFormContext.Provider value={coveragesForm}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrides}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </HOCovCoverageFormContext.Provider>
        </WizardPage>
    );
}

HODPCoveragesBasePage.propTypes = wizardProps;
export default HODPCoveragesBasePage;
