import React from 'react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PolicyDetailsPage.metadata.json5';

function PolicyDetailsPage(props) {
    const { initialValidation, isComponentValid, onValidate } = useValidation('PolicyDetailsPage');
    const { wizardData: submissionVM, updateWizardData } = props;

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        coverageStartDate: {
            minDate: LocalDateUtil.today()
        }
    };

    return (
        <WizardPage
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

PolicyDetailsPage.propTypes = wizardProps;
export default PolicyDetailsPage;
