


import React from 'react';
import { IMAAISCommonFormsPage } from 'wni-capability-quoteandbind-im-react';


const IMAAISCommonFormsChangePage = (props) => {
    return <IMAAISCommonFormsPage {...props} isPolicyChange />
};
export default IMAAISCommonFormsChangePage
