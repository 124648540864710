import React, {
    Fragment,
    useContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useErrors, ErrorBoundary } from '@xengage/gw-portals-error-react';
import { WizardUtil } from 'wni-portals-util-js';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as customMessages } from 'wni-platform-translations';
import { WizardContext, WizardPage } from '@xengage/gw-portals-wizard-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardErrorContext } from 'wni-portals-wizard-react';
// import { BaseWizardPage } from 'wni-portals-wizard-react';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { useAuthentication } from '@xengage/gw-digital-auth-react';


import { HOWizardPage } from 'wni-capability-quoteandbind-ho-react';
/**
 * Solution Proposals for handling onNext errors:
 * 1) Each page calls WniCommonQuoteService.getErrorsAndWarnigns() on its own onNext,
 *      and manullay update wizardPageData[wizardPageErrorsAndWarnings]
 * 2) Set "checkErrorsAndWarningsOnPageNext" on HOWizardPage to let it handle the task automatically;'
 *      Note that similar feature could be introduced for page initialization, e.g. checkErrorsAndWarningsOnPageInit
 * 3) 
 * 
 * @param {object} props 
 * @returns {function}
 */
function DPWizardPage(props) {
    const translator = useTranslator();
    const wizardContext = useContext(WizardContext);
    const wizardErrorContext = useContext(WizardErrorContext);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [showErrors, updateShowErrors] = useState(false);
    const { authHeader } = useAuthentication();

    // const {
    //     wizardData: submissionVM,
    //     isSkipping,
    //     currentStep: { id: currentPageId },
    //     // wizardPageData,
    //     // updateWizardPageData,
    //     markFollowingWizardStepsUnvisited,
    // } = wizardContext;

    // const {
    //     //
    //     updateShowRequiredInfo,
    //     // Page Level
    //     updateWizardPageIssues,
    //     updateWizardPageStickyIssues,
    //     getWizardPageIssues,
    //     getWizardPageStickyIssues,
    //     // Wizard Level
    //     updateWizardIssues,
    // } = wizardErrorContext;

    // const {
    //     // these two flags will update pageLevelValidationIssues
    //     checkErrorsAndWarningsOnPageNext,
    //     checkErrorsAndWarningsOnPageInit,
    //     //
    //     showRequiredInfoForFasterQuote,
    //     // pagelLevelErrorsAndWarnings,
    //     // wizardLevelErrorsAndWarnings,
    //     //
    //     pageLevelValidationIssues,
    //     // pageLevelStickyValidationIssues,
    //     // wizard level issues are always sticky
    //     // wizardLevelValidationIssues, // it makes little sense to update wizardLevel issues here
    //     // =============================
    //     onNext: originalOnPageNext,
    //     ...otherProps
    // } = props;

    
    // // const {

    // // } = otherProps;

    // const {
    //     jobID, sessionUUID,
    // } = submissionVM.value;

    // const existingPageLevelValidationIssues = getWizardPageIssues(currentPageId);
    // const existingPageLevelStickyValidationIssues = getWizardPageStickyIssues(currentPageId);
    // const hasPageLevelStickyValidationIssues = !_.isEmpty(existingPageLevelStickyValidationIssues);

    // //
    // const retrieveAndUpdateErrorsAndWarningsForCurrentPage = useCallback(async () => {
    //     const pageIssues = await WniCommonQuoteService.getErrorsAndWarnings(jobID, sessionUUID,
    //         currentPageId, authHeader);
    //     //
    //     // To be put into wizardPageIssues
    // }, []);

    // useEffect(() => {
    //     if (isSkipping) {
    //         return;
    //     }
    //     if (checkErrorsAndWarningsOnPageInit) {
    //         retrieveAndUpdateErrorsAndWarningsForCurrentPage();
    //     }
    // }, [checkErrorsAndWarningsOnPageInit, isSkipping]);

    // // ========================================================
    // useEffect(() => {
    //     updateShowRequiredInfo(showRequiredInfoForFasterQuote);
    // }, [showRequiredInfoForFasterQuote]);

    // useEffect(() => {
    //     updateWizardPageIssues(currentPageId, pageLevelValidationIssues);
    // }, [pageLevelValidationIssues]);

    // useEffect(() => {
    //     if (hasPageLevelStickyValidationIssues) {
    //         markFollowingWizardStepsUnvisited();
    //     }
    // }, [hasPageLevelStickyValidationIssues]);

    // // useEffect(() => {
    // //     updateWizardPageStickyIssues(currentPageId, pageLevelStickyValidationIssues);
    // // }, [pageLevelStickyValidationIssues]);

    // // useEffect(() => {
    // //     updateWizardIssues(wizardLevelValidationIssues);
    // // }, [wizardLevelValidationIssues]);
    // // --------------------------------------------------------

    // const onNext = useCallback((wizardData) => {
    //     updateWizardPageStickyIssues(currentPageId, []);
    //     return originalOnPageNext(wizardData);
    // }, [originalOnPageNext]);

    // // ================================
    // // const overrideProps = {

    // // };
    return (
        <HOWizardPage {...props}
        />
        
    );
};

DPWizardPage.propTypes =  {
    ...WizardPage.propTypes,
    //
    checkErrorsAndWarningsOnPageNext: PropTypes.bool,
    checkErrorsAndWarningsOnPageInit: PropTypes.bool,
    //
    showRequiredInfoForFasterQuote: PropTypes.bool,
    //
    pageLevelValidationIssues: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        reason: PropTypes.string,
    })),
    // pageLevelStickyValidationIssues: PropTypes.arrayOf(PropTypes.shape({
    //     type: PropTypes.string,
    //     reason: PropTypes.string,
    // })),
    // wizard level issues are always sticky
    // wizardLevelValidationIssues: PropTypes.arrayOf(PropTypes.shape({
    //     type: PropTypes.string,
    //     reason: PropTypes.string,
    // })),
};
DPWizardPage.defaultProps = {
    ...WizardPage.defaultProps,
    //
    checkErrorsAndWarningsOnPageNext: false,
    checkErrorsAndWarningsOnPageInit: false,
    //
    showRequiredInfoForFasterQuote: false,
    //
    pageLevelValidationIssues: [],
    // pageLevelStickyValidationIssues: [],
    // wizardLevelValidationIssues: [],
};
export default DPWizardPage;
