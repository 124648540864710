import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { QuoteUtil, ValidationIssueUtil, WindowUtil } from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { GLUnmannedAircraftService } from 'wni-capability-quoteandbind-gl';
import { Icon } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { PortalConstants } from 'wni-portals-config-js';

import WizardPage from '../../templates/GLWizardPage';
import UnmannedAircraftComponent from './components/UnmannedAircraftComponent';
import metadata from './GLUnmannedAircraftPage.metadata.json5';
import messages from './GLUnmannedAircraftPage.messages';
import { generateStringOptions, generateObjectOptions } from './config/unmannedAircraft.util';

const PATH = 'lobData.generalLiability.coverables.unmannedAircrafts';
const CHILDREN_PATH = `${PATH}.children`;
const DTO_PATH = 'wni.edge.capabilities.quote.lob.generalliability.dto.GLUnmannedAircraftDTO';

const xCenter = 'pc';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

function GLUnmannedAircraftPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        resetWizardDataToSnapshot,
        readOnly,
        currentStep,
        markFollowingWizardStepsUnvisited,
        //
        checkRequiredForIssuance,
        //
        // onPreQuotePageNext,
        isPolicyChange,
    } = props;

    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const { authHeader } = useAuthentication();

    const {
        lobData: {
            generalLiability: {
                coverables: {
                    uaTypeFilterSetMaps: {
                        uaTypeFilterSet,
                        locationFilterMaps,
                        sublineFilterMaps,
                        yearFilterMaps
                    } = {},
                    unmannedAircrafts = []
                } = {}
            },
        },
        jobID,
        sessionUUID,
    } = submissionVM.value;
    const viewModelService = useContext(ViewModelServiceContext);
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);

    const [showErrors, updateShowErrors] = useState(false);
    const [currentRow, updateCurrentRow] = useState(null);
    const [unmannedAircraftClauses, updateUnmannedAircraftClauses] = useState()
    const [selection, updateSelection] = useState([]);
    const [isEditing, setIsEditing] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [typeFilterChoice, updateTypeFilterChoice] = useState(null);
    const [filterValue, updateFilterValue] = useState(null);

    const { initialValidation, onValidate, registerComponentValidation, isComponentValid, invalidFields } = useValidation('GLUnmannedAircraftPage');

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'tableField');
    };

    const updateSubmission = (currentVM) => {
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const allData = _.get(submissionVM.value, PATH);
        const currentIndex = allData.findIndex((item) => item.rowIdPath === rowIdPath); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRow(rowData);
            return false;
        }
        const initCurrentRow = viewModelService.create(rowData.value, xCenter, DTO_PATH);
        
        updateCurrentRow(initCurrentRow);
        if(updateSubmissionData && !readOnly) {
            const newSubmissionVM = updateSubmission(initCurrentRow);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const updateWizardSnapshotToDraft = useCallback((newSubmissionVM) => {
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardSnapshot(newSubmissionVM);
    }, [updateWizardSnapshot]);

    const updateWizardDataToDraft = useCallback((newSubmissionVM) => {
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        updateWizardData(newSubmissionVM);
    }, [updateWizardData]);
  
    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshotToDraft);
    };
    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardDataToDraft);
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [invalidFields]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const updateUnmannedAircraftClausesAndClearPages = useCallback((clausesParam) => {
        updateUnmannedAircraftClauses(clausesParam);
        markFollowingWizardStepsUnvisited();
    }, [updateUnmannedAircraftClauses]);

    const addItem = async() => {
        // old data
        const allData = _.get(submissionVM, `${PATH}.value`, []);

        // add service
        setLoadingMask(true);
        const res = await GLUnmannedAircraftService.addUnmannedAircraft(jobID, sessionUUID, authHeader);
        setLoadingMask(false);
        if(!res.unmannedAircraft) {
            const validationMessages = _.get(res, 'errorsAndWarnings.serverIssues_Ext.displayMssages', []);
            updateValidationIssues(validationMessages)
            return false;
        }
        updateUnmannedAircraftClausesAndClearPages(res.unmannedAircreaftClauses)
        // update submission
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${PATH}.value`, [...allData, res.unmannedAircraft]);
        updateWizardSnapshot(newSubmissionVM);
        updateShowErrors(false);

         // if find new vehicle, this is add new data
         const newItem= viewModelService.create(res.unmannedAircraft, xCenter, DTO_PATH);
         updateCurrentRow(newItem);

    };

    const removeItem = () => {
        modalApi.showConfirm({
            title: messages.removeTitle,
            message: messages.removeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            updateCurrentRow(null);
            
            setLoadingMask(true);
            const res = await GLUnmannedAircraftService.removeUnmannedAircraft(jobID, sessionUUID, selection, authHeader);
    
            // update submission
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, `${PATH}.value`, res.unmannedAircrafts);
            updateWizardSnapshot(newSubmissionVM);
            updateShowErrors(false);
            setLoadingMask(false);
        })
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };
  
    const viewAndEdit = async(item) => {
        const currentPublicID = _.get(currentRow, 'value.publicID');
        if(currentPublicID === item.publicID) {
            return false;
        }
        setLoadingMask(true);
        const res = await GLUnmannedAircraftService.viewAndEdit(jobID, sessionUUID, item.publicID, authHeader);
        const initCurrentRow = viewModelService.create(res.unmannedAircraft, xCenter, DTO_PATH);
        updateUnmannedAircraftClauses(res.unmannedAircreaftClauses)
        updateCurrentRow(initCurrentRow);
        generateValidationIssues();
        setLoadingMask(false);
    };

    const cancel = () => {
        updateCurrentRow(null);
    };

    const updateUnmannedAircraft = async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        setLoadingMask(true);
        const res = await GLUnmannedAircraftService.saveUnmannedAircraft(jobID, sessionUUID, currentRow.value, authHeader);
        _.set(currentRow, 'value', res.unmannedAircraft);
        syncWizardDataSnapshot(currentRow);
        setLoadingMask(false);
        return true;
    };

    const onNextUnmannedAircraft = () => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        let index = _.findIndex(childrenVM, (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const nextItem = _.get(childrenVM[index], 'value');
        syncWizardData(null);
        viewAndEdit(nextItem)
        WindowUtil.scrollToTop()
    };


    const findItemVMValid = (vm) => {
        const vmValid =  _.get(vm, 'aspects.valid') && _.get(vm, 'aspects.subtreeValid');
        const displayableValid = _.get(vm.value, 'displayableValid')
        const clauseValid = _.get(vm.value, 'unmannedAircraftClausesValid')
        return vmValid && displayableValid && clauseValid
    };

    const allDataValid = () => {
        const allDataVMs = _.get(submissionVM, CHILDREN_PATH);
        return allDataVMs.every((vm) => findItemVMValid(vm));
    };
    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        let type;
        const isItemValid = findItemVMValid(itemVM);
        if(isItemValid) {
            type = 'success';
            // const issuacneInValidField = generateIssuanceValid(itemVM);
            // if(_.isEmpty(issuacneInValidField)) {
            //     type = 'success';
            // } else {
            //     type = 'warning';
            // }
        } else {
            type = 'error';
        }
        return <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
    };

    

    const onPageNext = async() => {
        if(!allDataValid()) {
            return false;
        }

        const {
            onPreQuotePageNext, // = onDefaultPageNext,
        } = props;
        
        const requestData = {
            jobID,
            sessionUUID
        }
        const res = await GLUnmannedAircraftService.onPageNext(requestData, authHeader);
        // _.set(submissionVM.value, PATH, res.exposures);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }

        //
        let retval = submissionVM;
        if (_.isFunction(onPreQuotePageNext)) {
            retval = await onPreQuotePageNext();
        }
        return retval;
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(currentRow.value, path, undefined);
            } else {
                _.set(currentRow.value, path, value);
            }
            syncWizardData(currentRow);
        }
    };

    const filterUnmannedAircrafts = async(newFilterValue) => {
        const initFilterValue = newFilterValue || filterValue;
        setLoadingMask(true);
        const res = await GLUnmannedAircraftService.filterUnmannedAircrafts(jobID, sessionUUID, typeFilterChoice, initFilterValue, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${PATH}.value`, res.unmannedAircrafts);
        updateWizardData(newSubmissionVM);
        setLoadingMask(false);
    };

    const filterValueChange = (val) => {
        if(val === filterValue) {
            return false;
        }
        updateFilterValue(val);
        filterUnmannedAircrafts(val);
    };

    const filterOnBlur = (e, {beforeValue, value}) => {
        if(value === beforeValue){
            return false;
        }
        filterUnmannedAircrafts()
    };

    const typeFilterChoiceChange = (val) => {
        if(val !== typeFilterChoice) {
            updateFilterValue(null)
        }
        updateTypeFilterChoice(val)
    };

    const overrideProps = {
        '@field': {
            readOnly
        },
        addAction: {
            visible: !readOnly
        },
        removeAction:{
            visible: !readOnly,
            disabled: _.isEmpty(selection)
        },
        filterBy: {
            availableValues: generateStringOptions(uaTypeFilterSet),
            onValueChange: (val) => typeFilterChoiceChange(val),
            value: typeFilterChoice
        },
        filterByManufacturer: {
            visible: typeFilterChoice === uaTypeFilterSet[0],
            onValueChange: (val) => updateFilterValue(val),
            value: filterValue,
            onBlur: filterOnBlur
        },
        filterByModel:{
            visible: typeFilterChoice === uaTypeFilterSet[1],
            onValueChange: (val) => updateFilterValue(val),
            value: filterValue,
            onBlur: filterOnBlur
        },
        filterBySubline: {
            visible: typeFilterChoice === uaTypeFilterSet[2],
            availableValues: generateStringOptions(sublineFilterMaps),
            onValueChange: filterValueChange,
            value: filterValue
        },
        filterByYear: {
            visible: typeFilterChoice === uaTypeFilterSet[5],
            availableValues: generateStringOptions(yearFilterMaps),
            onValueChange: filterValueChange,
            value: filterValue
        },
        filterByLocation: {
            visible: typeFilterChoice === uaTypeFilterSet[3],
            availableValues: generateObjectOptions(locationFilterMaps),
            onValueChange: filterValueChange,
            value: filterValue
        },
        filterByIdentificationCode: {
            visible: typeFilterChoice === uaTypeFilterSet[4],
            onValueChange: (val) => updateFilterValue(val),
            value: filterValue,
            onBlur: filterOnBlur
        },
        tableField: {
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: readOnly ? 'none' : 'multi',
            selectedRows: selection,
        },
        detailContainer: {
            visible: !!currentRow && !!unmannedAircraftClauses
        },
        detailsField: {
            currentRow,
            onValueChange: writeValue,
            unmannedAircraftClauses: unmannedAircraftClauses,
            updateUnmannedAircraftClauses: updateUnmannedAircraftClausesAndClearPages,
            wizardData: submissionVM,
            updateWizardData: updateWizardDataToDraft,
            syncWizardData,
            syncWizardDataSnapshot,
            generateValidationIssues,
            registerComponentValidation,
            onValidate,
            isEditing,
            setIsEditing,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        },
        saveNextButton: {
            visible: !readOnly && unmannedAircrafts.length > 1,
        },
        saveButton: {
            visible: !readOnly
        },
        viewAndEditLink: {
            label: readOnly ? messages.viewLabel : messages.viewAndEditLabel
        }
    };

    const resolvers = {
        callbackMap: {
            viewAndEdit,
            cancel,
            removeItem,
            addItem,
            renderValidationCell,
            saveAndClose: () => {
                updateUnmannedAircraft().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNext: () => {
                updateUnmannedAircraft().then((valid) => {
                    if (valid) {
                        onNextUnmannedAircraft();
                    }
                });
            },
        },
        componentMap: {
            detailscomponent: UnmannedAircraftComponent,
        },
    };
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            disableNext={!allDataValid()}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            isPolicyChange={isPolicyChange}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </WizardPage>
    );
}

export default GLUnmannedAircraftPage;
