import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import CACoverageUtil from '../../../Coverages/util/CACoverageUtil';
import CoverageContext from '../../../Coverages/context/CACoverageContext';
import CAClausesComponentVM from '../../../Coverages/components/CAClausesComponentVM/CAClausesComponentVM';
import CASingleClauseComponentVM from '../../../Coverages/components/CASingleClauseComponentVM/CASingleClauseComponentVM';
import metadata from './CAVehicleConditions.metadata.json5';

function CAVehicleConditions(props) {
    const {
        submissionVM,
        updateWizardData,
        onValidate,
        showErrors,
        isEditing,
        setIsEditing,
        coveragesService,
        loadingClauses,
        setLoadingClauses,
        vehicleCoveragesVM,
        setVehicleCoveragesVM,
        vehicleVM,
        updateVehicleVM,
        generateValidationIssues,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const caVehicleCoverages = vehicleCoveragesVM.value

    const { authHeader } = useAuthentication();

    const getVehicleConditions = useCallback(() => {
        const Conditions = _.get(caVehicleCoverages, `conditions`, []);
        return Conditions;
    }, [caVehicleCoverages]);

    const vehicleConditionsVMPath = `conditions`;
    const ConditionsPath = `conditions`;

    const vehicleConditions = getVehicleConditions();

    if (!vehicleConditions.some((clause) => clause.selected)) {
        return null
    }

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = CACoverageUtil.setClauseValue(vehicleConditions, vehicleConditionsVMPath, value, changedPath);
            const newCAVehicleCoveragesVM = _.clone(vehicleCoveragesVM)
            _.set(newCAVehicleCoveragesVM.value, ConditionsPath, newCoverages);
            setVehicleCoveragesVM(newCAVehicleCoveragesVM);
            setIsEditing(false);
            return newCAVehicleCoveragesVM;
        },
        [
            vehicleConditions,
            vehicleConditionsVMPath,
            vehicleCoveragesVM,
            ConditionsPath,
            setVehicleCoveragesVM,
            setIsEditing
        ]
    );

    const updateVMToServer = useCallback(async (caVehicleCoveragesVMUpdatedINPE, setLoadingClausesFunc, setEditingFunc) => {
        const coverages = _.get(caVehicleCoveragesVMUpdatedINPE, 'value');
        const vehicleClausesToUpdate = CACoverageUtil.generateUpdatedVehicleCoveragesDTO(coverages);
        const updatedVehicleCoveragesPublicIDs = CACoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedVehicleCoveragesDTO(vehicleClausesToUpdate);
        setLoadingClausesFunc(updatedVehicleCoveragesPublicIDs)
        const response = await coveragesService.updateSingleVehicleCoverage(
            jobID,
            sessionUUID,
            vehicleClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setEditingFunc(false)
        const subVMUpdatedByRes = _.clone(caVehicleCoveragesVMUpdatedINPE)
        _.set(subVMUpdatedByRes, `value`, response.vehicleCoverages);
        setVehicleCoveragesVM(subVMUpdatedByRes);
        _.set(vehicleVM, 'value', response.caVehicle);
        updateVehicleVM(vehicleVM);
        generateValidationIssues(response.errorsAndWarnings);
    }, [coveragesService, jobID, sessionUUID, authHeader, setVehicleCoveragesVM, vehicleVM, updateVehicleVM, generateValidationIssues]);

    const updateCAVehicleCoveragesVMToServer = useCallback(async (newVehicleCoveragesVM) => {
        updateVMToServer(newVehicleCoveragesVM, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);



    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newVehicleCoveragesVM = changeSubmission(value, changedPath);
            
            updateCAVehicleCoveragesVMToServer(newVehicleCoveragesVM);
        },
        [changeSubmission, updateCAVehicleCoveragesVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateCAVehicleCoveragesVMToServer(submissionVM);
        },
        [submissionVM, updateCAVehicleCoveragesVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
            },
            vehicleConditions: {
                loadingClause: loadingClauses,
                path: vehicleConditionsVMPath,
                value: vehicleConditions,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    CASingleClauseComponentVM: CASingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
                isEditable: false
            }
        };
    }, [
        loadingClauses,
        vehicleConditionsVMPath,
        vehicleConditions,
        onValidate,
        showErrors,
        setIsEditing,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
        },
        resolveComponentMap: {
            CAClausesComponentVM: CAClausesComponentVM,
        }
    };


    return (
        <CoverageContext.Provider
            value={{
                wizardData: submissionVM,
                updateWizardData: updateWizardData,
                updateVMToServer: updateVMToServer
            }}
        >
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={{}}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </CoverageContext.Provider>
        
    );
}

CAVehicleConditions.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
CAVehicleConditions.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default CAVehicleConditions;
