import React, {
    useCallback, useState, useEffect, useContext
} from 'react';

import _ from 'lodash';


import { Flex } from '@jutro/layout';
import { Icon } from '@jutro/components';
import { InputField, IconButton } from '@jutro/legacy/components';



import { WniTableRowUtil } from 'wni-portals-util-react';

import RiskComponentType from './component/RiskItemComponent/RiskItemComponentType';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

const generateBusinessIncomeValidationNode = (businessIncome) => {
    const {
        coverableColumnBasicInfo: {
            isValid: isBusinessIncomeValid
        }
    } = businessIncome
    const businessIncomeNode = {isValid: isBusinessIncomeValid}
    return businessIncomeNode
}

const generatePersonalPropertyValidationNode = (personalProperty) => {
    const {
        coverableColumnBasicInfo: {
            isValid: isPersonalPropertyValid
        }
    } = personalProperty
    const personalPropertyNode = {isValid: isPersonalPropertyValid}
    return personalPropertyNode
}

const generateOccupancyValidationNode = (occupancy) => {
    const {
        personalPropertyTreeRows,
        coverableColumnBasicInfo: {
            isValid: isOccupancyValid
        }
    } = occupancy

    const occupancyNode = {}

    const allPersonalPropertyNodes = {}
    let allPersonalPropertyValid = true
    personalPropertyTreeRows.forEach((personalProperty) => {
        const {
            publicID: personalPropertyPublicID,
        } = personalProperty
        const personalPropertyNode = generatePersonalPropertyValidationNode(personalProperty)
        allPersonalPropertyValid = allPersonalPropertyValid && personalPropertyNode.isValid
        allPersonalPropertyNodes[personalPropertyPublicID] = personalPropertyNode
    })
    occupancyNode.personalProperty = allPersonalPropertyNodes
    occupancyNode.isValid = isOccupancyValid && allPersonalPropertyValid
    return occupancyNode
}


const generatebuildingValidationNode = (building) => {
    const {
        businessIncomeRows,
        occupancyTreeRows,
        coverableColumnBasicInfo: {
            isValid: isBuildingValid
        }
    } = building

    const buildingNode = {}

    const businessIncomeNodes = {}
    let allBusinessIncomeValid = true
    businessIncomeRows.forEach((businessIncome) => {
        const {
            publicID: businessIncomePublicID,
        } = businessIncome
        const businessIncomeNode = generateBusinessIncomeValidationNode(businessIncome)
        allBusinessIncomeValid = allBusinessIncomeValid && businessIncomeNode.isValid
        businessIncomeNodes[businessIncomePublicID] = businessIncomeNode
    })
    buildingNode.businessIncome = businessIncomeNodes

    const occupancyNodes = {}
    let allOccupancyValid = true
    occupancyTreeRows.forEach((occupancy) => {
        const {
            publicID: occupancyPublicID,
        } = occupancy

        const occupancyNode = generateOccupancyValidationNode(occupancy)
        allOccupancyValid = allOccupancyValid && occupancyNode.isValid
        occupancyNodes[occupancyPublicID] = occupancyNode
    })
    buildingNode.occupancy =  occupancyNodes

    buildingNode.isValid = allBusinessIncomeValid && allOccupancyValid && isBuildingValid
    return buildingNode
}

const generateSpecClsBIValidationNode = (specClsBI) => {
    const {
        coverableColumnBasicInfo: {
            isValid: isSpecClsBIValid
        }
    } = specClsBI
    const specClsBINode = {
        isValid: isSpecClsBIValid
    }
    return specClsBINode
}

const generateSpecialClassValidationNode = (specialClass) => {
    const {
        specialClassBusinessIncomeTreeRows,
        coverableColumnBasicInfo: {
            isValid: isSpecialClassValid
        }
    } = specialClass
    const specialClassNode = {}
    const specClsBINodes = {}
    let allSpecClsBIValid = true
    specialClassBusinessIncomeTreeRows.forEach((specClsBI) => {
        const {
            publicID: specClsBIPublicID,
        } = specClsBI
        const specClsBIValidationNode = generateSpecClsBIValidationNode(specClsBI)
        allSpecClsBIValid = allSpecClsBIValid && specClsBIValidationNode.isValid
        specClsBINodes[specClsBIPublicID] = specClsBIValidationNode
    })
    specialClassNode.specClsBI = specClsBINodes
    specialClassNode.isValid = isSpecialClassValid && allSpecClsBIValid
    return specialClassNode
}

const generateLocationValidationNode = (location) => {
    const {
        buildingTreeRows,
        specialCLassTreeRows,
    } = location
    const locationValidationNode = {}

    const buildingNodes = {}
    let allBuildingValid = true

    buildingTreeRows.forEach((building) => {
        const {
            publicID: buildingPublicID,
        } = building

        const buildingNode = generatebuildingValidationNode(building)
        allBuildingValid = allBuildingValid && buildingNode.isValid
        buildingNodes[buildingPublicID] = buildingNode
    })

    locationValidationNode.building = buildingNodes

    const specialClassNodes = {}
    let allSpecialClassValid = true

    specialCLassTreeRows.forEach((specialClass) => {
        const {
            publicID: specialClassPublicID,
        } = specialClass
        const specialClassNode = generateSpecialClassValidationNode(specialClass)

        allSpecialClassValid = allSpecialClassValid && specialClassNode.isValid
        specialClassNodes[specialClassPublicID] = specialClassNode
    })
    locationValidationNode.specialClass = specialClassNodes
    locationValidationNode.isValid = allSpecialClassValid && allBuildingValid
    return locationValidationNode
}

const generateValidationTreeByRowTree = (riskTreeRows) => {
    const res = {}
    let isAllLocationValid = true
    riskTreeRows.forEach(location => {
        const {
            publicID
        } = location
        const locationValidationNode = generateLocationValidationNode(location)
        isAllLocationValid = isAllLocationValid && locationValidationNode.isValid

        res[publicID] = locationValidationNode
    })
    res.isValid = isAllLocationValid
    return res
}

const getDisplaySuccessIcon = (validationTree, item) => {
    const {
        coverableType,
        locationPublicID,
        buildingPublicID,
        occupancyPublicID,
        specialClassPublicID,
        publicID,
    } = item
    switch (coverableType) {
        case RiskComponentType.Location:
            return _.get(validationTree, `${publicID}.isValid`)
        case RiskComponentType.Building:
            return _.get(validationTree, `${locationPublicID}.building.${publicID}.isValid`)
        case RiskComponentType.BusinessIncome:
            return _.get(validationTree, `${locationPublicID}.building.${buildingPublicID}.businessIncome.${publicID}.isValid`)
        case RiskComponentType.Occupancy:
            return _.get(validationTree, `${locationPublicID}.building.${buildingPublicID}.occupancy.${publicID}.isValid`)
        case RiskComponentType.PersonalProperty:
            return _.get(validationTree, `${locationPublicID}.building.${buildingPublicID}.occupancy.${occupancyPublicID}.personalProperty.${publicID}.isValid`)
        case RiskComponentType.SpecialClass:
            return _.get(validationTree, `${locationPublicID}.specialClass.${publicID}.isValid`)
        case RiskComponentType.SpecialClassBusinessIncome:
            return _.get(validationTree, `${locationPublicID}.specialClass.${specialClassPublicID}.specClsBI.${publicID}.isValid`)
        default:
            return true
    }
}

function canAddOccupancy(selectedRiskItem) {
    return _.get(selectedRiskItem, 'classCode', '') !== '1150'
}



function getRisksTableData(riskTreeRows) {
    const riskTreeData = _.clone(riskTreeRows);
        const finalTableData = [];
        riskTreeData.forEach((location) => {
            let buildingData;
            let specialClassData;
            let occupancyData;
            let businessIncomeData;
            let specialClassBusinessIncomeData;
            const locationCollapsed = !_.isUndefined(location.collapsed) ? location.collapsed  : !location.hasChildren;
            Object.assign(location, {'collapsed': locationCollapsed});
            const openBuildingTreeRows = _.isUndefined(location.openBuildingTreeRows) ? !_.isEmpty(location.buildingTreeRows) : location.openBuildingTreeRows;
            Object.assign(location, {'openBuildingTreeRows': openBuildingTreeRows});
            const openSpecialCLassTreeRows = _.isUndefined(location.openSpecialCLassTreeRows) ? !_.isEmpty(location.specialCLassTreeRows) : location.openSpecialCLassTreeRows;
            Object.assign(location, {'openSpecialCLassTreeRows': openSpecialCLassTreeRows});
            finalTableData.push({
                ...location,
                buildingTreeRows: undefined,
                specialCLassTreeRows: undefined
            })
            const locationDescription = location.description
            if (!_.isEmpty(location.buildingTreeRows) && location.openBuildingTreeRows) {
                buildingData = location.buildingTreeRows;
                for (const building of buildingData) {
                    const buildingDescription = _.get(building, 'coverableColumnBasicInfo.description', '')
                    const buildingCollapsed = !_.isUndefined(building.coverableColumnBasicInfo.collapsed) ? building.coverableColumnBasicInfo.collapsed  : !building.coverableColumnBasicInfo.hasChildren;
                    Object.assign(building.coverableColumnBasicInfo, {'locationPublicID': building.locationPublicID, 'collapsed': buildingCollapsed, 'locationDescription': locationDescription});
                    finalTableData.push({
                        ...building.coverableColumnBasicInfo,
                    })
                    const openOccupancyTreeRows = _.isUndefined(building.openOccupancyTreeRows) ? !_.isEmpty(building.occupancyTreeRows) : building.openOccupancyTreeRows;
                    Object.assign(building, {'openOccupancyTreeRows': openOccupancyTreeRows});
                    const openBusinessIncomeRows = _.isUndefined(building.openBusinessIncomeRows) ? !_.isEmpty(building.businessIncomeRows) : building.openBusinessIncomeRows;
                    Object.assign(building, {'openBusinessIncomeRows': openBusinessIncomeRows});
                    if (!_.isEmpty(building.occupancyTreeRows) && building.openOccupancyTreeRows) {
                        occupancyData = building.occupancyTreeRows;  
                        for (const occupancy of occupancyData) {
                            const occupancyCollapsed = !_.isUndefined(occupancy.coverableColumnBasicInfo.collapsed) ? occupancy.coverableColumnBasicInfo.collapsed : !occupancy.coverableColumnBasicInfo.hasChildren;
                            Object.assign(occupancy.coverableColumnBasicInfo, {'locationPublicID': building.locationPublicID, 'buildingPublicID': occupancy.buildingPublicID, 'collapsed': occupancyCollapsed, 'locationDescription': locationDescription, 'buildingDescription': buildingDescription });
                            finalTableData.push({
                                ...occupancy.coverableColumnBasicInfo
                            })
                            const openPersonalPropertyTreeRows = _.isUndefined(occupancy.openPersonalPropertyTreeRows) ? !_.isEmpty(occupancy.personalPropertyTreeRows) : occupancy.openPersonalPropertyTreeRows;
                            Object.assign(occupancy, {'openPersonalPropertyTreeRows': openPersonalPropertyTreeRows});

                            if (!_.isEmpty(occupancy.personalPropertyTreeRows) && occupancy.openPersonalPropertyTreeRows) {
                                const personalPropertyData = occupancy.personalPropertyTreeRows;
                                for (const personalProperty of personalPropertyData) {
                                    const occupancyDescription = _.get( occupancy, 'coverableColumnBasicInfo.description', '')
                                    Object.assign(personalProperty.coverableColumnBasicInfo, {'locationPublicID': building.locationPublicID, 'buildingPublicID': occupancy.buildingPublicID, 'occupancyPublicID': personalProperty.occupancyPublicID, 'locationDescription': locationDescription, 'buildingDescription': buildingDescription, 'occupancyDescription': occupancyDescription});
                                    finalTableData.push({
                                        ...personalProperty.coverableColumnBasicInfo
                                    })
                                }
                            }
                        }
                    }
                    if (!_.isEmpty(building.businessIncomeRows) && building.openBusinessIncomeRows) {
                        businessIncomeData = building.businessIncomeRows;
                        for (const businessIncome of businessIncomeData) {
                            Object.assign(businessIncome.coverableColumnBasicInfo, {'locationPublicID': building.locationPublicID, 'buildingPublicID': businessIncome.buildingPublicID, 'locationDescription': locationDescription, 'buildingDescription': buildingDescription});
                            finalTableData.push({
                                ...businessIncome.coverableColumnBasicInfo
                            })
                        }
                    }
                }
            }
            if (!_.isEmpty(location.specialCLassTreeRows) && location.openSpecialCLassTreeRows) {
                specialClassData = location.specialCLassTreeRows;
                for (const specialClass of specialClassData) {
                    const specialClassCollapsed = !_.isUndefined(specialClass.coverableColumnBasicInfo.collapsed) ? specialClass.coverableColumnBasicInfo.collapsed : !specialClass.coverableColumnBasicInfo.hasChildren;
                    Object.assign(specialClass.coverableColumnBasicInfo, {'locationPublicID': specialClass.locationPublicID, 'collapsed': specialClassCollapsed, 'locationDescription': locationDescription});
                    finalTableData.push({
                        ...specialClass.coverableColumnBasicInfo,
                    })
                    const openSpecialClassBusinessIncomeTreeRows = _.isUndefined(specialClass.openSpecialClassBusinessIncomeTreeRows) ? !_.isEmpty(specialClass.specialClassBusinessIncomeTreeRows) : specialClass.openSpecialClassBusinessIncomeTreeRows;
                    const specialClassDescription = _.get(specialClass, 'coverableColumnBasicInfo.description', '')
                    Object.assign(specialClass, {'openSpecialClassBusinessIncomeTreeRows': openSpecialClassBusinessIncomeTreeRows});
                    if (!_.isEmpty(specialClass.specialClassBusinessIncomeTreeRows) && specialClass.openSpecialClassBusinessIncomeTreeRows) {
                        specialClassBusinessIncomeData = specialClass.specialClassBusinessIncomeTreeRows;
                        for (const specialClassBusinessIncome of specialClassBusinessIncomeData) {
                            Object.assign(specialClassBusinessIncome.coverableColumnBasicInfo, {'locationPublicID': specialClass.locationPublicID, 'specialClassPublicID': specialClassBusinessIncome.specialClassPublicID, 'locationDescription': locationDescription, 'specialClassDescription': specialClassDescription});
                            finalTableData.push({
                                ...specialClassBusinessIncome.coverableColumnBasicInfo
                            })
                        }
                    }
                }
            }
        })
        return finalTableData.filter(elt => !_.isNil(elt));
}



function renderDescriptionCell_Internal(item, iconTypeGetter = undefined, getCoverableDetailsAndSubnodes = _.noop){
    let type;

    let showIcon = false;
    if (_.isFunction(iconTypeGetter)) {
        type = iconTypeGetter();
        showIcon = !_.isEmpty(type);
    }
    // if (showIcon) {
    //     const displaySuccessIcon = iconTypeGetter(); // CPRisksUtil.getDisplaySuccessIcon(validationTree, item)
    //     if (displaySuccessIcon) {
    //         type = 'success';
    //     } else {
    //         type = 'error';
    //     }
    // } 
    const isVisible = item.hasChildren;
    const isCollapsed = item.collapsed;

    let indentClassNames;
    switch(item.coverableType) {
        case 'Building':
        case 'Special Class':
            indentClassNames = 'pdl-10'
            break;
        case 'Occupancy':
        case 'Business Income':
        case 'Special Class Business Income':
            indentClassNames = 'pdl-20'
            break;
        case 'Personal Property':
            indentClassNames = 'pdl-30'
            break;
        default:
            break;
    }
    const description = !_.isNil(item.description) ? item.description.replace('null', '') : '';
    const dom =  <Flex justifyContent="left" alignContent="middle" wrap="false" className={indentClassNames}>
            { showIcon ? <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} /> : null }
            <InputField readOnly value={description}/>
            {isVisible && !isCollapsed && <IconButton
                id={`expand${item.publicID}`}
                icon="gw-expand-more"
                iconColor="dark"
                className='font-24'
                onClick={(e) => {
                    getCoverableDetailsAndSubnodes(e, item, isCollapsed);
                }}
            />}
            
            {isVisible && isCollapsed && <IconButton
                id={`expand${item.publicID}`}
                icon="gw-keyboard-arrow-right"
                iconColor="dark"
                className='font-24'
                onClick={(e) => {
                    getCoverableDetailsAndSubnodes(e,item, isCollapsed);
                }}
            />}
    </Flex>
    return WniTableRowUtil.renderCell(`${item.coverableType}${item.publicID}`, dom)
}

function getRenderDescriptionCellFuncForCPRisksPage(validationTree, getCoverableDetailsAndSubnodes) {
    const renderDescriptionCell = (item) => {
        return renderDescriptionCell_Internal(item, () => {
            const displaySuccessIcon = getDisplaySuccessIcon(validationTree, item)
            let type;
            if (displaySuccessIcon) {
                type = 'success';
            } else {
                type = 'error';
            }
            return type;
        }, getCoverableDetailsAndSubnodes);
    };

    return renderDescriptionCell;
}

function getRenderDescriptionCellFuncForCPRiskSummaryComponent(getCoverableDetailsAndSubnodes) {
    const renderDescriptionCell = (item) => {
        return renderDescriptionCell_Internal(item, undefined, getCoverableDetailsAndSubnodes);
    }

    return renderDescriptionCell;
}


export default {
    canAddOccupancy,
    generateValidationTreeByRowTree,
    getDisplaySuccessIcon,
    //
    getRisksTableData,
    //
    getRenderDescriptionCellFuncForCPRisksPage,
    getRenderDescriptionCellFuncForCPRiskSummaryComponent,
}