import { defineMessages } from 'react-intl';

export default defineMessages({
    vinNumber: {
        id: "wni.fnol.ho.views.incidentdetails.singleVehicleIncident.vinNumber",
        defaultMessage: "VIN Number"
    },
    make: {
        id: "wni.fnol.ho.views.incidentdetails.singleVehicleIncident.make",
        defaultMessage: "Make"
    },
    model: {
        id: "wni.fnol.ho.views.incidentdetails.singleVehicleIncident.model",
        defaultMessage: "Model"
    },
    damageDescription: {
        id: "wni.fnol.ho.views.incidentdetails.singleVehicleIncident.damageDescription",
        defaultMessage: "Damage Description"
    },
    vehicleOwner: {
        id: "wni.fnol.ho.views.incidentdetails.singleVehicleIncident.vehicle owner",
        defaultMessage: "Vehicle Owner"
    },
    addNewPerson: {
        id: 'wni.fnol.ho.views.incidentdetails.singleVehicleIncident.addNewPerson',
        defaultMessage: 'Add new person'
    },
    addNewCompany: {
        id: 'wni.fnol.ho.views.incidentdetails.singleVehicleIncident.addNewCompany',
        defaultMessage: 'Add new company'
    },
    addNewLocation: {
        id: 'wni.fnol.ho.views.incidentdetails.singleVehicleIncident.addNewLocation',
        defaultMessage: 'Add new location'
    },
    cancel: {
        id: 'wni.fnol.ho.views.incidentdetails.singleVehicleIncident.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'wni.fnol.ho.views.incidentdetails.singleVehicleIncident.save',
        defaultMessage: 'Save'
    }
})