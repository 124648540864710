import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glincidentsdetail'), method, data, additionalHeaders);
}


export default class GLIncidentsDetailService {
    static updatePropertyIncident(claimNumber,  propertyIncident, relatedContact, authHeader = {}) {
        return processSubmission('updatePropertyIncident', [claimNumber, propertyIncident, relatedContact], authHeader);
    }

    static removePropertyIncidents(claimNumber, incidentIDs, authHeader = {}) {
        return processSubmission('removePropertyIncidents', [claimNumber, incidentIDs], authHeader);
    }

    static updateOrAddInjuryIncident(claimNumber,  injuryIncident, relatedContact, authHeader = {}) {
        return processSubmission('updateOrAddInjuryIncident', [claimNumber, injuryIncident, relatedContact], authHeader);
    }

    static removeInjuryIncidents(claimNumber, incidentIDs, authHeader = {}) {
        return processSubmission('removeInjuryIncidents', [claimNumber, incidentIDs], authHeader);
    }
    
    static createNewVehicleIncident(claimNumber, authHeader = {}) {
        return processSubmission('createNewVehicleIncident', [claimNumber], authHeader);
    }

    static updateVehicleIncident(claimNumber,  vehicleIncident, relatedContact, authHeader = {}) {
        return processSubmission('updateVehicleIncident', [claimNumber, vehicleIncident, relatedContact], authHeader);
    }

    static removeVehicleIncident(claimNumber, incidentIDs, authHeader = {}) {
        return processSubmission('removeVehicleIncident', [claimNumber, incidentIDs], authHeader);
    }

    static onPageNext(claimNumber, authHeader = {}) {
        return processSubmission('onPageNext', [claimNumber], authHeader);
    }

}
