import React, {
    useContext,
    useState,
    useEffect,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import styles from './OtherStructureDetails.module.scss';
import metadata from './OtherStructureDetails.metadata.json5'
import StructureCoverageTermsComponent from './StructureCoverageTermsComponent';

function OtherStructureDetails(props) {

    const {
        structure,
        handleStructureChange,
        onValidate,
        registerComponentValidation,
        showErrors,
        isEditable,
        setIsEditing,
        coveragesConfig,
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);

    const [structureInModal, setStructureInModal] = useState(structure);
    const [isCurrentValueChanged, updateIsCurrentValueChanged] = useState(false);
    const {
        terms = [],
        structureType,
        isHeated,
    } = structureInModal

    const structureVM = viewModelService.create(
        structureInModal,
        'pc',
        'wni.edge.capabilities.policycommon.coverage.dto.hop.HOPrivatestructureDTO_Ext'
    );

    useEffect(() => {
        setStructureInModal(structure)
    }, [structure])

    const fieldsSyncOnBlur = [
        'structureValue',
        'squareFootage',
        'structureDetail',
    ]

    const valueTypesSyncOnBlur = [
        'Money',
        'shorttext'
    ]

    const writeValue = (value, changedValuePath) => {
        const structureClone = _.clone(structure)
        let changedValue = value;
        if (changedValuePath === 'squareFootage' && typeof changedValue === 'number' && changedValue <= 0) {
            changedValue = 1;
        }
        if (changedValuePath === "structureValue" && _.isNil(_.get(changedValue, 'amount'))) {
            changedValue = null
        }
        _.set(structureClone, changedValuePath, changedValue)
        let isValueChanged = false
        if (changedValuePath === "structureValue") {
            //check amount DTO changed or not
            if (!_.isEqual(changedValue, structure.structureValue)) {
                isValueChanged = true
            }
        } else if(!_.isEqual(changedValue, structure[`${changedValuePath}`])) {
            isValueChanged = true
        };
        if (isValueChanged) {
            updateIsCurrentValueChanged(true);
            if (fieldsSyncOnBlur.includes(changedValuePath)) {
                setIsEditing(true)
                setStructureInModal(structureClone);
                return;
            }
            handleStructureChange(structureClone)
        }
    }

    const onBlurSync = () => {
        if (isCurrentValueChanged){
            handleStructureChange(structureInModal)
        }
    }

    const handleTermsChange = (updatedTerms, updatedTermValueType) => {
        const structureClone = _.clone(structure)
        _.set(structureClone, 'terms', updatedTerms)
        if (valueTypesSyncOnBlur.includes(updatedTermValueType)) {
            setIsEditing(true)
            setStructureInModal(structureClone);
            return;
        }
        handleStructureChange(structureClone)
    }

    const handleTermsOnBlur = (updatedTerms) => {
        const structureClone = _.clone(structure)
        _.set(structureClone, 'terms', updatedTerms)
        handleStructureChange(structureClone)
    }

    const isStructureDetailVisible = structureType === 'other';
    const isHeatingTypeVisible = !!isHeated;

    const generateOverrides = () => {
        return {
            '@field': {
                readOnly: !isEditable,
            },
            structureNum: {
                readOnly: true,
            },
            structureDetail: {
                visible: isStructureDetailVisible,
            },
            heatingType: {
                visible: isHeatingTypeVisible,
            },
            structureCoverageTerms: {
                terms: terms,
                handleTermsChange: handleTermsChange,
                handleTermsOnBlur: handleTermsOnBlur,
                registerComponentValidation,
                showErrors,
                readOnly: !isEditable,
                coveragesConfig,
            }
        }
    }

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onBlurSync,
        },
        resolveComponentMap: {
            StructureCoverageTermsComponent: StructureCoverageTermsComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={structureVM}
            overrideProps={overrideProps}
            // onModelChange={updateFormData}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

OtherStructureDetails.propTypes = {
    structure: PropTypes.shape({}),
    handleStructureChange: PropTypes.func.isRequired,
    isEditable: PropTypes.bool,
};

OtherStructureDetails.defaultProps = {
    structure: {},
    isEditable: false
};

export default OtherStructureDetails;