import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imInstallationFloaterService'), method, data, additionalHeaders);
}

export default class IMInstallationFloaterService {
  
    /**
     * Invokes IMInstallationFloaterHandler.getInstallationFloaterClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getInstallationFloaterClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getInstallationFloaterClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.getInstallationFloaterClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getInstallationFloaterClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getInstallationFloaterClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.updateInstallationFloaterClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} InstallationFloaterToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateInstallationFloaterClauses(jobNumber, sessionUUID, InstallationFloaterToUpdate, authHeader = {}) {
        return processSubmission('updateInstallationFloaterClauses', [jobNumber, sessionUUID, InstallationFloaterToUpdate], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.updateInstallationFloaterSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateInstallationFloaterSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateInstallationFloaterSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.onInstallationFloaterNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onInstallationFloaterNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onInstallationFloaterNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMInstallationFloaterHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }
}