import _ from 'lodash';
import WniProductsUtil from './AccountAndProduct/WniProductsUtil';
import ErrorsAndWarningsUtil from './ErrorsAndWarnings/ErrorsAndWarningsUtil';

function getWarningMessagesAndSuccessNotificationVisibility(
    job,
    authUserData,
    referredToUnderWriter,
    showReferToUW,
    UWUtil,
    transactionVisibleActions
) {

    const {
        createUser_Ext: {
            userType: createUserType
        } = {},
        type,
    } = job
    const underwritingIssues = _.get(job, 'underwritingIssues');
    const selectedVersionPublicID = _.get(job, 'selectedVersion_Ext');
    let hasBlockingUWIssue = ErrorsAndWarningsUtil
        .quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues, selectedVersionPublicID);

    const uwutil = UWUtil(job, authUserData.userType);

    const {
        userType: currentUserType
    } = authUserData
    const showUWIssuesAlert = uwutil.hasUwIssues && !['Bound', 'Withdrawn'].includes(job.statusCode);
    // const transactionVisibleActions = this.getSubmissionToProceed();

    const isCLProduct = WniProductsUtil.isCLProduct(_.get(job, 'productCode'));
    const isCLRewrite = isCLProduct && uwutil.jobType === 'Rewrite';

    const showMessageBox = job && !isCLRewrite
        ? !job.currentVersionLocked_Ext
            && showReferToUW
            && job.statusCode !== 'Withdrawn'
            && hasBlockingUWIssue
        : false;

    if (isCLProduct) {
        hasBlockingUWIssue = hasBlockingUWIssue || uwutil.uwIssuesHaveBeenRejectedByUW;
    }
    const showUWIssuesTriggeredMsg = uwutil.jobType === 'Rewrite'? false : showUWIssuesAlert
            && !referredToUnderWriter
            && hasBlockingUWIssue
            && (isCLProduct ? !uwutil.uwIssuesHaveBeenRejectedByUW : !uwutil.uwIssuesHaveBeenInspectedByUW)
            // && !uwutil.uwIssuesHaveBeenInspectedByUW
            && !['Bound', 'Withdrawn'].includes(job.statusCode)
            && !job.currentVersionLocked_Ext;

    const showUWIssuesRejectedMsg = showUWIssuesAlert
        && !job.currentVersionLocked_Ext
        && (isCLProduct ? uwutil.uwIssuesHaveBeenRejectedByUW : uwutil.uwIssuesHaveBeenInspectedByUW)
        // && uwutil.uwIssuesHaveBeenInspectedByUW
        && hasBlockingUWIssue;

    const theJobStateForLock = ['Withdrawn'].includes(job.statusCode);

    // const lockedDueToCreatedbyUW = [
    //     'Submission',
    //     'PolicyChange'
    // ].includes(type) && (createUserType === 'underwriter' && currentUserType !== 'underwriter')
    let lockedDueToCreatedbyUW = false;
    if (isCLProduct) {
        lockedDueToCreatedbyUW = isLockedDueToCreatedbyUW(type, createUserType, currentUserType);
    }

    const showLockedWarning = (job.currentVersionLocked_Ext && !theJobStateForLock) || lockedDueToCreatedbyUW;
    const showDeclinedWarning = isCLRewrite? false : ['Declined'].includes(job.statusCode);

    const showUWIssuesApprovedMsg = isCLRewrite? false : showUWIssuesAlert
        && !job.currentVersionLocked_Ext && !hasBlockingUWIssue
        && uwutil.hasUwIssues && !showLockedWarning;

    const showRejectUWIssueWarning = isCLRewrite? false : showUWIssuesRejectedMsg
        && !showUWIssuesApprovedMsg
        && !job.currentVersionLocked_Ext;

    const showReferReviewWarning = isCLRewrite? false : ['Quoted'].includes(job.statusCode)
        && referredToUnderWriter
        && uwutil.hasUwIssues
        && hasBlockingUWIssue
        && !uwutil.uwIssuesHaveBeenInspectedByUW
        && !job.currentVersionLocked_Ext;

    const showRewriteInProgressWarning = isCLRewrite && ['Draft', 'Quoted'].includes(job.statusCode) && !showLockedWarning
    const showRewriteComfirmedWarning = isCLRewrite && ['Bound', 'Issued'].includes(job.statusCode)
    const showRewriteDeterminedWarning = isCLRewrite && ['Withdrawn', 'Cancelled'].includes(job.statusCode)
    const warningExist = (showUWIssuesTriggeredMsg
        || showRejectUWIssueWarning
        || showReferReviewWarning
        || showLockedWarning
        || showDeclinedWarning
        || showRewriteInProgressWarning
        || showRewriteComfirmedWarning
        || showRewriteDeterminedWarning
        )
        && (isCLProduct ? true : (job.statusCode !== 'Draft' || job.currentVersionLocked_Ext || lockedDueToCreatedbyUW));

    const showViewQuote = isCLRewrite? false : _.get(transactionVisibleActions, 'isViewTransaction');

    const showWithDrawButton = isCLRewrite? false : _.get(transactionVisibleActions, 'isWithdrawTransaction') && (
        showRejectUWIssueWarning || showReferReviewWarning || showLockedWarning || showUWIssuesTriggeredMsg)
        && !lockedDueToCreatedbyUW;

    const showEditQuoteButton = isCLRewrite? false : _.get(transactionVisibleActions, 'isContinueTransaction')
        && !job.currentVersionLocked_Ext
        && !lockedDueToCreatedbyUW;

    // const showReferToUnderWriterButton = _.get(transactionVisibleActions, 'isReferToUnderWriterTransaction') && (
    //     showUWIssueRaisedWarning || showReferReviewWarning) && !referredToUnderWriter;

    const showCopySubmissionButton = isCLRewrite? false : _.get(transactionVisibleActions, 'isCopySubmission') && !lockedDueToCreatedbyUW;

    return {
        warningExist: warningExist,
        warningMessagesVisibility: {
            showRewriteInProgressWarning: showRewriteInProgressWarning,
            showRewriteComfirmedWarning: showRewriteComfirmedWarning,
            showRewriteDeterminedWarning: showRewriteDeterminedWarning,
            showUWIssueRaisedWarning: showUWIssuesTriggeredMsg,
            showReferReviewWarning: showReferReviewWarning,
            showRejectUWIssueWarning: showRejectUWIssueWarning,
            showLockedWarning: showLockedWarning,
            showDeclinedWarning: showDeclinedWarning,
            showMessageBox: showMessageBox,
            warningButtonVisibility: {
                showViewQuote: showViewQuote,
                showReferToUnderWriterButton: false,
                showWithDrawButton: showWithDrawButton,
                showEditQuoteButton: showEditQuoteButton,
                showCopySubmissionButton: showCopySubmissionButton
            }
        },
        successNotification: {
            showUWIssuesApprovedMsg: showUWIssuesApprovedMsg
        }
    };
}

function isLockedDueToCreatedbyUW(type, createUserType, currentUserType) {
    const rs = [
        'Submission',
        'PolicyChange'
    ].includes(type) && (createUserType === 'underwriter' && currentUserType !== 'underwriter')
    return rs;
}

export default {
    getWarningMessagesAndSuccessNotificationVisibility,
    isLockedDueToCreatedbyUW
};
