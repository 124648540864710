import { defineMessages } from 'react-intl';

export default defineMessages({
    financialInstitutionName: {
        id: 'gateway.views.vehicle.additionalInterest.financial-institution-search.Financial Institution Name',
        defaultMessage: 'Financial Institution Name'
    },
    country: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Country',
        defaultMessage: 'Country'
    },
    pobox: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.PO Box',
        defaultMessage: 'PO Box'
    },
    city: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.City',
        defaultMessage: 'City'
    },
    county: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.County',
        defaultMessage: 'County'
    },
    state: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.State',
        defaultMessage: 'State'
    },
    zipCode: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Zip Code',
        defaultMessage: 'Zip Code'
    },
    financialInstitutionNameZipCodeRequired: {
        id: 'gateway.views.vehicle.additionalInterest.financial-institution-search.Please Specify the Financial Institution Name.',
        defaultMessage: 'Please Specify at least five characters of the Financial Institution Name and Zip Code.'
    },
    searchFinancialInstitution: {
        id: 'gateway.views.vehicle.additionalInterest.financial-institution-search.Search Financial Institution',
        defaultMessage: 'Search Financial Institution'
    },
    tooManyResult: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Too Many Results',
        defaultMessage: 'The Additional Interest search returned too many results, please refine your search criteria.'
    },
    AddNewFinancialInstitution: {
        id: 'gateway.views.vehicle.additionalInterest.financial-institution-search.Financial Institution',
        defaultMessage: 'Add New Financial Institution'
    }
});
