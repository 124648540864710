import React, { useCallback } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './CPSuccessPage.module.scss';
import metadata from './CPSuccessPage.metadata.json5';
import './CPSuccessPage.messages';

function CPSuccessPage(props) {
    const { wizardData: submissionVM } = props;
    const handlePrint = () => {
        window.print();
    };

    const getAccountNumber = () => {
        const accountNumber = _.get(submissionVM.value, 'baseData.accountNumber');
        return (
            <Link to={`/accounts/${accountNumber}/summary`} className={styles.removeLinkStyle}>
                {accountNumber}
            </Link>
        );
    };

    const getPolicyNumber = () => {
        const policyNumber = _.get(submissionVM.value, 'bindData.policyNumber');
        return (
            <Link to={`/policies/${policyNumber}/summary`} className={styles.removeLinkStyle}>
                {policyNumber}
            </Link>
        );
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        accountNumber: {
            value: getAccountNumber()
        },
        policyNumber: {
            value: getPolicyNumber()
        },
        effectiveDateRange: {
            value: {
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value'),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value')
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint
        }
    };

    const readValue = useCallback((id, path) => {
        return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
    });

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CPSuccessPage.propTypes = wizardProps;
export default CPSuccessPage;
