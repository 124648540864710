import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslator } from '@jutro/locale';
import { getConfigValue } from '@jutro/config';
import { ServiceManager } from '@jutro/legacy/services';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './Captcha.messages';

const DEFAULT_VALUE = '{{CAPTCHA_API_KEY}}';

function Captcha({ onChange, onError, onValidate, id }) {
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();
    const API_KEY = getConfigValue('CAPTCHA_API_KEY');
    const localeService = ServiceManager.getService('locale-service');

    const isApiKeySet = useMemo(() => API_KEY !== DEFAULT_VALUE, [API_KEY]);

    useEffect(() => {
        onValidate(isComponentValid, id)
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (!isApiKeySet) {
            if (onError) {
                onError({
                    id: 'noAPIKey',
                    message: translator(messages.noAPIKey)
                });
            }
            setComponentValidation(true);
        } else {
            setComponentValidation(false);
        }
    }, [isApiKeySet, onError, translator, setComponentValidation]);

    const getLanguage = useCallback(() => {
        const locale = localeService.getStoredLanguage();

        if (locale === 'zh-CN') {
            return locale.toLowerCase();
        }
        return locale.split('-')[0];
    }, [localeService]);

    const handleChange = useCallback((value) => {
        setComponentValidation(true);
        onChange(value);
    }, [onChange, setComponentValidation]);

    if (!isApiKeySet) {
        return null;
    }

    return (
        <ReCAPTCHA
            sitekey={API_KEY}
            onChange={handleChange}
            onErrored={onError}
            onExpired={onError}
            hl={getLanguage()}
        />
    );
};

Captcha.propTypes = {
    onChange: PropTypes.func,
    onError: PropTypes.func,
}

Captcha.defaultProps = {
    onChange: undefined,
    onError: undefined,
}

export default Captcha;
