import React, { useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { PaymentUtil, WniProductsUtil } from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import styles from './AQQuoteTableComponent.module.scss';
import metadata from './AQQuoteTableComponent.metadata.json5';
import messages from './AQQuoteTableComponent.messages';
import AQPaymentTableUtil from '../../utils/AQPaymentTableUtil';

function AQQuoteTableComponent(props) {
    const {
        quoteTableTitle,
        existingTable,
        tableData,
        renderQuoteTableAction,
        renderIssueAction,
        // renderOneTimePaymentAction,
        currentStep,
        showPremiumColumnValue,
        premiumColumnVisible,
        showDownPaymentColumnValue,
        editBillingHeaderVisible,
        downPaymentColumnVisible,
        showFeeColumnValue,
        feeColumnVisible,
        showStatusColumnValue,
        statusColumnVisible,
        renderBillAccountCellFn,
        dueDayOfMonthColumnPath,
        primaryPayerColumnPath,
        quoteTableTitleVisible,
        invoiceStreamDueDayOfMonthColumnPath,
        invoiceStreamPrimaryPayerColumnPath,
        actionColumnProportion,
        billingMethodColumnVisible
    } = props;

    const translator = useTranslator();
    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            quoteTableSection: {
                className: styles.maxWidth
            },
            quoteTableTitle: {
                content: quoteTableTitle || translator(messages.quotes),
                visible: quoteTableTitleVisible
            },
            quoteDataTable: {
                data: tableData
            },
            billAccountColumn: {
                sortable: false,
                header: translator(messages.billAccount),
                renderCell: renderBillAccountCellFn
            },
            policyColumn: {
                sortable: false,
                header: translator(messages.policy),
                path: 'product',
                renderCell: AQPaymentTableUtil.renderPolicyCell
            },
            premiumColumn: {
                sortable: false,
                header: showPremiumColumnValue ? translator(messages.premium) : ' ',
                renderCell: showPremiumColumnValue ? AQPaymentTableUtil.renderPremiumCell
                    : AQPaymentTableUtil.renderEmptyCell,
                visible: premiumColumnVisible
            },
            feeColumn: {
                sortable: false,
                header: showFeeColumnValue ? translator(messages.fee) : ' ',
                renderCell: showFeeColumnValue ? AQPaymentTableUtil.renderFeeCell
                    : AQPaymentTableUtil.renderEmptyCell,
                visible: feeColumnVisible
            },
            dueDayOfMonthColumn: {
                path: dueDayOfMonthColumnPath,
                sortable: false,
                header: translator(messages.dueDayOfMonth),
                condition: (data) => {
                    const isCLProduct = WniProductsUtil.isCLProduct(_.get(data, 'product.productCode'));
                    if (isCLProduct) {
                        return dueDayOfMonthColumnPath;
                    }
                    const invoiceStreamCode = _.get(data, 'invoiceStreamCode', '');
                    return invoiceStreamCode ? invoiceStreamDueDayOfMonthColumnPath : dueDayOfMonthColumnPath
                },
                renderCell: AQPaymentTableUtil.renderConditionCell
            },
            paymentPlanColumn: {
                paymentUtil: PaymentUtil,
                sortable: false,
                header: translator(messages.paymentPlan),
                renderCell: AQPaymentTableUtil.renderPaymentPlanCell
            },
            paymentMethodColumn: {
                sortable: false,
                header: translator(messages.paymentMethod),
                columnClassName: styles.wrapEverywhere,
                renderCell: AQPaymentTableUtil.renderPaymentMethodCell
            },
            primaryPayerColumn: {
                path: primaryPayerColumnPath,
                sortable: false,
                header: translator(messages.primaryPayer),
                condition: (data) => {
                    const isCLProduct = WniProductsUtil.isCLProduct(_.get(data, 'product.productCode'));
                    if (isCLProduct) {
                        return primaryPayerColumnPath;
                    }
                    const invoiceStreamCode = _.get(data, 'invoiceStreamCode', '');
                    return invoiceStreamCode ? invoiceStreamPrimaryPayerColumnPath : primaryPayerColumnPath
                },
                renderCell: AQPaymentTableUtil.renderConditionCell
            },
            downpaymentColumn: {
                sortable: false,
                header: showDownPaymentColumnValue ? translator(messages.downpayment) : ' ',
                renderCell: showDownPaymentColumnValue ? AQPaymentTableUtil.renderDownpaymentCell
                    : AQPaymentTableUtil.renderEmptyCell,
                visible: downPaymentColumnVisible
            },
            statusColumn: {
                sortable: false,
                header: showStatusColumnValue ? translator(messages.status) : ' ',
                renderCell: showStatusColumnValue ? AQPaymentTableUtil.renderStatusCell
                    : AQPaymentTableUtil.renderEmptyCell,
                visible: statusColumnVisible
            },
            quoteTableActionColumn: {
                sortable: !editBillingHeaderVisible,
                renderCell: renderQuoteTableAction,
                header: editBillingHeaderVisible ? translator(messages.editBilling): ' ',
                columnProportion: actionColumnProportion
                // visible: currentStep === 'step1' && !existingTable
            },
            billingMethodColumn: {
                sortable: false,
                header: translator(messages.billingMethod),
                renderCell: billingMethodColumnVisible ? AQPaymentTableUtil.renderBillingMethodCell
                    : AQPaymentTableUtil.renderEmptyCell,
                visible: billingMethodColumnVisible
            }
            // issueActionColumn: {
            //     renderCell: renderIssueAction,
            //     visible: currentStep === 'step2' && !existingTable
            // },
            // oneTimePayment: {
            //     renderCell: renderOneTimePaymentAction,
            //     visible: currentStep === 'step2' && existingTable
            // }
        };
    }, [quoteTableTitle, translator, quoteTableTitleVisible, tableData,
        renderBillAccountCellFn, showPremiumColumnValue, premiumColumnVisible,
        showFeeColumnValue, feeColumnVisible, dueDayOfMonthColumnPath,
        primaryPayerColumnPath, showDownPaymentColumnValue, downPaymentColumnVisible,
        showStatusColumnValue, statusColumnVisible, editBillingHeaderVisible,
        renderQuoteTableAction, invoiceStreamDueDayOfMonthColumnPath,
        invoiceStreamPrimaryPayerColumnPath, billingMethodColumnVisible, actionColumnProportion]);

    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{}}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

AQQuoteTableComponent.propTypes = {
    quoteTableTitle: PropTypes.string,
    existingTable: PropTypes.bool,
    tableData: PropTypes.arrayOf(PropTypes.shape({})),
    renderQuoteTableAction: PropTypes.func,
    renderIssueAction: PropTypes.func,
    renderOneTimePaymentAction: PropTypes.func,
    currentStep: PropTypes.string,
    showPremiumColumnValue: PropTypes.bool,
    premiumColumnVisible: PropTypes.bool,
    showDownPaymentColumnValue: PropTypes.bool,
    editBillingHeaderVisible: PropTypes.bool,
    downPaymentColumnVisible: PropTypes.bool,
    showFeeColumnValue: PropTypes.bool,
    feeColumnVisible: PropTypes.bool,
    showStatusColumnValue: PropTypes.bool,
    statusColumnVisible: PropTypes.bool,
    renderBillAccountCellFn: PropTypes.func,
    dueDayOfMonthColumnPath: PropTypes.string,
    primaryPayerColumnPath: PropTypes.string,
    quoteTableTitleVisible: PropTypes.bool,
    invoiceStreamDueDayOfMonthColumnPath: PropTypes.string,
    invoiceStreamPrimaryPayerColumnPath: PropTypes.string,
    actionColumnProportion: PropTypes.number
};

AQQuoteTableComponent.defaultProps = {
    quoteTableTitle: '',
    existingTable: false,
    tableData: [],
    renderQuoteTableAction: null,
    renderIssueAction: null,
    renderOneTimePaymentAction: null,
    currentStep: 'step1',
    showPremiumColumnValue: false,
    premiumColumnVisible: false,
    showDownPaymentColumnValue: false,
    downPaymentColumnVisible: false,
    editBillingHeaderVisible:false,
    showFeeColumnValue: false,
    feeColumnVisible: false,
    showStatusColumnValue: false,
    statusColumnVisible: false,
    renderBillAccountCellFn: _.noop,
    dueDayOfMonthColumnPath: 'dueDayOfTheMonth',
    primaryPayerColumnPath: 'primaryPayer.displayName',
    quoteTableTitleVisible: true,
    invoiceStreamDueDayOfMonthColumnPath: 'invoiceStreamDay',
    invoiceStreamPrimaryPayerColumnPath: 'invoiceStreamPrimaryPayer.displayName',
    actionColumnProportion: 1
};
export default AQQuoteTableComponent;
