import { defineMessages } from 'react-intl';

export default defineMessages({
    companyName: {
        id: 'quoteandbind.bop.views.bop-confirmation.CompanyName',
        defaultMessage: 'Company Name'
    },
    addressOfBusiness: {
        id: 'quoteandbind.bop.views.contact-details.Enter the address of the business that you want to insure.',
        defaultMessage: 'Enter the address of the business that you want to insure.'
    },
    email: {
        id: 'quoteandbind.views.contact-details.Email Address',
        defaultMessage: 'Email Address'
    },
    forYourQuote: {
        id: 'quoteandbind.views.contact-details.For Your Quote',
        defaultMessage: 'For Your Quote'
    },
    startDate: {
        id: 'quoteandbind.views.contact-details.I\'d like my coverage to start on',
        defaultMessage: 'Coverage Start Date'
    },
    organisationType: {
        id: 'quoteandbind.views.contact-details.organizationType',
        defaultMessage: 'Organization Type'
    },
    organisationTypePlaceholder: {
        id: 'quoteandbind.bop.views.contact-details.-- Please Choose --',
        defaultMessage: '-- Please Choose --'
    },
    smallBusiness: {
        id: 'quoteandbind.views.contact-details.smallBusinessType',
        defaultMessage: 'Small Business Type'
    }
});
