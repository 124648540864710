import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imFineArtsFloaterService'), method, data, additionalHeaders);
}

export default class IMFineArtsFloaterService {
  
    /**
     * Invokes IMFineArtsFloaterHandler.getFineArtsFloaterClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getFineArtsFloaterClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getFineArtsFloaterClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.getFineArtsFloaterClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getFineArtsFloaterClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getFineArtsFloaterClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.updateFineArtsFloaterClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} fineArtsFloaterToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateFineArtsFloaterClauses(jobNumber, sessionUUID, fineArtsFloaterToUpdate, authHeader = {}) {
        return processSubmission('updateFineArtsFloaterClauses', [jobNumber, sessionUUID, fineArtsFloaterToUpdate], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.updateAdditionalInterestScheduleInsideScheduleItem()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} schedulePublicID
     * @param {Object} scheduleDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateAdditionalInterestScheduleInsideScheduleItem(jobNumber, sessionUUID, schedulePublicID, scheduleDTO, authHeader = {}) {
        return processSubmission('updateAdditionalInterestScheduleInsideScheduleItem', [jobNumber, sessionUUID, schedulePublicID, scheduleDTO], authHeader)
    }

    /**
     * Invokes IMFineArtsFloaterHandler.updateFineArtsFloaterSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateFineArtsFloaterSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateFineArtsFloaterSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.onFineArtsFloaterNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onFineArtsFloaterNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onFineArtsFloaterNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMFineArtsFloaterHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }
}