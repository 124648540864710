import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { DropdownMenuHeader } from '@jutro/components';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GLPolicyDetailsChangeService } from 'wni-capability-policychange-gl';
import {
    ValidationIssueUtil,

    WindowUtil,
    QuoteUtil,
    WniDateUtil,
    WizardPageJumpUtil,
    ServiceErrorUtil,
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    MailingAddressInputPopupComponent
} from 'wni-capability-gateway-react';
import { useWniModal } from 'wni-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardConstants } from 'wni-portals-config-js';

import { WizardErrorContext } from 'wni-portals-wizard-react';
import { BRCLMessages }from 'wni-platform-translations';

import moment from 'moment';
import {
    GLWizardPage as WizardPage
} from 'wni-capability-quoteandbind-gl-react';


import metadata from './GLPolicyDetailsChangePage.metadata.json5';

import { DropdownMenuLink } from '@jutro/router';

function GLPolicyDetailsChangePage(props) {
    const wizardErrorContext = useContext(WizardErrorContext);
    const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        wizardSnapshot,
        savePolicyDetailsData,

        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        // clearVisitedStepsFromQuotePage,
        markFollowingWizardStepsUnvisited,
        isReadOnly = false,
    } = props;

    const {
        updateWizardIssues,
    } = wizardErrorContext;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        changeData: {
            maximumEffectiveDate
        }
    } = submissionVM.value;

    const {
        // baseState_Ext: baseState,
        // quoteFlow_Ext: quoteFlow,
        effectiveDate_Ext: transactionEffectiveDate,
        policyAddressCandidates_Ext: policyAddressCandidates
        // productCode
    } = baseData;

    const {
        [WizardConstants.landingPage]: landingPage,
        // [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    // const history = useHistory();
    const translator = useTranslator();
  
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('GLPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

 
    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
        return maxDate
    }, [submissionVM, maximumEffectiveDate])

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate();
        const maxDate = effectiveMaxDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf();
        
        return ((minDif >= 0) && (maxDif >= 0))
    }, [effectiveMaxDate, submissionVM]);
    
    

    const GLPolicyDetailsValidation = useCallback(() => {
        return isEffectiveDateValid()
    }, [isEffectiveDateValid]);

    // =======================================================================
    const pageOnInit = useCallback(() => {
        if (landingPage) {
            //gray out all following pages when first enter
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, 'changeData.description', '');
            updateSubmissionVM(newSubmissionVM);
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'GLPolicyDetailsPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            // clearVisitedStepsFromQuotePage();
            markFollowingWizardStepsUnvisited(); // change reason required
            //
            setLoadingMask(false);
        }
    }, []);
    // =======================================================================

    useEffect(() => {
        // A better solution might be expected in the future
        updateWizardIssues([{
            type: 'info',
            reason: translator(BRCLMessages.BR_POI_53097),
        }]);
        //
        pageOnInit();
    }, []);

    useEffect(() => {
        registerComponentValidation(GLPolicyDetailsValidation);
    }, [registerComponentValidation, transactionEffectiveDate]);


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );


    const initialEffectiveDate = _.get(wizardSnapshot.value, 'baseData.effectiveDate_Ext')
    const getOOSPeriods = useCallback(() => {
        const oOSSliceDates = _.get(submissionVM.value, 'changeData.oossliceDates_Ext');
            let oOSStartDate = _.get(wizardSnapshot.value, 'baseData.periodStartDate')
            let oOSEndDate = _.get(wizardSnapshot.value, 'baseData.periodEndDate')
            
            const oOSSliceDatesInMoment = oOSSliceDates.map((res) => {
                return moment(res)
            })
            const sortOOSSliceDatesInMoment = oOSSliceDatesInMoment.sort((a, b) => a.diff(b))
            const oOSEndDateIndex = sortOOSSliceDatesInMoment.findIndex((res) => res.isAfter(moment(initialEffectiveDate)))
            if (oOSEndDateIndex !==-1 ) { 
                oOSEndDate = sortOOSSliceDatesInMoment[oOSEndDateIndex] 
                if (oOSEndDateIndex > 0 ) { 
                oOSStartDate = sortOOSSliceDatesInMoment[oOSEndDateIndex-1]
                }
            }
            if (oOSEndDateIndex ===-1 && (sortOOSSliceDatesInMoment.length>0 )) {
                oOSStartDate = sortOOSSliceDatesInMoment[sortOOSSliceDatesInMoment.length-1]
            }
            return {oOSStartDate, oOSEndDate}
    }, [initialEffectiveDate, submissionVM.value, wizardSnapshot.value])

    
    const effectiveMinDate = useCallback(() => {
        const {oOSStartDate} = getOOSPeriods()
        return WniDateUtil.getDateObj(oOSStartDate)
    }, [getOOSPeriods])

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);

        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        try {
            const res = await savePolicyDetailsData(
                quoteID, 
                sessionUUID,
                quoteBaseData,
                authHeader);
            setLoadingMask(false);
            
            // Next step: move this and the service call into HOWizardPage as a common feature
            const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
            const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
            updateValidationIssues(newValidationIssues);
    
            const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
            const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
            if(hasValidationWarning && !displayWarnings) {
                updateDisplayWarnings(true);
                return false;
            }
    
            if (hasValidationError) {
                WindowUtil.scrollToWizardErrors();
                return false;
            }

            submissionVM.value = res;
            updateSubmissionVM(submissionVM);
            
            return submissionVM;
        } catch (e) {
            return modalApi.showConfirm({
                title: 'Error',
                message: ServiceErrorUtil.getErrorMessage(e),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok,
            }).then(() => {
                // return false to aviod error keeping throw to ErrorBoundary
                return false;
            }).catch(() => {
                // return false to aviod error keeping throw to ErrorBoundary
                return false;
            })
        } finally {
            // hide loading mask
            setLoadingMask(false);
        }
    }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings, modalApi]);

    const getMailingAddressDataAndDisplay = useCallback(() => {

        const policyAddress= _.get(submissionVM.value, 'baseData.policyAddress.displayName')
        return (
                <div>{policyAddress}</div>
        );
    }, [submissionVM.value]);

    const getAvailableValues = (path) => {
        const options = _.get(submissionVM.baseData.accountHolder,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };

    const onExistingAddressClick = (elt) => {
        const newVM = viewModelService.clone(submissionVM)
        _.set(newVM, 'baseData.policyAddress', elt)
        updateSubmissionVM(newVM)
    }

    const showMailingAddressPopupModal = useCallback(() => {
        const componentProps = {
            title: 'Add New Address',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            viewModelService,
            showEmployeeFlagToggle: false,
            authHeader,
            sessionUUID,
            jobID: quoteID,
            onValueChange: writeValue,
        };
        return modalApi.showModal(
            <MailingAddressInputPopupComponent {...componentProps} />
        );
    }, [viewModelService, authHeader, sessionUUID, quoteID, writeValue, modalApi]);

    const openAddMailingAddressPopup = useCallback(() => {
        showMailingAddressPopupModal()
            .then((newAddress) => {
                const newSubmissionVM = _.clone(submissionVM);
                const originPolicyAddress = _.clone(_.get(submissionVM, 'baseData.policyAddress'))
                const newCandidates = policyAddressCandidates.concat(originPolicyAddress.value)
                _.set(newSubmissionVM.value, 'baseData.policyAddress', newAddress.value);
                _.set(newSubmissionVM.value, 'baseData.policyAddressCandidates_Ext', newCandidates)

                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    },[showMailingAddressPopupModal, submissionVM, updateSubmissionVM, policyAddressCandidates]);

    const generateMailingAddress = useCallback(() => {
        const retval = policyAddressCandidates?.map((address) => (
            <DropdownMenuLink
                key={address.publicID}
                // disabled={!product.available}
                onClick={() => onExistingAddressClick(address)}
            >
                {address.displayName}
            </DropdownMenuLink>
        ));
        return <>
            <DropdownMenuLink
                key='new'
                onClick = {openAddMailingAddressPopup}
            >Add New Address 
            </DropdownMenuLink>
            <DropdownMenuHeader title='Existing Address' />
            {retval}
            </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAddMailingAddressPopup, policyAddressCandidates, submissionVM])

    const addressMenuContent = generateMailingAddress()

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                // onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true,
                    licensedAgentReadOnly: true,
                    servicingAgentReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            mailingAddress: {
                value: getMailingAddressDataAndDisplay(),
            },
            mailingAddressEdit: {
                content: addressMenuContent,
                visible: !isReadOnly
            },
            organizationTypeMultiSelect: {
                availableValues: getAvailableValues('organizationTypesOption_Ext')
            },
            effectiveDate: {
                minDate: effectiveMinDate(),
                maxDate: effectiveMaxDate(),
                showErrors,
                disabled: isReadOnly
            },
        
            
        }
    }, [addressMenuContent, getMailingAddressDataAndDisplay, isReadOnly, onValidate, submissionVM.baseData]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: {

        },
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

GLPolicyDetailsChangePage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
};

GLPolicyDetailsChangePage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: GLPolicyDetailsChangeService.saveGLPolicyDetailsData,
    // readOnlyFields: {
    //     // effectiveDate: false,
    //     agencyOfRecord: true,
    //     // uwqReadonly: false
    // }
} 
export default GLPolicyDetailsChangePage;