import _ from 'lodash';
import { ErrorsAndWarningsUtil, IssuanceValidationUtil } from 'wni-portals-util-js';

const flowStepId = 'HOPDwelling';
const requiredForIssuranceAllFields = [
    {
        id: 'constructionOption',
        path: 'construction.hopconstructionOption_Ext'
    },
    {
        id: 'numberofStalls',
        path: 'construction.stallsNumber_Ext'
    },
    {
        id: 'swimmingPoolType',
        path: 'liabilityExposures.swimmingPoolType'
    },
    {
        id: 'primaryHeating',
        path: 'construction.primaryHeatingType'
    },
    {
        id: 'secondaryHeating',
        path: 'construction.secondaryHeatingType_Ext'
    },
    {
        id: 'ampService',
        path: 'construction.ampService_Ext'
    },
    {
        id: 'solidFuelBurningDevice',
        path: 'construction.solidFuelBurningDevice_Ext'
    }
];

const readonlyRCTRequiredField = [
    {
        id: "basementFinished",
        path: "basementFinishedPercentage_Ext",
    },
];
function generateErrorMessages(
    {
        dwellingVM,
        translator,
        messages,
        isRequiredForIssuance
    }
) {
    if (!isRequiredForIssuance) {
        return [];
    };
    const newRequiredForIssuranceFields = requiredForIssuranceAllFields.filter((field) => {
        const val = _.get(dwellingVM, field.path);
        return _.isEmpty(val.value)
    });
    const errorValidationMessages = newRequiredForIssuranceFields.map((item) => {
        return {
            type: 'error',
            reason: `Below required fields for issuance are missing:${translator(messages[item.id])}`
        };
    });
    return errorValidationMessages;
}

function getReadonlyRCTRequiredError(translator, messages) {
    return readonlyRCTRequiredField.map((item) => {
        return {
            flowStepId,
            type: 'error',
            reason: `Below required fields for issuance are missing:${translator(messages[item.id])}`
        }
    })
}

function getDwellingNotifications(
    {
        dwellingVM,
        translator,
        messages,
        isRequiredForIssuance
    }
) {
    // const warningMessagesForField = validateFieldsBlockingIssuance({
    //     dwellingVM,
    //     translator,
    //     messages
    // });
    const errorMessages = generateErrorMessages({
        dwellingVM,
        translator,
        messages,
        isRequiredForIssuance
    });

    return [
        // ...warningMessagesForField.map((text) => {
        //     return {
        //         flowStepId: flowStepId,
        //         type: 'warning',
        //         reason: text
        //     };
        // }),
        ...errorMessages.map((error) => {
            return {
                flowStepId: flowStepId,
                type: 'error',
                reason: error.reason
            };
        })
    ];
}

function isIssueOnResidencePage(validationIssue = {}) {
    const {
        flowStepId: issueFlowStepId
    } = validationIssue;
    return flowStepId === issueFlowStepId;
}

function getAllValidationIssues(submissionVM, errorsAndWarnings, translator, messages, isRequiredForIssuance) {
    const backendIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings) || [];
    const dwellingVM = _.get(submissionVM, 'lobData.homeowners.coverables.dwelling_Ext');
    const frontendIssues = getDwellingNotifications({
        dwellingVM,
        translator,
        messages,
        isRequiredForIssuance
    }) || [];;
    const validationIssues = _.uniqBy(frontendIssues.concat(backendIssues), 'reason');

    const residenceValidationIssues = validationIssues.filter(isIssueOnResidencePage);
    const filterIssuesType = IssuanceValidationUtil.getIssuesMap(residenceValidationIssues);

    return filterIssuesType;
}

function getValidationIssues(validationIssues) {
    // POI-25579
    return _.filter(validationIssues, (issue) => {
        return !_.startsWith(issue.reason, 'Offering has been updated from Plus to Core');
    })
}

export default {
    getAllValidationIssues,
    getReadonlyRCTRequiredError,
    getValidationIssues
};