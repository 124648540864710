import React from 'react';
import { IconButton } from '@jutro/legacy/components';

function ModalHeader(props) {
    const { title, subTitle, className = '', onClose, children } = props;

    return (
        <div className={`wni__ModalHeader__modalHeader ${className}`}>
            <div className="wni__ModalHeader__modalStatus" />
            <div className="wni__ModalHeader__contentLayout">
                {onClose && (
                    <IconButton
                        className="wni__ModalHeader__closeButton"
                        icon="gw-close"
                        onClick={onClose}
                    />
                )}
                {children ? (
                    <div className="wni__ModalHeader__title">{children}</div>
                ) : (
                    <>
                        <div className="wni__ModalHeader__title font-PrimaryDark-bold-20">
                            {title}
                        </div>
                        <div className="wni__ModalHeader__subTitle">
                            {subTitle}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ModalHeader;
