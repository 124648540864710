import React from 'react';
import { BasePolicyChangeQuoteComponent } from 'wni-components-platform-react';

function PolicyChangeQuoteComponent(props) {
    return (
        <BasePolicyChangeQuoteComponent {...props} />
    );
}

PolicyChangeQuoteComponent.propTypes = {
    ...BasePolicyChangeQuoteComponent.propTypes,
};
PolicyChangeQuoteComponent.defaultProps = {
    ...BasePolicyChangeQuoteComponent.defaultProps,
};
export default PolicyChangeQuoteComponent;
