import { defineMessages } from 'react-intl';

export default defineMessages({
    modifierRangeInfo: {
        id : 'wni.quoteandbind.cr-wizard.cr-statespecific.modifierRangeInfo',
        defaultMessage: 'The system allows a total request of {max} credit or debit. For requests outside of that range, please contact an underwriter.'
    },
    creditDebitOutOfRange: {
        id : 'wni.quoteandbind.cr-wizard.cr-statespecific.creditDebitOutOfRange',
        defaultMessage: 'The selected modifier amounts are out of acceptable ranges for each category, please correct this before proceeding. To be approved for modifiers beyond the acceptable range please contact your underwriter.'
    },
    justificationsRequired: {
        id : 'wni.quoteandbind.cr-wizard.cr-statespecific.justificationsRequired',
        defaultMessage: 'Please enter a justification for any entered credits or debits before proceeding'
    },
    invalidRateFactorError: {
        id : 'wni.quoteandbind.cr-wizard.cr-statespecific.invalidRateFactorError',
        defaultMessage: 'Please enter a valid percentage.'
    },
    unEditableCategory: {
        id : 'wni.quoteandbind.cr-wizard.cr-statespecific.unEditableCategory',
        defaultMessage: "Dispersion or Concentration of Values Insured: Insured property is, or is not, sufficiently dispersed to minimize the probability of loss from the same cause"
    }
});