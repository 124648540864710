import { defineMessages } from 'react-intl';

export default defineMessages({
    lineConditions: {
        id: 'wni.quoteandbind.WC.wizard.QuoteClauseTable.Line Conditions',
        defaultMessage: 'Line Conditions',
    },
    lineDetails: {
        id: 'wni.quoteandbind.WC.wizard.QuoteClauseTable.Line Details',
        defaultMessage: 'Line Details',
    },
});