import { defineMessages } from 'react-intl';

export default defineMessages({
    AllCoveragesCategory: {
        "id": "wni.cu.wizard.crime.coveragePopup.All",
        "defaultMessage": "All"
    },
    SearchCoveragesForCoverableTitle: {
        "id": "wni.cu.wizard.crime.coveragePopup.Search Coverages For Coverable",
        "defaultMessage": "Search Coverages for {coverableName}"
    },
    SearchExclusionsAndConditionsForCoverableTitle: {
        "id": "wni.cu.wizard.crime.coveragePopup.Search Exclusions And Conditions For Coverable",
        "defaultMessage": "Search Exclusions And Conditions for {coverableName}"
    },
    CoverableAdditionalCoverageCategory: {
        "id": "wni.cu.wizard.crime.coveragePopup.Additional Coverages",
        "defaultMessage": "{coverableCategory} Additional Coverages"
    },
    CoverableConditionsCategory: {
        "id": "wni.cu.wizard.crime.coveragePopup.Coverable Conditions",
        "defaultMessage": "{coverableCategory} Conditions"
    },
    CoverableExclusionsCategory: {
        "id": "wni.cu.wizard.crime.coveragePopup.Coverable Exclusions",
        "defaultMessage": "{coverableCategory} Exclusions"
    },
    coveragesButtonTitle: {
        "id": "wni.cu.wizard.crime.coveragePopup.Coverages",
        "defaultMessage": "Coverages"
    },
    exclusionsAndConditionsButtonTitle: {
        "id": "wni.cu.wizard.crime.coveragePopup.Exclusions & Conditions",
        "defaultMessage": "Exclusions & Conditions"
    }
});