import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './UploadAttachmentModal.metadata.json5';

import { Button } from '@jutro/legacy/components';

function UploadAttachmentModal(props) {
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        size,
        uploadAttachmentVM: uploadAttachment,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        breakpoint,
        productCode
    } = props;
    const [uploadAttachmentVM, updateUploadAttachmentVM] = useState();
    const [showErrors, updateShowErrors] = useState(false);
    const [uploadFiles, updateUploadFiles] = useState();
    const translator = useTranslator();

    const {
        isComponentValid,
        onValidate 
    } = useValidation('UploadAttachmentModal');

    useEffect(() => {
        const newVM = viewModelService.clone(uploadAttachment);
        updateUploadAttachmentVM(newVM)
    }, []);
    const writeValue = useCallback(
        (value, path) => {
            const newUploadAttachmentVM = viewModelService.clone(uploadAttachmentVM);
            _.set(newUploadAttachmentVM, path, value);
            updateUploadAttachmentVM(newUploadAttachmentVM);
        },
        [uploadAttachmentVM, viewModelService]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
        },
        [updateShowErrors]
    );

    const handleSave = useCallback(
        () => {
            if (!isComponentValid || !uploadFiles) {
                handleValidation();
            } else {
                onResolve({ file: uploadFiles, vm: uploadAttachmentVM });
            }
        }, [uploadAttachmentVM, handleValidation, isComponentValid, onResolve, uploadFiles]
    );

    const renderDocumentTypes = () => {
        const documentTypeOptions = _.get(uploadAttachmentVM, 'documentType.aspects.availableValues', [])
        const PAContingencyTypes = ['CDDC_Ext', 'CPCI_Ext', 'C_photos_physical_damage_Ext', 'contingency_grades_Ext'];
        const HOContingencyTypes = ['CAC_Ext', 'CHIR_Ext', 'CMSD_Ext', 'CPDC_Ext', 'C_HP_detached_structure_Ext', 'C_HP_front_back_Ext'];
        const DPContingencyTypes = ['CHIR_Ext', 'CMSD_Ext', 'CPDC_Ext', 'C_HP_detached_structure_Ext', 'C_HP_front_back_Ext'];
        const RTContingencyTypes = ['CPSC_Ext', 'C_photos_physical_damage_Ext'];
        const WTContingencyTypes = ['CPSC_Ext', 'C_photos_physical_damage_Ext'];
        const LOBCtrlTypes = [
            ...PAContingencyTypes,
            ...HOContingencyTypes,
            ...DPContingencyTypes,
            ...RTContingencyTypes,
            ...WTContingencyTypes,
        ]
        const resTypes = documentTypeOptions.filter(item => {
            if (LOBCtrlTypes.includes(item.code)) {   
                switch (productCode) {
                    case 'PersonalAuto': {
                        return PAContingencyTypes.includes(item.code);
                    }
                    case 'HOPHomeowners': {
                        return HOContingencyTypes.includes(item.code);
                    }
                    case 'Watercraft': {
                        return WTContingencyTypes.includes(item.code);
                    }
                    case 'RoadTrail': {
                        return RTContingencyTypes.includes(item.code);
                    }
                    case 'DwellingProperty': {
                        return DPContingencyTypes.includes(item.code);
                    }
                    default:{
                        return false;
                    }
                }
            }
            return true;
        });
        const availableValues = resTypes.map(item => ({
            code: item.code,
            name: translator({id: item.name})
        }))
        return availableValues;
    }

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: false,
            showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        documentType: {
            availableValues: renderDocumentTypes()
        },
        uploadDocumentsDrop: {
            singleUpload: true,
            uploadConfig: {
                uploadFiles,
                updateUploadFiles,
                required: true
            }
        }
        
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            uploadAttachmentVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            // onUploadDocumentDrop: onUploadDocumentDrop
        }
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="uploadAttachmentModal">
                <ViewModelForm
                    model={uploadAttachmentVM}
                    overrideProps={overrideProps}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

UploadAttachmentModal.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    uploadAttachmentVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};
UploadAttachmentModal.defaultProps = {
    size: 'md'
};

export default UploadAttachmentModal;
