import React, {
    useEffect
} from 'react'
import { WniDropdownSelect } from 'wni-common-base-components';
import { useValidation } from '@xengage/gw-portals-validation-react';

export const DetailsDropdownSelect = (props) => {

    const {
        sublinePublicID,
        displayable,
        detailsIndex,
        onValidate,
        disabled,
        showErrors,
        isEditable,
        changeDetailsAndSync,
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        rangeValue,
        rangeValueOptions = [],
    } = displayable

    const isReadOnly = !isDisplayableEditable || !isEditable;

    const {
        isComponentValid,
        onValidate: onValidationChange
    } = useValidation(propertyName);

    useEffect(() => {
        if (onValidate && !isReadOnly) {
            onValidate(isComponentValid, propertyName);
        }
        return () => {
            if (onValidate) {
                onValidate(true, propertyName);
            }
        };
    }, [isComponentValid, isReadOnly, onValidate, propertyName, rangeValue, required]);

    const onDynamicRangeValueChange = (newValue) => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            rangeValue: newValue
        }
        changeDetailsAndSync(newDisplayable, detailsIndex)
    }

    return <WniDropdownSelect
        id={`${sublinePublicID}_displayable_dynamicrange_${propertyName}`}
        labelPosition='left'
        label={label}
        value={rangeValue}
        availableValues={rangeValueOptions.map((v) => {
            return {code: v,name: v}
        })}
        onValueChange={onDynamicRangeValueChange}
        placeholder=""
        disabled={disabled}
        size="medium"
        className='flex-1'
        required={required}
        showRequired
        onValidationChange={onValidationChange}
        showErrors={showErrors}
        readOnly={isReadOnly}
    />
}