
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ConfigUtil } from 'wni-portals-util-js';

import AnimalsUtil from '../../util/AnimalsUtil';
import metadata from './PUAnimalForm.metadata.json5';
import messages from './PUAnimalForm.messages'

const PUAnimalForm = (props) => {
    const {
        model: dwellingAnimalsVM,
        currentAnimalVM,
        isReadOnly,
        updateCurrentAnimalVM,
        updateAnimalsVM,
        viewModelService,
        originAnimalVM,
        isSubmissionType,
        onValueChange,
    } = props;

    const translator = useTranslator();
    const [loadingAnimals, updateLoadingAnimals] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        animalType,
        animalBreed
    } = currentAnimalVM.value || {};

    const {
        isChickenCoopHeated,
    } = dwellingAnimalsVM.value || {};

    const {
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('PUAnimalForm');

    const IsValidFields = useCallback(() => {
        if (!dwellingAnimalsVM.aspects.valid || !dwellingAnimalsVM.aspects.subtreeValid) {
            return false;
        }
        return true;
    }, [dwellingAnimalsVM, currentAnimalVM]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, dwellingAnimalsVM, currentAnimalVM]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const cancelAnimals = () => {
        const newDwellingAnimalsVM = viewModelService.clone(dwellingAnimalsVM);
        const animals = _.get(newDwellingAnimalsVM, 'animals.value');

        const removeNullAnimals = _.filter(animals, (animal) => {
            return animal.rowIdPath
        })
        _.set(newDwellingAnimalsVM, 'animals.value', removeNullAnimals);

        if (_.get(originAnimalVM, 'value.rowIdPath')) {
            const index = _.findIndex(animals, (animal) => { return animal.rowIdPath === _.get(originAnimalVM, 'value.rowIdPath')})
            _.set(newDwellingAnimalsVM, `animals.value.${index}`, originAnimalVM.value);
        }

        updateAnimalsVM(newDwellingAnimalsVM);
        updateCurrentAnimalVM(null);
    };

    const handleAnimalChange = useCallback((value, path) => {
        const newVM = viewModelService.clone(currentAnimalVM);
        if (path === 'animalType') {
            const availableBreed = AnimalsUtil.getAvailableBreed(
                _.get(currentAnimalVM, 'animalBreed.aspects.availableValues'), value, translator
            );
            const hasBreedOption = _.find(availableBreed, (val) => {
                return val.code === animalBreed
            });
            if (!hasBreedOption) {
                _.set(newVM, 'animalBreed', null);
            }

            if (value !== 'Dog') {
                _.set(newVM, 'animalBreed', 'none_Ext');
            }
        }
        _.set(newVM, path, value);
        updateCurrentAnimalVM(newVM);

        const newDwellingAnimalsVM = viewModelService.clone(dwellingAnimalsVM);
        const animals = _.get(newDwellingAnimalsVM, 'animals.value');
        const newAnimals = _.map(animals, (animal) => {
            if (!animal.rowIdPath || animal.rowIdPath === newVM.value.rowIdPath) {
                return newVM.value;
            }
            return animal;
        })
        _.set(newDwellingAnimalsVM, `animals.value`, newAnimals);
        updateAnimalsVM(newDwellingAnimalsVM);
    }, [animalBreed, currentAnimalVM, dwellingAnimalsVM]);

    const describeFieldforDogBreeds = animalType === 'Dog' && (animalBreed === 'Other' || animalBreed === 'AMixOfAnyOfTheseBreeds')
    const newDogEntry = currentAnimalVM.publicID.value === undefined

    const handleDwellingAnimalChange = useCallback((value, path) => {
        const newVM = viewModelService.clone(dwellingAnimalsVM);
        _.set(dwellingAnimalsVM, path, value);
        updateAnimalsVM(newVM);
    }, [dwellingAnimalsVM]);

    const saveAnimal = useCallback(async () => {
        const animalsPath = 'property.puDwellingAnimals';
        if (!_.get(currentAnimalVM, 'value.rowIdPath')) {
            handleAnimalChange(ConfigUtil.getUuid(), 'rowIdPath');
        }
        onValueChange(dwellingAnimalsVM.value, animalsPath);
        updateCurrentAnimalVM(null);
    }, [currentAnimalVM, dwellingAnimalsVM]);

    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        cancelAnimals: {
            onClick: cancelAnimals
        },
        saveAnimal: {
            onClick: isComponentValid ? saveAnimal : handleValidation
        },
        animalBreed: {
            availableValues: AnimalsUtil.getAvailableBreed(
                _.get(currentAnimalVM, 'animalBreed.aspects.availableValues'), animalType, translator
            ),
            visible: animalType === 'Dog'
        },
        animalBiteHistory: {
            visible: animalType === 'Dog'
        },
        animalDescribe: {
            visible: animalType === 'Poultry_Ext' || animalType === 'Livestock_Ext' || animalType === 'Other' || describeFieldforDogBreeds,
            tooltip: translator(messages.dogBreedsDescription),
            required: describeFieldforDogBreeds && isSubmissionType === 'Submission' && newDogEntry
        },
        numberofPoultry: {
            visible: animalType === 'Poultry_Ext'
        },
        heatedCoop: {
            visible: animalType === 'Poultry_Ext'
        },
        howFarIsTheChickenCoop: {
            visible: animalType === 'Poultry_Ext' && isChickenCoopHeated === true
        },
        sellOrSharedEggs: {
            visible: animalType === 'Poultry_Ext'
        }
    };

    const resolvers = {
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent.content[0],
            currentAnimalVM,
            id,
            path,
            overrideProps
        );
    };

    const readDwellingAnimals = (id, path) => {
        return readViewModelValue(
            metadata.componentContent.content[1],
            dwellingAnimalsVM,
            id,
            path,
            overrideProps
        );
    };

    if (loadingAnimals) {
        return <Loader loaded={!loadingAnimals} />;
    }

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent.content[0]}
                model={currentAnimalVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValueChange={handleAnimalChange}
                showErrors={showErrors}
            />
            <ViewModelForm
                uiProps={metadata.componentContent.content[1]}
                model={dwellingAnimalsVM}
                overrideProps={overrideProps}
                resolveValue={readDwellingAnimals}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValueChange={handleDwellingAnimalChange}
                showErrors={showErrors}
            />
        </div>
    );
};

PUAnimalForm.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    currentAnimalVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    isReadOnly: PropTypes.bool,
    updateCurrentAnimalVM: PropTypes.func,
    updateAnimalsVM: PropTypes.func
};

PUAnimalForm.defaultProps = {
    model: {},
    currentAnimalVM: {},
    isReadOnly: false,
    updateCurrentAnimalVM: _.noop,
    updateAnimalsVM: _.noop
};

export default PUAnimalForm;
