import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import WCPolicyChangeStartPage from './pages/Start/WCPolicyChangeStartPage';

import WCPaySuccessChangePage from './pages/PaySuccess/WCPaySuccessChangePage';

import WCQualificationChangePage from './pages/Qualification/WCQualificationChangePage';
import WCQualificationChangeReadOnlyPage from './pages/Qualification/WCQualificationChangePage.readonly';

import WCPolicyDetailsChangePage from './pages/PolicyDetails/WCPolicyDetailsChangePage';
import WCPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/WCPolicyDetailsChangePage.readonly';

import WCLocationsChangePage from './pages/Locations/WCLocationsChangePage';
import WCLocationsChangeReadOnlyPage from './pages/Locations/WCLocationsChangePage.readonly';

import WCCoveragesChangePage from './pages/Coverages/WCCoveragesChangePage';
import WCCoveragesChangeReadOnlyPage from './pages/Coverages/WCCoveragesChangePage.readonly';

import WCStateSpecificInformationChangePage from './pages/StateSpecificInformation/WCStateSpecificInformationChangePage';
import WCStateSpecificInformationChangeReadOnlyPage from './pages/StateSpecificInformation/WCStateSpecificInformationChangePage.readonly';

import WCUnderwritingQuestionsChangePage from './pages/UnderwritingQuestions/WCUnderwritingQuestionsChangePage';
import WCUnderwritingQuestionsChangeReadOnlyPage from './pages/UnderwritingQuestions/WCUnderwritingQuestionsChangePage.readonly';

import WCRiskAnalysisChangePage from './pages/RiskAnalysis/WCRiskAnalysisChangePage';
import WCRiskAnalysisChangeReadOnlyPage from './pages/RiskAnalysis/WCRiskAnalysisChangePage.readonly';


// WIZARD PAGE IMPORT ANCHOR

import WCQuoteChangePage from './pages/Quote/WCQuoteChangePage';
import WCQuoteChangeReadOnlyPage from './pages/Quote/WCQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        WCPolicyChangeStartPage: { component: 'WCPolicyChangeStartPage' },
        WCPaySuccessChangePage: { component: 'WCPaySuccessChangePage' },

        WCQualificationChangePage: { component: 'WCQualificationChangePage'},
        WCQualificationChangeReadOnlyPage: { component: 'WCQualificationChangeReadOnlyPage'},

        WCPolicyDetailsChangePage: { component: 'WCPolicyDetailsChangePage'},
        WCPolicyDetailsChangeReadOnlyPage: { component: 'WCPolicyDetailsChangeReadOnlyPage'},

        WCLocationsChangePage: { component: 'WCLocationsChangePage'},
        WCLocationsChangeReadOnlyPage: { component: 'WCLocationsChangeReadOnlyPage'},

        WCCoveragesChangePage: { component: 'WCCoveragesChangePage'},
        WCCoveragesChangeReadOnlyPage: { component: 'WCCoveragesChangeReadOnlyPage'},

        WCStateSpecificInformationChangePage: { component: 'WCStateSpecificInformationChangePage'},
        WCStateSpecificInformationChangeReadOnlyPage: { component: 'WCStateSpecificInformationChangeReadOnlyPage'},

        WCUnderwritingQuestionsChangePage: { component: 'WCUnderwritingQuestionsChangePage'},
        WCUnderwritingQuestionsChangeReadOnlyPage: { component: 'WCUnderwritingQuestionsChangeReadOnlyPage'},

        WCRiskAnalysisChangePage: { component: 'WCRiskAnalysisChangePage'},
        WCRiskAnalysisChangeReadOnlyPage: { component: 'WCRiskAnalysisChangeReadOnlyPage'},


// WIZARD PAGE COMPONENT NAME ANCHOR
        WCQuoteChangePage: { component: 'WCQuoteChangePage' },
        WCQuoteChangeReadOnlyPage: { component: 'WCQuoteChangeReadOnlyPage' },
    },
    {
        WCPolicyChangeStartPage,
        WCPaySuccessChangePage,

        WCQualificationChangePage,
        WCQualificationChangeReadOnlyPage,

        WCPolicyDetailsChangePage,
        WCPolicyDetailsChangeReadOnlyPage,

        WCLocationsChangePage,
        WCLocationsChangeReadOnlyPage,

        WCCoveragesChangePage,
        WCCoveragesChangeReadOnlyPage,

        WCStateSpecificInformationChangePage,
        WCStateSpecificInformationChangeReadOnlyPage,

        WCUnderwritingQuestionsChangePage,
        WCUnderwritingQuestionsChangeReadOnlyPage,

        WCRiskAnalysisChangePage,
        WCRiskAnalysisChangeReadOnlyPage,


// WIZARD PAGE COMPONENT MAP ANCHOR
        WCQuoteChangePage,
        WCQuoteChangeReadOnlyPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as WCPolicyChangeWizard } from './WCPolicyChangeWizard';