import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class OrganizationService {
    static getAvailableOrganizations(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayOrganization'),
            'getAllOrganizations', [], additionalHeaders);
    }

    static getProducerCodeForOrganization(organization, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayOrganization'),
            'getProducerCodeForOrganization', [organization], additionalHeaders);
    }
}
