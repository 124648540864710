import _ from 'lodash';

const ACCOUNTTYPE_PERSONAL = 'Personal';
const ACCOUNTTYPE_COMMERCIAL = 'Commercial';

const PERSONAL_ACCOUNT_CODE = 'person';
const COMMERCIAL_ACCOUNT_CODE = 'company';

const accountMaps = [
    {
        accountIcon: 'person',
        code: PERSONAL_ACCOUNT_CODE,
        name: ACCOUNTTYPE_PERSONAL
    },
    {
        accountIcon: 'business',
        code: COMMERCIAL_ACCOUNT_CODE,
        name: ACCOUNTTYPE_COMMERCIAL
    }
];

const getAvailableAccounts = () => {
    return accountMaps
};

const getAccountItem = (contactType) => {
    return _.find(accountMaps, (item) => item.code === contactType);
};

const getAccountIcon = (contactType) => {
    const accountItem = getAccountItem(contactType) || {};
    return accountItem.accountIcon
};

const isCLAccount = (contactType) => {
    return contactType === COMMERCIAL_ACCOUNT_CODE;
}

const getAccountType = (isPersonalAccount) => {
    return isPersonalAccount ? ACCOUNTTYPE_PERSONAL : ACCOUNTTYPE_COMMERCIAL
}

export default {
    PERSONAL_ACCOUNT_CODE,
    COMMERCIAL_ACCOUNT_CODE,
    
    getAvailableAccounts,
    getAccountItem,
    getAccountIcon,
    isCLAccount,
    getAccountType
}