import React from 'react';
import { Tooltip, Icon } from '@jutro/components';
import _ from 'lodash';

export default function InValidIconUtil(props) {
    const {
        iconMessages
    } = props;

    const validMessage = iconMessages.validToolTip;
    const invalidMessage = iconMessages.inValidToolTip;
    const inValidForInsuranceMessage = _.isNil(iconMessages.inValidForInsuranceToolTip) ? invalidMessage : iconMessages.inValidForInsuranceToolTip;
    const vinConlictionMessage = iconMessages.duplicatedVinToolTip;

    const validIcon = (
        <Tooltip
            id="validTooltip"
            trigger="mouseenter"
            content={validMessage}
        >
            <div>
                <Icon
                    id="checkIcon"
                    icon="gw-check-circle"
                    className="wni-icon-success"
                />
            </div>
        </Tooltip>
    );
    const inValidIcon = (
        <Tooltip
            id="inValidTooltip"
            trigger="mouseenter"
            content={invalidMessage}
        >
            <div>
                <Icon
                    id="exclamationIcon"
                    icon="gw-warning"
                    className="wni-icon-error"
                />
            </div>
        </Tooltip>
    );
    const inValidForIssuanceIcon = (
        <Tooltip
            id="inValidTooltip"
            trigger="mouseenter"
            content={inValidForInsuranceMessage}
        >
            <div>
                <Icon
                    id="exclamationIcon"
                    icon="gw-check-circle"
                    className="wni-icon-warning"
                />
            </div>
        </Tooltip>
    );

    const vinConflictIcon = (
        <Tooltip
            id="inValidTooltip"
            trigger="mouseenter"
            content={vinConlictionMessage}
        >
            <div>
                <Icon
                    id="exclamationIcon"
                    icon="gw-warning"
                    className="wni-icon-error"
                />
            </div>
        </Tooltip>
    );


    // workaround to use callback in useState
    function renderInValidIcon(valid, validForIssuance, isMultipleVehiclesWithSameVin = false) {
        if (isMultipleVehiclesWithSameVin) {
            return vinConflictIcon;
        }
        if (valid && validForIssuance) {
            return validIcon;
        }
        if (valid && !validForIssuance) {
            return inValidForIssuanceIcon;
        }
        return inValidIcon;
    }

    return {
        validIcon,
        inValidIcon,
        inValidForIssuanceIcon,
        vinConflictIcon,
        renderInValidIcon
    };
}
