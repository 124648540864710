import { defineMessages } from 'react-intl';

export default defineMessages({
    matchingAccountFound: {
        id: 'gateway.views.new-quote.risk-reservation.Matching Account Found',
        defaultMessage: 'Matching Account Found'
    },
    matchingTitle: {
        id: 'gateway.views.new-quote.risk-reservation.This may be an existing client for your agency.',
        defaultMessage: 'This may be an existing client for your agency.'
    },
    matchingMsg: {
        id: 'gateway.views.new-quote.risk-reservation.Please select one of the following accounts to continue to pay and issue or refer to your underwriter for review.',
        defaultMessage: 'Please select one of the following accounts to continue to pay and issue or refer to your underwriter for review.'
    },
    potentialTitle: {
        id: 'gateway.views.new-quote.risk-reservation.You may also want to take a look at these',
        defaultMessage: 'You may also want to take a look at these...'
    },
    warningMsg: {
        id: 'gateway.views.new-quote.risk-reservation.Merge account warning message',
        defaultMessage: 'All information from account #{sourceAccount}(this account) will be merged into account #{targetAccount}(Target Account). Account #{sourceAccount}(this account) will be removed.'
    },
    mergeAccount: {
        id: 'gateway.views.new-quote.risk-reservation.Merge Account',
        defaultMessage: 'Merge Account'
    },
    noMatchingTitle: {
        id: 'gateway.views.new-quote.risk-reservation.There is no existing client for your agency.',
        defaultMessage: 'There is no existing client for your agency.'
    },
    // This message is commented out due to:
    // 1) Its content is wrong, and 
    // 2) It is not being used at this moment, and
    // 3) It duplicates the key of "matchingMsg".
    // noMatchingPotentialTitle: {
    //     id: 'gateway.views.new-quote.risk-reservation.Please select one of the following accounts to continue to pay and issue or refer to your underwriter for review.',
    //     defaultMessage: 'Please select one of the following accounts to continue to pay and issue or refer to your underwriter for review.'
    // }
});
