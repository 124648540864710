
const FIRST_CHANGE_WIZARD_PAGE = 'CRPolicyInfoChangePage';

function getChangePageJumpList({ policyChangeSource, getPageJumpAction }) {
    let retval = [];
    if (policyChangeSource === 'cancellation') {
        retval = [{
            name: 'Mailing Address',
            action: getPageJumpAction('CRPolicyInfoPage'),
        }];
    } else {
        retval = [
            {
                name: 'CR Page',
                action: getPageJumpAction('CRDummyPage'),
            },
            // {
            //     name: '',
            //     action: getPageJumpAction(''),
            // },
        ];
        // return  [{
        //     name: 'Policy Details',
        //     action: getPageJumpAction('WALPolicyDetailsPage'),
        // }, {
        //     name: 'Watercrafts',
        //     action: getPageJumpAction('WALWatercraftPage'),
        // }, {
        //     name: 'Operators',
        //     action: getPageJumpAction('WALOperatorPage'),
        // }, {
        //     name: 'Coverages',
        //     action: getPageJumpAction('WALCoveragesPage'),
        // }, {
        //     name: 'Loss History and Prior Carrier',
        //     action: getPageJumpAction('WALIncidentsAndReportsPage'),
        // }];
    }
    return retval;
}

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};