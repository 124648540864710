import React, { useContext } from 'react';
import { WniDropdownSelect } from 'wni-common-base-components';
import SingleClauseContext from '../../../context/IMSingleClauseContext';

const IMScheduleItemEditableCovTermCellDropdownSelect = (props) => {
    const { showErrors, isEditable } = useContext(SingleClauseContext);

    const { termID, term, setUpdatedTermToScheduleItem } = props;

    const { chosenTerm, options } = term;

    const readOnlyCausedByOnlyOneAvailableValue = options.length === 1 && options[0].code === chosenTerm
        return <WniDropdownSelect
            id = {termID}
            showLabel={false}
            onValueChange={async (newChosenTerm) => {
                const updatedTerm = {
                    ...term,
                    chosenTerm: newChosenTerm,
                    updated: true,
                }
                await setUpdatedTermToScheduleItem(updatedTerm)
            }}
            showErrors={showErrors}
            availableValues={options}
            value= {term.chosenTerm}
            readOnly= {!isEditable || readOnlyCausedByOnlyOneAvailableValue || term.readOnly_Ext}
            required= {term.required}
        />
};

export default IMScheduleItemEditableCovTermCellDropdownSelect;
