import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class DPRCTService {
    static prefillRCTByRiskAddress(quoteID, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'prefillRCTByRiskAddress', [quoteID, sessionUUID], additionalHeaders);
    }

    static assumptionsRCTCall(quoteID, sessionUUID, assumptionRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'assumptionsRCTCall', [quoteID, sessionUUID, assumptionRequestDTO], additionalHeaders);
    }

    static launchRCTExpress(quoteID, sessionUUID, callbackUrl, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'launchRCTExpress', [quoteID, sessionUUID, callbackUrl], additionalHeaders);
    }

    static syncRCTInfo(quoteID, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'syncRCTInfo', [quoteID, sessionUUID], additionalHeaders);
    }

    // just call and return RCT data, not save
    static prefillRCTByRiskAddressPt1(quoteID, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'prefillRCTByRiskAddressPt1', [quoteID, sessionUUID], additionalHeaders);
    }

    // save RCT data
    static prefillRCTByRiskAddressPt2(quoteID, sessionUUID, prefillRCTID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'prefillRCTByRiskAddressPt2', [quoteID, sessionUUID, prefillRCTID], additionalHeaders);
    }

    // just call and return RCT data, not save
    static assumptionsRCTCallPt1(quoteID, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'assumptionsRCTCallPt1', [quoteID, sessionUUID], additionalHeaders);
    }

    // save RCT data
    static assumptionsRCTCallPt2(quoteID, sessionUUID, prefillRCTID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('dpRCT'),
            'assumptionsRCTCallPt2', [quoteID, sessionUUID, prefillRCTID], additionalHeaders);
    }
}
