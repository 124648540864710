import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class UserService {
    static getAvailableProducerCodesForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'),
            'getAvailableProducerCodesForCurrentUser', [], additionalHeaders);
    }

    static hasUserSystemPermission(permission, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'), 'hasUserSystemPermission',
            [permission], additionalHeaders);
    }

    static hasUserPermission(permission, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'), 'hasUserPermission',
            [permission], additionalHeaders);
    }

    static hasUserAccessToAccountPolicies(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'), 'hasUserAccessToAccountPolicies',
            [accountNumber], additionalHeaders);
    }

    /**
     * This is the server responsible for dealing with the Gateway Current User Information
     * whic is used in the AuthenticationContext.jsx
     **/

    static getGatewayCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'), 'getCurrentUser',
            [], additionalHeaders);
    }
}
