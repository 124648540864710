import React from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import metadata from './AsteriskIsRequiredNotification.metadata.json5';
import messages from './AsteriskIsRequiredNotification.messages';

function AsteriskIsRequiredNotification(props) {

    const { notificationMessage } = props;
    const translator = useTranslator();


    const defaultRequiredNotification = translator(messages.requiredNotification, {
        fasterQuote: <span className="font-Primary-bold-16">{translator(messages.fasterQuote)}</span>,
        asteriskIcon: <span className="font-error-bold-16 algin-text-top">*</span>,
    });

    //
    const asteriskMessage = notificationMessage || defaultRequiredNotification;
    const overrideProps = {
        asteriskNotification: {
            message: asteriskMessage,
        }
    };

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />;
}

AsteriskIsRequiredNotification.props = {
    notificationMessage: PropTypes.string,
};

AsteriskIsRequiredNotification.defaultProps = {
    notificationMessage: undefined,
};

export default AsteriskIsRequiredNotification;
