import _ from 'lodash';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';

export default class WniDocumentRetrievalService {
    static getOnDemandDocumentLink(publicID, targetProxiedServiceUrl = 'wniDocumentRetrieval') {
        const serviceUrl = getProxiedServiceUrl(targetProxiedServiceUrl);
        const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?publicID=${publicID}`);
        return templateDownloadEndpointURL
    }

    static getOnDemandPAYGDocument(publicID, periodPublicID, filename, authHeader,
        callBack = _.noop, errorCallback = _.noop, targetProxiedServiceUrl = 'wniDocumentRetrieval') {
        const serviceUrl = getProxiedServiceUrl(targetProxiedServiceUrl);
        const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?publicID=${publicID}&periodPublicID=${periodPublicID}`);
        return this.generateDocument(templateDownloadEndpointURL, filename, authHeader,
            callBack, errorCallback);
    }

    static getOnDemandDocument(publicID, filename, authHeader,
        callBack = _.noop, errorCallback = _.noop, targetProxiedServiceUrl = 'wniDocumentRetrieval') {
        const serviceUrl = getProxiedServiceUrl(targetProxiedServiceUrl);
        const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?publicID=${publicID}`);
        return this.generateDocument(templateDownloadEndpointURL, filename, authHeader,
            callBack, errorCallback);
    }

    static generateDocument(downloadEndpointURL, filename, authHeader,
        callBack = _.noop, errorCallback = _.noop) {
        const params = {
            method: 'GET',
            url: downloadEndpointURL,
            headers: {
                ...authHeader,
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer'
        };
        return fetch(downloadEndpointURL, params).then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const downloadLink = document.createElement('a');
                        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                        downloadLink.setAttribute('download', filename);
                        downloadLink.style.display = 'none';
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        downloadLink.remove();
                    }
                    callBack();
                });
            } else errorCallback();
        }).catch(() => {
            errorCallback();
        });
    }
}
