import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caPolicyDetailsService'), method, data, additionalHeaders);
}

export default class CAPolicyDetailsService {

    static saveCAPolicyDetailsData(quoteID, sessionUUID, caPolicyDetailsData, quoteBaseData, authHeader) {
        return processSubmission('saveCAPolicyDetailsData', [quoteID, sessionUUID, caPolicyDetailsData, quoteBaseData], authHeader);
    }
}