
import React from 'react';


import { CURiskAnalysisReadOnlyPage } from 'wni-capability-quoteandbind-cu-react';

function CURiskAnalysisChangeReadOnlyPage(props) {

    return (
        <CURiskAnalysisReadOnlyPage {...props} />
    );

};

export default CURiskAnalysisChangeReadOnlyPage;