import { defineMessages } from 'react-intl';

export default defineMessages({
    Details: {
        "id": "quoteandbind.gl.wizard.step.generalLiability page.Details and Coverages.Details",
        "defaultMessage": "Details"
    },
    Coverages: {
        "id": "quoteandbind.gl.wizard.step.generalLiability page.Details and Coverages.Coverages",
        "defaultMessage": "Coverages"
    },
});
