/**
 * Get the input type of a Question
 * 
 * @param {object} question
 * @param {function} translator
 * @returns {object} - an object with the availableValues for the given Question
 */
export default (question, translator) => {
    const choices = question.choices.map((choice) => {
        return {
            code: choice.choiceCode,
            name: translator(choice.displayKey)
        };
    });

    return {
        availableValues: {
            value: choices
        }
    };
};
