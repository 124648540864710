import React, { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
    ViewModelServiceContext,
    withViewModelService,
} from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import {
    WniLoadSaveService,
    WniCommonQuoteService,
} from 'wni-capability-quoteandbind';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useWniModal } from 'wni-components-platform-react';
import {
    ServiceErrorUtil,
    WizardUtil,
    QuoteUtil,
    WniUrlUtil,
    WizardPageJumpUtil,
    WizardStepUtil,
    WizardPageHeaderUtil,
} from 'wni-portals-util-js';
import {
    WizardSidebarLink,
    WizardActiveQuotesDropdownComponent,
    WizardActiveQuotesListComponent,
} from 'wni-capability-gateway-react';
import {
    MultiModeWizard,
    MultiModeWizardWithErrorContext,
} from 'wni-portals-wizard-react';
import { WniSubmissionService } from 'wni-capability-gateway';

import { WniPlatformMessages as customMessages } from 'wni-platform-translations';
import messages from './CASubmissionWizard.messages';

import wizardConfig from './config/ca-wizard-config.json5';
import wizardReadOnlyConfig from './config/ca-wizard-readonly-config.json5';
import wizardStepToFieldMapping from './config/ca-wizard-step-to-field-mapping.json5';
import styles from './CASubmissionWizard.module.scss';

const { steps, title } = wizardConfig;
const { steps: readOnlySteps } = wizardReadOnlyConfig;

const LOB_NAME = 'commercialAuto';

function CASubmissionWizard(props) {
    const modalApi = useWniModal();
    // const { steps, title } = wizardConfig;
    // const { steps: initialReadOnlySteps } = wizardReadOnlyConfig;
    const { authHeader } = useAuthentication();

    const location = useLocation();
    const history = useHistory();
    const viewModelService = useContext(ViewModelServiceContext);

    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // Is the Submission obtained by copying, updateWizardSnapshot
    // Whether the Submission has been copied, Shows that it has been successfully copied
    // const { viewModelService, history, location } = props;
    // const [readOnlySteps, updateReadOnlySteps] = useState(initialReadOnlySteps);

    const [initialWizardPageData, setInitialWizardPageData] =
        useState(undefined);

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        state: {
            isReadOnly = false,
            quoteentry: {
                quoteID,
                postalCode,
                // periodPublicID,
            } = {},
        } = {},
    } = location;

    const updateActiveJobsData = useCallback(
        async (jobID, sessionUUID) => {
            let wizardExitData;
            if (isReadOnly) {
                wizardExitData = await WniCommonQuoteService.loadWizardExitData(
                    jobID,
                    sessionUUID,
                    authHeader
                );
            } else {
                wizardExitData =
                    await WniCommonQuoteService.loadWizardExitDataR3(
                        jobID,
                        sessionUUID,
                        authHeader
                    );
            }
            return wizardExitData;
        },
        [isReadOnly]
    );

    const retrieveSubmission = useCallback(
        async (quoteRetrievalParam) => {
            const viewModelContext = {
                AccountEmailRequired: false,
                AccountDOBRequired: false,
            };

            const requestData = quoteRetrievalParam;
            setLoadingMask(true);
            const response =
                await WniCommonQuoteService.retrieveSubmissionWizardData(
                    requestData,
                    authHeader
                );

            const { jobID, sessionUUID } = response;
            const wizardExitData = await updateActiveJobsData(
                jobID,
                sessionUUID
            );

            const submission = viewModelService.create(
                response,
                'pc',
                'wni.edge.capabilities.quote.dto.SubmissionWizardDataDTO',
                viewModelContext
            );

            const initPageData =
                WizardUtil.getInitialWizardPageData(wizardExitData);

            setInitialSubmission(submission);
            setInitialWizardPageData(initPageData);
            // setIsLoading(false);

            setLoadingMask(false);
        },
        [authHeader, location, viewModelService]
    );

    useEffect(
        () => {
            if (!viewModelService) {
                return;
            }
            if (!location.state) {
                history.push('/');
                return;
            }

            retrieveSubmission({
                quoteID,
                postalCode,
            });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [viewModelService]
    );

    const onLeaveWizard = useCallback(
        async ({
            wizardData: submissionVM,
            wizardPageData,
            currentStepIndex,
        }) => {
            const { jobID: quoteIDParam, sessionUUID } = submissionVM.value;
            // setIsLoading(true);

            const wizardExitData = WizardUtil.getQuoteWizardExitData(
                wizardPageData,
                { currentStepIndex }
            );
            const updateResult = await WniCommonQuoteService.saveWizardExitData(
                quoteIDParam,
                sessionUUID,
                wizardExitData,
                authHeader
            );

            // setIsLoading(false);

            return true;
        },
        [authHeader]
    );

    const handleCancel = useCallback(
        ({ wizardData: submissionVM }) => {
            const { jobID: quoteIDParam } = submissionVM.value;

            WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, {
                jobID: quoteIDParam,
            });
        },
        [history]
    );

    const handleReadOnlyCancel = useCallback(
        ({ wizardData: submissionVM }) => {
            const quoteIDParam = _.get(submissionVM.value, 'jobID');
            WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, {
                jobID: quoteIDParam,
            });
        },
        [history]
    );

    const handleError = useCallback(
        (error) => {
            // const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
            const quoteIDFromLocation = _.get(
                location,
                'state.quoteentry.quoteID'
            );
            const quoteIDVal = quoteIDFromWizard || quoteIDFromLocation;
            const redirectPath = `/quotes/${quoteIDVal}/summary`;
            const state = {
                pathname: redirectPath,
                state: error,
            };

            const errorMsg = ServiceErrorUtil.getErrorMessage(
                error,
                messages.anErrorOccurred
            );
            return modalApi
                .showAlert({
                    title: messages.anErrorOccurredTitle,
                    message: errorMsg,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: customMessages.OK,
                })
                .then(() => {
                    setHasErrorOccurred(true);
                    return <Redirect to={state} />;
                });
        },
        [location]
    );

    const handleKnockout = useCallback(
        ({ knockOutErrors }) => {
            history.push('/knockoutpage', {
                underwritingIssues: knockOutErrors,
            });
        },
        [history]
    );

    const handleCopySubmission = async ({ updateWizardPageData }) => {
        // const copySubmissionID = _.get(initialSubmission.value, 'jobID');
        const { jobID, sessionUUID } = initialSubmission.value;
        // setLoadingMask(true);
        const copiedSubmission = await WniSubmissionService.copySubmission(
            jobID,
            authHeader
        );

        // const {
        //     otherActiveJobs: otherActiveJobsNewVal,
        // } = await updateActiveJobsData(jobID, sessionUUID);
        const activeJobsNewVal = await WniCommonQuoteService.getActiveQuotes(
            jobID,
            sessionUUID,
            authHeader
        );
        // setLoadingMask(false);

        //
        // setOtherActiveJobs(otherActiveJobsNewVal);
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: activeJobsNewVal,
            [WizardConstants.copySubmission]: copiedSubmission,
        });
    };

    const wizardPageHeaderFormatter = ({
        wizardData: wizardSubmission,
        wizardPageData: wizardPageDataParam,
    }) => {
        const headerObj = WizardPageHeaderUtil.getQuotePageHeader({
            wizardData: wizardSubmission,
            wizardPageData: wizardPageDataParam,
            //
            lobDataPath: LOB_NAME,
        });
        return headerObj;
    };

    const getPageHeaderExtensionComponent = useCallback(() => {
        // return () => {
        //     return !_.isEmpty(otherActiveJobs) && <ActiveQuotesDropdownComponent accountJobs={otherActiveJobs} postalCode={postalCode} />
        // };
        return () => {
            return <WizardActiveQuotesDropdownComponent />;
        };
    }, []);

    const getSidebarExtensionComponent = useCallback(() => {
        return () => {
            return (
                <>
                    {/* <ActiveQuotesListComponent accountJobs={otherActiveJobs} postalCode={postalCode} /> */}
                    <WizardActiveQuotesListComponent />
                    <WizardSidebarLink />
                </>
            );
        };
    }, [postalCode]);

    const onWizardModeChange = _.noop;

    // ===============================================================================
    const defaultOnInitialization = useCallback(
        ({
            wizardPageData,
            updateWizardReadOnly,
            updateFurthestPageVisited,
        }) => {
            const isUwLocked = wizardPageData[WizardConstants.isUwLocked];
            if (isUwLocked) {
                updateWizardReadOnly(true);
            }
            // updateFurthestPageVisited('HOAdditionalInterestsPage');
        },
        []
    );

    const readonlyOnInitialization = useCallback(
        ({ updateFurthestPageVisited }) => {
            // updateFurthestPageVisited('CAQuotePage');
            const quotePageIndex =
                WizardStepUtil.getQuotePageIndex(readOnlySteps);
            updateFurthestPageVisited(quotePageIndex);
        },
        []
    );

    // ===============================================================================
    // if (isLoading) {
    //     return <Loader loaded={isLoading} />;
    // }

    // Both initialSubmission and initialWizardPageData is required: one is for wizard,
    // the other is for OthreActiveQuotes;
    // Consider to use another dedicated flag to track this initialization status.
    if (!initialSubmission || !initialWizardPageData) {
        return null;
    }

    return (
        <div className='caLine'>
            <ErrorBoundary onError={handleError}>
                <MultiModeWizardWithErrorContext
                    // <MultiModeWizard
                    initialData={initialSubmission}
                    modeToWizardPropsMap={{
                        default: {
                            initialSteps: steps,
                            wizardTitle: title,
                            onCancel: handleCancel,
                            // onKnockOut: handleKnockout,
                            skipCompletedSteps: true,
                            wizardStepToFieldMapping,
                            wizardPageHeaderExtension:
                                getPageHeaderExtensionComponent(),
                            // wizardSidebarExtension: WizardSidebarLink,
                            wizardSidebarExtension:
                                getSidebarExtensionComponent(),
                            // wizardSideBarExtensionProps: getWizardSideBarExtensionProps(),
                            wizardServiceCallbacks: {
                                retrieveSubmission:
                                    WniCommonQuoteService.retrieveSubmissionWizardData, // WniLoadSaveService.retrieveSubmission,
                                copySubmission: handleCopySubmission,
                            },
                            // onPreviousModalProps: {
                            //     title: commonMessages.wantToJump,
                            //     message: commonMessages.wantToJumpMessage,
                            //     status: 'warning',
                            //     icon: 'gw-error-outline',
                            //     confirmButtonText: commonMessages.yesModel,
                            //     cancelButtonText: commonMessages.cancelModel
                            // }
                            wizardDataComparator:
                                WizardUtil.isWizardDataEqualForPA,
                            showWizardPromptMessage: true,
                            onLeaveWizard,
                            wizardPageHeaderFormatter,
                            initialWizardPageData,
                            onWizardInitialization: defaultOnInitialization,
                        },
                        readOnly: {
                            initialSteps: readOnlySteps,
                            wizardTitle: title,
                            onCancel: handleReadOnlyCancel,
                            wizardStepToFieldMapping,
                            onWizardInitialization: readonlyOnInitialization,
                            wizardPageHeaderFormatter,
                        },
                    }}
                    initialMode={isReadOnly ? 'readOnly' : 'default'}
                    onWizardModeChange={onWizardModeChange}
                />
            </ErrorBoundary>
        </div>
    );
}

CASubmissionWizard.propTypes = {};

// export default withViewModelService(CASubmissionWizard);
export default CASubmissionWizard;
