import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { QuestionSetComponent } from 'gw-components-platform-react';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/GLWizardPage';

import metadata from './GLQualificationPage.metadata.json5';
import messages from './GLQualificationPage.messages';

const lobDataPath = 'lobData.generalLiability';

function GLQualificationReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        turnOnWizardPage,
        turnOffWizardPage
    } = props;

    const isUnmannedAircraftAvailable = _.get(submissionVM.value, `${lobDataPath}.isUnmannedAircraftAvailable`);
    const questionsetAnswers = _.get(submissionVM, `${lobDataPath}.preQualQuestionSets.value[0].answers`);

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const displayQuestions = [
        'GL7DoOrHave',
        'GL7Operations',
        'GL7Products',
        'GL7Subcontractors'
    ];

    useEffect(() => {
        if(isUnmannedAircraftAvailable){
            turnOnWizardPage('GLUnmannedAircraftPage')
        } else {
            turnOffWizardPage('GLUnmannedAircraftPage')
        }
    }, [isUnmannedAircraftAvailable]);
    
    const filterQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                isReadOnly: true
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        return submissionVM;
    }, []);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

GLQualificationReadOnlyPage.propTypes = WizardPage.propTypes;
GLQualificationReadOnlyPage.defaultProps = WizardPage.defaultProps;

GLQualificationReadOnlyPage.propTypes = {
    ...wizardProps
};

GLQualificationReadOnlyPage.defaultProps = {
};
export default GLQualificationReadOnlyPage;