import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react'
import EligibilityIssuesPopup from './modal/EligibilityIssuesPopup';
import messages from './EligibilityIssuesLinkComponent.messages';
import styles from './EligibilityIssuesLinkComponent.module.scss';
import metadata from './EligibilityIssuesLinkComponent.metadata.json5';

function EligibilityIssuesLinkComponent(props) {
    const modalApi = useWniModal();
    const {
        policyType,
        eligibilityIssues,
        errorMode,
    } = props;
    const translator = useTranslator();

    const [model, updateModel] = useState({});

    const showEligibilityIssuesDialog = async () => {
        const popupTitle = `${translator(messages.popupTitle)} ${policyType}`;
        return modalApi.showModal(
            <EligibilityIssuesPopup
                title={popupTitle}
                okBtnLabel={translator(messages.okBtnLabel)}
                eligibilityIssues={eligibilityIssues}
            />
        );
    };

    //--------------------------------------------
    // const eligibilityLink = `${translator(messages.notEligibleFor)} ${policyType}`;

    const overrideProps = {
        '@field': {

        },
        errorIcon: {
            icon: errorMode ? 'gw-error' : 'warning',
            className: errorMode ? 'font-error' : 'font-warning',
        },
        // notEligibilLink: {
        //     content: eligibilityLink,
        // },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            showEligibilityIssuesDialog
        }
    };

    return (
        // <ModalNext isOpen={isOpen} className={size}>
        //     <ModalHeader title={title} />
        //     <Button className="modal-close" onClick={onReject}>
        //         <i className="fa fa-times"> </i>
        //     </Button>
        //     <ModalBody id="teamTabPanel">
        //         <ViewModelForm
        //             uiProps={metadata.componentContent}
        //             model={underwriterVM}
        //         />
        //     </ModalBody>
        // </ModalNext>
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={model}
            // onModelChange={updateAgencyAndAgentInfo}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            // resolveValue={readValue}
            // onValueChange={onAgencyChange}
            // showErrors={showErrors}
        />
    );
}

EligibilityIssuesLinkComponent.propTypes = {
    policyType: PropTypes.string.isRequired,
    eligibilityIssues: PropTypes.arrayOf(PropTypes.string),
    errorMode: PropTypes.bool,
};

EligibilityIssuesLinkComponent.defaultProps = {
    eligibilityIssues: [],
    errorMode: true,
};

export default EligibilityIssuesLinkComponent;
