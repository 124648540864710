import { WizardConstants } from 'wni-portals-config-js';
import { WniProductsUtil } from 'wni-portals-util-js';

const {
    CPP_PRODUCT_CODE
} = WniProductsUtil;

const AAIS_COVERAGE_SELECTION_MAPKEYS = {
    IMBaileePart_Ext: 'IMBaileePartPage',
    IMBoatPart_Ext: 'IMBoatPartPage',
    IMBuilderRiskPart_Ext: 'IMBuilderRiskPartPage',
    ContractorsEquipPart: 'IMContractorsEquipPartPage',
    IMElectronicDataProcessingPart_Ext: 'IMElectronicDataProcessingPartPage',
    IMFineArtsFloaterPart_Ext: 'IMFineArtsFloaterPartPage',
    IMInstallationFloaterPart_Ext: 'IMInstallationFloaterPartPage',
    IMMiscFormsPart_Ext: 'IMMiscFormsPartPage',
    IMMiscFloatersPart_Ext: 'IMMiscFloatersPartPage',
    IMMotorTruckCargoPart_Ext: 'IMMotorTruckCargoPartPage',
    IMMuseumsPart_Ext: 'IMMuseumsPartPage',
    IMRiggersPart_Ext: 'IMRiggersPartPage',
    IMTransitPart_Ext: 'IMTransitPartPage',
    IMWarehouseLegalLiabilityPart_Ext: 'IMWarehouseLegalLiabilityPartPage'
};

const ISO_COVERAGE_SELECTION_MAPKEYS = {
    IMAccountsRecPart: 'IMAccountsRecPartPage',
    IMPhysiciansAndSurgeonsPart_Ext: 'IMPhysiciansAndSurgeonsPartPage',
    IMSignPart: 'IMSignPartPage',
    IMValuablesPapersPart_Ext: 'IMValuablesPapersPartPage'
};

const getIMSkipPages = (productCode, data = {}) => {
    const {
        selectableCoverageParts: selectableData = [],
        selectedCoverageParts: selectedData = []
    } = data;
    const COVERAGE_SELECTION_MAPKEYS = productCode === CPP_PRODUCT_CODE ? ISO_COVERAGE_SELECTION_MAPKEYS : AAIS_COVERAGE_SELECTION_MAPKEYS;

    const skipPartIds = selectableData.map((coveragePart) => COVERAGE_SELECTION_MAPKEYS[coveragePart.code]);
    const selectedPartIds = selectedData.map((coveragePart) => COVERAGE_SELECTION_MAPKEYS[coveragePart.code]);

    const skipPages = skipPartIds.map((item) => {
        return {
            [WizardConstants.stepId]: item, 
            [WizardConstants.stepVisible]: false
        }
    });

    const showPages = selectedPartIds.map((item) => {
        return {
            [WizardConstants.stepId]: item, 
            [WizardConstants.stepVisible]: true
        }
    });
    return [
        ...skipPages,
        ...showPages
    ]
};

export default {
    AAIS_COVERAGE_SELECTION_MAPKEYS,
    ISO_COVERAGE_SELECTION_MAPKEYS,
    getIMSkipPages
}
    
