
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { ActiveQuoteUtil, WniJobFilterUtil } from 'wni-portals-util-js';

import { useProductsData } from 'wni-portals-util-react';
import ActiveQuotesListComponent from '../ActiveQuotesList/ActiveQuotesListComponent';

// See ActiveQuotesWithWizardContext for another solution
function WizardActiveQuotesListComponent(props) {
    const {
        wizardData: submissionVM,
        wizardPageData,
    } = useContext(WizardContext);

    const {
        jobID, 
        quoteID, // for backward compatibility
        baseData: {
            baseState_Ext: baseState,
            accountHolder: {
                contactType_Ext: accountType,
                primaryAddress: {
                    postalCode,
                    
                }
            }
        }
    } = submissionVM.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageData;

    const [accountJobs, updateAccountJobs] = useState([]);
    
    const actualJobID = jobID || quoteID;

    const { getAvailableProducts } = useProductsData();

    useEffect(() => {
        const otherActiveJobs = ActiveQuoteUtil.filterActiveJobDTOsByJobID(accountActiveQuotes, actualJobID);
        const availableProducts = getAvailableProducts(accountType, baseState, true);
        const otherActiveJobFilters = WniJobFilterUtil.getAvailableJobs(otherActiveJobs, availableProducts)
        updateAccountJobs(otherActiveJobFilters);
    }, [accountActiveQuotes, actualJobID]);

    // ==================
    if (_.isEmpty(accountJobs)) {
        return null;
    }
    return (
        <ActiveQuotesListComponent
            accountJobs={accountJobs}
            postalCode={postalCode}
        />
    );
}

WizardActiveQuotesListComponent.propTypes = {
    // postalCode: PropTypes.string.isRequired,
    // jobID: PropTypes.string,
};

WizardActiveQuotesListComponent.defaultProps = {
    // postalCode: undefined,
    // jobID: undefined,
};

export default WizardActiveQuotesListComponent;
