import React, { useCallback } from 'react';

import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageHeader } from 'gw-portals-wizard-components-ui'
// import WizardSingleErrorComponent from '../../../WizardSingleErrorComponent/WizardSingleErrorComponent'
import BaseDigitalWizardPageTemplate from '../../templates/BaseDigitalWizardPageTemplate';
import styles from '../../templates/WithTitle/BaseDigitalWizardPageWithTitleTemplate.module.scss';

import { Button } from '@jutro/legacy/components';

function CustomWizardPageFooterTemplate(props) {
    const translator = useTranslator();
    const {
        children,
        // acknowledgeError, errorsForStep,
        isSideHeader,   // To be removed
        //
        renderCustomWizardPageFooterContent,
        //
        ...otherProps
    } = props;

    const {
        //
        showCancel,
        disableCancel,
        onCancel,
        cancelLabel,
        isLoadingCancel,
        //
        showPrevious,
        disablePrevious,
        onPrevious,
        previousLabel,
        isLoadingPrevious,
        //
        showNext,
        renderNextButton,
    } = otherProps;

    const customWizardPageFooterContent = renderCustomWizardPageFooterContent();

    const wizardPageFooterContentRender = ({ 
        isPerformingTransiton,
        renderLoader
    }) => {
        // See: DigitalWizardPageTemplate.getWizardFootButtons()
        return (
            <div className="wizard-footer-buttons">
                <div className="buttonCancel">
                    {(showCancel) && (
                        <Button
                            id="gw-wizard-cancel"
                            disabled={disableCancel || isPerformingTransiton}
                            type="text"
                            onClick={onCancel}
                        >
                            {translator(cancelLabel)}
                            {renderLoader(isLoadingCancel)}
                        </Button>
                    )}
                </div>
                <div className="buttonGrounp">
                    {(showPrevious) && (
                        <Button
                            id="gw-wizard-previous"
                            disabled={disablePrevious || isPerformingTransiton}
                            type="outlined"
                            onClick={onPrevious}
                        >
                            {translator(previousLabel)}
                            {renderLoader(isLoadingPrevious)}
                        </Button>
                    )}
                    {(showNext) && renderNextButton}
                </div>
                {customWizardPageFooterContent}
            </div>
        );
    };
    
    const renderWizardHeader = useCallback(
        (wizardData) => {
            return <WizardPageHeader isSideHeader={isSideHeader} />;
        },
        [isSideHeader]
    );

    return (
        <div className={styles.wizardContainer}>
            <BaseDigitalWizardPageTemplate
                showContextComponent={false}
                showWizardHeader
                renderWizardFooterComponent={wizardPageFooterContentRender}
                {...otherProps}
            >
                {children}
            </BaseDigitalWizardPageTemplate>
        </div>
    );
}

CustomWizardPageFooterTemplate.propTypes = {
    ...pageTemplateProps,
    renderCustomWizardPageFooterContent: PropTypes.func,
}

CustomWizardPageFooterTemplate.defaultPropTypes = {
    renderCustomWizardPageFooterContent: () => null,
}

export default CustomWizardPageFooterTemplate;
