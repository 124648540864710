
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { HOCoveragesReadOnlyPage } from 'wni-capability-quoteandbind-ho-react';

function HOCoveragesChangeReadOnlyPage(props) {

    return (
        <HOCoveragesReadOnlyPage {...props} />
    );

}

HOCoveragesChangeReadOnlyPage.propTypes = HOCoveragesReadOnlyPage.propTypes;
HOCoveragesChangeReadOnlyPage.defaultProps = HOCoveragesReadOnlyPage.defaultProps;

export default HOCoveragesChangeReadOnlyPage;