const scheduleConfig = {
    visibleColumnIdsOverride: {
        // HOPScheduledPersonalProperty: [
        //     'HOPScheduledPersonalPropertyItemType',
        //     'HOPScheduledPersonalPropertyItemDescription_Ext',
        //     'HOPScheduledPersonalPropertyItemDeductible',
        //     'HOPScheduledPersonalPropertyItemLimit'
        // ],
        // // eslint-disable-next-line camelcase
        // HOPIncidentalPropHiLmt_Ext: [
        //     'HOPIncidentalPropHiLmtItemAmount_Ext',
        //     'HOPIncidentalPropHiLmtItemCov_Ext',
        //     // This field in hidden in portal
        //     // 'HOPIncidentalPropHiLmtItemIncludedLmt_Ext',
        //     'HOPIncidentalPropHiLmtItemTotalLmt_Ext'
        // ]
    },
    visibleFieldIdsOverride: {
        // HOPScheduledPersonalProperty: [
        //     'HOPScheduledPersonalPropertyItemType',
        //     'HOPScheduledPersonalPropertyItemWithBreakage_Ext',
        //     'HOPScheduledPersonalPropertyItemDescription_Ext',
        //     'HOPScheduledPersonalPropertyItemLocation_Ext',
        //     'HOPScheduledPersonalPropertyItemDeductible',
        //     'HOPScheduledPersonalPropertyItemDateAppraisal_Ext',
        //     'HOPScheduledPersonalPropertyItemLimit',
        //     'HOPScheduledPersonalPropertyItemHaveLossPayee_Ext',
        //     'HOPScheduledPersonalPropertyItemAgreedValue_Ext',
        //     'HOPScheduledPersonalPropertyItemSerialNo_Ext',
        // ],
        // // eslint-disable-next-line camelcase
        // HOPIncidentalPropHiLmt_Ext: [
        //     'HOPIncidentalPropHiLmtItemAmount_Ext',
        //     'HOPIncidentalPropHiLmtItemCov_Ext',
        //     // This field in hidden in portal
        //     // 'HOPIncidentalPropHiLmtItemIncludedLmt_Ext',
        //     'HOPIncidentalPropHiLmtItemTotalLmt_Ext'
        // ]
    },
    fieldsNotNeedSyncWhileChange: {
        // // Group by coverage code
        // // Common optional coverage
        // 'HOPScheduledPersonalProperty': [
        //     'HOPScheduledPersonalPropertyItemDescription_Ext',
        //     'HOPScheduledPersonalPropertyItemSerialNo_Ext'
        // ],
        // // Other optional coverage
        // 'HOPCovForResidentOfAssisLivingFacility_Ext': [
        //     // Facility Name
        //     'StringCol2',
        //     // Facility Location
        //     'StringCol3'
        // ],
        // 'HOPOtherResidentsOfHousehold_Ext': [
        //     // Name Of Person
        //     'PolicyContactRole'
        // ],
        // 'HOPNonOwnedBusinessActivities_Ext': [
        //     // Name Of Insured
        //     'PolicyContactRole',
        //     // Business Activities
        //     'HOPNonOwnedBusinessActivitiesBusinessActivities_Ext',
        // ],
        // 'HOPRoofSurfacingActualCashSched_Ext': [
        //     'HOPRoofSurfacingActualCashSchedItemDescription_Ext'
        // ],
        // 'HOPCoverageForNonResidentRelative_Ext': [
        //     // Name of Relative(s)
        //     'HOPCoverageForNonResidentRelativeItemNameOfRelative_Ext'
        // ],
        // 'HOPPropertyInRentalUnitsIncreasedLimit_Ext': [
        //     // Description of Rental Unit
        //     'HOPPropertyInRentalUnitsIncreasedLimitItemDescriptionofRentalUnit'
        // ],
        // 'HOPIncidentalCovForMotorVehicleUnder20MPH_Ext': [
        //     // Description of Vehicle
        //     'HOPIncidentalCovForMotorVehicleUnder20MPHItemDesc_Ext'
        // ],
        // 'HOPAdditionalResidenceRentedToOthersLBCovOnly_Ext': [
        //     // Location
        //     'HOPAdditionalResidenceRentedToOthersLBCovOnlyItemLocation',
        //     // Number of Families
        //     'HOPAdditionalResidenceRentedToOthersLBCovOnlyItemNumberOfFamilies'
        // ],
        // 'HOPAdditionalResidencePremisesOccupied_Ext': [
        //     // Location
        //     'HOPAdditionalResidencePremisesOccupiedItemLocation'
        // ],
        // // Golf Cart Liability Coverage
        // 'HOPGolfCartLiabCov_Ext': [
        //     // Make and Model
        //     'HOPGolfCartLiabCovItemMakeModel_Ext',
        //     // Serial or Motor Number
        //     'HOPGolfCartLiabCovItemSerialNum_Ext'
        // ],
        // // Scheduled Motorized Golf Cart Coverage
        // 'HOPSchedMotorGolfCartCov_Ext': [
        //     // Make or Model
        //     'HOPSchedMotorGolfCartCovItemMakeOrModel_Ext',
        //     // Serial or Motor Number
        //     'HOPSchedMotorGolfCartCovItemSerialNum_Ext'
        // ]
    }
}

const termConfig = {
    termsNotChangeWithSync: {
        // // group by coverage
        // // primary coverages
        // 'HOPCovL_Ext': [
        //     // Coverage L Limit
        //     'HOPCovLLimit'
        // ],
        // // Medical Payment to Others (Cov M)
        // 'HOPCovM_Ext': [
        //     // Coverage M Limit
        //     'HOPCovMLimit'
        // ],
        // // common optional coverages
        // // Identity Fraud Expense
        // 'HOPIdentityFraudExpense_Ext': [
        //     // Total Limit
        //     'HOPIdentityFraudExpenseTotalLimit'
        // ],
        // // Water Back Up and Sump Discharge Or Overflow Coverage
        // 'HOPBackupSewersDrainsPump': [
        //     // Backup Limit
        //     'HOPBackupSewersDrainsPumpLimit'
        // ],
        // // Matching of Undamaged Siding or Roofing Coverage
        // 'HOPMactchingOfUndamagedSidingOrRoofingCov_Ext': [
        //     // Limit
        //     'HOPMactchingOfUndamagedSidingOrRoofingCovLimit_Ext',
        // ],
        // // Blanket Jewelry Coverage
        // 'HOPBlanketJewelryCoverage_Ext': [
        //     // Limit
        //     'HOPBlanketJewelryCoverageLimit'
        // ],
        // // Other optional coverages
        // // Increased Cost - Ordinance Or Law - Increased Limit of Coverage
        // 'HOPOrdinanceOrLaw': [
        //     // Limit
        //     'HOPOrdinanceOrLawLimit'
        // ],
        // // Earthquake Coverage
        // 'HOPEarthquake': [
        //     // Exterior Masonry Veneer Exclusion Applies?
        //     'HOPEarthquakeMasonryExcl'
        // ],
        // // Cyber Protection Insurance
        // 'HOPCyberProtectionInsurance_Ext': [
        //     // Limit
        //     'HOPCyberProtectionInsuranceLimit_Ext'
        // ],
        // // Underground Service Line Coverage
        // 'HOPUndergroundServiceLineCoverage_Ext': [
        //     // Last Service Date
        //     'HOPUndergrdServiceLineCovDate_Ext'
        // ],
        // // Office, Professional, Private School, Or Studio Occupancy - Described Location
        // 'HOPOfficeProfessionalSchoolStudioOccupancyLocation_Ext': [
        //     // Description of business
        //     'HOPOfficeProfessionalSchoolStudioOccupancyLocationDescriptionBusiness_Ext',
        //     // Description
        //     'HOPOfficeProfessionalSchoolStudioOccupancyLocationDescription_Ext'
        // ]
    }
}

const privateRelatedStructureConfig = {
    hiddenScheduleItemCodes: [
        // 'HOPRelatedPrivateStructuresRentedToOthersItemLimit_Ext',
        // 'GenericHOPSchedRelatedPrivateStructAwayFromItemLocatStruct_ExtType'
    ]
}

export default {
    scheduleConfig,
    termConfig,
    privateRelatedStructureConfig,
}