import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import AddressInputField from './components/AddressInputField/AddressInputField';
import CheckboxElement from './components/CheckboxElement/CheckboxElement';
import CustDatatable from './components/DataTable/CustDataTable';
import CustToggleField from './components/ToggleField/CustToggleField';

setComponentMapOverrides(
    {
        AddressInputField: { component: 'AddressInputField' },
        CheckboxElement: { component: 'CheckboxElement' },
        CustDatatable: { component: 'CustDatatable' },
        toggle: { component: 'toggle' },
        Toggle: { component: 'Toggle' },
    },
    {
        AddressInputField,
        CheckboxElement,
        CustDatatable,
        toggle: CustToggleField,
        Toggle: CustToggleField
    }
);
export { AddressInputField, CheckboxElement, CustDatatable, CustToggleField };
export { default as RecommendInputComponent } from './components/RecommendInputComponent/RecommendInputComponent';
