import React from 'react';
import WizardPage from '../../templates/IMWizardPage';
import IMBaileeCustomersFloaterPage from './IMBaileeCustomersFloaterPage';

const IMBaileeCustomersFloaterReadOnlyPage = (props) => {
    return <IMBaileeCustomersFloaterPage {...props} readOnly />
};

IMBaileeCustomersFloaterReadOnlyPage.propTypes = WizardPage.propTypes;
export default IMBaileeCustomersFloaterReadOnlyPage;
