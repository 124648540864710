import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWniModal } from 'wni-components-platform-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { CPStateInformationService } from 'wni-capability-quoteandbind-cp';
import CPClausesComponentVM from '../../../CommercialProperty/components/CPClausesComponentVM';
import CPSingleClauseComponentVM from '../../../CommercialProperty/components/CPSingleClauseComponentVM';
import CoverageContext from '../../../CommercialProperty/context/CPCoverageContext';
import CPCoverageUtil from '../../../CommercialProperty/util/CPCoverageUtil';
import messages from './CPStateSpecificInfo.messages';
import AccordionCardTitle from '../../../CommercialProperty/components/CPClauseAccordionCardTitle/CPClauseAccordionCardTitle';
import CPSearchAndAddCoveragesPopup from '../../../../components/CPSearchAndAddCoveragesPopup/CPSearchAndAddCoveragesPopup';

import { Button, Accordion, AccordionCard } from '@jutro/legacy/components';

const CPStateSpecificInfo = (props) => {
    const modalApi = useWniModal();
    const {
        readOnly,
        selectedState,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        handleStateSpecificInfoClose,
    } = props;

    const { jobID, sessionUUID } = submissionVM.value;

    const translator = useTranslator();

    const {
        onValidate: onStandardCoverageValidate,
        isComponentValid: isStandardCoverageValid,
    } = useValidation('CPStateStandardCoverage');

    const {
        onValidate: onAdditionalCoverageValidate,
        isComponentValid: isAdditionalCoverageValid,
    } = useValidation('CPStateAdditionalCoverage');

    const {
        onValidate: onExclusionAndConditionValidate,
        isComponentValid: isExclusionAndConditionValid,
    } = useValidation('CPStateExclusionsAndConditions');

    const isStateInfoValid = isStandardCoverageValid && isAdditionalCoverageValid && isExclusionAndConditionValid;

    const [stateClauses, setStateClauses] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [loadingClauses, setLoadingClauses] = useState();
    const [showErrors, updateShowErrors] = useState(false);
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const initData = useCallback(async () => {
        if (!selectedState) {
            return;
        }
        setLoadingMask(true);
        const newStateClauses = await CPStateInformationService.getStateClauses(
            jobID,
            sessionUUID,
            selectedState,
            authHeader
        );
        setStateClauses(newStateClauses);
        setLoadingMask(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, jobID, selectedState, sessionUUID]);

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            jurisdictionCode: selectedState
        };
        return modalApi.showModal(
            <CPSearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, selectedState, sessionUUID, setLoadingMask]);

    const onClickAddSearchCoverage = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType);
        setStateClauses(data);
    },[showCoveragesModal])
    
    useEffect(() => {
        initData();
    }, [initData]);

    if (!stateClauses) {
        return null;
    }

    const { standardCoverage, additionalCoverage, exclusion, condition } =
        stateClauses;

    const changeClauses = (value, changedPath, clauses, clausesPath) => {
        const newClauses = CPCoverageUtil.setClauseValue(
            clauses,
            clausesPath,
            value,
            changedPath
        );
        const newStateClauses = _.clone(stateClauses);
        _.set(newStateClauses, clausesPath, newClauses);
        setStateClauses(newStateClauses);
        setIsEditing(false);
        return newStateClauses;
    };

    const changeStandardCoverage = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            standardCoverage,
            'standardCoverage'
        );
    };

    const changeAdditionalCoverage = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            additionalCoverage,
            'additionalCoverage'
        );
    };

    const changeExclusions = (value, changedPath) => {
        return changeClauses(value, changedPath, exclusion, 'exclusion');
    };

    const changeConditions = (value, changedPath) => {
        return changeClauses(value, changedPath, condition, 'condition');
    };

    const updateVMToServer = async (
        newStateClauses,
        setLoadingClausesFunc,
        setEditingFunc
    ) => {
        const stateClausesToUpdate =
            CPCoverageUtil.generateUpdatedStateClausesDTO(newStateClauses);
        const updatedStateClausesPublicIDs =
            CPCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedStateClausesDTO(
                stateClausesToUpdate
            );
        setLoadingMask(true);
        setLoadingClausesFunc(updatedStateClausesPublicIDs);
        const stateClausesFromServer =
            await CPStateInformationService.updateStateClauses(
                jobID,
                sessionUUID,
                selectedState,
                stateClausesToUpdate,
                authHeader
            );
        setLoadingClausesFunc(false);
        setLoadingMask(false);
        setEditingFunc(false);
        setStateClauses(stateClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM);
    };

    const updateNewStateClausesToServer = async (newLineClauses) => {
        updateVMToServer(newLineClauses, setLoadingClauses, setIsEditing);
    };

    const changeStandardCoverageAndSync = (value, changedPath) => {
        const newStateClauses = changeStandardCoverage(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const changeAdditionalCoveragesAndSync = (value, changedPath) => {
        const newStateClauses = changeAdditionalCoverage(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const changeExclusionAndSync = (value, changedPath) => {
        const newStateClauses = changeExclusions(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const changeConditionAndSync = (value, changedPath) => {
        const newStateClauses = changeConditions(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const onSyncCoverages = () => {
        updateNewStateClausesToServer(stateClauses);
    };

    const handleClose = () => {
        if (isStateInfoValid) {
            handleStateSpecificInfoClose();
            return;
        }
        updateShowErrors(true);
    };

    const commonClausesProps = {
        splitByClauseCategory: false,
        sortBySelected: true,
        onSyncCoverages: onSyncCoverages,
        showAmount: false,
        loadingClause: loadingClauses,
        showErrors: showErrors,
        componentMapOverrides: {
            CPSingleClauseComponentVM: CPSingleClauseComponentVM,
        },
        setIsEditing: setIsEditing,
        isEditing: isEditing,
        isEditable: !readOnly,
        hideCheckBoxForRequired: true,
    };

    return (
        <>
            {stateClauses && (
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        updateVMToServer: updateVMToServer,
                    }}
                >
                    <Accordion
                        id="stateSpecificInfoAccordion"
                        className="accordion-wizardTitle"
                        closeOthers={false}
                        showFrame={false}
                    >
                        <AccordionCard
                            id="DetailsAndCoverage"
                            chevron
                            className="coveragesAccordion"
                            cardBodyClassName="accordionCardBody"
                            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                            title={translator(messages.DetailsAndCoverage)}
                            errorState={showErrors && !isStandardCoverageValid}
                        >
                            <div className="formCoveragesContainer">
                                <CPClausesComponentVM
                                    {...commonClausesProps}
                                    onChangeSubmissionAndSync={
                                        changeStandardCoverageAndSync
                                    }
                                    onChangeClause={changeStandardCoverage}
                                    onValidate={onStandardCoverageValidate}
                                    path="standardCoverage"
                                    value={standardCoverage}
                                />
                            </div>
                        </AccordionCard>
                        <AccordionCard
                            id="AdditionalCoverages"
                            chevron
                            className="coveragesAccordion"
                            cardBodyClassName="accordionCardBody"
                            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                            title={
                                readOnly ? (
                                    translator(messages.AdditionalCoverages)
                                ) : (
                                    <AccordionCardTitle
                                        title={translator(
                                            messages.AdditionalCoverages
                                        )}
                                        actionText={
                                            messages.AddCoverages
                                        }
                                        onClick={() => {onClickAddSearchCoverage('additionalCoverages')}}
                                    />
                                )
                            }
                            errorState={
                                showErrors && !isAdditionalCoverageValid
                            }
                        >
                            <div className="formCoveragesContainer">
                                <CPClausesComponentVM
                                    {...commonClausesProps}
                                    onChangeSubmissionAndSync={
                                        changeAdditionalCoveragesAndSync
                                    }
                                    onChangeClause={changeAdditionalCoverage}
                                    onValidate={onAdditionalCoverageValidate}
                                    path="additionalCoverage"
                                    value={additionalCoverage}
                                />
                            </div>
                        </AccordionCard>
                        <AccordionCard
                            id="exclusionAndCondition"
                            chevron
                            className="coveragesAccordion"
                            cardBodyClassName="accordionCardBody"
                            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                            title={readOnly ? (
                                translator(messages.ExclusionsAndConditions)
                            ) : (
                                <AccordionCardTitle
                                        title={translator(
                                            messages.ExclusionsAndConditions
                                        )}
                                        actionText={
                                            messages.AddExclusionsAndConditions
                                        }
                                        onClick={() => {onClickAddSearchCoverage('exclusionsAndConditions')}}
                                    />
                            )}
                            errorState={
                                showErrors && !isExclusionAndConditionValid
                            }
                        >
                            <div className="formCoveragesContainer">
                                <CPClausesComponentVM
                                    {...commonClausesProps}
                                    onChangeSubmissionAndSync={
                                        changeExclusionAndSync
                                    }
                                    onChangeClause={changeExclusions}
                                    onValidate={onExclusionAndConditionValidate}
                                    path="exclusion"
                                    value={exclusion}
                                />
                                <CPClausesComponentVM
                                    {...commonClausesProps}
                                    onChangeSubmissionAndSync={
                                        changeConditionAndSync
                                    }
                                    onChangeClause={changeConditions}
                                    onValidate={onExclusionAndConditionValidate}
                                    path="condition"
                                    value={condition}
                                />
                            </div>
                        </AccordionCard>
                    </Accordion>
                </CoverageContext.Provider>
            )}

            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(messages.Close)}
                </Button>
            </Flex>
        </>
    );
};

export default CPStateSpecificInfo;
