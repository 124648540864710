
import React, { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { GLRiskAnalysisReadOnlyPage } from 'wni-capability-quoteandbind-gl-react';
// import messages from './CPRiskAnalysisReadOnlyPage.messages';

function CPRiskAnalysisReadOnlyPage(props) {

    const {
        wizardData: submissionVM,
    } = props;

    const CPOverrideProps = {
    }

    return (
        <GLRiskAnalysisReadOnlyPage
            {...props}
            // customMessages = {messages}
            CPOverrideProps = {CPOverrideProps}
            lobName = 'commercialProperty'
        />
    );

}

CPRiskAnalysisReadOnlyPage.propTypes = {
    ...wizardProps,
};

CPRiskAnalysisReadOnlyPage.defaultProps = {
};

export default CPRiskAnalysisReadOnlyPage;
