import React from 'react';
import _ from 'lodash';
import {
    ToggleField,
} from '@jutro/components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

const ToggleDefaultAvailableValues = [
    {
        code: true,
        name: commonMessages.yesModel
    },
    {
        code: false,
        name: commonMessages.noModel
    }
];
const CustToggleField = (props) => {
    const { availableValues } = props;
    const componentProps = { ...props };
    if (_.isEmpty(availableValues)) {
        _.set(componentProps, 'availableValues', ToggleDefaultAvailableValues);
    }
    return <ToggleField {...componentProps} />
};

export default CustToggleField
