import { defineMessages } from 'react-intl';

export default defineMessages({
    DetailsAndCoverage: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecificpage.statespecific.DetailsAndCoverage',
        defaultMessage: 'Details & Coverage',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecificpage.statespecific.AdditionalCoverages',
        defaultMessage: 'Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecificpage.statespecific.ExclusionsAndConditions',
        defaultMessage: 'Exclusions & Conditions',
    },
    AddCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecificpage.statespecific.Add Coverages',
        defaultMessage: 'Add Coverages',
    },
    Close: {
        id: 'wni.quoteandbind.cp-wizard.cp-statespecificpage.statespecific.close',
        defaultMessage: 'Close',
    },
    AddExclusionsAndConditions: {
        "id": "wni.quoteandbind.cp-wizard.cp-statespecificpage.statespecific.AddExclusionAndConditions",
        "defaultMessage": "Add Exclusions and Conditions"
    },
});