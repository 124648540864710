import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniContactService {
    static getAccountContact(
        accountNumber,
        additionalHeaders = {},
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayContact'),
            'getAccountContact', [accountNumber], additionalHeaders);
    }

    static getAssociatedAccounts(
        accountNumber,
        additionalHeaders = {},
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayContact'),
            'getAssociatedAccounts', [accountNumber], additionalHeaders);
    }

    static getAccountContactRoles(
        accountNumber,
        additionalHeaders = {},
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayContact'),
            'getAccountContactRoles', [accountNumber], additionalHeaders);
    }

    static getAccountDBAs(
        accountNumber,
        additionalHeaders = {},
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayContact'),
            'getAccountDBAs', [accountNumber], additionalHeaders);
    }
}
