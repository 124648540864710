import React, { useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PolicyDetailsPage.metadata.json5';

function PolicyDetailsPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const { onValidate, isComponentValid, initialValidation } = useValidation('PolicyDetailsPage');
    const { authHeader } = useAuthentication();

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [submissionVM, authHeader]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left'
        },
        policyChangeEffectiveDate: {
            minDate: _.get(
                submissionVM,
                'baseData.minimumEffectiveDate.value',
                LocalDateUtil.today()
            )
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            disableNext={!isComponentValid}
            template={WizardPageTemplate}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                model={submissionVM}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

PolicyDetailsPage.propTypes = pageTemplateProps;
export default PolicyDetailsPage;
