import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { PADriverAssignmentReadOnlyPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeVehicleService } from 'wni-capability-policychange';

function PADriverAssignmentChangeReadOnlyPage(props) {
    const policyChangeAutoAssignAndGetVehicleDriverAssignments = useCallback(
        async (policyNumber, effectiveDate, jobID, updatingAssignment, authHeader) => {
            const response = await WniPolicyChangeVehicleService
                .autoAssignAndGetVehicleDriverAssignments(
                    policyNumber, effectiveDate, jobID, updatingAssignment, authHeader
                );
            return response;
        }, []
    );
    const overrideProps = {
        ...props,
        policyChangeAutoAssignAndGetVehicleDriverAssignments,
    };

    return <PADriverAssignmentReadOnlyPage {...overrideProps} />;
}

PADriverAssignmentChangeReadOnlyPage.propTypes = wizardProps;
export default PADriverAssignmentChangeReadOnlyPage;
