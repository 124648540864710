/* eslint-disable camelcase */
import React from 'react';
import { useTranslator } from '@jutro/locale';
import messages from './DPTooltip.message';

const ItemListComponent = (props) => {
    const translator = useTranslator();
    const { content, type } = props;
    return <li className={type}>{translator(content)}</li>
};
const DPTooltips = {
    DPAdditionalLivingExpense_Ext : messages.DPAdditionalLivingExpense_Ext,
    DPPersonalPropUsedForBuss_Ext: messages.DPPersonalPropUsedForBuss_Ext,
    DPUnitOwnersCoverage_Ext: messages.DPUnitOwnersCoverage_Ext,
    DPActualCashValueLossSett_Ext: messages.DPActualCashValueLossSett_Ext,
    DPActualCashValLossSettleWindOrHailLossToRoofSurf_Ext: messages.DPActualCashValLossSettleWindOrHailLossToRoofSurf_Ext,
    DPFunctionalReplacementCostLossSett_Ext: messages.DPFunctionalReplacementCostLossSett_Ext,
    DPPersonalPropertyRepCostLossSett_Ext: messages.DPPersonalPropertyRepCostLossSett_Ext,
    DPEarthquake_Ext: messages.DPEarthquake_Ext,
    DPPersonalLiability_Ext: messages.DPPersonalLiability_Ext,
    DPLossAssessmentPropertyCoverage_Ext: messages.DPLossAssessmentPropertyCoverage_Ext,
    DPLossAssessmentLiability_Ext: messages.DPLossAssessmentLiability_Ext,
    DPLossAssessmentCovForEarthquakes_Ext: messages.DPLossAssessmentCovForEarthquakes_Ext,
    DPSinkholeCollapse_Ext: messages.DPSinkholeCollapse_Ext,
    DPSpecialCoverage_Ext: messages.DPSpecialCoverage_Ext,
    DPBroadTheftCoverage_Ext: messages.DPBroadTheftCoverage_Ext,
    DPLimitedTheftCoverage_Ext: messages.DPLimitedTheftCoverage_Ext,
    DPTheftEndNewDwelUnderCons_Ext: messages.DPTheftEndNewDwelUnderCons_Ext,
    DPLimitWaterBackUpAndSumpDis_Ext: messages.DPLimitWaterBackUpAndSumpDis_Ext,
    DPIncLowPowerRecMotorVehicleLiabCovEndor_Ext: messages.DPIncLowPowerRecMotorVehicleLiabCovEndor_Ext,
    DPPersonalInjury_Ext: messages.DPPersonalInjury_Ext,
    DPModifiedLossSettlementFormDP0001ONLY_Ext: messages.DPModifiedLossSettlementFormDP0001ONLY_Ext,
    DPOwnMotorGolfCartPhyLossSchedCov_Ext: messages.DPOwnMotorGolfCartPhyLossSchedCov_Ext,
    DPTreesShrubsAndOtherPlants_Ext: messages.DPTreesShrubsAndOtherPlants_Ext,
    DPNonOwnedBusPurSchedCov_Ext: messages.DPNonOwnedBusPurSchedCov_Ext,
    DPOrdinanceOrLawIncreasedAmountOfCov_Ext: messages.DPOrdinanceOrLawIncreasedAmountOfCov_Ext,
    DPOrdinanceOrLawCovDP1ONLY_Ext: messages.DPOrdinanceOrLawIncreasedAmountOfCov_Ext,
    DPPremLiabNonOwnerOccDwelSchedCov_Ext: messages.DPPremLiabNonOwnerOccDwelSchedCov_Ext,
    DPDeductibles_Ext: messages.DPDeductibles_Ext,
    DPCoverageBSchedCov_Ext: (
        <ul className='tooltipList'>
            <ItemListComponent content={messages.DPCoverageBSchedCov_Ext1} />
            <ItemListComponent type='item'
                content={messages.DPCoverageBSchedCov_Ext2}
            />
        </ul>
    ),
    DPCoverageDFairRentVal_Ext_DP1: messages.DPCoverageDFairRentVal_Ext_DP1,
    DPCoverageDFairRentVal_Ext_DP2: messages.DPCoverageDFairRentVal_Ext_DP2,
    DPCoverageEAddLivExp_Ext_DP1: messages.DPCoverageEAddLivExp_Ext_DP1,
    DPCoverageEAddLivExp_Ext_DP2: messages.DPCoverageDFairRentVal_Ext_DP2,
    DPAssLivCareEndorSchedCov_Ext: (
        <ul className='tooltipList'>
            <ItemListComponent content={messages.DPAssLivCareEndorSchedCov_Ext1} />
            <ItemListComponent type='item'
                content={messages.DPAssLivCareEndorSchedCov_Ext2}
            />
        </ul>
    ),
    DPRoomerBoardersNumberHelp: messages.DPRoomerBoardersNumberHelp,
}

export default DPTooltips