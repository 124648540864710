import React, {
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { InputField } from '@jutro/legacy/components';

function BaseModifierComponent(props) {
    const {
        modifiers,
        onModifierChange,
        setIsModifiersUpdated,
        modifiersConfig,
        messages,
        overallMaxAK,
        overallMinAK,
        modifierAK,
        allStateTotalCreditDebit,
        stateOverallMinMax,
        productName,
        isReadOnly: isReadonlyField
    } = props;

    const {
        agentStateTotalRangeMap
    } = modifiersConfig

    const [stateTotalCreditDebit, updateStateTotalCreditDebit] = useState(allStateTotalCreditDebit);
    const { authUserData } = useAuthentication();

    const states = modifiers.map(modifier => modifier.jurisdiction);
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const getRateFactorID = (rateFactor) => _.get(rateFactor, 'patternCode');

    const stateReadOnlyMap = useMemo(()=> {
        return states.map((currentState) => {
            const currentOverallCreditDebit = parseFloat(stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit).toFixed(4);
            const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
            const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
            let isReadOnly = false;
            if (isExternalUser) {
                // UWs will have a larger credit / debit range than an agent would (UWs can do +/- 35% in AK whereas Agents can only do +/-15%) If an UW enters a modifier sum outside of the agent allowed amount, the screen should become un-editable for agents if he opened this submission
                if(!_.isNil(modifierAK)) {
                    isReadOnly = currentOverallCreditDebit > _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) 
                    || currentOverallCreditDebit < _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`)
                } else {
                    isReadOnly =  currentOverallCreditDebit > currentTotalMaximum || currentOverallCreditDebit < currentTotalMinimum;
                }
            }
            return {state: currentState, isCurrentStateReadOnly: isReadOnly}
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const writeValue = (value, path, item, state) => {
        const currentModifier = modifiers.find((modifier) => modifier.jurisdiction === state);
        const currentModifierIndex = modifiers.findIndex((modifier) => modifier.jurisdiction === state);
        const newRateFactors= _.clone(currentModifier.rateFactors).map((oldRateFactor) => {
            if (getRateFactorID(oldRateFactor) === getRateFactorID(item)) {
                // eslint-disable-next-line no-param-reassign
                oldRateFactor[path] = value;
            }
            return oldRateFactor
        });
        if(path === 'creditDebit') {
            const newTotalCreditDebit = newRateFactors.reduce((total, rateFactor) =>  total + rateFactor.creditDebit, 0);
            const stateToUpdateIndex = allStateTotalCreditDebit.findIndex(factor => factor.state === state);
            updateStateTotalCreditDebit(prev => {
                const newStateTotalCreditDebit = [...prev];
                newStateTotalCreditDebit[stateToUpdateIndex] = {state, overallCreditDebit: newTotalCreditDebit};
                return newStateTotalCreditDebit}
            )
        }
        if(onModifierChange) {
            onModifierChange(productName, currentModifierIndex, newRateFactors);
        }
    };
    
    const handleJustificationChange = (justification, item) => {
        if(justification !== _.get(item, 'justification')){
            setIsModifiersUpdated(true);
        }
    }

    const handlePercentageInputOnBlur = (value, item, state, path) => {
        setIsModifiersUpdated(true);
        writeValue(parseFloat(((parseFloat(value) * 100)/10000).toFixed(4)), path, item, state);
    }

    const JustificationInputField = ({item, state, isReadOnly}) => {
        const [justification, setJustification] = useState(_.get(item, 'justification'));
        return <InputField
            onValueChange={(value) => setJustification(value)}
            onBlur={(value) => {handleJustificationChange(value, item); writeValue(justification, 'justification', item, state)}}
            readOnly={isReadOnly}
            value={justification}
        />
    }

    const CreditDebitInputField = ({item, state, isUnEditable, isReadOnly}) => {
        const shownCreditDebit = _.isNaN(_.get(item, 'creditDebit')) ? null : `${parseFloat(_.get(item, 'creditDebit') * 100).toFixed(2)}%`;
        const [creditDebit, setCreditDebit] = useState(shownCreditDebit);
        const errorCondition = ((parseFloat(creditDebit) * 100)/10000 > item.maximum) || ((parseFloat(creditDebit) * 100)/10000 < item.minimum) || (_.isNaN((parseFloat(creditDebit) * 100)/10000));
        return <InputField
            onValueChange={(value) => {setCreditDebit(value)}}
            onBlur={() => {handlePercentageInputOnBlur(creditDebit, item, state, 'creditDebit')}}
            value={creditDebit}
            readOnly={isUnEditable || isReadOnly}
            showErrors
            validationMessages={errorCondition ? [messages.invalidRateFactorError] : null}
        />
    }

    return (
        <>
            {
                modifiers.map(modifier => {
                    const currentState = modifier.jurisdiction;
                    const currentOverallCreditDebit = stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
                    const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
                    const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
                    const isReadOnly = stateReadOnlyMap.find(factor => factor.state === currentState).isCurrentStateReadOnly;
                    const newRateFactors = modifier.rateFactors.concat({category: 'Overall'});
                    return <div className='mb-20'>
                                <h5 className='mb-20'>{currentState}</h5>
                                <div className='pdl-20'>
                                    <h5>Modifier: Schedule Rating Modification</h5>
                                    <hr/>
                                    <DataTable
                                        columnsConfigurable={false}
                                        data={newRateFactors}
                                        id="basic"
                                        showPagination={false}
                                        showSearch={false}
                                        tableLabel="Modifiers"
                                        >
                                        <DisplayColumn
                                            header="Category"
                                            id="category"
                                            path="category"
                                            textAlign="left"
                                            sortable={false}
                                        />
                                        <DisplayColumn
                                            header="Maximum Credit"
                                            id="minimum"
                                            renderCell={(item, index) => {
                                                return index ===  modifier.rateFactors.length ?
                                                (overallMinAK && `${parseInt(overallMinAK * 100, 10)}%`) || `${parseInt(currentTotalMinimum * 100, 10)}%` 
                                                : `${parseInt(newRateFactors[index].minimum * 100, 10)}%`}}
                                            textAlign="left"
                                            sortable={false}
                                        />
                                        <DisplayColumn
                                            header="Maximum Debit"
                                            id="maximum"
                                            renderCell={(item, index) => {
                                                return index ===  modifier.rateFactors.length ?
                                                (overallMaxAK && `${parseInt(overallMaxAK * 100, 10)}%`)  || `${parseInt(currentTotalMaximum * 100, 10)}%` 
                                                : `${parseInt(newRateFactors[index].maximum * 100, 10)}%`}}
                                            textAlign="left"
                                            sortable={false}
                                        />
                                        <DisplayColumn
                                            header="Credit(-)/Debit(+)"
                                            id="Liability"
                                            renderCell={(item, index) => {
                                                const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                                                return index ===  modifier.rateFactors.length ? `${parseFloat(currentOverallCreditDebit * 100).toFixed(2)}%` : <CreditDebitInputField item={item} state={currentState} isUnEditable={isUnEditable} isReadOnly={isReadonlyField || isReadOnly}/>}} 
                                            textAlign="left"
                                            sortable={false}
                                        />
                                        <DisplayColumn
                                            header="Justification"
                                            id="justification"
                                            renderCell= {(item, index) => {
                                                return index ===  modifier.rateFactors.length ? '' : <JustificationInputField item={item} state={currentState} isReadOnly={isReadonlyField || isReadOnly}/>}}
                                            textAlign="left"
                                            sortable={false}
                                        />
                                    </DataTable>
                                </div>
                            </div>
                })
            }
        </>
    );
}

BaseModifierComponent.propTypes = {

};
BaseModifierComponent.defaultProps = {
    // modifiers,
    // onModifierChange,
    // setIsModifiersUpdated,
    // ModifiersConfig,
    // messages
};
export default BaseModifierComponent;