import { defineMessages } from 'react-intl';

export default defineMessages({
    homeOwnersQuote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ho.Homeowners Insurance Quote',
        defaultMessage: 'Homeowners Insurance Quote'
    },
    location: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ho.Location',
        defaultMessage: 'Location'
    }
});
