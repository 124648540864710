import React, {
    useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WizardSidebarLink } from 'wni-capability-gateway-react';
import HOWizardQuestionnaireComponent from './HOWizardQuestionnaireComponent';


function HOWizardSidebarLink(props) {

    const renderExtraQuestionnaireLinks = useCallback(({
        wizardData,
        handleDownload,
    }) => {
        return (<HOWizardQuestionnaireComponent
            wizardData={wizardData}
            handleDownload={handleDownload}
            lobDataProductKey='homeowners'
        />);
    }, []);

    return (
        <WizardSidebarLink sideLinkSlotRenderFunc={renderExtraQuestionnaireLinks}/>
    );
}

HOWizardSidebarLink.propTypes = {
};

HOWizardSidebarLink.defaultProps = {

};

export default HOWizardSidebarLink;
