import _ from 'lodash';

import { R1Config } from 'wni-portals-config-js';

// const {
//     PEPAWizardConfig: {
//         flowStepIdToPageMap: extraFlowStepPageMap
//     }
// } = R1Config;

// See PortalValidationIssue.PORTAL_PAGE_PREFIX
const PORTAL_FLOW_STEP_PREFIX = 'Portal:';
const PORTAL_FLOW_STEP_PA_PAYMENT_PAGE_ID = 'Portal:PAPaymentDetailsPage';
const PORTAL_FLOW_STEP_PA_DRIVER_PAGE_ID = 'PADrivers';


/**
 * Get a map of flowStepId to pageId
 *
 * //
 * Note: flowSteps here are typically fed to getPageIdForValidationIssue()
 * @param {object} wizardStepToFieldMapping
 * @returns {object} the map from flowStepId to pageId;
 */
function getFlowStepToPageMap(wizardStepToFieldMapping) {
    const policyCenterPagesMapping = {};
    const portalPagesMapping = {};
    Object.entries(wizardStepToFieldMapping).forEach(([portalPageId, stepDetails]) => {
        if (!portalPageId) {
            return;
        }
        const {
            pasFlowStepId, // : flowStepId,
            receiveIssueFromPolicyCenterPages = [],
            receiveIssueFromPortalPages = [],
        } = stepDetails;

        if (portalPageId && !_.isNil(pasFlowStepId)) {
            // 2024-02-22 (Thu): pasFlowStepId acts the same as receiveIssueFromPolicyCenterPages now
            // policyCenterPagesMapping[flowStepId] = portalPageId;
            const pasFlowSteps = _.isArray(pasFlowStepId) ? pasFlowStepId : [pasFlowStepId];
            pasFlowSteps.forEach((pcPage) => { policyCenterPagesMapping[pcPage] = portalPageId; });
        }
        if (!_.isEmpty(receiveIssueFromPolicyCenterPages)) {
            receiveIssueFromPolicyCenterPages.forEach((pcPage) => { policyCenterPagesMapping[pcPage] = portalPageId; });
        }
        if (!_.isEmpty(receiveIssueFromPortalPages)) {
            receiveIssueFromPortalPages.forEach((portalPage) => { portalPagesMapping[portalPage] = portalPageId; });
        }
    });
    return {
        policyCenterPagesMapping,
        portalPagesMapping,
    };
}


/**
 * Get PageId from validationIssue
 * //
 * Note: flowStepsFromPageMap comes from getFlowStepToPageMap();
 * @param {object} validationIssue
 * @param {object} flowStepsToPagesMap
 * @returns {string}
 */
function getPageIdForValidationIssue(validationIssue, flowStepsToPagesMap = {}) {
    const {
        flowStepId: flowStep,
        reason = '',
        relatedEntity: {
            type: entityType
        } = {},
    } = validationIssue;

    if (!flowStep) {
        return null;
    }

    const {
        policyCenterPagesMapping = {},
        portalPagesMapping = {},
    } = flowStepsToPagesMap;

    let retval = null;

    if (flowStep.startsWith(PORTAL_FLOW_STEP_PREFIX)) { // It is a Portal page
        const portalPage = flowStep.substring(PORTAL_FLOW_STEP_PREFIX.length);
        if (portalPage) {
            retval = portalPagesMapping[portalPage] || portalPage;
        }
    } else {
        retval = policyCenterPagesMapping[flowStep];
    }

    return retval;
}

/**
 * Return a map of pageId to validationIssues
 * @param {array} validationIssues 
 * @param {object} wizardStepToFieldMapping 
 * @returns {object} a map of {pageId => valiationIssues}
 */
function getPageIdToValidationIssuesMap(validationIssues, wizardStepToFieldMapping) {
    const retval = {};
    const flowStepToPageMap = getFlowStepToPageMap(wizardStepToFieldMapping);
    validationIssues.forEach((validationIssue) => {
        const pageId = getPageIdForValidationIssue(validationIssue, flowStepToPageMap);
        if (pageId) {
            const currentPageIssues = retval[pageId];
            if (currentPageIssues) {
                currentPageIssues.push(validationIssue);
            } else {
                retval[pageId] = [validationIssue];
            }
        }
    });
    return retval;
}

export default {
    PORTAL_FLOW_STEP_PA_PAYMENT_PAGE_ID,
    PORTAL_FLOW_STEP_PA_DRIVER_PAGE_ID,
    getFlowStepToPageMap,
    getPageIdForValidationIssue,
    //
    getPageIdToValidationIssuesMap,
};
