import { createContext } from "react"

/**
 * Common properties used inside a single clause
 * @property {String} idPrefix
 * @property {String} clauseCode
 * @property {String} clausePublicID
 * @property {String} clausePath
 * @property {Boolean} isEditable
 * @property {Boolean} isDisabled
 * @property {Function} onChangeClause
 * @property {Function} onSyncCoverages
 * @property {Function} onChangeSubmissionAndSync
 * @property {String} labelPosition
 * @property {String} labelTop
 * @property {Function} onValidate
 * @property {Function} setIsEditing
 * @property {Boolean} showErrors
 * @property {String} currentPageId
 */
const SingleClauseContext = createContext({});

export default SingleClauseContext;