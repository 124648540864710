import { defineMessages } from 'react-intl';

export default defineMessages({
    paAddAdditionalInterestContact: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Contact',
        defaultMessage: 'Contact',
    },
    paAddAdditionalInterestFinancialInstitutionName: {
        id: 'quoteandbind.views.pa-watercraft.additional-interest.Financial Institution Name',
        defaultMessage: 'Financial Institution Name',
    },
    paAddAdditionalInterestTrustName: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Trust Name',
        defaultMessage: 'Trust Name',
    },
    paAddAdditionalInterestCompanyType: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Company Type',
        defaultMessage: 'Company Type',
    },
    paAddAdditionalInterestLoanNumber: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Loan Number',
        defaultMessage: 'Loan Number',
    },
    paAddAdditionalInterestRelationshipToInsured: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Relationship to Insured',
        defaultMessage: 'Relationship to Insured',
    },
    paAddAdditionalInterestExplain: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Explain',
        defaultMessage: 'Explain',
    },
    additionalInterestCount: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.additionalInterestCount',
        defaultMessage: 'Additional Interest: { count }'
    },
    removeAdditionalInterest: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Remove Additional Interest.ext',
        defaultMessage: 'Remove Additional Interest?',
    },
    removeAdditionalInterestDesc: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.AddIntRemovalMsg.ext',
        defaultMessage: 'Are you sure you want to remove this additional interest from the list?',
    },
    addAdditionalInterest: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.addAdditionalInterest',
        defaultMessage: 'Add Additional Interest',
    },
    addAdditionalInterestType: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Type',
        defaultMessage: 'Type'
    },
    paAddAdditionalInterestTrust: {
        id: 'quoteandbind.views.pa-vehicles.additional-interest.Trust',
        defaultMessage: 'Trust'
    },
    editAdditionalInterest: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.editAdditionalInterest',
        defaultMessage: 'Edit Additional Interest',
    }
});
