import _ from 'lodash';
import {
    WniClausesUtil,
} from 'wni-portals-util-js';

function getPathToTerm(changedPath) {
    // onBlur event returns an object instead of path as a String
    const path = _.isString(changedPath) ? changedPath : changedPath.model;
    const matched = /(.*.terms(.children)?\[\d+\])(.*$)/.exec(path);
    if (!matched) {
        return changedPath;
    }
    const [, termPath] = matched;
    return termPath;
}

// This is the last part of path, such as dateValue stringValue
function getScheduleItemDataRelativePath(scheduleValuePath) {
    const regex = /([^.]\w+)$/g
    // There should be only one path in array
    const matchedPaths =  scheduleValuePath.match(regex)
    if (_.get(matchedPaths, 'length') === 1) {
        return matchedPaths[0]
    }
    return null
}

function getScheduleItemDataBasePath(scheduleValuePath) {
    const regex = /(\.\w+)$/g
    return scheduleValuePath.replace(regex, '')
}

function getValuePathByVMPath(VMPath) {
    return VMPath.replaceAll('.children', '')
}

function setValue(coverages, pathToCoverages, value) {
    // Currency field
    if (_.get(value, 'currency')) {
        _.set(coverages, pathToCoverages, _.get(value, 'amount'));
    } else {
        _.set(coverages, pathToCoverages, value);
    }
    return coverages
}

function setValueToCoverages(coverages, value, path) {
    const pathToCoverages = getValuePathByVMPath(path)
    const pathToChangedCoverage = pathToCoverages.substring(0, 3);
    coverages = setValue(coverages, pathToCoverages, value);
    if (path.includes('.terms')) {
        const pathToTerm = getPathToTerm(pathToCoverages);
        _.set(coverages, `${pathToTerm}.updated`, true);
    } else if (path.includes('.schedule')) {
        _.set(coverages, `${pathToChangedCoverage}.schedule.updated_Ext`, true);
    } else {
        _.set(coverages, `${pathToChangedCoverage}.updated`, true);
    }
    return coverages.concat([]);
}

function setValueToCoverage(coverage, value, path) {
    const pathToCoverage = getValuePathByVMPath(path)
    coverage = setValue(coverage, pathToCoverage, value);
    if (path.startsWith('terms')) {
        const pathToTerm = pathToCoverage.substring(0, 8);
        _.set(coverage, `${pathToTerm}.updated`, true);
    } else if (path.startsWith('schedule')) {
        _.set(coverage, `schedule.updated_Ext`, true);
    } else {
        _.set(coverage, `updated`, true);
    }
    return coverage;
}

/**
 * Sample Input:
 * coverages: LobCoveargesDTO
 * coveragesPath: lobData.watercraft.offerings.children[0].coverages.lineCoverages
 * value: "Limit1000"
 * path: lobData.watercraft.offerings.children[0].coverages.lineCoverages.children[1].terms.children[0].chosenTerm
 * 
 * @param {Object} coverages DTO, not VM
 * @param {String} coveragesPath VM Path, not DTO Path, Hmm.
 * @param {String} value 
 * @param {String} path changedPath
 * @returns {Void}
 */
function setClauseValue(coverages, coveragesPath, value, path) {
    const changedValuePathToCoverages = path.replace(coveragesPath, '');
    // let changedValuePathToCoverages = coveragesPath;
    // if (!_.isEmpty(path)) {
    //     changedValuePathToCoverages = path.replace(coveragesPath, '');
    // }
    return setValueToCoverages(coverages, value, changedValuePathToCoverages);
}

function selectCoveragesBySelectedCoverageCodes(coverages, selectedCoverageCodes) {
    const res = selectedCoverageCodes.reduce((updatedCoverages, selectedCoverageCode) => {
        return updatedCoverages.map((updatedCoverage) => {
            if (_.get(updatedCoverage, 'code_Ext') === selectedCoverageCode) {
                _.set(updatedCoverage, 'selected', true)
                _.set(updatedCoverage, 'updated', true)
            }
            return updatedCoverage
        })
    }, coverages)
    return res
}

function generateUpdatedCoveragesDTO(coverages, lobName) {
    const lineCoverages = _.get(coverages, 'lineCoverages', []);
    const lineCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(lineCoverages)
    const allWatercraftCoverages = _.get(coverages, 'watercraftCoverages', [])
    const allWatercraftCoveragesToUpdate = []
    allWatercraftCoverages.forEach((walCoverageDTO) => {
        const watercraftCoveragesToUpdate = WniClausesUtil.filterUnchangedClauses(_.get(walCoverageDTO, 'coverages', []))
        if (!_.isEmpty(watercraftCoveragesToUpdate)) {
            allWatercraftCoveragesToUpdate.push({
                ...walCoverageDTO,
                coverages: watercraftCoveragesToUpdate,
            })
        }
    })
    const coveragesDTO = {};
    _.set(coveragesDTO, 'lineCoverages', lineCoveragesToUpdate);
    if (!_.isEmpty(allWatercraftCoveragesToUpdate)) {
        _.set(coveragesDTO, 'watercraftCoverages', allWatercraftCoveragesToUpdate);
    }
    return {
        [lobName]: coveragesDTO
    }
}

function isClauseToUpdateEmpty(clausesToUpdate, lobName) {
    const lobCoverages = _.get(clausesToUpdate, lobName);
    return _.isEmpty(lobCoverages)
}

function getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName) {
    const lobCoverages = _.get(clausesToUpdate, lobName);
    const lineCoveragesPublicID = _.get(lobCoverages, 'lineCoverages', []).map((coverageDTO) => coverageDTO.publicID);
    const waterCraftCoveragesPublicID =  _.get(lobCoverages, 'watercraftCoverages', [])
        .reduce((accumulator, {coverages: watercraftCoverages}) => {
            const updatedCovPublicIDPerWC = watercraftCoverages.map((covDTO) => covDTO.publicID)
            return _.uniq(accumulator.concat(updatedCovPublicIDPerWC))
        }, [])
    return []
        .concat(lineCoveragesPublicID)
        .concat(waterCraftCoveragesPublicID)
}


// const shouldImmediatelySyncAfterValueChange = (covCode) => {
//     return scheduleConfig.codesOfCovImmediatelySyncAfterValueChange.includes(covCode)
// }

const isOptionTerm = (term) => {
    return _.isNil(_.get(term, 'valueType'))
}

const isTermHasOnlyOneOption = (term) => {
    const options = _.get(term, 'options', [])
    return isOptionTerm(term) && options.length === 1
}

const getOneOptionTermsInCoverage = (coverage) => {
    const terms = _.get(coverage, 'terms', []);
    const oneOptionTerms = terms.filter((term) => isTermHasOnlyOneOption(term));
    return oneOptionTerms;
}

const isChosenTermNull = (term) => {
    const chosenTerm = _.get(term, 'chosenTerm')
    if (_.isNil(chosenTerm)) {
        return true
    }
    const options = _.get(term, 'options', [])
    const optionCodes = options.map((option) => _.get(option, 'code'))
    if (optionCodes.includes(chosenTerm)) {
        return false
    }
    return true
}

const isCoverageHasOneOptionAndNullTerm = (coverage) => {
    const oneOptionTerms = getOneOptionTermsInCoverage(coverage);
    const oneOptionAndNullTerms = oneOptionTerms.filter((term) => isChosenTermNull(term))
    return _.get(oneOptionAndNullTerms, 'length') > 0;
}

const hasOneOptionAndNullTermInCoverages = (coverages) => {
    const coverageHasOneOptionAndNullTerm = coverages.filter((coverage) => {
        return isCoverageHasOneOptionAndNullTerm(coverage)
    })
    return _.get(coverageHasOneOptionAndNullTerm, 'length') > 0
}

const setValueToOneOptionAndNullTermToCoverages = (coverages) => {
    const updatedCoverages = coverages.map((coverage) => {
        if (!isCoverageHasOneOptionAndNullTerm(coverage)) {
            return coverage;
        }
        let updatedCoverage = _.clone(coverage);
        const terms = _.get(coverage, 'terms', []);
        const oneOptionAndNullTermCodes = terms
            .filter((term) => {
                const termHasOnlyOneOption = isTermHasOnlyOneOption(term);
                const isTermNull = isChosenTermNull(term)
                return termHasOnlyOneOption && isTermNull
            })
            .map((term) => {
                return _.get(term, 'code_Ext')
            });
        oneOptionAndNullTermCodes.forEach((termCode) => {
            const termIndex = terms.findIndex((term) => _.get(term, 'code_Ext') === termCode);
            const termToUpdate = terms[termIndex];
            const chosenTermValue = _.get(termToUpdate, 'options[0].code')
            updatedCoverage = setValueToCoverage(updatedCoverage, chosenTermValue, `terms[${termIndex}].chosenTerm`)

        })
        return updatedCoverage
    })
    return updatedCoverages
}

function isTermInvalid(term) {
    // Use chosenTerm instead of chosenTermValue.
    // chosenTermValue only update after vm is sent to PC
    // value field of date is directDateValue
    return term.required
        && !term.chosenTerm
        && !term.directDateValue
        && _.isNil(term.directValue)
        && _.isNil(term.directStringValue)
        && _.isNil(term.directBooleanValue);
}


function isScheduleItemInvalid(scheduleItem, propertyInfos = []) {
    if (_.isNil(scheduleItem)) {
        return false
    }
    const itemData = _.get(scheduleItem, 'itemData');
    const invalidItem = Object.keys(itemData).find((key) => {
        const scheduleItemData = _.get(itemData, key)
        const propertyInfo = propertyInfos.find((item) => _.get(item, 'id') === key)
        if (!_.get(propertyInfo, 'required')) {
            return false
        }
        if (!_.get(scheduleItemData, 'visible_Ext')) {
            return false
        }
        if (!_.get(scheduleItemData, 'editable_Ext')) {
            return false
        }
        const {
            dateValue,
            typeCodeValue,
            stringValue,
            booleanValue,
            bigDecimal,
            integerValue
        } = scheduleItemData
        if (_.isNil(dateValue)
            && _.isNil(typeCodeValue)
            && _.isEmpty(stringValue)
            && _.isNil(booleanValue)
            && _.isNil(bigDecimal)
            && _.isNil(integerValue)
        ) {
            return true
        }
        // if type is option, check if chosenTerm is still in options
        if (_.get(propertyInfo, 'valueType') === 'TYPEKEY') {
            const options = _.get(scheduleItemData, 'options_Ext', [])
            const optionCodes = options.map((option) => _.get(option, 'code'))
            if (!optionCodes.includes(typeCodeValue)) {
                return true
            }
        }
        return false
    })
    if (_.isNil(invalidItem)) {
        return false
    }
    return true
}

// eslint-disable-next-line no-unused-vars
function isCoverageInvalid(coverage) {
    if (coverage.required && !coverage.selected) {
        return true;
    }
    if (coverage.hasTerms) {
        const invalidTerm = _.find(coverage.terms, (term) => {
            return isTermInvalid(term)
        });
        if (!_.isEmpty(invalidTerm)) {
            return true;
        }
    }

    if (!_.isNil(coverage.schedule)) {
        const schedule = _.get(coverage, 'schedule');
        const propertyInfos = _.get(schedule, 'propertyInfos');
        const scheduleItems = _.get(schedule, 'scheduleItems');
        const invalidScheduleItem = _.find(scheduleItems, (scheduleItem) => {
            return isScheduleItemInvalid(scheduleItem, propertyInfos)
        })
        if (!_.isNil(invalidScheduleItem)) {
            return true
        }
    }
    return false;
}

// eslint-disable-next-line no-unused-vars
function isOpenedScheduleOrStructureInValid(openedItemNumber, coverage) {
    if(_.isNil(openedItemNumber)) {
        return true
    }
    const opendedSchedule = _.get(coverage, 'schedule.scheduleItems', [])
        .find((item) => _.get(item, 'itemNumber') === openedItemNumber);
    
    const propertyInfos = _.get(coverage, 'schedule.propertyInfos');
    return isScheduleItemInvalid(opendedSchedule, propertyInfos)
}

function hasInvalidCoverage(coverages) {
    return _.find(coverages, (cov) => {
        return isCoverageInvalid(cov)
    });
}

const itemDetailsPathMap = {
    'watercraft_motor_trailer': 'watercraftDetail',
    'watercraft_motor': 'watercraftDetail',
    'watercraft': 'watercraftDetail',
    'trailer': 'trailerDetail',
    'motor': 'motorDetail'
}

const getWatercraftDescription = (watercraft) => {
    const itemToBeInsuredDetailsPath = _.get(watercraft, 'itemToBeInsured_Ext')
    const insuredItemDetails = _.get(watercraft, itemDetailsPathMap[itemToBeInsuredDetailsPath])
    
    // YEAR MAKE MODEL
    return `${_.get(insuredItemDetails, 'year')} ${_.get(insuredItemDetails, 'manufacturer')} ${_.get(insuredItemDetails, 'model')}`
};

const getIsPhysicalDamageCovRequired = (waterCraft) => {
    switch (waterCraft.itemToBeInsured_Ext) {
        case 'watercraft_motor_trailer':
        case 'watercraft_motor':
        case 'watercraft':
            return waterCraft.watercraftDetail.isPhysicalDamageCovRequired;
        case 'motor':
            return waterCraft.motorDetail.isPhysicalDamageCovRequired;
        case 'trailer':
            return waterCraft.trailerDetail.isPhysicalDamageCovRequired;
        default:
            return true;
    }
}

export default {
    setValueToCoverages,
    setValueToCoverage,
    setClauseValue,
    generateUpdatedCoveragesDTO,
    isClauseToUpdateEmpty,
    selectCoveragesBySelectedCoverageCodes,
    getValuePathByVMPath,
    // shouldImmediatelySyncAfterValueChange,
    hasOneOptionAndNullTermInCoverages,
    setValueToOneOptionAndNullTermToCoverages,
    isCoverageInvalid,
    hasInvalidCoverage,
    getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO,
    getScheduleItemDataBasePath,
    getScheduleItemDataRelativePath,
    isOpenedScheduleOrStructureInValid,
    isTermInvalid,
    getWatercraftDescription,
    getIsPhysicalDamageCovRequired,
};