import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import { DebugUtil, DebugDataUtil } from 'wni-portals-util-js';
import DebugPanel from '../DebugPanel/DebugPanel';
import domainData from '../config/DomainData.config';
import interactionModelData from '../config/InteractionModes.config';
import WorkflowTypes, { DEFAULT_WORKFLOW_TYPE } from '../config/WorkflowTypes.config';
import ProductionWorkflows, { DEFAULT_PRODUCTION_WORKFLOW } from '../config/ProductionWorkflow.config';
import CommonUITypes, { DEFAULT_COMMON_UI_TYPE } from '../config/CommonUITypes.config';

function useDebugData() {
    const [domainCompany, updateDomainCompany] = useState(DebugUtil.getDefaultDomain(domainData));
    const [interactionModel, updateInteractionModel] = useState(DebugUtil.getDefaulInteractionMode(interactionModelData));
    const [isMenuBarEnabled, updateIsMenuBarEnabled] = useState(DebugUtil.getDefaultMenuBarVisibility());
    const [workflowType, updateWorkflowType] = useState(DEFAULT_WORKFLOW_TYPE);
    const [productionWorkflow, updateProductionWorkflow] = useState(DEFAULT_PRODUCTION_WORKFLOW);
    const [commonUIType, updateCommonUIType] = useState(DEFAULT_COMMON_UI_TYPE);

    const isDebugEnabled = DebugUtil.isDebugEnabled();

    useEffect(() => {
        DebugUtil.getFavicon(domainCompany);
    }, [domainCompany]);

    const domainChangeFn = useCallback((value) => {
        const matchValue = domainData.find((item) => item.code === value.code);
        updateDomainCompany(matchValue);
        // manually set data to DebugDataUtil to let the XUtil.js under Modules package could use
        DebugDataUtil.setDebugData('domainCompany', matchValue);
    }, []);

    const interactionModelChangeFn = useCallback((value) => {
        const matchValue = interactionModelData.find((item) => item.code === value.code);
        updateInteractionModel(matchValue);
        // manually set data to DebugDataUtil to let the XUtil.js under Modules package could use
        DebugDataUtil.setDebugData('interactionModel', matchValue);
    }, []);

    const menuBarEnabledChangeFn = useCallback((value) => {
        updateIsMenuBarEnabled(value);
        // manually set data to DebugDataUtil to let the XUtil.js under Modules package could use
        DebugDataUtil.setDebugData('isMenuBarEnabled', value);
    }, []);

    const workflowTypeChangeFn = useCallback((value) => {
        const matchValue = WorkflowTypes.find((item) => item.code === value.code);
        updateWorkflowType(matchValue);
        // manually set data to DebugDataUtil to let the XUtil.js under Modules package could use
        DebugDataUtil.setDebugData('workflowType', matchValue);
    }, []);

    const productionWorkflowChangeFn = useCallback((value) => {
        const matchValue = ProductionWorkflows.find((item) => item.code === value.code);
        updateProductionWorkflow(matchValue);
        // manually set data to DebugDataUtil to let the XUtil.js under Modules package could use
        DebugDataUtil.setDebugData('productionWorkflow', matchValue);
    }, []);

    const commonUITypeChangeFn = useCallback((value) => {
        const matchValue = CommonUITypes.find((item) => item.code === value.code);
        updateCommonUIType(matchValue);
        // manually set data to DebugDataUtil to let the XUtil.js under Modules package could use
        DebugDataUtil.setDebugData('commonUIType', matchValue);
    }, []);

    const renderDebugPanel = () => {
        return (
            <DebugPanel
                domainDataConfig={{
                    onValueChange: domainChangeFn,
                    value: domainCompany,
                }}
                interactionModeConfig={{
                    onValueChange: interactionModelChangeFn,
                    value: interactionModel,
                }}
                menuBarConfig={{
                    value: isMenuBarEnabled,
                    onValueChange: menuBarEnabledChangeFn,
                }}
                workflowConfig={{
                    value: workflowType,
                    onValueChange: workflowTypeChangeFn,
                }}
                productionConfig = {{
                    value: productionWorkflow,
                    onValueChange: productionWorkflowChangeFn,
                }}
                commonUITypeConfig = {{
                    value: commonUIType,
                    onValueChange: commonUITypeChangeFn,
                }}
            />
        );
    };

    // init DebugDataUtil data
    DebugDataUtil.setDebugData('isDebugEnabled', isDebugEnabled);
    DebugDataUtil.setDebugData('domainCompany', domainCompany);
    DebugDataUtil.setDebugData('interactionModel', interactionModel);
    DebugDataUtil.setDebugData('isMenuBarEnabled', isMenuBarEnabled);
    DebugDataUtil.setDebugData('workflowType', workflowType);
    DebugDataUtil.setDebugData('productionWorkflow', productionWorkflow);
    DebugDataUtil.setDebugData('commonUIType', commonUIType);

    return {
        isDebugEnabled,
        //
        domainCompany,
        //
        interactionModel,
        //
        isMenuBarEnabled,
        //
        workflowType,
        productionWorkflow,
        //
        commonUIType,
        //
        renderDebugPanel,
    };
}

export default useDebugData;
