import React, { useState, useContext }from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Button } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './SingleVehicleIncident.metadata.json5'
import messages from './SingleVehicleIncident.message'


const SingleVehicleIncident = (props) => {
    const {
        originVehicleIncident,
        editingVehicleIncidentIndex,
        onPopupCancel,
        onPopupSave,
    } = props


    const {
        isComponentValid,
        onValidate
    } = useValidation('SingleVehicleIncident')

    const [showErrors, setShowErrors] = useState(false);

    const viewModelService = useContext(ViewModelServiceContext);

    // handle vehicle is undefined
    if (_.isEmpty(_.get(originVehicleIncident, 'vehicle'))) {
        _.set(originVehicleIncident, 'vehicle', {});
    }

    const [vehicleIncidentVM, setVehicleIncidentVM] = useState(() => viewModelService.create(
            originVehicleIncident,
            'cc',
            'wni.edge.capabilities.claim.lob.impl.commonauto.dto.WniPEVehicleIncidentDTO'
        ))

    const writeValue = (value, path) => {
        const newVehicleIncidentVM = _.clone(vehicleIncidentVM);
        _.set(newVehicleIncidentVM.value, path, value);
        if (path.includes('ownerContact')) {
            _.set(newVehicleIncidentVM.value, 'ownerContact.updated_Ext', true);
        }
        setVehicleIncidentVM(newVehicleIncidentVM);
    }



    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(vehicleIncidentVM.value, editingVehicleIncidentIndex)
    }


    const overrideProps = {

        PersonaTypeContactContainer1: {
            visible: _.get(vehicleIncidentVM.value, 'ownerContact.subtype') === 'Person'
        },
        PersonaTypeContactContainer2: {
            visible: _.get(vehicleIncidentVM.value, 'ownerContact.subtype') === 'Person'
        },
        CompanyTypeContactContainer: {
            visible: _.get(vehicleIncidentVM.value, 'ownerContact.subtype') === 'Company'
        },
        workPhone: {
            visible: _.get(vehicleIncidentVM.value, 'ownerContact.primaryPhoneType') === 'work'
        },
        homePhone: {
            visible: _.get(vehicleIncidentVM.value, 'ownerContact.primaryPhoneType') === 'home'
        },
        cellPhone: {
            visible: _.get(vehicleIncidentVM.value, 'ownerContact.primaryPhoneType') === 'mobile'
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={vehicleIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
            <div style={{
                display: 'flex',
                'justify-content': 'right',
                gap: '10px'
            }}>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
                label={messages.cancel}
            />
            <Button
                type="filled"
                onClick={onSaveClicked}
                label={messages.save}
            />
        </div>
    </>
}

export default SingleVehicleIncident
