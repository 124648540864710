import React from 'react';
import PUUnderlyingPage from './PUUnderlyingPage';

function PUUnderlyingReadOnlyPage(props) {

    return (
        <PUUnderlyingPage {...props} isReadOnly checkRequiredForIssuance={false} />
    );
}

export default PUUnderlyingReadOnlyPage;
