import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
// import { DocumentUploadService } from '@xengage/gw-portals-document-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniIncidentMsg'), method, data, additionalHeaders);
}

export default class WniVendorComparsionService {
    /**
     * Invokes WniIncidentHandler.getErrorsAndWarnings()
     * @param {object} quoteId quoteId
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains
     * JobValidationUnderwritingIssuesDTO
     */
    static getIncidentErrorsAndWarnings(quoteId, authHeader = {}) {
        return processSubmission('getIncidentErrorsAndWarnings', [quoteId], authHeader);
    }

    /**
     * Invokes WniIncidentHandler.getPolicyChangeIncidentErrorsAndWarnings()
     * @param {object} jobId jobId
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains
     * JobValidationUnderwritingIssuesDTO
     */
    static getPolicyChangeIncidentErrorsAndWarnings(jobId, authHeader = {}) {
        return processSubmission('getPolicyChangeIncidentErrorsAndWarnings', [jobId], authHeader);
    }

    /**
     * Invokes WniIncidentHandler.triggerClueService()
     * Clue/Prior Loss services
     * @param {String} quoteId 
     * @param {String} sessionUUID
     * @param {Object} additionalHeaders 
     * @returns {Promise}
     */
    static triggerClueService(quoteId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('triggerClueService', [quoteId, sessionUUID], additionalHeaders);
    }
}
