
import React, {
    useEffect,
    useState,
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { WALIncidentsAndReportsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';
import WALIncidentsAndReportsUtil from '../../../wni-capability-quoteandbind-wal-react/pages/IncidentsAndReports/WALIncidentsAndReportsUtil';

function WALIncidentsAndReportsChangeReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;
    const {
        lobData: {
            watercraft: {
                incidentsAndReports_Ext: {
                    clueenter = [],
                    mvrenter = [],
                    isTermBasedOnRenewal
                },
            },
        },
    } = submissionVM.value;
    // we need filter duplicate manually clue/mvr for policy change
    const [filteredManualCLUE, updateFilteredManualCLUE] = useState([]);
    const [filteredManualMVR, updateFilteredManualMVR] = useState([]);
    useEffect(() => {
        const filteredCLUE = WALIncidentsAndReportsUtil.filterCLUEManuallyData(clueenter);
        const filteredMVR = WALIncidentsAndReportsUtil.filterMVRManuallyData(mvrenter);
        updateFilteredManualCLUE(filteredCLUE);
        updateFilteredManualMVR(filteredMVR);
    }, []) ;
    return (
        <WALIncidentsAndReportsReadOnlyPage 
            {...props}
            showPolicyClaims={isTermBasedOnRenewal}
            filteredManualCLUE={filteredManualCLUE}
            filteredManualMVR={filteredManualMVR}
        />
    );
}

WALIncidentsAndReportsChangeReadOnlyPage.propTypes = WALIncidentsAndReportsReadOnlyPage.propTypes;
WALIncidentsAndReportsChangeReadOnlyPage.defaultProps = WALIncidentsAndReportsReadOnlyPage.defaultProps;

export default WALIncidentsAndReportsChangeReadOnlyPage;






