import React, { useContext, useState } from 'react';
import { Chevron } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateField } from 'wni-common-base-components';
import messages from 'gw-capability-gateway-react/Policies/Summary/Summary.messages';
import VehicleCoverage from './VehicleCoverage/VehicleCoverage';
import metadata from './PolicyVehiclesDriversInfo.metadata.json5';
import infostyles from './PolicyVehiclesDriversInfo.module.scss';

function renderDateCell(item, index, {path}) {
    return <WniDateField readOnly value={item[path]} />
}

function PolicyVehiclesDriversInfo({ value }) {
    const translator = useTranslator();

    const [openItems, setOpenItems] = useState([]);
    if (_.isEmpty(value.vehicleDTOs)) {
        return null;
    }
    
    const getLimitDeductableValue = (terms, type) => {
        if (!_.isEmpty(terms) && terms[0].modelType === type) {
            return terms[0].amount;
        }
        return '-';
    };
    const getCoverage = (coverages, isLineCoverage) => {
        return coverages.map((coverage) => {
            return {
                name: coverage.name,
                coverageType: isLineCoverage ? translator(messages.vehicleInfoPolicy) : null,
                limit: getLimitDeductableValue(coverage.terms, 'Limit'),
                deductible: getLimitDeductableValue(coverage.terms, 'Deductible'),
                premium: coverage.premium
            };
        });
    };
    const lineCoverage = getCoverage(value.coverageDTOs, true);
    const toggleShowHide = (item) => {
        const openedItem = _.clone(openItems);
        const index = _.indexOf(openedItem, item);
        if (index === -1) {
            openedItem.push(item);
            setOpenItems(openedItem);
        } else {
            setOpenItems(_.slice(openedItem, index, 0));
        }
    };
    const generateVehicleOverrides = () => {
        const overrides = value.vehicleDTOs.map((vehicleDTO, index) => {
            const vehicleCoverage = getCoverage(vehicleDTO.coverages);
            return {
                [`vehicleCoverageInfo${index}`]: {
                    data: _.concat(lineCoverage, vehicleCoverage),
                    show: openItems
                },
                [`showContentToggleLink${index}`]: {
                    onClick: () => toggleShowHide(index),
                    icon: _.includes(openItems, index) ? 'gw-arrow-drop-up' : 'gw-arrow-drop-down',
                    content: _.includes(openItems, index)
                        ? translator(messages.hideVehicleCoverages)
                        : translator(messages.showVehicleCoverages)
                },
                [`vehicleCoverageContainer${index}`]: {
                    visible: _.includes(openItems, index)
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    const overrides = {
    
        ...generateVehicleOverrides()
    };
    const resolvers = {
        resolveClassNameMap: infostyles,
        resolveCallbackMap: {
            renderDateCell: renderDateCell
        },
        resolveComponentMap: {
            vehiclecoverage: VehicleCoverage
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={value}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyVehiclesDriversInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.shape({}),
        vehicleDTOs: PropTypes.shape([]),
        driverDTOs: PropTypes.shape([]),
        additionalLiabilityCoverages: PropTypes.shape([]),
        additionalPropertyCoverages: PropTypes.shape([])
    }).isRequired
};
export default PolicyVehiclesDriversInfo;
