
import React from 'react';

import { CRRiskAnalysisPage } from 'wni-capability-quoteandbind-cr-react';

function CRRiskAnalysisChangePage(props) {

    return (
        <CRRiskAnalysisPage {...props} isPolicyChange />
    );

}


export default CRRiskAnalysisChangePage;