import _ from 'lodash';

/**
 * QuestionSet Mapper for PolicyChange
 *
 * See QuestionSetsParser.contentMapper
 * @returns {Function}
 */
function getQSMapperForPUHouseholdInfo() {
    return (metaDataContent) => {
        if(metaDataContent.id.endsWith('Explain_Ext')){
            _.set(metaDataContent, 'componentProps.className', 'indentSection');
            _.set(metaDataContent, 'componentProps.showRequired', true);
            _.set(metaDataContent, 'componentProps.required', true);
        }
        return metaDataContent;
    };
}

export default {
    getQSMapperForPUHouseholdInfo,
};
