
import React, {
    useCallback, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react';
import AgencyMatchPopupComponentForCL from './AgencyDetailsPopupComponentForCL/AgencyMatchPopupComponentForCL';
import metadata from './AgencyOfServiceComponentForCL.metadata.json5';

const AgencyOfServiceComponentForCL = (props) => {
    const modalApi = useWniModal();
    const {
        model: submissionVM,
        isReadOnly,
        onAgencyChange,
        onValidate,
        showErrors,
        externalAgencyOptions,
        licensedAgentOptions,
        servicingAgentOptions,
        updateExternalAgency,
        externalAgencyName,
        externalAgencyPublicID,
        licensedAgentValue,
        updateLicensedAgent,
        servicingAgentValue,
        updateServicingAgent,
        updateLicensedAgentOptions,
        updateServicingAgentOptions
    } = props;
    const { authUserData } = useAuthentication();
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const [internalAgencyName, updateInternalAgencyName] = useState(externalAgencyName);

    useEffect(()=> {
        updateInternalAgencyName(externalAgencyName);
    }, [externalAgencyName]);

    const handleExternalAgency = (value) => {
        updateExternalAgency(value);
    };

    const handleLicensedAgentValue = (value) => {
        updateLicensedAgent(value);
    };

    const handleServicingAgentValue = (value) => {
        updateServicingAgent(value);
    };

    const handleInternalAgency = (value) => {
        updateInternalAgencyName(value);
        updateLicensedAgent(null);
        updateServicingAgent(null);
        updateLicensedAgentOptions([]);
        updateServicingAgentOptions([]);
    };

    const getExternalAgencyOptions = useCallback(() => {
        const displayExternalAgencyValue = externalAgencyOptions.map((agency) => {
            return {
                code: agency.publicID,
                name: agency.name,
            }
        })
        return displayExternalAgencyValue
    }, [externalAgencyOptions])

    const getExternalAgencyValue = useCallback(() => {
        return externalAgencyPublicID;
    }, [externalAgencyPublicID]);

    const showModal = useCallback(() => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            size: 'lg',
            agencySearchName: internalAgencyName,
            updateExternalAgency,
            updateInternalAgencyName
        };
        return modalApi.showModal(
            <AgencyMatchPopupComponentForCL {...componentProps} />
        );
    }, [internalAgencyName, updateExternalAgency]);

    const showInternalAgencyPopup = useCallback(() => {
        showModal().then((res) => {
            if (res) {
                updateExternalAgency(res);
            }
        }).catch(() => {
            _.noop();
        });
    }, [showModal, updateExternalAgency]);

    //-------------------------------------------
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        agencyOfRecord: {
            // visible: showAgencyOfRecord,
        },
        iconSearchButton: {
            visible: !isReadOnly,
            onClick: showInternalAgencyPopup
        },
        licensedProducer: {
            availableValues: licensedAgentOptions,
            onValueChange: handleLicensedAgentValue,
            value: licensedAgentValue
        },
        servicingProducer: {
            availableValues: servicingAgentOptions,
            onValueChange: handleServicingAgentValue,
            value: servicingAgentValue
        },
        personalAutoAgency: {
            value: internalAgencyName,
            onValueChange: handleInternalAgency
        },
        internalAgencyInfoContainer: {
            visible: !isExternalUser,
        },
        externalAgencyList: {
            visible: isExternalUser,
            availableValues: (isExternalUser ? getExternalAgencyOptions() : null),
            onValueChange: handleExternalAgency,
            value:((isExternalUser && externalAgencyPublicID)? getExternalAgencyValue() : null),
            
        }
    };

    const readValue = (id, path) => {
    };

    const resolvers = {
        resolveCallbackMap: {
            // agencyPopupSearch: agencyPopupSearch
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValueChange={onAgencyChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

AgencyOfServiceComponentForCL.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onAgencyChange: PropTypes.func,
    showErrors: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    externalAgencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
    licensedAgentOptions: PropTypes.arrayOf(PropTypes.shape({})),
    servicingAgentOptions: PropTypes.arrayOf(PropTypes.shape({})),
    updateExternalAgency: PropTypes.func,
    externalAgencyName: PropTypes.string,
    externalAgencyPublicID: PropTypes.string,
    updateLicensedAgent: PropTypes.func,
    licensedAgentValue: PropTypes.shape({}),
    servicingAgentValue: PropTypes.shape({}),
    updateServicingAgent: PropTypes.func,
    updateLicensedAgentOptions: PropTypes.func,
    updateServicingAgentOptions: PropTypes.func
};

AgencyOfServiceComponentForCL.defaultProps = {
    model: {},
    onAgencyChange: undefined,
    showErrors: true,
    isReadOnly: false,
    onValidate: _.noop,
    externalAgencyOptions: [],
    licensedAgentOptions: [],
    servicingAgentOptions: [],
    updateExternalAgency: undefined,
    externalAgencyName: null,
    externalAgencyPublicID: null,
    updateLicensedAgent: undefined,
    licensedAgentValue: null,
    servicingAgentValue: null,
    updateServicingAgent: undefined,
    updateLicensedAgentOptions: undefined,
    updateServicingAgentOptions: undefined
};

export default AgencyOfServiceComponentForCL;
