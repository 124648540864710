import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imCPPCommonFormsService'), method, data, additionalHeaders);
}

export default class IMCPPCommonFormsService {
  
    /**
     * Invokes IMCPPCommonFormsHandler.getCPPCommonFormsWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getCPPCommonFormsWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getCPPCommonFormsWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMCPPCommonFormsHandler.getCPPCommonForms()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getCPPCommonForms(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getCPPCommonForms', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMCPPCommonFormsHandler.updateCPPCommonFormsCoverage()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} aaisCommonFormsDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCPPCommonFormsCoverage(jobNumber, sessionUUID, aaisCommonFormsDTO, authHeader = {}) {
        return processSubmission('updateCPPCommonFormsCoverage', [jobNumber, sessionUUID, aaisCommonFormsDTO], authHeader);
    }

    /**
     * Invokes IMCPPCommonFormsHandler.onCPPCommonFormsNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onCPPCommonFormsNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onCPPCommonFormsNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMCPPCommonFormsHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMCPPCommonFormsHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}