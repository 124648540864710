import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import metadata from './WorkCompDetails.metadata.json5';
import { generateFieldsVisible } from '../../../CUUnderlyingPage.util';

function WorkCompDetails(props) {
    const {
        currentRow,
        writeValue,
       
        onValidate,
        showErrors,
        isReadOnly,
    } = props;

 

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        ...generateFieldsVisible(currentRow),
       
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default WorkCompDetails;
