// export { default } from 'gw-capability-gateway-react/Policies/OpenActivities/OpenActivities';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ActivitiesService } from 'gw-capability-gateway';
import { WniActivitiesService } from 'wni-capability-gateway';
import OpenActivitiesComponent from '../../Components/OpenActivitiesComponent/OpenActivitiesComponent';

import metadata from './PoliciesOpenActivities.metadata.json5';

function PoliciesOpenActivities(props) {
    const {
        fromAccountLanding,
    } = props;

    const {
        policyDetailsData: {
            policyResponse,
            getPolicyDetails,
            getUpdateCountInfo
        }
    } = fromAccountLanding

    const {
        policyNumber,
        product: {
            productCode
        }
    } = policyResponse;

    const { authHeader } = useAuthentication();

    const getActivities = async () => {
        const getAvailableActivities = await WniActivitiesService.getActivitiesForPolicy(
            policyNumber,
            authHeader
        );
        return getAvailableActivities;
    }

    const createNewActivity = async (newActivityData) => {
        const addResponse = await ActivitiesService.createNewActivity(
            newActivityData,
            null,
            authHeader
        );

        return addResponse;
    }

    const getActivitiesTileUpdatedCount = () => {
        getUpdateCountInfo('OpenActivities');
    }

    const overrides = {
        policyDetailsActivitiesContainer: {
            getActivities: getActivities,
            createNewActivity: createNewActivity,
            getPolicyDetails: getPolicyDetails,
            activityEntity: 'policy',
            activityEntityId: policyNumber,
            getActivitiesTileUpdatedCount: getActivitiesTileUpdatedCount,
            productCode
        }
    }

    const resolvers = {
        resolveComponentMap: {
            openactivitiescomponent: OpenActivitiesComponent
        },
        resolveCallbackMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyResponse}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PoliciesOpenActivities.propTypes = {
};

export const SummaryComponent = PoliciesOpenActivities;

export default PoliciesOpenActivities;

