import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from 'gw-capability-gateway-react/ContactUnderwriter/ContactUnderwriter.metadata.json5';

import 'gw-capability-gateway-react/ContactUnderwriter/ContactUnderwriter.messages';

function ContactUnderwriter(props) {
    const { history, match, location } = props;

    const { interactionModel } = useDependencies('interactionModel');

    const {
        params: { policyNumber }
    } = match;

    const {
        state: {
            accountNumber,
        } = {},
    } = location;

    const goToPolicy = useCallback(() => {
        // Will jump to AO Policy Details page in AO-PE mode
        interactionModel.goToPage(null, history, 'policySummary', accountNumber, policyNumber);
    }, [interactionModel, history, accountNumber, policyNumber]);

    const resolvers = {
        resolveCallbackMap: {
            goToPolicy: goToPolicy
        }
    };

    return (
        <ViewModelForm uiProps={metadata.pageContent} callbackMap={resolvers.resolveCallbackMap} />
    );
}

ContactUnderwriter.propTypes = wizardProps;
export default withRouter(ContactUnderwriter);
