
export function termFormatter(termDTO) {
    let {
        name,
        // chosenTermValue,
    } = termDTO;
    if (name === 'Manual Premium') {
        name = "Premium";
    }
    return {
        name, 
        chosenTermValue: termDTO.chosenTermValue,
    };
}


export default {
    termFormatter,
};