import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './UnderwritingMessage.metadata.json5';
import messages from './UnderwritingMessage.messages.js';
import styles from './UnderwritingMessage.module.scss';

const UnderwritingMessage = (props) => {
    const {
        job,
        createIsExternal
    } = props;
    const translator = useTranslator();
    const [messageData, updateMessageData] = useState({});
    const [iconType, updateiconType] = useState('');

    useEffect(() => {
        if(!createIsExternal && job.isEditLocked) {
            updateiconType('warning');
        } else {
            updateiconType('info');
        }
    }, [])

    const getUnderwritingMessageContent = () => {
        if(!createIsExternal && job.isEditLocked) {
            return translator(messages.clLockedMessage);
        }
        return translator(messages[`cl${job.statusCode}Message`]);
    }

    const getUnderwritingMessageTitle = () => {
        if(job.isEditLocked) {
            return translator(messages.clLockedTitle);
        }
        if(job.status === 'Quoted') {
            return translator(messages[`cl${job.statusCode}Title`]);
        }
    }
   
    const overrides = {
        messageContainerId: {
            className: cx(styles.gwAlert, {
                [styles.gwAlertInfo]: iconType === 'info',
                [styles.gwAlertWarning]: iconType === 'warning'
            }),
        },
        infoIcon: {
            icon: iconType === 'info' ? 'gw-info' : 'gw-warning'
        },
        underwritingHeading: {
            content: getUnderwritingMessageTitle()
        },
        underwritingMessageContent: {
            content: getUnderwritingMessageContent()
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={messageData}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

UnderwritingMessage.propTypes = {
    notificationContent: PropTypes.shape({
        infoMessageTitle: PropTypes.string
    }).isRequired,
    onContinueTransaction: PropTypes.func.isRequired,
    onWithdrawTransaction: PropTypes.func.isRequired,
    transactionVisibleActions: PropTypes.shape({
        isContinueTransaction: PropTypes.bool.isRequired,
        isWithdrawTransaction: PropTypes.bool.isRequired
    }).isRequired,
    type: PropTypes.string
};
UnderwritingMessage.defaultProps = {
    type: 'info'
};
export default UnderwritingMessage;
