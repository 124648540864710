import _ from 'lodash';
import DebugUtil from './DebugUtil';

// Candicate names: ALL_MIGHTY_MESSAGE, ONE_MESSAGE_TO_RULE_THEM_ALL,
const DEFAULT_FRIENDLY_MESSAGE = 'We are running into a technical issue processing this request. Please contact our Customer Relationship Center at (800) 352-2772 for support in resolving this issue.';

// from JsonRpcErrorCode
const JSON_RPC_ERROR_CODE_LIST = [
    -32700, // PARSE_ERROR
    -32600, // INVALID_REQUEST
    -32601, // METHOD_NOT_FOUND
    -32602, // INVALID_PARAMS
    -32603, // INTERNAL_ERROR
    -32500, // APPLICATION_ERROR
    -32501, // AUTHENTICATION_ERROR
    -32400, // SYSTEM_ERROR
    -32300, // TRANSPORT_ERROR
];

/**
 * Prepend actualErrorMsg with user friendly error message
 * @param {string} actualErrorMsg
 * @returns {string}
 */
function prependWithFriendMessage(actualErrorMsg = '') {
    if (DebugUtil.isDebugEnabled()) {
        return `${DEFAULT_FRIENDLY_MESSAGE}\n\nDebug info for developer: \n${actualErrorMsg}`;
    }
    return DEFAULT_FRIENDLY_MESSAGE;
}

/**
 * Get formatted dto validation error message
 * @param {object} serverError
 * @param {string} defaultErrorMsg
 * @returns {object} formatted dto validation error message
 */
function getFormattedDtoErrorMessages(serverError, defaultErrorMsg) {
    const errors = _.get(serverError, 'baseError.data.appData.data');
    if (_.isEmpty(errors)) {
        return defaultErrorMsg;
    }
    // const infos = errors.map((e) => {
    //     return (
    //         <div>{`${e.message} : ${e.path}`}</div>
    //     );
    // });
    // return (
    //     <div id="errorPopupModal" className="modal-content">
    //         <div className="modal-body">
    //             {infos}
    //         </div>
    //     </div>
    // );
    const retval = errors.map((e) => `${e.message}: ${e.path}`).join('\n');
    return retval;
}

/**
 * Check whether the error code comes from JsonRpcErrorCode
 * @param {number} errorCode
 * @returns {boolean}
 */
function isJsonRpcErrorCode(errorCode) {
    if (_.isNil(errorCode)) {
        return false;
    }
    return JSON_RPC_ERROR_CODE_LIST.includes(errorCode);
}

// /**
//  * Get server error message
//  * @param {object} serverError
//  * @param {string} defaultErrorMsg
//  * @returns {string} simple server error message
//  */
// function getSeverErrorMessage(serverError, defaultErrorMsg) {
//     const errorMsg = _.get(serverError, 'baseError.error.message');
//     return errorMsg || defaultErrorMsg;
// }

/**
 * Try to retrieve friendly message based on the nature of the error:
 * 1) Invalid Session
 * 2) Service Timeout
 *
 * Sample Server Error 1: Invalid Session
 *
 * {
 *     "baseError": {
 *         "message": "ErrorCode: -32500-APPLICATION_ERROR ErrorMessage: Invalid session",
 *         "data": {
 *             "appErrorCode": 600,
 *             "appError": "GW_SECURITY_ERROR"
 *         },
 *         "code": -32500
 *     },
 *     "errorCode": "GW_ERROR",
 *     "appErrorCode": 101,
 *     "isTimeout": false,
 *     "isUnauthenticated": false,
 *     "gwInfo": {
 *         "serviceEndPoint": "https://localhost:3000/gateway/submission",
 *         "method": "getSubmissionSummary",
 *         "params": [
 *             "0000000000"
 *         ]
 *     }
 * }
 *
 * Sample Server Error 2: Service Timeout
 * {
 *     "baseError": {},
 *     "errorCode": "GW_ERROR",
 *     "appErrorCode": 101,
 *     "isTimeout": false,
 *     "isUnauthenticated": false,
 *     "gwInfo": {
 *         "serviceEndPoint": "https://localhost:3000/gateway/submission",
 *         "method": "findJobByJobNumber",
 *         "params": [
 *             "0000001192"
 *         ]
 *     }
 * }
 *
 * References:
 * 1) gw-portals-auth-js/AuthErrors
 * 2) ApplicationErrorCode
 * 3) JsonRpcErrorCode
 * 4) TransportService.send()
 * 5) DefaultSessionPlugin.validateAndRefreshSession()
 *
 * Refactoring Note:
 * 1) Consider a more robust way to check the error type;
 * 2) Investigate the root cause
 * @param {object | string} serverError
 * @param {string} extractedMessage
 * @returns {string} could be null if the serverError is deemed not ready for a friendly message
 */
function retrievFriendlyMessage(serverError, extractedMessage) {
    if (!serverError) {
        return null;
    }

    // from TransportService.send()
    const {
        errorCode,
        appErrorCode,
        baseError: {
            baseError: baseMessage = '',
            data: baseData,
            code: baseCode,
        } = {},
    } = serverError;
    let retval = extractedMessage;
    if (_.isEmpty(extractedMessage)) {
        if (errorCode === 'GW_ERROR' && appErrorCode === 101) {
            // This typically means nothing useful is caught by TransportService.send()
            // retval = DEFAULT_FRIENDLY_MESSAGE;
            retval = prependWithFriendMessage(baseMessage || '');
        }
    // } else if (baseCode === -32500 && extractedMessage.endsWith('Invalid session')) {
    // Need a better way to check Invalid session error
    } else if (isJsonRpcErrorCode(baseCode)) {
        // retval = DEFAULT_FRIENDLY_MESSAGE;
        retval = prependWithFriendMessage(extractedMessage);
    }
    return retval;
}

/**
 * Try to get proper error message to display
 *
 * Refactoring Notice: this is a mess, and things thrown from BaseTransportService
 * and gw-portals-auth-js/* could have an overhaul.
 * ref: JsonRpcErrorCode
 * @param {object} serverError
 * @param {string} defaultErrorMsg
 * @returns {string}
 */
function getErrorMessage(serverError, defaultErrorMsg = DEFAULT_FRIENDLY_MESSAGE) {
    let retval = getFormattedDtoErrorMessages(serverError);
    if (_.isEmpty(retval)) {
        retval = _.get(serverError, 'baseError.error.message');
    }
    if (_.isEmpty(retval)) {
        retval = _.get(serverError, 'baseError.message');
    }
    if (_.isEmpty(retval)) {
        retval = _.get(serverError, 'message');
    }
    // if (_.isEmpty(retval)) {
    //     retval = _.get(serverError, 'message');
    // }
    // ==============To be refactored BEGINS============================
    // if (!_.isEmpty(retval)
    //     && retval.endsWith('Invalid session') // from DefaultSessionPlugin
    //     // && retval.indexOf('-32500') > -1 // from JsonRpcErrorCode
    // ) {
    //     // retval = 'Unable to process the request this time, please try again. If the issue persists, contact customer support.';
    //     retval = 'We are running into a technical issue processing this request. Please contact our Customer Relationship Center at (800) 352-2772 for support in resolving this issue.';
    // }
    retval = retrievFriendlyMessage(serverError, retval);
    // ==============To be refactored ENDS============================

    if (_.isEmpty(retval)) {
        try {
            retval = JSON.stringify(serverError);
        } catch (e) {
            // console.error(e);
        }
    }
    if (_.isEmpty(retval)) {
        retval = defaultErrorMsg;
    }
    return retval;
}

export default {
    getErrorMessage,
    // DEFAULT_FRIENDLY_MESSAGE,
    prependWithFriendMessage
};
