import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { CRLineCoveragesService } from 'wni-capability-quoteandbind-cr';
import React, { useEffect, useCallback } from 'react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';
import { WniInputText } from 'wni-common-base-components';
import { SearchClassCodeDescriptionPopup } from 'wni-capability-common-react';
import messages from './DetailsCard.messages';
import CRCoverageUtil from '../../../../util/CRCoverageUtil';
import CRDetails from '../../../../components/CRDetails';

const DetailsCard = (props) => {
    const modalApi = useWniModal();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        lineCoverages,
        setLineCoverages,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        toggleWizardPageDisplay,
    } = props;

    const translator = useTranslator();

    const validationID = 'detailsCard';
    const {
        jobID,
        sessionUUID,
        baseData: {
            accountHolder: {
                industryCode_Ext: naicsCode,
                industryDescription_Ext: naicsDescription,
            }
        }
    } = submissionVM.value;


    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    const {
        details,
        visibleOfCommercialCrime,
        visibleOfCommercialFidelity
    } = lineCoverages

    useEffect(() => {
        if (toggleWizardPageDisplay) {
            toggleWizardPageDisplay([
                {[WizardConstants.stepId]: 'CRCommercialCrimePage', [WizardConstants.stepVisible]: visibleOfCommercialCrime},
                {[WizardConstants.stepId]: 'CRCommercialFidelityPage', [WizardConstants.stepVisible]: visibleOfCommercialFidelity}
            ]);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleOfCommercialCrime, visibleOfCommercialFidelity])

    const showSearchModal = useCallback((tableData) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            tableData,
            isShowClassDescription: true,
            classDescriptionPath: 'ShowCR7ClassDescription'
        };
        return modalApi.showModal(
            <SearchClassCodeDescriptionPopup {...componentProps} />
        );
    }, [modalApi]);
    
    const writeSearchedDetailsValue = async(fieldItem, seletedRow) => {
        const { propertyName } = fieldItem;
        setLoadingMask(true);
        const newLineClauses = _.clone(lineCoverages);
        const updatedDisplayables = await CRLineCoveragesService.getUpdatedCrimeDetails(jobID, sessionUUID, propertyName, seletedRow, authHeader);
        _.set(newLineClauses, 'details', updatedDisplayables);
        setLineCoverages(newLineClauses);
        setLoadingMask(false);
    }
    
    const handleSearch = async(fieldItem = {}) => {
        const { propertyName } = fieldItem;   
        const tableData = await  withLoadingMask(() => CRLineCoveragesService.fetchCrimeDetailsClassCodeTable(jobID, sessionUUID, propertyName, authHeader));
        showSearchModal(tableData)
            .then((seletedRow) => {
                const ClassCodeDisplayable = details.find(elt => elt.propertyName === propertyName);
                writeSearchedDetailsValue(ClassCodeDisplayable, seletedRow);
            }).catch(() => {
                _.noop();
            })
    };

    const changeDetails = (displayable) => {
        const newLineClauses = _.clone(lineCoverages)
        const {
            propertyName
        } = displayable
        const index = details.findIndex((d) => _.get(d, 'propertyName') === propertyName)
        _.set(newLineClauses, `details[${index}]`, displayable);
        setLineCoverages(newLineClauses);
        setIsEditing(false);
        return newLineClauses;
    }

    const updateVMToServer = async (newCoveragePartClauses, setEditingFunc) => {
        const lineCoveragesToUpdate = CRCoverageUtil.generateUpdatedCRLineClausesDTO(newCoveragePartClauses);
        const lineCoveragessFromServer = await withLoadingMask(() => CRLineCoveragesService.updateLineCoverages(
            jobID,
            sessionUUID,
            lineCoveragesToUpdate,
            authHeader
        ))
        setEditingFunc(false)
        setLineCoverages(lineCoveragessFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }
    const updateNewCoveragePartClausesToServer = async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }

    const changeDetailsAndSync = (value) => {
            const newCoveragePartClauses = changeDetails(value);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        }


    return (
        <AccordionCard
            id="DetailsCard"
            initialExpanded
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={translator(messages.Details)}
            errorState={showErrors && !isComponentValid}
        >
            <CRDetails
                details={details}
                changeDetailsAndSync={changeDetailsAndSync}
                onValidate={onValidate}
                showErrors={showErrors}
                isEditable={isEditable}
                onSearch={handleSearch}
            />
            <WniInputText
                id='naicsCode'
                labelPosition='left'
                label={translator(messages.NAICSCode)}
                value={`${naicsCode} - ${naicsDescription}`}
                readOnly
            />
        </AccordionCard>
    );
};

export default DetailsCard;
