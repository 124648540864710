import React, {
    useContext,
    useCallback,
    useMemo,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { RTVehicleService } from 'wni-capability-quoteandbind-rt';
import metadata from './RTVehicleDetails.metadata.json5';
import { OTHER } from '../config/Vehicle.static';
import {
    getVehicleOrTrailerLabel,
    getAvailableYears,
    getAvailableList,
    isFieldVisible,
    getValuationMethodOptions,
    tooltipForcoverageAmount,
    tooltipForVehicalType
} from '../util/Vehicle.util'

function VehicleDetails(props) {
    const {
        value: vehicleVM,
        onValueChange,
        onValidate,
        showErrors,
        syncWizardData,
        syncWizardDataSnapshot,
        generateValidationIssues,
        isReadOnly,
        isRequiredForIssuance,
        extendProps: {
            jobID,
            sessionUUID,
            baseData,
            authHeader
        }
    } = props;
    const {
        publicID,
        itemToBeInsured,
        year,
        manufacturer,
        model,
        manufacturerOptions = [],
        modelOptions = [],
        priceDigest = [],
        priceDigestsDetails: currentPriceDigest = {},
        lossSettlementValMethod,
        msrp,
        currentMarketValue,
        coverageAmount,
        isCoverageAmountWarning
    } = vehicleVM.value;
    // // TODO get price digest first data, waiting for BA confirm
    // const currentPriceDigest = priceDigest[0] || {};
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [coverageValue, updateCoverageValue] = useState(coverageAmount);

    useEffect(() => {
        updateCoverageValue(coverageAmount);
    }, [coverageAmount]);

    const getRequestData = (value, path) => {
        const garagedAtVM = vehicleVM.garagedAt_Ext;
        const requestData = {
            ...vehicleVM.value,
            [path]: value,
            isUpdate: false
        };
        if(!_.get(garagedAtVM, 'aspects.subtreeValid')) {
            return {
                ...requestData,
                garagedAt_Ext: null
            };
        }
        return requestData;
    };
    const resultData = (data) => {
        return {
            ...data,
            garagedLocation: _.get(vehicleVM.value, 'garagedLocation'),
            garagedAt_Ext: _.get(vehicleVM.value, 'garagedAt_Ext')
        }
    };
    const vinLookup = async (event, valueObject) => {
        const { beforeValue, value } = valueObject;
        if(value === beforeValue) {
            return false;
        };
        setLoadingMask(true);
        const requestData = getRequestData(value, 'vin');
        const res = await RTVehicleService.postOnChangeAction(jobID, sessionUUID, requestData, 'vin', authHeader);
        _.set(vehicleVM, 'value', resultData(res.rtvehicle));
        generateValidationIssues(res.errorsAndWarnings)
        syncWizardDataSnapshot(vehicleVM);
        setLoadingMask(false);
    };

    const writeValue = async(value, path) => {
        onValueChange(value, path);
        if(!value) {
            return false;
        }
        switch(path) {
            case 'year':
                if(value === year) {
                    break;
                };
                setLoadingMask(true);
                const requestYearData = getRequestData(value, path);
                const res = await RTVehicleService.buildOptionsRange(jobID, sessionUUID, requestYearData, path, authHeader);
                _.set(vehicleVM, 'value', res);
                syncWizardData(vehicleVM);
                setLoadingMask(false);
                break;
            case 'manufacturer':
                if(value === manufacturer) {
                    break;
                };
                setLoadingMask(true);
                const requestManData = getRequestData(value, path);
                const resforManufacturer = await RTVehicleService.buildOptionsRange(jobID, sessionUUID, requestManData, path, authHeader);
                _.set(vehicleVM, 'value', resforManufacturer);
                syncWizardData(vehicleVM);
                setLoadingMask(false);
                break;
            case 'model':
                if(value === model) {
                    break;
                };
                setLoadingMask(true);
                const requestData = getRequestData(value, path);
                const resforModel = await RTVehicleService.postOnChangeAction(jobID, sessionUUID, requestData, path, authHeader);
                _.set(vehicleVM, 'value', resforModel.rtvehicle);
                // generateValidationIssues(resforModel.errorsAndWarnings)
                syncWizardDataSnapshot(vehicleVM);
                setLoadingMask(false);
                break;
            case 'isPhysicalDamageRequired':
                if(value) {
                    _.set(vehicleVM.value, 'currentMarketValue', currentPriceDigest.currentMarketValue);
                    syncWizardData(vehicleVM);
                }
                break;
            case 'lossSettlementValMethod':
                if(value === 'replacement_cost') {
                    _.set(vehicleVM.value, 'coverageAmount', currentPriceDigest.msrp);
                    updateCoverageValue(currentPriceDigest.msrp)
                    syncWizardData(vehicleVM)
                }
                if(value === 'agreed_value') {
                    _.set(vehicleVM.value, 'coverageAmount', currentPriceDigest.currentMarketValue);
                    updateCoverageValue(currentPriceDigest.currentMarketValue)
                    syncWizardData(vehicleVM)
                };
                break;
            default:
                break;
        }
    };


    const getVisibleField = (fieldPath) => {
        return isFieldVisible(vehicleVM, fieldPath);
    };
    const islossSettlementDisabled = lossSettlementValMethod === 'stated_value';
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        vehicleType: {
            tooltip: {
                text: tooltipForVehicalType(translator, itemToBeInsured)
            },
            label: getVehicleOrTrailerLabel('vehicleType', itemToBeInsured),
            disabled: !_.get(vehicleVM.value, 'isEditabled')
        },
        vin: {
            visible: getVisibleField('vin'),
            onBlur: vinLookup
        },
        year: {
            availableValues: getAvailableYears()
        },
        manufacturer:{
            availableValues: getAvailableList(manufacturerOptions)
        },
        otherManufacturer: {
            visible: getVisibleField('otherManufacturer')
        },
        model:{
            availableValues: getAvailableList(modelOptions),
            visible: getVisibleField('model')
        },
        otherModel: {
            visible: getVisibleField('otherModel') && itemToBeInsured !== "trailer"
        },
        trailerotherModel: {
            visible: getVisibleField('otherModel') && itemToBeInsured === "trailer"
        },
        identificationNumber: {
            visible: getVisibleField('identificationNumber')
        },
        vehicleDetailContent: {
            visible: !!_.get(vehicleVM, 'value.model')
        },
        registrationStateExplain: {
            visible: getVisibleField('registrationStateExplain')
        },
        engineCC: {
            visible: getVisibleField('engineCC')
        },
        currentMarketValue: {
            visible: getVisibleField('currentMarketValue'),
            disabled: _.get(currentPriceDigest, 'currentMarketValue') || !_.get(vehicleVM.value, 'isEditabled')
        },
        lossSettlementValMethod: {
            availableValues: getValuationMethodOptions(vehicleVM, translator, islossSettlementDisabled),
            disabled: islossSettlementDisabled,
            visible: getVisibleField('lossSettlementValMethod')
        },
        coverageAmount: {
            tooltip: {
                text: tooltipForcoverageAmount(translator)
            },
            visible: getVisibleField('coverageAmount'),
            onValueChange: (value) => {
                updateCoverageValue(value)
            },
            onBlur: () => {
                writeValue(coverageValue, 'coverageAmount');
            },
            value: coverageValue
        },
        statedValue: {
            visible: getVisibleField('statedValue'),
        },
        coverageAmountWarningMsg: {
            visible: isCoverageAmountWarning
        }
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, vehicleVM, fieldId, fieldPath, overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default VehicleDetails;
