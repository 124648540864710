

import React from 'react';
import { IMBuildersRiskReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMBuildersRiskChangeReadOnlyPage = (props) => {
    return <IMBuildersRiskReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMBuildersRiskChangeReadOnlyPage
