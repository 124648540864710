import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniSxsQuote'), method, data, additionalHeaders);
}

export default class WniSxsQuoteService {
    /**
     * Invokes WniSideBySideQuoteHandler.saveAndSxsQuote()
     * is disabled.
     * @param {object} data the QuoteDataDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static saveAndSxsQuote(data, authHeader = {}) {
        return processSubmission('saveAndSideBySideQuote', [data], authHeader);
    }

    /*
     * Invokes WniSideBySideQuoteHandler.processSideBySideQuote()
     * @param {string}} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static sxsQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('processSideBySideQuote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.finishSideBySideQuote()
     * @param {string} quoteID current Quote ID
     * @param {string} sxsPeriodPublicID the SideBySide Period Public ID
     * @param {string} sessionID current session ID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static finishSxsQuote(quoteID, sxsPeriodPublicID, sessionID, authHeader = {}) {
        return processSubmission('finishSideBySideQuote', [quoteID, sxsPeriodPublicID, sessionID], authHeader);
    }

    /**
     * Invokes WniSideBySideQuoteHandler.submitSideBySideQuote()
     * is disabled.
     * @param {string} quoteID current Quote ID
     * @param {string} sxsPeriodPublicID the SideBySide Period Public ID
     * @param {string} sessionID current session ID
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call
     */
    static submitSxsQuote(quoteID, sxsPeriodPublicID, sessionID, authHeader = {}) {
        return processSubmission('submitSideBySideQuote', [quoteID, sxsPeriodPublicID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.retrieveSideBySideData()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveSxsData(quoteID, sessionID, authHeader = {}) {
        return processSubmission('retrieveSideBySideData', [quoteID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.retrieveSideBySideDataWithActiveQuotes()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveSxsDataWithActiveQuotes(quoteID, sessionID, authHeader = {}) {
        return processSubmission('retrieveSideBySideDataWithActiveQuotes', [quoteID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.retrieveBasicSideBySideData()
     * 
     * Currently being used in PAQuotePage.onUpdateCoverage()
     * 
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveBasicSxsData(quoteID, sessionID, authHeader = {}) {
        return processSubmission('retrieveBasicSideBySideData', [quoteID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.retrieveSideBySideDataWithCov()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveSxsDataWithCov(quoteID, sessionID, authHeader = {}) {
        return processSubmission('retrieveSideBySideDataWithCov', [quoteID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.createSideBySidePair()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static createSxsPair(quoteID, sessionID, authHeader = {}) {
        return processSubmission('createSideBySidePair', [quoteID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.deleteSideBySidePair()
     * @param {string} quoteID
     * @param {string} deletePeriodPublicID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static deleteSxsPair(quoteID, deletePeriodPublicID, sessionID, authHeader = {}) {
        return processSubmission('deleteSideBySidePair', [quoteID, deletePeriodPublicID, sessionID], authHeader);
    }

    /**
     * Invokes WniSideBySideQuoteHandler.updateSideBySideCoverages()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} periodPublicID
     * @param {object} lobCoveragesDTO
     * @param {object} authHeader
     * @returns {Promise}
     */
    static updateSxsCoverages(quoteID, sessionUUID,
        periodPublicID, lobCoveragesDTO, authHeader = {}) {
        return processSubmission('updateSideBySideCoverages',
            [quoteID, sessionUUID, periodPublicID, lobCoveragesDTO], authHeader);
    }

    /**
     * Invokes WniSideBySideQuoteHandler.retrieveInstallmentPlans()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveInstallmentPlans(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('retrieveInstallmentPlans',
            [quoteID, sessionUUID], authHeader);
    }


    /**
     * Invokes WniSideBySideQuoteHandler.requestQuoteAll()
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static quoteAll(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('requestQuoteAll',
            [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.retrieveRiskReservation()
     * @param {string} quoteID
     * @param {string} periodPublicID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static retrieveRiskReservation(quoteID, periodPublicID, sessionID, authHeader = {}) {
        return processSubmission('retrieveRiskReservation', [quoteID, periodPublicID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.updatePaymentPlan()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {string} paymentPlanID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static updatePaymentPlan(quoteID, sessionID, paymentPlanID, authHeader = {}) {
        return processSubmission('updatePaymentPlan', [quoteID, sessionID, paymentPlanID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.getReadyForIssueErrorsAndWarnings()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static getReadyForIssueErrorsAndWarnings(quoteID, sessionID, authHeader = {}) {
        return processSubmission('getReadyForIssueErrorsAndWarnings', [quoteID, sessionID], authHeader);
    }

    /**
     * Invoke WniSideBySideQuoteHandler.checkPreIssuanceUWIssue()
     * @param {string} quoteID
     * @param {string} sessionID
     * @param {string} periodPublicID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static checkPreIssuanceUWIssue(quoteID, sessionID, periodPublicID, authHeader = {}) {
        return processSubmission('checkPreIssuanceUWIssue', [quoteID, sessionID, periodPublicID], authHeader);
    }
}
