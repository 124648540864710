import React from 'react';
import { WCPayAsYouGoComponent } from "wni-capability-quoteandbind-wc-react";



function GLPayAsYouGoComponent(props) {
    
    return <WCPayAsYouGoComponent {...props}/>

}

export default GLPayAsYouGoComponent;
