import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import AddressInputComponent from '../../components/AddressInputComponent/AddressInputComponent';
import metadata from './VehicleGaragesComponent.metadata.json5';
import styles from './VehicleGaragesComponent.module.scss';
import messages from './VehicleGaragesComponent.messages';

/**
 * Component contains vehicle garaged at
 * @param {object} props
 * @returns {object} React Component
 */
const VehicleGaragesComponent = (props) => {
    const {
        model: vehicleVM,
        onValueChange,
        showErrors,
        readOnly,
        garagedAtOptions,
        showGaragedAtErrors,
        updateShowGaragedAtErrors,
        onValidate
    } = props;

    const translator = useTranslator();
    useEffect(() => {
        garagedAtOptions.forEach((option) => {
            // eslint-disable-next-line no-param-reassign
            option.tempCode = option.publicID;
        });
    }, [garagedAtOptions]);


    const writeValue = useCallback((value, path) => {
        onValueChange(value, path);
    }, [onValueChange]);

    const handleGaragedAtChange = useCallback((code) => {
        if (readOnly) {
            return;
        }
        if (code === 'new') {
            writeValue({
                tempCode: 'new',
                displayName: translator(messages.paVehicleGaragedAtNew),
                country: 'US'
            }, 'garagedAt_Ext');
            // not show errors when new garaged at
            updateShowGaragedAtErrors(false);
        } else {
            writeValue(garagedAtOptions.find((option) => {
                return option.tempCode === code;
            }), 'garagedAt_Ext');
        }
    }, [readOnly, writeValue, translator, updateShowGaragedAtErrors, garagedAtOptions]);

    const getGaragedAtOptions = useCallback(() => {
        const options = garagedAtOptions.map((location) => {
            return {
                code: location.tempCode,
                name: location.displayName
            };
        });
        options.push({
            code: 'new',
            name: translator(messages.paVehicleGaragedAtNew),
            country: 'US'
        });
        return options;
    }, [garagedAtOptions]);

    const getGaragedAtValue = useCallback(() => {
        if (vehicleVM.value.garagedAt_Ext) {
            if (vehicleVM.value.garagedAt_Ext.tempCode) {
                return vehicleVM.value.garagedAt_Ext.tempCode;
            }
            return vehicleVM.value.garagedAt_Ext.publicID;
        }
        return undefined;
    }, [vehicleVM]);

    const garagedAt = getGaragedAtValue();

    //----------------------------------
    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        vehicleGaragedAt: {
            visible: !readOnly,
            onValueChange: handleGaragedAtChange,
            availableValues: getGaragedAtOptions(),
            value: garagedAt
        },
        garagedAtAddressContainer: {
            visible: !_.isNil(garagedAt),
            model: vehicleVM,
            dataPath: 'garagedAt_Ext',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true,
                pobox: true
            },
            showErrors: showErrors && showGaragedAtErrors,
            onValidate
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            addressinputcomponent: AddressInputComponent
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vehicleVM,
            id,
            path,
            overrideProps
        );
    };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
};

VehicleGaragesComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool,
    garagedAtOptions: PropTypes.arrayOf(PropTypes.shape({})),
    showGaragedAtErrors: PropTypes.bool,
    updateShowGaragedAtErrors: PropTypes.func
};

VehicleGaragesComponent.defaultProps = {
    model: {},
    onValueChange: undefined,
    showErrors: false,
    readOnly: false,
    garagedAtOptions: [],
    showGaragedAtErrors: false,
    updateShowGaragedAtErrors: _.noop()
};

export default VehicleGaragesComponent;
