import _ from 'lodash';
import { WniAccordionValidationUtil, ConfigUtil, WniDateUtil } from 'wni-portals-util-js';
import WALOperatorConstants from '../config/WALOperatorConstants';
import messages from '../WALOperatorsPage.messages';

function onDateOfBirthCell(item) {
    if (!item.dateOfBirth) {
        return '';
    }
    return WniDateUtil.formatDateWithPattern(item.dateOfBirth);
}

function onRelationshipCell(item, translator) {
    const relationship = _.get(item, 'relationship');
    const isPNI = _.get(item, 'isPNI');
    if (isPNI && relationship === 'Insured_Ext') {
        return `Primary ${translator({ id: `typekey.Relationship.${relationship}` })}`
    }
    if (relationship) {
        return translator({ id: `typekey.Relationship.${relationship}` }) || '';
    }
    return '';
}

function onExcludedCell(item) {
    const val = _.get(item, 'doesOperate');
    if (val === false) {
        return 'No';
    }
    if (val === true) {
        return 'Yes';
    }
    return '';
}

function onNameCell(item) {
    const lastName = _.get(item, 'lastName') || '';
    const firstName = _.get(item, 'firstName') || '';

    return `${firstName} ${lastName}`;
}

function getAccordionOverridesForOperator(operatorVM, validationIssues = []) {
    const cardFieldsMap = WALOperatorConstants.sectionFieldsMap;
    const retval = WniAccordionValidationUtil.getInvalidAccordionCardsOverrides(cardFieldsMap, operatorVM);
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(operatorVM, 'value.fixedId');
    });
    const errorFields = _.map(errorIssues, (error) => {
        return _.upperFirst(_.get(error, 'relatedEntity.fieldName'));
    });
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        const invalidFields = _.filter(fields, (field) => {
            const idx = errorFields.indexOf(_.upperFirst(field));
            return idx >= 0;
        });
        return invalidFields.length > 0;
    });
    ids.forEach((id) => {
        if (invalidAccordions.indexOf(id) >= 0) {
            _.set(retval, `${id}.errorState`, true);
        }
    });
    return retval;
}

function setDefaultOperatorObj(countryCode, lineName) {
    let defaultQuestionSets = [];
    if (lineName === 'WALWatercraftLine') {
        defaultQuestionSets = [
            {
                "answers": {
                    "explainForhasLicenseSuspended_Ext": null,
                    "hasLicenseSuspended_Ext": "false",
                    "explainForIsDriverRequiredSubmit_Ext": null,
                    "HasCompletedSafetyCoursesNoDependent_Ext": null,
                    "isDriverRequiredSubmit_Ext": "false",
                    "willDriverExclusion_Ext": "false"
                },
                "code": "WALDriverUWQuestion_Ext"
            }
        ];
    }
    if (lineName === 'RTLine') {
        defaultQuestionSets = [
            {
                "answers": {
                    "explainForhasLicenseSuspendedRT_Ext": null,
                    "hasLicenseSuspendedRT_Ext": "false",
                    "explainForIsDriverRequiredSubmitRT_Ext": null,
                    "HasCompletedSafetyCoursesRTNoDependent_Ext": null,
                    "isDriverRequiredSubmitRT_Ext": "false",
                    "willDriverExclusionRT_Ext": "false",
                    "operatorMotorcycleEndorsementDurationNoDependent_Ext": null,
                    "operatorhaveMotorcycleEndorsementNoDependent_Ext": "no_Ext",
                    "personOperateMotorcycleNoDependent_Ext": "false"
                },
                "code": "RTDriverQuestionSet_Ext"
            }
        ];
    }
    const res = {
        rowIdPath: ConfigUtil.getUuid()
    };
    _.unset(res, 'publicID');
    _.set(res, 'driverQuestionSets', defaultQuestionSets);

    // typeKey filterByCategory
    _.set(res, 'policyCountry', countryCode);
    _.set(res, 'lineName', lineName);
    return res;
}

function removeEmpty(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        if (val === '' || val === {} || val === [] || val === null) {
            delete res[key];
        }
    });
    return res;
}

function excludePNISelectionsAndMessage(operators, selection) {
    const selectionOperators = _.filter(operators, (v) => {
        return _.includes(selection, v.publicID);
    });
    const PNIOperator = _.find(selectionOperators, (v) => {
        return _.get(v, 'isPNI');
    });
    let resSelection;
    let removeMessage;
    if (_.isEmpty(PNIOperator)) {
        resSelection =  selection;
        removeMessage = messages.removeOperatorDescription
    } else {
        resSelection = _.remove(selection, (v) => {
            return PNIOperator.publicID === v
        });
        removeMessage = messages.removeIncludePNIOperatorDescription
    }
    return {
        selection: resSelection,
        removeMessage
    };
}

function getAge(operatorVM, effectiveDate) {
    const driverDateOfBirth = _.get(operatorVM, 'dateOfBirth.value') || {};
    const strAge = `${driverDateOfBirth.year}-${driverDateOfBirth.month}-${driverDateOfBirth.day}`;
    const birArr = strAge.split('-');
    const birYear = Number(birArr[0]);
    const birMonth = Number(birArr[1]);
    const birDay = Number(birArr[2]);

    const nowYear = effectiveDate.year;
    const nowMonth = effectiveDate.month + 1;
    const nowDay = effectiveDate.day;
    let returnAge;

    if (birArr == null) {
        return false;
    }
    const d = new Date(birYear, birMonth, birDay);
    if (d.getFullYear() === birYear && d.getMonth() === birMonth && d.getDate() === birDay) {
        if (nowYear === birYear) {
            returnAge = 0;
        } else {
            const ageDiff = nowYear - birYear;
            if (ageDiff > 0) {
                if (nowMonth === birMonth + 1) {
                    const dayDiff = nowDay - birDay;
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    const monthDiff = nowMonth - birMonth;
                    if (monthDiff <= 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                }
            }
        }
        return returnAge;
    }
    return false;
}

function isChild(operatorVM, baseState, effectiveDate) {
    if (getAge(operatorVM, effectiveDate) === false) {
        return null;
    }
    if (baseState === 'WI' || baseState === 'MN' || baseState === 'AK') {
        if (getAge(operatorVM, effectiveDate) < 16) {
            return true;
        }
    } else if (baseState === 'SD' || baseState === 'IA') {
        if (getAge(operatorVM, effectiveDate) < 14) {
            return true;
        }
    }
    return false;
}

function hasExcluded(operators) {
    const excludedOperators = _.filter(operators, (operator) => {
        return operator.excluded === 'Excluded'
    })
    if (excludedOperators.length > 0) {
        return true;
    }
    return false;
}

function contactToOperatorMap() {
    return {
        publicID: 'contactDenormPublicId',
        displayName: 'displayName',
        firstName: 'firstName',
        middleName: 'middleName',
        lastName: 'lastName',
        maritalStatus: 'maritalStatus',
        gender: 'gender',
        dateOfBirth: 'dateOfBirth',
        primaryPhoneType: 'primaryPhoneType',
        cellNumber: 'cellNumber',
        workNumber: 'workNumber',
        homeNumber: 'homeNumber',
        emailAddress1: 'email',
        licenseNumber: 'licenseNumber',
        licenseState: 'licenseState',
    }
}

function fillExistingContact(contact, onValueChange) {
    const fieldsMap = contactToOperatorMap();
    _.each(fieldsMap, (operatorKey, contactKey) => {
        onValueChange(contact[contactKey], operatorKey);
    });
}

function hasInvalidOperator(operatorsVM) {
    const retval = operatorsVM.children.some((item) => {
        return !item.aspects.valid || !item.aspects.subtreeValid;
    });
    return retval;
}


function hasIssuanceInvalidFields(operatorVM = {}) {
    /**
     * primaryPhoneType
     * workNumber
     * homeNumber
     * cellNumber
     * licenseStatus
     * licenseNumber
     * licenseState
     * ageFirstLicensed
     */
    const operator = operatorVM.value || {};
    const {
        // primaryPhoneType,
        // cellNumber,
        // workNumber,
        // homeNumber,
        isLicensedDriver,
        unLicensedDriverReason,
        licenseStatus,
        licenseState,
        licenseNumber
    } = operator;
    // if (_.isEmpty(primaryPhoneType)) {
    //     return true;
    // }
    // if (primaryPhoneType === 'mobile' && _.isNil(cellNumber)) {
    //     return true;
    // }
    // if (primaryPhoneType === 'work' && _.isNil(workNumber)) {
    //     return true;
    // }
    // if (primaryPhoneType === 'home' && _.isNil(homeNumber)) {
    //     return true;
    // }
    if (isLicensedDriver) {
        if (_.isEmpty(licenseStatus) || _.isEmpty(licenseState) || _.isEmpty(licenseNumber)) {
            return true;
        }
    }
    if (!isLicensedDriver
        && (unLicensedDriverReason === 'permitonly'
            || unLicensedDriverReason === 'idOnly')) {
        if (_.isEmpty(licenseStatus) || _.isEmpty(licenseState) || _.isEmpty(licenseNumber)) {
            return true;
        }
    }
    return false;
}

function getQuestionMapper(translator, customMessages, classNameMap) {
    return (metaDataContent) => {
        if (metaDataContent.id === 'hasLicenseSuspended_Ext') {
            _.set(metaDataContent, 'componentProps.label', translator(customMessages.hasLicenseSuspended));
        }
        if (metaDataContent.id === 'HasCompletedSafetyCoursesNoDependent_Ext') {
            _.set(metaDataContent, 'component', "WniToggle");
            _.set(metaDataContent, 'componentProps.tooltip', {
                "text": {
                    "id": "wni.quoteandbind.ho.householdinfo.Household members between 12-18 years old are required to complete a safety course. Attach certification to the submission.",
                    "defaultMessage": "Household members between 12-18 years old are required to complete a safety course. Attach certification to the submission."
                }
            });
        }
        if (metaDataContent.id === 'personOperateMotorcycleNoDependent_Ext'
            || metaDataContent.id === 'operatorhaveMotorcycleEndorsementNoDependent_Ext'
            || metaDataContent.id === 'operatorMotorcycleEndorsementDurationNoDependent_Ext') {
            _.set(metaDataContent, 'componentProps.requiredForIssuance', true)
        }
        _.each(classNameMap, (item) => {
            _.forIn(item, (className, id) => {
                if (metaDataContent.id === id) {
                    _.set(metaDataContent, 'componentProps.className', className)
                }
            })
        })
        return metaDataContent;
    };
}

function truncatedString(obj, path) {
    const str = _.get(obj, path);
    if (str) {
        const numStr = str.replace(/[^0-9]/ig, '');
        _.set(obj, path, numStr.substring(numStr.length - 10));
    }
}

function truncatedPhoneNumber(operator) {
    truncatedString(operator, 'workNumber');
    truncatedString(operator, 'homeNumber');
    truncatedString(operator, 'cellNumber');
}

function getRelationshipOptions(operator, relationshipOptions) {
    if (!operator.isPNI) {
        return _.filter(relationshipOptions, (option) => {
            return option.code !== 'Insured_Ext'
        });
    }
    return relationshipOptions;
}

export default {
    onDateOfBirthCell,
    onRelationshipCell,
    onExcludedCell,
    onNameCell,
    getAccordionOverridesForOperator,
    setDefaultOperatorObj,
    removeEmpty,
    excludePNISelectionsAndMessage,
    isChild,
    getAge,
    hasExcluded,
    fillExistingContact,
    hasInvalidOperator,
    hasIssuanceInvalidFields,
    getQuestionMapper,
    truncatedPhoneNumber,
    getRelationshipOptions,
}