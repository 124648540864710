import React, {
    useCallback,
    useContext,
    useState,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { 
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import RTCoverageUtil from '../../util/RTCoverageUtil';
import RTClausesComponentVM from '../RTClausesComponentVM/RTClausesComponentVM';
import RTSingleClauseComponentVM from '../RTSingleClauseComponentVM/RTSingleClauseComponentVM';
import metadata from './RTVehicleCoveragesModal.metadata.json5';
import messages from './RTVehicleCoveragesModal.messages';
import RTScheduleItemsPopup from '../RTScheduleItemsPopup/RTScheduleItemsPopup';

import { Button } from '@jutro/legacy/components';

function RTVehicleCoveragesModal(props) {
    const {
        isEditable,
        title,
        size,
        isOpen,
        onResolve,
        submissionVM,
        updateWizardData,
        rtVehiclePublicID,
        openCopyCoveragesModal,
        // updateErrorsAndWarnings,
        // loadingVehicleCoverages,
        lobName,
        selectedVersionIndex,
        coveragesService,
    } = props;

    const modalApi = useWniModal();

    const {
        onValidate,
        isComponentValid,
    } = useValidation('RTVehicleCoveragesModal');

    const {
        jobID,
        sessionUUID,
        lobData: {
            [lobName]: {
                coverables: {
                    vehicles = []
                } = {}
            } ={}
        } = {}
    } = submissionVM.value
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const allRTVehiclesCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.vehiclesCoverages`;
    const allRTVehiclesCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.vehiclesCoverages.children`;
    
    const allRTVehiclesCoverages = _.get(submissionVM.value, allRTVehiclesCoveragesPath);
    const allRTVehiclesCoverageVMs = _.get(submissionVM, allRTVehiclesCoveragesVMPath);

    const rtVehCoverageIndex = allRTVehiclesCoverages
        .findIndex((rtVehCoverageDTO) => _.get(rtVehCoverageDTO, 'owningCoverablePublicID') === rtVehiclePublicID);
    const rtVehCoverageVMIndex = allRTVehiclesCoverageVMs
        .findIndex((rtVehCoverageVM) => _.get(rtVehCoverageVM.value, 'owningCoverablePublicID') === rtVehiclePublicID);

    const rtVehCoveragePath = `${allRTVehiclesCoveragesPath}[${rtVehCoverageIndex}].coverages`;
    const rtVehCoveragesVMPath = `${allRTVehiclesCoveragesVMPath}[${rtVehCoverageVMIndex}].coverages`;

    const rtVehCoverages = _.get(
        submissionVM.value,
        rtVehCoveragePath
    )

    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [loadingRTVehCoverages, setLoadingRTVehCoverages] = useState(false);
    const [popupWarningIssue, setPopupWarningIssue] = useState([]);


    const handleSaveAndClose = () => {
        if (isEditing || loadingRTVehCoverages) {
            return;
        }
        if (!isComponentValid && isEditable) {
            setShowErrors(true);
        } else {
            onResolve();
        }
    };

    const showCoverageDetailsPopup = (coverageToOpen)=>{
        const rtVehCovVMs = _.get(submissionVM, `${rtVehCoveragesVMPath}.children`);
        const targetCoverageIndex = rtVehCovVMs.findIndex((vm)=>vm.value.code_Ext === coverageToOpen.code_Ext);
        const targetCoveragePath = `${rtVehCoveragesVMPath}.children[${targetCoverageIndex}]`;

        const getCoverageFromLobCoverages = (lobCovs) => {
            const vehiclesCoverages = _.get(lobCovs, 'vehiclesCoverages', [])
            const vehCoveragesDTO = vehiclesCoverages
                .find((rtVehDTO) => _.get(rtVehDTO, 'owningCoverablePublicID') === rtVehiclePublicID);
            const vehCoverages = _.get(vehCoveragesDTO, 'coverages', [])
            return vehCoverages.find((cov) => _.get(cov, 'code_Ext') === coverageToOpen.code_Ext)
        }

        const componentProps = {
            isEditable,
            coverageCode: coverageToOpen.code_Ext,
            coveragePath: targetCoveragePath,
            schedule: coverageToOpen.schedule,
            coverage: coverageToOpen,
            coveragesService,
            jobID,
            sessionUUID,
            lobName,
            submissionVM,
            updateSubmissionVM: updateWizardData,
            lobCoveragesPath,
            getCoverageFromLobCoverages,
        };
        return modalApi.showModal(
            <RTScheduleItemsPopup {...componentProps} />
        );
    } 

    const onOpenCoverageDetailsPopup = (clausePatternCode)=>{
        const coverageToOpen = rtVehCoverages.find((cov)=>cov.code_Ext === clausePatternCode);
        showCoverageDetailsPopup(coverageToOpen)
    }

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = RTCoverageUtil.setClauseValue(rtVehCoverages, rtVehCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, rtVehCoveragePath, newCoverages);
            updateWizardData(submissionVM);
            return submissionVM;
        },
        [
            rtVehCoverages,
            rtVehCoveragesVMPath,
            submissionVM,
            rtVehCoveragePath,
            updateWizardData,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = RTCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = RTCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingRTVehCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        // Only show warnings on field change here
        const newErrorsAndWarnings = ErrorsAndWarningsUtil.getServerIssues(errorsAndWarnings);
        const newWarnings = newErrorsAndWarnings.filter((issue) => _.get(issue, 'type') === 'warning');
        setPopupWarningIssue(newWarnings)
        // updateErrorsAndWarnings(errorsAndWarnings);
        setLoadingRTVehCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        lobName,
        coveragesService
    ]);

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const handleCopyCoverages = () => {
        openCopyCoveragesModal(rtVehiclePublicID);
    }

    const multipleRTVehs = vehicles.filter((vehicle) => vehicle.isPhysicalDamageRequired).length > 1;

    const overrideProps = {
        dynamicInlineNotificationContainer: {
            visible: popupWarningIssue.length > 0,
            validationIssues: popupWarningIssue
        },
        rtVehCoverageModalCopyCoverageButton: {
            visible: isEditable && multipleRTVehs,
        },
        rtVehCoveragesComponent: {
            loadingClause: loadingRTVehCoverages,
            path: rtVehCoveragesVMPath,
            value: rtVehCoverages,
            onValidate,
            showErrors,
            componentMapOverrides: {
                RTSingleClauseComponentVM: RTSingleClauseComponentVM,
            },
            isEditable,
            setIsEditing,
            isEditing,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onCopyCoverageButtonClick: handleCopyCoverages,
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
            onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
        },
        resolveComponentMap: {
            RTClausesComponentVM: RTClausesComponentVM,
            ValidationIssuesComponent: ValidationIssuesComponent,
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            className={size}
        >
            <ModalHeader
                title={title || 'title'}
                onClose={handleSaveAndClose}
            />
            <ModalBody
                id="vehicleCoverageModal"
            >
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={{}}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button disabled={isEditing || loadingRTVehCoverages} onClick={handleSaveAndClose}>
                    {translator(messages.saveAndClose)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

RTVehicleCoveragesModal.propTypes = {
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({
                lobData: PropTypes.shape({
                    personalAuto: PropTypes.shape({
                        coverables: PropTypes.shape({
                            vehicles: PropTypes.arrayOf()
                        })
                    }),
                })
            })
        }
    ).isRequired,
    rtVehiclePublicID: PropTypes.string.isRequired,
    openCopyCoveragesModal: PropTypes.func.isRequired,
    updateSubmissionVMOnClauseUpdate: PropTypes.func,
    loadingVehicleCoverages: PropTypes.bool.isRequired,
    lobName: PropTypes.string.isRequired,
    selectedVersionOfferingsIndex: PropTypes.number.isRequired,
};

RTVehicleCoveragesModal.defaultProps = {
    isReadOnly: false,
    title: null,
    size: 'md',
    updateSubmissionVMOnClauseUpdate: _.noop,
};

export default RTVehicleCoveragesModal;
