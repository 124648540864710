import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import metadata from './ScheduledDetails.metadata.json5';

function ScheduledDetails(props) {
    const {
        currentRow,
        syncCurrentRow,
        onValidate = _.noop,
        showErrors,
        isReadOnly
    } = props;

    const writeValue = (value, path) => {
        _.set(currentRow.value, path, value);
        syncCurrentRow(currentRow);
    };

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        
       
    };

    const resolvers = {
        callbackMap: {
           
        },
        componentMap: {
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default ScheduledDetails;
