import { defineMessages } from 'react-intl';

export default defineMessages({
    withDrawTitle: {
        id: 'quoteandbind.common.views.quote-details.Withdraw the quote?',
        defaultMessage: 'Withdraw the quote?'
    },
    withDraweDescription: {
        id: 'quoteandbind.common.views.quote-details.Withdraw description',
        defaultMessage: 'Withdrawing will withdraw both versions of the Select/Signature quotes. To withdraw both, select "Withdraw". Otherwise, select "Cancel".'
    },
});
