import React, {
    useMemo,
} from 'react';
import _ from 'lodash';
import HOCoverageUtil from '../../util/HOCoverageUtil';
import HOClauseComponent from '../HOClauseComponentVM/HOClauseComponent';
import HOClauseElementComponent from './HOClauseElementComponent';

function isClauseLoading(clause, loadingClause) {
    if (_.isArray(loadingClause)) {
        return loadingClause.includes(clause.publicID)
    }
    if (_.isString(loadingClause)) {
        return loadingClause === clause.publicID;
    }
    if (_.isBoolean(loadingClause)) {
        return loadingClause;
    }
    return false;
}

function getRelativePath(changedValuePath) {
    const [relativePath] = changedValuePath.match(/(selected|hoprivateStructures|terms\.children\[\d+\](\.\w+)+)/);

    return relativePath.replace(/\.children/g, '');
}

const isAllTermsReadOnly = (terms) => {
    return terms.every((term) => {
        const isTermReadOnly = _.get(term, 'readOnly_Ext')
        if (isTermReadOnly) {
            return true
        }
        const chosenTerm = _.get(term, 'chosenTerm')
        const options = _.get(term, 'options', [])
        if (_.get(options, 'length', 0) === 1 && _.get(options, '[0].code') === chosenTerm) {
            return true
        }
        return false
    })
}

function HOSingleClauseComponentVM(props) {
    const {
        //
        value: clause = {},
        path,
        //
        // onValidate,
        showErrors,
        //
        idPrefix,
        containerClassName,
        description,
        //
        labelPosition,
        labelTop,
        //
        showAmount,
        //
        loadingClause,
        loadingClauseMessage,
        loadingIconType,
        //
        isEditable,
        isDisabled,
        //
        onScheduleChangeWithSync,
        onScheduleChangeWithoutSync,
        onChangeSubmissionAndSync,
        // This function change submission without sync
        onChangeClause,
        onSyncCoverages,
        // ============================
        hideCheckBoxForRequired,
        isOnlyShowTermsAndScheduleItems,
        showScheduleDetailsInPanelBelow,
        showDetailsInPopup,
        onOpenCoverageDetailsPopup,
        openedRowItemNumber,
        setOpenedRowItemNumber,
        selectedRowItemIndex,
        setSelectedRowItemIndex,
        isCovBLinkVisible,
        hideCovBstructureRelatedTerms,
        hideCovBstructureLimitItem,
        hidePrivateRelatedStructures,
        isEditing,
        isInDetailsPopup,
        setIsEditing,
        coveragesConfig,
        additionalInterests = [],
        // create loss payee
        onSaveAdditionalInterestFn,
        onGettingAdditionalInterestTypeOptions,
        accountContacts,
        isCreateAdditionalInterestPanelVisible,
        setIsCreateAdditionalInterestPanelVisible,
        // end: create loss payee
    } = props;

    const {
        hoprivateStructures,
        code_Ext: codeExt,
        terms,
        schedule,
        selected
    } = clause

    const isCoverageB = ['HOPCovB', 'DPCoverageBSchedCov_Ext'].includes(codeExt);
    const isCoverageA = ['DPPremLiabNonOwnerOccDwelSchedCov_Ext'].includes(codeExt);

    const getShowPrivateStructureTable = () => {
        if (_.isNil(hoprivateStructures)) {
            return false
        }
        if (isCoverageB) {
            if (hidePrivateRelatedStructures) {
                return false
            }
            // Show structure when has one or more structure
            if (_.isArray(hoprivateStructures) && _.get(hoprivateStructures, 'length', 0) > 0) {
                return true
            }
            const isAddItNowTerm = _.get(clause, 'terms', [])
                .find((term) => _.get(term, 'code_Ext') === 'PortalCustomCovTerm_IsAddItNow')
            if (_.isNil(isAddItNowTerm) || _.get(isAddItNowTerm, 'chosenTerm') !== "true") {
                return false
            }
        }
        return true
    }

    const clauseID = useMemo(() => `${idPrefix}Clause_[${clause.publicID}]`, [idPrefix, clause.publicID])

    const isTooltipVisible = (
        description || (clause.description !== clause.name && !_.isEmpty(clause.description))
    );

    const coverageTermsCount = _.get(clause, 'terms.length', 0);
    const isLimitRequired = _.get(clause, 'terms', []).some((term) => term.required);

    const displayName = isEditable ? clause.name : description || clause.description;

    const showPrivateStructureTable = getShowPrivateStructureTable();
    const isCoverageValid = !HOCoverageUtil.isCoverageInvalid(clause, coveragesConfig)

    const linkToDetailsReadOnly = !isEditable || (
        _.isNil(schedule) // If coverage has schedule it's not readonly
        && _.isNil(hoprivateStructures) // If coverage has hoprivateStructures it's not readonly
        && isAllTermsReadOnly(terms)
    );


    const handleChangeAndSyncClause = (value, changedValuePath) => {
        if (_.get(clause, getRelativePath(changedValuePath)) !== value) {
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    
    const showViewOrEditButtonLink = (isCoverageB && isCovBLinkVisible) || isCoverageA
        || (showDetailsInPopup && selected && !!(_.get(terms, 'length', 0) > 0 || schedule || hoprivateStructures));

    const isLoading = isClauseLoading(clause, loadingClause)
    const ClauseContent = clause.selected && !isLoading && !showDetailsInPopup ? <HOClauseElementComponent
        idPrefix={idPrefix}
        clause={clause}
        hideCovBstructureRelatedTerms={hideCovBstructureRelatedTerms}
        hideCovBstructureLimitItem={hideCovBstructureLimitItem}
        clausePath={path}
        isEditable= {isEditable}
        isDisabled={isDisabled}
        labelPosition={labelPosition}
        labelTop={labelTop}
        hoprivateStructures={hoprivateStructures}
        // This function change submission without sync
        onChangeClause={onChangeClause}
        onSyncCoverages={onSyncCoverages}
        onChangeSubmissionAndSync={onChangeSubmissionAndSync}
        onScheduleChangeWithSync={onScheduleChangeWithSync}
        onScheduleChangeWithoutSync={onScheduleChangeWithoutSync}
        isInDetailsPopup={isInDetailsPopup}
        setIsEditing={setIsEditing}
        showScheduleDetailsInPanelBelow={showScheduleDetailsInPanelBelow}
        showPrivateStructureTable={showPrivateStructureTable}
        openedRowItemNumber={openedRowItemNumber}
        setOpenedRowItemNumber={setOpenedRowItemNumber}
        selectedRowItemIndex={selectedRowItemIndex}
        setSelectedRowItemIndex={setSelectedRowItemIndex}
        showErrors={showErrors}
        coveragesConfig={coveragesConfig}
        // create loss payee
        additionalInterests={additionalInterests}
        onSaveAdditionalInterestFn={onSaveAdditionalInterestFn}
        onGettingAdditionalInterestTypeOptions={onGettingAdditionalInterestTypeOptions}
        accountContacts={accountContacts}
        isCreateAdditionalInterestPanelVisible={isCreateAdditionalInterestPanelVisible}
        setIsCreateAdditionalInterestPanelVisible={setIsCreateAdditionalInterestPanelVisible}
        // end: create loss payee
    /> : null;
    
    const showInvalidCoverageError = showViewOrEditButtonLink && showErrors && !isCoverageValid;

    return (
        <>
            <HOClauseComponent
                id={clauseID}
                displayName={displayName}
                showAmount={showAmount}
                amount={clause.amount}
                path= {`${path}.selected`}
                readOnly= {clause.required || clause.readOnly_Ext}
                required= {clause.required}
                value= {selected}
                checked= {selected}
                onValueChange= {handleChangeAndSyncClause}
                isEditable= {isEditable}
                isDisabled= {isDisabled}
                isLoading= {isLoading}
                loadingMessage= {loadingClauseMessage}
                containerClassName= {`${containerClassName} clause-ootbcoverage-container`}
                labelPosition= {labelPosition}
                description= {isTooltipVisible ? description || clause.description : undefined}
                coverageTermsCount= {coverageTermsCount}
                isLimitRequired= {isLimitRequired}
                loadingIconType={loadingIconType}
                clausePatternCode= {clause.code_Ext}
                hideCheckBoxForRequired={hideCheckBoxForRequired}
                isOnlyShowTermsAndScheduleItems = {isOnlyShowTermsAndScheduleItems}
                showDetailsInPopup={showDetailsInPopup}
                onOpenCoverageDetailsPopup={onOpenCoverageDetailsPopup}
                // hoprivateStructures={hoprivateStructures}
                showPrivateStructureTable={showPrivateStructureTable}
                showScheduleDetailsInPanelBelow={showScheduleDetailsInPanelBelow}
                isCoverageValid={isCoverageValid}
                openedRowItemNumber={openedRowItemNumber}
                setOpenedRowItemNumber={setOpenedRowItemNumber}
                selectedRowItemIndex={selectedRowItemIndex}
                setSelectedRowItemIndex={setSelectedRowItemIndex}
                isCovBLinkVisible={isCovBLinkVisible}
                linkToDetailsReadOnly={linkToDetailsReadOnly}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                showViewOrEditButtonLink={showViewOrEditButtonLink}
            />
            {ClauseContent}
            {showInvalidCoverageError ?
                <div className='font-error-light-16 mb-10 mt--10' >Information for this coverage is missing.</div>
                : null}
        </>)
}

export default HOSingleClauseComponentVM;