import React, {useEffect, useCallback} from 'react';
import _ from 'lodash';
import { AccordionCard } from '@jutro/legacy/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import messages from './IMCommonAdditionalCovCard.messages.js'
import AccordionCardTitle from '../IMClauseAccordionCardTitle'
import IMCoverageUtil from '../../util/IMCoverageUtil';
import CoverageContext from '../../context/IMCoverageContext';
import IMSingleClauseComponentVM from '../IMSingleClauseComponentVM';
import IMClausesComponentVM from '../IMClausesComponentVM';

const IMCommonAdditionalCovCard = (props) => {
    const {
        coveragePartClauses,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateCoverageFormClausesService,
        coveragePartName,
        calculateLimit,
        handleAfterUpdateClauses
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { additionalCoverage } = coveragePartClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();
    const clauseType = 'additionalCoverages';
    const validationID = `${coveragePartName}AdditionalCovCard`;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }

        }

    }, [isComponentValid, onPageValidate, validationID])


    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = IMCoverageUtil.setClauseValue(additionalCoverage, 'additionalCoverage', value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, 'additionalCoverage', newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, additionalCoverage, setCoveragePartClauses, setIsEditing]
    );

    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        const coveragePartClausesToUpdate = IMCoverageUtil.generateUpdatedCoverageFormCommonClausesDTO(newCoveragePartClauses);
        setLoadingMask(true)
        const coveragePartClausesFromServer = await updateCoverageFormClausesService(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
        if (_.isFunction(handleAfterUpdateClauses)) {
            handleAfterUpdateClauses(coveragePartClausesFromServer)
        }
    }, [setLoadingMask, updateCoverageFormClausesService, jobID, sessionUUID, authHeader, setCoveragePartClauses, handleAfterUpdateClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);



    const changeClauseAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeSubmission(value, changedPath);
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeSubmission, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    return (
        <AccordionCard
            id="AdditionalCoverages"
            chevron
            cardHeadingClassName="font-PrimaryDark-bold-16 font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.AdditionalCoverages)}
                    actionText={messages.AddAdditionalCoverages}
                    onClick={() => onClickAddSearchClauses(clauseType)}
                /> : translator(messages.AdditionalCoverages)}
            errorState={showErrors && !isComponentValid}
            initialExpanded
        >
            <h4 className='mb-10'>{translator(messages.AddedAdditionalCoverages)}</h4>
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer,
                        calculateLimit
                    }}
                >
                    <IMClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeClauseAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        path="additionalCoverage"
                        value={additionalCoverage}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            IMSingleClauseComponentVM: IMSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                </CoverageContext.Provider>
            </div>
        </AccordionCard>
    )
}

export default IMCommonAdditionalCovCard