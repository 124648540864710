import { defineMessages } from 'react-intl';

export default defineMessages({
    contactGlassTitle: {
        id: 'fnol.wni.pa.views.pa-contact-admin.Contact Glass Network',
        defaultMessage: 'Contact Glass Network'
    },
    towingLaborOnlyTitle: {
        id: 'fnol.wni.pa.views.pa-contact-admin.Contact Roadside Assistance Network',
        defaultMessage: 'Contact Roadside Assistance Network'
    },
    contactGlassCall: {
        id: 'fnol.wni.pa.views.pa-contact-admin.contactGlassCall',
        defaultMessage: 'Please call 1-855-921-3164 for assistance.'
    },
    towingLaborOnlyCall: {
        id: 'fnol.wni.pa.views.pa-contact-admin.contactTowingLaborCall',
        defaultMessage: 'Please call 1-855-921-3164 for assistance.'
    }
})