import { defineMessages } from 'react-intl';

export default defineMessages({
    garagedAtNew: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-vehicle.Garaged At.New',
        defaultMessage: 'New',
    },
    removeVehicleTitle: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-vehicle.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeVehicleDescription: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-vehicle.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this vehicle record?',
    },
    viewAndEditLabel: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.View',
        defaultMessage: 'View',
    },
    vehicleType: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Vehicle Type',
        defaultMessage: 'Vehicle Type',
    },
    vehicleTypeForTrailer: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Trailer Type',
        defaultMessage: 'Trailer Type',
    },
    vehicleDetail: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Vehicle Detail',
        defaultMessage: 'Vehicle Details',
    },
    vehicleDetailForTrailer: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Trailer Detail',
        defaultMessage: 'Trailer Details',
    },
    vehicleInformation: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Vehicle Information',
        defaultMessage: 'Vehicle Information',
    },
    vehicleInformationForTrailer: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Trailer Information',
        defaultMessage: 'Trailer Information',
    },
    isThereExistingDamage: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Is there any existing damage to this vehicle?',
        defaultMessage: 'Is there any existing damage to this vehicle?',
    },
    isThereExistingDamageForTrailer:{
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Does this trailer have existing damage?',
        defaultMessage: 'Does this trailer have existing damage?',
    },
    hasCustomizedEquip: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Does this vehicle have any customized equipment?',
        defaultMessage: 'Does this vehicle have any customized equipment?',
    },
    hasCustomizedEquipForTrailer:{
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Does this trailer have any customized equipment?',
        defaultMessage: 'Does this trailer have any customized equipment?',
    },
    hasPhysDamageLast12Months: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Has this vehicle been continuously insured for physical damage for the last 12 months (or for the duration of your ownership if less than 12 months)?',
        defaultMessage: 'Has this vehicle been continuously insured for physical damage for the last 12 months (or for the duration of your ownership if less than 12 months)?',
    },
    hasPhysDamageLast12MonthsForTrailer: {
        id: 'quoteandbind.rt.directives.templates.rt-edit-vehicle.Has this Trailer been continuously insured for physical damage for the last 12 months (or for the duration of your ownership if less than 12 months)?',
        defaultMessage: 'Has this Trailer been continuously insured for physical damage for the last 12 months (or for the duration of your ownership if less than 12 months)?',
    },
    selectedVehicleAppliesWarningMsg: {
        id: 'wni.quoteandbind.rt.vehicle.Please add driver information in the driver section.',
        defaultMessage:
            'Please add driver information in the driver section.',
    },
    selectApply: {
        id: 'wni.quoteandbind.rt.vehicle.(Please select all that apply)',
        defaultMessage:
            '(Please select all that apply)',
    },
    hasLivingQuartersWarning: {
        id: 'wni.quoteandbind.rt.vehicle.This trailer needs to be added to the insured\'s personal auto policy',
        defaultMessage: 'This trailer needs to be added to the insured\'s personal auto policy'
    },
    coverageAmountTitle1: {
        id: 'wni.quoteandbind.rt.vehicle.If Replacement Cost is selected:',
        defaultMessage:
            'If Replacement Cost is selected:',
    },
    coverageAmountMsg1: {
        id: 'wni.quoteandbind.rt.vehicle.Enter in the cost new to replace the vehicle with like kind and quality. ',
        defaultMessage:
            'Enter in the cost new to replace the vehicle with like kind and quality. ',
    },
    coverageAmountTitle2: {
        id: 'wni.quoteandbind.rt.vehicle.If Agreed Value is selected:',
        defaultMessage:
            'If Agreed Value is selected:',
    },
    coverageAmountMsg2: {
        id: 'wni.quoteandbind.rt.vehicle.Upon a covered loss the maximum limit of liability payable by carrier without consideration for depreciation.',
        defaultMessage:
            'Upon a covered loss the maximum limit of liability payable by carrier without consideration for depreciation.',
    },
    trailerTypeMsg1: {
        id: 'wni.quoteandbind.rt.vehicle.A transport trailer is used to transport the vehicle to a location.',
        defaultMessage: 'A transport trailer is used to transport the vehicle to a location.'
    },
    trailerTypeMsg2: {
        id: 'wni.quoteandbind.rt.vehicle.A cargo trailer is pulled behind the vehicle carrying equipment or materials.',
        defaultMessage: 'A cargo trailer is pulled behind the vehicle carrying equipment or materials.'
    },
    verhicleTypeMsg: {
        id: 'wni.quoteandbind.rt.vehicle.Select the vehicle type that matches the item to be insured. For further details outlining specific vehicle types, click here. Not all vehicle types are eligible.',
        defaultMessage: 'Select the vehicle type that matches the item to be insured. For further details outlining specific vehicle types, click {here}. Not all vehicle types are eligible.'
    },
    save: {
        id: "wni.quoteandbind.rt.vehicle.save",
        defaultMessage: "Save"
    },
    customEquipment: {
        id: "wni.quoteandbind.rt.vehicle.Custom Equipment",
        defaultMessage: "Custom Equipment"
    },
    itemInsuredMsg: {
        id: 'wni.quoteandbind.rt.vehicle.Click here to determine vehicle category',
        defaultMessage: 'Click {here} to determine vehicle category'
    }
});
