import React, { Component } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { Loader } from '@jutro/components';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AccountService } from 'gw-capability-gateway';
// import { LoadingIndicatorComponent } from 'gw-components-platform-react';
import AccountTimeline from 'gw-capability-journey-react';
import metadata from './AccountsTimeline.metadata.json5';

class Timeline extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
    }

    state = {
        showLoader: false,
        EventsData: []
    };

    getResponse = async () => {
        const {
            authHeader, fromAccountLanding:
            { accountDetailsData: { accountNumber } }
        } = this.props;
        const responseData = await AccountService.getTimelineForAccount(
            accountNumber, authHeader
        );
        this.setState({
            EventsData: responseData.events, showLoader: false
        });
    }

    componentDidMount = () => {
        this.setState({ showLoader: true });
        this.getResponse();
    }

    render() {
        const { EventsData, showLoader } = this.state;
        const overrideProps = {
            accountTimeline: {
                events: EventsData,
                visible: !showLoader
            },
            timelineLoader: {
                showLoader: showLoader
            }
        };
        const resolvers = {
            resolveComponentMap: {
                timelinecomponent: AccountTimeline,
                loadingindicatorcomponent: Loader
            }
        };
        return (
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        );
    }
}

export default withAuthenticationContext(Timeline);
