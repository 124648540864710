
import React from 'react';

import { GLGeneralLiabilityReadOnlyPage } from 'wni-capability-quoteandbind-gl-react';

function GLGeneralLiabilityChangeReadOnlyPage(props) {

    return (
        <GLGeneralLiabilityReadOnlyPage {...props} />
    );

}

GLGeneralLiabilityChangeReadOnlyPage.propTypes = GLGeneralLiabilityReadOnlyPage.propTypes;
GLGeneralLiabilityChangeReadOnlyPage.defaultProps = GLGeneralLiabilityReadOnlyPage.defaultProps;

export default GLGeneralLiabilityChangeReadOnlyPage;