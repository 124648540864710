
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { WniChangeConflict } from 'wni-capability-gateway';
import { IMMultiQuoteChangeService } from 'wni-capability-policychange-im';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';

import { IMWarehouseLegalLiabilityPage } from 'wni-capability-quoteandbind-im-react';


const IMWarehouseLegalLiabilityChangePage = (props) => {

    // const modalApi = useWniModal();

    // const useAuthenticationData = useAuthentication();
    // const { authHeader } = useAuthenticationData;
    // const {
    //     loadingMask: { setLoadingMask },
    // } = useDependencies(['loadingMask']);
    // const {
    //     wizardData: submissionVM,
    //     updateWizardPageData,
    //     //
    //     quoteService = IMMultiQuoteChangeService,
    //     getPreQuotePageNext = _.identity,
    // } = props;

    // const {
    //     jobID,
    //     sessionUUID,
    //     baseData: {
    //         periodStatus,
    //     },
    // } = submissionVM.value;

    // const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('InlandMarine', submissionVM);

    // const showChangeConflictsModal = useCallback(
    //     (modalVM) => {
    //         const baseVM = modalVM.baseData;
    //         const policyNumber = _.get(modalVM.value, 'changeData.policyNumber');
    //         const componentProps = {
    //             title: 'Change Conflicts',
    //             iconClassType: false,
    //             showCloseBtn: false,
    //             showCancelBtn: false,
    //             actionBtnLabel: 'Submit',
    //             cancelBtnLabel: 'Cancel',
    //             size: 'lg',
    //             authHeader: authHeader,
    //             baseVM,
    //             jobID,
    //             policyNumber,
    //         };
    //         return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
    //     },
    //     [authHeader, jobID]
    // );

    // const goToNext = async () => {
    //     if (periodStatus === 'Draft') {
    //         updateWizardPageData({
    //             [WizardConstants.sideBySideData]: undefined,
    //             [WizardConstants.policyDiffData]: undefined,
    //             [WizardConstants.policyChangeDocumentsData]: undefined,
    //         });
    //         setLoadingMask(true)
    //             // submissionVM.value = await IMMultiQuoteChangeService.processQuote(
    //             submissionVM.value = await quoteService.processQuote(
    //             jobID, sessionUUID, authHeader
    //         );
    //     }
    //     return submissionVM;
    // };

    // const handleConflict = async () => {
    //     const conflictRes = await WniChangeConflict.getChangeConflictData(submissionVM.jobID.value, authHeader);
    //     if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
    //         setLoadingMask(false)
    //         const newSubmissionVM = await showChangeConflictsModal(submissionVM)
    //             .then(goToNext)
    //             .catch(() => {
    //                 _.noop();
    //                 return false;
    //             });
    //         return newSubmissionVM;
    //     }
    //     return goToNext();
    // };

    // const onChangePageNext = async () => {
    //     return handleConflict();
    // };

    // const onPreQuotePageNext = getPreQuotePageNext(onChangePageNext);
    return (
        <IMWarehouseLegalLiabilityPage {...props} isPolicyChange
            // onPreQuotePageNext={onPreQuotePageNext}
        />
    );
};
export default IMWarehouseLegalLiabilityChangePage

