import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { QuestionSetComponent } from 'gw-components-platform-react';
import {
    WniPNIUtil,
    WniQuestionSetUtil
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
} from 'wni-capability-gateway-react';
import { useProductsData } from 'wni-portals-util-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import WizardPage from '../../templates/WALWizardPage';

import styles from './WALPolicyDetailsPage.module.scss';
import metadata from './WALPolicyDetailsPage.metadata.json5';
import messages from './WALPolicyDetailsPage.messages';

const availableWALTermTypeCodes = ['Annual'];

function WALPolicyDetailsReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        linePath,
    } = props;

    const {
        baseData: {
            productCode,
        },
    } = submissionVM.value;

    const translator = useTranslator();
    // const ViewModelService = useContext(ViewModelServiceContext);

    const { getProductItem, getProductAvailableStates } = useProductsData();

    const { domainCompany: {code: domainCode, domainName}, loadingMask: { setLoadingMask } } = useDependencies(['domainCompany', 'loadingMask']);

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.firstName.value');
        const lastName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.lastName.value');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const renderPrimaryNamedInsuredValue = () => {
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))}</div>
            </div>
        )
    }

    const getAvailableTermType = () => {
        return  availableWALTermTypeCodes.map((code) => {
            return {
                code: code,
                name: translator({
                    id: `typekey.TermType.${code}`,
                    defaultMessage: `typekey.TermType.${code}`
                })
            };
        });
    }

    const productAvailableStates = (code) => {
        return getProductAvailableStates(code);
    };
    const getPolicyAvailableStates = () => {
        const policyStates = _.get(submissionVM, `baseData.baseState_Ext.aspects.availableValues`);
        const availablePolicyStates = policyStates.filter((item) => productAvailableStates(productCode).includes(item.code));
        return availablePolicyStates.map((state) => {
            return {
                code: state.code,
                name: translator({ id: state.name })
            }
        })
    };

    const getMultipleSelectOptions = () => {
        const options =  _.get(submissionVM.value, `lobData.${linePath}.policyInfoDTO.selectAllAppliesOptions_Ext`)
        return options.map((res) => {
            return {
                code: res,
                name: translator({ id: res })
            }
        })
    }

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            effectiveDate: {
                // minDate: effectiveMinDate(),
                // maxDate: effectiveMaxDate(),
                value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            },
            walTermTypedropdownselect: {
                availableValues: getAvailableTermType(),
            },
            walBaseState: {
                availableValues: getPolicyAvailableStates(),
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: true,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                // onValidate
                isReadOnly: true,
            },
            primaryNamedInsuredField: {
                value: renderPrimaryNamedInsuredValue()
            },
            primaryNamedInsuredEditIcon: {
                visible: false
            },
            policyUnderwritingQuestionSets: {
                isReadOnly: true,
                // contentMapper: questionSetMapper,
                path: `lobData.${linePath}.preQualQuestionSets.children[0].value`,
            },
            UWQSelectAllAppliesMultiSelect: {
                availableValues: getMultipleSelectOptions(),
                visible: _.get(submissionVM.value, `lobData.${linePath}.policyInfoDTO.applicantExistPolicy_Ext`),
                path: `lobData.${linePath}.policyInfoDTO.selectAllApplies_Ext`
            },
            UWQApplicantExistPolicyToggle: {
                label: translator(messages.isTWThereExistingPolicy, {
                    companyName: domainName
                }),
                path: `lobData.${linePath}.policyInfoDTO.applicantExistPolicy_Ext`,
            },
        };

    }, [submissionVM.value, submissionVM.baseData, getAvailableTermType, getPolicyAvailableStates, handleAgencyChange, renderPrimaryNamedInsuredValue]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

WALPolicyDetailsReadOnlyPage.propTypes = {
    ...WizardPage.propTypes,
    //
    savePolicyInfoData: PropTypes.func,
    linePath: PropTypes.string,
};

WALPolicyDetailsReadOnlyPage.defaultProps = {
    ...WizardPage.defaultProps,
    //
    linePath: 'watercraft',
};
export default WALPolicyDetailsReadOnlyPage;
