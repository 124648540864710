import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLWTWizard from './FNOLWTWizard.pe';
import FNOLWTLossTypePage from './pages/LossType/FNOLWTLossTypePage.pe';
import FNOLWTLossDetailPage from './pages/LossDetail/FNOLWTLossDetailPage.pe';
import FNOLWTOtherLossDetailPage from './pages/OtherLossDetail/FNOLWTOtherLossDetailPage.pe';

setComponentMapOverrides(
    {
        FNOLWTWizard: { component: 'FNOLWTWizard' },
        FNOLWTLossTypePage: { component: 'FNOLWTLossTypePage' },
        FNOLWTLossDetailPage: { component: 'FNOLWTLossDetailPage' },
        FNOLWTOtherLossDetailPage: { component: 'FNOLWTOtherLossDetailPage' },
    },
    {
        FNOLWTWizard,
        FNOLWTLossTypePage,
        FNOLWTLossDetailPage,
        FNOLWTOtherLossDetailPage
    }
);

export {
    FNOLWTWizard as default,
    FNOLWTLossTypePage,
    FNOLWTLossDetailPage,
    FNOLWTOtherLossDetailPage
};
