import React, {
    useEffect,
    useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
// import { useTranslator, IntlContext } from '@jutro/locale'; 
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { AccountService } from 'gw-capability-gateway-policy';
import { UserService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniAccountsUtil } from 'wni-portals-util-js';
import { useBusinessData } from 'wni-portals-util-react';
import { WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import styles from 'gw-capability-gateway-react/Accounts/Accounts.module.scss';
// import 'gw-capability-gateway-react/Policies/Policies.messages';
import metadata from './AccountDetails.metadata.json5';
import CommonRoutes from '../../Common/CommonRouting';
import pageRouting from '../../Common/Accounts-config.json5';

function AccountDetails(props) {
    // const translator = useTranslator();
    const { authHeader, isAuthenticated } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        location: {
            pathname = '',
            state: redirectPath,
        },
        match: {
            params: { accountNumber },
            url: matchURL,
        },
    } = props;

    const [currentView, updateCurrentView] = useState(undefined);
    const [journeyTurnedOn, updateJourneyTurnedOn] = useState(false);
    const [commissionTurnedOn, updateCommissionTurnedOn] = useState(false);
    const [accountData, updateAccountData] = useState({});
    const [accountBillingData, updateAccountBillingData] = useState({});
    const [isCl, updateIsCl] = useState(false);
    const { enableCommercialLine } = useBusinessData();

    const getJourneyTurnedOn = async (accountNumberParam, authHeaderParam) => {
        const { journey } = appConfig;
        const hasUserAccess = await UserService.hasUserAccessToAccountPolicies(accountNumberParam,
            authHeaderParam);
        updateJourneyTurnedOn(journey && hasUserAccess);
    };

    const getCommissionTurnedOn = async (authHeaderParam) => {
        const permissionDTO = {
            permission: 'viewcommissions'
        };
        const newCommissionStatus = await UserService.hasUserSystemPermission(permissionDTO,
            authHeaderParam);
        updateCommissionTurnedOn(newCommissionStatus);
    };

    const getAccountDetails = async () => {
        setLoadingMask(true);
        const { gatewayBilling } = appConfig;
        if (!isAuthenticated) {
            setLoadingMask(false);
            return;
        }
        await getJourneyTurnedOn(accountNumber, authHeader);
        await getCommissionTurnedOn(authHeader);
        // fetch from PC
        const accountDetailsResponse = await AccountService.getAccountDetails(accountNumber, authHeader);
        const isCL = _.get(accountDetailsResponse, 'accountHolder.contactType_Ext')  === 'company';
        updateIsCl(isCL);

        if (isCL || enableCommercialLine) {
            // fetch documents count from BC
            const billingAccountDocuments =
                // await WniCLBillingSubmissionService.getPortalDocumentsForAccount(
                await WniGatewayBillingSubmissionService.getDocumentsForAccount(
                    [accountNumber],
                    authHeader
                );
            const totalCount = _.get(accountDetailsResponse, 'numberOfDocuments') + billingAccountDocuments.length;
            _.set(accountDetailsResponse, 'numberOfDocuments',  totalCount);
        }
        
        updateAccountData(accountDetailsResponse);
        const currentPage = _.last(pathname.split('/'));
        const newView = redirectPath || currentPage;
        updateCurrentView(newView);
        if (gatewayBilling) {
            const newBillingData = await AccountService.getAccountBillingData(accountNumber, authHeader);
            updateAccountBillingData(newBillingData);
        }

        await AccountService.addRecentlyViewedAccounts(accountNumber, authHeader);
        setLoadingMask(false);
    };

    const updateCurrentPage = (currentPage) => {
        const excludePage = ['contactDetails'];
        if (excludePage.indexOf(currentPage) !== -1) {
            return;
        }
        updateCurrentView(currentPage);
    };

    useEffect(() => {
        getAccountDetails();
    }, [accountNumber]);

    useEffect(() => {
        if (pathname.indexOf(accountNumber) === -1) {
            getAccountDetails();
        }
        const currentPage = _.last(pathname.split('/'));
        if (currentView !== currentPage) {
            updateCurrentPage(currentPage);
        }
    }, [pathname, accountNumber]);

    const journeyFeatureTurnedOn = (routes) => {
        if (journeyTurnedOn) {
            const timelineRoute = {
                path: '/timeline',
                component: 'Accounts/Timeline/AccountsTimeline'
            };
            routes.push(timelineRoute);
        }
    };

    const billingFeatureTurnedOn = (routes) => {
        const { gatewayBilling } = appConfig;
        if (gatewayBilling) {
            const billingRoute = {
                path: '/billingAndPayment',
                component: 'Accounts/BillingAndPayment/BillingAndPayment'
            };
            routes.push(billingRoute);
        }
    };

    const isClaimsTurnedOn = () => {
        const { fnolLobs } = appConfig;
        return !_.isEmpty(fnolLobs);
    };

    const claimFeatureTurnedOn = (routes) => {
        if (isClaimsTurnedOn()) {
            const claimRoute = {
                path: '/claims',
                component: 'Accounts/Claims/AccountsClaims'
            };
            routes.push(claimRoute);
        }
    };

    const getRoutesWithFeature = () => {
        const routes = [...pageRouting];
        journeyFeatureTurnedOn(routes);
        billingFeatureTurnedOn(routes);
        claimFeatureTurnedOn(routes);
        return routes;
    };

    const updateCountInfo = (type) => {
        const currentCount = _.get(accountData, `numberOf${type}`);

        const newAccountData = _.clone(accountData);
        if (type === 'OpenActivities') {
            _.set(newAccountData, `numberOf${type}_Ext`, currentCount + 1);
        }else {
            _.set(newAccountData, `numberOf${type}`, currentCount + 1)
        };
        updateAccountData(newAccountData);
    };

    const updateCountInfoDirectly = (type, value) => {
        const newAccountData = _.clone(accountData);
        _.set(newAccountData, `numberOf${type}`, value);
        updateAccountData(newAccountData);
    };

    const handleTilesOnClick = (id) => {
        updateCurrentView(id);
    };

    const getMetadataImplementation = () => {
        const { gatewayBilling } = appConfig;
        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: !isCl
            },
            billingActivity: {
                active: (currentView === 'billingActivity')
            },
            timeline: {
                visible: journeyTurnedOn,
                active: (currentView === 'timeline'),
            },
            commission: {
                active: (currentView === 'commission'),
                visible: commissionTurnedOn
            },
            billingAndPayment: {
                active: (currentView === 'billingAndPayment'),
                visible: gatewayBilling
            },
            claims: {
                active: (currentView === 'claims'),
                visible: isClaimsTurnedOn()
            },
            accountIcon: {
                icon: WniAccountsUtil.getAccountIcon(_.get(accountData, 'accountHolder.contactType_Ext'))
            },
            accountDisplayName: {
                content: `${_.get(accountData, 'accountHolder.displayName')}(${accountData.accountNumber})`
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                handleTilesOnClick: handleTilesOnClick
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={accountData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    };

    // =================================================
    const render = () => {
        if (_.isEmpty(accountData)) {
            return null;
        }
        const accountActivitiesData = {
            accountData: accountData,
            getUpdateCountInfo: updateCountInfo,
            updateCountInfoDirectly
        };

        return (
            <div className={cx(styles.account)}>
                {getMetadataImplementation()}
                <CommonRoutes
                    steps={getRoutesWithFeature()}
                    basePath={matchURL}
                    accountDetailsData={accountData}
                    accountActivitiesData={accountActivitiesData}
                    billingData={accountBillingData}
                />
            </div>
        );
    };

    return render();
}

AccountDetails.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        params: PropTypes.shape({
            accountNumber: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]),
            type: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.string,
        pathname: PropTypes.string
    }).isRequired
};

export const AccountSummary = AccountDetails;
export default withViewModelService(withRouter(AccountDetails));
