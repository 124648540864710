import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TooltipIcon } from '@jutro/components';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    HOClueService,
    HOPriorCarrierService,
} from 'wni-capability-quoteandbind-ho';
import { IncidentPopupComponent, useWniModal } from 'wni-components-platform-react';

import {
    QuoteUtil,
    IssuanceValidationUtil,
    ValidationIssueUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/HOWizardPage';

import staticMessage from './HOLossHistoryAndPriorCarrierPage.static';
import styles from './HOLossHistoryAndPriorCarrierPage.module.scss';
import metadata from './HOLossHistoryAndPriorCarrierPage.metadata.json5';
import messages from './HOLossHistoryAndPriorCarrierPage.messages';

import { IconButton, CurrencyField } from '@jutro/legacy/components';

function HOLossHistoryAndPriorCarrierPage(props) {
    const modalApi = useWniModal();
    const {
        currentStep: { id: currentPageId },
        //
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        markFollowingWizardStepsUnvisited,
        wizardPageData,
        isSkipping,
        disableClueAndPriorCarrierEdit,
        showPolicyClaims,
        disableAddButton,
        checkRequiredForIssuance,
        productName,
        hoPriorCarrierService,
        hoClueService
    } = props;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const {
        authHeader
    } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    // carrier
    const [manuallyPriorCarrier, updateManuallyPriorCarrier] = useState([]);
    const isManualPriorCarrierEmpty = _.isEmpty(manuallyPriorCarrier);
    // clue
    const [manuallyClue, updateManuallyClue] = useState([]);
    const [isClueServiceCalled, updateIsClueServiceCalled] = useState(false);
    const [vendorClueReports, updateVendorClueReports] = useState([]);
    // policy claims
    const [vendorPolicyClaims, updateVendorPolicyClaims] = useState([]);
    const [vendorClaimsMsg, updateVendorClaimsMsg] = useState('');
    const policySource = _.get(
        submissionVM,
        'value.baseData.policyInfoSource_Ext'
        );
    const effectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
    const setWizardDataToDraft = useCallback(
        (quoteDataDTOVM) => {
            _.set(quoteDataDTOVM, 'baseData.periodStatus', 'Draft');
            markFollowingWizardStepsUnvisited();
        },
        [markFollowingWizardStepsUnvisited]
    );

    const mapDisputeValue = (reportList) => {
        reportList.map((report) => {
            if (report.dispute === "disputeweatherclaim") {
                _.set(report, 'dispute', "disputeother");
                _.set(report, 'explanationBox', "Weather Claim");
            }
            return report;
        });
        return reportList;
    }

    // fetch clue report data, ready for 'run validation' in quote screen'
    const fetchData = useCallback(async () => {
        // await WniVendorComparsionService.triggerClueService(submissionVM.jobID.value, submissionVM.sessionUUID.value, authHeader);
        if (
            IssuanceValidationUtil.isRequiredForIssuanceR2(
                submissionVM.value.baseData,
                wizardPageData
            )
        ) {
            const manualLossList = await hoClueService.getClueData(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                authHeader
            );
            const reportList = await hoClueService.getClueReportData(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                authHeader
            );
            const newReportList = mapDisputeValue(reportList);
            updateIsClueServiceCalled(true);
            updateManuallyClue(manualLossList);
            updateVendorClueReports(newReportList);
        }
    }, []);

    useEffect(() => {
        const {
            lobData: {
                [productName]: {
                    priorCarrierAndLossHistory_Ext: {
                        priorCarrierEnter,
                        clueEnter,
                        clueReport,
                        claimReport = [],
                    },
                },
            },
        } = submissionVM.value;
        const newClueReport = mapDisputeValue(clueReport);
        updateManuallyPriorCarrier(priorCarrierEnter);
        updateManuallyClue(clueEnter);
        updateVendorClueReports(newClueReport);
        updateVendorPolicyClaims(claimReport);
        if (!isClueServiceCalled) {
            if (isSkipping) {
                return;
            }
            fetchData();
        }
    }, [isSkipping, productName]) ;
    
    // filter dispute, current requirement need to remove 'Weather Claim' from dropdown list, and make PC 'Weather Claim' map to Portal 'Other' and Explanation 'Weather Claim',
    // in the near future, below code will be removed to meet new requirement
    const filterDisputeDropList = (path, availableValues) => {
        if (path === 'dispute') {
            const indexOfWeatherClaim = 4;
            if (availableValues[indexOfWeatherClaim].code === 'disputeweatherclaim'){
                availableValues.splice(indexOfWeatherClaim, 1);
            }
        }
    }

    const onColumnCell = useCallback((item, index, property, type) => {
        const { path, typeKey } = property;
        let flag;
        if (type === 'clue') {
            flag = false;
        }
        if (type === 'priorCarrier') {
            flag = false;
        }
        const classNames = flag && item.flag ? 'highlight' : '';
        if (item[path] && item[path].year) {
            return (
                <div className={classNames}>{WniDateUtil.formatDateWithPattern(item[path])}</div>
            );
        }
        if (item[path] && item[path].currency) {
            return (
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[path]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            );
        }
        const val = typeKey
            ? translator({ id: `${typeKey}.${item[path]}` })
            : item[path];
        return <div className={classNames}>{`${item[path] ? val : ''}`}</div>;
    }, []);

    const handleClauseOfLossChange = ({getNewModalVM, labelMap, path}) => {
        if(labelMap[path].refreshCategoryPath) {
            _.set(getNewModalVM, 'whatCauseOfLoss', null)
        }
    };

    const showModalFn = async (modalVM, modelProps) => {
        const {
            // quoteFlow_Ext, submittingQuote_Ext, periodStatus
            // eslint-disable-next-line camelcase
            quoteFlow_Ext
        } = submissionVM.value.baseData;

        // eslint-disable-next-line camelcase
        const isRequiredForIssuance =
            quoteFlow_Ext !== 'draft' && quoteFlow_Ext !== 'firstquote';

        const componentProps = {
            title: modelProps.title,
            actionType: modelProps.type,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            authHeader: authHeader,
            actionBtnLabel: translator(messages.ModalOk),
            cancelBtnLabel: translator(messages.ModalCancel),
            modalVM,
            viewModelService: viewModelService,
            modelProps: modelProps,
            displayStatus: submissionVM.value.baseData.displayStatus_Ext,
            quotedId: submissionVM.jobID.value,
            sessionUUID: submissionVM.sessionUUID.value,
            comingType: 'Submission',
            wniClueService: hoClueService,
            isRequiredForIssuance: isRequiredForIssuance,
            effectiveDate,
            productName,
            afterWriteValue: async ({ getNewModalVM, labelMap, path }) => {
                // HO manual clue: category should be drived from cause of loss, call mapping service
                if (labelMap[path].refreshCategoryPath) {
                    if (!_.isUndefined(getNewModalVM[path].value)) {
                        setLoadingMask(true);
                        const result =
                            await HOClueService.getCategoryByCauseOfLoss(
                                submissionVM.jobID.value,
                                submissionVM.sessionUUID.value,
                                getNewModalVM.value.causeOfLoss,
                                authHeader
                            );
                        setLoadingMask(false);
                        _.set(
                            getNewModalVM,
                            labelMap[path].refreshCategoryPath,
                            result
                        );
                    }
                }
            },
            filterDisputeDropList,
            handleClauseOfLossChange,
        };

        return modalApi.showModal(
            <IncidentPopupComponent {...componentProps} />
        );
    };

    const handleAddEditFn = (type, handleType, data, dtoService) => {
        const { saveService, updateService, updateTableData } = dtoService;
        let submitService = updateService;
        if (type === 'View') {
            return false;
        }
        if (type === 'Add') {
            submitService = saveService;
        }
        if (type === 'Edit') {
            submitService = updateService;
        }
        if (handleType === 'userPriorCarrier') {
            submitService(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                data,
                authHeader
            ).then((result) => {
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(
                    submissionVM,
                    `lobData.${productName}.priorCarrierAndLossHistory_Ext.priorCarrierEnter`,
                    result
                );
            });
        }
        if (handleType === 'userClue') {
            submitService(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                data,
                authHeader
            ).then((result) => {
                setWizardDataToDraft(submissionVM);
                // if (type === 'Edit') {
                //     updateClueStatus('checkMsg');
                // }
                updateTableData(result);
                _.set(
                    submissionVM,
                    `lobData.${productName}.priorCarrierAndLossHistory_Ext.clueEnter`,
                    result
                );
            });
        }
        if (handleType === 'vendorClue') {
            submitService(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                data,
                authHeader
            ).then((result) => {
                setWizardDataToDraft(submissionVM);
                // if (type === 'Edit') {
                //     updateCovPaidNewSet('checkMsg');
                // }
                const newTableResult = mapDisputeValue(result);
                updateTableData(newTableResult);
                _.set(
                    submissionVM,
                    `lobData.${productName}.priorCarrierAndLossHistory_Ext.clueReport`,
                    newTableResult
                );
            });
        }
        return false;
    };

    const handleUserPriorCarrier = () => {
        return {
            ...staticMessage.userPriorCarrier,
            dtoService: {
                saveService: hoPriorCarrierService.savePriorCarrier,
                updateService: hoPriorCarrierService.updatePriorCarrier,
                deleteService: hoPriorCarrierService.deletePriorCarrier,
                updateTableData: updateManuallyPriorCarrier
            }
        }
    }

    const handleUserClue = () => {
        return {
            ...staticMessage.userClue,
            dtoService: {
                saveService: hoClueService.saveClue,
                updateService: hoClueService.updateClue,
                deleteService: hoClueService.deleteClue,
                updateTableData: updateManuallyClue,
            },
        };
    };

    const handleVendorClue = () => {
        return {
            ...staticMessage.vendorClue,
            dtoService: {
                updateService: hoClueService.updateClueReport,
                updateTableData: updateVendorClueReports,
            },
        };
    };
    const handleRowItem = (e, rowProps, type, initialModal = {}) => {
        // e.preventDefault();
        const { item, handleType } = rowProps;

        let modalProps;
        switch (handleType) {
            case 'userPriorCarrier':
                modalProps = handleUserPriorCarrier();
                break;
            case 'userClue':
                modalProps = handleUserClue();
                break;
            case 'vendorClue':
                modalProps = handleVendorClue();
                break;
            default:
                modalProps = {};
                break;
        }
        const { labelMap, title, dtoPath, dtoService } = modalProps;
        let modalVM = {};
        if (item) {
            modalVM = viewModelService.create(item, 'pc', dtoPath);
        } else {
            modalVM = viewModelService.create(initialModal, 'pc', dtoPath);
        }
        const modelInfo = {
            labelMap: labelMap,
            type: type,
            title: `${type} ${title}`,
        };
        showModalFn(modalVM, modelInfo)
            .then((data) => {
                if (data.dispute === "disputeother" && data.explanationBox === "Weather Claim") {
                    _.set(data, 'dispute', "disputeweatherclaim");
                    _.set(data, 'explanationBox', null);
                }
                handleAddEditFn(type, handleType, data, dtoService);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    };

    const handleDeleteItem = (e, rowProps, handleType) => {
        e.preventDefault();
        const { item } = rowProps;
        modalApi.showConfirm({
                title: messages.ModaldeleteMsg,
                message: '',
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ModalOk,
                cancelButtonText: messages.ModalCancel,
            }).then((results) => {
                if (results === 'confirm') {
                    if (handleType === 'userClue') {
                        hoClueService.deleteClue(
                                submissionVM.jobID.value,
                                submissionVM.sessionUUID.value,
                                item.publicId,
                                authHeader
                            ).then((res) => {
                                setWizardDataToDraft(submissionVM);
                                updateManuallyClue(res);
                                _.set(
                                    submissionVM,
                                    `lobData.${productName}.priorCarrierAndLossHistory_Ext.clueEnter`,
                                    res
                                );
                            });
                    }
                    if (handleType === 'userPriorCarrier') {
                        hoPriorCarrierService.deletePriorCarrier(
                                submissionVM.jobID.value,
                                submissionVM.sessionUUID.value,
                                item.publicId,
                                authHeader
                            ).then((res) => {
                                setWizardDataToDraft(submissionVM);
                                updateManuallyPriorCarrier(res);
                                _.set(
                                    submissionVM,
                                    `lobData.${productName}.priorCarrierAndLossHistory_Ext.priorCarrierEnter`,
                                    res
                                );
                            });
                    }
                    return true;
                }
            }, _.noop);
    };

    const getActioncolumnFn = (item, index, handleType, readOnly) => {
        const rowProps = {
            item: item,
            index: index,
            handleType,
        };
        let classNames = '';
        if (handleType === 'userClue' || handleType === 'vendorClue') {
            // true && 'warningIcon' = 'warningIcon'
            // if trigger UWI.401/402, 'what is the cause of loss' or 'additional comment' is not filled, show warning icon
            // if trigger BR344, 'what is the cause of loss' is not filled, show warning icon
            classNames =
                (item.isWhatCauseOfLossNeedToFill
               || (item.isMitigationStepsRequiredForIssuance || item.isWhatCauseOfLossRequiredForIssuance))
               && 'warningIcon';
        }
         
        const lossCause = item.causeOfLoss === 'LiabilityAllOther' ? 'Liability (All Other)' : item.causeOfLoss;
        const isClaimDateWithinThreeYears = moment(effectiveDate).diff(item.claimDate, 'years', true) <= 3
        const showTooltipIcon = productName === 'homeowners' && (handleType === 'userClue' || handleType === 'vendorClue') 
            && (isClaimDateWithinThreeYears && (item.causeOfLoss === 'Fire' || item.causeOfLoss === 'LiabilityAllOther'))
        return (
    
            <div className={showTooltipIcon ? "wniKnockoutToolTip":null}>
                <IconButton
                    id={`view${handleType}${index}`}
                    icon="gw-visibility"
                    iconColor="dark"
                    className={classNames}
                    size="medium"
                    onClick={(e) => handleRowItem(e, rowProps, 'View')}
                />
                <IconButton
                    id={`edit${handleType}${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    className={classNames}
                    disabled={disableClueAndPriorCarrierEdit}
                    size="medium"
                    onClick={(e) => handleRowItem(e, rowProps, 'Edit')}
                />
                {!readOnly && (
                    <IconButton
                        id={`delete${handleType}${index}`}
                        icon="gw-delete"
                        iconColor="dark"
                        disabled={disableClueAndPriorCarrierEdit}
                        size="medium"
                        onClick={(e) =>
                            handleDeleteItem(e, rowProps, handleType)
                        }
                    />
                )}
                {showTooltipIcon ?
                    < TooltipIcon
                        id="wniTooltipContent"
                        title="Ineligible"
                        icon="gw-error"
                        showOnInit={showTooltipIcon}
                        text={translator(messages.causeOfLossIneligible,{lossCause: lossCause})}
                    />
                    : null}
            </div>
    
        );
    };

    const getSubUserEnterStates = () => {
        const userEnterStates = [];
        if (manuallyPriorCarrier.length > 0) {
            userEnterStates.push('userEnteredPriorCarrierCard');
        }
        if (manuallyClue.length > 0) {
            userEnterStates.push('userEnteredPriorLossesCard');
        }
        return userEnterStates;
    };
    const getSubVendorStates = () => {
        const vendorStates = [];
        if (vendorClueReports.length > 0) {
            vendorStates.push('vendorPriorLossesCard');
        }
        return vendorStates;
    };
    const getIsWhatCauseOfLossNeedToFillOFManuallyClue = () => {
        if(!_.isEmpty(manuallyClue)) {
            return _.some(manuallyClue,(item) => {
                return item.isWhatCauseOfLossNeedToFill;
            })
        }
        return false;
    };
    const getIsWhatCauseOfLossNeedToFillOFVendorClueReports = () => {
        if(!_.isEmpty(vendorClueReports)) {
            return _.some(vendorClueReports,(item) => {
                return item.isWhatCauseOfLossNeedToFill;
            })
        }
        return false;
    };

    const onPageNext = async () => {
        const errorsAndWarnings =
            await WniCommonQuoteService.getErrorsAndWarnings(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                currentPageId,
                authHeader
            );
        const newValidationIssues =
            ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        // fliter issues, only prior carrier 2th path or what is the cause of loss not filled should show error on the page top,
        // other kinds of error should only show on quote page when click 'continue with quote'/'run validation'
        const finalValidationIssues = newValidationIssues.filter(
            (item) =>
                (item.type === 'error' &&
                    item.reason === `${translator(messages.priorCarrierMissingForIssuance)}` || item.reason === `${translator(messages.whatIsTheCauseOfLossOnNext)}`)
                || (item.type === 'warning' && item.reason === `${translator(messages.lossHistory)}`)
        );
        updateValidationIssues(finalValidationIssues);

        const hasValidationError =
            ValidationIssueUtil.hasErrorInValidationIssueList(
                newValidationIssues
            );
        const hasValidationWarning =
            ValidationIssueUtil.hasWarningInValidationIssueList(
                newValidationIssues
            );
        if (hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError && (isManualPriorCarrierEmpty || getIsWhatCauseOfLossNeedToFillOFManuallyClue() || getIsWhatCauseOfLossNeedToFillOFVendorClueReports())) {
            updateShowErrors(true);
            return false;
        }
        return submissionVM;
    };

    const getAccordionCardTitle = useCallback(
        (recordLength, titlePrefix = 'PRIOR LOSSES') => {
            const isPlural = recordLength > 1;
            const suffix = isPlural ? ' RECORDS' : ' RECORD';
            const prefix =
                productName === 'dwellingProperty'
                    ? `${titlePrefix} - `
                    : `${titlePrefix} `;
            return `${prefix}${recordLength}${suffix}`;
        },
        [productName]
    );

    // converted policy NB show claim card and policychange always show claim card
    const showVendorPolicyClaims =
        policySource === 'Converted' || showPolicyClaims;
    //---------------------
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        '@action': {
            disabled: disableAddButton,
        },
        // prior carrier add manually
        userAddedPriorCarrierTable: {
            visible: manuallyPriorCarrier.length > 0,
            data: manuallyPriorCarrier,
        },
        // add button only display when no record showing up
        userProiorHeader: {
            visible: _.isEmpty(manuallyPriorCarrier),
        },
        // clue add manually
        userAddedPriorLossTable: {
            visible: manuallyClue.length > 0,
            data: manuallyClue,
        },
        // vendor clue report
        priorLossCLUETable: {
            visible: vendorClueReports.length > 0,
            data: vendorClueReports,
        },
        // vendor claim report
        claimInfo: {
            visible: showVendorPolicyClaims,
        },
        reportedPolicyClaimsTable: {
            visible: vendorPolicyClaims.length > 0,
            data: vendorPolicyClaims,
        },
        // length of item showing on UI
        userEnteredPriorCarrierCard: {
            title: getAccordionCardTitle(
                manuallyPriorCarrier.length,
                'PRIOR CARRIER'
            ),
        },
        userEnteredPriorLossesCard: {
            title: getAccordionCardTitle(manuallyClue.length),
        },
        vendorPriorLossesCard: {
            title: getAccordionCardTitle(vendorClueReports.length),
        },
        claimPriorLossesCard: {
            title: getAccordionCardTitle(vendorPolicyClaims.length),
        },
        userEnteredSubInfo: {
            accordionStates: getSubUserEnterStates(),
        },
        vendorSubInfo: {
            accordionStates: getSubVendorStates(),
        },
        clueCauseOfLossCol: {
            visible: false
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            // carrier
            handlePriorCarrierAddItem: (e) =>
                handleRowItem(e, { handleType: 'userPriorCarrier' }, 'Add'), // user carrier: add
            getUserPriorCarrierFn: (item, index) =>
                getActioncolumnFn(item, index, 'userPriorCarrier'), // vender carrier: edit/view
            // clue
            handleClueAddItem: (e) =>
                handleRowItem(e, { handleType: 'userClue' }, 'Add', {
                    source: 'userentered',
                }), // user clue: add
            getUserClueActionFn: (item, index) =>
                getActioncolumnFn(item, index, 'userClue'), // user clue: edit/view
            getVendorClueActionFn: (item, index) =>
                getActioncolumnFn(item, index, 'vendorClue', 'readOnly'), // vendor clue: view
            // common
            handleDeleteItem: handleDeleteItem, // user clue/mvr/carrier: delete
            handleRowItem: handleRowItem,
            onColumnCell: onColumnCell,
            onColumnPriorCarrierCell: (item, index, property) =>
                onColumnCell(item, index, property, 'priorCarrier'),
            onColumnClueCell: (item, index, property) =>
                onColumnCell(item, index, property, 'clue'),
            onColumnPolicyClaimsCell: (item, index, property) =>
                onColumnCell(item, index, property, 'policyClaims'),
        },
        resolveComponentMap: {
            // validationissuescomponent: ValidationIssuesComponent,
        },
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            onNext={onPageNext}
            pageLevelValidationIssues={validationIssues}
            alwaysCallOnNext
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

HOLossHistoryAndPriorCarrierPage.propTypes = {
    ...wizardProps,
    showPolicyClaims: PropTypes.bool,
    disableClueAndPriorCarrierEdit: PropTypes.bool,
    disableAddButton: PropTypes.bool,
    checkRequiredForIssuance: PropTypes.bool,
    productName: PropTypes.string,
    hoPriorCarrierService: PropTypes.object,
    hoClueService: PropTypes.object
};
HOLossHistoryAndPriorCarrierPage.defaultProps = {
    showPolicyClaims: false,
    disableClueAndPriorCarrierEdit: false,
    disableAddButton: false,
    checkRequiredForIssuance: true,
    productName: 'homeowners',
    hoPriorCarrierService: HOPriorCarrierService,
    hoClueService: HOClueService
};
export default HOLossHistoryAndPriorCarrierPage;
