import _ from 'lodash';
import { R1Config } from 'wni-portals-config-js';
import IssuanceValidationUtil from './IssuanceValidationUtil/IssuanceValidationUtil';
import ErrorsAndWarningsUtil from './ErrorsAndWarnings/ErrorsAndWarningsUtil';
import DriverUtil from './DriverUtil';
import FlowStepManager from './ErrorsAndWarnings/FlowStepManager';

function validateFieldsBlockingIssuance(
    {
        currentRow,
        baseData,
        translator,
        messages
    }
) {
    if (!currentRow) {
        return [];
    }
    let licensedMessage = {};
    if (_.get(currentRow, 'value.licensedDriver_Ext')) {
        licensedMessage = {
            driversLicenseStatus_Ext: 'Drivers License Status',
            licenseState: 'Drivers License State',
            licenseNumber: 'Drivers License Number'
        };
    }
    let otherMessage = {
        'driverQuestionSets_Ext.[0].answers.DriverLicSuspLastFiveYearsYN_Ext': translator(messages.DriverLicSuspLastFiveYearsYNWarning),
        'driverQuestionSets_Ext.[0].answers.DriverAnyCurFinResFilingYN_Ext': translator(messages.DriverAnyCurFinResFilingYNWarning)
    };
    if (!_.get(currentRow, 'licensedDriver_Ext.value')
        && _.get(currentRow, 'driverUnLicensedReason_Ext.value.code') === 'neverLicensed') {
        otherMessage = {
            'driverQuestionSets_Ext.[0].answers.DriverAnyCurFinResFilingYN_Ext': translator(messages.DriverAnyCurFinResFilingYNWarning)
        };
    }
    const fieldMessageMap = _.assign(licensedMessage, otherMessage);
    return IssuanceValidationUtil
        .getWarningMessages(currentRow, fieldMessageMap, translator,
            messages.requiredForIssuanceSchema);
}

function validateFieldsBlockingQuote(
    {
        currentRow,
        translator,
        message
    }
) {
    if (!currentRow) {
        return [];
    }
    const fieldMessageMap = {};
    return IssuanceValidationUtil
        .getErrorMessages(currentRow, fieldMessageMap, translator,
            message);
}

function hasParentRelationship(submissionVM) {
    let retVal = false;

    const drivers = _.get(submissionVM, 'value.lobData.personalAuto.coverables.drivers');
    const parentDriver = _.find(drivers, (driver) => {
        return driver.relationshipToInsured_Ext === 'Parent_Ext';
    });
    if (!_.isEmpty(parentDriver)) {
        retVal = true;
    }

    return retVal;
}

function getDriverNotifications(
    {
        submissionVM,
        currentRow,
        baseData,
        translator,
        messages
    }
) {
    const warningMessagesForField = validateFieldsBlockingIssuance({
        currentRow,
        baseData,
        translator,
        messages
    });
    const errorMessages = validateFieldsBlockingQuote({
        currentRow,
        translator,
        message: messages.requiredForQuoteSchema
    });

    // const displayName = _.get(currentRow, 'value.displayName');
    // BR.PL.0024
    // POI-13944
    // apply pc logic, so remove from portal side
    // if (_.get(currentRow, 'value.isPNI_Ext') && DriverUtil.getAge(currentRow, baseData) < 25 && !hasParentRelationship(submissionVM)) {
    //     warningMessagesForField.push(translator(messages.childPNIWarningMsg, { displayName }));
    // }
    // POI-18440 PC has added this error, so portal remove
    // if (_.get(currentRow, 'value.ageFirstLicensed_Ext') > DriverUtil.getAge(currentRow, baseData)
    //     && _.get(currentRow, 'value.licensedDriver_Ext')) {
    //     errorMessages.push(translator(messages.ageFirstLicensedErrMsg));
    // }
    return [
        ...warningMessagesForField.map((text) => {
            return {
                flowStepId: FlowStepManager.PORTAL_FLOW_STEP_PA_DRIVER_PAGE_ID,
                type: 'warning',
                reason: text
            };
        }),
        ...errorMessages.map((text) => {
            return {
                flowStepId: FlowStepManager.PORTAL_FLOW_STEP_PA_DRIVER_PAGE_ID,
                type: 'error',
                reason: text
            };
        })
    ];
}

function getAllValidationIssues(submissionVM, errorsAndWarnings, translator, messages) {
    const backendIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings) || [];
    const driverVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children', []);
    const baseData = _.get(submissionVM, 'value.baseData');
    const frontendIssues = driverVMs.flatMap((vm) => {
        return getDriverNotifications({
            submissionVM,
            currentRow: vm,
            baseData,
            translator,
            messages
        }) || [];
    });
    const quoteFlow = _.get(submissionVM, 'value.baseData.quoteFlow_Ext');
    let validationIssues = {};
    if (quoteFlow === 'draft' || quoteFlow === 'firstquote') {
        validationIssues = _.uniqBy(frontendIssues.concat(backendIssues), 'reason');
    } else {
        validationIssues = _.uniqBy(backendIssues, 'reason');
    }

    const driverValidationIssues = validationIssues.filter(ErrorsAndWarningsUtil.isIssueOnDriverPage);
    const filterIssuesType = IssuanceValidationUtil.getIssuesMap(driverValidationIssues);

    return filterIssuesType;
}

const getIssuesInDriverPage = (validationIssues) => {
    const { messagesInDriverPage } = R1Config.DriversPageConfig;
    return validationIssues.filter((issue) => !messagesInDriverPage
        .some((text) => issue.reason.includes(text)));
};

const getUpdatedIssues = (allValidationIssues, validationIssues) => {
    const issuesInThisPage = getIssuesInDriverPage(allValidationIssues);
    return _.differenceBy(issuesInThisPage, validationIssues, 'reason');
};

// POI-13775
const getPhoneNumberIssues = (currentRow, messages, translator) => {
    let message = '';
    if (_.get(currentRow, 'person.cellNumber.aspects.subtreeValid') === false) {
        message = translator(messages.mobilePhoneValidationText);
    }
    if (_.get(currentRow, 'person.homeNumber.aspects.subtreeValid') === false) {
        message = translator(messages.homePhoneValidationText);
    }
    if (_.get(currentRow, 'person.workNumber.aspects.subtreeValid') === false) {
        message = translator(messages.workPhoneValidationText);
    }
    if (_.get(currentRow, 'wniMVRsManuallyDTO_Ext.aspects.subtreeValid') === false) {
        message = translator(messages.MVRValidationText);
    }
    if (message) {
        return [{
            type: 'error',
            reason: message
        }];
    }
    return [];
};

const toBool = (val) => {
    if (typeof val === 'boolean') {
        return val;
    }
    if (val === 'false') {
        return false;
    }
    if (val === 'true') {
        return true;
    }
    return Boolean(val);
};

const getQuestionValid = (driverVM) => {
    const questionSets = _.get(driverVM, 'value.driverQuestionSets_Ext[0].answers') || {};
    const LicSuspLastFiveYearsYN = toBool(questionSets.DriverLicSuspLastFiveYearsYN_Ext);
    const LicSuspLastFiveYearsYNExplain = questionSets.DriverLicSuspLastFiveYearsYNExplain_Ext;
    const AnyCurFinResFilingYN = toBool(questionSets.DriverAnyCurFinResFilingYN_Ext);
    const AnyCurFinResFilingYNExplain = questionSets.DriverAnyCurFinResFilingYNExplain_Ext;

    if ((LicSuspLastFiveYearsYN && !LicSuspLastFiveYearsYNExplain)
    || (AnyCurFinResFilingYN && !AnyCurFinResFilingYNExplain)) {
        return false;
    }
    return true;
};

const getDriverValidForIssuance = (driverVM) => {
    let validForIssuance = true;
    if (!_.isEmpty(driverVM)) {
        const {
            licenseState,
            driversLicenseStatus_Ext: driversLicenseStatus,
            licenseNumber,
            licensedDriver_Ext: licensedDriver
        } = driverVM.value || {};
        if (licensedDriver
        && (_.isEmpty(licenseState)
        || _.isEmpty(driversLicenseStatus)
        || _.isEmpty(licenseNumber))) {
            validForIssuance = false;
        }
        if (_.isNil(_.get(driverVM, 'value.driverQuestionSets_Ext.[0].answers.DriverAnyCurFinResFilingYN_Ext'))) {
            validForIssuance = false;
        }
    }
    return validForIssuance;
};

const getDriverValid = (driverVM, validationIssues, baseData, wizardPageData) => {
    const isRequiredForIssuance = DriverUtil.isRequiredForIssuance(baseData, wizardPageData);
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(driverVM, 'value.fixedId');
    });
    if (errorIssues.length > 0) {
        return false;
    }
    if (!getQuestionValid(driverVM)
    || (isRequiredForIssuance && !getDriverValidForIssuance(driverVM))) {
        return false;
    }
    return _.get(driverVM, 'aspects.valid') && _.get(driverVM, 'aspects.subtreeValid');
};

export default {
    getDriverNotifications,
    getAllValidationIssues,
    getUpdatedIssues,
    getPhoneNumberIssues,
    getDriverValid,
    getDriverValidForIssuance,
    getQuestionValid
};
