import React from 'react';

function ModalNext(props) {
    const { isOpen, className = 'md', overlayClassName = '', children } = props;

    if (!isOpen) {
        return null;
    }
    return (
        <div className={`wni__ModalNext ${className}`}>
            <div className={`wni__ModalNext__overlay ${overlayClassName}`} />
            <div className={`wni__ModalNext__modal ${className}`}>{children}</div>
        </div>
    );
}

export default ModalNext;
