import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glSublineService'), method, data, additionalHeaders);
}

export default class GLSublineService {

    static updateSublineSelected(data, authHeader) {
        return processSubmission('updateSublineSelected', [data.jobID, data.sessionUUID, data.dto], authHeader);
    }

}