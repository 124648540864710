import _ from 'lodash';

const OTHER_BASE_FILEDS_MAPS = [
    "acLiabAggLim"
];


const generateFieldsVisible = (currentVM) => {
    const overrides = OTHER_BASE_FILEDS_MAPS.map((item) => {
        const isVisible = _.get(currentVM.value, item) === 'Other_Ext' || _.get(currentVM.value, item) === 'Other';
        if(!isVisible) {
            _.set(currentVM.value, `${item}O`, undefined);
        }
        return {
            [`${item}O`]: {
                visible: isVisible
            }
        }
    });
    return Object.assign({}, ...overrides);
};

export {
    generateFieldsVisible
}