
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';

import { WniChangeConflict } from 'wni-capability-gateway';
import { PUMultiQuoteChangeService } from 'wni-capability-policychange-pu';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';
import { PULossHistoryPage, PULossHistoryUtil } from 'wni-capability-quoteandbind-pu-react';

function PULossHistoryChangePage(props) {

    const modalApi = useWniModal();

    const useAuthenticationData = useAuthentication();
    const { authHeader } = useAuthenticationData;
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);
    const {
        wizardData: submissionVM,
        updateWizardPageData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus,
        },
        lobData: {
            personalUmbrella: {
                householdInfo: {
                    umbdrivers
                },
                lossHistory_Ext: {
                    clueenter = [],
                    mvrenter = [],
                }
            },
        },
    } = submissionVM.value;
    const [newlyAddedUMBDrivers, updateNewlyAddedUMBDrivers] = useState([]);
    const [disableAddButton, updateDisableAddButton] = useState(true);
    // we need filter duplicate manually clue/mvr for policy change
    const [filteredManualCLUE, updateFilteredManualCLUE] = useState([]);
    const [filteredManualMVR, updateFilteredManualMVR] = useState([]);

    useEffect(() => {
        // if UMBDriver basedOnFlag is false, it represent the driver is newly added
        const filteredUMBDrivers = umbdrivers.filter((driver) => !driver.basedOnFlag);
        updateNewlyAddedUMBDrivers(filteredUMBDrivers);
        if (filteredUMBDrivers.length > 0){
            updateDisableAddButton(false);
        } else {
            updateDisableAddButton(true);
        }
        const filteredCLUE = PULossHistoryUtil.filterCLUEManuallyData(clueenter);
        const filteredMVR = PULossHistoryUtil.filterMVRManuallyData(mvrenter);
        updateFilteredManualCLUE(filteredCLUE);
        updateFilteredManualMVR(filteredMVR);
    }, [clueenter, mvrenter, umbdrivers]) ;

    const hideOrDisableIcons = (item, handleType) => {
        const iconFlag = {hideEditDeleteIcon: false, disableReportsEditIcon: false};
        if (handleType === 'userClue' || handleType === 'userMVR') {
            if (item.basedOnFlag) {
                iconFlag.hideEditDeleteIcon = true;
            } else {
                iconFlag.hideEditDeleteIcon = false;
            }
        };
        if (handleType === 'vendorClue' || handleType === 'vendorMVR') {
            if (item.basedOnFlag) {
                iconFlag.disableReportsEditIcon = true;
            } else {
                iconFlag.disableReportsEditIcon = false;
            }
        }
        return iconFlag
    }
    const showChangeConflictsModal = useCallback(
        (modalVM) => {
            const baseVM = modalVM.baseData;
            const policyNumber = _.get(modalVM.value, 'changeData.policyNumber');
            const componentProps = {
                title: 'Change Conflicts',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'Submit',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                authHeader: authHeader,
                baseVM,
                jobID,
                policyNumber,
            };
            return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
        },
        [authHeader, jobID]
    );

    const goToNext = async () => {
        if (periodStatus === 'Draft') {
            updateWizardPageData({
                [WizardConstants.sideBySideData]: undefined,
                [WizardConstants.policyDiffData]: undefined,
                [WizardConstants.policyChangeDocumentsData]: undefined,
            });
            setLoadingMask(true)
            submissionVM.value = await PUMultiQuoteChangeService.processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM;
    };

    const handleConflict = async () => {
        const conflictRes = await WniChangeConflict.getChangeConflictData(submissionVM.jobID.value, authHeader);
        if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
            setLoadingMask(false)
            const newSubmissionVM = await showChangeConflictsModal(submissionVM)
                .then(goToNext)
                .catch(() => {
                    _.noop();
                    return false;
                });
            return newSubmissionVM;
        }
        return goToNext();
    }

    const onChangePageNext = async () => {
        return handleConflict()
    }

    return (
        <PULossHistoryPage {...props}
            disableAddButton={disableAddButton}
            newlyAddedUMBDrivers={newlyAddedUMBDrivers}
            hideOrDisableIcons={hideOrDisableIcons}
            filteredManualCLUE={filteredManualCLUE}
            filteredManualMVR={filteredManualMVR}
            onIncidentsPageNext={onChangePageNext} 
        />
    );

}

PULossHistoryChangePage.propTypes = PULossHistoryPage.propTypes;
PULossHistoryChangePage.defaultProps = PULossHistoryPage.defaultProps;

export default PULossHistoryChangePage;