import React, {useState, useCallback, useEffect} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DropdownMenuHeader, DropdownMenuSeparator } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { AddressInputComponent, IndustryCodePopup } from 'wni-capability-gateway-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages } from '@xengage/gw-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import DBAComponent from '../DBAComponent/DBAComponent';
import metadata from './ANIDetailComponent.metadata.json5';

import { DropdownMenuLink } from '@jutro/router';

function ANIDetailComponent(props) {
    const {
        currentRow,
        onValueChange,
        isReadOnly,
        isRequiredForIssuance,
        updateCurrentRow,
        originCurrentRow,
        ANIsVM,
        saveAddlInsured,
        organizationTypesOptions,
        accountContacts,
        policyDetailPath,
        policyDetailsService,
        authHeader,
        sessionUUID,
        jobID,
        viewModelService
    } = props;

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('addlInsuredForm');

    const {
        authUserData: {
            businessData_Ext: {
                industryCodes = []
            } = {}
        } = {}
    } = useAuthentication();

    const modalApi = useWniModal();

    const [showErrors, updateShowErrors] = useState(false);
    const [isFEINDisable, updateIsFEINDisable] = useState(false);
    const [isSSNDisable, updateIsSSNDisable] = useState(false);

    const {
        company: {
            feinofficialID_Ext: feinofficialID,
            ssnofficialID_Ext: ssnofficialID,
            organizationTypes_Ext: organizationTypes
        } = {},
        contactCode_Ext: contactCode
    } = currentRow.value;

    const IsValidFields = useCallback(() => {
        if (!currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            return false;
        }
        const fein = _.get(currentRow, 'value.company.feinofficialID_Ext')
        if (fein && fein.indexOf("#") !== -1) {
            return false
        }
        return true;
    }, [currentRow]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, currentRow]);

    const getSSNVisible = useCallback(() => {
        return _.includes(organizationTypes, 'individual_ext')
            || _.includes(organizationTypes, 'soleproprietorship_ext');

    }, [organizationTypes]);

    useEffect(() => {
        if (contactCode === 'Company') {
            if (getSSNVisible()) {
                if (feinofficialID) {
                    updateIsSSNDisable(true);
                } else {
                    updateIsSSNDisable(false);
                }
                if (ssnofficialID) {
                    updateIsFEINDisable(true);
                } else {
                    updateIsFEINDisable(false);
                }
            } else {
                updateIsSSNDisable(false);
                updateIsFEINDisable(false);
                _.set(currentRow, 'company.ssnofficialID_Ext', '');
                updateCurrentRow(currentRow);
            }
        }
    }, [feinofficialID, ssnofficialID, organizationTypes, currentRow]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const writeValue = useCallback((value, path) => {
        if(currentRow) {
            _.set(currentRow, path, value);
            updateCurrentRow(currentRow);
        }
        onValueChange(value, path);
    }, [currentRow]);

    const cancel = () => {
        let originANIs
        if (_.get(originCurrentRow, 'value.publicID')) {
            updateCurrentRow(originCurrentRow);
            originANIs = _.map(ANIsVM.value || [], (ANI) => {
                if (ANI.publicID === originCurrentRow.value.publicID) {
                    return originCurrentRow.value
                }
                return ANI;
            })
        } else {
            originANIs = _.filter(ANIsVM.value, (item) => {
                return item.publicID
            })
        }
        onValueChange(originANIs, `${policyDetailPath}.additionalInsureds`);
        updateCurrentRow(null);
    };

    const buildAddressList = () => {
        const addressList = _.map(accountContacts, (accountContact) => {
            return {
                id: `contact-${accountContact.publicID}`,
                name: accountContact.displayName,
                subMenuList: [
                    {
                        id: `address-${_.get(accountContact, 'primaryAddress.publicID')}`,
                        name: _.get(accountContact, 'primaryAddress.addressLine1'),
                        address: _.get(accountContact, 'primaryAddress')
                    }
                ]
            }
        })
        return addressList;
    };

    const selectAddress = (address) => {
        if (contactCode === 'Company') {
            _.set(currentRow, 'company.primaryAddress', address)
        }
        if (contactCode === 'Person') {
            _.set(currentRow, 'person_Ext.primaryAddress', address)
        }
        onValueChange();
    }

    const renderContent = () => {
        return buildAddressList().map((item, index) => {
            const subMenuList = item.subMenuList || []
            return (
                <>
                    <DropdownMenuHeader title={item.name} key={item.id}>
                        {!_.isEmpty(subMenuList) &&
                            subMenuList.map((v, i) => {
                                return (
                                    <DropdownMenuLink key={item.id} onClick={(e) => { selectAddress(v.address) }}>
                                        {v.name}
                                    </DropdownMenuLink>
                                );
                            })}
                    </DropdownMenuHeader>
                    <DropdownMenuSeparator />
                </>
            );
        });
    }

    const searchIndustry = () => {
        const industryPath = 'industryCode';
        const modalProps = {
            title: 'NAICS Lookup',
            model: currentRow[industryPath],
            actionBtnLabel: messages.ok,
            cancelBtnLabel: messages.cancelModel,
        };
        modalApi.showModal(<IndustryCodePopup {...modalProps} />).then((res) => {
            const newValue = {
                code: res.code,
                classification: res.classification
            }
            writeValue(newValue, industryPath)
        }).catch(_.noop);
    };

    const getNDAvailableValues = () => {
        const instryCode = _.get(currentRow.value, 'industryCode.code');
        const filterIndustryByCode = industryCodes.filter((item) => item.code === instryCode) || [];
        return filterIndustryByCode.map((item) => {
            return {
                name: item.classification,
                code: item.classification
            }
        })
    };

    const updateDBAsVM = (DBAsVM) => {
        _.set(currentRow, 'dbalist_Ext', DBAsVM);
        updateCurrentRow(currentRow);
        onValueChange();
    }

    //---------------------
    const overrides = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        companyDetail: {
            visible: contactCode === 'Company'
        },
        personDetail: {
            visible: contactCode === 'Person'
        },
        naicsCode: {
            readOnly: true,
            visible: false
        },
        naicsCodeSearchButton: {
            onClick: () => searchIndustry(),
            visible: false // requirement update, editable -> non editable
        },
        naicsDescription: {
            availableValues: getNDAvailableValues(),
            readOnly: true,
            visible: false
        },
        naicsDescriptionSearchButton: {
            onClick: () => searchIndustry('description'),
            visible: false // requirement update, editable -> non editable
        },
        FEIN: {
            disabled: isFEINDisable
        },
        SSN: {
            visible: getSSNVisible(),
            disabled: isSSNDisable
        },
        organizationType: {
            availableValues: organizationTypesOptions,
            // defaultValue: organizationTypes
        },
        addressContainer: {
            model: currentRow,
            dataPath: contactCode === 'Company' ? 'company.primaryAddress' : 'person_Ext.primaryAddress',
            onAddressChange: writeValue,
            showErrors,
            unAutoFilled: {
                addressLine1: true,
                postalCode: false
            },
            readOnly: isReadOnly,
            unRequiredField: {
                county: true
            },
            onValidate,
            hideFieldType: {
                addressType: true
            },
            defaultCountryRequired: false,
            isCLine: true
        },
        addressDropdownButton: {
            readOnly: true
        },
        dropdownMenuButton: {
            content: renderContent(),
            visible: !isReadOnly
        },
        save: {
            visible: !isReadOnly
        },
        dbaTableContainer: {
            model: _.get(currentRow, 'dbalist_Ext'),
            currentPcr: currentRow,
            isReadOnly,
            accountContacts,
            onValueChange,
            updateDBAsVM,
            policyDetailsService,
            authHeader,
            sessionUUID,
            policyDetailPath,
            jobID,
            viewModelService
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            cancel,
            save: isComponentValid ? saveAddlInsured : handleValidation
        },
        resolveComponentMap: {
            addressInputComponent: AddressInputComponent,
            dbaComponent: DBAComponent
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default ANIDetailComponent;
