import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('rtVehicleService'), method, data, additionalHeaders);
}

export default class RTVehicleService {

    /**
     * Invokes RTVehiclesHandler.updateVehicle()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {object} vehicle
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateVehicle(quoteID, sessionUUID, vehicle, authHeader = {}) {
        return processSubmission('updateVehicle', [quoteID, sessionUUID, vehicle], authHeader);
    }

    /**
     * Invokes RTVehiclesHandler.removeVehicle()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Array} publicIDs
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeVehicle(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('removeVehicle', [quoteID, sessionUUID, publicIDs], authHeader);
    }

    static postOnChangeAction(quoteID, sessionUUID, vehicle, fieldName, authHeader = {}) {
        return processSubmission('postOnChangeAction', [quoteID, sessionUUID, vehicle, fieldName], authHeader);
    }

    static buildOptionsRange(quoteID, sessionUUID, vehicle, fieldName, authHeader = {}) {
        return processSubmission('buildOptionsRange', [quoteID, sessionUUID, vehicle, fieldName], authHeader);
    }
    
    static viewEditVehicle(publicID, jobID, sessionUUID, authHeader = {}) {
        return processSubmission('viewEditVehicle', [jobID, sessionUUID, publicID], authHeader);
    }

    static onPageNext(requestData, authHeader = {}) {
        return processSubmission('onPageNext', [requestData.jobID, requestData.sessionUUID], authHeader);
    }
    
    static getAdditionalInterestType(quoteID, sessionUUID, publicID, contactType, authHeader) {
        return processSubmission('getAdditionalInterestType', [quoteID, sessionUUID, publicID, contactType], authHeader);
    }
    
}
