import React, {
    useContext,
    useCallback,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { Button } from '@jutro/components';
import {wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';

import { BaseAdditionalInterestDetailComponent } from 'wni-capability-common-react';
import styles from './MortgageeAdditionalInterestsComponent.module.scss';
import metadata from './MortgageeAdditionalInterestsComponent.metadata.json5';
import messages from './MortgageeAdditionalInterestsComponent.messages';

function MortgageeAdditionalInterestsComponent(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        lobDataProductKey: LOB_NAME,
        dtoVMPath: DTOVM_PATH,
        cpProductParams,
        onValidate,
        showErrors,
        readOnly: readOnlyMode
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            accountContacts_Ext: accountContacts
        },
    } = submissionVM.value;

    const {
        otherBuildingsMortgageeCount,
        additionalInterests,
        createOrUpdateAdditionalInterest,
        removeAdditionalInterests
    } = cpProductParams;

    const translator = useTranslator();
    const { loadingMask: { setLoadingMask }, workflowType }= useDependencies(['workflowType', 'loadingMask']);
    const [selection, updateSelection] = useState([]);
    const viewModelService = useContext(ViewModelServiceContext);

    const [currentRow, updateCurrentRow] = useState(null);
    const emptyVM = viewModelService.create({}, 'pc', DTOVM_PATH);
    const typeListsAvailableValues = _.get(emptyVM, 'type.aspects.availableValues', []);
    const [viewEditType, updateViewEditType] = useState('');
    const [validationIssues, updateValidationIssues] = useState([]);

    const createEmptyVM = useCallback(() => {
        const newAdditionalInterestDTOVM = viewModelService.create({
            policyAdditionalInterest: {
                primaryAddress: '',
            }
        }, 'pc', DTOVM_PATH);
        return newAdditionalInterestDTOVM;
    }, [DTOVM_PATH, viewModelService]);

    const viewOrEditVehicleFn = useCallback((value) => {
        const rowVM = viewModelService.create(value, 'pc', DTOVM_PATH);
        updateCurrentRow(rowVM);
        updateViewEditType('edit');
    }, [DTOVM_PATH, viewModelService]);

    const renderViewEditDom = useCallback(() => {
        return (
            <div className="activeRow btn-link">
                {translator(messages.viewAndEditLabel)}
            </div>
        );
    }, [translator]);

    const addAdditionalInterestFn = useCallback(() => {
        if(otherBuildingsMortgageeCount + additionalInterests.length >= 3){
            const allValidationIssues =  [{
                type: 'error',
                reason: translator(messages.mortgageeSizeLimitationWarning)
            }]
            updateValidationIssues(allValidationIssues);
            return
        }
        const newAdditionalInterestDTOVM = createEmptyVM();
        updateCurrentRow(newAdditionalInterestDTOVM);
        updateViewEditType('');
    }, [createEmptyVM, translator, additionalInterests, otherBuildingsMortgageeCount]);

    const updateCurrentEditingAdditionalInterest = useCallback((value, path) => {
        _.set(currentRow, path, value);
        updateCurrentRow(currentRow);
    }, [currentRow]);

    const handleSaveAdditionalInterestFn = useCallback(async (getAdditionalInterestVM, innerIsComponentValid, handleValidation) => {
        // same logic as BaseAdditionalInterestComponet#handleSave
        if (!innerIsComponentValid) {
            handleValidation();
            return false;
        }
        if (_.get(getAdditionalInterestVM, 'value.policyAdditionalInterest.phoneRequired_Ext')) {
            _.set(getAdditionalInterestVM, 'value.policyAdditionalInterest.phoneRequired_Ext', false);
        }
        // set sendBill_Ext default false value when it is null
        if (_.isNil(_.get(getAdditionalInterestVM, 'value.sendBill_Ext'))) {
            _.set(getAdditionalInterestVM, 'value.sendBill_Ext', false);
        }
        setLoadingMask(true);
        await createOrUpdateAdditionalInterest(_.get(getAdditionalInterestVM, 'value'));
        updateSelection([]);
        updateCurrentRow(null);
        setLoadingMask(false);
    }, [createOrUpdateAdditionalInterest, setLoadingMask])

    const handleCancelFn = useCallback(() => {
        updateCurrentRow(null);
    }, [])

    const removeAdditionalInterestFn = useCallback(() => {
        modalApi.showConfirm({
            title: messages.removeAdditionalInterestTitle,
            message: messages.removeAdditionalInterestDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.deletionModalOk,
            cancelButtonText: messages.deletionModalCancel
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                setLoadingMask(true);
                await removeAdditionalInterests(selection);
                updateSelection([]);
                updateCurrentRow(null);
                updateValidationIssues([])
                return true;
            }, _.noop
        ).finally(() => {
            setLoadingMask(false);
        });
    }, [modalApi, setLoadingMask, removeAdditionalInterests, selection]);

    const handleAdditionalInterestTypeValueChangeFn = useCallback((
        newValue,
        vmService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        writeValue
    ) => {
        if (newValue !== 'CP7FIRSTMORTGAGEE_Ext') {
            const newAdditionalInterestVM = vmService.clone(getAdditionalInterestVM);
            _.set(newAdditionalInterestVM, 'type', newValue);
            _.set(newAdditionalInterestVM, 'sendBill_Ext', false);
            updateAdditionalInterestVM(newAdditionalInterestVM);
        } else {
            writeValue(newValue, 'type');
        }
    }, []);

    const handleCustomActionFooterFn = useCallback((
        companyType, showBankDetail, readOnly, showBankSearch, newSearchBtnLabel,
        onReject, cancelBtnLabel, handleSave, actionBtnLabel, selectRow,
        handleSelectBank, getAdditionalInterestVM, innnerIsComponentValid, handleValidation
    ) => {
        const actionLabel = 'Save & Close';
        if (companyType === 'Bank') {
            let btns = null;
            if (showBankDetail) {
                btns = (
                    <Flex justifyContent="between" className="mt-10">
                        <Button onClick={() => handleCancelFn()} variant="secondary" label={cancelBtnLabel}/>
                        <div>
                            <Button disabled={readOnly} onClick={showBankSearch} variant="primary" label={newSearchBtnLabel}/>
                            <Button disabled={_.isEmpty(companyType) || readOnly} onClick={() => handleSaveAdditionalInterestFn(getAdditionalInterestVM, innnerIsComponentValid, handleValidation)} variant="primary" label={actionLabel}/>
                        </div>
                    </Flex>
                );
            } else {
                btns = (
                    <Flex justifyContent="between" className="mt-10">
                        <Button onClick={() => handleCancelFn()} variant="secondary" label={cancelBtnLabel}/>
                        <Button disabled={_.isEmpty(selectRow) || readOnly} onClick={handleSelectBank} variant="primary" label={actionBtnLabel}/>
                    </Flex>
                );
            }
            return btns;
        }
        return (
            <Flex justifyContent="between" className="mt-10">
                <Button onClick={() => handleCancelFn()} variant="secondary" label={cancelBtnLabel}/>
                <Button disabled={_.isEmpty(companyType) || readOnly} onClick={() => handleSaveAdditionalInterestFn(getAdditionalInterestVM, innnerIsComponentValid, handleValidation)} variant="primary" label={actionLabel}/>
            </Flex>
            
            
        );
    }, [handleCancelFn, handleSaveAdditionalInterestFn]);

    const renderInterestType = useCallback((item, index, property) => {
        const typeValue = _.get(item, property.path);
        let value = '';
        if (typeListsAvailableValues !== null) {
            const interestTypeKey = _.find(typeListsAvailableValues, (ttx) => ttx.code === typeValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [translator, typeListsAvailableValues]);

    const renderYesNo = useCallback((item, index, property) => {
        const typeValue = _.get(item, property.path);
        const value = typeValue ? 'Yes' : 'No';
        return value;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                labelPosition: 'left',
            },
            removeAdditionalInterest: {
                onClick: removeAdditionalInterestFn,
                disabled: selection.length <= 0,
                visible: !readOnlyMode
            },
            addAdditionalInterest: {
                onClick: addAdditionalInterestFn,
                visible: !readOnlyMode
            },
            additionalInterestTable: {
                noDataText: translator(messages.noDataText),
                data: additionalInterests,
                onSelectionChange: (rows) => updateSelection(rows),
                rowIdPath: 'fixedId',
            },
            interestType: {
                path: 'type',
                renderCell: renderInterestType
            },
            sendBill: {
                path: 'sendBill_Ext',
                renderCell: renderYesNo,
            },
            loanNumber: {
                path: 'contractNumber',
            },
            viewOrEdit: {
                label: renderViewEditDom(),
                onClick: viewOrEditVehicleFn,
                disabled: currentRow,
                visible: !readOnlyMode
            },
            currentEditingAddtionalInterest: {
                visible: currentRow != null,
                model: currentRow,
                emptyVM: emptyVM,
                onValueChange: updateCurrentEditingAdditionalInterest,
                showErrors,
                jobID,
                sessionUUID,
                handleSaveAdditionalInterestFn,
                handleCancelFn,
                accountContacts,
                viewEditType,
                workflowType,
                handleAdditionalInterestTypeValueChangeFn,
                handleCustomActionFooterFn,
                showLoanNumber: true,
                productCode: LOB_NAME,
                cpProductParams
            },
        };
    }, [removeAdditionalInterestFn, selection.length, readOnlyMode, addAdditionalInterestFn, translator, additionalInterests, renderInterestType, renderYesNo, renderViewEditDom, viewOrEditVehicleFn, currentRow, emptyVM, updateCurrentEditingAdditionalInterest, showErrors, jobID, sessionUUID, handleSaveAdditionalInterestFn, handleCancelFn, accountContacts, viewEditType, workflowType, handleAdditionalInterestTypeValueChangeFn, handleCustomActionFooterFn, LOB_NAME, cpProductParams]
    );

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            additionalinterestdetailcomponent: BaseAdditionalInterestDetailComponent
        }
    };

    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </>
    );
}

MortgageeAdditionalInterestsComponent.propTypes = {
    ...wizardProps,
    lobDataProductKey: PropTypes.string,
    dtoVMPath: PropTypes.string,
    cpProductParams: PropTypes.shape({
        additionalInterests: PropTypes.array,
        createOrUpdateAdditionalInterest: PropTypes.func.isRequired,
        removeAdditionalInterests: PropTypes.func.isRequired
    }).isRequired
};

MortgageeAdditionalInterestsComponent.defaultProps = {
    cpProductParams: {
        additionalInterests: [],
        createOrUpdateAdditionalInterest: _.noop,
        removeAdditionalInterests: _.noop
    }
};

export default MortgageeAdditionalInterestsComponent;
