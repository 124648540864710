import React from 'react';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { withRouter } from 'react-router-dom';
import { PEFNOLPALossTypePage } from 'wni-capability-fnol-pa-react';
import wizardConfig from '../../config/fnol-wt-wizard-config.json5';

function FNOLWTLossTypePage(props) {
    return <PEFNOLPALossTypePage {...props}
        wizardConfig={wizardConfig}
    />;
} 

FNOLWTLossTypePage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
};

FNOLWTLossTypePage.propTypes = wizardProps;
export default withRouter(FNOLWTLossTypePage);