import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    PaymentUtil,
    PaymentDetailsDataUtil, // as AQPaymentPageUtil,
} from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BaseAddPaymentMethodPopup } from 'wni-capability-common-react';
import { useWniModal } from 'wni-components-platform-react';
import NewPersonComponentV2 from '../NewPersonV2/NewPersonComponentV2';

import styles from './PaymentDetailEditComponent.module.scss';
import metadata from './PaymentDetailEditComponent.metadata.json5';
import messages from './PaymentDetailEditComponent.messages';


function getDueDayOfMonthOptions() {
    const options = [...Array(31).keys()].map((i) => ({
        code: i + 1,
        name: i + 1,
    }));
    return options;
};

function getAccountContactID(accountContactDTO) {
    if (!accountContactDTO) {
        return undefined;
    }
    return accountContactDTO.publicID || JSON.stringify(accountContactDTO);
}

function contactToPayerOption(accountContactDTO) {
    const code = getAccountContactID(accountContactDTO);
    const retval = {
        code,
        name: _.get(accountContactDTO, 'displayName'),
        accountContactDTO,
    };
    return retval;
}

function PaymentDetailEditComponent(props) {
    const modalApi = useWniModal();
    const {
        paymentDetailDataModel,
        updatePaymentDetailDataModel: updateModelInternal,
        //
        // ========================
        // rePopupEditQuoteRow,
        fetchedAccountTableData,
        // quoteTableData,
        // ========================
        // onInitBindData,
        bindData,
        onUpdatePaymentInstrument,
        onCreateOrUpdateBillingContact,
        //
        // beforeAddNewPerson,
        // beforeAddNewBankAccount,
        // beforeAddNewPaymentMethod,
        // onSavePaymentDetails,
        //
        // afterAddNewPerson,
        // afterAddNewBankAccount,
        // afterAddNewPaymentMethod,
        // afterAddMethodInitParams,
        //
        breakpoint
    } = props;

    const useDependenciesData = useDependencies(['loadingMask', 'workflowType']);
    const { loadingMask: { setLoadingMask } } = useDependenciesData;

    const useAuthenticationData = useAuthentication();
    const translator = useTranslator();

    const viewModelService = useContext(ViewModelServiceContext);

    const [hasSelectAutoPay, updateHasSelectAutoPay] = useState(false);
    // const [newAddedPrimaryPayer, updateNewAddedPrimaryPayer] = useState(undefined);

    const [primaryPayerAvailableValues, updatePrimaryPayerAvailableValues] = useState([]);
    const [primaryPayer, updatePrimaryPayerInternal] = useState(undefined);

    // Used as params to be fed to afterAddNewPerson()/afterAddNewBankAccount()/afterAddNewPaymentMethod()
    // const [selectedItem, updateSelectedItem] = useState({});

    const [paymentMethodsOptions, updatePaymentMethodsOptions] = useState([]);
    const [paymentMethodValue, updatepaymentMethodValue] = useState('');
    const [paymentPlanOptions, updatePaymentPlanOptions] = useState([]);

    // const [applyToOtherQuotesAvailableValues, updateapplyToOtherQuotesAvailableValues] = useState([]);
    // const [applyToOtherQuotesValue, updateApplyToOtherQuotesValue] = useState([]);

    const updatePaymentDetailDataModel = useCallback((newProps) => {
        const newModel = {
            ...paymentDetailDataModel,
            ...newProps,
        };
        updateModelInternal(newModel);
    }, [paymentDetailDataModel, updateModelInternal]);

    const updatePrimaryPayer = useCallback((newPrimaryPayerOption) => {
        
        updatePrimaryPayerInternal(newPrimaryPayerOption);

        const contact = newPrimaryPayerOption.accountContactDTO;

        // set phoneRequired_Ext flag to false to avoid dto error
        _.set(contact, 'phoneRequired_Ext', false);


        _.set(paymentDetailDataModel, 'primaryPayer', contact);

    }, [paymentDetailDataModel]);

    const resetPrimaryPayerOptions = useCallback((bindingDataDTO, newPerson) => {

        const {
            nonBillingContacts_Ext: nonBillingContacts,
            billingContacts_Ext: billingContacts,
        } = bindingDataDTO;
        
        
        const nonBillingContactOptions = nonBillingContacts.map(contactToPayerOption);
        
        const billingContactOptions = billingContacts.map(contactToPayerOption);

        const primaryPayerOptions = [...nonBillingContactOptions, ...billingContactOptions];
        
        const newPersonCode = getAccountContactID(newPerson);
        const newPersonExists = primaryPayerOptions.some((payerOption) => {
            // const optionCode = getAccountContactID(payerOption);
            const { code: optionCode } = payerOption;
            return optionCode === newPersonCode;
        });

        if (newPerson && !newPersonExists) {
            const newPersonOption = contactToPayerOption(newPerson);
            primaryPayerOptions.push(newPersonOption);
        }
        primaryPayerOptions.push({
            code: 'Add New Person',
            name: 'Add New Person'
        });
        updatePrimaryPayerAvailableValues(primaryPayerOptions);
    }, []);

    const initBindData = useCallback(async(bindingDataDTO) => {
        setLoadingMask(true);
        // const bindData = await onInitBindData();

        const {
            payInstrucments_Ext: payInstrucments,
            paymentPlans_Ext: newPaymentPlans,
            billingContacts_Ext: billingContacts,
            // nonBillingContacts_Ext: nonBillingContacts,

        } = bindingDataDTO;

        // paymentMethods
        // const payInstrucments = _.get(res, 'bindData.payInstrucments_Ext');
        const newPaymentMethods = PaymentUtil.initPaymentMethods(
            payInstrucments, viewModelService
        );
        updatePaymentMethodsOptions(newPaymentMethods);
        
        // paymentPlans
        const newPaymentPlanOptions = _.map(newPaymentPlans, (row) => {
            return PaymentUtil.forMatterPaymentPlan(row);
        });
        updatePaymentPlanOptions(newPaymentPlanOptions);

        // set up default primaryPayer_Ext
        let defaultPrimaryPayer = _.get(paymentDetailDataModel, 'primaryPayer');


        if (defaultPrimaryPayer) {
            const newPaymentProps = { ...paymentDetailDataModel };
            // set phoneRequired_Ext flag to false to avoid dto error
            _.set(newPaymentProps, 'primaryPayer.phoneRequired_Ext', false);

            const defaultPrimaryPayerOption = contactToPayerOption(defaultPrimaryPayer);
            updatePrimaryPayer(defaultPrimaryPayerOption);
            resetPrimaryPayerOptions(bindingDataDTO, defaultPrimaryPayer);

            updateModelInternal(newPaymentProps);
        } else {
            defaultPrimaryPayer = '-';
            resetPrimaryPayerOptions(bindingDataDTO);
        }

        const isAutoPay = _.get(paymentDetailDataModel, 'isAutoPay');
        if (isAutoPay) {
            const payUsing = _.get(paymentDetailDataModel, 'payUsing');
            updatepaymentMethodValue(payUsing);
        } else {
            updatepaymentMethodValue('Check');
        }

        setLoadingMask(false);
    }, [paymentDetailDataModel, resetPrimaryPayerOptions,
        setLoadingMask, updatePaymentDetailDataModel, updatePrimaryPayer, viewModelService]);

    useEffect(() => {
        if (!bindData) {
            return;
        }
        initBindData(bindData);
    }, [bindData]);

    const innerNewPersonPopup = useCallback(async () => {
        const modalProps = {
            title: translator(messages.newPersonTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            //
            primaryPayerAvailableValues,
            //
            requireProductsContainer: false,
            //
            onCreateOrUpdateBillingContact,
            //
            useAuthenticationData,
            useDependenciesData,
            viewModelService,
        };

        // await beforeAddNewPerson();

        // // let newAddPrimaryPayer = null;
        // // const newPerson = await ModalNextProvider.showModal(<NewPersonComponentV2 {...modalProps} />).catch(_.noop);
        // const newAccountContactDTO = await ModalNextProvider.showModal(<NewPersonComponentV2 {...modalProps} />).catch(_.noop);
        // // newAddPrimaryPayer = newPerson;

        // updateNewAddedPrimaryPayer(newAccountContactDTO);
        await modalApi.showModal(<NewPersonComponentV2 {...modalProps} />).catch(_.noop);

        // await afterAddNewPerson(newAccountContactDTO);
    }, [onCreateOrUpdateBillingContact, primaryPayerAvailableValues, translator]);

    const onNewPersonClick = useCallback(async () => {
        return innerNewPersonPopup();
    }, [innerNewPersonPopup]);


    const primaryPayerChange = useCallback((value) => {
        if (value.code === 'Add New Person') {
            onNewPersonClick();
        } else {
            updatePrimaryPayer(value);
        }
    }, [updatePrimaryPayer, onNewPersonClick]);


    const writeValue = useCallback((value, path) => {
        // const newModel = _.cloneDeep(paymentDetailDataModel);
        // _.set(newModel, `${path}`, value);
        // updateModel(newModel);
        const newProps = {
            [path]: value,
        };
        updatePaymentDetailDataModel(newProps);
    }, [updatePaymentDetailDataModel]);


    const showAddpaymentMethodPopup = useCallback(async (getPaymentDetailVM) => {
        const componentProps = {
            title: translator(messages.addPaymentMethodTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            paymentDetailVM: getPaymentDetailVM,
            viewModelService: viewModelService,
            breakpoint
        };
        const newPaymentDetailsDTOVM =  await modalApi.showModal(<BaseAddPaymentMethodPopup {...componentProps} />).catch(_.noop);
        return newPaymentDetailsDTOVM;
    }, [breakpoint, translator, viewModelService]);

    const popupNewBankAccount = useCallback(async (
        // isTemporaryForPortal = false
    ) => {
        // popup a window to enter
        const paymentDetails = {
            paymentMethod: 'autopay_Ext',
            bankAccountData: {
                bankAccountType: 'checking'
            }
        };
        const popupOneTimePaymentMethodVM = viewModelService.create(paymentDetails, 'pc',
            'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');

        const {
            payUsing = '',
            payUsingName = '',
            // payUsingTemporaryOptions: temporaryOptions = [],
        } = paymentDetailDataModel;

        const {
            payUsingTemporaryOptions: temporaryOptions = [],
        } = paymentDetailDataModel;

        // ========================
        // let code = payUsing;
        const updatedPaymentDetailsDTOVM = await showAddpaymentMethodPopup(popupOneTimePaymentMethodVM).catch(_.noop);

        // Try to find payment instrument in existing BindDataDTO
        const existingPaymemntInstrument = PaymentUtil.checkPaymentMethodExist(
            updatedPaymentDetailsDTOVM, paymentMethodsOptions
        );
        if (_.isEmpty(existingPaymemntInstrument)) {
            
            const updatedBankAccountData = _.get(updatedPaymentDetailsDTOVM, 'value.bankAccountData');

            await onUpdatePaymentInstrument(updatedBankAccountData);
            return;
            
        }
        const code = _.get(existingPaymemntInstrument, 'value.bankAccountData.publicID') || JSON.stringify(_.get(existingPaymemntInstrument, 'value.bankAccountData'));

        // =============================================================================================================
        // update options and value
        const newPaymentDetailProps = {};
        if (!_.isEmpty(code)) {
            _.set(newPaymentDetailProps, 'isAutoPay', true);

            const payInstrucments = _.get(paymentDetailDataModel, 'bindData.payInstrucments_Ext', []);


            const payInstrument = _.find(payInstrucments, (instrucment) => _.get(instrucment, 'publicID_Ext') === code);
            if (payInstrument) {
                const bankAccountNumber = _.get(payInstrument, 'accountNumber_Ext');
                _.set(newPaymentDetailProps, 'payUsingName', `AutoPay(BankAccount-${bankAccountNumber.substring(bankAccountNumber.length - 4)})`, 'payUsingName');
            } else {
                _.set(newPaymentDetailProps, 'payUsingName', payUsingName);
            }
        }


        _.set(newPaymentDetailProps, 'payUsing', code);


        // if (isTemporaryForPortal) {

        //     _.set(newPaymentDetailProps, 'payUsingTemporaryOptions', temporaryOptions);

        // }

        updatePaymentDetailDataModel(newPaymentDetailProps);
        // await afterAddNewPaymentMethod(selectedItem);
    }, [onUpdatePaymentInstrument, paymentDetailDataModel, paymentMethodsOptions,
        showAddpaymentMethodPopup, updatePaymentDetailDataModel, viewModelService]);

    const updatePaymentMethodForDropdown = useCallback(async (value) => {
        let isExistingBankOption = true;
        // addNewBankAccount
        if (value === 'Add New AutoPay Account') {
            // popup new Bank account
            await popupNewBankAccount();

            isExistingBankOption = false;
        } else if (value === 'Check') {
            updateHasSelectAutoPay(false);
        } else {
            // override the due date
            // use the bankAccount info to find from the accountData
            // sort the finding results
            // use the invoicing number biggerest dueday
            const existings = _.filter(fetchedAccountTableData, (data) => {
                return _.get(data, 'payUsing') === value;
            });
            if (_.isEmpty(existings)) {
                updateHasSelectAutoPay(false);
            } else {
                updateHasSelectAutoPay(true);
                const sorted = _.sortBy(existings, 'invoiceStreamDescription');
                const biggestInvoiceStreamNumber = sorted[sorted.length - 1];
                const invoiceStreamDueDay = _.get(biggestInvoiceStreamNumber, 'invoiceStreamDay');
                _.set(paymentDetailDataModel, 'dueDayOfTheMonth', invoiceStreamDueDay);
                const payUsingName = _.get(biggestInvoiceStreamNumber, 'payUsingName');
                _.set(paymentDetailDataModel, 'payUsingName', payUsingName);
            }
        }

        //
        if (isExistingBankOption) {
            writeValue(value, 'payUsing');
            updatepaymentMethodValue(value);
        }
        
    }, [fetchedAccountTableData, paymentDetailDataModel, popupNewBankAccount, writeValue]);

    // ==========================================================
    const paymentMethodDropdownOptions = PaymentDetailsDataUtil.getPaymentDetailsData(paymentMethodsOptions);
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        notificationSection: {
            className: styles.marginBottom
        },
        warningMessage: {
            visible: hasSelectAutoPay,
            content: messages.autoPayWarningMsg,
        },
        //
        paymentMethod: {
            availableValues: paymentMethodDropdownOptions,
            onValueChange: updatePaymentMethodForDropdown,
            value: paymentMethodValue,
        },
        dueDayOfMonth: {
            // path: 'dueDayOfTheMonth',
            availableValues: getDueDayOfMonthOptions(),
        },
        paymentPlan: {
            // path: 'selectedPaymentPlan',
            availableValues: paymentPlanOptions,
        },
        primaryPayer: {
            availableValues: primaryPayerAvailableValues,
            value: primaryPayer,
            onValueChange: primaryPayerChange,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={paymentDetailDataModel}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValueChange={writeValue}
        />
    );
}

PaymentDetailEditComponent.propTypes = {
    /**
     * data type: HOPaymentRetrieveDetailDTO
     */
    paymentDetailDataModel: PropTypes.shape({}).isRequired,
    updatePaymentDetailDataModel: PropTypes.func.isRequired,
    //
    // ======================================
    // rePopupEditQuoteRow: PropTypes.func,
    fetchedAccountTableData: PropTypes.arrayOf(PropTypes.shape({})),
    quoteTableData: PropTypes.arrayOf(PropTypes.shape({})),
    // ======================================
    // onInitBindData: PropTypes.func,
    /**
     * data type: BindingDataDTO
     */
    bindData: PropTypes.shape({}),
    onUpdatePaymentInstrument: PropTypes.func,
    onCreateOrUpdateBillingContact: PropTypes.func,
    //
    // beforeAddNewPerson: PropTypes.func,
    // beforeAddNewBankAccount: PropTypes.func,
    // beforeAddNewPaymentMethod: PropTypes.func,
    // onSavePaymentDetails: PropTypes.func,
    //
    // afterAddNewPerson: PropTypes.func,
    // afterAddNewBankAccount: PropTypes.func,
    // afterAddNewPaymentMethod: PropTypes.func,
    // afterAddMethodInitParams: PropTypes.shape({}),
};
PaymentDetailEditComponent.defaultProps = {
    // size: 'md',
    // rePopupEditQuoteRow: _.noop,
    fetchedAccountTableData: [],
    quoteTableData: [],
    //
    // onInitBindData: _.noop,
    bindData: undefined,
    onUpdatePaymentInstrument: _.noop,
    onCreateOrUpdateBillingContact: _.noop,
    //
    // beforeAddNewPerson: _.noop,
    // beforeAddNewBankAccount: _.noop,
    // beforeAddNewPaymentMethod: _.noop,
    // onSavePaymentDetails: _.noop,
    //
    // afterAddNewPerson: _.noop,
    // afterAddNewBankAccount: _.noop,
    // afterAddNewPaymentMethod: _.noop,
    // afterAddMethodInitParams: undefined,
};
export default PaymentDetailEditComponent;
