import React, {
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CurrencyValue, Loader, InlineLoader, TooltipIcon } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { WniDropdownSelect, WniToggle } from 'wni-common-base-components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { HOTooltipUtil, DPTooltipUtil } from 'wni-portals-tooltip';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WniClausesUtil } from 'wni-portals-util-js';
import HOClauseExtension from '../HOClauseExtension/HOClauseExtension';
import styles from './HOClauseComponent.module.scss';
import hoClauseComponentMessages from './HOClauseComponent.messages';
import HOCovCoveragesFormContext from '../../context/HOCovCoveragesFormContext';

import { Button } from '@jutro/legacy/components';

const tooltips = {
    ...HOTooltipUtil,
    ...DPTooltipUtil
}
/**
 * Component hierarchy diagram:
 * BaseClausesComponentVM ->  BaseSingleClauseComponentVM -> BaseClauseComponent
 * @param {object} props
 * @returns {object}
 */
function HOClauseComponent(props) {
    const modalApi = useWniModal();
    const {
        onValueChange,
        path,
        showAmount,
        isLoading,
        loadingMessage,
        containerClassName,
        displayName,
        readOnly,
        required,
        value,
        id,
        children,
        checked,
        description,
        labelPosition,
        amount,
        isDisabled,
        isLimitRequired,
        // children,
        expectCoverageTermComponentType,
        coverageTermsCount,
        isEditable,
        loadingIconType,
        clausePatternCode,
        // ====================================================
        hideCheckBoxForRequired,
        getCoverageLabel,
        getCheckboxTooltip,
        isOnlyShowTermsAndScheduleItems,
        showDetailsInPopup,
        onOpenCoverageDetailsPopup,
        hoprivateStructures,
        showPrivateStructureTable,
        showScheduleDetailsInPanelBelow,
        showErrors,
        isCoverageValid,
        openedRowItemNumber,
        setOpenedRowItemNumber,
        selectedRowItemIndex,
        setSelectedRowItemIndex,
        isCovBLinkVisible,
        linkToDetailsReadOnly,
        isEditing,
        setIsEditing,
        showViewOrEditButtonLink,
    } = props;
    const basePath = path.replaceAll('.selected', '');

    const intl = useContext(IntlContext);
    const coveragesFrom = useContext(HOCovCoveragesFormContext);
    const translator = useTranslator();

    const renderChildren = value && !showDetailsInPopup;


    function defaultGetCheckboxTooltip(clausePatternCodeParam) {
        const formattedTooltip = !_.isUndefined(description) ? description : '';

        return formattedTooltip;
    }

    function defaultGetCoverageLabel(labelName, labelAmount) {
        return WniClausesUtil.getLabelNameWithAmount(labelName, labelAmount, intl, showAmount);
    }


    const handleChange = async (newValue) => {
        if (newValue === false) {
            const result = await modalApi.showConfirm({
                title: displayName,
                message: translator(hoClauseComponentMessages.areYouSure),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(commonMessages.ok)
            });

            if (result === 'cancel' || result === 'close') {
                return;
            }
        }
        if (onValueChange) {
            onValueChange(newValue, path);
        }
    };

    const onDetailsLinkClick = () => {
        onOpenCoverageDetailsPopup(clausePatternCode)
    };

    const ViewOrEditButtonLink = () => {
        if (showViewOrEditButtonLink) {
            return <Button
                className="wni-button-link ml-10"
                onClick={onDetailsLinkClick}
                disabled={isEditing}
            >
                {linkToDetailsReadOnly ? 'View Details' : 'View/Edit Details'}
            </Button>
        }
        return null
    }

    const setTooltipPath = () => {
        if(clausePatternCode ==='DPCoverageDFairRentVal_Ext' || clausePatternCode ==='DPCoverageEAddLivExp_Ext') {
            if(coveragesFrom === 'dp1_Ext') {
                return tooltips[`${clausePatternCode}_DP1`]
            } 
            return tooltips[`${clausePatternCode}_DP2`]
        }
        return tooltips[clausePatternCode]
    }

    const renderToggleFieldComponent = () => {
        const hasChildren = !_.isNil(children);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });

        const formattedTooltip = _.isFunction(getCheckboxTooltip)
            ? getCheckboxTooltip(clausePatternCode)
            : defaultGetCheckboxTooltip(clausePatternCode);
        const coverageLabel = _.isFunction(getCoverageLabel)
            ? getCoverageLabel(displayName, amount)
            : defaultGetCoverageLabel(displayName, amount);
        
        const showHOClauseExtension = renderChildren
            && showPrivateStructureTable
        const HOClauseExtensionProps = {
            hoprivateStructures: hoprivateStructures,
            onValueChange,
            isEditable,
            path: basePath,
            clausePatternCode,
            showScheduleDetailsInPanelBelow,
            openedRowItemNumber,
            setOpenedRowItemNumber,
            selectedRowItemIndex,
            setSelectedRowItemIndex,
            showErrors,
            setIsEditing,
        }

        if (hideCheckBoxForRequired && required) {
            return (
                <div id={id} className="formCoveragesList">
                    <h6 className="formCoveragesTitle">
                        {coverageLabel}
                        {tooltips[clausePatternCode] && <TooltipIcon
                            id={`coverage_tooltip_${clausePatternCode}`}
                            text={tooltips[clausePatternCode]}/>
                        }
                        <ViewOrEditButtonLink />
                    </h6>
                    {renderChildren ? children : null}
                    {showHOClauseExtension ? <HOClauseExtension
                        {...HOClauseExtensionProps}
                    /> : null}
                </div>
            )
        }

        return (<WniToggle
                id={`${id}Toggle`}
                visible= {!isOnlyShowTermsAndScheduleItems}
                label= {coverageLabel}
                required= {isLimitRequired}
                // showRequired={isLimitRequired}
                value= {value}
                readOnly= {readOnly}
                disabled= {isDisabled}
                onValueChange= {handleChange}
                className={`${checkboxStyles} clauseCoverages`}
                controlClassName= {checkboxControlStyles}
                path= {path}
                checked= {checked}
                labelPosition= {labelPosition}
                showInlineLabel
                // tooltip= {{ text: formattedTooltip }}
                layout= "full-width"
                tooltip= {{ text: setTooltipPath() }}
                secondaryLabel= {<ViewOrEditButtonLink />}
            />);
    };

    const renderDisabledCoverageTermDropdownList = () => {
        return (
            <WniDropdownSelect
                autoTrim={false}
                dataType="string"
                disabled
                hideLabel={false}
                id="dropdownselect"
                // label={coverageName}
                labelPosition={labelPosition}
                searchable
                readOnly={false}
                required={false}
                schemaRequired={false}
                showErrors={showErrors}
                placeholder=""
                showValidationIcon={false}
                size="medium"
                value="false"
                tooltipPath={setTooltipPath()}
            />
        );
    };



    const renderCheckboxField = () => {
        const renderDisabledDropdown = !value && expectCoverageTermComponentType === 'DropDownList';

        if (renderDisabledDropdown) {
            return (
                <div className="clause-coverage-disabled">
                    <div className="clause-coverage-disabled-label">
                        {renderToggleFieldComponent()}
                    </div>
                    <div className="clause-coverage-disabled-content">
                        {renderDisabledCoverageTermDropdownList(displayName)}
                    </div>
                </div>
            );
        }
        return renderToggleFieldComponent();
    };

    const getLoadingComponent = useCallback(() => {
        if (loadingIconType === 'small') {
            return (
                <InlineLoader loading className="gw-inline-loader" />
            );
        }
        return (
            <Loader
                showLoader={isLoading}
                loadingMessage={loadingMessage}
            />
        );
    }, [loadingIconType]);

    const renderEditableValue = () => {
        const clauseContainerStyles = `${classNames(styles.clause, containerClassName)} quote-coverage-clause`;
        return (
            <div className={clauseContainerStyles}>
                {isLoading ? getLoadingComponent() : renderCheckboxField()}
            </div>
        );
    };

    const editModeRenderChildren = () => {
        if (children) {
            return children;
        }
        if (!value && expectCoverageTermComponentType === 'DropDownList') {
            return renderDisabledCoverageTermDropdownList();
        }
        return null;
    };

    const renderReadOnlyValue = () => {
        const showHOClauseExtension = renderChildren
            && showPrivateStructureTable
        const HOClauseExtensionProps = {
            hoprivateStructures: hoprivateStructures,
            onValueChange,
            isEditable,
            path: basePath,
            clausePatternCode,
            showScheduleDetailsInPanelBelow,
        }
        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        { !_.isUndefined(amount) && showAmount ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                    <ViewOrEditButtonLink />
                </span>
                {/* {showDetailsInPopup || (
                    <div className={styles.readOnlyGridSlotRight}>
                        {children}
                        {showHOClauseExtension ? <HOClauseExtension
                            {...HOClauseExtensionProps}
                        /> : null}
                    </div>
                )} */}
                
            </div>
        );
    };

    // ================================
    return isEditable ? renderEditableValue() : renderReadOnlyValue();
}

/**
 * @memberof gw-components-platform-react.ClauseComponent
 * @prop {Object} propTypes - the props that are passed to this component
 * @prop {string} propTypes.displayName - name of clause to display
 * @prop {bool} propTypes.readOnly - if the clause selection is read only
 * @prop {bool} propTypes.value - is the clause is seleceted
 * @prop {function} propTypes.onValueChange - callback when change is made
 * @prop {string} propTypes.path - path to value in the view modal
 * @prop {bool} propTypes.isLoading - should the clause be loading
 * @prop {string} propTypes.loadingMessage - message to be shown while loading
 * @prop {string} propTypes.containerClassName - clause container class
 * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
 */
HOClauseComponent.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    hideCheckBoxForRequired: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.bool,
    path: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    checked: PropTypes.bool,
    isEditable: PropTypes.bool,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    containerClassName: PropTypes.string,
    labelPosition: PropTypes.string,
    amount: PropTypes.shape({}),
    showAmount: PropTypes.bool,
    isDisabled: PropTypes.bool,
    coverageTermsCount: PropTypes.number,
    expectCoverageTermComponentType: PropTypes.string,
    isLimitRequired: PropTypes.bool.isRequired,
    loadingIconType: PropTypes.string,
    clausePatternCode: PropTypes.string,
    // ===========================
    /**
     * (Optional) Function: (labelName, labelAmount) => String
     * Used to to get coverable label displayed beside
     * the checkbox.
     */
    getCoverageLabel: PropTypes.func,
    /**
     * (Optional) Function: (clausePatternCode) => object
     * Used to get checkbox Tooltip
     */
    getCheckboxTooltip: PropTypes.func,
    isOnlyShowTermsAndScheduleItems: PropTypes.bool,
    showDetailsInPopup: PropTypes.bool,
    showScheduleDetailsInPanelBelow: PropTypes.bool,
    showErrors: PropTypes.bool,
};

HOClauseComponent.defaultProps = {
    readOnly: false,
    required: false,
    hideCheckBoxForRequired: false,
    path: undefined,
    value: undefined,
    isLoading: false,
    loadingMessage: '',
    checked: false,
    children: undefined,
    isEditable: true,
    description: undefined,
    containerClassName: undefined,
    labelPosition: 'left',
    amount: undefined,
    showAmount: true,
    isDisabled: false,
    coverageTermsCount: 0,
    expectCoverageTermComponentType: 'none',
    loadingIconType: undefined,
    clausePatternCode: undefined,
    getCoverageLabel: undefined,
    getCheckboxTooltip: undefined,
    isOnlyShowTermsAndScheduleItems: false,
    showDetailsInPopup: false,
    showScheduleDetailsInPanelBelow: false,
    showErrors: false,
};


export default HOClauseComponent;
