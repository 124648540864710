import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext, useTranslator } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

import { formatDate, formatNumber, FormattedDate, FormattedNumber } from '@jutro/components';

import { WniDateField } from 'wni-common-base-components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/legacy/datatable';

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CUUnderlyingPoliciesComponent(props) {

    const {
        underlyingPolicies, // CUUnderlyingPolicyDetailDTO[];
    } = props;

    const translator = useTranslator();

    // const [currentRow, updateCurrentRow] = useState(undefined);

    if (_.isEmpty(underlyingPolicies)) {
        return null;
    }

    const groupedByPolicyType = _.groupBy(underlyingPolicies, (up) => up.policyTypes);

    const renderCell = (columnField) => {
        return (rowData, ...params) => {
            let retval = rowData[columnField];
            if (columnField.endsWith('Date')) {
                retval = (<WniDateField readOnly value={retval} />);
            } else if (columnField === 'policyTypes') {
                retval = translator({id: `typekey.CELPolicyTypes.${retval}`, defaultMessage: retval});
            }
            return retval;
        }; 
    } ;

    const renderPolicyDetailsOfType = (
        policyDetails /* CUUnderlyingPolicDetailDTO */,
        policyType
    ) => {

        return (
            <div>
                <DataTable
                    id={`underlyingPolicyDetail-${policyType}`}
                    data={policyDetails}
                    showSearch={false}
                >
                    <DisplayColumn
                        header="Policy Type"
                        renderCell={renderCell('policyTypes')}
                    />
                    <DisplayColumn
                        header="Effective Date"
                        renderCell={renderCell('effectDate')}
                    />
                    <DisplayColumn
                        header="Expiration Date"
                        renderCell={renderCell('expireDate')}
                    />
                </DataTable>
            </div>
        );
    }

    const renderContent = (typToDetailsList) => {
        const retval = Object.entries(typToDetailsList).map(([policyType, policyDetails]) => {
            return renderPolicyDetailsOfType(policyDetails, policyType);
        });
        return retval;
    };

    return (
        <div>
            <h4>Underlying Policy Details</h4>
            {/* {renderContent(groupedByPolicyType)} */}
            { renderPolicyDetailsOfType(underlyingPolicies, 'all')}
        </div>
    );
    
}

CUUnderlyingPoliciesComponent.propTypes = {
    jurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CUUnderlyingPoliciesComponent.defaultProps = {
    
};


export default CUUnderlyingPoliciesComponent;