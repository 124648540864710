
import React, {
    useEffect,
    useState,
} from 'react';
import { RTIncidentsAndReportsReadOnlyPage } from 'wni-capability-quoteandbind-rt-react';
import WALIncidentsAndReportsUtil from '../../../wni-capability-quoteandbind-wal-react/pages/IncidentsAndReports/WALIncidentsAndReportsUtil';

function RTIncidentsAndReportsChangeReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;
    const {
        lobData: {
            roadTrail: {
                incidentsAndReports_Ext: {
                    clueenter = [],
                    mvrenter = [],
                    isTermBasedOnRenewal
                },
            },
        },
    } = submissionVM.value;
    // we need filter duplicate manually clue/mvr for policy change
    const [filteredManualCLUE, updateFilteredManualCLUE] = useState([]);
    const [filteredManualMVR, updateFilteredManualMVR] = useState([]);
    useEffect(() => {
        const filteredCLUE = WALIncidentsAndReportsUtil.filterCLUEManuallyData(clueenter);
        const filteredMVR = WALIncidentsAndReportsUtil.filterMVRManuallyData(mvrenter);
        updateFilteredManualCLUE(filteredCLUE);
        updateFilteredManualMVR(filteredMVR);
    }, []) ;
    return (
        <RTIncidentsAndReportsReadOnlyPage 
            {...props}
            productName = 'roadTrail'
            showPolicyClaims={isTermBasedOnRenewal}
            filteredManualCLUE={filteredManualCLUE}
            filteredManualMVR={filteredManualMVR}
        />
    );

}

RTIncidentsAndReportsChangeReadOnlyPage.propTypes = RTIncidentsAndReportsReadOnlyPage.propTypes;
RTIncidentsAndReportsChangeReadOnlyPage.defaultProps = RTIncidentsAndReportsReadOnlyPage.defaultProps;

export default RTIncidentsAndReportsChangeReadOnlyPage;