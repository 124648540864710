import React, {
    useContext
} from 'react';
import { useTranslator } from '@jutro/locale';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import _ from 'lodash';
import { useBusinessData as useWniBusinessData } from 'wni-portals-util-react';

const DEPENDENCIES_LIST = [
    "domainCompany",
    "interactionModel",
    "loadingMask",
    "workflowType",
    "applicationCache",
];

function useWniModal() {
    const useAuthenticationData = useAuthentication();
    const useDependenciesData = useDependencies(DEPENDENCIES_LIST);
    const useBusinessData = useWniBusinessData();
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { showAlert, showConfirm, showModal } = useModal()
    const showWniAlert = (modalProps) => {
        return showAlert(modalProps);
    };
        
    const showWniConfirm = (modalProps) => {
        return showConfirm(modalProps);
    };

    const showWniModal = (ele) => {
        const modalProps = _.get(ele, 'props', {});
        const extendProps = {
            useAuthenticationData,
            useDependenciesData,
            useBusinessData,
            viewModelService,
            breakpoint,
            translator,
        };
        const componentProps = {...extendProps, ...modalProps};
        const componentEle = {...ele, props: componentProps};
        return showModal(componentEle)
    };

    return {
       showAlert: showWniAlert,
       showConfirm: showWniConfirm,
       showModal: showWniModal
    };

}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withWniModal(WrappedComponent) {
    function WithWniModal(props) {
        const modalProps = useWniModal();
        return (
            <WrappedComponent
                {...props}
                {...modalProps}
            />
        );
    }
    hoistNonReactStatic(WithWniModal, WrappedComponent);
    WithWniModal.displayName = `WithWniModal(${getDisplayName(WrappedComponent)})`;
    return WithWniModal;
}



export default useWniModal;