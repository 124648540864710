import { defineMessages } from 'react-intl';

export default defineMessages({
    test11: {
        id: 'quoteandbind.pa.views.pa-driver-details.test',
        defaultMessage: 'this is {fieldName} -- {displayName}',
    },
    paDriverTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver',
        defaultMessage: 'Driver',
    },
    paDriverTitleNumber: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver:',
        defaultMessage: 'Driver:',
    },
    paDriver: {
        id: 'quoteandbind.views.quote.Personal Auto Driver',
        defaultMessage: 'Personal Auto Driver',
    },
    removeDriverTitle: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.Remove Driver?',
        defaultMessage: 'Remove Driver?',
    },
    removeDriverDescription: {
        id: 'quoteandbind.pa.drivers.remove-driver.message',
        defaultMessage:
            'Are you sure you want to remove this driver from the list of drivers?',
    },
    removeIncludePNIDriverDescription: {
        id: 'quoteandbind.pa.drivers.remove-driver.include pni message',
        defaultMessage:
            'PNI driver cannot be deleted, are you sure to delete other drivers?',
    },
    removePNIDriverDescription: {
        id: 'quoteandbind.pa.drivers.remove-driver.pni message',
        defaultMessage:
            'PNI driver cannot be deleted.',
    },
    paAddAnotherDriverTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Another Driver?',
        defaultMessage: 'Add Another Driver?',
    },
    paAddDriverButtonText: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Driver',
        defaultMessage: 'Add Driver',
    },
    paSaveDriverButtonText: {
        id: 'quoteandbind.pa.views.pa-driver-details.Save Driver',
        defaultMessage: 'Save Driver',
    },
    paUpdateDriverButtonText: {
        id: 'quoteandbind.pa.views.pa-driver-details.Update Driver',
        defaultMessage: 'Update Driver',
    },
    paDriverDetailTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver / Household Member Detail',
        defaultMessage: 'Driver / Household Member Detail',
    },
    paDriverTableTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver / Household Member',
        defaultMessage: 'Driver / Household Member',
    },
    paDriverNoPlaceholder: {
        id: 'quoteandbind.views.pa.driver details.No driver Placeholder',
        defaultMessage: 'There is no driver now. Please add one.',
    },
    validToolTip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltip.valid',
        defaultMessage:
            'All required fields have been completed for this Driver.',
    },
    inValidToolTip: {
        id:
            'quoteandbind.pa.directives.templates.pa-edit-driver.tooltip.inValid',
        defaultMessage:
            'Not all required fields for rating have been completed for this Driver.',
    },
    inValidForInsuranceToolTip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltip.inValidForInsurance',
        defaultMessage: 'Not all required fields for issuance have been completed for this Driver'
    },
    paDriverRemovePrefillReason: {
        id: 'quoteandbind.pa.views.pa-driver-details.-- none --',
        defaultMessage: '-- none --',
    },
    deletionModalOk: {
        id: 'quoteandbind.pa.views.pa-driver-details.modal.Ok',
        defaultMessage: 'Ok',
    },
    deletionModalCancel: {
        id: 'quoteandbind.pa.views.pa-driver-details.modal.Cancel',
        defaultMessage: 'cancel',
    },
    paDriversHouseholdMembers: {
        id: 'quoteandbind.pa.views.pa-driver-details.Drivers/Household Members',
        defaultMessage: 'Drivers/Household Members'
    },
    paDriversHouseholdMembersSub: {
        id: 'quoteandbind.pa.views.pa-driver-details.List all residents, dependents (licensed or not) and regular operators.',
        defaultMessage: ' - List all residents, dependents (licensed or not) and regular operators.'
    },
    viewAndEditLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.View',
        defaultMessage: 'View',
    },
    deleteLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Delete',
        defaultMessage: 'Delete Selected',
    },
    paDriverRemoveComplexTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Are you sure you want to remove driver(s) from the list of drivers?',
        defaultMessage: 'Are you sure you want to remove driver(s) from the list of drivers?'
    },
    cancelLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Cancel',
        defaultMessage: 'Cancel',
    },
    continueToVehicle: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Continue to Vehicle',
        defaultMessage: 'Continue to Vehicle',
    },
    addAnotherDriverLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Add Another Driver',
        defaultMessage: 'Add Another Driver',
    },
    continueToCoveragesLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Add/Edit Driver Coverages',
        defaultMessage: 'Add/Edit Driver Coverages',
    },
    closeLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-Driver.Close Driver',
        defaultMessage: 'Close Driver',
    },
    requiredForIssuanceSchema: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.warnings.required.issuance.driver',
        defaultMessage: 'Below required fields for issuance are missing: {displayName} - {fieldName}'
    },
    requiredForQuoteSchema: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.required.quote.driver',
        defaultMessage: '{displayName} - {fieldName} is required for quote.'
    },
    ageFirstLicensedErrMsg: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.error.age first licensed',
        defaultMessage: 'Age when first licensed should not be greater than Driver\'s age'
    },
    childPNIWarningMsg: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.warning.pni child',
        defaultMessage: 'Please add parent of {displayName} if living in the household.'
    },
    skipDriverCoverage: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Skip Driver Coverage',
        defaultMessage: 'Skip Driver Coverage'
    },
    saveAndNext: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Save & Next Driver',
        defaultMessage: 'Save & Next Driver'
    },
    mobilePhoneValidationText: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.validation mobile number',
        defaultMessage: 'The provided mobile number is not valid.'
    },
    homePhoneValidationText: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.validation home number',
        defaultMessage: 'The provided home number is not valid.'
    },
    workPhoneValidationText: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.validation work number',
        defaultMessage: 'The provided work number is not valid.'
    },
    MVRValidationText: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.validation MVR',
        defaultMessage: 'MVR information is missing.'
    },
    DriverLicSuspLastFiveYearsYNWarning: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.DriverLicSuspLastFiveYearsYN qa warning',
        defaultMessage: 'Has the driver’s license been suspended/revoked within the last 5 years?'
    },
    DriverAnyCurFinResFilingYNWarning: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.DriverAnyCurFinResFilingYN qa warning',
        defaultMessage: 'Is this driver required to submit a financial responsibility filing?'
    },
    DriverValidationTitle: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Driver validation title',
        defaultMessage: 'Below required fields for issuance are missing:'
    }
});
