import React from 'react';
import _ from 'lodash';
import {
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
    QuoteCoveragesUtil,
    QuoteUtil
} from 'wni-portals-util-js';
import { DomRenderUtil } from 'wni-portals-util-react';
import { Button } from '@jutro/legacy/components';

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = DomRenderUtil; 

export default {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
};