import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseVehicleType: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vehicleType.-- Choose Vehicle Type --',
        defaultMessage: '-- Choose Vehicle Type --'
    },
    // vehicleTypeChangeWaning: {
    //     id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vehicleTypeChangeWaning',
    //     defaultMessage: 'Line Coverages in Policy Detail will be eliminated once you choose new type of vehicle, you need to input new in following pop-up.'
    // },
    vehicleTypeChangePopupTitle: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vehicleTypeChangePopupTitle',
        defaultMessage: 'Please enter coverages since vehicle type is changed'
    },
    vinAmpersandNotification: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Replace ampersand(&) in VIN with the correct character/number.',
        defaultMessage: 'Replace ampersand(&) in VIN with the correct character/number.'
    },
    vinOverrideConfirmationMessage: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vinOverrideConfirmationMessage',
        defaultMessage: 'A different VIN ({vin}) was returned by the service. If you want to replace the user-entered VIN with this service-returned VIN, select “VIN Override” below.'
    },
    vinOverrideYes: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vinOverrideYes',
        defaultMessage: 'Override VIN'
    },
    vinOverrideNo: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vinOverrideNo',
        defaultMessage: 'Do not Override'
    },
    makeNotFound: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Make Not Found',
        defaultMessage: 'Make Not Found'
    },
    newMake: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.New Make',
        defaultMessage: 'New Make'
    },
    make: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Make.override',
        defaultMessage: 'Make'
    },
    modelNotFound: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Model Not Found',
        defaultMessage: 'Model Not Found'
    },
    newModel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.New Model',
        defaultMessage: 'New Model'
    },
    model: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Model.override',
        defaultMessage: 'Model'
    },
    bodyType: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Body Type',
        defaultMessage: 'Body Type'
    },
    vehicleMakeYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Model Year',
        defaultMessage: 'Model Year'
    },
    vinServiceNotFound: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Vin Service Not Found',
        defaultMessage: 'VIN not found, please re-enter the data.'
    }
});
