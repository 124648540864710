import React, { Component, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
// import { usePrevious } from 'wni-portals-util-react';
import { BaseSingleClauseComponentVM } from 'wni-components-platform-react';

/**
 * Clause component hierarchy diagram:
 * ClausesComponentVM -> SingleClauseComponentVM -> ClauseComponent
 * @param {object} props
 * @returns {object}
 */
function SingleClauseComponentVM(props) {
    const {
        onValidate,
        // isComponentValid,
        // registerComponentValidation,
        // hasValidationChanged,
        value: clause = {},
        isEditable,
        containerClassName,
        idPrefix,
    } = props;
    const translator = useTranslator();

    /**
     * Generate first clauses sub element metadata from clause
     * @param {object} newClause the clause to get data from
     * @returns {object | null} the metadata to render
     */
    const getFirstClauseData = (newClause, { getTermMetaData, getScheduleMetadata }) => {
        const terms = !_.isEmpty(newClause.terms)
            ? getTermMetaData(newClause.terms, newClause.publicID) : [];
        const schedules = _.has(newClause, 'schedule') ? getScheduleMetadata(newClause.schedule, newClause.publicID) : [];
        const clauseSubElements = _.concat([], terms, schedules);

        // hide label of first coverage term
        if (clauseSubElements.length > 0) {
            clauseSubElements[0].componentProps.hideLabel = true;
        }

        return !_.isEmpty(clauseSubElements) ? [{
            id: `${idPrefix}ClausetFirstElementContainer_[${newClause.publicID}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clause_element_container' },
            content: clauseSubElements.slice(0, 1)
        }] : null;
    };

    /**
     * Generate other (exclude first item) clauses sub element metadata from clause
     * @param {object} newClause the clause to get data from
     * @returns {object | null} the metadata to render
     */
    const getOtherClauseData = (newClause, { getTermMetaData, getScheduleMetadata }) => {
        const terms = !_.isEmpty(newClause.terms)
            ? getTermMetaData(newClause.terms, newClause.publicID) : [];
        const schedules = _.has(newClause, 'schedule') ? getScheduleMetadata(newClause.schedule, newClause.publicID) : [];
        const clauseSubElements = _.concat([], terms, schedules);

        return !_.isEmpty(clauseSubElements) && clauseSubElements.length > 1 ? [{
            id: `${idPrefix}ClauseOtherElementContainer_[${newClause.publicID}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clause_element_container' },
            content: clauseSubElements.slice(1)
        }] : [];
    };

    /**
     * Generate metadata from value provided from props
     * @returns {object} the metadata to render
     */
    const generateMetadata = ({
        clauseComponentProps: clauseComponentPropsParam,
        getClauseMetadata,
        getTermMetaData,
        getScheduleMetadata,
    }) => {
        let content = null;
        if (R1Config.coveragesContaingLongFirstItem.includes(clause.code_Ext) || !isEditable) {
            content = [{
                id: `${idPrefix}Clause_[${clause.publicID}]`,
                type: 'field',
                component: 'ClauseComponent',
                componentProps: clauseComponentPropsParam,
                content: getClauseMetadata(clause)
            }];
        } else {
            const clauseComponentProps = { ...clauseComponentPropsParam };

            clauseComponentProps.expectCoverageTermComponentType = clause.expectCoverageTermComponentType_Ext;
            clauseComponentProps.containerClassName = `${containerClassName} clause-coverage-container`;

            const firstClauseData = getFirstClauseData(clause, { getTermMetaData, getScheduleMetadata });
            const otherClauseData = getOtherClauseData(clause, { getTermMetaData, getScheduleMetadata });

            content = [{
                id: `${idPrefix}Clause_[${clause.publicID}]`,
                type: 'field',
                component: 'ClauseComponent',
                componentProps: clauseComponentProps,
                content: clause.selected ? firstClauseData : null
            }].concat(otherClauseData);
        }

        return {
            // content: content
            content: [{
                id: clause.publicID,
                type: 'container',
                component: 'div',
                content: content
            }]
        };
    };

    // ==================================================
    return (
        <BaseSingleClauseComponentVM
            {...props}
            getSingleClauseMetadata={generateMetadata}
        />
    );
}


SingleClauseComponentVM.propTypes = BaseSingleClauseComponentVM.propTypes;
SingleClauseComponentVM.defaultProps = BaseSingleClauseComponentVM.defaultProps;

export const SingleClauseVM = SingleClauseComponentVM;
// export default withValidation(SingleClauseComponentVM);
export default SingleClauseComponentVM;
