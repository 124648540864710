import { defineMessages } from 'react-intl';

export default defineMessages({
    agencyBillStatements: {
        id: 'gateway.statements.gateway-statements.Agency Bill Statements',
        defaultMessage: 'Agency Bill Statements'
    },
    commissionStatements: {
        id: 'gateway.statements.gateway-statements.Commission Statements',
        defaultMessage: 'Commission Statements'
    },
    backToAllQuotes: {
        id: 'gateway.statements.gateway-statements.Back to all quotes',
        defaultMessage: 'Back to All Quotes'
    },
    noPermissionToView: {
        id: 'gateway.statements.gateway-statements.Do not have permission',
        defaultMessage: 'You do not have permissions to view this page. '
    },
});
