import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import metadata from './AdditionalInformationPage.metadata.json5';

function AdditionalInformationPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate } = useValidation('AdditionalInformationPage');

    const shouldShow = (path, valueToMatch = true) => {
        const value = _.get(submissionVM, path);
        return value === valueToMatch;
    };

    useEffect(() => {
        if (!_.get(submissionVM, '_context.Binding', false)) {
            const vm = viewModelService.changeContext(submissionVM, {
                Binding: true
            });
            updateWizardData(vm);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    const generateAddInfoOverrides = () => {
        return {
            noOfRoomersOrBorders: {
                visible: shouldShow('lobData.homeowners.coverables.rating.roomerBoarders.value')
            },
            fireplaceOrWoodStovesNumber: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.rating.fireplaceOrWoodStoveExists.value'
                )
            },
            swimmingPoolDivingBoard: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            swimmingPoolFencing: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            swimmingPoolSlide: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            swimmingPoolType: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            trampolineSafetyNet: {
                visible: shouldShow('lobData.homeowners.coverables.rating.trampolineExists.value')
            },
            knownWaterLeakageDescription: {
                visible: shouldShow('lobData.homeowners.coverables.rating.knownWaterLeakage.value')
            }
        };
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        ...generateAddInfoOverrides()
    };

    return (
        <WizardPage disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

AdditionalInformationPage.propTypes = wizardProps;
export default AdditionalInformationPage;
