import _ from 'lodash';


const PA_RISK_ANALYSIS_FIELDS = [
    'risks_Ext',
    'clueEnter_Ext',
    'mvrEnter_Ext',
    'clueReport_Ext',
    'mvrReport_Ext',
    'priorCarrierReport_Ext',
    'priorCarrierEnter_Ext',
    'policyClaimsReport_Ext',
    'coverables.drivers'
];
const LOB_DATA_PATH = 'lobData';
const LOB_DATA_PA_PATH = 'lobData.personalAuto';
const PA_RISK_DATA_FIELDS = PA_RISK_ANALYSIS_FIELDS.map((fieldPath) => `${LOB_DATA_PA_PATH}.${fieldPath}`);

/**
 * Get updated DTO based on newPartialDTO
 *
 * SAMPLE USAGE:
 * const currentDTO = { baseData: 'Hello', lobData: 'World'};
 * const newPartialDTO = { baseData: 'Welcome' };
 * getUpdatedDTO(currentDTO, newPartialDTO);
 *
 * RESULT:
 * { baseData: 'Welcome', lobData: 'World'}
 * @param {object} currentDTO
 * @param {object} newPartialDTO
 * @returns {object}
 */
function getUpdatedDTO(currentDTO, newPartialDTO) {
    // Object.assign(currentDTO, newPartialDTO);
    // or _.assign(curretnDTO, newPartialDTO);
    // or return {...currentDTO, ...newPartialDTO}
    return { ...currentDTO, ...newPartialDTO };
}

/**
 * Update <code>targetDTO</code> with new value from sourceDTO
 *
 * Note: targetDTO will be UPDATED
 *
 * SAMPLE USAGE:
 * const targetDTO = { baseData, lobData };
 * const sourceDTO = { lobData };
 * const updatePaths = [ 'lobData.personalAuto.clueEnter_Ext', 'lobData.personalAuto.mvrEnter_Ext'];
 * updateDTOFields(targetDTO, sourceDTO, updatePaths);
 *
 * RESULT:
 * The CLUE and MVR Data in targetDTO will be replaced with newer version from sourceDTO.
 * @param {object} targetDTO
 * @param {object} sourceDTO
 * @param {object} updatePaths
 */
function updateDTOFields(targetDTO, sourceDTO, updatePaths) {
    const updatePathArray = _.isArray(updatePaths) ? updatePaths : [updatePaths];
    if (_.isEmpty(sourceDTO) || _.isEmpty(updatePathArray)) {
        return;
    }
    updatePathArray.forEach((updatePath) => {
        const newValue = _.get(sourceDTO, updatePath);
        _.set(targetDTO, updatePath, newValue);
    });
}


/**
 * Update current QuoteDataDTO with fresh RiskAnalysis Data for PA
 *
 * ref: QuoteHandlerDelegatePlugin.getLobDataWithPersonalAutoRiskData()
 *
 * 
 *
 * @param {object} currentQuoteDataDTO
 * @param {object} quoteDataDTOWithPARisksData
 * @returns {object} a new quoteDataDTO with fresh risk analysis data
 */
function getUpdatedDTOWithNewPARiskData(currentQuoteDataDTO, quoteDataDTOWithPARisksData) {
    // Cherry pick LobData so that it could be used later
    const lobDataWithRiskAnalysis = _.get(quoteDataDTOWithPARisksData, LOB_DATA_PATH);
    _.unset(quoteDataDTOWithPARisksData, LOB_DATA_PATH);

    // Update QuoteDataDTO with new baseData
    const retval = getUpdatedDTO(currentQuoteDataDTO, quoteDataDTOWithPARisksData);

    // Update QuoteDataDTO with new RiskAnalysis Data that locates in lobData
    updateDTOFields(
        retval,
        { [LOB_DATA_PATH]: lobDataWithRiskAnalysis }, // lobData preserved earlier
        PA_RISK_DATA_FIELDS, // The RiskAnalysis Fields Path for PA Line
    );

    return retval;
}

export default {
    getUpdatedDTO,
    getUpdatedDTOWithNewPARiskData,
};
