import React, { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { PolicyChangeDetailsComponent } from 'gw-capability-policyjob-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './QuotePage.metadata.json5';

function QuotePage(props) {
    const { wizardData: submissionVM, steps, jumpTo } = props;
    const breakpoint = useContext(BreakpointTrackerContext);

    const navigateToCoverages = useCallback(() => {
        const indexOfGeneralCoverages = _.findIndex(
            steps,
            ({ path }) => path === '/general-coverages'
        );
        jumpTo(indexOfGeneralCoverages);
    }, [jumpTo, steps]);

    const setPolicyData = useMemo(() => {
        const quoteData = _.get(submissionVM.value, 'quoteData.offeredQuotes[0].premium');
        return {
            effectiveDate: _.get(submissionVM.value, 'baseData.effectiveDate'),
            policyPeriod: {
                startDate: _.get(submissionVM.value, 'baseData.periodStartDate'),
                endDate: _.get(submissionVM.value, 'baseData.periodEndDate')
            },
            previousPremium: _.get(quoteData, 'previousPolicyTotalCost'),
            changeInCost: {
                amount:
                    _.get(quoteData, 'total.amount')
                    - _.get(quoteData, 'previousPolicyTotalCost.amount'),
                currency: _.get(quoteData, 'total.currency')
            },
            newPremium: _.get(quoteData, 'total'),
            taxes: _.get(quoteData, 'taxes'),
            newPremiumBeforeTaxes: _.get(quoteData, 'totalBeforeTaxes')
        };
    }, [submissionVM]);

    const resolvers = {
        resolveCallbackMap: {
            navigateToCoverages: navigateToCoverages
        },
        resolveComponentMap: {
            policychangedetailscomponent: PolicyChangeDetailsComponent
        }
    };

    const overrides = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        bopPolicyRenewalDetails: {
            policyDetails: setPolicyData,
            visible: !_.isEmpty(submissionVM),
            showPolicyPeriodDateRange: true
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
