import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { DriverContactDetailsComponent, DriverLicenseInfoComponent } from 'wni-capability-gateway-react';
import { DriverUtil, WniAccordionValidationUtil, DriverCoveragesUtil } from 'wni-portals-util-js';
import { WniDriverService } from 'wni-capability-quoteandbind';
import { useTranslator } from '@jutro/locale';

import metadata from './DriverComponent.metadata.json5';
import messages from './DriverComponent.messages';

function DriverComponent(props) {
    const {
        value: driverVM,
        initValue: initDriverVM,
        labelPosition,
        id,
        onValidate,
        onValueChange,
        showErrors,
        sourcePage,
        baseState,
        primaryNamedInsured,
        serviceData,
        policyChangeUpdateCoverages,
        fromReadOnlyPage,
        baseData,
        validationIssues,
        accordionOpendIds,
        updateAccordionOpendIds,
        wizardPageData
    } = props;
    const {
        quoteID,
        sessionUUID
    } = serviceData;
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [loadingClause, updateLoadingClause] = useState();
    const driverQuestionSets = _.get(driverVM, 'value.driverQuestionSets_Ext');
    const {
        driverCovered_Ext: driverCovered = []
    } = driverVM.value || {};

    const translator = useTranslator();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVM, id, onValidate, isComponentValid]);
    const getAccordionStatesOverrides = useCallback(() => {
        return WniAccordionValidationUtil.getAccordionOverridesForDrivers(driverVM, validationIssues, baseData, wizardPageData);
    }, [driverVM]);

    const defaultOpenedId =() => {
        const errorStateObj = WniAccordionValidationUtil
            .getAccordionOverridesForDrivers(driverVM, validationIssues, baseData, wizardPageData);
        const filterErrorStateObj = _.pickBy(errorStateObj, (v) => { return v.errorState});
        const openedArr = _.keys(filterErrorStateObj);
        
        if(!_.isEmpty(openedArr)) {
            return _.take(openedArr)
        }
        const accordionCardIds = _.keys(errorStateObj);
        return _.take(accordionCardIds);
    };

    useEffect(() => {
        updateAccordionOpendIds(defaultOpenedId());
    }, []);

    const updateCoverages = useCallback(async (changedPath, isSelectClause) => {
        const covPath = changedPath.split('.')[0];
        const requestData = _.merge(
            {},
            { quoteID },
            { sessionUUID },
            DriverCoveragesUtil.buildUpdateCoveragesReuqest(changedPath, isSelectClause, driverVM)
        );
        updateLoadingClause(_.get(driverVM, `${covPath}.publicID`));
        let coverages = {};
        if (_.isFunction(policyChangeUpdateCoverages)) {
            coverages = await policyChangeUpdateCoverages(
                requestData.driverDTO, requestData.cov, requestData.scheduleItem
            );
        } else {
            coverages = await WniDriverService.updateCoverages(requestData, authHeader);
        }
        DriverCoveragesUtil.mapScheduleToDriver(driverVM, coverages);

        _.set(driverVM, 'coverages', coverages);
        updateLoadingClause(false);
    }, [authHeader, driverVM, policyChangeUpdateCoverages, quoteID, sessionUUID]);

    const changeSubmission = useCallback(
        (value, changedPath, isSelectClause) => {
            _.set(driverVM, changedPath, value);
            updateCoverages(changedPath, isSelectClause);
        },
        [driverVM, updateCoverages]
    );

    /*
     * DriverFurnishedVehicleCompany_Ext   CompanyName
     * DriverIsCompanyVehFurnishedYN_Ext   Is there a company vehicle furnished or available for regular use of this driver?
     * DriverWNEmployeeDiscountYN_Ext      Western National Employee Discount?
     */
    const filterQuestionSets = useCallback((metadataContent) => {
        const relationshipToInsured = _.get(driverVM, 'value.relationshipToInsured_Ext');
        let hideQuestions = ['DriverIsCompanyVehFurnishedYN_Ext', 'DriverFurnishedVehicleCompany_Ext'];
        // POI-10401 && BAFinding #183
        if (_.get(driverVM, 'driverCovered_Ext.value') !== false
            && (relationshipToInsured === 'Insured_Ext'
            || relationshipToInsured === 'Spouse_Ext'
            || relationshipToInsured === 'Partner_Ext')) {
            hideQuestions = ['DriverFurnishedVehiclePolicy_Ext', 'DriverFurnishedVehicleCompany_Ext'];
        }
        // POI-16493
        if (!_.get(driverVM, 'licensedDriver_Ext.value')
            && _.get(driverVM, 'driverUnLicensedReason_Ext.value.code') === 'neverLicensed') {
            hideQuestions = ['DriverLicSuspLastFiveYearsYN_Ext', 'DriverIsCompanyVehFurnishedYN_Ext', 'DriverFurnishedVehicleCompany_Ext'];
        }
        // POI-19479
        if (_.get(baseData, 'baseState_Ext') === 'AK') {
            hideQuestions.push('DriverWNEmployeeDiscountYN_Ext');
        }
        return _.indexOf(hideQuestions, metadataContent.id) < 0;
    }, [driverVM]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition,
            showRequired: true
        },
        driverAccordion: {
            accordionStates: accordionOpendIds
        },
        driverUnderwritingQuestionSets: {
            visible: !_.isEmpty(driverQuestionSets),
            contentFilter: filterQuestionSets,
            contentMapper: DriverUtil.getQuestionMapper(translator, messages),
            onValidate,
            isReadOnly: fromReadOnlyPage
        },
        greaterAgeNotification: {
            visible: sourcePage === 'policyChange' && DriverUtil.getAge(driverVM, baseData) > 69 && driverCovered
        },
        lessAgeNotification: {
            visible: sourcePage === 'policyChange' && DriverUtil.getAge(driverVM, baseData) < 16
        },
        driverContactDetailsSection: {
            // onValueChange: handleDriverVM,
            primaryNamedInsured,
            onValueChange,
            model: driverVM,
            initModel: initDriverVM,
            showErrors,
            baseState,
            baseData,
            isReadOnly: fromReadOnlyPage
        },
        driverLicenseInfoSection: {
            onValueChange,
            model: driverVM,
            showErrors,
            baseState,
            baseData,
            wizardPageData,
            isReadOnly: fromReadOnlyPage
        },
        ...getAccordionStatesOverrides()
    };

    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, driverVM, fieldId, fieldPath, overrideProps
        );
    }, [driverVM]);

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent,
            drivercontactdetails: DriverContactDetailsComponent,
            driverlicense: DriverLicenseInfoComponent,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={driverVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={onValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

DriverComponent.propTypes = {
    value: PropTypes.shape({}),
    initValue: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    showErrors: PropTypes.bool,
    sourcePage: PropTypes.string,
    baseState: PropTypes.string,
    primaryNamedInsured: PropTypes.string,
    serviceData: PropTypes.shape({}),
    policyChangeUpdateCoverages: PropTypes.func,
    fromReadOnlyPage: PropTypes.bool,
    baseData: PropTypes.shape({}),
    validationIssues: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        reason: PropTypes.string,
    })),
    wizardPageData: PropTypes.shape({})
};
DriverComponent.defaultProps = {
    value: {},
    initValue: {},
    labelPosition: 'left',
    id: undefined,
    showErrors: false,
    sourcePage: 'quote',
    baseState: {},
    primaryNamedInsured: undefined,
    serviceData: {},
    policyChangeUpdateCoverages: undefined,
    fromReadOnlyPage: false,
    baseData: {},
    validationIssues: [],
    wizardPageData: {}
};
export default DriverComponent;
