
import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DriverCoveragesUtil, CoveragesUtil } from 'wni-portals-util-js';
import DriverCoveragesSection from '../DriverCoveragesSection/DriverCoveragesSection';
import metadata from './AllDriversCoveragesComponent.metadata.json5';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const AllDriversCoveragesComponent = (props) => {
    const {
        onValidate,
        submissionVM,
        updateWizardSnapshot,
        updateCoverages,
        loadingClause,
        showLoader,
        readOnly,
        showErrors,
        selectedVersionOfferingsIndex,
        lobName,
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const coverages = _.get(submissionVM, `value.lobData.personalAuto.offerings[${selectedVersionOfferingsIndex}].coverages`);
    const driverCoverages = DriverCoveragesUtil.generateDriverCoverages(submissionVM, coverages);

    useEffect(() => {
        setPageInitialized(true);
    }, []);

    const generateOverrides = useCallback(() => {
        const overrides = driverCoverages.map((driver, index) => {
            DriverCoveragesUtil.mapScheduleToDriver(driver, driver.coverages);
            return {
                [`coverageSection${index}`]: {
                    visible: driver.coverages.length >= 1,
                    driver,
                    index,
                    showErrors,
                    onValidate,
                    updateCoverages,
                    loadingClause,
                    fromReadOnlyPage: readOnly,
                    lobName,
                    selectedVersionOfferingsIndex,
                    updateWizardSnapshot,
                    submissionVM
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [driverCoverages, showErrors, onValidate, updateCoverages, loadingClause, readOnly, lobName, selectedVersionOfferingsIndex, updateWizardSnapshot, submissionVM]);

    const hasCovDrivers = driverCoverages.filter((d) => d.coverages.length > 0);
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        driverCoveragesIterable: {
            data: driverCoverages
        },
        emptyContent: {
            visible: hasCovDrivers.length <= 0
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveComponentMap: {
            driverCoveragesSection: DriverCoveragesSection
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    if (showLoader) {
        return <Loader showLoader />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={handleValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            showErrors={showErrors}
        />
    );
};

AllDriversCoveragesComponent.propTypes = {
    onValidate: PropTypes.func,
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    updateCoverages: PropTypes.func,
    loadingClause: PropTypes.bool,
    showLoader: PropTypes.bool,
    readOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    updateWizardSnapshot: PropTypes.func,
    selectedVersionOfferingsIndex: PropTypes.number,
    lobName: PropTypes.string.isRequired,
};

AllDriversCoveragesComponent.defaultProps = {
    onValidate: undefined,
    submissionVM: {},
    updateCoverages: undefined,
    loadingClause: false,
    showLoader: false,
    readOnly: false,
    showErrors: false,
    updateWizardSnapshot: undefined,
    selectedVersionOfferingsIndex: 0
};

export default AllDriversCoveragesComponent;
