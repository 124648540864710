
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { RTOperatorChangeService } from 'wni-capability-policychange-rt';

import { RTOperatorsPage } from 'wni-capability-quoteandbind-rt-react';

function RTOperatorsChangePage(props) {

    return (
        <RTOperatorsPage {...props}
            // onPageNextClicked={RTOperatorChangeService.onPageNext}
        />
    );

}

RTOperatorsChangePage.propTypes = RTOperatorsPage.propTypes;
RTOperatorsChangePage.defaultProps = RTOperatorsPage.defaultProps;

export default RTOperatorsChangePage;