import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import _ from 'lodash';
// import { DebugUtil } from 'wni-portals-util-js';
import onConfig  from './FeatureRenderConfig.On';
import offConfig from './FeatureRenderConfig.Off';


/**
 * Render feature content based on the code and enabled status
 * @param {string} featureCode 
 * @param {boolean} featureEnabled 
 * @param {array} renderParams
 * @returns {ReactElement}
 */
function renderFeatureContent(featureCode, featureEnabled, ...renderParams) {
    const contentRenderFunc = featureEnabled ? onConfig[featureCode] : offConfig[featureCode];
    if (contentRenderFunc) {
        return contentRenderFunc(...renderParams);
    }
    return null;
}

export default {
    renderFeatureContent,
};
