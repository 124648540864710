import _ from 'lodash';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
/**
 *
 * import { WniSubmissionService } from 'wni-capability-gateway';
 * WniSubmissionService.getPortalDocumentsForSubmission
 * WniPolicyService.getPortalDocumentsForPolicy
 *
 * import { WniCustomCancellationService } from 'wni-capability-gateway';
 * WniCustomCancellationService.getDocumentsForCancellation
 *
 * import { WniCustomEndorsementService } from 'wni-capability-policy-change';
 * WniCustomEndorsementService.getDocumentsForPolicyChange
 *
 * OOTB
 * import { SubmissionService } from 'gw-capability-gateway';
 * SubmissionService.getDocumentsForSubmission
 * import { PolicyService } from 'gw-capability-gateway';
 * PolicyService.getDocumentsForPolicy(policyNumber, authHeader)
 * import { CancellationService } from 'gw-capability-gateway';
 * CancellationService.getDocumentsForCancellation(jobNumber, authHeader)
 * import { RenewalService } from 'gw-capability-gateway';
 * RenewalService.getDocumentsForRenewal
 *
 * const POLICY_CHANGE_DATA = [
        'Personal Auto Quote Summary',
        'Auto Policy Change Request'
    ];
 *
 * const RETAIN_IN_AGENCY_DATA = [
        'Personal Auto Quote Summary',
        'Personal Automobile Application-',
        'AutoPay Enrollment',
        'Alaska Adverse Action reconsideration',
        'ACORD', // except ACORD 91
        'Vehicle Identification Card',
        'Roadside Assistance ID Card',
        // only for AK
        '3rd Party Designee Notice',
        // only for AK
        'Motor Vehicle Authorization Form',
        // only for AK
        'Alaska Reconsideration Certification',
        // only for IA
        'Iowa Extraordinary Life Circumstances Disclosure',
        // only for SD
        'Signature for Excluded Driver - South Dakota'
    ];
 *
 * const SUBMIT_TO_WESTERNNATIONAL_DATA = [
        'ACORD91',
        'Vehicle Photos',
        'Corporate Discount Removal',
        'Defensive Driving Credit'
    ];
 *
 * const CANCELLATION_DATA = [
        'CANCELLATION REQUEST / POLICY RELEASE'
    ];
 *
 *
 */

const ACCOUNT_LEVEL_AUTOPAY_DOCUMENT_KEYS = ['BLDBAPFM_Blank AutoPay Enrollment Form', 'AutoPay Enrollment Form for Bank Account'];

async function downloadDocument(publicID, sessionID, authHeader, history, proxiedServiceUrl = 'downloadDocument') {
    const serviceUrl = getProxiedServiceUrl(proxiedServiceUrl);
    const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?token=${sessionID}`);

    const errorCallback = () => {
        history.push('/documenterror');
    };

    DocumentDownloadService.getDocument(
        templateDownloadEndpointURL,
        authHeader,
        errorCallback
    );
}

async function previewDocument(publicID, authHeader, history, name, targetProxiedServiceUrl = 'wniDocumentRetrieval') {
    const serviceUrl = getProxiedServiceUrl(targetProxiedServiceUrl);
    const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?publicID=${publicID}`);
    const errorCallback = () => {
        history.push('/documenterror');
    };

    const params = {
        method: 'GET',
        url: templateDownloadEndpointURL,
        headers: {
            ...authHeader,
            'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer'
    };
    return fetch(templateDownloadEndpointURL, params).then((response) => {
        if (response.ok) {
            response.blob().then((blob) => {
                const filename = name;
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, filename);
                } else {
                    window.open(window.URL.createObjectURL(blob));
                }
            });
        } else errorCallback();
    }).catch(() => {
        errorCallback();
    });
}

function getDocumentData(docs, target) {
    const result = [];
    const targetDocs = _.get(docs, target, []);
    // sort by time to make sure show the latest one
    targetDocs.sort((a, b) => {
        return new Date(b.dateCreated_Ext) - new Date(a.dateCreated_Ext);
    });
    // duplicate check
    const map = {};
    _.forEach(targetDocs, (doc) => {
        _.set(doc, 'displayName', _.get(doc, 'displayName_Ext'));
        if (!map[doc.displayName]) {
            map[doc.displayName] = doc.displayName;
            result.push(doc);
        }
    });
    // sort by the alphabet
    result.sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
    });
    return result;
}

function getDocumentDataByVersion(docs, target, version) {
    const result = [];
    const targetDocs = _.get(docs, target, []);

    // filter the docs relate to the target version
    const targetVersionDocs = _.filter(targetDocs, (doc) => _.get(doc, 'policyPeriodPublicID_Ext') === version || ACCOUNT_LEVEL_AUTOPAY_DOCUMENT_KEYS.includes(_.get(doc, 'documentKey_Ext')));

    // sort by time to make sure show the latest one
    targetVersionDocs.sort((a, b) => {
        return new Date(b.dateCreated_Ext) - new Date(a.dateCreated_Ext);
    });
    // duplicate check
    const map = {};
    _.forEach(targetVersionDocs, (doc) => {
        _.set(doc, 'displayName', _.get(doc, 'displayName_Ext'));
        if (!map[doc.displayName]) {
            map[doc.displayName] = doc.displayName;
            result.push(doc);
        }
    });
    // sort by the alphabet
    result.sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
    });
    return result;
}

/**
 * generate the option object for the on demand document
 * @param {array} isMultiPeriods
 * @param {string} periodPublicID
 * @param {string} pairPeriodPublicID
 * @param {string} version
 * @param {string} docName
 * @returns {array}
 */
function addOnDemandDocument(
    isMultiPeriods, periodPublicID, pairPeriodPublicID, version, docName
) {
    return {
        code: `${periodPublicID}_${pairPeriodPublicID}_${docName}`,
        name: isMultiPeriods ? `${docName} ${version}` : docName,
        periodPublicID: `${periodPublicID}`,
        pairPeriodPublicID: `${pairPeriodPublicID}`,
        docName: `${docName}`
    };
}

/**
 * popup the option object for the on demand document
 * @param {string} periodPublicID
 * @param {string} pairPeriodPublicID
 * @param {string} docName
 * @param {array} options
 * @returns {array} filtered options
 */
function popupDocument(
    periodPublicID, pairPeriodPublicID, docName, options
) {
    let retval = options;
    if (options.length > 0) {
        retval = _.filter(options,
            (option) => option.code !== `${periodPublicID}_${pairPeriodPublicID}_${docName}`);
    }
    return retval;
}

/**
 * Get the On Demand Documents
 * @param {array} calculatutedPeriods SideBySidePeriodInfoDTO list
 * @param {string} line
 * @returns {array}
 */
function getOnDemandDcouments(calculatutedPeriods, line = null) {
    const sideBySideDocName = 'Side By Side Quote Summary';
    const selectDocName = 'Select Auto Quote Summary';
    const signatureDocName = 'Signature Auto Quote Summary';
    let isMultiPeriods = _.get(calculatutedPeriods, 'length') > 2;
    const options = [];
    _.each(calculatutedPeriods, (period) => {
        const version = _.get(period, 'branchName');
        const periodPublicID = _.get(period, 'publicID');
        const pairPeriodPublicID = _.get(period, 'pairPeriodPublicId_Ext');
        let option;
        if (line !== null) {
            switch (line) {
                case 'homeowners':
                    const hoDocName = "HO Quote Summary";
                    // default for HO, use the same logic as Plus
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, hoDocName
                    );
                    options.push(option);
                    break;
                case 'watercraft':
                    const watercraftDocName = 'WT Quote Summary';
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, watercraftDocName
                    );
                    options.push(option);
                    break;
                case 'roadTrail':
                    const roadTrailDocName = 'RT Quote Summary';
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, roadTrailDocName
                    );
                    options.push(option);
                    break;
                case 'dwellingProperty':
                    const dpDocName = "DP Quote Summary";
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, dpDocName
                    );
                    options.push(option);
                    break;
                case 'personalUmbrella':
                    const puDocName = "PU Quote Summary";
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, puDocName
                    );
                    options.push(option);
                    break;
                default: break;
            }
            
        } else {
            switch (_.get(period, 'policyType_Ext')) {
                case 'Named Non-Owner':
                    isMultiPeriods = _.get(calculatutedPeriods, 'length') > 1;
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, selectDocName
                    );
                    options.push(option);
                    break;
                case 'Select':
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, sideBySideDocName
                    );
                    options.push(option);
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, selectDocName
                    );
                    options.push(option);
                    break;
                case 'Signature':
                    option = addOnDemandDocument(
                        isMultiPeriods, periodPublicID, pairPeriodPublicID,
                        version, signatureDocName
                    );
                    options.push(option);
                    break;
                default: break;
            }
        }
    });
    return options;
}

async function tryDownloadDocument(
    doc, authHeader, history, retrieveDocumentService, successCallback, errorCallback, targetProxiedServiceUrl
) {
    const publicID = _.get(doc, 'publicID');
    const filename = _.get(doc, 'name');
    await retrieveDocumentService.getOnDemandDocument(
        publicID,
        filename,
        authHeader,
        successCallback,
        errorCallback,
        targetProxiedServiceUrl
    );
}

async function printAllDocuments(
    documents, authHeader, retrieveDocumentService, successCallback, errorCallback,
    specificName, multiTargetProxiedServiceUrl
) {
    return retrieveDocumentService.printAllDocument(
        documents,
        authHeader,
        successCallback,
        errorCallback,
        specificName,
        multiTargetProxiedServiceUrl
    );
}

function defaultSuccessCallback(setLoadingMask) {
    setLoadingMask(false);
};

function defaultErrorCallback(modalApi, setLoadingMask, ServiceErrorUtil) {
    setLoadingMask(false);
    modalApi.showConfirm({
        title: 'Error',
        message: ServiceErrorUtil.prependWithFriendMessage(),
        status: 'error',
        icon: 'gw-error-outline',
        // confirmButtonText: messages.ok,
    }).then(() => {
        _.noop();
    }).catch(() => {
        _.noop();
    });
};

function defaultPrintAllErrorCallBack(exception, modalApi, setLoadingMask, ServiceErrorUtil) {
    setLoadingMask(false);
    // 408 - timeout
    if (_.get(exception, 'status') === 408) {
        alert(NO_DOCUID_NOTIFICATION);
    } else {
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'gw-error-outline',
            // confirmButtonText: messages.ok,s
        }).then(() => {
            _.noop();
        }).catch(() => {
            _.noop();
        });
    }
};

async function handlePrintAllFn(
    docs,
    setLoadingMask,
    authHeader,
    history,
    WniDocumentRetrievalService,
    successCallback,
    errorCallback,
    WniMultiDocumentRetrievalService,
    printAllErrorCallBack,
    targetProxiedServiceUrl,
    multiTargetProxiedServiceUrl
) {
    if (_.get(docs, 'length') < 1) {
        return false;
    }
    // length === 1 and docUID exist call OOTB download
    if (docs.length === 1) {
        // only one document
        const doc = docs[0];
        setLoadingMask(true);
        if (_.isNil(targetProxiedServiceUrl)) {
            await tryDownloadDocument(
                doc, authHeader, history, WniDocumentRetrievalService,
                successCallback, errorCallback
            );
        } else {
            await tryDownloadDocument(
                doc, authHeader, history, WniDocumentRetrievalService,
                successCallback, errorCallback, targetProxiedServiceUrl
            );
        }
    } else {
        setLoadingMask(true);
        if (_.isNil(multiTargetProxiedServiceUrl)) {
            await printAllDocuments(
                docs, authHeader, WniMultiDocumentRetrievalService,
                successCallback, printAllErrorCallBack
            );
        } else {
            await printAllDocuments(
                docs, authHeader, WniMultiDocumentRetrievalService,
                successCallback, printAllErrorCallBack, null, multiTargetProxiedServiceUrl
            );
        }
    }
};

async function onDemandDocumentDownloadFn(
    item, authHeader, history, modalApi, setLoadingMask,
    ServiceErrorUtil, WniDocumentRetrievalService
) {
    const successCallback = () => {
        setLoadingMask(false);
    };
    const errorCallback = () => {
        setLoadingMask(false);
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'gw-error-outline',
            // confirmButtonText: messages.ok,
        }).then(() => {
            _.noop();
        }).catch(() => {
            _.noop();
        });
    };
    setLoadingMask(true);
    let targetProxiedServiceUrl = 'wniDocumentRetrieval';
    if (_.includes(item.publicID, 'bc')) {
        targetProxiedServiceUrl = 'wniBCDocumentRetrieval';
    }
    await tryDownloadDocument(
        item, authHeader, history, WniDocumentRetrievalService,
        successCallback, errorCallback, targetProxiedServiceUrl
    );
};

async function defaultDownloadDocument(
    publicID, sessionID, setLoadingMask, history, authHeader
) {
    setLoadingMask(true);
    const serviceUrl = getProxiedServiceUrl('downloadDocument');
    const templateDownloadEndpointURL = serviceUrl.concat(
        `/${publicID}?token=${sessionID}`
    );

    const errorCallback = () => {
        setLoadingMask(false);
        history.push('/documenterror');
    };

    await DocumentDownloadService.getDocument(
        templateDownloadEndpointURL,
        authHeader,
        errorCallback
    );
    setLoadingMask(false);
}

function filterValidDocuments(documents) {
    return _.filter(documents, (doc) => !_.get(doc, 'publicID', '').includes('gnore'));
}

const NO_DOCUID_NOTIFICATION = 'Documents are still being processed on this policy and are not yet available – please check back soon. If you need support in obtaining documents, please contact our Customer Relationship Center at (800) 352-2772.';

function updateDocumentUnselectable(documents) {
    const data = _.map(documents, (item => {
        return {
            ...item,
            unselectable: _.get(item, 'mimeType') !== 'application/pdf'
        }
    }));
    return data;
}


function getLatestDocumentData(targetDocs) {
    const result = [];
    // sort by time to make sure show the latest one
    targetDocs.sort((a, b) => {
        return new Date(b.dateCreated_Ext) - new Date(a.dateCreated_Ext);
    });
    // duplicate check
    const map = {};
    _.forEach(targetDocs, (doc) => {
        _.set(doc, 'displayName', _.get(doc, 'simpleName_Ext'));
        if (!map[doc.displayName]) {
            map[doc.displayName] = doc.displayName;
            result.push(doc);
        }
    });
    // sort by the alphabet
    result.sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
    });
    return result;
}

export default {
    downloadDocument,
    previewDocument,
    getDocumentData,
    RETAIN_IN_AGENCY: 'retainInAgency',
    SUBMIT_TO_WESTERNNATIONAL: 'submitToWesternNational',
    POLICY_CHANGE: 'policyChange',
    CANCELLATION: 'cancellation',
    NO_DOCUID_NOTIFICATION,
    RENEWAL: 'renewal',
    REWRITE: 'rewrite',
    getOnDemandDcouments,
    getDocumentDataByVersion,
    tryDownloadDocument,
    printAllDocuments,
    handlePrintAllFn,
    defaultSuccessCallback,
    defaultErrorCallback,
    defaultPrintAllErrorCallBack,
    onDemandDocumentDownloadFn,
    defaultDownloadDocument,
    filterValidDocuments,
    updateDocumentUnselectable,
    getLatestDocumentData
};
