
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 

import { PULossHistoryReadOnlyPage } from 'wni-capability-quoteandbind-pu-react';

function PULossHistoryChangeReadOnlyPage(props) {

    return (
        <PULossHistoryReadOnlyPage {...props} />
    );

}

PULossHistoryChangeReadOnlyPage.propTypes = PULossHistoryReadOnlyPage.propTypes;
PULossHistoryChangeReadOnlyPage.defaultProps = PULossHistoryReadOnlyPage.defaultProps;

export default PULossHistoryChangeReadOnlyPage;