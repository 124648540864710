import { defineMessages } from 'react-intl';

export default defineMessages({
    removeTitle: {
        id: 'quoteandbind.gl.directives.templates.gl-edit.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeDescription: {
        id: 'quoteandbind.gl.directives.templates.gl-edit.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this record?',
    },
    viewAndEditLabel: {
        id: 'quoteandbind.gl.directives.templates.gl-edit-vehicle.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.gl.directives.templates.gl-edit-vehicle.View',
        defaultMessage: 'View',
    },
});
