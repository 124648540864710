import React, { useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Flex } from '@jutro/layout';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import { Avatar } from '@jutro/components';
import { ServiceManager } from '@jutro/legacy/services';

import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    ViewModelServiceContext,
    ViewModelForm,
} from '@xengage/gw-portals-viewmodel-react';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { ContactService } from 'gw-capability-gateway';
import messages from 'gw-capability-gateway-react/Contacts/Contacts.messages';

import metadata from './PolicyContactsPage.metadata.json5';
import tabStyles from '../Contacts.module.scss';
import gatewayMessages from '../../gateway.messages';

const PRIMARY_PHONE_TYPE_MAP = {
    home: 'homeNumber',
    mobile: 'cellNumber',
    work: 'workNumber',
};

const roleAbstractEntities = [
    'PolicyContactRole',
    'BAPolicyContactRole',
    'BOPPolicyContactRole',
    'CPPolicyContactRole',
    'GLPolicyContactRole',
    'PAPolicyContactRole',
    'IMPolicyContactRole',
    'PolicyNamedInsured',
    'WCPolicyContactRole',
    'WCLaborContact',
    'PlcyNonPriNamedInsured',
    'PolicyNamedInsured',
];

const servicePageConfig = {
    page: 0,
    pageSize: 100,
};

const pageConfig = {
    page: 0,
    pageSize: 5,
};

function PolicyContactsPage(props) {
    const { history, policyNumber } = props;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const localeService = ServiceManager.getService('locale-service');
    const phoneUtil = PhoneUtil();
    const [policyContactRoleVM, updatePolicyContactRoleVM] = useState({});
    const [policyContactRole, updatePolicyContactRole] = useState('allRoles');
    const [policyContactRoleOptions, updatePolicyContactRoleOptions] = useState([]);
    const [policyContactTableData, updatePolicyContactTableData] = useState([]);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);

    
    const generateTableData = (data = []) => {
        return data.map((item) => {
            const itemPhoneType = item.primaryPhoneType;
            const telNumber = item[PRIMARY_PHONE_TYPE_MAP[itemPhoneType]];
            const prettyTelNumber = telNumber && phoneUtil.prettyPrint(telNumber, localeService.getDefaultCountryCode())

            return {
                ...item,
                telNumber: prettyTelNumber
            }
        })
    };

    const getPolicyContactSummaries = async () => {
        const role =
            policyContactRole !== 'allRoles' ? [policyContactRole] : [];

        const response = await ContactService.getPolicyContactSummaries(
            policyNumber,
            authHeader,
            role,
            servicePageConfig.fromPage,
            servicePageConfig.pageSize
        );
        const policyContacts = response.policyContacts || [];
        const sortedPolicyContactTableData = _.sortBy(policyContacts, (contact) => {
                return !_.includes(contact.policyContactRoles, 'PolicyPriNamedInsured');
            }
        );
        updatePolicyContactTableData(generateTableData(sortedPolicyContactTableData));
    };

    const getPolicyContactRoles = useCallback(() => {
        const allRolesOption = {
            code: 'allRoles',
            name: messages.allRoles,
        };

        const contactRoles = _.get(
            policyContactRoleVM,
            'subtype.aspects.availableValues',
            []
        );

        const roleOptions = contactRoles
            .filter((option) => {
                return !roleAbstractEntities.includes(option.code);
            })
            .map((role) => {
                return {
                    code: role.code,
                    name: translator(messages[_.lowerFirst(role.code)]),
                };
            });

        roleOptions.unshift(allRolesOption);
        return roleOptions;
    }, [policyContactRoleVM, translator]);

    useEffect(() => {
        const newPolicyContactRoleVM = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.contact.dto.roles.policy.PolicyContactRoleDTO'
        );
        updatePolicyContactRoleVM(newPolicyContactRoleVM);

        const newPolicyContactRoleOptions = getPolicyContactRoles();
        updatePolicyContactRoleOptions(newPolicyContactRoleOptions);

        getPolicyContactSummaries();
    }, []);

    const redirectToContactDetails = (item) => {
        history.push({
            pathname: `/policies/${policyNumber}/contactDetails`,
            state: {
                contact: 'policyContacts',
                accountOrPolicyNumber: policyNumber,
                relatedTo: 'policies',
                contactId: item.accountContactPublicID,
            },
        });
    };

    const renderContact = (item, index, { path }) => {
        return (
            <Flex
                justifyContent="left"
                alignItems="center"
                onClick={() => redirectToContactDetails(item)}
            >
                <Avatar username={item[path]} />
                <span className="btn-link">{item[path]}</span>
            </Flex>
        );
    };

    const renderRole = (item, index, { path: property }) => {
        const roles = item[property];
        const roleOptions = policyContactRoleVM.subtype.aspects.availableValues;
        const getAllRoles = roles
            .map((data) => {
                const matchingRole = roleOptions.find((opt) => opt.code === data);
                return matchingRole
                    ? translator(messages[_.lowerFirst(matchingRole.code)])
                    : null;
            })
            .join(', ');

        return <div title={getAllRoles}>{getAllRoles}</div>;
    };

    const renderPoliciesInvolved = (item, index, { path }) => {
        const policiesCount = item[path];
        const isActive = item.active;
        const activePolicies = policiesCount > 1 ? translator(messages.activePolicies) : translator(messages.activePolicy); 

        return isActive ? (
            <Flex gap='small'>
                <h4>{policiesCount}</h4>
                <span>{activePolicies}</span>
            </Flex>
        )
        : translator(gatewayMessages.inactive);
    };

    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };

    const overrides = {
        policyContactsRoles: {
            availableValues: policyContactRoleOptions,
            value: policyContactRole,
        },
        policyContactsDataTable: {
            // key: policyContactRole,
            data: policyContactTableData,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [5, 10, 15, 20],
        },
    };

    const resolvers = {
        resolveClassNameMap: tabStyles,
        resolveComponentMap: {},
        resolveCallbackMap: {
            renderContact,
            renderRole,
            renderPoliciesInvolved,
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            policyContactRole,
            id,
            path,
            overrides
        );
    };

    const writeValue = (value) => {
        updatePolicyContactRole(value);
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            onValueChange={writeValue}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyContactsPage.propTypes = {};

export const PolicyContactsComponent = PolicyContactsPage;

export default PolicyContactsPage;
