import { defineMessages } from 'react-intl';

export default defineMessages({
    baseCoverages: {
        id: 'quoteandbind.ho.directives.HoOfferingSelection.defaultBaseCoverages.General Coverages',
        defaultMessage: 'General Coverages',
    },
    additionalCoverages: {
        id: 'quoteandbind.ho.directives.HoOfferingSelection.defaultAdditionalCoverages.Additional Coverages',
        defaultMessage: 'Additional Coverages',
    }
});
