import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import WizardPage from '../../templates/RTWizardPage';

import RTLineCoverages from './CoveragesSection/RTLineCoverages/RTLineCoverages';
import RTVehiclesCoverages from './CoveragesSection/RTVehiclesCoverages/RTVehiclesCoverages';
import RTOperatorsCoverages from './CoveragesSection/RTOperatorsCoverages/RTOperatorsCoverages';

import styles from './RTCoveragesPage.module.scss';
import metadata from './RTCoveragesPage.metadata.json5';
import CoveragesConfigContext from './context/RTCoveragesConfigContext';
import RTCoveragesConfig from './RTCoverages.config';

function RTCoveragesReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
            baseState_Ext: baseState,
            policyContactRoles_Ext: policyContactRoles
        },
        lobData: {
            roadTrail: {
                offerings,
                coverables: {
                    operators = []
                }
            }
        },
    } = submissionVM.value;

    // const { authHeader } = useAuthentication();

    const {
        initialValidation,
    } = useValidation('RTCoveragesPage');
    // const [showErrors, setShowErrors] = useState(false);
    // const [isEditing, setIsEditing] = useState(false)

    // const [validationIssues, updateValidationIssues] = useState([])
    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelCoverages',
    ]);
    // const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    const lobName = 'roadTrail'

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const pinAniOwningCovInPortal = policyContactRoles.filter((contact) => {
        return contact.contactRole === "Named Insured" 
    })
    
    const operatorsOwningCovInPortal = operators.filter((operator) => {
        const {
            relationship,
            additionalInsured
        } = operator;
        if (additionalInsured) return;
        return ['Spouse_Ext', 'Partner_Ext'].includes(relationship);
    })


    const operatorsCoveragesFor = operatorsOwningCovInPortal.concat(_.map(pinAniOwningCovInPortal, 'contact'))
    const showOperaterLevelCoverages = baseState === 'SD' && operatorsCoveragesFor.length > 0;
    

    useEffect(() => {

    }, []);

    const onPageNext = async () => {
        return submissionVM;
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                isEditable: false,
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            rtLineCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
            },
            rtVehiclesCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
                isEditable: false,
            },
            operatorLevelCoverages: {
                visible: showOperaterLevelCoverages,
            },
            rtOperatorsCoverages: {
                selectedVersionIndex,
                submissionVM,
                lobName,
                operatorsCoveragesFor,
            }
        };
    }, [
        openedAccordions,
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        showOperaterLevelCoverages,
        operatorsCoveragesFor
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            RTLineCoverages: RTLineCoverages,
            RTVehiclesCoverages: RTVehiclesCoverages,
            RTOperatorsCoverages: RTOperatorsCoverages,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // disableNext={isEditing}
            // disablePrevious={isEditing}
            // disableCancel={isEditing}
            onNext={onPageNext}
            showRequiredInfoForFasterQuote
        >
            <CoveragesConfigContext.Provider value={RTCoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
}

RTCoveragesReadOnlyPage.propTypes = WizardPage.propTypes;
export default RTCoveragesReadOnlyPage;
