import React from 'react';
import _ from 'lodash';

import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';

import {
    CustomEquipmentSingleClauseComponentVM,
    AccidentForgivenessClauseComponent,
} from 'wni-components-platform-react';
import {
    QuoteCoveragesUtil, WniClausesUtil,
    QuoteClauseTableUtil,
} from 'wni-portals-util-js';
import { WizardQuotePageConstants } from 'wni-portals-config-js';

import QuoteClauseDesktopTableHelper from './QuoteClauseDesktopTableHelper';
import SideBySideSingleClauseComponentVM from '../../Clauses/SxS/SideBySideSingleClauseComponentVM';


// const {
//     PAYMENT_TYPES,
//     BLOCKS_QUOTES,
//     BLOCKS_BIND,
//     NON_BLOCKING,
// } = WizardQuotePageConstants;

// const {
//     getSelectedCoverages
// } = QuoteClauseTableUtil;

//--------------------------------------------
// Default QuoteClauseTableUIHelper
function QuoteClauseTableUIHelper(props) {
    const {
        columnData,
        isCoveragesEditable = true,
        // staleQuoteBranchCodes,
        // resetBranchCodes,
        loadingClause,
        // updateStaleQuoteBranchCodes,
        // updateResetBranchCodes,
        updateLoadingClause,
        // updatePriceDifference,
        // onStaleQuoteBranchCode,
        onSyncCoverages,
        onChangeSubmissionAndSync,
        // onBuyNow, onRecalculate, onResetQuote,
        // onChangeSubmission,
        //
        onValidate,
        showErrors,

        // ==================================
        tableData,
    } = props;

    const getChangedOfferingPathsAndSetClauseToLoading = (chosenTermPath) => {
        const changedColumn = QuoteClauseTableUtil.getChangedColumnData(chosenTermPath, columnData);
        const changedClause = QuoteClauseTableUtil.getChangedClause(chosenTermPath, columnData);
        // const lobOfferingPath = changedColumn.lobOffering.path;
        // const sxsPeriodPath = changedColumn.sxsPeriod.path;
        const {
            // sxsPeriod: {
            //     path: sxsPeriodPath,
            // },
            lobOffering: {
                path: lobOfferingPath,
                index: lobOfferingIndex,
            },
        } = changedColumn;
        // const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        updateLoadingClause({
            clauseID: changedClause.coveragePublicID || changedClause.publicID,
            quoteCode: changedColumn.code
        });
        // updateResetBranchCodes(updatedBranchCodes);

        return { lobOfferingPath, lobOfferingIndex };
    };

    const syncCoverages = async (value, path) => {
        const { lobOfferingPath, lobOfferingIndex } = getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobOfferingPath, lobOfferingIndex).then(() => {
                updateLoadingClause(undefined);
            });
        }
    };

    const changeSubmissionAndSync = async (value, chosenTermPath) => {
        // const { onChangeSubmissionAndSync } = props;

        const { lobOfferingPath, lobOfferingIndex } = getChangedOfferingPathsAndSetClauseToLoading(
            chosenTermPath
        );

        if (onChangeSubmissionAndSync) {
            await onChangeSubmissionAndSync(value, chosenTermPath, lobOfferingPath, lobOfferingIndex);
            updateLoadingClause(undefined);
        }
    };

    const renderCellContent = (tableRow, index, propParams) => {
        // TODO: need to check the value of onChangeSubmissionTmp
        // const { onChangeSubmission: onChangeSubmissionClause } = propParams;
        const onChangeSubmissionClause = _.noop;

        // propParams is defined in QuoteClauseDesktopTableHelper.getTableColumnContent()::componentProps
        // const { path, clauses = [], code, vehicleFixedId } = propParams.data;
        // const { isSxsEditable, periodPublicID, coverageIssues } = propParams;
        const {
            publicID: rowPublicID,
            codeIdentifier: rowCodeIdentifier, // ref: QuoteCoveragesUtil.structureClauseTableData()
        } = tableRow;
        const {
            // See PAQuoteUIHelper.generateTableData()/generateClauseData()
            data: {
                code,
                path,
                clauses = [],
                pairClauses = [],
            },
            // See QuoteClauseDesktopTableHelper.getTableColumnContent()
            isSxsEditable,
            periodPublicID,
            coverageIssues,
            vehicleCoverageSets,
            vehicleFixedId,
        } = propParams;

        const isSignatureVersion = !isSxsEditable;

        const clauseIndex = clauses.findIndex(
            (clause) => clause.publicID === rowPublicID
        );

        if (clauseIndex < 0) {
            let renderContent = null;
            if (rowCodeIdentifier === 'PACustomEquipCov_Ext') {
                const customEquipTerms = QuoteCoveragesUtil.getVehicleCustomEquipTerms(vehicleCoverageSets, vehicleFixedId);
                renderContent = (
                    <div>
                        <CustomEquipmentSingleClauseComponentVM
                            idPrefix={code}
                            value={{ publicID: 'Custom Equipment Coverage' }}
                            customEquipTerms={customEquipTerms}
                            withoutCustomEquipmentCoverage
                        />
                    </div>
                );
            } else if (rowCodeIdentifier === 'PAAcdntForCov_Ext') {
                const isPairSelected = QuoteCoveragesUtil.isClauseSelected(rowPublicID, pairClauses);
                if (isSignatureVersion && isPairSelected) {
                    renderContent = (
                        <div>
                            <AccidentForgivenessClauseComponent />
                        </div>
                    );
                }
            }

            return renderContent;
        }

        const clause = clauses[clauseIndex];

        // ==See PAQuoteUIHelper.getLineCoveragesUniqueID()/getVehicleCoveragesUniqueID()==
        const availableForCurrentBranch = QuoteCoveragesUtil.filterCovOnQuotePage(clause,
            coverageIssues, vehicleFixedId);
        // if (!availableForCurrentBranch) {
        //     return null;
        // }
        // ================================================================================

        const clauseID = _.get(loadingClause, 'clauseID');
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(clauseID);
        const isClauseLoading = (_.get(loadingClause, 'clauseID') === clause.publicID && _.get(loadingClause, 'quoteCode') === code);
        const labelPosition = columnData && columnData.length <= 2 ? 'left' : 'top';
        const clausePatternCode = clause.code_Ext;

        const clauseContainerId = WniClausesUtil.getClauseContainerId(code, clause.publicID, vehicleFixedId);
        const clauseComponentProps = {
            value: clause,
            path: `${path}.children[${clauseIndex}]`,
            onChangeClause: onChangeSubmissionClause,
            onSyncCoverages: syncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            loadingClause: isClauseLoading || isQuoteLoading,
            idPrefix: code,
            isEditable: isCoveragesEditable,
            isDisabled: isSignatureVersion,
            labelPosition: labelPosition,
            showSchedule: false,
            loadingIconType: 'small',
            onValidate,
            showErrors,
        };

        if (clausePatternCode === 'PACustomEquipCov_Ext') {
            const customEquipTerms = QuoteCoveragesUtil.getVehicleCustomEquipTerms(vehicleCoverageSets, vehicleFixedId);
            return (
                <div id={clauseContainerId}>
                    <CustomEquipmentSingleClauseComponentVM
                        {...clauseComponentProps}
                        customEquipTerms={customEquipTerms}
                        withoutCustomEquipmentCoverage={isSignatureVersion}
                    />
                </div>
            );
        }

        return (
            <div id={clauseContainerId} className="SxSClauseContainer">
                <SideBySideSingleClauseComponentVM {...clauseComponentProps} />
            </div>
        );
    };


    // ===================================================
    const desktopTableHelper = QuoteClauseDesktopTableHelper({
        tableData, columnData, renderCellContent,
    });

    return {
        // remove private method from interface
        // getChangedOfferingPathsAndSetClauseToLoading,
        //
        // renderCellContent,
        //
        generateDesktopOverrides: desktopTableHelper.generateDesktopOverrides,
    };
}

export default QuoteClauseTableUIHelper;
