import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.del',
        defaultMessage: 'Delete Selected'
    },
    scheduleEdit: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.edit',
        defaultMessage: 'Edit'
    },
    scheduleView: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.view',
        defaultMessage: 'View'
    },
    CoveredPremises: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.CoveredPremises',
        defaultMessage: 'Covered Premises'
    },
    itemFormNotClosedMessage: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.itemFormNotClosedMessage',
        defaultMessage: 'Click Cancel or Save to continue.'
    },
    scheduleItemInvalidMessage: {
        id: 'InlandMarine.miscforms.coveredPremises.schedules.table.scheduleInvalidMessage',
        defaultMessage: 'Information for this schedule is missing.'
    }
})