import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'policycommon.directives.schedules.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'policycommon.directives.schedules.table.del',
        defaultMessage: 'Delete Selected'
    },
    scheduleCancel: {
        id: 'policycommon.directives.schedules.table.Cancel',
        defaultMessage: 'Cancel',
    },
    scheduleRemoveTitle: {
        id: 'policycommon.directives.schedules.table.Remove Scheduled Item?',
        defaultMessage: 'Remove Scheduled Item?',
    },
    scheduleRemove: {
        id: 'policycommon.directives.schedules.table.Are you sure you want to remove this item?',
        defaultMessage: 'Are you sure you want to remove this item?',
    },
    noItems: {
        id: 'policycommon.directives.schedules.table-readonly.No items',
        defaultMessage: 'No items'
    },
    delete: {
        id: 'policycommon.directives.schedules.table.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'policycommon.directives.schedules.table.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'policycommon.directives.schedules.table.Save',
        defaultMessage: 'Save'
    }
});
