import React, {
    useContext, useCallback, useState, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useWniModal } from 'wni-components-platform-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import messages from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemsComponent.messages';
import { valueTypeMap, getFormattedValue, processModalData } from './ScheduleItemsUtil';
import ScheduleItemModalPopover from './ScheduleItemModalPopover';
import ScheduleItemRemovePopover from './ScheduleItemRemovePopover';
import styles from './ScheduleItemsComponent.module.scss';
import metadata from './ScheduleItemsComponent.metadata.json5';

import { Button } from '@jutro/legacy/components';

function ScheduleItemsComponent(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [isLoading, setLoading] = useState(false);
    const {
        value: originScheduleItem,
        labelPosition,
        id,
        readOnly,
        onScheduleChange,
        path,
        showTitle
    } = props;

    const scheduleItem = {
        ...originScheduleItem,
        scheduleItems: _.sortBy(originScheduleItem.scheduleItems, 'itemNumber')
    };

    const renderCell = useCallback((items, index, property) => {
        const { id: pathToValue, valueType } = property.data;

        if (valueType === 'ICON') {
            const { content } = property.data;
            const iconActionDom = (
                <div className="align-right">
                    {
                        content.map((item) => {
                            const { icon, onClick } = item;
                            return (
                                <Button
                                    className="wni-button-icon-border"
                                    icon={icon}
                                    type="outlined"
                                    size="small"
                                    onClick={onClick(items, index)}
                                />
                            );
                        })
                    }
                </div>
            );
            return iconActionDom;
        }

        const valueObject = _.get(items.itemData, pathToValue);
        const value = _.get(valueObject, valueTypeMap[valueType]);

        if (valueType === 'INTEGER' && _.has(property.data, 'currency')) {
            const { currency } = property.data;
            return intl.formatNumber(
                value,
                {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'symbol'
                }
            );
        }
        if (valueType === 'DATE') {
            return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
        }
        return getFormattedValue(value, valueType, property.data);
    }, [intl]);

    const generateColumnOverrides = useCallback(
        (columnData) => {
            const overrideProps = columnData.map((info, index) => ({
                [`scheduleItemColumn${index}`]: {
                    renderCell: renderCell,
                    header: info.label,
                    textAlign: info.textAlign ? info.textAlign : 'left'
                }
            }));
            return Object.assign({}, ...overrideProps);
        },
        [renderCell]
    );

    const showModal = useCallback(
        async (scheduleData) => {
            const componentProps = {
                propertyInfo: scheduleItem.propertyInfos,
                scheduleData: processModalData(scheduleData),
                viewModelService,
                scheduleItem,
                labelPosition,
                id,
                isNew: !scheduleData
            };

            const result = await modalApi.showModal(
                <ScheduleItemModalPopover {...componentProps} />
            );
            return result;
        },
        [id, labelPosition, scheduleItem, viewModelService]
    );

    const removeSchedule = useCallback(
        (scheduleToRemove) => async () => {
            const componentProps = {
                title: translator(messages.scheduleRemoveTitle),
                message: translator(messages.scheduleRemove),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messages.delete),
                cancelButtonText: translator(messages.cancel),
            };
            const result = await modalApi.showModal(
                <ScheduleItemRemovePopover {...componentProps} />
            );
            if (result) {
                const clonedSchedule = _.cloneDeep(scheduleItem);
                clonedSchedule.scheduleItems = clonedSchedule.scheduleItems.filter(
                    (schedule) => !_.isEqual(schedule, scheduleToRemove)
                );

                if (onScheduleChange) {
                    setLoading(true);
                    onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                }
            }
        },
        [onScheduleChange, path, scheduleItem, translator]
    );

    const editSchedule = useCallback(
        (schedule, index) => () => {
            const localPathToSchedule = `scheduleItems[${index}]`;

            showModal(schedule)
                .then((updatedSchedule) => {
                    const clonedSchedule = _.cloneDeep(scheduleItem);
                    _.set(clonedSchedule, localPathToSchedule, updatedSchedule);

                    if (onScheduleChange) {
                        setLoading(true);
                        onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                    }
                })
                .catch(_.noop);
        },
        [onScheduleChange, path, scheduleItem, showModal]
    );

    const addSchedule = useCallback(() => {
        showModal()
            .then((schedule) => {
                const clonedSchedule = _.cloneDeep(scheduleItem);
                const ScheduleItemHasMaxItemNumber = _.maxBy(
                    scheduleItem.scheduleItems,
                    'itemNumber'
                );
                const maxItemNumberOfCurrentScheduleItem = _.get(ScheduleItemHasMaxItemNumber, 'itemNumber') || 0;
                const newScheduleItem = {
                    '@deserialization-class': scheduleItem.deserializationClass,
                    ...schedule,
                    itemNumber: maxItemNumberOfCurrentScheduleItem + 1,
                    itemData: {
                        ...schedule.itemData,
                        ScheduleNumber: {
                            integerValue: maxItemNumberOfCurrentScheduleItem + 1
                        }
                    }
                };

                clonedSchedule.scheduleItems = [...scheduleItem.scheduleItems, newScheduleItem];

                if (onScheduleChange) {
                    setLoading(true);
                    onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                }
            })
            .catch(_.noop);
    }, [onScheduleChange, path, scheduleItem, showModal]);

    const columnData = useMemo(() => {
        const orderedPropertyInfo = _.sortBy(scheduleItem.propertyInfos, 'order');
        const icons = {
            valueType: 'ICON',
            textAlign: 'right',
            content: [
                {
                    icon: 'gw-delete',
                    onClick: removeSchedule
                },
                {
                    icon: 'gw-edit',
                    onClick: editSchedule
                }
            ]
        };
        const iconsShow = !readOnly ? icons : [];
        return [...orderedPropertyInfo, iconsShow];
    }, [editSchedule, readOnly, removeSchedule, scheduleItem.propertyInfos]);

    const renderAdd = useCallback(
        () => (
            <div className="d-flex justify-content-end">
                <Button icon="gw-add" size="small" onClick={addSchedule} type="outlined">
                    {messages.scheduleAdd}
                </Button>
            </div>
        ),
        [addSchedule]
    );

    const overrideProps = useMemo(() => {
        return {
            scheduleItemTable: {
                data: columnData,
                title: showTitle ? scheduleItem.displayName : undefined,
                renderTitleAction: !readOnly ? renderAdd : undefined,
                placeholder: readOnly ? messages.noItems : ''
            },
            ...generateColumnOverrides(columnData)
        };
    }, [
        columnData,
        scheduleItem.displayName,
        showTitle,
        readOnly,
        renderAdd,
        generateColumnOverrides
    ]);

    const readValue = useCallback(
        (elementId, schedulePath) => {
            return _.get(scheduleItem, schedulePath);
        },
        [scheduleItem]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

ScheduleItemsComponent.propTypes = {
    value: PropTypes.shape({
        scheduleItems: PropTypes.arrayOf(PropTypes.shape({})),
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onScheduleChange: PropTypes.func,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    readOnly: PropTypes.bool,
    showTitle: PropTypes.bool
};

ScheduleItemsComponent.defaultProps = {
    onScheduleChange: undefined,
    readOnly: false,
    labelPosition: undefined,
    showTitle: true
};

export default ScheduleItemsComponent;
