import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniSubmissionService } from 'wni-capability-gateway';
import {
    QuoteUtil,
    DocumentsUtil,
    DTOUtil,
    ErrorsAndWarningsUtil,
    UWIssueUtil,
    WniPNIUtil,
} from 'wni-portals-util-js';
import { DocumentCheckedComponent } from 'wni-capability-gateway-react';
import { WniPAQuoteService } from 'wni-capability-quoteandbind';
import { R1Config, WizardConstants } from 'wni-portals-config-js';
import { QuoteUWIssuesPendingList } from 'wni-components-platform-react';
import metadata from './PAPolicySummaryPage.metadata.json5';
import messages from './PAPolicySummaryPage.messages';
// import marketingMessageDropdownItems from './PAPolicySummaryPage.static';
import styles from './PAPolicySummaryPage.module.scss';


const {
    PolicyInformationPageConfig: {
        marketingMessageOptions,
    }
} = R1Config;

function PAPolicySummaryPage(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        wizardData: submissionVM, updateWizardData, history,
        wizardPageData
    } = props;
    const { domainCompany } = useDependencies('domainCompany');
    const localeService = ServiceManager.getService('locale-service');
    const { authHeader } = useAuthentication();
    const { onValidate } = useValidation('PolicyInformationPage');
    const [retainInAgencyData, updateRetainInAgencyData] = useState([]);
    const [submitToWesternNationalData, updateSubmitToWesternNationalData] = useState([]);
    const [initialized, updateInitialized] = useState(false);

    const {
        quoteID,
        sessionUUID,
        baseData: {
            PolicyInfoSource_Ext: policyInfoSource,
            selectedVersion_Ext: selectedVersion,
            primaryNamedInsured_Ext: {
                displayName: pniDisplayName,
                emailAddress1: pniEmailAddress,
            },
            primaryNamedInsured_Ext: primaryNamedInsured,
        },
        bindData
    } = submissionVM.value;

    const {
        [WizardConstants.paymentViewType]: paymentViewType,
        [WizardConstants.sideBySideData]: sideBySideData,
    } = wizardPageData;

    const pniPrimaryPhoneNumber = WniPNIUtil.getPrimaryPhoneNumber(primaryNamedInsured);

    const infoUWIssueBaseTitle = translator(messages.requiringPostIssueReview);

    const updateBindData = async () => {
        submissionVM.value.bindData = await WniPAQuoteService.retriveBindingData(quoteID,
            sessionUUID, authHeader);
        updateWizardData(submissionVM);
    };

    useEffect(() => {
        if (_.isEmpty(bindData)) {
            updateBindData();
        }
    }, []);

    useEffect(() => {
        const getAvailableDocumentsData = (async () => {
            const documents = await WniSubmissionService.getPortalDocumentsForSubmissionGroupByType(
                [quoteID], authHeader
            );
            const retainInAgency = DocumentsUtil.getDocumentDataByVersion(documents,
                DocumentsUtil.RETAIN_IN_AGENCY, selectedVersion);
            const submitToWesternNational = DocumentsUtil.getDocumentData(documents,
                DocumentsUtil.SUBMIT_TO_WESTERNNATIONAL);
            return { retainInAgency, submitToWesternNational };
        });
        if (!initialized) {
            getAvailableDocumentsData().then((res) => {
                const { retainInAgency, submitToWesternNational } = res;
                updateRetainInAgencyData(retainInAgency);
                updateSubmitToWesternNationalData(submitToWesternNational);
            });
            updateInitialized(true);
        }
    }, [authHeader, quoteID, updateRetainInAgencyData, retainInAgencyData, initialized, selectedVersion]);

    const onNext = useCallback(
        async () => {
            const updatedQuoteDataDTO = await WniPAQuoteService.updateMarketingMessage(
                _.get(submissionVM, 'quoteID.value', null),
                _.get(submissionVM, 'sessionUUID.value', null),
                _.get(submissionVM, 'baseData.marketingMessage_Ext.value', null),
                paymentViewType,
                authHeader
            );
            submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, updatedQuoteDataDTO);
            return submissionVM;
        },
        [authHeader, paymentViewType, submissionVM]
    );

    const renderCellContent = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        if (path === 'licenseState') {
            return translator(
                {
                    id: `typekey.State.${data.licenseState}`,
                    defaultMessage: data.licenseState
                }
            );
        }
        return _.get(data, path);
    },
    [translator]);

    const isShowNotification = useCallback(() => {
        if ((policyInfoSource) && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const getMarketingMessageDropDownItems = useCallback(() => {
        const { domainDesc } = domainCompany;
        return marketingMessageOptions[domainDesc]
            ? marketingMessageOptions[domainDesc]
            : marketingMessageOptions.default;
    }, [domainCompany]);

    const getMarketingDefaultValue = useCallback(() => {
        const { domainDesc } = domainCompany;
        const vals = marketingMessageOptions[domainDesc]
            ? marketingMessageOptions[domainDesc][0]
            : marketingMessageOptions.default[3];
        return vals.code;
    }, [domainCompany]);
    const handleMarketMsgFn = (value, path) => {
        _.set(submissionVM, `${path}.value`, value);
        updateWizardData(submissionVM);
    };

    // ====================================================================
    const totalCost = QuoteUtil.getSelectdVersionDisplayedPremium(
        selectedVersion, paymentViewType, sideBySideData,
    );
    const infoUWIssues = UWIssueUtil.getPendingInfoIssuesFromSxsData(sideBySideData, selectedVersion);
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        totalPremium: {
            value: totalCost,
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry(),
            // value: bindData && bindData.contactPhone,
            value: pniPrimaryPhoneNumber,
        },
        emailAddress: {
            // value: bindData && bindData.contactEmail
            value: pniEmailAddress,
        },
        vehicleList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
        },
        driverList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
        },
        userAggreenmenLi1: {
            content: messages.UserAggreenmenli1
        },
        userAggreenmenLi2: {
            content: translator(messages.UserAggreenmenli2, { domainName: `${domainCompany.domainName}` })
        },
        lossPolicyReleaseForm: {
            visible: isShowNotification()
        },
        lossPolicyReleaseFormDiv: {
            visible: isShowNotification()
        },
        marketingMessageHeading: {
            visible: false
        },
        marketingMessage: {
            availableValues: getMarketingMessageDropDownItems(),
            onValueChange: handleMarketMsgFn,
            defaultValue: getMarketingDefaultValue(),
            visible: false
        },
        documentcheckedList: {
            history: history,
            submission: submissionVM,
            renderData: [
                {
                    subTitle: messages.RetaininAgency,
                    data: retainInAgencyData,
                },
                {
                    subTitle: translator(messages.WesternNational, { domainName: `${domainCompany.domainName}` }),
                    data: submitToWesternNationalData,
                }
            ],
        },
        uwIssuePendingList: {
            visible: !_.isEmpty(infoUWIssues),
            uwIssues: infoUWIssues,
        },
        periodStartDate: {
            showRequired: false,
            required: false,
            value: _.get(submissionVM.value, 'baseData.periodStartDate'),
            showErrors: false
        },
        periodEndDate: {
            showRequired: false,
            required: false,
            value: _.get(submissionVM.value, 'baseData.periodEndDate'),
            showErrors: false
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onRenderCell: renderCellContent
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            documentcheckedcomponent: DocumentCheckedComponent,
            // quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            uwissuependinglist: QuoteUWIssuesPendingList,
        }

    };
    return (
        <WizardPage
            onNext={onNext}
            // disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PAPolicySummaryPage.propTypes = wizardProps;
export default PAPolicySummaryPage;
