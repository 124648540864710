import React, {
    useState,
    useEffect,
    useCallback
} from 'react'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniInputNumber } from 'wni-common-base-components';
import { useTranslator } from '@jutro/locale';
import messages from './DetailsCR7IntegerValidation.messages'

const DetailsCR7IntegerValidation = (props) => {

    const {
        displayable,
        detailsIndex,
        onValidate: onContainerValidate,
        disabled,
        showErrors,
        isEditable,
        changeDetailsAndSync,
        cr7IntegerValidationMaxValue,
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        integerValue,
    } = displayable

    const translator = useTranslator();

    const [editingValue, setEditingValue] = useState(integerValue)

    const validationID = `displayable_integerinput_${propertyName}`
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(validationID);

    const checkValueNotHigherthanTotalNumberOfRatableEmployees = useCallback(() => {
        
        return editingValue <= cr7IntegerValidationMaxValue
    }, [cr7IntegerValidationMaxValue, editingValue])

    useEffect(() => {
        registerComponentValidation(checkValueNotHigherthanTotalNumberOfRatableEmployees);
    }, [checkValueNotHigherthanTotalNumberOfRatableEmployees, registerComponentValidation]);

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, validationID);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, validationID);
            }
        }
    }, [isComponentValid, onContainerValidate, validationID])

    useEffect(() => {
        setEditingValue(integerValue)
    }, [integerValue])

    const onIntegerValueChange = (newValue) => {
        setEditingValue(newValue)
    }

    const onBlur = () => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            integerValue: editingValue
        }
        changeDetailsAndSync(newDisplayable, detailsIndex)
    }

    return <WniInputNumber
        id={validationID}
        labelPosition='left'
        label={label}
        value={editingValue}
        onValueChange={onIntegerValueChange}
        onBlur={onBlur}
        placeholder=""
        size="medium"
        disabled={disabled}
        className='flex-1'
        required={required}
        showRequired
        onValidationChange={onValidate}
        showErrors={showErrors}
        readOnly={!isDisplayableEditable || !isEditable}
        allowNegative={false}
        decimalPlaces={0}
        validationMessages = {showErrors && !checkValueNotHigherthanTotalNumberOfRatableEmployees() ? [translator(messages.CR7LineNumERISAPlanOfficials_Ext)] : undefined}
    />
}

export default DetailsCR7IntegerValidation