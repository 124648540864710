
const ProductionWorkflow = [
    {
        name: 'Production',
        code: 'production',
        get TestingWorkflow() { return false; },
    },
    {
        name: 'Testing',
        code: 'testing',
        get TestingWorkflow() { return true; },
    }
];

// For R1, it's 'Classical'
// For R2, it is, well, 'R2'
export const DEFAULT_PRODUCTION_WORKFLOW = ProductionWorkflow[0];

export default ProductionWorkflow;