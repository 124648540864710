import { defineMessages } from 'react-intl';

export default defineMessages({
    contactGlassTitle: {
        id: 'fnol.pa.views.pa-contact-admin.Contact Glass Administrator',
        defaultMessage: 'Contact Glass Administrator'
    },
    towingLaborOnlyTitle: {
        id: 'fnol.pa.views.pa-contact-admin.Contact Roadside Assistance Administrator',
        defaultMessage: 'Contact Roadside Assistance Administrator'
    },
    contactGlassCall: {
        id: 'fnol.pa.views.pa-contact-admin.contactGlassCall',
        defaultMessage: 'Please call 1-855-921-3164 for assistance in reporting your claim.'
    },
    towingLaborOnlyCall: {
        id: 'fnol.pa.views.pa-contact-admin.contactTowingLaborCall',
        defaultMessage: 'Please call 1-855-921-3164 to receive assistance.'
    }
})