import { defineMessages } from 'react-intl';

export default defineMessages({
    walDummyPage: {
        id: "wni.quoteandbind.wal.wizard.step.Dummy Page",
        defaultMessage: 'Dummy Page'
    },
    walPolicyDetialsPage: {
        id: "wni.quoteandbind.wal.wizard.step.Policy Details Page",
        defaultMessage: 'Policy Details Page'
    },
    // walQuotePage: {
    //     id: "quoteandbind.wal.wizard.step.Quote & Price",
    //     defaultMessage: "Quote & Price",
    // },
    walOperatorsPage: {
        id: 'wni.quoteandbind.wal.wizard.step.Operators',
        defaultMessage: 'Operators',
    },
});
