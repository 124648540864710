import { defineMessages } from 'react-intl';

export default defineMessages({
    complexSchedulePopupSave: {
        id: 'CommercialCrime.directives.schedules.complexSchedulePopup.Save',
        defaultMessage: 'Save',
    },
    complexSchedulePopupExit: {
        id: 'CommercialCrime.directives.schedules.complexSchedulePopup.Exit',
        defaultMessage: 'Exit',
    },
    scheduledItemTitle: {
        id: 'CommercialCrime.directives.schedules.complexSchedulePopup.Scheduled Item',
        defaultMessage: 'Scheduled Item',
    },
});