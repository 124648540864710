import React, {
    useState, useEffect, useContext, useCallback, useRef
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import metadata from './EditCoveragesSummary.metadata.json5';
import styles from './EditCoveragesSummary.module.scss';
import messages from '../../../PECPWizard.messages';

const EditCoveragesSummary = (props) => {
    const modalApi = useModal();
    const {
        submissionVM,
        updateWizardData,
        selectedLocation,
        selectedBuilding,
        completeEdit
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [coverageSubmission, updateCoverageSubmission] = useState({});
    const [loadingClause, updateLoadingClause] = useState();
    const { isComponentValid, onValidate } = useValidation();

    const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';
    const updatedCoverage = useRef({});

    const saveEditedCoverages = useCallback(
        async (isCompleteEdit) => {
            const quoteId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
            const sessionId = _.get(submissionVM.value, 'sessionUUID');
            const locationId = _.get(selectedBuilding, 'locationId');

            const response = await CPCoverablesService.updateCPBuildingCoverages(
                quoteId,
                locationId,
                coverageSubmission.value,
                sessionId,
                authHeader
            );

            const location = _.get(submissionVM.value, LOCATION_PATH).find((loc) => {
                return loc.publicID === selectedLocation.publicID;
            });

            const buildingIndex = _.findIndex(location.buildings, (building) => {
                return building.publicID === response.publicID;
            });
            location.buildings.splice(buildingIndex, 1, response);

            updateWizardData(submissionVM);
            updatedCoverage.current = {
                submission: submissionVM.value,
                isSavedOrCancelled: true
            };
            completeEdit(true, isCompleteEdit);
        },
        [
            submissionVM,
            selectedBuilding,
            coverageSubmission.value,
            authHeader,
            updateWizardData,
            completeEdit,
            selectedLocation.publicID
        ]
    );

    const onSaveClicked = useCallback(() => {
        saveEditedCoverages(true);
    }, [saveEditedCoverages]);

    useEffect(() => {
        const submission = viewModelService.create(
            selectedBuilding,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverages.dto.BuildingCoverageDTO'
        );
        updateCoverageSubmission(submission);

        updatedCoverage.current = {
            submission: submission.value,
            isSavedOrCancelled: false
        };

        return () => {
            const isBuildingSaved = !updatedCoverage.current.isSavedOrCancelled
                && !_.isEqual(
                    selectedBuilding.coverages,
                    updatedCoverage.current.submission.coverages
                );

            if (isBuildingSaved) {
                modalApi.showConfirm({
                    title: messages.unsavedChangesTitle,
                    message: messages.unsavedChangesMessage,
                    status: 'warning',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.editLocationCancelChangesConfirmationYes,
                    cancelButtonText: messages.editLocationCancelChangesConfirmationNo
                }).then((results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    return saveEditedCoverages(false);
                }, _.noop);
            }
        };
        // Do not add dependency page is getting re-rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = ClausesUtil.setClauseValue(
                viewModelService.clone(coverageSubmission),
                value,
                changedPath
            );
            updateCoverageSubmission(newSubmissionVM);
            updatedCoverage.current = {
                submission: newSubmissionVM.value,
                isSavedOrCancelled: false
            };

            return newSubmissionVM;
        },
        [coverageSubmission, updateCoverageSubmission, viewModelService]
    );

    const onClauseChange = useCallback(
        async (value, changedPath) => {
            const quoteID = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
            const sessionUUID = _.get(submissionVM.value, 'sessionUUID');
            const locationId = _.get(selectedBuilding, 'locationId');

            const updatedSubmission = changeSubmission(value, changedPath);

            if (!value) {
                return false;
            }
            let coverageResponse = {};
            try {
                const updatedCoverageResponse = await CPCoverablesService.updateCPBuildingCoverages(
                    quoteID,
                    locationId,
                    updatedSubmission.value,
                    sessionUUID,
                    authHeader
                );

                const newSubmissionVM = viewModelService.clone(updatedSubmission);
                _.set(newSubmissionVM, 'value', updatedCoverageResponse);
                coverageResponse = newSubmissionVM;
                updatedCoverage.current = {
                    submission: newSubmissionVM.value,
                    isSavedOrCancelled: false
                };
                updateCoverageSubmission(coverageResponse);
            } catch {
                modalApi.showAlert({
                    title: messages.coveragesNotSavedErrorTitle,
                    message: messages.coveragesNotSavedErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
            updateLoadingClause(false);
            return coverageResponse;
        },
        [submissionVM.value, selectedBuilding, changeSubmission, authHeader, viewModelService]
    );

    const syncClauses = useCallback(
        (value, changedPath, latestSubmission = coverageSubmission) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(latestSubmission, basePath);
            const clauses = _.get(latestSubmission, 'coverages.value');

            if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
                if (value) {
                    updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
                }
                onClauseChange(value, changedPath);
            }
        },
        [onClauseChange, coverageSubmission]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const latestSubmissionVM = changeSubmission(value, changedPath);
            syncClauses(value, changedPath, latestSubmissionVM);

            updatedCoverage.current = {
                submission: latestSubmissionVM.value,
                isSavedOrCancelled: false
            };
        },
        [changeSubmission, syncClauses, updatedCoverage]
    );

    const showCancelModal = useCallback(() => {
        return modalApi.showConfirm({
            title: messages.editBuildingCancelChangesTitle,
            message: messages.editBuildingCancelChangesMessage,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: messages.editLocationCancelChangesConfirmationYes,
            cancelButtonText: messages.editLocationCancelChangesConfirmationNo
        }).catch(_.noop);
    }, []);

    const onCancelClicked = useCallback(() => {
        const isComplete = true;
        const toCollapseBuildingDetails = true;
        const isCancelled = true;

        updatedCoverage.current = {
            ...updatedCoverage.current,
            isSavedOrCancelled: true
        };

        if (!_.isEqual(selectedBuilding, updatedCoverage.current.submission)) {
            showCancelModal().result.then(() => {
                completeEdit(isComplete, toCollapseBuildingDetails, isCancelled);
            });
        } else {
            completeEdit(isComplete, toCollapseBuildingDetails, isCancelled);
        }
    }, [selectedBuilding, showCancelModal, completeEdit]);

    const overrideProps = {
        coverageClauses: {
            loadingClause: loadingClause
        },
        saveButton: {
            disabled: !isComponentValid
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onSaveClicked: onSaveClicked,
            onCancelClicked: onCancelClicked,
            onValidate: onValidate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={coverageSubmission}
            overrideProps={overrideProps}
            onModelChange={updateCoverageSubmission}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

EditCoveragesSummary.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    selectedLocation: PropTypes.shape({
        publicID: PropTypes.shape({})
    }).isRequired,
    selectedBuilding: PropTypes.shape({
        coverages: PropTypes.shape({})
    }).isRequired,
    completeEdit: PropTypes.func.isRequired
};

export default EditCoveragesSummary;
