
import React, {
    useCallback, useEffect, useContext, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './OperatorContactDetails.metadata.json5';
import OperatorUtil from '../../util/OperatorUtil';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function OperatorContactDetails(props) {
    const {
        operatorVM,
        relationshipOptions,
        availableAccountContacts,
        onValueChange,
        showErrors,
        isReadOnly,
        isShowMaritalStatus,
        isShowGender
    } = props;

    const [selectedContact, updateSelectedContact] = useState();

    const {
        relationship,
        primaryPhoneType,
        isPNI
    } = operatorVM.value;

    useEffect(() => {
    }, []);

    const defaultAdditinalInsuredValue = useCallback(() => {
        if (relationship === 'Insured_Ext' || relationship === 'Spouse_Ext') {
            return true;
        }
        return null;
    }, [relationship]);

    const handleRelationship = useCallback((val, path) => {
        onValueChange(val, path);
        if (val === 'Insured_Ext' || val === 'Spouse_Ext') {
            onValueChange(true, 'additionalInsured');
        } else {
            onValueChange(null, 'additionalInsured');
        }
        if (isShowMaritalStatus) {
            if (val === 'Spouse_Ext') {
                onValueChange('M', 'maritalStatus');
            } else if(val === 'Partner_Ext') {
                onValueChange('S', 'maritalStatus');
            }
        }
        if (!isShowMaritalStatus && val === 'Spouse_Ext') {
            onValueChange('M', 'maritalStatus');
        }
    }, [onValueChange]);

    const isShowAdditionalInsured = useCallback(() => {
        if ((relationship === 'Spouse_Ext'
            || relationship === 'Partner_Ext'
            || relationship === 'Insured_Ext')
            && !isPNI) {
            return true;
        }
        return false;
    }, [relationship, isPNI]);

    // clear value of fields that are not displayed
    const handlePrimaryPhone = useCallback((val, path) => {
        onValueChange(val, path);
        if (val === 'mobile') {
            onValueChange(null, 'work');
            onValueChange(null, 'home');
        }
        if (val === 'work') {
            onValueChange(null, 'mobile');
            onValueChange(null, 'home');
        }
        if (val === 'home') {
            onValueChange(null, 'work');
            onValueChange(null, 'mobile');
        }
    }, [onValueChange]);

    const getFullNameOptions = useCallback(() => {
        const res = _.map(availableAccountContacts, (contact) => {
            return {
                name: contact.displayName,
                code: contact.publicID
            }
        });
        res.unshift({
            name: 'New',
            code: 'New'
        });
        return res;
    }, [availableAccountContacts]);

    const handleFullName = (code) => {
        const contact = _.find(availableAccountContacts, (item) => {
            return item.publicID === code
        }) || {};
        updateSelectedContact(_.cloneDeep(contact));
        OperatorUtil.fillExistingContact(contact, onValueChange);
    }

    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        fullName: {
            availableValues: getFullNameOptions(),
            visible: !_.get(operatorVM, 'value.publicID'),
            onValueChange: handleFullName,
            value: !_.isEmpty(selectedContact) ? _.get(selectedContact, 'publicID') : 'New'
        },
        relationship: {
            onValueChange: handleRelationship,
            defaultValue: isPNI ? 'Insured_Ext' : null,
            availableValues: OperatorUtil.getRelationshipOptions(operatorVM.value, relationshipOptions)
        },
        additionalInsured: {
            visible: isShowAdditionalInsured(),
            defaultValue: defaultAdditinalInsuredValue()
        },
        maritalStatus: {
            visible: isShowMaritalStatus
        },
        gender: {
            visible: isShowGender
        },
        primaryPhone: {
            // defaultValue: isPNI ? 'mobile' : '',
            onValueChange: handlePrimaryPhone
        },
        workNumber: {
            visible: primaryPhoneType === 'work'
        },
        homeNumber: {
            visible: primaryPhoneType === 'home'
        },
        cellNumber: {
            visible: primaryPhoneType === 'mobile'
        }
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            operatorVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    // return MetadataContent(metadata.componentContent, override, resolvers);
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={operatorVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            showErrors={showErrors}
        />
    );
}

OperatorContactDetails.propTypes = {
    operatorVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    relationshipOptions: PropTypes.array,
    availableAccountContacts: PropTypes.array,
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    isReadOnly: PropTypes.bool,
};

OperatorContactDetails.defaultProps = {
    operatorVM: null,
    relationshipOptions: [],
    availableAccountContacts: [],
    onValueChange: _.noop,
    showErrors: false,
    isReadOnly: false,
};

export default OperatorContactDetails;
