
const minYear = 1945;
const OTHER = 'Other';
const newGarageLocation = {
    code: 'new',
    name: 'New',
    country: 'US',

}
const requiredForIssuanceData = [
    'vin',
    'otherManufacturer',
    'otherModel',
    'identificationNumber',
    'registrationState',
    'registrationStateExplain',
    'purchaseDate',
    'isPhysicalDamageRequired',
    'currentMarketValue',
    'coverageAmount',
    // info 
    'isThereExistingDamage',
    'existingDamageExplain',
    'hasAntiTheftProtectDevice',
    'isLicensedForRoadUse',
    'whereVehicleStored',
    'selectVehicleApplies',
    'isUsedForBusiness',
    'isUsedForBusinessExplain',
    'hasVehicleBeenModified',
    'hasVehBeenModifiedExplain',
    'hasCoOwner',
    'coOwnerName',
    'hasPhysDamageLast12Months',
    'customEquipment'
];
// const defaultObj = {
//     watercraftDetail: {
//         selectedWatercraftUsages: [],
//         selectedWatercraftKepts: [],
//         selectedWatercraftApplies: [],
//         watercraftDetailType: 'watercraft',
//         isPhysicalDamageCovRequired: true
//     },
//     motorDetail: {
//         watercraftDetailType: 'motor'
//     },
//     trailerDetail: {
//         watercraftDetailType: 'trailer'
//     }
// }

export {
    minYear,
    OTHER,
    requiredForIssuanceData,
    newGarageLocation
}