import React from "react";
import { IMPhysiciansAndSurgeonsPage } from "wni-capability-quoteandbind-im-react";
import { PolicyChangeUtil } from 'wni-portals-util-js';

function IMPhysiciansAndSurgeonsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('IMLine', submissionVM);
    return <IMPhysiciansAndSurgeonsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
};

export default IMPhysiciansAndSurgeonsChangePage;
