import { defineMessages } from 'react-intl';

export default defineMessages({
    followingQuotes: {
        id: 'quoteandbind.common.views.quote-details.Underwriting issues have been raised for the following quotes:',
        defaultMessage: 'Underwriting issues have been raised for the following quotes:'
    },
    weCannotShow: {
        id: 'quoteandbind.common.views.quote-details.We cannot show a quote because it has underwriting issues',
        defaultMessage: 'We cannot show a quote because it has underwriting issues'
    },
    youCanDoTheFollowing: {
        id: 'quoteandbind.common.views.quote-details.You can do the following:',
        defaultMessage: 'You can do the following:'
    },
    editTheQuote: {
        id: 'quoteandbind.common.views.quote-details.Edit the quote if the customer is willing to accept the change',
        defaultMessage: 'Edit the quote if the customer is willing to accept the change'
    },
    approveTheIssue: {
        id: 'quoteandbind.common.views.quote-details.Approve the issue(s)',
        defaultMessage: 'Approve the issue(s)'
    },
    referTheQuote: {
        id: 'quoteandbind.common.views.quote-details.Refer the quote to the underwriter',
        defaultMessage: 'Refer the quote to the underwriter'
    },
    withdrawQuote: {
        id: 'quoteandbind.common.views.quote-details.Withdraw the quote',
        defaultMessage: 'Withdraw the quote'
    },
    approve: {
        id: 'quoteandbind.common.views.quote-details.underwriting-issue.action.Approve',
        defaultMessage: 'Approve'
    },
    blocksBind: {
        id: 'quoteandbind.directives.MultiQuoteView.Blocks bind',
        defaultMessage: 'Blocks bind'
    },
    blocksQuote: {
        id: 'quoteandbind.directives.MultiQuoteView.Blocks quote',
        defaultMessage: 'Blocks quote'
    },
    blocksQuoteRelease: {
        id: 'quoteandbind.directives.MultiQuoteView.Blocks quote release',
        defaultMessage: 'Blocks quote release'
    },
    nonBlocking: {
        id: 'quoteandbind.directives.MultiQuoteView.Non-blocking',
        defaultMessage: 'Non-blocking'
    }
});
