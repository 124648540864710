import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processService(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('hoPaymentChange'), method, data, additionalHeaders);
}

export default class HOPaymentChangeService {
    // /**
    //  * Invokes HOPaymentChangeService.methodName()
    //  * is disabled.
    //  * @param {object} data the QuoteDataDTO json obj
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static methodName(data, authHeader = {}) {
    //     return processService('methodName', [data], authHeader);
    // }


    /**
     * Invokes HOPaymentChangeService.getPolicyChangePaymentData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise} PolicyChangePaymentDataDTO
     */
    static getPolicyChangePaymentData(jobID, sessionUUID, authHeader = {}) {
        return processService('getPolicyChangePaymentData', [jobID, sessionUUID], authHeader);
    }


    /**
     * Invokes HOPaymentChangeService.getPaymentResponseData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise} HOPaymentRetrieveDTO
     */
    static getPaymentResponseData(jobID, sessionUUID, authHeader = {}) {
        return processService('getPaymentResponseData', [jobID, sessionUUID], authHeader);
    }


    /**
     * Invokes HOPaymentChangeService.getPaymentDetailData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise} HOPaymentRetrieveDetailDTO
     */
    static getPaymentDetailData(jobID, sessionUUID, authHeader = {}) {
        return processService('getPaymentDetailData', [jobID, sessionUUID], authHeader);
    }


    /**
     * Invokes HOPaymentChangeService.getBindData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise} BindingDataDTO
     */
    static getBindData(jobID, sessionUUID, authHeader = {}) {
        return processService('getBindData', [jobID, sessionUUID], authHeader);
    }


    /**
     * Invokes HOPaymentChangeService.updatePaymentData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {object} paymentData HOPaymentRetrieveDetailDTO
     * @param {Object} authHeader 
     * @returns {Promise} HOPaymentRetrieveDetailDTO
     */
    static updatePaymentData(jobID, sessionUUID, paymentData, authHeader = {}) {
        return processService('updatePaymentData', [jobID, sessionUUID, paymentData], authHeader);
    }

    /**
     * Invokes HOPaymentChangeService.updatePaymentInstrument()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {object} bankDetailsDTO BankDetailsDTO
     * @param {Object} authHeader 
     * @returns {Promise} String
     */
    static updatePaymentInstrument(jobID, sessionUUID, bankDetailsDTO, authHeader = {}) {
        return processService('updatePaymentInstrument', [jobID, sessionUUID, bankDetailsDTO], authHeader);
    }

    /**
     * Invokes HOPaymentChangeService.createOrUpdateBillingContact()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {object} accountContactDTO AccountContactDTO
     * @param {Object} authHeader 
     * @returns {Promise} AccountContactDTO
     */
    static createOrUpdateBillingContact(jobID, sessionUUID, accountContactDTO, authHeader = {}) {
        return processService('createOrUpdateBillingContact', [jobID, sessionUUID, accountContactDTO], authHeader);
    }


    /**
     * Invokes HOPaymentChangeService.getPortalDocuments()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise} WniDocumentWithProductDTO[]
     */
    static getPortalDocuments(jobID, sessionUUID, authHeader = {}) {
        return processService('getPortalDocuments', [jobID, sessionUUID], authHeader);
    }
    

    // /**
    //  * Invokes HOPaymentChangeService.refreshAutoPayEnrollmentFormAndGetPaymentData()
    //  * 
    //  * @param {string} jobID 
    //  * @param {string} sessionUUID 
    //  * @param {object} paymentDetailsData HOPaymentRetrieveDetailDTO
    //  * @param {Object} authHeader 
    //  * @returns {Promise} PolicyChangePaymentDataDTO
    //  */
    // static refreshAutoPayEnrollmentFormAndGetPaymentData(jobID, sessionUUID, paymentDetailsData, authHeader = {}) {
    //     return processService('refreshAutoPayEnrollmentFormAndGetPaymentData', [jobID, sessionUUID, paymentDetailsData], authHeader);
    // }


    // ================================================================

    /**
     * Invokes HOPaymentChangeService.getPaymentData_del()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     * @deprecated
     */
    static getPaymentData_del(jobID, sessionUUID, authHeader = {}) {
        return processService('getPaymentData_del', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes HOPaymentChangeService.updatePaymentData_del()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID
     * @param {Object} policyChangePaymentData
     * @param {Object} authHeader 
     * @returns {Promise}
     * @deprecated
     */
    static updatePaymentData_del(jobID, sessionUUID, policyChangePaymentData, authHeader = {}) {
        return processService('updatePaymentData_del', [jobID, sessionUUID, policyChangePaymentData], authHeader);
    }

}