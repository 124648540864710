import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Icon, Tooltip } from '@jutro/components';

import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { VehicleComponent } from 'gw-capability-policyjob-react';
import { InValidIconUtil, WniTableRowUtil } from 'wni-portals-util-react';
import { VehicleUtil, WniClausesUtil } from 'wni-portals-util-js';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import styles from './PAVehiclesPage.module.scss';
import metadata from './PAVehiclesPage.metadata.json5';
import customMessages from './PAVehiclesPage.messages';

function PAVehiclesReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        policyChangeGetAdditionalInterestTypeOptions,
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialised, updateIsPageInitialised] = useState(false);
    const [currentRow, updateCurrentRow] = useState(null);
    const [accordionOpendIds, updateAccordionOpendIds] = useState([]);

    const {
        onValidate,
        registerInitialComponentValidation,
    } = useValidation('VehiclePage');

    const { authHeader } = useAuthentication();

    const {
        quoteID,
        sessionUUID,
        policyNumber,
        jobID,
        baseData: {
            effectiveDate,
            jobType,
            periodStatus,
            accountNumber,
            accountContacts_Ext: accountContacts,
        },
        lobData: {
            personalAuto: {
                isForNamedNonOwner_Ext: isQuoteForNamedNonOwner = false,
                coverables: {
                    availableOwners_Ext: availableOwners = [],
                    garagedAtOptions_Ext: garagedAtOptions = [],
                }
            }
        }
    } = submissionVM.value;

    const coverageVMs = WniClausesUtil.getVehicleCoverageVMs(submissionVM);

    const currentVehicleCoverages = VehicleUtil
        .getCurrentVehicleCoverages(coverageVMs, currentRow); // VMListNode
    const accountHolderBirthday = _.get(submissionVM.value, 'baseData.accountHolder.dateOfBirth')
        || _.get(submissionVM.value, 'baseData.accountHolderDateOfBirth_Ext');
    const additionalInterestType = _.get(submissionVM, 'lobData.personalAuto.coverables.addInterestTypeCategory');

    const onVINCell = useCallback((items, index, property) => {
        const vin = items[property.id];
        return vin ? vin.toString().substring(vin.length - 4, vin.length) : '';
    }, []);

    const onVehicleValidationIconCell = useCallback(
        (items, index) => {
            const vehicleVM = _.get(
                submissionVM,
                `lobData.personalAuto.coverables.vehicles.children[${index}]`
            );
            const valid =
                vehicleVM.aspects.valid && vehicleVM.aspects.subtreeValid;
            const vin = _.get(vehicleVM, 'vin.value');
            const vehiclesWithThisVin = _.get(
                submissionVM,
                'lobData.personalAuto.coverables.vehicles.value'
            ).filter((v) => v.vin === vin);
            const isMultipleVehiclesWithSameVin =
                !_.isNil(vin) && vehiclesWithThisVin.length > 1;
            // Implement invalid icon util
            const iconMessages = {
                validToolTip: translator(customMessages.validToolTip),
                inValidToolTip: translator(customMessages.inValidToolTip),
                inValidForInsuranceToolTip: translator(
                    customMessages.inValidForInsuranceToolTip
                ),
                duplicatedVinToolTip: translator(
                    customMessages.duplicatedVinToolTip
                ),
            };
            const invalidIconUtil = InValidIconUtil({ iconMessages });
            return invalidIconUtil.renderInValidIcon(
                valid,
                true,
                isMultipleVehiclesWithSameVin
            );
        },
        [submissionVM, translator]
    );

    const highlightRowFn = useCallback(
        (activeRow) => {
            const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
            WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'vehicleTable');
        },
        [],
    );

    useEffect(() => {
        if (!isPageInitialised) {
            updateIsPageInitialised(true);
        }
    }, [isPageInitialised, submissionVM, updateWizardData]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const getAdditionalInterestTypeOptions = useCallback(async ({
        publicID, isPerson, isCompany, isBank, isTrust
    }) => {
        let options;
        try {
            switch (jobType) {
                case 'Submission':
                    options = await WniQuoteVehicleService.getAdditionalInterestType(
                        quoteID, sessionUUID, publicID, isPerson,
                        isCompany, isBank, isTrust, authHeader
                    );
                    break;
                case 'PolicyChange':
                    options = await policyChangeGetAdditionalInterestTypeOptions({
                        jobID,
                        sessionUUID,
                        publicID,
                        isPerson,
                        isCompany,
                        isBank,
                        isTrust,
                        authHeader
                    });
                    break;
                default: break;
            }
        } catch (e) {
            options = [];
        }
        return options;
    }, [authHeader, jobID, jobType,
        policyChangeGetAdditionalInterestTypeOptions, quoteID, sessionUUID]);

    const viewOrEditVehicle = useCallback((value, index) => {
        const vehicleVM = _.get(submissionVM, `lobData.personalAuto.coverables.vehicles.children.${index}`);
        updateCurrentRow(vehicleVM);
    }, [submissionVM]);

    const onSort = (a, b) => {
        highlightRowFn(currentRow);
        return DatatableUtil.sortString(a, b);
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            visible: false,
            // scrollToIssues: true,
        },
        vehicleTitleAction: {
            visible: false
        },
        vehicleTable: {
            selectionType: 'none'
        },
        vehicleValidationIcon: {
            renderCell: onVehicleValidationIconCell
        },
        vehicleIndex: {
            renderCell: (value) => {
                return WniTableRowUtil.renderCell(
                    value.publicID,
                    value.vehicleNumber ? value.vehicleNumber : '-'
                );
            },
        },
        vin: {
            renderCell: onVINCell,
        },
        type: {
            visible: isQuoteForNamedNonOwner,
            renderCell: (value) => {
                if (value.vehicleType_Ext) {
                    return translator({
                        id: `typekey.VehicleType.${value.vehicleType_Ext}`
                    });
                }
                return '-';
            }
        },
        viewOrEdit: {
            label: translator(customMessages.viewLabel),
            onClick: viewOrEditVehicle,
        },
        vehicle: {
            getAdditionalInterestTypeOptions,
            readOnly: true,
            visible: currentRow != null,
            vehicleVM: currentRow,
            onValueChange: _.noop,
            onClauseUpdate: _.noop,
            availableOwners,
            garagedAtOptions,
            coverages: currentVehicleCoverages,
            isQuoteForNamedNonOwner,
            accountHolderBirthday,
            accountNumber,
            accountContacts,
            additionalInterestType,
            validVinInfo: {},
            updateValidVinInfo: _.noop,
            quoteStatus: periodStatus || { code: _.get(submissionVM, 'value.status') },
            accordionOpendIds,
            updateAccordionOpendIds
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onSort
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            vehiclecomponent: VehicleComponent,
        },
        resolveClassNameMap: styles
    };

    const isSubmissionQuoted = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    useEffect(() => {
        registerInitialComponentValidation(isSubmissionQuoted);
    }, [isSubmissionQuoted, registerInitialComponentValidation]);

    if (!isPageInitialised) {
        return null;
    }

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={_.noop}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PAVehiclesReadOnlyPage.propTypes = wizardProps;
export default PAVehiclesReadOnlyPage;
