
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import appConfig from 'app-config';
import messages from './AQPaymentIssuesComponent.messages';
import styles from './AQPaymentIssuesComponent.module.scss';
import { Link } from '@jutro/router';

/**
 * AQPayment Issues Component provides a customized validationIssueCompoent for diffrent
 * requirement of AQPayment page comparing to other pages
 * @param {object} props
 * @returns {object} React Component
 */
function AQPaymentIssuesComponent(props) {
    const {
        jobNumber,
        submission,
        productCode,
        history,
        ...extraProps
    } = props;
    const {
        authUserData: {
            businessData_Ext: {
                featureToggles = [],
            }
        },
    } = useAuthentication();
    const { lobQuoteURL } = appConfig;
    const translator = useTranslator();

    const onContinueTransaction = useCallback(() => {
        const postalCode = _.get(
            submission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (jobNumber) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: jobNumber
                    }
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(jobNumber);
            }
        }
    }, [productCode, jobNumber, submission, history]);

    const issueRenderFn = (issue,
        {
            issueKey, listKey, issueJumpFnMap = {},
        }) => {
        const { reason } = issue;
        let listContent = reason;
        if (issueKey && issueJumpFnMap[issueKey]) {
            const pageJumpFunc = issueJumpFnMap[issueKey];
            listContent = (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link key={`${listKey}-Link`} onClick={pageJumpFunc}>
                    {reason}
                </Link>
            );
        }
        const VINLengthErrMsg = translator(messages.VINLengthErrMsg);
        let isEnableCodeChangeForTeamX = false;
        const EnableCodeChangeForTeamX = featureToggles.filter(({ code }) => code === 'EnableCodeChangeForTeamX');
        if (EnableCodeChangeForTeamX.length) {
            isEnableCodeChangeForTeamX = true;
        }
        if (reason.includes(VINLengthErrMsg) && isEnableCodeChangeForTeamX) {
            listContent = (
                <>
                    <span>{reason}</span>
                    <Link onClick={onContinueTransaction} className={styles.issueLink}>
                        #{jobNumber}
                    </Link>
                </>
            );
        }
        return listContent;
    }


    useEffect(() => {
    }, []);
    
    return (
        <ValidationIssuesComponent issueRenderFn={issueRenderFn} {...extraProps}/>
    );
}

AQPaymentIssuesComponent.propTypes = {
    jobNumber: PropTypes.string,
    submission: PropTypes.shape({}),
    productCode: PropTypes.string,
    history: PropTypes.shape({})
};

AQPaymentIssuesComponent.defaultProps = {
    jobNumber: '',
    submission: {},
    productCode: '',
    history: {}
};

export default AQPaymentIssuesComponent;
