import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

//
import HOPolicyChangeStartPage from './pages/Start/HOPolicyChangeStartPage';

import HOHouseholdInfoChangePage from './pages/HouseholdInfo/HOHouseholdInfoChangePage';
import HOHouseholdInfoChangeReadOnlyPage from './pages/HouseholdInfo/HOHouseholdInfoChangePage.readonly';

import HOResidenceChangePage from './pages/HOResidence/HOResidenceChangePage';
import HOResidenceChangeReadOnlyPage from './pages/HOResidence/HOResidenceChangePage.readonly';

import HOCoveragesChangePage from './pages/HOCoverages/HOCoveragesChangePage';
import HOCoveragesChangeReadOnlyPage from './pages/HOCoverages/HOCoveragesChangePage.readonly';

import HOLossHistoryAndPriorCarrierChangePage from './pages/LossHistoryAndPriorCarrier/HOLossHistoryAndPriorCarrierChangePage';
import HOLossHistoryAndPriorCarrierChangeReadOnlyPage from './pages/LossHistoryAndPriorCarrier/HOLossHistoryAndPriorCarrierChangePage.readonly';

import HOAdditionalInterestsChangePage from './pages/AdditionalInterests/HOAdditionalInterestsChangePage';
import HOAdditionalInterestsChangeReadOnlyPage from './pages/AdditionalInterests/HOAdditionalInterestsChangePage.readonly';

//
import HOQuoteChangePage from './pages/Quote/HOQuoteChangePage';
import HOQuoteChangeReadOnlyPage from './pages/Quote/HOQuoteChangePage.readonly';

import HOPaymentChangePage from './pages/Payment/HOPaymentChangePage';

import HOPaySuccessChangePage from './pages/PaySuccess/HOPaySuccessChangePage';


setComponentMapOverrides(
    {
        HOPolicyChangeStartPage: { component: 'HOPolicyChangeStartPage' },

        HOHouseholdInfoChangePage: { component: 'HOHouseholdInfoChangePage' },
        HOHouseholdInfoChangeReadOnlyPage: { component: 'HOHouseholdInfoChangeReadOnlyPage' },
        
        HOResidenceChangePage: { component: 'HOResidenceChangePage' },
        HOResidenceChangeReadOnlyPage: { component: 'HOResidenceChangeReadOnlyPage' },
        
        HOCoveragesChangePage: { component: 'HOCoveragesChangePage' },
        HOCoveragesChangeReadOnlyPage: { component: 'HOCoveragesChangeReadOnlyPage' },

        HOLossHistoryAndPriorCarrierChangePage: { component: 'HOLossHistoryAndPriorCarrierChangePage'},
        HOLossHistoryAndPriorCarrierChangeReadOnlyPage: {component: 'HOLossHistoryAndPriorCarrierChangeReadOnlyPage'},

        HOAdditionalInterestsChangePage: { component: 'HOAdditionalInterestsChangePage' },
        HOAdditionalInterestsChangeReadOnlyPage: { component: 'HOAdditionalInterestsChangeReadOnlyPage' },
        
        HOQuoteChangePage: { component: 'HOQuoteChangePage' },
        HOQuoteChangeReadOnlyPage: { component: 'HOQuoteChangeReadOnlyPage' },

        HOPaymentChangePage: { component: 'HOPaymentChangePage' },

        HOPaySuccessChangePage: { component: 'HOPaySuccessChangePage' },
    },
    {
        HOPolicyChangeStartPage,
        
        HOHouseholdInfoChangePage,
        HOHouseholdInfoChangeReadOnlyPage,

        HOResidenceChangePage,
        HOResidenceChangeReadOnlyPage,

        HOCoveragesChangePage,
        HOCoveragesChangeReadOnlyPage,

        HOLossHistoryAndPriorCarrierChangePage,
        HOLossHistoryAndPriorCarrierChangeReadOnlyPage,

        HOAdditionalInterestsChangePage,
        HOAdditionalInterestsChangeReadOnlyPage,

        HOQuoteChangePage,
        HOQuoteChangeReadOnlyPage,

        HOPaymentChangePage,

        HOPaySuccessChangePage,
    }
);

export {
    HOPolicyChangeStartPage,
    
    HOHouseholdInfoChangePage,
    HOHouseholdInfoChangeReadOnlyPage,

    HOResidenceChangePage,
    HOResidenceChangeReadOnlyPage,

    HOCoveragesChangePage,
    HOCoveragesChangeReadOnlyPage,

    HOLossHistoryAndPriorCarrierChangePage,
    HOLossHistoryAndPriorCarrierChangeReadOnlyPage,

    HOAdditionalInterestsChangePage,
    HOAdditionalInterestsChangeReadOnlyPage,

    HOQuoteChangePage,
    HOQuoteChangeReadOnlyPage,

    HOPaymentChangePage,

    HOPaySuccessChangePage,
};

// eslint-disable-next-line import/prefer-default-export
export { default as HOPolicyChangeWizard } from './HOPolicyChangeWizard';
