
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
// import { useTranslator, IntlContext } from '@jutro/locale'; 
import { WizardConstants } from 'wni-portals-config-js';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
// import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
// import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ActiveQuoteUtil, WniJobFilterUtil } from 'wni-portals-util-js';

import { useBusinessData, useProductsData } from 'wni-portals-util-react';
import ActiveQuotesDropdownComponent from '../ActiveQuotesDropdown/ActiveQuotesDropdownComponent';

// See ActiveQuotesWithWizardContext for another solution
function WizardActiveQuotesDropdownComponent(props) {
    // const {
    // } = props;

    const {
        wizardData: submissionVM,
        //
        // updateWizardSnapshot,
        //
        wizardPageData,
    } = useContext(WizardContext);

    // const history = useHistory();
    // const translator = useTranslator();
    // const { authHeader } = useAuthentication();

    const {
        jobID, 
        quoteID, // for backward compatibility
        // sessionUUID,
        baseData: {
            baseState_Ext: baseState,
            accountHolder: {
                contactType_Ext: accountType,
                primaryAddress: {
                    postalCode,
                }
            }
        },
    } = submissionVM.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageData;

    const [accountJobs, updateAccountJobs] = useState([]);

    const actualJobID = jobID || quoteID;

    const { getAvailableProducts } = useProductsData();

    useEffect(() => {
        // POI-24758: filter out all periods of the current Job from ActiveQuotesDropdown
        // const otherActiveJobs = ActiveQuoteUtil.filterActiveJobDTOsByPeriodID(accountActiveQuotes,
        //     actualJobID, selectedVersion);

        const otherActiveJobs = ActiveQuoteUtil.filterActiveJobDTOsByJobID(accountActiveQuotes, actualJobID);

        const availableProducts = getAvailableProducts(accountType, baseState, true);
        const otherActiveJobFilters = WniJobFilterUtil.getAvailableJobs(otherActiveJobs, availableProducts)

        updateAccountJobs(otherActiveJobFilters);
    }, [accountActiveQuotes, actualJobID]);

    /**
     * Clarification for POI-24758 by QA Jessica:
     * 1) extra periods for current job will be unavailalbe from the ActiveQuotesDropdown
     * 2) Branch switching should be done throug the Change/Add Version dropdown on QuotePage instead of ActiveQuotesDropdown
     * 
     * @deprecated due to requirement clarification for POI-24758
     */

    // const onClickQuotePeriod = useCallback(async (periodInfo, defaultOnClickQuotePeriod) => {
    //     const {
    //         targetJobID,
    //         targetProductCode,
    //         // targetPostalCode,
    //         isSelectedBranch,
    //         targetPeriodPublicID,
    //     } = periodInfo;
    //     if (targetJobID === actualJobID) {
    //         if (targetPeriodPublicID === selectedVersion) {
    //             // nothing happens
    //             return;
    //         }
    //         const wizardDataDTO = await WniCommonQuoteService.updateCurrentBranchAndRefreshWizardData(actualJobID, 
    //             sessionUUID, targetPeriodPublicID, authHeader);
    //         if (jobID) {
    //             submissionVM.value = wizardDataDTO;
    //         } else { // if (quoteID) {  // backward compatibility
    //             // Consider to extract this into a util method
    //             const quoteDataDTO = submissionVM.value;
    //             const {
    //                 jobID: newQuoteID,
    //                 ...otherQuoteDataProps
    //             } = wizardDataDTO;
    //             submissionVM.value = {
    //                 ...quoteDataDTO,
    //                 //
    //                 quoteID: newQuoteID,
    //                 ...otherQuoteDataProps,
    //             }
    //         }
    //         updateWizardSnapshot(submissionVM);
    //     } else {
    //         await defaultOnClickQuotePeriod(periodInfo);
    //     }
    // }, [actualJobID, sessionUUID, submissionVM, updateWizardSnapshot, jobID]);


    // ========================
    if (_.isEmpty(accountJobs)) {
        return null;
    }
    return (
        <ActiveQuotesDropdownComponent
            accountJobs={accountJobs}
            postalCode={postalCode}
            // currentJobID={jobID}
            // currentSelectedVersion={selectedVersion}
            // onClickQuotePeriod={onClickQuotePeriod}
        />
    );
}

WizardActiveQuotesDropdownComponent.propTypes = {
    // postalCode: PropTypes.string.isRequired,
    // jobID: PropTypes.string,
};

WizardActiveQuotesDropdownComponent.defaultProps = {
    // postalCode: undefined,
    // jobID: undefined,
};


export default WizardActiveQuotesDropdownComponent;
