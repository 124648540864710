import React, {
    useState,
    useEffect,
    useContext,
} from "react";
import { WniInputNumber } from "wni-common-base-components"
import CoveragesConfigContext from "../../../../../context/CACoveragesConfigContext";
import SingleClauseContext from "../../../../../context/CASingleClauseContext";
import { ValuePath } from "../../../../../util/CAScheduleItemsUtil";

const CAComplexSchedulePopupPropertyInfoInteger = (props) => {

    const {
        propertyInfo,
        openedScheduleItem,
        openedScheduleItemIndex,
        updateSubmissionVMToServer,
        showErrors,
        setIsEditing,
    } = props

    const {
        id: propertyInfoId,
        label,
        required,
    } = propertyInfo;

    const {
        itemNumber,
        itemData: {
            [propertyInfoId]: itemDataField
        }
    } = openedScheduleItem
    const {
        [ValuePath.IntegerValue]: value
    } = itemDataField

    const {
        clauseCode,
        // onChangeClause,
        // onChangeSubmissionAndSync,
        clausePath: clauseVMPath,
        onChangeClause,
        isEditable,
    } = useContext(SingleClauseContext)

    const coveragesConfig = useContext(CoveragesConfigContext);

    const {
        scheduleConfig: {
            fieldsNotNeedSyncWhileChange: {
                [clauseCode]: fieldsNotNeedSync = []
            }
        }
    } = coveragesConfig;

    const notNeedSyncImmediately = fieldsNotNeedSync.includes(propertyInfoId)

    const [editingValue, setEditingValue] = useState(value)

    useEffect(() => {
        setEditingValue(value)
    }, [value])

    const onStringValueChange = (newValue) => {
        setIsEditing(true)
        setEditingValue(newValue)
    }

    const onBlur = () => {
        setIsEditing(false)
        if (editingValue === value) {
            return
        }
        const newItemDataField = {
            ...itemDataField,
            [ValuePath.IntegerValue]: editingValue,
            updated_Ext: true
        };
        const path = `${clauseVMPath}.schedule.scheduleItems[${openedScheduleItemIndex}].itemData.${propertyInfoId}`

        const updatedSubVMInPE = onChangeClause(newItemDataField, path)
        if (notNeedSyncImmediately) {
            return
        }
        updateSubmissionVMToServer(updatedSubVMInPE)
    }

    return <WniInputNumber
        id = {`${propertyInfoId}_${itemNumber}`}
        label = {label}
        required = {required}
        showRequired = {required}
        value = {editingValue}
        onValueChange = {onStringValueChange}
        showErrors = {showErrors}
        onBlur={onBlur}
        showFractions={false}
        readOnly={!isEditable}
    />
}

export default CAComplexSchedulePopupPropertyInfoInteger;

