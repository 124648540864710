import { defineMessages } from 'react-intl';

export default defineMessages({
    county: {
        id: 'gateway.views.new-quote.County',
        defaultMessage: 'County',
    },
    boroughs: {
        id: 'gateway.views.new-quote.Borough',
        defaultMessage: 'Borough',
    },
    unit: {
        id: 'platform.inputs.address-details.unit #',
        defaultMessage: 'Unit #',
    },
    pobox: {
        id: 'gateway.views.new-quote.PO Box',
        defaultMessage: 'PO Box',
    },
    verifiedAddresses: {
        id: 'gateway.views.new-quote.Address entered could not be validated. Please modify the address if needed.',
        defaultMessage: 'Address entered could not be validated. Please modify the address if needed.'
    },
    CAPostalCode: {
        id: 'gateway.views.new-quote.address.Postal Code',
        defaultMessage: 'Postal Code'
    },
    PostalCode: {
        id: 'gateway.views.new-quote.address.Zip Code',
        defaultMessage: 'Zip Code'
    },
    CACity: {
        id: 'gateway.views.new-quote.address.Municipality',
        defaultMessage: 'Municipality'
    },
    City: {
        id: 'gateway.views.new-quote.address.City',
        defaultMessage: 'City'
    },
    CAState: {
        id: 'gateway.views.new-quote.address.Province',
        defaultMessage: 'Province'
    },
    State: {
        id: 'gateway.views.new-quote.address.State',
        defaultMessage: 'State'
    }
});
