import React from "react";
import _ from 'lodash';
import WCStateRatingPeriodCoveragesSection from '../WCStateRatingPeriodCoveragesSection/WCStateRatingPeriodCoveragesSection'

const WCStateRatingPeriodsCoverages = (props) => {

    const {
        selectedVersion,
        stateCoveragesIndex,
    } = props

    const allStateRatingPeriodsCoverages = _.get(selectedVersion, `coverages.stateSpecificCoverages[${stateCoveragesIndex}].ratingPeriodsCovs`, []);

    const moreThanOnePeriod = allStateRatingPeriodsCoverages.length > 1

    return <>
        {allStateRatingPeriodsCoverages.map((ratingPeriodCoverages, index) => {
            const ratingPeriodNumber = ratingPeriodCoverages.number
            return <React.Fragment
                    key={ratingPeriodNumber}
                >
                    {moreThanOnePeriod && (
                        <div>{`Period ${ratingPeriodCoverages.number}`}</div>
                    )}
                    <WCStateRatingPeriodCoveragesSection
                        {...props}
                        ratingPeriodNumber={ratingPeriodNumber}
                        ratingPeriodCoveragesIndex={index}
                    />
                </React.Fragment>
            }
        )}
    </>
}

export default WCStateRatingPeriodsCoverages;