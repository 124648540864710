import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { CAPolicyDetailsService } from 'wni-capability-quoteandbind-ca';
import {
    ValidationIssueUtil,
    ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
    PolicyDBAComponent,
    ANIComponent
} from 'wni-capability-gateway-react';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import WizardPage from '../../templates/CAWizardPage';

import styles from './CAPolicyDetailsPage.module.scss';
import metadata from './CAPolicyDetailsPage.metadata.json5';
import messages from './CAPolicyDetailsPage.messages';

function CAPolicyDetailsPage(props) {

    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        // updateWizardSnapshot,
        // updateWizardReadOnly,
        // wizardSnapshot,
        //
        // wizardStepToFieldMapping,
        // markFollowingWizardStepsUnvisited,
        savePolicyDetailsData,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        // wizardPageData,
        // updateWizardPageData,
        readOnlyFields: {
            // effectiveDate = false,
            agencyOfRecord = false,
        },
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        lobData: {
            commercialAuto: {
                policyDetails_Ext: {
                    displayableDTO
                }
            }
        },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        // baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        // productCode
    } = baseData;

    // const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CAPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    const policyDetailPath = 'lobData.commercialAuto.policyDetails_Ext';

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);


    const getPrimaryNamedInsuredDisplayName = useCallback(() => {      
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.contactName');
        // const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        // const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${displayName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);


    const getDisplayableFieldValue = useCallback((label) => {
        const displayableValue = displayableDTO
        .find((elt) => elt.label === label);
        return _.get(displayableValue, 'rangeValue')
        // return displayableValue.rangeValue;
    }, [displayableDTO])

    const shouldShowAndRequireVehicleType = useCallback(() => {
        const grossReceiptsBasisOrMileageBasis = displayableDTO.find((elt) => elt.label === 'Gross Receipts Basis Or Mileage Basis');
        if (_.get(grossReceiptsBasisOrMileageBasis, 'rangeValue') === 'Yes') {
            return true
        }
        return false
    },[displayableDTO])
    
    const shouldShowAndRequireClassCode = useCallback(() => {
        const grossReceiptsBasisOrMileageBasis = displayableDTO.find((elt) => elt.label === 'Gross Receipts Basis Or Mileage Basis');
        if (_.get(grossReceiptsBasisOrMileageBasis, 'rangeValue') === 'Yes') {
            return true
        }
        return false
    },[displayableDTO])

    const getAvailableOrganizationTypesOptions = () => {
        const organizationTypesOptions = _.get(submissionVM, `${policyDetailPath}.organizationTypesOptions.aspects.availableValues`);
        return organizationTypesOptions ? organizationTypesOptions.map((option) => {
            return {
                code: option.code,
                name: {
                    id: option.name,
                },
            };
        }) : [];
    };

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true,
                    licensedAgentReadOnly: true,
                    servicingAgentReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate,
                isReadOnly: true
            },
            primaryNamedInsured: {
                value: getPrimaryNamedInsuredAndDisplay(),
            },
            primaryNamedInsuredEditIcon: {
                visible: false
            },
            policyTypeDropdown: {
                value: getDisplayableFieldValue('Policy Type'),
            },
            individualNamedInsuredDropdown: {
                value: getDisplayableFieldValue('Individual Named Insured On The Policy'),
            },
            legalEntityDropdown: {
                value: getDisplayableFieldValue('Legal Entity'),
            },
            grossReceiptsBasisOrMileageBasisDropdown: {
                value: getDisplayableFieldValue('Gross Receipts Basis Or Mileage Basis'),
            },
            vehicleTypeDropdown: {
                value: getDisplayableFieldValue('Vehicle Type'),
                visible: shouldShowAndRequireVehicleType(),
                required: shouldShowAndRequireVehicleType()
            },
            classCodeDropdown: {
                value: getDisplayableFieldValue('Class Code'),
                visible: shouldShowAndRequireClassCode(),
                required: shouldShowAndRequireClassCode(),
            },
            AcceptCertifiedActsDropdown:{
                value: getDisplayableFieldValue('Accept Certified Acts Of Terrorism Coverage'),
            },
            RideSharingDropdown:{
                value: getDisplayableFieldValue('Ride Sharing Arrangements Endorsement Indicator'),
            },
            additionalInsuredSection: {
                model: _.get(submissionVM, `${policyDetailPath}.additionalInsureds`),
                onValueChange: writeValue,
                viewModelService,
                industryCode: _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.industryCode_Ext'),
                authHeader,
                sessionUUID,
                jobID: quoteID,
                organizationTypes: _.get(submissionVM, `value.${policyDetailPath}.organizationType`),
                policyDetailPath,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                policyAddress: _.get(submissionVM, 'value.baseData.policyAddress'),
                existingAddlNamedInsureds: _.get(submissionVM, `value.${policyDetailPath}.unassignedAdditionalNamedInsureds`),
                isReadOnly: true,
                organizationTypesOptions: getAvailableOrganizationTypesOptions(),
            },
            policyDBA: {
                dbas: _.get(submissionVM, `value.${policyDetailPath}.policyDBAList`),
                isReadOnly: true,
                policyDetailPath
            }

        };
    }, [breakpoint, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            anicomponent: ANIComponent,
            policydbacomponent: PolicyDBAComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CAPolicyDetailsPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: false,
        // uwqReadonly: false
    }
};

CAPolicyDetailsPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: CAPolicyDetailsService.saveCAPolicyDetailsData,
        readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: false,
        // uwqReadonly: false
    }
} 
export default CAPolicyDetailsPage;