import { defineMessages } from 'react-intl';

export default defineMessages({
    removeWatercraftTitle: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-watercraft.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeWatercraftDescription: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-watercraft.deleteMessage',
        defaultMessage:
            'Are you sure you want to delete this watercraft record?',
    },
    viewAndEditLabel: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-vehicle.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.wal.directives.templates.wal-edit-vehicle.View',
        defaultMessage: 'View',
    },
    onlyTrailers: {
        id: 'wni.quoteandbind.wal.watercraft.Only trailers that transport watercraft can be added.',
        defaultMessage: 'Only trailers that transport watercraft can be added.',
    },
    runPrefill: {
        id: 'wni.quoteandbind.wal.watercraft.Enter Watercraft Details to run prefill.',
        defaultMessage: 'Enter Watercraft Details to run prefill.',
    },
    isThereExistingDamage: {
        id: 'wni.quoteandbind.wal.watercraft.Is there any existing damage to the Watercraft?',
        defaultMessage: 'Is there any existing damage to the Watercraft?',
    },
    selectedWatercraftApplies1: {
        id: 'wni.quoteandbind.wal.watercraft.Do any of the following apply to this Watercraft?',
        defaultMessage: 'Do any of the following apply to this Watercraft?',
    },
    selectedWatercraftApplies2: {
        id: 'wni.quoteandbind.wal.watercraft.(Please select all that apply)',
        defaultMessage: '(Please select all that apply)',
    },
    selectedWatercraftAppliesWarningMsg: {
        id: 'wni.quoteandbind.wal.watercraft.Please add driver information in the operator section.',
        defaultMessage:
            'Please add driver information in the operator section.',
    },
    coverageAmountTitle1: {
        id: 'wni.quoteandbind.wal.watercraft.If Replacement Cost is selected:',
        defaultMessage: 'If Replacement Cost is selected:',
    },
    coverageAmountMsg1: {
        id: 'wni.quoteandbind.wal.watercraft.Enter in the cost new to replace the vehicle with like kind and quality. ',
        defaultMessage:
            'Enter in the cost new to replace the vehicle with like kind and quality. ',
    },
    coverageAmountTitle2: {
        id: 'wni.quoteandbind.wal.watercraft.If Agreed Value is selected:',
        defaultMessage: 'If Agreed Value is selected:',
    },
    coverageAmountMsg2: {
        id: 'wni.quoteandbind.wal.watercraft.Upon a covered loss the maximum limit of liability payable by carrier without consideration for depreciation.',
        defaultMessage:
            'Upon a covered loss the maximum limit of liability payable by carrier without consideration for depreciation.',
    },
    coverageAmountTitle3: {
        id: 'wni.quoteandbind.wal.watercraft.The amount entered should include any customization on the watercraft.',
        defaultMessage:
            'The amount entered should include any customization on the watercraft.',
    },
    deleteAllWatercraft: {
        id: 'wni.quoteandbind.wal.watercraft.Deleting all watercraft will require policy cancelled',
        defaultMessage: 'Deleting all watercraft will require policy cancelled',
    },
    BRPL0441: {
        id: 'wni.quoteandbind.wal.watercraft.Item to be Insured has changed to <Item to be Insured> due to information from Price Digest. If you believe this to be incorrect please contact underwriting.',
        defaultMessage:
            'Item to be Insured has changed to {itemToBeInsured} due to information from Price Digest. If you believe this to be incorrect please contact underwriting.',
    },
    BRPL0442: {
        id: 'wni.quoteandbind.wal.watercraft.Item to be Insured has changed to <Item to be Insured> due to the selection in the description field.If you believe this to be incorrect please contact underwriting.',
        defaultMessage:
            'Item to be Insured has changed to {itemToBeInsured} due to the selection in the description field.If you believe this to be incorrect please contact underwriting.',
    },
    itemInsuredMsg: {
        id: 'wni.quoteandbind.wal.watercraft.Click here to determine vehicle category',
        defaultMessage: 'Click {here} to determine vehicle category'
    }
});
