import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'modal.button.label.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'modal.button.label.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'modal.button.label.Save',
        defaultMessage: 'Save'
    },
    title: {
        id: 'gateway.views.primary.named.insured.Edit Primary Named Insured',
        defaultMessage: 'Edit Primary Named Insured'
    },
    contactDetails: {
        id: 'gateway.views.primary.named.insured.contactDetails',
        defaultMessage: 'Contact Details'
    },
    showBasicDetails: {
        id: 'gateway.views.primary.named.insured.showBasicDetails',
        defaultMessage: 'Show Basic Details'
    },
    primaryAddress: {
        id: 'gateway.views.primary.named.insured.PrimaryAddress',
        defaultMessage: 'Primary Address'
    },
    showAddresses: {
        id: 'gateway.views.primary.named.insured.showAddresses',
        defaultMessage: 'Show Addresses'
    },
    fullName: {
        id: 'gateway.views.primary.named.insured.fullName',
        defaultMessage: 'Full Name'
    },
    firstName: {
        id: 'gateway.views.primary.named.insured.firstName',
        defaultMessage: 'First Name'
    },
    middleName: {
        id: 'gateway.views.primary.named.insured.middleName',
        defaultMessage: 'Middle Name'
    },
    lastName: {
        id: 'gateway.views.primary.named.insured.lastName',
        defaultMessage: 'Last Name'
    },
    suffix: {
        id: 'gateway.views.primary.named.insured.suffix',
        defaultMessage: 'Suffix'
    },
    relationshipToInsured: {
        id: 'gateway.views.primary.named.insured.relationshipToInsured',
        defaultMessage: 'Relationship To Insured'
    },
    addressLine1: {
        id: 'gateway.views.primary.named.insured.addressLine1',
        defaultMessage: 'Address Line 1'
    },
    addressLine2: {
        id: 'gateway.views.primary.named.insured.addressLine2',
        defaultMessage: 'Address Line 2'
    },
    zipCode: {
        id: 'gateway.views.primary.named.insured.zipCode',
        defaultMessage: 'Zip Code'
    },
    city: {
        id: 'gateway.views.primary.named.insured.city',
        defaultMessage: 'City'
    },
    county: {
        id: 'gateway.views.primary.named.insured.county',
        defaultMessage: 'County'
    },
    state: {
        id: 'gateway.views.primary.named.insured.state',
        defaultMessage: 'State'
    },
    maritalStatus: {
        id: 'gateway.views.primary.named.insured.maritalStatus',
        defaultMessage: 'Marital Status'
    },
    phoneType: {
        id: 'gateway.views.primary.named.insured.PhoneType',
        defaultMessage: 'Phone Type'
    },
    primaryPhone: {
        id: 'gateway.views.primary.named.insured.primaryPhone',
        defaultMessage: 'Primary Phone'
    },
    homePhone: {
        id: 'gateway.views.primary.named.insured.homePhone',
        defaultMessage: 'Home Phone'
    },
    mobilePhone: {
        id: 'gateway.views.primary.named.insured.mobilePhone',
        defaultMessage: 'Mobile Phone'
    },
    primaryEmail: {
        id: 'gateway.views.primary.named.insured.primaryEmail',
        defaultMessage: 'Primary Email'
    },
    secondaryEmail: {
        id: 'gateway.views.primary.named.insured.secondaryEmail',
        defaultMessage: 'Secondary Email'
    },
    NoAddressRecommended: {
        id: 'quoteandbind.pa.views.primary address.no address message',
        defaultMessage: 'No matching address is recommended!'
    },
    NoZipCodeRecommended: {
        id: 'quoteandbind.pa.views.primary zipcode.no message',
        defaultMessage: 'No matching Zip Code is recommended!'
    },
    westernNationalEmployee: {
        id: "wni.quoteandbind.pa.views..western National Employee Discount",
        defaultMessage: "Western National Employee?"
    },
    dba: {
        id: "wni.quoteandbind.pa.views.DBA",
        defaultMessage: "DBA"
    }
});
