import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniPolicyChangeDriverService {
    static addDriver(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'addDriver', [data.policyNumber, data.effectiveDate, data.jobID, data.driver], additionalHeaders);
    }

    static delDrivers(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'removeDrivers', [data.policyNumber, data.effectiveDate, data.jobID, data.drivers], additionalHeaders);
    }

    static updateDriver(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'updateDriver', [data.policyNumber, data.effectiveDate, data.jobID, data.driver], additionalHeaders);
    }

    static getCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'getCoverages', [data.jobID, data.driver], additionalHeaders);
    }

    static getDriversCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'getDriversCoverages', [data.jobID], additionalHeaders);
    }

    static updateCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'updateCoverage', [data.policyNumber, data.effectiveDate, data.jobID, data.driverDTO, data.cov, data.scheduleItem], additionalHeaders);
    }

    static updateAllCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'updateAllCoverages', [data.policyNumber, data.effectiveDate, data.jobID, data.driverDTO, data.cov, data.scheduleItem], additionalHeaders);
    }

    static unselectUnavailableExtendedCoverage(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'unselectUnavalibleExtendedCoverage', [data.policyNumber, data.effectiveDate, data.jobID], additionalHeaders);
    }

    static removeADPDrivers(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'removeADPDrivers', [data.jobID, data.effectiveDate, data.drivers], additionalHeaders);
    }

    static getErrorsAndWarnings(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'getErrorsAndWarnings', [data.jobID, data.isErrorLevel], additionalHeaders);
    }

    static getCoveragesErrorsAndWarnings(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicyChangeDriver'),
            'getCoveragesErrorsAndWarnings', [data.jobID], additionalHeaders);
    }
}
