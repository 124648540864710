import { defineMessages } from 'react-intl';

export default defineMessages({
    email: {
        id: 'document.base.views.email.New Email',
        defaultMessage: 'New Email'
    },
    send: {
        id: 'document.base.views.email.Send',
        defaultMessage: 'Send'
    },
    cancel: {
        id: 'document.base.views.email.Cancel',
        defaultMessage: 'Cancel'
    },
    emailMaxSize: {
        id: 'document.base.views.email.Max size',
        defaultMessage: 'Emailed documents must be sent in a PDF format and cannot exceed a size of 10 MB'
    },
    toRecipient: {
        id: 'document.base.views.email.toRecipients',
        defaultMessage: 'To Recipients'
    },
    toCCRecipient: {
        id: 'document.base.views.email.toCCRecipients',
        defaultMessage: 'CC Recipients'
    },
    toBCCRecipient: {
        id: 'document.base.views.email.toBCCRecipients',
        defaultMessage: 'BCC Recipients'
    },
    addCCRecipientBtn: {
        id: 'document.base.views.email.addCCRecipients',
        defaultMessage: 'Add CC Recipients'
    },
    addBCCRecipientBtn: {
        id: 'document.base.views.email.addBCCRecipients',
        defaultMessage: 'Add BCC Recipients'
    },
    senderName: {
        id: 'document.base.views.email.senderName',
        defaultMessage: 'Sender Name'
    },
    senderEmail: {
        id: 'document.base.views.email.senderEmail',
        defaultMessage: 'Sender Email'
    },
    senderSubject: {
        id: 'document.base.views.email.senderSubject',
        defaultMessage: 'Subject'
    },
    senderBody: {
        id: 'document.base.views.email.senderBody',
        defaultMessage: 'Body'
    },
    attachedDocuments: {
        id: 'document.base.views.email.Attached Documents',
        defaultMessage: 'Attached Documents'
    },
    noDocumentSelected: {
        id: 'document.base.views.email.no Document selected',
        defaultMessage: 'At least one document need to be attached.'
    },
    docCannotExceedTotal: {
        id: 'document.pa.views.email.Emailed documents cannot exceed total size of 10 MB',
        defaultMessage: 'Emailed documents cannot exceed total size of 10 MB.'
    }
});
