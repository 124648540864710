

import React from 'react';
import { IMCPPCommonFormsPage } from 'wni-capability-quoteandbind-im-react';
import { PolicyChangeUtil } from 'wni-portals-util-js';


const IMCPPCommonFormsChangePage = (props) => {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('IMLine', submissionVM);
    return <IMCPPCommonFormsPage {...props} isPolicyChange={isDisplayAsSubmission} />
};
export default IMCPPCommonFormsChangePage
