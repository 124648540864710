import { defineMessages } from 'react-intl';

export default defineMessages({
    paDetailsCollision: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.Details (Collision)',
        defaultMessage: 'Details (Collision)'
    },
    paWhatHappened: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.What Happened',
        defaultMessage: 'What Happened'
    },
    paDescribeWhatHappened: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.Describe what happened',
        defaultMessage: 'Describe what happened?'
    },
    paAnyPropertyDamaged: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.Was any property damaged, other than the vehicle(s) involved in the collision?',
        defaultMessage: 'Was any property damaged, other than the vehicle(s) involved in the collision?'
    },
    paLossDetailsYes: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.Yes',
        defaultMessage: 'Yes'
    },
    paLossDetailsNo: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.No',
        defaultMessage: 'No'
    },
    paDescribePropertyDamage: {
        id: 'pe.fnol.pa.views.pa-collision-loss-details.Please briefly describe the property damage',
        defaultMessage: 'Please briefly describe the property damage'
    }
});
