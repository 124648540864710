import { defineMessages } from 'react-intl';

export default defineMessages({
    possibleBaseQuote: {
        id: 'gateway.views.new-quote-account-search.search-match.The following existing accounts may represent this customer. Please review, and if there is a match, use the existing account as a base for this quote',
        defaultMessage: 'The following existing accounts may represent this customer. Please review, and if there is a match, use the existing account as a base for this quote.'
    },
    noMatches: {
        id: 'gateway.views.new-quote.search-match.No results found!',
        defaultMessage: 'No results found!'
    },
    noLengthMatches: {
        id: 'gateway.views.new-quote.search-match.No results found! At least five letters are required',
        defaultMessage: 'No results found! At least five letters are required'
    },
    noPersonLengthMatch: {
        id: 'gateway.views.new-quote.search-match.Person No results found! At least five letters are required',
        defaultMessage: 'Person No results found! At least five letters are required.'
    }
});
