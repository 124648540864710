import { defineMessages } from 'react-intl';

export default defineMessages({
    caViewAndEditLabel: {
        id: 'wni.quoteandbind.ca-wizard.ca-statespecific.View/Edit',
        defaultMessage: 'View/Edit',
    },
    caViewLabel: {
        id: 'wni.quoteandbind.ca-wizard.ca-statespecific.View',
        defaultMessage: 'View',
    },
});