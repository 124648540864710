import React, {useEffect, useCallback, useState} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniDBAService } from 'wni-capability-quoteandbind';
import PropTypes from 'prop-types';
import {
    Loader,
} from '@jutro/components';
import metadata from './PolicyDBAComponent.metadata.json5';

function PolicyDBAComponent(props) {
    const {
        dbas,
        isReadOnly,
        isRequiredForIssuance,
        authHeader,
        sessionUUID,
        jobID,
        onValueChange,
        policyDetailPath
    } = props;

    const [loading, updateLoading] = useState(false);

    const setAsPrimary = async(item) => {
        updateLoading(true);
        const res = await WniDBAService.setAsPrimary(jobID, sessionUUID, item.publicID, authHeader);
        onValueChange(res, `${policyDetailPath}.policyDBAList`);
        updateLoading(false);
    }

    //---------------------
    const overrides = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        DBAsTable: {
            data: dbas
        },
        isPrimary: {
            renderCell: (item) => {
                if (item.isPrimary) {
                    return  'X';
                }
                return '';
            }
        },
        setAsPrimary: {
            visible: !isReadOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            setAsPrimary
        },
        resolveComponentMap: {
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, dbas, fieldId, fieldPath, overrides
        );
    };

    if (loading) {
        return <Loader loaded={!loading} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dbas}
            overrideProps={overrides}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyDBAComponent.propTypes = {
};
PolicyDBAComponent.defaultProps = {
};

export default PolicyDBAComponent;
