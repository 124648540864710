import { defineMessages } from 'react-intl';

/**
 * ref: common/ClauseComponentVM.messages.js
 */
export default defineMessages({
    WALWatercraftCoveragesCopyCoverageButton: {
        "id": "WALWatercraftCoveragesCopyCoverageButton.button.label.copy coverages",
        "defaultMessage": "Copy Coverages"
    },
    WALWatercraftCoveragesTip: {
        "id": "wa.watercraft.coverages.section.Summary coverages are shown below. Additional information is available, please click \"View/Edit/Add Coverage\".",
        "defaultMessage": "Summary coverages are shown below. Additional information is available, please click \"View/Edit/Add Coverage\"."
    },
    WALWatercraftCoveragesTableNO: {
        "id": "wa.watercraft.coverages.table.no",
        "defaultMessage": "No"
    },
    WALWatercraftCoveragesTableYES: {
        "id": "wa.watercraft.coverages.table.yes",
        "defaultMessage": "Yes"
    },
    WALWatercraftCoveragesTableViewEditAddCoverage: {
        "id": "wa.watercraft.coverages.table.View/Edit/Add Coverage",
        "defaultMessage": "View/Edit/Add Coverage"
    },
    WALWatercraftLiabilityOnly: {
        "id": "wa.watercraft.coverages.table.N/A - Liability Only",
        "defaultMessage": "N/A - Liability Only"
    },
});
