import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalInterestButtonTitle: {
        id: "wni.common.wizard.additionalInterest.Additional Interests",
        defaultMessage: "Additional Interests"
    },
    removeSelected: {
        id: "wni.common.wizard.additionalInterest.Delete Selected",
        defaultMessage: "Delete Selected"
    },
    addAdditionalInterest: {
        id: "wni.common.wizard.additionalInterest.Add",
        defaultMessage: "Add"
    },
    noDataText: {
        id: "wni.common.wizard.additionalInterest.no data text",
        defaultMessage: "There is no additional interest now. Please add one."
    },
    viewAndEditLabel: {
        id: "wni.common.wizard.additionalInterest.View/Edit",
        defaultMessage: "View/Edit"
    },
    viewLabel: {
        id: "wni.common.wizard.additionalInterest.View",
        defaultMessage: "View"
    },
    removeAdditionalInterestTitle: {
        id: "wni.common.wizard.additionalInterest.Remove Additional Interest",
        defaultMessage: "Remove Additional Interest?"
    },
    removeAdditionalInterestDescription: {
        id: "wni.common.wizard.additionalInterest.removeAdditionalInterestDescription",
        defaultMessage: "Are you sure you want to remove this additional interest from the list?"
    },
    deletionModalOk: {
        id: 'wni.common.wizard.additionalInterest.modal.Ok',
        defaultMessage: 'Ok',
    },
    deletionModalCancel: {
        id: 'wni.common.wizard.additionalInterest.Cancel',
        defaultMessage: 'cancel',
    },
    saveAndClose: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.save&close',
        defaultMessage: 'Save & Close',
    },
    cancel: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Cancel',
        defaultMessage: 'Cancel',
    }
});
