import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex } from '@jutro/layout';
import { Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CRStateSpecificInfoService } from 'wni-capability-quoteandbind-cr'
import messages from '../CRStateSpecificInformationPage.messages';
import CoveragesConfigContext from '../../../context/CRCoveragesConfigContext';
import CRCoveragesConfig from '../../../config/CRCoverages.config';
import CRSearchAndAddClausesPopup from '../../../components/CRSearchAndAddClausesPopup/CRSearchAndAddClausesPopup.jsx';
import metadata from './CRStateInfoComponent.metadata.json5';
import StateCoveragesCard from './StateCoveragesCard/StateCoveragesCard.jsx';
import StateAdditionalCoveragesCard from './StateAdditionalCoveragesCard/StateAdditionalCoveragesCard.jsx';
import StateExclusionsAndConditionsCard from './StateExclusionsAndConditionsCard/StateExclusionsAndConditionsCard.jsx';

const CRStateInfoComponent = (props) => {
    const modalApi = useWniModal();
    const {
        readOnly,
        selectedState,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        handleStateSpecificInfoClose
    } = props;

    const { jobID, sessionUUID } = submissionVM.value;

    const translator = useTranslator();

    const { 
        onValidate, 
        isComponentValid 
    } = useValidation('CRStateInfoComponent');

    const { authHeader } = useAuthentication();
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [stateClauses, setStateClauses] = useState();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const initStateCoverages = useCallback(async () => {
        if (!selectedState) {
            return;
        }
        setLoadingMask(true);
        const newStateClauses = await CRStateSpecificInfoService.getStateClauses(
            jobID,
            sessionUUID,
            selectedState,
            authHeader
        );
        setStateClauses(newStateClauses);
        setLoadingMask(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, jobID, selectedState, sessionUUID]);


    useEffect(() => {
        initStateCoverages()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showCoveragesModal = useCallback((covType) => {
        const stateFullName = translator({
            id: `typekey.Jurisdiction.${selectedState}`,
            defaultMessage: selectedState
        })
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            jurisdictionCode: selectedState,
            searchClausesFunc: CRStateSpecificInfoService.searchStateSpecificCoverages,
            addSearchedClausesFunc: CRStateSpecificInfoService.addSearchedStateSpecificCoverages,
            coverableName: stateFullName,
            coverableCategory: 'Jurisdiction'
        };
        return modalApi.showModal(
            <CRSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, selectedState, sessionUUID, setLoadingMask, translator]);

    const onClickAddSearchClauses = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType);
        setStateClauses(data);
    },[showCoveragesModal])
    
    if (!stateClauses) {
        return null;
    }

    const handleClose = () => {
        setShowErrors(true);
        handleStateSpecificInfoClose();
    };

    const accordionCommonProps = {
        stateClauses,
        setStateClauses: (stateCovs) => {
            setStateClauses(stateCovs)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
        },
        isEditing,
        setIsEditing,
        isEditable: !readOnly,
        showErrors,
        onValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateClausesFunc: CRStateSpecificInfoService.updateStateClauses,
    };

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        StateCoveragesCard: {
            ...accordionCommonProps
        },
        StateAdditionalCoveragesCard: {
            ...accordionCommonProps
        },
        StateExclusionsAndConditionsCard: {
            ...accordionCommonProps
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            StateCoveragesCard: StateCoveragesCard,
            StateAdditionalCoveragesCard: StateAdditionalCoveragesCard,
            StateExclusionsAndConditionsCard: StateExclusionsAndConditionsCard,
        },
    };

    return (
        <>
            {stateClauses && (
                <CoveragesConfigContext.Provider value={CRCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onValidationChange={onValidate}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                    />
                </CoveragesConfigContext.Provider>
            )}

            <Flex justifyContent="right">
                <Button onClick={handleClose} 
                    label={translator(messages.crClose)} />
            </Flex>
        </>
    );
};

export default CRStateInfoComponent;
