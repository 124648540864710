/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
    RTLimitedMexicoCoverage_Ext: {
        id: 'wni.custom.products.WAL.tooltip.The coverage we provide with this endorsement may not meet Mexican Motorcycle insurance requirements. You are required to purchase liability insurance through a licensed Mexican insurance company for this endorsement to apply - Contact underwriting',
        defaultMessage:'The coverage we provide with this endorsement may not meet Mexican Motorcycle insurance requirements. You are required to purchase liability insurance through a licensed Mexican insurance company for this endorsement to apply - Contact underwriting'
    },
    RTTripInterruptionCoverage_Ext : {
        id: 'wni.custom.products.WAL.tooltip.Transportation, lodging and meal expenses incurred due to mechanical or electrical breakdown that occur more than 100 miles from home and your motorcycle is withdrawn from use for more than 24 hours. Maximum limit $600.',
        defaultMessage: 'Transportation, lodging and meal expenses incurred due to mechanical or electrical breakdown that occur more than 100 miles from home and your motorcycle is withdrawn from use for more than 24 hours. Maximum limit $600.'
    },
    RTTransportationExpensesCoverage_Ext: {
        id: 'wni.custom.products.WAL.tooltip.Also known as Rental Reimbursement coverage, this covers rental vehicle costs while an insured vehicle is out of service due to a covered loss starting at $20/$600 with higher limits available.',
        defaultMessage: 'Also known as Rental Reimbursement coverage, this covers rental vehicle costs while an insured vehicle is out of service due to a covered loss starting at $20/$600 with higher limits available.'
    },
    RTElectronicEquipmentCoverage_Ext: {
        id: 'wni.custom.products.WAL.tooltip.This will cover such property as Radios, Stereos, Navigation systems, etc.',
        defaultMessage: 'This will cover such property as Radios, Stereos, Navigation systems, etc.'
    },
})