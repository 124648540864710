import _ from 'lodash';
import { ConfigUtil, WniDateUtil, WniAccordionValidationUtil } from 'wni-portals-util-js';
import CADriverConstants from '../config/CADriverConstants';


function hasIssuanceInvalidFields(driverVM = {}) {
    return false;
}

function setDefaultDriverObj(countryCode, driversVM) {
    const res = {
        rowIdPath: ConfigUtil.getUuid()
    };
    _.unset(res, 'publicID');

    // typeKey filterByCategory
    _.set(res, 'policyCountry', countryCode);
    const driversLength = _.get(driversVM, 'value.length') || 0;
    _.set(res, 'unitNumber', driversLength + 1)
    return res;
}

function hasInvalidDriver(driversVM) {
    const retval = driversVM.children.some((item) => {
        return !item.aspects.valid || !item.aspects.subtreeValid;
    });
    return retval;
}

function removeEmpty(object) {
    const res = _.cloneDeep(object);
    _.forEach(res, (val, key) => {
        if (val === '' || val === {} || val === [] || val === null) {
            delete res[key];
        }
    });
    return res;
}

function truncatedString(obj, path) {
    const str = _.get(obj, path);
    if (str) {
        const numStr = str.replace(/[^0-9]/ig, '');
        _.set(obj, path, numStr.substring(numStr.length - 10));
    }
}

function truncatedPhoneNumber(driver) {
    truncatedString(driver, 'workNumber');
    truncatedString(driver, 'homeNumber');
    truncatedString(driver, 'cellNumber');
}

function onLicenseNumberCell(item, isMask) {
    if (isMask && item.licenseNumber) {
        let maskLicenseNumber = '';
        for (let i = 0; i < item.licenseNumber.length; i++) {
            maskLicenseNumber += '*';
        }
        return maskLicenseNumber
    }
    return item.licenseNumber
}

function onDateOfBirthCell(item, isMask) {
    if (!item.dateOfBirth) {
        return '';
    }
    if (isMask) {
        return `**/**/${item.dateOfBirth.year}`;
    }
    return WniDateUtil.formatDateWithPattern(item.dateOfBirth);
}

function getAccordionOverridesForDriver(driverVM, validationIssues = []) {
    const cardFieldsMap = CADriverConstants.sectionFieldsMap;
    const retval = WniAccordionValidationUtil.getInvalidAccordionCardsOverrides(cardFieldsMap, driverVM);
    const errorIssues = _.filter(validationIssues, (issue) => {
        return _.get(issue, 'type') === 'error'
            && _.get(issue, 'relatedEntity.fixedId') === _.get(driverVM, 'value.fixedId');
    });
    const errorFields = _.map(errorIssues, (error) => {
        return _.upperFirst(_.get(error, 'relatedEntity.fieldName'));
    });
    const ids = Object.keys(cardFieldsMap);
    const invalidAccordions = ids.filter((id) => {
        const fields = cardFieldsMap[id];
        const invalidFields = _.filter(fields, (field) => {
            const idx = errorFields.indexOf(_.upperFirst(field));
            return idx >= 0;
        });
        return invalidFields.length > 0;
    });
    ids.forEach((id) => {
        if (invalidAccordions.indexOf(id) >= 0) {
            _.set(retval, `${id}.errorState`, true);
        }
    });
    return retval;
}

export default {
    hasIssuanceInvalidFields,
    setDefaultDriverObj,
    hasInvalidDriver,
    removeEmpty,
    truncatedPhoneNumber,
    onLicenseNumberCell,
    onDateOfBirthCell,
    getAccordionOverridesForDriver
}