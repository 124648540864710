

import React from 'react';
import { IMBaileeCustomersFloaterReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMBaileeCustomersFloaterChangeReadOnlyPage = (props) => {
    return <IMBaileeCustomersFloaterReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMBaileeCustomersFloaterChangeReadOnlyPage
