import React, {
    useCallback
} from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { WniDropdownSelect } from 'wni-common-base-components';
import {
    Loader,
} from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WCCoverageUtil from '../../../../Coverages/util/WCCoverageUtil';
import styles from './WCStateDetails.module.scss'

const WCStateDetails = (props) => {

    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        lobName,
        coveragesService,
        loadingClauses,
        setLoadingClauses,
        setIsEditing,
        onValidate,
        showErrors,
        isEditable,
        stateCoveragesIndex,
    } = props

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const getStateDetails = useCallback(() => {
        const stateCoverages = _.get(selectedVersion, `coverages.stateSpecificCoverages[${stateCoveragesIndex}].stateDetails`, []);
        return stateCoverages;
    }, [selectedVersion, stateCoveragesIndex]);

    const stateDetailPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.stateSpecificCoverages[${stateCoveragesIndex}].stateDetails`;
    const stateDetails = getStateDetails();

    const updateSubmissionVMToServer = useCallback(async (subVMUpdatedinPE) => {
        const coverages = _.get(subVMUpdatedinPE.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = WCCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = WCCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingClauses(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        const subVMUpdatedByRes = _.clone(subVMUpdatedinPE)
        _.set(subVMUpdatedByRes.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingClauses(false);
        updateWizardData(subVMUpdatedByRes);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingClauses,
    ]);

    return <>
        {stateDetails
            .map((displayable, index) => {
                const {
                    propertyName,
                    label,
                    required,
                    inputSetMode,
                    editable: isDisplayableEditable,
                    rangeValue,
                    rangeValueOptions = [],
                } = displayable

                if (loadingClauses && loadingClauses.includes(propertyName)) {
                    return <Loader
                        key={`lineDetails_${propertyName}_loader`}
                        showLoader
                    /> 
                }

                const onDynamicRangeValueChange = (newValue) => {
                    const newDsiplayable = {
                        ...displayable,
                        updated: true,
                        rangeValue: newValue
                    }
                    _.set(submissionVM.value, `${stateDetailPath}[${index}]`, newDsiplayable);
                    updateWizardData(submissionVM);
                    setIsEditing(false);
                    updateSubmissionVMToServer(submissionVM)
                }

                if (!isEditable) {
                    return  <div className={`${styles.readOnlyContainer} flex-1 d-flex`}>
                        <span className='flex-1'>
                            {label}
                        </span>
                        <WniDropdownSelect
                            hideLabel
                            id={`displayable_dynamicrange_${propertyName}_readonly`}
                            value={rangeValue}
                            availableValues={rangeValueOptions.map((v) => {
                                return {code: v,name: v}
                            })}
                            placeholder=""
                            size="medium"
                            className='flex-1'
                            readOnly
                        />
                    </div>
                }

                return <div
                    key={propertyName}
                    className='d-flex'
                >
                    {inputSetMode === 'dynamicrange' ? <WniDropdownSelect
                        id={`displayable_dynamicrange_${propertyName}`}
                        label = {label}
                        value={rangeValue}
                        availableValues={rangeValueOptions.map((v) => {
                            return {code: v,name: v}
                        })}
                        onValueChange={onDynamicRangeValueChange}
                        placeholder=""
                        size="medium"
                        className='flex-1'
                        required={required}
                        onValidationChange={onValidate}
                        showErrors={showErrors}
                        readOnly={!isDisplayableEditable}
                    /> : null}
                </div>
            })}
    </>
}

WCStateDetails.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    lobName: PropTypes.string.isRequired,
    coveragesService: PropTypes.shape({}),
    loadingLineCoverages: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    setLoadingLineCoverages: PropTypes.func,
    setIsEditing: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
}

WCStateDetails.defaultProps = {
    updateWizardData: _.noop,
    coveragesService: {},
    loadingLineCoverages: false,
    setLoadingLineCoverages: _.noop,
    setIsEditing: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
}

export default WCStateDetails
