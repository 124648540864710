import React, {
    Component,
    useState,
    useEffect,
    useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniCustomEndorsementService } from 'wni-capability-policychange';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { PolicyChangeService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { PageHeaderComponent as FormatPageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import metadata from './Endorsement.metadata.json5';
import CommonRoutes from '../Common/CommonRouting';
import messages from './Summary/EndorsementSummary.messages';
import pageRouting from '../Common/Endorsement-config.json5';

function Endorsement(props) {
    const modalApi = useWniModal();
    const {
        match: {
            url,
            params: {
                jobNumber
            }
        },
        location: {
            pathname
        },
        history,
    } = props;

    const { authHeader } = useAuthentication();
    const [currentView, updateCurrentView] = useState('summary');
    const [policyChangeSummaryCount, updatePolicyChangeSummaryCount] = useState({});
    const [policyChange, updatePolicyChange] = useState({});
    const { loadingMask: { setLoadingMask, isLoadingMask } } = useDependencies('loadingMask');
    const [isCLLine, updateIsCLLine] = useState(false);

    const loadPolicyChangeSummaryCount = async () => {
        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        const tilesData = await PolicyChangeService.getPolicyChangeSummary(jobNumber, authHeader);
        const attachments = await WniCustomEndorsementService
            .getPortalAttachmentsForPolicyChange([jobNumber], authHeader);
        _.set(tilesData, 'numberOfDocuments', attachments.length);

        updateCurrentView(activeTile);
        updatePolicyChangeSummaryCount(tilesData);
    };

    const updatePolicyChangeSummary = (newPolicyChange) => {
        // updatePolicyChange({ ...newPolicyChange });
        updatePolicyChange(newPolicyChange);
    };

    const getJobByJobNumber = async (jobNumberParam) => {
        try {
            const newPolicyChange = await PolicyChangeService.findJobByJobNumber(jobNumberParam, authHeader);
            if (!newPolicyChange.statusCode) {
                modalApi.showAlert({
                    title: messages.error,
                    message: messages.details,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                });
                history.push('/policies');
            }
            const isCL =  _.get(newPolicyChange, 'policy.account.accountHolder.contactType_Ext') === 'company';
            updateIsCLLine(isCL);

            updatePolicyChangeSummary(newPolicyChange);
        } catch {
            modalApi.showAlert({
                title: messages.error,
                message: messages.details,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            });
            history.push('/policies');
        }
    };

    const loadingData = useCallback(async () => {
        setLoadingMask(true);
        await loadPolicyChangeSummaryCount();
        await getJobByJobNumber(jobNumber);
        setLoadingMask(false);
    }, [jobNumber]);

    useEffect(() => {
        loadingData();
    }, [loadingData]);

    const handleTilesOnClick = (id) => {
        updateCurrentView(id);
    };

    // ==========================================================================
    const render = () => {
        if (isLoadingMask) {
            return null;
        }
        if (_.isEmpty(policyChange)) {
            return null;
        }

        const policyChangeVM = {
            policyChange,
            policyChangeSummaryCount
        };
        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: false
            },
            notes: {
                visible: false
            }
        };
        const resolvers = {
            // resolveClassNameMap: styles,
            resolveCallbackMap: {
                handleTilesOnClick: handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: FormatPageHeaderComponent
            }
        };
        const PolicyChangeDetailspage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
        const policyChangeData = {
            jobNumber: jobNumber,
            loadPolicyChangeSummaryCount: loadPolicyChangeSummaryCount,
            loadPolicyChangeSummary: policyChange,
            updateJobSummary: updatePolicyChangeSummary
        };
        return (
            <>
                {PolicyChangeDetailspage}
                <CommonRoutes
                    steps={pageRouting}
                    basePath={url}
                    // keep this name quoteDetailsData
                    // In current version it means transactionDetailsData, and this props used by common components
                    quoteDetailsData={policyChangeData}
                />
            </>
        );
    };
    return render();
}

Endorsement.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            jobNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default withRouter(withAuthenticationContext(Endorsement));
