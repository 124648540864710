
import React, {
    useCallback, useContext, useRef, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import metadata from './MultiSelectField.metadata.json5';
import { CheckboxGroupField } from '@jutro/legacy/components';

const MultiSelectField = (props) => {
    const {
        id,
        path,
        value,
        disabled,
        availableValues = [],
        onValueChange,
        showErrors,
        readOnly,
        layout,
        className,
        multiContainerClassName,
        checkboxGroupComponent = CheckboxGroupField
    } = props;
    const classNameForReadOnly = readOnly ? ' hide' : '';
    const [selectedVals, updateSelectedVals] = useState(value || []);
    const [optionsShow, updateOptionsShow] = useState(false);

    const elementRef = useRef(null);

    // renderHelper.useOnClickOutside(elementRef, () => {
    //     if(elementRef){
    //         updateOptionsShow(false)
    //     }
    // });
    // Mec on 2024-02-28 (Wed): This is poor design.Please consider another solution when it is available.
    useEffect(() => {
        const callback = () => {
            if(elementRef){
                updateOptionsShow(false);
            };
        };
            
        function handler(event) {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                callback();
            }
        }

        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, [elementRef]);

    useEffect(() => {
        const parentDom = document.getElementById(id);
        if(window.addEventListener && parentDom){
            const selectFieldDom = parentDom.querySelector('.multiSelectContent');
            selectFieldDom.addEventListener('click', () => updateOptionsShow(true), false)
        }
    }, [selectedVals, id]);

    useEffect(() => {
        updateSelectedVals(value || []);
    }, [value]);

    const handleSelectChange = (vals) => {
        onValueChange(vals, path);
        updateSelectedVals(vals)
    }
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            showOptional: false,
            autoComplete: false,
            readOnly
        },
        typeaheadSelect: {
            ...props,
            isInitiallyOpen: false
        },
        multiSelectForm: {
            ref: elementRef
        },
        multiOptionContainer: {
            className: `${layout || ''} ${className} ${classNameForReadOnly} multiSelectOptions`,
            visible: optionsShow
        },
        multiSelectOption: {
            value: selectedVals,
            availableValues: availableValues,
            onValueChange: handleSelectChange,
            visible: !readOnly && !disabled
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            checkboxGroupComponent
        }
    };

    return (
        <div id={id} className={multiContainerClassName}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={selectedVals}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </div>
    );
};

MultiSelectField.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    availableValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onValueChange: PropTypes.func.isRequired,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool
};
MultiSelectField.defaultProps = {
    disabled: false,
    showErrors: false,
    readOnly: false
};
export default MultiSelectField;
