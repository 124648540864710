import React, {
    useContext,
    useState,
    useCallback
} from "react";
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import SingleClauseContext from "../../context/WCSingleClauseContext";
import messages from './WCComplexSchedulePopup.messages'
import CoverageContext from "../../context/WCCoverageContext";
import CoverageUtil from '../../util/WCCoverageUtil'
import WCComplexSchedulePopupTable from "./components/WCComplexSchedulePopupTable/WCComplexSchedulePopupTable";
import WCComplexSchedulePopupPropertyInfos from "./components/WCComplexSchedulePopupPropertyInfos/WCComplexSchedulePopupPropertyInfos";
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import WCComplexSchedulePopupCoverages from "./components/WCComplexSchedulePopupCoverages/WCComplexSchedulePopupCoverages";

import { Button } from '@jutro/legacy/components';

const WCComplexSchedulePopup = (props) => {

    const {
        schedule,
        size = 'lg',
        handleSave
    } = props

    const {
        clauseName,
    } = useContext(SingleClauseContext)

    const {
        wizardData: submissionVM,
        updateWizardData,
        lobName,
        selectedVersionIndex,
        coveragesService,
    } = useContext(CoverageContext)
    
    const {
        isComponentValid: isOpenedScheduleItemValid,
        onValidate,
        // registerComponentValidation,
    } = useValidation('ComplexScheduleEditPopup-OpenedScheduledItem');

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const translator = useTranslator();

    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [openedScheduleItemNumber, setOpenedScheduleItemNumber] = useState()

    const isScheduleInValid = CoverageUtil.isScheduleInvalid(schedule)

    const updateSubmissionVMToServer = useCallback(async (subVMUpdatedInPE) => {
        const coverages = _.get(subVMUpdatedInPE.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = CoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        setIsLoading(true)
        const response = await coveragesService.updateStateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        setIsLoading(false)
        const lobCoverages = _.get(response, lobName);
        const subVMUpdatedByRes = _.clone(subVMUpdatedInPE)
        _.set(subVMUpdatedByRes.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        updateWizardData(subVMUpdatedByRes);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        lobName,
        coveragesService,
    ]);

    const onSaveButtonClick = () => {
        if (!_.isNil(openedScheduleItemNumber)) {
            if (isOpenedScheduleItemValid) {
                setOpenedScheduleItemNumber(undefined)
                return
            }
            setShowErrors(true)
        } else {
            if (isScheduleInValid) {
                setShowErrors(true)
                return
            }
            handleSave()
        }
    }

    const onExitButtonClick = () => {
        onSaveButtonClick()
    }


    return <ModalNext isOpen className={size}>
        <ModalHeader title={clauseName} />
        <ModalBody id="complexScheduleModal">
            {isLoading ? <Loader /> : <>
                <WCComplexSchedulePopupTable
                    schedule={schedule}
                    openedScheduleItemNumber={openedScheduleItemNumber}
                    updateSubmissionVMToServer={updateSubmissionVMToServer}
                    setOpenedScheduleItemNumber={setOpenedScheduleItemNumber}
                    isLoading={isLoading}
                    isEditing = {isEditing}
                />
                {openedScheduleItemNumber && (<>
                    <WCComplexSchedulePopupPropertyInfos
                        schedule={schedule}
                        openedScheduleItemNumber={openedScheduleItemNumber}
                        updateSubmissionVMToServer={updateSubmissionVMToServer}
                        onValidate={onValidate}
                        showErrors={showErrors}
                        setIsEditing = {setIsEditing}
                    />
                    <WCComplexSchedulePopupCoverages
                        schedule={schedule}
                        openedScheduleItemNumber={openedScheduleItemNumber}
                        showErrors={showErrors}
                        onValidate={onValidate}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />

                </>)}
                {showErrors && isScheduleInValid && <div className='font-error-light-16 mb-10 mt--10' >Information for this coverage is missing.</div>}
            </>}
            
        </ModalBody>
        <ModalFooter contentLayout={{
            component: 'flex',
            componentProps: {
                alignItems: "middle",
                justifyContent: "between",
                wrap: 'reverse',
                gap: 'medium'
            }
        }}>
            <Button
                onClick={onExitButtonClick}
                className="wni-button-link"
                disabled={isLoading || isEditing}
            >{translator(messages.complexSchedulePopupExit)}</Button>
            <Button
                onClick={onSaveButtonClick}
                type="filled"
                disabled={isLoading || isEditing}
            >{translator(messages.complexSchedulePopupSave)}</Button>
        </ModalFooter>
    </ModalNext>
}

export default WCComplexSchedulePopup
