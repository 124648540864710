import React, {
    useContext,
    useState,
    useCallback
} from "react";
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import SingleClauseContext from "../../context/CRSingleClauseContext";
import messages from './CRComplexSchedulePopup.messages'
import CoverageContext from "../../context/CRCoverageContext";
import CoverageUtil from '../../util/CRCoverageUtil'
import CRComplexSchedulePopupTable from "./components/CRComplexSchedulePopupTable/CRComplexSchedulePopupTable";
import CRComplexSchedulePopupPropertyInfos from "./components/CRComplexSchedulePopupPropertyInfos/CRComplexSchedulePopupPropertyInfos";
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import CRComplexSchedulePopupCoverages from "./components/CRComplexSchedulePopupCoverages/CRComplexSchedulePopupCoverages";

import { Button } from '@jutro/legacy/components';

const CRComplexSchedulePopup = (props) => {

    const {
        schedule,
        size = 'lg',
        handleSave
    } = props

    const {
        clauseName,
    } = useContext(SingleClauseContext)

    const {
        updateVMToServer,
    } = useContext(CoverageContext)
    
    const {
        isComponentValid: isOpenedScheduleItemValid,
        onValidate,
        // registerComponentValidation,
    } = useValidation('ComplexScheduleEditPopup-OpenedScheduledItem');

    const translator = useTranslator();

    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [openedScheduleItemNumber, setOpenedScheduleItemNumber] = useState()

    const isScheduleInValid = CoverageUtil.isScheduleInvalid(schedule)

    const updateSubmissionVMToServer = useCallback(async (subVMUpdatedInPE) => {
        updateVMToServer(subVMUpdatedInPE, setIsLoading, setIsEditing)
    }, [updateVMToServer]);

    const onSaveButtonClick = () => {
        if (!_.isNil(openedScheduleItemNumber)) {
            if (isOpenedScheduleItemValid) {
                setOpenedScheduleItemNumber(undefined)
                return
            }
            setShowErrors(true)
        } else {
            if (isScheduleInValid) {
                setShowErrors(true)
                return
            }
            handleSave()
        }
    }

    const onExitButtonClick = () => {
        onSaveButtonClick()
    }


    return <ModalNext isOpen className={size}>
        <ModalHeader title={clauseName} />
        <ModalBody id="complexScheduleModal">
            {isLoading ? <Loader /> : <>
                <CRComplexSchedulePopupTable
                    schedule={schedule}
                    openedScheduleItemNumber={openedScheduleItemNumber}
                    updateSubmissionVMToServer={updateSubmissionVMToServer}
                    setOpenedScheduleItemNumber={setOpenedScheduleItemNumber}
                    isLoading={isLoading}
                    isEditing = {isEditing}
                />
                {openedScheduleItemNumber && (<>
                    <CRComplexSchedulePopupPropertyInfos
                        schedule={schedule}
                        openedScheduleItemNumber={openedScheduleItemNumber}
                        updateSubmissionVMToServer={updateSubmissionVMToServer}
                        onValidate={onValidate}
                        showErrors={showErrors}
                        setIsEditing = {setIsEditing}
                    />
                    <CRComplexSchedulePopupCoverages
                        schedule={schedule}
                        openedScheduleItemNumber={openedScheduleItemNumber}
                        showErrors={showErrors}
                        onValidate={onValidate}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />

                </>)}
                {showErrors && isScheduleInValid && <div className='font-error-light-16 mb-10 mt--10' >Information for this coverage is missing.</div>}
            </>}
            
        </ModalBody>
        <ModalFooter contentLayout={{
            component: 'flex',
            componentProps: {
                alignItems: "middle",
                justifyContent: "between",
                wrap: 'reverse',
                gap: 'medium'
            }
        }}>
            <Button
                onClick={onExitButtonClick}
                className="wni-button-link"
                disabled={isLoading || isEditing}
            >{translator(messages.complexSchedulePopupExit)}</Button>
            <Button
                onClick={onSaveButtonClick}
                type="filled"
                disabled={isLoading || isEditing}
            >{translator(messages.complexSchedulePopupSave)}</Button>
        </ModalFooter>
    </ModalNext>
}

export default CRComplexSchedulePopup
