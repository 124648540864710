
const minYear = 1945;
const OTHER = 'Other';
const OUTBOARD_MAP = [
    'watercraft_motor_trailer_outboard',
    'watercraft_motor_outboard',
    'Outboard',
    'Non-motorized'
];
const INBOARD_MAP = [
    'watercraft_motor_trailer_inboard',
    'watercraft_motor_inboard',
    'Inboard/Outdrive',
    'Personal Watercraft',
    'Sail Boats'
];
const itemIsuredTypeKey = 'typekey.ItemToBeInsuredType_Ext'
const basePathMap = ['watercraftDetail', 'motorDetail', 'trailerDetail']
// const requiredFields = ['year', 'manufacturer', 'model', 'lossSettlementValuationMethod', 'selectedWatercraftUsages', 'watercraftUsedMonths']
const requiredForIssuanceData = {
    watercraftDetail: [
        "otherManufacturer",
        "otherModel",
        "identificationNumber",
        "registrationState",
        "registrationStateExplain",
        "purchaseDate",
        "motorIdentificationNumber",
        "isPhysicalDamageCovRequired",
        "currentMarketValue",
        "coverageAmount",
       
    ],
    watercraftInfo: [
        // info 
        "selectedWatercraftKepts",
        "watercraftKeptExplain",
        "selectedWatercraftApplies",
        "hasMotorBeenModified",
        "hasMotorBeenModifiedExplain",
        "isThereExistingDamage",
        "isThereExistingDamageExplain",
        "hasCoOwner",
        "coOwnerName",
        "hasPhysicalDamageLast12Months",
        "watercraftStoredType",
        "hasAnyAdditionalInfo",
        "hasAnyAdditionalInfoExplain",
        "hasMotorBeenModified"
    ],
    motorDetail: [
        "otherManufacturer",
        "otherModel",
        "identificationNumber",
        // "registrationState",
        // "registrationStateExplain",
        "purchaseDate",
        // "motorIdentificationNumber",
        "isPhysicalDamageCovRequired",
        "currentMarketValue",
        "coverageAmount",
    ],
    motorInfo: [
        // info 
        "hasMotorBeenModified",
        "hasMotorBeenModifiedExplain",
        "isThereExistingDamage",
        "isThereExistingDamageExplain"
    ],
    trailerDetail: [
        "otherManufacturer",
        // "otherModel",
        "identificationNumber",
        "registrationState",
        "registrationStateExplain",
        "purchaseDate",
        "isPhysicalDamageCovRequired",
        "currentMarketValue",
        "coverageAmount",
        
    ],
    trailerInfo: [
        // info
        "isThereExistingDamage",
        "isThereExistingDamageExplain",
        "isUsedForBusinessPurposes",
        "isUsedForBusiPurposesExplain"
    ]
};

const itemToBeInsuredConfig = [
    {
        value: "watercraft_motor_trailer",
        sectionConfig: { watercraftDetail: true, motorDetail: true, trailerDetail: true, watercraftInfo: true, motorInfo: false, trailerInfo: false },
        defaultFlowType: 'watercraft_motor_trailer_outboard'
    },
    {
        value: "watercraft_motor",
        sectionConfig: { watercraftDetail: true, motorDetail: true, trailerDetail: false, watercraftInfo: true, motorInfo: false, trailerInfo: false },
        defaultFlowType: 'watercraft_motor_outboard'
    },
    {
        value: "watercraft",
        sectionConfig: { watercraftDetail: true, motorDetail: false, trailerDetail: false, watercraftInfo: true, motorInfo: false, trailerInfo: false },
        defaultFlowType: 'watercraft'
    },
    {
        value: "motor",
        sectionConfig: { watercraftDetail: false, motorDetail: true, trailerDetail: false, watercraftInfo: false, motorInfo: true, trailerInfo: false },
        defaultFlowType: 'motor'
    },
    {
        value: "trailer",
        sectionConfig: { watercraftDetail: false, motorDetail: false, trailerDetail: true, watercraftInfo: false, motorInfo: false, trailerInfo: true },
        defaultFlowType: 'trailer'
    }
];

const defaultObj = {
    watercraftDetail: {
        selectedWatercraftUsages: [],
        selectedWatercraftKepts: [],
        selectedWatercraftApplies: [],
        watercraftDetailType: 'watercraft',
        isPhysicalDamageCovRequired: true
    },
    motorDetail: {
        watercraftDetailType: 'motor'
    },
    trailerDetail: {
        watercraftDetailType: 'trailer'
    }
}

export {
    minYear,
    OTHER,
    OUTBOARD_MAP,
    INBOARD_MAP,
    basePathMap,
    requiredForIssuanceData,
    itemToBeInsuredConfig,
    defaultObj,
    itemIsuredTypeKey
}