import { useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
ViewModelForm,
ViewModelServiceContext
} from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React,{ useCallback, useContext,useMemo,useState } from 'react';

import { ProductUtil, TableRowUtil } from 'wni-portals-util-react';

import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { dtoName,xCenter, wtDtoName } from '../../config/vehicles.static';
import { ButtonWithTips,IncidentTitle } from '../Actions/Actions';
import VehicleDetails from '../VehicleDetails/VehicleDetails';
import WatercraftDetails from '../VehicleDetails/WatercarftDetails';
import RTVehicleDetails from '../VehicleDetails/RTVehicleDetails';
import metadata from './VehicleIncident.metadata.json5';
import messages from '../../FNOLPAVehiclesPage.messages';

import { AccordionCard } from '@jutro/legacy/components';

const {
    PA_LOB_NAME,
    RT_LOB_NAME,
    WT_LOB_NAME
} = ProductUtil;

function VehicleIncidentSection(props) {
    const modalApi = useModal();
    const {
        claimNumber,
        basePath,
        authHeader,
        tableData = [],
        onValidate = _.noop,
        writeValue = _.noop,
        syncWizardData,
        syncWizardDataSnapshot,
        resetWizardDataToSnapshot,
        saveIncident,
        addButtonProps,
        LOB_NAME,
        PAGE_TYPE_NAME,
        VehicleService,
        initIncidentData = {},
        handleContactSelectionChange,
        updateIsEditing,
        isEditing,
        availableDrivers,
        updateAvailableDrivers,
        availablePassengers,
        updateAvailablePassengers,
    } = props;
    const dtoPath = LOB_NAME === WT_LOB_NAME ? wtDtoName : dtoName;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const [accordionStates, updateAccordionStates] = useState([]);

    const removeVehicleIncident = async (e, item) => {
        if(e) {
            e.stopPropagation();
        }
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline',
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return false;
            }
            updateIsEditing(false);
            if(!item.publicID) {
                const newTableData = tableData.filter((v) => v.rowIdPath !== item.rowIdPath);
                syncWizardData(newTableData, basePath);
                return false;
            };
            setLoadingMask(true);
            const res = await VehicleService.removeVehicleIncident(
                claimNumber,
                item.publicID,
                authHeader
            );
            setLoadingMask(false);
            syncWizardDataSnapshot(res, basePath);
            updateAccordionStates([]);
            return true;
        }, _.noop);
    };
    
    const addIncident = async() => {
        const initRowData = {
            ...initIncidentData,
            lossPartyType: _.get(addButtonProps, 'incidentType'),
            pageTypeName: PAGE_TYPE_NAME,
            isSave: true,
            rowIdPath: `cc:${TableRowUtil.getUuid()}`,
            location: { country: 'US'},
            [PAGE_TYPE_NAME]: {
                watercraft: {},
                motor: {},
                trailer: {}
            }
        };
        const newTableData = [...tableData, initRowData];
        syncWizardData(newTableData, basePath);
        
        // // find the new incident index, and open corresponding accordion card
        const newIndex = newTableData.length - 1;
        updateAccordionStates([`vehicleIncidentCard${newIndex}`])

        updateIsEditing(true);
    };

    const onValueChange = (value, path, vm) => {
        if(vm) {
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(vm.value, path, undefined);
            } else {
                _.set(vm.value, path, value);
            }
            const currentIndex = tableData.findIndex((item) => item.rowIdPath === _.get(vm, 'rowIdPath.value'));
            const newTableData = _.cloneDeep(tableData);
            _.set(newTableData, currentIndex, vm.value);
            syncWizardData(newTableData, basePath);
        }
    };

    const saveAndClose = useCallback(async (rowValue) => {
        const driverPublicID = _.get(rowValue, 'driver.publicID');
        const passengersPublicID = _.get(rowValue, 'passengers', []).map(item => item.publicID);
        if(driverPublicID && passengersPublicID.includes(driverPublicID)) {
            modalApi.showAlert({
                title: messages.invalidSelectionTitle,
                message: translator(messages.invalidSelectionText),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return;
        }
        await saveIncident(basePath, rowValue);
        updateAccordionStates([]);
        updateIsEditing(false);
        updateAvailableDrivers(null);
        updateAvailablePassengers(null);
    },[basePath, modalApi, saveIncident, translator, updateAvailableDrivers, updateAvailablePassengers, updateIsEditing])

    const cancel = () => {
        // resetWizardDataToSnapshot();
        updateAccordionStates([]);
        updateIsEditing(false);
        updateAvailableDrivers(null);
        updateAvailablePassengers(null);
    }
    
    const renderAccordionCard = useCallback(() => {
        let FieldComponent = VehicleDetails
        switch(LOB_NAME){
            case RT_LOB_NAME:
                FieldComponent = RTVehicleDetails
                break;
            case WT_LOB_NAME:
                FieldComponent = WatercraftDetails
                break;
            default:
                FieldComponent = VehicleDetails
                break;
        }
        return tableData.map((item, index) => {
            const initRowData = {
                ...item, 
                isSave: true, 
                location: item.location || { country: 'US'} 
            };
            const vm = viewModelService.create(initRowData, xCenter, dtoPath);
            let isOpening = accordionStates[0] === `vehicleIncidentCard${index}`;
            if (_.isEmpty(accordionStates)) {
                isOpening = false
            }
            // none open & isEditing -> isCollapsible -> all false
            // one open & isEditing -> isCollapsible -> opened one true , rest is false
            let isCollapsible = true;
            if (!isOpening && isEditing) {
                isCollapsible = false;
            } else if (isOpening && isEditing) {
                isCollapsible = true;
            }

            return (
                <AccordionCard
                    isCollapsible={isCollapsible}
                    id={`vehicleIncidentCard${index}`}
                    chevron
                    chevronAlignment="left"
                    title={
                        <IncidentTitle
                            disabled={!isCollapsible}
                            data={item}
                            PAGE_TYPE_NAME={PAGE_TYPE_NAME}
                            onRemove={(e) =>
                                removeVehicleIncident(e, item)
                            }
                        />
                    }
                >
                {!isOpening ? null : 
                    <FieldComponent 
                        id={`incidentDetails${index}`}
                        currentRow={vm}
                        writeValue={(value, path) =>
                            onValueChange(value, path, vm)
                        }
                        cancel={cancel}
                        save={(rowValue) => saveAndClose(rowValue)}
                        VehicleService={VehicleService}
                        claimNumber={claimNumber}
                        authHeader={authHeader}
                        handleContactSelectionChange={handleContactSelectionChange}
                        availableDrivers={availableDrivers}
                        updateAvailableDrivers={updateAvailableDrivers}
                        availablePassengers={availablePassengers}
                        updateAvailablePassengers={updateAvailablePassengers}
                    />
                }
                </AccordionCard>
            );
        });
    }, [LOB_NAME, PAGE_TYPE_NAME, VehicleService, accordionStates, authHeader, availableDrivers, availablePassengers, cancel, claimNumber, dtoPath, handleContactSelectionChange, isEditing, onValueChange, removeVehicleIncident, saveAndClose, tableData, updateAvailableDrivers, updateAvailablePassengers, viewModelService]);

    const overrides = useMemo(
        () => ({
            '@field': {
                labelPosition: 'left',
            },
            addIncidentAction: {
                ...addButtonProps,
                onClick: addIncident,
            },
            incidentAccordion: {
                accordionStates: accordionStates,
                onUpdateAccordionStates: async (ids) => {
                    updateAccordionStates(ids);
                    if (_.isEmpty(ids)) {
                        updateIsEditing(false)
                    } else {
                        setLoadingMask(true);
                        const idx = _.replace(ids[0], 'vehicleIncidentCard', '');
                        const editingRowData = tableData[idx];
                        const { publicID } = editingRowData
                        try {
                            const res = await VehicleService.getOrCreateVehicleIncident(
                                claimNumber,
                                publicID,
                                authHeader
                            );
                            _.set(editingRowData, 'driverMap', res.driverMap)
                            _.set(editingRowData, 'passengerMap', res.passengerMap)
                            _.set(editingRowData, 'publicID', res.publicID)
                            const newTableData = _.cloneDeep(tableData);
                            _.set(newTableData, idx, editingRowData);
                            syncWizardData(newTableData, basePath);
                            updateIsEditing(true);
                        } finally {
                            setLoadingMask(false);
                        }
                    }
                },
                content: renderAccordionCard(),
                closeOthers: true
            },
        }),
        [VehicleService, accordionStates, addButtonProps, addIncident, authHeader, basePath, claimNumber, renderAccordionCard, setLoadingMask, syncWizardData, tableData, updateIsEditing]
    );
    const resolvers = {
        resolveComponentMap: {
            vehicledetails: VehicleDetails,
            buttonwithtips: ButtonWithTips,
        },
        resolveCallbackMap: {
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            tableData,
            id,
            path,
            overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={tableData}
            overrideProps={overrides}
            onValueChange={writeValue}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default VehicleIncidentSection;
