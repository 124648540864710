import { defineMessages } from 'react-intl';

export default defineMessages({
    businessQuote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.bop.Businessowners Property Quote',
        defaultMessage: 'Businessowners Property Quote'
    },
    company: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.bop.Company',
        defaultMessage: 'Company'
    },
    location: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.bop.Location',
        defaultMessage: 'Location'
    }
});
