
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { PortalConstants } from 'wni-portals-config-js';
import { DPResidenceChangeService, DPRCTChangeService } from 'wni-capability-policychange-dp';

import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

import { DPResidencePage } from 'wni-capability-quoteandbind-dp-react';

function DPResidenceChangePage(props) {

    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();

    return (
        <DPResidencePage {...props}
            dpRctService = {DPRCTChangeService}
            dpResidenceService = {DPResidenceChangeService}
            transactionType = { PortalConstants.TRANSACTIONTYPE_POLICYCHANGE }
            checkRequiredForIssuance = {false}
            isSkipPrefillRCT
            isReadonlyFireProtectionClass
            constructionFieldReadOnlyFlags = {
                {
                    isConstructionOptionReadOnly: !isExternalUser,
                    isNumberofBathroomsReadOnly: !isExternalUser,
                    isRoofSlopeReadOnly:  !isExternalUser,
                }
            }
        />
    );

}

DPResidenceChangePage.propTypes = DPResidenceChangePage.propTypes;
DPResidenceChangePage.defaultProps = DPResidenceChangePage.defaultProps;

export default DPResidenceChangePage;
