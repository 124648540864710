import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    addVersions: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Add Versions',
        defaultMessage: 'Add Versions',
    }, 
    createNewVersion: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Create another version of this quote',
        defaultMessage: 'Create another version of this quote',
    }, 
    createHO3Version: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Create HO3 quote',
        defaultMessage: 'Create HO3 quote',
    }, 
    createHO5Verseion: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Create HO5 quote',
        defaultMessage: 'Create HO5 quote',
    }, 
    createSelectVersion: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Create a Select version',
        defaultMessage: 'Create a Select version',
    },
    createSelectPlusVersion: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Create a Select Plus version',
        defaultMessage: 'Create a Select Plus version',
    },
    // ===============================================
    chooseAddVersions: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Choose / Add Versions',
        defaultMessage: 'Choose / Add Versions',
    },
    chooseVersions: {
        id: 'custom.component.ho.HoAddNewVersionComponent.Choose Versions',
        defaultMessage: 'Choose Versions',
    },
});
