import React, {
    Component,
    useContext,
    useCallback,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { UserService } from 'gw-capability-gateway';
import SelectProducerCodeMessages from './SelectProducerCode.messages';


const workStartedByMe = {
    code: 'myWork',
    name: SelectProducerCodeMessages.workStartedByMe
};
const everything = {
    code: 'all',
    name: SelectProducerCodeMessages.everything
};

const myTransactions = {
    code: 'myTransactions',
    name: SelectProducerCodeMessages.myTransactions,
}

export const ProducerContext = React.createContext({
    producerCode: 'all',
    setProducerCode: () => {}
});

function SelectProducerCodeContextProvider(props) {

    const {
        children
    } = props;

    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();

    const [producerCodeList, updateProducerCodeList] = useState([]);
    const [unSortedProducerCodeList, updateUnSortedProducerCodeList] = useState([]);
    const [producerCode, updateProducerCode] = useState('all');

    const setProducerCode = (producerCodeParam) => {
        updateProducerCode(producerCodeParam);
    }

    const getProducerCode = async () => {
        const availableProducerCode = await UserService.getAvailableProducerCodesForCurrentUser(
            authHeader
        );

        const codeList = _.sortBy(
            availableProducerCode.map((codes) => {
                const availableProducerCodesforUser = {
                    code: codes.code,
                    name: codes.displayValue
                };
                return availableProducerCodesforUser;
            }),
            (o) => o.code
        );
        const producerCodeListVal = [workStartedByMe, everything, ...codeList];

        if (isExternalUser) {
            producerCodeListVal.push(myTransactions);
        }

        updateProducerCodeList(producerCodeListVal);
        updateUnSortedProducerCodeList(availableProducerCode);
    };

    useEffect(() => {
        getProducerCode();
    }, []);

    return (
        <ProducerContext.Provider
            value={{
                unSortedProducerCodeList,
                producerCodeList,
                producerCode,
                setProducerCode
            }}
        >
            {children}
        </ProducerContext.Provider>
    );
}

SelectProducerCodeContextProvider.propTypes = {
    // children: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({})
    ]).isRequired,
};

SelectProducerCodeContextProvider.defaultProps = {
    children: null,
};

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
export function withProducerContext(WrappedComponent) {
    function WithProducerContext(props) {
        const producerProps = useContext(ProducerContext);
        return <WrappedComponent {...producerProps} {...props} />;
    }
    hoistNonReactStatic(WithProducerContext, WrappedComponent);
    WithProducerContext.displayName = `WithProducerContext(${getDisplayName(WrappedComponent)})`;
    return WithProducerContext;
}

export const SelectProducerContextProvider = SelectProducerCodeContextProvider;
export default ProducerContext.Consumer;
