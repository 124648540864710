import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    ErrorsAndWarningsUtil,
    OOSUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { JobUtil } from '@xengage/gw-portals-util-js';
import moment from 'moment';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniEndorsementService, WniPAPolicyChangeService, WniCustomEndorsementService } from 'wni-capability-policychange';
import {
    AgencyAndAgentInfoComponent,
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, ActionListComponent, useWniModal } from 'wni-components-platform-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { useAORValidationData } from 'wni-portals-util-react';
import metadata from './PAPolicyChangeStartPage.metadata.json5';
import policyChangePageMessages from '../PolicyDetails/PAPolicyDetailsChangePage.messages';
import policyChangeStartPageMessages from './PAPolicyChangeStartPage.messages';

function PAPolicyChangeStartPage(props) {
    const modalApi = useWniModal();
    // const { EndorsementService } = useDependencies('EndorsementService');
    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        EndorsementService,
        loadingMask: { setLoadingMask },
    } = useDependencies(['EndorsementService', 'loadingMask']);
    const {
        isComponentValid,
        onValidate,
        registerComponentValidation
    } = useValidation('PAPolicyChange');
    const {
        updateWizardData,
        updateWizardSnapshot,
        wizardData: submissionVM,
        updateWizardReadOnly,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        // history,
        updateWizardPageData,
    } = props;
    const { authHeader } = useAuthentication();
    const [showErrors, updateShowErrors] = useState(false);
    // const [warningMessage, updateWarningMessage] = useState();
    const [showDescription, updateShowDescription] = useState('');
    const [showLoader, updateShowLoader] = useState(false);
    const [frontendValidationIssuesWarning, updateFrontendValidationIssuesWarning] = useState([]);
    const [backendValidationIssuesWarning, updateBackendValidationIssuesWarning] = useState([]);
    const [setEffectiveDateOfChange, updateEffectiveDateofChange] = useState(true);
    const translator = useTranslator();
    const history = useHistory();
    const { validateAORAccess } = useAORValidationData();
    const isEffectiveDateValid = useCallback(() => {
        const minDate = _.get(submissionVM, 'value.baseData.minimumEffectiveDate');
        const maxDate = _.get(submissionVM, 'value.baseData.maximumEffectiveDate_Ext');
        const currentEffectiveDate = _.get(submissionVM, 'value.baseData.effectiveDate');

        const min = moment(minDate).toDate();
        const max = moment(maxDate).toDate();
        const current = moment(currentEffectiveDate).toDate();
        if (current >= min && current <= max) {
            return true;
        }
        return false;
    }, [submissionVM])

    useEffect(() => {
        registerComponentValidation(isEffectiveDateValid);
    }, [registerComponentValidation, isEffectiveDateValid]);

    const {
        jobID,
        policyNumber,
        baseData: {
            effectiveDate,
            producerCode_Ext: producerCode,
            productCode,
            coverageTerms_Ext: coverageTerms,
        },
        oossliceDates_Ext: oossliceDates,
    } = submissionVM.value;

    const validationIssuesWarning = frontendValidationIssuesWarning
        .concat(backendValidationIssuesWarning);

    const getCurrentCoverageTerm = useCallback(() => {
        const termOfSelectedEffectiveDate = coverageTerms.find((coverageTerm) => {
            const selectedEffectiveDate = moment(effectiveDate).toDate();
            const termStartDate = moment(coverageTerm.termStartDate).toDate();
            const termEndDate = moment(coverageTerm.termEndDate).toDate();
            const effectiveDateIsValid = moment(selectedEffectiveDate, 'YYYY-MM-DD').isValid();
            if (effectiveDateIsValid === false
                    || (selectedEffectiveDate >= termStartDate
                        && selectedEffectiveDate < termEndDate)
            ) {
                return true;
            }
            return false;
        });
        return termOfSelectedEffectiveDate;
    }, [effectiveDate, coverageTerms]);

    useEffect(() => {
        validateAORAccess(producerCode) 
    }, [])


    const getFrontendValiationIssuesWarning = useCallback(() => {
        let frontendValidationWarning = [];
        // Check has cancellation on inforce period
        const selectedCoverageTerm = getCurrentCoverageTerm();
        if (_.get(selectedCoverageTerm, 'cancelled', false)) {
            frontendValidationWarning = frontendValidationWarning.concat({
                type: 'warning',
                reason: translator(
                    policyChangeStartPageMessages.policyIsCancelled,
                    { cancellationDate: WniDateUtil.formatDateWithPattern(_.get(selectedCoverageTerm, 'cancellationDate'))}
                )
            });
        }
        return frontendValidationWarning;
    }, [getCurrentCoverageTerm, translator]);

    useEffect(() => {
        updateFrontendValidationIssuesWarning(getFrontendValiationIssuesWarning());
    }, [effectiveDate, getFrontendValiationIssuesWarning]);

    const handleEffectiveDateChange = (newEffectiveDate) => {
        _.set(submissionVM, 'baseData.effectiveDate', newEffectiveDate);
        updateWizardData(submissionVM);
    };

    const handleDescription = (des) => {
        _.set(submissionVM, 'baseData.description_Ext', des);
        updateWizardData(submissionVM);
        updateShowDescription(des);
    };

    const changeToDefaultWizardProcess = () => {
        const { lobEndorsementURL, endorsementProducts } = appConfig;

        if (endorsementProducts.includes(productCode)) {
            // ref: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
            // ref2: https://reactrouter.com/web/api/history
            history.replace(lobEndorsementURL[productCode], {
                isReadOnly: false,
                policyNumber: policyNumber,
                jobNumber: _.get(submissionVM.value, 'jobID'),
            });
        } else {
            JobUtil.openJobInXCenter(jobID);
        }
    };

    const loadEndorsementWithEffectiveDate = useCallback(() => {
        if (jobID) {
            return WniEndorsementService.loadEndorsementWithjobIDAndEffectiveDate(
                jobID,
                submissionVM.baseData.effectiveDate.value,
                authHeader
            );
        }
        return EndorsementService.loadEndorsementWithEffectiveDate(
            [
                policyNumber,
                submissionVM.baseData.effectiveDate.value,
            ],
            authHeader
        );
    }, [submissionVM, EndorsementService, authHeader, jobID, policyNumber]);

    const savePolicyChange = useCallback(async () => {
        const response = await EndorsementService.checkEffectiveDateIsValid(
            [
                submissionVM.policyNumber.value,
                submissionVM.baseData.effectiveDate.value,
            ],
            authHeader
        );
        if (response) {
            const effectiveDateResposne = await loadEndorsementWithEffectiveDate();
            // submissionVM.value = new PolicyChange(effectiveDateResposne);
            submissionVM.value = effectiveDateResposne;

            const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
            const oldVehicles = _.cloneDeep(vehicles) || [];
            // const policyCountryCode =
            // _.get(submissionVM.value, 'baseData.policyAddress.country');
            // oldVehicles.forEach((vehicle) => {
            //     VehicleUtil.updatePolicyCountryForVehicle(vehicle, policyCountryCode);
            // });
            _.set(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', oldVehicles);
            if (_.isEmpty(showDescription)) {
                const existShowDescription = _.get(submissionVM.value, 'baseData.description_Ext');
                _.set(submissionVM, 'baseData.description_Ext', existShowDescription);
            } else {
                _.set(submissionVM, 'baseData.description_Ext', showDescription);
            }

            // When renew period is selected, set period end to end of coverageDate
            const maximumEffectiveDate = _.get(submissionVM.value, 'baseData.maximumEffectiveDate_Ext');
            const periodEndDate = _.get(submissionVM.value, 'baseData.periodEndDate');
            if (moment(effectiveDate).diff(moment(periodEndDate), 'days') > 0) {
                _.set(submissionVM, 'baseData.periodEndDate', maximumEffectiveDate);
            }
            updateWizardData(submissionVM);
            // save page temp data
            // submissionVM.value.beforeSave();
            const policyChangeResponse = await WniPAPolicyChangeService.savePolicyDetails(
                null,
                _.get(submissionVM.value, 'policyNumber'),
                _.get(submissionVM.value, 'description'),
                _.get(submissionVM.value, 'baseData'),
                _.get(submissionVM.value, 'lobData.personalAuto.preQualQuestionSets[0]'),
                'PAPolicyDetails',
                authHeader
            );
            const backendErrorsAndWarnings = _.get(policyChangeResponse, 'errorsAndWarnings_Ext', {});
            const backendValidationIssues = ErrorsAndWarningsUtil
                .getValidationIssues(backendErrorsAndWarnings);
            const hasBackendError = backendValidationIssues.some((issue) => issue.type === 'error');
            if (hasBackendError) {
                updateBackendValidationIssuesWarning(backendValidationIssues);
                return false;
            }
            await WniCustomEndorsementService
                .addRecentlyViewedPolicyChange(policyChangeResponse.jobID, authHeader);
            submissionVM.value = policyChangeResponse;
            updateWizardData(submissionVM);
            updateWizardSnapshot(submissionVM);

            // return true;
            // 2022-11-0: The following check might result into Portal staying on 
            // the start page even after a new PolicyChange has been created. Consider to remove them.
            updateBackendValidationIssuesWarning(backendValidationIssues);
            const newValidationIssuesWarning = backendValidationIssues
                .concat(frontendValidationIssuesWarning);
            if (ErrorsAndWarningsUtil
                .hasNewValidationIssue(validationIssuesWarning, newValidationIssuesWarning)) {
                return false;
            }
        }
        return submissionVM;
    }, [
        submissionVM,
        authHeader,
        showDescription,
        updateWizardData,
        updateWizardSnapshot,
        EndorsementService,
        frontendValidationIssuesWarning,
        validationIssuesWarning,
        loadEndorsementWithEffectiveDate,
        effectiveDate
    ]);

    const handleInitalPolicyChangePageNextBtnClicked = async () => {
        // const response = await updateOOSSliceDatesAndWarning();
        setLoadingMask(false);
        const OOSSliceDatesStringArray = OOSUtil.getOOSSliceDatesString(
            oossliceDates,
            effectiveDate
        );
        let createPolicyChangeSuccess;
        if (!_.isEmpty(OOSSliceDatesStringArray)) {
            try {
                const modalResponse = await modalApi.showConfirm({
                    title: translator(
                        policyChangePageMessages.OOSSliceDatesPopupMessage,
                        { OOSSliceDates: OOSSliceDatesStringArray }
                    ),
                    message: '',
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel'
                });
                if (modalResponse === 'confirm') {
                    setLoadingMask(true);
                    createPolicyChangeSuccess = await savePolicyChange();
                    setLoadingMask(false);
                    if (createPolicyChangeSuccess) {
                        updateWizardReadOnly(false);
                        // changeToDefaultWizardProcess();
                    }
                }
            } catch {
                // do nothing when click cancel button
                _.noop();
            }
        } else {
            updateShowLoader(true);
            setLoadingMask(true);
            createPolicyChangeSuccess = await savePolicyChange();
            setLoadingMask(false);
            updateShowLoader(false);
            if (createPolicyChangeSuccess) {
                updateWizardReadOnly(false);
                // changeToDefaultWizardProcess();
            }
        }
        updateShowErrors(true);
        return false;
        // return submissionVM;
    };

    const isShowNotification = useCallback(() => {
        const policyInfoSource = _.get(
            submissionVM,
            'baseData.PolicyInfoSource_Ext'
        );
        if (policyInfoSource && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const loadCovertPolicyInfo = useCallback(() => {
        // const currentEffectiveDate = _.get(submissionVM, 'baseData.periodStartDate.value');
        // const infoDate = WniDateUtil.formatDateWithPattern(currentEffectiveDate).subtract(34, 'days');
        const covertInfo = `messages.covertedPolicyInfoStart.defaultMessage
        + infoDate + messages.covertedPolicyInfoEnd.defaultMessage`;
        return covertInfo;
    }, []);

    const selectedCoverageTerm = getCurrentCoverageTerm();

    // ======================

    const pageOnNext = async () => {
        updateWizardPageData({ [WizardConstants.landingPage]: 'PCPAPolicyChange' });
        return handleInitalPolicyChangePageNextBtnClicked();
    };
    const getPageJumpAction = (wizardPageName) => {
        return async () => {
            if (showLoader || !isComponentValid) {
                updateShowErrors(true);
                return;
            }
            if (wizardPageName) {
                updateWizardPageData({ [WizardConstants.landingPage]: wizardPageName });
            }
            handleInitalPolicyChangePageNextBtnClicked();
        };
    };
    const getPageJumpList = () => {
        const retval = [{
            name: 'Policy Details',
            action: getPageJumpAction('PCPAPolicyChange'),
        }, {
            name: 'Driver',
            action: getPageJumpAction('PCPADriverPage'),
        }, {
            name: 'Vehicle',
            action: getPageJumpAction('PCPAVehiclePage'),
        }, {
            name: 'Additional Interest',
            action: getPageJumpAction('PCPAVehiclePage'),
        }, {
            name: 'Coverage',
            action: getPageJumpAction('PACoveragesPage'),
        }];
        return retval;
    };

    const loadCurrentEffectiveDateAsBlank = () => {
        if (setEffectiveDateOfChange === true) {
            _.set(submissionVM, 'value.baseData.effectiveDate', '');
            updateEffectiveDateofChange(false);
        }
        return _.get(submissionVM, 'value.baseData.effectiveDate');
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        covertNotification: {
            visible: isShowNotification(),
            message: loadCovertPolicyInfo(),
        },
        effectiveDate: {
            value: loadCurrentEffectiveDateAsBlank(),
            onValueChange: handleEffectiveDateChange,
            minDate: _.get(submissionVM, 'value.baseData.minimumEffectiveDate'),
            maxDate: _.get(submissionVM, 'value.baseData.maximumEffectiveDate_Ext'),
            showErrors,
        },
        policyEffectiveDate: {
            value: _.get(selectedCoverageTerm, 'termStartDate', null),
            showErrors
        },
        policyExpirationDate: {
            value: _.get(selectedCoverageTerm, 'termEndDate', null),
            showErrors
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssuesWarning
                .concat(wizardErrorsAndWarnings)
                .concat(
                    OOSUtil.getOOSSliceDatesWarning(
                        OOSUtil.getOOSSliceDatesString(
                            oossliceDates,
                            effectiveDate
                        ),
                        translator,
                        false
                    )
                ),
            visible: validationIssuesWarning.length > 0
                || wizardErrorsAndWarnings.length > 0
                || OOSUtil.getOOSSliceDatesString(
                    oossliceDates,
                    effectiveDate
                ).length > 0,
            scrollToIssues: true,
        },
        pageJumpList: {
            actions: getPageJumpList(),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleEffectiveDateChange: handleEffectiveDateChange,
            handleDescription: handleDescription,
        },
        resolveComponentMap: {
            agencyagentinfo: AgencyAndAgentInfoComponent,
            validationissuescomponent: ValidationIssuesComponent,
            // paunderwritingquestionsinput: PAUnderwritingQuestionsInput,
            questionset: QuestionSetComponent,
            actionlist: ActionListComponent,
        },
    };

    return (
        <WizardPage
            onNext={pageOnNext}
            // disableNext={!isComponentValid}
            showPrevious={false}
            disableNext={showLoader || !isComponentValid}
            // skipWhen={PolicyChangeUtil.getSkipDraftFn()}
        >
            {
                !showLoader && (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PAPolicyChangeStartPage.propTypes = {
    ...wizardProps,
};
export default PAPolicyChangeStartPage;
