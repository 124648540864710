import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class ClaimService {
    static getClaimSummaries(query, offset, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimSummaries', [query, offset], additionalHeaders);
    }

    static getClaimDetail(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimDetail', [claimNumber], additionalHeaders);
    }

    static getClaimPolicyDetail(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimPolicyDetail', [claimNumber], additionalHeaders);
    }

    static getClaimActivities(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimActivities', [claimNumber], additionalHeaders);
    }

    static getClaimNotes(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimNotes', [claimNumber], additionalHeaders);
    }

    static createClaimNote(claimNumber, noteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'createClaimNote', [claimNumber, noteDTO], additionalHeaders);
    }

    static updateClaimNote(claimNumber, note, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'updateClaimNote', [claimNumber, note], additionalHeaders);
    }

    static deleteClaimNote(claimId, noteId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'deleteClaimNote', [claimId, noteId], additionalHeaders);
    }

    static uploadDocument(file, documentMetadata, additionalHeaders = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('claimDocumentUpload'),
            file, documentMetadata, additionalHeaders);
    }

    static claimsDocUploadToken(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claimDocs'),
            'generateUploadToken',
            params, additionalHeaders);
    }

    static claimsRemoveDocument(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claimDocs'),
            'removeDocument',
            params, additionalHeaders);
    }
}
