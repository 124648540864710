import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
    CoverableSummaryComponent,
} from 'wni-capability-gateway-react';


/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CRJurisdictionSummaryComponent(props) {

    const {
        jurisdiction, // CRJurisdictionDTO;
    } = props;

    if (_.isEmpty(jurisdiction)) {
        return null;
    }

    const {
        state,
        // hiredAuto,
        // nonOwnedAuto,
    } = jurisdiction;


    return (
        <div>
            <CoverableSummaryComponent coverable={state} />
            {/* 
            <CoverableSummaryComponent coverable={hiredAuto} />
            <CoverableSummaryComponent coverable={nonOwnedAuto} />
            */}
        </div>
    );
    
}

CRJurisdictionSummaryComponent.propTypes = {
    
};

CRJurisdictionSummaryComponent.defaultProps = {
    
};


export default CRJurisdictionSummaryComponent;