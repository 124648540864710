/* eslint-disable camelcase */
import messages from './WALTooltip.message';

const WALTooltips = {
    WALMedicalPayments_Ext : messages.WALMedicalPayments_Ext,
    policyState: messages.policyState,
    itemToBeInsured: messages.itemToBeInsured,
    watercraftRegistrationState: messages.watercraftRegistrationState,
    trailerRegistrationState: messages.trailerRegistrationState,
    primarilyUsedCounty: messages.primarilyUsedCounty,
    hasAnyAdditionalInfo: messages.hasAnyAdditionalInfo,
    WALReplacementCostPersonalProp_Ext: messages.WALReplacementCostPersonalProp_Ext,
    WALTripInterruptionCoverage_Ext: messages.WALTripInterruptionCoverage_Ext,
}

export default WALTooltips