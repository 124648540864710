
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import message from './AccountClearanceComponent.messages';
import metadata from './AccountClearanceComponent.metadata.json5';
import styles from './AccountClearanceComponent.module.scss';
import { Button } from '@jutro/legacy/components';

const AccountClearanceComponent = (props) => {
    const {
        isSXS,
        tableData,
        onStartNewQuote,
        toAccountSummary,
        onConfirmMerge
    } = props;
    const translator = useTranslator();
    const { interactionModel } = useDependencies('interactionModel');

    useEffect(() => {
    }, []);

    const startNewQuoteLink = (item, index, { id: property }) => {
        const value = translator(message.startNewQuote);
        // item type: AccountClearanceDTO
        const { accountNumber, allQuotesInLastYearDeclined } = item;
        return (
            <Button
                id={`button${index}`}
                size="small"
                // className="btn-action"
                onClick={() => onStartNewQuote(accountNumber)}
            >
                {value}
            </Button>
        );
    };

    const getAccountLink = (item) => {
        return (
            <FieldLinkComponent
                id={`account${item.accountNumber}`}
                value={item.accountNumber}
                onClick={() => toAccountSummary(item.accountNumber)}
            />
        );
    };
    const getMatchQuoteLink = (item) => {
        const { matchingQuoteNumber } = item;
        const matchingQuoteArr = matchingQuoteNumber.split(',');
        return (
            <div>
                {
                    matchingQuoteArr.map((quote) => {
                        return (
                            <span title={quote}>
                                <FieldLinkComponent
                                    id={`quote${quote}`}
                                    value={quote}
                                    to={`/quotes/${quote}/summary`}
                                />
                            </span>
                        );
                    })
                }
            </div>
        );
    };
    const mergeAccountLink = (item, index, { id: property }) => {
        const value = translator(message.merge);
        const { accountNumber, allQuotesInLastYearDeclined } = item;
        return (
            <Button
                id={`button${index}`}
                className="btn-action"
                onClick={() => onConfirmMerge(accountNumber)}
            >
                {value}
            </Button>
        );
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false
        },
        accountClearanceTable: {
            data: tableData
        },
        startNewQuotecolumn: {
            visible: !isSXS
        },
        mergeAccountColumn: {
            visible: isSXS
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            tableData,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            startNewQuoteLink: startNewQuoteLink,
            mergeAccountLink: mergeAccountLink,
            getAccountLink: getAccountLink,
            getMatchQuoteLink: getMatchQuoteLink
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={tableData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            // componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
};

AccountClearanceComponent.propTypes = {
    isSXS: PropTypes.bool,
    tableData: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    onStartNewQuote: PropTypes.func.isRequired,
    toAccountSummary: PropTypes.func.isRequired,
    onConfirmMerge: PropTypes.func.isRequired
};

AccountClearanceComponent.defaultProps = {
    isSXS: false,
    tableData: {},
    submissionVM: {}
};

export default AccountClearanceComponent;
