import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import metadata from './InjuryContactDetails.metadata.json5';
import messages from '../Contact/ContactDetails.messages';

function ContactDetails(props) {
    const {
        contactVM,
        writeValue = _.noop,
        save,
        cancel,
        showPerson = false,
        availablePersons,
        handlePersonChange,
        incidentType
    } = props;
    const {
        primaryPhoneType,
        rowIdPath: currentPersonId
    } = contactVM.value;

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation
    } = useValidation('contactDetails');

    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const [showErrors, updateShowErrors] = useState(false);
    const [selectedPerson, updateSelectedPerson] = useState(currentPersonId || 'newPerson');

    const handleSelectPerson = (rowIdPath) => {
        updateSelectedPerson(rowIdPath);
        handlePersonChange(rowIdPath);
    }

    useEffect(() => {
        const currentDisplayPerson = availablePersons.find(person => person.code === currentPersonId);
        if (!_.isNil(_.get(currentDisplayPerson, 'code'))) {
            updateSelectedPerson(currentPersonId);
        } else {
            updateSelectedPerson('newPerson');
        }
        
    }, [availablePersons, currentPersonId])

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const onSave = () => {
        if(!isComponentValid || !contactVM.aspects.valid || !contactVM.aspects.subtreeValid) {
            handleValidation();
            return false;
        }
        save()
    };

    const isFiledVisible = (path) => {
        let isVisible = true;
        switch(path) {
            case 'workNumber':
                isVisible = !primaryPhoneType || primaryPhoneType === 'work'
                break;
            case 'homeNumber':
                isVisible = !primaryPhoneType || primaryPhoneType === 'home'
                break;
            case 'cellNumber':
                isVisible = !primaryPhoneType || primaryPhoneType === 'mobile'
                break;
            default:
                break
        }
        if (!isVisible) {
            _.set(contactVM.value, path, undefined)
        }
        return isVisible
    };

    const overrides = useMemo(() => ({
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        selectPerson: {
            alwaysShowPlaceholder: false,
            visible: showPerson && incidentType === 'insured',
            availableValues: availablePersons,
            value: selectedPerson,
            onValueChange: handleSelectPerson
        },
        personContainer: {
            className: incidentType === 'insured' ? 'indentSection' : ''
        },
        addressField: {
            model: contactVM,
            basePath: 'primaryAddress',
            onValueChange: writeValue,
            onValidate,
            cityStateRequired: false
        },
        primaryPhoneType: {
            visible: false
        },
        lastName: {
            tooltip: translator(messages.unknowTips, {
                what: translator(messages.lastName)
            }),
            labelPosition: isPhone ? 'top' : 'left'
        },
        workNumber: {
            visible: isFiledVisible('workNumber')
        },
        homeNumber: {
            visible: isFiledVisible('homeNumber')
        },
        mobileNumber: {
            visible: isFiledVisible('cellNumber')
        }
       
    }), [contactVM, isFiledVisible]);
    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
            cancel,
            save: onSave
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            contactVM,
            id,
            path,
            overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={contactVM}
            overrideProps={overrides}
            onValueChange={writeValue}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default ContactDetails;
