import React, {useMemo, useEffect} from 'react';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { UrlUtil } from 'wni-portals-util-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import metadata from './MotorcycleDetails.metadata.json5';
import { getAvailableYears, getMutilAvailableValues, tooltipForVehicleType } from '../../util/Underlying.util';

function MotorcycleDetails(props) {
    const {
        vm,
        index,
        coveredItem,
        onValueChange,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance
    } = props;

    const translator = useTranslator();
    const { domainCompany : { code: domainCode } = {} } = useDependencies(['domainCompany']);

    const {
        onValidate: onValidationChange, 
        isComponentValid
    } = useValidation(`${coveredItem}DetailFields${index}`);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, `${coveredItem}DetailFields${index}`);
        }
        return () => {
            if (onValidate) {
                onValidate(true, `${coveredItem}DetailFields${index}`);
            }
        }
    }, [onValidate, isComponentValid])
    
    const writeValue = (value, path) => {
        onValueChange(value, path, { index, coveredItem })
    };
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        year: {
            availableValues: getAvailableYears()
        },
        selectVehicleApplies: {
            id: `selectVehicleApplies${index}`,
            availableValues: getMutilAvailableValues(vm, 'selectVehicleApplies', translator),
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);
    _.set(newMetadata, 'id', `${coveredItem}DetailFields${index}`);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
           
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            newMetadata, vm, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={newMetadata}
            model={vm}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidationChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default MotorcycleDetails;
