import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caUnderwritingQuestionsService'), method, data, additionalHeaders);
}

export default class CAUnderwritingQuestionsService {

    static saveCAUnderwritingQuestionsData(quoteID, sessionUUID, questionSetData, authHeader) {
        return processSubmission('saveCAUnderwritingQuestionsData', [quoteID, sessionUUID, questionSetData], authHeader);
    }

}