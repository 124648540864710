import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import HOCoverageUtil from '../../util/HOCoverageUtil';
import HOClausesComponentVM from '../../components/HOClausesComponentVM/HOClausesComponentVM';
import HOSingleClauseComponentVM from '../../components/HOSingleClauseComponentVM/HOSingleClauseComponentVM';
import HOCoverageDetailsPopup from '../../components/HOCoverageDetailsPopup/HOCoverageDetailsPopup';
import metadata from './HOPrimaryCoverages.metadata.json5';
import styles from './HOPrimaryCoverages.module.scss';


function HOPrimaryCoverages(props) {
    const modalApi = useWniModal();
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        updateErrorsAndWarnings,
        isEditing,
        setIsEditing,
        lobName,
        coveragesConfig,
        coveragesService,
        DPOverrideProps,
        isDPcoverages
    } = props;

    const {
        quoteID,
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const [loadingPrimaryCoverages, setLoadingPrimaryCoverages] = useState(false);

    const getPrimaryCoverages = useCallback(() => {
        const primaryCoverages = _.get(selectedVersion, 'coverages.primaryCoverages_Ext', []);
        return primaryCoverages;
    }, [selectedVersion]);

    const primaryCoveragesVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.primaryCoverages_Ext`;
    const primaryCoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.primaryCoverages_Ext`;
    const lobCoveragesPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`;
    const primaryCoverages = getPrimaryCoverages();

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = HOCoverageUtil.setClauseValue(primaryCoverages, primaryCoveragesVMPath, value, changedPath);
            _.set(submissionVM.value, primaryCoveragePath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            primaryCoverages,
            primaryCoveragesVMPath,
            primaryCoveragePath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubVMUpdatedInPE) => {
        const coverages = _.get(newSubVMUpdatedInPE.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = HOCoverageUtil.getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingPrimaryCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            quoteID || jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        setIsEditing(false);
        const subVMUpdatedByRes = _.clone(newSubVMUpdatedInPE)
        _.set(subVMUpdatedByRes.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        updateErrorsAndWarnings(errorsAndWarnings);
        setLoadingPrimaryCoverages(false);
        updateWizardData(subVMUpdatedByRes);
    }, [
        authHeader,
        jobID,
        quoteID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const showCoverageDetailsPopup = useCallback(
        (coverage) => {
            const componentProps = {
                coverage,
                quoteID,
                jobID,
                sessionUUID,
                coverageType: 'primaryCoverages_Ext',
                lobCoveragesPath,
                submissionVM,
                updateSubmissionVM: updateWizardData,
                isEditable,
                hideCovBstructureLimitItem: true,
                hideCovBstructureRelatedTerms: true,
                lobName,
                coveragesConfig,
                coveragesService,
            };
            return modalApi.showModal(
                <HOCoverageDetailsPopup {...componentProps} />
            );
        }, [
            quoteID,
            jobID,
            sessionUUID,
            lobCoveragesPath,
            submissionVM,
            updateWizardData,
            isEditable,
            lobName,
            coveragesConfig,
            coveragesService,
        ]
    )

    const onOpenCoverageBDetailsPopup = (clausePatternCode) => {
        const coverageBToOpen = primaryCoverages
            .find((coverage) => _.get(coverage, 'code_Ext') === clausePatternCode)
        showCoverageDetailsPopup(coverageBToOpen)
            .then(async (updatedCoverage) => {
                const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO({
                    // eslint-disable-next-line camelcase
                    primaryCoverages_Ext: [updatedCoverage]
                }, lobName);
                const updatedCoveragePublicIDs = HOCoverageUtil
                    .getUpdatedCoveragesPublicIDArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
                setLoadingPrimaryCoverages(updatedCoveragePublicIDs);
                const response = await coveragesService.updateCoverages(
                    quoteID || jobID,
                    sessionUUID,
                    clausesToUpdate,
                    authHeader
                )
                const lobCoverages = _.get(response, lobName);
                _.set(submissionVM.value, lobCoveragesPath, lobCoverages);
                setLoadingPrimaryCoverages(false);
                updateWizardData(submissionVM);
            })
            .catch(() => _.noop)
    }

    const generateOverrides = useCallback(() => {

        let isCovBLinkVisible = false
        primaryCoverages.forEach((coverage) => {
            
            if (_.get(coverage, 'code_Ext') === 'HOPCovB') {
                const allTerms = _.get(coverage, 'terms', [])
                // show links when has private related structure under coverage B or Click yes for
                //  'Do you want additional infomational now?'
                const addItNowTerm = allTerms.find((term) => {
                    return _.get(term, 'code_Ext') === 'PortalCustomCovTerm_IsAddItNow'
                })
                const addItNowTermChosenTerm = _.get(addItNowTerm, 'chosenTerm')
                const hoPrivateStructures = _.get(coverage, 'hoprivateStructures', [])
                if (addItNowTermChosenTerm === 'true' || _.get(hoPrivateStructures, 'length', 0) > 0) {
                    isCovBLinkVisible = true
                }
            }
        })
        const covBHasStructure = !!primaryCoverages.find((coverage) => {
            if (!_.get(coverage, 'code_Ext') === 'HOPCovB') {
                return false
            }
            const hoprivateStructures = _.get(coverage, 'hoprivateStructures', []);
            return _.get(hoprivateStructures, 'length', 0) > 0
        });
        return {
            '@field': {
                isEditable,
            },
            primaryCoverages: {
                loadingClause: loadingPrimaryCoverages,
                path: primaryCoveragesVMPath,
                value: primaryCoverages,
                hideCheckBoxForRequired: true,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    HOSingleClauseComponentVM: HOSingleClauseComponentVM,
                },
                hideCovBstructureRelatedTerms: covBHasStructure,
                hidePrivateRelatedStructures: true,
                isCovBLinkVisible: isCovBLinkVisible,
                setIsEditing,
                isEditing,
                coveragesConfig,
            }
        };
    }, [
        loadingPrimaryCoverages,
        primaryCoveragesVMPath,
        primaryCoverages,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
        coveragesConfig,
    ]);

    const overrideProps = generateOverrides();

    const overrides = _.mergeWith(overrideProps, DPOverrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    //---------------------
    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
            onOpenCoverageDetailsPopup: onOpenCoverageBDetailsPopup,
        },
        resolveComponentMap: {
            HOClausesComponentVM: HOClausesComponentVM,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

HOPrimaryCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
HOPrimaryCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default HOPrimaryCoverages;
