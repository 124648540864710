import WniProductsUtil from './WniProductsUtil';

 const {
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
 } = WniProductsUtil;
const productsDateMaps = {
    [WAL_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '08/22/2023',
            effectiveDate: '09/15/2023',
            rateBookDate: '08/15/2023',
        },
        {
            state: 'MN',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        },
        {
            state: 'SD',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        },
        {
            state: 'WI',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        },
        {
            state: 'IA',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        }
    ],
    [RT_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '08/22/2023',
            effectiveDate: '09/15/2023',
            rateBookDate: '08/15/2023',
        },
        {
            state: 'MN',
            creationDate: '08/22/2023',
            effectiveDate: '09/01/2023',
            rateBookDate: '08/05/2023',
        },
        {
            state: 'SD',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        },
        {
            state: 'WI',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        },
        {
            state: 'IA',
            creationDate: '08/22/2023',
            effectiveDate: '08/22/2023',
            rateBookDate: '08/05/2023',
        }
    ],
    [DP_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '1/16/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'MN',
            creationDate: '4/15/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'SD',
            creationDate: '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'WI',
            creationDate: '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'IA',
            creationDate:  '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        }
    ],
    [PU_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '1/16/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'MN',
            creationDate: '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'SD',
            creationDate: '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'WI',
            creationDate: '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        },
        {
            state: 'IA',
            creationDate: '1/23/2024',
            effectiveDate: '2/1/2024',
            rateBookDate: '2/1/2024',
        }
    ],
    [CA_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '01/01/2024',
            effectiveDate: '07/08/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'MN',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'SD',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'WI',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'IA',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        }
    ],
    [WCM_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '01/01/2024',
            effectiveDate: '07/08/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'MN',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'SD',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'WI',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'IA',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        }
    ],
    [GL_PRODUCT_CODE]: [
        {
            state: 'AK',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'MN',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'SD',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'WI',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        },
        {
            state: 'IA',
            creationDate: '01/01/2024',
            effectiveDate: '01/01/2024',
            rateBookDate: '01/01/2024',
        }
    ]
};

export default {
    productsDateMaps,
};
