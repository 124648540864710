import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CRPolicyChangeStartPage from './pages/Start/CRPolicyChangeStartPage';
import CRPaySuccessChangePage from './pages/PaySuccess/CRPaySuccessChangePage';

import CRQualificationChangePage from './pages/Qualification/CRQualificationChangePage';
import CRQualificationChangeReadOnlyPage from './pages/Qualification/CRQualificationChangePage.readonly';

import CRPolicyDetailsChangePage from './pages/PolicyDetails/CRPolicyDetailsChangePage';
import CRPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/CRPolicyDetailsChangePage.readonly';

import CRLocationsChangePage from './pages/Locations/CRLocationsChangePage';
import CRLocationsChangeReadOnlyPage from './pages/Locations/CRLocationsChangePage.readonly';

import CRLineCoveragesChangePage from './pages/LineCoverages/CRLineCoveragesChangePage';
import CRLineCoveragesChangeReadOnlyPage from './pages/LineCoverages/CRLineCoveragesChangePage.readonly';

import CRCommercialCrimeChangePage from './pages/CommercialCrime/CRCommercialCrimeChangePage';
import CRCommercialCrimeChangeReadOnlyPage from './pages/CommercialCrime/CRCommercialCrimeChangePage.readonly';

import CRCommercialFidelityChangePage from './pages/CommercialFidelity/CRCommercialFidelityChangePage';
import CRCommercialFidelityChangeReadOnlyPage from './pages/CommercialFidelity/CRCommercialFidelityChangePage.readonly';

import CRModifiersChangePage from './pages/Modifiers/CRModifiersChangePage';
import CRModifiersChangeReadOnlyPage from './pages/Modifiers/CRModifiersChangePage.readonly';

import CRRiskAnalysisChangePage from './pages/RiskAnalysis/CRRiskAnalysisChangePage';
import CRRiskAnalysisChangeReadOnlyPage from './pages/RiskAnalysis/CRRiskAnalysisChangePage.readonly';

import CRStateSpecificInformationChangePage from './pages/StateSpecificInformation/CRStateSpecificInformationPage';
import CRStateSpecificInformationChangeReadOnlyPage from './pages/StateSpecificInformation/CRStateSpecificInformationPage.readonly';


// WIZARD PAGE IMPORT ANCHOR

import CRQuoteChangePage from './pages/Quote/CRQuoteChangePage';
import CRQuoteChangeReadOnlyPage from './pages/Quote/CRQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        CRPolicyChangeStartPage: { component: 'CRPolicyChangeStartPage' },
        CRPaySuccessChangePage: { component: 'CRPaySuccessChangePage' },

        CRQualificationChangePage: { component: 'CRQualificationChangePage'},
        CRQualificationChangeReadOnlyPage: { component: 'CRQualificationChangeReadOnlyPage'},

        CRPolicyDetailsChangePage: { component: 'CRPolicyDetailsChangePage'},
        CRPolicyDetailsChangeReadOnlyPage: { component: 'CRPolicyDetailsChangeReadOnlyPage'},

        CRLocationsChangePage: { component: 'CRLocationsChangePage'},
        CRLocationsChangeReadOnlyPage: { component: 'CRLocationsChangeReadOnlyPage'},

        CRLineCoveragesChangePage: { component: 'CRLineCoveragesChangePage'},
        CRLineCoveragesChangeReadOnlyPage: { component: 'CRLineCoveragesChangeReadOnlyPage'},

        CRCommercialCrimeChangePage: { component: 'CRCommercialCrimeChangePage'},
        CRCommercialCrimeChangeReadOnlyPage: { component: 'CRCommercialCrimeChangeReadOnlyPage'},

        CRCommercialFidelityChangePage: { component: 'CRCommercialFidelityChangePage'},
        CRCommercialFidelityChangeReadOnlyPage: { component: 'CRCommercialFidelityChangeReadOnlyPage'},

        CRModifiersChangePage: { component: 'CRModifiersChangePage'},
        CRModifiersChangeReadOnlyPage: { component: 'CRModifiersChangeReadOnlyPage'},

        CRRiskAnalysisChangePage: { component: 'CRRiskAnalysisChangePage'},
        CRRiskAnalysisChangeReadOnlyPage: { component: 'CRRiskAnalysisChangeReadOnlyPage'},

        CRStateSpecificInformationChangePage: { component: 'CRStateSpecificInformationChangePage'},
        CRStateSpecificInformationChangeReadOnlyPage: {component: 'CRStateSpecificInformationChangeReadOnlyPage'},


// WIZARD PAGE COMPONENT NAME ANCHOR
        CRQuoteChangePage: { component: 'CRQuoteChangePage' },
        CRQuoteChangeReadOnlyPage: { component: 'CRQuoteChangeReadOnlyPage' },
    },
    {
        CRPolicyChangeStartPage,
        CRPaySuccessChangePage,

        CRQualificationChangePage,
        CRQualificationChangeReadOnlyPage,

        CRPolicyDetailsChangePage,
        CRPolicyDetailsChangeReadOnlyPage,

        CRLocationsChangePage,
        CRLocationsChangeReadOnlyPage,

        CRLineCoveragesChangePage,
        CRLineCoveragesChangeReadOnlyPage,

        CRCommercialCrimeChangePage,
        CRCommercialCrimeChangeReadOnlyPage,

        CRCommercialFidelityChangePage,
        CRCommercialFidelityChangeReadOnlyPage,

        CRModifiersChangePage,
        CRModifiersChangeReadOnlyPage,

        CRRiskAnalysisChangePage,
        CRRiskAnalysisChangeReadOnlyPage,

        CRStateSpecificInformationChangePage,
        CRStateSpecificInformationChangeReadOnlyPage,


// WIZARD PAGE COMPONENT MAP ANCHOR
        CRQuoteChangePage,
        CRQuoteChangeReadOnlyPage,
    }
);

export { default as CRPolicyChangeWizard } from './CRPolicyChangeWizard';