import { defineMessages } from 'react-intl';

export default defineMessages({
    modifierRangeInfo: {
        id : 'wni.quoteandbind.cp-wizard.cp-statespecific.modifierRangeInfo',
        defaultMessage: 'The system allows a total request of {max} credit or debit. For requests outside of that range, please contact an underwriter.'
    },
    creditDebitOutOfRange: {
        id : 'wni.quoteandbind.cp-wizard.cp-statespecific.creditDebitOutOfRange',
        defaultMessage: 'Agents are restricted from proceeding with a summative credit or debit value exceeding {agentStateTotalRangeMaximum}'
    },
    justificationsRequired: {
        id : 'wni.quoteandbind.cp-wizard.cp-statespecific.justificationsRequired',
        defaultMessage: 'Please enter a justification for any entered credits or debits before continuing'
    },
    invalidRateFactorError: {
        id : 'wni.quoteandbind.cp-wizard.cp-statespecific.invalidRateFactorError',
        defaultMessage: 'Please enter a valid percentage.'
    },
    unEditableCategory: {
        id : 'wni.quoteandbind.cp-wizard.cp-statespecific.unEditableCategory',
        defaultMessage: "Dispersion or Concentration of Values Insured: Insured property is, or is not, sufficiently dispersed to minimize the probability of loss from the same cause"
    }
});