
import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import defaultMetadata from './QuestionSetsComponent.metadata.json5';

const QuestionSetsComponent = (props) => {
    const {
        model: dwellingQuestionSetsVM,
        isReadOnly,
        onValidate,
        showErrors,
        onValueChange,
        baseState,
        coverageForm,
        isRequiredForIssuance,
        messages,
        metadataOverride,
        DPOverrideProps
    } = props;

    const metadata = metadataOverride || defaultMetadata;

    const stringQuestions = _.get(dwellingQuestionSetsVM, 'value[0].answers');
    const dateQuestionList = _.get(dwellingQuestionSetsVM, 'value[0].dateAnswers');
    const isPropertyWithin300Feet = _.get(stringQuestions, 'isPropertyWithin300Feet_Ext');
    const wasTheStructureOriginallyBuilt = _.get(stringQuestions, 'wasTheStructureOriginallyBuilt_Ext');
    const residentHaveCurrentInspection = _.get(stringQuestions, 'ResidentHaveCurrentInspection_Ext');

    const [question, updateQuestion] = useState();

    useEffect(() => {
        const dateQuestions = {};
        _.each(dateQuestionList, (dateQ) => {
            _.set(dateQuestions, dateQ.code, dateQ.answer);
        });
        const q = {};
        _.assign(q, stringQuestions, dateQuestions);
        updateQuestion(q);
    }, [dateQuestionList, dwellingQuestionSetsVM, stringQuestions]);

    const buildDateQuestionPath = useCallback((key) => {
        const prefix = 'dateAnswers';
        const index = _.findIndex(dateQuestionList, (dateQ) => dateQ.code === key);
        const path = `${prefix}[${index}].answer`;
        return path;
    }, [dateQuestionList]);

    const handleChange = useCallback((value, path) => {
        let resPath = 'value[0]';
        if (path === 'DateOfInspection_Ext') {
            resPath = `value[0].${buildDateQuestionPath('DateOfInspection_Ext')}`;
        } else {
            resPath = `${resPath}.answers.${path}`;
        }
        onValueChange(value, resPath);
    }, [buildDateQuestionPath, onValueChange]);

    const changeToBool = (string) => {
        return (/^true$/i).test(string);
    };

    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(metadata.componentContent), []);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            isRequiredForIssuance
        },
        structureOriginallyBuilt:{
            label: messages.structureOriginallyBuilt
        },
        structureOriginallyBuiltExplain: {
            visible: changeToBool(wasTheStructureOriginallyBuilt)
        },
        commercialOrNonresidential: {
            visible: baseState === 'AK'
        },
        commercialOrNonresidentialExplain: {
            visible: changeToBool(isPropertyWithin300Feet) && baseState === 'AK'
        },
        residentHaveCurrentInspection: {
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6' && baseState === 'AK',
            label: messages.residentHaveCurrentInspection
        },
        dateOfInspection: {
            visible: changeToBool(residentHaveCurrentInspection)
                    && coverageForm !== 'ho4' && coverageForm !== 'ho6' && baseState === 'AK'
        },
        accessibleByVehicle: {
            label: messages.accessibleByVehicle
        },
    };
    const overrides = _.mergeWith(overrideProps, DPOverrideProps, overridesForIssuance, (obj, src) => {
        return {...obj, ...src}
    });
    const resolvers = {
        resolveCallbackMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={question}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={handleChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

QuestionSetsComponent.propTypes = {
    model: PropTypes.shape({}),
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    coverageForm: PropTypes.string,
    messages: PropTypes.object
};

QuestionSetsComponent.defaultProps = {
    model: {},
    showErrors: true,
    isReadOnly: false,
    onValidate: _.noop,
    coverageForm: '',
    messages: {}
};

export default QuestionSetsComponent;
