import { defineMessages } from 'react-intl';

export default defineMessages({
    address: {
        id: 'quoteandbind.cp.views.cp-confirmation.Address',
        defaultMessage: 'Address',
    },
    contactDetails: {
        id: 'quoteandbind.cp.views.cp-confirmation.Contact Details',
        defaultMessage: 'Contact Details'
    },
    name: {
        id: 'quoteandbind.cp.views.cp-confirmation.CompanyName',
        defaultMessage: 'Company Name'
    },
    email: {
        id: 'quoteandbind.cp.views.cp-confirmation.Email',
        defaultMessage: 'Email'
    },
    startDate: {
        id: 'quoteandbind.directives.cp.QuoteDetailsBox.Start Date',
        defaultMessage: 'Start Date'
    },
    endDate: {
        id: 'quoteandbind.directives.cp.QuoteDetailsBox.End Date',
        defaultMessage: 'End Date'
    }
});
