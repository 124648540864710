import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { SpreadsheetProcessor } from 'gw-capability-spreadsheet-react';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import BuildingsListPage from './BuildingsList/BuildingsListPage';
import PECPBuildingsWizard from '../../components/BuildingWizard/AddBuildingWizard';

import OverallSummary from '../../components/summary/OverallSummary/OverallSummary';
import ViewBuildingInfo from '../../components/ViewBuildingInfo/ViewBuildingInfo';
import metadata from './CPBuildingsAndLocationsPage.metadata.json5';
import styles from './CPBuildingsAndLocationsPage.module.scss';
import messages from '../../PECPWizard.messages';

const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';

const getBuildings = (submission) => {
    const locationsList = _.get(submission, LOCATION_PATH);
    return locationsList.flatMap((location) => {
        return location.buildings.map((building) => ({
            buildingId: building.publicID,
            name: building.name,
            code: building.classCode.code,
            displayName: location.displayName
        }));
    });
};

const CPBuildingsAndLocationsPage = (props) => {
    const modalApi = useModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('CPBuildingsAndLocationsPage');
    const [showLandingPage, setLandingPageVisible] = useState(true);
    const [showBuildingList, setShowBuildingsList] = useState(false);
    const [showAddBuildingWizard, setBuildingWizardVisible] = useState(false);
    const [overallSummaryInfo, setOverallSummaryVisible] = useState({
        showOverallSummary: false,
        location: {},
        building: {}
    });
    const [viewBuilding, setViewBuildingInfoVisible] = useState({
        isVisible: false,
        viewLocationAndBuildings: false,
        buildingLocationName: '',
        buildingToBeShown: {},
        editBuilding: ''
    });
    const [showSpreadsheetProcessor, setSpreadsheetProcessorVisible] = useState(false);
    const [newBuildings, setNewlyAddedBuildings] = useState([]);
    const [selectedLocation, updateSelectedLocation] = useState({});
    const {
        wizardData: submissionVM,
        updateWizardData,
        isNewlyAddedBuilding,
        newlyAddedBuilding,
        cancel
    } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');

    const shouldPageSkip = useCallback(() => {
        const jobType = _.get(submissionVM.value, 'baseData.jobType');
        const status = jobType === 'PolicyChange'
            ? _.get(submissionVM.value, 'status')
            : _.get(submissionVM.value, 'baseData.periodStatus');
        return status === 'Quoted';
    }, [submissionVM]);

    const hasBuildings = useCallback(() => {
        const locations = _.get(submissionVM, `${LOCATION_PATH}.value`);

        if (_.isNil(locations)) {
            return false;
        }

        return locations.some((location) => location.buildings.length > 0);
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldPageSkip);
        registerComponentValidation(hasBuildings);
    }, [
        hasBuildings,
        registerComponentValidation,
        registerInitialComponentValidation,
        shouldPageSkip
    ]);

    useEffect(() => {
        const existingBuildings = _.get(submissionVM.value, LOCATION_PATH).flatMap((location) => {
            return location.buildings;
        });

        if (!_.isEmpty(existingBuildings, true)) {
            setShowBuildingsList(true);

            if (isNewlyAddedBuilding) {
                setNewlyAddedBuildings([...newBuildings, newlyAddedBuilding]);
            }
        }
        // It should call once in component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updatePrimaryLocation = useCallback(
        async (newPrimaryLocation) => {
            const updatedLocation = await CPCoverablesService.updateCPLocation(
                _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID'),
                newPrimaryLocation,
                _.get(submissionVM.value, 'sessionUUID'),
                authHeader
            );

            return updatedLocation;
        },
        [authHeader, submissionVM]
    );

    const removeLocation = useCallback(
        (locationToRemove, cpLocationId) => {
            const locations = _.get(submissionVM.value, LOCATION_PATH);
            CPCoverablesService.removeCPLocation(
                _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID'),
                locationToRemove,
                _.get(submissionVM.value, 'sessionUUID'),
                authHeader
            ).then(() => {
                _.remove(locations, (currentLocation) => {
                    return currentLocation.publicID === cpLocationId;
                });
                _.set(submissionVM.value, LOCATION_PATH, locations);
                const toShowBuildingsList = locations.some((location) => {
                    return location.buildings.length > 0;
                });
                setShowBuildingsList(toShowBuildingsList);
                setLandingPageVisible(!toShowBuildingsList);
                setViewBuildingInfoVisible({
                    ...viewBuilding,
                    isVisible: false
                });
                updateWizardData(submissionVM);
            });
        },
        [authHeader, submissionVM, updateWizardData, viewBuilding]
    );

    const updateSubmission = useCallback(async () => {
        if (!_.isEmpty(submissionVM.quoteID)) {
            submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
                submissionVM.value,
                authHeader
            );
        } else if (!_.isEmpty(submissionVM.jobID)) {
            const isQuotedStatus = await LoadSaveService.isQuoted(
                [submissionVM.jobID.value],
                authHeader
            );
            if (!isQuotedStatus) {
                if (submissionVM.value.baseData.jobType === 'Renewal') {
                    submissionVM.value = await LoadSaveService.generateQuote(
                        [submissionVM.value],
                        authHeader
                    );
                } else {
                    submissionVM.value = await LoadSaveService.quoteEndorsement(
                        [submissionVM.jobID.value],
                        authHeader
                    );
                }
            }
        }

        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM]);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        const locationsWithoutBuildings = _.get(submissionVM.value, LOCATION_PATH).filter((loc) => {
            return loc.buildings.length === 0;
        });
        const locationsToUpdate = _.get(submissionVM.value, LOCATION_PATH);

        if (!_.isEmpty(locationsWithoutBuildings)) {
            const updateLocations = locationsWithoutBuildings.map(async (locationToRemove) => {
                if (locationToRemove.isPrimary) {
                    const newPrimaryLocation = _.get(submissionVM.value, LOCATION_PATH).find(
                        (loc) => {
                            return loc.publicID !== locationToRemove.publicID;
                        }
                    );
                    newPrimaryLocation.isPrimary = true;

                    const updatedLocation = await updatePrimaryLocation(newPrimaryLocation);

                    const updateLocationIndex = locationsToUpdate.findIndex((loc) => {
                        return loc.publicID === updatedLocation.publicID;
                    });
                    locationsToUpdate.splice(updateLocationIndex, 1, updatedLocation);
                }

                await removeLocation(locationToRemove);

                const removeLocationIndex = locationsToUpdate.findIndex((loc) => {
                    return loc.publicID === locationToRemove.publicID;
                });
                locationsToUpdate.splice(removeLocationIndex, 1);
            });
            await Promise.all(updateLocations);
        }

        return updateSubmission();
    }, [removeLocation, submissionVM, updatePrimaryLocation, updateSubmission]);

    const renderAddBuildingsWizard = useCallback(() => {
        updateSelectedLocation(undefined);
        setBuildingWizardVisible(true);
        setSpreadsheetProcessorVisible(false);
        setOverallSummaryVisible({
            ...overallSummaryInfo,
            showOverallSummary: false
        });
    }, [overallSummaryInfo]);

    const updateSubmissionWithNewBuilding = useCallback(
        (newLocation, newBuilding) => {
            const locationsList = _.get(submissionVM.value, LOCATION_PATH);

            const locationToUpdate = locationsList.find((location) => {
                return location.publicID === newBuilding.locationId;
            });

            const isLocationAlreadyUpdated = locationToUpdate
                && locationToUpdate.buildings.some((building) => {
                    return building.publicID === newBuilding.publicID;
                });

            if (locationToUpdate && !isLocationAlreadyUpdated) {
                locationToUpdate.buildings.push(newBuilding);
            } else if (
                newLocation.publicID === newBuilding.locationId
                && !isLocationAlreadyUpdated
            ) {
                newLocation.buildings.push(newBuilding);
                locationsList.push(newLocation);
            }

            _.set(submissionVM, LOCATION_PATH, locationsList);
            setNewlyAddedBuildings(() => {
                return [...newBuildings, newBuilding.publicID];
            });
            updateWizardData(submissionVM);
        },
        [submissionVM, newBuildings, setNewlyAddedBuildings, updateWizardData]
    );

    const onFinishAddBuilding = useCallback(
        (location, building) => {
            updateSubmissionWithNewBuilding(location, building);
            setBuildingWizardVisible(false);
            setOverallSummaryVisible({
                showOverallSummary: true,
                location: location,
                building: building
            });
        },
        [updateSubmissionWithNewBuilding]
    );

    const onCancelAddBuilding = useCallback(() => {
        setBuildingWizardVisible(false);
        setLandingPageVisible(!isComponentValid);
        setShowBuildingsList(isComponentValid);
    }, [isComponentValid]);

    const renderSpreadsheetProcessor = useCallback(() => {
        setSpreadsheetProcessorVisible(true);
    }, []);

    const onManualEntry = useCallback(() => {
        setBuildingWizardVisible(true);
        setSpreadsheetProcessorVisible(false);
    }, []);

    const addBuildingComplete = useCallback(() => {
        setShowBuildingsList(true);
        setOverallSummaryVisible({
            ...overallSummaryInfo,
            showOverallSummary: false
        });
    }, [overallSummaryInfo]);

    const onUseSpreadSheetButtonClicked = useCallback(() => {
        setSpreadsheetProcessorVisible(true);
    }, []);

    const viewBuildingDetails = useCallback((building, category, editBuildingDetails = '') => {
        let viewLocationAndBuildings;
        let buildingLocationName;
        let editBuilding;

        if (editBuildingDetails) {
            viewLocationAndBuildings = false;
            buildingLocationName = building.name;
            editBuilding = editBuildingDetails;
        } else {
            viewLocationAndBuildings = category === 'location';
            buildingLocationName = category === 'location' ? building.displayName : building.name;
            editBuilding = '';
        }

        setViewBuildingInfoVisible({
            isVisible: true,
            viewLocationAndBuildings,
            buildingLocationName,
            buildingToBeShown: building,
            editBuilding
        });
        setShowBuildingsList(false);
    }, []);

    const viewBuildingComplete = useCallback(() => {
        setViewBuildingInfoVisible({
            ...viewBuilding,
            isVisible: false
        });
        setShowBuildingsList(true);
    }, [viewBuilding]);

    const overallSummaryEditClicked = useCallback(
        (building, category) => {
            setOverallSummaryVisible({
                ...overallSummaryInfo,
                showOverallSummary: false
            });
            viewBuildingDetails(building, '', category);
        },
        [overallSummaryInfo, viewBuildingDetails]
    );

    const removeBuilding = useCallback(
        (quoteID, cpLocationId, cpBuildingId, sessionUUID) => {
            CPCoverablesService.removeCPBuilding(
                quoteID,
                cpLocationId,
                cpBuildingId,
                sessionUUID,
                authHeader
            ).then(() => {
                const locations = _.get(submissionVM.value, LOCATION_PATH);
                const loc = _.find(locations, (currentLocation) => {
                    return currentLocation.publicID === cpLocationId;
                });
                _.remove(loc.buildings, (currentBuilding) => {
                    return currentBuilding.publicID === cpBuildingId;
                });
                _.set(submissionVM.value, LOCATION_PATH, locations);
                const toShowBuildingsList = locations.some((location) => {
                    return location.buildings.length > 0;
                });
                setShowBuildingsList(toShowBuildingsList);
                setLandingPageVisible(!toShowBuildingsList);
                setViewBuildingInfoVisible({
                    ...viewBuilding,
                    isVisible: false
                });
                updateWizardData(submissionVM);
            });
        },
        [authHeader, submissionVM, updateWizardData, viewBuilding]
    );

    const deleteBuilding = useCallback(
        (cpLocationId, cpBuildingId) => {
            modalApi.showConfirm({
                title: messages.deleteBuilding,
                message: messages.deleteConfirmation,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.proceedToDelete,
                cancelButtonText: messages.cancelDelete
            }).then(async (results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                const quoteID = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
                const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
                const locations = _.get(submissionVM.value, LOCATION_PATH);
                locations.forEach(async (location) => {
                    if (location.publicID === cpLocationId) {
                        if (location.buildings.length <= 1 && !location.isPrimary) {
                            removeLocation(location, cpLocationId, cpBuildingId);
                        } else {
                            removeBuilding(quoteID, cpLocationId, cpBuildingId, sessionUUID);
                        }
                    }
                });
                return true;
            }, _.noop);
        },
        [submissionVM, removeLocation, removeBuilding]
    );

    const getFormattedMessage = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.addBuildingsUsingExcel}
                values={{
                    excel: <strong>{translator(messages.excelSpreadsheets)}</strong>,
                    faqlink: (
                        <Link to="/faq" target="_blank" className={styles.cpNeedMoreInfoSection}>
                            {`${translator(messages.faqInlineQuestions)}
                             ${translator(messages.needMoreInformation)}`}
                        </Link>
                    )
                }}
            />
        );
    }, [translator]);

    const onUploadSpreadsheetComplete = useCallback(
        (updatedSubmission) => {
            const newlyAddedLocations = _.get(updatedSubmission.value, 'updatedLocations');
            const coverables = _.get(submissionVM.value, 'lobData.commercialProperty.coverables');

            const updatedBuildings = newlyAddedLocations.flatMap((newLocations) => {
                return newLocations.buildings;
            });

            const existingBuildings = coverables.locations.flatMap((availableBuildings) => {
                return availableBuildings.buildings;
            });

            const newlyAddedBuildings = updatedBuildings.filter((newLocation) => {
                return !existingBuildings.find((existingLocation) => {
                    return existingLocation.publicID === newLocation.publicID;
                });
            });

            setNewlyAddedBuildings((existingBuildingList) => {
                return [
                    ...existingBuildingList,
                    ...newlyAddedBuildings.flatMap((newBuilding) => newBuilding.publicID)
                ];
            });

            coverables.locations.length = 0;
            coverables.locations = [...newlyAddedLocations];
            const toShowBuildings = _.get(submissionVM.value, LOCATION_PATH, []).some(
                (location) => {
                    return location.buildings.length > 0;
                }
            );
            setShowBuildingsList(toShowBuildings);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const addBuildingToSpecificLocation = useCallback(
        (locationToBeSelected) => {
            updateSelectedLocation(locationToBeSelected);
            setBuildingWizardVisible(true);
            setShowBuildingsList(false);
            setViewBuildingInfoVisible({
                ...viewBuilding,
                isVisible: false
            });
        },
        [viewBuilding]
    );

    const overrideProps = {
        mandatoryBuildingNotification: {
            visible: !isComponentValid
        },
        buildingsAndLocationContainer: {
            visible:
                showLandingPage
                && !showBuildingList
                && !showAddBuildingWizard
                && !overallSummaryInfo.showOverallSummary
                && !showSpreadsheetProcessor
                && !viewBuilding.isVisible
        },
        buildingsList: {
            visible:
                showBuildingList
                && !showAddBuildingWizard
                && !viewBuilding.isVisible
                && !overallSummaryInfo.showOverallSummary,
            locationsList: _.get(submissionVM.value, LOCATION_PATH),
            buildingsList: getBuildings(submissionVM.value),
            addBuildingToSpecificLocation: addBuildingToSpecificLocation,
            viewBuildingAndLocation: viewBuildingDetails,
            deleteBuilding: deleteBuilding,
            newBuildings: newBuildings
        },
        addBuildingButtonContainer: {
            visible: showBuildingList && !showAddBuildingWizard
        },
        useSpreadSheetButton: {
            visible: showBuildingList && !showSpreadsheetProcessor
        },
        spreadsheetBuildingsSeparator: {
            visible: showBuildingList && showSpreadsheetProcessor
        },
        buildingsWizard: {
            visible: showAddBuildingWizard,
            initialSubmission: submissionVM,
            finishAddBuilding: onFinishAddBuilding,
            cancelAddBuilding: onCancelAddBuilding,
            selectedLocation: selectedLocation,
            onCancel: cancel
        },
        pageTitleWrapper: {
            visible:
                !showAddBuildingWizard
                && !overallSummaryInfo.showOverallSummary
                && !viewBuilding.isVisible
        },
        overallSummary: {
            visible: overallSummaryInfo.showOverallSummary,
            onAddNewBuilding: renderAddBuildingsWizard,
            onComplete: addBuildingComplete,
            summaryInfo: {
                location: overallSummaryInfo.location,
                building: overallSummaryInfo.building
            },
            onEditClicked: overallSummaryEditClicked
        },
        viewBuildingInfo: {
            visible: viewBuilding.isVisible,
            isLocation: viewBuilding.viewLocationAndBuildings,
            title: viewBuilding.buildingLocationName,
            buildingInfo: viewBuilding.buildingToBeShown,
            submission: submissionVM,
            updateWizardData: updateWizardData,
            viewBuildingDetails: viewBuildingDetails,
            backToBuildingsPage: viewBuildingComplete,
            deleteBuilding: deleteBuilding,
            editBuilding: viewBuilding.editBuilding,
            addBuildingToSelectedLocation: addBuildingToSpecificLocation
        },
        spreadsheetProcessor: {
            visible: showSpreadsheetProcessor && !viewBuilding.isVisible,
            onCallbackResponse: onUploadSpreadsheetComplete,
            enableManualEntry: !isComponentValid,
            onManualEntry: onManualEntry,
            submissionVM: submissionVM
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            buildingslist: BuildingsListPage,
            buildingswizard: PECPBuildingsWizard,
            overallsummary: OverallSummary,
            viewbuildinginfo: ViewBuildingInfo,
            spreadsheetprocessor: SpreadsheetProcessor,
            formattedmessage: getFormattedMessage
        },
        resolveCallbackMap: {
            renderAddBuildingsWizard: renderAddBuildingsWizard,
            renderSpreadsheetProcessor: renderSpreadsheetProcessor,
            useSpreadSheetButtonClicked: onUseSpreadSheetButtonClicked,
            addBuildingButtonClicked: renderAddBuildingsWizard
        }
    };
    const toShowWizardButtons = !showAddBuildingWizard
    && !overallSummaryInfo.showOverallSummary && !viewBuilding.isVisible;
    const customMessageModal = showAddBuildingWizard
        ? {
            title: messages.cancelAddingBuilding,
            message: messages.cancelConfirmation
        }
        : {};

    return (
        <WizardPage
            showNext={toShowWizardButtons}
            showCancel={toShowWizardButtons}
            showPrevious={toShowWizardButtons}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            nextButtonTooltip={
                !isComponentValid && translator(messages.nextButtonTooltipForBuildings)
            }
            isSideHeader={
                showAddBuildingWizard
                || overallSummaryInfo.showOverallSummary
                || viewBuilding.isVisible
            }
            customMessageModal={customMessageModal}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
};

CPBuildingsAndLocationsPage.propTypes = {
    isNewlyAddedBuilding: PropTypes.bool,
    newlyAddedBuilding: PropTypes.string,
    ...wizardProps
};

CPBuildingsAndLocationsPage.defaultProps = {
    isNewlyAddedBuilding: false,
    newlyAddedBuilding: ''
};

export default CPBuildingsAndLocationsPage;
