import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caPayment'), method, data, additionalHeaders);
}

export default class CAPaymentService {
   
    /**
     * retrieveCLQuotePaymentDetailsData
     * 
     * @param {String} publicID 
     * @param {String} sessionUUID 
     * @param {Json} additionalHeaders 
     * @returns 
     */
    static retrieveCLQuotePaymentDetailsData(publicID, sessionUUID, additionalHeaders) {
        return processSubmission('retrieveCLQuotePaymentDetailsData', [publicID, sessionUUID], additionalHeaders);
    }

    /**
     * updateBillingMethodAndRetrievePaymentData
     * 
     * @param {String} quoteID 
     * @param {String} sessionUUID 
     * @param {String} selectedBillingMethod 
     * @param {Json} additionalHeaders 
     * @returns 
     */
    static updateBillingMethodAndRetrievePaymentData(quoteID, sessionUUID, selectedBillingMethod, additionalHeaders) {
        return processSubmission('updateBillingMethodAndRetrievePaymentData', [quoteID, sessionUUID, selectedBillingMethod], additionalHeaders);
    }

    /**
     * retrieveCLQuotePaymentData
     * 
     * @param {String[]} quoteIDs 
     * @param {Json} additionalHeaders 
     * @returns 
     */
    static retrieveCLQuotePaymentData(quoteIDs, additionalHeaders) {
        return processSubmission('retrieveCLQuotePaymentData', [quoteIDs], additionalHeaders);
    }

    /**
     * update billing paramter
     * 
     * @param {Object} CAPaymentRetrieveDetailDTO 
     * @param {Json} additionalHeaders 
     * @returns 
     */
    static saveBillingParameterToPeriod(CAPaymentRetrieveDetailDTO, additionalHeaders) {
        return processSubmission('saveBillingParameterToPeriod', [CAPaymentRetrieveDetailDTO], additionalHeaders);
    }

    static wniMultiQuotesBind(caPaymentRetrieveDetailDTOs, wniBindData,
        ignoreWarning, additionalHeaders = {}) {
        return processSubmission('wniMultiQuotesBind',
            [caPaymentRetrieveDetailDTOs, wniBindData, ignoreWarning],
            additionalHeaders);
    }

    static calculateInvoiceStreamCode(caPaymentRetrieveDetailDTOs, additionalHeaders = {}) {
        return processSubmission('calculateInvoiceStreamCode',
            [caPaymentRetrieveDetailDTOs],
            additionalHeaders);
    }

    static wniMultiQuotesDirectBind(caPaymentRetrieveDetailDTOs,
        ignoreWarning, additionalHeaders = {}) {
        return processSubmission('wniMultiQuotesDirectBind',
            [caPaymentRetrieveDetailDTOs, ignoreWarning],
            additionalHeaders);
    }

    static getReferenceNumber(middleNumber, isMultiAndNew, additionalHeaders = {}) {
        return processSubmission('getReferenceNumber',
            [middleNumber, isMultiAndNew],
            additionalHeaders);
    }
}