import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('caCoveragesService'), method, data, additionalHeaders);
}

export default class CACoveragesService {

    /**
     * Updates coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateLineCoverages(jobNumber, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateLineCoverages',
            [jobNumber, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Updates coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateStateCoverages(jobNumber, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateStateCoverages',
            [jobNumber, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Updates vehicle coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateVehicleCoverages(jobNumber, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateVehicleCoverages',
            [jobNumber, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Get vehicle coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {string} vehicleType the coverages to be saved
     * @param {vehiclePublicID} vehiclePublicID
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static getSingleVehicleCoverage(jobNumber, sessionUUID, vehicleType, vehiclePublicID, additionalHeaders = {}) {
        return processSubmission(
            'getSingleVehicleCoverage',
            [jobNumber, sessionUUID, vehicleType, vehiclePublicID],
            additionalHeaders
        );
    }

    /**
     * Get vehicle coverages.
     *
     * @param {Object} jobNumber jobNumber
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} caVehicleCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateSingleVehicleCoverage(jobNumber, sessionUUID, caVehicleCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateSingleVehicleCoverage',
            [jobNumber, sessionUUID, caVehicleCoverages],
            additionalHeaders
        );
    }
}