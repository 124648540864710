import React from 'react';


import CURiskAnalysisPage from './CURiskAnalysisPage';


function CURiskAnalysisReadOnlyPage(props) {

    return <CURiskAnalysisPage {...props} isReadOnly />
}


export default CURiskAnalysisReadOnlyPage;