
const CommonUITypes = [
    {
        name: 'Old UI: PL Only',
        code: 'OldUIForPLOnly',
        get Classical() { return true; },
    },
    {
        name: 'New UI: CL & PL',
        code: 'NewUIForCLPL',
        get Classical() { return false; },
    }
];

// For R1, R2, R3, R4, it is CommonUITypes[0]
// For R5 and onwards (with Commerical Line goes-live), it is CommonUITypes[1]
export const DEFAULT_COMMON_UI_TYPE = CommonUITypes[1];

export default CommonUITypes;
