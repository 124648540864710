
import React from 'react';
import { CUUnderlyingPage } from 'wni-capability-quoteandbind-cu-react';

function CUUnderlyingChangePage(props) {

    return (
        <CUUnderlyingPage {...props} isPolicyChange />
    );

};


export default CUUnderlyingChangePage;