/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniUrlUtil } from 'wni-portals-util-js';
import { AccountService } from 'gw-capability-gateway-policy';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import messages from 'gw-capability-gateway-react/NewQuote/NewQuoteAccountSearch.messages';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import currentMessage from './SearchAccountMatches.messages';
import metadata from './SearchAccountMatches.metadata.json5';

const SearchAccountMatches = (props) => {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask, isLoadingMask },
        workflowType,
    } = useDependencies(['loadingMask', 'workflowType']);
    const [accountMatches, updateAccountMatches] = useState([]);
    const [accountMatchesMsg, updateAccountMatchesMsg] = useState(currentMessage.noMatches);
    const {
        history,
        location: { state: serviceData, search },
        match: { params }
    } = props;

    const getResponse = (searchResponse) => {
        const userList = searchResponse.map((result) => {
            const user = {
                startNewQuote: `${translator(messages.startNewQuote)}`,
                accountNumber: result.accountNumber,
                displayName: result.accountHolder.primaryAddress.displayName,
                contactName: result.accountHolder.displayName,
                canView: result.canUserView && result.canUserCreateSubmission
            };
            return user;
        });
        return userList;
    };
    const showValidations = (value) => {
        let showContent = false;
        if (value.contactType === 'person') {
            if ((value.firstName && value.firstName.length < 5) || (value.lastName && value.lastName.length < 5)) {
                showContent = true;
            }
        }
        return showContent;
    };
    const getAccountMatches = useCallback(async () => {
        if (!serviceData) {
            return history.push(`/new-quote-account-search/search${search}`);
        }
        if (!serviceData.accountNumber_Ext && !serviceData.firstName) {
            return history.push(`/new-quote-account-search/search${search}`);
        }
        setLoadingMask(true);
        const accounts = await AccountService.getPotentialExistingAccounts(serviceData, authHeader);
        const existingSearchResult = getResponse(accounts);

        // // Add this filter for BA finding issue #144
        const accountCanView = _.filter(existingSearchResult, (item) => {
            return item.canView === true;
        });
        updateAccountMatches(accountCanView);
        if (existingSearchResult && existingSearchResult.length === 0) {
            if (serviceData.contactType === 'company') {
                if (serviceData.contactName && serviceData.contactName.length < 5) {
                    updateAccountMatchesMsg(currentMessage.noLengthMatches);
                } else {
                    updateAccountMatchesMsg(currentMessage.noMatches);
                }
            } else if (showValidations(serviceData) === true) {
                updateAccountMatchesMsg(currentMessage.noPersonLengthMatch);
            } else {
                updateAccountMatchesMsg(currentMessage.noMatches);
            }
        } else {
            updateAccountMatchesMsg(currentMessage.possibleBaseQuote);
        }
        setLoadingMask(false);
    }, [authHeader, serviceData]);

    useEffect(() => {
        getAccountMatches();
    }, [getAccountMatches]);

    const startNewQuoteLink = (item) => {
        const { startNewQuote, accountNumber } = item;

        let pathname = `/account-quotes/${accountNumber}`;
        if (workflowType.ClassicalWorkflow) {
            pathname = `/new-quote/${accountNumber}`;
        }
        return (
            <>
                {
                    item.canView ? (
                        <Link
                            to={{
                                // pathname: `/new-quote/${accountNumber}`,
                                // pathname: `/account-quotes/${accountNumber}`,
                                pathname,
                                state: {
                                    accountSearchCriteria: serviceData,
                                    externalData: params,
                                }
                            }}
                        >
                            {startNewQuote}
                        </Link>
                    ) : (
                        <span>{startNewQuote}</span>
                    )
                }
            </>
        );
    };

    const handleCancel = () => {
        modalApi.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.close
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            history.goBack();
        }, _.noop);
    };
    const onSearchAgain = () => {
        return history.push('/new-quote-account-search/search');
    };

    const handlenewCustomer = () => {
        const nextLocation = {
            accountSearchCriteria: serviceData,
            externalData: search ? WniUrlUtil.getURLSearchJson(search) : params
        };
        history.push('/new-quote', nextLocation);
    };

    const overrideProps = {
        existingSearchResult: {
            visible: !isLoadingMask
        },
        accountMatchesTable: {
            visible: !_.isEmpty(accountMatches),
            data: accountMatches
        },
        accountMatchesMessage: {
            content: accountMatchesMsg
        }
    };

    const resolvers = {
        resolveComponentMap: {

        },
        resolveCallbackMap: {
            startNewQuoteLink,
            handleCancel,
            onSearchAgain,
            handlenewCustomer
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={serviceData}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

SearchAccountMatches.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        params: PropTypes.shape({
            PolicyNumber: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
        goBack: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.string,
        search: PropTypes.string
    }).isRequired
};

export default SearchAccountMatches;
