import { defineMessages } from 'react-intl';

export default defineMessages({
    recentlyViewedTile: {
        id: 'wni.gateway.views.account-landing.Recently Viewed',
        defaultMessage: 'Recently Viewed'
    },
    recentlyCreatedTile: {
        id: 'wni.gateway.views.account-landing.Recently Created',
        defaultMessage: 'Recently Created'
    },
    personalAccountsTile: {
        id: 'wni.gateway.views.account-landing.Personal Accounts',
        defaultMessage: 'Personal Accounts'
    },
    commercialAccountsTile: {
        id: 'wni.gateway.views.account-landing.Commercial Accounts',
        defaultMessage: 'Commercial Accounts'
    }
});
