
import React from 'react';
import _ from "lodash";
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLGeneralLiabilityPage } from 'wni-capability-quoteandbind-gl-react';

const SUB_LINES_PATH = `lobData.generalLiability.coverables.subLines`;

function GLGeneralLiabilityChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    const isContainingNewSubLines = _.get(submissionVM.value, SUB_LINES_PATH, []).some((subLine) => subLine.isChangeAdded);
    
    if(isDisplayAsSubmission) {
        return (
            <GLGeneralLiabilityPage {...props} />
        )
    };
    return (
        <GLGeneralLiabilityPage {...props} 
        readonlyForAdditionalCov
        hideDetailsAndStandardCoverages={!isContainingNewSubLines}
        readOnlyForExclCondUnlessSpecificFields
        editableFieldsForPolicyChange = {['GL7SchedNoticeOfCancellation_Ext']}
        />
    );

}

GLGeneralLiabilityChangePage.propTypes = GLGeneralLiabilityPage.propTypes;
GLGeneralLiabilityChangePage.defaultProps = GLGeneralLiabilityPage.defaultProps;

export default GLGeneralLiabilityChangePage;