import { defineMessages } from 'react-intl';

export default defineMessages({
    documents: {
        id: 'document.component.document-component.Documents',
        defaultMessage: 'Documents'
    },
    uploadDocuments: {
        id: 'document.component.document-component.Upload Documents',
        defaultMessage: 'Upload Documents'
    },
    documentName: {
        id: 'document.component.document-component.File name',
        defaultMessage: 'File name'
    },
    searchDocuments: {
        id: 'document.component.document-component.Search documents',
        defaultMessage: 'Search Documents'
    },
    dateUploaded: {
        id: 'document.component.document-component.Date Modified',
        defaultMessage: 'Date Uploaded'
    },
    documentRemove: {
        id: 'document.component.document-component.Remove',
        defaultMessage: 'Remove'
    },
    dropaFilehere: {
        id: 'document.component.document-component.dropafilehere',
        defaultMessage: 'drag and drop a file here'
    },
    fileisUploaded: {
        id: 'document.component.document-component.fileisuploaded',
        defaultMessage: 'file is uploaded'
    },
    removeDocument: {
        id: 'document.component.document-component.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'document.component.document-component.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove'
    },
    documentRemoveConfirmYes: {
        id: 'document.component.document-component.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'document.component.document-component.No',
        defaultMessage: 'No'
    },
    noDocsAssociated:{
        id: 'document.component.document-component.There are no documents associated with this policy',
        defaultMessage: 'There are no documents associated with this policy'
    }
});
