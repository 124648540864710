import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import { useTranslator } from '@jutro/locale';
import { Grid, useBreakpoint } from '@jutro/layout';
import PropTypes from 'prop-types';
import { CheckboxField } from '@jutro/legacy/components';

const defaultRenderCell = (item, index, { path }) => {
    return item[path];
};
const multiType = 'multi';
const renderDataTableFn = (FieldComponent) => {
    const Components = (props) => {
        const {
            data = [],
            className,
            rowIdPath,
            children = [],
            selectionType = 'none',
            onSelectionChange = _.noop,
            selectedRows 
        } = props;
        const { breakpoint } = useBreakpoint({});
        const translator = useTranslator();

        const [selectionData, updateSelectionData] = useState(selectedRows || []);
        const [selectableData, updateSelectableData] = useState([]);
        const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

        useEffect(() => {
            const newData = _.cloneDeep(data);
            const initNewData = newData.filter((item) => !item.unselectable);
            updateSelectableData(initNewData);
        }, [data]);
        useEffect(() => {
            if(selectedRows) {
                updateSelectionData(selectedRows);
                onSelectionChange(selectedRows);
            }
        }, [onSelectionChange, selectedRows]);

        // ----------- selectionType is 'multi' started -----------
        const initRowId = (item, index) => {
            return rowIdPath ? item[rowIdPath] : index;
        };

        const onCheckboxForSingle = (isChecked, index) => {
            let newselectionData;
            if (isChecked) {
                newselectionData = selectionData.filter((item) => item !== index);
            } else {
                newselectionData = selectionData.concat([index]);
            }
            const selectedDataIds = data.map((item, i) => initRowId(item, i));
            const selectedData = _.intersection(selectedDataIds, newselectionData);
            updateSelectionData(selectedData);
            onSelectionChange(selectedData);
        };

        const onCheckboxForAll = (isChecked) => {
            let newselectionData;
            if(isChecked) {
                newselectionData = [];
            } else {
                newselectionData = selectableData.map((item, i) => initRowId(item, i));
            }
            updateSelectionData(newselectionData);
            onSelectionChange(newselectionData);
        };
    
        const renderSelectCell = (row, index) => {
            let checkboxProps = {};
            let showCheckboxField = true;
            // if row has value, it should be row renderCell, otherwise, it's table renderHeader
            if (row) { // row checkbox
                const isChecked = selectionData.some((item) => item === index);
                checkboxProps = {
                    value: isChecked,
                    onValueChange: () => onCheckboxForSingle(isChecked, index)
                }
                showCheckboxField = !row.unselectable
            } else { // all checkbox
                const allSelected = _.isEmpty(selectionData) ? false : selectableData.every((item, i) => selectionData
                    .some((rowId) => rowId === initRowId(item, i)));

                checkboxProps = {
                    value: allSelected,
                    onValueChange: () => onCheckboxForAll(allSelected),
                    disabled: selectableData.length === 0
                }
            }
            if (!showCheckboxField) {
                return null
            };
            return (
                <CheckboxField
                    id={index ? `checkbox${index}` : 'checkboxAll'}
                    className="d-inline-block"
                    {...checkboxProps}
                />
            );
        };
        
        const checkboxColumn = React.createElement(DisplayColumn, {
            width: 60,
            textAlign: 'left',
            renderHeader: renderSelectCell,
            renderCell: renderSelectCell,
            sortable: false,
            headerClassName: 'without-resizer'
        });
        // ----------- selectionType is 'multi' end -----------

        // if device is phone or phonewide, the UI should display as renderPhoneCell
        const renderPhoneCell = (item, index, property, renderCell) => {
            const { header } = property;
            return (
                <Grid columns={['1fr', '1fr']}>
                    <div className="font-uppercase font-NeutralUltraDark-bold-16">
                        {header && translator(header)}
                    </div>
                    <div className='align-right'>{renderCell(item, index, property)}</div>
                </Grid>
            );
        };

        const renderChildren = ()=> {
            let newChildren = [...children];
            if (selectionType === multiType) { // selectionType is 'multi', add checkbox into children
                newChildren = [checkboxColumn, ...children];
            }

            return newChildren.map((child) => {
                if (!child) {return;}
                const { props: itemChild } = child;
                const { renderCell = defaultRenderCell } = itemChild;
                return {
                    ...child,
                    props: {
                        ...itemChild,
                        renderCell: !isPhone ? renderCell : (item, index, property) => renderPhoneCell( item, index, property, renderCell)
                    },
                };
            });
        };

        const componentProps = {
            ...props,
            selectionType: selectionType === multiType ? 'none' : selectionType,
            children: renderChildren()
        };
        return (
            <div className={`table-wrapper ${isPhone ? 'withPhone' : ''} ${className}`}>
                <FieldComponent {...componentProps} />
            </div>
        );
    };
    Components.propTypes = {
        labelPosition: PropTypes.string,
        className: PropTypes.string,
    };
    Components.defaultProps = {};
    return Components;
};

//  action
const WniDataTable = renderDataTableFn(DataTable)
export default WniDataTable;
