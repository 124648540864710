import React, {useEffect, useContext, useState} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { GLPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-gl-react';
import { CPPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-cp-react';
import { CPPPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-cpp-react';
import { CRPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-cr-react';
import { CUPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-cu-react';
import { IMPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-im-react';
import CA7CommAutoCoverage from './lobs/CA7CommAutoCoverages';
import WCMWorkersCompCoverages from './lobs/WCMWorkersCompCoverages';
import HOPHomeownersCoverages from './lobs/HOPHomeownersCoverages';
import DwellingPropertyCoverages from './lobs/DwellingPropertyCoverages';

const PoliciesSummaryCoverages = (props) => {

    const {
        lobCoverages = {},
        isLobCoveragesLoading,
        accountContacts,
        coverableDisplayNameMap
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const [coverageSubmissionVM, updateCoverageSubmissionVM] = useState();

    const {
        CA7CommAuto,
        WCMWorkersComp,
        HOPHomeowners,
        DwellingProperty,
        GLpolicySummary,
        CPpolicySummary,
        CPPpolicySummary,
        CRpolicySummary,
        CUpolicySummary,
        IMpolicySummary
    } = lobCoverages;

    useEffect(() => {
        const submissionVM = viewModelService.create(
            {
                baseData: {
                    accountContacts_Ext: accountContacts,
                },
                lobData: {
                    homeowners: {
                        offerings: [{
                            coverages: HOPHomeowners
                        }]
                    },
                    dwellingProperty: {
                        offerings: [{
                            coverages: DwellingProperty
                        }]
                    }
                }
            },
            'pc',
            'wni.edge.capabilities.quote.dto.SubmissionWizardDataDTO'
        );
        updateCoverageSubmissionVM(submissionVM);
    }, [DwellingProperty, HOPHomeowners, accountContacts, viewModelService]);

    if (isLobCoveragesLoading || _.isNil(coverageSubmissionVM)) {
        return <Loader />
    }

    return <div>
        {CA7CommAuto ? <CA7CommAutoCoverage  caCoverages={CA7CommAuto} coverableDisplayNameMap={coverableDisplayNameMap}/>: null}
        {GLpolicySummary ? <GLPolicyLineSummaryComponent  policyLineSummary={GLpolicySummary}/>: null}
        {CPpolicySummary ? <CPPolicyLineSummaryComponent  policyLineSummary={CPpolicySummary.commercialProperty} lobData={CPpolicySummary.lobData}/>: null}
        {CPPpolicySummary ? <CPPPolicyLineSummaryComponent  sxsDataDTO={CPPpolicySummary} lobData={CPPpolicySummary.lobData}/>: null}
        {CRpolicySummary ? <CRPolicyLineSummaryComponent policyLineSummary={CRpolicySummary} />: null}
        {CUpolicySummary ? <CUPolicyLineSummaryComponent policyLineSummary={CUpolicySummary} />: null}
        {IMpolicySummary ? <IMPolicyLineSummaryComponent policyLineSummary={IMpolicySummary} />: null}
        {WCMWorkersComp ? <WCMWorkersCompCoverages  wcCoverages={WCMWorkersComp} />: null}
        {HOPHomeowners ? <HOPHomeownersCoverages coverageSubmissionVM={coverageSubmissionVM} />: null}
        {DwellingProperty ? <DwellingPropertyCoverages coverageSubmissionVM={coverageSubmissionVM} />: null}
    </div>
}

export default PoliciesSummaryCoverages