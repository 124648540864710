import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

// import WCCoveargesPageMain from '../../../Coverages/WCCoveragesPage.main';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function WCCoveredEmployeeSummaryComponent(props) {

    const {
        coveredEmployee, // ;
    } = props;

    if (_.isEmpty(coveredEmployee)) {
        return null;
    }

    //
    const {
        effectiveDate,
        expirationDate,
        location,
        classCode,
        classDescription,
        ifAny,
        payRoll,
        basis,
        basisType,
        //
        coverages,
        //
    } = coveredEmployee;


    return (
        <div>
            <CoverageSummaryComponent title="Coverages" clauses={coverages} />
        </div>
    );
    
}

WCCoveredEmployeeSummaryComponent.propTypes = {
    
};

WCCoveredEmployeeSummaryComponent.defaultProps = {
    
};


export default WCCoveredEmployeeSummaryComponent;