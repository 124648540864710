import { defineMessages } from 'react-intl';

export default defineMessages({
    isTWThereExistingPolicy: {
        id: 'wni.questions.isTWThereExistingPolicy_Ext.',
        defaultMessage: 'Does the applicant(s) have an existing {companyName} policy or will a {companyName} policy be issued within the next 60 days?'
    },
    policyinfoNotificaion: {
        id: 'wni.quoteandbind.wal.policy-info.policyinfoNotificaion',
        defaultMessage: 'Our auto product will prefill driver information saving quote time. If you wish to take advantage of this prefill data, please complete your auto quote first. This data will then be shared across quoted lines of business. {requiredNotification}'
    },
    requiredNotification: {
        id: 'wni.quoteandbind.wal.policy-info.requiredNotification',
        defaultMessage: '{asteriskIcon} You may still need to enter additional information for non-auto licensed operators or non-household members.'
    },
    outOfSliceDate: {
        id: 'wni.policychange.wal.frontendErrors.Out of slice date',
        defaultMessage: 'We are unable to update the effective date of an out-of-sequence transaction.  You will need to withdraw the transaction and enter a new policy change effective { effectiveDate }.  Please contact your underwriter if further assistance is needed.'
    },
    effectiveDateValidation: {
        id: 'wni.policychange.wal.frontendValidation.effectiveDate',
        defaultMessage: 'The selected effective date is before the product has become available. Please select an effective date after {productEffectiveDate}'
    },
});
