import _ from 'lodash';

const generateStringOptions = (data = []) => {
    return data.map((item) => {
        return {
            name: item,
            code: item
        }
    })
};

const generateObjectOptions = (data = []) => {
    return data.map((item) => {
        return {
            ...item,
            name: item.displayName,
            code: item.publicID
        }
    })
};

export {
    generateStringOptions,
    generateObjectOptions
}