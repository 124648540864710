import _ from 'lodash';

function getAvailableJobs(activeJobs, availableProducts) {
    const availableProductCodes = availableProducts.map((item) => item.code);

    return activeJobs.filter((job) => _.includes(availableProductCodes, job.productCode))
};


export default {
    getAvailableJobs
}