import { defineMessages } from 'react-intl';

export default defineMessages({
    accountQuotes: {
        id: 'wni.account.quotes.wizard.step.Account Quotes',
        defaultMessage: 'Account Quotes',
    },
    policyHolderPage: {
        id: 'wni.account.quotes.wizard.step.Account Holder',
        defaultMessage: 'Account Holder',
    },
    accountSummaryPage: {
        id: 'wni.account.quotes.wizard.step.Account Summary',
        defaultMessage: 'Account Summary'
    },
    paymentPage: {
        id: 'wni.account.quotes.wizard.step.Payment & Documents',
        defaultMessage: 'Payment & Documents'
    }
});
