import React, {
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {
    CurrencyValue,
    TooltipIcon,
    Button
} from '@jutro/components';
import {
    useWniModal
} from 'wni-components-platform-react';
import {
    WniCheckboxField,
    WniToggle
 } from 'wni-common-base-components';
import { IMTooltipUtil as tooltips } from 'wni-portals-tooltip';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WniClausesUtil } from 'wni-portals-util-js';
import styles from './IMClauseComponent.module.scss';
import clauseComponentMessages from './IMClauseComponent.messages';
import SingleClauseContext from '../../context/IMSingleClauseContext';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';



/**
 * Component hierarchy diagram:
 * BaseClausesComponentVM ->  BaseSingleClauseComponentVM -> BaseClauseComponent
 * @param {object} props
 * @returns {object}
 */
function IMClauseComponent(props) {

    const {
        clauseCode: clausePatternCode,
        isEditable,
        isDisabled,
        labelPosition,
    } = useContext(SingleClauseContext)

    const {
        ClauseComponentType,
        clauseComponentTypeMap,
    } = useContext(CoveragesConfigContext)

    const modalApi = useWniModal();
    const {
        onValueChange,
        path,
        showAmount,
        containerClassName,
        displayName,
        readOnly,
        required,
        value,
        id,
        children,
        checked,
        // labelPosition,
        amount,
        isLimitRequired,
        // children,
        // ====================================================
        hideCheckBoxForRequired,
        getCoverageLabel,
        isOnlyShowTermsAndScheduleItems,
        onOpenCoverageDetailsPopup,
        linkToDetailsReadOnly,
        isEditing,
        showViewOrEditButtonLink,
        className,
    } = props;

    const intl = useContext(IntlContext);
    const translator = useTranslator();

    const renderChildren = value;

    function defaultGetCoverageLabel(labelName, labelAmount) {
        return WniClausesUtil.getLabelNameWithAmount(labelName, labelAmount, intl, showAmount);
    }


    const handleChange = async (newValue) => {
        if (newValue === false) {
            const result = await modalApi.showConfirm({
                title: displayName,
                message: translator(clauseComponentMessages.areYouSure),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(commonMessages.ok)
            });

            if (result === 'cancel' || result === 'close') {
                return;
            }
        }
        if (onValueChange) {
            onValueChange(newValue, path);
        }
    };

    const onDetailsLinkClick = () => {
        onOpenCoverageDetailsPopup(clausePatternCode)
    };

    const ViewOrEditButtonLink = () => {
        if (showViewOrEditButtonLink) {
            return <Button
                className="pv-5 wni-button-link"
                onClick={() => onDetailsLinkClick()}
                disabled={isEditing}
                label={linkToDetailsReadOnly ? 'View Details' : 'View/Edit Details'}
            />
        }
        return null
    }

    const renderCheckboxField = () => {
        const hasChildren = !_.isNil(children);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });

        const coverageLabel = _.isFunction(getCoverageLabel)
            ? getCoverageLabel(displayName, amount)
            : defaultGetCoverageLabel(displayName, amount);
        

        if (hideCheckBoxForRequired && required) {
            return (
                <div id={id} className="formCoveragesList">
                    <h6 className="formCoveragesTitle">
                        {coverageLabel}
                        {tooltips[clausePatternCode] && <TooltipIcon
                            id={`coverage_tooltip_${clausePatternCode}`}
                            text={tooltips[clausePatternCode]}/>
                        }
                        <ViewOrEditButtonLink />
                    </h6>
                    {renderChildren ? children : null}
                </div>
            )
        }

        const fieldWithTooltipProps = {
            visible: !isOnlyShowTermsAndScheduleItems,
            label: coverageLabel,
            required: isLimitRequired,
            // showRequired={isLimitRequired}
            value: value,
            readOnly: readOnly,
            disabled: isDisabled,
            onValueChange: handleChange,
            className: `${checkboxStyles} clauseCoverages clauseCheckbox`,
            controlClassName: checkboxControlStyles,
            path: path,
            checked: checked,
            labelPosition: labelPosition,
            showInlineLabel: true,
            layout: "full-width",
            tooltip: {
                text: tooltips[clausePatternCode]
            },
            secondaryLabel: <ViewOrEditButtonLink />,
        }

        if (clauseComponentTypeMap[clausePatternCode] === ClauseComponentType.Toggle) {
            return (
                <WniToggle
                    id={`${id}Toggle`}
                    {...fieldWithTooltipProps}
                />
            );
        }

        return (
            <WniCheckboxField
                id={`${id}Checkbox`}
                {...fieldWithTooltipProps}
            />
        );
    };

    const renderEditableValue = () => {
        const clauseContainerStyles = `${classNames(styles.clause, containerClassName, className)} quote-coverage-clause`;
        return (
            <div className={clauseContainerStyles}>
                {renderCheckboxField()}
            </div>
        );
    };

    const renderReadOnlyValue = () => {
        return (
            <div className={`${styles.readOnlyGrid} ${className}`}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        { !_.isUndefined(amount) && showAmount ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                    <ViewOrEditButtonLink />
                </span>
            </div>
        );
    };

    // ================================
    return isEditable ? renderEditableValue() : renderReadOnlyValue();
}

/**
 * @memberof gw-components-platform-react.ClauseComponent
 * @prop {Object} propTypes - the props that are passed to this component
 * @prop {string} propTypes.displayName - name of clause to display
 * @prop {bool} propTypes.readOnly - if the clause selection is read only
 * @prop {bool} propTypes.value - is the clause is seleceted
 * @prop {function} propTypes.onValueChange - callback when change is made
 * @prop {string} propTypes.path - path to value in the view modal
 * @prop {bool} propTypes.isLoading - should the clause be loading
 * @prop {string} propTypes.loadingMessage - message to be shown while loading
 * @prop {string} propTypes.containerClassName - clause container class
 * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
 */
IMClauseComponent.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    hideCheckBoxForRequired: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.bool,
    path: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    checked: PropTypes.bool,
    isEditable: PropTypes.bool,
    containerClassName: PropTypes.string,
    labelPosition: PropTypes.string,
    amount: PropTypes.shape({}),
    showAmount: PropTypes.bool,
    isDisabled: PropTypes.bool,
    coverageTermsCount: PropTypes.number,
    expectCoverageTermComponentType: PropTypes.string,
    isLimitRequired: PropTypes.bool.isRequired,
    loadingIconType: PropTypes.string,
    clausePatternCode: PropTypes.string,
    // ===========================
    /**
     * (Optional) Function: (labelName, labelAmount) => String
     * Used to to get coverable label displayed beside
     * the checkbox.
     */
    getCoverageLabel: PropTypes.func,
    isOnlyShowTermsAndScheduleItems: PropTypes.bool,
    showScheduleDetailsInPanelBelow: PropTypes.bool,
    showErrors: PropTypes.bool,
};

IMClauseComponent.defaultProps = {
    readOnly: false,
    required: false,
    hideCheckBoxForRequired: false,
    path: undefined,
    value: undefined,
    isLoading: false,
    loadingMessage: '',
    checked: false,
    children: undefined,
    isEditable: true,
    containerClassName: undefined,
    labelPosition: 'left',
    amount: undefined,
    showAmount: true,
    isDisabled: false,
    coverageTermsCount: 0,
    expectCoverageTermComponentType: 'none',
    loadingIconType: undefined,
    clausePatternCode: undefined,
    getCoverageLabel: undefined,
    isOnlyShowTermsAndScheduleItems: false,
    showScheduleDetailsInPanelBelow: false,
    showErrors: false,
};


export default IMClauseComponent;
