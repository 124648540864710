import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'modal.button.label.account.holder.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'modal.button.label.account.holder.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'modal.button.label.account.holder.Save',
        defaultMessage: 'Save'
    },
    title: {
        id: 'gateway.views.account.holder.Edit Primary Named Insured',
        defaultMessage: 'Edit Account'
    },
    contactDetails: {
        id: 'gateway.views.account.holder.contactDetails',
        defaultMessage: 'Contact Details'
    },
    showBasicDetails: {
        id: 'gateway.views.account.holder.showBasicDetails',
        defaultMessage: 'Show Basic Details'
    },
    primaryAddress: {
        id: 'gateway.views.account.holder.PrimaryAddress',
        defaultMessage: 'Primary Address'
    },
    showAddresses: {
        id: 'gateway.views.account.holder.showAddresses',
        defaultMessage: 'Show Addresses'
    },
    fullName: {
        id: 'gateway.views.account.holder.fullName',
        defaultMessage: 'Full Name'
    },
    firstName: {
        id: 'gateway.views.account.holder.firstName',
        defaultMessage: 'First Name'
    },
    middleName: {
        id: 'gateway.views.account.holder.middleName',
        defaultMessage: 'Middle Name'
    },
    lastName: {
        id: 'gateway.views.account.holder.lastName',
        defaultMessage: 'Last Name'
    },
    suffix: {
        id: 'gateway.views.account.holder.suffix',
        defaultMessage: 'Suffix'
    },
    relationshipToInsured: {
        id: 'gateway.views.account.holder.relationshipToInsured',
        defaultMessage: 'Relationship To Insured'
    },
    addressLine1: {
        id: 'gateway.views.account.holder.addressLine1',
        defaultMessage: 'Address Line 1'
    },
    addressLine2: {
        id: 'gateway.views.account.holder.addressLine2',
        defaultMessage: 'Address Line 2'
    },
    zipCode: {
        id: 'gateway.views.account.holder.zipCode',
        defaultMessage: 'Zip Code'
    },
    city: {
        id: 'gateway.views.account.holder.city',
        defaultMessage: 'City'
    },
    county: {
        id: 'gateway.views.account.holder.county',
        defaultMessage: 'County'
    },
    state: {
        id: 'gateway.views.account.holder.state',
        defaultMessage: 'State'
    },
    maritalStatus: {
        id: 'gateway.views.account.holder.maritalStatus',
        defaultMessage: 'Marital Status'
    },
    phoneType: {
        id: 'gateway.views.account.holder.PhoneType',
        defaultMessage: 'Phone Type'
    },
    primaryPhone: {
        id: 'gateway.views.account.holder.primaryPhone',
        defaultMessage: 'Primary Phone'
    },
    homePhone: {
        id: 'gateway.views.account.holder.homePhone',
        defaultMessage: 'Home Phone'
    },
    mobilePhone: {
        id: 'gateway.views.account.holder.mobilePhone',
        defaultMessage: 'Mobile Phone'
    },
    primaryEmail: {
        id: 'gateway.views.account.holder.primaryEmail',
        defaultMessage: 'Primary Email'
    },
    secondaryEmail: {
        id: 'gateway.views.account.holder.secondaryEmail',
        defaultMessage: 'Secondary Email'
    },
    NoAddressRecommended: {
        id: 'quoteandbind.ho.views.primary address.no address message',
        defaultMessage: 'No matching address is recommended!'
    },
    NoZipCodeRecommended: {
        id: 'quoteandbind.ho.views.primary zipcode.no message',
        defaultMessage: 'No matching Zip Code is recommended!'
    }
});
