import _ from 'lodash';
import config from 'app-config';
import { R1Config } from 'wni-portals-config-js';

/**
 * Is the Vehicle validated according to VIN related rules
 * @param {number} year year of vehicle
 * @param {number} vinLength length of VIN
 * @param {boolean} vehicleFoundByVIN is this vehicle can be found by VIN
 * @returns {boolean} true if invalid, false if valid
 */
function isVinInvalid(year, vinLength, vehicleFoundByVIN) {
    if (vinLength < 17) {
        // Old Car
        return year ? year > 1980 : true;
    }
    if (vinLength === 17) {
        if (year === undefined || year > 1980) {
            // Modern Car
            return !vehicleFoundByVIN;
        }
        return false;
    }
    // Length > 17, Invalid VIN
    return true;
}

const isIdEqual = (a, b) => {
    return a && b && a.toString() === b.toString();
};

const getVehicleDataById = (submissionVM, fixedId) => {
    if (!fixedId) {
        return undefined;
    }
    const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', []);
    return vehicles.find((value) => isIdEqual(value.fixedId, fixedId));
};

const getDriverDataById = (submissionVM, fixedId) => {
    if (!fixedId) {
        return undefined;
    }
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
    return drivers.find((value) => isIdEqual(value.fixedId, fixedId));
};

const isDriverNameDuplicated = (submissionVM, fixedId) => {
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
    const driver = drivers.find((value) => isIdEqual(value.fixedId, fixedId));
    return drivers.filter((value) => value.person.firstName === driver.person.firstName
        && value.person.lastName === driver.person.lastName).length > 1;
};

function isNamedNonOwner(typeCode) {
    return typeCode === 'noVehicleNamedNonOwner_Ext';
}

function isNamedNonOwnerOnVehicle(vehicleVM = {}) {
    return isNamedNonOwner(_.get(vehicleVM.value, 'vehicleType_Ext'));
}

function isNNO(vehicleVMs, isQuoteForNamedNonOwner) {
    if (isQuoteForNamedNonOwner) {
        // NNO policy with no nno vehicle
        if (vehicleVMs.some((vm) => !isNamedNonOwnerOnVehicle(vm))) {
            return true;
        }
        // NNO policy with more than one nno vehicle
        if (vehicleVMs.some((vm) => isNamedNonOwnerOnVehicle(vm)) && vehicleVMs.length > 1) {
            return true;
        }
    } else {
        return false;
    }
    return false;
}

function isSelect(vehicleVMs) {
    if (vehicleVMs.some((vm) => isNamedNonOwnerOnVehicle(vm))) {
        return true;
    }
    return false;
}

function isPPA(typeCode) {
    return typeCode === 'privatepassengerauto_Ext';
}

function isPPAOnVehicle(vehicleVM = {}) {
    return isPPA(_.get(vehicleVM.value, 'vehicleType_Ext'));
}

function getMakeOptions(make, makes) {
    let makeOptions = [];
    makeOptions = _.concat(makeOptions, makes);
    if (!_.includes(makeOptions, make)) {
        makeOptions.push(make);
    }
    makeOptions = _.uniq(makeOptions);
    return makeOptions.map(_.toString).map((option) => ({
        code: option,
        name: option
    }));
}

function getModelOptions(model, models) {
    let modelOptions = [];
    modelOptions = _.concat(modelOptions, models);
    if (!_.includes(modelOptions, model)) {
        modelOptions.push(model);
    }
    modelOptions = _.uniq(modelOptions);
    return modelOptions.map(_.toString).map((modelOption) => ({
        code: modelOption,
        name: modelOption
    }));
}

function getYearOptions() {
    const currentYear = new Date().getUTCFullYear() + 1;
    const { oldestCarYear } = config.personalAutoConfig;

    // _.range is not inclusive. Need to add -1 to get correct years
    const yearRange = _.range(currentYear, oldestCarYear - 1);

    return yearRange.map(_.toString).map((year) => ({
        code: year,
        name: year
    }));
}

const getAvailableBodyTypes = (vehicleVM, bodyTypeCodeRange, translator) => {
    const availableValues = _.get(vehicleVM, 'bodyType_Ext.aspects.availableValues');
    const options = availableValues.map((option) => {
        return {
            code: option.code,
            name: translator({
                id: option.name
            })
        };
    });
    if (bodyTypeCodeRange.length > 0) {
        return options.filter((option) => bodyTypeCodeRange.includes(option.code));
    }
    const selectedValue = options.find((option) => {
        return _.get(vehicleVM, 'value.bodyType_Ext') === option.code;
    });
    return _.isNil(selectedValue) ? [] : [selectedValue];
};

const getAvailableVehicleTypes = (availableValues, isQuoteForNamedNonOwner, translator) => {
    const options = availableValues.map((option) => {
        return {
            code: option.code,
            name: translator({
                id: option.name
            })
        };
    });
    if (isQuoteForNamedNonOwner) {
        return options.filter((option) => isNamedNonOwner(option.code));
    }
    return options.filter((option) => !isNamedNonOwner(option.code));
};

const getVehicleName = (vehicleData) => {
    const {
        // eslint-disable-next-line camelcase
        year, make, model, vin, vehicleType_Ext
    } = vehicleData;
    const vinLast4 = vin ? vin.toString().substring(vin.length - 4, vin.length) : '';
    if (isNamedNonOwner(vehicleType_Ext)) {
        return 'Named Non Owned';
    }
    return `${year} ${make} ${model} ${vinLast4}`;
};

const getDriverName = (driverData, age = undefined) => {
    if (age) {
        return `${driverData.displayName}-${age}`;
    }
    return driverData.displayName;
};

const getDriverNameById = (vd, submissionVM) => {
    if (!vd.driverID) {
        return undefined;
    }
    const driverData = getDriverDataById(submissionVM, vd.driverID);
    const showAge = isDriverNameDuplicated(submissionVM, vd.driverID);
    if (showAge) {
        return getDriverName(driverData, vd.driverAge_Ext);
    }
    return getDriverName(driverData);
};

const getPrimaryDriverName = (vds, submissionVM) => {
    const vd = vds.find((vdData) => vdData.assignment_Ext === 'Primary');
    if (!vd) {
        return undefined;
    }
    return getDriverNameById(vd, submissionVM);
};

const getPrimaryOperatorName = (vds, submissionVM) => {
    const vd = vds.find((vdData) => _.isNil(vdData.assignment_Ext));
    if (!vd) {
        return undefined;
    }
    return getDriverNameById(vd, submissionVM);
};

const getOccasionalDriverNames = (vds, submissionVM, fixedId) => {
    return vds.filter((vd) => vd.assignment_Ext === 'Occasional')
        .map((vd) => getDriverNameById(vd, submissionVM, fixedId));
};

const getStudentAwayDriverNames = (vds, submissionVM, fixedId) => {
    return vds.filter((vd) => vd.assignment_Ext === 'StudentAway')
        .map((vd) => getDriverNameById(vd, submissionVM, fixedId));
};

const getRatedDriverName = (vds, submissionVM) => {
    const fixedId = vds[0].ratedDriverId_Ext;
    if (!fixedId) {
        return undefined;
    }
    const age = vds[0].ratedDriverAge_Ext;
    const driverData = getDriverDataById(submissionVM, fixedId);
    const showAge = isDriverNameDuplicated(submissionVM, fixedId);
    if (showAge) {
        return getDriverName(driverData, age);
    }
    return getDriverName(driverData);
};

const getAssignmentData = (submissionVM, originalData) => {
    const result = [];
    const vehicleMap = _.groupBy(originalData, 'vehicleID');
    Object.keys(vehicleMap).forEach((fixedId) => {
        const rowData = {};
        const vds = vehicleMap[fixedId];
        if (vds && vds.length > 0) {
            const vehicleData = getVehicleDataById(submissionVM, fixedId);
            rowData.vehicleID = fixedId;
            rowData.vehicleName = getVehicleName(vehicleData);
            if (!(vds.length === 1 && !vds[0].driverID)) {
                rowData.primaryDriver = getPrimaryDriverName(vds, submissionVM);
                rowData.studentAwayDrivers = _.join(getStudentAwayDriverNames(vds, submissionVM, fixedId), ',\n');
                // getStudentAwayDriverName(vds, submissionVM);
                rowData.occasionalDrivers = _.join(getOccasionalDriverNames(vds, submissionVM, fixedId), ',\n');
                rowData.ratedDriver = getRatedDriverName(vds, submissionVM);
                rowData.primaryOperator = getPrimaryOperatorName(vds, submissionVM);
            }
            result.push(rowData);
        }
    });
    return result;
};

const getVehicleNameMap = (submissionVM) => {
    const map = {};
    const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', []);
    vehicles.forEach((value) => {
        const vehicleData = vehicles.find((v) => isIdEqual(v.fixedId, value.fixedId));
        map[value.fixedId.toString()] = getVehicleName(vehicleData);
    });
    return map;
};

const getDriverNameMap = (submissionVM) => {
    const map = {};
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
    drivers.forEach((value) => {
        const driverData = drivers.find((d) => isIdEqual(d.fixedId, value.fixedId));
        const showAge = isDriverNameDuplicated(submissionVM, value.fixedId);
        if (showAge) {
            map[value.fixedId.toString()] = getDriverName(driverData, driverData.age_Ext);
        } else {
            map[value.fixedId.toString()] = getDriverName(driverData);
        }
    });
    return map;
};

const getDriverAgeMap = (submissionVM) => {
    const map = {};
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
    drivers.forEach((value) => {
        const driverData = drivers.find((d) => isIdEqual(d.fixedId, value.fixedId));
        map[value.fixedId.toString()] = driverData.age_Ext;
    });
    return map;
};


// POI-7935
// when there are any drivers with UW question on driver screen for company vehicle furnished marked
// as Yes and Vehicle count is less than driver count then display message
const showFurnishedAssignmentGuideline = (submissionVM) => {
    const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', []);
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
    const questionYes = drivers.some((driver) => {
        const answer = _.get(driver, 'driverQuestionSets_Ext.0.answers.DriverIsCompanyVehFurnishedYN_Ext');
        // answer of this question is the string code such as '1stxxxxxxxx'
        return answer != null && answer !== 'false';
    });
    return questionYes && vehicles.length < drivers.length;
};

const showStudentAway = (submissionVM) => {
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
    return drivers.some((driver) => driver.isStudentAwayWithoutCarYN_Ext && driver.age_Ext < 23);
};

const getCurrentVehicleCoverages = (coverageVMs, currentRow) => {
    const { coverages } = _.find(coverageVMs, (cov) => {
        return _.get(cov, 'publicID.value') === _.get(currentRow, 'publicID.value');
    }) || {};
    return coverages;
};

function checkPAAdditionalInterestCodeBelongsToSubtype(subtype, code) {
    return R1Config.VehiclesPageConfig
        .paAdditionalInterestAvailableValueCodes[subtype].includes(code);
}

function checkPARelationshipToInsuredCodeIsVisble(code) {
    return R1Config.VehiclesPageConfig.paRelationshipToInsuredCodes.includes(code);
}

function checkPrimaryNamedRelationshipToInsuredCodes(code) {
    return R1Config.VehiclesPageConfig.primaryNamedRelationshipToInsuredCodes.includes(code);
}

const clearFields = (vehicleVM) => {
    const { value } = vehicleVM;
    _.unset(value, 'year');
    _.unset(value, 'make');
    _.unset(value, 'model');
    _.unset(value, 'vin');
    _.unset(value, 'annualMileage');
    _.unset(value, 'leaseOrRent');
    _.unset(value, 'licenseState');
    _.unset(value, 'costNew');
    _.set(value, 'additionalInterests', []);
    _.unset(value, 'purchaseDate_Ext');
    _.set(value, 'owners_Ext', []);
    _.unset(value, 'otherOwnersAssignedFlag_Ext');
    _.unset(value, 'otherOwners_Ext');
    _.unset(value, 'statedValue_Ext');
    // reset default value for leaseOrRent_Ext
    _.set(value, 'leaseOrRent_Ext', false);
    _.unset(value, 'vehDrivenToWorkSchool_Ext');
    _.unset(value, 'milesToWorkSchoolOneWay_Ext');
    _.unset(value, 'vehUsedInBusOwned_Ext');
    _.unset(value, 'vehicleExplain_Ext');
    _.unset(value, 'howManyHours_Ext');
    _.unset(value, 'whatDelivered_Ext');
    _.unset(value, 'questionOfOther_Ext');
    _.unset(value, 'vehExclusiveUseToFarmOwned_Ext');
    _.unset(value, 'vehDrivenInMexico_Ext');
    _.unset(value, 'motorHomeRented_Ext');
    _.unset(value, 'vehicleRentPercent_Ext');
    _.unset(value, 'vehRecreationalTrailer_Ext');
    _.unset(value, 'milePercentPerYear_Ext');
    _.unset(value, 'customEquipments_Ext');
    _.set(value, 'safetyFeatures_Ext', []);
    _.unset(value, 'makeNotFound_Ext');
    _.unset(value, 'modelNotFound_Ext');
    _.unset(value, 'noVehicleNamedNonOwned_Ext');
    _.unset(value, 'bodyType_Ext');
    _.unset(value, 'symbolAndModifiers_Ext');
    _.unset(value, 'isAutoPrefillData_Ext');
};

const shouldHideAssignmentPage = (vehicleVMList) => {
    if (_.isEmpty(vehicleVMList)) {
        return false;
    }
    return vehicleVMList.every((vehicle) => !vehicle.isDriverAssignmentAvailable_Ext);
    // const namedNonOwnerVehicles = vehicleVMList
    //     .filter((v) => VehicleUtil.isNamedNonOwnerOnVehicle(v));
    // if (_.isEmpty(namedNonOwnerVehicles) && (_.isNil(coverageVMs) || _.isEmpty(coverageVMs.value))) {
    //     return false;
    // }
    // const ppaVehicleIds = vehicleVMList
    //     .filter((v) => VehicleUtil.isPPAOnVehicle(v)).map((v) => v.value.publicID);
    // const vehicleCoverages = coverageVMs.value
    //     .filter((vc) => ppaVehicleIds.includes(vc.publicID));
    // const ppaWithLiability = vehicleCoverages.filter((vc) => !isVehicleCoverageExcluded(vc));
    // return _.isEmpty(ppaWithLiability) && _.isEmpty(namedNonOwnerVehicles);
};

const checkNextSteps = (changeNextSteps, currentStep, initialSteps, submissionVM) => {
    const {
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles: vehicleVMs
                }
            }
        },
    } = submissionVM.value;
    const showAssignmentPage = shouldHideAssignmentPage(vehicleVMs);
    const currentStepIndexForAllSteps = _
        .findIndex(initialSteps, (step) => step.id === currentStep.id);
    const nextSteps = initialSteps
        .slice(currentStepIndexForAllSteps + 1, initialSteps.length);

    if (showAssignmentPage) {
        // changeNextSteps(nextSteps.filter((step) => !step.path.includes('assignment')));
        const nextWizardSteps = nextSteps.filter(
            (step) => !step.path.includes('assignment')
        );
        if (nextWizardSteps.length < nextSteps.length) {
            changeNextSteps(nextWizardSteps);
        }
    }
    // changeNextSteps() will grey out following pages in sideBar. Please do not call it randomly.
    // else {
    //     changeNextSteps(nextSteps);
    // }
};

const handleLoanNumberChange = (value, path, writeValue) => {
    let inputValue = value;
    if (!_.isNil(value)) {
        // seprate number and alphanumeric characters.
        inputValue = value.match(/[A-Za-z0-9]+/g);
        // to string
        if (!_.isNil(inputValue)) {
            inputValue = inputValue.join('');
            // maxinum lenth is 15
            if (inputValue.length > 15) {
                inputValue = inputValue.substring(0, 15);
            }
        }
    }
    writeValue(inputValue, path);
}

export default {
    isVinInvalid,
    isNamedNonOwner,
    isNamedNonOwnerOnVehicle,
    isNNO,
    isSelect,
    isPPA,
    isPPAOnVehicle,
    getMakeOptions,
    getModelOptions,
    getYearOptions,
    getAvailableBodyTypes,
    getAvailableVehicleTypes,
    getAssignmentData,
    getVehicleDataById,
    isIdEqual,
    getVehicleNameMap,
    getDriverNameMap,
    getDriverAgeMap,
    showFurnishedAssignmentGuideline,
    showStudentAway,
    getCurrentVehicleCoverages,
    checkPAAdditionalInterestCodeBelongsToSubtype,
    checkPARelationshipToInsuredCodeIsVisble,
    checkPrimaryNamedRelationshipToInsuredCodes,
    clearFields,
    checkNextSteps,
    handleLoanNumberChange
};
