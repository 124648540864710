
import React from 'react';
import { CURiskAnalysisPage } from 'wni-capability-quoteandbind-cu-react';

function CURiskAnalysisChangePage(props) {

    return (
        <CURiskAnalysisPage {...props} isPolicyChange />
    );

};


export default CURiskAnalysisChangePage;