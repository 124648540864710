import React, {useState, useCallback, useMemo, useEffect, useContext} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { IMContractorsEquipmentService as  ContractorsEquipmentService, IMContractorsEquipmentService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMContractorsEquipmentPage.metadata.json5';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import messages from './IMContractorsEquipmentPage.messages';

// eslint-disable-next-line camelcase
const SCHEDUlLEDEQUIPMENT_ACCORDION_CARD_COMPONENT_MAPS = ['scheduledEquipmentFormMainCovCard', 'scheduledEquipmentFormSupplementalCovCard', 'scheduledEquipmentFormEquipmentScheduleCovCard', 'scheduledEquipmentFormAdditionalInsuredScheduleCovCard']
const BLANKETEDEQUIPMENT_ACCORDION_CARD_COMPONENT_MAPS = ['blanketEquipmentFormMainCovCard', 'blanketEquipmentFormSupplementalCovCard']
const SMALLTOOLSFLOATER__ACCORDION_CARD_COMPONENT_MAPS = ['smallToolsFloaterMainCovCard', 'smallToolsFloaterSupplementalCovCard']
const ContractorsEquipmentPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        markFollowingWizardStepsUnvisited,
    } = props;

    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('ContractorsEquipmentPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [contractorsEquipmentClauses, setContractorsEquipmentClauses] = useState()
    const [contractorsEquipmentLoadingClauses, setContractorsEquipmentLoadingClauses] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await ContractorsEquipmentService.getContractorsEquipmentClausesWithSync(jobID, sessionUUID, authHeader) 
            : await ContractorsEquipmentService.getContractorsEquipmentClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setContractorsEquipmentClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    const calculateLimit = useCallback(async (termCode) => {
        setLoadingMask(true)
        const res = await ContractorsEquipmentService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setContractorsEquipmentClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 
    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAfterUpdateClauses = useCallback((coveragePartClauses) => {
        const additionalCoverages = _.get(coveragePartClauses, 'additionalCoverage', []);
        const smallToolsFloaterPath = 'lobData.inlandMarine.coveragePartSelection.smallToolsFloater';
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isNil(additionalCoverages.find(elt => elt.code_Ext === 'IMToolsEndorsement_Ext'))) {
            _.set(newSubmissionVM, `${smallToolsFloaterPath}.available`, false);
        } else {
            _.set(newSubmissionVM, `${smallToolsFloaterPath}.available`, true);
        }
        updateWizardSnapshot(newSubmissionVM);
    }, [submissionVM, updateWizardSnapshot, viewModelService])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: ContractorsEquipmentService,
            handleAfterUpdateClauses
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, handleAfterUpdateClauses, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setContractorsEquipmentClauses(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await ContractorsEquipmentService.onContractorsEquipmentNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const defaultOpenId = useMemo(() => {
        const isScheduledEquipmentFormVisible = _.get(contractorsEquipmentClauses, 'scheduledEquipmentFormCoverage.visible');
        const isBlanketEquipmentFormCoverage = _.get(contractorsEquipmentClauses, 'blanketEquipmentFormCoverage.visible');
        if (isScheduledEquipmentFormVisible) {
            return 'scheduledEquipmentForm'
        }
        if (!isScheduledEquipmentFormVisible && isBlanketEquipmentFormCoverage) {
            return 'blanketEquipmentForm'
        }
        return 'smallToolsFloater'
    }, [contractorsEquipmentClauses])

     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: contractorsEquipmentClauses,
            setCoveragePartClauses: (newCoveragePart) => {
                setContractorsEquipmentClauses(newCoveragePart)
                updateWizardDataWhileSetPeriodStatus(submissionVM)
                markFollowingWizardStepsUnvisited()
            },
            isEditing,
            setIsEditing,
            isEditable: true,
            showErrors,
            onValidate: onValidate,
            loadingClauses: contractorsEquipmentLoadingClauses,
            setLoadingClauses: setContractorsEquipmentLoadingClauses,
            onClickAddSearchClauses,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus,
            updateCoverageFormClausesService: ContractorsEquipmentService.updateContractorsEquipmentClauses,
            calculateLimit
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: true,
            },
            coveragesAccordion: {
                defaultOpenedId: defaultOpenId
            },
            scheduledEquipmentForm: {
                visible: _.get(contractorsEquipmentClauses, 'scheduledEquipmentFormCoverage.visible', false),
                errorState: !_.isEmpty(_.intersection(invalidFields, SCHEDUlLEDEQUIPMENT_ACCORDION_CARD_COMPONENT_MAPS))
            },
            blanketEquipmentForm: {
                visible: _.get(contractorsEquipmentClauses, 'blanketEquipmentFormCoverage.visible', false),
                errorState: !_.isEmpty(_.intersection(invalidFields, BLANKETEDEQUIPMENT_ACCORDION_CARD_COMPONENT_MAPS))
            },
            smallToolsFloater: {
                visible: _.get(contractorsEquipmentClauses, 'smallToolsFloaterCoverage.visible', false),
                errorState: !_.isEmpty(_.intersection(invalidFields, SMALLTOOLSFLOATER__ACCORDION_CARD_COMPONENT_MAPS))
            },
            scheduledEquipmentFormMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(contractorsEquipmentClauses, 'scheduledEquipmentFormCoverage.mainCoverage'),
                coverageFormPath: 'scheduledEquipmentFormCoverage.mainCoverage'
            },
            scheduledEquipmentFormSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(contractorsEquipmentClauses, 'scheduledEquipmentFormCoverage.supplementalCoverage'),
                coverageFormPath: 'scheduledEquipmentFormCoverage.supplementalCoverage'
            },
            scheduledEquipmentFormEquipmentScheduleCovCard: {
                ...accordionCommonProps,
                scheduleContainer: contractorsEquipmentClauses,
                coveragePartSchedule: _.get(contractorsEquipmentClauses, 'scheduledEquipmentFormCoverage.scheduledEquipment'),
                updateScheduleService: ContractorsEquipmentService.updateContractorsEquipmentSchedule,
                schedulePath: 'scheduledEquipmentFormCoverage.scheduledEquipment',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails),
                updateAdditionalIntestSchedule: IMContractorsEquipmentService.updateContractorsEquipmentAddiInsuredSchedule
            },
            blanketEquipmentFormMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(contractorsEquipmentClauses, 'blanketEquipmentFormCoverage.mainCoverage'),
                coverageFormPath: 'blanketEquipmentFormCoverage.mainCoverage'
            },
            blanketEquipmentFormSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(contractorsEquipmentClauses, 'blanketEquipmentFormCoverage.supplementalCoverage'),
                coverageFormPath: 'blanketEquipmentFormCoverage.supplementalCoverage'
            },
            smallToolsFloaterMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(contractorsEquipmentClauses, 'smallToolsFloaterCoverage.mainCoverage'),
                coverageFormPath: 'smallToolsFloaterCoverage.mainCoverage'
            },
            smallToolsFloaterSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(contractorsEquipmentClauses, 'smallToolsFloaterCoverage.supplementalCoverage'),
                coverageFormPath: 'smallToolsFloaterCoverage.supplementalCoverage'
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'ContractorsEquipment',
                handleAfterUpdateClauses
            },
            exclusionAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'ContractorsEquipment'
            }
        }
    }, [accordionCommonProps, contractorsEquipmentClauses, defaultOpenId, handleAfterUpdateClauses, invalidFields, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            ScheduledEquipmentFormMainCovCard: IMCommonMainCoverage,
            ScheduledEquipmentFormSupplementalCovCard: IMCommonMainCoverage,
            ScheduledEquipmentFormEquipmentScheduleCovCard: IMCommonCovPartSchedule,
            ScheduledEquipmentFormAdditionalInsuredScheduleCovCard: IMCommonCovPartSchedule,
            BlanketEquipmentFormMainCovCard: IMCommonMainCoverage,
            BlanketEquipmentFormSupplementalCovCard: IMCommonMainCoverage,
            SmallToolsFloaterMainCovCard: IMCommonMainCoverage,
            SmallToolsFloaterSupplementalCovCard: IMCommonMainCoverage,
            AdditionalAccordionCard: IMCommonAdditionalCovCard,
            ExclusionAccordionCard: IMExclusionAccordionCard,
        },
    };

    const disableNavigator = isEditing || isLoadingMask || contractorsEquipmentLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {contractorsEquipmentClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default ContractorsEquipmentPage
