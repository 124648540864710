import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsTitle: {
        id: 'wni.quoteandbind.pu.householdinfo.Policy Details Title',
        defaultMessage: 'Policy Details'
    },
    termType: {
        id: 'wni.quoteandbind.pu.householdinfo.Term Type',
        defaultMessage: 'Term Type'
    },
    policyState: {
        id: 'wni.quoteandbind.pu.householdinfo.Policy State',
        defaultMessage: 'Policy State'
    },
    agencyOfRecordTitle: {
        id: "wni.quoteandbind.pu.householdinfo.Agency of Record Title",
        defaultMessage: "Agency of Record"
    },
    organizationTypeDropdown: {
        id: "wni.quoteandbind.pu.householdinfo.Organization Type",
        defaultMessage: "Organization Type"
    },
    policyUnderwritingQuestionsTitle: {
        id: "wni.quoteandbind.pu.householdinfo.Policy Underwriting Questions Title",
        defaultMessage: "Policy Underwriting Questions"
    },
    uWQApplicantExistPolicyToggle: {
        id: "wni.quoteandbind.pu.householdinfo.UWQ ApplicantExistPolicyToggle",
        defaultMessage: "Does the applicant(s) have an existing {companyName} policy or will a {companyName} policy be issued effective within 60 days of the Personal Umbrella policy?",
    },
    uWQSelectedProductTypeMultiSelect: {
        id: "wni.quoteandbind.pu.householdinfo.uwq products",
        defaultMessage: "Products:(Please select all that apply)",
    },
    deleteResidentsTitle:  {
        id: "wni.quoteandbind.pu.householdinfo.deleteResidentsTitle",
        defaultMessage: "Remove Household Residents?"
    },
    deleteResidentsMessage:  {
        id: "wni.quoteandbind.pu.householdinfo.deleteResidentsMessage",
        defaultMessage: "Are you sure you want to remove this Household Resident?"
    },
    addResidentsTitle:  {
        id: "wni.quoteandbind.pu.householdinfo.addResidentsTitle",
        defaultMessage: "Add Household Residents"
    },
    editResidentsTitle:  {
        id: "wni.quoteandbind.pu.householdinfo.editResidentsTitle",
        defaultMessage: "Edit Household Residents"
    },
    effectiveDateValidation: {
        id: 'wni.quoteandbind.pu.householdinfo.golive.effectiveDate',
        defaultMessage: 'The selected effective date is before the product has become available. Please select an effective date after {productEffectiveDate}'
    },
});