
import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import metadata from './BusinessQuestionComponent.metadata.json5';
import styles from '../../HOResidencePage.module.scss';
import messages from '../../HOResidencePage.messages';

const BusinessQuestionComponent = (props) => {
    const {
        model: businessQuestionsVM,
        isReadOnly,
        onValidate,
        showErrors,
        onValueChange,
        isRequiredForIssuance,
        baseState,
        DPOverrideProps,
    } = props;

    const {
        conductRemoteOfficeWork,
        hasBusiConductedOnPremise,
        additionalBusinessType
    } = businessQuestionsVM.value

    const translator = useTranslator();

    // const anyBusinessConductedOptions = () => {
    //     return [
    //         {
    //             code: 'Business',
    //             name: 'Yes – Business'
    //         },
    //         {
    //             code: 'Farming',
    //             name: 'Yes – Farming'
    //         }
    //     ]
    // }

    // const anyBusinessConductedValue = () => {
    //     const res = [];
    //     if (anyFarmingConducted) {
    //         res.push({
    //             code: 'Farming',
    //             name: 'Yes – Farming'
    //         });
    //     }
    //     if (anyBusinessConducted) {
    //         res.push({
    //             code: 'Business',
    //             name: 'Yes – Business'
    //         });
    //     }
    //     return res;
    // }

    const handleAnyBusinessConducted = useCallback((value ,path) => {
        onValueChange(value, path);
        if (value !== 'yesbusiness') {
            onValueChange(null, 'additionalBusinessType');
        }
    }, []);

    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(metadata.componentContent), []);

    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            isRequiredForIssuance
        },
        additionalBusinessPropCov: {
            visible: conductRemoteOfficeWork
        },
        anyBusinessConducted: {
            // availableValues: anyBusinessConductedOptions(),
            // value: anyBusinessConductedValue(),
            onValueChange: handleAnyBusinessConducted
        },
        additionalBusinessType: {
            visible: hasBusiConductedOnPremise === 'yesbusiness'
        },
        existBusiPropStoredOutbuilding: {
            visible: hasBusiConductedOnPremise === 'yesbusiness'
        },
        existCommercialPolicyWithWNI: {
            visible: hasBusiConductedOnPremise === 'yesbusiness',
            label: baseState === 'AK' ? translator(messages.existCommercialPolicyWithUMI) : translator(messages.existCommercialPolicyWithWNI)
        },
        additionalBusinessTypeExplain: {
            visible: additionalBusinessType === 'other'
        }
    };
    const overrides = _.mergeWith(overrideProps, DPOverrideProps, overridesForIssuance, (obj, src) => {
        return {...obj, ...src}
    });
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={businessQuestionsVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

BusinessQuestionComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    DPOverrideProps: PropTypes.shape({}),
};

BusinessQuestionComponent.defaultProps = {
    model: {},
    showErrors: true,
    isReadOnly: false,
    onValidate: _.noop,
    DPOverrideProps: {}
};

export default BusinessQuestionComponent;
