import React, { useEffect, useState, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    WniDocumentRetrievalService,
    WniMultiDocumentRetrievalService,
    WniSubmissionService,
} from 'wni-capability-gateway';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    AttachmentUtil,
    DocumentsUtil,
    EmailUtil,
    ServiceErrorUtil,
    SummaryUtil,
    WniProductsUtil,
} from 'wni-portals-util-js';
import {
    BaseDocumentCheckedComponentMessages,
    BaseDocumentSendViaEmailPopup,
} from 'wni-capability-common-react';
import { useBusinessData } from 'wni-portals-util-react';
import { useWniModal } from 'wni-components-platform-react';
import DocumentsComponent from '../../components/DocumentsComponent/DocumentsComponent';
import DocumentListComponent from '../../components/DocumentListComponent/DocumentListComponent';
import metadata from './Documents.metadata.json5';
import messages from './Documents.messages';

/** 
 * BR.PL.0254
 */ 
const BR_PL_0254_ERROR_MESSAGE = 'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.';
const BR_PL_0254_ERROR_CODE = -32603;

function QuoteDetailsDocuments(props) {
    const modalApi = useWniModal();
    const {
        uploadSuccess = false,
        fromAccountLanding: {
            quoteDetailsData: {
                jobNumber,
                getQuoteSummaryCount,
                loadQuoteSummary,
                updateCountInfoDriectly
            },
        },
        history
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader, authUserData } = useAuthentication();

    // const jobType = _.get(loadQuoteSummary, 'type');
    // const createUserType = _.get(loadQuoteSummary, 'createUser_Ext.userType');
    // const currentUserType = _.get(authUserData, 'userType');
    const accountNumber = _.get(loadQuoteSummary, 'policy.account.accountNumber');
    const accountName = _.get(loadQuoteSummary, 'policy.account.accountHolder.displayName');
    // const lockedDueToCreatedbyUW = SummaryUtil.isLockedDueToCreatedbyUW(jobType, createUserType, currentUserType);

    const { enableCommercialLine } = useBusinessData();
    const {
        domainCompany: {code: domainCode },
        loadingMask: { setLoadingMask }
    
    } = useDependencies(['domainCompany', 'loadingMask']);

    const [initDocuments, updateInitDocuments] = useState([]);
    const [initAttachments, updateInitAttachments] = useState([]);

    const productCode = _.get(loadQuoteSummary, 'productCode');
    const isCL = WniProductsUtil.isCLProduct(productCode);

    const successCallback = useCallback(() => {
        return DocumentsUtil.defaultSuccessCallback(setLoadingMask);
    }, [setLoadingMask]);

    const errorCallback = useCallback(() => {
        return DocumentsUtil.defaultErrorCallback(
            modalApi,
            setLoadingMask,
            ServiceErrorUtil
        );
    }, [modalApi, setLoadingMask]);

    const printAllErrorCallBack = useCallback(
        (exception) => {
            return DocumentsUtil.defaultPrintAllErrorCallBack(
                exception,
                modalApi,
                setLoadingMask,
                ServiceErrorUtil
            );
        },
        [modalApi, setLoadingMask]
    );

    const handlePrintAllFn = useCallback(
        async (docs) => {
            await DocumentsUtil.handlePrintAllFn(
                docs,
                setLoadingMask,
                authHeader,
                history,
                WniDocumentRetrievalService,
                successCallback,
                errorCallback,
                WniMultiDocumentRetrievalService,
                printAllErrorCallBack
            );
        },
        [
            authHeader,
            errorCallback,
            history,
            printAllErrorCallBack,
            setLoadingMask,
            successCallback,
        ]
    );

    const handleError = useCallback(
        (title, message) => {
            return modalApi
                .showAlert({
                    title: title,
                    message: message,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(() => {
                    _.noop();
                });
        },
        [modalApi]
    );

    const handleWarning = useCallback(
        (title, message) => {
            return modalApi
                .showAlert({
                    title: title,
                    message: message,
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(() => {
                    _.noop();
                });
        },
        [modalApi]
    );

    const retrieveInitSubmissionDocAndAttachments = useCallback(async () => {
        try {
            const response =
                await WniSubmissionService.getDocsAndAttachsForJob(
                    [jobNumber, isCL],
                    authHeader
                );
            const documents = DocumentsUtil.updateDocumentUnselectable(response);
            updateInitDocuments(documents);
        } catch (e) {
            handleError(messages.modalError, messages.errorLoadingDocument);
        }
    }, [authHeader, handleError, isCL, jobNumber]);

    const getAttachments = useCallback(async () => {
        try {
            const attachments =
                await WniSubmissionService.getPortalAttachmentsForSubmission(
                    [jobNumber, isCL],
                    authHeader
                );
            updateInitAttachments(attachments);
            getQuoteSummaryCount();
        } catch (e) {
            handleError(messages.modalError, messages.errorLoadingDocument);
        }
    }, [authHeader, handleError, isCL, jobNumber, getQuoteSummaryCount]);

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID =
                await GatewayDocumentService.generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }, [authHeader, handleError]);

    useEffect(() => {
        async function retrieveInitDatas() {
            try {
                setLoadingMask(true);
                if (enableCommercialLine) {
                    await retrieveInitSubmissionDocAndAttachments();
                } else {
                    // await getDocuments();
                    await getAttachments();
                }
            } finally {
                setLoadingMask(false);
            }
        }
        retrieveInitDatas();
    }, []);

    // useEffect(() => {
    //     updateCountInfoDriectly(
    //         'Documents',
    //         _.get(initDocuments, 'length')
    //     );
    // }, [initDocuments]);

    const handleSendViaEmailFn = useCallback((documents) => {
        const validDocs = DocumentsUtil.filterValidDocuments(documents);
        const tempSubmission = _.cloneDeep(loadQuoteSummary);
        _.set(tempSubmission, 'useJobNumber', true);
        const componentProps = {
            isOpen: true,
            documents: validDocs,
            submission: tempSubmission,
            accountNumber,
            accountName
        };
        return EmailUtil.showSendViaEmailPopup(
            modalApi,
            componentProps,
            BaseDocumentSendViaEmailPopup,
            BaseDocumentCheckedComponentMessages
        );
    }, [loadQuoteSummary, modalApi]);

    const uploadDocument = useCallback(
        async (file, vm) => {
            const documentMetaDataTemplate = {
                docUID: '001',
                documentType: _.get(vm, 'value.documentType'),
                description: _.get(vm, 'value.description'),
                securityType: 'unrestricted',
                status: 'approved',
                jobNumber: jobNumber,
                name: file.name,
                mimeType: file.type,
                sessionID: await claimsDocUploadToken(),
            };
            if (isCL) {
                // // Agency and Internal
                // _.set(documentMetaDataTemplate, 'securityType', 'agency_Ext');
                // Final
                _.set(documentMetaDataTemplate, 'status', 'final');
            }

            try{
                setLoadingMask(true);
                const res = await await GatewayDocumentService.uploadDocument(file, documentMetaDataTemplate, authHeader);
                setLoadingMask(false);
                if (!_.isEmpty(res)) {
                    return isCL ? retrieveInitSubmissionDocAndAttachments() : getAttachments()
                }
            }catch(error){
                setLoadingMask(false);
                if(error && _.get(error, 'baseError.message') ===  BR_PL_0254_ERROR_MESSAGE && _.get(error, 'baseError.code') === BR_PL_0254_ERROR_CODE) {
                    const message = messages[`uploadFailedMessage${domainCode}`] || messages.uploadFailedMessage;
                    return handleWarning(messages.warningUploadTitle, message);
                }
                return handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
            }
        },
        [jobNumber, claimsDocUploadToken, isCL, authHeader, setLoadingMask, retrieveInitSubmissionDocAndAttachments, getAttachments, domainCode, handleWarning, handleError]
    );

    const deleteDocument = useCallback(
        async (publicID) => {
            try {
                const isDeleteItem =
                    await GatewayDocumentService.removeDocument(
                        publicID,
                        authHeader
                    );
                if (isDeleteItem) {
                    if (isCL) {
                        await retrieveInitSubmissionDocAndAttachments();
                    } else {
                        await getAttachments();
                    }
                }
            } catch (documentDeletionError) {
                handleError(
                    messages.documentDeletionFailed,
                    messages.documentCannotDeleted
                );
            }
        },
        [authHeader, getAttachments, handleError, isCL, retrieveInitSubmissionDocAndAttachments]
    );

    const overrideProps = {
        '@all': {},
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        quoteDocumentsForCL: {
            visible: enableCommercialLine,
            initQuoteDocuments: initDocuments,
            showQuoteDetailsDocumentsCL: true,
            onDemandDocumentDownloadFn: async (item) => {
                await DocumentsUtil.onDemandDocumentDownloadFn(
                    item,
                    authHeader,
                    history,
                    modalApi,
                    setLoadingMask,
                    ServiceErrorUtil,
                    WniDocumentRetrievalService
                );
            },
            downloadPCDocument: (publicID, sessionID) => {
                DocumentsUtil.defaultDownloadDocument(
                    publicID,
                    sessionID,
                    setLoadingMask,
                    history,
                    authHeader
                );
            },
            handlePCPrintAllFn: handlePrintAllFn,
            handlePCSendViaEmailFn: handleSendViaEmailFn,
            listDocHeader: translator(messages.quoteDocumentsTitleForCL),
            overrideTitleStyle: true,
            uploadDocument: uploadDocument,
            productCode: productCode || _.get(loadQuoteSummary, 'policy.product.productCode'),
            getDateModifiedFn: isCL ? AttachmentUtil.getDateModifiedFn : _.noop,
            documentNameColumnPath: 'simpleName_Ext',
            pageSizeConfig: { pageSize: 5 },
            pageSizeOptions: [5, 10, 20]
       },
        quoteDetailsDocuments: {
            visible: !enableCommercialLine,
            initialDocumentsData: initAttachments,
            uploadDocument: uploadDocument,
            deleteDocument: deleteDocument,
            downloadDocument: (publicID, sessionID) => {
                DocumentsUtil.defaultDownloadDocument(
                    publicID,
                    sessionID,
                    setLoadingMask,
                    history,
                    authHeader
                );
            },
            noDataMessage: translator(messages.noQuoteDocuments),
            showUploadModal: !uploadSuccess,
            viewModelService,
            authHeader,
            breakpoint,
            productCode: productCode || _.get(loadQuoteSummary, 'policy.product.productCode'),
            accountInfo: loadQuoteSummary.policy.account,
            showFilterBar: !isCL,
            showActionColumn: !isCL,
            getRelatedFieldFn: isCL
                ? AttachmentUtil.getRelatedFieldFn
                : _.noop,
            getDateModifiedFn: isCL ? AttachmentUtil.getDateModifiedFn : _.noop,
            printAllActionContent: translator(
                messages.printSelectedAttachments
            ),
            handlePrintAllFn: handlePrintAllFn,
            handleSendViaEmailFn: handleSendViaEmailFn,
            overrideTitleStyle: isCL
        },
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            documentscomponent: DocumentsComponent,
            documentListComponent: DocumentListComponent,
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(QuoteDetailsDocuments);
