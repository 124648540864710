import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _, { remove } from 'lodash';
import { ModalNext, ModalBody, Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ConfigUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';

import PUAnimalFormComponent from '../PUAnimalForm/PUAnimalForm';
import messages from '../../PUUnderlyingPage.messages';
import metadata from './PUAnimalsPopup.metadata.json5';
import AnimalsUtil from '../../util/AnimalsUtil';

import { Button } from '@jutro/legacy/components';

function PUAnimalsPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        animalsVM,
        isReadOnly,
        viewModelService,
        translator,
        onValueChange,
    } = props;

    const [selection, updateSelection] = useState([]);
    const [currentAnimalVM, updateCurrentAnimalVM] = useState();
    const [loadingAnimalsTable, updateLoadingAnimalsTable] = useState(false);
    const [animalsStateVM, updateAnimalsStateVM] = useState(animalsVM);
    const [originAnimalVM, updateOriginAnimalVM] = useState(currentAnimalVM);

    useEffect(() => {
        const newAnimalsStateVM = viewModelService.clone(animalsStateVM);
        const animals = _.get(newAnimalsStateVM, 'animals.value') || [];
        _.each(animals, (animal) => {
            _.set(animal, 'rowIdPath', _.get(animal, 'publicID') || ConfigUtil.getUuid());
        });
        updateAnimalsStateVM(newAnimalsStateVM)
    }, []);

    const addAnimals = () => {
        const newVM = viewModelService.create(
            {},
            'pc',
            'wni.edge.capabilities.quote.lob.personalumbrella.dto.PUAnimalDTO'
        );
        updateCurrentAnimalVM(newVM);
        updateOriginAnimalVM(viewModelService.clone(newVM));

        const newAnimalsVM = viewModelService.clone(animalsStateVM);
        const exsitAnimals = _.get(animalsStateVM, 'value.animals') || [];
        const animals = [...exsitAnimals, newVM.value];
        _.set(newAnimalsVM, 'animals', animals);
        updateAnimalsStateVM(newAnimalsVM);
    };

    const removeSelectedAnimals = async() => {
        const animals = _.get(animalsStateVM, 'value.animals') || [];
        const remianAnimals = _.filter(animals, (animal, index) => {
            return !_.includes(selection, index);
        });
        const animalsPath = 'property.puDwellingAnimals';
        onValueChange(animalsStateVM.value, animalsPath);
        _.set(animalsStateVM, 'animals', remianAnimals);
        updateAnimalsStateVM(animalsStateVM);
        updateCurrentAnimalVM(null);
        updateSelection([]);
    };

    const viewOrEditAnimal = (animal) => {
        const dwellingAnimalsVM = _.get(animalsStateVM, 'animals.children') || [];
        _.each(dwellingAnimalsVM, (animalVM) => {
            if (animalVM.value.rowIdPath === animal.rowIdPath) {
                updateOriginAnimalVM(viewModelService.clone(animalVM));
                updateCurrentAnimalVM(animalVM);
            }
        });
    };

    const renderViewEditDom = () => {
        return (
            <div className="activeRow btn-link">
                {isReadOnly ? translator(messages.viewLabel) : translator(messages.viewAndEditLabel)}
            </div>
        );
    };

    const isAddAnimalDisabled = useCallback(() => {
        const animals = _.get(animalsStateVM, 'value.animals');
        const newlyAnimal = _.filter(animals, (animal) => {
            return !animal.rowIdPath
        })
        if (_.isEmpty(newlyAnimal)) {
            return false;
        }
        return true;
    }, [animalsStateVM]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                showOptional: false,
                labelPosition: 'left',
                showRequired: true
            },
            animalsTable: {
                data: _.get(animalsStateVM, 'value.animals') || [],
                onSelectionChange: (rows) => updateSelection(rows),
            },
            type: {
                cell: (item) => AnimalsUtil.onTypeCell(item, translator)
            },
            breed: {
                cell: (item) => AnimalsUtil.onBreedTypeCell(item, translator)
            },
            animalBiteHistory: {
                cell: (item) => AnimalsUtil.onBiteHistoryCell(item)
            },
            describe: {
                cell: (item) => AnimalsUtil.onDescribeCell(item)
            },
            excluded: {
                cell: (item) => AnimalsUtil.onExcludedCell(item)
            },
            addAnimal: {
                onClick: addAnimals,
                visible: !isReadOnly,
                disabled: isAddAnimalDisabled()
            },
            removeAnimals: {
                onClick: removeSelectedAnimals,
                visible: !isReadOnly,
                disabled: _.isEmpty(selection)
            },
            viewOrEdit: {
                onClick: viewOrEditAnimal,
                label: renderViewEditDom(),
            },
            animalForm: {
                model: animalsStateVM,
                currentAnimalVM,
                updateCurrentAnimalVM,
                updateAnimalsVM: updateAnimalsStateVM,
                originAnimalVM,
                isReadOnly,
                visible: !_.isEmpty(currentAnimalVM),
                // editAnimal,
                viewModelService,
                onValueChange,
                // underlyingIndex
            }
        }
    }, [currentAnimalVM, isReadOnly, selection, translator, viewModelService, animalsStateVM]);

    const overrideProps = generateOverrides();

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            animalsStateVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            AnimalFormComponent: PUAnimalFormComponent
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <Button className="modal-close" icon="gw-close" onClick={onReject} />
            <ModalBody id="animalDatailsPage">
                { loadingAnimalsTable ? <Loader loaded={!loadingAnimalsTable} /> : <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={animalsStateVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    // showErrors={showErrors}
                />
                }
            </ModalBody>
        </ModalNext>
    );
}

PUAnimalsPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    animalsVM: PropTypes.shape({}),
    isReadOnly: PropTypes.bool
};
PUAnimalsPopup.defaultProps = {
    searchValue: '',
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    animalsVM: null,
    isReadOnly: false
};

export default PUAnimalsPopup;

