import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { SimpleIncidentPopupComponent, ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import {
    WALMVRService,
    WALCLUEService,
    WALMultiQuoteService,
} from "wni-capability-quoteandbind-wal";
import { 
    QuoteUtil,
    ValidationIssueUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

//
import WizardPage from '../../templates/WALWizardPage';
import walStaticMessage from './WALIncidentsAndReportsPage.static';
import styles from './WALIncidentsAndReportsPage.module.scss';
import metadata from './WALIncidentsAndReportsPage.metadata.json5';
import messages from './WALIncidentsAndReportsPage.messages';
import LossUtil from './WALIncidentsAndReportsUtil';

import { IconButton, CurrencyField } from '@jutro/legacy/components';

function WALIncidentsAndReportsPage(props) {
    const modalApi = useWniModal();
    const {
        currentStep: { id: currentPageId },
        wizardData: submissionVM,
        updateWizardPageData,
        updateWizardData,
        //
        disableAddButton,
        productName,
        currentStepPageId,
        wtCLUEService,
        wtMVRService,
        wtStaticMessage,
        newlyAddedOperators,
        hideOrDisableIcons,
        filteredManualCLUE,
        filteredManualMVR,
        showPolicyClaims
    } = props;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const ViewModelService = useContext(ViewModelServiceContext);
    const setWizardDataToDraft = (quoteDataDTOVM) => {
        _.set(quoteDataDTOVM, 'baseData.periodStatus', 'Draft');
        _.set(quoteDataDTOVM, 'baseData.displayStatus_Ext', 'Draft');
        // markFollowingWizardStepsUnvisited();
    };
    const {
        authHeader
    } = useAuthentication();

    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const viewModelService = useContext(ViewModelServiceContext);
    // clue
    const [manuallyClue, updateManuallyClue] = useState([]);
    const [vendorClueReports, updateVendorClueReports] = useState([]);
    // mvr
    const [manuallyMVR, updateManuallyMVR] = useState([]);
    const [vendorMVR, updateVendorMVR] = useState([]);
    // policy claims    
    const [vendorPolicyClaims, updateVendorPolicyClaims] = useState([]);

    const {
        quoteFlow_Ext: quoteFlow,
    } = submissionVM.value.baseData;
    const [accordionStates, updateAccordionStates] = useState([]);
    // filter duplicate manual clue/mvr for policy change, NB show all manual records
    const isFilteredManualClueAvaliable = _.isArray(filteredManualCLUE);
    const isFilteredManualMVRAvaliable = _.isArray(filteredManualMVR);
    const manualClueVisiblity = isFilteredManualClueAvaliable ? filteredManualCLUE.length > 0 : manuallyClue.length > 0;
    const manualMVRVisiblity = isFilteredManualMVRAvaliable ? filteredManualMVR.length > 0 : manuallyMVR.length > 0;
    useEffect(()=>{
        if (quoteFlow === 'draft') {
            updateAccordionStates(["userEnteredInfo"])
        }
    },[]);
    useEffect(() => {
        const {
            lobData: {
                [productName]: {
                    incidentsAndReports_Ext: {
                        clueenter = [],
                        cluereport = [],
                        mvrenter = [],
                        mvrreport = [],
                        claimReport = [],
                    },
                },
            },
        } = submissionVM.value;
        const sortedMVRReport = LossUtil.sortReports(mvrreport);
        updateManuallyClue(clueenter);
        updateVendorClueReports(cluereport);
        updateManuallyMVR(mvrenter);
        updateVendorMVR(sortedMVRReport);
        updateVendorPolicyClaims(claimReport);
    }, [productName]) ;

    const showModalFn = async (modalVM, modelProps) => {
        const isCopiedLoss = modalVM.value.source === "auto" || modalVM.value.source === "home";
        let drivers = _.get(submissionVM, `lobData.${productName}.coverables.operators.value`).map((driver) => {
            return {
                code: driver.publicID,
                name: driver.displayName,
            };
        });
        const specifiedDrivers = _.cloneDeep(drivers);
        specifiedDrivers.forEach((driver) => {
            driver.code = driver.name
        });
        let policyDrivers;
        if (LossUtil.ViewEditVendorClueTitle.includes(modelProps.title)) {
            policyDrivers = LossUtil.addRegularDrivers(specifiedDrivers);
        }
        if (LossUtil.AddViewEditManualClueTitle.includes(modelProps.title)) {
            drivers = LossUtil.addRegularDrivers(drivers);
        }
        // On Policy change - User will see an Add Button but within the popup user could only add manual incidents for the newly added driver in this policy change
        const newOperatorForChangeAvaliable = newlyAddedOperators.length > 0;
        let newOperators = [];
        if (modalVM.value.basedOnFlag || isCopiedLoss) {
            if (LossUtil.AddViewEditManualClueTitle.includes(modelProps.title)) {
                newOperators = drivers;
            }
        } else {
            newOperators = newlyAddedOperators.map((operator) => {
                return {
                    code: operator.publicID,
                    name: operator.displayName,
                };
            })
        }
        let newPolicyOperators = []; 
        if (modalVM.value.basedOnFlag || isCopiedLoss) {
            if (LossUtil.ViewEditVendorClueTitle.includes(modelProps.title)) {
                newPolicyOperators = policyDrivers;
            }
        } else {
            newPolicyOperators = _.cloneDeep(newOperators);
            newPolicyOperators.forEach((driver) => {
                driver.code = driver.name
            });
        }
        const isShowCategoryValidationMessage = productName === 'watercraft' && !isCopiedLoss;
        const componentProps = {
            title: modelProps.title,
            actionType: modelProps.type,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            authHeader: authHeader,
            actionBtnLabel: translator(messages.ModalOk),
            cancelBtnLabel: translator(messages.ModalCancel),
            modalVM,
            viewModelService: viewModelService,
            modelProps: modelProps,
            drivers: newOperatorForChangeAvaliable ? newOperators : drivers,
            policyDrivers: newOperatorForChangeAvaliable ? newPolicyOperators : policyDrivers,
            displayStatus: submissionVM.value.baseData.displayStatus_Ext,
            isShowCategoryValidationMessage,
        };

        return modalApi.showModal(
            <SimpleIncidentPopupComponent {...componentProps} />
        );
    };
    const handleUserClue = () => {
        return {
            ...wtStaticMessage.userClue,
            dtoService: {
                saveService: wtCLUEService.saveClue,
                updateService: wtCLUEService.updateClue,
                deleteService: wtCLUEService.deleteClue,
                updateTableData: updateManuallyClue,
            },
        };
    };

    const handleVendorClue = () => {
        return {
            ...wtStaticMessage.vendorClue,
            dtoService: {
                updateService: wtCLUEService.updateClueReport,
                updateTableData: updateVendorClueReports,
            },
        };
    };
    const handleUserMVR = () => {
        return {
            ...wtStaticMessage.userMvr,
            dtoService: {
                saveService: wtMVRService.saveMvr,
                updateService: wtMVRService.updateMvr,
                deleteService: wtMVRService.deleteMvr,
                updateTableData: updateManuallyMVR,
            },
        };
    };

    const handleVendorMVR = () => {
        return {
            ...wtStaticMessage.vendorMvr,
            dtoService: {
                updateService: wtMVRService.updateMVRReport,
                updateTableData: updateVendorMVR,
            },
        };
    };

    const handleAddEditFn = async(type, handleType, data, dtoService) => {
        const { saveService, updateService, updateTableData } = dtoService;
        let submitService = updateService;
        if (type === 'View') {
            return false;
        }
        if (type === 'Add') {
            submitService = saveService;
        }
        if (type === 'Edit') {
            submitService = updateService;
        }
        if (handleType === 'userClue') {
            setLoadingMask(true);
            try {
                const result =  await submitService(
                    submissionVM.jobID.value,
                    submissionVM.sessionUUID.value,
                    data,
                    authHeader
                );
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(
                    submissionVM,
                    `lobData.${productName}.incidentsAndReports_Ext.clueenter`,
                    result
                );
                updateWizardData(submissionVM);
            } catch (error) {
                _.noop();
            }
            setLoadingMask(false);
        }
        if (handleType === 'vendorClue') {
            setLoadingMask(true);
            try {
                const result = await submitService(
                    submissionVM.jobID.value,
                    submissionVM.sessionUUID.value,
                    data,
                    authHeader
                );
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(
                    submissionVM,
                    `lobData.${productName}.incidentsAndReports_Ext.cluereport`,
                    result
                );
                updateWizardData(submissionVM);
            } catch (error) {
                _.noop();
            }
            setLoadingMask(false);
        }
        if (handleType === 'userMVR') {
            setLoadingMask(true);
            try {
                const result =  await submitService(
                    submissionVM.jobID.value,
                    submissionVM.sessionUUID.value,
                    data,
                    authHeader
                );
                setWizardDataToDraft(submissionVM);
                updateTableData(result);
                _.set(
                    submissionVM,
                    `lobData.${productName}.incidentsAndReports_Ext.mvrenter`,
                    result
                );
                updateWizardData(submissionVM);
            } catch (error) {
                _.noop();
            }
            setLoadingMask(false);
        }
        if (handleType === 'vendorMVR') {
            setLoadingMask(true);
            try {
                const result = await submitService(
                    submissionVM.jobID.value,
                    submissionVM.sessionUUID.value,
                    data,
                    authHeader
                );
                const sortedMVRReport = LossUtil.sortReports(result);
                setWizardDataToDraft(submissionVM);
                updateTableData(sortedMVRReport);
                _.set(
                    submissionVM,
                    `lobData.${productName}.incidentsAndReports_Ext.mvrreport`,
                    sortedMVRReport
                );
                updateWizardData(submissionVM);
            } catch (error) {
                _.noop();
            }
            setLoadingMask(false);
        }
        return false;
    };

    const handleRowItem = (e, rowProps, type, initialModal = {}) => {
        // e.preventDefault();
        const { item, handleType } = rowProps;

        let modalProps;
        switch (handleType) {
            case 'userClue':
                modalProps = handleUserClue();
                break;
            case 'vendorClue':
                modalProps = handleVendorClue();
                break;
            case 'userMVR':
                modalProps = handleUserMVR();
                break;
            case 'vendorMVR':
                modalProps = handleVendorMVR();
                break;
            default:
                modalProps = {};
                break;
        }
        const { labelMap, title, dtoPath, dtoService } = modalProps;
        let modalVM = {};
        if (item) {
            modalVM = viewModelService.create(item, 'pc', dtoPath);
        } else {
            modalVM = viewModelService.create(initialModal, 'pc', dtoPath);
        }
        const modelInfo = {
            labelMap: labelMap,
            type: type,
            title: `${type} ${title}`,
        };
        showModalFn(modalVM, modelInfo)
            .then((data) => {
                if (!_.isEqual(data, submissionVM.value)) {
                    handleAddEditFn(type, handleType, data, dtoService);
                } 
                else {_.noop()}
            })
            .catch(() => {
                _.noop();
            });
    };

    const handleDeleteItem = (e, rowProps, handleType) => {
        e.preventDefault();
        const { item } = rowProps;
        modalApi.showConfirm({
            title: messages.ModaldeleteMsg,
            message: '',
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.ModalOk,
            cancelButtonText: messages.ModalCancel,
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            if (handleType === 'userClue') {
                try {
                    const result = await wtCLUEService.deleteClue(
                        submissionVM.jobID.value,
                        submissionVM.sessionUUID.value,
                        item.publicId,
                        authHeader
                    )
                    setWizardDataToDraft(submissionVM);
                    updateManuallyClue(result);
                    _.set(
                        submissionVM,
                        `lobData.${productName}.incidentsAndReports_Ext.clueenter`,
                        result
                    );
                    updateWizardData(submissionVM);
                } catch (error) {
                    _.noop();
                }
                
            }
            if (handleType === 'userMVR') {
                try {
                    const result = await wtMVRService.deleteMvr(
                        submissionVM.jobID.value,
                        submissionVM.sessionUUID.value,
                        item.driverId,
                        item.publicId,
                        authHeader
                    )
                    setWizardDataToDraft(submissionVM);
                    updateManuallyMVR(result);
                    _.set(
                        submissionVM,
                        `lobData.${productName}.incidentsAndReports_Ext.mvrenter`,
                        result
                    );
                    updateWizardData(submissionVM);
                } catch (error) {
                    _.noop()
                }
            }
            return true;           
        }, _.noop);
    };

    const getActioncolumnFn = (item, index, handleType, readOnly) => {
        const rowProps = {
            item: item,
            index: index,
            handleType,
        };
        if (handleType === 'vendorMVR') {
            if (item.earsFlag) {
                return (
                    <>
                        <IconButton
                            id={`view${handleType}${index}`}
                            icon="gw-visibility"
                            iconColor="dark"
                            size="medium"
                            onClick={(e) => handleRowItem(e, rowProps, 'View')}
                        />
                        {!readOnly && (
                            <IconButton
                                id={`delete${handleType}${index}`}
                                icon="gw-delete"
                                iconColor="dark"
                                size="medium"
                                onClick={(e) => handleDeleteItem(e, rowProps, handleType)
                                }
                            />
                        )}
                    </>
                );
            }
        };
        let hideEditDeleteIcon = false;
        let disableReportsEditIcon = false;
        if (_.isFunction(hideOrDisableIcons)){
            const iconFlag = hideOrDisableIcons(item, handleType);
            hideEditDeleteIcon = iconFlag.hideEditDeleteIcon;
            disableReportsEditIcon = iconFlag.disableReportsEditIcon;
        }
        let classNames = '';
        if (handleType === 'vendorClue') {
            classNames = item.isPolicyDriverNameRequired && _.isNil(item.policyDriverName) && 'warningIcon';
        }
        return (
            <>
                <IconButton
                    id={`view${handleType}${index}`}
                    icon="gw-visibility"
                    iconColor="dark"
                    className={classNames}
                    size="medium"
                    onClick={(e) => handleRowItem(e, rowProps, 'View')}
                />
                {!hideEditDeleteIcon && (
                    <IconButton
                        id={`edit${handleType}${index}`}
                        icon="gw-edit"
                        iconColor="dark"
                        disabled={disableReportsEditIcon}
                        className={classNames}
                        size="medium"
                        onClick={(e) => handleRowItem(e, rowProps, 'Edit')}
                    />
                )}
                {(!hideEditDeleteIcon && !readOnly) && (
                    <IconButton
                        id={`delete${handleType}${index}`}
                        icon="gw-delete"
                        iconColor="dark"
                        size="medium"
                        onClick={(e) =>
                            handleDeleteItem(e, rowProps, handleType)
                        }
                    />
                )}
            </>
        );
    };

    const onDefaultPageNext = useCallback(async () =>{
        const {
            jobID,
            sessionUUID,
            baseData: {
                periodStatus,
            },
        } = submissionVM.value;

        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
            submissionVM.value = await WALMultiQuoteService.processQuote(
            // submissionVM.value = await processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM
    }, [authHeader, submissionVM, updateWizardPageData]);

    const onPageNext = useCallback(async () => {

        const {
            onIncidentsPageNext = onDefaultPageNext
        } = props;
        
        const pageId = currentStepPageId || currentPageId;
        const errorsAndWarnings =
            await WniCommonQuoteService.getErrorsAndWarnings(
                submissionVM.jobID.value,
                submissionVM.sessionUUID.value,
                pageId,
                authHeader
            );
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(_.uniqWith(newValidationIssues, _.isEqual));
        const hasValidationError =
            ValidationIssueUtil.hasErrorInValidationIssueList(
                newValidationIssues
            );
        const hasValidationWarning =
            ValidationIssueUtil.hasWarningInValidationIssueList(
                newValidationIssues
            );
        if (hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            return false;
        }

        const res = await onIncidentsPageNext();
        return res
    }, [onDefaultPageNext, props]);

    const onColumnDesCell = (
        (item, index, property) => {
            const { typeKey } = property;
            if (item.source === 'chips') {
                return (
                    <div>{`${item.descriptionForChips}`}</div>
                );
            }
            const val = translator({ id: `${typeKey}.${item.description}` });
            return <div>{`${item.description ? val : ''}`}</div>;
        }
    );

    const onColumnCell = useCallback((item, index, property) => {
        const { path, typeKey } = property;
        if (item[path] && item[path].year) {
            return (
                <div>{WniDateUtil.formatDateWithPattern(item[path])}</div>
            );
        }
        if (item[path] && item[path].currency) {
            return (
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[path]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            );
        }
        const val = typeKey
            ? translator({ id: `${typeKey}.${item[path]}` })
            : item[path];
        return <div>{`${item[path] ? val : ''}`}</div>;
    }, [translator]);

    const getInfoValidationMsg = () => {
        const msg = [{
            type: 'info',
            reason: messages.CreditAuth1.defaultMessage
        },
        {
            type: 'info',
            reason: messages.CreditAuth2.defaultMessage
        }];
        return msg;
    }

    const getSubUserEnterStates = () => {
        const userEnterStates = [];
        if (manuallyClue.length > 0 || (quoteFlow === 'draft')) {
            userEnterStates.push('userEnteredPriorLossesCard');
        }
        if (manuallyMVR.length > 0) {
            userEnterStates.push('userEnteredViolationCard');
        }
        return userEnterStates;
    };
    const getSubVendorStates = () => {
        const vendorStates = [];
        if (vendorClueReports.length > 0) {
            vendorStates.push('vendorPriorLossesCard');
        }
        if (vendorMVR.length > 0) {
            vendorStates.push('vendorViolationCard');
        }
        if (vendorPolicyClaims.length > 0) {
            vendorStates.push('PolicyClaimsCard');
        }
        return vendorStates;
    };
    const sortDate = (a, b) => {
        return DatatableUtil.sortDate(moment(a).format("MM/DD/YYYY"), moment(b).format("MM/DD/YYYY"));
    };
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        '@action': {
            disabled: disableAddButton,
        },
        infoValidationMessages: {
            validationIssues: getInfoValidationMsg()
        },
        // clue add manually
        userAddedPriorLossTable: {
            visible: manualClueVisiblity,
            data: isFilteredManualClueAvaliable? filteredManualCLUE : manuallyClue,
        },
        // vendor clue report
        priorLossCLUETable: {
            visible: vendorClueReports.length > 0,
            data: vendorClueReports,
        },
        // MVR add manually
        userAddedMVRTable: {
            visible: manualMVRVisiblity,
            data: isFilteredManualMVRAvaliable? filteredManualMVR : manuallyMVR,
        },
        // MVR report 
        reportedMVRTable: {
            visible: vendorMVR.length > 0,
            data: vendorMVR,
        },
        // vendor claim report
        PolicyClaimsCard: {
            visible: showPolicyClaims
        },
        reportedPolicyClaimsTable: {
            visible: vendorPolicyClaims.length > 0,
            data: vendorPolicyClaims,
        },
        userEnteredPriorLossesCard: {
            title: LossUtil.getManualClueTitle(isFilteredManualClueAvaliable, filteredManualCLUE, manuallyClue),
        },
        vendorPriorLossesCard: {
            title:
                vendorClueReports.length > 1
                    ? `PRIOR LOSSES ${vendorClueReports.length} RECORDS`
                    : `PRIOR LOSSES ${vendorClueReports.length} RECORD`,
        },
        userEnteredViolationCard: {
            title: LossUtil.getManualMVRTitle(isFilteredManualMVRAvaliable, filteredManualMVR, manuallyMVR),
        },
        vendorViolationCard: {
            title:
                vendorMVR.length > 1
                    ? `VIOLATIONS ${vendorMVR.length} RECORDS`
                    : `VIOLATIONS ${vendorMVR.length} RECORD`,
        },
        claimPriorLossesCard: {
            title:
                vendorPolicyClaims.length > 1
                    ? `PRIOR LOSSES ${vendorPolicyClaims.length} RECORDS`
                    : `PRIOR LOSSES ${vendorPolicyClaims.length} RECORD`,
        },
        accordionInfo: {
            accordionStates: accordionStates
        },
        userEnteredSubInfo: {
            accordionStates: getSubUserEnterStates()
        },
        vendorSubInfo: {
            accordionStates: getSubVendorStates()
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            // clue
            handleClueAddItem: (e) => handleRowItem(e, { handleType: 'userClue' }, 'Add', {source: 'portal'}), // user clue: add
            getUserClueActionFn: (item, index) => getActioncolumnFn(item, index, 'userClue'), // user clue: edit/view
            getVendorClueActionFn: (item, index) => getActioncolumnFn(item, index, 'vendorClue', 'readOnly'), // vendor clue: view
            // mvr
            handleMvrAddItem: (e) => handleRowItem(e, { handleType: 'userMVR' }, 'Add'), // user mvr: add
            getUserMVRActionFn: (item, index) => getActioncolumnFn(item, index, 'userMVR'), // user Mvr: edit/view
            getVendorMVRActionFn: (item, index) => getActioncolumnFn(item, index, 'vendorMVR', 'readOnly'), // vendor mvr: view  
            // common
            handleDeleteItem: handleDeleteItem, // user clue/mvr/carrier: delete
            handleRowItem: handleRowItem,
            onColumnCell: onColumnCell,
            onColumnClueCell: (item, index, property) => onColumnCell(item, index, property, 'clue'),
            onColumnDesCell: (item, index, property) => onColumnDesCell(item, index, property, 'mvr'),
            onColumnMvrCell: (item, index, property) => onColumnCell(item, index, property, 'mvr'),
            onColumnPolicyClaimsCell: (item, index, property) => onColumnCell(item, index, property, 'policyClaims'),
            sortDate: sortDate,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
            sortCurrency: DatatableUtil.sortCurrency
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            onNext={onPageNext}
            pageLevelValidationIssues={validationIssues}
            alwaysCallOnNext
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

WALIncidentsAndReportsPage.propTypes = {
    ...wizardProps,
    checkRequiredForIssuance: PropTypes.bool,
    productName: PropTypes.string,
    currentStepPageId: PropTypes.string,
    wtCLUEService: PropTypes.func,
    wtMVRService: PropTypes.func,
    wtStaticMessage: PropTypes.object,
    /**
     * for other Transaction Types like PolicyChange
     */
    disableAddButton: PropTypes.bool,
    onIncidentsPageNext: PropTypes.func,
    newlyAddedOperators: PropTypes.array,
    hideOrDisableIcons: PropTypes.func,
    filteredManualCLUE: PropTypes.array,
    filteredManualMVR: PropTypes.array,
    showPolicyClaims: PropTypes.bool,
};
WALIncidentsAndReportsPage.defaultProps = {
    checkRequiredForIssuance: true,
    productName: 'watercraft',
    wtCLUEService: WALCLUEService,
    wtMVRService: WALMVRService,
    wtStaticMessage: walStaticMessage,
    // for other Transaction Types like PolicyChange 
    disableAddButton: false,
    onIncidentsPageNext: undefined,
    newlyAddedOperators: [],
    hideOrDisableIcons: undefined,
    filteredManualCLUE: undefined,
    filteredManualMVR: undefined,
    showPolicyClaims: false,
};
export default WALIncidentsAndReportsPage;
