import React from 'react';
import _ from 'lodash';

import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';

// import {
//     CustomEquipmentSingleClauseComponentVM,
//     AccidentForgivenessClauseComponent,
// } from 'wni-components-platform-react';
import {
    QuoteCoveragesUtil, WniClausesUtil,
    QuoteClauseTableUtil,
} from 'wni-portals-util-js';
import { WizardQuotePageConstants } from 'wni-portals-config-js';

import RTSideBySideSingleClauseComponentVM from '../components/Clause/RTSideBySideSingleClauseComponentVM';

// import CoverageSectionHeaderComponent from '../components/CovrageSectionHeader/CoverageSectionHeaderComponent';

/**
 * See
 * 1, QuoteClaueTable
 * 2, QuoteClauseTableUIHelper: default implementation
 * 3, QuoteClauseDesktopTableHelper
 * 4, 
 * @param {object} props 
 * @returns {object}
 */
function RTQuoteClauseTableUIHelper(props) {
    const {
        columnData,
        isCoveragesEditable = true,
        // staleQuoteBranchCodes,
        // resetBranchCodes,
        loadingClause,
        // updateStaleQuoteBranchCodes,
        // updateResetBranchCodes,
        updateLoadingClause,
        // updatePriceDifference,
        // onStaleQuoteBranchCode,
        onSyncCoverages,
        onChangeSubmissionAndSync,
        // onBuyNow, onRecalculate, onResetQuote,
        // onChangeSubmission,
        //
        onValidate,
        showErrors,
        // ==================================
        tableData,
    } = props;

    const getChangedOfferingPathsAndSetClauseToLoading = (chosenTermPath) => {
        const changedColumn = QuoteClauseTableUtil.getChangedColumnData(chosenTermPath, columnData);
        const changedClause = QuoteClauseTableUtil.getChangedClause(chosenTermPath, columnData);
        // const lobOfferingPath = changedColumn.lobOffering.path;
        // const sxsPeriodPath = changedColumn.sxsPeriod.path;
        const {
            // sxsPeriod: {
            //     path: sxsPeriodPath,
            // },
            lobOffering: {
                path: lobOfferingPath,
                index: lobOfferingIndex,
            },
        } = changedColumn;
        // const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        updateLoadingClause({
            clauseID: changedClause.coveragePublicID || changedClause.publicID,
            quoteCode: changedColumn.code
        });
        // updateResetBranchCodes(updatedBranchCodes);

        return { lobOfferingPath, lobOfferingIndex };
    };

    const syncCoverages = async (value, path) => {
        const { lobOfferingPath, lobOfferingIndex } = getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobOfferingPath, lobOfferingIndex).then(() => {
                updateLoadingClause(undefined);
            });
        }
    };

    const changeSubmissionAndSync = async (value, chosenTermPath) => {
        const { lobOfferingPath, lobOfferingIndex } = getChangedOfferingPathsAndSetClauseToLoading(
            chosenTermPath
        );

        if (onChangeSubmissionAndSync) {
            await onChangeSubmissionAndSync(value, chosenTermPath, lobOfferingPath, lobOfferingIndex);
            updateLoadingClause(undefined);
        }
    };

    const renderCellContent = (tableRow, index, propParams) => {
        // TODO: need to check the value of onChangeSubmissionTmp
        // const { onChangeSubmission: onChangeSubmissionClause } = propParams;
        const onChangeSubmissionClause = _.noop;

        // propParams is defined in QuoteClauseDesktopTableHelper.getTableColumnContent()::componentProps
        // const { path, clauses = [], code, vehicleFixedId } = propParams.data;
        // const { isSxsEditable, periodPublicID, coverageIssues } = propParams;
        const {
            // ref: QuoteCoveragesUtil.structureClauseTableData()
            // ref2: RTQuoteUIHelper.structureClauseTableData()
            //
            publicID: rowPublicID,
            codeIdentifier: rowCodeIdentifier,
            //
            // ref: RTQuoteUIHelper.populateRTCovGroupWithCategoryPremium()
            // 
            // sectionTitle,
            // sectionPremium,
        } = tableRow;


        // if (sectionTitle) {
        //     return <CoverageSectionHeaderComponent sectionTitle={sectionTitle} sectionPremium={sectionPremium} />
        // }

        // =================================
        const {
            // See PAQuoteUIHelper.generateTableData()/generateClauseData()
            data: {
                code,
                path,
                clauses = [],
                // pairClauses = [],
            },
            // See QuoteClauseDesktopTableHelper.getTableColumnContent()
            // isSxsEditable,
            periodPublicID,
            coverageIssues,
        } = propParams;

        // const isSignatureVersion = !isSxsEditable;

        const clauseIndex = clauses.findIndex(
            (clause) => clause.publicID === rowPublicID
        );

        if (clauseIndex < 0) {
            const renderContent = null;
            //
            return renderContent;
        }

        const clause = clauses[clauseIndex];

        // ==See PAQuoteUIHelper.getLineCoveragesUniqueID()/getVehicleCoveragesUniqueID()==
        // const availableForCurrentBranch = QuoteCoveragesUtil.filterCovOnQuotePage(clause,
        //     coverageIssues, vehicleFixedId);
        // if (!availableForCurrentBranch) {
        //     return null;
        // }
        // ================================================================================

        const clauseID = _.get(loadingClause, 'clauseID');
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(clauseID);
        const isClauseLoading = (_.get(loadingClause, 'clauseID') === clause.publicID && _.get(loadingClause, 'quoteCode') === code);
        const labelPosition = columnData && columnData.length <= 2 ? 'left' : 'top';
        
        // =====================
        // const clausePatternCode = clause.code_Ext;
        const {
            code_Ext: clausePatternCode,
            coverageCategoryCode,
        } = clause;
        // Amount value will be filtered in backend
        // const isQuoteLevelCoverage = coverageCategoryCode === SECTION_I_CATEGORY
        //     || coverageCategoryCode === SECTION_II_CATEGORY;

        const clauseContainerId = WniClausesUtil.getClauseContainerId(code, clause.publicID);
        const clauseComponentProps = {
            value: clause,
            path: `${path}.children[${clauseIndex}]`,
            onChangeClause: onChangeSubmissionClause,
            onSyncCoverages: syncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            loadingClause: isClauseLoading || isQuoteLoading,
            idPrefix: code,
            isEditable: isCoveragesEditable,
            // isDisabled: isSignatureVersion,
            labelPosition: labelPosition,
            showSchedule: false,
            loadingIconType: 'small',
            onValidate,
            showErrors,
            //
            // showAmount: !isQuoteLevelCoverage,
        };

        return (
            <div id={clauseContainerId} className="SxSClauseContainer">
                <RTSideBySideSingleClauseComponentVM {...clauseComponentProps} />
            </div>
        );
    };

    // ================================================================================
    const getTableColumnContent = (columnDataParam, tableInfo) => {
        return columnDataParam.map((colData, index) => {
            // from PAQuoteUIHelper.generateColumnData()
            const {
                data: {
                    isSxsEditable,
                    publicID: periodPublicID,
                    coverageIssues,
                },
                // vehicleCoverageSets,
            } = colData.sxsPeriod;
            const {
                // vehicleFixedId,
                tableContent,
            } = tableInfo;

            const tableCellContent = tableContent.find((content) => content.code === colData.code);

            return {
                id: `quoteTableColumn${index}`,
                type: 'element',
                component: 'tablecolumn',
                componentProps: {
                    cellClassName: 'gwTableCell',
                    renderCell: renderCellContent,
                    data: tableCellContent,
                    //
                    // isSxsEditable,
                    periodPublicID,
                    coverageIssues,
                    //
                    // vehicleCoverageSets,
                    // vehicleFixedId,
                }
            };
        });
    };

    const generateDesktopTableOverrides = () => {
        // const { tableData, columnData } = props;

        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data,
                title: tableInfo.header,
                content: getTableColumnContent(columnData, tableInfo)
            }
        }));

        return Object.assign({}, ...overrides);
    };


    function generateDesktopOverrides() {
        return {
            // ...generateDesktopHeaderOverrides(),
            ...generateDesktopTableOverrides()
        };
    }

    return {
        // renderCellContent,
        generateDesktopOverrides,
    };
}

export default RTQuoteClauseTableUIHelper;
