import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleTitle: {
        id: 'InlandMarine.valuablePapers.form.schedule.scheduleTitle',
        defaultMessage: 'List of Schedule Items'
    },
    scheduleAdd: {
        id: 'InlandMarine.valuablePapers.form.schedule.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'InlandMarine.valuablePapers.form.schedule.del',
        defaultMessage: 'Delete Selected'
    },
    itemNO: {
        id: 'InlandMarine.valuablePapers.form.schedule.itemNO',
        defaultMessage: 'Item No.'
    },
    description: {
        id: 'InlandMarine.valuablePapers.form.schedule.Description',
        defaultMessage: 'Description'
    },
    limit: {
        id: 'InlandMarine.valuablePapers.form.schedule.limit',
        defaultMessage: 'Limit'
    }
})