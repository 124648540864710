import React from 'react';
import CPRisksPage from "./CPRisksPage";
import WizardPage from '../../templates/CPWizardPage';

function CPRisksReadOnlyPage(props) {
    return (
        <CPRisksPage {...props} isReadOnly/>
    );
}

CPRisksReadOnlyPage.propTypes = WizardPage.propTypes;
export default CPRisksReadOnlyPage;