import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Link } from 'react-router-dom';
import { Icon } from '@jutro/components';
import tileStyles from './TileComponent.module.scss';

class TileComponent extends Component {
    /**
     * @memberof gw-components-platform-react.TilesComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.id - id of the component
     * @prop {string|Object} propTypes.title - title of the component
     * @prop {number} propTypes.value - value of the component
     * @prop {string} propTypes.tileIcon - material-ui icon that need to be displayed in component
     * @prop {boolean} propTypes.redirect - enable/disable page redirect
     * @prop {string} propTypes.redirectPath - used when redirect prop is set to true
     * @prop {string} propTypes.tileSize - size of tile - large/medium
     * @prop {function} propTypes.onClick - callback when tile is clicked
     * @prop {boolean} propTypes.active - mark tile as selected
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                defaultMessage: PropTypes.string
            }),
        ]).isRequired,
        value: PropTypes.number,
        tileIcon: PropTypes.string,
        redirect: PropTypes.bool,
        redirectPath: PropTypes.string,
        tileSize: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        active: PropTypes.bool
    };

    static defaultProps = {
        value: null,
        tileIcon: '',
        redirect: false,
        redirectPath: '#',
        onClick: null,
        active: false,
    };

    tileClicked = (id) => {
        const { onClick } = this.props;
        if (onClick) {
            onClick(id);
        }
    };

    renderContent = () => {
        const {
            title, value, tileIcon, id, tileSize
        } = this.props;
        const tileHeaderColor = cx(tileStyles.gwTileColor, 'gwTilesColor');
        const headerTitle = tileSize === 'large' ? <h1>{value}</h1> : <h2>{value}</h2>;

        return (
            <TranslatorContext.Consumer>
                {(translator) => {
                    return (
                        <div id={id}>
                            <div className={tileHeaderColor} />
                            <div className={tileStyles.gwTileContent}>
                                { tileIcon ? <Icon icon={tileIcon} className={tileStyles.tileIcon} /> : headerTitle}
                            </div>
                            {tileSize === 'large' ? <h4>{translator(title)}</h4> : <span>{translator(title)}</span>}
                        </div>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    };

    renderTiles = () => {
        const {
            id, tileSize, active
        } = this.props;
        const tileWrapper = cx(tileStyles.gwTile, 'gwTileMainContent', {
            [tileStyles.gwActive]: active
        });
        const inputProps = { onClick: () => this.tileClicked(id) };
        const container = cx(tileStyles.gwTiles, {
            [tileStyles.gwTilesLarge]: tileSize === 'large',
            [tileStyles.gwTilesMedium]: tileSize === 'medium'
        });
        return (
            <div className={container}>
                <div className={tileStyles.gwTilesAligner}>
                    <div className={tileWrapper} {...inputProps}>
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { redirect, redirectPath } = this.props;

        return redirect ? (
            <Link to={redirectPath} className={tileStyles.linkStyles}>
                {this.renderTiles()}
            </Link>
        ) : this.renderTiles();
    }
}

export default TileComponent;
