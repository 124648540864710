import React, {
    useCallback,
    useState,
} from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator, IntlContext } from '@jutro/locale';
import {
    Loader,
} from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import AutoDeathBenefitsCoverage from './SingleOperatorClause/AutoDeathBenefitsCoverage/AutoDeathBenefitsCoverage'
import messages from './RTOperatorsCoverages.messages';
import RTCoverageUtil from '../../util/RTCoverageUtil'
import RTOperatorCoverageConfig from "./RTOperatorCoverage.config";
import TotalDisabilityBenefitsCoverage from "./SingleOperatorClause/TotalDisabilityBenefitsCoverage/TotalDisabilityBenefitsCoverage";


const {OperatorCoverageCode} = RTOperatorCoverageConfig

function RTOperatorsCoverages(props) {

    const {
        selectedVersionIndex,
        submissionVM,
        updateWizardData,
        updateErrorsAndWarnings,
        lobName,
        coveragesService,
        isEditable,
        policyContactRolesHasOperatorCovUniqueByContact = [],
        // loadingLineCoverages,
        // setLoadingLineCoverages,
        setIsEditing,
        onValidate,
        showErrors,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const lineCoveragePath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.lineCoverages`;

    /**
     * @typedef {object} LoadingOperatorLineCoveragesState
     * @property {string} policyContactRolePublicID
     * @property {Array.<string>} loadingCoveragePublicID
     */
    /** @type {[LoadingOperatorLineCoveragesState, React.Dispatch<LoadingOperatorLineCoveragesState>]} */
    const [loadingOperatorLineCoverages, setLoadingOperatorLineCoverages] = useState({
        policyContactRolePublicID: null,
        loadingCoveragePublicID: []
    })

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM, policyContactRolePublicID) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = RTCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = RTCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingOperatorLineCoverages({
            policyContactRolePublicID: policyContactRolePublicID,
            loadingCoveragePublicID: updatedCoveragePublicIDs
        })
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        updateErrorsAndWarnings(errorsAndWarnings);
        setLoadingOperatorLineCoverages({
            policyContactRolePublicID: null,
            loadingCoveragePublicID: []
        })
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
    ]);

    const updateOperatorCoverage = useCallback(async (newSubmissionVM, policyContactRolePublicID) => {
        await updateSubmissionVMToServer(newSubmissionVM, policyContactRolePublicID)
    }, [updateSubmissionVMToServer])

    return (
        <>
            {policyContactRolesHasOperatorCovUniqueByContact.map((policyContactRole) => {
                const contact = _.get(policyContactRole, 'contact')
                const policyContactRolePublicID = _.get(policyContactRole, 'publicID_Ext')

                const {
                    policyContactRolePublicID: loadingPolicyContactRolePublicID,
                    loadingCoveragePublicID,
                } = loadingOperatorLineCoverages

                const loadingCoveragesForCurrentContact = 
                    loadingPolicyContactRolePublicID === policyContactRolePublicID ?
                    loadingCoveragePublicID : []
                /**
                 * @constant {{
                 *  [covCode: String]: Boolean
                 * }}
                 */
                const IsOperaterCovLoading = {
                    AutoDeathBenefits: _.isArray(loadingCoveragesForCurrentContact)
                        && loadingCoveragesForCurrentContact.includes(OperatorCoverageCode.AutoDeathBenefits),
                    TotalDisabilityBenefits: _.isArray(loadingCoveragesForCurrentContact)
                        && loadingCoveragesForCurrentContact.includes(OperatorCoverageCode.TotalDisabilityBenefits),
                }    

                return (
                    <div key={policyContactRolePublicID}>
                        <h4 className="driverCoverageTitle mb-15 pb-15">
                            {`${translator(messages.RTOpertatorCoveragesTitlePrefix)}: ${_.get(contact, 'firstName')} ${_.get(contact, 'lastName')}`}
                        </h4>
                
                        {IsOperaterCovLoading.AutoDeathBenefits ?
                            <Loader
                                showLoader
                            /> : 
                            <AutoDeathBenefitsCoverage
                                isEditable = {isEditable}
                                policyContactRole = {policyContactRole}
                                submissionVM={submissionVM}
                                updateWizardData={updateWizardData}
                                lineCoveragePath={lineCoveragePath}
                                updateSubmissionVMToServer={(newSubmissionVM) => updateOperatorCoverage(newSubmissionVM, policyContactRolePublicID)}
                                onValidate={onValidate}
                                showErrors={showErrors}
                            />
                        }
                
                
                        {IsOperaterCovLoading.TotalDisabilityBenefits ?
                            <Loader
                                showLoader
                            /> : 
                            <TotalDisabilityBenefitsCoverage
                                isEditable = {isEditable}
                                policyContactRole = {policyContactRole}
                                submissionVM={submissionVM}
                                updateWizardData={updateWizardData}
                                lineCoveragePath={lineCoveragePath}
                                updateSubmissionVMToServer={(newSubmissionVM) => updateOperatorCoverage(newSubmissionVM, policyContactRolePublicID)}
                                onValidate={onValidate}
                                showErrors={showErrors}
                            />
                        }
                
                    </div>
                )
            })}
        </>
    );

}

RTOperatorsCoverages.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
    loadingLineCoverages: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.bool
    ]).isRequired,
    policyContactRolesHasOperatorCovUniqueByContact: PropTypes.array,
};
RTOperatorsCoverages.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
    policyContactRolesHasOperatorCovUniqueByContact: [],
}

export default RTOperatorsCoverages;