import { defineMessages } from 'react-intl';

export default defineMessages({
    priorLossInfo: {
        id : 'wni.quoteandbind.cpp-wizard.cpp-risk-analysis.priorLossInfo',
        defaultMessage: 'Loss History is required for all claims with a prior carrier in the past 5 years. Please manually enter the prior loss information or upload a loss run.'
    },
    removeLossTitle: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-loss.Remove Loss?',
        defaultMessage: "Remove Manual Loss?"
    },
    removeLossDescription: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-loss.removeLossDescription',
        defaultMessage: 'Are you sure you want to remove this manual loss entry?',
    },
    Ok: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-loss.OK',
        defaultMessage: 'OK'
    },
    Cancel: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-loss.Cancel',
        defaultMessage: 'Cancel'
    },
    uploadFile: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-loss.Upload File',
        defaultMessage: 'Upload File'
    },
    viewAndEditLabel: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-risk analysis.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'wni.quoteandbind.cpp-wizard.cpp-risk analysis.View',
        defaultMessage: 'View',
    },
});