
import React from 'react';

import { CRCommercialFidelityPage } from 'wni-capability-quoteandbind-cr-react';
import { WniProductsUtil, PolicyChangeUtil } from 'wni-portals-util-js';

const {
    CR_PRODUCT_CODE,
    getPolicyLinePatternName
} = WniProductsUtil;

const linePattern = getPolicyLinePatternName(CR_PRODUCT_CODE);

function CRCommercialFidelityChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent(linePattern, submissionVM);
    return (
        <CRCommercialFidelityPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

};

export default CRCommercialFidelityChangePage;