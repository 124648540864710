const sectionFieldsMap = {
    // StateSpecificDetail: [
    //     "experienceRating",
    //     "contractingClassAdjustment",
        
    // ],
    coveredEmployeesDetails: [
        "classCode",
        "classDescription",
        "governingLaw",
        "ifAny",
        "annualPayroll"
    ]
};

export default {
    sectionFieldsMap,
};
