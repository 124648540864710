import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    furtherReviewRequired: {
        id: 'custom.component.ho.VirtualInspectionNotification. If you wish to proceed at this time, review the eligibility and loss details, then refer it to Underwriting to be reviewed.',
        defaultMessage: ' If you wish to proceed at this time, review the eligibility and loss details, then refer it to Underwriting to be reviewed.',
    },
    okBtnLabel: {
        id: 'custom.component.ho.VirtualInspectionNotification.Refer to Underwriting',
        defaultMessage: 'Refer to Underwriting',
    },
    // cancelBtnLabel: {
    //     id: 'custom.component.ho.VirtualInspectionNotification.No',
    //     defaultMessage: 'No',
    // },
    confirmCancelMsg: {
        id: 'custom.component.ho.VirtualInspectionNotification.You have chosen not to proceed for further underwriting, the application has not been submitted.  The quote will be saved and can be accessed in the future. Click “Save and Exit” to leave this page or please click “Cancel” if you wish to continue with the quote.',
        defaultMessage: 'You have chosen not to proceed for further underwriting, the application has not been submitted.  The quote will be saved and can be accessed in the future. Click “Save and Exit” to leave this page or please click “Cancel” if you wish to continue with the quote.',
    }
});
