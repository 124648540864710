import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniGatewayBillingService {
  static getAccountAgencyBillPolicies(accountNumber, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
        'getAccountAgencyBillPolicies', [accountNumber], additionalHeaders);
  }
  
  static getStatementCycles(producerCodes, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
        'getStatementCycles', [producerCodes], additionalHeaders);
  }
}


