import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'modal.button.pu.label.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'modal.button.pu.label.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'modal.button.pu.label.Save',
        defaultMessage: 'Save'
    },
    title: {
        id: 'gateway.views.primary.named.insured.pu.Edit Primary Named Insured',
        defaultMessage: 'Edit Primary Named Insured'
    },
    contactDetails: {
        id: 'gateway.views.primary.named.insured.pu.contactDetails',
        defaultMessage: 'Contact Details'
    },
    showBasicDetails: {
        id: 'gateway.views.primary.named.insured.pu.showBasicDetails',
        defaultMessage: 'Show Basic Details'
    },
    primaryAddress: {
        id: 'gateway.views.primary.named.insured.pu.PrimaryAddress',
        defaultMessage: 'Primary Address'
    },
    showAddresses: {
        id: 'gateway.views.primary.named.insured.pu.showAddresses',
        defaultMessage: 'Show Addresses'
    },
    fullName: {
        id: 'gateway.views.primary.named.insured.pu.fullName',
        defaultMessage: 'Full Name'
    },

});
