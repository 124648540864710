
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DropdownMenuHeader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardContext, wizardStepProps } from '@xengage/gw-portals-wizard-react';

import { PolicyChangeCommonMessages as messages } from 'wni-platform-translations';
import { BaseAddNewChangeVersionComponent } from 'wni-components-platform-react';

import { WALMultiQuoteChangeService } from 'wni-capability-policychange-wal';
import { DropdownMenuLink } from '@jutro/router';
// import metadata from './WALAddNewChangeVersionComponent.metadata.json5';
// import styles from './WALAddNewChangeVersionComponent.module.scss';
// import messages from './WALAddNewChangeVersionComponent.messages';



// const POLICY_TYPE_CORE = 'CORE';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function WALAddNewChangeVersionComponent(props) {
    
    const translator = useTranslator();

    const {
        sxsDataDTO: walSxsDataDTO = {},
        ...restProps
    } = props;


    const {
        canAddNewVersion,
        periods = [{}],
    } = walSxsDataDTO;

    // const [{
    //     policyType_Ext: policyType,
    // }] = periods;


    // useEffect(() => {
    // }, []);

    // // =====================================
    // const renderSwitchToOtherBranchMenuItems = useCallback((accountJobPeriodDTOListParam) => {
    //     const retval =  accountJobPeriodDTOListParam.map((accountJobPeriodDTO) => {
    //         const {
    //             publicID: periodPublicID,
    //             branchName,
    //             // hoCoverageForm,
    //         } = accountJobPeriodDTO;


    //         const menuName = `${branchName}`; // ${hoCoverageForm}
    //         const onMenuClick = async() => onSwitchToBranch(periodPublicID);

    //         return (
    //             <DropdownMenuLink
    //                 type="action"
    //                 onClick={onMenuClick}
    //                 key={`${menuName}-MenuLink`}
    //             >
    //                 {menuName}
    //             </DropdownMenuLink>
    //         );
    //     });

    //     return retval;
    // }, [onSwitchToBranch]);


    // const writeValue = (value, path) => {
    //     onValueChange(value, path);
    // };
    // const renderCreateVersionMenuItems = (menuItemOptions) => {
    //     const retval =  menuItemOptions.map((menuItem) => {
    //         const {
    //             nameMsgKey,
    //             createService
    //         } = menuItem;

    //         const menuName = translator(messages[nameMsgKey]);
    //         const onMenuClick = async() => onCreateNewVersion(createService);
            
    //         return (
    //             <DropdownMenuLink
    //                 type="action"
    //                 onClick={onMenuClick}
    //                 key={`${menuName}-MenuLink`}
    //             >
    //                 {menuName}
    //             </DropdownMenuLink>
    //         );
    //     });

    //     return retval;
    // };

    // const renderCreateVersionMenuItemsV2 = useCallback(() => {

    //     const menuItems = [
    //         {  
    //             menuName: translator(messages.createNewVersion),
    //             createService: WALMultiQuoteChangeService.createDuplicateQuote,
    //             menuVisible: canAddNewVersion,
    //         },
    //     ];

    //     // =======================
    //     const visibleMenuItems =  menuItems.filter((item) => item.menuVisible);

    //     const retval =  visibleMenuItems.map((menuItem) => {
    //         const {
    //             menuName,
    //             createService,
    //             menuVisible,
    //         } = menuItem;

    //         const onMenuClick = async() => onCreateNewVersion(createService);
            
    //         return (
    //             <DropdownMenuLink
    //                 type="action"
    //                 onClick={onMenuClick}
    //                 key={`${menuName}-MenuLink`}
    //                 visible={menuVisible}
    //             >
    //                 {menuName}
    //             </DropdownMenuLink>
    //         );
    //     });

    //     return retval;
    // }, [walSxsDataDTO, onCreateNewVersion]);

    // const getMenuButtonText = useCallback((chooseOptions, addOptions) => {
    //     let retval = messages.addVersions;
    //     if (!_.isEmpty(chooseOptions)) {
    //         if (_.isEmpty(addOptions)) {
    //             retval = messages.chooseVersions;
    //         } else {
    //             retval = messages.chooseAddVersions;
    //         }
    //     }

    //     return retval;
    // }, []);

    // //----------------------------------
    // // const createVersionMenuItems = renderCreateVersionMenuItems(CREATE_VERSION_OPTIONS);
    // const createVersionMenuItems = renderCreateVersionMenuItemsV2();
    // const switchVersionMenuItems = renderSwitchToOtherBranchMenuItems(accountJobPeriodDTOList);
    // const allMenuItems = [...switchVersionMenuItems, ...createVersionMenuItems];
    // const menuButtonText = getMenuButtonText(switchVersionMenuItems, createVersionMenuItems);
    

    // const overrideProps = {
    //     '@all': {
    //     },
    //     '@field': {
    //         labelPosition: 'left',
    //         showOptional: false,
    //         showRequired: true
    //     },
    //     chooseAddNewVersionDropdown: {
    //         buttonText: menuButtonText,
    //         disabled: _.isEmpty(allMenuItems),
    //         content: allMenuItems,
    //     }
    // };

    // const resolvers = {
    //     resolveClassNameMap: styles,
    //     resolveCallbackMap: {

    //     },
    //     resolveComponentMap: {

    //     },
    // };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------

    // return (
    //     <ViewModelForm
    //         uiProps={metadata.componentContent}
    //         model={{}}
    //         overrideProps={overrideProps}
    //         classNameMap={resolvers.resolveClassNameMap}
    //         callbackMap={resolvers.resolveCallbackMap}
    //         componentMap={resolvers.resolveComponentMap}
    //         // onModelChange={updateModelValue}
    //         // resolveValue={readValue}
    //         // onValueChange={writeValue}
    //         // showErrors={showErrors}
    //     />
    // );

    const menuItems = [
        {  
            menuName: translator(messages.createNewVersion),
            createService: WALMultiQuoteChangeService.createDuplicateQuote,
            menuVisible: canAddNewVersion,
        },
    ];

    return <BaseAddNewChangeVersionComponent
        menuItems={menuItems}
        {...restProps}
    />;
}

WALAddNewChangeVersionComponent.propTypes = {
    // model: PropTypes.shape(
    //     {
    //         value: PropTypes.shape({})
    //     }
    // ),
    // onValueChange: PropTypes.func,
    // showErrors: PropTypes.bool,
    sxsDataDTO: PropTypes.shape({}).isRequired,
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
};

WALAddNewChangeVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,

};

export default WALAddNewChangeVersionComponent;
