
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WCStateInfoService } from 'wni-capability-quoteandbind-wc';
import WCStateSpecificUtil from '../../util/WCStateSpecificUtil'

import metadata from './CoverdEmployeesDetails.metadata.json5';

function CoverdEmployeesDetails(props) {
    const {
        coveredEmployeeVM,
        statePublicID,
        onValidate,
        onValueChange,
        syncWizardData,
        updateSubmissionVMForResponse,
        showErrors,
        messages,
        isReadOnly,
        isPolicyChange,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const displayableDTO = _.get(coveredEmployeeVM.value, 'displayableDTO');

    const getDisplayableFieldValue = useCallback((label) => {
        const displayableValue = displayableDTO
        .find((elt) => elt.label === label && elt.available);
        if(label === 'Estimated Annual Payroll') {
            return _.get(displayableValue, 'integerValue')
        }
        return _.get(displayableValue, 'rangeValue')
    }, [displayableDTO])

    const getDisplayableDropdownOptions = useCallback((label) => {
        const displayableValue = displayableDTO.find((elt) => elt.label === label && elt.available);
        if(displayableValue) {
            const options =  displayableValue.rangeValueOptions
            .map((elt) => {
                return {
                    code: elt,
                    name: elt
                }
            });
            return options;
        }
        return null;
    }, [displayableDTO])

    const onDisplayablesValueChange = useCallback(
        async (value, label, path) => {
            const displayable = _.get(coveredEmployeeVM.value, 'displayableDTO')
            setLoadingMask(true);
            const newCoveredEmployeeVM = _.clone(coveredEmployeeVM);
            const index = displayable.findIndex((elt) => elt.label === label && elt.available);
            const diaplayablePath = `displayableDTO[${index}]`
            _.set(newCoveredEmployeeVM.value, `${diaplayablePath}.updated`, true);
            _.set(newCoveredEmployeeVM.value, `${path}`, value);
            // update to server
            if(label !== 'Estimated Annual Payroll') {
                _.set(newCoveredEmployeeVM.value, `${diaplayablePath}.rangeValue`, value);
                const coveredEmployeePublicID = _.get(newCoveredEmployeeVM.value, 'publicID');
                const coveredEmployeeDisplayables = _.get(newCoveredEmployeeVM.value, 'displayableDTO');
                const res = await WCStateInfoService.updateCoveredEmployeeDisplayables(jobID, sessionUUID, statePublicID, coveredEmployeePublicID, coveredEmployeeDisplayables, authHeader);
                updateSubmissionVMForResponse(res);
                const stateSpecifics = _.get(res, 'wcstateSpecificInfo.stateSpecifics');
                const currentStateSpecific = stateSpecifics.find(elt => elt.publicID === statePublicID);
                const coveredEmployees = _.get(currentStateSpecific, 'coveredEmployees');
                const currentCoveredEmployee = coveredEmployees.find(elt => elt.publicID === newCoveredEmployeeVM.value.publicID);
                _.set(newCoveredEmployeeVM, 'value', currentCoveredEmployee);
            } else {
                _.set(newCoveredEmployeeVM.value, `${diaplayablePath}.integerValue`, value);
            }
            syncWizardData(newCoveredEmployeeVM);
            setLoadingMask(false);
        },
        [displayableDTO]);

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        selectByClassCode: {
            visible: getDisplayableFieldValue('Select By') === 'Class Code'
        },
        selectByClassDescription: {
            visible: getDisplayableFieldValue('Select By') === 'Class Description'
        },
        selectBy: {
            value: getDisplayableFieldValue('Select By'),
            availableValues: getDisplayableDropdownOptions('Select By'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'Select By', 'selectBy')
        },
        classCode: {
            value: getDisplayableFieldValue('Class Code'),
            availableValues: getDisplayableDropdownOptions('Class Code'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'Class Code', 'classCode')
        },
        classDescription: {
            value: getDisplayableFieldValue('Class Description'),
            availableValues: getDisplayableDropdownOptions('Class Description'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'Class Description', 'classDescription')
        },
        governingLaw: {
            value: getDisplayableFieldValue('Governing Law'),
            availableValues: getDisplayableDropdownOptions('Governing Law'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'Governing Law', 'governingLaw'),
            readOnly: isPolicyChange || isReadOnly
        },
        ifAny: {  
            visible: getDisplayableDropdownOptions('If Any') !== null,
            value: getDisplayableFieldValue('If Any'),
            availableValues: getDisplayableDropdownOptions('If Any'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'If Any', 'ifAny')
        },
        estimatedAnnualPayroll: {
            value: getDisplayableFieldValue('Estimated Annual Payroll'),
            onValueChange: (value) => onDisplayablesValueChange(value, 'Estimated Annual Payroll', 'annualPayroll')
        }
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            coveredEmployeeVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveredEmployeeVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
}

CoverdEmployeesDetails.propTypes = {

};
CoverdEmployeesDetails.defaultProps = {

};

export default CoverdEmployeesDetails;
