import React from 'react';
import _ from 'lodash';

import ConstructionComponent from "wni-capability-quoteandbind-ho-react/pages/HOResidence/Component/ConstructionComponent/ConstructionComponent";

function DPConstructionComponent(props) {
    const {
        model: constructionVM,
        coverageForm,
        baseState
    } = props;

    const {
        coveragePartType_Ext: coveragePartType,
        isResidenceHaveCentralHeat_Ext: isResidenceHaveCentralHeat,
        selectedCentralHeats_Ext: selectedCentralHeats,
        garageType_Ext: garageType,
        constructionType,
        logType_Ext: logType,
        existChinkingBetweenLogs_Ext: existChinkingBetweenLogs,
        plumbingType_Ext: plumbingType,
        ampService_Ext: ampService,
    } = constructionVM.value;

    // const DP2Condo = coverageForm === 'dp2_Ext' && coveragePartType === 'hopcondo';
    const DP2Condo = coveragePartType === 'hopcondo';

    const DPOverrideProps = {
        constructionOption: {
            requiredForIssuance: coverageForm === 'dp2_Ext' && !DP2Condo || coverageForm === 'dp3_Ext',
            // readOnly: isConstructionOptionReadOnly || (launchRCTExpressCallFlag && !isNoValue(originHopconstructionOption))
        },
        yearBuilt: {
            // readOnly: launchRCTExpressCallFlag && !isNoValue(originYearBuilt)
        },
        numberofStories: {
            // readOnly: launchRCTExpressCallFlag && !isNoValue(originAtoriesNumber)
        },
        finishedLivingArea: {
            required: true,
            // readOnly: launchRCTExpressCallFlag && !isNoValue(originTotalLivingArea)
        },
        numberofBathrooms: {
            requiredForIssuance: true,
            // readOnly: isNumberofBathroomsReadOnly || (launchRCTExpressCallFlag && !isNoValue(originNumberOfBathrooms))
        },
        construction: {
            // readOnly: launchRCTExpressCallFlag && !isNoValue(originConstructionType)
        },
        numberofUnitinBuilding: {
            requiredForIssuance: true,
            availableValues: _.get(constructionVM, 'value.numOfUnitsInBuildingOptions')
        },
        logType: {
            visible: !DP2Condo && constructionType === 'log' && baseState === 'AK'
        },
        foundation: {
            requiredForIssuance: true,
            // readOnly: isFoundationReadOnly || (launchRCTExpressCallFlag && !isNoValue(originFoundationType))
        },
        basementFinished: {
            requiredForIssuance: true,
            // readOnly: isBasementFinishedReadOnly || (launchRCTExpressCallFlag && !isNoValue(originBasementFinishedPercentage)),
        },
        exteriorWallCovering: {
            requiredForIssuance: !DP2Condo,
            visible: !DP2Condo,
            // readOnly: isExteriorWallCoveringReadOnly || (launchRCTExpressCallFlag && !isNoValue(originExteriorWallCovering)),
        },
        roof: {
            requiredForIssuance: !DP2Condo,
            visible: !DP2Condo,
            // readOnly: isRoofReadOnly || (launchRCTExpressCallFlag && !isNoValue(originRoofType)),
        },
        roofSlope: {
            requiredForIssuance: !DP2Condo,
            visible: !DP2Condo,
            // readOnly: isRoofSlopeReadOnly || (launchRCTExpressCallFlag && !isNoValue(originRoofSlopeType)),
        },
        hasCompletedMaintenance: {
            visible: false
        },
        heatingUpgradeDate: {
            visible:
                !DP2Condo &&
                !_.some(
                    _.get(constructionVM, 'value.selectedCentralHeats_Ext'),
                    (elt) => {
                        return elt === 'None_Ext';
                    }
                ),
        },
        isResidenceHaveCentralHeat: {
            requiredForIssuance: !DP2Condo,
            visible: !DP2Condo
        },
        centralHeatExplain: {
            requiredForIssuance: !DP2Condo && isResidenceHaveCentralHeat === false,
            visible: !DP2Condo && isResidenceHaveCentralHeat === false
        },
        centralHeatingType: {
            requiredForIssuance: !DP2Condo && Boolean(isResidenceHaveCentralHeat),
            visible: !DP2Condo && Boolean(isResidenceHaveCentralHeat)
        },
        centralHeatingExplain: {
            requiredForIssuance: !DP2Condo && _.includes(selectedCentralHeats, 'other'),
            visible: !DP2Condo &&  _.includes(selectedCentralHeats, 'other')
        },
        plumbingUpgradeDate: {
            visible:
                !DP2Condo &&
                !_.some(
                    _.get(constructionVM, 'value.plumbingType_Ext'),
                    (elt) => {
                        return elt === 'None_Ext';
                    }
                ),
        },
        wiringUpgradeDate: {
            visible:
                !DP2Condo &&
                !_.some(
                    _.get(constructionVM, 'value.wiringType_Ext'),
                    (elt) => {
                        return elt === 'None_Ext';
                    }
                ),
        },
        isAnyKnobAndTubeWiring: {
            requiredForIssuance: !DP2Condo,
            visible:
                !DP2Condo &&
                !_.some(
                    _.get(constructionVM, 'value.wiringType_Ext'),
                    (elt) => {
                        return elt === 'None_Ext';
                    }
                ),
        },
        garage: {
            requiredForIssuance: !DP2Condo,
            visible: !DP2Condo,
            // readOnly: isGarageReadOnly || (launchRCTExpressCallFlag && !isNoValue(originGarageType)),
        },
        garageExplain: {
            visible: !DP2Condo && garageType === 'Other_Ext'
        },
        numberofStalls: {
            requiredForIssuance: !DP2Condo && garageType !== 'None',
            visible: !DP2Condo && garageType !== 'None',
            // readOnly: isNumberofStallsReadOnly || (launchRCTExpressCallFlag && !isNoValue(originStallsNumber)),
        },
        existChinkingBetweenLogs: {
            visible: !DP2Condo && (logType === 'handhewn' || logType === 'milled')
            && baseState === 'AK'
        },
        chinkingUpdatedType: {
            visible: !DP2Condo && Boolean(existChinkingBetweenLogs && baseState === 'AK')
        },
        logsJoinedSealedPattern: {
            visible: !DP2Condo && Boolean(existChinkingBetweenLogs === false && baseState === 'AK')
        },
        haveLogsBeenSealed: {
            visible: !DP2Condo && (logType === 'handhewn' || logType === 'milled') && baseState === 'AK'
        },
        routineMaintanceFrequency: {
            visible: !DP2Condo && constructionType === 'log' && baseState === 'AK'
        },
        primaryHeatingFuelTankLocation: {
            visible: !DP2Condo && _.includes(selectedCentralHeats, 'oil')
        },
        plumbingTypeDescription: {
            visible: !DP2Condo && _.includes(plumbingType, 'other')
        },
        wiring: {
            visible: !DP2Condo
        },
        electricalType: {
            visible:
                !DP2Condo &&
                !_.some(
                    _.get(constructionVM, 'value.wiringType_Ext'),
                    (elt) => {
                        return elt === 'None_Ext';
                    }
                ),
        },
        ampService: {
            visible: !DP2Condo,
            // readOnly: launchRCTExpressCallFlag && !isNoValue(originAmpService)
        },
        ampServiceExplain: {
            visible: !DP2Condo && ampService === 'Other'
        },
        isGarageHeated: {
            visible: !DP2Condo && garageType !== 'None'
        },
        plumbing: {
            visible: !DP2Condo
        },
    }

    return <ConstructionComponent
        {...props}
        DPOverrideProps={DPOverrideProps}
    />
    }

DPConstructionComponent.propTypes = ConstructionComponent.propTypes;
DPConstructionComponent.defaultProps = ConstructionComponent.defaultProps;

export default DPConstructionComponent;