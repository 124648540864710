import React, {
    useCallback, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import WizardWithErrorContext from '../ErrorContext/WizardWithErrorContext';
import MultiModeWizard from './MultiModeWizard';


// function getWizardWithInitialErrors(initialWizardLevelIssues) {
//     return (wizardProps) => <WizardWithErrorContext initialWizardLevelIssues={initialWizardLevelIssues} {...wizardProps} />;
// }

function MultiModeWizardWithErrorContext(props) {
    const {
        initialWizardLevelIssues,
        ...multiModeWizardProps
    } = props;


    // This returns a new component every time, and only results into funny infinite loops    
    // const WizardWithInitialErrors = getWizardWithInitialErrors(initialWizardLevelIssues);
    // Meanwhile, the wizardComponent/wizardComponentProps pair is also clunky and awkward.
    // Might need to come up with some new design in the future. e.g. will useMemo() help?

    // Workable solution 1:
    // return (
    //     <MultiModeWizard {...multiModeWizardProps}
    //         wizardComponent={WizardWithErrorContext}
    //         wizardComponentProps={{initialWizardLevelIssues}}
    //     />
    // );

    // Workable solution 2:
    const WizardWithInitialErrors = useCallback((wizardProps) => {
        return <WizardWithErrorContext initialWizardLevelIssues={initialWizardLevelIssues} {...wizardProps} />;
    }, []);

    return (
        <MultiModeWizard {...multiModeWizardProps}
            wizardComponent={WizardWithInitialErrors}
        />
    );
}

MultiModeWizardWithErrorContext.propTypes = {
    ...MultiModeWizard.propTypes,
    initialWizardLevelIssues: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        reason: PropTypes.reason,
    })),
}
MultiModeWizardWithErrorContext.defaultProps = {
    ...MultiModeWizard.defaultProps,
    initialWizardLevelIssues: undefined,
};

export default MultiModeWizardWithErrorContext;
