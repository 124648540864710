import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@jutro/layout';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import { Avatar } from '@jutro/components';
import { ImageComponent } from 'gw-components-platform-react';
import { ServiceManager } from '@jutro/legacy/services';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { ContactService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import messages from 'gw-capability-gateway-react/Contacts/Contacts.messages';
import metadata from './AssociatedContactsPage.metadata.json5';
import tabStyles from '../Contacts.module.scss';
import gatewayMessages from '../../gateway.messages';

const PRIMARY_PHONE_TYPE_MAP = {
    home: 'homeNumber',
    mobile: 'cellNumber',
    work: 'workNumber',
};

const servicePageConfig = {
    page: 0,
    pageSize: 100,
};

const pageConfig = {
    page: 0,
    pageSize: 5,
};

function AssociatedContactsPage(props) {
    const { history, accountName, accountNumber, relatedTo } = props;

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const localeService = ServiceManager.getService('locale-service');
    const phoneUtil = PhoneUtil();
    const [associatedContactsTableData, updateAssociatedContactsTableData] = useState([]);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);
    const [selectedTab, updateSelectedTab] = useState('all');

    const generateTableData = (data = []) => {
        return data.map((item) => {
            const itemPhoneType = item.primaryPhoneType;
            const telNumber = item[PRIMARY_PHONE_TYPE_MAP[itemPhoneType]];
            const prettyTelNumber =
                telNumber &&
                phoneUtil.prettyPrint(
                    telNumber,
                    localeService.getDefaultCountryCode()
                );

            return {
                ...item,
                telNumber: prettyTelNumber
            };
        });
    };

    const getAssociatedContacts = async (tab) => {
        let response;
        if (tab !== 'commoncontact') {
            const relationships = tab ? [tab] : [];
            response = await ContactService.getRelatedAccountContacts(
                accountNumber,
                authHeader,
                relationships,
                servicePageConfig.fromPage,
                servicePageConfig.pageSize
            );
        } else {
            response = await ContactService.getCommonAccountContacts(
                accountNumber,
                authHeader,
                servicePageConfig.fromPage,
                servicePageConfig.pageSize
            );
        };
        const generateData = generateTableData(response.accountContacts)
        updateAssociatedContactsTableData(generateData);
    };

    useEffect(() => {
        const tab = selectedTab === 'all' ? null : selectedTab;
        getAssociatedContacts(tab);
    }, [selectedTab]);


    const relatedByDropdownValues = () => {
        const availableValues = [
            {
                name: translator(messages.relationshipTypeAll),
                code: 'all',
            },
            {
                name: translator(messages.commonOwner),
                code: 'commonowner',
            },
            {
                name: translator(messages.commonContact),
                code: 'commoncontact',
            },
        ];
        return availableValues;
    };

    const redirectToContactDetails = (item) => {
        history.push({
            pathname: `/${relatedTo}/${accountNumber}/contactDetails`,
            state: {
                contact: 'associatedContacts',
                accountOrPolicyNumber: accountNumber,
                relatedTo: relatedTo,
                contactId: item.accountContactPublicID
            },
        });
    };

    const renderContact = (item, index, { path }) => {
        return (
            <Flex
                justifyContent="left"
                alignItems="center"
                onClick={() => redirectToContactDetails(item)}
            >
                <Avatar username={item[path]} />
                <span className="btn-link">{item[path]}</span>
            </Flex>
        );
    };

    const renderPoliciesInvolved = (item, index, { path }) => {
        const policiesCount = item[path];
        const isActive = item.active;
        const activePolicies = policiesCount > 1 ? translator(messages.activePolicies) : translator(messages.activePolicy); 

        return isActive ? (
            <Flex gap='small'>
                <h4>{policiesCount}</h4>
                <span>{activePolicies}</span>
            </Flex>
        )
        : translator(gatewayMessages.inactive);
    };

    const onAssociatedContactsSelect = (selectedOption) => {
        updateSelectedTab(selectedOption);
    };

    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };

    const overrideProps = {
        associatedContactsDataTable: {
            key: selectedTab,
            data: associatedContactsTableData,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [5, 10, 15, 20],
        },
        relatedByDropdown: {
            availableValues: relatedByDropdownValues(),
            value: selectedTab,
        },
        contactsRelatedByDescription: {
            content: translator(
                messages[`${selectedTab}RelationshipDescription`],
                {
                    accountName: accountName,
                    accountNumber: accountNumber,
                }
            ),
        },
    };

    const resolvers = {
        resolveClassNameMap: tabStyles,
        resolveCallbackMap: {
            renderContact,
            // renderAccount,
            renderPoliciesInvolved,
            handleAssociatedContactSelect: onAssociatedContactsSelect,
            onRowClick: (data, index) => {
                redirectToContactDetails(index);
            },
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

AssociatedContactsPage.propTypes = {};
export default AssociatedContactsPage;
