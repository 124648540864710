import React, { useMemo, useState, useContext } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { Address } from 'gw-capability-fnol-common-react';
import {createNewPersonWithoutPublicID, createNewCompanyWithoutPublicID} from '../../utils/NewContactUtil';
import metadata from './GLPropertyDetail.metadata.json5';
import messages from '../../FNOLGLIncidentDetailsPage.messages';
import { createNewLocationWithPublicID, getFullAddressDisplayName } from '../../utils/AddressUtil';
import { Button } from '@jutro/legacy/components';

const AddNewPerson = 'AddNewPerson';
const AddNewCompany = 'AddNewCompany';
const AddNewProperty = 'AddNewProperty';
function GLPropertyDetail(props) {
    const {
        propertyVM,
        onValueChange,
        relatedContacts,
        syncWizardData,
        onDetailCancel,
        onDetailSave,
    } = props;
    const {
        ownerContact_Ext: owner,
        availableAddress_Ext: availableAddress,
        readOnlyAddressPublicIDs_Ext: readOnlyAddressPublicIDs
    } = propertyVM.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const { isComponentValid, onValidate } = useValidation('GLPropertyDetail');
    const [ownerPublicID, setOwnerPublicID] = useState(_.get(owner, 'publicID')); 
    const [ownerContact, setOwnerContact] = useState(owner);
    const [showErrors, setShowErrors] = useState(false);


    const locationPublicID = _.get(propertyVM.value, 'locationPublicID_Ext');

    const isReadOnlyAddress = readOnlyAddressPublicIDs.includes(propertyVM.value.locationPublicID_Ext);

    const propertyNameAvailableValues = availableAddress.map((address) => {
        return {
            code: _.get(address, 'publicID'),
            name: getFullAddressDisplayName(address)
        }
    }).concat([
        {
            code: AddNewProperty,
            name: translator(messages.AddNewProperty)
        }
    ])

    const onPropertyNameChange = (value) => {
        const newPropertyIncidentVM = _.clone(propertyVM);
        let newlocation = null;
        if (value === AddNewProperty && !locationPublicID) {
            _.set(newPropertyIncidentVM.value, 'locationPublicID_Ext', AddNewProperty);
            newlocation = createNewLocationWithPublicID()
        } else {
            _.set(newPropertyIncidentVM.value, 'locationPublicID_Ext', value);
            newlocation = availableAddress.find((address) => address.publicID === value);
        }
        _.set(newPropertyIncidentVM.value, 'location_Ext', newlocation);
        syncWizardData(newPropertyIncidentVM);
    }

    const stateAvailableValues = useMemo(() => {
        const newLocationVM = viewModelService.create(
            new Address(),
            'cc',
            'edge.capabilities.address.dto.AddressDTO'
        );
        const availableValues = _.get(newLocationVM, 'state.aspects.availableValues', []);
        const options = _.map(availableValues, (option) => {
            return {
                code: option.code,
                name: translator({
                    id: option.name,
                    defaultMessage: option.name
                })
            };
        });
        return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ownerSelectorAvailableValues = relatedContacts.map((contact) => {
        return {
            code: _.get(contact, 'publicID'),
            name: _.get(contact, 'displayName', '-')
        }
    }).concat(
        [
            {
                code: AddNewPerson, 
                name: translator(messages.addNewPerson)
            },
            {
                code: AddNewCompany, 
                name: translator(messages.addNewCompany)
            }
        ]
    )

    const onOwnerSelectorChange = (value) => {
        if(value === ownerPublicID) {
            return;
        }
        const newPropertyVM = _.clone(propertyVM);
        let currentOwner = null;
        if(value === AddNewPerson) {
            currentOwner = {
                publicID: AddNewPerson,
                ...createNewPersonWithoutPublicID()
            };
        } else if (value === AddNewCompany) {
            currentOwner = {
                publicID: AddNewCompany,
                ...createNewCompanyWithoutPublicID()
            };
        } else {
            currentOwner = relatedContacts.find(item => item.publicID === value);
        }
        _.set(newPropertyVM.value, 'ownerContact_Ext', currentOwner);
        setOwnerContact(currentOwner);
        setOwnerPublicID(currentOwner.publicID);
        syncWizardData(newPropertyVM);
    };

    const onOwnerDetailChange = (value, path) => {
        const updateOwner = {
            ...ownerContact,
            [path]: value
        };
        setOwnerContact(updateOwner);
        _.set(propertyVM.value, 'ownerContact_Ext', updateOwner);
    };

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return;
        }
        onDetailSave();
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
        },
        propertySelector: {
            value: locationPublicID,
            availableValues: propertyNameAvailableValues,
            onValueChange: onPropertyNameChange
        }, 
        propertyLocationContainer: {
            visible: !!locationPublicID
        },
        addressLine: {
            disabled: isReadOnlyAddress,
        },
        city: {
            disabled: isReadOnlyAddress,
        },
        state: {
            availableValues: stateAvailableValues,
            disabled: isReadOnlyAddress,
        },
        zipCode: {
            disabled: isReadOnlyAddress,
        },
        ownerName: {
            value: ownerPublicID,
            availableValues: ownerSelectorAvailableValues,
            onValueChange: onOwnerSelectorChange
        },
        firstName: {
            visible: _.get(ownerContact, 'subtype') === 'Person' || _.get(ownerContact, 'subtype') === 'UserContact',
            onValueChange: (value) => onOwnerDetailChange(value, 'firstName'),
        },
        lastName: {
            visible: _.get(ownerContact, 'subtype') === 'Person' || _.get(ownerContact, 'subtype') === 'UserContact',
            onValueChange: (value) => onOwnerDetailChange(value, 'lastName'),
        },
        companyName: {
            visible: _.get(ownerContact, 'subtype') === 'Company',
            onValueChange: (value) => onOwnerDetailChange(value, 'contactName'),
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, propertyVM, fieldId, fieldPath, overrideProps
        );
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={propertyVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <div className='d-flex flex-direction-row-reverse'>
                <Button
                    type="filled"
                    onClick={onSaveClicked}
                >{translator(messages.saveAndContinue)}</Button>
                <Button
                    className="mr-15 wni-button-link"
                    type="outlined"
                    onClick={() => {onDetailCancel()}}
                >{translator(messages.cancel)}</Button>
            </div>
        </>
    );
}

GLPropertyDetail.propTypes = {
};
GLPropertyDetail.defaultProps = {
};
export default GLPropertyDetail;
