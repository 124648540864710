import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWniModal } from 'wni-components-platform-react';
import PUAddiDetailPopup from './modal/PUAddiDetailPopup';
import messages from './PUAddiComponent.message';
import metadata from './PUAddiComponent.metadata.json5';
import styles from './PUAddiComponent.module.scss';

function PUAddiComponent(props) {
    const modalApi = useWniModal();
    const {
        id,
        value: additionalInterestsVM,
        onValidate,
        onValueChange,
        licenseStateCode,
        accountNumber,
        accountContacts,
        accountHolderBirthday,
        authHeader,
        initialContext,
        readOnly,
        getAdditionalInterestTypeOptions,
        isPopupMode,
        getSubTypeOptions,
        removeAdditionalInterestFn,
        emptyTrusteeVM,
        removeTruteeFn
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation
    } = useValidation(id);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const validateAdditionalInterests = useCallback(() => {
        return additionalInterestsVM.aspects.valid
            && additionalInterestsVM.aspects.subtreeValid;
    }, [additionalInterestsVM]);

    useEffect(() => {
        registerComponentValidation(validateAdditionalInterests);
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, registerComponentValidation]);

    const showModal = useCallback((vm, type) => {
        const initialValue = {
            policyAdditionalInterest: {
                subtype: 'Company',
                primaryAddress: {},
                phoneRequired_Ext: false
            }
        };
        const { _xCenter, _dtoName } = vm;
        const emptyVM = viewModelService.create(
            initialValue, _xCenter, _dtoName, initialContext
        );

        const title = type === 'edit' ? translator(messages.editAdditionalInterest) : translator(messages.addAdditionalInterest);
        const componentProps = {
            title: title,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            newSearchBtnLabel: 'New Search',
            additionalInterestVM: vm,
            emptyVM: emptyVM,
            viewModelService: viewModelService,
            accountNumber,
            accountContacts,
            accountHolderBirthday,
            authHeader,
            readOnly,
            licenseState: licenseStateCode,
            type: type,
            getAdditionalInterestTypeOptions,
            isPopupMode: isPopupMode,
            getSubTypeOptions,
            // TrusteeVM
            emptyTrusteeVM: emptyTrusteeVM,
            removeTruteeFn
        };
        return modalApi.showModal(<PUAddiDetailPopup {...componentProps} />);
    }, [viewModelService, initialContext, translator, accountNumber,
        accountContacts, accountHolderBirthday, authHeader, readOnly,
        licenseStateCode, getAdditionalInterestTypeOptions, isPopupMode,
        getSubTypeOptions, emptyTrusteeVM, removeTruteeFn, modalApi]);

    const getAddButtonLabel = useCallback(() => {
        return translator(messages.addAdditionalInterest);
    }, [translator]);

    const addInterestAndEdit = useCallback(() => {
        // initialize additional interest VM
        additionalInterestsVM.value = additionalInterestsVM.value || [];

        const initialValue = {
            policyAdditionalInterest: {
                subtype: 'Company',
                primaryAddress: {},
                phoneRequired_Ext: false
            }
        };

        const { _xCenter, _dtoName } = additionalInterestsVM;

        const newInterestVM = viewModelService.create(
            initialValue, _xCenter, _dtoName, initialContext
        );

        showModal(newInterestVM).then((updatedVM) => {
            onValueChange(updatedVM);
        }).catch(() => _.noop());
    }, [additionalInterestsVM, initialContext, onValueChange, showModal, viewModelService]);

    const editInterest = useCallback((i) => {
        const vm = additionalInterestsVM.children[i];
        if (!_.isNil(vm)
            && !_.isNil(vm.value.policyAdditionalInterest)
            && _.isNil(vm.value.policyAdditionalInterest.primaryAddress)) {
            // prevent converted data don't has primaryAddress
            _.set(vm, 'value.policyAdditionalInterest.primaryAddress', {});
        }

        // add context to vm, so FilterByCategory can work
        additionalInterestsVM.value = additionalInterestsVM.value || [];
        const { _xCenter, _dtoName } = additionalInterestsVM;
        const vmWithContext = viewModelService.create(
            vm.value, _xCenter, _dtoName, initialContext
        );

        showModal(vmWithContext, 'edit').then((updatedVM) => {
            if (readOnly) {
                return;
            }
            vm.value = updatedVM.value;
            onValueChange(updatedVM);
        }).catch(() => _.noop());
    },
    [additionalInterestsVM, initialContext, onValueChange, readOnly, showModal, viewModelService]);

    const generateOverrides = useCallback(() => {
        const children = _.get(additionalInterestsVM, 'children');
        const overrides = _.map(children, (additionalInterest, i) => {
            const subtype = _.get(additionalInterest, 'policyAdditionalInterest.subtype.value');
            const interestType = _.get(additionalInterest, 'type.value.code');
            const relationshipToInsured = _.get(additionalInterest, 'relationshipToInsured_Ext.value.code');
            const personName = `${_.get(additionalInterest, 'policyAdditionalInterest.firstName.value')} ${
                _.get(additionalInterest, 'policyAdditionalInterest.lastName.value')}`;
            const companyName = _.get(additionalInterest, 'policyAdditionalInterest.contactName.value');
            const valid = additionalInterest.aspects.valid
                && additionalInterest.aspects.subtreeValid;
            return {
                [`additionalInterestCard${i}`]: {
                    className: valid ? styles.card : styles.cardInvalid
                },
                [`personIcon${i}`]: {
                    visible: subtype === 'Person'
                },
                [`companyIcon${i}`]: {
                    visible: subtype === 'Company'
                },
                [`personLink${i}`]: {
                    visible: subtype === 'Person',
                    content: personName,
                    onClick: () => editInterest(i)
                },
                [`companyLink${i}`]: {
                    visible: subtype === 'Company',
                    content: companyName,
                    onClick: () => editInterest(i)
                },
                [`additionalInterestRelationshipToInsured${i}`]: {
                    visible: interestType === 'JOINTOWNERSHIP_Ext'
                },
                [`additionalInterestExplain${i}`]: {
                    visible: interestType === 'JOINTOWNERSHIP_Ext' && relationshipToInsured === 'Ot'
                },
            };
        });

        return Object.assign({}, ...overrides);
    }, [additionalInterestsVM, editInterest]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
        },
        paDeleteAdditionalInterest: {
            visible: !readOnly
        },
        additionalInterestAddCard: {
            onClick: addInterestAndEdit,
            visible: !readOnly
        },
        addButtonLabel: {
            content: getAddButtonLabel()
        },
        ...generateOverrides()
    };

    const removeAdditionalInterest = useCallback(
        (evt) => {
            if (readOnly) {
                return;
            }
            const additionInterestChildrenPath = evt.path; // 'children.0'
            const index = additionInterestChildrenPath.split('.')[1];

            modalApi.showConfirm({
                title: messages.removeAdditionalInterest,
                message: messages.removeAdditionalInterestDesc,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
            }).then(async (result) => {
                if (result === 'confirm') {
                    const selectedItem = _.get(additionalInterestsVM, additionInterestChildrenPath);
                    const fixedId = _.get(selectedItem, 'value.fixedId');
                    await removeAdditionalInterestFn(fixedId);
                }
            }, () => {
                _.noop();
            });
        },
        [additionalInterestsVM, modalApi, readOnly, removeAdditionalInterestFn]
    );

    const resolvers = {
        resolveCallbackMap: {
            onRemoveAdditionalInterest: removeAdditionalInterest,
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={additionalInterestsVM}
            overrideProps={overrideProps}
            // onModelChange={updateAdditionalInterests}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
        />
    );
}

PUAddiComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    value: PropTypes.shape({}).isRequired,
    initialContext: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    licenseStateCode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountContacts: PropTypes.shape([]),
    accountHolderBirthday: PropTypes.shape({}).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool,
    getAdditionalInterestTypeOptions: PropTypes.func,
    isPopupMode: PropTypes.bool
};

PUAddiComponent.defaultProps = {
    readOnly: false,
    accountContacts: [],
    getAdditionalInterestTypeOptions: _.noop,
    isPopupMode: true
};

export default PUAddiComponent;
