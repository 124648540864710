import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { CancellationService } from 'gw-capability-gateway';
import { TranslatorContext } from '@jutro/locale';
import NotesComponent from 'gw-capability-gateway-react/Components/NotesComponent/NotesComponent';
import metadata from './Notes.metadata.json5';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from 'gw-capability-gateway-react/Cancellation/Notes/Notes.messages';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';

class CancellationNotesWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string,
                getCancellationSummaryCount: PropTypes.func
            }),
            basePath: PropTypes.string
        }).isRequired
    }

    state = {
        notesData: [],
        showLoader: false
    }

    componentDidMount() {
        this.getNotes();
    }

    handleError = (title, message) => {
        return this.props.modalContext.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        });
    }

    getNotes = (async () => {
        const {
            authHeader, fromAccountLanding:
            { quoteDetailsData: { jobNumber, getCancellationSummaryCount } }
        } = this.props;
        this.setState({ showLoader: true });
        try {
            const getAvailableNotesData = await CancellationService
                .getNotesForCancellation([jobNumber], authHeader);
            this.setState({
                notesData: getAvailableNotesData,
                showLoader: false
            });
            getCancellationSummaryCount();
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingNotes
            );
        }
    })

    createNote = (async (data) => {
        const {
            authHeader, fromAccountLanding:
            { quoteDetailsData: { jobNumber } }
        } = this.props;
        this.setState({ showLoader: true });
        try {
            const cancellationNoteData = await CancellationService
                .createNoteForCancellation([jobNumber, data],
                    authHeader);
            if (!_.isEmpty(cancellationNoteData)) {
                this.getNotes();
            }
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingNotes
            );
        }
    })

    render() {
        const { notesData, showLoader } = this.state;
        const translator = this.context;
        const overrides = {
            cancellationNotes: {
                initialNotesData: notesData,
                createNote: this.createNote,
                noDataMessage: translator(messages.noCancellationNotes),
                visible: !showLoader,
                openActivitiesNote: false
            },
            notesLoader: {
                showLoader: showLoader
            }
        };
        const resolvers = {
            resolveComponentMap: {
                notescomponent: NotesComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const CancellationNotes = withModalContext(CancellationNotesWithoutModalContext);

export default withAuthenticationContext(CancellationNotes);
