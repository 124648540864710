


import React from 'react';
import { IMInstallationFloaterPage } from 'wni-capability-quoteandbind-im-react';


const IMInstallationFloaterChangePage = (props) => {
    return <IMInstallationFloaterPage {...props} isPolicyChange />
};
export default IMInstallationFloaterChangePage
