
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';

import { CRPolicyDetailsPage } from 'wni-capability-quoteandbind-cr-react';
import { CRPolicyDetailsChangeService } from 'wni-capability-policychange-cr';

function CRPolicyDetailsChangePage(props) {

    return (
        <CRPolicyDetailsPage
            {...props}
            isPolicyChange
            crPolicyDetailsService={CRPolicyDetailsChangeService} />
    );

};

export default CRPolicyDetailsChangePage;