import React from 'react';
import messages from './SpecialClsBIClauses.messages'
import DefaultRiskItemClauses from '../DefaultRiskItemClauses/DefaultRiskItemClauses';

const SpecClsBIClauses = (props) => {
    return <DefaultRiskItemClauses
        {...props}
        messages={messages}
        displayAdditionalInsuredTab
    />
};

export default SpecClsBIClauses;
