import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';

import CADriverComponent from './Components/CADriverComponent/CADriverComponent';
import WizardPage from '../../templates/CAWizardPage';

import metadata from './CADriversPage.metadata.json5';
import messages from './CADriversPage.messages';
import CADriverUtil from './Util/CADriversUtil';

function CADriversReadOnlyPage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const translator = useTranslator();
    const [currentRow, updateCurrentRow] = useState(null);
    const [driverData, updateDriverData] = useState([]);

    const periodStatus = _.get(submissionVM, 'value.baseData.periodStatus');
    const licenseStateOptions = _.get(submissionVM.value, 'lobData.commercialAuto.coverables.licenseStateOptions');

    const driversVMPath = 'lobData.commercialAuto.coverables.drivers';
    const isMaskPrivateFields = periodStatus === 'Bound';

    const setRowIdPath = useCallback(() => {
        const drivers = _.get(submissionVM, `${driversVMPath}.value`);
        drivers.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateDriverData(drivers);
    }, [submissionVM]);

    useEffect(() => {
        setRowIdPath();
    }, []);

    const viewOrEditDriver = useCallback((value, index) => {
        const childrenVM = _.get(submissionVM, `${driversVMPath}.children`);
        const driverVM = childrenVM.find((item) => item.value.rowIdPath === index);
        setRowIdPath();
        updateCurrentRow(driverVM);
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            downloadTemplate: {
                visible: false
            },
            importTemplate: {
                visible: false
            },
            exportData: {
                visible: false
            },
            delDriver: {
                visible: false
            },
            addDriver: {
                visible: false
            },
            driverTable: {
                data: driverData,
                rowIdPath: 'rowIdPath'
            },
            licenseState: {
                renderCell: (item) => _.get(item, 'licenseState.code')
            },
            licenseNumber: {
                renderCell: (item) => CADriverUtil.onLicenseNumberCell(item, isMaskPrivateFields)
            },
            dateOfBirth: {
                renderCell: (item) => CADriverUtil.onDateOfBirthCell(item, isMaskPrivateFields)
            },
            viewOrEditLink: {
                onClick: viewOrEditDriver,
                label: translator(messages.caViewLabel)
            },
            driver: {
                visible: !_.isEmpty(currentRow),
                driverVM: currentRow,
                isMaskPrivateFields,
                isReadOnly: true,
                licenseStateOptions
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM, driverData, currentRow]);

    // ===================================================
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            cadrivercomponent: CADriverComponent
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />

        </WizardPage>
    );
}

CADriversReadOnlyPage.propTypes = WizardPage.propTypes;
export default CADriversReadOnlyPage;