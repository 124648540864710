
import { defineMessages } from 'react-intl';

export default defineMessages({
    Details: {
        "id": "quoteandbind.cr.wizard.step.Line coverages Page.Details",
        "defaultMessage": "Details"
    },
    NAICSCode: {
        "id": "quoteandbind.cr.wizard.step.Line coverages Page.NAICS Code",
        "defaultMessage": "NAICS Code"
    }
});