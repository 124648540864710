import _ from 'lodash';

/**
 * QuestionSet Mapper for PolicyChange
 *
 * See QuestionSetsParser.contentMapper
 * @param {boolean} uwqReadonly
 * @returns {Function}
 */
function getQSMapperForWALPolicyChangePolicyDetails(uwqReadonly) {
    return (metaDataContent) => {
        if ((metaDataContent.id === 'isThisTransferWAL_Ext' || metaDataContent.id === 'isThisTransferRT_Ext') && uwqReadonly) {
            metaDataContent.componentProps.readOnly = true;
            // _.set(metaDataContent, 'componentProps.readOnly', true);
        }
        if(metaDataContent.id.startsWith('explainFor')){
            _.set(metaDataContent, 'componentProps.className', 'indentSection')
        }
        return metaDataContent;
    };
}

export default {
    getQSMapperForWALPolicyChangePolicyDetails,
};
