import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWniModal } from 'wni-components-platform-react';
import { CRPolicyDetailsService } from 'wni-capability-quoteandbind-cr';
import moment from 'moment';

import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil,
    WniProductsUtil,
    WniPNIUtil
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
    PolicyDBAComponent,
    ANIComponent
} from 'wni-capability-gateway-react';
const {
    CR_PRODUCT_CODE,
    getLobName
} = WniProductsUtil;
import WizardPage from '../../templates/CRWizardPage';

import metadata from './CRPolicyDetailsPage.metadata.json5';

function CRPolicyDetailsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        crPolicyDetailsService,
        isReadOnly,
        isPolicyChange
    } = props;

    const {
        jobID,
        sessionUUID,
        policyInfoSource_Ext: policyInfoSource,
        baseData: {
            primaryNamedInsured_Ext: primaryNamedInsured,
            quoteFlow_Ext: quoteFlow,
            accountHolder: {
                feinOfficialId_Ext: fein,
                organizationTypes_Ext: organizationTypes
            },
            productCode
        } = {}
    } = submissionVM.value;

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { domainCompany } = useDependencies('domainCompany');
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CRPolicyDetailsPage');

    const lobName = getLobName(productCode)
    const policyDetailPath = `lobData.${lobName}.policyDetails`;
    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [showPNIInfoMessage, updateShowPNIInfoMessage] = useState(false);
    const [isFEINDisable, updateIsFEINDisable] = useState(false);
    const [isSSNDisable, updateIsSSNDisable] = useState(false);
    const pnidbalist = _.get(submissionVM, 'baseData.pnidbalist_Ext');
    const policyDetailsData = _.get(submissionVM, `value.${policyDetailPath}`) || {};
    const {
        ssn,
    } = policyDetailsData

    const removeSSN = () => {
        const newSubmissionVM = _.clone(submissionVM);
        delete _.get(newSubmissionVM.value, `lobData.${lobName}.policyDetails.ssn`)
        updateSubmissionVM(newSubmissionVM);
    }

    useEffect(() => {
        if (getSSNVisible()) {
            if (fein) {
                updateIsSSNDisable(true);
            } else {
                updateIsSSNDisable(false);
            }
            if (ssn) {
                updateIsFEINDisable(true);
            } else {
                updateIsFEINDisable(false);
            }
        } else {
            updateIsSSNDisable(false);
            updateIsFEINDisable(false);
            removeSSN();
        }
    }, [organizationTypes, fein, ssn]);

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = getMinDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();

        return (minDif >= 0) 
    }, [submissionVM.value, getMinDate]);

    const isFeinValid = useCallback(() => {
        if (!fein) {
            return true
        }
        return fein.indexOf('#') === -1 ;
    }, [fein])

    const CRPolicyDetailsValidation = useCallback(() => {
        return isEffectiveDateValid()
            && WniPNIUtil.isPNIValid(primaryNamedInsured)
            && isFeinValid()
    }, [isEffectiveDateValid, isFeinValid, primaryNamedInsured]);

    useEffect(() => {
        registerComponentValidation(CRPolicyDetailsValidation);
    }, [registerComponentValidation, CRPolicyDetailsValidation, primaryNamedInsured]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const getMinDate = useCallback(() => {
        // for transactions once rated, enable 5 days behind the current date
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            return moment(systemDate).add(-5, 'd').startOf('day').toDate();
        }
        return systemDate
    }, [quoteFlow, systemDate]);

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
    }, [submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.contactName');
        return `${displayName}`;
    }, [submissionVM]);

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        _.set(primaryNamedInsuredVM.value, 'isNewAccount_Ext', true)
        // _.set(primaryNamedInsuredVM.value, 'accountOrgType_Ext', 'commonownership')
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                const newSubmissionVM = _.clone(submissionVM);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    },[showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    const getAvailableValues = (path) => {
        const options = _.get(submissionVM.baseData.accountHolder,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };

    const getSSNVisible = useCallback(() => {
        return _.includes(organizationTypes, 'individual_ext')
            || _.includes(organizationTypes, 'soleproprietorship_ext');

    }, [organizationTypes]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        let primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);

    const writeValue = useCallback((value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            const accountHolderPublicID = _.get(submissionVM.value, 'baseData.accountHolder.publicID')
            const pniContactPublicID = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.publicID')
            if(path === 'baseData.accountHolder.feinOfficialId_Ext' && accountHolderPublicID === pniContactPublicID){
                _.set(newSubmissionVM.value, 'baseData.primaryNamedInsured_Ext.feinOfficialId_Ext', value)
            }
            if (path === 'baseData.accountHolder.businessDescription_Ext') {
                _.set(newSubmissionVM.value, 'baseData.primaryNamedInsured_Ext.businessDescription_Ext', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        }, [submissionVM, updateSubmissionVM]);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                showEmployeeFlagToggle: false,
                authHeader,
                sessionUUID,
                jobID,
                policyDetailsService: crPolicyDetailsService,
                policyDetailPath,
                DBAsVM: pnidbalist,
                isReadOnly,
                accountContacts:  _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                onValueChange: writeValue,
                size: 'lg'
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, sessionUUID, policyDetailPath, pnidbalist, submissionVM, writeValue, modalApi]
    );

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
        },
        effectiveDate: {
            minDate: getMinDate(),
            showErrors
        },
        agencyAndProducerInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: false,
                licensedAgent: true,
                servicingAgent: true
            },
            readOnlyFields: {
                agencyOfRecordReadOnly: true,
                licensedAgentReadOnly: isReadOnly,
                servicingAgentReadOnly: isReadOnly
            },
            producerCodePath: 'producerCode_Ext',
            onValidate
        },
        primaryNamedInsured: {
            readOnly:true,
            value: getPrimaryNamedInsuredAndDisplay(),
            containerClassName: (!WniPNIUtil.isPNIValid(primaryNamedInsured) && showErrors)? 'validationError' : '',
        },
        primaryNamedInsuredEditIcon: {
            onClick: !showPNIInfoMessage ? () => updateShowPNIInfoMessage(true) : openEditPrimaryNamedInsuredPopup,
            visible: !isReadOnly
        },
        organizationTypeMultiSelect: {
            availableValues: getAvailableValues('organizationTypesOption_Ext')
        },
        CRLineWarningMsg: {
            visible: productCode === CR_PRODUCT_CODE && _.get(domainCompany, 'code') !== 'UIC'
        },
        primaryNamedInsuredNotificationInfo: {
            visible: showPNIInfoMessage,
        },
        additionalInsuredSection: {
            model: _.get(submissionVM, `${policyDetailPath}.additionalInsureds`) || {},
            onValueChange: writeValue,
            viewModelService,
            industryCode: {
                code: _.get(primaryNamedInsured, 'industryCode_Ext'),
                classification: _.get(primaryNamedInsured, 'industryDescription_Ext')
            },
            policyDetailsService: crPolicyDetailsService,
            authHeader,
            sessionUUID,
            jobID,
            organizationTypesOptions: getAvailableValues('organizationTypesOption_Ext'),
            organizationTypes,
            policyDetailPath,
            accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
            policyAddress: _.get(submissionVM, 'value.baseData.policyAddress'),
            existingAddlNamedInsureds: _.get(submissionVM, `value.${policyDetailPath}.unassignedAdditionalNamedInsureds`),
            FEIN: _.get(submissionVM, 'baseData.accountHolder.feinOfficialId_Ext'),
            isReadOnly
        },
        FEIN: {
            disabled: isFEINDisable
        },
        SSN: {
            disabled: isSSNDisable,
            visible: getSSNVisible()
        },
        policyDBA: {
            dbas: _.get(submissionVM, `value.${policyDetailPath}.policyDBAList`),
            isReadOnly,
            authHeader,
            sessionUUID,
            jobID,
            onValueChange: writeValue,
            policyDetailPath,
            titleMessage: 'Additional Policy DBAs'
        },
        termType: {
            readOnly:true
        },
        policyState: {
            readOnly:true
        },
        NAICS: {
            readOnly:true
        },
        NAICSDescription: {
            readOnly:true
        }
    };

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const policyDetailsData = _.get(submissionVM.value, `lobData.${lobName}.policyDetails`)
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        const requestData = {
            jobID,
            sessionUUID,
            policyDetailsData,
            quoteBaseData
        }
        if (isPolicyChange) {
            const changeData = _.get(submissionVM.value, 'changeData');
            _.set(requestData, 'changeData', changeData);
        }

        const res = await crPolicyDetailsService.savePolicyDetailsData(requestData, authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [displayWarnings, submissionVM, updateSubmissionVM]);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            anicomponent: ANIComponent,
            policydbacomponent: PolicyDBAComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CRPolicyDetailsPage.propTypes = WizardPage.propTypes;
CRPolicyDetailsPage.defaultProps = WizardPage.defaultProps;

CRPolicyDetailsPage.propTypes = {
    ...wizardProps,
    crPolicyDetailsService: PropTypes.object
};

CRPolicyDetailsPage.defaultProps = {
    crPolicyDetailsService: CRPolicyDetailsService
};
export default CRPolicyDetailsPage;