import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './LocationBuildingsComponent.metadata.json5';

import BuildingHeaderComponent from '../BuildingHeaderComponent/BuildingHeaderComponent';
import styles from './LocationBuildingsComponent.module.scss';

function renderBuildingAccordionHeader({
    building,
    pathOfBuildingToRender,
    isEditable,
    onEditBuilding,
    onRemoveBuilding
}) {
    return (isOpen) => (
        <div className={styles.accordionHeaderContainer}>
            <BuildingHeaderComponent
                buildingName={building.name}
                path={pathOfBuildingToRender}
                isAccordionOpen={isOpen}
                isEditable={isEditable}
                onEditBuilding={onEditBuilding}
                onRemoveBuilding={onRemoveBuilding}
            />
        </div>
    );
}

function LocationBuildingsComponent(props) {
    const {
        data: locationVM, path, isEditable, onEditBuilding, onRemoveBuilding
    } = props;

    const breakpoint = useContext(BreakpointTrackerContext);

    function generateBuildingOverrides() {
        const overrides = locationVM.buildings.value.map((building, index) => {
            const pathOfBuildingToRender = `${path}.buildings.children[${index}].value`;
            return {
                [`buildingAccordionCard${index}`]: {
                    renderHeader: renderBuildingAccordionHeader({
                        building,
                        pathOfBuildingToRender,
                        isEditable,
                        onEditBuilding,
                        onRemoveBuilding
                    })
                },
                [`buildingContent${index}`]: {
                    defaultActiveTab: `detailTab${index}`
                },
                [`propertyClassCode${index}`]: {
                    value: `${building.classCode.code}: ${building.classCode.classification}`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }

    const overrideProps = {
        ...generateBuildingOverrides()
    };

    if (breakpoint !== 'desktop') {
        overrideProps['@field'] = {
            labelPosition: 'top'
        };
    }

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={locationVM.buildings}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

LocationBuildingsComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    path: PropTypes.string,
    isEditable: PropTypes.bool,
    onEditBuilding: PropTypes.func,
    onRemoveBuilding: PropTypes.func
};

LocationBuildingsComponent.defaultProps = {
    path: '',
    isEditable: false,
    onEditBuilding: undefined,
    onRemoveBuilding: undefined
};

export default LocationBuildingsComponent;
