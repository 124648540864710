import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PropertyCodeLookupService } from 'gw-capability-quoteandbind-cp';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './EditBuilding.metadata.json5';
import styles from './EditBuilding.module.scss';

const EditBuilding = (props) => {
    const {
        id,
        onValidate,
        setBuildingSubmission,
        wizardData: { submissionVM: wizardSubmissionVM, selectedLocation, selectedBuilding }
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [submissionVM, updateSubmissionVM] = useState(null);
    const [propertyClassCodeList, updatePropertyClassCodeList] = useState([]);
    const [propertyCodes, updatePropertyCodes] = useState([]);
    const [isAlarmEnabled, enableAlarm] = useState(true);

    useEffect(() => onValidate(isComponentValid, id), [id, isComponentValid, onValidate]);

    const getPropertyClassCodeList = useCallback(
        (buildingSubmission) => {
            const jobId = _.get(wizardSubmissionVM.value, 'quoteID')
                || _.get(wizardSubmissionVM.value, 'jobID');
            const classCode = _.get(buildingSubmission.value, 'classCode');
            const address = _.get(selectedLocation, 'location.address');
            const sessionId = _.get(wizardSubmissionVM.value, 'sessionUUID');

            PropertyCodeLookupService.getPropertyCodes(
                'CommercialProperty',
                jobId,
                classCode || null,
                address,
                sessionId,
                authHeader
            ).then((response) => {
                const propertyClassCodesResponse = response
                    && response.map((propertyClassCode) => {
                        const propertyClassCodesData = {
                            publicID: propertyClassCode.publicID,
                            code: propertyClassCode.code,
                            name: propertyClassCode.code.concat(
                                ': ',
                                propertyClassCode.classification
                            )
                        };
                        return propertyClassCodesData;
                    });

                updatePropertyCodes(response);
                updatePropertyClassCodeList(propertyClassCodesResponse);
            });
        },
        [wizardSubmissionVM.value, selectedLocation, authHeader]
    );

    useEffect(() => {
        const buildingModel = _.isEmpty(_.get(selectedBuilding, 'building'))
            ? { classCode: '' }
            : _.get(selectedBuilding, 'building');
        const submission = viewModelService.create(
            buildingModel,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.BuildingDTO'
        );
        getPropertyClassCodeList(submission);
        updateSubmissionVM(submission);
        // does not required for every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            if (path === 'classCode') {
                const selectedClassCode = propertyCodes.find(
                    (classCodeData) => classCodeData.code === value
                );
                newSubmissionVM.classCode.value = selectedClassCode;
            } else {
                _.set(newSubmissionVM, path, value);
            }
            updateSubmissionVM(newSubmissionVM);
            setBuildingSubmission(newSubmissionVM, propertyCodes);
        },
        [viewModelService, submissionVM, setBuildingSubmission, propertyCodes]
    );

    const updateToggledAlarm = (value) => {
        enableAlarm(value);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        propertyClassCode: {
            availableValues: propertyClassCodeList,
            value: _.isObject(_.get(submissionVM, 'classCode.value'))
                ? _.get(submissionVM, 'classCode.value.code')
                : _.get(submissionVM, 'classCode.value')
        },
        alarm: {
            value: isAlarmEnabled,
            onValueChange: updateToggledAlarm
        },
        alarmType: {
            visible: isAlarmEnabled,
            value: _.get(submissionVM, 'alarmType.value.code')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (Id, Path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, Id, Path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
        />
    );
};

EditBuilding.propTypes = {
    id: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    setBuildingSubmission: PropTypes.func.isRequired,
    wizardData: PropTypes.shape({
        submissionVM: PropTypes.shape({}),
        selectedLocation: PropTypes.shape({}),
        selectedBuilding: PropTypes.shape({})
    }).isRequired
};

EditBuilding.defaultProps = {
    id: 'EditBuilding'
};

export default EditBuilding;
