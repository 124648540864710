const sectionFieldsMap = {
    contactDetail: [
        'firstName',
        'lastName',
        'dateOfBirth'
    ],
    // operatorLicenseInfo: [
    //     'isLicensedDriver',
    //     'unLicensedDriverReason',
    //     'unlicensedDriverExplain',
    //     'licenseStatus',
    //     'licenseNumber',
    //     'licenseState',
    //     'ageFirstLicensed'
    // ],
    // operatorUnderwriting: [
    // ]
};

export default {
    sectionFieldsMap,
};
