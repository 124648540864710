import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import BaseFNOLWizard from './CEBaseFNOLWizard';
import FNOLSelectPolicy from './pages/FNOLSelectPolicy/CEFNOLSelectPolicy';
import FNOLSummaryPage from './pages/Summary/SummaryPage';
import FNOLContactDetailsPage from './pages/ContactDetails/ContactDetailsPage';
import FNOLWherePage from './pages/Where/WherePage';
import FNOLAdditionalInformationPage from './pages/AdditionalInformation/AdditionalInformationPage';
import ClaimsConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import Claim, {saveFNOLDetails} from './models/Claim';
import Contact from './models/Contact';
import Address from './models/Address';
import FNOLTypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage';
import ClaimUtil from './utils/ClaimUtil';
import VehicleDamagePicker from './components/VehicleCollision/VehicleDamagePicker';
import FNOLContactAdminPage from './pages/ContactAdmin/FNOLContactAdminPage';
import FNOLContactAgentPage from './pages/ContactAgent/FNOLContactAgentPage';
import FNOLBusinessLocationPage from './pages/BusinessLocation/FNOLBusinessLocationPage';
import FNOLPolicyVehicle from './components/PolicyVehicle/PolicyVehicle';

setComponentMapOverrides(
    {
        BaseFNOLWizard: { component: 'BaseFNOLWizard' },
        FNOLSelectPolicy: { component: 'FNOLSelectPolicy' },
        FNOLSummaryPage: { component: 'FNOLSummaryPage' },
        FNOLContactDetailsPage: { component: 'FNOLContactDetailsPage' },
        FNOLTypeOfIncidentPage: { component: 'FNOLTypeOfIncidentPage' },
        FNOLWherePage: { component: 'FNOLWherePage' },
        FNOLAdditionalInformationPage: { component: 'FNOLAdditionalInformationPage' },
        ClaimsConfirmationPage: { component: 'ClaimsConfirmationPage' },
        FNOLContactAdminPage: { component: 'FNOLContactAdminPage' },
        FNOLContactAgentPage: { component: 'FNOLContactAgentPage' },
        FNOLBusinessLocationPage: { component: 'FNOLBusinessLocationPage' },
        FNOLPolicyVehicle: { component: 'FNOLPolicyVehicle' },
    },
    {
        BaseFNOLWizard,
        FNOLSelectPolicy,
        FNOLSummaryPage,
        FNOLContactDetailsPage,
        FNOLTypeOfIncidentPage,
        FNOLWherePage,
        FNOLAdditionalInformationPage,
        ClaimsConfirmationPage,
        FNOLContactAdminPage,
        FNOLContactAgentPage,
        FNOLBusinessLocationPage,
        FNOLPolicyVehicle
    }
);

export {
    BaseFNOLWizard as default,
    Claim,
    ClaimsConfirmationPage,
    Contact,
    Address,
    ClaimUtil,
    VehicleDamagePicker,
    FNOLPolicyVehicle,
    FNOLContactAdminPage,
    FNOLContactAgentPage,
    FNOLBusinessLocationPage,
    saveFNOLDetails
};
export { default as FNOLLossLocation } from './components/LossLocation/LossLocation';
export { default as fnolCommonMessages } from './FNOL.messages';
