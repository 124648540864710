import { defineMessages } from 'react-intl';

export default defineMessages({
    thisPropertyBelongsToYou: {
        id: "wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.thisPropertyBelongsToYou",
        defaultMessage: "This property belongs to you?"
    },
    damageDescription: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.damageDescription',
        defaultMessage: 'Damage Description'
    },
    Yes: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.Yes',
        defaultMessage: 'Yes'
    },
    No: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.No',
        defaultMessage: 'No'
    },
    cancel: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.save',
        defaultMessage: 'Save & Close'
    },
    addNewLocation: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.addNewLocation',
        defaultMessage: 'Add new location'
    },
    unknowTips: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.unknowTips',
        defaultMessage: 'If you don\'t know specific {what}, please enter \'Unknown\''
    },
    city: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.city',
        defaultMessage: 'city'
    },
    lastName: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.lastName',
        defaultMessage: 'last name'
    },
    addNewPerson: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.addNewPerson',
        defaultMessage: '-- Add New Person --'
    },
    addNewCompany: {
        id: 'wni.pe.otherlossdetail.fnol.pa.views.incidentdetails.singlePropertyIncidentPopup.addNewCompany',
        defaultMessage: '-- Add New Company --'
    },
})