
import React, { useEffect } from 'react';
import _ from 'lodash';
import { WALOperatorsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function RTOperatorsReadOnlyPage(props) {

    return (
        <WALOperatorsReadOnlyPage
            {...props}
            linePath = 'roadTrail'
        />
    );

}

RTOperatorsReadOnlyPage.propTypes = WALOperatorsReadOnlyPage.propTypes;
RTOperatorsReadOnlyPage.defaultProps = WALOperatorsReadOnlyPage.defaultProps;

export default RTOperatorsReadOnlyPage;
