
import React, {} from 'react';

import { DPCoveragesPage } from 'wni-capability-quoteandbind-dp-react';


function DPCoveragesChangePage(props) {

    return (
        <DPCoveragesPage
            {...props}
        />
    );

}

DPCoveragesChangePage.propTypes = DPCoveragesPage.propTypes;
DPCoveragesChangePage.defaultProps = DPCoveragesPage.defaultProps;

export default DPCoveragesChangePage;