import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import WALDummyPage from './pages/Dummy/WALDummyPage';
import WALDummyReadOnlyPage from './pages/Dummy/WALDummyPage.readonly';

import WALWatercraftsPage from './pages/Watercrafts/WALWatercraftsPage';
import WALWatercraftsReadOnlyPage from './pages/Watercrafts/WALWatercraftsPage.readonly';

import WALOperatorsPage from './pages/Operators/WALOperatorsPage';
import WALOperatorsReadOnlyPage from './pages/Operators/WALOperatorsPage.readonly';

import WALCoveragesPage from './pages/Coverages/WALCoveragesPage';
import WALCoveragesReadOnlyPage from './pages/Coverages/WALCoveragesPage.readonly';

import WALPolicyDetailsPage from './pages/PolicyDetails/WALPolicyDetailsPage';
import WALPolicyDetailsReadOnlyPage from './pages/PolicyDetails/WALPolicyDetailsPage.readonly';

import WALIncidentsAndReportsPage from './pages/IncidentsAndReports/WALIncidentsAndReportsPage';
import WALIncidentsAndReportsReadOnlyPage from './pages/IncidentsAndReports/WALIncidentsAndReportsPage.readonly';

import WALQuotePage  from './pages/Quote/WALQuotePage';
import WALQuoteReadOnlyPage from './pages/Quote/WALQuotePage.readonly';

import WALAdditonalInterestDemoPage from './pages/AdditionalInterest/WalAddInstDemoPage';

import WALAdditionalInterestComponent from './pages/AdditionalInterest/AdditionalInterest/WALAdditionalInterestComponent'

setComponentMapOverrides(
    {
        WALDummyPage: {component: 'WALDummyPage'},
        WALDummyReadOnlyPage: { component: 'WALDummyReadOnlyPage' },

        WALWatercraftsPage: {component: 'WALWatercraftsPage'},
        WALWatercraftsReadOnlyPage: { component: 'WALWatercraftsReadOnlyPage' },

        WALOperatorsPage: {component: 'WALOperatorsPage'},
        WALOperatorsReadOnlyPage: {component: 'WALOperatorsReadOnlyPage'},

        WALCoveragesPage: {component: 'WALCoveragesPage'},
        WALCoveragesReadOnlyPage: {component: 'WALCoveragesReadOnlyPage'},

        WALPolicyDetailsPage: {component: 'WALPolicyDetailsPage'},
        WALPolicyDetailsReadOnlyPage: {component: 'WALPolicyDetailsReadOnlyPage'},
        
        WALIncidentsAndReportsPage: {component: 'WALIncidentsAndReportsPage'},
        WALIncidentsAndReportsReadOnlyPage: {component: 'WALIncidentsAndReportsReadOnlyPage'},

        WALQuotePage: {component: 'WALQuotePage'},
        WALQuoteReadOnlyPage: {component: 'WALQuoteReadOnlyPage'},

        WALAdditonalInterestDemoPage: {component: 'WALAdditonalInterestDemoPage'},
        
    },
    {
        WALDummyPage,
        WALDummyReadOnlyPage,

        WALWatercraftsPage,
        WALWatercraftsReadOnlyPage,

        WALOperatorsPage,
        WALOperatorsReadOnlyPage,
        
        WALCoveragesPage,
        WALCoveragesReadOnlyPage,
        
        WALPolicyDetailsPage,
        WALPolicyDetailsReadOnlyPage,

        WALIncidentsAndReportsPage,
        WALIncidentsAndReportsReadOnlyPage,
        //
        WALQuotePage,
        WALQuoteReadOnlyPage,

        WALAdditonalInterestDemoPage,
    }
);


export {
    WALDummyPage,
    WALDummyReadOnlyPage,
    WALWatercraftsPage,
    WALWatercraftsReadOnlyPage,
    WALOperatorsPage,
    WALOperatorsReadOnlyPage,
    WALCoveragesPage,
    WALCoveragesReadOnlyPage,
    WALPolicyDetailsPage,
    WALPolicyDetailsReadOnlyPage,
    WALIncidentsAndReportsPage,
    WALIncidentsAndReportsReadOnlyPage,
    WALAdditonalInterestDemoPage,
    WALAdditionalInterestComponent
};


export { default as WALSubmissionWizard } from './WALSubmissionWizard';
export { default as WALWizardPage } from './templates/WALWizardPage';

export { default as WALQuoteIssueRenderHelper } from './pages/Quote/util/WALQuoteIssueRenderHelper';
