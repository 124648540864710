
import React from 'react';

import { RTCoveragesReadOnlyPage } from 'wni-capability-quoteandbind-rt-react';

function RTCoveragesChangeReadOnlyPage(props) {

    return (
        <RTCoveragesReadOnlyPage {...props} />
    );

}

RTCoveragesChangeReadOnlyPage.propTypes = RTCoveragesReadOnlyPage.propTypes;
RTCoveragesChangeReadOnlyPage.defaultProps = RTCoveragesReadOnlyPage.defaultProps;

export default RTCoveragesChangeReadOnlyPage;