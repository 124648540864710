import React, {useState, useCallback, useEffect} from 'react'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CRCoverablePage.metadata.json5';
import WizardPage from '../../templates/CRWizardPage';
import CoveragesConfigContext from '../../context/CRCoveragesConfigContext';
import CRCoveragesConfig from '../../config/CRCoverages.config';
import CoveragesCard from './component/CoveragesCard';
import AdditionalCoveragesCard from './component/AdditionalCoveragesCard';
import ExclusionsAndConditionsCard from './component/ExclusionsAndConditionsCard/ExclusionsAndConditionsCard';

const CRCoverableReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM,
        initClausesFunc,
    } = props;

    const { initialValidation, onValidate } = useValidation('CRCoverablePage');
    const { authHeader } = useAuthentication();
    const [coverableCoverages, setCoverableCoverages] = useState()

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');



    const initCoverableCoverages = useCallback(async () => {
        setLoadingMask(true)
        const res = await initClausesFunc(jobID, sessionUUID, authHeader)
        setCoverableCoverages(res)
        setLoadingMask(false)
    }, [authHeader, initClausesFunc, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverableCoverages()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    const onPageNext = async () => {
        return submissionVM
    }


    const accordionCommonProps = {
        coverableCoverages: coverableCoverages,
        isEditing: true,
        isEditable: false,
        showErrors: false,
        onValidate,
        wizardData: submissionVM,
    }

    const overrideProps = {
        '@field': {
            isEditable: false,
        },
        CoveragesCard: {
            ...accordionCommonProps
        },
        AdditionalCoveragesCard: {
            ...accordionCommonProps
        },
        ConditionsAndExclusionsCard: {
            ...accordionCommonProps
        },
        AdditionalInsuredsCard: {
            ...accordionCommonProps
        },
        ExclusionsAndConditionsCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            CoveragesCard: CoveragesCard,
            AdditionalCoveragesCard: AdditionalCoveragesCard,
            ExclusionsAndConditionsCard: ExclusionsAndConditionsCard,
        },
    };


    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {coverableCoverages && 
                <CoveragesConfigContext.Provider value={CRCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={false}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default CRCoverableReadOnlyPage;