import React, {
    useCallback, useContext, useEffect, useRef, useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    ErrorsAndWarningsUtil,
    QuoteUtil,
    VehicleClausesUtil,
    VehicleValidationUtil,
    OOSUtil, WniClausesUtil, VehicleUtil,
} from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import { WniPAQuoteService } from 'wni-capability-quoteandbind';
import { CustomFooterV2Component, VehicleCoveragesComponent } from 'wni-capability-gateway-react';
import metadata from './PAVehicleCoveragesPage.metadata.json5';
import customMessages from './PAVehicleCoveragesPage.messages';
import PAVehicleUtil from '../Vehicles/util/PAVehicleUtil';

function PAVehicleCoveragesPage(props) {
    const {
        wizardData: submissionVM,
        initialSteps,
        currentStep,
        changeNextSteps,
        updateWizardSnapshot,
        policyChangeUpdateCoverages,
        policyChangeGetErrorsAndWarnings,
        readOnly = false,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        steps: currentWizardSteps,
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [currentRow, updateCurrentRow] = useState(null);
    const currentVehicle = useRef(null);
    const [showErrors, updateShowErrors] = useState(false);
    const [hasError, updateHasError] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);

    const coverageVMs = WniClausesUtil.getVehicleCoverageVMs(submissionVM);
    const coverageValues = WniClausesUtil.getVehicleCoverageValues(submissionVM);

    const currentVehicleCoverages = VehicleUtil
        .getCurrentVehicleCoverages(coverageVMs, currentRow); // VMListNode

    const {
        quoteID,
        sessionUUID,
        jobID,
        baseData: {
            jobType,
            effectiveDate,
            quoteFlow_Ext: quoteFlow,
        },
        lobData: {
            personalAuto: {
                isForNamedNonOwner_Ext: isQuoteForNamedNonOwner,
            }
        },
        oossliceDates_Ext: oossliceDates,
        // quoteFlow_Ext: quoteFlow
    } = submissionVM.value;

    const uiUtil = PAVehicleUtil({
        vehicleVMList: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children'),
        translator,
        coverageVMs,
        quoteFlow,
        isQuoteForNamedNonOwner,
        validVinInfo: {},
        validationIssues,
    });

    const { authHeader } = useAuthentication();
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        disregardFieldValidation,
        registerInitialComponentValidation,
    } = useValidation('VehicleCoveragesPage');

    const highLightRowFn = (activeRow) => {
        const vehicleData = _.get(submissionVM.value, 'lobData.personalAuto.coverables.vehicles');
        WniTableRowUtil.selectedTableRowByRowDOM(activeRow.publicID, vehicleData, 'vehicleCoveragesTable');
    };

    useEffect(() => {
        if (currentRow) {
            highLightRowFn(currentRow.value);
            currentVehicle.current = currentRow;
        }
    }, [currentRow]);

    const onVINCell = useCallback((items, index, property) => {
        const vin = items[property.id];
        return vin ? vin.toString()
            .substring(vin.length - 4, vin.length) : '';
    }, []);

    const onViewOrEdit = useCallback((value, index) => {
        const vehicleVM = _.get(submissionVM, `lobData.personalAuto.coverables.vehicles.children.${index}`);
        updateShowErrors(false);
        updateCurrentRow(vehicleVM);
    }, [submissionVM]);

    const onClauseUpdate = useCallback(
        async (postUpdate) => {
            const updateVehicleCoverages = _.filter(coverageValues, (vehicle) => {
                return vehicle.publicID === _.get(currentVehicle.current, 'value.publicID');
            });
            const lobName = 'personalAuto';
            const clauseType = 'vehicleCoverages';

            // As the clause is set to edited
            // VehicleClausesUtil.filterUnchangedClauses will pick it up in structureClausesForServer
            const clausesToUpdate = VehicleClausesUtil
                .structureClausesForServer(updateVehicleCoverages, lobName, clauseType);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            let response;
            switch (jobType) {
                case 'Submission': {
                    response = await WniPAQuoteService
                        .updateCoverages(quoteID || jobID, sessionUUID,
                            clausesToUpdate,
                            authHeader);
                    const pcDisplayIssues = VehicleValidationUtil.getPCDisplayIssues(response);
                    updateValidationIssues(pcDisplayIssues);
                    WniClausesUtil.setVehicleCoverageValues(newSubmissionVM,
                        response.personalAuto.vehicleCoverages);
                    WniClausesUtil.setLineCoverageValues(newSubmissionVM,
                        response.personalAuto.lineCoverages);
                    break;
                }
                case 'PolicyChange': {
                    response = await policyChangeUpdateCoverages({
                        quoteID, jobID, sessionUUID, clausesToUpdate
                    }, authHeader);
                    const newVehicleCoverages = WniClausesUtil
                        .getVehicleCoverageValuesFromResponse(response);
                    const newLineCoverages = WniClausesUtil
                        .getLineCoverageValuesFromResponse(response);
                    WniClausesUtil.setVehicleCoverageValues(newSubmissionVM, newVehicleCoverages);
                    WniClausesUtil.setLineCoverageValues(newSubmissionVM, newLineCoverages);
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.log(`Unhandled job type: ${jobType}`);
                }
            }
            const fieldsToRemoveFromValidation = VehicleClausesUtil.getRemovedClausesID(
                currentRow, currentRow, 'coverages'
            );
            disregardFieldValidation(fieldsToRemoveFromValidation);
            if (postUpdate) {
                postUpdate();
            }
            _.set(newSubmissionVM, 'value.baseData.periodStatus', 'Draft');
            updateWizardSnapshot(newSubmissionVM);
        },
        [
            coverageValues,
            viewModelService,
            submissionVM,
            jobType,
            currentRow,
            disregardFieldValidation,
            updateWizardSnapshot,
            quoteID,
            jobID,
            sessionUUID,
            authHeader,
            policyChangeUpdateCoverages
        ]
    );

    const renderViewEditDom = () => {
        return (
            <div className="activeRow wni-button-link">
                {readOnly
                    ? translator(customMessages.viewLabel)
                    : translator(customMessages.viewAndEditLabel)}
            </div>
        );
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues.concat(
                OOSUtil.getOOSSliceDatesWarning(
                    OOSUtil.getOOSSliceDatesString(
                        oossliceDates,
                        effectiveDate
                    ),
                    translator
                )
            ).concat(wizardErrorsAndWarnings),
            visible: !_.isEmpty(validationIssues)
            || wizardErrorsAndWarnings.length > 0
            || OOSUtil.getOOSSliceDatesString(
                oossliceDates,
                effectiveDate
            ).length > 0,
            issueDistinguisher: true,
            scrollToIssues: true,
        },
        vehicleIndex: {
            renderCell: (value) => {
                return value.vehicleNumber ? value.vehicleNumber : '-';
            }
        },
        type: {
            visible: isQuoteForNamedNonOwner,
            renderCell: (value) => {
                if (value.vehicleType_Ext) {
                    return translator({
                        id: `typekey.VehicleType.${value.vehicleType_Ext}`
                    });
                }
                return '-';
            }
        },
        year: {
            visible: !isQuoteForNamedNonOwner,
        },
        vin: {
            renderCell: onVINCell,
        },
        viewOrEdit: {
            label: renderViewEditDom(),
            onClick: onViewOrEdit,
            disabled: currentRow
        },
        vehicleCoveragesComponent: {
            visible: currentRow != null,
            vehicleVM: currentRow,
            onValidate,
            onClauseUpdate,
            coverages: currentVehicleCoverages,
            readOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            vehiclecoveragescomponent: VehicleCoveragesComponent,
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const hideButtonsProps = {
        showNext: !currentRow || readOnly,
        showPrevious: !currentRow || readOnly,
        showCancel: !currentRow || readOnly
    };

    const onNextVehicle = useCallback(() => {
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        const index = _.findIndex(vehicleVMs,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === vehicleVMs.length - 1) {
            updateCurrentRow(_.get(vehicleVMs, 0));
            return;
        }
        updateCurrentRow(_.get(vehicleVMs, index + 1));
    }, [currentRow, submissionVM]);

    const getFooterButtons = useCallback(() => {
        if (!currentRow) {
            return (<div />);
        }
        const vehicleVMs = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        const nextVisible = vehicleVMs.length > 1;
        return (
            <CustomFooterV2Component
                saveAndNextMessage={translator(customMessages.next)}
                handleCancel={() => {
                    if (!isComponentValid) {
                        updateShowErrors(true);
                        return;
                    }
                    updateCurrentRow(null);
                    updateHasError(false);
                }}
                handleSaveAndClose={() => {
                    if (!isComponentValid) {
                        updateShowErrors(true);
                        return;
                    }
                    updateCurrentRow(null);
                    updateHasError(false);
                }}
                handleSaveAndNext={() => {
                    if (!isComponentValid) {
                        updateShowErrors(true);
                        return;
                    }
                    onNextVehicle();
                    updateHasError(false);
                }}
                nextButtonVisible={nextVisible}
            />
        );
    }, [currentRow, isComponentValid]);

    const renderPageContent = useCallback(() => {
        return (
            <React.Fragment>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    onModelChange={updateWizardSnapshot}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
                {getFooterButtons()}
            </React.Fragment>
        );
    }, [overrideProps, resolvers, showErrors, submissionVM]);

    const checkVehicleEmpty = useCallback(
        () => {
            const vehicleSize = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
            return !_.isEmpty(vehicleSize);
        },
        [submissionVM]
    );

    useEffect(() => {
        registerInitialComponentValidation(() => checkVehicleEmpty());
    }, [registerInitialComponentValidation, checkVehicleEmpty]);

    const retrieveBackendValidationIssues = useCallback(async () => {
        switch (jobType) {
            case 'Submission': {
                return WniQuoteVehicleService.getErrorsAndWarnings(quoteID, true, authHeader);
            }
            case 'PolicyChange': {
                // eslint-disable-next-line max-len
                return policyChangeGetErrorsAndWarnings(jobID, true, authHeader);
            }
            default: {
                // eslint-disable-next-line no-console
                console.log(`Unhandled job type: ${jobType}`);
            }
        }
        return [];
    }, []);

    const checkNextSteps = useCallback(() => {
        const hideAssignmentPage = uiUtil.shouldHideAssignmentPage();
        const currentStepIndexForAllSteps = _
            .findIndex(initialSteps, (step) => step.id === currentStep.id);
        const nextSteps = initialSteps
            .slice(currentStepIndexForAllSteps + 1, initialSteps.length);

        if (hideAssignmentPage) {
            // changeNextSteps(nextSteps.filter((step) => !step.path.includes('assignment')));
            const nextWizardSteps = nextSteps.filter((step) => !step.path.includes('assignment'));
            changeNextSteps(nextWizardSteps);
        }
        // else {
        //     changeNextSteps(nextSteps);
        // }
    }, [changeNextSteps, currentStep, initialSteps, uiUtil]);

    const onPageNext = async () => {
        const errorsAndWarningsResponse = await retrieveBackendValidationIssues();
        const backendIssues = ErrorsAndWarningsUtil
            .getValidationIssues(errorsAndWarningsResponse) || [];
        const allValidationIssues = _.uniqBy(backendIssues, 'reason');
        const updatedIssues = _.differenceBy(allValidationIssues, validationIssues, 'reason');
        updateValidationIssues(allValidationIssues);
        if (!_.isEmpty(allValidationIssues.filter((n) => n.type === 'error'))) {
            updateShowErrors(true);
            updateHasError(true);
            return false;
        }
        updateHasError(false);
        // only warnings
        if (!_.isEmpty(allValidationIssues.filter((n) => n.type === 'warning'))) {
            if (!showErrors || !_.isEmpty(updatedIssues)) {
                updateShowErrors(true);
                return false;
            }
        }
        checkNextSteps();
        return submissionVM;
    };

    return (
        <WizardPage
            onNext={onPageNext}
            alwaysCallOnNext
            disableNext={hasError}
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            {...hideButtonsProps}
        >
            {renderPageContent}
        </WizardPage>
    );
}

PAVehicleCoveragesPage.propTypes = wizardProps;
export default PAVehicleCoveragesPage;
