import { defineMessages } from 'react-intl';

export default defineMessages({
    quotes: {
        id: 'wni.payment.aqQuoteTable.Quotes',
        defaultMessage: 'Quotes'
    },
    billAccount: {
        id: 'wni.payment.aqQuoteTable.Bill Account#',
        defaultMessage: 'Bill Account#'
    },
    policy: {
        id: 'wni.payment.aqQuoteTable.Policy',
        defaultMessage: 'Policy'
    },
    premium: {
        id: 'wni.payment.aqQuoteTable.Premium',
        defaultMessage: 'Premium'
    },
    fee: {
        id: 'wni.payment.aqQuoteTable.Fee',
        defaultMessage: 'Fee'
    },
    dueDayOfMonth: {
        id: 'wni.payment.aqQuoteTable.Due Day of Month',
        defaultMessage: 'Due Day of Month'
    },
    paymentPlan: {
        id: 'wni.payment.aqQuoteTable.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    paymentMethod: {
        id: 'wni.payment.aqQuoteTable.Payment Method',
        defaultMessage: 'Payment Method'
    },
    primaryPayer: {
        id: 'wni.payment.aqQuoteTable.Primary Payer',
        defaultMessage: 'Primary Payer'
    },
    downpayment: {
        id: 'wni.payment.aqQuoteTable.Down Payment',
        defaultMessage: 'Down Payment'
    },
    status: {
        id: 'wni.payment.aqQuoteTable.status',
        defaultMessage: 'status'
    },
    editBilling: {
        id: 'wni.payment.Edit Billing',
        defaultMessage: 'Edit Billing',
    },
    billingMethod: {
        id: 'wni.payment.Billing Method',
        defaultMessage: 'Billing Method',
    }
});
