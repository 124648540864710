import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imElectronicDataProcessingService'), method, data, additionalHeaders);
}

export default class IMElectronicDataProcessingService {
  
    /**
     * Invokes IMElectronicDataProcessingHandler.getElectronicDataProcessingClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getElectronicDataProcessingClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getElectronicDataProcessingClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.getElectronicDataProcessingClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getElectronicDataProcessingClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getElectronicDataProcessingClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.updateElectronicDataProcessingClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} ElectronicDataProcessingToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateElectronicDataProcessingClauses(jobNumber, sessionUUID, ElectronicDataProcessingToUpdate, authHeader = {}) {
        return processSubmission('updateElectronicDataProcessingClauses', [jobNumber, sessionUUID, ElectronicDataProcessingToUpdate], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.updateElectronicDataProcessingSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateElectronicDataProcessingSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateElectronicDataProcessingSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }
    
    static updateElectronicDataProcessing7200Schedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateElectronicDataProcessing7200Schedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }
    
    static updateElectronicDataProcessing7201Schedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateElectronicDataProcessing7201Schedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.updateElectronicDataProcessingAddiInsuredSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateElectronicDataProcessingAddiInsuredSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateElectronicDataProcessingAddiInsuredSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.onElectronicDataProcessingNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onElectronicDataProcessingNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onElectronicDataProcessingNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMElectronicDataProcessingHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }
}