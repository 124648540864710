import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { UnderwritingIssues } from 'gw-components-platform-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './QuotePage.metadata.json5';
import styles from './QuotePage.module.scss';
import './QuotePage.messages';

const BLOCKS_QUOTES = [UnderwritingIssues.BLOCKS_QUOTE, UnderwritingIssues.BLOCKS_QUOTE_RELEASE];

function QuotePage(props) {
    const {
        wizardData: submissionVM, steps, jumpTo, underwritingIssues
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const isAnonymousPersona = config.persona === 'anonymous';
    const [quoteBlocked, isQuoteBlocked] = useState(false);
    const [offeredQuotes, setOfferedQuotes] = useState();

    useEffect(() => {
        setOfferedQuotes(_.get(submissionVM.value, 'quoteData.offeredQuotes'));

        const isBlocked = underwritingIssues.some(
            (uwIssue) => uwIssue.currentBlockingPoint !== UnderwritingIssues.NON_BLOCKING
        );

        isQuoteBlocked(isBlocked || offeredQuotes === undefined);
    }, [offeredQuotes, submissionVM, underwritingIssues]);

    const handleNext = useCallback(() => {

        _.set(submissionVM, 'bindData.chosenQuote', offeredQuotes[0].publicID);
        return submissionVM;
    }, [submissionVM, offeredQuotes]);

    const handlePrint = () => {
        window.print();
    };

    const findBlockingPoint = useCallback((type) => {
        return underwritingIssues.some((offeringIssue) => {
            if (type === UnderwritingIssues.BLOCKS_BIND) {
                return offeringIssue.currentBlockingPoint === UnderwritingIssues.BLOCKS_BIND;
            }
            return _.includes(BLOCKS_QUOTES, offeringIssue.currentBlockingPoint);
        });
    }, [underwritingIssues]);

    const navigateToCoverages = useCallback(() => {
        const indexOfGeneralCoverages = _.findIndex(
            steps,
            ({ path }) => path === '/general-coverages'
        );
        jumpTo(indexOfGeneralCoverages);
    }, [jumpTo, steps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            navigateToCoverages: navigateToCoverages,
            onPrint: handlePrint
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        startDate: {
            visible: isAnonymousPersona
        },
        printPage: {
            visible: !isAnonymousPersona
        },
        quoteBox: {
            visible: offeredQuotes !== undefined
        },
        underWritingIssues: {
            underwritingIssues: underwritingIssues,
            blockQuote: findBlockingPoint(),
            quoteID: _.get(submissionVM, 'quoteID.value'),
            filterUWIssuesInCustomOffering: false
        },
        policyPeriodDateRange: {
            value: {
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value'),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value')
            }
        }
    };

    return (
        <WizardPage
            onNext={handleNext}
            disableNext={quoteBlocked}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
