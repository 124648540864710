import { defineMessages } from 'react-intl';

export default defineMessages({
    glViewAndEditLabel: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.View/Edit',
        defaultMessage: 'View/Edit'
    },
    glViewLabel: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.View',
        defaultMessage: 'View'
    },
    removeLocationTitle: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.Remove Location?',
        defaultMessage: 'Remove Location?'
    },
    removeLocationDescription: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.removeLocationDescription',
        defaultMessage: 'Are you sure you want to remove this location from the list of locations?'
    },
    Ok: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.OK',
        defaultMessage: 'OK'
    },
    Cancel: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.Cancel',
        defaultMessage: 'Cancel'
    },
    transferErrorMessage: {
        id: 'wni.quoteandbind.gl-wizard.gl-locations.This is not an eligible location. Please update the coverage subline on the Locations screen to make it an eligible location.',
        defaultMessage: 'This is not an eligible location. Please update the coverage subline on the Locations screen to make it an eligible location.',
    },
});
