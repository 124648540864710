import { defineMessages } from 'react-intl';

export default defineMessages({
    // producerCodeNotLicensed: {
    //     id: 'quoteandbind.views.quote-details.is producer license valid',
    //     defaultMessage: 'Selected producer does not have a license in this state. A license is required to issue. Please update license details or select another producer.'
    // },
    clueMvrCompareNeeded: {
        id: 'quoteandbind.views.quote-details.CLUE/MVR Compare needed',
        defaultMessage: 'CLUE/MV Compare needed'
    },
    // referToUnderwriter: {
    //     id: 'quoteandbind.views.quote-details.Refer to Underwriter',
    //     defaultMessage: 'Refer to Underwriter',
    // },
    errorSendingJobForReview: {
        id: 'quoteandbind.views.quote-details.gateway.views.job-uwissues.There was an error sending the job for review',
        defaultMessage: 'There was an error sending the job for review.'
    },
    printQuoteSummary: {
        id: 'quoteandbind.views.quote-details.Print Quote Summary',
        defaultMessage: 'Print Quote Summary'
    },
    addSxsPair: {
        id: 'quoteandbind.views.quote-details.Add Side-By-Side Version',
        defaultMessage: 'Add Side-By-Side Version'
    },
    // referralSubmitted: {
    //     id: 'quoteandbind.views.quote-details.The referral has been submitted. Please wait on the Underwriter&quot;s response.',
    //     defaultMessage: 'The referral has been submitted. Please wait on the Underwriter\'s response.'
    // },
    liabilityLimits: {
        id: 'quoteandbind.views.quote-details.Liability Limits',
        defaultMessage: 'Liability Limits'
    },
    vehicleSpecificCoverage: {
        id: 'custom.quoteandbind.pa.directives.MultiQuoteView.Vehicle Specific Coverages',
        defaultMessage: 'Vehicle Specific Coverages',
    },
    otherCoverages: {
        id: 'quoteandbind.views.quote-details.Other Coverages',
        defaultMessage: 'Other Coverages'
    },
    paTogglePricingView: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Toggle Pricing View: ',
        defaultMessage: 'Toggle Pricing View: '
    },
    paymentToggleInstallments: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Pay in Installments',
        defaultMessage: 'Pay In Installments',
    },
    submitTheApplication: {
        id: 'quoteandbind.views.quote-details.submit the application',
        defaultMessage: 'In order to submit the application, the following information must first be resolved:'
    },
    reviewWarningMessages: {
        id: 'quoteandbind.views.quote-details.Review the following item(s) before submitting:',
        defaultMessage: 'Review the following item(s) before submitting:'
    },
    // resolveErrors: {
    //     id: 'quoteandbind.views.quote-details.Resolve Errors',
    //     defaultMessage: 'Resolve Errors'
    // },
    markQuoteAsNotTaken: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Mark Quote as "Not Taken"',
        defaultMessage: 'Mark Quote as "Not Taken"'
    },
    activity19Subject: {
        id: 'quoteandbind.views.quote-details.Agent Risk Reservation Referral',
        defaultMessage: 'Agent Risk Reservation Referral'
    },
    activity19Desc: {
        id: 'quoteandbind.views.quote-details.Activity Description',
        defaultMessage: 'Quote submitted by agent has other matching accounts. Please review risk reservation in Risk Analysis screen and note decision for Underwriter'
    },

    activitySubject: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Lost Policy Release LPR',
        defaultMessage: 'Lost Policy Release LPR'
    },
    activityDesc: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Matching risk reservation account approved by Underwriter. Requires Lost Policy Letter',
        defaultMessage: 'Matching risk reservation account approved by Underwriter. Requires Lost Policy Letter'
    },
    deleteSideBySidePairTitle: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deletePair.Confirm Delete this Quote Version',
        defaultMessage: 'Confirm Delete this Quote Version'
    },
    // deleteSideBySidePairMessage: {
    //     id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deletePair.Withdrawing will withdraw both versions of the Select/Signature quotes. To withdraw both, select "Withdraw". Otherwise, select "Cancel".',
    //     defaultMessage: 'Withdrawing will withdraw both versions of the Select/Signature quotes. To withdraw both, select "Withdraw". Otherwise, select "Cancel".'
    // },
    deleteSideBySideNamedNonOwnerMessage: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deletePair.Deleting will delete the version you selected. To delete it, select "Delete". To proceed with one of the quotes click "Cancel" and then select the appropriate quote.',
        defaultMessage: 'Deleting will delete the version you selected. To delete it, select "Delete". To proceed with one of the quotes click "Cancel" and then select the appropriate quote.'
    },
    deleteSideBySidePairMessage: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deletePair.Deleting will delete both versions of the Select/Signature quotes. To delete both, select "Delete". To proceed with one of the quotes click "Cancel" and then select the appropriate quote.',
        defaultMessage: 'Deleting will delete both versions of the Select/Signature quotes. To delete both, select "Delete". To proceed with one of the quotes click "Cancel" and then select the appropriate quote.'
    },
    deleteSideBySidePairConfirm: {
        id: 'custom.quoteandbind.common.directives.CommonOfferingSelection.deletePair.Delete',
        defaultMessage: 'Delete'
    },
    fileNotFound: {
        id: 'custom.quoteandbind.common.sidebysidesummary.File Not Found',
        defaultMessage: 'File Not Found'
    },
    documentNotFound: {
        id: 'custom.quoteandbind.common.sidebysidesummary.Personal Auto Side By Side Quote Summary Not Found',
        defaultMessage: 'Document "Personal Auto Side By Side Quote Summary" Not Found'
    },
    ok: {
        id: 'custom.quoteandbind.common.sidebysidesummary.OK',
        defaultMessage: 'OK'
    },
    noSxsDataMessage: {
        id: 'custom.quoteandbind.common.sidebysidesummary.There is an issue with the quote. Please try again later or call your Underwriter.',
        defaultMessage: 'There is an issue with the quote. Please try again later or call your Underwriter.'
    },
});
