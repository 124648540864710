import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WCStateInfoService } from 'wni-capability-quoteandbind-wc';

import metadata from './WCRemoveSplitPeriodPopup.metadata.json5';

import { Button } from '@jutro/legacy/components';

function WCRemoveSplitPeriodPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        submissionVM,
        messages,
        isReadOnly,
        viewModelService,
        translator,
        cancelBtnLabel,
        actionBtnLabel,
        useDependenciesData,
        useAuthenticationData
    } = props;

    const {
        jobID, 
        sessionUUID
    } = submissionVM.value;

    const {setLoadingMask } = useDependenciesData.loadingMask

    const [showErrors, updateShowErrors] = useState(false);
    const [stateSpecificData, updateStateSpecificData] = useState([]);
    const [splitPeriodData, updateSplitPeriodData] = useState([]);
    const [selection, updateSelection] = useState([]);
    const [selecedStateId, updateSelecedStateId] = useState();

    const stateSpecificsVMPath = `lobData.workersComp.coverables.stateSpecificInfo.stateSpecifics`;

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid
    } = useValidation('WCRemoveSplitPeriodPopupPage');

    useEffect(() => {
        setTimeout(() => {
            setRowIdPath();
        })
    }, [submissionVM.value]);

    const setRowIdPath = useCallback(() => {
        const stateSpecifics = _.get(submissionVM, `${stateSpecificsVMPath}.value`);
        stateSpecifics.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateStateSpecificData(stateSpecifics);
        handleStateRowClick(stateSpecifics[0])
    }, [submissionVM]);

    const handleSave = async () => {
        setLoadingMask(true);
        const res = await WCStateInfoService.removeSplitPeriod(jobID, sessionUUID, selecedStateId, selection, useAuthenticationData.authHeader);
        onResolve(res);
        setLoadingMask(false);
    }

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'stateTable');
    };

    const renderStateCell = (data, index, {path, typeKey}) => {
        const formatValue = data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-';
        const dom = <span className='text-breakAll'>{formatValue}</span>
        return WniTableRowUtil.renderCell(data.publicID, dom)
    };

    const handleStateRowClick = (split) => {
        highlightRowFn(split);
        const splitPeriodArray = split.splitPeriods;
        splitPeriodArray.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateSplitPeriodData(splitPeriodArray);
        updateSelecedStateId(split.publicID)
    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        stateTable: {
            data: stateSpecificData,
            rowIdPath: 'rowIdPath'
        },
        splitPeriodTable: {
            data: splitPeriodData,
            onSelectionChange: (rows) => {
                updateSelection(rows)
            },
            rowIdPath: 'rowIdPath'
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderStateCell,
            onRowClick: (value, index) => handleStateRowClick(value, index),
        },
        resolveComponentMap: {
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="wcSplitPeriodPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

WCRemoveSplitPeriodPopup.defaultProps = {
    size: 'md',
};

export default WCRemoveSplitPeriodPopup;

