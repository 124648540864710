import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ModalNext,
    ModalHeader,
    ModalBody
} from '@jutro/components';

function InvoiceCloudPopup(props) {
    const {
        icTitle,
        icUrl,
        isOpen,
        onReject,
        size
    } = props;

    window.addEventListener('message', handleIframeMessages, false);
  
    function handleIframeMessages(event) {
        console.log(event, event.data)
    //   if (event.origin !== "https://<base-url>") { return; }
       // event.data will hold the specific Window Events code emitted 
       switch (event.data) {
         case "IC_SCHEDULED_PAYMENTS_CLOSED": 
           console.log("SCHEDULED PAYMENTS IFRAME CLOSE EVENT");
           break;
        case 'IC_PAY_INVOICE_UNAUTHORIZED':
           // This event triggers when a user tries to access the embedded resource and is not authenticated with the necessary company credentials. 
           // It ensures secure content access by verifying user login status.
           console.log("IC_PAY_INVOICE_UNAUTHORIZED");  
           break;
        case 'IC_PAY_INVOICE_FORBIDDEN':
           // This event occurs when a user, although authenticated, attempts to access an embedded resource for which they do not have the appropriate permissions 
           // or access rights.
           console.log("IC_PAY_INVOICE_FORBIDDEN");
           break;
        case 'IC_PAY_INVOICE_OPENED':
           // This event is triggered when an embedded resource has successfully loaded, indicating that the user has gained access and the content is now being displayed.
           console.log("IC_PAY_INVOICE_OPENED");
           break;
        case 'IC_PAY_INVOICE_CLOSED':
           // This event is triggered when the embedded iframe is closed.
           console.log("IC_PAY_INVOICE_CLOSED");
           break;
        case 'IC_PAY_INVOICE_PAID':
           // This event is triggered when the user has completed a payment. 
           console.log("IC_PAY_INVOICE_PAID");
           break;
        case 'IC_PAY_INVOICE_CANCELED':
           // This event is triggered when a user closes the embedded iframe.
           console.log("IC_PAY_INVOICE_CANCELED");
           break;
        default: break;
       }    
    }
    

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader onClose={onReject} />
            <ModalBody className="withFullBody">
                <iframe
                    id="invoiceCloudPopup"
                    title={icTitle}
                    width="100%"
                    height="100%"
                    src={icUrl}
                />
            </ModalBody>
        </ModalNext>
    );
}

InvoiceCloudPopup.propTypes = {
    icTitle: PropTypes.string.isRequired,
    icUrl: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    size: PropTypes.string,
};

InvoiceCloudPopup.defaultProps = {
    size: 'full',
};

export default InvoiceCloudPopup;
