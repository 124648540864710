import { DropdownMenuButton, useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm,ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React,{ useContext, useState, useEffect } from 'react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { TableRowUtil } from 'wni-portals-util-react';

import { getFullAddressDisplayName } from '../../../Incidents/util/AddressUtil';
import metadata from './Contact.metadata.json5';
import ContactDetails from './ContactDetails';
import messages from '../../FNOLPAVehiclesPage.messages';

import { PhoneNumberField } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

const CONTACT_DTO = 'wni.edge.capabilities.claim.contact.dto.WniContactDTO';
const xCenter = 'cc';

function Contact(props) {
    const modalApi = useModal();
    const {
        id: pageId,
        model,
        basePath,
        dataPath,
        contactRole,
        tableData = [],
        title,
        buttonText,
        onValidate = _.noop,
        writeValue = _.noop,
        tableConfig = {},
        selection,
        onSelectionChange,
        showPerson
        // VehicleService,
        // claimNumber,
        // authHeader
    } = props;

    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const {selectionType} = tableConfig;
    const [contactVM, updateContactVM] = useState(null);
    const allDataVM = _.get(model, dataPath);
    const allData = _.get(allDataVM, 'value', []);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        TableRowUtil.setTablePublicIDSelected(activePublicID, pageId);
    };

    useEffect(() => {
        highlightRowFn(contactVM);
    }, [contactVM]);

    const handleSelectionChange = (ids) => {
        onSelectionChange(ids, basePath);
    };
    const addContact = () => {
        const initData = {
            rowIdPath: `cc:${TableRowUtil.getUuid()}`,
            primaryAddress: { 
                country: 'US'
            },
            subtype: "Person",
            isSave: true
        }
        const initVM = viewModelService.create(initData, xCenter, CONTACT_DTO);
        updateContactVM(initVM);
    };
    const editContact = (item) => {
        const initData = {
            ...item,
            primaryAddress: item.primaryAddress || { country: 'US' },
            isSave: true
        }
        const initVM = viewModelService.create(initData, xCenter, CONTACT_DTO);
        updateContactVM(initVM);
    };

    const removeContact = (item) => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline',
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return false;
            }
           
            setLoadingMask(true);
            // if(item.publicID) {
            //     const res = await VehicleService.removeContact(
            //         claimNumber,
            //         item.publicID,
            //         contactRole,
            //         authHeader
            //     );
            //     syncVehicleData(res);
            // }
            
            setLoadingMask(false);
            const newData = tableData.filter((contact) => contact.rowIdPath !== item.rowIdPath)
            writeValue(newData, dataPath);
            return true;
        }, _.noop);
    };

    const renderPhoneCell = (item) => {
        const phoneNumber = item.cellNumber || item.workNumber || item.homeNumber;
        return <PhoneNumberField value={phoneNumber} readOnly />
    };

    const renderActionCell = (item, index) => {
        if (item.policySystemId) {
            return null;
        }
        const dom = (
            <DropdownMenuButton
                icon="gw-expand-more"
                id="dropdownMenuButton"
                className="dropDownMenuIconbtn"
                menuClassName="dropDownMenuList"
                alignRight
            >
                <DropdownMenuLink icon="gw-edit" onClick={() => editContact(item)}>Edit</DropdownMenuLink>
                <DropdownMenuLink icon="gw-delete" onClick={() => removeContact(item)}>Remove</DropdownMenuLink>
            </DropdownMenuButton>
        );
        return TableRowUtil.renderCell(item.rowIdPath, dom);
    };


    const onValueChange = (value, path) => {
        if(contactVM) {
            const newVM = viewModelService.clone(contactVM);
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(newVM.value, path, undefined);
            } else {
                _.set(newVM.value, path, value);
            }
            updateContactVM(newVM);
        }
    };

    const generateVM = (vm) => {
        const newVM = viewModelService.clone(vm);
        const contactAddress = _.get(newVM.value, 'primaryAddress', {});
        const newContactAddress = _.omit(contactAddress, 'country'); // country not be checked
        const isAddressEmpty = _.every(newContactAddress, _.isEmpty);
        if(isAddressEmpty) {
            _.set(newVM.value, 'primaryAddress', null);
        }
        return newVM
    };

    const getPersonAvailablePersons = () => {
        const contacts = _.filter(allData, contact => !contact.policySystemId)
        const availablePersons = _.map(contacts, (person) => {
            return {
                code: person.rowIdPath,
                name: person.displayName || `${_.get(person, 'firstName', '')} ${_.get(person, 'lastName', '')}`
            }
        })
        return availablePersons.concat({code: 'newPerson', name: 'New Person'})
    }

    const handlePersonChange = (contactId) => {
        const currentContact = allData.find((item) => item.rowIdPath === contactId);

        const initData = {
            ...currentContact,
            rowIdPath: currentContact?.rowIdPath || `cc:${TableRowUtil.getUuid()}`,
            subtype: currentContact?.subtype || "Person",
            primaryAddress: currentContact?.primaryAddress || { country: 'US' },
            isSave: true,

        }
        const initVM = viewModelService.create(initData, xCenter, CONTACT_DTO);
        updateContactVM(initVM);
    }

    const renderAddressCell = (item) => {
        const primaryAddress = _.get(item, 'primaryAddress');
        if (_.isEmpty(primaryAddress)) {
            return '-';
        }
        return getFullAddressDisplayName(_.get(item, 'primaryAddress'));
    }

    const save = () => {
        const currentVM = generateVM(contactVM);
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const findCurrentContact = allData.find((item) => item.rowIdPath === rowIdPath)
        if(findCurrentContact) { // edit existing contact
            const currentIndex = allData.findIndex((item) => item.rowIdPath === rowIdPath);
            const newModel = viewModelService.clone(model);
            _.set(newModel.value, `${dataPath}[${currentIndex}]`, currentVM.value);
            const newDataVM = _.get(newModel, dataPath)
            writeValue(newDataVM.value, dataPath);
            updateContactVM(null);
            return false;
        }

        // add new contact
        allDataVM.pushElement(currentVM);
        writeValue(allDataVM.value, dataPath);
        updateContactVM(null);
    };

    const cancel = () => {
        updateContactVM(null);
    };

    const overrides = {
        contactCard:{
            title
        },
        contactTable: {
            id: pageId,
            data: allData,
            selectedRows: selection,
            onSelectionChange: handleSelectionChange,
            ...tableConfig
        },
        addButton: {
            content: buttonText,
            disabled: contactVM
        },
        contactDetails: {
            visible: !!contactVM,
            contactVM: contactVM,
            writeValue: onValueChange,
            save,
            cancel,
            showPerson,
            availablePersons: getPersonAvailablePersons(),
            handlePersonChange
        }
    };
    const resolvers = {
        resolveComponentMap: {
            contactdetails: ContactDetails
        },
        resolveCallbackMap: {
            renderPhoneCell,
            renderActionCell,
            addContact,
            renderAddressCell
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            tableData,
            id,
            path,
            overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={tableData}
            overrideProps={overrides}
            onValueChange={onValueChange}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default Contact;
