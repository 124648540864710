import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateUtil } from 'wni-portals-util-js'

import metadata from './PoliciesDriversTable.metadata.json5';

function PoliciesDriversTable(props) {
    const { model } = props;

    const drivers = _.get(model, 'lobs.personalAuto.driverDTOs');

    const [coveredDrivers, updateCoveredDrivers] = useState([]);

    useEffect(() => {
        const res = _.filter(drivers, (driver) => driver.driverCovered_Ext);
        updateCoveredDrivers(res);
    }, []);

	const onDateOfBirthCell = (item) => {
		// eslint-disable-next-line no-param-reassign
		item.dateOfBirth = item.dateOfBirth || item.person.dateOfBirth || undefined;
		if (!item.dateOfBirth) {
			return '';
		}
		if (item.initIsADPDOB_Ext) {
			return '**';
		}
		return WniDateUtil.formatDateWithPattern(item.dateOfBirth);
	}

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            driverTableContainer: {
                data: coveredDrivers
            },
			dateOfBirth: { renderCell: onDateOfBirthCell }
        };
    }, [coveredDrivers]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={model}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PoliciesDriversTable.propTypes = {
    model: PropTypes.shape({}),
};
PoliciesDriversTable.defaultProps = {
    model: {},
};
export default PoliciesDriversTable;
