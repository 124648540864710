import { defineMessages } from 'react-intl';

export default defineMessages({
    walMVRDriverName: {
        id: 'wal.quote.views.incident.mvr.userAdd.Driver Name',
        defaultMessage: 'Driver'
    },
    walMVRReportDriverName: {
        id: 'wal.quote.views.incident.mvr.report.Driver Name',
        defaultMessage: 'Driver Name'
    },
    walIncidentDate: {
        id: 'wal.quote.views.incidentsAndReports.Incident Date',
        defaultMessage: 'Incident Date'
    },
    walDescription: {
        id: 'wal.quote.views.incidentsAndReports.Description',
        defaultMessage: 'Description'
    },
    walSurchargeable: {
        id: 'wal.quote.views.incidentsAndReports.Surchargeable',
        defaultMessage: 'Surchargeable'
    },
    walSurChargeApplied: {
        id: 'wal.quote.views.incidentsAndReports.SurchargeApplied',
        defaultMessage: 'Surcharge Applied'
    },
    walDispute: {
        id: 'wal.quote.views.incident.mvr.userAdd.Dispute',
        defaultMessage: 'Dispute'
    },
    walMVRDisputeTooltip: {
        id: 'wal.quote.views.incidentsAndReports.MVR.dispute tooltip',
        defaultMessage: 'If this incident does not apply, select the appropriate reason for an underwriter to review',
    },
    walExplanation: {
        id: 'wal.quote.views.incident.mvr.userAdd.Explanation',
        defaultMessage: 'Explanation'
    },
    walUnderWritingResponse: {
        id: 'wal.quote.views.incident.mvr.userAdd.UnderWritingResponse',
        defaultMessage: 'Underwriting Response'
    },
    walSource: {
        id: 'wal.quote.views.clue.source',
        defaultMessage: 'Source'
    },
    ModalOk: {
        id: 'wal.quote.views.modal.OK',
        defaultMessage: 'OK',
    },
    ModalCancel: {
        id: 'wal.quote.views.modal.Cancel',
        defaultMessage: 'Cancel',
    },
    ModaldeleteMsg: {
        id: 'wal.quote.views.modal.Are you confirm to delete this item ?',
        defaultMessage: 'Are you confirm to delete this item ?'
    },
    walAmountPaid: {
        id: 'wal.quote.views.prior loss.userAdd.Amount Paid',
        defaultMessage: 'Amount Paid'
    },
    walClueDriver: {
        id: 'wal.quote.views.clue.Clue Driver',
        defaultMessage: 'CLUE Driver'
    },
    walPolicyDriver: {
        id: 'wal.quote.views.clue.Policy Driver',
        defaultMessage: 'Policy Driver'
    },
    walDriver: {
        id: 'wal.quote.views.clue.Driver',
        defaultMessage: 'Driver'
    },
    walCategory: {
        id: 'wal.quote.views.clue.category',
        defaultMessage: 'Category'
    },
    walStatus: {
        id: 'wal.quote.views.priorcarrier.walStatus',
        defaultMessage: 'Status'
    },
    DriverHousehold: {
        id: 'wal.quote.incident.Driver no longer in household',
        defaultMessage: 'Driver no longer in Household'
    },
    NotApplicable: {
        id: 'wal.quote.incident.views.Not applicable',
        defaultMessage: 'Not Applicable'
    },
    PermissiveDriver: {
        id: 'wal.quote.incident.views.Permissive Driver',
        defaultMessage: 'Permissive Driver'
    },
    Unoccupied: {
        id: 'wal.quoteincident.views.Unoccupied',
        defaultMessage: 'Unoccupied'
    },
    AddPriorLossesEntry: {
        id: 'wal.quote.views.prior.losses entry.Add Prior Losses Entry',
        defaultMessage: 'Add Prior Loss Entry'
    },
    EditPriorLossesEntry: {
        id: 'wal.quote.views.prior.losses entry.Edit Prior Losses Entry',
        defaultMessage: 'Edit Prior Loss Entry'
    },
    ViewPriorLossesEntry: {
        id: 'wal.quote.views.prior.losses entry.View Prior Losses Entry',
        defaultMessage: 'View Prior Loss Entry'
    },
    ViewPriorLossesVendor: {
        id: 'wal.quote.views.prior.losses entry.View Prior Losses Vendor',
        defaultMessage: 'View Prior Loss Reports'
    },
    EditPriorLossesVendor: {
        id: 'wal.quote.views.prior.losses entry.Edit Prior Losses Vendor',
        defaultMessage: 'Edit Prior Loss Reports'
    },
    CreditAuth1: {
        id: 'wal.quote.title.CreditAuth1',
        defaultMessage: 'Policy premium is calculated in part based on information obtained from consumer reports. Agents are required to inform customers that they will be requesting information from consumer reports as part of the quoting process.'
    },
    CreditAuth2: {
        id: 'wal.quote.title.CreditAuth2',
        defaultMessage: 'Please note that reports will be run during the Run Validation step. To ensure accuracy of the quote, please enter losses and violations manually. For a more accurate quote, please enter all incident information from the last 5 years below:'
    },
});
