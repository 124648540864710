import messages from './HOLossHistoryAndPriorCarrierPage.messages'

export default {
    userPriorCarrier: {
        title: 'Prior Carrier Entry',
        labelMap: {
            carrier_Ext: {
                label: messages.hoCarrier,
                isTypeAlphabet: true,
                linkage: [
                    { linkPath: 'otherCarrierName_Ext', linkCode: 'Other' },
                    { linkPath: 'reasonForNoInsurance_Ext', linkCode: 'NoPrior' },
                ],
                noPriorLinkages: [
                    { linkPath: 'expirationDate', linkCode: 'NoPrior' },
                    { linkPath: 'timeWithPriorCarrier_Ext', linkCode: 'NoPrior' },
                    { linkPath: 'lapse_Ext', linkCode: 'NoPrior' },
                ]
            },
            otherCarrierName_Ext: {
                unEditable: false,
                label: messages.hoOtherCarrierName,
                unVisible: true,
            },
            expirationDate: { label: messages.hoCancelledExpirationDate },
            timeWithPriorCarrier_Ext: { label: messages.hoTimewithPriorCarrier },
            reasonForNoInsurance_Ext: { label: messages.hoReason, unVisible: true },
            lapse_Ext: {
                unEditable: false,
                disabled: true,
                label: messages.hoLapse,
                radioValues: [
                    {
                        code: 'true',
                        displayName: {
                            id: 'quote.clur.userAdd.Yes',
                            defaultMessage: 'Yes'
                        },
                    },
                    {
                        code: 'false',
                        displayName: {
                            id: 'quote.clur.userAdd.No',
                            defaultMessage: 'No'
                        }
                    }
                ]
            }
        },
        dtoPath:'wni.edge.capabilities.quote.lob.homeowners.dto.HOPriorCarrierManuallyDTO'
    },
    userClue: {
        title: 'Prior Loss Entry',
        labelMap: {
            claimDate: {label: messages.hoClaimDate},
            causeOfLoss: {
                label: messages.hoCauseOfLoss, 
                refreshCategoryPath: 'clueLossCategory',
                refreshWhatCauseOfLossPath: 'whatCauseOfLoss',
                // linkage: [
                //     { linkPath: 'whatCauseOfLoss', linkCode: ['Fire', 'WaterDamage'] },
                // ],
            },
            clueLossCategory: { label: messages.hoCategory,disabled: true, unEditable: true,},
            amountPaid: { label: messages.hoAmountpaid },
            status: { label: messages.userCLUEStatus, initialFiled: true, initialFilterValues: [{ code: 'Open', name: 'Open' }, { code: 'Closed', name: 'Closed' }, { code: 'Subrogated', name: 'Subrogated' }] },
            riskAddress: { label: messages.hoRiskAddress },
            whatCauseOfLoss: {
                label: messages.hoWhatCauseOfLoss, 
                unVisible: false,
            },
            mitigationSteps: {label: messages.hoMitigationSteps, unVisible: false},
            chargeable: { label: messages.hoSurchargeable, checkvisible: true, disabled: true },
            surChargeApply: { label: messages.hoSurChargeApplied, checkvisible: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.homeowners.dto.HOCLUEManuallyDTO'
    },
    vendorClue: {
        title: 'Prior Loss Reports',
        labelMap: {
            claimDate: {unEditable: true, label: messages.hoClaimDate},
            causeOfLoss: {
                unEditable: true, 
                label: messages.hoCauseOfLoss,
                unVisible: true
            },
            clueLossCategory: {unEditable: true, label: messages.hoCategory},
            policyholderName: {unEditable: true, label: messages.hoCLUEPolicyholderName},
            policyTypeCompany: {unEditable: true, label: messages.hoPolicyTypeCompany},
            amountPaid: { unEditable: true, label: messages.hoAmountpaid },
            status: { unEditable: true, label: messages.hoStatus },
            locationOfLoss: {unEditable: true, label: messages.hoLocationOfLoss},
            address: { unEditable: true, label: messages.hoAddress},
            whatCauseOfLoss: {
                label: messages.hoWhatCauseOfLoss, 
                unVisible: false
            },
            mitigationSteps: {label: messages.hoMitigationSteps, unVisible: false},
            chargeable: { label: messages.hoSurchargeable, checkvisible: true, disabled: true },
            surChargeApply: { label: messages.hoSurChargeApplied, checkvisible: true, disabled: true },
            dispute: {
                unEditable: false,
                label: messages.hoDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'disputeother' },
                tooltip: { text: messages.hoDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.hoExplaination },
            underwritingResponse: { unEditable: true, label: messages.hoUnderwritingResponse }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.homeowners.dto.HOCLUEReportDTO'
    }
};
