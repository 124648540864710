
import React, {
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WniChangeConflict } from 'wni-capability-gateway';
import { RTMultiQuoteChangeService } from 'wni-capability-policychange-rt';

import { RTIncidentsAndReportsPage } from 'wni-capability-quoteandbind-rt-react';
import WALIncidentsAndReportsUtil from "wni-capability-quoteandbind-wal-react/pages/IncidentsAndReports/WALIncidentsAndReportsUtil";

function RTIncidentsAndReportsChangePage(props) {

    const useAuthenticationData = useAuthentication();
    const { authHeader } = useAuthenticationData;
    const modalApi = useWniModal();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const {
        wizardData: submissionVM,
        updateWizardPageData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus,
        },
        lobData: {
            roadTrail: {
                incidentsAndReports_Ext: {
                    clueenter = [],
                    mvrenter = [],
                    isTermBasedOnRenewal
                },
                coverables: {
                    operators
                },
            },
        }
    } = submissionVM.value;

    const [newlyAddedOperators, updateNewlyAddedOperators] = useState([]);
    const [disableAddButton, updateDisableAddButton] = useState(true);
    // PCN we need to filter duplicate manually clue/mvr
    const [filteredManualCLUE, updateFilteredManualCLUE] = useState([]);
    const [filteredManualMVR, updateFilteredManualMVR] = useState([]);

    useEffect(() => {
        // if toperator basedOnFlag is false, it represent the operator is newly added
        const filteredOperators = operators.filter((operator) => !operator.basedOnFlag);
        updateNewlyAddedOperators(filteredOperators);
        // if we have new driver data in the policy change then the manual enter add btn is enable.
        if (filteredOperators.length > 0){
            updateDisableAddButton(false);
        } else {
            updateDisableAddButton(true);
        }
        const filteredCLUE = WALIncidentsAndReportsUtil.filterCLUEManuallyData(clueenter);
        const filteredMVR = WALIncidentsAndReportsUtil.filterMVRManuallyData(mvrenter);
        updateFilteredManualCLUE(filteredCLUE);
        updateFilteredManualMVR(filteredMVR);
    }, [clueenter, mvrenter, operators]) ;

    const hideOrDisableIcons = (item, handleType) => {
        // For PCN, hide old incidents edit/delete icon, disable old reports edit/delete icon.
        // 'basedOnFlag is false' represents current item is newly added in current transcation
        const iconFlag = {hideEditDeleteIcon: false, disableReportsEditIcon: false};
        if (handleType === 'userClue' || handleType === 'userMVR') {
            if (item.basedOnFlag) {
                iconFlag.hideEditDeleteIcon = true;
            } else {
                iconFlag.hideEditDeleteIcon = false;
            }
        };
        if (handleType === 'vendorClue' || handleType === 'vendorMVR') {
            if (item.basedOnFlag) {
                iconFlag.disableReportsEditIcon = true;
            } else {
                iconFlag.disableReportsEditIcon = false;
            }
        }
        return iconFlag
    }

    const showChangeConflictsModal = useCallback(
        (modalVM) => {
            const baseVM = modalVM.baseData;
            const policyNumber = _.get(modalVM.value, 'changeData.policyNumber');
            const componentProps = {
                title: 'Change Conflicts',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'Submit',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                authHeader: authHeader,
                baseVM,
                jobID,
                policyNumber,
            };
            return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
        },
        [authHeader, jobID]
    );

    const goToNext = async () => {
        if (periodStatus === 'Draft') {
            updateWizardPageData({
                [WizardConstants.sideBySideData]: undefined,
                [WizardConstants.policyDiffData]: undefined,
                [WizardConstants.policyChangeDocumentsData]: undefined,
            });
            // submissionVM.value = await RTMultiQuoteService.processQuote(
            setLoadingMask(true)
            submissionVM.value = await RTMultiQuoteChangeService.processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM;
    };

    const handleConflict = async () => {
        const conflictRes = await WniChangeConflict.getChangeConflictData(submissionVM.jobID.value, authHeader);
        if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
            setLoadingMask(false)
            const newSubmissionVM = await showChangeConflictsModal(submissionVM)
                .then(goToNext)
                .catch(() => {
                    _.noop();
                    return false;
                });
            return newSubmissionVM;
        }
        return goToNext();
    }

    const onChangePageNext = async () => {
        return handleConflict()
    }

    return (
        <RTIncidentsAndReportsPage {...props}
            productName = 'roadTrail'
            disableAddButton={disableAddButton}
            newlyAddedOperators={newlyAddedOperators}
            hideOrDisableIcons={hideOrDisableIcons}
            filteredManualCLUE={filteredManualCLUE}
            filteredManualMVR={filteredManualMVR}
            showPolicyClaims={isTermBasedOnRenewal}
            onIncidentsPageNext={onChangePageNext}
        />
    );

}

RTIncidentsAndReportsChangePage.propTypes = RTIncidentsAndReportsPage.propTypes;
RTIncidentsAndReportsChangePage.defaultProps = RTIncidentsAndReportsPage.defaultProps;

export default RTIncidentsAndReportsChangePage;