import { defineMessages } from 'react-intl';

export default defineMessages({
    underwritingIssues: {
        id: 'gateway.views.cancellation-documents.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    longDescription: {
        id: 'gateway.views.endorsement-documents.Long Description',
        defaultMessage: 'Long Description'
    },
    shortDescription: {
        id: 'gateway.views.endorsement-documents.Short Description',
        defaultMessage: 'Short Description'
    },
    noOpenUnderwritingSubmission: {
        id: 'gateway.views.job-uwissues.There are no underwriting issues associated with this.Submission',
        defaultMessage: 'There are no open underwriting issues associated with this quote.'
    },
    noOpenUnderwritingPolicyChange: {
        id: 'gateway.views.job-uwissues.There are no underwriting issues associated with this.PolicyChange',
        defaultMessage: 'There are no open underwriting issues associated with this policy change.'
    },
    noOpenUnderwritingRenewal: {
        id: 'gateway.views.job-uwissues.There are no underwriting issues associated with this.Renewal',
        defaultMessage: 'There are no open underwriting issues associated with this renewal.'
    },
    underwritingCustom: {
        id: 'quoteandbind.views.quote-details.Custom',
        defaultMessage: 'Custom'
    },
    withDrawJOB: {
        id: 'gateway.views.quote-uwissues.Withdraw',
        defaultMessage: 'Withdraw'
    },
    reasonCancellation: {
        id: 'gateway.views.job-uwissues.this job and select a reason for the cancellation.',
        defaultMessage: 'this job and select a reason for the cancellation.'
    },
    cancelQuote: {
        id: 'gateway.views.quote-uwissues.Cancel',
        defaultMessage: 'Cancel'
    },
    reasonContinue: {
        id: 'gateway.views.job-uwissues.this job if you do not wish to continue.',
        defaultMessage: 'this job if you do not wish to continue.'
    },
    confirmJob: {
        id: 'gateway.views.job-uwissues.Confirm',
        defaultMessage: 'Confirm'
    },
    jobStatusUW: {
        id: 'gateway.views.job-uwissues.Status',
        defaultMessage: 'Status'
    },
    cancelJob: {
        id: 'gateway.views.job-uwissues.Cancel',
        defaultMessage: 'Cancel'
    },
    toCompletePointersForSubmission: {
        id: 'gateway.views.job-uwissues.You cannot complete this.You cannot complete this Submission until these issues have been resolved. You can do the following',
        defaultMessage: 'You cannot complete this quote until these issues have been resolved. You can do the following:'
    },
    editSubmission: {
        id: 'gateway.views.job-uwissues.Edit the Submission if the customer is willing to accept the changes',
        defaultMessage: 'Edit the quote if the customer is willing to accept the changes.'
    },
    reviewSubmission: {
        id: 'gateway.views.job-uwissues.Refer the Submission to an underwriter for review',
        defaultMessage: 'Refer the quote to an underwriter for review.'
    },
    reviewPolicyChange: {
        id: 'gateway.views.job-uwissues.Refer the PolicyChange to an underwriter for review',
        defaultMessage: 'Refer the policychange to an underwriter for review.'
    },
    reviewRenewal: {
        id: 'gateway.views.job-uwissues.Refer the Renewal to an underwriter for review',
        defaultMessage: 'Refer the renewal to an underwriter for review.'
    },
    reviewCancellation: {
        id: 'gateway.views.job-uwissues.Refer the Cancellation to an underwriter for review',
        defaultMessage: 'Refer the cancellation to an underwriter for review.'
    },
    warningEditSubmission: {
        id: 'gateway.views.job-uwissues.Edit Submission',
        defaultMessage: 'Edit quote'
    },
    warningEditRenewal: {
        id: 'gateway.views.job-uwissues.Edit Renewal',
        defaultMessage: 'Edit renewal'
    },
    warningEditPolicyChange: {
        id: 'gateway.views.job-uwissues.Edit PolicyChange',
        defaultMessage: 'Edit policy change'
    },
    warningEditCancellation: {
        id: 'gateway.views.job-uwissues.Edit Cancellation',
        defaultMessage: 'Edit cancellation'
    },
    warningUnderwriter: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    underwriterSubmission: {
        id: 'gateway.views.job-uwissues.Underwriting issues have been raised for this Submission',
        defaultMessage: 'Underwriting issues have been raised for this quote.'
    },
    underwriterPolicyChange: {
        id: 'gateway.views.job-uwissues.Underwriting issues have been raised for this PolicyChange',
        defaultMessage: 'Underwriting issues have been raised for this policy change.'
    },
    underwriterRenewal: {
        id: 'gateway.views.job-uwissues.Underwriting issues have been raised for this Renewal',
        defaultMessage: 'Underwriting issues have been raised for this renewal.'
    },
    underwriterCancellation: {
        id: 'gateway.views.job-uwissues.Underwriting issues have been raised for this Cancellation',
        defaultMessage: 'Underwriting issues have been raised for this cancellation.'
    },
    toCompletePointersForPolicyChange: {
        id: 'gateway.views.job-uwissues.You cannot complete this.You cannot complete this PolicyChange until these issues have been resolved. You can do the following',
        defaultMessage: 'You cannot complete this policy change until these issues have been resolved. You can do the following:'
    },
    toCompletePointersForRenewal: {
        id: 'gateway.views.job-uwissues.You cannot complete this.You cannot complete this Renewal until these issues have been resolved. You can do the following',
        defaultMessage: 'You cannot complete this renewal until these issues have been resolved. You can do the following:'
    },
    toCompletePointersForCancellation: {
        id: 'gateway.views.job-uwissues.You cannot complete this.You cannot complete this Cancellation until these issues have been resolved. You can do the following',
        defaultMessage: 'You cannot complete this cancellation until these issues have been resolved. You can do the following:'
    },
    editPolicyChange: {
        id: 'gateway.views.job-uwissues.Edit the PolicyChange if the customer is willing to accept the changes',
        defaultMessage: 'Edit the policy change if the customer is willing to accept the changes.'
    },
    editRenewal: {
        id: 'gateway.views.job-uwissues.Edit the Renewal if the customer is willing to accept the changes',
        defaultMessage: 'Edit the renewal if the customer is willing to accept the changes.'
    },
    editCancellation: {
        id: 'gateway.views.job-uwissues.Edit the Cancellation if the customer is willing to accept the changes',
        defaultMessage: 'Edit the cancellation if the customer is willing to accept the changes.'
    },
    withDrawIfYouWish: {
        id: 'gateway.views.job-uwissues.Withdraw if you do not wish to continue',
        defaultMessage: 'Withdraw if you do not wish to continue'
    },
    underwriterReviewingPolicyChange: {
        id: 'gateway.views.job-uwissues.This PolicyChange has been referred to an underwriter for review"',
        defaultMessage: 'This policy change has been referred to an underwriter for review.'
    },
    underwriterReviewingRenewal: {
        id: 'gateway.views.job-uwissues.This Renewal has been referred to an underwriter for review',
        defaultMessage: 'This renewal has been referred to an underwriter for review.'
    },
    underwriterReviewingSubmission: {
        id: 'gateway.views.job-uwissues.This Submission has been referred to an underwriter for review',
        defaultMessage: 'This quote has been referred to an underwriter for review.'
    },
    underwriterReviewingCancellation: {
        id: 'gateway.views.job-uwissues.This Cancellation has been referred to an underwriter for review',
        defaultMessage: 'This cancellation has been referred to an underwriter for review.'
    },
    stillWithdrawRenewal: {
        id: 'gateway.views.job-uwissues.You can still withdraw the renewal. If you decide not to, the underwriter will continue with the review process',
        defaultMessage: 'You can still withdraw the renewal. If you decide not to, the underwriter will continue with the review process'
    },
    reviewCompleteInfo: {
        id: 'gateway.views.job-uwissues.An activity will be created to inform you when the underwriter has completed the review',
        defaultMessage: 'An activity will be created to inform you when the underwriter has completed the review.'
    },
    withdrawPolicyChange: {
        id: 'gateway.views.job-uwissues.Withdraw PolicyChange',
        defaultMessage: 'Withdraw policy change'
    },
    withdrawRenewal: {
        id: 'gateway.views.job-uwissues.Withdraw Renewal',
        defaultMessage: 'Withdraw renewal'
    },
    withdrawRenewalWizard: {
        id: 'gateway.views.renewal.renewal-summary.You may either withdraw or continue to the renewal wizard.',
        defaultMessage: 'You may either withdraw or continue to the renewal wizard.'
    },
    continueRenewal: {
        id: 'gateway.views.job-uwissues.Continue Renewal',
        defaultMessage: 'Continue Renewal'
    },
    continuePolicyChange: {
        id: 'gateway.views.job-uwissues.PolicyChange',
        defaultMessage: 'Continue policy change'
    },
    continueSubmission: {
        id: 'gateway.views.job-uwissues.Continue Submission',
        defaultMessage: 'Continue quote'
    },
    continueCancellation: {
        id: 'gateway.views.job-uwissues.Continue Cancellation',
        defaultMessage: 'Continue cancellation'
    },
    withdrawSubmission: {
        id: 'gateway.views.job-uwissues.Withdraw Submission',
        defaultMessage: 'Withdraw quote'
    },
    withdrawCancellation: {
        id: 'gateway.views.job-uwissues.Withdraw Cancellation',
        defaultMessage: 'Withdraw cancellation'
    },
    rejectedByUW: {
        id: 'gateway.views.job-uwissues.One or more Underwriting issues have been rejected by the underwriter',
        defaultMessage: 'One or more Underwriting issues have been rejected by the underwriter'
    },
    offerUnresolvedIssue: {
        id: 'gateway.views.job-uwissues.Some offerings have unresolved underwriting issues',
        defaultMessage: 'Some offerings have unresolved underwriting issues.'
    },
    issueResolved: {
        id: 'gateway.views.job-uwissues.You can proceed with the offerings that do not have issues or where the issues have been resolved.',
        defaultMessage: 'You can proceed with the offerings that do not have issues or where the issues have been resolved.'
    },
    editOrReferPolicyChange: {
        id: 'gateway.views.job-uwissues.For offerings with unresolved issues you can either edit the PolicyChange or refer it to the Underwriter.',
        defaultMessage: 'For offerings with unresolved issues, you can either edit the policy change or refer it to the underwriter.'
    },
    editOrReferRenewal: {
        id: 'gateway.views.job-uwissues.For offerings with unresolved issues you can either edit the Renewal or refer it to the Underwriter.',
        defaultMessage: 'For offerings with unresolved issues, you can either edit the renewal or refer it to the underwriter.'
    },
    editOrReferSubmission: {
        id: 'gateway.views.job-uwissues.For offerings with unresolved issues you can either edit the Submission or refer it to the Underwriter.',
        defaultMessage: 'For offerings with unresolved issues, you can either edit the quote or refer it to the underwriter.'
    },
    editOrReferCancellation: {
        id: 'gateway.views.job-uwissues.For offerings with unresolved issues you can either edit the Cancellation or refer it to the Underwriter.',
        defaultMessage: 'For offerings with unresolved issues, you can either edit the cancellation or refer it to the underwriter.'
    },
    withdrawOrContinuePolicyChange: {
        id: 'gateway.views.job-uwissues.You may either withdraw or continue to the PolicyChange wizard',
        defaultMessage: 'You may either withdraw or continue to the policy change wizard.'
    },
    withdrawOrContinueRenewal: {
        id: 'gateway.views.job-uwissues.You may either withdraw or continue to the Renewal wizard',
        defaultMessage: 'You may either withdraw or continue to the renewal wizard.'
    },
    withdrawOrContinueSubmission: {
        id: 'gateway.views.job-uwissues.You may either withdraw or continue to the Submission wizard',
        defaultMessage: 'You may either withdraw or continue to the quote wizard.'
    },
    withdrawOrContinueCancellation: {
        id: 'gateway.views.job-uwissues.You may either withdraw or continue to the Cancellation wizard',
        defaultMessage: 'You may either withdraw or continue to the cancellation wizard.'
    },
    withdrawThePolicyChange: {
        id: 'gateway.views.job-uwissues.You may withdraw the PolicyChange',
        defaultMessage: 'You may withdraw the policy change'
    },
    withdrawTheRenewal: {
        id: 'gateway.views.job-uwissues.You may withdraw the Renewal',
        defaultMessage: 'You may withdraw the renewal'
    },
    withdrawTheQuote: {
        id: 'gateway.views.job-uwissues.You may withdraw the Submission',
        defaultMessage: 'You may withdraw the quote'
    },
    withdrawTheCancellation: {
        id: 'gateway.views.job-uwissues.You may withdraw the Cancellation',
        defaultMessage: 'You may withdraw the cancellation'
    },
    lockedPolicyChange: {
        id: 'gateway.views.job-uwissues.This PolicyChange is locked.',
        defaultMessage: 'This policy change is locked.'
    },
    lockedRenewal: {
        id: 'gateway.views.job-uwissues.This Renewal is locked.',
        defaultMessage: 'This renewal is locked.'
    },
    lockedSubmission: {
        id: 'gateway.views.job-uwissues.This Submission is locked.',
        defaultMessage: 'This quote is locked.'
    },
    lockedCancellation: {
        id: 'gateway.views.job-uwissues.This Cancellation is locked.',
        defaultMessage: 'This cancellation is locked.'
    },
    referralInfoPolicyChange: {
        id: 'gateway.views.job-uwissues.Referral Info for PolicyChange',
        defaultMessage: 'Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the policy change until the underwriter releases it back to you.'
    },
    referralInfoRenewal: {
        id: 'gateway.views.job-uwissues.Referral Info for Renewal',
        defaultMessage: 'Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the renewal until the underwriter releases it back to you.'
    },
    referralInfoSubmission: {
        id: 'gateway.views.job-uwissues.Referral Info for Submission',
        defaultMessage: 'Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the quote until the underwriter releases it back to you.'
    },
    referralInfoCancellation: {
        id: 'gateway.views.job-uwissues.Referral Info for Cancellation',
        defaultMessage: 'Once referred, a high priority activity will be created for the underwriter. You will not be able to edit the cancellation until the underwriter releases it back to you.'
    },
    referToUWPolicyChange: {
        id: 'gateway.views.job-uwissues.Are you sure you want to refer this PolicyChange to the Underwriter?',
        defaultMessage: 'Are you sure you want to refer this policy change to the Underwriter?'
    },
    referToUWRenewal: {
        id: 'gateway.views.job-uwissues.Are you sure you want to refer this Renewal to the Underwriter?',
        defaultMessage: 'Are you sure you want to refer this renewal to the Underwriter?'
    },
    referToUWSubmission: {
        id: 'gateway.views.job-uwissues.Are you sure you want to refer this Submission to the Underwriter?',
        defaultMessage: 'Are you sure you want to refer this quote to the Underwriter?'
    },
    referToUWCancellation: {
        id: 'gateway.views.job-uwissues.Are you sure you want to refer this Cancellation to the Underwriter?',
        defaultMessage: 'Are you sure you want to refer this cancellation to the Underwriter?'
    },
    referToUnderwriter: {
        id: 'gateway.views.job-uwissues.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    noteForUnderwriter: {
        id: 'gateway.views.job-uwissues.Note for the underwriter (optional)',
        defaultMessage: 'Note for Underwriter (optional)'
    },
    noteForUnderwriterWizard: {
        id: 'gateway.views.job-uwissues.Note for the underwriter',
        defaultMessage: 'Note for Underwriter'
    },
    moreInfoUW: {
        id: 'gateway.views.job-uwissues.For more information, please contact the underwriter.',
        defaultMessage: 'For more information, please contact the underwriter.'
    },
    approvedForThisCancellation: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Cancellation',
        defaultMessage: 'All underwriting issues have been approved for this cancellation.'
    },
    approvedForThisSubmission: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Submission',
        defaultMessage: 'All underwriting issues have been approved for this quote.'
    },
    approvedForThisRenewal: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Renewal',
        defaultMessage: 'All underwriting issues have been approved for this renewal.'
    },
    approvedForThisPolicyChange: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this PolicyChange',
        defaultMessage: 'All underwriting issues have been approved for this policy change.'
    },
    approved: {
        id: 'gateway.views.job-uwissues.Approved',
        defaultMessage: 'Approved'
    },
    errorSendingJobForReview: {
        id: 'gateway.views.job-uwissues.There was an error sending the job for review',
        defaultMessage: 'There was an error sending the job for review.'
    },
    notApplicable: {
        id: 'gateway.views.job-uwissues.N/A',
        defaultMessage: 'N/A'
    }
});
