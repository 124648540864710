import { defineMessages } from 'react-intl';

export default defineMessages({
    occasionalDrivers: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Occasional Driver(s)',
        defaultMessage: 'Occasional Driver(s)',
    },
    cancel: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Cancel',
        defaultMessage: 'Cancel',
    },
    save: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.Save',
        defaultMessage: 'Save',
    },
    excessVehilce: {
        id: 'quoteandbind.pa.views.pa-driver-assignment.ExcessVehilce',
        defaultMessage: 'This vehicle is classified as an Excess Vehicle because all drivers are assigned to other vehicles. Please select a Primary Operator for this vehicle.',
    },
});
