import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import {
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    WindowUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants} from 'wni-portals-config-js';
import { ComponentRenderHelper as renderHelper, ModalDialogHelper } from 'wni-portals-util-react';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, useWniModal } from 'wni-components-platform-react';
import { PAQuoteUIHelper } from 'gw-capability-quoteandbind-pa-react-ext';
import { WniPAPolicyChangeService } from 'wni-capability-policychange';
import { PolicyDiffService } from 'gw-capability-policyjob';
import {
    SideBySidePeriodsComponent,
    ReferToUnderwriterComponent,
    ChangeSummaryComponent,
} from 'wni-capability-gateway-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as currentMessages } from 'wni-platform-translations';

import { HOWizardPage as WizardPage } from 'wni-capability-quoteandbind-ho-react';

import HOPolicyChangeQuoteComponent from './components/HOPolicyChangeQuoteComponent';
import HOAddNewChangeVersionComponent from './components/AddNewVersion/HOAddNewChangeVersionComponent';

import styles from './HOQuoteChangePage.module.scss';
import metadata from './HOQuoteChangePage.metadata.json5';


const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = PAQuoteUIHelper;

function HOQuoteChangeReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardReadOnly,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const modalApi = useWniModal();
    const { authHeader } = useAuthentication();

    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('HOQuoteChangePage');
    const [showErrors, updateShowErrors] = useState(false);
    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [isShowMulti, updateShowMulti] = useState(false);
    const [hasBlockingIssue, updateHasBlockingIssue] = useState(false);
    const [policyDiffData, updatePolicyDiffData] = useState([]);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);

    const {
        jobID,
        sessionUUID,
        baseData: {
            effectiveDate_Ext: effectiveDate,
            periodStartDate,
            periodEndDate,
            selectedVersion_Ext: selectedVersion,
            periodStatus: jobStatus,
        },
        // lobData: {
        // },
        changeData: {
            totalCost,
            totalCost: {
                amount: totalCostAmount,
            } = {},
            transactionCost = {},
            transactionCost: {
                amount: transactionCostAmount,
            } = {},
            previousTotalCost,
            isBillingOptionsEditable,
        }
    } = submissionVM.value;
    const modalHelper = ModalDialogHelper(modalApi);

    const updatePolicyDifferences = async () => {
        const newPolicyDiffData = await PolicyDiffService.getPolicyDiffWithPrevious(jobID,
            authHeader);
        updatePolicyDiffData(newPolicyDiffData);
        updateWizardPageData({ [WizardConstants.policyDiffData]: newPolicyDiffData });
    };

    const withServiceInProgressUpdated = async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        }
        updateServiceCallInProgress(false);
    };

    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        WindowUtil.scrollToTop();
        // to be updated
        withServiceInProgressUpdated(async () => {
            updatePolicyDifferences();
        });
    }, []);

    const onReferToUnderwriter = async (noteForUW) => {
        withServiceInProgressUpdated(async () => {
            const jobResponse = await WniPAPolicyChangeService.referToUnderwriter(jobID,
                noteForUW, authHeader);

            // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
            const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse);
            if (!_.isEmpty(referToUWIssues)) {
                updateExtraBlockingSubmitIssues(referToUWIssues);
                WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
            } else if (!_.isEmpty(jobResponse)) {
                // history.push(`/change/${jobID}/summary`);
                updateWizardReadOnly(true);
            }
        });
    };

    const generateOverrides = useCallback(() => {
        const effectiveDateFormat = WniDateUtil.formatDateWithPattern(effectiveDate);
        const startPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodStartDate);
        const endPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodEndDate);

        const newErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        const validationIssues_all = ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings,
            [
                // ...oosErrorsAndWarnings,
                ...extraBlockingSubmitIssues
            ]);
        // POI-55289: requirement requests hiding of all waring messages
        const validationIssues = validationIssues_all.filter((issue) => issue.type !== 'warning');
            
        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);

        // const underwritingIssues = _.get(newErrorsAndWarnings, 'underwritingIssues', []);
        const underwritingIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(newErrorsAndWarnings, 'underwritingIssues', []), selectedVersion
        );
        // const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(newErrorsAndWarnings);
        const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue({ underwritingIssues });

        updateHasBlockingIssue(hasBlockUWIssue || hasValidationError);

        const changeUtil = PolicyChangeQuoteUtil({
            intl, translator, totalCost, transactionCost, currentMessages
        });
        const premiumDiffMsg = changeUtil.getPremiumDiffMessage();
        const premiumDiffTitle = changeUtil.getPremiumDiffTitle();
        return {
            '@field': {
                labelPosition: 'left'
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssues),
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator, (issueType) => {
                    return issueType === 'error' ? currentMessages.submitTheChange : undefined;
                }),
                typeFooterFormatter: getQuotePageErrorsAndWarningsFooter({
                    wizardSteps,
                    wizardStepToFieldMapping,
                    wizardJumpTo,
                    resolveButtonLabel: translator(currentMessages.resolveErrors),
                    isServiceCallInProgress,
                }),
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                // Refactoring Notes: should retrieve validation result for current slice only
                issueDistinguisher: true,
            },
            quoteUWIssues: {
                underwritingIssues,
                // headerObject: currentMessages.IssuesRequiringReferral,
            },
            addNewVersionBtn: {
                visible: false,
            },
            deleteQuoteVersionButton: {
                visible: false,
            },
            referToUWBtn: {
                onReferToUnderwriter,
                isServiceCallInProgress,
                visible: false, // hasBlockUWIssue,
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            },
            printBtn: {
                disabled: isServiceCallInProgress,
            },
            // printBtnV2: {
            //     disabled: isServiceCallInProgress,
            // },
            // =====================
            policyChangeSummary: {
                validationIssues: premiumDiffMsg,
                typeTitleFormatter: premiumDiffTitle,
                previousTotalCost,
                transactionCost,
                totalCost,
                effectiveDateFormat,
                periodDateRange: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`,
                // transactionCostAmount,
                showPremiumChange: jobStatus === 'Quoted' || jobStatus === 'Bound',
            },
            // // ==========================
            PAHeadId: {
                visible: !isShowMulti
            },
            policyChangeDetailId: {
                visible: !isShowMulti
            },
            paPolicyDiff: {
                policyDiffData,
                isServiceCallInProgress,
                // vehicles,
            },
            // multiVersionsId: {
            //     visible: isShowMulti
            // },
            // multiVersionBtn: {
            //     // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            //     visible: false,
            // },
            // backToDetailBtn: {
            //     disabled: isServiceCallInProgress,
            // },
            // addNewBtn: {
            //     // disabled: true,
            //     visible: PolicyChangeUtil.canAddSideBySideChangePeriod(_.get(sxsDataDTO, 'personalAuto.periods')),
            //     // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            //     content: renderHelper.getButtonContent(translator(currentMessages.addNewVersion), isServiceCallInProgress),
            // },
            // sxsPeriodsInfoSection: {
            //     columnData,
            //     isServiceCallInProgress,
            //     showActionButtons: false,
            //     sxsPeriodInfo: PolicyChangeSideBySidePeriodInfo,
            //     readyForSxsRemovalCheckFn: PolicyChangeUtil.canRemoveSideBySideChangePeriod,
            // },
            // quoteTable: {
            //     columnData,
            //     tableData,
            //     // isEditable: false,
            //     onValidate,
            //     showErrors,
            // },
        };
    },
    [
        submissionVM,
        isServiceCallInProgress,
        errorsAndWarnings, sxsDataDTO,
        translator,
        isShowMulti,
        policyDiffData,
        showErrors,
    ]);

    const renderQuotePage = useCallback(({ onNext }) => {
        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                // // onStaleQuoteBranchCode: _.noop,
                // onChangeSubmissionAndSync: onUpdateCoverage,
                // // onChangeSubmission: updateWizardData,
                // // onSyncCoverages: _.noop,
                // onShowMultiVersions: showMultiVersions,
                // onBackToDetail: closeMultiVersions,
                // onCreateNewVersion: onAddSideBySideVersion,
                onPrint: handlePrint
            },
            resolveComponentMap: {
                sxsquoteperiods: SideBySidePeriodsComponent,
                validationissuescomponent: ValidationIssuesComponent,
                quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
                // notificationcomponent: SuccessNotification,
                policychangequotecomponent: HOPolicyChangeQuoteComponent,
                refertouw: ReferToUnderwriterComponent,
                changesummary: ChangeSummaryComponent,
                addnewversioncomponent: HOAddNewChangeVersionComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateSubmissionVM}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, submissionVM, updateSubmissionVM]); // sxsDataDTO

    return (
        <WizardPage
            showNext={false}
            // cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            nextLabel={ isBillingOptionsEditable ? undefined : currentMessages.submitChange}
        >
            {renderQuotePage}
        </WizardPage>
    );
}

HOQuoteChangeReadOnlyPage.propTypes = wizardProps;
export default HOQuoteChangeReadOnlyPage;
