import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import messages from './ExposureExclusionAndConditionAccordionCard.messages'

import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil'
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext';
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM'
import AccordionCardTitle from '../../../GeneralLiability/components/GLClauseAccordionCardTitle/GLClauseAccordionCardTitle';
import { AccordionCard } from '@jutro/legacy/components';

const ExposureExclusionAndConditionAccordionCard = (props) => {
    const {
        currentExposureClauses,
        setCurrentExposureClauses,
        wizardData: submissionVM,
        updateWizardData,
        onValidate: onExposurePageValidate,
        showErrors,
        readOnly,
        extendProps,
        setIsEditing,
        isEditing,
        onClickAddSearchCoverage
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        jobID,
        sessionUUID,
    } = extendProps

    const { authHeader } = useAuthentication();

    const covType = 'exclusionsAndConditions';
    const validationID = 'glExposureExclusionAndCondition'
    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    useEffect(() => {
        if (onExposurePageValidate) {
            onExposurePageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onExposurePageValidate) {
                onExposurePageValidate(true, validationID)
            }
        }
    }, [isComponentValid, onExposurePageValidate])

    const { exclusion, condition } = currentExposureClauses;

    const [loadingClauses, setLoadingClauses] = useState(false);

    const changeClauses = useCallback(
        (value, changedPath, clauses, clausesPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(
                clauses,
                clausesPath,
                value,
                changedPath
            );
            const newExposureClauses = _.clone(currentExposureClauses);
            _.set(newExposureClauses, clausesPath, newClauses);
            setCurrentExposureClauses(newExposureClauses);
            setIsEditing(false);
            return newExposureClauses;
        },
        [currentExposureClauses, setCurrentExposureClauses, setIsEditing]
    );

    const changeExclusions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, exclusion, 'exclusion');
        },
        [changeClauses, exclusion]
    );

    const changeConditions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, condition, 'condition');
        },
        [changeClauses, condition]
    );

    const updateVMToServer = useCallback(async (newExposureClauses, setLoadingClausesFunc, setEditingFunc) => {
        const exposureClausesToUpdate = GLCoverageUtil.generateUpdatedExposureClausesDTO(newExposureClauses);
        const updatedExposureClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedExposureClausesDTO(exposureClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedExposureClausesPublicIDs)
        const response = await GLCoverageService.updateExposureClauses(
            jobID,
            sessionUUID,
            exposureClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setCurrentExposureClauses(response);
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCurrentExposureClauses]);

    const updateNewExposureClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);

    const changeExclusionsAndSync = useCallback(
        (value, changedPath) => {
            const newExposureClauses = changeExclusions(value, changedPath);

            updateNewExposureClausesToServer(newExposureClauses);
        },
        [changeExclusions, updateNewExposureClausesToServer]
    );

    const changeConditionsAndSync = useCallback(
        (value, changedPath) => {
            const newExposureClauses = changeConditions(value, changedPath);

            updateNewExposureClausesToServer(newExposureClauses);
        },
        [changeConditions, updateNewExposureClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewExposureClausesToServer(currentExposureClauses);
        },
        [currentExposureClauses, updateNewExposureClausesToServer]
    )

    return <AccordionCard
            id="glExposureExclusionAndConditionAccordionCard"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={
                !readOnly ?
                <AccordionCardTitle
                    title={messages.exclusionAndConditionTitle}
                    actionText={messages.exclusionAndConditionActionText}
                    onClick={() => {onClickAddSearchCoverage(covType)}}
                /> : translator(messages.exclusionAndConditionTitle)
            }
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeExclusionsAndSync}
                        onChangeClause={changeExclusions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="exclusion"
                        value={exclusion}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM:
                                GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={!readOnly}
                    />
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeConditionsAndSync}
                        onChangeClause={changeConditions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="condition"
                        value={condition}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM:
                                GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={!readOnly}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
}

export default ExposureExclusionAndConditionAccordionCard;