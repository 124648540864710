import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { QuestionSetComponent } from '@xengage/gw-portals-questionsets-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './QualificationPage.metadata.json5';

const LOB_PATH = {
    PersonalAuto: 'lobData.personalAuto.preQualQuestionSets.children[0].value',
    Homeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    HOPHomeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    BusinessOwners: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    BusinessAuto: 'lobData.businessOwners.preQualQuestionSets.children[0].value'
};

function QualificationPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const {
        registerInitialComponentValidation,
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('QualificationPage');
    const lobCode = _.get(submissionVM.value, 'baseData.productCode');

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );

        return submissionVM;
    }, [submissionVM, LoadSaveService, authHeader]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        questionSets: {
            path: LOB_PATH[lobCode]
        }
    };

    const resolvers = {
        resolveComponentMap: { questionset: QuestionSetComponent },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const shouldSkipBopPage = useCallback(() => {
        const preQualQuestionSets = _.get(
            submissionVM,
            LOB_PATH[lobCode].replace(/\.children\[\d\]/, '')
        );
        if (!_.isEmpty(preQualQuestionSets)) {
            const answerValues = _.values(preQualQuestionSets[0].answers);
            return answerValues.some((value) => value !== null);
        }
        return false;
    }, [lobCode, submissionVM]);

    const hasLicenseNumberForPADriver = useCallback(() => {
        const licenseNumber = _.get(
            submissionVM,
            'lobData.personalAuto.coverables.drivers.value[0].licenseNumber'
        );

        if (config.persona === 'producer' || config.persona === 'csr') {
            return !_.isEmpty(licenseNumber);
        }
        return true;
    }, [submissionVM]);

    useEffect(() => {
        if (lobCode === 'PersonalAuto') {
            registerInitialComponentValidation(hasLicenseNumberForPADriver);
        } else if (lobCode === 'BusinessOwners') {
            registerInitialComponentValidation(shouldSkipBopPage);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

QualificationPage.propTypes = wizardProps;
export default QualificationPage;
