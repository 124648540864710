import { defineMessages } from 'react-intl';

export default defineMessages({
    saveAndClose: {
        id: 'vehicleCoverageModal.button.label.SaveAndClose',
        defaultMessage: 'Save & Close'
    },
    copyCoverages: {
        id: 'vehicleCoverageModal.button.label.CopyCoverages',
        defaultMessage: 'Copy Coverages'
    }
});
