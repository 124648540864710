
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    WizardUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';
import { useTranslator, IntlContext } from '@jutro/locale';
import { HOHouseholdInfoChangeService } from 'wni-capability-policychange-ho';
import { WniCommonChangeService } from 'wni-capability-policychange';
import { HOHouseholdInfoPage } from 'wni-capability-quoteandbind-ho-react';

function HOHouseholdInfoChangePage(props) {

    const {
        authHeader,
        authUserData
    } = useAuthentication();

    const {
        wizardData: submissionVM,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        turnOffWizardPage,
        //
        clearVisitedStepsFromQuotePage,
    }  = props;

    const {
        jobID,
        sessionUUID,
        changeData: {
            isBillingOptionsEditable,
        }
    } = submissionVM.value;

    const {
        [WizardConstants.landingPage]: landingPage,
        [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const pageOnInit = useCallback(() => {
        if (landingPage) {
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'HOHouseholdInfoPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            clearVisitedStepsFromQuotePage();
        }
    }, []);

    useEffect(() => {
        pageOnInit();

        if (!isBillingOptionsEditable) {
            turnOffWizardPage('HOPaymentPage');
        }
    }, []);

    const saveHouseHoldIndfoAndUpdateActiveQuotes = useCallback(async (...params) => {
        const retval = await HOHouseholdInfoChangeService.saveHoHouseholdInfoData(...params);
        // Update ActiveQuotes as well, since PolicyPeriod publicID could be updated due to Date change

        const newActiveQuotes = await WniCommonChangeService.getActiveQuotes(jobID, sessionUUID, authHeader);
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: newActiveQuotes,
        });

        return retval;
    }, [authHeader, jobID, sessionUUID, updateWizardPageData]);

    const maximumEffectiveDate = _.get(submissionVM,'value.changeData.maximumEffectiveDate')

    return (
        <HOHouseholdInfoPage {...props}
            setCoverageFormReadOnly
            setAgentComponentReadOnly
            isOpenPNIPopupPageInit={policyChangeSource === 'cancellation'}
            // saveHoHouseholdInfo={HOHouseholdInfoChangeService.saveHoHouseholdInfoData}
            saveHoHouseholdInfo={saveHouseHoldIndfoAndUpdateActiveQuotes}
            // householdInfoPageOnInit={pageOnInit}
            checkRequiredForIssuance = {false}
            setEffectiveDateForChangeTransaction
            maximumEffectiveDate = {maximumEffectiveDate}
        />
    );

}

HOHouseholdInfoChangePage.propTypes = HOHouseholdInfoPage.propTypes;
HOHouseholdInfoChangePage.defaultProps = HOHouseholdInfoPage.defaultProps;

export default HOHouseholdInfoChangePage;