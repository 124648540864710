import { defineMessages } from 'react-intl';

export default defineMessages({
    commission: {
        id: 'gateway.views.account-detail.Commission',
        defaultMessage: 'Commission'
    },
    commissionSummary: {
        id: 'gateway.directives.CommissionsSummaryDirective.Commission Summary for Policies in Force',
        defaultMessage: 'Commission Summary for Policies in Force'
    },
    commissionShowBelow: {
        id: 'gateway.directives.CommissionsSummaryDirective.Shown below is the commission you will earn for the policies on this account for the length of each policy period.',
        defaultMessage: 'Shown below is the commission you will earn for the policies on this account for the length of each policy period.'
    },
    commissionPolicy: {
        id: 'gateway.directives.CommissionsSummaryDirective.Policy',
        defaultMessage: 'Policy'
    },
    commissionProducerCode: {
        id: 'gateway.directives.CommissionsSummaryDirective.Producer Code',
        defaultMessage: 'Producer Code',
    },
    commissionRole: {
        id: 'gateway.directives.CommissionsSummaryDirective.Role',
        defaultMessage: 'Role',
    },
    commissionTotal: {
        id: 'gateway.directives.CommissionsSummaryDirective.Total Commission',
        defaultMessage: 'Total Commission'
    },
    commissionTotaldata: {
        id: 'gateway.directives.CommissionsSummaryDirective.Total',
        defaultMessage: 'Total',
    },
    commissionViewOverAll: {
        id: 'gateway.directives.CommissionsSummaryDirective.View Overall Commission',
        defaultMessage: 'View Overall Commission'
    },
    commissionTransactions: {
        id: 'gateway.directives.CommissionsSummaryDirective.Transactions',
        defaultMessage: 'Transactions'
    },
    commissionDate: {
        id: 'gateway.directives.CommissionsSummaryDirective.Date',
        defaultMessage: 'Date'
    },
    commissionType: {
        id: 'gateway.directives.CommissionsSummaryDirective.Type',
        defaultMessage: 'Type'
    },
    commissionCharge: {
        id: 'gateway.directives.CommissionsSummaryDirective.Charge',
        defaultMessage: 'Charge'
    },
    commissionBasis: {
        id: 'gateway.directives.CommissionsSummaryDirective.Basis',
        defaultMessage: 'Basis'
    },
    commissionRate: {
        id: 'gateway.directives.CommissionsSummaryDirective.Rate',
        defaultMessage: 'Rate'
    },
    commissionAmount: {
        id: 'gateway.directives.CommissionsSummaryDirective.Amount',
        defaultMessage: 'Amount'
    },
    AccountCommissionPaid: {
        id: 'gateway.directives.CommissionsSummaryDirective.Paid',
        defaultMessage: 'Paid'
    },
    AccountCommissionNotPaid: {
        id: 'gateway.directives.CommissionsSummaryDirective.Not Paid',
        defaultMessage: 'Not Paid'
    },
    commissionAllPoliciesAccount: {
        id: 'gateway.directives.CommissionsSummaryDirective.All Policies on This Account',
        defaultMessage: 'All Policies on This Account'
    },
});
