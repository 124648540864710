import React, { useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ConfirmationPage.metadata.json5';
import styles from './ConfirmationPage.module.scss';

function RenewalConfirmationPage(props) {
    const translator = useTranslator();
    const { wizardData: submissionVM, history } = props;

    const goToPolicy = useCallback(() => {
        const policyNumber = _.get(submissionVM, 'policyNumber.value');
        return history.push({ pathname: `/policies/${policyNumber}/summary` });
    }, [history, submissionVM]);

    const goToTransaction = useCallback(() => {
        const jobID = _.get(submissionVM, 'jobID.value');
        return history.push({ pathname: `/renewal/${jobID}/summary` });
    }, [history, submissionVM]);

    const getBillingLink = useCallback(() => {
        const policyNumber = _.get(submissionVM, 'policyNumber.value');
        return (
            <FormattedMessage
                {...messages.paymentSchedule}
                values={{
                    link: (
                        <Link
                            to={`/policies/${policyNumber}/billing`}
                            className={styles.removeLinkStyle}
                        >
                            {translator(messages.policyBillingPage)}
                        </Link>
                    )
                }}
            />
        );
    }, [submissionVM, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            goToTransaction: goToTransaction,
            goToPolicy: goToPolicy
        }
    };
    const overrideProps = {
        lobDetails: {
            content: translator(messages.lobPolicy, {
                lob: _.get(submissionVM, 'baseData.productName.value'),
                policyNumber: _.get(submissionVM, 'policyNumber.value'),
            })
        },
        referenceNumber: {
            content: translator(messages.referenceNumber, { refNumber: _.get(submissionVM, 'jobID.value') }),
            className: styles.refNumber
        },
        paymentScheduleAndInvoices: {
            content: getBillingLink()
        },
        transactionButton: {
            content: translator(messages.viewJobDetails, { jobType: _.get(submissionVM, 'baseData.jobType.value.code') })
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

RenewalConfirmationPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                transactionSummary: PropTypes.shape({})
            }).isRequired
        }).isRequired,
        push: PropTypes.func.isRequired
    }).isRequired
};

RenewalConfirmationPage.propTypes = wizardProps;
export default withRouter(RenewalConfirmationPage);
