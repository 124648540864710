import { defineMessages } from 'react-intl';

export default defineMessages({
    fullAddress: {
        id: 'quoteandbind.bop.directives.bop-location-directive.Full Address',
        defaultMessage: 'Full Address'
    },
    locationCode: {
        id: 'quoteandbind.bop.directives.bop-location-directive.Location Code',
        defaultMessage: 'Location Code'
    },
    fireProtection: {
        id: 'quoteandbind.bop.directives.bop-location-directive.Fire Protection',
        defaultMessage: 'Fire Protection'
    },
    phone: {
        id: 'quoteandbind.bop.directives.bop-location-directive.Phone',
        defaultMessage: 'Phone'
    },
    building: {
        id: 'quoteandbind.bop.directives.bop-location-directive.Building',
        defaultMessage: 'Building'
    },
    addBuilding: {
        id: 'quoteandbind.bop.directives.bop-location-directive.Add Building',
        defaultMessage: 'Add Building'
    },
    saveBuilding: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.building.building.Save Building',
        defaultMessage: 'Save Building'
    },
    addLocation: {
        id: 'gw-portals-bop-components-angular.locationsAndBuildings.locationsAndBuildings.locationsAndBuildings.Add Location',
        defaultMessage: 'Add Location'
    },
    details: {
        id: 'quoteandbind.bop.directives.bop-building-directive.tabs.Details',
        defaultMessage: 'Details'
    },
    yearBuilt: {
        id: 'quoteandbind.bop.directives.bop-building-directive.Year Built',
        defaultMessage: 'Year Built'
    },
    constructionType: {
        id: 'quoteandbind.bop.directives.bop-building-directive.Construction Type',
        defaultMessage: 'Construction Type'
    },
    percentageSprinklered: {
        id: 'quoteandbind.bop.directives.bop-building-directive.% Sprinklered',
        defaultMessage: 'Percentage Sprinklered'
    },
    numberOfStories: {
        id: 'quoteandbind.bop.directives.bop-building-directive.# of Stories',
        defaultMessage: 'Number of Stories'
    },
    totalArea: {
        id: 'quoteandbind.bop.directives.bop-building-directive.Total Area (excl basement)',
        defaultMessage: 'Total Area Excluding Basement'
    },
    propertyClassCode: {
        id: 'quoteandbind.bop.directives.bop-building-directive.Property Class Code',
        defaultMessage: 'Property Class Code'
    },
    exposure: {
        id: 'quoteandbind.bop.directives.bop-building-directive.Exposure',
        defaultMessage: 'Exposure'
    },
    alarmType: {
        id: 'quoteandbind.bop.directives.bop-building-directive.Alarm Type',
        defaultMessage: 'Alarm Type'
    },
    premiumBasisAmount: {
        id: 'quoteandbind.bop.directives.bop-building-directive.PremiumBasisAmount',
        defaultMessage: 'Premium Basis Amount'
    },
    coverages: {
        id: 'quoteandbind.bop.directives.bop-building-directive.tabs.Coverages',
        defaultMessage: 'Coverages'
    },
    additionalCoverages: {
        id: 'quoteandbind.bop.directives.bop-building-directive.tabs.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    removeBuildingTitle: {
        id: 'quoteandbind.bop.views.bop-buildings-locations.RemoveBuildingTitle',
        defaultMessage: 'Remove Building?'
    },
    removeBuildingMessage: {
        id: 'quoteandbind.bop.views.bop-buildings-locations.RemoveBuildingMessage',
        defaultMessage: 'Are you sure that you want to delete this building?'
    },
    removeActionLabel: {
        id: 'quoteandbind.bop.views.bop-buildings-locations.RemoveActionLabel',
        defaultMessage: 'Delete'
    },
    removeLocationTitle: {
        id: 'quoteandbind.bop.directives.bop-location-directive.RemoveLocationTitle',
        defaultMessage: 'Remove Location?'
    },
    removeLocationMessage: {
        id: 'quoteandbind.bop.directives.bop-location-directive.RemoveLocationMessage',
        defaultMessage: 'Are you sure that you want to delete this location?'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-bop-angular.config.FlowConfig.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-bop-angular.config.FlowConfig.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    }
});
