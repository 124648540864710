import { defineMessages } from 'react-intl';

export default defineMessages({
    withdraw: {
        id: 'custom.gateway.directives.JobSummary.Withdraw',
        defaultMessage: 'Withdraw'
    },
    withdrawJob: {
        id: 'custom.gateway.directives.JobSummary.WithdrawJob',
        defaultMessage: 'Delete Quote?'
    },
    sureWithDrawQuote: {
        id: 'custom.gateway.directives.JobSummary.Are you sure you want to delete quote:',
        defaultMessage: 'Are you sure you want to delete quote:'
    },
    failedWithdrawSubmission: {
        id: 'custom.gateway.directives.JobSummary.Failed to delete submission',
        defaultMessage: 'Failed to delete submission'
    },
});
