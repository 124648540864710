
import React, {
    useContext, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './OperatorQuestions.metadata.json5';
import OperatorUtil from '../../util/OperatorUtil';

/**
 * @param {object} props
 * @returns {object} React Component
 */
function OperatorQuestions(props) {
    const translator = useTranslator();
    const {
        operatorVM,
        onValueChange,
        showErrors,
        baseState,
        messages,
        effectiveDate,
        isReadOnly
    } = props;

    const question = _.get(operatorVM, 'value.driverQuestionSets[0].answers');

    const {
        isLicensedDriver,
        unLicensedDriverReason,
        productCode
    } = operatorVM.value;

    const {
        hasLicenseSuspended_Ext: hasLicenseSuspended,
        isDriverRequiredSubmit_Ext: isDriverRequiredSubmit,
        willDriverExclusion_Ext: willDriverExclusion
    } = question || {};

    useEffect(() => {
    }, []);

    const isShowHasLicenseSuspended = useCallback(() => {
        const isChild = OperatorUtil.isChild(operatorVM, baseState, effectiveDate);
        if (isLicensedDriver && !isChild) {
            return true;
        }
        return false;
    }, [baseState, effectiveDate, isLicensedDriver, operatorVM]);

    const isShowIsDriverRequiredSubmit = useCallback(() => {
        if (isLicensedDriver) {
            return true;
        }
        if (isLicensedDriver === false) {
            if (unLicensedDriverReason
                && unLicensedDriverReason !== 'permitonly'
                && unLicensedDriverReason !== 'underage'
                && unLicensedDriverReason !== 'willNeverLicensed'
                && unLicensedDriverReason !== 'other') {
                return true;
            }
        }
        return false;
    }, [isLicensedDriver, unLicensedDriverReason]);

    const isShowExclusion = useCallback(() => {
        if (baseState === 'AK' || baseState === 'SD') {
            if (isLicensedDriver) {
                return true;
            }
            if (isLicensedDriver === false
                && unLicensedDriverReason !== 'underage'
                && unLicensedDriverReason !== 'willNeverLicensed'
                && unLicensedDriverReason !== 'other') {
                return true;
            }
        }
        return false;
    }, [baseState, isLicensedDriver, unLicensedDriverReason]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
            },
            '@field': {
                labelPosition: 'left',
                showOptional: false,
                showRequired: true,
                readOnly: isReadOnly
            },
            hasLicenseSuspended: {
                visible: isShowHasLicenseSuspended(),
                defaultValue: false
            },
            explainForhasLicenseSuspended: {
                visible: Boolean(hasLicenseSuspended && hasLicenseSuspended !== 'false' && isShowHasLicenseSuspended())
            },
            isDriverRequiredSubmit: {
                visible: isShowIsDriverRequiredSubmit(),
                defaultValue: false
            },
            explainForIsDriverRequiredSubmit: {
                visible: Boolean(isDriverRequiredSubmit && isDriverRequiredSubmit !== 'false' && isShowIsDriverRequiredSubmit())
            },
            willDriverExclusion: {
                visible: isShowExclusion(),
                defaultValue: false
            },
            HasCompletedSafetyCourses: {
                visible: Boolean((!willDriverExclusion || willDriverExclusion === 'false')),
                label: messages.completedSafetyCoursesInfo,
            }
        }
    }, [hasLicenseSuspended, isDriverRequiredSubmit, isShowExclusion, isShowHasLicenseSuspended, isShowIsDriverRequiredSubmit, willDriverExclusion])

    const overrideProps = generateOverrides();

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            question,
            id,
            path,
            overrideProps
        );
    };
    //---------
    // return MetadataContent(metadata.componentContent, override, resolvers);
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={question}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
}

OperatorQuestions.propTypes = {
    operatorVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    baseState: PropTypes.string,
    messages: PropTypes.object,
    effectiveDate: PropTypes.object,
    isReadOnly: PropTypes.bool
};

OperatorQuestions.defaultProps = {
    operatorVM: {},
    onValueChange: _.noop,
    showErrors: false,
    baseState: '',
    messages: {},
    effectiveDate: {},
    isReadOnly: false
};

export default OperatorQuestions;
