import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

function ExternalCERouting(props) {
    const { match, history } = props;

    useEffect(() => {
        const {
            params: { jobNumber }
        } = match;

        const toURL = `/change/${jobNumber}/summary`;
        history.push(toURL);
    }, [history, match]);

    return null;
}

ExternalCERouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

export default withRouter(ExternalCERouting);
