import React, {
    useState,
} from 'react';
import _ from 'lodash';
import { DropdownMenuButton } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import HOCreateAdditionalInterestPanel from './HOCreateAdditionalInterestPanel';


import { IconButton, InputField } from '@jutro/legacy/components';


import { DropdownMenuLink } from '@jutro/router';


function HOSSPLossPayeeComponent(props) {

    const {
        labelPosition,
        isEditable,
        additionalInterests = [],
        lossPayees = [],
        lossPayeeCandidates = [],
        handleLossPayeeChange,
        onSaveAdditionalInterestFn,
        onGettingAdditionalInterestTypeOptions,
        accountContacts,
        isCreateAdditionalInterestPanelVisible,
        setIsCreateAdditionalInterestPanelVisible,
    } = props;


    const onLossPayeeChange = async (code) => {
        if (code === 'createNew') {
            setIsCreateAdditionalInterestPanelVisible(true);
            return
        };
        if (_.isFunction(handleLossPayeeChange)) {
            const newLossPayees = _.clone(lossPayees);
            const selectedContact = lossPayeeCandidates.find((lossPayeeCandidate) => {
                return code === _.get(lossPayeeCandidate, 'publicID');
            })
            newLossPayees[lossPayees.length] = {
                policyAdditionalInterest: selectedContact
            };
            await handleLossPayeeChange(newLossPayees);
        };
    };
    const orderdLossPayeeCandidates = _.sortBy(lossPayeeCandidates, (i)=>{return i.displayName.toLowerCase()});
    return <>
        <Flex>
            <DropdownMenuButton
                id= "ho.coverage.losspayee.dropdown-menu-button"
                buttonText="Add loss payee"
                icon= "gw-expand-more"
                iconPosition= "right"
                dropUp={false}
                disabled={!isEditable}
            >
                {
                    [{
                        code: 'createNew',
                        name: 'Create New'
                    }]
                        .concat(orderdLossPayeeCandidates.map((lossPayeeCandidate) => {
                            return {
                                code: _.get(lossPayeeCandidate, 'publicID'),
                                name: _.get(lossPayeeCandidate, 'displayName')
                            }
                        })).map((lossPayee) => 
                            <DropdownMenuLink
                                id={lossPayee.code}
                                key={lossPayee.code}
                                onClick={()=> onLossPayeeChange(lossPayee.code)}
                            >
                                {lossPayee.name}
                            </DropdownMenuLink>
                        )
                }
            </DropdownMenuButton>
        </Flex>
        <DataTable
            data={lossPayees}
            id="basic"
            showPagination={false}
            showSearch={false}
            columnsConfigurable={false}
        >
            <DisplayColumn
                id='ho.coverage.losspayee.name'
                header='Name'
                sortable={false}
                renderCell={(lossPayee) =>  
                    <InputField
                        labelPosition= {labelPosition}
                        value={_.get(lossPayee, 'policyAdditionalInterest.displayName')}
                        readOnly
                    />
                }
            />
            <DisplayColumn
                id='ho.coverage.losspayee.interestType'
                header='Interest Type'
                sortable={false}
                renderCell={(lossPayee) => 
                    <InputField
                        labelPosition= {labelPosition}
                        value={_.get(lossPayee, 'type') === 'LOSSP'? 'Loss Payee' : null }
                        readOnly
                    />
                }
            />
            <DisplayColumn
                id='ho.coverage.losspayee.sendBill'
                header='Send Bill'
                sortable={false}
                renderCell={(lossPayee) => 
                    <InputField
                        labelPosition= {labelPosition}
                        value={_.get(lossPayee, 'sendBill_Ext') ? 'true' : 'false'}
                        readOnly
                    />
                }
            />
            <DisplayColumn
                id='ho.coverage.losspayee.contractNumber'
                header='Loan Number'
                sortable={false}
                renderCell={(lossPayee) => {
                    const ContractNumberInputField = () => {
                        const [contractNumber, setContractNumber] = useState(_.get(lossPayee, 'contractNumber'))
                        return <InputField
                            labelPosition= {labelPosition}
                            value={contractNumber}
                            readOnly={!isEditable}
                            onValueChange={(value) => {
                                setContractNumber(value)
                            }}
                            onBlur={async () => {
                                if (contractNumber === _.get(lossPayee, 'contractNumber')) {
                                    return;
                                }
                                const newLossPayees = _.clone(lossPayees).map((oldLossPayee) => {
                                    const getLossPayeeFixedId = (lossP) => _.get(lossP, 'fixedId')
                                    if (getLossPayeeFixedId(oldLossPayee) === getLossPayeeFixedId(lossPayee)) {
                                        return {...oldLossPayee, contractNumber}
                                    }
                                    return oldLossPayee
                                })
                                await handleLossPayeeChange(newLossPayees);
                            }}
                        />
                    }
                    return <ContractNumberInputField />
                    
                }
                    
                }
            />
            <DisplayColumn
                id='action'
                sortable ={false}
                renderCell={(item) => {
                    return <IconButton
                        iconClassName= "btn-text"
                        className= "btn-text"
                        icon= "gw-delete"
                        disabled={!isEditable}
                        onClick={async () => {
                            const newLossPayees = lossPayees
                                .filter((lossPayee) => _.get(lossPayee, 'fixedId')
                                    !== _.get(item, 'fixedId'))
                            await handleLossPayeeChange(newLossPayees)
                        }}
                    />
                }}
            />
        </DataTable>      
        {
            isCreateAdditionalInterestPanelVisible ? <HOCreateAdditionalInterestPanel
                additionalInterests={additionalInterests}
                setIsCreateAdditionalInterestPanelVisible={setIsCreateAdditionalInterestPanelVisible}
                onSaveAdditionalInterestFn={onSaveAdditionalInterestFn}
                onGettingAdditionalInterestTypeOptions={onGettingAdditionalInterestTypeOptions}
                accountContacts={accountContacts}
            /> : null
        }
    </>
}

export default HOSSPLossPayeeComponent;