import React, {
    Fragment,
    useContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useErrors, ErrorBoundary } from '@xengage/gw-portals-error-react';
import { WizardUtil } from 'wni-portals-util-js';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as customMessages } from 'wni-platform-translations';
import { WizardContext, WizardPage } from '@xengage/gw-portals-wizard-react';
import { WizardConstants, PortalConstants } from 'wni-portals-config-js';
import { WizardErrorContext, ErrorHandlingWizardPage } from 'wni-portals-wizard-react';
// import { BaseWizardPage } from 'wni-portals-wizard-react';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';

import { useWniModal } from 'wni-components-platform-react';

import { WniChangeConflict } from 'wni-capability-gateway';
import { ChangeConflictsComponent } from 'wni-capability-gateway-react';

import { useAuthentication } from '@xengage/gw-digital-auth-react';

/**
 * 
 * Derived from HOWizardPage
 * 
 * Solution Proposals for handling onNext errors:
 * 1) Each page calls WniCommonQuoteService.getErrorsAndWarnigns() on its own onNext,
 *      and manullay update wizardPageData[wizardPageErrorsAndWarnings]
 * 2) Set "checkErrorsAndWarningsOnPageNext" on ErrorHandlingWizardPage to let it handle the task automatically;'
 *      Note that similar feature could be introduced for page initialization, e.g. checkErrorsAndWarningsOnPageInit
 * 3) 
 * 
 * @param {object} props 
 * @returns {function}
 */
function CommercialWizardPage(props) {
    const {
        quoteService,
        quoteChangeService,
        // isReadOnly = false,
        // transactionType = PortalConstants.TRANSACTIONTYPE_SUBMISSION,
        // =============================
        onNext: originalOnPageNext,
        ...otherProps
    } = props;

    
    const modalApi = useWniModal();
    const translator = useTranslator();
    const wizardContext = useContext(WizardContext);
    const wizardErrorContext = useContext(WizardErrorContext);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [showErrors, updateShowErrors] = useState(false);
    const { authHeader } = useAuthentication();

    
    const {
        wizardData: submissionVM,
        //
        steps: wizardSteps,
        currentStepIndex,
        //
        updateWizardPageData,

        //
        wizardTransactionType: transactionType = PortalConstants.TRANSACTIONTYPE_SUBMISSION,
    } = wizardContext;

    
    //
    const {
        jobID, sessionUUID,
        baseData: {
            periodStatus,
        },
    } = submissionVM.value;
    


    let isNextQuotePage = false;
    if (_.get(wizardSteps[currentStepIndex + 1], PortalConstants.ATTR_SHOULD_PROCESS_QUOTE)) {
        isNextQuotePage = true;
    }

    // ===============================================
    const onPreQuoteNextForSubmission = useCallback(async () => {
        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
            setLoadingMask(true);
            submissionVM.value = await quoteService.processQuote(
            // submissionVM.value = await processQuote(
                jobID, sessionUUID, authHeader
            );
            setLoadingMask(false);
        }
        return submissionVM;
    }, []);

    // ===============================================

    const showChangeConflictsModal = useCallback(
        (modalVM) => {
            const baseVM = modalVM.baseData;
            const policyNumber = _.get(modalVM.value, 'changeData.policyNumber');
            const componentProps = {
                title: 'Change Conflicts',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'Submit',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                authHeader: authHeader,
                baseVM,
                jobID,
                policyNumber,
            };
            return modalApi.showModal(<ChangeConflictsComponent {...componentProps} />);
        },
        [authHeader, jobID]
    );

    const goToNext = async () => {
        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({
                [WizardConstants.sideBySideData]: undefined,
                [WizardConstants.policyDiffData]: undefined,
                [WizardConstants.policyChangeDocumentsData]: undefined,
            });
            setLoadingMask(true)
            submissionVM.value = await quoteChangeService.processQuote(
                jobID, sessionUUID, authHeader
            );
        }
        return submissionVM;
    };

    const handleConflict = async () => {
        const conflictRes = await WniChangeConflict.getChangeConflictData(jobID, authHeader);
        if (!_.isUndefined(conflictRes.length) && conflictRes.length > 0) {
            setLoadingMask(false)
            const newSubmissionVM = await showChangeConflictsModal(submissionVM)
                .then(goToNext)
                .catch(() => {
                    _.noop();
                    return false;
                });
            return newSubmissionVM;
        }
        return goToNext();
    };

    const onChangePageNext = async () => {
        return handleConflict();
    };
    const onPreQuoteNextForPolicyChange = useCallback(async () => {
        return onChangePageNext();
    }, [onChangePageNext]);


    // ===============================================

   
    const onNext = useCallback(async (wizardData) => {
        let retval = wizardData;

        // if (isReadOnly) {
        //     return retval;
        // }
        
        retval = await originalOnPageNext(wizardData);
        if (retval === false) {
            return retval; // see BaseWizardPage.jsx::checkContinue()
        }
        
        if (isNextQuotePage) {
            if (transactionType === PortalConstants.TRANSACTIONTYPE_SUBMISSION) {
                retval = onPreQuoteNextForSubmission();
            } else if (transactionType === PortalConstants.TRANSACTIONTYPE_POLICYCHANGE) {
                retval = onPreQuoteNextForPolicyChange();
            }
        }

        return retval;
    }, [originalOnPageNext, isNextQuotePage]);

    // ================================
    return (
        <ErrorHandlingWizardPage
            onNext={onNext}
            {...otherProps}
        />
        
    );
};

CommercialWizardPage.propTypes =  {
    ...ErrorHandlingWizardPage.propTypes, // WizardPage undefined
    //
    quoteService: PropTypes.shape({}).isRequired,
    quoteChangeService: PropTypes.shape({}).isRequired,
    // isReadOnly: PropTypes.bool,
    // transactionType: PropTypes.string,
};
CommercialWizardPage.defaultProps = {
    ...ErrorHandlingWizardPage.defaultProps,
    //
     // would cause problem if other compoonents designates the same default props;
    // transactionType: PortalConstants.TRANSACTIONTYPE_SUBMISSION,
};
export default CommercialWizardPage; // ErrorHandlingWizardPage;
