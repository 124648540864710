import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm,ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage,wizardProps } from '@xengage/gw-portals-wizard-react';
import { claimsMessages } from 'gw-capability-claim-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import _ from 'lodash';
import React,{ useCallback,useContext,useEffect,useMemo,useState } from 'react';
import { withRouter } from 'react-router-dom';
import { WniGatewayClaimService } from "wni-capability-gateway-claim"
import { ProductUtil } from 'wni-portals-util-react';

import { ClaimService } from "wni-capability-claim";
import { IconButton, Button, InputField, DropdownSelectField, PhoneNumberField } from '@jutro/legacy/components';
import { ClaimDocumentService} from "gw-capability-claimdocument/index"
import messages from '../../FNOL.messages';
import Claim, {saveFNOLDetails} from '../../models/Claim';
import customMessages from './AdditionalInformationPage.messages';
import metadata from './AdditionalInformationPage.metadata.json5';
import styles from './AdditionalInformationPage.module.scss';

const ROLES_TO_FILTER = ['witness', 'other'];
const ClaimDownloadService = ClaimDocumentService

const getPhoneFieldBasedOnPrimaryPhoneType = (relatedContact) => {
    let primaryPhoneType;
    switch (relatedContact.contact.primaryPhoneType) {
        case 'home':
            primaryPhoneType = 'homeNumber';
            break;
        case 'work':
            primaryPhoneType = 'workNumber';
            break;
        case 'mobile':
            primaryPhoneType = 'cellNumber';
            break;
        default:
            primaryPhoneType = 'homeNumber';
    }
    return primaryPhoneType;
};

const { 
    PA_PRODUCT_CODE,
    WC_LOB_NAME,
    // Neither ProductUtil nor FnolProductUtil includes GL_LINE and CP_LINE. This is likely an existing issue in CE
    GL_LINE,
    CP_LINE,
    FNOL_LOB_CR
} = ProductUtil;


function FNOLAdditionalInformationPage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM, updateWizardData, authHeader
    } = props;
    // const { ClaimDownloadService } = useDependencies('ClaimDownloadService');
    // const { ClaimService } = useDependencies('ClaimService');
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [claimObj, updateClaimObj] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(
        'FNOLAdditionalInformationPage'
    );
    const {
        policy: {
            policyType,
            lobCode_Ext: lobCode
        }
    } = claimVM.value;

    const showInjuredColumn = useMemo(
        () => {
            const policyTypes = ['InlandMarine', 'GeneralLiability', 'CommercialProperty', 'BusinessOwners'];
            const isCPPwithGLOrCP = policyType === 'CommercialPackage' && ([GL_LINE, CP_LINE].includes(lobCode));
            return !policyTypes.includes(policyType) && !isCPPwithGLOrCP;
        },
        // Policy type won't change on re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const isWCLine = useMemo(
        () => {
            return policyType === 'WorkersComp'
        },
        [policyType]
    );

    const isGLOrCPLine = useMemo(
        () => {
            return ['GeneralLiability', 'CommercialProperty'].includes(policyType) || (
                policyType === 'CommercialPackage' && ([GL_LINE, CP_LINE].includes(lobCode))
            )
        },
        [lobCode, policyType]
    );

    const isCRLine = useMemo(
        () => {
            return policyType === 'Crime' || (
                policyType === 'CommercialPackage' && ([FNOL_LOB_CR].includes(lobCode))
            )
        },
        [lobCode, policyType]
    );


    const isWALLine = useMemo(
        () => {
            return policyType === 'Watercraft_EXT'
        },
        [policyType]
    );

    const writeValue = useCallback(
        (value, path) => {
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
            _.set(claimObj, 'relatedContacts', claimVM.relatedContacts.value);
        },
        [claimObj, claimVM, updateWizardData]
    );

    const handleError = useCallback(
        (title, message) => {
            return modalApi.showAlert({
                title: title,
                message: message,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }, []
    );

    useEffect(() => {
        updateClaimObj(new Claim(claimVM.value));
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFirstLastNameData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, writeValue]
    );

    const getOfficialNameData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `officials.value[${rowId}].${path}`;
            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    readOnly={item.contact?.publicID}
                />
            );
        },
        [claimVM, writeValue]
    );

    const getOfficialReportNoData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `officials.value[${rowId}].${path}`;
            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, writeValue]
    );

    const filteredRoleTypeList = useMemo(() => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'edge.capabilities.claim.fnol.dto.FnolRelatedContactDTO'
        );
        const filteredList = typeList.role.aspects.availableValues
            .filter((typeCode) => {
                return ROLES_TO_FILTER.includes(typeCode.code);
            })
            .map((typeCode) => {
                return {
                    code: typeCode.code,
                    name: translator({
                        id: typeCode.name,
                        defaultMessage: typeCode.name
                    })
                };
            });
        return filteredList;
    }, [translator, viewModelService]);

    const filteredOfficialTypeList = useMemo(() => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'wni.edge.capabilities.claim.official.dto.WniOfficialDTO'
        );
        const filteredList = typeList.type.aspects.availableValues
            .map((typeCode) => {
                return {
                    code: typeCode.code,
                    name: translator({
                        id: typeCode.name,
                        defaultMessage: typeCode.name
                    })
                };
            });
        return filteredList;
    }, [translator, viewModelService]);

    const availableValuesFromVM = useCallback((path) => {
        const originAvailableValues = _.get(claimVM, `${path}.aspects.availableValues`)
        return (originAvailableValues).map((value)=>{
            return {
                code: value.code,
                name: translator({
                    id: value.name,
                    defaultMessage: value.name
                })
            }
        })
    }, [claimVM, translator]);

    const availableTypesForMetroReport = useCallback((path) => {
        let availableValues = _.get(claimVM, `${path}.aspects.availableValues`)
        if(isWALLine){
            availableValues = availableValues.filter((value) => {
                return value.code !== 'R' && value.code !== 'S'
            })
        }
        return (availableValues).map((value)=>{
            return {
                code: value.code,
                name: translator({
                    id: value.name,
                    defaultMessage: value.name
                })
            }
        })
    }, [claimVM, isWALLine, translator]);

    const getInvolvementInjuredData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            let availableValues;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            if (id === 'role') {
                availableValues = filteredRoleTypeList;
            } else if (id === 'witnessPossion_Ext'){
                availableValues = availableValuesFromVM('relatedContacts.children[0].witnessPossion_Ext');
            } else {
                availableValues = [
                    {
                        code: 'true',
                        name: translator(messages.fnolYes)
                    },
                    {
                        code: 'false',
                        name: translator(messages.fnolNo)
                    }
                ];
            }
            return (
                <DropdownSelectField
                    id={`${id}_${rowId}`}
                    alwaysShowPlaceholder={false}
                    availableValues={availableValues}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    className={styles.involvmentInjuredDropDown}
                />
            );
        },
        [availableValuesFromVM, claimVM, filteredRoleTypeList, translator, writeValue]
    );

    const getOfficialTypeData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `officials.value[${rowId}].${path}`;
            return (
                <DropdownSelectField
                    id={`${id}_${rowId}`}
                    alwaysShowPlaceholder={false}
                    availableValues={filteredOfficialTypeList}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, filteredOfficialTypeList, writeValue]
    );

    const getMetroReportTypeData = useCallback((item, rowId, property) => {
        const { id, path } = property;
            const claimVMPath = `metroReports.value[${rowId}].${path}`;
            return (
                <DropdownSelectField
                    id={`${id}_${rowId}`}
                    alwaysShowPlaceholder={false}
                    availableValues={availableTypesForMetroReport('metroReports.children[0].type')}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
    }, [claimVM, availableTypesForMetroReport, writeValue])

    const getAgentCityData = useCallback((item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `metroReports.value[${rowId}].${path}`;
            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        }, [claimVM, writeValue]);

    const getAgentStateData = useCallback((item, rowId, property) => {
        const { id, path } = property;
        const claimVMPath = `metroReports.value[${rowId}].${path}`;
        const value = _.get(claimVM, claimVMPath)
        const metroValue = _.get(claimVM, `metroReports.value[${rowId}]`)
        /**
         * Add Country value to filte state option
         */
        metroValue.country = 'US'
        return (
            <DropdownSelectField
                id={`${id}_${rowId}`}
                alwaysShowPlaceholder={false}
                availableValues={availableValuesFromVM('metroReports.children[0].agentState')}
                path={claimVMPath}
                onValueChange={writeValue}
                value={value}
            />
        );
    }, [availableValuesFromVM, claimVM, writeValue])

    const getPhoneNumberData = useCallback(
        (item, rowId) => {
            const phoneField = getPhoneFieldBasedOnPrimaryPhoneType(item);
            const claimVMPath = `relatedContacts.value[${rowId}].contact.${phoneField}`;
        
            return (
                <PhoneNumberField
                    id={`${item.primaryPhoneID}_${rowId}`}
                    dataType="string"
                    onValueChange={writeValue}
                    path={claimVMPath}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, writeValue]
    );

    const showDocumentDeleteIcon = useCallback((documentUploadItem) => {
        if (documentUploadItem.canDelete) {
            return true;
        }
        return false;
    }, []);

    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();
            const { claimNumber, publicID, name } = item;
            modalApi.showConfirm({
                title: claimsMessages.confirmationToRemoveTitle,
                message: translator(messages.fnolRemoveUploadedDocument, { documentName: name }),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: claimsMessages.removeConfirmationYes,
                cancelButtonText: claimsMessages.removeConfirmationNo
            }).then(async (results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                setShowLoader(true);
                try {
                    const isDeleteItem = await WniGatewayClaimService.claimsRemoveDocument(
                        [claimNumber, publicID],
                        authHeader
                    );
                    if (isDeleteItem === false) {
                        setShowLoader(false);
                        return handleError(
                            claimsMessages.deletionFailedTitle,
                            claimsMessages.deletionFailedMessage
                        );
                    }
                    const documentsArray = _.get(claimVM, 'documents.value');
                    const updatedDocArray = documentsArray.filter((documentObj) => {
                        return documentObj.publicID !== publicID;
                    });
                    const newClaimVM = _.clone(claimVM);
                    _.set(newClaimVM, 'documents.value', updatedDocArray);
                    updateWizardData(newClaimVM);
                    setShowLoader(false);
                    return true;
                } catch (documentDeletionError) {
                    setShowLoader(false);
                    return handleError(
                        claimsMessages.removeServiceFailedTitle,
                        claimsMessages.removeServiceFailedMessage
                    );
                }
            }, _.noop);
        },
        [modalApi, translator, authHeader, claimVM, updateWizardData, handleError, WniGatewayClaimService]
    );

    const handleDocumentDownload = useCallback(
        (item) => {
            if (_.isNil(item)) {
                return null;
            }
            const { workingPublicID, sessionID } = item;
            return ClaimDownloadService
                .getClaimDocument(workingPublicID, sessionID);
        },
        [ClaimDownloadService]
    );

    const generateMoreInfoDocumentsOverrides = useCallback(() => {
        const documentUploadPath = 'value.documents';
        const documentUploadItems = _.get(claimVM, documentUploadPath, []);
        const overrides = documentUploadItems.map((documentUploadItem, index) => {
            return {
                [`documentNameLink${index}`]: {
                    href: handleDocumentDownload(documentUploadItem),
                    content: _.get(documentUploadItem, 'name')
                },
                [`documentDeleteIcon${index}`]: {
                    onClick: (e) => {
                        onDeleteDocumentIcon(e, documentUploadItem);
                    },
                    visible: showDocumentDeleteIcon(documentUploadItem)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [
        claimVM,
        handleDocumentDownload,
        onDeleteDocumentIcon,
        showDocumentDeleteIcon
    ]);

    const getContactsRelatedPersons = useCallback((contactsItem) => {
        if (contactsItem.subtype === 'Person' && contactsItem.lastName) {
            return `${contactsItem.firstName} ${contactsItem.lastName}`;
        }
        return '';
    }, []);

    const generateMoreInfoAddPersonOverrides = useCallback(() => {
        const contactsPath = 'value.contacts';
        const contactsItems = _.get(claimVM, contactsPath, []);
        let availableRelatedContacts = [];
        if (!_.isEmpty(claimObj) && _.isFunction(claimObj.availableRelatedContacts)) {
            availableRelatedContacts = claimObj.availableRelatedContacts().filter((contact) => {
                return contact.subtype === 'Person' && contact.firstName;
            });
        }

        if (!contactsItems.length) {
            return {};
        }
        const overrides = contactsItems.map((contactItem, index) => {
            return {
                [`contactItemsDropDown${index}`]: {
                    content: getContactsRelatedPersons(contactItem),
                    visible: _.includes(availableRelatedContacts, contactItem)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [claimVM, claimObj, getContactsRelatedPersons]);

    const removeContactItem = useCallback(
        (e, rowId) => {
            e.preventDefault();
            const { relatedContacts } = claimVM;
            const relatedContactToDelete = relatedContacts.value.filter((relatedContact, index) => {
                return index === rowId;
            });
            claimObj.removeRelatedContact(relatedContactToDelete[0].contact);
            _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            updateWizardData(claimVM);
        },
        [claimVM, claimObj, updateWizardData]
    );

    const removeOfficialItem = useCallback(
        // (e, rowId) => {
        (e, rowId) => {
            e.preventDefault();
            const { officials } = claimVM;
            const officialToDelete = officials.value.find((relatedContact, index) => {
                return index === rowId;
            });
            claimObj.removeofficial(officialToDelete);
            _.set(claimVM.value, 'officials', claimObj.officials);
            updateWizardData(claimVM);
        },
        [claimVM, claimObj, updateWizardData]
    );

    const removeMetroReportItem = useCallback((e, rowId) => {
        e.preventDefault();
            const { metroReports } = claimVM;
            const metroReportToDelete = metroReports.value.find((relatedContact, index) => {
                return index === rowId;
            });
            claimObj.removeMetroReport(metroReportToDelete);
            _.set(claimVM.value, 'metroReports', claimObj.metroReports);
            updateWizardData(claimVM);
    }, [claimVM, claimObj, updateWizardData])

    const removeContactRow = useCallback(
        (item, index) => {
            return (
                <IconButton
                    icon="gw-delete"
                    onClick={(e) => removeContactItem(e, index, item)}
                />
            );
        },
        [removeContactItem]
    );

    const removeOfficialRow = useCallback(
        (item, index) => {
            return (
                <IconButton
                    icon="gw-delete"
                    onClick={(e) => removeOfficialItem(e, index, item)}
                />
            );
        },
        [removeOfficialItem]
    );

    const removeMetroReportRow = useCallback((item, index) => {
        return (
            <IconButton
                icon="gw-delete"
                onClick={(e) => removeMetroReportItem(e, index, item)}
            />
        );
    }, [removeMetroReportItem])

    const generateNewWitnessRow = useCallback(
        (event) => {
            event.preventDefault();
            const { contacts } = claimVM;
            const getCurrentMenuItemID = event.currentTarget.id;
            if (getCurrentMenuItemID.indexOf('anotherPersonLink') === -1) {
                const getIndex = parseInt(getCurrentMenuItemID.match(/[0-9]/gi), 10);
                const selectedContact = contacts.value[getIndex];
                claimObj.addRelatedContact(selectedContact);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            } else {
                claimObj.addRelatedContact(null);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            }
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, claimObj]
    );

    const generateNewOfficialRow = useCallback(
        (event) => {
            event.preventDefault();
            claimObj.addOfficial(null);
            _.set(claimVM.value, 'officials', claimObj.officials);
            
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, claimObj]
    );

    const generateNewMetroReportRow = useCallback(
        (event) => {
            event.preventDefault();
            claimObj.addMetroReport(null);
            _.set(claimVM.value, 'metroReports', claimObj.metroReports);
            
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, claimObj]
    );

    const generateAddPersonMenuDropDown = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen } = dropDownProps;
            const onAddPersonClick = () => {
                toggleMenu(!isOpen);
            };
            return (
                <Button onClick={onAddPersonClick} icon="gw-expand-more" iconPosition="right">
                    {translator(messages.fnolAddPerson)}
                </Button>
            );
        },
        [translator]
    );

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID = await WniGatewayClaimService.claimsDocUploadToken([], authHeader);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }, [WniGatewayClaimService, authHeader, handleError]);

    const onUploadDocument = useCallback(
        async (file) => {
            setShowLoader(true);
            const documentMetaDataTemplate = {
                DocUID: '001',
                DocumentType: 'fnol',
                SecurityType: 'unrestricted',
                Status: 'approved',
                Author: 'Policy Holder',
                claimNumber: claimVM.claimNumber.value,
                name: file.name,
                mimeType: file.type,
                sessionID: await claimsDocUploadToken()
            };
            try {
                const data = await WniGatewayClaimService.uploadDocument(
                    file,
                    documentMetaDataTemplate,
                    authHeader
                );
                const documentsArray = _.get(claimVM, 'documents.value');
                documentsArray.push(data);
                const newClaimVM = _.clone(claimVM);
                _.set(newClaimVM, 'documents.value', documentsArray);
                updateWizardData(newClaimVM);
                setShowLoader(false);
            } catch (error) {
                setShowLoader(false);
                handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
            }
        },
        [claimVM, claimsDocUploadToken, ClaimService, authHeader, updateWizardData, handleError]
    );

    const findRelatedContactValid = (itemVM) => {
        const phonePath = getPhoneFieldBasedOnPrimaryPhoneType(itemVM.value);
        return _.get(itemVM, 'aspects.valid') && _.get(itemVM, 'aspects.subtreeValid') && _.get(itemVM.value, `contact.${phonePath}`, null)
    };

    const findItemValid = useCallback((basePath) => {
        const isValid =  _.get(claimVM, `${basePath}.aspects.valid`) && _.get(claimVM, `${basePath}.aspects.subtreeValid`);
        if(basePath === 'relatedContacts') {
            const allItemVMs = _.get(claimVM, `${basePath}.children`);
            
            const itemsValid = allItemVMs.every((vm) => findRelatedContactValid(vm));
            return isValid && itemsValid
        }
        return isValid
        
    },[claimVM])

    const renderHeader = (label) => {
        return (
            <div>
                <span>{label}</span>
                <span className="font-error ml-5">*</span>
            </div>
        );
    };

    const validateTableForm = useCallback(() => {
        const isRelatedContactValid = findItemValid('relatedContacts');
        const isMotroReportValid = findItemValid('metroReports');
        const isOfficialsValid =findItemValid('officials');
        if (
            !isRelatedContactValid || !isMotroReportValid || !isOfficialsValid
        ) {
            return false;
        }
        return true;
    }, [findItemValid]);

    const onNext = useCallback(async () => {
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        return claimVM
    }, [authHeader, claimVM]);

    const getAddPersonButtonDropdownText = () => {
        if(isWCLine && isGLOrCPLine) {
            return customMessages.addPersonButtonDefaultText;
        } 
        if(isCRLine) {
            return customMessages.addPersonButtonCRText;
        }
        return customMessages.addPersonButtonPAText;
    }

    const overrides = {
        addtionalInformationLoader: {
            loaded: !showLoader,
        },
        addPersonDropDownItems: {
            visible: false,
        },
        anotherPersonLink: {
            visible: !isWCLine && !isGLOrCPLine && !isCRLine,
        },
        metroReportLink: {
            visible: false,
        },
        uploadDocumentGrid: {
            visible: !showLoader,
        },
        witnessPartyTable: {
            data: claimVM.relatedContacts.value,
            visible: !_.isEmpty(claimVM.relatedContacts.value),
        },
        metroReportTable: {
            data: claimVM.metroReports?.value,
            visible: !_.isEmpty(claimVM.metroReports?.value),
        },
        officialTable: {
            data: claimVM.officials?.value,
            visible: !_.isEmpty(claimVM.officials?.value),
        },
        tipsForUploadFile: {
            visible: false,
        },
        injuredHeader: {
            visible: showInjuredColumn,
        },
        addPersonButtonDropdownMenu: {
            buttonText: getAddPersonButtonDropdownText()
        },
        WithnessOtherPartiesInfoLabel: {
            content: isWCLine
                ? customMessages.respondingOfficialWCTitle
                : customMessages.witnessesOrOtherPartiesText,
        },
        involvementHeader: {
          visible: _.get(claimVM, 'value.lobCode') !== 'HomeownersLine_HOE'
        },
        ...generateMoreInfoDocumentsOverrides(),
        ...generateMoreInfoAddPersonOverrides(),
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            generateWitnessPartyTableRow: generateNewWitnessRow,
            generateMetroReportTableRow: generateNewMetroReportRow,
            generateOfficialTableRow: generateNewOfficialRow,
            getFirstNameData: getFirstLastNameData,
            getLastNameData: getFirstLastNameData,
            getNameData: getOfficialNameData,
            getOfficialReportNoData,
            getInvolvementData: getInvolvementInjuredData,
            getPhoneNumberData: getPhoneNumberData,
            getInjuredData: getInvolvementInjuredData,
            getOfficialTypeData: getOfficialTypeData,
            getMetroReportTypeData: getMetroReportTypeData,
            getAgentCityData: getAgentCityData,
            getAgentStateData: getAgentStateData,
            removeContactRow: removeContactRow,
            removeOfficialRow,
            removeMetroReportRow,
            onUploadDocument: onUploadDocument,
            renderTrigger: generateAddPersonMenuDropDown,
            renderHeader
        }
    };

    useEffect(() => {
        registerComponentValidation(validateTableForm);
    }, [registerComponentValidation, validateTableForm]);

    return (
        <WizardPage
            cancelLabel={translator(messages.fnolSaveandExit)}
            disableNext={!isComponentValid}
            template={WizardPageTemplate}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}
FNOLAdditionalInformationPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLAdditionalInformationPage));
