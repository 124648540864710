import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentHeader: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Policy Change - Homeowners',
        defaultMessage: 'Policy Change - Homeowners'
    },
    accountTitleHo: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Account:',
        defaultMessage: 'Account:'
    },
    hoPolicyChangeCoverages: {
        id: 'endorsement.ho.directives.templates.ho-coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    hoPolicyChangeValuables: {
        id: 'endorsement.ho.directives.templates.ho-valuables.Valuables',
        defaultMessage: 'Valuables'
    },
    hoPolicyChangeMortgagee: {
        id: 'endorsement.ho.directives.templates.ho-mortgagee.Mortgagee',
        defaultMessage: 'Mortgagee'
    },
    hoPolicyChange: {
        id: 'endorsement.ho.directives.templates.ho-policy-changes.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    hoPolicyChangeQuotes: {
        id: 'endorsement.common.views.history.Quote',
        defaultMessage: 'Quote'
    },
    hoPolicyChangePayments: {
        id: 'endorsement.ho.directives.templates.ho-coverages.Payments',
        defaultMessage: 'Payments'
    },
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-ho.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-ho.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    homeownersPolicyChange: {
        id: 'custom.endorsement.directives.templates.pa.Policy Changes - Homeowner',
        defaultMessage: 'Home({policyNumber})'
    },
});
