import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalCoverages: {
        "id": "quoteandbind.im.wizard.step.ValuableAdditionalCov page.additional additionalCoverages",
        "defaultMessage": "Additional Coverages"
    },
    AddAdditionalCoverages: {
        "id": "quoteandbind.im.wizard.step.ValuableAdditionalCov page.Add AdditionalCoverages",
        "defaultMessage": "Add Additional Coverages"
    },
    AddedAdditionalCoverages: {
        "id": "quoteandbind.im.wizard.step.ValuableAdditionalCov page.Added AdditionalCoverages",
        "defaultMessage": "Added Additional Coverages"
    },
});