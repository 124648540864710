import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imBaileeCustomersFloaterService'), method, data, additionalHeaders);
}

export default class IMBaileeCustomersFloaterService {
  
    /**
     * Invokes IMBaileeCustomersFloaterHandler.getBaileeCustomersFloaterClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getBaileeCustomersFloaterClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getBaileeCustomersFloaterClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.getBaileeCustomersFloaterClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getBaileeCustomersFloaterClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getBaileeCustomersFloaterClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.updateBaileeCustomersFloaterClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} coverageToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBaileeCustomersFloaterClauses(jobNumber, sessionUUID, coverageToUpdate, authHeader = {}) {
        return processSubmission('updateBaileeCustomersFloaterClauses', [jobNumber, sessionUUID, coverageToUpdate], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.updateCoveragePartSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }

    /**
     * Invokes IMBaileeCustomersFloaterHandler.onBaileeCustomersFloaterNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onBaileeCustomersFloaterNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onBaileeCustomersFloaterNext', [jobNumber, sessionUUID], authHeader);
    }
}