import React from 'react';
import  { CALocationsPage } from 'wni-capability-quoteandbind-ca-react';
import { WCLocationService } from 'wni-capability-quoteandbind-wc';

function WCLocationsPage(props) {

    return (
        <CALocationsPage 
            {...props}
            caLocationService={ WCLocationService }
            linePath = 'workersComp'
        />
    );
}

WCLocationsPage.propTypes = CALocationsPage.propTypes;
WCLocationsPage.defaultProps = CALocationsPage.defaultProps;
export default WCLocationsPage;