import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BaseAdditionalInterestDetailComponent from './BaseAdditionalInterestDetailComponent';

function AdditionalInterestDetailPopup(props) {
    const initProps = {
        ...props,
        isPopupMode: true
    };
    return (
        <BaseAdditionalInterestDetailComponent
            {...initProps}
        />
    );
}

AdditionalInterestDetailPopup.propTypes = {
    additionalInterestVM: PropTypes.shape({}).isRequired,
    emptyVM: PropTypes.shape({}),
    accountNumber: PropTypes.string.isRequired,
    accountContacts: PropTypes.shape([]),
    accountHolderBirthday: PropTypes.shape({}).isRequired,
    // authHeader: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    // licenseState: PropTypes.string,
    type: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    newSearchBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func,
    }).isRequired,
    readOnly: PropTypes.bool,
    getAdditionalInterestTypeOptions: PropTypes.func
};

AdditionalInterestDetailPopup.defaultProps = {
    // licenseState: '',
    size: 'md',
    readOnly: false,
    type: null,
    accountContacts: [],
    emptyVM: {},
    getAdditionalInterestTypeOptions: _.noop
};

export default AdditionalInterestDetailPopup;
