import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import messages from '../RTVehiclesPage.messages';
import metadata from './GaragingLocation.metadata.json5';
import { getVehicleOrTrailerLabel, getAvailableYears, getAvailableList, isFieldVisible } from '../util/Vehicle.util';
import { newGarageLocation } from '../config/Vehicle.static';

function GaragingLocation(props) {
    const {
        value: vehicleVM,
        onValueChange,
        syncWizardData,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance
    } = props;
    const {
        garagedLocation,
        garagedAtOptions = []
    } = vehicleVM.value;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const getGaragedAtOptions = (options) => {
        const generateOptions = options.map((location) => {
            return {
                code: location.publicID,
                name: location.displayName
            };
        });
        generateOptions.push(newGarageLocation);
        return generateOptions;
    };

    const writeValue = async(value, path) => {
        if(!value) {
            onValueChange(value, path);
            return false;
        }
        switch(path) {
            case "garagedLocation":
                if (value === 'new') {
                    _.set(vehicleVM, 'garagedAt_Ext', {
                        displayName: newGarageLocation.name,
                        country: newGarageLocation.country
                    });
                } else {
                    _.set(vehicleVM, 'garagedAt_Ext', garagedAtOptions.find((option) => {
                        return option.publicID === value;
                    }));
                }
                _.set(vehicleVM, path, value);
                syncWizardData(vehicleVM);
                break;
            default:
                break;
        }
    };
    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        garagingLocation: {
            availableValues: getGaragedAtOptions(garagedAtOptions),
            readOnly: isReadOnly
        },
        addressLookup: {
            model: vehicleVM,
            dataPath: 'garagedAt_Ext',
            onAddressChange: onValueChange,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true
            },
            showErrors,
            onValidate,
            readOnly: isReadOnly
        }
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, vehicleVM, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default GaragingLocation;
