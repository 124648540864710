
import React from 'react';
import { CRCommercialCrimeReadOnlyPage } from 'wni-capability-quoteandbind-cr-react';

function CRCommercialCrimeChangeReadOnlyPage(props) {

    return (
        <CRCommercialCrimeReadOnlyPage {...props} />
    );

}

CRCommercialCrimeChangeReadOnlyPage.propTypes = CRCommercialCrimeReadOnlyPage.propTypes;
CRCommercialCrimeChangeReadOnlyPage.defaultProps = CRCommercialCrimeReadOnlyPage.defaultProps;

export default CRCommercialCrimeChangeReadOnlyPage;