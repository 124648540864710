import React, {useState} from "react"
import _ from "lodash";
import { Button } from "@jutro/components";
import { DisplayColumn } from '@jutro/legacy/datatable';
import { WniDataTable, WniToggle } from "wni-common-base-components";
import { Flex } from "@jutro/layout";
import { useTranslator } from '@jutro/locale';
import messages from './PropertyIncidents.messages'
import SinglePropertyIncident from "./SinglePropertyIncident/SinglePropertyIncident";
import { getFullAddressDisplayName } from "../util/AddressUtil";


const PropertyIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const propertyIncidentInitValue = {
    ownerContact_Ext: {
        primaryAddress: {
            country: "US"
        }
    }
}

const PropertyIncidents = (props) => {
    const {
        propertyIncidents = [],
        updatePropertyIncidents,
    } = props;

    const translator = useTranslator();

    const [propertyIncidentQuestionValue, setPropertyIncidentQuestion] = useState(() => {
        return propertyIncidents.length > 0 ? PropertyIncidentQuestionValue.Yes : PropertyIncidentQuestionValue.No
    })
    const [singlePropertyIncidentPopupStatus, setSinglePropertyIncidentPopupStatus] = useState({
        isOpen: false,
        originPropertyIncident: propertyIncidentInitValue,
        editingPropertyIncidentIndex: undefined
    })

    const [selectedIncidentNumbers, setSelectedIncidentNumebrs] = useState([])

    const closePopup = () => {
        setSinglePropertyIncidentPopupStatus({
            isOpen: false,
            originPropertyIncident: propertyIncidentInitValue,
            editingPropertyIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = (propertyIncident, editingPropertyIncidentIndex) => {

        let updatedPropertyIncidents
        if (_.isNil(editingPropertyIncidentIndex)) {
            // new added item
            updatedPropertyIncidents = propertyIncidents.concat([propertyIncident])
        } else {
            // existing item
            updatedPropertyIncidents = propertyIncidents.toSpliced(editingPropertyIncidentIndex, 1, propertyIncident)
        }

        updatePropertyIncidents(updatedPropertyIncidents)
        closePopup()
    }

    return <>
        <WniToggle
            id='propertyIncidentQuestion'
            label={translator(messages.PropertyIncidentsQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: PropertyIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: PropertyIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setPropertyIncidentQuestion(value)
            }}
            disabled={propertyIncidents.length > 0}
            value={propertyIncidentQuestionValue}
        />
        {propertyIncidentQuestionValue === PropertyIncidentQuestionValue.Yes &&
            <>
                <Flex gap="small" justifyContent="right" className="mb-10">
                    <Button
                        type="primary"
                        onClick={() => setSinglePropertyIncidentPopupStatus({
                            isOpen: true,
                            originPropertyIncident: propertyIncidentInitValue
                        })}
                        disabled={singlePropertyIncidentPopupStatus.isOpen}
                        label={messages.AddProperty}
                    />
                    <Button
                        type="primary"
                        className="wni-button-danger"
                        onClick={() => {
                            const newPropertyIncidents = propertyIncidents.filter((_incident, index) => {
                                if (selectedIncidentNumbers.includes(index)) {
                                    return false
                                }
                                return true
                            })
                            updatePropertyIncidents(newPropertyIncidents)
                        }}
                        disabled={selectedIncidentNumbers.length === 0}
                        label={messages.DeleteProperty}
                    />
                </Flex>
                
                <WniDataTable
                    id="propertyIncidentsTable"
                    showSearch= {false}
                    showPagination= {false}
                    data={propertyIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                    rowIdpath="number"
                    selectionType="multi"
                    onSelectionChange={(selectedNumebrs) => setSelectedIncidentNumebrs(selectedNumebrs)}
                    selectedRows={selectedIncidentNumbers}
                >
                    <DisplayColumn
                        id="propertySectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="propertyLocationColumn"
                        header={translator(messages.PropertyLocation)}
                        textAlign='center'
                        renderCell = {(item) => {
                            const address = _.get(item, 'ownerContact_Ext.primaryAddress')
                            return getFullAddressDisplayName(address)
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {

                            return <Flex gap="small" justifyContent="right" className="mb-10">
                                <Button
                                    className="btn-link"
                                    onClick={() => setSinglePropertyIncidentPopupStatus({
                                        isOpen: true,
                                        originPropertyIncident: item,
                                        editingPropertyIncidentIndex: index
                                    })}
                                    label={messages.ViewOrEdit}
                                />
                            </Flex>
                        }}
                    />
                </WniDataTable>
                
                {singlePropertyIncidentPopupStatus.isOpen && <SinglePropertyIncident
                    originPropertyIncident={singlePropertyIncidentPopupStatus.originPropertyIncident}
                    editingPropertyIncidentIndex={singlePropertyIncidentPopupStatus.editingPropertyIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                />}
            </>
        }
        
    </>
}

export default PropertyIncidents
