import _ from 'lodash';

export default class CPPClaimExtension {
    constructor(claim) {
        Object.defineProperty(this, 'claim', {
            enumerable: false,
            value: claim
        });
        _.extend(this, this.claim.lobs.commercialPackage);
    }
}
