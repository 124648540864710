
import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { BaseRecommendCoveragesComponent } from 'wni-capability-gateway-react';


function DPRecommendCoveragesComponent(props) {

    const {
        goToCoveragesPage,
    } = props;

    const getCovJumpFn = useCallback((idPrefix, coveragePublicID) => {
        // See SingleClauseComponentVM.generateMetadata()
        return () => {
            goToCoveragesPage();
        };
    }, [goToCoveragesPage]);


    return (
        <BaseRecommendCoveragesComponent
            {...props}
            getCovJumpFn={getCovJumpFn}
        />
    );
}

DPRecommendCoveragesComponent.defaultProps = {
    goToCoveragesPage: PropTypes.func.isRequired,
    //
    ...BaseRecommendCoveragesComponent.defaultProps,
}

DPRecommendCoveragesComponent.defaultProps = {
    ...BaseRecommendCoveragesComponent.defaultProps,
}

export default DPRecommendCoveragesComponent;
