import { defineMessages } from 'react-intl';

export default defineMessages({
    searchQuoteDocuments: {
        id: 'gateway.directives.gateway-documents.Search Quotes',
        defaultMessage: 'Search Product, Transaction Number, Account'
    },
    searchPolicyNumber: {
        id: 'gateway.views.policy-landing.Search Policy Number or Name',
        defaultMessage: 'Search Policy Number or Name'
    },
    searchTransactionNumber: {
        id: 'gateway.views.policy-landing.Search Transaction Number or Name',
        defaultMessage: 'Search Transaction Number or Name'
    },
    status: {
        id: 'gateway.views.policy-landing.Status Action',
        defaultMessage: 'Status'
    },
    prefilledQuote: {
        id: 'gateway.views.policy-landing.Prefilled Quote',
        defaultMessage: 'Prefilled Quote'
    },
    transactionNumber: {
        id: 'gateway.views.policy-landing.Transaction Number',
        defaultMessage: 'Transaction Number'
    },
    inGoodStanding: {
        id: 'gateway.views.policy-landing.InGoodStanding',
        defaultMessage: 'In Good Standing'
    },
    delinquent: {
        id: 'gateway.views.policy-landing.Delinquent Info',
        defaultMessage: 'Delinquent',
    },
    policies: {
        id: 'gateway.views.policy-landing.Policies Info',
        defaultMessage: 'Policies',
    },
    policyLandingQuotes: {
        id: 'gateway.views.policy-landing.Quotes Info',
        defaultMessage: 'Quotes'
    },
    policyLandingRenewal: {
        id: 'gateway.views.policy-landing.Renewal Info',
        defaultMessage: 'Renewal'
    },
    policyLandingChange: {
        id: 'gateway.views.policy-landing.Change Info',
        defaultMessage: 'Change'
    },
    policyLandingCancellation: {
        id: 'gateway.views.policy-landing.Cancellation Info',
        defaultMessage: 'Cancellation'
    },
    policyLandingRewrite: {
        id: 'gateway.views.policy-landing.Rewrite Info',
        defaultMessage: 'Rewrite'
    },
    changePolicy: {
        id: 'gateway.directives.PolicySummary.Change Policy',
        defaultMessage: 'Change Policy'
    },
    cancelPolicy: {
        id: 'gateway.directives.PolicySummary.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },
    warningMsg: {
        id: 'gateway.directives.PolicySummary.warning Policy Message',
        defaultMessage: 'Our records indicate that you do not have servicing rights on this policy. Please contact your Underwriter for further assistance'
    },
    aosTooltipMessage: {
        id: 'gateway.directives.PolicySummary.aosTooltipMessage',
        defaultMessage: 'Please use the arrow on the right to select an action (policy change or cancel). For any other servicing request, please contact your Underwriter for assistance.'
    },
    mostRecentMsg: {
        id: 'gateway.directives.PolicySummary.The 30 most recently viewed transactions are displayed, please expand search by transaction number or name as needed.',
        defaultMessage: 'The 30 most recently viewed transactions are displayed, please expand search by transaction number or name as needed.'
    },
    blockChangeTitle: {
        id: 'gateway.directives.PolicySummary.blockChangeTitle',
        defaultMessage: 'Transaction Conflict'
    },
    oosTransaction: {
        id: 'gateway.directives.PolicySummary.oosTransaction',
        defaultMessage: 'Due to additional policy changes in progress, this transaction is identified as out of sequence. Please contact your underwriter or start a new policy change in the most recently updated policy.'
    },
    preemptiveTransaction: {
        id: 'gateway.directives.PolicySummary.preemptiveTransaction',
        defaultMessage: 'Please contact your underwriter to continue this transaction or start a new policy change on the most up to date transaction'
    }
});
