import { defineMessages } from 'react-intl';

export default defineMessages({
    VehicleIncidentsQuestion: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.question',
        defaultMessage: "Was there any third party vehicle damage?"
    },
    Yes: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.No',
        defaultMessage: "No"
    },
    Number: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.No.',
        defaultMessage: "No."
    },
    VehicleOwner: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.VehicleOwner',
        defaultMessage: "Vehicle Owner"
    },
    VehicleLocation: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.VehicleLocation',
        defaultMessage: "Vehicle Location"
    },
    Edit: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.Edit',
        defaultMessage: "Edit"
    },
    Delete: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.Delete',
        defaultMessage: "Delete"
    },
    ViewOrEdit: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.View/Edit',
        defaultMessage: "View/Edit"
    },
    AddVehicle: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.AddVehicle',
        defaultMessage: "Add Vehicle"
    },
    DeleteVehicle: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.vehicleIncidents.DeleteVehicle',
        defaultMessage: "Delete Vehicle"
    }
})