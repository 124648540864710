import React, {
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import metadata from './WatercraftDetails.metadata.json5';
import messages from '../../WALWatercraftsPage.messages';
import {
    getAvailableYears,
    getAvailableList,
    postOnChangeAction,
    getAvailableFromPath,
    getAvailableFromVM,
    tooltipForcoverageAmount,
    isFieldVisible,
    getValuationMethodOptions,

} from '../../util/Watercraft.util';
import {
    INBOARD_MAP,
    itemIsuredTypeKey
} from '../../config/Watercraft.static';

function WatercraftDetails(props) {
    const {
        sectionType,
        basePath,
        title,
        currentRow,
        onValueChange,
        onValidate,
        showErrors,
        isRequiredForIssuance,
        syncWizardData,
        syncWizardDataSnapshot,
        changeModelAction,
        isReadOnly,
        updateValidationIssues,
        extendProps: {
            jobID,
            sessionUUID,
            baseData,
            authHeader,
            systemDate
        }
    } = props;
    const vm = currentRow[basePath];
    const {
        effectiveDate_Ext: effectiveDate
    } = baseData;
    const {
        itemToBeInsured_Ext: itemToBeInsured,
        walCategoryFlowType
    } = currentRow.value

    const {
        year,
        manufacturer,
        model,
        description,
        isCoverageAmountWarning,
        categoryName,
        priceDigestsDetails = {},
        currentMarketValue,
        modelOptions = [],
        manufacturerOptions = [],
        coverageAmount
    } = vm.value;
    const translator = useTranslator();
    const [coverageValue, updateCoverageValue] = useState(coverageAmount);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    
    useEffect(() => {
        updateCoverageValue(coverageAmount);
    }, [coverageAmount]);

    const getWatercraftMotorTypeAvailables = (path) => {
        const options = getAvailableFromVM(vm, path, translator);
        const categoryType = categoryName || description // check categoryName, if INT call fail, check description
        // Only available for Inboard/Outdrive or Sail Boats 
        // For Inboard/Outdrive, show "Inboard and Inboard/outdrive".
        // For Sail Boats, show "No Motor".
        // For Pontoon Deck (inboard), show  "Inboard and Inboard/outdrive".
        if(['Inboard/Outdrive', 'STERN-IO'].includes(categoryType)) {
            return options.filter((v) => v.code === 'inboard_outdrive');
        }
        if(categoryType === 'Sail Boats') {
            return options.filter((v) => v.code === 'no_motor');
        }
        if(categoryType === 'Pontoon Deck and Houseboats' && _.includes(INBOARD_MAP, walCategoryFlowType)) {
            return options.filter((v) => v.code === 'inboard_outdrive');
        }
        return options;
    };

    const getVisibleField = (fieldPath) => {
        return isFieldVisible(currentRow, fieldPath, basePath, baseData);
    };

    const getRequestData = (value, path) => {
        return {
            publicID: _.get(currentRow.value, 'publicID'),
            fieldName: path,
            watercraftDetailType: sectionType,
            walWatercraftDetail: vm.value
        }
    };

    const writeValue = async(value, path) => {
        onValueChange(value, `${basePath}.${path}`);
        if(!value) {
            return false;
        }
        switch(path) {
            case 'year':
                if(value === year) {
                    break;
                };
                setLoadingMask(true);
                const res = await postOnChangeAction(getRequestData(value, path), jobID, sessionUUID, authHeader);
                _.set(currentRow.value, basePath, res.walWatercraftDetail);
                syncWizardDataSnapshot(currentRow);
                setLoadingMask(false);
                break;
            case 'manufacturer':
                if(value === manufacturer) {
                    break;
                };
                setLoadingMask(true);
                const resforManufacturer = await postOnChangeAction(getRequestData(value, path), jobID, sessionUUID, authHeader);
                _.set(currentRow.value, basePath, resforManufacturer.walWatercraftDetail);
                syncWizardDataSnapshot(currentRow);
                setLoadingMask(false);
                break;
            case 'model':
                if(value === model) {
                    break;
                };
                changeModelAction(getRequestData(value, path));
                break;
            case 'description':                
                setLoadingMask(true);
                const resforDescription = await postOnChangeAction(getRequestData(value, path), jobID, sessionUUID, authHeader);
                const {
                    walWatercraftDetail: {
                        itemToBeInsured_Ext: newItemInsuredType,
                        walCategoryFlowType: newCategoryFlowType
                    } = {}
                } = resforDescription
                // POI-31456: show warning message if item to be insured update
                const resInsuredType = translator({id: `${itemIsuredTypeKey}.${newItemInsuredType}`});
                const insuredTypeForDescriptionValidation = {
                    type: 'warning',
                    insuredTypeBaseField: 'description',
                    reason: translator(messages.BRPL0442, { itemToBeInsured: resInsuredType})
                }
                updateValidationIssues((prev) => {
                    const filterPrev = prev.filter((item) => item.insuredTypeBaseField !== 'description' && item.insuredTypeBaseField !== 'priceDigest');
                    const validations = [...filterPrev, insuredTypeForDescriptionValidation];
                    if(newItemInsuredType !== itemToBeInsured) {
                        return _.uniqBy(validations, 'reason')
                    }
                    return filterPrev;
                });
                // -------- end for POI-31456
                _.set(currentRow.value, 'itemToBeInsured_Ext', newItemInsuredType || itemToBeInsured);
                _.set(currentRow.value, 'walCategoryFlowType', newCategoryFlowType);
                _.set(currentRow.value, basePath, resforDescription.walWatercraftDetail);
                syncWizardDataSnapshot(currentRow);
                setLoadingMask(false);
                break;
            case 'isPhysicalDamageCovRequired':
                if(value) {
                    _.set(vm.value, 'currentMarketValue', priceDigestsDetails.currentMarketValue);
                    const section = ['motorDetail', 'trailerDetail'];
                    section.forEach((itemPath) => {
                        const itemCurrentMarketValue = _.get(currentRow.value, `${itemPath}.priceDigestsDetails.currentMarketValue`);
                        _.set(currentRow.value, `${itemPath}.currentMarketValue`, itemCurrentMarketValue);
                    });
                    syncWizardData(currentRow);
                }
                break;
            case 'lossSettlementValuationMethod':
                if(value === 'replacement_cost') {
                    _.set(vm.value, 'coverageAmount', priceDigestsDetails.msrp);
                    updateCoverageValue(priceDigestsDetails.msrp);
                    syncWizardData(currentRow, 'updateMotorOrTrailer')
                }
                if(value === 'agreed_value') {
                    _.set(vm.value, 'coverageAmount', priceDigestsDetails.currentMarketValue);
                    updateCoverageValue(priceDigestsDetails.currentMarketValue);
                    syncWizardData(currentRow, 'updateMotorOrTrailer')
                };
                break;
            default:
                break;
        }
    };
    const valuationMethodOptions = getValuationMethodOptions(vm, effectiveDate, translator);
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        detailsCard: {
            title
        },
        watercraftyear: {
            availableValues: getAvailableYears(systemDate),
        },
        watercraftmanufacturer: {
            availableValues: getAvailableList(manufacturerOptions)
        },
        watercraftotherManufacturer: {
            visible: getVisibleField('otherManufacturer')
        },
        watercraftmodel: {
            availableValues: getAvailableList(modelOptions)
        },
        watercraftotherModel: {
            visible: getVisibleField('otherModel')
        },
        watercraftDetailContent: {
            visible: !!_.get(vm, 'value.model')
        },
        watercraftdescription: {
            visible: !categoryName,
            availableValues: getAvailableFromPath(currentRow, 'descriptionList'),
            required: true,
            disabled: !_.get(currentRow.value, 'isEditabled') && description
        },
        watercraftDrive: {
            visible: getVisibleField('drive'),
            availableValues: getAvailableFromPath(vm, 'driveOptions'),
        },
        watercraftmotorType: {
            visible: getVisibleField('motorType'),
            availableValues: getWatercraftMotorTypeAvailables('motorType'),
            required: true
        },
        watercraftmotorIdentificationNumber: {
            visible: getVisibleField('motorIdentificationNumber')
        },
        watercraftengineHorsepower: {
            visible: getVisibleField('engineHorsepower'),
            required: true
        },
        watercraftfuelType: {
            visible: getVisibleField('fuelType'),
            required: true
        },
        watercraftregistrationState: {
            visible: getVisibleField('registrationState')
        },
        watercraftregistrationStateExplain: {
            visible: getVisibleField('registrationStateExplain')
        },
        watercraftRegistrationStateOtherExplain: {
            visible: getVisibleField('registrationStateOtherExplain')
        },
        watercraftlossSettlementValuationMethod: {
            visible: getVisibleField('lossSettlementValuationMethod'),
            availableValues: valuationMethodOptions,
            required: true
        },
        watercraftcurrentMarketValue: {
            visible: getVisibleField('currentMarketValue'),
            disabled: !!_.get(priceDigestsDetails, 'currentMarketValue') && (_.get(priceDigestsDetails, 'currentMarketValue.amount') === _.get(currentMarketValue, 'amount'))
        },
        watercraftcoverageAmount: {
            tooltip: {
                text: tooltipForcoverageAmount(translator, sectionType)
            },
            visible: getVisibleField('coverageAmount'),
            required: getVisibleField('coverageAmount'),
            onValueChange: (value) => {
                updateCoverageValue(value)
            },
            onBlur: () => {
                writeValue(coverageValue, 'coverageAmount');
            },
            value: coverageValue
        },
        watercraftcoverageAmountWarningMsg: {
            visible: isCoverageAmountWarning
        }
    };
    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);

    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);

    const overrides = _.merge(overrideProps, overridesForIssuance);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };
   

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            newMetadata, vm, fieldId, fieldPath, overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={newMetadata}
            model={vm}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default WatercraftDetails;