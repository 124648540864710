import React, { useCallback } from 'react';
import _ from 'lodash';
import { WALWatercraftService } from 'wni-capability-quoteandbind-wal';
import AdditionalInterestComponent from '../../AdditionalInterest/AdditionalInterest/WALAdditionalInterestComponent';

function WALAdditionalInterest(props) {
    const {
        currentRow,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader,
            baseData: {
                accountNumber,
                accountContacts_Ext: accountContacts,
                accountHolder
            }
        },
        onValueChange,
        readOnly,
    } = props;

    const getAdditionalInterestTypeOptions = useCallback(async ({
        contactType, publicID
    }) => {
        let options;
        try {
            options = await WALWatercraftService.getAdditionalInterestType(
                jobID, sessionUUID, publicID, contactType, authHeader
            );
        } catch (e) {
            options = [];
        }
        return options;
    }, [authHeader, jobID, sessionUUID]);

    const componentProps = {
        value: _.get(currentRow, 'additionalInterests'),
        accountNumber,
        accountContacts,
        authHeader,
        getAdditionalInterestTypeOptions,
        accountHolderBirthday: _.get(accountHolder, 'dateOfBirth'),
        onValueChange,
        readOnly
    } 

    return (
        <AdditionalInterestComponent {...componentProps} />
    );
}

export default WALAdditionalInterest;
