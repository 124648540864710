/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

const vmQualifyingProperties = ['value', 'aspects', '_propertyName', '_parent',
    '_ancestorChain', '_metadataInfo', '_accessorCode', '_aspects'
];

const vmOwnProperties = vmQualifyingProperties.concat(['_aspectFactories']);


/**
 * Checks if maybenode is view model node.
 * @alias isViewModelNode
 * @memberof module:gw-portals-viewmodel-js.ViewModelUtil
 * @param {*} maybeNode
 *
 * @returns {Boolean}
 */
function isVMNode(maybeNode) {
    if (!maybeNode || !_.isObject(maybeNode)) {
        return false;
    }

    return vmQualifyingProperties.every((qp) => {
        return qp in maybeNode;
    });
}

/**
 * Returns the value of maybenode if it is a view model node.
 * @alias unpackIfViewModelNode
 * @memberof module:gw-portals-viewmodel-js.ViewModelUtil
 * @param {*} maybeNode
 *
 * @returns {*} the value of maybenode if it is a view model node.
 */
function unpackIfVMNode(maybeNode) {
    if (isVMNode(maybeNode)) {
        return maybeNode.value;
    }

    return maybeNode;
}

/**
 * Returns the root of the view model node.
 * @memberof module:gw-portals-viewmodel-js.ViewModelUtil
 * @param {*} viewModel
 *
 * @returns {*} the root of the view model node.
 */
function findRoot(viewModel) {
    if (viewModel._parent) {
        return findRoot(viewModel._parent);
    }

    return viewModel;
}

/**
 * replaces 'children' and 'value' strings in the given path
 * @memberof module:gw-portals-viewmodel-js.ViewModelUtil
 * @param {string} path
 *
 * @returns {string} the non ViewModel path
 */
function getNonVMPath(path) {
    return path.replace(/\.children|\.value/g, '');
}

/** 
 * Miscellaneous utilities for the view model nodes. 
 * @namespace ViewModelUtil
 * @memberof module:gw-portals-viewmodel-js
 */
export default {
    /* Checks if maybenode is view model node. */
    isViewModelNode: isVMNode,
    unpackIfViewModelNode: unpackIfVMNode,
    findRoot: findRoot,
    getNonVMPath: getNonVMPath,

    /**
     * Cleans item and extracts its true value.
     * @memberof module:gw-portals-viewmodel-js.ViewModelUtil
     * @param {*} maybeNode
     *
     * @returns {Boolean}
     */
    cleanse: (maybeNode) => {
        return isVMNode(maybeNode) ? maybeNode.value : maybeNode;
    },

    /**
     * Iterates over child nodes of the provided view model node and calls the callback function for each item
     * @memberof module:gw-portals-viewmodel-js.ViewModelUtil
     * @param {*} vmNode 
     * @param {Function} cb - Callback function to be called for each iterated node
     */
    iterateOverChildNodes(vmNode, cb) {
        Object.keys(vmNode)
            .filter((key) => !vmOwnProperties.includes(key))
            .filter((key) => isVMNode(vmNode[key]))
            .forEach((key) => cb(vmNode[key], key));
    }
};