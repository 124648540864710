import React, { useContext, useEffect, useState } from 'react';
import { WniInputNumber } from 'wni-common-base-components';
import SingleClauseContext from '../../../context/IMSingleClauseContext';

const IMScheduleItemEditableCovTermCellInputNumber = (props) => {
    const { showErrors, isEditable, setIsEditing } = useContext(SingleClauseContext);

    const { termID, term, setUpdatedTermToScheduleItem } = props;

    const { directValue } = term;

    const [editingValue, setEditingValue] = useState(directValue);

    useEffect(() => {
        setEditingValue(directValue);
    }, [directValue]);

    return (
        <WniInputNumber
            id={termID}
            showLabel={false}
            value={editingValue}
            onValueChange={(value) => {
                setIsEditing(true)
                setEditingValue(value)
            }}
            onBlur={async () => {
                setIsEditing(false)
                if (editingValue === directValue) {
                    return;
                }
                const updatedTerm = {
                    ...term,
                    directValue: editingValue,
                    updated: true,
                };
                await setUpdatedTermToScheduleItem(updatedTerm);
            }}
            required={term.required}
            readOnly={!isEditable || term.readOnly_Ext}
            showErrors={showErrors}
        />
    );
};

export default IMScheduleItemEditableCovTermCellInputNumber;
