import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 * WniCEPolicyChangeVehicleHandler
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wtwatercraft'), method, data, additionalHeaders);
}


export default class WTWatercraftService {
    static getOrCreateVehicleIncident(claimNumber, vehicleID, authHeader = {}) {
        return processSubmission('getOrCreateVehicleIncident', [claimNumber, vehicleID], authHeader);
    }

    static removeVehicleIncident(claimNumber, incidentID, authHeader = {}) {
        return processSubmission('removeVehicleIncident', [claimNumber, incidentID], authHeader);
    }

    static removeContact(claimNumber, contactID, contactRole, authHeader = {}) {
        return processSubmission('removeContact', [claimNumber, contactID, contactRole], authHeader);
    }

    static saveVehicleIncident(claimNumber, incidentDTO, authHeader = {}) {
        return processSubmission('saveVehicleIncident', [claimNumber, incidentDTO], authHeader);
    }

    static onPageNext(claimNumber, authHeader = {}) {
        return processSubmission('onPageNext', [claimNumber], authHeader);
    }

}
