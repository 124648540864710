import React from 'react';
import messages from './OccupancyClauses.messages'
import DefaultRiskItemClauses from '../DefaultRiskItemClauses/DefaultRiskItemClauses';

const BusinessIncomeClauses = (props) => {
    return <DefaultRiskItemClauses
        {...props}
        messages={messages}
    />
};

export default BusinessIncomeClauses;
