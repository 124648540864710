
const minYear = 1945;
const validationNameMaps = ['underlyingComponent', 'personalAutoDetailFields', 'motorcycleDetailFields', 'recreationalDetailFields', 'watercraftDetailFields'];
const requiredForIssuanceData = {
    property: [
        'numberOfFamilies',
        'numberOfResidenceEmployees',
        'roomerBoardersNumber',
        'swimmingPoolType',
        'approvedFence',
        'approvedFenceExplain',
        'divingBoard',
        'depthOfThePool',
        'existATrampoline',
        'isTrampolineNetted',
        'isTrampolineSecured',
        'existSkateboardRampOnPremise',
        'isPropLocatedAlongWaterfront',
        'ownAnyAnimals',
        'hasBusiConductedOnPremise',
        'additionalBusinessType',
        'businessTypeExplain',
        'isPropertyRentedToOthers'
    ],
    auto: [
        'vin',
        'year',
        'make',
        'model',
        'annualMileage',
        'vehExclusiveUseToFarmOwned',
        'vehicleUsedInBusinessOwned',
        'vehicleUsedInBusiOwnedExplain',
        'motorHomeRented',
        'milePercentPerYear'
    ],
    watercraft: [
        'identificationNumber',
        'year',
        'make',
        'model',
        'hasMotorBeenModified',
        'selectedWatercraftAppliedTypes'
    ],
    motorcycle: [
        'vin',
        'year',
        'make',
        'model',
        'engineCC',
        'hasVehicleBeenModified',
        'selectVehicleApplies'
    ],
    recreational: [
        'identificationNumber',
        'year',
        'make',
        'model',
        'engineCC',
        'hasVehicleBeenModified',
        'selectVehicleApplies'
    ],
    coverageInfo: [
        'hasUlPolicyCSLLimit',
        'ulPolicyCSLLimit',
        'ulPolicyCSLOtherLimit',
        'ulPolicyBILimit',
        'ulPolicyBIOtherLimit',
        'ulPolicyPDLimit',
        'ulPolicyPDOtherLimit',
        'ulPolicyLiabilityLimit',
        'ulPolicyLiabOtherLimit',
        'ulPolicyCarrier',
        'ulPolicyNumber',
        'ulPolicyEffectiveDate',
        'ulPolicyExpirationDate',
        'ulPolicyStatus'
    ]

};

export {
    minYear,
    validationNameMaps,
    requiredForIssuanceData
}