import { defineMessages } from 'react-intl';

export default defineMessages({
    UnderwritingQuestionsTitle: {
        id: 'wni.quoteandbind.wc.policydetails.Underwriting Questions Title',
        defaultMessage: 'Underwriting Questions'
    },
    UnderwritingQuestionsValidationMessages: {
        id: 'wni.quoteandbind.wc.policydetails.UnderwritingQuestionsValidationMessages',
        defaultMessage: 'This field is required.'
    }
});