import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { PortalConstants } from 'wni-portals-config-js';
import {
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import appConfig from 'app-config';
import { CACoveragesService } from 'wni-capability-quoteandbind-ca';
import VehicleDetails from '../VehicleDetails/CAVehicleDetails';
import metadata from './CAVehicleComponent.metadata.json5';
import messages from '../../CAVehiclesPage.messages';
import VehiclePrimaryCoverages from '../CAVehiclePrimaryCoverages/CAVehiclePrimaryCoverages';
import VehicleAdditionalCoverages from '../CAVehicleAdditionalCoverages/CAVehicleAdditionalCoverages';
import VehicleExclusions from '../CAVehicleExclusions/CAVehicleExclusions';
import VehicleConditions from '../CAVehicleConditions/CAVehicleConditions';
import VehicleAdditionalInterestCoverages from '../CAVehicleAdditionalInterestCoverages/CAVehicleAdditionalInterestCoverages';

function CAVehicleComponent(props) {
    const {
        currentRow: vehicleVM,
        onValueChange,
        onValidate,
        showErrors,
        submissionVM,
        updateWizardData,
        syncWizardDataSnapshot,
        generateValidationIssues,
        isReadOnly
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            commercialAuto: {
                offerings,
            }
        },
        jobID,
        sessionUUID,
    } = submissionVM.value;
    
    const {
        vehicleType,
        publicID: vehiclePublicID,
    } = vehicleVM.value

    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const [vehicleCoveragesVM, setVehicleCoveragesVM] = useState()

    const initVehicleCoverages = useCallback(async () => {
        setLoadingMask(true)
        const vehicleCoverages = await CACoveragesService.getSingleVehicleCoverage(
            jobID,
            sessionUUID,
            vehicleType,
            vehiclePublicID,
            authHeader
        )
        setLoadingMask(false)
        const res = viewModelService.create(vehicleCoverages, 'pc', 'wni.edge.capabilities.policyjob.lob.commercialauto.coverages.dto.CAVehicleCoveragesDTO');
        setVehicleCoveragesVM(res)
    }, [authHeader, jobID, sessionUUID, setLoadingMask, vehiclePublicID, vehicleType, viewModelService])

    useEffect(() => {
        initVehicleCoverages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclePublicID])

    const [validationIssues, updateValidationIssues] = useState([])
    const [loadingClauses, setLoadingClauses] = useState(false);
    const [isEditing, setIsEditing] = useState(false)

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        _.set(newSubmissionVM, 'baseData.displayStatus_Ext', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        const newSubmissionVM = setWizardDataToDraft(wizardData);
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const hasExclusionsOrConditions = useMemo(() => {
        const vehicleCoverages = _.get(vehicleCoveragesVM, 'value', {})
        const {
            exclusions = [],
            conditions = []
        } = vehicleCoverages
        return exclusions.length > 0 || conditions.length > 0
    }, [vehicleCoveragesVM])

    const commonCoverageComponentProps = {
        submissionVM,
        updateWizardData: updateWizardDataToDraft,
        onValidate,
        showErrors,
        updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
        isEditing,
        setIsEditing,
        coveragesService: CACoveragesService,
        loadingClauses,
        setLoadingClauses,
        isEditable: !isReadOnly,
        vehicleCoveragesVM,
        setVehicleCoveragesVM,
        vehicleVM,
        updateVehicleVM: syncWizardDataSnapshot,
        generateValidationIssues,
    }

    // const currentVehicleCoverageProps = {
    //     selectedVersion,
    //     vehicleCoveragesIndex
    // }
    //---------------------
    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
            isReadOnly
        },
        vehicleDetailsSection: {
            ...props, 
            setVehicleCoveragesVM,
        },
        coveragesAccordion: {
            visible: !!vehicleCoveragesVM
        },
        vehiclePrimaryCoverages: {
            ...commonCoverageComponentProps
        },
        vehicleAdditionalCoverages: {
            ...commonCoverageComponentProps
        },
        exclusionsAndConditionsCard: {
            visible: hasExclusionsOrConditions
        },
        vehicleExclusions: {
            ...commonCoverageComponentProps
        },
        vehicleConditions: {
            ...commonCoverageComponentProps
        },
        additionalInterestCard: {
            visible: !!vehicleCoveragesVM
        },
        additionalInterestSection: {
            ...commonCoverageComponentProps
        }
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            vehicledetails: VehicleDetails,
            VehiclePrimaryCoverages: VehiclePrimaryCoverages,
            VehicleAdditionalCoverages: VehicleAdditionalCoverages,
            VehicleExclusions: VehicleExclusions,
            VehicleConditions: VehicleConditions,
            VehicleAdditionalInterestCoverages: VehicleAdditionalInterestCoverages
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vehicleVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default CAVehicleComponent;