import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glCoverageService'), method, data, additionalHeaders);
}

export default class GLCoverageService {
  
    /**
     * Invokes GLCoveragesHandler.getSublineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLineClausesWithSync(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getLineClausesWithSync', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.getSublineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getSublineCoverages(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getSublineCoverages', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.updateSingleSublineClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateSingleSublineClauses(jobID, sessionUUID, sublineClauses, authHeader = {}) {
        return processSubmission('updateSingleSublineClauses', [jobID, sessionUUID, sublineClauses], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.updateExposureClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateExposureClauses(jobID, sessionUUID, exposureClauses, authHeader = {}) {
        return processSubmission('updateExposureClauses', [jobID, sessionUUID, exposureClauses], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.searchSublineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchSublineCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, authHeader = {}) {
        return processSubmission('searchSublineCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.addSearchedSublineCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clauseSpecData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedSublineCoverages(jobID, sessionUUID, clauseSpecData, authHeader = {}) {
        return processSubmission('addSearchedSublineCoverages', [jobID, sessionUUID, clauseSpecData], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.searchExposureCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} exposureID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchExposureCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, exposureID, authHeader = {}) {
        return processSubmission('searchExposureCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, exposureID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.addSearchedExposureCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clauseSpecData
     * @param {string} exposureID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedExposureCoverages(jobID, sessionUUID, clauseSpecData, exposureID, authHeader = {}) {
        return processSubmission('addSearchedExposureCoverages', [jobID, sessionUUID, clauseSpecData, exposureID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.onGeneralLiabilityPageNext()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onGeneralLiabilityPageNext(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('onGeneralLiabilityPageNext', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.getLocationClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLocationClauses(jobID, sessionUUID, locationPublicID, authHeader = {}) {
        return processSubmission('getLocationClauses', [jobID, sessionUUID, locationPublicID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.updateLocationPerSublineClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID 
     * @param {string} locationSublinePublicID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateLocationPerSublineClauses(
        jobID,
        sessionUUID,
        locationPublicID,
        locationSublinePublicID,
        dto,
        authHeader = {}
    ) {
        return processSubmission('updateLocationPerSublineClauses', [
            jobID,
            sessionUUID,
            locationPublicID,
            locationSublinePublicID,
            dto,
        ], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.updateUnmannedAircraftClauses()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} unmannedAircraftClausesDTO 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateUnmannedAircraftClauses(
        jobID,
        sessionUUID,
        unmannedAircraftClausesDTO,
        authHeader = {}
    ) {
        return processSubmission('updateUnmannedAircraftClauses', [
            jobID,
            sessionUUID,
            unmannedAircraftClausesDTO,
        ], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.searchLocationCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} locationPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchLocationCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, locationID, authHeader = {}) {
        return processSubmission('searchLocationCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, locationID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.addSearchedLocationCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clauseSpecData
     * @param {string} locationPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedLocationCoverages(jobID, sessionUUID, clauseSpecData, locationID, authHeader = {}) {
        return processSubmission('addSearchedLocationCoverages', [jobID, sessionUUID, clauseSpecData, locationID], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.getStateClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} jurisdictionCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getStateClauses(jobID, sessionUUID, jurisdictionCode, authHeader = {}) {
        return processSubmission('getStateClauses', [jobID, sessionUUID, jurisdictionCode], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.updatStatePerSublineClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} state 
     * @param {string} sublinePublicID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updatStatePerSublineClauses(
        jobID,
        sessionUUID,
        state,
        sublinePublicID,
        dto,
        authHeader = {}
    ) {
        return processSubmission('updatStatePerSublineClauses', [
            jobID,
            sessionUUID,
            state,
            sublinePublicID,
            dto,
        ], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.searchStateSpecificCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} coverageCategory
     * @param {string} keyword
     * @param {string} searchType
     * @param {string} jurisdictionCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchStateSpecificCoverages(jobID, sessionUUID, coverageCategory, keyword, searchType, jurisdictionCode, authHeader = {}) {
        return processSubmission('searchStateSpecificCoverages', [jobID, sessionUUID, coverageCategory, keyword, searchType, jurisdictionCode], authHeader);
    }

    /**
     * Invokes GLCoveragesHandler.addSearchedStateSpecificCoverages()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clauseSpecData
     * @param {string} jurisdictionCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedStateSpecificCoverages(jobID, sessionUUID, clauseSpecData, jurisdictionCode, authHeader = {}) {
        return processSubmission('addSearchedStateSpecificCoverages', [jobID, sessionUUID, clauseSpecData, jurisdictionCode], authHeader);
    }
}