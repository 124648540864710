import _ from 'lodash';
import WniDateUtil from './WniDateUtil';

// async function deleteAttachment(
//     publicID,
//     deletionFailedMessage,
//     cannotDeletedMessage,
//     commonMessages,
//     authHeader,
//     attachmentPageService,
//     setloadingMask,
//     modalApi,
//     retrieveInitAttachments
// ) {
//     setloadingMask(true);
//     await attachmentPageService
//         .removeAttachment(publicID, authHeader)
//         .then((isDeleteItem) => {
//             if (isDeleteItem) {
//                 return retrieveInitAttachments();
//             }
//         })
//         .catch(() => {
//             modalApi
//                 .showAlert({
//                     title: deletionFailedMessage,
//                     message: cannotDeletedMessage,
//                     status: 'error',
//                     icon: 'gw-error-outline',
//                     confirmButtonText: commonMessages.ok,
//                 })
//                 .catch(_.noop);
//         })
//         .finally(() => {
//             setloadingMask(false);
//         });
// }

// async function getAttachmentLink(
//     publicID,
//     sessionID,
//     attachmentPageService,
//     errorCallback,
//     authHeader
// ) {
//     const link = attachmentPageService.getAttachmentLink(publicID, sessionID);
//     await attachmentPageService.downloadAttachment(
//         link,
//         authHeader,
//         errorCallback
//     );
// }


const getDateModifiedFn = (item, index, property) => {
    const { path } = property;
    const date = new Date(item[path]);
    const dateVal = WniDateUtil.formatDateWithPattern(date);
    return `${item[path] ? dateVal : ''}`
};

const getRelatedFieldFn = (item) => {
    const relatedTo = _.get(item, 'level');
    switch (relatedTo) {
        case 'Account':
            return `#${item.accountNumber}`;
        case 'Policy':
            return `#${item.policyNumber}`;
        case 'Job':
            return `#${item.accountNumber}`;
        default:
            return `#${item.accountNumber}`;
    }
};


export default {
    getDateModifiedFn,
    getRelatedFieldFn
};
