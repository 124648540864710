import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imPolicyDetailsService'), method, data, additionalHeaders);
}

export default class IMPolicyDetailsService {

    static savePolicyDetailsData(quoteID, sessionUUID, imPolicyDetailsData, quoteBaseData, authHeader) {
        return processSubmission('saveIMPolicyDetailsData', [quoteID, sessionUUID, imPolicyDetailsData, quoteBaseData], authHeader);
    }

}