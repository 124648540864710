import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function ExternalBroadridgeRouting(props) {
    const { match, history } = props;

    useEffect(() => {
        const {
            params: { jobNumber, issueJobNumber, payStatus, initStep }
        } = match;
        const state = {
            jobNumber,
            issueJobNumber,
            payStatus,
            initStep
        };
        const toURL = '/aqpayment';

        history.push(toURL, state);
    }, [history, match]);

    return null;
}

ExternalBroadridgeRouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

export default withRouter(ExternalBroadridgeRouting);
