 import { defineMessages } from 'react-intl';

export default defineMessages({
    tooltipDummy: {
        id: 'custom.products.CP.tooltip.Dummy',
        defaultMessage: 'Dummy'
    },
    CP7FullSpecialClassValue: {
        id: 'wni.custom.products.CA7.tooltip.CP7FullSpecialClassValue',
        defaultMessage: 'Limit of coverage identifies amount covered whereas full item value is the reported value of the items covered'
    },
    
})