import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { AutoPaymentPage } from 'wni-capability-gateway-react';
import { NewQuoteAccountSearch as AccountSearch } from 'gw-capability-gateway-react';
import { PaySuccessPageByBCC } from 'gw-capability-gateway-quoteandbind-pa-react';
import AgentsOnlineCommonRouting from '../components/AgentsOnlineCommonRouting';
import ExternalRCTRouting from '../components/ExternalRCTRouting';
import ExternalBroadridgeRouting from "../components/ExternalBroadridgeRouting";
import ExternalAgentsOnlineBillingRouting from "../components/ExternalAgentsOnlineBillingRouting";
import AgentsOnlineNewAccountRouting from "../components/AgentsOnlineNewAccountRouting"
import ExternalINTIRouting from '../components/ExternalINTIRouting';
import ExternalCERouting from '../components/ExternalCERouting';

export default function ExternalCommonRoutes() {
    const { pathname } = useLocation();
    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            {/* AgentsOnline Routing: this first routing should be updated to /agentsonline/policy-view/:productCode/:policyNumber */}
            <Route exact path="/agentsonline/:actionType/:productCode/:policyNumber" component={AgentsOnlineCommonRouting} />
            <Route exact path="/agentsonline/external-autopayment/accounts/:accountNumber/invoice/:invoiceNumber" component={AutoPaymentPage} />
            <Route exact path="/agentsonline/external-account-search/state/:stateCode/effectiveDate/:effectiveDate/producerCode/:producerCode/productCode/:productCode" component={AccountSearch} />
            <Route exact path="/agentsonline/external-billing/:accountNumber" component={ExternalAgentsOnlineBillingRouting} />
            <Route exact path="/agentsonline/external-new-account/AOEffectiveDate/:AOEffectiveDate/productSelected/:productSelected/baseState/:baseState" component={AgentsOnlineNewAccountRouting} />

            {/* RCT Routing */}
            <Route exact path="/RCT/:transaction/:postalCode/:productCode/:jobID/:policyNumber" component={ExternalRCTRouting} />
            <Route exact path="/RCT/:transaction/:postalCode/:productCode/:jobID" component={ExternalRCTRouting} />
            
            {/* Broadridge Routing */}
            <Route exact path="/broadridge/paySuccessByBCC/:jobNumber/:payStatus" component={PaySuccessPageByBCC}/>
            <Route exact path="/broadridge/payment/:jobNumber/:issueJobNumber/:payStatus/:initStep" component={ExternalBroadridgeRouting} />
            
            {/* integration Routing */}
            <Route exact path="/integration/quote/:jobNumber" component={ExternalINTIRouting} />
            <Route exact path="/integration/policy/:policyNumber" component={ExternalINTIRouting} />

            {/* CE Policy Change Email Routing */}
            <Route exact path="/customengage/change/:jobNumber" component={ExternalCERouting} />

            {/* Default Routing */}
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}
