import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { QuestionSetComponent } from 'gw-components-platform-react';

import WizardPage from '../../templates/CAWizardPage';

// import PAQuoteUIHelper from './util/PAQuoteUIHelper';
import metadata from './CAQualificationPage.metadata.json5';
import messages from './CAQualificationPage.messages';

function CAQualificationReadOnlyPage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const translator = useTranslator();

    const filterQuestionSets = useCallback((metadataContent) => {
        const displayQuestions = [
            'CA7DriverRecruiting', // Does the applicant have a specific driver recruiting method?
            'CA7EmployeeAutoUsage', // Do over 50% of the employees use their own autos in the business?
            'CA7HazMat', // Do operations involve the transportation of hazardous materials?
            'CA7VehicleMaintProgram', // Is there a vehicle maintenance program in operation?
            // 'CA7RadiusOver300_Ext', // Does your radius operations exceed 300 miles?
            // 'CA7Over3AutoLossInPast5Year_Ext', // Have there been three (3) or more auto losses in past 5 years?
            // 'CA7IfHiredAndNonOwned_Ext' // If hired and non-owned auto coverage is provided, does the ...
        ];
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const getQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            if (metaDataContent.id === 'CA7HazMat') {
                _.set(metaDataContent, 'component', "WniToggle");
                _.set(metaDataContent, 'componentProps.tooltip', {
                    "text": {
                        "id": "wni.quoteandbind.ca.qualification.CA7HazMat tooltip",
                        "defaultMessage": "FMCSA definition of hazardous material: Class 1 - Explosives, Class 2 - Gases, Class 3 - Flammable liquids, Class 4 - Flammable solids or substances"
                    }
                });
            }
            return metaDataContent;
        }
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                contentMapper: getQuestionMapper(),
                isReadOnly: true
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM]);

    // ===================================================
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />

        </WizardPage>
    );
}

CAQualificationReadOnlyPage.propTypes = WizardPage.propTypes;
export default CAQualificationReadOnlyPage;