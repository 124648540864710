import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useProductsData } from 'wni-portals-util-react';
import { WniDateUtil, PaymentUtil } from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BaseAddPaymentMethodPopup } from 'wni-capability-common-react';
import { WniLoadSaveService } from 'wni-capability-quoteandbind';
import { useWniModal } from 'wni-components-platform-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import metadata from './DownPaymentSetup.metadata.json5';
import messages from '../EditQuotePopup/EditQuotePopup.messages';
import messages2 from './DownPaymentSetup.messages';
import styles from './DownPaymentSetup.module.scss';
import AQPaymentPageUtil from '../../utils/AQPaymentPageUtil';

import { Button } from '@jutro/legacy/components';

function DownPaymentSetup(props) {
    const modalApi = useWniModal();
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        rowData,
        rePopupDownPaymentSetup,
        quoteID,
        sessionUUID,
        payUsing,
        totalDownPaymentAmount,
        isMulti,
        accountNumber,
        setLoadingMask,
        getProductName,
        useAuthenticationData,
        viewModelService,
        breakpoint,
        isCLProduct
    } = props;

    const { authHeader } = useAuthenticationData || useAuthentication();
    const translator = useTranslator();
    const [model, updateModel] = useState(rowData);
    // const viewModelService = useContext(ViewModelServiceContext);
    const [selectedItem, updateSelectedItem] = useState({});
    const [paymentMethodValue, updatePaymentMethodValue] = useState('');
    const [paymentMethodsVM, updatePaymentMethodsVM] = useState([]);
    const [paymentAmount, updatePaymentAmount] = useState(totalDownPaymentAmount);
    const [oneTimePaymentMethodsVM, updateOneTimePaymentMethodsVM] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const { routerBaseName } = appConfig;
    const [requestParam, updateRequestParam] = useState(null);
    const [isMultiAndNew, updateIsMultiAndNew] = useState(false);
    const [displayAgencySweep, updateDisplayAgencySweep] = useState(false);
    // const { getProductName } = useProductsData();

    const updateElectronicBank = useCallback((bankVM, basicParam) => {
        const bankValue = _.get(bankVM, 'value');
        const bank = _.get(bankValue, 'bankAccountData.publicID');
        const payDate = WniDateUtil.formatDateWithPattern(new Date(), 'MM/DD/YYYY');
        updatePaymentMethodValue(bank);
        const toUpdateRequestParam = PaymentUtil.getToUpdateRequestParam(
            basicParam, payDate, bankValue
        );
        updateRequestParam(toUpdateRequestParam);
        // updatePaymentDetailsForDownPaymentExt(bankValue);
    }, []);
    
    useEffect(() => {
        // loading data options from PC
        async function retrieveQuotePaymentDetailsData() {
            const rs = await WniLoadSaveService
                .retrieveQuotePaymentDetailsData(quoteID, authHeader);
            return rs;
        }
        setLoadingMask(true);
        retrieveQuotePaymentDetailsData().then((res) => {
            const showAgencySweep = _.get(res, 'bindData.agencySweep_Ext');
            updateDisplayAgencySweep(showAgencySweep);
            // paymentMethods
            const payInstrucments = _.get(res, 'bindData.payInstrucments_Ext');
            const newPaymentMethods = PaymentUtil.initPaymentMethods(
                payInstrucments, viewModelService
            );
            const newAddOneTimePaymentMethodsVM = _.get(model, 'newAddOneTimePaymentMethodsVM', null);
            if (newAddOneTimePaymentMethodsVM) {
                // update oneTimePaymentMethodsVM
                updateOneTimePaymentMethodsVM(newAddOneTimePaymentMethodsVM);
                // update paymentMethods options
                // newPaymentMethods = newPaymentMethods.concat(newAddOneTimePaymentMethodsVM);
            }
            updatePaymentMethodsVM(newPaymentMethods);
            const accountHolderInfo = _.get(res, 'baseData');
            _.set(model, 'value.baseData', accountHolderInfo);
            let isNewBillAccount = false;
            let isMultiAndNewInit = false;
            if (isMulti) {
                // use bill accountNumber as referenceID
                const billAccount = _.get(model, 'invoiceStreamCode');
                const billAccountNumber = _.get(model, 'invoiceStreamDescription');
                if (billAccount === 'New') {
                    _.set(model, 'value.quoteID', quoteID);
                    isNewBillAccount = true;
                    isMultiAndNewInit = true;
                    updateIsMultiAndNew(true);
                } else {
                    _.set(model, 'value.quoteID', billAccountNumber);
                }
            } else {
                // use quoteID as referenceID
                _.set(model, 'value.quoteID', quoteID);
            }
            const currentPayUsing = _.get(model, 'payUsing') || payUsing;
            if (currentPayUsing === 'Check') {
                updatePaymentMethodValue(null);
            } else {
                updatePaymentMethodValue(currentPayUsing);
            }
            // generate the requestParam
            if (currentPayUsing && currentPayUsing !== 'Check') {
                const amt = parseFloat(_.get(paymentAmount, 'amount')).toFixed(2);
                const amtDue = `${amt}`;
                const baseCallBackUrl = `${window.location.origin}/${routerBaseName}/broadridge/paySuccessByBCC/${quoteID}`;
                const basicParam = PaymentUtil.getDefaultBasicRequestParam(
                    model, amtDue, baseCallBackUrl, isMultiAndNewInit
                );
        
                if (isCLProduct) {
                    _.set(basicParam, 'firstName', _.get(model, 'value.baseData.accountHolder.contactName'));
                    _.unset(basicParam, 'lastName');
                }

                // special for new bill account
                if (isNewBillAccount) {
                    _.set(basicParam, `3${quoteID}000000`);
                }
                // select added bank
                let options = newPaymentMethods;
                if (newAddOneTimePaymentMethodsVM) {
                    options = options.concat(newAddOneTimePaymentMethodsVM);
                }
                const selectedVM = PaymentUtil.findSelectedPaymentMethod(currentPayUsing, options);
                updateElectronicBank(selectedVM, basicParam);
            }

            const item = {
                title: title,
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: true,
                actionBtnLabel: messages.dialogOk,
                cancelBtnLabel: messages.dialogCancel,
                authHeader,
                rowData: rowData,
                rePopupDownPaymentSetup,
                quoteID,
                sessionUUID,
                payUsing,
                totalDownPaymentAmount,
                isMulti,
                viewModelService,    
                setLoadingMask,
                getProductName,
                breakpoint,
                isCLProduct
            };
            updateSelectedItem(item);
        }).finally(() => {
            setLoadingMask(false);
        });
    }, []);

    const writeValue = useCallback((value, path) => {
        const newModel = _.cloneDeep(model);
        _.set(newModel, `${path}`, value);
        updateModel(newModel);
    }, [model]);

    const showAddpaymentMethodPopup = useCallback((getPaymentDetailVM) => {
        const componentProps = {
            title: translator(messages.addPaymentMethodTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            paymentDetailVM: getPaymentDetailVM,
            viewModelService: viewModelService,
            breakpoint
        };
        return modalApi.showModal(<BaseAddPaymentMethodPopup {...componentProps} />);
    }, [translator, viewModelService, breakpoint, modalApi]);

    const isValueValid = useCallback(() => {
        return _.get(paymentAmount, 'amount') >= _.get(totalDownPaymentAmount, 'amount');
    }, [paymentAmount, totalDownPaymentAmount]);

    const isPaymentMethodValid = useCallback(() => {
        return paymentMethodValue;
    }, [paymentMethodValue]);

    const handleSave = useCallback(async () => {
        if (!isValueValid() || !isPaymentMethodValid()) {
            updateShowErrors(true);
            return false;
        }
        const res = {};
        _.set(res, 'billingMethod', 'DirectBill');
        _.set(res, 'requestParam', requestParam);
        if (paymentMethodValue === 'AgencySweep') {
            // agencySweep
            _.set(res, 'downPayment', 'AgencySweep');
            _.set(res, 'agencySweepValue', paymentAmount);
            _.set(res, 'paymentDetailsForDownPayment', { paymentMethd: 'creditcard' });
            _.set(res, 'isBCC', false);
        } else {
            _.set(res, 'downPayment', 'Electronic');
            _.set(res, 'electronicValue', paymentAmount);
            const bankAccountData = _.find(paymentMethodsVM,
                (option) => _.get(option, 'value.bankAccountData.publicID') === paymentMethodValue);
            const paymentDetails = _.get(bankAccountData, 'value');
            _.set(res, 'paymentDetailsForDownPayment', paymentDetails);
            _.set(res, 'isBCC', true);
        }
        return onResolve(res);
    }, [isValueValid, isPaymentMethodValid, requestParam,
        paymentMethodValue, onResolve, paymentAmount, paymentMethodsVM]);

    const getAccountInfo = useCallback(() => {
        const quoteIDs = [];
        const data = _.get(rowData, 'data');
        if (_.isEmpty(data)) {
            quoteIDs.push(quoteID);
        } else {
            _.each(data, (submission) => {
                const submissioNumber = _.get(submission, 'quoteNumber');
                // const productName = _.get(submission, 'product.productName');
                const productCode = _.get(submission, 'product.productCode');
                quoteIDs.push({
                    submissioNumber,
                    productCode
                });
            });
        }
        const STYLE = {
            'margin-bottom': '20px'
        };
        return (
            <div style={STYLE}>
                {quoteIDs.map((value) => {
                    return (
                        <div>
                            {getProductName(_.get(value, 'productCode'), translator)} {_.get(value, 'submissioNumber')}
                        </div>
                    );
                })}
            </div>
        );
    }, []);



    const getPaymentMethodDropdownData = useCallback(() => {
        let data = [];
        if (displayAgencySweep && displayAgencySweep === 'Yes') {
            data.push({
                code: 'AgencySweep',
                name: translator(messages2.agencySweep)
            });
        }
        data = PaymentUtil.addPaymentMethodOptions(data, paymentMethodsVM);
        data = PaymentUtil.addPaymentMethodOptions(data, oneTimePaymentMethodsVM);
        data.push({
            code: 'addNewCreditCard',
            name: translator(messages2.addNewCreditCard)
        });
        data.push({
            code: 'addNewBankAccount',
            name: translator(messages2.addNewBankAccount)
        });
        return data;
    }, [displayAgencySweep, paymentMethodsVM, oneTimePaymentMethodsVM, translator]);

    const handleDownPaymentMethodChange = useCallback(async (value) => {
        if (!isValueValid()) {
            updateShowErrors(true);
            return false;
        }
        const amt = parseFloat(_.get(paymentAmount, 'amount')).toFixed(2);
        const amtDue = `${amt}`;
        const baseCallBackUrl = `${window.location.origin}/${routerBaseName}/broadridge/paySuccessByBCC/${quoteID}`;
        const basicParam = PaymentUtil.getDefaultBasicRequestParam(
            model, amtDue, baseCallBackUrl, isMultiAndNew
        );

        if (isCLProduct) {
            _.set(basicParam, 'firstName', _.get(model, 'value.baseData.accountHolder.contactName'));
            _.unset(basicParam, 'lastName');
        }

        if (value === 'AgencySweep' || value === 'Check') {
            updatePaymentMethodValue(value);
            return true;
        }
        // select Add New Credit Card
        if (value === 'addNewCreditCard') {
            updatePaymentMethodValue(value);
            updateRequestParam({ ...basicParam, ActionCode: 'EXPAYCC' });
            // updatePaymentDetailsForDownPaymentExt({ paymentMethd: 'creditcard' });
            return true;
        }

        // select Add New Bank Acount
        if (value === 'addNewBankAccount') {
            // popup a window to enter
            const paymentDetails = {
                paymentMethod: 'autopay_Ext',
                bankAccountData: {
                    bankAccountType: 'checking'
                }
            };
            const popupOneTimePaymentMethodVM = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
            return Promise.resolve(onReject()).then(() => {
                let code = _.get(model, 'payUsing', '');
                let newAddOneTimePaymentMethodsVM = null;
                showAddpaymentMethodPopup(popupOneTimePaymentMethodVM)
                    .then(async (updatedVM) => {
                        // resetPopupPaymentMethodVM();
                        // check payment exist in payment methods or one-time payment
                        const paymentMethodOptions = paymentMethodsVM.concat(
                            oneTimePaymentMethodsVM
                        );
                        const result = PaymentUtil.checkPaymentMethodExist(
                            updatedVM,
                            paymentMethodOptions
                        );
                        if (!_.isEmpty(result)) {
                            updateElectronicBank(result, basicParam);
                            return true;
                        }
                        const payDate = WniDateUtil.formatDateWithPattern(new Date());
                        const oneTimePaymentPublicID = PaymentUtil
                            .getOneTimePaymentPublicID(updatedVM);
                        const toUpdateRequestParam = PaymentUtil.getToUpdateRequestParam(
                            basicParam,
                            payDate,
                            _.get(updatedVM, 'value')
                        );
                        updateRequestParam(toUpdateRequestParam);
                        // const paymentDetailsDTO = {
                        //     paymentMethod: 'autopay_Ext',
                        // };
                        // updatePaymentDetailsForDownPaymentExt(paymentDetailsDTO);
                        // add option to dropdown
                        _.set(
                            updatedVM,
                            'value.bankAccountData.publicID',
                            oneTimePaymentPublicID
                        );
                        // add this option only to the dropdown list
                        const modifiedVM = viewModelService.clone(updatedVM);
                        newAddOneTimePaymentMethodsVM = [
                            ...oneTimePaymentMethodsVM,
                            modifiedVM,
                        ];
                        updateOneTimePaymentMethodsVM(newAddOneTimePaymentMethodsVM);
                        // updatePaymentDetailsForDownPayment(oneTimePaymentPublicID);
                        updatePaymentMethodValue(oneTimePaymentPublicID);
                        code = oneTimePaymentPublicID;
                        return true;
                    })
                    .catch(() => {
                        updatePaymentMethodValue(undefined);
                        _.noop();
                        return false;
                    })
                    .finally(() => {
                        // update options and value
                        _.set(selectedItem, 'rowData.payUsing', code);
                        if (newAddOneTimePaymentMethodsVM) {
                            _.set(selectedItem, 'rowData.newAddOneTimePaymentMethodsVM', newAddOneTimePaymentMethodsVM);
                        }
                        rePopupDownPaymentSetup(selectedItem);
                        _.noop();
                    });
            });
        }
        // select added bank
        if (value && paymentMethodsVM.length > 0) {
            const options = paymentMethodsVM;
            const selectedVM = PaymentUtil.findSelectedPaymentMethod(value, options);
            updateElectronicBank(selectedVM, basicParam);
            return true;
        }
        // select default
        updatePaymentMethodValue(value);
        return false;
    }, [isValueValid, paymentAmount,
        routerBaseName, quoteID, model, isMultiAndNew, paymentMethodsVM,
        viewModelService, onReject,
        showAddpaymentMethodPopup, oneTimePaymentMethodsVM, updateElectronicBank,
        selectedItem, rePopupDownPaymentSetup]);

    const agencySweepMessage = useCallback(() => {
        return (
            <div>
                <span className={styles.fontWeightNormal}>
                    You have selected Agency Sweep as the down payment method.  Please note, you will see a separate down payment for each policy issued.  If you are submitting multiple policies for issue, the down payment amount will not be combined.
                </span>
            </div>
        )
    }, []);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        accountLabelTitle: {
            content: `${translator(messages2.forQuotes)}: `
        },
        accountNumberTitle: {
            content: getAccountInfo()
        },
        paymentAmount: {
            disabled: !isCLProduct,
            value: paymentAmount,
            label: translator(messages.paymentAmount),
            showErrors: !isValueValid(),
            validationMessages: isValueValid() ? [] : [
                translator(messages.minimumAmount, {
                    amount: _.get(totalDownPaymentAmount, 'amount')
                }),
            ],
            className: 'form-control-no-label',
            onValueChange: updatePaymentAmount
        },
        paymentMethod: {
            label: translator(messages.paymentMethod),
            availableValues: getPaymentMethodDropdownData(),
            onValueChange: handleDownPaymentMethodChange,
            value: paymentMethodValue,
            required: true
        },
        agencySweepMessage: {
            label: ' ',
            value: agencySweepMessage(),
            readOnly: true,
            visible: paymentMethodValue === 'AgencySweep'
        },
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="paymentDetailPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={model}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

DownPaymentSetup.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    rowData: PropTypes.shape({}).isRequired,
    rePopupDownPaymentSetup: PropTypes.func,
    quoteID: PropTypes.string.isRequired,
    sessionUUID: PropTypes.string.isRequired,
    payUsing: PropTypes.string.isRequired,
    totalDownPaymentAmount: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.PropTypes.shape({})
};
DownPaymentSetup.defaultProps = {
    size: 'md',
    rePopupDownPaymentSetup: null,
    viewModelService: undefined,
};
export default DownPaymentSetup;
