
import React from 'react';

import { CPCommercialPropertyReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPCommercialPropertyChangeReadOnlyPage(props) {

    return (
        <CPCommercialPropertyReadOnlyPage {...props} />
    );

}

CPCommercialPropertyChangeReadOnlyPage.propTypes = CPCommercialPropertyReadOnlyPage.propTypes;
CPCommercialPropertyChangeReadOnlyPage.defaultProps = CPCommercialPropertyReadOnlyPage.defaultProps;

export default CPCommercialPropertyChangeReadOnlyPage;