import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    ErrorsAndWarningsUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import { CRStateSpecificInfoService } from 'wni-capability-quoteandbind-cr'
import WizardPage from '../../templates/CRWizardPage';
import metadata from './CRStateSpecificInformationPage.metadata.json5';
import messages from './CRStateSpecificInformationPage.messages';
import CRStateInfoComponent from './component/CRStateInfoComponent';

function CRStateSpecificInformationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        isReadOnly
    } = props;

    const {
        jobID, 
        sessionUUID,
        lobData: {
            'crime': {
                coverables: {
                    stateSimpleInfos = []
                }
            }
        },
    } = submissionVM.value;

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        initialValidation,
        onValidate,
        isComponentValid,
    } = useValidation('CRStateSpecificInformationPage');
    
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [selectedState, setSelectedState] = useState()
    
    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    const handleValidation = useCallback(() => {
        return false;
    }, []);

    const highlightRowFn = useCallback(() => {
        WniTableRowUtil.setTablePublicIDSelected(selectedState, 'stateSpecificTable');
    }, [selectedState]);

    useEffect(() => {
        highlightRowFn();
    }, [highlightRowFn]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn();
        return DatatableUtil[sortType](a, b);
    };

    const renderStateCell = (data, index, {path, typeKey}) => {
        const formatValue = data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-';
        return <span className='text-breakAll'>{formatValue}</span>
    };

    const viewOrEditStateInfo = (value) => {
        const {jurisdiction} = value
        setSelectedState(jurisdiction)
    };

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        const updatedSubmissionVM = setWizardDataToDraft(newSubmissionVM)
        updateSubmissionVM(updatedSubmissionVM)
    }

    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const handleStateSpecificInfoClose = () => {
        setSelectedState(undefined)
    }

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await CRStateSpecificInfoService.onStateInfoPageNext(jobID, sessionUUID, authHeader)
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return newSubmissionVM
    }

    //---------------------
    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
            readOnly: isReadOnly
        },
        stateInfoTable: {
            data: stateSimpleInfos,
            rowIdPath: 'jurisdiction'
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.crViewLabel : messages.crViewAndEditLabel
        },
        stateSpecificInfoContainer: {
            visible: !!selectedState,
        },
        stateSpecificInfo: {
            selectedState: selectedState,
            wizardData: submissionVM,
            handleStateSpecificInfoClose,
            updateWizardDataWhileSetPeriodStatus: updateWizardDataWhileSetPeriodStatus,
        }
    };
    const resolvers = {
        resolveClassNameMap: {},
        resolveCallbackMap: {
            viewOrEditStateInfo,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderStateCell,
        },
        resolveComponentMap: {
            StateSpecificInfo: CRStateInfoComponent
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showPrevious={!selectedState}
            showCancel={!selectedState}
            showEntityNameInPageLevelIssues
            disableNext={selectedState}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={pageLevelValidation}
            alwaysCallOnNext={!isReadOnly}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateSubmissionVM}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

CRStateSpecificInformationPage.propTypes = WizardPage.propTypes;
CRStateSpecificInformationPage.defaultProps = WizardPage.defaultProps;
export default CRStateSpecificInformationPage;