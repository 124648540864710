import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { Icon, Tooltip } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { ErrorsAndWarningsUtil, UWIssueUtil } from 'wni-portals-util-js';
import ValidationIssuesComponent from '../../ErrorsAndWarnings/ValidationIssuesComponent/ValidationIssuesComponent';
import metadata from './QuoteUnderwritingIssuesList.metadata.json5';
import styles from './QuoteUnderwritingIssuesList.module.scss';
import messages from './QuoteUnderwritingIssuesList.messages';

/**
 * Map UWIssueDTO array into [{type, reason}] based on blocking or non-blocking.
 * @param {array} uwIssues
 * @param {function} issueDescFormatter
 * @returns {array}
 */
function mapUWIssuesToValidationIssues(uwIssues, issueDescFormatter) {
    const validationIssues = uwIssues.map((item) => {
        const isBlockingIssue = ErrorsAndWarningsUtil.isBlockingOrRejectedUWIssue(item);
        const availableValuesList = {
            type: isBlockingIssue ? 'error' : 'warning',
            reason: issueDescFormatter(item)
        };
        return availableValuesList;
    });
    return validationIssues;
}

/**
 * Combines isBlockingOrRejectedUWIssue() and isUwIssuePendingApproval()
 * @param {object} uwIssue
 * @returns {boolean}
 */
function isUWIssueBlockingWhilePendingApproval(uwIssue) {
    return ErrorsAndWarningsUtil.isBlockingOrRejectedUWIssue(uwIssue)
        && ErrorsAndWarningsUtil.isUwIssuePendingApproval(uwIssue);
}

const QuoteUnderwritingIssuesList = (props) => {
    const {
        underwritingIssues,
        // headerObject,
        issueDescFormatter,
        // hideTooltip,
        issueFilter,
    } = props;
    const translator = useTranslator();

    const blockingUWIssueTitle = translator(messages.requiringUnderwriterReferral);
    const nonBlockingUWIssueTitle = translator(messages.requiringPostIssuanceReview);

    const getUWIssueTypeTitle = (type, issues = []) => {
        // default type === 'error'
        let retval = UWIssueUtil.formatUWIssueTypeTitle(blockingUWIssueTitle, issues.length);

        if (type === 'warning') {
            retval = UWIssueUtil.formatUWIssueTypeTitle(nonBlockingUWIssueTitle, issues.length);
        }
        
        return retval;
    };


    const getUWIssuesList = useCallback(() => {
        const filteredUWIssues = underwritingIssues.filter(issueFilter);
        // const validationIssues = mapUWIssuesToValidationIssues(filteredUWIssues, issueDescFormatter);
        const validationIssues = filteredUWIssues.map((item) => {
            const isBlockingIssue = ErrorsAndWarningsUtil.isBlockingOrRejectedUWIssue(item);
            const availableValuesList = {
                type: isBlockingIssue ? 'error' : 'warning',
                reason: issueDescFormatter(item)
            };
            return availableValuesList;
        });
        return (
            <ValidationIssuesComponent
                validationIssues={validationIssues}
                typeTitleFormatter={getUWIssueTypeTitle}
                scrollToIssues={false}
                initiallyCollapsed
            />
        );
    }, [underwritingIssues]);

    

    const resolvers = {
        resolveCallbackMap: {},
        resolveClassNameMap: styles,
        resolveComponentMap: {}
    };

    // ===========================
    if (_.isEmpty(underwritingIssues)) {
        return (null);
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        UWIssuesListContainer: {
            content: getUWIssuesList()
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={underwritingIssues}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

QuoteUnderwritingIssuesList.propTypes = {
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({
        longDescription: PropTypes.string,
        hasApprovalOrRejection: PropTypes.bool,
        currentBlockingPoint: PropTypes.string,
    })).isRequired,
    // headerObject: PropTypes.shape({}).isRequired,
    issueDescFormatter: PropTypes.func,
    // hideTooltip: PropTypes.bool,
    /**
     * This filter func decides which UWIssue to show
     */
    issueFilter: PropTypes.func,
};

QuoteUnderwritingIssuesList.defaultProps = {
    // issueDescFormatter: (uwIssue) => uwIssue.shortDescription,
    issueDescFormatter: (uwIssue) => uwIssue.longDescription,
    // hideTooltip: false,
    issueFilter: isUWIssueBlockingWhilePendingApproval,
};

export default QuoteUnderwritingIssuesList;
