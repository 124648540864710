import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    Redirect,
    Prompt,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import _ from 'lodash';

// import { ModalService } from '@jutro/components';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardUtil, WizardStepUtil } from 'wni-portals-util-js';
// import BaseWizard from '../../Wizard/BaseWizard';
import CommonTransactionWizard from '../Transaction/CommonTransactionWizard';

import useBaseWizardErrorsV2 from '../../Wizard/hooks/useBaseWizardErrorsV2';
import { WizardErrorContext } from './WizardErrorContext';
import { PromptService } from '@jutro/legacy/router';


function WizardWithErrorContext(props) {

    const {
        initialWizardLevelIssues,
        ...wizardProps
    } = props;

    const wizardErrors = useBaseWizardErrorsV2(initialWizardLevelIssues);

    return (
        <WizardErrorContext.Provider value={wizardErrors}>
            {/* <BaseWizard {...wizardProps} /> */}
            <CommonTransactionWizard {...wizardProps} />
        </WizardErrorContext.Provider>
    );
}

WizardWithErrorContext.propTypes = {
    ...CommonTransactionWizard.propTypes,
    initialWizardLevelIssues: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        reason: PropTypes.reason,
    })),
};
WizardWithErrorContext.defaultProps = {
    ...CommonTransactionWizard.defaultProps,
    initialWizardLevelIssues: undefined
};

export default WizardWithErrorContext;