/* eslint no-param-reassign: "error"*/
import _ from 'lodash';
import { ProductUtil } from 'wnice-portals-util-react';
import ClaimUtil from './utils/ClaimUtil';

export default {
    mixinLobDraftDataExtensions: (claim) => {
        if (!claim.policy) {
            return;
        }
        claim.lobs = (_.isObject(claim.lobs)) ? claim.lobs : {};
        const { 
            policyType,
            lobCode_Ext: lobCode
         } = claim.policy;

         let lobType = policyType;
         if (policyType === 'CommercialPackage') {
            switch (lobCode) {
                case ProductUtil.FNOL_LOB_CP:
                    lobType = 'CommercialProperty';
                    break;
                case ProductUtil.FNOL_LOB_GL:
                    lobType = 'GeneralLiability';
                    break;
                case ProductUtil.FNOL_LOB_IM:
                    lobType = 'InlandMarine';
                    break;
                case ProductUtil.FNOL_LOB_CR:
                    lobType = 'Crime';
                    break;
                default:
                    break;
            }
         }
        const { lob, claimExtension } = ClaimUtil.getLobExtensionByPolicyType(lobType);
        _.set(claim, `lobs.${lob}`, claimExtension(claim));
    },

    setLossDetails: (claim) => {
        const { policyType } = claim.policy;
        switch (policyType) {
            case 'Watercraft_EXT':
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case 'RoadTrail_EXT':
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case 'PersonalAuto':
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case 'BusinessAuto':
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case 'Homeowners':
            case 'HOPHomeowners':
            case 'DwellingFire_EXT': 
            case 'DwellingProperty': 
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            case 'GeneralLiability':
                claim.lossType = 'GL';
                claim.lossCause = undefined;
                break;
            case 'BusinessOwners':
                claim.lossType = 'GL';
                claim.lossCause = undefined;
                break;
            case 'InlandMarine':
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            case 'WorkersComp':
                claim.lossType = 'WC';
                claim.lossCause = undefined;
                break;
            case 'CommercialProperty':
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            case 'CommercialPackage':
                const { lobCode_Ext: lobCode } = claim.policy;
                switch (lobCode) {
                    case ProductUtil.FNOL_LOB_CP:
                    case ProductUtil.FNOL_LOB_IM:
                    case ProductUtil.FNOL_LOB_CR:
                        claim.lossType = 'PR';
                        claim.lossCause = undefined;
                        break;
                    case ProductUtil.FNOL_LOB_GL:
                        claim.lossType = 'GL';
                        claim.lossCause = undefined;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }
};
