import { R1Config } from 'wni-portals-config-js';

const { availablePATermTypeCodes } = R1Config;

function getPATermTypeAvailableValues(translator) {
    return availablePATermTypeCodes.map((code) => {
        return {
            code: code,
            name: translator({
                id: `typekey.TermType.${code}`,
                defaultMessage: `typekey.TermType.${code}`
            })
        };
    });
}

export default {
    getPATermTypeAvailableValues
};
