import React, {
    useState, useEffect, useCallback
} from 'react';
import _ from 'lodash';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WNIAddressLookupService } from 'wni-capability-address';
import { RecommendInputComponent } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { UserService } from 'gw-capability-gateway';
import metadata from './PageHeaderComponent.metadata.json5';
import messages from './PageHeaderComponent.messages';
import { ApplicationHeader } from '@jutro/legacy/router';


function PageHeaderComponent(props) {
    const {
        history
    } = props;
    const translator = useTranslator();
    const { domainCompany, interactionModel, workflowType } = useDependencies(['domainCompany', 'interactionModel', 'workflowType']);
    // const { interactionModel } = useDependencies('interactionModel');
    // const { workflowType } = useDependencies('workflowType');
    const { authHeader, logout, userInfo: { name: userName} } = useAuthentication();
    const [searchText, updateSearchText] = useState('');
    const [isLogout, updateIsLogout] = useState(false);
    const [showStartNewButton, updateShowStartNewButton] = useState(false);
    const [canCreateAccount, updateCanCreateAccount] = useState(false);
    const [recommendAccounts, updateRecommendAccounts] = useState([]);
    useEffect(() => {
        const parentDom = document.getElementById('headerDropdownAvatar');
        const btnDom = parentDom && parentDom.getElementsByTagName('button')[0];
        if (btnDom) {
            btnDom.tabIndex = -1;
        }
    }, []);
    const getShowStartNewQuoteButton = async () => {
        const permissionDTO = {
            permission: 'createsubmission'
        };
        const showStartNewButtonNew = await
        UserService.hasUserSystemPermission(permissionDTO, authHeader);
        updateShowStartNewButton(showStartNewButtonNew);
    };

    const showNewQuoteButton = async () => {
        const accountPermCheckDTO = {
            permEntityType: 'Account',
            permEntityID: null,
            permission: 'create',
            isCheckPermEntity: false
        };

        const canCreateAccountNew = await UserService.hasUserPermission(accountPermCheckDTO,
            authHeader);
        updateCanCreateAccount(canCreateAccountNew);
    };

    useEffect(() => {
        getShowStartNewQuoteButton();
        showNewQuoteButton();
    }, []);

    const goToLogout = () => {
        if (!isLogout) {
            logout();
            updateIsLogout(true);
        }
    };

    const goToNewQuote = () => {
        const fromLocation = history.location.pathname;
        const { lobQuoteURL } = appConfig;
        const lobPaths = Object.values(lobQuoteURL) || [];
        const fromLOB = lobPaths.filter((value) => fromLocation.indexOf(value) !== -1);

        const previousLocation = {
            prevLocation: !!fromLOB.length
        };
        // return history.push('/new-quote', previousLocation);

        if (workflowType.ClassicalWorkflow) {
            return history.push('/new-quote-account-search/search', previousLocation);
        }
        // return history.push('/new-quote-account-search/search', previousLocation);
        return history.push({
            pathname: '/new-quote',
            state: {
                isNewQuote: true,
                previousLocation
            }
        });
    };

    const goToQuoteThisAccount = () => {
        const fromLocation = history.location.pathname;
        const accountNumber = fromLocation.match('accounts/(.*)/(.*)');
        return history.push(`/new-quote/${accountNumber[1]}`);
    };

    const lookupServiceFn = useCallback(_.debounce(async (value) => {
        try {
            // CL only specific search
            // const res = WNIAddressLookupService.searchCLAccountNumbers(
            //     value,
            //     authHeader
            // )
            const res = await WNIAddressLookupService.searchAccountNumbers(
                    value,
                    authHeader,
                )
            const recommendList = res ? res.map((item) => {
                return {
                    recommendCode: `Account: ${item}`,
                    accountNumber: item
                };
            }) : [];
            updateRecommendAccounts(recommendList);
        } catch (e) {
            _.noop();
        }
    }, 1000), []);


    const writeValue = (value) => {
        updateSearchText(value);
    };
    const handelRecommendFn = (value) => {
        writeValue(value);
        if (!value || value.length < 3) {
            return false;
        }
        lookupServiceFn(value);
        return true;
    };

    const handleSelectionRecommendFn = (account) => {
        const { accountNumber } = account;
        interactionModel.goToPage('header', history, 'accountSummary', accountNumber);
    };

    const goToAOAccountSearch = () => {
        interactionModel.goToPage('header', history, 'accountSearch', searchText);
    };

    const resolvers = {
        // resolveClassNameMap: pageHeaderStyles,
        resolveCallbackMap: {
            goToLogout: goToLogout,
            goToNewQuote: goToNewQuote,
            goToQuoteThisAccount: goToQuoteThisAccount,
            goToAOAccountSearch: goToAOAccountSearch
        },
        resolveComponentMap: {
            applicationheader: ApplicationHeader,
            recommendinputcomponent: RecommendInputComponent
        }
    };

    const { domainLogoClass } = domainCompany;
    const overrideProps = {
        '@all': {
            tabIndex: -1,
        },
        logo: {
            className: `gw-app-logo ${domainLogoClass}`
        },
        appLogoLink: {
            onClick: () => {
                interactionModel.goToPage('header', history, 'homePage');
            }
        },
        settingProfileLink: {
            onClick: () => {
                interactionModel.goToPage('header', history, 'profileSettings');
            }
        },
        settingPreferenceLink: {
            onClick: () => {
                interactionModel.goToPage('header', history, 'preferenceSettings');
            }
        },
        settingFAQLink: {
            onClick: () => {
                interactionModel.goToPage('header', history, 'FAQSettings');
            }
        },
        settingLogout: {
            onClick: () => {
                // if (isDebugEnabled) {
                //     goToLogout();
                //     return;
                // }
                interactionModel.goToPage('header', history, 'logout', goToLogout);
            }
        },
        headerUserInfo: {
            content: `Welcome, ${userName}`
        },
        startNewQuoteBtn: {
            visible: canCreateAccount && showStartNewButton
        },
        recommendInput: {
            visible: interactionModel.code === 'PE',
            path: '',
            value: searchText,
            availableValues: recommendAccounts,
            onValueChange: handelRecommendFn,
            onSelectionChange: handleSelectionRecommendFn,
            // onValueEnter: goToAOAccountSearch,
            placeholder: translator(messages.searchPlaceholder)
        },
        searchInput: {
            visible: interactionModel.code !== 'PE',
            path: '',
            value: searchText,
            onValueChange: (value) => updateSearchText(value),
            onEnter: goToAOAccountSearch
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

PageHeaderComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            pathname: PropTypes.string
        })
    }).isRequired
};

PageHeaderComponent.defaultProps = {

};

export default withRouter(withAuthenticationContext(PageHeaderComponent));
