import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/waloperator'), method, data, additionalHeaders);
}

export default class WALOperatorService {

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static updateOperator(data, additionalHeaders = {}) {
        return processSubmission(
            'updateOperator',
            [data.jobID, data.sessionUUID, data.operator],
            additionalHeaders
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static removeOperators(data, additionalHeaders = {}) {
        return processSubmission(
            'removeOperators',
            [data.jobID, data.sessionUUID, data.publicIDs],
            additionalHeaders
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static addOperatorForPNI(data, additionalHeaders = {}) {
        return processSubmission(
            'addOperatorForPNI',
            [data.jobID, data.sessionUUID],
            additionalHeaders
        );
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static onPageNext(data, additionalHeaders = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            additionalHeaders
        );
    }

}
