import React from 'react';

import { WniCPPolicyDetailsPage } from 'wni-capability-quoteandbind-cp-react';

function CPPolicyDetailsChangePage(props) {

    return (
        <WniCPPolicyDetailsPage {...props} />
    );

}

CPPolicyDetailsChangePage.propTypes = WniCPPolicyDetailsPage.propTypes;
CPPolicyDetailsChangePage.defaultProps = WniCPPolicyDetailsPage.defaultProps;

export default CPPolicyDetailsChangePage;
