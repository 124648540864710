import React, {
    Component,
    useEffect,
    useCallback,
    useState,
    useContext,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withProducerContext, ProducerContext } from 'gw-gateway-common-react';
import CategoryFilterCard from './CategoryFilterCard';
import PolicyLandingUtil from './PolicyLandingUtil';

const policiesID = ['RecentlyViewedTile', 'RecentlyIssuedTile', 'DelinquentTile'];

function PolicyAdvancedFilter(props) {

    const {
        policies: policiesProp,
        accounts,
        clickedTile,
        onValueChange,
        initCategoryName: initCategoryNameProp
    } = props;

    const { producerCode } = useContext(ProducerContext);

    const [selectedCategory, updateSelectedCategory] = useState(undefined);
    const [initCategoryName, updateInitCategoryName] = useState('');
    const [policies, updatePolicies] = useState();
    const [customFilters, updateCustomFilters] = useState();
    const [policyLineFilter, updatePolicyLineFilter] = useState();

    const [util, updateUtil] = useState(undefined);

    const filterCallback = ({
        selectedCategory: selectedCategoryParam = selectedCategory,
        policies: policiesParam = policies,
        producerCode: producerCodeParam = producerCode,
        util: utilParam = util,
    } = {}) => {
        const filteredPolicies = utilParam.callFiltersForCurrentFilterOptions(
            producerCodeParam, selectedCategoryParam, policiesParam
        );
        const policy = {
            currentView: selectedCategoryParam,
            data: filteredPolicies
        };
        onValueChange(policy);
    };

    useEffect(() => {
        const initUtil = new PolicyLandingUtil();
        initUtil.initJobs(accounts);

        const [newCustomFilters, newPolicyLineFilter] = initUtil.getPolicySummaries(
            policiesProp, clickedTile
        );
        let getDisplayValueForAdvFilter;
        const checkPolicyIDAvailability = policiesID.includes(clickedTile);
        if (checkPolicyIDAvailability) {
            getDisplayValueForAdvFilter = newCustomFilters[0].displayValue;
        } else {
            getDisplayValueForAdvFilter = _.find(newCustomFilters, (item) => item.displayValue === clickedTile)?.displayValue
        }
        
        updateUtil(initUtil);
        updatePolicies(policiesProp);
        updateCustomFilters(newCustomFilters);
        updatePolicyLineFilter(newPolicyLineFilter);
        updateSelectedCategory(getDisplayValueForAdvFilter);
        updateInitCategoryName(initCategoryNameProp);

        filterCallback({
            selectedCategory: getDisplayValueForAdvFilter,
            policies: policiesProp,
            util: initUtil,
        });
    }, []);

    useEffect(() => {
        if (!util) {
            return;
        }
        filterCallback();
    }, [producerCode, util]);

    const onStatusValueChange = (category) => {
        if (initCategoryName !== '') {
            updateInitCategoryName('');
        }
        updateSelectedCategory(category);
        filterCallback({
            selectedCategory: category,
        });
    };

    const onProductFilterChange = (value) => {
        updatePolicyLineFilter(value);
        filterCallback();
    };


    const render = () => {
        if (!customFilters || !policyLineFilter || !selectedCategory) {
            return null;
        }
        return (
            <div className="section-sidebar-wrapper">
                <CategoryFilterCard
                    selectedCategory={selectedCategory}
                    customFilter={customFilters}
                    onValueChange={onStatusValueChange}
                    countInformation={util.policyCountInformation}
                    initCategoryName={initCategoryName}
                    policies={policies}
                />
            </div>
        );
    };

    return render();
}

PolicyAdvancedFilter.propTypes = {
    onValueChange: PropTypes.func.isRequired,
    clickedTile: PropTypes.string.isRequired,
    initCategoryName: PropTypes.string.isRequired,
};

PolicyAdvancedFilter.defaultProps = {
    // producerCode: undefined
};

export default PolicyAdvancedFilter;
