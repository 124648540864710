import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext';
import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil';
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';
import { AccordionCard } from '@jutro/legacy/components';

const LocationStandardPerSublineClauses = (props) => {
    const {
        locationPublicID,
        locationSublineClauses,
        setLocationSublineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: multiSublinesOnValidate,

        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        publicID: locationSublinePublicID,
        standardCoverage,
        sublineTypeDisplayName 
    } = locationSublineClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const validationID = `LocationStandardPerSublineClauses_${locationSublinePublicID}`

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (multiSublinesOnValidate) {
            multiSublinesOnValidate(isComponentValid, validationID);
        }
        return () => {
            if (multiSublinesOnValidate) {
                multiSublinesOnValidate(true, validationID);
            }
        }
    }, [isComponentValid, multiSublinesOnValidate, validationID])


    const [loadingClauses, setLoadingClauses] = useState(false);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(standardCoverage, 'standardCoverage', value, changedPath);
            const newSublineClauses = _.clone(locationSublineClauses)
            _.set(newSublineClauses, 'standardCoverage', newClauses);
            setLocationSublineClauses(newSublineClauses);
            setIsEditing(false);
            return newSublineClauses;
        },
        [standardCoverage, locationSublineClauses, setLocationSublineClauses, setIsEditing]
    );

    const updateVMToServer = useCallback(async (newLocationSublineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const locationSubLineClausesToUpdate = GLCoverageUtil.generateUpdatedLocationSublineClausesDTO(newLocationSublineClauses);
        const updatedLocationSublineClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLocationSublineClausesDTO(locationSubLineClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedLocationSublineClausesPublicIDs)
        const response = await GLCoverageService.updateLocationPerSublineClauses(
            jobID,
            sessionUUID,
            locationPublicID,
            locationSublinePublicID,
            locationSubLineClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setLocationSublineClauses(response);
    }, [setLoadingMask, jobID, sessionUUID, locationPublicID, locationSublinePublicID, authHeader, setLocationSublineClauses]);

    const updateNewSubLineClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);



    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeSubmission(value, changedPath);
            
            updateNewSubLineClausesToServer(newSublineClauses);
        },
        [changeSubmission, updateNewSubLineClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewSubLineClausesToServer(locationSublineClauses);
        },
        [locationSublineClauses, updateNewSubLineClausesToServer]
    )

    return (
        <AccordionCard
            id={`${locationSublinePublicID}_StandardCoverage`}
            chevron
            className="coveragesAccordion"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={sublineTypeDisplayName}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="standardCoverage"
                        value={standardCoverage}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default LocationStandardPerSublineClauses;
