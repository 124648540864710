import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class CPCoverablesService {
    static addCPLocation(quoteID, cpLocation, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'addLocation', [quoteID, cpLocation, sessionUUID], additionalHeaders);
    }

    static updateCPLocation(quoteID, cpLocation, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'updateLocation', [quoteID, cpLocation, sessionUUID], additionalHeaders);
    }

    static removeCPLocation(quoteID, cpLocation, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'removeLocation', [quoteID, cpLocation, sessionUUID], additionalHeaders);
    }

    static addCPBuilding(quoteID, cpLocationId, cpBuilding, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'addBuilding', [quoteID, cpLocationId, cpBuilding, sessionUUID], additionalHeaders);
    }

    static updateCPBuilding(
        quoteID,
        cpLocationId,
        cpBuilding,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'updateBuilding', [quoteID, cpLocationId, cpBuilding, sessionUUID], additionalHeaders);
    }

    static removeCPBuilding(
        quoteID,
        cpLocationId,
        cpBuildingId,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'removeBuilding', [quoteID, cpLocationId, cpBuildingId, sessionUUID], additionalHeaders);
    }

    static updateCPBuildingCoverages(
        quoteID,
        cpLocationId,
        cpBuilding,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('cpCoverables'), 'updateBuildingCoverages', [quoteID, cpLocationId, cpBuilding, sessionUUID], additionalHeaders);
    }
}
