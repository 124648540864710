import React, {
    useEffect,
} from 'react';
import {
    useHistory,
    useParams,
} from "react-router-dom";

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';


const { lobEndorsementURL, endorsementProducts } = appConfig;
// const { lobQuoteURL } = appConfig;

function PolicyChangeWizardCommonRoutes(props) {

    const history = useHistory();
    const {
        productCode,
        policyNumber,
        jobID,
        landingPageID,
    } = useParams();

    useEffect(() => {
        const targetURL = lobEndorsementURL[productCode];
        const stateObj = {
            policyNumber,
            jobNumber: jobID,
            landingPageID,
        }
        history.push(targetURL, stateObj);
    }, []);

    return null;

}

export default PolicyChangeWizardCommonRoutes;