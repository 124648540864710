import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { 
    AddressInputComponent, AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from 'wni-capability-gateway-react';
import metadata from './HouseholdLocationPopupComponent.metadata.json5';
import styles from './HouseholdLocationPopupComponent.module.scss';
import messages from './HouseholdLocationPopupComponent.messages';

import { Button } from '@jutro/legacy/components';

/**
 * Component contains Location with address
 * @param {object} props
 * @returns {object} React Component
 */
const HouseholdLocationPopupComponent = (props) => {
    const {
        addressDataVM,
        addressOptions,
        readOnly,
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        size,
        viewModelService,
        emptyVM,
        showDropDown,
        translator,
        useAuthenticationData,
        linePath
    } = props;

    const modalApi = useWniModal();
    const { authHeader } = useAuthenticationData;
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isAddressFlag, updateAddressFlag] = useState(false);
    const [dtoVM, updateDtoVM] = useState(viewModelService.clone(addressDataVM));
    const { isComponentValid, onValidate } = useValidation('HouseholdResidentsPopUp');
    const [showErrors, updateShowErrors] = useState(false);

    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);

    const writeValue = useCallback((value, path) => {
        if (linePath === 'dwellingProperty') {
            if (AddressChangeVerify(path, 'address')) { // when address filed change
                // address change, the warning message about invaild address set hide
                const verifyMsg = getVerifyAddressIssues(false);
                updateLookupValidation(verifyMsg);
                // set the flag false, and click next button, verify address again
                updateAddressFlag(false);
            }
        }
        const newVM = viewModelService.clone(dtoVM);
        _.set(newVM, `value.${path}`, value)
        updateDtoVM(newVM);
    }, [dtoVM, viewModelService]);

    const handleSave = useCallback( async () => {
        if (!isComponentValid) {
            updateShowErrors(true);
            return false;
        }
        if (linePath === 'dwellingProperty') {
            const uiUtil = AddressVerifiedUtil({
                authHeader,
                addressVM: _.get(dtoVM, 'address'),
                addressPath: 'address',
                updateValidations: updateLookupValidation,
                writeValue,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                doublePopup: true,
                //
                modalApi,
            });
            const verifiedObj = await uiUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }
            _.set(dtoVM, 'address', verifiedObj.addressValue);
            updateDtoVM(dtoVM);
        }
        onResolve(_.get(dtoVM, 'value'));
    }, [authHeader, dtoVM, isAddressFlag, isComponentValid, linePath, modalApi, onResolve, updateLookupValidation, writeValue]);

    const handleLocationChange = useCallback((code) => {
        if (readOnly) {
            return;
        }
        if (code === 'new') {
            const newVM = viewModelService.clone(emptyVM);
            _.set(newVM, 'value.publicID', code);
            updateDtoVM(newVM);
            updateShowErrors(false);
        } else {
            const selectedOption = _.find(addressOptions, (option) => option.publicID === code);
            const newVM = viewModelService.clone(dtoVM);
            _.set(newVM, `value`, selectedOption);
            updateDtoVM(newVM);
        }
    }, [readOnly, viewModelService, emptyVM, addressOptions, dtoVM]);

    const getAddressOptions = useCallback(() => {
        const options = addressOptions.map((location) => {
            return {
                code: _.get(location, 'publicID'),
                name: _.get(location, 'address.displayName')
            };
        });
        options.push({
            code: 'new',
            name: translator(messages.new),
            country: 'US'
        });
        return options;
    }, [addressOptions, translator]);

    const getSelectedValue = useCallback(() => {
        const selected = _.get(dtoVM, 'value.publicID');
        if (selected) {
            return selected;
        }
        return undefined;
    }, [dtoVM]);

    const selectedValue = getSelectedValue();

    //----------------------------------
    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        location: {
            visible: !readOnly && showDropDown,
            onValueChange: handleLocationChange,
            availableValues: getAddressOptions(),
            value: selectedValue,
            label: translator(messages.location)
        },
        addressContainer: {
            model: dtoVM,
            dataPath: 'address',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true,
                pobox: true
            },
            showErrors: showErrors,
            onValidate,
            useAuthenticationData
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            addressinputcomponent: AddressInputComponent
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="householdResidentsLocationPopup">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={dtoVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
};

HouseholdLocationPopupComponent.propTypes = {
    addressDataVM: PropTypes.shape({}),
    addressOptions: PropTypes.arrayOf(PropTypes.shape({})),
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    size: PropTypes.string,
    viewModelService: PropTypes.shape({}),
    emptyVM: PropTypes.shape({}),
    showDropDown: PropTypes.bool,
    linePath: PropTypes.string,
};

HouseholdLocationPopupComponent.defaultProps = {
    addressDataVM: {},
    addressOptions: [],
    readOnly: false,
    title: '',
    actionBtnLabel: '',
    cancelBtnLabel: '',
    isOpen: true,
    onResolve: _.noop,
    onReject: _.noop,
    size: 'md',
    viewModelService: null,
    emptyVM: {},
    showDropDown: true,
    linePath: 'homeowners',
};

export default HouseholdLocationPopupComponent;
