import React from 'react';
import { CPBlanketsReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPBlanketsChangeReadOnlyPage(props) {

    return (
        <CPBlanketsReadOnlyPage {...props} />
    );

}

CPBlanketsChangeReadOnlyPage.propTypes = CPBlanketsReadOnlyPage.propTypes;
CPBlanketsChangeReadOnlyPage.defaultProps = CPBlanketsReadOnlyPage.defaultProps;

export default CPBlanketsChangeReadOnlyPage;