import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('dummyMultiQuote'), method, data, additionalHeaders);
}

export default class DummyMultiQuoteService {
    /**
     * Invokes DummyMultiQuoteHandler.processQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('processQuote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes DummyMultiQuoteHandler.finishQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static finishQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('finishQuote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes DummyMultiQuoteHandler.createDuplicateQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDuplicateQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDuplicateQuote', [quoteID, sessionUUID], authHeader);
    }


    /**
     * Invokes DummyMultiQuoteHandler.withdrawBranch()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} periodPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static withdrawBranch(quoteID, sessionUUID, periodPublicID, authHeader = {}) {
        return processSubmission('withdrawBranch', [quoteID, sessionUUID, periodPublicID], authHeader);
    }

    /**
     * Invokes DummyMultiQuoteHandler.updateBranchName()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} branchName
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBranchName(quoteID, sessionUUID, branchName, authHeader = {}) {
        return processSubmission('updateBranchName', [quoteID, sessionUUID, branchName], authHeader);
    }

}
