import { defineMessages } from 'react-intl';

export default defineMessages({
    ListOfScheduleItems: {
        id: 'wni.quoteandbind.im-wizard.im-BaileeCustomersFloater.LiatOfScheduleItems',
        defaultMessage: 'List Of Scheduled Items',
    },
    ScheduleItem: {
        id: 'wni.quoteandbind.im-wizard.im-BaileeCustomersFloater.ScheduleItem',
        defaultMessage: 'Schedule Item',
    },
});