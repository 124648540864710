import { defineMessages } from 'react-intl';

export default defineMessages({
    addPaymentMethodTitle: {
        id: 'quoteandbind.views.payment-details.Add Payment Method',
        defaultMessage: 'Add Payment Method',
    },
    dialogOk: {
        id: 'quoteandbind.views.payment-details.Add Payment Method.OK',
        defaultMessage: 'OK',
    },
    dialogCancel: {
        id: 'quoteandbind.views.payment-details.Add Payment Method.Cancel',
        defaultMessage: 'Cancel',
    },
    totalPremium: {
        id: 'quoteandbind.views.payment-details.Total Premium',
        defaultMessage: 'Total Premium'
    },
    stateTaxes: {
        id: 'quoteandbind.views.payment-details.State Taxes',
        defaultMessage: 'State Taxes'
    },
    billingFees: {
        id: 'quoteandbind.views.payment-details.Billing Fees',
        defaultMessage: 'Billing Fees'
    },
    fullPayDiscount: {
        id: 'quoteandbind.views.payment-details.Full Pay Discount',
        defaultMessage: 'Full Pay Discount'
    },
    totalCost: {
        id: 'quoteandbind.views.payment-details.Total Cost',
        defaultMessage: 'Total Cost'
    },
    payUsing: {
        id: 'quoteandbind.views.payment-details.Pay Using',
        defaultMessage: 'Pay Using'
    },
    frequency: {
        id: 'quoteandbind.views.payment-details.Frequency',
        defaultMessage: 'Frequency'
    },
    installments: {
        id: 'quoteandbind.views.payment-details.Installments',
        defaultMessage: 'Installments'
    },
    invoicing: {
        id: 'quoteandbind.views.payment-details.Invoicing',
        defaultMessage: 'Invoicing'
    },
    dueDate: {
        id: 'quoteandbind.views.payment-details.Due Date',
        defaultMessage: 'Day Of Month'
    },
    billing: {
        id: 'quoteandbind.views.payment-details.Billing',
        defaultMessage: 'Billing'
    },
    billingMethod: {
        id: 'quoteandbind.views.payment-details.Billing Method',
        defaultMessage: 'Billing Method'
    },
    dueDayOfTheMonth: {
        id: 'quoteandbind.views.payment-details.Due Day Of The Month',
        defaultMessage: 'Due Day Of The Month'
    },
    payUsingAdd: {
        id: 'quoteandbind.views.payment-details.Pay Using.Add',
        defaultMessage: 'Add'
    },
    primaryPayer: {
        id: 'quoteandbind.views.payment-details.Primary Payer',
        defaultMessage: 'Primary Payer'
    },
    downPayment: {
        id: 'quoteandbind.views.payment-details.Down Payment',
        defaultMessage: 'Down Payment'
    },
    downPaymentAgencySweep: {
        id: 'quoteandbind.views.payment-details.Down Payment.Agency Sweep',
        defaultMessage: 'Agency Sweep'
    },
    downPaymentElectronic: {
        id: 'quoteandbind.views.payment-details.Down Payment.Electronic',
        defaultMessage: 'Electronic'
    },
    downPaymentChoosePaymentMethod: {
        id: 'quoteandbind.views.payment-details.Down Payment.Choose Payment Method',
        defaultMessage: 'Choose Payment Method'
    },
    addNewCreditCard: {
        id: 'quoteandbind.views.payment-details.Down Payment.Add New Credit Card',
        defaultMessage: 'Add New Credit Card'
    },
    addNewBankAccount: {
        id: 'quoteandbind.views.payment-details.Down Payment.Add New Bank Account',
        defaultMessage: 'Add New Bank Account'
    },
    paymentPayAndIssue: {
        id: 'quoteandbind.views.payment-details.Pay and Issue',
        defaultMessage: 'Pay and Issue'
    },
    nonBillingContactsTitle: {
        id: 'quoteandbind.views.payment-details.Other Contacts',
        defaultMessage: 'Other Contacts'
    },
    billingContactsTitle: {
        id: 'quoteandbind.views.payment-details.Existing Billing Contacts',
        defaultMessage: 'Existing Billing Contacts'
    },
    newPersonTitle: {
        id: 'quoteandbind.views.payment-details.New Person Details',
        defaultMessage: 'New Person Details'
    },
    noDueDateDataMessage: {
        id: 'quoteandbind.views.payment-details.There is an issue with the Due Day of Month. Please try again later or call your Underwriter.',
        defaultMessage: 'There is an issue with the Due Day of Month. Please try again later or call your Underwriter.'
    },
    noPaymentPlanDataMessage: {
        id: 'quoteandbind.views.payment-details.There is an issue with the Payment Plans. Please try again later or call your Underwriter.',
        defaultMessage: 'There is an issue with the Payment Plans. Please try again later or call your Underwriter.'
    },
    bccErrorMessage: {
        id: 'quoteandbind.views.payment-details.System is facing an issue and could not submit payment now. Please try again later.',
        defaultMessage: 'System is facing an issue and could not submit payment now. Please try again later.'
    }
});
