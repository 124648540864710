import React, { Component, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { WniDateUtil, WindowUtil } from 'wni-portals-util-js';
import { withModalContext } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import messages from 'gw-components-platform-react/PaymentComponent/PaymentComponent.messages';
import PaymentStyles from 'gw-components-platform-react/PaymentComponent/PaymentComponent.module.scss';
import metadata from './PaymentComponent.metadata.json5';
import customMessages from './PaymentComponent.messages';

class PaymentComponentWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onCallback: PropTypes.func.isRequired,
        handleRemoveAutoPayment: PropTypes.func.isRequired,
        xCenter: PropTypes.string.isRequired,
        title: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        onCancel: PropTypes.func.isRequired,
        nextLabel: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        previousLabel: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        isSetupPayment: PropTypes.bool,
        isCustomFooter: PropTypes.bool,
        isDisabled: PropTypes.bool,
        paymentGroup: PropTypes.shape({}),
        sysLocalDate: PropTypes.shape({}),
        ...validationPropTypes
    };

    static defaultProps = {
        title: undefined,
        nextLabel: undefined,
        previousLabel: undefined,
        isSetupPayment: false,
        isCustomFooter: false,
        isDisabled: false,
        paymentGroup: undefined,
        sysLocalDate: {}
    }

    state = {
        paymentMethod: 'wire',
        accountCreditCardVM: {},
        accountBankDetailVM: {},
        showValidationErrors: false,
        dataExist: false,
        abaNumberVisible: false,
        tempBankAccountNumberWithMaskValue: '',
        tempBankAccountNumberValue: '',
        tempBankABANumberWithMaskValue: '',
        tempBankABANumberValue: ''
    }

    componentDidMount = () => {
        let { accountCreditCardVM, accountBankDetailVM, dataExist } = this.state;
        const { viewModelService, xCenter, paymentGroup, paymentInstrument } = this.props;
        if (paymentGroup && paymentInstrument && paymentInstrument.paymentMethod === 'autopay_Ext') {
            dataExist = true;
            const { bankAccountData } = paymentInstrument;
            accountCreditCardVM = viewModelService.create({},
                xCenter, 'edge.capabilities.billing.dto.AccountCreditCardDTO');
            accountBankDetailVM = viewModelService.create(bankAccountData,
                xCenter, 'edge.capabilities.billing.dto.AccountBankDetailsDTO');
        } else {
            const model = {};
            accountCreditCardVM = viewModelService.create(model,
                xCenter, 'edge.capabilities.billing.dto.AccountCreditCardDTO');
            accountBankDetailVM = viewModelService.create(model,
                xCenter, 'edge.capabilities.billing.dto.AccountBankDetailsDTO');
        }
        const bankAccountTypes = {
            checkingObj: _.find(accountBankDetailVM.bankAccountType_Ext.aspects.availableValues, {
                code: 'checking_Ext'
            }),
            savingsObj: _.find(accountBankDetailVM.bankAccountType_Ext.aspects.availableValues, {
                code: 'savings_Ext'
            }),
        };
        if (!_.get(accountBankDetailVM, 'bankAccountType_Ext.value')) {
            // set default bankAccountype
            _.set(accountBankDetailVM, 'bankAccountType_Ext.value', bankAccountTypes.checkingObj);
        }
        _.set(accountBankDetailVM, 'value.effectiveDate_Ext', WniDateUtil.getDateObj(this.sysLocalDate));
        this.setState({
            accountCreditCardVM,
            accountBankDetailVM,
            dataExist
        });
    }

    handlePaymentOptions = (data) => {
        this.setState({
            paymentMethod: data,
            showValidationErrors: false
        });
    }

    writeValue = (value, path) => {
        const { paymentMethod, accountCreditCardVM, accountBankDetailVM } = this.state;
        if (paymentMethod === 'wire') {
            _.set(accountBankDetailVM, path, value);
            this.setState({ accountBankDetailVM });
        } else {
            _.set(accountCreditCardVM, path, value);
            this.setState({ accountCreditCardVM });
        }
    };

    removeAutoPay = () => {
        const { handleRemoveAutoPayment } = this.props;
        const translator = this.context;
        if (handleRemoveAutoPayment) {
            this.props.modalContext.showConfirm({
                title: `${translator(customMessages.removeAutoPayment)}?`,
                message: translator(customMessages.removeConfirmMessage),
                iconClassType: false,
                status: 'warning',
                icon: 'gw-error-outline',
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                handleRemoveAutoPayment();
                return true;
            }, _.noop);
        }
    };

    payNowClicked = () => {
        const { onCallback } = this.props;
        const {
            paymentMethod,
            accountCreditCardVM,
            accountBankDetailVM
        } = this.state;
        const { isComponentValid } = this.props;
        const paymentVM = paymentMethod === 'wire' ? accountBankDetailVM : accountCreditCardVM;

        if (!isComponentValid) {
            this.setState({
                showValidationErrors: true
            });
            return;
        }

        if (onCallback) {
            onCallback(paymentVM, paymentMethod);
        }
    }

    getCurrentPaymentVM = () => {
        const {
            paymentMethod, accountCreditCardVM, accountBankDetailVM
        } = this.state;
        const paymentVM = paymentMethod === 'wire' ? accountBankDetailVM : accountCreditCardVM;
        return paymentVM;
    }

    reomoveBankAccountEncrypt = () => {
        const paymentVM = this.getCurrentPaymentVM();
        const bankAccountNumberWithMaskValue = _.get(paymentVM, 'value.bankAccountNumberWithMask_Ext');
        if (_.includes(bankAccountNumberWithMaskValue, '*')) {
            this.setState({
                tempBankAccountNumberWithMaskValue: bankAccountNumberWithMaskValue,
                tempBankAccountNumberValue: _.get(paymentVM, 'value.bankAccountNumber')
            });
            _.set(paymentVM, 'bankAccountNumber', '');
            this.writeValue('', 'bankAccountNumberWithMask_Ext');
        }
    }

    checkBankAccountNumberReset = () => {
        const paymentVM = this.getCurrentPaymentVM();
        const bankAccountNumberValue = _.get(paymentVM, 'value.bankAccountNumber');
        // rollback value if nothing changes
        if (_.isEmpty(bankAccountNumberValue)) {
            const {
                tempBankAccountNumberWithMaskValue, tempBankAccountNumberValue
            } = this.state;
            _.set(paymentVM, 'bankAccountNumber', tempBankAccountNumberValue);
            this.writeValue(tempBankAccountNumberWithMaskValue, 'bankAccountNumberWithMask_Ext');
        }
    }

    removeBankABANumberEncrypt = () => {
        const paymentVM = this.getCurrentPaymentVM();
        const bankABANumberWithMaskValue = _.get(paymentVM, 'value.bankABANumberWithMask_Ext');
        if (_.includes(bankABANumberWithMaskValue, '*')) {
            this.setState({
                tempBankABANumberWithMaskValue: bankABANumberWithMaskValue,
                tempBankABANumberValue: _.get(paymentVM, 'value.bankABANumber')
            });
            _.set(paymentVM, 'bankABANumber', '');
            this.writeValue('', 'bankABANumberWithMask_Ext');
        }
        this.setState({ abaNumberVisible: true });
    }

    checkBankABANumberReset = () => {
        const paymentVM = this.getCurrentPaymentVM();
        const bankABANumberValue = _.get(paymentVM, 'value.bankABANumber');
        // rollback value if nothing changes
        if (_.isEmpty(bankABANumberValue)) {
            const {
                tempBankABANumberWithMaskValue, tempBankABANumberValue
            } = this.state;
            _.set(paymentVM, 'bankABANumber', tempBankABANumberValue);
            this.writeValue(tempBankABANumberWithMaskValue, 'bankABANumberWithMask_Ext');
            this.setState({ abaNumberVisible: false });
        }
    }

    updateABANumber = (value, path) => {
        const paymentVM = this.getCurrentPaymentVM();
        _.set(paymentVM, 'bankABANumber', value);
        _.set(paymentVM, 'bankABANumberWithMask_Ext', value);
        this.writeValue(value, path);
    }

    focusAbANumber = () => {
        if (document.getElementById('abaNumber')) {
            document.getElementById('abaNumber').focus();
        }
    }

    updateAccountNumber = (value, path) => {
        const paymentVM = this.getCurrentPaymentVM();
        _.set(paymentVM, 'bankAccountNumber', value);
        this.writeValue(value, path);
    }

    render() {
        const translator = this.context;
        const {
            paymentMethod, accountCreditCardVM, accountBankDetailVM,
            showValidationErrors,
            dataExist,
            abaNumberVisible
        } = this.state;
        const paymentVM = paymentMethod === 'wire' ? accountBankDetailVM : accountCreditCardVM;
        const {
            title, nextLabel, previousLabel, onCancel, isCustomFooter, isDisabled,
            setComponentValidation, isComponentValid, isSetupPayment, sysLocalDate
        } = this.props;
        const overrides = {
            '@field': {
                showOptional: false,
                showRequired: true
            },
            automaticPaymentMethod: {
                visible: isSetupPayment,
                value: translator(messages.bankAccountSource)
            },
            paymentOptions: {
                value: paymentMethod,
                labelPosition: 'left',
                visible: !isSetupPayment
            },
            bankAccountContainer: {
                visible: paymentMethod === 'wire'
            },
            creditCardContainer: {
                visible: paymentMethod === 'creditcard'
            },
            payNow: {
                onClick: this.payNowClicked,
                // content: translator(nextLabel) || translator(customMessages.setupAutoPayment),
                disabled: isDisabled || !isComponentValid
            },
            paymentOptionsTitle: {
                content: translator(title) || translator({
                    id: 'quoteandbind.views.payment-details.Payment Method',
                    defaultMessage: 'Payment Method'
                })
            },
            remove: {
                onClick: this.removeAutoPay,
                disabled: !dataExist
            },
            cancel: {
                content: translator(previousLabel) || translator({
                    id: 'gateway.views.new-quote.Cancel',
                    defaultMessage: 'Cancel'
                })
            },
            buttonContainer: {
                className: cx(PaymentStyles.paymentButtonFooter, {
                    [PaymentStyles.paymentCustomFooter]: isCustomFooter
                })
            },
            // effectiveDate_Ext: {
            //     value: moment(_.get(paymentVM.value, 'effectiveDate_Ext')).toDate(),
            //     minDate: sysLocalDate
            // },
            accountNumber: {
                visible: false
            },
            accountNumberWithMask: {
                onBlur: this.checkBankAccountNumberReset,
                onFocus: this.reomoveBankAccountEncrypt,
                onValueChange: this.updateAccountNumber,
                visible: true,
            },
            abaNumber: {
                onBlur: this.checkBankABANumberReset,
                onValueChange: this.updateABANumber,
                className: abaNumberVisible ? 'd-flex' : 'd-none'
            },
            abaNumberWithMask: {
                onFocus: this.removeBankABANumberEncrypt,
                onBlur: this.focusAbANumber,
                className: !abaNumberVisible ? 'd-flex' : 'd-none'
            }
        };

        const resolvers = {
            resolveClassNameMap: PaymentStyles,
            resolveCallbackMap: {
                handlePaymentOptions: this.handlePaymentOptions,
                onCancel: onCancel
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.componentContent, paymentVM, id, path, overrides);
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={paymentVM}
                showErrors={showValidationErrors}
                overrideProps={overrides}
                resolveValue={readValue}
                onValidationChange={setComponentValidation}
                onValueChange={this.writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

const PaymentComponent = withModalContext(PaymentComponentWithoutModalContext);

export default withValidation(withViewModelService(PaymentComponent));
