import messages from './PULossHistoryPage.messages'

export default {
    userClue: {
        title: 'Prior Loss Entry',
        labelMap: {
            incidentDate: { label: messages.puIncidentDate },
            policyDriverId: { label: messages.puDriver, driverOptionPath: 'drivers' },
            category: { label: messages.puCategory}, 
            status: { label: messages.puStatus, initialFiled: true, initialFilterValues: [{ code: 'Open', name: 'Open' }, { code: 'Closed', name: 'Closed' }, { code: 'Subrogated', name: 'Subrogated' }] },
            amountPaid: { label: messages.puAmountPaid },
            // chargeable: { label: messages.puSurchargeable, checkRatedStatus: true, disabled: true },
            // surChargeApply: { label: messages.puSurChargeApplied, checkRatedStatus: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.personalumbrella.dto.PUCLUEManuallyDTO'
    },
    vendorClue: {
        title: 'Prior Loss Reports',
        labelMap: {
            incidentDate: { unEditable: true, label: messages.puIncidentDate },
            category: {unEditable: true, label: messages.puCategory},
            driverName: { unEditable: true, label: messages.puClueDriver },
            policyDriverName: { unEditable: false, label: messages.puPolicyDriver, driverOptionPath: 'policydrivers' },
            status: { unEditable: true, label: messages.puCategoryStatus },
            amountPaid: { unEditable: true, label: messages.puAmountPaid },
            // chargeable: { unEditable: true, label: messages.puSurchargeable },
            // surChargeApply: { unEditable: true, label: messages.puSurChargeApplied },
            dispute: {
                unEditable: false,
                label: messages.puDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'disputeother' },
                tooltip: { text: messages.puMVRDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.puExplanation, unVisible: true },
            underwritingResponse: { unEditable: true, label: messages.puUnderWritingResponse },
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.personalumbrella.dto.PUCLUEReportDTO'
    },
    userMvr: {
        title: 'MVR Entry',
        labelMap: {
            incidentDate: { label: messages.puIncidentDate },
            driverId: { label: messages.puMVRDriverName, driverOptionPath: 'drivers' },
            description: { label: messages.puDescription, filterVal: 'AccidentFromMVR', },
            descriptionForChips: {label: messages.puDescription, disabled: true },
            source: { label: messages.puSource, hidden: true },
            // chargeable: { label: messages.puSurchargeable, checkRatedStatus: true, disabled: true },
            // chargeableApply: { label: messages.puSurChargeApplied, checkRatedStatus: true, disabled: true }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.personalumbrella.dto.PUMVRManuallyDTO'
    },
    vendorMvr: {
        title: 'MVR Reports',
        labelMap: {
            incidentDate: { unEditable: true, label: messages.puIncidentDate },
            driverName: { unEditable: true, label: messages.puMVRReportDriverName },
            // description: { unEditable: true, label: messages.puDescription },
            // chargeable: { unEditable: true, label: messages.puSurchargeable },
            // surChargeApply: { unEditable: true, label: messages.puSurChargeApplied },
            dispute: {
                unEditable: false,
                label: messages.puDispute,
                linkage: { linkPath: 'explanationBox', linkCode: 'Other' },
                tooltip: { placement: 'left', text: messages.puMVRDisputeTooltip }
            },
            explanationBox: { unEditable: false, label: messages.puExplanation, unVisible: true },
            underwritingResponse_Ext: { unEditable: true, label: messages.puUnderWritingResponse }
        },
        dtoPath: 'wni.edge.capabilities.quote.lob.personalumbrella.dto.PUMVRReportDTO'
    }
}