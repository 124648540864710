import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('dpMultiQuote'), method, data, additionalHeaders);
}

export default class DPMultiQuoteService {
    // /**
    //  * Invokes HOAdditionalInterestsHandler.methodName()
    //  * is disabled.
    //  * @param {object} data the QuoteDataDTO json obj
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static methodName(data, authHeader = {}) {
    //     return processSubmission('methodName', [data], authHeader);
    // }

    /**
     * Invokes DPMultiQuoteHandler.processQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static processQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('processQuote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteHandler.finishQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static finishQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('finishQuote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes DPMultiQuoteHandler.createDuplicateQuote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDuplicateQuote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDuplicateQuote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes DPMultiQuoteHandler.createDP1Quote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDP1Quote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDP1Quote', [quoteID, sessionUUID], authHeader);
    }



    /**
     * Invokes DPMultiQuoteHandler.createDP2Quote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDP2Quote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDP2Quote', [quoteID, sessionUUID], authHeader);
    }


    /**
     * Invokes DPMultiQuoteHandler.createDP3Quote()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static createDP3Quote(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('createDP3Quote', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteHandler.withdrawBranch()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} periodPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static withdrawBranch(quoteID, sessionUUID, periodPublicID, authHeader = {}) {
        return processSubmission('withdrawBranch', [quoteID, sessionUUID, periodPublicID], authHeader);
    }

    /**
     * Invokes DPMultiQuoteHandler.updateBranchName()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} branchName
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBranchName(quoteID, sessionUUID, branchName, authHeader = {}) {
        return processSubmission('updateBranchName', [quoteID, sessionUUID, branchName], authHeader);
    }

    /**
     * Invokes DPMultiQuoteHandler.callVirtualInspectionReportAndCreateNote()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} note
     * @param {Object} authHeader 
     * @returns {JobDTO}
     */
    static callVirtualInspectionReportAndCreateNote(jobID, sessionUUID, note, authHeader = {}) {
        return processSubmission('callVirtualInspectionReportAndCreateNote', [jobID, sessionUUID, note], authHeader);
    }


    // // ======================================

    // /**
    //  * Invokes DPMultiQuoteHandler.retrieveQuotePeriodData()
    //  * 
    //  * @param {string} quoteID 
    //  * @param {string} sessionUUID 
    //  * @param {Object} authHeader 
    //  * @returns {Promise}
    //  */
    // static retrieveQuotePeriodData(quoteID, sessionUUID, authHeader = {}) {
    //     return processSubmission('retrieveQuotePeriodData', [quoteID, sessionUUID], authHeader);
    // }
}
