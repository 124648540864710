import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
// import { WniSxsQuoteService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
// import { WniSubmissionService } from 'wni-capability-gateway';
// import {
//     IncidentsUtil,
//     ErrorsAndWarningsUtil,
//     PaymentUtil,
//     DocumentsUtil,
//     QuoteUtil,
//     IssuanceValidationUtil,
// } from 'wni-portals-util-js';
// import { WizardConstants } from 'wni-portals-config-js';
// import { SideBySidePeriodsComponent } from 'wni-capability-gateway-react';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import WizardPage from '../../templates/IMWizardPage';

// import PAQuoteUIHelper from './util/PAQuoteUIHelper';
import styles from './IMDummyPage.module.scss';
import metadata from './IMDummyPage.metadata.json5';
import messages from './IMDummyPage.messages';

function IMDummyReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        wizardPageData,
        updateWizardPageData,
        steps: wizardSteps,
        wizardStepToFieldMapping,
        history,
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);

    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const [sxsDataDTO, updatesxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    // const [paymentViewType, updatePaymentViewType] = useState(QuoteUtil.PAYMENT_PLAN_TYPES.monthly);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    // useEffect(() => {
    // }, []);


    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, isServiceCallInProgress, errorsAndWarnings, sxsDataDTO,
        translator, extraBlockingSubmitIssues]);

    // ===================================================
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />

        </WizardPage>
    );
}

IMDummyReadOnlyPage.propTypes = WizardPage.propTypes;
export default IMDummyReadOnlyPage;