import _ from 'lodash';
// import { DebugUtil } from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import FeatureRenderManager from '../FeatureToggle/FeatureRenderManager';


function getFeatureToggleCodeMap(featureToggleDTOArray) {
    const retval = {};
    if (featureToggleDTOArray) {
        featureToggleDTOArray.forEach((elt) => {
            // elt.code: typekey.FeatureToggle_Ext
            retval[elt.code] = elt.enabled;
        });
    }
    return retval;
}

function useFeatureToggle() {

    // const authData = useAuthentication();
    const {
        authHeader,
        authUserData: {
            businessData_Ext: {
                featureToggles = [],
            },
        }
        
    } = useAuthentication();

    const featureToggleCodeMap = getFeatureToggleCodeMap(featureToggles);

    
    /**
     * Call the render func defined FeatureRenderConfig.On.js when 
     * the Feature specified by <code>>featureCode</code> is enabled
     * 
     * @param {String} featureCode
     * @param {objecrt} renderParams
     * @returns {ReactElement}
     */
    const renderFeature = (featureCode, ...renderParams) => {
        if(!featureCode) {
            return null;
        }
        const isFeatureEnabled = featureToggleCodeMap[featureCode];

        // Feature status undecided
        if (_.isNil(isFeatureEnabled)) {
            return null;
        }

        const retval = FeatureRenderManager.renderFeatureContent(featureCode, isFeatureEnabled,
            ...renderParams);
        return retval;
    };

    /**
     * When Feature specified by <code>featureCode</code> is enabled,
     * call the renderFunc.
     * 
     * @param {String} featureCode 
     * @param {Function} renderFunc 
     * @param {Function} renderDisabledFunc (Optional)
     * @returns {ReactElement}
     */
    const renderWhenEnabled  = (featureCode, renderFunc, renderDisabledFunc = undefined) => {
        if(!featureCode) {
            return null;
        }
        const isFeatureEnabled = featureToggleCodeMap[featureCode];

        let renderContent = null;

        if (isFeatureEnabled) {
            renderContent = renderFunc();
        } else if (renderDisabledFunc) {
            renderContent = renderDisabledFunc();
        }

        return renderContent;
    }

    const getFeatureCodeValue = (featureCode) => {
        if (!featureCode) {
            return null;
        }
        return featureToggleCodeMap[featureCode];
    }


    return {
        renderFeature,
        //
        renderWhenEnabled,
        // 
        getFeatureCodeValue
    };

}

export default useFeatureToggle;
