import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cppPolicyDetailsChange'), method, data, additionalHeaders);
}

export default class CPPPolicyDetailsChangeService {

    static saveCPPDetailsChangeData(quoteID, sessionUUID, quoteBaseData, authHeader) {
        return processSubmission('saveCPPDetailsChangeData', [quoteID, sessionUUID, quoteBaseData], authHeader);
    }

}