import { defineMessages } from 'react-intl';

export default defineMessages({
    paTogglePricingView: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Toggle Pricing View',
        defaultMessage: 'Toggle Pricing View',
    },
    resolveUWIssuesMsg: {
        id: 'custom.quoteandbind.common.quote-clause-table.Please resolve UW issues before proceed',
        defaultMessage: 'Please resolve UW issues before proceed',
    },
    policyType: {
        id: 'custom.quoteandbind.common.quote-clause-table.Policy Type',
        defaultMessage: 'Policy Type',
    },
    withThePenaltyFreePromise: {
        id: 'custom.quoteandbind.common.quote-clause-table.with The Penalty-Free Promise®',
        defaultMessage: 'with The Penalty-Free Promise®',
    },
    withPayFullDiscount: {
        id: 'custom.quoteandbind.common.quote-clause-table.( With Full Pay discount )',
        defaultMessage: '( With Full Pay discount )',
    },
    withoutPayFullDiscount: {
        id: 'custom.quoteandbind.common.quote-clause-table.( Without Full Pay discount )',
        defaultMessage: '( Without Full Pay discount )',
    },
    IssuesRequiringReferral: {
        id: 'custom.quoteandbind.common.quote-clause-table.issues Requiring Referral:',
        defaultMessage: 'Issues Requiring Referral:',
    },
    continueWithQuote: {
        id: 'custom.quoteandbind.common.quote-clause-table.Continue with Quote',
        defaultMessage: 'Continue with Quote',
    },
    continueToQuote: {
        id: 'custom.quoteandbind.common.quote-clause-table.Continue to Quote',
        defaultMessage: 'Continue to Quote',
    },
    runFinalValidation: {
        id: 'custom.quoteandbind.common.quote-clause-table.Run Validation',
        defaultMessage: 'Run Validation',
    },
    referToUnderwriter: {
        id: 'custom.quoteandbind.common.quote-clause-table.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter',
    },
    continueToIssue: {
        id: 'custom.quoteandbind.common.quote-clause-table.Continue to Issue',
        defaultMessage: 'Continue to Issue',
    },
    getUpdatedRate: {
        id: 'custom.quoteandbind.common.quote-clause-table.Get Updated Rate',
        defaultMessage: 'Get Updated Rate',
    },
    reviewLoss: {
        id: 'custom.quoteandbind.common.quote-clause-table.Review Losses',
        defaultMessage: 'Review Losses',
    },
    reviewViolation: {
        id: 'custom.quoteandbind.common.quote-clause-table.Review Violations',
        defaultMessage: 'Review Violations',
    },
    reviewViolationLoss: {
        id: 'custom.quoteandbind.common.quote-clause-table.Review Violations & Losses',
        defaultMessage: 'Review Violations & Losses',
    },
    clearMvrAndClue: {
        id: 'custom.quoteandbind.common.quote-clause-table.Clear MVR(s) & CLUE',
        defaultMessage: 'Clear MVR(s) & CLUE',
    },
    RiskReservation: {
        id: 'custom.quoteandbind.common.quote-clause-table.Risk Reservation',
        defaultMessage: 'Risk Reservation'
    },
    deleteSideBySidePairBtn: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Delete this Quote Version',
        defaultMessage: 'Delete this Quote Version'
    },
    editQuoteVersionName: {
        id: 'wni.platform.translations.Quote.SideBySide.Edit Quote Version Name',
        defaultMessage: 'Edit Quote Version Name',
    },
    runLossReport: {
        id: 'custom.quoteandbind.common.quote-clause-table.Finalize Eligibility / Run Loss Report',
        defaultMessage: 'Finalize Eligibility / Run Loss Report',
    },
    //
    doNotProceedSaveAndExit: {
        id: 'wni.platform.translations.Quote.SideBySide.Do Not Proceed - Save and Exit',
        defaultMessage: 'Do Not Proceed - Save and Exit'
    },
    proceedToSubmission: {
        id: 'wni.platform.translations.Quote.SideBySide.Proceed to Submission',
        defaultMessage: 'Proceed to Submission'
    },
    //
    quotePending: {
        id: 'wni.platform.translations.Quote.SideBySide.Quote Pending',
        defaultMessage: 'Quote Pending',
    },
    issuePolicy: {
        id: 'custom.quoteandbind.common.quote-clause-table.Issue Policy',
        defaultMessage: 'Issue Policy',
    },
    finalizeQuote: {
        id: 'custom.quoteandbind.common.quote-clause-table.Finalize Quote',
        defaultMessage: 'Finalize Quote',
    },
    returnToQuote: {
        id: 'custom.quoteandbind.common.quote-clause-table.Return to Quote',
        defaultMessage: 'Return to Quote',
    },
});
