import React from 'react';
import WizardPage from '../../templates/GLWizardPage';
import GLLocationsPage from './GLLocationsPage';

function GLLocationsReadOnlyPage(props) {
    return (
        <GLLocationsPage {...props} isReadOnly/>
    );
}

GLLocationsReadOnlyPage.propTypes = WizardPage.propTypes;
export default GLLocationsReadOnlyPage;
