import _ from 'lodash';

function WTClaimExtension(claim) {
    Object.defineProperty(this, 'claim', {
        enumerable: false,
        value: claim
    });
    if (claim && claim.lobs && claim.lobs.watercraft) {
        _.extend(this, claim.lobs.watercraft);

        this.contacts = this.claim.contacts || [];

        _.each(this.claim.lobs.watercraft.vehicleIncidents, _.bind((vi) => {
            const watercraft = vi;
            watercraft.driver = this.claim.findContact(this.claim, watercraft.driver);
            _.each(watercraft.passengers, _.bind((p, index) => {
                watercraft.passengers[index] = this.claim.findContact(this.claim, p);
            }, this));
            // watercraft.watercraft = this.findVehicle(watercraft.watercraft);
        }, this));
    }
}

export default WTClaimExtension
