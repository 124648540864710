import { defineMessages } from 'react-intl';

export default defineMessages({
    new: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.householdlocationpopup.New',
        defaultMessage: 'New'
    },
    location: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.householdlocationpopup.Location',
        defaultMessage: 'Locations'
    },
    message0246: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.householdlocationpopup.Garaging Address is required',
        defaultMessage: 'Garaging Address is required'
    },
    message0247: {
        id: 'wni.quoteandbind.ho-wizard.ho-householdinfo.householdlocationpopup.Add garaging location',
        defaultMessage: 'Garaging address cannot be a PO Box address - Add garaging location'
    },
});
