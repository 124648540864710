import { defineMessages } from 'react-intl';

export default defineMessages({
    handlePreemptionMessagePart1: {
        id: 'customer.pa.wizard.step.paysuccess.Handle Preemption Message Part1',
        defaultMessage: 'The changes made on this endorsement will automatically apply to change request #'
    },
    handlePreemptionMessagePart2: {
        id: 'customer.pa.wizard.step.paysuccess.Handle Preemption Message Part2',
        defaultMessage: '. You can '
    },
    handlePreemptionMessageDeleteJobLink: {
        id: 'customer.pa.wizard.step.paysuccess.Handle Preemption Message Delete Job Link',
        defaultMessage: 'delete'
    },
    handlePreemptionMessagePart3: {
        id: 'customer.pa.wizard.step.paysuccess.Handle Preemption Message Part3',
        defaultMessage: ' {jobID} if the change request is no longer needed.'
    },
    deleteQuote: {
        id: 'custom.gateway.directives.JobSummary.Delete Quote?',
        defaultMessage: 'Delete Quote?'
    },
    sureDeletePolicyChange: {
        id: 'gw-portals-policytransaction-pc-pa.gateway.views.endorsement.Are you sure you want to delete this policy transaction? All changes will be lost.',
        defaultMessage: 'Are you sure you want to delete this policy transaction? All changes will be lost.'
    },
    driverDetails: {
        id: 'customer.pa.policychange.views.pa-confirmation.Driver Details',
        defaultMessage: 'Driver Details'
    },
    contactInformationDetails: {
        id: 'customer.pa.policychange.views.pa-confirmation.Contact Information Details',
        defaultMessage: 'Contact Information Details'
    },
    changeSuccess: {
        id: 'customer.pa.policychange.views.confirmation.gateway.This policy has been changed',
        defaultMessage: 'This policy has been changed.'
    },
});
