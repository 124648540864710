

import React from 'react';
import { IMMotorTruckCargoReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMMotorTruckCargoChangeReadOnlyPage = (props) => {
    return <IMMotorTruckCargoReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMMotorTruckCargoChangeReadOnlyPage
