import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { QuoteUtil, ErrorsAndWarningsUtil } from 'wni-portals-util-js';
import {
    QuoteUnderwritingIssuesList,
    EligibilityIssuesLinkComponent,
    ValidationIssuesComponent
} from 'wni-components-platform-react';
import SideBySidePeriodsUtil from './SideBySidePeriodsUtil';
import AccountClearanceLinkComponent from './QuoteAccountClearance/AccountClearanceLinkComponent';
import RecommendCoveragesComponent from './RecommendCoverages/RecommendCoveragesComponent';
import ReferToUnderwriterComponent from '../components/ReferToUnderwriterComponent/ReferToUnderwriterComponent';
import NoteUWInputComponent from '../components/NoteUWInputComponent/NoteUWInputComponent';
import { SxsTableRowProfiles } from './SideBySidePeriodsComponent.static';
import metadata from './SideBySidePeriodsComponentV2.metadata.json5';

/**
 * Default table rows to display
 */
const INITIAL_TABLE_ROWS = SxsTableRowProfiles.DefaultRows;
// const INITIAL_TABLE_ROWS = [
//     { rowId: 'policyType' },
//     { rowId: 'branchName' },
//     { rowId: 'withThePenaltyFreePromise' },
//     { rowId: 'payTotal' },
//     { rowId: 'premiumFullDiscount' },
//     { rowId: 'underwritingIssues' },
//     { rowId: 'eligibilityIssuesLink' },
//     { rowId: 'accountClearanceLink' },
//     { rowId: 'recommendedCoverages' },
//     { rowId: 'submitAndDeleteActions' },
//     { rowId: 'compareReviewViolationLink' },
//     { rowId: 'compareReviewLossLink' },
//     { rowId: 'compareReviewAllLink' },
//     { rowId: 'clearMvrClueLink' },
// ];

function defaultSxsPairRemovalCheckFn(sxsPeriods, sideBySidePairs) {
    const retval = QuoteUtil.checkReadyForSideBySidePairRemoval(sideBySidePairs);
    return retval;
}


function SideBySidePeriodsComponentV2(props) {
    const {
        quoteID,
        columnData,
        onRemoveSideBySidePair,
        isServiceCallInProgress: isLoading,
        isReadOnly,
        showActionButtons,
        readyForSxsRemovalCheckFn,
        // sxsPeriodInfoRowFilter,
        sxsTableRows,
    } = props;
    const translator = useTranslator();
    const sxsPeriods = columnData.map(({ sxsPeriod }) => sxsPeriod.data);
    const sideBySidePairs = QuoteUtil.getSideBySidePairs(sxsPeriods);
    const isReadyForRemoval = !isReadOnly && readyForSxsRemovalCheckFn(sxsPeriods, sideBySidePairs);

    const dataForComponent = {
        columnData,
        sideBySidePairs,
    };
    const commonHelper = SideBySidePeriodsUtil({
        translator,
        isLoading,
        isReadyForRemoval,
        onRemoveSideBySidePair
    });
    // let sxsTableRows = INITIAL_TABLE_ROWS;
    // if (sxsPeriodInfoRowFilter && _.isFunction(sxsPeriodInfoRowFilter)) {
    //     sxsTableRows = sxsTableRows.filter(sxsPeriodInfoRowFilter);
    // }

    const overrideProps = {
        sxsVersionTable: {
            data: sxsTableRows,
            content: commonHelper.getTableColumnContent(columnData)
        },
        sxsVersionActionTable: {
            visible: showActionButtons,
            data: [
                { rowId: 'removeSxsPairBtn' }
            ],
            content: commonHelper.getTableColumnContent(sideBySidePairs)
        },
        noteForUWInputInfo: {
            quoteID
        },
    };

    const resolvers = {
        resolveComponentMap: {
            noteuwinputinfo: NoteUWInputComponent,
            quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            refertouw: ReferToUnderwriterComponent,
            eligibilitydetails: EligibilityIssuesLinkComponent,
            accountclearancelink: AccountClearanceLinkComponent,
            recommendedCoverages: RecommendCoveragesComponent,
            validationissuescomponent: ValidationIssuesComponent,
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dataForComponent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

SideBySidePeriodsComponentV2.propTypes = {
    quoteID: PropTypes.string.isRequired,
    columnData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.number,
        quote: PropTypes.shape({
            path: PropTypes.string,
            data: PropTypes.shape({
                publicID: PropTypes.string,
                pairPeriodPublicId_Ext: PropTypes.string
            })
        })
    })).isRequired,
    onRemoveSideBySidePair: PropTypes.func,
    isServiceCallInProgress: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    showActionButtons: PropTypes.bool,
    readyForSxsRemovalCheckFn: PropTypes.func,
    // sxsPeriodInfoRowFilter: PropTypes.func,
    sxsTableRows: PropTypes.arrayOf(PropTypes.shape({
        rowId: PropTypes.string,
    })),
};

SideBySidePeriodsComponentV2.defaultProps = {
    onRemoveSideBySidePair: _.noop,
    isServiceCallInProgress: false,
    isReadOnly: false,
    showActionButtons: true,
    readyForSxsRemovalCheckFn: defaultSxsPairRemovalCheckFn,
    // sxsPeriodInfoRowFilter: undefined,
    sxsTableRows: INITIAL_TABLE_ROWS,
};

export default SideBySidePeriodsComponentV2;
