import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniSubmission'), method, data, additionalHeaders);
}

export default class WniSubmissionService {
    static getPortalDocumentsForSubmission(params, additionalHeaders) {
        return processSubmission('getPortalDocumentsForSubmission', params, additionalHeaders);
    }

    static getSideBySideQuoteSummaryDocument(params, additionalHeaders) {
        return processSubmission('getSideBySideQuoteSummaryDocument', params, additionalHeaders);
    }

    static getSystemDate(additionalHeaders) {
        return processSubmission('getSystemDate', [], additionalHeaders);
    }

    static getPortalDocumentsForSubmissionGroupByType(params, additionalHeaders) {
        return processSubmission('getPortalDocumentsForSubmissionGroupByType', params, additionalHeaders);
    }

    static getPortalAttachmentsForSubmission(params, additionalHeaders) {
        return processSubmission('getPortalAttachmentsForSubmission', params, additionalHeaders);
    }

    static getPortalDocumentsForMultiSubmissionGroupByType(params, additionalHeaders) {
        return processSubmission('getPortalDocumentsForMultiSubmissionGroupByType', params, additionalHeaders);
    }
    
    static getPortalCLDocumentsForMultiSubmissionGroupByType(params, additionalHeaders) {
        return processSubmission('getPortalCLDocumentsForMultiSubmissionGroupByType', params, additionalHeaders);
    }

    static getPortalCLDocumentsForSubmissionGroupByType(params, additionalHeaders) {
        return processSubmission('getPortalCLDocumentsForSubmissionGroupByType', params, additionalHeaders);
    }

    /**
     * Function signature copied from SbumsisionService.referToUnderwriter()
     * 
     * Invokes WniSubmissionHandler.referToUnderwriter()
     * 
     * @param {string} jobNumber
     * @param {string} noteBody
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static referToUnderwriter(jobNumber, noteBody, additionalHeaders = {}) {
        return processSubmission('referToUnderwriter', [jobNumber, noteBody], additionalHeaders);
    }

    static getOnDemandQuoteSummaryDocument(params, additionalHeaders) {
        return processSubmission('getOnDemandQuoteSummaryDocument', params, additionalHeaders);
    }

    static addRecentlyViewedSubmission(jobNumber, additionalHeaders) {
        return processSubmission('addRecentlyViewedSubmission', [jobNumber], additionalHeaders);
    }

    static getDocumentDocUIDs(publicIDs, additionalHeaders) {
        return processSubmission('getDocumentDocUIDs', [publicIDs], additionalHeaders);
    }

    /**
     * Invokes WniSubmissionHandler.copySubmission()
     * 
     * @param {object} jobID
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static copySubmission(jobID, additionalHeaders = {}) {
        return processSubmission('copySubmission', [jobID], additionalHeaders);
    }

    /**
     * Invokes WniSubmissionHandler.copyPASubmission2HO()
     * 
     * @param {object} jobID
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static copyPASubmissionToHO(jobID, additionalHeaders = {}) {
        return processSubmission('copyPASubmissionToHO', [jobID], additionalHeaders);
    }

    /**
     * Invokes WniSubmissionHandler.copySubmissionByWay()
     * 
     * @param {string} jobID
     * @param {string} way
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static copySubmissionByWay(jobID, way, additionalHeaders = {}) {
        return processSubmission('copySubmissionByWay', [jobID, way], additionalHeaders);
    }

    /**
     * 
     * @param {string} accountNumber 
     * @param {object} additionalHeaders 
     * @returns {Promise}
     */
    static getLastPAPolicyOrSubmissionJobNumber(accountNumber, additionalHeaders = {}) {
        return processSubmission('getLastPAPolicyOrSubmissionJobNumber', [accountNumber], additionalHeaders);
    }

    /**
     * get the documents for given jobNumber
     * 
     * @param {*} jobNumber 
     * @param {*} isCL 
     * @param {*} additionalHeaders 
     * @returns 
     */
    static getAllPortalDocumentsForSubmission(jobNumber, isCL, additionalHeaders) {
        return processSubmission('getAllPortalDocumentsForSubmission', [jobNumber, isCL], additionalHeaders);
    }
    
    /**
     * get documents and attachments for given jobNumber
     * 
     * @param {*} jobNumber 
     * @param {*} additionalHeaders 
     * @returns 
     */
    static getDocsAndAttachsForJob(params, additionalHeaders) {
        return processSubmission('getDocsAndAttachsForJob', params, additionalHeaders);
    }

}
