import _ from 'lodash';
import { PaymentPlanConfig, PortalConstants } from 'wni-portals-config-js';
import { ConfigUtil } from 'wni-portals-util-js';


const {
    PRICE_VIEW_MODES: PAYMENT_PLAN_TYPES
} = PortalConstants;

const {
    paymentMethodData,
    payUsing,
    fullPayStr,
    PAYMENT_PLANS_BASIC: PAYMENT_PLANS,
    TERM_TYPE_TO_PAYMENT_PLAN_MAP,
} = PaymentPlanConfig;

const aoURL = ConfigUtil.getBrandedAOUrlConfig();

/**
 * Get current selected paymentPlan ID based on existing <code>selectedPaymentPlanID</code>
 * and <code>paymentPlans</code>, or default to the Full Pay Plan.
 *
 * Note: the return object is of type PaymentPlanDTO
 * @param {string} selectedPaymentPlanID
 * @param {array} paymentPlans
 * @returns {object} an instance of PaymentPlanDTO, default to Full Pay
 */
function getSelectedPaymentPlan(selectedPaymentPlanID, paymentPlans) {
    let retval = null;
    if (selectedPaymentPlanID) {
        retval = paymentPlans.find((plan) => plan.billingId === selectedPaymentPlanID);
    }
    if (!retval) {
        retval = paymentPlans.find((plan) => plan.name === fullPayStr);
    }
    return retval;
}

/**
 * Same with getSelectedPaymentPlan(), while returns paymentPlanID
 * instead of the PaymentPlanDTO object
 * @param {string} selectedPaymentPlan
 * @param {array} paymentPlans
 * @returns {string} the payment plan ID
 */
function getSelectedPaymentPlanID(selectedPaymentPlan, paymentPlans) {
    const paymentPlan = getSelectedPaymentPlan(selectedPaymentPlan, paymentPlans);
    return paymentPlan && paymentPlan.billingId;
}


/**
 * Get payment plan id based on provided info
 * @param {string} termType typekey.TermType.code, i.e. "Annual" or "HalfYear"
 * @param {string} paymentMethod "Monthly" or "Yearly"
 * @param {array} paymentPlans PaymentPlanDTO[]
 * @returns {object} the PaymentPlanDTO object, or null
 */
function getPaymentPlanByTerm(termType, paymentMethod, paymentPlans) {
    if (!termType || !paymentMethod || _.isEmpty(paymentPlans)) {
        return null;
    }
    const expectedPlanName = TERM_TYPE_TO_PAYMENT_PLAN_MAP[termType][paymentMethod];
    const paymentPlan = paymentPlans.find((plan) => expectedPlanName === plan.name);
    return paymentPlan;
}

/**
 * Same with getPaymentPlanByTerm(), with the PaymentPlan ID returned.
 * @param {string} termType
 * @param {string} paymentMethod
 * @param {array} paymentPlans
 * @returns {string} the PaymentPlan ID
 */
function getPaymentPlanIDByTerm(termType, paymentMethod, paymentPlans) {
    const paymentPlan = getPaymentPlanByTerm(termType, paymentMethod, paymentPlans);
    return paymentPlan && paymentPlan.billingId;
}


/**
 * Get payment method ('annually' or 'monthly') based on selected payment plan
 * @param {object | string} selectedPaymentPlan the PaymentPlanDTO or PaymentPlan ID
 * @param {array} paymentPlans
 * @returns {string} payment method
 */
function getPaymentPlanType(selectedPaymentPlan, paymentPlans) {
    let retval = PAYMENT_PLAN_TYPES.monthly;

    const selectedPaymentPlanID = _.isString(selectedPaymentPlan) ? selectedPaymentPlan : _.get(selectedPaymentPlan, 'billingId');
    if (!selectedPaymentPlanID || _.isEmpty(paymentPlans)) {
        return retval;
    }
    const paymentPlan = paymentPlans.find((plan) => plan.billingId === selectedPaymentPlanID);
    if (!paymentPlan) {
        return retval;
    }

    const { name: planName = '' } = paymentPlan;
    if (planName.startsWith(PAYMENT_PLANS.FULL_PAY)) {
        retval = PAYMENT_PLAN_TYPES.annually;
    }
    return retval;
}

/**
 * Checks whether the payment plan type is monthly.
 * @param {string} paymentPlanType
 * @returns {boolean}
 */
function isMonthlyPaymentPlan(paymentPlanType) {
    return paymentPlanType === PAYMENT_PLAN_TYPES.monthly;
}

/**
 * Get the Month Payment total cost
 * @deprecated To be removed, 2021-04-15
 * @param {array} installmentPlans list of PaymentPlanDTO[]
 * @returns {object} instalce of AmountDTO, i.e. with amount and currency
 */
function getMonthlyPaymentTotalCost(installmentPlans = []) {
    const retval = installmentPlans.find((plan) => plan.name.startsWith('Monthly PL'));
    return retval && retval.total;
}

const UWISSUE_MAP = {
    PASubmissionPastEffectiveDate_Ext: 'The current effective date is outside of your binding authority. Contact your underwriter for approval or update the effective date to within 5 days prior to today’s date.'
};


const AGENCY_SWEEP_AVAILABLE_OPTIONS = [
    {
        code: 'AgencySweep',
        name: {
            id: 'quoteandbind.views.payment-details.Down Payment.Agency Sweep',
            defaultMessage: 'Agency Sweep',
        },
    },
    {
        code: 'Electronic',
        name: {
            id: 'quoteandbind.views.payment-details.Down Payment.Electronic',
            defaultMessage: 'Electronic',
        },
    },
];

const AGENCY_SWEEP_UNAVAILABLE_OPTIONS = [
    {
        code: 'Electronic',
        name: {
            id: 'quoteandbind.views.payment-details.Down Payment.Electronic',
            defaultMessage: 'Electronic',
        },
    },
];

function updatePrimaryPayerOptions(contacts, primaryPayerOptions) {
    _.forEach(contacts, (contact) => {
        primaryPayerOptions.push({
            code: JSON.stringify(contact),
            name: _.get(contact, 'displayName')
        });
    });
    return primaryPayerOptions;
}


function updateBillingPrimaryPayerOptions(contacts, primaryPayerOptions) {
    _.forEach(contacts, (contact) => {
        primaryPayerOptions.push({
            code: _.get(contact, 'displayName'),
            name: _.get(contact, 'displayName')
        });
    });
    return primaryPayerOptions;
}

function initPaymentMethods(payInstrucments, viewModelService) {
    const paymentMethods = [];
    _.forEach(payInstrucments, (bank) => {
        const bankAccountType = _.get(bank, 'bankAccountType_Ext') === 'checking_Ext'
            ? 'checking' : 'savings';
        const paymentDetails = {
            paymentMethod: 'autopay_Ext',
            bankAccountData: {
                bankABANumber: _.get(bank, 'routingNumber_Ext'),
                bankAccountNumber: _.get(bank, 'accountNumber_Ext'),
                bankAccountType: bankAccountType,
                bankName: _.get(bank, 'bankName_Ext'),
                notes_Ext: _.get(bank, 'Note_Ext'),
                publicID: _.get(bank, 'publicID_Ext')
            }
        };
        const node = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
        paymentMethods.push(node);
    });
    return paymentMethods;
}

function initDayOfMonth(offeredQuotes, chosenQuote, dueDateExt, DateUtil) {
    let days = 31;
    if (offeredQuotes && chosenQuote) {
        if (!_.isNil(dueDateExt)) {
            const dueDate = new Date(dueDateExt);
            const year = DateUtil.getYear(dueDate);
            const month = DateUtil.getMonth(dueDate);
            days = new Date(year, month + 1, 0).getDate();
        }
    }
    // reset days to default if days is null or below zero
    if (_.isNil(days) || _.isNaN(days) || days < 0) {
        days = 31;
    }
    return [...Array(days).keys()].map((i) => ({
        code: i + 1,
        name: i + 1,
    }));
}

function getAccountNumberForBCC(accountNumber) {
    let length = _.get(accountNumber, 'length');
    let accountNumberForBCC = accountNumber;
    while (length < 12) {
        accountNumberForBCC = `0${accountNumberForBCC}`;
        length += 1;
    }
    return accountNumberForBCC;
}

function addPaymentMethodOptions(data, paymentMethodList) {
    if (paymentMethodList.length > 0) {
        paymentMethodList.forEach((vm) => {
            // last 4 digits of bankAccountNumber
            let digits = _.get(vm, 'value.bankAccountData.bankAccountNumber');
            if (digits) {
                digits = digits.substr(-4);
            }
            data.push({
                code: _.get(vm, 'value.bankAccountData.publicID'),
                name: `Bank Account - ${digits}`,
            });
        });
    }
    return data;
}

function getDefaultBasicRequestParam(submissionVM, amtDue, baseCallBackUrl, isMultiAndNew = false) {
    const middleNumber = _.get(submissionVM, 'value.quoteID', '');
    let referenceNumber;
    if (isMultiAndNew) {
        referenceNumber = `4${middleNumber}000001`;
    } else if (middleNumber.length > 10) {
        referenceNumber = `4${middleNumber}000`;
    } else {
        referenceNumber = `4${middleNumber}000000`;
    }
    const basicParam = {
        FromSystem: 'bcc-ecc',
        ActionCode: 'EXPAYCC',
        brand: 'WNI',
        AccountNumber: getAccountNumberForBCC(_.get(submissionVM, 'value.baseData.accountNumber')),
        RemoteUserId: _.get(submissionVM, 'value.baseData.accountHolder.displayName'),
        firstName: _.get(submissionVM, 'value.baseData.accountHolder.firstName'),
        lastName: _.get(submissionVM, 'value.baseData.accountHolder.lastName'),
        address1: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.addressLine1')
            || _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.pobox_Ext'),
        city: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.city'),
        state: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.state'),
        postal: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.postalCode'),
        amtDue: amtDue,
        reference: referenceNumber,
        surl: `${baseCallBackUrl}/success`,
        curl: `${baseCallBackUrl}/cancel`
    };
    return basicParam;
}

function getToUpdateRequestParam(basicParam, payDate, bankValue) {
    const toUpdate = {
        ...basicParam,
        ActionCode: 'EXPAYACH',
        payDate: payDate,
        bankingAccountNumber: _.get(bankValue, 'bankAccountData.bankAccountNumber'),
        bankingAccountType: _.get(bankValue, 'bankAccountData.bankAccountType') === 'checking' ? 'Chkng' : 'Svgs',
        routingNum: _.get(bankValue, 'bankAccountData.bankABANumber'),
        nameOnAccount: _.get(bankValue, 'bankAccountData.bankName')
    };
    return toUpdate;
}

function getOneTimePaymentPublicID(updatedVM) {
    const publicID = `${_.get(updatedVM, 'value.bankAccountData.bankAccountNumber')}`
    + `${_.get(updatedVM, 'value.bankAccountData.bankAccountType')}`
    + `${_.get(updatedVM, 'value.bankAccountData.bankABANumber')}`
    + `${_.get(updatedVM, 'value.bankAccountData.bankName')}`;
    return publicID;
}

function trnasformDate(dueDateExt, DateUtil) {
    const dueDate = new Date(dueDateExt);
    const year = DateUtil.getYear(dueDate);
    const month = DateUtil.getMonth(dueDate);
    const days = new Date(year, month + 1, 0).getDate();
    return {
        dueDate, year, month, days
    };
}

function findSelectedPaymentMethod(value, paymentMethodVMs) {
    const result = _.find(paymentMethodVMs, (v) => _.get(v, 'value.bankAccountData.publicID') === value);
    return result;
}

function checkPaymentMethodExist(updatedVM, paymentMethodsVMList) {
    const result = _.find(paymentMethodsVMList, (value) => {
        return _.get(value, 'value.bankAccountData.bankAccountNumber')
            === _.get(updatedVM, 'value.bankAccountData.bankAccountNumber')
            && _.get(value, 'value.bankAccountData.bankABANumber')
            === _.get(updatedVM, 'value.bankAccountData.bankABANumber');
    });
    return result;
}

function showBCCFailPopup(modalApi, title, message, confirmButtonText) {
    modalApi.showAlert({
        title: title,
        message: message,
        status: 'error',
        icon: 'gw-error-outline',
        confirmButtonText: confirmButtonText
    }).catch(() => {
        _.noop();
    });
}

function forMatterPaymentPlan(row) {
    /*
        "Full Pay",
        "Semiannual",
        "Quarterly PL - 12 months",
        "Quarterly PL - 6 months"
        "Monthly PL - 12 months",
        "Monthly PL - 6 months",
        "EFTCONV6",
        "EFTCONV12"
    */
    let { name } = row;
    const NamesChange = {
        'PL - 12 months': '',
        'PL - 6 months': '',
        'Full Pay': 'Pay in Full',
        // CL payment plans rename
        'FULL PAY CL': 'Full Pay CL',
        'SEMIANNUAL CL': 'Semiannual CL',
        'QUARTERLY CL': 'Quarterly CL',
        'MONTHLY CL': 'Monthly CL'
    };
    _.forEach(NamesChange, (value, key) => {
        if (name.includes(key)) {
            name = name.substring(0, row.name.indexOf(key));
            name += value;
        }
    });
    return {
        name: name,
        code: row.billingId,
    };
}

async function savePayInstrucment(vm, updateShowLoader,
    savePayInstrucmentService, quoteID, sessionUUID, authHeader) {
    try {
        updateShowLoader(true);
        const result = await savePayInstrucmentService(
            quoteID,
            sessionUUID,
            _.get(vm, 'value.bankAccountData.bankABANumber'),
            _.get(vm, 'value.bankAccountData.bankAccountNumber'),
            _.get(vm, 'value.bankAccountData.bankName'),
            _.get(vm, 'value.bankAccountData.bankAccountType'),
            null,
            _.get(vm, 'value.bankAccountData.publicID'),
            authHeader
        );
        updateShowLoader(false);
        return result;
    } catch (ex) {
        updateShowLoader(false);
        return null;
    }
}

function generatePaymentBillingMethods(codes, showPAYGOption = false) {
    const billingMethodOptions = [];
    _.each(codes, code => {
        switch(code) {
            case 'DIRECT_BILL':
                billingMethodOptions.push({
                    code: 'DIRECT_BILL',
                    name: 'Direct Bill'
                });
                break;
            case 'AGENCY_BILL':
                billingMethodOptions.push({
                    code: 'AGENCY_BILL',
                    name: 'Agency Bill'
                });
                break;
            case 'PAY_AS_YOU_GO':
                if (showPAYGOption) {
                    billingMethodOptions.push({
                        code: 'PAY_AS_YOU_GO',
                        name: 'Pay As You Go'
                    });
                }
                break;
            default: break;
        }
    });
    return billingMethodOptions;
}


async function getDefaultInvoiceCloudBasicRequestParam(submissionVM, isMultiAndNew, CAPaymentService, authHeader) {
    const middleNumber = _.get(submissionVM, 'value.quoteID', '');
    const referenceNumber = await CAPaymentService.getReferenceNumber(middleNumber, isMultiAndNew, authHeader);
    // let referenceNumber;
    // if (isMultiAndNew) {
    //     referenceNumber = `4${middleNumber}000001`;
    // } else if (middleNumber.length > 10) {
    //     referenceNumber = `4${middleNumber}000`;
    // } else {
    //     referenceNumber = `4${middleNumber}000000`;
    // }
    const basicParam = {
        FromSystem: 'bcc-ecc',
        ActionCode: 'EXPAYCC',
        brand: 'WNI',
        AccountNumber: getAccountNumberForBCC(_.get(submissionVM, 'value.baseData.accountNumber')),
        RemoteUserId: _.get(submissionVM, 'value.baseData.accountHolder.displayName'),
        firstName: _.get(submissionVM, 'value.baseData.accountHolder.firstName'),
        lastName: _.get(submissionVM, 'value.baseData.accountHolder.lastName'),
        address1: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.addressLine1')
            || _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.pobox_Ext'),
        city: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.city'),
        state: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.state'),
        postal: _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.postalCode'),
        // amtDue: amtDue,
        reference: referenceNumber,
        // surl: `${baseCallBackUrl}/success`,
        // curl: `${baseCallBackUrl}/cancel`
    };
    return basicParam;
}

function initInvoiceCloudData(
    res,
    viewModelService,
    model,
    isMulti,
    quoteID,
    updateDisplayAgencySweep,
    updatePaymentMethodsVM,
    updateIsMultiAndNew,
    updatePaymentMethodValue
) {
    const showAgencySweep = _.get(res, 'bindData.agencySweep_Ext');
    updateDisplayAgencySweep(showAgencySweep);
    // paymentMethods
    const payInstrucments = [];
    const newPaymentMethods = initPaymentMethods(
        payInstrucments,
        viewModelService
    );
    updatePaymentMethodsVM(newPaymentMethods);
    const accountHolderInfo = _.get(res, 'baseData');
    _.set(model, 'value.baseData', accountHolderInfo);
    // let isNewBillAccount = false;
    // let isMultiAndNewInit = false;
    if (isMulti) {
        // use bill accountNumber as referenceID
        const billAccount = _.get(model, 'invoiceStreamCode');
        const billAccountNumber = _.get(model, 'invoiceStreamDescription');
        if (billAccount === 'New') {
            _.set(model, 'value.quoteID', quoteID);
            // isNewBillAccount = true;
            // isMultiAndNewInit = true;
            updateIsMultiAndNew(true);
        } else {
            _.set(model, 'value.quoteID', billAccountNumber);
        }
    } else {
        // use quoteID as referenceID
        _.set(model, 'value.quoteID', quoteID);
    }
    updatePaymentMethodValue(null);
}

function getInvoiceCloudBaseUrl() {
    return aoURL;
}
/*
Below logic is required for IC
  164: Western National - Personal,
  165: Western National - Commercial,
  166: Umialik - Personal,
  167: Umialik - Commercial

*/
function getinvoiceTypeId(isCL, isWNI) {
    if(isCL){
        if(isWNI){
            return '165'
        }
        return '167'
    }
    if(isWNI){
        return '164'
    }
    return '166'
  
}

export default {
    getSelectedPaymentPlan,
    getSelectedPaymentPlanID,
    getPaymentPlanByTerm,
    getPaymentPlanIDByTerm,
    getPaymentPlanType,
    isMonthlyPaymentPlan,
    UWISSUE_MAP,
    AGENCY_SWEEP_AVAILABLE_OPTIONS,
    AGENCY_SWEEP_UNAVAILABLE_OPTIONS,
    updatePrimaryPayerOptions,
    initPaymentMethods,
    initDayOfMonth,
    getAccountNumberForBCC,
    addPaymentMethodOptions,
    getDefaultBasicRequestParam,
    getToUpdateRequestParam,
    getOneTimePaymentPublicID,
    trnasformDate,
    findSelectedPaymentMethod,
    checkPaymentMethodExist,
    showBCCFailPopup,
    forMatterPaymentPlan,
    savePayInstrucment,
    updateBillingPrimaryPayerOptions,
    generatePaymentBillingMethods,
    initInvoiceCloudData,
    getInvoiceCloudBaseUrl,
    getDefaultInvoiceCloudBasicRequestParam,
    getinvoiceTypeId
};
