import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _, { uniqBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { Icon } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WALOperatorService } from 'wni-capability-quoteandbind-wal';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { CustomFooterV2Component } from 'wni-capability-gateway-react';
import {
    WindowUtil,
    QuoteUtil,
    ValidationIssueUtil
} from 'wni-portals-util-js';

import WizardPage from '../../templates/WALWizardPage';
import OperatorComponent from './components/OperatorComponent/OperatorComponent';
import OperatorUtil from './util/OperatorUtil';

import styles from './WALOperatorsPage.module.scss';
import metadata from './WALOperatorsPage.metadata.json5';
import messages from './WALOperatorsPage.messages';

function WALOperatorsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardSnapshot,
        updateWizardSnapshot,
        resetWizardDataToSnapshot,
        //
        walOperatorService,
        linePath,
        customMessages,
        // onPageNextClicked,
    } = props;

    const translator = useTranslator();
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('WALOperatorsPage');

    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [selection, updateSelection] = useState([]);
    const [currentRow, updateCurrentRow] = useState(null);
    const [accordionStates, updateAccordionStates] = useState([]);
    const [operatorData, updateOperatorData] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false)

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;
    const operatorsPath = `lobData.${linePath}.coverables.operators`;
    const operatorsVM = _.get(submissionVM, operatorsPath);
    const operators = operatorsVM.value;
    const policyCountryCode = _.get(submissionVM.value, 'baseData.policyAddress.country');
    const lineName = !_.isEmpty(operators) ? operators[0].lineName : '';
    const vehiclePath = linePath === 'watercraft' ? `value.lobData.${linePath}.coverables.watercrafts` : `value.lobData.${linePath}.coverables.vehicles`;
    const vehicles =  _.get(submissionVM, vehiclePath) || [];
    const effectiveDate = _.get(submissionVM, 'value.baseData.effectiveDate_Ext');
    const baseState = _.get(submissionVM, 'value.baseData.baseState_Ext');

    const VALIDATION_ICON_MAP = {
        success: 'gw-check-circle',
        warning: 'gw-warning',
        error: 'gw-error',
    };

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'operatorTable');
    };

    const accordionErrorId = useCallback(() => {
        if (!currentRow) {
            return [];
        }
        const errorStateObj = OperatorUtil
            .getAccordionOverridesForOperator(currentRow);
        const errorIds = Object.keys(errorStateObj).filter((key) => errorStateObj[key].errorState);
        return errorIds;
    }, [currentRow]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        updateAccordionStates(accordionErrorId());
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [accordionErrorId, invalidFields]);

    
    const updateSubmissionVMForResponse = useCallback((res) => {
        const resPath = linePath === 'watercraft' ? 'waloperators' : 'rtoperators';
        const incidentsPath = linePath === 'watercraft' ? 'walincidentsAndReports' : 'rtincidentsAndReports';
        const resOperators = _.get(res, resPath);
        _.set(submissionVM, operatorsPath, resOperators);
        _.set(submissionVM, 'errorsAndWarnings', res.errorsAndWarnings);
        _.set(submissionVM.value, 'baseData', res.baseData);
        _.set(submissionVM, `lobData.${linePath}.incidentsAndReports_Ext`, res[incidentsPath]);
        updateWizardSnapshot(submissionVM);
        updateDisplayWarnings(true);
        return submissionVM;
    }, [submissionVM]);

    const getValidationIssues = useCallback((resErrorsAndWarnings) => {
        const errorsAndWarnings = !_.isEmpty(resErrorsAndWarnings) ? resErrorsAndWarnings : _.get(submissionVM, 'value.errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(_.uniqBy(newValidationIssues, 'reason'));
        return _.uniqBy(newValidationIssues, 'reason');
    }, [submissionVM]);

    const setRowIdPath = useCallback(() => {
        const VM = _.get(submissionVM, operatorsPath);
        const value = VM.value;
        value.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateOperatorData(value);
    }, [submissionVM]);
    
    const switchToNextOperator = useCallback(() => {
        const childrenVM = _.get(submissionVM, `lobData.${linePath}.coverables.operators.children`);
        const index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            updateCurrentRow(_.get(childrenVM, 0));
            return;
        }
        updateCurrentRow(_.get(childrenVM, index + 1));
    }, [currentRow, submissionVM]);

    const saveOperator = useCallback(async (callback) => {
        if (!isComponentValid || !_.get(currentRow, 'aspects.valid') || !_.get(currentRow, 'aspects.subtreeValid')) {
            handleValidation();
            return false;
        }
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            operator: OperatorUtil.removeEmpty(currentRow.value)
        };
        const res = await walOperatorService.updateOperator(requestData, authHeader);
        updateSubmissionVMForResponse(res);
        getValidationIssues(res.errorsAndWarnings);
        setRowIdPath();
        updateServiceCallInProgress(false);
        updateSelection([]);
        if (_.isFunction(callback)) {
            await callback(null);
        }
    }, [currentRow, handleValidation, isComponentValid, updateWizardSnapshot, walOperatorService]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow, saveOperator, switchToNextOperator, submissionVM]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const sortDateColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](moment(a).format('MM/DD/YYYY'), moment(b).format('MM/DD/YYYY'));
    };

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    useEffect(() => {
        setRowIdPath();
        _.each(operators, (operator) => {
            OperatorUtil.truncatedPhoneNumber(operator);
        })
    }, [submissionVM.value, switchToNextOperator]);

    const addOperatorForPNI = useCallback(async () => {
        updateServiceCallInProgress(true);
        const data = {
            jobID,
            sessionUUID
        }
        const res = await walOperatorService.addOperatorForPNI(data, authHeader);
        updateSubmissionVMForResponse(res);
        setRowIdPath();
        updateServiceCallInProgress(false);
    }, []);

    useEffect(() => {
        if(_.isEmpty(operators)) {
            addOperatorForPNI();
        }
    }, []);

    const removeOperators = useCallback(() => {
        const removeMessage = OperatorUtil.excludePNISelectionsAndMessage(operators, selection).removeMessage;
        const removeSelection = OperatorUtil.excludePNISelectionsAndMessage(operators, selection).selection;
        modalApi.showConfirm({
            title: messages.removeOperatorTitle,
            message: removeMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.Ok,
            cancelButtonText: messages.Cancel
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const requestData = {
                    jobID,
                    sessionUUID,
                    publicIDs: removeSelection
                };
                updateServiceCallInProgress(true);
                const res = await walOperatorService.removeOperators(requestData, authHeader);
                updateServiceCallInProgress(false);
                updateCurrentRow(null);
                updateSelection([]);
                updateSubmissionVMForResponse(res);
                getValidationIssues(res.errorsAndWarnings);
                setRowIdPath();
                return true;
            }, _.noop
        );
    }, [selection, submissionVM, updateWizardSnapshot]);

    const addOperator = useCallback(() => {
        const operatorObj = OperatorUtil.setDefaultOperatorObj(policyCountryCode, lineName)
        const {
            _dtoName,
            _xCenter,
        } = operatorsVM;
        const operatorVM = viewModelService.create(operatorObj, _xCenter, _dtoName);
        const addedOperator = operatorsVM
            .pushElement(operatorVM);
        setRowIdPath();
        updateCurrentRow(addedOperator);
        updateWizardSnapshot(submissionVM);
        updateShowErrors(false);
    }, [submissionVM, updateWizardSnapshot]);

    const viewOrEditOperator = (value, index) => {
        const childrenVM = _.get(submissionVM, `lobData.${linePath}.coverables.operators.children`);
        const operatorVM = childrenVM.find((item) => item.value.rowIdPath === index);
        updateShowErrors(false);
        setRowIdPath();
        updateCurrentRow(operatorVM);
    };

    const writeValue = useCallback((value, path) => {
        if(currentRow) {
            _.set(currentRow, path, value);
            updateWizardData(submissionVM);
        }
    }, [currentRow, submissionVM, updateWizardData]);

    const cancel = useCallback(() => {
        resetWizardDataToSnapshot();
        updateCurrentRow(null);
    }, [wizardSnapshot]);

    const getFooterButtons = useCallback(() => {
        if (!currentRow) {
            return (<div />);
        }
        const nextButtonVisible = operators.length > 1;

        return (
            <CustomFooterV2Component
                saveAndNextMessage={translator(messages.saveAndNext)}
                handleCancel={cancel}
                handleSaveAndClose={() => {
                    saveOperator(updateCurrentRow);
                }}
                handleSaveAndNext={() => {
                    saveOperator(switchToNextOperator);
                }}
                nextButtonVisible={nextButtonVisible}
            />
        );
    }, [cancel, currentRow, saveOperator, switchToNextOperator, translator]);
    
    const isShowPersonOperateMotorcycle = useCallback(() => {
        const eligibleVehicle = _.find(vehicles, (vehicle) => {
            const itemToBeInsured = linePath === 'watercraft' ? vehicle.itemToBeInsured_Ext : vehicle.itemToBeInsured;
            return itemToBeInsured === 'motorcycle'
        });
        return !_.isEmpty(eligibleVehicle) && OperatorUtil.getAge(currentRow, effectiveDate) > 12 && linePath === 'roadTrail';
    }, [currentRow, effectiveDate, linePath, vehicles]);

    const isShowWillDriverExclusion = useCallback(() => {
        const {
            isLicensedDriver,
            unLicensedDriverReason
        } = _.get(currentRow, 'value') || {};
        const showCondition = (baseState === 'AK' || baseState === 'SD')
            && (isLicensedDriver
                || (!isLicensedDriver
                    && unLicensedDriverReason !== 'underage'
                    && unLicensedDriverReason !== 'willNeverLicensed'
                    && unLicensedDriverReason !== 'other'))
        return showCondition;
    }, [baseState, currentRow]);

    const isShowOperatorhaveMotorcycleEndorsement = useCallback((operatorVM) => {
        const itemVm = operatorVM || currentRow;
        const {
            isLicensedDriver,
            unLicensedDriverReason
        } = _.get(itemVm, 'value') || {};
        const willDriverExclusionRT = _.get(itemVm, 'value.driverQuestionSets[0].answers.willDriverExclusionRT_Ext') || {};
        const eligibleVehicle = _.find(vehicles, (vehicle) => {
            const itemToBeInsured = linePath === 'watercraft' ? vehicle.itemToBeInsured_Ext : vehicle.itemToBeInsured;
            return itemToBeInsured !== 'trailer'
        });
        const hideConditions = !isLicensedDriver &&
            (unLicensedDriverReason === 'underage'
            || unLicensedDriverReason === 'willNeverLicensed'
            || unLicensedDriverReason === 'idOnly'
            || unLicensedDriverReason === 'permitonly'
            || unLicensedDriverReason === 'other')
        const willDriverExclusionCondition = isShowWillDriverExclusion() && (willDriverExclusionRT === 'true' || willDriverExclusionRT === true)
        return !_.isEmpty(eligibleVehicle) && !hideConditions && !willDriverExclusionCondition && linePath === 'roadTrail';
    }, [currentRow, isShowWillDriverExclusion, linePath, vehicles]);
    
    const isShowOperatorMotorcycleEndorsementDuration = useCallback((operatorVM) => {
        const itemVM = operatorVM || currentRow;
        const questionset = _.get(itemVM, 'value.driverQuestionSets[0].answers') || {};
        const {
            operatorhaveMotorcycleEndorsementNoDependent_Ext: operatorhaveMotorcycleEndorsementNoDependent
        } = questionset;
        if (isShowOperatorhaveMotorcycleEndorsement(operatorVM)
            && operatorhaveMotorcycleEndorsementNoDependent === 'yes_Ext') {
            return true && linePath === 'roadTrail';
        }
        return false;
    }, [currentRow, isShowOperatorhaveMotorcycleEndorsement, linePath]);

    const hasIssuanceInvalidUWQuestion = useCallback((operatorVM) => {
        /**
         * personOperateMotorcycleNoDependent_Ext
         * operatorhaveMotorcycleEndorsementNoDependent_Ext
         * operatorMotorcycleEndorsementDurationNoDependent_Ext
         */
        const operator = _.get(operatorVM, 'value') || {};
        const {
            personOperateMotorcycleNoDependent_Ext: personOperateMotorcycleNoDependent,
            operatorhaveMotorcycleEndorsementNoDependent_Ext: operatorhaveMotorcycleEndorsementNoDependent,
            operatorMotorcycleEndorsementDurationNoDependent_Ext: operatorMotorcycleEndorsementDuration
        } = _.get(operator, 'driverQuestionSets[0].answers')
        if (isShowPersonOperateMotorcycle()
            &&(personOperateMotorcycleNoDependent === null || personOperateMotorcycleNoDependent === undefined)) {
            return true;
        }
        if (isShowOperatorhaveMotorcycleEndorsement(operatorVM)
            && !operatorhaveMotorcycleEndorsementNoDependent) {
            return true;
        }
        if (isShowOperatorMotorcycleEndorsementDuration(operatorVM)
            && !operatorMotorcycleEndorsementDuration) {
            return true;
        }
        return false;
    }, [])

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `lobData.${linePath}.coverables.operators.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        const hasIssuanceInvalidFields = OperatorUtil.hasIssuanceInvalidFields(itemVM);
        let type;
        if(!_.get(itemVM, 'aspects.valid') || !_.get(itemVM, 'aspects.subtreeValid')) {
            type = 'error';
        } else if (hasIssuanceInvalidFields || hasIssuanceInvalidUWQuestion(itemVM)) {
            type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const isShowCompletedSafetyCoursesInfoWT = useCallback(() => {
        const willDriverExclusion = _.get(currentRow, 'value.driverQuestionSets[0].answers.willDriverExclusion_Ext');
        const willDriverExclusionCondition = isShowWillDriverExclusion() && (willDriverExclusion === 'true' || willDriverExclusion === true)
        return !willDriverExclusionCondition;
    }, [currentRow, isShowWillDriverExclusion]);

    const isShowCompletedSafetyCoursesInfo = useCallback(() => {
        const willDriverExclusion = _.get(currentRow, 'value.driverQuestionSets[0].answers.willDriverExclusionRT_Ext');
        const eligibleVehicle = _.find(vehicles, (vehicle) => {
            const itemToBeInsured = linePath === 'watercraft' ? vehicle.itemToBeInsured_Ext : vehicle.itemToBeInsured;
            return itemToBeInsured === 'recreational_vehicle'
                || itemToBeInsured === 'snowmobile_snowmachine'
                || vehicle.vehicleType === 'recreational_vehicle'
                || vehicle.vehicleType === 'snowmobile_snowmachine'
        });
        const willDriverExclusionCondition = isShowWillDriverExclusion() && (willDriverExclusion === 'true' || willDriverExclusion === true)

        if (linePath === 'roadTrail') {
            return false;
        }

        return !_.isEmpty(eligibleVehicle) && !willDriverExclusionCondition;
    }, [currentRow, isShowWillDriverExclusion, linePath, vehicles]);

    const isShowMaritalStatus = useCallback(() => {
        const eligibleVehicle = _.find(vehicles, (vehicle) => {
            return vehicle.itemToBeInsured === 'motorcycle'
        });
        return linePath === 'roadTrail' && !_.isEmpty(eligibleVehicle);
    }, []);
    //---------------------
    // const overrideProps = generateOverrides();
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
        },
        delOperator: {
            onClick: removeOperators,
            disabled: _.isEmpty(selection)
        },
        addOperator: {
            onClick: addOperator,
            disabled: currentRow
        },
        operatorTable: {
            data: operatorData,
            onSelectionChange: (rows) => {
                updateSelection(rows)
            },
            rowIdPath: 'rowIdPath'
        },
        operatorValidationIcon:{
            renderCell: renderValidationCell
        },
        name: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.rowIdPath, OperatorUtil.onNameCell(item))
        },
        dateOfBirth: { renderCell: OperatorUtil.onDateOfBirthCell },
        relationship: { renderCell: (item) => OperatorUtil.onRelationshipCell(item, translator) },
        excluded: {
            visible: OperatorUtil.hasExcluded(operators)
        },
        viewOrEditLink: {
            disabled: currentRow || selection.length > 1,
        },
        operator: {
            visible: !_.isEmpty(currentRow),
            operatorVM: currentRow,
            onValueChange: writeValue,
            showErrors,
            accordionStates,
            updateAccordionStates,
            messages: customMessages,
            baseState,
            effectiveDate,
            relationshipOptions: _.get(operatorsVM, 'value[0].relationshipOptions'),
            availableAccountContacts: _.get(operatorsVM, 'value[0].unassignedDrivers'),
            isShowCompletedSafetyCoursesInfo: isShowCompletedSafetyCoursesInfo(),
            isShowCompletedSafetyCoursesInfoWT: isShowCompletedSafetyCoursesInfoWT(),
            isShowOperatorhaveMotorcycleEndorsement: isShowOperatorhaveMotorcycleEndorsement(),
            isShowOperatorMotorcycleEndorsementDuration: isShowOperatorMotorcycleEndorsementDuration(),
            isShowPersonOperateMotorcycle: isShowPersonOperateMotorcycle(),
            isShowWillDriverExclusion: isShowWillDriverExclusion(),
            isShowMaritalStatus: isShowMaritalStatus(),
            isShowGender: isShowMaritalStatus()
        },
        operatorDetailContainer: {
            visible: !_.isEmpty(currentRow),
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            viewOrEditOperator: viewOrEditOperator,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortDateColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
        },
        resolveComponentMap: {
            operatorcomponent: OperatorComponent,
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    const hideButtonsProps = {
        showNext: !currentRow,
        showPrevious: !currentRow,
        showCancel: !currentRow
    };

    const onPageNext = useCallback(async () => {
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await walOperatorService.onPageNext(requestData, authHeader);
        // const onNextFunc = onPageNextClicked || walOperatorService.onPageNext;
        // const res = await onNextFunc(requestData, authHeader);
        updateServiceCallInProgress(false);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        const newValidationIssues = getValidationIssues(res.errorsAndWarnings);
        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        setRowIdPath();
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, jobID, sessionUUID, submissionVM, walOperatorService, validationIssues, displayWarnings]);

    const renderPageContent = useCallback(({ onNext }) => {
        return <React.Fragment>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors = {showErrors}
                onValidationChange={onValidate}
            />
            {getFooterButtons(onNext)}
        </React.Fragment>;
    }, [overrideProps, resolvers, submissionVM, showErrors]);

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            disableNext={isServiceCallInProgress || OperatorUtil.hasInvalidOperator(operatorsVM)}
            pageLevelValidationIssues={validationIssues}
            {...hideButtonsProps}>
            {renderPageContent}
        </WizardPage>
    );
}

WALOperatorsPage.propTypes = {
    ...wizardProps,
    walOperatorService: PropTypes.object,
    linePath: PropTypes.string,
    customMessages: PropTypes.object,
    // /**
    // * Function to be called when "Next" button is clicked.
    // * Default to walOperatorService.onPageNext()
    // */
    // onPageNextClicked: PropTypes.func,
};

WALOperatorsPage.defaultProps = {
    walOperatorService: WALOperatorService,
    linePath: 'watercraft',
    customMessages: messages,
    //
    // onPageNextClicked: undefined,
};
export default WALOperatorsPage;
