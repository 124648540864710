import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { PaymentUtil } from 'wni-portals-util-js';
import { CAPaymentService } from 'wni-capability-quoteandbind-ca';
import metadata from './PaymentSetup.metadata.json5';
import messages from './PaymentSetup.messages';
import styles from './PaymentSetup.module.scss';

import { Button } from '@jutro/legacy/components';

function PaymentSetup(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        minimumDueAmount,
        invoiceStreamNumber,
        authHeader,
        displayAgencySweep,
        domainCompany,
        totalDueAmount,
        minimumDueWithFullPayDiscountAmount,
        totalBalance
    } = props;

    const translator = useTranslator();
    const [paymentMethodValue, updatePaymentMethodValue] = useState('');
    const [showErrors, updateShowErrors] = useState(false);
    const [requestParam, updateRequestParam] = useState(null);
    const [paymentAmountOther, updatePaymentAmountOther] = useState();
    const [paymentAmountOtherCheckbox, updatePaymentAmountOtherCheckbox] = useState(false);
    const [showPaymentAmountOtherError, updateShowPaymentAmountOtherError] = useState(false);
    const [minimumDueCheckBox, updateMinimumDueCheckBox] = useState(false);
    const [totalDueCheckBox, updateTotalDueCheckBox] = useState(false);
    const [minimumDueWithFullPayDiscountCheckBox, updateMinimumDueWithFullPayDiscountCheckBox] = useState(false);
    const minimumDueAmountValue = _.get(minimumDueAmount, 'amount');
    const totalBalanceAmountValue = _.get(totalBalance, 'amount', 0);

    const isOtherValueValid = useCallback(() => {
        // Other amount can be anything above min due when the min due is less than or equal $50,000
        // If min due is greater than $50,000 then the Other amount can be equal to any amount
        // const minDueAmount = minimumDueAmountValue;
        // const minDueAmountOther = _.get(paymentAmountOther, 'amount');
        // if (minDueAmount <= 50000) {
        //     return minDueAmountOther > minDueAmount;
        // }
        // (POI-53680) - Allow user to pay less than min due
        const minDueAmountOther = _.get(paymentAmountOther, 'amount');
        // (POI-53928) - do not allow the user to pay more than the total balance
        return minDueAmountOther >= 0 && minDueAmountOther <= totalBalanceAmountValue;
    }, [paymentAmountOther, totalBalanceAmountValue]);

    const isValueValid = useCallback(() => {
        return paymentAmountOtherCheckbox ? isOtherValueValid() : minimumDueAmountValue >= 0;
    }, [paymentAmountOtherCheckbox, isOtherValueValid, minimumDueAmountValue]);

    const isPaymentMethodValid = useCallback(() => {
        return paymentMethodValue;
    }, [paymentMethodValue]);

    const isCheckBoxSelected = useCallback(() => {
        return minimumDueCheckBox || totalDueCheckBox || paymentAmountOtherCheckbox || minimumDueWithFullPayDiscountCheckBox;
    }, [minimumDueCheckBox, minimumDueWithFullPayDiscountCheckBox, paymentAmountOtherCheckbox, totalDueCheckBox]);

    const handleSave = useCallback(async () => {
        if (!isValueValid() || !isPaymentMethodValid() || !isCheckBoxSelected()) {
            updateShowErrors(true);
            return false;
        }

        let amtValue;
        if (minimumDueCheckBox) {
            amtValue = minimumDueAmount;
        } else if (totalDueCheckBox) {
            amtValue = totalBalance;
        } else if (paymentAmountOtherCheckbox) {
            amtValue = paymentAmountOther;
        } else if (minimumDueWithFullPayDiscountCheckBox) {
            amtValue = minimumDueWithFullPayDiscountAmount;
        }

        const res = {};
        _.set(res, 'requestParam', requestParam);
        if (paymentMethodValue === 'AgencySweep') {
            // agencySweep
            _.set(res, 'downPayment', 'AgencySweep');
            _.set(res, 'agencySweepValue', amtValue);
            _.set(res, 'isInvoiceCloud', false);
        } else {
            _.set(res, 'isInvoiceCloud', true);

            const basicParam = await PaymentUtil.getDefaultInvoiceCloudBasicRequestParam(
                { 'value.quoteID': invoiceStreamNumber },
                false,
                CAPaymentService,
                authHeader
            );
            // const amtValue = paymentAmountOtherCheckbox ? paymentAmountOther : minimumDueAmount;
            _.set(basicParam, 'amtValue', amtValue);

            if (paymentMethodValue === 'addNewCreditCard') {
                _.set(basicParam, 'paymentType', 'cc');
            } else {
                _.set(basicParam, 'paymentType', 'ach');
            }
            
            updateRequestParam(basicParam);
            _.set(res, 'requestParam', basicParam);

            _.set(res, 'paymentMethodValue', paymentMethodValue);
        }

        return onResolve(res);
    }, [authHeader, invoiceStreamNumber, isCheckBoxSelected, isPaymentMethodValid, isValueValid,
        minimumDueAmount, minimumDueCheckBox, minimumDueWithFullPayDiscountAmount,
        minimumDueWithFullPayDiscountCheckBox, onResolve, paymentAmountOther,
        paymentAmountOtherCheckbox, paymentMethodValue, requestParam, totalBalance, totalDueCheckBox]);

    const getPaymentMethodDropdownData = useCallback(() => {
        const data = [];
        if (displayAgencySweep) {
            data.push({
                code: 'AgencySweep',
                name: translator(messages.agencySweep)
            });
        }
        data.push({
            code: 'addNewCreditCard',
            name: translator(messages.addNewCreditCard),
        });
        data.push({
            code: 'addNewBankAccount',
            name: translator(messages.addNewBankAccount),
        });
        return data;
    }, [displayAgencySweep, translator]);

    const handleDownPaymentMethodChange = useCallback(
        async (value) => {
            const basicParam =
                PaymentUtil.getDefaultInvoiceCloudBasicRequestParam(
                    { 'value.quoteID': invoiceStreamNumber },
                    false,
                    CAPaymentService,
                    authHeader
                );
            if (value === 'AgencySweep' || value === 'Check') {
                updatePaymentMethodValue(value);
                return true;
            }
            // select Add New Credit Card
            if (value === 'addNewCreditCard') {
                updatePaymentMethodValue(value);
                updateRequestParam({ ...basicParam, ActionCode: 'EXPAYCC' });
                return true;
            }

            // select Add New Bank Acount
            if (value === 'addNewBankAccount') {
                updatePaymentMethodValue(value);
                updateRequestParam({ ...basicParam, ActionCode: 'EXPAYCC' });
                return true;
            }
            // select default
            updatePaymentMethodValue(value);
            return false;
        },
        [authHeader, invoiceStreamNumber]
    );

    const agencySweepMessage = useCallback(() => {
        return (
            <div>
                <span className={styles.fontWeightNormal}>
                    You have selected Agency Sweep as the down payment method.  Please note, you will see a separate down payment for each policy issued.  If you are submitting multiple policies for issue, the down payment amount will not be combined.
                </span>
            </div>
        )
    }, []);

    const renderMinimunDueWarning = useCallback(() => {
        const basicMessage = 'Credit card maximum is $50,000 per transaction.\nBank account (ACH) maximum is $125,000 per transaction.\nMaximum number of payments per day is two (2).\nMaximum number of payments per invoice is four (4).\n\nIf you have questions, please contact our Customer Relationship Center at ';
        const UICNumber = 'UIC 800-251-3563. \n\n';
        const WNINumber = 'WN 800-352-2772. \n\n';
        if (_.get(domainCompany, 'code') === 'UIC') {
			return `${basicMessage}${UICNumber}`
        }
		return `${basicMessage}${WNINumber}`
    }, [domainCompany])

    const showPaymentAmountOtherMessage = useCallback(() => {
        if (!paymentAmountOtherCheckbox) {
            return [];
        }
        const valid = isOtherValueValid()
            ? []
            : [
                //   translator(messages.minimumAmount, {
                //       amount: minimumDueAmountValue,
                //   }),
                translator(messages.invalidValue)
              ];
        return valid;
    }, [
        isOtherValueValid,
        paymentAmountOtherCheckbox,
        minimumDueAmountValue,
        translator,
    ]);

    const handleCheckBoxSelect = useCallback((updateCheckBox, value) => {
        updateMinimumDueCheckBox(false);
        updatePaymentAmountOtherCheckbox(false);
        updateTotalDueCheckBox(false);
        updateMinimumDueWithFullPayDiscountCheckBox(false)
        updateCheckBox(value);
    }, []);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        // minimumDueAmount: {
        //     value: minimumDueAmount
        // },
        minimunDueWarning: {
            visible: _.isNumber(minimumDueAmountValue) && minimumDueAmountValue > 50000,
            content: renderMinimunDueWarning()
        },
        minimumDueCheckBox: {
            value: minimumDueCheckBox,
            onValueChange: (value) => handleCheckBoxSelect(updateMinimumDueCheckBox, value),
        },
        minimumDueAmount: {
            disabled: true,
            value: minimumDueAmount,
            label: translator(messages.minimumDue),
            className: `form-control-no-label ${styles.flexAuto}`
        },
        minimumDueWithFullPayDiscountCheckboxInputSection: {
            visible: minimumDueWithFullPayDiscountAmount && _.get(minimumDueWithFullPayDiscountAmount, 'amount') > 0
        },
        minimumDueWithFullPayDiscountCheckBox: {
            value: minimumDueWithFullPayDiscountCheckBox,
            onValueChange: (value) => handleCheckBoxSelect(updateMinimumDueWithFullPayDiscountCheckBox, value),
        },
        minimumDueWithFullPayDiscountAmount: {
            disabled: true,
            value: minimumDueWithFullPayDiscountAmount,
            label: translator(messages.minimumDueWithFullPayDiscount),
            className: `form-control-no-label ${styles.flexAuto}`
        },
        totalDueCheckBox: {
            value: totalDueCheckBox,
            onValueChange: (value) => handleCheckBoxSelect(updateTotalDueCheckBox, value),
        },
        totalDueAmount: {
            disabled: true,
            value: totalBalance,
            label: translator(messages.totalDue),
            className: `form-control-no-label ${styles.flexAuto}`
        },
        paymentAmountOtherCheckBox: {
            value: paymentAmountOtherCheckbox,
            onValueChange: (value) => handleCheckBoxSelect(updatePaymentAmountOtherCheckbox, value),
        },
        paymentAmountOther: {
            disabled: !paymentAmountOtherCheckbox,
            value: paymentAmountOther,
            label: translator(messages.minimumDueOther),
            required: paymentAmountOtherCheckbox,
            validationMessages: showPaymentAmountOtherMessage(),
            className: `form-control-no-label ${styles.flexAuto}`,
            onValueChange: updatePaymentAmountOther,
        },
        paymentMethod: {
            availableValues: getPaymentMethodDropdownData(),
            onValueChange: handleDownPaymentMethodChange,
            value: paymentMethodValue,
            required: true
        },
        agencySweepMessage: {
            label: ' ',
            value: agencySweepMessage(),
            readOnly: true,
            // visible: paymentMethodValue === 'AgencySweep'
            // POI-57714 hide this messages when select 'agency sweep'
            visible: false
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="paymentDetailPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

PaymentSetup.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    rePopupDownPaymentSetup: PropTypes.func,
    viewModelService: PropTypes.PropTypes.shape({})
};
PaymentSetup.defaultProps = {
    size: 'md',
    rePopupDownPaymentSetup: null,
    viewModelService: undefined,
};
export default PaymentSetup;
