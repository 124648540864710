import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';

import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';

import metadata from './GeneralCoveragesPage.metadata.json5';
import './GeneralCoveragesPage.messages';

const coveragePath = 'lobData.businessOwners.coverages.lobCoverages';

function GeneralCoveragesPage(props) {
    const [loadingClause, updateLoadingClause] = useState();
    const { wizardData: submissionVM, updateWizardData, authHeader } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('GeneralCoveragesPage');

    const updateClauses = useCallback(
        async (clausesToUpdate) => {
            const { quoteID, jobID, sessionUUID } = submissionVM.value;
            const newSubmissionVM = viewModelService.clone(submissionVM);

            // Replace last word with value
            const baseCoveragePath = coveragePath.replace(/\.\w+$/, '.value');
            const lobName = ClausesUtil.getLobNameFromPath(coveragePath);

            const dataForServer = {
                [lobName]:
                    _.get(clausesToUpdate, baseCoveragePath)
                    || _.get(newSubmissionVM, baseCoveragePath)
            };

            const response = await LoadSaveService.updateCoverages(
                quoteID || jobID,
                sessionUUID,
                dataForServer,
                authHeader
            );

            _.set(
                newSubmissionVM,
                baseCoveragePath,
                response.businessOwners || response.lobData.businessOwners.coverages
            );

            const fieldsToRemoveFromValidation = ClausesUtil.getRemovedClausesID(
                submissionVM,
                newSubmissionVM,
                coveragePath
            );

            disregardFieldValidation(fieldsToRemoveFromValidation);
            updateWizardData(newSubmissionVM);

            return newSubmissionVM;
        },
        [
            LoadSaveService,
            authHeader,
            submissionVM,
            disregardFieldValidation,
            updateWizardData,
            viewModelService
        ]
    );

    const onClauseUpdate = useCallback(async () => {
        const { quoteID, jobID, sessionUUID } = submissionVM.value;

        const lobName = ClausesUtil.getLobNameFromPath(coveragePath);
        const clauseType = ClausesUtil.getLastSectionOfPath(coveragePath);

        const clauses = _.get(submissionVM, `${coveragePath}.value`);
        // As the clause is set to edited
        // ClausesUtil.filterUnchangedClauses will pick it up in structureClausesForServer
        const clausesToUpdate = ClausesUtil.structureClausesForServer(clauses, lobName, clauseType);

        const response = await LoadSaveService.updateCoverages(
            quoteID || jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        );
        const updatedClauses = _.get(response, `${lobName}.${clauseType}`)
            || _.get(response.lobData, `${lobName}.coverages.${clauseType}`);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, coveragePath, updatedClauses);

        const fieldsToRemoveFromValidation = ClausesUtil.getRemovedClausesID(
            submissionVM,
            newSubmissionVM,
            coveragePath
        );
        disregardFieldValidation(fieldsToRemoveFromValidation);

        updateLoadingClause(undefined);
        updateWizardData(newSubmissionVM);
        return newSubmissionVM;
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        disregardFieldValidation,
        updateWizardData,
        viewModelService
    ]);

    const onScheduleChange = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.set(newSubmissionVM, `${path}.value`, value);

            return updateClauses(newSubmissionVM);
        },
        [submissionVM, updateClauses, viewModelService]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const syncCoverages = useCallback(
        async (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(submissionVM, basePath);

            const clauses = _.get(submissionVM, `${coveragePath}.value`);

            if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
                updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
                const newSubmissionData = await onClauseUpdate();
                updateWizardData(newSubmissionData);
            }
        },
        [onClauseUpdate, submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncCoverages(value, changedPath);
        },
        [changeSubmission, syncCoverages]
    );

    const onNext = useCallback(updateClauses, [updateClauses]);

    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: onScheduleChange,
            onValidate: onValidate
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        clauses_from_VM: {
            loadingClause: loadingClause
        }
    };

    const shouldPageSkip = useCallback(() => {
        const coverages = _.get(
            submissionVM.value,
            'lobData.businessOwners.coverages.lobCoverages'
        );
        const jobType = _.get(submissionVM.value, 'baseData.jobType');
        if (jobType === 'Submission') {
            return true;
        }
        const status = _.get(submissionVM.value, 'status');
        return status === 'Quoted' || (coverages && !_.find(coverages, { isValid: false }));
    }, [submissionVM]);

    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
        registerInitialComponentValidation(shouldPageSkip);
    }, [
        isPageLoading,
        registerComponentValidation,
        registerInitialComponentValidation,
        shouldPageSkip
    ]);

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

GeneralCoveragesPage.propTypes = {
    ...wizardProps,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withAuthenticationContext(GeneralCoveragesPage);
