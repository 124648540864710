import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _, { over } from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import metadata from './UnderlyingCoverageInfo.metadata.json5';
import { isInfoFieldVisible } from '../../util/Underlying.util';

function UnderlyingCoverageInfo(props) {
    const {
        currentRow,
        originalCurrentRow,
        onValueChange,
        syncWizardData,
        onValidate,
        showErrors,
        isReadOnly,
        isRequiredForIssuance,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;

    const {
        coveredItem,
        isULPolicyCopiedFromSource
    } = currentRow.value;

    const translator = useTranslator();
 

    const getVisibleField = (fieldPath) => {
        return isInfoFieldVisible(currentRow, fieldPath);
    };


    const renderAvailableValues = () => {
        if(coveredItem === 'property'){
            return []
        }
        const coveredData = _.get(currentRow.value, coveredItem, []);
        return coveredData.map((item) => {
            return {
                name: `${item.year || '-'} ${item.make || '-'} ${item.model || '-'}`,
                code: item.rowIdPath
            }
        });
    }
    const handleCoveredUnderPolicy = (value, path) => {
        _.set(currentRow.value, path, value);
        const currentCoveredData = _.get(currentRow.value, coveredItem, []);
        currentCoveredData.forEach((item) => {
            if(value.includes(item.rowIdPath)) {
                _.set(item, 'isVehCoveredUnderThisPolicy', true)
            }else {
                _.set(item, 'isVehCoveredUnderThisPolicy', false)
            }
        });
        syncWizardData(currentRow);
    }

    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance,
            disabled: isULPolicyCopiedFromSource
        },
        areVehiclesUnderSamePol: {
            visible: getVisibleField('areVehiclesUnderSamePol')
        },
        ulPolicyBaseState: {
            visible: getVisibleField('ulPolicyBaseState')
        },
        vehCoveredUnderThisPolicy: {
            visible: getVisibleField('vehCoveredUnderThisPolicy'),
            availableValues: renderAvailableValues(),
            onValueChange: handleCoveredUnderPolicy
        },
        hasUlPolicyCSLLimit: {
            visible: getVisibleField('hasUlPolicyCSLLimit'),
        },
        ulPolicyCSLLimit: {
            visible: getVisibleField('ulPolicyCSLLimit')
        },
        ulPolicyCSLOtherLimit: {
            visible: getVisibleField('ulPolicyCSLOtherLimit')
        },
        ulPolicyBILimit: {
            visible: getVisibleField('ulPolicyBILimit')
        },
        ulPolicyBIOtherLimit: {
            visible: getVisibleField('ulPolicyBIOtherLimit')
        },
        ulPolicyPDLimit: {
            visible: getVisibleField('ulPolicyPDLimit')
        },
        ulPolicyPDOtherLimit: {
            visible: getVisibleField('ulPolicyPDOtherLimit')
        },
        ulPolicyLiabilityLimit: {
            visible: getVisibleField('ulPolicyLiabilityLimit'),
            disabled: isULPolicyCopiedFromSource && _.get(originalCurrentRow.value, 'ulPolicyLiabilityLimit')
        },
        ulPolicyLiabOtherLimit: {
            visible: getVisibleField('ulPolicyLiabOtherLimit'),
            disabled: isULPolicyCopiedFromSource && _.get(originalCurrentRow.value, 'ulPolicyLiabilityLimit')
        },
        ulPolicyCarrier: {
            visible: getVisibleField('ulPolicyCarrier'),
        },
        ulPolicyNumber: {
            visible: getVisibleField('ulPolicyNumber'),
            disabled: isULPolicyCopiedFromSource && _.get(originalCurrentRow.value, 'ulPolicyLiabilityLimit')
        },
        ulPolicyEffectiveDate: {
            visible: getVisibleField('ulPolicyEffectiveDate')
        },
        ulPolicyExpirationDate: {
            visible: getVisibleField('ulPolicyExpirationDate')
        },
        ulPolicyStatus: {
            visible: getVisibleField('ulPolicyStatus')
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, currentRow, fieldId, fieldPath, overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrides}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default UnderlyingCoverageInfo;
