import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Icon } from '@jutro/components';
// import { useHistory } from 'react-router-dom';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import {
    WindowUtil,
    IssuanceValidationUtil,
    QuoteUtil,
    ValidationIssueUtil,
    ConfigUtil
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues } from 'wni-capability-gateway-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { PUUnderlyingService } from 'wni-capability-quoteandbind-pu';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PropTypes from 'prop-types';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import WizardPage from '../../templates/PUWizardPage';
import PUUnderlyingComponent from './UnderlyingComponent/PUUnderlyingComponent';
import metadata from './PUUnderlyingPage.metadata.json5';
import messages from './PUUnderlyingPage.messages';
import { setDefaultObj, generateIssuanceValid } from './util/Underlying.util';
import { validationNameMaps } from './config/Underlying.static';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};
const DTO_PATH = 'wni.edge.capabilities.quote.lob.personalumbrella.dto.PUUnderlyingDTO';
const xCenter = 'pc';

const PATH = 'lobData.personalUmbrella.coverables.underlyings';
const COVERABLES_PATH = 'lobData.personalUmbrella.coverables';
const CHILDREN_PATH = `${PATH}.children`;
// const DATA_PATH = `${PATH}.value`;
function PUUnderlyingPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardPageData,
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        isReadOnly,
        checkRequiredForIssuance,
        currentStep
    } = props;
    const {
        jobID,
        sessionUUID,
        baseData,
        lobData: {
            personalUmbrella: {
                offerings,
            }
        }
    } = submissionVM.value;
    const {
        selectedVersion_Ext: selectedVersionPublicID,
    } = baseData;
    const { 
        initialValidation,
        disregardFieldValidation,
        onValidate, 
        isComponentValid, 
        invalidFields
    } = useValidation('underlyingPage');
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const [currentRow, updateCurrentRow] = useState(null);
    const [originalCurrentRow, updateOriginalCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [isAddressFlag, updateAddressFlag] = useState(true);

    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);
    // const isRequiredForIssuance = true
    const COVERED_MAPS = _.get(submissionVM.value, `${COVERABLES_PATH}.underlyingCoveredMaps`, []);
    const policyLine = _.get(submissionVM.value, `${COVERABLES_PATH}.policyLine`);

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? (_.get(activeRow.value, 'rowIdPath')) : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'underlyingTable');
    };

    useEffect(() => {
        if(!currentRow) {
            disregardFieldValidation(validationNameMaps)
        }
    }, [currentRow]);

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        updateCurrentRow(rowData);
        if(!rowData) {
            return false;
        }
        // const {
        //     _dtoName,
        //     _xCenter,
        // } = rowData;
        // const initCurrentRow = viewModelService.create(rowData.value, _xCenter, _dtoName);
       
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const updateSubmission = (currentVM) => {
        const allData = _.get(submissionVM.value, PATH);
        const currentIndex = allData.findIndex((item) => item.rowIdPath === _.get(currentVM.value, 'rowIdPath')); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshot);
    };
    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardData);
    };
    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [currentRow, invalidFields]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow, submissionVM]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const updateService = useCallback(async(serviceName, serviceData) => {
        setLoadingMask(true);
        const res = await PUUnderlyingService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        _.set(submissionVM, `${PATH}.value`, res.underlyings);
        _.set(submissionVM, `${COVERABLES_PATH}.propertyLocations.value`, res.propertyLocations);
        _.set(submissionVM, `${COVERABLES_PATH}.unUsedPropertyLocations.value`, res.unUsedPropertyLocations);
        updateSelection([]);
        updateWizardSnapshot(submissionVM);
        updateShowErrors(false);
        updateCurrentRowInteranl(null);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM]);

    const addCoveredItemFn = async(item) => {
        const addItem = setDefaultObj(item.code, policyLine)
        
        const newData = {
            coveredItem: item.code,
            [item.code]: item.code === 'property' ? addItem : [addItem],
            rowIdPath: `policy:${ConfigUtil.getUuid()}`
        };
        const newVM = await viewModelService.create(newData, xCenter, DTO_PATH);
        const addedNewItem = _.get(submissionVM, PATH, []).pushElement(newVM);
        syncWizardData(addedNewItem);
        // await updateWizardData(submissionVM);
        highlightRowFn(addedNewItem)
        updateShowErrors(false);
    };

    const removeFn = () => {
        modalApi.showConfirm({
            title: messages.removeTitle,
            message: messages.removeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            
            syncWizardData(null);
            updateService('removeUnderlying', selection);
        })
    };

    const viewAndEdit = (row, index) => {
        setLoadingMask(true);
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const currentVM = childrenVM.find((item) => item.value.rowIdPath === index);

        updateCurrentRowInteranl(currentVM)
        updateOriginalCurrentRow(viewModelService.clone(currentVM));
        setLoadingMask(false);
    };

    const cancelFn = () => {
        resetWizardDataToSnapshot()
        updateCurrentRowInteranl(null);
    };

    const updateLookupValidation = (validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    };

    const updateItem = async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        const {
            coveredItem,
        } = currentRow.value || {};
        if(coveredItem === 'property') {
            const addressUtil = AddressVerifiedUtil({
                authHeader,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                addressVM: _.get(currentRow, 'property.propertyLocation.address'),
                addressPath: 'property.propertyLocation.address',
                updateValidations: updateLookupValidation,
                writeValue: writeValue,
                //
                modalApi,
            });
            const verifiedObj = await addressUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }
        }
        await updateService('updateUnderlying', currentRow.value);
        return true;
    };

    const saveAndAdd = async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        const {
            coveredItem,
            areVehiclesUnderSamePol,
            vehCoveredUnderThisPolicy = []
        } = currentRow.value || {};
        
        const vehicles = _.get(currentRow.value, coveredItem, []);
        const filterUnderPolicy = vehicles.filter((item) => vehCoveredUnderThisPolicy.includes(item.rowIdPath));
        const filterNotUnderPolicy = vehicles.filter((item) => !vehCoveredUnderThisPolicy.includes(item.rowIdPath));
        const saveUnderPolicyData = {
            ...currentRow.value,
            [coveredItem]: filterUnderPolicy
        };

        // save the vehicles under this policy
        await updateService('updateUnderlying', saveUnderPolicyData);

        // add new policy for vehicles which is not under prev policy    
        const newData = {
            coveredItem: coveredItem,
            [coveredItem]: filterNotUnderPolicy,
            rowIdPath: `policy:${ConfigUtil.getUuid()}`
        };
        const newVM = await viewModelService.create(newData, xCenter, DTO_PATH);
        const addedNewItem = _.get(submissionVM, PATH, []).pushElement(newVM);
        syncWizardData(addedNewItem);
        highlightRowFn(addedNewItem)
        updateShowErrors(false);
        return true;
    };

    const onNextItem = useCallback(() => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        let index = _.findIndex(childrenVM, (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.rowIdPath');
        syncWizardData(null);
        viewAndEdit(null, indexID)
        WindowUtil.scrollToTop()
    }, [currentRow, submissionVM]);

    const allVehicleValid = () => {
        const allVMs = _.get(submissionVM, CHILDREN_PATH, []);
        return allVMs.every((vm) => vm.aspects.valid && vm.aspects.subtreeValid);
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const onPageNext = async() => {
        if (!allVehicleValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await PUUnderlyingService.onPageNext(requestData, authHeader);
        const lobCoveragePath = `lobData.personalUmbrella.offerings[${selectedVersionIndex}].coverages`;
        const newLobCoverage = _.get(res, `lobCoverages.personalUmbrella`);
        _.set(submissionVM.value, lobCoveragePath, newLobCoverage);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return submissionVM;
    };

    const renderCoveredCell = (item) => {
        const currentCovered = item.coveredItem;

        const itemCovered = item[currentCovered];
        switch(currentCovered){   
            case 'property':
                return _.get(itemCovered, 'propertyLocation.address.displayName', '-')
            default:
                const coveredData = itemCovered || [];
                return <ul className='mb-0'>
                    {
                        coveredData.map((eachItem) => {
                            return <li>{`${eachItem.year || '-'} ${eachItem.make || '-'} ${eachItem.model || '-'}`}</li>
                        })
                    }
                </ul>
        }
    };

    const renderCell = (item, index, { path, typeKey }) => {
        return item[path] ? translator({id: `${typeKey}.${item[path]}` }) : '-'
    };
    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        let type;
        const isItemValid = _.get(itemVM, 'aspects.valid') && _.get(itemVM, 'aspects.subtreeValid');
        if(isItemValid) {
            const issuacneInValidField = generateIssuanceValid(itemVM, baseData);     
            if(_.isEmpty(issuacneInValidField)) {
                type = 'success';
            } else {
                type = 'warning';
            }
        } else {
            type = 'error';
        }
        const iconDom = <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.rowIdPath, iconDom)
    };
    const generateCoveredItemsOverrides = () => {
        const overrides = COVERED_MAPS.map((item, index) => {
            return {
                [`coveredItem${index}`]: {
                    onClick: () => addCoveredItemFn(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const writeValue = (value, path) => {
        if (!currentRow) {
            return false;
        }

        if (AddressChangeVerify(path, 'property.propertyLocation.address')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateAddressFlag(false);
        }
        const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
        if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')) {
            _.set(currentRow.value, path, undefined);
        } else {
            _.set(currentRow.value, path, value);
        }

        syncWizardData(currentRow);
    };

    const getVisibleForSaveAndAdd = () => {
        if(!currentRow) {
            return false;
        }
        const {
            coveredItem,
            areVehiclesUnderSamePol,
            vehCoveredUnderThisPolicy = []
        } = currentRow.value || {};
        if(coveredItem === 'property') {
            return false;
        }
        const vehicles = _.get(currentRow.value, coveredItem, []);
        if(areVehiclesUnderSamePol !== false) {
            return false;
        }
        if(vehCoveredUnderThisPolicy.length === vehicles.length) {
            return false;
        }
        return true;
    }

    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        underlyingTitleAction: {
            visible: !isReadOnly
        },
        addItem: {
            disabled: currentRow
        },
        itemData: {
            data: COVERED_MAPS
        },
        removeItem: {
            disabled: selection.length < 1,
        },
        underlyingTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: isReadOnly ? 'none' : 'multi',
        },
        underlyingDetailContainer: {
            visible: currentRow != null,
        },
        viewAndEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel,
        },
        underlyingDetails: {
            visible: currentRow != null,
            currentRow,
            originalCurrentRow,
            syncWizardData,
            syncWizardDataSnapshot,
            updateCurrentRowInteranl,
            onValueChange: writeValue,
            onValidate,
            showErrors,
            updateShowErrors,
            isReadOnly,
            isRequiredForIssuance,
            propertyLocations: _.get(submissionVM, `${COVERABLES_PATH}.propertyLocations.value`),
            unUsedPropertyLocations:_.get(submissionVM, `${COVERABLES_PATH}.unUsedPropertyLocations.value`),
            puUnderlyingService: PUUnderlyingService,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
                baseData,
                policyLine
            },
        },
        saveButtons: {
            visible: !isReadOnly,
        },
        // saveNextButton: {
        //     visible: _.get(submissionVM, `${PATH}.value`, []).length > 1,
        // },
        saveAndAddButton: {
            visible: getVisibleForSaveAndAdd()
        },
        saveButton: {
            visible: !getVisibleForSaveAndAdd()
        },
        ...generateCoveredItemsOverrides()
    };
    const resolvers = {
        resolveCallbackMap: {
            removeFn,
            viewAndEdit,
            cancelFn,
            saveFn: () => {
                updateItem().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndAdd,
            // saveAndNext: () => {
            //     updateItem().then((valid) => {
            //         if (valid) {
            //             onNextItem();
            //         }
            //     });
            // },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderCell,
            renderValidationCell,
            renderCoveredCell
        },
        resolveComponentMap: {
            underlyingcomponent: PUUnderlyingComponent,
        },
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            disableNext={!allVehicleValid()}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

PUUnderlyingPage.propTypes = {
    ...wizardProps,
    isReadOnly: PropTypes.bool
}
PUUnderlyingPage.defaultProps = {
    checkRequiredForIssuance: true,
    isReadOnly: false
};
export default PUUnderlyingPage;
