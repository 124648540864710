import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cuUnderlyingService'), method, data, additionalHeaders);
}

export default class CUUnderlyingService {

    static fetchExistingPolicies(jobNumber, sessionUUID, accountNumber, authHeader = {}) {
        return processSubmission('fetchExistingPolicies', [jobNumber, sessionUUID, accountNumber], authHeader);
    }

    static refreshUnderlyingPolicies(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('refreshUnderlyingPolicies', [jobNumber, sessionUUID], authHeader);
    }

    static addInternalPolicies(jobNumber, sessionUUID, accountNumber, authHeader = {}) {
        return processSubmission('addInternalPolicies', [jobNumber, sessionUUID, accountNumber], authHeader);
    }
    
    static updateUnderlyingPolicy(jobNumber, sessionUUID, dto, authHeader = {}) {
        return processSubmission('updateUnderlyingPolicy', [jobNumber, sessionUUID, dto], authHeader);
    }

    static removeItems(jobNumber, sessionUUID, publicIds, authHeader = {}) {
        return processSubmission('removeItems', [jobNumber, sessionUUID, publicIds], authHeader);
    }

    /**
     * Invokes CUUnderlyingHandler.onPageNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onPageNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onPageNext', [jobNumber, sessionUUID], authHeader);
    }

}