import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React from 'react';
import metadata from './LocationComponent.metadata.json5';
import LocationDetails from './LocationDetails/LocationDetails';

function LocationComponent(props) {
    const {
        currentRow = {},
        writeValue,
        onValidate,
        showErrors,
    } = props;

    const newComponentProps = _.omit(props, 'id');

    const overrideProps = {
        '@field': {
            ...newComponentProps,
        },
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            locationdetails: LocationDetails
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default LocationComponent;
