import { defineMessages } from 'react-intl';

export default defineMessages({
    construction: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Type of Construction',
        defaultMessage: 'Type of Construction'
    },
    constructionType: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Type of Construction Description',
        defaultMessage: 'Type of Construction Description'
    },
    logType: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Type of Log',
        defaultMessage: 'Type of Log'
    },
    foundation: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Type of Foundation',
        defaultMessage: 'Type of Foundation'
    },
    roof: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Type of Roof Covering',
        defaultMessage: 'Type of Roof Covering'
    },
    divingBoard: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Is the swimming pool equipped with a diving board or slide?',
        defaultMessage: 'Is the swimming pool equipped with a diving board or slide?'
    },
    isResidenceForSale: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Is the dwelling for sale?',
        defaultMessage: 'Is the dwelling for sale?'
    },
    accessibleByVehicle: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.DPAccessibleByVehicle',
        defaultMessage: 'Is the dwelling accessible year round by vehicle?'
    },
    primaryHeating: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.What is the primary heating source for this dwelling?',
        defaultMessage: 'What is the primary heating source for this dwelling?'
    },
    plumbing: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.What plumbing type(s) are present in this dwelling?',
        defaultMessage: 'What plumbing type(s) are present in this dwelling?'
    },
    plumbingTypeDescription: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Type of Plumbing Description',
        defaultMessage: 'Type of Plumbing Description'
    },
    wiring: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.What type(s) of electrical wiring are present in this dwelling?',
        defaultMessage: 'What type(s) of electrical wiring are present in this dwelling?'
    },
    electricalType: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.electricalType',
        defaultMessage: 'What type of electrical system is present in this dwelling?'
    },
    residentHaveCurrentInspection: {
        id: "wni.quoteandbind.dp-wizard.dp-residence.residentHaveCurrentInspection",
        defaultMessage: "Does the dwelling to be insured by this policy have a current inspection?"
    },
    viewOrEditAnimalDetail: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.viewOrEditAnimalDetail',
        defaultMessage: 'View/Edit Details'
    },
    viewAnimalDetail: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.viewAnimalDetail',
        defaultMessage: 'View Details'
    },
    viewOrEditDetail: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.viewOrEditDetail',
        defaultMessage: 'View/Edit Details'
    },
    viewDetail: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.viewDetail',
        defaultMessage: 'View Details'
    },
    replacementCostLabel: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Estimated Replacement Cost',
        defaultMessage: 'Estimated Replacement Cost'
    },
    yearBuilt: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Year Built',
        defaultMessage: 'Year Built'
    },
    numberofStories: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Number of Stories',
        defaultMessage: 'Number of Stories'
    },
    finishedLivingArea: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Finished Living Area - Above ground',
        defaultMessage: 'Finished Living Area - Above ground'
    },
    basementFinished: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Percentage of Basement Finished',
        defaultMessage: 'Percentage of Basement Finished'
    },
    exteriorWallCovering: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Primary Exterior Wall Covering',
        defaultMessage: 'Primary Exterior Wall Covering'
    },
    garage: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Garage Type',
        defaultMessage: 'Garage Type'
    },
    secondaryHeating: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.secondaryHeating',
        defaultMessage: 'What is the secondary heating source type for this residence?'
    },
    solidFuelBurningDevice: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.solidFuelBurningDevice',
        defaultMessage: 'What type of solid fuel burning device is located on premise?'
    },
    ampService: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Amp Service',
        defaultMessage: 'Amp Service'
    },
    numberofBathrooms: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Number of Bathrooms',
        defaultMessage: 'Number of Bathrooms'
    },
    numberofStalls: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Number of Stalls',
        defaultMessage: 'Number of Stalls'
    },
    swimmingPoolType: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Is there a swimming pool on premise?',
        defaultMessage: 'Is there a swimming pool on premise?'
    },
    burglarAlarm: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Burglar Alarm',
        defaultMessage: 'Burglar Alarm'
    },
    fireAlarm: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Fire Alarm',
        defaultMessage: 'Fire Alarm'
    },
    homeMonitoringSystems: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.Home Monitoring System(s)',
        defaultMessage: 'Home Monitoring System(s)'
    },
    isResidenceHaveCentralHeat: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.isResidenceHaveCentralHeat',
        defaultMessage: 'Does the dwelling have central heat?'
    },
    primaryHeatingType: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.primaryHeatingType',
        defaultMessage: 'How is the central heating system powered?'
    },
    anySolidFuelBurningDevice: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.anySolidFuelBurningDevice',
        defaultMessage: 'Are there any solid fuel burner devices located on the premises?'
    },
    isAnyKnobAndTubeWiring: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.isAnyKnobAndTubeWiring',
        defaultMessage: 'Is there any knob and tube wiring present in this dwelling?'
    },
    BL0328WarningMessage: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.BL0328WarningMessage',
        defaultMessage: 'The valuation method can only be Functional Replacement Cost when the construction type is Standard.'
    },
    BL0329WarningMessage: {
        id: 'wni.quoteandbind.dp-wizard.dp-residence.BL0329WarningMessage',
        defaultMessage: 'The valuation method cannot be Functional Replacement Cost when the construction type is not Standard.'
    },
    structureOriginallyBuilt: {
        id: 'wni.quoteandbind.ho-wizard.dp-residence.structureOriginallyBuilt',
        defaultMessage: 'Has this structure ever functioned as anything other than a dwelling?'
    },
})