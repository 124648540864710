import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PolicyContactsPage from '../../Contacts/PolicyContacts/PolicyContactsPage';
import AccountContactsPage from '../../Contacts/AccountContacts/AccountContactsPage';
import AssociatedContactsPage from '../../Contacts/AssociatedContacts/AssociatedContactsPage';

import metadata from './PoliciesContacts.metadata.json5';
import styles from './PoliciesContacts.module.scss';
import messages from './PoliciesContacts.messages';

function PoliciesContacts(props) {
    const {
        fromAccountLanding,
        location: { state },
        history
    } = props;
    const translator = useTranslator();

    const [selectedTab, updateSelectedTab] = ['policyContactsTab']

    const { policyDetailsData: { policyResponse } = {} } = fromAccountLanding
    const { basePath } = fromAccountLanding;
    const {
        account: {
            accountNumber,
            accountHolder: {
                displayName,
                subtype
            } = {}
        } = {},
        policyNumber,
        numberOfContacts
    } = policyResponse;

    useEffect(() => {
        if (state && state.selectTab && _.isFunction(updateSelectedTab)) {
            updateSelectedTab(state.selectTab);
        }
    }, [state, updateSelectedTab]);

    const updatePolicyContactsHeading = () => {
        const contactsCount = basePath.includes('policies') ? `(${numberOfContacts})` : '';
        return translator(messages.policyContactsCount, { policyContactsCount: contactsCount });
    }

    const overrides = {
        policyContacts: {
            policyNumber: policyNumber,
            history
        },
        accountContacts: {
            accountNumber: accountNumber,
            relatedTo: 'policies',
            history,
            fromAccountLanding
        },
        associatedContacts: {
            accountName: displayName,
            accountNumber: accountNumber,
            relatedTo: 'policies',
            history
        },
        policyContactsTab: {
            heading: updatePolicyContactsHeading()
        },
        contactsTabSetContainer: {
            defaultActiveTab: selectedTab
        },
        accountContactsContainer: {
            visible: subtype !== 'Person'
        },
        associatedContactsContainer: {
            visible: subtype !== 'Person'
        }
    }

    const resolvers = {
        resolveComponentMap: {
            policycontactspage: PolicyContactsPage,
            accountcontactspage: AccountContactsPage,
            associatedcontactspage: AssociatedContactsPage
        },
        resolveCallbackMap: {
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyResponse}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PoliciesContacts.propTypes = {
};

export const SummaryComponent = PoliciesContacts;

export default PoliciesContacts;
