import React, {
    useEffect,
    useState,
    useContext,
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { NumberValue, CurrencyValue } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import metadata from './HOClauseExtension.metadata.json5';
import HOClauseExtensionUtil from '../../util/HOClauseExtensionUtil';
import OtherStructuresDetails from './OtherStructureDetails/OtherStructureDetails';
import messages from './HOClauseExtension.messages'

import { Button } from '@jutro/legacy/components';

function HOClauseExtension(props) {
    const modalApi = useWniModal();
    const {
        isEditable,
        hoprivateStructures = [],
        path,
        onValueChangeWithoutSync,
        onValueChangeWithSync,
        clausePatternCode,
        showScheduleDetailsInPanelBelow,
        openedRowItemNumber,
        setOpenedRowItemNumber,
        selectedRowItemIndex,
        setSelectedRowItemIndex,
        showErrors,
        setIsEditing,
        coveragesConfig
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const structureBelow = hoprivateStructures
        .find((hoprivateStructure) => _.get(hoprivateStructure, 'structureNum') === openedRowItemNumber)

    const getCell = (items, index, property) => {
        return items[property.id] || '-';
    };

    const getSquareFootageNumber = (items, index, property) => {
        return  (
            <NumberValue
                id="squareFootageNumber"
                value={items[property.id]}
                readOnly
                hideLabel
                showOptional={false}
            />
        )
    };

    const getTypeKeyItemCell = (structure, index, property) => {
        const structureVM = viewModelService.create(
            structure,
            'pc',
            'wni.edge.capabilities.policycommon.coverage.dto.hop.HOPrivatestructureDTO_Ext'
        );
        const typeKeyCode = _.get(structure, property.id)
        const availableValues = _.get(structureVM, `${property.id}.aspects.availableValues`, [])
        const typeKeyOption = availableValues.find((option) => _.get(option, 'code') === typeKeyCode);
        const typekeyName = _.get(typeKeyOption, 'name');
        return typekeyName ? translator({ id: typekeyName }) : '-';
    };

    const getStructureValueCell = (item) => {
        return  (
            <CurrencyValue
                id="structureValueCurrency"
                value={_.get(item, 'structureValue.amount')}
                readOnly
                hideLabel
                showOptional={false}
            />
        )
    };


    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'otherStructuresTable');
    };

    useEffect(() => {
        highlightRowFn(structureBelow);
    }, [structureBelow]);

    const getActionCell = (item, index) => {
        const onShowStructureDetailsBelowHandleEdit = () => {
            setOpenedRowItemNumber(_.get(item, 'structureNum'))
        }

        const onEditClick = () => {
            // if (showScheduleDetailsInPanelBelow) {
            onShowStructureDetailsBelowHandleEdit()
            // } else {
            //     onShowStructureDetailsInPopupHandleEdit()
            // }
        }

        return (
            <div className="align-right">
                <Button
                    className="wni-button-icon-border"
                    icon="gw-edit"
                    type="outlined"
                    size="small"
                    onClick={() => onEditClick()}
                />
            </div>
        )
    }

    const onSelectionChange = (ids) => {
        setSelectedRowItemIndex(ids)
    }

    const onRemoveStructure = () => {
        modalApi.showConfirm({
            title: messages.deleteMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.confirm,
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            const selectedStructurePublicIDs = selectedRowItemIndex.map((index) => {
                const selectedStructure = hoprivateStructures[index]
                return _.get(selectedStructure, 'publicID')
            })
            const newHoprivateStructures = hoprivateStructures
                .filter((privateStructure) => !selectedStructurePublicIDs.includes(_.get(privateStructure, 'publicID')))
            setOpenedRowItemNumber(null)
            setSelectedRowItemIndex([])
            onValueChangeWithSync(newHoprivateStructures, `${path}.hoprivateStructures`);
        }, _.noop);        
    }

    const onShowDetailsInPanelBelowHandleAdd = () => {
        const termsForNewStructure = HOClauseExtensionUtil.getTermsForNewStructure(clausePatternCode);
        const newHoprivateStructures = hoprivateStructures.concat([{terms: termsForNewStructure}]);
        onValueChangeWithSync(newHoprivateStructures, `${path}.hoprivateStructures`);
    }

    const onAddStructure = () => {
        onShowDetailsInPanelBelowHandleAdd()
    }

    const columnData = hoprivateStructures.map((hoprivateStructure) => {
        return {
            ...hoprivateStructure,
            actionColumn: 'actionColumn'
        }
    })

    const handleStructureInBelowChange = (changedStructure) => {
        let newHoprivateStructures = _.clone(hoprivateStructures)
        let oldHopPrivateStructure = null
        newHoprivateStructures = newHoprivateStructures.map((hoprivateStructureInArray) => {
            if (_.get(hoprivateStructureInArray, 'structureNum') === openedRowItemNumber) {
                oldHopPrivateStructure = hoprivateStructureInArray
                return changedStructure
            }
            return hoprivateStructureInArray
        })
        if (!_.isNil(oldHopPrivateStructure)) {
            // keys should be a union of new private structure and oly private structuew
            const keysOfOldHopPrivateStructure = _.keys(oldHopPrivateStructure)
            const keysOfChangedStructure = _.keys(changedStructure)

            const changedFieldList = _.union(keysOfOldHopPrivateStructure, keysOfChangedStructure)
                .filter((key) => {
                    const oldValue = _.get(oldHopPrivateStructure, key)
                    const newValue = _.get(changedStructure, key)
                    return oldValue !== newValue
                })
            const allChangesNotNeedSync = changedFieldList.every((field) => {
                return HOClauseExtensionUtil.structureFieldsNotNeedImmediatelySync.includes(field)
            })
            if (allChangesNotNeedSync) {
                onValueChangeWithoutSync(newHoprivateStructures, `${path}.hoprivateStructures`)
                return
            }
        }
        onValueChangeWithSync(newHoprivateStructures, `${path}.hoprivateStructures`);
    }

    const overrideProps = {
        '@field': {
            isEditable,
            showErrors,
        },
        removeStructure: {
            visible: isEditable,
            disabled: _.get(selectedRowItemIndex, 'length', 0) <= 0,
            onClick: onRemoveStructure,
        },
        addStructure: {
            visible: isEditable,
            onClick: onAddStructure,
        },
        otherStructuresTable: {
            data: columnData,
            onSelectionChange,
            selectionType: isEditable ? 'multi' : 'none',
        },
        otherStructuresDetails: {
            visible: showScheduleDetailsInPanelBelow && !_.isNil(openedRowItemNumber),
            structure: structureBelow,
            handleStructureChange: handleStructureInBelowChange,
            isEditable,
            showErrors,
            setIsEditing,
            coveragesConfig,
        },
        structureNum: {
            renderCell: (item, index, property) => {
                return WniTableRowUtil.renderCell(
                    item.publicID,
                    item[property.id] || '-'
                );
            }
        }
    }


    const resolvers = {
        resolveCallbackMap: {
            getCell,
            getTypeKeyItemCell,
            getStructureValueCell,
            getSquareFootageNumber,
            // getActionHeader,
            getActionCell,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
        },
        resolveComponentMap: {
            OtherStructuresDetails: OtherStructuresDetails
        }
    }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default HOClauseExtension;