import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'gateway.views.activities-landing.Activities',
        defaultMessage: 'Activities'
    },
    advanceFilter: {
        id: 'gateway.views.activities-landing.Advanced Filter',
        defaultMessage: 'Advanced Filter'
    },
    allOpen: {
        id: 'gateway.views.activities-landing.All Open',
        defaultMessage: 'All Open'
    },
    createdByYou: {
        id: 'gateway.views.activities-landing.Created By You',
        defaultMessage: 'Created By You'
    },
    allCompleted: {
        id: 'gateway.views.activities-landing.All Completed',
        defaultMessage: 'All Completed'
    },
    allCanceled: {
        id: 'gateway.views.activities-landing.All Canceled',
        defaultMessage: 'All Canceled'
    },
    yourComplete: {
        id: 'gateway.views.activities-landing.Your Completed',
        defaultMessage: 'Your Completed'
    },
    yourOpen: {
        id: 'gateway.views.activities-landing.Your Open',
        defaultMessage: 'Your Open'
    },
    note: {
        id: 'gateway.directives.activity-view-summary.Note',
        defaultMessage: 'Note'
    },
    noActivitiesFound: {
        id: 'gateway.directives.activity-schedule.No Activities found.',
        defaultMessage: 'No Activities found.'
    },
    dueAfterThisWeek: {
        id: 'gateway.directives.activity-schedule.Due after this week',
        defaultMessage: 'DUE AFTER THIS WEEK'
    },
    overdue: {
        id: 'gateway.directives.activity-schedule.Overdue',
        defaultMessage: 'OVERDUE'
    },
    dueToday: {
        id: 'gateway.directives.activity-schedule.Due Today',
        defaultMessage: 'DUE TODAY'
    },
    dueTomorrow: {
        id: 'gateway.directives.activity-schedule.Due Tomorrow',
        defaultMessage: 'DUE TOMORROW'
    },
    dueThisWeek: {
        id: 'gateway.directives.activity-schedule.Due this week',
        defaultMessage: 'DUE THIS WEEK'
    },
    completedActivity: {
        id: 'gateway.directives.activity-schedule.Completed',
        defaultMessage: 'Completed',
    }
});
