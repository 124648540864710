

function getChangePageJumpList(getPageJumpAction) {
    return  [{
        name: 'Policy Details',
        action: getPageJumpAction('WALPolicyDetailsPage'),
    }, {
        name: 'Watercrafts',
        action: getPageJumpAction('WALWatercraftPage'),
    }, {
        name: 'Operators',
        action: getPageJumpAction('WALOperatorPage'),
    }, {
        name: 'Coverages',
        action: getPageJumpAction('WALCoveragesPage'),
    }, {
        name: 'Incidents & Reports',
        action: getPageJumpAction('WALIncidentsAndReportsPage'),
    }];
}

export default {
    getChangePageJumpList,
};
