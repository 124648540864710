import { defineMessages } from 'react-intl';

export default defineMessages({
    norewriteDocuments: {
        id: 'gateway.directives.gateway-documents.There are no documents associated with this rewrite',
        defaultMessage: 'There are no documents associated with this rewrite',
    },
    warningUploadTitle: {
        id: 'custom.gateway.views.rewrite-detail.warningUploadTitle',
        defaultMessage: 'Warning',
    },
    uploadFailedMessageUIC: {
        id: 'custom.gateway.views.rewrite-detail.uploadFailedMessageUIC',
        defaultMessage: 'The “Upload Documents” function is temporarily unavailable. In the meantime, please email any documents as attachments to plund@umialik.com and include your account number in the subject line'
    },
    uploadFailedMessage: {
        id: 'custom.gateway.views.rewrite-detail.uploadFailedMessage',
        defaultMessage: 'The “Upload Documents” function is temporarily unavailable. In the meantime, please email any documents as attachments to plund@wnins.com and include your account number in the subject line'
    }
});
