import React, {
    Component,
    useEffect,
    useState,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
// import { withValidation, validationPropTypes, useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import BaseSingleClauseComponentVM from './Base/BaseSingleClauseComponentVM';

/**
 * To be refactored
 * @param {object} props
 * @returns {object}
 */
function CustomEquipmentSingleClauseComponentVM(props) {
    const {
        value: clause = {},
        idPrefix,
        customEquipTerms,
        withoutCustomEquipmentCoverage
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    // from ClauseComponent.jsx
    const getLabelNameWithAmount = (labelName, labelAmount) => {
        if (!_.isUndefined(labelAmount)) {
            const formatterCurrency = intl.formatNumber(
                labelAmount.amount,
                {
                    style: 'currency',
                    currency: labelAmount.currency,
                    currencyDisplay: 'symbol'
                }
            );
            return `${labelName} ${formatterCurrency}`;
        }
        return labelName;
    };

    const generatecustomEquipTermsMetaData = () => {
        if (!customEquipTerms) {
            return [];
        }
        const retval = customEquipTerms.map((customEquipTerm) => {
            // from QuoteCoveragesUtil.filterVehicleCoverageSets()
            const {
                customEquipmentEndorsementCovDisplayValue,
                coverageLabel,
            } = customEquipTerm;

            //
            let vehCovLabel = coverageLabel;
            if (vehCovLabel === 'Additional Custom Equipment' && !_.isEmpty(clause.amount)) {
                vehCovLabel = getLabelNameWithAmount(vehCovLabel, clause.amount);
            }

            return {
                id: coverageLabel,
                type: 'field',
                component: 'Currency',
                componentProps: {
                    label: vehCovLabel,
                    readOnly: true,
                    visible: customEquipmentEndorsementCovDisplayValue !== 0,
                    value: {
                        amount: customEquipmentEndorsementCovDisplayValue,
                        currency: 'USD',
                    }
                }
            };
        });
        return retval;
    };

    /**
     * Generate metadata from value provided from props
     * @returns {object} the metadata to render
     */
    const generateMetadata = ({
        clauseComponentProps: clauseComponentPropsParam
    }) => {
        const customEqupTermsMetaData = generatecustomEquipTermsMetaData();
        const clauseComponentProps = {
            ...clauseComponentPropsParam,
            showAmount: false,
            isLimitRequired: false,
            expectCoverageTermComponentType: clause.expectCoverageTermComponentType_Ext,
            loadingIconType: undefined, // ?
            clausePatternCode: undefined, // ?
        };

        if (withoutCustomEquipmentCoverage) {
            // const signatureDisplayName = _.isEmpty(clause.amount) ? ' ' : displayName;
            clauseComponentProps.isEditable = false;
            clauseComponentProps.displayName = ' ';
            clauseComponentProps.onValueChange = _.noop;
            clauseComponentProps.isLimitRequired = false;
        }
        const clauseComponentContent = [{
            id: `${idPrefix}Clause_[${clause.publicID}]`,
            type: 'field',
            component: 'ClauseComponent', // it is the one in gw package
            componentProps: clauseComponentProps,
            content: null
        }];

        // =======================
        return {
            content: [
                ...clauseComponentContent,
                ...customEqupTermsMetaData,
            ],
        };
    };

    // ===================================
    // return MetadataContent(generateMetadata(), overrides, resolvers);
    return (
        <BaseSingleClauseComponentVM
            {...props}
            getSingleClauseMetadata={generateMetadata}
        />
    );
}

CustomEquipmentSingleClauseComponentVM.propTypes = {
    ...BaseSingleClauseComponentVM.propTyps,
    customEquipTerms: PropTypes.arrayOf(PropTypes.shape({
        customEquipmentEndorsementCovDisplayValue: PropTypes.number,
        coverageLabel: PropTypes.string,
    })),
    withoutCustomEquipmentCoverage: PropTypes.bool,
};

CustomEquipmentSingleClauseComponentVM.defaultProps = {
    ...BaseSingleClauseComponentVM.defaultProps,
    customEquipTerms: undefined,
    withoutCustomEquipmentCoverage: false,
};


export default CustomEquipmentSingleClauseComponentVM;
