import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';


import { termFormatter } from './GLPolicyLineSummaryUtil';
import GLJurisdictionsComponent from './GLJurisdictionsComponent';
/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function GLSublineLineSummaryComponent(props) {

    const {
        subline, // GLGeneralLiabilitySummaryDTO;
    } = props;

    if (_.isEmpty(subline)) {
        return null;
    }

    //
    const {
        
        title,
        //
        lineLevelCoverages,
        coverages,
        additionalCoverages,
        exclusions,
        conditions,
        additionalInsureds,
    } = subline;


    return (
        <div>
            <h4>{title}</h4>
            <DisplayableSummaryComponent title="Details" displayables={lineLevelCoverages} />
            <CoverageSummaryComponent title="General Liability Coverages" clauses={coverages} termFormatter={termFormatter} />
            <CoverageSummaryComponent title="Additional Coverages" clauses={additionalCoverages} termFormatter={termFormatter} />
            <CoverageSummaryComponent title="Exclusions" clauses={exclusions} termFormatter={termFormatter} />
            <CoverageSummaryComponent title="Conditions" clauses={conditions} termFormatter={termFormatter} />
            <CoverageSummaryComponent title="Additional Insured" clauses={additionalInsureds} termFormatter={termFormatter} />
        </div>
    );
    
}

GLSublineLineSummaryComponent.propTypes = {
    
};

GLSublineLineSummaryComponent.defaultProps = {
    
};


export default GLSublineLineSummaryComponent;