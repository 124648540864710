import { defineMessages } from 'react-intl';

export default defineMessages({
    OOSSliceDatesPopupMessage: {
        id: 'customer.ho.wizard.step.policychange.warning.OOSSliceDates',
        defaultMessage: 'Your policy change is an out-of-sequence transaction. There are future transaction(s) effective { OOSSliceDates }. Are you sure you want to continue?'
    },
    // policyIsCancelled: {
    //     id: 'customer.pa.wizard.step.policychange.warning.policyIsCancelled',
    //     defaultMessage: 'This policy is canceled as of { cancellationDate }'
    // },
    // wizardPageJumpTitle: {
    //     id: 'customer.pa.wizard.step.policychange.Select a link below to jump directly to',
    //     defaultMessage: 'Select a link below to jump directly to:',
    // },
    // wizardPageItemPrefix: {
    //     id: 'customer.pa.wizard.step.policychange.Change ',
    //     defaultMessage: 'Change ',
    // },
});
