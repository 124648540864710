
// import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';
import ActiveQuoteUtil from './ActiveQuoteUtil';

function getQuotePageHeader({
    wizardData: wizardSubmission,
    wizardPageData: wizardPageDataParam,
    //
    lobDataPath: LOB_DATA_PATH,
}) {
    // const selectedTypeAndBranch = QuoteUtil.getDefaultTypeAndBranch(wizardSubmission, 'watercraft');
    // See BaseWizardPageHeader for more info
    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            [LOB_DATA_PATH]: {
                offerings,
            },
        },
        jobID,
    } = wizardSubmission.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageDataParam;

    const accountJobPeriodDTO = ActiveQuoteUtil.findAccountJobPeriodDTO(accountActiveQuotes, jobID, selectedVersionPublicID);

    if (!accountJobPeriodDTO) {
        return {};
    }

    const {
        branchName: selectedBranchName,
        branchCode: selectedBranchCode,
        // policyType_Ext: selectedPolicyType,
    } = accountJobPeriodDTO;

    // const policyTypeDisplayName = selectedPolicyType;
    let jobStatusInfo; // = 'Signature'; // Is this really needed? See ActiveQuotesDropdownComponent
    let versionName = ''; //
    const hasMultiplePeriods = ActiveQuoteUtil.hasMultiplePeriodsForJob(accountActiveQuotes, jobID);
    if (hasMultiplePeriods && selectedBranchName !== 'CUSTOM') {
        versionName = selectedBranchName;
        jobStatusInfo = versionName; // (`${policyTypeDisplayName} ${versionName}`);
    }

    return {
        jobStatusInfo,
        // // policyTypeDisplayName,
    };
}


function getPolicyChangeHeader({
    wizardData: wizardSubmission,
    wizardPageData: wizardPageDataParam,
    //
    // lobDataPath: LOB_DATA_PATH,
    lobWithQuoteNumber,
}) {
    const {
        jobID,
        baseData: {
            periodStatus: endorsementStatusInfo,
            selectedVersion_Ext: selectedVersionPublicID,
        },
        changeData: {
            policyNumber: policyNumberParam,
        },
        // lobData: {
        // },
    } = wizardSubmission.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageDataParam;

    const accountJobPeriodDTO = ActiveQuoteUtil.findAccountJobPeriodDTO(accountActiveQuotes,
        jobID, selectedVersionPublicID);

    if (!accountJobPeriodDTO) {
        return {};
    }
    
    // const lobWithQuoteNumber = translator(messages.watercraftPolicyChange, {
    //     policyNumber: policyNumberParam
    // });

    const {
        branchName: selectedBranchName,
        // branchCode: offeringType,
        // policyType_Ext: offeringPolicyType,
    } = accountJobPeriodDTO;

    let versionName = '';

    const hasMultiplePeriods = ActiveQuoteUtil.hasMultiplePeriodsForJob(accountActiveQuotes, jobID);
    if (hasMultiplePeriods && selectedBranchName !== 'CUSTOM') {
        versionName = selectedBranchName;
    }

    // const offeringPolicyTypeName = (`${offeringPolicyType} ${versionName}`);  
    const offeringPolicyTypeName = versionName;  
    
    // onider to extract the following logic into a util method
    // so that it could be re-used with HOSubmissionWizard.wizardPageHeaderFormatter()
    // const endorsementStatusInfo = _.get(wizardSubmission.value,'baseData.periodStatus');
    let jobStatusInfo = endorsementStatusInfo;
    if (jobStatusInfo === 'Approved_Ext') {
        jobStatusInfo = 'Approved';
    }

    return {
        productWithQuoteNumber: lobWithQuoteNumber,
        policyTypeDisplayName: offeringPolicyTypeName,
        jobStatusInfo: jobStatusInfo,
        isDisplayPolicyType: true,
        isDisplayJobType: true,
    };
}

export default {
    getQuotePageHeader,
    getPolicyChangeHeader,

};
