import { defineMessages } from 'react-intl';

export default defineMessages({
    submissionAgency: {
        id: 'wni.gateway.views.new-quote.Agency',
        defaultMessage: 'Agency',
    },
    issued: {
        id: 'wni.gateway.views.new-quote.Issued',
        defaultMessage: 'Issued',
    },
    notTaken: {
        id: 'wni.gateway.views.new-quote.Not Taken',
        defaultMessage: 'Not Taken',
    },
    inactive: {
        id: 'wni.gateway.views.new-quote.Inactive',
        defaultMessage: 'Inactive',
    },
    accountHolder: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Account Holder.Title',
        defaultMessage: 'Account Holder',
    },
    additionalInsured: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Insured.Title',
        defaultMessage: 'Additional Insured Types',
    },
    additionalInterest: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Interest.Title',
        defaultMessage: 'Additional Interest',
    },
    detail: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Title',
        defaultMessage: 'Additional Interest Details: {subtype}',
    },
    additionalInterestCertificateRequired: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Certificate Required',
        defaultMessage: 'Certificate Required',
    },
    additionalInteresContractNumber: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Contract Number',
        defaultMessage: 'Contract Number',
    },
    additionalInterestInterest: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Interest',
        defaultMessage: 'Interest',
    },
    additionalInterestType: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Type',
        defaultMessage: 'Type',
    },
    namedInsured: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Named Insured.Title',
        defaultMessage: 'Named Insured',
    },
    namedInsuredIndustryCode: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Named Insured.Industry Code',
        defaultMessage: 'Industry Code',
    },
    namedInsuredIndustryCodeClassification: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Named Insured.Industry Code Classification',
        defaultMessage: 'Classification',
    },
    namedInsuredOrganizationType: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Named Insured.Organization Type',
        defaultMessage: 'Organization Type',
    },
    namedInsuredBusOpDescription: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Named Insured.Business Operation Description',
        defaultMessage: 'Business Operation Description',
    },
    driver: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Title',
        defaultMessage: 'Driver',
    },
    driverAccountAccidents: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Account.Accidents',
        defaultMessage: 'Account Level : Number of Accidents',
    },
    driverPolicyLevelNumberOfAccidents: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Policy.Accidents',
        defaultMessage: 'Policy Level : Number of Accidents',
    },
    driverAccountViolations: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Account.Violations',
        defaultMessage: 'Account Level : Number of Violations',
    },
    driverPolicyLevelNumberOfViolations: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Policy.Violations',
        defaultMessage: 'Policy Level : Number of Violations',
    },
    driverLicenseNumber: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.License Number',
        defaultMessage: 'License Number',
    },
    driverLicenseState: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.License State',
        defaultMessage: 'License State',
    },
    driverYearLicensed: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Year Licensed',
        defaultMessage: 'Year First Licensed',
    },
    driverDateCompletedTrainingClass: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Date Completed Training Class',
        defaultMessage: 'Date Completed Training Class',
    },
    driverTrainingClassType: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Training Class Type',
        defaultMessage: 'Training Class Type',
    },
    driverGoodStudent: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Good Student',
        defaultMessage: 'Good Student',
    },
    driverExcluded: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Excluded',
        defaultMessage: 'Excluded?',
    },
    driverApplicableGoodDriverDiscount: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Apply Good Driver Discount',
        defaultMessage: 'Apply Good Driver Discount?',
    },
    driverGoodDriverDiscount: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Qualifies Driver Discount',
        defaultMessage: 'Qualifies for a Good Driver Discount',
    },
    yes: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.Yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Driver.No',
        defaultMessage: 'No',
    },
    laborClient: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Title',
        defaultMessage: 'WC Labor Client',
    },
    laborClientDetail: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Detail',
        defaultMessage: 'WC Labor Client Detail: {description}',
    },
    laborClientContractEffectiveDate: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Contract Effective Date',
        defaultMessage: 'Contract Effective Date',
    },
    laborClientContractExpirationDate: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Contract Expiration Date',
        defaultMessage: 'Contract Expiration Date',
    },
    laborClientEmployees: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Employees',
        defaultMessage: 'Number of Employees',
    },
    laborClientInclusion: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Inclusion',
        defaultMessage: 'Inclusion',
    },
    laborClientLocation: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.Location',
        defaultMessage: 'Work Location',
    },
    laborClientState: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Client.State',
        defaultMessage: 'State',
    },
    laborContractor: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Title',
        defaultMessage: 'WC Labor Contractor',
    },
    laborContractorDetail: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Detail',
        defaultMessage: 'WC Labor Contractor Detail: {description}',
    },
    laborContractorContractEffectiveDate: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Contract Effective Date',
        defaultMessage: 'Contract Effective Date',
    },
    laborContractorContractExpirationDate: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Contract Expiration Date',
        defaultMessage: 'Contract Expiration Date',
    },
    laborContractorEmployees: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Employees',
        defaultMessage: 'Number of Employees',
    },
    laborContractorInclusion: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Inclusion',
        defaultMessage: 'Inclusion',
    },
    laborContractorLocation: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Location',
        defaultMessage: 'Work Location',
    },
    laborContractorState: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Labor Contractor.State',
        defaultMessage: 'State',
    },
    ownerOfficer: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.Title',
        defaultMessage: 'Owner/Officer',
    },
    ownerOfficerClassCode: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.Class Code',
        defaultMessage: 'Class Code',
    },
    ownerOfficerInclusion: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.Inclusion',
        defaultMessage: 'Inclusion',
    },
    ownerOfficerOwnershipPercentage: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.Ownership Percentage',
        defaultMessage: 'Ownership Percentage',
    },
    ownerOfficerPolicyRelationship: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.Policy Relationship',
        defaultMessage: 'Policy Relationship',
    },
    ownerOfficerRelationshipTitle: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.Relationship Title',
        defaultMessage: 'Title/Relationship',
    },
    ownerOfficerState: {
        id: 'wni.gateway.contacts.components.ContactDetails.Roles.Owner Officer.State',
        defaultMessage: 'State',
    },
    claimsInfoContact: {
        id: 'wni.gateway.contacts.common.views.roles.ClaimsInfoContact',
        defaultMessage: 'Claims Info Contact',
    },
    billingContact: {
        id: 'wni.gateway.contacts.common.views.roles.BillingContact',
        defaultMessage: 'Billing Contact',
    },
    accountingContact: {
        id: 'wni.gateway.contacts.common.views.roles.AccountingContact',
        defaultMessage: 'Accounting Contact',
    },
    inspectionContact: {
        id: 'wni.gateway.contacts.common.views.roles.InspectionContact',
        defaultMessage: 'Inspection Contact',
    },
    secondaryContact: {
        id: 'wni.gateway.contacts.common.views.roles.SecondaryContact',
        defaultMessage: 'Secondary Contact',
    },
    policyPriNamedInsured: {
        id: 'wni.gateway.contacts.common.views.roles.Primary Named Insured',
        defaultMessage: 'Primary Named Insured',
    },
    dBARole_Ext: {
        id: 'wni.gateway.contacts.common.views.roles.DBA',
        defaultMessage: 'DBA'
    },
    accountContactsTitle: {
        id: 'wni.gateway.contacts.title.Account Contacts',
        defaultMessage: 'Account Contacts',
    },
    policyContactsTitle: {
        id: 'wni.gateway.contacts.title.Policy Contacts',
        defaultMessage: 'Policy Contacts',
    },
    associatedContactsTitle: {
        id: 'wni.gateway.contacts.title.Associated Contacts',
        defaultMessage: 'Associated Contacts',
    },
});
