import { Button } from '@jutro/components';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { WniCheckboxField, WniDataTable, WniInputNumber, WniInputText, WniTextArea } from 'wni-common-base-components';
import messages from './ValuablePaperSchedule.messages';

const TableCellInputDesc = (props) => {
    const {
        scheduleItem,
        updateSheduleItem,
        onValidate: onValuablePaperFormValidate,
        showErrors,
    } = props

    const {
        scheduleItemNumber,
        description
    } = scheduleItem
    const validationID = `valuablePaper_schedule_${scheduleItemNumber}_Desc`;

    const { onValidate, isComponentValid } = useValidation(validationID);

    useEffect(() => {
        if (onValuablePaperFormValidate) {
            onValuablePaperFormValidate(isComponentValid, validationID);
        }
        return () => {
            if (onValuablePaperFormValidate) {
                onValuablePaperFormValidate(true, validationID);
            }
        }
    }, [isComponentValid, onValuablePaperFormValidate, validationID])

    return <WniTextArea
        id="description"
        value={description}
        onValueChange={(value) => {
            const updatedItem = {...scheduleItem, description: value}
            updateSheduleItem(updatedItem)
        }}
        required
        showErrors={showErrors}
        onValidationChange={onValidate}
    />
}

const TableCellInputLimit = (props) => {
    const {
        scheduleItem,
        updateSheduleItem,
        onValidate: onValuablePaperFormValidate,
        showErrors,
    } = props

    const {
        scheduleItemNumber,
        limit
    } = scheduleItem
    const validationID = `valuablePaper_schedule_${scheduleItemNumber}_Limit`;

    const { onValidate, isComponentValid } = useValidation(validationID);

    useEffect(() => {
        if (onValuablePaperFormValidate) {
            onValuablePaperFormValidate(isComponentValid, validationID);
        }
        return () => {
            if (onValuablePaperFormValidate) {
                onValuablePaperFormValidate(true, validationID);
            }
        }
    }, [isComponentValid, onValuablePaperFormValidate, validationID])

    return <WniInputNumber
            id="limit"
            value={limit}
            onValueChange={(value) => {
                const updatedItem = {...scheduleItem, limit: value}
                updateSheduleItem(updatedItem)
            }}
            required
            showErrors={showErrors}
            onValidationChange={onValidate}
            showFractions={false}
        />
}

const ValuablePaperSchedule = (props) => {
    const translator = useTranslator();

    const {
        scheduleItems,
        setScheduleItems,
        isEditable,
        onValidate,
        showErrors,
    } = props;

    const [selectedScheduledItemNumbers, setSelectedScheduledItemNumbers] =
        useState([]);

    const delScheduledItems = () => {
        const newScheduleItems = scheduleItems.filter(
            (item) => !selectedScheduledItemNumbers.includes(item.scheduleNumber)
        );
        setScheduleItems(newScheduleItems);
    };

    const addScheduledItem = () => {
        const lastScheduleItem = _.maxBy(scheduleItems, 'scheduleNumber')
        const newScheduleNumber = _.get(lastScheduleItem, 'scheduleNumber', 0) + 1
        const newScheduleItems = scheduleItems.concat([
            {
                scheduleNumber: newScheduleNumber,
            },
        ]);
        setScheduleItems(newScheduleItems);
    };

    const updateSheduleItem = (updatedItem) => {
        const newScheduleItems = scheduleItems.map(item => {
            if (item.scheduleNumber === updatedItem.scheduleNumber) {
                return updatedItem
            }
            return item
        })
        setScheduleItems(newScheduleItems);
    }

    return (
        <>
            <h4>{translator(messages.scheduleTitle)}</h4>

            {isEditable && (
                <Flex gap="small" justifyContent="right" className="mb-10">
                    <Button
                        className="wni-button-danger"
                        disabled={selectedScheduledItemNumbers.length === 0}
                        onClick={delScheduledItems}
                        label={messages.scheduleDel}
                    />
                    <Button icon="gw-add" onClick={addScheduledItem} label={messages.scheduleAdd} />
                </Flex>
            )}
            <WniDataTable
                id="schedule_table_IMValuablePapersScheduled"
                data={scheduleItems}
                showSearch={false}
            >
                <DisplayColumn
                    renderHeader={() => {
                        return isEditable && scheduleItems.length > 0 ? (
                            <WniCheckboxField
                                value={
                                    !scheduleItems.some(
                                        (scheduleItem) =>
                                            !selectedScheduledItemNumbers.includes(
                                                scheduleItem.scheduleNumber
                                            )
                                    )
                                }
                                label={translator(messages.itemNO)}
                                showInlineLabel
                                onValueChange={(checked) => {
                                    const newSelectedIds = checked
                                        ? scheduleItems.map(
                                              (scheduleItem) =>
                                                  scheduleItem.scheduleNumber
                                          )
                                        : [];
                                    setSelectedScheduledItemNumbers(
                                        newSelectedIds
                                    );
                                }}
                            />
                        ) : (
                            translator(messages.itemNO)
                        );
                    }}
                    sortable={false}
                    textAlign="left"
                    renderCell={(scheduleItem, index) => {
                        return isEditable ? (
                            <WniCheckboxField
                                value={selectedScheduledItemNumbers.includes(
                                    scheduleItem.scheduleNumber
                                )}
                                label={index + 1}
                                showInlineLabel
                                onValueChange={(checked) => {
                                    let newSelectedScheduleItemNumbers;
                                    if (checked) {
                                        newSelectedScheduleItemNumbers =
                                            selectedScheduledItemNumbers.concat(
                                                [scheduleItem.scheduleNumber]
                                            );
                                    } else {
                                        newSelectedScheduleItemNumbers =
                                            selectedScheduledItemNumbers.filter(
                                                (id) =>
                                                    id !==
                                                    scheduleItem.scheduleNumber
                                            );
                                    }
                                    setSelectedScheduledItemNumbers(
                                        newSelectedScheduleItemNumbers
                                    );
                                }}
                            />
                        ) : (
                            index + 1
                        );
                    }}
                />
                <DisplayColumn
                    header={translator(messages.description)}
                    sortable={false}
                    textAlign="left"
                    renderCell={(item) =>
                        isEditable ? <TableCellInputDesc
                            scheduleItem={item}
                            updateSheduleItem={updateSheduleItem}
                            onValidate={onValidate}
                            showErrors={showErrors}
                        />: item.description
                    }
                />
                <DisplayColumn
                    header={translator(messages.limit)}
                    sortable={false}
                    textAlign="left"
                    renderCell={(item) =>
                        isEditable ? <TableCellInputLimit
                            scheduleItem={item}
                            updateSheduleItem={updateSheduleItem}
                            onValidate={onValidate}
                            showErrors={showErrors}
                        />: item.description
                    }
                />
            </WniDataTable>
        </>
    );
};

export default ValuablePaperSchedule;
