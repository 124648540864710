
import React, {
    useEffect, useCallback, useState, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation, withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DriverCoveragesUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import ConstantData from '../Constant.data';
import styles from '../DriverCoveragesComponent.scss';

const DriverSingleCoverageComponent = (props) => {
    const {
        clause,
        onChangeClause,
        path,
        loadingClause,
        onValidate,
        // isComponentValid,
        showErrors,
        readOnly,
        id
        // registerComponentValidation
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const [isSelectClause, updateIsSelectClause] = useState(false);
    const [getClause, updateClause] = useState(clause);
    const translator = useTranslator();
    const [showLoading, updateShowLoading] = useState(false);

    const checkboxTooltip = DriverCoveragesUtil.getClauseCheckboxTooltip(clause.code_Ext,
        ConstantData, clause.description);
    const isTooltipVisible = (
        checkboxTooltip.text !== clause.name && !_.isEmpty(clause.description));
    const {
        registerComponentValidation,
        isComponentValid
    } = useValidation(id);

    const toggleMockData = [
        { code: 'true', name: 'Yes' },
        { code: 'false', name: 'No' }
    ];

    const isClauseLoading = useCallback((cl, loadingCl) => {
        if (_.isString(loadingCl)) {
            return loadingCl === cl.publicID;
        }

        if (_.isBoolean(loadingCl)) {
            return loadingCl;
        }

        return false;
    }, []);

    useEffect(() => {
        if (_.has(clause, 'scheduleOptions_Ext')) {
            updateIsSelectClause(true);
        }

        updateShowLoading(isClauseLoading(clause, loadingClause));
    }, [clause, isClauseLoading, loadingClause]);

    const handleChangeClause = (value, changedValuePath) => {
        if (onChangeClause) {
            return Promise.resolve(onChangeClause(value, `${path}.${changedValuePath}`, isSelectClause));
        }

        return Promise.resolve();
    };

    const getNullClause = useCallback(() => ({
            id: 'null',
            type: 'field',
            component: 'span'
        }), []);

    const getMetadataForField = useCallback((term, termPathPrefix) => {
        const hasAvailableValues = _.has(term, 'options') && !_.isEmpty(term.options);
        const valueType = _.has(term, 'valueType') && term.valueType;

        if (hasAvailableValues && valueType === 'bit') {
            return {
                component: 'Toggle',
                componentProps: {
                    path: `${termPathPrefix}.directBooleanValue`,
                    value: term.directBooleanValue,
                    readOnly
                }
            };
        }

        return {};
    }, []);

    const getTermMetaData = useCallback((clauseTerms, clausePublicID) => clauseTerms.map((term, index) => {
            const termPathPrefix = `terms[${index}]`;
            const termComponent = {
                id: `ClauseTerm_[${clausePublicID}]_[${index}]`,
                type: 'field',
                componentProps: {
                    label: term.name,
                    className: 'clause_term driver-coverages-clause',
                    required: term.required,
                    availableValues: term.options,
                    onValueChange: 'onValueChange',
                    readOnly,
                    // placeholder: term.required ? '-- Required for Quote --' : ''
                    placeholder: ''
                }
            };
            const content = _.merge(
                {}, termComponent, getMetadataForField(term, termPathPrefix)
            );

            return content;
        }), []);

    const getClauseData = useCallback((clauseData) => {
        const terms = !_.isEmpty(clauseData.terms)
            ? getTermMetaData(clauseData.terms, clauseData.publicID) : null;
        const clauseSubElements = _.merge([], terms);

        return !_.isEmpty(clauseSubElements) ? [{
            id: `ClauseElementContainer_[${clauseData.publicID}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clause_element_container', required: clauseData.required, },
            content: clauseSubElements
        }] : getNullClause();
    }, []);

    const getCheckboxClause = useCallback(() => {
        const checkboxClass = _.has(clause, 'scheduleOptions_Ext') ? 'd-inline-block mr-10' : 'd-flex';

        if (clause.valueType_Ext !== 'bit') {
            if (readOnly && !clause.selected) {
                return getNullClause();
            }

            return {
                id: 'checkboxField',
                component: 'div',
                type: 'container',
                componentProps: {
                    className: checkboxClass
                },
                content: [
                    {
                        id: 'checkbox',
                        component: 'Checkbox',
                        type: 'field',
                        componentProps: {
                            path: 'selected',
                            className: 'driver-cov-checkbox',
                            onValueChange: 'onValueChange',
                            tooltip: isTooltipVisible ? checkboxTooltip : {},
                            label: _.has(clause, 'scheduleOptions_Ext') ? '' : translator(clause.name),
                            readOnly
                        }
                    }
                ]
            };
        }

        return getNullClause();
    }, [clause.name, clause.selected, clause.valueType_Ext, getNullClause, translator]);

    const getDropdownSelect = useCallback(() => {
        if (_.has(clause, 'scheduleOptions_Ext')) {
            if (readOnly && !clause.selected) {
                return getNullClause();
            }

            return {
                id: 'dropdownSelect',
                type: 'field',
                component: 'dropdownSelect',
                componentProps: {
                    className: 'flex-sm-fill',
                    path: 'scheduleVal',
                    availableValues: clause.selected ? _.get(clause, 'scheduleOptions_Ext') : [],
                    disabled: !clause.selected,
                    searchable: true,
                    // for required red start
                    label: translator(clause.name),
                    required: clause.selected,
                    value: _.get(clause, 'scheduleVal'),
                    onValueChange: 'onValueChange',
                    readOnly,
                    // placeholder: clause.selected ? '-- Required for Quote --' : ''
                    placeholder: ''
                }
            };
        }

        return getNullClause();
    }, [clause]);

    const getToggleCluse = useCallback(() => {
        if (clause.valueType_Ext === 'bit') {
            if (readOnly && !clause.selected) {
                return getNullClause();
            }

            return {
                id: `ClauseTerm_[${clause.publicID}]`,
                type: 'field',
                component: 'Toggle',
                componentProps: {
                    className: 'clause_term driver-coverages-clause',
                    // required: clause.required,
                    label: clause.name,
                    availableValues: toggleMockData,
                    path: 'selected',
                    onValueChange: 'onValueChange',
                    readOnly
                }
            };
        }

        return getNullClause();
    }, [clause]);

    const generateMetadata = useCallback(() => {
        const content = _.concat(
            [], getCheckboxClause(), getToggleCluse(), getDropdownSelect(), getClauseData(clause)
        );
        const clauseClassName = _.has(clause, 'scheduleOptions_Ext') ? 'd-flex' : '';

        return {
            content: [{
                id: `Clause_[${clause.publicID}]`,
                // id: 'SingleClause',
                type: 'container',
                component: 'div',
                componentProps: {
                    id: `${clause.publicID}`,
                    path: 'selected',
                    // onValueChange: 'onValueChange',
                    className: clauseClassName
                },
                content
            }]
        };
    }, [clause, getCheckboxClause, getClauseData, getDropdownSelect]);

  /*  const isMetadataValid = useCallback(() => {
        const metadata = generateMetadata();

        return <MetadataForm
        uiProps={metadata} />
    }, [generateMetadata]);

    useEffect(() => {
        registerComponentValidation(isMetadataValid);
    }, [clause]);
    */

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [clause, isComponentValid, onValidate, id]);

    // useEffect(() => {
    //     if (onValidate) {
    //         registerComponentValidation(isMetadataValid);
    //         onValidate(isComponentValid, clause.publicID);
    //     }
    // }, [clause, isComponentValid, isMetadataValid, onValidate, registerComponentValidation]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: false
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            generateMetadata(),
            clause,
            fieldId,
            fieldPath,
            overrideProps
        ), [clause, overrideProps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValueChange: handleChangeClause
        }
    };

    const writeValue = useCallback((value, changePath) => {
        const clauseVM = viewModelService.create(getClause, 'pc', 'edge.capabilities.policycommon.coverage.dto.CoverageDTO');
        const newClause = viewModelService.clone(clauseVM);

        _.set(newClause, `${changePath}.value`, value);
        updateClause(newClause);
    }, [getClause, viewModelService]);

    return (
        showLoading ? (<Loader showLoading />) : (
            <ViewModelForm
                uiProps={generateMetadata()}
                model={clause}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValueChange={writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
        )
    );
};

DriverSingleCoverageComponent.propTypes = {
    clause: PropTypes.objectOf.isRequired,
    onChangeClause: PropTypes.func,
    path: PropTypes.string.isRequired,
    loadingClause: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    readOnly: PropTypes.bool
};

DriverSingleCoverageComponent.defaultProps = {
    onChangeClause: undefined,
    loadingClause: undefined,
    readOnly: false
};

export default DriverSingleCoverageComponent;
// export default withValidation(DriverSingleCoverageComponent);
