import { defineMessages } from 'react-intl';

export default defineMessages({
    Coverages: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.Signs.Coverages",
        "defaultMessage": "Coverages"
    },
    Coinsurance: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.Signs.Coinsurance",
        "defaultMessage": "Coinsurance"
    }
});