
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import messages from './CustomFooterComponent.messages';
import styles from './CustomFooterComponent.module.scss';
import { Button } from '@jutro/legacy/components';

/**
 * Custom Fotter Component for driver and vehicle screen
 *
 * @param {object} props
 * @returns {object} React Component
 */
const CustomFooterComponent = (props) => {
    const {
        addAnotherOneMessage,
        closeMessage,
        continueToCoveragesMessage,
        nextPageMessage,
        isShowContinueCoverages,
        isDisabled,
        isAddDisabled,
        handleCancel,
        handleSaveAndClose,
        handleContinueCoverages,
        handleAddAnotherOne,
        handleSaveAndNext
    } = props;
    const translator = useTranslator();

    const getFooterButtons = () => {
        return (
            <React.Fragment>
                <Grid
                    id="customFooterButtonsRow1"
                    justifyContent="right"
                    className={styles.buttonRow}
                >
                    <div
                        visible={isShowContinueCoverages}
                    >
                        <Button
                            id="continue-to-coverages-button"
                            onClick={handleContinueCoverages}
                            disabled={isDisabled}
                        >
                            {
                                continueToCoveragesMessage
                                || translator(messages.continueToCoveragesLabel)
                            }
                        </Button>
                    </div>
                </Grid>
                <Grid
                    id="customFooterButtonsRow2"
                    justifyContent="between"
                    columns={['1fr', '2fr']}
                >
                    <div>
                        <Button
                            id="cancel-button"
                            type="outlined"
                            onClick={handleCancel}
                        >
                            {translator(messages.cancelLabel)}
                        </Button>
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-end mr-10">
                            <div className="d-flex align-items-center">
                                <span className="font-PrimaryDark-bold-16 mr-10">{translator(messages.saveAnd)}</span>
                            </div>
                            <Button
                                id="save-close-button"
                                onClick={handleSaveAndClose}
                                disabled={isDisabled}
                            >
                                {closeMessage}
                            </Button>
                        </div>
                        <Button
                            id="add-another-button"
                            disabled={isDisabled || isAddDisabled}
                            onClick={handleAddAnotherOne}
                        >
                            {addAnotherOneMessage}
                        </Button>
                        <Button
                            id="save-next-button"
                            disabled={isDisabled}
                            onClick={handleSaveAndNext}
                        >
                            {nextPageMessage || translator(messages.saveNextLabel)}
                        </Button>
                    </div>
                </Grid>
            </React.Fragment>
        );
    };

    return getFooterButtons();
};

CustomFooterComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    addAnotherOneMessage: PropTypes.string,
    isShowContinueCoverages: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isAddDisabled: PropTypes.bool,
    handleCancel: PropTypes.func.isRequired,
    handleContinueCoverages: PropTypes.func.isRequired,
    handleAddAnotherOne: PropTypes.func.isRequired,
    handleSaveAndNext: PropTypes.func.isRequired
};

CustomFooterComponent.defaultProps = {
    model: {},
    onValueChange: undefined,
    showErrors: false,
    addAnotherOneMessage: 'Add Another One',
    isShowContinueCoverages: true,
    isDisabled: false,
    isAddDisabled: false
};

export default CustomFooterComponent;
