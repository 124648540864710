import { defineMessages } from 'react-intl';

export default defineMessages({
    detailsAndCoverages: {
        id: 'quoteandbind.gl.wizard.step.exposures page.Details & Coverages',
        defaultMessage: 'Details & Coverages'
    },
    details: {
        id: 'quoteandbind.gl.wizard.step.exposures page.Details',
        defaultMessage: 'Details'
    },
    coverages: {
        id: 'quoteandbind.gl.wizard.step.exposures page.Coverages',
        defaultMessage: 'Coverages'
    }
})