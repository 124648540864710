import React from 'react'
import _ from 'lodash'
import { WniDataTable } from 'wni-common-base-components';
import { DisplayColumn } from '@jutro/legacy/datatable'
import PoliciesSummarySclauseScheduleTableCell from './PoliciesSummaryClauseScheduleTableCell'

const PoliciesSummaryClauseScheduleTable = (props) => {

    const {
        schedule
    } = props

    const {
        propertyInfos = [],
        scheduleItems = [],
        isPolicyContactSchedule_Ext : isPolicyContactSchedule,
    } = schedule

    const policyContact = propertyInfos.find(propertyInfo => propertyInfo.id === 'PolicyContact');

    const scheduleNumberColumn = <DisplayColumn
        key='ScheduleNumber'
        header= 'Schedule Number'
        sortable = {false}
        textAlign = 'left'
        path = 'itemNumber'
        renderCell = {(scheduleItem) => scheduleItem.itemNumber}
    />

    const sortedScheduleItems = scheduleItems.toSorted((scheduleItemA, scheduleItemB) => {
        const getScheduleNumber = (scheduleItem) => {
            return _.get(scheduleItem, 'itemNumber')
        }
        return getScheduleNumber(scheduleItemA) - getScheduleNumber(scheduleItemB)
    })

    const contactColumn = <DisplayColumn
        key='ScheduleNumber'
        header = {policyContact? policyContact.label : ''}
        sortable = {false}
        textAlign = 'left'
        path = 'contactDisplayName_Ext'
    /> 

    const propertyInfoColumns = propertyInfos
        .filter((propertyInfo) => propertyInfo.id !== 'PolicyContact')
        .map((propertyInfo) => {
            const {
                id,
                label,
            } = propertyInfo
            
            return <DisplayColumn
                key = {id}
                header = {label}
                renderCell = {(scheduleItem) => {
                    return <PoliciesSummarySclauseScheduleTableCell
                        propertyInfo={propertyInfo}
                        scheduleItem={scheduleItem}
                    />
                }}
                sortable = {false}
            />
        })

    return <div className='table-wrapper mb-10'>
        <WniDataTable
            id = 'clause_schedule_table'
            data={sortedScheduleItems}
            showSearch={false}
        >
            {isPolicyContactSchedule ? [scheduleNumberColumn, contactColumn, ...propertyInfoColumns] : [scheduleNumberColumn, ...propertyInfoColumns]}
        </WniDataTable>
    </div>
}

export default PoliciesSummaryClauseScheduleTable