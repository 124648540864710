import { defineMessages } from 'react-intl';

export default defineMessages({
    listofScheduledItems: {
        id: 'InlandMarine.boatCoverage.schedules.table.List of Scheduled Items',
        defaultMessage: 'List of Scheduled Items'
    },
    scheduleItem: {
        id: 'InlandMarine.boatCoverage.schedules.scheduleItem.Schedule Item',
        defaultMessage: 'Schedule Item',
    },
});