import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

import { useTranslator } from '@jutro/locale';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';

import { AllTranslation } from 'wni-platform-translations';

import { GLPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-gl-react';
import { CPPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-cp-react';
import { IMPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-im-react';
import { CRPolicyLineSummaryComponent } from 'wni-capability-quoteandbind-cr-react';


import CPPJurisdictionsComponent from './CPPJurisdictionsComponent';
import CPPPolicyLineSummarySublineContainer from './CPPPolicyLineSummarySublineContainer';
/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CPPPolicyLineSummaryComponent(props) {

    const {
        // policyLineSummary, // CPPPollicyLineSummaryDTO;
        sxsDataDTO, // : cppSxsDataDTO,
        //
        lobData,
    } = props;

    const translator = useTranslator();

    if (_.isEmpty(sxsDataDTO)) {
        return null;
    }

    //
    // const {
    //     lineLevelCoverages,
    //     coverages,
    //     exclusions,
    //     conditions,
    //     //
    //     jurisdictions
    // } = policyLineSummary;

    const {
        commercialPackage: commercialPackage, // not being used
        generalLiability: { policyLineSummary: glPolicyLineSummary } = {},
        commercialProperty: { policyLineSummary: cpPolicyLineSummary } = {},
        inlandMarine: { policyLineSummary: imPolicyLineSummary } = {},
        crime: { policyLineSummary: crPolicyLineSummary } = {},
    } = sxsDataDTO;


    return (
        <div>
            <CPPPolicyLineSummarySublineContainer
                sublines={[
                    {
                        title: translator(AllTranslation.GeneralLiability),
                        component: GLPolicyLineSummaryComponent,
                        componentProps: {
                            policyLineSummary: glPolicyLineSummary,
                        }
                    },
                    {
                        title: translator(AllTranslation.CP7CommercialProperty),
                        component: CPPolicyLineSummaryComponent,
                        componentProps: {
                            policyLineSummary: cpPolicyLineSummary,
                            lobData,
                        }
                    },
                    {
                        title: translator(AllTranslation.InlandMarine),
                        component: IMPolicyLineSummaryComponent,
                        componentProps: {
                            policyLineSummary: imPolicyLineSummary,
                        }
                    },
                    {
                        title: translator(AllTranslation.CR7Crime),
                        component: CRPolicyLineSummaryComponent,
                        componentProps: {
                            policyLineSummary: crPolicyLineSummary,
                        }
                    },
                ]}
            />
            {/* <CPPPolicyLineSummarySublineContainer title={translator(AllTranslation.GeneralLiability)}>
                <GLPolicyLineSummaryComponent policyLineSummary={glPolicyLineSummary} />
            </CPPPolicyLineSummarySublineContainer>
            <CPPPolicyLineSummarySublineContainer title={translator(AllTranslation.CP7CommercialProperty)} >
                <CPPolicyLineSummaryComponent policyLineSummary={cpPolicyLineSummary} />
            </CPPPolicyLineSummarySublineContainer> */}
            
            {/* <DisplayableSummaryComponent title="Line-Level Coverages" displayables={lineLevelCoverages} />
            <CoverageSummaryComponent title="Coverages" clauses={coverages} />
            <CoverageSummaryComponent title="Exclusions" clauses={exclusions} />
            <CoverageSummaryComponent title="Conditions" clauses={conditions} />
            <CPPJurisdictionsComponent jurisdictions={jurisdictions} /> */}
        </div>
    );
    
}

CPPPolicyLineSummaryComponent.propTypes = {
    
};

CPPPolicyLineSummaryComponent.defaultProps = {
    
};


export default CPPPolicyLineSummaryComponent;