import { defineMessages } from 'react-intl';

export default defineMessages({
    dpTypeOfIncident: {
        id: 'pe.fnol.dp.views.dp-what.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    dpFireDamage: {
        id: 'pe.fnol.dp.views.dp-what.Fire Damage',
        defaultMessage: 'Fire Damage'
    },
    dpWaterDamage: {
        id: 'pe.fnol.dp.views.dp-what.Water Damage',
        defaultMessage: 'Water Damage'
    },
    dpCriminalDamage: {
        id: 'pe.fnol.dp.views.dp-what.Criminal Damage',
        defaultMessage: 'Criminal Damage'
    },
    dpOtherDamage: {
        id: 'pe.fnol.dp.views.dp-what.Other',
        defaultMessage: 'Other'
    },
    fireDamageHeader: {
        id: 'pe.fnol.dp.views.dp-fire-details.Details (Fire Damage)',
        defaultMessage: 'Details (Fire Damage)'
    },
    fireCause: {
        id: 'pe.fnol.dp.views.dp-fire-details.What caused the fire (if you know)?',
        defaultMessage: 'What caused the fire (if you know)?'
    },
    fireDiscovered: {
        id: 'pe.fnol.dp.views.dp-fire-details.dpw was the fire discovered?',
        defaultMessage: 'dpw was the fire discovered?'
    },
    isdpmeHabitable: {
        id: 'pe.fnol.dp.views.dp-fire-details.Is the dpme habitable?',
        defaultMessage: 'Is the dpme habitable?'
    },
    cdpiceYes: {
        id: 'pe.fnol.dp.views.dp-fire-details.Yes',
        defaultMessage: 'Yes'
    },
    cdpiceNo: {
        id: 'pe.fnol.dp.views.dp-fire-details.No',
        defaultMessage: 'No'
    },
    cdpiceNotSure: {
        id: 'pe.fnol.dp.views.dp-fire-details.I\'m not sure',
        defaultMessage: 'I\'m not sure'
    },
    isdpmeSecure: {
        id: 'pe.fnol.dp.views.dp-fire-details.Is the dpme secure',
        defaultMessage: 'Is the dpme secure?'
    },
    anyOtherDetails: {
        id: 'pe.fnol.dp.views.dp-fire-details.Any other details?',
        defaultMessage: 'Any other details?'
    },
    waterDamageHeader: {
        id: 'pe.fnol.dp.views.dp-water-details.Details (Water Damage)',
        defaultMessage: 'Details (Water Damage)'
    },
    typeOfDamage: {
        id: 'pe.fnol.dp.views.dp-water-details.What was the type of damage?',
        defaultMessage: 'What was the type of damage?'
    },
    waterDamage: {
        id: 'pe.fnol.dp.views.dp-water-details.Water Damage',
        defaultMessage: 'Water Damage'
    },
    moldDamage: {
        id: 'pe.fnol.dp.views.dp-water-details.Mold',
        defaultMessage: 'Mold'
    },
    sourceOfWater: {
        id: 'pe.fnol.dp.views.dp-water-details.What was the source of the water?',
        defaultMessage: 'What was the source of the water?'
    },
    plumbingSource: {
        id: 'pe.fnol.dp.views.dp-water-details.Plumbing or Appliance',
        defaultMessage: 'Plumbing or Appliance'
    },
    roofLeakSource: {
        id: 'pe.fnol.dp.views.dp-water-details.Roof Leak',
        defaultMessage: 'Roof Leak'
    },
    otherSource: {
        id: 'pe.fnol.dp.views.dp-water-details.Other',
        defaultMessage: 'Other'
    },
    hasWaterTurnedOff: {
        id: 'pe.fnol.dp.views.dp-water-details.Has the water been turned off?',
        defaultMessage: 'Has the water been turned off?'
    },
    waterCdpiceYes: {
        id: 'pe.fnol.dp.views.dp-water-details.Yes',
        defaultMessage: 'Yes'
    },
    waterCdpiceNo: {
        id: 'pe.fnol.dp.views.dp-water-details.No',
        defaultMessage: 'No'
    },
    waterCdpiceNotSure: {
        id: 'pe.fnol.dp.views.dp-water-details.I\'m not sure',
        defaultMessage: 'I\'m not sure'
    },
    anyOtherDetailsOfWater: {
        id: 'pe.fnol.dp.views.dp-water-details.Any other details?',
        defaultMessage: 'Any other details?'
    },
    criminalDamageHeader: {
        id: 'pe.fnol.dp.views.dp-crime-details.Details (Criminal Damage)',
        defaultMessage: 'Details (Criminal Damage)'
    },
    typeOfCrime: {
        id: 'pe.fnol.dp.views.dp-crime-details.What type of crime took place?',
        defaultMessage: 'What type of crime took place?'
    },
    burglaryCrime: {
        id: 'pe.fnol.dp.views.dp-crime-details.Burglary',
        defaultMessage: 'Burglary'
    },
    vandalismCrime: {
        id: 'pe.fnol.dp.views.dp-crime-details.Vandalism',
        defaultMessage: 'Vandalism'
    },
    riotCrime: {
        id: 'pe.fnol.dp.views.dp-crime-details.Riot or civil commotion',
        defaultMessage: 'Riot or civil commotion'
    },
    anyOtherDetailsCrime: {
        id: 'pe.fnol.dp.views.dp-crime-details.Any other details',
        defaultMessage: 'Any other details?'
    },
    flowConfigErrorcreatingClaim: {
        id: 'pe.fnol.dp.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    dpClaim: {
        id: 'pe.fnol.dp.config.FlowConfig.An error occurred attempting to create dwelling claim.',
        defaultMessage: 'An error occurred attempting to create dwelling claim.'
    },
    draftClaim: {
        id: 'pe.fnol.dp.config.FlowConfig.Creating dwelling draft claim...',
        defaultMessage: 'Creating dwelling draft claim...'
    },
    dpDetails: {
        id: 'pe.fnol.wizard.step.dp.Details',
        defaultMessage: 'Details'
    },
    dpCreateDraftClaim: {
        id: 'pe.fnol.dp.config.FlowConfig.Creating dwelling property draft claim...',
        defaultMessage: 'Creating dwelling property draft claim...'
    },
    dpCreateDraftErrorTitle: {
        id: 'pe.fnol.dp.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    dpCreateDraftErrorMessage: {
        id: 'pe.fnol.dp.config.FlowConfig.An error occurred attempting to create dwelling property claim.',
        defaultMessage: 'An error occurred attempting to create dwelling property claim.'
    }
});
