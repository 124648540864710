import React, {
    useState
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import WizardPage from '../../templates/CAWizardPage';
import messages from './CAModifiersPage.messages';
import styles from './CAModifiersPage.module.scss';
import CAModifiersConfig from './CAModifiersPage.config';

import { Accordion, AccordionCard, InputField } from '@jutro/legacy/components';

function CAModifiersReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;

    const translator = useTranslator();
    const {
        lobData: {
            commercialAuto: {
                modifiers_Ext : modifiers
            }
        },
    } = submissionVM.value;
    const {
        agentStateTotalRangeMap
    } = CAModifiersConfig;
    const { authUserData } = useAuthentication();
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const states = modifiers.map(modifier => modifier.jurisdiction);
    // currently, only focus on AK, this story will be revisited, then follow up other states
    const modifierAK = modifiers.find(modifier => modifier.jurisdiction === 'Alaska');
    const overallMinAK = isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`) : modifierAK.overallMinimum;
    const overallMaxAK = isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) : modifierAK.overallMaximum;
    
    const initTotalPhysicalDamageForAK = modifierAK ? modifierAK.rateFactors.reduce((acc, currentFactor) => acc + currentFactor.physicalDamage, 0) : undefined;
    const allStateTotalCreditDebit = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallCreditDebit: modifier.rateFactors.reduce((acc, currentFactor) => acc + currentFactor.creditDebit, 0)}
    });
    const stateOverallMinMax = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallMin: modifier.overallMinimum, overallMax: modifier.overallMaximum}
    })
    const [accordionStates, setAccordionStates] = useState(states);
    
    const defaultValidationInfo = _.isNil(modifierAK) ? [] : [{
            type: 'info',
            reason: translator(messages.modifierRangeInfo, {min:`${Math.round(overallMinAK * 100)}%` , max: `${Math.round(overallMaxAK * 100)}%`})
    }]

    const CreditDebitInputField = ({item}) => {
        const shownCreditDebit = _.isNaN(_.get(item, 'creditDebit')) ? null : `${parseFloat(_.get(item, 'creditDebit') * 100).toFixed(1)}%`;
        const [creditDebit, setCreditDebit] = useState(shownCreditDebit);
        const errorCondition = (parseFloat(creditDebit)/100 > item.maximum) || (parseFloat(creditDebit)/100 < item.minimum) || (_.isNaN(parseFloat(creditDebit)/100));
        return <InputField
            onValueChange={(value) => {setCreditDebit(value)}}
            value={creditDebit}
            readOnly
            showErrors
            validationMessages={errorCondition ? [messages.invalidRateFactorError] : null}
        />
    }
    
    const PhysicalDamageInputField = ({item}) => {
        const shownPhysicalDamage = _.isNaN(_.get(item, 'physicalDamage')) ? null : `${parseFloat(_.get(item, 'physicalDamage') * 100).toFixed(1)}%`;
        const [physicalDamage, setPhysicalDamage] = useState(shownPhysicalDamage);
        return <InputField
            onValueChange={(value) => {setPhysicalDamage(value)}}
            value={physicalDamage}
            readOnly
        />
    }

    const JustificationInputField = ({item}) => {
        const [justification, setJustification] = useState(_.get(item, 'justification'));
        return <InputField
            onValueChange={(value) => setJustification(value)}
            readOnly
            value={justification}
        />
    }

    const onPageNext = ( () => {
        return submissionVM;
    })

    return (
        <WizardPage
            onNext={onPageNext}
        >
            <ValidationIssuesComponent validationIssues={defaultValidationInfo}/>
            <h2>Modifiers</h2>
            <hr/>
            <Accordion
                accordionStates={accordionStates}
                onUpdateAccordionStates= {(ids) => setAccordionStates(ids)}
                closeOthers={false}
            >
            {
                modifiers.map(modifier => {
                    const currentState = modifier.jurisdiction;
                    const currentOverallCreditDebit = allStateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
                    const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
                    const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
                    const newRateFactors = modifier.rateFactors.concat({category: 'Overall'})
                    return <AccordionCard
                                chevron
                                id={currentState}
                                title={currentState}
                            >
                                <hr/>
                                <DataTable
                                    columnsConfigurable={false}
                                    data={newRateFactors}
                                    id="basic"
                                    showPagination={false}
                                    showSearch={false}
                                    tableLabel="Modifiers"
                                    >
                                    <DisplayColumn
                                        header="Category"
                                        id="category"
                                        path="category"
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Credit Minimum"
                                        id="minimum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ?  
                                            `${parseFloat(overallMinAK * 100).toFixed(1)}%` || `${parseFloat(currentTotalMinimum * 100).toFixed(1)}%` 
                                            : `${parseFloat(newRateFactors[index].minimum * 100).toFixed(1)}%`}}
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Debit Maximum"
                                        id="maximum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ? 
                                            `${parseFloat(overallMaxAK * 100).toFixed(1)}%`  || `${parseFloat(currentTotalMaximum * 100).toFixed(1)}%` 
                                            : `${parseFloat(newRateFactors[index].maximum * 100).toFixed(1)}%`}}
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Liability"
                                        id="Liability"
                                        renderCell={(item, index) => { 
                                            const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                                            if (isUnEditable) {
                                                return "";
                                            }
                                            return index ===  modifier.rateFactors.length ? `${parseFloat(currentOverallCreditDebit * 100).toFixed(1)}%` : <CreditDebitInputField item={item}/>}} 
                                        textAlign="left"
                                    />
                                    {currentState === 'Alaska' && 
                                    <DisplayColumn
                                        header="Physical Damage"
                                        id="physicalDamage"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ? `${parseFloat(initTotalPhysicalDamageForAK * 100).toFixed(1)}%`: <PhysicalDamageInputField item={item}/>}} 
                                        textAlign="left"
                                    />
                                    }
                                    <DisplayColumn
                                        header="Justification"
                                        id="justification"
                                        renderCell= {(item, index) => {
                                            return index ===  modifier.rateFactors.length ? '' : <JustificationInputField item={item}/>}}
                                        textAlign="left"
                                    />
                                </DataTable>
                            </AccordionCard>
                })
            }
            </Accordion>
        </WizardPage>
    );
}

CAModifiersReadOnlyPage.propTypes = WizardPage.propTypes;
CAModifiersReadOnlyPage.defaultProps = WizardPage.defaultProps;
export default CAModifiersReadOnlyPage;
