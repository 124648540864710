
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DriverUtil, WniDateUtil, WniFormat } from 'wni-portals-util-js';
import metadata from './DriverContactDetailsComponent.metadata.json5';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const DriverContactDetailsComponent = (props) => {
    const {
        model: driverVM,
        initModel: initDriverVM,
        showErrors,
        isReadOnly,
        onValueChange,
        primaryNamedInsured,
        baseData,
        onValidate
    } = props;

    const translator = useTranslator();
    const [isMaritalReadOnly, updateIsMaritalReadOnly] = useState(false);
    const [showDOBError, updateShowDOBError] = useState(false);
    const {
        person: { primaryPhoneType = {} },
        relationshipToInsured_Ext: relationshipToInsured = {},
        isPNI_Ext: isPNI,
        isADPDOB_Ext: isADPDOB,
        dateOfBirth,
        ratedAge_Ext: ratedAge,
        firstName,
        lastName,
        middleName_Ext: middleName,
        suffix_Ext: suffix,
        maritalStatus_Ext: maritalStatus
    } = driverVM.value || { person: {} };
    const [isMaskDOB, updateIsMaskDOB] = useState(isADPDOB);
    const [editableDOB, updateEditableDOB] = useState(false);
    const initIsADPDOB = _.get(initDriverVM, 'value.isADPDOB_Ext');
    const initADPDOB = _.get(initDriverVM, 'value.dateOfBirth');

    const isLincensedDriver = useCallback(() => {
        if (DriverUtil.isChild(driverVM, baseData)) {
            _.set(driverVM, 'licensedDriver_Ext', false);
            _.set(driverVM, 'driverUnLicensedReason_Ext', 'neverLicensed');
            return;
        }
        _.set(driverVM, 'licensedDriver_Ext', true);
    }, [baseData, driverVM]);

    useEffect(() => {
        if (isPNI && _.isEmpty(relationshipToInsured)) {
            _.set(driverVM, 'relationshipToInsured_Ext', 'Insured_Ext');
        }
    }, []);

    useEffect(() => {
        DriverUtil.setInexperiencedDriver(ratedAge, driverVM);
    }, [ratedAge]);

    useEffect(() => {
        if (relationshipToInsured === 'Spouse_Ext') {
            _.set(driverVM, 'maritalStatus_Ext', 'M');
            _.set(driverVM, 'value.person.maritalStatus', 'M');
            updateIsMaritalReadOnly(true);
        } else {
            updateIsMaritalReadOnly(false);
        }
    }, [relationshipToInsured]);

    useEffect(() => {
        if (_.isEmpty(_.get(driverVM, 'value'))) {
            return;
        }
        if (!_.get(driverVM, 'value.person.firstName')) {
            _.set(driverVM, 'value.person.firstName', firstName);
        }
        if (!_.get(driverVM, 'value.person.middleName')) {
            _.set(driverVM, 'value.person.middleName', middleName);
        }
        if (!_.get(driverVM, 'value.person.lastName')) {
            _.set(driverVM, 'value.person.lastName', lastName);
        }
        if (!_.get(driverVM, 'value.person.suffix')) {
            _.set(driverVM, 'value.person.suffix', suffix);
        }
        if (!_.get(driverVM, 'value.person.maritalStatus')) {
            _.set(driverVM, 'value.person.maritalStatus', maritalStatus);
        }
        if (!_.get(driverVM, 'value.person.dateOfBirth') || !_.get(driverVM, 'person.dateOfBirth.aspects.valid')) {
            _.set(driverVM, 'value.person.dateOfBirth', dateOfBirth);
        }
    }, [firstName, middleName, lastName, suffix, maritalStatus, dateOfBirth]);

    const handleDateOfBirth = useCallback((val) => {
        updateShowDOBError(true);
        updateEditableDOB(true);
        const ageVal = DriverUtil.getAge(driverVM, baseData);
        DriverUtil.setInexperiencedDriver(ageVal, driverVM);
        _.set(driverVM, 'age_Ext', ageVal);
        _.set(driverVM, 'dateOfBirth', val);
        isLincensedDriver();
        onValueChange(val, 'dateOfBirth');
    }, [baseData, driverVM, isLincensedDriver, onValueChange]);

    const handleRelInsured = useCallback((val) => {
        if (['Insured_Ext', 'Spouse_Ext'].includes(val)) {
            _.set(driverVM, 'additionalInsured_Ext', true);
        } else {
            _.set(driverVM, 'additionalInsured_Ext', false);
        }
        if (val === 'Partner_Ext') {
            _.set(driverVM, 'maritalStatus_Ext', 'S');
            _.set(driverVM, 'value.person.maritalStatus', 'S');
        }
        onValueChange(val, 'relationshipToInsured_Ext');
    }, [driverVM, onValueChange]);

    const visibleAddInsured = useCallback(() => {
        if ((relationshipToInsured === 'Spouse_Ext'
            || relationshipToInsured === 'Partner_Ext'
            || relationshipToInsured === 'Insured_Ext')
            && !isPNI) {
            return true;
        }
        return false;
    }, [isPNI, relationshipToInsured]);

    const addInsuredValue = useCallback(() => {
        const driverName = `${firstName} ${lastName}`;
        if (['Insured_Ext', 'Spouse_Ext'].includes(relationshipToInsured) && primaryNamedInsured !== driverName) {
            return true;
        }
        return false;
    }, [firstName, lastName, primaryNamedInsured, relationshipToInsured]);

    const handleAddInsured = useCallback((val) => {
        onValueChange(val, 'additionalInsured_Ext');
        if (_.get(driverVM, 'value.additionalInsured_Ext')) {
            _.set(driverVM, 'driverCovered_Ext', true);
        }
    }, [driverVM, onValueChange]);

    const DOBValue = useCallback(() => {
        if (initIsADPDOB && !editableDOB && !isMaskDOB) {
            return '';
        }
        return _.get(driverVM.value, 'dateOfBirth');
    }, [driverVM.value, editableDOB, initIsADPDOB, isMaskDOB]);

    const maskDOBVisible = useCallback(() => {
        if (isADPDOB && dateOfBirth) {
            return true;
        }
        return false;
    }, [dateOfBirth, isADPDOB]);

    const editADPDOB = useCallback(() => {
        updateIsMaskDOB(false);
        _.set(driverVM, 'dateOfBirth', '');
        _.set(driverVM, 'isADPDOB_Ext', false);
    }, [driverVM]);

    const cancelADPDOB = useCallback(() => {
        updateIsMaskDOB(true);
        _.set(driverVM, 'dateOfBirth', initADPDOB);
        _.set(driverVM, 'isADPDOB_Ext', true);
    }, [driverVM, initADPDOB]);

    const getPlaceholder = (path) => {
        return WniFormat.placeholderFormat(driverVM, path);
    };
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        driverFirstName: {
            path: 'firstName',
            ...getPlaceholder('firstName')
        },
        driverMiddleName: {
            ...getPlaceholder('middleName_Ext')
        },
        driverLastName: {
            ...getPlaceholder('lastName')
        },
        driverSuffix: {
            ...getPlaceholder('suffix_Ext')
        },
        driverGender: {
            ...getPlaceholder('gender_Ext')
        },
        driverOccupation: {
            ...getPlaceholder('occupation_Ext')
        },
        driverPrimaryPhone: {
            ...getPlaceholder('person.primaryPhoneType')
        },
        driverPrimaryEmail: {
            ...getPlaceholder('person.emailAddress1_Ext')
        },
        driverDateOfBirth: {
            readOnly: isReadOnly,
            onValueChange: handleDateOfBirth,
            maxDate: new Date(),
            minDate: WniDateUtil.backEarlyDate(),
            value: DOBValue(),
            required: _.get(driverVM, 'dateOfBirth.aspects.required'),
            showErrors: showDOBError,
            visible: !maskDOBVisible()
        },
        maskDateOfBirth: {
            disabled: true,
            value: '**/**/****',
            visible: maskDOBVisible()
        },
        editADPDOB: {
            visible: Boolean(initIsADPDOB && isMaskDOB),
            onClick: editADPDOB
        },
        cancelADPDOB: {
            visible: Boolean(initIsADPDOB && !isMaskDOB),
            onClick: cancelADPDOB
        },
        driverRelInsured: {
            ...getPlaceholder('relationshipToInsured_Ext'),
            onValueChange: handleRelInsured,
            availableValues: DriverUtil.getAvailableRelationship(
                _.get(driverVM, 'relationshipToInsured_Ext.aspects.availableValues'), translator
            ),
            visible: !isPNI
        },
        driverMaritalStatus: {
            readOnly: isMaritalReadOnly || isReadOnly,
            ...getPlaceholder('maritalStatus_Ext')
        },
        driverAddInsured: {
            visible: visibleAddInsured(),
            defaultValue: addInsuredValue(),
            onValueChange: handleAddInsured
        },
        workPhoneNumber: {
            visible: primaryPhoneType === 'work'
        },
        homePhoneNumber: {
            visible: primaryPhoneType === 'home'
        },
        cellPhoneNumber: {
            visible: primaryPhoneType === 'mobile'
        },
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, readPath) => {
        return readViewModelValue(
            metadata.componentContent,
            driverVM,
            id,
            readPath,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            // onModelChange={handleValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
};

DriverContactDetailsComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    initModel: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    showErrors: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    primaryNamedInsured: PropTypes.string,
    baseData: PropTypes.shape({})
};

DriverContactDetailsComponent.defaultProps = {
    model: {},
    initModel: {},
    showErrors: false,
    isReadOnly: false,
    primaryNamedInsured: undefined,
    baseData: {}
};

export default DriverContactDetailsComponent;
