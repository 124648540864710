import { defineMessages } from 'react-intl';

/**
 * ref: common/ClauseComponentVM.messages.js
 */
export default defineMessages({
    RTRoadAndTailCoveragesCopyCoverageButton: {
        "id": "RTVehiclesCoveragesCopyCoverageButton.button.label.copy coverages",
        "defaultMessage": "Copy Coverages"
    },
    RTRoadAndTailCoveragesTip: {
        "id": "rt.roadandtail.coverages.section.Summary coverages are shown below. Additional information is available, please click \"Edit\".",
        "defaultMessage": "Summary coverages are shown below. Additional information is available, please click “Edit”."
    },
    RTRoadAndTailCoveragesTableNO: {
        "id": "rt.roadandtail.coverages.table.no",
        "defaultMessage": "No"
    },
    RTRoadAndTailCoveragesTableYES: {
        "id": "rt.roadandtail.coverages.table.yes",
        "defaultMessage": "Yes"
    },
    RTRoadAndTailCoveragesTableViewEditAddCoverage: {
        "id": "rt.roadandtail.coverages.table.View/Edit/Add Coverage",
        "defaultMessage": "View/Edit/Add Coverage"
    },
    RTRoadAndTailCoveragesLiabilityOnly: {
        "id": "rt.roadandtail.coverages.table.N/A - Liability Only",
        "defaultMessage": "N/A - Liability Only"
    },
});
