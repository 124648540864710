import React from 'react';
import PropTypes from 'prop-types';
import {
    Route, Redirect, withRouter
} from 'react-router-dom';

const CommonRoutes = (props) => {
    const { steps, basePath, location: { pathname } } = props;
    const isPathMatch = pathname ? steps.some((step) => [basePath, step.path].join('') === pathname) : true;
    const routes = isPathMatch ? steps.map(({ path, component }) => {
        const composedPath = [basePath, path].join('');
        const Component = require(`../${component}`).default;
        return (
            <Route
                key={composedPath}
                path={composedPath}
                render={
                    (renderProps) => <Component {...renderProps} fromAccountLanding={props} />
                }
            />
        );
    }) : <Route render={() => <Redirect to="/" />} />;

    return (
        <div>
            {routes}
        </div>
    );
};

CommonRoutes.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
    })).isRequired,
    basePath: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired,
};
export default withRouter(CommonRoutes);