import { Card, ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import _ from 'lodash';
import React,{ useCallback,useState } from 'react';
import { CPLocationService, CPRisksService } from 'wni-capability-quoteandbind-cp';
import {
useWniModal,
ValidationIssuesComponent
} from 'wni-components-platform-react';
import messages from './CPLocationImportPopup.messages';
import metadata from './CPLocationImportPopup.metadata.json5';

import { Button } from '@jutro/legacy/components';

// import styles from './CustomInput.module.scss';
// import messages from './CustomInput.messages.ts';

function CPLocationImportPopup(props) {
    const modalApi = useWniModal();
    const {
        triggerScreen = "CPLocation",
        size = 'md',
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title = messages.defautlTitle,
        translator,
        useDependenciesData,
        extendProps: { jobID, sessionUUID, authHeader },
        showNote = true,
    } = props;
    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;

    const [isUploadSuccess, updateIsUploadSuccess] = useState(false);
    const [file, updateFile] = useState(null);
    const [uploadData, updateUploadData] = useState({});
    const [importValidationIssues, updateImportValidationIssues] = useState([]);

    const getDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID =
                await GatewayDocumentService.generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return modalApi
                .showAlert({
                    title: commonMessages.errorUploadTitle,
                    message: commonMessages.errorGenerateUploadToken,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(_.noop);
        }
    }, [authHeader]);

    const handleImport = useCallback(async () => {
        if (!file) {
            return false;
        }
        setLoadingMask(true);
        const documentMetaDataTemplate = {
            jobNumber: jobID,
            docUID: '001',
            documentType: 'Exc_Ext',
            status: 'approved',
            name: file.name,
            mimeType: file.type,
            sessionID: await getDocUploadToken(),
            sessionUUID: sessionUUID,
        };

        try {
            const res = await CPLocationService.upload(
                file,
                documentMetaDataTemplate,
                authHeader
            );
            updateUploadData(res);
            updateIsUploadSuccess(true);
            updateImportValidationIssues(
                _.get(
                    res,
                    'errorsAndWarnings.serverIssues_Ext.displayMssages',
                    []
                )
            );
            setLoadingMask(false);
        } catch (error) {
            setLoadingMask(false);
            modalApi
                .showAlert({
                    title: 'Importing',
                    message: messages.importTimeout,
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .then(async () => {
                    // setLoadingMask(true);
                    // const res = await CAVehicleService.fetchVehicles(jobID, sessionUUID, authHeader);
                    // updateUploadData(res);
                    onResolve({ isUploading: true });
                })
                .catch(_.noop);
        } finally {
            setLoadingMask(false);
        }
    }, [
        authHeader,
        file,
        getDocUploadToken,
        jobID,
        modalApi,
        onResolve,
        sessionUUID,
        setLoadingMask,
    ]);
    const uploadfile = (newfile) => {
        updateFile(newfile);
    };

    const renderChanges = () => {
        const summaryLog = _.get(uploadData, 'summaryLog', []);
        return summaryLog.map((log, index) => {
            return (
                <Card
                    key={index}
                    className="wizardSection pv-0"
                    headerClassName="wizardTitle font-capitalize"
                    title={log.displayName}
                >
                    <Flex justifyContent="between">
                        <span className="font-NeutralUltraDark-light-14">
                            {translator(messages.locationRead)}
                        </span>
                        <div className="font-NeutralUltraDark-bold-14">
                            {log.beansRead}
                        </div>
                    </Flex>
                    <Flex justifyContent="between">
                        <span className="font-NeutralUltraDark-light-14">
                            {translator(messages.locationEdited)}
                        </span>
                        <div className="font-NeutralUltraDark-bold-14">
                            {log.beansEdited}
                        </div>
                    </Flex>
                    <Flex justifyContent="between">
                        <span className="font-NeutralUltraDark-light-14">
                            {translator(messages.locationAdded)}
                        </span>
                        <div className="font-NeutralUltraDark-bold-14">
                            {log.beansAdded}
                        </div>
                    </Flex>
                    <Flex justifyContent="between">
                        <span className="font-NeutralUltraDark-light-14">
                            {translator(messages.locationRemoved)}
                        </span>
                        <div className="font-NeutralUltraDark-bold-14">
                            {log.beansRemoved}
                        </div>
                    </Flex>
                </Card>
            );
        });
    };

    const handleAcceptChanges = async () => {
        setLoadingMask(true);
        let res = {};
        if (triggerScreen === 'CPLocation') {
            res = await CPLocationService.fetchLocations(
                jobID,
                sessionUUID,
                authHeader
            );
        }
        if (triggerScreen === 'CPRisk') {
            res = await CPRisksService.fetchRisksRows(
                jobID,
                sessionUUID,
                authHeader
            );
        }
        setLoadingMask(false);
        onResolve(res);
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        importNoteSection: {
            visible: showNote,
        },
        uploadSection: {
            value: _.get(file, 'name', null),
        },
        importScreen: {
            visible: !isUploadSuccess,
        },
        changesSection: {
            visible: isUploadSuccess,
        },
        acceptChangesSection: {
            content: renderChanges(),
        },
        importvValidationIssues: {
            validationIssues: importValidationIssues,
            visible: importValidationIssues.length > 0,
            scrollToIssues: true,
        },
        fatalErrorCard: {
            visible: !_.isEmpty(_.get(uploadData, 'fatalErrors')),
        },
        fatalErrorMessage: {
            content: _.get(uploadData, 'fatalErrors'),
        },
        errorCard: {
            visible: !_.isEmpty(_.get(uploadData, 'errors')),
        },
        errorMessage: {
            content: _.get(uploadData, 'errors'),
        },
        warningCard: {
            visible: !_.isEmpty(_.get(uploadData, 'warnings')),
        },
        warningMessage: {
            content: _.get(uploadData, 'warnings'),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            uploadfile,
        },
        resolveComponentMap: {
            validationissues: ValidationIssuesComponent,
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader
                title={isUploadSuccess ? `${translator(title)} Summary` : title}
            />
            <ModalBody id="importLocation">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            {!isUploadSuccess ? (
                <ModalFooter
                    contentLayout={{
                        component: 'flex',
                        componentProps: {
                            alignItems: 'middle',
                            justifyContent: 'between',
                        },
                    }}
                >
                    <Button onClick={onReject} type="outlined">
                        {cancelBtnLabel}
                    </Button>
                    <Button onClick={handleImport} disabled={!file}>
                        Import
                    </Button>
                </ModalFooter>
            ) : (
                <ModalFooter>
                    <Button onClick={handleAcceptChanges}>
                        Accept Changes
                    </Button>
                </ModalFooter>
            )}
        </ModalNext>
    );
}

export default CPLocationImportPopup;
