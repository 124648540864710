import { defineMessages } from 'react-intl';

export default defineMessages({
    moreThanAfter30Warning: {
        id: 'gateway.directives.cancel-policy.You are setting an effective date of 30 or more days in the future.',
        defaultMessage: 'You are setting an effective date of 30 or more days in the future'
    },
    moreThanBack30Error: {
        id: 'gateway.directives.cancel-policy.Cannot backdate cancellation effective date by more than 30 days',
        defaultMessage: 'Cannot backdate cancellation effective date by more than 30 days. You can edit the effective date, contact your Underwriter directly for approval, or submit this request to your Underwriter for review.'
    },
    moreThanBack30Subject: {
        id: 'gateway.directives.cancel-policy.30 days backdated Cancellation by Insured',
        defaultMessage: '30 days backdated Cancellation by Insured.'
    },
    otherReasonWarning: {
        id: 'gateway.directives.cancel-policy.Request cancellation with Other reason will be referred to Cancellation Queue',
        defaultMessage: 'Request cancellation with Other reason will be referred to Cancellation Queue'
    },
    otherActivitySubject: {
        id: 'gateway.directives.cancel-policy.Cancellation Request for Other reason',
        defaultMessage: 'Cancellation Request for Other reason'
    },
    referToUnderwriter: {
        id: 'gateway.directives.cancel-policy.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    back: {
        id: 'gateway.directives.cancel-policy.Back',
        defaultMessage: 'Back'
    },
    withLossWarning: {
        id: 'gateway.directives.cancel-policy.This policy is currently blocked from cancellation due to recent claim activity. It will need to be submitted to your Underwriter for review prior to proceeding.',
        defaultMessage: 'This policy is currently blocked from cancellation due to recent claim activity. It will need to be submitted to your Underwriter for review prior to proceeding.'
    },
    withLossSubject: {
        id: 'gateway.directives.cancel-policy.Backdated Cancellation with Loss by Insured',
        defaultMessage: 'Backdated Cancellation with Loss by Insured.'
    },
    withLossActivityDesc: {
        id: 'gateway.directives.cancel-policy.withLossActivityDesc',
        defaultMessage: 'Please review Cancellation Request {jobNumber} since this policy has claims submitted after cancellation date, also provide selection on \'Cancellation with Loss?\' Field'
    },
    otherActivityDesc: {
        id: 'gateway.directives.cancel-policy.otherActivityDesc',
        defaultMessage: 'Agent request cancellation for Policy {policyNumber} with reason as \'Other\', description is \'{descriptionVal}\' please review and handle it'
    },
    beforeDaysActivityDesc: {
        id: 'gateway.directives.cancel-policy.beforeDaysActivityDesc',
        defaultMessage: 'Please review Policy {policyNumber}, Agent request cancellation effective before 30 days ago'
    },
    startCancellation: {
        id: 'gateway.directives.cancel-policy.StartCancellation',
        defaultMessage: 'Start Cancellation'
    },
    isChangeMailingAddress: {
        id: 'gateway.directives.cancel-policy.isChangeMailingAddress',
        defaultMessage: 'Would you like to change the mailing address for the cancellation documents and applicable refunds?'
    },
    yes: {
        id: 'gateway.directives.cancel-policy.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'gateway.directives.cancel-policy.No',
        defaultMessage: 'No'
    },
    mailingAddress: {
        id: 'gateway.directives.cancel-policy.Mailing Address',
        defaultMessage: 'Mailing Address'
    },
    changeAddressTitle: {
        id: 'gateway.directives.cancel-policy.Address Change Needed',
        defaultMessage: 'Address Change Needed'
    },
    changeAddressMessage: {
        id: 'gateway.directives.cancel-policy.changeAddressMessage',
        defaultMessage: 'Please process this mailing address change in order to continue with the cancellation.'
    },
    startAddressChange: {
        id: 'gateway.directives.cancel-policy.Start Address Change',
        defaultMessage: 'Start Address Change'
    },
    close: {
        id: 'gateway.directives.cancel-policy.Close',
        defaultMessage: 'Close'
    },
    warningMsg: {
        id: 'gateway.directives.policy change.warning Policy Message',
        defaultMessage: 'Our records indicate that you do not have servicing rights on this policy. Please contact your Underwriter for further assistance'
    },
    PLRefundLabel: {
        id: 'wni.gateway.directives.cancel-policy.Refund Method',
        defaultMessage: 'Refund Method'
    },
    CLRefundLabel: {
        id: 'wni.gateway.directives.cancel-policy.Method of Cancellation',
        defaultMessage: 'Method of Cancellation'
    }
});
