import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import styles from './WizardStepCircles.module.scss';
import { Button } from '@jutro/legacy/components';

const getIcon = (isStepVisited, isStepActive) => {
    return isStepVisited && !isStepActive ? 'mi-check' : null;
};

function renderSteps(translator, steps, currentStepIndex, jumpTo) {
    return steps.map((step, index) => {
        const isStepVisited = index <= currentStepIndex;
        const isStepActive = index === currentStepIndex;
        const stepLabel = classNames(styles.wizardStepCircles_stepLabel, {
            [styles.wizardStepCircles_stepLabelActive]: isStepActive
        });
        return (
            <div className={styles.wizardStepCircles_step}>
                {index > 0 && <span className={styles.wizardStepCircles_stepSeparator} />}
                <div className={styles.wizardStepCircles_stepContent}>
                    <div className={styles.wizardStepCircles_stepIcon}>
                        <div className={styles.stepActionLabel}>
                            <Button
                                className="actionIcon"
                                visible
                                solid={isStepVisited && !isStepActive}
                                icon={getIcon(isStepVisited, isStepActive, index)}
                                disabled={!isStepVisited}
                                onClick={() => jumpTo(index)}
                            >
                                {(!isStepVisited || isStepActive) && index + 1}
                            </Button>
                        </div>
                    </div>

                    <div className={stepLabel}>{translator(step.title)}</div>
                </div>
            </div>
        );
    });
}

function WizardStepCircles() {
    const wizardContext = useContext(WizardContext);
    const translator = useTranslator();
    const {
        steps, currentStepIndex, jumpTo, wizardTitle
    } = wizardContext;

    return (
        <div className={styles.buildingWizardWrapperHeading}>
            <div className={styles.buildingWizardWrapperHeadingItem}>
                <h3>{translator(wizardTitle)}</h3>
            </div>
            <div className={styles.wizardStepCircles}>
                <div className={styles.wizardStepCircles_baseline} />
                {renderSteps(translator, steps, currentStepIndex, jumpTo)}
            </div>
        </div>
    );
}

export default WizardStepCircles;
