import React, {useState} from "react"
import _ from "lodash";
import { DropdownMenuButton, ToggleField } from "@jutro/components";
import { DisplayColumn, Table } from '@jutro/legacy/datatable';

import { useTranslator } from '@jutro/locale';
import messages from './PropertyIncidents.messages'
import SinglePropertyIncident from "./SinglePropertyIncident/SinglePropertyIncident";
import { getNewAddressTempId } from "../util/NewAddressUtil";
import { getFullAddressDisplayName } from "../util/AddressUtil";

import { Button } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

const PropertyIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const propertyIncidentInitValue = {
    belongsToInsured_Ext: false
}

const PropertyIncidents = (props) => {
    const {
        propertyIncidents = [],
        updatePropertyIncidents,
        vehicleIncidents,
        predefinedLossLocations,
        incidentsAddresses,
    } = props;

    const translator = useTranslator();

    const [propertyIncidentQuestionValue, setPropertyIncidentQuestion] = useState(() => {
        return propertyIncidents.length > 0 ? PropertyIncidentQuestionValue.Yes : PropertyIncidentQuestionValue.No
    })
    const [singlePropertyIncidentPopupStatus, setSinglePropertyIncidentPopupStatus] = useState({
        isOpen: false,
        originPropertyIncident: propertyIncidentInitValue,
        editingPropertyIncidentIndex: undefined
    })

    const vehicleIncidentAddressIDs = _.uniq(vehicleIncidents
        .map((vehicleIncident) => _.get(vehicleIncident, 'vehicleLocationPublicID')))

    const closePopup = () => {
        setSinglePropertyIncidentPopupStatus({
            isOpen: false,
            originPropertyIncident: propertyIncidentInitValue,
            editingPropertyIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = (propertyIncident, editingPropertyIncidentIndex, location) => {

        const isClaimAddress = predefinedLossLocations.some((address) => address.publicID === location.publicID)
        const isNewIncidentAddress = !isClaimAddress
        let updatedLocation = location
        let updatedPropertyIncident = propertyIncident
        if (isNewIncidentAddress) {
            const tempAddressID = getNewAddressTempId()
            updatedPropertyIncident = {...updatedPropertyIncident, locationPublicID_Ext: tempAddressID}
            updatedLocation = {...updatedLocation, publicID: tempAddressID}
        }

        let updatedPropertyIncidents
        if (_.isNil(editingPropertyIncidentIndex)) {
            // new added item
            updatedPropertyIncidents = propertyIncidents.concat([updatedPropertyIncident])
        } else {
            // existing item
            updatedPropertyIncidents = propertyIncidents.toSpliced(editingPropertyIncidentIndex, 1, updatedPropertyIncident)
        }
        // if (isNewIncidentAddress) {
        //     updatePropertyIncidents(updatedPropertyIncidents, updatedLocation)
        // } else {
        //     updatePropertyIncidents(updatedPropertyIncidents)
        // }
        updatePropertyIncidents(updatedPropertyIncidents, updatedLocation)
        closePopup()
    }

    return <>
        <ToggleField
            id='propertyIncidentQuestion'
            label={translator(messages.PropertyIncidentsQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: PropertyIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: PropertyIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setPropertyIncidentQuestion(value)
            }}
            disabled={propertyIncidents.length > 0}
            value={propertyIncidentQuestionValue}
        />
        {propertyIncidentQuestionValue === PropertyIncidentQuestionValue.Yes &&
            <>
                <Table
                    title={translator(messages.PropertySection)}
                    data={propertyIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                >
                    <DisplayColumn
                        id="propertySectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="propertyLocationColumn"
                        header={translator(messages.PropertyLocation)}
                        // path="location_Ext.displayName"
                        textAlign='center'
                        renderCell = {(item) => {
                            const addressPublicID = _.get(item, 'locationPublicID_Ext')
                            const allAddresses = predefinedLossLocations.concat(incidentsAddresses)
                            const matchedAddress = allAddresses.find((address) => address.publicID === addressPublicID)
                            return getFullAddressDisplayName(matchedAddress)
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <DropdownMenuButton
                                id={`DropdownMenuBtn_${index}`}
                                icon="gw-expand-more"
                                size="small"
                                iconPosition="right"
                                disabled={singlePropertyIncidentPopupStatus.isOpen}
                            >
                                <DropdownMenuLink
                                    icon="gw-edit"
                                    iconPosition="left"
                                    onClick={() => setSinglePropertyIncidentPopupStatus({
                                        isOpen: true,
                                        originPropertyIncident: item,
                                        editingPropertyIncidentIndex: index
                                    })}
                                >
                                    {translator(messages.Edit)}
                                </DropdownMenuLink>
                                <DropdownMenuLink
                                    icon="gw-delete"
                                    iconPosition="left"
                                    onClick={() => {
                                        const updatedPropertyIncidents = propertyIncidents.toSpliced(index, 1)
                                        updatePropertyIncidents(updatedPropertyIncidents)
                                    }}
                                >
                                    {translator(messages.Delete)}
                                </DropdownMenuLink>
                            </DropdownMenuButton>
                        }}
                    />
                </Table>
                <Button
                    type="outlined"
                    onClick={() => setSinglePropertyIncidentPopupStatus({
                        isOpen: true,
                        originPropertyIncident: propertyIncidentInitValue
                    })}
                    disabled={singlePropertyIncidentPopupStatus.isOpen}
                >
                    {translator(messages.AddProperty)}
                </Button>
                {singlePropertyIncidentPopupStatus.isOpen && <SinglePropertyIncident
                    originPropertyIncident={singlePropertyIncidentPopupStatus.originPropertyIncident}
                    editingPropertyIncidentIndex={singlePropertyIncidentPopupStatus.editingPropertyIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                    predefinedLossLocations={predefinedLossLocations}
                    incidentsAddresses={incidentsAddresses}
                    vehicleIncidentAddressIDs={vehicleIncidentAddressIDs}
                />}
            </>
        }
        
    </>
}

export default PropertyIncidents
