import { defineMessages } from 'react-intl';

export default defineMessages({
    paIncidentAndReports: {
        id: 'quoteandbind.pa.wizard.step.PAIncidentsAndReportsPage',
        // defaultMessage: 'Incidents & Report Validation'
        defaultMessage: 'Incidents and Reports',
    },
    paSVRClarification: {
        id: 'pa.quote.views.incident.mvr.userAdd.SVR Clarification',
        defaultMessage: 'SVR Clarification'
    },
    paDriverName: {
        id: 'pa.quote.views.prior loss.userAdd.Driver Name',
        defaultMessage: 'Driver Name'
    },
    paISOCategory: {
        id: 'pa.quote.views.incident.mvr.userAdd.ISO Category',
        defaultMessage: 'ISO Category'
    },
    paChargeable: {
        id: 'pa.quote.views.incident.mvr.userAdd.Chargeable',
        defaultMessage: 'Chargeable'
    },
    paIfCharged: {
        id: 'pa.quote.views.incident.mvr.userAdd.If Charged?',
        defaultMessage: 'If Charged?'
    },
    paDispute: {
        id: 'pa.quote.views.incident.mvr.userAdd.Dispute',
        defaultMessage: 'Dispute'
    },
    paExplanation: {
        id: 'pa.quote.views.incident.mvr.userAdd.Explanation',
        defaultMessage: 'Explanation'
    },
    paUnderWritingResponse: {
        id: 'pa.quote.views.incident.mvr.userAdd.UnderWritingResponse',
        defaultMessage: 'UnderWriting Response'
    },
    paEditPriorLoss: {
        id: 'pa.quote.views.incident.mvr.userAdd.Edit Prior Loss',
        defaultMessage: 'Edit Prior Loss'
    },
    paAddPriorLoss: {
        id: 'pa.quote.views.incident.mvr.userAdd.Add Prior Loss',
        defaultMessage: 'Add Prior Loss'
    },
    paAmountPaid: {
        id: 'pa.quote.views.prior loss.userAdd.Amount Paid',
        defaultMessage: 'Amount Paid'
    },
    paCoveragesPaid: {
        id: 'pa.quote.views.clue.Coverages Paid',
        defaultMessage: 'Coverages Paid'
    },
    paCarrierName: {
        id: 'pa.quote.views.clue.Carrier Name',
        defaultMessage: 'Carrier Name'
    },
    paPolicyholderName: {
        id: 'pa.quote.views.clue.Policy Holder Name',
        defaultMessage: 'policy Holder Name'
    },
    paDriver: {
        id: 'pa.quote.views.clue.Driver',
        defaultMessage: 'Driver'
    },
    paClueDriver: {
        id: 'pa.quote.views.clue.Clue Driver',
        defaultMessage: 'Clue Driver'
    },
    paMVRDriverName: {
        id: 'pa.quote.views.incident.mvr.userAdd.Driver Name',
        defaultMessage: 'Driver Name'
    },
    paPolicyDriver: {
        id: 'pa.quote.views.clue.Policy Driver',
        defaultMessage: 'Policy Driver'
    },
    paCategory: {
        id: 'pa.quote.views.clue.category',
        defaultMessage: 'Category'
    },
    paSource: {
        id: 'pa.quote.views.clue.source',
        defaultMessage: 'Source'
    },
    paDescription: {
        id: 'pa.quote.views.incidentsAndReports.Description',
        defaultMessage: 'Description'
    },
    paSurchargeable: {
        id: 'pa.quote.views.incidentsAndReports.Surchargeable',
        defaultMessage: 'Surchargeable'
    },
    paSurChargeApplied: {
        id: 'pa.quote.views.incidentsAndReports.SurchargeApplied',
        defaultMessage: 'Surcharge Applied'
    },
    paIncidentDate: {
        id: 'pa.quote.views.incidentsAndReports.Incident Date',
        defaultMessage: 'Incident Date'
    },
    paCarrier: {
        id: 'pa.quote.views.incidentsAndReports.Carrier',
        defaultMessage: 'Carrier'
    },
    paCancelledExpirationDate: {
        id: 'pa.quote.views.incidentsAndReports.Cancelled/Expiration Date',
        defaultMessage: 'Cancelled/Expiration Date'
    },
    paReasonNoinsurance: {
        id: 'pa.quote.views.incidentsAndReports.Reason for No insurance',
        defaultMessage: 'Reason for No insurance'
    },
    paTimewithPriorCarrier: {
        id: 'pa.quote.views.incidentsAndReports.Time With Prior Carrier',
        defaultMessage: 'Time With Prior Carrier'
    },
    paBIPriorCarrierLimits: {
        id: 'pa.quote.views.incidentsAndReports.BI Prior Carrier Limits',
        defaultMessage: 'BI Prior Carrier Limits'
    },
    paUnderwritingResponse: {
        id: 'pa.quote.views.incidentsAndReports.Underwriting Response',
        defaultMessage: 'Underwriting Response'
    },
    paDateofBirth: {
        id: 'pa.quote.views.clue.date of birth',
        defaultMessage: 'Date of Birth'
    },
    paVehicle: {
        id: 'pa.quote.views.clue.Vehicle',
        defaultMessage: 'Vehicle'
    },
    paStatus: {
        id: 'pa.quote.views.clue.Status',
        defaultMessage: 'Category Status'
    },
    userPriorCarrierStatus: {
        id: 'pa.quote.views.priorcarrier.userPriorCarrierStatus',
        defaultMessage: 'Status'
    },
    paReason: {
        id: 'pa.quote.views.clue.Reason',
        defaultMessage: 'Reason for No Insurance'
    },
    ModalOk: {
        id: 'pa.quote.views.modal.OK',
        defaultMessage: 'OK',
    },
    ModalCancel: {
        id: 'pa.quote.views.modal.Cancel',
        defaultMessage: 'Cancel',
    },
    ModaldeleteMsg: {
        id: 'pa.quote.views.modal.Are you confirm to delete this item ?',
        defaultMessage: 'Are you confirm to delete this item ?'
    },
    paVendorInformation: {
        id: 'pa.quote.views.accordion.vendor information',
        defaultMessage: 'Vendor Information',
    },
    paUserEnteredInformation: {
        id: 'pa.quote.views.accordion.user entered information',
        defaultMessage: 'user entered information',
    },
    paVendorReported: {
        id: 'pa.quote.views.incident.mvr.reported.Vendor Reported',
        defaultMessage: 'Vendor Reported',
    },
    paCLUEReportedLoss: {
        id: 'pa.quote.views.prior loss.CLUE.userAdd.User Enter',
        defaultMessage: 'Prior Loss Reported',
    },
    paAdd: {
        id: 'pa.quote.views.prior loss.userAdd.+ Add',
        defaultMessage: '+ Add',
    },
    paCurrentCarrierReported: {
        id: 'pa.quote.views.incident.reported.Current Carrier Reported',
        defaultMessage: 'Current Carrier Reported',
    },
    paCurrenClaimsReported: {
        id: 'pa.quote.views.incident.reported.Current Claims Reported',
        defaultMessage: 'Current Policy Claims Reported',
    },
    paLapse: {
        id: 'pa.quote.views.prior.carrier.Lapse',
        defaultMessage: 'Lapse'
    },
    paDisputeRequest: {
        id: 'pa.quote.views.prior.carrier.Dispute Request',
        defaultMessage: 'Dispute Request'
    },
    paDisputeExplanation: {
        id: 'pa.quote.views.prior.carrier.Explanation',
        defaultMessage: 'Explanation'
    },
    paCarrierUnderwritingResponse: {
        id: 'pa.quote.views.prior.carrier.Underwriting Response',
        defaultMessage: 'Underwriting Response'
    },
    paUnderwritingReason: {
        id: 'pa.quote.views.prior.carrier.Underwriting Reason',
        defaultMessage: 'Underwriting Reason'
    },
    paEditPriorCarrier: {
        id: 'pa.quote.views.prior.carrier.Edit Prior Carrier',
        defaultMessage: 'Edit Prior Carrier',
    },
    paAddPriorCarrier: {
        id: 'pa.quote.views.prior.carrier.Add Prior Carrier',
        defaultMessage: 'Add Prior Carrier',
    },
    paEffectiveDate: {
        id: 'pa.quote.views.incidentsAndReports.Effective Date',
        defaultMessage: 'Effective Date'
    },
    paReasonForNoInsurance: {
        id: 'pa.quote.views.incidentsAndReports.Reason For No Insurance',
        defaultMessage: 'Reason For No Insurance'
    },
    paDisputeTooltip: {
        id: 'pa.quote.views.incidentsAndReports.dispute tooltip',
        defaultMessage: 'If this incident does not apply, please check the box and add an explanation for the underwriter to review.'
    },
    priorCarrierTooltip: {
        id: 'pa.quote.views.incidentsAndReports.prior carrier.dispute tooltip',
        defaultMessage: 'If any prior carrier information is incorrect, please select the field in question for an underwriter to review'
    },
    paMVRDisputeTooltip: {
        id: 'pa.quote.views.incidentsAndReports.MVR.dispute tooltip',
        defaultMessage: 'If this incident does not apply, select the appropriate reason for an underwriter to review',
    },
    paOtherCarrierName: {
        id: 'pa.quote.views.prior.carrier.Other Carrier Name',
        defaultMessage: 'Other Carrier Name'
    },
    paLossDate: {
        id: 'pa.quote.views.policyclaims.Loss Date',
        defaultMessage: 'Loss Date'
    },
    paLossCause: {
        id: 'pa.quote.views.policyclaims.Loss Cause',
        defaultMessage: 'Loss Cause'
    },
    paTotalPaid: {
        id: 'pa.quote.views.policyclaims.Incurred Amount',
        defaultMessage: 'Incurred Amount'
    },
    paClaimsSurchargeable: {
        id: 'pa.quote.views.policyclaims.Surchargeable',
        defaultMessage: 'Surchargeable'
    },
    paClaimsSurchargeApplied: {
        id: 'pa.quote.views.policyclaims.Surcharge Applied',
        defaultMessage: 'Surcharge Applied'
    },
    paClaimsDriver: {
        id: 'pa.quote.views.policyclaims.Driver',
        defaultMessage: 'Driver'
    },
    AddPriorLossesEntry: {
        id: 'pa.quote.views.prior.losses entry.Add Prior Losses Entry',
        defaultMessage: 'Add Prior Loss Entry'
    },
    EditPriorLossesEntry: {
        id: 'pa.quote.views.prior.losses entry.Edit Prior Losses Entry',
        defaultMessage: 'Edit Prior Loss Entry'
    },
    ViewPriorLossesEntry: {
        id: 'pa.quote.views.prior.losses entry.View Prior Losses Entry',
        defaultMessage: 'View Prior Loss Entry'
    },
    ViewPriorLossesVendor: {
        id: 'pa.quote.views.prior.losses entry.View Prior Losses Vendor',
        defaultMessage: 'View Prior Loss Reports'
    },
    EditPriorLossesVendor: {
        id: 'pa.quote.views.prior.losses entry.Edit Prior Losses Vendor',
        defaultMessage: 'Edit Prior Loss Reports'
    },
    DriverHousehold: {
        id: 'pa.quote.incident.Driver no longer in household',
        defaultMessage: 'Driver no longer in household'
    },
    NotApplicable: {
        id: 'pa.quote.incident.views.Not applicable',
        defaultMessage: 'Not applicable'
    },
    PermissiveDriver: {
        id: 'pa.quote.incident.views.Permissive Driver',
        defaultMessage: 'Permissive Driver'
    },
    Unoccupied: {
        id: 'pa.quoteincident.views.Unoccupied',
        defaultMessage: 'Unoccupied'
    },
    CreditAuth1: {
        id: 'pa.quote.title.CreditAuth1',
        defaultMessage: 'Policy premium is calculated in part based on information obtained from consumer reports. Agents are required to inform customers that they will be requesting information from consumer reports as part of the quoting process.'
    },
    CreditAuth2: {
        id: 'pa.quote.title.CreditAuth2',
        defaultMessage: 'Please note that reports will be run during the Run Validation step.  To ensure accuracy of the quote, please enter losses and violations manually. For a more accurate quote, please enter all incident information from the last 5 years below:'
    },
    otherThanCollisionDetails: {
        id: 'pa.quote.views.coverage.paid.Other Than Collision Details',
        defaultMessage: 'Other Than Collision Details'
    },
    manuallyClueHeader: {
        id: 'pa.quote.incident.Prior Losses Records',
        defaultMessage: 'Prior Losses { length } Record'
    },
    manuallyMVRHeader: {
        id: 'pa.quote.incident.Violation Records',
        defaultMessage: 'Violation { length } Record'
    }
});
