
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { MetadataContent } from '@jutro/uiconfig';
// import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';

import { RTMultiQuoteService } from 'wni-capability-quoteandbind-rt';

import { BaseAddNewVersionComponent } from 'wni-components-platform-react';

import { QuoteCommonMessages } from 'wni-platform-translations';

// import metadata from './RTAddNewVersionComponent.metadata.json5';
// import styles from './RTAddNewVersionComponent.module.scss';
// import messages from './RTAddNewVersionComponent.messages';



/**
 * RT Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function RTAddNewVersionComponent(props) {
    

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const {
        // model: dtoVM,
        // onValueChange,
        // showErrors,
        sxsDataDTO = {},
        ...restProps
    } = props;


    const {
        // canAddNewHO3Version,
        // canAddNewHO5Version,
        // canAddNewVersion,
        // canAddNewSelectVersion,
        periods = [{}],
    } = sxsDataDTO;

    const [{
        branchCode,
        policyType_Ext: policyType,
    }] = periods;


    useEffect(() => {
    }, []);

    
    const getRTMenuItems = useCallback((sxsDataDTOParam) => {
        const {
            // canAddNewHO3Version,
            // canAddNewHO5Version,
            canAddNewVersion,
            // canAddNewSelectVersion,
            // periods = [{}],
        } = sxsDataDTOParam;

        const menuItems = [
            {  
                menuName: translator(QuoteCommonMessages.addAnotherVersionOfThisQuote),
                createService: RTMultiQuoteService.createDuplicateQuote,
                menuVisible: canAddNewVersion,
            },
            // {  
            //     menuName: translator(messages.createHO3Version),
            //     createService: RTMultiQuoteService.createHO3Quote,
            //     menuVisible: canAddNewHO3Version,
            // },
            // {  
            //     menuName: translator(messages.createHO5Verseion),
            //     createService: RTMultiQuoteService.createHO5Quote, // Hmm.
            //     menuVisible: canAddNewHO5Version,
            // },
            // {  
            //     // menuName: translator(messages.createSelectVersionParam, { policyType: normalizedPolicyType }),
            //     menuName: translator(messages.createSelectVersionParam),
            //     createService: RTMultiQuoteService.createSelectQuote,
            //     menuVisible: canAddNewSelectVersion,
            // },
        ];
        
        return menuItems;

    }, []);

    const rtMenuItems = getRTMenuItems(sxsDataDTO);

    return (
        <BaseAddNewVersionComponent {...restProps}
            menuItems={rtMenuItems}
        />
    );

}

RTAddNewVersionComponent.propTypes = {
    sxsDataDTO: PropTypes.shape({}).isRequired,
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
    // /**
    //  * (Optinal) get menu items
    //  */
    // getMenuItems: PropTypes.func,
};

RTAddNewVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,
    // getMenuItems: undefined,
};

export default RTAddNewVersionComponent;
