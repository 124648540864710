import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext'
import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil'
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM'
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM'
import { AccordionCard } from '@jutro/legacy/components';

const SingleLineAdditionalCoveragesAccordionCard = (props) => {
    const {
        state,
        singleSublineClauses,
        setSingleLineClauses,
        submissionVM,
        updateWizardData,
        onValidate: multiSublinesOnValidate,
        isEditing,
        setIsEditing,
        loadingClauses,
        setLoadingClauses,
        showErrors,
        isEditable,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        publicID,
        sublineTypeDisplayName,
        additionalCoverage,
    } = singleSublineClauses

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const validationID = `SingleSublineAdditionalCoverage_${publicID}`

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (multiSublinesOnValidate) {
            multiSublinesOnValidate(isComponentValid, validationID);
        }
        return () => {
            if (multiSublinesOnValidate) {
                multiSublinesOnValidate(true, validationID);
            }
        }
    }, [isComponentValid, multiSublinesOnValidate, validationID])

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(additionalCoverage, 'additionalCoverage', value, changedPath);
            const newSingleSublineClauses = _.clone(singleSublineClauses)
            _.set(newSingleSublineClauses, 'additionalCoverage', newClauses);
            setSingleLineClauses(newSingleSublineClauses);
            setIsEditing(false);
            return newSingleSublineClauses;
        },
        [additionalCoverage, setIsEditing, setSingleLineClauses, singleSublineClauses]
    );

    const updateVMToServer = useCallback(async (newSublineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const subLineClausesToUpdate = GLCoverageUtil.generateUpdatedStateSpecificSingleLineClausesDTO(newSublineClauses);
        const updatedSublineClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedStateSublineClausesDTO(subLineClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedSublineClausesPublicIDs)
        const sublineClausesFromServer = await GLCoverageService.updatStatePerSublineClauses(
            jobID,
            sessionUUID,
            state,
            publicID,
            subLineClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setSingleLineClauses(sublineClausesFromServer);
    }, [setLoadingMask, jobID, sessionUUID, state, publicID, authHeader, setSingleLineClauses]);

    const updateNewSubLineClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeSubmission(value, changedPath);
            
            updateNewSubLineClausesToServer(newSublineClauses);
        },
        [changeSubmission, updateNewSubLineClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewSubLineClausesToServer(singleSublineClauses);
        },
        [singleSublineClauses, updateNewSubLineClausesToServer]
    )

    return <AccordionCard
        id={`stateSpecificInformation_singleLine_${publicID}_AdditionalCoverages`}
        chevron
        className="coveragesAccordion"
        cardHeadingClassName="coveragesAccordionHeader font-capitalize"
        title={sublineTypeDisplayName}
        errorState={showErrors && !isComponentValid}
    >
        <div className="formCoveragesContainer">
            <CoverageContext.Provider
                value={{
                    wizardData: submissionVM,
                    updateWizardData: updateWizardData,
                    updateVMToServer: updateVMToServer
                }}
            >
                <GLClausesComponentVM
                    splitByClauseCategory={false}
                    sortBySelected
                    onSyncCoverages={onSyncCoverages}
                    onChangeSubmissionAndSync={changeSubmissionAndSync}
                    onChangeClause={changeSubmission}
                    onValidate={onValidate}
                    showAmount={false}
                    loadingClause={loadingClauses}
                    path="additionalCoverage"
                    value={additionalCoverage}
                    showErrors={showErrors}
                    componentMapOverrides={{
                        GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                    }}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    isEditable={isEditable}
                    hideCheckBoxForRequired
                />
            </CoverageContext.Provider>
                
        </div>
    </AccordionCard>
}

export default SingleLineAdditionalCoveragesAccordionCard
