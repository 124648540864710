import _ from 'lodash';

function RTClaimExtension(claim) {
    Object.defineProperty(this, 'claim', {
        enumerable: false,
        value: claim
    });
    if (claim && claim.lobs && claim.lobs.roadTrail) {
        _.extend(this, claim.lobs.roadTrail);

        this.contacts = this.claim.contacts || [];

        _.each(this.claim.lobs.roadTrail.vehicleIncidents, _.bind((vi) => {
            const vehicle = vi;
            vehicle.driver = this.claim.findContact(this.claim, vehicle.driver);
            _.each(vehicle.passengers, _.bind((p, index) => {
                vehicle.passengers[index] = this.claim.findContact(this.claim, p);
            }, this));
            // watercraft.watercraft = this.findVehicle(watercraft.watercraft);
        }, this));
    }
}

export default RTClaimExtension
