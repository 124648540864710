import { defineMessages } from 'react-intl';

export default defineMessages({
    coverageEndDate: {
        id: 'quoteandbind.pa.views.pa-policy-info.Coverage End Date',
        defaultMessage: 'Coverage End Date',
    },
    totalPremium: {
        id: 'quoteandbind.pa.views.pa-policy-info.Total Premium(Inclusive of Taxes & Fees)',
        defaultMessage: 'Total Premium(Inclusive of Taxes & Fees)',
    },
    QuoteDocuments: {
        id: 'quoteandbind.pa.views.pa-policy-info.Quote Documents',
        defaultMessage: 'Quote Documents',
    },
    RetaininAgency: {
        id: 'quoteandbind.pa.views.pa-policy-info.Retain in Agency',
        defaultMessage: 'Retain in Agency',
    },
    WesternNational: {
        id: 'quoteandbind.pa.views.pa-policy-info.Submit to Western National',
        defaultMessage: 'Submit to { domainName }',
    },
    UmialikInsurance: {
        id: 'quoteandbind.pa.views.pa-policy-info.Submit to Umialik Insurance',
        defaultMessage: 'Submit to Umialik Insurance',
    },
    UserAgreement: {
        id: 'quoteandbind.pa.views.pa-policy-info.User Agreement',
        defaultMessage: 'User Agreement',
    },
    UserAggreenmenHeader: {
        id: 'quoteandbind.pa.views.pa-policy-info.user Aggreenmen Header',
        defaultMessage: 'By clicking the "Finish Quote" button below, you also agree that the following statements are true: '
    },
    UserAggreenmenli1: {
        id: 'quoteandbind.pa.views.pa-policy-info.user Aggreenmen li1',
        defaultMessage: 'You certify that the applicant is not aware of any losses/claims/violations/conditions that may result in a claim asserted on or after the Effective Date of the policy, except those already disclosed.'
    },
    UserAggreenmenli2: {
        id: 'quoteandbind.pa.views.pa-policy-info.user Aggreenmen li2',
        defaultMessage: 'Signed Application forms with the Insured’s signature will be retained and made available at the request of {domainName}.'
    },
    CreateHomeownerQuote: {
        id: 'quoteandbind.pa.views.pa-policy-info.Create a Home Quote',
        defaultMessage: 'Create a Home Quote'
    },
    Pending: {
        id: 'quoteandbind.pa.views.pa-policy-info.Pending',
        defaultMessage: 'Pending'
    },
    PrimaryInsuredContactDetails: {
        id: 'quoteandbind.pa.views.pa-policy-info.Primary Insured Contact Details',
        defaultMessage: 'Primary Insured Contact Details'
    },
    selectAutoVariety: {
        id: 'quoteandbind.pa.views.pa-policy-info.inputs.SelectAuto variety',
        defaultMessage: 'Our Select Auto program offers a variety of coverages designed to meet your personal auto needs.'
    },
    selectAutoService: {
        id: 'quoteandbind.pa.views.pa-policy-info.inputs.SelectAuto service',
        defaultMessage: 'Our expert Claim Representatives are committed to providing you with fair, helpful, and timely claim service, every time.'
    },
    signatureAutoFamily: {
        id: 'quoteandbind.pa.views.pa-policy-info.inputs.SignatureAuto family',
        defaultMessage: 'Family members who have Signature Auto policies with us reach receive a discount, even if they live in different households!'
    },
    signatureAutoService: {
        id: 'quoteandbind.pa.views.pa-policy-info.inputs.SignatureAuto service',
        defaultMessage: 'Our expert Claim Representatives are committed to providing you with fair, helpful, and timely claim service, every time.'
    },
});
