import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { WniDropdownSelect } from 'wni-common-base-components';

const TermFieldDropdown = (props) => {
    const {
        term,
        setScheduleItemFunc,
        onValidate: onContainerValidate,
        showErrors,
        isEditable,
        onChangeScheduleItem
    } = props;

    const { chosenTerm, options, code_Ext: termCode, name } = term;

    const { onValidate, isComponentValid } = useValidation(termCode);

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, termCode);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, termCode);
            }
        };
    }, [isComponentValid, onContainerValidate, termCode]);

    const handleValueChange = async (newChosenTerm) => {
        const updatedTerm = {
            ...term,
            chosenTerm: newChosenTerm,
            updated: true,
        };
        setScheduleItemFunc((oldScheduleItem) => {
            const newScheduleItem = _.clone(oldScheduleItem);
            let newTerms = _.get(
                oldScheduleItem,
                'scheduleItemTerms',
                []
            ).map((t) => {
                if (t.code_Ext === termCode) {
                    return updatedTerm;
                }
                return t;
            });
            if (_.isFunction(onChangeScheduleItem)) {
                newTerms = onChangeScheduleItem(newTerms, updatedTerm);
            }
            _.set(newScheduleItem, 'scheduleItemTerms', newTerms);
            return newScheduleItem;
        });
    };

    const readOnlyCausedByOnlyOneAvailableValue =
        options.length === 1 && options[0].code === chosenTerm;
    return (
        <WniDropdownSelect
            id={termCode}
            label={name}
            onValueChange={handleValueChange}
            showErrors={showErrors}
            availableValues={options}
            value={term.chosenTerm}
            readOnly={
                !isEditable ||
                readOnlyCausedByOnlyOneAvailableValue ||
                term.readOnly_Ext
            }
            required={term.required}
            showRequired={term.required}
            onValidationChange={onValidate}
        />
    );
};

export default TermFieldDropdown;
