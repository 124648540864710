import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { WniDriverService, WniPAQuoteService } from 'wni-capability-quoteandbind';
import { AllDriversCoveragesComponent, LineCoveragesInputComponent } from 'wni-capability-gateway-react';
import { QuoteUtil } from 'wni-portals-util-js';
import { R1Config } from 'wni-portals-config-js';
import PAVehicleCoveragesSection from './components/PAVehicleCoveragesSection/PAVehicleCoveragesSection';

import metadata from './PACoveragesPage.metadata.json5';

const { PACoverageConfig } = R1Config;

function PACoveragesReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        policyChangeService = {}
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [driverCoverages, updateDriverCoverages] = useState();
    const [updateVehicleValidationIssues] = useState([]);
    const [showDriversLoader, updateShowDriversLoader] = useState(false);
    const [coveragesErrorsAndWarnings, updateCoveragesErrorsAndWarnings] = useState({
        errors: [],
        warnings: []
    });
    const { authHeader } = useAuthentication();
    const {
        initialValidation,
        onValidate,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation('CoveragesPage');
    const {
        quoteID,
        sessionUUID,
        baseData: {
            jobType,
            selectedVersion_Ext: selectedVersion
        },
        lobData: {
            personalAuto: {
                offerings
            }
        }
    } = submissionVM.value;

    const selectedVersionOfferingsIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersion);

    const [showLoader, updateShowLoader] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [loadingClause, updateLoadingClause] = useState();

    const coveragesPath = 'value.lobData.personalAuto.offerings[0].coverages';
    const otherCoverages = _.get(submissionVM, coveragesPath);

    useEffect(() => {
        setPageInitialized(true);
    }, []);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            return false;
        },
        [updateShowErrors]
    );

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        coveragesAccordion: {
            accordionStates: ['lineCoverages', 'vehicleCoverages', 'driverCoverages']
        },
        lineCoveragesInputSection: {
            submissionVM,
            updateSubmissionVM: updateWizardSnapshot,
            onValidate,
            disregardFieldValidation,
            LoadSaveService: jobType === 'Submission' ? WniPAQuoteService : LoadSaveService,
            authHeader,
            viewModelService,
            showErrors,
            updateCoveragesErrorsAndWarnings,
            selectedVersionOfferingsIndex: selectedVersionOfferingsIndex,
            isReadOnly: true,
            lobName: 'personalAuto',
            lineCoverageFilter: PACoverageConfig.isNotDriverCoverage,
        },
        vehicleCoveragesSection: {
            onValidate,
            disregardFieldValidation,
            submissionVM,
            updateWizardSnapshot,
            updateVehicleValidationIssues,
            isReadOnly: true,
            registerComponentValidation,
        },
        driverCoveragesSection: {
            onValidate,
            submissionVM,
            driverCoverages,
            loadingClause,
            showLoader: showDriversLoader,
            readOnly: true
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    const onPageNext = useCallback(async () => {
        return submissionVM;
    }, [submissionVM, showErrors]);

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveComponentMap: {
            ValidationIssuesComponent: ValidationIssuesComponent,
            linecoveragesinputcomponent: LineCoveragesInputComponent,
            driverCoverages: AllDriversCoveragesComponent,
            PAVehicleCoveragesSection: PAVehicleCoveragesSection
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onPageNext}
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            alwaysCallOnNext
            disableNext={showLoader}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        resolveValue={readValue}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardSnapshot}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PACoveragesReadOnlyPage.propTypes = wizardProps;
export default PACoveragesReadOnlyPage;
