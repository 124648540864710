
import React from 'react';
import { CPModifiersReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPModifiersChangeReadOnlyPage(props) {

    return (
        <CPModifiersReadOnlyPage {...props} />
    );

}

CPModifiersChangeReadOnlyPage.propTypes = CPModifiersReadOnlyPage.propTypes;
CPModifiersChangeReadOnlyPage.defaultProps = CPModifiersReadOnlyPage.defaultProps;

export default CPModifiersChangeReadOnlyPage;