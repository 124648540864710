import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import IMDummyPage from './pages/Dummy/IMDummyPage';
import IMDummyReadOnlyPage from './pages/Dummy/IMDummyPage.readonly';

import IMPolicyDetailsPage  from './pages/PolicyDetails/IMPolicyDetailsPage';
import IMPolicyDetailsReadOnlyPage from './pages/PolicyDetails/IMPolicyDetailsPage.readonly';

import IMCoveragePartSelectionPage from './pages/CoveragePartSelection/IMCoveragePartSelectionPage';
import IMCoveragePartSelectionReadOnlyPage from './pages/CoveragePartSelection/IMCoveragePartSelectionPage.readonly';

import IMBuildingsAndLocationsPage from './pages/BuildingsAndLocations/IMBuildingsAndLocationsPage';
import IMBuildingsAndLocationsReadOnlyPage  from './pages/BuildingsAndLocations/IMBuildingsAndLocationsPage.readonly';

import IMModifiersPage from './pages/Modifiers/IMModifiersPage';
import IMModifiersReadOnlyPage from './pages/Modifiers/IMModifiersPage.readonly';

/** AAIS --START  */
import IMAAISCommonFormsPage from './pages/AAISCommonForms/IMAAISCommonFormsPage';
import IMAAISCommonFormsReadOnlyPage from './pages/AAISCommonForms/IMAAISCommonFormsPage.readonly';

import IMBuildersRiskPage from './pages/IMBuildersRisk/IMBuildersRiskPage';
import IMBuildersRiskReadOnlyPage from './pages/IMBuildersRisk/IMBuildersRiskPage.readonly';

import IMBoatCoveragePage from './pages/IMBoatCoverage/IMBoatCoveragePage';
import IMBoatCoverageReadOnlyPage from './pages/IMBoatCoverage/IMBoatCoveragePage.readonly';

import IMContractorsEquipmentPage from './pages/ContractorsEquipment/IMContractorsEquipmentPage';
import IMContractorsEquipmentReadOnlyPage from './pages/ContractorsEquipment/IMContractorsEquipmentPage.readonly';

import IMFineArtsFloaterPage from './pages/IMFineArtsFloater/IMFineArtsFloaterPage';
import IMFineArtsFloaterReadOnlyPage from './pages/IMFineArtsFloater/IMFineArtsFloaterPage.readonly';

import IMRiggersCoveragePage from './pages/RiggersCoverage/IMRiggersCoveragePage';
import IMRiggersCoverageReadOnlyPage from './pages/RiggersCoverage/IMRiggersCoveragePage.readonly';

import IMMotorTruckCargoPage from './pages/IMMotorTruckCargo/IMMotorTruckCargoPage';
import IMMotorTruckCargoReadOnlyPage from './pages/IMMotorTruckCargo/IMMotorTruckCargoPage.readonly';

import IMBaileeCustomersFloaterPage from './pages/IMBaileeCustomersFloater/IMBaileeCustomersFloaterPage';
import IMBaileeCustomersFloaterReadOnlyPage from './pages/IMBaileeCustomersFloater/IMBaileeCustomersFloaterPage.readonly';

/** AAIS --END  */

/** ISO --START  */
import IMCPPCommonFormsPage from './pages/CPPCommonForms/IMCPPCommonFormsPage';
import IMCPPCommonFormsReadOnlyPage from './pages/CPPCommonForms/IMCPPCommonFormsPage.readonly';

import IMAccountsReceivablePage from './pages/IMAccountsReceivable/IMAccountsReceivablePage';
import IMAccountsReceivableReadOnlyPage from './pages/IMAccountsReceivable/IMAccountsReceivablePage.readonly'
// ISO
import IMValuablePapersPage from './pages/IMValuablePapers/IMValuablePapersPage';
import IMValuablePapersReadOnlyPage from './pages/IMValuablePapers/IMValuablePapersPage.readonly'
// ISO
import IMPhysiciansAndSurgeonsPage from './pages/PhysiciansAndSurgeons/IMPhysiciansAndSurgeonsPage'
import IMPhysiciansAndSurgeonsReadOnlyPage from './pages/PhysiciansAndSurgeons/IMPhysiciansAndSurgeonsPage.readonly'
// ISO
import IMSignsPage from './pages/IMSigns/IMSignsPage';
import IMSignsReadOnlyPage from './pages/IMSigns/IMSignsPage.readonly'
/** ISO --END  */

import IMMiscFormsPage from './pages/IMMiscForms/IMMiscFormsPage'
import IMMiscFormsReadOnlyPage from './pages/IMMiscForms/IMMiscFormsPage.readonly'

import IMUnderwritingQuestionsPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsPage';
import IMUnderwritingQuestionsReadOnlyPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsPage.readonly';

import IMElectronicDataProcessingPage from './pages/IMElectronicDataProcessing/ElectronicDataProcessingPage';
import IMElectronicDataProcessingReadOnlyPage from './pages/IMElectronicDataProcessing/ElectronicDataProcessingPage.readonly';

import IMQuotePage  from './pages/Quote/IMQuotePage';
import IMQuoteReadOnlyPage from './pages/Quote/IMQuotePage.readonly';

import IMMiscellaneousFloatersPage from './pages/MiscellaneousFloaters/IMMiscellaneousFloatersPage';
import IMMiscellaneousFloatersReadOnlyPage from './pages/MiscellaneousFloaters/IMMiscellaneousFloatersPage.readonly';

import IMWarehouseLegalLiabilityPage from './pages/WarehouseLegalLiability/IMWarehouseLegalLiabilityPage';
import IMWarehouseLegalLiabilityReadOnlyPage from './pages/WarehouseLegalLiability/IMWarehouseLegalLiabilityPage.readonly';

import IMTransitPage from './pages/Transit/IMTransitPage';
import IMTransitReadOnlyPage from './pages/Transit/IMTransitPage.readonly';

import IMInstallationFloaterPage from './pages/IMInstallationFloater/IMInstallationFloaterPage';
import IMInstallationFloaterReadOnlyPage from './pages/IMInstallationFloater/IMInstallationFloaterPage.readonly';

import IMMuseumPage from './pages/Museum/IMMuseumPage';
import IMMuseumReadOnlyPage from './pages/Museum/IMMuseumPage.readonly';

import IMRiskAnalysisPage from './pages/RiskAnalysis/IMRiskAnalysisPage';
import IMRiskAnalysisReadOnlyPage from './pages/RiskAnalysis/IMRiskAnalysisPage.readonly';

setComponentMapOverrides(
    {
        IMDummyPage: {component: 'IMDummyPage'},
        IMDummyReadOnlyPage: { component: 'IMDummyReadOnlyPage' },

        IMPolicyDetailsPage: {component: 'IMPolicyDetailsPage'},
        IMPolicyDetailsReadOnlyPage: { component: 'IMPolicyDetailsReadOnlyPage' },
        
        IMCoveragePartSelectionPage: {component: 'IMCoveragePartSelectionPage'},
        IMCoveragePartSelectionReadOnlyPage: {component: 'IMCoveragePartSelectionReadOnlyPage'},

        IMBuildingsAndLocationsPage: {component: 'IMBuildingsAndLocationsPage'},
        IMBuildingsAndLocationsReadOnlyPage: {component: 'IMBuildingsAndLocationsReadOnlyPage'},

        IMModifiersPage: {component: 'IMModifiersPage'},
        IMModifiersReadOnlyPage: {component: 'IMModifiersReadOnlyPage'},
        
        /**  AAIS -- START */

        IMAAISCommonFormsPage: {component: 'IMAAISCommonFormsPage'},
        IMAAISCommonFormsReadOnlyPage: {component: 'IMAAISCommonFormsReadOnlyPage'},

        IMPhysiciansAndSurgeonsPage: {component: 'IMPhysiciansAndSurgeonsPage'},
        IMPhysiciansAndSurgeonsReadOnlyPage: {component: 'IMPhysiciansAndSurgeonsReadOnlyPage'},
    
        IMBuildersRiskPage: {component: 'IMBuildersRiskPage'},
        IMBuildersRiskReadOnlyPage: {component: 'IMBuildersRiskReadOnlyPage'},

        IMContractorsEquipmentPage: {component: 'IMContractorsEquipmentPage'},
        IMContractorsEquipmentReadOnlyPage: {component: 'IMContractorsEquipmentReadOnlyPage'},

        IMFineArtsFloaterPage: {component : 'IMFineArtsFloaterPage'},
        IMFineArtsFloaterReadOnlyPage: {component : 'IMFineArtsFloaterReadOnlyPage'},

        IMRiggersCoveragePage: {component: 'IMRiggersCoveragePage'},
        IMRiggersCoverageReadOnlyPage: {component: 'IMRiggersCoverageReadOnlyPage'},
 
        IMBoatCoveragePage: {component: 'IMBoatCoveragePage'},
        IMBoatCoverageReadOnlyPage: {component: 'IMBoatCoverageReadOnlyPage'},

        IMMotorTruckCargoPage: {component: 'IMMotorTruckCargoPage'},
        IMMotorTruckCargoReadOnlyPage: {component: 'IMMotorTruckCargoReadOnlyPage'},

        IMWarehouseLegalLiabilityPage: {component: 'IMWarehouseLegalLiabilityPage'},
        IMWarehouseLegalLiabilityReadOnlyPage: {component: 'IMWarehouseLegalLiabilityReadOnlyPage'},

        
        IMMiscFormsPage: {component: 'IMMiscFormsPage'},
        IMMiscFormsReadOnlyPage: {component: 'IMMiscFormsReadOnlyPage'},

        IMBaileeCustomersFloaterPage: {component: 'IMBaileeCustomersFloaterPage'},
        IMBaileeCustomersFloaterReadOnlyPage: {component: 'IMBaileeCustomersFloaterReadOnlyPage'},

        /**  AAIS -- END */
        /**  ISO -- START */
        IMCPPCommonFormsPage: {component: 'IMCPPCommonFormsPage'},
        IMCPPCommonFormsReadOnlyPage: {component: 'IMCPPCommonFormsReadOnlyPage'},

        IMAccountsReceivablePage: {component: 'IMAccountsReceivablePage'},
        IMAccountsReceivableReadOnlyPage: {component: 'IMAccountsReceivableReadOnlyPage'},

        IMValuablePapersPage: {component: 'IMValuablePapersPage'},
        IMValuablePapersReadOnlyPage: {component: 'IMValuablePapersReadOnlyPage'},

        IMSignsPage: {component: 'IMSignsPage'},
        IMSignsReadOnlyPage: {component: 'IMSignsReadOnlyPage'},
        /**  ISO -- END */

        IMUnderwritingQuestionsPage: {component: 'IMUnderwritingQuestionsPage'},
        IMUnderwritingQuestionsReadOnlyPage: { component: 'IMUnderwritingQuestionsReadOnlyPage' },

        IMQuotePage: {component: 'IMQuotePage'},
        IMQuoteReadOnlyPage: {component: 'IMQuoteReadOnlyPage'},

        IMMiscellaneousFloatersPage: {component: 'IMMiscellaneousFloatersPage'},
        IMMiscellaneousFloatersReadOnlyPage: {component: 'IMMiscellaneousFloatersReadOnlyPage'},

        IMMuseumPage: {component: 'IMMuseumPage'},
        IMMuseumReadOnlyPage: {component: 'IMMuseumReadOnlyPage'},

        IMElectronicDataProcessingPage: {component : 'IMElectronicDataProcessingPage'},
        IMElectronicDataProcessingReadOnlyPage: {component : 'IMElectronicDataProcessingReadOnlyPage'},

        IMTransitPage: {component : 'IMTransitPage'},
        IMTransitReadOnlyPage: {component : 'IMTransitReadOnlyPage'},

        IMInstallationFloaterPage: {component : 'IMInstallationFloaterPage'},
        IMInstallationFloaterReadOnlyPage: {component : 'IMInstallationFloaterReadOnlyPage'},
        
        IMRiskAnalysisPage: { component: 'IMRiskAnalysisPage' },
        IMRiskAnalysisReadOnlyPage: { component: 'IMRiskAnalysisReadOnlyPage' },
    },
    {
        IMDummyPage,
        IMDummyReadOnlyPage,

        IMPolicyDetailsPage,
        IMPolicyDetailsReadOnlyPage,

        IMCoveragePartSelectionPage,
        IMCoveragePartSelectionReadOnlyPage,
        
        IMBuildingsAndLocationsPage,
        IMBuildingsAndLocationsReadOnlyPage,

        IMModifiersPage,
        IMModifiersReadOnlyPage,

        /**  AAIS -- START */
        IMAAISCommonFormsPage,
        IMAAISCommonFormsReadOnlyPage,

        IMMiscFormsPage,
        IMMiscFormsReadOnlyPage,
        
        IMBuildersRiskPage,
        IMBuildersRiskReadOnlyPage,

        IMBoatCoveragePage,
        IMBoatCoverageReadOnlyPage,

        IMContractorsEquipmentPage,
        IMContractorsEquipmentReadOnlyPage,

        IMFineArtsFloaterPage,
        IMFineArtsFloaterReadOnlyPage,

        IMRiggersCoveragePage,
        IMRiggersCoverageReadOnlyPage,

        IMMotorTruckCargoPage,
        IMMotorTruckCargoReadOnlyPage,

        IMWarehouseLegalLiabilityPage,
        IMWarehouseLegalLiabilityReadOnlyPage,

        IMBaileeCustomersFloaterPage,
        IMBaileeCustomersFloaterReadOnlyPage,
        /**  AAIS -- END */
        
        /**  ISO -- START */
        IMCPPCommonFormsPage,
        IMCPPCommonFormsReadOnlyPage,

        IMAccountsReceivablePage,
        IMAccountsReceivableReadOnlyPage,

        IMValuablePapersPage,
        IMValuablePapersReadOnlyPage,
        
        IMPhysiciansAndSurgeonsPage,
        IMPhysiciansAndSurgeonsReadOnlyPage,

        IMSignsPage,
        IMSignsReadOnlyPage,
        /**  ISO -- END */

        IMUnderwritingQuestionsPage,
        IMUnderwritingQuestionsReadOnlyPage,

        IMQuotePage,
        IMQuoteReadOnlyPage,

        IMMiscellaneousFloatersPage,
        IMMiscellaneousFloatersReadOnlyPage,

        IMElectronicDataProcessingPage,
        IMElectronicDataProcessingReadOnlyPage,

        IMTransitPage,
        IMTransitReadOnlyPage,

        IMInstallationFloaterPage,
        IMInstallationFloaterReadOnlyPage,

        IMMuseumPage,
        IMMuseumReadOnlyPage,

        IMRiskAnalysisPage,
        IMRiskAnalysisReadOnlyPage
    }
);


export {
    IMDummyPage,
    IMDummyReadOnlyPage,

    IMMiscFormsPage,
    IMMiscFormsReadOnlyPage,
    IMPolicyDetailsPage,
    IMPolicyDetailsReadOnlyPage,
    
    IMCoveragePartSelectionPage,
    IMCoveragePartSelectionReadOnlyPage,
    
    IMBuildingsAndLocationsPage,
    IMBuildingsAndLocationsReadOnlyPage,

    IMModifiersPage,
    IMModifiersReadOnlyPage,

    IMUnderwritingQuestionsPage,
    IMUnderwritingQuestionsReadOnlyPage,

    // AAIS
    IMAAISCommonFormsPage,
    IMAAISCommonFormsReadOnlyPage,
    
    // AAIS
    IMBuildersRiskPage,
    IMBuildersRiskReadOnlyPage,
    // AAIS
    IMBoatCoveragePage,
    IMBoatCoverageReadOnlyPage,
    // AAIS
    IMContractorsEquipmentPage,
    IMContractorsEquipmentReadOnlyPage,
    // AAIS
    IMFineArtsFloaterPage,
    IMFineArtsFloaterReadOnlyPage,
    // AAIS
    IMRiggersCoveragePage,
    IMRiggersCoverageReadOnlyPage,
    // AAIS
    IMMotorTruckCargoPage,
    IMMotorTruckCargoReadOnlyPage,
    // AAIS
    IMWarehouseLegalLiabilityPage,
    IMWarehouseLegalLiabilityReadOnlyPage,
    // AAIS
    IMBaileeCustomersFloaterPage,
    IMBaileeCustomersFloaterReadOnlyPage,

    // ISO
    IMCPPCommonFormsPage,
    IMCPPCommonFormsReadOnlyPage,
    
    // ISO
    IMPhysiciansAndSurgeonsPage,
    IMPhysiciansAndSurgeonsReadOnlyPage,
    // ISO
    IMAccountsReceivablePage,
    IMAccountsReceivableReadOnlyPage,
    // ISO
    IMValuablePapersPage,
    IMValuablePapersReadOnlyPage,
    // ISO
    IMSignsPage,
    IMSignsReadOnlyPage,

    IMMiscellaneousFloatersPage,
    IMMiscellaneousFloatersReadOnlyPage,

    IMElectronicDataProcessingPage,
    IMElectronicDataProcessingReadOnlyPage,

    IMMuseumPage,
    IMMuseumReadOnlyPage,

    //
    IMTransitPage,
    IMTransitReadOnlyPage,

    IMInstallationFloaterPage,
    IMInstallationFloaterReadOnlyPage,

    IMRiskAnalysisPage,
    IMRiskAnalysisReadOnlyPage
};

 
export { default as IMSubmissionWizard } from './IMSubmissionWizard';
export { default as IMWizardPage } from './templates/IMWizardPage';

export { default as IMQuoteIssueRenderHelper } from './pages/Quote/util/IMQuoteIssueRenderHelper';
export { default as IMQuotePageConstants } from './pages/Quote/config/IMQuotePage.config';
export { default as IMPolicyLineSummaryComponent } from './pages/Quote/components/Summary/IMPolicyLineSummaryComponent';

// UTIL
export { default as IMCoverageSelectionUtil } from './util/IMCoverageSelectionUtil'
