import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect } from 'react';
import { CURiskDetailsService } from 'wni-capability-quoteandbind-cu';
import messages from './ConditionsAndExclusionsCard.messages';
import CoverageContext from '../../../../context/CUCoverageContext';
import CUClausesComponentVM from '../../../../components/CUClausesComponentVM';
import CUSingleClauseComponentVM from '../../../../components/CUSingleClauseComponentVM';
import CUCoverageUtil from '../../../../util/CUCoverageUtil';
import AccordionCardTitle from '../../../../components/CUClauseAccordionCardTitle';

const ConditionsAndExclusionsCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        riskDetails,
        setRiskDetails,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        onClickAddSearchClauses
    } = props;


    const translator = useTranslator();
    const clauseType = 'exclusionsAndConditions';
    const validationID = 'ConditionsAndExclusionsCard';
    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    const {
        exclusion,
        condition,
    } = riskDetails

    const changeExclusion = (value, changedPath) => {
        return changeClauses(value, changedPath, exclusion, 'exclusion');
    }

    const changeCondition = (value, changedPath) => {
        return changeClauses(value, changedPath, condition, 'condition');
    }

    const changeClauses = (value, changedPath, oldClauses, clausesPath) => {
        const newClauses = CUCoverageUtil.setClauseValue(oldClauses, clausesPath, value, changedPath);
        const newRiskDetails = _.clone(riskDetails)
        _.set(newRiskDetails, clausesPath, newClauses);
        setRiskDetails(newRiskDetails);
        setIsEditing(false);
        return newRiskDetails;
    }


    const updateVMToServer = async (newRiskDetails, setEditingFunc) => {
        const lineCoveragesToUpdate = CUCoverageUtil.generateUpdatedRiskDetailsDTO(newRiskDetails);
        const lineCoveragessFromServer = await withLoadingMask(() => CURiskDetailsService.updateRiskDetails(
            jobID,
            sessionUUID,
            lineCoveragesToUpdate,
            authHeader
        ))
        setEditingFunc(false)
        setRiskDetails(lineCoveragessFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }
    const updateNewCoveragePartClausesToServer = async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }

    const changeExclusionAndSync = (value, changedPath) => {
        const newCoveragePartClauses = changeExclusion(value, changedPath);
            
        updateNewCoveragePartClausesToServer(newCoveragePartClauses);
    }
    const changeConditionAndSync = (value, changedPath) => {
        const newCoveragePartClauses = changeCondition(value, changedPath);
        
        updateNewCoveragePartClausesToServer(newCoveragePartClauses);
    }

    const onSyncCoverages = () => {
            updateNewCoveragePartClausesToServer(riskDetails);
        }

    return (
        <AccordionCard
            id="ConditionsAndExclusionsCard"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.ConditionsAndExclusions)}
                    actionText={messages.AddConditionsAndExclusions}
                    onClick={() => onClickAddSearchClauses(clauseType)}
                /> : translator(messages.ConditionsAndExclusions)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: riskDetails,
                        updateClausesContainer: setRiskDetails,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <CUClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeExclusionAndSync}
                        onChangeClause={changeExclusion}
                        onValidate={onValidate}
                        showAmount={false}
                        path="exclusion"
                        value={exclusion}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CUSingleClauseComponentVM: CUSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                    <CUClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeConditionAndSync}
                        onChangeClause={changeCondition}
                        onValidate={onValidate}
                        showAmount={false}
                        path="condition"
                        value={condition}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CUSingleClauseComponentVM: CUSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default ConditionsAndExclusionsCard;
