import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wcQualificationService'), method, data, additionalHeaders);
}

export default class WCQualificationService {

    /**
     *
     * @param {Object} data
     * @param {Object} additionalHeaders
     * @returns {Promise}
     */
    static updateQualification(data, additionalHeaders = {}) {
        return processSubmission(
            'updateQualification',
            [data.jobID, data.sessionUUID, data.dto],
            additionalHeaders
        );
    }

}