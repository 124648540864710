/* eslint-disable camelcase */
import messages from './RTTooltip.message';

const RTTooltips = {
    RTLimitedMexicoCoverage_Ext: messages.RTLimitedMexicoCoverage_Ext,
    RTTripInterruptionCoverage_Ext: messages.RTTripInterruptionCoverage_Ext,
    RTTransportationExpensesCoverage_Ext: messages.RTTransportationExpensesCoverage_Ext,
    RTElectronicEquipmentCoverage_Ext: messages.RTElectronicEquipmentCoverage_Ext,
}

export default RTTooltips