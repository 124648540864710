
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { PortalConstants } from 'wni-portals-config-js';
import { HOResidenceChangeService, HORCTChangeService } from 'wni-capability-policychange-ho';
import { HOResidencePage } from 'wni-capability-quoteandbind-ho-react';

function HOResidenceChangePage(props) {
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();

    return (
        <HOResidencePage {...props}
            hoRctService = {HORCTChangeService}
            hoResidenceService = {HOResidenceChangeService}
            transactionType = { PortalConstants.TRANSACTIONTYPE_POLICYCHANGE }
            checkRequiredForIssuance = {false}
            isSkipPrefillRCT
            constructionFieldReadOnlyFlags = {
                {
                    isConstructionOptionReadOnly: !isExternalUser,
                    isNumberofBathroomsReadOnly: !isExternalUser,
                    isRoofSlopeReadOnly:  !isExternalUser,
                }
            }
        />
    );

}

HOResidenceChangePage.propTypes = HOResidencePage.propTypes;
HOResidenceChangePage.defaultProps = HOResidencePage.defaultProps;

export default HOResidenceChangePage;