import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import React from 'react';
import { Button } from '@jutro/legacy/components';

function ButtonWithTips(props) {
    const { buttonText, tip } = props;
    const translator = useTranslator();

    const componentProps = _.omit(props, ['tip', 'buttonText']);
    return (
        <>
            <Button {...componentProps}>{translator(buttonText)}</Button>
            <div className="mt-5 mb-10 font-14">{translator(tip)}</div>
        </>
    );
}

const IncidentTitle = (props) => {
    const { data, onRemove, PAGE_TYPE_NAME, disabled } = props;
    const { 
        [PAGE_TYPE_NAME]: { make, model, year } = {}, 
        driver 
    } = data;
    const displayName = (!make && !model && !year) ? `Unknow ${_.upperFirst(PAGE_TYPE_NAME)}` : `${make || ' '}  ${model || ' '}  ${year || ' '}`;
    return (
        <Flex justifyContent="between">
            <Flex justifyContent="left" gap="large" className="lineheight-40">
                <span className="font-18-bold">{displayName}</span>
                {driver && (
                    <span className="font-16">{`${driver.firstName || ''} ${driver.lastName}`}</span>
                )}
            </Flex>
            <Button type="outlined" onClick={onRemove} disabled={disabled}>
                Delete
            </Button>
        </Flex>
    );
};

export { ButtonWithTips, IncidentTitle };
