const constantData = {
    helpTextFields: [
        'PAENORegVehUseIndCov',
        'PAENORegVehUseResCov'
    ],
    helpTextFieldValue: {
        PAENORegVehUseIndCov: 'This extends liability coverage to vehicles furnished or available for regular use (except as public or livery conveyances) for the named individual.',
        PAENORegVehUseResCov: 'This extends liability coverage to vehicles furnished or available for regular use (except as public or livery conveyances) for the named individual and resident relatives.',
    }
};

export default constantData;
