import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';

import metadata from './YourHomePage.metadata.json5';
import messages from './YourHomePage.messages';

function YourHomePage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const { isComponentValid, initialValidation, onValidate } = useValidation('YourHomePage');

    useEffect(() => {
        if (_.isEmpty(submissionVM.lobData.homeowners.coverables.yourHome.value)) {
            // eslint-disable-next-line no-param-reassign
            submissionVM.lobData.homeowners.coverables.yourHome = {};
            updateWizardData(submissionVM);
        }
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addAdditionalInterest = useCallback(() => {
        const homeAdditionalInterests = _.get(
            submissionVM,
            'lobData.homeowners.coverables.additionalInterests'
        );

        // initialize additional interest VM
        homeAdditionalInterests.value = homeAdditionalInterests.value || [];
        const { _xCenter, _dtoName } = homeAdditionalInterests;
        const additionalVM = viewModelService.create({}, _xCenter, _dtoName);
        // initialize additional interest VM
        const addPolicyInterest = additionalVM.policyAdditionalInterest;
        addPolicyInterest.value = addPolicyInterest.value || {};
        addPolicyInterest.primaryAddress.value = addPolicyInterest.primaryAddress.value || {};
        additionalVM.policyAdditionalInterest = addPolicyInterest;
        homeAdditionalInterests.pushElement(additionalVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const removeAdditionalInterest = useCallback(
        (evt) => {
            const additionInterestPath = evt.path;
            // removes `.children.index` and replace with `.value`
            // so we can get an array of Additional Interests
            const additionalInterestListPath = additionInterestPath.replace(
                /\.children\[\d+\]$/,
                '.value'
            );
            const additionalInterestToRemove = _.get(submissionVM, additionInterestPath);
            const additionalInterestList = _.get(submissionVM, additionalInterestListPath);

            modalApi.showConfirm({
                title: commonMessages.removeAdditionalInterest,
                message: commonMessages.removeAdditionalInterestDesc,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.additionalModalYes,
                cancelButtonText: messages.additionalModalNo
            }).then(
                (results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    const newAdditionalInterestList = additionalInterestList.filter(
                        (additionalInterest, index) => {
                            // eslint-disable-next-line
                            const additionalInterestListPathWithoutValue = additionalInterestListPath.replace(
                                '.value',
                                ''
                            );
                            const currentAdditionalInterestPath = `${additionalInterestListPathWithoutValue}.children[${index}]`;
                            // eslint-disable-next-line
                            const isCorrectPath = currentAdditionalInterestPath === additionInterestPath;

                            return !(
                                isCorrectPath
                                && _.isEqual(additionalInterest, additionalInterestToRemove.value)
                            );
                        }
                    );

                    _.set(submissionVM, additionalInterestListPath, newAdditionalInterestList);
                    updateWizardData(submissionVM);
                    return true;
                }, _.noop
            );
        },
        [submissionVM, updateWizardData]
    );

    const generateAdditionalInterestOverrides = useCallback(
        () => {
            const additionalInterestsVM = _.get(submissionVM, 'lobData.homeowners.coverables.additionalInterests');
            const overrides = {};
            additionalInterestsVM.value.forEach((additionalInterest, i) => {
                const subtype = _.get(additionalInterest, 'policyAdditionalInterest.subtype');
                overrides[`companyContainer${i}`] = {
                    visible: subtype === 'Company'
                };
                overrides[`personalContainer${i}`] = {
                    visible: subtype === 'Person'
                };
                overrides[`additionalInterestState${i}`] = {
                    visible: !_.isEmpty(
                        _.get(
                            additionalInterestsVM,
                            `children[${i}].policyAdditionalInterest.primaryAddress.state.aspects.availableValues`
                        )
                    )
                };
            });
            return overrides;
        },
        [submissionVM]
    );

    const additionalInterest = _.get(
        submissionVM,
        'lobData.homeowners.coverables.additionalInterests.value'
    );
    const overrideTitleProps = useCallback(() => {
        const overrideProps = additionalInterest.map((value, index) => {
            return {
                [`hoAdditionalInterest${index}`]: {
                    visible: index === 0
                },
                [`hoAdditionalInterestCount${index}`]: {
                    visible: index > 0,
                    content: translator(messages.additionalInterestCount, {
                        count: index + 1
                    })
                }
            };
        });

        return Object.assign({}, ...overrideProps);
    }, [additionalInterest, translator]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showOptional: true
        },
        hoAddAnotherAdditionalInterest: {
            visible: additionalInterest.length > 0
        },
        hoAddAdditionalInterest: {
            visible: additionalInterest.length === 0
        },
        ...generateAdditionalInterestOverrides(),
        ...overrideTitleProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddAdditionalInterest: addAdditionalInterest,
            onRemoveAdditionalInterest: removeAdditionalInterest
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage disableNext={!isComponentValid} skipWhen={initialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourHomePage.propTypes = wizardProps;
export default YourHomePage;
