import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'gateway.views.cancellation-detail.Activities',
        defaultMessage: 'Activities'
    },
    sureBindCancellation: {
        id: 'gateway.views.cancellation-detail.Are you sure you want to bind this cancellation?',
        defaultMessage: 'Are you sure you want to bind this cancellation?'
    },
    bind: {
        id: 'gateway.views.cancellation-detail.Bind',
        defaultMessage: 'Bind'
    },
    bindingCancellation: {
        id: 'gateway.views.cancellation-detail.Binding Cancellation',
        defaultMessage: 'Binding Cancellation'
    },
    cancellation: {
        id: 'gateway.directives.cancel-policy.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },
    description: {
        id: 'gateway.views.cancellation-detail.Description',
        defaultMessage: 'Description'
    },
    effectiveDate: {
        id: 'gateway.views.cancellation-detail.Effective Date',
        defaultMessage: 'Effective Date'
    },
    failedToBindCancellation: {
        id: 'gateway.views.cancellation-detail.Failed to bind cancellation',
        defaultMessage: 'Failed to bind cancellation'
    },
    failedToWithdrawCancellation: {
        id: 'gateway.views.cancellation-detail.Failed to withdraw cancellation',
        defaultMessage: 'Failed to withdraw cancellation'
    },
    needToCancelStartNewCancellation: {
        id: 'gateway.views.cancellation-detail.If you need to cancel the policy please create a new Cancellation',
        defaultMessage: 'If you need to cancel the policy, please start a new cancellation.'
    },
    needReinitiateContactUnderWritter: {
        id: 'gateway.views.cancellation-detail.If you need to reinstate this policy please contact the underwriter',
        defaultMessage: 'If you need to reinstate this policy, please contact the underwriter.'
    },
    noNotesAssociatedWithCancellation: {
        id: 'gateway.views.cancellation-detail.There are no notes associated with this cancellation.',
        defaultMessage: 'There are no notes associated with this cancellation.'
    },
    note: {
        id: 'gateway.views.cancellation-detail.Note',
        defaultMessage: 'Note'
    },
    notes: {
        id: 'gateway.views.cancellation-detail.Notes',
        defaultMessage: 'Notes'
    },
    openActivities: {
        id: 'gateway.views.cancellation-detail.Open Activities',
        defaultMessage: 'Open Activities'
    },
    OpenIssues: {
        id: 'gateway.views.cancellation-detail.OpenIssues',
        defaultMessage: 'Open UW Issues'
    },
    premium: {
        id: 'gateway.views.cancellation-detail.Premium',
        defaultMessage: 'Premium'
    },
    reason: {
        id: 'gateway.views.cancellation-detail.Reason',
        defaultMessage: 'Reason'
    },
    refundAmount: {
        id: 'gateway.views.cancellation-detail.Refund Amount',
        defaultMessage: 'Refund Amount'
    },
    refundMethod: {
        id: 'gateway.views.cancellation-detail.Refund Method',
        defaultMessage: 'Refund Method'
    },
    status: {
        id: 'gateway.views.cancellation-documents.Status',
        defaultMessage: 'Status'
    },
    stillOwed: {
        id: 'gateway.views.cancellation-detail.Still Owed',
        defaultMessage: 'Still Owed'
    },
    summary: {
        id: 'gateway.views.cancellation-detail.Summary',
        defaultMessage: 'Summary'
    },
    cancellationAmountToBeCalculated: {
        id: 'gateway.views.cancellation-detail.The cancellation refund amount has been calculated.',
        defaultMessage: 'The cancellation refund amount has been calculated.'
    },
    cancellationHasBeenWithBound: {
        id: 'gateway.views.cancellation-detail.This cancellation has been bound.',
        defaultMessage: 'This cancellation has been bound.'
    },
    cancellationHasBeenWithDrawn: {
        id: 'gateway.views.cancellation-detail.This cancellation has been withdrawn.',
        defaultMessage: 'This cancellation has been withdrawn.'
    },
    cancellationOwnedByCarrier: {
        id: 'gateway.views.cancellation-detail.This cancellation is owned by the carrier',
        defaultMessage: 'This cancellation is owned by the carrier'
    },
    cancellationInitiatedByInsurer: {
        id: 'gateway.views.cancellation-detail.This cancellation was initiated by the insurer',
        defaultMessage: 'This cancellation was initiated by the insurer'
    },
    withdraw: {
        id: 'gateway.views.cancellation-detail.Withdraw',
        defaultMessage: 'Withdraw'
    },
    withdrawingCancellation: {
        id: 'gateway.views.cancellation-detail.Withdrawing Cancellation',
        defaultMessage: 'Withdrawing Cancellation'
    },
    cannotUndoOnceBound: {
        id: "gateway.views.cancellation-detail.You won't be able to undo once bound.",
        defaultMessage: "You won't be able to undo once bound."
    },
    withDrawActivitiesNotesDocument: {
        id: 'gateway.views.cancellation-detail.You can choose to Withdraw the Cancellation or add Activities, Notes, or Documents',
        defaultMessage: 'You can choose to Withdraw the Cancellation or add Activities, Notes, or Documents'
    },
    youCanViewInformationCannotBindOrWithdraw: {
        id: 'gateway.views.cancellation-detail.You can view information but cannot bind or withdraw',
        defaultMessage: 'You can view information but cannot bind or withdraw'
    },
    lastDays: {
        id: 'gateway.views.cancellation-detail.last 30 Days',
        defaultMessage: 'Issued in Last 30 Days'
    },
    openBound: {
        id: 'gateway.views.cancellation-detail.Open Bound',
        defaultMessage: 'Open - Bound'
    },
    openNotBound: {
        id: 'gateway.views.cancellation-detail.Open Not Bound',
        defaultMessage: 'Open - Not Bound'
    },
    errorLoadingDocuments: {
        id: 'gateway.views.cancellation-documents.Error loading documents',
        defaultMessage: 'Error loading documents.'
    },
    longDescription: {
        id: 'gateway.views.cancellation-documents.Long Description',
        defaultMessage: 'Long Description'
    },
    shortDescription: {
        id: 'gateway.views.cancellation-documents.Short Description',
        defaultMessage: 'Short Description'
    },
    underwritingIssuesAssociatedWithCancellation: {
        id: 'gateway.views.cancellation-documents.There are no open underwriting issues associated with this cancellation',
        defaultMessage: 'There are no open underwriting issues associated with this cancellation'
    },
    errorLoadingNotes: {
        id: 'gateway.views.cancellation-notes.Error loading notes.',
        defaultMessage: 'Error loading notes.'
    },
    stillOwedHasBeenCalculated: {
        id: 'gateway.views.cancellation-detail.The cancellation amount still owed has been calculated',
        defaultMessage: 'The cancellation amount still owed has been calculated'
    },
    bindActivitiesNotesDocument: {
        id: 'gateway.views.cancellation-detail.You can choose to Bind or Withdraw the Cancellation or add Activities, Notes, or Documents',
        defaultMessage: 'You can either bind or withdraw the cancellation.'
    },
    canNotBindOrWithDrawButCanAddInfo: {
        id: 'gateway.views.cancellation-detail.You cannot withdraw or bind the cancellation, but you can add activities, notes and documents to it.',
        defaultMessage: 'You cannot withdraw or bind the cancellation, but you can add activities, notes and documents to it.'
    },
    failedWithDrawCancellation: {
        id: 'gateway.directives.JobSummary.Failed to withdraw cancellation',
        defaultMessage: 'Failed to withdraw cancellation'
    },
    wontUndoOnceBound: {
        id: 'gateway.views.cancellation-detail.You wont be able to undo once bound.',
        defaultMessage: 'You won\'t be able to undo once bound.'
    },
    canBindCancelCannotWithdraw: {
        id: 'gateway.views.cancellation-detail.You can bind the cancellation, but you cannot withdraw it.',
        defaultMessage: 'You can bind the cancellation, but you cannot withdraw it.'
    },
    cancellationEffectiveDate: {
        id: 'gateway.views.cancellation-detail.Cancellation Effective Date',
        defaultMessage: 'Cancellation Effective Date'
    },
    bindCancellation: {
        id: 'gateway.views.cancellation-detail.Bind Cancellation',
        defaultMessage: 'Bind Cancellation'
    },
    doNotBind: {
        id: 'gateway.views.cancellation-detail.Do Not Bind',
        defaultMessage: 'Do Not Bind'
    },
    withdrawCancellation: {
        id: 'gateway.views.cancellation-detail.Withdraw Cancellation',
        defaultMessage: 'Withdraw Cancellation'
    },
    wantWithdrawCancellation: {
        id: 'gateway.views.cancellation-detail.Are you sure you want to withdraw the cancellation?',
        defaultMessage: 'Are you sure you want to withdraw the cancellation?'
    },
    doNotWithdraw: {
        id: 'gateway.views.cancellation-detail.Do Not Withdraw',
        defaultMessage: 'Do Not Withdraw'
    }
});
