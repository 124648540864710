import React, {
} from "react";
import _ from 'lodash';
import PropTypes from 'prop-types';
import RTOperatorCoverageConfig from "../../RTOperatorCoverage.config";
import SingleOperatorClause from "../SingleOperatorClause";
import messages from './TotalDisabilityBenefitsCoverage.messages';

const {
    OperatorCoverageCode,
    OperatorCoverageLimitScheduleItemTermCode,
} = RTOperatorCoverageConfig

function TotalDisabilityBenefitsCoverage(props) {

    return <SingleOperatorClause
        clauseCode = {OperatorCoverageCode.TotalDisabilityBenefits}
        subScheduleTermsCodes = {[
            OperatorCoverageLimitScheduleItemTermCode.RTTotalDisabilityBenefitsSchItemGE,
            OperatorCoverageLimitScheduleItemTermCode.RTTotalDisabilityBenefitsSchItemNotGE
        ]}
        label = {messages.TotalDisabilityBenefits}
        {...props}
    />
}

TotalDisabilityBenefitsCoverage.propTypes = {
    policyContactRole: PropTypes.shape({}).isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    lineCoveragePath: PropTypes.string.isRequired,
    updateSubmissionVMToServer: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
};
TotalDisabilityBenefitsCoverage.defaultProps = {
    onValidate: _.noop,
    showErrors: false,
}

export default TotalDisabilityBenefitsCoverage;