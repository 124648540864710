import React, {
    useCallback
} from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { WniCheckboxField, WniDropdownSelect } from "wni-common-base-components";
import {
    Loader,
} from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WCCoverageUtil from '../../util/WCCoverageUtil';
import styles from './WCLineDetails.module.scss'

const WCLineDetails = (props) => {

    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        lobName,
        coveragesService,
        loadingLineCoverages,
        setLoadingLineCoverages,
        setIsEditing,
        onValidate,
        showErrors,
        isEditable,
    } = props

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const getLineDetails = useCallback(() => {
        const lineCoverages = _.get(selectedVersion, 'coverages.lineDetails', []);
        return lineCoverages;
    }, [selectedVersion]);

    const lineDetailPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.lineDetails`;
    const lineDetails = getLineDetails();

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = WCCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = WCCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingLineCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingLineCoverages,
    ]);

    return <>
        {lineDetails
            .map((displayable, index) => {
                const {
                    propertyName,
                    label,
                    required,
                    inputSetMode,
                    editable: isDisplayableEditable,
                    rangeValue,
                    rangeValueOptions = [],
                } = displayable

                if (loadingLineCoverages && loadingLineCoverages.includes(propertyName)) {
                    return <Loader
                        key={`lineDetails_${propertyName}_loader`}
                        showLoader
                    /> 
                }

                const onDynamicRangeValueChange = (newValue) => {
                    const newDsiplayable = {
                        ...displayable,
                        updated: true,
                        rangeValue: newValue
                    }
                    _.set(submissionVM.value, `${lineDetailPath}[${index}]`, newDsiplayable);
                    updateWizardData(submissionVM);
                    setIsEditing(false);
                    updateSubmissionVMToServer(submissionVM)
                }

                if (!isEditable) {
                    return  <div className={`${styles.readOnlyContainer} flex-1 d-flex`}>
                        <span className='flex-1'>
                            {label}
                        </span>
                        <WniDropdownSelect
                            hideLabel
                            id={`displayable_dynamicrange_${propertyName}_readonly`}
                            value={rangeValue}
                            availableValues={rangeValueOptions.map((v) => {
                                return {code: v,name: v}
                            })}
                            placeholder=""
                            size="medium"
                            className='flex-1'
                            readOnly
                        />
                    </div>
                }

                return <div
                    key={propertyName}
                    className='d-flex'
                >
                    <WniCheckboxField
                        id= {`disabled_displayable_checkbox_${propertyName}`}
                        label= {label}
                        className= 'flex-1-5'
                        value
                        disabled
                        showInlineLabel
                        layout= "full-width"
                    />
                    {inputSetMode === 'dynamicrange' ? <WniDropdownSelect
                        hideLabel
                        id={`displayable_dynamicrange_${propertyName}`}
                        value={rangeValue}
                        availableValues={rangeValueOptions.map((v) => {
                            return {code: v,name: v}
                        })}
                        onValueChange={onDynamicRangeValueChange}
                        placeholder=""
                        size="medium"
                        className='flex-1'
                        required={required}
                        onValidationChange={onValidate}
                        showErrors={showErrors}
                        readOnly={!isDisplayableEditable}
                    /> : null}
                </div>
            })}
    </>
}

WCLineDetails.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    lobName: PropTypes.string.isRequired,
    coveragesService: PropTypes.shape({}),
    loadingLineCoverages: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    setLoadingLineCoverages: PropTypes.func,
    setIsEditing: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
}

WCLineDetails.defaultProps = {
    updateWizardData: _.noop,
    coveragesService: {},
    loadingLineCoverages: false,
    setLoadingLineCoverages: _.noop,
    setIsEditing: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
}

export default WCLineDetails
