import _ from 'lodash';

/**
 * Receives an instance of PCDisplayMessageDTO and returns a list
 * of validation issues;
 * @param {object} pcDisplayMessage instance of PCDisplayMessageDTO;
 * @returns {array} an array of [{type, reason}]
 */
function convertPcDisplayMessageToIssues(pcDisplayMessage = {}) {
    const {
        errors: displayErrors = [],
        warnings: displayWarnings = [],
    } = pcDisplayMessage;

    const errors = displayErrors.map((errorString) => {
        return {
            type: 'error',
            reason: errorString
        };
    });
    const warnings = displayWarnings.map((warningString) => {
        return {
            type: 'warning',
            reason: warningString
        };
    });
    return errors.concat(warnings);
}


/**
 * Retrieve VaidationIssueDTO list in JobValidationUnderwritingIssuesDTO
 * @param {object} errorsAndWarnings
 * @param {array} extraValidationIssues a list of validation issues with shape of {type, reason}
 * @returns {array} an (possibly empty) array of VaidationIssueDTO
 */
function getValidationIssues(errorsAndWarnings, extraValidationIssues = []) {
    // const errorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');

    if (!errorsAndWarnings) {
        return [];
    }

    const {
        validationIssues,
        underwritingIssues,
        eligibilityIssues_Ext: eligibilityIssues,
        pcDisplayMessage_Ext: pcDisplayMessagesDTO,
        //
        serverIssues_Ext: serverIssues,
    } = errorsAndWarnings;

    // only consider validationIssues.issue and PC display messages for now,
    // other issues will be added in the future
    const issues = _.get(validationIssues, 'issues', []);
    const fieldIssues = _.get(validationIssues, 'fieldIssues', []);
    const pcDisplayMessages = convertPcDisplayMessageToIssues(pcDisplayMessagesDTO);
    const serverErrors = _.get(serverIssues, 'serverErrors', []);
    const displayMssages = _.get(serverIssues, 'displayMssages', []);
    const results = [
        ...issues,
        ...fieldIssues,
        ...pcDisplayMessages,
        ...extraValidationIssues,
        ...serverErrors,
        ...displayMssages
    ];
    return results;
}

// This function only get issues that happens on field change
function getServerIssues(errorsAndWarnings, extraValidationIssues = []) {
    // const errorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');

    if (!errorsAndWarnings) {
        return [];
    }

    const {
        pcDisplayMessage_Ext: pcDisplayMessagesDTO,
        //
        serverIssues_Ext: serverIssues,
    } = errorsAndWarnings;

    // only consider validationIssues.issue and PC display messages for now,
    // other issues will be added in the future
    const pcDisplayMessages = convertPcDisplayMessageToIssues(pcDisplayMessagesDTO);
    const serverErrors = _.get(serverIssues, 'serverErrors', []);
    const displayMssages = _.get(serverIssues, 'displayMssages', []);
    const results = [
        ...pcDisplayMessages,
        ...extraValidationIssues,
        ...serverErrors,
        ...displayMssages
    ];
    return results;
}

/**
 * Checks whether any validation issue exists
 * @param {object} errorsAndWarnings, an instance ofJobValidationUnderwritingIssuesDTO
 * @param {function} issueFilter optional filter for vaildation issues
 * @returns {boolean}
 */
function hasValidationIssue(errorsAndWarnings = {}, issueFilter) {
    // const { validationIssues } = errorsAndWarnings;  // will fail for null
    const validationIssues = _.get(errorsAndWarnings, 'validationIssues');
    if (_.isEmpty(validationIssues)) {
        return false;
    }
    let retval = true;
    const { issues = [], fieldIssues = [] } = validationIssues;
    const filteredIssues = _.isFunction(issueFilter) ? issues.filter(issueFilter) : issues;
    const filteredFieldIssues = _.isFunction(issueFilter)
        ? fieldIssues.filter(issueFilter) : fieldIssues;
    if (_.isEmpty(filteredIssues) && _.isEmpty(filteredFieldIssues)) {
        retval = false;
    }
    return retval;
}


/**
 * Shorthand of hasValidationIssue(errorsAndWarnings, (issue) => issue.type === 'error');
 * @param {object} errorsAndWarnings
 * @returns {boolean}
 */
function hasValidationError(errorsAndWarnings) {
    return hasValidationIssue(errorsAndWarnings, (issue) => issue.type === 'error');
}

/**
 * Checks whether new validation issues exist
 * @param {array} oldValidationIssues
 * @param {array} newValidationIssues
 * @returns {boolean}
 */
function hasNewValidationIssue(oldValidationIssues, newValidationIssues) {
    const retval = newValidationIssues.find((newValidationIssue) => {
        return !oldValidationIssues.find((oldValidationIssue) => _.isEqual(oldValidationIssue,
            newValidationIssue));
    });
    return retval;
}

/**
 * Get the validation issue key for page jump
 * @param {object} validationIssue
 * @returns {string}
 */
function getValidationIssueKey(validationIssue) {
    const {
        type = '',
        reason = '',
        flowStepId = '',
    } = validationIssue;

    return `${type} ${reason} ${flowStepId}`;
}

/**
 * This method flatten "children" in issues and extract them out.
 * The "children" might be popoulated by methods like IssuanceValidationUtil.getIssuesMap()
 *
 * Notes:
 * 1) This method does <strong>NOT</strong> reset originalReason.
 * 2) This method does not flatten children issue recursively (for now)
 *
 * Sample INPUT:
 * [
 *  {type: 'type1', reason: 'reason1'},
 *  {type: 'type2', reason: 'reason2',
 *      children: [
 *                  {type: 'type2', reason: 'reason2.1'},
 *                  {type: 'type2', reason: 'reason2.2'}
 *                ]
 *  },
 *  {type: 'type3', reason: 'reason3'}
 * ]
 * Sample OUPUT:
 * [
 *  {type: 'type1', reason: 'reason1'},
 *  {type: 'type2', reason: 'reason2.1'},
 *  {type: 'type2', reason: 'reason2.2'},
 *  {type: 'type3', reason: 'reason3'}
 * ]
 * @see IssuanceValidationUtil.getIssuesMap()
 * @param {array} issues [{type, reason, children: []}]
 * @returns {array}
 */
function flattenIssueChildren(issues = []) {
    const retval = [];
    issues.forEach((issue) => {
        const { children } = issue;
        if (_.isEmpty(children)) {
            retval.push(issue);
        } else {
            retval.push(children);
        }
    });

    return retval.flat();
}

/**
 * INPUT:
 * ['hello', 'world'],
 * 
 * OUPUT:
 * [
 * {
 *   type: 'warning',
 *   reason: 'hello',
 * },
 * {
 *   type: 'warning',
 *   reason: 'world',
 * }
 * ]
 * @param {array} strArray 
 * @returns {array}
 */
function convertStrArrayToWarnings(strArray = []) {
    const retval = strArray.map((str) => ({
        type: 'warning',
        reason: str,
    }));
    return retval;
}

/**
 * Sample INPUT: [{type: 'error', reason: 'reason'}]
 * Sample OUTPUT: true
 * @param {array} validationIssueArray 
 * @returns {boolean}
 */
function hasErrorInValidationIssueList(validationIssueArray) {
    if(!validationIssueArray) {
        return false;
    }
    // const errorIssue = validationIssueArray.find((issue) => issue.type === 'error');
    // return !_.isNil(errorIssue);
    const retval = validationIssueArray.some((issue) => issue.type === 'error');
    return retval
}

function hasWarningInValidationIssueList(validationIssueArray) {
    if(!validationIssueArray) {
        return false;
    }
    const retval = validationIssueArray.some((issue) => issue.type === 'warning');
    return retval
}

export default {
    // =========================================
    // The following methods are re-exported in ErrorsAndWarningsUtil
    // Consider to move this method into IssueTranslatorUtil
    convertPcDisplayMessageToIssues,
    //
    getValidationIssues,
    hasValidationIssue,
    hasValidationError,
    hasNewValidationIssue,
    getValidationIssueKey,
    // =========================================
    flattenIssueChildren,
    //
    convertStrArrayToWarnings,
    hasErrorInValidationIssueList,
    hasWarningInValidationIssueList,

    getServerIssues,
};
