import { defineMessages } from 'react-intl';

export default defineMessages({
    warningUploadTitle: {
        id: 'custom.gateway.directives.policySummary.warningUploadTitle',
        defaultMessage: 'Warning',
    },
    uploadFailedMessageUIC: {
        id: 'custom.gateway.directives.policySummary.uploadFailedMessageUIC',
        defaultMessage: 'The “Upload Documents” function is temporarily unavailable. In the meantime, please email any documents as attachments to plund@umialik.com and include your account number in the subject line'
    },
    uploadFailedMessage: {
        id: 'custom.gateway.directives.policySummary.uploadFailedMessage',
        defaultMessage: 'The “Upload Documents” function is temporarily unavailable. In the meantime, please email any documents as attachments to plund@wnins.com and include your account number in the subject line'
    },
    noPolicyDocuments: {
        id: 'custom.gateway.directives.policySummary.noPolicyDocuments',
        defaultMessage: 'There are no documents associated with this policy'
    },
    printSelectedAttachments: {
        id: 'custom.gateway.directives.policySummary.Print Selected Attachments',
        defaultMessage: 'Print Selected Attachments'
    },
    policyDocuments: {
        id: 'custom.gateway.directives.policySummary.Policy Documents',
        defaultMessage: 'Policy Documents'
    }
});
