import { defineMessages } from 'react-intl';

export default defineMessages({
    clDraftInfoMessage: {
        id: 'gateway.views.quote-detail.cl.clDraftInfoMessage',
        defaultMessage: 'This line of business submission has been created. Please continue through the quote screen to complete the information necessary to receive a quote.'
    },
    clApprovedForThisCancellation: {
        id: 'gateway.views.job-uwissues.cl.clApprovedForThisCancellation',
        defaultMessage: 'This cancellation has been reviewed and approved by underwriting and is now available for view/bind. (or next steps)'
    },
    clApprovedForThisSubmission: {
        id: 'gateway.views.job-uwissues.cl.clApprovedForThisSubmission',
        defaultMessage: 'This submission has been reviewed and approved by underwriting and is now available for view/bind. (or next steps)'
    },
    clApprovedForThisRenewal: {
        id: 'gateway.views.job-uwissues.cl.clApprovedForThisRenewal',
        defaultMessage: 'This renewal has been reviewed and approved by underwriting and is now available for view/bind. (or next steps)'
    },
    clApprovedForThisPolicyChange: {
        id: 'gateway.views.job-uwissues.cl.clApprovedForThisPolicyChange',
        defaultMessage: 'This policy change has been reviewed and approved by underwriting and is now available for view/bind. (or next steps)'
    },
    clWithdrawQuote: {
        id: 'gateway.views.quote-detail.cl.Withdraw Quote',
        defaultMessage: 'Withdraw Quote'
    },
    clApprovedForIssurance: {
        id: 'gateway.views.job-uwissues.cl.clApprovedForIssurance',
        defaultMessage: 'This Quote has been approved for issuance by an Underwriter. Please review and accept to continue'
    },
});
