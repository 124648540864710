import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { Chevron } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './DiscountPage.module.scss';
import metadata from './DiscountPage.metadata.json5';
import commonMessages from '../../HOWizard.messages';
import './DiscountPage.messages';

function DiscountPage(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const {
        registerInitialComponentValidation,
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('DiscountPage');

    useEffect(() => {
        if (_.isEmpty(submissionVM.lobData.homeowners.coverables.rating.value)) {
            // eslint-disable-next-line no-param-reassign
            submissionVM.lobData.homeowners.coverables.rating = {};
            updateWizardData(submissionVM);
        }
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderAccordionHeader = () => {
        return (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} />
                <h3 className={styles.accordionHeaderTitle}>
                    {translator(commonMessages.additionalOptional)}
                </h3>
            </React.Fragment>
        );
    };

    const shouldShow = (path, valueToMatch = true) => {
        const value = _.get(submissionVM, path);
        return value === valueToMatch;
    };

    const generateDetailElementOverrides = () => {
        return {
            burglarAlarmType: {
                visible: shouldShow('lobData.homeowners.coverables.rating.burglarAlarm.value')
            },
            smokeAlarmOnAllFloors: {
                visible: shouldShow('lobData.homeowners.coverables.rating.smokeAlarm.value')
            },
            numberOfDeadbolts: {
                visible: shouldShow('lobData.homeowners.coverables.rating.deadbolts.value')
            },
            roomerBoardersNumber: {
                visible: shouldShow('lobData.homeowners.coverables.rating.roomerBoarders.value')
            },
            fireplaceOrWoodStovesNumber: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.rating.fireplaceOrWoodStoveExists.value'
                )
            },
            swimmingPoolType: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            swimmingPoolDivingBoard: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            swimmingPoolFencing: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            swimmingPoolSlide: {
                visible: shouldShow('lobData.homeowners.coverables.rating.swimmingPoolExists.value')
            },
            trampolineSafetyNet: {
                visible: shouldShow('lobData.homeowners.coverables.rating.trampolineExists.value')
            },
            provideDetails: {
                visible: shouldShow('lobData.homeowners.coverables.rating.knownWaterLeakage.value')
            }
        };
    };

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        additionalDiscountAccordionCard: {
            renderHeader: renderAccordionHeader()
        },
        ...generateDetailElementOverrides()
    };

    const handlePageValidation = useCallback(() => {
        const ratings = _.get(submissionVM.value, 'lobData.homeowners.coverables.rating');
        const quoteData = _.get(submissionVM.value, 'quoteData.offeredQuotes');
        const periodStatus = _.get(submissionVM.value, 'baseData.periodStatus');
        return (
            _.some(quoteData, (quote) => quote.status === 'Quoted')
            && periodStatus === 'Quoted'
            && ratings
        );
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(handlePageValidation);
    }, [handlePageValidation, registerInitialComponentValidation]);

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

DiscountPage.propTypes = wizardProps;
export default DiscountPage;
