import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import metadata from './TrailerDetails.metadata.json5';
// import { getFlattenedUiPropsContent } from './flattenUiPropsHelper';
import {
    getAvailableYears,
    getAvailableList,
    postOnChangeAction,
    isFieldVisible,
    tooltipForcoverageAmount,
    getValuationMethodOptions
} from '../../util/Watercraft.util';

function TrailerDetails(props) {
    const {
        sectionType,
        basePath,
        title,
        currentRow,
        onValueChange,
        onValidate,
        showErrors,
        isRequiredForIssuance,
        syncWizardData,
        syncWizardDataSnapshot,
        changeModelAction,
        isReadOnly,
        extendProps: {
            baseData,
            jobID,
            sessionUUID,
            authHeader,
            systemDate
        }
    } = props;
    const vm = currentRow[basePath];
    const {
        effectiveDate_Ext: effectiveDate
    } = baseData;
    const {
        itemToBeInsured_Ext: itemToBeInsured,
        watercraftDetail: {
            lossSettlementValuationMethod: lossSettlementValuationMethodForWatercraft,
        }
    } = currentRow.value
    const {
        year,
        manufacturer,
        model,
        priceDigestsDetails = {},
        currentMarketValue,
        coverageAmount,
        isCoverageAmountWarning,
        modelOptions = [],
        manufacturerOptions = [],
    } = vm.value;
    const translator = useTranslator();
    const [coverageValue, updateCoverageValue] = useState(coverageAmount);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    useEffect(() => {
        updateCoverageValue(coverageAmount);
    }, [coverageAmount]);

    const getVisibleField = useCallback((fieldPath) => {
        return isFieldVisible(currentRow, fieldPath, basePath, baseData);
    }, [baseData, basePath, currentRow]);

    const getRequestData = (value, path) => {
        return {
            publicID: _.get(currentRow.value, 'publicID'),
            fieldName: path,
            watercraftDetailType: sectionType,
            walWatercraftDetail: vm.value
        }
    };

    const writeValue = async(value, path) => {
        onValueChange(value, `${basePath}.${path}`);
        if(!value) {
            return false;
        }
        switch(path) {
            case 'year':
                if(value === year) {
                    break;
                };
                setLoadingMask(true);
                const res = await postOnChangeAction(getRequestData(value, path), jobID, sessionUUID, authHeader);
                _.set(currentRow.value, basePath, res.walWatercraftDetail);
                syncWizardDataSnapshot(currentRow);
                setLoadingMask(false);
                break;
            case 'manufacturer':
                if(value === manufacturer) {
                    break;
                };
                setLoadingMask(true);
                const resforManufacturer = await postOnChangeAction(getRequestData(value, path), jobID, sessionUUID, authHeader);
                _.set(currentRow.value, basePath, resforManufacturer.walWatercraftDetail);
                syncWizardDataSnapshot(currentRow);
                setLoadingMask(false);
                break;
            case 'model':
                if(value === model) {
                    break;
                };
                changeModelAction(getRequestData(value, path));
                break;
            case 'isPhysicalDamageCovRequired':
                _.set(vm.value, 'currentMarketValue', priceDigestsDetails.currentMarketValue);
                syncWizardData(currentRow)
                break;
            case 'lossSettlementValuationMethod':
                if(value === 'replacement_cost') {
                    _.set(vm.value, 'coverageAmount', priceDigestsDetails.msrp);
                    updateCoverageValue(priceDigestsDetails.msrp);
                    syncWizardData(currentRow);
                }
                if(value === 'agreed_value') {
                    _.set(vm.value, 'coverageAmount', priceDigestsDetails.currentMarketValue);
                    updateCoverageValue(priceDigestsDetails.currentMarketValue);
                    syncWizardData(currentRow);
                }
                break;
            default:
                break;
        }
    };

    //---------------------
    // const valuationMethodOptions = getAvailableForLossMethod('lossSettlementValuationMethod', itemToBeInsured === 'watercraft_motor_trailer');
    const valuationMethodOptions = getValuationMethodOptions(vm, effectiveDate, translator, itemToBeInsured === 'watercraft_motor_trailer');
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        detailsCard: {
            title
        },
        traileryear: {
            availableValues: getAvailableYears(systemDate)
        },
        trailermanufacturer: {
            availableValues: getAvailableList(manufacturerOptions)
        },
        trailerotherManufacturer: {
            visible: getVisibleField('otherManufacturer')
        },
        trailermodel: {
            availableValues: getAvailableList(modelOptions)
        },
        trailerotherModel: {
            visible: getVisibleField('otherModel')
        },

        trailerDetailContent: {
            visible: !!_.get(vm, 'value.model')
        },
        trailerpurchaseDate: {
            visible: getVisibleField('purchaseDate')
        },
        trailerregistrationState: {
            visible: getVisibleField('registrationState')
        },
        trailerregistrationStateExplain: {
            visible: getVisibleField('registrationStateExplain')
        },
        trailerRegistrationStateOtherExplain: {
            visible: getVisibleField('registrationStateOtherExplain')
        },
        trailerisPhysicalDamageCovRequired: {
            visible: getVisibleField('isPhysicalDamageCovRequired')
        },
        trailercurrentMarketValue: {
            visible: getVisibleField('currentMarketValue'),
            disabled: !!_.get(priceDigestsDetails, 'currentMarketValue') && (_.get(priceDigestsDetails, 'currentMarketValue.amount') === _.get(currentMarketValue, 'amount'))
        },
        trailerlossSettlementValuationMethod: {
            availableValues: valuationMethodOptions, // getAvailableForLossMethod('lossSettlementValuationMethod', itemToBeInsured === 'watercraft_motor_trailer'),
            visible: getVisibleField('lossSettlementValuationMethod'),
            disabled: !!lossSettlementValuationMethodForWatercraft
        },
        trailercoverageAmount: {
            tooltip: {
                text: tooltipForcoverageAmount(translator, sectionType)
            },
            visible: getVisibleField('coverageAmount'),
            required: getVisibleField('coverageAmount'),
            onValueChange: (value) => {
                updateCoverageValue(value)
            },
            onBlur: () => {
                writeValue(coverageValue, 'coverageAmount');
            },
            value: coverageValue
        },
        trailercoverageAmountWarningMsg: {
            visible: isCoverageAmountWarning
        },
        
        
    };
    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);

    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);

    const overrides = _.merge(overrideProps, overridesForIssuance);

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };
   

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            newMetadata, vm, fieldId, fieldPath, overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={newMetadata}
            model={vm}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default TrailerDetails;