import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imSignsService'), method, data, additionalHeaders);
}

export default class IMSignsService {
  
    /**
     * Invokes IMSignsHandler.getSignsClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getSignsClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getSignsClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMSignsHandler.getSignsClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getSignsClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getSignsClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMSignsHandler.updateSignsClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMSignsHandler.onSignsNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onSignsNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onSignsNext', [jobNumber, sessionUUID], authHeader);
    }

}