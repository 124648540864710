
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WniAccountService } from 'wni-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './AccountReferToUnderwriter.metadata.json5';

const AccountReferToUnderwriter = (props) => {
    const {
        updatePageVisible,
        authHeader,
        createAccount
    } = props;
    const [showAccountReferred, updateShowAccountReferred] = useState(false);
    const [noteForUnderwriter, updateNoteForUnderwriter] = useState();
    const history = useHistory();

    const onPrevious = () => {
        updatePageVisible(false);
    };

    const referToUnderwriter = async() => {
        const accountData = await createAccount();
        const accountNumber = _.get(accountData, 'accountNumber');
        const res = await WniAccountService.referToUnderwriterForAccount(accountNumber, noteForUnderwriter, authHeader)
        updateShowAccountReferred(true)
    };

    const backToHome = () => {
        history.push('/');
    };

   
    //-------------------------------------------
    const overrideProps = {
        underwriterSection: {
            visible: !showAccountReferred
        },
        accountReferred: {
            visible: showAccountReferred
        },
        referToUWFiled: {
            onValueChange: (value) => updateNoteForUnderwriter(value),
            value: noteForUnderwriter
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            {},
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            onPrevious,
            referToUnderwriter,
            backToHome
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
        />
    );
};

export default AccountReferToUnderwriter;
