import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import LocationScreen from './Locations/LocationScreen';
import BuildingScreen from './Buildings/BuildingScreen';
import LocationHeaderComponent from '../../components/LocationHeader/LocationHeaderComponent';
import LocationBuildingsComponent from '../../components/LocationBuildingsComponent/LocationBuildingsComponent';
import messages from './LocationsAndBuildingsPage.messages';

import metadata from './LocationsAndBuildingsPage.metadata.json5';
import styles from './LocationsAndBuildingsPage.module.scss';

const PATH_TO_LOCATIONS = 'lobData.businessOwners.coverables.locations.value';

const SCREENS = {
    locationsAndBuildingsView: 'LOCATIONS_AND_BUILDINGS_VIEW',
    addLocationView: 'ADD_LOCATION_VIEW',
    addBuildingView: 'ADD_BUILDING_VIEW'
};

function sortLocation(submissionVM) {
    const locations = _.get(submissionVM, PATH_TO_LOCATIONS);
    const locationsWithSortedBuildings = locations.map((location) => {
        const loc = location;
        loc.buildings = _.sortBy(location.buildings, (building) => building.buildingNumber);
        return loc;
    });
    const sortedLocation = _.sortBy(
        locationsWithSortedBuildings,
        (location) => !location.isPrimary
    );

    _.set(submissionVM, PATH_TO_LOCATIONS, sortedLocation);

    return submissionVM;
}

const generateData = (submissionData) => {
    if (!_.get(submissionData, 'bindData.contactPhone')) {
        // if contact phone is not available remove bind data
        return _.omit(submissionData, ['bindData']);
    }
    return submissionData;
};
function LocationsAndBuildingsPage(props) {
    const modalApi = useModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [screen, updateScreen] = useState(SCREENS.locationsAndBuildingsView);
    const [locationPath, updateLocationPath] = useState();
    const [buildingPath, updateBuildingPath] = useState();
    const { authHeader } = useAuthentication();
    const { wizardData: submissionVM, updateWizardData, history } = props;
    const { LoadSaveService, BopCoverablesService } = useDependencies(['LoadSaveService','BopCoverablesService']);
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('LocationsAndBuildingsPage');

    const handleNext = useCallback(async () => {
        if (!_.isEmpty(submissionVM.quoteID)) {
            submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
                generateData(submissionVM.value),
                authHeader
            );
        } else if (!_.isEmpty(submissionVM.jobID)) {
            const isQuotedStatus = await LoadSaveService.isQuoted(
                [submissionVM.jobID.value],
                authHeader
            );
            if (!isQuotedStatus) {
                if (submissionVM.value.baseData.jobType === 'Renewal') {
                    try {
                        submissionVM.value = await LoadSaveService.generateQuote(
                            [submissionVM.value],
                            authHeader
                        );
                    } catch (e) {
                        modalApi.showAlert({
                            title: messages.saveQuoteError,
                            message: messages.saveQuoteErrorMessage,
                            status: 'warning',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).then(() => {
                            const jobID = _.get(submissionVM.value, 'jobID');
                            const redirectPath = `/renewal/${jobID}/summary`;
                            history.push(redirectPath);
                        }, _.noop);
                        return false;
                    }
                } else {
                    try {
                        submissionVM.value = await LoadSaveService.quoteEndorsement(
                            [submissionVM.jobID.value],
                            authHeader
                        );
                    } catch (e) {
                        modalApi.showAlert({
                            title: messages.saveQuoteError,
                            message: messages.saveQuoteErrorMessage,
                            status: 'warning',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).then(() => {
                            const jobID = _.get(submissionVM.value, 'jobID');
                            const redirectPath = `/change/${jobID}/summary`;
                            history.push(redirectPath);
                        }, _.noop);
                        return false;
                    }
                }
            }
        }
        return submissionVM;
    }, [submissionVM, LoadSaveService, authHeader, history]);

    const onAddLocationClick = useCallback(() => updateScreen(SCREENS.addLocationView), []);

    const onAddBuildingClick = useCallback((locationsPath) => {
        updateLocationPath(locationsPath);
        updateBuildingPath(undefined);
        updateScreen(SCREENS.addBuildingView);
    }, []);

    const addBuildingAndReturn = useCallback(
        (locationPublicId, buildingVM) => {
            const { quoteID, jobID, sessionUUID } = submissionVM.value;
            const locations = _.get(submissionVM, PATH_TO_LOCATIONS);
            const currentLocation = locations.find((location) => {
                return location.publicID === locationPublicId;
            });

            currentLocation.buildings.push(buildingVM.value);

            BopCoverablesService.updateBOPBuilding(
                quoteID || jobID,
                locationPublicId,
                buildingVM.value,
                sessionUUID,
                authHeader
            ).then(() => {
                updateScreen(SCREENS.locationsAndBuildingsView);
                updateWizardData(submissionVM);
            });
        },
        [submissionVM, updateWizardData, authHeader, BopCoverablesService]
    );

    const updateBuildingAndReturn = useCallback(
        (locationPublicId, buildingVM) => {
            const { quoteID, jobID, sessionUUID } = submissionVM.value;
            _.set(submissionVM, buildingPath, buildingVM.value);

            BopCoverablesService.updateBOPBuilding(
                quoteID || jobID,
                locationPublicId,
                buildingVM.value,
                sessionUUID,
                authHeader
            ).then(() => {
                updateScreen(SCREENS.locationsAndBuildingsView);
                updateBuildingPath(undefined);
                updateLocationPath(undefined);
                updateWizardData(submissionVM);
            });
        },
        [buildingPath, submissionVM, updateWizardData, authHeader, BopCoverablesService]
    );

    const returnFromBuildingScreen = useCallback(() => {
        updateScreen(SCREENS.locationsAndBuildingsView);
        updateBuildingPath(undefined);
        updateLocationPath(undefined);
    }, []);

    const removeLocation = useCallback(
        (locationToRemove) => {
            const { quoteID, jobID, sessionUUID } = submissionVM.value;
            const location = _.get(submissionVM, locationToRemove);

            modalApi.showAlert({
                title: messages.removeLocationTitle,
                message: messages.removeLocationMessage,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.removeActionLabel
            }).then(() => {
                BopCoverablesService.removeBOPLocation(
                    quoteID || jobID,
                    location,
                    sessionUUID,
                    authHeader
                ).then(() => {
                    const locationsPath = locationToRemove.split('.children[')[0];
                    let locations = _.get(submissionVM.value, locationsPath);
                    locations = locations.filter(({ publicID }) => publicID !== location.publicID);
                    _.set(submissionVM, locationsPath, locations);
                    updateWizardData(submissionVM);
                });
            }, _.noop);
        },
        [submissionVM, updateWizardData, authHeader, BopCoverablesService]
    );

    const onRemoveBuilding = useCallback(
        (evt) => {
            const path = evt.currentTarget.attributes.path.value;
            const building = _.get(submissionVM, path);
            const { quoteID, jobID, sessionUUID } = submissionVM.value;
            const locationToRemove = path.split('.buildings')[0];
            const locationPublicID = _.get(submissionVM, `${locationToRemove}.publicID.value`);

            modalApi.showAlert({
                title: messages.removeBuildingTitle,
                message: messages.removeBuildingMessage,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.removeActionLabel
            }).then(() => {
                return BopCoverablesService.removeBOPBuilding(
                    quoteID || jobID,
                    locationPublicID,
                    building.publicID,
                    sessionUUID,
                    authHeader
                ).then(() => {
                    const location = _.get(submissionVM, `${locationToRemove}.value`);
                    location.buildings = location.buildings.filter(
                        ({ publicID }) => publicID !== building.publicID
                    );
                    _.set(submissionVM, locationToRemove, location);
                    updateWizardData(submissionVM);
                });
            }, _.noop);
        },
        [submissionVM, updateWizardData, authHeader, BopCoverablesService]
    );

    const onEditBuilding = useCallback((evt) => {
        const buildingToEdit = evt.currentTarget.attributes.path.value;
        // remove buildings from path to get location path
        const locationToEdit = buildingToEdit.replace(
            /\.buildings.children\[\d\]\.value/,
            '.value'
        );
        updateScreen(SCREENS.addBuildingView);
        updateLocationPath(locationToEdit);
        updateBuildingPath(buildingToEdit);
    }, []);

    const updateSubAndReturnToLocationsAndBuildingsView = useCallback(
        (changed) => {
            if (changed) {
                const locations = _.get(submissionVM, PATH_TO_LOCATIONS);
                locations.push(changed);
            }
            updateScreen(SCREENS.locationsAndBuildingsView);
        },
        [submissionVM]
    );

    const renderBuildingView = useCallback(() => {
        const quoteOrJobId = (submissionVM.quoteID && submissionVM.quoteID.value)
        || submissionVM.jobID.value;
        return (
            <BuildingScreen
                building={_.get(submissionVM, buildingPath)}
                location={_.get(submissionVM, locationPath)}
                id="buildingScreen"
                quoteID={quoteOrJobId}
                sessionUUID={submissionVM.sessionUUID.value}
                addBuilding={addBuildingAndReturn}
                updateBuilding={updateBuildingAndReturn}
                returnToLocationScreen={returnFromBuildingScreen}
                labelPosition={breakpoint === 'phoneWide' || breakpoint === 'phone' ? 'top' : 'left'}
            />
        );
    }, [
        addBuildingAndReturn,
        breakpoint,
        buildingPath,
        locationPath,
        returnFromBuildingScreen,
        submissionVM,
        updateBuildingAndReturn
    ]);

    const renderLocationView = useCallback(() => {
        const quoteOrJobId = (submissionVM.quoteID && submissionVM.quoteID.value)
        || submissionVM.jobID.value;
        return (
            <LocationScreen
                onClick={updateSubAndReturnToLocationsAndBuildingsView}
                quoteID={quoteOrJobId}
                sessionUUID={submissionVM.sessionUUID.value}
                labelPosition={breakpoint === 'phoneWide' || breakpoint === 'phone' ? 'top' : 'left'}
            />
        );
    }, [breakpoint, submissionVM, updateSubAndReturnToLocationsAndBuildingsView]);

    const renderLocationAccordionHeader = useCallback(
        (location, pathOfLocationToRender, locationIndex) => {
            return (isOpen) => (
                <div className={styles.accordionHeaderContainer}>
                    <LocationHeaderComponent
                        location={location}
                        path={pathOfLocationToRender}
                        locationIndex={locationIndex}
                        isAccordionOpen={isOpen}
                        onRemoveLocation={removeLocation}
                    />
                </div>
            );
        },
        [removeLocation]
    );

    const generateLocationOverrides = useCallback(() => {
        const locationsPath = 'lobData.businessOwners.coverables.locations';
        const locations = _.get(submissionVM, `${locationsPath}.value`, []);

        const overrides = locations.map((location, index) => {
            const overridePath = `${locationsPath}.children[${index}]`;
            return {
                [`locationAccordionCard${index}`]: {
                    renderHeader: renderLocationAccordionHeader(location, `${overridePath}.value`, index)
                },
                [`locationBuildings${index}`]: {
                    isEditable: true,
                    onEditBuilding,
                    onRemoveBuilding
                },
                [`addBuildingButton${index}`]: {
                    onClick: () => onAddBuildingClick(`${locationsPath}.children[${index}].value`)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [
        onAddBuildingClick,
        onEditBuilding,
        onRemoveBuilding,
        renderLocationAccordionHeader,
        submissionVM
    ]);

    const renderBuildingsAndLocationsView = useCallback(() => {
        const locationsPath = 'lobData.businessOwners.coverables.locations';
        const locations = _.get(submissionVM, `${locationsPath}.value`, []);

        const lastLocationWithoutBuilding = locations
            .map((location) => {
                return location.buildings.length === 0;
            })
            .lastIndexOf(true);

        const defaultOpenedId = lastLocationWithoutBuilding < 0
            ? locations.length - 1 : lastLocationWithoutBuilding;

        const overrideProps = {
            '@field': {
                // apply to all fields
                showOptional: true,
                labelPosition: 'top'
            },
            locationAccordionSet: {
                defaultOpenedId: `locationAccordionCard${defaultOpenedId}`
            },
            ...generateLocationOverrides()
        };

        const resolvers = {
            resolveCallbackMap: {
                onAddLocationClick: onAddLocationClick
            },
            resolveComponentMap: {
                locationbuildingscomponent: LocationBuildingsComponent
            }
        };

        if (_.isEmpty(submissionVM)) {
            return null;
        }

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={sortLocation(submissionVM)}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        );
    }, [generateLocationOverrides, onAddLocationClick, onValidate, submissionVM]);

    let toRender;
    const isMainScreen = screen === SCREENS.locationsAndBuildingsView;
    if (isMainScreen) {
        toRender = renderBuildingsAndLocationsView();
    } else if (screen === SCREENS.addLocationView) {
        toRender = renderLocationView();
    } else if (screen === SCREENS.addBuildingView) {
        toRender = renderBuildingView();
    }

    const isLocationsValid = useCallback(() => {
        const locations = _.get(submissionVM, PATH_TO_LOCATIONS);
        return locations.every((location) => location.buildings.length > 0);
    }, [submissionVM]);

    const shouldPageSkip = useCallback(() => {
        return _.get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted';
    }, [submissionVM]);

    useEffect(() => {
        registerComponentValidation(isLocationsValid);
        registerInitialComponentValidation(shouldPageSkip);
    }, [
        isLocationsValid,
        registerComponentValidation,
        registerInitialComponentValidation,
        shouldPageSkip
    ]);

    return (
        <WizardPage
            onNext={handleNext}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onValidationChange={onValidate}
            showCancel={isMainScreen}
            showPrevious={isMainScreen}
            showNext={isMainScreen}
        >
            {toRender}
        </WizardPage>
    );
}

LocationsAndBuildingsPage.propTypes = wizardProps;
export default withRouter(LocationsAndBuildingsPage);
