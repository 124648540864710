
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';


function CommissionComponent(props) {
    return (
        <div>
            <h2>Placeholder for the Commission page. </h2>
            <p>You can turn this page off by removing permission viewcommissions for current Portal user</p>
        </div>
    );
}

export default withAuthenticationContext(CommissionComponent);
