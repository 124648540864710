import { defineMessages } from 'react-intl';

export default defineMessages({
    InjuryIncidentsQuestion: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.question',
        defaultMessage: "Was anyone injured?"
    },
    Yes: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.No',
        defaultMessage: "No"
    },
    Number: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.No.',
        defaultMessage: "No."
    },
    InjuryLastName: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.InjuryLastName',
        defaultMessage: "Injury Last Name"
    },
    Edit: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.Edit',
        defaultMessage: "Edit"
    },
    Delete: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.Delete',
        defaultMessage: "Delete"
    },
    AddInjury: {
        id: 'wni.fnol.ho.views.incidentdetails.injuryIncidents.AddInjury',
        defaultMessage: "Add Injury"
    }
})