import { defineMessages } from 'react-intl';

export default defineMessages({
    deletePolicyChange: {
        id: 'gateway.views.endorsement-detail.Delete Policy Change',
        defaultMessage: 'Delete Policy Change'
    },
    mayDeletePolicyChange: {
        id: 'gateway.views.endorsement-detail.You may delete the policy change',
        defaultMessage: 'You may delete the policy change'
    },
    policyDeleted: {
        id: 'gateway.views.endorsement-detail.This policy change has been deleted',
        defaultMessage: 'This policy change has been deleted'
    },
    mayDeleteOrContinuePolicyChangeWizard: {
        id: 'gateway.views.endorsement-detail.You may either delete or continue to the policy change wizard',
        defaultMessage: 'You may either delete or continue to the policy change wizard.'
    },
    mayDeleteOrContinue: {
        id: 'gateway.views.endorsement-detail.You may either delete this change or continue to the policy change wizard.',
        defaultMessage: 'You may either delete this change or continue to the policy change wizard.'
    },
    sureDeletePolicyChange: {
        id: 'gateway.views.endorsement.Are you sure you want to delete this policy change?',
        defaultMessage: 'Are you sure you want to delete this policy change: {policyChangeNumber}? All changes will be lost.'
    },
    deletePolicyChangeQuestion: {
        id: 'gateway.views.endorsement.Delete Policy Change?',
        defaultMessage: 'Delete Policy Change?'
    },
    unableDeletePolicyChange: {
        id: 'gateway.views.endorsement.Unable to delete this policy change',
        defaultMessage: 'Unable to delete this policy change'
    },
    delete: {
        id: 'gateway.views.endorsement.Delete',
        defaultMessage: 'Delete'
    },
    deletingPolicyChange: {
        id: 'gateway.views.endorsement.Deleting Policy Change',
        defaultMessage: 'Deleting Policy Change'
    },
    failedToDeletePolicyChange: {
        id: 'gateway.views.endorsement-detail.Failed to delete policy change',
        defaultMessage: 'Failed to delete policy change'
    },
    approvedForThisCancellation: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Cancellation_v2',
        defaultMessage: 'All underwriting issues have been approved for this cancellation.'
    },
    approvedForThisSubmission: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Submission_v2',
        defaultMessage: 'All underwriting issues have been approved for this quote.'
    },
    approvedForThisRenewal: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Renewal_v2',
        defaultMessage: 'All underwriting issues have been approved for this renewal.'
    },
    approvedForThisPolicyChange: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this PolicyChange_v2',
        defaultMessage: 'All underwriting issues have been approved for this policy change.'
    },
    withdrawOrContinuePolicyChange: {
        id: 'gateway.views.job-uwissues.endorsement.You may either withdraw or continue to the PolicyChange wizardV2',
        defaultMessage: 'You may either withdraw or continue to the policy change wizard.'
    },
    withdrawOrContinueRenewal: {
        id: 'gateway.views.job-uwissues.endorsement.You may either withdraw or continue to the Renewal wizardV2',
        defaultMessage: 'You may either withdraw or continue to the renewal wizard.'
    },
    withdrawOrContinueSubmission: {
        id: 'gateway.views.job-uwissues.endorsement.You may either withdraw or continue to the Submission wizard',
        defaultMessage: 'You may either withdraw or continue to the quote wizard.'
    },
    withdrawOrContinueCancellation: {
        id: 'gateway.views.job-uwissues.endorsement.You may either withdraw or continue to the Cancellation wizardV2',
        defaultMessage: 'You may either withdraw or continue to the cancellation wizard.'
    },
    withdrawThePolicyChange: {
        id: 'gateway.views.job-uwissues.endorsement.You may withdraw the PolicyChange',
        defaultMessage: 'You may withdraw the policy change'
    },
    withdrawTheRenewal: {
        id: 'gateway.views.job-uwissues.endorsement.You may withdraw the Renewal',
        defaultMessage: 'You may withdraw the renewal'
    },
    withdrawTheQuote: {
        id: 'gateway.views.job-uwissues.endorsement.You may withdraw the Submission',
        defaultMessage: 'You may withdraw the quote'
    },
    withdrawTheCancellation: {
        id: 'gateway.views.job-uwissues.endorsement.You may withdraw the Cancellation',
        defaultMessage: 'You may withdraw the cancellation'
    },
    noOpenUnderwritingPolicyChange: {
        id: 'gateway.views.job-uwissues.There are no underwriting issues associated with this.PolicyChangeV2',
        defaultMessage: 'There are no open underwriting issues associated with this policy change.'
    },
    noOpenUnderwritingRenewal: {
        id: 'gateway.views.job-uwissues.summary.There are no underwriting issues associated with this.RenewalV2',
        defaultMessage: 'There are no open underwriting issues associated with this renewal.'
    },
    viewPolicyChange: {
        id: 'gateway.views.job-uwissues.summary.viewPolicyChange',
        defaultMessage: 'View Policy Change'
    },
    preemptedMessageTitlePart1: {
        id: 'gateway.views.job-uwissues.endorsement.preemptedMessageTitlePart1',
        defaultMessage: 'The changes made on endorsement '
    },
    preemptedMessageTitlePart2: {
        id: 'gateway.views.job-uwissues.endorsement.preemptedMessageTitlePart2',
        defaultMessage: ' have been applied to this change request.'
    },
    preemptedMessageDescriptionPart1: {
        id: 'gateway.views.job-uwissues.endorsement.preemptedMessageDescriptionPart1',
        defaultMessage: "Click 'Continue Policy Change' to apply all updates to the policy."
    },
    preemptedMessageDescriptionPart2: {
        id: 'gateway.views.job-uwissues.endorsement.preemptedMessageDescriptionPart2',
        defaultMessage: "Or you can 'Delete Policy Change' if the change request is no longer needed."
    },
    backTOPolicyDetails: {
        id: 'custom.gateway.directives.endorsementSummary.Back To Policy Details',
        defaultMessage: 'Back to Policy Details'
    },
    backTOPolicySummary: {
        id: 'custom.gateway.directives.endorsementSummary.Back To Policy Summary',
        defaultMessage: 'Back to Policy Summary'
    },
});
