import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { DataTable,DisplayColumn } from '@jutro/legacy/datatable';
import { Flex, FlexItem } from '@jutro/layout';
import { Button, RadioButtonField, InputField, DropdownSelectField } from '@jutro/legacy/components';
import messages from './CRSearchAndAddClausesPopup.messages';


function CRSearchAndAddClausesPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        serviceProps,
        setLoadingMask,
        covType,
        searchClausesFunc,
        addSearchedClausesFunc,
        coverableName,
        coverableCategory,
        jurisdictionCode
    } = props;

    const {
        jobID,
        sessionUUID,
        authHeader
    } = serviceProps;

    const translator = useTranslator();
    const [coverageCategory, updateCoverageCategory] = useState('all');
    const [searchKeyword, updateSearchKeyword] = useState(null);
    const [coverageType, updateCoverageType] = useState(covType);
    const [searchedCoveragesData, updateSearchedCoveragesData] = useState([]);
    const [selections, updateSelections] = useState([]);

    const availableTypeValues = [{id: 'conditions', displayName: 'Conditions'}, {id: 'exclusions', displayName: 'Exclusions'}, {id: 'exclusionsAndConditions', displayName: 'Exclusions And Conditions'}];
    
    const availableCategoryValues = useMemo(() => {
        if (covType === 'additionalCoverages') {
            return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'additionalCoverages', name: translator(messages.CoverableAdditionalCoverageCategory, {coverableCategory: coverableCategory})}];
        } 
        if (covType === 'exclusionsAndConditions') {
            return [{code: 'all', name: translator(messages.AllCoveragesCategory)}, {code: 'conditions', name: translator(messages.CoverableConditionsCategory, {coverableCategory: coverableCategory})}, {code: 'exclusions', name: translator(messages.CoverableExclusionsCategory, {coverableCategory: coverableCategory})}];    
        }
    }, [covType, coverableCategory, translator])

    const searchPopupTitle = useMemo(()=>{
        if (covType === 'additionalCoverages') {
            return translator(messages.SearchCoveragesForCoverableTitle, {coverableName: coverableName});
        }
        return translator(messages.SearchExclusionsAndConditionsForCoverableTitle, {coverableName: coverableName});
    }, [covType, coverableName, translator])

    const coverageButtonTypeTitle = useMemo(()=>{
        if (covType === 'additionalCoverages' || covType === 'additionalInsureds') {
            return translator(messages.coveragesButtonTitle);
        }
        return translator(messages.exclusionsAndConditionsButtonTitle);
    }, [covType, translator])

    const serchCoverages = useCallback(async() => {
        setLoadingMask(true);
        let searchResults;
        if (!_.isNil(jurisdictionCode)) {
            searchResults = await searchClausesFunc(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, jurisdictionCode, authHeader);
        } else {
            searchResults = await searchClausesFunc(jobID, sessionUUID, coverageCategory, searchKeyword, coverageType, authHeader);
        
        }
        if (!_.isEmpty(searchResults)) {
            updateSearchedCoveragesData(searchResults);
        } else {
            updateSearchedCoveragesData([]);
        }
        setLoadingMask(false);
    }, [authHeader, coverageCategory, coverageType, jobID, jurisdictionCode, searchClausesFunc, searchKeyword, sessionUUID, setLoadingMask])

    const addSelectedCoverage = useCallback(async()=>{
        setLoadingMask(true);
        const clauseSpecData = searchedCoveragesData.filter(cov => selections.includes(searchedCoveragesData.indexOf(cov)));
        let res;
        if (!_.isNil(jurisdictionCode)) {
            res = await addSearchedClausesFunc(jobID, sessionUUID, clauseSpecData, jurisdictionCode, authHeader);
        } else {
            res = await addSearchedClausesFunc(jobID, sessionUUID, clauseSpecData, authHeader);
        }
        setLoadingMask(false);
        return res;
    }, [addSearchedClausesFunc, authHeader, jobID, jurisdictionCode, searchedCoveragesData, selections, sessionUUID, setLoadingMask])

    const resetChoice = () => {
        updateCoverageCategory('all');
        updateSearchKeyword(null);
        updateCoverageType(covType);
        updateSearchedCoveragesData([]);
    }

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="coverageContactSearchComponent" autoFocus={false}>
                {!_.isNil(coverableName) && <h3>{searchPopupTitle}</h3>}
                <hr/>
                <DropdownSelectField
                    id="coverage_category"
                    label="Category"
                    labelPosition="left"
                    alwaysShowPlaceholder={false}
                    availableValues={availableCategoryValues}
                    onValueChange={(value) => updateCoverageCategory(value)}
                    value={coverageCategory}
                />
                <InputField
                    id="coverage_keyword"
                    label="Keyword"
                    labelPosition="left"
                    onValueChange={(value) => updateSearchKeyword(value)}
                    value={searchKeyword}
                />
                <RadioButtonField
                    id="coverage_type"
                    label="Type"
                    labelPosition="left"
                    availableValues={availableTypeValues}
                    showRequired
                    required
                    onValueChange={(value) => updateCoverageType(value)}
                    visible={covType === 'exclusionsAndConditions'}
                    value={coverageType}
                />
                <Flex justifyContent="left">
                    <FlexItem><Button onClick={serchCoverages}>Search</Button></FlexItem>
                    <FlexItem><Button onClick={resetChoice} type="outlined">Reset</Button></FlexItem>
                </Flex>
                <DataTable
                    columnsConfigurable={false}
                    data={searchedCoveragesData}
                    defaultConfig={{
                        // filterValue: '',
                        // page: 0,
                        pageSize: 5
                    }}
                    pageSizeOptions={[5]}
                    id="basic"
                    showPagination
                    showSearch={false}
                    selectionType="multi"
                    onSelectionChange={(indexs) => {
                        updateSelections(indexs)
                    }}
                    >
                    <DisplayColumn
                        header="Coverage"
                        id="coverage"
                        path="patternDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
                    <DisplayColumn
                        header="Category"
                        id="category"
                        path="categoryDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
                    <DisplayColumn
                        header="Type"
                        id="type"
                        path="clauseType"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
            </DataTable>
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "right",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject()}} type="outlined">Cancel</Button>
                <Button onClick={()=>{onResolve(addSelectedCoverage())}} disabled={_.isEmpty(selections)}>Add Selected {coverageButtonTypeTitle}</Button>
            </ModalFooter>
        </ModalNext>
    );
}
CRSearchAndAddClausesPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    jurisdictionCode: PropTypes.string,
    locationPublicID: PropTypes.string
};

CRSearchAndAddClausesPopup.defaultProps = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    jurisdictionCode: undefined,
    locationPublicID: undefined
};

export default CRSearchAndAddClausesPopup;
