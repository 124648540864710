import { Icon, ModalBody, ModalFooter, ModalNext, NumberValue } from '@jutro/components';
import { DataTable,DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React,{
useCallback,
useState
} from 'react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import messages from '../../WCStateSpecificInformationPage.messages';

import { Button, InputField, InputNumberField } from '@jutro/legacy/components';

function ModifiersPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        rateFactors,
        overallMinimum,
        overallMaximum,
        overallMinAK,
        overallMaxAK,
        selectedState,
        cancelBtnLabel,
        actionBtnLabel,
        translator,
        isReadOnly
    } = props;

    const [isModifiersUpdated, setIsModifiersUpdated] = useState(false);
    const defaultValidationInfo = selectedState === 'AK' ? [{
        type: 'info',
        reason: translator(messages.modifierRangeInfo, {max: `${Math.round(overallMaxAK * 100)}%`})
    }] : [{
        type: 'info',
        reason: translator(messages.modifierRangeInfo, {max: `${Math.round(overallMaximum * 100)}%`})
    }];
    const initialTotalCreditDebit = rateFactors.reduce((total, item) =>item.creditDebit + total, 0);
    const [newRateFactors, updateNewRateFactors] = useState(rateFactors);
    const [totalCreditDebit, updateTotalCreditDebit] = useState(initialTotalCreditDebit);
    const [showErrors, updateShowErrors] = useState(false);
    const getRateFactorID = (rateFactor) => _.get(rateFactor, 'patternCode');

    const writeValue = (value, path, item) => {
        const updatedRateFactors= _.cloneDeep(newRateFactors).map((oldRateFactor) => {
            if (getRateFactorID(oldRateFactor) === getRateFactorID(item)) {
                // eslint-disable-next-line no-param-reassign
                oldRateFactor[path] = value;
            }
            return oldRateFactor
        });
        if(path === 'creditDebit') {
            const newTotalCreditDebit = updatedRateFactors.reduce((total, rateFactor) =>  total + rateFactor.creditDebit, 0);
            updateTotalCreditDebit(newTotalCreditDebit);
        }
        updateNewRateFactors(updatedRateFactors);
    };

    const handleJustificationChange = (justification, item) => {
        if(justification !== _.get(item, 'justification')){
            setIsModifiersUpdated(true);
        }
    }
    
    const handleCreditDebitOnBlur = (value, item) => {
        setIsModifiersUpdated(true);
        writeValue(parseFloat(((value) * 100/10000).toFixed(4)), 'creditDebit', item);
    }

    const CreditDebitInputField = ({ item }) => {
        const shownCreditDebit = _.isNaN(_.get(item, 'creditDebit'))
            ? null
            : `${parseFloat(_.get(item, 'creditDebit') * 100).toFixed(2)}`;
        const [creditDebit, setCreditDebit] = useState(shownCreditDebit);
        const errorCondition =
            (parseFloat(creditDebit) * 100) / 10000 > item.maximum ||
            (parseFloat(creditDebit) * 100) / 10000 < item.minimum ||
            _.isNaN((parseFloat(creditDebit) * 100) / 10000);
        return (
            <Flex justifyContent="left" alignContent="middle">
                <InputNumberField
                    allowNegative
                    onValueChange={(value) => {
                        setCreditDebit(value);
                    }}
                    onBlur={() => {
                        handleCreditDebitOnBlur(creditDebit, item);
                    }}
                    value={creditDebit}
                    showErrors
                    decimalPlaces={2}
                    readOnly={isReadOnly}
                    validationMessages={
                        errorCondition
                            ? [messages.invalidRateFactorError]
                            : null
                    }
                />
                <Icon icon="cust-percent" style={{ 'margin-block': 'auto' }} />
            </Flex>
        );
    };
    const JustificationInputField = ({ item }) => {
        const [justification, setJustification] = useState(
            _.get(item, 'justification')
        );
        return (
            <InputField
                onValueChange={(value) => setJustification(value)}
                onBlur={() => {
                    handleJustificationChange(justification, item);
                    writeValue(justification, 'justification', item);
                }}
                readOnly={isReadOnly}
                value={justification}
            />
        );
    };

    const getValidationIssues = () => {
        let validationIssues = [];
        const invalidRateFactors = newRateFactors.filter((item) => item.creditDebit !== 0 && !_.isNaN(item.creditDebit) && (_.isNil(item.justification) || item.justification === ''));
        if (totalCreditDebit <  (overallMinAK || overallMinimum) || totalCreditDebit > (overallMaxAK || overallMaximum)) {
            validationIssues = [...validationIssues, {
                type: 'warning',
                reason: messages.creditDebitOutOfRange.defaultMessage
            }]
        } 
        if (!_.isEmpty(invalidRateFactors)) {
            validationIssues = [...validationIssues, {
                type: 'warning',
                reason: messages.justificationsRequired.defaultMessage
            }]
        } 
        return validationIssues
    }

    const isRateFactorsValid = useCallback(() => {
        const invalidRateFactors = newRateFactors.filter((item) => item.creditDebit !== 0 && (_.isNil(item.justification) || item.justification === ''));
        const invalidCreditDebitInput = newRateFactors.filter( (item) => (item.creditDebit > item.maximum) || (item.creditDebit < item.minimum) || _.isNaN(item.creditDebit));
        if ((totalCreditDebit < (overallMinAK || overallMinimum)) || (totalCreditDebit > (overallMaxAK || overallMaximum)) || !_.isEmpty(invalidRateFactors) || !_.isEmpty(invalidCreditDebitInput)) {
            return false;
        } 
        return true;
        
    }, [newRateFactors, overallMaxAK, overallMaximum, overallMinAK, overallMinimum, totalCreditDebit])

    const handleSave = useCallback(() => {
        if (!isRateFactorsValid()) {
            updateShowErrors(true)
            return false
        } 
        if (isModifiersUpdated){
            onResolve(newRateFactors);
        }
    }, [isRateFactorsValid, isModifiersUpdated, onResolve, newRateFactors]);

    const shownRateFactors = newRateFactors.concat({category: 'Overall'});
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="wcModifierPage" autoFocus={false}>
                <h3>Schedule Rating</h3>
                <hr/>
                <ValidationIssuesComponent validationIssues={showErrors ? defaultValidationInfo.concat(getValidationIssues()): defaultValidationInfo}/>
                <DataTable
                    columnsConfigurable={false}
                    data={shownRateFactors}
                    defaultConfig={{
                        filterValue: '',
                        page: 0,
                        pageSize: 10
                    }}
                    id="basic"
                    showPagination={false}
                    showSearch={false}
                    tableLabel="Modifiers"
                    >
                    <DisplayColumn
                        header="Category"
                        id="category"
                        path="category"
                        renderCell={(item, index) => {
                            return index === newRateFactors.length 
                            ? <InputField readOnly value={item.category}/>
                            : item.category
                        }}
                        textAlign="left"
                        columnProportion="3"
                        sortable={false}
                    />
                    <DisplayColumn
                        header="Maximum Credit"
                        id="minimum"
                        path="minimum"
                        renderCell={(item, index) => {
                            return index === newRateFactors.length 
                            ? (overallMinAK && `${parseFloat(overallMinAK * 100).toFixed(2)}%`) || `${Math.round(overallMinimum * 100)}%` 
                            : <NumberValue format="percent" value={item.minimum}/>
                        }}
                        textAlign="left"
                        columnProportion="1.5"
                        sortable={false}
                    />
                    <DisplayColumn
                        header="Maximum Debit"
                        id="maximum"
                        path="maximum"
                        renderCell={(item, index) => {
                            return index === newRateFactors.length 
                            ? (overallMaxAK && `${parseFloat(overallMaxAK * 100).toFixed(2)}%`) || `${Math.round(overallMaximum * 100)}%` 
                            : <NumberValue format="percent"  value={item.maximum}/>
                        }}
                        textAlign="left"
                        columnProportion="1.5"
                        sortable={false}
                    />
                    <DisplayColumn
                        header="Credit(-)/Debit(+) Percentage"
                        id="credit_debit"
                        path="creditDebit"
                        renderCell={(item, index) => {
                            return index === newRateFactors.length 
                            ? `${parseFloat(totalCreditDebit * 100).toFixed(2)}%` 
                            : <CreditDebitInputField item={item}/>
                        }} 
                        textAlign="left"
                        columnProportion="2.5"
                        sortable={false}
                    />
                    <DisplayColumn
                        header="Justification"
                        id="justification"
                        path="justification"
                        renderCell= {(item) => {
                            return item.category === 'Overall'
                            ? ''
                            : <JustificationInputField item={item}/>
                        }}
                        textAlign="left"
                        columnProportion="1.5"
                        sortable={false}
                    />
            </DataTable>
            </ModalBody>
            <ModalFooter>
                <Button onClick={()=>{onReject()}} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={()=>{handleSave()}} disabled={showErrors && !isRateFactorsValid()}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

ModifiersPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    rateFactors: PropTypes.array.isRequired,
    overallMinimum: PropTypes.number,
    overallMaximum: PropTypes.number,
    cancelBtnLabel: PropTypes.object,
    actionBtnLabel: PropTypes.object,
    isReadOnly: PropTypes.string
}

ModifiersPopup.defaultProps = {
    size: 'lg',
    onResolve: _.noop,
    onReject: _.noop,
    rateFactors: [],
    isOpen: false,
    isReadOnly: false
};

export default ModifiersPopup;

