import React from 'react';
import WizardPage from '../../templates/CAWizardPage';
import CALocationsPage from './CALocationsPage';

function CALocationsReadOnlyPage(props) {
    return (
        <CALocationsPage {...props} isReadOnly/>
    );
}

CALocationsReadOnlyPage.propTypes = WizardPage.propTypes;
export default CALocationsReadOnlyPage;
