import React from 'react';
import _ from 'lodash';
import { ConfigUtil } from "wni-portals-util-js";
import { Link as LinkComponent } from 'gw-components-platform-react';
import { UrlUtil } from 'wni-portals-util-react';
import { minYear, requiredForIssuanceData } from '../config/Underlying.static';
import messages from '../PUUnderlyingPage.messages';

const getAvailableYears = (startYear) => {
    const availableMinYear = startYear || minYear
    const currentYear = new Date().getFullYear();
    const yearRange = _.range(currentYear + 1, availableMinYear - 1);
    return yearRange.map((item) => {
        return {
            code: item,
            name: item
        };
    });
};

const getMutilAvailableValues = (vm, path, translator) => {
    const options = _.get(vm,  `${path}_Options.aspects.availableValues`) || []
    return options.map((item) => {
        return {
            code: item.code,
            name: translator({ id: item.name })
        }
    })
};

const setDefaultObj = (policyCovered, policyLine) => {
    const rowIdPath = `${policyCovered}:${ConfigUtil.getUuid()}`;
    if (policyCovered === 'property') {
        return {
            rowIdPath: rowIdPath,
            puDwellingAnimals: { animals: [] },
            propertyLocation: {address: {} },
        }
    }
    return {
        rowIdPath: rowIdPath,
        policyLine: policyLine
    }
};

const tooltipForVehicleType = (translator, link) => {
    return (
        <>
            {translator(messages.vehicleTypeHelpLink, {
                here: <LinkComponent onClick={() => window.open(link)}>here</LinkComponent>
            })}
        </>
    )
};

// eslint-disable-next-line default-param-last
const isPropertyFieldVisible = (vm = {}, fieldPath) => {
    let isVisible = true;
    const {
        occupancy,
        swimmingPoolType,
        existATrampoline,
        additionalBusinessType,
        hasBusiConductedOnPremise,
    } = vm.value || {};

    let isFieldReset = true;
// console.log(vm, vm.value.approvedFence)
    switch (fieldPath) {
        case 'approvedFence':
            isVisible = ['AboveGround', 'InGround'].includes(swimmingPoolType)
            break;
        case 'approvedFenceExplain':
            isVisible = _.get(vm.value, 'approvedFence') === false;
            break;
        case 'divingBoard':
            isVisible =  ['AboveGround', 'InGround', 'Indoor_Ext'].includes(swimmingPoolType)
            break;
        case 'depthOfThePool':
            isVisible = _.get(vm.value, 'divingBoard') === true
            break;
        case 'isTrampolineNetted':
            isVisible = existATrampoline === true;
            break;
        case 'isTrampolineSecured': 
            isVisible = existATrampoline === true
            break;
        case 'businessTypeExplain':
            isVisible = additionalBusinessType === 'other';
            break;
        case 'isPropertyRentedToOthers':
            isVisible = occupancy === 'owneroccupiedprimary_Ext' || occupancy === 'owneroccupiedseasonal_Ext'
            break;
        case 'additionalBusinessType':
            isVisible = hasBusiConductedOnPremise === 'yesbusiness';
            break;
        default:
            isVisible = true;
    }
    if (!isVisible && isFieldReset) {
        _.set(vm.value, fieldPath, undefined);
    }
    return isVisible;
};

// eslint-disable-next-line default-param-last
const isAutoFieldVisible = (vm = {}, fieldPath) => {
    let isVisible = true;
    if(!vm.value) {
        return false
    }
    const { 
        vehicleType,
    } = vm.value;

    let isFieldReset = true;

    switch (fieldPath) {
        case 'vin':
        case 'year':
        case 'make':
        case 'model':
            isVisible = ['privatepassengerauto_Ext', 'motorhome_Ext', 'collector_auto_Ext'].includes(vehicleType);
            break;
        case 'vehExclusiveUseToFarmOwned': 
            isVisible = ['privatepassengerauto_Ext', 'namenonowner_ext'].includes(vehicleType)
            break;
        case 'vehicleUsedInBusinessOwned':
            isVisible = ['privatepassengerauto_Ext', 'namenonowner_ext', 'collector_auto_Ext'].includes(vehicleType);
            break;
        case 'vehicleUsedInBusiOwnedExplain':
            isVisible = !!_.get(vm.value, 'vehicleUsedInBusinessOwned')
            break;
        case 'motorHomeRented':
            isVisible = vehicleType === 'motorhome_Ext'
            break;
        case 'milePercentPerYear':
            isVisible = _.get(vm.value, 'motorHomeRented') === true
            break;
        default:
            isVisible = true;
    }
    if (!isVisible && isFieldReset) {
        _.set(vm.value, fieldPath, undefined);
    }
    return !!isVisible;
};

// eslint-disable-next-line default-param-last
const isInfoFieldVisible = (currentRow = {}, fieldPath) => {
    let isVisible = true;
    const { 
        coveredItem,
        underlyingPolicyType,
        
    } = currentRow.value;

    let isFieldReset = true;

    switch (fieldPath) {
        case 'areVehiclesUnderSamePol':
            isVisible = coveredItem !== 'property' && _.get(currentRow.value, coveredItem).length > 1
            break;
        case 'ulPolicyBaseState':
            isVisible = underlyingPolicyType !== 'self-insured'
            break;
        case 'vehCoveredUnderThisPolicy':
            isVisible = coveredItem !== 'property' && _.get(currentRow.value, 'areVehiclesUnderSamePol') === false
            break;
        case 'hasUlPolicyCSLLimit':
            isVisible = ['personal_auto', 'business_auto', 'motorcycle', 'recreational_vehicle'].includes(underlyingPolicyType)
            break;
        case 'ulPolicyCSLLimit':
            isVisible = !!_.get(currentRow.value, 'hasUlPolicyCSLLimit')
            break;
        case 'ulPolicyCSLOtherLimit': 
            isVisible = _.get(currentRow.value, 'ulPolicyCSLLimit') === 'other_limit'
            break;
        case 'ulPolicyBILimit':
            isVisible = _.get(currentRow.value, 'hasUlPolicyCSLLimit') === false
            break;
        case 'ulPolicyBIOtherLimit':
            isVisible = _.get(currentRow.value, 'ulPolicyBILimit') === 'other_limit'
            break;
        case 'ulPolicyPDLimit':
            isVisible = _.get(currentRow.value, 'hasUlPolicyCSLLimit') === false
            break;
        case 'ulPolicyPDOtherLimit':
            isVisible = _.get(currentRow.value, 'ulPolicyPDLimit') === 'other_limit'
            break;
        case 'ulPolicyLiabilityLimit':
            isVisible = ['dwelling_property', 'homeowner', 'watercraft'].includes(underlyingPolicyType)
            break;
        case 'ulPolicyLiabOtherLimit':
            isVisible = _.get(currentRow.value, 'ulPolicyLiabilityLimit') === 'other_limit'
            break;
        case 'ulPolicyNumber':
        case 'ulPolicyCarrier':
        case 'ulPolicyEffectiveDate':
        case 'ulPolicyExpirationDate':
        case 'ulPolicyStatus':
            isVisible = underlyingPolicyType !== 'self-insured'
            break;
        default:
            isVisible = true;
    }
    if (!isVisible && isFieldReset) {
        _.set(currentRow.value, fieldPath, undefined);
    }
    return !!isVisible;
};

const generateIssuanceValid = (vm, baseData) => {
    const issuanceMaps = [];
    
    const {
        coveredItem
    } = vm.value;

    const requiredForIssuanceField = requiredForIssuanceData[coveredItem] || [];
    const requiredForIssuanceFieldForPolicy = requiredForIssuanceData.coverageInfo || [];
    const invalidIssuanceFields = [];

    // /** for underlying coverage information */
    requiredForIssuanceFieldForPolicy.forEach((fieldPath) => {
        const isVisible = isInfoFieldVisible(vm, fieldPath);
        issuanceMaps.push({
            path: fieldPath,
            visible: isVisible,
            fieldValue: _.get(vm, `value.${fieldPath}`)
        })
    });

    if(coveredItem === 'property') {
        requiredForIssuanceField.forEach((fieldPath) => {
            const isVisible = isPropertyFieldVisible(vm[coveredItem], fieldPath);
            issuanceMaps.push({
                path: `${coveredItem}.${fieldPath}`,
                visible: isVisible,
                fieldValue: _.get(vm, `value.${coveredItem}.${fieldPath}`)
            })
        });

        const newIssuanceMapsForProperty = issuanceMaps.filter((item) => _.get(item, 'visible'));
        newIssuanceMapsForProperty.forEach((item) => {
            const { path } = item;
            const fieldValue = _.get(vm, `value.${path}`);
            const isCurrencyField = _.isObject(fieldValue) && _.get(fieldValue, 'currency');
            if (isCurrencyField && (_.isNil(_.get(fieldValue, 'amount')) || _.get(fieldValue, 'amount') === '')) {
                invalidIssuanceFields.push(path);
            }
            if (_.isNil(fieldValue) || fieldValue.length === 0) {
                invalidIssuanceFields.push(path);
            }
        });

        return invalidIssuanceFields;
    }

    const coveredVM = vm[coveredItem] || []
    requiredForIssuanceField.forEach((fieldPath) => {
        coveredVM.forEach((itemVM, index) => {
            const isVisible = coveredItem === 'auto' ? isAutoFieldVisible(itemVM, fieldPath) : true;
            issuanceMaps.push({
                path: `${coveredItem}${index}-${fieldPath}`,
                visible: isVisible,
                fieldValue: _.get(itemVM, `value.${fieldPath}`)
            })
        })
        
    })
    const newIssuanceMaps = issuanceMaps.filter((item) => _.get(item, 'visible'));

    newIssuanceMaps.forEach((item) => {
        const { path, fieldValue } = item;

        const isCurrencyField = _.isObject(fieldValue) && _.get(fieldValue, 'currency');
        if (isCurrencyField && (_.isNil(_.get(fieldValue, 'amount')) || _.get(fieldValue, 'amount') === '')) {
            invalidIssuanceFields.push(path);
        }
        if (_.isNil(fieldValue) || fieldValue.length === 0) {
            invalidIssuanceFields.push(path);
        }
    });
    return invalidIssuanceFields;
};


export {
    getAvailableYears,
    getMutilAvailableValues,
    setDefaultObj,
    tooltipForVehicleType,
    isAutoFieldVisible,
    isPropertyFieldVisible,
    isInfoFieldVisible,
    generateIssuanceValid
}