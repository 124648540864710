// import _ from 'lodash';

// const flowStepIdToPageMap = {
//     // see PALineAssignmentValidator
//     PersonalVehicles: 'PAVehiclesPage',
//     PolicyInfo: 'PAPolicyDetailsPage',
// };

const validationIssues = {
    PAVehiclesPage: [
        // ref: PALineCoveragesValidator, Web.Policy.PA.Validation.VerifyOtherThanCollsion_Ext
        'Recreation Trailer or Utility Trailer must have Other Than Collision coverages,'
    ]
};

export default {
    // flowStepIdToPageMap
};
