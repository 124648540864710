
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import metadata from './ChangeSummaryComponent.metadata.json5';
import styles from './ChangeSummaryComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const ChangeSummaryComponent = (props) => {
    const {
        validationIssues,
        typeTitleFormatter,
        previousTotalCost,
        transactionCost,
        totalCost,
        effectiveDateFormat,
        periodDateRange,
        // transactionCostAmount,
        showPremiumChange,
    } = props;

    // const { authHeader, authUserData } = useAuthentication();
    // const viewModelService = useContext(ViewModelServiceContext);
    const transactionCostAmount = _.get(totalCost, 'amount');

    useEffect(() => {
    }, []);


    // const writeValue = (value, path) => {
    //     // onValueChange(value, path);
    // };

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        PACostAndPremiumCurrentPremium: {
            value: previousTotalCost,
        },
        PAChangePremiumField: {
            visible: showPremiumChange,
            value: transactionCost,
            className: transactionCostAmount < 0 ? 'below' : 'over',
        },
        PANewPremiumField: {
            value: totalCost,
        },
        effectiveDateId: {
            value: effectiveDateFormat
        },
        peroidDateRangeId: {
            value: periodDateRange,
        },
        PAChangePremiumSubLabel: {
            // value: `${translator(currentMessages.papolicychangeQuoteFullTerm)} <amount>`,
            visible: false,
        },
        PANewPremiumSubLabel: {
            // value: `${translator(currentMessages.papolicychangeQuoteFullTerm)} <amount>`,
            visible: false,
        },
        infoValidationMessages: {
            visible: showPremiumChange,
            validationIssues,
            typeTitleFormatter,
            scrollToIssues: false,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
        },
    };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            // onValueChange={writeValue}
            // showErrors={showErrors}
        />
    );
};

ChangeSummaryComponent.propTypes = {
    validationIssues: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        reason: PropTypes.string,
    })),
    typeTitleFormatter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    previousTotalCost: PropTypes.shape({
        amount: PropTypes.number
    }),
    transactionCost: PropTypes.shape({
        amount: PropTypes.number
    }),
    totalCost: PropTypes.shape({
        amount: PropTypes.number
    }),
    effectiveDateFormat: PropTypes.string,
    periodDateRange: PropTypes.string,
    // transactionCostAmount: PropTypes.number,
    showPremiumChange: PropTypes.bool,
};

ChangeSummaryComponent.defaultProps = {
    validationIssues: undefined,
    typeTitleFormatter: undefined,
    previousTotalCost: undefined,
    transactionCost: undefined,
    totalCost: undefined,
    effectiveDateFormat: undefined,
    periodDateRange: undefined,
    // transactionCostAmount: undefined,
    showPremiumChange: false,
};

export default ChangeSummaryComponent;
