import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpStateInformationService'), method, data, additionalHeaders);
}

export default class CPCoverageService {
    /**
     * Invokes CPCoveragesHandler.getStateClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @param {string} state
     * @returns {Promise}
     */
    static getStateClauses(jobID, sessionUUID, state, authHeader = {}) {
        return processSubmission('getStateClauses', [jobID, sessionUUID, state], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.updateStateClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} state
     * @param {dto} CPStateCoveragesDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateStateClauses(jobID, sessionUUID, state, dto, authHeader = {}) {
        return processSubmission('updateStateClauses', [jobID, sessionUUID, state, dto], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.getStateSimpleInfos()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getStateSimpleInfos(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('getStateSimpleInfos', [jobID, sessionUUID], authHeader);
    }

    /**
     * Invokes CPCoveragesHandler.onStateInfoPageNext()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onStateInfoPageNext(jobID, sessionUUID, authHeader = {}) {
        return processSubmission('onStateInfoPageNext', [jobID, sessionUUID], authHeader);
    }

}