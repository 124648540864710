import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cuPolicyDetailsService'), method, data, additionalHeaders);
}

export default class CUPolicyDetailsService {

    static savePolicyDetailsData(requestData, authHeader) {
        return processSubmission('savePolicyDetailsData', [requestData.jobID, requestData.sessionUUID, requestData.policyDetailsData, requestData.quoteBaseData], authHeader);
    }

}
