import React, { useState, useMemo } from "react"
import _ from "lodash";
import { Button } from "@jutro/components";
import { WniDataTable, WniToggle } from "wni-common-base-components";
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { ProductUtil } from 'wni-portals-util-react';
import messages from './PropertyIncidents.messages'
import SinglePropertyIncident from "./SinglePropertyIncident/SinglePropertyIncident";
import { getFullAddressDisplayName } from "../util/AddressUtil";

const PropertyIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const AddNewLocation = 'AddNewLocation';

const propertyIncidentInitValue = {
    belongsToInsured_Ext: false
}

const {
    RT_LOB_NAME,
    WT_LOB_NAME,
    GL_LOB_NAME
} = ProductUtil;

const PropertyIncidents = (props) => {
    const {
        propertyIncidents = [],
        predefinedLossLocations,
        incidentsAddresses,
        LOB_NAME,
        propertyOwnerContacts,
        savePropertyIncident,
        removePropertyIncident
    } = props;

    const translator = useTranslator();

    const [propertyIncidentQuestionValue, setPropertyIncidentQuestion] = useState(() => {
        return propertyIncidents.length > 0 ? PropertyIncidentQuestionValue.Yes : PropertyIncidentQuestionValue.No
    })
    const [singlePropertyIncidentPopupStatus, setSinglePropertyIncidentPopupStatus] = useState({
        isOpen: false,
        originPropertyIncident: propertyIncidentInitValue,
        editingPropertyIncidentIndex: undefined
    })
    const [selectedIncidentIndexs, setSelectedIncidentIndexs] = useState([])
    const closePopup = () => {
        setSinglePropertyIncidentPopupStatus({
            isOpen: false,
            originPropertyIncident: propertyIncidentInitValue,
            editingPropertyIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = async (propertyIncident) => {
        await savePropertyIncident(propertyIncident);
        closePopup();
    }

    const onAddPropertyIncident = () => {
        const newPropertyLocation = {
            country: 'US' ,
            publicID: AddNewLocation
        };
        const newPropertyIncident = {
            locationPublicID_Ext: AddNewLocation,
            location_Ext: newPropertyLocation
        };
        setSinglePropertyIncidentPopupStatus({
            isOpen: true,
            originPropertyIncident: newPropertyIncident
        })
    }

    const PropertyQuestionLabel = useMemo(() => {
        switch (LOB_NAME) {
            case WT_LOB_NAME:
                return translator(messages.PropertyIncidentsQuestionWT);
            case RT_LOB_NAME:
                return translator(messages.PropertyIncidentsQuestionRT);
            case GL_LOB_NAME:
                return translator(messages.PropertyIncidentsQuestionGL);
            default:
                return translator(messages.PropertyIncidentsQuestion);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <>
        <WniToggle
            id='propertyIncidentQuestion'
            label={PropertyQuestionLabel}
            labelPosition='left'
            availableValues={[
                {
                    code: PropertyIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: PropertyIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setPropertyIncidentQuestion(value)
            }}
            disabled={propertyIncidents.length > 0}
            value={propertyIncidentQuestionValue}
        />
        {propertyIncidentQuestionValue === PropertyIncidentQuestionValue.Yes &&
            <>
                <Flex gap="small" id="propertyFlexButton" justifyContent="right" className="mb-10">
                    <Button
                        variant="primary"
                        icon="gw-add"
                        onClick={onAddPropertyIncident}
                        disabled={singlePropertyIncidentPopupStatus.isOpen}
                        label={translator(messages.AddProperty)}
                    />
                    <Button
                        type="primary"
                        className="wni-button-danger"
                        onClick={() => {
                            propertyIncidents.forEach((_incident, index) => {
                                if (selectedIncidentIndexs.includes(index)) {
                                    removePropertyIncident(propertyIncidents[index])
                                }
                            })
                        }}
                        disabled={_.isEmpty(selectedIncidentIndexs)}
                        label={translator(messages.DeleteProperty)}
                    />
                </Flex>
                <WniDataTable
                    id="propertyIncidentsTable"
                    showSearch= {false}
                    showPagination= {false}
                    data={propertyIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                    rowIdpath="number"
                    selectionType="multi"
                    onSelectionChange={(selectedIndexs) => setSelectedIncidentIndexs(selectedIndexs)}
                    selectedRows={selectedIncidentIndexs}
                >
                    <DisplayColumn
                        id="propertySectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="propertyDescriptionColumn"
                        header={translator(messages.PropertyDescription)}
                        path="description"
                        textAlign='center'
                        visible={LOB_NAME === GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="propertyAddressColumn"
                        header={translator(messages.PropertyAddress)}
                        path="location_Ext.addressLine1"
                        textAlign='center'
                        visible={LOB_NAME === GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="propertyCityColumn"
                        header={translator(messages.PropertyCity)}
                        path="location_Ext.city"
                        textAlign='center'
                        visible={LOB_NAME === GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="propertyStateColumn"
                        header={translator(messages.PropertyState)}
                        path="location_Ext.state"
                        textAlign='center'
                        visible={LOB_NAME === GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="propertyLocationColumn"
                        header={translator(messages.PropertyLocation)}
                        textAlign='center'
                        renderCell = {(item) => {
                            const addressPublicID = _.get(item, 'locationPublicID_Ext')
                            const allAddresses = predefinedLossLocations.concat(incidentsAddresses)
                            const matchedAddress = allAddresses.find((address) => address.publicID === addressPublicID)
                            return getFullAddressDisplayName(matchedAddress)
                        }}
                        visible={LOB_NAME !== GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {

                            return <Flex gap="small" justifyContent="right" className="mb-10">
                                <Button
                                    className="btn-link"
                                    onClick={() => setSinglePropertyIncidentPopupStatus({
                                        isOpen: true,
                                        originPropertyIncident: item,
                                        editingPropertyIncidentIndex: index
                                    })}
                                    label={translator(messages.viewAndEditLabel)}
                                />
                            </Flex>
                        }}
                    />
                </WniDataTable>
                {singlePropertyIncidentPopupStatus.isOpen && <SinglePropertyIncident
                    originPropertyIncident={singlePropertyIncidentPopupStatus.originPropertyIncident}
                    editingPropertyIncidentIndex={singlePropertyIncidentPopupStatus.editingPropertyIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                    predefinedLossLocations={predefinedLossLocations}
                    incidentsAddresses={incidentsAddresses}
                    propertyOwnerContacts={propertyOwnerContacts}
                />}
            </>
        }
        
    </>
}

export default PropertyIncidents
