
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WniCommonChangeService } from 'wni-capability-policychange';

import {
    WizardPageJumpUtil,
} from 'wni-portals-util-js';

import { PUHouseholdInfoChangeService } from 'wni-capability-policychange-pu';

import { PUHouseholdInfoPage } from 'wni-capability-quoteandbind-pu-react';

function PUHouseholdInfoChangePage(props) {

    const {
        authHeader,
        authUserData
    } = useAuthentication();

    const {
        wizardData: submissionVM,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        turnOffWizardPage,
        //
        clearVisitedStepsFromQuotePage,
    }  = props;

    const {
        jobID,
        sessionUUID,
        changeData: {
            isBillingOptionsEditable,
        }
    } = submissionVM.value;

    const {
        [WizardConstants.landingPage]: landingPage,
        [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const pageOnInit = useCallback(() => {
        if (landingPage) {
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'PUHouseholdInfoPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            clearVisitedStepsFromQuotePage();
        }
    }, []);

    useEffect(() => {
        pageOnInit();
    }, []);

    const savePolicyDetailsData = useCallback(async (...params) => {
        const retval = await PUHouseholdInfoChangeService.savePUHouseholdInfoData(...params);
        // Update ActiveQuotes as well, since PolicyPeriod publicID could be updated due to Date change

        const newActiveQuotes = await WniCommonChangeService.getActiveQuotes(jobID, sessionUUID, authHeader);
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: newActiveQuotes,
        });

        return retval;
    }, [authHeader, jobID, sessionUUID, updateWizardPageData]);

    const maximumEffectiveDate = _.get(submissionVM,'value.changeData.maximumEffectiveDate')


    return (
        <PUHouseholdInfoPage {...props}
            savePolicyDetailsData = {savePolicyDetailsData}
            isOpenPNIPopupPageInit = {policyChangeSource === 'cancellation'}
            setAgentComponentReadOnly
            maximumEffectiveDate = {maximumEffectiveDate}
            setEffectiveDateForChangeTransaction
        />
    );

}

PUHouseholdInfoChangePage.propTypes = PUHouseholdInfoPage.propTypes;
PUHouseholdInfoChangePage.defaultProps = PUHouseholdInfoPage.defaultProps;

export default PUHouseholdInfoChangePage;