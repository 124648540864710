import { defineMessages } from 'react-intl';

export default defineMessages({
    contactAgent: {
        id: 'contact-agent.views.Contact Agent',
        defaultMessage: 'Contact Agent'
    },
    basedOnTheInformation: {
        id: 'contact-agent.views.Based on the information you have provided, we are unable to process your request online.',
        defaultMessage: 'Based on the information you have provided, we are unable to process your request online.'
    },
    callForAssistance: {
        id: 'contact-agent.views.Please call one of our agents at',
        defaultMessage: 'Please call one of our agents at {assistNumber} for assistance.'
    },
    claimReferenceNumber: {
        id: 'fnol.common.views.contact-agent.Claim reference number',
        defaultMessage: 'Claim reference number {num}'
    },
    back: {
        id: 'contact-agent.views.Back',
        defaultMessage: 'Back'
    },
});
