
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import classNames from 'classnames';

import AnimalsUtil from '../../util/AnimalsUtil';
import ResidenceUtil from '../../util/ResidenceUtil';
import metadata from './AnimalFormComponent.metadata.json5';
import messages from '../../HOResidencePage.messages'

const INELIGIBLE_BREEDS = ['DobermanPinschers', 'Rottweilers', 'Chows', 'Akitas', 'PitBulls', 'StaffordshireBullTerriers', 
'PresaCanarios_Ext', 'Bandog_Ext', 'BullTerriers', 'AMixOfAnyOfTheseBreeds']

const AnimalFormComponent = (props) => {
    const {
        model: dwellingAnimalsVM,
        baseState,
        currentAnimalVM,
        isReadOnly,
        updateCurrentAnimalVM,
        updateAnimalsVM,
        editAnimal,
        originAnimalVM,
        viewModelService,
        isSubmissionType,
        linePath
    } = props;

    const translator = useTranslator();
    const [loadingAnimals, updateLoadingAnimals] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const ineligibleWarningStyle = classNames('wniKnockoutToolTip');
    const newDogEntry = currentAnimalVM.publicID === undefined ? false : currentAnimalVM.publicID.value === undefined

    const {
        animalType,
        animalBreed,
        animalBiteHistory
    } = currentAnimalVM.value || {};

    const {
        numberofPoultry,
        isChickenCoopHeated,
        howFarIsTheChickenCoop,
        sellOrSharedEggs
    } = dwellingAnimalsVM.value || {};

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('animalForm');

    const IsValidFields = useCallback(() => {
        if (!dwellingAnimalsVM.aspects.valid || !dwellingAnimalsVM.aspects.subtreeValid) {
            return false;
        }
        if (linePath === 'dwellingProperty') {
            return true;
        }
        if (animalType === 'Poultry_Ext'
        && (_.isNil(numberofPoultry) || _.isNil(isChickenCoopHeated) || _.isNil(sellOrSharedEggs))) {
            return false;
        }
        if (animalType === 'Poultry_Ext'
        && isChickenCoopHeated
        && _.isNil(howFarIsTheChickenCoop)) {
            return false;
        }
        if ( newDogEntry && describeFieldforDogBreeds && _.isNil(currentAnimalVM.animalDescribe.value)) {
            return false;
        }
        return true;
    }, [dwellingAnimalsVM, currentAnimalVM]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, dwellingAnimalsVM, currentAnimalVM]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const cancelAnimals = () => {
        const newDwellingAnimalsVM = viewModelService.clone(dwellingAnimalsVM);
        const animals = _.get(newDwellingAnimalsVM, 'animals.value');

        const removeNullAnimals = _.filter(animals, (animal) => {
            return animal.publicID
        })
        _.set(newDwellingAnimalsVM, 'animals.value', removeNullAnimals);

        if (_.get(originAnimalVM, 'value.publicID')) {
            const index = _.findIndex(animals, (animal) => { return animal.publicID === _.get(originAnimalVM, 'value.publicID')})
            _.set(newDwellingAnimalsVM, `animals.value.${index}`, originAnimalVM.value);
        }

        updateAnimalsVM(newDwellingAnimalsVM);
        updateCurrentAnimalVM(null);
    };

    const handleAnimalChange = useCallback((value, path) => {
        const newVM = viewModelService.clone(currentAnimalVM);
        if (path === 'animalType') {
            const availableBreed = AnimalsUtil.getAvailableBreed(
                _.get(currentAnimalVM, 'animalBreed.aspects.availableValues'), value, translator
            );
            const hasBreedOption = _.find(availableBreed, (val) => {
                return val.code === animalBreed
            });
            if (!hasBreedOption) {
                _.set(newVM, 'animalBreed', null);
            }

            if (value !== 'Dog') {
                _.set(newVM, 'animalBreed', 'none_Ext');
            }
        }
        _.set(newVM, path, value);
        updateCurrentAnimalVM(newVM);

        const newDwellingAnimalsVM = viewModelService.clone(dwellingAnimalsVM);
        const animals = _.get(newDwellingAnimalsVM, 'animals.value');
        const index = _.findIndex(animals, (animal) => { return animal.publicID ===  newVM.value.publicID})
        _.set(newDwellingAnimalsVM, `animals.value.${index}`, newVM.value);
        updateAnimalsVM(newDwellingAnimalsVM);
    }, [animalBreed, animalType, currentAnimalVM, dwellingAnimalsVM]);

    const handleDwellingAnimalChange = useCallback((value, path) => {
        const newVM = viewModelService.clone(dwellingAnimalsVM);
        _.set(dwellingAnimalsVM, path, value);
        updateAnimalsVM(newVM);
    }, [dwellingAnimalsVM]);

    const saveAnimal = useCallback(async () => {
        const requestData = {
            dwellingAnimals: dwellingAnimalsVM.value,
            animal: currentAnimalVM.value
        }
        updateLoadingAnimals(true);
        const res = await editAnimal(requestData);

        const animalsPath = `lobData.${linePath}.coverables.dwelling_Ext.dwellingAnimals`;
        dwellingAnimalsVM.value = _.get(res, animalsPath);
        updateAnimalsVM(dwellingAnimalsVM);
        updateCurrentAnimalVM(null);

        // const animals = _.get(res, `${animalsPath.animals}`);
        // _.each(animals, (elt) => {
        //     if (elt.publicID === currentAnimalVM.value.publicID) {
        //         currentAnimalVM.value = elt;
        //         updateCurrentAnimalVM(currentAnimalVM);
        //     }
        // })
        updateLoadingAnimals(false);
    }, [currentAnimalVM, dwellingAnimalsVM, editAnimal]);

    const animalBreedKnockoutObj = useCallback(() => {
        const dogBreedAvailableValues = AnimalsUtil.getAvailableBreed(
            _.get(currentAnimalVM, 'animalBreed.aspects.availableValues'), animalType, translator
        ) || [];
        const currentDogBreed = _.find(dogBreedAvailableValues, (dogBreed) => {
            return dogBreed.code === animalBreed;
        }) || {};
        const isDogBreedIneligble = baseState === 'AK' ? INELIGIBLE_BREEDS.concat('WolfHybrids').includes(animalBreed) :
            INELIGIBLE_BREEDS.concat('GermanShepherds').includes(animalBreed);
        const isShowAnimalBreedKnockout =  animalType === 'Dog' && isDogBreedIneligble;
        const animalBreedIneligibleMessage = translator(messages.dogBreedIneligible,{dogBreed: currentDogBreed.name});
        return {
            isShowKnockout: isShowAnimalBreedKnockout,
            ineligibleMessage: animalBreedIneligibleMessage
         };
    },[animalBreed, animalType, baseState, currentAnimalVM, translator]);
    const animalBreedFieldOverrideProps = ResidenceUtil.getIneligibleKnockout(animalBreedKnockoutObj(), linePath);

    const describeFieldforDogBreeds = animalType === 'Dog' && (animalBreed === 'Other' || animalBreed === 'AMixOfAnyOfTheseBreeds')

    const animalBiteHistoryKnockoutObj = useCallback(() => {
        const animalBiteHistoryIneligibleMessage = translator(messages.dogBiteHistoryIneligible);
        return {
            isShowKnockout: animalBiteHistory,
            ineligibleMessage: animalBiteHistoryIneligibleMessage
         };
    },[animalBiteHistory, translator]);
    const animalBiteHistoryOverrideProps = ResidenceUtil.getIneligibleKnockout(animalBiteHistoryKnockoutObj(), linePath);

    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        cancelAnimals: {
            onClick: cancelAnimals
        },
        saveAnimal: {
            onClick: isComponentValid ? saveAnimal : handleValidation
        },
        animalType: {
            requiredForIssuance: true
        },
        animalBreed: {
            availableValues: AnimalsUtil.getAvailableBreed(
                _.get(currentAnimalVM, 'animalBreed.aspects.availableValues'), animalType, translator
            ),
            visible: animalType === 'Dog',
            requiredForIssuance: true,
            ineligibleTooptip: animalBreedFieldOverrideProps
        },
        animalBiteHistory: {
            ineligibleTooptip: animalBiteHistoryOverrideProps
        },
        animalDescribe: {
            visible: animalType === 'Poultry_Ext' || animalType === 'Livestock_Ext' || animalType === 'Other'
            || describeFieldforDogBreeds,
            tooltip: animalType === 'Dog' ? translator(messages.dogBreedsDescription) : null,
            required: describeFieldforDogBreeds  && isSubmissionType === 'Submission' && newDogEntry
        },
        numberofPoultry: {
            visible: animalType === 'Poultry_Ext',
            required: animalType === 'Poultry_Ext' && linePath !== 'dwellingProperty'
        },
        heatedCoop: {
            visible: animalType === 'Poultry_Ext',
            required: animalType === 'Poultry_Ext' && linePath !== 'dwellingProperty'
        },
        howFarIsTheChickenCoop: {
            visible: animalType === 'Poultry_Ext' && isChickenCoopHeated === true,
            required: animalType === 'Poultry_Ext' && isChickenCoopHeated === true && linePath !== 'dwellingProperty'
        },
        sellOrSharedEggs: {
            visible: animalType === 'Poultry_Ext',
            required: animalType === 'Poultry_Ext' && linePath !== 'dwellingProperty'
        }
    };

    const resolvers = {
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent.content[0],
            currentAnimalVM,
            id,
            path,
            overrideProps
        );
    };

    const readDwellingAnimals = (id, path) => {
        return readViewModelValue(
            metadata.componentContent.content[1],
            dwellingAnimalsVM,
            id,
            path,
            overrideProps
        );
    };

    if (loadingAnimals) {
        return <Loader loaded={!loadingAnimals} />;
    }

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent.content[0]}
                model={currentAnimalVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValueChange={handleAnimalChange}
                showErrors={showErrors}
            />
            <ViewModelForm
                uiProps={metadata.componentContent.content[1]}
                model={dwellingAnimalsVM}
                overrideProps={overrideProps}
                resolveValue={readDwellingAnimals}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValueChange={handleDwellingAnimalChange}
                showErrors={showErrors}
            />
        </div>
    );
};

AnimalFormComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    currentAnimalVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    baseState: PropTypes.string,
    isReadOnly: PropTypes.bool,
    updateCurrentAnimalVM: PropTypes.func,
    editAnimal: PropTypes.func,
    updateAnimalsVM: PropTypes.func,
    originAnimalVM: PropTypes.shape({})
};

AnimalFormComponent.defaultProps = {
    model: {},
    currentAnimalVM: {},
    baseState: '',
    isReadOnly: false,
    updateCurrentAnimalVM: _.noop,
    editAnimal: _.noop,
    updateAnimalsVM: _.noop,
    originAnimalVM: null
};

export default AnimalFormComponent;
