import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PADriversReadOnlyPage } from 'gw-capability-quoteandbind-pa-react';
import { WniPolicyChangeDriverService } from 'wni-capability-policychange';


function PADriversChangeReadOnlyPage(props) {
    const { wizardData: submissionVM } = props;
    const {
        jobID,
    } = submissionVM.value;
    const { authHeader } = useAuthentication();

    const getCoverages = useCallback(async (driver) => {
        const request = {
            jobID,
            driver
        };
        const response = await WniPolicyChangeDriverService.getCoverages(
            request,
            authHeader
        );
        return response;
    }, []);

    const overrideProps = {
        ...props,
        policyChangeService: {
            getCoverages
        }
    };


    return <PADriversReadOnlyPage {...overrideProps} />;
}

PADriversChangeReadOnlyPage.propTypes = wizardProps;
export default PADriversChangeReadOnlyPage;
