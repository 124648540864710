import React from 'react'
import { CRCommercialFidelityService } from 'wni-capability-quoteandbind-cr'
import CRCoverablePage from '../CRCoverablePage/CRCoverablePage'

const CRCommercialFidelityPage = (props) => {
    return <CRCoverablePage
        {...props}
        initClausesWithSyncFunc={CRCommercialFidelityService.getCommercialFidelityWithSync}
        initClausesFunc={CRCommercialFidelityService.getCommercialFidelity}
        updateClausesFunc={CRCommercialFidelityService.updateCommercialFidelity}
        searchClausesFunc={CRCommercialFidelityService.searchCommercialFidelityCoverages}
        addSearchedClausesFunc={CRCommercialFidelityService.addSearchedCommercialFidelityCoverages}
        onPageNextService={CRCommercialFidelityService.onPageNext}
        coverableName='Commercial Fidelity'
        coverableCategory='CommlFidelity'
    />
}

export default CRCommercialFidelityPage