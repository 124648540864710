import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imWarehouseLegalLiabilityService'), method, data, additionalHeaders);
}

export default class IMWarehouseLegalLiabilityService {
  
    /**
     * Invokes IMWarehouseLegalLiabilityHandler.getWarehouseLegalLiabilityClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getWarehouseLegalLiabilityClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getWarehouseLegalLiabilityClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.getWarehouseLegalLiabilityClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getWarehouseLegalLiabilityClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getWarehouseLegalLiabilityClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.updateWarehouseLegalLiabilityClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} warehouseLegalLiabilityToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateWarehouseLegalLiabilityClauses(jobNumber, sessionUUID, warehouseLegalLiabilityToUpdate, authHeader = {}) {
        return processSubmission('updateWarehouseLegalLiabilityClauses', [jobNumber, sessionUUID, warehouseLegalLiabilityToUpdate], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.updateWarehouseLegalLiabilitySchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateWarehouseLegalLiabilitySchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateWarehouseLegalLiabilitySchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.onWarehouseLegalLiabilityNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onWarehouseLegalLiabilityNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onWarehouseLegalLiabilityNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMWarehouseLegalLiabilityHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }
}