/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
    WALMedicalPayments_Ext: {
        id: 'wni.custom.products.WAL.tooltip.$1,000 has been included on the base policy.',
        defaultMessage: '$1,000 has been included on the base policy.'
    },
    policyState: {
        id: 'wni.custom.products.WAL.tooltip.Please list the state where the vehicle is registered and primarily garaged.',
        defaultMessage: 'Please list the state where the vehicle is registered and primarily garaged.'
    },
    itemToBeInsured: {
        id: 'wni.custom.products.WAL.tooltip.Select one option that represents the watercraft type to be insured.',
        defaultMessage: 'Select one option that represents the watercraft type to be insured.'
    },
    watercraftRegistrationState: {
        id: 'wni.custom.products.WAL.tooltip.List the state where the watercraft is registered. If this is not the same as the policy state, use the dropdown to explain.',
        defaultMessage: 'List the state where the watercraft is registered. If this is not the same as the policy state, use the dropdown to explain.'
    },
    trailerRegistrationState: {
        id: 'wni.custom.products.WAL.tooltip.List the state where vehicle is registered. If the state does not match the policy state additional questions will be asked.',
        defaultMessage: 'List the state where vehicle is registered. If the state does not match the policy state additional questions will be asked.'
    },
    primarilyUsedCounty: {
        id: 'wni.custom.products.WAL.tooltip.Select the county/borough of most frequently visited waterways.',
        defaultMessage: 'Select the county/borough of most frequently visited waterways.'
    },
    hasAnyAdditionalInfo: {
        id: 'wni.custom.products.WAL.tooltip.Use this field to provide comments about any use, customization, or additional details. You may also attach a photo with the submission.',
        defaultMessage: 'Use this field to provide comments about any use, customization, or additional details. You may also attach a photo with the submission.'
    },
    WALReplacementCostPersonalProp_Ext: {
        id: 'wni.custom.products.WAL.tooltip.This will cover property such as: Clothing, coolers, fishing equipment, etc. ',
        defaultMessage: 'This will cover property such as: Clothing, coolers, fishing equipment, etc. '
    },
    WALTripInterruptionCoverage_Ext : {
        id: 'wni.custom.products.WAL.tooltip.Transportation, lodging and meal expenses incurred due to mechanical or electrical breakdown that occur more than 100 miles from home and your watercraft is withdrawn from use for more than 24 hours. Maximum limit $600.',
        defaultMessage: 'Transportation, lodging and meal expenses incurred due to mechanical or electrical breakdown that occur more than 100 miles from home and your watercraft is withdrawn from use for more than 24 hours. Maximum limit $600.'
    }
})