import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';


import IMRiskAnalysisPage from './IMRiskAnalysisPage';

function IMRiskAnalysisReadOnlyPage(props) {

    return <IMRiskAnalysisPage {...props} isReadOnly />
   
}


export default IMRiskAnalysisReadOnlyPage;