import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
// import { DocumentUploadService } from '@xengage/gw-portals-document-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayIncidentReport'), method, data, additionalHeaders);
}

export default class WniClueService {
    static getMVRData(type, quoteId, additionalHeaders = {}) {
        return processSubmission('getMVRData', [type, quoteId], additionalHeaders);
    }

    static updateMvr(type, quoteId, driverId, newModel, additionalHeaders = {}) {
        return processSubmission('updateMvr', [type, quoteId, driverId, newModel], additionalHeaders);
    }

    static deleteMvr(type, quoteId, driverId, publicId, additionalHeaders = {}) {
        return processSubmission('deleteMvr', [type, quoteId, driverId, publicId], additionalHeaders);
    }

    static saveMvr(type, quoteId, selectedDriverId, newModel, additionalHeaders = {}) {
        return processSubmission('saveMvr', [type, quoteId, selectedDriverId, newModel], additionalHeaders);
    }

    static updateMVRReport(type, quoteId, newModel, additionalHeaders = {}) {
        return processSubmission('updateMVRReport', [type, quoteId, newModel], additionalHeaders);
    }
}
