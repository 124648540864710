

import React from 'react';
import { IMFineArtsFloaterPage } from 'wni-capability-quoteandbind-im-react';


const IMFineArtsFloaterChangePage = (props) => {
    return <IMFineArtsFloaterPage {...props} isPolicyChange />
};
export default IMFineArtsFloaterChangePage
