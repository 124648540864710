import React, { useCallback } from 'react';
import _ from 'lodash';
import { RTVehicleService } from 'wni-capability-quoteandbind-rt'
import { WALAdditionalInterestComponent } from 'wni-capability-quoteandbind-wal-react';

function RTAdditionalInterest(props) {
    const {
        value: vehicleVM,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader,
            baseData: {
                accountNumber,
                accountContacts_Ext: accountContacts,
                accountHolder
            }
        },
        onValueChange,
        readOnly,
    } = props;

    const getAdditionalInterestTypeOptions = useCallback(async ({
        contactType, publicID
    }) => {
        let options;
        try {
            options = await RTVehicleService.getAdditionalInterestType(
                jobID, sessionUUID, publicID, contactType, authHeader
            );
        } catch (e) {
            options = [];
        }
        return options;
    }, [authHeader, jobID, sessionUUID]);

    const componentProps = {
        value: _.get(vehicleVM, 'additionalInterests'),
        accountNumber,
        accountContacts,
        authHeader,
        getAdditionalInterestTypeOptions,
        accountHolderBirthday: _.get(accountHolder, 'dateOfBirth'),
        onValueChange,
        readOnly,
    } 

    return (
        <WALAdditionalInterestComponent {...componentProps} />
    );
}

export default RTAdditionalInterest;
