import { defineMessages } from 'react-intl';

export default defineMessages({
    lineOfBusiness: {
        id: 'gateway.views.new-quote.product.Line of Business',
        defaultMessage: 'Line of Business'
    },
    cancelQuote: {
        id: 'custom.gateway.controllers.NewQuote.Cancel Quote',
        defaultMessage: 'Cancel Quote?'
    },
    cancelMessage: {
        id: 'custom.gateway.controllers.NewQuote.Are you sure you want to cancel the new quote?',
        defaultMessage: 'Are you sure you want to cancel the new quote?'
    },
    noProducerCode: {
        id: 'custom.gateway.controllers.NewQuote.No Producer Code',
        defaultMessage: 'The agency field value is not correct'
    },
});
