import { defineMessages } from 'react-intl';

export default defineMessages({
    errorLoading: {
        id: 'custom.gateway.views.quote-documents.page.Error loading documents',
        defaultMessage: 'Error loading documents'
    },
    modalError: {
        id: 'custom.gatewayviews.page.modal.Error',
        defaultMessage: 'Error'
    },
    documentDeletionFailed: {
        id: 'custom.gateway.directives.GatewayDocuments.page.Document deletion failed',
        defaultMessage: 'DocumentDeletionFailed'
    },
    documentCannotDeleted: {
        id: 'custom.gateway.directives.GatewayDocuments.page.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    },
    noQuoteDocuments: {
        id: 'custom.gateway.directives.gateway-documents.page.There are no documents associated with this quote',
        defaultMessage: 'There are no documents associated with this quote',
    },
    warningUploadTitle: {
        id: 'custom.gateway.directives.gateway-documents.page.warningUploadTitle',
        defaultMessage: 'Warning',
    },
    uploadFailedMessageUIC: {
        id: 'custom.gateway.directives.gateway-documents.page.uploadFailedMessageUIC',
        defaultMessage: 'The “Upload Documents” function is temporarily unavailable. In the meantime, please email any documents as attachments to plund@umialik.com and include your account number in the subject line'
    },
    uploadFailedMessage: {
        id: 'custom.gateway.directives.gateway-documents.page.uploadFailedMessage',
        defaultMessage: 'The “Upload Documents” function is temporarily unavailable. In the meantime, please email any documents as attachments to plund@wnins.com and include your account number in the subject line'
    }
});
