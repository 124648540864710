import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useProductsData } from 'wni-portals-util-react';
import metadata from './ExistingAccountMatchPage.metadata.json5';
import ExistingAccountMatchTable from './ExistingAccountMatchTable';
import currentMessages from './ExistingAccountMatchPage.messages';


function ExistingAccountPage(props) {
    const {
        isVisible = true,
        accountType,
        matchData = {},
        onStartNewQuote,
        onCancelButton: onPreviousButton,
        onContinueCreate,
        /* sidebyside page
        *  isSXS
        * onConfirmMerge
        */
        isSXS = false,
        onConfirmMerge,
        //
        useDependenciesData,
        productsData: propProductsData,
    } = props;

    const productsData = propProductsData|| useProductsData();
    const { matchAccounts = [], poMatchAccounts = [] } = matchData;
    const translator = useTranslator();
    const { workflowType } = useDependenciesData || useDependencies('workflowType');

    const resolvers = {
        resolveCallbackMap: {
            onPreviousButton,
            onContinueCreate
        },
        resolveComponentMap:{
            existingaccountmatchtable: ExistingAccountMatchTable
        }
    };

    //
    const matchedSubMsgContent = isSXS ? translator(currentMessages.continiueToPayAndIssue) : translator(currentMessages.matchingMsg);
    const isClassicalWorkflow = workflowType.ClassicalWorkflow;
    const override = {
        cancelCreateNewAccount: {
            visible: !isClassicalWorkflow && !isSXS
        },
        continueNewBtn: {
            visible: !isSXS
        },
        matchedTable: {
            isSXS,
            onConfirmMerge,
            onStartNewQuote,
            data:  matchAccounts,
            visible:!_.isEmpty(matchAccounts),
            isClassicalWorkflow,
            accountType,
            //
            productsData,
        },
        potentialTable: {
            isSXS,
            onConfirmMerge,
            onStartNewQuote,
            data: poMatchAccounts,
            isClassicalWorkflow,
            accountType,
            //
            productsData,
        },
        matchedSubTitle: {
            content: !_.isEmpty(matchAccounts) ? translator(currentMessages.matchingTitle) : translator(currentMessages.noMatchingTitle)
        },
        matchedSubMsg: {
            visible: !_.isEmpty(matchAccounts),
            content: matchedSubMsgContent,
        },
        potentialSubMsg: {
            content: !_.isEmpty(matchAccounts) ? translator(currentMessages.potentialTitle) : translator(currentMessages.noMatchingPotentialTitle)
        },
        potentialContainer: {
            visible: !_.isEmpty(poMatchAccounts)
        }
    }
    if (!isVisible) {
        return null;
    }
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={override}
            {...resolvers} />
    );
}

ExistingAccountPage.propTypes = {
    matchData: PropTypes.shape({
        matchAccounts: PropTypes.arrayOf(PropTypes.shape({})),
        poMatchAccounts: PropTypes.arrayOf(PropTypes.shape({}))
    })
};
ExistingAccountPage.defaultPropTypes = {
    matchData: {}
};

export default ExistingAccountPage;