import { defineMessages } from 'react-intl';

export default defineMessages({
    selectVehicle: {
        id: 'pe.fnol.pa.directives.vehicle page.Select a Vehicle',
        defaultMessage: 'Select a Vehicle'
    },
    year: {
        id: 'pe.fnol.pa.directives.vehicle page.Year',
        defaultMessage: 'Year'
    },
    make: {
        id: 'pe.fnol.pa.directives.vehicle page.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'pe.fnol.pa.directives.vehicle page.Model',
        defaultMessage: 'Model'
    },
    vin: {
        id: 'pe.fnol.pa.directives.vehicle page.VIN Number',
        defaultMessage: 'VIN Number'
    },
    location: {
        id: 'pe.fnol.pa.directives.vehicle page.Location',
        defaultMessage: 'Location'
    },
    removeIncidentTitle: {
        id: 'pe.fnol.pa.directives.vehicle page.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeIncident: {
        id: 'pe.fnol.pa.directives.vehicle page.Are you sure you want to delete this record?',
        defaultMessage: 'Are you sure you want to delete this record?'
    },
    vehicleInvolved: {
        id: 'pe.fnol.pa.directives.vehicle page.Any other {pageTypeName} involved?',
        defaultMessage: 'Any other {pageTypeName} involved?'
    },
    insuredButtonText: {
        id: 'pe.fnol.pa.directives.vehicle page.Add Another {pageTypeName}',
        defaultMessage: 'Add Another {pageTypeName}'
    },
    insuredButtonTip: {
        id: 'pe.fnol.pa.directives.vehicle page.If the vehicle you were driving is not found above, please click button Add Another {pageTypeName}',
        defaultMessage: 'If the {pageType} you were driving is not found above, please click button \'Add Another {pageTypeName}\''
    },
    thirdButtonText: {
        id: 'pe.fnol.pa.directives.vehicle page.Add Thrid Party {pageTypeName}',
        defaultMessage: 'Add Thrid Party {pageTypeName}'
    },
    thirdButtonTip: {
        id: 'pe.fnol.pa.directives.vehicle page.If third party involved, please click button Add Thrid Party pageTypeName}',
        defaultMessage: 'If third party involved, please click button \'Add Thrid Party {pageTypeName}\''
    },
    itemToBeInsured: {
        id: 'pe.fnol.pa.directives.vehicle page.Item to be Insured',
        defaultMessage: 'Item to be Insured'
    },
    WatercraftInvolvedType: {
        id: 'pe.fnol.pa.directives.vehicle page.Watercraft/Unit Involved Type',
        defaultMessage: 'Watercraft/Unit Involved Type'
    },
    invalidSelectionTitle: {
        id: 'pe.fnol.pa.directives.vehicle page.Invalid Selection',
        defaultMessage: 'Invalid Selection'
    },
    invalidSelectionText: {
        id: 'pe.fnol.pa.directives.vehicle page.Sorry, you cannont select the same person as both the driver and passenger for this car. \n\n Please choose different individuals for the driver and passenger roles before saving.',
        defaultMessage: 'Sorry, you cannont select the same person as both the driver and passenger for this car. \n\n Please choose different individuals for the driver and passenger roles before saving.'
    }

});
