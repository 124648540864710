
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';


import { CRRiskAnalysisReadOnlyPage } from 'wni-capability-quoteandbind-cr-react';

function CRRiskAnalysisChangeReadOnlyPage(props) {

    return (
        <CRRiskAnalysisReadOnlyPage {...props} />
    );

}

CRRiskAnalysisChangeReadOnlyPage.propTypes = CRRiskAnalysisReadOnlyPage.propTypes;
CRRiskAnalysisChangeReadOnlyPage.defaultProps = CRRiskAnalysisReadOnlyPage.defaultProps;

export default CRRiskAnalysisChangeReadOnlyPage;