import React from 'react';
import _ from 'lodash';

import {
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
} from 'wni-portals-util-js';

import { QuoteCommonMessages as customMessages } from 'wni-platform-translations';
import { Button } from '@jutro/legacy/components';

const ISSUE_TYPE_TO_TITLE_MAP = {
    info: customMessages.reviewWarningMessages,
    warning: customMessages.reviewWarningMessages,
    error: customMessages.submitTheApplication,
};


const ERROR_TYPE = 'error';
const RESOLVE_ERROR_STYLE = 'd-flex flex-direction-row-reverse mt-10';

// Old name: DomRenderUtil

/**
 * @param {Object} issue {type: '', reason: '', children: [] } || children is not required.
 */

const issueRenderFn = (issue, {
    getListKeyFn,
    getSortKeyForIssueWithSameType,
}) => {
    const { reason, children = [] } = issue;
    if (_.isEmpty(children)) {
        return reason;
    }
    let sortedChildren = children;
    if (getSortKeyForIssueWithSameType) {
        sortedChildren = _.sortBy(sortedChildren, getSortKeyForIssueWithSameType);
    }
    return (
        <>
            <span>{reason}</span>
            <ul>
                {
                    sortedChildren.map((item) => {
                        const itemListKey = getListKeyFn(item);
                        return (<li key={itemListKey}>{item.reason}</li>);
                    })
                }
            </ul>
        </>
    );
};

function getQuotePageIssueRenderFn(issue, optConfigs) {
    const {
        issueKey,
        listKey,
        issueJumpFnMap,
        defaultIssueRenderFn,
        getIssueKeyFn,
        getListKeyFn,
        getSortKeyForIssueWithSameType,
    } = optConfigs;
    const { type, reason, children = [] } = issue;
    if (type === 'error') {
        return issueRenderFn(issue, optConfigs);
    }
    // else for "info" and "warning"
    let retval = null;
    if (_.isEmpty(children)) {
        retval = defaultIssueRenderFn(issue, {
            issueKey,
            listKey,
            issueJumpFnMap,
        });
    } else {
        let sortedChildren = children;
        if (getSortKeyForIssueWithSameType) {
            sortedChildren = _.sortBy(sortedChildren, getSortKeyForIssueWithSameType);
        }
        retval = (
            <>
                <span>{reason}</span>
                <ul>
                    {
                        sortedChildren.map((item) => {
                            const originalItem = { ...item, reason: item.originalReason };
                            const currentIssueKey = getIssueKeyFn(originalItem);
                            const currentListKey = getListKeyFn(item);

                            const listContent = defaultIssueRenderFn(item, {
                                issueKey: currentIssueKey,
                                listKey: currentListKey,
                                issueJumpFnMap,
                            });

                            return (
                                <li key={currentListKey}>
                                    {listContent}
                                </li>
                            );
                        })
                    }
                </ul>
            </>
        );
    }
    return retval;
}

/**
 * Get errors and warnings title
 * @param {function} translator
 * @param {function} issueToTitleFn (Optional)
 * @returns {function} string the title for specific issue type
 */
function getQuotePageErrorsAndWarningsTitle(translator, issueToTitleFn) {
    const issueToTitleGetter = (issueType) => {
        let typeTitle;
        if (_.isFunction(issueToTitleFn)) {
            typeTitle = issueToTitleFn(issueType);
        }
        if (!typeTitle) {
            typeTitle = ISSUE_TYPE_TO_TITLE_MAP[issueType];
        }
        return typeTitle || customMessages.reviewWarningMessages;
    };

    return (type, issues) => {
        const title = issueToTitleGetter(type);

        // return translator(title, {item: issues.length > 1 ? 'items': 'item'});
        return translator(title);
    };
}


function getQuotePageErrorsAndWarningsFooter({
    wizardSteps, wizardStepToFieldMapping,
    wizardJumpTo, resolveButtonLabel,
    isServiceCallInProgress,
    //
    buttonStyle = RESOLVE_ERROR_STYLE,
}) {
    return (type, issues) => {
        if (type === ERROR_TYPE) {
            //
            const originalIssues = ValidationIssueUtil.flattenIssueChildren(issues);
            const jumpFnToEarliestPage = ErrorsAndWarningsUtil.getJumpFnToEarliestPageWithIssue(
                originalIssues, wizardSteps, wizardStepToFieldMapping, wizardJumpTo
            );
            if (!jumpFnToEarliestPage) {
                return null;
            }
            // className: ph-10
            return (
                <div className={buttonStyle}>
                    <Button
                        id="issueJumpBtn"
                        onClick={jumpFnToEarliestPage}
                        disabled={isServiceCallInProgress}
                    >
                        {resolveButtonLabel}
                    </Button>
                </div>
            );
        }
        return null;
    };
}

function renderResolveButton({
    validationIssues,
    //
    wizardSteps, wizardStepToFieldMapping,
    wizardJumpTo, resolveButtonLabel,
    isServiceCallInProgress,
}) {
    if (!validationIssues) {
        return null;
    }
    
    const errorIssues = validationIssues.filter((issue) => ERROR_TYPE === issue.type);

    if(_.isEmpty(errorIssues)) {
        return null;
    }
    const footerFormatter = getQuotePageErrorsAndWarningsFooter({
        wizardSteps, wizardStepToFieldMapping,
        wizardJumpTo, resolveButtonLabel,
        isServiceCallInProgress,
        //
        buttonStyle: '',
    });

    const retval = footerFormatter(ERROR_TYPE, errorIssues);
    return retval;
}


export default {
    issueRenderFn,
    //
    getQuotePageIssueRenderFn,
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    //
    renderResolveButton,
};
