
import React, {
    useEffect, useContext,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import {
    Redirect,
    useLocation,
    useParams,
    withRouter,
    useHistory
} from 'react-router-dom';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { WizardPageJumpUtil } from 'wni-portals-util-js';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { PolicyChangeCommonMessages as commonChangeMsg } from 'wni-platform-translations';
import WithdrawTransactionComponent from '../../components/WithdrawTransaction/WithdrawTransactionComponent';

const WizardPageHeaderExtensionComponent = (props) => {
    const history = useHistory();
    const wizardContext = useContext(WizardContext);

    const {
        jobNumber,
        // btnContent,
        // confirmMessage,
        // messageWithJobNumber,
        // onWithdrawn,
    } = props;

    const {
        turnOffWizardPromptMessage,
    } = wizardContext;

    const beforeWithdraw = useCallback(() => {
        // NO need to show leave Prompt message when the PolicyChange is deleted
        turnOffWizardPromptMessage();
    }, []);

    const onWithdrawn = useCallback(() => {
        WizardPageJumpUtil.goToPoliciesPage(history);
    }, []);

    useEffect(() => {
    }, []);

    if (jobNumber) {
        return (
            <WithdrawTransactionComponent
                jobNumber={jobNumber}
                btnContent={commonChangeMsg.deletePolicyChange}
                confirmMessage={commonChangeMsg.sureDeletePolicyChange}
                messageWithJobNumber
                beforeWithdraw={beforeWithdraw}
                onWithdrawn={onWithdrawn}
            />
        );
    }
    return null;
};

WizardPageHeaderExtensionComponent.propTypes = {
    jobNumber: PropTypes.string,
    // ...WithdrawTransactionComponent.propTypes,
};

WizardPageHeaderExtensionComponent.defaultProps = {
    jobNumber: undefined,
    // ...WithdrawTransactionComponent.defaultProps,
};

export default WizardPageHeaderExtensionComponent;
