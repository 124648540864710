import { defineMessages } from 'react-intl';

export default defineMessages({
    noteCreated: {
        id: 'gateway.directives.activity-schedule.Note Created',
        defaultMessage: 'Note Created'
    },
    successCreated: { 
        id: 'gateway.directives.activity-schedule.Note successfully created', 
        defaultMessage: 'You have successfully created a note.' 
    },
    ok: {
        id: 'gateway.directives.activity-schedule.OK', 
        defaultMessage: 'OK' 
    }
});
