import defaultMessage from 'gw-capability-gateway-react/Components/UnderwritingComponent/UnderwritingComponent.messages';
import messages from './UnderwritingComponent.messages';

const uwMessages = { ...defaultMessage, ...messages };
function getTranslateJobType(type) {
    switch (type) {
        case 'PolicyChange':
            return 'gateway.views.job-uwissues.endorsement';
        case 'Cancellation':
            return 'gateway.views.job-uwissues.cancellation';
        case 'Renewal':
            return 'gateway.views.job-uwissues.renewal';

        default:
        case 'Submission':
            return 'gateway.views.job-uwissues.quote';
    }
}

function getTranslateWithdrawn(type, isAgent) {
    if (type === 'Renewal' && !isAgent) {
        return {
            link: uwMessages.cancelQuote,
            message: uwMessages.reasonCancellation
        };
    }

    return {
        link: uwMessages.withDrawJOB,
        message: uwMessages.reasonContinue
    };
}

function getReferredToUnderwriterMessage(type) {
    if (type === 'Renewal') {
        return uwMessages.stillWithdrawRenewal;
    }
    return uwMessages.reviewCompleteInfo;
}

export default function (job, userType) {
    const isAgent = userType === 'producer' || false;
    const uwIssues = job ? job.underwritingIssues || [] : [];

    return {
        get uwIssues() {
            return uwIssues;
        },

        get hasUwIssues() {
            return uwIssues.length > 0;
        },

        get offerings() {
            return uwIssues[0] && uwIssues[0].offerings;
        },

        get uwIssuesAreBlocking() {
            return uwIssues.some((issue) => issue.onOfferings && issue.onOfferings.some((offering) => offering.currentBlockingPoint && !['NonBlocking', 'Approved'].includes(offering.currentBlockingPoint)))
                || uwIssues.some((issue) => !issue.onOfferings && !['NonBlocking', 'Approved'].includes(issue.currentBlockingPoint));
        },

        get translateJobType() {
            if (job) {
                return getTranslateJobType(job.type);
            }
            return undefined;
        },

        get jobType() {
            if (job) {
                return job.type;
            }
            return undefined;
        },

        get uwIssuesHaveBeenInspectedByUW() {
            return uwIssues.some((issue) => issue.onOfferings
                && issue.onOfferings.some((offering) => offering.hasApprovalOrRejection));
        },

        get uwIssuesHaveBeenRejectedByUW() {
            return uwIssues.some((issue) => issue.onOfferings && issue.onOfferings.some((offering) => offering.rejected_Ext))
                || uwIssues.some((issue) => issue.rejected_Ext);
        },

        get translateWithdrawn() {
            if (job) {
                return getTranslateWithdrawn(job.type, isAgent);
            }
            return undefined;
        },

        get getReferredToUnderwriterMessage() {
            if (job) {
                return getReferredToUnderwriterMessage(job.type);
            }
            return undefined;
        },

        get isLockedToUser() {
            if (job) {
                return job.uwlocked && job.isEditLocked;
            }
            return undefined;
        }
    };
}
