import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';

import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';

import styles from './AdditionalCoveragesPage.module.scss';
import metadata from './AdditionalCoveragesPage.metadata.json5';
import './AdditionalCoveragesPage.messages';

const coveragePath = 'lobData.businessOwners.coverages.additionalCoverages';

function AdditionalCoveragesPage(props) {
    const [loadingClause, updateLoadingClause] = useState();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [textFilter, updateTextFilter] = useState('');
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('AdditionalCoveragesPage');

    const updateClauses = useCallback(
        async (clausesToUpdate) => {
            const { quoteID, jobID, sessionUUID } = submissionVM.value;
            const newSubmissionVM = viewModelService.clone(submissionVM);

            // Replace last word with value
            const baseCoveragePath = coveragePath.replace(/\.\w+$/, '.value');
            const lobName = ClausesUtil.getLobNameFromPath(coveragePath);

            const dataForServer = {
                [lobName]:
                    _.get(clausesToUpdate, baseCoveragePath)
                    || _.get(newSubmissionVM, baseCoveragePath)
            };

            const response = await LoadSaveService.updateCoverages(
                quoteID || jobID,
                sessionUUID,
                dataForServer,
                authHeader
            );

            _.set(
                newSubmissionVM,
                baseCoveragePath,
                response.businessOwners || response.lobData.businessOwners.coverages
            );

            const clauseIDsToRemove = ClausesUtil.getRemovedClausesID(
                submissionVM,
                newSubmissionVM,
                coveragePath
            );
            updateWizardData(newSubmissionVM);
            disregardFieldValidation(clauseIDsToRemove);

            return newSubmissionVM;
        },
        [
            LoadSaveService,
            authHeader,
            disregardFieldValidation,
            submissionVM,
            updateWizardData,
            viewModelService
        ]
    );

    const getRealPath = useCallback(
        (filteredPath, filteredCoverages) => {
            const indexRegex = /^([^0-9]*)([0-9]+)(.*)$/;
            const [, prefix, index, suffix] = filteredPath.match(indexRegex);

            if (_.isEmpty(textFilter)) {
                return filteredPath;
            }

            const filteredID = _.get(filteredCoverages, `[${index}].publicID`);
            const realIndex = _.get(submissionVM, `${coveragePath}.value`).findIndex(
                (cov) => cov.publicID === filteredID
            );

            return `${prefix}${realIndex}${suffix}`;
        },
        [submissionVM, textFilter]
    );

    const filterCoverages = useCallback(() => {
        const coverages = _.get(submissionVM, `${coveragePath}.value`);

        if (_.isEmpty(textFilter)) {
            return coverages;
        }

        const filteredCoverages = coverages.filter((coverage) => {
            const coverageName = coverage.name.toLowerCase();
            const textToFilter = textFilter.toLowerCase();

            return coverageName.includes(textToFilter);
        });

        return filteredCoverages;
    }, [submissionVM, textFilter]);

    const onClauseUpdate = useCallback(async () => {
        const { quoteID, jobID, sessionUUID } = submissionVM.value;

        const lobName = ClausesUtil.getLobNameFromPath(coveragePath);
        const clauseType = ClausesUtil.getLastSectionOfPath(coveragePath);

        const clauses = _.get(submissionVM, `${coveragePath}.value`);
        // As the clause is set to edited
        // ClausesUtil.filterUnchangedClauses will pick it up in structureClausesForServer
        const clausesToUpdate = ClausesUtil.structureClausesForServer(clauses, lobName, clauseType);

        const response = await LoadSaveService.updateCoverages(
            quoteID || jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        );
        const updatedClauses = _.get(response, `${lobName}.${clauseType}`)
            || _.get(response.lobData, `${lobName}.coverages.${clauseType}`);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, coveragePath, updatedClauses);

        const clauseIDsToRemove = ClausesUtil.getRemovedClausesID(
            submissionVM,
            newSubmissionVM,
            coveragePath
        );

        disregardFieldValidation(clauseIDsToRemove);
        updateLoadingClause(undefined);
        updateWizardData(newSubmissionVM);
        return newSubmissionVM;
    }, [
        LoadSaveService,
        authHeader,
        disregardFieldValidation,
        submissionVM,
        updateWizardData,
        viewModelService
    ]);

    const syncCoverages = useCallback(
        async (value, changedPath) => {
            const realPath = getRealPath(changedPath, filterCoverages());
            const basePath = ClausesUtil.getObjectPathFromChangedPath(realPath);
            const baseObject = _.get(submissionVM, basePath);

            const clauses = _.get(submissionVM, `${coveragePath}.value`);

            if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
                updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
                const newSubmissionData = await onClauseUpdate();
                updateWizardData(newSubmissionData);
            }
        },
        [getRealPath, filterCoverages, submissionVM, onClauseUpdate, updateWizardData]
    );

    const changeSubmission = useCallback(
        (value, pathToValue) => {
            if (pathToValue.includes(coveragePath)) {
                const realPath = getRealPath(pathToValue, filterCoverages());
                submissionVM.value = ClausesUtil.setClauseValue(
                    submissionVM,
                    value,
                    realPath
                ).value;
            } else {
                _.set(submissionVM, pathToValue, value);
            }
            updateWizardData(submissionVM);
        },
        [filterCoverages, getRealPath, submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncCoverages(value, changedPath);
        },
        [changeSubmission, syncCoverages]
    );

    const onFilterUpdate = useCallback(updateTextFilter, [updateTextFilter]);

    const readValue = useCallback(
        (id, path) => {
            if (id === 'coverageFilter') {
                return textFilter;
            }
            if (id === 'additionalCoverages') {
                return filterCoverages();
            }
            const node = _.get(submissionVM, path);
            return (
                _.get(node, 'answer') || _.get(node, 'value.code') || _.get(node, 'value') || node
            );
        },
        [filterCoverages, submissionVM, textFilter]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate: onValidate,
            onFilterUpdate
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        additionalCoverages: {
            loadingClause: loadingClause
        }
    };

    const onNext = useCallback(updateClauses, [updateClauses]);

    const shouldPageSkip = useCallback(() => {
        const coverages = _.get(
            submissionVM.value,
            'lobData.businessOwners.coverages.additionalCoverages'
        );
        const jobType = _.get(submissionVM.value, 'baseData.jobType');
        if (jobType === 'Submission') {
            return true;
        }
        const status = _.get(submissionVM.value, 'status');
        return status === 'Quoted' || (coverages && !_.find(coverages, { isValid: false }));
    }, [submissionVM]);

    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
        registerInitialComponentValidation(shouldPageSkip);
    }, [
        isPageLoading,
        registerComponentValidation,
        registerInitialComponentValidation,
        shouldPageSkip
    ]);

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={resolvers.resolveValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

AdditionalCoveragesPage.propTypes = wizardProps;
export default AdditionalCoveragesPage;
