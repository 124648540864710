import _ from 'lodash';
import WizardPageDataKeys from '../registries/WizardPageDataKeyRegistry';
import WizardStepDataKeys from '../registries/WizardStepDataKeyRegistry';

/**
 * A place to track keys used in updateWizardPageData.
 * For the full list, see WizardPageDataKeyRegistry
 *
 * Sample Content:
 * accountClearanceData: 'WPDK_accountClearanceData',
 * paymentViewType: 'WPDK_paymentViewType',
 * sideBySideData: 'WPDK_sideBySideData',
 * isUwLocked: 'WPDK_isUwLocked',
 * quoteErrorsAndWarnings: 'WPDK_quoteErrorsAndWarnings',
 * copySubmission: 'WPDK_copySubmission',
 * landingPage: 'WPDK_landingPage',
 * policyDiffData: 'WPDK_policyDiffData',
 * paymentPlans: 'WPDK_paymentPlans',
 * paymentMethods: 'WPDK_paymentMethods',
 * dueDateExt: 'WPDK_dueDateExt',
 * driverCoverages: 'WPDK_driverCoverages',
 * lastExitWizardPage: 'WPDK_lastExitWizardPage',
 * yearForMakeOptions: 'WPDK_yearForMakeOptions',
 * yearMakeForModelOptions: 'WPDK_yearMakeForModelOptions',
 * yearMakeModelForBodyTypeOptions: 'WPDK_yearMakeModelForBodyTypeOptions',
 * productSelected: 'WPDK_productSelected',
 * isNewAccount: 'WPDK_isNewAccount'
 *
 * Note: No longer need to use WizardConstnats.CacheKey to access value of
 * wizardPageData. Instead, use wizardPageData.WPDK_cacheKey should also work.
 *
 * Sample usage:
 * wizardPageData[WizardConstants.cacheKey]
 * wizardPageData.WPDK_cacheKey
 *
 */
// { cacheKey: WPDK_cacheKey, cacheKey2: WPDK_cacheKey2 }
const WizardPageDataConstants = WizardPageDataKeys;

// [WPDK_cacheKey, WPDK_cacheKey2, WPDK_cacheKey3]
const WizardPageDataCacheKeys = Object.values(WizardPageDataConstants);


/**
 * Checks whether the <em>cacheKey</em> is valid for WizardPageData
 * @param {string} cacheKey
 * @returns {boolean}
 */
function isWizardPageDataKey(cacheKey) {
    if (!cacheKey) {
        return false;
    }
    return WizardPageDataCacheKeys.includes(cacheKey);
}

// =========================================
const WizardStepDataConstants = WizardStepDataKeys;

/**
 * Sample Input: 
 * step: {'id': 'CPPDummyPage'}
 * pageId: 'CPPSamplePage', or ['CPPDummyPage', 'CPPSamplePage']
 * @param {object} step step object as defined in *-wizard-config.json
 * @param {string | array} pageId pageId string or array
 */
function isWizardStep(step, pageIdStrOrArray) {
    const {id: stepId} = step;
    let retval = false;
    if (_.isArray(pageIdStrOrArray)) {
        retval = pageIdStrOrArray.find((pageId) => stepId === pageId);
    } else {
        retval = stepId === pageIdStrOrArray;
    }

    return retval;
}

function isWizardStepParent(step, parentIdStrOrArray) {
    const stepParentId = _.get(step, WizardStepDataConstants.parentId);
    let retval = false;
    if (_.isArray(parentIdStrOrArray)) {
        retval = parentIdStrOrArray.find((parentId) => stepParentId === parentId);
    } else {
        retval = stepParentId === parentIdStrOrArray;
    }

    return retval;
}

// Another Option: export StepConsntats into another variable
// export const WizardStepsConstants = {
//     ...WizardStepDataConstants,
//     isWizardStep,
// };

const WizardConstants = {
    ...WizardPageDataConstants,
    isWizardPageDataKey,
    //
    ...WizardStepDataConstants,
    isWizardStep,
    // isWizardStepParent, // to be open for public usage
};

export default WizardConstants;
