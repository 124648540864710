import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { PEFNOLPALossDetailPage } from 'wni-capability-fnol-pa-react';

function FNOLWTLossDetailPage(props) {
    return <PEFNOLPALossDetailPage {...props}/>;
} 

FNOLWTLossDetailPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
};

FNOLWTLossDetailPage.propTypes = wizardProps;
export default withRouter(FNOLWTLossDetailPage);
