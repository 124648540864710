import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';
import { IMCoverageSelectionUtil } from 'wni-capability-quoteandbind-im-react';
import { WniProductsUtil } from 'wni-portals-util-js';

const FIRST_CHANGE_WIZARD_PAGE = 'IMPolicyDetailsPage';

const PAGE_ID_NAME_MAP = {
    'IMPolicyDetailsPage': 'Policy Details',
    'IMCoveragePartSelectionPage': 'Coverage Part Selection',
    'IMBuildingsAndLocationsPage': 'Buildings And Locations',
    'IMCommonFormPage': 'Common Forms',
    'IMBaileePartPage': 'Bailee Customers Floater Coverage - Dry Cleaners and Laundry',
    'IMBoatPartPage': 'Boat Coverage',
    'IMBuilderRiskPartPage': 'Builders\' Risk',
    'IMContractorsEquipPartPage': 'Contractors\' Equipment',
    'IMElectronicDataProcessingPartPage': 'Electronic Data Processing',
    'IMFineArtsFloaterPartPage': 'Fine Arts Floater',
    'IMInstallationFloaterPartPage': 'Installation Floater',
    'IMMiscFormsPartPage': 'Misc Forms',
    'IMMiscFloatersPartPage': 'Miscellaneous Floaters',
    'IMMotorTruckCargoPartPage': 'Motor Truck Cargo Legal Liability Coverage',
    'IMMuseumsPartPage': 'Museum Collection Coverage',
    'IMRiggersPartPage': 'Riggers\' Coverage',
    'IMTransitPartPage': 'Transit',
    'IMWarehouseLegalLiabilityPartPage': 'Warehouse Legal Liability',
};

const { 
    IM_PRODUCT_CODE,
    getLobName
} = WniProductsUtil;

const LOB_NAME = getLobName(IM_PRODUCT_CODE);

const {
    getIMSkipPages
} = IMCoverageSelectionUtil;

function getChangePageJumpList({ policyChangeSource, getPageJumpAction, submissionVM }) {
    let retval = [];
    if (policyChangeSource === 'cancellation') {
        retval = [{
            name: 'Mailing Address',
            action: getPageJumpAction('IMPolicyDetailsPage'),
        }];
    } else {
        const initIMData =  _.get(submissionVM, `lobData.${LOB_NAME}.coveragePartSelection.value`, {});
        const skipSteps = getIMSkipPages(IM_PRODUCT_CODE, initIMData);
        for (const [key, value] of Object.entries(PAGE_ID_NAME_MAP)) {
            const isVisible = _.find(skipSteps, (step) => step[WizardConstants.stepId] === key && step[WizardConstants.stepVisible]) || 
                !_.find(skipSteps, (step) => step[WizardConstants.stepId] === key);
            if (isVisible) {
                retval.push({
                    name: value,
                    action: getPageJumpAction(key)
                });
            }
        }
    }
    return retval;
};

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};