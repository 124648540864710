import _ from 'lodash';
import { R1Config } from 'wni-portals-config-js';

const {
    QuotePageConfig: {
        recommendCoverages,
        recommendCovLinkTexts,
    },
    PACoverageConfig,
} = R1Config;


const recommendCovPatternCodes = _.keys(recommendCoverages);

const PIPCoveragesCodes = [
    'PAPIP_Stack_MN', // PAPIPMNME_Ext
    'PAPIPNonStack_MN', // Personal Injury Protection Not Stacked
    'PAPIPMNME_Ext' // Medical Expense / Other Than Medical Expense    Personal Injury Protection
    // 'PAPIPMNWL_Ext',     // PIP - MN - WorkLoss, hidden
    // 'PAPIPMNES_Ext',     // PIP-MN-Essential Services, hidden
    // 'PAPIPMNFE_Ext',     // PIP-MN-Funeral Expense, hidden
    // 'PAPIPMNSL_Ext',     // PIP-MN-Survivors Loss, hidden
];


const sxsDriverCoverageCodes = [
    'PADeathDisabilityCov',
    'PADeathDisabilityCov_Ext',
];

function belongsToLiabilityTable(coverageDto) {
    const {
        coverageCategoryCode: patternCategory,
        code_Ext: patternCode
    } = coverageDto;
    const isLiabCov = patternCategory === 'PAPLiabGrp' && patternCode !== 'PAAcdntForCov_Ext';
    const retval = isLiabCov
                || PIPCoveragesCodes.includes(patternCode)
                || sxsDriverCoverageCodes.includes(patternCode);
    return retval;
}

/**
 * Get table row data from CoverageDTO
 * @param {object} coverage CoverageDTO
 * @returns {obejct}
 */
function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID,
        codeIdentifier: coverage.code_Ext,
    };
}

/**
 * Checks whether the specific coverage should display on SxS page
 *
 * Note: to be replaced by filterLineCovOnQuotePage() and filterVehCovOnQuotePage()
 * @param {object} coverageDto intance of edge.capabilities.policycommon.coverage.dto.CoverageDTO
 * @param {array} covIssues
 * @param {string} vehicleFixedId
 * @returns {boolean}
 */
function filterCovOnQuotePage(coverageDto, covIssues = [],
    vehicleFixedId = undefined) {
    const {
        code_Ext: clausePatternCode,
        selected
    } = coverageDto;

    // ====Criteria 1: include those coverages that have been selected;
    let retval = selected;
    // ====Criteria 2: if the coverage is not selected, check whether it is
    // is listed in the Recommended Upgrade coverage list
    if (!(
        retval
        // Recommended DriverCoverage is handled by
        // RecommendCoveragesComponent.goToDriverCoveragesPage()
        || PACoverageConfig.isDriverCoveragePatternCode(clausePatternCode)
        || _.isEmpty(covIssues)
    )) {
        const covIssue = covIssues.find((issue) => {
            const {
                coveragePatternCode: issueClausePatternCode,
                vehicleFixedId: issueVehicleFixedId,
            } = issue;
            let covMatch = issueClausePatternCode === clausePatternCode;

            if (covMatch && !_.isNil(vehicleFixedId)) {
                const vehicleMatch = issueVehicleFixedId === vehicleFixedId;
                covMatch = covMatch && vehicleMatch;
            }
            return covMatch;
        });
        retval = !_.isNil(covIssue);
    }
    // ====Criteria 3: Remove driver coveragese from PAQuotePage exception those
    // listed in sxsDriverCoverageCodes
    if (
        retval
        && PACoverageConfig.isDriverCoveragePatternCode(clausePatternCode)
        && !sxsDriverCoverageCodes.includes(clausePatternCode)
    ) {
        retval = false;
    }
    return retval;
}

/**
 * Get a map of period publicID to an array of coverage issues.
 * @param {object} sxsDataDTO
 * @param {string} lob
 * @returns {object} period.publicID to array of CoverageValidationIssueDTO
 */
function getPeriodToCovIssuesMap(sxsDataDTO, lob) {
    const periods = _.get(sxsDataDTO, `${lob}.periods`);
    const retval = {};
    if (_.isEmpty(periods)) {
        return retval;
    }
    periods.forEach((sxsPeriod) => {
        const {
            publicID,
            coverageIssues = []
        } = sxsPeriod;
        retval[publicID] = coverageIssues;
    });
    return retval;
}

/**
 * Checks whether the specific clausePatternCode is possibile recommended
 * @param {string} clausePatternCode
 * @returns {boolean}
 */
function isRecommendedCov(clausePatternCode) {
    const retval = recommendCovPatternCodes.find((rc) => rc === clausePatternCode);
    return !_.isNil(retval);
}

/**
 * Checks whether recommend coverages are involved
 * @param {string} lob
 * @param {stirng} lobCoveragesDto
 * @returns {boolean}
 */
function isRecommendedCoveragesInvolved(lob, lobCoveragesDto = []) {
    let retval = false;
    const {
        lineCoverages = [],
        vehicleCoverages = [],
    } = lobCoveragesDto[lob];
    const lineCovPatternCodes = lineCoverages.map((cov) => cov.code_Ext);
    retval = lineCovPatternCodes.some(isRecommendedCov);

    if (!retval) {
        const vehCovPatternCodes = _.uniq(vehicleCoverages.flatMap((vehCov) => {
            const { coverages = [] } = vehCov;
            return coverages.map((cov) => cov.code_Ext);
        }));
        retval = vehCovPatternCodes.some(isRecommendedCov);
    }
    return retval;
}

/**
 * Iterate the recommendedCovLinkTexts and try to find
 * the coverage recommended
 * @param {string} covIssueMessage
 * @returns {string}
 */
function findCovLinkText(covIssueMessage = '') {
    const covLinkText = recommendCovLinkTexts.find((covText) => covIssueMessage.indexOf(covText) > 0);
    return covLinkText;
}

/**
 * Filter VehiceCoverageSets based on columnIndex
 *
 * Sample INPUT:
  * [
 *     {
 *         "vehicleFixedId": 3602,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 1500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 10000.0
 *                 }
 *             ],
 *             "coverageLabel": "Custom Equipment (Included)"
 *         }
 *     },
 *     {
 *         "vehicleFixedId": 3602,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 2500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 0.0
 *                 }
 *             ],
 *             "coverageLabel": "Additional Custom Equipment"
 *         }
 *     },
 *     {
 *         "vehicleFixedId": 3606,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 1500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 10000.0
 *                 }
 *             ],
 *             "coverageLabel": "Custom Equipment (Included)"
 *         }
 *     },
 *     {
 *         "vehicleFixedId": 3606,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 3500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 0.0
 *                 }
 *             ],
 *             "coverageLabel": "Additional Custom Equipment"
 *         }
 *     }
 * ]
 *
 * To filter out content for columnIndex 0, the result looks like this:
 *
 * Sample OUTPUT:
 * [
 *     {
 *         "vehicleFixedId": 3602,
 *         "customEquipTerms": [
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 1500.0,
 *                "coverageLabel": "Custom Equipment (Included)"
 *             },
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 2500.0,
 *                "coverageLabel": "Additional Custom Equipment"
 *             },
 *         ]
 *     },
 *     {
 *         "vehicleFixedId": 3606,
 *         "customEquipTerms": [
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 1500.0,
 *                "coverageLabel": "Custom Equipment (Included)"
 *             },
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 3500.0,
 *                "coverageLabel": "Additional Custom Equipment"
 *             },
 *         ]
 *     }
 * ]
 * @param {array} vehicleCoverageSets an array of SideBySideVehicleSetDTO
 * @param {number} columnIndex
 * @returns {array} simplified array with form of [{ vehicleFixedId,
 *  customEquipRows: { customEquipmentEndorsementCovDisplayValue_Ext, coverageLabel }
 * }]
 */
function filterVehicleCoverageSets(vehicleCoverageSets, columnIndex) {
    const retval = [];
    if (_.isEmpty(vehicleCoverageSets)) {
        return retval;
    }
    //
    const vehicleIdCovRowMap = {};
    vehicleCoverageSets.forEach((vehicleCovSet) => {
        const {
            vehicleFixedId,
            customEquipRows: {
                covTermInfos,
                coverageLabel,
            }
        } = vehicleCovSet;

        if (!vehicleIdCovRowMap[vehicleFixedId]) {
            vehicleIdCovRowMap[vehicleFixedId] = [];
        }
        const storedCovRow = vehicleIdCovRowMap[vehicleFixedId];
        const currentCovTermInfo = covTermInfos.find((covTermInfo) => {
            return covTermInfo.columnIndex === columnIndex;
        });
        if (currentCovTermInfo) {
            const { customEquipmentEndorsementCovDisplayValue_Ext: customEquipmentEndorsementCovDisplayValue } = currentCovTermInfo;
            storedCovRow.push({
                coverageLabel,
                customEquipmentEndorsementCovDisplayValue,
            });
        }
    });

    if (!_.isEmpty(vehicleIdCovRowMap)) {
        Object.entries(vehicleIdCovRowMap).forEach(([vehicleFixedId, customEquipTerms]) => {
            retval.push({
                vehicleFixedId,
                customEquipTerms,
            });
        });
    }

    return retval;
}

/**
 * Sample Input:
 * [
 *     {
 *         "vehicleFixedId": 3602,
 *         "customEquipTerms": [
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 1500.0,
 *                "coverageLabel": "Custom Equipment (Included)"
 *             },
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 2500.0,
 *                "coverageLabel": "Additional Custom Equipment"
 *             },
 *         ]
 *     },
 *     {
 *         "vehicleFixedId": 3606,
 *         "customEquipTerms": [
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 1500.0,
 *                "coverageLabel": "Custom Equipment (Included)"
 *             },
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 3500.0,
 *                "coverageLabel": "Additional Custom Equipment"
 *             },
 *         ]
 *     }
 * ]
 *
 * Sample Output:
 *
 * [
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 1500.0,
 *                "coverageLabel": "Custom Equipment (Included)"
 *             },
 *             {
 *                "customEquipmentEndorsementCovDisplayValue": 2500.0,
 *                "coverageLabel": "Additional Custom Equipment"
 *             },
 *         ]
 * @param {array} vehicleCoverageSets
 * @param {number} vehicleFixedId
 * @returns {array}
 */
function getVehicleCustomEquipTerms(vehicleCoverageSets = [], vehicleFixedId) {
    // if (_.isEmpty(vehicleCoverageSets)) {
    //     return [];
    // }
    const retval = vehicleCoverageSets.find((covSet) => covSet.vehicleFixedId === `${vehicleFixedId}`);
    if (!retval) {
        return [];
    }
    return retval.customEquipTerms || [];
}

/**
 * Filter VehiceCoverageSets based on columnIndex
 *
 * Sample INPUT:
  * [
 *     {
 *         "vehicleFixedId": 3602,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 1500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 10000.0
 *                 }
 *             ],
 *             "coverageLabel": "Custom Equipment (Included)"
 *         }
 *     },
 *     {
 *         "vehicleFixedId": 3602,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 2500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 0.0
 *                 }
 *             ],
 *             "coverageLabel": "Additional Custom Equipment"
 *         }
 *     },
 *     {
 *         "vehicleFixedId": 3606,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 1500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 10000.0
 *                 }
 *             ],
 *             "coverageLabel": "Custom Equipment (Included)"
 *         }
 *     },
 *     {
 *         "vehicleFixedId": 3606,
 *         "customEquipRows": {
 *             "covTermInfos": [
 *                 {
 *                     "columnIndex": 0,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 3500.0
 *                 },
 *                 {
 *                     "columnIndex": 1,
 *                     "customEquipmentEndorsementCovDisplayValue_Ext": 0.0
 *                 }
 *             ],
 *             "coverageLabel": "Additional Custom Equipment"
 *         }
 *     }
 * ]
 *
 * To filter out content for columnIndex 0, the result looks like this:
 *
 * Sample OUTPUT:
 * [3602, 3606]
 * @param {array} vehicleCoverageSets an array of SideBySideVehicleSetDTO
 * @returns {array} array of vehicle fixedIds
 */
function getVehicleFixedIdsFromCoverageSets(vehicleCoverageSets = []) {
    if (_.isEmpty(vehicleCoverageSets)) {
        return [];
    }
    const fixedIds = vehicleCoverageSets.map((covSet) => covSet.vehicleFixedId);
    const retval = _.uniq(fixedIds);
    return retval;
}


/**
 * Check whether current clause/coverage is selected
 * @param {array} clausePublicID
 * @param {string} coverageDTOList an array of CoverageDTO, e.g. LineCoverages or VehicleCoverages
 * @returns {bool}
 */
function isClauseSelected(clausePublicID, coverageDTOList) {
    if (_.isEmpty(clausePublicID) || _.isEmpty(coverageDTOList)) {
        return false;
    }
    const clauseDTO = coverageDTOList.find((clause) => clause.publicID === clausePublicID);

    if (clauseDTO) {
        return clauseDTO.selected;
    }
    return false;
}


// ==============Lob: Personal Auto=======================================
/**
 * Get structured vehicle coverages with provided PaOfferingDTO list and vehicleFixedId
 * @param {array} paOfferingDTOArray list of PAOfferingDTO
 * @param {number} vehicleFixedId vehile fixedID
 * @param {function} vehCovFilter (Optional) (VehicleCoveragDTO, periodPublicID) => boolean
 * @returns {array} structured vehicle coverage list fed as table row to QuoteClauseTable
 */
function getStructuredVehicleCoverages(paOfferingDTOArray, vehicleFixedId,
    vehCovFilter = undefined) {
    const retval = _.uniqBy(paOfferingDTOArray.flatMap((offering) => {
        const {
            publicID_Ext: periodPublicID,
            coverages: {
                vehicleCoverages,
            },
        } = offering;

        const currentVehCoverages = vehicleCoverages.flatMap(({ fixedId, coverages }) => {
            if (fixedId !== vehicleFixedId) {
                return [];
            }
            let currentVehCoveragesRetVal = coverages;
            if (_.isFunction(vehCovFilter)) {
                currentVehCoveragesRetVal = coverages.filter((cov) => vehCovFilter(cov, periodPublicID));
            }
            return currentVehCoveragesRetVal;
        });
        return currentVehCoverages.map(structureClauseTableData);
    }), 'publicID');

    return retval;
}

export default {
    structureClauseTableData,
    belongsToLiabilityTable,
    filterCovOnQuotePage,
    // filterLineCovOnQuotePage,
    getPeriodToCovIssuesMap,
    // getCovJumpFn,
    isRecommendedCoveragesInvolved,
    findCovLinkText,
    filterVehicleCoverageSets,
    getVehicleCustomEquipTerms,
    getVehicleFixedIdsFromCoverageSets,
    isClauseSelected,
    getStructuredVehicleCoverages,
};
