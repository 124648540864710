import React, {
    useCallback,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMPolicyDetailsChangeService } from 'wni-capability-policychange-im';
import {
    IMWizardPage as WizardPage
} from 'wni-capability-quoteandbind-im-react';

import metadata from './IMPolicyDetailsChangePage.metadata.json5';

function IMPolicyDetailsChangeReadOnlyPage(props) {

    const {
        wizardData: submissionVM,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        onValidate,
    } = useValidation('IMPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const getMinDate = useCallback(() => {
        return systemDate
    }, [systemDate]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                isReadOnly: true,
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: getMinDate(),
                showErrors
            },
        
            
        }
    }, [getMinDate, showErrors]);

    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: {

        },
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

IMPolicyDetailsChangeReadOnlyPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
};

IMPolicyDetailsChangeReadOnlyPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: IMPolicyDetailsChangeService.saveIMPolicyDetailsData,
} 
export default IMPolicyDetailsChangeReadOnlyPage;