import React from 'react';
import { InlineLoader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import styles from './BuildingsAndLocationsTemplate.module.scss';
import WizardStepCircles from '../wizardStepCirlces/WizardStepCircles';

import { Button } from '@jutro/legacy/components';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function BuildingLocationPageTemplate(props) {
    const translator = useTranslator();
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel
    } = props;

    const isPerformingTransiton = isLoadingNext;

    const isNextDisabled = disableNext || isPerformingTransiton;

    return (
        <div>
            <WizardStepCircles />
            {content}
            <div className={styles.wizardButtons}>
                {showCancel && (
                    <Button
                        id="gw-wizard-cancel"
                        disabled={disableCancel || isPerformingTransiton}
                        type="text"
                        onClick={onCancel}
                    >
                        {translator(cancelLabel)}
                        {renderLoader(isLoadingCancel)}
                    </Button>
                )}
                {showNext && (
                    <Button id="gw-wizard-Next" disabled={isNextDisabled} onClick={onNext}>
                        {translator(nextLabel)}
                        {renderLoader(isLoadingNext)}
                    </Button>
                )}
            </div>
        </div>
    );
}

BuildingLocationPageTemplate.propTypes = {
    ...pageTemplateProps
};

export default BuildingLocationPageTemplate;
