
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { useTranslator, IntlContext } from '@jutro/locale'; 
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteCoveragesUtil, WniClausesUtil, WindowUtil } from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { BaseRecommendCoveragesComponent } from 'wni-capability-gateway-react';

import { Link } from '@jutro/router';


function HORecommendCoveragesComponent(props) {

    const {
        goToCoveragesPage,
    } = props;

    const getCovJumpFn = useCallback((idPrefix, coveragePublicID) => {
        // See SingleClauseComponentVM.generateMetadata()
        return () => {
            // Strange: cannot jump to checkbox, but can jump to <td>, <div>, etc.
            // const covId = WniClausesUtil.getClauseContainerId(idPrefix, coveragePublicID);
    
            // // ================tmp solution
            // const clauseDivEle = document.getElementById(covId);
            // if (clauseDivEle) {
            //     WindowUtil.scrollTo(covId, { focus: true });
            // } else {
            //     // goToDriverCoveragesPage();
            // }
            goToCoveragesPage();
        };
    }, [goToCoveragesPage]);


    return (
        <BaseRecommendCoveragesComponent
            {...props}
            getCovJumpFn={getCovJumpFn}
        />
    );
}

HORecommendCoveragesComponent.defaultProps = {
    goToCoveragesPage: PropTypes.func.isRequired,
    //
    ...BaseRecommendCoveragesComponent.defaultProps,
}

HORecommendCoveragesComponent.defaultProps = {
    ...BaseRecommendCoveragesComponent.defaultProps,
}

export default HORecommendCoveragesComponent;
