import React from 'react';
import { WALPolicyDetailsReadOnlyPage } from 'wni-capability-quoteandbind-wal-react';

function RTPolicyDetailsReadOnlyPage(props) {

    return (
        <WALPolicyDetailsReadOnlyPage 
            {...props} 
            linePath = 'roadTrail'
        />
    );

}

RTPolicyDetailsReadOnlyPage.propTypes = WALPolicyDetailsReadOnlyPage.propTypes;
RTPolicyDetailsReadOnlyPage.defaultProps = WALPolicyDetailsReadOnlyPage.defaultProps;

export default RTPolicyDetailsReadOnlyPage;