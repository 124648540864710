import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'), method, data, additionalHeaders);
}

export default class WniDriverService {
    static addDriver(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'addDriver', [data.quoteID, data.sessionUUID, data.driver], additionalHeaders);
    }

    static delDrivers(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'removeDrivers', [data.quoteID, data.sessionUUID, data.drivers], additionalHeaders);
    }

    static removeADPDrivers(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'removeADPDrivers', [data.quoteID, data.sessionUUID, data.drivers], additionalHeaders);
    }

    static updateDriver(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'updateDriver', [data.quoteID, data.sessionUUID, data.driver], additionalHeaders);
    }

    static getCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'getCoverages', [data.quoteID, data.sessionUUID, data.driver], additionalHeaders);
    }

    static getDriversCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'getDriversCoverages', [data.quoteID, data.sessionUUID], additionalHeaders);
    }

    static updateCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'updateCoverage', [data.quoteID, data.sessionUUID, data.driverDTO, data.cov, data.scheduleItem], additionalHeaders);
    }

    static updateAllCoverages(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'updateAllCoverages', [data.quoteID, data.sessionUUID, data.driverDTO, data.cov, data.scheduleItem], additionalHeaders);
    }

    static unselectUnavailableExtendedCoverage(requestData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'unselectUnavalibleExtendedCoverage', [requestData.quoteID, requestData.sessionUUID], additionalHeaders);
    }

    static addDriverForPrimaryNamedInsured(requestData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'addDriverForPrimaryNamedInsured', [requestData.quoteID, requestData.sessionUUID], additionalHeaders);
    }

    static getErrorsAndWarnings(quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'getErrorsAndWarnings', [quoteID], additionalHeaders);
    }

    static getCoveragesErrorsAndWarnings(quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quote/wnidriver'),
            'getCoveragesErrorsAndWarnings', [quoteID], additionalHeaders);
    }

    /**
     * Invokes WniDriverHandler.updateCreditReport()
     * @param {String} quoteID
     * @param {String} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateCreditReport(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('updateCreditReport', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes WniDriverHandler.triggerInsuranceScoreService()
     * Credit report/Insurance score services
     * @param {String} quoteID 
     * @param {String} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static triggerInsuranceScoreService(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('triggerInsuranceScoreService', [quoteID, sessionUUID], authHeader);
    }
}
