import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
    useMemo,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { useTranslator, IntlContext } from '@jutro/locale';
import { PortalConstants } from 'wni-portals-config-js';
import { RTCoveragesService } from 'wni-capability-quoteandbind-rt';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import WizardPage from '../../templates/RTWizardPage';
import RTCoverageUtil from './util/RTCoverageUtil';

import RTLineCoverages from './CoveragesSection/RTLineCoverages/RTLineCoverages';
import RTVehiclesCoverages from './CoveragesSection/RTVehiclesCoverages/RTVehiclesCoverages';
import RTOperatorsCoverages from './CoveragesSection/RTOperatorsCoverages/RTOperatorsCoverages';

import styles from './RTCoveragesPage.module.scss';
import metadata from './RTCoveragesPage.metadata.json5';
import messages from './RTCoveragesPage.messages';
import CoveragesConfigContext from './context/RTCoveragesConfigContext';
import RTCoveragesConfig from './RTCoverages.config';

function RTCoveragesPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
            baseState_Ext: baseState,
            policyContactRoles_Ext: policyContactRoles
        },
        lobData: {
            roadTrail: {
                offerings,
                coverables: {
                    vehicles,
                }
            }
        },
    } = submissionVM.value;

    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
    } = useValidation('RTCoveragesPage');


    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, setIsEditing] = useState(false)

    const [validationIssues, updateValidationIssues] = useState([])
    const [openedAccordions, setOpendedAccordions] = useState([
        'policyLevelCoverages',
        'vehicleLevelCoverages',
        'operatorLevelCoverages'
    ]);
    const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    const lobName = 'roadTrail'

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const ANIAndPNIpolicyContactRoles = policyContactRoles
        .filter((policyContactRole) => policyContactRole.contactRole === "Named Insured")

    const hasSameContact = (policyContactRoleA, policyContactRoleB) => {
        const contactA = _.get(policyContactRoleA, 'contact')
        const contactB = _.get(policyContactRoleB, 'contact')
        return _.get(contactA, 'publicID') === _.get(contactB, 'publicID')
    }
    const policyContactRolesHasOpertorCov = policyContactRoles
        .filter((policyContactRole) => {
            const relationship = _.get(policyContactRole, 'relationshipToInsured_Ext')
            const isSpouseOrPartner = ['Spouse_Ext', 'Partner_Ext'].includes(relationship);
            const isANIOrPNI = ANIAndPNIpolicyContactRoles
                .some((ANIPNIPolicyContactRole) => hasSameContact(ANIPNIPolicyContactRole, policyContactRole))
            return isANIOrPNI || isSpouseOrPartner
        })

    const policyContactRolesSortedByPublicID = _.sortBy(policyContactRolesHasOpertorCov, 'publicID_Ext')
    const policyContactRolesHasOperatorCovUniqueByContact = _.uniqBy(policyContactRolesSortedByPublicID, 'contact.publicID')

    const showOperaterLevelCoverages = baseState === 'SD'
        && policyContactRolesHasOperatorCovUniqueByContact.length > 0;

    const {
        coverages: {
            vehiclesCoverages
        }
    } = selectedVersion

    const rtVehiclePublicIDsWithInvalidCoverage = useMemo(
        () => vehiclesCoverages.filter((rtVehicleCovDTO) => {
            const coveragesPerfWatercraft = _.get(rtVehicleCovDTO, 'coverages', [])
            return coveragesPerfWatercraft.some((cov) => RTCoverageUtil.isCoverageInvalid(cov))
        }).map((rtVehicleCovDTO) => rtVehicleCovDTO.owningCoverablePublicID),
        [vehiclesCoverages])

    const errMsgsInvalidRTVehicle = useMemo(
        () => showErrors ? rtVehiclePublicIDsWithInvalidCoverage.map((watercraftPublicID) => {
            const rtVehicle = vehicles.find((v) => v.publicID === watercraftPublicID)
            const vehicleDesc = RTCoverageUtil.getVehicleDescription(rtVehicle)
            return {
                type: 'error',
                reason: translator(messages.rtVehicleCoverageInvalidField, {
                    vehicleDesc
                })
            }
        }) : [], [translator, rtVehiclePublicIDsWithInvalidCoverage, vehicles, showErrors])

    const pageLevelValidation = useMemo(
        () => validationIssues.concat(errMsgsInvalidRTVehicle),
        [errMsgsInvalidRTVehicle, validationIssues]
    )
    const isWatercraftsCoveragesValid = useCallback(() => {
        return rtVehiclePublicIDsWithInvalidCoverage.length === 0;
    }, [rtVehiclePublicIDsWithInvalidCoverage]);

    useEffect(() => {
        registerComponentValidation(isWatercraftsCoveragesValid);
    }, [isWatercraftsCoveragesValid, registerComponentValidation]);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const onPageNext = async () => {
        // Call update coverages service to save coverages not sync and check errors and warnings
        const coverages = _.get(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = RTCoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = RTCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName);
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await RTCoveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        setLoadingLineCoverages(false);
        const lobCoverages = _.get(response, lobName);
        const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
        _.set(submissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        // _.set(newSubmissionVM.value, 'errorsAndWarnings', errorsAndWarnings);
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        return newSubmissionVM;
    }

    const handleValidation = () => {
        setShowErrors(true)
        return false
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            rtLineCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                onValidate,
                showErrors,
                updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                isEditing,
                setIsEditing,
                lobName,
                coveragesService: RTCoveragesService,
                loadingLineCoverages,
                setLoadingLineCoverages,
            },
            rtVehiclesCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                showErrors,
                updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                lobName,
                coveragesService: RTCoveragesService,
            },
            operatorLevelCoverages: {
                visible: showOperaterLevelCoverages,
            },
            rtOperatorsCoverages: {
                selectedVersionIndex,
                submissionVM,
                updateWizardData: updateWizardDataToDraft,
                showErrors,
                updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
                lobName,
                coveragesService: RTCoveragesService,
                policyContactRolesHasOperatorCovUniqueByContact,
                // loadingLineCoverages,
                // setLoadingLineCoverages,
                setIsEditing,
                onValidate,
            }
        };
    }, [
        isEditing,
        lobName,
        onValidate,
        selectedVersion,
        selectedVersionIndex,
        showErrors,
        submissionVM,
        updateErrorsAndWarningsForCovComponent,
        updateWizardDataToDraft,
        openedAccordions,
        loadingLineCoverages,
        setLoadingLineCoverages,
        showOperaterLevelCoverages,
        policyContactRolesHasOperatorCovUniqueByContact,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            RTLineCoverages: RTLineCoverages,
            RTVehiclesCoverages: RTVehiclesCoverages,
            RTOperatorsCoverages: RTOperatorsCoverages,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={isEditing}
            disablePrevious={isEditing}
            disableCancel={isEditing}
            onNext={isComponentValid ? onPageNext : handleValidation}
            alwaysCallOnNext
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            <CoveragesConfigContext.Provider value={RTCoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    // onModelChange={updateFormData}
                    // onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </CoveragesConfigContext.Provider>
            
        </WizardPage>
    );
}

RTCoveragesPage.propTypes = WizardPage.propTypes;
export default RTCoveragesPage;
