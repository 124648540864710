import { defineMessages } from 'react-intl';

export default defineMessages({
    LocationCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-locationPage.LocationClauses.LocationCoverages',
        defaultMessage: 'Location Coverages',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-locationPage.LocationClauses.AdditionalCoverages',
        defaultMessage: 'Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-locationPage.LocationClauses.ExclusionsAndConditions',
        defaultMessage: 'Exclusions & Conditions',
    },
    AddCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-locationPage.LocationClauses.Add Coverages',
        defaultMessage: 'Add Coverages',
    },
    AddExclusionsAndConditions: {
        "id": "wni.quoteandbind.cp-wizard.cp-locationPage.LocationClauses.AddExclusionAndConditions",
        "defaultMessage": "Add Exclusions and Conditions"
    },
});