import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';

import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import BuildingsAndLocationsTemplate from '../../templates/BuildingsAndLocationsTemplate';
import EditLocation from '../../WizardFileConfig';

const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';

function LocationsPage(props) {
    const { wizardData, updateWizardData } = props;
    const { submissionVM, selectedLocation } = wizardData;
    const { authHeader } = useAuthentication();
    const [locationSubmission, updateLocationSubmission] = useState({});
    const { isComponentValid, onValidate } = useValidation('LocationsPage');

    const addLocation = useCallback(
        async (location) => {
            const quoteId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
            const sessionId = _.get(submissionVM.value, 'sessionUUID');
            _.set(selectedLocation, 'locationType', 'newLocation');
            const locationsList = _.get(submissionVM.value, LOCATION_PATH);

            const addOrUpdateLocation = _.get(location.value, 'publicID')
                ? CPCoverablesService.updateCPLocation
                : CPCoverablesService.addCPLocation;

            const response = await addOrUpdateLocation(
                quoteId,
                location.value,
                sessionId,
                authHeader
            );

            locationsList.push(response);
            _.set(submissionVM, LOCATION_PATH, locationsList);
            _.set(selectedLocation, 'location', response);

            return wizardData;
        },
        [submissionVM, authHeader, selectedLocation, wizardData]
    );

    const updateSelectedLocation = useCallback(
        (value, path) => {
            _.set(wizardData, `selectedLocation.${path}`, value);
            updateWizardData(wizardData);
        },
        [updateWizardData, wizardData]
    );

    const firstExistingLocation = useMemo(() => {
        const location = _.get(submissionVM.value, LOCATION_PATH);
        return _.first(location);
    }, [submissionVM.value]);

    const handleNext = useCallback(async () => {
        const { locationType, location } = selectedLocation;
        if (locationType === 'newLocation') {
            await addLocation(locationSubmission);
        } else if (locationType === 'existingLocation' && _.isEmpty(location)) {
            updateSelectedLocation(firstExistingLocation, 'location');
        }

        return Promise.resolve(wizardData);
    }, [
        selectedLocation,
        wizardData,
        addLocation,
        locationSubmission,
        updateSelectedLocation,
        firstExistingLocation
    ]);

    const setLocationSubmission = useCallback(
        (submission, location) => {
            updateLocationSubmission(submission);
            const locationDetails = location === 'newLocation' ? submission.value : submission;
            _.set(selectedLocation, 'location', locationDetails);
            updateWizardData(wizardData);
        },
        [selectedLocation, updateWizardData, wizardData]
    );

    return (
        <WizardPage
            onNext={handleNext}
            disableNext={!isComponentValid}
            template={BuildingsAndLocationsTemplate}
            showCancel
        >
            <EditLocation
                setLocationSubmission={setLocationSubmission}
                updateSelectedLocation={updateSelectedLocation}
                selectedLocationType={selectedLocation.locationType}
                wizardData={wizardData}
                onValidate={onValidate}
            />
        </WizardPage>
    );
}

LocationsPage.propTypes = wizardProps;
export default LocationsPage;
