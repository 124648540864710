import React, { useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CPPolicyDetailsPage.metadata.json5';

function CPPolicyDetailsPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { isComponentValid, initialValidation, onValidate } = useValidation(
        'CPPolicyDetailsPage'
    );

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [submissionVM, LoadSaveService, authHeader]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        coverageStartDate: {
            minDate: LocalDateUtil.today()
        }
    };

    return (
        <WizardPage
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CPPolicyDetailsPage.propTypes = wizardProps;
export default CPPolicyDetailsPage;
