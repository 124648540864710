import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import { GLLocationService } from 'wni-capability-quoteandbind-gl';
import metadata from './GLLocationDetails.metadata.json5';
import GLLocationDetailsFieldMap from './GLLocationDetailsFieldMap';
import { CheckboxField } from '@jutro/legacy/components';

const RESET_LOCATIONS_PATH_MAPS = ['address', 'country', 'city', 'county', 'state'];
const RESET_LOCATIONS_PATH_MAPS_ONVALUECHANGE = ['address', 'state']

function GLLocationDetails(props) {
    const {
        locationVM,
        onValidate,
        onValueChange,
        getLocationClauses,
        syncWizardDataSnapshot,
        showErrors,
        isReadOnly,
        externalData: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    // useEffect(() => {
    //     if(!isReadOnly) {
    //         _.set(locationVM.value, 'address.country', defaultCountryCode)
    //     }
    // }, []);
    const updateLocationService = async(requestData) => {
        let locationData;
        try{
            setLoadingMask(true);
            const res = await GLLocationService.postOnChangeAction(jobID, sessionUUID, requestData, authHeader);
            locationData = res.gllocation;
            await getLocationClauses(_.get(locationData, 'publicID'));
            setLoadingMask(false);
        }catch(e){                                
            locationData = requestData
        }
        const newLocationVM = viewModelService.clone(locationVM);
        _.set(newLocationVM, 'value', locationData);
        syncWizardDataSnapshot(newLocationVM);
    }

    const isAddressValid = () => {
        return _.get(locationVM, 'address.aspects.valid') && _.get(locationVM, 'address.aspects.subtreeValid')
    }

    const onBlur = async(e, { beforeValue, model, value}) => {
        const isValid = isAddressValid();
        if(value === beforeValue || !isValid) {
            return false;
        }
        if(RESET_LOCATIONS_PATH_MAPS.includes(model)) {
            const requestData = {
                ...locationVM.value,
                isResetLocation: true
            }
            await updateLocationService(requestData);
        }
        
    };

    const onSelectionChange = async(val, path, item) => {
        const newValue = {
            ...item,
            [path]: val
        };

        const allSublines = _.get(locationVM.value, 'locationSubline', []);
        const currentIndex = allSublines.findIndex((v) => v.subline === item.subline); 
        const newLocationVM = viewModelService.clone(locationVM);

        _.set(newLocationVM.value, `locationSubline[${currentIndex}]`, newValue);
        await updateLocationService(newLocationVM.value);
    };

    const onAddressChange = async(value, path) => {
        onValueChange(value, path);
        const isValid = isAddressValid();
        if(isValid && RESET_LOCATIONS_PATH_MAPS_ONVALUECHANGE.includes(path)){
            const requestData = {
                ...locationVM.value,
                [path]: value,
                isResetLocation: true
            }
            await updateLocationService(requestData); 
        }
    }

    const renderCheckbox = (item, index, { path}) => {
        return <CheckboxField value={item[path]} readOnly={isReadOnly} onValueChange={(value) => onSelectionChange(value, path, item)} />
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        addressLookup: {
            model: locationVM,
            dataPath: 'address',
            onAddressChange: onAddressChange,
            onBlur,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true
            },
            showErrors,
            onValidate,
            readOnly: isReadOnly,
            defaultCountryRequired: false
        },
        fieldSetModel: {
            vm: locationVM,
            dataPath: 'displayables',
            onValidate,
            showErrors,
            isReadOnly,
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, locationVM, fieldId, fieldPath, overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            renderCheckbox
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent,
            fieldsetmap: GLLocationDetailsFieldMap
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={locationVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={onValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default GLLocationDetails;
