import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '@jutro/components';
import { InputField, RadioButtonField } from '@jutro/legacy/components';
import { useTranslator } from '@jutro/locale';
import { DataTable,DisplayColumn } from '@jutro/legacy/datatable';
import { WniDataTable } from 'wni-common-base-components';
import { Flex, FlexItem } from '@jutro/layout';
import messages from './IMSearchAndAddClausesPopup.messages';

function IMSearchAndAddClausesPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        serviceProps,
        setLoadingMask,
        clauseType,
        coverableService,
        handleAfterUpdateClauses
    } = props;

    const {
        jobID,
        sessionUUID,
        authHeader
    } = serviceProps;

    const translator = useTranslator();
    const [searchKeyword, updateSearchKeyword] = useState(null);
    const [searchedClausesData, updateSearchedClausesData] = useState([]);
    const [exclusionConditionType, updateExclusionConditionType] = useState('exclusionsAndConditions');
    const [selections, updateSelections] = useState([]);

    const availableTypeValues = [{id: 'conditions', displayName: 'Condition'}, {id: 'exclusions', displayName: 'Exclusion'}, {id: 'exclusionsAndConditions', displayName: 'Exclusion and Condition'}];

    const serchCoverages = useCallback(async() => {
        setLoadingMask(true)
        if (_.isObject(coverableService)) {
            let searchResults;
            if (clauseType === 'exclusionsAndConditions') {
                searchResults = await coverableService.searchClauses(jobID, sessionUUID, searchKeyword, exclusionConditionType, authHeader);
            } else {
                searchResults = await coverableService.searchClauses(jobID, sessionUUID, searchKeyword, clauseType, authHeader);
            }
            
            if (!_.isEmpty(searchResults)) {
                updateSearchedClausesData(searchResults);
            } else {
                updateSearchedClausesData([]);
            }
        }
        
        setLoadingMask(false);
    }, [setLoadingMask, coverableService, clauseType, jobID, sessionUUID, searchKeyword, exclusionConditionType, authHeader])

    const serchAllCoverages = useCallback(async() => {
        updateSearchKeyword(null);
        if (_.isObject(coverableService)) {
            setLoadingMask(true);
            const searchResults = await coverableService.searchClauses(jobID, sessionUUID, null, clauseType, authHeader);
            setLoadingMask(false);
            if (!_.isEmpty(searchResults)) {
                updateSearchedClausesData(searchResults);
            } else {
                updateSearchedClausesData([]);
            }
        }
    }, [authHeader, clauseType, jobID, coverableService, sessionUUID, setLoadingMask])
    const addSelectedCoverage = useCallback(async()=>{
        if (_.isObject(coverableService)) {
            setLoadingMask(true);
            const clauseSpecData = searchedClausesData?.filter(cov => selections.includes(searchedClausesData.indexOf(cov)));
            const res = await coverableService.addSearchedClauses(jobID, sessionUUID, clauseSpecData, authHeader);
            if (_.isFunction(handleAfterUpdateClauses)) {
                handleAfterUpdateClauses(res)
            }
            setLoadingMask(false);
            return res;
        }
    }, [coverableService, setLoadingMask, searchedClausesData, jobID, sessionUUID, authHeader, handleAfterUpdateClauses, selections])

    const resetChoice = () => {
        updateSearchKeyword(null);
        updateSearchedClausesData([]);
    }

    useEffect(() => {
        serchAllCoverages();
    }, []);

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={messages[`${clauseType}Title`] || messages.defaultTitle} />
            <ModalBody id="coverageContactSearchComponent" autoFocus={false}>
                <InputField
                    id="coverage_keyword"
                    label="Keyword"
                    labelPosition="left"
                    onValueChange={(value) => updateSearchKeyword(value)}
                    value={searchKeyword}
                />
                <RadioButtonField
                    id="coverage_type"
                    label="Type"
                    labelPosition="left"
                    availableValues={availableTypeValues}
                    showRequired
                    required
                    onValueChange={(value) => updateExclusionConditionType(value)}
                    visible={clauseType === 'exclusionsAndConditions'}
                    value={exclusionConditionType}
                />
                <Flex justifyContent="left" className="mb-10">
                    <Button onClick={serchCoverages} variant="primary" label="Search" />
                    <Button onClick={resetChoice} variant="secondary"  label="Reset" />
                    <Button onClick={serchAllCoverages} variant="primary" label={messages[`${clauseType}Button`] || messages.displayAllConditionsAndExclusionsButton} />
                </Flex>
                <WniDataTable
                    columnsConfigurable={false}
                    data={searchedClausesData}
                    defaultConfig={{
                        // filterValue: '',
                        // page: 0,
                        pageSize: 5
                    }}
                    pageSizeOptions={[5]}
                    id="basic"
                    showPagination
                    showSearch={false}
                    selectionType="multi"
                    onSelectionChange={(indexs) => {
                        updateSelections(indexs)
                    }}
                    >
                    <DisplayColumn
                        header={messages[`${clauseType}Header`]}
                        id="coverage"
                        path="patternDisplayName"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                    />
            </WniDataTable>
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "right",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject()}} variant="secondary" label="Cancel" />
                <Button onClick={()=>{onResolve(addSelectedCoverage())}} disabled={_.isEmpty(selections)} label={messages[`${clauseType}AddButton`] || messages.addButton} />
            </ModalFooter>
        </ModalNext>
    );
}
IMSearchAndAddClausesPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    coverableService: PropTypes.object
};

IMSearchAndAddClausesPopup.defaultProps = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
};

export default IMSearchAndAddClausesPopup;
