import { defineMessages } from 'react-intl';

export default defineMessages({
    PropertyIncidentsQuestion: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.question',
        defaultMessage: "Was someone else's property damaged other than a vehicle?"
    },
    Yes: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.No',
        defaultMessage: "No"
    },
    Number: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.No.',
        defaultMessage: "No."
    },
    PropertyLocation: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.PropertyLocation',
        defaultMessage: "Property Location"
    },
    Edit: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.Edit',
        defaultMessage: "Edit"
    },
    Delete: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.Delete',
        defaultMessage: "Delete"
    },
    AddProperty: {
        id: 'wni.fnol.ho.views.incidentdetails.propertyIncidents.AddProperty',
        defaultMessage: "Add Property"
    }
})