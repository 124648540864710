
import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import messages from './CustomFooterV2Component.messages';
import styles from './CustomFooterV2Component.module.scss';
import { Button } from '@jutro/legacy/components';

/**
 * Custom Fotter Component for driver and vehicle screen
 *
 * @param {object} props
 * @returns {object} React Component
 */
const CustomFooterV2Component = (props) => {
    const {
        saveAndNextMessage,
        handleCancel,
        handleSaveAndClose,
        handleSaveAndNext,
        nextButtonVisible,
        saveButtonVisible,
        isSaveDisabled
    } = props;
    const translator = useTranslator();

    const getFooterButtons = () => {
        return (
            <Flex justifyContent="between" className="mt-20">
                <Button onClick={handleCancel} type="filled">
                    {translator(messages.cancelLabel)}
                </Button>
                <Flex>
                    {nextButtonVisible ? (
                        <Button onClick={handleSaveAndNext} disabled={isSaveDisabled}>
                            {saveAndNextMessage}
                        </Button>
                    ) : null}
                    {saveButtonVisible ? (
                        <Button onClick={handleSaveAndClose} disabled={isSaveDisabled}>
                            {translator(messages.saveAndColose)}
                        </Button>
                    ) : null}
                </Flex>
            </Flex>
        );
    };

    return getFooterButtons();
};

CustomFooterV2Component.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    showErrors: PropTypes.bool,
    isSaveDisabled: PropTypes.bool,
    saveAndNextMessage: PropTypes.string,
    nextButtonVisible: PropTypes.bool.isRequired,
    saveButtonVisible: PropTypes.bool,
    handleCancel: PropTypes.func.isRequired,
    handleSaveAndNext: PropTypes.func.isRequired
};

CustomFooterV2Component.defaultProps = {
    model: {},
    showErrors: false,
    isSaveDisabled: false,
    saveAndNextMessage: 'Save & Next',
    saveButtonVisible: true
};

export default CustomFooterV2Component;
