import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WindowUtil, WniUrlUtil } from 'wni-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './AccountSearch.messages';
import metadata from './AccountSearch.metadata.json5';
import styles from './AccountSearch.module.scss';


const ACCOUNT_TYPE = {
    commercial: 'company',
    personal: 'person'
};
const AccountSearch = (props) => {
    const modalApi = useWniModal();
    const {
        history,
        location: {
            search,
            state
        },
        match: {
            params
        }
    } = props;

    const { isComponentValid, onValidate: setComponentValidation, invalidFields } = useValidation('AccountSearch');
    // const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { interactionModel } = useDependencies('interactionModel');
    const [submissionVM, updateSubmissionVM] = useState({});
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        const model = {
            contactType: ACCOUNT_TYPE.personal,
            country: 'US'
        };
        const submissionDTO = viewModelService ? viewModelService.create(model,
            'pc', 'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO') : {};
        updateSubmissionVM(submissionDTO);
    }, [viewModelService]);

    const searchAccounts = async () => {
        history.push(`/new-quote-account-search/matches${search}`, submissionVM.value);
    };

    const handleValidation = () => {
        WindowUtil.scrollToInvalidField(invalidFields);
        updateShowErrors(true);
    };

    const writeValue = (value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${path}`, value);
        updateSubmissionVM(newSubmissionVM);
    };

    const handleNewQuoteCancel = () => {
        if (state && !state.prevLocation) {
            history.goBack();
        } else {
            interactionModel.goToPage(null, history, 'homePage');
        }
    };

    const handleCancel = () => {
        modalApi.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.close
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            handleNewQuoteCancel();
        }, _.noop);
    };

    const newCustomerBtnClick = () => {
        const nextLocation = {
            accountSearchCriteria: _.get(submissionVM, 'value'),
            externalData: search ? WniUrlUtil.getURLSearchJson(search) : params
        };
        history.push('/new-quote', nextLocation);
    };

    //----------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        accountTypeToggle: {
            disabled: true,
            value: _.get(submissionVM, 'value.contactType')
        },
        companyDiv: {
            visible: _.get(submissionVM, 'value.contactType') === ACCOUNT_TYPE.commercial
        },
        state: {
            // availableValues: this.availableStatesForR1
        },
        ssn: {
            visible: false,
            path: 'TBD',
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSearchAccounts: isComponentValid ? searchAccounts : handleValidation,
            onCancel: handleCancel,
            newCustomerBtnClick: newCustomerBtnClick
        }

    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
};

AccountSearch.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            prevLocation: PropTypes.object
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

AccountSearch.defaultProps = {
    // model: {},
    // onValueChange: undefined,
    // showErrors: false,
};

export default withRouter(AccountSearch);
