import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CPPolicyChangeStartPage from './pages/Start/CPPolicyChangeStartPage';

import CPPaySuccessChangePage from './pages/PaySuccess/CPPaySuccessChangePage';

import CPDummyChangePage from './pages/Dummy/CPDummyChangePage';
import CPDummyChangeReadOnlyPage from './pages/Dummy/CPDummyChangePage.readonly';

import CPPolicyDetailsChangePage from './pages/PolicyDetails/CPPolicyDetailsChangePage';
import CPPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/CPPolicyDetailsChangePage.readonly';

import CPCommercialPropertyChangePage from './pages/CommercialProperty/CPCommercialPropertyChangePage';
import CPCommercialPropertyChangeReadOnlyPage from './pages/CommercialProperty/CPCommercialPropertyChangePage.readonly';

import CPLocationsChangePage from './pages/Locations/CPLocationsChangePage';
import CPLocationsChangeReadOnlyPage from './pages/Locations/CPLocationsChangePage.readonly';

import CPStateSpecificInformationChangePage from './pages/StateSpecificInformation/CPStateSpecificInformationChangePage';
import CPStateSpecificInformationChangeReadOnlyPage from './pages/StateSpecificInformation/CPStateSpecificInformationChangePage.readonly';

import CPRisksChangePage from './pages/Risks/CPRisksChangePage';
import CPRisksChangeReadOnlyPage from './pages/Risks/CPRisksChangePage.readonly';

import CPBlanketsChangePage from './pages/Blankets/CPBlanketsChangePage';
import CPBlanketsChangeReadOnlyPage from './pages/Blankets/CPBlanketsPage.readonly';

import CPModifiersChangePage from './pages/Modifiers/CPModifiersChangePage';
import CPModifiersChangeReadOnlyPage from './pages/Modifiers/CPModifiersChangePage.readonly';

import CPRiskAnalysisChangePage from './pages/RiskAnalysis/CPRiskAnalysisChangePage';
import CPRiskAnalysisChangeReadOnlyPage from './pages/RiskAnalysis/CPRiskAnalysisChangePage.readonly';


// WIZARD PAGE IMPORT ANCHOR

import CPQuoteChangePage from './pages/Quote/CPQuoteChangePage';
import CPQuoteChangeReadOnlyPage from './pages/Quote/CPQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        CPPolicyChangeStartPage: { component: 'CPPolicyChangeStartPage' },
        CPPaySuccessChangePage: { component: 'CPPaySuccessChangePage' },

        CPDummyChangePage: { component: 'CPDummyChangePage'},
        CPDummyChangeReadOnlyPage: { component: 'CPDummyChangeReadOnlyPage'},

        CPPolicyDetailsChangePage: { component: 'CPPolicyDetailsChangePage'},
        CPPolicyDetailsChangeReadOnlyPage: { component: 'CPPolicyDetailsChangeReadOnlyPage'},

        CPCommercialPropertyChangePage: { component: 'CPCommercialPropertyChangePage'},
        CPCommercialPropertyChangeReadOnlyPage: { component: 'CPCommercialPropertyChangeReadOnlyPage'},

        CPLocationsChangePage: { component: 'CPLocationsChangePage'},
        CPLocationsChangeReadOnlyPage: { component: 'CPLocationsChangeReadOnlyPage'},

        CPStateSpecificInformationChangePage: { component: 'CPStateSpecificInformationChangePage'},
        CPStateSpecificInformationChangeReadOnlyPage: { component: 'CPStateSpecificInformationChangeReadOnlyPage'},

        CPRisksChangePage: { component: 'CPRisksChangePage'},
        CPRisksChangeReadOnlyPage: { component: 'CPRisksChangeReadOnlyPage'},

        CPBlanketsChangePage: { component: 'CPBlanketsChangePage'},
        CPBlanketsChangeReadOnlyPage: {component: 'CPBlanketsChangeReadOnlyPage'},

        CPModifiersChangePage: { component: 'CPModifiersChangePage'},
        CPModifiersChangeReadOnlyPage: { component: 'CPModifiersChangeReadOnlyPage'},

        CPRiskAnalysisChangePage: { component: 'CPRiskAnalysisChangePage'},
        CPRiskAnalysisChangeReadOnlyPage: { component: 'CPRiskAnalysisChangeReadOnlyPage'},


// WIZARD PAGE COMPONENT NAME ANCHOR
        CPQuoteChangePage: { component: 'CPQuoteChangePage' },
        CPQuoteChangeReadOnlyPage: { component: 'CPQuoteChangeReadOnlyPage' },
    },
    {
        CPPolicyChangeStartPage,
        CPPaySuccessChangePage,

        CPDummyChangePage,
        CPDummyChangeReadOnlyPage,

        CPPolicyDetailsChangePage,
        CPPolicyDetailsChangeReadOnlyPage,

        CPCommercialPropertyChangePage,
        CPCommercialPropertyChangeReadOnlyPage,

        CPLocationsChangePage,
        CPLocationsChangeReadOnlyPage,

        CPStateSpecificInformationChangePage,
        CPStateSpecificInformationChangeReadOnlyPage,

        CPRisksChangePage,
        CPRisksChangeReadOnlyPage,

        CPBlanketsChangePage,
        CPBlanketsChangeReadOnlyPage,

        CPModifiersChangePage,
        CPModifiersChangeReadOnlyPage,

        CPRiskAnalysisChangePage,
        CPRiskAnalysisChangeReadOnlyPage,


// WIZARD PAGE COMPONENT MAP ANCHOR
        CPQuoteChangePage,
        CPQuoteChangeReadOnlyPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as CPPolicyChangeWizard } from './CPPolicyChangeWizard';
export { default as CPRisksChangePage } from './pages/Risks/CPRisksChangePage';
