import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import { Loader, ToastProvider } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { useWniModal } from 'wni-components-platform-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { WniProductsUtil } from 'wni-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { UserService, ActivitiesService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from 'gw-capability-gateway-react/Components/OpenActivitiesComponent/OpenActivitiesComponent.messages';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import ActivityTableComponent from '../Activity/ActivityTableComponent/ActivityTableComponent';
import AddActivityPopUp from './AddActivityPopUp';
import metadata from './OpenActivitiesComponent.metadata.json5';
import styles from './OpenActivitiesComponent.module.scss';

const getNoOfDays = (data) => {
    const currentDate = new Date();
    const eDate = new Date(data.dueDate);
    const noOfDays = currentDate - eDate;
    const noOfDueDay = noOfDays / (1000 * 3600 * 24);
    return noOfDueDay;
};

const activitiesOverdueData = (data) => data.filter((activitiesInfo) => {
    return activitiesInfo.status !== 'complete' && getNoOfDays(activitiesInfo) > 0;
});

const activitiesThisWeekData = (data, status) => data.filter((activitiesInfo) => {
    const today = moment();
    const tomorrow = moment().add(1, 'days');
    return (
        !moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
        && !moment(activitiesInfo.dueDate).isSame(today, 'd')
        && activitiesInfo.status === status
        && (getNoOfDays(activitiesInfo) > 0
            ? false
            : Math.floor(getNoOfDays(activitiesInfo) * -1)
            < moment(activitiesInfo.dueDate).isoWeekday())
    );
});

const activitiesTodayData = (data, status) => data.filter((activitiesInfo) => {
    const today = moment();
    return (
        activitiesInfo.dueDate !== undefined
        && activitiesInfo.status === status
        && moment(activitiesInfo.dueDate).isSame(today, 'd')
    );
});

const activitiesTomorrowData = (data, status) => data.filter((activitiesInfo) => {
    const tomorrow = moment().add(1, 'days');
    return (
        activitiesInfo.status === status
        && moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
    );
});

const activitiesFutureData = (data, status) => data.filter((activitiesInfo) => {
    return (
        activitiesInfo.status === status
        && !moment(activitiesInfo.dueDate).isBefore(moment().endOf('isoWeek'), 'd')
    );
});

const getActivitiesOverdue = (activityList) => {
    const today = moment();
    return activityList.filter((activity) => {
        return activity.status !== 'complete' && (_.isUndefined(activity.dueDate) || today.isAfter(activity.dueDate, 'day'));
    });
};

const filterActivitiesByValue = (activityArrayResult, filterValue) => {
    const lowerCaseFilterValue = filterValue.toLocaleLowerCase();
    return _.filter(activityArrayResult, (res) => {
        return Object.keys(res).some(
            (key) => typeof res[key] === 'string'
                && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
        );
    });
};

const filterActivitiesByStatus = (filterValue, statusMap, openActivityData) => {
    let activityList = [];

    switch (filterValue) {
        case statusMap.allOpen:
            activityList = openActivityData.filter((act) => act.status === 'open');
            break;
        case statusMap.allClose:
            activityList = openActivityData.filter(
                (activitiesInfo) => {
                    return activitiesInfo.status === 'complete';
                }
            );
            break;
        case statusMap.overDueOnly:
            activityList = activitiesOverdueData(openActivityData);
            break;
        case statusMap.openAssignedToMe:
            activityList = openActivityData.filter((act) => act.status === 'open'
                && act.isAssignedToCurrentUser);
            break;
        default:
            break;
    }

    return activityList;
};

const splitActivitiesForTable = (activityList) => {
    return {
        activitiesComplete: activityList.filter(
            (activitiesInfo) => {
                return activitiesInfo.status === 'complete';
            }
        ),
        activitiesFuture: activitiesFutureData(activityList, 'open'),
        activitiesOverdue: getActivitiesOverdue(activityList),
        activitiesThisWeek: activitiesThisWeekData(
            activityList,
            'open'
        ),
        activitiesToday: activitiesTodayData(activityList, 'open'),
        activitiesTomorrow: activitiesTomorrowData(
            activityList,
            'open'
        )
    };
};

function OpenActivitiesComponent(props) {
    const modalApi = useWniModal();
    const {
        id: componentId,
        authHeader,
        getActivities,
        activityEntityId,
        activityEntity,
        createNewActivity,
        getPolicyDetails,
        getActivitiesTileUpdatedCount,
        productCode,
        contactType
    } = props;
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const viewModelService = useContext(ViewModelServiceContext);
    const [openActivityResponse, updateOpenActivityResponse] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [activityFilter, setActivityFilter] = useState({
        statusFilter: '',
        searchFilter: ''
    });
    const [selectedUser, updateSelectedUser] = useState([]);
    const [showComponent, updateShowComponent] = useState(false);
    const [displayNoActivities, updateDisplayNoActivities] = useState(false);
    const [addActivitySubmissionVM, updateVMData] = useState({});
    const [newActivityTypeAvailableValues, updateNewActivityTypeAvailableValues] = useState([]);
    const [newActivityPriorityAvailableValues, updateNewActivityPriorityAvailableValues] = useState(
        []
    );
    const [userAvailableValues, updateUserAvailableValues] = useState([]);
    const [activityOptions, updateActivityOptions] = useState();
    const [showActivityButton, updateShowActivityButton] = useState(false);
    const [assignableUserData, updateAssignableUserData] = useState([]);
    const [activityPatternData, updateActivityPatternData] = useState([]);
    const [isAddActivityLoading, setIsAddActivityLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [publicId, setPublicId] = useState('');
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        componentId
    );
    const isCLProduct = WniProductsUtil.isCLProduct(productCode);

    const getActivityOptions = useCallback(
        () => {
            const openActivityOptionsArray = [
                translator(messages.allOpen),
                translator(messages.allCompleted),
                translator(messages.overdue),
                translator(messages.openAssignedToMe)
            ];
            return openActivityOptionsArray.map((key) => {
                return {
                    code: key,
                    name: key
                };
            });
        },
        [translator]
    );

    const dateIsInFuture = useCallback((date) => {
        if (!date) {
            return false;
        }

        const currentTime = new Date();
        currentTime.setHours(0, 0, 0, 0);
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        return selectedDate >= currentTime;
    }, []);

    const isPageValid = useCallback(() => {
        const dueDate = _.get(addActivitySubmissionVM.value, 'dueDate');
        const escalationDate = _.get(addActivitySubmissionVM.value, 'escalationDate');
        const isFutureDueDate = dateIsInFuture(dueDate);
        const isFutureEscalationDate = dateIsInFuture(escalationDate);
        return isFutureDueDate && isFutureEscalationDate;
    }, [addActivitySubmissionVM, dateIsInFuture]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    const getActivityDataTable = useCallback(
        (activityResponseData, filter) => {
            let updatedActivityList = activityResponseData;
            const statusMap = {
                allOpen: translator(messages.allOpen),
                overDueOnly: translator(messages.overdue),
                allClose: translator(messages.allCompleted),
                openAssignedToMe: translator(messages.openAssignedToMe)
            };

            if (_.toString(filter.statusFilter) !== '') {
                updatedActivityList = filterActivitiesByStatus(
                    filter.statusFilter, statusMap, updatedActivityList
                );
            }

            if (_.toString(filter.searchFilter) !== '') {
                updatedActivityList = filterActivitiesByValue(
                    updatedActivityList, filter.searchFilter
                );
            }
            setActivityList(_.sortBy(updatedActivityList, 'dueDate'));
            setActivityFilter(filter);
            if (!updatedActivityList.length) {
                updateDisplayNoActivities(true);
            } else {
                updateDisplayNoActivities(false);
            }
        },
        [translator]
    );

    const responseData = useCallback(
        async () => {
            const filter = _.cloneDeep(activityFilter);
            filter.statusFilter = translator(messages.allOpen);
            setLoadingMask(true);
            const response = await getActivities();
            setLoadingMask(false);
            const activities = _.map(response, (activity) => {
                return _.extend({}, activity, { expanded: false });
            });
            getActivityDataTable(activities, filter);
            updateOpenActivityResponse(activities);
            updateActivityOptions(getActivityOptions(activities));
            return true;
        },
        [activityFilter, getActivities, getActivityDataTable, getActivityOptions, translator]
    );

    const shouldShowAddActivityButton = useCallback(
        async () => {
            const permissionDTO = {
                permission: 'actcreate'
            };
            UserService.hasUserSystemPermission(permissionDTO, authHeader).then((showActivity) => {
                updateShowActivityButton(showActivity);
            });
        },
        [authHeader]
    );

    useEffect(() => {
        responseData();
        shouldShowAddActivityButton();
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNote = async (data, publicID) => {
        const activityNoteData = await ActivitiesService.createNoteForActivity(
            publicID,
            data,
            authHeader
        );
        if (!_.isUndefined(activityNoteData)) {
            modalApi.showAlert({
                title: gatewayMessages.noteCreated,
                message: gatewayMessages.noteCreatedSuccessfully,
                status: 'success',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                const activityObj = openActivityResponse.find((obj) => obj.publicID === publicID);
                activityObj.notes.push(activityNoteData);
                getActivityDataTable(openActivityResponse, activityFilter);
            }, _.noop);
        }
    };

    const createVM = useCallback(
        (model) => {
            return viewModelService.create(
                model,
                'pc',
                'edge.capabilities.gateway.activity.dto.ActivityDTO'
            );
        },
        [viewModelService]
    );

    const handleChange = useCallback(
        (value, path) => {
            const userData = assignableUserData;
            const addActivityResponseData = activityPatternData;
            const newActivitySubmissionVM = viewModelService.clone(addActivitySubmissionVM);
            let newActivityResult = [];
            _.set(newActivitySubmissionVM.value, path, value !== '' ? value : undefined);
            switch (path) {
                case 'jobType':
                    newActivityResult = addActivityResponseData.find((ob) => {
                        return ob.subject === value;
                    });
                    _.set(newActivitySubmissionVM.value, 'dueDate', newActivityResult.dueDate);
                    _.set(newActivitySubmissionVM.value, 'subject', newActivityResult.subject);
                    _.set(
                        newActivitySubmissionVM.value,
                        'escalationDate',
                        newActivityResult.escalationDate
                    );
                    _.set(newActivitySubmissionVM.value, 'priority', newActivityResult.priority);
                    _.set(
                        newActivitySubmissionVM.value,
                        'description',
                        newActivityResult.description
                    );
                    _.set(newActivitySubmissionVM.value, 'selectedAssignee', userData[0]);
                    _.set(newActivitySubmissionVM.value, 'mandatory', newActivityResult.mandatory);
                    _.set(newActivitySubmissionVM.value, 'code', newActivityResult.code);
                    _.set(newActivitySubmissionVM.value, 'recurring', newActivityResult.recurring);
                    _.set(
                        newActivitySubmissionVM.value,
                        'assignedTo',
                        newActivityResult.assignedTo
                    );
                    updateSelectedUser(newActivitySubmissionVM.value.selectedAssignee);
                    break;
                case 'assignedTo':
                    _.set(newActivitySubmissionVM.value, 'assignedTo', value);
                    updateSelectedUser(value);
                    break;
                default:
                    break;
            }
            updateVMData(newActivitySubmissionVM);
        },
        [activityPatternData, addActivitySubmissionVM, assignableUserData, viewModelService]
    );

    const handleFilterChange = (value, id) => {
        const filter = _.cloneDeep(activityFilter);
        if (id === 'statusFilter') {
            filter.statusFilter = value;
            getActivityDataTable(openActivityResponse, filter);
        }
        if (id === 'searchFilter') {
            filter.searchFilter = value;
            getActivityDataTable(openActivityResponse, filter);
        }
    };

    useEffect(() => {
        const addNewActivityResponse = activityPatternData;
        const userData = assignableUserData;
        const subjectDataArray = addNewActivityResponse.map((key) => {
            return {
                name: key.subject,
                code: key.subject
            };
        });

        const uniqueActivityPriority = _.uniqBy(addNewActivityResponse, 'priority');
        const priorityDataArray = uniqueActivityPriority.map((key) => {
            return {
                name: _.startCase(_.toLower(key.priority)),
                code: key.priority
            };
        });

        const userDataObject = userData.map((key) => {
            return {
                name: key,
                code: key
            };
        });
        const defaultSelectedUser = userDataObject[0];
        if (!_.isEmpty(activityPatternData)) {
            updateShowComponent(true);
        }
        updateNewActivityTypeAvailableValues(subjectDataArray);
        updateNewActivityPriorityAvailableValues(priorityDataArray);
        updateUserAvailableValues(userDataObject);
        updateSelectedUser(defaultSelectedUser);
    }, [activityPatternData, assignableUserData, updateSelectedUser]);

    const getAssignableUserForActivity = useCallback(
        (activityPatterns) => {
            const res = activityPatterns;
            ActivitiesService.getAssignableUserForActivity(
                activityEntity,
                activityEntityId,
                res,
                authHeader
            ).then((activity) => {
                updateAssignableUserData(activity.assignableUsers);
            });
        },
        [activityEntity, activityEntityId, authHeader]
    );

    const getActivityPatternsFor = useCallback(
        () => {
            ActivitiesService.getActivityPatternsFor(activityEntity, authHeader).then(
                (activityPatterns) => {
                    getAssignableUserForActivity(activityPatterns[0]);
                    updateActivityPatternData([...activityPatterns]);
                    setIsAddActivityLoading(false);
                }
            );
        },
        [activityEntity, authHeader, getAssignableUserForActivity]
    );

    const addNewActivityClick = useCallback(
        () => {
            setIsAddActivityLoading(true);
            const addActivitySubmissionData = createVM({});
            updateVMData(addActivitySubmissionData);
            getActivityPatternsFor();
        },
        [createVM, getActivityPatternsFor]
    );

    const handleError = useCallback(
        (title, message) => {
            return modalApi.showAlert({
                title: title,
                message: message,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.noTypeSelected
            }).catch(_.noop);
        },
        []
    );

    const addNewActivityOOTBAddClick = useCallback(
        async (model) => {
            let newActivityData = {
                ..._.omit(model.value, 'assignedTo'),
                selectedAssignee: _.get(model.value, 'assignedTo'),
                activityPattern: {
                    code: _.get(model.value, 'code'),
                    priority: _.get(model.value, 'priority')
                }
            };
            newActivityData = _.omit(newActivityData, 'jobType');
            switch (activityEntity) {
                case 'account':
                    newActivityData.accountNumber = activityEntityId;
                    break;
                case 'policy':
                    newActivityData.policyNumber = activityEntityId;
                    break;
                case 'job':
                    newActivityData.jobNumber = activityEntityId;
                    break;
                default:
                    break;
            }
            if (!_.get(model.value, 'jobType')) {
                handleError(messages.noActivityType, messages.noActivityTypeMessage);
            } else {
                const addResponse = await createNewActivity(newActivityData);
                if (!_.isEmpty(addResponse) && !_.isUndefined(addResponse)) {
                    ToastProvider.toast({
                        type: 'success',
                        message: translator(messages.activityCreated),
                        autoClose: true
                    });
                    if (getActivitiesTileUpdatedCount) {
                        getActivitiesTileUpdatedCount();
                    }
                }
                const updatedActivityResponseArray = [...openActivityResponse, addResponse];
                updateOpenActivityResponse(updatedActivityResponseArray);
                const filter = _.cloneDeep(activityFilter);
                filter.statusFilter = translator(messages.allOpen);
                getActivityDataTable(updatedActivityResponseArray, filter);
                updateActivityOptions(getActivityOptions(updatedActivityResponseArray));
                if (!_.isUndefined(getPolicyDetails)) {
                    getPolicyDetails();
                }
            }
        },
        [
            openActivityResponse,
            activityEntity,
            activityEntityId,
            activityFilter,
            createNewActivity,
            getActivitiesTileUpdatedCount,
            getActivityDataTable,
            getActivityOptions,
            getPolicyDetails,
            handleError,
            translator
        ]
    );
 // For CL Products, keep OOTB add activity behivior - open a popup and hide Escalation Date 
    const addNewOOTBActivityClick = useCallback(
        () => {
            modalApi.showModal(
                <AddActivityPopUp
                    title={translator(messages.addActivity)}
                    actionBtnLabel={messages.createActivity}
                    cancelBtnLabel={messages.cancel}
                    activityEntity={activityEntity}
                    activityEntityId={activityEntityId}
                    viewModelService={viewModelService}
                    authHeader={authHeader}
                    size='md'
                />
            )
                .then(addNewActivityOOTBAddClick)
                .catch(_.noop);
        },
        [openActivityResponse, activityEntity, activityEntityId,viewModelService,authHeader,translator]
    );

    const addNewActivityAddClick = useCallback(
        async () => {
            let newActivityData = {
                ...addActivitySubmissionVM.value,
                activityPattern: {
                    code: _.get(addActivitySubmissionVM.value, 'code'),
                    priority: _.get(addActivitySubmissionVM.value, 'priority')
                }
            };
            newActivityData = _.omit(newActivityData, 'jobType');
            setIsAddActivityLoading(true);
            switch (activityEntity) {
                case 'account':
                    newActivityData.accountNumber = activityEntityId;
                    break;
                case 'policy':
                    newActivityData.policyNumber = activityEntityId;
                    break;
                case 'job':
                    newActivityData.jobNumber = activityEntityId;
                    break;
                default:
                    break;
            }
            if (!_.get(addActivitySubmissionVM.value, 'jobType')) {
                handleError(messages.noActivityType, messages.noActivityTypeMessage);
                setIsAddActivityLoading(false);
            } else {
                const addResponse = await createNewActivity(newActivityData);
                if (!_.isEmpty(addResponse) && !_.isUndefined(addResponse)) {
                    modalApi.showAlert({
                        title: messages.activityCreated,
                        status: 'info',
                        icon: 'gw-error-outline'
                    }).catch(_.noop);
                    if (getActivitiesTileUpdatedCount) {
                        getActivitiesTileUpdatedCount();
                    }
                }
                const updatedActivityResponseArray = [...openActivityResponse, addResponse];
                updateOpenActivityResponse(updatedActivityResponseArray);
                const filter = _.cloneDeep(activityFilter);
                filter.statusFilter = translator(messages.allOpen);

                getActivityDataTable(updatedActivityResponseArray, filter);
                updateActivityOptions(getActivityOptions(updatedActivityResponseArray));
                updateShowComponent(false);
                updateVMData({});
                setIsAddActivityLoading(false);
                if (!_.isUndefined(getPolicyDetails)) {
                    getPolicyDetails();
                }
            }
        },
        [
            activityEntity,
            activityEntityId,
            activityFilter,
            addActivitySubmissionVM.value,
            createNewActivity,
            getActivitiesTileUpdatedCount,
            getActivityDataTable,
            getActivityOptions,
            getPolicyDetails,
            handleError,
            openActivityResponse,
            translator
        ]
    );

    const addNewActivityCancelClick = useCallback(
        () => {
            updateShowComponent(false);
            updateVMData({});
        },
        []
    );
   
    const onClickComplete = useCallback(
        (data) => {
            const activityObj = openActivityResponse.find((obj) => obj.publicID === data);
            ActivitiesService.markActivityAsCompleted(data, authHeader).then((completeActivity) => {
                if (activityObj) {
                    activityObj.status = completeActivity.status;
                    activityObj.closeDate = completeActivity.closeDate;
                    activityObj.completedDate = completeActivity.completedDate;
                    activityObj.canComplete = false;
                }
                setPublicId(data);
            });
        },
        [authHeader, openActivityResponse]
    );

    const tagType = !_.get(addActivitySubmissionVM.value, 'jobType');

    const {
        activitiesOverdue,
        activitiesComplete,
        activitiesFuture,
        activitiesThisWeek,
        activitiesToday,
        activitiesTomorrow
    } = splitActivitiesForTable(activityList);

    const overrideProps = {
        statusFilter: {
            availableValues: activityOptions,
            value: activityFilter.statusFilter,
            disabled: showComponent
        },
        searchFilter: {
            value: activityFilter.searchFilter,
            disabled: showComponent
        },
        noActivityFound: {
            visible: displayNoActivities !== false
        },
        noActivitySeparator: {
            visible: displayNoActivities !== false
        },
        activitiesDetailGrid: {
            visible: displayNoActivities === false
        },
        type: {
            availableValues: newActivityTypeAvailableValues,
            value: _.get(addActivitySubmissionVM.value, 'jobType')
        },
        priority: {
            availableValues: newActivityPriorityAvailableValues
        },
        assignedTo: {
            availableValues: userAvailableValues,
            disabled: tagType,
            value: selectedUser,
            showOptional: true,
        },
        addActivityButton: {
            visible: showActivityButton,
            disabled: showComponent && !isCLProduct
        },
        addNewActivityButton: {
            disabled: !isComponentValid
        },
        dueDate: {
            disabled: tagType,
            minDate: LocalDateUtil.today()
        },
        subject: {
            disabled: tagType
        },
        escalationDate: {
            disabled: tagType,
            minDate: LocalDateUtil.today()
        },
        description: {
            disabled: tagType,
            showOptional: true,
        },
        loader: {
            loaded: !isAddActivityLoading
        },
        activitiesTableContainer: {
            activitiesOverdue,
            activitiesToday,
            activitiesTomorrow,
            activitiesThisWeek,
            activitiesFuture,
            activitiesComplete,
            displayNoActivities,
            createNote,
            onClickComplete,
            publicId
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            activitytablecomponent: ActivityTableComponent
        },
        resolveCallbackMap: {
            handleLOBValueChange: (value) => handleFilterChange(value, 'statusFilter'),
            handleSearchValueChange: (value) => handleFilterChange(value, 'searchFilter'),
            addNewActivityClick: isCLProduct? addNewOOTBActivityClick : addNewActivityClick,
            addNewActivityAddClick: addNewActivityAddClick,
            addNewActivityCancelClick: addNewActivityCancelClick
        }
    };

    const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                addActivitySubmissionVM,
                id,
                path,
                overrideProps
            );
        };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <div className={cx(styles.summary)}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={addActivitySubmissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={handleChange}
                onValidationChange={onValidate}
                resolveValue={readValue}
            />
        </div>
    );
}

OpenActivitiesComponent.propTypes = {
    id: PropTypes.string.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    getActivities: PropTypes.func.isRequired,
    activityEntityId: PropTypes.number.isRequired,
    activityEntity: PropTypes.string.isRequired,
    createNewActivity: PropTypes.func.isRequired,
    getPolicyDetails: PropTypes.func.isRequired,
    getActivitiesTileUpdatedCount: PropTypes.func
};

OpenActivitiesComponent.defaultProps = {
    getActivitiesTileUpdatedCount: undefined
};

export default withAuthenticationContext(OpenActivitiesComponent);
