import React from 'react';
import WizardPage from '../../templates/GLWizardPage';
import GLStateSpecificInformationPage from './GLStateSpecificInformationPage';

function GLStateSpecificInformationReadOnlyPage(props) {

    return (
        <GLStateSpecificInformationPage {...props} isReadOnly/>
    );
}

GLStateSpecificInformationReadOnlyPage.propTypes = WizardPage.propTypes;
export default GLStateSpecificInformationReadOnlyPage;