import React, { useEffect } from 'react'
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { WniDropdownSelect } from 'wni-common-base-components'
import { useValidation } from '@xengage/gw-portals-validation-react';

import { ValuePath } from '../../../../../util/IMScheduleItemsUtil'


function formatAvailableValues(availableValues) {
    return _.sortBy(availableValues, 'priority')
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.name
        }));
}

const PropertyFieldDropdown = (props) => {
    const {
        propertyInfo,
        propertyInfoItemData,
        setScheduleItemFunc,
        showErrors,
        onValidate: onContainerValidate,
        isEditable,
    } = props

    const {
        id: propertyInfoId,
        label,
        required,
        valueRange_Ext: valueRange,
        typeKeyName_Ext: typeKeyName
    } = propertyInfo

    const { onValidate, isComponentValid } = useValidation(propertyInfoId);

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, propertyInfoId);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, propertyInfoId);
            }
        };
    }, [isComponentValid, onContainerValidate, propertyInfoId]);

    const {
        options_Ext: allOptions = [],
        [ValuePath.TypeCodeValue]: value,
        available_Ext: available
    } = propertyInfoItemData

    const optionValues = allOptions;

    const translator = useTranslator();

    const  formatAvailableTypeKeyValues = () => {
        return valueRange.map((typeKey) => ({
                code: typeKey.code,
                name: translator({id: `${typeKeyName}.${typeKey.code}`})
            }));
    }

    const availableValues = !_.isEmpty(valueRange) ? formatAvailableTypeKeyValues(): formatAvailableValues(optionValues)

    const onDropdownValueChange = (newValue) => {
        const newItemDataField = {
            ...propertyInfoItemData,
            [ValuePath.TypeCodeValue]: newValue,
            updated_Ext: true
        };
        setScheduleItemFunc((oldScheduleItem) => {
            const newScheduleItem = {
                ...oldScheduleItem,
                itemData: {
                    ...oldScheduleItem.itemData,
                    [propertyInfoId]: newItemDataField
                }
            }

            // _.set(newScheduleItem, `itemData.${propertyInfoId}`, newItemDataField)
            return newScheduleItem
        })
    }

    return <WniDropdownSelect
        id = {propertyInfoId}
        label = {label}
        dataType = 'string'
        required = {required}
        showRequired = {required}
        availableValues = {availableValues}
        value = {value}
        onValueChange = {onDropdownValueChange}
        showErrors = {showErrors}
        disabled={!available}
        onValidationChange={onValidate}
        readOnly={!isEditable}
    />
}

export default PropertyFieldDropdown