import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-pa.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-pa.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-pa.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-pa.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    // sureDeletePolicyChange: {
    //     id: 'gw-portals-policytransaction-pc-pa.gateway.views.endorsement.Are you sure you want to delete this policy change?',
    //     defaultMessage: 'Are you sure you want to delete this policy transaction? All changes will be lost.'
    // },
    unableToStartPolicy: {
        id: 'custom.portal.components.transactionWizardTitle.transactionWizardTitle.Unable to Start Transaction',
        defaultMessage: 'Unable to Start Transaction',
    },
    // deletePolicyChange: {
    //     id: 'custom.portal.components.transactionWizardTitle.transactionWizardTitle.Delete',
    //     defaultMessage: 'Delete',
    // },
    personalAutoPolicyChange: {
        id: 'custom.endorsement.directives.templates.pa.Policy Changes - Personal Auto',
        defaultMessage: 'Personal Auto({policyNumber})'
    },
});
