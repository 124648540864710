import React from 'react';

function ModalBody(props) {
    const {
        className = '',
        children
    } = props;
    return <div className={`wni__ModalBody__modalBody ${className}`}>
        {children}
    </div>;
}

export default ModalBody;