import React from "react";
import { IMBuildingsAndLocationsPage } from "wni-capability-quoteandbind-im-react";
import { PolicyChangeUtil } from 'wni-portals-util-js';

function IMBuildingsAndLocationsChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('IMLine', submissionVM);
    return <IMBuildingsAndLocationsPage {...props} isPolicyChange={!isDisplayAsSubmission} />
};

export default IMBuildingsAndLocationsChangePage;
