
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale'; 
// import { HOAdditionalInterestsReadOnlyPage } from 'wni-capability-quoteandbind-ho-react';
import { BaseAdditionalInterestsReadOnlyPageComponent } from 'wni-capability-common-react';

import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

function DPAdditionalInterestsReadOnlyPage(props) {

    const initProps = {
        ...props,
        WizardPage,
        lobDataProductKey: 'dwellingProperty',
        dtoVMPath: 'wni.edge.capabilities.quote.lob.dwellingproperty.dto.DPAdditionalInterestDTO',
        showAdditionalInterestInsuredType: true,
        showAdditionalInterestPleaseExplain: true,
        showAdditionalInterestSpecificInterest: true,
        showAdditionalInterestNameOfSchool: true,
    };

    return (
        <BaseAdditionalInterestsReadOnlyPageComponent {...initProps} />
    );

}

DPAdditionalInterestsReadOnlyPage.propTypes = BaseAdditionalInterestsReadOnlyPageComponent.propTypes;
DPAdditionalInterestsReadOnlyPage.defaultProps = BaseAdditionalInterestsReadOnlyPageComponent.defaultProps;

export default DPAdditionalInterestsReadOnlyPage;
