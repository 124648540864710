import { defineMessages } from 'react-intl';

export default defineMessages({
    vehiclePageMessage: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.ca.vehicle.warning message',
        defaultMessage: 'To enter the information for multiple vehicles at once please select the download template button to receive a blank bulk upload spreadsheet and enter in relevant vehicle information by vehicle type (Truck, Private Passenger Vehicle, Zone Rated Truck, Public Transit, Special Type). Existing data can be modified in bulk by selecting the export data button and modifying the downloaded spreadsheet. For additional guidance please consult the '
    },
    vehicleChangePageMessage: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.vehicle change.warning message',
        defaultMessage: 'You may proceed to add any PPT, light truck, or medium truck. Please note, for Zone Rated, Public Transport, Heavy and Extra Heavy Trucks and Truck Tractor type; the vehicle type must already exist on the policy to add additional vehicles of that type. Please contact your underwriter with any questions.',
    },
    garagedAtNew: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Garaged At.New',
        defaultMessage: 'New',
    },
    removeVehicleTitle: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeVehicleDescription: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this vehicle record?',
    },
    viewAndEditLabel: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.View',
        defaultMessage: 'View',
    },
    applyFilters: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Apply Filters',
        defaultMessage: 'Apply Filters',
    },
    exportTitle: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Export',
        defaultMessage: 'Export',
    },
    exportToSpreadsheet: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Export To Spreadsheet',
        defaultMessage: 'Export To Spreadsheet',
    },
    importTitle: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Import',
        defaultMessage: 'Import',
    },
    copyNotes: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Please note that every entered field and coverage will be copied from the existing entry, with the exception of VIN. A unique VIN will be required for every vehicle before a policy can be issued',
        defaultMessage: 'Please note that every entered field and coverage will be copied from the existing entry, with the exception of VIN. A unique VIN will be required for every vehicle before a policy can be issued',
    },
    copyVehicles: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Copy Vehicles',
        defaultMessage: 'Copy Vehicles',
    },
    importNote: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Import Note',
        defaultMessage: 'Please Note: Any Imported template will automatically generate vehicle information based on VIN and/or Year, Make, and Model.',
    },
    importNoteAdditionally: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Import Note Additionally',
        defaultMessage: 'Additionally, all vehicle entries are required to be broken down by vehicle Type in the bulk upload spreadsheet (ie: Truck, Private Passenger, Zone Rated, Public Transport, or Specially Type).',
    },
    importNoteLastly: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.Import Note Lastly',
        defaultMessage: 'Lastly, all spreadsheet errors must be addressed before entries can be accepted into the quote.',
    },
    vehicleRead: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.vehicle read',
        defaultMessage: "Number of { vehicleType } Entities Read:"
    },
    vehicleEdited: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.vehicle edited',
        defaultMessage: "Number of { vehicleType } Entities Edited:"
    },
    vehicleAdded: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.vehicle added',
        defaultMessage: "Number of { vehicleType } Entities Added:"
    },
    vehicleRemoved: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-vehicle.vehicle Removed',
        defaultMessage: "Number of { vehicleType } Entities Removed:"
    },
    error: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Something went wrong. Template was not downloaded. Please try again.',
        defaultMessage: 'Something went wrong. Template was not downloaded. Please try again.'
    },
    priceDigestTitle: {
        id: 'quoteandbind.ca.directives.templates.ca-vehicle.vehicle.Price Digest(s)',
        defaultMessage: 'Select Vehicle {vehicleNumber}'
    },
    overCopyMessage: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.vehicle.overCopyMessage',
        defaultMessage: 'You have copied over 20 versions of the same vehicle, do you wish to proceed?'
    },
    overwrittenTitle: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.overwritten',
        defaultMessage: 'Overwritten'
    },
    uovTitle: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.System Generated Value Overridden',
        defaultMessage: 'System Generated Value Overridden'
    },
    vouTitle: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.User Entered Value Overridden',
        defaultMessage: 'User Entered Value Overridden'
    },
    copyVehicleCoverage: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Copy Vehicle Coverages',
        defaultMessage: 'Copy Vehicle Coverages'
    },
    applyCoverages: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Select Vehicle(s) to Apply Coverages To:',
        defaultMessage: 'Select Vehicle(s) to Apply Coverages To:'
    },
    copyCoverageSuccess: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Coverages have been Copied',
        defaultMessage: 'Coverages have been Copied'
    },
    copyCoverageFailed: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Coverages Copy Failed',
        defaultMessage: 'Coverages Copy Failed'
    },
    // BR.CL.0225
    OriginalCostNewMessage: {
        id: "quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Original Cost New cannot be lower than the VIN integration value generated",
        defaultMessage: "Original Cost New cannot be lower than the VIN integration value generated"
    },
    importTimeOut: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Your vehicle data is currently being uploaded. This process may take a few minutes. Please do not reload the screen or you will risk cancelling the upload process. ',
        defaultMessage: 'Your vehicle data is currently being uploaded. This process may take a few minutes. Please do not reload the screen or you will risk cancelling the upload process.'
    }
});
