import { defineMessages } from 'react-intl';

export default defineMessages({
    policyIsCancelled: {
        id: 'customer.pa.wizard.step.policychange.warning.policyIsCancelled',
        defaultMessage: 'This policy is canceled as of { cancellationDate }'
    },
    wizardPageJumpTitle: {
        id: 'customer.pa.wizard.step.policychange.Select a link below to jump directly to',
        defaultMessage: 'Select a link below to jump directly to:',
    },
    wizardPageItemPrefix: {
        id: 'customer.pa.wizard.step.policychange.Change ',
        defaultMessage: 'Change ',
    },
});
