import { defineMessages } from 'react-intl';

export default defineMessages({
    productFilterAllProductsItemName: {
        id: 'wni.directives.policy-transaction.all products',
        defaultMessage: '--All Products--',
    },
    productFilterAllTransactionsItemName: {
        id: 'wni.directives.policy-transaction.all transactions',
        defaultMessage: '--All Transactions--',
    },
    prendingTransationsColumnHeaderProduct: {
        id: 'wni.directives.policy-transaction.Product',
        defaultMessage: 'Product',
    },
    prendingTransationsColumnHeaderEffective: {
        id: 'wni.directives.policy-transaction.Effective',
        defaultMessage: 'Effective',
    },
    prendingTransationsColumnHeaderTotalPremium: {
        id: 'wni.directives.policy-transaction.Total Premium',
        defaultMessage: 'Total Premium',
    },
    prendingTransationsColumnHeaderStatus: {
        id: 'wni.directives.policy-transaction.Status',
        defaultMessage: 'Status',
    },
    prendingTransationsColumnHeaderStatusTootipDraft: {
        id: 'wni.directives.policy-transaction.StatusTootip.Draft',
        defaultMessage: 'Draft- Proceed to quote or complete edits',
    },
    prendingTransationsColumnHeaderStatusTootipRated: {
        id: 'wni.directives.policy-transaction.StatusTootip.Rated',
        defaultMessage: 'Rated- Proceed to complete quote',
    },
    prendingTransationsColumnHeaderStatusTootipQuoted: {
        id: 'wni.directives.policy-transaction.StatusTootip.Quoted',
        defaultMessage: 'Quoted- Proceed to validation or refer to underwriting',
    },
    prendingTransationsColumnHeaderStatusTootipApproved: {
        id: 'wni.directives.policy-transaction.StatusTootip.Approved',
        defaultMessage: 'Approved- Proceed to issue',
    },
    prendingTransationsColumnHeaderQuoteSummary: {
        id: 'wni.directives.policy-transaction.Quote Summary',
        defaultMessage: 'Quote Summary',
    },
    prendingTransationsColumnHeaderViewPolicy: {
        id: 'wni.directives.policy-transaction.View Policy',
        defaultMessage: 'Policy #',
    },
    prendingTransationsColumnHeaderType: {
        id: 'wni.directives.policy-transaction.Transaction Type',
        defaultMessage: 'Transaction Type',
    },
    prendingTransationsColumnHeaderViewTransaction: {
        id: 'wni.directives.policy-transaction.View Transaction',
        defaultMessage: 'Transaction #'
    },
    withdrawJob: {
        id: 'wni.directives.policy-transaction.WithdrawJob',
        defaultMessage: 'Withdraw Quote?'
    },
    sureWithDrawJob: {
        id: 'wni.directives.policy-transaction.Are you sure you want to withdraw {jobType}:',
        defaultMessage: 'Are you sure you want to withdraw {jobType}: {quoteNumber}? All changes will be lost.'
    },
    failedWithdrawSubmission: {
        id: 'wni.directives.JobSummary.Failed to withdraw submission',
        defaultMessage: 'Failed to withdraw submission'
    },
    warningMsgNoRights: {
        id: 'wni.directives.active-policies.warning Policy Message',
        defaultMessage: 'Our records indicate that you do not have servicing rights on this policy. Please contact your Underwriter for further assistance'
    },
    changePolicy: {
        id: 'wni.views.policies.PolicySummary.Change Policy',
        defaultMessage: 'Change Policy'
    },
    cancelPolicy: {
        id: 'wni.views.policies.PolicySummary.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },
    electronic: {
        id: 'wni.views.policies.policySummary.Electronic/Portal Delivery',
        defaultMessage: 'Electronic/Portal Delivery'
    },
    print: {
        id: 'wni.views.policies.policySummary.Print/Mail Delivery',
        defaultMessage: 'Print/Mail Delivery'
    },
    blockChangeTitle: {
        id: 'wni.accountDetails.blockChangeTitle',
        defaultMessage: 'Transaction Conflict'
    },
    oosTransaction: {
        id: 'wni.accountDetails.oosTransaction',
        defaultMessage: 'Due to additional policy changes in progress, this transaction is identified as out of sequence. Please contact your underwriter or start a new policy change in the most recently updated policy.'
    },
    preemptiveTransaction: {
        id: 'wni.accountDetails.preemptiveTransaction',
        defaultMessage: 'Please contact your underwriter to continue this transaction or start a new policy change on the most up to date transaction'
    },
    createNewQuoteCL:  {
        "id": "wni.accountDetails.cl.Quote Additional Line of Business",
        "defaultMessage": "Quote Additional Line of Business"
      },
    createNewQuotePL: {
        "id": "wni.accountDetails.pl.New Quote for This Account",
        "defaultMessage": "New Quote for This Account"
    }
});
