import React, {
    useCallback,
    useState,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { 
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import ScheduleItemsComponent from "../PUScheduleItemsComponent/PUScheduleItemsComponent";
import CoverageUtil from '../../util/PUCoverageUtil';
import CoveragesConfigContext from '../../context/PUCoveragesConfigContext';

import { Button } from '@jutro/legacy/components';

function PUScheduleItemsPopup(props) {
    const {
        size,
        isOpen,
        isEditable,
        // onReject,
        onResolve,
        coverage,
        coveragePath,
        coveragesService,
        jobID,
        sessionUUID,
        lobName,
        submissionVM,
        updateSubmissionVM,
        lobCoveragesPath,
        getCoverageFromLobCoverages,
        useAuthenticationData,
        viewModelService,
    } = props;

    const coveragesConfig = useContext(CoveragesConfigContext);

    const [coverageInModal, setCoverageInModal] = useState(coverage)

    const {
        code_Ext: coverageCode,
        schedule,
    } = coverageInModal;

    const { authHeader } = useAuthenticationData || useAuthentication();

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [needSyncBeforeSave, setNeedSyncBeforeSave] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [popupWarningIssue, setPopupWarningIssue] = useState([]);
    const [openedRowItemNumber, setOpenedRowItemNumber] = useState();
    const [selectedRowItemIndex, setSelectedRowItemIndex] = useState([]);

    const isCoverageInvalid = CoverageUtil.isCoverageInvalid(coverageInModal)

    const isOpenedRowItemInvalid = CoverageUtil
        .isOpenedScheduleOrStructureInValid(openedRowItemNumber, coverageInModal)
    const bottomButton = ((openedRowItemNumber && isOpenedRowItemInvalid) || isCoverageInvalid || needSyncBeforeSave) ? 'Save' : 'Close';

    const updateUIByResponse = useCallback(
        (response) => {
            setIsEditing(false);
            const lobCoverages = _.get(response, lobName);
            const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
            // Only show warnings on field change here
            const newErrorsAndWarnings = ErrorsAndWarningsUtil.getServerIssues(errorsAndWarnings);
            const newWarnings = newErrorsAndWarnings.filter((issue) => _.get(issue, 'type') === 'warning');
            setPopupWarningIssue(newWarnings)
            // Update coverages on socerages screen
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, lobCoveragesPath, lobCoverages);
            updateSubmissionVM(newSubmissionVM);
            // Update coverages on serires popup
            const updatedCoverageAfterSync = getCoverageFromLobCoverages(lobCoverages);
            setCoverageInModal(updatedCoverageAfterSync)
            return updatedCoverageAfterSync
        },
        [lobName, viewModelService, submissionVM, lobCoveragesPath, updateSubmissionVM, getCoverageFromLobCoverages]
    )

    const saveChangesToPC = useCallback(
        async (coverageToSave) => {
            setNeedSyncBeforeSave(true);
            const pathToLobCovDTO = coveragePath.replaceAll('.children', '').replaceAll(`${lobCoveragesPath}.`, '');
            const lobCovs = _.get(submissionVM.value, lobCoveragesPath);
            _.set(lobCovs, pathToLobCovDTO, coverageToSave)
            const clausesToUpdate = CoverageUtil.generateUpdatedCoveragesDTO(lobCovs, lobName);
            setIsLoading(true);
            const response = await coveragesService.updateCoverages(
                jobID,
                sessionUUID,
                clausesToUpdate,
                authHeader
            )
            setIsLoading(false);
            
            // Sync complete
            setNeedSyncBeforeSave(false);
            return response
        },
        [authHeader, jobID, sessionUUID, lobName, coveragesService, coveragePath, lobCoveragesPath, submissionVM.value]
    )

    // This function change submission but not sync
    const changeSubmission = useCallback(
        (value, changedPath) => {
            setIsEditing(false)
            // Due to someThing change, need sync to PC later
            setNeedSyncBeforeSave(true)
            const changedItemPathToCoverage = changedPath.replace(`${coveragePath}.`, '');
            let updatedCoverageInModal = _.clone(coverageInModal);
            updatedCoverageInModal = CoverageUtil.setValueToCoverage(updatedCoverageInModal, value, changedItemPathToCoverage);
            setCoverageInModal(updatedCoverageInModal);
            return updatedCoverageInModal
        },
        [coverageInModal, coveragePath]
    );
    
    // This function change submission and sync
    const changeSubmissionAndUpdateCoverageInModal = useCallback(
        async (value, changedPath) => {
            // changeSubmission(value, changedPath)
            setIsEditing(false)
            const changedItemPathToCoverage = changedPath.replace(`${coveragePath}.`, '');
            
            let coverageInModalClone = _.clone(coverageInModal);
            
            coverageInModalClone = CoverageUtil.setValueToCoverage(coverageInModalClone, value, changedItemPathToCoverage);

            const response = await saveChangesToPC(coverageInModalClone)
            const updatedCoverageAfterSync = updateUIByResponse(response)
            
            return updatedCoverageAfterSync
        },
        [coverageInModal, saveChangesToPC, updateUIByResponse, coveragePath]
    );

    const onScheduleChangeWithoutSync = useCallback(
        (value, changedPath, localPathToScheduleItem, changedFields) => {
            let updatedSchedule = value
            _.forEach(changedFields, (changedField) => {
                const frontendScheduleItemSync = _.get(
                    coveragesConfig,
                    `scheduleConfig.frontendScheduleItemSync.${coverageCode}.${changedField}`)
                if (!_.isNil(frontendScheduleItemSync)) {
                    updatedSchedule = frontendScheduleItemSync(updatedSchedule, localPathToScheduleItem)
                }

            })
            
            const updatedCoverage = changeSubmission(updatedSchedule, changedPath);
            updatedSchedule = _.get(updatedCoverage, 'schedule')
            return updatedSchedule;
        },
        [changeSubmission, coverageCode, coveragesConfig]
    )

    const onScheduleChangeWithSync = useCallback(
        async (value, changedPath) => {
            const updatedCoverageAfterSync = await changeSubmissionAndUpdateCoverageInModal(value, changedPath);
            const updatedScheduleAfterSync = _.get(updatedCoverageAfterSync, 'schedule')
            return updatedScheduleAfterSync;
            
        },
        [changeSubmissionAndUpdateCoverageInModal]
    )

    const handleSave = async () => {
        // if a row item is open, close the opened item instead of whole popup
        if (!_.isNil(openedRowItemNumber)) {
            
            // When close opened item, save changes when needed
            if (needSyncBeforeSave) {
                const response = await saveChangesToPC(coverageInModal)
                updateUIByResponse(response)
            }
            // If have valid error in current opened item, stop user to let user fix it
            if (isOpenedRowItemInvalid) {
                setShowErrors(true)
            } else {
                setOpenedRowItemNumber(null)
                setShowErrors(false)
            }
            
            return;
        }
        // All row items are closed, close popup
        if (isCoverageInvalid) {
            setShowErrors(true)
            return;
        }
        onResolve(coverageInModal);
    }

    const actionButtonDisabled = isEditing || isLoading;

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={coverage.name} />
            <ModalBody id="tablePopupModal">
                {isLoading ? <Loader loaded={!isLoading} /> : (
                    <>
                        <ValidationIssuesComponent 
                            visible={popupWarningIssue.length > 0}
                            validationIssues={popupWarningIssue}
                        />
                        <ScheduleItemsComponent
                            coverageCode={coverageCode}
                            path= {`${coveragePath}.schedule`}
                            onScheduleChangeWithSync= {onScheduleChangeWithSync}
                            onScheduleChangeWithoutSync={onScheduleChangeWithoutSync}
                            value={schedule}
                            labelPosition = "left"
                            showTitle
                            readOnly= {!isEditable}
                            showScheduleDetailsInPanelBelow
                            openedRowItemNumber={openedRowItemNumber}
                            setOpenedRowItemNumber={setOpenedRowItemNumber}
                            selectedRowItemIndex={selectedRowItemIndex}
                            setSelectedRowItemIndex={setSelectedRowItemIndex}
                            setIsEditing={setIsEditing}
                            showErrors={showErrors}
                        />
                    </>
                    
                )}
            </ModalBody>
            <ModalFooter>
                <Button disabled={actionButtonDisabled} onClick={handleSave} type="filled">{bottomButton}</Button>
            </ModalFooter>
        </ModalNext>
    );
}
PUScheduleItemsPopup.propTypes = {
    size: PropTypes.string
};
PUScheduleItemsPopup.defaultProps = {
    size: 'md',
};

export default PUScheduleItemsPopup;