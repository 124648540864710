import React, { useState, useContext, useMemo, useEffect }from 'react'
import _ from 'lodash'
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './ThirdPartyContact.metadata.json5'
import messages from './ThirdPartyContact.messages'

const ContactSubType = {
    Person: 'Person',
    Company: 'Company',
}
const commonContactInitProperties = {
    isReportedByTypeOptional_Ext: true,
    updated_Ext: true,
}

const AddNewPerson = 'AddNewPerson';
const AddNewCompany = 'AddNewCompany';

const ThirdPartyContact = (props) => {
    const {
        vehicleIncidentVM,
        setVehicleIncidentVM,
        writeValue,
        vehicleOwnerContacts,
        showErrors,
        setComponentValid
    } = props

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('ThirdPartyContact')

    const ownerContact = _.get(vehicleIncidentVM.value, 'ownerContact_Ext');
    const ownerPublicID = _.get(vehicleIncidentVM.value, 'ownerContact_Ext.publicID');

    const contactIndex = vehicleOwnerContacts?.findIndex(elt => elt.publicID === ownerPublicID);
    const { _dtoName, _xCenter} = _.get(vehicleIncidentVM, 'ownerContact_Ext');
    const contactVM = useMemo(() => {
        if (contactIndex !== -1 || ownerPublicID === 'AddNewPerson' || ownerPublicID === 'AddNewCompany') {
            if (_.isNil(_.get(vehicleIncidentVM, 'value.ownerContact_Ext.primaryAddress'))) {
                const addressVM = viewModelService.create(
                    { country: 'US' },
                    'cc',
                    'edge.capabilities.address.dto.AddressDTO'
                )
                _.set(vehicleIncidentVM, 'ownerContact_Ext.primaryAddress', addressVM.value);
            }
            return viewModelService.clone(_.get(vehicleIncidentVM, `ownerContact_Ext`));
        }
        return viewModelService.create(
            {
                primaryAddress: {
                    country: 'US'
                }
            },
            _xCenter,
            _dtoName
        )
    }, [_dtoName, _xCenter, contactIndex, ownerPublicID, vehicleIncidentVM, viewModelService])
    
    useEffect(() => {
        if (setComponentValid) {
            setComponentValid(isComponentValid, 'ThirdPartyContact');
        }
        return () => {
            if (setComponentValid) {
                setComponentValid(true, 'ThirdPartyContact');
            }
        }
    }, [setComponentValid, isComponentValid])
    const primaryPhoneType = _.get(contactVM, 'value.primaryPhoneType');
    const ownerSelectorAvailableValues = useMemo(() => {
        if (!_.isNil(vehicleOwnerContacts) && !_.isEmpty(vehicleOwnerContacts)) {
            return vehicleOwnerContacts.map((contact) => {
                return {
                    code: _.get(contact, 'publicID'),
                    name: _.get(contact, 'displayName', '-')
                }
            }).concat(
                [
                    {
                        code: AddNewPerson, 
                        name: translator(messages.addNewPerson)
                    },
                    {
                        code: AddNewCompany, 
                        name: translator(messages.addNewCompany)
                    }
                ]
            )
        }
        return (
            [
                {
                    code: AddNewPerson, 
                    name: translator(messages.addNewPerson)
                },
                {
                    code: AddNewCompany, 
                    name: translator(messages.addNewCompany)
                }
            ]
        )
    }, [translator, vehicleOwnerContacts])

    const onOwnerSelectorChange = (value) => {
        let currentOwner = null;
        if(value === AddNewPerson) {
            currentOwner = {
                publicID: AddNewPerson,
                ...commonContactInitProperties,
                subtype: ContactSubType.Person,
                primaryAddress: {
                    country: 'US'
                }
            };
        } else if (value === AddNewCompany) {
            currentOwner = {
                publicID: AddNewCompany,
                ...commonContactInitProperties,
                subtype: ContactSubType.Company
            };
        } else {
            currentOwner = vehicleOwnerContacts.find(item => item.publicID === value);
        }
        writeValue(currentOwner, 'ownerContact_Ext');
    }

    const onPropertyOwnerChange = (value, path) => {
        const newVehicleIncidentVM = viewModelService.clone(vehicleIncidentVM);
        _.set(newVehicleIncidentVM.value, path, value);
        const newPropertyOwner = _.get(newVehicleIncidentVM.value, 'ownerContact_Ext');
        const updatedPropertyOwner = {
            ...newPropertyOwner,
            updated_Ext: true
        };
        _.set(newVehicleIncidentVM.value, 'ownerContact_Ext', updatedPropertyOwner);
        setVehicleIncidentVM(newVehicleIncidentVM);
    }
    const onValueChange = (value, path) => {
        writeValue(value, `ownerContact_Ext.${path}`);
    }

    const isFiledVisible = (path) => {
        let isVisible = true;
        switch(path) {
            case 'workNumber':
                isVisible = primaryPhoneType === 'work'
                break;
            case 'homeNumber':
                isVisible = primaryPhoneType === 'home'
                break;
            case 'cellNumber':
                isVisible = primaryPhoneType === 'mobile'
                break;
            default:
                break
        }
        if (!isVisible) {
            _.set(contactVM.value, path, undefined)
        }
        return isVisible
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        addressDetailsContainer: {
            visible: !_.isEmpty(ownerPublicID)
        },
        ownerName: {
            availableValues: ownerSelectorAvailableValues,
            onValueChange: onOwnerSelectorChange
        },
        propertyOwnerContainer: {
            visible: !_.isEmpty(ownerPublicID)
        },
        personContainer: {
            visible: _.get(ownerContact, 'subtype') === 'Person' || _.get(ownerContact, 'subtype') === 'UserContact'
        },
        companyContainer: {
            visible: _.get(ownerContact, 'subtype') === 'Company'
        },
        addressField: {
            model: contactVM,
            basePath: 'primaryAddress',
            onValueChange: onValueChange,
            onValidate,
            cityStateRequired: false
        },
        lastName: {
            tooltip: translator(messages.unknowTips, {
                what: translator(messages.lastName)
            }),
            labelPosition: isPhone ? 'top' : 'left'
        },
        city: {
            tooltip: translator(messages.unknowTips, {
                what: translator(messages.city)
            }),
            labelPosition: isPhone ? 'top' : 'left'
        },
        workNumber: {
            visible: isFiledVisible('workNumber')
        },
        homeNumber: {
            visible: isFiledVisible('homeNumber')
        },
        mobileNumber: {
            visible: isFiledVisible('cellNumber')
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            onPropertyOwnerChange
        },
        resolveComponentMap: {
        }
    };

    return <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
}

export default ThirdPartyContact
