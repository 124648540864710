/* eslint-disable camelcase */
import React from 'react';
import { useTranslator } from '@jutro/locale';
import messages from './HOTooltip.messages';

const ItemListComponent = (props) => {
    const translator = useTranslator();
    const { content, type } = props;
    return <li className={type}>{translator(content)}</li>
};

const HOTooltips = {
    // return {
    /*
     *   household
     */
    namedInsuredOccupancy: (
        <ul className='tooltipList'>
            <ItemListComponent type="item" content={messages.namedInsuredOccupancyFullTime} />
            <ItemListComponent type="item"
                content={messages.namedInsuredOccupancyAtLeastHalfOfYear}
            />
            <ItemListComponent type="item"
                content={messages.namedInsuredOccupancyLessThanHalfTheYear}
            />
            <ItemListComponent type="item" content={messages.namedInsuredOccupancyVacant} />
            <ItemListComponent type="item" content={messages.namedInsuredOccupancyUnoccupied} />
        </ul>
    ),
    /*
     *   Residence Risk Characteristics
     */
    // Are you completing the recommended protective maintenance treatments every 3 years?
    hasCompletedMaintenance: (
        <ul className='tooltipList'>
            <ItemListComponent content={messages.hasCompletedMaintenance} />
            <ItemListComponent type='item'
                content={messages.hasCompletedMaintenanceRegularly}
            />
            <ItemListComponent type='item'
                content={messages.hasCompletedMaintenancePowerWashing}
            />
            <ItemListComponent type='item'
                content={messages.hasCompletedMaintenanceCleanGutters}
            />
            <ItemListComponent type='item' content={messages.hasCompletedMaintenanceBranches} />
            <ItemListComponent type='item' content={messages.hasCompletedMaintenanceSurface} />
        </ul>
    ),
    // Home Monitoring Systems (AX)
    homeMonitoringSystems: (
        <ul className='tooltipList'>
            <ItemListComponent type='item'
                content={messages.homeMonitoringSystemsAutomaticWater}
            />
            <ItemListComponent type='item'
                content={messages.homeMonitoringSystemsWaterMonitoringSystem}
            />
            <ItemListComponent type='item'
                content={messages.homeMonitoringSystemsSmartThermostat}
            />
        </ul>
    ),

    /*
     *   coverages
     */
    // eslint-disable-next-line camelcase
    // Cov A Valuation Type (Coverage A - Residence >> Valuation Method productModel: PC.HO.6003) ?? options are different with tooltip info. need to confirm with BA
    HOPCovAValuation: (
        <ul className='tooltipList'>
            <ItemListComponent type='item' content={messages.HOPCovAValuationStandard} />
            <ItemListComponent type='item' content={messages.HOPCovAValuationVintage} />
            <ItemListComponent type='item' content={messages.HOPCovAValuationStdVintage} />
        </ul>
    ),
    // Cov A Roof Valuation Type
    HOPCovARoofValuation_Ext: messages.HOPCovARoofTypeValuation_Ext,
    // Other Residents Of Your Household
    HOPOtherResidentsOfHousehold_Ext: messages.HOPOtherResidentsOfHousehold_Ext,
    // Coverage for Resident of Assisted Living Facility  ?? find two coverage name in product designer, need to ask for confirm
    /* confirm with syed, this coverage name is for HOPCovForResidentOfAssisLivingFacility_Ext */
    // HOPAssistedLivingCare: messages.HOPAssistedLivingCare,
    HOPCovForResidentOfAssisLivingFacility_Ext:
        messages.HOPCovForResidentOfAssisLivingFacility_Ext,

    // Residence Under Construction - Theft
    HOPDwellingUnderConstruction: messages.HOPDwellingUnderConstruction,
    // Collapse Coverage Endorsement - New Dwellings Under Construction
    HOPCollapse: messages.HOPCollapse,
    // Office, Professional, Private School, Or Studio Occupancy – Property & Liability (PC.HO.5077)
    HOPOfficeProfessionalSchoolStudioOccupancyLocation_Ext:
        messages.HOPOfficeProfessionalSchoolStudioOccupancyLocation_Ext,
    // Higher Limits On Personal Property Used For Business Purposes
    HOPHiLmtOnPersonalPropUsedForBuzPurpose_Ext:
        messages.HOPHiLmtOnPersonalPropUsedForBuzPurpose_Ext,
    // Non-Owned Business Activities
    HOPNonOwnedBusinessActivities_Ext:
        messages.HOPNonOwnedBusinessActivities_Ext,
    // Computer Coverage
    HOPSpecialComputerCoverage: messages.HOPSpecialComputerCoverage,
    // Specified Additional Amount Of Insurance – Coverage A
    HOPSpecifiedAddlAmountOfInsurance_Ext:
        messages.HOPSpecifiedAddlAmountOfInsurance_Ext,
    // Additional Limits Coverage A, B, C, and D
    HOPAdditionalLimitsCoveragesABCD_Ext:
        messages.HOPAdditionalLimitsCoveragesABCD_Ext,
    // Functional Replacement Cost Loss Settlement Terms
    HOPFuncReplaceCostLoss_Ext: (
        <ul className='tooltipList'>
            <ItemListComponent
                content={messages.HOPFuncReplaceCostLoss_Ext_text1}
            />
            <ItemListComponent
                content={messages.HOPFuncReplaceCostLoss_Ext_text2}
            />
        </ul>
    ),
    // Functional Replacement Cost Loss Settlement Terms
    HOPFuncReplaceCostLossAddAmount_Ext: (
        <ul className='tooltipList'>
            <ItemListComponent
                content={messages.HOPFuncReplaceCostLoss_Ext_text1}
            />
            <ItemListComponent
                content={messages.HOPFuncReplaceCostLoss_Ext_text2}
            />
        </ul>
    ),
    // Modified Replacement Cost Terms
    HOPModifiedReplaceCostTerms_Ext: messages.HOPModifiedReplaceCostTerms_Ext,
    // Incidental Property Coverages – Higher Limits for Credit Card, Electronic Fund Transfer Card, Forgery, and Counterfeit Money
    HOPIncidentalPropHiLmt_Ext: messages.HOPIncidentalPropHiLmt_Ext,
    // Cyber Protection Insurance
    HOPCyberProtectionInsurance_Ext: messages.HOPCyberProtectionInsurance_Ext,
    // Earthquake Coverage
    HOPEarthquake: messages.HOPEarthquake,
    // Equipment Breakdown Endorsement
    HOPEquipBreakdownEnhanceEndorse_Ext:
        messages.HOPEquipBreakdownEnhanceEndorse_Ext,
    // Incidental Farming – Garden Plots
    HOPIncidentalFarming_Ext: messages.HOPIncidentalFarming_Ext,
    // Scheduled Motorized Golf Cart Coverage
    HOPSchedMotorGolfCartCov_Ext: messages.HOPSchedMotorGolfCartCov_Ext,
    // Golf Cart Liability Coverage
    HOPGolfCartLiabCov_Ext: messages.HOPGolfCartLiabCov_Ext,
    // Blanket Jewelry Coverage
    HOPBlanketJewelryCoverage_Ext: messages.HOPBlanketJewelryCoverage_Ext,

    // Identity Fraud Expense  ??? find two coverage name in product designer, need to ask for confirm
    /* confirm with syed, this coverage name is for HOPIdentityFraudExpense_Ext */
    // HOPIdTheft: "Provides coverage for expenses incurred as the result of identity fraud.",
    HOPIdentityFraudExpense_Ext: messages.HOPIdentityFraudExpense_Ext,

    // Loss Assessment Coverage
    HOPLossAssessmentSchedCov_Ext: messages.HOPLossAssessmentSchedCov_Ext,
    // Earthquake Loss Assessment
    HOPEarthquakeLossAssessment_Ext: messages.HOPEarthquakeLossAssessment_Ext,
    // Incidental Coverage For Motorized Vehicles – Low Power Vehicles
    HOPIncidentalCoverageForMotorizedVehiclesLP_Ext:
        messages.HOPIncidentalCoverageForMotorizedVehiclesLP_Ext,
    // Increased Cost - Ordinance Or Law - Increased Limit of Coverage
    HOPOrdinanceOrLaw: messages.HOPOrdinanceOrLaw,
    // Actual Cash Value - Related Private Structures
    HOPActualCashValueRelatedPrivateStructures_Ext:
        messages.HOPActualCashValueRelatedPrivateStructures_Ext,
    // Related Private Structures Rented
    HOPRelatedPrivateStructuresRentedToOthers_Ext:
        messages.HOPRelatedPrivateStructuresRentedToOthers_Ext,
    // Related Private Structures - Liability
    HOPRelatedPrivateStructuresDescribedLocation_Ext:
        messages.HOPRelatedPrivateStructuresDescribedLocation_Ext,
    // Scheduled Related Private Structures – Away From  The Described Location
    HOPSchedRelatedPrivateStructuresAwayFromDescripLocat_Ext:
        messages.HOPSchedRelatedPrivateStructuresAwayFromDescripLocat_Ext,
    // Personal Injury HOPCovLPersonalInjury
    HOPCovLPersonalInjury: messages.HOPPersonalInjury_Ext,
    // Coverage for Non-Resident Relative
    HOPCoverageForNonResidentRelative_Ext:
        messages.HOPCoverageForNonResidentRelative_Ext,
    // Personal Property Coverage for Designated Relative
    HOPPersonalPropertyCoverageForDesignatedRelative_Ext:
        messages.HOPPersonalPropertyCoverageForDesignatedRelative_Ext,

    // Replacement Cost Loss Settlement Terms - Personal Property   ?? find two coverage name in product designer, need to ask for confirm
    /* confirm with syed, this coverage name is for HOPReplacementCostLossSettlementTermsPersonalProperty_Ext */
    // HOPReplacementCostLossSettlementTerms_Ext: "Converts loss settlement terms for personal property and various miscellaneous items from actual cash value to replacement cost.",
    HOPReplacementCostLossSettlementTermsPersonalProperty_Ext:
        messages.HOPReplacementCostLossSettlementTermsPersonalProperty_Ext,

    // Special Personal Property Coverage
    HOPSpclPersonalPropCov_Ext: messages.HOPSpclPersonalPropCov_Ext,
    // Scheduled Personal Property Coverage
    HOPScheduledPersonalProperty: messages.HOPScheduledPersonalProperty,
    // Coverage C - Higher Limits On Certain Property
    HOPCovCHigherLmtOnCertainProp_Ext:
        messages.HOPCovCHigherLmtOnCertainProp_Ext,
    // Property in Rental Units - Increased Limit
    HOPPropertyInRentalUnitsIncreasedLimit_Ext:
        messages.HOPPropertyInRentalUnitsIncreasedLimit_Ext,
    // Extended Theft Coverage - Described Location Occasionally Rented To Others
    HOPExtendedResidenceTheft: messages.HOPExtendedResidenceTheft,
    // Additional Residence Rented to Others - Liability Coverage Only
    HOPAdditionalResidenceRentedToOthersLBCovOnly_Ext:
        messages.HOPAdditionalResidenceRentedToOthersLBCovOnly_Ext,
    // Roof Surfacing Amendment - Actual Cash value for Windstorm or Hail Loss
    HOPRoofSurfacingActualCash_Ext: messages.HOPRoofSurfacingActualCash_Ext,
    // Roof Surfacing Amendment - Actual Cash Value Terms For Windstorm Or Hail Loss Scheduled
    HOPRoofSurfacingActualCashSched_Ext:
        messages.HOPRoofSurfacingActualCashSched_Ext,
    // Matching of Undamaged Siding or Roofing Coverage
    HOPMactchingOfUndamagedSidingOrRoofingCov_Ext:
        messages.HOPMactchingOfUndamagedSidingOrRoofingCov_Ext,
    // Additional Residence Premises Occupied By Insured
    HOPAdditionalResidencePremisesOccupied_Ext:
        messages.HOPAdditionalResidencePremisesOccupied_Ext,
    // Underground Service Line Coverage
    HOPUndergroundServiceLineCoverage_Ext:
        messages.HOPUndergroundServiceLineCoverage_Ext,
    // Water Back Up and Sump Dischargeor Overflow Coverage
    HOPBackupSewersDrainsPump: messages.HOPBackupSewersDrainsPump,
    // Incidental Coverage For Motorized Vehicles Not To Exceed 20 MPH
    HOPIncidentalCovForMotorVehicleUnder20MPH_Ext: messages.HOPIncidentalCovForMotorVehicleUnder20MPH_Ext,
    // can't find in producet desginer and can't find in PC and portal ??? need to ask for confirm
    HOPScheduledPersonalPropertyItemAgreedValue_Ext:
        messages.HOPScheduledPersonalPropertyItemAgreedValue_Ext,

    /** Incidental Property - Higher limits: 4 options */
    // Credit Card; Electronic Fund Transfer Card Or Access Device; Forgery; And Counterfeit Money
    z11giqu03qblnb06i3176iu4mf9: messages.z11giqu03qblnb06i3176iu4mf9,
    // Fire Department Service Charge
    zkqgedm1v5ogi0vpubjdm77rvr8: messages.zkqgedm1v5ogi0vpubjdm77rvr8,
    // Refrigerated Property
    z6kggvgf69bhnchbalhksikg4e8: messages.z6kggvgf69bhnchbalhksikg4e8,
    // Tenant’s Improvements
    zffismojuk7q3c0c8d5v3eu0ks8: messages.zffismojuk7q3c0c8d5v3eu0ks8,

    // }
};

export default HOTooltips;
