import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { WniAccountsUtil } from 'wni-portals-util-js';
import CompanyComponent from './PrimaryNamedInsuredInputPopup.company'
import PersonComponent from './PrimaryNamedInsuredInputPopup.person'
import messages from './PrimaryNamedInsuredInputPopup.messages';

function PrimaryNamedInsuredInputPopup(props) {
    const {
        primaryNamedInsuredVM,
        DBAsVM,
        isReadOnly,
        accountContacts,
        authHeader,
        sessionUUID,
        jobID,
        policyDetailsService,
        policyDetailPath,
        viewModelService,
    } = props;
    const translator = useTranslator();

    const overrideProps = {
        dbaCard: {
            title: translator(messages.dba),
            visible: false
        },
        dbaTableContainer: {
            model: DBAsVM,
            isReadOnly,
            accountContacts,
            currentPcr: primaryNamedInsuredVM,
            // onValueChange,
            // updateCurrentPcr: updateCurrentRow,
            policyDetailsService,
            authHeader,
            sessionUUID,
            policyDetailPath,
            jobID,
            viewModelService,
            source: 'PNI'
        }
    };

    const contactType = _.get(primaryNamedInsuredVM.value, 'contactType_Ext')
    
    if (WniAccountsUtil.isCLAccount(contactType)){
        _.set(overrideProps, 'dbaCard.visible', true);
        return <CompanyComponent
            {...props}
            commonOverrideProps = {overrideProps}
        />
    }
        return <PersonComponent
            {...props}
            commonOverrideProps = {overrideProps}
        />

}
PrimaryNamedInsuredInputPopup.propTypes = {
    primaryNamedInsuredVM: PropTypes.shape({}).isRequired,
    policyNamedInsuredCandidatesVM: PropTypes.shape({
        getElement: PropTypes.func.isRequired,
    }).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    //
    readOnly: PropTypes.bool,
    disablePNISwitch: PropTypes.bool,
    showEmployeeFlagToggle: PropTypes.bool,
};

PrimaryNamedInsuredInputPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md',
    //
    readOnly: false,
    disablePNISwitch: false,
    showEmployeeFlagToggle: true,
};

export default withAuthenticationContext(PrimaryNamedInsuredInputPopup);
