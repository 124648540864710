import _ from 'lodash';
import { WniDateUtil } from 'wni-portals-util-js'
import { WizardConstants } from 'wni-portals-config-js';
import ConfigUtil from './ConfigUtil';
/**
 * Is the Driver LicenseNumber validated according to licenseState related rules
 * @param {object} driverVM driverVm
 * @returns {boolean} true if invalid, false if valid
 */
function validateLicenseNumber(driverVM) {
    const licenseState = _.get(driverVM, 'licenseState.value.code');
    const licenseNumber = _.get(driverVM, 'licenseNumber.value') || '';
    switch (licenseState) {
        case 'WI':
            return licenseNumber.length !== 14;
        case 'AZ':            
        case 'IA':
            return licenseNumber.length !== 9;
        case 'SD':
            return licenseNumber.length !== 8;
        case 'AK':
            return licenseNumber.length !== 7;
        case 'MN':
            return licenseNumber.length !== 13;           
        default:
            return false;
    }
}

function onMaritalStatusCell(item, translator) {
    const maritalStatus = _.get(item, 'person.maritalStatus');
    // const maritalStatusMap = {
    //     D: 'Divorced',
    //     C: 'Domestic partner',
    //     M: 'Married',
    //     P: 'Separated',
    //     S: 'Single',
    //     U: 'Unknown',
    //     W: 'Widowed',
    // };
    // if (item.person) {
    //     return maritalStatusMap[maritalStatus];
    // }
    // return '';
    if (maritalStatus) {
        return translator({ id: `typekey.MaritalStatus.${maritalStatus}` }) || '';
    }
    return '';
}

function onRelationshipCell(item, translator) {
    const relationship = _.get(item, 'relationshipToInsured_Ext');

    // const relationshipMap = {
    //     Insured_Ext: 'Insured',
    //     Spouse_Ext: 'Spouse',
    //     Partner_Ext: 'Partner',
    //     Child_Ext: 'Child',
    //     Parent_Ext: 'Parent',
    //     Employee_Ext: 'Employee',
    //     Other_Ext: 'Other',
    //     SIBLING_Ext: 'Sibling'
    // };
    // return relationshipMap[relationship] || relationship;
    if (relationship) {
        return translator({ id: `typekey.Relationship.${relationship}` }) || '';
    }
    return '';
}

function onGenderCell(item, translator) {
    // const genderMap = {
    //     M: 'Male',
    //     F: 'Female',
    //     O: 'Other'
    // };
    // if (item.gender_Ext) {
    //     return genderMap[item.gender_Ext];
    // }
    // return '';
    if (item.gender_Ext) {
        return translator({ id: `typekey.GenderType.${item.gender_Ext}` }) || '';
    }
    return '';
}

function onNameCell(item) {
    const lastName = _.get(item, 'lastName') || '';
    const firstName = _.get(item, 'firstName') || '';

    return `${firstName} ${lastName}`;
}

function onDateOfBirthCell(item) {
    // eslint-disable-next-line no-param-reassign
    item.dateOfBirth = item.dateOfBirth || item.person.dateOfBirth || undefined;
    if (!item.dateOfBirth) {
        return '';
    }
    if (item.initIsADPDOB_Ext) {
        return '**';
    }
    return WniDateUtil.formatDateWithPattern(item.dateOfBirth);
}

function onRatedDriverCell(item, periodStatus) {
    if (periodStatus === 'Draft') {
        return '';
    }
    if (item.driversLicenseStatus_Ext) {
        if (!item.driverCovered_Ext && item.driverCoveredReason_Ext === 'excluded') {
            return 'Excluded';
        }
        if (item.driversLicenseStatus_Ext === 'valid' && item.driverCovered_Ext) {
            return 'Yes';
        }
        return 'No';
    }
    return '';
}

function onLicensedCell(item) {
    const val = _.get(item, 'licensedDriver_Ext');
    if (val === false) {
        return 'No';
    }
    if (val === true) {
        return 'Yes';
    }
    return '';
}

function truncatedString(obj, path) {
    const str = _.get(obj, path);
    if (str) {
        const numStr = str.replace(/[^0-9]/ig, '');
        _.set(obj, path, numStr.substring(numStr.length - 10));
    }
}

function truncatedPhoneNumber(driverObj) {
    truncatedString(driverObj, 'person.workNumber');
    truncatedString(driverObj, 'person.homeNumber');
    truncatedString(driverObj, 'person.cellNumber');
}

function updatePolicyCountryForDriver(driverVM, countryCode) {
    if (_.isEmpty(driverVM) || _.isEmpty(countryCode)) {
        return;
    }
    _.set(driverVM, 'policyCountry_Ext', countryCode);
}

function getAge(driverVM, baseData) {
    const driverDateOfBirth = _.get(driverVM, 'dateOfBirth.value') || {};
    const effDate = _.get(baseData, 'effectiveDate_Ext') || _.get(baseData, 'effectiveDate');
    const strAge = `${driverDateOfBirth.year}-${driverDateOfBirth.month}-${driverDateOfBirth.day}`;
    const birArr = strAge.split('-');
    const birYear = Number(birArr[0]);
    const birMonth = Number(birArr[1]);
    const birDay = Number(birArr[2]);

    // const nowD = new Date();
    // const nowYear = nowD.getFullYear();
    // const nowMonth = nowD.getMonth() + 1;
    // const nowDay = nowD.getDate();

    const nowYear = effDate.year;
    const nowMonth = effDate.month + 1;
    const nowDay = effDate.day;
    let returnAge;

    if (birArr == null) {
        return false;
    }
    const d = new Date(birYear, birMonth, birDay);
    if (d.getFullYear() === birYear && d.getMonth() === birMonth && d.getDate() === birDay) {
        if (nowYear === birYear) {
            returnAge = 0;
        } else {
            const ageDiff = nowYear - birYear;
            if (ageDiff > 0) {
                if (nowMonth === birMonth + 1) {
                    const dayDiff = nowDay - birDay;
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    const monthDiff = nowMonth - birMonth;
                    if (monthDiff <= 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                }
            }
        }
        return returnAge;
    }
    return false;
}

function isChild(driverVM, baseData) {
    if (getAge(driverVM, baseData) === false) {
        return null;
    }
    if (_.get(baseData, 'baseState_Ext') === 'SD') {
        if (getAge(driverVM, baseData) < 14) {
            return true;
        }
    } else if (getAge(driverVM, baseData) < 16) {
        return true;
    }
    return false;
}

function setDefaultQuestionSets(questionSets) {
    _.set(questionSets, '[0].answers.DriverWNEmployeeDiscountYN_Ext', false);
    _.set(questionSets, '[0].answers.DriverAnyCurFinResFilingYN_Ext', null);
    _.set(questionSets, '[0].answers.DriverFurnishedVehicleCompany_Ext', null);
    _.set(questionSets, '[0].answers.DriverIsCompanyVehFurnishedYN_Ext', false);
    _.set(questionSets, '[0].answers.DriverLicSuspLastFiveYearsYN_Ext', null);

    return questionSets;
}
// function getUuid() {
//     // eslint-disable-next-line no-restricted-globals
//     return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
// }

function setDefaultDriverObj(questionSets) {
    const res = {
        isAdd: true,
        accidents: '0',
        violations: '0',
        isPolicyHolder: true,
        person: {
            primaryPhoneType: 'mobile'
        },
        driverQuestionSets_Ext: setDefaultQuestionSets(questionSets),
        rowIdPath: ConfigUtil.getUuid()
    };
    _.unset(res, 'publicID');
    return res;
}

function setVehFurnishedQuestionSets(questionSets) {
    if (!_.get(questionSets, '[0].answers.DriverIsCompanyVehFurnishedYN_Ext')) {
        _.set(questionSets, '[0].answers.DriverIsCompanyVehFurnishedYN_Ext', false);
    }

    return questionSets;
}

function hasInvalidDriver(
    driversVM, onFindInvalideDriver = null, onCopyInvalideDriver = null, viewModelService = null
) {
    const retval = driversVM.children.some((item) => {
        if (!item.aspects.valid || !item.aspects.subtreeValid) {
            if (onFindInvalideDriver) {
                onFindInvalideDriver(item);
            }
            if (onCopyInvalideDriver && viewModelService) {
                const copyDriverVM = viewModelService.clone(item);
                onCopyInvalideDriver(copyDriverVM);
            }
            return true;
        }
        return false;
    });
    return retval;
}

function getAvailableCoveredReason(availableValues = [], baseState, translator) {
    const options = availableValues.map((option) => {
        return {
            code: option.code,
            name: translator({
                id: option.name
            })
        };
    });
    let res = [];
    if (baseState === 'AK' || baseState === 'SD' || baseState === 'AZ') {
        res = _.cloneDeep(options);
    } else {
        res = options.filter((option) => {
            return option.code !== 'excluded';
        });
    }
    return res;
}

function getQuestionMapper(translator, messages) {
    return (metaDataContent) => {
        if (metaDataContent.id === 'DriverAnyCurFinResFilingYN_Ext') {
            _.set(metaDataContent, 'componentProps.label', translator(messages.driverAnyCurFinResFilingYNMsg));
        }
        return metaDataContent;
    };
}

function setInexperiencedDriver(ratedAge, driverVM) {
    const {
        dateOfBirth,
        ageFirstLicensed_Ext: ageFirstLicensed,
    } = driverVM.value || {};
    const year = _.parseInt(_.get(dateOfBirth, 'year')) + _.parseInt(ageFirstLicensed);
    // const resDateFirstLicensed = `${year}-${_.get(dateOfBirth, 'month') + 1}-${_.get(dateOfBirth, 'day')}`;
    const resDateFirstLicensed = {
        year: year,
        month: _.get(dateOfBirth, 'month'),
        day: _.get(dateOfBirth, 'day'),
    };
    if (ageFirstLicensed) {
        if (ratedAge - ageFirstLicensed < 4 && ratedAge - ageFirstLicensed >= 0) {
            _.set(driverVM, 'inexperiencedDriver_Ext', true);
            // _.set(driverVM, 'dateFirstLicensed_Ext', new Date(resDateFirstLicensed));
            _.set(driverVM, 'dateFirstLicensed_Ext', resDateFirstLicensed);
        } else {
            _.set(driverVM, 'inexperiencedDriver_Ext', false);
            if (ratedAge > ageFirstLicensed) {
                // _.set(driverVM, 'dateFirstLicensed_Ext', new Date(resDateFirstLicensed));
                _.set(driverVM, 'dateFirstLicensed_Ext', resDateFirstLicensed);
            } else {
                _.set(driverVM, 'dateFirstLicensed_Ext', null);
            }
        }
    }
}

function isRequiredForIssuance(baseData = {}, wizardPageData) {
    const {
        quoteFlow_Ext: quoteFlow,
        submittingQuote_Ext: submittingQuote,
        jobType
    } = baseData;
    const isQuoteExecuted = !_.isNil(wizardPageData[WizardConstants.policyDiffData]);
    const isRequiredForIssuanceFlag = (quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote) || (jobType === 'PolicyChange' && isQuoteExecuted);
    return isRequiredForIssuanceFlag;
}

function getAvailableRelationship(availableValues = [], translator) {
    const options = availableValues.map((option) => {
        return {
            code: option.code,
            name: translator({
                id: option.name
            })
        };
    });

    const res = options.filter((opt) => {
        return opt.code !== 'Child_Under_12_Ext' && opt.code !== 'Child_Over_12_Ext'
    });
    return res;
}

export default {
    validateLicenseNumber,
    onMaritalStatusCell,
    onRelationshipCell,
    onGenderCell,
    onNameCell,
    onDateOfBirthCell,
    onRatedDriverCell,
    onLicensedCell,
    truncatedPhoneNumber,
    updatePolicyCountryForDriver,
    getAge,
    isChild,
    setDefaultDriverObj,
    setVehFurnishedQuestionSets,
    hasInvalidDriver,
    setDefaultQuestionSets,
    getAvailableCoveredReason,
    getQuestionMapper,
    setInexperiencedDriver,
    isRequiredForIssuance,
    getAvailableRelationship
};
