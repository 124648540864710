import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ConfirmationPage.metadata.json5';
import messages from '../../FNOL.messages';
import styles from './ConfirmationPage.module.scss';

function ConfirmationPage(props) {
    const {
        history: { location: { state } },
        match: {
            params: { claimNumber }
        }
    } = props;
    const translator = useTranslator();

    const handlePrint = useCallback((e) => {
        e.preventDefault();
        window.print();
    }, []);

    const overrideProps = useMemo(() => ({
        claimReferenceNumber: {
            visible: !!claimNumber,
            content: translator(messages.claimNumber, { num: claimNumber })
        },
        linkID: {
            content:
                appConfig.persona === 'policyholder'
                    ? messages.fnolBackToHome
                    : messages.fnolBackToClaims,
            to: appConfig.persona === 'policyholder'
                ? '/home' : {
                    pathname: _.get(state, 'redirectPath'),
                    state: 'claims'
                }
        }
    }), [claimNumber, state, translator]);

    const resolvers = {
        resolveCallbackMap: {
            handlePrint: handlePrint
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ConfirmationPage.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                redirectPath: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            claimNumber: PropTypes.string.isRequired
        })
    }).isRequired
};

export default withRouter(ConfirmationPage);
