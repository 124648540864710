
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';


import { CRModifiersReadOnlyPage } from 'wni-capability-quoteandbind-cr-react';

function CRModifiersChangeReadOnlyPage(props) {

    return (
        <CRModifiersReadOnlyPage {...props} />
    );

};


export default CRModifiersChangeReadOnlyPage;