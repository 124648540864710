import React, {
    useContext,
} from "react";
import _ from 'lodash'
import { WniDropdownSelect } from "wni-common-base-components";
import CoveragesConfigContext from "../../../../../context/WCCoveragesConfigContext";
import SingleClauseContext from "../../../../../context/WCSingleClauseContext";
import { ValuePath } from "../../../../../util/WCScheduleItemsUtil";

function formatAvailableValues(availableValues) {
    return _.sortBy(availableValues, 'priority')
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.name
        }));
}

const WCComplexSchedulePopupPropertyInfoDropdown = (props) => {

    const {
        propertyInfo,
        openedScheduleItem,
        openedScheduleItemIndex,
        updateSubmissionVMToServer,
        showErrors,
    } = props

    const {
        id: propertyInfoId,
        label,
        required,
    } = propertyInfo;

    const {
        itemNumber,
        itemData: {
            [propertyInfoId]: itemDataField
        }
    } = openedScheduleItem
    const {
        options_Ext: allOptions = [],
        [ValuePath.TypeCodeValue]: value
    } = itemDataField
    const coveragesConfig = useContext(CoveragesConfigContext);


    const {
        clauseCode,
        onChangeClause,
        clausePath: clauseVMPath,
        isEditable,
    } = useContext(SingleClauseContext)
    const {
        scheduleConfig: {
            optionFilters: {
                [clauseCode]: optionFilter = {},
            },
            fieldsNotNeedSyncWhileChange: {
                [clauseCode]: fieldsNotNeedSync = []
            }
        }
    } = coveragesConfig;

    const notNeedSyncImmediately = fieldsNotNeedSync.includes(propertyInfoId)

    const optionFilterForCurrentField = _.get(optionFilter, propertyInfoId)
    const optionValues = _.isNil(optionFilterForCurrentField) ? _.sortBy(allOptions, ['code', 'name'])
        : optionFilterForCurrentField(openedScheduleItem, _.sortBy(allOptions, ['code', 'name']));

    const availableValues = formatAvailableValues(optionValues)

    const onDropdownValueChange = (newValue) => {
        const newItemDataField = {
            ...itemDataField,
            [ValuePath.TypeCodeValue]: newValue,
            updated_Ext: true
        };
        const path = `${clauseVMPath}.schedule.scheduleItems[${openedScheduleItemIndex}].itemData.${propertyInfoId}`

        const updatedSubVMInPE = onChangeClause(newItemDataField, path)
        if (notNeedSyncImmediately) {
            return
        }
        updateSubmissionVMToServer(updatedSubVMInPE)
    }

    return <WniDropdownSelect
        id = {`${propertyInfoId}_${itemNumber}`}
        dataType = 'string'
        label={label}
        required = {required}
        showRequired = {required}
        availableValues = {availableValues}
        value = {value}
        onValueChange = {onDropdownValueChange}
        showErrors = {showErrors}
        readOnly={!isEditable}
        searchable = {label === "Class Code"}
    />
}

export default WCComplexSchedulePopupPropertyInfoDropdown