
import React, {
    useContext, useState, useEffect, useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WniQuoteNoteService } from 'wni-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import messages from './NoteUWInputComponent.messages';

import metadata from './NoteUWInputComponent.metadata.json5';
import styles from './NoteUWInputComponent.module.scss';

const NoteUWInputComponent = (props) => {
    const {
        canUWIssueStatus,
        quoteID,
        // WniQuoteNoteService,
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [showUWReferLink, setShowUWReferLink] = useState(false);
    const [showNoteAddContainer, setShowNoteAddContainer] = useState(false);
    const [showNoteListContainer, setShowNoteListContainer] = useState(false);
    const [showNoteRes, setShowNoteRes] = useState([]);
    const [isAddBtn, setAddBtn] = useState(true);
    const [newModalVM, updateNewModalVM] = useState({});

    const referralNoteLink = () => {
        setShowNoteAddContainer(true);
        setShowUWReferLink(false);
        setShowNoteListContainer(false);
    };

    const cancelNote = () => {
        setShowUWReferLink(true);
        setShowNoteAddContainer(false);
        setShowNoteListContainer(false);
    };


    const addNote = () => {
        WniQuoteNoteService.saveQuoteNote(quoteID, newModalVM.value, authHeader).then((noteResponse) => {
            setShowNoteAddContainer(false);
            setShowNoteListContainer(true);
            setShowNoteRes(noteResponse);
            _.set(newModalVM, 'body', '');
            setAddBtn(true);
        });
    };

    const deleteNote = (note) => {
        WniQuoteNoteService.deletQuoteNote(quoteID, note.publicID, authHeader).then((noteResponse) => {
            if (noteResponse.length > 0) {
                setShowUWReferLink(false);
                setShowNoteAddContainer(false);
                setShowNoteListContainer(true);
                setShowNoteRes(noteResponse);
            } else {
                setShowUWReferLink(false);
                setShowNoteAddContainer(true);
                setShowNoteListContainer(false);
                setShowNoteRes(noteResponse);
            }
        });
    };

    const getNotesToshowContainer = () => {
        WniQuoteNoteService.getQuoteNoteData(quoteID, authHeader).then((noteResponse) => {
            if (noteResponse.length > 0) {
                setShowUWReferLink(false);
                setShowNoteAddContainer(false);
                setShowNoteListContainer(true);
                setShowNoteRes(noteResponse);
            } else {
                setShowUWReferLink(true);
                setShowNoteAddContainer(false);
                setShowNoteListContainer(false);
            }
        });
    };

    useEffect(() => {
        if (canUWIssueStatus) {
            const modalVM = viewModelService.create({}, 'pc', 'wni.edge.capabilities.note.dto.QuoteNoteDTO');
            if (newModalVM !== modalVM) {
                updateNewModalVM(modalVM);
            }
            getNotesToshowContainer();
        }
    }, [canUWIssueStatus]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        addReferralNote: {
            visible: showUWReferLink,
        },
        noteAddContainer: {
            visible: showNoteAddContainer
        },
        noteListContainer: {
            visible: showNoteListContainer
        },
        quoteNoteTable: {
            data: showNoteRes,
        },
        deleteNote: {
            label: translator(messages.deleteLabel),
        },
        iconUwAddButton: {
            disabled: isAddBtn
        }

    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            newModalVM,
            id,
            path,
            overrideProps
        );
    };

    const writeValue = useCallback(
        (value, path) => {
            const getNewModalVM = viewModelService.clone(newModalVM);
            _.set(getNewModalVM, path, value);
            updateNewModalVM(getNewModalVM);
            if (value !== '') {
                setAddBtn(false);
            } else {
                setAddBtn(true);
            }
        },
        [newModalVM, viewModelService]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onClickReferralNote: referralNoteLink,
            onAddNote: addNote,
            onCancelNote: cancelNote,
            onDeleteNote: deleteNote
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

NoteUWInputComponent.propTypes = {
    canUWIssueStatus: PropTypes.bool,
    quoteID: PropTypes.string,
    // WniQuoteNoteService: PropTypes.shape({
    //     saveQuoteNote: PropTypes.func,
    //     deletQuoteNote: PropTypes.func,
    //     getQuoteNoteData: PropTypes.func,
    // }),
    // WniQuoteNoteService: PropTypes.func,
};

NoteUWInputComponent.defaultProps = {
    canUWIssueStatus: false,
    quoteID: '',
    // WniQuoteNoteService: undefined,
};

export default NoteUWInputComponent;
