
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WizardConstants } from 'wni-portals-config-js';

import { GLLocationsReadOnlyPage } from 'wni-capability-quoteandbind-gl-react';

function GLLocationsChangeReadOnlyPage(props) {

    return (
        <GLLocationsReadOnlyPage {...props} />
    );

}

GLLocationsChangeReadOnlyPage.propTypes = GLLocationsReadOnlyPage.propTypes;
GLLocationsChangeReadOnlyPage.defaultProps = GLLocationsReadOnlyPage.defaultProps;

export default GLLocationsChangeReadOnlyPage;