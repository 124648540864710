import React, {
    // useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import {
    CAUnderwritingQuestionsService
} from 'wni-capability-quoteandbind-ca';

import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import WizardPage from '../../templates/CAWizardPage';

import styles from './CAUnderwritingQuestionsPage.module.scss';
import metadata from './CAUnderwritingQuestionsPage.metadata.json5';
// import messages from './WCPolicyDetailsPage.messages';
import caQuestionSetsUtil from './utils/CAQuestionSetsUtil';

function CAUnderwritingQuestionsPage(props) {

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        saveUnderwritingQuestionsData,
        //
        //
        // onPreQuotePageNext,
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
    } = submissionVM.value;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);


    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CAUnderwritingQuestionsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);

    const CAUnderwritingQuestionsValidation = useCallback(()=> {
        const ca7IfYesWhatPercentage = _.get(submissionVM.value, 'lobData.commercialAuto.underwritingQuestionSets[0].answers.CA7IfYesWhatPercentage_Ext')
        return (ca7IfYesWhatPercentage <= 100 && ca7IfYesWhatPercentage >= 0 )
    
    }, [submissionVM.value])

    useEffect(() => {
        registerComponentValidation(CAUnderwritingQuestionsValidation);
    }, [CAUnderwritingQuestionsValidation, registerComponentValidation]);
    
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );

    const onPageNext = useCallback(async () => {


        setLoadingMask(true);
        const caUWQuestionsData = _.get(submissionVM.value, 'lobData.commercialAuto.underwritingQuestionSets')
        
        const res = await saveUnderwritingQuestionsData(
            quoteID, 
            sessionUUID,
            caUWQuestionsData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        
        updateSubmissionVM(submissionVM);
        
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask, submissionVM, saveUnderwritingQuestionsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);


    const questionSetMapper = caQuestionSetsUtil.getQSMapperForCaUnderwritingQuestions()
    const questionSetFilter = caQuestionSetsUtil.getQSFilterForCaUnderwritingQuestions()

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            policyUnderwritingQuestionSets: {
                contentFilter: questionSetFilter,
                contentMapper: questionSetMapper,
                onValidate
            }
        }
    }, [onValidate]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAUnderwritingQuestionsPage.propTypes = {
    ...WizardPage.propTypes,
    saveUnderwritingQuestionsData: PropTypes.func
};

CAUnderwritingQuestionsPage.defaultProps = {
    ...WizardPage.defaultProps,
    saveUnderwritingQuestionsData: CAUnderwritingQuestionsService.saveCAUnderwritingQuestionsData
} 
export default CAUnderwritingQuestionsPage;