import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMAccountsReceivableService } from 'wni-capability-quoteandbind-im';
import messages from './ExclusionAccordionCard.messages'
import AccordionCardTitle from '../../../../components/IMClauseAccordionCardTitle'
import IMCoverageUtil from '../../../../util/IMCoverageUtil';
import CoverageContext from '../../../../context/IMCoverageContext';
import IMSingleClauseComponentVM from '../../../../components/IMSingleClauseComponentVM';
import IMClausesComponentVM from '../../../../components/IMClausesComponentVM';

const ExclusionAccordionCard = (props) => {

    const {
        coveragePartClauses,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        onClickAddSearchClauses,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { exclusion } = coveragePartClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();
    const clauseType = 'exclusions';
    const validationID = 'AccountsReceivableExclusionAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])


    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = IMCoverageUtil.setClauseValue(exclusion, 'exclusion', value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, 'exclusion', newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, exclusion, setCoveragePartClauses, setIsEditing]
    );

    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        const coveragePartClausesToUpdate = IMCoverageUtil.generateUpdatedAccountReceivableClausesDTO(newCoveragePartClauses);
        setLoadingMask(true)
        const coveragePartClausesFromServer = await IMAccountsReceivableService.updateAccountsReveivableClauses(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCoveragePartClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);



    const changeClauseAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeSubmission(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeSubmission, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    return (
        <AccordionCard
            id="ExclusionCoverages"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.AdditionalExclusions)}
                    actionText={messages.AddExclusions}
                    onClick={() => onClickAddSearchClauses(clauseType)}
                /> : translator(messages.AdditionalExclusions)}
            errorState={showErrors && !isComponentValid}
        >
            <h4>{translator(messages.AddedExclusions)}</h4>
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <IMClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeClauseAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        path="exclusion"
                        value={exclusion}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            IMSingleClauseComponentVM: IMSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    )
}

export default ExclusionAccordionCard