import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/dpaddint'), method, data, additionalHeaders);
}

export default class DPAddIntService {
    // /**
    //  * Invokes HOAdditionalInterestsHandler.methodName()
    //  * is disabled.
    //  * @param {object} data the QuoteDataDTO json obj
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call
    //  */
    // static methodName(data, authHeader = {}) {
    //     return processSubmission('methodName', [data], authHeader);
    // }

    static createOrUpdateAdditionalInterest(jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO, authHeader = {}) {
        return processSubmission('createOrUpdateAdditionalInterest', [jobID, sessionUUID, toCreateOrUpdateAdditionalInterestDTO], authHeader);
    }

    static getAdditionalInterestType(quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, productCode, authHeader) {
        return processSubmission('getAdditionalInterestType', [quoteID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, productCode], authHeader);
    }

    static removeAdditionalInterest(jobID, sessionUUID, selection, authHeader) {
        return processSubmission('removeAdditionalInterest', [jobID, sessionUUID, selection], authHeader);
    }

    static getAdditionalInterestInsuredType(jobID, sessionUUID, authHeader) {
        return processSubmission('getAdditionalInterestInsuredType', [jobID, sessionUUID], authHeader);
    }
    
}
