import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import React, {
    useState,
    useEffect,
    useCallback,
    useContext
} from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './CommonScheduleItemForm.messages';
import IMCoverageUtil from '../../../util/IMCoverageUtil';
import IMScheduleDetailsPropertyField from "../../IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsPropertyField";
import IMScheduleDetailsCovTermField from "../../IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsCovTermField";
// eslint-disable-next-line import/no-cycle
import CommonCoveragePartSchedule from '../CommonCoveragePartSchedule/CommonCoveragePartSchedule';
import CoveragesConfigContext from '../../../context/IMCoveragesConfigContext';

const ColumnType = {
    PropertyInfo: 'PropertyInfo',
    CoveragePropertyInfo: 'CoveragePropertyInfo'
}

const CommonScheduleItemForm = (props) => {
    const [showErrors, setShowErrors] = useState(false)
    const {
        id,
        jobID,
        sessionUUID,
        propertyInfos,
        coveragePropertyInfos,
        scheduleItem: originScheduleItem = {},
        handleScheduleItemCancel,
        handleSaveScheduleItem,
        handleSaveAdditionalInterest,
        isEditable,
        onValidate,
        scheduleId,
        setIsEditing,
        scheduleFormHeader,
        onChangeScheduleItem,
        stackOfOpenScheduleID,
        setStackOfOpenedScheduleID,
        updateAdditionalIntestSchedule,
        saveButtonLabel,
    } = props;

    const {
        scheduleConfig
    } = useContext(CoveragesConfigContext)

    const visibleColumnIdsOverride = _.get(scheduleConfig, `visibleColumnIdsOverride.${scheduleId}`);
    const columnOrderOverride = _.get(scheduleConfig, `columnOrderOverride.${scheduleId}`)

    const {
        '@deserialization-class': deserializationClass,
        publicId_Ext: scheduleItemPublicID
    } = originScheduleItem

    const isAdditionalInterestScheduledItem = deserializationClass === 'wni.edge.capabilities.policyjob.lob.inlandmarine.coverages.dto.IMAdditionalInterestScheduledItemDTO'
    const { onValidate: onValidationChange, isComponentValid, registerComponentValidation } = useValidation(id);
    const [scheduleItem, setScheduleItem] = useState(originScheduleItem);

    // Change schedule Item when switch scheduleItem
    useEffect(() => {
        setScheduleItem(originScheduleItem)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleItemPublicID])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, scheduleId);
        }
        return () => {
            if (onValidate) {
                onValidate(true, scheduleId);
            }
        }
    }, [onValidate, scheduleItem, propertyInfos, scheduleId, isComponentValid]);

    const registerFieldValidation = useCallback(() => {
        return !IMCoverageUtil.isScheduleItemFormInvalid(scheduleItem, propertyInfos);
    }, [propertyInfos, scheduleItem])

    useEffect(() => {
        registerComponentValidation(registerFieldValidation)
    }, [registerFieldValidation, registerComponentValidation]);

    const setScheduleItemFunc = (setPropertyFieldFunc) => {
        const newScheduleItem = setPropertyFieldFunc(scheduleItem)
        setScheduleItem(newScheduleItem)
    }

    const propertyInfoWithWrappers = propertyInfos
        .filter((propertyInfo) => {
            if (propertyInfo.id === 'AdditionalInsured') {
                return false
            }
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(propertyInfo.id)
            }
            return true
        })
        .map(propertyInfo => ({
            id: propertyInfo.id,
            columnType: ColumnType.PropertyInfo,
            columnInfo: propertyInfo,
            order: propertyInfo.order,
        }))

    const coveragePropertyInfoWithWrappers = coveragePropertyInfos
        .filter((coveragePropertyInfo) => {
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(coveragePropertyInfo.id)
            }
            return true
        })
        .map(coveragePropertyInfo => ({
            id: coveragePropertyInfo.id,
            columnType: ColumnType.CoveragePropertyInfo,
            columnInfo: coveragePropertyInfo,
            order: coveragePropertyInfo.order,
        }))

    const fieldComponents = propertyInfoWithWrappers
        .concat(coveragePropertyInfoWithWrappers)
        .sort((a, b) => {
            if (columnOrderOverride) {
                const aOrder = _.get(columnOrderOverride, `${a.id}`, a.order)
                const bOrder = _.get(columnOrderOverride, `${b.id}`, b.order)
                return aOrder - bOrder
            }
            // By default, all propertyInfo should display before coverageProeprtyInfo
            if (a.columnType !== b.columnType) {
                return a.columnType === ColumnType.PropertyInfo ? -1 : 1
            }
            return a.order - b.order
        })
        .map((infoWithWrapper => {
            if (infoWithWrapper.columnType === ColumnType.PropertyInfo) {
                const { columnInfo: propertyInfo } = infoWithWrapper

                const propertyInfoID = propertyInfo.id
                const propertyInfoItemData = scheduleItem.itemData[propertyInfoID]

                return <IMScheduleDetailsPropertyField
                    id={propertyInfoID}
                    key={propertyInfoID}
                    setScheduleItemFunc={setScheduleItemFunc}
                    propertyInfo={propertyInfo}
                    onValidate={onValidationChange}
                    propertyInfoItemData={propertyInfoItemData}
                    showErrors={showErrors}
                    isEditable={isEditable}
                    setIsEditing={setIsEditing}
                />
            }
            if (infoWithWrapper.columnType === ColumnType.CoveragePropertyInfo) {

                const { columnInfo: termPropertyInfo } = infoWithWrapper

                const termCode = termPropertyInfo.id
                const term = scheduleItem.scheduleItemTerms
                    .find(t => t.code_Ext === termCode)
                if (_.isNil(term)) {
                    return null;
                }
                return <IMScheduleDetailsCovTermField
                    key={termCode}
                    term={term}
                    setScheduleItemFunc={setScheduleItemFunc}
                    onValidate={onValidationChange}
                    showErrors={showErrors}
                    isEditable={isEditable}
                    setIsEditing={setIsEditing}
                    onChangeScheduleItem={onChangeScheduleItem}
                />
            }

            return null
        }))


    const handleSave = useCallback((currentScheduleItem) => {
        if (!isComponentValid) {
            setShowErrors(true)
            return false
        }
        handleSaveScheduleItem(currentScheduleItem)
    }, [handleSaveScheduleItem, isComponentValid])

    const openedScheduleItemID = stackOfOpenScheduleID.at(-1)

    return (
        <>
            <h4>{scheduleFormHeader}</h4>
            {/* {propertyFieldComponents}
            {termFieldComponents} */}
            {fieldComponents}
            {isAdditionalInterestScheduledItem && <CommonCoveragePartSchedule
                scheduleId={_.get(scheduleItem, 'scheduledAdditionalInterest.schedulePatternCode_Ext')}
                jobID={jobID}
                sessionUUID={sessionUUID}
                setCoveragePartClauses={(newScheduleItem) => {
                    // Only update additional interest part

                    setScheduleItem({
                        ...scheduleItem,
                        scheduledAdditionalInterest: newScheduleItem.scheduledAdditionalInterest
                    })
                    handleSaveAdditionalInterest(newScheduleItem)
                }}
                setIsEditing={setIsEditing}
                isEditable={isEditable}
                schedule={_.get(scheduleItem, 'scheduledAdditionalInterest')}
                updateScheduleService={(
                    _jobID,
                    _sessionUUID,
                    scheduleRequest,
                    authHeader
                ) => updateAdditionalIntestSchedule(
                    _jobID,
                    _sessionUUID,
                    scheduleItemPublicID,
                    scheduleRequest,
                    authHeader
                )}
                schedulePath='scheduledAdditionalInterest'
                onValidate={onValidationChange}
                stackOfOpenScheduleID={stackOfOpenScheduleID}
                setStackOfOpenedScheduleID={setStackOfOpenedScheduleID}
                saveButtonLabel={messages.saveAdditionalInterestOrAdditionalInsured}
                showErrors={showErrors}
            />}
            <Flex gap="small" justifyContent="right" className="mb-10">
                <Button
                    variant="secondary"
                    onClick={handleScheduleItemCancel}
                    label={messages.Cancel}
                    disabled={openedScheduleItemID !== scheduleId}
                />
                <Button
                    onClick={() => handleSave(scheduleItem)}
                    label={saveButtonLabel || messages.SaveAndContinue}
                    disabled={openedScheduleItemID !== scheduleId}
                />
            </Flex>
        </>

    );
};

export default CommonScheduleItemForm;
