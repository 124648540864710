import React, {
    useState,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { TranslatorContext, IntlContext } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import metadataDesktopTable from './QuoteClauseTable.metadata.json5';
import styles from './QuoteClauseTable.module.scss';
import 'gw-components-platform-react/QuoteClauseTable/QuoteClauseTable.messages';
import QuoteClauseTableUIHelper from './util/QuoteClauseTableUIHelper';
// import QuoteClauseDesktopTableHelper from './util/QuoteClauseDesktopTableHelper';

function selectMetadata(breakpoint) {
    return metadataDesktopTable;
}


function QuoteClauseTable(props) {
    // const contextType = TranslatorContext;
    const {
        tableData,
        columnData,
        isComponentValid,
        onSyncCoverages,
        onChangeSubmissionAndSync,
        isEditable = true,
        onValidate,
        showErrors,
        //
        clauseTableRenderHelper,
    } = props;

    // const intl = useContext(IntlContext);

    const [formData, updateFormData] = useState({});
    const [loadingClause, updateLoadingClause] = useState();
    // const [resetBranchCodes, updateResetBranchCodes] = useState(columnData.map((item) => item.code));

    
    const writeValue = (value, path) => {
        _.set(formData, path, value);
        updateFormData(formData);
    };
    const clauseRenderHelper = clauseTableRenderHelper({ // QuoteClauseTableUIHelper({
        //  =================for renderCellContent ====================
        columnData,
        isCoveragesEditable: isEditable,
        // resetBranchCodes,
        loadingClause,
        // updateResetBranchCodes,
        updateLoadingClause,
        onSyncCoverages,
        onChangeSubmissionAndSync,
        onValidate,
        showErrors,
        // =================for generateDesktopOverrides=====================
        tableData,
        // =================for generatePhoneOverrides=======================
        isComponentValid, // for PhoneTable
        formData, // for PhoneTable
        writeValue, // for PhoneTable
    });

    const {
        // renderCellContent,
        generateDesktopOverrides,
    } = clauseRenderHelper;

    const generateOverrides = (breakpoint) => {
        // const helperProps = {
        //     tableData,
        //     columnData,
        //     isComponentValid, // for PhoneTable
        //     formData, // for PhoneTable
        //     writeValue, // for PhoneTable
        //     renderCellContent: renderCellContent,
        // };
        // let breakpointOverrides = null;
        // const desktopHelper = QuoteClauseDesktopTableHelper(helperProps);
        // breakpointOverrides = desktopHelper.generateDesktopOverrides();
        const breakpointOverrides = generateDesktopOverrides();

        return {
            '@field': {
                // apply to all fields
                labelPosition: 'top',
                onValueChange: writeValue
            },
            paymentToggle: {
                // visible: !blockingPointFound,
            },
            tableContainer: {
                // visible: !blockingPointFound,
            },
            payMentToggleConatiner: {
                visible: isEditable,
            },
            actionVersionConatiner: {
                visible: isEditable,
            },
            ...breakpointOverrides
        };
    };

    const renderClauseTable = (breakpoint) => {
        const dataForComponent = {
            ...formData,
            columnData,
            tableData,
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron
            }
        };
        const generatedMetadata = selectMetadata(breakpoint);
        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={generateOverrides(breakpoint)}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    };

    return (
        renderClauseTable()
    );
}

QuoteClauseTable.propTypes = {
    columnData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        quote: PropTypes.shape({
            path: PropTypes.string,
            data: PropTypes.shape({
                publicID: PropTypes.string,
                pairPeriodPublicId_Ext: PropTypes.string
            })
        })
    })).isRequired,
    tableData: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({
            publicID: PropTypes.string
        })),
        tableContent: PropTypes.arrayOf(PropTypes.shape({
            path: PropTypes.string,
            clauses: PropTypes.arrayOf(PropTypes.shape({})),
            code: PropTypes.string
        }))
    })).isRequired,
    onBuyNow: PropTypes.func,
    onRecalculate: PropTypes.func,
    onResetQuote: PropTypes.func,
    onChangeSubmissionAndSync: PropTypes.func,
    onChangeSubmission: PropTypes.func,
    onSyncCoverages: PropTypes.func,
    periodStatus: PropTypes.string,
    WniQuoteNoteService: PropTypes.shape({}),
    isEditable: PropTypes.bool,
    // ...validationPropTypes
    onValidate: PropTypes.func,
    showErrors: PropTypes.boolean,
    /**
     * (Optional) In case you want to customize the render of QuoteClaueses, 
     * it could be done throw this property uiHelper.
     * See the default implementation QuoteClauseTableUIHelper 
     * for reference.
     * 
     * Expected interface from return value:
     * {
     *  generateDesktopOverrides,
     * } 
     * 
     * Sample Usage:
     * const renderHelper = CustomClauseRenderHelper({
     * 	columnData,
     * 	isCoveragesEditable: isEditable,
     * 	// resetBranchCodes,
     * 	loadingClause,
     * 	// updateResetBranchCodes,
     * 	updateLoadingClause,
     * 	onSyncCoverages,
     * 	onChangeSubmissionAndSync,
     * 	onValidate,
     * 	showErrors,
     * 	//
     * 	tableData,
     * });
     * 
     * 
     * // Clause table overrides
     * tableData: {
     * 	...renderHelper.generateDesktopOverrides(),
     * }
     */
    clauseTableRenderHelper: PropTypes.func,
};

QuoteClauseTable.defaultProps = {
    onBuyNow: undefined,
    onRecalculate: undefined,
    onResetQuote: undefined,
    onChangeSubmissionAndSync: undefined,
    onChangeSubmission: undefined,
    onSyncCoverages: undefined,
    periodStatus: '',
    WniQuoteNoteService: undefined,
    isEditable: true,
    onValidate: undefined,
    showErrors: false,
    //
    clauseTableRenderHelper: QuoteClauseTableUIHelper,
};

// export default withValidation(QuoteClauseTable);
export default QuoteClauseTable;
