import _ from 'lodash';

export default class CPClaimExtension {
    constructor(claim) {
        Object.defineProperty(this, 'claim', {
            enumerable: false,
            value: claim
        });
        this.dateOfNotice = this.claim.lobs.commercialProperty
            && this.claim.lobs.commercialProperty.dateOfNotice
            ? this.claim.lobs.commercialProperty.dateOfNotice
            : this.claim.lossDate;
        _.extend(this, this.claim.lobs.commercialProperty);
    }
}
