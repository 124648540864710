import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'workersComp.directives.schedules.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'workersComp.directives.schedules.table.del',
        defaultMessage: 'Delete Selected'
    },
    scheduleEdit: {
        id: 'workersComp.directives.schedules.table.edit',
        defaultMessage: 'Edit'
    },
    newCompany: {
        id: 'workersComp.directives.schedules.table.New Company',
        defaultMessage: 'New Company'
    },
    newPerson: {
        id: 'workersComp.directives.schedules.table.New Person',
        defaultMessage: 'New Person'
    },
    searchExistingContacts:{
        id: 'workersComp.directives.schedules.table.Search existing contacts',
        defaultMessage: 'Search Existing Contacts'
    }
});
