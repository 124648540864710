import { defineMessages } from 'react-intl';

export default defineMessages({
    exclusionAndConditionTitle: {
        id: "quoteandbind.gl.wizard.step.state specific information page.Exclusions and Conditions",
        defaultMessage: "Exclusions & Conditions"
    },
    addExclusionAndConditionActionText: {
        "id": "quoteandbind.gl.wizard.step.state specific information page.Exclusions and Conditions.Add Exclusion and Conditions",
        "defaultMessage": "Add Exclusion & Conditions"
    },
});