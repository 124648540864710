import React, {
    useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { QuickLinkComponent } from 'wni-capability-gateway-react';
import { WniProductsUtil } from 'wni-portals-util-js';
import metadata from './HOWizardQuestionnaireComponent.metadata.json5';
import messages from './HOWizardQuestionnaireComponent.messages';


function HOWizardQuestionnaireComponent(props) {

    const {
        wizardData,
        handleDownload,
        lobDataProductKey,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            accountNumber,
            productCode,
            accountHolder,
            producerCode_Ext: producerCode,
            producerCodePublicID_Ext: producerCodePublicID,
            baseState_Ext: baseState
        },
        lobData: {
            [lobDataProductKey]: {
                wizardSideBar_Ext: {
                    solidFuelQuestionnaireDoc = null ,
                    earthquakeQuestionnaireDoc = null
                } = {}
            } = {}
        },
    } = wizardData.value;

    const quickLinksMap = WniProductsUtil.getQuickLink(productCode);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        '@action': {
            quickLinkData: quickLinksMap
        },
        '@all': {
            tabIndex: -1
        },
        solidFuelQuestionnaire: {
            visible: !_.isEmpty(solidFuelQuestionnaireDoc),
            onClick: () => handleDownload(solidFuelQuestionnaireDoc)
        },
        earthquakeQuestionnaire: {
            visible: !_.isEmpty(earthquakeQuestionnaireDoc),
            onClick: () => handleDownload(earthquakeQuestionnaireDoc)
        },
    };
    

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            quicklinkcomponent: QuickLinkComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

HOWizardQuestionnaireComponent.propTypes = {
    wizardData: PropTypes.shape({}),
    handleDownload: PropTypes.func,
    lobDataProductKey: PropTypes.string,
};

HOWizardQuestionnaireComponent.defaultProps = {
    lobDataProductKey: 'homeowners',
};

export default HOWizardQuestionnaireComponent;
