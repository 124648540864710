
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';

import { RTPolicyDetailsReadOnlyPage } from 'wni-capability-quoteandbind-rt-react';

function RTPolicyDetailsChangeReadOnlyPage(props) {

    return (
        <RTPolicyDetailsReadOnlyPage {...props} />
    );

}

RTPolicyDetailsChangeReadOnlyPage.propTypes = RTPolicyDetailsReadOnlyPage.propTypes;
RTPolicyDetailsChangeReadOnlyPage.defaultProps = RTPolicyDetailsReadOnlyPage.defaultProps;

export default RTPolicyDetailsChangeReadOnlyPage;
