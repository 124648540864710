import React from 'react';
import WizardPage from '../../templates/CAWizardPage';
import CAStateSpecificInformationPage from './CAStateSpecificInformationPage';

function CAStateSpecificInformationReadOnlyPage(props) {

    return (
        <CAStateSpecificInformationPage {...props} isReadOnly/>
    );
}

CAStateSpecificInformationReadOnlyPage.propTypes = WizardPage.propTypes;
export default CAStateSpecificInformationReadOnlyPage;