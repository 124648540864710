import { defineMessages } from 'react-intl';

/**
 * ref: common/ClauseComponentVM.messages.js
 */
export default defineMessages({
    noCoverage: {
        id: 'custom.cpcoverages.bop.directives.bop-building-directive.No Coverages',
        defaultMessage: 'No Coverages'
    },
    noneSelected: {
        id: 'custom.policycommon.directives.cpcoverages.select.None Selected',
        defaultMessage: 'None Selected'
    },
    selectedValue: {
        id: 'custom.cpcoverages.The value must be between',
        defaultMessage: 'The value must be between {minValue} and {maxValue}'
    },
    minValue: {
        id: 'custom.cpcoverages.The minimum allowed value is',
        defaultMessage: 'The minimum allowed value is {minValue}'
    },
    maxValue: {
        id: 'custom.cpcoverages.The maximum allowed value is',
        defaultMessage: 'The maximum allowed value is {maxValue}'
    },
    // updatingSelection: {
    //     id: 'policycommon.directives.coverages.Updating selection...',
    //     defaultMessage: 'Updating selection...'
    // }
});
