import { defineMessages } from 'react-intl';

export default defineMessages({
    selectedAll: {
        id: 'quoteandbind.base.views.pa-policy-info.Select All',
        defaultMessage: 'Select All'
    },
    downloadSelected: {
        id: 'quoteandbind.base.views.pa-policy-info.Download Selected',
        defaultMessage: 'Download Selected'
    },
    sendViaEmail: {
        id: 'quoteandbind.base.views.pa-policy-info.Send via Email',
        defaultMessage: 'Send via Email'
    },
    documents: {
        id: 'quoteandbind.base.views.pa-policy-info.Documents',
        defaultMessage: 'Documents'
    },
    success: {
        id: 'quoteandbind.base.views.email.Success',
        defaultMessage: 'Success'
    },
    successContent: {
        id: 'quoteandbind.base.views.email.The email was submitted for delivery',
        defaultMessage: 'The email was submitted for delivery.'
    },
    close: {
        id: 'quoteandbind.base.views.email.Close',
        defaultMessage: 'Close'
    },
    notSend: {
        id: 'quoteandbind.base.views.email.Email did not send',
        defaultMessage: 'Email did not send'
    },
    notSendContent: {
        id: 'quoteandbind.base.views.email.Email send error',
        defaultMessage: 'An error prevented this email from being delivered. You may download the documents directly or contact our Customer Relationship Center at (800) 352-2772 for support in retrieving these documents.'
    },
    printSelected: {
        id: 'quoteandbind.base.views.pa-policy-info.Print Selected',
        defaultMessage: 'Print Selected'
    },
    noDocUID: {
        id: 'quoteandbind.base.views.pa-policy-info.no DocUID',
        defaultMessage: 'Documents: {displayNames} are still being processed on this policy and are not yet available – please check back soon. If you need support in obtaining documents, please contact our Customer Relationship Center at (800) 352-2772.'
    },
});
