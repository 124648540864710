import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
// import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';

import WizardPage from '../../templates/HOWizardPage';

import HOPrimaryCoverages from './CoveragesSection/HOPrimaryCoverages/HOPrimaryCoverages';
import HOCommonOptionalCoverages from './CoveragesSection/HOCommonOptionalCoverages/HOCommonOptionalCoverages';
import HOOtherOptionalCoverages from './CoveragesSection/HOOtherOptionalCoverages/HOOtherOptionalCoverages';
import HOExclusions from './CoveragesSection/HOExclusions/HOExclusions';
import styles from './HOCoveragesPage.module.scss';
import metadata from './HOCoveragesPage.metadata.json5';
// import messages from './HOCoveragesPage.messages';

function HODPCoveragesBaseReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        lobName,
        coveragesConfig,
        componentMapOverride,
        DPOverrideProps,
    } = props;

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID
        },
        lobData: {
            [lobName]: {
                offerings
            }
        },
    } = submissionVM.value;

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const [openedAccordions, setOpendedAccordions] = useState([
        'primaryCoverages',
        'commonOptionalCoverages'
    ]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                isEditable: false,
            },
            // dynamicInlineNotificationContainer: {
            //     visible: false,
            // },
            coveragesAccordion: {
                accordionStates: openedAccordions,
                onUpdateAccordionStates: (ids) => setOpendedAccordions(ids),
            },
            hoPrimaryCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
                coveragesConfig,
            },
            hoCommonOptionalCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
                coveragesConfig,
            },
            hoOtherOptionalCoverages: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
                coveragesConfig,
            },
            hoExclusions: {
                selectedVersion,
                selectedVersionIndex,
                submissionVM,
                lobName,
                coveragesConfig,
            },
        };
    }, [openedAccordions, selectedVersion, selectedVersionIndex, submissionVM, lobName, coveragesConfig]);

    //---------------------
    const overrideProps = generateOverrides();

    const overrides = _.mergeWith(overrideProps, DPOverrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            // validationIssuesComponent: ValidationIssuesComponent,
            hoPrimaryCoverages: HOPrimaryCoverages,
            hoCommonOptionalCoverages: HOCommonOptionalCoverages,
            hoOtherOptionalCoverages: HOOtherOptionalCoverages,
            hoExclusions: HOExclusions,
            ...componentMapOverride,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrides}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

HODPCoveragesBaseReadOnlyPage.propTypes = wizardProps;
export default HODPCoveragesBaseReadOnlyPage;
