import { defineMessages } from 'react-intl';

export default defineMessages({
    DroneCoverage: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.DroneCoverage",
        "defaultMessage": "Drone Coverage"
    },
    BlanketDroneLimit: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.BlanketDroneLimit",
        "defaultMessage": "Blanket Drone Limit"
    },
    BlanketDroneRate: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.BlanketDroneRate",
        "defaultMessage": "Blanket Drone Rate"
    },
    DroneAndGroundEquipmentCoverage: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.DroneAndGroundEquipmentCoverage",
        "defaultMessage": "Drone and Ground Equipment Coverage"
    },
    DroneCargoCoverage: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.DroneCargoCoverage",
        "defaultMessage": "Drone Cargo Coverage"
    },
    BlanketDroneandGroundEquipment: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.BlanketDroneandGroundEquipment",
        "defaultMessage": "Blanket Drone and Ground Equipment"
    },
    DroneCoverageIncomeCoveragePart: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.Drone Coverage - Income Coverage Part",
        "defaultMessage": "Drone Coverage - Income Coverage Part"
    },
    IncomeCoverageWaitingPeriod: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.Income Coverage Waiting Period",
        "defaultMessage": "Income Coverage Waiting Period"
    },
    days: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.MiscForms.DroneCoverage.days",
        "defaultMessage": "days"
    }
});