import { defineMessages } from 'react-intl';

export default defineMessages({
    confirm: {
        id: 'copyWatercraftCoverageModal.button.label.Confirm',
        defaultMessage: 'Confirm'
    },
    cancel: {
        id: 'copyWatercraftCoverageModal.button.label.Cancel',
        defaultMessage: 'Cancel'
    },
    title: {
        id: 'copyWatercraftCoverageModal.title.label',
        defaultMessage: 'Copy Watercraft Coverages'
    },
    copyFromLabel: {
        id: 'copyWatercraftCoverageModal.copyFrom.label',
        defaultMessage: 'Copy From:'
    },
    copyToLabel: {
        id: 'copyWatercraftCoverageModal.Copy To:',
        defaultMessage: 'Copy To:'
    },
    selectAll: {
        id: 'copyWatercraftCoverageModal.Select All',
        defaultMessage: 'Select All'
    }
});
