import { defineMessages } from 'react-intl';

export default defineMessages({
    InjuryIncidentsQuestion: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.question',
        defaultMessage: "Were there any third party injuries?"
    },
    Yes: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.Yes',
        defaultMessage: "Yes"
    },
    No: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.No',
        defaultMessage: "No"
    },
    Number: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.No.',
        defaultMessage: "No."
    },
    InjuryLastName: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.InjuryLastName',
        defaultMessage: "Injury Last Name"
    },
    ViewOrEdit: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.View/Edit',
        defaultMessage: "View/Edit"
    },
    Delete: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.Delete',
        defaultMessage: "Delete"
    },
    AddInjuries: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.AddInjuries',
        defaultMessage: "Add Injuries"
    },
    DeleteInjuries: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.injuryIncidents.DeleteInjuries',
        defaultMessage: "Delete Injuries"
    }
})