

import React from 'react';
import { IMMiscellaneousFloatersPage } from 'wni-capability-quoteandbind-im-react';


const IMMiscellaneousFloatersChangePage = (props) => {
    return <IMMiscellaneousFloatersPage {...props} isPolicyChange />
};
export default IMMiscellaneousFloatersChangePage
