
import React from 'react';
import { WALOperatorsPage } from 'wni-capability-quoteandbind-wal-react';
import { RTOperatorService } from 'wni-capability-quoteandbind-rt';

import messages from './RTOperatorsPage.messages';

function RTOperatorsPage(props) {

    return (
        <WALOperatorsPage
            {...props}
            walOperatorService = {RTOperatorService}
            linePath = 'roadTrail'
            customMessages = {messages}
        />
    );

}

RTOperatorsPage.propTypes = WALOperatorsPage.propTypes;
RTOperatorsPage.defaultProps = WALOperatorsPage.defaultProps;

export default RTOperatorsPage;
