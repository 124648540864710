import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('glUnmannedAircraftService'), method, data, additionalHeaders);
}

export default class GLUnmannedAircraftService {

    /**
     * Invokes GLUnmannedAircraftHandler.addUnmannedAircraft()
     */
    static addUnmannedAircraft(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('addUnmannedAircraft', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes GLUnmannedAircraftHandler.removeUnmannedAircraft()
     *
     */
    static removeUnmannedAircraft(quoteID, sessionUUID, publicIDs, authHeader = {}) {
        return processSubmission('removeUnmannedAircraft', [quoteID, sessionUUID, publicIDs], authHeader);
    }

    static filterUnmannedAircrafts(quoteID, sessionUUID, typeFilterChoice, filterValue, authHeader = {}) {
        return processSubmission('filterUnmannedAircrafts', [quoteID, sessionUUID, typeFilterChoice, filterValue], authHeader);
    }

    static postOnChangeAction(quoteID, sessionUUID, dto, authHeader = {}) {
        return processSubmission('postOnChangeAction', [quoteID, sessionUUID, dto], authHeader);
    }

    static viewAndEdit(quoteID, sessionUUID, publicID, authHeader = {}) {
        return processSubmission('viewAndEdit', [quoteID, sessionUUID, publicID], authHeader);
    }

    static saveUnmannedAircraft(quoteID, sessionUUID, dto, authHeader = {}) {
        return processSubmission('saveUnmannedAircraft', [quoteID, sessionUUID, dto], authHeader);
    }


    static onPageNext(requestData, authHeader = {}) {
        return processSubmission('onPageNext', [requestData.jobID, requestData.sessionUUID], authHeader);
    }
    
}
