import React, {
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BasePolicyChangeQuoteComponent from './BasePolicyChangeQuoteComponent';

const RTUTExcludeLiabilityCoveragePatternCode = 'z98isu2q3e61sf6i9agqpmfndc9';

function checkVehicleType(vehicle = undefined) {
    // Note: vehicle could be undefined when it is removed from PolicyChange
    const vehicleType = _.get(vehicle, 'vehicleType_Ext');
    const retval = ['utilitytrailer_Ext', 'rectrailer_Ext'].includes(vehicleType);
    return retval;
}

function PAPolicyChangeQuoteComponent(props) {
    const {
        vehicles
    } = props;

    const [accordionHeaders] = useState([]);


    const generatePersonalVehicleQuoteTable = useCallback(
        (
            currentNodes,
            vehicle,
            generateQuoteGrid,
            sanitizeText,
        ) => {
            return currentNodes
                .filter((currentNode) => {
                    if (
                        currentNode.isLeaf
                        && currentNode.entityType_Ext === 'Coverage'
                        && currentNode.coveragePatternCode_Ext === RTUTExcludeLiabilityCoveragePatternCode
                        && checkVehicleType(vehicle)
                    ) {
                        return false;
                    }
                    return true;
                })
                .map((currentNode) => {
                    if (currentNode.isLeaf) {
                        return generateQuoteGrid(currentNode);
                    }

                    accordionHeaders.push(currentNode);
                    const childrenContent = generatePersonalVehicleQuoteTable(
                        currentNode.children,
                        vehicle,
                        generateQuoteGrid,
                        sanitizeText,
                    );
                    return {
                        id: `quote${sanitizeText(currentNode.label)}`,
                        type: 'container',
                        component: 'accordion',
                        componentProps: {
                            closeOthers: false,
                            showFrame: false,
                            boldFont: false,
                            chevronAlignment: 'left',
                            defaultOpenedId: `quoteCard${sanitizeText(currentNode.label)}`
                        },
                        content: [
                            {
                                id: `quoteCard${sanitizeText(currentNode.label)}`,
                                type: 'element',
                                component: 'accordioncard',
                                componentProps: {
                                    cardBodyClassName: 'cardBodyMargins',
                                },
                                content: childrenContent
                            }
                        ]
                    };
                });
        },
        [accordionHeaders]
    );

    const generateQuoteTable = useCallback(
        (currentNodes, generateQuoteGrid, sanitizeText) => {
            return currentNodes.map((currentNode) => {
                if (currentNode.isLeaf) {
                    return generateQuoteGrid(currentNode);
                }

                accordionHeaders.push(currentNode);
                let childrenContent;
                if (currentNode.entityType_Ext === 'PersonalVehicle') {
                    const vehiclePublicID = currentNode.publicID_Ext;
                    const vehicle = vehicles.find((v) => v.publicID === vehiclePublicID);
                    childrenContent = generatePersonalVehicleQuoteTable(
                        currentNode.children,
                        vehicle,
                        generateQuoteGrid,
                        sanitizeText,
                    );
                } else {
                    childrenContent = generateQuoteTable(
                        currentNode.children,
                        generateQuoteGrid,
                        sanitizeText,
                    );
                }
                return {
                    id: `quote${sanitizeText(currentNode.label)}`,
                    type: 'container',
                    component: 'accordion',
                    componentProps: {
                        closeOthers: false,
                        showFrame: false,
                        boldFont: false,
                        chevronAlignment: 'left',
                        defaultOpenedId: `quoteCard${sanitizeText(currentNode.label)}`
                    },
                    content: [
                        {
                            id: `quoteCard${sanitizeText(currentNode.label)}`,
                            type: 'element',
                            component: 'accordioncard',
                            componentProps: {
                                cardBodyClassName: 'cardBodyMargins',
                            },
                            content: childrenContent
                        }
                    ]
                };
            });
        },
        [accordionHeaders, generatePersonalVehicleQuoteTable, vehicles]
    );

    const generateAllAccordionHeaders = useCallback((
        sanitizeText
    ) => {
        const overrides = accordionHeaders.map((accordionHeader) => {
            return {
                [`quoteCard${sanitizeText(accordionHeader.label)}`]: {
                    title: accordionHeader.label,
                    chevron: true
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [accordionHeaders]);

    return (
        <BasePolicyChangeQuoteComponent
            {...props}
            generateQuoteTable={generateQuoteTable}
            generateAllAccordionHeaders={generateAllAccordionHeaders}
        />
    );
}

PAPolicyChangeQuoteComponent.propTypes = {
    ...BasePolicyChangeQuoteComponent.propTypes,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})),
};

PAPolicyChangeQuoteComponent.defaultProps = {
    ...BasePolicyChangeQuoteComponent.defaultProps,
    vehicles: [],
};

export default PAPolicyChangeQuoteComponent;
