import React from 'react';
import { injectIntl } from 'react-intl';
import { useTranslator } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

const MainModal = (props) => {
    const {
        isOpen, 
        modalProps = {}
    } = props;

    const {
        title,
        message,
        status,
        icon = 'gw-error-outline',
        showCancelButton,
        showConfirmButton,
        confirmButtonText = commonMessages.ConfirmModal,
        cancelButtonText = commonMessages.cancelModel,
        modalType,
        element
    } = modalProps;
    const translator = useTranslator();
    if(!isOpen) {
        return null;
    }
    if(modalType === 'element') {
        return React.cloneElement(element, { ...props })

    }
    // TODO: for alert and confirm
    return null;
};

export default injectIntl(MainModal);