import _ from 'lodash';
import { 
    valueTypeMap as customerValueTypeMap,
    valueTypeDatatypeMap as customerValueTypeDatatypeMap,
    valueTypePropDatatypeMap as customerValueTypePropDatatypeMap,
    formatAddress as customerFormatAddress,
    getFormattedValue as customerGetFormattedValue,
} from 'customer-scheduleitems-config';

export const valueTypeMap = {
    TYPEKEY: 'typeCodeValue',
    STRING: 'stringValue',
    INTEGER: 'integerValue',
    LOCATION: 'locationValue',
    DATE: 'dateValue',
    POLICYCONTACT: 'policyContactValue',
    ...customerValueTypeMap,
};

export const valueTypeDatatypeMap = {
    TYPEKEY: 'select',
    STRING: 'string',
    INTEGER: 'number',
    DATE: 'date',
    ...customerValueTypeDatatypeMap,
};

export const valueTypePropDatatypeMap = {
    TYPEKEY: 'string',
    INTEGER: 'number',
    DATE: 'string',
    ...customerValueTypePropDatatypeMap,
};

export function formatAddress(address) {
    if(customerFormatAddress) {
        const customerFormattedAddress = customerFormatAddress(address);

        if(customerFormattedAddress) {
            return customerFormattedAddress;
        }
    }

    const addressValues = [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.city,
        address.postalCode,
        address.state
    ].filter((addressValue) => !_.isUndefined(addressValue));

    return address ? addressValues.join(', ') : undefined;
}

export function getFormattedValue(value, valueType, propertyInfo) {
    if(customerGetFormattedValue) {
        const customerFormattedValue = customerGetFormattedValue(value, valueType, propertyInfo);

        if(customerFormattedValue) {
            return customerFormattedValue;
        }
    }

    if (valueType === 'TYPEKEY') {
        const availableValue = propertyInfo.availableTypeListValues.find(
            (av) => av.code === value || av.displayKey === value
        );
        return _.get(availableValue, 'displayKey');
    }
    if (valueType === 'LOCATION') {
        const address = _.get(value, 'address', {});
        return formatAddress(address);
    }
    if (valueType === 'POLICYCONTACT') {
        return _.get(value, 'person.displayName');
    }
    return value;
}
