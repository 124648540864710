import React, { useCallback } from 'react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PaymentConfirmation.metadata.json5';
import './PaymentConfirmation.messages';

function PaymentConfirmation(props) {
    const { history, match } = props;

    const goToAccountSummary = useCallback(() => {
        const {
            params: { accountNumber }
        } = match;
        const redirectLobPath = `/accounts/${accountNumber}/summary`;
        history.push(redirectLobPath);
    });

    const resolver = {
        resolveCallbackMap: {
            goToAccountSummary: goToAccountSummary
        }
    };

    return (
        <ViewModelForm uiProps={metadata.pageContent} callbackMap={resolver.resolveCallbackMap} />
    );
}

PaymentConfirmation.propTypes = wizardProps;
export default withRouter(PaymentConfirmation);
