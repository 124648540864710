import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { DriverComponent } from 'gw-capability-policyjob-react';
import { CustomFooterV2Component } from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WniDriverService } from 'wni-capability-quoteandbind';
import { InValidIconUtil, WniTableRowUtil, DomRenderUtil } from 'wni-portals-util-react';
import { WizardConstants } from 'wni-portals-config-js';
import {
    DriverUtil,
    QuoteUtil,
    DriverValidationUtil,
    OOSUtil,
    WindowUtil,
    WniAccordionValidationUtil,
    ValidationIssueUtil
} from 'wni-portals-util-js';
import ADPDriverUtil from './util/ADPDriverUtil';

import styles from './PADriversPage.module.scss';
import metadata from './PADriversPage.metadata.json5';
import messages from './PADriversPage.messages';

function PADriversPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot,
        policyChangeService = {},
        jumpTo,
        steps,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        resetWizardDataToSnapshot,
        wizardPageData,
        isSkipping,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [currentRow, updateCurrentRow] = useState(null);
    const [initCurrentRow, updateInitCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [pcDisplayIssues, updatePcDisplayIssues] = useState([]);
    const [accordionOpendIds, updateAccordionOpendIds] = useState([]);
    const {
        authHeader,
        authUserData: {
            businessData_Ext: {
                featureToggles = []
            } = {}
        } = {},
    } = useAuthentication();
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation,
        invalidFields
    } = useValidation('DriverPage');
    const {
        quoteID,
        sessionUUID,
        baseData: {
            periodStatus,
            effectiveDate
        },
        baseData,
        oossliceDates_Ext: oossliceDates
    } = submissionVM.value;

    const accordionErrorId = useCallback(() => {
        if (!currentRow) {
            return [];
        }
        const errorStateObj = WniAccordionValidationUtil
            .getAccordionOverridesForDrivers(currentRow, validationIssues, baseData, wizardPageData);
        const errorIds = Object.keys(errorStateObj).filter((key) => errorStateObj[key].errorState);
        return errorIds;
    }, [currentRow, validationIssues]);
    const [driverData, updateDriverData] = useState([]);
    const [showLoader, updateShowLoader] = useState(false);
    const [defaultQuestionSets, updateDefaultQuestionSets] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const policyCountryCode = _.get(submissionVM.value, 'baseData.policyAddress.country');
    const driversPath = 'lobData.personalAuto.coverables.drivers';
    const driversVM = _.get(submissionVM, driversPath);
    // const hasInvalidDriver = DriverUtil.hasInvalidDriver(driversVM);
    const quoteFlow = _.get(submissionVM, 'value.baseData.quoteFlow_Ext');
    const jobType = _.get(submissionVM.value, 'baseData.jobType', 'Submission');
    const nameNonOwner = _.get(submissionVM, 'value.lobData.personalAuto.isForNamedNonOwner_Ext');

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            updateAccordionOpendIds(accordionErrorId());
            setTimeout(() => {
                WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
            }, 500);
            return false;
        },
        [accordionErrorId, invalidFields]
    );

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'driverTable');
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const checkVehicleEmpty = useCallback(
        () => {
            const driverSize = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
            return !_.isEmpty(driverSize);
        },
        [submissionVM]
    );

    useEffect(() => {
        registerInitialComponentValidation(
            () => checkVehicleEmpty() && !DriverUtil.hasInvalidDriver(driversVM)
        );
    }, [registerInitialComponentValidation, checkVehicleEmpty, driversVM]);

    const createDriverVM = useCallback(() => {
        const driverObj = DriverUtil.setDefaultDriverObj(defaultQuestionSets);
        _.unset(driverObj.person, 'emailAddress1');
        const {
            _dtoName,
            _xCenter,
        } = submissionVM.lobData.personalAuto.coverables.drivers;
        const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
        const addedDriver = submissionVM.lobData.personalAuto.coverables.drivers
            .pushElement(driverVM);
        DriverUtil.updatePolicyCountryForDriver(driverVM, policyCountryCode);
        updateCurrentRow(addedDriver);
        updateInitCurrentRow(addedDriver);
        updateWizardSnapshot(submissionVM);
        updateShowErrors(false);
    }, [defaultQuestionSets, policyCountryCode, submissionVM, updateWizardSnapshot, viewModelService]);

    const addDriverForPrimaryNamedInsured = useCallback(async () => {
        updateShowLoader(true);
        const res = await WniDriverService.addDriverForPrimaryNamedInsured(
            { quoteID, sessionUUID }, authHeader
        );
        _.set(submissionVM, driversPath, _.get(res, driversPath));
        const questionSets = _.cloneDeep(_.get(res, `${driversPath}[0].driverQuestionSets_Ext`));
        _.set(res, `${driversPath}[0].driverQuestionSets_Ext`, DriverUtil.setDefaultQuestionSets(questionSets));
        updateDefaultQuestionSets(questionSets);
        updateShowLoader(false);

        updateWizardSnapshot(submissionVM);
        DriverUtil.hasInvalidDriver(driversVM, updateCurrentRow, updateInitCurrentRow, viewModelService);
    }, [authHeader, driversVM, quoteID, sessionUUID, submissionVM, updateWizardSnapshot]);

    const getErrorsAndWarnings = useCallback(async () => {
        let res = {};
        if (_.isFunction(policyChangeService.getErrorsAndWarnings)) {
            res = await policyChangeService.getErrorsAndWarnings();
        } else {
            res = await WniDriverService.getErrorsAndWarnings(quoteID, authHeader);
        }
        const allValidationIssues = DriverValidationUtil
            .getAllValidationIssues(submissionVM, res, translator, messages);
        updateValidationIssues(allValidationIssues);
        if (allValidationIssues.length > 0) {
            if (!showErrors) {
                updateShowErrors(true);
                return false;
            }
        }
    }, [authHeader, quoteID, showErrors, submissionVM, translator]);

    const initErrorsAndWarnings = useCallback(() => {
        if (quoteFlow !== 'draft'
        && (jobType !== 'PolicyChange' || !_.isNil(wizardPageData[WizardConstants.policyDiffData]))) {
            getErrorsAndWarnings();
            updateShowErrors(true);
        }
    }, [getErrorsAndWarnings, jobType, quoteFlow, wizardPageData]);

    useEffect(() => {
        setPageInitialized(true);
        DriverUtil.hasInvalidDriver(driversVM, updateCurrentRow, updateInitCurrentRow, viewModelService);
        //
        const drivers = _.get(submissionVM, `${driversPath}.value`);
        _.forEach(drivers, (d) => {
            // default value for Driver's UW Question
            const questionSets = _.cloneDeep(_.get(d, 'driverQuestionSets_Ext'));
            _.set(d, 'driverQuestionSets_Ext', DriverUtil.setVehFurnishedQuestionSets(questionSets));
        });
        if (_.isEmpty(drivers)) {
            addDriverForPrimaryNamedInsured();
        } else {
            const firstDriverQuestion = _.cloneDeep(_.get(submissionVM, `value.${driversPath}[0].driverQuestionSets_Ext`));
            updateDefaultQuestionSets(DriverUtil.setDefaultQuestionSets(firstDriverQuestion));
        }
    }, []);

    useEffect(() => {
        if (!isSkipping) {
            initErrorsAndWarnings();
        }
    }, [isSkipping]);

    useEffect(() => {
        _.forEach(driversVM.children, (d) => {
            DriverUtil.updatePolicyCountryForDriver(d, policyCountryCode);
        });
    }, [submissionVM]);

    const setRowIdPath = useCallback(() => {
        const drivers = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        drivers.forEach((item) => {
            _.set(item, 'rowIdPath', item.publicID || item.rowIdPath);
        });
        updateDriverData(drivers);
    }, [submissionVM.value]);

    const addDriver = useCallback(async (callback) => {
        updateShowLoader(true);
        const requestData = {
            quoteID: quoteID,
            sessionUUID: sessionUUID,
            driver: currentRow.value
        };
        let res = {};
        if (_.isFunction(policyChangeService.addDriver)) {
            res = await policyChangeService.addDriver(currentRow.value);
        } else {
            res = await WniDriverService.addDriver(requestData, authHeader);
        }
        _.set(submissionVM, 'value', res);
        setRowIdPath();
        if (!_.isEmpty(validationIssues)) {
            await getErrorsAndWarnings();
        } else {
            await initErrorsAndWarnings();
        }
        const enablePAAccountSync = _.find(featureToggles, ({ code }) => code === 'EnablePAAccountSync');
        if (enablePAAccountSync) {
            const errorsAndWarnings = res.errorsAndWarnings || res.errorsAndWarnings_Ext
            if (errorsAndWarnings) {
                const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                updatePcDisplayIssues(_.uniqBy(newValidationIssues, 'reason'));
            } else {
                updatePcDisplayIssues([]);
            }
        } 
        updateShowLoader(false);
        // const pcDisplayIssues = ErrorsAndWarningsUtil.getPCDisplayIssues(submissionVM.value);
        // updateValidationIssues(pcDisplayIssues);
        updateWizardSnapshot(submissionVM);
        updateSelection([]);
        if (_.isFunction(callback)) {
            await callback(null);
        }
    }, [authHeader, currentRow, quoteID, sessionUUID, submissionVM, updateWizardSnapshot]);

    const updateDriver = useCallback(async (callback) => {
        updateShowLoader(true);
        const requestData = {
            quoteID: quoteID,
            sessionUUID: sessionUUID,
            driver: currentRow.value
        };
        let res = {};
        if (_.isFunction(policyChangeService.updateDriver)) {
            res = await policyChangeService.updateDriver(currentRow.value);
        } else {
            res = await WniDriverService.updateDriver(requestData, authHeader);
        }
        _.set(submissionVM, 'value', res);
        if (!_.isEmpty(validationIssues)) {
            getErrorsAndWarnings();
        }
        if (_.isFunction(callback)) {
            await callback();
        }
        initErrorsAndWarnings();
        const enablePAAccountSync = _.find(featureToggles, ({ code }) => code === 'EnablePAAccountSync');
        if (enablePAAccountSync) {
            const errorsAndWarnings = res.errorsAndWarnings || res.errorsAndWarnings_Ext
            if (errorsAndWarnings) {
                const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                updatePcDisplayIssues(_.uniqBy(newValidationIssues, 'reason'));
            } else {
                updatePcDisplayIssues([]);
            }
        } 
        updateShowLoader(false);
        updateSelection([]);
        updateWizardSnapshot(submissionVM);
    }, [authHeader, currentRow, quoteID, sessionUUID]);

    const switchToNextDriver = useCallback(() => {
        const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
        const index = _.findIndex(drivers,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === drivers.length - 1) {
            updateCurrentRow(_.get(drivers, 0));
            updateInitCurrentRow(viewModelService.clone(_.get(drivers, 0)));
            return;
        }
        updateCurrentRow(_.get(drivers, index + 1));
        updateInitCurrentRow(viewModelService.clone(_.get(drivers, index + 1)));
    }, [currentRow, driversVM]);

    const saveDriver = useCallback((callback) => {
        if (!isComponentValid || !_.get(currentRow, 'aspects.valid') || !_.get(currentRow, 'aspects.subtreeValid')) {
            handleValidation();
            const phoneNumberIssues = DriverValidationUtil.getPhoneNumberIssues(currentRow, messages, translator);
            updateValidationIssues(phoneNumberIssues);
            return false;
        }
        updateValidationIssues([]);
        if (_.get(currentRow, 'value.publicID')) {
            return updateDriver(callback);
        }
        return addDriver(callback);
    }, [addDriver, currentRow, updateDriver, isComponentValid, handleValidation]);

    const cancel = useCallback(() => {
        resetWizardDataToSnapshot();
        updateCurrentRow(null);
    }, [wizardSnapshot]);

    useEffect(() => {
        setRowIdPath();
    }, [submissionVM.value, updateDriver, switchToNextDriver]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow, saveDriver, switchToNextDriver, submissionVM]);

    const removeDriversFromTable = useCallback((toRemoveDriver) => {
        if (_.get(toRemoveDriver, 'isPNI_Ext')) {
            return;
        }
        const driverListPath = 'lobData.personalAuto.coverables.drivers.value';
        const driverList = _.get(submissionVM, driverListPath);
        const newDriverList = _.filter(driverList, (driverFromList) => {
            return !_.isEqual(driverFromList, toRemoveDriver);
        });
        _.set(submissionVM, driverListPath, newDriverList);
    }, [submissionVM]);

    const delDriversService = useCallback(async (drivers) => {
        if (drivers.length > 0) {
            const simpleDrivers = _.map(drivers, (d) => {
                return {
                    quoteId: quoteID,
                    publicID: d.publicID,
                    dateOfBirth: d.dateOfBirth
                };
            });
            const requestData = {
                quoteID,
                sessionUUID,
                drivers: simpleDrivers
            };
            updateShowLoader(true);
            let res = {};
            if (_.isFunction(policyChangeService.delDrivers)) {
                res = await policyChangeService.delDrivers(simpleDrivers);
            } else {
                res = await WniDriverService.delDrivers(requestData, authHeader);
            }
            _.set(submissionVM, 'value', res);
            // const pcDisplayIssues = ErrorsAndWarningsUtil.getPCDisplayIssues(submissionVM.value);
            // updateValidationIssues(pcDisplayIssues);
            if (!_.isEmpty(validationIssues)) {
                await getErrorsAndWarnings();
            } else {
                await initErrorsAndWarnings();
            }
            const enablePAAccountSync = _.find(featureToggles, ({ code }) => code === 'EnablePAAccountSync');
            if (enablePAAccountSync) {
                const errorsAndWarnings = res.errorsAndWarnings || res.errorsAndWarnings_Ext
            if (errorsAndWarnings) {
                const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                    updatePcDisplayIssues(_.uniqBy(newValidationIssues, 'reason'));
                } else {
                    updatePcDisplayIssues([]);
                }
            }
            updateShowLoader(false);
        }
        return false;
    }, [authHeader, initErrorsAndWarnings, policyChangeService, quoteID, sessionUUID, submissionVM]);

    const delManuallyDrivers = useCallback(async (drivers, unsavedDrivers, showModal) => {
        const isntPNIDrivers = drivers.filter((d) => {
            return !_.get(d, 'isPNI_Ext');
        });
        const PNIDriver = drivers.filter((d) => {
            return _.get(d, 'isPNI_Ext');
        });
        let description = messages.removeIncludePNIDriverDescription;
        if (_.isEmpty(PNIDriver)) {
            description = messages.removeDriverDescription;
        }
        if (_.isEmpty(isntPNIDrivers) && PNIDriver.length === 1) {
            description = messages.removePNIDriverDescription;
        }
        const allDrivers = _.concat(isntPNIDrivers, unsavedDrivers);
        if (showModal) {
            modalApi.showConfirm({
                title: messages.removeDriverTitle,
                message: description,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.deletionModalOk,
                cancelButtonText: messages.deletionModalCancel
            }).then(
                async (results) => {
                    if (results === 'cancel' || results === 'close' || (_.isEmpty(isntPNIDrivers) && PNIDriver.length === 1)) {
                        return _.noop();
                    }
                    updateSelection([]);
                    await delDriversService(isntPNIDrivers);
                    _.forEach(allDrivers, (driver) => {
                        removeDriversFromTable(driver);
                    });
                    updateCurrentRow(null);
                    disregardFieldValidation();
                    updateWizardSnapshot(submissionVM);
                    setRowIdPath();
                    return true;
                }, _.noop
            );
        } else {
            updateSelection([]);
            await delDriversService(isntPNIDrivers);
            _.forEach(allDrivers, (driver) => {
                removeDriversFromTable(driver);
            });
            updateCurrentRow(null);
            disregardFieldValidation();
            updateWizardSnapshot(submissionVM);
            setRowIdPath();
        }
    }, [delDriversService, disregardFieldValidation, removeDriversFromTable, submissionVM]);

    const delADPCallback = (isLoading) => {
        updateSelection([]);
        updateCurrentRow(null);
        updateShowLoader(isLoading);
    };

    const handleDelDrivers = useCallback(async () => {
        const driverList = _.get(submissionVM, driversPath);
        const autoPrefillDataDrivers = [];
        const manuallyAddDrivers = [];
        const manuallyAddUnSavedDrivers = [];
        selection.sort((a, b) => {
            return b - a;
        });
        const driversToRemove = [];
        _.forEach(selection, (index) => {
            const driverNode = driverList.children.find((item) => item.value.rowIdPath === index);
            const driver = driverList.value.find((item) => item.rowIdPath === index);
            const isSavedDriver = _.get(driver, 'publicID');
            if (_.get(driver, 'isAutoPrefillData_Ext')) {
                _.set(driverNode, 'person.removePrefillReason_Ext', undefined);
                _.set(driverNode, 'person.explaination_Ext', undefined);
                autoPrefillDataDrivers.push(driverNode);
            } else if (isSavedDriver) {
                manuallyAddDrivers.push(driver);
            } else {
                manuallyAddUnSavedDrivers.push(driver);
            }
            driversToRemove.push(driver);
        });
        if (!_.isEmpty(autoPrefillDataDrivers)) {
            // remove ADP drivers
            ADPDriverUtil.delADPDrivers({
                autoPrefillDataDrivers, submissionVM, policyChangeService, authHeader, translator, delADPCallback, modalApi
            }).then((res) => {
                if (res) {
                    delManuallyDrivers(manuallyAddDrivers, manuallyAddUnSavedDrivers, false);
                }
            }).catch(() => {
                _.noop();
            });
        } else if (!_.isEmpty(manuallyAddDrivers) || !_.isEmpty(manuallyAddUnSavedDrivers)) {
            // remove manually added drivers
            delManuallyDrivers(manuallyAddDrivers, manuallyAddUnSavedDrivers, true);
        }
    }, [authHeader, delManuallyDrivers, policyChangeService, selection, submissionVM, translator]);

    const onDriverValidationIconCell = useCallback((items, index) => {
        const driversVMColl = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
        const driverVM = driversVMColl.find((item) => item.value.rowIdPath === index);
        const valid = DriverValidationUtil.getDriverValid(driverVM, validationIssues, baseData, wizardPageData);
        const validForIssuance = DriverValidationUtil.getDriverValidForIssuance(driverVM);
        const iconMessages = {
            validToolTip: translator(messages.validToolTip),
            inValidToolTip: translator(messages.inValidToolTip),
            inValidForInsuranceToolTip: translator(messages.inValidForInsuranceToolTip)
        };
        const invalidIcounUtil = InValidIconUtil({ iconMessages });
        return invalidIcounUtil.renderInValidIcon(valid, validForIssuance);
    }, [submissionVM, validationIssues, translator]);

    const viewOrEditDriver = useCallback((value, index) => {
        const driversVMColl = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
        const driverVM = driversVMColl.find((item) => item.value.rowIdPath === index);
        DriverUtil.updatePolicyCountryForDriver(driverVM, policyCountryCode);
        updateShowErrors(false);
        updateCurrentRow(driverVM);
        updateInitCurrentRow(viewModelService.clone(driverVM));
    }, [submissionVM]);

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        let res = {};
        if (_.isFunction(policyChangeService.getErrorsAndWarnings)) {
            res = await policyChangeService.getErrorsAndWarnings();
        } else {
            res = await WniDriverService.getErrorsAndWarnings(quoteID, authHeader);
        }
        const allValidationIssues = DriverValidationUtil
            .getAllValidationIssues(submissionVM, res, translator, messages);
        const errorIssues = _.filter(allValidationIssues, (issue) => {
            return _.endsWith(issue.type, 'error');
        });
        updateValidationIssues(allValidationIssues);
        if (errorIssues.length > 0) {
            updateShowErrors(false);
            setLoadingMask(false);
            return false;
        }
        if (allValidationIssues.length > 0) {
            if (!showErrors) {
                updateShowErrors(true);
                setLoadingMask(false);
                return false;
            }
        }

        // To be turned on only whne Driver Data has been updated.
        const shouldUpdateCreditReport = !_.isFunction(policyChangeService.getErrorsAndWarnings);
        if (periodStatus === 'Draft' && shouldUpdateCreditReport) {
            await WniDriverService.updateCreditReport(quoteID, sessionUUID, authHeader);
        }
        setLoadingMask(false);
        return submissionVM;
    }, [submissionVM, showErrors]);

    const skipCoveragesPage = useCallback(async () => {
        if (onPageNext()) {
            setLoadingMask(false);
            let pageId = 'PADriversCoveragesPage';
            if (_.isFunction(policyChangeService.getErrorsAndWarnings)) {
                pageId = 'PCPADriverCoveragesPage';
            }
            const pageIndex = steps.findIndex((step) => step.id === pageId);
            if (pageIndex < 0) {
                return _.noop;
            }
            jumpTo(pageIndex + 1, pageIndex + 1);
        }
    }, [jumpTo, onPageNext, setLoadingMask, showErrors]);

    const renderViewEditDom = () => {
        return (
            <div className="activeRow btn-link">
                {translator(messages.viewAndEditLabel)}
            </div>
        );
    };
    const getValidationIssues = () => {
        return validationIssues
            .concat(pcDisplayIssues)
            .concat(wizardErrorsAndWarnings)
            .concat(
                OOSUtil.getOOSSliceDatesWarning(
                    OOSUtil.getOOSSliceDatesString(
                        oossliceDates,
                        effectiveDate
                    ),
                    translator
                )
            );
    };

    const issueJumpToFieldFn = (issueItem) => {
        const { relatedEntity } = issueItem;
        if (!relatedEntity) {
            return false;
        }
        viewOrEditDriver(relatedEntity, relatedEntity.publicID);
        const errorAccordionIds = accordionErrorId();
        if (relatedEntity.fieldName === 'AgeFirstLicensed_Ext') {
            if (!errorAccordionIds.includes('memberLicenseInfo')) {
                errorAccordionIds.push('memberLicenseInfo');
            }
            updateAccordionOpendIds(errorAccordionIds);
            setTimeout(() => {
                const driverAge = document.getElementById('driverAgeFirstLicensed');
                if (!driverAge) return;
                driverAge.classList.add('invalid');
                WindowUtil.scrollToInvalidField(['driverAgeFirstLicensed']); // scroll to the invalid fields
            }, 500);
        }
    };
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            validationIssues: getValidationIssues(),
            visible: !_.isEmpty(validationIssues) || !_.isEmpty(pcDisplayIssues)
            || !_.isEmpty(wizardErrorsAndWarnings)
            || OOSUtil.getOOSSliceDatesString(
                oossliceDates,
                effectiveDate
            ).length > 0,
            scrollToIssues: true,
            issueRenderFn: DomRenderUtil.issueRenderFn,
            issueJumpToFieldFn: issueJumpToFieldFn
        },
        delDriver: {
            disabled: selection.length <= 0
        },
        addDriver: {
            // disabled: currentRow || !isComponentValid || hasInvalidDriver
            disabled: currentRow
        },
        driverTable: {
            data: driverData,
            onSelectionChange: (rows) => updateSelection(rows),
            rowIdPath: 'rowIdPath'
        },
        driverValidationIcon: {
            renderCell: onDriverValidationIconCell
        },
        driverIndex: {
            renderCell: (value, index) => {
                const driversData = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
                return driversData.findIndex((item) => item.rowIdPath === index) + 1;
            }
        },
        name: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.rowIdPath, DriverUtil.onNameCell(item))
        },
        gender: { renderCell: (item) => DriverUtil.onGenderCell(item, translator) },
        // maritalStatus: { renderCell: DriverUtil.onMaritalStatusCell },
        relationship: { renderCell: (item) => DriverUtil.onRelationshipCell(item, translator) },
        ratedDriver: {
            renderCell: (item) => DriverUtil.onRatedDriverCell(item, periodStatus)
        },
        dateOfBirth: { renderCell: DriverUtil.onDateOfBirthCell },
        licensed: { renderCell: DriverUtil.onLicensedCell },
        driver: {
            visible: currentRow != null,
            value: currentRow,
            initValue: initCurrentRow,
            serviceData: {
                sessionUUID,
                quoteID
            },
            onValueChange: (value, path) => {
                if (currentRow.value) {
                    _.set(currentRow, path, value);
                }
                updateWizardData(submissionVM);
            },
            baseState: _.get(submissionVM, 'value.baseData.baseState_Ext'),
            primaryNamedInsured: _.get(submissionVM, 'value.baseData.accountHolder.displayName'),
            policyChangeUpdateCoverages: policyChangeService.updateCoverages,
            showErrors,
            baseData: _.get(submissionVM, 'value.baseData'),
            validationIssues,
            accordionOpendIds,
            updateAccordionOpendIds,
            wizardPageData
        },
        viewOrEdit: {
            label: renderViewEditDom(),
            disabled: currentRow
        },
        delete: {
            label: translator(messages.deleteLabel),
        },
        skipCoveragesButton: {
            onClick: skipCoveragesPage,
            visible: !currentRow && !nameNonOwner
        }
    };

    const getFooterButtons = useCallback(() => {
        if (!currentRow) {
            return (<div />);
        }
        const nextButtonVisible = _.get(driversVM, 'value').length > 1;

        return (
            <CustomFooterV2Component
                saveAndNextMessage={translator(messages.saveAndNext)}
                handleCancel={cancel}
                handleSaveAndClose={() => {
                    saveDriver(updateCurrentRow);
                }}
                handleSaveAndNext={() => {
                    saveDriver(switchToNextDriver);
                }}
                nextButtonVisible={nextButtonVisible}
            />
        );
    }, [cancel, currentRow, saveDriver, switchToNextDriver, translator]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    const hideButtonsProps = {
        showNext: !currentRow,
        showPrevious: !currentRow,
        showCancel: !currentRow
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onViewOrEditDriver: viewOrEditDriver,
            onAddDriverVM: createDriverVM,
            onDelDrivers: handleDelDrivers,
            onCancel: cancel,
            onValidate,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
        },
        resolveComponentMap: {
            drivercomponent: DriverComponent,
            CustomFooterV2Component: CustomFooterV2Component,
            ValidationIssuesComponent: ValidationIssuesComponent
        }
    };

    const renderPageContent = useCallback(({ onNext }) => {
        return showLoader ? (<Loader showLoader />) : (
            <React.Fragment>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    onModelChange={updateWizardData}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    showErrors={showErrors}
                />
                {getFooterButtons(onNext)}
            </React.Fragment>
        );
    }, [overrideProps, resolvers, showLoader, submissionVM, showErrors]);

    if (!isPageInitialized) {
        return null;
    }
    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            disableNext={showLoader || DriverUtil.hasInvalidDriver(driversVM)}
            alwaysCallOnNext
            {...hideButtonsProps}
        >
            {renderPageContent}
        </WizardPage>
    );
}

PADriversPage.propTypes = wizardProps;
export default PADriversPage;
