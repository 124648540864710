import { defineMessages } from 'react-intl';

export default defineMessages({
    caViewAndEditLabel: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.View/Edit',
        defaultMessage: 'View/Edit',
    },
    caViewLabel: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.View',
        defaultMessage: 'View',
    },
    removeDriverTitle: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.Remove Driver?',
        defaultMessage: "Remove Driver?"
    },
    removeDriverDescription: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.removeDriverDescription',
        defaultMessage: 'Are you sure you want to remove this operator from the list of operators?',
    },
    removeIncludePNIDriverDescription: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.removeIncludePNIDriverDescription',
        defaultMessage:
            'PNI operator cannot be deleted, are you sure to delete other operators?',
    },
    Ok: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.OK',
        defaultMessage: 'OK'
    },
    Cancel: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.Cancel',
        defaultMessage: 'Cancel'
    },
    importTitle: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-drivers.Import',
        defaultMessage: 'Import',
    },
    error: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-drivers.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-drivers.Something went wrong. Template was not downloaded. Please try again.',
        defaultMessage: 'Something went wrong. Template was not downloaded. Please try again.'
    },
    fasterQuote: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-drivers.fasterQuote',
        defaultMessage: 'Get a faster quote!'
    },
    requiredNotification: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-drivers.requiredNotification',
        defaultMessage: '{fasterQuote} Only fields with red asterisks {asteriskIcon} are required.For Bulk uploads of driver information select the download template button,which will provide an excel template for uploading multiple drivers at once.Once entered on the spreadsheet please click import template to upload the data.'
    },
    exportToSpreadsheet: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.Export To Spreadsheet',
        defaultMessage: 'Export To Spreadsheet',
    },
    exportTitle: {
        id: 'wni.quoteandbind.ca-wizard.ca-drivers.Export',
        defaultMessage: 'Export',
    },
    driverRead: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.driver read',
        defaultMessage: "Number of { driverType } Entities Read:"
    },
    driverEdited: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.driver edited',
        defaultMessage: "Number of { driverType } Entities Edited:"
    },
    driverAdded: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.driver added',
        defaultMessage: "Number of { driverType } Entities Added:"
    },
    driverRemoved: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.driver removed',
        defaultMessage: "Number of { driverType } Entities removed:"
    },
    MVRWarning: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.MVRWarning',
        defaultMessage: "Per underwriting requirements, a Motor Vehicle Report may be obtained for drivers. Please use this { driverReleaseFormLink } to collect signature of consent."
    },
    driverReleaseFormLink: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.driverReleaseFormLink',
        defaultMessage: "Driver's Release Authorization Form"
    },
    downloadDriverReleaseFormTitle: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.downloadDriverReleaseFormTitle',
        defaultMessage: "Click here to download the document."
    },
    noDocMessage: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.noDocMessage',
        defaultMessage: "There is no doc, please check whether the doc is generated first."
    },
    importErrorMessages: {
        id: 'quoteandbind.ca.directives.templates.upload.error message',
        defaultMessage: 'Error(s) have been identified while attempting to upload. It is required to address each error'
    },
    importDriverNote: {
        id: 'quoteandbind.ca.directives.templates.ca-edit-driver.Import Note',
        defaultMessage: 'Please Note: Any imported data must follow the template design. No fields can be added and all spreadsheet errors must be addressed before the entries can be accepted into the quote.'
    }
});