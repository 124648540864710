import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'quoteandbind.wizard.step.Policy Details',
        defaultMessage: 'Policy Details'
    },
    coverageStartDate: {
        id: 'gw-portals-policytransaction-pc-cp-angular.policyDetails.policyDetails.Policy Detail Effective Date',
        defaultMessage: 'Coverage Start Date'
    },
    pleaseChoose: {
        id: 'gw-portals-policytransaction-pc-cp-angular.policyDetails.policyDetails.-- Please Choose --',
        defaultMessage: '-- Please Choose --'
    },
    buildingsAndLocationsStep: {
        id: 'quoteandbind.cp.wizard.step.Buildings and Locations',
        defaultMessage: 'Buildings and Locations'
    },
    buildingsAndLocations: {
        id: 'quoteandbind.cp.entryscreen.Buildings & Locations',
        defaultMessage: 'Buildings & Locations'
    },
    useSpreadsheet: {
        id: 'quoteandbind.cp.entryscreen.Use spreadsheet?',
        defaultMessage: 'Use spreadsheet?'
    },
    getStarted: {
        id: 'quoteandbind.cp.entryscreen.Get Started',
        defaultMessage: 'Get Started'
    },
    enterInformationManually: {
        id: 'quoteandbind.cp.entryscreen.Enter information manually?',
        defaultMessage: 'Enter information manually?'
    },
    addBuilding: {
        id: 'quoteandbind.cp.entryscreen.Add Building',
        defaultMessage: 'Add Building'
    },
    or: {
        id: 'quoteandbind.cp.entryscreen.or',
        defaultMessage: 'or'
    },
    addBuildingsUsingExcel: {
        id: 'quoteandbind.cp.entryscreen.Add buildings and locations using excel spreadsheets. Need more information?',
        defaultMessage: 'Add buildings and locations using {excel} {faqlink}'
    },
    excelSpreadsheets: {
        id: 'quoteandbind.cp.entryscreen.Excel spreadsheets.',
        defaultMessage: 'Excel spreadsheets.'
    },
    faqInlineQuestions: {
        id: 'faq.topics.deductible_payment.inline_question',
        defaultMessage: 'Why do I have to pay a deductible?'
    },
    needMoreInformation: {
        id: 'quoteandbind.cp.entryscreen.Need more information?',
        defaultMessage: 'Need more information?'
    },
    fillFormDetails: {
        id: 'quoteandbind.cp.entryscreen.Add buildings and locations by filling in their details in the form.',
        defaultMessage: 'Add buildings and locations by filling in their details in the form.'
    },
    location: {
        id: 'quoteandbind.cp.views.cp-location.Location',
        defaultMessage: 'Location'
    },
    existingLocation: {
        id: 'quoteandbind.cp.views.cp-location.Existing Location',
        defaultMessage: 'Existing Location'
    },
    newLocation: {
        id: 'quoteandbind.cp.views.cp-location.New Location',
        defaultMessage: 'New Location'
    },
    locationCode: {
        id: 'quoteandbind.cp.views.cp-location.Location Code',
        defaultMessage: 'Location Code'
    },
    chooseState: {
        id: 'quoteandbind.cp.views.contact-details.-- Please Choose --',
        defaultMessage: '-- Please Choose --'
    },
    phoneNumber: {
        id: 'quoteandbind.cp.views.cp-location.Phone Number',
        defaultMessage: 'Phone Number'
    },
    fireProtection: {
        id: 'quoteandbind.cp.views.cp-location.Fire Protection',
        defaultMessage: 'Fire Protection'
    },
    chooseFireProtection: {
        id: 'quoteandbind.cp.views.cp-location.-- Fire Protection --',
        defaultMessage: '-- Choose Fire Protection --'
    },
    editBuilding: {
        id: 'quoteandbind.cp.wizard.titles.Edit Building',
        defaultMessage: 'Edit Building'
    },
    propertyClassCode: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Property Class Code',
        defaultMessage: 'Property Class Code'
    },
    searchPropertyCodes: {
        id: 'quoteandbind.cp.views.cp-building.Search property codes',
        defaultMessage: 'Search property codes'
    },
    buildingDescription: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Building Description',
        defaultMessage: 'Building Description'
    },
    yearBuilt: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Year Built',
        defaultMessage: 'Year Built'
    },
    constructionType: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Construction Type',
        defaultMessage: 'Construction Type'
    },
    constructionTypePlaceholder: {
        id: 'quoteandbind.cp.directives.cp-building-directive.-- Construction Type --',
        defaultMessage: '-- Construction Type --'
    },
    numberOfStories: {
        id: 'quoteandbind.cp.directives.cp-building-directive.# of Stories',
        defaultMessage: 'Number of Stories'
    },
    totalAreaExcludingBasement: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Total Area (excl basement)',
        defaultMessage: 'Total Area Excluding Basement'
    },
    percentageSprinklered: {
        id: 'quoteandbind.cp.directives.cp-building-directive.% Sprinklered',
        defaultMessage: 'Percentage Sprinklered'
    },
    percentageSprinkleredPlaceholder: {
        id: 'quoteandbind.cp.directives.cp-building-directive.-- % Sprinklered --',
        defaultMessage: '-- Percentage Sprinklered --'
    },
    exposure: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Exposure',
        defaultMessage: 'Exposure'
    },
    alarm: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Alarm',
        defaultMessage: 'Alarm'
    },
    alarmType: {
        id: 'quoteandbind.cp.directives.cp-building-directive.Alarm Type',
        defaultMessage: 'Alarm Type'
    },
    editCoverages: {
        id: 'quoteandbind.cp.views.CP-Building-Coverages-Summary-Directive.Edit Coverages',
        defaultMessage: 'Edit Coverages'
    },
    locationBuildingDescription: {
        id: 'quoteandbind.cp.views.cp-location-view.Building Description',
        defaultMessage: 'Building Description'
    },
    buildingClassCode: {
        id: 'quoteandbind.cp.views.cp-location-view.Building Class Code',
        defaultMessage: 'Building Class Code'
    },
    searchForBuildings: {
        id: 'quoteandbind.cp.views.cp-locations-and-buildings.Search for buildings or locations',
        defaultMessage: 'Search for buildings or locations'
    },
    useASpreadsheet: {
        id: 'quoteandbind.cp.wizard.titles.Use a Spreadsheet',
        defaultMessage: 'Use a Spreadsheet'
    },
    addBuildingTitle: {
        id: 'quoteandbind.cp.wizard.titles.Add Building',
        defaultMessage: 'Add Building'
    },
    backToList: {
        id: 'quoteandbind.cp.views.cp-building-view.Back',
        defaultMessage: 'Back'
    },
    save: {
        id: 'quoteandbind.cp.Save',
        defaultMessage: 'Save'
    },
    cancel: {
        id: 'quoteandbind.cp.Cancel',
        defaultMessage: 'Cancel'
    },
    buildingSaved: {
        id: 'quoteandbind.cp.Building saved',
        defaultMessage: 'Building saved.'
    },
    changesSaved: {
        id: 'quoteandbind.cp.Saved Title',
        defaultMessage: 'Changes Saved'
    },
    locationSummary: {
        id: 'quoteandbind.cp.directives.CPPremisesSummaryDirective.Location',
        defaultMessage: 'Location'
    },
    buildingSummary: {
        id: 'quoteandbind.cp.directives.CPPremisesSummaryDirective.Building',
        defaultMessage: 'Building'
    },
    coveragesSummary: {
        id: 'quoteandbind.cp.directives.CPPremisesSummaryDirective.Coverages',
        defaultMessage: 'Coverages'
    },
    fullAddress: {
        id: 'quoteandbind.cp.directives.cp-location-directive.Full Address',
        defaultMessage: 'Full Address'
    },
    locationCodeSummary: {
        id: 'quoteandbind.cp.directives.cp-location-directive.Location Code',
        defaultMessage: 'Location Code'
    },
    phone: {
        id: 'quoteandbind.cp.directives.cp-location-directive.Phone',
        defaultMessage: 'Phone'
    },
    territoryCode: {
        id: 'quoteandbind.cp.directives.cp-location-directive.Territory Code',
        defaultMessage: 'Territory Code'
    },
    territoryCodeTooltip: {
        id: 'quoteandbind.cp.directives.cp-location-directive.If you feel this code is incorrect please create an activity for the assigned underwriter',
        defaultMessage: 'If you feel this code is incorrect please create an activity for the assigned underwriter'
    },
    fireProtectionSummary: {
        id: 'quoteandbind.cp.directives.cp-location-directive.Fire Protection',
        defaultMessage: 'Fire Protection'
    },
    addBuildingHeader: {
        id: 'quoteandbind.cp.Add Building',
        defaultMessage: 'Add Building'
    },
    cancelAddingBuilding: {
        id: 'quoteandbind.cp.Cancel Adding Building?',
        defaultMessage: 'Cancel Adding Building?'
    },
    cancelConfirmation: {
        id: 'quoteandbind.cp.The information you have already provided about this building will be lost. Are you sure you want to cancel?',
        defaultMessage: 'The information you have already provided about this building will be lost. Are you sure you want to cancel?'
    },
    addAnotherBuilding: {
        id: 'quoteandbind.cp.Add Another Building',
        defaultMessage: 'Add Another Building'
    },
    done: {
        id: 'quoteandbind.cp.Done',
        defaultMessage: 'Done'
    },
    yes: {
        id: 'quoteandbind.cp.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.cp.No',
        defaultMessage: 'No'
    },
    editLocation: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Edit Location',
        defaultMessage: 'Edit Location'
    },
    editBuildingHeader: {
        id: 'quoteandbind.cp.directives.cp-building-header.Edit Building',
        defaultMessage: 'Edit Building'
    },
    editBuildingDetails: {
        id: 'quoteandbind.cp.views.CP-Building-Summary-Directive.Edit Building Details',
        defaultMessage: 'Edit Building Details'
    },
    deleteBuilding: {
        id: 'quoteandbind.cp.Delete Building',
        defaultMessage: 'Delete Building'
    },
    addBuildingToThisLocation: {
        id: 'quoteandbind.cp.Add Building to this Location',
        defaultMessage: 'Add Building to this Location'
    },
    deleteBuildingTooltip: {
        id: 'quoteandbind.cp.views.cp-building-view.Delete Building',
        defaultMessage: 'Delete Building'
    },
    deleteConfirmation: {
        id: 'quoteandbind.cp.views.cp-building-view.Are you sure you want to delete this building?',
        defaultMessage: 'Are you sure you want to delete this building?'
    },
    proceedToDelete: {
        id: 'quoteandbind.cp.views.cp-building-view.Yes, please delete',
        defaultMessage: 'Yes, please delete'
    },
    cancelDelete: {
        id: 'quoteandbind.cp.views.cp-building-view.No, Ill keep it',
        defaultMessage: 'No, I\'ll keep it'
    },
    buildingsAtThisLocation: {
        id: 'quoteandbind.cp.views.cp-location-view.Buildings at this location:',
        defaultMessage: 'Buildings at this location: {buildingsCount}'
    },
    inlineNotification: {
        id: 'quoteandbind.cp.views.cp-location-view.There must be at least one building per location',
        defaultMessage: 'There must be at least one building per location'
    },
    locationDetails: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Location Details',
        defaultMessage: 'Location Details'
    },
    coveragesNotSavedErrorTitle: {
        id: 'quoteandbind.cp.buildingsWizard.EditCoverages.Coverages not saved',
        defaultMessage: 'Coverages not saved'
    },
    coveragesNotSavedErrorMessage: {
        id: 'quoteandbind.cp.Please check for errors and try again',
        defaultMessage: 'Please check for errors and try again'
    },
    nextButtonTooltipForBuildings: {
        id: 'quoteandbind.cp.entryscreen.You need to add at least 1 building to get a quote.',
        defaultMessage: 'You need to add at least 1 building to get a quote.'
    },
    anErrorOccurred: {
        id: 'quoteandbind.cp.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.cp.wizard.error.cancel.title',
        defaultMessage: 'Error'
    },
    unsavedChangesTitle: {
        id: 'quoteandbind.cp.Unsaved Changes',
        defaultMessage: 'Unsaved Changes'
    },
    unsavedChangesMessage: {
        id: 'quoteandbind.cp.Do you want to save your changes?',
        defaultMessage: 'Do you want to save your changes?'
    },
    newLabel: {
        id: 'quoteandbind.cp.views.cp-locations-and-buildings.new',
        defaultMessage: 'new'
    },
    editLocationCancelChangesTitle: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Cancel Changes?',
        defaultMessage: 'Cancel Changes?'
    },
    editLocationCancelChangesMessage: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.You will lose all the change youve made. Are you sure you want to cancel?',
        defaultMessage: 'You will lose all the change you\'ve made. Are you sure you want to cancel?'
    },
    editLocationCancelChangesConfirmationYes: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.Yes',
        defaultMessage: 'Yes'
    },
    editLocationCancelChangesConfirmationNo: {
        id: 'quoteandbind.cp.buildingsWizard.EditLocation.No',
        defaultMessage: 'No'
    },
    editBuildingCancelChangesTitle: {
        id: 'quoteandbind.cp.buildingsWizard.EditBuilding.Cancel Changes?',
        defaultMessage: 'Cancel Changes?'
    },
    editBuildingCancelChangesMessage: {
        id: 'quoteandbind.cp.buildingsWizard.EditBuilding.You will lose all the changes',
        defaultMessage: 'You will lose all the changes you\'ve made. Are you sure you want to cancel?'
    },
    saveQuoteError: {
        id: 'quoteandbind.cp.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'quoteandbind.cp.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    }
});
