import _ from 'lodash';

function showSendViaEmailPopup(
  modalApi,
  componentProps,
  BaseDocumentSendViaEmailPopup,
  BaseDocumentCheckedComponentMessages
) {
  return modalApi.showModal(<BaseDocumentSendViaEmailPopup {...componentProps} />)
    .then((res) => {
        if (res) {
            modalApi.showAlert({
                title: BaseDocumentCheckedComponentMessages.success,
                message: BaseDocumentCheckedComponentMessages.successContent,
                status: 'info',
                icon: 'gw-info-outline',
                confirmButtonText: BaseDocumentCheckedComponentMessages.close
            }).then(() => {
                _.noop();
            }, _.noop);
        } else {
            modalApi.showAlert({
                title: BaseDocumentCheckedComponentMessages.notSend,
                message: BaseDocumentCheckedComponentMessages.notSendContent,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: BaseDocumentCheckedComponentMessages.close
            }).then(() => {
                _.noop();
            }, _.noop);
        }
    }).catch(() => {
        _.noop();
    });
}

export default {
    showSendViaEmailPopup
};
