import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { VehicleUtil, WindowUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import {
    AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from '../AddressInputComponent/AddressVerifiedUtil';

import AddressInputComponent from '../AddressInputComponent/AddressInputComponent';
import metadata from './AddressInputPopupComponent.metadata.json5';
import messages from './AddressInputPopupComponent.messages';
import styles from './AddressInputPopupComponent.module.scss';

import { Button } from '@jutro/legacy/components';

function AddressInputPopupComponent(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        addressDataVM,
        addressDataListVM,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
    } = props;
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [addressVM, updateaddressVM] = useState(addressDataVM);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isAddressFlag, updateAddressFlag] = useState(false);
    const modalApi = useWniModal();

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('AddressInputPopup');

    
    // const isAddressValid = useCallback(() => {
    //     const {
    //         firstName,
    //         lastName,
    //         primaryAddress: {
    //             pobox_Ext: pobox,
    //             addressLine1,
    //             postalCode,
    //             city,
    //             county,
    //             state
    //         }
    //     } = addressVM.value;
    //     const contactDetailsValid = !_.isEmpty(firstName)
    //         && !_.isEmpty(lastName);
    //     const addressValidField = !_.isEmpty(postalCode)
    //         && !_.isEmpty(county)
    //         && !_.isEmpty(city)
    //         && !_.isEmpty(state);
    //     const addressIsValid = !_.isEmpty(pobox) ? addressValidField
    //         : (addressValidField && !_.isEmpty(addressLine1));
    //     if (addressIsValid && contactDetailsValid) {
    //         return true;
    //     }
    //     return false;
    // }, [addressVM.value]);

    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);

    // useEffect(() => {
    //     registerComponentValidation(isAddressValid);
    // }, [registerComponentValidation, isAddressValid]);


    const writeValue = useCallback((value, path) => {
        // if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
        //     // address change, the warning message about invaild address set hide
        //     const verifyMsg = getVerifyAddressIssues(false);
        //     updateLookupValidation(verifyMsg);
        //     // set the flag false, and click next button, verify address again
        //     updateAddressFlag(false);
        // }
        const newVM = viewModelService.clone(addressVM);
        _.set(newVM, path, value);
        updateaddressVM(newVM);
    }, [viewModelService, addressVM, updateLookupValidation]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const handleSave = useCallback(
        async () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            const uiUtil = AddressVerifiedUtil({
                authHeader,
                addressVM: _.get(addressVM, 'primaryAddress'),
                addressPath: 'primaryAddress',
                updateValidations: updateLookupValidation,
                writeValue,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                doublePopup: true,
                //
                modalApi,
            });
            const verifiedObj = await uiUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }
            onResolve(addressVM);
        },
        [isComponentValid, authHeader, addressVM, updateLookupValidation, writeValue, isAddressFlag, onResolve, handleValidation]
    );

    const handleAddressChange = useCallback((value) => {
        if (value === _.get(addressVM.value, 'publicID', null)) {
            return;
        }
        const newAddressIndex = _.get(addressDataListVM, 'value', []).findIndex(
            (addressDataList) => addressDataList.publicID === value
        );
        const newAddressVM = addressDataListVM
            .getElement(newAddressIndex);
        updateaddressVM(newAddressVM);
    }, [addressDataListVM, addressVM.value]);
     
    const handleAddressLocationChange = useCallback((code) => {
        if (code === 'new') {
            writeValue({
                tempCode: 'new',
                displayName: translator(messages.paVehicleGaragedAtNew),
                country: 'US'
            }, 'addressVM.value');
            // not show errors when new garaged at
            // updateShowGaragedAtErrors(false);
        } else {
            writeValue(addressDataListVM.value.find((option) => {
                return option.tempCode === code;
            }), 'addressVM.value');
        }
    }, [writeValue, translator, addressDataListVM.value]);
    useEffect(() => {
        addressDataListVM.value.forEach((option) => {
            // eslint-disable-next-line no-param-reassign
            option.tempCode = option.publicID;
        });
    }, [addressDataListVM.value]);
    
    const getAddressOptions = useCallback(() => {
        const options = addressDataListVM.value.map((location) => {
            return {
                code: location.publicID,
                name: location.address.displayName
            };
        });
        options.push({
            code: 'new',
            name: translator(messages.new),
            country: 'US'
        });
        return options;
    }, [addressDataListVM.value, translator]);

    const getAddressToDisplayValues = useCallback(() => {
        if (addressVM.value.publicID) {
            if (addressVM.value.address.tempCode) {
                return addressVM.value.address.tempCode;
            }
            return addressVM.value.publicID;
        }
        return undefined;
    }, [addressVM.value.address])

    const addressToDisplay = getAddressToDisplayValues()
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        locationDropdown: {
            onValueChange: handleAddressLocationChange,
            value: addressToDisplay,
            availableValues: getAddressOptions(),
            // dataType: 'object'
        },
        primaryAddressCard: {
            title: translator(messages.primaryAddress)
        },
        addressContainer: {
            model: addressVM,
            dataPath: 'address',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true,
                pobox: true
            },
            onValidate
        },
    };

    const resolvers = {
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            validationissuescomponent: ValidationIssuesComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFullNameChange: handleAddressChange,
        }

    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title || translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={addressVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

AddressInputPopupComponent.propTypes = {
    addressDataVM: PropTypes.shape({}).isRequired,
    addressDataListVM: PropTypes.shape({
        getElement: PropTypes.func.isRequired,
    }).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};

AddressInputPopupComponent.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md'
};

export default AddressInputPopupComponent;
