import React, {
    useCallback,
    useContext,
    useState,
} from "react";
import _ from 'lodash'
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import CoverageContext from '../../../../context/WCCoverageContext'
import SingleClauseContext from '../../../../context/WCSingleClauseContext'
import WCClausesComponentVM from '../../../WCClausesComponentVM/WCClausesComponentVM'
// Here's a cycle dependency, clause own schedule items and schedule items own clauses
// eslint-disable-next-line import/no-cycle
import WCSingleClauseComponentVM from '../../../WCSingleClauseComponentVM/WCSingleClauseComponentVM'
import CoverageUtil from '../../../../util/WCCoverageUtil'
import messages from './WCComplexSchedulePopupCoverages.messages'

const WCComplexSchedulePopupCoverages = (props) => {
    const {
        schedule,
        openedScheduleItemNumber,
        onValidate,
        showErrors,
        isEditing,
        setIsEditing,
    } = props

    const {
        scheduleItems,
    } = schedule

    const translator = useTranslator();

    if (_.isNil(openedScheduleItemNumber)) {
        return null
    }

    const openedScheduleItemIndex = scheduleItems.findIndex((scheduleItem) => scheduleItem.itemNumber === openedScheduleItemNumber)
    const openedScheduleItem = scheduleItems[openedScheduleItemIndex]

    const {
        wizardData: submissionVM,
        updateWizardData,
        lobName,
        selectedVersionIndex,
        coveragesService,
    } = useContext(CoverageContext)

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();
    
    const {
        clausePath: clauseVMPath,
        isEditable,
    } = useContext(SingleClauseContext)

    const [loadingClauses, setLoadingClauses] = useState([])

    const getScheduledItemClauses = useCallback(() => {
        const scheduledItemClauses = _.get(openedScheduleItem, 'clauses', []);
        return scheduledItemClauses;
    }, [openedScheduleItem]);

    const scheduledItemClausesVMPath = `${clauseVMPath}.schedule.scheduleItems.children[${openedScheduleItemIndex}].clauses`;
    const scheduledItemClausesPath = `${clauseVMPath.replaceAll('.children', '')}.schedule.scheduleItems[${openedScheduleItemIndex}].clauses`;
    const scheduleItemClauses = getScheduledItemClauses();

    const updateSubmissionVMToServer = useCallback(async (updatedSubVMInPE) => {
        const coverages = _.get(updatedSubVMInPE.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = CoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedClausesCodes = CoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingClauses(updatedClausesCodes);
        const response = await coveragesService.updateLineCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        const newSubVMUpdatedByRes = _.clone(updatedSubVMInPE)
        _.set(newSubVMUpdatedByRes.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingClauses(false);
        updateWizardData(newSubVMUpdatedByRes);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        // setLoadingLineCoverages,
    ]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = CoverageUtil.setClauseValue(scheduleItemClauses, scheduledItemClausesVMPath, value, changedPath);
            _.set(submissionVM.value,`${clauseVMPath.replaceAll('.children', '')}.schedule.updated_Ext`, true)
            _.set(submissionVM.value, scheduledItemClausesPath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            clauseVMPath,
            submissionVM,
            scheduleItemClauses,
            scheduledItemClausesVMPath,
            scheduledItemClausesPath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubVMUpdatedInPE = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubVMUpdatedInPE);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    if (_.isEmpty(scheduleItemClauses)) {
        return null
    }

    return <>
        <h5>{translator(messages.scheduledItemCoveragesTitle)}</h5>
        <div className="formCoveragesContainer">
            <WCClausesComponentVM
                splitByClauseCategory= {false}
                sortBySelected
                onSyncCoverages= {onSyncCoverages}
                onChangeSubmissionAndSync = {changeSubmissionAndSync}
                onChangeClause = {changeSubmission}
                onValidate = {onValidate}
                showAmount = {false}
                loadingClause = {loadingClauses}
                path = {scheduledItemClausesVMPath}
                value = {scheduleItemClauses}
                showErrors ={showErrors}
                componentMapOverrides = {{
                    WCSingleClauseComponentVM: WCSingleClauseComponentVM,
                }}
                setIsEditing ={setIsEditing}
                isEditing = {isEditing}
                isEditable={isEditable}
            />
        </div>
    </>
}

export default WCComplexSchedulePopupCoverages