import React, {
 
} from 'react';
import _ from 'lodash';


import GLRiskAnalysisPage from './GLRiskAnalysisPage';


function GLRiskAnalysisReadOnlyPage(props) {

    return <GLRiskAnalysisPage {...props} isReadOnly />
}


export default GLRiskAnalysisReadOnlyPage;