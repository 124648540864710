import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('rtPolicyInfoChangeService'), method, data, additionalHeaders);
}

export default class RTPolicyInfoChangeService {

    /**
     * Invokes RTPolicyInfoChangeService.saveRTPolicyInfoData()
     * @param {String} quoteID 
     * @param {String} sessionUUID 
     * @param {object} questionSetData 
     * @param {Object} quoteBaseData 
     * @param {Object} policyInfoData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveRTPolicyInfoData(quoteID, sessionUUID, questionSetData, quoteBaseData, policyInfoData, authHeader) {
        return processSubmission('saveRTPolicyInfoData', [quoteID, sessionUUID, questionSetData, quoteBaseData, policyInfoData], authHeader);
    }

}
