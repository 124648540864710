// import AOConfig from './agentsonline/AOConfig';

import PACoverageConfig from './static/PACoverage.config';
import DriversPageConfig from './static/PADriversPage.config';
import VehiclesPageConfig from './static/PAVehiclesAPage.config';
import PolicyInformationPageConfig from './static/PAPolicyInformationPage.config';
import PEPAWizardConfig from './static/PEPAWizard.config';
import QuotePageConfig from './static/PAQuotePage.config';
import R1ValidationMessages from './messages/R1Validation.messages';
import WniTableConfig from './static/WniTable.config';
import UWQuestionConfig from './static/UWQuestion.config';

// const allAccountStates = [
//     {
//         code: 'AK',
//         name: 'Alaska',
//     },
//     {
//         code: 'SD',
//         name: 'South Dakota',
//     },
//     {
//         code: 'WI',
//         name: 'Wisconsin',
//     },
//     {
//         code: 'MN',
//         name: 'Minnesota',
//     },
//     {
//         code: 'IA',
//         name: 'Iowa',
//     },
// ];
// const availableAccountStates = AOConfig.filterStatesByCode(allAccountStates);
// const availableAccountStateCodes = availableAccountStates.map((state) => state.code);

const availablePATermTypeCodes = ['HalfYear', 'Annual'];

// change coverages order
const coveragePriorityOverride = {
    PAStatedAmtColl_Ext: 4050, // vehicle, original is 6230
    PALtdTNDCov_Ext: 8500, // vehicle, original is 9300
    PALtdTNDAKCov_Ext: 8600, // vehicle, original is 9400
    PALtdTNDMNCov_Ext: 8700, // vehicle, original is 9500
};
const coveragesContaingLongFirstItem = [
    'PALtdTNDCov_Ext', // Limited Transportation Network Driver Coverage (No Passenger)
    'PALtdTNDAKCov_Ext', // Limited Transportation Network Driver Coverage (No Passenger) - Alaska
    'PALtdTNDMNCov_Ext', // Limited Transportation Network Driver Coverage (No Passenger) - Minnesota
    'PATNDCov_Ext', // Transportation Network Driver Coverage (No Passenger)
    'PATNDAKCov_Ext', // Transportation Network Driver Coverage (No Passenger) - Alaska
    'PATNDMNCov_Ext', // Transportation Network Driver Coverage (No Passenger) - Minnesota
];


export default {
    description: 'This is the WNI Portal config for Release 1',
    // availableAccountStates,
    // availableAccountStateCodes,
    availablePATermTypeCodes,
    coveragePriorityOverride,

    PACoverageConfig,
    DriversPageConfig,
    VehiclesPageConfig,
    PolicyInformationPageConfig,
    QuotePageConfig,
    PEPAWizardConfig,
    coveragesContaingLongFirstItem,
    R1ValidationMessages,
    WniTableConfig,
    UWQuestionConfig,
};
