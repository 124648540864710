import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
// import PCPAAddressPage from 'gw-capability-policychange-pa-react/pages/Address/AddressPage';
// import PCPADriverPage from 'gw-capability-policychange-pa-react/pages/Drivers/DriversPage';

// mode: start
import PAPolicyChangeStartPage from './pages/PolicyChangeStartPage/PAPolicyChangeStartPage';

// mode: default / editable
import PAPolicyDetailsChangePage from './pages/PolicyDetails/PAPolicyDetailsChangePage';
import PADriversChangePage from './pages/Drivers/PADriversChangePage';
import PAVehiclesChangePage from './pages/Vehicles/PAVehiclesChangePage';
import PACoveragesChangePage from './pages/Coverages/PACoveragesChangePage';
import PADriverAssignmentChangePage from './pages/DriverAssignment/PADriverAssignmentChangePage';
import PAIncidentsAndReportsChangePage from './pages/IncidentsAndReports/PAIncidentsAndReportsChangePage';
import PAQuoteChangePage from './pages/Quote/PAQuoteChangePage';
import PAPolicySummaryChangePage from './pages/Summary/PAPolicySummaryChangePage';

// mode: readOnly
import PAPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/PAPolicyDetailsChangePage.readonly';
import PADriversChangeReadOnlyPage from './pages/Drivers/PADriversChangePage.readonly';
import PAVehiclesChangeReadOnlyPage from './pages/Vehicles/PAVehiclesChangePage.readonly';
import PACoveragesChangeReadOnlyPage from './pages/Coverages/PACoveragesChangePage.readonly';
import PADriverAssignmentChangeReadOnlyPage from './pages/DriverAssignment/PADriverAssignmentChangePage.readonly';
import PAIncidentsAndReportsChangeReadOnlyPage from './pages/IncidentsAndReports/PAIncidentsAndReportsChangePage.readonly';
import PAQuoteChangeReadOnlyPage from './pages/Quote/PAQuoteChangePage.readonly';
import PAPolicySummaryChangeReadOnlyPage from './pages/Summary/PAPolicySummaryChangePage.readonly';

//


// import PCPADriverCoveragesPage from './pages/DriverCoverages/DriverCoveragesPage';

// import PCPAVehicleCoveragesPage from './pages/VehicleCoverages/PCVehicleCoveragesPage';
import PAPaySuccessChangePage from './pages/PaySuccess/PaySuccessChangePage';


// import PCPADriverCoveragesReadOnlyPage from './pages/DriverCoverages/DriverCoveragesPage.readonly';
// import PCPAVehicleCoveragesReadOnlyPage from './pages/VehicleCoverages/PCVehicleCoveragesPage.readonly';


setComponentMapOverrides(
    {
        PAPolicyChangeStartPage: { component: 'PAPolicyChangeStartPage' },

        PAPolicyDetailsChangePage: { component: 'PAPolicyDetailsChangePage' },
        PADriversChangePage: { component: 'PADriversChangePage' },
        PAVehiclesChangePage: { component: 'PAVehiclesChangePage' },
        PACoveragesChangePage: { component: 'PACoveragesChangePage' },
        PADriverAssignmentChangePage: { component: 'PADriverAssignmentChangePage' },
        PAIncidentsAndReportsChangePage: { component: 'PAIncidentsAndReportsChangePage' },
        PAQuoteChangePage: { component: 'PAQuoteChangePage' },
        PAPolicySummaryChangePage: { component: 'PAPolicySummaryChangePage' },
        PAPaySuccessChangePage: { component: 'PAPaySuccessChangePage' },

        // PCPAAddressPage: { component: 'PCPAAddressPage' },
        // PCPAVehicleCoveragesPage: { component: 'PCPAVehicleCoveragesPage' },
        // PCPADriverCoveragesPage: { component: 'PCPADriverCoveragesPage' },

        PAPolicyDetailsChangeReadOnlyPage: { component: 'PAPolicyDetailsChangeReadOnlyPage' },
        PADriversChangeReadOnlyPage: { component: 'PADriversChangeReadOnlyPage' },
        PAVehiclesChangeReadOnlyPage: { component: 'PAVehiclesChangeReadOnlyPage' },
        PACoveragesChangeReadOnlyPage: { component: 'PACoveragesChangeReadOnlyPage' },
        PADriverAssignmentChangeReadOnlyPage: { component: 'PADriverAssignmentChangeReadOnlyPage' },
        PAIncidentsAndReportsChangeReadOnlyPage: { component: 'PAIncidentsAndReportsChangeReadOnlyPage' },
        PAQuoteChangeReadOnlyPage: { component: 'PAQuoteChangeReadOnlyPage' },
        PAPolicySummaryChangeReadOnlyPage: { component: 'PAPolicySummaryChangeReadOnlyPage' },

        // PCPADriverCoveragesReadOnlyPage: { component: 'PCPADriverCoveragesReadOnlyPage' },
        // PCPAVehicleCoveragesReadOnlyPage: { component: 'PCPAVehicleCoveragesReadOnlyPage' },
    },
    {
        PAPolicyChangeStartPage,

        PAPolicyDetailsChangePage,
        PADriversChangePage,
        PAVehiclesChangePage,
        PACoveragesChangePage,
        PADriverAssignmentChangePage,
        PAIncidentsAndReportsChangePage,
        PAQuoteChangePage,
        PAPolicySummaryChangePage,
        PAPaySuccessChangePage,

        // PCPAAddressPage,
        // PCPAVehicleCoveragesPage,
        // PCPADriverCoveragesPage,

        PAPolicyDetailsChangeReadOnlyPage,
        PADriversChangeReadOnlyPage,
        PAVehiclesChangeReadOnlyPage,
        PACoveragesChangeReadOnlyPage,
        PADriverAssignmentChangeReadOnlyPage,
        PAIncidentsAndReportsChangeReadOnlyPage,
        PAQuoteChangeReadOnlyPage,
        PAPolicySummaryChangeReadOnlyPage,

        // PCPADriverCoveragesReadOnlyPage,
        // PCPAVehicleCoveragesReadOnlyPage,

    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PAPolicyChangeWizard } from './PAPolicyChangeWizard';
