import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

function ExternalINTIRouting(props) {
    const { match, history } = props;

    useEffect(() => {
        const {
            params: { jobNumber, policyNumber }
        } = match;

        let toURL = `/quotes/${jobNumber}/summary`;
        if (!_.isNil(policyNumber)) {
          toURL = `/policies/${policyNumber}/summary`;
        }
        history.push(toURL);
    }, [history, match]);

    return null;
}

ExternalINTIRouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

export default withRouter(ExternalINTIRouting);
