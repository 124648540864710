import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import CoverageUtil from '../../util/WCCoverageUtil';
import CoverageContext from '../../context/WCCoverageContext';
import WCClausesComponentVM from '../../components/WCClausesComponentVM/WCClausesComponentVM';
import WCSingleClauseComponentVM from '../../components/WCSingleClauseComponentVM/WCSingleClauseComponentVM';
import metadata from './WCLineConditions.metadata.json5';
import styles from './WCLineConditions.module.scss';

function WCLineConditions(props) {
    const {
        submissionVM,
        updateWizardData,
        selectedVersion,
        selectedVersionIndex,
        onValidate,
        showErrors,
        isEditable,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService,
        loadingLineCoverages,
        setLoadingLineCoverages,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const { authHeader } = useAuthentication();

    const getLineConditions = useCallback(() => {
        const lineConditions = _.get(selectedVersion, 'coverages.lineConditions', []);
        return lineConditions;
    }, [selectedVersion]);

    const lineConditionsVMPath = `lobData.${lobName}.offerings.children[${selectedVersionIndex}].coverages.lineConditions`;
    const lineConditionsPath = `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages.lineConditions`;
    const lineConditions = getLineConditions();

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newCoverages = CoverageUtil.setClauseValue(lineConditions, lineConditionsVMPath, value, changedPath);
            _.set(submissionVM.value, lineConditionsPath, newCoverages);
            updateWizardData(submissionVM);
            setIsEditing(false);
            return submissionVM;
        },
        [
            submissionVM,
            lineConditions,
            lineConditionsVMPath,
            lineConditionsPath,
            updateWizardData,
            setIsEditing,
        ]
    );

    const updateSubmissionVMToServer = useCallback(async (newSubmissionVM) => {
        const coverages = _.get(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`);
        const clausesToUpdate = CoverageUtil.generateUpdatedCoveragesDTO(coverages, lobName);
        const updatedCoveragePublicIDs = CoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedCoveragesDTO(clausesToUpdate, lobName)
        setLoadingLineCoverages(updatedCoveragePublicIDs);
        const response = await coveragesService.updateCoverages(
            jobID,
            sessionUUID,
            clausesToUpdate,
            authHeader
        )
        const lobCoverages = _.get(response, lobName);
        setIsEditing(false);
        _.set(newSubmissionVM.value, `lobData.${lobName}.offerings[${selectedVersionIndex}].coverages`, lobCoverages);
        setLoadingLineCoverages(false);
        updateWizardData(newSubmissionVM);
    }, [
        authHeader,
        jobID,
        selectedVersionIndex,
        sessionUUID,
        updateWizardData,
        // updateErrorsAndWarnings,
        setIsEditing,
        lobName,
        coveragesService,
        setLoadingLineCoverages,
    ]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = changeSubmission(value, changedPath);
            
            updateSubmissionVMToServer(newSubmissionVM);
        },
        [changeSubmission, updateSubmissionVMToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateSubmissionVMToServer(submissionVM);
        },
        [submissionVM, updateSubmissionVMToServer]
    )

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable,
            },
            lineConditions: {
                loadingClause: loadingLineCoverages,
                path: lineConditionsVMPath,
                value: lineConditions,
                onValidate,
                showErrors,
                componentMapOverrides: {
                    WCSingleClauseComponentVM: WCSingleClauseComponentVM,
                },
                setIsEditing,
                isEditing,
                isEditable,
            }
        };
    }, [
        loadingLineCoverages,
        lineConditionsVMPath,
        lineConditions,
        onValidate,
        showErrors,
        setIsEditing,
        isEditable,
        isEditing,
    ]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSyncCoverages: onSyncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange: _.noop,
            onChangeClause: changeSubmission,
            onValidate: onValidate,
        },
        resolveComponentMap: {
            WCClausesComponentVM: WCClausesComponentVM,
        }
    };
    return (
        <CoverageContext.Provider
            value={{
                wizardData: submissionVM,
                updateWizardData: updateWizardData,
                lobName: lobName,
                selectedVersionIndex: selectedVersionIndex,
                coveragesService: coveragesService,
            }}
        >
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={{}}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </CoverageContext.Provider>
        
    );
}

WCLineConditions.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    selectedVersion: PropTypes.shape({}).isRequired,
    selectedVersionIndex: PropTypes.number.isRequired,
    updateWizardData:  PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    updateErrorsAndWarnings: PropTypes.func,
};
WCLineConditions.defaultProps = {
    updateWizardData: _.noop,
    onValidate: _.noop,
    showErrors: false,
    isEditable: true,
    updateErrorsAndWarnings: _.noop,
}

export default WCLineConditions;
