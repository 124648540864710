import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { WindowUtil, WniPNIUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import {
    AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from '../AddressInputComponent/AddressVerifiedUtil';

import AddressInputComponent from '../AddressInputComponent/AddressInputComponent';
import DBAcomponent from "../DBAComponent/DBAComponent";
import metadata from './PrimaryNamedInsuredInputPopup.metadata.json5';
import messages from './PrimaryNamedInsuredInputPopup.messages';
import styles from './PrimaryNamedInsuredInputPopup.module.scss';

import { Button } from '@jutro/legacy/components';

function PrimaryNamedInsuredInputPopup(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        primaryNamedInsuredVM,
        policyNamedInsuredCandidatesVM,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        //
        readOnly: allFieldsReadOnly,
        disablePNISwitch,
        showEmployeeFlagToggle,
        useAuthenticationData,
        commonOverrideProps
    } = props;
    const modalApi = useWniModal();
    const { authHeader } = useAuthenticationData;
    const translator = useTranslator();
    const [PNIVM, updatePNIVM] = useState(primaryNamedInsuredVM);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isAddressFlag, updateAddressFlag] = useState(false);
    const PNIState = _.get(PNIVM.value, 'primaryAddress.state')
    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('PrimaryNamedInsuredInputPopup');

    const contactType = _.get(primaryNamedInsuredVM.value, 'contactType_Ext')

    const isAddressValid = useCallback(() => {
        return WniPNIUtil.isNameAndAddressValid(PNIVM)
    }, [PNIVM]);

    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);

    useEffect(() => {
        registerComponentValidation(isAddressValid);
    }, [registerComponentValidation, isAddressValid]);

    const writeValue = useCallback((value, path) => {
        if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateAddressFlag(false);
        }
        const newVM = viewModelService.clone(PNIVM);
        _.set(newVM, path, value);
        updatePNIVM(newVM);
    }, [PNIVM, viewModelService, updateLookupValidation]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const handleSave = useCallback(
        async () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            const uiUtil = AddressVerifiedUtil({
                authHeader,
                addressVM: _.get(PNIVM, 'primaryAddress'),
                addressPath: 'primaryAddress',
                updateValidations: updateLookupValidation,
                writeValue,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                doublePopup: true,
                //
                modalApi,
            });
            const verifiedObj = await uiUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }
            _.set(PNIVM, 'primaryAddress', verifiedObj.addressValue);
            updatePNIVM(PNIVM);
            onResolve(PNIVM);
        },
        [isComponentValid, authHeader, PNIVM, updateLookupValidation, writeValue, isAddressFlag, modalApi, onResolve, handleValidation]
    );

    const getPolicyNamedInsuredCandidatesAvailableItems = useCallback(() => {
        const policyNamedInsuredCandidates = _.get(policyNamedInsuredCandidatesVM, 'value', []);
        return policyNamedInsuredCandidates.map((policyNamedInsuredCandidate) => {
            return {
                code: policyNamedInsuredCandidate.publicID,
                name: policyNamedInsuredCandidate.publicID === _.get(PNIVM.value, 'publicID')
                    ? `${_.get(PNIVM.value, 'firstName')} ${_.get(PNIVM.value, 'lastName')}`
                    : `${policyNamedInsuredCandidate.firstName} ${policyNamedInsuredCandidate.lastName}`,

            };
        });
    }, [policyNamedInsuredCandidatesVM, PNIVM]);

    const handlePNIChange = useCallback((value) => {
        if (value === _.get(PNIVM.value, 'publicID', null)) {
            return;
        }
        const newNamedInuseredVMIndex = _.get(policyNamedInsuredCandidatesVM, 'value', []).findIndex(
            (policyNamedInsuredCandidate) => policyNamedInsuredCandidate.publicID === value
        );
        const newNamedInuseredVM = policyNamedInsuredCandidatesVM
            .getElement(newNamedInuseredVMIndex);
        updatePNIVM(newNamedInuseredVM);
    }, [PNIVM, policyNamedInsuredCandidatesVM]);

    const getPrimaryPhone = useCallback(() => {
        return WniPNIUtil.getPrimaryPhone(PNIVM)
    }, [PNIVM]);

    // const getPrimaryPhone = useCallback(() => {
    //     const primaryPhoneType = _.get(PNIVM.value, 'primaryPhoneType');
    //     switch (primaryPhoneType) {
    //         case 'mobile':
    //             return _.get(PNIVM.value, 'cellNumber');
    //         case 'home':
    //             return _.get(PNIVM.value, 'homeNumber');
    //         case 'work':
    //             return _.get(PNIVM.value, 'workNumber');
    //         default:
    //             break;
    //     }
    //     return null;
    // }, [PNIVM]);

    const setPrimaryPhone = useCallback((value) => {
        const primaryPhoneType = _.get(PNIVM.value, 'primaryPhoneType');
        switch (primaryPhoneType) {
            case 'mobile':
                writeValue(value, 'cellNumber');
                break;
            case 'home':
                writeValue(value, 'homeNumber');
                break;
            case 'work':
                writeValue(value, 'workNumber');
                break;
            default:
                break;
        }
    }, [PNIVM, writeValue]);

    const overrideProps = {
        // '@all': {
        // },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors,
            readOnly: allFieldsReadOnly,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        fullNameDropdownSelect: {
            availableValues: getPolicyNamedInsuredCandidatesAvailableItems(),
            value: _.get(PNIVM.value, 'publicID'),
            // readOnly: disablePNISwitch,
            disabled: disablePNISwitch,
            visible: true
        },
        companyName: {
            visible: (contactType === 'company')
        },
        firstName: {
            disabled: disablePNISwitch,
            visible: true
        },
        middleName: {
            disabled: disablePNISwitch,
            visible: true
        },
        lastName: {
            disabled: disablePNISwitch,
            visible: true
        },
        suffix: {
            disabled: disablePNISwitch,
            visible: true
        },
        contactDetailsCard: {
            title: translator(messages.contactDetails)
        },
        primaryAddressCard: {
            title: translator(messages.primaryAddress)
        },
        dbaContainer: {},
        country: {
            visible: false,
        },
        primaryAddressContainer: {
            model: PNIVM,
            dataPath: 'primaryAddress',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true
            },
            onValidate,
            useAuthenticationData
        },
        primaryPhoneType: {
            visible: true,
        },
        primaryPhone: {
            visible: true,
            value: getPrimaryPhone(),
            onValueChange: setPrimaryPhone
        },
        officePhone: {
            visible: false,
        },
        employeeFlagToggle: {
            visible: (PNIState!=='AK' && showEmployeeFlagToggle),
            label: translator(messages.westernNationalEmployee)
        }
    };

    const override = _.mergeWith(commonOverrideProps, overrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    const resolvers = {
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            validationissuescomponent: ValidationIssuesComponent,
            dbacomponent: DBAcomponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFullNameChange: handlePNIChange,
        }

    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title || translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={PNIVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    overrideProps={override}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={styles.cancelBtnMargin}>
                    {cancelBtnLabel || translator(messages.cancel)}
                </Button>
                <Button onClick={handleSave}>
                    {actionBtnLabel || translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PrimaryNamedInsuredInputPopup.propTypes = {
    primaryNamedInsuredVM: PropTypes.shape({}).isRequired,
    policyNamedInsuredCandidatesVM: PropTypes.shape({
        getElement: PropTypes.func.isRequired,
    }).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    //
    readOnly: PropTypes.bool,
    disablePNISwitch: PropTypes.bool,
    showEmployeeFlagToggle: PropTypes.bool,
};

PrimaryNamedInsuredInputPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md',
    //
    readOnly: false,
    disablePNISwitch: false,
    showEmployeeFlagToggle: true,
};

export default withAuthenticationContext(PrimaryNamedInsuredInputPopup);
