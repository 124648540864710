import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
// import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    ValidationIssueUtil,
    UWIssueUtil,
    OOSUtil,
    QuoteUtil,
    WniClausesUtil,
    WindowUtil,
    PolicyChangeUtil,
    CoveragesUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    DTOUtil,
    DocumentsUtil,
} from 'wni-portals-util-js';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, PAPolicyChangeQuoteComponent } from 'wni-components-platform-react';
import { PAQuoteUIHelper } from 'gw-capability-quoteandbind-pa-react-ext';
import { SuccessNotification } from 'gw-components-platform-react';
import { WniSxsChangeService, WniPAPolicyChangeService } from 'wni-capability-policychange';
import { PolicyDiffService } from 'gw-capability-policyjob';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import {
    DocumentCheckedComponent,
    PaymentDetailEditComponent,
} from 'wni-capability-gateway-react';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as commonMessages } from 'wni-platform-translations';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';

import { HOPaymentChangePage } from 'wni-capability-policychange-ho-react';

/**
 * Hard lessons learned:
 * 1) Sibling properties are different objects, and
 * 2) DropdownSelect likely checks whether availableValues contains value by Object-Is.
 * @param {object} props 
 * @returns {function}
 */
function DPPaymentChangePage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const viewModelService = useContext(ViewModelServiceContext);

    return (
        <HOPaymentChangePage {...props} />
    );
}

DPPaymentChangePage.propTypes = HOPaymentChangePage.propTypes;
DPPaymentChangePage.defaultProps = HOPaymentChangePage.defaultProps;

export default DPPaymentChangePage;
