import { defineMessages } from 'react-intl';

export default defineMessages({
    ExclusionAndConditionTitle: {
        "id": "quoteandbind.gl.wizard.step.location.ExclusionAndCondition",
        "defaultMessage": "Exclusions & Conditions"
    },
    ExclusionAndConditionActionText: {
        "id": "quoteandbind.gl.wizard.step.location.ExclusionAndCondition.AddExclusionAndCondition",
        "defaultMessage": "Add Exclusion & Conditions"
    },
});

