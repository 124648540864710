import React, {
    useContext,
} from 'react';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';

const {
    QuotePageConfig: {
        hiddenAmountCoveragePatterns,
    }
} = R1Config;

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CRSideBySideClauseComponent(props) {
    const {
        showAmount,
        clausePatternCode,
    } = props;

    const intl = useContext(IntlContext);

    const getCoverageLabel = (labelName, labelAmount) => {
        if (hiddenAmountCoveragePatterns.includes(clausePatternCode)) {
            return labelName;
        }
        return WniClausesUtil.getLabelNameWithAmount(labelName, labelAmount, intl, showAmount);
    };

    return (
        <ClauseComponent
            {...props}
            getCoverageLabel={getCoverageLabel}
        />
    );
}

CRSideBySideClauseComponent.propTypes = ClauseComponent.propTypes;

CRSideBySideClauseComponent.defaultProps = ClauseComponent.defaultProps;


export default CRSideBySideClauseComponent;