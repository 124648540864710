
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteCoveragesUtil, WniClausesUtil, WindowUtil } from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import messages from '../BaseRecommendCoverages/BaseRecommendCoveragesComponent.messages';

// import messages from './RecommendCoveragesComponent.messages';
import metadata from './RecommendCoveragesComponent.metadata.json5';
import styles from './RecommendCoveragesComponent.module.scss';
import { Link } from '@jutro/router';


function getVaidationIssuesWithUniqueMessage(covIssues) {
    // const validationIssues = covIssues.map((issue) => {
    //     return {
    //         type: 'fine',
    //         reason: issue.message,
    //         coveragePublicID: issue.coveragePublicID,
    //         vehicleFixedId: issue.vehicleFixedId,
    //     };
    // });
    // const uniqueCovIssues = _.uniqBy(validationIssues, (issue) => issue.reason);
    // return retval;
    const validationIssues = [];
    covIssues.forEach((covIssue) => {
        const {
            coveragePublicID,
            message: covIssueMessage,
            vehicleFixedId: covIssueVehicleFixedId,
        } = covIssue;
        const existingValIssue = validationIssues.find((issue) => issue.reason === covIssueMessage);
        if (existingValIssue) {
            existingValIssue.vehicleFixedIds.push(covIssueVehicleFixedId);
        } else {
            validationIssues.push({
                type: 'fine',
                reason: covIssueMessage,
                coveragePublicID,
                vehicleFixedIds: [covIssueVehicleFixedId],
            });
        }
    });

    return validationIssues;
}

const RecommendCoveragesComponent = (props) => {
    const {
        columnIndex,
        coverageIssues,
        // issueJumpFuncGetter,
        goToDriverCoveragesPage,
        orderedVehicleFixedIds = [],
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const itemMsg = translator(messages.recommendedUpgrades);
    // const recommendTitle = translator(messages.recommendedUpgrades);
    // const validationIssues = coverageIssues.map((issue) => {
    //     return {
    //         type: 'fine',
    //         reason: issue.message,
    //         coveragePublicID: issue.coveragePublicID,
    //     };
    // });

    /**
     * Get a page jump func that will will navigate to the coverage checkbox
     * @param {string} idPrefix
     * @param {string} coveragePublicID
     * @param {string} vehicleFixedIds
     * @returns {function}
     */
    function getCovJumpFn(idPrefix, coveragePublicID, vehicleFixedIds = []) {
        const vehicleFixedId = orderedVehicleFixedIds.find((vehId) => vehicleFixedIds.includes(vehId));

        // See SingleClauseComponentVM.generateMetadata()
        return () => {
            // Strange: cannot jump to checkbox, but can jump to <td>, <div>, etc.
            const covId = WniClausesUtil.getClauseContainerId(idPrefix, coveragePublicID, vehicleFixedId);

            // ================tmp solution
            const clauseDivEle = document.getElementById(covId);
            if (clauseDivEle) {
                WindowUtil.scrollTo(covId, { focus: true });
            } else {
                goToDriverCoveragesPage();
            }
        };
    }

    const issueRenderFn = (validationIssue) => {
        const {
            coveragePublicID,
            reason,
            vehicleFixedIds,
        } = validationIssue;

        const jumpFn = getCovJumpFn(columnIndex, coveragePublicID, vehicleFixedIds);

        let renderContent = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link onClick={jumpFn}>{reason}</Link>
        );
        const covLinkText = QuoteCoveragesUtil.findCovLinkText(reason);
        if (covLinkText) {
            const linkTextArray = reason.split(covLinkText);
            renderContent = (
                <span>
                    {linkTextArray[0]}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link onClick={jumpFn}>{covLinkText}</Link>
                    {linkTextArray[1]}
                </span>
            );
        }
        return (
            renderContent
        );
    };

    useEffect(() => {
    }, []);

    //----------------------------------
    const validationIssues = getVaidationIssuesWithUniqueMessage(coverageIssues, orderedVehicleFixedIds);
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        recommendCovList: {
            id: `recommendCovContainer${columnIndex}`,
            validationIssues,
            issueRenderFn,
            typeTitleFormatter: (type, issues) => {
                // return issues.length > 1 ? `${itemMsg}s` : itemMsg;
                return itemMsg;
            },
            scrollToIssue: false,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            validationissues: ValidationIssuesComponent,
        },
    };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            // onValueChange={writeValue}
            // showErrors={showErrors}
        />
    );

};

RecommendCoveragesComponent.propTypes = {
    /**
     * (Required) Column index of the policyPeriod the recommendation is based on
     */
    columnIndex: PropTypes.string.isRequired,
    /**
     * (Required) an array of PaCoverageValidationIssueDTO instances
     */
    coverageIssues: PropTypes.arrayOf(PropTypes.shape({
        coveragePublicID: PropTypes.string,
        coverageName: PropTypes.string,
        coveragePatternCode: PropTypes.string,
        message: PropTypes.string,
        vehicleFixedId: PropTypes.number,
    })).isRequired,
    /**
     * (Optional): an ordered list of vehicleFixId, used to provided jump link for the first one;
     */
    orderedVehicleFixedIds: PropTypes.arrayOf(PropTypes.number),
    /**
     * (Optional) issue jump func getter
     */
    // issueJumpFuncGetter: PropTypes.func,
    goToDriverCoveragesPage: PropTypes.func.isRequired,
};

RecommendCoveragesComponent.defaultProps = {
    // issueJumpFuncGetter: undefined,
    orderedVehicleFixedIds: undefined,
};

export default RecommendCoveragesComponent;
