import _ from 'lodash';

function renderDisplayName(str) {
    const newStr = !!str
    return newStr ? `${str},` : ''
}

function getPrimaryAddressDisplayName(primaryAddress = {}) {
    const {
        pobox_Ext: pobox,
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode
    } = primaryAddress;
    const addressLine = pobox || addressLine1 || '-';
    return `${addressLine}, ${renderDisplayName(addressLine2)} ${renderDisplayName(city)} \n ${renderDisplayName(state)} ${postalCode || '-'}`
}



export default {
    getPrimaryAddressDisplayName,
}