import { getKeyCacheMap} from './WizardPageDataKeyRegistry';

const WizardStepDataKeyPrefix = 'WSDK_';

const WizardStepDataKeysRegistry = [
    'parentId', // -> 'WSDK_parentId'
    'parentTitle', // -> 'WSDK_parentTitle'
    //
    'stepVisible', // -> 'WSDK_stepVisible'
    'stepId', // -> 'WSDK_stepId', this stepId is used only for toggleWizardStepDisplay()
];

const WizardStepDataKeys = getKeyCacheMap(WizardStepDataKeyPrefix, WizardStepDataKeysRegistry);

export default WizardStepDataKeys;
