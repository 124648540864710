
import React, { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { HOResidencePage } from 'wni-capability-quoteandbind-ho-react';
import { DPRCTService, DPResidenceService } from 'wni-capability-quoteandbind-dp';
import DPConstructionComponent from "./Component/DPConstructionComponent/DPConstructionComponent";
import DPLiabilityExposuresComponent from "./Component/DPLiabilityExposuresComponent/DPLiabilityExposuresComponent";
import messages from './DPResidencePage.messages';
import DPOtherInformationComponent from './Component/DPOtherInformationComponent/DPOtherInformationComponent';
import DPBusinessQuestionComponent from './Component/DPBusinessQuestionComponent/DPBusinessQuestionComponent';
import DPProtectionComponent from './Component/DPProtectionComponent/DPProtectionComponent';

function DPResidencePage(props) {

    const {
        wizardData: submissionVM,
        dpResidenceService,
        dpRctService
    } = props;

    const {
        lobData: {
            dwellingProperty: {
                householdInfoDTO_Ext: {
                    namedInsuredOccupancy
                },
                coverables: {
                    dwelling_Ext: {
                        construction: {
                            coveragePartType_Ext: coveragePartType,
                            coverageForm_Ext: coverageForm
                        }
                    }
                }
            }
        }
    } = submissionVM.value

    const isSkipPrefillRCT = coverageForm === 'dp1_Ext' || coveragePartType === 'hopcondo';
    const DP2Condo = coveragePartType === 'hopcondo';

    useEffect(() => {
        _.set(dpRctService, 'launchRCTExpress', DPRCTService.launchRCTExpress);
        _.set(dpResidenceService, 'updateNoValidationDwelling', DPResidenceService.updateNoValidationDwelling);
    }, []);

    const DPOverrideProps = {
        businessQuestion: {
            namedInsuredOccupancy
        }
    }

    const componentMapOverride = {
        ConstructionComponent: DPConstructionComponent,
        LiabilityExposuresComponent: DPLiabilityExposuresComponent,
        OtherInformationComponent: DPOtherInformationComponent,
        BusinessQuestionComponent: DPBusinessQuestionComponent,
        ProtectionComponent: DPProtectionComponent
    }

    return (
        <HOResidencePage
            {...props}
            hoRctService = {dpRctService}
            hoResidenceService = {dpResidenceService}
            linePath = 'dwellingProperty'
            customMessages = {messages}
            componentMapOverride = {componentMapOverride}
            DPOverrideProps = {DPOverrideProps}
            isSkipPrefillRCT = {isSkipPrefillRCT}
            DP2Condo = {DP2Condo}
        />
    );

}

DPResidencePage.propTypes = {
    ...wizardProps,
    dpRctService: PropTypes.object,
    dpResidenceService: PropTypes.object
};

DPResidencePage.defaultProps = {
    dpRctService: DPRCTService,
    dpResidenceService: DPResidenceService
};

export default DPResidencePage;
