import { defineMessages } from 'react-intl';

export default defineMessages({
    uploadDocuments: {
        id: 'gateway.directives.gateway-documents.Upload Attachment',
        defaultMessage: 'Upload Attachment'
    },
    documentsName: {
        id: 'gateway.directives.gateway-documents.Document Name',
        defaultMessage: 'Document Name'
    },
    documentsType: {
        id: 'gateway.directives.gateway-documents.Document Type',
        defaultMessage: 'Document Type'
    },
    description: {
        id: 'gateway.directives.gateway-documents.Description',
        defaultMessage: 'Description'
    },
    pleasaeSelect: {
        id: 'gateway.directives.gateway-documents.Please Select',
        defaultMessage: 'Please Select'
    },
    relatedData: {
        id: 'gateway.directives.gateway-documents.Related To',
        defaultMessage: 'Related To'
    },
    documentDate:{
        id: 'gateway.directives.gateway-documents.Date',
        defaultMessage: 'Date'
    },
    cancel: {
        id: 'gateway.directives.gateway-documents.Cancel',
        defaultMessage: 'Cancel'
    },
    done: {
        id: 'gateway.directives.gateway-documents.Done',
        defaultMessage: 'Done'
    },

    removeDocument: {
        id: 'custom.gateway.directives.GatewayDocuments.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'custom.gateway.directives.GatewayDocuments.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove'
    },
    documentRemoveConfirmYes: {
        id: 'custom.gateway.directives.GatewayDocuments.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'custom.gateway.directives.GatewayDocuments.No',
        defaultMessage: 'No'
    },
    UploadAttachments: {
        id: 'custom.gateway.directives.Upload Attachments',
        defaultMessage: 'Upload Attachments'
    },
    Attachments: {
        id: 'gateway.directives.gateway-documents.Attachments',
        defaultMessage: 'Attachments'
    },
    uploadModalOk: {
        id: 'custom.gateway.directives.modal.OK',
        defaultMessage: 'OK',
    },
    uploadModalCancel: {
        id: 'custom.gateway.directives.modal.Cancel',
        defaultMessage: 'Cancel',
    },
    filterBy: {
        id: 'custom.gateway.directives.Filter By',
        defaultMessage: 'Filter By',
    },
    all: {
        id: 'custom.gateway.directives.All',
        defaultMessage: 'All',
    },
    printAll: {
        id: 'custom.gateway.directives.Print All',
        defaultMessage: 'Print All',
    },
    sendViaEmail: {
        id: 'custom.gateway.directives.Send via Email',
        defaultMessage: 'Send via Email',
    }
});
