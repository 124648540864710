import React from 'react';
import WizardPage from '../../templates/CRWizardPage';
import CRStateSpecificInformationPage from './CRStateSpecificInformationPage';

function CRStateSpecificInformationReadOnlyPage(props) {

    return (
        <CRStateSpecificInformationPage {...props} isReadOnly />
    );
}

CRStateSpecificInformationReadOnlyPage.propTypes = WizardPage.propTypes;
export default CRStateSpecificInformationReadOnlyPage;