import { defineMessages } from 'react-intl';

export default defineMessages({
    searchQuoteDocuments: {
        id: 'gateway.views.policies.gateway-documents.Search Quotes',
        defaultMessage: 'Search Product, Transaction Number, Account'
    },
    searchPolicyNumber: {
        id: 'gateway.views.policies.policy-landing.Search Policy Number or Name',
        defaultMessage: 'Search Policy Number or Name'
    },
    searchTransactionNumber: {
        id: 'gateway.views.policies.policy-landing.Search Transaction Number or Name',
        defaultMessage: 'Search Transaction Number or Name'
    },
    status: {
        id: 'gateway.views.policies.policy-landing.Status Action',
        defaultMessage: 'Status'
    },
    prefilledQuote: {
        id: 'gateway.views.policies.policy-landing.Prefilled Quote',
        defaultMessage: 'Prefilled Quote'
    },
    transactionNumber: {
        id: 'gateway.views.policies.policy-landing.Transaction Number',
        defaultMessage: 'Transaction Number'
    },
    inGoodStanding: {
        id: 'gateway.views.policies.policy-landing.InGoodStanding',
        defaultMessage: 'In Good Standing'
    },
    delinquent: {
        id: 'gateway.views.policies.policy-landing.Delinquent Info',
        defaultMessage: 'Delinquent',
    },
    policies: {
        id: 'gateway.views.policies.policy-landing.Policies Info',
        defaultMessage: 'Policies',
    },
    policyLandingQuotes: {
        id: 'gateway.views.policies.policy-landing.Quotes Info',
        defaultMessage: 'Quotes'
    },
    policyLandingRenewal: {
        id: 'gateway.views.policies.policy-landing.Renewal Info',
        defaultMessage: 'Renewal'
    },
    policyLandingChange: {
        id: 'gateway.views.policies.policy-landing.Change Info',
        defaultMessage: 'Change'
    },
    policyLandingCancellation: {
        id: 'gateway.views.policies.policy-landing.Cancellation Info',
        defaultMessage: 'Cancellation'
    },
    policyLandingRewrite: {
        id: 'gateway.views.policies.policy-landing.Rewrite Info',
        defaultMessage: 'Rewrite'
    },
    changePolicy: {
        id: 'gateway.views.policies.PolicySummary.Change Policy',
        defaultMessage: 'Change Policy'
    },
    cancelPolicy: {
        id: 'gateway.views.policies.PolicySummary.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },
    warningMsg: {
        id: 'gateway.views.policies.PolicySummary.warning Policy Message',
        defaultMessage: 'Our records indicate that you do not have servicing rights on this policy. Please contact your Underwriter for further assistance'
    },
    aosTooltipMessage: {
        id: 'gateway.views.policies.PolicySummary.aosTooltipMessage',
        defaultMessage: 'Please use the arrow on the right to select an action (policy change or cancel). For any other servicing request, please contact your Underwriter for assistance.'
    },
    mostRecentMsg: {
        id: 'gateway.views.policies.PolicySummary.The 30 most recently viewed transactions are displayed, please expand search by transaction number or name as needed.',
        defaultMessage: 'The 30 most recently viewed transactions are displayed, please expand search by transaction number or name as needed.'
    },
    titleForRecentlyViewed: {
        id: 'gateway.views.policies.quick filter.Recently Viewed Policies',
        defaultMessage: 'Recently Viewed Policies'
    },
    titleForRecentlyIssued: {
        id: 'gateway.views.policies.quick filter.Recently Issued Policies for past x days',
        defaultMessage: 'Recently Issued Policies for past {day} days'
    },
    titleForDelinquent: {
        id: 'gateway.views.policies.quick filter.Delinquent Policies',
        defaultMessage: 'Delinquent Policies'
    },
    titleForQuotes: {
        id: 'gateway.views.policies.quick filter.Open Quotes',
        defaultMessage: 'Open Quotes'
    },
    titleForRenewal: {
        id: 'gateway.views.policies.quick filter.Open Renewals',
        defaultMessage: 'Open Renewals'
    },
    titleForChange: {
        id: 'gateway.views.policies.quick filter.Open Changes',
        defaultMessage: 'Open Changes'
    },
    titleForCancellation: {
        id: 'gateway.views.policies.quick filter.Open Cancellations',
        defaultMessage: 'Open Cancellations'
    },
    showVehicleCoveragesMessage: {
        id: 'gateway.views.policies.PolicySummary.PaVehicle.Show Vehicle Coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    blockChangeTitle: {
        id:'gateway.views.policies.PolicySummary.blockChangeTitle',
        defaultMessage: 'Transaction Conflict'
    },
    oosTransaction: {
        id: 'gateway.views.policies.PolicySummary.oosTransaction',
        defaultMessage: 'Due to additional policy changes in progress, this transaction is identified as out of sequence. Please contact your underwriter or start a new policy change in the most recently updated policy.'
    },
    preemptiveTransaction: {
        id: 'gateway.views.policies.PolicySummary.preemptiveTransaction',
        defaultMessage: 'Please contact your underwriter to continue this transaction or start a new policy change on the most up to date transaction'
    }
});
