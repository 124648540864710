import { defineMessages } from 'react-intl';

export default defineMessages({
    paDetailsCollision: {
        id: 'pe.fnol.pa.views.pa-theft-details.Details (Theft)',
        defaultMessage: 'Details (Theft)'
    },
    paWhatHappened: {
        id: 'pe.fnol.pa.views.pa-theft-details.What Happened',
        defaultMessage: 'What Happened'
    },
    paDescribeWhatHappened: {
        id: 'pe.fnol.pa.views.pa-theft-details.Describe what happened',
        defaultMessage: 'Describe what happened'
    },
    paVechilesInvolved: {
        id: 'pe.fnol.pa.views.pa-theft-details.Which vehicle was involved?',
        defaultMessage: 'Which vehicle was involved?'
    },
    paTheftChooseVehicle: {
        id: 'pe.fnol.pa.views.pa-theft-details.Choose Vehicle',
        defaultMessage: '-- Choose Vehicle --'
    },
    paWhatStolen: {
        id: 'pe.fnol.pa.views.pa-theft-details.What was stolen?',
        defaultMessage: 'What was stolen?'
    },
    paEntireVehicle: {
        id: 'pe.fnol.pa.views.pa-theft-details.The entire vehicle',
        defaultMessage: 'The entire vehicle'
    },
    paOtherVehicleParts: {
        id: 'pe.fnol.pa.views.pa-theft-details.Audio or other equipment on or in the vehicle',
        defaultMessage: 'Audio or other equipment on or in the vehicle'
    },
    paAdditionalInformationVehicle: {
        id: 'pe.fnol.pa.views.pa-theft-details.Any additional information about the theft?',
        defaultMessage: 'Any additional information about the theft?'
    }
});
