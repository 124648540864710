
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';

import { CRQualificationPage } from 'wni-capability-quoteandbind-cr-react';

function CRQualificationChangePage(props) {

    return (
        <CRQualificationPage {...props} isPolicyChange />
    );

};

export default CRQualificationChangePage;