import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { withRouter } from 'react-router-dom';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteInfoComponentContext } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import wizardStepToFieldMapping from './config/pa-wizard-step-to-field-mapping.json5';
import PaQuoteInfo from './components/PaQuoteInfo/PaQuoteInfo';
import wizardConfig from './config/pa-wizard-config.json5';
import './PAWizard.messages';

const PATH_TO_MOCK_DATA = 'quote.pa';
const MOCK_DATA_TO_SET = [
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.accountHolder',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city',
    'baseData.productCode',
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
    'baseData.accountHolder.subtype',
    'baseData.accountHolder.dateOfBirth.day',
    'baseData.accountHolder.dateOfBirth.month',
    'baseData.accountHolder.dateOfBirth.year'
];
const MOCK_DATA_TO_REMOVE = [
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
    'baseData.accountHolder.dateOfBirth',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city'
];

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: commonMessages.infoWillBeSavedAsDraft,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function setCoverageStartDate() {
    const currentDate = new Date();
    const coverageStartDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return coverageStartDate;
}

function setPrepData(address) {
    const realData = {
        baseData: {
            policyAddress: address,
            periodStartDate: setCoverageStartDate()
        }
    };

    return MockUpUtil.setMockData(realData, PATH_TO_MOCK_DATA, ...MOCK_DATA_TO_SET);
}

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        ...MOCK_DATA_TO_REMOVE
    );
}

function createSubmissionOnPartialAddress(address) {
    const data = setPrepData(address);
    return LoadSaveService.createSubmission(data);
}

function PAWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;

    useEffect(() => {
        const viewModelContext = {
            AccountEmailRequired: true,
            DriverEmailRequired: true,
            AccountDOBRequired: true
        };

        if (!viewModelService) {
            if (_.isEmpty(location.search)) {
                history.push('/');
            }

            return;
        }

        let submissionPromise;
        if (_.has(location, 'state.address')) {
            const { address } = location.state;
            submissionPromise = createSubmissionOnPartialAddress(address);
        } else if (_.has(location, 'state.submission')) {
            const { submission } = location.state;
            setShouldSkipValidSteps(true);
            submissionPromise = Promise.resolve(submission);
        } else if (_.has(location, 'search')) {
            const parsedParms = queryString.parse(location.search);
            submissionPromise = LoadSaveService.retrieveAccountSubmission(parsedParms);
        } else {
            return;
        }

        submissionPromise.then((response) => {
            const submissionVM = viewModelService.create(
                removeMockData(response),
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                viewModelContext
            );

            setInitialSubmission(submissionVM);
            setIsLoading(false);
        });
        // only execute this once per component lifecycle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const handleCancel = useCallback(
        (wizardProps) => {
            const { wizardSnapshot, wizardData } = wizardProps;
            const latestSnapshot = wizardData.aspects.subtreeValid && wizardData.aspects.valid
                ? wizardData
                : wizardSnapshot;

            const periodStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code');
            if (periodStatus === 'Bound') {
                // allow transition once the submission is bound
                return true;
            }
            return modalApi.showConfirm(ACTION_MSG.cancelAction).then((results) => {
                if (results !== 'confirm') {
                    return _.noop();
                }
                let serverCall = LoadSaveService.updateDraftSubmission;

                if (periodStatus === 'Quoted') {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const wizardDataFromSnapshot = _.get(latestSnapshot, 'value');
                const dataToSave = periodStatus === 'Quoted'
                    ? MockUpUtil.setMockData(wizardDataFromSnapshot, PATH_TO_MOCK_DATA)
                    : MockUpUtil.setMockData(
                        wizardDataFromSnapshot,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_SET
                    );
                setIsLoading(true);

                serverCall(dataToSave)
                    .then(() => {
                        history.push('/');
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            title: commonMessages.unableToSaveQuote,
                            message: commonMessages.unableToSaveQuoteMessage,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                return true;
            }, _.noop);
        },
        [history]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <QuoteInfoComponentContext.Provider value={PaQuoteInfo}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                skipCompletedSteps={shouldSkipValidSteps}
                wizardStepToFieldMapping={wizardStepToFieldMapping}
            />
        </QuoteInfoComponentContext.Provider>
    );
}

PAWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            submission: PropTypes.shape({})
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(PAWizard);
