import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { CPCommercialPropertyPage } from  'wni-capability-quoteandbind-cp-react';

function CPCommercialPropertyChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('CP7Line', submissionVM);
    return (
        <CPCommercialPropertyPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

CPCommercialPropertyChangePage.propTypes = CPCommercialPropertyPage.propTypes;
CPCommercialPropertyChangePage.defaultProps = CPCommercialPropertyPage.defaultProps;

export default CPCommercialPropertyChangePage;
