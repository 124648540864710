import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    WniDateUtil,
    TermTypeUtil,
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
    WniQuestionSetUtil,
    PolicyChangeUtil,
    OOSUtil,
    WniPNIUtil,
    WizardUtil,
    WizardPageJumpUtil,
    WindowUtil,
    WniLocalDateUtil,
    IssuanceValidationUtil,
} from 'wni-portals-util-js';
import { DomRenderUtil } from 'wni-portals-util-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import moment from 'moment';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniEndorsementService, WniPAPolicyChangeService } from 'wni-capability-policychange';
import { FrontendErrorConstants, WizardConstants } from 'wni-portals-config-js';
import {
    AgencyAndAgentInfoComponent,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
// eslint-disable-next-line import/no-unresolved
import questionSetsMetadata from 'question-sets-metadata';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import metadata from './PAPolicyDetailsChangePage.metadata.json5';
import messages from './PAPolicyDetailsChangePage.messages';

const TERM_TYPE_OTHER = 'Other';


function PAPolicyDetailsChangePage(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    // const { EndorsementService } = useDependencies('EndorsementService');
    // const { LoadSaveService } = useDependencies('LoadSaveService');
    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const {
        EndorsementService,
        LoadSaveService,
        loadingMask: { setLoadingMask },
    } = useDependencies(['EndorsementService', 'LoadSaveService', 'loadingMask']);
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('PAPolicyChange');
    const {
        updateWizardData,
        updateWizardSnapshot,
        wizardData: submissionVM,
        steps,
        // currentStepIndex,
        changeNextSteps,
        initialSteps,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const {
        FrontendErrorCode
    } = FrontendErrorConstants;
    const [UWValues, updateUWValues] = useState({});
    const { authHeader } = useAuthentication();
    const [showErrors, updateShowErrors] = useState(false);
    const [coveragesErrorsAndWarnings, updateCoveragesErrorsAndWarnings] = useState({
        errors: [],
        warnings: []
    });
    // const [warningMessage, updateWarningMessage] = useState();
    const [showDescription, updateShowDescription] = useState('');
    const [showLoader, updateShowLoader] = useState(false);
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);
    const [frontendErrors, updateFrontendErrors] = useState([]);

    const {
        jobID,
        sessionUUID,
        policyNumber,
        baseData: {
            effectiveDate,
            selectedVersion_Ext: selectedVersion,
            baseState_Ext: policyState,
            primaryNamedInsured_Ext: PNI,
            maximumEffectiveDate_Ext: maximumEffectiveDate
        },
        lobData: {
            personalAuto: {
                offerings
            }
        },
        oossliceDates_Ext: oossliceDates,
        originPolicyType_Ext: originPolicyType,
    } = submissionVM.value;

    const selectedOffering = offerings
        .find((offering) => offering.publicID_Ext === selectedVersion);

    const selectedVersionOfferingsIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersion);

    // const hideDriverCoveragesPage = useCallback((isForNamedNonOwner) => {
    //     const coveragePageId = 'PCPADriverCoveragesPage';
    //     const driverPageId = 'PCPADriverPage';
    //     const originDriverCoveragesPageConfig = _.filter(initialSteps, (s) => {
    //         return s.id === coveragePageId;
    //     });
    //     const driverCoveragesPageConfig = _.filter(steps, (s) => {
    //         return s.id === coveragePageId;
    //     });
    //     const coveragePageIndex = steps.findIndex((step) => step.id === coveragePageId);
    //     const driverPageIndex = steps.findIndex((step) => step.id === driverPageId);
    //     if (isForNamedNonOwner) {
    //         steps.splice(coveragePageIndex, 1);
    //         changeNextSteps(steps.slice(1));
    //     } else {
    //         if (driverCoveragesPageConfig.length <= 0) {
    //             steps.splice(driverPageIndex + 1, 0, originDriverCoveragesPageConfig[0]);
    //         }
    //         changeNextSteps(steps.slice(1));
    //     }
    // }, [changeNextSteps, currentStepIndex]);

    const handlePolicyTypeChange = useCallback(async (isForNamedNonOwner) => {
        if (_.get(submissionVM.value, 'lobData.personalAuto.isForNamedNonOwner_Ext') === isForNamedNonOwner) {
            return;
        }
        const coveragePath = `lobData.personalAuto.offerings.children[${selectedVersionOfferingsIndex}].coverages.lineCoverages`;
        const oldSubmissionVM = viewModelService.clone(submissionVM);
        updateShowLoader(true);
        const request = {
            sessionUUID,
            policyNumber,
            jobID,
            effectiveDate,
            lineCode: 'PersonalAutoLine',
            isForNamedNonOwner: isForNamedNonOwner,
            policyChangeBaseData: _.get(submissionVM.value, 'baseData'),
            firstPreQualQuestionSet: _.get(submissionVM.value, `lobData.personalAuto.preQualQuestionSets[${selectedVersionOfferingsIndex}]`),
            flowStepID: 'PAPolicyDetails',
        };
        submissionVM.value = await WniEndorsementService.changePolicyType(request, authHeader);
        const fieldsToRemoveFromValidation = ClausesUtil.getRemovedClausesID(
            oldSubmissionVM, submissionVM, coveragePath
        );
        disregardFieldValidation(fieldsToRemoveFromValidation);
        updateWizardData(submissionVM);
        updateShowLoader(false);
    }, [
        authHeader,
        submissionVM,
        updateWizardData,
        disregardFieldValidation,
        viewModelService,
        jobID,
        effectiveDate,
        policyNumber,
        sessionUUID,
        selectedVersionOfferingsIndex,
    ]);

    const isAgencyAndAgentInfoValid = useCallback(() => {
        const {
            baseData: {
                producerCode_Ext: produerCode,
                producerOrLicensedAgent_Ext: producerOrLicensedAgent,
            },
        } = submissionVM;
        if (produerCode.value
            && producerOrLicensedAgent.value
        ) {
            return true;
        }
        return false;
    }, [submissionVM]);

    const PAPolicyChangeValidation = useCallback(() => {
        // const questionAnswers = _.get(
        //     submissionVM.value,
        //     'lobData.personalAuto.preQualQuestionSets[0].answers',
        //     []
        // );
        return isAgencyAndAgentInfoValid()
            && WniPNIUtil.isPNIValid(PNI);
        // && WniQuestionSetUtil.isPAPreQualQuestionValid(questionAnswers);
    }, [isAgencyAndAgentInfoValid, PNI]);

    useEffect(() => {
        const isForNamedNonOwner = _.get(submissionVM, 'value.lobData.personalAuto.isForNamedNonOwner_Ext');
        const PNIRelationshipToInsured = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.relationshipToInsured_Ext', null);
        if (!PNIRelationshipToInsured) {
            _.set(submissionVM.baseData, 'primaryNamedInsured_Ext.relationshipToInsured_Ext', 'Insured_Ext');
            updateWizardData(submissionVM);
        }
        // WizardUtil.updateNamedNonOwnerPageStep({
        //     isForNamedNonOwner,
        //     initialSteps,
        //     steps,
        //     changeNextSteps,
        //     driversPageId: 'PCPADriverPage',
        //     driverCoveragesPageId: 'PCPADriverCoveragesPage',
        // });
        const landingPage = wizardPageData[WizardConstants.landingPage];
        if (landingPage) {
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'PCPAPolicyChange') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        }
    }, []);

    useEffect(() => {
        registerComponentValidation(PAPolicyChangeValidation);
    }, [
        registerComponentValidation,
        PAPolicyChangeValidation,
        policyNumber,
        authHeader,
    ]);

    const onTermTypeChange = (newTermType) => {
        const periodStartDate = _.get(
            submissionVM,
            'baseData.periodStartDate.value'
        );
        const termTypeCode = newTermType;
        if (TERM_TYPE_OTHER !== termTypeCode) {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(
                periodStartDate,
                termTypeCode
            );

            _.set(submissionVM.baseData, 'periodEndDate', newPeriodEndDate);
        }
        _.set(submissionVM.baseData, 'termType', newTermType);
        updateWizardData(submissionVM);
    };

    const onStartDateChange = (newPeriodStartDate) => {
        const termType = _.get(submissionVM, 'baseData.termType.value');
        const { code: termTypeCode } = termType;
        if (TERM_TYPE_OTHER !== termTypeCode) {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(
                newPeriodStartDate,
                termTypeCode
            );
            _.set(submissionVM.baseData, 'periodEndDate', newPeriodEndDate);
        }
        _.set(submissionVM.baseData, 'periodStartDate', newPeriodStartDate);
        updateWizardData(submissionVM);
    };

    const isTermTypeOther = () => {
        // const currentTermType = _.get(submissionVM, 'baseData.termType.value');
        // return TERM_TYPE_OTHER === currentTermType.code;
    };

    const handleAgencyChange = (value, path) => {
        if (path === 'producerCode_Ext' && !value) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
        }
        _.set(submissionVM.baseData, path, value);

        updateWizardData(submissionVM);
    };

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        },
        []
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(
            submissionVM,
            'baseData.primaryNamedInsured_Ext'
        );
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                updateWizardData(submissionVM);
            })
            .catch(() => _.noop());
    }, [showPrimaryNamedInsuredModal, submissionVM, updateWizardData]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(
            submissionVM,
            'baseData.primaryNamedInsured_Ext.firstName.value'
        );
        const lastName = _.get(
            submissionVM,
            'baseData.primaryNamedInsured_Ext.lastName.value'
        );
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const isShowNotification = useCallback(() => {
        const policyInfoSource = _.get(
            submissionVM,
            'baseData.PolicyInfoSource_Ext'
        );
        if (policyInfoSource && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const loadCovertPolicyInfo = useCallback(() => {
        // const currentEffectiveDate = _.get(submissionVM, 'baseData.periodStartDate.value');
        // const infoDate = WniDateUtil.formatDateWithPattern(currentEffectiveDate).subtract(34, 'days');
        const covertInfo = `messages.covertedPolicyInfoStart.defaultMessage
        + infoDate + messages.covertedPolicyInfoEnd.defaultMessage`;
        return covertInfo;
    }, []);

    const getFrontendValiationIssuesWarning = useCallback(() => {
        const questionSet = questionSetsMetadata.PAPolicyInfoUWQuestions.orderedQuestions;
        return WniQuestionSetUtil.getQSWarningQuestionsByAnswersAndState(
            _.get(submissionVM.value, 'lobData.personalAuto.preQualQuestionSets[0].answers', []),
            policyState
        ).map((code) => {
            return {
                type: 'warning',
                // POI-17852: The beginning description of the warning messages are incorrect for both Policy Details and Quote screen
                reason: `Below required fields for issuance are missing:"${translator({
                    id: questionSet.find((question) => question.code === code).displayKey
                })}"`
            };
        });
    }, [submissionVM, translator, policyState]);

    const handleEffectiveDateChange = (newEffectiveDate) => {
        _.set(submissionVM, 'baseData.effectiveDate', newEffectiveDate);
        updateWizardData(submissionVM);
        const selectedEffectiveDate = moment(newEffectiveDate).toDate();

        const sliceDateRange = _.get(submissionVM.value, 'baseData.currentSliceDateRange_Ext');
        const { sliceStart, sliceEnd } = sliceDateRange;
        const newFrontendErrors = frontendErrors.slice()
            .filter(
                (frontendError) => frontendError.code !== FrontendErrorCode.BEFORE_SLICE_START_DATE
                    && frontendError.code !== FrontendErrorCode.AFTER_SLICE_END_DATE
            );
        if (selectedEffectiveDate < moment(sliceStart).toDate()) {
            newFrontendErrors.push({
                type: 'error',
                reason: translator(messages.beforeSliceStartDate, {
                    effectiveDate: WniDateUtil.formatDateWithPattern(newEffectiveDate)
                }),
                code: FrontendErrorCode.BEFORE_SLICE_START_DATE
            });
        } else if (selectedEffectiveDate >= moment(sliceEnd).toDate()) {
            newFrontendErrors.push({
                type: 'error',
                reason: translator(messages.afterSliceEndDate, {
                    effectiveDate: WniDateUtil.formatDateWithPattern(newEffectiveDate)
                }),
                code: FrontendErrorCode.AFTER_SLICE_END_DATE
            });
        }
        updateFrontendErrors(newFrontendErrors);
        // updateOOSSliceDatesAndWarning();
    };

    const handleDescription = (des) => {
        _.set(submissionVM, 'baseData.description_Ext', des);
        updateWizardData(submissionVM);
        updateShowDescription(des);
    };

    const savePolicyChange = useCallback(async () => {
        const oldValidationIssuesWarning = validationIssuesWarning;
        const response = await EndorsementService.checkEffectiveDateIsValid(
            [
                submissionVM.policyNumber.value,
                submissionVM.baseData.effectiveDate.value,
            ],
            authHeader
        );
        if (response) {
            const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
            const oldVehicles = _.cloneDeep(vehicles) || [];
            // const policyCountryCode =
            // _.get(submissionVM.value, 'baseData.policyAddress.country');
            // oldVehicles.forEach((vehicle) => {
            //     VehicleUtil.updatePolicyCountryForVehicle(vehicle, policyCountryCode);
            // });
            _.set(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', oldVehicles);
            if (_.isEmpty(showDescription)) {
                const existShowDescription = _.get(submissionVM.value, 'baseData.description_Ext');
                _.set(submissionVM, 'baseData.description_Ext', existShowDescription);
            } else {
                _.set(submissionVM, 'baseData.description_Ext', showDescription);
            }

            // When renew period is selected, set period end to end of coverageDate
            const periodEndDate = _.get(submissionVM.value, 'baseData.periodEndDate');
            if (moment(effectiveDate).diff(moment(periodEndDate), 'days') > 0) {
                _.set(submissionVM, 'baseData.periodEndDate', maximumEffectiveDate);
            }
            updateWizardData(submissionVM);
            // save page temp data
            // submissionVM.value.beforeSave();
            const primaryAddress =  _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress')
            if(_.isNil(primaryAddress.addressLine1) && !_.isNil(primaryAddress.pobox_Ext)) {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.addressLine1', primaryAddress.pobox_Ext)
            }
            const policyChangeResponse = await WniPAPolicyChangeService.savePolicyDetails(
                _.get(submissionVM.value, 'jobID'),
                _.get(submissionVM.value, 'policyNumber'),
                _.get(submissionVM.value, 'description'),
                _.get(submissionVM.value, 'baseData'),
                _.get(submissionVM.value, 'lobData.personalAuto.preQualQuestionSets[0]'),
                'PAPolicyDetails',
                authHeader
            );
            submissionVM.value = policyChangeResponse;
            updateWizardSnapshot(submissionVM);
            const backendErrosAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings_Ext', []);
            const backendValidationIssues = ErrorsAndWarningsUtil.getValidationIssues(backendErrosAndWarnings)
            const hasBackendError = ValidationIssueUtil.hasErrorInValidationIssueList(backendValidationIssues)
            const frontendValidationIssuesWarning = getFrontendValiationIssuesWarning();
            const allValidationIssues = backendValidationIssues
                .concat(frontendValidationIssuesWarning);
            updateValidationIssuesWarning(allValidationIssues);
            if (ErrorsAndWarningsUtil
                .hasNewValidationIssue(oldValidationIssuesWarning, allValidationIssues) || hasBackendError) {
                return false;
            }
        }
        return submissionVM;
    }, [
        submissionVM,
        authHeader,
        showDescription,
        updateWizardData,
        updateWizardSnapshot,
        EndorsementService,
        validationIssuesWarning,
        getFrontendValiationIssuesWarning,
        effectiveDate
    ]);

    const handleValidation = useCallback(
        () => {
            WindowUtil.scrollToInvalidField(invalidFields);
            updateShowErrors(true);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const onNext = useCallback(async () => {
        return isComponentValid ? savePolicyChange() : handleValidation();
    }, [
        isComponentValid,
        savePolicyChange,
        handleValidation,
    ]);

    const questionSetFilter = WniQuestionSetUtil.getQSFilterForPolicyDetails(policyState);
    const questionSetMapper = WniQuestionSetUtil.getQSMapperForPAPolicyChangePolicyDetails(policyState);
    // const questionSetGetInvalidAnswerKeys = WniQuestionSetUtil.getPAInvalidAnswerKeys();

    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        const maxDate = ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
            maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
        return maxDate
    }, [maximumEffectiveDate, submissionVM])

    const allValidationIssues = validationIssuesWarning
        .concat(wizardErrorsAndWarnings)
        .concat(frontendErrors)
        .concat(ErrorsAndWarningsUtil
            .convertPcDisplayMessageToIssues(coveragesErrorsAndWarnings))
        .concat(
            OOSUtil.getOOSSliceDatesWarning(
                OOSUtil.getOOSSliceDatesString(
                    oossliceDates,
                    effectiveDate
                ),
                translator,
                true
            )
        );
    const renderPrimaryNamedInsuredValue = () => {
        return (
            <>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))}</div>
            </>
        )
    }
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
        },
        effectiveDate: {
            value: _.get(submissionVM.value, 'baseData.effectiveDate'),
            minDate: _.get(submissionVM, 'value.baseData.periodStartDate'),
            maxDate: effectiveMaxDate(),
            showErrors,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: IssuanceValidationUtil.getIssuesMap(allValidationIssues),
            visible: validationIssuesWarning.length > 0
                || wizardErrorsAndWarnings.length > 0
                || frontendErrors.length > 0
                || ErrorsAndWarningsUtil
                    .convertPcDisplayMessageToIssues(coveragesErrorsAndWarnings).length > 0
                || OOSUtil.getOOSSliceDatesString(
                    oossliceDates,
                    effectiveDate
                ).length > 0,
            scrollToIssues: true,
            issueRenderFn: DomRenderUtil.issueRenderFn,
        },
        coverageStartDate: {
            minDate: new Date(),
            onValueChange: onStartDateChange,
        },
        paTermTypedropdownselect: {
            onValueChange: onTermTypeChange,
            /**
             * in policyChange, 'other' needs to be retained,
             * because the data of the policychange after pc rewrite may be 'other'.
             * so the availableValues don't need to filter
             */
            // availableValues: TermTypeUtil.getPATermTypeAvailableValues(translator)
        },
        coverageExpirationDate: {
            readOnly: !isTermTypeOther(),
        },
        primaryNamedInsuredField: {
            value: renderPrimaryNamedInsuredValue()
        },
        primaryNamedInsuredGrid: {
            className: WniPNIUtil.isPNIValid(PNI) ? 'form-with-address-group' : 'form-with-address-group-error-state'
        },
        primaryNamedInsuredName: {
            content: getPrimaryNamedInsuredDisplayName(),
        },
        primaryNamedInsuredAddress: {
            content: WniPNIUtil.getPrimaryAddressDisplayName(
                _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress')
            ),
        },
        primaryNamedInsuredEditIcon: {
            onClick: openEditPrimaryNamedInsuredPopup,
        },
        agencyAndAgentInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: true,
                licensedAgent: true,
                servicingAgent: true,
            },
            producerCodePath: 'producerCode_Ext',
            isReadOnly: true
        },
        isForNamedNonOwner_Ext: {
            onValueChange: handlePolicyTypeChange,
            visible: originPolicyType === 'Select' || originPolicyType === 'Named Non-Owner'
        },
        // paUnderwritingQuestionsSection: {
        //     UWValues,
        //     updateUWValues,
        // },
        policyUnderwritingQuestionSets: {
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            onValidate,
            // getInvalidAnswerKeys: questionSetGetInvalidAnswerKeys,
        },
        covertNotification: {
            visible: isShowNotification(),
            message: loadCovertPolicyInfo(),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleEffectiveDateChange: handleEffectiveDateChange,
            handleDescription: handleDescription,
            handlePolicyTypeChange
        },
        resolveComponentMap: {
            agencyagentinfo: AgencyAndAgentInfoComponent,
            validationissuescomponent: ValidationIssuesComponent,
            // paunderwritingquestionsinput: PAUnderwritingQuestionsInput,
            questionset: QuestionSetComponent,
        },
    };

    return (
        <WizardPage
            onNext={
                onNext
            }
            // disableNext={!isComponentValid}
            showPrevious={false}
            disableNext={showLoader || !_.isEmpty(frontendErrors)}
            skipWhen={PolicyChangeUtil.getSkipRatedQuotedFn(initialValidation)}
        >
            {
                !showLoader && (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PAPolicyDetailsChangePage.propTypes = {
    ...wizardProps,
};
export default PAPolicyDetailsChangePage;
