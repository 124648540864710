
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { SearchAndAddCoveragesPopup } from 'wni-capability-common-react';
import metadata from './GLLocationComponent.metadata.json5';
import GLLocationDetails from './LocationDetails/GLLocationDetails';
import GLCoveragesConfig from '../../GeneralLiability/GLCoverages.config'
import CoveragesConfigContext from '../../GeneralLiability/context/GLCoveragesConfigContext'
import LocationStandardMultiSublineClauses from './LocationStandardMultiSublineClauses/LocationStandardMultiSublineClauses';
import LocationAdditionalInsuredMultiSublineClauses from './LocationAdditionalInsuredMultiSublineClauses/LocationAdditionalInsuredMultiSublineClauses';
import LocationAdditionalCoverageMultiSublineClauses from './LocationAdditionalCoverageMultiSublineClause/LocationAdditionalCoverageMultiSublineClauses';
import LocationExclusionAndConditionMultiSublineClauses from './LocationExclusionAndConditionMultiSublineClause/LocationExclusionAndConditionMultiSublineClauses'

function GLLocationComponent(props) {
    const modalApi = useWniModal();
    const {
        currentRow = {},
        locationVM,
        submissionVM,
        updateWizardData,
        writeValue,
        onValidate,
        showErrors,
        isReadOnly,
        locationClauses,
        updateLocationClauses,
        isEditing,
        setIsEditing,
        externalData: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;

    const {
        publicID: locationPublicID
    } = locationVM.value

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const newComponentProps = _.omit(props, 'id');

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            locationPublicID
        };
        return modalApi.showModal(
            <SearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, jobID, locationPublicID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchCoverage = useCallback((covType) => {
        showCoveragesModal(covType).then(
            (data) => {updateLocationClauses(data)}
        ).catch(_.noop);
    },[showCoveragesModal, updateLocationClauses])

    const mutiSublineClausesProps = {
        locationPublicID,
        locationClauses: locationClauses,
        submissionVM: submissionVM,
        updateWizardData: updateWizardData,
        updateLocationClauses: updateLocationClauses,
        isEditable: !isReadOnly,
        isEditing,
        setIsEditing,
        onValidate,
        showErrors: showErrors,
        onClickAddSearchCoverage
    }

    const overrideProps = {
        '@field': {
            ...newComponentProps,
        },
        locationStandardMultiSublineClauses: {
            ...mutiSublineClausesProps,
        },
        locationAdditionalCoverageMultiSublineClauses: {
            ...mutiSublineClausesProps,
        },
        locationExclusionAndConditionMultiSublineClauses: {
            ...mutiSublineClausesProps,
        },
        locationAdditionalInsuredMultiSublineClauses: {
            ...mutiSublineClausesProps,
        }
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            locationdetails: GLLocationDetails,
            LocationStandardMultiSublineClauses: LocationStandardMultiSublineClauses,
            LocationAdditionalCoverageMultiSublineClauses: LocationAdditionalCoverageMultiSublineClauses,
            LocationExclusionAndConditionMultiSublineClauses: LocationExclusionAndConditionMultiSublineClauses,
            LocationAdditionalInsuredMultiSublineClauses: LocationAdditionalInsuredMultiSublineClauses
        }
    };
    return (
        <CoveragesConfigContext.Provider value={GLCoveragesConfig}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={currentRow}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </CoveragesConfigContext.Provider>
        
    );
}

export default GLLocationComponent;
