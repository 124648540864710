import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './BOPPolicyDetailsPage.metadata.json5';
import messages from './BOPPolicyDetailsPage.messages';

function BOPPolicyDetailsPage(props) {
    const modalApi = useModal();
    const { onValidate, initialValidation } = useValidation('BOPPolicyDetailsPage');
    const {
        wizardData: submissionVM,
        updateWizardData,
        authHeader,
        history,
        wizardSnapshot
    } = props;
    const { RenewalService } = useDependencies('RenewalService');

    const onNext = useCallback(async () => {
        try {
            if (!_.isEqual(submissionVM.value, wizardSnapshot.value)) {
                submissionVM.value = await RenewalService.saveRenewal(
                    [submissionVM.value],
                    authHeader
                );
            }
            return submissionVM;
        } catch {
            return modalApi.showAlert({
                title: messages.renewalSaveModalTitle,
                message: messages.renewalSaveModalMessage,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then(() => {
                const jobID = _.get(submissionVM, 'jobID.value');
                const redirectPath = `/renewal/${jobID}/summary`;
                return history.push(redirectPath);
            }, _.noop);
        }
    }, [RenewalService, authHeader, history, submissionVM, wizardSnapshot]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left'
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage onNext={onNext} showPrevious={false} skipWhen={initialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

BOPPolicyDetailsPage.propTypes = wizardProps;
BOPPolicyDetailsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(BOPPolicyDetailsPage));
