
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { DropdownMenuHeader } from '@jutro/components';
import { WizardContext, wizardStepProps } from '@xengage/gw-portals-wizard-react';

import { DummyMultiQuoteService } from 'wni-capability-quoteandbind-dummy';

import { BaseAddNewVersionComponent } from 'wni-components-platform-react';

import { QuoteCommonMessages } from 'wni-platform-translations';

// import metadata from './DummyAddNewVersionComponent.metadata.json5';
// import styles from './DummyAddNewVersionComponent.module.scss';
import messages from './DummyAddNewVersionComponent.messages';



import { DropdownMenuLink } from '@jutro/router';



/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function DummyAddNewVersionComponent(props) {
    

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const {
        // model: dtoVM,
        // onValueChange,
        // showErrors,
        sxsDataDTO = {},
        ...restProps
    } = props;


    const {
        // canAddNewHO3Version,
        // canAddNewHO5Version,
        // canAddNewVersion,
        // canAddNewSelectVersion,
        periods = [{}],
    } = sxsDataDTO;

    const [{
        branchCode,
        policyType_Ext: policyType,
    }] = periods;


    useEffect(() => {
    }, []);

    
    const getDummyMenuItems = useCallback((sxsDataDTOParam) => {
        const {
            // canAddNewHO3Version,
            // canAddNewHO5Version,
            canAddNewVersion,
            // canAddNewSelectVersion,
            // periods = [{}],
        } = sxsDataDTOParam;

        const menuItems = [
            {  
                menuName: translator(QuoteCommonMessages.addVersions),
                createService: DummyMultiQuoteService.createDuplicateQuote,
                menuVisible: canAddNewVersion,
            },
            // {  
            //     menuName: translator(messages.createHO3Version),
            //     createService: DummyMultiQuoteService.createHO3Quote,
            //     menuVisible: canAddNewHO3Version,
            // },
            // {  
            //     menuName: translator(messages.createHO5Verseion),
            //     createService: DummyMultiQuoteService.createHO5Quote, // Hmm.
            //     menuVisible: canAddNewHO5Version,
            // },
            // {  
            //     // menuName: translator(messages.createSelectVersionParam, { policyType: normalizedPolicyType }),
            //     menuName: translator(messages.createSelectVersionParam),
            //     createService: DummyMultiQuoteService.createSelectQuote,
            //     menuVisible: canAddNewSelectVersion,
            // },
        ];
        
        return menuItems;

    }, []);

    const menuItems = getDummyMenuItems(sxsDataDTO);

    return (
        <BaseAddNewVersionComponent {...restProps}
            menuItems={menuItems}
        />
    );

}

DummyAddNewVersionComponent.propTypes = {
    sxsDataDTO: PropTypes.shape({}).isRequired,
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
    // /**
    //  * (Optinal) get menu items
    //  */
    // getMenuItems: PropTypes.func,
};

DummyAddNewVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,
    // getMenuItems: undefined,
};

export default DummyAddNewVersionComponent;
