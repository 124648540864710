
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniDateUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { OrganizationService } from 'gw-capability-gateway';
import { WniAccountService } from 'wni-capability-gateway';
import { useTranslator } from '@jutro/locale';
import { ProductsField } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import AddressInputComponent from '../AddressInputComponent/AddressInputComponent';
import messages from './AccountContactInputComponent.messages';
import metadata from './AccountContactInputComponent.metadata.json5';
import styles from './AccountContactInputComponent.module.scss';

const AccountContactInputComponent = (props) => {
    const {
        model: accountContactDtoVM,
        updateAccountHolderViewVM,
        onAccountContactChange,
        onOrganisationOptionsChange,
        onProducerCodeOptionsChange,
        onContactTypeChange,
        updateAddressSubType,
        showErrors,
        isHideContactType,
        dateOfBirthContainerConfig,
        hideRequiredforQuote,
        onValidate,
        products,
        //
        showProductsContainer, // default to true
        //
        useAuthenticationData,
        useDependenciesData,
        viewModelService,
    } = props;
    const useAuth = useAuthentication();
    const authData = useAuthenticationData || useAuth;
    const { authHeader, authUserData } = authData;
    const translator = useTranslator();
    const useDep = useDependencies('workflowType');
    const { workflowType } = useDependenciesData || useDep;

    const [contactTypeValue, updateContactTypeValue] = useState('');
    const [producerCodeOptions, updateProducerCodeOptions] = useState([]);
    const [organisationOptions, updateOrganisationOptions] = useState([]);
    const [concactTypeRequired, updateConcactTypeRequired] = useState({});

    useEffect(() => {
        // _.set(accountContactDtoVM, 'primaryPhoneType', 'mobile');
        if (_.isNil(_.get(accountContactDtoVM, 'value.primaryPhoneType'))) {
            _.set(accountContactDtoVM, 'primaryPhoneType', 'mobile');
        }
        updateAccountHolderViewVM(accountContactDtoVM);
        updateConcactTypeRequired({
            home: _.get(accountContactDtoVM, 'value.primaryPhoneType') === 'home',
            work: _.get(accountContactDtoVM, 'value.primaryPhoneType') === 'work',
            mobile: _.get(accountContactDtoVM, 'value.primaryPhoneType') === 'mobile'
        });
    }, []);
    // -----------for Personal Account----------
    const updateProducerCodeOptionsAll = (newProducerCodeOptions) => {
        updateProducerCodeOptions(newProducerCodeOptions);
        onProducerCodeOptionsChange(newProducerCodeOptions);
    };

    const getProducerCodes = () => {
        WniAccountService.getAvailableProducerCodesForCurrentUser(authHeader).then(
            (producerCodeResponse) => {
                const newProducerCodeOptions = _.sortBy(producerCodeResponse, 'code').map(
                    (value) => {
                        return {
                            code: value.code,
                            name: value.displayValue,
                            publicID: value.publicID
                        };
                    }
                );
                updateProducerCodeOptionsAll(newProducerCodeOptions);
            }
        );
    };

    const getAllOrganisations = () => {
        OrganizationService.getAvailableOrganizations(authHeader).then(
            (getOrganizationsResponse) => {
                const allOrganisationValue = getOrganizationsResponse.map((value) => {
                    return {
                        code: `${value.publicID}`,
                        name: `${value.displayName}`,
                    };
                });

                updateOrganisationOptions(allOrganisationValue);
                onOrganisationOptionsChange(allOrganisationValue);
            }
        );
    };

    useEffect(() => {
        let subTypeLabel = '';
        let newContactTypeValue = '';
        if (_.get(accountContactDtoVM, 'subtype.value') === 'Person') {
            newContactTypeValue = translator(messages.contactTypePersonal);
            subTypeLabel = translator(messages.person);
        } else if (_.get(accountContactDtoVM, 'subtype.value') === 'Company') {
            newContactTypeValue = translator(messages.contactTypeCommercial);
            subTypeLabel = translator(messages.company);
        }
        updateContactTypeValue(newContactTypeValue);
        updateAddressSubType(subTypeLabel);
        onContactTypeChange(newContactTypeValue);

        if (authUserData && authUserData.userType === 'producer') {
            getProducerCodes();
        } else {
            getAllOrganisations();
        }
    }, [authUserData]);

    // const getProducerCodesForAnOrganization = (selectedOrganisationValue) => {
    //     let organisationCode = {};

    //     const selectedOrg = selectedOrganisationValue;

    //     organisationOptions.forEach((value) => {
    //         if (value.code === selectedOrg) {
    //             organisationCode = {
    //                 displayName: value.name,
    //                 publicID: value.code,
    //             };
    //         }
    //     });
    //     OrganizationService.getProducerCodeForOrganization(
    //         organisationCode,
    //         authHeader
    //     ).then((producerCodeResponse) => {
    //         const producerCodes = producerCodeResponse.map((value) => {
    //             return {
    //                 code: value.code,
    //                 name: value.displayValue,
    //                 publicID: value.publicID
    //             };
    //         });
    //         onAccountContactChange('', 'producerCode');
    //         updateProducerCodeOptionsAll(producerCodes);
    //     });
    // };

    const handleAddresssChange = (value, path) => {
        onAccountContactChange(value, path);
    };

    const contactTypeChangeFn = async (value, path,) => {
        switch (value) {
            case 'home':
                updateConcactTypeRequired({
                    home: true,
                    work: false,
                    mobile: false
                });
                break;
            case 'work':
                updateConcactTypeRequired({
                    home: false,
                    work: true,
                    mobile: false
                });
                break;
            case 'mobile':
                updateConcactTypeRequired({
                    home: false,
                    work: false,
                    mobile: true
                });
                break;
            default:
                updateConcactTypeRequired({});
                break;
        }
        onAccountContactChange(value, path, (vm) => {
            _.set(vm, path, value);
            _.set(vm, 'homeNumber', '');
            _.set(vm, 'workNumber', '');
            _.set(vm, 'cellNumber', '');
        });
    };

    const dateInputChange = (value, path) => {
        onAccountContactChange(value, path);
    };

    const getPlaceholder = () => {
        return hideRequiredforQuote ? '' : '-- Required for Quote --';
    };
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false
        },
        firstName: {
            placeholder: getPlaceholder()
        },
        lastName: {
            placeholder: getPlaceholder()
        },
        contactType: {
            value: contactTypeValue,
            visible: !isHideContactType,
        },
        contactTypeSelect: {
            placeholder: getPlaceholder()
        },
        primaryNamedInsuredTitle: {
            visible: !isHideContactType
        },
        accountAddressContainer: {
            model: accountContactDtoVM,
            dataPath: 'primaryAddress',
            onAddressChange: handleAddresssChange,
            hideFieldType: {
                addressType: true,
                country: true
            },
            showErrors,
            hideRequiredforQuote,
            onValidate,
            useAuthenticationData,
            useDependenciesData,
            viewModelService
        },
        producerCode: {
            availableValues: producerCodeOptions,
        },
        homePhone: {
            visible: !!concactTypeRequired.home
        },
        mobilePhone: {
            visible: !!concactTypeRequired.mobile
        },
        workPhone: {
            visible: !!concactTypeRequired.work
        },
        dateOfBirthContainer: {
            maxDate: new Date(),
            minDate: WniDateUtil.backEarlyDate(),
            value: _.get(accountContactDtoVM.value, 'dateOfBirth'),
            onValueChange: dateInputChange,
            showErrors,
            ...dateOfBirthContainerConfig
        },
        productsContainer: {
            ...products,
            productVisible: _.get(products, 'requireProductsContainer', !workflowType.ClassicalWorkflow),
            useAuthenticationData,
            useDependenciesData,
            viewModelService,
            visible: showProductsContainer,
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            accountContactDtoVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            contactTypeChangeFn: contactTypeChangeFn
        },
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            productsfield: ProductsField
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountContactDtoVM}
            // onModelChange={updateAgencyAndAgentInfo}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={onAccountContactChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

AccountContactInputComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onAccountContactChange: PropTypes.func,
    updateAddressSubType: PropTypes.func,
    onOrganisationOptionsChange: PropTypes.func,
    onProducerCodeOptionsChange: PropTypes.func,
    onContactTypeChange: PropTypes.func,
    showErrors: PropTypes.bool,
    isHideContactType: PropTypes.bool,
    dateOfBirthContainerConfig: PropTypes.PropTypes.shape({}),
    hideRequiredforQuote: PropTypes.bool,
    onValidate: PropTypes.func,
    //
    showProductsContainer: PropTypes.bool,
};

AccountContactInputComponent.defaultProps = {
    model: {},
    onAccountContactChange: undefined,
    updateAddressSubType: undefined,
    onOrganisationOptionsChange: undefined,
    onProducerCodeOptionsChange: undefined,
    onContactTypeChange: undefined,
    showErrors: false,
    isHideContactType: false,
    dateOfBirthContainerConfig: {},
    hideRequiredforQuote: false,
    onValidate: undefined,
    requireProductsVisible: false,
    //
    showProductsContainer: true,
};

export default AccountContactInputComponent;
