import React, { useCallback, useEffect, useState } from 'react';
import { ToastProvider } from '@jutro/components'
import { withRouter, useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { AccountService } from 'gw-capability-gateway-policy';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {  WniAccountQuoteService } from 'wni-capability-gateway';
import {  WniAccountsUtil } from 'wni-portals-util-js';
import {  useBusinessData } from 'wni-portals-util-react';
import messages from 'gw-capability-gateway-react/Accounts/Accounts.messages';
import EditAccountDetailsPopUp from './EditAccountDetailsPopUp';
import PendingTransactions from './PendingTransactions/PendingTransactions';
import ActivePolicies from './ActivePolicies/ActivePolicies';
import metadata from './AccountSummary.metadata.json5';

function AccountSummary(props) {
    const modalApi = useWniModal();
    const {
        fromAccountLanding: { 
            accountDetailsData = {},
            accountDetailsData: {
                accountNumber,
                accountHolder,
                accountHolder: {
                    contactType_Ext: accountType
                } = {},
                producerCodes,
                availableProducerCodesForCurrentUser_Ext: availableProducerCodesForCurrentUser
            }
        } = {},
    } = props;
    const history = useHistory();
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const [accountData, updateAccountData] = useState(accountDetailsData);
    const [activePoliciesData, updateActivePoliciesData] = useState([]);
    const [pendingTransactionsData, updatePendingTransactionsData] = useState([]);
    const { loadingMask: { setLoadingMask }, interactionModel} = useDependencies(['interactionModel', 'loadingMask']);
    const [selectedAgency, updateSelectedAgency] = useState({});
    const { enableCommercialLine } = useBusinessData();

    const isCLAccount = WniAccountsUtil.isCLAccount(accountType);

    useEffect(() => {
        if (!_.isNil(producerCodes)) {
            // external user NB
            updateSelectedAgency(producerCodes[0]);
        } else if (!_.isEmpty(availableProducerCodesForCurrentUser)){
            // internal user person type account create NB
            updateSelectedAgency(availableProducerCodesForCurrentUser[0]);
        }
    }, [producerCodes, availableProducerCodesForCurrentUser]);
    useEffect(() => {
        const { policySummaries } = accountData;
        updateActivePoliciesData(policySummaries)
    }, [accountData]);

    const initData = useCallback(async(accountNumberParam) => {
        setLoadingMask(true);
        let res = await WniAccountQuoteService.getAQPrendingTransactionsForAccount(
            accountNumberParam,
            authHeader
        );
        if (!_.isArray(res)) {
            res = [];
        };
        updatePendingTransactionsData(res);
        setLoadingMask(false)
    }, []);

    useEffect(() => {
        if(accountNumber){
            initData(accountNumber)
        }
    }, [accountNumber]);

    const showModal = async (data) => {
        const result = await modalApi.showModal(
            <EditAccountDetailsPopUp
                title={translator(messages.editContactDetails)}
                actionBtnLabel={messages.saveChanges}
                cancelBtnLabel={messages.cancel}
                accountData={data}
                size='lg'
            />
        );
        return result;
    };

    const handleEditButtonClick = () => {
        showModal(accountData).then(async (result) => {
            const contactVM = await AccountService.updateAccountDetails(result, authHeader);
            updateAccountData(contactVM)
            ToastProvider.toast({
                type: 'success',
                message: translator(messages.savedMessage),
                autoClose: true
            });
        }).catch(_.noop);
    };
    const getPhoneNumber = () => {
        let phonePath = '';
        const primaryPhoneType = _.get(accountData, 'accountHolder.primaryPhoneType');
        switch(primaryPhoneType){
            case 'mobile':
                phonePath = 'cellNumber';
                break;
            default:
                phonePath = `${primaryPhoneType}Number`
                break;
        }
        return `accountHolder.${phonePath}`
    };

    const getAgencyPrintPrefValue = () => {
        const agencyPrintPref = _.get(accountData, 'accountHolder.agencyPrintPref_Ext');
        return translator({id: `typekey.PolicyHolderPrintPref_Ext.${agencyPrintPref}`})
    }
    const overrides = {
        stateAndPostalcode: {
            value: `${_.get(
                accountData,
                'accountHolder.primaryAddress.state'
            )} ${_.get(
                accountData,
                'accountHolder.primaryAddress.postalCode'
            )}`,
        },
        phoneNumber: {
            path: getPhoneNumber(),
        },
        renewalsValue: {
            value: accountData.numberOfOpenPolicyRenewals
        },
        cancellationsValue: {
            value: accountData.numberOfOpenPolicyCancellations
        },
        changesValue: {
            value: accountData.numberOfOpenPolicyChanges
        },
        auditsValue: {
            value: accountData.numberOfActiveAudits_Ext
        },
        pendingTransactions: {
            accountNumber,
            accountType,
            accountHolder,
            activePolicies: activePoliciesData,
            data: pendingTransactionsData,
            refreshPrendingTransactions: initData,
            selectedAgency,
            history
        },
        activePolicies: {
            data: activePoliciesData,
            accountNumber,
            accountType,
            availableProducerCodesForCurrentUser,
            history
        },
        auditsInfo: {
            visible: isCLAccount
        },
        printPreferenceField: {
            // POI-55634 apply print preference to PL + CL
            visible: enableCommercialLine,
            value: getAgencyPrintPrefValue()
        },
        terrorismIndicator: {
            visible: isCLAccount,
        },
        triaType: {
            visible: isCLAccount
        },
        coverageForCertifiedActs: {
            visible: isCLAccount
        },
        viewPriorPolicies: {
            ...interactionModel.getURLObj(null, 'accountSummary', accountNumber)
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            handleEditButtonClick
        },
        resolveComponentMap: {
            pendingtransactions: PendingTransactions,
            activepolicies: ActivePolicies
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountData}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(AccountSummary);
