import { defineMessages } from 'react-intl';

export default defineMessages({
    quote: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Quote',
        defaultMessage: 'Quote'
    },
    currentPremium: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Current Premium',
        defaultMessage: 'Current Premium'
    },
    changeInCost: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Change in Cost',
        defaultMessage: 'Change in Cost'
    },
    newPremium: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.New Premium',
        defaultMessage: 'New Premium'
    },
    policyPeriod: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Policy Period',
        defaultMessage: 'Policy Period'
    },
    premium: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.policyDetails.policyDetails.Premium',
        defaultMessage: 'Premium'
    }
});
