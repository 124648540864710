import { defineMessages } from 'react-intl';

export default defineMessages({
    structure: {
        id: 'gateway.directives.otherStructuresTable.Structure',
        defaultMessage: 'Structure'
    },
    structureType: {
        id: 'gateway.directives.otherStructuresTable.Structure Type',
        defaultMessage: 'Structure Type'
    },
    value: {
        id: 'gateway.directives.otherStructuresTable.Value',
        defaultMessage: 'Value'
    },
    confirm: {
        id: 'gateway.directives.otherStructuresTable.Confirm',
        defaultMessage: 'Confirm'
    },
    deleteMessage: {
        id: 'gateway.directives.otherStructuresTable.Are you sure you want to remove this coverage. Any coverage limits selected for this coverage will be lost',
        defaultMessage: 'Are you sure you want to remove this coverage. Any coverage limits selected for this coverage will be lost'
    }
});