
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import SingleClauseContext from '../../../../context/IMSingleClauseContext';
import IMScheduleTable from '../../../../components/IMScheduleTable/IMScheduleTable';
import IMCoverageUtil from '../../../../util/IMCoverageUtil';

const MiscFormsCoverageSchedule = (props) => {

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { authHeader } = useAuthentication();

    const {
        path,
        coverageContainer,
        isEditable,
        onValidate: onCoverageContainerValidate,
        setIsEditing,
        setCoverageContainer,
        updateCoverageContainerService,
        showErrors,
        jobID,
        sessionUUID,
    } = props

    const {
        [path]: coverage
    } = coverageContainer

    const {
        code_Ext: clauseCode,
        schedule,
    } = coverage

    const validationID = path;

    const { onValidate, isComponentValid } = useValidation(validationID);

    useEffect(() => {
        if (onCoverageContainerValidate) {
            onCoverageContainerValidate(isComponentValid, validationID);
        }
        return () => {
            if (onCoverageContainerValidate) {
                onCoverageContainerValidate(true, validationID);
            }
        };
    }, [isComponentValid, onCoverageContainerValidate, validationID]);

    const changeClauses = useCallback(
        (value, changedPath, clausesPath, originClauses) => {
            const newClauses = IMCoverageUtil.setClauseValue(originClauses, clausesPath, value, changedPath);
            const newDroneCoverage = _.clone(coverageContainer)
            _.set(newDroneCoverage, clausesPath, newClauses);
            setCoverageContainer(newDroneCoverage);
            setIsEditing(false);
            return newDroneCoverage;
        },
        [coverageContainer, setCoverageContainer, setIsEditing]
    );
    
    const changeCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, path, coverageContainer);
        },
        [changeClauses, coverageContainer, path]
    );


    const updateVMToServer = useCallback(async (newDroneCoverage, setEditingFunc) => {
        const droneCoverageToUpdate = {[path]: newDroneCoverage[path]}
        setLoadingMask(true)
        const newDroneCoverageFromServer = await updateCoverageContainerService(
            jobID,
            sessionUUID,
            droneCoverageToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoverageContainer(newDroneCoverageFromServer);
    }, [path, setLoadingMask, updateCoverageContainerService, jobID, sessionUUID, authHeader, setCoverageContainer]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeMainCoveragesAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeCoverages, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coverageContainer);
        },
        [coverageContainer, updateNewCoveragePartClausesToServer]
    )

    return <SingleClauseContext.Provider value={{
        clauseCode,
        clausePath: path,
        isEditable,
        // isDisabled,
        onChangeClause: changeCoverages,
        onSyncCoverages: onSyncCoverages,
        onChangeSubmissionAndSync: changeMainCoveragesAndSync,
        labelPosition: 'left',
        onValidate,
        setIsEditing,
        showErrors,
    }}>
        <IMScheduleTable schedule={schedule} />
    </SingleClauseContext.Provider>
}

export default MiscFormsCoverageSchedule