import _ from 'lodash';


/**
 * To be refactored
 * @param {object} modalApi
 * @returns {object}
 */
export default function ModalDialogHelper(modalApi) {
    /**
     * To be refactored
     * @param {object} optConfigs
     */
    function alert(optConfigs = {}) {
        const {
            thenOp = _.noop,
            dialogOptions = {},
            errorMessage,
        } = optConfigs;
        modalApi.showAlert({
            title: 'Error',
            message: errorMessage || 'Error',
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: 'OK',
            ...dialogOptions,
        }).then(thenOp);
    }

    return {
        alert,
    };
}
