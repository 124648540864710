import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import _ from 'lodash';
import { useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniUrlUtil } from 'wni-portals-util-js';
import { messages as gwCommonMessages } from '@xengage/gw-platform-translations';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { WniPlatformMessages as wniCommonMessages } from 'wni-platform-translations';

import BaseWizard from '../../Wizard/BaseWizard';

function CommonTransactionWizard(props) {
    const modalApi = useWniModal();
    const { authHeader } = useAuthentication();

    const {
        onLeaveWizard: onLeaveTransactionWizard,
        ...otherProps
    } = props;

    const onLeaveWizard = useCallback(async ({
        wizardData: submissionVM,
        wizardPageData,
        currentStepIndex,
        //
        nextPath,
    }) => {
        const {
            jobID, sessionUUID, 
            baseData: {
                periodStatus,
            },
            //
            status: PAPolicyChangeStatus,   // for backward compatibility
        } = submissionVM.value;

        const jobStatus = periodStatus || PAPolicyChangeStatus;
        const isStatusBound = jobStatus === 'Bound';
        if (isStatusBound) {
            return true;
        }
        
        const skipCancelDialog = WniUrlUtil.shouldSkipConfirmCancelModalDialog(nextPath);
        if (skipCancelDialog) {
            return true;
        }

        
        let shouldGoToNextLocation = true;
        const result = await modalApi.showConfirm({
            title: wniCommonMessages.wantToSaveAndExit,
            message: gwCommonMessages.infoWillBeSavedAsDraft,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: wniCommonMessages.OK,
            cancelButtonText: wniCommonMessages.Cancel
        }).catch(_.noop); // no-op for 'close' thrown as exception
        // Possible outcome from ModalDialog: confirm, cancel, or close
        // Note that "close" will be thrown out and handled by .catch(),
        // hence the result is undefined in this scenario.
        if (result !== PortalConstants.MODAL_DIALOG_RESULT_CONFIRM) {
            shouldGoToNextLocation =  false;
        }
        // ========================================
        // setIsLoading(true);


        // ==================================================================
        // This line of code is causing trouble (infinite loop in AsyncPrompt.resumePageJump when try leaving). But why?
        else if (onLeaveTransactionWizard) {
            // IMPORTANT: be very cautious here, since onLeaveTarnsactionWizard() might 
            // crash AsyncPrompt by simply unmounting the whole Wizard.
            // If that happens ( which is shown by infinite loop), simply drop
            // the await here an call onLeaveTransactionWizard like a sync func, e.g.
            // 
            // onLeaveTransactionWizard({ wizardData: submissionVM, wizardPageData, currentStepIndex });
            //
            shouldGoToNextLocation = await onLeaveTransactionWizard({
                wizardData: submissionVM,
                wizardPageData,
                currentStepIndex,
            });
        }
        // ==================================================================

        
        // ==================================================================
        // ===========Meanwhile, this line of code could execute without issue. Why?
        // A: is because setIsLoading(false)  unmounts the whole Wizard, hence all things start over again.
        // 
        // const wizardExitData = WizardUtil.getQuoteWizardExitData(wizardPageData, { currentStepIndex });
        // await WniCommonQuoteService.saveWizardExitData(jobID, sessionUUID, wizardExitData, authHeader);
        // ==================================================================


        // ==================================================================
        // And this works. Looks like multi-layer of Promise result will mess up history::transitionManager.Prompt,
        // A closer look at history.js might be needed to see how to resolve it thoroughly.
        // 
        // A: Not much to do with history::transitionManager.Prompt(), but how could the whole Wizard
        // got unmounted by some outside force. LOL.
        // Perhaps it would be a better idea to define <AsyncPrompt> in LOB Transaciton Wzards
        // instead of in BaseWizard?
        //
        // else if (onLeaveTransactionWizard) {
        //     // The ideal solution would be take the return value of onLeaveTransactionWizard() and return,
        //     // in this case, I'm gonna ignore it at this moment due to the tricky behavior of how history.block() works.
        //     // Might gonna check it sometime later.
        //     onLeaveTransactionWizard({
        //         wizardData: submissionVM,
        //         wizardPageData,
        //         currentStepIndex,
        //     });
        // }
        // ==================================================================

        return shouldGoToNextLocation;
    }, [onLeaveTransactionWizard]);


    return (
        <BaseWizard
            onLeaveWizard = {onLeaveWizard}
            // onLeaveWizard = {onLeaveTransactionWizard}
            {...otherProps}
        />
    );
}

CommonTransactionWizard.propTypes = BaseWizard.propTypes;

CommonTransactionWizard.defaultProps = BaseWizard.defaultProps;

// export default withRouter(Wizard);
export default CommonTransactionWizard;