import _ from 'lodash';
import moment from 'moment';
import { WniDateUtil } from 'wni-portals-util-js';

const OOSSliceDateWarningPrefix = 'Your policy change is an out-of-sequence transaction. '

const getOOSSliceDatesString = (oossliceDates, effectiveDate) => {
    if (!oossliceDates || !effectiveDate) {
        return [];
    }
    return oossliceDates
        .filter(
            (oossliceDate) => moment(oossliceDate).diff(moment(effectiveDate), 'days') > 0
        ).map((date) => WniDateUtil.formatDateWithPattern(date));
};

const getOOSSliceDatesWarning = (getOOSSliceDatesStringArray, translator, showWarning = true) => {
    if (!_.isEmpty(getOOSSliceDatesStringArray) && showWarning) {
        return [{
            type: 'warning',
            reason: translator({
                id: 'custom.portal.components.transactionWizardTitle.transactionWizardTitle.OOSSliceDates',
                defaultMessage: `${OOSSliceDateWarningPrefix}There are future transaction(s) effective { OOSSliceDates }.`
            }, { OOSSliceDates: getOOSSliceDatesStringArray.join(', ') })
        }];
    }
    return [];
};

const isOOSSliceDateWarning = (issue) => {
    const reason = _.get(issue, 'reason');
    if (reason !== null && reason.startsWith(OOSSliceDateWarningPrefix)) {
        return true
    }
    return false
}

const getMaxChangeBoundDate = (oOSSliceDates) => {
        
        const sortOOSSliceDatesInMoment = oOSSliceDates.sort((a, b) => moment(b).diff(moment(a)));   
        return sortOOSSliceDatesInMoment[0] || '';
}

export default {
    getOOSSliceDatesString,
    getOOSSliceDatesWarning,
    isOOSSliceDateWarning,
    getMaxChangeBoundDate,

};
