import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    ToggleField,
    useModal
} from '@jutro/components';

import { useTranslator } from '@jutro/locale';
import { Link as LinkComponent } from 'gw-components-platform-react';

import messages from './WCPayAsYouGoComponent.messages';

function WCPayAsYouGoComponent(props) {
    const modalApi = useModal();
    const {
        value: isPayAsYouGo,
        onValueChange: onPayAsYouGo,
        showRequired,
        required,
        showErrors
    } = props;

    const translator = useTranslator();

    const message = `Our Pay As You Go program allows the policyholder to pay premiums based on actual exposures for the reporting period.\nFeatures:
    • Only available for Workers' Compensation, General Liability and Commercial Package including General Liability
    • Requires a 25% deposit premium that will be held on account and re-evaluated at each renewal.  This is in addition to reporting period payments. 
    • Policyholder must create an online account to submit reports and payments online
    • Offer monthly or quarterly reporting periods 
    • An email will be sent at the end of the reporting period to notify the policyholder it is time to report and pay
    • Report and payment are due on the 15th following the reporting period selected to avoid cancellation`

    const showInfoPopup = useCallback(() => {
        modalApi.showAlert({
            title: 'Pay As You Go',
            message: message,
            status: 'info',
            // icon: 'gw-info-outline',
        }).catch(_.noop);
    }, [modalApi, message]);

    const onValueChange = useCallback((value) => {
        // Calls WCQuotePage.onPayAsYouGo()
        onPayAsYouGo(value);
    }, [onPayAsYouGo]);

    const tooltipForItemInsured = () => {
        return (
            <LinkComponent onClick={() => showInfoPopup()} >Click here for details</LinkComponent>
        )
    };

    return (
        <ToggleField
            availableValues = {[{code: 'true', name: 'Yes'}, {code: 'false', name: 'No'}]}
            value = {isPayAsYouGo}
            id = "toggle-button"
            label = {messages.payAsYouGoOffer}
            labelPosition = "left"
            onValueChange = {onValueChange}
            secondaryLabel = {tooltipForItemInsured(translator)}
            secondaryLabelClassName = 'd-block font-NeutralUltraDark-light-14'
            className = 'jut__FieldComponent__reversed wniTooltipFieldsContainer'
            showRequired={showRequired}
            required={required}
            showErrors={showErrors}
        />
    );
}

WCPayAsYouGoComponent.propTypes = {
    value: PropTypes.bool,
    onValueChange: PropTypes.func,
    showRequired: PropTypes.bool,
    required: PropTypes.bool,
    showErrors: PropTypes.bool
};

WCPayAsYouGoComponent.defaultProps = {
    value: undefined,
    onValueChange: _.noop,
    showRequired: true,
    required: true,
    showErrors: true
};

export default WCPayAsYouGoComponent;
