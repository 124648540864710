
import React from 'react';
import CPPLineSelctionPage from './CPPLineSelectionPage';

function CPPLineSelctionReadOnlyPage(props) {

    return (
        <CPPLineSelctionPage {...props} isReadOnly />
    );

}

export default CPPLineSelctionReadOnlyPage;
