import { defineMessages } from 'react-intl';

export default defineMessages({
    OOSSliceDatesPopupMessage: {
        id: 'customer.pa.wizard.step.policychange.warning.OOSSliceDates',
        defaultMessage: 'Your policy change is an out-of-sequence transaction. There are future transaction(s) effective { OOSSliceDates }. Are you sure you want to continue?'
    },
    paPolicyDetailsTitle: {
        id: 'policychange.wizard.step.Policy Details',
        defaultMessage: 'Policy Details'
    },
    paTermType: {
        id: 'customer.pa.wizard.step.policychange.termtype.paTermType',
        defaultMessage: 'Term Type'
    },
    paTermTypePleaseSelect: {
        id: 'customer.pa.wizard.step.policychange.termtype.placeholder.Please Select',
        defaultMessage: 'Please Select'
    },
    effectiveDate: {
        id: 'customer.pa.wizard.step.policychange.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyEffectiveDate: {
        id: 'customer.pa.wizard.step.policychange.Policy Effective Date',
        defaultMessage: 'Policy Effective Date'
    },
    coverageStartDate: {
        id: 'customer.pa.wizard.step.policychange.Coverage Start Date',
        defaultMessage: 'Coverage Start Date'
    },
    periodPolicyState: {
        id: 'customer.pa.wizard.step.policychange.Policy State',
        defaultMessage: 'Policy State'
    },
    primaryNamedInsured: {
        id: 'policychange.pa.views.pa-policy-info.Primary Named Insured',
        defaultMessage: 'Primary Named Insured'
    },
    // message: {
    //     id: 'policychange.Future Effective Date - Quoted premium may be inaccurate.',
    //     defaultMessage: 'Future Effective Date - Quoted premium may be inaccurate.'
    // },
    covertNotificationMessage: {
        id: 'policychange.pa.directives.templates.pa-edit-vehicle.covertNotificationMessage',
        defaultMessage: 'This quote was created based on information from the insured’s current Personal Auto policy as of . Please review it carefully to ensure that any recent changes have been applied.'
    },
    paLineUWTitle: {
        id: 'policychange.pa.views.line coverage.title.Underwriting Questions',
        defaultMessage: 'Underwriting Questions'
    },
    Description: {
        id: 'customer.pa.wizard.step.policychange.Policy Change Description',
        defaultMessage: 'Policy Change Description'
    },
    beforeSliceStartDate: {
        id: 'policychange.pa.frontendErrors.before start date',
        defaultMessage: 'Updating the policy change effective date has created an out-of-sequence transaction.  You will need to withdraw the transaction and enter a new policy change effective { effectiveDate }.  Please contact your underwriter if further assistance is needed.'
    },
    afterSliceEndDate: {
        id: 'policychange.pa.frontendErrors.Out of slice end date',
        defaultMessage: 'We are unable to update the effective date of an out-of-sequence transaction.  You will need to withdraw the transaction and enter a new policy change effective { effectiveDate }.  Please contact your underwriter if further assistance is needed.'
    }
});
