import React, {
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { BaseClausesComponentVM } from 'wni-components-platform-react';
import CoveragesConfigContext from '../../context/WCCoveragesConfigContext';
// import SingleClauseComponentVM from './SingleClauseComponentVM';

/**
 * Clause component hierarchy diagram:
 * ClausesComponentVM -> SingleClauseComponentVM -> ClauseComponent
 * @param {object} props
 * @returns {object}
 */
function WCClausesComponentVM(props) {
    const {
        value: clauses,
        path,
        labelPosition,
        loadingClauseMessage,
        showErrors,
        sortBySelected,
        showAmount,
        //
        clauseFilter,
        hideUnSelectCoverages,
        splitByClauseCategory,
        setIsEditing,
        isEditing,
        onOpenCoverageDetailsPopup
    } = props;

    const translator = useTranslator();
    const {coverageAvailable} = useContext(CoveragesConfigContext);

    const toMetadata = (clausesCategory) => {
        // override priority
        const { coveragePriorityOverride } = R1Config;
        const keys = Object.keys(coveragePriorityOverride);
        clausesCategory.filter((elt) => keys.includes(_.get(elt, 'code_Ext')))
            .forEach((elt) => {
                const key = _.get(elt, 'code_Ext');
                _.set(elt, 'priority_Ext', coveragePriorityOverride[key]);
            });
        let displayedClausesCategory = clausesCategory;
        if (hideUnSelectCoverages) {
            displayedClausesCategory = clausesCategory.filter((clause) => _.get(clause, 'selected'))
        }

        // sort
        let sortedClausesCategory;
        if (sortBySelected) {
            sortedClausesCategory = _.orderBy(displayedClausesCategory, ['selected', 'priority_Ext'], ['desc', 'asc']);
        } else {
            sortedClausesCategory = _.sortBy(displayedClausesCategory, ['priority_Ext']);
        }

        // filter out some clauses, e.g. filter out driverCoverages from LineCovInput
        sortedClausesCategory = sortedClausesCategory.filter((clause) => {
            const clauseCode = clause.code_Ext;
            if (!coverageAvailable.hasOwnProperty(clauseCode)) {
                return true
            }
            return coverageAvailable[clauseCode]()
        });

        return sortedClausesCategory.map((clause) => {
            const index = clauses.findIndex((element) => element.publicID === clause.publicID);
            const clausePath = `[${index}]`;

            const changedValuePath = WniClausesUtil.getChangedValuePath(path, clausePath);
            return {
                id: `clause_${clause.publicID}_[${index}]`,
                type: 'field',
                component: 'WCSingleClauseComponentVM',
                componentProps: {
                    ...props,
                    labelPosition,
                    loadingClauseMessage: translator(loadingClauseMessage),
                    value: clause,
                    path: changedValuePath,
                    containerClassName: 'clauseContainer',
                    showErrors: showErrors,
                    showAmount: showAmount,
                    onOpenCoverageDetailsPopup: onOpenCoverageDetailsPopup,
                    setIsEditing,
                    isEditing,
                }
            };
        });
    };

    const generateClauseGroup = (clauseCategory, index) => {
        const clausesMetadata = toMetadata(clauseCategory);

        const hideTitleOfClauseGroup = _.get(clauseCategory, '[0].coverageCategoryCode') === 'HideCategoryTitle'

        const clauseCategoryName = (splitByClauseCategory) && (!hideTitleOfClauseGroup) && {
            componentProps: { className: 'clause_category_name' },
            id: index ? `clause_category_title_[${index}]` : 'clause_category',
            type: 'element',
            component: 'h3',
            // If splitByClauseCategory is false, categoryDisplayName should be supplied
            content: clauseCategory[0].coverageCategoryDisplayName
        };
        return {
            id: `clause_category_container_[${index}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clauseCategoryContainer' },
            content: clauseCategoryName
                ? [clauseCategoryName, ...clausesMetadata] : clausesMetadata
        };
    };

    return (
        <BaseClausesComponentVM
            {...props}
            getClausesCategoryMetadata={toMetadata}
            generateClauseGroup={generateClauseGroup}
        />
    );
}

WCClausesComponentVM.propTypes = {
    ...BaseClausesComponentVM.propTypes,
    /**
    * (Optional) (clauseDataDTO) => boolean
     * Filter function to decide which clauses should be handled
     * by this component.
     */
    clauseFilter: PropTypes.func,
};
WCClausesComponentVM.defaultProps = {
    ...BaseClausesComponentVM.defaultProps,
    clauseFilter: undefined,
};

export default WCClausesComponentVM;
