import {
    useEffect
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import AgentsOnlineCodeMatch from '../AgentsOnlineCodeMatch/AgentsOnlineCodeMatch.json5'

function AgentsOnlineCommonRouting(props) {
    const { match, history } = props;
    const { lobEndorsementURL, endorsementProducts } = appConfig;

    useEffect(() => {
        const { params: { actionType, productCode, policyNumber } } = match;
        const PEProductCode = AgentsOnlineCodeMatch[productCode] || productCode;
        let path = '';
        let state = {};
        switch (actionType) {
            case 'external-endorsement':
                if (endorsementProducts.includes(PEProductCode)) {
                    path = lobEndorsementURL[PEProductCode];
                    state = { policyNumber: policyNumber };
                }
                break;
            case 'external-cancellation':
                path = `/policies/${policyNumber}/summary`;
                state = {
                    disableCancelLink: false,
                    disableChangePolicyLink: true,
                    displayBtnGroup: false
                };
                break;
            default:
                path = '/';
                break;
        }
        history.push(path, state);
    }, [history, match]);

    return (
        null
    );
}

AgentsOnlineCommonRouting.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withRouter(AgentsOnlineCommonRouting);
