import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleGaragedAtNew: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Garaged At.New',
        defaultMessage: 'New',
    },
    message0246: {
        id: 'Web.Policy.Address.Validation.StateCodeForGaraged',
        defaultMessage: 'Garaging Address is required'
    },
    message0247: {
        id: 'Web.Policy.Address.Validation.CheckPoBoxForGaraged',
        defaultMessage: 'Garaging address cannot be a PO Box address - Add garaging location'
    },
});
