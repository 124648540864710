import { defineMessages } from 'react-intl';

export default defineMessages({
    StandardCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class.Special Class Coverages',
        defaultMessage: 'Special Class Coverages',
    },
    AdditionalCoverages: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class.Special Class Additional Coverages',
        defaultMessage: 'Special Class Additional Coverages',
    },
    AddAdditionalCoverage: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class.Add Additional Coverages',
        defaultMessage: 'Add Additional Coverages',
    },
    ExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class.Special Class Exclusions and Conditions',
        defaultMessage: 'Special Class Exclusions and Conditions',
    },
    AddExclusionsAndConditions: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.Special Class.Add Exclusions and Conditions',
        defaultMessage: 'Add Exclusions and Conditions',
    }
});