import { defineMessages } from 'react-intl';

export default defineMessages({
    CommonCoverages: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.CommonCoverages",
        "defaultMessage": "Common Coverages"
    },
    TerrorismRiskInsuranceProgramApplies: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Terrorism Risk Insurance Program Applies",
        "defaultMessage": "Terrorism Risk Insurance Program Applies"
    },
    TerrorismRiskInsuranceAct: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Terrorism Risk Insurance Act",
        "defaultMessage": "Terrorism Risk Insurance Act"
    },
    CoverageforCertifiedActsofTerrorism: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Coverage for Certified Acts of Terrorism",
        "defaultMessage": "Coverage for Certified Acts of Terrorism"
    },
    CoverageforCertifiedActsofTerrorismHelpText: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.CoverageforCertifiedActsofTerrorismHelpText",
        "defaultMessage": "TRIA Coverages are specified at the account level.  Please return to the account summary screen to modify the selection."
    },
    AddCoverages: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Add Coverages",
        "defaultMessage": "Add Coverages"
    },
    AddedCoverages: {
        "id": "quoteandbind.im.wizard.step.AAIS Common forms page.Added Coverages",
        "defaultMessage": "Added Coverages"
    },
});