import { defineMessages } from 'react-intl';

export default defineMessages({
    agencySweep: {
        id: 'wni.account.payment.paymentSetup.Agency Sweep',
        defaultMessage: 'Agency Sweep',
    },
    addNewCreditCard: {
        id: 'wni.account.payment.paymentSetup.Add New Credit Card',
        defaultMessage: 'Pay By Credit Card'
    },
    addNewBankAccount: {
        id: 'wni.account.payment.paymentSetup.Add New Bank Account',
        defaultMessage: 'Pay By Bank Account'
    },
    agencySweepMessage: {
        id: 'wni.account.payment.paymentSetup.Agency Sweep Message',
        defaultMessage: 'You have selected Agency Sweep as the down payment method.  Please note, you will see a separate down payment for each policy issued.  If you are submitting multiple policies for issue, the down payment amount will not be combined.'
    },
    minimumAmount: {
        id: 'wni.account.payment.paymentSetup.Minimum Amount',
        defaultMessage: 'Value must be greater than ${amount}'
    },
    minimumDueOther: {
        id: 'wni.account.payment.paymentSetup.Minimum Amount Other',
        defaultMessage: 'Other'
    },
    minimumDue: {
        id: "wni.account.payment.paymentSetup.Minimum Due",
		defaultMessage: "Minimum Due"
    },
    totalDue: {
        id: "wni.account.payment.paymentSetup.Total Due",
		defaultMessage: "Total Due"
    },
    invalidValue: {
        id: 'wni.account.payment.paymentSetup.Invalid value',
        defaultMessage: 'Invalid value'
    },
    minimumDueWithFullPayDiscount: {
        id: "wni.account.payment.paymentSetup.Minimum Due with Auto Policy Full Pay Discount",
		defaultMessage: "Minimum Due with Auto Policy Full Pay Discount"
    }
});
