import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { DriverCoveragesSection, DriverCoveragesComponent } from 'wni-capability-gateway-react';
import { WniDriverService } from 'wni-capability-quoteandbind';
import { DriverCoveragesUtil } from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';

import metadata from './PADriverCoveragesPage.metadata.json5';

function DriverCoveragesPageReadOnly(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        policyChangeService = {},
        wizardPageData,
        updateWizardPageData
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    // const [validationIssues, updateValidationIssues] = useState([]);
    const { authHeader } = useAuthentication();
    const {
        onValidate
    } = useValidation('DriverCoveragesPage');
    const {
        quoteID,
        sessionUUID
    } = submissionVM.value;

    const [showLoader, updateShowLoader] = useState(false);
    const [drivers, updateDrivers] = useState([]);

    const getDriversCoverages = useCallback(async () => {
        if (_.isEmpty(_.get(wizardPageData, WizardConstants.driverCoverages))) {
            updateShowLoader(true);
            let res = {};
            if (_.isFunction(policyChangeService.getDriversCoverages)) {
                res = await policyChangeService.getDriversCoverages();
            } else {
                res = await WniDriverService
                    .getDriversCoverages({ quoteID, sessionUUID }, authHeader);
            }
            updateWizardPageData({ [WizardConstants.driverCoverages]: res });
            updateDrivers(res);
            updateShowLoader(false);
        } else {
            updateDrivers(_.get(wizardPageData, WizardConstants.driverCoverages));
        }
    }, [authHeader, policyChangeService, quoteID, sessionUUID, updateWizardPageData, wizardPageData]);

    useEffect(() => {
        getDriversCoverages();
    }, []);

    const generateOverrides = useCallback(() => {
        const overrides = drivers.map((driver, index) => {
            DriverCoveragesUtil.mapScheduleToDriver(driver, driver.coverages);
            return {
                [`coverageSection${index}`]: {
                    driver,
                    index,
                    serviceData: {
                        quoteID,
                        sessionUUID
                    },
                    fromReadOnlyPage: true
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [drivers, quoteID, sessionUUID]);

    const hasCovDrivers = drivers.filter((d) => d.coverages.length > 0);
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        driverCoveragesIterable: {
            data: drivers
        },
        emptyContent: {
            visible: hasCovDrivers.length <= 0
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveComponentMap: {
            ValidationIssuesComponent: ValidationIssuesComponent,
            driverCoveragesSection: DriverCoveragesSection,
            driverCoverages: DriverCoveragesComponent
        }
    };

    return (
        <WizardPage>
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={drivers}
                        resolveValue={readValue}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                    />
                )
            }
        </WizardPage>
    );
}

DriverCoveragesPageReadOnly.propTypes = wizardProps;
export default DriverCoveragesPageReadOnly;
