import _ from "lodash";

const CURRENT_DEBUG_DATA = {};

function setDebugData(path, value) {
  _.set(CURRENT_DEBUG_DATA, path, value);
}

function getDebugData(path) {
  return _.get(CURRENT_DEBUG_DATA, path);
}


export default {
  CURRENT_DEBUG_DATA,
  setDebugData,
  getDebugData
}
