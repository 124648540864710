import React from 'react'
import {
    ValueType,
} from '../../../../util/IMScheduleItemsUtil'
import PropertyFieldDropdown from './PropertyFieldComponents/PropertyFieldDropdown'

const IMScheduleDetailsPropertyField = (props) => {

    const {
        id,
        setScheduleItemFunc,
        propertyInfo,
        onValidate,
        showErrors,
        propertyInfoItemData,
        isEditable,
        setIsEditing,
    } = props

    const {
        schedulePropertyInfoType_Ext: valueType,
    } = propertyInfo
    
    const commonEditableCellComponentProps = {
        id,
        propertyInfo,
        setScheduleItemFunc,
        onValidate,
        showErrors,
        propertyInfoItemData,
        isEditable,
    }

    switch (valueType) {
        case ValueType.Option:
        case ValueType.AdditionalInsured:
        case ValueType.AdditionalInterest:
        case ValueType.ForeignKey:
        case ValueType.ForeignKeyWithOptionLabels:
        case ValueType.PolicyContact:
        case ValueType.TypeKey:
            return <PropertyFieldDropdown
                {...commonEditableCellComponentProps}
            />
        // case ValueType.Shorttext:
        // case ValueType.String:
        // case ValueType.TextArea:
            // return <ScheduleItemEditableCellText
            //     {...commonEditableCellComponentProps}
            // />
        // case ValueType.Direct:
        // case ValueType.BigDecimal:
            // return <ScheduleItemEditableCellBigDecimal
            //     {...commonEditableCellComponentProps}
            // />
        // case ValueType.Integer:
        // case ValueType.IntegerRange:
        // case ValueType.AutoNumber:
            // return <ScheduleItemEditableCellInteger
            // {...commonEditableCellComponentProps}
            // />
        // case ValueType.MONEY_EXT:
            // return <ScheduleItemEditableCellCurrency
            //     {...commonEditableCellComponentProps}
            // />
        // case ValueType.DateTime:
            // return <ScheduleItemEditableCellDate
            //     {...commonEditableCellComponentProps}
            // />
        // case ValueType.Date:
            // return <ScheduleItemEditableCellLocalDateObj
            //     {...commonEditableCellComponentProps}
            // />
        // case ValueType.Bit:
        // case ValueType.Boolean:
            // return <ScheduleItemEditableCellBoolean
            //     {...commonEditableCellComponentProps}
            // />
        default:
            return <div>Unsupport type editable cell</div>
    }

}

export default IMScheduleDetailsPropertyField