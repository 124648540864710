import React, {
    useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WizardSidebarLink } from 'wni-capability-gateway-react';
import { HOWizardQuestionnaireComponent } from 'wni-capability-quoteandbind-ho-react';


function DPWizardSidebarLink(props) {

    const renderExtraQuestionnaireLinks = useCallback(({
        wizardData,
        handleDownload,
    }) => {
        return (<HOWizardQuestionnaireComponent
            wizardData={wizardData}
            handleDownload={handleDownload}
            lobDataProductKey='dwellingProperty'
        />);
    }, []);

    return (
        <WizardSidebarLink sideLinkSlotRenderFunc={renderExtraQuestionnaireLinks}/>
    );
}


export default DPWizardSidebarLink;
