import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { CPRiskAnalysisPage } from 'wni-capability-quoteandbind-cp-react';

function CPRiskAnalysisChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('CP7Line', submissionVM);
    return (
        <CPRiskAnalysisPage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

}

CPRiskAnalysisChangePage.propTypes = CPRiskAnalysisPage.propTypes;
CPRiskAnalysisChangePage.defaultProps = CPRiskAnalysisPage.defaultProps;

export default CPRiskAnalysisChangePage;
