import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

//need to confirm the url
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote/hoPriorCarrier'), method, data, additionalHeaders);
}

export default class HOPriorCarrierService {
    static getPriorCarrierData(jobId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('getPriorCarrierData', [jobId, sessionUUID], additionalHeaders);
    }

    static updatePriorCarrier(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('updatePriorCarrier', [jobId, sessionUUID, newModel], additionalHeaders);
    }

    static deletePriorCarrier(jobId, sessionUUID, publicId, additionalHeaders = {}) {
        return processSubmission('deletePriorCarrier', [jobId, sessionUUID, publicId], additionalHeaders);
    }

    static savePriorCarrier(jobId, sessionUUID, newModel, additionalHeaders = {}) {
        return processSubmission('savePriorCarrier', [jobId, sessionUUID, newModel], additionalHeaders);
    }

}
