
import React from 'react';
import { WniProductsUtil, PolicyChangeUtil } from 'wni-portals-util-js';
import { CRCommercialCrimePage } from 'wni-capability-quoteandbind-cr-react';

const {
    CR_PRODUCT_CODE,
    getPolicyLinePatternName
} = WniProductsUtil;

const linePattern = getPolicyLinePatternName(CR_PRODUCT_CODE);

function CRCommercialCrimeChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent(linePattern, submissionVM);
    return (
        <CRCommercialCrimePage {...props} isPolicyChange={!isDisplayAsSubmission} />
    );

};

export default CRCommercialCrimeChangePage;