import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import RTPolicyChangeStartPage from './pages/Start/RTPolicyChangeStartPage';

import RTPaySuccessChangePage from './pages/PaySuccess/RTPaySuccessChangePage';

import RTPolicyDetailsChangePage from './pages/PolicyDetails/RTPolicyDetailsChangePage';
import RTPolicyDetailsChangeReadOnlyPage from './pages/PolicyDetails/RTPolicyDetailsChangePage.readonly';

import RTOperatorsChangePage from './pages/Operators/RTOperatorsChangePage';
import RTOperatorsChangeReadOnlyPage from './pages/Operators/RTOperatorsChangePage.readonly';

import RTVehiclesChangePage from './pages/Vehicles/RTVehiclesChangePage';
import RTVehiclesChangeReadOnlyPage from './pages/Vehicles/RTVehiclesChangePage.readonly';

import RTCoveragesChangePage from './pages/Coverages/RTCoveragesChangePage';
import RTCoveragesChangeReadOnlyPage from './pages/Coverages/RTCoveragesChangePage.readonly';

import RTIncidentsAndReportsChangePage from './pages/IncidentsAndReports/RTIncidentsAndReportsChangePage';
import RTIncidentsAndReportsChangeReadOnlyPage from './pages/IncidentsAndReports/RTIncidentsAndReportsChangePage.readonly';

import RTQuoteChangePage from './pages/Quote/RTQuoteChangePage';
import RTQuoteChangeReadOnlyPage from './pages/Quote/RTQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        RTPolicyChangeStartPage: {component: 'RTPolicyChangeStartPage'},
        RTPaySuccessChangePage: { component: 'RTPaySuccessChangePage' },

        RTPolicyDetailsChangePage: {component: 'RTPolicyDetailsChangePage'},
        RTPolicyDetailsChangeReadOnlyPage: { component: 'RTPolicyDetailsChangeReadOnlyPage' },

        RTOperatorsChangePage: { component: 'RTOperatorsChangePage' },
        RTOperatorsChangeReadOnlyPage: { component: 'RTOperatorsChangeReadOnlyPage' },

        RTVehiclesChangePage: { component: "RTVehiclesChangePage" },
        RTVehiclesChangeReadOnlyPage: { component: "RTVehiclesChangeReadOnlyPage"},

        RTCoveragesChangePage: { component: "RTCoveragesChangePage" },
        RTCoveragesChangeReadOnlyPage: { component: "RTCoveragesChangeReadOnlyPage" },

        RTIncidentsAndReportsChangePage: { component: "RTIncidentsAndReportsChangePage" },
        RTIncidentsAndReportsChangeReadOnlyPage: { component: "RTIncidentsAndReportsChangeReadOnlyPage" },

        RTQuoteChangePage: { component: 'RTQuoteChangePage' },
        RTQuoteChangeReadOnlyPage: { component: 'RTQuoteChangeReadOnlyPage' },
        
    },
    {
        RTPolicyChangeStartPage,
        RTPaySuccessChangePage,

        RTPolicyDetailsChangePage,
        RTPolicyDetailsChangeReadOnlyPage,

        RTOperatorsChangePage,
        RTOperatorsChangeReadOnlyPage,
      
        RTVehiclesChangePage,
        RTVehiclesChangeReadOnlyPage,

        RTCoveragesChangePage,
        RTCoveragesChangeReadOnlyPage,

        RTIncidentsAndReportsChangePage,
        RTIncidentsAndReportsChangeReadOnlyPage,

        RTQuoteChangePage,
        RTQuoteChangeReadOnlyPage,
    }
);

export { default as RTPolicyChangeWizard } from './RTPolicyChangeWizard';
