import walStaticMessage from '../../../wni-capability-quoteandbind-wal-react/pages/IncidentsAndReports/WALIncidentsAndReportsPage.static'

export default {
    userClue: {
        title: 'Prior Loss Entry',
        labelMap: walStaticMessage.userClue.labelMap,
        dtoPath: 'wni.edge.capabilities.quote.lob.roadtrail.dto.RTCLUEManuallyDTO'
    },
    vendorClue: {
        title: 'Prior Loss Reports',
        labelMap: walStaticMessage.vendorClue.labelMap,
        dtoPath: 'wni.edge.capabilities.quote.lob.roadtrail.dto.RTCLUEReportDTO'
    },
    userMvr: {
        title: 'MVR Entry',
        labelMap: walStaticMessage.userMvr.labelMap,
        dtoPath: 'wni.edge.capabilities.quote.lob.roadtrail.dto.RTMVRManuallyDTO'
    },
    vendorMvr: {
        title: 'MVR Reports',
        labelMap: walStaticMessage.vendorMvr.labelMap,
        dtoPath: 'wni.edge.capabilities.quote.lob.roadtrail.dto.RTMVRReportDTO'
    }
}