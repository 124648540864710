import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
// import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class WniQuoteNoteService {
    static getQuoteNoteData(quoteId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayQuoteNote'),
            'getQuoteNoteData', [quoteId], additionalHeaders);
    }

    static deletQuoteNote(quoteId, publicId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayQuoteNote'),
            'deletQuoteNote', [quoteId, publicId], additionalHeaders);
    }

    static saveQuoteNote(quoteId, newModel, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayQuoteNote'),
            'saveQuoteNote', [quoteId, newModel], additionalHeaders);
    }
}
