import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpModifierService'), method, data, additionalHeaders);
}

export default class CPModifierService {
  
    /**
     * Invokes CPModifierHandler.updateModifiers()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateModifiers(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('updateModifiers', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CPModifierHandler.getModifiers()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getModifiers(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('getModifiers', [quoteID, sessionUUID], authHeader);
    }
}