import React, {
} from 'react'
import { WniDropdownWithAction } from 'wni-common-base-components';

export const DetailsDropdownWithAction = (props) => {

    const {
        displayable,
        detailsIndex,
        onValidate,
        disabled,
        showErrors,
        isEditable,
        changeDetailsAndSync,
        onSearch
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        rangeValue,
        rangeValueOptions = [],
    } = displayable

    const onDynamicRangeValueChange = (newValue) => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            rangeValue: newValue
        }
        changeDetailsAndSync(newDisplayable, detailsIndex)
    }

    return <WniDropdownWithAction
        id={`displayable_dynamicrange_${propertyName}`}
        labelPosition='left'
        label={label}
        value={rangeValue}
        availableValues={rangeValueOptions.map((v) => {
            return {code: v,name: v}
        })}
        className='searchFieldWithButtonFilled'
        actionFn={() => onSearch(displayable)}
        actionVisible= {isEditable}
        actionConfig={{
            id: `${propertyName}Action`,
            text: "Search",
            type: "filled"
        }}
        onValueChange={onDynamicRangeValueChange}
        placeholder=""
        disabled={disabled}
        size="medium"
        required={required}
        showRequired
        onValidationChange={onValidate}
        showErrors={showErrors}
        readOnly={!isDisplayableEditable || !isEditable}
    />
}