import { defineMessages } from 'react-intl';

export default defineMessages({
    blocksIssuance: {
        id: 'quoteandbind.directives.MultiQuoteView.BlocksIssuance',
        defaultMessage: 'Needs UW Review',
    },
    DeleteJOB: {
        id: 'gateway.views.quote-uwissues.Delete',
        defaultMessage: 'Delete'
    },
    DeleteIfYouWish: {
        id: 'gateway.views.job-uwissues.Delete if you do not wish to continue',
        defaultMessage: 'Delete if you do not wish to continue'
    },
    stillDeleteRenewal: {
        id: 'gateway.views.job-uwissues.You can still delete the renewal. If you decide not to, the underwriter will continue with the review process',
        defaultMessage: 'You can still delete the renewal. If you decide not to, the underwriter will continue with the review process'
    },
    deletePolicyChange: {
        id: 'gateway.views.job-uwissues.Delete PolicyChange',
        defaultMessage: 'Delete policy change'
    },
    deleteRenewal: {
        id: 'gateway.views.job-uwissues.Delete Renewal',
        defaultMessage: 'Delete renewal'
    },
    deleteRenewalWizard: {
        id: 'gateway.views.renewal.renewal-summary.You may either delete or continue to the renewal wizard.',
        defaultMessage: 'You may either delete or continue to the renewal wizard.'
    },
    deleteSubmission: {
        id: 'gateway.views.job-uwissues.Delete Submission',
        defaultMessage: 'Delete quote'
    },
    deleteCancellation: {
        id: 'gateway.views.job-uwissues.Delete Cancellation',
        defaultMessage: 'Delete cancellation'
    },
    deleteOrContinuePolicyChange: {
        id: 'gateway.views.job-uwissues.You may either delete or continue to the PolicyChange wizard',
        defaultMessage: 'You may either delete or continue to the policy change wizard.'
    },
    deleteOrContinueRenewal: {
        id: 'gateway.views.job-uwissues.You may either delete or continue to the Renewal wizard',
        defaultMessage: 'You may either delete or continue to the renewal wizard.'
    },
    deleteOrContinueSubmission: {
        id: 'gateway.views.job-uwissues.You may either delete or continue to the Submission wizard',
        defaultMessage: 'You may either delete or continue to the quote wizard.'
    },
    deleteOrContinueCancellation: {
        id: 'gateway.views.job-uwissues.You may either delete or continue to the Cancellation wizard',
        defaultMessage: 'You may either delete or continue to the cancellation wizard.'
    },
    deleteThePolicyChange: {
        id: 'gateway.views.job-uwissues.You may delete the PolicyChange',
        defaultMessage: 'You may delete the policy change'
    },
    deleteTheRenewal: {
        id: 'gateway.views.job-uwissues.You may delete the Renewal',
        defaultMessage: 'You may delete the renewal'
    },
    deleteTheQuote: {
        id: 'gateway.views.job-uwissues.You may delete the Submission',
        defaultMessage: 'You may delete the quote'
    },
    deleteTheCancellation: {
        id: 'gateway.views.job-uwissues.You may delete the Cancellation',
        defaultMessage: 'You may delete the cancellation'
    },
    copySubmission: {
        id: 'gateway.views.job-uwissues.copy submission',
        defaultMessage: 'Copy Submission'
    },
    copyToHOQuote: {
        "id": "gateway.views.job-uwissues.Copy to Home Quote",
        "defaultMessage": "Copy to Home Quote"
    },
    copyToDPQuote: {
        "id": "gateway.views.job-uwissues.Copy to Dwelling Quote",
        "defaultMessage": "Copy to Dwelling Quote"
    },
    warningEditSubmission: {
        id: 'gateway.views.job-uwissues.submission.Continue Submission',
        defaultMessage: 'Continue Quote'
    },
    viewSubmission: {
        id: 'gateway.views.quote-uwissues.view Quote',
        defaultMessage: 'View Quote'
    },
    viewPolicyChange: {
        id: 'gateway.views.quote-uwissues.view Policy Change',
        defaultMessage: 'View Policy Change'
    },
    continueSubmission: {
        id: 'gateway.views.job-uwissues.submission.Continue Submission',
        defaultMessage: 'Continue Quote'
    },
    viewRenewal: {
        id: 'gateway.views.job-uwissues.submission.View Renewal',
        defaultMessage: 'View Renewal'
    },
    withdrawRenewal: {
        id: 'gateway.views.job-uwissues.submission.Delete Renewal',
        defaultMessage: 'Delete Renewal'
    },
    continueRenewal: {
        id: 'gateway.views.job-uwissues.submission.Continue Renewal',
        defaultMessage: 'Continue Renewal'
    },
    continuePolicyChange: {
        id: 'gateway.views.job-uwissues.submission.PolicyChange',
        defaultMessage: 'Continue Policy Change'
    },
    continueCancellation: {
        id: 'gateway.views.job-uwissues.submission.Continue Cancellation',
        defaultMessage: 'Continue Cancellation'
    },
    withdrawPolicyChange: {
        id: 'gateway.views.job-uwissues.submission.Delete PolicyChange',
        defaultMessage: 'Delete policy change'
    },
    withdrawSubmission: {
        id: 'gateway.views.job-uwissues.submission.Delete Submission',
        defaultMessage: 'Delete Quote'
    },
    withDrawJOB: {
        id: 'gateway.views.quote-uwissues.Delete',
        defaultMessage: 'Delete'
    },
    toCompletePointerForSubmission: {
        id: 'gateway.views.job-uwissues.You cannot complete this quote until these issues have been resolved. You can do the following:',
        defaultMessage: 'You cannot complete this quote until these issues have been resolved. You can do the following:'
    },
    toCompletePointerForPolicyChange: {
        id: 'gateway.views.job-uwissues.You cannot complete this policy change until these issues have been resolved. You can do the following:',
        defaultMessage: 'You cannot complete this policy change until these issues have been resolved. You can do the following:'
    },
    toCompletePointerForRenewal: {
        id: 'gateway.views.job-uwissues.You cannot complete this renewal until these issues have been resolved. You can do the following:',
        defaultMessage: 'You cannot complete this renewal until these issues have been resolved. You can do the following:'
    },
    toCompletePointerCancellation: {
        id: 'gateway.views.job-uwissues.You cannot complete this cancellation until these issues have been resolved. You can do the following:',
        defaultMessage: 'You cannot complete this cancellation until these issues have been resolved. You can do the following:'
    },
    jobLockedSubHeadingMessage: {
        id: 'gateway.views.job-uwissues.This quote is being edited by Underwriter',
        defaultMessage: 'This quote is being edited by Underwriter.'
    },
    wniOfferUnresolvedIssue: {
        id: 'gateway.views.job-uwissues.Some products have unresolved underwriting issues',
        defaultMessage: 'Some products have unresolved underwriting issues.'
    },
    wniEditOrReferPolicyChange: {
        id: 'gateway.views.job-uwissues.For products with unresolved issues you can either edit the PolicyChange or refer it to the Underwriter.',
        defaultMessage: 'For products with unresolved issues, you can either edit the policy change or refer it to the underwriter.'
    },
    wniEditOrReferRenewal: {
        id: 'gateway.views.job-uwissues.For products with unresolved issues you can either edit the Renewal or refer it to the Underwriter.',
        defaultMessage: 'For products with unresolved issues, you can either edit the renewal or refer it to the underwriter.'
    },
    wniEditOrReferSubmission: {
        id: 'gateway.views.job-uwissues.For products with unresolved issues you can either edit the Submission or refer it to the Underwriter.',
        defaultMessage: 'For products with unresolved issues, you can either edit the quote or refer it to the underwriter.'
    },
    wniEditOrReferCancellation: {
        id: 'gateway.views.job-uwissues.For products with unresolved issues you can either edit the Cancellation or refer it to the Underwriter.',
        defaultMessage: 'For products with unresolved issues, you can either edit the cancellation or refer it to the underwriter.'
    },
    reasonContinueSubmission: {
        id: 'gateway.views.job-uwissues.this quote if you do not wish to continue.',
        defaultMessage: 'this quote if you do not wish to continue.'
    },
    reasonContinuePolicyChange: {
        id: 'gateway.views.job-uwissues.this policy change if you do not wish to continue.',
        defaultMessage: 'this policy change if you do not wish to continue.'
    },
    reasonContinueRenewal: {
        id: 'gateway.views.job-uwissues.this renewal if you do not wish to continue.',
        defaultMessage: 'this renewal if you do not wish to continue.'
    },
    reasonContinueCancellation: {
        id: 'gateway.views.job-uwissues.this cancellation if you do not wish to continue.',
        defaultMessage: 'this cancellation if you do not wish to continue.'
    },
});
