import React from 'react';
import _ from 'lodash';
import { CheckboxField } from '@jutro/legacy/components';

function CheckboxElement(props) {
    const { label, onValueChange, className } = props;
    const componentprops = _.omit(props, ['className', 'label']);
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={className}>
            <CheckboxField onValueChange={onValueChange} {...componentprops} />
            <span>{label}</span>
        </label>
    );
};

export default CheckboxElement;