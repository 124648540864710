import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitle: {
        id: 'gateway.custom.views.landing.Account:',
        defaultMessage: 'Account: '
    },
    policyNavigation: {
        id: 'gateway.custom.navigation.landing.Policy:',
        defaultMessage: 'Policy: '
    },
    quote: {
        id: 'gateway.custom.views.quote-detail.Quote',
        defaultMessage: 'Quote'
    },
    policyChange: {
        id: 'gateway.custom.views.endorsement-detail.Policy Change',
        defaultMessage: 'Policy Change'
    },
    renewal: {
        id: 'portal.custom.components.transactionWizardTitle.transactionWizardTitle.Renewal',
        defaultMessage: 'Renewal'
    },
    convertedInfo: {
        id: 'gateway.custom.views.quote-detail.Converted Info',
        defaultMessage: 'This quote was created based on information from the insured’s current Personal Auto policy as of {effectiveDate}. Please review it carefully to ensure that any recent changes have been applied.'
    }
});
