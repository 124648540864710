import { defineMessages } from 'react-intl';

export default defineMessages({
    viewAndEditLabel: {
        id: 'quoteandbind.views.pu.underlying-table.View/Edit',
        defaultMessage: 'View/Edit',
    },
    viewLabel: {
        id: 'quoteandbind.views.pu.underlying-table.View',
        defaultMessage: 'View',
    },
    removeTitle: {
        id: 'quoteandbind.pu.directives.templates.pu-edit-vehicle.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeDescription: {
        id: 'quoteandbind.pu.directives.templates.pu-edit-vehicle.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this record?',
    },
    PATitle: {
        id: 'quoteandbind.views.pu.underlying.Personal Auto.Vehicle Details#1',
        defaultMessage: 'Vehicle Details #{index}',
    },
    WALTitle: {
        id: 'quoteandbind.views.pu.underlying.Watercraft.Watercraft Details#1',
        defaultMessage: 'Watercraft Details #{index}',
    },
    viewOrEditAnimalDetail: {
        id: 'quoteandbind.views.pu.underlying.viewOrEditAnimalDetail',
        defaultMessage: 'View/Edit Details',
    },
    viewAnimalDetail: {
        id: 'quoteandbind.views.pu.underlying.viewAnimalDetail',
        defaultMessage: 'View Details',
    },
    autoDetails: {
        id: 'quoteandbind.views.pu.underlying.Personal Auto.Vehicle Details#{indexNumber}',
        defaultMessage: 'Vehicle Details #{indexNumber}'
    },
    watercraftDetails: {
        id: 'quoteandbind.views.pu.underlying.Watercraft.Watercraft Details#{indexNumber}',
        defaultMessage: 'Watercraft Details #{indexNumber}'
    },
    recreationalDetails: {
        id: 'quoteandbind.views.pu.underlying.Recreational.Recreational Vehicle Details#{indexNumber}',
        defaultMessage: 'Recreational Vehicle Details #{indexNumber}'
    },
    motorcycleDetails: {
        id: 'quoteandbind.views.pu.underlying.Motorcycle.Motorcycle Details#{indexNumber}',
        defaultMessage: 'Motorcycle Details #{indexNumber}'
    },
    addAuto: {
        id: 'quoteandbind.views.pu.underlying.Personal Auto.add Vehicle',
        defaultMessage: 'Add Vehicle'
    },
    addWatercraft: {
        id: 'quoteandbind.views.pu.underlying.Watercraft.add Watercraft',
        defaultMessage: 'Add Watercraft'
    },
    addRecreational: {
        id: 'quoteandbind.views.pu.underlying.Personal Auto.add Recreational Vehicle',
        defaultMessage: 'Add Recreational Vehicle'
    },
    addMotorcycle: {
        id: 'quoteandbind.views.pu.underlying.Motorcycle.add Motorcycle',
        defaultMessage: 'Add Motorcycle'
    },
    vehicleTypeHelpLink: {
        id: 'wni.quoteandbind.pu.vehicle.Click here to determine vehicle category',
        defaultMessage: 'Click {here} to determine vehicle category'
    },
    vehicleDeleteTips: {
        id: 'wni.quoteandbind.pu.vehicle.Please add the exposure information to the underlying policy',
        defaultMessage: 'Please add the exposure information to the underlying policy'
    },
    new: {
        id: 'wni.quoteandbind.pu.vehicle.New',
        defaultMessage: 'New'
    },
});
