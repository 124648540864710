import React from 'react';
import PropTypes from 'prop-types';
import {
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { FNOLBusinessLocationPage } from 'gwce-capability-fnol-common-react-ext';
import { ProductUtil } from 'wnice-portals-util-react';
import { withRouter } from 'react-router-dom';

function FNOLGLBusnessLocationPage(props) {
    return <FNOLBusinessLocationPage 
                {...props}
                LOB_NAME={ProductUtil.GL_LOB_NAME}
            />;
}

FNOLGLBusnessLocationPage.propTypes = wizardProps;
FNOLGLBusnessLocationPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLGLBusnessLocationPage));
