import React from 'react';


import CRRiskAnalysisPage from './CRRiskAnalysisPage';


function CRRiskAnalysisReadOnlyPage(props) {

    return <CRRiskAnalysisPage {...props} isReadOnly />
}


export default CRRiskAnalysisReadOnlyPage;