import { defineMessages } from 'react-intl';

export default defineMessages({
    accountDocuments: {
        id: 'wni.gateway.gateway-documents.Account Documents',
        defaultMessage: 'Account Documents'
    },
    billingDocuments: {
        id: 'wni.gateway.gateway-documents.Billing Documents',
        defaultMessage: 'Billing Documents'
    },
    claimsDocuments: {
        id: 'wni.gateway.gateway-documents.Claims Documents',
        defaultMessage: 'Claims Documents'
    },
    printSelectedAccountDocuments: {
        id: 'wni.gateway.gateway-documents.Print Selected Account Documents',
        defaultMessage: 'Print Selected Account Documents'
    },
    printSelectedBillingDocuments: {
        id: 'wni.gateway.gateway-documents.Print Selected Billing Documents',
        defaultMessage: 'Print Selected Billing Documents'
    },
    printSelectedQuoteDocuments: {
        id: 'wni.gateway.gateway-documents.Print Selected Quote Documents',
        defaultMessage: 'Print Selected Quote Documents'
    }
});
