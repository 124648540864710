import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cppPolicyDetailsService'), method, data, additionalHeaders);
}

export default class CPPPolicyDetailsService {

    static savePolicyDetailsData(quoteID, sessionUUID, cppPolicyDetailsData, quoteBaseData, authHeader) {
        return processSubmission('saveCPPPolicyDetailsData', [quoteID, sessionUUID, cppPolicyDetailsData, quoteBaseData], authHeader);
    }

}