import { defineMessages } from 'react-intl';

export default defineMessages({
    paAddAdditionalInterestContact: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Contact',
        defaultMessage: 'Contact',
    },
    paAddAdditionalInterestBankName: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Financial Institution',
        defaultMessage: 'Financial Institution',
    },
    paAddAdditionalInterestTrustName: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Trust Name',
        defaultMessage: 'Trust Name',
    },
    paAddAdditionalInterestCompanyType: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Company Type',
        defaultMessage: 'Company Type',
    },
    paAddAdditionalInterestLoanNumber: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Loan Number',
        defaultMessage: 'Loan Number',
    },
    paAddAdditionalInterestRelationshipToInsured: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Relationship to Insured',
        defaultMessage: 'Relationship to Insured',
    },
    paAddAdditionalInterestExplain: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Explain',
        defaultMessage: 'Explain',
    },
    additionalInterestCount: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.additionalInterestCount',
        defaultMessage: 'Additional Interest: { count }'
    },
    removeAdditionalInterest: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Remove Additional Interest.ext',
        defaultMessage: 'Remove Additional Interest?',
    },
    removeAdditionalInterestDesc: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.AddIntRemovalMsg.ext',
        defaultMessage: 'Are you sure you want to remove this additional interest from the list?',
    },
    addAdditionalInterest: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.addAdditionalInterest',
        defaultMessage: 'Add Additional Interest',
    },
    addAdditionalInterestType: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Type',
        defaultMessage: 'Type'
    },
    paAddAdditionalInterestTrust: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.Trust',
        defaultMessage: 'Trust'
    },
    editAdditionalInterest: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.editAdditionalInterest',
        defaultMessage: 'Edit Additional Interest',
    },
    insuredType: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.insuredType',
        defaultMessage: "Provides liability coverage for a full-time student, who was a resident of the named insured's household before moving out to attend school.",
    }
});
