import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cuPolicyDetailsChange'), method, data, additionalHeaders);
}

export default class CUPolicyDetailsChangeService {

    static saveCUDetailsChangeData(quoteID, sessionUUID, quoteBaseData, authHeader) {
        return processSubmission('saveCUDetailsChangeData', [quoteID, sessionUUID, quoteBaseData], authHeader);
    }

}