import { defineMessages } from 'react-intl';

export default defineMessages({
    notes: {
        id: 'gateway.views.account-detail.Notes',
        defaultMessage: 'Notes',
    },
    notesInfoId: {
        id: 'gateway.directives.gateway-notes.Note',
        defaultMessage: 'Note',
    },
    notesTopic: {
        id: 'gateway.directives.gateway-notes.Topic',
        defaultMessage: 'Topic',
    },
    notesSubject: {
        id: 'gateway.directives.gateway-notes.Subject',
        defaultMessage: 'Subject',
    },
    editTopicInput: {
        id: 'gateway.directives.activity-summary.Topic',
        defaultMessage: 'Topic',
    },
    editSubjectInput: {
        id: 'gateway.views.edit-note-modal.Subject',
        defaultMessage: 'Subject',
    },
    editNotesInput: {
        id: 'gateway.views.edit-note-modal.Note',
        defaultMessage: 'Note',
    },
    editNoteTopic: {
        id: 'gateway.views.edit-note-modal.note topic',
        defaultMessage: '--note topic--',
    },
    notesSend: {
        id: 'gateway.views.edit-note-modal.Send Note',
        defaultMessage: 'Add',
    },
    notesCancel: {
        id: 'gateway.views.edit-note-modal.Cancel',
        defaultMessage: 'Cancel',
    },
    searchNotes: {
        id: 'gateway.directives.gateway-notes.Search notes',
        defaultMessage: 'Search notes'
    },
    moreNotes: {
        id: 'gateway.directives.activity-view-summary.x more note(s)',
        defaultMessage: '{value} more note(s)'
    },
    hideNotes: {
        id: 'gateway.directives.activity-view-summary.Hide notes',
        defaultMessage: 'Hide notes'
    }
});
