import React, {useState, useCallback, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMInstallationFloaterService as  InstallationFloaterService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMInstallationFloaterPage.metadata.json5';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import messages from './IMInstallationFloaterPage.messages';

const InstallationFloaterReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM,
    } = props;

    const { initialValidation, onValidate, } = useValidation('InstallationFloaterReadOnlyPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [isEditing, setIsEditing] = useState(false);
    const [installationFloaterClauses, setInstallationFloaterClausesClauses] = useState();
    const [installationFloaterLoadingClauses, setInstallationFloaterLoadingClauses] = useState();

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');
    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await InstallationFloaterService.getInstallationFloaterClausesWithSync(jobID, sessionUUID, authHeader) 
            : await InstallationFloaterService.getInstallationFloaterClauses(jobID, sessionUUID, authHeader)
        setInstallationFloaterClausesClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onPageNext = () => {
        return submissionVM
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: installationFloaterClauses,
            setCoveragePartClauses: setInstallationFloaterClausesClauses,
            isEditing: false,
            isEditable: false,
            onValidate: onValidate,
            loadingClauses: installationFloaterLoadingClauses,
            setLoadingClauses: setInstallationFloaterLoadingClauses,
            wizardData: submissionVM,
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: false,
            },
            // accor
            installationFloater: {
                visible: _.get(installationFloaterClauses, 'installationCoverage.visible'),
            },
            installationFloaterMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(installationFloaterClauses, 'installationCoverage.mainCoverage'),
                coverageFormName: 'installationFloaterMain',
                coverageFormPath: 'installationCoverage.mainCoverage'
            },
            installationFloaterSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(installationFloaterClauses, 'installationCoverage.supplementalCoverage'),
                coverageFormName: 'installationFloaterSupplemental',
                coverageFormPath: 'installationCoverage.supplementalCoverage'
            },
            installationFloaterScheduleCoverageTypeTermCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(installationFloaterClauses, 'installationCoverage.installationFloaterScheduleCoverageTypeTerm'),
                coverageFormName: 'installationFloaterScheduleCoverageTypeTerm',
                coverageFormPath: 'installationCoverage.installationFloaterScheduleCoverageTypeTerm'
            },
            installationFloaterScheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(installationFloaterClauses, 'installationCoverage.instFloaterSchedule'),
                updateScheduleService: InstallationFloaterService.updateInstallationFloaterSchedule,
                schedulePath: 'installationCoverage.instFloaterSchedule',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails)
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'InstallationFloater'
            },
            // exclusionAccordionCard: {
            //     ...accordionCommonProps,
            //     coveragePartName: 'InstallationFloater'
            // }
        }
    }, [accordionCommonProps, installationFloaterClauses]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            InstallationFloaterMainCovCard: IMCommonMainCoverage,
            InstallationFloaterSupplementalCovCard: IMCommonMainCoverage,
            AdditionalAccordionCard: IMCommonAdditionalCovCard,
            ExclusionAccordionCard: IMExclusionAccordionCard,
            InstallationFloaterScheduleCovCard: IMCommonCovPartSchedule,
            InstallationFloaterScheduleCoverageTypeTermCovCard: IMCommonMainCoverage
        },
    };

    const disableNavigator = isEditing || isLoadingMask || installationFloaterLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={onPageNext}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {installationFloaterClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default InstallationFloaterReadOnlyPage
