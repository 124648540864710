
import React, {
} from 'react';
import _ from 'lodash';
import HOTermComponent from './HOTermComponent';
import HOScheduleItemsComponent from '../HOScheduleItemsComponent/HOScheduleItemsComponent';
import HOClauseExtension from '../HOClauseExtension/HOClauseExtension';

function HOClauseElementComponent(props) {

    const {
        idPrefix,
        clause,
        hideCovBstructureRelatedTerms,
        hideCovBstructureLimitItem,
        clausePath,
        isEditable,
        isDisabled,
        isInDetailsPopup,
        labelPosition,
        labelTop,
        // This function change submission without sync
        onChangeClause,
        onSyncCoverages,
        onChangeSubmissionAndSync,
        onScheduleChangeWithSync,
        onScheduleChangeWithoutSync,
        setIsEditing,
        showScheduleDetailsInPanelBelow,
        showPrivateStructureTable,
        openedRowItemNumber,
        setOpenedRowItemNumber,
        selectedRowItemIndex,
        setSelectedRowItemIndex,
        hoprivateStructures,
        showErrors,
        coveragesConfig,
        // create loss payee
        additionalInterests = [],
        onSaveAdditionalInterestFn,
        onGettingAdditionalInterestTypeOptions,
        accountContacts,
        isCreateAdditionalInterestPanelVisible,
        setIsCreateAdditionalInterestPanelVisible,
        // end create loss payee
    } = props

    const {
        scheduleConfig
    } = coveragesConfig;



    const {
        code_Ext: code,
        terms,
        publicID,
        schedule,
    } = clause

    const displayedTerms = terms.filter((term) => {
        if (!['HOPCovB', 'DPCoverageBSchedCov_Ext'].includes(code)) {
            return true
        }
        const termCode = _.get(term, 'code_Ext');
        if (hideCovBstructureRelatedTerms) {
            if ([
                'PortalCustomCovTerm_CovBIsAddedLabel',
                'PortalCustomCovTerm_IsAddItNow'
            ].includes(termCode)) {
                return false
            }
        }
        if (hideCovBstructureLimitItem) {
            if ([
                'HOPCovBDirectLimit',
                'DPCoverageBSchedCovLimit_Ext',
            ].includes(termCode)) {
                return false
            }
        }
        return true
    })
    const hasSchedule = _.has(clause, 'schedule')
    const schedulePath = `${clausePath}.schedule`;
    const isScheduleReadOnly = !isEditable || _.get(schedule, 'readonly_Ext');
    const visibleColumnIdsOverride = _.get(scheduleConfig, `visibleColumnIdsOverride.${code}`);
    const visibleFieldIdsOverride = _.get(scheduleConfig, `visibleFieldIdsOverride.${code}`);

    const showHOClauseExtension = showPrivateStructureTable
    const basePath = clausePath.replaceAll('.selected', '');

    return (
        <div
            id= {`${idPrefix}ClausetElementContainer_[${publicID}]`}
            className= 'clause_element_container'
        >
            {displayedTerms.map((term) => {
                const termIndex = terms
                    .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                return <HOTermComponent
                    key={_.get(term, 'code_Ext')}
                    idPrefix={idPrefix}
                    clausePublicID={publicID}
                    coverageCode={code}
                    term={term}
                    termIndex = {termIndex}
                    clausePath = {clausePath}
                    isEditable={isEditable}
                    isDisabled={isDisabled}
                    labelPosition={labelPosition}
                    labelTop={labelTop}
                    onChangeClause={onChangeClause}
                    onSyncCoverages={onSyncCoverages}
                    onChangeSubmissionAndSync={onChangeSubmissionAndSync}
                    setIsEditing={setIsEditing}
                    showErrors={showErrors}
                    coveragesConfig={coveragesConfig}
                />
            })}
            {(hasSchedule && code !== 'DPPremLiabNonOwnerOccDwelSchedCov_Ext')
            || (hasSchedule && code === 'DPPremLiabNonOwnerOccDwelSchedCov_Ext' && isInDetailsPopup) ? <HOScheduleItemsComponent
                coverageCode={code}
                path= {schedulePath}
                onScheduleChangeWithSync= {onScheduleChangeWithSync}
                onScheduleChangeWithoutSync={onScheduleChangeWithoutSync}
                // This function change submission without sync
                onChangeClause= {onChangeClause}
                value={schedule}
                labelPosition = {labelPosition}
                showTitle= {false}
                readOnly= {isScheduleReadOnly}
                visibleColumnIdsOverride={visibleColumnIdsOverride}
                visibleFieldIdsOverride={visibleFieldIdsOverride}
                showScheduleDetailsInPanelBelow={showScheduleDetailsInPanelBelow}
                openedRowItemNumber={openedRowItemNumber}
                setOpenedRowItemNumber={setOpenedRowItemNumber}
                selectedRowItemIndex={selectedRowItemIndex}
                setSelectedRowItemIndex={setSelectedRowItemIndex}
                setIsEditing={setIsEditing}
                showErrors={showErrors}
                coveragesConfig={coveragesConfig}
                // create loss payee
                additionalInterests={additionalInterests}
                onSaveAdditionalInterestFn={onSaveAdditionalInterestFn}
                onGettingAdditionalInterestTypeOptions={onGettingAdditionalInterestTypeOptions}
                accountContacts={accountContacts}
                isCreateAdditionalInterestPanelVisible={isCreateAdditionalInterestPanelVisible}
                setIsCreateAdditionalInterestPanelVisible={setIsCreateAdditionalInterestPanelVisible}
            /> : null}
            {showHOClauseExtension  ? <HOClauseExtension
                hoprivateStructures= {hoprivateStructures}
                // This function change submission without sync
                onValueChangeWithoutSync= {onChangeClause}
                onValueChangeWithSync={onChangeSubmissionAndSync}
                isEditable={isEditable}
                path= {basePath}
                clausePatternCode={code}
                showScheduleDetailsInPanelBelow={showScheduleDetailsInPanelBelow}
                openedRowItemNumber={openedRowItemNumber}
                setOpenedRowItemNumber={setOpenedRowItemNumber}
                selectedRowItemIndex={selectedRowItemIndex}
                setSelectedRowItemIndex={setSelectedRowItemIndex}
                showErrors={showErrors}
                setIsEditing={setIsEditing}
                coveragesConfig={coveragesConfig}
                additionalInterests={additionalInterests}
            /> : null}
        </div>
        
    )
}

export default HOClauseElementComponent;