import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _, { remove, update } from 'lodash';
import { ModalNext, ModalBody, ModalHeader, Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import metadata from './AddressBookPopup.metadata.json5';

import { Button } from '@jutro/legacy/components';

function AddressBookPopup(props) {
    const {
        searchCriteriaVM,
        size,
        isOpen,
        onResolve,
        onReject,
        searchContact,
        onValueChange,
        viewModelService,
        selectContact
    } = props;

    const translator = useTranslator();

    const [showErrors, updateShowErrors] = useState(false);
    const [searchCriteriaVMState, updateSearchCriteriaVM] = useState(searchCriteriaVM);
    const [showNotification, updateShowNotification] = useState(false);
    const [disabledSearch, updateDisabledSearch] = useState(false);
    const [searchResult, updateSearchResult] = useState([]);
    const [errorMessages, updateErrorMessages] = useState();

    const searchCriteriaType = _.get(searchCriteriaVMState, 'value.type')

    const {
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('addressBookPopup');

    const IsValidFields = useCallback(() => {
        if (!searchCriteriaVMState.aspects.valid || !searchCriteriaVMState.aspects.subtreeValid) {
            return false;
        }
        return true;
    }, [searchCriteriaVMState]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, searchCriteriaVMState]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const getAvailableTypeOptions = () => {
        const availableValues = _.get(searchCriteriaVMState, 'type.aspects.availableValues');
        const options = availableValues.map((option) => {
            return {
                code: option.code,
                name: translator({
                    id: option.name
                })
            };
        });
        const res = _.filter(options, (opt) => {
            return opt.code === 'Person' || opt.code === 'Company'
        })
        return res;
    }

    const searchContactFn = async () => {
        updateDisabledSearch(true);
        const res = await searchContact(searchCriteriaVMState.value);
        updateSearchResult(res.contactSearchResults || []);
        if (res.errorMessages) {
            updateErrorMessages(res.errorMessages);
            updateShowNotification(true);
        } else {
            updateShowNotification(false);
        }
        updateDisabledSearch(false);
    }

    const selectContactFn = (item) => {
        selectContact(item.publicID, item.displayName);
        onReject();
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                showOptional: false,
                labelPosition: 'left',
                showRequired: true,
                showErrors: showErrors
            },
            search: {
                onClick: isComponentValid ? searchContactFn : handleValidation,
                disabled: disabledSearch
            },
            type: {
                availableValues: getAvailableTypeOptions(),
                defaultValue: 'Person'
            },
            notification: {
                message: errorMessages,
                visible: showNotification
            },
            addressBookTable: {
                data: searchResult
            },
            companyName: {
                visible: searchCriteriaType === 'Company'
            },
            firstName: {
                visible: searchCriteriaType === 'Person'
            },
            lastName: {
                visible: searchCriteriaType === 'Person'
            }
        }
    }, [searchContact, searchCriteriaVMState, showErrors, showNotification, errorMessages, isComponentValid, searchResult, disabledSearch]);

    const overrideProps = generateOverrides();

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            searchCriteriaVMState,
            id,
            path,
            overrideProps
        );
    };

    const writeValue = useCallback((value, path) => {
        const newSearchCriteriaVM = viewModelService.clone(searchCriteriaVMState);
        _.set(newSearchCriteriaVM, path, value);
        updateSearchCriteriaVM(newSearchCriteriaVM);
    }, [searchCriteriaVMState, viewModelService])

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
            selectContactFn
        },
        resolveComponentMap: {
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title='Search Address Book' onClose={onReject}/>
            <ModalBody id="addressBookPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={searchCriteriaVMState}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                    onValueChange={writeValue}
                />
            </ModalBody>
        </ModalNext>
    );
}

AddressBookPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
};
AddressBookPopup.defaultProps = {
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
};

export default AddressBookPopup;

