
import React, {
    useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
// import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { WniPAPolicyChangeService } from 'wni-capability-policychange';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ReactUtil } from 'wni-portals-util-react';
import { CoveragesUtil, WniClausesUtil } from 'wni-portals-util-js';
import metadata from './LineCoveragesInputComponent.metadata.json5';
import styles from './LineCoveragesInputComponent.module.scss';


const LineCoveragesInputComponent = (props) => {
    const {
        submissionVM,
        updateSubmissionVM,
        onValidate,
        disregardFieldValidation,
        showErrors,
        LoadSaveService,
        // authHeader,
        viewModelService,
        isReadOnly,
        updateCoveragesErrorsAndWarnings,
        selectedVersionOfferingsIndex,
        // getDriversCoverages,
        setLineCoverageLoading,
        lobName,
        //
        lineCoverageFilter,
    } = props;

    const jobType = _.get(submissionVM.value, 'baseData.jobType');

    const lineCoveragePath = `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages.lineCoverages`;

    const { authHeader } = useAuthentication();
    const [loadingClause, updateLoadingClause] = useState();
    const [callingService, updateCallingService] = ReactUtil.useStateCallback(false);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateSubmissionVM(
                WniClausesUtil.setClauseValue(submissionVM, value, changedPath)
            );
        },
        [submissionVM, updateSubmissionVM]
    );


    const onClauseUpdate = useCallback(
        async (updatedSubmissionVM = submissionVM) => {
            // As the clause is set to edited
            // ClausesUtil.filterUnchangedClauses will pick it up in structureClausesForServer
            const clausesToUpdate = CoveragesUtil.generateUpdatedCoveragesDTO({
                submissionVM: updatedSubmissionVM,
                lobName: lobName,
                selectedVersionOfferingsIndex
            });

            const hasClauseMustUpdateImmediately = CoveragesUtil
                .hasCoverageMustUpdateImmediately(clausesToUpdate);

            const newSubmissionVM = viewModelService.clone(submissionVM);
            if (hasClauseMustUpdateImmediately) {
                setLineCoverageLoading(true);

                let updateCoveragesDTOToServerMethod = () => {};
                switch (jobType) {
                    case 'Submission': {
                        updateCoveragesDTOToServerMethod = LoadSaveService.updateCoverages;
                        break;
                    }
                    case 'PolicyChange': {
                        updateCoveragesDTOToServerMethod = WniPAPolicyChangeService.updateCoverages;
                        break;
                    }
                    default: {
                        // eslint-disable-next-line no-console
                        console.log(`Unhandled job type: ${jobType}`);
                    }
                }

                const { updatedCoverages, errorsAndWarning } = await CoveragesUtil
                    .updateCoveragesToServer({
                        submissionVM,
                        updateCoveragesDTOToServerMethod,
                        clausesToUpdate,
                        authHeader,
                        lobName,
                        selectedVersionOfferingsIndex,
                    });

                updatedCoverages.forEach((updateCoverage) => {
                    _.set(newSubmissionVM, updateCoverage.path, updateCoverage.updatedClauses);
                });

                if (updateCoveragesErrorsAndWarnings) {
                    updateCoveragesErrorsAndWarnings(errorsAndWarning);
                }
            }
            CoveragesUtil.setAdditionalFieldsAfterCoverageUpdate(newSubmissionVM);

            const fieldsToRemoveFromValidation = CoveragesUtil
                .getRemovedClausesIDByUpdatedCoverages(
                    submissionVM,
                    newSubmissionVM,
                    `lobData.${lobName}.offerings.children[${selectedVersionOfferingsIndex}].coverages`
                );
            if (!_.isEmpty(fieldsToRemoveFromValidation)) {
                disregardFieldValidation(fieldsToRemoveFromValidation);
            }
            updateLoadingClause(undefined);
            updateSubmissionVM(newSubmissionVM);
            // if (hasClauseMustUpdateImmediately) {
            //     getDriversCoverages();
            // }
            setLineCoverageLoading(false);
            return newSubmissionVM;
        },
        [
            jobType,
            LoadSaveService,
            authHeader,
            submissionVM,
            disregardFieldValidation,
            updateSubmissionVM,
            viewModelService,
            updateCoveragesErrorsAndWarnings,
            setLineCoverageLoading,
            selectedVersionOfferingsIndex,
            lobName,
        ]
    );

    const syncCoverages = useCallback(async (value, changedPath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        const baseObject = _.get(submissionVM, basePath);

        if (!callingService) {
            updateCallingService(true, async () => {
                updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
                const newSubmissionData = await onClauseUpdate();
                updateSubmissionVM(newSubmissionData);
                updateCallingService(false);
            });
        }
    }, [callingService, onClauseUpdate, submissionVM, updateCallingService, updateSubmissionVM]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncCoverages(value, changedPath);
        },
        [changeSubmission, syncCoverages]
    );

    const onScheduleChange = useCallback((value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);

        _.set(newSubmissionVM, `${path}.value`, value);

        return onClauseUpdate(newSubmissionVM);
    }, [submissionVM, onClauseUpdate, viewModelService]);


    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        clausesInput: {
            loadingClause: loadingClause,
            isEditable: !isReadOnly,
            showErrors: showErrors,
            // onChangeClause: changeSubmission,
            // onSyncCoverages: syncCoverages,
            // onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate,
            path: lineCoveragePath,
            clauseFilter: lineCoverageFilter,
        }
    };

    const readValue = (id, path) => {
        let retval = readViewModelValue(
            metadata.componentContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
        return retval;
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            // onValidate: onValidate,
            onScheduleChange: onScheduleChange,
        },
        resolveComponentMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            onModelChange={updateSubmissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            showErrors={showErrors}
            // onValueChange={writeValue}
        />
    );
};

LineCoveragesInputComponent.propTypes = {
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    updateSubmissionVM: PropTypes.func,
    onValidate: PropTypes.func,
    disregardFieldValidation: PropTypes.func,
    showErrors: PropTypes.bool,
    // authHeader: PropTypes.shape({}).isRequired,
    LoadSaveService: PropTypes.shape({
        updateCoverages: PropTypes.func
    }).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    isReadOnly: PropTypes.bool,
    updateCoveragesErrorsAndWarnings: PropTypes.func,
    selectedVersionOfferingsIndex: PropTypes.number,
    // getDriversCoverages: PropTypes.func,
    setLineCoverageLoading: PropTypes.func,
    lobName: PropTypes.string.isRequired,
    //
    /**
     * (Optional) (clauseDataDTO) => boolean
     * Filter function to decide which clauses should be handled
     * by this component.
     */
    lineCoverageFilter: PropTypes.func,
};

LineCoveragesInputComponent.defaultProps = {
    submissionVM: {},
    updateSubmissionVM: undefined,
    onValidate: undefined,
    disregardFieldValidation: undefined,
    isReadOnly: false,
    showErrors: false,
    updateCoveragesErrorsAndWarnings: undefined,
    selectedVersionOfferingsIndex: 0,
    // getDriversCoverages: undefined,
    setLineCoverageLoading: _.noop,
    lineCoverageFilter: undefined,
};

export default LineCoveragesInputComponent;
