import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import metadata from './UnmannedAircraftCoverages.metadata.json5';

function UnmannedAircraftCoverages(props) {
    const { currentRow, writeValue, onValidate, showErrors, readOnly } = props;

    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const overrideProps = {};

    const resolvers = {
        callbackMap: {},
        componentMap: {},
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default UnmannedAircraftCoverages;
