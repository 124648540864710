import _ from 'lodash';

// from UWIssueBlockingPoint.tti
// const UW_BLOCKS_BIND = 'BlocksBind';
// const UW_BLOCKS_ISSUE = 'BlocksIssuance';
const UW_BLOCKS_PREFIX = 'Blocks';
const UW_REJECTED = 'Rejected';
const UW_NON_BLOCKING = 'NonBlocking';

/**
 * Check the properties of UWIssues, e.g.
 * currentBlockingPoint, hasApprovalOrRejection;
 *
 * @param {object} uwIssue
 * @param {function} uwIssuePropsCheckFn
 * @returns {boolean}
 */
function checkUWIssueProps(uwIssue = {}, uwIssuePropsCheckFn) {
    const {
        currentBlockingPoint = '',
        hasApprovalOrRejection = false,
        onOfferings,
    } = uwIssue;
    let blockingPoint = currentBlockingPoint;
    let approvalStatus = hasApprovalOrRejection;
    if (!_.isEmpty(onOfferings)) {
        blockingPoint = _.get(onOfferings[0], 'currentBlockingPoint');
        approvalStatus = _.get(onOfferings[0], 'hasApprovalOrRejection');
    }
    const retval = uwIssuePropsCheckFn({
        blockingPoint,
        approvalStatus,
    });
    return retval;
}


/**
 * Checks whether current UWIssue is a blocking or rejected issue
 * @param {object} uwIssue an instance of UWIssueDTO
 * @returns {boolean}
 */
function isBlockingOrRejectedUWIssue(uwIssue) {
    const retval = checkUWIssueProps(uwIssue, ({ blockingPoint }) => {
        return blockingPoint === UW_REJECTED || blockingPoint.startsWith(UW_BLOCKS_PREFIX);
    });
    return retval;
}

/**
 * Checks whether current UWIssue is a Rejected issue
 * @param {object} uwIssue an instance of UWIssueDTO
 * @returns {boolean}
 */
function isRejectedUWIssue(uwIssue = {}) {
    const retval = checkUWIssueProps(uwIssue, ({ blockingPoint }) => blockingPoint === UW_REJECTED);
    return retval;
}

/**
 * Checks whether current UWIssue is a blocking issue
 * @param {object} uwIssue an instance of UWIssueDTO
 * @returns {boolean}
 */
function isBlockingUWIssue(uwIssue = {}) {
    const retval = checkUWIssueProps(uwIssue, ({ blockingPoint }) => blockingPoint.startsWith(UW_BLOCKS_PREFIX));
    return retval;
}

/**
 * Checks whether we have blocking UW issues or not
 * @param {object} errorsAndWarnings, an instance ofJobValidationUnderwritingIssuesDTO
 * @returns {boolean}
 */
function hasBlockingUWIssue(errorsAndWarnings = {}) {
    const { underwritingIssues: uwIssues = [] } = errorsAndWarnings;
    const blockingIssue = uwIssues.find(isBlockingUWIssue);
    return !_.isEmpty(blockingIssue);
}

/**
 * Checks whether we have blocking or rejected UW issues or not
 * @param {object} errorsAndWarnings, an instance ofJobValidationUnderwritingIssuesDTO
 * @returns {boolean}
 */
function hasBlockingOrRejectedUWIssue(errorsAndWarnings = {}) {
    const { underwritingIssues: uwIssues = [] } = errorsAndWarnings;
    const brIssue = uwIssues.find(isBlockingOrRejectedUWIssue);
    return !_.isEmpty(brIssue);
}

/**
 * Filter UW issues based on periodPublicID
 * @param {array} uwIssues an array of UWIssueDTO
 * @param {string} periodPublicID
 * @returns {array} UWIssueDTO[]
 */
function filterUWIssuesBasedOnPeriod(uwIssues, periodPublicID) {
    if (_.isEmpty(uwIssues) || _.isEmpty(periodPublicID)) {
        return [];
    }
    const uwIssuesWithOffering = uwIssues.map((uwIssue) => {
        const {
            offerings,
            onOfferings = [],
            ...actualIssueInfo
        } = uwIssue;
        const filteredOnOfferings = onOfferings.filter(
            (onOffering) => periodPublicID === onOffering.periodPublicID_Ext
        );
        return {
            onOfferings: filteredOnOfferings,
            ...actualIssueInfo
        };
    }).filter((uwIssue) => !_.isEmpty(uwIssue.onOfferings));

    // or _.isNil()
    const uwIssuesWithoutOffering = uwIssues.filter((uwIssue) => _.isEmpty(uwIssue.onOfferings));

    const retval = [
        ...uwIssuesWithOffering,
        ...uwIssuesWithoutOffering,
    ];
    return retval;
}


/**
 * Filter UW issues based on periodPublicID
 * @param {array} uwIssues an array of UWIssueDTO
 * @param {string} periodPublicID
 * @returns {array} UWIssueDTO[]
 */
function filterBlockingUWIssuesBasedOnPeriod(uwIssues, periodPublicID) {
    const currentIssues = filterUWIssuesBasedOnPeriod(uwIssues, periodPublicID);
    if(_.isEmpty(currentIssues)) {
        return currentIssues;
    }
    const retval = currentIssues.filter(isBlockingOrRejectedUWIssue);
    return retval;
}


/**
 * Checks whether the UWIssue has been approved
 * @param {object} uwIssue an instance of UWIssueDTO
 * @returns {boolean}
 */
function isUWIssueApproved(uwIssue) {
    const retval = checkUWIssueProps(uwIssue, ({ blockingPoint, approvalStatus }) => {
        return approvalStatus && blockingPoint === UW_NON_BLOCKING;
    });
    return retval;
}

/**
 * Checks whether current issue needs approval
 * @param {object} uwIssue an instance of UWIssueDTO
 * @returns {boolean}
 */
function isUwIssuePendingApproval(uwIssue) {
    return !isUWIssueApproved(uwIssue);
}


/**
 * Format the uwIssueTypeTitle based on length of the issues list.
 * @param {string} uwIssueTypeTitle
 * @param {string} issuesLength
 * @returns {string} formatted uwIssueTypeTitle
 */
function formatUWIssueTypeTitle(uwIssueTypeTitle, issuesLength = 0) {
    const titlePrefix = `${issuesLength} Item`;
    const pluralSuffix = issuesLength > 1 ? 's' : '';
    return `${titlePrefix}${pluralSuffix} ${uwIssueTypeTitle}`;
}

/**
 * Return the UWIssue type title
 * @param {object} typeTitleMap
 * @param {string} type
 * @param {array} issues
 * @returns {string}
 */
function getUWIssueTypeTitle(typeTitleMap, type, issues = []) {
    const uwIssueTypeTitle = _.get(typeTitleMap, type, '');
    const issuesLength = issues.length || 0;
    const retval = formatUWIssueTypeTitle(uwIssueTypeTitle, issuesLength);
    return retval;
}

/**
 * Combines isBlockingOrRejectedUWIssue() and isUwIssuePendingApproval()
 * @param {object} uwIssue
 * @returns {boolean}
 */
function isUWIssueNonBlockingWhilePendingApproval(uwIssue) {
    return !isBlockingOrRejectedUWIssue(uwIssue)
        && isUwIssuePendingApproval(uwIssue);
}

/**
 * Return an array of UWIssue.longDescription for Pending Info Issues
 * @param {object} sideBySideData
 * @param {string} selectedVersion
 * @returns {array}
 */
function getPendingInfoIssuesFromSxsData(sideBySideData, selectedVersion) {
    const sxsErrorsAndWarnings = _.get(sideBySideData, 'errorsAndWarnings', {});
    const sxsPeriodUWIssues = filterUWIssuesBasedOnPeriod(
        _.get(sxsErrorsAndWarnings, 'underwritingIssues'), selectedVersion
    );
    const pendingInfoIssues = sxsPeriodUWIssues.filter(isUWIssueNonBlockingWhilePendingApproval);
    return pendingInfoIssues;
}

/**
 * Extrac Pending UWIssues from PolicyChangeDataDTO
 * @param {object} errorsAndWarnings
 * @returns {array} list of UWIssueDTO
 */
function getPendingInfoIssues(errorsAndWarnings = {}) {
    const {
        underwritingIssues: uwIssues = []
    } = errorsAndWarnings;
    const pendingInfoIssues = uwIssues.filter(isUWIssueNonBlockingWhilePendingApproval);
    return pendingInfoIssues;
}

/**
 * Checks whether we have blocking UW issues or not
 * This function is only for quote details summary page use
 * @param {Array} underwritingIssues, an instance ofJobValidationUnderwritingIssuesDTO
 * @param {String} selectedVersionPublicID selected version public ID
 * @returns {boolean}
 */
function quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues = [], selectedVersionPublicID) {
    const blockingIssue = [...underwritingIssues].find((uwIssue) => {
        const {
            offerings,
            currentBlockingPoint,
            onOfferings,
        } = uwIssue;
        let blockingPoint = currentBlockingPoint || '';
        // When issue is Blocking but only block one version, onOffering of unblocked version will not exist
        // So if blockingPoint is undefined, it means this issue is not blocking
        if (!_.isEmpty(onOfferings)) {
            blockingPoint = _.get(onOfferings[0], 'currentBlockingPoint');
            // The first item of offering is always the selected version, find onOffering by this
            // const selectedVersionName = offerings[0];
            const selectedOnOffering = onOfferings
                .find((onOffering) => onOffering.periodPublicID_Ext === selectedVersionPublicID);
            if (selectedOnOffering) {
                blockingPoint = _.get(selectedOnOffering, 'currentBlockingPoint');
            } else {
                return false;
            }
        }
        // return UW_BLOCKS_BIND === blockingPoint || UW_BLOCKS_ISSUE === blockingPoint;
        return blockingPoint.startsWith(UW_BLOCKS_PREFIX);
    });
    return !_.isEmpty(blockingIssue);
}

// ============================================

function hasUnApprovedUWIssueByFilter(uwIssues = [], filterFn = _.stubFalse) {
    if (_.isEmpty(uwIssues)) {
        return false;
    }

    const retval = uwIssues.some((uwIssue) => {
        const foundIssue = filterFn(uwIssue);
        if (foundIssue) {
            const isPendingApproval = isUwIssuePendingApproval(uwIssue);
            return isPendingApproval;
        }
        return false;
    });
    return retval;
}

function hasOnlyOneUnApprovedUWIssueByFilter(uwIssues = [], filterFn = _.stubFalse) {
    if (_.isEmpty(uwIssues)) {
        return false;
    }
    if (uwIssues.length > 1) {
        return false;
    }
    const retval = hasUnApprovedUWIssueByFilter(uwIssues, filterFn);
    return retval;
}

/**
 * Same with hasUnApprovedIneligibleUWIssue(), but for R2 and later Lobs.
 * @param {array} uwIssues 
 * @param {array} uwIssueCodeList 
 * @returns {boolean}
 */
function hasUnApprovedUWIssueByTypeCode(uwIssues = [], uwIssueCodeList = []) {
    const retval = hasUnApprovedUWIssueByFilter(uwIssues, (uwIssue) => {
        return uwIssueCodeList.includes(uwIssue.issueTypeCode_Ext);
    });
    return retval;
}

/**
 * Checks whether there is only one UnApproved UW issue with specific code
 * @param {array} uwIssues 
 * @param {string} uwIssueCode 
 * @returns {boolean}
 */
function hasOnlyOneUnApprovedUWIssue(uwIssues = [], uwIssueCode) {
    const retval = hasOnlyOneUnApprovedUWIssueByFilter(uwIssues, (uwIssue) => {
        return uwIssueCode === uwIssue.issueTypeCode_Ext;
    });
    return retval;
}


export default {
    hasBlockingOrRejectedUWIssue,
    isUwIssuePendingApproval,
    quoteDetailsSummaryPageHasBlockingUWIssue,
    //
    filterUWIssuesBasedOnPeriod,
    filterBlockingUWIssuesBasedOnPeriod,
    //
    isBlockingOrRejectedUWIssue,
    //
    getPendingInfoIssuesFromSxsData,
    getPendingInfoIssues,
    formatUWIssueTypeTitle,
    // getUWIssueTypeTitle,
    //
    hasUnApprovedUWIssueByTypeCode,
    hasOnlyOneUnApprovedUWIssue,
};
