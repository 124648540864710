import React, {useState, useCallback, useEffect} from 'react'
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMTransitService as  TransitService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMTransitPage.metadata.json5';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMCommonClausesCard from '../../components/IMCommonClausesCard/CommonClausesCard';
import messages from './IMTransitPage.messages';
import IMCoverageUtil from '../../util/IMCoverageUtil';

const TransitReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM
    } = props;

    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [isEditing, setIsEditing] = useState(false)
    const [transitClauses, setTransitClauses] = useState()
    const [transitLoadingClauses, setTransitLoadingClauses] = useState()

    const {
        jobID,
        sessionUUID
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const res = await TransitService.getTransitClauses(jobID, sessionUUID, authHeader)
        setTransitClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onPageNext = () => {
        return submissionVM
    }

     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: transitClauses,
            setCoveragePartClauses: setTransitClauses,
            isEditing,
            setIsEditing,
            isEditable: false,
            loadingClauses: transitLoadingClauses,
            setLoadingClauses: setTransitLoadingClauses,
            wizardData: submissionVM,
            updateCoverageFormClausesService: TransitService.updateTransitClauses,
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: false,
            },
            transportations: {
                visible: _.get(transitClauses, 'transportationsCoverage.visible')
            },
            ownersCargo: {
                visible: _.get(transitClauses, 'ownersCargoCoverage.visible')
            },
            tripTransit: {
                visible: _.get(transitClauses, 'tripTransitCoverage.visible')
            },
            transportationsMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'transportationsCoverage.mainCoverage'),
                coverageFormPath: 'transportationsCoverage.mainCoverage',
            },
            transportationsSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'transportationsCoverage.supplementalCoverage'),
                coverageFormPath: 'transportationsCoverage.supplementalCoverage',
            },
            transportationCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'transportationsCoverage.transportationCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(transitClauses, 'transportationsCoverage.transportationCovs'),
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            ownersCargoMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'ownersCargoCoverage.mainCoverage'),
                coverageFormPath: 'ownersCargoCoverage.mainCoverage'
            },
            ownersCargoSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'ownersCargoCoverage.supplementalCoverage'),
                coverageFormPath: 'ownersCargoCoverage.supplementalCoverage'
            },
            ownersCargoCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'ownersCargoCoverage.ownersCargoCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(transitClauses, 'ownersCargoCoverage.ownersCargoCovs'),
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            tripTransitMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'tripTransitCoverage.mainCoverage'),
                coverageFormPath: 'tripTransitCoverage.mainCoverage'
            },
            tripTransitSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'tripTransitCoverage.supplementalCoverage'),
                coverageFormPath: 'tripTransitCoverage.supplementalCoverage'
            },
            tripTransitCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'tripTransitCoverage.tripTransitCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(transitClauses, 'tripTransitCoverage.tripTransitCovs'),
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'Transit'
            }
        }
    }, [transitClauses, accordionCommonProps, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            TransportationsMainCovCard: IMCommonMainCoverage,
            TransportationsSupplementalCovCard: IMCommonMainCoverage,
            TransportationCovs: IMCommonClausesCard,
            OwnersCargoMainCovCard: IMCommonMainCoverage,
            OwnersCargoSupplementalCovCard: IMCommonMainCoverage,
            OwnersCargoCovs: IMCommonClausesCard,
            TripTransitMainCovCard: IMCommonMainCoverage,
            TripTransitSupplementalCovCard: IMCommonMainCoverage,
            TripTransitCovs: IMCommonClausesCard,
            AdditionalAccordionCard: IMCommonAdditionalCovCard
        },
    };

    const disableNavigator = isEditing || isLoadingMask || transitLoadingClauses

    return (
        <WizardPage
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={onPageNext}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {transitClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default TransitReadOnlyPage
