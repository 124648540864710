import { defineMessages } from 'react-intl';

export default defineMessages({
    allOpen: {
        id: 'gateway.directives.ActivitySummary.All Open',
        defaultMessage: 'All Open'
    },
    allCompleted: {
        id: 'gateway.directives.ActivitySummary.All Completed',
        defaultMessage: 'All Completed'
    },
    allClosed: {
        id: 'gateway.directives.ActivitySummary.All Closed',
        defaultMessage: 'All Closed'
    },
    overdue: {
        id: 'gateway.directives.ActivitySummary.Overdue',
        defaultMessage: 'Overdue Only'
    },
    openAssignedToMe: {
        id: 'gateway.directives.ActivitySummary.Open, Assigned to me',
        defaultMessage: 'Open, Assigned to me'
    },
    activityType: {
        id: 'gateway.directives.activity-summary.Type',
        defaultMessage: 'Type'
    },
    activtyDueDate: {
        id: 'gateway.directives.activity-summary.Due Date',
        defaultMessage: 'Due Date'
    },
    activitySubject: {
        id: 'gateway.directives.activity-summary.Subject',
        defaultMessage: 'Subject'
    },
    activityAssignedTo: {
        id: 'gateway.directives.activity-summary.Assign To',
        defaultMessage: 'Assign To'
    },
    activityPriority: {
        id: 'gateway.directives.activity-summary.Priority',
        defaultMessage: 'Priority'
    },
    activityDescription: {
        id: 'gateway.directives.activity-summary.Description',
        defaultMessage: 'Description'
    },
    activityTypePlaceHolder: {
        id: 'gateway.directives.activity-summary.Please select activity type',
        defaultMessage: 'Please select activity type'
    },
    activityPriorityPlaceHolder: {
        id: 'gateway.directives.activity-summary.Priority.select',
        defaultMessage: '--Choose Priority--'
    },
    noTypeSelected: {
        id: 'gateway.directives.add-activity.Close',
        defaultMessage: 'Close'
    },
    noActivityType: {
        id: 'gateway.directives.add-activity.You must choose an activity type',
        defaultMessage: 'No Activity Type Selected'
    },
    noActivityTypeMessage: {
        id: 'gateway.directives.add-activity.You must first select an activity type',
        defaultMessage: 'You must first select an activity type.'
    },
    activityCreated: {
        id: 'gateway.directives.add-activity.Your Activity has been created',
        defaultMessage: 'Your Activity has been created'
    },
    searchActivities: {
        id: 'gateway.directives.activity-summary.Search activities',
        defaultMessage: 'Search activities'
    },
    activity: {
        id: 'gateway.directives.activity-summary.Activity',
        defaultMessage: 'Activity'
    },
    cancel: {
        id: 'gateway.directives.activity-summary.Cancel',
        defaultMessage: 'Cancel'
    },
    createActivity: {
        id: 'gateway.directives.activity-summary.Create Activity',
        defaultMessage: 'Create Activity'
    },
    addActivity: {
        id: 'gateway.directives.activity-summary.Add an Activity',
        defaultMessage: 'Add an Activity'
    }
});
