import React, { useContext, useEffect, useState } from 'react';
import { WniInputNumber } from 'wni-common-base-components';
import SingleClauseContext from '../../../context/IMSingleClauseContext';

const IMScheduleItemEditableCovTermCellInputYear = (props) => {
    const { showErrors, isEditable, setIsEditing } =
        useContext(SingleClauseContext);

    const { termID, term, setUpdatedTermToScheduleItem } = props;

    const { directStringValue } = term;

    const [editingValue, setEditingValue] = useState(directStringValue);

    useEffect(() => {
        setEditingValue(directStringValue);
    }, [directStringValue]);

    return (
        <WniInputNumber
            id={termID}
            showLabel={false}
            value={editingValue}
            onValueChange={(value) => {
                setEditingValue(value);
                setIsEditing(true);
            }}
            onBlur={async () => {
                setIsEditing(false);
                if (editingValue === directStringValue) {
                    return;
                }
                if (editingValue < 1000 || editingValue > 2999) {
                    setEditingValue(directStringValue);
                    return;
                }
                const updatedTerm = {
                    ...term,
                    directStringValue: editingValue,
                    updated: true,
                };
                await setUpdatedTermToScheduleItem(updatedTerm);
            }}
            maxValue={2999}
            minValue={1000}
            required={term.required}
            readOnly={!isEditable || term.readOnly_Ext}
            showFractions={false}
            showErrors={showErrors}
        />
    );
};

export default IMScheduleItemEditableCovTermCellInputYear;
