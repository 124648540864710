import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentFail: {
        id: 'customer.views.confirmation.gateway.PaymentFail',
        defaultMessage: 'Payment could not be processed. The policy has been conditionally issued, but a payment is still required. \nClick the Next button to proceed to the next page where you can make a One-Time Payment or contact your Underwriter.'
    },
    issuedSuccess: {
        id: 'customer.views.confirmation.gateway.This policy has been issued',
        defaultMessage: 'This policy has been issued.'
    },
    driversLicenseNumber: {
        id: 'customer.pa.views.pa-confirmation.Drivers License Number',
        defaultMessage: 'Driver\'s License Number'
    },
    licenseState: {
        id: 'customer.pa.views.pa-confirmation.License State',
        defaultMessage: 'License State'
    },
    yearFirstLicensed: {
        id: 'customer.pa.views.pa-confirmation.Year First Licensed',
        defaultMessage: 'Year First Licensed'
    },
    carMakeTable: {
        id: 'customer.pa.views.pa-confirmation.Make',
        defaultMessage: 'Make'
    },
    carModelTable: {
        id: 'customer.pa.views.pa-confirmation.Model',
        defaultMessage: 'Model'
    },
    carLicensePlateTable: {
        id: 'customer.pa.views.pa-confirmation.License Plate',
        defaultMessage: 'License Plate'
    },
    coverageTable: {
        id: 'customer.pa.views.pa-confirmation.Coverage',
        defaultMessage: 'Coverage'
    },
    limitsTable: {
        id: 'customer.pa.views.pa-confirmation.Limits',
        defaultMessage: 'Limits'
    },
    firstName: {
        id: 'customer.pa.views.pa-confirmation.FirstName',
        defaultMessage: 'First Name'
    },
    lastName: {
        id: 'customer.pa.views.pa-confirmation.LastName',
        defaultMessage: 'Last Name'
    },
    phone: {
        id: 'customer.pa.views.pa-confirmation.Phone',
        defaultMessage: 'Phone'
    },
    email: {
        id: 'customer.pa.views.pa-confirmation.Email',
        defaultMessage: 'Email'
    },
    address: {
        id: 'customer.pa.views.pa-confirmation.Address',
        defaultMessage: 'Address'
    },
    driverDetails: {
        id: 'customer.pa.views.pa-confirmation.Driver Details',
        defaultMessage: 'Driver Details'
    },
    contactInformationDetails: {
        id: 'customer.pa.views.pa-confirmation.Contact Information Details',
        defaultMessage: 'Contact Information Details'
    },
    vehicleDetails: {
        id: 'customer.pa.views.pa-confirmation.Vehicle Details',
        defaultMessage: 'Vehicle Details'
    },
    coveragePremiumDetails: {
        id: 'customer.pa.views.pa-confirmation.Coverage Premium Details',
        defaultMessage: 'Coverage Premium Details'
    },
    changeSuccess: {
        id: 'customer.views.confirmation.gateway.This policy has been changed',
        defaultMessage: 'This policy has been changed.'
    },
    convertedInfo: {
        id: 'customer.pa.views.pa-confirmation.Converted Info',
        defaultMessage: 'This quote was created based on information from the insured’s current Personal Auto policy as of {effectiveDate}. Please review it carefully to ensure that any recent changes have been applied.'
    },
    paymentSubmitted: {
        id: 'customer.pa.views.pa-confirmation..Payment Submitted',
        defaultMessage: 'Payment Submitted'
    },
    paymentCancelled: {
        id: 'customer.pa.views.pa-confirmation..Payment Cancelled',
        defaultMessage: 'Payment Cancelled'
    },
});
