import React from 'react';
import messages from './SpecialClassClauses.messages'
import DefaultRiskItemClauses from '../DefaultRiskItemClauses/DefaultRiskItemClauses';

const SpecialClassClauses = (props) => {
    return <DefaultRiskItemClauses
        {...props}
        messages={messages}
    />
};

export default SpecialClassClauses;
