
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import { useWniModal } from 'wni-components-platform-react';
import { messages } from '@xengage/gw-platform-translations';
import ContactInformation from '../ContactInformation/ContactInformation';
import IndustryCodePopup from '../IndustryCodePopup/IndustryCodePopup';
import { DESCRIPTION_SEARCH_TYPE } from '../IndustryCodePopup/IndustryCodeUtil';
import metadata from './CommercialAccountContact.metadata.json5';

const CommercialAccountContact = (props) => {
    const {
        model: accountContactDtoVM,
        writeValue,
        onValidate,
        showErrors
    } = props;
    const modalApi = useWniModal();
    const translator = useTranslator();
    const {
        authUserData: {
            businessData_Ext: {
                yearBusinessWasStartedList = [],
                industryCodes = []
            } = {}
        } ={}
    } = useAuthentication();

    const searchIndustry = (searchType) => {
        const industryPath = 'industry_Ext';
        const modalProps = {
            title: 'NAICS Lookup',
            model: accountContactDtoVM,
            basePath: industryPath,
            searchType,
            actionBtnLabel: messages.ok,
            cancelBtnLabel: messages.cancelModel,
        };
        modalApi.showModal(<IndustryCodePopup {...modalProps} />).then((res) => {
            const newValue = {
                ...accountContactDtoVM.value,
                [industryPath]: res,
                industryCode_Ext: res.code,
                industryDescription_Ext: res.classification
            }
            writeValue(newValue, 'value')
        }).catch(_.noop);
    };

    const getAvailableValues = (path) => {
        const options = _.get(accountContactDtoVM,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };

    const getAvailableYears = () => {
        return yearBusinessWasStartedList.map((item) => {
            return {
                name: item,
                code: item
            }
        })
    };
  
    const getNDAvailableValues = (code) => {
        const instryCode = code || _.get(accountContactDtoVM.value, 'industryCode_Ext');
        const filterIndustryByCode = industryCodes.filter((item) => item.code === instryCode) || [];
        return filterIndustryByCode.map((item) => {
            return {
                name: item.classification,
                code: item.classification
            }
        })
    };

    const onValueChange = (value, path) => {
        writeValue(value, path);
        switch(path){
            case 'industryCode_Ext':
                const descriptionAvailableValues = getNDAvailableValues(value);
                if(descriptionAvailableValues.length === 1) {
                    const initIndustry = descriptionAvailableValues[0];
                    writeValue(initIndustry.code, 'industryDescription_Ext');
                } else {
                    writeValue('industryDescription_Ext', undefined);
                }
                break;
            default:
                break
        }
    };

    if(_.isEmpty(accountContactDtoVM)) {
        return null;
    }
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false
        },
        accountAddressContainer: {
            model: accountContactDtoVM,
            dataPath: 'primaryAddress',
            onAddressChange: writeValue,
            hideFieldType: {
                addressType: true,
                country: true
            },
            showErrors,
            onValidate
        },
        contactInfoCard:{
            model: accountContactDtoVM,
            writeValue,
            onValidate,
            showErrors,
        },
        naicsCodeSearchButton: {
            onClick: () => searchIndustry()
        },
        naicsDescription: {
            availableValues: getNDAvailableValues()
        },
        naicsDescriptionSearchButton: {
            onClick: () => searchIndustry(DESCRIPTION_SEARCH_TYPE)
        },
        organizationTypes: {
            availableValues: getAvailableValues('organizationTypesOption_Ext')
        },
        businessYearStarted: {
            availableValues: getAvailableYears()
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            accountContactDtoVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            
        },
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            contactinformation: ContactInformation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountContactDtoVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

export default CommercialAccountContact;
