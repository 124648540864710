import React from 'react';
import WizardPage from '../../templates/CPWizardPage';
import CPLocationsPage from './CPLocationsPage';

function CPLocationsReadOnlyPage(props) {
    return (
        <CPLocationsPage {...props} isReadOnly/>
    );
}

CPLocationsReadOnlyPage.propTypes = WizardPage.propTypes;
export default CPLocationsReadOnlyPage;
