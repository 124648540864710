import React from 'react';
import WizardPage from '../../templates/CPPWizardPage';
import CPPLocationsPage from './CPPLocationsPage';

function CPPLocationsReadOnlyPage(props) {
    return (
        <CPPLocationsPage {...props} isReadOnly/>
    );
}

CPPLocationsReadOnlyPage.propTypes = WizardPage.propTypes;
export default CPPLocationsReadOnlyPage;
